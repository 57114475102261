import {Component, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Coupon, CouponsService, SubscriptionDefService} from '@savvy/subscription';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangePlanSameCard, SubscriptionService } from '@savvy/subscription';
import { MatSidenav } from '@angular/material/sidenav';
import { FloSnackbarComponent } from '../../../snackbar/floSnackbar.component';
import { UsersService } from '@savvy/user';
import { TranslateService } from '@ngx-translate/core';
import {
  SubscriptionCompService,
  Subscription,
  SubscriptionDefinitionId
} from '@savvy/subscription';
import { TrialExpiredStateService } from '../../services/trial-expired-state.service';
import { SetQuantityModalComponent } from './set-quantity-modal.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import { PhoneNumberHelperService } from '../../services/phone-number-helper.service';
import { LoadSubscriptionDefinitionsResponse, SubscriptionDefinition, SubscriptionIntervalPackage } from '@savvy/subscription';
import { UserDto } from '@savvy/ui';
import { ContextIdDto } from '@savvy/view-definition';
import { ConfirmUpdateSubscriptionModalComponent } from './update-subscription-in-trial-modal/confirm-update-subscription-modal/confirm-update-subscription-modal.component';
import { NavigateService } from '../../../entry/NavigateService';
import { ConfirmAnnualPlanComponent } from './confirm-annual-plan/confirm-annual-plan.component';
import { ConfirmationDialogComponent } from 'src/app/flo/confirmation-dialog/confirmation-dialog.component';
import { SubscriptionCurrency } from '@savvy/subscription/model/subscriptionCurrency';
import {ConfirmationDialog} from "../../../confirmation-dialog/confirmation-dialog.model";

@Component({
  selector: 'app-confirm-change-subscription-plan',
  templateUrl: 'confirmChangeSubscriptionPlan.html',
  styleUrls: ['./confirmChangeSubscriptionPlan.component.scss']
})
export class ConfirmChangeSubscriptionPlanComponent implements OnInit {

  response = { confirm: false, couponCode: null, couponId: null };
  validCoupon = false;
  upgrade = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConfirmChangeSubscriptionPlanComponent>,
    private couponsService: CouponsService
  ) {
    this.upgrade = data.upgrade;
  }

  ngOnInit(): void {
  }

  confirmDialog() {
    this.response.confirm = true;

    if (this.response.couponCode) {
      this.couponsService.searchCouponByCode(this.response.couponCode).subscribe(res => {
        if (res && res.couponCode === this.response.couponCode) {
          this.validCoupon = true;
          this.response.couponCode = res.couponCode;
          this.response.couponId = res.id;
        } else {
          this.validCoupon = false;
          this.response.couponId = null;
        }
        this.dialogRef.close(this.response);
      });
    } else {
      this.dialogRef.close(this.response);
    }

  }

  closeDialog() {
    this.response.confirm = false;
    this.dialogRef.close(this.response);
  }

  discountCodeChanged(value: any) {
    const coupon = this.response.couponCode;
    if (coupon && this.upgrade) {
      this.couponsService.searchCouponByCodeAndUsage(coupon, 'UPGRADE_PLAN').subscribe(res => {
        if (res && res.couponCode === coupon) {
          this.validCoupon = true;
          this.response.couponCode = res.couponCode;
          this.response.couponId = res.id;
        } else {
          this.validCoupon = false;
          this.response.couponId = null;
        }
      });
    }

  }
}
