import { Component, Input, OnInit, Output } from '@angular/core';
import { EventBusService } from '../EventBusService';
import { ContextIdDto, IdNameTupleDto } from '@savvy/entity-instance-composite';
import { ViewContextDto } from '@savvy/view-composite';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { CreateCustomerCompositeLite, CustomerCompositeService, Customer } from '@savvy/customer';
import { EventEmitter } from '@angular/core';
import { PhoneNumber, UserDto, UserId } from '@savvy/view-definition';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserCountryService } from '../../../services/userCountry.service';
import { CheckinFormDefService, CheckinFormDefsService } from '@savvy/check-in-form';
import { CheckinFormDefinition } from '@savvy/check-in-form/model/models';


@Component({
  selector: 'app-pet-check-in-form-selector',
  templateUrl: 'petCheckInFormSelector.component.html'
})
export class PetCheckInFormSelectorComponent implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  selectedCheckinFormDefIds: string[] = [];
  selectedCheckinFormDefs: CheckinFormDefinition[] = [];
  checkInFormDefs: Array<CheckinFormDefinition>;

  constructor(private api: CheckinFormDefsService) {

  }

  ngOnInit(): void {
    this.api.loadCheckinFormDefs(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType).subscribe(response => {
        this.checkInFormDefs = response;
        for (const checkInFormDef of this.checkInFormDefs) {
          checkInFormDef['checked'] = true;
        }
        this.selectedCheckinFormsChanged();
      });
  }

  selectedCheckinFormsChanged() {
    this.selectedCheckinFormDefs = this.checkInFormDefs.filter(form => form['checked']);
    this.selectedCheckinFormDefIds = this.selectedCheckinFormDefs.map(f => f.id);
  }

}

