import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { TranslateService } from '@ngx-translate/core';
import { ContextIdDto } from '@savvy/view-definition';
import { CreatePlanDefinition, PlanDefService, UpdatePlanDefinition } from '@savvy/plan-definition';
import { CustomerCompositeService, Customer, CustomerService, IdNameTupleDto } from '@savvy/customer';
import { PlanDto, PlansCompService, PlanService, SubscribeToPlan, UpdatePlanCost } from '@savvy/plan';
import { OrgCompositeService } from '@savvy/org';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddCustomerToPlanComponent } from './addCustomerToPlan.component';
import { ViewPlanComponent } from '../shared/components/plan/view-plan.component';
import { ConfirmationModalComponent } from '../shared/components/confirmation-modal/confirmation-modal.component';
import { PlanDefinition } from '@savvy/plan-definition/model/planDefinition';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-add-edit-planDef',
  templateUrl: 'addEditPlanDef.component.html',
  styleUrls: ['addEditPlanDef.component.scss']
})
export class AddEditPlanDefComponent implements OnInit {

  private sub: any;

  @Input() contextIdDto: ContextIdDto;
  @Input() customerId: string;
  @Input() customerOnboarding = false;
  model = <PlanDefinition>{};
  searchText = '';
  searchCustomerResult: Array<IdNameTupleDto> = [];
  currency = '';

  customerPlans: Array<PlanDto> = [];
  customerPlan = <PlanDto>{};
  selected: PlanDto[] = [];
  customers: Array<any> = [];
  customerDto: Customer;
  subscribeToPlanList: Array<SubscribeToPlan> = [];

  previousPrice: number;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private notify: FloSnackbarComponent,
    private planApi: PlanService,
    private planCompApi: PlansCompService,
    private customerCompApi: CustomerCompositeService,
    private customerService: CustomerService,
    private planDefApi: PlanDefService,
    private orgCompApi: OrgCompositeService,
    @Optional() private dialogRef: MatDialogRef<AddEditPlanDefComponent>) {
    if (data) {
      this.contextIdDto = data.contextIdDto;
      this.customerId = data.customerId;
      this.customerOnboarding = data.customerOnboarding;
    }

  }

  ngOnInit() {
    this.model.customerIds = [];

    this.sub = this.route.params.subscribe(params => {

      if (!this.contextIdDto) {
        this.contextIdDto = <ContextIdDto>{};
        this.contextIdDto.contextId = params['contextId'];
        this.contextIdDto.contextIdType = params['contextIdType'];
      }

      this.model.paymentSchedule = 'IMMEDIATE';
      this.model.numberOfSessions = 1;

      const planDefId = params['planDefId'];

      if (planDefId) {
        this.loadPlanDef(planDefId);
      }

      if (this.customerId) {
        this.insertCustomerToSubscribePlanList(this.customerId);
      }
    });

    this.loadOrg();

    if (this.model.unlimitedSessions == null) {
      this.model.unlimitedSessions = false;
    }

  }

  private loadPlanDef(planDefId) {
    this.planDefApi.load(this.contextIdDto.contextId, this.contextIdDto.contextIdType, planDefId).subscribe(res => {
      this.model = res.planDefinition;
      this.previousPrice = res.planDefinition.cost;

      if (!this.model.customerIds || this.model.customerIds.length == 0) {
        this.model.customerIds = [];
      }
      this.loadSubscribedCustomers();

    });
  }

  loadOrg() {
    this.orgCompApi.getCurrentOrgComp().subscribe(res => {
      console.log('ORG');
      console.log(res);
      this.currency = res.currencyCode;
    });
  }

  addPlanDef() {
    const req = <CreatePlanDefinition>{};
    req.contextIdDto = this.contextIdDto;
    req.name = this.model.name;
    req.description = this.model.description;
    req.cost = this.model.cost;
    req.paymentInterval = this.model.paymentInterval;
    req.unlimitedSessions = this.model.unlimitedSessions;
    req.numberOfSessions = this.model.numberOfSessions;
    req.onlineSalesEnabled = true;
    req.paymentSchedule = this.model.paymentSchedule;
    req.currency = this.currency;

    this.planDefApi.create(req).subscribe(res => {
      this.model = res.planDefinition;
      this.model.customerIds = [];

      this.previousPrice = this.model.cost;
      this.notify.message = 'Successfully created plan.';
      this.notify.open();

      this.subscribeCustomers();

      if (this.customerOnboarding) {//coming from customer onboarding
        this.dialogRef.close(this.model.id);
      }


    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  updatePlanDef() {

    if (this.model.cost !== this.previousPrice) {
      const confirmDialog = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: 'Price Updated',
          message: 'Price has been updated. The new price will be applied to all subscribed customers on their next billing. Do you want to proceed?'
        },
        height: 'auto',
        width: '500px',
        panelClass: 'helpwindow'
      });

      confirmDialog.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.sendUpdateRequest(true);
        }
      });
    } else {
      this.sendUpdateRequest(false);
    }

  }

  sendUpdateRequest(priceUpdated: boolean) {
    const req = <UpdatePlanDefinition>{};
    req.contextIdDto = this.contextIdDto;
    req.planDefinition = this.model;

    this.planDefApi.update(req).subscribe(response => {
      this.model = response.planDefinition;
      this.translateService.get('Successfully updated plan.').subscribe(value => {
        this.subscribeCustomers();
        this.notify.message = value;
        this.notify.open();

        if (priceUpdated) {
          this.planApi.loadByPlanDefId(this.contextIdDto.contextId, this.contextIdDto.contextIdType, this.model.id).subscribe(res => {
            const plans = res.plans;

            plans.forEach(plan => {
              const updatePlanCostReq = <UpdatePlanCost>{};
              updatePlanCostReq.contextIdDto = this.contextIdDto;
              updatePlanCostReq.planId = plan.id;
              updatePlanCostReq.amount = this.model.cost;


              this.planApi.updatePlanCost(updatePlanCostReq).subscribe(res => {
                console.log('plan updated');
              });
            });
          });
        }
      });
    });
  }

  private subscribeCustomers() {
    this.subscribeToPlanList.forEach(planReq => {
      planReq.contextIdDto = this.contextIdDto;
      planReq.planDefinitionId = this.model.id;

      this.planCompApi.subscribe(planReq).subscribe(res => {
        this.loadSubscribedCustomers();
        this.loadPlanDef(this.model.id);
        this.notify.message = planReq.customerName + ' has been successfully subscribed to plan.';
        this.notify.open();

        if (this.customerId) { //request is coming from modal
          this.dialogRef.close();
        }
      });

    });
  }

  deletePlanDef() {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        name: 'schedule'
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.planDefApi._delete(this.contextIdDto.contextId, this.contextIdDto.contextIdType, this.model.id).subscribe(res => {
          this.notify.message = 'Deleted plan.';
          this.notify.open();
          this.backToList();
        });
      }
    });

  }

  backToList() {
    this.router.navigate(['/planDef/planDefs',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType]);
  }

  changePaymentSchedule(event: any) {
    this.model.paymentInterval = null;
  }


  loadSubscribedCustomers() {
    if (this.model.id) {
      this.planCompApi.loadByPlanDefIdComp(this.contextIdDto.contextId, this.contextIdDto.contextIdType, this.model.id).subscribe(res => {
        this.customerPlans = res.plans;
      });
    }

  }

  addCustomerToPlan() {
    const dialogRef = this.dialog.open(AddCustomerToPlanComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        planDef: this.model,
        customerPlans: this.customerPlans
      },
      width: '90%',
      height: '90%'
    });

    dialogRef.componentInstance.added.subscribe(data => {
      // do something
      this.loadSubscribedCustomers();
    });
  }

  viewPlan(customerPlan) {
    console.log('Event: select', customerPlan);
    const dialogRef = this.dialog.open(ViewPlanComponent, {
      width: '90%',
      height: '90%',
      data: {
        contextIdDto: this.contextIdDto,
        plan: customerPlan
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadSubscribedCustomers();
    });

  }

  deletePlan(id) {
    this.planCompApi.deletePlan(this.contextIdDto.contextId, this.contextIdDto.contextIdType, id).subscribe(response => {
      this.notify.message = 'Deleted Plan';
      this.notify.open();
      this.loadSubscribedCustomers();
    });
  }

  loadCustomer(id: string) {
    this.customerService.loadCustomer(id, this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(res => {
      console.log(res);
      this.customerDto = res;
    });
  }

  removeCustomerFromList(index) {
    this.subscribeToPlanList.splice(index, 1);
    this.subscribeToPlanList = [...this.subscribeToPlanList];
  }

  navigateToViewPlan() {
    this.router.navigate(['/planDef/viewPlanDef',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      this.model.id]);
  }

  handler(event: MatAutocompleteSelectedEvent): void {
    console.log('calling set value NOW');

    this.customerService.loadCustomer(event.option.value, this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(res => {
      console.log(res);
      this.customerDto = res;

      if (this.customerDto && this.customerDto.username) {
        this.model.customerIds.push(event.option.value);
        const item = <SubscribeToPlan>{};
        item.customerId = event.option.value;
        item.customerName = `${this.customerDto.firstName} ${this.customerDto.lastName}`;
        item.dayOfPayment = 1;
        this.subscribeToPlanList.push(item);

        this.subscribeToPlanList = [...this.subscribeToPlanList];

      }
    });

  }

  insertCustomerToSubscribePlanList(customerId) {
    console.log('calling set value NOW');

    this.customerService.loadCustomer(customerId, this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(res => {
      console.log(res);
      this.customerDto = res;

      if (this.customerDto && this.customerDto.username) {
        this.model.customerIds.push(customerId);
        const item = <SubscribeToPlan>{};
        item.customerId = customerId;
        item.customerName = this.customerDto.firstName + ' ' + this.customerDto.lastName;
        item.dayOfPayment = 1;
        this.subscribeToPlanList.push(item);

        this.subscribeToPlanList = [...this.subscribeToPlanList];

      }
    });
  }

  loadTypeahead(searchString: string) {

    console.log('env id is ' + this.contextIdDto.contextId);

    if (searchString && searchString.length > 0) {
      this.customerCompApi.searchCustomers(searchString, this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(res => {
        console.log(res);
        this.searchCustomerResult = res;
      });
    }
  }

  onKey(event: any) { // without type info
    this.loadTypeahead(event.target.value);
  }

  displayFn(id) {
    if (!id) {
      return '';
    }
    const index = this.searchCustomerResult.findIndex(state => state.id === id);
    if (index === -1) {
      console.log('id is ' + id + ' index is' + index + ' this should never happen as tuples should always be there first');
      return '';
    } else {
      return this.searchCustomerResult[index].name;
    }
  }
}
