import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ContactFormsSummaryDto, ContextIdDto, SummaryItemDto } from '@savvy/view-definition';
import { NavigateService } from '../entry/NavigateService';

@Component({
  selector: 'app-contact-forms-summary-widget',
  templateUrl: 'contactFormsSummaryWidget.component.html'
})
export class ContactFormsSummaryWidgetComponent {

  @Input() contactFormsSummaryDto: ContactFormsSummaryDto;
  @Input() contextIdDto: ContextIdDto;

  constructor(private router: Router, private nav: NavigateService) {
  }

  go(summaryItemDto: SummaryItemDto) {
    console.log('reuse logic from menu to construct');
    this.router.navigate(['/contactForms/viewContactForm',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      summaryItemDto.itemId
    ]);
  }
}

