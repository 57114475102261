import { Component, Input, OnInit, Output } from '@angular/core';
import { ContextIdDto } from '@savvy/entity-instance-composite';
import { ViewContextDto } from '@savvy/view-composite';
import {
  CreateCustomerCompositeLite,
  CustomerCompositeService,
  Customer,
  CreateCustomer,
  CustomerService
} from '@savvy/customer';
import { EventEmitter } from '@angular/core';
import { PhoneNumber, UserDto, UserId } from '@savvy/view-definition';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EventBusService } from '../../../element/EventBusService';
import { UserCountryService } from 'src/app/services/userCountry.service';
import { FloSnackbarComponent } from 'src/app/flo/snackbar/floSnackbar.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Address } from '@savvy/savvy-pay';

@Component({
  selector: 'app-new-customer-selector-v2',
  templateUrl: './new-customer-selector-v2.component.html',
  styleUrls: ['./new-customer-selector-v2.component.scss']
})
export class NewCustomerSelectorV2Component implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() viewContext: ViewContextDto;
  @Input() eventBus: EventBusService;
  @Input() showPlaceholder: boolean;
  @Input() passIdOnly = true;

  @Output() customerCreated = new EventEmitter();
  @Output() newExistingSwitch = new EventEmitter();

  newCustomer = false;
  customer;
  // customers: IdNameTupleDto[] = new Array(0);

  customerFormGroup: UntypedFormGroup;
  customerPhone: PhoneNumber;

  createdCustomer: Customer;

  constructor(
    private customerService: CustomerService,
    public userCountryService: UserCountryService,
    private notify: FloSnackbarComponent,
    private _formBuilder: UntypedFormBuilder,
    public deviceService: DeviceDetectorService
  ) {

  }

  ngOnInit(): void {
    console.log('inside init for invoice stepper panel');
    this.customerPhone = {} as PhoneNumber;
    this.customerPhone.country = this.userCountryService?.defaultCountryCode;
    this.initFormGroup();
  }

  createCustomer(optIn: boolean) {
    if (!this.customerFormGroup.valid) {
      this.notify.message = 'Please provide mandatory values';
      this.notify.open();
      return;
    }
    const req = {} as CreateCustomer;
    req.contextIdDto = this.contextIdDto;
    req.customer = <Customer>{};
    req.customer.firstName = this.customerFormGroup.get('customerFirstName').value;
    req.customer.lastName = this.customerFormGroup.get('customerLastName').value;
    req.customer.username = this.customerFormGroup.get('customerEmail').value;
    req.customer.mobilePhoneNumberObj = this.customerPhone;
    req.customer.address = this.customerFormGroup.get('customerAddress').value;
//    req.customer.optIn = <OptIn>{};

    this.customerService.createCustomer(req).subscribe(customer => {
      setTimeout(() => this.customerFormGroup.disable(), 300);
      this.notify.message = 'Created Customer';
      this.notify.open();
      this.createdCustomer = customer;
      this.customerCreated.emit(customer);
    }, (error) => {
      console.log('err is', error);
      if (error && error.status === 409) {
        this.notify.message = `Customer already exists with email ${req.customer.username}`;
        this.notify.open();
      } else {
        this.notify.message = error.error;
        this.notify.open();
      }
    });
  }

  onMobilePhoneChange(value) {
    console.log('mobile changed');
    this.customerPhone = value;
  }

  onMobileCountryChange($event: any) {
    console.log('onMobileCountryChange', $event);
    this.customerPhone.country = $event.iso2;
  }

  setUserOptVal(user: UserDto): UserId {
    return { id: user.id, userContextType: UserId.UserContextTypeEnum.UserId };
  }

  compareUserFn(user1: UserDto, user2: UserDto) {
    return user1 && user2 && user1.id === user2.id;
  }

  cancel() {
    this.newExistingSwitch.emit(false);
  }

  addressChanged(customerAddress: Address) {
    this.customerFormGroup.patchValue({ customerAddress });
  }

  private initFormGroup() {
    this.customerFormGroup = this._formBuilder.group({
      customerFirstName: ['', Validators.required],
      customerLastName: ['', Validators.required],
      customerEmail: [''],
      customerPhone: [''],
      customerAddress: [{}]
    });
  }

}
