import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { EntityModule } from '../entity/entity.module';
import { FloMaterialModule } from '../flo.material.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ElementModule } from '../element/element.module';
import { SimpleBookingPublicRoutes } from './simplebookingpub.routing';
import { BookComponent } from './book.component';
import { SimplebookingcompService } from '@savvy/booking';
import { ServicesCompositeService } from '@savvy/services';
import { UsercompService } from '@savvy/user';
import { SimpleslotsService } from '@savvy/booking';
import { CallbackPipe } from './callback.component';
import { PaymentSettingsService } from '@savvy/payment';
import { BookServiceComponent } from './bookService.component';
import { BookLoginComponent } from './bookLogin.component';
import { BookWizardComponent } from './bookWizard.component';
import { BookSlotComponent } from './bookSlot.component';
import { BookSelectUserComponent } from './bookSelectUser.component';
import { BookSummaryComponent } from './bookSummary.component';
import { TakePaymentComponent } from './takePayment.component';
import { BookingConfirmationComponent } from './bookingConfirmation.component';
import { OrgCompositeService } from '@savvy/org';
import {BookingAnalyticsComponent} from "./bookingAnalytics.component";

@NgModule({
    imports: [
        CommonModule,
        EntityModule,
        ElementModule,
        FloMaterialModule,
        MatProgressSpinnerModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        SharedModule,
        TranslateModule,
        RouterModule.forChild(SimpleBookingPublicRoutes)
    ],
    providers: [
        PaymentSettingsService,
        UsercompService,
        OrgCompositeService,
        SimpleslotsService,
        // SimpleBookingDefApiExtension,
        SimplebookingcompService,
        ServicesCompositeService
    ],
    declarations: [
        BookComponent,
        BookServiceComponent,
        BookingAnalyticsComponent,
        BookWizardComponent,
        BookSummaryComponent,
        BookSelectUserComponent,
        BookSlotComponent,
        TakePaymentComponent,
        BookingConfirmationComponent,
        BookLoginComponent,
        CallbackPipe
    ]
})

export class SimpleBookingPublicModule { }
