import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ChangeListener } from './changeListener';
import { EventBusService } from './EventBusService';
import { PhoneNumberHelperService } from '../shared/services/phone-number-helper.service';
import { TabPanelDefinitionDto, ContextIdDto } from '@savvy/view-definition';

@Component({
  selector: 'app-tab-panel',
  templateUrl: 'tabPanel.component.html'
})
export class TabPanelComponent {

  constructor(private sharedService: PhoneNumberHelperService) { }

  @Input() tabPanelDefinitionDto: TabPanelDefinitionDto;
  @Input() contextIdDto: ContextIdDto;
  @Input() viewContext: any;
  @Input() form: UntypedFormGroup;
  @Input() eventBus: EventBusService;
  @Input() additionalDataMapDto: any;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;

  closePanelBar() {
    this.sharedService.closeSidePanelSubj.next(true);
  }
}

