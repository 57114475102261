import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-annual-plan',
  templateUrl: './confirm-annual-plan.component.html',
  styleUrls: ['./confirm-annual-plan.component.scss']
})
export class ConfirmAnnualPlanComponent implements OnInit {

  constructor(
     @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConfirmAnnualPlanComponent>
  ) { }

  ngOnInit() {
  }

}
