<mat-toolbar color="primary">
  <h5>{{'MERGE_CUSTOMER' | translate}}</h5>
</mat-toolbar>
<div class="mdc-p-3 ">
  <h6 class="my-1 font-weight-bold">{{'ORIGINAL_CUSTOMER' | translate}}</h6>
  <p class="mat-text-secondary">{{'ORIGINAL_CUSTOMER_DESC' | translate}}</p>
  <br>
  <app-customer-selector #customerSelector1 class="field-center" [contextIdDto]="contextIdDto"
    (selectedCustomer)="sourceCustomerChanged($event)">
  </app-customer-selector>

  <h6 class="my-1 font-weight-bold">{{'DUPLICATE_CUSTOMER' | translate}}</h6>
  <p class="mat-text-secondary">
    {{'DUPLICATE_CUSTOMER_DESC_ONE' | translate}} <br>
    {{'DUPLICATE_CUSTOMER_DESC_TWO' | translate}} <br>
    {{'DUPLICATE_CUSTOMER_DESC_THREE' | translate}}
  </p>
  <br>
  <app-customer-selector #customerSelector2 class="field-center" [contextIdDto]="contextIdDto"
    (selectedCustomer)="targetCustomerChanged($event)">
  </app-customer-selector>
  <br>
  <div class="alert alert-danger mdc-mb-2">
    <p><mat-icon class="me-1 vm">warning</mat-icon> Merge customer can not be reverted or undo</p>
  </div>

  <button mat-raised-button (click)="merge()" color="accent">{{'MERGE' | translate}}</button>
</div>