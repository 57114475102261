<div class="container mt-1 mb-2">
  <div class="row">
    <div class="col">
      <h3>{{'Name and Contact' | translate}}</h3>
    </div>
    <div class="col-auto"></div>
  </div>
  <mat-card appearance="outlined" class="demo-card demo-basic m-0 " *ngIf="model">
    <mat-card-content>
      <form #customerForm="ngForm" style="width: 100%">

        <div fxLayout="row" fxLayoutAlign="space-between center">
          <mat-checkbox (change)="companyChanged()" [checked]="company" [(ngModel)]="company" class="example-margin"
            name="company">{{'This client is a Company' | translate}}
          </mat-checkbox>
          <button mat-raised-button color="accent" (click)="showHistoryModal()">View History</button>
        </div>
        <br>

        <div fxLayout.gt-xs="row" fxLayout="column" fxLayoutWrap="wrap" class="split-panel">
          <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">

            <mat-form-field appearance="outline" *ngIf="company" #usernameField style="width: 100%">
              <mat-label>{{'Company Name' | translate}}</mat-label>
              <input matInput autofocus [(ngModel)]="model.customer.companyName" name="companyName"
                #companyName="ngModel" (change)="updateCustomer()" class="demo-full-width"
                placeholder="{{'Enter company name' | translate}}" style="width: 100%" [required]="company">

              <mat-error *ngIf="companyName.hasError('required')">
                {{'Company name is' | translate}} <strong>{{'required' | translate}}</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>{{'Email' | translate}}</mat-label>
              <input matInput appInputTrim [(ngModel)]="model.username" name="username"
                (change)="updateCustomer()" #username="ngModel" class="demo-full-width"
                placeholder="{{'Email' | translate}}" style="width: 100%" email>
              <mat-error *ngIf="username.hasError('email')">
                {{'Please enter a valid email address' | translate}}
              </mat-error>
            </mat-form-field>
            <br>
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>{{'First Name' | translate}}</mat-label>
              <input matInput [(ngModel)]="model.firstName" name="firstName" (change)="updateCustomer()"
                #firstName="ngModel" class="demo-full-width" placeholder="{{'First Name' | translate}}"
                style="width: 100%" required>
              <mat-error *ngIf="firstName.hasError('required')">
                {{'First Name is' | translate}} <strong>{{'required' | translate}}</strong>
              </mat-error>
            </mat-form-field>

            <br>

            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>{{'Surname' | translate}}</mat-label>
              <input matInput [(ngModel)]="model.lastName" name="surname" (change)="updateCustomer()"
                #surname="ngModel" class="demo-full-width" placeholder="{{'Surname' | translate}}" style="width: 100%"
                required>
              <mat-error *ngIf="surname.hasError('required')">
                {{'Surname name is' | translate}} <strong>{{'required' | translate}}</strong>
              </mat-error>
            </mat-form-field>

            <!--app-add-edit-flags-panel [linkId]="viewCustomerEmbeddedData.linkId"
              [contextIdDto]="contextIdDto"></app-add-edit-flags-panel-->


          </div>

          <div fxShow.gt-xs="true" fxShow="false" fxFlex.gt-sm="2" fxFlex.gt-xs="2" fxFlex="100">
          </div>

          <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">

            <mat-form-field appearance="outline" *ngIf="company" #usernameField style="width: 100%">
              <mat-label>{{'Company Number' | translate}}</mat-label>
              <input matInput autofocus [(ngModel)]="model.customer.companyNumber" name="companyNumber"
                (change)="updateCustomer()" #companyNumber="ngModel" class="demo-full-width"
                placeholder="{{'Enter company number' | translate}}" style="width: 100%">

            </mat-form-field>

            <app-phone-input name="mobilePhoneNumber" [(ngModel)]="localPhones.mobilePhoneNumberObj.nationalNumber"
              (phoneChanged)="onMobileChange($event)" (countryChanged)="onMobileCountryChange($event)"
              (change)="updateCustomer()" [selectedCountryCode]="localPhones.mobilePhoneNumberObj?.country" label="Mobile"
              placeholder="Mobile" errorName="Mobile" required="false"></app-phone-input>

            <app-phone-input name="phoneNumber" [(ngModel)]="localPhones.phoneNumberObj.nationalNumber"
              (phoneChanged)="onPhoneChanged($event)" (countryChanged)="onPhoneCountryChange($event)"
              (change)="updateCustomer()" [selectedCountryCode]="localPhones.phoneNumberObj?.country" errorName="Phone"
              placeholder="Phone" label="Phone" required="false"></app-phone-input>

            <app-phone-input name="workPhoneNumber" [(ngModel)]="localPhones.workPhoneNumberObj.nationalNumber"
              (phoneChanged)="onWorkPhoneChanged($event)" (countryChanged)="onWorkPhoneCountryChange($event)"
              (change)="updateCustomer()" [selectedCountryCode]="localPhones.workPhoneNumberObj?.country" errorName="Work Phone"
              placeholder="Work Phone" label="Work Phone" required="false"></app-phone-input>

            <!-- <app-phone-input name="whatsAppNumber" [(ngModel)]="localPhones.whatsAppNumberObj.nationalNumber" [selectedCountryCode]="localPhones.whatsAppNumberObj?.countryCode" (intPhoneChanged)="model.whatsAppNumberObj.nationalNumber = $event" (change)="updateCustomer()" errorName="Whats App" placeholder="Whats App Number" label="Customer Whats App" required="false"></app-phone-input> -->

            <mat-form-field [ngClass]="{'notes-field': false}" appearance="outline" class="w-100">
              <mat-label>{{'Notes' | translate}}</mat-label>
              <textarea matInput class="notes" #notes="ngModel" (change)="updateCustomer()" name="notes"
                [(ngModel)]="model.notes" [maxlength]="900" placeholder="{{'Notes' | translate}}"></textarea>
            </mat-form-field>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
<div class="container">
  <div class="row">
    <div class="col">
      <h3>{{'Address Lookup' | translate}}</h3>
    </div>
    <div class="col-auto"></div>
  </div>
  <div fxLayout.gt-xs="row" fxLayout="column" fxLayoutWrap="wrap" class="split-panel" *ngIf="model">
    <div fxFlex.gt-sm="99" fxFlex.gt-xs="99" fxFlex="100">

      <app-address-lookup [contextIdDto]="contextIdDto" [address]="model.customer?.address"
        (modelUpdate)="addressChanged($event)"></app-address-lookup>

    </div>
  </div>
  <div class="mb-2" fxLayout.gt-xs="row" fxLayout="column" fxLayoutWrap="wrap" class="split-panel"
    *ngIf="model">
    <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
      <div class="row">
        <div class="col">
          <h3>{{'Communication' | translate}}</h3>
        </div>
        <div class="col-auto"></div>
      </div>
      <mat-card appearance="outlined" class="m-0">
        <mat-card-content>

          <mat-list *ngIf="model?.optIn">
            <mat-list-item>
              <span fxFlex>{{'Email' | translate}}</span>
              <mat-slide-toggle name="allowEmail" #allowEmail="ngModel" [(ngModel)]="model.optIn.allowEmail"
                (change)="updateCustomer()"></mat-slide-toggle>
            </mat-list-item>

            <mat-list-item>
              <span fxFlex>{{'Sms' | translate}}</span>
              <mat-slide-toggle name="allowSms" #allowSms="ngModel" [(ngModel)]="model.optIn.allowSms"
                (change)="updateCustomer()"></mat-slide-toggle>
            </mat-list-item>

            <!-- <mat-list-item>
              <span fxFlex>{{'WhatsApp' | translate}}</span>
              <mat-slide-toggle name="allowWhatApp" #allowWhatApp="ngModel" [(ngModel)]="model.optIn.allowWhatApp" (change)="updateCustomer()"></mat-slide-toggle>
            </mat-list-item> -->
          </mat-list>
        </mat-card-content>
      </mat-card>
    </div>

    <div fxShow.gt-xs="true" fxShow="false" fxFlex.gt-sm="2" fxFlex.gt-xs="2" fxFlex="100">
    </div>

    <div class="mb-2" fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
      <div class="row">
        <div class="col">
          <h3>{{'Marketing' | translate}}</h3>
        </div>
        <div class="col-auto"></div>
      </div>
      <mat-card appearance="outlined" class="m-0">
        <mat-card-content>
          <mat-list>
            <mat-list-item>
              <span fxFlex>{{'Email' | translate}}</span>
              <mat-slide-toggle name="allowEmailMarketing" #allowEmailMarketing="ngModel"
                [(ngModel)]="model.optIn.allowEmailMarketing" (change)="updateCustomer()"></mat-slide-toggle>
            </mat-list-item>

            <mat-list-item>
              <span fxFlex>{{'Sms' | translate}}</span>
              <mat-slide-toggle name="allowSmsMarketing" #allowSmsMarketing="ngModel"
                [(ngModel)]="model.optIn.allowSmsMarketing" (change)="updateCustomer()"></mat-slide-toggle>
            </mat-list-item>

            <!-- <mat-list-item>
              <span fxFlex>{{'WhatsApp' | translate}}</span>
              <mat-slide-toggle name="allowWhatsAppMarketing" #allowWhatsAppMarketing="ngModel" color="accent" [(ngModel)]="model.optIn.allowWhatsAppMarketing" (change)="updateCustomer()"></mat-slide-toggle>
            </mat-list-item> -->
          </mat-list>
        </mat-card-content>
      </mat-card>

    </div>
  </div>

</div>
