import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContextIdDto } from '@savvy/menu';
import { LookAndFeelSharedService, TimeValue } from 'src/app/flo/shared/services/look-and-feel-shared.service';

@Component({
  selector: 'app-change-slot',
  templateUrl: './change-slot.component.html',
  styleUrls: ['./change-slot.component.scss']
})
export class ChangeSlotComponent implements OnInit {

  contextIdDto: ContextIdDto;
  timeArray: TimeValue[] = [];
  startTime: string;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    private lookAndFeelService: LookAndFeelSharedService,
    @Optional() public dialogRef: MatDialogRef<ChangeSlotComponent>,
  ) { }

  ngOnInit(): void {
    if (this.data.contextIdDto) {
      this.contextIdDto = this.data.contextIdDto;
      this.getLookAndFeelConfig();
    }
  }

  close() {
    this.dialogRef.close();
  }

  changeSlot() {
    this.dialogRef.close(this.startTime);
  }

  getLookAndFeelConfig() {
    const lookAndFeelConfig = this.lookAndFeelService.getLookAndFeelConfig(this.contextIdDto);

    if (lookAndFeelConfig) {
      this.timeArray = lookAndFeelConfig.timeArray;
      if (this.timeArray?.length) {
        // const index = this.timeArray.findIndex(time => time === '09:00');
        // if (index !== -1) {
        //   this.startTime = this.timeArray[index];
        // } else {
        // }
        this.startTime = this.data.selectedSlot ? this.data.selectedSlot : this.timeArray[0].actualValue;
      }
    }
  }

}
