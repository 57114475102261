import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppointmentService, AppointmentServiceRow } from '@savvy/appointment';
import { ContextIdDto } from '@savvy/entity-instance-composite';
import { CustomerSearchResultDto } from '@savvy/search';
import { ConsumerService } from '@savvy/services';
import { Tax } from '@savvy/tax';
import { IdNameTupleDto } from '@savvy/user';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import * as _ from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { AddCustomerComponent } from 'src/app/flo/customer/customer/addCustomer.component';
import { ViewCustomerV2Component } from 'src/app/flo/customer/view-customer-v2/view-customer-v2.component';
import { DateUtils } from 'src/app/flo/dates/DateUtils';
import { StaffSelectorComponent } from 'src/app/flo/shared/components/staff-selector/staff-selector.component';
import { AppointmentConfigHelperService } from 'src/app/flo/shared/services/appointment-config-helper.service';
import { LookAndFeelConfig, LookAndFeelSharedService, TimeValue } from 'src/app/flo/shared/services/look-and-feel-shared.service';
import { OrgUsersHelperService } from 'src/app/flo/shared/services/org-users-helper.service';
import { ServicesHelperService } from 'src/app/flo/shared/services/services-helper.service';
import { TaxesHelperService } from 'src/app/flo/shared/services/taxes-helper.service';
import { UserCountryService } from 'src/app/services/userCountry.service';
import { ServiceSelectorData } from '../../shared/extra-types';
import { CreateAppointmentV2SyncService, PopulateNewCustomerEventData } from '../create-appointment-v2-sync.service';
import { ServiceSelectorV2Component } from './service-selector-v2.component';

@Component({
  selector: 'app-services-selector-v2',
  templateUrl: 'services-selector-v2.component.html',
  styleUrls: ['services-selector-v2.component.scss']
})
export class ServicesSelectorV2Component implements OnInit, OnDestroy {
  @ViewChild('petTable') petTable: DatatableComponent;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @Input() contextIdDto: ContextIdDto;
  @Input() durationRequired = false;
  @Input() disabled = false;
  @Input() allowedToRemove = true;
  @Input() userToUseWhenCreating: IdNameTupleDto;
  @Output() updateCustFlags: EventEmitter<boolean> = new EventEmitter();

  @ViewChild(StaffSelectorComponent, { static: false }) staffSelectorComponent: StaffSelectorComponent;
  @ViewChild(ServiceSelectorV2Component, { static: false }) serviceSelectorPetV2Component: ServiceSelectorV2Component;

  // petServices: Array<PetServicesRequired> = new Array<PetServicesRequired>();
  valid = true;
  lookAndFeelConfig: LookAndFeelConfig;
  timeArray: TimeValue[] = [];
  totalAppointmentDuration = 0;
  // selectedServices = {};
  step = 0;
  subs: Subscription[] = [];

  lastPrice = 0;
  taxDefs: Tax[] = [];

  constructor(
    public deviceService: DeviceDetectorService,
    public userCountryService: UserCountryService,
    private appointmentService: AppointmentService,
    private lookAndFeelService: LookAndFeelSharedService,
    private dateUtils: DateUtils,
    private orgUsersHelperService: OrgUsersHelperService,
    private snackBar: MatSnackBar,
    private servicesHelperService: ServicesHelperService,
    public taxesHelperService: TaxesHelperService,
    public createAppointmentV2SyncService: CreateAppointmentV2SyncService,
    public appointmentConfigHelperService: AppointmentConfigHelperService,
    private dialog: MatDialog,
  ) {

  }

  ngOnInit(): void {
    if (this.contextIdDto) {
      this.taxesHelperService.getTaxDefs(this.contextIdDto).then(res => {
        this.taxDefs = res;
      });
      // listen for search field value changes
      this.lookAndFeelConfig = this.lookAndFeelService.getLookAndFeelConfig(this.contextIdDto);
      if (this.lookAndFeelConfig) {
        this.timeArray = this.lookAndFeelConfig.timeArray;
      } else {
        console.log('no look and feel config');
      }
    }
    this.subs.push(this.createAppointmentV2SyncService.populateNewCustomer$.subscribe((customer: PopulateNewCustomerEventData) => {
      if (customer && customer.populatingAppointment && customer?.customerSearchResultDto?.id) {
        this.servicesHelperService.getConsumerServices(this.contextIdDto).then(consumerServices => {
          // eslint-disable-next-line max-len
          for (const appointmentService of this.createAppointmentV2SyncService.groupedServicesWithCustomerId[customer.customerSearchResultDto.id]) {
            const serviceSelected = consumerServices.find(s => s.id === appointmentService.serviceNumber);


            if (serviceSelected) {
              serviceSelected.durationInMins = appointmentService.duration;
              serviceSelected.unitPrice = appointmentService.unitPrice;
              serviceSelected.tax = appointmentService.tax;
              if (!appointmentService.tax) {
                serviceSelected.taxId = '';
              } else {
                serviceSelected.taxId = this.taxDefs.find(td => td.taxRate === appointmentService.tax)?.id;
              }
            }
            let serviceToBeSelected: ConsumerService = {} as ConsumerService;
            if (serviceSelected) {
              serviceToBeSelected = serviceSelected;
            } else {
              serviceToBeSelected = {
                ownerId: this.contextIdDto.contextId,
                serviceName: appointmentService?.serviceName,
                id: appointmentService.serviceNumber,
                deleted: true
              };
            }
            if (!this.createAppointmentV2SyncService.selectedServices[customer.customerSearchResultDto.id]) {
              this.createAppointmentV2SyncService.selectedServices[customer.customerSearchResultDto.id] = [];
            }
            this.createAppointmentV2SyncService.selectedServices[customer.customerSearchResultDto.id].push(
              _.cloneDeep({
                selectedService: serviceToBeSelected,
                selectedStaff: { id: appointmentService.serviceUser?.id, name: appointmentService.serviceUserFullName },
                grossPrice: appointmentService.grossPrice,
                quantity: appointmentService.quantity,
                subTotal: appointmentService.subTotal,
                startTime: appointmentService.startTime,
                endTime: appointmentService.endTime,
              })
            );

          }
          this.createAppointmentV2SyncService.calculateTotalAmount();
        });
      }
      if (!customer.populatingAppointment) {
        this.calculateEndTime();
      }
    }));

    this.subs.push(this.createAppointmentV2SyncService.customerSelectionChanged$.subscribe((res: CustomerSearchResultDto[]) => {
      if (res.length) {
        for (const customer of this.createAppointmentV2SyncService.customersSelected) {
          if (!this.createAppointmentV2SyncService.selectedServices[customer.id]
            || !this.createAppointmentV2SyncService.selectedServices[customer.id].length) {
            this.addServiceNew(customer);
          }
        }
      }
    }));
  }

  ngOnDestroy(): void {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }

  calculateLocalEndTime(service: ServiceSelectorData) {
    if (service && service.startTime && service?.selectedService?.durationInMins) {
      console.log('6666', service);
      const endTime = this.dateUtils.addMinsToTimeString(service.startTime, service?.selectedService?.durationInMins);
      if (this.timeArray?.length) {
        service.endTime = this.timeArray.find(t => t?.actualValue >= endTime)?.actualValue;
      }
    }
  }

  calculateEndTime(service?: ServiceSelectorData) {
    setTimeout(() => {
      this.calculateLocalEndTime(service);
      this.createAppointmentV2SyncService.calculateStartEndTime();
    }, 10);
  }

  serviceSelected(service: ConsumerService, petId, rowIndex: number, serviceData: ServiceSelectorData) {
    this.createAppointmentV2SyncService.selectedServices[petId][rowIndex].selectedService = _.cloneDeep(service);
    this.createAppointmentV2SyncService.selectedServices[petId][rowIndex].quantity = 1;
    this.createAppointmentV2SyncService.selectedServices[petId][rowIndex].subTotal = service.unitPrice;
    this.createAppointmentV2SyncService.selectedServices[petId][rowIndex].grossPrice = service.unitPrice;
    this.createAppointmentV2SyncService.selectedServices[petId][rowIndex].startTime = serviceData.startTime;
    // eslint-disable-next-line max-len
    console.log('444', service);
    // eslint-disable-next-line max-len
    this.createAppointmentV2SyncService.selectedServices[petId][rowIndex].endTime = this.dateUtils.addMinsToTimeString(serviceData.startTime, service?.durationInMins);
    this.createAppointmentV2SyncService.selectedServices[petId][rowIndex].selectedStaff = this.userToUseWhenCreating;

    this.calculateEndTime(this.createAppointmentV2SyncService.selectedServices[petId][rowIndex]);
    this.updateUnitPrice(serviceData);
    this.checkIfValid();
  }

  staffSelectionChanged(serviceUser: IdNameTupleDto, customerId: string, rowIndex) {
    if (!serviceUser) {
      console.log('No service user availabe', serviceUser);
      return;
    }
    this.createAppointmentV2SyncService.selectedServices[customerId][rowIndex].selectedStaff = serviceUser;
    const groomerExist = this.createAppointmentV2SyncService.selectedServices[customerId]
      .findIndex(g => g.selectedStaff.id === serviceUser.id);
    if (groomerExist > -1 && groomerExist !== rowIndex) {
      const existingGroomerService = this.createAppointmentV2SyncService.selectedServices[customerId][groomerExist];

      this.createAppointmentV2SyncService.selectedServices[customerId][rowIndex].startTime = existingGroomerService.endTime;
      console.log('333', this.createAppointmentV2SyncService.selectedServices[customerId][rowIndex].selectedService);
      this.createAppointmentV2SyncService.selectedServices[customerId][rowIndex].endTime =
        this.dateUtils.addMinsToTimeString(
          this.createAppointmentV2SyncService.selectedServices[customerId][rowIndex].startTime,
          this.createAppointmentV2SyncService.selectedServices[customerId][rowIndex].selectedService.durationInMins);
    }
    this.calculateEndTime(this.createAppointmentV2SyncService.selectedServices[customerId][rowIndex]);
    this.checkIfValid();
  }

  checkIfValid() {
    this.valid = true;
    for (const key in this.createAppointmentV2SyncService.selectedServices) {
      if (Object.prototype.hasOwnProperty.call(this.createAppointmentV2SyncService.selectedServices, key)) {
        const groupedRow = this.createAppointmentV2SyncService.selectedServices[key];
        for (const customer of groupedRow) {
          if (!customer.selectedService || !customer?.selectedStaff?.id) {
            this.valid = false;
          }
        }
      }
    }
  }

  durationInMinsChanged(service: ServiceSelectorData) {
    this.calculateEndTime(service);
  }

  startTimeChanged(startTime: string, service: ServiceSelectorData) {
    this.setEndTimeFromStart(startTime, service);
  }

  setEndTimeFromStart(startTime: string, service: ServiceSelectorData) {
    console.log('got duration in mins ' + service.selectedService);
    service.endTime = this.dateUtils.addMinsToTimeString(startTime, service.selectedService.durationInMins);
    console.log('just set endTime to ' + service.endTime);
    service.selectedService.durationInMins = _.cloneDeep(this.dateUtils.getDurationInMins(startTime, service.endTime));
    this.calculateEndTime(service);
    this.checkIfValid();
  }

  endTimeChanged(endTime: string, service: ServiceSelectorData) {
    if (endTime <= service.startTime) {
      this.snackBar.open('End time should be greater than start time', 'ok', {
        duration: 3000,
      });
    }
    console.log('222', service);
    service.selectedService.durationInMins = _.cloneDeep(this.dateUtils.getDurationInMins(service.startTime, service.endTime));
    this.calculateEndTime(service);
    this.checkIfValid();
  }

  compareTimeFn(user1: string, user2: string) {
    // console.log('comparing');
    return user1 && user2 && user1 === user2;
  }

  toggleExpandGroup(group) {
    console.log('Toggled Expand Group!', group);
    this.petTable.groupHeader.toggleExpandGroup(group);
  }

  getPrevAppointmentServices(customerId: string): Promise<AppointmentServiceRow[]> {
    return new Promise(resolve => {
      this.appointmentService.loadCustomerFuture(
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        customerId
      )
        .subscribe(response => {
          if (response?.contents?.length) {
            if (this.createAppointmentV2SyncService.editMode &&
              this.createAppointmentV2SyncService.appointment.id === response.contents[0].id) {
              resolve([]);
              return;
            }
            this.appointmentService.loadAppointment(
              response.contents[0].id,
              this.contextIdDto.contextId,
              this.contextIdDto.contextIdType).subscribe(res => {
                resolve(res.appointmentServiceRows);
              });
          } else {
            resolve([]);
          }
        }, error => {
          resolve([]);
        });
    });
  }

  async addServiceNew(customer: CustomerSearchResultDto, fresh = false) {
    if (!this.createAppointmentV2SyncService.selectedServices[customer.id]) {
      this.createAppointmentV2SyncService.selectedServices[customer.id] = [];
    }
    if (this.createAppointmentV2SyncService.selectedServices[customer.id].length
      // eslint-disable-next-line max-len
      && !this.isRowValid(this.createAppointmentV2SyncService.selectedServices[customer.id][this.createAppointmentV2SyncService.selectedServices[customer.id].length - 1])) {
      this.snackBar.open('Please select a service first', 'ok', {
        duration: 3000,
      });
      return;
    }
    let selectedService = {} as ConsumerService;
    this.servicesHelperService.getConsumerServices(this.contextIdDto).then(async (consumerServices) => {
      if (consumerServices.length) {
        selectedService = _.cloneDeep(consumerServices[0]);
      }

      const lastIndexOfServices = this.createAppointmentV2SyncService.selectedServices[customer.id].length - 1;
      let startTime = this.createAppointmentV2SyncService.startTime;
      console.log('111', selectedService);
      let endTime = this.dateUtils.addMinsToTimeString(this.createAppointmentV2SyncService.startTime, selectedService.durationInMins);

      let selectedStaff = _.cloneDeep(this.orgUsersHelperService.staffUsers[0]);
      if (this.userToUseWhenCreating) {
        selectedStaff = this.orgUsersHelperService.staffUsers
          .find(u => u.id === this.userToUseWhenCreating.id);
      } else {
        if (this.orgUsersHelperService.staffUsers.length) {
          if (this.orgUsersHelperService.staffUsers[lastIndexOfServices + 1]) {
            selectedStaff = _.cloneDeep(this.orgUsersHelperService.staffUsers[lastIndexOfServices + 1]);

          } else {
            if (lastIndexOfServices > -1) {
              startTime = this.createAppointmentV2SyncService.selectedServices[customer.id][lastIndexOfServices].endTime;
              console.log('1212', selectedService);
              endTime = this.dateUtils.addMinsToTimeString(startTime, selectedService.durationInMins);
            }
          }
        }
      }
      const appointmentServices = await this.getPrevAppointmentServices(customer.id);
      if (this.appointmentConfigHelperService.appointmentConfig.autoPopulateFromPreviousAppointmentEnabled
        && appointmentServices.length && !fresh) {
        for (const appointmentService of appointmentServices) {
          selectedService = consumerServices.find(cs => String(appointmentService.serviceNumber) === String(cs.id));
          if (selectedService) {
            console.log('appointmentService', appointmentService);
            console.log('selectedService', selectedService);
            selectedService.unitPrice = appointmentService.unitPrice;
            selectedStaff = this.orgUsersHelperService.staffUsers
              .find(u => u.id === appointmentService.serviceUser.id);
            selectedService.tax = appointmentService.tax;
            if (!appointmentService.tax) {
              selectedService.taxId = '';
            } else {
              selectedService.taxId = this.taxDefs.find(td => td.taxRate === appointmentService.tax)?.id;
            }
            // if (appointmentService.eiId === customer.id) {
            this.createAppointmentV2SyncService.selectedServices[customer.id].push(
              _.cloneDeep({
                selectedService: _.cloneDeep(selectedService),
                selectedStaff,
                quantity: 1,
                grossPrice: selectedService?.unitPrice,
                subTotal: selectedService?.unitPrice,
                startTime,
                endTime,
              }));
            const lastAddedIndex = this.createAppointmentV2SyncService.selectedServices[customer.id].length - 1;
            this.calculateEndTime(this.createAppointmentV2SyncService.selectedServices[customer.id][lastAddedIndex]);
            this.updateGrossPrice(this.createAppointmentV2SyncService.selectedServices[customer.id][lastAddedIndex]);
            this.checkIfValid();
            // }
          }
        }
      } else {
        this.createAppointmentV2SyncService.selectedServices[customer.id].push(
          _.cloneDeep({
            selectedService: _.cloneDeep(selectedService),
            selectedStaff,
            quantity: 1,
            grossPrice: selectedService?.unitPrice,
            subTotal: selectedService?.unitPrice,
            startTime,
            endTime,
          }));
        const lastAddedIndex = this.createAppointmentV2SyncService.selectedServices[customer.id].length - 1;
        this.calculateEndTime(this.createAppointmentV2SyncService.selectedServices[customer.id][lastAddedIndex]);
        this.updateGrossPrice(this.createAppointmentV2SyncService.selectedServices[customer.id][lastAddedIndex]);
        this.checkIfValid();
      }

    });
  }

  updateUnitPrice(service: ServiceSelectorData) {
    this.updateQty(service.quantity, service);
  }

  updateGrossPrice(service: ServiceSelectorData) {
    if (service?.selectedService?.taxId) {
      const tax = this.taxDefs.find(td => td.id === service.selectedService.taxId);
      if (tax) {
        service.selectedService.tax = tax.taxRate;
        service.grossPrice = Number((service.subTotal + (service.subTotal * ((tax?.taxRate || 0) / 100))).toFixed(2));
      }
    } else {
      service.selectedService.tax = 0;
      service.grossPrice = service.subTotal;
    }
    this.createAppointmentV2SyncService.calculateTotalAmount();
  }

  updateQty(qty, service: ServiceSelectorData) {
    service.subTotal = service.selectedService.unitPrice * qty;
    service.selectedService.durationInMins = service.selectedService.durationInMins * qty;
    this.updateGrossPrice(service);
  }

  isRowValid(service: ServiceSelectorData) {
    return service && service.selectedService && service.selectedService.id && service.selectedStaff.id;
  }

  isGroupValid(group) {
    let valid = false;
    for (const pet of group.value) {
      if (pet['selectedService']) {
        if (this.isRowValid(pet)) {
          valid = true;
        } else {
          valid = false;
        }
      }
    }
    return valid;
  }

  deleteServiceNew(customerId, rowIndex) {
    this.createAppointmentV2SyncService.selectedServices[customerId].splice(rowIndex, 1);
    this.calculateEndTime();
    this.checkIfValid();
  }

  getLastSeenPrice(customer: CustomerSearchResultDto, service: ConsumerService) {
    if (!this.lastPrice) {
      // this.appointmentService.lastPetPrice(this.contextIdDto.contextId, this.contextIdDto.contextIdType,
      //   pet.id, service.id)
      //   .subscribe(res => {
      //     this.lastPrice = res.lastPrice;
      //   });
    }
  }

  openViewCustomerModal(customer: CustomerSearchResultDto) {
    const createPetModal = this.dialog.open(ViewCustomerV2Component, {
      data: {
        contextIdDto: this.contextIdDto,
        customerId: customer.id
      },
      maxWidth: '1024px',
      // maxHeight: '80vh',
      // height: '80vh',
      panelClass: [
        'modal-100',
        'helpwindow',
        'petProfileModal'
      ],
      autoFocus: false
    });

    createPetModal.afterClosed()
      .subscribe(
        () => {
          // if (this.customerStatsComponent) {
          //   this.customerStatsComponent.loadStatus();
          // }
          this.updateCustFlags.emit(true);
        });
  }

  openEditCustomerModal(customer: CustomerSearchResultDto) {
    if (!customer?.id) {
      console.log('No customerId');
      return;
    }
    const createPetModal = this.dialog.open(AddCustomerComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        customerId: customer.id,
        isViewMode: true
      },
      maxWidth: '1024px',
      panelClass: [
        'modal-100',
        'helpwindow',
        'scrollable-modal-2'
      ],
      autoFocus: false,
      disableClose: true
    });

    createPetModal.afterClosed()
      .subscribe(
        () => {
          // if (this.customerStatsComponent) {
          //   this.customerStatsComponent.loadStatus();
          // }
          this.updateCustFlags.emit(true);

        });
  }

}

