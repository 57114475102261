<!-- <facebook-login-button *ngIf="facebookLogin" (socialUser)="getSocialUser($event)"></facebook-login-button> -->

<!--
<facebook-share-button [share]="shareObj" ></facebook-share-button>
-->
<!--
<google-login-button (socialUser)="getSocialUser($event)"></google-login-button>

<linkedin-login-button (socialUser)="getSocialUser($event)"></linkedin-login-button>

<linkedin-share-button></linkedin-share-button>
-->

<button *ngIf="facebookLogin" (click)="signOut()">{{'SOCIAL_LOGOUT' | translate: {Default:'SOCIAL LOGOUT'} }}</button>
