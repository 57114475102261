<h3 mat-dialog-title>{{'RECURRING_APPT' | translate}}</h3>
<mat-dialog-content>
  <form #reccuringForm="ngForm">
    <div style="padding-bottom: 1.34375em; margin: .25em 0;">
      <div style="padding: 1em 0.75em;
                    box-sizing: border-box;
                    ">
        <mat-slide-toggle color="accent" [(ngModel)]="data.fieldRepeatableValue.enableRecurring" name="enableRecurring">
          {{'ENABLE_RECURRING' | translate}}
        </mat-slide-toggle>
      </div>
    </div>
    <div>
      <div fxLayout="row" fxLayoutAlign="start">
        <div fxFlex="50" style="padding-right: 10px">
          <mat-form-field appearance="outline" style="width: 100%"
            [ngClass]="{'mat-form-field-invalid': validateDate(true)}">
            <mat-label>{{'FIRST_APPT_DATE' | translate}}</mat-label>

            <input matInput [(ngModel)]="dateTimeRange" name="start" (focus)="startPicker.open()"
              (click)="startPicker.open()" [selectMode]="'rangeFrom'"
              [disabled]="!data.fieldRepeatableValue.enableRecurring" required #start="ngModel"
              placeholder="{{'FIRST_APPT_DATE' | translate}}" [owlDateTimeTrigger]="startPicker"
              [owlDateTime]="startPicker">
            <mat-icon matSuffix>event</mat-icon>

            <owl-date-time [pickerType]="'calendar'" #startPicker></owl-date-time>
            <mat-error *ngIf="true">
              {{'FIRST_APPT_DATE_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="50">
          <mat-form-field appearance="outline" style="width: 100%"
            [ngClass]="{'mat-form-field-invalid': validateDate(false)}">
            <mat-label>{{'Last Appointment Date' | translate}}</mat-label>

            <input matInput [(ngModel)]="dateTimeRange" name="end" [selectMode]="'rangeTo'"
              [disabled]="!data.fieldRepeatableValue.enableRecurring" (focus)="endPicker.open()"
              (click)="endPicker.open()" required #end="ngModel" placeholder="{{'Last Appointment Date' | translate}}"
              [owlDateTimeTrigger]="endPicker" [owlDateTime]="endPicker">
            <mat-icon matSuffix>event</mat-icon>

            <owl-date-time [pickerType]="'calendar'" #endPicker></owl-date-time>
            <mat-error *ngIf="validateDate(false)">
              {{'Last Appointment Date date is' | translate}} <strong>{{'required' | translate}}</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div>
        {{'REPEAT_EVERY' | translate}}:
      </div>
      <div fxLayout="row" fxLayoutAlign="start">
        <div fxFlex="33" style="padding-right: 10px">
          <mat-form-field appearance="outline" style="width: 100%;">
            <mat-select name="repeatEvery" required [disabled]="!data.fieldRepeatableValue.enableRecurring"
              [compareWith]="matSelectCompageFn" placeholder="6" #repeatEvery="ngModel"
              [(ngModel)]="series.repeatEvery">
              <mat-option *ngFor="let repeatEveryOption of repeatEveryOpts"
                [value]="repeatEveryOption.value">{{repeatEveryOption.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex="33" style="padding-right: 10px">
          <mat-form-field appearance="outline" style="width: 100%;">
            <mat-select required name="repeatEveryPeriod" [disabled]="!data.fieldRepeatableValue.enableRecurring"
              [compareWith]="matSelectCompageFn" placeholder="{{'WEEKS' | translate}}" #repeatEveryPeriod="ngModel"
              [(ngModel)]="series.repeatEveryPeriod">
              <mat-option *ngFor="let repeatEveryPeriodOption of repeatEveryPeriodOpts"
                [value]="repeatEveryPeriodOption.value">{{repeatEveryPeriodOption.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex="33">
          <mat-form-field appearance="outline" style="width: 100%;">
            <mat-select name="numRepeats" required [disabled]="!data.fieldRepeatableValue.enableRecurring"
              [compareWith]="matSelectCompageFn" placeholder="23{{'TIMES' | translate}}" #numRepeats="ngModel"
              [(ngModel)]="series.numRepeats">
              <mat-option *ngFor="let numRepeatsOption of numRepeatsOpts"
                [value]="numRepeatsOption.value">{{numRepeatsOption.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start">
        <div fxFlex="100">
          <mat-error *ngIf="isInvalid('numRepeats') || isInvalid('repeatEveryPeriod') || isInvalid('repeatEvery')">
            {{'REPEAT_EVERY_FIELDS_ARE' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
          </mat-error>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>
<div mat-dialog-actions style="width: 100%">
  <button mat-raised-button color="accent" [disabled]="reccuringForm.invalid || isInvalid('numRepeats') || isInvalid('repeatEveryPeriod') || isInvalid('repeatEvery') ||
           validateDate(true) || !dateTimeRange[0]" (click)="save()">{{'SAVE' | translate}}</button>
  &nbsp;
  <button mat-raised-button (click)="cancel()">{{'CANCEL' | translate}}</button>
</div>