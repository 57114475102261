<mat-card appearance="outlined">

    <hr>
    <mat-card-content>

        <app-element
                [elementDto]="sectionHeaderDefinitionDto.headerLine"
                [form]="form"
                [contextIdDto]="contextIdDto"
                [eventBus]="eventBus"
                [viewContext]="viewContext"
                [additionalDataMapDto]="additionalDataMapDto"
                [showPlaceholder]="showPlaceholder"
                [changeListener]="changeListener"
        >

        </app-element>

        <hr>

        <app-element *ngIf="sectionHeaderDefinitionDto.subHeaderLine"
                     [elementDto]="sectionHeaderDefinitionDto.subHeaderLine"
                     [form]="form"
                     [contextIdDto]="contextIdDto"
                     [eventBus]="eventBus"
                     [viewContext]="viewContext"
                     [additionalDataMapDto]="additionalDataMapDto"
                     [showPlaceholder]="showPlaceholder"
                     [changeListener]="changeListener"
        >

        </app-element>


    </mat-card-content>

</mat-card>
