<div *ngIf="labelValueLeftRightPanelDto">
  <div fxLayout="row" fxLayout.lt-md="column" *ngFor="let labelValueRowDto of labelValueLeftRightPanelDto.labelValueRowDtos">
    <div fxFlex="50" fxLayout="column">
      <div fxFlex="100" *ngIf="labelValueRowDto.left">
        <div fxFlex="40">
          <p class="mat-text-muted mt-xs mb-xs">{{labelValueRowDto.left.label}}</p>
        </div>
        <div fxFlex="60">
          <p class="mt-xs mb-xs">{{labelValueRowDto.left.value}}</p>
        </div>
      </div>
      <div fxFlex="100" *ngIf="!labelValueRowDto.left">
        <div fxFlex="40">
          <p class="mat-text-muted mt-xs mb-xs">&nbsp;</p>
        </div>
        <div fxFlex="60">
          <p class="mt-xs mb-xs">&nbsp;</p>
        </div>
      </div>
    </div>
    <div fxFlex="50" fxLayout="column">
      <div fxFlex="100" *ngIf="labelValueRowDto.right">
        <div fxFlex="40">
          <p class="mat-text-muted mt-xs mb-xs">{{labelValueRowDto.right.label}}</p>
        </div>
        <div fxFlex="60">
          <p class="mt-xs mb-xs">{{labelValueRowDto.right.value}}</p>
        </div>
      </div>
      <div fxFlex="100" *ngIf="!labelValueRowDto.right">
        <div fxFlex="40">
          <p class="mat-text-muted mt-xs mb-xs">&nbsp;</p>
        </div>
        <div fxFlex="60">
          <p class="mt-xs mb-xs">&nbsp;</p>
        </div>
      </div>
    </div>
  </div>
</div>

