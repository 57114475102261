import {AfterContentChecked, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EventBusService} from './EventBusService';
import {ActivatedRoute, Router} from '@angular/router';
import {ChangeListener} from './changeListener';
import {ElementControlService} from './ElementControlService';
import {UntypedFormGroup} from '@angular/forms';
import {InvoicecompService, UpdateInvoiceComp} from '@savvy/invoice';
import {Invoice} from '@savvy/invoice';
import { ContextIdDto, InvoiceDefinition, InvoiceServiceRow, UserDto, UserId } from '@savvy/view-definition';
import {ConsumerService} from "@savvy/services";
import {Product} from "@savvy/products";

@Component({
  selector: 'app-invoice-single-services-panel',
  templateUrl: 'invoiceSingleServicesPanel.component.html'
})
export class InvoiceSingleServicesPanelComponent implements OnInit, AfterContentChecked {

  @Input() invoice: Invoice;
  @Input() userDtos: Array<UserDto>;
  @Input() invoiceDefinition: InvoiceDefinition;
  @Input() consumerServices: Array<ConsumerService>;
  @Input() products: Array<Product>;
  @Input() currencyCode: string;
  @Input() allowMultipleServices: boolean;

  @Input() viewContext: any;
  @Input() contextIdDto: ContextIdDto;
  @Input() additionalDataMapDto: any;
  @Input() eventBus: EventBusService;
  @Input() form: UntypedFormGroup;
  @Input() changeListener: ChangeListener;


  @Output() taskSelected = new EventEmitter();
  @Output() taskDeselected = new EventEmitter();

  init = false;
  valid = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    // private userCompApi: UserCompositeApiExtension,
    private ecs: ElementControlService,
    private invoicecompService: InvoicecompService) {
  }

  ngOnInit(): void {
    if (this.invoice) {
      if (!this.invoice.invoiceServiceRows || this.invoice.invoiceServiceRows.length === 0) {
        this.createServiceInvoiceRow();
      }
    } else {
      console.log('No invoice');
    }
  }

  ngAfterContentChecked(): void {
    this.init = true;
  }

  setValid() {
    console.log('setting valid in single service panel');
    if (this.init) {
      this.form.controls['invoiceServicePanel'].setErrors({incorrect: !this.isValid()});

      if (this.valid) {
        this.form.controls['invoiceServicePanel'].setValue('abc');
      } else {
        this.form.controls['invoiceServicePanel'].setValue('');
      }
    }
  }

  isValid() {
    this.valid = true;
    this.invoice.invoiceServiceRows.forEach(
      row => {
        /*if (this.invoiceDefinition.enableServiceRowUser && !row.serviceUser) {
          console.log('serviceUser is invalid');
          this.valid = false;
        }*/
        if (!row.serviceNumber) {
          console.log('serviceNumber is invalid');
          this.valid = false;
        }
        /*if (this.invoiceDefinition.enableStartTimeServiceRow && !row.startDate) {
          console.log('startDate is invalid');
          this.valid = false;
        }
        if (this.invoiceDefinition.enableStartTimeServiceRow && !row.startTime) {
          console.log('startTime is invalid');
          this.valid = false;
        }
        if (this.invoiceDefinition.enableStartTimeServiceRow && !row.endDate) {
          console.log('endDate is invalid');
          this.valid = false;
        }
        if (this.invoiceDefinition.enableStartTimeServiceRow && !row.endTime) {
          console.log('endTime is invalid');
          this.valid = false;
        }
        if (this.invoiceDefinition.serviceRowLinkedEntity  && !row.entityInstanceId) {
          console.log('entityInstanceId is invalid');
          this.valid = false;
        }*/
      }
    );
    console.log('issp:returning valid ' + this.valid);
    return this.valid;
  }

  createServiceInvoiceRow() {
    console.log('creating row');
    const invoiceRow: InvoiceServiceRow = <InvoiceServiceRow>{};
    if (!this.invoice.invoiceServiceRows) {
      console.log('creating rows array');
      this.invoice.invoiceServiceRows = new Array(0);
    }
    console.log('adding service row 130');
    this.invoice.invoiceServiceRows.push(invoiceRow);
    console.log('rows now ' + this.invoice.invoiceServiceRows.length);
    this.updateInvoice();
  }

  deleteServiceRow(invoiceRow: InvoiceServiceRow) {

    this.invoice.invoiceServiceRows.forEach( (item, index) => {
      if (item === invoiceRow) {
        this.invoice.invoiceServiceRows.splice(index, 1 );
      }
    });
    this.updateInvoice();
  }

  serviceChanged() {
    console.log('service changes');
    if (this.invoice.invoiceServiceRows) {
      this.invoice.invoiceServiceRows.forEach(row => {
        console.log('got row ' + row);
        if (row.serviceNumber) {
          console.log('Service number is ' + row.serviceNumber);
          const serviceDto = this.getService(row.serviceNumber);
          if (serviceDto) {
            row.unitPrice = serviceDto.unitPrice;
            row.tax = serviceDto.tax;
          } else {
            console.log('failed to get service');
          }
          if (!row.quantity) {
            row.quantity = 1;
          }
        }
      });
    }
    this.updateInvoice();
  }
  getService(serviceId: string): ConsumerService {

    let retConsumerService: ConsumerService;
    this.consumerServices.forEach(serviceDto => {

      if (serviceDto.id === serviceId) {
        console.log('found match!');
        retConsumerService = serviceDto;
        return serviceDto;
      } else {
        console.log('no match');
      }
    });
    return retConsumerService;
  }

  updateInvoice() {

    const req: UpdateInvoiceComp = <UpdateInvoiceComp>{};
    req.contextIdDto = this.contextIdDto;
    req.invoice = this.invoice;
    req.entityInstanceId = this.viewContext.entityInstanceId;

    this.invoicecompService.updateInvoiceComp(req).subscribe( response => {
      // @ts-ignore
      this.invoice = response.invoice;
      // @ts-ignore
      this.ecs.handleChangedElements(response.changedElementList, this.form);
      this.setValid();
      // this.changeListener.add('change');
      // this.eventBus.addInvoiceEvent(EventType.INVOICE_UPDATED, this.invoice.invoiceId);
    });
  }

  setUserOptVal(user: UserDto): UserId {
    return {id: user.id, userContextType: UserId.UserContextTypeEnum.UserId};
  }

  compareUserFn(user1: UserDto,  user2: UserDto) {
    return user1 && user2 && user1.id === user2.id;
  }
}

