import {Component, Input} from '@angular/core';
import {ContextIdDto} from '@savvy/view-definition';
import {EventBusService} from '../element/EventBusService';
import {EventType} from '../event/UiEvent';

@Component({
    selector: 'app-zero-tasks',
    templateUrl: 'zeroTasks.component.html'
})
export class ZeroTasksComponent {

    @Input() contextIdDto: ContextIdDto;
    @Input() eventBus: EventBusService;
    create() {
        console.log('inside create of zero tasks');
        this.eventBus.addEvent(EventType.CREATE_TASK);
    }
}


