<mat-toolbar class="pt-1 mat-toolbar helptitle" style="background:#F7F7F7">
  <div fxLayout="row" class="w-100">
    <div fxFlex>
      <h5 class="mb-0">{{'GENERATED_DRAFT_INVOICE' | translate}}</h5>
    </div>
    <div fxFlex></div>
    <div fxFlex="nogrow">
      <button mat-icon-button (click)="close()"><mat-icon>close</mat-icon></button>
    </div>
  </div>
</mat-toolbar>
<div class="mdc-p-3 " style="background:#F7F7F7">
  <app-view-customer-invoice *ngIf="boardingBooking && invoice" [boardingBooking]="boardingBooking" [contextIdDto]="data.contextIdDto" [invoiceId]="invoice.invoiceId?.id" [viewOnly]="true" (invoiceUpdated)="invoice = $event" (editAppointment)="edit()">
  </app-view-customer-invoice>
  <div fxLayout="flex wrap" fxLayoutAlign=" center">
    <div fxFlex="nowrap">
      <button mat-raised-button color="accent" (click)="generateInvoice()">{{'CONFIRM' | translate}}</button>
    </div>
    <div fxFlex></div>
  </div>
</div>