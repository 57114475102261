<div class="customers-email-modal customers-email-modal-mobile">
    <mat-toolbar color="primary">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span>{{'SEND_TO_ALL' | translate}}</span>
            <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
    <div class="m-0 p-0" *ngIf="!sending">
        <ng-wizard #nzWiard [config]="config" (stepChanged)="stepChanged($event)">

            <ng-wizard-step [title]="'STEP1' | translate" [description]="'CONFIG' | translate">
                <app-send-to-all-message-configuration [recipientType]="recipientType" #messageConfiguration></app-send-to-all-message-configuration>
            </ng-wizard-step>

            <ng-wizard-step [title]="'STEP2' | translate" [description]="'EMAIL' | translate">
                <app-send-to-all-message-email #messageEmailConfiguration></app-send-to-all-message-email>
            </ng-wizard-step>

            <ng-wizard-step [title]="'STEP3' | translate" [description]="'SMS' | translate">
                <app-send-to-all-message-sms #messageSmsConfiguration></app-send-to-all-message-sms>
            </ng-wizard-step>

            <ng-wizard-step [title]="'STEP4' | translate" [description]="'FINISH' | translate">
                <app-send-to-all-message-preview #messagePreview></app-send-to-all-message-preview>
            </ng-wizard-step>

        </ng-wizard>
    </div>
    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="center center">
        <button mat-raised-button color="accent" class="mr-xs" *ngIf="!firstStep" (click)="showPreviousStep()">{{'PREVIOUS' | translate}}</button>
        <div fxFlex></div>

        <button mat-raised-button color="accent" *ngIf="!lastStep" (click)="showNextStep()">{{'NEXT' |
            translate}}</button>
        <button mat-raised-button color="accent" *ngIf="lastStep" (click)="sendMessage()">{{'SEND' |
            translate}}</button>
    </div>
</div>