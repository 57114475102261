import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TipsHelperService {


  public updateViewTips: Subject<any> = new Subject();

  constructor() { }

  updateTips() {
    this.updateViewTips.next(true);
  }

}
