import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

import {
  FlagDefService,
  ContextIdDto,
  FlagDefinition,
  ListFlagDefsResponse
} from '@savvy/flags';
import { EmailRecipient } from '@savvy/messaging';


@Component({
  selector: 'app-add-edit-contract-flags-panel',
  templateUrl: 'addEditContractFlagsPanel.component.html',
  styleUrls: ['addEditContractFlagsPanel.component.scss']
})
export class AddEditContractFlagsPanelComponent implements OnInit, OnChanges {

  @Input() recipientType: string;
  @Input() contextIdDto: ContextIdDto;
  @Input() selectedFlags: FlagDefinition[] = [];
  @Input() include = true;

  flagDefinitions: Array<FlagDefinition>;

  @Output() addFlagEvt: EventEmitter<FlagDefinition> = new EventEmitter();
  @Output() removeFlagEvt: EventEmitter<number> = new EventEmitter();

  constructor(private flagDefApi: FlagDefService) {
  }

  ngOnInit(): void {
    this.listFlagDefs();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.linkId?.currentValue !== changes?.linkId?.previousValue) {
      this.listFlagDefs();
    }
  }

  listFlagDefs() {
    this.flagDefApi.listFlagDefsByType(this.getFlagDefType(), this.contextIdDto.contextId, this.contextIdDto.contextIdType)
      .subscribe((res: ListFlagDefsResponse) => {
        this.flagDefinitions = [...res.flagDefinitions];
      });
  }

  getFlagDefType(): string {
    if (this.recipientType === EmailRecipient.RecipientTypeEnum.Customer) {
      return FlagDefinition.FlagDefinitionTypeEnum.Customer;
    } else if (this.recipientType === EmailRecipient.RecipientTypeEnum.Student) {
      return FlagDefinition.FlagDefinitionTypeEnum.Student;
    } else if (this.recipientType === EmailRecipient.RecipientTypeEnum.Lead) {
      return FlagDefinition.FlagDefinitionTypeEnum.Lead;
    } else {
      return FlagDefinition.FlagDefinitionTypeEnum.Customer;
    }
  }

  addFlag(flagDef: FlagDefinition) {
    this.addFlagEvt.emit(flagDef);
  }

  removeFlag(index: number) {
    this.removeFlagEvt.emit(index);
  }
}

