import {NgModule, Pipe, PipeTransform} from "@angular/core";

@Pipe({name: "timeToDate"})
export class TimeToDate implements PipeTransform {
  transform(time: string): Date {
    return new Date("1970-01-01T"+time);
  }
}

@NgModule({
  imports: [],
  declarations: [TimeToDate],
  exports: [TimeToDate]
})
export class TimeToDateModule {

}
