import {Component, Inject, ViewEncapsulation} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import {SubscriptionDialogComponent} from '../subscription/subscriptionDialogComponent.component';

@Component({
  selector: 'app-trial-expired-popup',
  templateUrl: './trial-expired-popup.component.html',
  styles: [
    `
           .wide-snack-bar {
             max-width: 800px;
           }
           @media (max-width: 739px) {
             .mobile-view-subscription-dialog .mat-mdc-dialog-container {
               padding: 5px 10px;
             }
             .mobile-view-subscription-dialog {
               width: calc(100% - 40px) !important;
               max-width: initial !important;
             }
           }
         `
  ],
  encapsulation: ViewEncapsulation.None
})
export class TrialExpiredPopupComponent {

  constructor(
    public matSnackBarRef: MatSnackBarRef<TrialExpiredPopupComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public dialog: MatDialog
  ) {

  }
  dismiss() {
    this.matSnackBarRef.dismiss();
  }

  onUpdatePlan() {
    const dialogRef = this.dialog.open(SubscriptionDialogComponent, {
      width: '90%',
      height: '90%',
      panelClass: 'mobile-view-subscription-dialog',
      data: {contextIdDto: this.data.contextIdDto}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
    this.matSnackBarRef.dismiss();
  }
}
