import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ContextIdDto } from '@savvy/app';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FloSnackbarComponent } from 'src/app/flo/snackbar/floSnackbar.component';
import { AddNewPetV2Component } from '../add-new-pet-v2/add-new-pet-v2.component';

@Component({
  selector: 'app-create-pet-existing-customer',
  templateUrl: './create-pet-existing-customer.component.html',
  styleUrls: ['./create-pet-existing-customer.component.scss']
})
export class CreatePetExistingCustomerComponent implements OnInit {
  @ViewChild(AddNewPetV2Component, { static: false }) addNewPetV2Component: AddNewPetV2Component;

  @Input() contextIdDto: ContextIdDto;
  @Input() customerId: string;
  @Input() customerName: string;
  @Input() maxPets = 1;
  @Output() addNewCustomerEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() petCreated = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();

  constructor(
    private notify: FloSnackbarComponent,
    public deviceService: DeviceDetectorService,

  ) { }

  ngOnInit() {
  }

  customerChanged(event) {
    if (event) {
      this.customerId = event;
    }
  }

  addNewCustomer() {
    this.addNewCustomerEvent.emit(true);
  }

  cancel() {
    this.cancelEvent.emit();
  }

  savePet() {
    if (!this.customerId || !this.addNewPetV2Component.petFormGroup.valid) {
      this.notify.message = 'Please provide mandatory values';
      this.notify.open();
      return;
    }

    this.addNewPetV2Component.customerId = this.customerId;
    this.addNewPetV2Component.createPet().subscribe(res => {
      if (res) {
        this.petCreated.emit(res);
      }
    });

  }

}
