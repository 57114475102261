import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ChangeListener } from './changeListener';
import { EventBusService } from './EventBusService';
import { ViewContextDto } from '@savvy/view-composite';
import { ContextIdDto, ViewPetAppointmentData } from '@savvy/view-definition';
import { AppointmentDto, AppointmentService, LoadAppointmentsByLinkId, LoadAppointmentsByLinkIds } from '@savvy/appointment';
import { ViewInvoiceDialogComponent } from './viewInvoiceDialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-view-pet-appointments',
  templateUrl: 'viewPetAppointments.component.html'
})
export class ViewPetAppointmentsComponent implements OnInit {
  @Input() viewPetAppointmentData: ViewPetAppointmentData;
  @Input() contextIdDto: ContextIdDto;
  @Input() viewContext: ViewContextDto;
  @Input() form: UntypedFormGroup;
  @Input() eventBus: EventBusService;
  @Input() additionalDataMapDto: any;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;


  pastAppointments: Array<AppointmentDto>;
  futureAppointments: Array<AppointmentDto>;

  constructor(private appointmentService: AppointmentService, public dialog: MatDialog) { }



  ngOnInit() {
    this.load();
  }


  load() {
    console.log('pet ids are ', this.viewPetAppointmentData.petIds);

    const req: LoadAppointmentsByLinkIds = {};
    req.contextIdDto = this.contextIdDto;
    req.pageNum = 1;
    req.pageSize = 10;
    req.linkIds = this.viewPetAppointmentData.petIds as any;

    this.appointmentService.loadFutureAppointmentsByLinkIds(req).subscribe(response => {
      console.log('got ', response);
      this.futureAppointments = response.appointments.contents;
    });

    this.appointmentService.loadPastAppointmentsByLinkIds(req).subscribe(response => {
      console.log('got ', response);
      this.pastAppointments = response.appointments.contents;
    });
  }

  viewAppointment(appointmentData: AppointmentDto) {

    console.log('inside view appointment ', appointmentData);
    const dialogRef = this.dialog.open(ViewInvoiceDialogComponent, {
      width: '90%',
      height: '90%',
      data: {
        appointmentId: appointmentData.appointment.entityInstanceId,
        contextIdDto: this.contextIdDto
      }
    });

    dialogRef.afterClosed().subscribe((result) => {

    });
  }
}

