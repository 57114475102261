import { AfterViewInit, Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ContextIdDto, IdNameTupleDto } from '@savvy/entity-instance-composite';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { CustomerCompositeService, CustomerService } from '@savvy/customer';
import { EventEmitter } from '@angular/core';
import { UserDto, UserId } from '@savvy/view-definition';
import { UserCountryService } from '../../../services/userCountry.service';
import { Subject } from 'rxjs';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'app-existing-customer-selector',
  templateUrl: 'existingCustomerSelector.component.html'
})
export class ExistingCustomerSelectorComponent implements OnInit, AfterViewInit {
  @ViewChild('searchInput') searchInput: MatInput;
  @Input() label = 'Customer Name';
  @Input() contextIdDto: ContextIdDto;
  @Input() showPlaceholder: boolean;
  @Input() autoFocus = true;
  @Input() readonly = false;
  @Input() maxWidth = '50';
  @Input() required = false;

  @Output() selectedCustomer = new EventEmitter();
  @Output() newExistingSwitch = new EventEmitter();

  customers: IdNameTupleDto[];
  subject = new Subject();
  private customerLocal = null;

  constructor(
    private api: CustomerCompositeService,
    private customerService: CustomerService,
    // private customerHandlerService: CustomerHandlerService,
    public userCountryService: UserCountryService
  ) { }

  get customerId() {
    return this.customerLocal;
  }
  @Input() set customerId(value: string) {
    if (value) {
      this.loadCustomer(value);
    }
  }
  ngOnInit(): void {
  }

  ngAfterViewInit() {
    if (this.autoFocus) {
      setTimeout(() => {
        this.searchInput.focus();
      }, 500);
    }
  }

  handler(event: MatAutocompleteSelectedEvent): void {
    console.log('calling set value NOW');
    // this.customer = event.option.value;
    this.selectedCustomer.emit(event.option.value);
  }

  loadCustomer(customerId) {
    this.customerService.loadCustomer(
      customerId,
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
    ).subscribe(response => {
      if (response) {
        this.searchInput.value = `${response.firstName} ${response.lastName}`;
      }
    });
  }

  loadTypeahead(searchString: string) {

    console.log('env id is ' + this.contextIdDto.contextId);

    if (searchString && searchString.length > 0) {
      this.api.searchCustomers(
        searchString,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType
      ).subscribe(response => {
        this.handleResponse(response);
      });
    }
  }

  onKey(event: any) { // without type info
    console.log('event is ' + event);
    console.log('event.target is ' + event.target);
    console.log('value is ' + event.target.value);
    //console.log(this.form.controls[this.elementInstanceDto.instanceId].value);
    // this.form.controls[this.elementInstanceDto.instanceId].setErrors({ 'doesNotSelected': true });
    this.loadTypeahead(event.target.value);
  }

  search(event, cell, rowIndex) {
    const searchText = event.target.value;
    // emits the `searchText` into the stream.
    // This will cause the operators in its pipe function (defined in the ngOnInit method)
    // to be run. `debounce` runs and then `map`.
    // If the time interval of 1 sec in debounce hasn't elapsed, map will not be called, thereby saving the server from being called.
    this.subject.next({ searchText, cell, rowIndex });
  }


  displayFn(id) {
    if (!id) {
      return '';
    }
    const index = this.customers.findIndex(state => state.id === id);
    if (index === -1) {
      console.log('id is ' + id + ' index is' + index + ' this should never happen as tuples should always be there first');
      return '';
    } else {
      return this.customers[index].name;
    }
  }

  newCustomer() {
    this.newExistingSwitch.emit('switch');
  }

  setUserOptVal(user: UserDto): UserId {
    return { id: user.id, userContextType: UserId.UserContextTypeEnum.UserId };
  }

  compareUserFn(user1: UserDto, user2: UserDto) {
    return user1 && user2 && user1.id === user2.id;
  }

  viewCustomer() {
    // this.customerHandlerService.view(this.contextIdDto, this.customerId);
  }

  private handleResponse(response) {
    console.log('setting customers to response ' + response);
    this.customers = response;
    console.log('got customers ' + response.length);
  }
}

