<app-show-task [contextIdDto]="contextIdDto" [eventBus]="eventBus"
    [visible]="activePanel === 'showTask'"></app-show-task>
<app-show-reminder [contextIdDto]="contextIdDto" [eventBus]="eventBus"
    [visible]="activePanel === 'showReminder'"></app-show-reminder>
<app-add-edit-task [contextIdDto]="contextIdDto" [eventBus]="eventBus"
    [visible]="activePanel === 'addEditTask'"></app-add-edit-task>
<app-view-message-panel [contextIdDto]="contextIdDto" [visible]="activePanel === 'viewMessage'"
    [eventBus]="eventBus"></app-view-message-panel>
<app-view-nested-entity [contextIdDto]="contextIdDto" [eventBus]="eventBus"
    [visible]="activePanel === 'entity'"></app-view-nested-entity>
<app-send-questionnaire [contextIdDto]="contextIdDto" [viewContextDto]="viewContextDto" [eventBus]="eventBus"
    [visible]="activePanel === 'sendQuestionnaire'"></app-send-questionnaire>