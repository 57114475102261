import {Component, Inject, Input, OnInit, Optional, ViewChild} from '@angular/core';
import { ContextIdDto, LinkId } from '@savvy/view-definition';
import { SendCustomerContractComponent } from '../sendCustomerContract/sendCustomerContract.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddContractModalComponent } from '../add-contract-modal/add-contract-modal.component';
import { LinkedIdTypeEnum } from 'src/app/core/data-share.service';
import { ActivatedRoute } from "@angular/router";
import {ContractTemplate, ContractTemplateCompositeService, ContractTemplateService} from "@savvy/contract";
import {FloSnackbarComponent} from "../../snackbar/floSnackbar.component";
import {ListContractsComponent} from "../list-contracts/listContracts.component";

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['contracts.component.scss']
})
export class ContractsComponent implements OnInit {
  @ViewChild('listSignedContracts') listSignedContractsComponent: ListContractsComponent;
  @ViewChild('listUnsignedContracts') listUnsignedContractsComponent: ListContractsComponent;

  @Input() adminMenu = false;
  contextIdDto: ContextIdDto;
  isModal = false;
  templates: ContractTemplate[] = [];
  resendingContracts = false;

  private sub: any;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private notify: FloSnackbarComponent,
    private templateService: ContractTemplateService,
    private templateCompositeService: ContractTemplateCompositeService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data?: any,
    @Optional() public dialogRef?: MatDialogRef<ContractsComponent>) {
    if (this.data) {
      this.isModal = this.data.isModal;
    }
  }

  ngOnInit() {
    if (this.isModal) {
      this.contextIdDto = this.data.contextIdDto;
    } else {
      this.sub = this.route.parent.params.subscribe(params => {
        if (params['contextId'] && params['contextIdType']) {
          this.contextIdDto = this.contextIdDto ? this.contextIdDto : {
            contextIdType: params['contextIdType'],
            contextId: params['contextId']
          };
        }
      });

      this.sub = this.route.params.subscribe(params => {
        if (params['contextId'] && params['contextIdType']) {
          this.contextIdDto = this.contextIdDto ? this.contextIdDto : {
            contextIdType: params['contextIdType'],
            contextId: params['contextId']
          };
        }
      });
    }

    this.templateService.listContractTemplates(this.contextIdDto.contextId).subscribe(
      response => { this.templates = response },
      error => { console.log("Error getting contract templates.") }
    )
  }

  addContract() {
    const linkId = {} as LinkId;
    linkId.linkedIdType = LinkedIdTypeEnum.CustomerId;
    const dialogRef = this.dialog.open(AddContractModalComponent, {
      panelClass: ['helpwindow', 'scrollable-modal'],
      data: {
        linkId,
        contextIdDto: this.contextIdDto,
        isContractModal: true
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });

  }

  sendContract() {
    const dialogRef = this.dialog.open(SendCustomerContractComponent, {
      panelClass: ['helpwindow', 'scrollable-modal-2'],
      data: {
        contextIdDto: this.contextIdDto
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.listSignedContractsComponent.loadContracts();
      this.listUnsignedContractsComponent.loadContracts();
    });
  }

  sendToAllUnsigned(contractTemplate: ContractTemplate) {
    this.notify.message = "Your contracts are now being sent. This may take a few minutes.";
    this.notify.open();
    this.resendingContracts = true;
    console.log("Resending all unsigned contracts with template ", contractTemplate.name);
    this.templateCompositeService.resendContractsWithTemplate({
      contextIdDto: this.contextIdDto,
      contractTemplateId: contractTemplate.id
    }).subscribe(
      response => {
        this.notify.message = response.contractIds.length + " Contracts Successfully Resent!";
        this.notify.open();
        this.resendingContracts = false;
      },
      error => {
        this.notify.message = "There was an error resending the contracts.";
        this.notify.open();
        this.resendingContracts = false;
      }
    )
  }
}
