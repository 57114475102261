<div fxLayout="row" fxLayoutAlign=" center" class="mdc-mb-1" *ngIf="!hideHeader">
  <div fxFlex>
    <h6 class="mb-0 ">{{'FILES' | translate}}</h6>
  </div>
  <div fxFlex="nogrow" *ngIf="!isView">
    <div fxLayout="row" fxLayoutAlign=" center">
      <ngx-file-drop dropZoneLabel="{{'DROP_FILES_HERE' | translate}}" (onFileDrop)="droppedFiles($event)">
        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
          <div fxLayout="column" fxLayoutAlign="space-around center" (click)="openFileSelector()">
            <button mat-button color="accent">
              <mat-icon class="vm">add</mat-icon>
              {{'ADD_FILE' | translate}}
            </button>
          </div>
        </ng-template>
      </ngx-file-drop>
    </div>
  </div>
</div>
<form #importForm="ngForm" fxLayoutAlign="space-between center">
  <input [hidden]="true" id="input-file-id-field" multiple required (change)="saveFiles($event)" type="file">
</form>
<mat-list *ngIf="files?.length; else elseBlock" class="mdc-p-0">
  <mat-list-item *ngFor="let file of files">
    <div fxLayout="row wrap" fxLayoutAlign=" center" fxLayoutGap="16px" class="mdc-py-2 w-100">
      <div fxFlex="calc(100% - 64px)">
        <h6 class="mb-0 text-truncated">{{file?.fileName}}</h6>
      </div>
      <div fxFlex="nogrow">
        <button (click)="removeFile(i)" class="remove-file" mat-icon-button *ngIf="!isView">
          <mat-icon role="img" aria-label="delete">close</mat-icon>
        </button>
        <button (click)="downloadFile(file)" mat-icon-button>
          <mat-icon role="img" aria-label="delete">get_app</mat-icon>
        </button>
      </div>
    </div>
  </mat-list-item>
</mat-list>
<ng-template #elseBlock>
  <div class="flexbox-parent">
    <div fxLayout="row" fxLayoutAlign="center center">
      <div style="text-align:center;">
        <div class="avatar avatar-100 circle border-3 mb-2 d-inline-block mt-15px">
          <mat-icon class="h1 h-auto w-auto">cloud_upload</mat-icon>
        </div>
        <h6 class="mb-2">{{'NO_FILES_UPLOADED' | translate}}.</h6>
      </div>
    </div>
  </div>
</ng-template>