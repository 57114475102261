<mat-form-field appearance="outline" class="w-100">
  <mat-label>{{'PET_BREED' | translate}}</mat-label>
  <mat-select placeholder="{{'SEARCH_PET_BREED' | translate}}" [formControl]="selectedPetBreed" name="selectedPetType">
    <mat-option>
      <ngx-mat-select-search [placeholderLabel]="'SEARCH_TYPES' | translate" [formControl]="petBreedFilter">
      </ngx-mat-select-search>
    </mat-option>
    <mat-option>
    </mat-option>
    <mat-option *ngFor="let breed of filteredPetBreeds | async" [value]="breed.name">
      {{ breed.label }}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="selectedPetBreed?.errors && (selectedPetBreed.touched || selectedPetBreed.dirty)">{{'SELECT_PET_BREED' | translate}}</mat-error>
</mat-form-field>
