<form class="text-center">
  <p class="mdc-mb-0">{{'YOU_HAVE_SELECTED' | translate: {Default: 'You have selected:'} }}<span class="highlight-message">{{createPetAppointmentV2SyncService.petsSelected.length}} {{'PET' | translate: {Default: 'pet'} }}<span *ngIf="createPetAppointmentV2SyncService.petsSelected.length > 1">s</span></span> {{'WITH' | translate: {Default: 'with'} }}
    <span class="highlight-message">{{createPetAppointmentV2SyncService.totalServices}} {{'SERVICE' | translate: {Default: 'service'} }}<span *ngIf="createPetAppointmentV2SyncService.totalServices > 1">s</span></span>
    <span *ngIf="pickupRequired">{{'WITH_HAVING_PICKUP_SERVICE' | translate: {Default: 'with having pickup service'} }}</span> {{'ON' | translate: {Default: 'on'} }}
    <span class="highlight-message">
      {{createPetAppointmentV2SyncService.startDate | localizedDate: 'shortDate'}} {{'AT' | translate: {Default: 'at'} }}
      {{createPetAppointmentV2SyncService.displayStartTime | localizedDate:'shortTime'}}.
    </span>
  </p>
  <h4 class="mdc-mb-0">{{'TOTAL_PAYABLE_AMOUNT_IS' | translate: {Default: 'Total payment amount is:'} }} {{createPetAppointmentV2SyncService.totalAmount | currency:currencyCode:'symbol'}}</h4>
  <p>
    <span class="mx-1 small">
      {{'DEPOSIT_PAYABLE_IS' | translate: {Default: 'Deposit Payable is'} }}
      <span class="mat-text-green">
        {{createPetAppointmentV2SyncService.totalAmountDue | currency:currencyCode:'symbol'}}
      </span>
    </span>
  </p>

</form>