<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span *ngIf="!data.edit">
      {{'CREATE_SERVICE' | translate}}
    </span>
    <span *ngIf="data.edit">
      {{'EDIT_SERVICE' | translate}}
    </span>
    <span class="spacer"></span>

  </mat-toolbar-row>
</mat-toolbar>
<div class="mdc-p-3 ">
  <form (ngSubmit)="add()" #createForm="ngForm">
    <div fxLayout="column">
      <mat-form-field fxFlex="100" appearance="outline">
        <mat-label>{{'SERVICE_NAME' | translate}}</mat-label>
        <input matInput placeholder="{{'SERVICE_NAME' | translate}}" name="serviceName"
          [(ngModel)]="service.serviceName" #serviceName="ngModel" required>
      </mat-form-field>
      <mat-form-field fxFlex="100" appearance="outline" *ngIf="!data?.hideGroups">
        <mat-label>{{'GROUP_NAME' | translate}}</mat-label>
        <mat-select [(ngModel)]="service.groupName" name="groupName" #groupName="ngModel"
          placeholder="{{'GROUP_NAME' | translate}}">
          <mat-option value="" disabled>
            <button mat-flat-button color="accent" class="mt-1 mdc-mb-1" (click)="addNew()">{{'CREATE_NEW' |
              translate}}</button>
          </mat-option>
          <mat-option *ngFor="let serviceGroup of serviceGroups" [value]="serviceGroup.name">
            {{serviceGroup.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- <mat-label fxFlex="15">{{service.groupName}}</mat-label> -->
      <div fxLayout="row" fxLayoutGap="5px">
        <mat-form-field fxFlex="33" appearance="outline">
          <mat-label>{{'DURATION_IN_MINS' | translate}}</mat-label>
          <input matInput placeholder="{{'DURATION_IN_MINS' | translate}}" type="text" appNumberOnly
            name="durationInMins" #durationInMins="ngModel" [(ngModel)]="service.durationInMins" required>
        </mat-form-field>

        <mat-form-field fxFlex="33" appearance="outline">
          <mat-label>{{'UNIT_PRICE' | translate}}</mat-label>
          <input matInput type="text" (change)="refreshPriceChangeImpact(true)" appNumberOnly [decimalPoints]="2"
            placeholder="{{'UNIT_PRICE' | translate}}" name="unitPrice" #unitPrice="ngModel"
            [(ngModel)]="service.unitPrice" required>
        </mat-form-field>

        <mat-form-field fxFlex="33" appearance="outline">
          <mat-label>{{'POSITION' | translate}}</mat-label>
          <input matInput type="text" appNumberOnly [decimalPoints]="0" placeholder="{{'POSITION' | translate}}"
            name="position" #position="ngModel" [(ngModel)]="service.position" required>
        </mat-form-field>
      </div>



      <mat-form-field fxFlex="100" appearance="outline">
        <mat-label>{{'TAX' | translate}}</mat-label>
        <mat-select placeholder="{{'TAX' | translate}}" [(ngModel)]="service.taxId" name="taxId" #taxId
          (selectionChange)="updateTax($event, 'taxId')">
          <mat-option value="" selected>{{'NO_TAX' | translate}}</mat-option>
          <mat-option *ngFor="let tax of taxDefs" [value]="tax.id">
            {{ tax.taxName }} ( {{ tax.taxRate }} %)
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-card appearance="outlined" *ngIf="priceChangeImpactDto" class="no-shadow border mx-0 mdc-mb-3"
        style="box-shadow: none !important">
        <mat-card-content>
          <div fxLayout="row wrap" fxLayoutGap="16px" fxLayoutAlign=" center">
            <div fxFlex="55%">
              <h4 class="mdc-mb-0"><b>{{ priceChangeImpactDto.totalAppointmentsChanged }}</b> <small> {{'FUTURE_APPTS' |
                  translate}}</small></h4>
              <p class="mat-text-secondary small">{{'AFFECTED_SERVICE_PRICE_CHANGE' | translate}}</p>
            </div>
            <div fxFlex="">
              <div fxLayout="row wrap" fxLayoutGap="16px" fxLayoutAlign=" center" class="mdc-mb-1">
                <div fxFlex="">
                  <h4 class="mdc-mb-0">{{ priceChangeImpactDto.confirmedAppointments }}</h4>
                  <p class="mat-text-secondary small">{{'CONFIRMED_APPTS' | translate}}</p>
                </div>
                <div fxFlex="">
                  <p class="mat-text-secondary small">&nbsp;
                    <!--Invoice Not sent ?? What does this mean?-->
                  </p>
                </div>
              </div>
              <div fxLayout="row wrap" fxLayoutGap="16px" fxLayoutAlign=" center">
              </div>

            </div>
          </div>

          <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign=" center"
            class="mdc-py-2 mdc-mb-2 border-top border-bottom mdc-px-2"
            style="background: #F9FDFC; margin-left:-16px; margin-right:-16px;" *ngIf="unitPriceUpdated">
            <div fxFlex="">
              <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign=" center">
                <div fxFlex="nogrow">
                  <h2 class="mat-text-theme mdc-mb-0"><mat-icon class="vm"
                      style="font-size: 36px; height: 40px; width: 40px; text-align: center;">data_exploration</mat-icon>
                  </h2>
                </div>
                <div fxFlex="">
                  <h5 class="mdc-mb-0"><b>{{ ((priceChangeImpactDto.newPrice -
                      priceChangeImpactDto.originalPrice))/priceChangeImpactDto.originalPrice | percent }}</b> <small>
                      {{'PRICE_INCREASED' | translate}}</small></h5>
                  <p class="mat-text-secondary small mdc-mb-0">{{'YOU_WILL_EARN' | translate}} <b>{{
                      priceChangeImpactDto.revenueIncrease | currency }}</b> {{'MORE' | translate}}</p>
                </div>
              </div>
            </div>
            <div fxFlex="nogrow">
              <h5 class="mdc-mb-0">{{ priceChangeImpactDto.originalPriceTotalRevenue | currency }}</h5>
              <p class="mat-text-secondary small mdc-mb-0">{{'TOTAL_AT' | translate}} <span class="mat-text-red">{{
                  priceChangeImpactDto.originalPrice | currency }}</span></p>
            </div>
            <div fxFlex="nogrow">
              <h5 class="mdc-mb-0">{{ priceChangeImpactDto.newPriceTotalRevenue | currency }}</h5>
              <p class="mat-text-secondary small mdc-mb-0">{{'NEW_TOTAL_AT' | translate}} <span
                  class="mat-text-green">{{ priceChangeImpactDto.newPrice | currency }}</span></p>
            </div>
          </div>

          <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign=" center" class="mdc-mb-1">
            <div fxFlex="">
              <mat-checkbox name="updateFutureAppointmentsField" #updateFutureAppointmentsField="ngModel"
                [(ngModel)]="updateFutureAppointments" (change)="updateFutureAppointmentsChange()">
                <p class="mb-0 mx-2" style="line-height: 18px;">
                  {{'UPDATE_FUTURE_APPTS' | translate}}<br>
                  <span class="mat-text-secondary small mb-0">{{'DATE_TO_UPDATE_SERVICE_PRICE' | translate}}</span>
                </p>
              </mat-checkbox>
            </div>
            <div fxFlex="nogrow">
              <input matInput [matDatepicker]="picker" [disabled]="!updateFutureAppointments" name="fromDateField"
                #fromDateField="ngModel" [(ngModel)]="fromDate" ngDefaultControl
                (ngModelChange)="refreshPriceChangeImpact(false)" class="d-inline-block vm"
                style="vertical-align: middle; width:auto; max-width:100px; border:0px; font-size: 18px">
              <mat-datepicker-toggle matIconSuffix [for]="picker" class="vm"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <mat-form-field fxFlex="100" appearance="outline">
        <mat-label>{{'DESCRIPTION' | translate}}</mat-label>
        <textarea matInput placeholder="{{'DESCRIPTION' | translate}}" type="text" name="description"
          #description="ngModel" [(ngModel)]="service.description" rows="4"></textarea>
      </mat-form-field>

      <mat-form-field fxFlex="100" appearance="outline">
        <mat-label>{{'COLOUR' | translate}}</mat-label>
        <input matInput type="text" placeholder="{{'COLOUR' | translate}}" name="color" [value]="service.colour"
          [(ngModel)]="service.colour" #colour="ngModel" [style.background]="service.colour"
          [(colorPicker)]="service.colour" [cpUseRootViewContainer]="true" [cpSaveClickOutside]="true"
          [cpOKButtonClass]="'mat-raised-button mat-accent'" (colorPickerSelect)="updateColor($event, 'colour')">
      </mat-form-field>

      <div fxFlex="nogrow">
        <mat-label>{{'CUSTOMISE_SERVICE' | translate}}</mat-label>
        <mat-checkbox [(ngModel)]="service.serviceRestricted" #serviceRestrictedField="ngModel" name="serviceRestrictedField" (change)="serviceRestrictedChange($event)"></mat-checkbox>

      </div>
      <br/>

    </div>
    <!-- TODO: If pet spa or groom school -->
    <div fxLayout="row wrap" *ngIf="service.serviceRestricted">

      <div fxFlex="100" fxFlex.gt-xs="32" class="field-center">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'BREED' | translate: {Default: 'Breed'} }}</mat-label>
          <mat-select placeholder="{{'SELECT_BREED' | translate: {Default: 'Select Breed'} }}" multiple
                      [(ngModel)]="service.serviceRestriction.petServiceRestriction.breedIds"
                      #breedIdsField="ngModel" name="breedIdsField">
            <mat-option>
              <ngx-mat-select-search [placeholderLabel]="'SEARCH_BREED' | translate: {Default: 'Search Breed'} ">
              </ngx-mat-select-search>
            </mat-option>
            <mat-option [value]="'-1'">
              <button mat-flat-button color="accent" type="button" (click)="addNewBreed()">
                {{'ADD_NEW' | translate: {Default: 'Add New'} }}
              </button>
            </mat-option>
            <mat-option *ngFor="let breed of filteredBreeds | async" [value]="breed.name">
              {{ breed.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row wrap">
      <div fxFlex>
        <button mat-raised-button [mat-dialog-close]="false">{{'CANCEL' | translate}}</button>
      </div>
      <div fxFlex="nogrow">
        <button mat-raised-button [disabled]="!createForm.valid" type="submit" class="mat-accent">
          <span *ngIf="!data.edit">{{'ADD_SERVICE' | translate}}</span>
          <span *ngIf="data.edit">{{'UPDATE_SERVICE' | translate}}</span>
        </button>
      </div>
    </div>
  </form>
</div>
