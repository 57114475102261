import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LookAndFeelSharedService, TimeValue } from "../shared/services/look-and-feel-shared.service";
import { TimeUtils } from "../dates/TimeUtils";
import { DateUtils } from '../dates/DateUtils';

@Component({
  selector: 'app-time-select',
  templateUrl: 'time-select.component.html',
  styleUrls: ['time-select.component.scss']
})

export class TimeSelectComponent implements OnInit {
  private _date: Date;
  public get date(): Date {
    return this._date;
  }
  @Input()
  public set date(value: Date) {
    this._date = value;
    this.updateSelectedTime();
  }
  @Input() interval: number = 15;
  @Input() placeholder: string = "";
  @Input() required: boolean;
  @Output() dateChange: EventEmitter<Date> = new EventEmitter();

  timeArray: TimeValue[] = [];
  selectedTime: string;

  constructor(
    private lookAndFeelService: LookAndFeelSharedService,
    private timeUtils: TimeUtils,
    private dateUtils: DateUtils
  ) { }

  ngOnInit() {
    this.timeArray = this.lookAndFeelService.generateLocalisedTimeArray(this.interval, "");
    this.updateSelectedTime();
  }

  onSelect(value: string) {
    if (!this.date) {
      this.date = new Date();
    }
    let date = this.dateUtils.getCombinedDateObject(this.dateUtils.getDateAsStringV2(this.date), value);
    this.date = date;
    this.updateSelectedTime();
    this.dateChange.emit(date);
  }

  updateSelectedTime() {
    if (this.date) {
      this.timeUtils.roundMinutesToInterval(this.date, this.interval);
      this.selectedTime = this.date.getHours().toString().padStart(2, "0")
        + ":"
        + this.date.getMinutes().toString().padStart(2, "0");
    }
  }
}
