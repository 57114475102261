import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';
import { ContextIdDto, FlagDefinition, FlagDefService, UpdateFlagDef } from '@savvy/flags';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

import { DeleteFlagConfirmationModalComponent } from '../deleteFlagConfirmationModal/deleteFlagConfirmationModal.component';
import {FlagDefHelperService} from "../../../services/flagDefHelper.service";

@Component({
  selector: 'app-flag-defs-by-type',
  templateUrl: 'flagDefsByType.component.html',
  styleUrls: ['flagDefsByType.component.scss']
})
export class FlagDefsByTypeComponent implements OnInit {
  @Input() flagDefinitionType: string;
  flagDefinitions: FlagDefinition[];
  archivedFlagDefinitions: FlagDefinition[] = [];
  selected: FlagDefinition[] = [];
  flagDefinition: FlagDefinition;
  contextIdDto: ContextIdDto;
  sub: any;
  showArchived = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private api: FlagDefService,
    private flagDefHelperService: FlagDefHelperService,
    private notify: FloSnackbarComponent
  ) {
  }

  ngOnInit() {
    this.sub = this.route.parent.params.subscribe(params => {
      this.contextIdDto = <ContextIdDto>{};
      this.contextIdDto.contextId = params['contextId'];
      this.contextIdDto.contextIdType = params['contextIdType'];
      if (this.flagDefinitionType) {
        this.load();
      }
    });
  }

  editFlag(flag) {
    this.router.navigate(['editFlagDef', flag.id], { relativeTo: this.route });
  }

  create() {
    this.router.navigate(['addFlagDef', this.flagDefinitionType], { relativeTo: this.route });
  }

  archiveFlag(flag: FlagDefinition) {
    flag.deleted = true;
    const updateFlagDef: UpdateFlagDef = {
      contextIdDto: this.contextIdDto,
      flagDefinition: flag
    };
    this.api.updateFlagDef(updateFlagDef).subscribe(response => {
      this.updateCache();
      this.notify.message = 'Successfully archived flag \'' + response.name + '\'!';
      this.notify.open();
      this.load();
    }, error => {
      this.notify.message = 'There was an error archiving the flag.';
      this.notify.open();
    });
  }

  unarchiveFlag(flag: FlagDefinition) {
    flag.deleted = false;
    const updateFlagDef: UpdateFlagDef = {
      contextIdDto: this.contextIdDto,
      flagDefinition: flag
    };
    this.api.updateFlagDef(updateFlagDef).subscribe(response => {
      this.updateCache();
      this.notify.message = 'Successfully un-archived flag \'' + response.name + '\'!';
      this.notify.open();
      this.load();
    }, error => {
      this.notify.message = 'There was an error archiving the flag.';
      this.notify.open();
    });
  }

  deleteFlagDialog(flag: FlagDefinition) {
    const dialogRef = this.dialog.open(DeleteFlagConfirmationModalComponent, {
      data: { flag }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'archive') {
        this.archiveFlag(flag);
      } else if (result === 'delete') {
        this.deleteFlag(flag);
      }
      console.log(result);
    });
  }

  deleteFlag(flag: FlagDefinition) {
    this.api.deleteFlagDef(
      flag.flagDefinitionId.id,
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
    ).subscribe(response => {

      this.updateCache();
      this.notify.message = 'Flag successfully deleted.';
      this.notify.open();
      this.load();
    }, error => {
      this.notify.message = 'There was an error deleting the flag.';
      this.notify.open();
    });
  }

  private load() {
    this.api.listFlagDefsByType(
      this.flagDefinitionType,
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType).subscribe(response => {
        this.flagDefinitions = [];
        this.archivedFlagDefinitions = [];
        for (const flag of response.flagDefinitions) {
          if (flag.deleted) {
            this.archivedFlagDefinitions.push(flag);
          } else {
            this.flagDefinitions.push(flag);
          }
        }
      });
  }

  updateCache() {
    this.api.listFlagDefs(this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(res => {
      if (res.flagDefinitions) {
        this.flagDefHelperService.setFlagDefs(res.flagDefinitions);
      }
    });
  }
}
