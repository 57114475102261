import { Injectable } from '@angular/core';

@Injectable()
export class LocaleService {

  language: string;
  countryCode: string;

  constructor(
  ) { }


  countryCodeChanged(countryCode: string) {
    this.countryCode = countryCode;
  }

  languageChanged(language: string) {
    if (!language) {
      this.language = 'en';
    } else {
      this.language = language;
    }
  }

  setLocale(language: string, countryCode: string) {
    //alert('setting to language ' + language + ' and country ' + countryCode);

    this.languageChanged(language);
    this.countryCodeChanged(countryCode);
  }

  getLocale() {
    if (!this.language && !this.countryCode) {
      return 'en-GB';
    } else if (!this.language) {
      return 'en-' + this.countryCode;
    } else if (!this.countryCode) {
      return this.language + '-GB';
    } else {
      return this.language + '-' + this.countryCode;
    }
  }

}
