<ngx-datatable *ngIf="invoicePanelDataDto && invoicePanelDataDto.invoiceId && invoice" class="material" [rows]="invoice.invoiceServiceRows" [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'">

  <ngx-datatable-column name="{{'Ei' | translate}}" [flexGrow]="3" *ngIf="userDtos && invoiceDefinition && invoiceDefinition.serviceRowLinkedEntity">
    <ng-template ngx-datatable-cell-template let-row="row">
      <app-ei-selector [contextIdDto]="contextIdDto" [entityDefinitionId]="invoiceDefinition.serviceRowLinkedEntity" [entityDefinitionLabel]="invoiceDefinition?.serviceRowLinkedEntityLabel" [entityInstanceId]="row.entityInstanceId" (eiChanged)="eiChanged(row, $event)">

      </app-ei-selector>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="{{'User' | translate}}" [flexGrow]="3" *ngIf="userDtos && invoiceDefinition && invoiceDefinition.enableServiceRowUser">
    <ng-template ngx-datatable-cell-template let-row="row">
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>{{'User' | translate}}</mat-label>
        <mat-select placeholder="{{'Select User' | translate}}" [compareWith]="compareUserFn" [(ngModel)]="row.serviceUser" required (selectionChange)="serviceUserChanged()" name="serviceUserField" #serviceUserField="ngModel">
          <mat-option *ngFor="let serviceUser of userDtos" [value]="serviceUser.userId">
            {{ serviceUser.fullName }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="serviceUserField.hasError('required')">
          {{'Service User is' | translate}} <strong>{{'required' | translate}}</strong>
        </mat-error>
      </mat-form-field>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="{{'Service' | translate}}" [flexGrow]="3" *ngIf="consumerServices">
    <ng-template ngx-datatable-cell-template let-row="row">
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>{{'Service' | translate}}</mat-label>
        <mat-select placeholder="{{'Select Service' | translate}}" [(ngModel)]="row.serviceNumber" required (selectionChange)="serviceChanged()" name="service" #service="ngModel">
          <mat-option *ngFor="let service of consumerServices" [value]="service.id">
            {{ service?.serviceName }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="service.hasError('required')">
          {{'Service is' | translate}} <strong>{{'required' | translate}}</strong>
        </mat-error>
      </mat-form-field>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="{{'Price' | translate}}" [flexGrow]="1">
    <ng-template ngx-datatable-cell-template let-row="row">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'Price' | translate}}</mat-label>
        <input matInput type="text" appNumberOnly [decimalPoints]="2" placeholder="Unit Price" name="unitPrice" #unitPrice="ngModel" (change)="updateInvoice()" [(ngModel)]="row.unitPrice" id="unitPrice" required>
        <mat-error *ngIf="unitPrice.hasError('required')">
          {{'Price is' | translate}} <strong>{{'required' | translate}}</strong>
        </mat-error>
      </mat-form-field>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{'Tax Rate' | translate}}" [flexGrow]="0.6">
    <ng-template ngx-datatable-cell-template let-row="row">
      <strong>{{row.tax}}</strong>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{'Quantity' | translate}}" [flexGrow]="1">
    <ng-template ngx-datatable-cell-template let-row="row">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'Quantity' | translate}}</mat-label>
        <input matInput type="text" appNumberOnly placeholder="Quantity" name="quantity" #quantity="ngModel" (change)="updateInvoice()" [(ngModel)]="row.quantity" id="quantity" required>
        <mat-error *ngIf="quantity.hasError('required')">
          {{'Quantity is' | translate}} <strong>{{'required' | translate}}</strong>
        </mat-error>
      </mat-form-field>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{'Amount' | translate}}" [flexGrow]="1">
    <ng-template ngx-datatable-cell-template let-row="row">
      <strong>{{ row.grossPrice | currency:currencyCode:'symbol'}}</strong>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{'Edit' | translate}}" [flexGrow]="1">
    <ng-template ngx-datatable-header-template>
      <button mat-mini-fab color="accent" style="top: -10px !important;" type="button" (click)="createServiceInvoiceRow()">
        <mat-icon>add</mat-icon>
      </button>
    </ng-template>
    <ng-template ngx-datatable-cell-template let-row="row">
      <button (click)="deleteServiceRow(row)" class="ml-xs mat-icon-button" mat-icon-button="">
        <span class="mat-button-wrapper">
          <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">delete</mat-icon>
        </span>
        <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
        <div class="mat-button-focus-overlay"></div>
      </button>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>

<hr>


<ngx-datatable *ngIf="invoicePanelDataDto && invoicePanelDataDto.invoiceId && invoice" class="material" [rows]="invoice.invoiceProductRows" [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'">

  <ngx-datatable-column name="{{'Product' | translate}}" [flexGrow]="3" *ngIf="products">
    <ng-template ngx-datatable-cell-template let-row="row">
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>{{'Product' | translate}}</mat-label>
        <mat-select placeholder="{{'Select Product' | translate}}" [(ngModel)]="row.productNumber" required (selectionChange)="productChanged()" name="product" #product="ngModel">
          <mat-option *ngFor="let product of products" [value]="product.id">
            {{ product.productName }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="product.hasError('required')">
          {{'Service is' | translate}} <strong>{{'required' | translate}}</strong>
        </mat-error>
      </mat-form-field>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="{{'Unit Price' | translate}}" [flexGrow]="1">
    <ng-template ngx-datatable-cell-template let-row="row">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'Unit Price' | translate}}</mat-label>
        <input matInput type="text" appNumberOnly [decimalPoints]="2" placeholder="Unit Price" name="unitPrice" #unitPrice="ngModel" (change)="updateInvoice()" [(ngModel)]="row.unitPrice" id="productUnitPrice" required>
        <mat-error *ngIf="unitPrice.hasError('required')">
          {{'Unit Price is' | translate}} <strong>{{'required' | translate}}</strong>
        </mat-error>
      </mat-form-field>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{'Tax Rate' | translate}}" [flexGrow]="1">
    <ng-template ngx-datatable-cell-template let-row="row">
      <strong>{{row.tax}}</strong>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{'Quantity' | translate}}" [flexGrow]="1">
    <ng-template ngx-datatable-cell-template let-row="row">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'Quantity' | translate}}</mat-label>
        <input matInput type="text" appNumberOnly placeholder="Quantity" name="quantity" #quantity="ngModel" (change)="updateInvoice()" [(ngModel)]="row.quantity" id="productQuantity" required>
        <mat-error *ngIf="quantity.hasError('required')">
          {{'Quantity is' | translate}} <strong>{{'required' | translate}}</strong>
        </mat-error>
      </mat-form-field>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{'Amount' | translate}}" [flexGrow]="1">
    <ng-template ngx-datatable-cell-template let-row="row">
      <strong>{{ row.grossPrice | currency:currencyCode:'symbol'}}</strong>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{'Edit' | translate}}" [flexGrow]="1">
    <ng-template ngx-datatable-header-template>
      <button mat-mini-fab color="accent" style="top: -10px !important;" type="button" (click)="createProductInvoiceRow()">
        <mat-icon>add</mat-icon>
      </button>
    </ng-template>
    <ng-template ngx-datatable-cell-template let-row="row">
      <button (click)="deleteProductRow(row)" class="ml-xs mat-icon-button" mat-icon-button="">
        <span class="mat-button-wrapper">
          <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">delete</mat-icon>
        </span>
        <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
        <div class="mat-button-focus-overlay"></div>
      </button>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>

<hr>
<mat-card appearance="outlined">
  <mat-card-content *ngIf="invoice" class="py-1">
    <div fxLayout="row" fxLayoutWrap="wrap">
      <div fxFlex.gt-sm="25" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
        <h6 class="text-uppercase text-md ma-0 text-bold">{{'Subtotal' | translate}}</h6>
        <h1 class="ma-0">{{invoice.subTotal | currency:currencyCode:'symbol'}}</h1>
      </div>
      <div fxFlex.gt-sm="25" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
        <h6 class="text-uppercase text-md ma-0 text-bold">{{'Tax' | translate}}</h6>
        <h1 class="ma-0">{{invoice.tax | currency:currencyCode:'symbol'}}</h1>
      </div>
      <div fxFlex.gt-sm="25" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
        <h6 class="text-uppercase text-md ma-0 text-bold">{{'Discount' | translate}}</h6>
        <h1 class="ma-0">{{0.00|currency:currencyCode:'symbol'}}</h1>
      </div>
      <div fxFlex.gt-sm="25" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1 text-xs-left text-sm-right">
        <h6 class="text-uppercase text-sm ma-0 text-bold">{{'Total' | translate}}</h6>
        <h1 class="ma-0 mat-text-accent">{{ invoice.total |
          currency:currencyCode:'symbol'}}</h1>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<app-view-payments *ngIf="invoice && invoice.invoiceId" [contextIdDto]="contextIdDto" (invoiceUpdated)="loadInvoice()" [visible]="true" [invoice]="invoice"></app-view-payments>

<app-view-tips *ngIf="invoice && invoice.invoiceId" [contextIdDto]="contextIdDto" (invoiceUpdated)="this.invoice = $event" [visible]="true" [invoice]="invoice"></app-view-tips>