import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ChangeListener } from './changeListener';
import { TrialExpiredService } from '../shared/services/trial-expired.service';
import { PhoneNumberHelperService } from '../shared/services/phone-number-helper.service';
import { MatDialog } from '@angular/material/dialog';
import { ClearObservable } from '../shared/classes/clear-observable';
import { takeUntil } from 'rxjs/operators';
import { ContractCompService, ContractUiDto, LoadContractsResponse } from '@savvy/contract';
import { HttpErrorResponse } from '@angular/common/http';
import { AddContractModalComponent } from '../contract/add-contract-modal/add-contract-modal.component';
import { ViewContractModalComponent } from '../contract/view-contract-modal/view-contract-modal.component';
import { ContextIdDto } from '@savvy/ui';
import { LinkedIdTypeEnum, LinkId } from 'src/app/core/data-share.service';

@Component({
  selector: 'app-view-customer-contracts',
  templateUrl: 'viewCustomerContracts.component.html'
})
export class ViewCustomerContractsComponent extends ClearObservable implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() customerId: string;
  @Input() form: UntypedFormGroup;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;

  contractUiDtoList: Array<ContractUiDto>;

  constructor(
    private router: Router,
    public trialExpiredService: TrialExpiredService,
    private sharedService: PhoneNumberHelperService,
    private dialog: MatDialog,
    private contractApi: ContractCompService) {
    super();
  }

  ngOnInit() {
    this.loadContractLists();
  }


  create() {

    this.openCreateContractModal();
  }

  rowSelected(contractId: string) {
    //  if (this.listContractListDto.useModal) {
    console.log('TODO: Use modal here');
    this.openViewContractModal(contractId);
    /*  } else {
        this.sharedService.viewEntityModalSub.next(true);
        console.log('inside gotoEntity contractId ' + contractId);
        this.router.navigate([
          '/contract/viewContract',
          this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          contractId
        ]);
      }*/
  }

  openCreateContractModal() {
    console.log(this);

    const linkId = <LinkId>{};
    linkId.linkedIdType = LinkedIdTypeEnum.CustomerId;
    linkId.linkedId = this.customerId;

    const createContractModal = this.dialog.open(AddContractModalComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        linkId,
        isContractModal: true
      },
      maxWidth: '1024px',
      panelClass: ['helpwindow', 'scrollable-modal'],
      autoFocus: false
    });

    createContractModal.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        res => {
          if (res) {
            this.loadContractLists();
          }
        });
  }

  openViewContractModal(contractId) {
    const createContractModal = this.dialog.open(ViewContractModalComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        contractId,
        isContractModal: true
      },
      maxWidth: '1024px',
      maxHeight: '80vh',
      height: '80vh',
      panelClass: [
        'modal-100',
        'scrollable-modal'
      ],
      autoFocus: false
    });

    createContractModal.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.loadContractLists();
        });
  }

  loadContractLists() {
    if (!this.customerId) {
      console.error('No customer id to loadContractsForCustomer');
      return;
    }
    this.contractApi.loadContractsForCustomer(this.customerId)
      // .pipe(takeUntil(this.destroy$))
      .subscribe((response: LoadContractsResponse) => {
        this.contractUiDtoList = response.contractUiDtoList;
      }, (err: HttpErrorResponse) => {
        console.log('error while loadContractLists', err);
      });
  }
}

