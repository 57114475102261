<div
  *ngIf="showTextbox && !replyDone"
  style="width: 100%;">
  <textarea style="width: 100%;" [(ngModel)]="replyMesssage"></textarea>
</div>

<button *ngIf="notification.customerId" (click)="viewCustomer(notification.customerId)" mat-button color="accent"
        class="px-1">
  View Customer
</button>

<ng-container>
  <ng-container *ngIf="!replyDone && !error">
    <button mat-button color="accent" *ngIf="!showTextbox" class="px-1"
            (click)="reply(notification)">
      <mat-icon class="mr-5px icon-18 vm">done</mat-icon>
      <span>{{'REPLY' | translate}}</span>
    </button>
    <button mat-button color="accent" *ngIf="showTextbox" class="px-1"
            (click)="sendSavvyMessage(notification)">
      <mat-icon class="mr-5px icon-18 vm">done</mat-icon>
      <span>{{'SEND' | translate}}</span>
    </button>
  </ng-container>
  <label *ngIf="error" style="color: red;">{{'CANNOT_REPLY_FROM_HERE' | translate}}</label>
  <label *ngIf="replyDone" style="color: green;">
    <mat-icon class="mr-5px icon-18 vm">done</mat-icon>
    {{'REPLIED' | translate}}!
  </label>
</ng-container>
