import { Component, OnInit } from '@angular/core';
import { ContextIdDto } from '@savvy/view-definition';
import { ActivatedRoute } from '@angular/router';
import { EventBusService } from '../element/EventBusService';
import { Location } from '@angular/common';

@Component({
  selector: 'app-view-invoice-page',
  templateUrl: './viewInvoicePage.component.html'
})
export class ViewInvoicePageComponent implements OnInit {

  contextIdDto: ContextIdDto;
  invoiceId: string;
  eventBus = new EventBusService();
  invoicePaymentOwnerId = '';
  invoicePaymentOwnerType = '';
  private sub: any;
  constructor(private route: ActivatedRoute,
    private location: Location) {
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.contextIdDto = <ContextIdDto>{};
      this.contextIdDto.contextId = params['contextId'];
      this.contextIdDto.contextIdType = params['contextIdType'];
      this.invoiceId = params['invoiceId'];
      this.invoicePaymentOwnerId = params.invoicePaymentOwnerId;
      this.invoicePaymentOwnerType = params.invoicePaymentOwnerType;
      console.log('invoiceId ' + this.invoiceId);
      console.log('this.contextIdDto.contextId ' + this.contextIdDto.contextId);
      console.log('this.contextIdDto.contextIdType ' + this.contextIdDto.contextIdType);
    });
  }

  locationBack() {
    this.location.back();
  }
}
