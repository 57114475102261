import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { ContextIdDto } from '@savvy/entity-instance-composite';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import {
  CreateAndSendViaSms, SavvyPayPaymentLinksService
} from '@savvy/savvy-pay';
import { UserCountryService } from 'src/app/services/userCountry.service';
import { Invoice } from '@savvy/payment';
import { CustomerCompositeService, Customer, CustomerService } from "@savvy/customer";

@Component({
  selector: 'app-send-link-payment',
  styleUrls: ['./sms-link-payment.component.scss'],
  templateUrl: './sms-link-payment.component.html'
})
export class SmsLinkPaymentComponent implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() invoice: Invoice;
  @Input() totalDepositRequests = 0;
  @Input() customerDto: Customer;
  @Input() currencyCode = '';

  @Output() added: EventEmitter<any> = new EventEmitter();


  createAndSendLinkRequest = {} as CreateAndSendViaSms;
  linkUrl = undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private notify: FloSnackbarComponent,
    public userCountryService: UserCountryService,
    private savvyPayPaymentLinkService: SavvyPayPaymentLinksService,
    private customerService: CustomerService,
    private dialogRef: MatDialogRef<SmsLinkPaymentComponent>) {
    this.contextIdDto = data.contextIdDto;
    this.invoice = data.invoice;
    this.currencyCode = data.currencyCode;
    this.customerDto = data.customerDto;
  }

  ngOnInit(): void {
    if (this.invoice) {
      if (this.invoice && this.invoice.customerId) {
        this.customerService.loadCustomer(this.invoice.customerId.id,
          this.contextIdDto.contextId, this.contextIdDto.contextIdType)
          .subscribe(async (res) => {
            this.customerDto = res;
          });
      }

      if (this.totalDepositRequests) {
        this.createAndSendLinkRequest.amount = this.invoice.total - this.totalDepositRequests;
      } else {
        this.createAndSendLinkRequest.amount = this.invoice.total;
      }

    }


  }

  createAndSendLink() {
    this.createAndSendLinkRequest.contextIdDto = this.contextIdDto;
    this.createAndSendLinkRequest.paymentLinkType = CreateAndSendViaSms.PaymentLinkTypeEnum.PaymentRequest;
    this.createAndSendLinkRequest.recurring = false;
    this.createAndSendLinkRequest.currency = this.currencyCode;
    this.createAndSendLinkRequest.customerId = this.customerDto.customerId.id;
    this.createAndSendLinkRequest.recipient = this.customerDto.mobilePhoneNumberObj?.nationalNumber;
    this.createAndSendLinkRequest.shopperReference = this.customerDto.customerId.id;
    this.createAndSendLinkRequest.invoiceId = this.invoice.id;


    console.log(this.createAndSendLinkRequest);
    this.savvyPayPaymentLinkService.createAndSendViaSms(this.createAndSendLinkRequest).subscribe(res => {
      this.notify.message = 'Payment Link has been sent via SMS';
      this.notify.open();

      this.linkUrl = res.url;
    });
  }


  closeDialog() {
    this.dialogRef.close();
  }


}
