<mat-card appearance="outlined" class="view-entry panel new-card" *ngIf="viewEntityPanelDataDto">

  <mat-card-header fxLayoutAlign="space-between baseline">
    <mat-card-title>{{viewEntityPanelDataDto.entityName}}</mat-card-title>

    <div>
      <button (click)="editEntity()" class="ml-xs mat-icon-button" mat-icon-button="">
                <span class="mat-button-wrapper">
                    <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">edit</mat-icon>
                </span>
        <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
        <div class="mat-button-focus-overlay"></div>
      </button>
    </div>

  </mat-card-header>
  <mat-card-content>

    <div class="entry-title" fxLayout="row" fxLayoutAlign="start center">
      <ngx-avatars
        (click)="viewEntity()"
        name="{{viewEntityPanelDataDto.value}}"
        bgColor="#a4c5fb"
        class="pointer"
        size="30" [style.border-radius]="'50%'"></ngx-avatars>

      <h4><a class="text-underline" (click)="viewEntity()">{{viewEntityPanelDataDto.value}}</a></h4>
    </div>

    <div class="entry-list" fxLayout="row" fxLayout.lt-md="column" *ngFor="let labelValueDto of viewEntityPanelDataDto.rows">
      <div fxFlex="100" fxLayout="column">
        <div fxFlex="100">
          <div fxFlex="30">
            <p class="mat-text-muted mt-xs mb-xs">
              {{labelValueDto.label}}
            </p>
          </div>
          <div fxFlex="70">
            <p class="mt-xs mb-xs">
              {{labelValueDto.value}}
            </p>
          </div>
        </div>
      </div>
    </div>

  </mat-card-content>
</mat-card>
