import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContextIdDto } from '@savvy/quickbooks';
import { InvoicecompService } from '@savvy/invoice';
import { InvoiceService } from '@savvy/invoice';

export interface ViewInvoiceDialogData {
  appointmentId: string;
  invoiceId: string;
  contextIdDto: ContextIdDto;
}

@Component({
  selector: 'app-view-invoice-dialog',
  templateUrl: 'viewInvoiceDialog.component.html',
})
export class ViewInvoiceDialogComponent {

  invoiceId: string;
  contextIdDto: ContextIdDto;

  constructor(
    private invoiceService: InvoiceService,
    private invoicecompService: InvoicecompService,
    public dialogRef: MatDialogRef<ViewInvoiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ViewInvoiceDialogData) {

    this.contextIdDto = data.contextIdDto;

    if (this.data.appointmentId) {
      console.log('loading invoice ', this.data.appointmentId);
      this.invoicecompService.getInvoiceIdsByEi(this.data.contextIdDto.contextId, this.data.contextIdDto.contextIdType,
        this.data.appointmentId).subscribe(response => {

          console.log('got response ', response.invoiceIds);
          if (response.invoiceIds && response.invoiceIds.length > 0) {
            // @ts-ignore
            this.invoiceId = response.invoiceIds[0];
          }
        });
    } else {
      console.log('invoice id ', this.data.invoiceId);
      this.invoiceId = this.data.invoiceId;
    }


  }

  onNoClick(): void {
    this.dialogRef.close();
  }


}

