<div class="mdc-mb-1">
  <h2 class="mt-0"><strong>{{'' | translate}}</strong></h2>
</div>

<div *ngIf="tips && tips.length > 0">
  <ngx-datatable class="material payments-tab-view" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="50" [scrollbarV]="true" [scrollbarH]="true" [rows]="tips">

    <ngx-datatable-column name="{{'DATE_TIME' | translate}}" [width]="100">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
        {{row.dateTime | localizedDate:'medium'}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'PAYMENT_METHOD' | translate}}" [width]="80">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
        {{row.paymentMethod}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'AMOUNT' | translate}}" [width]="10" prop="amount"></ngx-datatable-column>
    <ngx-datatable-column name="{{'PAYMENT_REFERENCE' | translate}}" [width]="80">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
        {{row.paymentReference}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'TIP_FOR' | translate}}" [width]="80">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
        {{row.staffMemberName}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [width]="10">
      <ng-template ngx-datatable-cell-template let-row="row">
        <button (click)="deleteTip(row)" class="ml-xs mat-icon-button" mat-icon-button="">
          <span class="mat-button-wrapper">
            <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">delete</mat-icon>
          </span>
          <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
          <div class="mat-button-focus-overlay"></div>
        </button>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>

</div>
<div class="flexbox-parent" *ngIf="tips && tips.length === 0">
  <div fxLayout="row" fxLayoutAlign="center center">
    <div style="text-align:center;">
      <app-zero-image name="payment" class="zero-image"></app-zero-image><br>
      <h5 class="ma-0">{{'NO_TIPS' | translate}}</h5><br>
    </div>
  </div>
</div>
<!--mat-card>
THIS ADDS A TIP USING THE OLD METHOD - WE NOW NEED TO ADD A TIP TO A PAYMENT RECORD
  <button mat-raised-button color="accent" (click)="addTip()">{{'Add Tip' | translate}}
  </button>
</mat-card-->