import { Component, OnDestroy, OnInit } from '@angular/core';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { ActivatedRoute, Router } from '@angular/router';
import { NavChangeService } from '../element/NavChangeService';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PhoneNumberHelperService } from '../shared/services/phone-number-helper.service';
import { CreateInvoice, Invoice, InvoiceProductRow, InvoiceService, LinkId, UpdateInvoice } from '@savvy/invoice';
import { CreateEmptyInvoice, InvoicecompService } from '@savvy/invoice';
import { PaymentScheduleDefinitionId } from '@savvy/view-definition';
import { ContextIdDto } from '@savvy/quickbooks';
import { CustomerId } from '@savvy/quote';
import { LinkedInstanceDto } from '@savvy/invoice';
import { ProductService, Product } from '@savvy/products';
import { UserCurrencyService } from '../shared/services/userCurrency.service';

@Component({
  selector: 'app-add-invoice',
  templateUrl: './addInvoice.component.html'
})
export class AddInvoiceComponent implements OnInit, OnDestroy {

  invoice: Invoice = null;
  products: Array<Product>;

  linkedId: string;
  linkedEdId: string = null;
  linkedIdType: string;

  customerId: string; // Optional
  orderId: string; // Optional

  currencyCode: string;

  destroy$ = new Subject();
  private sub: any;
  private contextIdDto: ContextIdDto;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private invoiceService: InvoiceService,
    private invoicecompService: InvoicecompService,
    private navChange: NavChangeService,
    private translateService: TranslateService,
    private productService: ProductService,
    private adapter: DateAdapter<any>,
    private notify: FloSnackbarComponent,
    private sharedService: PhoneNumberHelperService,
    private userCurrencyService: UserCurrencyService) {
    this.adapter.setLocale(this.translateService.currentLang);
  }


  ngOnInit() {
    console.log('inside addInvoice onInit');
    this.sub = this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      this.contextIdDto = <ContextIdDto>{};
      this.contextIdDto.contextId = params['contextId'];
      this.contextIdDto.contextIdType = params['contextIdType'];

      this.getCurrencyCode();
      this.loadProducts();

      // This is a bit specific
      this.orderId = params['orderId'];
      this.customerId = params['customerId'];
      this.linkedId = params['linkId'];
      if (params['linkEdId']) {
        this.linkedEdId = params['linkEdId'];
      }
      this.linkedIdType = params['linkIdType'];
      console.log('linkedIdType is ', this.linkedIdType);
      console.log('creating invoice linkedId ', this.linkedId);
      this.createEmpty();
    });
  }

  createEmpty() {
    if (this.customerId) {
      const createEmptyInvoice = <CreateEmptyInvoice>{};
      createEmptyInvoice.contextIdDto = this.contextIdDto;
      createEmptyInvoice.customerId = <CustomerId>{};
      createEmptyInvoice.customerId.id = this.customerId;
      createEmptyInvoice.persist = false;
      createEmptyInvoice.created = false; // false?
      createEmptyInvoice.suppliedValueDtos = new Array();
      createEmptyInvoice.linkId = <LinkId>{};
      createEmptyInvoice.linkId.linkedIdType = 'CUSTOMER_ID';
      createEmptyInvoice.linkId.linkedId = this.customerId;
      createEmptyInvoice.linkedInstanceDto = <LinkedInstanceDto>{};
      createEmptyInvoice.linkedInstanceDto.linkedInstanceTypeDto = LinkedInstanceDto.LinkedInstanceTypeDtoEnum.Customer;
      createEmptyInvoice.linkedInstanceDto.customerId = this.customerId;
      this.invoicecompService.createEmptyInvoiceV2(createEmptyInvoice).pipe(takeUntil(this.destroy$)).subscribe(response => {
        // @ts-ignore
        this.invoice = response.invoice;
      });

    } else if (this.orderId) {
      // Call order comp create invoice?
      this.invoicecompService.createEmptyInvoice(
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.orderId,
        this.linkedId,
        this.linkedIdType,
        this.linkedEdId).pipe(takeUntil(this.destroy$)).subscribe(response => {
          // @ts-ignore
          this.invoice = response.invoice;
          this.invoice.paymentScheduleDefinitionId = <PaymentScheduleDefinitionId>{};
        });
    } else if (this.linkedId && this.linkedIdType) {

      // This looks really dodgy
      this.invoicecompService.createEmptyInvoice(
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        '',
        this.linkedId,
        this.linkedIdType,
        this.linkedEdId).pipe(takeUntil(this.destroy$)).subscribe(response => {
          // @ts-ignore
          this.invoice = response.invoice;
        });
    } else {
      console.log('unable to create empty invoice as no linked id');
    }
  }

  getCurrencyCode() {
    this.userCurrencyService.getDefaultCurrency(this.contextIdDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.currencyCode = res.org.currencyCode ? res.org.currencyCode : this.userCurrencyService.defaultCurrency;
      });
  }

  productChanged(row) {
    if (row.productNumber) {
      console.log('Product number is ' + row.productNumber);
      const productDto = this.getProduct(row.productNumber);
      if (productDto) {
        row.unitPrice = productDto.unitPrice;
        row.tax = productDto.tax;
        row.taxString = `${String(productDto.tax)}%`;
      }
    }
    this.updateInvoice();
  }

  getProduct(productId: string): Product {
    let retProduct: Product;
    if (this.products) {
      this.products.forEach(productDto => {
        if (productDto.id === productId) {
          console.log('found match!');
          retProduct = productDto;
          return productDto;
        } else {
          console.log('no match');
        }
      });
    }
    return retProduct;
  }

  sendInvoice() {
    console.log('TODO: slide out');
  }

  viewInvoice() {
    this.router.navigate(['/invoice/viewInvoice',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      this.invoice.id]);
  }

  deleteRow(invoiceRow: InvoiceProductRow) {

    this.invoice.invoiceProductRows.forEach((item, index) => {
      if (item === invoiceRow) {
        this.invoice.invoiceProductRows.splice(index, 1);
      }
    });
    this.updateInvoice();
  }

  createInvoiceRow() {
    console.log('creating row');
    const invoiceRow: InvoiceProductRow = <InvoiceProductRow>{};
    if (!this.invoice.invoiceProductRows) {
      console.log('creating rows array');
      this.invoice.invoiceProductRows = new Array(0);
    }
    this.invoice.invoiceProductRows.push(invoiceRow);
    console.log('rows now ' + this.invoice.invoiceProductRows.length);
    this.addOrUpdateInvoice();
  }

  addOrUpdateInvoice() {
    if (this.invoice.id) {
      this.updateInvoice();
    } else {
      this.addInvoice();
    }
  }

  addInvoice() {
    const req: CreateInvoice = <CreateInvoice>{};
    req.contextIdDto = this.contextIdDto;
    req.invoice = this.invoice;

    this.invoiceService.createInvoice(req).pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.invoice = response;
      this.translateService.get('Created Invoice')
        .subscribe(val => {
          this.notify.message = val;
          this.notify.open();
        });
    });
  }

  updateInvoice() {
    const req: UpdateInvoice = <UpdateInvoice>{};
    req.contextIdDto = this.contextIdDto;
    req.invoice = this.invoice;

    this.invoiceService.updateInvoice(req).pipe(takeUntil(this.destroy$)).subscribe(response => {

      this.invoice = response;
      this.notify.message = 'Updated Invoice';
      this.notify.open();
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private loadProducts() {
    this.productService.listProducts(this.contextIdDto.contextId).subscribe(response => {
      this.products = response;
    });
  }
}
