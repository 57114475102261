import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';

import { Subscription } from 'rxjs';

import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';
import { ContextIdDto } from '@savvy/ui';
import { ContextService } from '../../context.service';

import { DiscountSettingsService, DiscountItem, DiscountSettings, Discount } from '@savvy/invoice';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { getCurrencySymbol } from '@angular/common';


@Component({
  selector: 'app-view-discounts',
  templateUrl: './view-discounts.component.html',
  styleUrls: ['./view-discounts.component.scss']
})
export class ViewDiscountsComponent implements OnInit, OnDestroy {

  @Input() hideHeader: boolean;
  @Input() amount: number;
  @Input() currencyCode: string;
  @Output() onApply: EventEmitter<DiscountItem> = new EventEmitter();

  tabs: string[] = ['CONFIGURED', 'CUSTOM'];
  selectedTab: string = this.tabs[0];
  contextIdDto: ContextIdDto;
  subscriptions: Subscription[] = [];
  discountSettings: DiscountSettings;

  customDiscountForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder,
    public notify: FloSnackbarComponent,
    private contextService: ContextService,
    private discountSettingsService: DiscountSettingsService,) { }

  ngOnInit() {
    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (!data || (this.contextIdDto && data.contextId === this.contextIdDto.contextId && data.contextIdType === this.contextIdDto.contextIdType)) {
        return;
      }
      this.contextIdDto = data;
      this.buildCustomDiscountForm();
      this.loadDiscountSettings();
    }));
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  buildCustomDiscountForm() {
    this.customDiscountForm = this.formBuilder.group({
      discountType: new UntypedFormControl(Discount.DiscountTypeEnum.Percentage),
      amountDiscount: new UntypedFormControl(),
      percentDiscount: new UntypedFormControl(),
    });
    this.onDiscountTypeChange();
  }

  onDiscountTypeChange() {
    this.setCustomDiscountFormValidators();
  }

  setCustomDiscountFormValidators() {

    if (this.customDiscountForm.value.discountType === 'AMOUNT') {
      this.customDiscountForm.controls.amountDiscount.setValidators([Validators.required, Validators.min(0.01)]);
      this.customDiscountForm.controls.percentDiscount.clearValidators();
    } else {
      this.customDiscountForm.controls.amountDiscount.clearValidators();
      this.customDiscountForm.controls.percentDiscount.setValidators([Validators.required, Validators.min(1), Validators.max(100)]);
    }
    this.customDiscountForm.controls.amountDiscount.updateValueAndValidity();
    this.customDiscountForm.controls.percentDiscount.updateValueAndValidity();
  }

  selectedTabChange(event: MatTabChangeEvent): void {
    this.selectedTab = this.tabs[event.index];
  }

  loadDiscountSettings(): void {
    this.discountSettings = null;
    this.discountSettingsService
      .get(this.contextIdDto.contextId, this.contextIdDto.contextIdType)
      .subscribe(res => {
        this.discountSettings = res;
      });
  }

  onApplyClick(discountItem: DiscountItem): void {
    this.onApply.emit(discountItem);
  }

  onCustomApplyClick(): void {
    if (!this.customDiscountForm.valid) {
      return;
    }
    this.onApply.emit({
      id: 'CUSTOM',
      discount: this.customDiscountForm.value
    });
  }

}
