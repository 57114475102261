<div [formGroup]="form" *ngIf="fieldDefinition" style="padding-bottom: 1.34375em; margin: .25em 0;">


  <mat-checkbox *ngIf="!elementInstanceDto.elementInstanceViewDto.displayWidgetType"
    [id]="elementInstanceDto.instanceId"
                [checked]="this.form.controls[this.elementInstanceDto.instanceId].value"
    [formControlName]="elementInstanceDto.instanceId">
    {{fieldDefinition.label | translate}}
  </mat-checkbox>

  <div *ngIf="elementInstanceDto.elementInstanceViewDto.displayWidgetType === 'SLIDE_TOGGLE'" style="padding: 1em .75em;">


    <span fxFlex>{{fieldDefinition.label | translate}}</span>
    <mat-slide-toggle [id]="elementInstanceDto.instanceId"
                    color="accent"
                    [formControlName]="elementInstanceDto.instanceId"></mat-slide-toggle>
  </div>


  <mat-radio-group style="padding: 1em .75em;" [required]="fieldDefinition.required"
                   *ngIf="elementInstanceDto.elementInstanceViewDto.displayWidgetType === 'RADIO' &&
      elementInstanceDto.elementInstanceViewDto.radioLabels" [formControlName]="elementInstanceDto.instanceId">
    <mat-radio-button [checked]="form.controls[this.elementInstanceDto.instanceId].value"
                      value="true">{{elementInstanceDto.elementInstanceViewDto.radioLabels.trueLabel}}</mat-radio-button>
    &nbsp;
    <mat-radio-button [checked]="!form.controls[this.elementInstanceDto.instanceId].value"
                      value="false">{{elementInstanceDto.elementInstanceViewDto.radioLabels.falseLabel}}</mat-radio-button>
  </mat-radio-group>

  <!--mat-error *ngIf="form.controls[elementInstanceDto.instanceId].hasError('required')">
    {{fieldDefinition.label + ' is' | translate}} <strong>{{'required' | translate}}</strong>
  </mat-error-->

</div>
