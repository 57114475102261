import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { DataItemDto, DataListDto, DatalistService } from '@savvy/datalist';
import { ContextIdDto } from '@savvy/menu';
import { Pet, PetDto, PetService, PetSummaryDto, PetTypeService } from '@savvy/pet';
import * as _ from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AddEditDatalistModalComponent } from 'src/app/flo/datalist/add-edit-datalist-modal/add-edit-datalist-modal.component';
import { ClearObservable } from 'src/app/flo/shared/classes/clear-observable';

@Component({
  selector: 'app-add-new-pet-v2',
  templateUrl: './add-new-pet-v2.component.html',
  styleUrls: ['./add-new-pet-v2.component.scss']
})
export class AddNewPetV2Component extends ClearObservable implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() customerId: string;
  @Input() multiple = false;
  @Input() showTitle = true;
  @Input() maxPets;


  @Output() petsSelectionChange: EventEmitter<PetDto[]> = new EventEmitter();
  @Output() petsFormGroupChange: EventEmitter<UntypedFormGroup[]> = new EventEmitter();
  @ViewChild('multiSelect', { static: true }) multiSelect: MatSelect;

  pets: PetSummaryDto[] = [];
  selectedPets = [];
  petFormGroup: UntypedFormGroup;
  petFormGroupArray: UntypedFormGroup[] = [];

  addNewPet = false;
  // public petsTobeAdded: Pet[] = [];

  breeds = [];
  public filteredBreeds: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public petBreedFilter: UntypedFormControl = new UntypedFormControl();


  breedDatalistDto = {} as DataListDto;

  public petMultiCtrl: UntypedFormControl = new UntypedFormControl();

  /** control for the MatSelect filter keyword multi-selection */
  public petMultiFilterCtrl: UntypedFormControl = new UntypedFormControl();

  /** list of pets filtered by search keyword */
  public filteredPetsMulti: ReplaySubject<PetSummaryDto[]> = new ReplaySubject<PetSummaryDto[]>(1);

  constructor(
    private petService: PetService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private datalistService: DatalistService,
    private spinner: NgxSpinnerService,
    public deviceService: DeviceDetectorService,
  ) {
    super();
  }

  get valid() {
    if (this.multiple) {
      return this.petFormGroupArray.length === this.petFormGroupArray.filter(fg => fg.valid).length;
    }
    return this.petFormGroup.valid;
  }

  ngOnInit() {
    this.initFormGroup();
    this.petFormGroup.get('petName').valueChanges.subscribe(x => {
      this.petsFormGroupChange.emit(this.petFormGroupArray);
    });
    this.petFormGroupArray.push(this.petFormGroup);
    this.petsFormGroupChange.emit(this.petFormGroupArray);
    this.petBreedFilter.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.filterBreeds();
      });
    this.loadBreeds();

    // load the initial bank list
    this.filteredPetsMulti.next(this.pets.slice());

    // listen for search field value changes
    this.petMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.filterPets();
      });
  }

  loadBreeds() {
    this.datalistService.getDataListByName(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType, 'Breed').subscribe(breeds => {
        if (breeds) {
          this.breedDatalistDto = breeds;
          this.breeds = breeds.dataItems;
          this.filteredBreeds.next(this.breeds);
        }
      });
  }

  filterBreeds() {
    if (!this.breeds) {
      return;
    }
    // get the search keyword
    // get the search keyword
    let search = this.petBreedFilter.value;
    if (!search) {
      this.filteredBreeds.next(this.breeds.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the breeds
    this.filteredBreeds.next(
      this.breeds.filter(breed => breed.name.toLowerCase().indexOf(search) > -1)
    );
  }

  petsSelected(event: MatSelectChange) {
    let selectedPets = [];
    if (this.selectedPets && this.selectedPets.length) {
      selectedPets = this.selectedPets;
    } else {
      selectedPets = event.value;
    }
    this.petsSelectionChange.emit(selectedPets);
  }

  petSelectedFromList(event: any) {
    this.petsSelectionChange.emit(event);
  }

  loadPets(customer: string): void {
    this.customerId = customer;
    console.log('loading pets with customer ', customer);
    this.spinner.show();
    this.petService.listSummaryV2(
      this.customerId
    ).subscribe(response => {
      this.spinner.hide();
      console.log('got response', response);
      this.pets = response;
      this.filteredPetsMulti.next(this.pets);
      if (this.pets.length === 1) {
        this.selectedPets = _.cloneDeep(this.pets);
        this.petsSelectionChange.emit(this.selectedPets);
      }
      if (!this.pets.length) {
        this.addNewPet = true;
      }
    });
  }

  addPetSummary(petSummary: PetSummaryDto) {
    if (!this.pets) {
      this.pets = new Array<PetSummaryDto>();
    }
    this.pets.push(petSummary);
    this.pets = _.cloneDeep(this.pets);
    this.addNewPet = false;
    this.selectedPets = _.cloneDeep(this.pets);
    this.petsSelectionChange.emit(this.selectedPets);
  }

  createPet() {
    // Add Pet Modal
    const pet = {} as Pet;
    pet.name = this.petFormGroup.get('petName').value;
    pet.breed = this.petFormGroup.get('petBreed').value;
    pet.petType = this.petFormGroup.get('petType').value;
    pet.petOwnerId = this.customerId;
    pet.ownerId = this.contextIdDto.contextId;
    // this.spinner.show();
    return this.petService.createPet(
      pet);
  }

  addPet() {
    const petFormGroup = this.formBuilder.group({
      petName: ['', Validators.required],
      petBreed: [''],
      petType: ['Dog'],
    });
    this.petFormGroupArray.push(petFormGroup);
    this.petsFormGroupChange.emit(this.petFormGroupArray);
  }

  removePet(i) {
    this.petFormGroupArray.splice(i, 1);
  }

  createPetMultiple(customerId: string, customerCreated?: EventEmitter<Pet>) {
    return new Promise(async (resolve) => {
      for await (const petFormGroup of this.petFormGroupArray) {
        const pet = {} as Pet;
        pet.name = petFormGroup.get('petName').value;
        pet.breed = petFormGroup.get('petBreed').value;
        pet.petType = petFormGroup.get('petType').value;
        pet.petOwnerId = customerId;
        pet.ownerId = this.contextIdDto.contextId;
        await this.petService.createPet(
          pet).subscribe(newPet => {
            if (customerCreated) {
              customerCreated.emit(newPet);
            }
            setTimeout(() => petFormGroup.disable(), 300);
          });
      }
      resolve(true);
    });
  }

  compareObjects(o1: PetSummaryDto, o2: PetSummaryDto): boolean {
    return o1.id === o2.id;
  }

  petNotPresent() {
    this.addNewPet = true;
    this.initFormGroup();
  }

  selectPetForce(id) {
    if (this.selectedPets?.length) {
      const index = this.selectedPets.findIndex(pet => pet?.id === id);
      if (index !== -1) {
        return true;
      }
    }
    return false;
  }

  addNewBreed() {
    const confirmDialog = this.dialog.open(AddEditDatalistModalComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        dataListDto: this.breedDatalistDto
      },
      height: 'auto',
      width: '600px',
      panelClass: 'helpwindow'
    });

    confirmDialog.afterClosed().subscribe((result: DataItemDto) => {
      if (result) {
        this.loadBreeds();
        this.petFormGroup.patchValue({
          petBreed: result.name,
        });
      }
    });
  }

  protected setInitialValue() {
    this.filteredPetsMulti
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.multiSelect.compareWith = (a: PetSummaryDto, b: PetSummaryDto) => a && b && a.id === b.id;
      });
  }

  protected filterPets() {
    if (!this.pets) {
      return;
    }
    // get the search keyword
    let search = this.petMultiFilterCtrl.value;
    if (!search) {
      this.filteredPetsMulti.next(this.pets.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the pets
    this.filteredPetsMulti.next(
      this.pets.filter(pet => pet.name.toLowerCase().indexOf(search) > -1)
    );
  }

  private initFormGroup() {
    this.petFormGroup = this.formBuilder.group({
      petName: ['', Validators.required],
      petBreed: [''],
      petType: [''],
    });
  }

}
