import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ViewEntityWithImagePanelDataDto } from '@savvy/view-definition';
import { ContextIdDto } from '@savvy/quickbooks';
import { ChangeListener } from './changeListener';
import { EventBusService } from './EventBusService';

@Component({
  selector: 'app-view-entity-with-image-panel',
  templateUrl: 'viewEntityWithImagePanel.component.html',
  styleUrls: ['viewEntityWithImagePanel.component.scss', 'commonPanel.component.scss']
})
export class ViewEntityWithImagePanelComponent implements OnInit {

  constructor() { }

  @Input() viewEntityWithImagePanelDataDto: ViewEntityWithImagePanelDataDto;
  @Input() contextIdDto: ContextIdDto;
  @Input() viewContext: any;
  @Input() form: UntypedFormGroup;
  @Input() eventBus: EventBusService;
  @Input() additionalDataMapDto: any;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;

  ngOnInit() {
    console.log(11, this.viewEntityWithImagePanelDataDto);
  }
}

