import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { EventBusService } from './EventBusService';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PhoneNumberHelperService } from '../shared/services/phone-number-helper.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Invoice, InvoiceService } from '@savvy/invoice';
import { InvoicecompService, InvoicePaymentStatusDto, UpdateInvoiceComp } from '@savvy/invoice';
import { ContextIdDto, InvoiceDefinition, ViewInvoicePaymentStatusDataDto } from '@savvy/view-definition';
import { CaptureTokenizedPaymentComp, SavvyPayPaymentsCompService } from '@savvy/savvy-pay';
import { PaymentService, PaymentSettings, PaymentSettingsService } from '@savvy/payment';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { AddPaymentModalComponent } from '../shared/components/add-payment-modal/add-payment-modal.component';
import { SendInvoiceDialogComponent } from '../invoice/sendInvoiceDialog.component';
import { UserCurrencyService } from '../shared/services/userCurrency.service';

@Component({
  selector: 'app-view-invoice-payment-status-panel',
  templateUrl: 'viewInvoicePaymentStatusPanel.component.html',
  styleUrls: ['viewInvoicePaymentStatusPanel.component.scss']
})
export class ViewInvoicePaymentStatusPanelComponent implements OnInit, OnDestroy {

  @Input() viewInvoicePaymentStatusDataDto: ViewInvoicePaymentStatusDataDto;
  @Input() viewContext: any;
  @Input() contextIdDto: ContextIdDto;
  @Input() additionalDataMapDto: any;
  @Input() eventBus: EventBusService;

  @Output() taskSelected = new EventEmitter();
  @Output() taskDeselected = new EventEmitter();

  currencyCode: string;
  invoice: Invoice = null;
  amountOutstanding: number;
  invoicePaymentStatus: InvoicePaymentStatusDto;
  invoiceDefinition: InvoiceDefinition;
  hasSavvyPayPayments = false;
  submitting = false;
  captureTokenized = <CaptureTokenizedPaymentComp>{};
  paymentSettings: PaymentSettings;

  private destroy$ = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: InvoiceService,
    private invoiceCompApi: InvoicecompService,
    private savvyPayPaymentCompApi: SavvyPayPaymentsCompService,
    private paymentApi: PaymentService,
    private paymentSettingsApi: PaymentSettingsService,
    private dialog: MatDialog,
    private sharedService: PhoneNumberHelperService,
    private notify: FloSnackbarComponent,
    private userCurrencyService: UserCurrencyService) {
  }


  ngOnInit(): void {
    this.captureTokenized.amount = this.viewInvoicePaymentStatusDataDto.totalAmount;

    this.paymentSettingsApi.loadPaymentSettings(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType).subscribe(response => {
        this.paymentSettings = response;
      });

    this.getCurrencyCode();
    this.loadData();
    this.loadInvoiceState();
    this.setHasSavvyPayments();
  }

  loadData() {
    console.log(this.viewInvoicePaymentStatusDataDto);
  }

  loadInvoiceState() {
    this.loadInvoice();
    this.loadInvoicePaymentStatus();
  }

  loadInvoicePaymentStatus() {
    if (this.viewInvoicePaymentStatusDataDto?.invoiceId) {
      this.invoiceCompApi.loadInvoicePaymentStatus(
        this.viewInvoicePaymentStatusDataDto.invoiceId,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType).subscribe(response => {
          console.log('loaded invoice', response);
          this.invoicePaymentStatus = response.invoicePaymentStatusDto;
          this.amountOutstanding = response.invoicePaymentStatusDto.amountOutstanding;
        });
    }
  }

  loadInvoice() {

    this.api.loadInvoice(this.viewInvoicePaymentStatusDataDto.invoiceId, this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(response => {
      console.log('loaded invoice', response);
      this.invoice = response;
    });
  }

  setHasSavvyPayments() {
    // Payment settings
    //   "savvyPaySettings":{
    //       "accountHolderCode":"611d5ef0b688b82719f833b8"
    //    },
    if (this.viewInvoicePaymentStatusDataDto.paymentReferences
      && this.viewInvoicePaymentStatusDataDto.paymentReferences.length > 0) {
      this.hasSavvyPayPayments = true;
      this.captureTokenized.merchantReference = this.viewInvoicePaymentStatusDataDto.paymentReferences[0];
    }
  }


  getCurrencyCode() {
    this.userCurrencyService.getDefaultCurrency(this.contextIdDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.currencyCode = res.org.currencyCode ? res.org.currencyCode : this.userCurrencyService.defaultCurrency;
      });
  }

  addPayment() {
    const dialogRef = this.dialog.open(AddPaymentModalComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        invoice: this.invoice
      },
      autoFocus: false,
      panelClass: ['helpwindow']
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res) {
          console.log('reloading invoice');
          //get the updated invoice
          this.loadInvoiceState();
        }
      });
  }


  updateInvoice() {
    const req: UpdateInvoiceComp = <UpdateInvoiceComp>{};
    req.contextIdDto = this.contextIdDto;
    req.invoice = this.invoice;

    this.invoiceCompApi.updateInvoiceComp(req)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        // @ts-ignore
        this.invoice = response.invoice;
        // this.ecs.handleChangedElements(response.changedElementList, this.form);
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  takePayment() {
    this.submitting = true;
    this.captureTokenized.envId = this.contextIdDto.contextId;
    this.captureTokenized.amount = this.amountOutstanding;

    this.savvyPayPaymentCompApi.captureTokenizedPayment(this.captureTokenized).subscribe(res => {
      this.submitting = false;
      this.loadInvoiceState();

      if (res.serviceError && res.serviceError.message) {
        this.notify.message = res.serviceError.message;
        this.notify.open();
      } else if (res.resultCode == 'Refused') {
        if (res.refusalReason) {
          this.notify.message = res.refusalReason;
        } else {
          this.notify.message = 'Payment has been refused by issuer bank.';
        }

        this.notify.open();
      } else {
        this.notify.message = 'Payment has been successfully captured.';
        this.notify.open();
      }
    });
  }

  chargeNoShow() {
    this.submitting = true;
    const merchantReference = this.viewInvoicePaymentStatusDataDto.paymentReferences[0];

    this.savvyPayPaymentCompApi.chargeNoShow(this.contextIdDto.contextId, this.contextIdDto.contextIdType, merchantReference).subscribe(res => {
      this.submitting = false;
      if (res.serviceError && res.serviceError.message) {
        this.notify.message = res.serviceError.message;
        this.notify.open();
      } else if (res.resultCode == 'Refused') {
        if (res.refusalReason) {
          this.notify.message = res.refusalReason;
        } else {
          this.notify.message = 'Payment has been refused by issuer bank.';
        }

        this.notify.open();
      } else {
        this.notify.message = 'No show charge has been successfully applied.';
        this.notify.open();
      }
    });
  }

  chargeLateCancellation() {
    this.submitting = true;
    const merchantReference = this.viewInvoicePaymentStatusDataDto.paymentReferences[0];

    this.savvyPayPaymentCompApi.chargeLateCancellation(this.contextIdDto.contextId, this.contextIdDto.contextIdType, merchantReference).subscribe(res => {
      this.submitting = false;
      if (res.serviceError && res.serviceError.message) {
        this.notify.message = res.serviceError.message;
        this.notify.open();
      } else if (res.resultCode == 'Refused') {
        if (res.refusalReason) {
          this.notify.message = res.refusalReason;
        } else {
          this.notify.message = 'Payment has been refused by issuer bank.';
        }

        this.notify.open();
      } else {
        this.notify.message = 'Late cancellation charge has been successfully applied.';
        this.notify.open();
      }
    });
  }

  sendInvoice() {
    this.dialog.open(SendInvoiceDialogComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        invoice: this.invoice,
        eventBus: this.eventBus
      },
      autoFocus: false
    });
  }

  goToSavvyPaySettings() {
    this.router.navigate(['/paymentSettings/paymentSettings',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
    ]);
  }
}
