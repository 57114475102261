import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Link, SendToAllMessage, SendToAllMessageService } from '@savvy/send-to-all';
import { NgWizardComponent, NgWizardConfig, NgWizardService, STEP_POSITION, THEME, TOOLBAR_POSITION } from 'ng-wizard-v2';
import { Subject } from 'rxjs';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';
import { SendToAllMessageConfigurationComponent } from './send-to-all-message-configuration/send-to-all-message-configuration.component';
import { SendToAllMessageEmailComponent } from './send-to-all-message-email/send-to-all-message-email.component';
import { SendToAllMessagePreviewComponent } from './send-to-all-message-preview/send-to-all-message-preview.component';
import { SendToAllMessageSmsComponent } from './send-to-all-message-sms/send-to-all-message-sms.component';
import {EmailSettings, EmailSettingsService} from "@savvy/email-settings";
import {UserId} from "@savvy/user";

@Component({
  selector: 'app-send-to-all-message-wizard',
  templateUrl: './send-to-all-message-wizard.component.html',
  styleUrls: ['./send-to-all-message-wizard.component.scss']
})
export class SendToAllMessageWizardComponent implements OnInit, OnDestroy {

  @ViewChild(NgWizardComponent, { static: false }) nzWiard: NgWizardComponent;
  @ViewChild(SendToAllMessageConfigurationComponent, { static: false }) messageConfiguration: SendToAllMessageConfigurationComponent;
  @ViewChild(SendToAllMessageEmailComponent, { static: false }) messageEmailConfiguration: SendToAllMessageEmailComponent;
  @ViewChild(SendToAllMessageSmsComponent, { static: false }) messageSmsConfiguration: SendToAllMessageSmsComponent;
  @ViewChild(SendToAllMessagePreviewComponent, { static: false }) messagePreview: SendToAllMessagePreviewComponent;

  config: NgWizardConfig = {
    selected: 0,
    theme: THEME.arrows,
    toolbarSettings: {
      toolbarPosition: TOOLBAR_POSITION.none
    },
    anchorSettings: {
      anchorClickable: false,
      removeDoneStepOnNavigateBack: true
    }
  };

  recipientType: string;
  sending = false;
  isValidTypeBoolean = true;
  lastStep = false;
  firstStep = true;
  emailSettings: EmailSettings;



  private _unsubscribeAll: Subject<any>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SendToAllMessageWizardComponent>,
    private ngWizardService: NgWizardService,
    private notify: FloSnackbarComponent,
    private api: SendToAllMessageService,
    private emailSettingsApi: EmailSettingsService) {
    this._unsubscribeAll = new Subject();
    this.recipientType = data.recipientType;
    console.log('set recipiejtType to', this.recipientType);

  }

  ngOnInit(): void {
    this.loadEmailSettings();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  showPreviousStep(event?: Event) {
    this.ngWizardService.previous();
  }

  showNextStep(event?: Event) {
    if (!this.validStep(this.nzWiard.currentStepIndex)) {
      return;
    }
    this.ngWizardService.next();
  }

  resetWizard(event?: Event) {
    this.ngWizardService.reset();
  }

  setTheme(theme) {
    this.ngWizardService.theme(theme);
  }

  stepChanged(args) {
    this.setStepperButtonEnableOrDisable(args.position);
    if (args.position === STEP_POSITION.final) {
      this.prepareMessageForPreview();
    } else {
      this.messagePreview.showPreview(false);
    }
  }

  setStepperButtonEnableOrDisable(stepPosition): void {
    if (stepPosition === STEP_POSITION.first) {
      this.firstStep = true;
    } else {
      this.firstStep = false;
    }
    if (stepPosition === STEP_POSITION.final) {
      this.lastStep = true;
    } else {
      this.lastStep = false;
    }
  }

  prepareMessageForPreview() {
    const sendToAllMessage = this.prepareMessage(true);
    this.messagePreview.setCustomerMessage(sendToAllMessage);
    this.messagePreview.showPreview(true);
  }

  prepareMessage(preview?: boolean): SendToAllMessage {
    const sendToAllMessage: SendToAllMessage = <SendToAllMessage>{};
    sendToAllMessage.recipientType = this.data.recipientType;
    const configurationData: any = this.messageConfiguration.getFormRef().value;
    sendToAllMessage.marketingMessage = configurationData.marketing;
    if (!configurationData.allCustomers) {
      sendToAllMessage.flagDefIds = this.messageConfiguration.flagDefs
        .filter(fd => configurationData.flags.indexOf(fd.id) >= 0)
        .map(fd => preview ? fd.name : fd.id);
      sendToAllMessage.excludeFlagDefIds = this.messageConfiguration.flagDefs
        .filter(fd => configurationData.excludeFlags.indexOf(fd.id) >= 0)
        .map(fd => preview ? fd.name : fd.id);
    }

    const emailData: any = this.messageEmailConfiguration.getFormRef().value;
    if (emailData.allowEmail) {
      sendToAllMessage.emailMessage = {
        subject: emailData.subject,
        body: this.messageEmailConfiguration.quickLinksEditorV2Component.messageEditor.text,
        links: this.combineLinks()
      };
      sendToAllMessage.emailMessage.attachments = new Array();

      if (this.messageEmailConfiguration.uploadFiles && this.messageEmailConfiguration.uploadFiles.savedFiles) {
        sendToAllMessage.emailMessage.attachments
          = this.messageEmailConfiguration.uploadFiles.savedFiles
            .map(savedFile => {
              return {
                fileReferenceId: savedFile?.fileReferenceId,
                mimeType: savedFile?.mimeType,
                fileName: savedFile?.fileName,
                publicBucket: false
              }
            });
      }
    }

    const smsData: any = this.messageSmsConfiguration.getFormRef().value;
    if (smsData.allowSms) {
      sendToAllMessage.smsMessage = {
        textMessage: this.messageSmsConfiguration.quickLinksEditorV2Component.messageEditor.text,
        links: this.messageSmsConfiguration.quickLinksEditorV2Component.getLinksFromQuicklinksData() as Link[]
      };
    }

    if (this.emailSettings?.userId) {
      sendToAllMessage.senderUserId = <UserId>{};
      sendToAllMessage.senderUserId.id = this.emailSettings?.userId;
    }
    return sendToAllMessage;
  }

  combineLinks() {
    let links = [];
    if (this.messageEmailConfiguration.quickLinksEditorV2Component.getLinksFromQuicklinksData()?.length > 0) {
      links = Object.assign([],
        links.concat(this.messageEmailConfiguration.quickLinksEditorV2Component.getLinksFromQuicklinksData() as Link[]));
    }

    if (this.messageEmailConfiguration.links?.length > 0) {
      links = Object.assign([], links.concat(this.messageEmailConfiguration.links));
    }
    return links;
  }

  sendMessage() {
    const customersMessage: SendToAllMessage = this.prepareMessage(false);
    if (!customersMessage.emailMessage && !customersMessage.smsMessage) {
      this.notify.message = 'Please select Email or Sms';
      this.notify.open();
      return false;
    }

    if (this.emailSettings?.primaryFromEmail && customersMessage.emailMessage) {
      customersMessage.emailMessage.fromEmail = this.emailSettings?.primaryFromEmail;
      customersMessage.emailMessage.replyToEmail = this.emailSettings?.primaryReplyToEmail;
    }
    this.api.sendToAllMessage(this.data.contextIdDto.contextId,
      this.data.contextIdDto.contextIdType,
      customersMessage).subscribe(() => {
        this.notify.message = 'Message sent!';
        this.notify.open();
        this.dialogRef.close();
        this.sending = false;

      }, err => {
        this.notify.message = 'Error sending message ' + err.errorMessage;
        this.notify.open();
        this.sending = false;
      });
  }

  validStep(stepInd: number): boolean {
    let valid = true;
    if (stepInd === 0) {
      valid = this.hasMessageConfigurationValidData();
    } else if (stepInd === 1) {
      valid = this.hasMessageEmailConfigurationValidData();
    } else if (stepInd === 2) {
      valid = this.hasMessageSmsConfigurationValidData();
    }
    return valid;
  }

  hasMessageConfigurationValidData(): boolean {
    const configurationData: any = this.messageConfiguration.getFormRef().value;
    if (!configurationData.allCustomers && ((!configurationData.flags || !configurationData.flags.length)
      && (!configurationData.excludeFlags || !configurationData.excludeFlags.length))) {
      this.notify.message = 'Please select Include Flags or Exclude Flags.';
      this.notify.open();
      return false;
    }

    return true;
  }

  hasMessageEmailConfigurationValidData(): boolean {
    const emailData: any = this.messageEmailConfiguration.getFormRef().value;
    if (emailData.allowEmail && (!emailData.subject || !emailData.subject.length
      || !this.messageEmailConfiguration.quickLinksEditorV2Component.getEditorText() || !this.messageEmailConfiguration.quickLinksEditorV2Component.getEditorText().length)) {
      this.notify.message = 'Please fill all required details in this step';
      this.notify.open();
      return false;
    }
    return true;
  }

  hasMessageSmsConfigurationValidData(): boolean {
    const smsData: any = this.messageSmsConfiguration.getFormRef().value;
    if (smsData.allowSms
      && (!this.messageSmsConfiguration.quickLinksEditorV2Component.getEditorText() || !this.messageSmsConfiguration.quickLinksEditorV2Component.getEditorText().length)) {
      this.notify.message = 'Please fill all required details in this step';
      this.notify.open();
      return false;
    }
    return true;
  }

  private loadEmailSettings() {
    if (this.data.contextIdDto.contextId) {
      this.emailSettingsApi.getOrCreateEmailSettings(
        this.data.contextIdDto.contextId,
        this.data.contextIdDto.contextIdType
      ).subscribe(response => {
        this.emailSettings = response;
      });
    }
  }

}
