import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CardDefinitionDto, ContextIdDto } from '@savvy/view-definition';
import { ChangeListener } from './changeListener';
import { EventBusService } from './EventBusService';

@Component({
  selector: 'app-card-definition',
  templateUrl: 'cardDefinition.component.html',
  styleUrls: ['commonPanel.component.scss']

})
export class CardDefinitionComponent implements OnInit {

  @Input() cardDefinitionDto: CardDefinitionDto;
  @Input() contextIdDto: ContextIdDto;
  @Input() viewContext: any;
  @Input() form: UntypedFormGroup;
  @Input() eventBus: EventBusService;
  @Input() showPlaceholder: boolean;
  @Input() additionalDataMapDto: any;
  @Input() changeListener: ChangeListener;


  ngOnInit(): void {

  }
}
