import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonSettingsComponent } from './buttonSettings.component';
import { DateFieldSettingsComponent } from './dateFieldSettings.component';
import { DateTimeFieldSettingsComponent } from './dateTimeFieldSettings.component';
import { FieldSettingsComponent } from './fieldSettings.component';
import { LinkedEntitySettingsComponent } from './linkedEntitySettings.component';
import { SelectFieldSettingsComponent } from './selectFieldSettings.component';
import { FormSettingsComponent } from './formSettings.component';
import { FloMaterialModule } from '../../flo.material.module';
import { EntitydefsService } from '@savvy/entity-definition';
import { EicompService } from '@savvy/entity-instance-composite';
import { EntitysService } from '@savvy/entity-instance';
import { ElementFieldSettingsComponent } from './elementFieldSettings.component';
import { TimeFieldSettingsComponent } from './timeFieldSettings.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        FloMaterialModule
    ],
    exports: [
        ButtonSettingsComponent,
        DateFieldSettingsComponent,
        DateTimeFieldSettingsComponent,
        TimeFieldSettingsComponent,
        ElementFieldSettingsComponent,
        FieldSettingsComponent,
        FormSettingsComponent,
        LinkedEntitySettingsComponent,
        SelectFieldSettingsComponent,
        TranslateModule
    ],
    providers: [
        EntitydefsService,
        EntitysService,
        EicompService
    ],
    declarations: [
        ButtonSettingsComponent,
        DateFieldSettingsComponent,
        DateTimeFieldSettingsComponent,
        TimeFieldSettingsComponent,
        ElementFieldSettingsComponent,
        FieldSettingsComponent,
        FormSettingsComponent,
        LinkedEntitySettingsComponent,
        SelectFieldSettingsComponent
    ]
})

export class SettingsModule {
}
