import { Component, HostListener, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ContextService } from '../context.service';
import { Router } from '@angular/router';
import { ContextIdDto } from '@savvy/payment';
import { EventBusService } from '../element/EventBusService';
import { Page } from '../shared/model/page';
import { MenuDefinition } from '@savvy/ui';
import { Subscription } from 'rxjs';
import { ListPetsFiltered, Pet, PetService } from '@savvy/pet';
import { MatDialog } from '@angular/material/dialog';
import { TrialExpiredService } from '../shared/services/trial-expired.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import MenuItemTypeEnum = MenuDefinition.MenuItemTypeEnum;
import { ListPetsFilteredResponse } from '@savvy/pet/model/listPetsFilteredResponse';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { ConfService } from 'src/app/flo/config/conf.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { PageEvent } from '@angular/material/paginator';
import { AlphaFilterComponent } from '@savvy/alpha-filter';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ClearObservable } from '../shared/classes/clear-observable';
import { FlagDefinition } from "@savvy/flags";
import { PetFilter } from './listPetsGrid.component';
import { FlagFilterComponent } from '../shared/components/flag-filter/flag-filter.component';
import { FilterPetTypeSelectorComponent } from './filter-pet-type-selector/filter-pet-type-selector.component';
import { PetBreedSelectorComponent } from './pet-breed-selector/pet-breed-selector.component';
import { FormControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

const defalutProfilePicDog = '/assets/images/dog-profile.png';
const defalutProfilePicCat = '/assets/images/cat-profile.webp';

@Component({
  selector: 'app-list-pets-alpha',
  templateUrl: './listPetsAlpha.component.html',
  styleUrls: ['./listPetsAlpha.component.scss']
})
export class ListPetsAlphaComponent extends ClearObservable implements OnInit, OnDestroy {
  @ViewChild('flagFilterComponent', { static: false }) flagFilterComponent: FlagFilterComponent;
  @ViewChild('petTypeFilterComponent', { static: false }) petTypeFilterComponent: FilterPetTypeSelectorComponent;
  @ViewChild('petBreedFilterComponent', { static: false }) petBreedFilterComponent: PetBreedSelectorComponent;

  @ViewChild('alphabetFilterComponent', { static: false }) alphabetFilterComponent: AlphaFilterComponent;
  @Input() showFilters = true;

  public petFilter = new PetFilter();


  contextIdDto: ContextIdDto;
  subscriptions: Subscription[] = [];

  pets: Array<Pet> = [];



  selected: Pet[] = [];
  eventBus = new EventBusService();
  page = new Page();

  flagDefs: FlagDefinition[] = [];
  includeFlagDefs = new Array();
  excludeFlagDefs = new Array();
  selectedPetTypes = new Array();
  selectedPetBreeds = new Array();

  deleteOnly = false;

  sortAscending = true;
  nameSorted = true;

  viewDate = new Date();

  loaded = false;

  columns = [
    { prop: 'pet.name', name: 'Name' }
  ];

  petImage = '/assets/images/none.png';
  pageSizeOptions: number[] = [10, 25, 100, 250];
  searchKey = '';
  appendingData = false;

  constructor(
    private router: Router,
    private petService: PetService,
    private dialog: MatDialog,
    public deviceService: DeviceDetectorService,
    public trialExpiredService: TrialExpiredService,
    private contextService: ContextService,
    private notify: FloSnackbarComponent,
    private renderer: Renderer2
  ) {
    super();
  }

  @HostListener('click', ['$event'])
  handleClick(event) {
    // handle event
    if (event.srcElement?.offsetParent?.classList?.contains('alpha-letters-list')) {
      // alphabet changed
      this.searchKey = event.srcElement.innerText;
      this.page.pageNumber = 0;
      this.searchPet(this.searchKey);
    }
  }

  ngOnInit() {
    let offset = 0;
    if (sessionStorage.getItem('pageNumber')) {
      offset = Number(sessionStorage.getItem('pageNumber'));
      sessionStorage.removeItem('pageNumber');
    }

    this.page.size = 20;
    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
        //  this.navChange.add(MenuItemTypeEnum.ListPets);
        //     this.loadBreeds();
        const selectFromGrid = sessionStorage.getItem('selectFromGrid');
        if (this.deviceService.isMobile() || selectFromGrid === 'false') {
          this.loadAllPets();
        } else {
          this.setPage({ offset, size: this.page.size });
        }

        setTimeout(() => {
          if (this.alphabetFilterComponent) {
            this.renderer.listen(this.alphabetFilterComponent.searchListEl.nativeElement, 'scroll', this.onScrollList.bind(this));

            this.alphabetFilterComponent.form.get('search').valueChanges
              .pipe(
                takeUntil(this.destroy$),
                debounceTime(600),
                distinctUntilChanged()
              )
              .subscribe(search => {
                this.searchKey = search;
                if (search) {
                  this.page.pageNumber = 0;
                  this.searchPet(search);
                } else {
                  this.loadAllPets();
                }
              });
          }
        }, 500);

      }
    }));
  }

  resetData() {
    if (this.alphabetFilterComponent) {
      this.alphabetFilterComponent.form.get('search').setValue('');
      this.searchKey = '';
    }
    this.loadAllPets();
  }

  onScrollList(e) {
    if (e.currentTarget) {
      // In chrome and some browser scroll is given to body tag
      const pos = e.currentTarget.scrollTop + e.currentTarget.offsetHeight;
      const max = e.currentTarget.scrollHeight;
      // pos/max will give you the distance between scroll bottom and and bottom of screen in percentage.
      if ((pos + 200) >= max && !this.searchKey && !this.appendingData) {
        // Do your action here
        this.page.pageNumber += 1;
        this.appendingData = true;
        this.appendData();
      }
    }
  }

  appendData() {
    console.log('listInstances in listPets.component.ts');
    const req = <ListPetsFiltered>{};
    req.pageNum = (this.page.pageNumber + 1);
    req.pageSize = this.page.size;
    req.ascending = this.sortAscending;
    req.deleteOnly = this.deleteOnly;
    req.petTypes = this.selectedPetTypes;
    req.petBreeds = this.selectedPetBreeds;
    req.includeFlags = this.includeFlagDefs;

    this.petService.listByFilter(req).subscribe(response => {
      this.pets = [...this.pets, ...response.petPage.contents];
      this.page.totalElements = response.petPage.totalElements;
      this.page.totalPages = response.petPage.totalPages;
      this.appendingData = false;
    });
  }

  searchPet(search: string) {
    const req = <ListPetsFiltered>{};
    req.pageNum = (this.page.pageNumber + 1);
    req.pageSize = this.page.size;
    req.ascending = this.sortAscending;
    req.searchString = search;
    req.deleteOnly = this.petFilter.deleteOnly;
    req.excludeFlags = this.petFilter.excludeFlagDefs;
    req.includeFlags = this.petFilter.includeFlagDefs;
    req.petBreeds = this.petFilter.selectedPetBreeds;
    req.petTypes = this.petFilter.selectedPetTypes;

    this.petService.searchByFilter(req)
      .subscribe(response => {
        this.handlePets(response);
      });
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getUrl(reference: string) {
    if (reference) {
      return ConfService.apiUrl() + '/rest/storage?public=false&contextId=' +
        this.contextIdDto.contextId + '&contextIdType=' +
        this.contextIdDto.contextIdType + '&id=' + reference;
    }
    return null;
  }

  getPetImage(pet: Pet) {
    if (pet?.images && pet?.images.length > 0) {
      return this.getUrl(pet.images[0].fileReferenceId);
    } else if (pet?.profilePicReference) {
      return this.getUrl(pet?.profilePicReference);
    }
    if (pet.petType === 'Cat') {
      return defalutProfilePicCat;
    }
    return defalutProfilePicDog;
  }

  setPage(pageInfo) {
    console.log('pageInfo is ' + pageInfo);
    this.page.pageNumber = pageInfo.offset;
    this.page.size = pageInfo.size ? pageInfo.size : this.page.size;
    this.listInstances();
  }

  listInstances() {
    console.log('listInstances in ListPets.component.ts');
    const req = <ListPetsFiltered>{};
    req.pageNum = (this.page.pageNumber + 1);
    req.pageSize = this.page.size;
    req.ascending = this.petFilter.sortAscending;
    req.deleteOnly = this.petFilter.deleteOnly;
    req.petTypes = this.petFilter.selectedPetTypes;
    req.includeFlags = this.petFilter.includeFlagDefs;
    req.excludeFlags = this.petFilter.excludeFlagDefs;
    req.petBreeds = this.petFilter.selectedPetBreeds;

    this.loaded = false;
    this.petService.listByFilter(req).subscribe(response => {
      this.handlePets(response);
    });
  }

  loadAllPets() {
    this.searchKey = '';
    this.setPage({ offset: 0, size: 100 });
  }


  onSort(event) {
    // event was triggered, start sort sequence
    console.log('Sort1', event);
    console.log('Sort2', event.sorts);
    console.log('Sort2[0]', event.sorts[0]);
    this.sortAscending = event.newValue === 'asc';
    this.nameSorted = event.column.name === 'Name';

    // Reset back to page 1
    this.setPage({ offset: 0, size: 20 });
  }

  handlePets(response: ListPetsFilteredResponse) {
    this.pets = response.petPage.contents;
    this.page.totalElements = response.petPage.totalElements;
    this.page.totalPages = response.petPage.totalPages;
    if (!this.pets.length) {
      this.pets.push({
        name: ` You don't have any pets starting ${this.searchKey ? 'from ' + this.searchKey : ''}`,
        ownerId: '', id: '', petOwnerId: ''
      });
      this.notify.message = ` You don't have any pets starting ${this.searchKey ? 'from ' + this.searchKey : ''}`;
      this.notify.open();
    }
    this.pets = [...this.pets];
    this.loaded = true;
  }

  onSelect(event) {
    if (event?.selected[0]?.id) {
      sessionStorage.setItem('selectFromGrid', 'true');
      sessionStorage.setItem('pageNumber', String(this.page.pageNumber));
      this.router.navigate(['/pets/view',
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        event?.selected[0]?.id]);
    }
  }

  onSelectAlpha(event) {
    if (event?.id) {
      sessionStorage.setItem('selectFromGrid', 'false');
      sessionStorage.setItem('pageNumber', String(this.page.pageNumber));
      this.router.navigate(['/pets/view',
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        event?.id]);
    }
  }

  create() {
    this.router.navigate(['/pets/create',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType]);
  }

  updatePet(pet: Pet) {
    if (!pet.ownerId) {
      console.warn('fault');
    } else {
      this.petService.updatePet(pet).subscribe(response => {
          this.setPage({ offset: 0, size: this.page.size });
        });
    }
  }

  archive(row: Pet) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Archive',
        message: `Are you sure you want to archive this pet ${row.name}?`
      },
      height: 'auto',
      width: '360px',
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && row?.id) {
        this.petService.deletePet(
          row?.id
        ).subscribe(response => {
          this.notify.message = 'Pet Archived Successfully';
          this.notify.open();
          this.listInstances();
        });
      }
    });
  }

  unarchive(row: Pet) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Unarchive',
        message: `Are you sure you want to unarchive this pet ${row.name}?`
      },
      height: 'auto',
      width: '360px',
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.petService.undoDeletePet(
          row.id).subscribe(response => {
            this.notify.message = 'Pet Unarchived Successfully';
            this.notify.open();
            this.listInstances();
          });
      }
    });
  }

  pageChanged(event: PageEvent) {
    if (this.searchKey) {
      this.searchKey = '';
    }
    this.setPage({ offset: event.pageIndex, size: event.pageSize });
  }

  searchKeyUpdated(searchText) {
    this.petFilter.searchKey = searchText;
    this.searchPet(this.petFilter.searchKey);
  }

  includeFlagsChange(flagDefs: FlagDefinition[]) {
    this.petFilter.includeFlagDefs = new Array();
    if (flagDefs !== undefined) {
      flagDefs.forEach(flagDef => {
        this.petFilter.includeFlagDefs.push(flagDef.id);
      });
    }
    this.searchPet(this.petFilter.searchKey);
  }

  excludeFlagsChange(flagDefs: FlagDefinition[]) {
    this.petFilter.excludeFlagDefs = new Array();
    if (flagDefs !== undefined) {
      flagDefs.forEach(flagDef => {
        this.petFilter.excludeFlagDefs.push(flagDef.id);
      });
    }
    this.searchPet(this.petFilter.searchKey);
  }

  petTypeSelectionChange(event) {
    this.petFilter.selectedPetTypes = new Array();
    if (event !== undefined) {
      this.petFilter.selectedPetTypes.push(event);
    }
    this.searchPet(this.petFilter.searchKey);
  }

  petBreedSelectionChange(event) {
    this.petFilter.selectedPetBreeds = new Array();

    if (event !== undefined) {
      this.petFilter.selectedPetBreeds.push(event);
    }
    this.searchPet(this.petFilter.searchKey);
  }

  resetFilters() {
    this.petFilter = new PetFilter();
    this.flagFilterComponent.formGroup = new UntypedFormGroup({
      includeFlags: new UntypedFormControl([]),
      excludeFlags: new UntypedFormControl([]),
    });
    this.petTypeFilterComponent.selectedPetType = new FormControl<string>('');
    this.petBreedFilterComponent.selectedPetBreed = new FormControl<string>('');
    this.searchPet(this.petFilter.searchKey);
  }

  archivedChanged(event: any) {
    console.log('event is ', event);
    this.petFilter.deleteOnly = event.value;
    this.searchPet(this.petFilter.searchKey);
  }



}
