<div [formGroup]="form" *ngIf="elementInstanceDto">

  <mat-form-field appearance="outline">
    <mat-label>{{fieldDefinition.label | translate}}</mat-label>


    <input matInput [(colorPicker)]="color"
           [style.background]="color"
           style="height:100%; width:100%"
           [value]="color"
           [required]="fieldDefinition.required"
           (colorPickerChange)="setColor($event)"
           [cpOutputFormat]="'hex'"
           placeholder="{{fieldDefinition.placeholder | translate}}"
    />
    <mat-error *ngIf="form.controls[elementInstanceDto.instanceId].hasError('required')">
      {{fieldDefinition.label + ' is ' | translate}} <strong>{{'required in the format #123343' | translate}}</strong>
    </mat-error>
  </mat-form-field>
</div>

