import { Injectable } from '@angular/core';
import { WorkflowState, PhoneNumber } from '@savvy/workflow-instance';
import { Subject } from 'rxjs';

class StateChangeData {
  state: WorkflowState;
  askForReview?: boolean;
  cancellationReason?: string;
  contact?: PhoneNumber;
}

@Injectable({
  providedIn: 'root'
})
export class ViewAppointmentV2Service {
  private updateStateSource: Subject<WorkflowState> = new Subject();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public updateState$ = this.updateStateSource.asObservable();

  private confirmStateUpdateSource: Subject<StateChangeData> = new Subject();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public confirmStateUpdate$ = this.confirmStateUpdateSource.asObservable();

  constructor() { }

  updateState(state: WorkflowState) {
    this.updateStateSource.next(state);
  }

  confirmUpdateState(state?: StateChangeData) {
    this.confirmStateUpdateSource.next(state);
  }

}
