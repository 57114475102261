<mat-form-field appearance="outline" class="w-100">
  <mat-label>{{'PET_TYPE' | translate}}</mat-label>
  <mat-select placeholder="{{'SEARCH_PET_TYPE' | translate}}" [formControl]="selectedPetType" name="selectedPetType"
    required>
    <mat-option>
      <ngx-mat-select-search [placeholderLabel]="'SEARCH_TYPES' | translate" [formControl]="petTypeFilter">
      </ngx-mat-select-search>
    </mat-option>
    <mat-option [value]="'-1'" disabled>
      <button mat-flat-button color="accent" type="button" (click)="createPetType()">
        {{'ADD_NEW' | translate}}
      </button>
    </mat-option>
    <mat-option *ngFor="let petType of filteredPetTypes | async" [value]="petType.name">
      {{ petType.name }}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="selectedPetType?.errors && (selectedPetType.touched || selectedPetType.dirty)">{{'SELECT_PET_TYPE' | translate}}</mat-error>
</mat-form-field>
