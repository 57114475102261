import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppointmentService, UpdateAppointmentNotes } from '@savvy/appointment';
import { ContextIdDto } from '@savvy/user';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';


@Component({
  selector: 'app-appointment-notes-v2',
  templateUrl: './appointmentNotesV2.component.html'
})
export class AppointmentNotesV2Component {

  notes: string;
  contextIdDto: ContextIdDto;
  appointmentId: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private appointmentService: AppointmentService,
              private notify: FloSnackbarComponent,
              public dialogRef: MatDialogRef<AppointmentNotesV2Component>) {

    this.contextIdDto = data.contextIdDto;
    this.appointmentId = data.appointmentId;
    this.loadNotes();
  }

  loadNotes() {
    this.appointmentService.loadAppointment(
      this.appointmentId,
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
    ).subscribe(response=> {
      this.notes = response?.notes;
    }, error => {
      console.log('not a v2 appointment?');
    });
  }

  save() {
    if (!this.appointmentId) {
      console.warn('No apt id');
    } else {
      const req = <UpdateAppointmentNotes>{};
      req.contextIdDto = this.contextIdDto;
      req.appointmentId = this.appointmentId;
      req.notes = this.notes;
      this.appointmentService.updateAppointmentNotesV2(req).subscribe(response => {
        this.notify.message = 'Updated notes';
        this.notify.open();
        this.dialogRef.close(this.notes);
      }, error => {
        console.log('error');
      });
    }
  }
}
