<form class="pl-1">
  <div class="selection-message">{{'YOU_HAVE_SELECTED' | translate}} <span
      class="highlight-message">{{createBoardingBookingV2SyncService.selectedRuns.length}} {{'RUN' | translate}}<span
        *ngIf="createBoardingBookingV2SyncService.selectedRuns?.length > 1">s</span></span> {{'WITH' | translate}}
    <span class="highlight-message">{{createBoardingBookingV2SyncService.getTotalPets()}} {{'PET' | translate}}<span
        *ngIf="createBoardingBookingV2SyncService.getTotalPets() > 1">s</span></span> {{'FOR' | translate}}
    <span class="highlight-message" *ngIf="createBoardingBookingV2SyncService?.totalDays > 0">
      {{createBoardingBookingV2SyncService.totalDays}} {{'DAYS' | translate}}.
    </span>
    <span class="highlight-message" *ngIf="createBoardingBookingV2SyncService?.totalHours > 0">
      {{createBoardingBookingV2SyncService.totalHours}} {{'HOURS' | translate}}.
    </span>
  </div>
  <div class="amount-label">{{'TOTAL_PAYABLE_AMOUNT_IS' | translate}} :</div>
  <div class="total-amount">
    {{createBoardingBookingV2SyncService.totalAmount | currency:currencyCode:'symbol'}}
    <span class="mx-1 small" *ngIf="createBoardingBookingV2SyncService?.totalAmountDue">
      {{'DEPOSIT_PAYABLE_IS' | translate}}
      <span class="deposite-amount">
        {{createBoardingBookingV2SyncService.totalAmountDue | currency:currencyCode:'symbol'}}
      </span>
    </span>
  </div>
</form>
