import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { ContextIdDto } from '@savvy/quickbooks';
import { ChangeListener } from '../../../../element/changeListener';
import { EventBusService } from '../../../../element/EventBusService';
import { Page } from '../../../../shared/model/page';
import { NavChangeService } from '../../../../element/NavChangeService';
import { UrlLookupService } from '../../../../shared/services/urlLookup.service';
import { ElementControlService } from '../../../../element/ElementControlService';
import { FloSnackbarComponent } from '../../../../snackbar/floSnackbar.component';
import { Vaccination, VaccinationService } from "@savvy/vaccination";
import { MatDialog } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { Pet } from "@savvy/pet";
import { AddEditPetVaccinationComponent } from '../../../../shared/components/add-edit-pet-vaccination/add-edit-pet-vaccination.component';
import { takeUntil } from "rxjs/operators";

@Component({
  selector: 'app-view-pet-vaccinations',
  templateUrl: './view-pet-vaccinations.component.html',
  styleUrls: ['./view-pet-vaccinations.component.scss']
})
export class ViewPetVaccinationsComponent implements OnInit, OnChanges {

  @Input() contextIdDto: ContextIdDto;
  form: UntypedFormGroup;
  changeListener = new ChangeListener();
  @Input() pet: Pet;
  @Input() vaccinations: Array<Vaccination> = [];
  @Input() dueVaccinations: Array<Vaccination> = [];
  selected: Vaccination[] = [];

  loadEiViewResponse: any;
  eventBus = new EventBusService();
  page = new Page();

  loading = false;
  private sub: any;

  destroy$ = new Subject();

  @Output() loadVaccinations = new EventEmitter();

  showExpireNotif = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navChange: NavChangeService,
    private urlLookupService: UrlLookupService,
    private ecs: ElementControlService,
    private notify: FloSnackbarComponent,
    private vaccinationApi: VaccinationService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.loadDueVaccinations();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadDueVaccinations();
  }

  onSelect(event) {

  }


  setPage(pageInfo) {
    console.log('pageInfo is ', pageInfo);
    this.page.pageNumber = pageInfo.offset;
  }

  loadDueVaccinations() {
    if (this.contextIdDto?.contextId && this.pet && this.pet.id) {
      this.vaccinationApi.getDueVaccinationsByOwnerIdAndPetId(this.contextIdDto.contextId, this.contextIdDto.contextIdType, this.pet.id).subscribe(res => {
        this.dueVaccinations = res;
      });
    }
  }

  addVaccination() {

    console.log(this.pet);
    const dialogRef = this.dialog.open(AddEditPetVaccinationComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        petId: this.pet.id,
        customerId: this.pet.petOwnerId
      },
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this.destroy$)).subscribe(result => {
        console.log('back from dialog close');
        this.loadVaccinations.emit();
      });
  }

  closeExpiredNotif() {
    this.showExpireNotif = false;
  }

  getDueVaccines() {
    let dueVaccines = '';

    for (let due of this.dueVaccinations) {
      if (dueVaccines.length > 0) {
        dueVaccines = dueVaccines + ", ";
      }
      dueVaccines = dueVaccines + due.nameOfVaccination;
    }

    return dueVaccines;
  }

}
