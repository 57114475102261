import {Routes} from '@angular/router';
import {BookComponent} from './book.component';
import {BookWizardComponent} from './bookWizard.component';

export const SimpleBookingPublicRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'book/:contextId/:contextIdType',
        component: BookComponent
      },
      {
        path: 'bookWizard/:contextId/:contextIdType',
        component: BookWizardComponent
      },
      {
        path: 'book/:contextId/:contextIdType/:debug',
        component: BookComponent
      }
    ]
  }
];
