import { EventEmitter } from '@angular/core';
import { CustomerId, MessageId, QuoteId, ReminderId, OrderId, InvoiceId, TaskId, EntityInstanceId } from '@savvy/view-definition';
import { CalendarEntryStateChangeEvent, CalendarEvent, EventType, UiEvent } from '../event/UiEvent';
import { StudentId } from '@savvy/student';
import { LeadId } from '@savvy/leads';

export class EventBusService {
  public eventAdded$: EventEmitter<UiEvent>;
  public created = new Date();
  private events: UiEvent[] = [];

  constructor() {
    this.eventAdded$ = new EventEmitter();
  }

  public list(): UiEvent[] {
    return this.events;
  }

  public addEvent(eventType: EventType): void {
    const newEvent = <UiEvent>{};
    newEvent.eventType = eventType;
    this.add(newEvent);
  }

  public addCustomerEvent(eventType: EventType, customerId: CustomerId): void {
    const newEvent = <UiEvent>{};
    newEvent.eventType = eventType;
    newEvent.customerId = customerId;
    this.add(newEvent);
  }

  public addStudentEvent(eventType: EventType, studentId: StudentId): void {
    const newEvent = <UiEvent>{};
    newEvent.eventType = eventType;
    newEvent.studentId = studentId;
    this.add(newEvent);
  }
  public addLeadEvent(eventType: EventType, leadId: LeadId): void {
    const newEvent = <UiEvent>{};
    newEvent.eventType = eventType;
    newEvent.leadId = leadId;
    this.add(newEvent);
  }

  public addMessageEvent(eventType: EventType, messageId: MessageId): void {
    const newEvent = <UiEvent>{};
    newEvent.eventType = eventType;
    newEvent.messageId = messageId;
    this.add(newEvent);
  }
  public addQuoteEvent(eventType: EventType, quoteId: QuoteId): void {
    const newEvent = <UiEvent>{};
    newEvent.eventType = eventType;
    newEvent.quoteId = quoteId;
    this.add(newEvent);
  }
  public addReminderEvent(eventType: EventType, reminderId: ReminderId): void {
    const newEvent = <UiEvent>{};
    newEvent.eventType = eventType;
    newEvent.reminderId = reminderId;
    this.add(newEvent);
  }

  public addOrderEvent(eventType: EventType, orderId: OrderId): void {
    const newEvent = <UiEvent>{};
    newEvent.eventType = eventType;
    newEvent.orderId = orderId;
    this.add(newEvent);
  }

  public addInvoiceEvent(eventType: EventType, invoiceId: InvoiceId): void {
    const newEvent = <UiEvent>{};
    newEvent.eventType = eventType;
    newEvent.invoiceId = invoiceId;
    this.add(newEvent);
  }

  public addPaymentRequestEvent(eventType: EventType, invoiceId: InvoiceId): void {
    const newEvent = <UiEvent>{};
    newEvent.eventType = eventType;
    newEvent.invoiceId = invoiceId;
    this.add(newEvent);
  }

  public addTaskEvent(eventType: EventType, taskId: TaskId): void {
    const newEvent = <UiEvent>{};
    newEvent.eventType = eventType;
    newEvent.taskId = taskId;
    this.add(newEvent);
  }

  public addNumUnreadNotificationsEvent(eventType: EventType, numUnreadNotifications: number): void {
    const newEvent = <UiEvent>{};
    newEvent.eventType = eventType;
    newEvent.numUnreadNotifications = numUnreadNotifications;
    this.add(newEvent);
  }

  public addEntityEvent(eventType: EventType, entityInstanceId: EntityInstanceId): void {
    const newEvent = <UiEvent>{};
    newEvent.eventType = eventType;
    newEvent.entityInstanceId = entityInstanceId;
    this.add(newEvent);
  }

  public addCalendarEvent(eventType: EventType): void {
    const newEvent = <UiEvent>{};
    newEvent.eventType = eventType;
    this.add(newEvent);
  }

  public addRefreshCalendarEvent(): void {
    const newEvent = <UiEvent>{};
    newEvent.eventType = EventType.REFRESH_CALENDAR;
    this.add(newEvent);
  }

  public addCalendarEntryStateChange(entityInstanceId: EntityInstanceId, state: string): void {
    const newEvent = <UiEvent>{};
    newEvent.eventType = EventType.CALENDAR_ENTRY_STATE_CHANGE;
    newEvent.calendarEntryStateChangeEvent = <CalendarEntryStateChangeEvent>{};
    newEvent.calendarEntryStateChangeEvent.state = state;
    newEvent.calendarEntryStateChangeEvent.entityInstanceId = entityInstanceId;
    this.add(newEvent);
  }

  public addUpdateCalendarEvent(eventType: EventType, calendarEvent: CalendarEvent): void {
    const newEvent = <UiEvent>{};
    newEvent.eventType = eventType;
    newEvent.calendarEvent = calendarEvent;
    this.add(newEvent);
  }

  public add(event: UiEvent): void {
    console.log('adding event ' + event.eventType + '  on event bus ' + this.created);
    this.events.push(event);
    if (this.eventAdded$) {
      this.eventAdded$.emit(event);
    } else {
      console.log('err: event added is not initialised');
    }
  }
}
