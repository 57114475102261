import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ChangeListener } from './changeListener';
import { EventBusService } from './EventBusService';
import { ViewContextDto } from '@savvy/view-composite';
import { SectionDefinitionDto, ContextIdDto } from '@savvy/view-definition';

@Component({
    selector: 'app-section',
    templateUrl: 'section.component.html'
})
export class SectionComponent {

    @Input() sectionDefinitionDto: SectionDefinitionDto;
    @Input() contextIdDto: ContextIdDto;
    @Input() viewContext: ViewContextDto;
    @Input() form: UntypedFormGroup;
    @Input() eventBus: EventBusService;
    @Input() additionalDataMapDto: any;
    @Input() showPlaceholder: boolean;
    @Input() changeListener: ChangeListener;
}
