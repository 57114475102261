<mat-toolbar color="primary">{{'WORKFLOWS' | translate}}</mat-toolbar>
<ngx-datatable
        class='material'
        [columns]="columns"
        [columnMode]="'force'"
        [headerHeight]="50"
        [footerHeight]="50"
        [rowHeight]="'auto'"
        [limit]="10"
        [selectionType]="'cell'"
        (select)="onSelect($event)"
        [rows]='workflowLists'>
</ngx-datatable>
<button (click)="create()" mat-raised-button color="primary">{{'CREATE' | translate}}</button>
