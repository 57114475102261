import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkflowdefService } from '@savvy/workflow-definition';
import { ContextService } from '../context.service';
import { ContextIdDto } from '@savvy/app';
import { Subscription } from 'rxjs';
import { AddEditWorkflowTypeDialogComponent } from './add-edit-workflow-dialog/add-edit-workflow-type-dialog.component';
import { WorkflowDefinition } from '@savvy/workflow-definition/model/workflowDefinition';
import { DeleteWorkflowDefinition } from '@savvy/workflow-definition/model/models';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';

@Component({
  selector: 'app-workflow-lists-by-type',
  templateUrl: 'workflow-list-by-type.html'
})
export class WorkflowlistsByTypeComponent implements OnInit, OnDestroy {
  @Input() workflowDefinitionType: string;
  contextIdDto: ContextIdDto;
  subscriptions: Subscription[] = [];
  sub2: any;

  workflowLists: WorkflowDefinition[] = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private notify: FloSnackbarComponent,
    private contextService: ContextService,
    private api: WorkflowdefService) { }

  ngOnInit() {
    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
        this.getLists();
        // this.sub2 = this.route.params.subscribe(params => {
        //   this.workflowDefinitionType = params['type'];
        // });
      }
    }));
  }

  create() {
    const createWorkflowDialogRef = this.dialog.open(AddEditWorkflowTypeDialogComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        workflowDefinitionType: this.workflowDefinitionType
      },
      height: 'auto',
      width: '600px',
    });

    createWorkflowDialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.response) {
        this.getLists();
      }
    });

  }

  onSelect(event) {
    console.log('event is ', event);
    if (event && event.selected && event.selected.length) {
      this.router.navigate(['workflow/workflow-details',
        event.selected[0].id, true, true
      ]);
    }
  }


  getLists() {
    this.api.loadByTypeV2(this.contextIdDto.contextId, 'ENV_ID',
      this.workflowDefinitionType).subscribe(
        workflowLists => {
          this.workflowLists = workflowLists.workflowDefinitions;
        });
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  delete(row: WorkflowDefinition) {

    const req = <DeleteWorkflowDefinition>{};
    req.contextIdDto = this.contextIdDto;
    req.workflowDefinitionId = row.id;
    this.api.deleteWorkflowDefinition(req).subscribe(() => {
      this.notify.message = 'Removed Workflow';
      this.notify.open();
      this.getLists();
    });

  }
}
