<mat-toolbar color="primary">
  <mat-toolbar-row>{{'ADD_TIP' | translate}}</mat-toolbar-row>
</mat-toolbar>

<mat-card appearance="outlined">
  <mat-card-content>
    <form #form="ngForm">

      <mat-form-field appearance="outline">
        <mat-label>{{'PAYMENT_DATE' | translate}}</mat-label>
        <input matInput [(ngModel)]="model.dateTime" name="paymentDate" [owlDateTime]="dtPicker2" [required]="true"
          #paymentDate="ngModel" id="paymentDate" placeholder="{{'ENTER_PAYMENT_DATE' | translate}}"
          style="width: 100%">

        <mat-icon class="open-icon" matSuffix [owlDateTimeTrigger]="dtPicker2">event</mat-icon>
        <owl-date-time [stepMinute]="'15'" #dtPicker2></owl-date-time>
      </mat-form-field>
      <!--
      <small *ngIf="form.controls['paymentDate'].hasError('required') && form.controls['paymentDate'].touched" class="mat-text-warn">Payment Date is require.</small>
      -->
      <br>


      <mat-form-field appearance="outline" *ngIf="methods">
        <mat-label>{{'PAYMENT_METHOD' | translate}}</mat-label>
        <mat-select [(ngModel)]="model.paymentMethod" name="paymentMethod" #dateTime="ngModel" id="paymentMethod"
          [required]="true" placeholder="{{'SELECT_PAYMENT_METHOD' | translate}}">

          <mat-option *ngFor="let method of methods" [value]="method">{{method}}</mat-option>
        </mat-select>
      </mat-form-field>
      <br>

      <mat-form-field style="width: 100%" appearance="outline">
        <mat-label>{{'AMOUNT' | translate}}</mat-label>
        <input type="text" appNumberOnly [decimalPoints]="2" matInput [(ngModel)]="model.amount" name="amount"
          #amount="ngModel" id="amount" required placeholder="{{'ENTER_AMOUNT' | translate}}" style="width: 100%">
      </mat-form-field>
      <br>


      <mat-form-field style="width: 100%" appearance="outline">
        <mat-label>{{'USER' | translate}}</mat-label>

        <mat-select placeholder="{{'USER' | translate}}" [compareWith]="compareUserFn" name="userInput"
          #userInput="ngModel" [(ngModel)]="model.userId" required>
          <mat-option *ngFor="let user of users" [value]="setUserOptVal(user)">{{user.firstName}} {{user.lastName}}
          </mat-option>
        </mat-select>

        <mat-error *ngIf="userInput.hasError('required')">
          {{'STAFF_MEMBER_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
        </mat-error>

      </mat-form-field>

    </form>
  </mat-card-content>
  <mat-card-content>
    <button mat-raised-button color="accent" (click)="addTip()" [disabled]="!form.valid">{{'ADD' | translate}}</button>
    &nbsp;
    <button mat-raised-button (click)="dialogRef.close(false)">{{'CANCEL' | translate}}</button>
  </mat-card-content>

</mat-card>