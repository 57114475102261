import { Injectable } from '@angular/core';
import { ContextService } from '../../context.service';
import { LookAndFeelService } from '@savvy/look-and-feel';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { LookAndFeel } from '@savvy/look-and-feel';

export interface TimeValue {
  displayValue: string;
  actualValue: string;
}

export interface LookAndFeelConfig {
  lookAndFeel: LookAndFeel;
  minuteInterval: number;
  timeArray: Array<TimeValue>;
  defaultTimeValue: string;
  isTimePicker: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class LookAndFeelSharedService {
  lookAndFeel: LookAndFeel;
  lookAndFeelConfig = {} as LookAndFeelConfig;
  lookAndFeelSubject = new BehaviorSubject(null);
  constructor(
    private contextService: ContextService,
    private lookAndFeelApi: LookAndFeelService) {
  }

  setLookAndFeel(lookAndFeel: LookAndFeel) {
    this.lookAndFeel = lookAndFeel;
  }

  getLookAndFeelConfig(contextIdDto) {
    if (this.lookAndFeel) {
      this.setPropertiesToLookAndFeelConfig();
      return this.lookAndFeelConfig;
    } else {
      this.lookAndFeelApi.getLookAndFeel(contextIdDto.contextId).subscribe((res: LookAndFeel) => {
        this.lookAndFeel = res;
        this.setPropertiesToLookAndFeelConfig();
        this.lookAndFeelSubject.next(true);
        return this.lookAndFeelConfig;
      });
    }
  }

  setPropertiesToLookAndFeelConfig() {
    this.lookAndFeelConfig.lookAndFeel = this.lookAndFeel;
    if (this.lookAndFeel.timeWidgetPreferences && this.lookAndFeel.timeWidgetPreferences.timeDropDownPeriod) {
      this.lookAndFeelConfig.minuteInterval = this.lookAndFeel.timeWidgetPreferences.timeDropDownPeriod;
      this.lookAndFeelConfig.timeArray = this.generateLocalisedTimeArray(
        this.lookAndFeelConfig.minuteInterval,
        this.lookAndFeel.timeDisplayPreferences);
      this.lookAndFeelConfig.defaultTimeValue = this.getRoundedDefaultTimeValue(this.lookAndFeelConfig.minuteInterval);
    } else {
      this.lookAndFeelConfig.isTimePicker = true;
    }
  }

  updateLookAndFeelConfig(contextIdDto) {
    this.lookAndFeelApi.getLookAndFeel(contextIdDto.contextId).subscribe((res: LookAndFeel) => {
      this.lookAndFeel = res;
    });
  }

  generateLocalisedTimeArray(timeInterval, timeDisplayPreferences: string):TimeValue[] {
    const startHour = 6;
    const numHoursToShow = 18;
    const start = moment().startOf('day').add(startHour, 'hours');
    const times = numHoursToShow * (60 / timeInterval);
    const timeArray = [];

    for (let i = 0; i <= times; i++) {
      // console.log('i', i);
      if (timeDisplayPreferences && timeDisplayPreferences === 'TWELVE_HOUR_CLOCK') {
        // console.log('TWELVE_HOUR_CLOCK');
        const toPrint = moment(start)
          .add(timeInterval * i, 'minutes')
          .format('h:mm a');

        // console.log('adding ', toPrint);

        let timeValue = <TimeValue>{};
        timeValue.displayValue = toPrint;
        timeValue.actualValue = moment(start)
          .add(timeInterval * i, 'minutes')
          .format('HH:mm');



        timeArray.push(timeValue);
      } else {
        let timeValue = <TimeValue>{};
        timeValue.actualValue = moment(start)
          .add(timeInterval * i, 'minutes')
          .format('HH:mm');

        const toPrint = moment(start)
          .add(timeInterval * i, 'minutes')
          .format('HH:mm');

        timeValue.displayValue = toPrint;
        timeArray.push(timeValue);
      }
    }

    return timeArray;
  }


  getRoundedDefaultTimeValue(minuteInterval) {
    const now = moment();
    const remainder = minuteInterval - (now.minute() % minuteInterval);

    return moment(now).add(remainder, 'minutes').format('HH:mm');
  }
}
