import { Component, Input, OnInit } from '@angular/core';

import { UntypedFormGroup } from '@angular/forms';
import { EicompService, EntityChangeEvent } from '@savvy/entity-instance-composite';
import { ElementControlService } from '../ElementControlService';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { ChangeListener } from '../changeListener';
import { DateUtils } from '../../dates/DateUtils';
import { DaateFieldDefinitionDto, ElementInstanceDto, FieldValueDto } from '@savvy/view-composite';
import { ContextIdDto } from '@savvy/datalist';

@Component({
  selector: 'app-date-instance',
  templateUrl: 'dateInstance.component.html'
})
export class DateInstanceComponent implements OnInit {

  @Input() dateDefinition: DaateFieldDefinitionDto;
  @Input() elementInstanceDto: ElementInstanceDto;
  @Input() entityInstanceId: string;
  @Input() contextIdDto: ContextIdDto;
  @Input() form: UntypedFormGroup;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;

  constructor(private api: EicompService,
    private dateUtils: DateUtils,
    private translateService: TranslateService,
    private adapter: DateAdapter<any>,
    private ecs: ElementControlService) {

    this.adapter.setLocale(this.translateService.currentLang);
  }

  get isValid() {
    return this.form.controls[this.elementInstanceDto.instanceId].valid;
  }

  ngOnInit(): void {
    if (this.form.controls[this.elementInstanceDto.instanceId]) {
      this.form.controls[this.elementInstanceDto.instanceId].valueChanges.subscribe((value) =>  {
        console.log('changed value=' + value);
        this.onChange(value);
      });
    }
  }

  onChange(newValue: Date) {
    if (this.form.controls[this.elementInstanceDto.instanceId].invalid) {
      return;
    }
    let dateStr;
    if (newValue) {
      dateStr = this.dateUtils.getDateAsStringDash(newValue);
    }
    console.log('dateStr ' + dateStr);

    if (!/^[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(dateStr)) {
      this.form.controls[this.elementInstanceDto.instanceId].setErrors({ incorrect: true });
      return;
    }
    this.form.controls[this.elementInstanceDto.instanceId].setErrors(null);


    const fieldValue: FieldValueDto = {
      // Expect it in this format: yyyy-MM-dd
      dateValue: dateStr,
      valueType: FieldValueDto.ValueTypeEnum.Date
    };

    const req: EntityChangeEvent = {
      contextIdDto: this.contextIdDto,
      fieldValue,
      entityInstanceId: this.entityInstanceId,
      fieldInstanceId: this.elementInstanceDto.instanceId
    };

    // There is no loadProductsResponse from this, do we care?
    this.api.entityInstanceChangeEvent(req).subscribe(
      response => {
        console.log('response ' + response);
        this.ecs.handleChangedElements(response.changedElementList, this.form);

      }
    );
  }



}

