import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FloSnackbarComponent} from '../snackbar/floSnackbar.component';
import {ContextIdDto, FlagDefinition, FlagDefService} from '@savvy/flags';
import {AddEditFlagDefComponent} from './addEditFlagDef.component';

@Component({
  selector: 'app-entity-flag-defs',
  templateUrl: './entityFlagDefs.component.html'
})
export class EntityFlagDefsComponent implements OnInit {
  flagDefinitions: FlagDefinition[];
  selected: FlagDefinition[] = [];
  flagDefinition: FlagDefinition;
  contextIdDto: ContextIdDto;
  entityDefinitionId: string;
  sub: any;


  @ViewChild('addEditFlag', { static: false }) addEditFlag: AddEditFlagDefComponent;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: FlagDefService,
    private notify: FloSnackbarComponent) {
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
       this.contextIdDto = <ContextIdDto>{};
      this.contextIdDto.contextId = params['contextId'];
      this.contextIdDto.contextIdType = params['contextIdType'];

      this.entityDefinitionId = params['entityDefinitionId'];
      console.log('got entityDefinitionId ' + this.entityDefinitionId);

      if (this.entityDefinitionId) {
        this.load();
      }
    });
  }

  private load() {
    this.api.listEntityFlagDefs(this.entityDefinitionId, this.contextIdDto.contextId,
      this.contextIdDto.contextIdType).subscribe(response => {
        this.flagDefinitions = response.flagDefinitions;
      });
  }

  onSelect(event) {
    console.log('Event: select', event, this.selected);
    this.flagDefinition = this.selected[0];
    this.router.navigate(['/flags/editFlagDef',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      this.flagDefinition.id]);

  }

  create() {
    this.router.navigate(['/flags/addEditFlagDef',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      FlagDefinition.FlagDefinitionTypeEnum.Entity,
      this.entityDefinitionId]);
  }
}
