<div fxLayout="row" *ngIf="showStatus">
  <div *ngIf="hasEiFlags">
    <mat-chip-listbox #chipList>
      <mat-chip-option color="{{flagDto.flagDefinition.colour}}" *ngFor="let flagDto of eiFlagsResponse.flagDto"
        removable="false" selected>
        {{flagDto.flagDefinition.name}}
      </mat-chip-option>
    </mat-chip-listbox>
    &nbsp;&nbsp;&nbsp;
  </div>
  <div *ngIf="hasCustomerFlags">
    <mat-chip-listbox #chipList>
      <mat-chip-option color="{{flagDto.flagDefinition.colour}}" *ngFor="let flagDto of customerFlagsResponse.flagDto"
        removable="false" selected>
        {{flagDto.flagDefinition.name}}
      </mat-chip-option>
    </mat-chip-listbox>
    &nbsp;&nbsp;
  </div>

  <div *ngIf="hasLastSeen">
    Last Seen ({{loadLastAppointmentResponse.numWeeks}} Weeks Ago)
    &nbsp;&nbsp;
  </div>

  &nbsp;&nbsp;
  <div *ngIf="hasWorkflowState">
    <span *ngFor="let workflowStateDto of workflowStatsDto.workflowStatDtoList" style="padding-right: 25px">
      {{ workflowStateDto.state }}
      <span matBadge="{{workflowStateDto.numStates}}" matBadgeOverlap="false"
        matBadgeColor="{{workflowStateDto.colour}}">
      </span>
    </span>
    &nbsp;
  </div>
  <br>
  <br>
</div>
