import { NgModule } from '@angular/core';
import { TimeSelectComponent } from "./time-select.component";
import { TimeToDateModule } from "../../core/time-to-date.pipe";
import { CommonModule } from "@angular/common";
import { FloMaterialModule } from "../flo.material.module";

@NgModule({
  imports: [CommonModule, FloMaterialModule, TimeToDateModule],
  exports: [TimeSelectComponent],
  declarations: [TimeSelectComponent],
  providers: [],
})
export class TimeSelectModule {
}
