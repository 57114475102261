import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventBusService } from './EventBusService';
import { NavChangeService } from './NavChangeService';
import { ChangeListener } from './changeListener';
import { UiEvent } from '../event/UiEvent';
import { TablerowService } from '@savvy/cached-view';
import { TrialExpiredService } from '../shared/services/trial-expired.service';
import { Page } from '../shared/model/page';
import { EntitydefsService } from '@savvy/entity-definition';
import { ViewcompService } from '@savvy/view-composite';
import { ContextIdDto } from '@savvy/quickbooks';
import { CachedtableviewdefsService } from '@savvy/view-definition';

@Component({
  selector: 'app-list-cached-entity-instances-comp',
  templateUrl: 'listCachedEntityInstances.component.html',
  styleUrls: ['./listCachedEntityInstances.component.scss']
})
export class ListCachedEntityInstancesComponent implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() cachedTableViewDefinitionId: string;
  stereotype;
  loadCachedTableViewResponse: any;
  cachedTableViewDefinition: any;

  entityDefinitionDto: any;
  eventBus = new EventBusService();
  public changeListener = new ChangeListener();

  columns: any[] = [];
  rows = [];

  selected: any[] = [];
  page = new Page();
  pageSize = 20;

  @Input() showPlaceholder: boolean;

  constructor(
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    public trialExpiredService: TrialExpiredService,
    private cachedtableviewdefsService: CachedtableviewdefsService,
    private viewCompApi: ViewcompService,
    private router: Router,
    private edApi: EntitydefsService,
    private navChange: NavChangeService,
    private api: TablerowService) {


  }

  ngOnInit() {
    this.eventBus.eventAdded$.subscribe(event => this.onEvent(event));
    console.log('inside on init listEntityInstances');
    this.loadCachedTableViewResponse = null;
    console.log('params changed');

    if (this.contextIdDto && this.cachedTableViewDefinitionId) {
      this.loadInitial();
    }
  }

  loadInitial() {
    this.viewCompApi.loadDataForCachedTable(
      this.cachedTableViewDefinitionId,
      this.page.size,
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
    ).subscribe(response => {
      this.cachedTableViewDefinition = response.cachedTableViewDefinition;

      this.entityDefinitionDto = response.entityDefinitionDto;
      this.loadCachedTableViewResponse = response.loadCachedTableViewResponse;
      this.buildTable();
    });
  }

  loadCachedTableWith(contextIdDto: ContextIdDto, cachedTableViewDefinitionId: string) {
    this.cachedTableViewDefinitionId = cachedTableViewDefinitionId;
    this.contextIdDto = contextIdDto;
    this.loadCachedTable();
  }

  loadEd() {
    if (this.cachedTableViewDefinition && this.cachedTableViewDefinition.entityDefinitionId) {
      this.edApi.getEntityDefinition(this.cachedTableViewDefinition.entityDefinitionId.id,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType).subscribe(response => {
          this.entityDefinitionDto = response;
          this.setPage({ offset: 0, size: 20 });
        });
    }
  }

  buildTable() {
    if (this.loadCachedTableViewResponse && this.loadCachedTableViewResponse.table && this.loadCachedTableViewResponse.table.contents) {
      const newRows = new Array();
      for (const aRow of this.loadCachedTableViewResponse.table.contents) {
        newRows.push(this.getRow(aRow));
      }
      const newCols = new Array();
      for (const aCol of this.cachedTableViewDefinition.tableDefinition.columnDefinitions) {
        // console.log('pushing col ' + aCol);
        newCols.push(this.getCol(aCol.columnName));
      }
      this.rows = newRows;
      this.columns = newCols;
      // this.cd.detectChanges();
    }
  }

  getRow(cachedTableRow) {
    const row = {};
    cachedTableRow.values.forEach((item, index) => {
      row[this.cachedTableViewDefinition.tableDefinition.columnDefinitions[index].columnName] = item;
    });
    row['id'] = cachedTableRow.cachedTableRow.entityInstanceId;
    return row;
  }

  /**
   * Populate the table with new data based on the page number
   *
   * @param page The page to select
   */
  setPage(pageInfo) {
    console.log('pageInfo is ' + pageInfo);
    this.page.pageNumber = pageInfo.offset;
    this.listInstances();
  }

  onSelect() {
    /*this.router.navigate(['/entity/viewEntity',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      this.selected[0].cachedTableRow.entityInstanceId]);*/
  }

  onActivate(event) {
    if (event.type === 'click') {
      this.router.navigate(['/entity/viewEntity',
        event.row.id,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType]);
    }
  }

  getCol(colDef: string) {
    const col = {};
    // console.log('colDef is ' + colDef);
    col['prop'] = colDef;
    col['name'] = colDef;
    return col;
  }

  loadCachedTable() {

    this.cachedtableviewdefsService.getCachedTableViewDefinition(this.cachedTableViewDefinitionId,
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType).subscribe(response => {
        this.cachedTableViewDefinition = response;
        this.loadEd();
      });
  }

  listInstances() {

    this.navChange.add('listEntity:' + this.cachedTableViewDefinitionId);

    console.log('inside list cached entity instances with '
      + 'entity def id [' + this.cachedTableViewDefinitionId + ']'
    );
    this.api.listTableView(
      this.cachedTableViewDefinitionId,
      (this.page.pageNumber + 1),
      this.page.size,
      this.cachedTableViewDefinition?.primarySortCriteria?.ascending,
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
    ).subscribe(response => {
      this.loadCachedTableViewResponse = response;
      this.buildTable();
    });
  }

  create() {
    console.log('inside create');
    if (this.cachedTableViewDefinition && this.cachedTableViewDefinition.entityDefinitionId) {
      this.router.navigate(['/entity/createEntity',
        this.cachedTableViewDefinition.entityDefinitionId.id,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType]);
    }
  }


  private onEvent(event: UiEvent): void {
    console.log('list entity instances onEvent called with event ' + event);
  }
}
