import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ContextIdDto } from '@savvy/app';
import {FlagDefHelperService} from "../../../../services/flagDefHelper.service";
import {FlagDefinition} from "@savvy/flags";

@Component({
  selector: 'app-customer-flags-panel',
  templateUrl: './customer-flags-panel.component.html',
  styleUrls: ['./customer-flags-panel.component.scss']
})
export class CustomerFlagsPanelComponent implements OnInit {

  @Input() disableCreate: boolean;
  @Input() disableDelete: boolean;
  @Input() flagIds: Array<string>;
  @Input() contextIdDto: ContextIdDto;
  @Output() deleteFlag: EventEmitter<string> = new EventEmitter();
  @Output() createFlag: EventEmitter<string> = new EventEmitter();

  constructor(public flagDefHelperService: FlagDefHelperService) {
  }

  ngOnInit(): void {
    if (!this.flagIds) {
      this.flagIds = new Array<string>();
    }
  }

  addFlag(flagDef: FlagDefinition) {
    console.log('Adding flag');
    this.createFlag.emit(flagDef.id);
  }

  removeFlag(flagId: string) {
    this.deleteFlag.emit(flagId);
  }
}
