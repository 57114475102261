import { Routes } from '@angular/router';
import { ReminderDefsComponent } from './reminderDefs.component';
import { AddEditReminderDefPageComponent } from './addEditReminderDefPage.component';
import { RemindersTodayComponent } from './remindersToday.component';

export const reminderRoutes: Routes = [
  {
    path: '',
    children: [{
      path: 'reminderDefs/:contextId/:contextIdType',
      component: ReminderDefsComponent
    },
    {
      path: 'reminderDef/:reminderDefId/:contextId/:contextIdType',
      component: AddEditReminderDefPageComponent
    },
    {
      path: 'remindersToday/:contextId/:contextIdType',
      component: RemindersTodayComponent
    }
    ]
  }
];
