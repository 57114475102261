import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddOn, AddOnDefinition, AddOnDefinitionService, AddOnsCompService, AddOnService, CreateAddOn } from '@savvy/add-ons';
import { SubscriptionDefinition } from '@savvy/product-metadata';
import { ConfirmationDialogComponent } from 'src/app/flo/confirmation-dialog/confirmation-dialog.component';
import { ContextService } from 'src/app/flo/context.service';
import * as _ from 'lodash';
import { FloSnackbarComponent } from 'src/app/flo/snackbar/floSnackbar.component';
import { ContextIdDto } from '@savvy/menu';
import { AddOnsHelperService } from '../../../services/add-ons-helper.service';
@Component({
  selector: 'app-update-subscription-in-trial-modal',
  templateUrl: './update-subscription-in-trial-modal.component.html',
  styleUrls: ['./update-subscription-in-trial-modal.component.scss']
})
export class UpdateSubscriptionInTrialModalComponent implements OnInit {

  selectedSubscription: SubscriptionDefinition;
  addOnDefs: AddOnDefinition[] = [];

  constructor(
    public dialogRef: MatDialogRef<UpdateSubscriptionInTrialModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private addOnService: AddOnService,
    private addOnDefinitionService: AddOnDefinitionService,
    private contextService: ContextService,
    private addOnCompService: AddOnsCompService,
    private notify: FloSnackbarComponent,
    public addOnsHelperService: AddOnsHelperService
  ) { }

  ngOnInit() {
    this.selectedSubscription = _.cloneDeep(this.data.subscriptionsDefs.find(sub => sub.id === this.data.selectedSubscriptionId));
    this.filterLegacyPlans();
    this.loadAddOnDefs();
  }

  filterLegacyPlans() {
    const filteredSubDefs = [];
    for (const subscriptionDefinition of this.data.subscriptionsDefs) {
      if (!subscriptionDefinition.legacy) {
        filteredSubDefs.push(subscriptionDefinition);
      } else if (subscriptionDefinition.legacy && subscriptionDefinition.id === this.selectedSubscription.id) {
        filteredSubDefs.push(subscriptionDefinition);
      }
    }
    this.data.subscriptionsDefs = _.cloneDeep(filteredSubDefs);
  }

  loadAddOnDefs() {
    this.addOnDefinitionService.loadByAppType(this.contextService.getAppType()).subscribe(response => {
      this.addOnDefs = response;
    });
  }

  selectionChanged(event: any, addOnDef: AddOnDefinition) {
    if (event.checked) {
      const addOn = <CreateAddOn>{};
      addOn.addOnDefinitionId = addOnDef.id;
      addOn.contextIdDto = this.data.contextIdDto;
      this.addOnCompService.createAddOn(addOn).subscribe(() => {
        this.notify.message = 'Enabled ' + addOnDef.name;
        this.notify.open();
        // location.reload();
        this.addOnsHelperService.loadAddOns(this.data.contextIdDto);

      }, error => {
        console.log('error', error);
        this.notify.message = 'Failed to add add-on, please update your card';
        this.notify.open();
        event.source.writeValue(false);
      });
    } else {
      this.addOnsHelperService.addOns.forEach(addOn => {
        if (addOn.addOnDefinitionId === addOnDef.id) {
          this.addOnService.deleteAddOn(addOn.id).subscribe(() => {
            this.notify.message = 'Disabled ' + addOnDef.name;
            this.notify.open();
            // location.reload();
            this.addOnsHelperService.loadAddOns(this.data.contextIdDto);
          }, error => {
            console.log('error', error);
            this.notify.message = 'Failed to remove add-on';
            this.notify.open();
            event.source.writeValue(true);
          });
        }
      });
    }
  }

  updateSubscription() {
    const name = `${this.selectedSubscription.header} - ${this.selectedSubscription.headerSummary}`;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Confirmation',
        message: `Are you sure you want to update subscription plan to <strong>${name}</strong>`
      },
      height: 'auto',
      width: '520px',
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm === 'relogin') {
        this.dialogRef.close({ id: this.selectedSubscription.id, relogin: true });
      } else if (confirm) {
        this.dialogRef.close({ id: this.selectedSubscription.id });
      } else {
        this.dialogRef.close();
      }
    });
  }

  compareObjects(o1: AddOnDefinition, o2: AddOnDefinition): boolean {
    return o1.id === o2.id;
  }

  isEnabled(addOnDef: AddOnDefinition) {
    let checked = false;
    if (this.addOnsHelperService.addOns) {
      this.addOnsHelperService.addOns.forEach(addOn => {
        if (addOn.addOnDefinitionId === addOnDef.id) {
          checked = true;
        }
      });
    }
    return checked;
  }

}
