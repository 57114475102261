


<div *ngIf="this.customer">
  <button *ngIf="notification.customerId" (click)="viewCustomer(notification.customerId)" mat-button color="accent"
          class="px-1">
    View Customer
  </button>

  <button *ngIf="notification" (click)="gotoCheckinForm()" mat-button color="accent"
          class="px-1">
    View Check In Form
  </button>
</div>
