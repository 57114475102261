import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FloMaterialModule } from '../flo.material.module';
import { EditorModule } from '../editor/editor.module';
import { EicompService } from '@savvy/entity-instance-composite';
import { EntitysService } from '@savvy/entity-instance';
import { EntitydefsService } from '@savvy/entity-definition';
import { EntityModule } from '../entity/entity.module';
import { ElementModule } from '../element/element.module';
import { ElementsService } from '@savvy/entity-definition';
import { CheckinFormDefService } from '@savvy/check-in-form';
import { CheckinFormService } from '@savvy/check-in-form';
import { EntityDefsModule } from '../entityDefs/entityDefs.module';
import { SharedModule } from '../shared/shared.module';
import { CustomerSignupFormsRoutes} from './customerSignupForms.routes';
import { CheckinFormCompService, CheckinFormDefsService } from '@savvy/check-in-form';
import { AddEditCustomerSignupFormDefComponent } from './addEditCustomerSignupFormDef.component';

import { BrandingService } from '@savvy/branding';
import {CustomerSignupFormTemplatesComponent} from './customerSignupFormTemplates.component';
import {ZeroCustomerSignupFormTemplatesComponent} from './zeroCustomerSignupFormTemplates.component';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  imports: [
    FloMaterialModule,
    ElementModule,
    EntityModule,
    EditorModule,
    EntityDefsModule,
    SharedModule,
    RouterModule.forChild(CustomerSignupFormsRoutes),
    TranslateModule,
  ],
  providers: [
    BrandingService,
    CheckinFormCompService,
    CheckinFormDefService,
    CheckinFormDefsService,
    CheckinFormService,
    EicompService,
    EntitysService,
    EntitydefsService,
    ElementsService
  ],
  declarations: [
    AddEditCustomerSignupFormDefComponent,
    CustomerSignupFormTemplatesComponent,
    ZeroCustomerSignupFormTemplatesComponent
  ]
})

export class CustomerSignupFormsModule {
}
