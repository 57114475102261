<div *ngIf="invoice && invoice.invoiceServiceRows">
  <div *ngFor="let row of invoice.invoiceServiceRows; index as idx">

    <div fxLayout="column" *ngIf="userDtos && invoiceDefinition && invoiceDefinition.serviceRowLinkedEntity">
      <app-ei-selector-with-flags [contextIdDto]="contextIdDto"
        [entityDefinitionId]="invoiceDefinition.serviceRowLinkedEntity"
        [entityDefinitionLabel]="invoiceDefinition?.serviceRowLinkedEntityLabel"
        [entityInstanceId]="row.entityInstanceId" [entityInstanceLabel]="getEiLabel(idx)"
        (eiChanged)="eiChanged(row, $event)">
      </app-ei-selector-with-flags>
    </div>

    <div fxLayout="column" *ngIf="userDtos && invoiceDefinition && invoiceDefinition.enableServiceRowUser">
      <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">

        <div fxFlex="49" *ngIf="consumerServices">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{'Service' | translate}}</mat-label>
            <mat-select placeholder="{{'Select Service' | translate}}" [(ngModel)]="row.serviceNumber" required
              (selectionChange)="serviceChanged(row, false)" name="serviceField" #serviceField="ngModel">

              <mat-option>
                <ngx-mat-select-search [placeholderLabel]="'Search Service'" ngModel
                  (ngModelChange)="filterServices($event)"></ngx-mat-select-search>
              </mat-option>
              <mat-option [value]="''">
                <button mat-flat-button color="accent" (click)="createNewService(idx)">&nbsp;&nbsp;&nbsp;&nbsp;Add
                  Service</button>
              </mat-option>

              <mat-option *ngFor="let service of filteredServices | async" [value]="service.id">
                {{ service?.serviceName }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="serviceField.hasError('required')">
              {{'Service is' | translate}} <strong>{{'required' | translate}}</strong>
            </mat-error>
          </mat-form-field>
        </div>


        <div fxFlex="49">

          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{'Price' | translate}}</mat-label>
            <input matInput type="text" appNumberOnly [decimalPoints]="2" placeholder="Price" name="unitPrice"
              #unitPrice="ngModel" [(ngModel)]="row.unitPrice" (ngModelChange)="priceChanged(row, $event)"
              id="unitPrice" required>
            <mat-error *ngIf="unitPrice.hasError('required')">
              {{'Price is' | translate}} <strong>{{'required' | translate}}</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">

        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>{{'Staff' | translate}}</mat-label>
          <mat-select placeholder="{{'Select Staff' | translate}}" [compareWith]="compareUserFn"
            [(ngModel)]="row.serviceUser" required (selectionChange)="serviceUserChanged()" name="serviceUserField"
            #serviceUserField="ngModel">
            <mat-option *ngFor="let serviceUser of userDtos" [value]="serviceUser.userId">
              {{ serviceUser.fullName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="serviceUserField.hasError('required')">
            {{'Staff is' | translate}} <strong>{{'required' | translate}}</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </div>


    <div fxLayout="column">
      <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">
        <div fxFlex="48">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'Appointment Date' | translate}}</mat-label>
            <input matInput [(ngModel)]="row.startDate" [matDatepicker]="picker" required
              (dateChange)="startDateChange(row)" [disabled]="idx > 0" (focus)="picker.open()" and
              (click)="picker.open()" placeholder="Start">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>

        <div fxFlex="24">
          <mat-form-field appearance="outline" class="w-100"
            *ngIf="timeArray && timeArray.length > 0 && userDtos && invoiceDefinition && invoiceDefinition.enableStartTimeServiceRow">
            <mat-label>{{ 'Start Time' | translate}}</mat-label>
            <mat-select placeholder="Select Start Time" (selectionChange)="startTimeChanged(row, $event.value)"
              [(ngModel)]="row.startTime" [compareWith]="compareTimeFn" required>
              <mat-option *ngFor="let time of timeArray" [value]="time.actualValue">{{time.displayValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxFlex="24">
          <mat-form-field appearance="outline" class="w-100" placeholder="End Time"
            *ngIf="timeArray && timeArray.length > 0 && userDtos && invoiceDefinition">
            <mat-label>{{ 'End Time' | translate}}</mat-label>
            <mat-select placeholder="Select End Time" (selectionChange)="endTimeChanged(row, $event.value)"
              [(ngModel)]="row.endTime" [compareWith]="compareTimeFn" required>
              <mat-option *ngFor="let time of timeArray" [value]="time.actualValue">{{time.displayValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div fxLayout="column">
      <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">
        <button mat-flat-button style="width: 100%" color="accent" (click)="createServiceRow()"
          *ngIf="invoiceDefinition?.serviceRowLinkedEntityLabel">Add another Service / {{
          invoiceDefinition.serviceRowLinkedEntityLabel }}</button>
        <button mat-flat-button style="width: 100%" color="accent" (click)="createServiceRow()"
          *ngIf="!invoiceDefinition?.serviceRowLinkedEntityLabel">Add another Service</button>
        <button (click)="deleteServiceRow(row)" class="ml-xs mat-icon-button" mat-icon-button="">
          <span class="mat-button-wrapper">
            <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">delete</mat-icon>
          </span>
          <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
          <div class="mat-button-focus-overlay"></div>
        </button>

      </div>
    </div>
    <div fxLayout="column">
      <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">
        &nbsp;
      </div>
    </div>

  </div>
</div>
