<div *ngIf="elementSetDefinition && rows">


    <!--


    THIS IS NOT NICE FOR STARTERS - AN ELEMENT SET SHOULD HAVE A NUMBER OF ROWS
    Num rows is {{ elementSetDefinition.elementDtos }}


    -->


    <ngx-datatable
            #mydatatable
            class="material"
            [headerHeight]="50"
            [limit]="5"
            [columnMode]="'force'"
            [footerHeight]="50"
            [rowHeight]="'auto'"
            [rows]="rows">

        <ngx-datatable-column name="{{elementDto.label}}" *ngFor="let elementDto of elementSetDefinition.elementDtos; let i = index">
            <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">

                <!--
                I actually want the 'Label' as per Label Panel for this when not editing.

                        Or, I want to make them all editable, all the time.
                -->
                <!--
              <span
                      title="Double click to edit"
                      (dblclick)="editing[rowIndex + '-' + elementDto.id] = true"
                      *ngIf="!editing[rowIndex + '-' + elementDto.id]">
                Value [{{value}}]
              </span>
              -->

                <!-- *ngIf="editing[rowIndex + '-' + elementDto.id]"  autofocus

                        (blur)="updateValue($event, elementDto.id, value, row)"
                         -->

                <app-element
                        [contextIdDto]="contextIdDto"
                        [elementDto]="row.rowData[i]"
                        [viewContext]="viewContext"
                        [additionalDataMapDto]="additionalDataMapDto"
                        [showPlaceholder]="false"
                        [form]="form"
                        [changeListener]="changeListener">
                </app-element>

            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
    <button mat-mini-fab color="warn" type="button" (click)="create()">
        <mat-icon class="mat-24">add</mat-icon>
    </button>
</div>


