import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import {LoadServiceDtosResponse, ServicesService, ConsumerService} from '@savvy/services';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { SimpleslotsService } from '@savvy/booking';
import { StyleService } from '../style/style.service';
import { BrandingService } from '@savvy/branding';
import { ConfService } from '../config/conf.service';
import { OrgCompositeService } from '@savvy/org';
import { DaySlotDto } from '@savvy/booking';
import { UserDto, ContextIdDto } from '@savvy/view-definition';
import { SimpleBookingDefinition, SimpleBookingDefService } from '@savvy/booking-definition';
import { ServicesHelperService } from '../shared/services/services-helper.service';

@Component({
  selector: 'app-book',
  templateUrl: 'book.component.html',
  styleUrls: ['book.component.scss']
})
export class BookComponent implements OnInit {

  sub: any;
  styleDef: string;
  logoUri: string;

  bookingDef: SimpleBookingDefinition;
  consumerServices: Array<ConsumerService>;
  users: UserDto[];

  contextIdDto: ContextIdDto;

  serviceNumber: string;
  cssClass: string;

  serviceNumbers = new Array();

  services: Array<string>;
  user: string;
  appointmentTime: Date;
  daySlot: DaySlotDto;
  countryCode: string;


  debug = false;
  selectedSlot: any;

  constructor(
    private route: ActivatedRoute,
    private orgCompApi: OrgCompositeService,
    private brandingApi: BrandingService,
    private el: ElementRef,
    private renderer: Renderer2,
    public styleService: StyleService,
    private slotApi: SimpleslotsService,
    private simpleBookingDefService: SimpleBookingDefService,
    private servicesHelperService: ServicesHelperService) {

  }

  ngOnInit(): void {
    console.log('inside book');

    this.services = new Array();
    this.sub = this.route.params.subscribe(params => {
      this.contextIdDto = <ContextIdDto>{};
      this.contextIdDto.contextId = params['contextId'];
      this.contextIdDto.contextIdType = params['contextIdType'];
      if (params['debug']) {
        this.debug = true;
      }
      console.log('loading services');
      this.loadServices();
      this.loadBookingDef();
      this.loadLogo();
      this.loadCountryCode();
    });
  }

  loadCountryCode() {
    this.orgCompApi.loadOrgFromCtx(this.contextIdDto.contextId).subscribe(response => {
      this.countryCode = response.org.countryCode;
      console.log('got countryCode ' + this.countryCode);
      if (!this.countryCode) {
        this.countryCode = 'GB';
      }
    });
  }

  isSlotAvailable(slotAndCheckDto) {
    return slotAndCheckDto.slotDto.availableUsersNames && slotAndCheckDto.slotDto.availableUsersNames.length > 0;
  }

  loadServices() {
    this.servicesHelperService.getConsumerServices(this.contextIdDto).then(consumerServices => {
      if (consumerServices.length) {
        this.consumerServices = consumerServices;
      }
    });
  }

  addService() {
    if (this.serviceNumber) {
      console.log('Adding service ' + this.serviceNumber);
      this.serviceNumbers.push(this.serviceNumber);
    }
  }

  deleteService(serviceNumber: string) {

    const index = this.serviceNumbers.indexOf(serviceNumber, 0);
    if (index > -1) {
      this.serviceNumbers.splice(index, 1);
    }
  }

  loadLogo() {

    this.brandingApi.getAllBrands(this.contextIdDto.contextId).subscribe(response => {
      if (response.length > 0) {
        this.logoUri = ConfService.apiUrl() + '/rest/storage?contextId=' +
          this.contextIdDto.contextId + '&contextIdType=' +
          this.contextIdDto.contextIdType + '&id=' + response[0].imageReference;
      } else {
        console.log('brand response is ' + response);
      }
    });
  }

  setStyle() {
    this.styleDef = '';
    if (this.bookingDef.bookingLookAndFeelDefinition.backgroundColour) {
      this.renderer.setStyle(this.el.nativeElement.ownerDocument.body
        , 'backgroundColor', this.bookingDef.bookingLookAndFeelDefinition.backgroundColour);
      // this.renderer.setStyle(this.el.nativeElement.ownerDocument.body,'backgroundImage',
      // 'url(\'https://s3.amazonaws.com/daysmartonlinebooking/accounts/921aaf4d-e9b8-4f1c-a843-8879d40d3cfe/images/background_stripes.png\')');
      this.styleDef = 'background-color: ' + this.bookingDef.bookingLookAndFeelDefinition.backgroundColour;
    }
    this.styleDef = this.styleDef + '; height: 100%';
  }

  loadBookingDef() {

    this.simpleBookingDefService.getAllSimpleBookingDefinition(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
    ).subscribe(response => {
      console.log('response');
      if (response.length > 0) {
        this.bookingDef = response[0];
        this.setStyle();
        this.styleService.add(this.bookingDef.bookingLookAndFeelDefinition.theme);
      }
    });
  }

  serviceChanged() {
    this.services = new Array();
    this.services.push(this.getServiceId(this.serviceNumber));
    this.searchWithSelected();
  }

  selectSlot(slot) {
    this.selectedSlot = slot;
  }

  searchWithSelected() {
    this.serviceNumbers = new Array();
    this.addService();
    this.search();
  }

  search() {
    console.log('search');
    console.log('search' + this.appointmentTime);
    console.log('search' + this.bookingDef);

    if (this.serviceNumbers.length === 0) {

    } else {
      if (this.appointmentTime) {
        console.log('serviceNumber is ' + this.serviceNumber);
        const req = <any>{};

        req.contextIdDto = this.contextIdDto;
        req.bookingDefinitionId = this.bookingDef.bookingDefinitionId;
        req.slotFilterDto = <any>{};
        req.slotFilterDto.serviceNumbers = this.serviceNumbers;
        req.slotFilterDto.locationIds = null;
        req.slotFilterDto.users = null;
        console.log('day is ' + this.appointmentTime.getDate());
        console.log('month is ' + this.appointmentTime.getMonth());
        console.log('full year is ' + this.appointmentTime.getFullYear());


        req.day = this.appointmentTime.getDate();
        req.month = this.appointmentTime.getMonth() + 1;
        req.year = this.appointmentTime.getFullYear();
        this.slotApi.getSimpleSlotsForDay(req).subscribe(response => {
          console.log('got response');
          this.daySlot = response;
        }
        );
      } else {
        console.log('not searching');
      }
    }
  }

  getServiceName(serviceId: string) {
    let serviceDto: ConsumerService;

    this.consumerServices.forEach(service => {
      if (service.id === serviceId) {
        serviceDto = service;
      }
    });
    if (serviceDto) {
      return serviceDto?.serviceName;
    }
    return '';
  }

  getServiceId(serviceId: string): string {
    let serviceRetId: string;

    this.consumerServices.forEach(service => {
      if (service.id === serviceId) {
        serviceRetId = service.id;
      }
    });
    if (serviceRetId) {
      return serviceRetId;
    }
    return null;
  }
}
