import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { StyleService } from '../style/style.service';
import { BrandingService } from '@savvy/branding';
import { ConfService } from '../config/conf.service';
import { takeUntil } from 'rxjs/operators';
import { PhoneNumberHelperService } from '../shared/services/phone-number-helper.service';
import { SimpleBookingDefinition } from '@savvy/booking-definition';
import { ContextIdDto } from '@savvy/entity-instance-composite';
import { UserCurrencyService } from '../shared/services/userCurrency.service';

@Component({
  selector: 'app-book-wizard',
  templateUrl: 'bookWizard.component.html',
  styleUrls: ['bookWizard.component.scss']
})
export class BookWizardComponent implements OnInit {

  private destroy$ = new Subject();
  sub: any;
  styleDef: string;
  logoUri: string;

  serviceNumbers: string[];
  bookingDef: SimpleBookingDefinition;
  contextIdDto: ContextIdDto;
  currencyCode: string;
  cssClass: string;
  debug = false;
  slotDto: any;
  wizardStep = 'selectService';

  constructor(
    private route: ActivatedRoute,
    private brandingApi: BrandingService,
    private el: ElementRef,
    private renderer: Renderer2,
    public styleService: StyleService,
    private sharedService: PhoneNumberHelperService,
    private userCurrencyService: UserCurrencyService
    //  private bookingDefApi: SimpleBookingDefApiExtension
  ) {

  }

  ngOnInit(): void {
    console.log('inside book');

    this.sub = this.route.params.subscribe(params => {
      this.contextIdDto = <ContextIdDto>{};
      this.contextIdDto.contextId = params['contextId'];
      this.contextIdDto.contextIdType = params['contextIdType'];
      if (params['debug']) {
        this.debug = true;
      }
      console.log('loading services');
      this.loadBookingDef();
      this.loadLogo();
    });
  }

  serviceSelected() {
    // If booking def - allow pick user?
    if (this.bookingDef.bookingFilterDefinition && this.bookingDef.bookingFilterDefinition.showServiceUser) {
      // Go to
      this.wizardStep = 'selectUser';
    } else {
      this.wizardStep = 'selectSlot';
    }
  }

  bookingSummary() {

    if (this.bookingDef.bookingPaymentDefinition && this.bookingDef.bookingPaymentDefinition.paymentRequired) {
      // Go to
      this.wizardStep = 'takePayment';
    } else {
      this.wizardStep = 'bookingConfirmation';
    }
  }

  paymentMade() {
    this.wizardStep = 'bookingConfirmation';
  }

  loadLogo() {

    this.brandingApi.getAllBrands(this.contextIdDto.contextId).subscribe(response => {
      if (response.length > 0) {
        this.logoUri = ConfService.apiUrl() + '/rest/storage?contextId=' +
          this.contextIdDto.contextId + '&contextIdType=' +
          this.contextIdDto.contextIdType + '&id=' + response[0].imageReference;
      } else {
        console.log('brand response is ' + response);
      }
    });
  }

  setStyle() {
    this.styleDef = '';
    if (this.bookingDef.bookingLookAndFeelDefinition.backgroundColour) {
      this.renderer.setStyle(this.el.nativeElement.ownerDocument.body, 'backgroundColor',
        this.bookingDef.bookingLookAndFeelDefinition.backgroundColour);
      // this.renderer.setStyle(this.el.nativeElement.ownerDocument.body,'backgroundImage',
      // 'url(\'https://s3.amazonaws.com/daysmartonlinebooking/accounts/921aaf4d-e9b8-4f1c-a843-8879d40d3cfe/images/background_stripes.png\')');
      this.styleDef = 'background-color: ' + this.bookingDef.bookingLookAndFeelDefinition.backgroundColour;
    }
    this.styleDef = this.styleDef + '; height: 100%';
  }

  loadBookingDef() {

    // this.bookingDefApi.getAllSimpleBookingDefinition(
    //   this.contextIdDto.contextId,
    //   this.contextIdDto.contextIdType
    // ).subscribe(response => {
    //   console.log('response');
    //   if (response.length > 0) {
    //     this.bookingDef = response[0];
    //     this.setStyle();
    //     this.styleService.add(this.bookingDef.bookingLookAndFeelDefinition.theme);
    //   }
    // });
  }

  getCurrencyCode() {
    this.userCurrencyService.getDefaultCurrency(this.contextIdDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.currencyCode = res.org.currencyCode ? res.org.currencyCode : this.userCurrencyService.defaultCurrency;
      });
  }
}
