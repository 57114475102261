<mat-card appearance="outlined" class="p-0 overflow-hidden mx-0">
  <div class="cover-photo coverimg mdc-mb-2" [style.background-image]="'url(' + coverPic + ')'" (click)="editCover()"></div>
  <div fxLayout="column" fxLayout.gt-sm="row">
    <div fxFlex="nogrow" class="mdc-px-3 text-center">
      <div class="avatar avatar-150 circle border-3 pet-pic coverimg mx-auto" [style.background-image]="'url(' + profilePic + ')'" (click)="editProfilePic()">
        <input [hidden]="true" id="input-view-file-id-profile" required (change)="handleProfileInput($event)" [multiple]="false" type="file">
      </div>
    </div>
    <div fxFlex class="mdc-px-3 ">
      <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="16px">
        <div fxFlex class="mdc-mb-2">
          <h5 class="dog-name mdc-mb-1">
            <span class="mr-1 font-weight-bold" [ngClass]="{ 'deceased-pet': pet?.deceased }">{{ pet.name || 'N/A'
              }}</span>
            <span *ngIf="pet?.deceased"> (D) </span>
            <span *ngIf="!pet?.deleted && !pet?.deceased" class="chiptag green vm px-2">{{'ACTIVE' | translate}}</span>
            <span *ngIf="pet?.deleted && !pet?.deceased" class="chiptag red vm px-2">{{'INACTIVE' | translate}}</span>
            <button *ngIf="pet?.deleted && !pet?.deceased" mat-raised-button color="accent" (click)="unarchive(pet)">
              <mat-icon>restart_alt</mat-icon> Re-activate
            </button>
            <span *ngIf="pet?.deceased" class="chiptag red vm px-2">{{'DECEASED' | translate}}</span>
          </h5>

          <div fxLayout="column" fxLayout.gt-sm="row">
            <div fxFlex="100" fxFlex.gt-sm="nogrow" class="pr-2">
              <p class="my-2"><span class="mat-text-secondary mr-1">{{'BREED' | translate}}:</span><span>{{ pet?.breedLabel || pet?.breed || 'N/A'
                  }}</span></p>
              <p class="my-2"><span class="mat-text-secondary mr-1">{{'GENDER' | translate}}:</span><span>{{ pet?.bitch ? 'Female' :
                  'Male' }}</span></p>
              <p class="my-2"><span class="mat-text-secondary mr-1">{{'DATE_OF_BIRTH' | translate}}:</span><span>{{ (pet?.dob | localizedDate:
                  'shortDate') || 'N/A'}}</span></p>
              <p class="my-2" *ngIf="!contextService.isPetStay()"><span class="mat-text-secondary mr-1">{{'REGULAR_BLADES' | translate}}:</span><span>{{ pet?.blades || 'N/A'
                  }}</span></p>
              <p class="my-2"><span class="mat-text-secondary mr-1">{{'WEIGHT' | translate}}:</span><span>{{ pet?.weight || 'N/A'
                  }}</span></p>
              <p class="my-2"><span class="mat-text-secondary mr-1">{{'INSURED' | translate}}:</span><span>{{ pet?.insured ? 'Yes' : 'No'
                  || 'N/A'
                  }}</span></p>
            </div>
            <div fxFlex="100" fxFlex.gt-sm="nogrow" class="pr-1">
              <p class="my-2"><span class="mat-text-secondary mr-1">{{'DECEASED' | translate}}:</span><span>{{ pet?.deceased ? 'Yes' :
                  'No'
                  }}</span></p>
              <p class="my-2"><span class="mat-text-secondary mr-1">{{'COLOUR' | translate}}:</span><span>{{ pet?.colour || 'N/A'
                  }}</span></p>
              <p class="my-2"><span class="mat-text-secondary mr-1">{{'PRICE' | translate}}:</span><span>{{ pet?.groomPrice ?
                  (pet?.groomPrice | currency:currencyCode:'symbol') :
                  'N/A' }}</span></p>
              <p class="my-2"><span class="mat-text-secondary mr-1">{{'NEUTERED' | translate}}:</span><span>{{ pet?.spayedOrNeutered ?
                  'Yes' : 'No' }}</span></p>
              <p class="my-2"><span class="mat-text-secondary mr-1">{{'AFRAID_OF_NOISES' | translate}}:</span><span>{{ pet?.afraidOfNoises
                  || 'N/A'
                  }}</span></p>
            </div>
          </div>
          <div fxLayout="column" fxLayout.gt-sm="row" *ngIf="!contextService.isPetStay()">
            <p class="my-2"><span class="mat-text-secondary mr-1">{{'VISIT_FREQ_WEEKS' | translate}}:</span><span>{{
                pet?.recommendedVisitFrequencyInWeeks ? pet?.recommendedVisitFrequencyInWeeks : 'N/A' }}</span></p>
          </div>
        </div>

        <div fxFlex="nogrow" class="mdc-mb-2">
          <button mat-raised-button color="accent" (click)="createAppointment(pet)" class="mdc-mb-2">
            <mat-icon>event</mat-icon> {{'CREATE_APPOINTMENT' | translate}}
          </button>


          <div *ngIf="pet.id">
            <app-pets-flags-panel [contextIdDto]="contextIdDto" [flagIds]="pet?.flags" (deleteFlag)="deleteFlag($event)" (createFlag)="createFlag($event)">
            </app-pets-flags-panel>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>