

<mat-toolbar color="primary" *ngIf="!zeroState">
    <span class="mr-1 ml-1">{{'TASK_BOARD' | translate}}</span>
    <div class="example-spacer"></div>
    <button mat-flat-button (click)="toggleTaskView()">{{'TOGGLE_VIEW' | translate}}</button>

</mat-toolbar>

<mat-sidenav-container class="app-inner" style="width: 100%">

    <mat-sidenav #taskPanel position="end" class="task-panel" mode="over" opened="false" style="width:80%">
        <app-show-task [contextIdDto]="contextIdDto" [eventBus]="eventBus" [visible]="activePanel === 'showTask'"></app-show-task>
        <app-add-edit-task [contextIdDto]="contextIdDto" [eventBus]="eventBus"
                           [visible]="activePanel === 'addEditTask'"></app-add-edit-task>
    </mat-sidenav>

  <app-zero-tasks *ngIf="zeroState" [contextIdDto]="contextIdDto" [eventBus]="eventBus">

  </app-zero-tasks>


  <div class="taskboard page-height">
    <div class="taskboard-wrapper" *ngFor="let board of taskboard">
      <div class="taskboard-list">
        <div class="taskboard-header text-uppercase">
          <strong>{{board.title | translate}}</strong>
        </div>
        <div class="taskboard-cards" [dragula]='"task-group"'>
          <div class="taskboard-task" *ngFor="let task of board.tasks" [ngClass]="task.class">
            <div class="taskboard-task-title">{{task.title | translate}}</div>
            <small class="card-text text-muted">{{task.description | translate}}</small>
          </div>
        </div>
      </div>
    </div>
  </div>


    <button mat-fab color="accent" (click)="createTask()" *ngIf="!zeroState" class="mat-fab-bottom-right">
        <mat-icon class="mat-24">add</mat-icon>
    </button>
</mat-sidenav-container>
