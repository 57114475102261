import { Component, Input, OnInit } from '@angular/core';
import { ContextIdDto } from '@savvy/view-definition';

@Component({
  selector: 'app-book-login',
  templateUrl: 'bookLogin.component.html'
})
export class BookLoginComponent implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() bookingDef: any;

  loggedIn: boolean;


  ngOnInit(): void {

  }

}
