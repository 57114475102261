import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { ServicesCompositeService } from '@savvy/services';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfService } from '../config/conf.service';
import { ContextIdDto, EntityInstanceId } from '@savvy/view-definition';

@Component({
  selector: 'app-select-user',
  templateUrl: 'bookSelectUser.component.html',
  styleUrls: ['bookSelectUser.component.scss']
})
export class BookSelectUserComponent implements OnInit {
  @Input() contextIdDto: ContextIdDto;
  @Output() serviceUsersSelected = new EventEmitter();

  serviceUser: string;
  cssClass: string;

  serviceUsers = new Array();
  services: Array<EntityInstanceId>;
  user: string;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private el: ElementRef,
    private renderer: Renderer2,
    private serviceApi: ServicesCompositeService) {

  }

  ngOnInit(): void {

  }


  /*
  loadUsers() {
    this.userApi.getEnvUsersFilterCustomers(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType)
      .subscribe(response => {
        this.users = response;
      });
  }
*/


}
