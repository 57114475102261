<mat-card appearance="outlined" *ngIf="customer">
  <!-- header title -->
  <mat-card-header class="pb-1">
    <div fxLayout="row wrap" fxLayoutGap="16px" fxLayoutAlign=" center" class="w-100">
      <div fxFlex="nogrow">
        <button mat-icon-button color="accent"><mat-icon class="vm">contacts</mat-icon></button>
      </div>
      <div fxFlex>
        <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign=" center">
          <div class="avatar avatar-40 border-2 rounded-circle coverimg " fxFlex="nogrow" style="background-image: url('assets/images/customer-profile.png');"></div>
          <div fxFlex fxLayout="row wrap">
            <div fxFlex="">
              <p class="mb-0">
                <span class="fw-bold">{{customer?.firstName}} {{customer?.lastName}}</span>
                <span class="ml-1"><small class="mat-text-secondary"><span class="status-online"></span>Online</small></span>
              </p>
            </div>
            <div fxFlex="100" class="text-truncate">
              <p class="small mat-text-secondary mb-0">Last seen 10 October 2023</p>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="nogrow">
        <button mat-icon-button><mat-icon class="vm">star_outline</mat-icon></button>
      </div>
      <div fxFlex="nogrow">
        <button mat-icon-button [matMenuTriggerFor]="menu" class="mat-text-secondary"><mat-icon>more_vert</mat-icon></button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item>Item 1</button>
          <button mat-menu-item>Item 2</button>
        </mat-menu>
      </div>
    </div>
  </mat-card-header>

  <mat-card-content class="scroll-box">
    <div *ngFor="let message of messageList;let i = index" class="chat-conversation-user" [ngClass]="{ 'them': message?.replyMessage, 'me': !message?.replyMessage }">
      <div [ngSwitch]="message.type">
        <div *ngSwitchCase="messageTypesEnum.Email">
          <div class="chat-conversation-message">
            <!-- <div *ngIf="message?.fileReference?.fileReferenceId"
          class="avatar avatar-150 border-3 coverimg pet-pic mx-auto"
          [style.background-image]="'url(' + getUrl(message?.fileReference?.fileReferenceId) + ')'"
          (click)="handleEditImageInput($event)">
          <div *ngIf="message?.fileReference?.fileReferenceId"
            (click)="downloadImage(message?.fileReference?.fileReferenceId)" style="cursor: pointer;">
            <mat-icon>download</mat-icon>
          </div>
        </div> -->
            <p [innerHTML]="message?.emailMessage?.body"></p>
          </div>
          <div class="chat-date small text-secondary mb-0" *ngIf="message?.replyMessage">
            {{message?.emailMessage.recipientsAsString}} {{ message?.sentLocalDateTime | localizedDate:'short' }}
          </div>
          <div class="chat-date small text-secondary mb-0" *ngIf="!message?.replyMessage">
            You {{ message?.sentLocalDateTime | localizedDate:'short' }}
          </div>
        </div>
        <div *ngSwitchCase="messageTypesEnum.Sms">
          <div class="chat-conversation-message">
            <!-- <div *ngIf="message?.fileReference?.fileReferenceId"
          class="avatar avatar-150 border-3 coverimg pet-pic mx-auto"
          [style.background-image]="'url(' + getUrl(message?.fileReference?.fileReferenceId) + ')'"
          (click)="handleEditImageInput($event)">
          <div *ngIf="message?.fileReference?.fileReferenceId"
            (click)="downloadImage(message?.fileReference?.fileReferenceId)" style="cursor: pointer;">
            <mat-icon>download</mat-icon>
          </div>
        </div> -->
            <p [innerHTML]="message?.smsMessage?.textMessage"></p>
          </div>
          <div class="chat-date small text-secondary mb-0" *ngIf="message?.replyMessage">
            {{message?.smsMessage.recipientsAsString}} {{ message?.sentLocalDateTime | localizedDate:'short' }}
          </div>
          <div class="chat-date small text-secondary mb-0" *ngIf="!message?.replyMessage">
            You {{ message?.sentLocalDateTime | localizedDate:'short' }}
          </div>
        </div>
        <div *ngSwitchCase="messageTypesEnum.Savvy">
        </div>
        <div *ngSwitchDefault>Unknown</div>
      </div>
    </div>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions class="mx-0 pt-2">
    <form class="">
      <mat-form-field appearance="outline" class="w-100 pb-0">
        <mat-label>{{'WRITE_RESPONSE' | translate}}...</mat-label>
        <input matInput placeholder="{{'WRITE_RESPONSE' | translate}}..." aria-invalid="false" aria-required="false">
        <label matSuffix for="chat-file-id-upload">
          <mat-icon>attach_file</mat-icon>
        </label>
        <button matSuffix mat-icon-button color="accent">
          <mat-icon>send</mat-icon>
        </button>
      </mat-form-field>
    </form>
  </mat-card-actions>

</mat-card>