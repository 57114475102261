<!-- <mat-toolbar color="primary">{{'Payment Plans' | translate}}</mat-toolbar> -->
<ng-container *ngIf="!hasSavvyPay">
  <div class="flexbox-parent">
    <div fxLayout="row" fxLayoutAlign="center center">
      <div style="text-align:center;">
        <div class="avatar avatar-100 circle border-3 mb-2 d-inline-block">
          <mat-icon class="h1 h-auto w-auto">edit_attributes</mat-icon>
        </div>
        <!-- <app-zero-image name="Plans"></app-zero-image><br> -->
        <div>
          <h6>
            {{'CONNECT_YOUR_ACCOUNT' | translate}} <b>{{'SAVVY_PAY' | translate}}</b> {{'TO_START_ASSIGNING' | translate}} <b>{{'PLANS' | translate}}</b> {{'TO_CUSTOMERS' | translate}}
          </h6>
          <button mat-raised-button color="primary" (click)="goToSavvyPay()">{{'CONNECT_TO_SAVVY_PAY' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<mat-tab-group>
  <mat-tab label="{{'PAYMENT_PLANS' | translate}}">
    <app-plan-definition *ngIf="hasSavvyPay" [contextIdDto]="contextIdDto" [isModal]="isModal"></app-plan-definition>
  </mat-tab>
  <mat-tab label="{{'CUSTOMERS' | translate}}">
    <app-customer-plans *ngIf="hasSavvyPay" [contextIdDto]="contextIdDto" [isModal]="isModal"></app-customer-plans>
  </mat-tab>
  <mat-tab label="{{'PAST_DUE' | translate}}">
    <app-past-due-plans *ngIf="hasSavvyPay" [contextIdDto]="contextIdDto" [isModal]="isModal"></app-past-due-plans>
  </mat-tab>
</mat-tab-group>