import { Component, Injector, Input, OnInit } from '@angular/core';

import { UntypedFormGroup } from '@angular/forms';
import { ElementControlService } from '../ElementControlService';
import { EicompService, EntityChangeEvent } from '@savvy/entity-instance-composite';
import { ChangeListener } from '../changeListener';
import { StorageService } from '@savvy/storage';
import { forkJoin, Observable } from 'rxjs';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';
import { ConfirmationModalComponent } from '../../shared/components/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfService } from '../../config/conf.service';
import { WindowRef } from '../../windowWrapper';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { ElementInstanceDto, FieldDefinitionDto, FileReference, FieldFilesValue } from '@savvy/view-composite';
import { ContextIdDto } from '@savvy/datalist';
import { FileValue } from '@savvy/view-definition';

@Component({
  selector: 'app-files',
  templateUrl: 'filesFieldInstance.component.html',
  styleUrls: ['filesFieldInstance.component.scss', '../commonPanel.component.scss']
})
export class FilesFieldInstanceComponent implements OnInit {

  @Input() fieldDefinitionDto: FieldDefinitionDto;
  @Input() elementInstanceDto: ElementInstanceDto;
  @Input() entityInstanceId: string;
  @Input() contextIdDto: ContextIdDto;
  @Input() additionalDataMapDto: any;
  @Input() form: UntypedFormGroup;
  @Input() showPlaceholder: boolean;

  @Input() changeListener: ChangeListener;

  savedFiles: Array<FileValue> = [];
  droppedFilesArray: File[];
  isMobile: boolean;

  constructor(
    private api: EicompService,
    private storage: StorageService,
    private ecs: ElementControlService,
    private notify: FloSnackbarComponent,
    private dialog: MatDialog,
    private injector: Injector,
    private deviceService: DeviceDetectorService) {
  }

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
    if (!this.elementInstanceDto.fieldInstanceDto.value.fieldFilesValue) {
      this.elementInstanceDto.fieldInstanceDto.value.fieldFilesValue = {} as FieldFilesValue;
    }
    if (!this.elementInstanceDto.fieldInstanceDto.value.fieldFilesValue.fileValues) {
      this.elementInstanceDto.fieldInstanceDto.value.fieldFilesValue.fileValues = new Array();
    }
    const files = this.elementInstanceDto.fieldInstanceDto.value.fieldFilesValue.fileValues;
    if (files.length) {
      this.savedFiles = files;
    }
  }

  onChange() {
    console.log('value is ', this.elementInstanceDto.fieldInstanceDto.value);
    const req: EntityChangeEvent = {
      contextIdDto: this.contextIdDto,
      fieldValue: this.elementInstanceDto.fieldInstanceDto.value,
      entityInstanceId: this.entityInstanceId,
      fieldInstanceId: this.elementInstanceDto.instanceId
    };

    this.api.entityInstanceChangeEvent(req).subscribe(
      response => {
        this.ecs.handleChangedElements(response.changedElementList, this.form);
        if (this.changeListener) {
          if (this.changeListener) {
            this.changeListener.add('change');
          }
          this.notify.message = 'Successfully updated files!';
          this.notify.open();
        }
      }
    );
  }

  postFile(fileToUpload: File): Observable<any> {
    return this.storage.uploadFileToStorage(this.contextIdDto.contextId, this.contextIdDto.contextIdType, fileToUpload);
  }

  updateFiles() {
    console.log('files now ', this.savedFiles);
    this.elementInstanceDto.fieldInstanceDto.value.fieldFilesValue.fileValues = this.savedFiles;
  }

  saveFiles(files) {
    console.log('saveFiles called', files.length);
    const filesObservable = [];
    for (const file of files) {
      filesObservable.push(this.postFile(file));
    }

    forkJoin(filesObservable).subscribe(res => {
      res.forEach((elem, index) => {
        const newFile: FileValue = {
          fileName: files[index].name, mimeType: files[index].type, fileReferenceId: elem['fileReference']
        };

        console.log('calling savedFiles push', newFile);
        this.savedFiles.push(newFile);
      });
      console.log('calling updateFiles');
      this.updateFiles();
      this.onChange();
    });

  }

  removeFile(index) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        name: 'file'
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.savedFiles.splice(index, 1);
        this.updateFiles();
        this.onChange();
      }
    });
  }

  droppedFiles(files: NgxFileDropEntry[]) {
    console.log('droppedFiles called');
    this.droppedFilesArray = [];
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          if (file.size > 1048576) {
            this.notify.message = 'File is too large, max file size is 1048576 bytes';
            this.notify.open();
          } else {
            this.droppedFilesArray.push(file);
            if (files.length === this.droppedFilesArray.length) {
              this.saveFiles(this.droppedFilesArray);
            }
          }
        });
      }
    }
  }

  downloadFile(file: FileValue) {
    console.log('file ', file);
    console.log('fileReferenceId ', file.fileReferenceId);
    const url = ConfService.apiUrl() + '/rest/storage?contextId=' + this.contextIdDto.contextId +
      '&contextIdType=' + this.contextIdDto.contextIdType +
      '&id=' + file.fileReferenceId;

    const nativeWindow = this.injector.get(WindowRef).nativeWindow;
    nativeWindow.location.href = url;
  }
}

