 <div *ngIf="elementInstanceDto.instanceId">

  <!-- HERE isTypeahead {{ isTypeahead }}


   isSelect {{ isSelect }}

   linkedEntityDefinition.allowMulti {{ linkedEntityDefinition.allowMulti }}
   -->

    <app-linked-entity-instance-multi-typeahead *ngIf="isTypeahead && linkedEntityDefinition.allowMulti"
                                          [linkedEntityDefinition]="linkedEntityDefinition"
                                          [entityInstanceId]="entityInstanceId"
                                          [contextIdDto]="contextIdDto"
                                          [showPlaceholder]="showPlaceholder"
                                          [elementInstanceDto]="elementInstanceDto"
                                          [additionalDataMapDto]="additionalDataMapDto"
                                          [form]="form"
                                          [changeListener]="changeListener">

    </app-linked-entity-instance-multi-typeahead>

   <app-linked-entity-instance-single-typeahead *ngIf="isTypeahead && !linkedEntityDefinition.allowMulti"
                                               [linkedEntityDefinition]="linkedEntityDefinition"
                                               [entityInstanceId]="entityInstanceId"
                                               [contextIdDto]="contextIdDto"
                                               [showPlaceholder]="showPlaceholder"
                                               [elementInstanceDto]="elementInstanceDto"
                                               [additionalDataMapDto]="additionalDataMapDto"
                                               [form]="form"
                                               [changeListener]="changeListener">

   </app-linked-entity-instance-single-typeahead>

    <app-linked-entity-instance-select *ngIf="isSelect"
                                       [linkedEntityDefinition]="linkedEntityDefinition"
                                       [entityInstanceId]="entityInstanceId"
                                       [contextIdDto]="contextIdDto"
                                       [showPlaceholder]="showPlaceholder"
                                       [elementInstanceDto]="elementInstanceDto"
                                       [additionalDataMapDto]="additionalDataMapDto"
                                       [form]="form"
                                       [changeListener]="changeListener">

    </app-linked-entity-instance-select>

  </div>

