import { Component, Input, OnInit } from '@angular/core';
import { Page } from '../shared/model/page';
import { TrialExpiredService } from '../shared/services/trial-expired.service';
import {Appointment, AppointmentCompService, AppointmentService} from '@savvy/appointment';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { ContextIdDto } from '@savvy/quickbooks';

@Component({
  selector: 'app-view-appts-by-customer',
  templateUrl: 'viewAppointmentsByCustomer.component.html'
})
export class ViewAppointmentsByCustomerComponent implements OnInit {

  @Input() customerId: string;
  @Input() contextIdDto: ContextIdDto;

  futurePage = new Page();
  futureSelected: Appointment[] = [];
  futureAppointmentDates: Array<Appointment>;

  pastPage = new Page();
  pastSelected: Appointment[] = [];
  pastAppointmentDates: Array<Appointment>;

  columns = [
    { prop: 'label', name: '' }
  ];

  constructor(
    public trialExpiredService: TrialExpiredService,
    private appointmentService: AppointmentService,
    private appointmentCompService: AppointmentCompService,
    private notify: FloSnackbarComponent) {
  }
  ngOnInit() {
    this.futurePage.size = 10;
    this.pastPage.size = 10;
    this.setPastPage({ offset: 0, size: 10 });
    this.setFuturePage({ offset: 0, size: 10 });
  }

  setFuturePage(pageInfo) {
    console.log('pageInfo is ' + pageInfo.offset);
    this.futurePage.pageNumber = pageInfo.offset;
    this.loadFuturePage();
  }

  setPastPage(pageInfo) {
    console.log('pageInfo is ' + pageInfo.offset);
    this.pastPage.pageNumber = pageInfo.offset;
    this.loadPastPage();
  }

  sendAppointments() {
    this.appointmentCompService.sendAppointmentsV2(this.contextIdDto.contextId,
      this.customerId
    ).subscribe(response => {
      if (response.errorMessage) {
        this.notify.message = response.errorMessage;
        this.notify.open();
      } else {
        this.notify.message = 'Sent Appointments to Customer';
        this.notify.open();
      }
    });
  }

  loadFuturePage() {
    // Call list display names and pass page number
    console.log('loading page:');
    this.appointmentService.loadFutureCustomerAppointmentsPaged(
      this.customerId,
      (this.futurePage.pageNumber + 1),
      this.futurePage.size,
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
    ).subscribe(response => {
      console.log(response);
      if (response && response.contents) {
        this.futureAppointmentDates = response.contents;
        this.futurePage.totalElements = response.totalElements;
        this.futurePage.totalPages = response.totalPages;
      }
    });
  }

  loadPastPage() {
    // Call list display names and pass page number

    console.log('loading page:');

    this.appointmentService.loadPastCustomerAppointmentsPaged(
      this.customerId,
      (this.pastPage.pageNumber + 1),
      this.pastPage.size,
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
    ).subscribe(response => {
      console.log(response);
      if (response && response.contents) {
        this.pastAppointmentDates = response.contents;
        this.pastPage.totalElements = response.totalElements;
        this.pastPage.totalPages = response.totalPages;
      }
    });
  }



  onFutureSelect(event) {
    if (this.futureSelected && this.futureSelected.length > 0) {
      this.rowSelected(this.futureSelected[0].id);
    }
  }

  onPastSelect(event) {
    if (this.pastSelected && this.pastSelected.length > 0) {
      this.rowSelected(this.pastSelected[0].id);
    }
  }

  numFutureRows() {
    return this.futureAppointmentDates.length;
  }

  numPastRows() {
    return this.pastAppointmentDates.length;
  }


  rowSelected(appointmentId: string) {
      /*this.dialog.open(ViewEntityComponent, {
        data: {
          entityInstanceId: entityInstanceId.id,
          contextIdDto: this.contextIdDto,
          isViewMode: true
        },
        panelClass: 'modal-100',
        maxHeight: '80vh'
      });*/
  }
}
