import { Component, Input, OnInit } from '@angular/core';
import { EventBusService } from './EventBusService';
import { ElementInstanceDto, WorkflowinstcompService } from '@savvy/workflow-instance';
import {
  WorkflowdefService,
  WorkflowState,
} from '@savvy/workflow-definition';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { ChangeListener } from './changeListener';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '../shared/components/confirmation-modal/confirmation-modal.component';
import { WorkflowInstance, WorkflowStateInstanceDto, ChangeWorkflowState } from '@savvy/workflow-instance';
import { ContextIdDto, WorkflowExecuteActionPanelDataDto, Invoice } from '@savvy/view-definition';
import { ViewContextDto } from '@savvy/view-composite';
import { InvoiceService } from '@savvy/invoice';
import { PaymentcompService, PaymentService } from '@savvy/payment';

@Component({
  selector: 'app-workflow-action-panel',
  templateUrl: 'workflowActionPanel.component.html',
  styleUrls: ['workflowActionPanel.scss', 'commonPanel.component.scss'],

})
export class WorkflowActionPanelComponent implements OnInit {

  @Input() workflowExecuteActionPanelDataDto: WorkflowExecuteActionPanelDataDto;
  @Input() viewContext: ViewContextDto;
  @Input() contextIdDto: ContextIdDto;
  @Input() additionalDataMapDto: any;
  @Input() eventBus: EventBusService;
  @Input() changeListener: ChangeListener;
  @Input() element: any;


  workflowDef: any;
  workflowInstance: WorkflowInstance;
  workflowStateInstanceDtos: Array<WorkflowStateInstanceDto>;
  invoiceList: Array<Invoice> = [];

  constructor(
    private workflowInstanceCompApi: WorkflowinstcompService,
    private notify: FloSnackbarComponent,
    private workflowApi: WorkflowdefService,
    private invoiceApi: InvoiceService,
    private paymentApi: PaymentService,
    private paymentCompApi: PaymentcompService,
    private globalEventBus: EventBusService,
    private dialog: MatDialog) { }

  ngOnInit(): void {

    if (this.workflowExecuteActionPanelDataDto.workflowInstanceId) {
      // alert('loading workflow instance');
      this.loadWorkflowInstance();
    } else if (this.workflowExecuteActionPanelDataDto.workflowActionButtonPanelDefinition.workflowDefinitionId &&
      this.workflowExecuteActionPanelDataDto.workflowActionButtonPanelDefinition.workflowDefinitionId.id) {

      console.log('loading workflow def ', this.workflowExecuteActionPanelDataDto.workflowActionButtonPanelDefinition.workflowDefinitionId.id);
      this.workflowApi.getWorkflowDef(
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.workflowExecuteActionPanelDataDto.workflowActionButtonPanelDefinition.workflowDefinitionId.id
      ).subscribe(response => {
        this.workflowDef = response;
      });
    }
  }

  private loadWorkflowInstance() {
    this.workflowInstanceCompApi.loadWorkflowInstAndDefForPanel(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      this.workflowExecuteActionPanelDataDto.workflowInstanceId.id
    ).subscribe(response => {
      this.workflowInstance = response.workflowInstance;
      this.workflowDef = response.workflowDefinition;
      this.workflowStateInstanceDtos = response.workflowStateInstanceDtos;
    });
  }

  setState(workflowState: WorkflowState) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        name: workflowState.state,
        isChange: true
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.changeWorkflowState(workflowState);
      }
    });

  }

  changeWorkflowState(workflowState: WorkflowState) {
    if (this.workflowExecuteActionPanelDataDto.elementInstanceDto) {

      const req: ChangeWorkflowState = {
        contextIdDto: this.contextIdDto,
        elementInstanceDto: this.workflowExecuteActionPanelDataDto.elementInstanceDto as ElementInstanceDto,
        entityDefinitionId: this.viewContext.entityDefinitionId,
        entityInstanceId: this.viewContext.entityInstanceId,
        state: workflowState.state,
        workflowDefinitionId: this.workflowDef.workflowDefinitionId,
        workflowInstanceId: this.workflowExecuteActionPanelDataDto.workflowInstanceId,
        executeActions: true
      };


      this.workflowInstanceCompApi.changeWorkflowState(req).subscribe(
        response => {
          console.log('back from calling change workflow state');

          // NEED TO PASS BACK NEW COLOUR!!!
          /*   this.globalEventBus.addCalendarEntryStateChange(
               this.viewContext.entityInstanceId,
               workflowState.state);*/

          if (response.userMessage) {
            this.notify.message = response.userMessage;
            if (response.numCreditsRemaining && response.numCreditsRemaining < 25) {
              this.notify.message = response.userMessage + ' and  you have ' + response.numCreditsRemaining + ' SMS credits remaining';
            }
            this.notify.open();
          } else {
            this.notify.message = 'Updated & Sent Any Messages';
            this.notify.open();
          }

          if (this.changeListener) {
            this.changeListener.add('change');
          }

          this.loadWorkflowInstance();
        }
      );
    } else {
      this.notify.message = 'No workflow instance available';
      this.notify.open();
    }
  }
}

