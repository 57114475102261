import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ConfService } from '../../config/conf.service';
import { UiAuthService } from '../../auth/ui-auth.service';
import { ChangeListener } from '../changeListener';
import { EicompService } from '@savvy/entity-instance-composite';
import { UrlLookupService } from '../../shared/services/urlLookup.service';
import { ElementInstanceDto, ImageFieldDefinitionDto, ImageFieldValue, FileReference } from '@savvy/view-composite';
import { ContextIdDto } from '@savvy/datalist';

interface UploadedFile {
  url: string;
  fileName: string;
  fileReferenceId: string;
  blob?: Blob;
}
@Component({
  selector: 'app-image-field-instance',
  styleUrls: ['imageFieldInstance.component.scss'],
  templateUrl: 'imageFieldInstance.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageFieldInstanceComponent implements OnInit {

  @Input() imageFieldDefinition: ImageFieldDefinitionDto;
  @Input() elementInstanceDto: ElementInstanceDto;
  @Input() entityInstanceId: string;
  @Input() contextIdDto: ContextIdDto;
  @Input() form: UntypedFormGroup;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;

  // imageUploadStyle: Style = {
  //   selectButton: {
  //     'background-color': '#008080',
  //     color: '#FFF'
  //   }
  // };

  uploadedFiles: UploadedFile[];
  imageUrl = 'assets/images/no-image.jpg';



  constructor(
    private eiCompositeApi: EicompService,
    private urlLookup: UrlLookupService,
    private authService: UiAuthService) { }

  ngOnInit(): void {
    this.init();
  }




  getImageUrl(fileReference: FileReference) {
    // This is NOT the correct URL to use here

    /*if (this.imageFieldDefinition.imageFieldDefinition.publicBucket) {
      return this.urlLookup.cloudfrontUrl() + '/' + fileReference.fileReferenceId;
    } else {*/
    return ConfService.apiUrl() + '/rest/storage?contextId=' + this.contextIdDto.contextId +
      '&contextIdType=' + this.contextIdDto.contextIdType +
      '&id=' + (fileReference.fileReferenceId ? encodeURIComponent(fileReference.fileReferenceId) : fileReference.fileReferenceId) +
      '&public=' + this.imageFieldDefinition.imageFieldDefinition.publicBucket;
    // }
  }

  imageAvailable(elementInstanceDto) {
    return elementInstanceDto.fieldInstanceDto.value.imageFieldValue &&
      elementInstanceDto.fieldInstanceDto.value.imageFieldValue.fileReferences &&
      elementInstanceDto.fieldInstanceDto.value.imageFieldValue.fileReferences.length > 0;
  }

  onUploadFinished(file) {
    try {
      // console.log('upload finished status:' + file.serverResponse.status);
      // console.log('upload finished response:' + file.serverResponse.response);
      // const newJsonObj = file.serverResponse.response.body;
      // console.log('newJsonObj finished response:' + newJsonObj);
      const fileReferencesTmp = file['imageFieldValue'];
      console.log('fileReferencesTmp' + fileReferencesTmp);

      const newFileReferences = new Array<FileReference>();

      fileReferencesTmp['fileReferences'].forEach(fileReference => {
        console.log('got file reference ' + fileReference['fileReferenceId']);
        const newRef = {} as FileReference;
        newRef.fileReferenceId = fileReference['fileReferenceId'];
        newFileReferences.push(newRef);
      });
      this.elementInstanceDto.fieldInstanceDto.value.imageFieldValue.fileReferences = newFileReferences;
      this.initUploadedFiles();
    } catch (e) {
      console.log('Error occured parsing response:' + e);
    }
  }

  getFileRef(imageUrl) {

    let fileReferenceId = null;

    console.log('getting file ref for image src ', imageUrl);

    this.elementInstanceDto.fieldInstanceDto.value.imageFieldValue.fileReferences.forEach(fileRef => {
      const loopImageUrl = this.getImageUrl(fileRef);

      console.log('loopImageUrl ', loopImageUrl);

      if (loopImageUrl === imageUrl) {
        fileReferenceId = fileRef.fileReferenceId;
        console.log('found it');
      } else {
        console.log('imageUrl ', imageUrl);
      }
    });
    return fileReferenceId;
  }

  onRemoved(url: string) {
    console.log('onRemoved', url);

    const fileRef = this.getFileRef(url);

    if (fileRef) {
      console.log('file ref is ', fileRef);
      // this.eiCompositeApi.removeImage(this.contextIdDto.contextId,
      //   this.contextIdDto.contextIdType,
      //   this.entityInstanceId,
      //   this.elementInstanceDto.instanceId,
      //   fileRef).subscribe(response => {
      //     console.log('got response back');
      //     this.elementInstanceDto.fieldInstanceDto.value.imageFieldValue = response.imageFieldValue;
      //     this.init();
      //   });
    } else {
      console.log('unable to get fileRef');
    }
  }

  onUploadStateChanged(state: boolean) {
    console.log('onUploadStateChanged:' + state);
  }

  getAuthToken() {
    return this.authService.getAuthorizationHeader();
  }

  getUploadUrl() {
    if (this.imageFieldDefinition.imageFieldDefinition.allowMultiples) {
      return ConfService.apiUrl() + '/rest/eicomp/uploadImage?contextId=' +
        this.contextIdDto.contextId + '&contextIdType=' +
        this.contextIdDto.contextIdType + '&entityInstanceId=' +
        this.entityInstanceId + '&fieldInstanceId=' +
        this.elementInstanceDto.instanceId;
    } else {
      // console.log('calling replace image');
      return ConfService.apiUrl() + '/rest/eicomp/replaceImage?contextId=' +
        this.contextIdDto.contextId + '&contextIdType=' +
        this.contextIdDto.contextIdType + '&entityInstanceId=' +
        this.entityInstanceId + '&fieldInstanceId=' +
        this.elementInstanceDto.instanceId;
    }
  }

  private init() {
    console.log('inside init');
    this.initUploadedFiles();
    if (this.imageAvailable(this.elementInstanceDto)) {
      console.log('image is available!');
      this.imageUrl = this.getImageUrl(this.elementInstanceDto.fieldInstanceDto.value.imageFieldValue.fileReferences[0]);
    } else {
      console.log('setting image url to default no image');
      this.imageUrl = 'assets/images/no-image.jpg';
    }
  }

  private initUploadedFiles() {
    this.uploadedFiles = new Array();

    if (this.elementInstanceDto.fieldInstanceDto.value.imageFieldValue
      && this.elementInstanceDto.fieldInstanceDto.value.imageFieldValue.fileReferences) {
      this.elementInstanceDto.fieldInstanceDto.value.imageFieldValue.fileReferences.forEach(fileRef => {
        const uploadedFile = {} as UploadedFile;
        uploadedFile.url = this.getImageUrl(fileRef);
        uploadedFile.fileName = fileRef.fileName;
        uploadedFile.fileReferenceId = fileRef.fileReferenceId;
        this.uploadedFiles.push(uploadedFile);
      });
    } else {
      this.elementInstanceDto.fieldInstanceDto.value.imageFieldValue = {} as ImageFieldValue;
      this.elementInstanceDto.fieldInstanceDto.value.imageFieldValue.fileReferences = new Array();
    }
  }
}

