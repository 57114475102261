import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ContextIdDto, VerticalPanelDefinitionDto } from '@savvy/view-definition';
import { ViewContextDto } from '@savvy/view-composite';
import { ChangeListener } from './changeListener';
import { EventBusService } from './EventBusService';

@Component({
  selector: 'app-vertical-panel',
  templateUrl: 'verticalPanel.component.html',
  styleUrls: ['verticalPanel.component.scss']
})
export class VerticalPanelComponent implements OnInit {

  @Input() verticalPanelDefinitionDto: VerticalPanelDefinitionDto;
  @Input() contextIdDto: ContextIdDto;
  @Input() viewContext: ViewContextDto;
  @Input() form: UntypedFormGroup;
  @Input() eventBus: EventBusService;
  @Input() additionalDataMapDto: any;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;
  percent;
  cssClass;

  ngOnInit(): void {
    if (this.verticalPanelDefinitionDto && this.verticalPanelDefinitionDto.elementDtos) {
      this.percent = 100 / this.verticalPanelDefinitionDto.elementDtos.length;
    } else {
      this.percent = 100;
    }
    if (this.verticalPanelDefinitionDto['applySpacing']) {
      this.cssClass = 'mt-0 new-card';
    }
  }
}

