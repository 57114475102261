import {EventEmitter, Injectable} from '@angular/core';

@Injectable()
export class FaviconService {

    public eventAdded$: EventEmitter<string>;
    private events: string[] = [];

    constructor() {
        this.eventAdded$ = new EventEmitter();
    }

    public list(): string[] {
        return this.events;
    }

    public add(event: string): void {
        this.events.push(event);
        this.eventAdded$.emit(event);
    }
}
