<!--div class="example-button-row" *ngIf="workflowDef && workflowInstance">
  <button mat-flat-button color="accent" (click)="setState(workflowState)"
          *ngFor="let workflowState of workflowDef.workflowStateList">
    {{workflowState.state | translate}}
  </button>


</div-->


<!--
<span *ngIf="workflowInstance">
{{ workflowInstance.id }}
  </span>
-->
  <mat-button-toggle-group style="overflow: auto; margin: 10px 0; position: relative;z-index: 20; background-color: white" name="fontStyle" aria-label="Font Style" *ngIf="workflowDef && workflowInstance" value="{{workflowInstance.state}}">
    <mat-button-toggle [class.workflow-checked]="workflowInstance.state === workflowState.state" class="mat-button-toggle-checked" fxFlex fxLayoutAlign="center center" (click)="setState(workflowState)"
                       value="{{workflowState.state}}"
                       *ngFor="let workflowState of workflowDef.workflowStateList">{{workflowState.state}}</mat-button-toggle>
  </mat-button-toggle-group>



<!--div class="example-button-row" *ngIf="workflowDef && workflowInstance">



<div *ngFor="let workflowState of workflowDef.workflowStateList">
  <button mat-flat-button color="accent" matBadge="current" matBadgePosition="before" matBadgeColor="primary" (click)="setState(workflowState)" *ngIf="workflowInstance.state === workflowState.state">
    {{workflowState.state | translate}}
  </button>
  <button mat-flat-button color="accent" (click)="setState(workflowState)" *ngIf="workflowInstance.state !== workflowState.state">
    {{workflowState.state | translate}}
  </button>
</div>


</div-->
