import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { EntitydefsService } from '@savvy/entity-definition';
import { TemplateCompService } from '@savvy/template-engine';
import { WorkflowDefConfigService, WorkflowdefService } from '@savvy/workflow-definition';
import { WorkflowinstcompService } from '@savvy/workflow-instance';
import { ColorPickerModule } from 'ngx-color-picker';
import { EditorModule } from '../editor/editor.module';
import { FloMaterialModule } from '../flo.material.module';
import { ScheduleModule } from '../schedule/schedule.module';
import { AddEditWorkflowMessageDefinitionComponent } from './addEditWorkflowMessageDefinition.component';
import { AddEditWorkflowStateComponent } from './addEditWorkflowState.component';
import { AddEditWorkflowTaskDefinitionComponent } from './addEditWorkflowTaskDefinition.component';
import { EditWorkflowPanelComponent } from './editWorkflowPanel.component';
import { MessageOnlyWorkflowComponent } from './messageOnlyWorkflow.component';
import { workflowRoutes } from './workflow.routing';
import { WorkflowOverviewComponent } from './workflowOverview.component';
import { WorkflowsComponent } from './workflows.component';


import { ArchwizardModule } from '@viso-trust/angular-archwizard';
import { NgWizardModule } from 'ng-wizard-v2';
import { DirectivesModule } from 'src/app/core/directives/directives.module';
import { SharedModule } from '../shared/shared.module';
import { ConfirmationDialogComponent } from './../confirmation-dialog/confirmation-dialog.component';
import { AddEditTaskWorkflowDialogComponent } from './add-edit-task-workflow-dialog/add-edit-task-workflow-dialog.component';
import { AddEditWorkflowDialogComponent } from './add-edit-workflow-dialog/add-edit-workflow-dialog.component';
import { AddEditWorkflowTypeDialogComponent } from './add-edit-workflow-dialog/add-edit-workflow-type-dialog.component';
import { AddEditWorkflowStateDialogComponent } from './add-edit-workflow-state-dialog/add-edit-workflow-state-dialog.component';
import { AddEditWorkflowTaskGroupDialogComponent } from './add-edit-workflow-task-group-dialog/add-edit-workflow-task-group-dialog.component';
import { AddEditWorkflowTaskComponent } from './add-edit-workflow-task/add-edit-workflow-task.component';
import { ManualTaskComponent } from './add-manual-task/manual-task.component';
import { SendMessageTaskComponent } from './add-send-message-task/send-message-task.component';
import { SendSmsTaskComponent } from './add-send-sms-task/send-sms-task.component';
import {
  AddEditWorkflowStateColorDialogComponent
} from "./edit-workflow-state-color-dialog/add-edit-workflow-state-color-dialog.component";
import { MessageOnlyWorkflowPanelComponent } from './message-only-workflow-panel/messageOnlyWorkflowPanel.component';
import { TaskOnlyWorkflowPanelComponent } from './task-only-workflow-panel/taskOnlyWorkflowPanel.component';
import { TaskWorkflowDetailsComponent } from './task-workflow-details/task-workflow-details.component';
import { TaskWorkflowlistsComponent } from './task-workflow-list.component';
import { WorkflowBoardComponent } from './workflow-board/workflow-board.component';
import { WorkflowDetailsModalComponent } from './workflow-details/workflow-details-modal/workflow-details-modal.component';
import { WorkflowDetailsComponent } from './workflow-details/workflow-details.component';
import { WorkflowlistsByTypeComponent } from './workflow-list-by-type.component';
import { WorkflowlistsComponent } from './workflow-list.component';
import { GenericWorkflowPanelComponent } from './workflow-panel/genericWorkflowPanel.component';
import { AddEditWorkflowMessageComponent } from './add-edit-workflow-message/add-edit-workflow-message.component';


@NgModule({
  imports: [
    ColorPickerModule,
    CommonModule,
    EditorModule,
    ScheduleModule,
    RouterModule.forChild(workflowRoutes),
    FloMaterialModule,
    NgWizardModule,
    ArchwizardModule,
    TranslateModule,
    DirectivesModule,
    SharedModule
  ],
  exports: [
    GenericWorkflowPanelComponent,
    TaskOnlyWorkflowPanelComponent,
    MessageOnlyWorkflowPanelComponent,
    WorkflowDetailsComponent,
    WorkflowlistsByTypeComponent,
    TaskWorkflowlistsComponent,
    TaskWorkflowDetailsComponent
  ],
  providers: [
    EntitydefsService,
    TemplateCompService,
    WorkflowdefService,
    WorkflowDefConfigService,
    WorkflowinstcompService
  ],
  declarations: [
    AddEditWorkflowStateComponent,
    WorkflowsComponent,
    SendMessageTaskComponent,
    ManualTaskComponent,
    AddEditWorkflowTypeDialogComponent,
    AddEditWorkflowTaskDefinitionComponent,
    AddEditWorkflowMessageDefinitionComponent,
    EditWorkflowPanelComponent,
    WorkflowOverviewComponent,
    WorkflowlistsComponent,
    TaskWorkflowlistsComponent,
    MessageOnlyWorkflowPanelComponent,
    MessageOnlyWorkflowComponent,
    AddEditWorkflowDialogComponent,
    AddEditTaskWorkflowDialogComponent,
    AddEditWorkflowTaskGroupDialogComponent,
    WorkflowBoardComponent,
    AddEditWorkflowStateDialogComponent,
    AddEditWorkflowStateColorDialogComponent,
    TaskOnlyWorkflowPanelComponent,
    ConfirmationDialogComponent,
    AddEditWorkflowTaskComponent,
    GenericWorkflowPanelComponent,
    WorkflowlistsByTypeComponent,
    TaskWorkflowDetailsComponent,
    AddEditWorkflowMessageComponent,
    WorkflowDetailsComponent,
    SendSmsTaskComponent,
    WorkflowDetailsModalComponent
  ]
})

export class WorkflowModule {
}
