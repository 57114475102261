/**
 * Created by bengill on 28/04/2017.
 */
// login.ts
import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-signout',
  templateUrl: 'signout.component.html'
})

export class SignoutComponent implements OnInit {
  
  constructor(
    private auth: AuthService,
    private authService: AuthService
  ) {
    console.log('inside constructor of signout component');
    
  }

  ngOnInit(): void {
    console.log('inside ngOnInit of signout component');
    this.auth.logout();
    this.authService.logout({ logoutParams: { returnTo: document.location.origin } });
  }
}
