import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AppointmentDataV1V2, AppointmentService, AppointmentV1V2Service } from '@savvy/appointment';
import { CalendarEntry } from '@savvy/calendar';
import { ContextIdDto } from '@savvy/menu';
import { FileReference, Pet, PetService } from '@savvy/pet';
import { Contact } from '@savvy/workflow-definition';
import { CalendarEvent } from 'angular-calendar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ViewV1V2PetHistoryComponent } from '../../appointment/pet/view-v1-v2-pet-history/view-v1-v2-pet-history.component';
import { CreateAppointmentV2SyncService } from '../../appointments-v2/create-appointment-v2/create-appointment-v2-sync.service';
import { ContextService } from '../../context.service';
import { EventBusService } from '../../element/EventBusService';
import { ViewEntitySummaryComponent } from '../../element/viewEntitySummary.component';
import { UiEvent } from '../../event/UiEvent';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';
import { PetHandlerService } from '../pet-handler.service';
import { AppointmentInvoiceSummaryComponent } from './appointment-invoice-summary/appointment-invoice-summary.component';
import { CreatePetDetailsV2Component } from 'src/app/flo/pets/create-pet-details-v2/create-pet-details-v2.component';
import { AddOnsHelperService } from '../../shared/services/add-ons-helper.service';

@Component({
  selector: 'app-view-pet-details-v2',
  templateUrl: './view-pet-details-v2.component.html',
  styleUrls: ['./view-pet-details-v2.component.scss']
})
export class ViewPetDetailsV2Component implements OnInit {
  @ViewChild('sidePanel') sidePanel;
  @ViewChild(ViewV1V2PetHistoryComponent, { static: false }) viewV1V2PetHistoryComponent: ViewV1V2PetHistoryComponent;
  @ViewChild(AppointmentInvoiceSummaryComponent, { static: false }) appointmentInvoiceSummaryComponent: AppointmentInvoiceSummaryComponent;


  contextIdDto: ContextIdDto;
  subscriptions: Subscription[] = [];
  pet: Pet;
  appointments: AppointmentDataV1V2[] = [];
  loaded = false;
  options = {
    collapsed: false,
    compact: false,
    boxed: false,
    dark: false,
    dir: 'ltr'
  };
  sidePanelOpened;
  viewing = false;
  calendarEventType: string;
  calendarEventToView: CalendarEntry;
  entityInstanceId;
  eventBus: EventBusService;
  isViewMode = false;
  petId = '';
  petStayAddOnActive = false;
  private sub: any;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private petService: PetService,
    private spinner: NgxSpinnerService,
    private appointmentService: AppointmentService,
    private petHandlerService: PetHandlerService,
    public createAppointmentV2SyncService: CreateAppointmentV2SyncService,
    public deviceService: DeviceDetectorService,
    public contextService: ContextService,
    private notify: FloSnackbarComponent,
    private appointmentV1V2Service: AppointmentV1V2Service,
    private addOnsHelperService: AddOnsHelperService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data?,
    @Optional() public dialogRef?: MatDialogRef<ViewPetDetailsV2Component>,
  ) { }

  ngOnInit(): void {
    if (this.data && this.data.contextIdDto) {
      this.isViewMode = true;
      this.contextIdDto = this.data.contextIdDto;
      if (this.data.petId) {
        this.petId = this.data.petId;
        this.loadPet(this.data.petId);
      }
    } else {
      this.createAppointmentV2SyncService.clear();
      this.sub = this.route.params.subscribe(params => {
        this.contextIdDto = <ContextIdDto>{};
        this.contextIdDto.contextId = params['contextId'];
        this.contextIdDto.contextIdType = params['contextIdType'];
        this.petId = params['petId'];
        this.loadPet(params['petId']);
      });
    }

    this.addOnsHelperService.loadAddOns(this.contextIdDto).then(res => {
      if (this.addOnsHelperService.addOnPresent('Day Care')) {
        this.petStayAddOnActive = true;
      }
    });
  }

  petChanged(petId: string) {
    if (petId) {
      this.loadPet(petId);
    }
  }

  loadPet(petId) {
    this.spinner.show();
    this.petService.get(
      petId).subscribe(response => {
        this.pet = response;
        this.loadFutureCustomerAppointments(this.pet?.id);
        this.spinner.hide();
        this.loaded = true;
      }, error => {
        this.pet = null;
        this.spinner.hide();
      });
  }

  loadFutureCustomerAppointments(petId: string) {
    if (this.pet.entityInstanceId) {
      this.appointmentV1V2Service.loadPetFutureV1V2(
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        petId,
        this.pet.entityInstanceId)
        // loadFutureCustomerAppointments(customerId, this.contextIdDto.contextId,this.contextIdDto.contextIdType)
        .subscribe(response => {
          this.appointments = response;
        }, error => {
          this.appointments = [];
        });
    } else {
      console.log('Entity instance id not present for pet so using v2 pet id ', petId);
      this.appointmentService.loadPetFutureV2(
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        petId, 1, 10)
        // loadFutureCustomerAppointments(customerId, this.contextIdDto.contextId,this.contextIdDto.contextIdType)
        .subscribe(response => {
          this.appointments = response.contents;
        }, error => {
          this.appointments = [];
        });
    }
  }

  callback = (res) => {
    if (res) {
      // TODO
    }
  };

  softDelete() {
    this.petHandlerService.softDelete(this.contextIdDto, this.pet, this.callback);
  }

  gotoPetList() {
    // this.router.navigate(['/pets/pets',
    //   this.contextIdDto.contextId,
    //   this.contextIdDto.contextIdType]);
    history.back();
  }

  edit() {
    if (this.isViewMode) {
      this.openEditPetModal(this.pet?.id);
    } else {
      this.router.navigate(['/pets/edit',
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.pet?.id]);
    }
  }

  openEditPetModal(petId: string) {
    const createPetModal = this.dialog.open(CreatePetDetailsV2Component, {
      data: {
        contextIdDto: this.contextIdDto,
        customerId: this.pet.petOwnerId,
        petId
      },
      maxWidth: '1024px',
      panelClass: [
        'modal-100',
        'helpwindow',
        'petProfileModal'
      ],
      autoFocus: false
    });

    createPetModal.afterClosed()
      .subscribe(
        (updatedPet: Pet) => {
          if (updatedPet?.id) {
            this.loadPet(updatedPet?.id);
          }
        });
  }

  cancel() {
    this.dialogRef.close();
  }

  viewV1Appointment(event: AppointmentDataV1V2) {
    const entryTypeToUse = 'Ei';
    this.entityInstanceId = event.entityInstanceId;
    // if (calendarEntryDto.entryType) {
    //   entryTypeToUse = calendarEntryDto.entryType;
    // }
    const newEvent: CalendarEvent = {
      start: new Date(event.startDateUtc),
      title: '',
      meta: {
        reference: event.entityInstanceId.id,
        entryType: entryTypeToUse,
      }
    };
    this.openViewDialog(newEvent);
  }

  openViewDialog(calendarEntry) {
    if (calendarEntry && calendarEntry.meta) {
      const reference = calendarEntry.meta.reference;
      this.viewing = true;

      if (calendarEntry && calendarEntry.meta) {

        console.log('calendarEntry.meta.entryType', calendarEntry.meta.entryType);
        if (calendarEntry.meta.entryType === 'holiday') {
          console.log('it is a holiday!');
          this.sidePanel.open();
          this.calendarEventType = calendarEntry.meta.entryType.charAt(0).toUpperCase() + calendarEntry.meta.entryType.slice(1);
          this.calendarEventToView = calendarEntry;
        } else if (calendarEntry.meta.entryType === 'meeting') {
          console.log('it is a meeting!');
          this.sidePanel.open();
          this.calendarEventType = calendarEntry.meta.entryType.charAt(0).toUpperCase() + calendarEntry.meta.entryType.slice(1);
          this.calendarEventToView = calendarEntry;
        } else if (calendarEntry.meta.entryType === 'gmail') {

          console.log('it is a gmail!');
          this.notify.message = 'You cannot open Gmail Calendar Entries, just read them';
          this.notify.open();
        } else if (calendarEntry.meta.entryType === 'Ei' && reference) {
          this.openViewEi(reference);
        } else if (calendarEntry.meta.entryType === 'appointment') {
          // this.openAppointment(reference);
        } else {
          console.log('not sure what this is, id is:' + calendarEntry.id
            + ' title is:' + calendarEntry.title
            + ' start is:' + calendarEntry.start
            + ' end is:' + calendarEntry.end);
        }
      }
    }
  }

  openViewEi(entityInstanceId) {
    console.log('opening up view ei ', entityInstanceId);
    this.viewing = true;
    console.log('openViewDialog entityInstanceId', entityInstanceId);
    setTimeout(() => {
      this.entityInstanceId = entityInstanceId;
      this.calendarEventType = 'Ei';
      if (this.deviceService.isDesktop()) {
        console.log('opening side panel');
        this.sidePanel.open();
      } else if (this.deviceService.isMobile()) {
        this.openEntitySummaryDialog();
      } else {
        this.openEntitySummaryDialog();
      }
    }, 0);
  }

  openEntitySummaryDialog() {
    const dialogRef = this.dialog.open(ViewEntitySummaryComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        eventBus: this.eventBus,
        entityInstanceId: this.entityInstanceId
      },
      maxHeight: '80vh',
      panelClass: 'entitySummaryComponent'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log('dialog closed!', true);
      }
    });
  }

  reloadPetHistory(event) {
    if (event && this.viewV1V2PetHistoryComponent) {
      this.viewV1V2PetHistoryComponent.loadAppointments();
    }
  }

  reloadInvoices(event) {
    if (event && this.appointmentInvoiceSummaryComponent) {
      this.appointmentInvoiceSummaryComponent.loadAppointmentsAndInvoices();
    }
  }

  loadAppointments(event) {
    this.reloadPetHistory(event);
    this.reloadInvoices(event);
  }

  updateHistory() {
    if (this.viewV1V2PetHistoryComponent) {
      this.viewV1V2PetHistoryComponent.loadAppointments();
    }
  }

  updateContacts(contacts: Contact[]) {
    this.pet.contacts = contacts;
    this.petService.updatePet(this.pet)
      .subscribe(res => {

      });
  }

  filesUpdated(newFiles: FileReference[]) {
    this.pet.files = newFiles;
    this.petService.updatePet(this.pet)
      .subscribe(res => {
        this.notify.message = 'Pet files updated';
        this.notify.open();
      });
  }

  customerFilesUpdated(newFiles: FileReference[]) {
    this.pet.customerFiles = newFiles;
    this.petService.updatePet(this.pet)
      .subscribe(res => {
        this.notify.message = 'Pet files updated';
        this.notify.open();
      });
  }

}
