import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { EventBusService } from '../element/EventBusService';
import { LinkedInstanceFactory } from '../element/linkedInstanceFactory';
import {
  MessagecompService, ContextIdDto,
  LinkId,
  LinkIds, MSISDN,
  SendCompositeMessageResponse,
  SendEmailMessage,
  SendMessageResponse, SmsRecipient,
  SendSmsMessage, SendWhatsAppMessage, EmailRecipient
} from '@savvy/messaging';
import { EmailRecipientHelper } from './EmailRecipientHelper';
import { EventType } from '../event/UiEvent';
import { UsercompService } from '@savvy/user';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { LoadUserForLink } from '@savvy/user';
import {ViewContextDto} from '@savvy/view-composite';
import { LinkedIdTypeEnum } from 'src/app/core/data-share.service';
import {Customer, CustomerService} from "@savvy/customer";
import RecipientTypeEnum = EmailRecipient.RecipientTypeEnum;

@Component({
  selector: 'app-new-message-panel',
  templateUrl: 'newMessagePanel.component.html'
})
export class NewMessagePanelComponent implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() visible: boolean;
  @Input() viewContextDto: ViewContextDto;
  @Input() eventBus: EventBusService;
  showSpinner = false;
  body: string;
  subject: string;
  emailAddresses: string;
  smsNumbers: string;
  smsSenderId: string;
  whatsAppNumbers: string;
  messageText: string;
  emailTemplateId: string;
  // readonly messageTypes = ['Email', 'SMS', 'WhatsApp'];
  readonly messageTypes = ['Email', 'SMS'];
  selectedMessageType = this.messageTypes[0];

  public form: UntypedFormGroup; // our form model
  customer: Customer;

  constructor(
    private api: MessagecompService,
    private emailRecipientHelper: EmailRecipientHelper,
    private notify: FloSnackbarComponent,
    private customerService: CustomerService) {
  }

  ngOnInit() {
    this.loadRecipient();
  }

  bodyUpdated(text: string) {
    this.body = text;
  }

  cancelIt() {
    this.eventBus.addEvent(EventType.CANCEL_SEND_MESSAGE);
  }

  onEmailTemplateChange($event) {
    this.emailTemplateId = $event.id;
    this.body = $event.body;
    this.subject = $event.subject;
  }

  private loadRecipient() {
    if (this.viewContextDto && this.viewContextDto.customerContextDto?.customerId?.id) {
      let customerId = this.viewContextDto.customerContextDto?.customerId?.id;

      this.customerService.loadCustomer(customerId, this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(res => {
        console.log('got response from loading recipient');

        if (res) {
          this.handleLoadCustomerResponse(res);
        }
      });
    } else {
    }
  }

  messageDefined() {

    switch (this.selectedMessageType) {
      case this.messageTypes[0]: {
        if (this.body && this.body.length > 0) {
          return true;
        }
        return false;
      }
      case this.messageTypes[1]: {
        if (this.messageText && this.messageText.length > 0) {
          return true;
        }
        return false;
      }
      case this.messageTypes[2]: {
        if (this.messageText && this.messageText.length > 0) {
          return true;
        }
        return false;
        break;
      }
    }
  }
  handleLoadCustomerResponse(customer: Customer) {
    this.customer = customer;

    console.log('customer is ' + customer.username);

    if (this.customer.username) {
      this.emailAddresses = this.customer.username;
    } else {
    }


    if (this.customer.mobilePhoneNumberObj?.internationalNumber) {
      console.log('user mobile is ' + this.customer.mobilePhoneNumberObj?.internationalNumber);

      this.smsNumbers = this.customer.mobilePhoneNumberObj?.internationalNumber;
    } else if (this.customer.phoneNumberObj?.internationalNumber) {
      console.log('user phone is ' + this.customer.phoneNumberObj?.internationalNumber);

      this.smsNumbers = this.customer.phoneNumberObj?.internationalNumber;
    } else {
      console.log('user not setting sms number');

    }

    if (this.customer.whatsAppNumberObj?.internationalNumber) {
      console.log('user whats app');

      this.whatsAppNumbers = this.customer.whatsAppNumberObj?.internationalNumber;
    } else {
    }
  }


  send() {
    switch (this.selectedMessageType) {
      case this.messageTypes[0]: {
        this.sendEmail();
        break;
      }
      case this.messageTypes[1]: {
        this.sendSms();
        break;
      }
      case this.messageTypes[2]: {
        this.sendWhatsApp();
        break;
      }
    }
  }


  checkIfAllowedToSend() {
    switch (this.selectedMessageType) {
      case this.messageTypes[0]: {
        return this.customer.optIn.allowEmail;
      }
      case this.messageTypes[1]: {
        return this.customer.optIn.allowSms;
      }
      case this.messageTypes[2]: {
        return this.customer.optIn.allowWhatApp;
      }
      default: {
        return false;
      }
    }
  }


  private sendSms() {
    if (this.customer.id) {
      const req = <SendSmsMessage>{};
      req.contextId = this.contextIdDto.contextId;
      req.contextIdType = this.contextIdDto.contextIdType;
      req.message = this.messageText;
      console.log('setting sender id to ' + this.smsSenderId);
      req.smsSenderId = this.smsSenderId;
      req.linkIds = this.getLinkIds();

      // To Do:  use real customer number
      req.toNumbers = [];
      /*if (this.smsNumbers) {
        const msisdns: string[] = this.smsNumbers.split(',');
        msisdns.forEach(msisdn => {
          const smsRecipient = <SmsRecipient>{};
          smsRecipient.recipient = <MSISDN>{};
          smsRecipient.recipient.msidn = msisdn;
          req.toNumbers.push(smsRecipient);
        });
      }*/

      const smsRecipient = <SmsRecipient>{};
      smsRecipient.recipient = <MSISDN>{};
      smsRecipient.recipient.msidn = this.customer.mobilePhoneNumberObj?.internationalNumber;
      smsRecipient.recipientId = this.customer.id;
      smsRecipient.recipientType = SmsRecipient.RecipientTypeEnum.Customer;
      req.toNumbers.push(smsRecipient);
      this.showSpinner = true;
      this.api.sendSmsMessage(req).subscribe(response => {
        this.handleResponse(response);
      });
    } else {
      console.log('newMessagePanel.component : Customer ID is missing while sending SMS');
    }
  }

  private sendWhatsApp() {
    const req = <SendWhatsAppMessage>{};
    req.contextId = this.contextIdDto.contextId;
    req.contextIdType = this.contextIdDto.contextIdType;
    req.message = this.messageText;
    // req.linkIds = this.getLinkIds();

    // To Do:  use real customer number
    req.toNumbers = [];
    if (this.whatsAppNumbers) {
      req.toNumbers = this.whatsAppNumbers.split(',');
    }
    this.showSpinner = true;
    this.api.sendWhatsAppMessage(req).subscribe(response => {
      this.handleResponse(response);
    });
  }



  private sendEmail() {
    if (this.customer?.id) {
      const req = <SendEmailMessage>{};
      req.subject = this.subject;
      req.body = this.body;
      req.contextIdDto = this.contextIdDto;
      req.linkIds = this.getLinkIds();
      req.emailRecipients = this.emailRecipientHelper.toEmailRecipientsWithCustomerId(this.emailAddresses, this.customer?.id);
      this.showSpinner = true;
      this.api.sendEmailMessage(req).subscribe(response => {
        this.handleResponse(response);
      });
    } else {
      console.log('newMessagePanel.component : Customer ID is missing while sending email');
    }
  }

  handleResponse(response: SendCompositeMessageResponse) {
    if (response.sendMessageResponses && response.sendMessageResponses.length > 0) {
      response.sendMessageResponses.forEach(smr => {
        if (smr.sendMessageError) {
          console.log('smr is ' + smr.sendMessageError);
          if (smr.sendMessageError === SendMessageResponse.SendMessageErrorEnum.AuthorizationFailure) {
            this.notify.message = 'Your username or password is wrong in Email Settings';
            this.notify.open();
          } else if (smr.sendMessageError === SendMessageResponse.SendMessageErrorEnum.ConnectionFailure) {
            this.notify.message = 'We were unable to connect to your SMTP server (please check your host name and port)';
            this.notify.open();
          } else if (smr.sendMessageError === SendMessageResponse.SendMessageErrorEnum.UnknownMailServer) {
            this.notify.message = 'We were unable to connect to your SMTP server (please check your host name)';
            this.notify.open();
          } else if (smr.sendMessageError === SendMessageResponse.SendMessageErrorEnum.NoActiveSubscription) {
            this.notify.message = 'You do not have an active subscription so are not allowed to send messages';
            this.notify.open();
          } else if (smr.sendMessageError === SendMessageResponse.SendMessageErrorEnum.NoCredits) {
            this.notify.message = 'You do not have an credits remaining';
            this.notify.open();
          } else if (smr.sendMessageError === SendMessageResponse.SendMessageErrorEnum.SendingDisabled) {
            this.notify.message = 'You do not have sending enabled, please contact support who can enable it for you';
            this.notify.open();
          } else {
            this.notify.message = 'An error occurred when sending this message';
            this.notify.open();
          }
        }
      });
    }
    this.eventBus.addEvent(EventType.SENT_MESSAGE);
    this.clearFields();
  }




  private clearFields() {
    this.showSpinner = false;
    this.subject = '';
    this.body = '';
    this.messageText = '';
    this.emailTemplateId = null;
  }



  // LINK IDS

  getLinkIds(): LinkIds {

    const linkIds = <LinkIds>{};
    linkIds.linkIds = new Array();

    if (this.viewContextDto && this.viewContextDto.entityInstanceId && this.viewContextDto.entityInstanceId.id) {
      linkIds.linkIds.push(this.getEntityAsLinkId());
    }
    linkIds.linkIds.push(this.getUserAsLinkId());
    return linkIds;
  }

  private getEntityAsLinkId(): LinkId {
    console.log('ei id', this.viewContextDto.entityInstanceId);
    const linkId = <LinkId>{};
    linkId.linkedId = this.viewContextDto.entityInstanceId.id;
    linkId.linkedIdType = LinkedIdTypeEnum.EntityInstanceId;
    return linkId;
  }


  getUserAsLinkId(): LinkId {
    const linkId = <LinkId>{};
    linkId.linkedId = this.customer.id;
    linkId.linkedIdType = LinkedIdTypeEnum.CustomerId;
    return linkId;
  }
}
