import { Component, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { AppointmentService, ChangeState } from '@savvy/petBoarding';
import { ContextIdDto } from '@savvy/student';
// import { Appointment, UpdateAppointment } from '@savvy/petBoarding/model/models';
import { MatTabGroup } from '@angular/material/tabs';
import { Subscription } from 'rxjs';
import { WorkflowState, PhoneNumber } from '@savvy/workflow-instance';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConfirmationDialogComponent } from 'src/app/flo/confirmation-dialog/confirmation-dialog.component';
import { Plan, PlanService } from '@savvy/plan';
import { Contact, PetService } from '@savvy/pet';
import { FeaturesHelperService } from 'src/app/flo/shared/services/features-helper.service';
import { Feature } from '@savvy/add-ons';
import { CustomerCompositeService, CustomerService } from '@savvy/customer';
import { Customer } from '@savvy/customer';
import { ClarificationDialogComponent } from 'src/app/flo/clarification-dialog/clarification-dialog.component';
import { SmsCreditService } from 'src/app/flo/shared/services/sms-credit.service';
import { WorkflowDetailsModalComponent } from 'src/app/flo/workflow/workflow-details/workflow-details-modal/workflow-details-modal.component';
import { ContextService } from '../../context.service';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';
// import { ViewAppointmentV2Service } from '../../petBoarding/view-petBoarding-v2.service';
// import { AppointmentConfigHelperService } from '../../shared/services/petBoarding-config-helper.service';
import { BoardingBooking, BoardingBookingService, PetRunBooking, UpdateBoardingBooking } from '@savvy/pet-stay';
import { ChangeState } from '@savvy/appointment';
import { AppointmentConfigHelperService } from '../../shared/services/appointment-config-helper.service';
import { ViewPetBoardingV2Service } from './view-pet-boarding-v2.service';
import { DateUtils } from '../../dates/DateUtils';
import * as moment from 'moment';
import { UserCurrencyService } from '../../shared/services/userCurrency.service';
import { GenerateDraftInvoiceModalComponent, GenerateDraftInvoiceModalData } from '../invoicing-pet-stays/generate-draft-invoice-modal/generate-draft-invoice-modal.component';

@Component({
  selector: 'app-view-pet-boarding',
  templateUrl: './view-pet-boarding.component.html',
  styleUrls: ['./view-pet-boarding.component.scss']
})
export class ViewPetBoardingComponent implements OnInit, OnDestroy {

  @ViewChild(MatTabGroup) matTabGroup: MatTabGroup;
  // @ViewChild(DetailedPetViewComponent, { static: false }) detailedPetViewComponent: DetailedPetViewComponent;


  contextIdDto: ContextIdDto;
  boardingBooking: BoardingBooking;
  quickAppointment = true;
  startTime;
  endTime;

  currentTab = 0;

  paymentCategory = 'amount';
  messageCategory = 'messages';

  showConfirmationDialog = false;
  updatedState: WorkflowState;

  subs: Subscription[] = [];

  uniqueServices: Array<PetRunBooking> = [];
  plan: Plan;
  contactList: Contact[] = [];
  showContacts = false;
  contactToShare: PhoneNumber;
  showAskForReview = false;
  showReviewUrlConfig = false;
  askForReview = false;
  showCancellationReason = false;
  featureType = Feature.TypeEnum;
  customer: Customer;
  cancellationReason = '';
  bypassReviewBooster = false;
  totalDays = 1;
  totalNights = 0;
  historyFor: 'bookings' | 'food' | 'medication' = 'bookings';
  currencyCode = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() public dialogRef: MatDialogRef<ViewPetBoardingComponent>,
    private contextService: ContextService,
    private boardingBookingService: BoardingBookingService,
    private planApi: PlanService,
    private dialog: MatDialog,
    private notify: FloSnackbarComponent,
    private viewPetBoardingV2Service: ViewPetBoardingV2Service,
    public appointmentConfigHelperService: AppointmentConfigHelperService,
    private router: Router,
    public deviceService: DeviceDetectorService,
    public petService: PetService,
    public featuresHelperService: FeaturesHelperService,
    private customerCompositeService: CustomerCompositeService,
    private customerService: CustomerService,
    private smsCreditService: SmsCreditService,
    private dateUtils: DateUtils,
    private userCurrencyService: UserCurrencyService
  ) {
    this.contextIdDto = data.contextIdDto;
  }

  askForReviewChanged(ask: boolean) {
    if (!ask) {
      this.showReviewUrlConfig = false;
    }
  }

  gotoPet(petId: string) {
    this.dialogRef.close();
    this.router.navigate(['pets/view', this.contextIdDto.contextId, this.contextIdDto.contextIdType, petId]);
  }

  gotoCustomer(customerId) {
    this.dialogRef.close();
    this.router.navigate(['customer/viewCustomer', this.contextIdDto.contextId, this.contextIdDto.contextIdType, customerId]);
  }

  dateFromTime(dStr) {
    const now = new Date();
    now.setHours(dStr.substr(0, dStr.indexOf(':')));
    now.setMinutes(dStr.substr(dStr.indexOf(':') + 1));
    now.setSeconds(0);
    return now;
  }

  ngOnInit(): void {
    console.log('loading boardingBookingId', this.data.boardingBookingId);
    this.getCurrencyCode();
    this.loadAppointment();
    // if (!this.appointmentConfigHelperService.appointmentConfig?.appointmentViewQuickMode || !this.quickAppointment) {
    this.fullScreen();
    // }
    this.subs.push(this.viewPetBoardingV2Service.updateState$.subscribe((res: WorkflowState) => {
      if (res) {
        this.showConfirmationDialog = true;
        this.showCancellationReason = false;
        this.showContacts = false;
        this.showAskForReview = false;

        this.updatedState = res;
        if (res.state === 'Ready For Collection') {
          this.showContacts = true;
          this.loadContacts();
        } else if (res.state === 'Cancelled') {
          // this.askForReview = this.appointmentConfigHelperService.appointmentConfig.askForReviewEnabled;
          this.showCancellationReason = true;
        } else if (res.state === 'Complete' && this.appointmentConfigHelperService.appointmentConfig.askForReviewEnabled) {
          this.askForReview = this.appointmentConfigHelperService.appointmentConfig.askForReviewEnabled;
          this.showAskForReview = true;
        } else {
          this.showContacts = false;
          this.showAskForReview = false;
        }
      }
    }));
  }

  customerUpdated(event) {
    if (event) {
      this.loadContacts();
    }
  }

  petUpdated(event) {
    if (event) {
      this.loadContacts();
    }
  }

  loadCustomer() {
    return this.customerService.loadCustomer(
      this.boardingBooking.customerId,
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType).subscribe(res => {
        this.customer = res;
        if (this.customer
          && (this.customer.mobilePhoneNumberObj?.internationalNumber
            || this.customer.phoneNumberObj?.internationalNumber
            || this.customer.workPhoneNumberObj?.internationalNumber
            || this.customer.whatsAppNumberObj?.internationalNumber)) {
          this.contactList.unshift({
            firstName: this.customer.firstName,
            lastName: this.customer.lastName,
            mobilePhoneNumberObj: this.customer.mobilePhoneNumberObj,
            phoneNumberObj: this.customer.phoneNumberObj,
            workPhoneNumberObj: this.customer.workPhoneNumberObj,
            whatsAppNumberObj: this.customer.whatsAppNumberObj,
          });
        }
        if (this.contactList?.length) {
          if (this.contactList[0].mobilePhoneNumberObj) {
            this.contactToShare = this.contactList[0].mobilePhoneNumberObj;
          } else if (this.contactList[0].phoneNumberObj) {
            this.contactToShare = this.contactList[0].phoneNumberObj;
          } else if (this.contactList[0].workPhoneNumberObj) {
            this.contactToShare = this.contactList[0].workPhoneNumberObj;
          } else if (this.contactList[0].whatsAppNumberObj) {
            this.contactToShare = this.contactList[0].whatsAppNumberObj;
          }
        }
      });
  }

  async loadContacts() {
    this.contactList = [];
    // const petIds = this.boardingBooking.petRunBookings?.map(pb => [...petIds, ...pb.petIds]);
    // const uniquePetIds = petIds.filter((item, pos) => petIds.indexOf(item) === pos);
    // for (const petId of uniquePetIds) {
    //   this.petService.load(this.contextIdDto.contextId, this.contextIdDto.contextIdType, petId).subscribe(pet => {
    //     if (pet?.contacts?.length) {
    //       this.contactList = [...this.contactList, ...pet.contacts];
    //     }
    //   });
    // }
    this.loadCustomer();
  }

  ngOnDestroy(): void {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }

  loadAppointment() {
    this.boardingBookingService.getBoardingBookingById(this.data.boardingBookingId)
      .subscribe(response => {
        if (response?.boardingBooking) {

          this.boardingBooking = response.boardingBooking;
          if (this.boardingBooking.startDate === this.boardingBooking.endDate) {
            this.totalDays = 1;
            this.totalNights = 0;
          } else {
            this.totalDays = this.dateUtils.getDurationInDays(new Date(this.boardingBooking.startDate), moment(this.boardingBooking.endDate).add(1, 'day').toDate());
            this.totalNights = this.dateUtils.getDurationInDays(new Date(this.boardingBooking.startDate), new Date(this.boardingBooking.endDate));
          }

          this.contactToShare = this.boardingBooking.contactPhone;
          this.startTime = this.dateFromTime(this.boardingBooking.startTime);
          this.endTime = this.dateFromTime(this.boardingBooking.endTime);
          const dataSource = this.boardingBooking.petRunBookings;
          this.uniqueServices = _.uniqBy(dataSource, 'petRunId');

          if (this.boardingBooking.customerId) {
            this.loadCustomer();
          }

          if (this.boardingBooking.paymentConfig?.petStayPaymentMode === 'PAYMENT_PLAN'
            && this.boardingBooking.paymentConfig.paymentPlanId) {
            this.planApi.loadPlanById(
              this.contextIdDto.contextId,
              this.contextIdDto.contextIdType,
              this.boardingBooking.paymentConfig.paymentPlanId)
              .subscribe(res => {
                this.plan = res.plan;
              });
          }
        }
      });
  }

  cancel() {
    this.dialogRef.close();
  }

  fullScreen() {
    this.quickAppointment = false;
    this.dialogRef.updateSize('800px', 'auto');
    if (this.matTabGroup) {
      this.matTabGroup.realignInkBar();
    }
  }

  exitFullScreen() {
    this.quickAppointment = true;
    this.dialogRef.updateSize(null, 'auto');
  }

  tabChange(event) {
    console.log('event', event);
    this.currentTab = event;
  }

  edit() {
    this.dialogRef.close({ boardingBooking: this.boardingBooking, rebook: false });
  }

  rebookEvent() {
    this.dialogRef.close({ boardingBooking: this.boardingBooking, rebook: true });
  }

  cancelAppointment() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Cancel Booking',
        message: 'Are you sure you want to cancel this booking?',
        // isChange: true
      },
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.clarifyCancellation();
      }
    });
  }

  clarifyCancellation() {
    const dialogRef = this.dialog.open(ClarificationDialogComponent, {
      data: {
        title: 'Cancellation Reason',
        message: 'What is the reason for cancellation of this booking?'
      },
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const req: ChangeState = {
          newState: 'Cancelled',
          reason: result?.clarify,
          askForReview: true,
        };
        this.boardingBookingService.changeStateV2(this.boardingBooking.id, this.contextIdDto.contextId,
          this.contextIdDto.contextIdType, req)
          .subscribe(response => {
            if (response) {
              this.notify.message = 'Booking cancelled successfully';
              this.notify.open();
              this.dialogRef.close();
            }
          });
      }
    });
  }

  deleteAppointment() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete Booking',
        message: 'Are you sure you want to delete this booking?',
      },
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && this.boardingBooking.id) {
        this.boardingBookingService.deleteBoardingBooking(this.boardingBooking.id)
          .subscribe(response => {
            if (response) {
              this.boardingBooking = response;
              this.notify.message = 'Booking deleted successfully';
              this.notify.open();
              this.dialogRef.close();
            }
          });
      }
    });

  }

  hasGoogleReviewUrl() {
    return this.appointmentConfigHelperService.appointmentConfig.googleReviewUrl &&
      this.appointmentConfigHelperService.appointmentConfig.googleReviewUrl.trim().length > 0;
  }

  hasFacebookReviewUrl() {
    return this.appointmentConfigHelperService.appointmentConfig.facebookReviewUrl &&
      this.appointmentConfigHelperService.appointmentConfig.facebookReviewUrl.trim().length > 0;
  }

  confirmStateChange() {
    if (this.askForReview
      && !this.hasGoogleReviewUrl()
      && !this.hasFacebookReviewUrl()) {
      this.notify.message = 'There are no urls configured for review';
      this.notify.open();
      this.showReviewUrlConfig = true;
      return;
    }
    if (this.showCancellationReason && (!this.cancellationReason || !this.cancellationReason?.length)) {
      this.notify.message = 'Cancellation reason is required';
      this.notify.open();
      return;
    }
    this.showReviewUrlConfig = false;
    this.showCancellationReason = false;
    this.showConfirmationDialog = false;
    this.smsCreditService.checkSMSCredits(this.contextIdDto);

    this.viewPetBoardingV2Service.confirmUpdateState({
      state: this.updatedState,
      contact: this.contactToShare,
      askForReview: this.askForReview,
      cancellationReason: this.cancellationReason
    });
  }

  closeConfirmDialog() {
    this.showConfirmationDialog = false;
    this.viewPetBoardingV2Service.confirmUpdateState();
  }

  viewHistory($event) {
    console.log('view history called');
    this.currentTab = 1;
    this.matTabGroup.focusTab(1);
    //    this.matTabGroup.selectedIndexChange.emit(1);
  }

  openEditTemplate() {
    const confirmDialog = this.dialog.open(WorkflowDetailsModalComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        type: 'BOARDING'
      },
      // height: 'auto',
      width: '800px',
      panelClass: 'helpwindow'

    });

    confirmDialog.afterClosed().subscribe((result: boolean) => {
      if (result) {
      }
    });
  }

  configAppointment() {
    // const dialogRef = this.dialog.open(AppointmentV2ConfigComponent, {
    //   data: {
    //     contextIdDto: this.contextIdDto
    //   },
    //   panelClass: 'helpwindow'
    // });

    // dialogRef.afterClosed().subscribe((result) => {
    //   if (result) {
    //     this.appointmentConfigHelperService.loadAppointmentConfig(this.contextIdDto);
    //   }
    // });

  }

  appointmentUpdated(boardingBooking: BoardingBooking) {
    const updateBoardingBooking: UpdateBoardingBooking = {
      boardingBooking,
      contextIdDto: this.contextIdDto
    };
    this.boardingBookingService.updateBoardingBooking(updateBoardingBooking).subscribe(res => { });
  }

  updateBypassReviewBooster(event) {
    this.askForReview = !event;
    this.appointmentConfigHelperService.appointmentConfig.askForReviewEnabled = !event;
    this.appointmentConfigHelperService.updateAppointmentConfig(this.contextIdDto, this.appointmentConfigHelperService.appointmentConfig);
  }

  generateInvoice() {
    const data: GenerateDraftInvoiceModalData = {
      contextIdDto: this.contextIdDto,
      currencyCode: this.currencyCode,
      bookingId: this.boardingBooking?.id
    }

    const confirmDialog = this.dialog.open(GenerateDraftInvoiceModalComponent, {
      data,
      height: 'auto',
      width: 'auto',
      panelClass: 'helpwindow'
    });


    confirmDialog.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.loadAppointment();
      }
    });
  }

  private getCurrencyCode() {
    this.userCurrencyService.getDefaultCurrency(this.contextIdDto)
      .subscribe(res => {
        this.currencyCode = res.org.currencyCode ? res.org.currencyCode : this.userCurrencyService.defaultCurrency;
      });
  }

}
