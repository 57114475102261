import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Customer, CustomerService } from "@savvy/customer";
import { EmailmessagetemplatesService, Link } from '@savvy/email-template';
import { FileValue } from "@savvy/entity-instance-composite";
import { Invoice, InvoicecompService, SendInvoiceAsSms, SendInvoiceForQuote } from '@savvy/invoice';
import { SendInvoiceWithUserIds } from '@savvy/invoice/model/models';
import { SendInvoiceResponse } from "@savvy/invoice/model/sendInvoiceResponse";
import { EmailRecipient, MSISDN, SmsRecipient } from "@savvy/messaging";
import { ContextIdDto } from '@savvy/quickbooks';
import { QuoteId } from '@savvy/quote';
import { Student, StudentService } from "@savvy/student";
import { EventBusService } from '../element/EventBusService';
import { EventType } from '../event/UiEvent';
import { UploadFilesComponent } from "../shared/components/upload-files/upload-files.component";
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import RecipientTypeEnum = EmailRecipient.RecipientTypeEnum;

@Component({
  selector: 'app-send-invoice',
  templateUrl: './sendInvoice.component.html',
  styles: [`
  .invoice-container {
    max-height: calc(100vh - 184px);
    overflow: auto;
  }
  @media only screen and (max-width: 375px) {
    .invoice-container {
        height: calc(100vh - 118px);
        overflow-y: auto;
    }
  }`]
})
export class SendInvoiceComponent implements OnInit {

  @ViewChild('uploadFiles') uploadFilesComponent: UploadFilesComponent;
  @Input() invoice: Invoice;
  @Input() contextIdDto: ContextIdDto;
  @Input() visible: boolean;
  @Input() eventBus: EventBusService;
  @Input() sendInvoiceForQuote = false;
  @Input() quoteId: QuoteId;

  @Output() invoiceSent = new EventEmitter();
  @Output() invoiceSendCancelled = new EventEmitter();

  showSpinner = false;
  allowedToSend = true;
  body: string;
  subject: string;
  emailMessageTemplate: any;
  recipients: string;
  smsNumbers: string;
  messageText: string = 'Here is your invoice.';
  emailRecipient: EmailRecipient;

  savedFiles: FileValue[] = [];
  links: Link[] = [];

  public form: UntypedFormGroup; // our form model

  readonly messageTypes = ['Email', 'SMS'];
  selectedMessageType = this.messageTypes[0];

  recipientId: string;
  recipientType: RecipientTypeEnum;
  recipientFullName: string;

  constructor(
    private notify: FloSnackbarComponent,
    private emailMessageApi: EmailmessagetemplatesService,
    private invoicecompService: InvoicecompService,
    private customerService: CustomerService,
    private studentService: StudentService) {

  }

  ngOnInit() {

    console.log('inside new message init');
    this.emailMessageApi.getEmailMessageTemplateByType(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType, 'invoice').subscribe(response => {
        console.log('loading type');
        this.emailMessageTemplate = response;
        this.subject = response.subject;
        this.body = response.body;

        if (response?.attachments) {
          this.savedFiles = response?.attachments;
        }

        if (response?.links) {
          this.links = response?.links;
        }
      });
    this.loadRecipient();
  }

  bodyUpdated(text: string) {
    this.body = text;
  }

  loadRecipient() {
    if (this.invoice) {
      if (this.invoice.invoiceOwnerId) {
        if (this.invoice.invoiceOwnerId.invoiceOwnerIdType === 'CUSTOMER_ID') {
          this.customerService.loadCustomer(this.invoice.invoiceOwnerId.id, this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(res => {
            if (res) {
              this.handleCustomerMessageRecipient(res);
            }
          });
        } else if (this.invoice.invoiceOwnerId.invoiceOwnerIdType === 'STUDENT_ID') {
          this.studentService.loadStudent(this.invoice.invoiceOwnerId.id, this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(res => {
            if (res) {
              this.handleStudentMessageRecipient(res);
            }
          });
        }

      } else {
        console.log('no link id set');
      }
    } else {
      console.log('ERROR: No invoice!!');
    }
  }

  recipientChanged(address: string) {
    this.emailRecipient = <EmailRecipient>{};
    this.emailRecipient.address = address;
    this.emailRecipient.recipientId = 'UNKNOWN';
    this.emailRecipient.recipientType = EmailRecipient.RecipientTypeEnum.Customer;
  }

  handleCustomerMessageRecipient(customer: Customer) {
    if (customer && customer.optIn) {
      this.allowedToSend = customer.optIn?.allowEmail;
      this.recipients = customer.username;
    } else {
      console.log('ERROR: No customer!!');
    }

    this.emailRecipient = <EmailRecipient>{};
    this.emailRecipient.address = this.recipients;
    this.emailRecipient.recipientId = customer.id;
    this.emailRecipient.recipientType = EmailRecipient.RecipientTypeEnum.Customer;


    if (customer.mobilePhoneNumberObj?.internationalNumber) {
      this.smsNumbers = customer.mobilePhoneNumberObj?.internationalNumber;
    } else if (customer.phoneNumberObj?.internationalNumber) {
      this.smsNumbers = customer.phoneNumberObj?.internationalNumber;
    }

    this.recipientId = customer.id;
    this.recipientType = EmailRecipient.RecipientTypeEnum.Customer;
    this.recipientFullName = customer.firstName + ' ' + customer.lastName;
  }

  handleStudentMessageRecipient(student: Student) {
    if (student.optIn) {
      this.allowedToSend = student.optIn.allowEmail;
    }
    this.recipients = student.username;
    this.emailRecipient = <EmailRecipient>{};
    this.emailRecipient.address = student.username;
    this.emailRecipient.recipientId = student.id;
    this.emailRecipient.recipientType = EmailRecipient.RecipientTypeEnum.Student;


    if (student.mobilePhoneNumberObj?.internationalNumber) {
      this.smsNumbers = student.mobilePhoneNumberObj?.internationalNumber;
    } else if (student.phoneNumberObj?.internationalNumber) {
      this.smsNumbers = student.phoneNumberObj?.internationalNumber;
    }

    this.recipientId = student.id;
    this.recipientType = EmailRecipient.RecipientTypeEnum.Student;
    this.recipientFullName = student.firstName + ' ' + student.lastName;
  }

  send() {
    this.showSpinner = true;
    console.log('onSubmit');
    if (this.quoteId && this.sendInvoiceForQuote && this.selectedMessageType === this.messageTypes[0]) {
      this.sendInvoiceForQuoteByEmail();
    } else if (this.selectedMessageType === this.messageTypes[0]) {
      this.sendInvoiceEmail();
    } else if (this.selectedMessageType === this.messageTypes[1]) {
      this.sendInvoiceSms();
    }

  }

  sendInvoiceForQuoteByEmail() {
    const req: SendInvoiceForQuote = {};
    req.subject = this.subject;
    req.body = this.body;
    req.recipientEmailAddresses = [];
    req.recipientEmailAddresses.push(this.emailRecipient);
    req.contextIdDto = this.contextIdDto;
    req.invoiceId = this.invoice.invoiceId;
    req.quoteId = this.quoteId.id;
    this.invoicecompService.sendInvoiceForQuote(req).subscribe(response => {
      this.handleSendInvoiceResponse(response);
    });
  }

  sendInvoiceEmail() {
    const req = <SendInvoiceWithUserIds>{};
    req.subject = this.subject;
    req.body = this.body;
    req.recipientEmailAddresses = new Array();
    req.recipientEmailAddresses.push(this.emailRecipient);
    req.contextIdDto = this.contextIdDto;
    req.invoiceId = this.invoice.invoiceId;
    req.fileAttachments = this.getAttachments();
    req.links = this.links;

    this.invoicecompService.sendInvoiceComp(req).subscribe(response => {
      this.handleSendInvoiceResponse(response);
    });
  }

  sendInvoiceSms() {
    const req = <SendInvoiceAsSms>{};
    req.contextIdDto = this.contextIdDto;
    req.message = this.messageText;
    // To Do:  use real customer number
    req.toNumbers = [];
    req.links = this.links;
    req.invoiceId = this.invoice.invoiceId;
    if (this.smsNumbers) {
      const msisdns: string[] = this.smsNumbers.split(',');
      msisdns.forEach(msisdn => {
        const smsRecipient = <SmsRecipient>{};
        smsRecipient.recipient = <MSISDN>{};
        smsRecipient.recipient.msidn = msisdn;
        smsRecipient.recipientId = this.recipientId;
        smsRecipient.recipientType = this.recipientType;
        req.toNumbers.push(smsRecipient);
      });
    }
    this.showSpinner = true;
    this.invoicecompService.sendInvoiceAsSmsComp(req).subscribe(response => {
      this.handleSendInvoiceResponse(response);
    });
  }

  handleSendInvoiceResponse(response: SendInvoiceResponse) {
    console.log('Got response! ' + response);
    if (response.sendMessageError) {
      console.log('there was an error!');
      this.notify.message = 'Error sending invoice ' + response.sendMessageError;
      this.notify.open();
    } else {
      this.notify.message = 'Sent Invoice';
      this.notify.open();
    }
    this.showSpinner = false;
    this.invoiceSent.emit('sentInvoice');
    this.eventBus.addInvoiceEvent(EventType.SENT_INVOICE, this.invoice.invoiceId);
  }

  getAttachments() {
    const attachments = new Array();

    if (this.uploadFilesComponent.savedFiles) {
      this.uploadFilesComponent.savedFiles.forEach(
        savedFile => {
          attachments.push({
            ...savedFile,
            publicBucket: false
          });
        }
      );
    }
    return attachments;
  }

  cancelIt() {
    this.eventBus.addEvent(EventType.CANCEL_SEND_MESSAGE);
    this.invoiceSendCancelled.emit('cancelled');
  }

  
}
