<mat-toolbar color="accent">
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span>{{'SEND_MESSAGE' | translate}}</span>
    <button mat-icon-button (click)="delete()" aria-label="Delete">
      <mat-icon>delete</mat-icon>
    </button>
  </mat-toolbar-row>

</mat-toolbar>

<mat-card appearance="outlined" class="m-0" *ngIf="initialised">
  <aw-wizard [navBarLocation]="'left'" class="custom-global-scss">
    <aw-wizard-step [stepTitle]="'MESSAGE_NAME' | translate" [stepId]="'messageName'"
      (stepEnter)="enterStep($event, 'messageName')">
      <mat-form-field appearance="outline" style="width: 50%">
        <mat-label>{{'GIVE_MESSAGE_NAME' | translate}}</mat-label>
        <input matInput [(ngModel)]="workflowTaskDefinition.taskSettings.taskName" #taskName="ngModel" name="taskName"
          placeholder="{{'MESSAGE_NAME' | translate}}">
      </mat-form-field>
      <br>
      <button type="button" mat-flat-button color="accent" awNextStep class="ml-1">Next</button>

    </aw-wizard-step>

    <aw-wizard-step [stepTitle]="'ENTER_MESSAGE' | translate" [stepId]="'enterMessage'"
      (stepEnter)="enterStep($event, 'enterMessage')">

      <div fxLayout="row" fxLayoutAlign="center start">
        <div fxFlex="100" class="mt-xs">
          <mat-form-field appearance="outline" style="width: 50%">
            <mat-label>{{'SUBJECT' | translate}}</mat-label>
            <input matInput [(ngModel)]="workflowTaskDefinition.actionDefinition.sendMessageDefinition.subject"
              #subject="ngModel" name="subject" placeholder="{{'SUBJECT' | translate}}">
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="center start">
        <div fxFlex="100" class="mt-xs">
          <!--
                      @Input() links: Link[];
-->
          <app-appointment-quick-links-editor
            [(editorContent)]="workflowTaskDefinition.actionDefinition.sendMessageDefinition.body"
            [messageType]="'EMAIL'" messagingMenu="WORKFLOW" (editorContentChange)="bodyUpdated($event)">
          </app-appointment-quick-links-editor>
          <!--app-flo-editor [text]="workflowTaskDefinition.actionDefinition.sendMessageDefinition.body"
                          (textUpdated)="bodyUpdated($event)"></app-flo-editor-->
        </div>
      </div>

      <button type="button" mat-flat-button color="accent" awPreviousStep>{{'PREVIOUS' | translate}}</button>&nbsp;&nbsp;
      <button type="button" mat-flat-button color="accent" awNextStep class="ml-1">{{'NEXT' | translate}}</button>

    </aw-wizard-step>

    <aw-wizard-step [stepTitle]="'SCHEDULE' | translate" [stepId]="'schedule'" (stepEnter)="enterStep($event, 'schedule')">
      <app-add-edit-schedule-def *ngIf="workflowTaskDefinition?.autoTaskDefinition && workflowDefinitionTypeData"
        [contextIdDto]="contextIdDto" [title]="'WHEN_SEND_MESSAGE' | translate"
        (scheduleUpdated)="scheduleUpdated($event)"
        [scheduleDef]="workflowTaskDefinition.autoTaskDefinition.workflowScheduleDefinition"
        [workflowDefinitionId]="workflowDefinitionId" [workflowDefinitionTypeData]="workflowDefinitionTypeData">
      </app-add-edit-schedule-def>
      <button type="button" mat-flat-button color="accent" awPreviousStep>{{'PREVIOUS' | translate}}</button>&nbsp;&nbsp;
      <button mat-raised-button (click)="save()" color="accent">{{'SAVE' | translate}}</button>
    </aw-wizard-step>
  </aw-wizard>
</mat-card>
