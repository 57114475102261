import {Injectable} from '@angular/core';
import {LookAndFeel} from '@savvy/look-and-feel';

@Injectable()
export class LookAndFeelLocalService {
  lookAndFeel: LookAndFeel;
  setLookAndFeel(lookAndFeel) {
    this.lookAndFeel = lookAndFeel;
  }
}
