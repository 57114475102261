import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { DateTimeAdapter } from '@danielmoncada/angular-datetime-picker';
import { TranslateService } from '@ngx-translate/core';
// import { Invoice } from '@savvy/invoice';
import {
  Invoice,
  InvoicecompService,
  InvoicePanelDto,
  InvoiceServiceRow,
  UpdateInvoiceComp
} from '@savvy/invoice';
import {
  InvoiceDefinition, UserDto, UserId
} from '@savvy/view-definition';
import { ContextIdDto } from '@savvy/quickbooks';
import { ConsumerService } from '@savvy/services';
import { Product } from '@savvy/products';
import { LookAndFeelService } from '@savvy/look-and-feel';
import { AdditionalDataMapDto, ViewContextDto } from '@savvy/view-composite';
import * as _ from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EventBusService } from '../EventBusService';
import { ChangeListener } from '../changeListener';
import { ElementControlService } from '../ElementControlService';
import { LookAndFeelSharedService, TimeValue } from '../../shared/services/look-and-feel-shared.service';
import { DateUtils } from '../../dates/DateUtils';
import { EventType } from '../../event/UiEvent';
import { AddServiceDialogComponent } from '../fieldinstance/addServiceDialog.component';
import { ServicesHelperService } from '../../shared/services/services-helper.service';

@Component({
  selector: 'app-base-invoice-services-panel',
  templateUrl: './base-invoice-services-panel.component.html',
  styleUrls: ['./base-invoice-services-panel.component.scss']
})
export class BaseInvoiceServicesPanelComponent implements OnInit, AfterViewInit {

  @Input() invoice: Invoice;
  @Input() invoicePanelDto: InvoicePanelDto;
  @Input() userDtos: Array<UserDto>;
  @Input() invoiceDefinition: InvoiceDefinition;
  @Input() viewContext: ViewContextDto;
  @Input() contextIdDto: ContextIdDto;
  @Input() additionalDataMapDto: AdditionalDataMapDto;
  @Input() eventBus: EventBusService;
  @Input() form: UntypedFormGroup;
  @Input() consumerServices: Array<ConsumerService>;
  @Input() products: Array<Product>;
  @Input() changeListener: ChangeListener;
  @Input() currencyCode: string;

  @Output() taskSelected = new EventEmitter();
  @Output() taskDeselected = new EventEmitter();
  @Output() invoiceUpdated = new EventEmitter();

  valid = false;
  init = false;
  timeArray: TimeValue[] = [];
  customerId: string;


  public filteredServices: ReplaySubject<ConsumerService[]> = new ReplaySubject<ConsumerService[]>(1);
  // filteredServiceGroups: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  searchService = false;

  private destroy$ = new Subject();


  constructor(
    private dialog: MatDialog,
    private dateUtils: DateUtils,
    private ecs: ElementControlService,
    private invoicecompService: InvoicecompService,
    private dateTimeAdapter: DateTimeAdapter<any>,
    private translateService: TranslateService,
    private servicesHelperService: ServicesHelperService,
    private lookAndFeelApi: LookAndFeelService,
    private lookAndFeelService: LookAndFeelSharedService,
    private snackBar: MatSnackBar
  ) {

    console.log('setting locale to ', this.translateService.currentLang);
    this.dateTimeAdapter.setLocale(this.translateService.currentLang);

  }

  get invoiceServiceRows() {
    return this.form.controls.invoiceServiceRows as FormArray;
  }

  getGroupedServices(services: ConsumerService[]) {
    const tempArray = [];
    const grouped = _.groupBy(services, (service) => service.groupName);
    for (const key in grouped) {
      if (Object.prototype.hasOwnProperty.call(grouped, key)) {
        const element = grouped[key];
        tempArray.push({
          name: key,
          services: element
        });
      }
    }
    return tempArray;
  }


  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.lookAndFeelApi.getLookAndFeel(this.contextIdDto.contextId).subscribe(response => {
      //   console.log('setting time array');
      this.timeArray = this.lookAndFeelService.generateLocalisedTimeArray(response.timeWidgetPreferences.timeDropDownPeriod,
        response.timeDisplayPreferences);
      if (!this.invoice.invoiceServiceRows || this.invoice.invoiceServiceRows.length === 0) {
        this.createServiceRow();
      } else {
        this.ensureFirstRowSet();
      }
      // load the initial bank list
      this.filteredServices.next(this.consumerServices);
      this.updateInvoiceLite();
    });
  }

  ensureFirstRowSet() {
    if (!this.invoice.invoiceServiceRows[0].serviceUser) {
      this.invoice.invoiceServiceRows[0].serviceUser = this.userDtos[0].userId;
    }
    if (!this.invoice.invoiceServiceRows[0].serviceNumber) {
      if (this.consumerServices.length > 0) {
        this.invoice.invoiceServiceRows[0].serviceNumber = this.consumerServices[0].id;
        this.invoice.invoiceServiceRows[0].quantity = 1;
        this.invoice.invoiceServiceRows[0].unitPrice = this.consumerServices[0].unitPrice;
        this.invoice.invoiceServiceRows[0].tax = this.consumerServices[0].tax;

        this.setEndTimeFromStart(this.invoice.invoiceServiceRows[0], this.invoice.invoiceServiceRows[0].startTime);
      } else {
        console.log('err no services');
      }
    }
    if (this.invoiceServiceRows.length === 0) {
      this.setServiceData();
    }
  }

  getEiLabel(idx: number) {
    if (this.invoicePanelDto.invoicePanelServiceRowDtos.length > idx) {
      return this.invoicePanelDto.invoicePanelServiceRowDtos[idx].eiLabel;
    }
    return null;
  }

  startTimeChanged(row: UntypedFormGroup, value: any) {
    console.log('start time changed:' + row.value.startTime);
    console.log('start time value:' + value);
    this.setEndTimeFromStartFormGroup(row, value);
    this.updateInvoiceLite();
  }

  setEndTimeFromStartFormGroup(row: UntypedFormGroup, value: any) {
    if (row.value.serviceNumber) {
      const service = this.getService(row.value.serviceNumber);
      if (service) {
        const durationInMins = service.durationInMins;
        console.log('got duration in mins ' + durationInMins);
        console.log('value is ' + value);
        if (value) {
          row.patchValue({
            endTime: this.dateUtils.addMinsToTimeString(value, durationInMins)
          });
          console.log('just set endTime to ' + row.value.endTime);
        }
      }
    }
  }

  setEndTimeFromStart(row: InvoiceServiceRow, value: any) {

    if (row.serviceNumber) {
      const service = this.getService(row.serviceNumber);
      if (service) {
        const durationInMins = service.durationInMins;
        console.log('got duration in mins ' + durationInMins);
        console.log('value is ' + value);
        if (value) {
          row.endTime = this.dateUtils.addMinsToTimeString(value, durationInMins);
          console.log('just set endTime to ' + row.endTime);
        }
      }
    }
  }

  setEndTimeFromStartRow(row: UntypedFormGroup) {

    if (row.value.serviceNumber && row.value.startTime) {
      const service = this.getService(row.value.serviceNumber);
      if (service) {
        const durationInMins = service.durationInMins;
        console.log('got duration in mins ' + durationInMins);
        row.patchValue({
          endTime: this.dateUtils.addMinsToTimeString(row.value.startTime, durationInMins)
        });
        console.log('just set endTime to ' + row.value.endTime);
      }
    }
  }

  startDateChange(row: UntypedFormGroup) {
    let dateStr;
    if (row.value.startDate) {
      dateStr = this.dateUtils.getDateAsStringDash(row.value.startDate);
    }
    console.log('dateStr ' + dateStr);
    row.patchValue({
      startDate: dateStr,
      endDate: dateStr
    });
    console.log('start date is ', row.value.startDate);
    // Reset all services to be same start / end date
    this.invoiceServiceRows.controls.forEach(control => {
      control.patchValue({
        startDate: row.value.startDate,
        endDate: row.value.endDate
      });
    });
    // Update all

    this.updateInvoiceLite();
  }

  endTimeChanged(row: UntypedFormGroup, value: any) {
    console.log('end time changed:' + row.value.startTime);
    console.log('end time value:' + value);
    this.updateInvoiceLite();
  }

  priceChanged(row: UntypedFormGroup, event: any) {
    // console.log('price changed:', row.value.unitPrice);
    // console.log('price event:', event);
    // row.patchValue({
    //   unitPrice: event
    // });
    this.updateInvoiceLite();
  }

  endDateChange(row: InvoiceServiceRow) {
    let dateStr;
    if (row.endDate) {
      dateStr = this.dateUtils.getDateAsStringDash(row.endDate);
    }
    console.log('dateStr ' + dateStr);

    row.endDate = dateStr;

    /*this.invoice.invoiceServiceRows.forEach(aRow => {
      aRow.endDate = dateStr;
    });*/
    this.updateInvoiceLite();
  }

  createServiceRow() {
    console.log('creating row');
    const newInvoiceRow: InvoiceServiceRow = <InvoiceServiceRow>{};
    newInvoiceRow.quantity = 1;

    if (!this.invoice.invoiceServiceRows) {
      console.log('creating rows array');
      this.invoice.invoiceServiceRows = new Array(0);
    } else {

      // Lets make it the same as the previous row
      const lastRow = _.cloneDeep(this.invoiceServiceRows.at(this.invoiceServiceRows.controls.length - 1) as UntypedFormGroup)?.value;
      if (lastRow) {
        newInvoiceRow.serviceUser = lastRow.serviceUser;
        newInvoiceRow.quantity = lastRow.quantity;
        newInvoiceRow.entityInstanceId = lastRow.entityInstanceId;
        console.log('is last row set ei to ', lastRow.entityInstanceId);
        newInvoiceRow.startDate = lastRow.startDate;
        console.log('is last row set startDate to ', lastRow.startDate);
        newInvoiceRow.startTime = lastRow.startTime;
        newInvoiceRow.endDate = lastRow.endDate;
        newInvoiceRow.serviceNumber = lastRow.serviceNumber;
        this.setEndTimeFromStartFormGroup(
          this.invoiceServiceRows.at(this.invoiceServiceRows.controls.length - 1) as UntypedFormGroup, lastRow.startTime);
        newInvoiceRow.subTotal = lastRow.subTotal;
        newInvoiceRow.discount = lastRow.discount;
        newInvoiceRow.grossPrice = lastRow.grossPrice;
        newInvoiceRow.tax = lastRow.tax;
        console.log('set price to ', lastRow.grossPrice);
      } else {
        console.log('not last row');
        if (this.userDtos && this.userDtos.length > 0) {
          newInvoiceRow.serviceUser = this.userDtos[0].userId;
          newInvoiceRow.quantity = 1;
        } else {

        }
      }
    }
    console.log('invoiceServiceRows to ', newInvoiceRow);
    console.log('adding service row 129');

    this.invoice.invoiceServiceRows.push(newInvoiceRow);
    this.invoicePanelDto.invoicePanelServiceRowDtos
      .push(this.invoicePanelDto.invoicePanelServiceRowDtos[this.invoicePanelDto.invoicePanelServiceRowDtos.length - 1]);
    this.addService(newInvoiceRow);
    console.log('rows now ' + this.invoice.invoiceServiceRows.length);
    this.serviceChanged(this.invoiceServiceRows.at(this.invoiceServiceRows.controls.length - 1) as UntypedFormGroup, true); // Causes recalculate
  }

  deleteServiceRow(index: number) {
    if (this.invoiceServiceRows.length === 1) {
      this.snackBar.open('At least one service should be selected', 'ok', {
        duration: 3000,
      });
      return;
    }
    this.invoiceServiceRows.removeAt(index);
    if (index === 0) {
      this.invoiceServiceRows.at(index).get('startDate').enable();
    }
    this.updateInvoice();
  }

  serviceChanged(invServiceRow: UntypedFormGroup, fullUpdate: boolean) {
    if (this.invoice.invoiceServiceRows) {
      // this.invoice.invoiceServiceRows.forEach(invServiceRow => {
      console.log('got row ' + invServiceRow);
      if (invServiceRow.value.serviceNumber) {
        console.log('Service number is ' + invServiceRow.value.serviceNumber);
        const serviceDto = this.getService(invServiceRow.value.serviceNumber);
        if (serviceDto) {
          invServiceRow.patchValue({
            unitPrice: serviceDto.unitPrice,
            tax: serviceDto.tax
          });
          console.log('set unit price to ' + serviceDto.unitPrice);
        } else {
          console.log('failed to get service');
        }
      }
      // });
    }
    this.setEndTimeFromStartRow(invServiceRow);
    if (fullUpdate) {
      this.updateInvoice();
    } else {
      this.updateInvoiceLite();
    }
  }

  eiChanged(row: UntypedFormGroup, entityInstanceId: string) {
    console.log('eiChanged:' + entityInstanceId);
    if (row.value.entityInstanceId) {
      console.log('row is ' + row.value.entityInstanceId.id);
    }
    row.patchValue({
      entityInstanceId: {
        id: entityInstanceId
      }
    });
    // row.entityInstanceId = <EntityInstanceId>{};
    // row.entityInstanceId.id = entityInstanceId;
    if (row.value.entityInstanceId) {
      console.log('row is ' + row.value.entityInstanceId.id);
    }
    this.updateInvoice();
  }

  serviceUserChanged() {
    console.log('service user changed');
    this.updateInvoiceLite();
  }

  getService(entityInstanceId: string): ConsumerService {

    let retConsumerService: ConsumerService;

    console.log('looking for service with id ', entityInstanceId);
    if (this.consumerServices) {
      console.log('there are ', this.consumerServices.length);
      this.consumerServices.forEach(serviceDto => {

        if (serviceDto.id === entityInstanceId) {
          console.log('found match!');
          retConsumerService = serviceDto;
          return serviceDto;
        } else {
          console.log('no match');
        }
      });
    }
    return retConsumerService;
  }

  setUserOptVal(user: UserDto): UserId {
    return { id: user.id, userContextType: UserId.UserContextTypeEnum.UserId };
  }

  compareUserFn(user1: UserDto, user2: UserDto) {
    // console.log('comparing');
    return user1 && user2 && user1.id === user2.id;
  }

  compareTimeFn(user1: string, user2: string) {
    // console.log('comparing');
    return user1 && user2 && user1 === user2;
  }
  updateInvoice() {
    this.formToInvoice();
    const req: UpdateInvoiceComp = <UpdateInvoiceComp>{};
    req.contextIdDto = this.contextIdDto;
    req.invoice = this.invoice;
    req.entityInstanceId = this.viewContext.entityInstanceId;

    // Ok, so what he was trying to do here.  Was because Australian dates are set to
    // 12 hours ahead, if they book a date/time it can get set to the wrong day as it uses local javascript date

    /*req.invoice.invoiceServiceRows.forEach((row) => {
      if (row.startDate) {
        console.log('resetting start date from', row.startDate);
        row.startDate = this.dateUtils.getDateAsStringDash(row.startDate);
        console.log('resetting start date to', row.startDate);
      }
      if (row.endDate) {
        row.endDate = this.dateUtils.getDateAsStringDash(row.endDate);
      }
    })*/

    this.invoicecompService.updateInvoicePanel(req)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.invoicePanelDto = response.invoicePanelDto;
        // @ts-ignore
        this.invoice = response.invoicePanelDto.invoice;
        console.log('response.changedElementList ' + response.changedElementList);
        // @ts-ignore
        this.ecs.handleChangedElements(response.changedElementList, this.form);
        this.invoiceUpdated.emit(response.invoicePanelDto.invoice);
        console.log('invoiceMulti adding invoice changed ' + this.changeListener);
        if (this.changeListener) {
          this.changeListener.add('invoiceChanged');
        }
      });
  }

  updateInvoiceLite() {
    this.formToInvoice();
    //  this.updateInvoice();
    //  console.log('startTimeChanged', this.invoice);
    const req: UpdateInvoiceComp = <UpdateInvoiceComp>{};
    req.contextIdDto = this.contextIdDto;
    req.invoice = _.cloneDeep(this.invoice);
    req.entityInstanceId = this.viewContext.entityInstanceId;


    /*req.invoice.invoiceServiceRows.forEach((row) => {
      if (row.startDate) {
        console.log('resetting start date from', row.startDate);
        row.startDate = this.dateUtils.getDateAsStringDash(row.startDate);
        console.log('resetting start date to', row.startDate);
      }
      if (row.endDate) {
        row.endDate = this.dateUtils.getDateAsStringDash(row.endDate);
      }
    })*/

    this.invoicecompService.updateInvoicePanel(req)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        //    this.invoicePanelDto = response.invoicePanelDto;
        //  this.invoice = response.invoicePanelDto.invoice;
        console.log('response.changedElementList ' + response.changedElementList);
        //    this.ecs.handleChangedElements(response.changedElementList, this.form);
        //   this.invoiceUpdated.emit(response.invoicePanelDto.invoice);
        console.log('invoiceMulti adding invoice changed ' + this.changeListener);
        //  this.changeListener.add('invoiceChanged');
        this.eventBus.addCalendarEvent(EventType.REFRESH_CALENDAR);
      });
  }


  createNewService(rowIndex) {


    this.createService(rowIndex);

  }

  createService(rowIndex) {
    const dialogRef = this.dialog.open(AddServiceDialogComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        serviceLabel: 'Service',
        eventBus: this.eventBus
      }
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this.destroy$)).subscribe(result => {
        console.log('back from dialog close');
        if (result && result.id) {
          console.log('got a new instance to link to ', result);
          this.loadAndSetService(result.id, rowIndex);
        }
      });
  }

  loadAndSetService(serviceId: string, rowIndex) {
    console.log('loading new services');
    this.servicesHelperService.loadServices(this.contextIdDto).then(response => {
      this.consumerServices = response;
      this.filteredServices.next(this.consumerServices);
      this.setSelectedService(serviceId, rowIndex);
    });
  }

  loadServices() {
    this.servicesHelperService.getConsumerServices(this.contextIdDto).then(consumerServices => {
      if (consumerServices.length) {
        this.consumerServices = consumerServices;
      }
    });
  }

  setSelectedService(serviceNumber, rowIndex) {
    if (this.invoiceServiceRows && this.invoiceServiceRows.length > 0) {
      const invServiceRow: UntypedFormGroup = this.invoiceServiceRows.at(rowIndex) as UntypedFormGroup;
      console.log('got row ' + invServiceRow.value);
      invServiceRow.patchValue({
        serviceNumber
      });
      console.log('Service number is ' + invServiceRow.value.serviceNumber);
      const serviceDto = this.getService(invServiceRow.value.serviceNumber);
      if (serviceDto) {
        invServiceRow.patchValue({
          unitPrice: serviceDto.unitPrice,
          tax: serviceDto.tax
        });
        console.log('set unit price to ' + serviceDto.unitPrice);
      } else {
        console.log('failed to get service');
      }
    }
  }

  filterServices(value: string) {
    this.servicesHelperService.getConsumerServices(this.contextIdDto).then(consumerServices => {
      if (!consumerServices) {
        return;
      }
      // get the search keyword
      if (!value) {
        this.filteredServices.next(consumerServices.slice());
        return;
      }
      const search = value.toLowerCase();
      // filter the consumerServices
      this.filteredServices.next(consumerServices.filter(serviceDto => serviceDto?.serviceName.toLowerCase().indexOf(search) > -1));
    });
  }

  displayFn(id) {
    if (!id) {
      return '';
    }
    const index = this.consumerServices.findIndex(state => state.entityInstanceId.id === id);
    if (index === -1) {
      return '';
    } else {
      return this.consumerServices[index]?.serviceName;
    }
  }

  setServiceData() {
    if (this.invoice?.invoiceServiceRows?.length) {
      this.invoice?.invoiceServiceRows.forEach(service => {
        this.addService(service);
      });
    }
  }

  addService(service: InvoiceServiceRow) {
    if (service) {
      const group = new UntypedFormGroup({
        serviceUser: new UntypedFormControl(service.serviceUser, this.invoiceDefinition.enableServiceRowUser ? Validators.required : []),
        entityInstanceId: new UntypedFormControl(service.entityInstanceId, this.invoiceDefinition.serviceRowLinkedEntity ? Validators.required : []),
        startDate: new UntypedFormControl(service.startDate, this.invoiceDefinition.enableStartTimeServiceRow ? Validators.required : []),
        endTime: new UntypedFormControl(service.endTime, this.invoiceDefinition.enableEndTimeServiceRow ? Validators.required : []),
        endDate: new UntypedFormControl(service.endDate, this.invoiceDefinition.enableEndTimeServiceRow ? Validators.required : []),
        serviceNumber: new UntypedFormControl(service.serviceNumber, Validators.required),
        quantity: new UntypedFormControl(service.quantity),
        unitPrice: new UntypedFormControl(service.unitPrice, Validators.required),
        tax: new UntypedFormControl(service.tax),
        subTotal: new UntypedFormControl(service.subTotal),
        grossPrice: new UntypedFormControl(service.grossPrice),
        discount: new UntypedFormControl(service.discount),
        totalString: new UntypedFormControl(service.totalString),
        unitPriceString: new UntypedFormControl(service.unitPriceString),
        taxString: new UntypedFormControl(service.taxString),
        quantityString: new UntypedFormControl(service.quantityString),
        startTime: new UntypedFormControl(service.startTime, this.invoiceDefinition.enableStartTimeServiceRow ? Validators.required : [])
      });
      this.invoiceServiceRows.push(group);
    }
  }

  formToInvoice() {
    if (this.invoice) {
      this.invoice.invoiceServiceRows = [];
    }
    this.invoiceServiceRows.controls.forEach(control => {
      console.log('adding service row 124');
      this.invoice.invoiceServiceRows.push(control.value);
    });
  }

}
