import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { LocaleService } from "../../services/locale.service";

@Pipe({
  name: 'localizedDate',
  pure: true
})
export class LocalizedDatePipe implements PipeTransform {

  constructor(private localeService: LocaleService) {
  }

  transform(value: any, pattern: string = 'shortDate'): any {
    let locale = this.localeService.getLocale();
    // console.log('using locale ', locale);
    let transformedValue = value;
    try {
      const datePipe: DatePipe = new DatePipe(locale);
      transformedValue = datePipe.transform(value, pattern);
    } catch (error) {
      console.info(`${locale} is falling back to en-GB`);
      const datePipe: DatePipe = new DatePipe('en-GB');
      transformedValue = datePipe.transform(value, pattern);
    }
    return transformedValue;
  }

}
