<form [formGroup]="petFormGroup">
  <h6 class="">Medical</h6>

  <div fxLayout="column" fxLayout.gt-md="row wrap" fxLayoutGap="10px" fxLayoutGap.gt-md="5px">
    <div fxFlex="100" fxLayout="row wrap" fxLayoutGap="0px" fxLayoutGap.gt-sm="10px">
      <mat-form-field fxFlex="100" fxFlex.gt-sm="46" fxFlex.gt-sm="24" appearance="outline" class="w-100">
        <mat-label>{{'VET_NAME' | translate}}</mat-label>
        <input matInput formControlName="vetName" name="vetName" />
        <!-- <mat-error *ngIf="petFormGroup.controls?.vetName?.errors && (petFormGroup.touched || petFormGroup.dirty)">
                        Please enter vet name.</mat-error> -->
      </mat-form-field>
      <mat-form-field fxFlex="100" fxFlex.gt-sm="46" fxFlex.gt-sm="24" appearance="outline" class="w-100">
        <mat-label>{{'VET_PHONE' | translate}}</mat-label>
        <input matInput formControlName="vetPhone" name="vetPhone" />
        <!-- <mat-error *ngIf="petFormGroup.controls?.vetPhone?.errors && (petFormGroup.touched || petFormGroup.dirty)">
                        Please enter vet phone number.</mat-error> -->
      </mat-form-field>
      <mat-form-field fxFlex="100" fxFlex.gt-sm="46" fxFlex.gt-sm="24" appearance="outline" class="w-100">
        <mat-label>{{'MICRO_CHIPPED' | translate}}</mat-label>
        <input matInput formControlName="microChipped" name="microChipped" />
        <!-- <mat-error *ngIf="petFormGroup.controls?.microChipped?.errors && (petFormGroup.touched || petFormGroup.dirty)">
                        Please enter micro chipped.</mat-error> -->
      </mat-form-field>
      <mat-form-field fxFlex="100" fxFlex.gt-sm="46" fxFlex.gt-sm="24" appearance="outline" class="w-100">
        <mat-label>{{'ALLERGIES' | translate}}</mat-label>
        <input matInput formControlName="allergies" name="allergies" />
        <!-- <mat-error *ngIf="petFormGroup.controls?.allergies?.errors && (petFormGroup.touched || petFormGroup.dirty)">
                        Please enter allergies.</mat-error> -->
      </mat-form-field>
    </div>
    <div fxFlex="100" fxLayoutGap="10px">
      <div fxLayout="column" fxLayout.gt-md="row" fxLayoutGap="0px" fxLayoutGap.gt-md="10px">
        <mat-form-field fxFlex="46" fxFlex.gt-sm="24" appearance="outline" class="w-100">
          <mat-label>{{'WEIGHT' | translate}}</mat-label>
          <input matInput formControlName="weight" name="weight" appNumberOnly [decimalPoints]="2" />
          <!-- <mat-error *ngIf="petFormGroup.controls?.microChipped?.errors && (petFormGroup.touched || petFormGroup.dirty)">
                        Please enter micro chipped.</mat-error> -->
        </mat-form-field>
        <mat-form-field fxFlex="46" fxFlex.gt-sm="24" appearance="outline" class="w-100">
          <mat-label>{{'VACCINATED' | translate}}</mat-label>
          <input matInput formControlName="vaccinated" name="vaccinated" />
          <!-- <mat-error *ngIf="petFormGroup.controls?.microChipped?.errors && (petFormGroup.touched || petFormGroup.dirty)">
                        Please enter micro chipped.</mat-error> -->
        </mat-form-field>
        <mat-form-field fxFlex="46" fxFlex.gt-sm="50" appearance="outline" class="w-100">
          <mat-label>{{'MEDICAL_NOTES' | translate}}</mat-label>
          <textarea matInput formControlName="medical" cols="50" rows="3"
            placeholder="{{'MEDICAL_NOTES' | translate}}..."></textarea>
          <!-- <mat-error *ngIf="petFormGroup.controls?.medical?.errors && (petFormGroup.touched || petFormGroup.dirty)">
            Please enter medical notes.</mat-error> -->
        </mat-form-field>
      </div>
    </div>
  </div>

</form>
