import { Injectable } from '@angular/core';
import { Location } from '@savvy/location';
import { PetSearchResultDto } from '@savvy/search/model/petSearchResultDto';
import { ContextIdDto } from '@savvy/user';
import { Pet, PetService } from '@savvy/pet';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import * as _ from 'lodash';
// import { AppointmentServices, AppointmentTime, SelectedServices, ServiceSelectorData } from '../shared/extra-types';
import { LookAndFeelSharedService, TimeValue } from '../../shared/services/look-and-feel-shared.service';
import { DateUtils } from '../../dates/DateUtils';
import { AppointmentConfigHelperService } from '../../shared/services/appointment-config-helper.service';
import * as moment from 'moment';
import { ServiceSelectorData } from '../../appointments-v2/shared/extra-types';
import {
  AppointmentDateLocal
} from '../../appointments-v2/create-pet-appointment-v2/repeat-appointments/repeat-filter/repeat-filter.component';
import { PopulateNewPetEventData } from '../../appointments-v2/create-pet-appointment-v2/create-pet-appointment-v2-sync.service';
import { Discount } from '@savvy/pet-stay';
import { BoardingBooking, PetRun, PetRunService, PetFeedingConfig, PetMedicationConfig, PetServiceBooking, PetRunDetails, PetStayTimeRange, PetStayPreference } from '@savvy/pet-stay';
import { TaxesHelperService } from '../../shared/services/taxes-helper.service';
import { Tax } from '@savvy/tax';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';
import { PetStayTimeRangeLocal, SlotEnum } from './day-care-date-selector/day-care-date-selector.component';
import { WorkingHours } from '@savvy/working-hours';

export interface SelectedPetData extends PetSearchResultDto {
  petId?: string;
  petFeedingConfig?: PetFeedingConfig;
  petMedicationConfig?: PetMedicationConfig;
  petServiceBookings?: PetServiceBooking[];
}
export interface PetRunSelectorData {
  selectedPetRun: PetRun;
  selectedPets: SelectedPetData[];
  subTotal?: number;
  grossPrice?: number;
  discount?: Discount;
  quantity?: number;
}
// export interface SelectedRuns { [key: string]: PetRunSelectorData[] };
@Injectable({
  providedIn: 'root'
})
export class CreateBoardingBookingV2SyncService {
  // petsSelected: PetSearchResultDto[] = [];
  bookingType: BoardingBooking.BookingTypeEnum;

  petList: Pet[] = [];
  customerId = '';
  customerName = '';
  selectedRuns: PetRunSelectorData[] = [];
  selectedLocation: Location = {};

  startDate = new Date();
  endDate = new Date();
  startDateReq = '';
  endDateReq = '';
  startTime = '';
  endTime = '';
  displayStartTime: Date;
  displayEndTime: Date;

  editMode = false;
  boardingBooking: BoardingBooking;

  runToUseWhenCreating: string;

  totalAmount = 0;
  totalAmountDue = 0;
  totalServices = 0;
  totalDays = 0;
  totalNights = 0;
  totalHours = 0;

  timeArray: TimeValue[] = [];

  petStayTimeRanges: PetStayTimeRangeLocal[] = [];
  petStayPreference: PetStayPreference;
  workingHours: WorkingHours;

  private petSelectionChangeSource: Subject<PetSearchResultDto[]> = new Subject();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public petSelectionChanged$ = this.petSelectionChangeSource.asObservable();

  private populateNewPetSource: Subject<PopulateNewPetEventData> = new Subject();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public populateNewPet$ = this.populateNewPetSource.asObservable();

  private createPetSource: Subject<boolean> = new Subject();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public createPet$ = this.createPetSource.asObservable();

  private totalAmountChangedSource: Subject<number> = new Subject();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public totalAmountChanged$ = this.totalAmountChangedSource.asObservable();

  private selectedLocationSource: BehaviorSubject<Location> = new BehaviorSubject(this.selectedLocation);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectedLocation$: Observable<Location> = this.selectedLocationSource.asObservable();

  private bookingTypeChangedSource: Subject<BoardingBooking.BookingTypeEnum> = new Subject();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public bookingTypeChanged$ = this.bookingTypeChangedSource.asObservable();


  constructor(
    private petService: PetService,
    private petRunService: PetRunService,
    private lookAndFeelSharedService: LookAndFeelSharedService,
    private taxesHelperService: TaxesHelperService,
    private dateUtils: DateUtils,
    public appointmentConfig: AppointmentConfigHelperService,
    private notify: FloSnackbarComponent,
  ) {

  }

  bookingTypeChanged(bookingType: BoardingBooking.BookingTypeEnum) {
    this.bookingType = bookingType;
    this.bookingTypeChangedSource.next(bookingType);
  }

  setPetList(customerId?: string, contextIdDto?: ContextIdDto) {
    if (customerId && contextIdDto?.contextId && contextIdDto?.contextIdType) {
      this.petService.loadCustomersPets(customerId)
        .subscribe(pets => {
          if (pets?.length) {
            const petIds: string[] = [];
            for (const selectedRun of this.selectedRuns) {
              for (const selectedPet of selectedRun.selectedPets) {
                if (selectedPet?.petId) {
                  petIds.push(selectedPet.petId);
                }
              }
            }
            this.petList = pets.filter(p => petIds.indexOf(p.id) === -1);
          }
        });
    } else {
      this.petList = [];
    }
  }

  setSelectedLocation(location: Location) {
    this.selectedLocation = Object.assign({}, location);
    this.selectedLocationSource.next(this.selectedLocation);
  }

  setTimeArray(contextIdDto: ContextIdDto) {
    if (contextIdDto) {
      const lookAndFeelConfig = this.lookAndFeelSharedService.getLookAndFeelConfig(contextIdDto);
      if (lookAndFeelConfig) {
        // const lookAndFeel = lookAndFeelConfig.lookAndFeel;
        // this.minuteInterval = this.lookAndFeelConfig.minuteInterval;
        this.timeArray = lookAndFeelConfig.timeArray;
      }
    }
  }

  updatePetSelection(newPets: PetSearchResultDto) {
    // this.petsSelected = newPets;
    // this.petSelectionChangeSource.next(this.petsSelected);
  }

  dateFromTime(dStr) {
    const now = new Date();
    if (dStr) {
      now.setHours(dStr.substr(0, dStr.indexOf(':')));
      now.setMinutes(dStr.substr(dStr.indexOf(':') + 1));
      now.setSeconds(0);
    }
    return now;
  }

  updateGrossPriceForRun(petRun: PetRunSelectorData) {
    if (petRun?.selectedPetRun?.taxId) {
      const tax = this.taxesHelperService.taxDefs.find(td => td.id === petRun.selectedPetRun.taxId);
      if (tax) {
        petRun.selectedPetRun.tax = tax.taxRate;
        if (petRun.subTotal) {
          const subTotal = Number(petRun.subTotal) * Number(petRun.quantity);
          petRun.grossPrice = Number((subTotal + (subTotal * ((tax?.taxRate || 0) / 100))).toFixed(2));
        }
      }
    } else {
      petRun.selectedPetRun.tax = 0;
      const subTotal = Number(petRun?.subTotal || 0) * Number(petRun.quantity);
      petRun.grossPrice = subTotal;
    }
    this.calculateTotalAmount();
  }

  updateGrossPriceForFeeding(config: PetFeedingConfig) {
    if (config?.tax && config?.subTotal) {
      const subTotal = Number(config.subTotal) * Number(config.quantity);
      config.grossPrice = Number((subTotal + (subTotal * ((config.tax || 0) / 100))).toFixed(2));
    } else {
      config.tax = 0;
      const subTotal = Number(config?.subTotal || 0) * Number(config.quantity);
      config.grossPrice = subTotal;
    }
    this.calculateTotalAmount();
  }

  updateGrossPriceForMedication(config: PetMedicationConfig) {
    if (config?.tax && config?.subTotal) {
      const subTotal = Number(config.subTotal) * Number(config.quantity);
      config.grossPrice = Number((subTotal + (subTotal * ((config.tax || 0) / 100))).toFixed(2));
    } else {
      config.tax = 0;
      const subTotal = Number(config?.subTotal || 0) * Number(config.quantity);
      config.grossPrice = subTotal;
    }
    this.calculateTotalAmount();
  }

  updateQty() {
    for (const selectedRun of this.selectedRuns) {
      if (this.bookingType === BoardingBooking.BookingTypeEnum.DayCare) {
        if (this.petStayPreference.bookHourly) {
          selectedRun.quantity = this.totalHours;
        } else {
          selectedRun.quantity = selectedRun?.selectedPetRun?.chargePerNight ? this.totalNights : this.totalDays;
        }
      } else {
        selectedRun.quantity = selectedRun?.selectedPetRun?.chargePerNight ? this.totalNights : this.totalDays;
      }
      this.updateGrossPriceForRun(selectedRun);
      for (const selectedPet of selectedRun.selectedPets) {
        if (selectedPet.petFeedingConfig) {
          selectedPet.petFeedingConfig.quantity = this.totalDays;
          this.updateGrossPriceForFeeding(selectedPet.petFeedingConfig);
        }
        if (selectedPet.petMedicationConfig) {
          selectedPet.petMedicationConfig.quantity = this.totalDays;
          this.updateGrossPriceForMedication(selectedPet.petMedicationConfig);
        }
      }
    }
  }

  setAppointmentDates(startDate: Date, endDate: Date, startTime: string, endTime: string) {
    // console.log('inside setAppointmentDates we have startDate', startDate);
    if (startDate) {
      this.startDate = new Date(startDate);
      console.log('inside setAppointmentDates NOW we have startDate', startDate);
      this.startDateReq = this.dateUtils.getDateAsStringDash(startDate);
    }
    if (endDate) {
      this.endDate = new Date(endDate);
      this.endDateReq = this.dateUtils.getDateAsStringDash(endDate);
    }
    this.startTime = startTime;
    this.endTime = endTime;
    this.displayStartTime = this.dateFromTime(this.startTime);
    this.displayEndTime = this.dateFromTime(this.endTime);
    this.totalHours = 0;
    this.totalDays = 0;
    this.totalNights = 0;
    if (this.startDate === this.endDate) {
      this.totalDays = 1;
      this.totalNights = 0;
    } else {
      if (this.bookingType === BoardingBooking.BookingTypeEnum.Boarding) {
        this.totalDays = this.dateUtils.getDurationInDays(this.startDate, moment(this.endDate).add(1, 'day').toDate());
        this.totalNights = this.dateUtils.getDurationInDays(this.startDate, this.endDate);
      } else {
        for (const petStayTimeRange of this.petStayTimeRanges) {
          if (this.petStayPreference.bookHourly) {
            this.totalHours += Number(this.dateUtils.getDurationInHours(petStayTimeRange.startTime, petStayTimeRange.endTime).toFixed(1));
            this.totalDays += 1;
          } else {
            if (petStayTimeRange?.slotSelected === SlotEnum.FullDay) {
              this.totalDays += 1;
            } else {
              this.totalDays += 0.5;
            }
          }
        }
        if (this.petStayTimeRanges?.length) {
          this.startTime = this.petStayTimeRanges[0]?.startTime;
          this.endTime = this.petStayTimeRanges[this.petStayTimeRanges.length - 1]?.endTime;
          this.displayStartTime = this.dateFromTime(this.startTime);
          this.displayEndTime = this.dateFromTime(this.endTime);
        }
      }
    }
    this.updateQty();
  }

  calculateStartEndTime() {
    let minStart = '';
    let maxEnd = '';
    if (this.timeArray.length) {
      // if (this.appointmentConfig.appointmentConfig.customizedTimeForServices) {
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let tIndex = 0; tIndex < this.timeArray.length; tIndex++) {
        const t = this.timeArray[tIndex];
        if (minStart <= t.actualValue) {
          this.startTime = t.actualValue;
          break;
        }
      }
      this.displayStartTime = this.dateFromTime(this.startTime);
      for (let index = 0; index < this.timeArray.length; index++) {
        const t = this.timeArray[index];
        if (maxEnd && t.actualValue >= maxEnd) {
          this.endTime = t.actualValue;
          break;
        }
      }
      this.displayEndTime = this.dateFromTime(this.endTime);
    }
    this.calculateTotalAmount();
    this.setAppointmentDates(this.startDate, this.endDate, this.startTime, this.endTime);
  }

  clear() {
    this.updatePetSelection(null);
    this.selectedRuns = [];
    this.selectedLocation = {};
    this.editMode = false;
    this.boardingBooking = null;
    this.runToUseWhenCreating = null;
    this.totalAmount = 0;
    this.totalAmountDue = 0;
    this.totalServices = 0;
    this.petList = [];
  }

  handleCreatePetFromList() {
    this.createPetSource.next(true);
  }

  populateNewPet(newPet: Pet, petRunDetails?: Array<PetRunDetails>) {
    const petRunDetail = petRunDetails ? petRunDetails.find(prd => prd.petId === newPet.id) : {};
    const pet: SelectedPetData = {
      petId: newPet.id,
      petBreed: newPet.breedLabel,
      customerName: `${newPet.petOwnerFirstName} ${newPet.petOwnerLastName}`,
      ownerId: newPet.ownerId,
      petName: newPet.name,
      petOwnerId: newPet.petOwnerId,
      petNotes: newPet.petNotes,
      deleted: false,
      customerVerified: newPet?.approvalNeeded ? false : true,
      deceased: newPet.deceased,
      petFeedingConfig: petRunDetail?.petFeedingConfig ? petRunDetail?.petFeedingConfig : undefined,
      petMedicationConfig: petRunDetail?.petMedicationConfig ? petRunDetail?.petMedicationConfig : undefined,
      petServiceBookings: petRunDetail?.petServiceBookings?.length ? petRunDetail?.petServiceBookings : []
    };
    return pet;
  }

  populateNewCustomer(newPet: Pet) {
    const pet: PetSearchResultDto = {
      petId: newPet.id,
      ownerId: newPet.ownerId,
      petOwnerId: newPet.petOwnerId,
      petName: newPet.name,
      customerName: `${newPet.petOwnerFirstName} ${newPet.petOwnerLastName}`,
      petBreed: newPet.breedLabel,
      petNotes: newPet.petNotes,
      deleted: newPet.deleted,
      deceased: newPet.deceased
    };
  }

  populateAppointment(boardingBooking: BoardingBooking, contextIdDto: ContextIdDto, petStayTimeRanges: PetStayTimeRange[], petStayPreference: PetStayPreference) {
    return new Promise<BoardingBooking>(async (resolve) => {
      this.boardingBooking = boardingBooking;
      this.petStayPreference = petStayPreference;
      if (petStayTimeRanges?.length) {
        this.petStayTimeRanges = [];
        petStayTimeRanges = petStayTimeRanges.sort((a, b) => new Date(a.startDateUtc).getTime() - new Date(b.startDateUtc).getTime());
        for (const petStayTimeRange of petStayTimeRanges) {
          this.petStayTimeRanges.push({
            ...petStayTimeRange,
            date: new Date(),
            dayName: '',
            dayNumber: 0,
            disabled: false,
            selected: true
          });
        }
      } else {
        this.petStayTimeRanges = [];
      }
      this.bookingType = boardingBooking.bookingType;
      // 2022-03-11

      if (boardingBooking?.startDate) {
        console.log('POPULATING APPOINTMENT!!', boardingBooking.startDate);
        this.setAppointmentDates(
          this.dateUtils.convertDateStringToJsDate(boardingBooking.startDate),
          this.dateUtils.convertDateStringToJsDate(boardingBooking.endDate),
          boardingBooking.startTime,
          boardingBooking.endTime);
      } else {
        console.log('POPULATING APPOINTMENT!!', boardingBooking.start);
        this.setAppointmentDates(
          this.dateUtils.convertDateStringToJsDate(boardingBooking.startDateUtc),
          this.dateUtils.convertDateStringToJsDate(boardingBooking.endDateUtc),
          this.dateUtils.getTimeAsString(boardingBooking.startDateUtc),
          this.dateUtils.getTimeAsString(boardingBooking.endDateUtc));
      }

      for (const petRunBooking of boardingBooking.petRunBookings) {
        await this.petRunService.get(petRunBooking.petRunId).toPromise().then(async (petRun) => {
          const selectedPets: SelectedPetData[] = [];
          const thePets: Promise<any>[] = [];
          if (petRunBooking?.petRunDetails) {
            for (const pet of petRunBooking.petRunDetails) {
              try {
                thePets.push(this.petService.get(pet.petId).toPromise());
              } catch (error) {
                console.log('Pet not found');
                console.error(error);
              }
            }
          } else {
            for (const petId of petRunBooking.petIds) {
              try {
                thePets.push(this.petService.get(petId).toPromise());
              } catch (error) {
                console.log('Pet not found');
                console.error(error);
              }
            }
          }

          await Promise.all(thePets).then(responses => {
            console.log('Got all pets', responses.length);
            for (const res of responses) {
              selectedPets.push(this.populateNewPet(res, petRunBooking?.petRunDetails));
            }
            if (petRun && petRunBooking.unitPrice) {
              petRun.price = _.cloneDeep(petRunBooking.unitPrice);
            }
            if (petRun && petRunBooking.tax) {
              petRun.tax = _.cloneDeep(petRunBooking.tax);
              // eslint-disable-next-line max-len
              petRun.taxId = this.taxesHelperService.taxDefs.find(td => !td?.deleted && Number(td.taxRate) === Number(petRunBooking.tax))?.id;
            }
            if (selectedPets?.[0]?.petOwnerId) {
              this.customerId = selectedPets[0].petOwnerId;
              this.customerName = selectedPets[0].customerName;
            }
            this.selectedRuns.push({
              selectedPets,
              selectedPetRun: petRun,
              subTotal: petRunBooking.subTotal,
              grossPrice: petRunBooking.grossPrice,
              discount: petRunBooking.discountDetails,
              quantity: petRunBooking.quantity,
            });
          });
        });
      }
      this.calculateTotalAmount();
      this.setPetList(this.customerId, contextIdDto)
      resolve(this.boardingBooking);
    });
  }

  calculateTotalAmount() {
    this.totalAmount = 0;
    this.totalServices = 0;
    for (const selectedPetRun of this.selectedRuns) {
      if (selectedPetRun?.selectedPets?.length) {
        this.totalAmount += Number(selectedPetRun.grossPrice);
        for (const pet of selectedPetRun.selectedPets) {
          if (pet?.petFeedingConfig?.grossPrice) {
            this.totalAmount += Number(pet.petFeedingConfig.grossPrice);
          }
          if (pet?.petMedicationConfig?.grossPrice) {
            this.totalAmount += Number(pet.petMedicationConfig.grossPrice);
          }
          if (pet?.petServiceBookings) {
            for (const petServiceBooking of pet.petServiceBookings) {
              this.totalAmount += petServiceBooking?.grossPrice ? Number(petServiceBooking.grossPrice) : 0;
            }
          }
        }
      }
    }
    if (this.totalAmountDue > this.totalAmount || this.boardingBooking?.paymentConfig?.sendSms) {
      this.totalAmountDue = this.totalAmount;
    }
    this.totalAmountChangedSource.next(this.totalAmount);
  }

  removePet(petRowIndex: number) {
    // this.updatePetSelection(this.petsSelected);
    // this.calculateTotalAmount();
  }

  updateStartTimeAndEndTimeByStartTime(startTime: string) {
    // for (const petSelected of this.petsSelected) {
    //   for (const service of this.selectedRuns[petSelected.petId]) {
    //     const startTimeMoment = moment(service.startTime ? service.startTime : this.startTime, 'HH:mm');
    //     const endTimeMoment = moment(service.endTime ? service.endTime : this.endTime, 'HH:mm');
    //     const duration = moment.duration(endTimeMoment.diff(startTimeMoment));
    //     const hours = duration.asHours();
    //     service.startTime = startTime;
    //     service.endTime = moment(startTime, 'HH:mm').add(hours, 'hours').format('HH:mm');
    //   }
    // }
  }

  // eslint-disable-next-line max-len
  syncServiceStartAndEndTimeForRepeatApp(minStartTime: string, appointmentDate: AppointmentDateLocal, service: ServiceSelectorData): ServiceSelectorData {
    // find difference between start time between the service date and repeat date and apply adjustment accordinly
    const startDate = moment(appointmentDate.localStartDate).format('YYYY-MM-DD');
    const startTimeOfRepeat = moment(`${startDate} ${appointmentDate.startTime}`);
    const startTimeOfService = moment(`${startDate} ${minStartTime}`);

    const minutesDiff = moment.duration(startTimeOfRepeat.diff(startTimeOfService)).asMinutes();

    // service.startTime = moment(service.startTime, 'HH:mm').add(minutesDiff, 'minutes').format('HH:mm');
    // service.endTime = moment(service.startTime, 'HH:mm').add(service.selectedPetRun.durationInMins, 'minutes').format('HH:mm');
    console.log('Final Start and end', { start: service.startTime, end: service.endTime });

    return service;
  }

  getEarliestServiceTime() {
    const times = [];
    // for (const petSelected of this.petsSelected) {
    //   for (const service of this.selectedRuns[petSelected.petId]) {
    //     times.push(moment(service.startTime, 'HH:mm'));
    //   }
    // }
    return moment.min(times).format('HH:mm');
  }

  getSelectedUserIds() {
    const selectedUserIds = [];
    // for (const key in this.selectedRuns) {
    //   if (Object.prototype.hasOwnProperty.call(this.selectedRuns, key)) {
    //     const selectedServices = this.selectedRuns[key];
    //     for (const selectedPetRun of selectedServices) {
    //       selectedUserIds.push(selectedPetRun.selectedStaff.id);
    //     }
    //   }
    // }
    // return unique selected user ids in all services
    return selectedUserIds.filter((item, i, ar) => ar.indexOf(item) === i);
  }

  getTotalPets() {
    const petsTotal = this.selectedRuns.map(r => r.selectedPets.length);
    let totalPets = 0;
    for (const total of petsTotal) {
      totalPets += total;
    }
    return totalPets;
  }

  checkIfValid(): boolean {
    let valid = true;
    const tempSelectedRuns = Object.assign([], this.selectedRuns);
    for (const selectedRun of tempSelectedRuns) {
      if (!this.isRowValid(selectedRun)) {
        valid = false;
        break;
      } else {
        valid = true;
      }
    }
    return valid;
  }

  pushNewRun(selectedRun: PetRun) {

    const selectedPets: SelectedPetData[] = [];
    if (selectedRun?.maxPets) {
      for (let index = 0; index < selectedRun?.maxPets; index++) {
        selectedPets.push({
          petId: ''
        });
      }
    }
    let quantity = selectedRun?.chargePerNight ? this.totalNights : this.totalDays;
    let price = selectedRun?.price;

    if (this.petStayPreference.bookHourly && this.bookingType === BoardingBooking.BookingTypeEnum.DayCare) {
      quantity = this.totalHours;
      price = selectedRun?.hourlyPrice;
    }
    this.selectedRuns.push(
      _.cloneDeep({
        selectedPetRun: _.cloneDeep(selectedRun),
        selectedPets,
        quantity,
        grossPrice: price,
        subTotal: price,
      }));
    const lastAddedIndex = this.selectedRuns.length - 1;
    this.updateGrossPriceForRun(this.selectedRuns[lastAddedIndex]);
  }

  isRowValid(run: PetRunSelectorData) {
    return run.selectedPetRun.id && run.selectedPetRun.name && run.selectedPets.length;
  }

  addRunNew(contextIdDto: ContextIdDto) {
    if (this.selectedRuns.length
      // eslint-disable-next-line max-len
      && !this.isRowValid(this.selectedRuns[this.selectedRuns.length - 1])) {
      this.notify.message = 'Please select a service first';
      this.notify.open();
      return;
    }
    let selectedRun = {} as PetRun;
    this.petRunService.loadAllByOwnerIdAndDeleted(false, contextIdDto.contextId, contextIdDto.contextIdType)
      .subscribe((petRuns) => {
        if (petRuns.length) {
          if (this.runToUseWhenCreating) {
            selectedRun = petRuns.find(pr => pr.id === this.runToUseWhenCreating);
            this.bookingType = selectedRun?.petRunType || 'BOARDING';
          } else {
            selectedRun = _.cloneDeep(petRuns.filter(pr => pr.petRunType === this.bookingType)?.[0]);
          }
        }
        console.log('selectedRun', selectedRun);
        console.log('pusing new run');
        this.taxesHelperService.getTaxDefs(contextIdDto).then(taxDefs => {
          this.pushNewRun(selectedRun);
        });
      });
  }

}
