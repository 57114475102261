<mat-toolbar>
  <mat-toolbar-row>
    <h5>{{'PET_HISTORY' | translate}}</h5>
  </mat-toolbar-row>
</mat-toolbar>

<div class="mdc-p-3" *ngIf="petHistories">
  <ngx-datatable *ngIf="petHistories && petHistories.length > 0" class='material mdc-mb-2' [rows]='petHistories' [columnMode]="'force'" [headerHeight]="50" [rowHeight]="'auto'" [footerHeight]="50">


    <ngx-datatable-column name="{{'APPT_TIME' | translate}}" [width]="80">
      <ng-template ngx-datatable-cell-template let-row="row">
        {{ row.appointmentDate | localizedDate:'mediumDate' }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'BLADES' | translate}}" [width]="80">
      <ng-template ngx-datatable-cell-template let-row="row">
        {{ row.blades }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'NOTES' | translate}}" [width]="80">
      <ng-template ngx-datatable-cell-template let-row="row">
        {{ row.appointmentNotes }}
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
  <br>

  <button mat-raised-button color="accent" (click)="close()">{{'CLOSE' | translate}}</button>
</div>
