<form [formGroup]="form">
    <div formArrayName="discountItems" *ngFor="let discountItem of discountItemsFA['controls']; let i = index;"
        class="mdc-mb-2 border rounded-10 mdc-p-2 mdc-pt-3">
        <div fxLayout="row wrap" fxLayoutGap="2%" fxLayoutAlign=" center" [formGroupName]="i">
            <div fxFlex="100" fxFlex.gt-sm="20">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'CODE' | translate}}&nbsp;</mat-label>
                    <input type="text" matInput name="code" placeholder="{{'CODE' | translate}}" formControlName="code">
                </mat-form-field>
            </div>
            <div fxFlex="100" fxFlex.gt-sm="20">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'NOTES' | translate}}&nbsp;</mat-label>
                    <textarea matInput name="notes" placeholder="{{'NOTES' | translate}}" formControlName="notes"
                        rows="1"></textarea>
                </mat-form-field>
            </div>
            <div fxFlex="nogrow" fxFlex.gt-sm="20">
                <mat-radio-group formControlName="paymentType" class="w-100 mdc-m-2 mdc-mb-3 d-block">
                    <mat-radio-button value="AMOUNT">{{'AMOUNT' | translate}}</mat-radio-button>
                    <mat-radio-button value="PERCENTAGE">{{'PERCENTAGE' | translate}}</mat-radio-button>
                </mat-radio-group>
            </div>
            <div fxFlex="nogrow" fxFlex.gt-sm="10">
                <mat-form-field *ngIf="discountItem.controls['paymentType'].value === 'AMOUNT'" class="w-100"
                    appearance="outline">
                    <div matSuffix>{{ currencySymbol }}</div>
                    <mat-label>{{'AMOUNT' | translate}} </mat-label>
                    <input type="text" appNumberOnly [decimalPoints]="2" matInput name="amount"
                        placeholder="{{'AMOUNT' | translate}}" formControlName="amount">
                </mat-form-field>
                <mat-form-field *ngIf="discountItem.controls['paymentType'].value === 'PERCENTAGE'" class="w-100"
                    appearance="outline">
                    <div matSuffix class="mdc-px-2">%</div>
                    <mat-label>{{'PERCENTAGE' | translate}}</mat-label>
                    <input type="text" appNumberOnly matInput name="percentage"
                        placeholder="{{'PERCENTAGE' | translate}}" formControlName="percentage">
                </mat-form-field>
            </div>
            <div fxFlex="nogrow" fxFlex.gt-sm="nogrow">
                <mat-slide-toggle formControlName="active" class="mdc-mb-3 d-block">
                    {{'ACTIVE' | translate}}
                </mat-slide-toggle>
            </div>
            <div fxFlex="nogrow" fxFlex.gt-sm="nogrow">
                <button mat-button color="" aria-label="{{'DELETE' | translate}}" #tooltip="matTooltip"
                    matTooltip="Delete" (click)="onDeleteClick(i)" class="mdc-mb-3">
                    <mat-icon>delete</mat-icon> <span>{{'DELETE' | translate}}</span>
                </button>
            </div>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start ">
        <button mat-raised-button color="accent" class="mr-1" (click)="onAddClick()">{{'ADD' | translate}}</button>
        <button mat-raised-button color="accent" (click)="onSaveClick()">{{'SAVE' | translate}}</button>
    </div>
</form>