import { Component, ElementRef, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatStepper } from '@angular/material/stepper';
import {
  Appointment,
  AppointmentPaymentConfig,
  AppointmentService,
  AppointmentServiceRow,
  CreateAppointment,
  PetServicesRequired,
  UpdateAppointment
} from '@savvy/appointment';
import { ListEntityListItemDto } from '@savvy/cached-view/model/listEntityListItemDto';
import { LinkId } from '@savvy/entity-instance';
import { ContextIdDto } from '@savvy/entity-instance-composite';
import { Location } from '@savvy/location';
import { Pet } from '@savvy/pet';
import { PlansCompService, SubscribeToPlan } from '@savvy/plan';
import { UserId } from '@savvy/user';
import { Clash, ClashCheckResponse } from '@savvy/user-availability';
import { StepPref, WizardPrefs, WizardPrefsService } from '@savvy/wizard-prefs';
import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxSpinnerService } from 'ngx-spinner';
import * as Quill from 'quill';
import { Subscription } from 'rxjs';
import { DataShareService } from 'src/app/core/data-share.service';
import { ContextService } from '../../context.service';
import { DateUtils } from '../../dates/DateUtils';
import { PetCheckInFormSelectorComponent } from '../../element/create-pet-appointment/petCheckInFormSelector.component';
import { PetServiceSelectorComponent } from '../../element/create-pet-appointment/petServiceSelector.component';
import { AppointmentConfigHelperService } from '../../shared/services/appointment-config-helper.service';
import { LocationsHelperService } from '../../shared/services/locations-helper.service';
import { ServicesHelperService } from '../../shared/services/services-helper.service';
import { SmsCreditService } from '../../shared/services/sms-credit.service';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';
import { AppointmentSelectorV2Component } from '../shared/appointment-selector-v2/appointment-selector-v2.component';
import { AppointmentV2IconsComponent } from '../shared/appointment-v2-icons/appointment-v2-icons.component';
import { PaymentComponent } from '../shared/payment/payment.component';
import { ValidateAppointmentV2Service } from '../validate-appointment-v2.service';
import { ConfirmPetAppointmentV2Component } from './confirm-pet-appointment-v2/confirm-pet-appointment-v2.component';
import { CreatePetAppointmentV2SyncService } from './create-pet-appointment-v2-sync.service';
import { PetSelectorV2Component } from './pet-selector-v2/pet-selector-v2.component';
import { PickupServiceComponent } from './pickup-service/pickup-service.component';
import { RepeatAppointmentsComponent } from './repeat-appointments/repeat-appointments.component';
import { AppointmentDateLocal } from './repeat-appointments/repeat-filter/repeat-filter.component';
@Component({
  selector: 'app-create-pet-appointment-v2',
  templateUrl: './create-pet-appointment-v2.component.html',
  styleUrls: ['./create-pet-appointment-v2.component.scss']
})
export class CreatePetAppointmentV2Component implements OnInit, OnDestroy {

  @ViewChild(PetServiceSelectorComponent, { static: false }) petServiceSelectorComponent: PetServiceSelectorComponent;
  @ViewChild(PetCheckInFormSelectorComponent, { static: false }) checkInFormSelectorComponent: PetCheckInFormSelectorComponent;
  @ViewChild(AppointmentSelectorV2Component, { static: false }) appointmentSelectorV2Component: AppointmentSelectorV2Component;
  @ViewChild(ConfirmPetAppointmentV2Component, { static: false }) confirmAppointmentV2Component: ConfirmPetAppointmentV2Component;
  @ViewChild(PickupServiceComponent, { static: false }) pickupServiceComponent: PickupServiceComponent;
  @ViewChild(AppointmentV2IconsComponent, { static: false }) appointmentV2IconsComponent: AppointmentV2IconsComponent;
  @ViewChild(PaymentComponent, { static: false }) paymentComponent: PaymentComponent;
  @ViewChild(RepeatAppointmentsComponent, { static: false }) repeatAppointmentComponent: RepeatAppointmentsComponent;
  @ViewChild(PetSelectorV2Component, { static: false }) petSelectorV2Component: PetSelectorV2Component;


  @ViewChild('messageEditor', { read: ElementRef }) messageEditorEl: ElementRef;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild(MatStepper) stepper: MatStepper;

  quickAppointment = false;

  editor: Quill;
  selectedPets = [];
  contextIdDto: ContextIdDto;

  collectionTime = '';
  messageBody = '';
  emailBody = '';
  links: any[] = [];

  /* stepStates = {
     normal: STEP_STATE.normal,
     disabled: STEP_STATE.disabled,
     error: STEP_STATE.error,
     hidden: STEP_STATE.hidden
   };*/

  // isValidTypeBoolean = true;
  // petEdId = '';

  customerMode: 'existingCustomer' | 'newCustomer' = 'existingCustomer';
  createPet = false;
  customerId: string;

  serviceNumber;

  creating = false;

  //lastStep = '';

  wizardPrefs: WizardPrefs;

  //  locationFreeText = '';
  notes = '';
  blades = '';
  enquiryDate: Date = new Date();

  wizardSteps: StepPref[] = [];

  // advancedStepVisible = false;
  //smsWorkflowDef: SendSmsDefinition;
  smsMessageName = '';
  // emailWorkflowDef: SendMessageDefinition;
  emailMessageName = '';
  // defaultMessage = 'You need to setup a Confirm message';

  totalAppointmentDuration = 60;
  subs: Subscription[] = [];

  appointment: Appointment = {
    paymentConfig: {} as AppointmentPaymentConfig,
  } as Appointment;

  selectedTabIndex = 0;

  showConfirmationDialog = false;
  checkConflictsDialog = false;
  clashes: Array<Clash> = [];
  bypassConflictCheck = false;
  operationToPerform = '';
  createAnywayApplied = false;
  isRepeatAvailable = false;
  createNewPlan = false;

  paymentModeEnum = AppointmentPaymentConfig.PaymentModeEnum;
  selectedDay = 'SUNDAY';

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    @Optional() public dialogRef: MatDialogRef<CreatePetAppointmentV2Component>,
    private wizardPrefsService: WizardPrefsService,
    private appointmentService: AppointmentService,
    private notify: FloSnackbarComponent,
    private spinner: NgxSpinnerService,
    private dateUtils: DateUtils,
    public dataShareService: DataShareService,
    public createPetAppointmentV2SyncService: CreatePetAppointmentV2SyncService,
    public appointmentConfigHelperService: AppointmentConfigHelperService,
    public deviceService: DeviceDetectorService,
    private validateAppointmentV2Service: ValidateAppointmentV2Service,
    public planCompApi: PlansCompService,
    public servicesHelperService: ServicesHelperService,
    private smsCreditService: SmsCreditService,
    private locationsHelperService: LocationsHelperService,
    public contextService: ContextService,

  ) {
    this.contextIdDto = this.data.contextIdDto;
    this.createPetAppointmentV2SyncService.multiLocationEnabled = data?.calendarSettings?.multiLocationEnabled;
  }

  ngOnDestroy(): void {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.customerMode = 'existingCustomer';
    this.fullScreen();

    console.log('INSIDE INIT!');
    this.createPetAppointmentV2SyncService.setTimeArray(this.contextIdDto);
    // this.servicesHelperService.getConsumerServices(this.contextIdDto);

    if (this.data.edit || this.data.rebook) {
      console.log('EDIT OR REBOOK');
      // Handle edit flow
      this.appointment = this.data.appointment;
      this.notes = this.appointment?.notes;
      this.blades = this.appointment?.scissors;
      if (this.appointment?.enquiryDate) {
        this.enquiryDate = new Date(this.appointment.enquiryDate);
      } else {
        this.enquiryDate = new Date(this.appointment.dateCreated);
      }
      this.createPetAppointmentV2SyncService.editMode = true;
      this.createPetAppointmentV2SyncService.populateAppointment(this.appointment, this.contextIdDto, this.data.rebook);
    } else {
      console.log('HERE');
      if (this.data.startDateToUseOnCreate) {
        const startDate = this.data.startDateToUseOnCreate ? new Date(this.data.startDateToUseOnCreate) : new Date();
        this.selectedDay = moment(startDate).format('dddd').toUpperCase();
        const startTime = this.data.startDateToUseOnCreate ? this.dateUtils.getTimeAsString(this.data.startDateToUseOnCreate) : '';
        const endTime = this.dateUtils.addMinsToTimeString(startTime, 60);
        this.createPetAppointmentV2SyncService.setAppointmentDates(startDate, startDate, startTime, endTime);
      } else {
        const startDate = new Date();
        this.selectedDay = moment(startDate).format('dddd').toUpperCase();
        const startTimeTemp = this.dateUtils.getTimeAsString(new Date());
        let startTime = startTimeTemp;
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let tIndex = 0; tIndex < this.createPetAppointmentV2SyncService.timeArray.length; tIndex++) {
          const t = this.createPetAppointmentV2SyncService.timeArray[tIndex];
          if (startTime <= t.actualValue) {
            startTime = t.actualValue;
            break;
          }
        }
        const endTime = this.dateUtils.addMinsToTimeString(startTime, 60);
        this.createPetAppointmentV2SyncService.setAppointmentDates(startDate, startDate, startTime, endTime);
      }

      if (this.data.userToUseWhenCreating) {
        this.createPetAppointmentV2SyncService.userToUseWhenCreating = this.data.userToUseWhenCreating;
      }

      if (this.data.populatePet) {
        setTimeout(() => {
          this.createPetAppointmentV2SyncService.populateNewPet(this.data.populatePet);
        }, 500);
      }
    }

    this.subs.push(this.createPetAppointmentV2SyncService.createPet$.subscribe((result) => {
      if (result) {
        this.fullScreen();
        this.createPet = true;
      }
    }));

    // if (this.appointmentConfigHelperService.appointmentConfig.confirmEnabled) {
    //   this.appointmentStatus = 'confirm';
    // } else {
    //   this.appointmentStatus = 'tentative';
    // }

  }

  addNewCustomerEvent($event) {
    this.createPet = false;
    this.customerMode = 'newCustomer';
  }

  loadWizard() {
    this.wizardPrefsService.get('createAppointment').subscribe(response => {
      this.wizardSteps = response.stepPrefs.filter(step => !step.hide);
      const ackIndex = this.wizardSteps.findIndex(step => step.stepName === 'Acknowledgement');
      if (ackIndex > -1) {
        this.wizardSteps.splice(ackIndex, 1);
      }
    });
  }

  locationSelected($event) {

  }

  createStepPref(stepName: string) {
    const stepPref = {} as StepPref;
    stepPref.stepName = stepName;
    stepPref.hide = true;
    return stepPref;
  }

  getMinTime(requiredServices: PetServicesRequired[]) {
    let min = null;
    for (const requiredService of requiredServices) {
      for (const iterator of requiredService.petServicesRequired) {
        if (!min || iterator.start < min) {
          min = iterator.start;
        }
      }
    }
    return min;

  }

  getMaxTime(requiredServices: PetServicesRequired[]) {
    let max = null;
    for (const requiredService of requiredServices) {
      for (const iterator of requiredService.petServicesRequired) {
        if (!max || iterator.end > max) {
          max = iterator.end;
        }
      }
    }
    return max;

  }


  isDuplicateSlotSelectedForSameGroomer(startTime: string, endTime: string, userId: string, petIndex: number, serviceIndex: number): boolean {
    if (this.createPetAppointmentV2SyncService.petsSelected?.length > 1) {
      for (const [petSelectedIndex, petSelected] of this.createPetAppointmentV2SyncService.petsSelected.entries()) {
        if (petSelectedIndex !== petIndex) {
          for (const serviceSelected of this.createPetAppointmentV2SyncService.selectedServices[petSelected.petId]) {
            if (serviceSelected.selectedStaff.id === userId) {
              return serviceSelected.startTime === startTime && serviceSelected.endTime === endTime;
            }
          }
        }
      }
    }
    return false;
  }

  isDuplicateSlotSelectedForSameGroomerForSamePet(startTime: string, endTime: string, userId: string, petId: string, serviceIndex: number): boolean {
    if (this.createPetAppointmentV2SyncService.selectedServices[petId]?.length > 1) {
      for (const [serviceSelectedIndex, serviceSelected] of this.createPetAppointmentV2SyncService.selectedServices[petId].entries()) {
        if (serviceSelectedIndex !== serviceIndex && serviceSelected.selectedStaff.id === userId) {
          return serviceSelected.startTime === startTime && serviceSelected.endTime === endTime;
        }
      }
    }
    return false;
  }

  validateRequest() {
    if (!this.createPetAppointmentV2SyncService.petsSelected || !this.createPetAppointmentV2SyncService.petsSelected.length) {
      this.notify.message = 'Please select a pet';
      this.notify.open();
      return false;
    }
    if (!Object.keys(this.createPetAppointmentV2SyncService.selectedServices).length) {
      this.notify.message = 'Please select a service';
      this.notify.open();
      return false;
    }
    if (this.createPetAppointmentV2SyncService.petsSelected && this.createPetAppointmentV2SyncService.petsSelected.length) {
      for (const [petIndex, petSelected] of this.createPetAppointmentV2SyncService.petsSelected.entries()) {
        for (const [serviceIndex, service] of this.createPetAppointmentV2SyncService.selectedServices[petSelected.petId].entries()) {
          if (!service.selectedService || service.selectedService.deleted || !Object.keys(service.selectedService).length) {
            this.notify.message = `A service should be selected in each row`;
            this.notify.open();
            return false;
          }
          if (!service.selectedStaff.id) {
            this.notify.message = `A staff member should be allocated to each service`;
            this.notify.open();
            return false;
          }
          if (!service.subTotal) {
            service.subTotal = 0;
          }
          if (!service.quantity) {
            service.quantity = 1;
          }
          if (!service.grossPrice) {
            service.grossPrice = 0;
          }
          if (!service.startTime) {
            this.notify.message = 'Start time is required';
            this.notify.open();
            return false;
          }
          if (!service.endTime) {
            this.notify.message = 'End time is required';
            this.notify.open();
            return false;
          }
          if (service.endTime < service.startTime) {
            this.notify.message = 'End time should be greater than start time';
            this.notify.open();
            return false;
          }
          // if (this.isDuplicateSlotSelectedForSameGroomerForSamePet(service.startTime, service.endTime, service.selectedStaff.id, petSelected.petId, serviceIndex)) {
          //   this.notify.message = `You can't have duplicate slots for same pet ${petSelected.petName} and same groomer ${service.selectedStaff.firstName} ${service.selectedStaff.lastName}`;
          //   this.notify.open();
          //   return false;
          // }
          // if (this.isDuplicateSlotSelectedForSameGroomer(service.startTime, service.endTime, service.selectedStaff.id, petIndex, serviceIndex)) {
          //   this.notify.message = `You can't have duplicate slots for same groomer ${service.selectedStaff.firstName} ${service.selectedStaff.lastName}`;
          //   this.notify.open();
          //   return false;
          // }
        }
      }
    }
    if (!this.createPetAppointmentV2SyncService.startDateReq
      || !this.createPetAppointmentV2SyncService.startTime
      || !this.createPetAppointmentV2SyncService.endTime) {
      this.notify.message = 'Invalid date/time selection';
      this.notify.open();
      return false;
    }
    if (this.pickupServiceComponent && this.pickupServiceComponent.pickupRequired) {
      if (!this.pickupServiceComponent.collectionTime) {
        this.notify.message = 'Collection time is required';
        this.notify.open();
        return false;
      }
      // if (!this.pickupServiceComponent.pickupAddress) {
      //   this.notify.message = 'Pickup address is required';
      //   this.notify.open();
      //   return false;
      // }
    }
    return true;
  }

  prepareCreateRequestPayload(confirm = false, appointmentDate: AppointmentDateLocal, planId?: any): CreateAppointment | null {
    let customerId = '';
    let customerName = '';

    if (this.createPetAppointmentV2SyncService.petsSelected.length) {
      customerId = this.createPetAppointmentV2SyncService.petsSelected[0].petOwnerId || '';
      customerName = this.createPetAppointmentV2SyncService.petsSelected[0].customerName || '';
    }
    if (!customerId) {
      this.notify.message = 'No pet owner id specified for this pet';
      this.notify.open();
      return null;
    }
    const createComp = {} as CreateAppointment;
    createComp.contextIdDto = this.contextIdDto;
    createComp.confirm = confirm;
    if (confirm) {
      this.operationToPerform = 'createAndConfirm';
    } else {
      this.operationToPerform = 'create';
    }
    let selectedLocation;
    debugger
    if (this.data.rebook && this.appointment?.locationId) {
      selectedLocation = this.locationsHelperService.nonDeletedLocations.find(l=> l?.id === this.appointment?.locationId);
    } else if (this.createPetAppointmentV2SyncService.selectedLocation) {
      selectedLocation = this.createPetAppointmentV2SyncService.selectedLocation;
    } else {
      selectedLocation = this.locationsHelperService.nonDeletedLocations[0];
    }

    const appointmentServiceRows: AppointmentServiceRow[] = [];
    let startDateReq = this.createPetAppointmentV2SyncService.startDateReq;
    let endDateReq = this.createPetAppointmentV2SyncService.endDateReq;
    if (this.isRepeatAvailable && appointmentDate) {
      startDateReq = this.dateUtils.getDateAsStringDash(appointmentDate?.localStartDate);
      endDateReq = this.dateUtils.getDateAsStringDash(appointmentDate?.localStartDate);
    }
    for (const petSelected of this.createPetAppointmentV2SyncService.petsSelected) {
      if (petSelected.petId) {
        for (const service of this.createPetAppointmentV2SyncService.selectedServices[petSelected.petId]) {
          let startTimeFinal = service.startTime ? service.startTime : this.createPetAppointmentV2SyncService.startTime;
          let endTimeFinal = service.endTime ? service.endTime : this.createPetAppointmentV2SyncService.endTime;
          if (this.isRepeatAvailable && appointmentDate && service.startTime != appointmentDate.startTime) {
            if (appointmentDate.differenceInHours) {
              // event is dragged
              const duration = moment.duration(moment(endTimeFinal, 'HH:mm').diff(moment(startTimeFinal, 'HH:mm')));
              const hours = duration.asHours();
              if (service?.startTime) {
                startTimeFinal = this.dateUtils.addHoursToTime(service.startTime, appointmentDate.differenceInHours);
              }
              endTimeFinal = moment(startTimeFinal, 'HH:mm').add(hours, 'hours').format('HH:mm');
            }
            // else {
            //   // start time in repeat is different then actually selected in services
            //   const differenceInHours: number = moment.duration(moment(appointmentDate.startTime, 'HH:mm').diff(moment(service.startTime, 'HH:mm'))).asHours();
            //   startTimeFinal = this.dateUtils.addHoursToTime(service?.startTime, differenceInHours);
            //   endTimeFinal = this.dateUtils.addHoursToTime(service?.endTime, differenceInHours);
            // }
          }

          appointmentServiceRows.push({
            serviceUser: {
              id: service?.selectedStaff?.id,
              type: 'USER_ID',
              userContextType: UserId.UserContextTypeEnum.UserId
            },
            serviceUserFullName: service?.selectedStaff?.name || '',
            eiId: petSelected.petId,
            eiLabel: petSelected.petName,
            eiType: petSelected.petBreed,
            startDate: startDateReq,
            startTime: startTimeFinal,
            endDate: endDateReq,
            endTime: endTimeFinal,
            serviceNumber: service?.selectedService?.id || '',
            serviceName: service?.selectedService?.serviceName,
            quantity: service.quantity,
            unitPrice: service?.selectedService?.unitPrice || 0,
            tax: service?.selectedService?.tax,
            subTotal: service?.subTotal || 0,
            grossPrice: service?.grossPrice || 0,
            discountDetails: service.discount,
            // discountId ?: string;
          });
        }
      }
    }
    let startTime = this.createPetAppointmentV2SyncService.startTime;
    let endTime = this.createPetAppointmentV2SyncService.endTime;
    if (this.isRepeatAvailable && appointmentDate) {
      const duration = moment.duration(moment(endTime, 'HH:mm').diff(moment(startTime, 'HH:mm')));
      const hours = duration.asHours();
      startTime = appointmentDate.startTime;
      endTime = moment(startTime, 'HH:mm').add(hours, 'hours').format('HH:mm');
    }
    const paymentConf = this.paymentComponent ? this.paymentComponent.getPaymentConfig(confirm) : null;

    if (planId) {
      paymentConf.paymentPlanId = planId;
    }

    console.log('payment conf');
    console.log(paymentConf);
    createComp.appointment = {
      appointmentDefinitionId: {},
      dateCreated: this.dateUtils.getDateTimeAsIsoString(new Date(), true),
      startDateUtc:
        // eslint-disable-next-line max-len
        this.dateUtils.getDateTimeAsIsoString(new Date(`${startDateReq}T${startTime}`), true),
      endDateUtc:
        // eslint-disable-next-line max-len
        this.dateUtils.getDateTimeAsIsoString(new Date(`${endDateReq}T${endTime}`), true),
      startDate: startDateReq,
      startTime,
      endDate: endDateReq,
      endTime,
      appointmentTimeStrategy: this.getAppointmentTimeStrategy(),
      linkedIds: [],
      locationId: selectedLocation ? selectedLocation.id : '',
      locationName: selectedLocation ? selectedLocation.name : '',
      locationFreeText: selectedLocation ? selectedLocation.locationName : '',
      entityInstanceId: '',
      // cancelled?: boolean;
      customerId,
      customerName,
      // workflowDefinitionId?: string;
      // workflowInstanceId?: string;
      // status?: string;
      // endState?: boolean;
      // stateColour?: string;
      // extra?: {
      //   [key: string]: object;
      // };
      scissors: this.blades,
      notes: this.notes,
      icons: this.appointmentV2IconsComponent ? this.appointmentV2IconsComponent.selectedIcons : [],
      appointmentServiceRows,
      ownerId: this.contextIdDto.contextId,
      pickupRequired: this.pickupServiceComponent ? this.pickupServiceComponent.pickupRequired : false,
      collectionTime: this.pickupServiceComponent && this.pickupServiceComponent.pickupRequired
        ? moment(this.pickupServiceComponent.collectionTime).format('HH:mm') : '',
      pickupAddress: this.pickupServiceComponent && this.pickupServiceComponent.pickupRequired
        ? this.pickupServiceComponent.pickupAddress : '',
      paymentConfig: paymentConf,
      appointmentSource: Appointment.AppointmentSourceEnum.WebCrm,
      enquiryDate: this.enquiryDate ? this.dateUtils.getDateAsStringDash(this.enquiryDate) : ''
    };
    return createComp;
  }

  checkConflictApi(appointmentDates: AppointmentDateLocal | AppointmentDateLocal[]) {
    if (Array?.isArray(appointmentDates)) {
      let executed = 0;
      const repeatConflictsPromises: Promise<any>[] = [];
      for (const appointmentDate of appointmentDates) {
        const createComp: CreateAppointment = this.prepareCreateRequestPayload(true, appointmentDate);
        repeatConflictsPromises.push(this.validateAppointmentV2Service.checkConflictsWithReason(this.contextIdDto, createComp.appointment,
          this.createPetAppointmentV2SyncService.selectedLocation.id)
          .then((clashCheckResponse: ClashCheckResponse) => {
            if (clashCheckResponse && clashCheckResponse.clashes.length) {
              appointmentDate.conflict = true;
              appointmentDate.clashStr = clashCheckResponse.clashStr;
            } else {
              appointmentDate.conflict = false;
            }
            executed += 1;
          }));

      }
      Promise.all(repeatConflictsPromises).then(res => {
        if (executed === appointmentDates?.length) {
          this.repeatAppointmentComponent?.notifyConflict(appointmentDates);
        }
      });
    } else {

    }
  }

  updateStartDateAndEndDate(appointmentDate) {
    if (appointmentDate && appointmentDate[0] && appointmentDate[0].startTime) {
      this.createPetAppointmentV2SyncService.updateStartTimeAndEndTimeByStartTime(appointmentDate[0].startTime);
    }
  }

  getAppointmentTimeStrategy() {
    // if (this.quickAppointment) {
    //   return Appointment.AppointmentTimeStrategyEnum.AppointmentTimes;
    // } else
    // if (this.createPetAppointmentV2SyncService.appointmentConfig.appointmentConfig.customizedTimeForServices) {
    return Appointment.AppointmentTimeStrategyEnum.ServiceTimes;
    // } else {
    //   return Appointment.AppointmentTimeStrategyEnum.AppointmentTimes;
    // }
  }

  createAppointmentApi(toast = true, confirm?: boolean, appointmentDate?: AppointmentDateLocal, planId?: any) {
    return new Promise<Appointment>((resolve) => {
      if (!this.validateRequest()) {
        return;
      }
      if (this.clashes.length && !this.createAnywayApplied) {
        this.createAnywayApplied = true;
      }
      const createComp: CreateAppointment = this.prepareCreateRequestPayload(confirm, appointmentDate, planId);
      this.validateAppointmentV2Service.checkConflicts(this.contextIdDto, createComp.appointment,
        this.createPetAppointmentV2SyncService.selectedLocation?.id).then((clashes: Array<Clash>) => {
          const overrideConflictCheck = appointmentDate && appointmentDate.overrideConflictCheck || false;
          if (this.appointmentConfigHelperService.appointmentConfig.conflictCheckEnabled
            && clashes.length && !this.createAnywayApplied
            && !overrideConflictCheck) {
            this.clashes = clashes;
            this.checkConflictsDialog = true;
            resolve(null);
            return;
          }
          this.spinner.show();
          this.appointmentService.createAppointment(createComp).subscribe(response => {
            this.spinner.hide();
            if (response) {
              if (toast) {
                this.notify.message = 'Appointment created successfully';
                this.notify.open();
                this.createAnywayApplied = false;
                this.dialogRef.close(response);
              }
              resolve(response);
            }
          });
        });
    });
  }

  createAppointment(toast = true, confirm?: boolean) {
    this.smsCreditService.checkSMSCredits(this.contextIdDto);
    if (this.createNewPlan) {
      this.createPaymentPlanThenCreateAppointment(toast, confirm);
    } else {
      if (this.isRepeatAvailable) {
        const appointmentDates = this.repeatAppointmentComponent.getAppointmentDates();
        if (appointmentDates?.length) {
          appointmentDates.forEach(appointmentDate => {
            // const smallestTime = this.createPetAppointmentV2SyncService.getEarliestServiceTime();
            // for (const petSelected of this.createPetAppointmentV2SyncService.petsSelected) {
            //   for (let service of this.createPetAppointmentV2SyncService.selectedServices[petSelected.petId]) {
            //     if (appointmentDate) {
            //       service = this.createPetAppointmentV2SyncService
            //         .syncServiceStartAndEndTimeForRepeatApp(smallestTime, appointmentDate, service);
            //     }
            //   }
            // }
            this.createAppointmentApi(toast, confirm, appointmentDate);
          });
        }
      } else {
        this.createAppointmentApi(toast, confirm).then(res => {
          if (res) {
            //
          }
        });
      }
    }

  }

  createPaymentPlanThenCreateAppointment(toast: boolean, confirm: boolean) {
    if (this.createPetAppointmentV2SyncService.petsSelected.length) {
      const customerId = this.createPetAppointmentV2SyncService.petsSelected[0].petOwnerId;
      let planDefId;
      if (this.paymentComponent.getSelectedPlanDefinition()) {
        planDefId = this.paymentComponent.getSelectedPlanDefinition().id;
      }
      if (customerId && planDefId) {
        const subscribeToPlanReq: SubscribeToPlan = {};
        subscribeToPlanReq.contextIdDto = this.contextIdDto;
        subscribeToPlanReq.customerId = customerId;
        subscribeToPlanReq.planDefinitionId = planDefId;
        subscribeToPlanReq.dayOfPayment = 1;

        this.planCompApi.subscribe(subscribeToPlanReq).subscribe(res => {
          if (this.isRepeatAvailable) {
            const appointmentDates = this.repeatAppointmentComponent?.getAppointmentDates();
            appointmentDates?.forEach(appointmentDate => {
              for (const petSelected of this.createPetAppointmentV2SyncService.petsSelected) {
                const dates = this.createPetAppointmentV2SyncService.selectedServices[petSelected.petId]
                  .map(s => moment(`${moment(appointmentDate.localStartDate).format('YYYY-MM-DD')} ${moment(s.startTime, 'HH:mm')}`));
                const smallestTime = moment.min(dates);
                for (let service of this.createPetAppointmentV2SyncService.selectedServices[petSelected.petId]) {
                  if (this.isRepeatAvailable && appointmentDate) {
                    service = this.createPetAppointmentV2SyncService
                      .syncServiceStartAndEndTimeForRepeatApp(smallestTime.format('HH:mm'), appointmentDate, service);
                  }
                }
              }
              this.createAppointmentApi(toast, confirm, appointmentDate, res.plan.id);
            });
          } else {
            this.createAppointmentApi(toast, confirm, null, res.plan.id).then(appt => {
              if (appt) {
                //
              }
            });
          }
        });
      }
    }
  }

  prepareUpdateRequestPayload(confirm = false): UpdateAppointment {
    const updateAppointment = {} as UpdateAppointment;
    updateAppointment.contextIdDto = this.contextIdDto;
    updateAppointment.confirm = confirm;
    if (confirm) {
      this.operationToPerform = 'updateAndConfirm';
    } else {
      this.operationToPerform = 'update';
    }
    let customerId = '';
    let customerName = '';

    if (this.createPetAppointmentV2SyncService.petsSelected.length) {
      customerId = this.createPetAppointmentV2SyncService.petsSelected[0].petOwnerId;
      // eslint-disable-next-line max-len
      customerName = this.createPetAppointmentV2SyncService.petsSelected[0].customerName;
    }
    if (!customerId) {
      this.notify.message = 'No pet owner id specified for this pet';
      this.notify.open();
      return;
    }
    let selectedLocation;
    if (this.appointment?.locationId) {
      selectedLocation = this.locationsHelperService.nonDeletedLocations.find(l=> l?.id === this.appointment?.locationId);
    } else {
      selectedLocation = this.locationsHelperService.nonDeletedLocations[0];
    }

    const appointmentServiceRows: AppointmentServiceRow[] = [];

    for (const petSelected of this.createPetAppointmentV2SyncService.petsSelected) {
      for (const service of this.createPetAppointmentV2SyncService.selectedServices[petSelected.petId]) {
        const startTime = service.startTime ? service.startTime : this.createPetAppointmentV2SyncService.startTime;
        const endTime = service.endTime ? service.endTime : this.createPetAppointmentV2SyncService.endTime;
        appointmentServiceRows.push({
          serviceUser: {
            id: service.selectedStaff.id,
            type: 'USER_ID',
            userContextType: UserId.UserContextTypeEnum.UserId
          },
          serviceUserFullName: service.selectedStaff?.name,
          eiId: petSelected.petId,
          eiLabel: petSelected.petName,
          eiType: petSelected.petBreed,
          startDate: this.createPetAppointmentV2SyncService.startDateReq,
          startTime,
          endDate: this.createPetAppointmentV2SyncService.endDateReq,
          endTime,
          serviceNumber: service.selectedService.id,
          serviceName: service.selectedService?.serviceName,
          quantity: service.quantity,
          unitPrice: service?.selectedService?.unitPrice || 0,
          tax: service.selectedService.tax,
          subTotal: service?.subTotal || 0,
          grossPrice: service?.grossPrice || 0,
          discountDetails: service.discount,
          // discountId ?: string;
        });
      }
    }
    updateAppointment.appointment = {
      ...this.appointment,
      id: this.appointment.id,
      appointmentDefinitionId: this.appointment.appointmentDefinitionId,
      startDateUtc:
        // eslint-disable-next-line max-len
        this.dateUtils.getDateTimeAsIsoString(new Date(`${this.createPetAppointmentV2SyncService.startDateReq}T${this.createPetAppointmentV2SyncService.startTime}`), true),
      endDateUtc:
        // eslint-disable-next-line max-len
        this.dateUtils.getDateTimeAsIsoString(new Date(`${this.createPetAppointmentV2SyncService.endDateReq}T${this.createPetAppointmentV2SyncService.endTime}`), true),
      startDate: this.createPetAppointmentV2SyncService.startDateReq,
      startTime: this.createPetAppointmentV2SyncService.startTime,
      endDate: this.createPetAppointmentV2SyncService.endDateReq,
      endTime: this.createPetAppointmentV2SyncService.endTime,
      linkedIds: this.appointment.linkedIds,
      locationId: selectedLocation ? selectedLocation.id : '',
      locationName: selectedLocation ? selectedLocation.name : '',
      locationFreeText: selectedLocation ? selectedLocation.locationName : '',
      entityInstanceId: '',
      cancelled: this.appointment.cancelled,
      customerId,
      customerName,
      workflowDefinitionId: this.appointment.workflowDefinitionId,
      workflowInstanceId: this.appointment.workflowInstanceId,
      status: this.appointment.status,
      endState: this.appointment.endState,
      stateColour: this.appointment.stateColour,
      extra: this.appointment.extra,
      scissors: this.blades,
      notes: this.notes,
      icons: this.appointmentV2IconsComponent ? this.appointmentV2IconsComponent.selectedIcons : [],

      appointmentServiceRows,
      ownerId: this.contextIdDto.contextId,
      pickupRequired: this.pickupServiceComponent ? this.pickupServiceComponent.pickupRequired : false,
      collectionTime: this.pickupServiceComponent && this.pickupServiceComponent.pickupRequired
        ? moment(this.pickupServiceComponent.collectionTime).format('HH:mm') : '',
      pickupAddress: this.pickupServiceComponent && this.pickupServiceComponent.pickupRequired
        ? this.pickupServiceComponent.pickupAddress : '',
      paymentConfig: this.paymentComponent ? this.paymentComponent.getPaymentConfig(confirm) : null,
      appointmentSource: Appointment.AppointmentSourceEnum.WebCrm,
      appointmentTimeStrategy: this.getAppointmentTimeStrategy(),
      enquiryDate: this.enquiryDate ? this.dateUtils.getDateAsStringDash(this.enquiryDate) : ''
    };
    return updateAppointment;
  }

  updateAppointment(confirm = false) {
    this.smsCreditService.checkSMSCredits(this.contextIdDto);
    if (!this.validateRequest()) {
      return;
    }
    if (this.clashes.length && !this.createAnywayApplied) {
      this.createAnywayApplied = true;
    }
    const updateAppointment: UpdateAppointment = this.prepareUpdateRequestPayload(confirm);
    this.validateAppointmentV2Service.checkConflicts(this.contextIdDto, updateAppointment.appointment,
      this.createPetAppointmentV2SyncService.selectedLocation?.id).then((clashes: Array<Clash>) => {
        if (this.appointmentConfigHelperService.appointmentConfig.conflictCheckEnabled && clashes.length && !this.createAnywayApplied) {
          this.clashes = clashes;
          this.checkConflictsDialog = true;
          return;
        }
        this.spinner.show();
        this.appointmentService.updateAppointment(updateAppointment).subscribe(response => {
          this.spinner.hide();
          this.notify.message = 'Appointment updated successfully';
          this.notify.open();
          this.createPetAppointmentV2SyncService.editMode = false;
          this.createPetAppointmentV2SyncService.clear();
          this.dialogRef.close(response);
        });
      });
  }

  confirmAppointment() {
    this.createAppointment(true, true);
  }

  updateAndConfirmAppointment() {
    this.updateAppointment(true);
  }

  petCreated(event: Pet) {
    this.createPet = false;
    this.createPetAppointmentV2SyncService.populateNewPet(event);
  }

  getLinkId(row: ListEntityListItemDto) {
    const linkId = {} as LinkId;
    linkId.linkedId = row.entityInstanceId.id;
    linkId.linkedIdType = 'ENTITY_INSTANCE';
    return linkId;
  }

  isHidden(stepName: string) {
    if (this.wizardPrefs) {
      this.wizardPrefs.stepPrefs.forEach(
        step => {
          if (step.stepName === stepName) {
            return step.hide;
          }
        }
      );
    }
    return false;
  }

  petSelected($event) {
    this.selectedPets = $event || [];
    this.petServiceSelectorComponent.selectedServices = {};
    for (const pet of this.selectedPets) {
      this.petServiceSelectorComponent.addServiceNew(pet);
    }
  }

  cancel() {
    if (this.data.fromView) {
      this.dialogRef.close({
        fromView: true,
        appointmentId: this.appointment.id,
      });
    } else {
      this.dialogRef.close();
    }
  }

  fullScreen() {
    this.quickAppointment = false;
    this.dialogRef.updateSize('1100px', 'auto');
  }

  exitFullScreen() {
    this.quickAppointment = true;
    this.dialogRef.updateSize(null, 'auto');
  }

  stepChanged(event) {

    this.selectedTabIndex = event.selectedIndex;
    if (event.selectedIndex === 1) {
      // payment step
      this.createPetAppointmentV2SyncService.calculateTotalAmount();
    }
  }

  amountDueChanged(amountDue: number) {
    this.createPetAppointmentV2SyncService.totalAmountDue = amountDue;
  }

  createPlanChanged(willCreatePlan: boolean) {
    this.createNewPlan = willCreatePlan;
  }

  closeConfirmDialog() {
    this.showConfirmationDialog = false;
    // this.appointmentStatus = 'tentative';
  }

  updateConfirmEnabled(event) {
    this.appointmentConfigHelperService.appointmentConfig.confirmEnabled = !event;
    this.appointmentConfigHelperService.updateAppointmentConfig(this.contextIdDto, this.appointmentConfigHelperService.appointmentConfig);
  }

  updateConflictCheck(event) {
    this.appointmentConfigHelperService.appointmentConfig.conflictCheckEnabled = !event;
    this.appointmentConfigHelperService.updateAppointmentConfig(this.contextIdDto, this.appointmentConfigHelperService.appointmentConfig);
  }

  createAndConfirm() {
    if (this.appointmentConfigHelperService.appointmentConfig.confirmEnabled
      && !this.createAnywayApplied) {
      this.showConfirmationDialog = true;
    } else {
      this.confirmAppointment();
    }
  }

  customerCreated(event: Pet) {
    this.createPetAppointmentV2SyncService.populateNewCustomer(event);
  }

  customerModeChange(newMode: string) {
    if (newMode === 'newCustomer') {
      this.createPetAppointmentV2SyncService.petsSelected = [];
      this.createPetAppointmentV2SyncService.petsSelectedChips = [];
      this.createPetAppointmentV2SyncService.updatePetSelection([]);
      this.createPetAppointmentV2SyncService.selectedServices = {};
    } else {
      //
    }
  }

  createAnyway() {
    if (this.operationToPerform) {
      this.createAnywayApplied = true;
      switch (this.operationToPerform) {
        case 'create':
          this.createAppointment();
          break;
        case 'createAndConfirm':
          this.createAndConfirm();
          break;
        case 'update':
          this.updateAppointment();
          break;
        case 'updateAndConfirm':
          this.updateAndConfirmAppointment();
          break;
      }
    }
  }

  updateCustFlags() {
    if (this.petSelectorV2Component && this.petSelectorV2Component.customerStatsComponent) {
      this.petSelectorV2Component.customerStatsComponent.loadStatus();
    }
  }

  locationChangedEvent(newLocation: Location) {
    console.log('location changed' + newLocation.locationName);
    this.createPetAppointmentV2SyncService.setSelectedLocation(newLocation);
    this.appointment.locationId = newLocation?.id;
  }

  getMessage(action: string) {
    let message = '';
    switch (action) {
      case 'create':
        message += 'This will create the booking without sending email/SMS confirmation messages';
        if (this.appointment?.paymentConfig?.paymentMode === this.paymentModeEnum.DepositRequest
          || this.appointment?.paymentConfig?.sendSms) {
          message += ' but will send out a deposit request via';
          message += this.appointment?.paymentConfig?.sendSms ? ' SMS' : '';
          if (this.appointment?.paymentConfig?.paymentMode === this.paymentModeEnum.DepositRequest
            && this.appointment?.paymentConfig?.sendSms) {
            message += ' &';
          }
          if (this.appointment?.paymentConfig?.paymentMode === this.paymentModeEnum.DepositRequest) {
            message += ' Email';
          }
        }
        message += '.';
        break;
      case 'createAndConfirm':
        message += 'This will create the booking and will send out email/SMS confirmation messages';
        if (this.appointment?.paymentConfig?.paymentMode === this.paymentModeEnum.DepositRequest
          || this.appointment?.paymentConfig?.sendSms) {
          message += ' with a deposit request via';
          message += this.appointment?.paymentConfig?.sendSms ? ' SMS' : '';
          if (this.appointment?.paymentConfig?.paymentMode === this.paymentModeEnum.DepositRequest
            && this.appointment?.paymentConfig?.sendSms) {
            message += ' &';
          }
          if (this.appointment?.paymentConfig?.paymentMode === this.paymentModeEnum.DepositRequest) {
            message += ' Email';
          }
        }
        if (this.appointment?.paymentConfig?.paymentMode === this.paymentModeEnum.PaymentPlan) {
          message += ' and selected payment plan will be applied';
        }
        message += '.';
        break;
      case 'update':
        message += 'This will update the booking without sending email/SMS confirmation messages';
        if (this.appointment?.paymentConfig?.paymentMode === this.paymentModeEnum.DepositRequest
          || this.appointment?.paymentConfig?.sendSms) {
          message += ' but will send out a deposit request reminder via';
          message += this.appointment?.paymentConfig?.sendSms ? ' SMS' : '';
          if (this.appointment?.paymentConfig?.paymentMode === this.paymentModeEnum.DepositRequest
            && this.appointment?.paymentConfig?.sendSms) {
            message += ' &';
          }
          if (this.appointment?.paymentConfig?.paymentMode === this.paymentModeEnum.DepositRequest) {
            message += ' Email';
          }
        }
        message += '.';
        break;
      case 'updateAndConfirm':
        message += 'This will update the booking and will send out email/SMS confirmation messages';
        if (this.appointment?.paymentConfig?.paymentMode === this.paymentModeEnum.DepositRequest
          || this.appointment?.paymentConfig?.sendSms) {
          message += ' with a deposit request via';
          message += this.appointment?.paymentConfig?.sendSms ? ' SMS' : '';
          if (this.appointment?.paymentConfig?.paymentMode === this.paymentModeEnum.DepositRequest
            && this.appointment?.paymentConfig?.sendSms) {
            message += ' &';
          }
          if (this.appointment?.paymentConfig?.paymentMode === this.paymentModeEnum.DepositRequest) {
            message += ' Email';
          }
        }
        if (this.appointment?.paymentConfig?.paymentMode === this.paymentModeEnum.PaymentPlan) {
          message += ' and selected payment plan will be applied';
        }
        message += '.';
        break;
    }
    return message;
  }

  paymentConfigChanged($event) {
    this.createPetAppointmentV2SyncService.calculateTotalAmount();
  }

}
