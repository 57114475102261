import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ContextIdDto } from '@savvy/app';
import {
  ActionDefinition,
  AddSuppliedTaskToWorkflowState,
  DeleteWorkflowDefinition,
  ListWorkflowDefinitionsForEditDto,
  LoadWorkflowDefinitionForEditResponse,
  LoadWorkflowStateResponse,
  RemoveStateFromWorkflow, UpdateTaskDefinition,
  WorkflowState,
  WorkflowTaskDefinition,
  WorkflowdefService
} from '@savvy/workflow-definition';
import { Subject, Subscription } from 'rxjs';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { APP_TYPE_ENUM, ContextService } from '../../context.service';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';
import { AddEditWorkflowDialogComponent } from '../add-edit-workflow-dialog/add-edit-workflow-dialog.component';
import { AddEditWorkflowTaskGroupDialogComponent } from '../add-edit-workflow-task-group-dialog/add-edit-workflow-task-group-dialog.component';
import { ManualTaskComponent } from '../add-manual-task/manual-task.component';
import { SendMessageTaskComponent } from '../add-send-message-task/send-message-task.component';
import { SendSmsTaskComponent } from '../add-send-sms-task/send-sms-task.component';

interface ActionType {
  label: string;
  icon: string;
  taskType: WorkflowTaskDefinition.TaskTypeEnum;
  actionType: ActionDefinition.ActionTypeEnum;
}

@Component({
  selector: 'app-task-workflow-details',
  templateUrl: './task-workflow-details.component.html',
  styleUrls: ['./task-workflow-details.component.scss']
})
export class TaskWorkflowDetailsComponent implements OnInit, OnDestroy {

  loadWorkflowDefinitionForEditResponse: LoadWorkflowDefinitionForEditResponse;
  response: ListWorkflowDefinitionsForEditDto;
  workflowDefinitionId: string;
  selectedTaskDef: WorkflowTaskDefinition | null;
  selectedWorkflowState: WorkflowState;
  selectedStateId: string;
  allowEditState = true;
  appType: string;
  actionTypes: Array<ActionType> = new Array<ActionType>();
  contextIdDto: ContextIdDto;
  subscriptions: Subscription[] = [];
  taskTypeEnum = WorkflowTaskDefinition.TaskTypeEnum;

  private _unsubscribeAll: Subject<any>;

  constructor(private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private location: Location,
    private contextService: ContextService,
    private workflowdefService: WorkflowdefService,
    public notify: FloSnackbarComponent) {
    this._unsubscribeAll = new Subject();
  }

  get showButtonsForApp(): boolean {
    if (this.contextService.builderApp) {
      return true;
    }
    if (this.appType === APP_TYPE_ENUM.PET_GROOMING_SALON) {
      return false;
    }

    return true;
  }

  ngOnInit(): void {
    this.initActionTypes();
    console.log('inside workflow details init');
    this.route?.parent?.parent?.params.subscribe(params => {
      if (params?.contextId && params?.contextIdType) {
        console.log('looking for route params');
        this.contextIdDto = {
          contextId: params?.contextId,
          contextIdType: params?.contextIdType
        };
        this.appType = this.contextService.getAppType();
        console.log('got app type ', this.appType);

        console.log('looking for sidePanel');
        this.selectedTaskDef = null;
        this.route.params.subscribe(childParams => {
          if (childParams['workflowDefinitionId']) {
            this.workflowDefinitionId = childParams['workflowDefinitionId'];
            console.log('got workflowDefinitionId', this.workflowDefinitionId);
            this.loadWorkflow();
          } else {
            console.log('ERROR: No workflow definition id');
          }
        });
        // TODO: Set this allowEditState
      } else {
        console.log('no contextIdDto', this.contextIdDto);
        this.appType = this.contextService.getAppType();
        console.log('got app type ', this.appType);
      }
    });
  }

  initActionTypes() {
    this.actionTypes = new Array<ActionType>();
    this.actionTypes.push({
      label: 'Send an Email',
      icon: 'email',
      taskType: WorkflowTaskDefinition.TaskTypeEnum.SendMessage,
      actionType: ActionDefinition.ActionTypeEnum.SendMessage
    });
    this.actionTypes.push({
      label: 'Send a Message',
      icon: 'sms',
      taskType: WorkflowTaskDefinition.TaskTypeEnum.SendSms,
      actionType: ActionDefinition.ActionTypeEnum.SendSms
    });
    this.actionTypes.push({
      label: 'Create a Manual Task',
      icon: 'task',
      taskType: WorkflowTaskDefinition.TaskTypeEnum.ManualTask,
      actionType: null
    });
  }

  ngOnDestroy() {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  loadWorkflow() {
    console.log('Loading workflow with ', this.workflowDefinitionId);
    this.workflowdefService.getWorkflowDefsByIdsForEdit(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      [this.workflowDefinitionId]).subscribe(response => {
        if (response) {
          this.response = response;
          console.log('got response');
          if (response.workflowDefinitionForEditResponses && response.workflowDefinitionForEditResponses.length) {
            this.loadWorkflowDefinitionForEditResponse = response.workflowDefinitionForEditResponses[0];
            this.selectedWorkflowState = this.getState(this.selectedStateId);
          } else {
            console.log('unable to init as no workflowDefinitionForEditResponses in', response);
          }
        }
      });
  }

  editWorkflowBtnClick() {
    const editWorkflowStateDialogRef = this.dialog.open(AddEditWorkflowDialogComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        workflowDefinition: this.loadWorkflowDefinitionForEditResponse
      },
      height: 'auto',
      width: '600px',
    });

    editWorkflowStateDialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.response) {
        this.loadWorkflow();
      }
    });
  }

  addStateBtnClick() {
    const createWorkflowStateDialogRef = this.dialog.open(AddEditWorkflowTaskGroupDialogComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        workflowDefinitionId: this.workflowDefinitionId,
        allowEditState: this.allowEditState
      },
      height: 'auto',
      width: '600px',
    });

    createWorkflowStateDialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.response) {
        this.loadWorkflow();
      }
    });
  }

  deleteWorkflowBtnClick() {
    const confirmDialog = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete',
        message: 'Do you want to delete workflow!'
      },
      height: 'auto',
      width: '250px',
    });

    confirmDialog.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.deleteWorkflowDef();
      }
    });
  }

  deleteWorkflowDef() {
    const deleteRequest: DeleteWorkflowDefinition = {
      contextIdDto: this.contextIdDto,
      workflowDefinitionId: this.workflowDefinitionId
    };
    this.workflowdefService.deleteWorkflowDefinition(deleteRequest).subscribe(response => {
      if (response) {
        this.notify.message = 'Workflow deleted successfully.';
        this.notify.open();
        this.location.back();

        //        this.router.navigate(['/workflows']);
      }
    }, () => {
      this.notify.message = 'Failed to delete workflow.';
      this.notify.open();
    });
  }

  addTaskToStateClick(state: LoadWorkflowStateResponse, actionType: ActionType) {
    this.selectedWorkflowState = state.workflowState;
    this.selectedStateId = state.workflowState.id;

    if (!this.loadWorkflowDefinitionForEditResponse.workflowDefinitionTypeData) {
      console.warn('error - no workflowDefinitionTypeData for this working, please edit workflow and set it up');
      return;
    }
    console.log('actionType ', actionType);
    if (actionType.taskType === WorkflowTaskDefinition.TaskTypeEnum.ManualTask) {
      this.addManualTask(state);
    } else if (actionType.taskType === WorkflowTaskDefinition.TaskTypeEnum.SendMessage) {
      this.addSendMessageTask(state);
    } else if (actionType.taskType === WorkflowTaskDefinition.TaskTypeEnum.SendSms) {
      this.addSendSmsTask(state);
    }
  }

  editManualTask(state: LoadWorkflowStateResponse, workflowTaskDefinition: WorkflowTaskDefinition) {
    const dialogRef = this.dialog.open(ManualTaskComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        workflowState: state.workflowState,
        workflowDefinitionId: this.workflowDefinitionId,
        workflowTaskDefinition,
        workflowDefinitionTypeData: this.loadWorkflowDefinitionForEditResponse.workflowDefinitionTypeData
      },
      width: '60%',
      height: 'auto'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        console.log('no result returned!');
        this.loadWorkflow();

      } else {
        const req = <UpdateTaskDefinition>{};
        req.contextIdDto = this.contextIdDto;
        req.workflowDefinitionId = this.workflowDefinitionId;
        req.stateId = state.workflowState.id;
        req.workflowTaskDefinition = result;

        this.workflowdefService.updateTaskDef(req).subscribe(() => {
          this.notify.message = 'Updated task';
          this.notify.open();
          this.loadWorkflow();
        });
      }
    });
  }

  addManualTask(state: LoadWorkflowStateResponse) {
    const dialogRef = this.dialog.open(ManualTaskComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        workflowState: state.workflowState,
        workflowDefinitionId: this.workflowDefinitionId,
        workflowDefinitionTypeData: this.loadWorkflowDefinitionForEditResponse.workflowDefinitionTypeData
      },
      width: '80%',
      height: 'auto'
    });

    dialogRef.afterClosed().subscribe(result => {

      if (!result) {
        console.log('no result returned!');
        this.loadWorkflow();

      } else {
        state.workflowState.workflowTaskDefinitionList.push(result);
        const req = <AddSuppliedTaskToWorkflowState>{};
        req.workflowTaskDefinition = result;
        req.workflowDefinitionId = this.workflowDefinitionId;
        req.contextIdDto = this.contextIdDto;
        req.stateId = state.workflowState.id;
        req.workflowTaskDefinition.taskType = this.taskTypeEnum.ManualTask;
        this.workflowdefService.addSuppliedTaskToState(req).subscribe(() => {
          this.notify.message = 'Added new task to workflow';
          this.notify.open();
          this.loadWorkflow();
        });
      }
    });
  }

  addSendMessageTask(state: LoadWorkflowStateResponse) {
    const dialogRef = this.dialog.open(SendMessageTaskComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        workflowState: state.workflowState,
        workflowDefinitionId: this.workflowDefinitionId,
        workflowDefinitionTypeData: this.loadWorkflowDefinitionForEditResponse.workflowDefinitionTypeData
      },
      width: '60%',
      height: 'auto',
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe(result => {

      if (!result) {
        console.log('no result returned!');
        this.loadWorkflow();
      } else {
        state.workflowState.workflowTaskDefinitionList.push(result);
        const req = <AddSuppliedTaskToWorkflowState>{};
        req.workflowTaskDefinition = result;
        req.workflowDefinitionId = this.workflowDefinitionId;
        req.contextIdDto = this.contextIdDto;
        req.stateId = state.workflowState.id;

        console.log('adding supplied task to state', result);
        this.workflowdefService.addSuppliedTaskToState(req).subscribe(() => {
          console.log('added supplied task to state');
          this.notify.message = 'Added new message to workflow';
          this.notify.open();
          this.loadWorkflow();
        });
      }
    });
  }

  addSendSmsTask(state: LoadWorkflowStateResponse) {
    const dialogRef = this.dialog.open(SendSmsTaskComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        workflowState: state.workflowState,
        workflowDefinitionId: this.workflowDefinitionId,
        workflowDefinitionTypeData: this.loadWorkflowDefinitionForEditResponse.workflowDefinitionTypeData
      },
      width: '60%',
      height: 'auto',
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe(result => {

      if (!result) {
        console.log('no result returned!');
        this.loadWorkflow();
      } else {
        state.workflowState.workflowTaskDefinitionList.push(result);
        const req = <AddSuppliedTaskToWorkflowState>{};
        req.workflowTaskDefinition = result;
        req.workflowDefinitionId = this.workflowDefinitionId;
        req.contextIdDto = this.contextIdDto;
        req.stateId = state.workflowState.id;

        console.log('adding supplied task to state', result);
        this.workflowdefService.addSuppliedTaskToState(req).subscribe(() => {
          console.log('added supplied task to state');
          this.notify.message = 'Added new message to workflow';
          this.notify.open();
          this.loadWorkflow();
        });
      }
    });
  }

  editSendMessageTask(state: LoadWorkflowStateResponse, workflowTaskDefinition: WorkflowTaskDefinition) {
    const dialogRef = this.dialog.open(SendMessageTaskComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        workflowState: state.workflowState,
        workflowDefinitionId: this.workflowDefinitionId,
        workflowTaskDefinition,
        workflowDefinitionTypeData: this.loadWorkflowDefinitionForEditResponse.workflowDefinitionTypeData
      },
      width: '60%',
      height: 'auto',
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe(result => {

      if (!result) {
        console.log('no result returned!');
        this.loadWorkflow();
      } else {
        const req = <UpdateTaskDefinition>{};
        req.contextIdDto = this.contextIdDto;
        req.workflowDefinitionId = this.workflowDefinitionId;
        req.stateId = state.workflowState.id;
        req.workflowTaskDefinition = result;

        this.workflowdefService.updateTaskDef(req).subscribe(() => {
          this.notify.message = 'Updated message';
          this.notify.open();
          this.loadWorkflow();
        });
      }
    });
  }

  editSendSmsTask(state: LoadWorkflowStateResponse, workflowTaskDefinition: WorkflowTaskDefinition) {
    const dialogRef = this.dialog.open(SendSmsTaskComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        workflowState: state.workflowState,
        workflowDefinitionId: this.workflowDefinitionId,
        workflowTaskDefinition,
        workflowDefinitionTypeData: this.loadWorkflowDefinitionForEditResponse.workflowDefinitionTypeData
      },
      width: '60%',
      height: 'auto',
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe(result => {

      if (!result) {
        console.log('no result returned!');
        this.loadWorkflow();
      } else {
        const req = <UpdateTaskDefinition>{};
        req.contextIdDto = this.contextIdDto;
        req.workflowDefinitionId = this.workflowDefinitionId;
        req.stateId = state.workflowState.id;
        req.workflowTaskDefinition = result;

        this.workflowdefService.updateTaskDef(req).subscribe(() => {
          this.notify.message = 'Updated message';
          this.notify.open();
          this.loadWorkflow();
        });
      }
    });
  }

  onTaskSelect(taskDef: WorkflowTaskDefinition, state: LoadWorkflowStateResponse) {
    this.selectedWorkflowState = state.workflowState;
    this.selectedStateId = state.workflowState.id;
    this.selectedTaskDef = taskDef;

    if (taskDef.taskType === WorkflowTaskDefinition.TaskTypeEnum.ManualTask) {
      this.editManualTask(state, taskDef);
    } else if (taskDef.taskType === WorkflowTaskDefinition.TaskTypeEnum.SendMessage) {
      this.editSendMessageTask(state, taskDef);
    } else if (taskDef.taskType === WorkflowTaskDefinition.TaskTypeEnum.SendSms) {
      this.editSendSmsTask(state, taskDef);
    }
  }

  editTaskGroup(state: LoadWorkflowStateResponse) {
    const createWorkflowStateDialogRef = this.dialog.open(AddEditWorkflowTaskGroupDialogComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        allowEditState: this.allowEditState,
        workflowDefinitionId: this.workflowDefinitionId,
        state
      },
      height: 'auto',
      width: '600px',
    });

    createWorkflowStateDialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.response) {
        this.loadWorkflow();
      }
    });
  }

  deleteStateBtnClick(state: LoadWorkflowStateResponse) {
    const confirmDialog = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete',
        message: 'Do you want to delete section?'
      },
      height: 'auto',
      width: '250px',
      panelClass: 'helpwindow'
    });

    confirmDialog.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.deleteState(state);
      }
    });
  }

  deleteState(state: LoadWorkflowStateResponse) {
    const removeWf: RemoveStateFromWorkflow = {
      contextIdDto: this.contextIdDto,
      workflowDefinitionId: this.workflowDefinitionId,
      stateId: state.workflowState.workflowStateId.id
    };
    this.workflowdefService.removeStateFromWorkflow(removeWf).subscribe(response => {
      if (response) {
        this.notify.message = 'State successfully deleted.';
        this.notify.open();
        this.loadWorkflow();
      }
    }, () => {
      this.notify.message = 'State failed to delete.';
      this.notify.open();
    });
  }

  taskRemoved() {
    this.loadWorkflow();
    //this.sidePanel.close();
  }

  loadWorkflowState() {
    this.loadWorkflow();
  }

  getState(stateId: string): WorkflowState {
    let workflowState: WorkflowState = null;
    if (stateId) {
      const loadWorkflowStateResponse: LoadWorkflowStateResponse =
        this.loadWorkflowDefinitionForEditResponse.loadWorkflowStateResponseList.find(v => v.workflowState.id === stateId);
      if (loadWorkflowStateResponse) {
        workflowState = loadWorkflowStateResponse.workflowState;
      }
    }
    return workflowState;
  }


  backToWorkflow() {

  }

  backToList() {
    this.router.navigate(['/admin/settings',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType, 'list-task-workflows']);
  }


  public colorInversionByHexString(colorString: string) {
    if (!colorString) {
      return '#000000';
    }

    colorString = colorString.replace('#', '');
    if (colorString.length == 6) {
      const r = parseInt(colorString.substring(0, 2), 16);
      const g = parseInt(colorString.substring(2, 4), 16);
      const b = parseInt(colorString.substring(4, 6), 16);
      const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
      return (yiq >= 128) ? '#000000' : '#FFFFFF';
    } else {
      return '#000000';
    }
  }
}
