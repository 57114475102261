import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreateUserRating, UserRating, UserRatingsService, UsersService } from '@savvy/user';
import { SessionStorageService } from '../shared/services/local-storage.service';

@Component({
  selector: 'app-user-rating-modal',
  templateUrl: './user-rating-modal.component.html',
  styleUrls: ['./user-rating-modal.component.scss']
})
export class UserRatingModalComponent implements OnInit {
  userRating: UserRating = {
    comments: '',
    rating: 0,
    userId: '',
    dateReceived: ''
  };
  public stars: boolean[] = Array(10).fill(false);

  constructor(
    private userRatingsService: UserRatingsService,
    private localStorageService: SessionStorageService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserRatingModalComponent>,

  ) { }

  ngOnInit() {
  }

  public rate(rating: number) {
    console.log('rating', rating);
    this.stars = this.stars.map((_, i) => rating > i);
    console.log('stars', this.stars);
    this.userRating.rating = rating;
  }

  submitRating() {
    this.userRating.userId = this.localStorageService.getAuthData()?.userId;
    this.userRating.dateReceived = new Date().toISOString();
    const addUserRatingRequest: CreateUserRating = {
      userRating: this.userRating
    };
    this.userRatingsService.addUserRating(addUserRatingRequest)
      .subscribe(res => {
        if (res) {
          this.dialogRef.close(res);
        }
      });
  }

  ignoreRating() {
    const userId = this.localStorageService.getAuthData()?.userId;
    this.userRatingsService.ratingsIgnored(userId, this.data.contextIdDto.contextId)
      .subscribe(res => {
        if (res) {
          this.dialogRef.close();
        }
      });
  }

}
