import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlphaFilterComponent } from '@savvy/alpha-filter';
import { DataListDto } from '@savvy/datalist';
import { ContextIdDto } from '@savvy/payment';
import { SearchService } from '@savvy/search';
import { MenuDefinition } from '@savvy/ui';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { ConfService } from 'src/app/flo/config/conf.service';
import { ContextService } from '../context.service';
import { EventBusService } from '../element/EventBusService';
import { NavChangeService } from '../element/NavChangeService';
import { TrialExpiredService } from '../shared/services/trial-expired.service';
import MenuItemTypeEnum = MenuDefinition.MenuItemTypeEnum;

import { ClearObservable } from '../shared/classes/clear-observable';

const defalutProfilePicDog = '/assets/images/dog-profile.png';
const defalutProfilePicCat = '/assets/images/cat-profile.webp';

@Component({
  selector: 'app-list-pets',
  templateUrl: './listPets.component.html',
  styleUrls: ['./listPets.component.scss']
})
export class ListPetsComponent extends ClearObservable implements OnInit, OnDestroy {
  @ViewChild('alphabetFilterComponent', { static: false }) alphabetFilterComponent: AlphaFilterComponent;

  contextIdDto: ContextIdDto;
  subscriptions: Subscription[] = [];

  eventBus = new EventBusService();

  breeds: DataListDto;

  viewDate = new Date();

  loaded = false;

  viewMode = 'grid';

  petsCount = 0;

  showFilters = true;

  constructor(
    private router: Router,
    public deviceService: DeviceDetectorService,
    public trialExpiredService: TrialExpiredService,
    private navChange: NavChangeService,
    private contextService: ContextService,
    private searchService: SearchService
  ) {
    super();
    this.showFilters = this.deviceService.isMobile() ? false : true;
  }



  ngOnInit() {
    let offset = 0;
    if (sessionStorage.getItem('pageNumber')) {
      offset = Number(sessionStorage.getItem('pageNumber'));
      sessionStorage.removeItem('pageNumber');
    }

    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
        this.navChange.add(MenuItemTypeEnum.ListPets);
        this.searchService.countPets(this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(res => this.petsCount = res);
      }
    }));
  }

  viewChanged(event: any) {
    console.log('view changed', event);
    this.viewMode = event;
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getUrl(reference: string) {
    if (reference) {
      return ConfService.apiUrl() + '/rest/storage?public=false&contextId=' +
        this.contextIdDto.contextId + '&contextIdType=' +
        this.contextIdDto.contextIdType + '&id=' + reference;
    }
    return null;
  }



  create() {
    this.router.navigate(['/pets/create',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType]);
  }

  showPetTypes() {
    this.router.navigate(['/pets/petTypes', this.contextIdDto.contextId, this.contextIdDto.contextIdType]);
  }



}
