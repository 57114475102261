<app-element
  *ngIf="loadEiViewResponse != null && loadEiViewResponse.viewRootElementDto != null && form != null"
  style="height: 100%"
  [elementDto]="loadEiViewResponse.viewRootElementDto"
  [form]="form"
  [eventBus]="eventBus"
  [contextIdDto]="contextIdDto"
  [viewContext]="loadEiViewResponse.viewContextDto"
  [additionalDataMapDto]="loadEiViewResponse.additionalDataMapDto"
  [showPlaceholder]="true"
  [changeListener]="changeListener"
>
</app-element>
