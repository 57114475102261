import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ElementControlService } from '../ElementControlService';
import { EicompService, EntityChangeEvent } from '@savvy/entity-instance-composite';
import { ChangeListener } from '../changeListener';
import { MatDialog } from '@angular/material/dialog';
import { AddFieldDefinitionModalComponent } from '../../shared/components/add-field-definition-modal/add-field-definition-modal.component';
import { ElementInstanceDto, ElementInstanceViewDto, FieldValueDto, ListItemDto, SelectFieldDefinitionDto } from '@savvy/view-composite';
import { ContextIdDto } from '@savvy/datalist';

@Component({
  selector: 'app-select-field-instance',
  templateUrl: 'selectFieldInstance.component.html',
  styles: [`
    .add-new-field {
      margin-left: 30% !important;
    }
  `]
})
export class SelectFieldInstanceComponent implements OnInit {

  @Input() selectFieldDefinition: SelectFieldDefinitionDto;
  @Input() elementInstanceDto: ElementInstanceDto;
  @Input() entityInstanceId: string;
  @Input() contextIdDto: ContextIdDto;
  @Input() additionalDataMapDto: any;
  @Input() form: UntypedFormGroup;
  @Input() showPlaceholder: boolean;

  @Input() changeListener: ChangeListener;

  constructor(private api: EicompService,
    private ecs: ElementControlService,
    private dialog: MatDialog) {
  }

  get isValid() {
    return this.form.controls[this.elementInstanceDto.instanceId].valid;
  }

  isTypeahead() {
    if (!this.elementInstanceDto.elementInstanceViewDto.displayWidgetType) {
      // Default is typeahead
      return true;
    }
    if (this.elementInstanceDto.elementInstanceViewDto.displayWidgetType === ElementInstanceViewDto.DisplayWidgetTypeEnum.Typeahead) {
      return true;
    }
    return false;
  }


  ngOnInit(): void {

    // console.log('name is ' + this.selectFieldDefinition.name);
    //    console.log('elementInstanceDto instance id is ' + this.elementInstanceDto.instanceId);

    //  console.log('on init ' + this.form.controls[this.elementInstanceDto.instanceId].value);
    this.form.controls[this.elementInstanceDto.instanceId].valueChanges.subscribe((value) =>  {
      // console.log('changed value=' + value);
      this.onChange(value);
    });
  }

  onChange(newValue: string) {
    console.log('onChange detected value new value is ' + newValue);

    const fieldValue: FieldValueDto = {
      strValue: newValue,
      valueType: FieldValueDto.ValueTypeEnum.String
    };

    const req: EntityChangeEvent = {
      contextIdDto: this.contextIdDto,
      fieldValue,
      entityInstanceId: this.entityInstanceId,
      fieldInstanceId: this.elementInstanceDto.instanceId
    };

    // There is no loadProductsResponse from this, do we care?
    this.api.entityInstanceChangeEvent(req).subscribe(
      response => {
        console.log('response ' + response);
        this.ecs.handleChangedElements(response.changedElementList, this.form);
        if (this.changeListener) {
          this.changeListener.add('change');
        }
      }
    );
  }

  openAddFieldDefinitionModal() {
    const dialogRef = this.dialog.open(AddFieldDefinitionModalComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        dataListName: 'Breed',
        dataListId: this.selectFieldDefinition.datalistId,
        dataListItems: this.additionalDataMapDto.elementDefDataMap
        [this.elementInstanceDto.elementDefinitionId].selectListDto.selectItemDtoList
      }
    });

    dialogRef.afterClosed().subscribe((newItemName) => {
      if (newItemName) {
        console.log('list was updated');
        const listItemDto = <ListItemDto>{};
        listItemDto.id = newItemName;
        listItemDto.label = newItemName;
        this.additionalDataMapDto.elementDefDataMap[this.elementInstanceDto.elementDefinitionId].selectListDto.selectItemDtoList.unshift(listItemDto);
      }
    });
  }
}

