import { Injectable } from '@angular/core';
import { ContextIdDto } from '@savvy/app';
import { Location, LocationService } from '@savvy/location';
import { UserId, UserLocationMatrixDto, UserLocationMatrixService } from '@savvy/user-location';
import { AdminSettingsService } from '../../admin-settings/admin-settings.service';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash';
@Injectable({
  providedIn: 'root'
})
export class LocationsHelperService {
  allLocationsIncludingDeleted: Location[] = [];
  nonDeletedLocations: Location[] = [];
  userLocationMatrixDto: UserLocationMatrixDto;

  private locationsSource: BehaviorSubject<Location[]> = new BehaviorSubject(this.allLocationsIncludingDeleted);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public locations$: Observable<Location[]> = this.locationsSource.asObservable();

  private activeLocationsSource: BehaviorSubject<Location[]> = new BehaviorSubject(this.nonDeletedLocations);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public activeLocations$: Observable<Location[]> = this.activeLocationsSource.asObservable();

  constructor(
    private locationsService: LocationService,
    private adminSettingsService: AdminSettingsService,
    private userLocationMatrixService: UserLocationMatrixService
  ) { }


  loadAllLocations(contextIdDto: ContextIdDto) {
    return new Promise<Location[]>((resolve) => {
      this.locationsService.listLocations(contextIdDto.contextId).subscribe(res => {
        if (res && res.length) {
          this.allLocationsIncludingDeleted = _.cloneDeep(res);
          for (const location of this.allLocationsIncludingDeleted) {
            if (!location.address) {
              location.address = {
                address1: '',
                address2: '',
                address3: '',
                city: '',
                state: '',
                country: '',
                zipCode: ''
              };
            }
            if (!location.locationName) {
              location.locationName = 'Main Branch';
            }
          }
          // WHAT IS THIS DOING?  SHOULD BE DONE SERVER SIDE
          if (this.allLocationsIncludingDeleted.filter(l => l.primary).length === 0) {
            this.allLocationsIncludingDeleted[0].address = this.adminSettingsService.org.address;
            this.allLocationsIncludingDeleted[0].primary = true;
            this.locationsService.updateLocation(this.allLocationsIncludingDeleted[0]).subscribe((locationUpdated) => {
              if (locationUpdated) {
                this.loadAllLocations(contextIdDto);
              }
            });
          }
          this.locationsSource.next(res);
          resolve(res);
        } else {
          resolve([]);
        }
      });
    })
  }

  public loadLocationMatrix(contextIdDto: ContextIdDto) {
    return new Promise(resolve => {
      this.userLocationMatrixService.getUserLocationMatrix(contextIdDto.contextId).subscribe(response => {
        this.userLocationMatrixDto = response;
        resolve(response);
      });
    });
  }

  public isUserAllowedInLocation(locationId: string, userId: string) {
    if (this.userLocationMatrixDto?.userLocationMatrixRows?.filter(r => r.locationIds?.length > 0)?.length > 0) {
      // Checks only if location matrix active
      const matrixRow = this.userLocationMatrixDto.userLocationMatrixRows.find(row => row.userId === userId);
      if (matrixRow && (!matrixRow.locationIds?.length || matrixRow.locationIds?.indexOf(locationId) === -1)) {
        return false;
      }
    }
    return true;
  }

  public loadActiveLocations(contextIdDto: ContextIdDto) {
    return new Promise<Location[]>(resolve => {
      // Active locations are non deleted ones
      this.locationsService.loadActiveLocations(contextIdDto.contextId).subscribe(async (res) => {
        if (res && res.length) {
          this.nonDeletedLocations = res.filter(l => l.locationName);
          if (this.nonDeletedLocations.length) {
            this.activeLocationsSource.next(res);
            await this.loadLocationMatrix(contextIdDto);
            console.log('loaded locations');
          }
        }
        resolve(this.nonDeletedLocations);
      });
    });
  }



}
