<mat-card appearance="outlined" class="panel new-card" *ngIf="invoices && invoices.length > 0">
  <mat-card-title>
    <div fxLayout="row">
      <div fxFlex="80">
        <p class="mt-xs mb-xs">{{'INVOICES' | translate}}</p>
      </div>
      <div fxFlex="20">
        <button mat-mini-fab color="accent" [disabled]="trialExpiredService.isTrialExpired()" (click)="create()">
          <mat-icon class="mat-24">add</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-title>

  <mat-card-content>
    <mat-list>
      <mat-list-item *ngFor="let invoice of invoices">
        <p matLine (click)="rowSelected(invoice.id)">
          {{invoice.invoiceDate |date:'medium'}}</p>
        <mat-divider *ngIf="invoices.length > 1"></mat-divider>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card>

<mat-card appearance="outlined" class="panel new-card" *ngIf="(!invoices || invoices.length === 0)">

  <mat-card-content>
    <div class="flexbox-parent">
      <div fxLayout="row" fxLayoutAlign="center center">
        <div style="text-align:center;">
          <app-zero-image name="Invoices"></app-zero-image><br>
          <h5 class="ma-0">{{'DONOT_HAVE_ANY_INVOICES' | translate}}</h5><br>
          <!-- *ngIf="listEntityListDto.canCreate" -->
          <button mat-raised-button color="accent" [disabled]="trialExpiredService.isTrialExpired()" (click)="create()">{{'NEW_INVOICE' | translate}}</button>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>