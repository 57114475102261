import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ContextIdDto, LineDefinitionDto} from '@savvy/entity-instance-composite';

@Component({
    selector: 'app-line-instance',
    templateUrl: 'lineInstance.component.html'
})
export class LineInstanceComponent {

    @Input() lineDefinition: LineDefinitionDto;
    @Input() contextIdDto: ContextIdDto;
    @Input() additionalDataMapDto: any;
    @Input() viewContext: any;
    @Input() entityInstanceId: string;
    @Input() showPlaceholder: boolean;
    @Input() form: UntypedFormGroup;
}

