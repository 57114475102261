import {CurrencyPipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';
import {Discount} from '@savvy/invoice';

@Pipe({
    name: 'discountTmpl'
})
export class DiscountTmplatePipe implements PipeTransform {

    constructor(private currencyPipe: CurrencyPipe) {
    }

    transform(discount: Discount, currencyCode: string, discountItem: any): string {
        let discountTxt = '';
        if (discount && discount.discountType) {
            if (discount.discountType === Discount.DiscountTypeEnum.Amount && discount.amountDiscount) {
                discountTxt = currencyCode ? this.currencyPipe.transform(discount.amountDiscount, currencyCode, 'symbol') : (discount.amountDiscount + '');
            } else if (discount.discountType === Discount.DiscountTypeEnum.Percentage && discount.percentDiscount) {
                discountTxt = discount.percentDiscount + '%';
            }
        }
        if (discountItem) {
            if (typeof discountItem === 'object') {
                discountTxt = discountItem.code + ' - ' + discountTxt;
            } else {
                discountTxt = discountItem + ' - ' + discountTxt;
            }
        }
        return discountTxt;
    }
}
