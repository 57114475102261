<div *ngIf="showSpinner" class="bg-spinner-wrapper">
  <mat-spinner style="margin: 0 auto;"></mat-spinner>
</div>

<mat-toolbar color="primary">
  <mat-toolbar-row *ngIf="!data?.title">{{'CHANGE_CARD' | translate}}</mat-toolbar-row>
  <mat-toolbar-row *ngIf="data?.title">
    <div>{{data.title | translate}}</div>
    <span class="example-spacer"></span>
    <button mat-raised-button color="accent" aria-label="Skip verification" (click)="skipVerification()">
      {{'SKIP' | translate}} <mat-icon class="full-screen-icon">skip_next</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="mdc-p-3 ">
  <p *ngIf="data?.desc">
    {{'CUSTOMER_VERIFY_CARD_DETAILS' | translate}}
  </p>
  <p *ngIf="data?.desc">
    {{'AYDEN_DETAILS_NOTE' | translate}} <a href="https://listings.pcisecuritystandards.org/pdfs/pcissc_overview.pdf" target="_blank" style="text-decoration: underline" rel="noopener noreferrer">{{'KNOW_MORE_ABOUT' | translate}}</a>).
  </p>
  <!--  <form novalidate (ngSubmit)="buy()" [formGroup]="stripeTest">-->
  <!--    <mat-form-field style="width: 100%" appearance="outline">-->
  <!--      <mat-label>{{'Name on Card' | translate}}</mat-label>-->
  <!--      <input matInput placeholder="{{'Name on Card' | translate}}" formControlName="name">-->
  <!--    </mat-form-field>-->

  <app-savvy-pay-change-card [contextIdDto]="contextIdDto" [subscription]="subscription" [cost]="data?.desc ? 0 : price" [currency]="currencyCode" [countryCode]="countryCode" [returnUrl]="returnUrl">
  </app-savvy-pay-change-card>
  <!--    <button mat-raised-button color="accent" [disabled]="!validForm()">{{'Change Card' | translate}}</button>-->
  <!--  </form>-->
</div>