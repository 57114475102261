<div class="cover-photo coverimg" [style.background-image]="coverPic ? 'url(' + coverPic + ')' : 'none'">
  <div class="cover-photo-label">
    <input [hidden]="true" id="input-file-id-cover" required (change)="handleCoverInput($event)" [multiple]="false"
      type="file">
    <label for="input-file-id-cover">
      <span class="vm mx-1">{{'UPLOAD_COVER_PHOTO' | translate}}</span>
      <mat-icon class="camera-icon-small vm">camera_alt</mat-icon>
    </label>
  </div>
</div>
<div fxLayout="column" fxLayout.gt-sm="row">
  <div fxFlex="nogrow" class="mdc-px-3 text-center">
    <div class="avatar avatar-150 circle border-3 coverimg pet-pic mx-auto"
      [style.background-image]="profilePic ? 'url(' + profilePic + ')' : 'none'">
      <input [hidden]="true" id="input-file-id-profile" required (change)="handleProfileInput($event)"
        [multiple]="false" type="file">
      <label for="input-file-id-profile" class="upload-label">
        <div>
          <mat-icon class="camera-icon">camera_alt</mat-icon>
          <span>{{'PROFILE_PHOTO' | translate}}</span>
        </div>
      </label>
    </div>
  </div>
  <form [formGroup]="petFormGroup" fxFlex fxLayout="column" class="pt-1 mdc-px-3">
    <div fxFlex="grow" fxLayout="column" fxLayout.gt-md="row" fxLayoutGap="0px" fxLayoutGap.gt-md="2%">
      <div fxFlex="32">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'NAME' | translate}}</mat-label>
          <input matInput formControlName="petName" name="petName" required />
          <mat-error *ngIf="petFormGroup.controls?.petName?.errors && (petFormGroup.touched || petFormGroup.dirty)">
            {{'ENTER_NAME' | translate}}</mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="32">
        <app-pet-type-selector [contextIdDto]="contextIdDto" [selectedPetType]="petFormGroup.controls?.petType">
        </app-pet-type-selector>
      </div>
      <div fxFlex="32">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'BREED' | translate}}</mat-label>
          <mat-select placeholder="{{'SELECT_BREED' | translate}}" formControlName="petBreed" name="petBreed">
            <mat-option>
              <ngx-mat-select-search [placeholderLabel]="'SEARCH_BREED' | translate" [formControl]="petBreedFilter">
              </ngx-mat-select-search>
            </mat-option>
            <mat-option [value]="'-1'">
              <button mat-flat-button color="accent" type="button" (click)="addNew()">
                {{'ADD_NEW' | translate}}
              </button>
            </mat-option>
            <mat-option *ngFor="let breed of filteredBreeds | async" [value]="breed.name">
              {{ breed.label }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="petFormGroup.controls?.petBreed?.errors && (petFormGroup.touched || petFormGroup.dirty)">
            {{'PLEASE' | translate}} {{'SELECT_BREED' | translate}}.</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div fxFlex="grow">
      <mat-form-field appearance="outline" fxFlex="grow">
        <mat-label>{{'PET_NOTES' | translate}}</mat-label>
        <textarea matInput formControlName="petNotes" cols="50" rows="3"
          placeholder="{{'PET_NOTES' | translate}}..."></textarea>
        <mat-error *ngIf="petFormGroup.controls?.petNotes?.errors && (petFormGroup.touched || petFormGroup.dirty)">
          {{'PLEASE' | translate}} {{'ENTER_NOTES' | translate}}.</mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
