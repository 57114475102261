<div>
  <mat-toolbar color="primary">
    <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <span>{{'NOTIF_SETTINGS' | translate}}</span>
      <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <div>
    <app-notification-settings [contextIdDto]="data?.contextIdDto"></app-notification-settings>
  </div>
</div>
