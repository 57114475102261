import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TranslateModule } from '@ngx-translate/core';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ViewSingleImageComponent } from './viewSingleImage.component';
import { ViewGalleryImageComponent } from './viewGalleryImage.component';
import { ImgCompressorDirective } from './img-compressor.directive';
import { ColorPickerModule } from 'ngx-color-picker';
import { SharedModule } from '../shared/shared.module';
import { DirectivesModule } from 'src/app/core/directives/directives.module';
import { NgxPhotoEditorModule } from 'ngx-photo-editor';
import { ImagesComponent } from './images.component';
import { VideoUploaderComponent } from './video-uploader/video-uploader.component';


@NgModule({
  imports: [
    CommonModule,
    NgxPhotoEditorModule,
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    TranslateModule,
    ColorPickerModule,
    MatToolbarModule,
    MatTabsModule,
    MatListModule,
    MatSlideToggleModule,
    SharedModule,
    MatGridListModule,
    NgxFileDropModule,
    MatSelectModule,
    FlexLayoutModule,
    FormsModule,
    NgxDatatableModule,
    DirectivesModule
  ],
  declarations: [
    ImgCompressorDirective,
    ImagesComponent,
    ViewSingleImageComponent,
    ViewGalleryImageComponent,
    VideoUploaderComponent
  ],
  exports: [
    ImgCompressorDirective,
    ImagesComponent,
    ViewSingleImageComponent,
    ViewGalleryImageComponent,
    VideoUploaderComponent
  ],
  providers: []
})

export class AppImagesModule { }
