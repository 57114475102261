import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { RouterModule } from '@angular/router';

import { TranslateModule } from "@ngx-translate/core";
import { BaseChartDirective, provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { BarChartWidgetComponent } from '../flo/chartlib/barChartWidget.component';
import { DonutChartComponent } from '../flo/chartlib/donutChart.component';
import { FloMaterialModule } from '../flo/flo.material.module';
import { ChartlibComponent } from './chartlib.component';
import { ChartlibRoutes } from './chartlib.routing';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(ChartlibRoutes),
    FloMaterialModule,
    MatCardModule,
    FlexLayoutModule,
    TranslateModule,
    BaseChartDirective
  ],
  declarations: [ChartlibComponent,
    BarChartWidgetComponent,
    DonutChartComponent
  ],
  exports: [
    BarChartWidgetComponent,
    DonutChartComponent
  ],
  providers: [
    provideCharts(withDefaultRegisterables())
  ]
})

export class ChartlibModule { }
