<div class="mdc-mb-1">
  <h2 class="mt-0">&nbsp;<strong>{{'VIEW_PAYMENTS' | translate}}</strong></h2>
</div>

<div *ngIf="payments && payments.length > 0">
  <ngx-datatable class="material payments-tab-view" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="50" [scrollbarV]="true" [scrollbarH]="true" [rows]="payments">

    <ngx-datatable-column name="{{'DATE_TIME' | translate}}" [width]="100">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
        {{row.dateTime | localizedDate:'medium'}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'PAYMENT_METHOD' | translate}}" [width]="80">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
        {{row.paymentMethod}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'AMOUNT' | translate}}" [width]="10" prop="amount"></ngx-datatable-column>
    <ngx-datatable-column name="{{'PAYMENT_REFERENCE' | translate}}" [width]="80" prop="paymentReference">
    </ngx-datatable-column>
    <ngx-datatable-column [width]="10">
      <ng-template ngx-datatable-cell-template let-row="row">
        <button (click)="deletePayment(row)" class="ml-xs mat-icon-button" mat-icon-button="">
          <span class="mat-button-wrapper">
            <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">delete</mat-icon>
          </span>
          <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
          <div class="mat-button-focus-overlay"></div>
        </button>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>
<div class="flexbox-parent" *ngIf="payments && payments.length == 0">
  <div fxLayout="row" fxLayoutAlign="center center">
    <div style="text-align:center;">
      <app-zero-image name="payment" class="zero-image"></app-zero-image><br>
      <h5 class="ma-0">{{'DONOT_HAVE_ANY_PAYMENTS' | translate}}</h5><br>
    </div>
  </div>
</div>

&nbsp;
<button mat-raised-button color="accent" (click)="addPayment()">{{'ADD_PAYMENT' | translate}}
</button>

&nbsp;
<button mat-raised-button color="accent" (click)="paidFull('CASH')">{{'PAID_CASH' | translate}}
</button>

&nbsp;
<button mat-raised-button color="accent" (click)="paidFull('CARD_MACHINE')">{{'PAID_CARD' | translate}}
</button>
&nbsp;

<button mat-raised-button color="accent" (click)="paidFull('DIRECT_DEBIT')">{{'PAID_DIRECT_DEBIT' | translate}}
</button>
&nbsp;

<button mat-raised-button color="accent" (click)="paidFull('BANK_TRANSFER')">{{'PAID_BANK' | translate}}
</button>
&nbsp;
<button *ngIf="cardMachines && cardMachines.length > 0" mat-raised-button color="accent" (click)="showTerminalPayment()">{{'SAVVY_CARD_MACHINE' | translate}}
</button>
&nbsp;
<button *ngIf="plans && plans.length > 0 && !invoice.paidInFull" mat-raised-button color="accent" (click)="showPaidPlans()">{{'PAID_PLANS' | translate}}
</button>

<button mat-raised-button color="accent" (click)="cancelIt()">{{'CLOSE' | translate}}
</button>
<!-- &nbsp;
  <button mat-raised-button color="accent" (click)="showSavvyPayView()">{{'Savvy Pay' | translate}}
  </button> -->