import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { TranslateService } from '@ngx-translate/core';
import { ContextIdDto } from '@savvy/view-definition';
import { CustomerCompositeService, Customer, CustomerService, IdNameTupleDto } from '@savvy/customer';
import { PlanDto, PlansCompService, PlanService, SubscribeToPlan } from '@savvy/plan';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-customer-plan',
  templateUrl: 'addCustomerToPlan.html'
})
export class AddCustomerToPlanComponent implements OnInit {

  customerPlans: Array<PlanDto> = [];
  contextIdDto: ContextIdDto;
  planDef: any;

  searchCustomerResult: Array<IdNameTupleDto> = [];
  request = <SubscribeToPlan>{};

  customerDto: Customer;

  @Output() added: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private notify: FloSnackbarComponent,
    private planApi: PlanService,
    private planCompApi: PlansCompService,
    private customerCompApi: CustomerCompositeService,
    private customerService: CustomerService,
    private dialogRef: MatDialogRef<AddCustomerToPlanComponent>) {
    this.contextIdDto = data.contextIdDto;
    this.planDef = data.planDef;
    this.customerPlans = data.customerPlans;
  }

  ngOnInit() {
    this.request.planDefinitionId = this.planDef.id;
  }

  addToPlan() {

    if (this.request.customerId) {
      const customerExists = this.customerPlans.find(plan => plan.customerId === this.request.customerId && plan.status == 'ACTIVE');

      if (customerExists) {
        this.notify.message = 'Customer is already subscribed or has a pending payment for this plan.';
        this.notify.open();
      } else {
        this.request.contextIdDto = this.contextIdDto;
        this.request.planDefinitionId = this.planDef.id;

        this.planCompApi.subscribe(this.request).subscribe(res => {

          this.notify.message = 'Subscription request sent to customer.';
          this.notify.open();

          this.added.emit();
          this.dialogRef.close();
        });
      }

    }

  }

  loadCustomer(id: string) {
    this.customerService.loadCustomer(id, this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(res => {
      console.log(res);
      this.customerDto = res;
    });
  }

  handler(event: MatAutocompleteSelectedEvent): void {
    console.log('calling set value NOW');
    this.request.customerId = event.option.value;
    this.loadCustomer(this.request.customerId);
  }

  loadTypeahead(searchString: string) {

    console.log('env id is ' + this.contextIdDto.contextId);

    if (searchString && searchString.length > 0) {
      this.customerCompApi.searchCustomers(searchString, this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(res => {
        console.log(res);
        this.searchCustomerResult = res;
      });
    }
  }

  onKey(event: any) { // without type info
    this.loadTypeahead(event.target.value);
  }

  displayFn(id) {
    if (!id) {
      return '';
    }
    const index = this.searchCustomerResult.findIndex(state => state.id === id);
    if (index === -1) {
      console.log('id is ' + id + ' index is' + index + ' this should never happen as tuples should always be there first');
      return '';
    } else {
      return this.searchCustomerResult[index].name;
    }
  }



}
