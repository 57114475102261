<mat-toolbar>
  <mat-toolbar-row>{{'CREATE_WEBHOOK' | translate: {Default: 'Create Webhook'} }}</mat-toolbar-row>
</mat-toolbar>

<mat-card appearance="outlined">


  <mat-card-content>
    <form #customerForm="ngForm">

      <div fxLayout="row">
        <mat-form-field appearance="outline" #sourceField style="width: 100%">
          <mat-label>{{'URL' | translate}}</mat-label>
          <input matInput
            [(ngModel)]="webhook.url" name="nameField"
            #websiteTemplateField="ngModel"
            required="false"
            placeholder="{{'URL' | translate}}"/>
        </mat-form-field>
      </div>
      <!--div fxLayout="row">
        <mat-form-field appearance="outline" #sourceField style="width: 100%">
          <mat-label>{{'Name' | translate}}</mat-label>
          <mat-select
            [(ngModel)]="petWalk.name" name="nameField"
            #websiteTemplateField="ngModel"
            required="false"
            placeholder="{{ 'Name of Walk' | translate}}">
            <mat-option [value]=""></mat-option>
            <mat-option
              *ngFor="let item of websiteTemplates"
              [value]="item.id">{{item.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div-->
    </form>

  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button (click)="save()" color="primary">{{'CREATE' | translate: {Default:'Create'} }}</button>
  </mat-card-actions>
</mat-card>
