<mat-toolbar color="primary" *ngIf="!noToolbar">
  <app-breadcrumb *ngIf="!isContractModal"></app-breadcrumb>
  <h2 *ngIf="isContractModal">{{'CREATE_CONTRACT' | translate: {Default: 'Create Contract'} }}</h2>

</mat-toolbar>

<div *ngIf="response && contract" class="mdc-p-3 ">

  <form #contractForm="ngForm">

    <mat-form-field appearance="outline" style="width: 100%">
      <mat-select [placeholder]="'SELECT_CONTRACT_TEMPLATE' | translate: {Default: 'Select Contract Template'} " (selectionChange)="templateChange()" [(ngModel)]="contract.contractTemplateId" required name="contractTemplateId" #contractTemplateId="ngModel">
        <mat-option *ngFor="let contractTemplate of response.idNameTupleDtos" [value]="contractTemplate.id">
          {{ contractTemplate.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <app-flo-editor *ngIf="contract" style="width: 100%" [text]="contract.legalText" (textUpdated)="bodyUpdated($event);">
    </app-flo-editor>

  </form>

  <div fxLayout="row" class="mt-1">
    <div fxFlex="nogrow" class="pr-2" *ngIf="contract.id == null">
      <button mat-raised-button color="accent" (click)="addContract()" [disabled]="!contractForm.form.valid" disabled="disabled">{{'CREATE_CONTRACT' | translate: {Default: 'Create Contract'} }}</button>
    </div>
    <div fxFlex="nogrow" class="pr-2" *ngIf="contract.id == null">
      <button mat-raised-button color="accent" (click)="createAndSendContract()" [disabled]="!contractForm.form.valid" disabled="disabled">{{'CREATE_AND_SEND_CONTRACT' | translate: {Default: 'Create And Send Contract'} }}</button>
    </div>
    <div fxFlex="nogrow" class="pr-2" *ngIf="contract.id != null">
      <button mat-raised-button color="accent" (click)="sendContract()" [disabled]="!contractForm.form.valid" disabled="disabled">{{'SEND_CONTRACT' | translate: {Default: 'Send Contract'} }}</button>
    </div>
    <div fxFlex="nogrow" class="pr-2" *ngIf="contract.id != null">
      <button mat-raised-button color="accent" (click)="viewContract()" [disabled]="!contractForm.form.valid" disabled="disabled">{{'VIEW_CONTRACT' | translate: {Default: 'View Contract'} }}</button>
    </div>
    <div fxFlex></div>
    <div fxFlex="nogrow">
      <button mat-raised-button (click)="updateContract()" *ngIf="contract.id != null" [disabled]="!contractForm.form.valid" disabled="disabled">{{'SAVE_CONTRACT' | translate: {Default: 'Save Contract'} }}</button>&nbsp;
      <button *ngIf="isContractModal" mat-raised-button (click)="closeModal()">{{'CLOSE' | translate}}</button>
    </div>

  </div>
</div>
