<ng-container *ngIf="reminderDef">
  <form fxLayout="column" fxLayoutAlign="space-around" #reminderForm="ngForm" class="mdc-mb-2">
    <mat-tab-group (selectedTabChange)="selectedTabChange($event)">
      <mat-tab [label]="'SCHEDULE' | translate">

        <div fxLayout="row" fxLayoutGap="2%" fxLayoutAlign=" center" class="mt-1">
          <div fxFlex="100" fxFlex.gt-md="23.5">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>{{'REMINDER_NAME' | translate}}</mat-label>
              <input matInput placeholder="{{'NAME' | translate}}" name="name" #name="ngModel"
                [(ngModel)]="reminderDef.name" required readonly [readonly]="true">
            </mat-form-field>
          </div>
          <div fxFlex="100" fxFlex.gt-md="23.5">
            <mat-checkbox [name]="'ENABLED' | translate" #enabled="ngModel" [(ngModel)]="reminderDef.enabled"
              [checked]="reminderDef.enabled" class="mdc-mb-1">
              {{'ENABLED' | translate}}
            </mat-checkbox>
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="2%" class="mt-1">
          <div fxFlex="100" fxFlex.gt-md="48.5">

            <table
              *ngIf="reminderDef && reminderDef.reminderScheduleList && reminderDef.reminderDefinitionType !== 'PET_BIRTHDAY'"
              class="w-100">
              <tr *ngFor="let schedule of reminderDef.reminderScheduleList; index as i">
                <td *ngIf="schedule.daysBeforeSchedule" style="padding-right: 10px;">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label *ngIf="reminderDef.reminderDefinitionType === 'VACCINATION'">{{'NUMBER_DAYS_BEFORE' |
                      translate}}</mat-label>
                    <mat-label *ngIf="reminderDef.reminderDefinitionType === 'DEPOSIT'">{{'NUMBER_DAYS_PAST_DEPOSIT_DUE'
                      | translate}}</mat-label>
                    <mat-label
                      *ngIf="reminderDef.reminderDefinitionType !== 'VACCINATION' && reminderDef.reminderDefinitionType !== 'DEPOSIT'">
                      {{'NUMBER_DAYS_BEFORE' |translate}}</mat-label>
                    <input matInput [placeholder]="'NUMBER_DAYS_BEFORE' | translate" name="numDaysBefore{{i}}"
                      #numDaysBefore{{i}}="ngModel" [(ngModel)]="schedule.daysBeforeSchedule.numDaysBefore"
                      type="number" min="1" oninput="this.value =
                      !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null" required>
                  </mat-form-field>
                </td>

                <td *ngIf="schedule.daysAfterSchedule" style="padding-right: 10px;">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'NUMBER_DAYS_AFTER' | translate}}</mat-label>
                    <input matInput [placeholder]="'NUMBER_DAYS_AFTER' | translate" name="numDaysAfter{{i}}"
                      #numDaysAfter{{i}}="ngModel" [(ngModel)]="schedule.daysAfterSchedule.numDaysAfter" type="number"
                      min="1" oninput="this.value =
                      !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null" required>
                  </mat-form-field>
                </td>

                <td>
                  <app-time-select [placeholder]="'TIME_TO_SEND_REMINDER' | translate" [(date)]="timeToSendArr[i]"
                    (dateChange)="updateTime($event, schedule)"></app-time-select>
                </td>

                <td>
                  <button (click)="deleteSchedule(i)" class="mdc-mb-1" mat-icon-button>
                    <span class="mat-button-wrapper">
                      <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">delete</mat-icon>
                    </span>
                    <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
                    <div class="mat-button-focus-overlay"></div>
                  </button>
                </td>
              </tr>
            </table>
          </div>
          <div fxFlex="100" fxFlex.gt-md="48.5">
            <button *ngIf="reminderDef && reminderDef.reminderDefinitionType != 'PET_BIRTHDAY'" mat-button
              color="primary" (click)="addScheduleToList();" class="mt-1">
              <mat-icon class="mat-24">add</mat-icon> {{'ADD_NEW' | translate}}
            </button>
          </div>
        </div>

      </mat-tab>
      <mat-tab [label]="'EMAIL' | translate">
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>{{'EMAIL_SUBJECT' | translate}}</mat-label>
          <input matInput placeholder="{{'EMAIL_SUBJECT' | translate}}" name="emailSubject" #emailSubject="ngModel"
            [(ngModel)]="reminderDef.emailReminderDetails.subject">
        </mat-form-field>
        <div fxLayout="column">
          <app-boarding-quick-links-editor *ngIf="reminderDef && reminderDef.reminderDefinitionType === 'PET_BOARDING'"
            #emailQuicklinksMessageEditor [selectedLinks]="reminderDef?.emailReminderDetails?.links"
            [(editorContent)]="reminderDef.emailReminderDetails.body" messageType="EMAIL">
          </app-boarding-quick-links-editor>
          <app-appointment-quick-links-editor
            *ngIf="reminderDef && reminderDef.reminderDefinitionType === 'APPOINTMENT'" #emailQuicklinksMessageEditor
            [selectedLinks]="reminderDef?.emailReminderDetails?.links"
            [(editorContent)]="reminderDef.emailReminderDetails.body" messageType="EMAIL">
          </app-appointment-quick-links-editor>
          <app-vaccination-quick-links-editor
            *ngIf="reminderDef && reminderDef.reminderDefinitionType === 'VACCINATION'" #emailQuicklinksMessageEditor
            [(editorContent)]="reminderDef.emailReminderDetails.body" messageType="EMAIL">
          </app-vaccination-quick-links-editor>
          <app-pet-quick-links-editor *ngIf="reminderDef && reminderDef.reminderDefinitionType === 'PET_BIRTHDAY'"
            #emailQuicklinksMessageEditor [(editorContent)]="reminderDef.emailReminderDetails.body" messageType="EMAIL">
          </app-pet-quick-links-editor>
          <app-deposit-quick-links-editor *ngIf="reminderDef && reminderDef.reminderDefinitionType === 'DEPOSIT'"
            #emailQuicklinksMessageEditor [(editorContent)]="reminderDef.emailReminderDetails.body" messageType="EMAIL">
          </app-deposit-quick-links-editor>
          <app-customer-quick-links-editor
            *ngIf="reminderDef && reminderDef.reminderDefinitionType === 'CUSTOMERS_LAST_INVOICE'"
            #emailQuicklinksMessageEditor [(editorContent)]="reminderDef.emailReminderDetails.body" messageType="EMAIL">
          </app-customer-quick-links-editor>
        </div>
      </mat-tab>
      <mat-tab [label]="'SMS' | translate">
        <div fxLayout="column">
          <h6>{{'SMS_BODY' | translate}}</h6>
          <app-appointment-quick-links-editor #quickLinksEditorV2Component
            [selectedLinks]="reminderDef?.smsReminderDetails?.links"
            *ngIf="reminderDef && reminderDef.reminderDefinitionType === 'APPOINTMENT'|| reminderDef.reminderDefinitionType === 'PET_BOARDING'"
            [(editorContent)]="reminderDef.smsReminderDetails.body" messageType="SMS">
          </app-appointment-quick-links-editor>
          <app-vaccination-quick-links-editor #quickLinksEditorV2Component
            *ngIf="reminderDef && reminderDef.reminderDefinitionType === 'VACCINATION'"
            [(editorContent)]="reminderDef.smsReminderDetails.body" messageType="SMS">
          </app-vaccination-quick-links-editor>
          <app-customer-quick-links-editor #quickLinksEditorV2Component
            *ngIf="reminderDef && reminderDef.reminderDefinitionType === 'CUSTOMERS_LAST_INVOICE'"
            [(editorContent)]="reminderDef.smsReminderDetails.body" messageType="SMS">
          </app-customer-quick-links-editor>
          <app-deposit-quick-links-editor #quickLinksEditorV2Component
            *ngIf="reminderDef && reminderDef.reminderDefinitionType === 'DEPOSIT'"
            [(editorContent)]="reminderDef.smsReminderDetails.body" messageType="SMS">
          </app-deposit-quick-links-editor>
          <app-pet-quick-links-editor #quickLinksEditorV2Component
            *ngIf="reminderDef && reminderDef.reminderDefinitionType === 'PET_BIRTHDAY'"
            [(editorContent)]="reminderDef.smsReminderDetails.body" messageType="SMS">
          </app-pet-quick-links-editor>

        </div>
      </mat-tab>
    </mat-tab-group>

    <!-- <div *ngIf="templateType === 'email'" fxLayout="column" fxLayoutAlign="left" class="mt-1">
    </div>
    <div *ngIf="templateType === 'sms'" fxLayout="column" fxLayoutAlign="left" class="mt-1">
    </div> -->
  </form>
  <div>
    <button mat-raised-button class=" " color="accent" *ngIf="!reminderDef.id" (click)="create()"
      [disabled]="!reminderForm.form.valid">{{'CREATE' | translate}}
    </button>
    <button mat-raised-button class=" " color="accent" *ngIf="reminderDef.id" (click)="update()"
      [disabled]="!reminderForm.form.valid">{{'UPDATE' | translate}}
    </button>
  </div>
</ng-container>
