import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContextIdDto } from '@savvy/view-definition';
import { EventBusService } from '../EventBusService';

export interface AddCustomerDialogData {
  customerId: string;
  contextIdDto: ContextIdDto;
  eventBus: EventBusService;
}

@Component({
  selector: 'app-add-customer-dialog',
  templateUrl: './addCustomerDialog.component.html'
})
export class AddCustomerDialogComponent {

  contextIdDto: ContextIdDto;
  public eventBus: EventBusService;

  constructor(
    public dialogRef: MatDialogRef<AddCustomerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddCustomerDialogData) {
    this.contextIdDto = data.contextIdDto;
    this.eventBus = data.eventBus;
  }
  customerCreated(customerId: string) {
    console.log('in customer dialog customer created, customer id is ' + customerId);
    this.data.customerId = customerId;
  }
}
