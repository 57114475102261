<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span>
      {{'CARD_DETAILS_AND_PAYMENT' | translate}}
    </span>
    <span class="spacer"></span>
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>
<div #dropin id="dropin-container"></div>
<br><br>
