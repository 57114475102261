<div fxLayout="row" fxLayout.gt-sm="row" fxLayoutGap="16px" fxLayoutAlign=" start">
  <div fxFlex="0px" fxFlex.gt-sm="50px" fxHide fxShow.gt-sm>
    <div class="avatar avatar-50 circle border-3">
      <mat-icon>payments</mat-icon>
    </div>
  </div>

  <!-- <div class="subtotal-amount">{{totalAmount | currency:currencyCode:'symbol'}}</div>
  <div class="subtotal-label">Sub Total</div> -->
  <div fxFlex="calc(100% - 70px)">
    <h5>{{'PAYMENT' | translate}}</h5>
    <div class="mdc-mb-2">
      <mat-radio-group id="appointment-payment-modes" aria-label="Select an option" [(ngModel)]="paymentConfig.paymentMode" (ngModelChange)="paymentModeChanged($event)">
        <mat-radio-button [value]="paymentModeEnum.DepositRequest">{{'DEPOSIT_REQUEST' | translate}}</mat-radio-button>
        <mat-radio-button [value]="paymentModeEnum.PaymentPlan">{{'PAYMENT_PLAN' | translate}}</mat-radio-button>
        <mat-radio-button [value]="paymentModeEnum.InPerson">{{'IN_PERSON' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
    <div *ngIf="paymentConfig.paymentMode === paymentModeEnum.DepositRequest" class="mdc-mb-0">
      <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutGap.gt-sm="16px">
        <div fxFlex="46" fxFlex.gt-sm="nogrow">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{'PAYMENT_VALUE' | translate}}</mat-label>
            <mat-select id="appointment-payment-type-deposit" [(ngModel)]="paymentConfig.paymentType" (ngModelChange)="paymentTypeChanged($event)">
              <mat-option [value]="paymentTypeEnum.Percentage">{{'PERCENTAGE' | translate}}</mat-option>
              <mat-option [value]="paymentTypeEnum.Amount">{{'AMOUNT' | translate}}</mat-option>
              <mat-option [value]="paymentTypeEnum.FullAmount">{{'FULL_AMOUNT' | translate}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex="46" fxFlex.gt-sm="nogrow" *ngIf="paymentConfig?.paymentType === paymentTypeEnum.Percentage">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Percentage</mat-label>
            <input id="appointment-payment-type-deposit-percentage" matInput placeholder="" [(ngModel)]="percentage" (ngModelChange)="calculateAmountDue()" appNumberOnly [decimalPoints]="2" [minValue]="0" [maxValue]="100" required>
            <span matSuffix class="mdc-px-2">%</span>
          </mat-form-field>
        </div>
        <div fxFlex="46" fxFlex.gt-sm="nogrow" *ngIf="paymentConfig?.paymentType === paymentTypeEnum.Amount">
          <mat-form-field appearance="outline" class="w-100" appNumberOnly [decimalPoints]="2">
            <mat-label>{{'AMOUNT' | translate: {Default: 'Amount'} }}</mat-label>
            <input id="appointment-payment-type-deposit-amount" matInput appNumberOnly [decimalPoints]="2" [maxValue]="totalAmount" placeholder="" [(ngModel)]="amount" (ngModelChange)="amountChanged($event)" required>
          </mat-form-field>
        </div>
      </div>
      <!-- <mat-form-field appearance="outline" class="input-fields-custom">
      <mat-label>Amount Payable</mat-label>
      <input matInput type="number" placeholder="" readonly [(ngModel)]="amount" min="0">
    </mat-form-field> -->
    </div>
    <div *ngIf="paymentConfig.paymentMode === paymentModeEnum.PaymentPlan" fxLayout="column" fxLayoutAlign="center" fxLayoutGap="8px">
      <mat-form-field *ngIf="plans && plans.length" appearance="outline" class="w-100">
        <mat-label>{{'CHOOSE_PAYMENT_PLAN' | translate: {Default: 'Choose Payment Plan'} }}</mat-label>
        <mat-select id="appointment-payment-type-payment-plan" [placeholder]="'SELECT_PAYMENT_PLAN' | translate: {Default: 'Select Payment Plan'} " name="paymentPlan" #paymentPlanField="ngModel" [(ngModel)]="selectedPlan" (ngModelChange)="planChanged($event)" required>
          <mat-option *ngFor="let plan of plans" [value]="plan">
            {{plan.planName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-card-subtitle *ngIf="plans && plans.length && selectedPlan?.unlimitedSessions">
        {{'PLAN_HAS_NO_RESTRICTIONS' | translate: {Default: 'This plan has no restrictions on how many times it can be used.'} }}
      </mat-card-subtitle>
      <mat-card-subtitle *ngIf="plans && plans.length && selectedPlan?.sessionsRemaining">
        {{'ONLY' | translate}} {{selectedPlan.sessionsRemaining}}
        {{'NUMBER_OF_SESSIONS_REMAINING' | translate: {Default: 'Number of sessions remaining: '} }}
      </mat-card-subtitle>

      <div *ngIf="plans && !plans.length">
        <mat-card appearance="outlined" class="">
          <mat-card-content>
            <h6>{{'NO_PLANS_FOR_SELECTED_CUSTOMER' | translate: {Default: 'There are no plans available for the selected customer.'} }}</h6>
            <hr class="mdc-mb-1 w-100">
            <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="16px">
              <div fxFlex="100" fxFlex.gt-sm="50">
                <mat-form-field appearance="outline" class="nomarginbottom">
                  <mat-label>{{'ASSIGN_PLAN' | translate: {Default: 'Assign Plan'} }}</mat-label>
                  <mat-select [placeholder]="'ASSIGN_PLAN' | translate: {Default: 'Assign Plan'} " name="planDefinition" #planDefinition="ngModel" [(ngModel)]="selectedPlanDefinition" required (ngModelChange)="planDefinitionChange($event)">
                    <mat-option *ngFor="let planDefinition of planDefinitions" [value]="planDefinition">
                      {{planDefinition.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div fxFlex=>
                <mat-card-subtitle>
                  {{'SUITABLE_PLAN_NOT_LISTED' | translate: {Default: 'Suitable plan not listed here?'} }}. {{'CREATE_NEW_PAYMENT_PLAN' | translate: {Default: 'Create a new payment plan'} }}
                  <a (click)="goToPlans()" class="mat-text-accent">{{'PAYMENT_PLANS' | translate: {Default: 'Payment Plans'} }}</a>
                </mat-card-subtitle>
              </div>
            </div>
            <mat-card-subtitle class="mt-1">
              {{'CUSTOMER_PAYMENT_PLAN_INVOICE_EXPLANATION' | translate: {Default: 'The customer will be sent an invoice for the assigned payment plan. Once the invoice has been paid, you will receive a notification from which you can confirm the appointment.'} }}
            </mat-card-subtitle>
          </mat-card-content>
        </mat-card>
      </div>

      <mat-form-field *ngIf="plans && plans.length && selectedPlan?.sessionsRemaining" appearance="outline" class="input-fields-custom" appNumberOnly [decimalPoints]="2" class="w-100">
        <mat-label>{{'SESSIONS_TO_REDEEM' | translate: {Default: 'Sessions To Redeem'} }}</mat-label>
        <input matInput appNumberOnly placeholder="" [maxValue]="selectedPlan?.sessionsRemaining" [(ngModel)]="paymentConfig.noOfSessionsToRedeem" required>
      </mat-form-field>
    </div>

    <div *ngIf="paymentConfig.paymentMode === paymentModeEnum.DepositRequest && customerDto && customerDto.mobilePhoneNumberObj" class="mdc-mb-1">
      <mat-slide-toggle id="appointment-req-payment-in-sms" color="accent" *ngIf="hasDepositSmsTemplate" [(ngModel)]="paymentConfig.sendSms" name="sendSms" #sendSms="ngModel">
        <span class="ml-1">{{'SHOULD_SEND_PAYMENT_LINK_BY_SMS' | translate: {Default: 'Send Payment Link by SMS?'} }}</span>
      </mat-slide-toggle>
      <mat-hint *ngIf="!hasDepositSmsTemplate">
        <p>Configure your Deposit Request SMS Template for you to send payment link via sms</p>
      </mat-hint>
      <!--      <mat-slide-toggle *ngIf="paymentConfig.paymentMode === paymentModeEnum.InPerson && paymentConfig.sendSms" color="accent"-->
      <!--                        [(ngModel)]="paymentConfig.preAuth"-->
      <!--                        name="preAuth"-->
      <!--                        #preAuth="ngModel"-->
      <!--      >-->
      <!--        <span class="ml-1">{{'Pre-authorization?' | translate}}</span>-->
      <!--      </mat-slide-toggle>-->

    </div>
    <mat-card-subtitle *ngIf="paymentConfig.paymentMode === paymentModeEnum.InPerson
     && paymentConfig.sendSms
     && customerDto
     && customerDto.mobilePhoneNumberObj
     && !savvyPayAccountHolder?.paymentDisabled">
      {{'PRE_AUTH_EXPLANATION' | translate: {Default: 'If pre-authorisation is selected, card details will be stored and payment will be deducted upon appointment completion.'} }}
    </mat-card-subtitle>

  </div>
</div>

<!-- <div class="mt-1">
    <h5>Amount Payable</h5>
    <h5>{{paymentConfig.amountDue | currency:currencyCode:'symbol'}}</h5>
  </div> -->
