<div class="container mt-1" *ngIf="viewAppointmentData?.viewAppointmentDataEmbeddeds">
  <div *ngFor="let viewAppointmentHistoryDataEmbedded of viewAppointmentData.viewAppointmentDataEmbeddeds">
    <div class="row" *ngIf="viewAppointmentHistoryDataEmbedded.label">
      <div class="col">
        <h3>{{viewAppointmentHistoryDataEmbedded.label}}</h3>
      </div>
      <div class="col-auto"></div>
    </div>
    <app-view-appointment-history-embedded [viewAppointmentHistoryDataEmbedded]="viewAppointmentHistoryDataEmbedded" [contextIdDto]="contextIdDto" [eventBus]="eventBus" [viewContext]="viewContext">
    </app-view-appointment-history-embedded>
  </div>
</div>