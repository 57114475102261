<mat-card appearance="outlined" *ngIf="listEntityListDto.rows.length > 0">
  <mat-card-title>
    <div fxLayout="row">
      <div fxFlex="80">
        <p class="mt-xs mb-xs">{{listEntityListDto.name | translate}}</p>
      </div>
      <div fxFlex="20">
        <button mat-mini-fab color="accent" [disabled]="trialExpiredService.isTrialExpired()" (click)="create()">
          <mat-icon class="mat-24">add</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-title>
  <mat-card-content>
    <mat-list>
      <mat-list-item *ngFor="let row of listEntityListDto.rows">
        <p matLine (click)="rowSelected(row.entityInstanceId)" *ngIf="!row.displayText || row.displayText.length === 0">{{listEntityListDto.name | translate}}</p>
        <p matLine (click)="rowSelected(row.entityInstanceId)" *ngIf="row.displayText && row.displayText.length > 0">{{row.displayText | translate}}</p>
        <mat-divider *ngIf="listEntityListDto.rows.length > 1"></mat-divider>
      </mat-list-item>
    </mat-list>

  </mat-card-content>
</mat-card>

<mat-card appearance="outlined" *ngIf="listEntityListDto.rows.length === 0" class="full-height">

  <mat-card-content>
    <div class="flexbox-parent">
      <div fxLayout="row" fxLayoutAlign="center center">
        <div style="text-align:center;">
          <app-zero-image [name]="listEntityListDto.iconName"></app-zero-image><br>
          <h5 class="ma-0">{{'You don’t have any ' + listEntityListDto.name | translate}}</h5><br>
          <!-- *ngIf="listEntityListDto.canCreate" -->
          <button mat-raised-button color="accent" [disabled]="trialExpiredService.isTrialExpired()" (click)="create()">{{'New' | translate}} {{listEntityListDto.name | translate}}</button>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>