import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { EventBusService } from '../element/EventBusService';
import { MatSidenav } from '@angular/material/sidenav';
import { ViewcompService } from '@savvy/view-composite';
import { ChangeListener } from '../element/changeListener';
import { UntypedFormGroup } from '@angular/forms';
import { TrialExpiredService } from '../shared/services/trial-expired.service';
import { ContextIdDto } from '@savvy/datalist';

@Component({
  selector: 'app-view-customer',
  templateUrl: 'viewCustomer.html'
})
export class ViewCustomerComponent implements OnInit {

  @ViewChild('sidePanel', { static: false }) sidePanel: MatSidenav;
  @Output() created = new EventEmitter();

  @Input() contextIdDto: ContextIdDto;
  @Input() visible: boolean;

  public eventBus = new EventBusService();
  public changeListener = new ChangeListener();
  loadViewResponse: any;
  public form: UntypedFormGroup; // our form model
  private sub: any;


  constructor(
    private route: ActivatedRoute,
    public trialExpiredService: TrialExpiredService,
    private router: Router,
    private api: ViewcompService,
    private notify: FloSnackbarComponent) {
  }

  ngOnInit() {

    console.log('inside on init ViewCustomerComponent');
    this.eventBus.eventAdded$.subscribe(event => this.onEvent());

    this.sub = this.route.params.subscribe(params => {
      this.contextIdDto = <ContextIdDto>{};
      this.contextIdDto.contextId = params['contextId'];
      this.contextIdDto.contextIdType = params['contextIdType'];

      console.log('customerId is ' + params['customerId']);
      console.log('contextId is ' + params['contextId']);
      if (params['customerId']) {

        this.api.loadCustomerToView(
          params['customerId'],
          this.contextIdDto.contextId,
          this.contextIdDto.contextIdType).subscribe(loadViewResponse => {
            this.loadViewResponse = loadViewResponse;
          });
      }
    });
  }

  create() {
    console.log('inside create');
    this.router.navigate(['/customer/addCustomer',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType]);
  }

  private onEvent(): void {
    console.log('view customer on event');
  }
}
