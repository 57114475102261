<div [ngClass]="{'mobile-focused': doSearch}" id="global-search-bar" class="search-form">
  <mat-icon (click)="searchFocus()">search</mat-icon>
  <input [ngClass]="{hide: deviceDetectorService.isMobile() && !doSearch}" id="global-search-bar-input" type="text"
    placeholder="{{'SEARCH' | translate}}" aria-label="Search" [matAutocomplete]="auto" [formControl]="stateCtrl">
  <button [ngClass]="{hide: deviceDetectorService.isMobile() && !doSearch}" id="clear-search-button"
    *ngIf="stateCtrl.value" class="vm clear-icon" mat-button matSuffix (click)="clearInput()">{{'CLEAR' |
    translate}}</button>
  <!-- <mat-icon *ngIf="stateCtrl.value" matSuffix class="vm clear-icon" (click)="clearInput()">
    close
  </mat-icon> -->
</div>
<mat-autocomplete #auto="matAutocomplete">
  <mat-optgroup *ngFor="let group of searchResultGroups" [label]="group.groupName | translate">
    <mat-option *ngFor="let option of group.searchResultInstanceDtos" [value]="option.value"
      (click)="gotoInstance(option);" (onSelectionChange)="gotoInstance(option)" [title]="option.value">
      {{ option.value | translate }}
    </mat-option>
  </mat-optgroup>
  <!-- <mat-optgroup>
    <mat-option *ngFor="let menuSearchResult of menuSearchResults">

    </mat-option>
  </mat-optgroup> -->
</mat-autocomplete>