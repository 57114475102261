import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { ContextIdDto } from '@savvy/app';
import { RoleId } from '@savvy/ui';
import {
  ActionDefinition,
  DeleteWorkflowDefinition,
  Links,
  ListWorkflowDefinitionsForEditDto,
  LoadWorkflowDefinitionForEditResponse,
  LoadWorkflowStateResponse,
  MoveStateInWorkflow,
  RemoveStateFromWorkflow, SendMessageDefinition, SendSmsDefinition,
  TaskSettings,
  WorkflowState,
  WorkflowTaskDefinition,
  WorkflowdefService
} from '@savvy/workflow-definition';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { APP_TYPE_ENUM, ContextService } from '../../context.service';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';
import { AddEditWorkflowDialogComponent } from '../add-edit-workflow-dialog/add-edit-workflow-dialog.component';
import { AddEditWorkflowStateDialogComponent } from '../add-edit-workflow-state-dialog/add-edit-workflow-state-dialog.component';
import {
  AddEditWorkflowStateColorDialogComponent
} from '../edit-workflow-state-color-dialog/add-edit-workflow-state-color-dialog.component';

@Component({
  selector: 'app-workflow-details',
  templateUrl: './workflow-details.component.html',
  styleUrls: ['./workflow-details.component.scss']
})
export class WorkflowDetailsComponent implements OnInit, OnDestroy {

  @ViewChild('sidePanel', { static: true }) sidePanel: MatSidenav;
  @Input() workflowDefinitionId: string;

  loadWorkflowDefinitionForEditResponse: LoadWorkflowDefinitionForEditResponse;
  response: ListWorkflowDefinitionsForEditDto;
  selectedTaskDef: WorkflowTaskDefinition;
  workflowTypeLabel = '';
  selectedWorkflowState: WorkflowState;
  selectedStateId: string;
  allowEditState = true; // ALWAYS DEFAULT THIS TO FALSE!!!
  allowAddState = false; // ALWAYS DEFAULT THIS TO FALSE!!!
  appType: string;
  contextIdDto: ContextIdDto;
  subscriptions: Subscription[] = [];

  private _unsubscribeAll: Subject<any>;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private location: Location,
    private contextService: ContextService,
    private workflowdefService: WorkflowdefService,
    public notify: FloSnackbarComponent) {
    this._unsubscribeAll = new Subject();
  }

  get showButtonsForApp(): boolean {
    if (this.contextService.builderApp) {
      return true;
    }
    if (this.appType === APP_TYPE_ENUM.PET_GROOMING_SALON) {
      return false;
    }

    return true;
  }

  ngOnInit(): void {
    console.log('inside workflow details init');
    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
        this.appType = this.contextService.getAppType();

        console.log('got app type ', this.appType);

        console.log('looking for sidePanel');

        this.sidePanel.closedStart.pipe(
          takeUntil(this._unsubscribeAll),
        ).subscribe(() => {
          this.selectedTaskDef = null;
        });

        console.log('looking for route params');

        this.route.params.pipe(
          takeUntil(this._unsubscribeAll),
        ).subscribe(params => {
          if (params['allowEditState']) {
            this.allowEditState = params['allowEditState'];
          }
          if (params['allowAddState']) {
            this.allowAddState = params['allowAddState'];
          }
          if (params['contextId'] && params['contextIdType']) {
            this.contextIdDto.contextId = params['contextId'];
            this.contextIdDto.contextIdType = params['contextIdType'];
          }
          if (this.workflowDefinitionId) {
            console.log('got workflowDefinitionId', this.workflowDefinitionId);
            this.loadWorkflow();
          } else if (params['workflowDefinitionId']) {
            this.workflowDefinitionId = params['workflowDefinitionId'];
            console.log('got workflowDefinitionId', this.workflowDefinitionId);
            this.loadWorkflow();
          } else {
            console.log('ERROR: No workflow definition id');
          }
          // TODO: Set this allowEditState
        });
      }
    }));
  }

  ngOnDestroy() {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  loadWorkflow() {
    console.log('Loading workflow with ', this.workflowDefinitionId);
    this.workflowdefService.getWorkflowDefsByIdsForEdit(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      [this.workflowDefinitionId]).subscribe(response => {
        if (response) {
          this.response = response;
          console.log('got response');
          if (response.workflowDefinitionForEditResponses && response.workflowDefinitionForEditResponses.length) {
            this.loadWorkflowDefinitionForEditResponse = response.workflowDefinitionForEditResponses[0];
            this.selectedWorkflowState = this.getState(this.selectedStateId);
          } else {
            console.log('unable to init as no workflowDefinitionForEditResponses in', response);
          }
        }
      });
  }

  editWorkflowBtnClick() {
    const editWorkflowStateDialogRef = this.dialog.open(AddEditWorkflowDialogComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        workflowDefinition: this.loadWorkflowDefinitionForEditResponse
      },
      height: 'auto',
      width: '600px',
    });

    editWorkflowStateDialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.response) {
        this.loadWorkflow();
      }
    });
  }

  addStateBtnClick() {
    const createWorkflowStateDialogRef = this.dialog.open(AddEditWorkflowStateDialogComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        workflowDefinitionId: this.workflowDefinitionId,
        allowEditState: this.allowEditState
      },
      height: 'auto',
      width: '600px',
    });

    createWorkflowStateDialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.response) {
        this.loadWorkflow();
      }
    });
  }

  deleteWorkflowBtnClick() {
    /* const confirmDialog = this.dialog.open(ConfirmationDialogComponent, {
       data: {
         title: 'Delete',
         message: 'Do you want to delete workflow!'
       },
       height: 'auto',
       width: '250px',
     });

     confirmDialog.afterClosed().subscribe((result: boolean) => {
       if (result) {
         this.deleteWorkflowDef();
       }
     });*/
  }

  deleteWorkflowDef() {
    const deleteRequest: DeleteWorkflowDefinition = {
      contextIdDto: this.contextIdDto,
      workflowDefinitionId: this.workflowDefinitionId
    };
    this.workflowdefService.deleteWorkflowDefinition(deleteRequest).subscribe(response => {
      if (response) {
        this.notify.message = 'Workflow deleted successfully.';
        this.notify.open();
        this.location.back();

        //        this.router.navigate(['/workflows']);
      }
    }, () => {
      this.notify.message = 'Failed to delete workflow.';
      this.notify.open();
    });
  }

  addTaskToStateClick(state: LoadWorkflowStateResponse, actionType?: ActionDefinition.ActionTypeEnum) {
    this.selectedWorkflowState = state.workflowState;
    this.selectedStateId = state.workflowState.id;
    this.selectedTaskDef = <WorkflowTaskDefinition>{};
    this.initialise(this.selectedTaskDef);
    if (actionType) {
      console.log('Now setting action type', actionType);
      this.selectedTaskDef.actionDefinition.actionType = actionType;
      if (actionType === ActionDefinition.ActionTypeEnum.SendSms) {
        console.log('Initialising sms definition', actionType);
        this.selectedTaskDef.actionDefinition.sendSmsDefinition = <SendSmsDefinition>{};
        this.selectedTaskDef.actionDefinition.sendSmsDefinition.message = '';
        this.selectedTaskDef.actionDefinition.sendSmsDefinition.links = <Links>{};

      }
    }
    this.sidePanel.open();
  }

  onTaskSelect(taskDef: WorkflowTaskDefinition, state: LoadWorkflowStateResponse) {
    this.selectedWorkflowState = state.workflowState;
    this.selectedStateId = state.workflowState.id;
    this.selectedTaskDef = taskDef;
    this.initialise(this.selectedTaskDef);
    this.sidePanel.open();
  }

  editState(state: LoadWorkflowStateResponse) {

    let createWorkflowStateDialogRef = null;

    if (this.allowAddState) {
      createWorkflowStateDialogRef = this.dialog.open(AddEditWorkflowStateDialogComponent, {
        data: {
          contextIdDto: this.contextIdDto,
          allowEditState: this.allowEditState,
          workflowDefinitionId: this.workflowDefinitionId,
          state
        },
        height: 'auto',
        width: '600px',
      });
    } else {
      createWorkflowStateDialogRef = this.dialog.open(AddEditWorkflowStateColorDialogComponent, {
        data: {
          contextIdDto: this.contextIdDto,
          workflowDefinitionId: this.workflowDefinitionId,
          workflowState: state.workflowState
        },
        height: 'auto',
        width: '600px',
        panelClass: ['helpwindow']
      });
    }

    createWorkflowStateDialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.response) {
        this.loadWorkflow();
      }
    });
  }

  deleteStateBtnClick(state: LoadWorkflowStateResponse) {
    const confirmDialog = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete',
        message: 'Do you want to delete workflow state!'
      },
      height: 'auto',
      width: '250px',
      panelClass: 'helpwindow'
    });

    confirmDialog.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.deleteState(state);
      }
    });
  }

  deleteState(state: LoadWorkflowStateResponse) {
    const removeWf: RemoveStateFromWorkflow = {
      contextIdDto: this.contextIdDto,
      workflowDefinitionId: this.workflowDefinitionId,
      stateId: state.workflowState.workflowStateId.id
    };
    this.workflowdefService.removeStateFromWorkflow(removeWf).subscribe(response => {
      if (response) {
        this.notify.message = 'State successfully deleted.';
        this.notify.open();
        this.loadWorkflow();
      }
    }, () => {
      this.notify.message = 'State failed to delete.';
      this.notify.open();
    });
  }

  taskRemoved() {
    this.loadWorkflow();
    this.sidePanel.close();
  }

  loadWorkflowState() {
    this.loadWorkflow();
  }

  getState(stateId: string): WorkflowState {
    let workflowState: WorkflowState = null;
    if (stateId) {
      const loadWorkflowStateResponse: LoadWorkflowStateResponse =
        this.loadWorkflowDefinitionForEditResponse.loadWorkflowStateResponseList.find(v => v.workflowState.id === stateId);
      if (loadWorkflowStateResponse) {
        workflowState = loadWorkflowStateResponse.workflowState;
      }
    }
    return workflowState;
  }

  initialise(taskDef: WorkflowTaskDefinition) {
    if (!taskDef.taskSettings) {
      taskDef.taskSettings = <TaskSettings>{};
      taskDef.taskSettings.taskAssignToRoles = <RoleId>{};
    }
    if (!taskDef.postTaskDefinition) {
      console.log('initialising post task def');
      taskDef.postTaskDefinition = <any>{};
    }
    if (!taskDef.autoTaskDefinition) {
      taskDef.autoTaskDefinition = <any>{};
    }
    if (!taskDef.manualTaskDefinition) {
      taskDef.manualTaskDefinition = <any>{};
    }
    if (!taskDef.actionDefinition) {
      taskDef.actionDefinition = <ActionDefinition>{};
      taskDef.actionDefinition.actionType = ActionDefinition.ActionTypeEnum.SendMessage;
      taskDef.actionDefinition.sendMessageDefinition = <SendMessageDefinition>{};
    }
  }


  drop(event: CdkDragDrop<string[]>) {
    if (event.item?.data?.workflowState?.id) {
      moveItemInArray(this.loadWorkflowDefinitionForEditResponse?.loadWorkflowStateResponseList, event.previousIndex, event.currentIndex);
      const moveStateInWorkflow: MoveStateInWorkflow = {
        contextIdDto: this.contextIdDto,
        newPosition: event.currentIndex,
        stateId: event.item.data.workflowState.id,
        workflowDefinitionId: this.workflowDefinitionId
      };
      this.workflowdefService.moveStateInWorkflow(moveStateInWorkflow).subscribe(res => {
        this.notify.message = 'State position updated successfully';
        this.notify.open();
        if (res && res.newWorkflowState) {
          this.loadWorkflow();
        }
      });
    }
  }

  public colorInversionByHexString(colorString: string) {
    if (!colorString) {
      return '#000000';
    }

    colorString = colorString.replace('#', '');
    if (colorString.length == 6) {
      const r = parseInt(colorString.substring(0, 2), 16);
      const g = parseInt(colorString.substring(2, 4), 16);
      const b = parseInt(colorString.substring(4, 6), 16);
      const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
      return (yiq >= 128) ? '#000000' : '#FFFFFF';
    } else {
      return '#000000';
    }
  }

  backToList() {
    this.router.navigate(['/admin/settings',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType, 'messaging']);
  }



  // onSidenavClose() {
  //  console.log('onSidenavClose');
  // }

}
