import { Injectable } from '@angular/core';
import { Appointment } from '@savvy/appointment';
import { Location } from '@savvy/location';
import { Pet, PetService } from '@savvy/pet';
import { PetSearchResultDto } from '@savvy/search/model/petSearchResultDto';
import { ConsumerService } from '@savvy/services';
import { ContextIdDto, IdNameTupleDto, UserDto } from '@savvy/user';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { DateUtils } from '../../dates/DateUtils';
import { AppointmentConfigHelperService } from '../../shared/services/appointment-config-helper.service';
import { LookAndFeelSharedService, TimeValue } from '../../shared/services/look-and-feel-shared.service';
import { OrgUsersHelperService } from '../../shared/services/org-users-helper.service';
import { AppointmentServices, SelectedServices, ServiceSelectorData } from '../shared/extra-types';
import { AppointmentDateLocal } from './repeat-appointments/repeat-filter/repeat-filter.component';

export class PopulateNewPetEventData {
  petSearchResultDto: PetSearchResultDto;
  populatingAppointment: boolean;
  rebooking: boolean;
}
@Injectable({
  providedIn: 'root'
})
export class CreatePetAppointmentV2SyncService {

  petsSelected: PetSearchResultDto[] = [];
  petsSelectedChips: string[] = [];
  selectedServices: SelectedServices = {};
  selectedLocation: Location = {};
  multiLocationEnabled = false;

  startDate = new Date();
  endDate = new Date();
  startDateReq = '';
  endDateReq = '';
  startTime = '';
  endTime = '';
  displayStartTime: Date;
  displayEndTime: Date;

  editMode = false;
  appointment: Appointment;
  groupedServicesWithPetId: AppointmentServices = {};

  userToUseWhenCreating: IdNameTupleDto;

  totalAmount = 0;
  totalAmountDue = 0;
  totalServices = 0;

  timeArray: TimeValue[] = [];

  private petSelectionChangeSource: Subject<PetSearchResultDto[]> = new Subject();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public petSelectionChanged$ = this.petSelectionChangeSource.asObservable();

  private populateNewPetSource: Subject<PopulateNewPetEventData> = new Subject();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public populateNewPet$ = this.populateNewPetSource.asObservable();

  private createPetSource: Subject<boolean> = new Subject();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public createPet$ = this.createPetSource.asObservable();

  private totalAmountChangedSource: Subject<number> = new Subject();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public totalAmountChanged$ = this.totalAmountChangedSource.asObservable();

  private selectedLocationSource: Subject<Location> = new Subject();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectedLocation$: Observable<Location> = this.selectedLocationSource.asObservable();


  constructor(
    private petService: PetService,
    private lookAndFeelSharedService: LookAndFeelSharedService,
    private orgUsersHelperService: OrgUsersHelperService,
    private dateUtils: DateUtils,
    public appointmentConfig: AppointmentConfigHelperService,

  ) {

  }

  setSelectedLocation(location: Location) {
    this.selectedLocation = Object.assign({}, location);
    this.selectedLocationSource.next(this.selectedLocation);
  }

  setTimeArray(contextIdDto) {
    if (contextIdDto) {
      const lookAndFeelConfig = this.lookAndFeelSharedService.getLookAndFeelConfig(contextIdDto);
      if (lookAndFeelConfig) {
        // const lookAndFeel = lookAndFeelConfig.lookAndFeel;
        // this.minuteInterval = this.lookAndFeelConfig.minuteInterval;
        this.timeArray = lookAndFeelConfig.timeArray;
      }
    }
  }

  updatePetSelection(newPets: PetSearchResultDto[]) {
    this.petsSelected = newPets;
    this.petSelectionChangeSource.next(this.petsSelected);
  }

  dateFromTime(dStr) {
    const now = new Date();
    if (dStr) {
      now.setHours(dStr.substr(0, dStr.indexOf(':')));
      now.setMinutes(dStr.substr(dStr.indexOf(':') + 1));
      now.setSeconds(0);
    }
    return now;
  }

  setAppointmentDates(startDate: Date, endDate: Date, startTime: string, endTime: string) {
    console.log('inside setAppointmentDates we have startDate', startDate);
    if (startDate) {
      this.startDate = new Date(startDate);
      console.log('inside setAppointmentDates NOW we have startDate', startDate);
      this.startDateReq = this.dateUtils.getDateAsStringDash(startDate);
    }
    if (endDate) {
      this.endDate = new Date(endDate);
      this.endDateReq = this.dateUtils.getDateAsStringDash(endDate);
    }
    this.startTime = startTime;
    this.endTime = endTime;
    this.displayStartTime = this.dateFromTime(this.startTime);
    this.displayEndTime = this.dateFromTime(this.endTime);

  }

  calculateStartEndTime() {
    let minStart = '';
    let maxEnd = '';
    if (this.petsSelected && this.petsSelected?.[0]) {
      minStart = this.selectedServices[this.petsSelected[0]?.petId]?.[0]?.startTime;
      maxEnd = this.selectedServices[this.petsSelected[0]?.petId]?.[0]?.endTime;
    }
    for (const pet of this.petsSelected) {
      for (const key in this.selectedServices[pet.petId]) {
        if (Object.prototype.hasOwnProperty.call(this.selectedServices[pet.petId], key)) {
          const selectedData = this.selectedServices[pet.petId][key] as ServiceSelectorData;
          if (minStart && selectedData.startTime < minStart) {
            minStart = selectedData.startTime;
          }
          if (maxEnd && selectedData.endTime > maxEnd) {
            maxEnd = selectedData.endTime;
          }
        }
      }
    }
    if (this.timeArray.length) {

      for (let tIndex = 0; tIndex < this.timeArray.length; tIndex++) {
        const t = this.timeArray[tIndex];
        if (minStart <= t.actualValue) {
          this.startTime = t.actualValue;
          break;
        }
      }
      this.displayStartTime = this.dateFromTime(this.startTime);

      for (let index = 0; index < this.timeArray.length; index++) {
        const t = this.timeArray[index];
        if (maxEnd && t.actualValue >= maxEnd) {
          this.endTime = t.actualValue;
          break;
        }
      }
      this.displayEndTime = this.dateFromTime(this.endTime);
    }
    this.calculateTotalAmount();
    this.setAppointmentDates(this.startDate, this.endDate, this.startTime, this.endTime);
  }

  updateServiceSelectionFromQuickView(petId: string, selectedService: ConsumerService, selectedStaff: UserDto, index = 0) {
    if (!this.selectedServices[petId]) {
      this.selectedServices[petId] = [];
      this.selectedServices[petId].push({
        selectedService: {} as ConsumerService,
        selectedStaff: { id: '', name: '' } as IdNameTupleDto
      });
    }
    this.selectedServices[petId][index].selectedService = selectedService;
    this.selectedServices[petId][index].selectedStaff.id = selectedStaff?.id;
    this.selectedServices[petId][index].quantity = 1;
    this.selectedServices[petId][index].subTotal = selectedService.unitPrice;
    this.selectedServices[petId][index].grossPrice = selectedService.unitPrice;
    this.selectedServices[petId][index].startTime = this.startTime;
    this.selectedServices[petId][index].endTime = this.dateUtils.addMinsToTimeString(this.startTime, selectedService.durationInMins);
    this.calculateTotalAmount();
  }

  clear() {
    this.petsSelected = [];
    this.petsSelectedChips = [];
    this.updatePetSelection([]);
    this.selectedServices = {};
    this.selectedLocation = {};
    this.editMode = false;
    this.appointment = null;
    this.groupedServicesWithPetId = {};
    this.userToUseWhenCreating = null;
    this.totalAmount = 0;
    this.totalAmountDue = 0;
    this.totalServices = 0;
  }

  handleCreatePetFromList() {
    this.createPetSource.next(true);
  }

  populateNewPet(newPet: Pet, populatingAppointment = false, rebooking = false) {
    const pet: PetSearchResultDto = {
      petId: newPet.id,
      petBreed: newPet.breedLabel,
      customerName: `${newPet.petOwnerFirstName} ${newPet.petOwnerLastName}`,
      ownerId: newPet.ownerId,
      petName: newPet.name,
      petOwnerId: newPet.petOwnerId,
      petNotes: newPet.petNotes,
      deleted: false
    };
    this.populateNewPetSource.next({ petSearchResultDto: pet, populatingAppointment, rebooking });
  }

  populateNewCustomer(newPet: Pet) {
    const pet: PetSearchResultDto = {
      petId: newPet.id,
      petBreed: newPet.breedLabel,
      customerName: `${newPet.petOwnerFirstName} ${newPet.petOwnerLastName}`,
      ownerId: newPet.ownerId,
      petName: newPet.name,
      petOwnerId: newPet.petOwnerId,
      petNotes: newPet.petNotes,
      deleted: false
    };
    this.petsSelected.push(pet);
    this.petsSelectedChips.push(pet.petName);
    this.petSelectionChangeSource.next(this.petsSelected);
  }

  populateAppointment(appointment: Appointment, contextIdDto: ContextIdDto, rebooking = false) {
    this.appointment = appointment;
    // 2022-03-11
    console.log('POPULATING APPOINTMENT!!', appointment.startDate);

    this.setAppointmentDates(
      this.dateUtils.convertDateStringToJsDate(appointment.startDate),
      this.dateUtils.convertDateStringToJsDate(appointment.endDate),
      appointment.startTime,
      appointment.endTime);

    if (appointment?.appointmentServiceRows?.length) {
      this.groupedServicesWithPetId = _.groupBy(appointment.appointmentServiceRows, 'eiId');
      const petIds = Object.keys(this.groupedServicesWithPetId);
      for (const petId of petIds) {
        try {
          this.petService.get(petId).subscribe((res) => {
            this.populateNewPet(res, true, rebooking);
          });
        } catch (error) {
          console.log('Pet not found');
          console.error(error);
        }
      }
    }
  }

  calculateTotalAmount() {
    this.totalAmount = 0;
    this.totalServices = 0;
    for (const key in this.selectedServices) {
      if (Object.prototype.hasOwnProperty.call(this.selectedServices, key)) {
        const selectedServices = this.selectedServices[key];
        for (const selectedService of selectedServices) {
          this.totalAmount += Number(selectedService.grossPrice);
        }
      }
    }
    if (this.totalAmountDue > this.totalAmount || this.appointment?.paymentConfig?.sendSms) {
      this.totalAmountDue = this.totalAmount;
    }
    this.totalAmountChangedSource.next(this.totalAmount);
    for (const key in this.selectedServices) {
      if (Object.prototype.hasOwnProperty.call(this.selectedServices, key)) {
        const services = this.selectedServices[key];
        this.totalServices += services.length;
      }
    }
  }

  removePet(petRowIndex: number) {
    delete this.selectedServices[this.petsSelected[petRowIndex].petId];
    this.petsSelected.splice(petRowIndex, 1);
    this.petsSelectedChips.splice(petRowIndex, 1);
    this.updatePetSelection(this.petsSelected);
    this.calculateTotalAmount();
  }

  updateStartTimeAndEndTimeByStartTime(startTime: string) {
    for (const petSelected of this.petsSelected) {
      for (const service of this.selectedServices[petSelected.petId]) {
        const startTimeMoment = moment(service.startTime ? service.startTime : this.startTime, 'HH:mm');
        const endTimeMoment = moment(service.endTime ? service.endTime : this.endTime, 'HH:mm');
        const duration = moment.duration(endTimeMoment.diff(startTimeMoment));
        const hours = duration.asHours();
        service.startTime = startTime;
        service.endTime = moment(startTime, 'HH:mm').add(hours, 'hours').format('HH:mm');
      }
    }
  }

  // eslint-disable-next-line max-len
  syncServiceStartAndEndTimeForRepeatApp(minStartTime: string, appointmentDate: AppointmentDateLocal, service: ServiceSelectorData): ServiceSelectorData {
    // find difference between start time between the service date and repeat date and apply adjustment accordinly
    const startDate = moment(appointmentDate.localStartDate).format('YYYY-MM-DD');
    const startTimeOfRepeat = moment(`${startDate} ${appointmentDate.startTime}`);
    const startTimeOfService = moment(`${startDate} ${minStartTime}`);

    const minutesDiff = moment.duration(startTimeOfRepeat.diff(startTimeOfService)).asMinutes();

    service.startTime = moment(service.startTime, 'HH:mm').add(minutesDiff, 'minutes').format('HH:mm');
    service.endTime = moment(service.startTime, 'HH:mm').add(service.selectedService.durationInMins, 'minutes').format('HH:mm');
    console.log('Final Start and end', { start: service.startTime, end: service.endTime });

    return service;
  }

  getEarliestServiceTime() {
    const times = [];
    for (const petSelected of this.petsSelected) {
      for (const service of this.selectedServices[petSelected.petId]) {
        times.push(moment(service.startTime, 'HH:mm'));
      }
    }
    return moment.min(times).format('HH:mm');
  }

  getSelectedUserIds() {
    const selectedUserIds = [];
    for (const key in this.selectedServices) {
      if (Object.prototype.hasOwnProperty.call(this.selectedServices, key)) {
        const selectedServices = this.selectedServices[key];
        for (const selectedService of selectedServices) {
          selectedUserIds.push(selectedService?.selectedStaff?.id);
        }
      }
    }
    // return unique selected user ids in all services
    return selectedUserIds.filter((item, i, ar) => ar.indexOf(item) === i);
  }

}
