import { Component, Input, OnInit } from '@angular/core';
import { ContextIdDto } from '@savvy/app';
import { Customer } from '@savvy/customer';

@Component({
  selector: 'app-customer-stats-panel',
  templateUrl: './customer-stats-panel.component.html',
  styleUrls: ['./customer-stats-panel.component.scss']
})
export class CustomerStatsPanelComponent implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() customerDto: Customer;

  constructor() { }

  ngOnInit() {
  }

}
