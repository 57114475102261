import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ChangeListener } from './changeListener';
import { TrialExpiredService } from '../shared/services/trial-expired.service';
import { Subject } from 'rxjs';
import { CheckinFormDefsService } from '@savvy/check-in-form';
import { MatDialog } from '@angular/material/dialog';
import { CheckinFormCompService } from '@savvy/check-in-form';
import { CheckinFormService } from '@savvy/check-in-form';
import { ViewCheckInFormModalComponent } from './viewCheckInFormModal.component';
import { CheckinFormDefinition } from '@savvy/check-in-form';
import { CheckinForm, ListCheckInFormsListDto } from '@savvy/view-composite';
import { ContextIdDto } from '@savvy/quickbooks';
import { LinkedIdTypeEnum, LinkId } from 'src/app/core/data-share.service';
import { CreateCheckinFormFromDefId } from "@savvy/check-in-form/model/createCheckinFormFromDefId";

@Component({
  selector: 'app-list-check-in-forms',
  templateUrl: 'listCheckInFormsList.component.html',
  styleUrls: ['commonPanel.component.scss']

})
export class ListCheckInFormsListComponent implements OnInit, OnDestroy {

  @Input() listCheckInFormsListDto: ListCheckInFormsListDto;
  @Input() contextIdDto: ContextIdDto;
  @Input() viewContext: any;
  @Input() form: UntypedFormGroup;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;
  destroy$ = new Subject();

  checkinFormDefs: Array<CheckinFormDefinition>;


  constructor(
    public trialExpiredService: TrialExpiredService,
    private dialog: MatDialog,
    private checkinFormCompositeApi: CheckinFormCompService,
    private checkinFormApi: CheckinFormService,
    private api: CheckinFormDefsService) {
  }

  ngOnInit() {
    this.loadTemplates();
  }

  loadTemplates() {
    this.api.loadCheckinFormDefs(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType).subscribe(response => {
        this.checkinFormDefs = response;
      });
  }

  create(checkinFormDef: CheckinFormDefinition) {
    console.log('inside create in ListCheckInFormsListComponent');
    console.log('this.contextIdDto.contextId is ' + this.contextIdDto.contextId);
    console.log('this.contextIdDto.contextIdType is ' + this.contextIdDto.contextIdType);
    // :linkId/:linkIdType/:linkEdId
    const linkIdType = LinkedIdTypeEnum.EntityInstanceId;
    console.log('linkIdType is ' + linkIdType);

    if (!this.viewContext.entityInstanceId) {
      console.log('no entityInstanceId', this.viewContext.entityInstanceId);
      return;
    }
    if (!this.viewContext.entityDefinitionId) {
      console.log('no entityDefinitionId', this.viewContext.entityDefinitionId);
      return;
    }

    const req = <CreateCheckinFormFromDefId>{};
    req.checkinFormDefinitionId = checkinFormDef.id;
    req.linkEiId = this.viewContext.entityInstanceId.id;
    req.linkEdId = this.viewContext.entityDefinitionId.id;
    req.customerId = this.viewContext.customerId;
    req.envId = this.contextIdDto.contextId;

    this.checkinFormCompositeApi.createCheckinFormFromDefId(req).subscribe(response => {
      this.loadForms();
    });


  }

  loadForms() {
    this.checkinFormApi.listCheckinFormsByLink(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      this.viewContext.entityInstanceId.id,
      this.viewContext.entityDefinitionId.id).subscribe(response => {

        this.listCheckInFormsListDto.checkInForms = response as CheckinForm[];
      });
  }

  rowSelected(checkinForm: CheckinForm) {


    console.log('inside create in ListCheckInFormsListComponent');
    console.log('this.contextIdDto.contextId is ' + this.contextIdDto.contextId);
    console.log('this.contextIdDto.contextIdType is ' + this.contextIdDto.contextIdType);
    // :linkId/:linkIdType/:linkEdId
    const linkIdType = LinkedIdTypeEnum.EntityInstanceId;
    console.log('linkIdType is ' + linkIdType);


    if (!this.viewContext.entityInstanceId) {
      console.log('no entityInstanceId', this.viewContext.entityInstanceId);
      return;
    }
    if (!this.viewContext.entityDefinitionId) {
      console.log('no entityDefinitionId', this.viewContext.entityDefinitionId);
      return;
    }

    const dialogRef = this.dialog.open(ViewCheckInFormModalComponent, {
      width: '600px',
      height: '600px',
      panelClass: 'mobile-view-subscription-dialog',
      data: {
        contextIdDto: this.contextIdDto,
        checkInForm: checkinForm,
        viewContext: this.viewContext
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });

    //  this.contextIdDto = this.data.contextIdDto;
    // this.emailAddress = this.data.emailAddress;
    // checkInFormId: CheckinFormId;
    //   checkInFormDefinitionId: CheckinFormDefinitionId;

  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
