import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IdNameTupleDto, ContextIdDto } from '@savvy/view-definition';
import { EmailmessagetemplatesService } from '@savvy/email-template';

@Component({
  selector: 'app-email-template-select',
  templateUrl: './email-template-select.component.html'
})
export class EmailTemplateSelectComponent implements OnInit {
  emailTemplateList: Array<IdNameTupleDto>;

  @Input() contextIdDto: ContextIdDto;
  @Input() emailTemplateId: string;
  @Output() emailTemplateSelect = new EventEmitter<any>();

  constructor(
    private emailTemplateApi: EmailmessagetemplatesService,
  ) {
  }

  ngOnInit(): void {
    this.loadEmailTemplates();
  }

  onEmailTemplateChange(event) {
    this.emailTemplateApi.getEmailMessageTemplate(this.contextIdDto.contextId, this.contextIdDto.contextIdType, event.value)
      .subscribe(res => {
        this.emailTemplateSelect.emit(res);
      });
  }

  private loadEmailTemplates() {
    this.emailTemplateApi.getUserEmailMessageTemplates(
      '1',
      '10',
      this.contextIdDto.contextIdType,
      this.contextIdDto.contextId
    ).subscribe(res => {
      this.emailTemplateList = res;
    });
  }
}

