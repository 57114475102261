<mat-card appearance="outlined" *ngIf="listOrderListDto && listOrderListDto.orders.length > 0" class="panel new-card">
    <mat-card-title>
        <div fxLayout="row">
            <div fxFlex="80">
                <p class="mt-xs mb-xs">{{'Orders' | translate}}</p>
            </div>
            <div fxFlex="20" *ngIf="listOrderListDto.allowCreate">
                <button mat-mini-fab color="accent" (click)="create()">
                    <mat-icon class="mat-24">add</mat-icon>
                </button>
            </div>
            <!--
            <div fxFlex="20" *ngIf="!listOrderListDto.allowCreate">
                You can create an Order from a Shoot
            </div>
            -->
        </div>
    </mat-card-title>

    <mat-card-content>
        <mat-list>
            <mat-list-item *ngFor="let order of listOrderListDto.orders">
                <p matLine (click)="rowSelected(order.id)">{{ order.orderDate |date:'medium'}} {{ order.total | currency:currencyCode:'symbol'}}</p>
                <mat-divider *ngIf="listOrderListDto.orders.length > 1"></mat-divider>
            </mat-list-item>
        </mat-list>
    </mat-card-content>
</mat-card>

<mat-card appearance="outlined" *ngIf="listOrderListDto && (!listOrderListDto.orders || listOrderListDto.orders.length === 0)" class="full-height new-card">

    <mat-card-content>
        <div class="flexbox-parent">
            <div fxLayout="row" fxLayoutAlign="center center">
                <div style="text-align:center;">
                    <app-zero-image name="orders"></app-zero-image><br>
                    <h5 class="ma-0" *ngIf="listOrderListDto.allowCreate">{{'You don’t have any Order\'s' | translate}}</h5><br>
                    <h5 class="ma-0" *ngIf="!listOrderListDto.allowCreate">{{'You don’t have any Order\'s' | translate}} {{'You can create new orders from Shoot page' | translate}}</h5><br>
                    <button mat-raised-button color="accent" *ngIf="listOrderListDto.allowCreate" (click)="create()">{{'New Order' | translate}}</button>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>