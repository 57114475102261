<mat-toolbar color="primary">
  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
      <span>{{'CUSTOMERS' | translate}} {{'(' + customersCount + ')'}}</span>
    </div>
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="end center">

      <button id="add-new-customer" color="accent" class="mx-4" mat-raised-button
        (click)="createNewCustomer()">{{'NEW_CUSTOMER' | translate}}</button>

      <!--button *ngIf="portalEnabled" mat-raised-button class="mx-4" color="accent" (click)="inviteNewCustomer()">{{'INVITE_ONBOARD_CUSTOMERS' | translate}}</button-->

      <button id="send-to-all-customer" color="accent" class="mx-4" mat-raised-button
        [matMenuTriggerFor]="sendToAllMenu">{{'SEND_TO_ALL' | translate}}</button>
      <mat-menu #sendToAllMenu="matMenu">
        <button mat-menu-item (click)="openSendModal()">{{'SEND_MESSAGE' | translate}}</button>
        <button mat-menu-item (click)="onCustomerMessagesClick()">{{'HISTORY' | translate}}</button>
      </mat-menu>

      <button color="accent" class="mx-4" mat-raised-button (click)="mergeCustomer()">{{'MERGE' | translate}}</button>

      <mat-button-toggle-group [(ngModel)]="viewMode" name="viewMode" aria-label="Alphabetic"
        class="button-group-payments mx-4" (ngModelChange)="viewModeChange()">
        <mat-button-toggle [value]="'grid'">{{'GRID_VIEW' | translate}}</mat-button-toggle>
        <mat-button-toggle [value]="'alpha'">{{'ALPHA_VIEW' | translate}}</mat-button-toggle>
      </mat-button-toggle-group>

      <button mat-button class="mx-4" (click)="showFilters = !showFilters"><mat-icon>filter_alt</mat-icon>
        {{'SHOW_FILTERS' | translate}}</button>

    </div>
  </div>
</mat-toolbar>




<app-customers-alpha *ngIf="viewMode == 'alpha'"></app-customers-alpha>
<app-customers-grid *ngIf="viewMode == 'grid'" [showFilters]="showFilters"
  [selectStatus]="selectStatus"></app-customers-grid>
