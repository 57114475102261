<mat-card appearance="outlined" *ngIf="barChartDto && barChartLabels">
    <!--
    <mat-card-title>{{barChartDto.title}}</mat-card-title>
    <mat-card-subtitle>{{barChartDto.subTitle}}</mat-card-subtitle>
    -->

    <mat-card-subtitle fxLayout="row" fxLayoutAlign="start center">
        {{barChartDto.title}} &nbsp;

      <span class="font-weight-bold mat-text-primary">+ {{ barChartDto.grandTotal | currency:currencyCode:'symbol'}}</span>
        <span fxFlex></span>
        <button mat-icon-button mat-button-sm [matMenuTriggerFor]="card1" aria-label="Open card menu">
            <mat-icon>more_vert</mat-icon>
        </button>
    </mat-card-subtitle>
    <mat-menu #card1="matMenu" x-position="before">
        <button mat-menu-item>
            <mat-icon>settings</mat-icon>
           {{'SETTINGS' | translate: {Default: 'Settings'} }}
        </button>
        <button mat-menu-item>
            <mat-icon>more</mat-icon>
            {{'VIEW_MORE' | translate: {Default: 'View More'} }}
        </button>
        <button mat-menu-item>
            <mat-icon>notifications_off</mat-icon>
            {{'DISABLE_NOTIFICATIONS' | translate: {Default: 'Disable Notifications'} }}
        </button>
        <button mat-menu-item>
            <mat-icon>exit_to_app</mat-icon>
            {{'REMOVE_PANEL' | translate: {Default: 'Remove Panel'} }}
        </button>
    </mat-menu>


    <mat-card-content>
        <canvas height="100" baseChart class="chart"
                [datasets]="barChartData"
                [labels]="barChartLabels"
                [options]="barChartOptions"
                [legend]="barChartLegend"
                [type]="barChartType"></canvas>
    </mat-card-content>
</mat-card>
