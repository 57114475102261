
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ListMessagesForDisplayResponse, Message, MessageService } from '@savvy/messaging';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NewMessagePanelComponent } from '../messaging/newMessagePanel.component';
import { ChangeListener } from './changeListener';
import { TrialExpiredService } from '../shared/services/trial-expired.service';
import { ContextIdDto } from '@savvy/view-definition';

const SMALL_WIDTH_BREAKPOINT = 960;

@Component({
  selector: 'app-list-messages-panel',
  templateUrl: 'listMessagesPanel.component.html',
  styleUrls: ['./listMessagesPanel.component.scss']
})
export class ListMessagesPanelComponent implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() viewContext: any;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;
  displayMode = 'default';
  multi = false;
  hideToggle = true;
  selectedMessage: Message;
  response: ListMessagesForDisplayResponse;
  dialogRef: MatDialogRef<NewMessagePanelComponent>;
  private mediaMatcher: MediaQueryList = matchMedia(`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`);
  private _results: { success: boolean } = { success: false };

  constructor(
    private router: Router,
    public dialog: MatDialog,
    public trialExpiredService: TrialExpiredService,
    private api: MessageService) {
  }

  ngOnInit(): void {
    console.log('loading messages');
    this.api.getMessagesForLinkedInstance(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      this.viewContext.entityInstanceId.id
    ).subscribe(response => {
      console.log('Got messages ' + response.messagesList.length);
      this.response = response;
    });
  }

  isOver(): boolean {
    if (this.mediaMatcher) {
      return this.mediaMatcher.matches;
    }
    return false;
  }

  noMessages(): boolean {
    if (!this.response) {
      return true;
    } else if (!this.response.messagesList) {
      return true;
    } else {
      return this.response.messagesList.length === 0;
    }
  }

  public create() {
    console.log('create new message called');


    this.dialogRef = this.dialog.open(NewMessagePanelComponent, {});

    this.dialogRef.componentInstance.contextIdDto = this.contextIdDto;

    this.dialogRef.afterClosed().subscribe(result => {
      console.log('IT HAS BEEN CLOSED!!');
    });
  }

  onSelect(message: Message): void {
    this.selectedMessage = message;
  }

  deleteMessage(message: Message): void {
  }

  public getIcon(labelName: string) {
    if (labelName === 'Inbox') {
      return 'inbox';
    } else if (labelName === 'Done') {
      return 'check';
    } else if (labelName === 'Snoozed') {
      return 'schedule';
    } else if (labelName === 'Draft') {
      return 'drafts';
    } else if (labelName === 'Important') {
      return 'stars';
    } else if (labelName === 'Junk') {
      return 'error';
    } else if (labelName === 'Trash') {
      return 'delete';
    } else if (labelName === 'Social') {
      return 'people';
    } else if (labelName === 'Promotions') {
      return 'ocal_offer';
    } else if (labelName === 'Family') {
      return 'wc';
    } else if (labelName === 'Work') {
      return 'business';
    } else if (labelName === 'Clients') {
      return 'contacts';
    }
    return 'schedule';
  }


}

