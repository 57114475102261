/* eslint-disable max-len */
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { MatAccordion } from '@angular/material/expansion';
import { ContextIdDto } from '@savvy/app';
import { PetServiceBooking } from '@savvy/pet-stay';
import { PetSearchResultDto } from '@savvy/search/model/petSearchResultDto';
import { ConsumerService } from '@savvy/services';
import { Tax } from '@savvy/tax';
import { IdNameTupleDto, UserDto } from '@savvy/user';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { DataShareService } from 'src/app/core/data-share.service';
import { DateUtils } from 'src/app/flo/dates/DateUtils';
import { LookAndFeelConfig, LookAndFeelSharedService, TimeValue } from 'src/app/flo/shared/services/look-and-feel-shared.service';
import { OrgUsersHelperService } from 'src/app/flo/shared/services/org-users-helper.service';
import { ServicesHelperService } from 'src/app/flo/shared/services/services-helper.service';
import { TaxesHelperService } from 'src/app/flo/shared/services/taxes-helper.service';
import { FloSnackbarComponent } from 'src/app/flo/snackbar/floSnackbar.component';
import { CreateBoardingBookingV2SyncService } from '../../create-boarding-booking-v2-sync.service';
import { PetBoardingServiceSelectorComponent } from './pet-boarding-service-selector/pet-boarding-service-selector.component';

@Component({
  selector: 'app-pet-boarding-services-selector',
  templateUrl: './pet-boarding-services-selector.component.html',
  styleUrls: ['./pet-boarding-services-selector.component.scss']
})
export class PetBoardingServicesSelectorComponent implements OnInit, OnDestroy {
  @ViewChild('datePickerInput', { static: false }) datePickerInput: ElementRef;

  @ViewChild(MatAccordion) accordion: MatAccordion;

  @Input() contextIdDto: ContextIdDto;
  @Input() selectedServices: PetServiceBooking[];
  @Input() pet: PetSearchResultDto;
  @Input() durationRequired = false;
  @Input() disabled = false;
  @Input() allowedToRemove = true;
  @Input() userToUseWhenCreating: IdNameTupleDto;
  @Input() taxDefs: Tax[] = [];
  @Output() updateCustFlags: EventEmitter<boolean> = new EventEmitter();
  @Output() selectedServicesChange: EventEmitter<PetServiceBooking[]> = new EventEmitter();


  // @ViewChild(StaffSelectorComponent, { static: false }) petBoardingStaffSelectorComponent: StaffSelectorComponent;
  @ViewChild(PetBoardingServiceSelectorComponent, { static: false }) petServiceSelectorV2Component: PetBoardingServiceSelectorComponent;

  petServices: Array<PetServiceBooking> = new Array<PetServiceBooking>();
  valid = true;
  lookAndFeelConfig: LookAndFeelConfig;
  timeArray: TimeValue[] = [];
  step = 0;
  subs: Subscription[] = [];
  lastPrice = 0;

  constructor(
    private lookAndFeelService: LookAndFeelSharedService,
    private dateUtils: DateUtils,
    public dataShareService: DataShareService,
    public servicesHelperService: ServicesHelperService,
    public taxesHelperService: TaxesHelperService,
    public createBoardingBookingV2SyncService: CreateBoardingBookingV2SyncService,
    private notify: FloSnackbarComponent,
    private eRef: ElementRef,
    private orgUsersHelperService: OrgUsersHelperService

  ) {

  }

  ngOnInit() {
    if (this.contextIdDto) {
      this.taxesHelperService.getTaxDefs(this.contextIdDto).then(res => {
        this.taxDefs = res;
      });
      // listen for search field value changes
      this.lookAndFeelConfig = this.lookAndFeelService.getLookAndFeelConfig(this.contextIdDto);
      if (this.lookAndFeelConfig) {
        this.timeArray = this.lookAndFeelConfig.timeArray;
      } else {
        console.log('no look and feel config');
      }
    }

  }

  ngOnDestroy(): void {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }

  startDateChanged(service: PetServiceBooking) {
    service.endDate = service.startDate;
  }

  calculateLocalEndTime(service: PetServiceBooking) {
    if (service && service.startTime) {
      console.log('6666', service);
      const endTime = this.dateUtils.addMinsToTimeString(service.startTime, service.duration);
      if (this.timeArray?.length) {
        service.endTime = this.timeArray.find(t => t?.actualValue >= endTime)?.actualValue;
      }
    }
  }

  calculateEndTime(service?: PetServiceBooking) {
    setTimeout(() => {
      this.calculateLocalEndTime(service);
      this.createBoardingBookingV2SyncService.calculateStartEndTime();
    }, 10);
  }


  serviceSelected(service: PetServiceBooking, rowIndex: number) {
    this.selectedServices[rowIndex].serviceId = service.serviceId;
    this.selectedServices[rowIndex].serviceName = service?.serviceName;
    this.selectedServices[rowIndex].quantity = 1;
    this.selectedServices[rowIndex].unitPrice = service.unitPrice;
    this.selectedServices[rowIndex].subTotal = service.unitPrice;
    this.selectedServices[rowIndex].grossPrice = service.unitPrice;
    this.selectedServices[rowIndex].duration = service.duration;
    this.selectedServices[rowIndex].startTime = service.startTime;
    this.selectedServices[rowIndex].endTime = this.dateUtils.addMinsToTimeString(service.startTime, service.duration || 60);
    this.selectedServices[rowIndex].taxId = service.taxId;
    this.selectedServices[rowIndex].tax = service.tax;
    this.selectedServices[rowIndex].startDate = this.createBoardingBookingV2SyncService.endDateReq;
    this.selectedServices[rowIndex].endDate = this.createBoardingBookingV2SyncService.endDateReq;
    if (this.userToUseWhenCreating?.id) {
      this.selectedServices[rowIndex].serviceUser = this.userToUseWhenCreating;
    }
    this.updateUnitPrice(this.selectedServices[rowIndex]);
    this.startTimeChanged(this.selectedServices[rowIndex].startTime, this.selectedServices[rowIndex]);
    this.checkIfValid();
    this.selectedServicesChange.emit(this.selectedServices);
  }

  staffSelectionChanged(serviceUser: IdNameTupleDto, rowIndex) {
    if (!serviceUser) {
      console.log('No service user availabe', serviceUser);
      return;
    }
    this.selectedServices[rowIndex].serviceUser = { id: serviceUser.id };
    this.selectedServices[rowIndex].serviceUserFullName = serviceUser.name || '';
    this.selectedServicesChange.emit(this.selectedServices);
    this.checkIfValid();
  }

  checkIfValid() {
    this.valid = true;

    for (const selectedService of this.selectedServices) {
      if (!selectedService.serviceId) {
        this.valid = false;
      }
      if (!selectedService.serviceUser) {
        this.valid = false;
      }
    }
  }

  durationInMinsChanged(service: PetServiceBooking) {
    this.calculateEndTime(service);
  }

  startTimeChanged(startTime: string, service: PetServiceBooking) {
    this.setEndTimeFromStart(startTime, service);
  }

  setEndTimeFromStart(startTime: string, service: PetServiceBooking) {
    console.log('got duration in mins ' + service.duration);
    service.endTime = this.dateUtils.addMinsToTimeString(startTime, Number(service.duration || 60));
    console.log('just set endTime to ' + service.endTime);
    this.calculateEndTime(service);
    this.checkIfValid();
  }

  endTimeChanged(endTime: string, service: PetServiceBooking) {
    if (endTime <= service.startTime) {
      this.notify.message = 'End time should be greater than start time';
      this.notify.open();
      service.endTime = this.dateUtils.addMinsToTimeString(service.startTime, Number(service.duration || 60));

    }
    this.checkIfValid();
  }

  pushNewService(selectedService: ConsumerService, selectedStaff: UserDto, startTime: string, endTime: string) {
    if (this.selectedServices.length) {
      this.selectedServices.push(
        _.cloneDeep({
          selectedService: _.cloneDeep(selectedService),
          selectedStaff,
          quantity: 1,
          grossPrice: selectedService?.unitPrice,
          subTotal: selectedService?.unitPrice,
          startTime,
          endTime,
        }));
      const lastAddedIndex = this.selectedServices.length - 1;
      this.calculateEndTime(this.selectedServices[lastAddedIndex]);
      this.updateGrossPrice(this.selectedServices[lastAddedIndex]);
      this.checkIfValid();
      this.selectedServicesChange.emit(this.selectedServices);
    }
  }

  updateUnitPrice(service: PetServiceBooking) {
    this.updateQty(service.quantity, service);
  }

  updateGrossPrice(service: PetServiceBooking) {
    if (service?.taxId && service?.subTotal) {
      service.subTotal = Number(service.subTotal);
      const tax = this.taxDefs.find(td => td.id === service.taxId);
      if (tax) {
        service.tax = tax.taxRate;
        service.grossPrice = Number((service.subTotal + (service.subTotal * ((service?.tax || 0) / 100))).toFixed(2));
      }
    } else {
      service.tax = 0;
      service.grossPrice = service.subTotal;
    }
    this.createBoardingBookingV2SyncService.calculateTotalAmount();
    this.selectedServicesChange.emit(this.selectedServices);

  }

  updateQty(qty, service: PetServiceBooking) {
    if (service.unitPrice)
      service.subTotal = service.unitPrice * qty;
    this.updateGrossPrice(service);
  }

  deleteServiceNew(rowIndex) {
    this.selectedServices.splice(rowIndex, 1);
    this.createBoardingBookingV2SyncService.calculateTotalAmount();
    this.selectedServicesChange.emit(this.selectedServices);
    // this.calculateEndTime();
    this.checkIfValid();
  }

  closedStream($event) {
    const focusoutEvent = new Event('focusout');
    this.eRef.nativeElement.dispatchEvent(focusoutEvent);
    this.datePickerInput.nativeElement.dispatchEvent(focusoutEvent);
  }

}
