import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';

import {taskRoutes} from './task.routing';
import {TaskcompService, TaskService} from '@savvy/task';
import {AddEditTaskComponent} from './addEditTask.component';
import {TasksComponent} from './tasks.component';
import {ShowTaskComponent} from './showTask.component';
import {EditorModule} from '../editor/editor.module';
import {FloMaterialModule} from '../flo.material.module';
import {UsersService} from '@savvy/user';
import {RolesService} from '@savvy/role';
import {TasksPanelComponent} from './tasksPanel.component';
import {ZeroTasksComponent} from './zeroTasks.component';
import {AddEditTaskPageComponent} from './addEditTaskPage.component';
import {TaskListComponent} from './taskList.component';
import {TaskBoardComponent} from './taskBoard.component';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '../shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        EditorModule,
        RouterModule.forChild(taskRoutes),
        FloMaterialModule,
        SharedModule,
        TranslateModule
    ],
    providers: [
        TaskService,
        TaskcompService,
        RolesService,
        UsersService
    ],
    declarations: [
        AddEditTaskComponent,
        AddEditTaskPageComponent,
        TasksComponent,
        TaskBoardComponent,
        TaskListComponent,
        ShowTaskComponent,
        TasksPanelComponent,
        ZeroTasksComponent
    ],
    exports: [
        AddEditTaskComponent,
        AddEditTaskPageComponent,
        TasksComponent,
        ShowTaskComponent,
        TasksPanelComponent,
        ZeroTasksComponent
    ]
})

export class AppTaskModule { }
