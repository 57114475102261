import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class NavChangeService {
  public eventAdded$: EventEmitter<string>;
  private events: string[] = [];
  private created = new Date();

  constructor() {
    console.log('nav service constructor');
    this.eventAdded$ = new EventEmitter();
    console.log('leaving nav service constructor');
  }

  public list(): string[] {
    return this.events;
  }

  // TODO: TYPE THIS PROPERLY
  public add(event: string): void {
    console.log('adding event ' + event + '  on event bus ' + this.created);
    this.events.push(event);
    this.eventAdded$.emit(event);
  }
}
