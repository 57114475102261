<mat-toolbar color="primary" *ngIf="workflowDef">
  <mat-toolbar-row *ngIf="workflowDef.id">
    <app-breadcrumb></app-breadcrumb>
    <span fxFlex></span>
    <!--button mat-icon-button class="mat-toolbar-icon" (click)="deleteWorkflow()">
      <mat-icon class="mat-toolbar-icon">delete</mat-icon>
    </button-->
  </mat-toolbar-row>
  <mat-toolbar-row *ngIf="!workflowDef.id">
    <app-breadcrumb></app-breadcrumb>
  </mat-toolbar-row>

</mat-toolbar>

<mat-card appearance="outlined" *ngIf="workflowDef">


  <form #workflowForm="ngForm" style="width: 100%">
    <mat-card-content>

      <mat-form-field style="width: 100%" appearance="outline">
        <mat-label>{{'WORKFLOW_NAME' | translate}}</mat-label>
        <input matInput [(ngModel)]="workflowDef.name" name="workflowNameField" #workflowNameField="ngModel" class="example-full-width" placeholder="{{'WORKFLOW_NAME' | translate}}" required>
        <mat-error *ngIf="workflowNameField.hasError('required')">
          {{'WORKFLOW_NAME_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
        </mat-error>
      </mat-form-field>
      <br>
      <mat-form-field style="width: 100%" appearance="outline">
        <mat-label>{{'WORKFLOW_TYPE' | translate}}</mat-label>
        <mat-select name="workflowTypeField" #workflowTypeField="ngModel" id="workflowTypeField" [(ngModel)]="workflowDefinitionTypeDataIdx" (selectionChange)="updateTypeData($event)" [required]="true" required>

          <mat-option *ngFor="let item of workflowDefinitionTypeItems" [value]="item.idx">
            {{item.label}}</mat-option>
        </mat-select>
        <mat-error *ngIf="workflowTypeField.hasError('required')">
          {{'WORKFLOW_TYPE_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
        </mat-error>
      </mat-form-field>
    </mat-card-content>

    <ngx-datatable *ngIf="workflowDef.workflowStateList" class='material' [columnMode]="'force'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'" [limit]="10" [selected]="selected" [selectionType]="'cell'" (select)="onSelect($event)" [rows]='workflowDef.workflowStateList'>

      <ngx-datatable-column name="{{'STATE' | translate}}" [flexGrow]="7">
        <ng-template ngx-datatable-cell-template let-row="row">
          {{row.state}}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="{{'EDIT' | translate}}" [flexGrow]="1">
        <ng-template ngx-datatable-header-template>
          <button mat-mini-fab color="accent" style="top: -10px !important;" type="button" (click)="createState()">
            <mat-icon>add</mat-icon>
          </button>
        </ng-template>
        <ng-template ngx-datatable-cell-template let-row="row">

          <!--
        <button (click)="editRow(row)" class="ml-xs mat-icon-button" mat-icon-button="">
              <span class="mat-button-wrapper">
                  <mat-icon role="img" class="demo-toolbar-icon" aria-label="edit">edit</mat-icon>
              </span>
          <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
          <div class="mat-button-focus-overlay"></div>
        </button>
        -->
          <button (click)="deleteState(row)" class="ml-xs mat-icon-button" mat-icon-button="">
            <span class="mat-button-wrapper">
              <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">delete</mat-icon>
            </span>
            <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
            <div class="mat-button-focus-overlay"></div>
          </button>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>

    <mat-card-content *ngIf="!workflowDef.id">
      <button mat-raised-button color="accent" [disabled]="!workflowForm.form.valid" disabled="disabled" (click)="createWorkflow()">{{'CREATE' | translate}}</button>
    </mat-card-content>

    <button mat-fab color="accent" *ngIf="workflowDef.id" (click)="createState()" class="mat-fab-bottom-right">
      <mat-icon class="mat-24">add</mat-icon>
    </button>
  </form>
</mat-card>
