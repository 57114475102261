import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ChangeListener } from './changeListener';
import { EventBusService } from './EventBusService';
import { TranslateService } from '@ngx-translate/core';
import { DateTimeAdapter } from '@danielmoncada/angular-datetime-picker';
import { AppointmentCompService } from '@savvy/appointment';
import { ContextIdDto } from '@savvy/ui';
import { ViewContextDto, CreateAppointmentViewData } from '@savvy/view-composite';
import { LookAndFeelConfig, LookAndFeelSharedService, TimeValue } from '../shared/services/look-and-feel-shared.service';
import { LookAndFeel } from '@savvy/look-and-feel';

@Component({
  selector: 'app-create-appointment-view',
  templateUrl: 'createAppointmentView.component.html'
})
export class CreateAppointmentViewComponent implements OnInit {

  @Input() createAppointmentViewData: CreateAppointmentViewData;
  @Input() contextIdDto: ContextIdDto;
  @Input() viewContext: ViewContextDto;
  @Input() form: UntypedFormGroup;
  @Input() eventBus: EventBusService;
  @Input() additionalDataMapDto: any;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;

  petName: string;
  customerName: string;
  customerPhone: string;
  appointmentDateTime: string;

  timeArray: TimeValue[] = [];
  minuteInterval: number;
  lookAndFeelConfig: LookAndFeelConfig;
  lookAndFeel: LookAndFeel;
  defaultTimeValue: string;

  constructor(
    private appointmentCompositeApi: AppointmentCompService,
    private translateService: TranslateService,
    dateTimeAdapter: DateTimeAdapter<any>,
    private lookAndFeelService: LookAndFeelSharedService) {
    dateTimeAdapter.setLocale(this.translateService.currentLang);
  }

  ngOnInit(): void {
    this.lookAndFeelConfig = this.lookAndFeelService.getLookAndFeelConfig(this.contextIdDto);

    if (this.lookAndFeelConfig) {
      this.lookAndFeel = this.lookAndFeelConfig.lookAndFeel;
      this.minuteInterval = this.lookAndFeelConfig.minuteInterval;
      this.timeArray = this.lookAndFeelConfig.timeArray;
    } else {
      console.log('no look and feel config');
    }

    // this.initialise();
  }


  create() {

    // const req = <CreateQuickAppointment>{};
    // req.contextIdDto = this.contextIdDto;
    // req.createQuickAppointmentDataDto = <CreateQuickAppointmentDataDto>{};
    // req.createQuickAppointmentDataDto.customerName = this.customerName;
    // req.createQuickAppointmentDataDto.customerPhone = this.customerPhone;
    // req.createQuickAppointmentDataDto.appointmentStart = this.appointmentDateTime;
    // req.createQuickAppointmentDataDto.appointmentStartTime = this.appointmentDateTime;
    // req.createQuickAppointmentDataDto.appointmentEndTime = this.appointmentDateTime;
    // req.createQuickAppointmentDataDto.petName = this.petName;

    // this.appointmentCompositeApi.createQuickAppointment(req).subscribe(response => {

    //   console.log('created appointment');
    //   // Go to view appointment instead
    // });
  }
}

