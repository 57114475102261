import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ContextIdDto } from '@savvy/app';
import { Pet, PetService } from '@savvy/pet';
import { MatDialog } from '@angular/material/dialog';
import { FloSnackbarComponent } from 'src/app/flo/snackbar/floSnackbar.component';
import { BoardingBookingForListingDto, BoardingBookingService, PetStayPreference, PetStayPreferenceService } from "@savvy/pet-stay";
import { ViewInvoiceV2Component } from "../../../appointment/view-invoice-v2/view-invoice-v2.component";
import { ViewPetBoardingComponent } from "../../../petdaycare/view-pet-boarding/view-pet-boarding.component";
import { BoardingBookingHandlerService } from 'src/app/flo/petdaycare/boarding-booking-handler.service';

@Component({
  selector: 'app-view-pet-boardings',
  templateUrl: './view-boarding-pet-history.component.html',
  styleUrls: ['./view-boarding-pet-history.component.scss']
})
export class ViewBoardingPetHistoryComponent implements OnInit, OnChanges {
  @Input() pet: Pet;
  @Input() petId: string;
  @Input() contextIdDto: ContextIdDto;
  @Input() addBoardingAllowed = false;
  @Input() isViewMode = false;
  @Input() historyMode: 'past' | 'future' | 'ongoing' = 'future';
  @Output() loadBoardingsEvent: EventEmitter<any> = new EventEmitter();


  pastBoardings: Array<BoardingBookingForListingDto> = [];
  futureBoardings: Array<BoardingBookingForListingDto> = [];
  ongoingBoardings: Array<BoardingBookingForListingDto> = [];


  petList = [];
  showOnlyCancelled = false;
  selectedBoardings: string[] = [];
  petStayPreference: PetStayPreference;
  constructor(
    private dialog: MatDialog,
    private petService: PetService,
    private notify: FloSnackbarComponent,
    private boardingBookingService: BoardingBookingService,
    private boardingBookingHandlerService: BoardingBookingHandlerService,
    private petStayPreferenceService: PetStayPreferenceService

  ) { }

  ngOnInit() {
    console.log('load pet');
    this.loadBoardings();
    this.loadPetStayPreferences();
  }

  loadPetStayPreferences() {
    this.petStayPreferenceService.getPetStayPreferenceByOwnerId(this.contextIdDto.contextId).subscribe(res => {
      if (res) {
        this.petStayPreference = res;
      }
    });

  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.pet?.currentValue !== changes?.pet?.previousValue ||
      changes?.petId?.currentValue !== changes?.petId?.previousValue) {
      this.loadBoardings();
    }
  }

  historyModeChanged(event) {
    this.selectedBoardings = [];
    if (event === 'past') {
      this.loadHistory();
    } else if (event === 'ongoing') {
      this.loadOngoing();
    } else if (event === 'future') {
      this.loadFuture();
    }
  }

  loadBoardings() {
    this.loadHistory();
    this.loadOngoing();
    this.loadFuture();
  }

  viewInvoice(boardingBooking: BoardingBookingForListingDto) {
    if (boardingBooking?.boardingBooking?.invoiceId) {
      const dialogRef = this.dialog.open(ViewInvoiceV2Component, {
        maxWidth: '800px',
        width: '800px',
        data: {
          invoiceId: boardingBooking?.boardingBooking?.invoiceId.id,
          contextIdDto: this.contextIdDto
        },
        panelClass: ['scrollable-modal', 'invoicemodal']
      });

      dialogRef.afterClosed().subscribe((result) => {

      });
    }
  }

  // addAppointment() {
  //   this.appointmentHandlerService.createAppointment({ contextIdDto: this.contextIdDto, populatePet: this.pet, callback: this.callback });
  // }

  callback = (result) => {
    if (result) {
      this.loadBoardings();
      this.loadBoardingsEvent.emit(result);
    }
  };

  openEditAppointmentModal(boardingBookingDto: BoardingBookingForListingDto) {
    if (!this.isViewMode) {
      this.boardingBookingHandlerService.editBooking(this.contextIdDto, boardingBookingDto.boardingBooking, this.petStayPreference, this.callback);
    }
  }

  openViewBoardingModal(boardingBookingDto: BoardingBookingForListingDto) {
    if (!this.isViewMode) {
      const dialogRef = this.dialog.open(ViewPetBoardingComponent, {
        data: {
          contextIdDto: this.contextIdDto,
          boardingBookingId: boardingBookingDto?.boardingBooking?.id
        },
        autoFocus: false,
        panelClass: 'quickview'
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.rebook) {
            this.boardingBookingHandlerService.rebookBooking(this.contextIdDto, result.boardingBooking, this.petStayPreference, this.callback);
          } else {
            this.boardingBookingHandlerService.editBooking(this.contextIdDto, result.boardingBooking, this.petStayPreference, this.callback);
          }
        } else {
          this.loadBoardings();
        }

      });
    }
  }

  cancelledFilterChanged(event) {
    this.loadBoardings();
  }

  selectBoarding($event, boardingBookingId: string) {
    if (boardingBookingId) {
      const index = this.selectedBoardings.indexOf(boardingBookingId);
      if (index > -1) {
        this.selectedBoardings.splice(index, 1);
      } else {
        this.selectedBoardings.push(boardingBookingId);
      }
    }
  }

  // deleteAppointments() {
  //   const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
  //     data: {
  //       title: 'Bulk Delete Appointments',
  //       message: 'Are you sure you want to delete all selected appointments?',
  //       // isChange: true
  //     },
  //     panelClass: 'helpwindow'
  //   });
  //
  //   dialogRef.afterClosed().subscribe((result) => {
  //     if (result) {
  //       this.selectedAppointments.forEach((selectedAppointment, index) => {
  //         this.appointmentService.deleteAppointment(selectedAppointment, this.contextIdDto.contextId, this.contextIdDto.contextIdType)
  //           .subscribe(response => {
  //             if (response) {
  //               console.log('deleted appointment', { id: response.id });
  //               if (index === this.selectedAppointments.length - 1) {
  //                 this.loadAppointments();
  //               }
  //             }
  //           });
  //       });
  //     }
  //   });
  // }

  private loadHistory() {
    if ((this.petId || this.pet.id)) {
      if (this.showOnlyCancelled) {
        this.boardingBookingService.loadPastCancelledPetBoardings(this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          (this.petId || this.pet.id)).subscribe(res => {
            console.log('pet past v2 is', res);
            this.pastBoardings = res;
          }, error => {
            // Ignore
          });
      } else {
        this.boardingBookingService.loadPastPetBoardings(this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          (this.petId || this.pet.id)).subscribe(res => {
            console.log('pet past v2 is', res);
            this.pastBoardings = res;
          }, error => {
            // Ignore
          });
      }
    }
  }

  private loadFuture() {
    if (this.showOnlyCancelled) {
      this.boardingBookingService.loadFutureCancelledPetBoardings(this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        (this.petId || this.pet.id)).subscribe(res => {
          console.log('pet future v2 is', res);
          this.futureBoardings = res;
        }, error => {
          // Ignore
        });
    } else {
      this.boardingBookingService.loadFuturePetBoardings(this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        (this.petId || this.pet.id)).subscribe(res => {
          console.log('pet future v2 is', res);
          this.futureBoardings = res;
        }, error => {
          // Ignore
        });
    }
  }

  private loadOngoing() {
    if (this.showOnlyCancelled) {
      this.boardingBookingService.loadOngoingCancelledPetBoardings(this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        (this.petId || this.pet.id)).subscribe(res => {
          console.log('pet future v2 is', res);
          this.ongoingBoardings = res;
        }, error => {
          // Ignore
        });
    } else {
      this.boardingBookingService.loadOngoingPetBoardings(this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        (this.petId || this.pet.id)).subscribe(res => {
          console.log('pet future v2 is', res);
          this.ongoingBoardings = res;
        }, error => {
          // Ignore
        });
    }
  }
}
