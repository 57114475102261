import { Component, Input, OnInit } from '@angular/core';
import { ContextIdDto } from '@savvy/menu';
import { Vaccination, VaccinationService, Vaccine, VaccinesService } from '@savvy/vaccination';
import { AddEditPetVaccinationComponent } from "../../../../shared/components/add-edit-pet-vaccination/add-edit-pet-vaccination.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'app-pet-vaccination-list',
  templateUrl: './pet-vaccination-list.component.html',
  styleUrls: ['./pet-vaccination-list.component.scss']
})
export class PetVaccinationListComponent implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() petId;

  vaccinesList: Vaccine[] = [];
  vaccinations: Vaccination[] = [];

  constructor(
    private vaccinesService: VaccinesService,
    private vaccinationService: VaccinationService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.loadVaccineList();
  }

  loadVaccineList() {
    this.vaccinesService.loadVaccineByDeleted(false, this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(res => {
      if (res && res.length) {
        this.vaccinesList = res;
        this.getVaccinationOfPet();
      }
    });
  }

  editVaccination(vaccination: Vaccination) {

    const dialogRef = this.dialog.open(AddEditPetVaccinationComponent, {
      data: {
        vaccination,
        contextIdDto: this.contextIdDto,
      },
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed()
      .subscribe(result => {
        console.log('back from dialog close');
        this.getVaccinationOfPet();
      });
  }

  getVaccinationOfPet() {
    if (this.petId) {
      this.vaccinationService.getVaccinationsByOwnerIdAndPetId(this.contextIdDto.contextId, this.petId)
        .subscribe(res => {
          if (res && res.length) {
            this.vaccinations = res.filter(v => !v.deleted);
            this.mapVaccinationName();
          }
        });
    } else {
      this.vaccinations = [];
    }
  }

  mapVaccinationName() {
    if (this.vaccinations?.length && this.vaccinesList?.length) {
      this.vaccinations.forEach(petVaccination => {
        this.vaccinesList.forEach(vaccination => {
          if (vaccination?.id === petVaccination?.vaccineId) {
            petVaccination.nameOfVaccination = vaccination?.vaccineName;
          }
        });
      });
    }
  }

}
