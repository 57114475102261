<div fxLayout="row" [formGroup]="form" *ngIf="linkedEntityDefinition && elementInstanceDto.elementInstanceViewDto.viewable">

<!-- If typeahead

  IN MULTI TYPEAHEAD

  selectedPetsIds {{ selectedPetsIds}}

  this.elementInstanceDto.fieldInstanceDto.value {{ this.elementInstanceDto.fieldInstanceDto.value.strValue }}
  -->
<div fxLayout="column" fxFlex="100" class="multi-select-container">
  <mat-form-field class="example-chip-list w-100" appearance="outline">
    <mat-chip-grid #chipList>
      <mat-chip-row
        *ngFor="let pet of pets"
        [selectable]="selectable"
        [removable]="false">
        <span [ngClass]="{ 'deceased-pet': pet?.deceased }">{{pet.name}}</span><span *ngIf="pet?.deceased"> (D) </span>
        <mat-icon matChipRemove *ngIf="removable" (click)="remove(pet)">cancel</mat-icon>
      </mat-chip-row>
      <input
        required
        placeholder="{{linkedEntityDefinition.placeholder + ' *'}}"
        #fruitInput
        (keyup)="onKey($event)"
        [formControl]="petsCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur">
    </mat-chip-grid>

    <mat-autocomplete #auto="matAutocomplete"
                      [displayWith]="displayFn.bind(this)" autoActiveFirstOption>
      <mat-option *ngFor="let tuple of entityInstanceIdFieldValueTupleDtos"
                  (onSelectionChange)="selected(tuple)"
                  [value]="tuple.id">
        {{ tuple.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-error *ngIf="!pets.length > 0 && petsCtrl.touched">
    Field is <strong>required</strong>
  </mat-error>
</div>



  <!--
  <mat-form-field appearance="outline" style="width: 100%;" *ngIf="linkedEntityDefinition && elementInstanceDto.instanceId">
    <mat-label>{{this.form.controls[this.elementInstanceDto.instanceId].value}} {{linkedEntityDefinition.label | translate}}</mat-label>

    <input matInput placeholder="{{getPlaceholder() | translate}}"
           [required]="linkedEntityDefinition.required"
           [matAutocomplete]="auto1"
           [formControlName]="elementInstanceDto.instanceId"
           (keyup)="onKey($event)">

    <mat-autocomplete #auto1="matAutocomplete"
                      [displayWith]="displayFn.bind(this)" autoActiveFirstOption
                      (optionSelected)="handler($event)">
      <mat-option *ngFor="let tuple of entityInstanceIdFieldValueTupleDtos" [value]="tuple.id">
        {{ tuple.name }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="form.controls[elementInstanceDto.instanceId].hasError('required')">
      {{linkedEntityDefinition.label + ' is' | translate}} <strong>{{'required' | translate}}</strong>
    </mat-error>
    <mat-error *ngIf="!form.controls[elementInstanceDto.instanceId].hasError('required') && form.controls[elementInstanceDto.instanceId].hasError('doesNotSelected')">
      {{linkedEntityDefinition.label + ' is' | translate}} <strong>{{'required' | translate}}</strong>.
      {{'Please Select one of the choices or create it' | translate}}.
    </mat-error>
  </mat-form-field>
-->


<!--
  <div *ngIf="linkedEntityDefinition && elementInstanceDto.instanceId &&
                            !additionalDataMapDto.elementDefDataMap[elementInstanceDto.elementDefinitionId]">

    MISSING IMPLEMENT THIS AGAIN!!  THIS IS WHERE TYPEAHEAD
  </div>-->




  <div *ngIf="linkedEntityDefinition.allowCreate">
    <span style="text-align: center; padding: 15px">{{'OR' | translate}}</span>

    <button (click)="createNewLinkedEntity()" mat-fab>
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>
