import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Customer } from '@savvy/customer';
import { ContextIdDto } from '@savvy/user';
import { PhoneNumberHelperService } from 'src/app/flo/shared/services/phone-number-helper.service';
import { UrlLookupService } from 'src/app/flo/shared/services/urlLookup.service';
import { WindowRef } from 'src/app/flo/windowWrapper';
import { PortalSettingsLocalService } from 'src/app/services/portalSettingsLocal.service';
import { OnboardCustomerMessagingModalComponent } from '../../onboard-customer-messaging-modal/onboard-customer-messaging-modal.component';
import { ResetCustomerPasswordModalComponent } from '../../reset-customer-password-modal/reset-customer-password-modal.component';

@Component({
  selector: 'app-customer-portal-buttons',
  templateUrl: './customer-portal-buttons.component.html',
  styleUrls: ['./customer-portal-buttons.component.scss']
})
export class CustomerPortalButtonsComponent implements OnInit {
  @Input() customerDto: Customer;
  @Input() contextIdDto: ContextIdDto;

  constructor(
    private router: Router,
    private windowRef: WindowRef,
    private dialog: MatDialog,
    private urlService: UrlLookupService,
    private sharedService: PhoneNumberHelperService,
    public portalSettingsLocalService: PortalSettingsLocalService,
  ) { }

  ngOnInit() {
  }


  resetPassword() {

    this.dialog.open(ResetCustomerPasswordModalComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        customerId: this.customerDto.customerId,
        userId: this.customerDto.userId,
        email: this.customerDto.username
      },
      panelClass: 'customers-messaging-dialog',
      autoFocus: true
    });
  }

  registerToPortal() {

    this.dialog.open(OnboardCustomerMessagingModalComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        customerId: this.customerDto.customerId,
        email: this.customerDto.username
      },
      panelClass: 'customers-messaging-dialog',
      autoFocus: true
    });
  }

  gotoPortal() {
    this.urlService.openNewTab(this.urlService.customerPortalUrl() + '?id=' + this.contextIdDto.contextId + this.urlService.getEnv());
  }

}
