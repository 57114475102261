import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContextIdDto } from '@savvy/app';
import { ContractCompService, ContractUiDto, LoadContractsResponse } from '@savvy/contract';
import { takeUntil } from 'rxjs/operators';
import { LinkedIdTypeEnum, LinkId } from 'src/app/core/data-share.service';
import { AddContractModalComponent } from 'src/app/flo/contract/add-contract-modal/add-contract-modal.component';
import { ViewContractModalComponent } from 'src/app/flo/contract/view-contract-modal/view-contract-modal.component';
import { ClearObservable } from 'src/app/flo/shared/classes/clear-observable';

@Component({
  selector: 'app-customer-contracts-v2',
  templateUrl: './customer-contracts-v2.component.html',
  styleUrls: ['./customer-contracts-v2.component.scss']
})
export class CustomerContractsV2Component extends ClearObservable implements OnInit, OnChanges {
  @Input() contextIdDto: ContextIdDto;

  @Input() customerId: string;

  contractUiDtoList: ContractUiDto[] = [];

  constructor(
    private contractApi: ContractCompService,
    private dialog: MatDialog,
  ) {
    super();
  }

  ngOnInit() {
    this.loadContractLists();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.customerId?.currentValue !== changes?.customerId?.previousValue) {
      this.loadContractLists();
    }
  }

  loadContractLists() {
    if (!this.customerId) {
      console.error('No customer id to loadContractsForCustomer');
      return;
    }
    this.contractApi.loadContractsForCustomer(
      this.customerId).subscribe((response: LoadContractsResponse) => {
        this.contractUiDtoList = response.contractUiDtoList;
      }, (err: HttpErrorResponse) => {
        this.contractUiDtoList = [];
        console.log('error while loadContractLists', err);
      });
  }

  openViewContractModal(contractId) {
    const createContractModal = this.dialog.open(ViewContractModalComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        contractId,
        isContractModal: true
      },
      maxWidth: '1024px',
      maxHeight: '80vh',
      height: '80vh',
      panelClass: [
        'modal-100',
        'scrollable-modal'
      ],
      autoFocus: false
    });

    createContractModal.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.loadContractLists();
        });
  }


  addContract() {
    const linkId = {} as LinkId;
    linkId.linkedIdType = LinkedIdTypeEnum.CustomerId;
    linkId.linkedId = this.customerId;
    const dialogRef = this.dialog.open(AddContractModalComponent, {
      width: '600px',
      panelClass: ['helpwindow', 'scrollable-modal'],
      data: {
        linkId,
        contextIdDto: this.contextIdDto,
        isContractModal: true
      }
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        console.log('The dialog was closed');
        this.loadContractLists();
      });

  }

}
