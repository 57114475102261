import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";

import {ContextIdDto, LinkId, Notification} from '@savvy/notification';
import {Customer, CustomerService} from "@savvy/customer";
import {FloSnackbarComponent} from "../../snackbar/floSnackbar.component";
import {LinkIds, MessagecompService, MessageService, MSISDN, SendSmsMessage, SmsRecipient} from "@savvy/messaging";
import {ViewCustomerV2Component} from "../../customer/view-customer-v2/view-customer-v2.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-sms-reply-notification',
  templateUrl: './smsReplyNotification.component.html'
})
export class SmsReplyNotificationComponent implements OnInit {

  @Input() notification: Notification;
  @Input() contextIdDto: ContextIdDto;

  @Output() notificationProcessed: EventEmitter<any> = new EventEmitter();

  customer: Customer;

  error = null;
  replyMesssage = null;
  showTextbox = false;
  replyDone = false;
  replyToMsisdn: string = null;

  constructor(private notify: FloSnackbarComponent,
              public dialog: MatDialog,
              private customerService: CustomerService,
              private messageService: MessageService,
              private messagecompService: MessagecompService) {
  }

  ngOnInit(): void {
  //  this.loadCustomer();
  }


  loadCustomer() {
    this.customerService.loadCustomer(this.notification.customerId, this.contextIdDto.contextId,
      this.contextIdDto.contextIdType).subscribe(response => {
        this.customer = response;
    })
  }

  getLinkId(type: string, linkIds: Array<LinkId>): string {
    let linkIdToRet;

    linkIds.forEach(linkId => {
      if (linkId.linkedIdType === type) {
        linkIdToRet = linkId.linkedId;
      }
    });
    return linkIdToRet;
  }

  sendSms(notification: Notification) {
    const customerId = this.getLinkId('CUSTOMER_ID', notification.linkIds);
    if (customerId) {
      const userId = this.getLinkId('USER_ID', notification.linkIds);
      if (this.replyToMsisdn) {
        const req = <SendSmsMessage>{};
        req.contextId = this.contextIdDto.contextId;
        req.contextIdType = this.contextIdDto.contextIdType;
        req.message = this.replyMesssage;
        // req.smsSenderId = this.smsSenderId;
        const linkIds = <LinkIds>{};
        linkIds.linkIds = new Array();
        const linkId: LinkId = {
          linkedId: customerId,
          linkedIdType: 'CUSTOMER_ID'
        };
        const linkUserId: LinkId = {
          linkedId: userId,
          linkedIdType: 'USER_ID'
        };
        linkIds.linkIds.push(linkId);
        linkIds.linkIds.push(linkUserId);
        req.linkIds = linkIds;

        req.toNumbers = [];
        const smsRecipient = <SmsRecipient>{};
        smsRecipient.recipient = <MSISDN>{};
        smsRecipient.recipient.msidn = this.replyToMsisdn;
        smsRecipient.recipientId = customerId;
        smsRecipient.recipientType = SmsRecipient.RecipientTypeEnum.Customer;

        req.toNumbers.push(smsRecipient);
        this.messagecompService.sendSmsMessage(req).subscribe(response => {
          let errorMessage = undefined;
          if (response?.sendMessageResponses) {
            for (let message of response.sendMessageResponses) {
              if (message.message.errorMessage) {
                errorMessage = message.message.errorMessage;
                break;
              } else if (message.errorMessage) {
                errorMessage = message.errorMessage;
              }
            }
          }

          if (!errorMessage) {
            this.replyMesssage = null;
            this.showTextbox = false;
            this.replyDone = true;
          } else {
          // this.hideNotifications();
            this.notify.message = 'Failed to send sms. ' + errorMessage;
            this.notify.open();
          }

        }, error => {

        });
      }
    } else {
      console.log('smsReplyNotification.component : customer ID is missing while sending SMS');
    }

  }

  reply(notification: Notification) {

    const messageId = this.getLinkId('MESSAGE_ID', notification.linkIds);
    if (messageId) {
      this.messageService.getMessage(messageId, this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(response => {
        if (response?.smsMessage?.fromNumber?.msidn) {
          this.showTextbox = true;
          this.replyToMsisdn = response?.smsMessage?.fromNumber?.msidn;
          console.log('set replyToMsisdn to ', response?.smsMessage?.fromNumber?.msidn);
        } else {
          this.error = true;
          this.replyToMsisdn = null;
        }
      }, error => {
        this.error = true;
      });
    } else {
      this.error = true;
    }
  }

  viewCustomer(customerId: string) {
    const contextIdDto = this.contextIdDto;
    const viewCustomerModal = this.dialog.open(ViewCustomerV2Component, {
      data: {
        contextIdDto,
        customerId
      },
      maxWidth: '1024px',
      panelClass: [
        'modal-100',
        'helpwindow',
        'petProfileModal'
      ],
      autoFocus: false
    });

    viewCustomerModal.afterClosed()
      .subscribe(
        () => {
        });
  }

}
