import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ChangeListener } from './changeListener';
import { TrialExpiredService } from '../shared/services/trial-expired.service';
import { PhoneNumberHelperService } from '../shared/services/phone-number-helper.service';
import { MatDialog } from '@angular/material/dialog';
import { ClearObservable } from '../shared/classes/clear-observable';
import { takeUntil } from 'rxjs/operators';
import { ContractCompService, ContractUiDto, LoadContractsResponse } from '@savvy/contract';
import { HttpErrorResponse } from '@angular/common/http';
import { AddContractModalComponent } from '../contract/add-contract-modal/add-contract-modal.component';
import { ViewContractModalComponent } from '../contract/view-contract-modal/view-contract-modal.component';
import { ListContractListDto, ViewContextDto } from '@savvy/view-composite';
import { ContextIdDto } from '@savvy/ui';
import { LinkedIdTypeEnum, LinkId } from 'src/app/core/data-share.service';
import {GalleryService} from '@savvy/gallery';
import {LoadGalleriesByAssociation, LoadGalleriesResponse} from '@savvy/gallery/model/models';
import {Gallery} from '@savvy/gallery/model/gallery';

@Component({
  selector: 'app-view-galleries',
  templateUrl: 'viewGalleries.component.html'
})
export class ViewGalleriesComponent extends ClearObservable implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() viewContext: ViewContextDto;
  @Input() form: UntypedFormGroup;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;

  galleries: Array<Gallery>;

  constructor(
    private router: Router,
    public trialExpiredService: TrialExpiredService,
    private sharedService: PhoneNumberHelperService,
    private galleryService: GalleryService,
    private dialog: MatDialog) {
    super();
  }

  ngOnInit() {
    this.loadGalleriesLists();
  }


  create() {
    if (this.viewContext.viewContextTypeDto === ViewContextDto.ViewContextTypeDtoEnum.ViewEntityInstance ||
      this.viewContext.viewContextTypeDto === ViewContextDto.ViewContextTypeDtoEnum.AddEditEntityInstance) {

      this.router.navigate(['/galleries/addGallery',
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.viewContext.entityInstanceId.id,
        this.viewContext.entityDefinitionId.id
      ]);

    } else if (this.viewContext.viewContextTypeDto === ViewContextDto.ViewContextTypeDtoEnum.Customer) {
      this.router.navigate(['/galleries/addGallery',
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.viewContext.customerContextDto.customerId.id
      ]);
    }
  }

  rowSelected(galleryId: string) {
    this.router.navigate(['/galleries/editGallery',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      galleryId
    ]);
  }

  openCreateContractModal() {
   /* console.log(this);

    let linkId = <LinkId>{};
    linkId.linkedIdType = LinkedIdTypeEnum.CustomerId;
    linkId.linkedId = this.customerId;

    const createContractModal = this.dialog.open(AddContractModalComponent, {
        data: {
          contextIdDto: this.contextIdDto,
          linkId: linkId,
          isContractModal: true
        },
        panelClass: ['helpwindow', 'scrollable-modal'],
        autoFocus: false
      });

      createContractModal.afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          res => {
            if (res) {
              this.loadGalleriesLists();
            }
          });*/
  }

  openViewContractModal(contractId) {
   /* const createContractModal = this.dialog.open(ViewContractModalComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        contractId: contractId,
        isContractModal: true
      },
      maxWidth: '1024px',
      maxHeight: '80vh',
      height: '80vh',
      panelClass: [
        'modal-100',
        'scrollable-modal'
      ],
      autoFocus: false
    });

    createContractModal.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.loadGalleriesLists();
        });*/
  }

  loadGalleriesLists() {

    if (this.viewContext.viewContextTypeDto === ViewContextDto.ViewContextTypeDtoEnum.ViewEntityInstance ||
      this.viewContext.viewContextTypeDto === ViewContextDto.ViewContextTypeDtoEnum.AddEditEntityInstance) {

      const req = <LoadGalleriesByAssociation>{};
      req.contextIdDto = this.contextIdDto;
      req.linkId = this.viewContext.linkId;

      this.galleryService.loadGalleriesByAssociation(req)
        .pipe(takeUntil(this.destroy$))
        .subscribe((response: LoadGalleriesResponse) => {
          this.galleries = response.galleries;
        }, (err: HttpErrorResponse) => {
          console.log('error while galleries', err);
        });

    } else if (this.viewContext.viewContextTypeDto === ViewContextDto.ViewContextTypeDtoEnum.Customer) {
      this.galleryService.loadCustomerGalleries(
        this.viewContext.customerContextDto.customerId.id,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType
      )
        .pipe(takeUntil(this.destroy$))
        .subscribe((response: LoadGalleriesResponse) => {
          this.galleries = response.galleries;
        }, (err: HttpErrorResponse) => {
          console.log('error while galleries', err);
        });
    }
  }
}

