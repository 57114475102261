<quill-editor #editor [format]="'html'" [(ngModel)]="text" (onEditorCreated)="created($event)"
  (ngModelChange)="logChange($event);" [readOnly]="readOnly" bounds="self"
  [required]="required" [modules]="modules" [placeholder]="placeholder" class="rounded-6 border">

  <div quill-editor-toolbar *ngIf="!hideToolbar">

    <span class="ql-formats">
      <select class="ql-font">
        <option selected></option>
        <option value="serif"></option>
        <option value="monospace"></option>
      </select>
      <select class="ql-size">
        <option value="small"></option>
        <option selected></option>
        <option value="large"></option>
        <option value="huge"></option>
      </select>
    </span>
    <span class="ql-formats">
      <button class="ql-header" value="1"></button>
      <button class="ql-header" value="2"></button>
    </span>
    <span class="ql-formats">
      <button class="ql-bold"></button>
      <button class="ql-italic"></button>
      <button class="ql-underline"></button>
      <button class="ql-strike"></button>
    </span>
    <span class="ql-formats">
      <select class="ql-color"></select>
      <select class="ql-background"></select>
    </span>
    <span class="ql-formats">
      <button class="ql-list" value="ordered"></button>
      <button class="ql-list" value="bullet"></button>
      <select class="ql-align">
        <option selected></option>
        <option value="center"></option>
        <option value="right"></option>
        <option value="justify"></option>
      </select>
    </span>
    <span class="ql-formats">
      <button class="ql-link"></button>
      <!-- <button class="ql-image"></button> -->
    </span>
    <span class="ql-formats">
      <!--
          <span class="ql-formats">
      <button [title]="'Salutation'" (click)="onAddSalutation()">
        <mat-icon fontSet="fontawesome" fontIcon="fa-bullhorn"></mat-icon>
      </button>
      <button [title]="'Signature'" (click)="onAddSignature()">
        <mat-icon fontSet="fontawesome" fontIcon="fa-pencil"></mat-icon>
      </button>
      <button [title]="'Unsubscribe'" (click)="onAddUnsubscribeLink()">
        <mat-icon fontSet="fontawesome" fontIcon="fa-optin-monster"></mat-icon>
      </button>
    </span>
          -->
      <button *ngIf="variables && variables.length > 0" mat-raised-button style="height: 34px; line-height: inherit"
        [matMenuTriggerFor]="menu">{{'INSERT' | translate}}</button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="insertVariable(variable)" *ngFor="let variable of variables">{{variable.label |
          translate}}</button>
      </mat-menu>
    </span>
  </div>
</quill-editor>
