import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { DateUtils } from 'src/app/flo/dates/DateUtils';
import { CreatePetAppointmentV2SyncService } from '../create-pet-appointment-v2-sync.service';

@Component({
  selector: 'app-pickup-service',
  templateUrl: './pickup-service.component.html',
  styleUrls: ['./pickup-service.component.scss']
})
export class PickupServiceComponent implements OnInit, OnChanges {
  @Input() pickupRequired = false;
  @Input() collectionTime = new Date();
  @Input() pickupAddress = '';
  @Input() selectedIcons = [];

  constructor(
    private dateUtils: DateUtils,
    public createPetAppointmentV2SyncService: CreatePetAppointmentV2SyncService
  ) { }

  ngOnInit() {
    if (this.createPetAppointmentV2SyncService.startTime) {
      this.collectionTime = this.dateFromTime(
        this.dateUtils.subtractMinsToTimeString(this.createPetAppointmentV2SyncService.startTime, 30));

    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.collectionTime && typeof changes.collectionTime.currentValue === 'string') {
        this.collectionTime = this.dateFromTime(changes.collectionTime.currentValue);
      }
    }
  }

  dateFromTime(dStr) {
    const now = new Date();
    const time = dStr.split(':');
    now.setHours(time[0]);
    now.setMinutes(time[1]);
    now.setSeconds(0);
    return now;
  }

}
