<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <div cdkDragHandle color="primary" class="mdc-px-3 mdc-py-2"
    style="background-color: var(--currentStepColor);color: #FFF">

    <div fxLayout="row wrap" fxLayout.gt-sm="row" fxLayoutAlign=" start" fxLayoutAlign.gt-sm=" center" class="w-100"
      *ngIf="!data.edit">
      <span fxFlex fxFlex.gt-sm="nogrow">
        <h5 class="text-truncate my-2" *ngIf="!data.rebook">{{'CREATE_BOOKING' | translate}}</h5>
        <h5 class="text-truncate my-2" *ngIf="data.rebook">{{'REBOOK_BOOKING' | translate}}</h5>
      </span>

      <div fxFlex="nogrow" fxFlex.gt-sm
        *ngIf="(!boardingBooking.id) || (deviceService.isMobile() || deviceService.isTablet())" fxFlex fxLayout="row"
        fxLayoutAlign=" center" class="pb-0 pb-md-0 text-center">
        <div *ngIf="!data.edit" [ngClass]="{'text-md-center': !deviceService.isMobile()}" style="margin: 0 auto">
          <mat-button-toggle-group [(ngModel)]="createBoardingBookingV2SyncService.bookingType"
            (ngModelChange)="bookingTypeChanged($event)" name="bookingType" aria-label="Booking Type"
            class="button-group-payments ml-1">
            <mat-button-toggle [value]="'BOARDING'"><mat-icon class="mr-5px">house</mat-icon>
              {{'BOARDING' | translate}}
              <!-- <span class="d-none d-sm-inline-block">Customer</span> -->
            </mat-button-toggle>
            <mat-button-toggle [value]="'DAY_CARE'"><mat-icon class="mr-5px">wb_sunny</mat-icon>
              {{'DAY_CARE' | translate}}</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>

      <div fxFlex="nogrow" class="mnwi-138px">
        <div class="w-100" *ngIf="data?.calendarSettings?.multiLocationEnabled">
          <app-location-selector-v2 class=" display-block location-select" [contextIdDto]="contextIdDto"
            [locationIdSelected]="boardingBooking?.locationId" (locationChange)="locationChange($event)"
            #locationSelectorV2Component>
          </app-location-selector-v2>
        </div>
      </div>
      <div fxFlex="nogrow">
        <button mat-icon-button aria-label="Close" (click)="cancel()"
          *ngIf="deviceService.isMobile() || deviceService.isTablet()">
          <mat-icon class="full-screen-icon">close</mat-icon>
        </button>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign=" center" class="w-100" *ngIf="data.edit">
      <span fxFlex="100%" fxFlex.gt-sm="nogrow" class="header-icon">
        <!-- <mat-icon class="back-icon" (click)="cancel()">keyboard_backspace</mat-icon> -->
        <h5 class="text-truncate my-2" *ngIf="createBoardingBookingV2SyncService.bookingType === 'BOARDING'">
          <mat-icon class="mr-5px">house</mat-icon>
          {{'EDIT_BOARDING_BOOKING' | translate}}
        </h5>
        <h5 class="text-truncate my-2" *ngIf="createBoardingBookingV2SyncService.bookingType === 'DAY_CARE'">
          <mat-icon class="mr-5px">wb_sunny</mat-icon>
          {{'EDIT_DAY_CARE_BOOKING' | translate}}
        </h5>
      </span>
      <div class="example-spacer"></div>
      <div fxFlex="nogrow" *ngIf="data?.calendarSettings?.multiLocationEnabled">
        <app-location-selector-v2 class=" display-block location-select" [contextIdDto]="contextIdDto"
          [locationIdSelected]="boardingBooking?.locationId" (locationChange)="locationChange($event)"
          #locationSelectorV2Component>
        </app-location-selector-v2>
      </div>
      <div fxFlex="nogrow">
        <button mat-icon-button aria-label="Close" (click)="cancel()"
          *ngIf="deviceService.isMobile() || deviceService.isTablet()">
          <mat-icon class="full-screen-icon">close</mat-icon>
        </button>
      </div>
    </div>

  </div>

  <app-boarding-date-selector
    *ngIf="appointmentConfigHelperService?.appointmentConfig && createBoardingBookingV2SyncService.bookingType === 'BOARDING'"
    class="mt-15px display-block" [contextIdDto]="contextIdDto"
    [(startDate)]="createBoardingBookingV2SyncService.startDate"
    [(endDate)]="createBoardingBookingV2SyncService.endDate"
    [(startTime)]="createBoardingBookingV2SyncService.startTime"
    [(endTime)]="createBoardingBookingV2SyncService.endTime" [totalAppointmentDuration]="totalAppointmentDuration"
    [rebook]="data.rebook">
  </app-boarding-date-selector>

  <app-day-care-date-selector #dayCareDateSelectorComponent *ngIf="appointmentConfigHelperService?.appointmentConfig && createBoardingBookingV2SyncService.bookingType === 'DAY_CARE'
    && createBoardingBookingV2SyncService?.petStayPreference" class="mt-15px display-block"
    [contextIdDto]="contextIdDto" [(startDate)]="createBoardingBookingV2SyncService.startDate"
    [(endDate)]="createBoardingBookingV2SyncService.endDate"
    [(startTime)]="createBoardingBookingV2SyncService.startTime"
    [(endTime)]="createBoardingBookingV2SyncService.endTime" [totalAppointmentDuration]="totalAppointmentDuration"
    [rebook]="data.rebook" [petStayPreference]="createBoardingBookingV2SyncService?.petStayPreference"
    [workingHours]="createBoardingBookingV2SyncService?.workingHours">
  </app-day-care-date-selector>



  <div class="mdc-px-3 ">

    <app-pet-run-selector
      *ngIf="createBoardingBookingV2SyncService?.petStayPreference && createBoardingBookingV2SyncService?.bookingType"
      class="display-block" #petRunSelectorComponent [contextIdDto]="contextIdDto" [allowedToRemove]="true"
      [petStayPreference]="createBoardingBookingV2SyncService?.petStayPreference"
      [bookingType]="createBoardingBookingV2SyncService?.bookingType" (updateCustFlags)="updateCustFlags()">
    </app-pet-run-selector>

    <div fxLayout="row" class="mdc-mb-1">
      <div fxFlex>
        <button id="boarding-add-run" mat-button color="accent"
          (click)="createBoardingBookingV2SyncService.addRunNew(contextIdDto)">
          <mat-icon>add_circle_outline</mat-icon>
          {{'ANOTHER_RUN' | translate}}
        </button>
      </div>
      <div fxFlex="nogrow">
        <button id="boarding-add-run" mat-button color="accent" (click)="createCustomer()">
          <mat-icon>person_add</mat-icon>
          {{'NEW_CUSTOMER' | translate}}
        </button>
      </div>
    </div>

    <!-- <app-pickup-service #pickupServiceComponent [pickupRequired]="boardingBooking.pickupRequired"
          [collectionTime]="boardingBooking.collectionTime" [pickupAddress]="boardingBooking.pickupAddress">
        </app-pickup-service> -->

    <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="5px">
      <div fxFlex="100">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'BOARDING_NOTES' | translate}}</mat-label>
          <textarea id="appointment-notes" matInput [(ngModel)]="notes" [ngModelOptions]="{standalone: true}"
            rows="2"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign=" center"
      class="petservice mdc-mb-2 rounded-10 border mdc-p-3" *ngIf="createBoardingBookingV2SyncService.totalAmount">
      <app-boarding-payment fxFlex="grow" class="my-2" #paymentComponent [contextIdDto]="contextIdDto"
        [customerId]="createBoardingBookingV2SyncService?.customerId"
        [totalAmount]="createBoardingBookingV2SyncService.totalAmount" [(paymentConfig)]="boardingBooking.paymentConfig"
        (paymentConfigChange)="paymentConfigChanged($event)" (amountDueChanged)="amountDueChanged($event)"
        (createPlanChanged)="createPlanChanged($event)"></app-boarding-payment>
      <app-pet-confirm-boarding-v2 fxFlex="100" fxFlex.gt-sm="45" align="end" #confirmAppointmentV2Component
        [contextIdDto]="contextIdDto">
      </app-pet-confirm-boarding-v2>
    </div>
    <!-- <div *ngIf="createBoardingBookingV2SyncService?.selectedRuns?.length && !data.edit && !data.rebook">
          <mat-slide-toggle id="appointment-repeat" [labelPosition]="'before'" class="mdc-mb-1"
            [(ngModel)]="isRepeatAvailable">
            Repeat Appointment?
          </mat-slide-toggle>
          <br>
          <app-repeat-appointments *ngIf="isRepeatAvailable" (checkConflictApi)="checkConflictApi($event)"
            [selectedDay]="selectedDay" [startTime]="startTime"
            [startDate]="createBoardingBookingV2SyncService?.startDateReq" #repeatAppointmentComponent>
          </app-repeat-appointments>
        </div> -->


    <mat-card appearance="outlined" class="mx-0 mdc-mb-2" *ngIf="showConfirmationDialog">
      <mat-card-header class="close-confirm">
        <mat-icon (click)="closeConfirmDialog()">cancel</mat-icon>
      </mat-card-header>
      <mat-card-content>
        <div fxLayout="row" fxLayoutAlign=" center">
          <div fxFlex>
            <h6><strong>Confirmation!</strong></h6>
            <p class="small mat-text-secondary mb-0">
              <strong>{{'BY_CONFIRMING_SYSTEM_NOTIFS' | translate}}</strong>
            </p>
            <p class="small mat-text-secondary mb-0">
              <mat-checkbox [(ngModel)]="confirmEnabled" (ngModelChange)="updateConfirmEnabled($event)">
                {{'DONT_SHOW_MESSAGE_AGAIN' | translate}}
              </mat-checkbox>
            </p>
          </div>
          <div fxFlex="nogrow">
            <button mat-raised-button color="accent" (click)="confirmAppointment()">{{'OK' | translate}}</button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card appearance="outlined" class="mx-0 mdc-mb-2" *ngIf="checkConflictsDialog">
      <mat-card-header class="close-confirm">
        <mat-icon (click)="checkConflictsDialog = false">cancel</mat-icon>
      </mat-card-header>
      <mat-card-content>
        <div fxLayout="row" fxLayoutAlign=" center">
          <div fxFlex>
            <h6><strong>{{'CLASHES' | translate}}</strong></h6>
            <p class="small mat-text-secondary mb-0">
              <ng-container *ngFor="let clash of clashes">
                <ng-container *ngIf="clash.clashedCalendarEntries[0].entryType === 'userHours'
                  || clash.clashedCalendarEntries[0].entryType === 'userOff'">
                  <span *ngIf="clash.clashedCalendarEntries[0].detailedLabel === 'Off'">
                    {{'CLOSED_CLASHES' | translate}}
                  </span>
                  <span *ngIf="clash.clashedCalendarEntries[0].detailedLabel !== 'Off'">
                    {{clash.clashedCalendarEntries[0].detailedLabel}}
                  </span>
                </ng-container>
                <ng-container
                  *ngIf="clash.clashedCalendarEntries[0].entryType !== 'userOff' && clash.clashedCalendarEntries[0].entryType !== 'userHours'">
                  {{'CLASHES_WITH' | translate}} <span
                    [innerHTML]="clash.clashedCalendarEntries[0].dayCellLinesHtml"></span>
                </ng-container>

              </ng-container>
            </p>
            <p class="small mat-text-secondary mb-0">
              <mat-checkbox [(ngModel)]="bypassConflictCheck" (ngModelChange)="updateConflictCheck($event)">
                {{'NEVER_CHECK_CONFLICTS' | translate}}
              </mat-checkbox>
            </p>
          </div>
          <div fxFlex="nogrow">
            <button mat-raised-button color="accent" (click)="createAnyway()">
              <span *ngIf="data.edit">{{'UPDATE' | translate}}</span><span *ngIf="!data.edit">{{'CREATE' |
                translate}}</span> {{'ANYWAY' | translate}}
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="16px" fxLayoutAlign=" center" class="pb-2">
      <div fxFlex>
        <div fxLayout="row" fxLayoutAlign=" center">
          <div fxFlex="nogrow" class="icon-24 mat-text-secondary" fxFlexAlign="center">
            <mat-icon style="vertical-align: top">event</mat-icon>
          </div>
          <div fxFlex class="date-time padding-left-half" fxLayout="column">
            <span>
              <strong *ngIf="createBoardingBookingV2SyncService.bookingType === 'BOARDING'">
                <!-- <ng-container *ngIf="createBoardingBookingV2SyncService.petStayPreference?.bookHourly">
                  {{createBoardingBookingV2SyncService.totalHours}} Hour<span
                    *ngIf="createBoardingBookingV2SyncService.totalHours > 1">s</span>
                </ng-container> -->
                <ng-container>
                  <span>
                    {{createBoardingBookingV2SyncService.totalNights}} {{'NIGHT' | translate}}<span
                      *ngIf="createBoardingBookingV2SyncService.totalNights > 1">s</span>/</span>
                  {{createBoardingBookingV2SyncService.totalDays}} {{'DAY' | translate}}<span
                    *ngIf="createBoardingBookingV2SyncService.totalDays > 1">s</span>
                </ng-container>
              </strong>
              <strong *ngIf="createBoardingBookingV2SyncService.bookingType === 'DAY_CARE'">
                <ng-container *ngIf="createBoardingBookingV2SyncService.petStayPreference?.bookHourly">
                  {{createBoardingBookingV2SyncService.totalHours}} {{'HOUR' | translate}}<span
                    *ngIf="createBoardingBookingV2SyncService.totalHours > 1">s</span>
                </ng-container>
                <ng-container *ngIf="!createBoardingBookingV2SyncService.petStayPreference?.bookHourly">
                  {{createBoardingBookingV2SyncService.totalDays}} {{'DAY' | translate}}<span
                    *ngIf="createBoardingBookingV2SyncService.totalDays > 1">s</span>
                </ng-container>
              </strong>
              <span *ngIf="createBoardingBookingV2SyncService.bookingType === 'BOARDING'"> {{'BOARDING' |
                translate}}</span>
              <span *ngIf="createBoardingBookingV2SyncService.bookingType === 'DAY_CARE'"> {{'DAY_CARE' |
                translate}}</span> {{'SELECTED' | translate}}
            </span>
            <small class=" mat-text-secondary fs-12">
              {{createBoardingBookingV2SyncService.startDate | localizedDate: 'mediumDate'}}
              {{createBoardingBookingV2SyncService.displayStartTime | localizedDate:'h:mm a'}} to
              {{createBoardingBookingV2SyncService.endDate | localizedDate: 'mediumDate'}}
              {{createBoardingBookingV2SyncService.displayEndTime | localizedDate:'h:mm a'}}
            </small>
          </div>
        </div>
      </div>
      <!-- <div fxFlex="nogrow" [hidden]="appointment && boardingBooking.status !== 'Created'">
          <mat-radio-group aria-label="Select an option" [(ngModel)]="appointmentStatus" name="appointmentStatus"
            class="mr-1">
            <mat-radio-button value="tentative"
              matTooltip="This will save the booking as a draft and won't sent any Email/SMS notifications to the customer.">
              Tentative</mat-radio-button>
            <mat-radio-button value="confirm" class="ml-1"
              matTooltip="This will confirm the booking immediately and will send Email/SMS notifications to the customer.">
              Confirmed</mat-radio-button>
          </mat-radio-group>
        </div> -->

      <div fxFlex="nogrow" fxFlexOrder="3">
        <mat-menu #createAppointmentNotes="matMenu">
          <div class="mdc-p-3">
            <p *ngIf="!data.edit"><strong>{{'CREATE' | translate}}</strong>: {{getMessage('create')}}
            </p>
            <p *ngIf="!data.edit"><strong>{{'CREATE_AND_CONFIRM' | translate}}</strong>:
              {{getMessage('createAndConfirm')}}
            </p>

            <p *ngIf="data.edit"><strong>{{'UPDATE' | translate}}</strong>: {{getMessage('update')}}
            </p>
            <p *ngIf="data.edit"><strong>{{'UPDATE_AND_RECONFIRM' | translate}}</strong>:
              {{getMessage('updateAndConfirm')}}
            </p>
          </div>
        </mat-menu>
        <mat-icon *ngIf="createBoardingBookingV2SyncService.totalAmount" class="vm cp mr-5px"
          [matMenuTriggerFor]="createAppointmentNotes" color="accent">info</mat-icon>
        <button id="create-appointment-button" *ngIf="!data.edit" type="button" class="mt-0 mb-0 mr-5px"
          mat-raised-button color="accent" (click)="createAppointment()"
          [disabled]="!createBoardingBookingV2SyncService?.selectedRuns?.length" matTooltip="{{getMessage('create')}}">
          {{'CREATE' | translate}}
        </button>
        <button id="create-confirm-appointment-button" *ngIf="!data.edit" type="button" class="mt-0 mb-0 mr-5px"
          mat-raised-button color="accent" (click)="createAndConfirm()"
          [disabled]="!createBoardingBookingV2SyncService?.selectedRuns?.length || showConfirmationDialog || createNewPlan"
          matTooltip="{{getMessage('createAndConfirm')}}">
          {{'CREATE_AND_CONFIRM' | translate}}
        </button>
        <button id="update-appointment-button" *ngIf="data.edit" type="button" class="mt-0 mb-0 mr-5px"
          mat-raised-button color="accent" (click)="updateAppointment()"
          [disabled]="!createBoardingBookingV2SyncService?.selectedRuns?.length" matTooltip="{{getMessage('update')}}">
          {{'UPDATE' | translate}}
        </button>
        <button id="update-confirm-appointment-button" *ngIf="data.edit" type="button" class="mt-0 mb-0"
          mat-raised-button color="accent" (click)="updateAndConfirmAppointment()"
          [disabled]="!createBoardingBookingV2SyncService?.selectedRuns?.length || showConfirmationDialog || createNewPlan"
          matTooltip="{{getMessage('updateAndConfirm')}}">
          {{'UPDATE_AND_RECONFIRM' | translate}}
        </button>
      </div>
    </div>

  </div>
</div>