<div [formGroup]="form">

   <app-field-instance
            *ngIf="elementDto.elementInstanceDto.elementType.toString() === 'FIELD_INSTANCE'"
            [contextIdDto]="contextIdDto"
            [elementDto]="elementDto"
            [viewContext]="viewContext"
            [additionalDataMapDto]="additionalDataMapDto"
            [eventBus]="eventBus"
            [showPlaceholder]="showPlaceholder"
            [form]="form"
            [changeListener]="changeListener">

    </app-field-instance>

    <app-entity-element-set-definition
            *ngIf="elementDto.elementInstanceDto.elementType.toString() === 'ELEMENT_SET_VALUE'"
            [contextIdDto]="contextIdDto"
            [elementSetDefinition]="getDefinition().elementSetDefinitionDto"
            [viewContext]="viewContext"
            [additionalDataMapDto]="additionalDataMapDto"
            [elementInstanceDto]="elementDto.elementInstanceDto"
            [showPlaceholder]="showPlaceholder"
            [form]="form"
            [changeListener]="changeListener">

    </app-entity-element-set-definition>

</div>
