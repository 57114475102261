<div *ngIf="visible">
  <mat-toolbar *ngIf="!model.id" color="primary">{{'CREATE_TASK' | translate}}</mat-toolbar>
  <mat-toolbar *ngIf="model.id" color="primary">{{'EDIT_TASK' | translate}}</mat-toolbar>


  <mat-card appearance="outlined" *ngIf="visible">

    <mat-card-content>

      <form #taskForm="ngForm">

        <mat-form-field class="example-full-width" appearance="outline" style="width: 100%;">
          <mat-label>{{'TASK_NAME' | translate}}</mat-label>
          <input matInput [(ngModel)]="model.name" name="name" #name="ngModel" class="demo-full-width"
            placeholder="{{'LABEL' | translate}}" style="width: 100%" required>
        </mat-form-field>


        <mat-form-field class="example-full-width" appearance="outline" style="width: 100%;">
          <mat-label>{{'DESC_TASK' | translate}}</mat-label>
          <input matInput [(ngModel)]="model.description" name="description" #description="ngModel"
            class="demo-full-width" placeholder="{{'DESCRIPTION' | translate}}" style="width: 100%" required>
        </mat-form-field>

        <div style="width: 100%;">

          <span>{{'ASSIGN_TO_WHICH_ROLES' | translate}}</span>
          <p></p>

          <div *ngFor="let role of roles; let i = index">
            <mat-checkbox class="example-margin" (change)="onChange($event)" [value]="role.roleId">{{role.name}}
            </mat-checkbox>
          </div>
        </div>

        <mat-form-field appearance="outline">
          <mat-label>{{'DUE_DATE' | translate}}</mat-label>
          <input matInput #dueDate name="dueDate" placeholder="{{'DUE_DATE' | translate}}"
            [owlDateTimeTrigger]="dtPicker2" [owlDateTime]="dtPicker2" [(ngModel)]="model.dueDate">
          <mat-icon matSuffix>event</mat-icon>

          <owl-date-time #dtPicker2></owl-date-time>

        </mat-form-field>


        <!--
        <p>
          <mat-select *ngIf="users" [(ngModel)]="model.userContextId"
                     name="userContextId" #userContextId="ngModel"
                     class="demo-full-width"
                     style="width: 100%"
                     placeholder="Select a user to assign the task to">

            <mat-option *ngFor="let user of users"
                       [value]="user.id">{{user.firstName}} {{user.lastName}}</mat-option>
          </mat-select>
        </p>
        -->
      </form>
    </mat-card-content>
    <mat-card-content>
      <button mat-raised-button color="accent" *ngIf="!model.id" type="submit" (click)="addTask()"
        [disabled]="!taskForm.form.valid" disabled="disabled">{{'ADD' | translate}}
      </button>
      &nbsp;&nbsp;
      <button mat-raised-button color="accent" *ngIf="model.id" type="submit" (click)="updateTask()"
        [disabled]="!taskForm.form.valid" disabled="disabled">{{'UPDATE' | translate}}
      </button>
      <button mat-flat-button type="submit" (click)="cancelAddEditTask()">{{'CANCEL' | translate}}</button>
    </mat-card-content>
  </mat-card>
</div>