<mat-toolbar color="primary">
  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
      <span>{{'PETS' | translate}} {{'(' + petsCount + ')'}}</span>
    </div>
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="end center">
      <button mat-raised-button class="mx-4" (click)="create()" color="accent">{{'ADD_PET' | translate}}</button>
      <button color="accent" class="mx-4" mat-raised-button (click)="showPetTypes()">
        {{'SHOW_PET_TYPES' | translate}}
      </button>
      <mat-button-toggle-group [(ngModel)]="viewMode" (ngModelChange)="viewChanged($event)" name="viewMode"
        aria-label="Alphabetic" class="button-group-payments mx-4">
        <mat-button-toggle [value]="'grid'">{{'GRID_VIEW' | translate}}</mat-button-toggle>
        <mat-button-toggle [value]="'alpha'">{{'ALPHA_VIEW' | translate}}</mat-button-toggle>
      </mat-button-toggle-group>
      <button mat-button class="mx-4" (click)="showFilters = !showFilters"><mat-icon>filter_alt</mat-icon>
        {{'SHOW_FILTERS' | translate}}</button>
    </div>
  </div>
</mat-toolbar>



<app-list-pets-alpha *ngIf="viewMode == 'alpha'"></app-list-pets-alpha>
<app-list-pets-grid *ngIf="viewMode == 'grid'" [showFilters]="showFilters"></app-list-pets-grid>