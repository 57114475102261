<div fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px grid">
    <div [fxFlex]="maxWidth">
      <mat-form-field appearance="outline" [fxFlex]="maxWidth">
        <mat-label>{{label | translate}}</mat-label>
        <input matInput #searchInput="matInput" placeholder="{{'START_TYPING' | translate}} {{label}}" [matAutocomplete]="auto"
          autofocus (keyup)="onKey($event)" autocomplete="off" type="text" [readonly]="readonly" [required]="required">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)"
          (optionSelected)="handler($event)">
          <mat-option disabled *ngIf="customers && !customers.length" value="">
            {{ label + 'NOT_FOUND' | translate}}
          </mat-option>
          <mat-option *ngFor="let tuple of customers" [value]="tuple.id">
            {{ tuple.name }}
          </mat-option>
        </mat-autocomplete>
        <!-- <button *ngIf="readonly" mat-icon-button color="accent" (click)="viewCustomer()" matTooltip="View Customer"
          matTooltipPosition="above" matSuffix>
          <mat-icon>info</mat-icon>
        </button> -->
      </mat-form-field>
    </div>
  </div>
</div>
