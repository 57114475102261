import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';
import { Subject } from 'rxjs';
import { ContextIdDto } from '@savvy/view-definition';
import { MergePet, Pet, PetService } from "@savvy/pet";

@Component({
  selector: 'app-merge-pet',
  templateUrl: './merge-pet-modal.component.html',
  styleUrls: ['./merge-pet-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MergePetModalComponent implements OnInit {

  @Input() customerId: string;

  private destroy$ = new Subject();

  sending = false;

  // Passed in data
  contextIdDto: ContextIdDto;

  pet: string;


  sourcePet: Pet;
  targetPet: Pet;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MergePetModalComponent>,
    private petService: PetService,
    private notify: FloSnackbarComponent
  ) {
    this.contextIdDto = data.contextIdDto;
    this.customerId = data.customerId;
  }

  ngOnInit(): void {
    if (this.data.contextIdDto) {
      this.contextIdDto = this.data.contextIdDto;
    }
    if (this.data.customerId) {
      this.customerId = this.data.customerId;

    }
  }


  sourcePetChanged(petId: string) {

    console.log('got source ', petId);
    this.petService.get(petId).subscribe(response => {
      this.sourcePet = response;
    });
  }

  targetPetChanged(petId: string) {
    console.log('got target ', petId);

    this.petService.get(petId).subscribe(response => {
      this.targetPet = response;
    });
  }


  merge() {

    const req = <MergePet>{};
    req.petId = this.sourcePet.id;
    if (this.targetPet) {
      req.duplicatePetId = this.targetPet.id;
      this.petService.mergePet(req).subscribe(response => {
        this.notify.message = 'Merged Customer';
        this.notify.open();
        this.dialogRef.close(this.sourcePet);
      })
    }
  }
}
