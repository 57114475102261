<mat-card appearance="outlined" *ngIf="showFilters" class="mdc-m-0">
  <mat-card-content class="mdc-pb-0">
    <div fxLayout="row wrap" fxLayoutAlign="" fxLayoutGap="5px" fxLayoutGap.gt-md="1%">
      <!-- <div fxFlex="100" fxFlex.gt-md="nogrow">
        <mat-radio-group aria-label="Show Archived?" [ngModel]="includeDeleted" (ngModelChange)="includeDeleted = $event; listInstances()">
          <mat-radio-button class="example-radio-button px-8" [value]="true">
            <div class="text-bold">{{'SHOW_ARCHIVED' | translate}}</div>
          </mat-radio-button>
          <mat-radio-button class="example-radio-button px-8" [value]="false">
            <div class="text-bold">{{'HIDE_ARCHIVED' | translate}}</div>
          </mat-radio-button>
        </mat-radio-group>
      </div> -->
      <div fxFlex="100" fxFlex.gt-xs="49" fxFlex.gt-sm="20">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Customer, Email, Phone...</mat-label>
          <input matInput placeholder="Customer Name, Email, Phone..." [(ngModel)]="customerFilter.searchKey" (ngModelChange)="searchKeyUpdated($event)">
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
      <div fxFlex="100" fxFlex.gt-xs="49" fxFlex.gt-sm="18">
        <!-- make this multi select-->
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Customer Status</mat-label>
          <mat-select [(ngModel)]="customerFilter.status" (ngModelChange)="statusChanged($event)">
            <mat-option [value]="statusEnum.Active">Active</mat-option>
            <!--mat-option [value]="statusEnum.InActive">Inactive</mat-option-->
            <mat-option [value]="statusEnum.Archived">Archived Only</mat-option>
            <mat-option [value]="statusEnum.NotVerified">Needs Verification</mat-option>
            <mat-option [value]="statusEnum.AwaitingRegistration">Awaiting Form</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="100" fxFlex.gt-xs="100" fxFlex.gt-sm="36">
        <app-flag-filter (includeFlagsChanged)="includeFlagsChange($event);" (excludeFlagsChanged)="excludeFlagsChange($event);" [contextIdDto]="contextIdDto" [flagDefinitionType]="'CUSTOMER'" #flagFilterComponent></app-flag-filter>
      </div>
      <!-- <div fxFlex="nogrow">
        <p class="small mat-text-secondary mb-0 py-1">Archived Customer</p>
        <mat-slide-toggle [(ngModel)]="includeDeleted" (ngModelChange)="listInstances()">Show
          Archived</mat-slide-toggle>
      </div> -->
      <div fxFlex></div>
      <div fxFlex="nogrow">
        <button (click)="resetFilters()" mat-button color="accent" class="mt-1 mdc-mb-1 mb-md-0"><mat-icon>cancel</mat-icon>
          Clear Filter</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<!-- <div class="mdc-p-3">
  <h6 class="resulttext">
    <span>Showing</span> "<span>365</span>" result

    <span>for your search "<span>Suz</span>", </span>
    <span>"<span>Active</span>" customer &nbsp;</span>

    <span>
      with flags
      <span>including "<span>VIP</span>, <span>VIP</span>" &nbsp;</span>
      <span>excluding "<span>noVIP</span>, <span>noVIP</span>"</span>
    </span>

  </h6>
</div> -->

<div>
  <alphabet-filter #alphabetFilterComponent [propAlphaOrder]="'firstName'" [propsSearch]="'firstName'" placeholder="{{'SEARCH_CUSTOMER_FNAME' | translate}}" listClass="search-list" [data]="customers" (onClick)="onSelectAlpha($event)" [withTemplate]="true" height="calc(100vh - 140px)">
    <ng-template let-item>
      <div class="search-list-item">
        <img [src]="getCustomerImage(item)">
        <span (click)="edit(item)">{{item?.firstName}} {{item?.lastName}}</span>
        <span *ngIf="!item.id">
          <button mat-raised-button color="accent" (click)="loadAllCustomers()">
            Reset
          </button>
        </span>
        <span *ngIf="item.id">
          <p *ngIf="item.email"><a href="mailto: {{ item.email }}"><span class="chiptag theme-accent vm mt-5px"><mat-icon>email</mat-icon> {{ item.email }}</span></a></p>
          <p *ngIf="item.mobileInternational"><a href="tel: {{ item.mobileInternational }}"><span class="chiptag theme vm mt-5px"><mat-icon>call</mat-icon> {{ item.mobileInternational }} </span></a>
          </p>
          <app-view-customer-flags *ngIf="item?.flags?.length" [contextIdDto]="contextIdDto" [flags]="item.flags">
          </app-view-customer-flags>
        </span>
        <span class="action-button" *ngIf="item.id">
          <button mat-button color="primary" (click)="view(item)">
            <mat-icon>visibility</mat-icon>
            <span>View</span>
          </button>
          <button mat-button color="accent" (click)="edit(item)">
            <mat-icon>edit</mat-icon>
            <span>Edit</span>
          </button>
          <button *ngIf="item?.approvalNeeded" class="ml-1" mat-button color="primary" (click)="verify(item); $event.stopImmediatePropagation();"><mat-icon>verified</mat-icon> {{'VERIFY' |
            translate: {Default: 'Verify'} }}</button>
          <button *ngIf="!item.deleted" mat-button (click)="archive(item); $event.stopImmediatePropagation();"><mat-icon>inventory_2</mat-icon> {{'ARCHIVE' |
            translate}}</button>
          <button *ngIf="item.deleted" mat-button (click)="unarchive(item); $event.stopImmediatePropagation();"><mat-icon>unarchive</mat-icon> {{'UN_ARCHIVE'
            | translate}}</button>
          <button *ngIf="item.deleted" title="{{'PERM_DELETE' | translate}}" mat-button (click)="deleteCustomerForever(item); $event.stopImmediatePropagation();"><mat-icon>delete</mat-icon>
            {{'DELETE' | translate}}</button>
        </span>
      </div>
    </ng-template>
  </alphabet-filter>
</div>