<div fxLayout="row" [formGroup]="form" *ngIf="linkedEntityDefinition && elementInstanceDto.elementInstanceViewDto.viewable">

<!-- If typeahead -->


  <mat-form-field appearance="outline" style="width: 100%;" *ngIf="linkedEntityDefinition && elementInstanceDto.instanceId">
    <mat-label>{{linkedEntityDefinition.label | translate}}</mat-label>

    <input matInput placeholder="{{linkedEntityDefinition.placeholder | translate}}"
           [required]="linkedEntityDefinition.required"
           [matAutocomplete]="auto1"
           [formControlName]="elementInstanceDto.instanceId"
           (keyup)="onKey($event)">

    <mat-autocomplete #auto1="matAutocomplete"
                      [displayWith]="displayFn.bind(this)"
                      autoActiveFirstOption>
      <mat-option *ngFor="let tuple of entityInstanceIdFieldValueTupleDtos" (onSelectionChange)="changedSelection(tuple.id)" [value]="tuple.id">
        {{ tuple.name }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="form.controls[elementInstanceDto.instanceId].hasError('required')">
      {{linkedEntityDefinition.label + ' is' | translate}} <strong>{{'required' | translate}}</strong>
    </mat-error>
    <mat-error *ngIf="!form.controls[elementInstanceDto.instanceId].hasError('required') && form.controls[elementInstanceDto.instanceId].hasError('doesNotSelected')">
      {{linkedEntityDefinition.label + ' is' | translate}} <strong>{{'required' | translate}}</strong>.
      {{'Please Select one of the choices or create it' | translate}}.
    </mat-error>
  </mat-form-field>


<!--
  <div *ngIf="linkedEntityDefinition && elementInstanceDto.instanceId &&
                            !additionalDataMapDto.elementDefDataMap[elementInstanceDto.elementDefinitionId]">

    MISSING IMPLEMENT THIS AGAIN!!  THIS IS WHERE TYPEAHEAD
  </div>-->




  <div *ngIf="linkedEntityDefinition.allowCreate">
    <span style="text-align: center; padding: 15px">{{'OR' | translate}}</span>

    <button (click)="createNewLinkedEntity()" mat-fab>
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>
