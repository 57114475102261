import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog
} from '@angular/material/dialog';
import { ContextIdDto } from '@savvy/app';
import { ProductCompositeService, Product, FileReference } from '@savvy/products';
import { Tax } from '@savvy/tax';
import { TaxesHelperService } from 'src/app/flo/shared/services/taxes-helper.service';
import { ConfirmationDialogComponent } from "../../../../../confirmation-dialog/confirmation-dialog.component";
import * as _ from 'lodash';

export class UpdatedProduct {
  product: Product;
  deleteOld: boolean;
}
@Component({
  selector: 'app-create-product',
  templateUrl: './create-product.component.html',
  styleUrls: ['./create-product.component.scss']
})
export class CreateProductComponent implements OnInit {

  product: Product = {} as Product;
  categories = [];
  taxDefs: Tax[] = [];
  contextIdDto: ContextIdDto;
  productToEdit: Product;
  constructor(
    public dialogRef: MatDialogRef<CreateProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private productCompositeService: ProductCompositeService,
    private taxesHelperService: TaxesHelperService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.contextIdDto = this.data.contextIdDto;
    this.taxesHelperService.getTaxDefs(this.contextIdDto).then(res => {
      this.taxDefs = res;
    });
    // this.product.productName = `New Product ${Date.now()}`;
    this.categories = this.data.categories;
    if (!this.categories?.length) {
      this.getProductCategories();
    }
    if (this.data.edit) {
      this.product = this.data.product;
      this.productToEdit = _.cloneDeep(this.data.product);
    }
  }

  close() {
    this.dialogRef.close();
  }

  isPriceOrTaxUpdated(): boolean {
    return this.productToEdit.unitPrice != this.product.unitPrice
      || this.productToEdit.tax != this.product.tax;
  }

  add() {
    if (this.data.edit) {
      if (this.isPriceOrTaxUpdated()) {
        const confirmDialog = this.dialog.open(ConfirmationDialogComponent, {
          data: {
            title: 'Update Product',
            message: `This will create a new product and archive the old one. Do you want to proceed?`
          },
          height: 'auto',
          width: '400px',
          panelClass: 'helpwindow'
        });
        confirmDialog.afterClosed().subscribe((result: boolean) => {
          if (result) {
            this.dialogRef.close({ product: this.product, deleteOld: true } as UpdatedProduct);
          }
        });
      } else {
        this.dialogRef.close({ product: this.product, deleteOld: false } as UpdatedProduct);
      }
    } else {
      this.dialogRef.close({ product: this.product, deleteOld: false } as UpdatedProduct);
    }
  }

  updateCategory($event, cell) {
    this.product[cell] = $event.value;
  }

  updateTax($event, cell) {
    this.product[cell] = $event.value;
  }

  setImageIds(images: Array<any>) { // FileValue
    console.log('website.landingPage.imageIds = $event:' + event); 1
    this.product.images = new Array();

    images.forEach(sf => {
      const fileRef = {} as FileReference;
      fileRef.fileReferenceId = sf.fileReferenceId;
      console.log('sf', sf);
      fileRef.mimeType = sf.mimeType;
      this.product.images.push(fileRef);
    });
  }

  imageDeleted(idx: number) {
    if (this.product.images) {
      console.log('before ', this.product.images.length);
      console.log('idx ', idx);
      this.product.images.splice(idx, 1);
      console.log('after splice ' + this.product.images.length);
    }
  }

  getProductCategories() {
    this.productCompositeService.loadProductCategories(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
    ).subscribe(response => {
      this.categories = response.categories;
    });
  }

}
