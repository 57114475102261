import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Customer} from "@savvy/customer";
import {ContextIdDto} from "@savvy/ui";

interface InputData {
    contextIdDto: ContextIdDto,
    customer: Customer
}

@Component({
  selector: 'app-merge-customer-success',
  templateUrl: './merge-customer-success.component.html',
  styleUrls: ['./merge-customer-success.component.scss']
})
export class MergeCustomerSuccessComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: InputData,
    public dialogRef: MatDialogRef<MergeCustomerSuccessComponent>,
  ) { }

  ngOnInit(): void {
  }

  redirect() {
    this.dialogRef.close(this.data.customer);
  }

}
