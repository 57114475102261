<!-- <mat-card class=" mx-0"> -->
<div fxLayout="row wrap" fxLayoutAlign=" center" class="w-100">
  <div fxFlex>
    <h6 class="mb-0 font-weight-bold" matTooltip="{{'ALTERNATIVE_CONTACT_HINT' | translate}}">
      {{'ALTERNATIVE_CONTACTS' | translate}}</h6>
  </div>
  <div fxFlex="nogrow">
    <button mat-button color="accent" (click)="addEditContact()">
      <mat-icon class="vm">add</mat-icon>
      {{'ADD_CONTACT' | translate}}
    </button>
  </div>
</div>
<mat-list *ngIf="contacts?.length; else elseBlockVaccination" class="list-content">
  <mat-list-item *ngFor="let contact of contacts; let i = index" class="contact-list-item">

    <div fxLayout="row wrap" fxLayoutAlign=" center" fxLayoutGap="16px" class="mdc-py-2">
      <div fxFlex="nogrow">
        <a href="tel:{{contact.mobilePhoneNumberObj?.internationalNumber}}" class="avatar avatar-50 circle border-3">
          <mat-icon>call</mat-icon>
        </a>
      </div>
      <div fxFlex>
        <h6 class="mb-0" (click)="addEditContact(i)">{{contact.firstName}} {{contact.lastName}}</h6>
        <p mat-line (click)="addEditContact(i)" class="small mat-text-secondary mb-0">
          {{contact.mobilePhoneNumberObj?.internationalNumber}}
        </p>
      </div>
      <div fxFlex="nogrow">
        <button mat-icon-button color="accent" (click)="deleteContact(i)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>

  </mat-list-item>
</mat-list>
<ng-template #elseBlockVaccination>
  <div class="flexbox-parent">
    <div fxLayout="row" fxLayoutAlign="center center">
      <div style="text-align:center;">
        <div class="avatar avatar-100 circle border-3 mb-2 d-inline-block">
          <mat-icon class="h1 h-auto w-auto">call</mat-icon>
        </div>
        <h6 class="mb-2">{{'NO_CONTACTS' | translate}}.</h6>
      </div>
    </div>
  </div>
</ng-template>
<!-- </mat-card> -->