<mat-card appearance="outlined">
    <mat-card-content>
        <div fxLayout="row">
            <div fxFlex="55">
                <h1 class="ma-0">{{socialWidgetDto.firstName}} <b>{{socialWidgetDto.lastName}}</b></h1>
                <small>{{socialWidgetDto.email}}</small>
                <p class="mt-xs mb-xs">{{socialWidgetDto.phone}}</p>
                <p class="mt-xs mb-xs">{{socialWidgetDto.mobile}}</p>
                <p class="mt-xs mb-xs" *ngIf="socialWidgetDto.role">{{socialWidgetDto.role}}</p>
                <a href="javascript:void(0)" *ngIf="socialWidgetDto.email" class="block mt-xs mb-xs">{{socialWidgetDto.email}}</a>
                <a href="javascript:void(0)" *ngIf="socialWidgetDto.website" class="block mt-xs mb-xs">{{socialWidgetDto.website}}</a>
                <button mat-raised-button color="accent" (click)="editEntityInstance()">{{'Edit Profile' | translate}}</button>
            </div>
            <div fxFlex="45">

                <figure>
                    <div class="text-xs-center">

                        <!-- ONES THAT WORK

                        <ngx-avatars twitterId="LisaGillPhoto" size="70"></ngx-avatars>
                        <ngx-avatars gravatarId="elmosbahihaithem@gmail.com" size="120" [round]="true"></ngx-avatars>


                        -->

                        <!--
                        <ngx-avatars [round]="true" size="120" [gravatarId]="{{socialWidgetDto.email}}"></ngx-avatars>
                        -->

                        <!--
                        gravatarId="elmosbahihaithem@gmail.com"
            name="Steven Wilson"
            -->





                        <img *ngIf="imageUrl" [src]="imageUrl" class="radius-circle" />
                        <!--img *ngIf="imageUrl" width="130" height="130" [src]="imageUrl" class="radius-circle" /-->


                        <ngx-avatars *ngIf="!imageUrl"
                            gravatarId="{{socialWidgetDto.email}}"
                            name="{{socialWidgetDto.firstName}} {{socialWidgetDto.lastName}}"
                            size="130" [style.border-radius]="'50%'"></ngx-avatars>



                        <!--
                        <ngx-avatars
                                src="assets/images/avatar.jpg"
                                size="130" [style.border-radius]="'50%'"></ngx-avatars>
                                -->



                        <!--
                        <ngx-avatars gravatarId="elmosbahihaithem@gmail.com" size="120" [round]="true"></ngx-avatars>
                        -->

                        <!--
                        <ngx-avatars [round]="true" size="120" sourceType="google"
                                    gravatarId="ben.d.gill@gmail.com"
                                    src="assets/images/avatar.jpg"></ngx-avatars>
                                    -->
                        <!--
                        <img src="assets/images/avatar.jpg" alt="" class="radius-circle">
                        -->
                        <!--
                        <div class="text-md mt10">Account Usage</div>
                        -->
                    </div>
                    <!--
                    <div class="ma-xs">
                        <mat-progress-bar mode="determinate" value="40"></mat-progress-bar>
                    </div>
                    <div  class="text-xs-center"><small>234Gb of 879Gb</small></div>
                    -->
                </figure>
            </div>
        </div>
    </mat-card-content>

    <!--
    <hr>
    <mat-card-content>
        <div fxLayout="row" class="text-xs-center">
            <div fxFlex="33">
                <h1 class="ma-0"><b>23,8K</b></h1>
                <small>Followers</small>
            </div>
            <div fxFlex="33">
                <h1 class="ma-0"><b>569</b></h1>
                <small>Following</small>
            </div>
            <div fxFlex="33">
                <h1 class="ma-0"><b>67</b></h1>
                <small>Posts</small>
            </div>
        </div>
    </mat-card-content>

    <hr>
    <mat-card-content>
        <h4 class="mt-0">About</h4>
        <p>Maecenas sed diam eget risus varius blandit sit amet non magna. Curabitur blandit tempus porttitor. Vestibulum id ligula porta felis euismod semper.</p>
    </mat-card-content>
    -->
</mat-card>
