<div *ngIf="!additionalDataMapDto">
  additionalDataMapDto IS EMPTY
</div>

<div *ngIf="additionalDataMapDto">

  <!--
  <div *ngIf="!additionalDataMapDto.elementDefDataMap[elementInstanceDto.elementDefinitionId]">
    additionalDataMapDto does not contain element def id {{ elementInstanceDto.elementDefinitionId }}
  </div>
  -->
  <div *ngIf="additionalDataMapDto.elementDefDataMap[elementInstanceDto.elementDefinitionId]">

    <div *ngIf="!additionalDataMapDto.elementDefDataMap[elementInstanceDto.elementDefinitionId].selectListDto">
      IT IS NOT A SELECT LIST UNDER THAT ELEMENT DEF ID
    </div>
  </div>
</div>


<div [formGroup]="form" *ngIf="elementInstanceDto">

  <mat-form-field appearance="outline" style="width: 100%;" *ngIf="additionalDataMapDto &&
          additionalDataMapDto.elementDefDataMap[elementInstanceDto.elementDefinitionId] &&
          additionalDataMapDto.elementDefDataMap[elementInstanceDto.elementDefinitionId].selectListDto">
    <mat-label>{{selectFieldDefinition.label | translate}}</mat-label>
    <mat-select
                [formControlName]="elementInstanceDto.instanceId"
                [required]="selectFieldDefinition.required"
                placeholder="{{selectFieldDefinition.placeholder | translate}}">

      <button class="add-new-field" mat-raised-button color="accent"
              *ngIf="selectFieldDefinition.datalistId"
              (click)="openAddFieldDefinitionModal()">Add new</button>

        <mat-option
                *ngFor="let item of additionalDataMapDto.elementDefDataMap[elementInstanceDto.elementDefinitionId].selectListDto.selectItemDtoList"
                [value]="item.id">{{item.label}}
        </mat-option>
    </mat-select>
    <mat-error *ngIf="form.controls[elementInstanceDto.instanceId].hasError('required')">
      {{selectFieldDefinition.label + ' is' | translate}} <strong>{{'required' | translate}}</strong>
    </mat-error>
  </mat-form-field>

</div>
