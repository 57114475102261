<div class="mdc-mb-1">
  <h2 class="mt-0"><strong>{{'DEPOSIT_REQUESTS' | translate}}</strong>
    <button *ngIf="!invoice.paidInFull" mat-raised-button color="accent" (click)="addNew()" class="ml-1">Add New
      Request</button>
  </h2>
</div>
<ngx-datatable class="material" [rows]="paymentRequests" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'">
  <ngx-datatable-column name="{{'AMOUNT_DUE' | translate}}" [width]="120" [resizeable]="true">
    <ng-template ngx-datatable-cell-template let-row="row">
      <strong *ngIf="row.percentRequired">{{row.percentOfTotal}} %</strong>
      <strong *ngIf="!row.percentRequired">{{row.paymentAmountInPence / 100 | currency:currencyCode:'symbol'}}</strong>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{'DATE_TO_SEND' | translate}}" [width]="150" [resizeable]="true">
    <ng-template ngx-datatable-cell-template let-row="row">
      <strong>{{row.dateToSend | localizedDate:'mediumDate'}}</strong>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{'DUE_DATE' | translate}}" [width]="150" [resizeable]="true">
    <ng-template ngx-datatable-cell-template let-row="row">
      <strong>{{row.dueDate | localizedDate:'mediumDate'}}</strong>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{'NOTIFICATION_SENT' | translate}}" [width]="120" [resizeable]="true">
    <ng-template ngx-datatable-cell-template let-row="row">
      <strong>{{row.sent ? 'Yes' : 'No'}}</strong>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{'PAID' | translate}}" [width]="80" [resizeable]="true">
    <ng-template ngx-datatable-cell-template let-row="row">
      <strong>{{row.paid ? 'Yes' : 'No'}}</strong>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{'ACTIONS' | translate}}" [width]="150" [resizeable]="true">
    <ng-template ngx-datatable-cell-template let-row="row">
      <ng-container *ngIf="!invoice.paidInFull">
        <strong *ngIf="row.paid" title="Paid">
          <mat-icon>credit_score</mat-icon>
        </strong>
        <strong *ngIf="!row.paid" title="Re-Schedule" class="action-button" (click)="reSchedule(row)">
          <mat-icon>update</mat-icon>
        </strong>
        <strong *ngIf="row.sent && !row.paid" title="Re-Send" class="action-button ml-1" (click)="send(row.id)">
          <mat-icon>forward_to_inbox</mat-icon>
        </strong>
        <strong *ngIf="!row.sent && !row.paid" title="Send Reminder" class="action-button ml-1" (click)="send(row.id)">
          <mat-icon>send</mat-icon>
        </strong>
      </ng-container>
    </ng-template>
  </ngx-datatable-column>
  <!-- <ngx-datatable-column name="{{'Unit Price' | translate}}" [flexGrow]="1">
            <ng-template ngx-datatable-cell-template let-row="row">
              <strong>{{row.unitPrice | currency:currencyCode:'symbol'}}</strong>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'Tax Rate' | translate}}" [flexGrow]="0.6">
            <ng-template ngx-datatable-cell-template let-row="row">
              <strong>{{row.tax}}</strong>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'Quantity' | translate}}" [flexGrow]="1">
            <ng-template ngx-datatable-cell-template let-row="row">
              <strong>{{row.quantity}}</strong>
            </ng-template>
          </ngx-datatable-column>
           -->
</ngx-datatable>


<app-pending-amount *ngIf="paymentRequests && paymentRequests.length && !invoice.paidInFull" [contextIdDto]="contextIdDto" [paymentRequests]="paymentRequests" [totalAmount]="invoice.total"></app-pending-amount>