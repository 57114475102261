import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PetService } from '@savvy/pet';
import { PetTupleDto } from '@savvy/pet/model/petTupleDto';
import { ContextIdDto } from '@savvy/ui';
import { takeUntil } from 'rxjs/operators';
import { CreatePetDetailsV2Component } from '../pets/create-pet-details-v2/create-pet-details-v2.component';
import { ClearObservable } from '../shared/classes/clear-observable';
import { PhoneNumberHelperService } from '../shared/services/phone-number-helper.service';
import { TrialExpiredService } from '../shared/services/trial-expired.service';
import { ChangeListener } from './changeListener';

@Component({
  selector: 'app-view-customer-pets',
  templateUrl: 'viewCustomerPets.component.html',
  styleUrls: ['viewCustomerPets.component.scss'],
})
export class ViewCustomerPetsComponent extends ClearObservable implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() customerId: string;
  @Input() form: UntypedFormGroup;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;

  petTupleDtos: Array<PetTupleDto>;

  constructor(
    private router: Router,
    public trialExpiredService: TrialExpiredService,
    private sharedService: PhoneNumberHelperService,
    private dialog: MatDialog,
    private petService: PetService) {
    super();
  }

  ngOnInit() {
    this.loadPets();
  }


  create() {
    this.openCreatePetModal();
  }

  rowSelected(petId: string) {
    //  if (this.listContractListDto.useModal) {
    // this.router.navigate(['/pets/view',
    //   this.contextIdDto.contextId,
    //   this.contextIdDto.contextIdType,
    //   petId]);
    this.openCreatePetModal(petId);
  }

  openCreatePetModal(petId?: string) {
    console.log(this);
    const createPetModal = this.dialog.open(CreatePetDetailsV2Component, {
      data: {
        contextIdDto: this.contextIdDto,
        customerId: this.customerId,
        petId
      },
      maxWidth: '1024px',
      // maxHeight: '80vh',
      // height: '80vh',
      panelClass: [
        'modal-100',
        'helpwindow',
        'petProfileModal'
      ],
      autoFocus: false
    });

    createPetModal.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.loadPets();
        });

    // this.router.navigate(['/pets/create',
    //   this.contextIdDto.contextId,
    //   this.contextIdDto.contextIdType,
    //   this.customerId]);
  }

  openViewPetModal(petId) {

    this.router.navigate(['/pets/view',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      petId]);

    /*const createContractModal = this.dialog.open(CreatePetV2Component, {
      data: {
        contextIdDto: this.contextIdDto,
        petId: petId
      },
      maxWidth: '1024px',
      maxHeight: '80vh',
      height: '80vh',
      panelClass: [
        'modal-100',
        'entitySummaryComponent'
      ],
      autoFocus: false
    });

    createContractModal.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.loadPets();
        });*/
  }

  loadPets() {
    this.petService.customersPetsV2(
      this.customerId)
      // .pipe(takeUntil(this.destroy$))
      .subscribe((response: Array<PetTupleDto>) => {
        this.petTupleDtos = response;
      }, (err: HttpErrorResponse) => {
        console.log('error while petTupleDtos', err);
      });
  }
}

