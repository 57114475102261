<mat-toolbar color="primary" *ngIf="reminderDefs?.length">
  <mat-toolbar-row>
    <div fxLayout="row wrap" fxLayoutGap="16px" fxLayoutAlign=" center" class="w-100">
      <div fxFlex>
        <h5>{{'Reminders' | translate}}</h5>
      </div>

      <div fxFlex="nogrow" *ngIf="!(deviceService.isMobile() || deviceService.isTablet())">
        <button mat-button class="mx-4" (click)="showFilters = !showFilters"><mat-icon>filter_alt</mat-icon>
          {{'SHOW_FILTERS' | translate}}</button>

      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<mat-card appearance="outlined" *ngIf="showFilters" class="mdc-mb-2">
  <mat-card-content>
    <div fxLayout="row" fxLayoutGap="10px">
      <div fxFlex="auto">
        <mat-button-toggle-group #group="matButtonToggleGroup" name="remindersDue" class="smallfield">
          <mat-button-toggle [checked]="daysToAdd === -1" (click)="yesterday()">
            {{'YESTERDAY' | translate}}
          </mat-button-toggle>
          <mat-button-toggle [checked]="daysToAdd === 0" (click)="today()">
            {{'TODAY' | translate}}
          </mat-button-toggle>
          <mat-button-toggle [checked]="daysToAdd === 1" (click)="tomorrow()">
            {{'TOMORROW' | translate}}
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div fxFlex="auto" *ngIf="reminderDefs?.length">
        <mat-form-field appearance="outline" class="smallfield w-100">
          <mat-label>{{'TYPE' | translate}}</mat-label>
          <mat-select [(ngModel)]="reminderDefId" (selectionChange)="loadReminders(startDate, endDate)">
            <mat-option value="">All</mat-option>
            <mat-option *ngFor="let reminderDef of reminderDefs" [value]="reminderDef.id">{{
              reminderDef.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="auto">
        <mat-form-field appearance="outline" class="smallfield w-100">
          <mat-label>{{'START_DATE' | translate}} - {{'END_DATE' | translate}}</mat-label>
          <mat-date-range-input [rangePicker]="eventRangePicker" [comparisonStart]="startDate"
            [comparisonEnd]="endDate">
            <input matStartDate placeholder="{{'START_DATE' | translate}}" [(ngModel)]="startDate"
              (focus)="eventRangePicker.open()">
            <input matEndDate placeholder="{{'END_DATE' | translate}}" [(ngModel)]="endDate"
              (ngModelChange)="loadReminders(startDate, endDate)" (focus)="eventRangePicker.open()">
          </mat-date-range-input>
          <mat-datepicker-toggle matIconSuffix [for]="eventRangePicker"></mat-datepicker-toggle>
          <mat-date-range-picker #eventRangePicker></mat-date-range-picker>
        </mat-form-field>
      </div>
      <!-- <div fxFlex></div> -->
    </div>
  </mat-card-content>
</mat-card>

<div class="mdc-mb-1" *ngIf="loaded">
  <div *ngIf="(deviceService.isMobile() || deviceService.isTablet())" class=" text-center mdc-mb-2" align="center">
    <mat-button-toggle-group #group="matButtonToggleGroup" name="remindersDue">
      <mat-button-toggle [checked]="daysToAdd === -1" (click)="yesterday()">{{'YESTERDAY' |
        translate}}</mat-button-toggle>
      <mat-button-toggle [checked]="daysToAdd === 0" (click)="today()">{{'TODAY' | translate}}</mat-button-toggle>
      <mat-button-toggle [checked]="daysToAdd === 1" (click)="tomorrow()">{{'TOMORROW' | translate}}</mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <ng-container *ngIf="reminders && reminders.length; else elseBlock">
    <div *ngIf="(deviceService.isMobile() || deviceService.isTablet())" class="search-results mdc-px-2">

      <!-- mobile view -->
      <div fxLayout="row wrap" fxLayoutGap="10px">
        <!-- mat-card repeat -->
        <div fxFlex="100" fxFlex.gt-xs="48" fxFlex.gt-md="33" *ngFor="let row of reminders">
          <mat-card appearance="outlined" class="mdc-mb-1">
            <mat-card-header>
              <div fxLayout="row wrap" class="w-100">
                <div fxFlex>
                  <mat-card-title>{{ row.dueToSend | localizedDate: 'medium' }}</mat-card-title>
                  <mat-card-subtitle>{{'DATE_TO_SEND' | translate}}</mat-card-subtitle>
                </div>
              </div>
            </mat-card-header>
            <mat-card-content>

              <!-- recipient only-->
              <div>
                <p class='mdc-mb-1' *ngIf="row?.reminderType === 'EMAIL'">
                  <span class="mat-text-secondary mr-5px">{{'RECIPIENT' | translate}} : </span>
                  <!-- <b class="mx-2">{{ row.recipient }}</b> -->
                  <span class="chiptag theme vm"><mat-icon>email</mat-icon> {{row?.recipient}}</span>
                </p>
                <p class='mdc-mb-1' *ngIf="row?.reminderType === 'SMS'">
                  <span class="mat-text-secondary mr-5px">{{'RECIPIENT' | translate}} : </span>
                  <!-- <b class="mx-2">{{ row.recipient }}</b> -->
                  <span class="chiptag theme-accent vm"><mat-icon>phone</mat-icon> {{row?.recipient}}</span>
                </p>
                <!-- <h4 class='mb-0'><span class="mat-text-secondary fw-normal">{{'RECIPIENT' | translate}}</span>
                <b class="mx-2">{{ row.recipient }}</b>
              </h4> -->

              </div>
              <p><span class="mat-text-secondary">{{'NOTES' | translate}}: </span> {{ row.notes }}</p>

              <p><span class="mat-text-secondary">{{'MESSAGE' | translate}}: </span> <span
                  [innerHTML]="row.expandedMessageText"></span>
              </p>

              <p class="appointment-status">
                <span class="mat-text-secondary mdc-mr-2 ">{{'STATE' | translate}}:</span>
                <!-- any one show -->
                <span class="text-center status paid-status d-inline-block w-auto">{{row.reminderState}}</span>
                <!-- one show -->
                <!-- <span class="text-center status unpaid-status d-inline-block w-auto">Ready to Sent</span> -->
              </p>
              <br>
              <button mat-button color="primary" (click)="sendNow(row)"><mat-icon>send</mat-icon> {{'SEND_NOW'
                |
                translate}}</button>
              <button *ngIf="!row.submittedToQueue" mat-button class="ml-1"
                (click)="deleteReminder(row)"><mat-icon>delete</mat-icon>
                {{'DELETE_REMINDER' | translate}}</button>
            </mat-card-content>
          </mat-card>
        </div>
        <!-- mat-card repeat ends -->
      </div>
      <!-- mobile view ends -->
    </div>

    <ngx-datatable *ngIf="!deviceService.isMobile() && !deviceService.isTablet()" class='material mdc-mx-1'
      [headerHeight]="50" [footerHeight]="50" [columnMode]="'force'" [rowHeight]="'auto'" [rows]='reminders'>
      <ngx-datatable-column name="{{'TIME_TO_SEND' | translate}}" [flexGrow]="1">
        <ng-template ngx-datatable-cell-template let-row="row">
          <h6 class="mdc-mb-0">{{ row.dueToSend | localizedDate: 'h:mm a' }}</h6>
          <p><small class="mat-text-secondary">{{ row.dueToSend | localizedDate: 'd MMMM yyyy' }}</small></p>
          <input type="hidden" value="{{row.id}}">
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'TYPE' | translate}}" [flexGrow]="1" [sortable]="false">
        <ng-template ngx-datatable-cell-template let-row="row">
          <div *ngIf="row.reminderType === 'EMAIL'" class="appt-type email">
            <mat-icon>mail</mat-icon> {{'EMAIL' | translate}}
          </div>
          <div *ngIf="row.reminderType === 'SMS'" class="appt-type sms">
            <mat-icon>sms</mat-icon> {{'SMS' | translate}}
          </div>
          <ng-container [ngSwitch]="row.reminderDefinitionType">
            <div *ngSwitchCase="'APPOINTMENT'" class="appt-type appointment">
              <mat-icon>event</mat-icon> {{'APPOINTMENT' | translate}}
            </div>
            <div *ngSwitchCase="'DEPOSIT'" class="appt-type deposit">
              <mat-icon>payment</mat-icon> {{'DEPOSIT' | translate}}
            </div>
            <div *ngSwitchCase="'MISS_YOU'" class="appt-type missyou">
              <mat-icon>event</mat-icon> {{'MISS_YOU' | translate}}
            </div>
            <div *ngSwitchCase="'VACCCINATION'" class="appt-type vaccination">
              <mat-icon>vaccines</mat-icon> {{'VACCINATION' | translate}}
            </div>
            <div *ngSwitchCase="'BIRTHDAY'" class="appt-type birthday">
              <mat-icon>cake</mat-icon> {{'BIRTHDAY' | translate}}
            </div>
            <div *ngSwitchCase="'DUE_TO_SEND'" class="appt-type duetosend">
              <mat-icon>history_toggle_off</mat-icon> {{'Due To Send' | translate}}
            </div>
          </ng-container>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'STATE' | translate}}" [flexGrow]="1">
        <ng-template ngx-datatable-cell-template let-row="row">
          <p class="mdc-mb-0">{{ row.reminderState | translate }}</p>
          <button (click)="sendNow(row)" mat-button color="accent"><mat-icon>send</mat-icon> {{'SEND_NOW' |
            translate}}</button>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'MESSAGE' | translate}}" [flexGrow]="4" [width]="300" [sortable]="false">
        <ng-template ngx-datatable-cell-template let-row="row">


          <div [innerHTML]="row.expandedMessageText" class="message-oneline"></div> <button mat-button color="accent"
            (click)="showEntireReminderMessage(row)">{{'MORE' | translate}}...</button>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'RECIPIENT' | translate}}" [flexGrow]="1">
        <ng-template ngx-datatable-cell-template let-row="row">
          <div *ngIf="row.recipient">
            <div *ngIf="row.reminderType === 'EMAIL'" class="chiptag theme vm"><mat-icon>email</mat-icon><span
                class="emails">{{ row.recipient }}</span></div>
            <div *ngIf="row.reminderType === 'SMS'" class="chiptag theme-accent vm">
              <mat-icon>phone</mat-icon><span>{{ row.recipient }}</span>
            </div>
          </div>
          <div *ngIf="!row.recipient">
            <div [ngSwitch]="row.reminderType">
              <p *ngSwitchCase="'EMAIL'" class="mdc-mb-0 mat-text-red small">
                <mat-icon class="small">warning</mat-icon> {{'WARN_NO_EMAIL' | translate}}
              </p>
              <p *ngSwitchCase="'SMS'" class="mdc-mb-0 mat-text-red small">
                <mat-icon class="small">warning</mat-icon> {{'WARN_NO_PHONE_NUM' | translate}}
              </p>
              <p *ngSwitchDefault class="mdc-mb-0 mat-text-red small">
                <mat-icon class="small">warning</mat-icon> N/A.
              </p>
            </div>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'USER' | translate}}" [flexGrow]="1">
        <ng-template ngx-datatable-cell-template let-row="row">
          <div>{{ row.recipientFullName }}</div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'ACTION' | translate}}" [flexGrow]="1" [sortable]="false">
        <ng-template ngx-datatable-cell-template let-row="row">
          <div *ngIf="!row.submittedToQueue">
            <button mat-button (click)="deleteReminder(row)"><mat-icon>delete</mat-icon> {{'DELETE' |
              translate}}</button>
          </div>
        </ng-template>
      </ngx-datatable-column>

    </ngx-datatable>
  </ng-container>
  <ng-template #elseBlock>
    <div class="no-reminders mt-2">
      <div style="text-align:center;">
        <div class="avatar avatar-100 circle border-3 mb-2 d-inline-block">
          <mat-icon class="h1 h-auto w-auto">notifications_none</mat-icon>
        </div>
        <h6>{{'NO_REMINDERS' | translate: {Default: 'There are no reminders'} }}</h6>
      </div>
    </div>
  </ng-template>



</div>
