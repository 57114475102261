<mat-card class="mx-0 shdaow-none">
  <mat-card-header class="mdc-mb-1">
    <div fxLayout="row" fxLayoutAlign=" center">
      <div fxFlex>
        <h6 class="mb-0">{{'FLAGS' | translate}}</h6>
      </div>
      <div fxFlex="nogrow" *ngIf="!disableCreate && flagDefHelperService.getCustomerFlagDefs().length > 0">
        <button mat-button color="accent" [matMenuTriggerFor]="menu">
          <mat-icon class="vm">add</mat-icon> {{'ADD_FLAG' | translate}}
        </button>
        <mat-menu #menu="matMenu">
          <button color="accent" *ngFor="let flagDef of flagDefHelperService.getCustomerFlagDefs()" (click)="addFlag(flagDef)" mat-menu-item>{{ flagDef.name }}</button>
        </mat-menu>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content>
    <mat-chip-listbox>
      <ng-container *ngFor="let flagId of flagIds">
        <mat-chip-option *ngIf="flagDefHelperService.getFlagDef(flagId)" color="{{flagDefHelperService.getFlagDef(flagId).colour}}" removable="true" [matTooltip]="flagDefHelperService.getFlagDef(flagId).description" matTooltipPosition="above" (removed)="removeFlag(flagId)" selected>
          {{flagDefHelperService.getFlagDef(flagId).name}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-option>
      </ng-container>
    </mat-chip-listbox>
  </mat-card-content>
</mat-card>