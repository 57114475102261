import {Injectable} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {GLOBAL} from '../../app.constants';
import {LocalMenuService} from '../menu/local-menu.service';

@Injectable()
export class IconService {

  icons = new Map<string, string>();
  appType: any;

  constructor(private matIconRegistry: MatIconRegistry,
              private menuService: LocalMenuService,
    private domSanitizer: DomSanitizer) {
  }

  getSvgIcon(name: string) {
    console.log('looking for icon matching:', name);
    // this.path = this.iconService.getIcon(this.name);
    const menuHasMatchingIcon = this.menuService.menuItems.find(menuItem => menuItem.name && name
      && (menuItem.name === name
        || String(menuItem.name).toLowerCase().includes(name.toLowerCase())));
    const adminMenuHasMatchingIcon = this.menuService.adminMenuItems.find(menuItem => menuItem.label && name
      && (menuItem.label === name
        || String(menuItem.label).toLowerCase().includes(name.toLowerCase())));


    if (name && menuHasMatchingIcon) {
      return menuHasMatchingIcon.svgXml;
    } else if (name && adminMenuHasMatchingIcon) {
      return adminMenuHasMatchingIcon.svgXml;
    } else {
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let index = 0; index < this.menuService.adminMenuItems.length; index++) {
        const adminMenuItem = this.menuService.adminMenuItems[index];
        if (adminMenuItem.menuDefinitions && adminMenuItem.menuDefinitions.length) {
          const adminMenuHasMatchingIcon2 = adminMenuItem.menuDefinitions.find(menuItem => menuItem.label
            && name && (menuItem.label === name
              || String(menuItem.label).toLowerCase().includes(name.toLowerCase())));
          if (adminMenuHasMatchingIcon2) {
            return adminMenuHasMatchingIcon2.svgXml;
            return;
          }
        }
      }
      return GLOBAL.selectedMenuIconSvgXml;
    }
    // console.log('got path ' + this.path + ' for name ' + this.name);
  }

  public getIcon(key: string): string {
    console.log('getting icon for ' + key);
    if (!key) {
      return this.icons.get('data');
    }


    return this.icons.get(key.toLowerCase());
  }


  public initAppType(appType) {
    this.appType = appType;
  }

  registerLocalIcons() {
    this.registerIcon('history', 'PetGroomingSalon/icons8-history.svg');

  }

  public registerIcons() {
    // Standard Pet start
    this.registerIcon('calendar', 'icons8-diary.svg');
    this.registerIcon('pet-home', 'icons8-pet-shelter.svg');
    this.registerIcon('customer', 'icons8-crowd-of-people.svg');
    this.registerIcon('scissors', 'icons8-scissors.svg');
    this.registerIcon('pet', 'icons8-dog.svg');
    this.registerIcon('waitingList', 'pet/icons8-pull-down.svg');
    this.registerIcon('reports', 'pet/icons8-combo-chart.svg');
    this.registerIcon('reminder', 'zeroReminders.svg');
    this.registerIcon('contracts', 'icons8-audio-file.svg');
    this.registerIcon('booking', 'pet/icons8-invitation.svg');
    this.registerIcon('order', 'zeroOrders.svg');
    // Standard Pet end

    // Admin Pet start
    this.registerIcon('org', 'icons8-information.svg');
    this.registerIcon('orgDetails', 'icons8-org-details.svg');
    this.registerIcon('orgTimings', 'icons8-org-timings.svg');
    this.registerIcon('orgLookAndFeel', 'icons8-org-look-and-feel.svg');

    this.registerIcon('users', 'icons8-crowd-of-people.svg');
    this.registerIcon('userInfo', 'pet/icons8-userInfo.svg');
    this.registerIcon('userLocationMatrix', 'pet/icons8-userLocationMatrix.svg');
    this.registerIcon('userServiceMatrix', 'pet/icons8-userServiceMatrix.svg');
    this.registerIcon('userRoleManagement', 'pet/icons8-userRoleManagement.svg');
    this.registerIcon('userWorkingHours', 'pet/icons8-userWorkingHours.svg');
    this.registerIcon('userStaffRota', 'pet/icons8-userStaffRota.svg');
    this.registerIcon('userCheckIn', 'pet/icons8-userCheckIn.svg');

    this.registerIcon('messaging', 'pet/icons8-messaging.svg');
    this.registerIcon('emailSettings', 'pet/icons8-emailSettings.svg');
    this.registerIcon('email', 'pet/icons8-emailSettings.svg');
    this.registerIcon('smsSettings', 'pet/icons8-smsSettings.svg');
    this.registerIcon('emailLookAndFeel', 'pet/icons8-emailLookAndFeel.svg');
    this.registerIcon('emailTemplates', 'pet/icons8-emailTemplates.svg');
    this.registerIcon('sentMessages', 'pet/icons8-sentMessages.svg');
    this.registerIcon('appointmentMessages', 'pet/icons8-appointmentMessages.svg');

    this.registerIcon('reminders', 'pet/icons8-reminders.svg');
    this.registerIcon('reminderMissYou', 'pet/icons8-reminderMissYou.svg');
    this.registerIcon('reminderAppointment', 'pet/icons8-reminderAppointment.svg');
    this.registerIcon('reminderDueToSend', 'pet/icons8-reminderDueToSend.svg');

    this.registerIcon('calendarSettings', 'pet/icons8-calendarSettings.svg');

    this.registerIcon('data', 'pet/icons8-pull-down.svg');
    this.registerIcon('serviceGroups', 'pet/icons8-service-groups.svg');
    this.registerIcon('spa', 'pet/icons8-spa.svg');
    this.registerIcon('product', 'pet/icons8-product.svg');
    this.registerIcon('customerSource', 'pet/icons8-customer-source.svg');
    this.registerIcon('suppliers', 'pet/icons8-suppliers.svg');
    this.registerIcon('categories', 'pet/icons8-categories.svg');
    this.registerIcon('taxCategories', 'pet/icons8-taxCategories.svg');
    this.registerIcon('breeds', 'pet/icons8-breeds.svg');
    this.registerIcon('petColour', 'pet/icons8-pet-colour.svg');

    this.registerIcon('contract', 'icons8-audio-file.svg');

    this.registerIcon('contactForm', 'pet/icons8-form.svg');
    this.registerIcon('templateForms', 'pet/icons8-templateForms.svg');
    this.registerIcon('submittedForms', 'pet/icons8-submittedForms.svg');

    this.registerIcon('checkinForm', 'pet/icons8-todo-list.svg');

    this.registerIcon('flag', 'pet/icons8-empty-flag.svg');
    this.registerIcon('customerFlags', 'pet/icons8-customerFlags.svg');
    this.registerIcon('petFlags', 'pet/icons8-petFlags.svg');

    this.registerIcon('branding', 'icons8-check-file.svg');

    this.registerIcon('website', 'pet/icons8-website.svg');
    this.registerIcon('customerPortal', 'pet/icons8-customer-insight.svg');
    this.registerIcon('bookingSetup', 'pet/icons8-bookingSetup.svg');
    this.registerIcon('slotManagement', 'pet/icons8-slotManagement.svg');

    this.registerIcon('questionnaire', 'icons8-survey.svg');

    this.registerIcon('payment', 'pet/icons8-payment.svg');
    this.registerIcon('paymentSchedules', 'pet/icons8-paymentSchedules.svg');
    this.registerIcon('paymentSettings', 'pet/icons8-paymentSettings.svg');
    this.registerIcon('paymentsReceived', 'pet/icons8-paymentsReceived.svg');
    this.registerIcon('paymentXero', 'pet/icons8-paymentXero.svg');
    this.registerIcon('paymentQuickbooks', 'pet/icons8-paymentQuickbooks.svg');

    this.registerIcon('import', 'pet/icons8-import-csv.svg');
    this.registerIcon('iExCustomers', 'pet/icons8-iExCustomers.svg');
    this.registerIcon('iExInvoices', 'pet/icons8-iExInvoices.svg');
    this.registerIcon('iExPayments', 'pet/icons8-iExPayments.svg');
    this.registerIcon('iExAppointments', 'pet/icons8-iExAppointments.svg');
    this.registerIcon('iExHistory', 'pet/icons8-iExHistory.svg');


    // Admin Pet end
    this.registerIcon('trades-home', 'trades/icons8-home-48.svg');
    this.registerIcon('barber-scissors', 'icons8-barbershop.svg');
    this.registerIcon('sms', 'icons8-sms.svg');
    this.registerIcon('meeting', 'icons8-meeting.svg');
    this.registerIcon('marketing', 'pet/icons8-multichannel.svg');
    this.registerIcon('spa', 'icons8-check-file.svg');
    this.registerIcon('invoice', 'icons8-money.svg');
    this.registerIcon('leads', 'zeroLeads.svg');

    this.registerIcon('paymentSchedule', 'icons8-money.svg');
    this.registerIcon('orders', 'zeroOrders.svg');
    this.registerIcon('home', 'zeroAppointments.svg');
    this.registerIcon('workflows', 'icons8-work.svg');
    this.registerIcon('task', 'icons8-todo-list.svg');
    this.registerIcon('quote', 'icons8-estimate.svg');
    this.registerIcon('activity', 'pet/icons8-activity-feed.svg');
    this.registerIcon('shoots', 'zeroShoots.svg');

    this.registerIcon('sms', 'icons8-speech-bubble.svg');
    this.registerIcon('subscriptions', 'icons8-renew.svg');


    this.registerIcon('bookings', 'pet/icons8-invitation.svg');
    this.registerIcon('webshop', 'pet/icons8-website.svg');

    this.registerIcon('services', 'pet/icons8-services.svg');


  }

  registerIcon(name: string, imgPath: string) {
    const fullPath = 'assets/icons/' + imgPath;
    this.icons.set(name.toLowerCase(), fullPath);
    this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(fullPath));
    this.matIconRegistry.addSvgIcon(name.toLowerCase(), this.domSanitizer.bypassSecurityTrustResourceUrl(fullPath));
  }
}
