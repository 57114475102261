import { Component, Input } from '@angular/core';
import { LabelValueLeftRightPanelDto } from '@savvy/view-definition';

@Component({
  selector: 'app-label-value-left-right-panel',
  templateUrl: 'labelValueLeftRightPanel.component.html',
  styles: [
    `
             p {
               word-wrap: break-word;
               white-space: -moz-pre-wrap;
               white-space: pre-wrap;
             }
           `
  ]
})
export class LabelValueLeftRightPanelComponent {
  @Input() labelValueLeftRightPanelDto: LabelValueLeftRightPanelDto;
}

