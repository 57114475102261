import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RequestForPayment } from '@savvy/request-for-payment';
import { PaymentSchedulerComponent } from 'src/app/flo/payment/payment-scheduler/payment-scheduler.component';
import { DateUtils } from 'src/app/flo/dates/DateUtils';
import { FloSnackbarComponent } from '../../../snackbar/floSnackbar.component';
import { Invoice } from '@savvy/invoice';

@Component({
  selector: 'app-payment-scheduler-modal',
  templateUrl: './payment-scheduler-modal.component.html',
  styleUrls: ['./payment-scheduler-modal.component.scss']
})
export class PaymentSchedulerModalComponent implements OnInit {
  @ViewChild(PaymentSchedulerComponent) public paymentSchedulerComponent: PaymentSchedulerComponent;

  paymentMode = 'PS';
  paymentType = 'AMOUNT';
  schedule: RequestForPayment;
  minRange = new Date();
  invoice: Invoice;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<PaymentSchedulerModalComponent>,
    private dateUtils: DateUtils,
    private notify: FloSnackbarComponent,

  ) {
    if (this.data.invoice) {
      this.invoice = this.data.invoice;
    }

    if (data.paymentRequest) {
      this.schedule = data.paymentRequest;
      if (this.schedule.percentRequired) {
        this.paymentType = 'PERCENTAGE';
      } else {
        this.paymentType = 'AMOUNT';
        this.schedule.paymentAmountInPence = this.schedule.paymentAmountInPence / 100;
      }
    } else {
      if (this.data.percentRequired) {
        this.paymentType = 'PERCENTAGE';
      } else {
        this.paymentType = 'AMOUNT';
      }
      this.initiateSchedule();
    }

    if (data.requestForPaymentOwnerType) {
      this.schedule.requestForPaymentOwnerType = data.requestForPaymentOwnerType;
    }

    // this.minRange = this.dateUtils.addDays(new Date(), 1);
  }

  ngOnInit() {

  }

  initiateSchedule() {
    this.schedule = {
      invoiceId: this.invoice.invoiceId.id,
      invoiceDate: this.invoice.invoiceDate,
      dueDate: this.invoice.dueDate,
      ownerId: this.data.contextIdDto.contextId,
      percentRequired: this.data.percentRequired,
      requestForPaymentOwnerType: RequestForPayment.RequestForPaymentOwnerTypeEnum.Customer
    };
  }

  isValid() {
    return new Date(this.schedule.dueDate) > new Date(this.schedule.dateToSend);
  }

  reSchedule() {
    if (this.isValid()) {
      if (!this.schedule.percentRequired) {
        this.schedule.paymentAmountInPence = this.schedule.paymentAmountInPence * 100;
      }
      this.dialogRef.close(this.schedule);
    } else {
      this.notify.message = 'Please select valid dates';
      this.notify.open();
    }
  }

  close() {
    this.dialogRef.close();
  }

}
