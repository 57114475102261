import { Component, Input, OnInit } from '@angular/core';
import { ViewContextDto } from '@savvy/view-composite';
import { Router } from '@angular/router';
import { EntitysService } from '@savvy/entity-instance';
import { TrialExpiredService } from '../shared/services/trial-expired.service';
import { ContextIdDto, ZeroEntitiesDto } from '@savvy/view-definition';

@Component({
    selector: 'app-zero-instances',
    templateUrl: './zeroInstances.component.html'
})
export class ZeroInstancesComponent implements OnInit {

    @Input() contextIdDto: ContextIdDto;
    @Input() zeroEntitiesDto: ZeroEntitiesDto;
    @Input() viewContext: ViewContextDto;

    constructor(
        private eiApi: EntitysService,
        public trialExpiredService: TrialExpiredService,
        private router: Router) {
    }

    ngOnInit() {
        console.log('inside zero instances');
    }

    create() {
        console.log('Navigating to create entity with entityDefinitionId ' + this.zeroEntitiesDto.entityDefinitionId.id +
            ' context id ' + this.contextIdDto.contextId);
        if (this.viewContext.viewContextTypeDto === ViewContextDto.ViewContextTypeDtoEnum.ViewEntityInstance) {
            this.router.navigate(['/entity/createEntity',
                this.zeroEntitiesDto.entityDefinitionId.id,
                this.contextIdDto.contextId,
                this.contextIdDto.contextIdType,
                this.viewContext.viewEntityInstanceContextDto.entityInstanceId.id,
                this.viewContext.viewEntityInstanceContextDto.entityDefinitionId.id
            ]);
        } else if (this.viewContext.viewContextTypeDto === ViewContextDto.ViewContextTypeDtoEnum.Customer) {
            this.router.navigate(['/entity/createEntity',
                this.zeroEntitiesDto.entityDefinitionId.id,
                this.contextIdDto.contextId,
                this.contextIdDto.contextIdType,
                this.viewContext.customerContextDto.customerId.id
            ]);
        } else {
            this.router.navigate(['/entity/createEntity',
                this.zeroEntitiesDto.entityDefinitionId.id,
                this.contextIdDto.contextId,
                this.contextIdDto.contextIdType
            ]);
        }
    }
}
