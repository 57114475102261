import { Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { WorkflowOverviewComponent } from './workflowOverview.component';
import { AddEditWorkflowComponent } from './addEditWorkflow.component';
import { AddEditWorkflowStateComponent } from './addEditWorkflowState.component';
import { EditWorkflowPanelComponent } from './editWorkflowPanel.component';
import { WorkflowDetailsComponent } from './workflow-details/workflow-details.component';
import { WorkflowlistsComponent } from './workflow-list.component';
import { WorkflowlistsByTypeComponent } from './workflow-list-by-type.component';
import { TaskWorkflowlistsComponent } from './task-workflow-list.component';
import { TaskWorkflowDetailsComponent } from './task-workflow-details/task-workflow-details.component';

export const workflowRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'workflows/:contextId/:contextIdType',
        component: WorkflowlistsComponent, canActivate: [AuthGuard],
        data: {
          breadcrumb: 'List Workflows',
          topLevel: 'true'
        }
      },
      {
        path: 'taskWorkflows/:contextId/:contextIdType',
        component: TaskWorkflowlistsComponent, canActivate: [AuthGuard],
        data: {
          breadcrumb: 'List Workflows',
          topLevel: 'true'
        }
      },
      {
        path: 'workflowsByType/:type/:contextId/:contextIdType',
        component: WorkflowlistsByTypeComponent, canActivate: [AuthGuard],
        data: {
          breadcrumb: 'List Workflows',
          topLevel: 'true'
        }
      },
      {
        path: 'workflow-details/:workflowDefinitionId',
        component: WorkflowDetailsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'workflow-details/:workflowDefinitionId/:allowEditState/:allowAddState',
        component: WorkflowDetailsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'task-workflow-details/:workflowDefinitionId/:contextId/:contextIdType',
        component: TaskWorkflowDetailsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'overview/:contextId/:contextIdType',
        component: WorkflowOverviewComponent, canActivate: [AuthGuard]
      },
      {
        path: 'messageOnlyWorkflow/:workflowDefinitionId/:contextId/:contextIdType',
        component: WorkflowDetailsComponent, canActivate: [AuthGuard]
      },
      {
        path: 'taskOnlyWorkflow/:workflowDefinitionId/:contextId/:contextIdType',
        component: TaskWorkflowDetailsComponent, canActivate: [AuthGuard]
      },
      {
        path: 'editWorkflowPanel/:workflowDefinitionId/:contextId/:contextIdType',
        component: EditWorkflowPanelComponent, canActivate: [AuthGuard]
      },
      {
        path: 'addEditWorkflow/:workflowDefinitionId/:contextId/:contextIdType',
        component: AddEditWorkflowComponent, canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Edit Workflow'
        }
      },
      {
        path: 'addEditWorkflow/:contextId/:contextIdType', component: AddEditWorkflowComponent, canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Add Workflow'
        }
      },
      {
        path: 'addEditWorkflowState/:workflowDefinitionId/:stateId/:contextId/:contextIdType',
        component: AddEditWorkflowStateComponent, canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Edit Step'
        }
      },
      {
        path: 'addEditWorkflowState/:workflowDefinitionId/:contextId/:contextIdType',
        component: AddEditWorkflowStateComponent, canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Add Step'
        }
      }
    ]
  }
];
