import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ChangeListener } from './changeListener';
import { EventBusService } from './EventBusService';
import { ContextIdDto, StepPanelDto } from '@savvy/entity-instance-composite';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-step-panel',
  templateUrl: 'stepPanel.component.html'
})
export class StepPanelComponent implements OnInit {

  @ViewChild('stepper') stepper: MatStepper;
  @Input() stepPanelDto: StepPanelDto;
  @Input() contextIdDto: ContextIdDto;
  @Input() viewContext: any;
  @Input() form: UntypedFormGroup;
  @Input() eventBus: EventBusService;
  @Input() additionalDataMapDto: any;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    console.log(this.stepper);
  }

  isStepEditable(stepDefinitionDtos, i) {
    if (stepDefinitionDtos[i - 1]) {
      return stepDefinitionDtos[i - 1].formGroup.valid;
    }
    return true;
  }

  selectionChange(event) {
    console.log(event.previouslySelectedStep.stepControl.controls);
    let allControlsValid = true;
    const controls = Object.keys(event.previouslySelectedStep.stepControl.controls);

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let index = 0; index < controls.length; index++) {
      const key = controls[index];
      if (!event.previouslySelectedStep.stepControl.controls[key].valid) {
        allControlsValid = false;
        break;
      }
    }
    if (allControlsValid) {
      event.previouslySelectedStep.hasError = false;
      event.previouslySelectedStep.completed = true;
    } else {
      event.previouslySelectedStep.hasError = true;
      event.previouslySelectedStep.completed = false;
      this.stepper.selectedIndex = event.previouslySelectedIndex;
      this.stepper.selected = event.previouslySelectedStep;
    }
  }


}

