import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';
import { ContextIdDto } from '@savvy/view-definition';
import { Customer, CustomerId, CustomerService } from '@savvy/customer';
import { MergeCustomer } from "@savvy/customer/model/mergeCustomer";

@Component({
  selector: 'app-merge-customer',
  templateUrl: './merge-customer-modal.component.html',
  styleUrls: ['./merge-customer-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MergeCustomerModalComponent {

  sending = false;

  // Passed in data
  contextIdDto: ContextIdDto;


  customerId: CustomerId;


  sourceCustomer: Customer;
  targetCustomer: Customer;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MergeCustomerModalComponent>,
    private customerService: CustomerService,
    private notify: FloSnackbarComponent
  ) {


    this.contextIdDto = data.contextIdDto;
  }


  sourceCustomerChanged(customerId: string) {

    console.log('got source ', customerId);
    this.customerService.loadCustomer(customerId, this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(response => {
      this.sourceCustomer = response;
    });
  }

  targetCustomerChanged(customerId: string) {
    console.log('got target ', customerId);

    this.customerService.loadCustomer(customerId, this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(response => {
      this.targetCustomer = response;
    });
  }


  merge() {
    if (this.sourceCustomer?.id) {
      const req = <MergeCustomer>{};
      req.contextIdDto = this.contextIdDto;
      req.customerId = this.sourceCustomer.id;
      req.duplicateCustomerId = this.targetCustomer.id;


      this.customerService.mergeCustomer(req).subscribe(response => {
        this.notify.message = 'Merged Customer';
        this.notify.open();
        this.dialogRef.close(response);
      });

    }

  }
}
