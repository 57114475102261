import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomerId } from '@savvy/customer';
import { CustomerPortalService, InviteExistingCustomer, SignupNewCustomer } from '@savvy/customer-portal';
import { EmailmessagetemplatesService, Link } from '@savvy/email-template';
import { FileValue } from "@savvy/entity-instance-composite";
import { SendToAllMessageService } from '@savvy/send-to-all';
import { ContextIdDto } from '@savvy/view-definition';
import { Subject } from 'rxjs';
import { UploadFilesComponent } from '../../shared/components/upload-files/upload-files.component';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';
import { EmailRecipient } from '@savvy/appointment';

@Component({
  selector: 'app-onboard-customer-messaging-modal',
  templateUrl: './onboard-customer-messaging-modal.component.html',
  styleUrls: ['./onboard-customer-messaging-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OnboardCustomerMessagingModalComponent implements OnInit, OnDestroy {
  emailSubjectText: string;
  emailMessageText: string;
  emailTo: string;
  mobile: string;
  smsMessageText: string;
  sendEmail = true;
  sendSms = false;
  savedFiles: FileValue[] = [];

  // public config: PerfectScrollbarConfigInterface = {};

  private destroy$ = new Subject();

  sending = false;

  // Passed in data
  contextIdDto: ContextIdDto;
  customerId: CustomerId;
  links: Link[] = [];

  @ViewChild('uploadFiles') uploadFilesComponent: UploadFilesComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<OnboardCustomerMessagingModalComponent>,
    private notify: FloSnackbarComponent,
    private api: SendToAllMessageService,
    private customerPortalService: CustomerPortalService,
    private emailmessagetemplatesService: EmailmessagetemplatesService,

  ) {

    this.smsMessageText = 'Click here to signup';
    this.emailSubjectText = 'Customer Portal';
    this.emailMessageText = 'Welcome to {{orgName}} Customer Portal, where you can access your appointments, contracts and invoices';
    this.contextIdDto = data.contextIdDto;

    this.customerId = null;
    this.emailTo = null;

    if (data.customerId) {
      this.customerId = data.customerId;
    }
    if (data.email) {
      this.emailTo = data.email;
    }
  }

  ngOnInit(): void {
    this.loadTemplate();
  }

  loadTemplate() {
    this.emailmessagetemplatesService.getEmailMessageTemplateByType(
      this.contextIdDto.contextId, this.contextIdDto.contextIdType, 'inviteToPortal')
      .subscribe(res => {
        if (res?.subject && res?.body) {
          this.emailSubjectText = res.subject;
          this.emailMessageText = res?.body;
          if (res?.attachments) {
            this.savedFiles = res?.attachments;
          }
          if (res?.links) {
            this.links = res.links;
          }
        }
      });
  }

  send() {
    if (this.sendEmail && !this.emailSet()
      && this.sendSms && !this.smsSet()) {
      this.notify.message = 'Email and SMS are not specified';
      this.notify.open();
      return;
    }

    if (!this.sendEmail && !this.sendSms) {
      this.notify.message = 'Neither Email or SMS have been specified';
      this.notify.open();
      return;
    }

    if (((this.sendEmail && !this.emailSet()) || (this.sendSms && !this.smsSet()))) {
      this.notify.message = 'Please fill all required details to send message';
      this.notify.open();
      return;
    }

    if (this.sendEmail && !this.uploadFilesComponent) {
      this.notify.message = 'No upload files component detected';
      this.notify.open();
      return;
    }

    if (this.customerId) {
      this.signupExisting();
    } else {
      this.signupNew();
    }
  }

  signupExisting() {
    const req: InviteExistingCustomer = <InviteExistingCustomer>{};
    req.contextIdDto = this.data.contextIdDto;
    req.customerId = this.customerId.id;
    req.emailSubject = this.emailSubjectText;
    req.emailBody = this.emailMessageText;

    req.emailRecipient = <EmailRecipient>{};
    req.emailRecipient.address = this.emailTo;
    req.emailRecipient.recipientId = this.customerId.id;
    req.emailRecipient.recipientType = EmailRecipient.RecipientTypeEnum.Customer;

    req.attachments = this.getAttachments();
    req.links = this.links;

    this.customerPortalService.inviteExisting(req).subscribe(() => {
      this.notify.message = 'Message sent!';
      this.notify.open();
      this.dialogRef.close();
      this.sending = false;

    }, err => {
      this.notify.message = 'Error sending message ' + err.errorMessage;
      this.notify.open();
      this.sending = false;
    });
  }

  signupNew() {
    const req: SignupNewCustomer = <SignupNewCustomer>{};
    req.contextIdDto = this.data.contextIdDto;
    req.emailSubject = this.emailSubjectText;
    req.emailBody = this.emailMessageText;
    req.emailRecipients = new Array();
    req.emailRecipients = this.toEmailRecipients(this.emailTo);

    req.attachments = this.getAttachments();
    req.links = this.links;

    this.customerPortalService.signupNewCustomer(req).subscribe(() => {
      this.notify.message = 'Message sent!';
      this.notify.open();
      this.dialogRef.close();
      this.sending = false;

    }, err => {
      this.notify.message = 'Error sending message ' + err.errorMessage;
      this.notify.open();
      this.sending = false;
    });
  }

  public toEmailRecipients(emailAddresses: string): Array<any> {
    const emailRecipients = new Array<any>();
    const recipientEmails = emailAddresses.split(',');
    recipientEmails.forEach(
      emailAddress => {
        emailRecipients.push(emailAddress.trim());
      }
    );
    return emailRecipients;
  }

  emailSet() {
    return this.emailSubjectText && this.emailSubjectText.length > 0 && this.emailMessageText && this.emailMessageText.length > 0;
  }

  smsSet() {
    return this.smsMessageText && this.smsMessageText.length > 0;
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  emailMessageBodyUpdated(value: any) {
    this.emailMessageText = value;
  }

  getAttachments() {
    const attachments = new Array();

    if (this.uploadFilesComponent.savedFiles) {
      this.uploadFilesComponent.savedFiles.forEach(
        savedFile => {
          attachments.push({
            ...savedFile,
            publicBucket: false
          });
        }
      );
    }
    return attachments;
  }
}
