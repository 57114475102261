import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ContextIdDto, DataItemDto, DataListDto, DatalistService } from '@savvy/datalist';
import { StorageService } from '@savvy/entity-instance-composite';
import { Pet } from '@savvy/pet';
import * as heic2any from 'heic2any';
import { NgxCroppedEvent, NgxPhotoEditorService } from 'ngx-photo-editor';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfService } from 'src/app/flo/config/conf.service';
import { AddEditDatalistModalComponent } from 'src/app/flo/datalist/add-edit-datalist-modal/add-edit-datalist-modal.component';
@Component({
  selector: 'app-pet-profile',
  templateUrl: './pet-profile.component.html',
  styleUrls: ['./pet-profile.component.scss']
})
export class PetProfileComponent implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() pet: Pet;
  @Input() breeds: DataItemDto[] = [];
  petFormGroup: UntypedFormGroup;
  public filteredBreeds: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public petBreedFilter: UntypedFormControl = new UntypedFormControl();

  coverPic = null;
  profilePic = null;
  breedDatalistDto = {} as DataListDto;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  constructor(
    private dialog: MatDialog,

    private storage: StorageService,
    private formBuilder: UntypedFormBuilder,
    private datalistService: DatalistService,
    private ngxPhotoEditorService: NgxPhotoEditorService
  ) { }

  ngOnInit(): void {
    this.initFormGroup();
    this.loadBreeds();
    this.petBreedFilter.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBreeds();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.breeds?.currentValue) {
      this.filteredBreeds.next(changes?.breeds?.currentValue);
      console.log("change petbreed");
      console.log(this.pet?.breed);
      console.log("change breeds");
      console.log(this.filteredBreeds);
    }
    if (changes?.pet?.currentValue) {
      this.setValue(changes?.pet?.currentValue);
    }
  }

  addNew() {

    if (!this.breedDatalistDto || !this.breedDatalistDto.dataListId) {
      console.log('breedDatalistDto ', this.breedDatalistDto);
      console.warn('no data list id');
      return;
    }
    const confirmDialog = this.dialog.open(AddEditDatalistModalComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        dataListDto: this.breedDatalistDto
      },
      height: 'auto',
      width: '600px',
      panelClass: 'helpwindow'
    });

    confirmDialog.afterClosed().subscribe((result: DataItemDto) => {
      if (result) {
        if (this.pet.breed) {
          this.pet.breed = result.name;
        }
        this.loadBreeds();
        this.petFormGroup.patchValue({
          petBreed: result.name,
        });
      }
    });
  }

  public setValue(pet: Pet) {
    this.petFormGroup.patchValue({
      petName: pet?.name,
      petBreed: pet?.breed,
      petType: pet?.petType,
      petNotes: pet?.petNotes,
      coverPicReference: pet?.coverPicReference,
      profilePicReference: pet?.profilePicReference
    });
    if (this.petFormGroup.value.profilePicReference) {
      this.profilePic = this.getUrl(this.petFormGroup.value.profilePicReference);
    } else if (pet?.images && pet?.images.length > 0) {
      this.profilePic = this.getUrl(pet.images[0].fileReferenceId);
    }
    this.coverPic = this.getUrl(this.petFormGroup.value.coverPicReference);
  }

  loadBreeds() {
    this.datalistService.getDataListByName(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType, 'Breed').subscribe(breeds => {
        if (breeds) {
          this.breedDatalistDto = breeds;
          this.breeds = breeds.dataItems;
          this.filteredBreeds.next(this.breeds);
          if (this.pet?.breed) {
            this.petFormGroup.patchValue({
              petBreed: this.pet?.breed,
            })
          }
        }
      });
  }

  filterBreeds() {
    if (!this.breeds) {
      return;
    }
    // get the search keyword
    // get the search keyword
    let search = this.petBreedFilter.value;
    if (!search) {
      this.filteredBreeds.next(this.breeds.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the breeds
    this.filteredBreeds.next(
      this.breeds.filter(breed => breed.name.toLowerCase().indexOf(search) > -1)
    );
  }

  handleCoverInput(event) {
    if (event.target.closest('datatable-body-cell')) {
      event.target.closest('datatable-body-cell').blur();
    }
    if (event.srcElement?.files?.length && event.srcElement?.files?.[0]?.name?.split('.')[1]?.toLowerCase() === 'heic') {
      heic2any.default({ blob: event.srcElement?.files?.[0], toType: 'png' }).then((res: any) => {
        const list = new DataTransfer();
        const file: File = new File([res], event.srcElement?.files?.[0]?.name?.split('.')[0] + '.png');
        list.items.add(file);
        event.srcElement.files = list.files;
        this.ngxPhotoEditorService.open(event, {
          // aspectRatio: 4 / 3,
          autoCropArea: 100,
        }).subscribe((croppedEvent: NgxCroppedEvent) => {
          this.coverImageCropped(croppedEvent);
        });
      });
    } else {
      this.ngxPhotoEditorService.open(event, {
        // aspectRatio: 4 / 3,
        autoCropArea: 100
      }).subscribe((croppedEvent: NgxCroppedEvent) => {
        this.coverImageCropped(croppedEvent);
      });
    }
  }

  handleProfileInput(event) {
    if (event.target.closest('datatable-body-cell')) {
      event.target.closest('datatable-body-cell').blur();
    }

    if (event.srcElement?.files?.length && event.srcElement?.files?.[0]?.name?.split('.')[1]?.toLowerCase() === 'heic') {
      heic2any.default({ blob: event.srcElement?.files?.[0], toType: 'png' }).then((res: any) => {
        const list = new DataTransfer();
        const file: File = new File([res], event.srcElement?.files?.[0]?.name?.split('.')[0] + '.png');
        list.items.add(file);
        event.srcElement.files = list.files;
        this.ngxPhotoEditorService.open(event, {
          // aspectRatio: 4 / 3,
          autoCropArea: 100,
          roundCropper: true,
        }).subscribe((croppedEvent: NgxCroppedEvent) => {
          this.profileImageCropped(croppedEvent);
        });
      });
    } else {
      this.ngxPhotoEditorService.open(event, {
        // aspectRatio: 4 / 3,
        roundCropper: true,
        autoCropArea: 100
      }).subscribe((croppedEvent: NgxCroppedEvent) => {
        this.profileImageCropped(croppedEvent);
      });
    }
  }

  coverImageCropped(event: NgxCroppedEvent) {
    if (event?.file) {
      this.postFile(event?.file, 'COVER');
    }
  }

  profileImageCropped(event: NgxCroppedEvent) {
    if (event?.file) {
      this.postFile(event?.file, 'PROFILE');
    }
  }

  postFile(fileToUpload: File | undefined, imageFor: string) {
    this.storage.uploadFileToStorageV2(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      false,
      fileToUpload).subscribe(response => {
        console.log('uploadFileToStorageV2 got response ', response);
        if (imageFor === 'COVER') {
          this.petFormGroup.patchValue({
            coverPicReference: response?.fileReference
          });
          this.coverPic = this.getUrl(this.petFormGroup.value.coverPicReference);
        } else if (imageFor === 'PROFILE') {
          this.petFormGroup.patchValue({
            profilePicReference: response?.fileReference
          });
          this.profilePic = this.getUrl(this.petFormGroup.value.profilePicReference);
        }
      });
  }

  getUrl(reference: string) {
    if (reference) {
      return ConfService.apiUrl() + '/rest/storage?public=false&contextId=' +
        this.contextIdDto.contextId + '&contextIdType=' +
        this.contextIdDto.contextIdType + '&id=' + reference;
    }
    return '';
  }

  private initFormGroup() {
    this.petFormGroup = this.formBuilder.group({
      petName: ['', Validators.required],
      petBreed: [''],
      petType: [''],
      petNotes: [''],
      coverPicReference: [''],
      profilePicReference: ['']
    });
  }



}
