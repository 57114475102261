import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EventBusService } from '../EventBusService';
import { ContextIdDto } from '@savvy/entity-instance-composite';

import { ConsumerService, ServicesService } from '@savvy/services';
import { ReplaySubject, Subject } from 'rxjs';
import { AddServiceDialogComponent } from '../fieldinstance/addServiceDialog.component';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ServicesHelperService } from '../../shared/services/services-helper.service';

@Component({
  selector: 'app-service-selector-pet',
  templateUrl: 'serviceSelectorPet.component.html'
})
export class ServiceSelectorPetComponent implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() eventBus: EventBusService;
  @Input() showPlaceholder: boolean;
  @Input() selectedService: ConsumerService;

  @Output() serviceSelected: EventEmitter<ConsumerService> = new EventEmitter();

  consumerServices: Array<ConsumerService>;

  public filteredServices: ReplaySubject<ConsumerService[]> = new ReplaySubject<ConsumerService[]>(1);
  private destroy$ = new Subject();


  constructor(
    private servicesService: ServicesService,
    private dialog: MatDialog,
    private servicesHelperService: ServicesHelperService
  ) {

  }

  ngOnInit(): void {
    this.loadServices();
  }

  serviceChanged(event) {
    if (event.value) {
      this.serviceSelected.emit(event.value);
    }
  }

  loadServices() {
    this.servicesHelperService.loadServices(this.contextIdDto).then(response => {
      this.consumerServices = response;
      this.filteredServices.next(this.consumerServices);
    });
  }

  filterServices(value: string) {
    this.servicesHelperService.getConsumerServices(this.contextIdDto).then(consumerServices => {
      if (!consumerServices) {
        return;
      }
      // get the search keyword
      if (!value) {
        this.filteredServices.next(consumerServices.slice());
        return;
      }
      const search = value.toLowerCase();
      // filter the consumerServices
      this.filteredServices.next(consumerServices.filter(serviceDto => serviceDto?.serviceName.toLowerCase().indexOf(search) > -1));
    });
  }

  createNewService() {
    this.createService();
  }

  createService() {
    const dialogRef = this.dialog.open(AddServiceDialogComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        entityDefinitionLabel: 'Service',
        eventBus: this.eventBus
      }
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this.destroy$)).subscribe(result => {
        console.log('back from dialog close');
        if (result && result.entityInstanceId) {
          console.log('got a new instance to link to ', result);
          this.loadAndSetService(result.service);
        }
      });
  }

  loadAndSetService(service: ConsumerService) {
    console.log('loading new services');
    this.servicesHelperService.loadServices(this.contextIdDto).then(response => {
      this.consumerServices = response;
      this.filteredServices.next(this.consumerServices);
      const newService = response.find(s => s?.serviceName === service?.serviceName);
      setTimeout(() => {
        this.selectedService = newService;
        this.serviceSelected.emit(service);
      }, 500);
    });
  }

  compareObjects(o1: ConsumerService, o2: ConsumerService): boolean {
    if (o1 && o2) {
      return o1?.serviceName === o2?.serviceName;
    }
    return false;
  }
}

