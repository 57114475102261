<mat-toolbar color="primary">
  <mat-toolbar-row>
    <h2>Add Customer To Plan</h2>
  </mat-toolbar-row>
</mat-toolbar>

<br>

<mat-card appearance="outlined" class="demo-card demo-basic">
  <mat-card-content>
    <div *ngIf="this.planDef.id">
      <mat-hint>
        <span *ngIf="planDef.unlimitedSessions">Unlimited sessions</span>
        <span *ngIf="!planDef.unlimitedSessions"><b>{{ planDef.numberOfSessions }} sessions</b></span>.<br></mat-hint>
      <mat-hint *ngIf="planDef.paymentSchedule === 'IMMEDIATE'">Full payment of <b>&#163; {{ planDef.cost }}</b> for the plan will be taken upon payment.<br></mat-hint>
      <mat-hint *ngIf="planDef.paymentSchedule === 'RECURRING' && planDef.paymentInterval === 'MONTHLY'"><b>&#163; {{ planDef.cost }}</b> will be paid monthly for the plan.<br></mat-hint>
      <mat-hint *ngIf="planDef.paymentSchedule === 'RECURRING' && planDef.paymentInterval === 'ANNUALLY'"><b>&#163; {{ planDef.cost }}</b> will be paid annually for the plan.<br></mat-hint>
    </div>
    <br><br>
    <form #planForm="ngForm">
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>Plan</mat-label>
        <mat-select [(ngModel)]="request.planDefinitionId" (selectionChange)="planDefinitionChange($event)" name="planDefinitionId" #planDefinitionId="ngModel" id="planDefinitionId">
          <mat-option *ngFor="let pd of planDefinitions" [value]="pd.id">
            {{pd.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="planDef.paymentSchedule === 'RECURRING' && planDef.paymentInterval === 'MONTHLY'" style="width: 100%" appearance="outline">
        <mat-label>{{'Take payment every' | translate}}</mat-label>
        <input matInput type="number" [(ngModel)]="request.dayOfPayment" name="dayOfPayment" #dayOfPayment="ngModel" id="dayOfPayment"
               placeholder="Take payment every" required>
        <mat-hint>The day of the month which the payment will be taken. ie. if value is 1, payment will be taken every 1st of the month. If value is 28, the payment will be taken every 28th of the month.</mat-hint>
      </mat-form-field>

      <br><br>
      <button mat-raised-button color="accent" (click)="addToPlan()"
                  [disabled]="!planForm.form.valid"
                  disabled="disabled">{{'Add to plan' | translate}}
          </button>
    </form>
  </mat-card-content>
</mat-card>
