import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { EntitySummaryDto, ContextIdDto, SummaryItemDto } from '@savvy/view-definition';
import { NavigateService } from '../entry/NavigateService';

@Component({
  selector: 'app-entity-summary-widget',
  templateUrl: 'entitySummaryWidget.component.html'
})
export class EntitySummaryWidgetComponent {

  @Input() entitySummaryDto: EntitySummaryDto;
  @Input() contextIdDto: ContextIdDto;

  constructor(private router: Router, private nav: NavigateService) {
  }

  go(summaryItemDto: SummaryItemDto) {
    console.log('reuse logic from menu to construct');
    this.router.navigate(['/entity/viewEntity',
      summaryItemDto.itemId,
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
    ]);
  }
}

