<mat-toolbar *ngIf="!isContractModal" color="primary">
  <div fxFlex="100" fxLayoutGap="16px" fxLayout="row" fxLayoutAlign=" center">
    <div fxFlex="nogrow">
      <button (click)="backToList()" class="ml-xs mat-icon-button" mat-icon-button="">
        <span class="mat-button-wrapper">
          <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">arrow_back</mat-icon>
        </span>
        <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
        <div class="mat-button-focus-overlay"></div>
      </button>
    </div>
    <div fxFlex>
      <h5>{{'VIEW_CONTRACT' | translate: {Default: 'View Contract'} }}</h5>
    </div>
    <div fxFlex="nogrow">
      <!--button (click)="editCustomer()" class="ml-xs mat-icon-button" mat-icon-button="">
      <span class="mat-button-wrapper">
      <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">edit</mat-icon>
      </span>
        <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
        <div class="mat-button-focus-overlay"></div>
      </button-->
      <button (click)="deleteContract()" class="ml-xs mat-icon-button" mat-icon-button="">
        <span class="mat-button-wrapper">
          <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">delete</mat-icon>
        </span>
        <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
        <div class="mat-button-focus-overlay"></div>
      </button>
    </div>
  </div>
</mat-toolbar>

<div class="view-invoice-page" fxLayout="column" fxLayoutAlign="center">
  <mat-sidenav #sendContractPanel position="end" class="task-panel" mode="over" opened="false" style="width:95%">
    <app-send-contract *ngIf="contract && contract.contractId" [contextIdDto]="contextIdDto" [eventBus]="eventBus"
      [visible]="true" (contractSent)="contractSent()" (cancel)="cancel()" [contract]="contract"></app-send-contract>
  </mat-sidenav>

  <div [class.invoice-container]="!isContractModal" [fxFlex]="viewWidth" fxFlex.xs="100"
    [ngClass]="{'mt-1': !isContractModal}">

    <mat-toolbar color="primary" *ngIf="isContractModal && !noToolbar">
      <div fxLayout='row'>
        {{'VIEW_CONTRACT' | translate: {Default: 'View Contract'} }}
      </div>

      <div class="example-spacer"></div>

      <button (click)="editContract()" class="ml-xs mat-icon-button" mat-icon-button="">
        <span class="mat-button-wrapper">
          <mat-icon role="img" class="demo-toolbar-icon" aria-label="edit">edit</mat-icon>
        </span>
        <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
        <div class="mat-button-focus-overlay"></div>
      </button>

      <button (click)="deleteContract()" class="ml-xs mat-icon-button" mat-icon-button="">
        <span class="mat-button-wrapper">
          <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">delete</mat-icon>
        </span>
        <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
        <div class="mat-button-focus-overlay"></div>
      </button>
    </mat-toolbar>

    <div *ngIf="contract" class="mdc-px-3">
      <mat-card appearance="outlined" class="mdc-mb-2">
        <mat-card-content>
          <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="16px" class="mdc-mb-2">
            <div fxFlex="nogrow">
              <button *ngIf="isContractModal" mat-icon-button (click)="closeModal()">
                <mat-icon>arrow_back</mat-icon>
                <!-- {{'Close' | translate}} -->
              </button>
            </div>
            <div fxFlex>
              <p class=""><span class="mat-text-secondary">{{'DATE_ISSUED' | translate: {Default: 'Date Issued'}
                  }}:</span> {{contract.dateCreated
                | localizedDate: 'short' }}</p>
              <p class=""><span class="mat-text-secondary">{{'DUE_DATE' | translate: {Default: 'Due Date'} }}:</span>
                {{dueDate | localizedDate: 'short' }}</p>
            </div>
            <div fxFlex="nogrow" align="end">
              <button *ngIf="!contract.dateClientSigned && !contract.signature" disabled=""
                style="border-radius: 20px; background-color: #f7edce; border-color: transparent; color: orange;"
                class="ng-star-inserted">{{'UNSIGNED' | translate: {Default: 'Unsigned'} }}</button>
              <button *ngIf="contract.dateClientSigned || contract.signature" disabled=""
                style="border-radius: 20px; background-color: #d5edeb; border-color: transparent; color: #009688;"
                class="ng-star-inserted">{{'SIGNED' | translate: {Default: 'Signed'} }}</button>
              <div *ngIf="contract.signature">
                <div *ngIf="contract.dateClientSigned">
                  <p class="mb-0"><span class="mat-text-secondary">{{'DATE_CLIENT_SIGNED' | translate: {Default: 'Date
                      Client Signed'} }}: </span>
                    {{contract.dateClientSigned | localizedDate:'short' }}</p>
                </div>
              </div>
            </div>
          </div>

          <app-flo-editor *ngIf="contract" [text]="contract.legalText" [readOnly]="true" class="mdc-mb-2 d-block"></app-flo-editor>

          <div fxLayout="row wrap" fxLayoutAlign=" center" fxLayoutGap="16px">
            <div fxFlex="nogrow" class="mdc-py-1">
              <button mat-raised-button color="accent" (click)="sendContract()">{{'SEND_CONTRACT' | translate: {Default:
                'Send Contract'} }}</button>
            </div>
            <div fxFlex="nogrow" *ngIf="!contract.dateClientSigned" class="mdc-py-1">
              <button mat-raised-button color="accent" (click)="gotoContract()">{{'CUSTOMER_SIGN' | translate: {Default:
                'Customer Sign'} }}</button>
            </div>
            <div fxFlex="nogrow" *ngIf="contract.dateClientSigned" class="mdc-py-1">
              <button mat-raised-button color="accent" (click)="gotoContract()">{{'VIEW_SIGNED_CONTRACT' | translate:
                {Default: 'View Signed Contract'} }}</button>
            </div>
            <div fxFlex></div>
            <div fxFlex="nogrow" *ngIf="isContractModal" class="mdc-py-1">
              <button mat-raised-button (click)="closeModal()">{{'CLOSE' | translate: {Default: 'Close'} }}</button>
            </div>
          </div>

        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
