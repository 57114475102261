<mat-toolbar color="primary">
  <div fxFlex fxLayout="row" fxLayoutAlign=" center">
    <div fxFlex="nogrow">
      <button mat-button (click)="backToList()" class="px-1">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
    <div fxFlex>
      <span *ngIf="!model.id">{{'ADD_PLAN' | translate}}</span>
      <span *ngIf="model.id">{{'EDIT_PLAN' | translate}}</span>
    </div>
    <span class="example-spacer"></span>
    <div fxFlex="nogrow">
      <button *ngIf="!data?.customerId" mat-raised-button class="mx-4" (click)="navigateToViewPlan()" color="accent">{{'VIEW_PLAN' | translate}}
        <mat-icon aria-hidden="false" aria-label="Example home icon">remove_red_eye</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>

<div class="container">
  <form #planDefForm="ngForm">

    <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="center" fxLayoutGap="16px" class="mt-1">
      <div fxFlex="100" fxFlex.gt-sm="65">

        <mat-card appearance="outlined" class="mdc-mb-1 mx-0">
          <mat-card-title>{{'PLAN_DETAILS' | translate}}</mat-card-title>
          <hr>
          <mat-card-content>
            <div fxLayout="row">
              <mat-form-field style="width: 100%" appearance="outline">
                <mat-label>{{'PLAN_NAME'| translate}}</mat-label>
                <input matInput [(ngModel)]="model.name" name="planName" #planName="ngModel" id="planName" placeholder="{{'PLAN_NAME' | translate}}" style="width: 100%" required planName>
                <mat-error *ngIf="planName.hasError('required')">
                  {{'PLAN_NAME_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
                </mat-error>
              </mat-form-field>
            </div>

            <div fxLayout="row">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{'DESCRIPTION' | translate}}</mat-label>
                <textarea matInput id="description" name="description" cols="100" rows="6" #description="ngModel" [(ngModel)]="model.description" placeholder="Enter Description" required description>
                    </textarea>
                <mat-error *ngIf="description.hasError('required')">
                  {{'DESCRIPTION_IS'}} <strong>{{'REQUIRED' | translate}}</strong>
                </mat-error>
              </mat-form-field>
            </div>

            <div fxLayout="row wrap" fxLayoutGap="16px">
              <div fxFlex="35" fxFlex.xs="100">
                <mat-slide-toggle color="accent" class="my-1" [(ngModel)]="model.unlimitedSessions" name="unlimitedSessions" #unlimitedSessions="ngModel">
                  <span class="ml-0">{{'UNLIMITED_SESSIONS' | translate}}?</span>
                </mat-slide-toggle>
              </div>
              <div fxFlex="nogrow" fxFlex.xs="100">
                <mat-form-field *ngIf="!model.unlimitedSessions" appearance="outline" style="max-width: 80px;">
                  <input matInput type="number" [(ngModel)]="model.numberOfSessions" name="numberOfSessions" #numberOfSessions="ngModel" id="numberOfSessions" required numberOfSessions>
                </mat-form-field>
              </div>
              <div fxFlex fxFlex.xs="100">
                <mat-hint *ngIf="!model.unlimitedSessions" style="font-size:12px; line-height: 15px;" class="mat-text-secondary">{{'RECURRING_PLAN_SESSIONS_DESC' | translate}}<br></mat-hint>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card appearance="outlined" class="mdc-mb-1 mx-0">
          <mat-card-title>{{'PAYMENT_DETAILS' | translate}}</mat-card-title>
          <hr>
          <div fxLayout="row wrap" fxLayoutGap="10px">
            <div fxFlex="35" fxFlex.xs="100">
              <mat-form-field style="width: 100%" appearance="outline">
                <input matInput type="number" [(ngModel)]="model.cost" name="cost" #cost="ngModel" id="cost" placeholder="Cost" required cost>

                <mat-error *ngIf="cost.hasError('required')">
                  {{'COST_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="nogrow" fxFlex.xs="100">
              <mat-form-field style="width: 100%" appearance="outline" #paymentSchedule>
                <mat-label>{{'PAYMENT_SCHEDULE' | translate}}</mat-label>
                <mat-select [(ngModel)]="model.paymentSchedule" name="paymentSchedule" (selectionChange)="changePaymentSchedule($event)" #paymentSchedule="ngModel" required="true" placeholder="{{'PAYMENT_TYPE' | translate}}">
                  <mat-option [value]="'IMMEDIATE'" selected>Immediate
                  </mat-option>
                  <mat-option [value]="'RECURRING'">Recurring payment
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxFlex fxFlex.xs="100" *ngIf="model.paymentSchedule === 'RECURRING'">
              <mat-form-field style="width: 100%" appearance="outline" #interval>
                <mat-label>{{'INTERVAL' | translate}}</mat-label>
                <mat-select [(ngModel)]="model.paymentInterval" name="paymentInterval" #paymentInterval="ngModel" required="true" placeholder="{{'INTERVAL' | translate}}">
                  <mat-option [value]="'MONTHLY'">{{'MONTHLY' | translate}}
                  </mat-option>
                  <mat-option [value]="'ANNUALLY'">{{'ANNUALLY' | translate}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

          </div>

        </mat-card>

        <div>
          <button mat-raised-button color="primary" *ngIf="!model.id" (click)="addPlanDef()" [disabled]="!planDefForm.form.valid" disabled="disabled">{{'CREATE_PLAN' | translate}}
          </button> &nbsp;
          <button mat-raised-button color="primary" *ngIf="model.id" (click)="updatePlanDef()" [disabled]="!planDefForm.form.valid" disabled="disabled">{{'UPDATE' | translate}}
          </button>&nbsp;
          <button mat-raised-button color="primary" *ngIf="model.id && customerPlans.length === 0" (click)="deletePlanDef()">{{'DELETE' | translate}}
          </button>&nbsp;
          <button mat-raised-button color="primary" *ngIf="customerId" (click)="closeDialog()" class="ml-1">{{'CLOSE' | translate}}
          </button>
        </div>



      </div>
      <div fxFlex="100%" fxFlex.gt-sm="35">
        <mat-card appearance="outlined" class="mdc-mb-1 mx-0">
          <mat-card-title>{{'ADD_CUSTOMER(S)' | translate}}</mat-card-title>
          <hr>
          <div *ngIf="!customerId && !customerOnboarding">
            <form #searchForm="ngForm">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-hint style="color: red;" *ngIf="customerDto && !customerDto.username">{{'CUSTOMER_HAS_NO_EMAIL' | translate}}</mat-hint>
                <mat-label>{{'START_TYPING_TO_SEARCH_CUSTOMERS' | translate}}</mat-label>
                <input matInput placeholder="{{'START_TYPING_TO_FILTER' | translate}}" [matAutocomplete]="auto" required (keyup)="onKey($event)">


                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)" (optionSelected)="handler($event)">
                  <mat-option *ngFor="let tuple of searchCustomerResult" [value]="tuple.id">
                    {{ tuple.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </form>

          </div>
          <div>
            <ngx-datatable class="material m-0" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'" [rows]="subscribeToPlanList" style="box-shadow: none !important;">

              <ngx-datatable-column name="{{'NAME' | translate}}" [width]="100">
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
                  {{row.customerName}}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="{{'PAYMENT_ON' | translate}}" [width]="100">
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
                  <mat-select [(ngModel)]="row.dayOfPayment" name="{{'dayOfPayment' + rowIndex}}">
                    <mat-option [value]="1">
                      1st
                    </mat-option>
                    <mat-option [value]="2">
                      2nd
                    </mat-option>
                    <mat-option [value]="3">
                      3rd
                    </mat-option>
                    <mat-option *ngFor="let number of [4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]" [value]="number">
                      {{number + 'th'}}
                    </mat-option>
                    <mat-option [value]="21">
                      21st
                    </mat-option>
                    <mat-option [value]="22">
                      22nd
                    </mat-option>
                    <mat-option [value]="23">
                      23rd
                    </mat-option>
                    <mat-option *ngFor="let number of [24,25,26,27,28,29,30]" [value]="number">
                      {{number + 'th'}}
                    </mat-option>
                    <mat-option [value]="31">
                      31st
                    </mat-option>
                  </mat-select>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="{{'ACTION' | translate}}" [width]="100">
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
                  <a (click)="removeCustomerFromList(rowIndex)">
                    <mat-icon [ngStyle]="{'color':'red'}">remove_circle_outline</mat-icon>
                  </a>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </mat-card>
        <mat-card appearance="outlined" class="mdc-mb-1 mx-0" *ngIf="model.id">
          <mat-card-title>{{ customerPlans.length }} Customer(s)</mat-card-title>
          <hr>
          <div>
            <ngx-datatable class="material m-0" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'" [rows]="customerPlans" style="box-shadow: none !important;">

              <ngx-datatable-column name="{{'NAME' | translate}}" [width]="100">
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
                  {{row.customerFullName}}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="{{'PAYMENT' | translate}}" [width]="100">
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
                  <div fxLayoutAlign="center" [class]="row.lastPaymentStatus == 'Received' ? 'planStatusActive' : 'planStatusInactive'">{{
                    row.lastPaymentStatus == 'Pending' ? 'Waiting for Card Info' : row.lastPaymentStatus }}</div>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="{{'ACTION' | translate}}" [width]="100">
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
                  <mat-icon (click)="deletePlan(row.id)" [ngStyle]="{'color':'red'}">remove_circle_outline</mat-icon>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </mat-card>
      </div>
    </div>


  </form>
</div>
