import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Page } from '../../shared/model/page';
import {ActivatedRoute, Router} from '@angular/router';
import { ContextService } from '../../context.service';
import { ContextIdDto } from '@savvy/view-definition';
import {SendToAllMessage, SendToAllMessageService} from '@savvy/send-to-all';
import { PageDtoSendToAllMessage} from '@savvy/send-to-all/model/models';
import { DateUtils } from '../../dates/DateUtils';
import { Subscription } from 'rxjs';
import { EmailRecipient } from '@savvy/messaging';

@Component({
  selector: 'app-send-to-all-messages',
  templateUrl: './send-to-all-messages.component.html',
  styleUrls: ['./send-to-all-messages.component.scss']
})
export class SendToAllMessagesComponent implements OnInit, OnDestroy {

  sendToAllMessages: Array<SendToAllMessage> = [];
  contextIdDto: ContextIdDto;
  recipientType: string;
  subscriptions: Subscription[] = [];
  selected: SendToAllMessage[] = [];
  page = new Page();
  loading = false;
  firstNameSorted = true;
  sortAscending = true;

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private contextService: ContextService,
    private dateUtils: DateUtils,
    private sendToAllMessageService: SendToAllMessageService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
        this.route.params.subscribe(params => {
          this.recipientType = params['recipientType'];
          this.loadMessages();
        });
      }
    }));
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  backToList() {
    this.location.back();
  }

  loadMessages() {
    this.loading = true;
    console.log('loadMessages');
    console.log('page number is ' + this.page.pageNumber);
    console.log('page size is ' + this.page.size);
    console.log('firstNameSorted is ' + this.firstNameSorted);
    // console.log('sortAscending is ' + this.sortAscending);
    this.sendToAllMessageService.getMessagesByType(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      this.recipientType,
      (this.page.pageNumber + 1),
      this.page.size).subscribe(response => {
        this.loading = false;
        this.handleMessages(response);
      });

  }

  handleMessages(messagesPage: PageDtoSendToAllMessage) {
    this.sendToAllMessages = messagesPage.contents;
    this.page.totalElements = messagesPage.totalElements;
    this.page.totalPages = messagesPage.totalPages;
  }

  onSort(event: any) { }

  onSelect(event: any) {
    console.log('on select called', event);
    console.log('selected is', this.selected[0]);
    if (this.selected[0].recipientType === EmailRecipient.RecipientTypeEnum.Customer) {
      this.router.navigate(['/customer/customerMessage',
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.selected[0].id]);
    } else if (this.selected[0].recipientType === EmailRecipient.RecipientTypeEnum.Lead) {
      console.log('routing to leadMessage');
      this.router.navigate(['/lead/leadMessage',
        this.selected[0].id]);
    } else if (this.selected[0].recipientType === EmailRecipient.RecipientTypeEnum.Student) {
      this.router.navigate(['/student/studentMessage',
        this.selected[0].id]);
    }
  }

  setPage(pageInfo: any) {
    this.page.pageNumber = pageInfo.offset;
    this.loadMessages();
  }
}
