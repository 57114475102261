import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialog } from '../confirmation-dialog/confirmation-dialog.model';

@Component({
  selector: 'app-clarification-dialog',
  templateUrl: './clarification-dialog.component.html',
  styleUrls: ['./clarification-dialog.component.scss']
})
export class ClarificationDialogComponent implements OnInit {

  clarification: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialog,
    public dialogRef: MatDialogRef<ClarificationDialogComponent>
  ) { }

  ngOnInit(): void {
  }

  clarified() {
    this.dialogRef.close({
      clarify: this.clarification
    });
  }

}
