import {
  Component, ComponentRef,
  EventEmitter,
  Input,
  OnDestroy, OnInit, Output, ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabGroup } from '@angular/material/tabs';
import { Link, QuickLinkDto } from '@savvy/messaging';
import { KeyValueTupleDto, TemplateValuesList } from '@savvy/template-engine';
import { LinkDetails, LinkSelectedEvent } from '../../templateLinkEvents';

@Component({
  selector: 'app-quick-links-panel',
  templateUrl: './quick-links-panel.component.html',
  styleUrls: ['./quick-links-panel.component.scss']
})
export class QuickLinksPanelComponent implements OnInit, OnDestroy {

  @ViewChild(MatTabGroup) matTabGroup: MatTabGroup;

  @Input() currencyCode: string;
  @Input() templateValuesList: TemplateValuesList;
  @Input() quickLinks: QuickLinkDto[] = [];
  @Input() onlyShowVariables = false;


  @Output() linkSelected: EventEmitter<LinkSelectedEvent> = new EventEmitter<LinkSelectedEvent>();
  @Output() variableAdded = new EventEmitter();

  _selectedItem: any;
  linkTypeRef = Link.LinkTypeEnum;
  selected: UntypedFormControl;
  tabIndex = 0;

  variablesList: LinkDetails[] = [];
  private cmpRef: ComponentRef<any>;

  constructor(
    private snackBar: MatSnackBar,
  ) { }


  ngOnInit() {
    // this.tabIndex = 0;
    // setTimeout(() => {
    //   this.matTabGroup.realignInkBar();
    // }, 300);
    this.handleResponse();
  }

  ngOnDestroy() {
    if (this.cmpRef) {
      this.cmpRef.destroy();
    }
  }

  onLinkAction(data: any) {
    this.doAction(data);
  }

  onVariableAction(data: any) {
    this.variableAdded.emit(data);
  }

  onDepositRequestAction(data: any) {
    this.doAction(data);
  }

  onCheckInFormAction(data: LinkSelectedEvent) {
    console.log('onCheckInFormAction', data);
    this.doAction(data);
  }

  onQuestionnaireAction(data: any) {
    this.doAction(data);
  }

  onCustomerPortalAction(data: any) {
    this.doAction(data);
  }

  doAction(data: LinkSelectedEvent) {
    if (!this.linkSelected) {
      return;
    }
    console.log('data ', data);
    this.linkSelected.emit(data);
  }


  variableSelectionChange(data: any) {
    this.onVariableAction(data);
  }

  private getLabel(v: KeyValueTupleDto) {
    if (v.value && v.value !== 'TODO' && v.value.length > 0) {
      return v.key + '  (' + v.value + ')';
    }
    return v.key;
  }

  private handleResponse() {
    this.variablesList = this.templateValuesList.keyValueTupleDtos.map(v => ({
      type: '',
      label: this.getLabel(v),
      subLabel: '',
      id: v.key,
      data: v,
    }));
  }

}
