import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContextIdDto } from '@savvy/datalist';
import { MenuDefinition, WorkflowDefinitionId } from '@savvy/ui';
import { WorkflowDefinition, WorkflowdefService } from '@savvy/workflow-definition';
import { WorkflowBoardDto, WorkflowinstcompService } from '@savvy/workflow-instance';
import { NavChangeService } from '../element/NavChangeService';
import MenuItemTypeEnum = MenuDefinition.MenuItemTypeEnum;

interface WorkflowInstance {
  title: string;
  description: string;
  class?: string;
}

interface Workflowboard {
  title: string;
  workflowInstances: WorkflowInstance[];
}

@Component({
  selector: 'app-workflow-overview',
  templateUrl: 'workflowOverview.component.html',
  styleUrls: ['./workflowOverview.component.scss']
})
export class WorkflowOverviewComponent implements OnInit {

  contextIdDto: ContextIdDto;
  workflowBoardDto: WorkflowBoardDto;
  workflowBoard: Workflowboard[] = [];
  workflowDefinitions: WorkflowDefinition[];
  currentWorkflowDef: WorkflowDefinition;
  sub: any;
  zeroState: boolean;

  constructor(
    private route: ActivatedRoute,
    private api: WorkflowinstcompService,
    private worflowDefApi: WorkflowdefService,
    private navChange: NavChangeService) {

    // eventBus.eventAdded$.subscribe(event => this.onEvent(event));
  }

  ngOnInit(): void {
    this.navChange.add(MenuItemTypeEnum.WorkflowOverview);
    this.sub = this.route.params.subscribe(params => {
       this.contextIdDto = <ContextIdDto>{};
      this.contextIdDto.contextId = params['contextId'];
      this.contextIdDto.contextIdType = params['contextIdType'];
      this.loadWorkflowDefs();
    });
  }
  loadWorkflowDefs() {
    this.worflowDefApi.getAllWorkflowDefs(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
      //'ENTITY_INSTANCE'
    ).subscribe(response => {
      this.workflowDefinitions = response.workflowDefinitions;
      if (this.workflowDefinitions && this.workflowDefinitions.length > 0) {
        this.switchBoard(this.workflowDefinitions[0]);
      }
    });
  }

  switchBoard(workflowDefinition: WorkflowDefinition) {
    this.currentWorkflowDef = workflowDefinition;
    this.loadBoard(this.currentWorkflowDef.workflowDefinitionId);
  }
  loadBoard(workflowDefinitionId: WorkflowDefinitionId) {
    console.log('loading board');
    this.api.loadWorkflowBoard(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      workflowDefinitionId.id).subscribe(response => {
        this.workflowBoardDto = response.workflowBoardDto;
        this.populateBoard();
      });
  }

  populateBoard() {
    this.workflowBoard = new Array();
    if (this.workflowBoardDto && this.workflowBoardDto.workflowBoardColumnDtos) {
      this.workflowBoardDto.workflowBoardColumnDtos.forEach(col => {
        const board = <Workflowboard>{};
        board.title = col.title;
        board.workflowInstances = new Array();
        if (col.workflowOnBoardDtos) {
          col.workflowOnBoardDtos.forEach(workflowInstance => {
            const toAdd = <WorkflowInstance>{};
            toAdd.title = workflowInstance.title;
            toAdd.class = workflowInstance.cssClass;
            toAdd.description = workflowInstance.description;
            board.workflowInstances.push(toAdd);
          });
        }
        this.workflowBoard.push(board);
      });
    }
    this.zeroState = this.workflowBoard.length === 0;
  }
}

