
<div style="width: 60vw;max-height: 80vh">
  <mat-toolbar color="primary" >
    <mat-toolbar-row>{{'Conflicting Bookings' | translate}}</mat-toolbar-row>
  </mat-toolbar>
  <mat-card appearance="outlined">
    <mat-card-content>
        <mat-card appearance="outlined" *ngFor="let clash of clashResponse.clashes">
          <mat-card-header>
            <mat-card-title>Requested Slot</mat-card-title>
            <!--mat-card-subtitle>Day {{ clash.requestedSlot.requestedDay }}</mat-card-subtitle-->
            <!--mat-card-subtitle>User {{ clash.requestedSlot.userId.id }}</mat-card-subtitle-->
            <!--mat-card-subtitle>Start {{ clash.requestedSlot.dateRange.start }}</mat-card-subtitle-->
          </mat-card-header>
          <mat-card-content>
            <mat-list>
              <mat-list-item *ngFor="let entry of clash.clashedCalendarEntries">
                 {{ entry.cellLabel }}
              </mat-list-item>
            </mat-list>
          </mat-card-content>
        </mat-card>
    </mat-card-content>
    <mat-card-content>
      <button mat-raised-button (click)="dialogRef.close(false)">{{'Cancel' | translate}}</button>
    </mat-card-content>
  </mat-card>
</div>

