import { Directive, ElementRef, HostListener, Input, NgModule } from '@angular/core';
import { GLOBAL } from '../../app.constants';
@Directive({
  selector: '[appNumberOnly]'
})
export class NumberOnlyDirective {

  @Input() appOnlyNumber = true;
  @Input() decimalPoints = 0;
  @Input() minValue: number;
  @Input() maxValue: number;
  @Input() thousandSep: string = GLOBAL.groupSeparator;
  @Input() decimalSep: string = GLOBAL.decimalSeparator;
  @Input() allowComma = false;
  @Input() allowNegetiveIntegers = false;
  @Input() allowReplaceDecimalSeparator = false;

  private isPaste = false;
  private inputLastValue = '';
  // Allow decimal numbers. The \. is only allowed once to occur

  constructor(private el: ElementRef) {
    // if (this.appOnlyNumber) {
    //   this.el.nativeElement.value = this.el.nativeElement.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, this.thousandSep);
    // }
  }

  @HostListener('change', ['$event']) onChange(event) {
    if (this.appOnlyNumber) {
      // console.log('on change called');
      // this.el.nativeElement.value = this.el.nativeElement.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, this.thousandSep);
    }
  }

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = event as KeyboardEvent;
    if (this.appOnlyNumber) {
      // SM-1983 - handling paste of data with charcters
      if ((e.keyCode === 86 && e.ctrlKey === true)
        || (this.el.nativeElement.selectionStart === 0 && this.el.nativeElement.selectionEnd === this.el.nativeElement.value.length)) {
        this.isPaste = true;
        this.inputLastValue = this.el.nativeElement.value || '';
      } else {
        this.isPaste = false;
      }
      // SM-1983 - End - handling paste of data with charcters
      if ([46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode === 65 && e.ctrlKey === true) ||
        // Allow: Ctrl+C
        (e.keyCode === 67 && e.ctrlKey === true) ||
        // Allow: Ctrl+V
        (e.keyCode === 86 && e.ctrlKey === true) ||
        // Allow: Ctrl+X
        (e.keyCode === 88 && e.ctrlKey === true) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)) {
        // let it happen, don't do anything
        return;
      }

      if (e.key === GLOBAL.groupSeparator && GLOBAL.groupSeparator !== '.') {
        e.preventDefault();
      }

      let newString = this.el.nativeElement.value || '';
      const cursorPos = this.el.nativeElement.selectionStart;
      newString = newString.slice(0, cursorPos) + e.key + newString.slice(cursorPos);
      if (this.el.nativeElement.selectionStart === 0 && this.el.nativeElement.selectionEnd === this.el.nativeElement.value.length) {
        if (this.isValidNumberString(newString)) {
          return;
        } else {
          e.preventDefault();
        }
      }
      if (GLOBAL.decimalSeparator !== '.' && e.key === '.' && this.decimalPoints > 0) {
        newString = newString.replace(new RegExp('\\.', 'gi'), GLOBAL.decimalSeparator);
        e.preventDefault();
        if (this.isValidNumberString(newString) && this.isValidValue(newString)) {
          this.el.nativeElement.value = newString;
        }
      }
      if (!this.isValidNumberString(newString) || !this.isValidValue(newString)) {
        e.preventDefault();
      }
    }
  }

  @HostListener('keyup', ['$event']) onKeyUp(event) {
    if (this.appOnlyNumber) {
      // this.el.nativeElement.value = this.el.nativeElement.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, this.thousandSep);
      // SM-1983 - handling paste of data with charcters
      if (this.isPaste) {
        const curValue = this.el.nativeElement.value || '';
        if (!this.isValidNumberString(curValue) || !this.isValidValue(curValue)) {
          this.el.nativeElement.value = this.inputLastValue;
          this.el.nativeElement.dispatchEvent(new Event('input'));
        }
      }
      // SM-1983 - End - handling paste of data with charcters
    }
  }

  private isValidNumberString(newString): boolean {
    const regex = new RegExp(/^[0-9]*$/);
    const regexComma = new RegExp(/^[0-9,.]*$/);
    const regexNegetiveInt = new RegExp(/^-?[0-9]\d*(\.\d+)?$/);

    if (this.decimalPoints === 0) {
      if (!regex.test(newString)) {
        return false;
      }
    } else {
      if (newString.indexOf(GLOBAL.decimalSeparator) > 0) {
        const numberArr = newString.split(GLOBAL.decimalSeparator);
        if (numberArr.length !== 2) {
          return false;
        } else {
          // SM-2221 by Shivani Patel,
          // Added .replace because regex.test(num) get fails when numberArr[0] has thousandSep in it.
          const num = numberArr[0].replace(new RegExp(GLOBAL.groupSeparator === '.' ? `\\${GLOBAL.groupSeparator}`
            : GLOBAL.groupSeparator, 'gi'), '');
          const dec = numberArr[1];
          if ((dec.length > 0 && !regex.test(dec)) || (dec.length > this.decimalPoints)) {
            if (this.allowNegetiveIntegers) {
              if (newString === '-') {
                return true;
              } else if (!regexNegetiveInt.test(newString)) {
                return false;
              }
            } else if (!regex.test(num)) {
              return false;
            }
            return false;
          }
        }
      } else {
        if (this.allowComma) {
          if (!regexComma.test(newString)) {
            return false;
          }
        } else if (this.allowNegetiveIntegers) {
          if (newString === '-') {
            return true;
          } else if (!regexNegetiveInt.test(newString)) {
            return false;
          }
        } else {
          if (!regex.test(newString)) {
            return false;
          }
        }
      }
    }
    return true;
  }

  private isValidValue(newString) {
    // SM-2221 by Shivani Patel
    // Check validation for maximum and minimum value
    let filteredNum = newString.replace(new RegExp(GLOBAL.groupSeparator === '.' ? `\\${GLOBAL.groupSeparator}`
      : GLOBAL.groupSeparator, 'gi'), '');
    if (GLOBAL.decimalSeparator !== '.' && this.allowReplaceDecimalSeparator) {
      filteredNum = filteredNum.replace(new RegExp(GLOBAL.decimalSeparator, 'g'), '.');
    }
    if ((this.minValue && filteredNum < this.minValue) || (this.maxValue && filteredNum > this.maxValue)) {
      return false;
    }
    return true;
  }


}
