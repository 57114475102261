<ngx-datatable
      *ngIf="rows && rows.length > 0"
      class='no-shadow material has-plus-btn'
      [rows]='rows'
      [columnMode]="'force'"
      [headerHeight]="'auto'"
      [rowHeight]="'auto'"
      [footerHeight]="0"
      [columns]="columns"
      [externalPaging]="true"
      [count]="page.totalElements"
      [offset]="page.pageNumber"
      [limit]="page.size"
      (page)='setPage($event)'
    >

      <!--ngx-datatable-footer>

      </ngx-datatable-footer-->
</ngx-datatable>
