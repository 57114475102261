import {ChangeDetectorRef, Component, OnInit, ViewChildren} from '@angular/core';

import {ActivatedRoute} from '@angular/router';

import {ContextIdDto} from '@savvy/view-definition';
import {TaskBoardComponent} from './taskBoard.component';
import {TaskListComponent} from './taskList.component';
import {EventBusService} from '../element/EventBusService';

@Component({
  selector: 'app-tasks',
  templateUrl: 'tasks.component.html',
  styleUrls: ['tasks.component.scss']
})
export class TasksComponent implements OnInit {
  @ViewChildren(TaskBoardComponent) taskBoard: TaskBoardComponent;
  @ViewChildren(TaskListComponent) taskList: TaskListComponent;
  sub: any;
  contextIdDto: ContextIdDto;
  showBoard = false;
  eventBus = new EventBusService();

  constructor(private route: ActivatedRoute, private cd: ChangeDetectorRef) {
  }


  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.contextIdDto = <ContextIdDto>{};
      this.contextIdDto.contextId = params['contextId'];
      this.contextIdDto.contextIdType = params['contextIdType'];
    });
  }

  showTaskList() {
    console.log('showTaskList');
    this.showBoard = false;
  }

  showTaskBoard() {
    console.log('showTaskBoard');
    this.showBoard = true;
  }
}

