import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  ContextIdDto,
  Notification,
  NotificationService,
  NotificationsFilter,
  NotificationTypeCheckedDto,
  UpdateNotification
} from '@savvy/notification';
import { MatDialog } from '@angular/material/dialog';
import { ViewNotificationDialogComponent } from './viewNotificationDialog.component';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { Subscription } from 'rxjs';
import {
  NotificationSettingsDialogComponent
} from './notification-settings/notification-settings-dialog/notification-settings-dialog.component';
import { Page } from '../shared/model/page';
import { LinkId } from '@savvy/notification';
import { UrlLookupService } from '../shared/services/urlLookup.service';
import { DeviceDetectorService } from 'ngx-device-detector';

export interface DialogData {
  notification: Notification;
  contextIdDto: ContextIdDto;
}

@Component({
  selector: 'app-notifications-panel',
  templateUrl: './notificationsPanel.component.html',
  styleUrls: ['./notificationsPanel.component.scss']
})
export class NotificationsPanelComponent implements OnInit, OnDestroy {
  @Input() contextIdDto: ContextIdDto;
  @Output() notificationUpdated: EventEmitter<any> = new EventEmitter();

  showMarkAll = false;
  today: number = Date.now();
  notifications: Array<Notification>;
  notificationTypeEnum = Notification.NotificationTypeEnum;


  selected: Notification[] = [];
  timer;
  errorCounter = 120000; // 2 min
  periodToLoad = 'today';


  totalNumUnread = -1;

  includeRead = false;
  page = new Page();


  notificationTypes = new Array();

  subscriptions: Subscription[] = [];

  loading = false;

  allNotificationTypes: Array<NotificationTypeCheckedDto>;

  constructor(
    private api: NotificationService,
    private urlLookupService: UrlLookupService,
    private notify: FloSnackbarComponent,
    public dialog: MatDialog,
    public deviceService: DeviceDetectorService
  ) {

  }


  cancel() {
  }

  ngOnInit() {
    console.log('loading notifications as none exist');
    this.setPage({ offset: 0, size: 20 });
    // this.loadNotificationsFiltered();
    this.loadNotificationTypes();

  }

  userNotificationSettings() {
    const dialogRef = this.dialog.open(NotificationSettingsDialogComponent, {
      data: {
        contextIdDto: this.contextIdDto
      },
      panelClass: ['notification-settings-dialog', 'scrollable-modal'],
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  loadNotificationTypes() {
    this.api.getAllNotificationTypes().subscribe(response => {
      console.log('allNotificationTypes', response);
      this.allNotificationTypes = response;

      this.allNotificationTypes.forEach(notType => {
        this.notificationTypes.push(notType.notificationType);
      });
    });
  }

  setPage(pageInfo) {
    console.log('pageInfo is ' + pageInfo);
    this.page.pageNumber = pageInfo.offset;
    this.loadNotificationsFiltered();
  }

  getUnreadBannerNotifications() {
    this.api.loadUnreadAndBannerNotifications(1, 50, this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(response => {
      this.notificationUpdated.emit(response.numUnreadOverall);
    }, error => {
      this.notificationUpdated.emit(0);
    });
  }

  loadNotificationsFiltered() {

    const notificationsFilter = <NotificationsFilter>{};
    notificationsFilter.includeRead = this.includeRead;
    notificationsFilter.notificationTypes = new Array();

    if (this.periodToLoad === 'today') {
      notificationsFilter.todays = true;
    }
    if (this.periodToLoad === 'yesterday') {
      notificationsFilter.yesterdays = true;
    }
    if (this.periodToLoad === 'lastWeek') {
      notificationsFilter.lastWeek = true;
    }

    this.loading = true;

    this.api.loadNotificationsFiltered((this.page.pageNumber + 1), this.page.size,
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      notificationsFilter).subscribe(response => {
        this.loading = false;
        console.log('allNotificationTypes', response);
        this.notifications = response.notificationList.contents;
        this.page.totalElements = response.notificationList.totalElements;
        this.page.totalPages = response.notificationList.totalPages;
        if (this.notifications.filter(n => !n.read)?.length > 0) {
          this.showMarkAll = true;
        }
        this.getUnreadBannerNotifications();
      });

    this.api.loadNotificationsPage(1, 10, this.contextIdDto.contextId,
      this.contextIdDto.contextIdType, '', false).subscribe(response => {
        this.totalNumUnread = response.numUnread;
      });
  }

  onSort(event: any) {

  }

  onPage() {

  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }


  selectNotification(notification: Notification) {

    if (notification.notificationType === Notification.NotificationTypeEnum.ContractSigned) {
      this.gotoContract(this.getLinkId('CONTRACT_ID', notification.linkIds));
    } else if (notification.notificationType === Notification.NotificationTypeEnum.ContactFormSubmission) {
      this.gotoContactForm(this.getLinkId('CONTACT_FORM_ID', notification.linkIds));
    }
  }



  getLinkId(type: string, linkIds: Array<LinkId>): string {
    let linkIdToRet;

    linkIds.forEach(linkId => {
      if (linkId.linkedIdType === type) {
        linkIdToRet = linkId.linkedId;
      }
    });
    return linkIdToRet;
  }




  gotoContract(contractId: string) {
    const url = this.urlLookupService.portalUrl() + '/contract.html?id=' + this.contextIdDto.contextId +
      '&contractId=' + contractId + this.urlLookupService.getEnv();
    this.urlLookupService.openNewTab(url);
  }




  gotoContactForm(contactFormId: string) {
    const url = this.urlLookupService.portalUrl() + '/viewSubmittedContactForm.html?id=' +
      this.contextIdDto.contextId + '&contactFormId='
      + contactFormId + this.urlLookupService.getEnv();
    this.urlLookupService.openNewTab(url);
  }

  deleteAll() {
    this.api.markAllAsRead(this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(response => {
      this.notify.message = 'Marked all as read';
      this.notify.open();
      this.loadNotificationsFiltered();
    });
  }

  markAsRead(notification: Notification) {
    const req = {} as UpdateNotification;
    req.notification = notification;
    req.notification.read = true;
    req.contextIdDto = this.contextIdDto;

    this.api.update(req).subscribe(response => {
      this.notify.message = 'Marked as read';
      this.notify.open();
      this.loadNotificationsFiltered();
    });
  }

  notificationSettings() {
    const dialogRef = this.dialog.open(NotificationSettingsDialogComponent, {
      data: {
        contextIdDto: this.contextIdDto
      },
      panelClass: ['notification-settings-dialog', 'scrollable-modal'],
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  onSelect(notification) {
    this.selectNotification(notification);
  }

  handleResponse(response) {
    this.notifications = response.notificationList.contents;
    console.log('notifications', this.notifications);
  }

  showNotification(notification: Notification) {
    const dialogRef = this.dialog.open(ViewNotificationDialogComponent, {
      // width: '450px',
      data: { notification, contextIdDto: this.contextIdDto },
      panelClass: ['helpwindow']
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.loadNotificationsFiltered();
    });
  }
}


