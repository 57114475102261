import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ContextIdDto } from '@savvy/entity-instance-composite';
import { UserDto } from '@savvy/view-definition';
import { UserCountryService } from '../../../services/userCountry.service';
import { ConsumerService } from '@savvy/services';
import { PetSummaryDto } from '@savvy/pet/model/models';
import { PetServicesRequired } from '@savvy/appointment';
import { LookAndFeelConfig, LookAndFeelSharedService, TimeValue } from '../../shared/services/look-and-feel-shared.service';
import { DateUtils } from '../../dates/DateUtils';
import { IdNameTupleDto, UsercompService } from '@savvy/user';
import { ServiceSelectorPetComponent } from './serviceSelectorPet.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { MatAccordion } from '@angular/material/expansion';
import { StaffSelectorComponent } from '../../shared/components/staff-selector/staff-selector.component';
@Component({
  selector: 'app-pet-service-selector',
  templateUrl: 'petServiceSelector.component.html',
  styleUrls: ['petServiceSelector.component.scss'],
  styles: [`

  `]
})
export class PetServiceSelectorComponent implements OnInit {
  @ViewChild('petTable') petTable: DatatableComponent;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @Input() contextIdDto: ContextIdDto;
  @Input() customerId: string;
  @Input() durationRequired = false;
  @ViewChild(StaffSelectorComponent, { static: false }) staffSelectorComponent: StaffSelectorComponent;
  @ViewChild(ServiceSelectorPetComponent, { static: false }) serviceSelectorComponent: ServiceSelectorPetComponent;
  @Input() pets: Array<PetSummaryDto>;
  petServices: Array<PetServicesRequired> = new Array<PetServicesRequired>();
  valid = true;
  lookAndFeelConfig: LookAndFeelConfig;
  timeArray: TimeValue[] = [];
  userDtos: Array<UserDto>;
  totalAppointmentDuration = 0;
  selectedServices = {};
  step = 0;

  constructor(
    public userCountryService: UserCountryService,
    private lookAndFeelService: LookAndFeelSharedService,
    private dateUtils: DateUtils,
    private usercompService: UsercompService,
    private snackBar: MatSnackBar

  ) {

  }


  ngOnInit(): void {
    // listen for search field value changes
    this.lookAndFeelConfig = this.lookAndFeelService.getLookAndFeelConfig(this.contextIdDto);
    if (this.lookAndFeelConfig) {
      // this.lookAndFeel = this.lookAndFeelConfig.lookAndFeel;
      // this.minuteInterval = this.lookAndFeelConfig.minuteInterval;
      this.timeArray = this.lookAndFeelConfig.timeArray;
    } else {
      console.log('no look and feel config');
    }

    this.loadUsers();
  }

  serviceSelected(service: ConsumerService, petId, rowIndex: number) {
    this.selectedServices[petId][rowIndex].selectedService = service;
    this.selectedServices[petId][rowIndex].unitPrice = service.unitPrice;
    this.selectedServices[petId][rowIndex].selectedStaff = null;
    if (this.durationRequired) {
      this.selectedServices[petId][rowIndex].duration = service.durationInMins;
    }
    this.setAppointmentDuration();
    this.checkIfValid();
  }

  staffSelectionChanged(serviceUser: IdNameTupleDto, petId, rowIndex) {
    this.selectedServices[petId][rowIndex].selectedStaff = serviceUser;
    this.checkIfValid();
  }

  checkIfValid() {
    this.valid = true;
    for (const key in this.selectedServices) {
      if (Object.prototype.hasOwnProperty.call(this.selectedServices, key)) {
        const groupedRow = this.selectedServices[key];
        for (const pet of groupedRow) {
          if (!pet['selectedService'] || !pet['selectedStaff']) {
            this.valid = false;
          }
        }
      }
    }
  }


  startTimeChanged(value: any, row) {
    this.setEndTimeFromStart(value, row);
  }

  setEndTimeFromStart(value: any, row) {
    const durationInMins = 60;
    console.log('got duration in mins ' + durationInMins);
    row.endTime = this.dateUtils.addMinsToTimeString(value, durationInMins);
    console.log('just set endTime to ' + row.endTime);
    this.checkIfValid();
  }

  endTimeChanged(value, row) {
    if (value <= row.startTime) {
      row.endTime = row.startTime;
      this.snackBar.open('End time should be greater than start time', 'ok', {
        duration: 3000,
      });
    }
    this.checkIfValid();
  }

  compareTimeFn(user1: string, user2: string) {
    // console.log('comparing');
    return user1 && user2 && user1 === user2;
  }

  loadUsers() {
    this.usercompService.getEnvUsersFilterCustomers(this.contextIdDto.contextId).subscribe(response => {
      this.userDtos = response.userDtos;
    });
  }


  toggleExpandGroup(group) {
    console.log('Toggled Expand Group!', group);
    this.petTable.groupHeader.toggleExpandGroup(group);
  }

  addServiceNew(pet: PetSummaryDto) {
    if (!this.selectedServices[pet.id]) {
      this.selectedServices[pet.id] = [];
    }
    if (this.selectedServices[pet.id].length && !this.isRowValid(this.selectedServices[pet.id][this.selectedServices[pet.id].length - 1])) {
      this.snackBar.open('Please select a service first', 'ok', {
        duration: 3000,
      });
      return;
    }
    this.selectedServices[pet.id].push({
      selectedService: null,
      unitPrice: null,
      selectedStaff: null
    });
    this.checkIfValid();
  }

  // addService(group, expanded) {
  //   if (!expanded) {
  //     this.toggleExpandGroup(group);
  //     return;
  //   }
  //   if (!this.isRowValid(group.value[group.value.length - 1])) {
  //     this.snackBar.open('Please select a service first', 'ok', {
  //       duration: 3000,
  //     });
  //     return;
  //   }
  //   const newService = _.cloneDeep(group.value[0]);
  //   if (newService['selectedService']) {
  //     delete newService['selectedService'];
  //     delete newService['unitPrice'];
  //     delete newService['startTime'];
  //     delete newService['endTime'];
  //   }
  //   group.value.push(newService);
  // }

  setAppointmentDuration() {
    this.totalAppointmentDuration = 0;
    for (const key in this.selectedServices) {
      if (Object.prototype.hasOwnProperty.call(this.selectedServices, key)) {
        const groupedRow = this.selectedServices[key];
        for (const pet of groupedRow) {
          if (pet['selectedService']) {
            this.totalAppointmentDuration += pet['selectedService'].durationInMins;
          }
        }
      }
    }
  }

  getPetServicesRequired() {
    this.petServices = [];
    for (const petId in this.selectedServices) {
      if (Object.prototype.hasOwnProperty.call(this.selectedServices, petId)) {
        const services = this.selectedServices[petId];
        for (const service of services) {
          if (service['selectedService']) {
            const serviceIndex = this.petServices.findIndex(p => p.petId === petId);
            if (serviceIndex > -1) {
              const petServicesRequired = {
                serviceId: service['selectedService'].id,
                price: service['unitPrice'],
                groomerUserId: service['selectedStaff'].id,
                start: service['startTime'],
                end: service['endTime']
              };
              if (this.durationRequired) {
                petServicesRequired['duration'] = service['duration'];
              }
              this.petServices[serviceIndex].petServicesRequired.push(petServicesRequired);
            } else {
              const petServicesRequired = {
                serviceId: service['selectedService'].id,
                price: service['unitPrice'],
                groomerUserId: service['selectedStaff'].id,
                start: service['startTime'],
                end: service['endTime']
              };
              if (this.durationRequired) {
                petServicesRequired['duration'] = service['duration'];
              }
              this.petServices.push({
                petId,
                petServicesRequired: [petServicesRequired]
              });
            }
          }
        }
      }
    }
    return this.petServices;
  }

  getPetServicesRequiredwithDisplay() {
    const petServices = [];
    for (const petId in this.selectedServices) {
      if (Object.prototype.hasOwnProperty.call(this.selectedServices, petId)) {
        const services = this.selectedServices[petId];
        for (const service of services) {
          if (service['selectedService']) {
            const serviceIndex = petServices.findIndex(p => p.petId === petId);
            if (serviceIndex > -1) {
              const petServicesRequired = {
                serviceId: service['selectedService'].id,
                serviceName: service['selectedService']['serviceName'],
                groupName: service['selectedService']['groupName'],
                price: service['unitPrice'],
                groomerUserId: service['selectedStaff'].id,
                groomerUserName: service['selectedStaff'].fullName,
                groomerUserEmail: service['selectedStaff'].email,
                start: service['startTime'],
                end: service['endTime']
              };
              if (this.durationRequired) {
                petServicesRequired['duration'] = service['duration'];
              }
              petServices[serviceIndex].petServicesRequired.push(petServicesRequired);
            } else {
              const petServicesRequired = {
                serviceId: service['selectedService'].id,
                serviceName: service['selectedService']['serviceName'],
                groupName: service['selectedService']['groupName'],
                price: service['unitPrice'],
                groomerUserId: service['selectedStaff'].id,
                groomerUserName: service['selectedStaff'].fullName,
                groomerUserEmail: service['selectedStaff'].email,
                start: service['startTime'],
                end: service['endTime']
              };
              if (this.durationRequired) {
                petServicesRequired['duration'] = service['duration'];
              }
              const petName = this.pets?.find(pet => pet?.id === petId)?.name;
              petServices.push({
                petId,
                petName,
                petServicesRequired: [petServicesRequired]
              });
            }
          }
        }
      }
    }
    return petServices;
  }

  isRowValid(pet) {
    return pet['selectedService'] && pet['unitPrice'] && pet['selectedStaff'];
  }

  isGroupValid(group) {
    let valid = false;
    for (const pet of group.value) {
      if (pet['selectedService']) {
        if (this.isRowValid(pet)) {
          valid = true;
        } else {
          valid = false;
        }
      }
    }
    return valid;
  }

  deleteServiceNew(petId, rowIndex) {
    this.selectedServices[petId].splice(rowIndex, 1);
    this.setAppointmentDuration();
    this.checkIfValid();
  }

  // deleteService(groupRowIndex, groupRows) {
  //   let groupIndex = null;
  //   let rowIndex = null;
  //   if (typeof groupRowIndex === 'string') {
  //     const temp = groupRowIndex.split('-');
  //     groupIndex = temp[0];
  //     rowIndex = temp[1];
  //   }
  //   if (groupRows.length === 1) {
  //     this.clearService(groupRows);
  //   } else {
  //     if (groupIndex) {
  //       this.petTable.groupedRows[groupIndex].value.splice(rowIndex, 1);
  //     } else {
  //       groupRows.splice(groupRowIndex + 1, 1);
  //     }
  //   }
  //   this.checkIfValid();
  // }

  // clearService(groupRows) {
  //   delete groupRows[0].selectedService;
  // }


  // setStep(index: number) {
  //   this.step = index;
  // }

  // nextStep() {
  //   this.step++;
  // }

  // prevStep() {
  //   this.step--;
  // }

}

