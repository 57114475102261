import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-merge-pet-success',
  templateUrl: './merge-pet-success.component.html',
  styleUrls: ['./merge-pet-success.component.scss']
})
export class MergePetSuccessComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MergePetSuccessComponent>,
  ) { }

  ngOnInit(): void {
  }

  redirect() {
    this.dialogRef.close(this.data);
  }

}
