<div *ngIf="boardingBooking" class="position-relative">



  <!-- <div class="quick-actions">
    <button mat-button [matMenuTriggerFor]="menu" class="mat-text-secondary more-btn">
      <mat-icon>more_vert</mat-icon> <span class="more-text">{{'MORE' | translate}}</span>
    </button>

    <mat-menu #menu="matMenu" class="quick-actions-menu">
      <button mat-menu-item (click)="edit()">
        <mat-icon>edit</mat-icon>
        {{'EDIT' | translate}}
      </button>
      <button *ngIf="boardingBooking.status !== 'Cancelled'" mat-menu-item (click)="cancelAppointment()">
        <mat-icon>cancel</mat-icon>
        {{'CANCEL' | translate}}
      </button>
      <button mat-menu-item (click)="deleteAppointment()">
        <mat-icon>delete_forever</mat-icon>
        {{'DELETE' | translate}}
      </button>

    </mat-menu>
  </div> -->

  <mat-tab-group class="view-appointment-expanded" #matTabGroup (selectedIndexChange)="tabChange($event)">

    <mat-tab #tab label="Booking" class="mdc-p-3 ">
      <div *ngIf="boardingBooking" fxLayout="row wrap" fxLayoutAlign=" center" class="h-auto mdc-mb-2 mdc-px-3">
        <div fxLayout="row wrap" fxLayoutAlign=" center">

          <span class="icon-24 mat-text-secondary">
            <mat-icon style="vertical-align: top">event</mat-icon>
          </span>

          <span class="date-time padding-left-half" fxLayout="column">
            <span>
              <strong>
                <span *ngIf="boardingBooking?.bookingType === 'BOARDING'">{{totalNights}} {{'NIGHT' | translate}}<span
                    *ngIf="totalNights > 1">s</span>/</span>
                {{totalDays}} Day<span *ngIf="totalDays > 1">s</span></strong>
              <span *ngIf="boardingBooking.bookingType === 'BOARDING'"> {{'BOARDING' | translate}}</span>
              <span *ngIf="boardingBooking.bookingType === 'DAY_CARE'"> {{'DAY_CARE' | translate}}</span> {{'SELECTED' |
              translate}}
            </span>
            <small class=" mat-text-secondary fs-12">
              {{boardingBooking.startDate | localizedDate: 'mediumDate'}}
              {{startTime | localizedDate:'h:mm a'}} {{'TO' | translate}}
              {{boardingBooking.endDate | localizedDate: 'mediumDate'}}
              {{endTime | localizedDate:'h:mm a'}}
            </small>


            <!-- <span>{{boardingBooking.startDate | localizedDate}}</span>
            <small class="mat-text-secondary fs-12">
              {{startTime | localizedDate:'h:mm a'}} - {{endTime | localizedDate:'h:mm a'}}
            </small> -->
          </span>
        </div>
        <span class="example-spacer"></span>
        <div fxFlex="nogrow" fxFlex.gt-sm="30" class="mr-1 cancellation-reason"
          *ngIf="boardingBooking?.cancellationReason">
          {{'REASON' | translate}}: {{boardingBooking.cancellationReason}}
        </div>
        <app-view-boarding-workflow-panel fxFlex="nogrow" fxFlex.gt-sm="nogrow" fxLayoutAlign=" center"
          [contextIdDto]="contextIdDto" [(boardingBooking)]="boardingBooking">
        </app-view-boarding-workflow-panel>
        <!-- <div fxFlex="nogrow">
          <button mat-button color="accent" (click)="edit()">
            <mat-icon>edit</mat-icon>
            {{'EDIT' | translate}}
          </button>
        </div> -->
      </div>

      <mat-card appearance="outlined"
        *ngIf="boardingBooking.paymentConfig?.paymentMode === 'PAYMENT_PLAN' && plan && plan.id && plan.status === 'PENDING_PAYMENT'"
        class="mdc-px-3 warning">
        <h6>
          <div><b>{{'PAYMENT_PENDING' | translate}}</b></div>
        </h6>
        <div>{{plan.planName}}: {{plan.cost | currency:plan.currency:'symbol'}}</div>
      </mat-card>

      <div class="mdc-px-3 ">
        <mat-card appearance="outlined" class="mx-0 mdc-mb-1" *ngIf="showConfirmationDialog">
          <mat-card-header class="close-confirm">
            <mat-icon (click)="closeConfirmDialog()">cancel</mat-icon>
          </mat-card-header>
          <mat-card-content>
            <div fxLayout="row" fxLayoutAlign=" center">
              <div fxFlex *ngIf="!showReviewUrlConfig">
                <h6 *ngIf="!showCancellationReason">{{'NICE_PROGRESS' | translate}}</h6>
                <h6 *ngIf="showCancellationReason">{{'CONFIRMATION' | translate}}</h6>
                <p *ngIf="!showCancellationReason" class="small mat-text-secondary mb-0">
                  {{'CHANGED_STATUS_CONFIRM' | translate}}
                </p>
                <p *ngIf="showCancellationReason" class="small mat-text-secondary mb-0">
                  {{'CANCEL_APPOINTMENT_CONFIRM_MESSAGE' | translate}}
                </p>
                <p class="small mat-text-secondary mb-0">
                  {{'CHANGE_OR_MODIFY_TEMPLATE' | translate}} <a class="text-underline"
                    (click)="openEditTemplate()">{{'CLICK_HERE' | translate}}</a>.
                </p>

              </div>
              <div *ngIf="showContacts && contactList?.length" fxFlex>
                <mat-form-field appearance="outline" class="w-100 pr-1 vm">
                  <mat-label>{{'CHOOSE_CONTACT' | translate}}</mat-label>
                  <mat-select [(ngModel)]="contactToShare">
                    <ng-container *ngFor="let contact of contactList">
                      <mat-option *ngIf="contact?.mobilePhoneNumberObj?.internationalNumber"
                        [value]="contact.mobilePhoneNumberObj">
                        {{contact.mobilePhoneNumberObj?.internationalNumber}}
                        ({{contact.firstName}} {{contact.lastName}} - {{'MOBILE_NO' | translate}})
                      </mat-option>
                      <mat-option *ngIf="contact?.phoneNumberObj?.internationalNumber" [value]="contact.phoneNumberObj">
                        {{contact.phoneNumberObj.internationalNumber}}
                        ({{contact.firstName}} {{contact.lastName}} - {{'PHONE_NO' | translate}})
                      </mat-option>
                      <mat-option *ngIf="contact?.workPhoneNumberObj?.internationalNumber"
                        [value]="contact.workPhoneNumberObj">
                        {{contact.workPhoneNumberObj.internationalNumber}}
                        ({{contact.firstName}} {{contact.lastName}} - {{'WORK_PHONE_NO' | translate}})
                      </mat-option>
                      <!-- <mat-option *ngIf="contact?.whatsAppNumberObj?.internationalNumber"
                        [value]="contact.whatsAppNumberObj">
                        {{contact.whatsAppNumberObj.internationalNumber}}
                        ({{contact.firstName}} {{contact.lastName}} - Whats App No)
                      </mat-option> -->
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngIf="showCancellationReason" fxFlex>
                <mat-form-field appearance="outline" class="w-100 pr-1 vm">
                  <mat-label>{{'CANCELLATION_REASON' | translate}}</mat-label>
                  <textarea matInput [(ngModel)]="cancellationReason" [ngModelOptions]="{standalone: true}" rows="2"
                    required></textarea>
                </mat-form-field>
              </div>
              <div *ngIf="showAskForReview" fxFlex>
                <mat-slide-toggle *ngIf="!bypassReviewBooster" [(ngModel)]="askForReview"
                  (ngModelChange)="askForReviewChanged($event)" class="w-100 pr-1 vm" color="accent"
                  [checked]="askForReview">
                  {{'ASK_FOR_REVIEW' | translate}}
                </mat-slide-toggle>
                <mat-checkbox [(ngModel)]="bypassReviewBooster" name="bypassReviewBooster"
                  (ngModelChange)="updateBypassReviewBooster($event)">
                  {{'NEVER_ASK_FOR_REVIEW' | translate}}
                </mat-checkbox>
              </div>
              <div *ngIf="showReviewUrlConfig && askForReview" fxFlex="80" class="ml-1 mr-1">
                {{'SET_ONE' | translate}}
                <div fxLayout="row" fxLayoutGap="5px">
                  <mat-form-field fxFlex appearance="outline" class="w-100">
                    <mat-label>{{'FACEBOOK_REVIEW_URL' | translate}}</mat-label>
                    <input type="text" matInput placeholder="{{'ENTER_FACEBOOK_REVIEW_URL' | translate}}"
                      [(ngModel)]="appointmentConfigHelperService.appointmentConfig.facebookReviewUrl"
                      (ngModelChange)="appointmentConfigHelperService.updateAppointmentConfig(contextIdDto, appointmentConfigHelperService.appointmentConfig)"
                      name="facebookReviewUrl">
                  </mat-form-field>
                  <mat-form-field fxFlex appearance="outline" class="w-100">
                    <mat-label>{{'GOOGLE_REVIEW_URL' | translate}}</mat-label>
                    <input type="text" matInput placeholder="{{'ENTER_GOOGLE_REVIEW_URL' | translate}}"
                      [(ngModel)]="appointmentConfigHelperService.appointmentConfig.googleReviewUrl"
                      (ngModelChange)="appointmentConfigHelperService.updateAppointmentConfig(contextIdDto, appointmentConfigHelperService.appointmentConfig)"
                      name="googleReviewUrl">
                  </mat-form-field>
                </div>
              </div>

              <div fxFlex="nogrow">
                <button mat-raised-button color="accent" (click)="confirmStateChange()">{{'CONFIRM' |
                  translate}}</button>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <div *ngIf="boardingBooking && tab.isActive">
          <app-detailed-pet-boarding-view #detailedPetViewComponent [boardingBooking]="boardingBooking"
            [contextIdDto]="contextIdDto" [petStayPreference]="petStayPreference"
            (viewHistoryEvent)="viewHistory($event)" (gotoPetEvent)="gotoPet($event)"
            (gotoCustomerEvent)="gotoCustomer($event)" (rebookEvent)="rebookEvent()" (closeEvent)="cancel()"
            (customerUpdated)="customerUpdated($event)" (petUpdated)="petUpdated($event)">
          </app-detailed-pet-boarding-view>
        </div>

      </div>
    </mat-tab>

    <!-- <mat-tab #tab1 *ngIf="contextService.appType === 'GROOM_SCHOOL' && contextIdDto" label="Students">
      <app-view-appointment-students #viewStudents *ngIf="boardingBooking && tab1.isActive" [contextIdDto]="contextIdDto"
        [appointment]="boardingBooking">
      </app-view-appointment-students>
    </mat-tab> -->

    <mat-tab #tab2 label="{{'HISTORY' | translate}}">
      <div class="mdc-px-3 ">
        <div fxLayout="row" fxLayoutAlign="center center" class="">
          <mat-button-toggle-group [(ngModel)]="historyFor" name="historyFor" aria-label="History For"
            class="button-group-payments">
            <mat-button-toggle [value]="'bookings'">{{'BOOKINGS' | translate}}</mat-button-toggle>
            <mat-button-toggle [value]="'food'">{{'FOOD_CYCLE' | translate}}</mat-button-toggle>
            <mat-button-toggle [value]="'medication'">{{'MEDICATION' | translate}}</mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div *ngIf="historyFor === 'bookings'">
          <app-view-customer-boardings #viewCustomerBoardingsComponent [contextIdDto]="contextIdDto"
            [customerId]="boardingBooking.customerId" [isViewMode]="true">
          </app-view-customer-boardings>
        </div>

        <div *ngIf="historyFor === 'food'">
          <app-food-history [contextIdDto]="contextIdDto" [boardingBooking]="boardingBooking"></app-food-history>
        </div>

        <div *ngIf="historyFor === 'medication'">
          <app-medication-history [contextIdDto]="contextIdDto"
            [boardingBooking]="boardingBooking"></app-medication-history>


        </div>

      </div>
    </mat-tab>

    <mat-tab #tab3 label="{{'MESSAGES' | translate}}">
      <div fxLayout="row" fxLayoutAlign="center center" class="mdc-px-3">
        <mat-button-toggle-group [(ngModel)]="messageCategory" name="messageCategory" aria-label="Message Categories"
          class="button-group-payments">
          <mat-button-toggle value="messages">{{'MESSAGES' | translate}}</mat-button-toggle>
          <mat-button-toggle value="reminders">{{'REMINDERS' | translate}}</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <app-view-boarding-messages-v2 *ngIf="boardingBooking && messageCategory === 'messages' && tab3.isActive"
        [contextIdDto]="contextIdDto" [boardingBooking]="boardingBooking">
      </app-view-boarding-messages-v2>
      <app-view-boarding-reminders-v2 *ngIf="boardingBooking && messageCategory === 'reminders' && tab3.isActive"
        [contextIdDto]="contextIdDto" [boardingBooking]="boardingBooking">
      </app-view-boarding-reminders-v2>
    </mat-tab>

    <mat-tab #tab4 label="{{'CONTRACTS' | translate}}"
      *ngIf="featuresHelperService.isFeatureAllowed(featureType.Contracts)">
      <app-view-appointment-contracts *ngIf="boardingBooking && tab4.isActive" [contextIdDto]="contextIdDto"
        [customerId]="boardingBooking.customerId">
      </app-view-appointment-contracts>
    </mat-tab>

    <mat-tab #tab5 label="{{'PAYMENT' | translate}}">
      <ng-container *ngIf="boardingBooking?.invoiceId">
        <div fxLayout="row" fxLayoutAlign="center center" class="mdc-px-3 mdc-mb-1">
          <mat-button-toggle-group [(ngModel)]="paymentCategory" name="paymentCategory" aria-label="Payment Categories"
            class="button-group-payments">
            <mat-button-toggle value="amount">{{'AMOUNT' | translate}}</mat-button-toggle>
            <mat-button-toggle value="deposits">{{'DEPOSITS' | translate}}</mat-button-toggle>
            <mat-button-toggle value="payments">{{'PAYMENTS' | translate}}</mat-button-toggle>
            <mat-button-toggle value="tips">{{'TIPS' | translate}}</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <app-view-customer-invoice *ngIf="boardingBooking  && paymentCategory === 'amount' && tab5.isActive"
          [boardingBooking]="boardingBooking" [contextIdDto]="contextIdDto" [invoiceId]="boardingBooking.invoiceId?.id"
          (invoiceUpdated)="invoice = $event" (editAppointment)="edit()">
        </app-view-customer-invoice>
        <app-view-deposit-requests *ngIf="boardingBooking && paymentCategory === 'deposits' && tab5.isActive"
          [contextIdDto]="contextIdDto" [invoiceId]="boardingBooking.invoiceId?.id"
          [requestForPaymentOwnerId]="boardingBooking.customerId" [requestForPaymentOwnerType]="'CUSTOMER'">
        </app-view-deposit-requests>
        <app-view-customer-payments *ngIf="boardingBooking  && paymentCategory === 'payments' && tab5.isActive"
          [contextIdDto]="contextIdDto" (invoiceUpdated)="invoice = $event" [visible]="true"
          [invoiceId]="boardingBooking.invoiceId?.id">
        </app-view-customer-payments>
        <app-view-customer-tips *ngIf="boardingBooking  && paymentCategory === 'tips' && tab5.isActive"
          [contextIdDto]="contextIdDto" (invoiceUpdated)="invoice = $event" [visible]="true"
          [invoiceId]="boardingBooking.invoiceId?.id">
        </app-view-customer-tips>
      </ng-container>
      <ng-container *ngIf="!boardingBooking?.invoiceId">
        <div class="flexbox-parent">
          <div fxLayout="row" fxLayoutAlign="center center" style="height: 30vh;">
            <div style="text-align:center;">
              <div class="avatar avatar-100 circle border-3 mb-2 d-inline-block">
                <mat-icon class="h1 h-auto w-auto">receipt</mat-icon>
              </div>
              <br>
              <h6>{{"NO_INVOICE_CREATED" | translate}}</h6>
              <br>
              <button mat-button color="accent" (click)="generateInvoice()"><mat-icon>receipt</mat-icon>
                {{'GENERATE_INVOICE' | translate}}
              </button>
            </div>
          </div>
        </div>

      </ng-container>
    </mat-tab>

    <mat-tab #tab6 label="{{'CHECK_IN' | translate}}"
      *ngIf="featuresHelperService.isFeatureAllowed(featureType.CheckinForms)">
      <app-view-checkin-forms *ngIf="boardingBooking && tab6.isActive" [contextIdDto]="contextIdDto"
        [customerId]="boardingBooking.customerId" [appointmentId]="boardingBooking.id">
      </app-view-checkin-forms>
    </mat-tab>

    <!-- <mat-tab #tab7 label="Photos">
      <app-view-appointment-photos *ngIf="boardingBooking && tab7.isActive" [contextIdDto]="contextIdDto"
        [appointment]="boardingBooking" (appointmentUpdated)="appointmentUpdated($event)">
      </app-view-appointment-photos>
    </mat-tab> -->
  </mat-tab-group>


  <div fxLayout="row" fxLayoutGap="6px" class="mdc-px-3">
    <!-- <div fxLayout="row" fxLayoutGap="6px" *ngIf="deviceService.isMobile() || deviceService.isTablet()" class="mdc-px-3"> -->
    <div fxFlex>
      <button mat-stroked-button color="accent" class="mdc-mb-1 text-center mdc-p-2 w-100 h-auto mat-mdc-card-outlined"
        (click)="cancel()">
        <div>
          <mat-icon>arrow_back</mat-icon><br>
          <p class="fs-12">{{'Back' | translate: {Default: 'Back'} }}</p>
        </div>
      </button>
    </div>
    <div fxFlex>
      <button mat-stroked-button color="accent" class="mdc-mb-1 text-center mdc-p-2 w-100 h-auto mat-mdc-card-outlined"
        (click)="edit()">
        <div>
          <mat-icon>edit</mat-icon><br>
          <p class="fs-12">{{'EDIT' | translate: {Default: 'Edit'} }}</p>
        </div>
      </button>
    </div>

    <div fxFlex>
      <button mat-stroked-button color="accent" class="mdc-mb-1 text-center mdc-p-2 w-100 h-auto mat-mdc-card-outlined"
        (click)="rebookEvent()">
        <div>
          <mat-icon>repeat</mat-icon><br>
          <p class="fs-12">{{'REBOOK' | translate: {Default: 'Re-book'} }}</p>
        </div>
      </button>
    </div>

    <div fxFlex *ngIf="boardingBooking.status !== 'Cancelled'">
      <button mat-stroked-button color="accent" class="mdc-mb-1 text-center mdc-p-2 w-100 h-auto mat-mdc-card-outlined"
        (click)="cancelAppointment()">
        <div>
          <mat-icon>cancel</mat-icon><br>
          <p class="fs-12">{{'CANCEL' | translate: {Default: 'Cancel'} }}</p>
        </div>
      </button>
    </div>
    <div fxFlex>
      <button mat-stroked-button color="accent" class="mdc-mb-1 text-center mdc-p-2 w-100 h-auto mat-mdc-card-outlined"
        (click)="deleteAppointment()">
        <div>
          <mat-icon>delete_forever</mat-icon><br>
          <p class="fs-12">{{'DELETE' | translate: {Default: 'Delete'} }}</p>
        </div>
      </button>
    </div>

  </div>

</div>

<!-- <div fxLayout="row" class="mdc-px-3 ">
  <div fxFlex></div>

  <button mat-raised-button class="mat-text-secondary" *ngIf="deviceService.isMobile() || deviceService.isTablet()"
    type="button" (click)="cancel()">
    Close
  </button>
</div> -->
<!-- </mat-card-content>
</mat-card> -->