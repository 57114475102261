<div [formGroup]="form" *ngIf="fieldDefinition">
  <mat-form-field appearance="outline" *ngIf="fieldDefinition.elementType.toString() === 'INPUT'" style="width: 100%;">
    <mat-label>{{fieldDefinition.label | translate}}</mat-label>
    <input matInput [autofocus]="elementInstanceDto.elementInstanceViewDto.focus"
           placeholder="{{getPlaceholder() | translate}}"
           [required]="fieldDefinition.required"
           [id]="elementInstanceDto.instanceId"
           [formControlName]="elementInstanceDto.instanceId"
    >
    <mat-error *ngIf="form.controls[elementInstanceDto.instanceId].hasError('required')">
      {{fieldDefinition.label + ' is' | translate}} <strong>{{'required' | translate}}</strong>
    </mat-error>
  </mat-form-field>


  <app-signature [form]="form" [elementInstanceDto]="elementInstanceDto" *ngIf="fieldDefinition.elementType.toString() === 'SIGNATURE'" style="width: 100%">


  </app-signature>

  <mat-form-field style="width: 100%" appearance="outline" style="width: 100%;"
                  *ngIf="fieldDefinition.elementType.toString() === 'INTEGER_VALUE_DEFINITION'">
    <mat-label>{{fieldDefinition.label | translate}}</mat-label>
    <input matInput
           placeholder="{{getPlaceholder() | translate}}"
           [id]="elementInstanceDto.instanceId"
           [required]="fieldDefinition.required"
           [formControlName]="elementInstanceDto.instanceId">

    <mat-error *ngIf="form.controls[elementInstanceDto.instanceId].hasError('required')">
      {{fieldDefinition.label + ' is' | translate}} <strong>{{'required' | translate}}</strong>
    </mat-error>

  </mat-form-field>


  <mat-form-field appearance="outline" style="width: 100%;"
                  *ngIf="fieldDefinition.elementType.toString() === 'TEXT_AREA' && elementInstanceDto.elementInstanceViewDto &&
                  elementInstanceDto.elementInstanceViewDto.displayWidgetType !== 'INLINE_EDITOR'">
    <mat-label *ngIf="fieldDefinition.label === 'Pet Notes'">To Edit Pet Notes please navigate to the main profile of the pet or use the appointments notes</mat-label>
    <mat-label  *ngIf="fieldDefinition.label !== 'Pet Notes'">{{fieldDefinition.label | translate}}</mat-label>
    <textarea matInput
              [id]="elementInstanceDto.instanceId"
              [required]="fieldDefinition.required"
              [disabled]="fieldDefinition.label === 'Pet Notes'"
              [readonly]="fieldDefinition.label === 'Pet Notes'"
              rows="6"
              [formControlName]="elementInstanceDto.instanceId"
              placeholder="{{getPlaceholder() | translate}}">
        </textarea>
    <mat-error *ngIf="form.controls[elementInstanceDto.instanceId].hasError('required')">
      {{fieldDefinition.label + ' is' | translate}} <strong>{{'required' | translate}}</strong>
    </mat-error>
  </mat-form-field>

  <app-flo-editor
    *ngIf="fieldDefinition.elementType.toString() === 'TEXT_AREA' && elementInstanceDto.elementInstanceViewDto &&
    elementInstanceDto.elementInstanceViewDto.displayWidgetType === 'INLINE_EDITOR'"
    [id]="elementInstanceDto.instanceId"
    [text]="form.controls[this.elementInstanceDto.instanceId].value"
    [required]="fieldDefinition.required"
    [editorId]="elementInstanceDto.instanceId"
    (textUpdated)="onChange($event)">
  </app-flo-editor>

</div>
