import { Injectable } from '@angular/core';
import { IBreadcrumb } from './crumb';
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router } from '@angular/router';
import { filter } from 'rxjs/operators';



@Injectable()
export class BreadcrumbsService {
  public breadcrumbs: IBreadcrumb[] = [];

  created = new Date();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {

    // console.log('calling clear all from constructor of breadcrumbs service');
    this.clearAll();

    // subscribe to the NavigationEnd event

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const root: ActivatedRoute = this.activatedRoute.root;

      const newCrumbs = this.getBreadcrumbs(root);
      newCrumbs.forEach(newCrumb => {
        this.add(newCrumb);
      });
    });
    // console.log('leaving breadcrumbs service contractor');

  }

  output() {
    let str: string;
    this.breadcrumbs.forEach(crumb => {
      str += crumb.label + ' ';
    });
    return 'Service has [' + this.breadcrumbs.length + '] breadcrumbs ' + str;
  }

  /**
   *
   *
   * Returns array of IBreadcrumb objects that represent the breadcrumb
   *
   *
   *
   * @class DetailComponent
   * @method getBreadcrumbs
   * @param route
   * @param url
   * @param breadcrumbs
   */
  private getBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadcrumb[] = []): IBreadcrumb[] {
    const ROUTE_DATA_BREADCRUMB = 'breadcrumb';
    const ROUTE_DATA_TOP_LEVEL = 'topLevel';

    // console.log('getting breadcrumbs for ' + url);

    // get the child routes
    const children: ActivatedRoute[] = route.children;

    // return if there are no more children
    if (children.length === 0) {
      // console.log('no more children so returning from url ' + url);
      return breadcrumbs;
    }

    // iterate over each children
    for (const child of children) {
      // verify primary route
      if (child.outlet !== PRIMARY_OUTLET) {
        // console.log('ignoring child as not primary outlet');

        continue;
      }

      // verify the custom data property "breadcrumb" is specified on the route
      if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
        // console.log('no data attribute called breadcrumb exists on route [' + url + '] so calling get breadcrumbs again');
        return this.getBreadcrumbs(child, url, breadcrumbs);
      } else {
        if (child.snapshot.data.hasOwnProperty(ROUTE_DATA_TOP_LEVEL)) {
          console.log('top level, resetting calling clearAll');
          this.clearAll();
        } else {
          // console.log('not top level');
        }
      }

      // get the route's URL segment
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');

      // append route URL to URL
      url += `/${routeURL}`;
      if (route.parent !== null) {
        url = '/' + route.parent.snapshot.url + `${url}`;
      }
      // add breadcrumb
      const breadcrumb: IBreadcrumb = {
        label: this.getLabel(child),
        params: child.snapshot.params,
        url
      };
      // console.log('ADDING BREADCRUMB!!! ' + breadcrumb.label);
      breadcrumbs.push(breadcrumb);
      // console.log('now there are [' + breadcrumbs.length + '] breadcrumbs');

      // recursive
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }
  }
  private getLabel(child: ActivatedRoute) {
    const ROUTE_DATA_BREADCRUMB = 'breadcrumb';
    const label = child.snapshot.data[ROUTE_DATA_BREADCRUMB];
    return label;
  }

  public list(): IBreadcrumb[] {
    return this.breadcrumbs;
  }

  public add(breadcrumb: IBreadcrumb): void {
    if (breadcrumb.label === 'View' || breadcrumb.label === 'Edit' || breadcrumb.label === 'Create') {
      // We could ask menu service based on params.   ie. get menu item with params 'blah'
      console.log('found a View, Edit or Create breadcrumb');
    }
    if (!this.existsAlready(breadcrumb.label)) {
      this.breadcrumbs.push(breadcrumb);
    }
  }

  existsAlready(label: string) {
    let exists = false;
    this.breadcrumbs.forEach(
      breadcrumb => {
        if (breadcrumb.label === label) {
          exists = true;
        }
      }
    );
    return exists;
  }

  public addAll(breadcrumb: IBreadcrumb[]): void {
    breadcrumb.forEach(crumb => {
      this.breadcrumbs.push(crumb);
    });
  }

  go(breadcrumb: IBreadcrumb) {
    console.log('going to ' + breadcrumb.label + ' uri: ' + breadcrumb.url);
    this.router.navigateByUrl(breadcrumb.url);
  }

  clearAll() {
    this.breadcrumbs = [];
  }
}

