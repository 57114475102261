import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {HtmlSelectElementHolder} from '@savvy/entity-instance-composite';

@Component({
    selector: 'app-html-select-element',
    templateUrl: 'htmlSelectElement.component.html'
})
export class HtmlSelectElementComponent implements OnInit {

    @Input() htmlSelectElement: HtmlSelectElementHolder;
    @Input() formGroup: UntypedFormGroup;

  ngOnInit(): void {

  }
}

