<mat-card appearance="outlined" class="appt-panel panel new-card" *ngIf="appointmentPanelData">

  <mat-card-content class="entry-list" fxLayout="column">
    <div class="appt-title" fxFlex="100" fxLayout="row" fxLayout.lt-md="column">
      <div fxFlex="53" fxLayout="column">
        <div fxFlex="50" fxLayout="column">
          <p class="mat-text-muted mt-xs mb-xs">
           {{appointmentPanelData.header.left.label}}
          </p>
        </div>
        <div fxFlex="50" fxLayout="column">
          <p class="mt-xs mb-xs mat-card-title">
            {{appointmentPanelData.header.left.value}}
          </p>
        </div>
      </div>

      <div fxFlex="47" fxLayout="column">
        <div fxFlex="50" fxLayout="column">
          <div fxFlex="100">
            <p class="mat-text-muted mt-xs mb-xs">
              {{appointmentPanelData.header.right.label}}
            </p>
          </div>
        </div>
        <div fxFlex="100">
          <p class="mt-xs mb-xs mat-card-title">
            {{appointmentPanelData.header.right.value}}
          </p>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column" *ngFor="let labelValueRowDto of appointmentPanelData.labelValueRowDtos">
      <div fxFlex="53" fxLayout="column">
        <div fxFlex="100">
          <div fxFlex="40">
            <p class="mat-text-muted mt-xs mb-xs">
              {{labelValueRowDto.left.label}}
            </p>
          </div>
          <div fxFlex="60">
            <p class="mt-xs mb-xs">
              {{labelValueRowDto.left.value}}
            </p>
          </div>
        </div>
      </div>
      <div fxFlex="47" fxLayout="column">
        <div fxFlex="100">
          <div fxFlex="40">
            <p class="mat-text-muted mt-xs mb-xs">
              {{labelValueRowDto.right.label}}
            </p>
          </div>
          <div fxFlex="60">
            <p class="mt-xs mb-xs">
              {{labelValueRowDto.right.value}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
