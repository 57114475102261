import { Component, Input } from '@angular/core';
import { ListEntityImagesDto, ContextIdDto } from '@savvy/view-definition';
import { ConfService } from '../config/conf.service';

@Component({
  selector: 'app-image-grid-element',
  templateUrl: './imageGrid.component.html',
  styleUrls: ['./imageGrid.component.scss']
})
export class ImageGridElementComponent {

  @Input() listEntityImagesDto: ListEntityImagesDto;
  @Input() contextIdDto: ContextIdDto;
  images: any[] = [];
  num = 1;


  constructor() {
    /*for (this.num; this.num <= 20; this.num += 1) {
     this.addProducts(this.num);
    }*/

    for (this.num; this.num <= 21; this.num += 1) {
      this.images.push(this.num);
    }
  }

  getImageUrl(reference: string) {
    console.log('inside get image url, reference is ' + reference);

    return ConfService.apiUrl() + '/rest/storage?contextId=' + this.contextIdDto.contextId +
      '&contextIdType=' + this.contextIdDto.contextIdType +
      '&id=' + (reference ? encodeURIComponent(reference) : reference);
  }

  isOver(): boolean {
    return window.matchMedia(`(max-width: 960px)`).matches;
  }

  isMac(): boolean {
    let bool = false;
    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
      bool = true;
    }
    return bool;
  }
}
