import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { ActivatedRoute, Router } from '@angular/router';
import { NavChangeService } from '../element/NavChangeService';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PhoneNumberHelperService } from '../shared/services/phone-number-helper.service';
import {
  CreateInvoice,
  Invoice,
  InvoiceOwnerId,
  InvoiceProductRow,
  InvoiceService,
  LinkId,
  UpdateInvoice
} from '@savvy/invoice';
import { CreateEmptyInvoice, InvoicecompService } from '@savvy/invoice';
import { Product, ProductService } from '@savvy/products';
import { ContextIdDto } from '@savvy/quickbooks';
import { CustomerId } from '@savvy/quote';
import { LinkedInstanceDto } from '@savvy/invoice';
import { UserCurrencyService } from '../shared/services/userCurrency.service';
import { LanguageService } from 'src/app/services/language.service';
import { ProductsHelperService } from "../shared/services/products-helper.service";
import { ShopConfig, ShopService } from '@savvy/shop';

@Component({
  selector: 'app-add-customer-invoice',
  templateUrl: './addCustomerInvoice.component.html'
})
export class AddCustomerInvoiceComponent implements OnInit, OnDestroy {
  @Input() customerId: string; // Optional
  @Input() contextIdDto: ContextIdDto;

  invoice: Invoice = null;
  products: Array<Product>;

  linkedId: string;
  linkedEdId: string = null;
  linkedIdType: string;

  orderId: string; // Optional

  currencyCode: string;

  destroy$ = new Subject();

  shopConfig: ShopConfig;

  private sub: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private invoiceService: InvoiceService,
    private invoicecompService: InvoicecompService,
    private navChange: NavChangeService,
    private translateService: TranslateService,
    private productService: ProductService,
    private adapter: DateAdapter<any>,
    private notify: FloSnackbarComponent,
    private sharedService: PhoneNumberHelperService,
    private userCurrencyService: UserCurrencyService,
    private languageService: LanguageService,
    private productsHelperService: ProductsHelperService,
    private shopService: ShopService
  ) {
    this.adapter.setLocale(this.translateService.currentLang);
  }

  loadShopConfig() {
    this.shopService.loadOrCreateShopConfig(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
    ).subscribe(response => {
      this.shopConfig = response;
    });
  }


  ngOnInit() {
    console.log('inside addCustomerInvoice onInit');
    this.languageService.currentLocale$
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        if (res) {
          this.adapter.setLocale(res);
        }
      });
    if (this.languageService?.currentLocale) {
      this.adapter.setLocale(this.languageService?.currentLocale);
    }
    if (this.customerId && this.contextIdDto) {
      this.getCurrencyCode();
      this.loadProducts();
      this.loadShopConfig();
      // This is a bit specific
      this.createEmpty();
    } else {
      this.sub = this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
        this.contextIdDto = <ContextIdDto>{};
        this.contextIdDto.contextId = params['contextId'];
        this.contextIdDto.contextIdType = params['contextIdType'];
        this.getCurrencyCode();
        this.loadProducts();
        this.loadShopConfig();
        // This is a bit specific
        this.customerId = params['customerId'];
        this.createEmpty();
      });
    }
  }

  createEmpty() {
    const createEmptyInvoice = <CreateEmptyInvoice>{};
    createEmptyInvoice.contextIdDto = this.contextIdDto;
    createEmptyInvoice.customerId = <CustomerId>{};
    createEmptyInvoice.customerId.id = this.customerId;
    createEmptyInvoice.persist = false;
    createEmptyInvoice.created = false; // false?
    createEmptyInvoice.suppliedValueDtos = new Array();
    createEmptyInvoice.linkId = <LinkId>{};
    createEmptyInvoice.linkId.linkedIdType = 'CUSTOMER_ID';
    createEmptyInvoice.linkId.linkedId = this.customerId;
    createEmptyInvoice.linkedInstanceDto = <LinkedInstanceDto>{};
    createEmptyInvoice.linkedInstanceDto.linkedInstanceTypeDto = LinkedInstanceDto.LinkedInstanceTypeDtoEnum.Customer;
    createEmptyInvoice.linkedInstanceDto.customerId = this.customerId;
    createEmptyInvoice.invoiceOwnerId = <InvoiceOwnerId>{};
    createEmptyInvoice.invoiceOwnerId.invoiceOwnerIdType = InvoiceOwnerId.InvoiceOwnerIdTypeEnum.CustomerId;
    createEmptyInvoice.invoiceOwnerId.id = this.customerId;

    this.invoicecompService.createEmptyInvoiceV2(createEmptyInvoice).pipe(takeUntil(this.destroy$)).subscribe(response => {
      // @ts-ignore
      this.invoice = response.invoice;
    });
  }

  getCurrencyCode() {
    this.userCurrencyService.getDefaultCurrency(this.contextIdDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.currencyCode = res.org.currencyCode ? res.org.currencyCode : this.userCurrencyService.defaultCurrency;
      });
  }

  productChanged(row) {
    if (row.productNumber) {
      console.log('Product number is ' + row.productNumber);
      const productDto = this.getProduct(row.productNumber);
      if (productDto) {
        row.unitPrice = productDto.unitPrice;
        row.tax = productDto.tax;
        row.taxString = `${String(productDto.tax)}%`;
      }
    }
    this.updateInvoice();
  }

  getProduct(entityInstanceId: string): Product {
    let retProduct: Product;
    if (this.products) {
      this.products.forEach(productDto => {

        if (productDto.id === entityInstanceId) {
          console.log('found match!');
          retProduct = productDto;
          return productDto;
        } else {
          console.log('no match');
        }
      });
    }
    return retProduct;
  }

  sendInvoice() {
    console.log('TODO: slide out');
  }

  viewInvoice() {
    this.router.navigate(['/invoice/viewInvoice',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      this.invoice.id]);
  }

  deleteRow(invoiceRow: InvoiceProductRow) {

    this.invoice.invoiceProductRows.forEach((item, index) => {
      if (item === invoiceRow) {
        this.invoice.invoiceProductRows.splice(index, 1);
      }
    });
    this.updateInvoice();
  }

  createInvoiceRow() {
    console.log('creating row');
    const invoiceRow: InvoiceProductRow = {
      quantity: 1
    };
    if (!this.invoice.invoiceProductRows) {
      console.log('creating rows array');
      this.invoice.invoiceProductRows = new Array(0);
    }
    this.invoice.invoiceProductRows.push(invoiceRow);
    console.log('rows now ' + this.invoice.invoiceProductRows.length);
    this.addOrUpdateInvoice();
  }

  addOrUpdateInvoice() {
    if (this.invoice.id) {
      this.updateInvoice();
    } else {
      this.addInvoice();
    }
  }

  addInvoice() {
    const req: CreateInvoice = <CreateInvoice>{};
    req.contextIdDto = this.contextIdDto;
    this.invoice.created = true;
    req.invoice = this.invoice;

    this.invoiceService.createInvoice(req).pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.invoice = response;
      this.translateService.get('Created Invoice')
        .subscribe(val => {
          this.notify.message = val;
          this.notify.open();
        });
    });
  }

  updateInvoice() {
    const req: UpdateInvoice = <UpdateInvoice>{};
    req.contextIdDto = this.contextIdDto;
    req.invoice = this.invoice;

    this.invoiceService.updateInvoice(req).pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.invoice = response;
      this.notify.message = 'Updated Invoice';
      this.notify.open();
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private loadProducts() {
    this.productsHelperService.listActiveProducts(this.contextIdDto).then(products => {
      console.log('loaded products');
      this.products = products;
    });
  }
}
