import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ContextService} from '../context.service';
import {Router} from '@angular/router';
import {FloSnackbarComponent} from '../snackbar/floSnackbar.component';
import {WindowRef} from '../windowWrapper';
import {TranslateService} from '@ngx-translate/core';
import {ContextIdDto} from '@savvy/menu';
import {Subscription} from 'rxjs';
import {PlanDefinition, PlanDefService} from '@savvy/plan-definition';
import {PaymentSettings} from '@savvy/payment/model/models';
import {PaymentSettingsService} from '@savvy/payment';
import {EventBusService} from '../element/EventBusService';
import {Page} from '../shared/model/page';
import {Plan, PlansCompService, PlanService} from '@savvy/plan';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmationDialogComponent} from "../confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: 'app-past-due-plans',
  templateUrl: './pastDuePlans.component.html',
  styleUrls: ['pastDuePlans.component.scss']
})
export class PastDuePlansComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  @Input() contextIdDto: ContextIdDto;
  @Input() isModal: boolean = false;
  eventBus = new EventBusService();
  page = new Page();
  sortAscending = true;
  planDefinition = <PlanDefinition>{};
  plans: Array<Plan> = [];

  selected: any[] = [];

  constructor(
    private router: Router,
    private windowRef: WindowRef,
    private translate: TranslateService,
    private notify: FloSnackbarComponent,
    private dialog: MatDialog,
    private contextService: ContextService,
    private planApi: PlanService,
    private planCompApi: PlansCompService,
    private paymentSettingsApi: PaymentSettingsService) {
  }

  ngOnInit() {
    this.page.size = 10;
    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
        this.listInstances();

      }
    }));

  }

  listInstances() {
    this.planApi.loadPastDue(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType).subscribe(res => {
        this.plans = res;

    });
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  onSelect(event) {
    this.router.navigate(['/planDef/viewCustomerPlan',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      this.selected[0].id
    ]);
  }

  viewPlan(plan: Plan) {
    this.router.navigate(['/planDef/viewCustomerPlan',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      plan.id
    ]);
  }

  deletePlan(plan: Plan) {
    event.stopPropagation();
    const confirmDialog = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete',
        message: 'Are you sure you want to remove this plan?'
      },
      height: 'auto',
      width: '500px',
      panelClass: 'helpwindow'
    });
    confirmDialog.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.planCompApi.expireAndDeleteComp(this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          plan.id).subscribe(res => {
          this.notify.message = 'Successfully deleted plan';
          this.notify.open();
          this.listInstances();
        });
      }
    });

  }

}
