import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerPopupButtonDefinition, ContextIdDto } from '@savvy/view-definition';

@Component({
  selector: 'app-customer-popup',
  templateUrl: 'customerPopup.component.html'
})
export class CustomerPopupComponent {

  @Input() customerPopupButtonDefinition: CustomerPopupButtonDefinition;
  @Input() contextIdDto: ContextIdDto;
  constructor(private router: Router) {
  }
  open() {
    this.router.navigate(['/entity/viewEntity',
      //  customer id!      this.eiPopupButtonDefinition.entityDefinitionId.id,
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType]);
  }
}
