<div [formGroup]="formGroup">
  <div fxLayout="row wrap" fxLayoutAlign=" center" fxLayoutGap="5px" fxLayoutGap.gt-sm="2%">
    <div fxFlex="100" fxFlex.gt-sm="49">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>{{'INCLUDE_FLAGS' | translate}}</mat-label>
        <mat-select formControlName="includeFlags" multiple (selectionChange)="includeFlagsSelectionChange($event)">
          <mat-select-trigger>
            <mat-chip-listbox>
              <mat-chip-option *ngFor="let flag of selectedIncludeFlagDefs" [color]="flag.colour" [removable]="true" (removed)="onIncludeFlagRemovedFromSelection(flag)" selected>
                {{ flag.name }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip-option>
            </mat-chip-listbox>
          </mat-select-trigger>
          <mat-option *ngFor="let flag of includeFlagDefs" [value]="flag.id">
            {{flag.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex="100" fxFlex.gt-sm="49">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>{{'EXCLUDE_FLAGS' | translate}}</mat-label>
        <mat-select formControlName="excludeFlags" multiple (selectionChange)="excludeFlagsSelectionChange($event)">
          <mat-select-trigger>
            <mat-chip-listbox>
              <mat-chip-option *ngFor="let flag of selectedExcludeFlagDefs" [color]="flag.colour" [removable]="true" (removed)="onExcludeFlagRemovedFromSelection(flag)" selected>
                {{ flag.name }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip-option>
            </mat-chip-listbox>
          </mat-select-trigger>
          <mat-option *ngFor="let flag of excludeFlagDefs" [value]="flag.id">
            {{flag.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>