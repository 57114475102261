import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Customer, CustomerService } from "@savvy/customer";
import { EmailmessagetemplatesService, Link } from '@savvy/email-template';
import { FileValue } from "@savvy/entity-instance-composite";
import {
  ContextIdDto,
  EmailRecipient,
  MSISDN,
  MessagecompService,
  SendCompositeMessageResponse,
  SendEmailMessage,
  SendMessageResponse,
  SendSmsMessage,
  SendWhatsAppMessage,
  SmsRecipient,
} from '@savvy/messaging';
import { OrgCompositeService } from '@savvy/org';
import { OrgDto } from "@savvy/org/model/orgDto";
import { Pet } from '@savvy/pet';
import { LinkIds } from '@savvy/send-to-all';
import { LinkId } from '@savvy/view-definition';
import { AppointmentQuickLinksEditorComponent } from 'src/app/flo/editor/appointment-quick-links-editor/appointment-quick-links-editor.component';
import { EventBusService } from '../../../element/EventBusService';
import { EventType } from '../../../event/UiEvent';
import { EmailRecipientHelper, MessageRecipient } from '../../../messaging/EmailRecipientHelper';
import { FloSnackbarComponent } from '../../../snackbar/floSnackbar.component';
import { UploadFilesComponent } from "../upload-files/upload-files.component";
@Component({
  selector: 'app-overdue-groom-message-modal-v2',
  templateUrl: 'overdue-groom-message-modal.component.html'
})
export class OverdueGroomMessageModalComponent implements OnInit {

  @ViewChild(AppointmentQuickLinksEditorComponent, { static: false }) quickLinksEditorV2Component: AppointmentQuickLinksEditorComponent;
  @ViewChild('uploadFiles') uploadFilesComponent: UploadFilesComponent;

  contextIdDto: ContextIdDto;
  eventBus: EventBusService;
  showSpinner = false;
  pet: Pet;
  body: string;
  subject: string;
  emailAddresses: string;
  smsNumbers: string;
  smsSenderId: string;
  whatsAppNumbers: string;
  messageText: string;
  emailTemplateId: string;
  messageTypes = ['Email', 'SMS'];
  selectedMessageType = 'SMS';


  public form: UntypedFormGroup; // our form model
  messageRecipient: MessageRecipient;
  orgDto: OrgDto;

  savedFiles: FileValue[] = [];
  links: Link[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: MessagecompService,
    private emailRecipientHelper: EmailRecipientHelper,
    private orgCompositeService: OrgCompositeService,
    private notify: FloSnackbarComponent,
    private emailmessagetemplatesService: EmailmessagetemplatesService,
    private customerService: CustomerService,
    public dialogRef: MatDialogRef<OverdueGroomMessageModalComponent>) {
    this.contextIdDto = this.data.contextIdDto;

    this.pet = this.data.pet;
    this.eventBus = this.data.eventBus;
  }

  ngOnInit() {
    this.loadRecipient();
    this.messageText = this.pet.name + ' is due a Groom!';
    this.subject = this.pet.name + ' is due a Groom!';
    this.body = 'Please contact us to arrange an appointment';

    this.loadEmailTemplate();

    this.orgCompositeService.getCurrentOrgComp().subscribe(response => {
      this.orgDto = response;
    })
  }

  loadEmailTemplate() {
    this.emailmessagetemplatesService.getEmailMessageTemplateByType(this.contextIdDto.contextId, this.contextIdDto.contextIdType, 'overdueGroom')
      .subscribe(res => {
        console.log('loaded email message template', res);
        if (res) {
          this.onEmailTemplateChange(res);

        }
      });
  }


  bodyUpdated(text: string) {
    this.body = text;
  }

  // cancelIt() {
  //   this.eventBus.addEvent(EventType.CANCEL_SEND_MESSAGE);
  // }

  onEmailTemplateChange($event) {
    this.emailTemplateId = $event.id;
    this.body = $event.body;
    this.subject = $event.subject;
    if ($event?.attachments) {
      this.savedFiles = $event?.attachments;
    }

    if ($event?.links) {
      this.links = $event?.links;
    }
  }

  loadRecipient() {
    if (this.pet.petOwnerId) {
      this.customerService.loadCustomer(this.pet.petOwnerId, this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(res => {
        if (res) {
          this.handleCustomerMessageRecipient(res);
        }
      });
    }
  }

  messageDefined() {

    switch (this.selectedMessageType) {
      case this.messageTypes[0]: {
        if (this.body && this.body.length > 0) {
          return true;
        }
        return false;
      }
      case this.messageTypes[1]: {
        if (this.messageText && this.messageText.length > 0) {
          return true;
        }
        return false;
      }
      case this.messageTypes[2]: {
        if (this.messageText && this.messageText.length > 0) {
          return true;
        }
        return false;
      }
    }
  }
  handleCustomerMessageRecipient(customer: Customer) {
    this.messageRecipient = <MessageRecipient>{};
    this.messageRecipient.optIn = customer.optIn;
    this.messageRecipient.recipientId = customer.id;
    this.messageRecipient.recipientType = EmailRecipient.RecipientTypeEnum.Customer;
    if (customer.username) {
      this.messageRecipient.email = customer.username;
      this.emailAddresses = customer.username;
    }

    if (customer.mobilePhoneNumberObj) {

      this.smsNumbers = customer.mobilePhoneNumberObj.internationalNumber;
    } else if (customer.phoneNumberObj) {
      this.smsNumbers = customer.phoneNumberObj.internationalNumber;
    } else {
    }

    if (customer.whatsAppNumberObj) {

      this.whatsAppNumbers = customer.whatsAppNumberObj.internationalNumber;
    } else {
    }
  }


  send() {
    if (this.selectedMessageType === 'Email') {
      this.sendEmail();
    } else if (this.selectedMessageType === 'SMS') {
      this.sendSms();
    }
  }


  checkIfAllowedToSend() {
    if (this.messageRecipient) {

      if (this.selectedMessageType === 'Email') {
          return this.messageRecipient.optIn.allowEmail;
      } else if (this.selectedMessageType === 'SMS') {
          return this.messageRecipient.optIn.allowSms;
      }
    }
    return false;

  }

  handleResponse(response: SendCompositeMessageResponse) {
    if (response.sendMessageResponses && response.sendMessageResponses.length > 0) {
      response.sendMessageResponses.forEach(smr => {
        if (smr.sendMessageError) {
          console.log('smr is ' + smr.sendMessageError);
          if (smr.sendMessageError === SendMessageResponse.SendMessageErrorEnum.AuthorizationFailure) {
            this.notify.message = 'Your username or password is wrong in Email Settings';
            this.notify.open();
          } else if (smr.sendMessageError === SendMessageResponse.SendMessageErrorEnum.ConnectionFailure) {
            this.notify.message = 'We were unable to connect to your SMTP server (please check your host name and port)';
            this.notify.open();
          } else if (smr.sendMessageError === SendMessageResponse.SendMessageErrorEnum.UnknownMailServer) {
            this.notify.message = 'We were unable to connect to your SMTP server (please check your host name)';
            this.notify.open();
          } else if (smr.sendMessageError === SendMessageResponse.SendMessageErrorEnum.NoActiveSubscription) {
            this.notify.message = 'You do not have an active subscription so are not allowed to send messages';
            this.notify.open();
          } else if (smr.sendMessageError === SendMessageResponse.SendMessageErrorEnum.NoCredits) {
            this.notify.message = 'You do not have an credits remaining';
            this.notify.open();
          } else if (smr.sendMessageError === SendMessageResponse.SendMessageErrorEnum.SendingDisabled) {
            this.notify.message = 'You do not have sending enabled, please contact support who can enable it for you';
            this.notify.open();
          } else {
            this.notify.message = 'An error occurred when sending this message';
            this.notify.open();
          }
        } else {
          this.notify.message = 'Message sent!';
          this.notify.open();
          this.dialogRef.close(true);
        }
      });
    }
    if (this.eventBus) {
      this.eventBus.addEvent(EventType.SENT_MESSAGE);
    }
    this.clearFields();
  }

  private sendEmail() {
    if (this.messageRecipient?.recipientId) {
      const req = <SendEmailMessage>{};
      req.subject = this.subject;
      req.body = this.body;
      req.emailRecipients =
        this.emailRecipientHelper.toEmailRecipientsWithUser(this.emailAddresses, this.messageRecipient);
      req.contextIdDto = this.contextIdDto;
      req.linkIds = this.getLinkIds();
      req.fileAttachments = this.getAttachments();
      req.links = this.links;
      this.showSpinner = true;
      this.api.sendEmailMessage(req).subscribe(response => {
        this.showSpinner = false;
        this.handleResponse(response);
      });
    } else {
      console.log("overdue-groom-message-modal.component : Customer ID is missing while sending EMAIL");
    }
  }


  getLinkIds() {

    const linkIds = <LinkIds>{};
    linkIds.linkIds = new Array();

    const customerAsLinkId = <LinkId>{};
    customerAsLinkId.linkedIdType = 'CUSTOMER_ID';
    customerAsLinkId.linkedId = this.pet.petOwnerId;
    linkIds.linkIds.push(customerAsLinkId);

    const petAsLinkId = <LinkId>{};
    petAsLinkId.linkedIdType = 'PET_ID';
    petAsLinkId.linkedId = this.pet.id;
    linkIds.linkIds.push(petAsLinkId);
    return linkIds;
  }

  private sendSms() {
    if (this.messageRecipient?.recipientId) {
      const req = <SendSmsMessage>{};
      req.contextId = this.contextIdDto.contextId;
      req.contextIdType = this.contextIdDto.contextIdType;
      req.message = this.messageText;
      console.log('setting sender id to ' + this.smsSenderId);
      req.smsSenderId = this.smsSenderId;
      req.linkIds = this.getLinkIds();

      // To Do:  use real customer number
      req.toNumbers = [];
      if (this.smsNumbers) {
        const msisdns: string[] = this.smsNumbers.split(',');
        msisdns.forEach(msisdn => {
          const smsRecipient = <SmsRecipient>{};
          smsRecipient.recipient = <MSISDN>{};
          smsRecipient.recipient.msidn = msisdn;
          smsRecipient.recipientId = this.messageRecipient.recipientId;
          smsRecipient.recipientType = this.messageRecipient.recipientType;
          req.toNumbers.push(smsRecipient);
        });
      }
      this.showSpinner = true;
      this.api.sendSmsMessage(req).subscribe(response => {
        this.showSpinner = false;
        this.handleResponse(response);
      });
    } else {
      console.log("overdue-groom-message-modal.component : Customer ID is missing while sending SMS");
    }
  }

  private sendWhatsApp() {
    const req = <SendWhatsAppMessage>{};
    req.contextId = this.contextIdDto.contextId;
    req.contextIdType = this.contextIdDto.contextIdType;
    req.message = this.messageText;

    // To Do:  use real customer number
    req.toNumbers = [];
    if (this.whatsAppNumbers) {
      req.toNumbers = this.whatsAppNumbers.split(',');
    }
    this.showSpinner = true;
    this.api.sendWhatsAppMessage(req).subscribe(response => {
      this.showSpinner = false;
      this.handleResponse(response);
    });
  }


  private clearFields() {
    this.showSpinner = false;
    this.subject = '';
    this.body = '';
    this.messageText = '';
    this.emailTemplateId = null;
  }

  getAttachments() {
    const attachments = new Array();

    if (this.uploadFilesComponent.savedFiles) {
      this.uploadFilesComponent.savedFiles.forEach(
        savedFile => {
          attachments.push({
            fileReferenceId: savedFile?.fileReferenceId,
            mimeType: savedFile?.mimeType,
            fileName: savedFile?.fileName,
            publicBucket: false
          });
        }
      );
    }
    return attachments;
  }
}
