<mat-card appearance="outlined" class="tasks-group overdue" *ngFor="let taskSetDto of taskSetDtos; let taskSetNum = index">
    <mat-card-title>{{taskSetDto.label | translate}}</mat-card-title>
    <mat-card-content>
        <mat-list>
            <mat-list-item class="task" *ngFor="let taskDto of taskSetDto.tasks; let taskNum = index">
                <div fxLayout="row"  class="w-100 row" (click)="taskSelectionChange(taskDto)">
                    <div>
                        <div>{{taskDto.description | translate}}</div>
                        <div>{{taskDto.taskInfo?.taskLabel | translate}}</div>
                        <!-- also tag wedding, viewing or order -->
                        <div class="tag shoot">{{taskDto.name | translate}}</div>
                    </div>
                    <div class="text-xs-right text-ellipsis hide-on-open">
                        <div class="customer"></div>
                        <div class="date overdue">{{taskDto.dueDate  | localizedDate: 'mediumDate'}}</div>
                    </div>
                </div>
            </mat-list-item>
        </mat-list>
    </mat-card-content>
</mat-card>
