import { Component, Input, OnInit } from '@angular/core';
import { EventBusService } from './EventBusService';
import { ViewInvoiceDialogComponent } from './viewInvoiceDialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ViewAppointmentDataEmbedded, AppointmentData } from '@savvy/view-definition';
import { ContextIdDto } from '@savvy/quickbooks';

@Component({
  selector: 'app-view-appointment-history-embedded',
  templateUrl: './viewAppointmentHistoryEmbedded.component.html'
})
export class ViewAppointmentHistoryEmbeddedComponent implements OnInit {

  @Input() viewAppointmentHistoryDataEmbedded: ViewAppointmentDataEmbedded;
  @Input() contextIdDto: ContextIdDto;
  @Input() eventBus: EventBusService;
  @Input() viewContext: any;

  selected: any[] = [];

  constructor(public dialog: MatDialog) {
  }

  ngOnInit() {
  }

  viewAppointment(appointmentData: AppointmentData) {

    console.log('inside view appointment ', appointmentData);
    const dialogRef = this.dialog.open(ViewInvoiceDialogComponent, {
      width: '90%',
      height: '90%',
      data: {
        appointmentId: appointmentData.entityInstanceId.id,
        contextIdDto: this.contextIdDto
      }
    });

    dialogRef.afterClosed().subscribe((result) => {

    });
  }
}
