<div fxFlex fxLayout="row wrap" fxLayoutGap="5px" class="new-pet rounded-10 border">
  <!-- *ngIf="!deviceService.isMobile()" -->
  <!-- <div fxFlex="100" fxFlex.gt-xs="24" fxFlex.gt-sm="15" fxFlexAlign="center">
    <div class="my-2">
      <h6>{{'ADD_NEW_PET' | translate: {Default: 'Add New Pet'} }}</h6>
    </div>
  </div> -->
  <div fxFlex="92" fxFlex.gt-xs="53.5" fxFlex.gt-sm="24">
    <app-customer-selector *ngIf="!customerName" class="field-center" [contextIdDto]="contextIdDto" [suppportCreate]="true" (selectedCustomer)="customerChanged($event)" (addNewCustomerEvent)="addNewCustomer()">
    </app-customer-selector>
    <div class="my-2 text-right w-100" *ngIf="customerName">
      <p class="mat-text-secondary small mb-0">{{'ADDING_PET_FOR' | translate: {Default: 'Adding pet for'} }}</p>
      <h6 class="mb-0">{{customerName}}</h6>
    </div>
  </div>
  <div fxFlex="92" fxFlex.gt-xs="80" fxFlex.gt-sm="65">
    <app-add-new-pet-v2 [maxPets]="maxPets" class="field-center" #addNewPetV2Component [contextIdDto]="contextIdDto" [customerId]="customerId">
    </app-add-new-pet-v2>
  </div>

  <div fxFlex="nogrow" fxFlexAlign="center" fxLayout="row">
    <button mat-icon-button color="primary" (click)="savePet()" [disabled]="!customerId || !addNewPetV2Component?.petFormGroup?.valid">
      <mat-icon>save</mat-icon>
    </button>
    <button mat-icon-button (click)="cancel()">
      <mat-icon>cancel</mat-icon>
    </button>
  </div>
</div>

<!-- <div fxFlex *ngIf="deviceService.isMobile()" fxLayout="row wrap" fxLayoutGap="5px" class="rounded-10 border">
  <div fxFlex="100" class="new-pet">
    <div fxFlex="46" fxFlex.gt-sm="15" fxFlexAlign="center" class="pl-2">
      <p class="mat-text-secondary small mb-0">Thats great news</p>
      <h6>Add New Pet</h6>
    </div>
    <div fxFlex="50" fxFlex.gt-sm="25">
      <app-customer-selector class="field-center" [contextIdDto]="contextIdDto" [suppportCreate]="true" (selectedCustomer)="customerChanged($event)" (addNewCustomerEvent)="addNewCustomer()">
      </app-customer-selector>
    </div>
  </div>
  <div fxFlex="100" fxFlex.gt-xs="80" fxFlex.gt-sm="50" class="ml-1">
    <app-add-new-pet-v2 class="field-center" #addNewPetV2Component [contextIdDto]="contextIdDto" [customerId]="customerId">
    </app-add-new-pet-v2>
  </div>
  <div fxFlex="100" fxFlex.gt-xs="nogrow" fxFlexAlign="center" fxLayout="row" class="ml-1">
    <button mat-icon-button color="primary" (click)="savePet()" [disabled]="!customerId || !addNewPetV2Component?.petFormGroup?.valid">
      <mat-icon>save</mat-icon>
    </button>
    <button mat-icon-button (click)="cancel()">
      <mat-icon>cancel</mat-icon>
    </button>
  </div>
</div> -->