<!-- <mat-toolbar color="primary" >Export Pets</mat-toolbar> -->

<mat-card appearance="outlined">
  <mat-card-header>
    <h6>{{'EXPORTING_PETS' | translate}}</h6>
  </mat-card-header>
  <mat-card-content *ngIf="exportSettings && !exportSettings.allowAllExports">{{'WANT_TO_EXPORT_PET_DATA' | translate}}</mat-card-content>
  <mat-card-content *ngIf="exportSettings && !exportSettings.allowAllExports">{{'DONT_ALLOW_DOWNLOAD' | translate}}</mat-card-content>


  <mat-card appearance="outlined" *ngIf="exporting">
    <mat-card-header class="mdc-mb-1">
      <h5>{{'EXPORTING_PETS_DATA' | translate}}</h5>
    </mat-card-header>
    <mat-card-content>{{'PET_EXPORT_DURATION' | translate}}</mat-card-content>
    <mat-card-content>
      <mat-spinner></mat-spinner>
    </mat-card-content>
  </mat-card>

  <div *ngIf="!exporting && exportSettings && exportSettings.allowAllExports">
    <mat-card-content>
      <form #emailSettingsForm="ngForm" class="mdc-mb-2">
        <mat-radio-group aria-label="Select an option" [(ngModel)]="exportType" [ngModelOptions]="{standalone: true}">
          <mat-radio-button value="allPets" (change)="exportType = 'allCustomers'">{{'ALL_PETS' | translate}}</mat-radio-button>&nbsp;&nbsp;
          <mat-radio-button value="petsAndCustomers" (change)="exportType = 'marketingEmail'">{{'CUSTOMERS_AND_PETS' | translate}}
          </mat-radio-button>&nbsp;&nbsp;
        </mat-radio-group>
      </form>
      <button mat-raised-button color="accent" (click)="exportData()">{{'EXPORT' | translate}}</button>
    </mat-card-content>
  </div>
</mat-card>