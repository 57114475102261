import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ElementControlService } from '../ElementControlService';
import {
  ContextIdDto,
  EicompService,
  ElementInstanceDto, EntityChangeEventV2,
  IdNameTupleDto,
  LinkedCustomerDefinitionDto
} from '@savvy/entity-instance-composite';
import { ChangeListener } from '../changeListener';
import { CustomerService } from '@savvy/customer';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { AddCustomerDialogComponent } from './addCustomerDialog.component';
import { CustomerCompositeService } from '@savvy/customer';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { EventBusService } from '../EventBusService';
import { FieldValueDto } from '@savvy/view-composite';
import { LinkedIdTypeEnum, LinkId } from 'src/app/core/data-share.service';

@Component({
  selector: 'app-linked-customer-instance',
  styleUrls: ['linkedCustomerInstance.component.scss'],
  templateUrl: 'linkedCustomerInstance.component.html'
})
export class LinkedCustomerInstanceComponent implements OnInit, AfterViewInit {

  @Input() linkedCustomerDefinition: LinkedCustomerDefinitionDto;
  @Input() contextIdDto: ContextIdDto;
  @Input() elementInstanceDto: ElementInstanceDto;
  @Input() entityInstanceId: string;
  @Input() form: UntypedFormGroup;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;
  @Output() customerSelected = new EventEmitter();
  @ViewChild(MatAutocompleteTrigger, { static: false }) trigger: MatAutocompleteTrigger;

  eventBus = new EventBusService();

  isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.XSmall);

  subscription: Subscription;
  customers: IdNameTupleDto[] = new Array(0);

  customerIdAsLinkId = null;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    public dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private _sanitizer: DomSanitizer,
    private customerService: CustomerService,
    private api: CustomerCompositeService,
    private eicompService: EicompService,
    private ecs: ElementControlService) {
  }

  ngOnInit() {
    console.log('inside init of LinkedCustomerInstanceComponent');
    // setTimeout(() => {
    if (this.form.controls[this.elementInstanceDto.instanceId] && this.form.controls[this.elementInstanceDto.instanceId].value) {
      console.log('loading customer with value ' + this.form.controls[this.elementInstanceDto.instanceId].value);
      this.loadCustomerAndSetWithId(this.form.controls[this.elementInstanceDto.instanceId].value);
    }
    // });
  }

  ngAfterViewInit(): void {
    // this.cdRef.detectChanges();
  }

  displayFn(id) {
    if (!id) {
      return '';
    }
    const index = this.customers.findIndex(state => state.id === id);
    if (index === -1) {
      console.log('id is ' + id + ' index is' + index + ' this should never happen as tuples should always be there first');
      return '';
    } else {
      return this.customers[index].name;
    }
  }


  handler(event: MatAutocompleteSelectedEvent): void {
    console.log('calling set value NOW');

    this.form.controls[this.elementInstanceDto.instanceId].setValue(event.option.value);
    this.form.controls[this.elementInstanceDto.instanceId].setErrors(null);
    if (this.form.controls[this.elementInstanceDto.instanceId].value) {
      const newLinkedCustomerId = this.form.controls[this.elementInstanceDto.instanceId].value;
      console.log('new customer id is ' + newLinkedCustomerId);
      this.update(newLinkedCustomerId);
    }
  }



  isValid() {
    if (this.linkedCustomerDefinition.required) {
      console.log('here 99999');
      if (this.form.controls[this.elementInstanceDto.instanceId] && this.form.controls[this.elementInstanceDto.instanceId].value) {
        return true;
      }
    }
    return true;
  }

  loadTypeahead(searchString: string) {

    console.log('env id is ' + this.contextIdDto.contextId);

    if (searchString && searchString.length > 0) {
      this.api.searchCustomers(
        searchString,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType
      ).subscribe(response => {
        this.handleResponse(response);
      });
    }
  }



  onKey(event: any) { // without type info
    console.log('event is ' + event);
    console.log('event.target is ' + event.target);
    console.log('value is ' + event.target.value);
    console.log(this.form.controls[this.elementInstanceDto.instanceId].value);
    this.form.controls[this.elementInstanceDto.instanceId].setErrors({ doesNotSelected: true });
    this.loadTypeahead(event.target.value);
  }

  createCust() {
    console.log('passing ' + this.contextIdDto);
    const dialogRef = this.dialog.open(AddCustomerDialogComponent, {
      data: { contextIdDto: this.contextIdDto, eventBus: this.eventBus }
    });

    const smallDialogSubscription = this.isExtraSmall.subscribe(size => {
      if (size.matches) {
        dialogRef.updateSize('100%', '100%');
      } else {
        // dialogRef.updateSize('50%', '50%');
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      smallDialogSubscription.unsubscribe();

      if (result && result.customerId) {
        console.log('back from dialog customer id is ' + result.customerId);
        this.customerCreated(result.customerId);
      } else {
        console.log('back from dialog, calling create cancelled as no customer id');
        this.customerCreateCancelled();
      }
    });
  }

  customerCreated(customerId: string) {
    console.log('customer created with id ' + customerId);
    this.updateAndReload(customerId);
  }

  updateAndReload(customerId: string) {
    const fieldValue: FieldValueDto = {
      strValue: customerId,
      valueType: FieldValueDto.ValueTypeEnum.String
    };

    const req: EntityChangeEventV2 = {
      contextIdDto: this.contextIdDto,
      entityInstanceId: this.entityInstanceId,
      fieldInstanceId: {
        id: this.elementInstanceDto.instanceId
      },
      elementDefinitionId: this.linkedCustomerDefinition.elementDefinitionId,
      fieldValue
    };

    // There is no response from this, do we care?
    this.eicompService.entityInstanceChangeEventV2(req).subscribe(
      response => {
        console.log('change done, setting selectedEntity to ' + customerId);
        this.ecs.handleChangedElements(response.changedElementList, this.form);
        if (this.changeListener) {
          this.changeListener.add('change');
        }
        this.customerSelected.emit(this.entityInstanceId);
        this.loadCustomerAndSetWithId(customerId);
      }
    );
  }

  customerCreateCancelled() {
  }

  optionSelected() {
    console.log('optionSelected');
    if (this.form.controls[this.elementInstanceDto.instanceId].value) {
      const newLinkedCustomerId = this.form.controls[this.elementInstanceDto.instanceId].value;
      console.log('new customer id is ' + newLinkedCustomerId);
      this.update(newLinkedCustomerId);
    }
  }

  update(customerId: string) {
    const fieldValue: FieldValueDto = {
      strValue: customerId,
      valueType: FieldValueDto.ValueTypeEnum.String
    };

    const req: EntityChangeEventV2 = {
      contextIdDto: this.contextIdDto,
      entityInstanceId: this.entityInstanceId,
      fieldInstanceId: {
        id: this.elementInstanceDto.instanceId
      },
      elementDefinitionId: this.linkedCustomerDefinition.elementDefinitionId,
      fieldValue
    };

    // There is no response from this, do we care?
    this.eicompService.entityInstanceChangeEventV2(req).subscribe(
      response => {
        console.log('change done, setting selectedEntity to ' + customerId);
        this.ecs.handleChangedElements(response.changedElementList, this.form);
        if (this.changeListener) {
          this.changeListener.add('change');
        }
      }
    );
  }

  getPlaceholder() {
    if (this.showPlaceholder) {
      return this.linkedCustomerDefinition.placeholder;
    }
    return '';
  }

  private loadCustomerAndSetWithId(customerId: string) {
    console.log('loadCustomerAndSetWithId customerId ' + customerId);
    this.customerService.loadCustomer(
      customerId,
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType).subscribe(customer => {
        if (customer) {
          console.log('loadCustomerAndSetWithId got customer back first name is ' + customer.firstName);
          this.customers = new Array(1);
          this.customers[0] = <IdNameTupleDto>{};
          this.customers[0].id = customer.id;
          this.customers[0].name = customer.firstName + ' ' + customer.lastName;

          this.customerIdAsLinkId = <LinkId>{};
          this.customerIdAsLinkId.linkIdType = LinkedIdTypeEnum.CustomerId;
          this.customerIdAsLinkId.linkedId = customer.id;
          console.log('calling patch value on customer with customer id ' + customer.id);
          this.form.controls[this.elementInstanceDto.instanceId].patchValue(customer.id);
        }
      });
  }

  private handleResponse(response) {
    console.log('setting customers to response ' + response);
    this.customers = response;
    console.log('got customers ' + response.length);
    if (this.elementInstanceDto && this.elementInstanceDto.fieldInstanceDto.value
      && this.elementInstanceDto.fieldInstanceDto.value.strValue) {
      for (const tuple of this.customers) {
        if (tuple.id === this.elementInstanceDto.fieldInstanceDto.value.strValue) {
          console.log('setting value linked customer instance to ', tuple);
          this.form.controls[this.elementInstanceDto.instanceId].patchValue(tuple.id);
        } else {
          console.log('not setting value as strValue is ' + this.elementInstanceDto.fieldInstanceDto.value.strValue);
        }
      }
    }
    console.log('leaving loadTypeahead');
  }


}

