<div *ngIf="invoice && invoice.invoicePackageRows">
  <div *ngFor="let row of invoice.invoicePackageRows; index as idx">
    <div fxLayout="column" *ngIf="userDtos">
      <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">
        <div fxFlex="49" *ngIf="packages">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{ "Package" | translate }}</mat-label>
            <mat-select
              placeholder="{{ 'Select Package' | translate }}"
              [(ngModel)]="row.packageNumber"
              required
              (selectionChange)="itemChanged(row, false)"
              name="serviceField"
              #serviceField="ngModel"
            >
              <mat-option>
                <ngx-mat-select-search
                  [placeholderLabel]="'Search Package'"
                  ngModel
                  (ngModelChange)="filterPackages($event)"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option [value]="''">
                <button
                  mat-flat-button
                  color="accent"
                  (click)="createNewPackage(idx)"
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;Add Package
                </button>
              </mat-option>
              <mat-option
                *ngFor="let aPackage of packages | packageFilter: searchPackage"
                [value]="aPackage.id"
              >
                {{ aPackage.packageName }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="serviceField.hasError('required')">
              {{ "Package is" | translate }}
              <strong>{{ "required" | translate }}</strong>
            </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="49">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ "Price" | translate }}</mat-label>
            <input
              matInput
              type="text"
              appNumberOnly
              [decimalPoints]="2"
              placeholder="Price"
              name="unitPrice"
              #unitPrice="ngModel"
              [(ngModel)]="row.unitPrice"
              (ngModelChange)="priceChanged(row, $event)"
              id="unitPrice"
              required
            />
            <mat-error *ngIf="unitPrice.hasError('required')">
              {{ "Price is" | translate }}
              <strong>{{ "required" | translate }}</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>{{ "Staff" | translate }}</mat-label>
          <mat-select
            placeholder="{{ 'Select Staff' | translate }}"
            [compareWith]="compareUserFn"
            [(ngModel)]="row.serviceUser"
            [ngModelOptions]="{ standalone: true }"
            required
            (selectionChange)="serviceUserChanged()"
            name="serviceUserField"
            #serviceUserField="ngModel"
          >
            <mat-option
              *ngFor="let serviceUser of userDtos"
              [value]="serviceUser.userId"
            >
              {{ serviceUser.fullName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="serviceUserField.hasError('required')">
            {{ "Staff is" | translate }}
            <strong>{{ "required" | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">
        <div fxFlex="48">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ "Appointment Date" | translate }}</mat-label>
            <input
              matInput
              [(ngModel)]="row.startDate"
              [matDatepicker]="picker"
              required
              (dateChange)="startDateChange(row)"
              [disabled]="idx > 0"
              (focus)="picker.open()"
              and
              (click)="picker.open()"
              placeholder="Start"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>

        <div fxFlex="24">
          <mat-form-field
            appearance="outline"
            class="w-100"
            *ngIf="timeArray && timeArray.length > 0 && userDtos"
          >
            <mat-label>{{ "Start Time" | translate }}</mat-label>
            <mat-select
              placeholder="Select Start Time"
              (selectionChange)="startTimeChanged(row, $event.value)"
              [(ngModel)]="row.startTime"
              [compareWith]="compareTimeFn"
              required
            >
              <mat-option *ngFor="let time of timeArray" [value]="time.actualValue"
                >{{ time.displayValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxFlex="24">
          <mat-form-field
            appearance="outline"
            class="w-100"
            placeholder="End Time"
            *ngIf="timeArray && timeArray.length > 0 && userDtos"
          >
            <mat-label>{{ "End Time" | translate }}</mat-label>
            <mat-select
              placeholder="Select End Time"
              (selectionChange)="endTimeChanged(row, $event.value)"
              [(ngModel)]="row.endTime"
              [compareWith]="compareTimeFn"
              required
            >
              <mat-option *ngFor="let time of timeArray" [value]="time.actualValue"
                >{{ time.displayValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>
