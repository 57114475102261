import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { takeUntil } from 'rxjs/operators';
import { AddEntityDialogComponent } from './fieldinstance/addEntityDialog.component';
import { EventBusService } from './EventBusService';
import { ClearObservable } from '../shared/classes/clear-observable';
import { CachedlistcompService } from '@savvy/cached-view';
import { ContextIdDto, EntityDefinitionId, EntityInstanceId, IdNameTupleDto } from '@savvy/view-definition';
import { LinkedIdTypeEnum, LinkId } from 'src/app/core/data-share.service';

@Component({
  selector: 'app-ei-selector-with-flags',
  templateUrl: 'eiSelectorWithFlags.component.html'
})

export class EiSelectorWithFlagsComponent extends ClearObservable implements OnInit {

  @Input() entityDefinitionId: EntityDefinitionId;
  @Input() contextIdDto: ContextIdDto;
  @Input() entityInstanceLabel: string;
  @Input() entityInstanceId: EntityInstanceId;
  @Input() entityDefinitionLabel: string;
  @Output() eiChanged = new EventEmitter();

  eventBus = new EventBusService();

  eiAsLinkId: LinkId = <LinkId>{};
  initialised = false;
  entityInstanceIdField: string;
  entityInstanceIdFieldValueTupleDtos: Array<IdNameTupleDto> = [];
  private timeout;
  constructor(
    private breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
    private cachedListCompositeApi: CachedlistcompService) {
    super();
  }

  ngOnInit() {
    if (this.entityInstanceId) {
      console.log('got entity instance:', this.entityInstanceId);
      this.entityInstanceIdField = this.entityInstanceId.id;
      this.setEiId(this.entityInstanceId.id);
      this.entityInstanceIdFieldValueTupleDtos = [];
      this.entityInstanceIdFieldValueTupleDtos[0] = <IdNameTupleDto>{};
      this.entityInstanceIdFieldValueTupleDtos[0].id = this.entityInstanceId.id;
      console.log('label is:' + this.entityInstanceLabel);
      this.entityInstanceIdFieldValueTupleDtos[0].name = this.entityInstanceLabel;
    } else {
      console.log('no entity instance passed in');
    }
    console.log('leaving eiSelectorWithFlags constructor');
    this.initialised = true;
  }

  displayFn(id) {

    if (!id) {
      return '';
    }
    console.log('looking for id ' + id);
    console.log('entityInstanceIdFieldValueTupleDtos ' + this.entityInstanceIdFieldValueTupleDtos.length);
    const index = this.entityInstanceIdFieldValueTupleDtos.findIndex(state => state.id === id);
    if (index === -1) {
      console.log('id is ' + id + ' index is' + index + ' this should never happen as tuples should always be there first');
      return '';
    } else {
      console.log('returning name: ', this.entityInstanceIdFieldValueTupleDtos[index].name);
      return this.entityInstanceIdFieldValueTupleDtos[index].name;
    }
  }

  private loadTypeaheadAndSet(entityInstanceId: string) {
    console.log('loadTypeaheadAndSet3 entityInstanceId', entityInstanceId);
    console.log('this.entityDefinitionId', this.entityDefinitionId);
    this.cachedListCompositeApi.loadSingleEiTypeahead(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      entityInstanceId).pipe(takeUntil(this.destroy$)).subscribe(response => {
        if (response.entityInstanceIdFieldValueTupleDto) {
          console.log('got response ');
          this.entityInstanceIdFieldValueTupleDtos = [];
          this.entityInstanceIdFieldValueTupleDtos[0] = response.entityInstanceIdFieldValueTupleDto;
          this.entityInstanceIdField = response.entityInstanceIdFieldValueTupleDto.id;
        } else {
          console.log('error, expecting an id on ', response.entityInstanceIdFieldValueTupleDto);
        }
        this.initialised = true;
      });
  }

  loadTypeahead(searchString: string) {
    if (searchString && searchString.length > 0) {
      this.cachedListCompositeApi.listForTypeahead(
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.entityDefinitionId.id,
        searchString).pipe(takeUntil(this.destroy$)).subscribe(
          result => {
            console.log('got loadTypeahead ', result);
            this.setResponse(result);
          }, err => {
            console.log('error while loadTypeahead', err);
          });
    }
  }

  setResponse(result) {
    this.entityInstanceIdFieldValueTupleDtos = result.entityInstanceIdFieldValueTupleDtos;
    console.log('just updated:' + this.entityInstanceIdFieldValueTupleDtos);
  }


  onKey(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //      this.form.controls[this.entityInstanceId].setErrors({'doesNotSelected': true});
      this.loadTypeahead(event.target.value);
    }, 500);
  }

  changedSelection(tupleId: string) {
    if (tupleId) {
      console.log('new one ' + tupleId);
      this.eiChanged.emit(tupleId);
      this.setEiId(tupleId);
    } else {
      this.entityInstanceId = null;
      this.eiAsLinkId = null;
    }
  }

  setEiId(id: string) {
    if (!this.entityInstanceId) {
      this.entityInstanceId = <EntityInstanceId>{};
    }
    this.entityInstanceId.id = id;
    // this.eiAsLinkId = <LinkId>{};
    this.eiAsLinkId.linkedId = id;
    this.eiAsLinkId.linkedEdId = this.entityDefinitionId.id;
    this.eiAsLinkId.linkedIdType = LinkedIdTypeEnum.EntityInstanceId;
  }

  createNewLinkedEntity() {
    const dialogRef = this.dialog.open(AddEntityDialogComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        entityDefinitionId: this.entityDefinitionId.id,
        entityDefinitionLabel: this.entityDefinitionLabel,
        eventBus: this.eventBus
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.entityInstanceId) {
        console.log('got a new instance to link to ' + result.entityInstanceId);
        this.eiChanged.emit(result.entityInstanceId);
        this.loadTypeaheadAndSet(result.entityInstanceId);
      }
    });
  }
}

