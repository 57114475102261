import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SendToAllMessage } from '@savvy/send-to-all';

@Component({
  selector: 'app-send-to-all-message-preview',
  templateUrl: './send-to-all-message-preview.component.html',
  styleUrls: ['./send-to-all-message-preview.component.scss']
})
export class SendToAllMessagePreviewComponent implements OnInit {

  sendToAllMessage: SendToAllMessage;
  preview: boolean;

  flags: string[] = [];

  formGroup: UntypedFormGroup = new UntypedFormGroup({
    allCustomers: new UntypedFormControl(false),
    marketing: new UntypedFormControl(false),
    flags: new UntypedFormControl(false),
    allowEmail: new UntypedFormControl(false),
    allowSms: new UntypedFormControl(false),
    subject: new UntypedFormControl(),
    emailContent: new UntypedFormControl(),
    savedFiles: new UntypedFormControl(),
    smsContent: new UntypedFormControl()
  });

  constructor() { }

  ngOnInit(): void {
  }

  setCustomerMessage(sendToAllMessage: SendToAllMessage): void {
    this.sendToAllMessage = sendToAllMessage;
  }

  showPreview(preview: boolean) {
    this.preview = preview;
    if (this.preview) {
      this.setFormValues();
    }
  }

  setFormValues() {
    this.formGroup.disable();
    this.formGroup.patchValue({
      allCustomers: !this.sendToAllMessage.flagDefIds || !this.sendToAllMessage.flagDefIds.length,
      marketing: this.sendToAllMessage.marketingMessage,
      flags: this.sendToAllMessage.flagDefIds || [],
      allowEmail: !!this.sendToAllMessage.emailMessage,
      allowSms: !!this.sendToAllMessage.smsMessage,
      subject: this.sendToAllMessage.emailMessage ? this.sendToAllMessage.emailMessage.subject : '',
      emailContent: this.sendToAllMessage.emailMessage ? this.sendToAllMessage.emailMessage.body || '' : '',
      savedFiles: this.sendToAllMessage.emailMessage ? this.sendToAllMessage.emailMessage.attachments || [] : [],
      smsContent: this.sendToAllMessage.smsMessage ? this.sendToAllMessage.smsMessage.textMessage : ''
    });
    this.formGroup.disable();
  }

  get fg(): UntypedFormGroup {
    return this.formGroup;
  }

}
