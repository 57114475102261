<mat-toolbar color="primary">
  <mat-toolbar-row>
    <div>Your account is Past Due</div>
    <span class="example-spacer"></span>
    <button mat-raised-button color="accent" aria-label="Skip verification" (click)="skip()">
      {{'SKIP' | translate}} <mat-icon class="full-screen-icon">skip_next</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="mdc-p-3 ">
  <p>
    Hi Customer,
  </p>
  <br>
  <p>
    We're having some trouble with your current billing information. We'll  try again, but in the meantime, you may want to update your payment details.
  </p>
  <br>
  <p>
    <button mat-raised-button color="accent" aria-label="Skip verification" (click)="goToSubscriptions()">
      Update Account Now <mat-icon class="full-screen-icon">skip_next</mat-icon>
    </button>
  </p>
  <br>
  <p>
    Need help? We're here if you need it. Please come through Help Chat now!
  </p>
  <p>
    - <strong>Your friends at Savvy!</strong>
  </p>
</div>
