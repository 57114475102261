import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { OrgCompositeService } from '@savvy/org';
import { Link } from '@savvy/view-definition';
import { UserCurrencyService } from 'src/app/flo/shared/services/userCurrency.service';

@Component({
  selector: 'app-quick-deposit-view',
  templateUrl: './quick-deposit-view.component.html',
  styleUrls: ['./quick-deposit-view.component.scss']
})
export class QuickDepositViewComponent implements OnInit {


  @Output() onAction = new EventEmitter();

  depositForm: UntypedFormGroup = new UntypedFormGroup({
    paymentType: new UntypedFormControl('PERCENTAGE', [Validators.required]),
    amount: new UntypedFormControl('', []),
    percentage: new UntypedFormControl('', [])
  });

  intialValues: any = this.depositForm.value;
  currencyCode = '';
  private _selectedItem: any;

  constructor(
    private userCurrencyService: UserCurrencyService
  ) { }

  @Input() set selectedItem(selectedItem: any) {
    this._selectedItem = selectedItem;
    this.setDepositForm();
  }

  ngOnInit(): void {
    this.setDepositFormValidators();
    this.currencyCode = this.userCurrencyService.getCurrency();
  }

  onPaymentTypeChange() {
    this.setDepositFormValidators();
  }

  setDepositFormValidators() {

    if (this.depositForm.value.paymentType === 'AMOUNT') {
      this.depositForm.controls.amount.setValidators([Validators.required, Validators.min(1)]);
      this.depositForm.controls.percentage.clearValidators();
    } else {
      this.depositForm.controls.amount.clearValidators();
      this.depositForm.controls.percentage.setValidators([Validators.required, Validators.min(1), Validators.max(100)]);
    }
    this.depositForm.controls.amount.updateValueAndValidity();
    this.depositForm.controls.percentage.updateValueAndValidity();
  }

  onAddItemClick() {
    if (!this.onAction) {
      return;
    }
    const data = {
      ...this.depositForm.value
    };
    data.type = 'REQUEST_DEPOSIT';

    this.onAction.emit(data);
  }

  setDepositForm() {
    if (!this._selectedItem) {
      this.depositForm.reset(this.intialValues);
      this.setDepositFormValidators();
      this.depositForm.markAsPristine();
      return;
    }

    this.depositForm.patchValue({
      paymentType: this._selectedItem.paymentType,
      amount: this._selectedItem.amount,
      percentage: this._selectedItem.percentage
    });
  }

}
