<mat-toolbar class="main-header" color="primary">

  <button id="main-menu-toggle-button" (click)="toggleSidenav.emit()" mat-icon-button
    *ngIf="!uiAuthService?.isTrialExpired && orgSettingsInitialised">
    <mat-icon>menu</mat-icon>
  </button>

  <div class="branding">
    <a id="main-savvy-logo" (click)="home()">
      <div class="headerLogo" (click)="home()" *ngIf="backgroundImageUrl" [style.background-image]="backgroundImageUrl">
      </div>
    </a>
  </div>


  <!--button *ngIf="!deviceService.isMobile() && !uiAuthService?.isTrialExpired && orgSettingsInitialised && trialExpiredStateService.isInTrial()" class="blinking" (click)="updateSubscription()" mat-icon-button [matTooltip]="'Switch Trial Plan'">
    <mat-icon colour="primary">subscriptions</mat-icon>
  </button-->

  <mat-chip-listbox
    *ngIf="!deviceService.isMobile() && !uiAuthService?.isTrialExpired && orgSettingsInitialised && trialExpiredStateService.isInTrial() && subscriptionsDefs.length">
    <mat-chip-option color="accent" (click)="updateSubscription()" selected>
      {{'SWITCH_TRIAL_PLAN' | translate}}
    </mat-chip-option>
  </mat-chip-listbox>

  <span class="spacer"></span>

  <div *ngIf="!uiAuthService?.isTrialExpired && orgSettingsInitialised && deviceService.isMobile()"
    class="search-bar mr-2" fxFlex="nogrow">
    <app-search></app-search>
  </div>
  <div *ngIf="!uiAuthService?.isTrialExpired && orgSettingsInitialised && !deviceService.isMobile()" class="search-bar"
    fxFlex="auto">
    <app-search></app-search>
  </div>

  <button id="intercom-launcher" *ngIf="!deviceService.isMobile() && orgSettingsInitialised" mat-icon-button
    class="overflow-visible" [matTooltip]="'HELP_CHAT' | translate">
    <mat-icon>support_agent</mat-icon>
  </button>

  <!-- REMOVING CLUTTER -->
  <!--button id="add-rating-button" *ngIf="!deviceService.isMobile() && !uiAuthService?.isTrialExpired && orgSettingsInitialised" (click)="rating()" mat-icon-button [matTooltip]="'FEEDBACK' | translate">
    <mat-icon colour="primary">star</mat-icon>
  </button-->

  <!-- <button id="show-tour-button" *ngIf="!uiAuthService?.isTrialExpired && orgSettingsInitialised"
    mat-icon-button [matMenuTriggerFor]="langMenu" title="Change Language" matTooltip="Change language (Current: {{translate.currentLang}})">
    <mat-icon colour="primary">translate</mat-icon>
  </button> -->

  <!-- <mat-menu #langMenu="matMenu" x-position="before">
    <ng-container *ngFor="let lang of languages">
      <button mat-menu-item (click)="languageService.setLocale(lang.locale)">
        <span>{{lang.label}}</span>
      </button>
    </ng-container>
  </mat-menu> -->

  <!-- <button id="show-tour-button" *ngIf="!deviceService.isMobile() && !uiAuthService?.isTrialExpired && orgSettingsInitialised"
    mat-icon-button [matMenuTriggerFor]="tourMenu" [title]="'START_TOUR' | translate" [matTooltip]="'INTERACTIVE_TUTORIALS' | translate">
    <mat-icon colour="primary">tour</mat-icon>
  </button>

  <mat-menu #tourMenu="matMenu" x-position="before">
    <button *ngIf="!production" id="mobile-sms-button" mat-menu-item (click)="runTour(351411)">
      <span>{{'WELCOME_TOUR' | translate}}</span>
    </button>
    <button *ngIf="production" id="mobile-sms-button" mat-menu-item (click)="runTour(354322)">
      <span>{{'WELCOME_TOUR' | translate}}</span>
    </button>
  </mat-menu> -->

  <button id="sms-credit-button"
    *ngIf="!deviceService.isMobile() && !uiAuthService?.isTrialExpired && orgSettingsInitialised && smsCreditStateService?.smsCreditSummaryDto"
    (click)="sms()" mat-icon-button [matTooltip]="'SMS_CREDIT_SUMMARY' | translate">
    <mat-icon colour="primary"
      *ngIf="subscriptionHelperService?.isFreemium || smsCreditStateService?.smsCreditSummaryDto?.remainingSmsCreditsIncludingPaid >= 50">
      sms
    </mat-icon>
    <mat-icon class="mat-text-red"
      *ngIf="!subscriptionHelperService?.isFreemium && smsCreditStateService?.smsCreditSummaryDto?.remainingSmsCreditsIncludingPaid < 50">
      announcement
    </mat-icon>
  </button>

  <!-- REMOVING CLUTTER -->
  <!--button id="fullscreen-button" *ngIf="!deviceService.isMobile() && !uiAuthService?.isTrialExpired && orgSettingsInitialised" (click)="fullScreenToggle()" mat-icon-button [matTooltip]="'TOGGLE_FULLSCREEN' | translate">
    <mat-icon>fullscreen</mat-icon>
  </button-->

  <button id="admin-settings-button"
    *ngIf="!deviceService.isMobile() && !uiAuthService?.isTrialExpired && orgSettingsInitialised"
    (click)="gotoSettings()" mat-icon-button [matTooltip]="'ADMIN_SETTINGS' | translate">
    <mat-icon>settings</mat-icon>
  </button>

  <!-- <app-apps-menu *ngIf="!deviceService.isMobile() && appsMenuEnabled && !uiAuthService?.isTrialExpired && orgSettingsInitialised ">
  </app-apps-menu> -->

  <!-- <a id="settings-button"
    *ngIf="!deviceService.isMobile() && !appsMenuEnabled && !uiAuthService?.isTrialExpired && orgSettingsInitialised"
    routerLink="/org/editOrg/{{contextIdDto.contextId}}/{{contextIdDto.contextIdType}}">
    <button mat-icon-button>
      <mat-icon>settings</mat-icon>
    </button>
  </a> -->

  <button id="notifications-button"
    *ngIf="!deviceService.isMobile() && !uiAuthService?.isTrialExpired && orgSettingsInitialised"
    (click)="notifications()" mat-icon-button class="overflow-visible" [matTooltip]="'NOTIFICATIONS' | translate">
    <mat-icon>notifications</mat-icon>
    <span *ngIf="numUnreadNotifications > 0" class="notification-label">{{numUnreadNotifications}}</span>
  </button>

  <!-- <button id="version-info-button" *ngIf="!deviceService.isMobile() && !uiAuthService?.isTrialExpired && orgSettingsInitialised"
    (click)="showVersion()" [matTooltip]="version" mat-icon-button>
    <mat-icon>info</mat-icon>
  </button> -->

  <!-- REMOVING CLUTTER -->
  <!--button id="update-system-button" *ngIf="!deviceService.isMobile() && !uiAuthService?.isTrialExpired && orgSettingsInitialised" (click)="checkForUpdate()" [matTooltip]="'CHECK_FOR_UPDATES' | translate" mat-icon-button>
    <mat-icon>upgrade</mat-icon>
  </button-->

  <!-- <button id="refresh-system-button"
    *ngIf="uiUpdateAvailable && !deviceService.isMobile() && !uiAuthService?.isTrialExpired && orgSettingsInitialised"
    (click)="bootstrapService.forceReload()" [matTooltip]="'Refresh to get new version'" mat-icon-button class="blinking">
    <mat-icon>refresh</mat-icon>
  </button> -->

  <!-- REMOVE CLUTTER -->
  <!--button id="find-replace-button" *ngIf="appMigration && !migrationScheduled && !deviceService.isMobile() && !uiAuthService?.isTrialExpired && orgSettingsInitialised" (click)="performMigration()" [matTooltip]="'REFRESH_TO_UPDATE' | translate" mat-icon-button class="blinking">
    <mat-icon>find_replace</mat-icon>
  </button-->

  <button id="user-profile-button" *ngIf="!deviceService.isMobile()" [matMenuTriggerFor]="user" mat-icon-button
    [matTooltip]="'MY_ACCOUNT' | translate">
    <mat-icon>person</mat-icon>
  </button>

  <button id="mobile-menu-button" mat-icon-button
    *ngIf="deviceService.isMobile() && !uiAuthService?.isTrialExpired && orgSettingsInitialised"
    [matMenuTriggerFor]="user">
    <mat-icon>menu</mat-icon>
  </button>

  <mat-menu #user="matMenu" x-position="before">

    <a id="mobile-profile-button" routerLink="/user/userProfile">
      <button mat-menu-item>
        <mat-icon>account_box</mat-icon>
        <span>{{'MY_PROFILE' | translate}}</span>
      </button>
    </a>

    <a id="mobile-subscription-button"
      [routerLink]="'/subscription/subscriptions/' + contextIdDto?.contextId + '/' + contextIdDto?.contextIdType">
      <button mat-menu-item>
        <mat-icon>subscriptions</mat-icon>
        <span>{{'MY_SUBSCRIPTIONS' | translate}}</span>
      </button>
    </a>

    <a id="mobile-earnings-button"
      [routerLink]="'/payment/payments/' + contextIdDto?.contextId + '/' + contextIdDto?.contextIdType">
      <button mat-menu-item>
        <mat-icon>wallet</mat-icon>
        <span>{{'MY_EARNINGS' | translate}}</span>
      </button>
    </a>

    <a id="mobile-earnings-button" [routerLink]="'/releaseNotes'">
      <button mat-menu-item>
        <mat-icon>new_releases</mat-icon>
        <span>{{'WHAT_NEW' | translate}}</span>
      </button>
    </a>

    <a id="explore-more-button" href="https://intercom.help/savvypet/en" target="_blank">
      <button mat-menu-item>
        <mat-icon>more</mat-icon>
        <span>{{'TUTORIALS' | translate}}</span>
      </button>
    </a>

    <a *ngIf="contextService?.appType === 'PET_GROOMING_SALON'" id="youtube-button"
      href="https://www.youtube.com/@savvypetspa" target="_blank">
      <button mat-menu-item>
        <mat-icon>smart_display</mat-icon>
        <span>{{'YOUTUBE' | translate}}</span>
      </button>
    </a>

    <a id="support-demo-button" href="https://meetings-eu1.hubspot.com/savvy-customer-support" target="_blank">
      <button mat-menu-item>
        <mat-icon>co_present</mat-icon>
        <span>{{'SUPPORT_DEMO' | translate}}</span>
      </button>
    </a>

    <button id="mobile-sms-button" mat-menu-item (click)="sms()"
      *ngIf="deviceService.isMobile() && !uiAuthService?.isTrialExpired && orgSettingsInitialised">
      <mat-icon colour="primary"
        *ngIf="subscriptionHelperService?.isFreemium || smsCreditStateService?.smsCreditSummaryDto?.remainingSmsCreditsIncludingPaid >= 50">
        sms
      </mat-icon>
      <mat-icon class="mat-text-red"
        *ngIf="!subscriptionHelperService?.isFreemium && smsCreditStateService?.smsCreditSummaryDto?.remainingSmsCreditsIncludingPaid < 50">
        announcement
      </mat-icon>
      <span>{{'SMS' | translate}}</span>
    </button>

    <!-- <button id="mobile-rating-button" mat-menu-item (click)="rating()"
      *ngIf="deviceService.isMobile() && !uiAuthService?.isTrialExpired && orgSettingsInitialised">
      <mat-icon>star</mat-icon>
      <span>{{'RATING' | translate}}</span>
    </button> -->

    <!-- <button id="show-tour-button" mat-menu-item
      *ngIf="deviceService.isMobile() && !uiAuthService?.isTrialExpired && orgSettingsInitialised" [matMenuTriggerFor]="tourMenu"
      [title]="'START_TOUR' | translate">
      <mat-icon>tour</mat-icon>
      <span>{{'TOUR' | translate}}</span>
    </button> -->


    <button id="mobile-notification-button" (click)="notifications()" mat-menu-item
      *ngIf="deviceService.isMobile() && !uiAuthService?.isTrialExpired && orgSettingsInitialised">
      <mat-icon>notifications</mat-icon>
      Notifications <span *ngIf="numUnreadNotifications" class="notification-label">({{numUnreadNotifications}})</span>
      <span *ngIf="!numUnreadNotifications" class="notification-label">(0)</span>
    </button>

    <!-- <button id="mobile-info-button" (click)="showVersion()" [matTooltip]="version" mat-menu-item
      *ngIf="deviceService.isMobile()">
      <mat-icon>info</mat-icon>
      <span>{{'Version' | translate}}</span>
    </button> -->

    <!-- <button id="mobile-setup-button" *ngIf="deviceService.isMobile() && !uiAuthService?.isTrialExpired && orgSettingsInitialised"
      [matTooltip]="Setup" (click)="orgSetup()" mat-menu-item>
      <mat-icon colour="primary">auto_fix_high</mat-icon>
      <span>{{'Setup' | translate}}</span>
    </button> -->

    <button id="mobile-update-button" *ngIf="deviceService.isMobile()" (click)="checkForUpdate()"
      [matTooltip]="'CHECK_FOR_UPDATES' | translate" mat-menu-item>
      <mat-icon>upgrade</mat-icon>
      <span>{{'CHECK_FOR_UPDATES' | translate}}</span>
    </button>

    <!-- <button id="mobile-refresh-button" (click)="bootstrapService.forceReload()" [matTooltip]="'Refresh to get new version'" mat-menu-item
      *ngIf="uiUpdateAvailable && deviceService.isMobile()" class="blinking">
      <mat-icon>refresh</mat-icon>
      <span>{{'Refresh' | translate}}</span>
    </button> -->

    <button id="mobile-migration-button" (click)="performMigration()" [matTooltip]="'REFRESH_TO_UPDATE' | translate"
      mat-menu-item
      *ngIf="appMigration && !migrationScheduled && deviceService.isMobile() && !uiAuthService?.isTrialExpired && orgSettingsInitialised"
      class="blinking">
      <mat-icon>find_replace</mat-icon>
      <span>{{'REFRESH_TO_UPDATE' | translate}}</span>
    </button>

    <a id="intercom-launcher" *ngIf="deviceService.isMobile()" mat-menu-item>
      <mat-icon>support_agent</mat-icon>
      <span>{{'HELP_CHAT' | translate}}</span>
    </a>
    <!-- <app-apps-menu *ngIf="deviceService.isMobile()"></app-apps-menu> -->

    <!-- routerLink="/org/editOrg/{{this.contextIdDto.contextId}}/{{this.contextIdDto.contextIdType}}" -->
    <a id="mobile-settings-button" *ngIf="deviceService.isMobile()">
      <button mat-menu-item (click)="gotoSettings()">
        <mat-icon>settings</mat-icon>
        {{'SETTINGS' | translate}}
      </button>
    </a>

    <a id="mobile-signout-button" href="javascript:void(0)" (click)="logout()">
      <button mat-menu-item>
        <mat-icon>exit_to_app</mat-icon>
        <span>{{'SIGN_OUT' | translate}}</span>
      </button>
    </a>

    <!-- <button mat-menu-item (click)="orgSetup()">
      <mat-icon (click)="orgSetup()">card_membership</mat-icon>
      <span>{{'Setup Organization' | translate}}</span>
    </button> -->
    <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="14px" class="w-100 mdc-py-2 mdc-px-3">
      <mat-icon fxFlex="nogrow" class="mat-text-yellow"
        *ngIf="uiUpdateAvailable && !uiAuthService?.isTrialExpired && orgSettingsInitialised; else latestVersionIcon">info</mat-icon>
      <ng-template #latestVersionIcon>
        <mat-icon class="mat-text-green">check_circle</mat-icon>
      </ng-template>
      <!--conditionally change -->
      <div fxFlex class="ps-2">
        <p class="my-0" style="line-height: 16px;">
          {{'VERSION' | translate}} {{version}} <br>
          <small
            *ngIf="uiUpdateAvailable && !uiAuthService?.isTrialExpired && orgSettingsInitialised; else latestVersion">
            {{'UPDATE_AVAILABLE' | translate}}
          </small>
          <ng-template #latestVersion>
            <small>{{'ON_LATEST_VERSION' | translate}}</small>
          </ng-template>
          <!--conditionally change -->
        </p>
      </div>
      <mat-icon fxFlex="nogrow" [matTooltip]="'CHECK_UI_UPDATE' | translate" class=""
        (click)="bootstrapService.forceReload()">update</mat-icon>
    </div>


  </mat-menu>
</mat-toolbar>
