import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { WorkflowdefService } from '@savvy/workflow-definition';
import {
  EntitydefsService, ActionDefinition,
  ContextIdDto, ElementDefinitionId,
  ElementDefinitionSummaryDto, EmailMessageTemplateId, EntityDefinitionId, FieldIdNameTupleDto,
  IdNameTupleDto
} from '@savvy/entity-definition';
import { ContextService } from '../context.service';
import { Variable } from '../editor/editor.component';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {
  AddSuppliedTaskToWorkflowState,
  RemoveTaskFromState,
  UpdateTaskDefinition,
  WorkflowDefinition,
  WorkflowDefinitionSummaryDto,
  WorkflowState,
  WorkflowTaskDefinition
} from '@savvy/workflow-definition';
import { ManualTaskDefinition } from '@savvy/task';


@Component({
  selector: 'app-add-edit-workflow-task-definition',
  templateUrl: 'addEditWorkflowTaskDefinition.html'
})
export class AddEditWorkflowTaskDefinitionComponent implements OnInit, OnDestroy {
  @Input() set taskDef(val) {
    this._taskDef = val;
  }
  @Input() actionTypes: Array<IdNameTupleDto>;
  @Input() taskTypes: Array<IdNameTupleDto>;
  @Input() users: Array<IdNameTupleDto>;
  @Input() roles: Array<IdNameTupleDto>;
  @Input() emailMessageTemplates: Array<IdNameTupleDto>;
  @Input() contextIdDto: ContextIdDto;

  @Input() workflowState: WorkflowState;
  @Input() workflowDefinitionId: string;
  @Input() workflowDefinitionSummaries: Array<WorkflowDefinitionSummaryDto>;

  @Output() taskAdded = new EventEmitter();
  @Output() taskUpdated = new EventEmitter();
  @Output() taskRemoved = new EventEmitter();
  _taskDef: WorkflowTaskDefinition;
  subscriptions: Subscription[] = [];

  summaryMap = new Map<string, Array<string>>();
  variables = new Array<Variable>();
  workflowDef: WorkflowDefinition;
  elementDefinitions: Array<ElementDefinitionSummaryDto>;

  updateBodyTerm$ = new Subject<string>();

  manualTask = WorkflowTaskDefinition.TaskTypeEnum.ManualTask;
  sendMessageTask = WorkflowTaskDefinition.TaskTypeEnum.SendMessage;

  constructor(
    private api: WorkflowdefService,
    private notify: FloSnackbarComponent,
    private router: Router,
    private contextService: ContextService,
    private translateService: TranslateService,
    private entityApi: EntitydefsService) {
  }

  ngOnInit(): void {
    this.initUpdateBody();
    this.variables = new Array();
    const variable = <Variable>{};
    variable.label = 'Test';
    variable.value = 'YES!!!';
    this.variables.push(variable);

    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
        this.api.getWorkflowDef(this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          this.workflowDefinitionId).subscribe(response => {
            this.workflowDef = response;
            this.loadElementDefs();
            this.loadVariables();
          });

        if (this.workflowDefinitionSummaries) {
          this.workflowDefinitionSummaries.forEach(summary => {
            this.summaryMap.set(summary.workflowDefinitionId.id, summary.states);
          });
        }
      }
    }));
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  toEmailMessageTemplateId(id: string): EmailMessageTemplateId {
    const msgId = <EmailMessageTemplateId>{};
    msgId.id = id;
    return msgId;
  }

  getWorkflowStates(): Array<string> {
    return this.summaryMap.get(this.workflowDefinitionId);
  }

  isSendMessageTask(): boolean {
    return this._taskDef.taskType && this._taskDef.taskType.toString() === 'SEND_MESSAGE';
  }

  isSendMessage(): boolean {
    return this.isSendMessageTask()
      && this._taskDef.actionDefinition
      && this._taskDef.actionDefinition.actionType === ActionDefinition.ActionTypeEnum.SendMessage;
  }

  isSendSms(): boolean {
    return this.isSendMessageTask()
      && this._taskDef.actionDefinition
      && this._taskDef.actionDefinition.actionType === ActionDefinition.ActionTypeEnum.SendSms;
  }

  compareEntityDefId(c1: EntityDefinitionId, c2: EntityDefinitionId): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  compareElementDefId(c1: ElementDefinitionId, c2: ElementDefinitionId): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }



  loadVariables() {
    console.log('loading variables');
    this.elementDefinitions = null;
    if (this.workflowDef && this.workflowDef.workflowDefinitionTypeData
      && this.workflowDef.workflowDefinitionTypeData.entityDefinitionId
      && this.workflowDef.workflowDefinitionTypeData.entityDefinitionId.id &&
      this.workflowDef.workflowDefinitionTypeData.entityDefinitionId.id.length > 0) {
      this.entityApi.getEntityDefinitionFieldTuples(
        this.workflowDef.workflowDefinitionTypeData.entityDefinitionId.id,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType
      ).subscribe(
        response => {
          this.createVariablesArray(response.fieldIdNameTupleList);
        }
      );
    }
  }
  createVariablesArray(fieldIdNameTupleList: Array<FieldIdNameTupleDto>) {
    if (fieldIdNameTupleList) {
      this.variables = new Array();
      fieldIdNameTupleList.forEach(tuple => {
        const variable = <Variable>{};
        variable.value = '{{' + tuple.name + '}}';
        variable.label = tuple.name;
        this.variables.push(variable);
      });
    }
  }

  loadElementDefs() {
    console.log('loading element defs');
    this.elementDefinitions = null;
    if (this.workflowDef && this.workflowDef.workflowDefinitionTypeData
      && this.workflowDef.workflowDefinitionTypeData.entityDefinitionId
      && this.workflowDef.workflowDefinitionTypeData.entityDefinitionId.id &&
      this.workflowDef.workflowDefinitionTypeData.entityDefinitionId.id.length > 0) {
      this.entityApi.getAllDateAndDateTimeFields(
        this.workflowDef.workflowDefinitionTypeData.entityDefinitionId.id,
        this.contextIdDto.contextIdType,
        this.contextIdDto.contextId).subscribe(
          response => {
            console.log('response.elementDefinitionSummaryDtoList ' + response.elementDefinitionSummaryDtoList.length);
            this.elementDefinitions = response.elementDefinitionSummaryDtoList;
          }
        );
    }
  }

  createTask() {
    console.log('calling create task');
    if (!this.workflowState) {
      console.log('ERROR, we have no workflowState!!');
      return;
    }
    if (this._taskDef.actionDefinition && !this._taskDef.actionDefinition.actionType) {
      console.log('ERROR, got action def but no action type');
      return;
    }
    const req = <AddSuppliedTaskToWorkflowState>{};
    req.contextIdDto = this.contextIdDto;
    req.stateId = this.workflowState.id;
    console.log('this._taskDef', this._taskDef);
    req.workflowTaskDefinition = this._taskDef;
    req.workflowDefinitionId = this.workflowDefinitionId;
    this.api.addSuppliedTaskToState(req).subscribe(response => {
      this.translateService.get('Task added').subscribe(value => {
        this.notify.message = value;
        this.notify.open();
      });
      this._taskDef = response.newTaskDefinition;
      console.log('new task def is ', this._taskDef);
      this.taskAdded.emit('taskAdded');
      /*this.router.navigate(['/workflow/addEditWorkflowState',
        this.workflowDefinitionId,
        this.workflowState.id,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
      ]);*/
    });
  }

  update() {
    if (this._taskDef.id) {
      console.log('updating task');
      if (this._taskDef.postTaskDefinition) {
        console.log('post task def is here:' + this._taskDef.postTaskDefinition.transitionTo);
      } else {
        console.log('post task def is empty');
      }
      const req = <UpdateTaskDefinition>{};
      req.contextIdDto = this.contextIdDto;
      req.workflowTaskDefinition = this._taskDef;
      req.stateId = this.workflowState.id;
      req.workflowDefinitionId = this.workflowDefinitionId;


      this.api.updateTaskDef(req).subscribe(response => {
        this._taskDef = response;
        this.translateService.get('Updated task').subscribe(value => {
          this.notify.message = value;
          this.notify.open();
        });
        this.taskUpdated.emit('updatedTask');
      });
    } else {
      console.log('not updating task as it has not been crated yet', this._taskDef);
    }
  }

  deleteTask() {
    const req = <RemoveTaskFromState>{};
    req.contextIdDto = this.contextIdDto;
    req.taskDefinitionId = this._taskDef.taskDefinitionId.id;
    req.state = this.workflowState.state;
    req.workflowDefinitionId = this.workflowDefinitionId;

    this.api.removeTaskFromState(req).subscribe(response => {
      this.translateService.get('Removed task').subscribe(value => {
        this.notify.message = value;
        this.notify.open();
      });
      this.taskRemoved.emit('removedTask');
    });
  }

  private initUpdateBody() {
    this.updateBodyTerm$
      .pipe(debounceTime(1000))
      .pipe(distinctUntilChanged())
      .subscribe(text => {
        console.log('body updated!');
        this._taskDef.actionDefinition.sendMessageDefinition.body = text;
        this.update();
      });
  }
}
