<mat-toolbar color="primary">
  <mat-toolbar-row> Is This Correct?</mat-toolbar-row>
</mat-toolbar>

<div class="confirm-modal mdc-p-3">

  <h5 class="text-center">{{'CHOSEN_ANNUAL_PLAN' | translate}}</h5>
  <br>

  <div fxLayout="row">
    <div fxFlex>
      <button mat-raised-button (click)="dialogRef.close(false)">{{'NO' | translate}}</button>
    </div>
    <div fxFlex="nogrow">
      <button mat-raised-button color="accent" (click)="dialogRef.close(true)">{{'YES' | translate}}</button>
    </div>
  </div>

</div>