import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Page } from '../../shared/model/page';
import { TableColumn } from '@swimlane/ngx-datatable';
import { IndividualMessageDetailsDto } from './individual-messages.model';
import {
  IndividualMessage,
  IndividualMessageService, PageDtoIndividualMessage
} from '@savvy/send-to-all';
import { ActivatedRoute } from '@angular/router';
import { ContextIdDto } from '@savvy/view-definition';
import SendingStatusEnum = IndividualMessage.SendingStatusEnum;

@Component({
  selector: 'app-individual-messages',
  templateUrl: './individual-messages.component.html',
  styleUrls: ['./individual-messages.component.scss']
})
export class IndividualMessagesComponent implements OnInit {

  individualMessageDetailsDtos: IndividualMessageDetailsDto[] = [];
  messageId: string;
  selected: IndividualMessageDetailsDto[] = [];
  page = new Page();
  loading = false;
  sortAscending = true;
  sub: any;
  contextIdDto: ContextIdDto;



  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private api: IndividualMessageService,
  ) { }


  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      const contextId = params['contextId'];
      const contextIdType = params['contextIdType'];
      console.log('got contextId ' + contextId);
      console.log(contextId, contextIdType);
      this.contextIdDto = { contextId, contextIdType };
      this.messageId = this.route.snapshot.paramMap.get('messageId');
      this.loadMessageDetails(this.messageId);
    });

  }

  backToList() {
    this.location.back();
  }

  loadMessageDetails(messageId: string) {
    this.loading = true;
    console.log('loadMessageDetails with message id ', messageId);
    console.log('page number is ' + this.page.pageNumber);
    console.log('page size is ' + this.page.size);
    console.log('sortAscending is ' + this.sortAscending);

    this.api.getIndividualMessage(messageId,
      (this.page.pageNumber + 1),
      this.page.size).subscribe(response => {
        this.loading = false;
        this.handleMessagesDetails(response);
      });

  }

  handleMessagesDetails(messageDetailsPage: PageDtoIndividualMessage) {
    this.individualMessageDetailsDtos = messageDetailsPage.contents.map(v => this.mapMessageDetailsToDto(v));
    this.page.totalElements = messageDetailsPage.totalElements;
    this.page.totalPages = messageDetailsPage.totalPages;
  }

  mapMessageDetailsToDto(message: IndividualMessage): IndividualMessageDetailsDto {
    const dto: IndividualMessageDetailsDto = new IndividualMessageDetailsDto();
    dto.fullName = `${message.firstName} ${message.lastName}`;
    dto.messageDetailsId = message.id;
    dto.firstName = message.firstName;
    dto.lastName = message.lastName;
    dto.sendingStatus = this.getSendingStatus(message);
    dto.status = this.getStatus(message);
    if (message.individualMessageType) {
      if (message.individualMessageType === IndividualMessage.IndividualMessageTypeEnum.Sms) {
        dto.type = 'Sms';
        dto.recipient = message.mobileNumber;
      } else {
        dto.type = 'Email';
        dto.recipient = message.username;
      }
    }
    return dto;
  }

  private getSendingStatus(message: IndividualMessage) {
    if (message.sendingStatus) {
        return message.sendingStatus.toUpperCase();
    }
    return '';
  }

  private getStatus(message: IndividualMessage) {

    if (message.status) {

      /*if (message.emailStatus.failureReason) {
        return message.emailStatus.failureReason;
      }*/


      if (message.status.messageStatus) {
        return message.status.messageStatus;
      }
    }
    return '';
  }

  onSort(event: any) { }

  setPage(pageInfo: any) {
    this.page.pageNumber = pageInfo.offset;
    this.loadMessageDetails(this.messageId);
  }

}
