import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ContextService } from '../context.service';
import { Router } from '@angular/router';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { ContextIdDto } from '@savvy/payment';
import { NavChangeService } from '../element/NavChangeService';
import { PhoneNumberHelperService } from '../shared/services/phone-number-helper.service';
import { Subject, Subscription } from 'rxjs';
import { ConfirmationModalComponent } from '../shared/components/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { OrgCompositeService } from '@savvy/org';
import {InvoiceService, Invoice, ListInvoiceSorted, ListInvoiceFilteredByInvoiceNumberSorted} from '@savvy/invoice';
import { Page } from '../shared/model/page';
import {ViewInvoiceV2Component} from "../appointment/view-invoice-v2/view-invoice-v2.component";
import {FormGroup, FormControl} from "@angular/forms";

@Component({
  selector: 'app-view-invoices',
  templateUrl: './viewInvoices.component.html'
})
export class ViewInvoicesComponent implements OnInit, OnDestroy {

  @Input() contextIdDto: ContextIdDto;

  invoices: Array<Invoice>;
  selected: Invoice[] = [];

  destroy$ = new Subject();
  subscriptions: Subscription[] = [];
  currencyCode = ''; //default
  page = new Page();

  loading: boolean;

  timer;

  totalAmount = 0;

  filterForm = new FormGroup({
    invoiceNumber: new FormControl('')
  });

  sortAscending = false;
  column = 'invoiceDate';

  constructor(
    private router: Router,
    private orgCompApi: OrgCompositeService,
    private invoiceApi: InvoiceService,
    private notify: FloSnackbarComponent,
    private navChange: NavChangeService,
    private contextService: ContextService,
    private sharedService: PhoneNumberHelperService,
    private translateService: TranslateService,
    private dialog: MatDialog) {
  }

  ngOnInit() {
    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
        this.setPage({ offset: 0, size: 10 });
        this.loadOrg();
      }
    }));
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  setPage(pageInfo) {
    console.log('pageInfo is ' + pageInfo);
    this.page.pageNumber = pageInfo.offset;
    this.getInvoices();
  }

  loadOrg() {
    this.orgCompApi.getCurrentOrgComp().subscribe(res => {
      this.currencyCode = res.currencyCode;
    });
  }

  onSelect(event) {

    if (this.selected[0]?.id) {
      const dialogRef = this.dialog.open(ViewInvoiceV2Component, {
        maxWidth: '800px',
        width: '800px',
        data: {
          invoiceId: this.selected[0].id,
          contextIdDto: this.contextIdDto
        },
        panelClass: ['scrollable-modal', 'invoicemodal']
      });

      dialogRef.afterClosed().subscribe((result) => {

      });
    }

  }


  getInvoices() {
    this.contextIdDto = this.contextIdDto;

    if (this.filterForm.value.invoiceNumber) {
      let req = <ListInvoiceFilteredByInvoiceNumberSorted>{};
      req.contextIdDto = this.contextIdDto;
      req.pageNum = this.page.pageNumber + 1;
      req.pageSize = this.page.size;
      req.ascending = this.sortAscending;
      req.column = this.column;
      req.invoiceNumber = this.filterForm.value.invoiceNumber;

      this.invoiceApi.listInvoiceFilteredByInvoiceNumberSorted(
        req).subscribe(response => {
        this.invoices = response.contents;
        this.page.totalElements = response.totalElements;
        this.page.totalPages = response.totalPages;
      });
    } else {
      let req = <ListInvoiceSorted>{};
      req.contextIdDto = this.contextIdDto;
      req.pageNum = this.page.pageNumber + 1;
      req.pageSize = this.page.size;
      req.ascending = this.sortAscending;
      req.column = this.column;
      this.invoiceApi.listInvoiceSorted(req).subscribe(response => {
        this.invoices = response.contents;
        this.page.totalElements = response.totalElements;
        this.page.totalPages = response.totalPages;
      });
    }
  }

  filter() {
    this.setPage({ offset: 0, size: 10 });
  }

  reset() {
    this.filterForm.patchValue({
      invoiceNumber: null
    });
    this.setPage({ offset: 0, size: 10 });
  }

  onSort(event: any) {
    // event was triggered, start sort sequence
    console.log('Sort1', event);
    console.log('Sort2', event.sorts);
    console.log('Sort2[0]', event.sorts[0]);
    this.sortAscending = event.newValue === 'asc';
    this.column = event.sorts[0].prop;

    if (this.column === 'invoiceNumber') {
      this.column = 'invoiceNumberLong';
    }
    console.log('sortAscending', this.sortAscending);
    console.log('column', this.column);
    this.setPage({ offset: 0, size: 20 });
  }


  voidInvoice(row) {
    console.log(row);

    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        name: 'Invoice',
        message: 'Are you sure you want to void'
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.invoiceApi.voidInvoice(
          row.id,
          this.contextIdDto.contextId, this.contextIdDto.contextIdType
        ).subscribe(response => {
          this.getInvoices();
        });
      } else {
        console.log('ERR: Not deleting');
      }
    });
  }

  unvoidInvoice(row) {
    console.log(row);

    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        name: 'Invoice',
        message: 'Are you sure you want to unvoid'
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.invoiceApi.unvoidInvoice(
          row.id,
          this.contextIdDto.contextId, this.contextIdDto.contextIdType
        ).subscribe(response => {
          this.getInvoices();
        });
      } else {
        console.log('ERR: Not deleting');
      }
    });
  }

}
