import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { DateTimeAdapter, NativeDateTimeAdapter, OWL_DATE_TIME_LOCALE, OwlDateTimeIntl } from '@danielmoncada/angular-datetime-picker';
import { LocalIntl } from '../../../../services/local-intl.service';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { Series } from '@savvy/series';

interface LabelValue { label: string; value: string | number }

@Component({
  selector: 'app-recurring-modal',
  templateUrl: './recurring.modal.component.html',
  providers: [
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-US' },
    { provide: DateTimeAdapter, useClass: NativeDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE, Platform] },
    { provide: OwlDateTimeIntl, useClass: LocalIntl },
  ]
})
export class RecurringModalComponent implements OnInit {
  series: Series;
  dateTimeRange: Date[];
  dialogChange: Subject<Series>;
  repeatEveryOpts: LabelValue[];
  repeatEveryPeriodOpts: LabelValue[];
  numRepeatsOpts: LabelValue[];

  @ViewChild('reccuringForm', { static: true }) reccuringForm;

  constructor(
    public dialogRef: MatDialogRef<RecurringModalComponent>,
    private translateService: TranslateService,
    dateTimeAdapter: DateTimeAdapter<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
    const backdropClickSubscription = dialogRef.backdropClick().subscribe(() => {
      this.cancel();
      backdropClickSubscription.unsubscribe();
    });
    this.generateOptions();
    this.dialogChange = data.dialogChange;
    dateTimeAdapter.setLocale(this.translateService.currentLang);
  }

  ngOnInit() {
    if (this.data.series) {
      this.dateTimeRange = [
        this.data.series.start,
        this.data.series.end,
      ];
      this.series = <Series>{};
      this.series.numRepeats = this.data.series.numRepeats;
      this.series.repeatEvery = this.data.series.repeatEvery;
      this.series.repeatEveryPeriod = this.data.series.repeatEveryPeriod;
      this.series.id = this.data.series.id;
    } else {
      this.dateTimeRange = [
        null,
        null
      ];
      this.series = <Series>{};
    }
  }


  matSelectCompageFn(opt1, opt2) {
    return opt1 === opt2;
  }

  validateDate(isStart) {
    if (this.reccuringForm && this.reccuringForm.controls && this.reccuringForm.controls.start && this.reccuringForm.controls.end) {
      if (isStart) {
        return (this.reccuringForm.controls.start.dirty || this.reccuringForm.controls.start.touched) && !this.dateTimeRange[0];
      } else {
        return true;
      }
      // return (this.reccuringForm.controls.end.dirty || this.reccuringForm.controls.end.touched) && !this.dateTimeRange[1];
    }
    return false;
  }

  isInvalid(controlName) {
    if (this.reccuringForm.controls[controlName]) {
      return (this.reccuringForm.controls[controlName].dirty ||
        this.reccuringForm.controls[controlName].touched) &&
        !this.reccuringForm.controls[controlName].value;
    }
    return false;
  }

  private generateOptions() {
    const repeatEveryOpts = [];
    const numRepeatsOpts = [];
    for (let i = 1; i < 100; i++) {
      repeatEveryOpts.push({ label: i.toString(), value: i });
      numRepeatsOpts.push({ label: `${i} ${i === 1 ? 'Time' : 'Times'}`, value: i });
    }
    this.repeatEveryOpts = repeatEveryOpts;
    this.repeatEveryPeriodOpts = [
      {
        label: 'Days',
        value: Series.RepeatEveryPeriodEnum.Days
      },
      {
        label: 'Weeks',
        value: 'WEEKS'
      },
      {
        label: 'Months',
        value: Series.RepeatEveryPeriodEnum.Months
      },
      {
        label: 'Years',
        value: Series.RepeatEveryPeriodEnum.Years
      }
    ];
    this.numRepeatsOpts = numRepeatsOpts;
  }

  save() {
    this.dialogChange.next(this.series);
    this.dialogRef.close();
  }

  cancel() {
    if (this.reccuringForm.invalid) {
      return this.dialogRef.close({ enableRecurring: false });
    }
    this.dialogRef.close();
  }

}
