import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ContextIdDto } from '@savvy/app';
import { AppointmentCompService, AppointmentDataV1V2, AppointmentId, AppointmentService, AppointmentV1V2Service } from '@savvy/appointment';
import { Pet, PetService } from '@savvy/pet';
import { ViewInvoiceV2Component } from '../../view-invoice-v2/view-invoice-v2.component';
import { MatDialog } from '@angular/material/dialog';
import { AppointmentHandlerService } from 'src/app/flo/appointments-v2/appointment-handler.service';
import { FloSnackbarComponent } from 'src/app/flo/snackbar/floSnackbar.component';
import { ConfirmationDialogComponent } from 'src/app/flo/confirmation-dialog/confirmation-dialog.component';
import { AppointmentConfigHelperService } from 'src/app/flo/shared/services/appointment-config-helper.service';
import { Vaccination, VaccinationService } from "@savvy/vaccination";
import { Feature } from "@savvy/add-ons";
import { FeaturesHelperService } from "../../../shared/services/features-helper.service";
import { CalendarSetupService } from 'src/app/flo/welcome-to-savvy/calendar-setup.service';

@Component({
  selector: 'app-view-v1-v2-pet-history',
  templateUrl: './view-v1-v2-pet-history.component.html',
  styleUrls: ['./view-v1-v2-pet-history.component.scss']
})
export class ViewV1V2PetHistoryComponent implements OnInit, OnChanges {
  @Input() pet: Pet;
  @Input() petId: string;
  @Input() contextIdDto: ContextIdDto;
  @Input() addAppointmentAllowed = false;
  @Input() isViewMode = false;
  @Input() historyMode: 'past' | 'future' | 'vaccinations' = 'future';
  @Input() showVaccinations = false;
  @Output() openV1Event: EventEmitter<AppointmentDataV1V2> = new EventEmitter();
  @Output() loadAppointmentsEvent: EventEmitter<any> = new EventEmitter();


  v1PetEntityInstanceId: string;

  pastAppointments: Array<AppointmentDataV1V2> = [];
  futureAppointments: Array<AppointmentDataV1V2> = [];


  petList = [];
  showOnlyCancelled = false;
  selectedAppointments: string[] = [];
  vaccinations: Array<Vaccination> = [];
  isVaccinationFeatureAllowed = false;
  constructor(
    /** If no v1 entity instance id, we will just load the v2 hstory / guture */
    private appointmentCompApi: AppointmentCompService,
    private appointmentV1V2Service: AppointmentV1V2Service,
    private dialog: MatDialog,
    private petService: PetService,
    private appointmentHandlerService: AppointmentHandlerService,
    private notify: FloSnackbarComponent,
    private appointmentService: AppointmentService,
    private appointmentConfigHelperService: AppointmentConfigHelperService,
    private vaccinationApi: VaccinationService,
    public featuresHelperService: FeaturesHelperService,
    private calendarSetupService: CalendarSetupService

  ) { }

  loadCalendarSettings() {
    this.calendarSetupService.loadCalendarSettings(this.contextIdDto);
  }

  ngOnInit() {
    this.loadAppointments();
    this.loadCalendarSettings();
    //to be able to load vaccination count ahead
    if (this.showVaccinations) {
      this.featuresHelperService.isFeatureAllowedForce(Feature.TypeEnum.Vaccinations, this.contextIdDto).then(res => {
        this.isVaccinationFeatureAllowed = this.featuresHelperService.isFeatureAllowed(Feature.TypeEnum.Vaccinations);
        this.loadVaccinations();
      });
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.pet?.currentValue !== changes?.pet?.previousValue ||
      changes?.petId?.currentValue !== changes?.petId?.previousValue) {
      this.loadAppointments();
      this.loadVaccinations();
    }
  }

  historyModeChanged(event) {
    this.selectedAppointments = [];
    if (event === 'past') {
      if (this.v1PetEntityInstanceId) {
        this.loadHistoryV1V2();
      } else {
        this.loadHistoryV2();
      }
    } else if (event === 'future') {
      if (this.v1PetEntityInstanceId) {
        this.loadFutureV1V2();
      } else {
        this.loadFutureV2();
      }
    } else if (event === 'vaccinations') {
      this.loadVaccinations();
    }
  }

  loadAppointments() {
    this.v1PetEntityInstanceId = '';
    this.petService.get((this.petId || this.pet.id)).subscribe(response => {
      console.log('loading v1v2 appointments, got response from loading a V2 pet of ', response);
      this.pet = response;
      this.selectedAppointments = [];
      if (response.entityInstanceId) {
        this.v1PetEntityInstanceId = response.entityInstanceId;
        console.log('set ei id to ', response.entityInstanceId);
        this.loadHistoryV1V2();
        this.loadFutureV1V2();
      } else {
        this.loadHistoryV2();
        this.loadFutureV2();
      }
    });
  }

  loadVaccinations() {
    if (this.isVaccinationFeatureAllowed && this.petId && this.showVaccinations) {
      this.vaccinationApi.getVaccinationsByOwnerIdAndPetId(
        this.contextIdDto.contextId, this.petId
      ).subscribe(
        response => {
          console.log('response ', response);
          this.vaccinations = response;
        });
    }
  }

  viewInvoice(appointmentData: AppointmentDataV1V2) {
    if (appointmentData.invoiceId) {
      console.log('inside view invoiceId ', appointmentData);
      const dialogRef = this.dialog.open(ViewInvoiceV2Component, {
        maxWidth: '800px',
        width: '800px',
        data: {
          invoiceId: appointmentData.invoiceId.id,
          contextIdDto: this.contextIdDto
        },
        panelClass: ['scrollable-modal', 'invoicemodal']
      });

      dialogRef.afterClosed().subscribe((result) => {

      });
    }
  }

  sendAppointments() {
    this.appointmentCompApi.sendAppointmentsV2(this.contextIdDto.contextId,
      this.pet.petOwnerId
    ).subscribe(response => {
      if (response.errorMessage) {
        this.notify.message = response.errorMessage;
        this.notify.open();
      } else {
        this.notify.message = 'Sent Appointments to Customer';
        this.notify.open();
      }
    });
  }

  addAppointment() {
    this.appointmentHandlerService.createAppointment({
      contextIdDto: this.contextIdDto, populatePet: this.pet, callback: this.callback,
      calendarSettings: this.calendarSetupService.calendarSettings
    });
  }

  callback = (result) => {
    if (result) {
      this.loadAppointments();
      this.loadAppointmentsEvent.emit(result);
    }
  };

  openEditAppointmentModal(appointmentDataV1V2: AppointmentDataV1V2) {
    if (!this.isViewMode) {
      if (appointmentDataV1V2.appointmentId) {
        console.log('appointmentId is ', appointmentDataV1V2.appointmentId);
        this.appointmentService.loadAppointment(appointmentDataV1V2.appointmentId.id,
          this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(res => {
            this.appointmentHandlerService.editAppointment({
              contextIdDto: this.contextIdDto,
              appointment: res,
              calendarSettings: this.calendarSetupService.calendarSettings,
              callback: this.callback
            });
          });
      } else {
        this.openV1Event.emit(appointmentDataV1V2);
      }
    }
  }

  openViewAppointmentModal(appointmentDataV1V2: AppointmentDataV1V2) {
    if (!this.isViewMode) {
      if (appointmentDataV1V2.appointmentId) {
        console.log('appointmentId is ', appointmentDataV1V2.appointmentId);
        this.appointmentHandlerService.viewAppointment({
          contextIdDto: this.contextIdDto,
          appointmentId: appointmentDataV1V2.appointmentId.id,
          appointmentConfig: this.appointmentConfigHelperService.appointmentConfig,
          calendarSettings: this.calendarSetupService.calendarSettings,
          callback: this.callback
        });
      } else {
        this.openV1Event.emit(appointmentDataV1V2);
      }
    }
  }

  cancelledFilterChanged(event) {
    this.loadAppointments();
  }

  selectAppointment($event, appointmentId: AppointmentId) {
    if (appointmentId?.id) {
      const index = this.selectedAppointments.indexOf(appointmentId.id);
      if (index > -1) {
        this.selectedAppointments.splice(index, 1);
      } else {
        this.selectedAppointments.push(appointmentId.id);
      }
    }
  }

  deleteAppointments() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Bulk Delete Appointments',
        message: 'Are you sure you want to delete all selected appointments?',
        // isChange: true
      },
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.selectedAppointments.forEach((selectedAppointment, index) => {
          this.appointmentService.deleteAppointment(selectedAppointment, this.contextIdDto.contextId, this.contextIdDto.contextIdType)
            .subscribe(response => {
              if (response) {
                console.log('deleted appointment', { id: response.id });
                if (index === this.selectedAppointments.length - 1) {
                  this.loadAppointments();
                }
              }
            });
        });
      }
    });
  }

  private loadHistoryV1V2() {
    console.log('loading pet past with ', this.v1PetEntityInstanceId);
    if (this.v1PetEntityInstanceId && (this.petId || this.pet.id)) {
      if (this.showOnlyCancelled) {
        this.appointmentV1V2Service.loadPetPastV1V2Cancelled(this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          (this.petId || this.pet.id),
          this.v1PetEntityInstanceId).subscribe(res => {
            console.log('pet past v1 v2 is', res);
            this.pastAppointments = res;
          }, error => {
            // Ignore
          });
      } else {
        this.appointmentV1V2Service.loadPetPastV1V2(this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          (this.petId || this.pet.id),
          this.v1PetEntityInstanceId).subscribe(res => {
            console.log('pet past v1 v2 is', res);
            this.pastAppointments = res;
          }, error => {
            // Ignore
          });

      }
    }
  }

  private loadFutureV1V2() {
    console.log('loading pet future with ', this.v1PetEntityInstanceId);

    if (this.v1PetEntityInstanceId && (this.petId || this.pet.id)) {
      if (this.showOnlyCancelled) {
        this.appointmentV1V2Service.loadPetFutureV1V2Cancelled(this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          (this.petId || this.pet.id),
          this.v1PetEntityInstanceId).subscribe(res => {
            console.log('pet future v1 v2 is', res);
            this.futureAppointments = res;
          }, error => {
            // Ignore
          });
      } else {
        this.appointmentV1V2Service.loadPetFutureV1V2(this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          (this.petId || this.pet.id),
          this.v1PetEntityInstanceId).subscribe(res => {
            console.log('pet future v1 v2 is', res);
            this.futureAppointments = res;
          }, error => {
            // Ignore
          });

      }
    }
  }

  private loadHistoryV2() {
    console.log('loading pet past with ', (this.petId || this.pet.id));
    if ((this.petId || this.pet.id)) {
      if (this.showOnlyCancelled) {
        this.appointmentService.loadPetPastV2Cancelled(this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          (this.petId || this.pet.id), 1, 100).subscribe(res => {
            console.log('pet past v2 is', res);
            this.pastAppointments = res.contents;
          }, error => {
            // Ignore
          });
      } else {
        this.appointmentService.loadPetPastV2(this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          (this.petId || this.pet.id), 1, 100).subscribe(res => {
            console.log('pet past v2 is', res);
            this.pastAppointments = res.contents;
          }, error => {
            // Ignore
          });
      }
    }
  }

  private loadFutureV2() {
    console.log('loading pet future with ', (this.petId || this.pet.id));

    if ((this.petId || this.pet.id)) {
      if (this.showOnlyCancelled) {
        this.appointmentService.loadPetFutureV2Cancelled(this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          (this.petId || this.pet.id), 1, 100).subscribe(res => {
            console.log('pet future v2 is', res);
            this.futureAppointments = res.contents;
          }, error => {
            // Ignore
          });
      } else {
        this.appointmentService.loadPetFutureV2(this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          (this.petId || this.pet.id), 1, 100).subscribe(res => {
            console.log('pet future v2 is', res);
            this.futureAppointments = res.contents;
          }, error => {
            // Ignore
          });
      }

    }
  }
}
