import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { PaymentMethodService, PaymentService, PaymentSettingsService } from '@savvy/payment';
import { TranslateService } from '@ngx-translate/core';
import { FloSnackbarComponent } from '../../../snackbar/floSnackbar.component';
import { Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { ContextService } from '../../../context.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PaymentcompService, ContextIdDto, InvoiceId, Payment, RecordPaymentComp } from '@savvy/payment';
import { DateUtils } from '../../../dates/DateUtils';
import { UsercompService } from '@savvy/user';
import { Invoice, UserDto } from '@savvy/view-definition';
import { UserId } from '@savvy/invoice';
import { DateTimeAdapter } from '@danielmoncada/angular-datetime-picker';
import { LanguageService } from 'src/app/services/language.service';

class AddPaymentModalData {
  contextIdDto: ContextIdDto;
  invoice: Invoice;
  totalDepositRequests?: number;
  payment?: Payment;
}
@Component({
  selector: 'app-add-payment-modal',
  templateUrl: './add-payment-modal.component.html',
  styleUrls: ['./add-payment-modal.component.scss']
})
export class AddPaymentModalComponent implements OnInit, OnDestroy {
  @Input() totalDepositRequests = 0;

  model: Payment = {
    dateTime: this.dateUtils.getDateTimeAsString(new Date()),
    amount: 0
  };
  invoiceId: InvoiceId;
  contextIdDto: ContextIdDto;
  subscriptions: Subscription[] = [];
  methods: Array<string>;
  destroy$ = new Subject();
  tipsEnabled = false;
  users: Array<UserDto> = [];


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddPaymentModalData,
    private router: Router,
    private api: PaymentcompService,
    private dateUtils: DateUtils,
    private notify: FloSnackbarComponent,
    private translateService: TranslateService,
    private paymentService: PaymentService,
    private adapter: DateAdapter<any>,
    private method: PaymentMethodService,
    private languageService: LanguageService,
    public dialogRef: MatDialogRef<AddPaymentModalComponent>,
    private paymentSettingsService: PaymentSettingsService,
    private userCompApi: UsercompService,
    private dateTimeAdapter: DateTimeAdapter<any>,
  ) {
    this.totalDepositRequests = data.totalDepositRequests ? data.totalDepositRequests : 0;
  }

  ngOnInit() {
    if (this.languageService.currentLocale) {
      this.dateTimeAdapter.setLocale(this.languageService.currentLocale);
    }
    this.contextIdDto = this.data.contextIdDto;
    if (this.data.invoice) {
      this.invoiceId = this.data.invoice.invoiceId;
      this.model.amount = this.data.invoice.total - this.totalDepositRequests;
    }
    // console.log(this.model.amount);
    this.loadPaymentSettings();
    this.method.loadPaymentMethods()
      .pipe(takeUntil(this.destroy$))
      .subscribe(methods => {
        this.methods = methods;
      });
    this.getUsers();
    if (this.data.payment) {
      this.model = this.data.payment;
    }
  }

  removeTip(i: number) {
    this.model.staffTips.splice(i);
  }

  addTip() {
    if (!this.model.staffTips) {
      this.model.staffTips = [];
    }
    this.model.staffTips.push({
      tipAmount: 10
    });
  }

  loadPaymentSettings() {
    this.paymentSettingsService.loadPaymentSettings(this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(res => {
      if (res?.tipSettings) {
        this.tipsEnabled = res.tipSettings?.tipsEnabled;
      }
    });
  }

  setUserOptVal(user: UserDto): UserId {
    return { id: user.id, userContextType: UserId.UserContextTypeEnum.UserId };
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }


  savePayment() {
    this.model.ownerId = this.contextIdDto.contextId;
    this.model.invoiceId = this.invoiceId;
    this.model.amount = Number(this.model.amount) + Number(this.getTotalTips());

    const req = {} as RecordPaymentComp;
    req.contextIdDto = this.contextIdDto;
    req.payment = this.model;

    //will always update invoice calculation
    this.api.recordPaymentComp(req)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.translateService.get('Successfully added payment')
          .subscribe(val => {
            this.notify.message = val;
            this.notify.open();
            this.dialogRef.close(true);
          }, () => {
            console.log('Error occurred while adding payment');
          });
      });
  }

  getTotalTips() {
    let totalTips: number = 0;
    if (this.model.staffTips) {
      this.model.staffTips.forEach(staffTip => {
        totalTips = Number(totalTips) + Number(staffTip.tipAmount);
      });
    }
    return totalTips;
  }

  compareUserFn(user1: UserDto, user2: UserDto) {
    return user1 && user2 && user1.id === user2.id;
  }

  private getUsers() {
    this.userCompApi.getEnvUsersFilterCustomers(this.contextIdDto.contextId)
      .subscribe(response => {
        this.users = response.userDtos;
      });
  }

}
