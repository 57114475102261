import {Injectable} from '@angular/core';
import {ConfService} from '../config/conf.service';
import { ContextIdDto } from '@savvy/view-definition';

@Injectable()
export class ImageService {

    constructor() {}

    getImageUrl(reference: string, contextIdDto: ContextIdDto, publicBucket?: boolean) {
      if (!contextIdDto) {
        return '';
      }
        // console.log('inside get image url, reference is ' + reference + ' contextIdDto ' + contextIdDto);
        return ConfService.apiUrl() + '/rest/storage?contextId=' + contextIdDto.contextId +
            '&contextIdType=' + contextIdDto.contextIdType +
            '&id=' + (reference ? encodeURIComponent(reference) : reference) + (publicBucket ? '&public=true' : '');
    }
}
