<div *ngFor="let service of selectedServices; let rowIndex = index; let last = last">
  <div fxLayout="row wrap" fxLayoutGap="8px" class="w-100">
    <div fxFlex="100" fxFlex.gt-sm="26">
      <app-pet-boarding-service-selector #petServiceSelectorV2Component [contextIdDto]="contextIdDto"
        [selectedService]="service" [petId]="pet.petId" (serviceSelectionChanged)="serviceSelected($event, rowIndex)"
        class="field-center w-100">
      </app-pet-boarding-service-selector>
    </div>
    <div fxFlex="47" fxFlex.gt-sm="8">
      <mat-form-field appearance="outline" class="field-center w-100">
        <mat-label>{{'PRICE' | translate}}</mat-label>
        <input id="unitPrice{{rowIndex}}" matInput type="text" appNumberOnly [decimalPoints]="2"
          placeholder="{{'UNIT_PRICE' | translate}}" name="unitPrice{{rowIndex}}" #unitPrice{{rowIndex}}="ngModel"
          [(ngModel)]="service.unitPrice" (ngModelChange)="updateUnitPrice(service)" required>
      </mat-form-field>
    </div>
    <div fxFlex="47" fxFlex.gt-sm="11" *ngIf="taxDefs && taxDefs.length">
      <mat-form-field appearance="outline" class="field-center w-100">
        <mat-label>{{'TAX' | translate}}</mat-label>
        <mat-select id="appointment-service-tax" placeholder="{{'SELECT_TAX_RATE' | translate}}"
          [(ngModel)]="service.taxId" name="taxEntityInstanceId" #taxEntityInstanceId="ngModel"
          (ngModelChange)="updateGrossPrice(service)" [disabled]="!service?.unitPrice">
          <mat-option value="" selected>{{'NO_TAX' | translate}}</mat-option>
          <mat-option *ngFor="let tax of taxDefs" [value]="tax.id">
            {{ tax.taxName }}({{ tax.taxRate }}%)
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="47" fxFlex.gt-sm="9">
      <mat-form-field appearance="outline" class="field-center w-100">
        <mat-label>{{'TOTAL_PRICE' | translate}}</mat-label>
        <input id="appointment-gross-price" matInput type="text" appNumberOnly [decimalPoints]="2" minValue
          placeholder="{{'TOTAL_PRICE' | translate}}" name="grossPrice{{rowIndex}}" #grossPrice{{rowIndex}}="ngModel"
          [(ngModel)]="service.grossPrice" (ngModelChange)="createBoardingBookingV2SyncService.calculateTotalAmount()"
          id="grossPrice{{rowIndex}}" required>
      </mat-form-field>
    </div>
    <div fxFlex="47" fxFlex.gt-sm="16">
      <app-staff-selector class="field-center" *ngIf="service.serviceId" [contextIdDto]="contextIdDto"
        [selectedStaff]="{id: service.serviceUser?.id}" [selectedServiceId]="service.serviceId"
        (staffSelectionChanged)="staffSelectionChanged($event, rowIndex)">
      </app-staff-selector>
    </div>
    <div fxFlex="47" fxFlex.gt-sm="nogrow" fxFlexAlign="center" fxLayout="column">
      <button id="appointment-remove-service" mat-icon-button color="accent" (click)="deleteServiceNew(rowIndex)"
        class="mdc-px-2 mdc-mb-1">
        <mat-icon>cancel</mat-icon>
      </button>
    </div>
    <div fxFlex="47" fxFlex.gt-sm="100" *ngIf="timeArray && timeArray.length > 0">
      <div fxLayout="row" fxLayoutGap="8px">
        <div fxFlex="26">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{'SERVICE_DATE' | translate}} </mat-label>
            <input matInput #datePickerInput [(ngModel)]="service.startDate" id="service-start-date"
              [matDatepicker]="startDatePicker" [min]="createBoardingBookingV2SyncService?.startDate"
              [max]="createBoardingBookingV2SyncService?.endDate" required (dateChange)="startDateChanged(service)"
              (focus)="startDatePicker.open()" placeholder="Service Date">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker (closed)="closedStream($event)" [restoreFocus]="false"></mat-datepicker>
          </mat-form-field>
        </div>
        <div fxFlex="12">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{'START_TIME' | translate}}</mat-label>
            <mat-select id="appointment-service-start-time" placeholder="{{'SELECT_START_TIME' | translate}}"
              (selectionChange)="startTimeChanged($event.value, service)" [(ngModel)]="service.startTime"
              [value]="service.startTime" required>
              <mat-option *ngFor="let time of timeArray" [value]="time.actualValue">{{time.displayValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxFlex="12">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{'END_TIME' | translate}}</mat-label>
            <mat-select id="appointment-service-end-time" placeholder="{{'SELECT_END_TIME' | translate}}"
              (selectionChange)="endTimeChanged($event.value, service)" [(ngModel)]="service.endTime"
              [value]="service.endTime" required>
              <mat-option *ngFor="let time of timeArray" [value]="time.actualValue">{{time.displayValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>