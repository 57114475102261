<mat-card appearance="outlined" *ngIf="!exporting; else isExporting">
  <mat-card-header>
    <h6>{{'EXPORTING_CUSTOMER_DATA' | translate}}</h6>
  </mat-card-header>

  <ng-container *ngIf="!exportSettings?.allowAllExports; else exportForm">
    <mat-card-content>
      {{'REACH_HELP_CUSTOMER_EXPORT' | translate}}
    </mat-card-content>
    <mat-card-content>
      {{'NO_DOWNLOAD_SECURITY' | translate}}
    </mat-card-content>
  </ng-container>

  <ng-template #exportForm>
    <form #importForm="ngForm" class="d-block">
      <mat-card-content>
        <form #emailSettingsForm="ngForm" class="d-block mdc-mb-2">
          <div>
            <mat-radio-group aria-label="{{'SELECT_OPTION' | translate}}" [(ngModel)]="exportType" [ngModelOptions]="{standalone: true}">
              <mat-radio-button value="allCustomers" (change)="exportType = 'allCustomers'">
                {{'ALL_CUSTOMERS' | translate}}
              </mat-radio-button>&nbsp;&nbsp;
              <mat-radio-button value="marketingEmail" (change)="exportType = 'marketingEmail'">
                {{'MARKETING_EMAIL' | translate}}
              </mat-radio-button>&nbsp;&nbsp;
              <mat-radio-button value="emailList" (change)="exportType = 'emailList'">
                {{'EMAIL' | translate}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </form>
        <button mat-raised-button color="accent" (click)="exportData()">
          {{'EXPORT' | translate}}
        </button>
      </mat-card-content>
    </form>
  </ng-template>
</mat-card>

<ng-template #isExporting>
  <mat-card appearance="outlined" *ngIf="exporting">
    <mat-card-header class="mdc-mb-1">
      <h5>{{'EXPORTING_CUSTOMER_DATA' | translate}}</h5>
    </mat-card-header>
    <mat-card-content>
      {{'EXPORT_DURATION' | translate}}
    </mat-card-content>
    <mat-card-content>
      <mat-spinner></mat-spinner>
    </mat-card-content>
  </mat-card>
</ng-template>