import Quill from "quill";

const Embed = Quill.import("blots/embed");

class QuicklinksBlot extends Embed {
  static create(value) {
    const node = super.create(value);
    node.innerHTML = value.label;
    return QuicklinksBlot.setDataValues(node, value);
  }

  static setDataValues(element, value) {
    const domNode = element;
    Object.keys(value).forEach(key => {
      if (key !== 'cssClassName') {
        domNode.dataset[key] = value[key];
      }
    });
    if (domNode.classList && value.cssClassName && !domNode.classList.contains(value.cssClassName)) {
      domNode.classList.add("special-link");
    }
    return domNode;
  }

  static value(domNode) {
    return domNode.dataset;
  }
}

QuicklinksBlot.blotName = "Quicklinks";
QuicklinksBlot.tagName = "span";
QuicklinksBlot.className = "ql-quicklinks";

export default QuicklinksBlot;
