import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';


import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { OwlDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { AddressService } from '@savvy/address';
import { DatalistService } from '@savvy/datalist';
import { EmailmessagetemplatesService } from '@savvy/email-template';
import { LookAndFeelService } from '@savvy/look-and-feel';
import { PaymentMethodService, PaymentService } from '@savvy/payment';
import { StorageService } from '@savvy/storage';
import { SubscriptionCompService, SubscriptionDefService, SubscriptionService } from '@savvy/subscription';
import { UsersService } from '@savvy/user';
import { WorkingHoursService } from '@savvy/working-hours';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxFileDropModule } from 'ngx-file-drop';
import { AddCustomerComponent } from '../customer/customer/addCustomer.component';
import { EditorModule } from '../editor/editor.module';
import { ViewPaymentsComponent } from '../invoice/viewPayments.component';
import { ViewTipsComponent } from '../invoice/viewTips.component';
import { ZeroImageComponent } from '../logo/zeroImage.component';
import { ZeroSvgImageComponent } from '../logo/zeroSvgImage.component';
import { LocalizedDatePipe } from '../pipes/localized-date.pipe';
import { PipesModule } from '../pipes/pipes.module';
import { AddFieldDefinitionModalComponent } from './components/add-field-definition-modal/add-field-definition-modal.component';
import { AddPaymentModalComponent } from './components/add-payment-modal/add-payment-modal.component';
import { AddTipModalComponent } from './components/add-tip-model/add-tip-modal.component';
import { AddressLookupComponent } from './components/address-lookup/address-lookup.component';
import { CreateMessageModalComponent } from './components/clean-message-modal/create-message-modal.component';
import { ConfirmationEiModalComponent } from './components/confirmation-ei-modal/confirmation-ei-modal.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { EmailTemplateSelectComponent } from './components/email-template-select/email-template-select.component';
import { GoogleMapsComponent } from './components/google-maps/google-maps.component';
import { MapContentComponent } from './components/google-maps/map-content.component';
import { GooglePlacesLookupV2Component } from './components/google-places-lookup/google-places-lookup-v2.component';
import { ImageUploadPreviewComponent } from './components/image-upload-preview/image-upload-preview.component';
import { NewMessageModalComponent } from './components/new-message-modal/new-message-modal.component';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { RecurringModalComponent } from './components/recurring/recurring.modal.component';

import { SelectSubscriptionPlanComponent } from './components/subscription/selectSubscriptionPlan.component';
import { SetQuantityModalComponent } from './components/subscription/set-quantity-modal.component';
import { SubscriptionDialogComponent } from './components/subscription/subscriptionDialogComponent.component';
import { TrialExpiredPopupComponent } from './components/trial-expired/trial-expired-popup.component';
import { UploadFilesComponent } from './components/upload-files/upload-files.component';
import { TrimDirective } from './directives/inputTrim.directive';
import { SpecialCharacterDirective } from './directives/specialCharacter.directive';
import { LocationService } from './services/location.service';
import { PhoneNumberHelperService } from './services/phone-number-helper.service';
import { UserCurrencyService } from './services/userCurrency.service';
// import { Ng2TelInputModule } from 'ng2-tel-input';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgWizardConfig, NgWizardModule, THEME } from 'ng-wizard-v2';
import { ColorPickerModule } from 'ngx-color-picker';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxPhotoEditorModule } from 'ngx-photo-editor';
import { DirectivesModule } from 'src/app/core/directives/directives.module';
import { SafePipeModule } from 'src/app/core/safeHtml.pipe';
import { TimelineCalendarComponent } from 'src/app/core/timeline-calendar/timeline-calendar.component';
import { ImageGridComponent } from '../images/imageGrid.component';
import { ConfirmationPaymentComponent } from './components/confirmation-payment-modal/confirmation-payment.component';
import { CreateProductComponent } from './components/setup/products/create-product/create-product.component';
import { ProductsComponent } from './components/setup/products/products.component';
import { CreateServiceComponent } from './components/setup/services/create-service/create-service.component';
import { ServicesComponent } from './components/setup/services/services.component';
import { SetupSubscriptionsComponent } from './components/setup/setup-subscriptions/setup-subscriptions.component';
import { WizardPrefsComponent } from './components/setup/wizard-prefs/wizard-prefs.component';
import { ConfirmAnnualPlanComponent } from './components/subscription/confirm-annual-plan/confirm-annual-plan.component';
import { TrialExpiredComponent } from './components/subscription/trial-expired/trial-expired.component';
import { ConfirmUpdateSubscriptionModalComponent } from
  // eslint-disable-next-line max-len
  './components/subscription/update-subscription-in-trial-modal/confirm-update-subscription-modal/confirm-update-subscription-modal.component';
import { WorkflowBarComponent } from './components/workflow-bar/workflowBar.component';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { PetStayCalendarComponent } from 'src/app/core/pet-stay-calendar/pet-stay-calendar.component';
import { PetStaySchedulerComponent } from 'src/app/core/pet-stay-calendar/pet-stay-scheduler/pet-stay-scheduler.component';
import { ViewPetHistoryComponent } from '../appointment/pet/view-pet-history/view-pet-history.component';
import { ViewV1PetHistoryComponent } from '../appointment/pet/view-v1-pet-history/view-v1-pet-history.component';
import { ViewV1V2PetHistoryComponent } from '../appointment/pet/view-v1-v2-pet-history/view-v1-v2-pet-history.component';
import { ViewAppointmentContractsComponent } from '../appointment/view-appointment-contracts/view-appointment-contracts.component';
import { ViewCheckinFormTemplatesComponent } from '../appointment/view-checkin-forms/view-checkin-form-templates/view-checkin-form-templates.component';
import { ViewCheckinFormsComponent } from '../appointment/view-checkin-forms/view-checkin-forms.component';
import { CustomerSelectorV2Component } from '../appointments-v2/create-appointment-v2/customer-selector-v2/customer-selector-v2.component';
import { NewCustomerSelectorV2Component } from '../appointments-v2/create-appointment-v2/new-customer-selector-v2/new-customer-selector-v2.component';
import { AddNewPetV2Component } from '../appointments-v2/create-pet-appointment-v2/add-new-pet-v2/add-new-pet-v2.component';
import { ConfirmPetAppointmentV2Component } from '../appointments-v2/create-pet-appointment-v2/confirm-pet-appointment-v2/confirm-pet-appointment-v2.component';
import { CreatePetExistingCustomerComponent } from '../appointments-v2/create-pet-appointment-v2/create-pet-existing-customer/create-pet-existing-customer.component';
import { NewPetCustomerSelectorV2Component } from '../appointments-v2/create-pet-appointment-v2/new-pet-customer-selector-v2/new-pet-customer-selector-v2.component';
import { AppointmentV2IconsComponent } from '../appointments-v2/shared/appointment-v2-icons/appointment-v2-icons.component';
import { CustomerStatsComponent } from '../appointments-v2/shared/customer-stats/customer-stats.component';
import { LocationSelectorV2Component } from '../appointments-v2/shared/location-selector-v2/location-selector-v2.component';
import { PaymentComponent } from '../appointments-v2/shared/payment/payment.component';
import { DayViewSchedulerComponent } from '../calendar/calendarDayViewScheduler.component';
import { AddContractComponent } from '../contract/addContract/addContract.component';
import { SendContractComponent } from '../contract/sendContract/sendContract.component';
import { ViewContractComponent } from '../contract/viewContract/viewContract.component';
import { CustomerSelectorComponent } from '../customer/customer-selector/customerSelector.component';
import { CustomerPetDetailsComponent } from '../customer/view-customer-v2/customer-pet-details/customer-pet-details.component';
import { ViewCustomerAddressComponent } from '../customer/view-customer-v2/view-customer-address/view-customer-address.component';
import { AddEditDatalistModalComponent } from '../datalist/add-edit-datalist-modal/add-edit-datalist-modal.component';
import {
  ExistingCustomerSearchSelectorComponent
} from '../element/create-appointment/existingCustomerSearchSelector.component';
import { ExistingCustomerSelectorComponent } from '../element/create-appointment/existingCustomerSelector.component';
import { NewCustomerSelectorComponent } from '../element/create-appointment/newCustomerSelector.component';
import { ViewVaccinationsPanelComponent } from '../element/viewVaccinationsPanel.component';
import { FloMaterialModule } from '../flo.material.module';
import { ViewPaymentRequestsComponent } from '../invoice/view-payment-requests/view-payment-requests.component';
import { NotificationV2Component } from '../notifications/notification-v2/notification-v2.component';
import { PaymentDepositRequestComponent } from '../payment/payment-scheduler/payment-deposit-request/payment-deposit-request.component';
import { PaymentSchedulerComponent } from '../payment/payment-scheduler/payment-scheduler.component';
import { MotoComponent } from '../paymentSettings/savvy-pay/moto.component';
import { NoShowPolicyComponent } from '../paymentSettings/savvy-pay/no-show-policy.component';
import { ListPetRunComponent } from '../petdaycare/list-pet-run/list-pet-run.component';
import { ListPetTypeComponent } from '../petdaycare/list-pet-type/list-pet-type.component';
import { PetTypeSelectorComponent } from '../pets/pet-type-selector/pet-type-selector.component';
import {
  CustomerContractsV2Component
} from '../pets/view-pet-details-v2/pet-description/customer-contracts-v2/customer-contracts-v2.component';
import { UpcomingAppointmentComponent } from '../pets/view-pet-details-v2/upcoming-appointment/upcoming-appointment.component';
import { EditWorkingHoursComponent } from '../user/edit-working-hours.component';
import { ViewServiceGroupsComponent } from '../welcome-to-savvy/manage-bookings/view-service-groups/view-service-groups.component';
import { ViewServicesComponent } from '../welcome-to-savvy/manage-bookings/view-services/view-services.component';
import {
  CreateStoreComponent
} from '../welcome-to-savvy/payment-setup/setup-savvy-pay/order-card-machine/create-store/create-store.component';
import {
  CreateCardMachineRequestComponent
} from '../welcome-to-savvy/payment-setup/setup-savvy-pay/order-card-machine/list-card-machines/create-card-machine-request/create-card-machine-request.component';
import {
  ListCardMachinesComponent
} from '../welcome-to-savvy/payment-setup/setup-savvy-pay/order-card-machine/list-card-machines/list-card-machines.component';
import {
  OrderCardMachineComponent
} from '../welcome-to-savvy/payment-setup/setup-savvy-pay/order-card-machine/order-card-machine.component';
import {
  SavvyPaySetupRulesModalComponent
} from '../welcome-to-savvy/payment-setup/setup-savvy-pay/savvy-pay-setup-rules-modal/savvy-pay-setup-rules-modal.component';
import { SetupSavvyPayComponent } from '../welcome-to-savvy/payment-setup/setup-savvy-pay/setup-savvy-pay.component';
import { VerifyAccountComponent } from '../welcome-to-savvy/payment-setup/setup-savvy-pay/verify-account/verify-account.component';
import { OrgBusinessHoursComponent } from '../welcome-to-savvy/savvy-options/org-business-hours/org-business-hours.component';
import { AddLocationComponent } from '../welcome-to-savvy/savvy-options/view-staff-members-location-wise/add-location/add-location.component';
import { StaffWorkingHoursComponent } from '../welcome-to-savvy/savvy-options/view-staff-members/staff-working-hours/staff-working-hours.component';
import { ViewColorGradesComponent } from '../welcome-to-savvy/savvy-options/view-staff-members/view-color-grades/view-color-grades.component';
import { PayViaCardMachineComponent } from './components/card-machine/payViaCardMachine.component';
import {
  OverdueGroomMessageModalComponent
} from './components/new-overdue-groom-message-modal/overdue-groom-message-modal.component';
import { OnboardCustomerComponent } from './components/onboard-customer/onboardCustomer.component';
import { PendingAmountComponent } from './components/pending-amount/pending-amount.component';
import { ViewPlanComponent } from './components/plan/view-plan.component';
import { SavvyPayChangeCardComponent } from './components/savvy-pay/savvy-pay-change-card.component';
import { SavvyPayComponent } from './components/savvy-pay/savvy-pay.component';
import { CustomerOnboardingComponent } from './components/setup/customer-onboarding/customer-onboarding.component';
import { CreatePackageComponent } from './components/setup/packages/create-package/create-package.component';
import { PackagesComponent } from './components/setup/packages/packages.component';
import { VaccinationsComponent } from './components/setup/vaccinations/vaccinations.component';
import { ConfirmationSubscriptionModalComponent } from './components/subscription/confirm-modal/confirmation-subscription-modal.component';
import { ConfirmChangeSubscriptionPlanComponent } from './components/subscription/confirmChangeSubscriptionPlan.component';
import { DebounceOnNgmodelDirective } from './directives/debounce-on-ngmodel.directive';
import { SharedInvoiceModule } from './shared-invoice/shared-invoice.module';

import { CdkMenuModule } from '@angular/cdk/menu';
import { GoogleMapsModule } from '@angular/google-maps';
import { SignaturePadModule } from "angular-signature-pad-v2";
import { NgxMatInputTelComponent } from 'ngx-mat-input-tel';
import { ViewPetVaccinationsComponent } from "../appointment/pet/view-v1-v2-pet-history/view-pet-vaccinations/view-pet-vaccinations.component";
import { AppointmentAuditLogsComponent } from '../appointments/appointment-audit-logs/appointment-audit-logs.component';
import { DaySkipperComponent } from '../calendar/day-skipper/day-skipper.component';
import { InviteCustomerComponent } from '../customer/invite-customer/invite-customer.component';
import {
  CustomerFlagsPanelComponent
} from "../customer/view-customer-v2/customer-flags-panel/customer-flags-panel.component";
import {
  CheckInFormNotificationComponent
} from "../notifications/check-in-form-notification/checkInFormNotification.component";
import {
  ContactFormNotificationComponent
} from "../notifications/contact-form-notification/contactFormNotification.component";
import {
  ContractSignedNotificationComponent
} from "../notifications/contract-signed-notification/contractSignedNotification.component";
import { InviteCustomerNotificationComponent } from '../notifications/invite-customer-notification/invite-customer-notification.component';
import { OnboardingNotificationComponent } from "../notifications/onboarding-notification/onboardingNotification.component";
import {
  SavvyMessageReceivedNotificationComponent
} from "../notifications/savvy-message-received-notification/savvyMessageReceivedNotification.component";
import { SmsReplyNotificationComponent } from "../notifications/sms-reply-notification/smsReplyNotification.component";
import { SavvyPayContractComponent } from "../welcome-to-savvy/payment-setup/setup-savvy-pay/savvy-pay-contract/savvy-pay-contract.component";
import { SavvyPayFaqComponent } from "../welcome-to-savvy/payment-setup/setup-savvy-pay/savvy-pay-faq/savvy-pay-faq.component";
import {
  SavvyPaySetupRulesCompanyComponent
} from "../welcome-to-savvy/payment-setup/setup-savvy-pay/savvy-pay-setup-rules-modal/savvy-pay-setup-rules-company.component";
import {
  SavvyPaySetupRulesRequiredDocComponent
} from "../welcome-to-savvy/payment-setup/setup-savvy-pay/savvy-pay-setup-rules-modal/savvy-pay-setup-rules-required-doc.component";
import {
  SavvyPaySetupRulesSection2Component
} from "../welcome-to-savvy/payment-setup/setup-savvy-pay/savvy-pay-setup-rules-modal/savvy-pay-setup-rules-section-2.component";
import {
  SavvyPaySetupRulesSection3Component
} from "../welcome-to-savvy/payment-setup/setup-savvy-pay/savvy-pay-setup-rules-modal/savvy-pay-setup-rules-section-3.component";
import { OrgBusinessHoursForPetStayComponent } from '../welcome-to-savvy/savvy-options/org-business-hours-for-pet-stay/org-business-hours-for-pet-stay.component';
import { AddEditPetVaccinationComponent } from "./components/add-edit-pet-vaccination/add-edit-pet-vaccination.component";
import { AddExpenseModalComponent } from "./components/add-expense-modal/add-expense-modal.component";
import { FlagFilterComponent } from './components/flag-filter/flag-filter.component';
import { StaffSelectorComponent } from './components/staff-selector/staff-selector.component';
import { ChangeCardComponent } from './components/subscription/change-card/change-card.component';
import {
  ReleaseNotesNotificationComponent
} from "../notifications/release-notes-notification/releaseNotesNotification.component";
import { ViewCustomerWorkflowStatsPanelComponent } from './components/view-customer-workflow-stats/view-customer-workflow-stats-panel.component';
import {
  SetupSavvyPayV2Component
} from "../welcome-to-savvy/payment-setup/setup-savvy-pay-v2/setup-savvy-pay-v2.component";
import {
  LegalEntityStatusDetailsComponent
} from "../welcome-to-savvy/payment-setup/setup-savvy-pay-v2/legal-entity-status-details.component";
import {
  SupportedPaymentMethodsComponent
} from "../welcome-to-savvy/payment-setup/setup-savvy-pay-v2/supported-payment-methods.component";
import {
  SavvyPayContractV2Component
} from "../welcome-to-savvy/payment-setup/setup-savvy-pay-v2/savvy-pay-contract/savvy-pay-contract-v2.component";
import {
  LegalEntityDetailsComponent
} from "../welcome-to-savvy/payment-setup/setup-savvy-pay-v2/legal-entity-details.component";
import {
  OrderCardMachineV2Component
} from "../welcome-to-savvy/payment-setup/setup-savvy-pay-v2/order-card-machine-v2/order-card-machine-v2.component";
import {
  ListCardMachinesV2Component
} from "../welcome-to-savvy/payment-setup/setup-savvy-pay-v2/order-card-machine-v2/list-card-machines/list-card-machines-v2.component";
import {
  CreateCardMachineRequestV2Component
} from "../welcome-to-savvy/payment-setup/setup-savvy-pay-v2/order-card-machine-v2/list-card-machines/create-card-machine-request/create-card-machine-request-v2.component";
import {
  CreateStoreV2Component
} from "../welcome-to-savvy/payment-setup/setup-savvy-pay-v2/order-card-machine-v2/create-store/create-store-v2.component";
import {PastDueComponent} from "./components/subscription/past-due/past-due.component";
import {
  SetupStepFeesComponent
} from "../welcome-to-savvy/payment-setup/setup-savvy-pay-v2/setup-step-fees/setup-step-fees.component";
import {
  SetupStepAccountTypeComponent
} from "../welcome-to-savvy/payment-setup/setup-savvy-pay-v2/setup-step-accout-type/setup-step-account-type.component";
import {
  SetupStepStoreComponent
} from "../welcome-to-savvy/payment-setup/setup-savvy-pay-v2/setup-step-store/setup-step-store.component";


const ngWizardConfig: NgWizardConfig = {
  theme: THEME.default
};

@NgModule({
  imports: [
    NgxMatInputTelComponent,
    NgxPhotoEditorModule,
    SafePipeModule,
    CommonModule,
    EditorModule,
    OwlDateTimeModule,
    NgxDatatableModule,
    PipesModule,
    FlexLayoutModule,
    TranslateModule,
    NgxFileDropModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgWizardModule.forRoot(ngWizardConfig),
    DirectivesModule,
    ColorPickerModule,
    DragDropModule,
    InfiniteScrollModule,
    ScrollingModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    NgxMatSelectSearchModule,
    FloMaterialModule,
    SharedInvoiceModule,
    GoogleMapsModule,
    SignaturePadModule,
    CdkMenuModule
  ],
  declarations: [
    ZeroImageComponent,
    ZeroSvgImageComponent,
    EmailTemplateSelectComponent,
    ImageUploadPreviewComponent,
    TrialExpiredPopupComponent,
    ConfirmationSubscriptionModalComponent,
    GoogleMapsComponent,
    MapContentComponent,
    SelectSubscriptionPlanComponent,
    ConfirmChangeSubscriptionPlanComponent,
    ChangeCardComponent,
    PastDueComponent,
    TrialExpiredComponent,
    SetQuantityModalComponent,
    SubscriptionDialogComponent,
    TrimDirective,
    SpecialCharacterDirective,
    PhoneInputComponent,
    GooglePlacesLookupV2Component,
    AddCustomerComponent,
    InviteCustomerComponent,
    OnboardCustomerComponent,
    AddressLookupComponent,
    RecurringModalComponent,
    ConfirmationModalComponent,
    ConfirmationPaymentComponent,
    ConfirmationEiModalComponent,
    AddPaymentModalComponent,
    AddExpenseModalComponent,
    AddTipModalComponent,
    ViewPaymentsComponent,
    ViewTipsComponent,
    CreateMessageModalComponent,
    UploadFilesComponent,
    NewMessageModalComponent,
    OverdueGroomMessageModalComponent,
    AddFieldDefinitionModalComponent,
    ServicesComponent,
    PackagesComponent,
    ProductsComponent,
    SetupSubscriptionsComponent,
    CreateServiceComponent,
    CreatePackageComponent,
    CreateProductComponent,
    ImageGridComponent,
    CustomerFlagsPanelComponent,
    WizardPrefsComponent,
    VaccinationsComponent,
    WorkflowBarComponent,
    SavvyPayComponent,
    SavvyPayChangeCardComponent,
    ViewPlanComponent,
    TimelineCalendarComponent,
    ConfirmUpdateSubscriptionModalComponent,
    ConfirmAnnualPlanComponent,
    PetStayCalendarComponent,
    PetStaySchedulerComponent,
    PaymentSchedulerComponent,
    PaymentDepositRequestComponent,
    PendingAmountComponent,
    ViewPaymentRequestsComponent,
    ExistingCustomerSelectorComponent,
    ExistingCustomerSearchSelectorComponent,
    NewCustomerSelectorComponent,
    AddContractComponent,
    ViewContractComponent,
    SendContractComponent,
    CustomerSelectorV2Component,
    CustomerOnboardingComponent,
    ViewPetHistoryComponent,
    ViewV1PetHistoryComponent,
    ViewV1V2PetHistoryComponent,
    ViewVaccinationsPanelComponent,
    ViewPetVaccinationsComponent,
    NewCustomerSelectorV2Component,
    NotificationV2Component,
    OnboardingNotificationComponent,
    InviteCustomerNotificationComponent,
    CheckInFormNotificationComponent,
    ContractSignedNotificationComponent,
    ReleaseNotesNotificationComponent,
    ContactFormNotificationComponent,
    SmsReplyNotificationComponent,
    SavvyMessageReceivedNotificationComponent,
    CustomerStatsComponent,
    CustomerContractsV2Component,
    ViewAppointmentContractsComponent,
    UpcomingAppointmentComponent,
    ViewCustomerAddressComponent,
    DayViewSchedulerComponent,
    AddEditDatalistModalComponent,
    PayViaCardMachineComponent,
    SetupSavvyPayComponent,
    SetupSavvyPayV2Component,
    SetupStepFeesComponent,
    SetupStepAccountTypeComponent,
    SetupStepStoreComponent,
    OrderCardMachineV2Component,
    ListCardMachinesV2Component,
    CreateCardMachineRequestV2Component,
    CreateStoreV2Component,
    LegalEntityStatusDetailsComponent,
    LegalEntityDetailsComponent,
    SupportedPaymentMethodsComponent,
    SavvyPaySetupRulesModalComponent,
    SavvyPaySetupRulesSection2Component,
    SavvyPaySetupRulesSection3Component,
    SavvyPaySetupRulesCompanyComponent,
    SavvyPaySetupRulesRequiredDocComponent,
    VerifyAccountComponent,
    OrderCardMachineComponent,
    CreateStoreComponent,
    SavvyPayContractComponent,
    SavvyPayContractV2Component,
    SavvyPayFaqComponent,
    ListCardMachinesComponent,
    CreateCardMachineRequestComponent,
    NoShowPolicyComponent,
    MotoComponent,
    EditWorkingHoursComponent,
    ListPetTypeComponent,
    ViewColorGradesComponent,
    AddLocationComponent,
    OrgBusinessHoursComponent,
    OrgBusinessHoursForPetStayComponent,
    ViewServicesComponent,
    ViewServiceGroupsComponent,
    DebounceOnNgmodelDirective,
    StaffWorkingHoursComponent,
    AddNewPetV2Component,
    PetTypeSelectorComponent,
    CustomerPetDetailsComponent,
    LocationSelectorV2Component,
    CreatePetExistingCustomerComponent,
    AppointmentV2IconsComponent,
    PaymentComponent,
    ConfirmPetAppointmentV2Component,
    NewPetCustomerSelectorV2Component,
    CustomerSelectorComponent,
    ViewCheckinFormsComponent,
    ViewCheckinFormTemplatesComponent,
    AddEditPetVaccinationComponent,
    ListPetRunComponent,
    DaySkipperComponent,
    FlagFilterComponent,
    StaffSelectorComponent,
    AppointmentAuditLogsComponent,
    ViewCustomerWorkflowStatsPanelComponent
  ],
  exports: [
    ZeroImageComponent,
    ZeroSvgImageComponent,
    EmailTemplateSelectComponent,
    ImageUploadPreviewComponent,
    TrialExpiredPopupComponent,
    GoogleMapsComponent,
    MapContentComponent,
    SelectSubscriptionPlanComponent,
    ConfirmChangeSubscriptionPlanComponent,
    TrialExpiredComponent,
    SubscriptionDialogComponent,
    ChangeCardComponent,
    PastDueComponent,
    TrimDirective,
    SpecialCharacterDirective,
    PhoneInputComponent,
    GooglePlacesLookupV2Component,
    AddCustomerComponent,
    CustomerFlagsPanelComponent,
    InviteCustomerComponent,
    OnboardCustomerComponent,
    RecurringModalComponent,
    AddPaymentModalComponent,
    AddExpenseModalComponent,
    AddTipModalComponent,
    AddressLookupComponent,
    ViewPaymentsComponent,
    ViewTipsComponent,
    UploadFilesComponent,
    CreateMessageModalComponent,
    NewMessageModalComponent,
    OverdueGroomMessageModalComponent,
    AddFieldDefinitionModalComponent,
    ServicesComponent,
    PackagesComponent,
    ProductsComponent,
    CreateServiceComponent,
    CreatePackageComponent,
    CreateProductComponent,
    WizardPrefsComponent,
    VaccinationsComponent,
    ImageGridComponent,
    WorkflowBarComponent,
    SavvyPayComponent,
    SavvyPayChangeCardComponent,
    ViewPlanComponent,
    TimelineCalendarComponent,
    ConfirmUpdateSubscriptionModalComponent,
    ConfirmAnnualPlanComponent,
    PetStayCalendarComponent,
    PetStaySchedulerComponent,
    PaymentSchedulerComponent,
    PaymentDepositRequestComponent,
    PendingAmountComponent,
    ViewPaymentRequestsComponent,
    ExistingCustomerSelectorComponent,
    ExistingCustomerSearchSelectorComponent,
    NewCustomerSelectorComponent,
    AddContractComponent,
    ViewContractComponent,
    SendContractComponent,
    CustomerSelectorV2Component,
    CustomerOnboardingComponent,
    ViewPetHistoryComponent,
    ViewV1V2PetHistoryComponent,
    ViewVaccinationsPanelComponent,
    ViewPetVaccinationsComponent,
    NewCustomerSelectorV2Component,
    NotificationV2Component,
    OnboardingNotificationComponent,
    InviteCustomerNotificationComponent,
    CheckInFormNotificationComponent,
    ContractSignedNotificationComponent,
    ReleaseNotesNotificationComponent,
    ContactFormNotificationComponent,
    SmsReplyNotificationComponent,
    SavvyMessageReceivedNotificationComponent,
    CustomerStatsComponent,
    PipesModule,
    CustomerContractsV2Component,
    ViewAppointmentContractsComponent,
    UpcomingAppointmentComponent,
    ViewCustomerAddressComponent,
    DayViewSchedulerComponent,
    AddEditDatalistModalComponent,
    SetupSavvyPayComponent,
    SetupSavvyPayV2Component,
    SetupStepFeesComponent,
    SetupStepAccountTypeComponent,
    SetupStepStoreComponent,
    OrderCardMachineV2Component,
    ListCardMachinesV2Component,
    CreateCardMachineRequestV2Component,
    CreateStoreV2Component,
    LegalEntityStatusDetailsComponent,
    LegalEntityDetailsComponent,
    SavvyPaySetupRulesModalComponent,
    SavvyPaySetupRulesSection2Component,
    SavvyPaySetupRulesSection3Component,
    SavvyPaySetupRulesRequiredDocComponent,
    SavvyPaySetupRulesCompanyComponent,
    VerifyAccountComponent,
    OrderCardMachineComponent,
    CreateStoreComponent,
    SavvyPayContractComponent,
    SavvyPayContractV2Component,
    SavvyPayFaqComponent,
    ListCardMachinesComponent,
    CreateCardMachineRequestComponent,
    NoShowPolicyComponent,
    MotoComponent,
    EditWorkingHoursComponent,
    ListPetTypeComponent,
    ViewColorGradesComponent,
    AddLocationComponent,
    OrgBusinessHoursComponent,
    OrgBusinessHoursForPetStayComponent,
    ViewServicesComponent,
    ViewServiceGroupsComponent,
    DebounceOnNgmodelDirective,
    StaffWorkingHoursComponent,
    AddNewPetV2Component,
    PetTypeSelectorComponent,
    CustomerPetDetailsComponent,
    LocationSelectorV2Component,
    DirectivesModule,
    AppointmentV2IconsComponent,
    PaymentComponent,
    ConfirmPetAppointmentV2Component,
    NewPetCustomerSelectorV2Component,
    CreatePetExistingCustomerComponent,
    CustomerSelectorComponent,
    SharedInvoiceModule,
    ViewCheckinFormsComponent,
    AddEditPetVaccinationComponent,
    ListPetRunComponent,
    DaySkipperComponent,
    InfiniteScrollModule,
    FlagFilterComponent,
    GoogleMapsModule,
    StaffSelectorComponent,
    AppointmentAuditLogsComponent,
    ViewCustomerWorkflowStatsPanelComponent
  ],
  providers: [
    AddressService,
    EmailmessagetemplatesService,
    SubscriptionService,
    SubscriptionDefService,
    UsersService,
    SubscriptionCompService,
    LocationService,
    PhoneNumberHelperService,
    UserCurrencyService,
    StorageService,
    PaymentService,
    PaymentMethodService,
    LocalizedDatePipe,
    WorkingHoursService,
    LookAndFeelService,
    DatalistService,
  ]
})

export class SharedModule {
}
