<div [formGroup]="form" fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">

<div fxFlex="49">
  <mat-form-field appearance="outline" class="w-100">
    <mat-label>{{dateTimeDefinition.label | translate}}</mat-label>

    <input matInput [formControlName]="elementInstanceDto.instanceId" [matDatepicker]="picker"
           [required]="dateTimeDefinition.required"
           (focus)="picker.open()" and (click)="picker.open()"
           [placeholder]="dateTimeDefinition.placeholder">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>

    <mat-error *ngIf="!form.controls[elementInstanceDto.instanceId].hasError('required') && form.controls[elementInstanceDto.instanceId].invalid">
      {{dateTimeDefinition.label + ' is' | translate}} <strong>{{'invalid' | translate}}</strong>
    </mat-error>
    <mat-error *ngIf="form.controls[elementInstanceDto.instanceId].hasError('required')">
      {{dateTimeDefinition.label + ' is' | translate}} <strong>{{'required' | translate}}</strong>
    </mat-error>
  </mat-form-field>
</div>

<div fxFlex="49">
  <mat-form-field appearance="outline" class="w-100" *ngIf="timeArray && timeArray.length > 0">
    <mat-select [formControlName]="elementInstanceDto.instanceId + 'time'"
                required>

      <mat-option *ngFor="let time of timeArray"
                  [value]="time.actualValue">{{time.displayValue}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
</div>
