import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContextService } from '../context.service';
import { Router } from '@angular/router';
import { ContextIdDto, PaymentcompService } from '@savvy/payment';
import { NavChangeService } from '../element/NavChangeService';
import { EventBusService } from '../element/EventBusService';
import { Page } from '../shared/model/page';
import { MenuDefinition } from '@savvy/ui';
import { Subscription } from 'rxjs';
import {DailyPetSummaryDto, Pet } from '@savvy/pet';
import * as moment from 'moment';
import { DateUtils } from '../dates/DateUtils';
import { DataListDto, DatalistService } from '@savvy/datalist';
import { DataItemDto } from '@savvy/datalist/model/dataItemDto';
import { AppointmentNotesComponent } from './appointmentNotes.component';
import { MatDialog } from '@angular/material/dialog';
import { PetHistoryComponent } from './petHistory.component';
import { PetNotesComponent } from './petNotes.component';
import { Appointment, AppointmentService } from '@savvy/appointment';
import { PetService } from '@savvy/pet';
import MenuItemTypeEnum = MenuDefinition.MenuItemTypeEnum;
import { AppointmentNotesV2Component } from './appointmentNotesV2.component';
import { ExportToExcelService } from '../shared/services/export-to-excel.service';
import { DatePipe } from '@angular/common';
import {FloSnackbarComponent} from "../snackbar/floSnackbar.component";

@Component({
  selector: 'app-daily-pets',
  templateUrl: './dailyPets.component.html',
  styleUrls: ['./dailyPets.component.scss'],
})
export class DailyPetsComponent implements OnInit, OnDestroy {

  contextIdDto: ContextIdDto;
  subscriptions: Subscription[] = [];

  // V2
  appointments: Array<Appointment>;

  // V1
  dailyPetSummaryDtos: Array<DailyPetSummaryDto>;


  selected: Appointment[] = [];
  eventBus = new EventBusService();
  page = new Page();

  breeds: DataListDto;

  viewDate = new Date();

  loading = false;

  pets: Array<Pet> = [];

  constructor(
    private router: Router,
    private petService: PetService,
    private appointmentService: AppointmentService,
    private exportToExcelService: ExportToExcelService,
    private dateUtils: DateUtils,
    private dialog: MatDialog,
    private datalistApi: DatalistService,
    private paymentCompApi: PaymentcompService,
    private navChange: NavChangeService,
    private contextService: ContextService,
    private datePipe: DatePipe,
    private notify: FloSnackbarComponent,
  ) {
  }

  ngOnInit() {
    this.page.size = 10;
    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
        this.navChange.add(MenuItemTypeEnum.DailyPets);
        this.loadBreeds();
        this.setPage({ offset: 0, size: this.page.size });
      }
    }));
    document.onkeydown = (e) => {
      if (e.ctrlKey == true && e.key == 'p') {
        e.preventDefault(); // to override browser's default save page feature
        this.printAppointments();
      }
    };
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getBreed(breedStr: string) {
    if (this.breeds) {
      //  this.books.filter(
      //   book => book.store_id === this.store.id);
      const petBreed: DataItemDto[] = this.breeds.dataItems.filter(breed => breed.name === breedStr);
      if (petBreed && petBreed.length > 0) {
        return petBreed[0].label;
      }
    }
    return 'Unknown Breed';
  }

  loadBreeds() {
    this.datalistApi.getDataListByName(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      'Breed').subscribe(response => {
        this.breeds = response;
      });
  }

  setPage(pageInfo) {
    console.log('pageInfo is ' + pageInfo);
    this.page.pageNumber = pageInfo.offset;
    this.listInstances();
  }

  listInstances() {
    this.loading = true;

    this.petService.loadDailyPets(this.dateUtils.getDateAsStringV2(this.viewDate)).subscribe(response => {
        this.dailyPetSummaryDtos = response.dailyPetSummaryDtos;

      });

    this.appointmentService.loadAppointmentsInDayRangeV2(this.contextIdDto.contextId,
      this.dateUtils.getDateAsStringV2(this.viewDate),
      this.dateUtils.getDateAsStringV2(this.viewDate)).subscribe(response => {

        this.loading = false;
        this.appointments = response;
        this.loadPets();

      });
  }

  loadPets() {
    if (this.appointments) {
      this.appointments.forEach(appointment => {
        if (appointment.appointmentServiceRows) {
          appointment.appointmentServiceRows.forEach(appointmentServiceRow => {
            if (appointmentServiceRow.eiId) {
              this.petService.get(appointmentServiceRow.eiId).subscribe(response => {
                if (response) {
                  //add this pet to the list
                  this.pets[appointmentServiceRow.eiId] = response;
                }
              });
            }
          });
        }
      });
    }
  }

  showImages(pet: Pet) {

    // petDto.images
    /**
            TODO:

     */

    /*const dialogRef = this.dialog.open(ImageGalleryComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        petId: petId
      }
    });

    dialogRef.afterClosed()
      .subscribe((result: string) => {
        //this.listInstances();
      });*/
  }

  loadPetHistory(petId: string) {
    const dialogRef = this.dialog.open(PetHistoryComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        petId
      },
      width: '90%',
      height: '90%'
    });

    dialogRef.afterClosed()
      .subscribe((result: string) => {
        //this.listInstances();
      });
  }

  recordV2Notes(appointmentId: string) {

    const dialogRef = this.dialog.open(AppointmentNotesV2Component, {
      data: {
        contextIdDto: this.contextIdDto,
        appointmentId
      },
      width: '90%',
      height: '90%'
    });

    dialogRef.afterClosed()
      .subscribe((result: string) => {
        this.listInstances();
      });
  }

  recordV1Notes(appointmentId: string) {

    const dialogRef = this.dialog.open(AppointmentNotesComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        appointmentId
      },
      width: '90%',
      height: '90%'
    });

    dialogRef.afterClosed()
      .subscribe((result: string) => {
        this.listInstances();
      });
  }

  recordPetNotes(petId: string) {
    const dialogRef = this.dialog.open(PetNotesComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        petId
      },
      width: '90%',
      height: '90%'
    });

    dialogRef.afterClosed()
      .subscribe((result: string) => {
        this.listInstances();
      });
  }

  next() {
    this.viewDate = moment(this.viewDate).add(1, 'd').toDate();
    this.listInstances();
  }

  prev() {
    this.viewDate = moment(this.viewDate).add(-1, 'd').toDate();
    this.listInstances();
  }

  viewDateChange() {
    this.listInstances();
  }

  onSelect(event) {
    console.log('Event: select', event, this.selected);
  }

  setToday() {
    this.viewDate = new Date();
    this.listInstances();
  }

  createFlag(flagId: string, pet: Pet) {
    if (pet && !pet.flags) {
      pet.flags = new Array();
    }
    pet.flags.push(flagId);
    this.updatePet(pet);
  }

  deleteFlag(flagId: string, pet: Pet) {
    if (pet.flags) {
      pet.flags.forEach((item, index) => {
        if (item === flagId) {
          pet.flags.splice(index, 1);
          this.updatePet(pet);
        }
      });
    }
  }

  updatePet(pet: Pet) {
    this.petService.updatePet(pet).subscribe(res => {
      if (res) {
        //add this pet to the list
        this.pets[res.id] = res;
        this.notify.message = 'Pet flags has been updated';
        this.notify.open();
      }
    });
  }

  exportAppointments() {
    if (this.appointments?.length) {
      const appointments: any[] = [];
      const header = ['Customer Name'];
      for (const appointment of this.appointments) {
        let customerFlags = '';
        if (appointment.customerFlags?.length) {
          for (const [index, customerFlag] of appointment.customerFlags.entries()) {
            customerFlags += `${customerFlag.name}`;
            if (appointment.customerFlags?.length - 1 !== index) {
              customerFlags += ',';
            }
          }
        }
        const apt = {
          'Customer Name': appointment?.customerName,
          'Customer Flags': customerFlags,
          'Appointment Type': appointment?.appointmentType,
          'Start Date': this.dateUtils.getDateAsStringV2(appointment?.startDateUtc),
          'End Date': this.dateUtils.getDateAsStringV2(appointment?.endDateUtc),
          'Start Time': appointment?.startTime,
          'End Time': appointment?.endTime,
          'Grooming Notes': appointment?.notes
        };
        appointments.push(apt);
        for (const appointmentServiceRow of appointment.appointmentServiceRows) {
          let petFlags = '';
          if (appointmentServiceRow.petFlags?.length) {
            for (const [index, petFlag] of appointmentServiceRow.petFlags.entries()) {
              petFlags += `${petFlag.name}`;
              if (appointmentServiceRow.petFlags?.length - 1 !== index) {
                petFlags += ',';
              }
            }
          }
          appointments.push({
            'Service': appointmentServiceRow?.serviceName,
            'Pet': appointmentServiceRow.eiLabel,
            'Breed': appointmentServiceRow.eiType,
            'Staff': appointmentServiceRow.serviceUserFullName,
            'Pet Flags': petFlags
          });
        }
      }
      this.exportToExcelService.exportAsExcelFileWithOpts(appointments, { header }, 'Day Plan');
    }
  }

  popup(data: any) {
    const mywindow = window.open('', '_blank');
    if (mywindow) {
      mywindow.document.write(`
      <!DOCTYPE html>
      <html>
      <head>
        <title>SAVVY - Print Appointments</title>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
      </head>`);
      mywindow.document.write(`<body style="font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 16px; color: #000000; background-color: #f7f7f7;">`);
      mywindow.document.write(`
      <style>
        .maintable>tbody>tr:nth-child(5n) {
            page-break-after: always;
        }

        .maintable>tbody>tr:nth-child(6n)>td:first-child {
            padding-top: 50px !important;
        }
    </style>
      `);
      mywindow.document.write(data);
      mywindow.document.write('</body></html>');
      mywindow.document.close();
      mywindow.focus();
      setTimeout(function () { mywindow.print(); }, 1000);
      // mywindow.close();
    }

    return true;
  }

  printAppointments() {
    let template = `
      <h3 style="text-align: center; position: fixed; margin: 0 auto; top: 10px; left: 0; width: 100%;">
        ${this.datePipe.transform(this.viewDate, 'fullDate')}
      </h3>
      <table class="maintable" style="border: none; border-collapse: collapse; width: 100%; max-width: 795px; margin: 50px auto 0 auto;">
        <tbody>
      `;

    if (this.appointments?.length) {
      for (const appointment of this.appointments) {
        let customerFlags = '';
        if (appointment.customerFlags?.length) {
          for (const [index, customerFlag] of appointment.customerFlags.entries()) {
            customerFlags += `${customerFlag.name}`;
            if (appointment.customerFlags?.length - 1 !== index) {
              customerFlags += ',';
            }
          }
        }
        template += `
        <tr>
          <td style="padding: 5px; height: 160px;">
            <table style="border: 1px solid #cccccc; border-radius: 10px; border-collapse: collapse; width: 100%;">
              <tbody>
                <tr>
                  <td style="padding: 3px 15px; vertical-align: top; max-width: 500px;">
                    <h3 style="margin: 0; line-height: 26px;">${appointment?.customerName} <span
                      style="font-weight: 400; font-size: 18px;display: none;">(VIP Plan)</span>
                      <br><span style="font-weight: 500; margin: 0 15px 0 0; font-size: 16px;">Phone:
                      ${appointment?.contactPhone?.nationalNumber}</span>
                      <span style="font-weight: normal; margin: 0 15px 0 0; font-size: 16px;">${customerFlags}</span>
                    </h3>
                  </td>
                  <td style="padding: 3px 15px; vertical-align: top; text-align: right;">
                    <h3 style="margin: 0; line-height: 26px;"><span
                      style="font-weight: 500; margin: 0 0 0 15px; font-size: 16px;">${appointment?.startTime} -
                      ${appointment?.endTime}</span><br>
                    </h3>
                  </td>
                </tr>
          `;

        for (const appointmentServiceRow of appointment.appointmentServiceRows) {
          let petFlags = '';
          if (appointmentServiceRow.petFlags?.length) {
            for (const [index, petFlag] of appointmentServiceRow.petFlags.entries()) {
              petFlags += `#${petFlag.name}`;
              if (appointmentServiceRow.petFlags?.length - 1 !== index) {
                petFlags += ',';
              }
            }
          }
          template += `
          <tr>
            <td style="padding: 5px 15px; vertical-align: top;" colspan="2">
              <p style="margin-top:0;">
                <span class="material-symbols-outlined"
                  style="vertical-align: middle; height: 20px; width: 20px; font-size: 18px;">pets</span>
                <b>${appointmentServiceRow.eiLabel}</b> (${appointmentServiceRow.eiType}):
                <br>
                <span class="material-symbols-outlined"
                  style="vertical-align: middle; height: 20px; width: 20px; font-size: 18px; margin-right: 5px;">cut</span><span
                  style="font-weight: 500;">${appointmentServiceRow?.serviceName}</span> by ${appointmentServiceRow.serviceUserFullName} from ${appointmentServiceRow.startTime} to ${appointmentServiceRow.endTime}.
                  ${petFlags}
              </p>
            </td>
          </tr>
                `;
          // <-- TODO: <p style="margin:5px 0;"><b>Pet Notes</b>: Pet Notes will go here</p>!-->
        }
        if (appointment?.notes?.length) {
          template += `
              <tr>
                <td style="padding: 5px 15px; vertical-align: top;" colspan="2">
                  <p style="margin:0 0 5px 0;"><b>Grooming Notes</b>: ${appointment?.notes}</p>
                </td>
              </tr>`;
        }
        template += `
              </tbody>
            </table>
          </td>
        </tr>
        `;
      }
    }
    template += `
          </tbody>
        </table>
      `;
    this.popup(template);
  }
}
