
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Invoice, InvoiceService } from '@savvy/invoice';
import { ContextIdDto } from '@savvy/ui';
import { takeUntil } from 'rxjs/operators';
import { ClearObservable } from '../shared/classes/clear-observable';
import { PhoneNumberHelperService } from '../shared/services/phone-number-helper.service';
import { TrialExpiredService } from '../shared/services/trial-expired.service';
import { ChangeListener } from './changeListener';
import { ViewInvoiceDialogComponent } from './viewInvoiceDialog.component';

@Component({
  selector: 'app-view-customer-invoices',
  templateUrl: 'viewCustomerInvoices.component.html'
})
export class ViewCustomerInvoicesComponent extends ClearObservable implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() customerId: string;
  @Input() form: UntypedFormGroup;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;

  invoices: Array<Invoice>;

  constructor(
    private router: Router,
    public trialExpiredService: TrialExpiredService,
    private sharedService: PhoneNumberHelperService,
    private dialog: MatDialog,
    private invoiceService: InvoiceService) {
    super();
  }

  ngOnInit() {
    this.loadInvoiceLists();
  }


  create() {
      this.addInvoice();
  }

  rowSelected(contractId: string) {
    //  if (this.listContractListDto.useModal) {
    console.log('TODO: Use modal here');
    this.openViewInvoiceModal(contractId);
    /*  } else {
        this.sharedService.viewEntityModalSub.next(true);
        console.log('inside gotoEntity contractId ' + contractId);
        this.router.navigate([
          '/contract/viewContract',
          this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          contractId
        ]);
      }*/
  }

  addInvoice() {
    if (!this.customerId) {
      console.warn('no customer id');
    } else {
      this.router.navigate(['/invoice/addCustomerInvoice',
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.customerId
      ]);
    }
  }

  openViewInvoiceModal(invoiceId) {

    const dialogRef = this.dialog.open(ViewInvoiceDialogComponent, {
      width: '90%',
      height: '90%',
      data: {
        invoiceId,
        contextIdDto: this.contextIdDto
      }
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.loadInvoiceLists();
        });
  }

  loadInvoiceLists() {
      this.invoiceService.listInvoiceByCustomerId(
        this.contextIdDto.contextId, this.contextIdDto.contextIdType,
        this.customerId)
        .subscribe((response) => {
          this.invoices = response;
          console.log('loaded customer invoices', response);
        }, (err: HttpErrorResponse) => {
          console.log('error while loadContractLists', err);
        });
  }
}

