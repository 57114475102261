<!-- *ngIf="!deviceService.isMobile() && !deviceService.isTablet()" -->
<ng-container>
  <div *ngFor="let run of createBoardingBookingV2SyncService.selectedRuns; let rowIndex = index; let last = last">
    <div class="mdc-mb-1 rounded-10 border position-relative">
      <div class="remove-run" *ngIf="createBoardingBookingV2SyncService.selectedRuns.length > 1">
        <button mat-icon-button color="accent" (click)="deleteRun(rowIndex)">
          <mat-icon>cancel</mat-icon>
        </button>
      </div>
      <div class="run-selection mdc-p-3">
        <div fxLayout="row wrap" fxLayout.gt-sm="row" fxLayoutGap="8px">
          <div fxFlex="nogrow" fxFlex.gt-sm="nogrow">
            <div class="avatar avatar-50 circle border-3">
              <mat-icon>home</mat-icon>
            </div>
          </div>
          <div fxFlex fxFlex.gt-sm="44">
            <app-run-selector-pet-stay #runSelectorPetStayComponent [contextIdDto]="contextIdDto"
              [selectedRun]="run.selectedPetRun" [runList]="runList" (runSelected)="runSelected($event, rowIndex)">
            </app-run-selector-pet-stay>
          </div>
          <div fxFlex="47" fxFlex.gt-sm="16">
            <ng-container *ngIf="bookingType === 'BOARDING'">
              <mat-form-field appearance="outline"
                *ngIf="run.selectedPetRun && !applyMultiPetPrice[run.selectedPetRun.id]" class="field-center w-100">
                <mat-label>{{'PRICE' | translate}}</mat-label>
                <input id="appointment-run-unit-price{{rowIndex}}" matInput type="text" appNumberOnly
                  [decimalPoints]="2" placeholder="{{'UNIT_PRICE' | translate}}" name="unitPrice{{rowIndex}}"
                  #unitPrice{{rowIndex}}="ngModel" [(ngModel)]="run.selectedPetRun.price"
                  (ngModelChange)="updateUnitPrice(run)" id="unitPrice{{rowIndex}}" required>
              </mat-form-field>
              <mat-form-field appearance="outline"
                *ngIf="run.selectedPetRun && applyMultiPetPrice[run.selectedPetRun.id]" class="field-center w-100">
                <mat-label>{{'PRICE' | translate}}</mat-label>
                <input id="appointment-run-unit-price{{rowIndex}}" matInput type="text" appNumberOnly
                  [decimalPoints]="2" placeholder="{{'UNIT_PRICE' | translate}}" name="unitPrice{{rowIndex}}"
                  #unitPrice{{rowIndex}}="ngModel" [(ngModel)]="run.selectedPetRun.multiPetPrice"
                  (ngModelChange)="updateUnitPrice(run)" id="unitPrice{{rowIndex}}" required>
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="bookingType === 'DAY_CARE'">
              <ng-container *ngIf="!petStayPreference?.bookHourly">
                <mat-form-field appearance="outline"
                  *ngIf="run.selectedPetRun && !applyMultiPetPrice[run.selectedPetRun.id]" class="field-center w-100">
                  <mat-label>{{'PRICE' | translate}}</mat-label>
                  <input id="appointment-run-unit-price{{rowIndex}}" matInput type="text" appNumberOnly
                    [decimalPoints]="2" placeholder="{{'UNIT_PRICE' | translate}}" name="unitPrice{{rowIndex}}"
                    #unitPrice{{rowIndex}}="ngModel" [(ngModel)]="run.selectedPetRun.price"
                    (ngModelChange)="updateUnitPrice(run)" id="unitPrice{{rowIndex}}" required>
                </mat-form-field>
                <mat-form-field appearance="outline"
                  *ngIf="run.selectedPetRun && applyMultiPetPrice[run.selectedPetRun.id]" class="field-center w-100">
                  <mat-label>{{'PRICE' | translate}}</mat-label>
                  <input id="appointment-run-unit-price{{rowIndex}}" matInput type="text" appNumberOnly
                    [decimalPoints]="2" placeholder="{{'UNIT_PRICE' | translate}}" name="unitPrice{{rowIndex}}"
                    #unitPrice{{rowIndex}}="ngModel" [(ngModel)]="run.selectedPetRun.multiPetPrice"
                    (ngModelChange)="updateUnitPrice(run)" id="unitPrice{{rowIndex}}" required>
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="petStayPreference?.bookHourly">
                <mat-form-field appearance="outline" class="field-center w-100">
                  <mat-label>{{'HOURLY_PRICE' | translate}}</mat-label>
                  <input id="appointment-run-hourly-unit-price{{rowIndex}}" matInput type="text" appNumberOnly
                    [decimalPoints]="2" placeholder="{{'HOURLY_PRICE' | translate}}" name="hourlyUnitPrice{{rowIndex}}"
                    #hourlyUnitPrice{{rowIndex}}="ngModel" [(ngModel)]="run.selectedPetRun.hourlyPrice"
                    (ngModelChange)="updateUnitPrice(run)" id="hourlyUnitPrice{{rowIndex}}" required>
                </mat-form-field>
              </ng-container>

            </ng-container>
          </div>
          <div fxFlex="47" fxFlex.gt-sm="16" *ngIf="taxDefs && taxDefs.length">
            <mat-form-field appearance="outline" *ngIf="run.selectedPetRun" class="field-center w-100">
              <mat-label>{{'TAX' | translate}}</mat-label>
              <mat-select id="appointment-run-tax{{rowIndex}}" placeholder="{{'SELECT_TAX_RATE' | translate}}"
                [(ngModel)]="run.selectedPetRun.taxId" name="taxId{{rowIndex}}" #taxId{{rowIndex}}="ngModel"
                (ngModelChange)="createBoardingBookingV2SyncService.updateGrossPriceForRun(run)"
                [compareWith]="compareTax">
                <mat-option value="" selected>{{'NO_TAX' | translate}}</mat-option>
                <mat-option *ngFor="let tax of taxDefs" [value]="tax.id">
                  {{ tax.taxName }} ({{ tax.taxRate }}%)
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex="47" fxFlex.gt-sm="5">
            <mat-form-field appearance="outline" *ngIf="run.selectedPetRun" class="field-center w-100">
              <mat-label>{{'QTY' | translate}}</mat-label>
              <input id="run-qty-{{rowIndex}}" matInput type="text" appNumberOnly [decimalPoints]="0"
                #runQty{{rowIndex}}="ngModel" [(ngModel)]="run.quantity" readonly>
            </mat-form-field>
          </div>
          <div fxFlex="47" fxFlex.gt-sm="16">
            <mat-form-field appearance="outline" *ngIf="run.selectedPetRun" class="field-center w-100">
              <mat-label>{{'TOTAL_PRICE' | translate}}</mat-label>
              <input id="appointment-gross-price{{rowIndex}}" readonly disabled matInput type="text" appNumberOnly
                [decimalPoints]="2" minValue placeholder="Gross Price" name="grossPrice{{rowIndex}}"
                #grossPrice{{rowIndex}}="ngModel" [(ngModel)]="run.grossPrice"
                (ngModelChange)="createBoardingBookingV2SyncService.calculateTotalAmount()" id="grossPrice{{rowIndex}}"
                required>
            </mat-form-field>
          </div>

        </div>
        <p class="vm mb-0 pl-sm-50">
          <span *ngIf="run.selectedPetRun?.maxPets" class="pr-1">
            <mat-icon class="vm mat-text-theme mr-5px">pets</mat-icon>
            <span class="mat-text-secondary mx-2 vm"><span>{{run?.selectedPetRun?.maxPets}}</span> {{'PETS_MAX' |
              translate}}.</span>
          </span>

          <ng-container *ngIf="bookingType === 'DAY_CARE'">
            <span class="pr-1">
              <mat-icon class="vm mat-text-theme  mr-5px">payments</mat-icon>
              <ng-container *ngIf="!petStayPreference?.bookHourly">
                <span class="mat-text-secondary mx-2 vm" *ngIf="applyMultiPetPrice[run.selectedPetRun.id]"
                  title="{{'MULTI_PET_PRICE' | translate}}">{{run.selectedPetRun?.multiPetPrice |
                  currency:currencyCode:'symbol'}}*</span>
                <span class=" mat-text-secondary mx-2 vm" *ngIf="!applyMultiPetPrice[run.selectedPetRun.id]"
                  title="{{'REGULAR_PRICE' | translate}}">{{run.selectedPetRun?.price |
                  currency:currencyCode:'symbol'}}</span>/ {{'DAY' | translate}}
              </ng-container>
              <ng-container *ngIf="petStayPreference?.bookHourly">
                <span class="mat-text-secondary mx-2 vm"
                  title="{{'MULTI_PET_PRICE' | translate}}">{{run.selectedPetRun?.hourlyPrice |
                  currency:currencyCode:'symbol'}} / {{'HOUR' | translate}}</span>
              </ng-container>
            </span>
          </ng-container>
          <ng-container *ngIf="bookingType === 'BOARDING'">
            <span class="pr-1" *ngIf="run.selectedPetRun?.chargePerNight">
              <mat-icon class="vm mat-text-theme mr-5px">nights_stay</mat-icon>

              <span class="mat-text-secondary mx-2 vm" *ngIf="applyMultiPetPrice[run.selectedPetRun.id]"
                title="{{'MULTI_PET_PRICE' | translate}}">{{run.selectedPetRun?.multiPetPrice |
                currency:currencyCode:'symbol'}}*</span>
              <span class="mat-text-secondary mx-2 vm" *ngIf="!applyMultiPetPrice[run.selectedPetRun.id]"
                title="{{'REGULAR_PRICE' | translate}}">{{run.selectedPetRun?.price |
                currency:currencyCode:'symbol'}}</span>/ {{'NIGHT' | translate}}
            </span>
            <span class="pr-1" *ngIf="!run.selectedPetRun?.chargePerNight">
              <mat-icon class="vm mat-text-theme mr-5px">payments</mat-icon>

              <span class="mat-text-secondary mx-2 vm" *ngIf="applyMultiPetPrice[run.selectedPetRun.id]"
                title="{{'MULTI_PET_PRICE' | translate}}">{{run.selectedPetRun?.multiPetPrice |
                currency:currencyCode:'symbol'}}*</span>
              <span class=" mat-text-secondary mx-2 vm" *ngIf="!applyMultiPetPrice[run.selectedPetRun.id]"
                title="{{'REGULAR_PRICE' | translate}}">{{run.selectedPetRun?.price |
                currency:currencyCode:'symbol'}}</span>/ {{'DAY' | translate}}
            </span>

          </ng-container>
          <span class="pr-1" *ngIf="run.selectedPetRun?.airConditioning">
            <mat-icon class="vm mat-text-theme mr-5px">ac_unit</mat-icon> <span
              class="mat-text-secondary mx-2 vm">{{'AIR_CONDITIONER' | translate}}</span>
          </span>
          <span class="pr-1" *ngIf="run.selectedPetRun?.heater">
            <mat-icon class="vm mat-text-theme mr-5px">local_fire_department</mat-icon> <span
              class="mat-text-secondary mx-2 vm">{{'HEATER' | translate}}</span>
          </span>
          <span class="pr-1" *ngIf="run.selectedPetRun?.heating">
            <mat-icon class="vm mat-text-theme mr-5px">fireplace</mat-icon> <span
              class="mat-text-secondary mx-2 vm">{{'SUITABLE_FOR_HEATING' | translate}}</span>
          </span>
        </p>
      </div>
      <hr class="mt-0 mb-0">
      <ng-container *ngIf="run?.selectedPetRun?.maxPets === 1">
        <ng-container *ngFor="let selectedPet of run.selectedPets; let i = index">
          <div class="pt-1">
            <app-pet-selector-boarding-v2 [contextIdDto]="contextIdDto" [multiple]="false"
              [maxPets]="run?.selectedPetRun?.maxPets" [taxDefs]="taxDefs"
              [showNewPet]="!createBoardingBookingV2SyncService?.editMode" [(selectedPet)]="run.selectedPets[i]"
              (selectedPetChange)="selectedPetChanged(run)" [petList]="createBoardingBookingV2SyncService.petList"
              (addAnotherPetEvent)="addAnotherPetEvent(run)" (addNewCustomerEvent)="createCustomer(run)">
            </app-pet-selector-boarding-v2>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="run?.selectedPetRun?.maxPets > 1">
        <!-- <ng-container > -->
        <mat-tab-group mat-align-tabs="center">
          <mat-tab label="{{'PET' | translate}}-{{i + 1}}"
            *ngFor="let selectedPet of run.selectedPets; let i = index; let last = last">
            <div class="pt-1">
              <app-pet-selector-boarding-v2 [contextIdDto]="contextIdDto" [multiple]="false"
                [maxPets]="run?.selectedPetRun?.maxPets" [taxDefs]="taxDefs"
                [showNewPet]="!createBoardingBookingV2SyncService?.editMode" [(selectedPet)]="run.selectedPets[i]"
                (selectedPetChange)="selectedPetChanged(run)" [petList]="createBoardingBookingV2SyncService.petList"
                (addAnotherPetEvent)="addAnotherPetEvent(run)" (addNewCustomerEvent)="createCustomer(run)">
              </app-pet-selector-boarding-v2>
            </div>
          </mat-tab>
        </mat-tab-group>
        <!-- </ng-container> -->
        <!-- <ng-container *ngFor="let selectedPet of run.selectedPets; let i = index; let last = last">

          <hr class="mt-0 mdc-mb-1" *ngIf="run.selectedPets?.length > 1 && !last">
        </ng-container> -->

      </ng-container>

    </div>
  </div>
</ng-container>