import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';
import { ActivatedRoute, Router } from '@angular/router';
import {ContractService, CreateAndSendCustomContract} from '@savvy/contract';
import { EicompService } from '@savvy/entity-instance-composite';
import { EntitysService } from '@savvy/entity-instance';
import { ContractCompService } from '@savvy/contract';
import { TranslateService } from '@ngx-translate/core';
import { BrandingService } from '@savvy/branding';
import { Contract, CreateContract, UpdateContract } from '@savvy/contract';
import { ContextIdDto } from '@savvy/view-definition';
import { LinkId } from 'src/app/core/data-share.service';

@Component({
  selector: 'app-add-contract',
  templateUrl: './addContract.component.html'
})
export class AddContractComponent implements OnInit {
  @Input() contextIdDto: ContextIdDto;
  @Input() linkId: LinkId;
  @Input() isContractModal: boolean;
  @Input() noToolbar = false;

  @Output() contractCreated = new EventEmitter();

  contract: Contract = null;
  response: any;
  private sub: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: ContractService,
    private brandApi: BrandingService,
    private contractCompositeApi: ContractCompService,
    private eiUiApi: EicompService,
    private eiApi: EntitysService,
    private translateService: TranslateService,
    private notify: FloSnackbarComponent) { }


  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.contextIdDto = this.contextIdDto ? this.contextIdDto : { contextIdType: params['contextIdType'], contextId: params['contextId'] };
      if (!this.linkId) {
        this.linkId = <LinkId>{};
        this.linkId.linkedId = params['linkId'];
        this.linkId.linkedIdType = params['linkIdType'];
        if (params['linkedEdId']) {
          this.linkId.linkedEdId = params['linkedEdId'];
        }
      }

      this.contract = <Contract>{};
      this.contract.ownerId = this.contextIdDto.contextId;
      this.contract.legalText = 'Select template and legal text will appear here';
      this.contract.linkId = this.linkId;

      this.loadContractTemplate();
      // Then, when this changes, we can send it
    });
  }

  loadContractTemplate() {
    this.contractCompositeApi.loadContractTemplates(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
    ).subscribe(response => {
      this.response = response;
      if (response.idNameTupleDtos &&
        response.idNameTupleDtos.length > 0) {
        this.contract.contractTemplateId = response.idNameTupleDtos[0].id;
        this.templateChange();
      }
    });
  }

  templateChange() {
    console.log('template changed');
    this.contractCompositeApi.loadLegalText(
      this.contract.contractTemplateId,
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
    ).subscribe(response => {
       console.log('legal text is ', response.legalText);
      this.contract.legalText = response.legalText;
    });
  }

  sendContract() {
    console.log('inside send contract');
  }

  viewContract() {
    this.router.navigate(['/contract/viewContract',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      this.contract.id]);
  }

  addContract() {
    const req: CreateContract = <CreateContract>{};
    req.contextIdDto = this.contextIdDto;
    req.contract = this.contract;

    this.api.createContract(req).subscribe(response => {
      this.contract = response;
      this.notify.message = 'Created Contract';
      this.notify.open();

      if (this.isContractModal) {
        this.contractCreated.emit(true);
      } else {
        this.router.navigate(['/contract/editContract',
          this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          this.contract.id
        ]);
      }
    });
  }

  createAndSendContract() {
    const req: CreateAndSendCustomContract = <CreateAndSendCustomContract>{};
    req.contextIdDto = this.contextIdDto;
    req.contract = this.contract;

    this.contractCompositeApi.createAndSendCustomContract(req).subscribe(response => {
      this.contract = response.contract;
      this.notify.message = 'Contract has been created and sent to the customer.';
      this.notify.open();

      if (this.isContractModal) {
        this.contractCreated.emit(true);
      } else {
        this.router.navigate(['/contract/editContract',
          this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          this.contract.id
        ]);
      }
    });
  }

  bodyUpdated(event: any) {
    console.log('body now ' + event);
    this.contract.legalText = event;
    // event.stopPropagation();
  }

  updateContract() {
    const req: UpdateContract = <UpdateContract>{};
    req.contextIdDto = this.contextIdDto;
    req.contract = this.contract;


    this.api.updateContract(req).subscribe(response => {
      this.contract = response;
      this.translateService.get('Updated Contract').subscribe(value => {
        this.notify.message = value;
        this.notify.open();
        this.contractCreated.emit(true);
      });
    });


  }

  closeModal() {
    this.contractCreated.next(true);
  }
}
