import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ContractCompService,
  ContractTemplateService, CreateAndSendContract
} from '@savvy/contract';
import { CustomerCompositeService, CustomerService } from '@savvy/customer';
import { EmailMessageTemplate, EmailmessagetemplatesService, Link } from '@savvy/email-template';
import { FileValue } from '@savvy/entity-instance-composite';
import { IdNameTupleDto } from '@savvy/pet';
import { UserId } from '@savvy/user';
import { ContextIdDto, EmailRecipient } from '@savvy/view-definition';
import { UploadFilesComponent } from "../../shared/components/upload-files/upload-files.component";
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';

@Component({
  selector: 'app-send-customer-contract',
  templateUrl: './sendCustomerContract.component.html'
})
export class SendCustomerContractComponent implements OnInit {

  @ViewChild('uploadFiles') uploadFilesComponent: UploadFilesComponent;

  contextIdDto: ContextIdDto;

  contractTemplates: Array<IdNameTupleDto>;

  contractTemplateId: string;
  customerId: string;
  existingCustomer = true;
  body: string;
  subject: string;
  emailMessageTemplate: EmailMessageTemplate;
  recipients: string;
  showSpinner = false;
  allowedToSend = false;

  customerRecipient: EmailRecipient;
  showCustomer = true;

  public form: UntypedFormGroup; // our form model

  savedFiles: FileValue[] = [];
  links: Link[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SendCustomerContractComponent>,
    private contractCompService: ContractCompService,
    private contractTemplateService: ContractTemplateService,
    private customerApi: CustomerCompositeService,
    private customerService: CustomerService,
    private notify: FloSnackbarComponent,
    private emailMessageApi: EmailmessagetemplatesService) {

    this.contextIdDto = data.contextIdDto;
    this.customerId = data.customerId;
    this.contractTemplateId = data.contractTemplateId;
  }

  ngOnInit() {
    if (this.customerId) {
      this.showCustomer = false;
      this.loadRecipient(this.customerId);
    }
    console.log('inside new message init');
    this.loadContractTemplates();

    this.emailMessageApi.getEmailMessageTemplateByType(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType, 'contract').subscribe(response => {
        console.log('loading type');
        this.emailMessageTemplate = response;
        if (response) {
          this.subject = response.subject;
          this.body = response.body;

          if (response?.attachments) {
            this.savedFiles = response?.attachments;
          }

          if (response?.links) {
            this.links = response.links;
          }
        }
      });
  }

  bodyUpdated(text: string) {
    this.body = text;
  }

  loadContractTemplates() {
    console.log('loading contract templates');
    this.contractCompService.loadContractTemplates(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType).subscribe(response => {
        this.contractTemplates = response.idNameTupleDtos;
        console.log('loaded ', this.contractTemplates.length);
      });

  }

  setCustomerId(customerId: string) {
    if (customerId !== this.customerId) {
      this.customerId = customerId;
      this.loadRecipient(customerId);
    }
  }

  loadRecipient(customerId: string) {
    this.customerService.loadCustomer(customerId,
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType).subscribe(customerDto => {
        if (customerDto.optIn) {
          this.allowedToSend = customerDto.optIn.allowEmail;
        }
        this.recipients = customerDto.username;
        this.customerRecipient = <EmailRecipient>{};
        this.customerRecipient.address = customerDto.username;
        this.customerRecipient.recipientId = customerDto.customerId?.id;
        this.customerRecipient.recipientType = EmailRecipient.RecipientTypeEnum.Customer;
      });
  }

  send() {

    if (!this.contractTemplateId || !this.customerId) {
      return;
    }

    this.showSpinner = true;
    console.log('onSubmit');
    const req = <CreateAndSendContract>{};
    req.contextIdDto = this.contextIdDto;
    req.contractTemplateId = this.contractTemplateId;
    req.customerId = this.customerId;

    this.contractCompService.createAndSendContract(req).subscribe(response => {
      console.log('Got response!');
      if (response.sendMessageError) {
        console.log('there was an error!');
        this.notify.message = 'Error sending contract ' + response.sendMessageError;
        this.notify.open();
      } else {
        this.notify.message = 'Sent Contract';
        this.notify.open();
        this.dialogRef.close(response);
      }
      this.showSpinner = false;
    });
  }

  templateChanged(selectedTemplateId: string) {
    this.contractTemplateService.getContractTemplate(selectedTemplateId).subscribe(res => {
      if (res) {
        this.subject = res.name;
        this.body = res.legalText;
      }
    });
  }

  getAttachments() {
    const attachments = new Array();

    if (this.uploadFilesComponent.savedFiles) {
      this.uploadFilesComponent.savedFiles.forEach(
        savedFile => {
          attachments.push({
            ...savedFile,
            publicBucket: false
          });
        }
      );
    }
    return attachments;
  }
}
