<!-- <mat-toolbar color="primary" >Export Invoices</mat-toolbar> -->

<mat-card appearance="outlined" *ngIf="exporting">
  <mat-card-title>
    <h5>{{'EXPORTING_INVOICE_DATA' | translate}}</h5>
  </mat-card-title>
  <mat-card-subtitle>{{'INVOICE_EXPORT_MAY_TAKE_TIME' | translate}}</mat-card-subtitle>
  <mat-card-content>
    <mat-spinner></mat-spinner>
  </mat-card-content>
</mat-card>

<div *ngIf="!exporting">
  <form #importForm="ngForm">
    <!-- <h3>Export Invoices</mat-card-title> -->
    <!-- <form #emailSettingsForm="ngForm">
    </form> -->
    <p class="mdc-mb-1">{{'INVOICE_EXPORT_MAY_TAKE_60' | translate}}</p>
    <button mat-raised-button color="accent" (click)="exportData()" class="mr-1 mdc-my-1">{{'EXPORT_ALL_INVOICES' | translate}}</button>
    <button mat-raised-button color="accent" (click)="exportDataByService()">{{'EXPORT_INVOICES_BY_SERVICE' | translate}}</button>
    <br>

  </form>
</div>