<mat-toolbar color="primary">
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span>{{'MESSAGE' | translate}}</span>
    <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div mat-dialog-content class="m-0 mdc-p-34">
  <mat-form-field appearance="outline" class="w-100">
    <mat-label>{{'COLOUR' | translate}}</mat-label>
    <input matInput type="text" placeholder="{{'COLOUR' | translate}}" name="{{'COLOUR' | translate}}"
      [value]="workflowState.colour" [style.background]="workflowState.colour" [(colorPicker)]="workflowState.colour"
      [cpOKButton]="false" [cpOutputFormat]="'hex'" [cpSaveClickOutside]="true" [cpUseRootViewContainer]="true"
      [cpOKButtonClass]="'mat-raised-button mat-accent'" (colorPickerSelect)="onColorChange($event)">
  </mat-form-field>
  <!-- <mat-form-field style="width: 100%">
      <mat-label>{{'COLOUR' | translate}}</mat-label>
      <input matInput placeholder="{{'COLOUR' | translate}}" class="form-control" name="colour"
        [(ngModel)]="workflowState.colour" [(colorPicker)]="workflowState.colour"
        [style.background]="workflowState.colour" [value]="workflowState.colour"
        (colorPickerChange)="onColorChange($event)">
    </mat-form-field> -->
</div>

<div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="center center">
  <button mat-raised-button color="accent" (click)="updateStateColor()">{{'SAVE' | translate}}</button>
</div>