<div class="view-invoice-page" fxLayout="column" fxLayoutAlign="center">
  <ng-container *ngIf="invoice && viewOnly">
    <div fxLayout="column" class="invoice-container w-100 mt-2" fxFlex="100">
      <mat-card appearance="outlined" class="mt-0 new-card">
        <div class="mdc-mb-1">
          <h2 class="mt-0"><strong>{{'INVOICE' | translate}}</strong></h2>
          <a href="javascript:void(0)" *ngIf="invoice.companyDetails">{{invoice.companyDetails.companyEmail}}</a>
        </div>

        <div fxLayout="row" fxLayoutWrap="wrap">
          <div fxFlex.gt-xs="50" fxFlex="100">
            <h4 class="my-0" *ngIf="invoiceDto">{{invoiceDto.fullName}}</h4>
            <div fxLayout="column">
              <span>{{'INVOICE_NO' | translate}}: {{invoice.invoiceNumber}}</span>
              <span>{{'DATE_ISSUED' | translate}}: {{invoice.invoiceDate | localizedDate: 'mediumDate'}}</span>
              <span>{{'DUE_DATE' | translate}}: {{invoice.invoiceDate | localizedDate: 'mediumDate'}}</span>
              <span>{{'VOID' | translate}}: {{ invoice.invoiceVoid }}</span>
            </div>
          </div>
          <div fxFlex.gt-xs="50" fxFlex="100" class="text-sm-right text-xs-left" *ngIf="invoice.companyDetails">
            <!--h4 class="my-0">{{invoiceDto?.fullName}}</h4-->
            <div fxLayout="column" *ngIf="invoice.companyDetails.companyAddress">
              <span>{{invoice.companyDetails.companyTelephone}}</span>
              <span>{{invoice.companyDetails.companyAddress.address1}}</span>
              <span>{{invoice.companyDetails.companyAddress.address2}}</span>
              <span>{{invoice.companyDetails.companyAddress.city}}
                , {{invoice.companyDetails.companyAddress.zipCode}}</span>
            </div>
          </div>
          <div fxLayout="row" fxLayoutWrap="wrap" *ngIf="invoice.paidInFull">
            <img src="assets/images/invoice/invoicePaid.svg" />
            <!--
            <h2>{{invoice.datePaid |date:'medium'}}</h2>
            -->
          </div>
        </div>
      </mat-card>

      <mat-card appearance="outlined" class="new-card pl-1" *ngIf="invoiceDto && invoiceDto.showPackages && invoiceDto.invoicePackageRowDtos && invoiceDto.invoicePackageRowDtos.length">
        <div class="mdc-mb-1">
          <h2 class="mt-0"><strong>{{'PACKAGE' | translate}}</strong></h2>
        </div>
        <ngx-datatable class="material" [rows]="invoiceDto.invoicePackageRowDtos" [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'" [scrollbarH]="true">
          <ngx-datatable-column name="{{'DESCRIPTION' | translate}}" [flexGrow]="3">
            <ng-template ngx-datatable-cell-template let-row="row">
              <strong>{{row.packageName}}</strong>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'UNIT_PRICE' | translate}}" [flexGrow]="1">
            <ng-template ngx-datatable-cell-template let-row="row">
              <strong>{{row.unitPrice | currency:currencyCode:'symbol'}}</strong>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'TAX_RATE' | translate}}" [flexGrow]="0.6">
            <ng-template ngx-datatable-cell-template let-row="row">
              <strong>{{row.tax}}</strong>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'QUANTITY' | translate}}" [flexGrow]="1">
            <ng-template ngx-datatable-cell-template let-row="row">
              <strong>{{row.quantity}}</strong>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'DISCOUNT' | translate}}" [flexGrow]="0.6">
            <ng-template ngx-datatable-cell-template let-row="row">
              {{row?.discountDetails | discountTmpl:currencyCode}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'AMOUNT' | translate}}" [flexGrow]="1">
            <ng-template ngx-datatable-cell-template let-row="row">
              <app-discount-price-view [originalPrice]="row.grossPriceNoOffer" [finalPrice]="row.grossPrice" [currencyCode]="currencyCode"></app-discount-price-view>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </mat-card>

      <mat-card appearance="outlined" class="new-card pl-1" *ngIf="invoiceDto && invoiceDto.showItemRows && invoiceDto.invoice.invoiceItemRows && invoiceDto.invoice.invoiceItemRows.length">
        <div class="mdc-mb-1">
          <h2 class="mt-0"><strong>{{'INVOICE_ITEMS' | translate}}</strong></h2>
        </div>
        <ngx-datatable class="material" [rows]="invoiceDto.invoice.invoiceItemRows" [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'">
          <ngx-datatable-column name="{{'DESCRIPTION' | translate}}" [flexGrow]="3">
            <ng-template ngx-datatable-cell-template let-row="row">
              <strong>{{row.name}}</strong><br /><span class="mat-text-muted">{{row.description}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'UNIT_PRICE' | translate}}" [flexGrow]="1">
            <ng-template ngx-datatable-cell-template let-row="row">
              <strong>{{row.unitPrice | currency:currencyCode:'symbol'}}</strong>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'TAX_RATE' | translate}}" [flexGrow]="0.6">
            <ng-template ngx-datatable-cell-template let-row="row">
              <strong>{{row.tax}}</strong>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'QUANTITY' | translate}}" [flexGrow]="1">
            <ng-template ngx-datatable-cell-template let-row="row">
              <strong>{{row.quantity}}</strong>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'AMOUNT' | translate}}" [flexGrow]="1">
            <ng-template ngx-datatable-cell-template let-row="row">
              <strong>{{ row.grossPrice | currency:currencyCode:'symbol'}}</strong>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </mat-card>

      <mat-card appearance="outlined" class="new-card pl-1" *ngIf="invoiceDto && invoiceDto.showCourses && invoiceDto.invoice.invoiceCourseRows && invoiceDto.invoice.invoiceCourseRows.length">
        <div class="mdc-mb-1">
          <h2 class="mt-0"><strong>{{'COURSES' | translate}}</strong></h2>
        </div>
        <ngx-datatable class="material" [rows]="invoiceDto.invoice.invoiceCourseRows" [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'">
          <ngx-datatable-column name="{{'DESCRIPTION' | translate}}" [flexGrow]="3">
            <ng-template ngx-datatable-cell-template let-row="row">
              <strong>{{row.name}}</strong><br /><span class="mat-text-muted">{{row.description}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'UNIT_PRICE' | translate}}" [flexGrow]="1">
            <ng-template ngx-datatable-cell-template let-row="row">
              <strong>{{row.unitPrice | currency:currencyCode:'symbol'}}</strong>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'TAX_RATE' | translate}}" [flexGrow]="0.6">
            <ng-template ngx-datatable-cell-template let-row="row">
              <strong>{{row.tax}}</strong>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'QUANTITY' | translate}}" [flexGrow]="1">
            <ng-template ngx-datatable-cell-template let-row="row">
              <strong>{{row.quantity}}</strong>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'DISCOUNT' | translate}}" [flexGrow]="0.6">
            <ng-template ngx-datatable-cell-template let-row="row">
              {{row?.discountDetails | discountTmpl:currencyCode}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'AMOUNT' | translate}}" [flexGrow]="1">
            <ng-template ngx-datatable-cell-template let-row="row">
              <!-- <strong>{{ row.grossPrice | currency:currencyCode:'symbol'}}</strong> -->
              <app-discount-price-view [originalPrice]="row.grossPriceNoOffer" [finalPrice]="row.grossPrice" [currencyCode]="currencyCode"></app-discount-price-view>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </mat-card>

      <mat-card appearance="outlined" class="new-card pl-1" *ngIf="invoiceDto && invoiceDto.showServices && invoiceDto.invoiceServiceRowDtos && invoiceDto.invoiceServiceRowDtos.length">
        <div class="mdc-mb-1">
          <h2 class="mt-0"><strong>{{'SERVICES' | translate}}</strong></h2>
        </div>
        <ngx-datatable class="material" [rows]="invoiceDto.invoiceServiceRowDtos" [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'" [scrollbarH]="true">
          <ngx-datatable-column name="{{'DESCRIPTION' | translate}}" [flexGrow]="3">
            <ng-template ngx-datatable-cell-template let-row="row">
              <strong>{{row?.serviceName}}</strong><br /><span class="mat-text-muted">{{row.serviceDescription}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'UNIT_PRICE' | translate}}" [flexGrow]="1">
            <ng-template ngx-datatable-cell-template let-row="row">
              <strong>{{row.unitPrice | currency:currencyCode:'symbol'}}</strong>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'TAX_RATE' | translate}}" [flexGrow]="0.6">
            <ng-template ngx-datatable-cell-template let-row="row">
              <strong>{{row.tax}}</strong>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'QUANTITY' | translate}}" [flexGrow]="1">
            <ng-template ngx-datatable-cell-template let-row="row">
              <strong>{{row.quantity}}</strong>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'DISCOUNT' | translate}}" [flexGrow]="0.6">
            <ng-template ngx-datatable-cell-template let-row="row">
              {{row?.discountDetails | discountTmpl:currencyCode}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'AMOUNT' | translate}}" [flexGrow]="1">
            <ng-template ngx-datatable-cell-template let-row="row">
              <!-- <strong>{{ row.grossPrice | currency:currencyCode:'symbol'}}</strong> -->
              <app-discount-price-view [originalPrice]="row.grossPriceNoOffer" [finalPrice]="row.grossPrice" [currencyCode]="currencyCode"></app-discount-price-view>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </mat-card>

      <mat-card appearance="outlined" class="new-card pl-1" *ngIf="invoiceDto && invoiceDto.showProducts && invoiceDto.invoiceProductRowDtos && invoiceDto.invoiceProductRowDtos.length">
        <div class="mdc-mb-1">
          <h2 class="mt-0"><strong>{{'PRODUCTS' | translate}}</strong></h2>
        </div>
        <ngx-datatable class="material" [rows]="invoiceDto.invoiceProductRowDtos" [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'" [scrollbarH]="true">
          <ngx-datatable-column name="{{'DESCRIPTION' | translate}}" [flexGrow]="3">
            <ng-template ngx-datatable-cell-template let-row="row">
              <strong>{{row.productName}}</strong><br /><span class="mat-text-muted">{{row.productDescription}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'UNIT_PRICE' | translate}}" [flexGrow]="1">
            <ng-template ngx-datatable-cell-template let-row="row">
              <strong>{{row.unitPrice | currency:currencyCode:'symbol'}}</strong>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'TAX_RATE' | translate}}" [flexGrow]="0.6">
            <ng-template ngx-datatable-cell-template let-row="row">
              <strong>{{row.tax}}</strong>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'QUANTITY' | translate}}" [flexGrow]="1">
            <ng-template ngx-datatable-cell-template let-row="row">
              <strong>{{row.quantity}}</strong>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'DISCOUNT' | translate}}" [flexGrow]="0.6">
            <ng-template ngx-datatable-cell-template let-row="row">
              {{row?.discountDetails | discountTmpl:currencyCode}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'AMOUNT' | translate}}" [flexGrow]="1">
            <ng-template ngx-datatable-cell-template let-row="row">
              <!-- <strong>{{ row.grossPrice | currency:currencyCode:'symbol'}}</strong> -->
              <app-discount-price-view [originalPrice]="row.grossPriceNoOffer" [finalPrice]="row.grossPrice" [currencyCode]="currencyCode"></app-discount-price-view>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </mat-card>

      <mat-card appearance="outlined" class="new-card py-1">
        <div fxLayout="row" fxLayoutWrap="wrap" *ngIf="invoice">
          <div fxFlex="100" class="mt-1 mdc-mb-1" *ngIf="invoice.discountId">
            <strong>{{discountItemsById[invoice.discountId]?.code || 'CUSTOM'}}</strong> - {{invoice?.discountDetails
            | discountTmpl:currencyCode}}
          </div>
        </div>
        <div fxLayout="row" fxLayoutWrap="wrap">
          <div fxFlex.gt-sm="{{ invoice.totalTips > 0 ? '20' : '25'}}" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
            <h6 class="text-uppercase text-md ma-0 text-bold">{{'SUBTOTAL' | translate}}</h6>
            <h2 *ngIf="invoice?.discountId" class="ma-0">{{invoice.subTotalWithoutDiscount |
              currency:currencyCode:'symbol'}}</h2>
            <h2 *ngIf="!invoice?.discountId" class="ma-0">{{invoice.subTotal | currency:currencyCode:'symbol'}}</h2>
          </div>
          <div fxFlex.gt-sm="{{ invoice.totalTips > 0 ? '20' : '25'}}" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
            <h6 class="text-uppercase text-md ma-0 text-bold">{{'TAX' | translate}}</h6>
            <h2 *ngIf="invoice?.discountId" class="ma-0">{{invoice.taxWithoutDiscount |
              currency:currencyCode:'symbol'}}</h2>
            <h2 *ngIf="!invoice?.discountId" class="ma-0">{{invoice.tax | currency:currencyCode:'symbol'}}</h2>
          </div>
          <div fxFlex.gt-sm="{{ invoice.totalTips > 0 ? '20' : '25'}}" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
            <h6 class="text-uppercase text-md ma-0 text-bold">{{'DISCOUNT' | translate}}</h6>
            <h2 *ngIf="invoice.discountId">{{invoice.invoiceDiscount | currency:currencyCode:'symbol'}}</h2>
            <h2 *ngIf="!invoice.discountId">{{0.00 | currency:currencyCode:'symbol'}}</h2>
          </div>
          <div fxFlex.gt-sm="{{ invoice.totalTips > 0 ? '20' : '25'}}" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1 text-xs-left text-sm-right">
            <h6 class="text-uppercase text-sm ma-0 text-bold">{{'TOTAL' | translate}}</h6>
            <h2 class="ma-0 mat-text-accent">{{ invoice.total | currency:currencyCode:'symbol'}}</h2>
          </div>
          <div *ngIf="invoice.totalTips > 0" fxFlex.gt-sm="{{ invoice.totalTips > 0 ? '20' : '25'}}" fxFlex.gt-xs="50" fxFlex="100" class="{{ invoice.totalTips > 0 ? 'mt-1 mdc-mb-1 text-xs-left text-sm-right' : 'mt-1 mdc-mb-1 text-xs-left'}}">
            <h6 class="text-uppercase text-sm ma-0 text-bold">{{'TIPS' | translate}}</h6>
            <h2 class="ma-0 mat">{{ invoice.totalTips | currency:currencyCode:'symbol'}}</h2>
          </div>
        </div>
      </mat-card>

      <mat-card appearance="outlined" class="new-card py-1" *ngIf="invoice && invoice.id ">
        <app-view-payment-requests [invoice]="invoice" [contextIdDto]="contextIdDto" [requestForPaymentOwnerId]="requestForPaymentOwnerId" [requestForPaymentOwnerType]="requestForPaymentOwnerType"></app-view-payment-requests>
      </mat-card>

      <mat-card appearance="outlined" class="new-card py-1">
        <p class="text-md">
          <strong>
            {{'PAYMENT_TERMS_AND_POLICIES' | translate}}
          </strong>
          {{invoice.invoiceTerms | translate}}
        </p>
      </mat-card>

    </div>
  </ng-container>
  <ng-container *ngIf="invoice && !viewOnly">
    <mat-sidenav-container class="app-inner h-100" *ngIf="invoice && !adminMenu">
      <div class="actions" fxFlex="100" fxLayout="row" fxLayoutAlign="space-between baseline" *ngIf="!viewOnly">
        <div>
          <button (click)="back()" class="ml-xs mat-icon-button" mat-icon-button="">
            <span class="mat-button-wrapper">
              <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">arrow_back</mat-icon>
            </span>
            <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
            <div class="mat-button-focus-overlay"></div>
          </button>
        </div>
        <div>
          <button (click)="editInvoice()" class="ml-xs mat-icon-button" mat-icon-button="">
            <span class="mat-button-wrapper">
              <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">edit</mat-icon>
            </span>
            <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
            <div class="mat-button-focus-overlay"></div>
          </button>
          <button (click)="deleteInvoice()" class="ml-xs mat-icon-button" mat-icon-button="">
            <span class="mat-button-wrapper">
              <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">delete</mat-icon>
            </span>
            <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
            <div class="mat-button-focus-overlay"></div>
          </button>
        </div>
      </div>
      <mat-sidenav #sendInvoicePanel position="end" class="task-panel" mode="over" opened="false" style="width:80%">
        <app-send-invoice *ngIf="invoice && invoice.invoiceId &&  show === 'sendInvoice'" [contextIdDto]="contextIdDto" [visible]="true" [eventBus]="eventBus" (invoiceSent)="invoiceSent()" (invoiceSendCancelled)="invoiceSendCancelled()" [invoice]="invoice"></app-send-invoice>
        <app-view-payments *ngIf="invoice && invoice.invoiceId && show === 'viewPayments'" [contextIdDto]="contextIdDto" (invoiceUpdated)="this.invoice = $event" [visible]="true" [invoice]="invoice"></app-view-payments>
      </mat-sidenav>

      <div class="invoice-container" fxFlex="70" fxFlex.xs="100">
        <mat-card appearance="outlined" class="mt-0 new-card">
          <div class="mdc-mb-1">
            <h2 class="mt-0"><strong>{{'INVOICE' | translate}}</strong></h2>
            <a href="javascript:void(0)" *ngIf="invoice.companyDetails">{{invoice.companyDetails.companyEmail}}</a>
          </div>

          <div fxLayout="row" fxLayoutWrap="wrap">
            <div fxFlex.gt-xs="50" fxFlex="100">
              <h4 class="my-0" *ngIf="invoiceDto">{{invoiceDto.fullName}}</h4>
              <div fxLayout="column">
                <span>{{'INVOICE_NO' | translate}}: {{invoice.invoiceNumber}}</span>
                <span>{{'DATE_ISSUED' | translate}}: {{invoice.invoiceDate | localizedDate: 'mediumDate'}}</span>
                <span>{{'DUE_DATE' | translate}}: {{invoice.invoiceDate | localizedDate: 'mediumDate'}}</span>
                <span>{{'VOID' | translate}}: {{ invoice.invoiceVoid }}</span>
              </div>
            </div>
            <div fxFlex.gt-xs="50" fxFlex="100" class="text-sm-right text-xs-left" *ngIf="invoice.companyDetails">
              <!--h4 class="my-0">{{invoiceDto?.fullName}}</h4-->
              <div fxLayout="column" *ngIf="invoice.companyDetails.companyAddress">
                <span>{{invoice.companyDetails.companyTelephone}}</span>
                <span>{{invoice.companyDetails.companyAddress.address1}}</span>
                <span>{{invoice.companyDetails.companyAddress.address2}}</span>
                <span>{{invoice.companyDetails.companyAddress.city}}
                  , {{invoice.companyDetails.companyAddress.zipCode}}</span>
              </div>
            </div>
            <div fxLayout="row" fxLayoutWrap="wrap" *ngIf="invoice.paidInFull">
              <img src="assets/images/invoice/invoicePaid.svg" />
              <!--
            <h2>{{invoice.datePaid |date:'medium'}}</h2>
            -->
            </div>
          </div>
        </mat-card>

        <!--mat-card class="new-card pl-1" *ngIf="invoiceDto.invoice.in">
        <div class="mdc-mb-1">
          <h2 class="mt-0"><strong>{{'Products' | translate}}</strong></h2>
        </div>
        <ngx-datatable class="material" [rows]="invoiceDto.invoiceProductRowDtos" [columnMode]="'flex'"
                       [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'">
          <ngx-datatable-column name="{{'Description' | translate}}" [flexGrow]="3">
            <ng-template ngx-datatable-cell-template let-row="row">
              <strong>{{row.productName}}</strong><br /><span class="mat-text-muted">{{row.productDescription}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'Unit Price' | translate}}" [flexGrow]="1">
            <ng-template ngx-datatable-cell-template let-row="row">
              <strong>{{row.unitPrice | currency:currencyCode:'symbol'}}</strong>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'Tax Rate' | translate}}" [flexGrow]="0.6">
            <ng-template ngx-datatable-cell-template let-row="row">
              <strong>{{row.tax}}</strong>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'Quantity' | translate}}" [flexGrow]="1">
            <ng-template ngx-datatable-cell-template let-row="row">
              <strong>{{row.quantity}}</strong>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'Amount' | translate}}" [flexGrow]="1">
            <ng-template ngx-datatable-cell-template let-row="row">
              <strong>{{ row.grossPrice | currency:currencyCode:'symbol'}}</strong>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </mat-card-->

        <mat-card appearance="outlined" class="new-card pl-1" *ngIf="invoiceDto && invoiceDto.showPackages && invoiceDto.invoicePackageRowDtos && invoiceDto.invoicePackageRowDtos.length">
          <div class="mdc-mb-1">
            <h2 class="mt-0"><strong>{{'PACKAGE' | translate}}</strong></h2>
          </div>
          <ngx-datatable class="material" [rows]="invoiceDto.invoicePackageRowDtos" [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'" [scrollbarH]="true">
            <ngx-datatable-column name="{{'DESCRIPTION' | translate}}" [flexGrow]="3">
              <ng-template ngx-datatable-cell-template let-row="row">
                <strong>{{row.packageName}}</strong>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'UNIT_PRICE' | translate}}" [flexGrow]="1">
              <ng-template ngx-datatable-cell-template let-row="row">
                <strong>{{row.unitPrice | currency:currencyCode:'symbol'}}</strong>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'TAX_RATE' | translate}}" [flexGrow]="0.6">
              <ng-template ngx-datatable-cell-template let-row="row">
                <strong>{{row.tax}}</strong>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'QUANTITY' | translate}}" [flexGrow]="1">
              <ng-template ngx-datatable-cell-template let-row="row">
                <strong>{{row.quantity}}</strong>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'DISCOUNT' | translate}}" [flexGrow]="0.6">
              <ng-template ngx-datatable-cell-template let-row="row">
                {{row?.discountDetails | discountTmpl:currencyCode}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'AMOUNT' | translate}}" [flexGrow]="1">
              <ng-template ngx-datatable-cell-template let-row="row">
                <app-discount-price-view [originalPrice]="row.grossPriceNoOffer" [finalPrice]="row.grossPrice" [currencyCode]="currencyCode"></app-discount-price-view>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </mat-card>

        <mat-card appearance="outlined" class="new-card pl-1" *ngIf="invoiceDto && invoiceDto.showItemRows && invoiceDto.invoice.invoiceItemRows && invoiceDto.invoice.invoiceItemRows.length">
          <div class="mdc-mb-1">
            <h2 class="mt-0"><strong>{{'INVOICE_ITEMS' | translate}}</strong></h2>
          </div>
          <ngx-datatable class="material" [rows]="invoiceDto.invoice.invoiceItemRows" [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'">
            <ngx-datatable-column name="{{'DESCRIPTION' | translate}}" [flexGrow]="3">
              <ng-template ngx-datatable-cell-template let-row="row">
                <strong>{{row.name}}</strong><br /><span class="mat-text-muted">{{row.description}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'UNIT_PRICE' | translate}}" [flexGrow]="1">
              <ng-template ngx-datatable-cell-template let-row="row">
                <strong>{{row.unitPrice | currency:currencyCode:'symbol'}}</strong>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'TAX_RATE' | translate}}" [flexGrow]="0.6">
              <ng-template ngx-datatable-cell-template let-row="row">
                <strong>{{row.tax}}</strong>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'QUANTITY' | translate}}" [flexGrow]="1">
              <ng-template ngx-datatable-cell-template let-row="row">
                <strong>{{row.quantity}}</strong>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'AMOUNT' | translate}}" [flexGrow]="1">
              <ng-template ngx-datatable-cell-template let-row="row">
                <strong>{{ row.grossPrice | currency:currencyCode:'symbol'}}</strong>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </mat-card>

        <mat-card appearance="outlined" class="new-card pl-1" *ngIf="invoiceDto && invoiceDto.showCourses && invoiceDto.invoice.invoiceCourseRows && invoiceDto.invoice.invoiceCourseRows.length">
          <div class="mdc-mb-1">
            <h2 class="mt-0"><strong>{{'COURSES' | translate}}</strong></h2>
          </div>
          <ngx-datatable class="material" [rows]="invoiceDto.invoice.invoiceCourseRows" [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'">
            <ngx-datatable-column name="{{'DESCRIPTION' | translate}}" [flexGrow]="3">
              <ng-template ngx-datatable-cell-template let-row="row">
                <strong>{{row.name}}</strong><br /><span class="mat-text-muted">{{row.description}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'UNIT_PRICE' | translate}}" [flexGrow]="1">
              <ng-template ngx-datatable-cell-template let-row="row">
                <strong>{{row.unitPrice | currency:currencyCode:'symbol'}}</strong>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'TAX_RATE' | translate}}" [flexGrow]="0.6">
              <ng-template ngx-datatable-cell-template let-row="row">
                <strong>{{row.tax}}</strong>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'QUANTITY' | translate}}" [flexGrow]="1">
              <ng-template ngx-datatable-cell-template let-row="row">
                <strong>{{row.quantity}}</strong>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'DISCOUNT' | translate}}" [flexGrow]="0.6">
              <ng-template ngx-datatable-cell-template let-row="row">
                {{row?.discountDetails | discountTmpl:currencyCode}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'AMOUNT' | translate}}" [flexGrow]="1">
              <ng-template ngx-datatable-cell-template let-row="row">
                <!-- <strong>{{ row.grossPrice | currency:currencyCode:'symbol'}}</strong> -->
                <app-discount-price-view [originalPrice]="row.grossPriceNoOffer" [finalPrice]="row.grossPrice" [currencyCode]="currencyCode"></app-discount-price-view>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </mat-card>

        <mat-card appearance="outlined" class="new-card pl-1" *ngIf="invoiceDto && invoiceDto.showServices && invoiceDto.invoiceServiceRowDtos && invoiceDto.invoiceServiceRowDtos.length">
          <div class="mdc-mb-1">
            <h2 class="mt-0"><strong>{{'SERVICES' | translate}}</strong></h2>
          </div>
          <ngx-datatable class="material" [rows]="invoiceDto.invoiceServiceRowDtos" [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'" [scrollbarH]="true">
            <ngx-datatable-column name="{{'DESCRIPTION' | translate}}" [flexGrow]="3">
              <ng-template ngx-datatable-cell-template let-row="row">
                <strong>{{row?.serviceName}}</strong><br /><span class="mat-text-muted">{{row.serviceDescription}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'UNIT_PRICE' | translate}}" [flexGrow]="1">
              <ng-template ngx-datatable-cell-template let-row="row">
                <strong>{{row.unitPrice | currency:currencyCode:'symbol'}}</strong>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'TAX_RATE' | translate}}" [flexGrow]="0.6">
              <ng-template ngx-datatable-cell-template let-row="row">
                <strong>{{row.tax}}</strong>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'QUANTITY' | translate}}" [flexGrow]="1">
              <ng-template ngx-datatable-cell-template let-row="row">
                <strong>{{row.quantity}}</strong>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'DISCOUNT' | translate}}" [flexGrow]="0.6">
              <ng-template ngx-datatable-cell-template let-row="row">
                {{row?.discountDetails | discountTmpl:currencyCode}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'AMOUNT' | translate}}" [flexGrow]="1">
              <ng-template ngx-datatable-cell-template let-row="row">
                <!-- <strong>{{ row.grossPrice | currency:currencyCode:'symbol'}}</strong> -->
                <app-discount-price-view [originalPrice]="row.grossPriceNoOffer" [finalPrice]="row.grossPrice" [currencyCode]="currencyCode"></app-discount-price-view>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </mat-card>

        <mat-card appearance="outlined" class="new-card pl-1" *ngIf="invoiceDto && invoiceDto.showProducts && invoiceDto.invoiceProductRowDtos && invoiceDto.invoiceProductRowDtos.length">
          <div class="mdc-mb-1">
            <h2 class="mt-0"><strong>{{'PRODUCTS' | translate}}</strong></h2>
          </div>
          <ngx-datatable class="material" [rows]="invoiceDto.invoiceProductRowDtos" [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'" [scrollbarH]="true">
            <ngx-datatable-column name="{{'DESCRIPTION' | translate}}" [flexGrow]="3">
              <ng-template ngx-datatable-cell-template let-row="row">
                <strong>{{row.productName}}</strong><br /><span class="mat-text-muted">{{row.productDescription}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'UNIT_PRICE' | translate}}" [flexGrow]="1">
              <ng-template ngx-datatable-cell-template let-row="row">
                <strong>{{row.unitPrice | currency:currencyCode:'symbol'}}</strong>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'TAX_RATE' | translate}}" [flexGrow]="0.6">
              <ng-template ngx-datatable-cell-template let-row="row">
                <strong>{{row.tax}}</strong>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'QUANTITY' | translate}}" [flexGrow]="1">
              <ng-template ngx-datatable-cell-template let-row="row">
                <strong>{{row.quantity}}</strong>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'DISCOUNT' | translate}}" [flexGrow]="0.6">
              <ng-template ngx-datatable-cell-template let-row="row">
                {{row?.discountDetails | discountTmpl:currencyCode}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'AMOUNT' | translate}}" [flexGrow]="1">
              <ng-template ngx-datatable-cell-template let-row="row">
                <!-- <strong>{{ row.grossPrice | currency:currencyCode:'symbol'}}</strong> -->
                <app-discount-price-view [originalPrice]="row.grossPriceNoOffer" [finalPrice]="row.grossPrice" [currencyCode]="currencyCode"></app-discount-price-view>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </mat-card>

        <mat-card appearance="outlined" class="new-card py-1">
          <div fxLayout="row" fxLayoutWrap="wrap" *ngIf="invoice">
            <div fxFlex="100" class="mt-1 mdc-mb-1" *ngIf="invoice.discountId">
              <strong>{{discountItemsById[invoice.discountId]?.code || 'CUSTOM'}}</strong> - {{invoice?.discountDetails
              | discountTmpl:currencyCode}}
            </div>
          </div>
          <div fxLayout="row" fxLayoutWrap="wrap">
            <div fxFlex.gt-sm="{{ invoice.totalTips > 0 ? '20' : '25'}}" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
              <h6 class="text-uppercase text-md ma-0 text-bold">{{'SUBTOTAL' | translate}}</h6>
              <h2 *ngIf="invoice?.discountId" class="ma-0">{{invoice.subTotalWithoutDiscount |
                currency:currencyCode:'symbol'}}</h2>
              <h2 *ngIf="!invoice?.discountId" class="ma-0">{{invoice.subTotal | currency:currencyCode:'symbol'}}</h2>
            </div>
            <div fxFlex.gt-sm="{{ invoice.totalTips > 0 ? '20' : '25'}}" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
              <h6 class="text-uppercase text-md ma-0 text-bold">{{'TAX' | translate}}</h6>
              <h2 *ngIf="invoice?.discountId" class="ma-0">{{invoice.taxWithoutDiscount |
                currency:currencyCode:'symbol'}}</h2>
              <h2 *ngIf="!invoice?.discountId" class="ma-0">{{invoice.tax | currency:currencyCode:'symbol'}}</h2>
            </div>
            <div fxFlex.gt-sm="{{ invoice.totalTips > 0 ? '20' : '25'}}" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
              <h6 class="text-uppercase text-md ma-0 text-bold">{{'DISCOUNT' | translate}}</h6>
              <h2 *ngIf="invoice.discountId">{{invoice.invoiceDiscount | currency:currencyCode:'symbol'}}</h2>
              <h2 *ngIf="!invoice.discountId">{{0.00 | currency:currencyCode:'symbol'}}</h2>
            </div>
            <div fxFlex.gt-sm="{{ invoice.totalTips > 0 ? '20' : '25'}}" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1 text-xs-left text-sm-right">
              <h6 class="text-uppercase text-sm ma-0 text-bold">{{'TOTAL' | translate}}</h6>
              <h2 class="ma-0 mat-text-accent">{{ invoice.total | currency:currencyCode:'symbol'}}</h2>
            </div>
            <div *ngIf="invoice.totalTips > 0" fxFlex.gt-sm="{{ invoice.totalTips > 0 ? '20' : '25'}}" fxFlex.gt-xs="50" fxFlex="100" class="{{ invoice.totalTips > 0 ? 'mt-1 mdc-mb-1 text-xs-left text-sm-right' : 'mt-1 mdc-mb-1 text-xs-left'}}">
              <h6 class="text-uppercase text-sm ma-0 text-bold">{{'TIPS' | translate}}</h6>
              <h2 class="ma-0 mat">{{ invoice.totalTips | currency:currencyCode:'symbol'}}</h2>
            </div>
          </div>
        </mat-card>

        <mat-card appearance="outlined" class="new-card py-1" *ngIf="invoice && invoice.id">
          <app-view-payment-requests [invoice]="invoice" [contextIdDto]="contextIdDto" [requestForPaymentOwnerId]="requestForPaymentOwnerId" [requestForPaymentOwnerType]="requestForPaymentOwnerType"></app-view-payment-requests>
        </mat-card>

        <mat-card appearance="outlined" class="new-card py-1">
          <p class="text-md">
            <strong>
              {{'PAYMENT_TERMS_AND_POLICIES' | translate}}
            </strong>
            {{invoice.invoiceTerms | translate}}
          </p>
        </mat-card>

        <mat-card appearance="outlined" class="new-card py-1" *ngIf="!viewOnly">
          <button mat-raised-button color="accent" *ngIf="invoice.id" (click)="sendInvoice()">{{'SEND' | translate}}
            {{'INVOICE' | translate}}
          </button>
          &nbsp;

          &nbsp;
          <button mat-raised-button color="accent" *ngIf="invoice.id && !invoice.invoiceVoid" (click)="viewInvoice()">
            {{'VIEW_INVOICE' | translate}}
          </button>
          &nbsp;
          &nbsp;
          <button mat-raised-button color="accent" *ngIf="invoice.id" (click)="viewPayments()">{{'VIEW_PAYMENTS' | translate}}
          </button>
          &nbsp;
          &nbsp;
          <button mat-raised-button color="accent" *ngIf="invoice.id && !invoice.invoiceVoid" (click)="voidInvoice()">Void
            {{'INVOICE' | translate}}
          </button>
          &nbsp;
        </mat-card>
      </div>
    </mat-sidenav-container>
    <ng-container *ngIf="invoice && adminMenu">

    </ng-container>
  </ng-container>

</div>
