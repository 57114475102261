import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ChangeListener } from './changeListener';
import { NavigateService } from '../entry/NavigateService';
import { EicompService } from '@savvy/entity-instance-composite';
import { Page } from '../shared/model/page';
import { TrialExpiredService } from '../shared/services/trial-expired.service';
import { ListEntityListDto, ContextIdDto, ListEntityListItemDto, EntityInstanceId } from '@savvy/view-definition';
import { ViewContextDto } from '@savvy/view-composite';

@Component({
  selector: 'app-list-entitys-list',
  templateUrl: 'listEntitysList.component.html'
})
export class ListEntitysListComponent implements OnInit {

  @Input() listEntityListDto: ListEntityListDto;
  @Input() contextIdDto: ContextIdDto;
  @Input() viewContext: ViewContextDto;
  @Input() form: UntypedFormGroup;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;

  page = new Page();
  selected: ListEntityListItemDto[] = [];


  columns = [
    { prop: 'displayText', name: '' }
  ];

  constructor(
    private router: Router,
    public trialExpiredService: TrialExpiredService,
    private naviateService: NavigateService,
    private api: EicompService) {
  }
  ngOnInit() {
    this.page.size = 10;
    //  this.setPage({ offset: 0 });
  }
  setPage(pageInfo) {
    console.log('pageInfo is ' + pageInfo.offset);
    this.page.pageNumber = pageInfo.offset;
    //  this.loadPage();
  }

  loadPage() {
    // Call list display names and pass page number
    /*this.cachedListCompositeApi.listEntityList(
      this.listEntityListDto.entityDefinitionId.id,
      (this.page.pageNumber + 1),
      this.page.size,
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType).subscribe(loadProductsResponse => {
        this.listEntityListDto = loadProductsResponse.listEntityListDto;
        this.page.totalElements = loadProductsResponse.listEntityListDto.totalRows;
        this.page.totalPages = loadProductsResponse.listEntityListDto.totalPages;
    });*/
  }
  onSelect() {
    this.naviateService.viewEntity(this.contextIdDto, this.selected[0].entityInstanceId);
  }

  rowSelected(entityInstanceId: EntityInstanceId) {
    this.naviateService.viewEntity(this.contextIdDto, entityInstanceId);
  }

  create() {
    console.log('inside create in ListEntitysListComponent');
    if (this.viewContext.viewContextTypeDto === ViewContextDto.ViewContextTypeDtoEnum.Customer) {
      this.router.navigate(['/entity/createEntity',
        this.listEntityListDto.entityDefinitionId.id,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.viewContext.customerContextDto.customerId.id
      ]);
    } else {
      this.router.navigate(['/entity/createEntity',
        this.listEntityListDto.entityDefinitionId.id,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.viewContext.entityInstanceId.id,
        this.viewContext.entityDefinitionId.id
      ]);
    }
  }
}
