import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContextIdDto } from '@savvy/view-definition';
import { CreateEmbeddedEntityComponent } from '../createEmbeddedEntityComponent.component';
import { EventBusService } from '../EventBusService';

export interface AddEntityDialogData {
  entityInstanceId: string;
  entityDefinitionId: string;
  entityDefinitionLabel: string;
  contextIdDto: ContextIdDto;
  eventBus: EventBusService;
}

@Component({
  selector: 'app-add-entity-dialog',
  templateUrl: './addEntityDialog.component.html'
})
export class AddEntityDialogComponent {

  contextIdDto: ContextIdDto;
  entityDefinitionId: string;
  entityDefinitionLabel: string;
  eventBus: EventBusService;

  @ViewChild('createEmbeddedComponent', { static: true }) createEmbeddedComponent: CreateEmbeddedEntityComponent;

  constructor(
    public dialogRef: MatDialogRef<AddEntityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddEntityDialogData) {
    this.contextIdDto = data.contextIdDto;
    this.entityDefinitionId = data.entityDefinitionId;
    this.entityDefinitionLabel = data.entityDefinitionLabel;
    this.eventBus = data.eventBus;
  }
  entityCreated(entityInstanceId: string) {
    this.data.entityInstanceId = entityInstanceId;
  }
}
