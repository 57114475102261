<div *ngIf="showSpinner" class="bg-spinner-wrapper">
  <mat-spinner style="margin: 0 auto;"></mat-spinner>
</div>
<div style="width: 60vw;">
  <mat-toolbar *ngIf="visible" color="primary" >
    <mat-toolbar-row>{{'SEND_MESSAGE' | translate}}</mat-toolbar-row>
  </mat-toolbar>
  <mat-card appearance="outlined" *ngIf="visible && customer">
    <mat-card-content>
      <form #msgForm="ngForm">

        <!-- Email Message -->
        <mat-form-field *ngIf="selectedMessageType === messageTypes[0]" appearance="outline" style="width: 100%">
          <mat-label>{{'TO' | translate}}</mat-label>
          <input type="text" matInput name="recipient" #recipient="ngModel" [(ngModel)]="emailAddresses" placeholder="{{'TO' | translate}}" required disabled>
          <mat-error *ngIf="recipient.hasError('required')">
            {{'TO_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
          </mat-error>
        </mat-form-field>
        <br>

        <!-- SMS Message -->
        <div *ngIf="selectedMessageType === messageTypes[1]">

          <!--mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{'From' | translate}}</mat-label>
            <input type="text" matInput name="from" #from="ngModel" [(ngModel)]="smsSenderId" placeholder="{{'From' | translate}}" required appSenderidValidator>
            <mat-error *ngIf="from.hasError('required')">
              {{'From is' | translate}} <strong>{{'required' | translate}}</strong>
            </mat-error>
            <mat-error *ngIf="from.hasError('senderIdValidator') && !from.hasError('required')">
              {{'Invalid from value.  SMS from value can be up to 11 characters from the following categories: Upper-case letters A-Z, Lower-case letters a-z, Numbers 0-9, Spaces.  It must include at least one letter; It cannot be comprised of only numbers. Special characters and punctuation are not allowed.' | translate}}
            </mat-error>
          </mat-form-field-->

          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{'TO' | translate}}</mat-label>
            <input type="text" matInput name="smsNumber" #smsNumber="ngModel" [(ngModel)]="smsNumbers" placeholder="{{'TO' | translate}}" required disabled>
            <mat-error *ngIf="smsNumber.hasError('required')">
              {{'TO_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" style="width: 100%;">
            <mat-label>{{'MESSAGE' | translate}}</mat-label>
            <textarea matInput #msgTxt="ngModel" name="msgTxt" required [(ngModel)]="messageText" placeholder="{{'ENTER_MESSAGE' | translate}}"></textarea>
            <mat-error *ngIf="msgTxt.hasError('required')">
              {{'MESSAGE_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
            </mat-error>
          </mat-form-field>
        </div>

        <div [hidden]="selectedMessageType !== messageTypes[0]">

          <app-email-template-select [contextIdDto]="contextIdDto" [emailTemplateId]="emailTemplateId" (emailTemplateSelect)="onEmailTemplateChange($event)">

          </app-email-template-select>

        </div>

        <ng-container *ngIf="selectedMessageType === messageTypes[0]">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{'SUBJECT' | translate}}</mat-label>
            <input matInput
                   placeholder="{{'SUBJECT' | translate}}"
                   name="subjectFieldName"
                   #subjectFieldName="ngModel"
                   [(ngModel)]="subject"
                   [required]="true"
                   id="subjectFieldName" required>
            <mat-error *ngIf="subjectFieldName.hasError('required')">
              {{'SUBJECT_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <div class="mat-form-field-wrapper">
            <app-flo-editor [text]="body" [required]="true" (textUpdated)="bodyUpdated($event)"></app-flo-editor>
          </div>
        </ng-container>


        <mat-radio-group name="radio" [(ngModel)]="selectedMessageType">
          <mat-radio-button style="margin: 10px 10px 15px 0;" *ngFor="let messageType of messageTypes" [value]="messageType">
            {{messageType}}
          </mat-radio-button>
        </mat-radio-group>

      </form>

    </mat-card-content>

    <mat-card-content>
      <button mat-raised-button color="accent" (click)="send()" [disabled]="!msgForm.valid || !checkIfAllowedToSend() || !messageDefined()">{{'SEND' | translate}}</button>
      &nbsp;
      <button mat-raised-button (click)="cancelIt()">{{'CANCEL' | translate}}</button>
    </mat-card-content>

    <div *ngIf="!checkIfAllowedToSend()">
      <mat-error>{{'CUSTOMER_OPTED_OUT_MESSAGE' | translate}} {{selectedMessageType | translate}} {{'CANT_SEND_MESSAGE_TWO' | translate}}.</mat-error>
    </div>

  </mat-card>
</div>

