<mat-toolbar color="primary" *ngIf="workflowDef">
  <mat-toolbar-row *ngIf="workflowDef.id">
    <app-breadcrumb></app-breadcrumb>
    {{ workflowDef.name }} {{'MESSAGES' | translate}}
    <span fxFlex></span>
  </mat-toolbar-row>
  <mat-toolbar-row *ngIf="!workflowDef.id">
    <app-breadcrumb></app-breadcrumb>
  </mat-toolbar-row>

</mat-toolbar>
<mat-sidenav-container class="app-inner" *ngIf="workflowDefinitionId">
  <mat-sidenav #sidePanel position="end" mode="over" opened="false" style="width: 80%">
    <app-add-edit-workflow-message-definition
      #addEditWorkflowMessageDef
      *ngIf="selectedTaskDef"
      (taskRemoved)="taskRemoved()"
      (taskAdded)="reloadWorkflow()"
      (taskUpdated)="loadWorkflowState()"
      [taskDef]="selectedTaskDef"
      [users]="response.users"
      [roles]="response.roles"
      [emailMessageTemplates]="response.emailMessageTemplates"
      [actionTypes]="response.actionTypes"
      [taskTypes]="response.taskTypes"
      [contextIdDto]="contextIdDto"
      [workflowState]="workflowState"
      [workflowDefinitionSummaries]="response.workflowDefinitionSummaryDtos"
      [workflowDefinitionId]="workflowDefinitionId">
    </app-add-edit-workflow-message-definition>
  </mat-sidenav>

  <div *ngIf="workflowDef">
    <mat-card appearance="outlined" *ngFor="let workflowState of workflowDef.workflowStateList" class="example-card">
      <mat-card-header>
        <mat-card-title>{{ workflowState.state }} {{'MESSAGES' | translate}}</mat-card-title>
      </mat-card-header>
      <mat-form-field appearance="outline">
        <mat-label>{{'COLOUR' | translate}}</mat-label>

        <input
          matInput
          [(colorPicker)]="workflowState.colour"
          [style.background]="workflowState.colour"
          style="height:100%; width:100%"
          [value]="workflowState.colour"
          [required]="true"
          (colorPickerChange)="setColor($event, workflowState)"
          [cpOutputFormat]="'hex'"
          placeholder="{{'PICK_COLOUR' | translate}}" />

        <!-- <mat-error *ngIf="form.controls[elementInstanceDto.instanceId].hasError('required')">
          {{fieldDefinition.label + ' is ' | translate}}
          <strong>{{'required in the format #123343' | translate}}</strong>
        </mat-error>  -->
      </mat-form-field>

      <ngx-datatable
        *ngIf="workflowState.id && workflowState.workflowTaskDefinitionList"
        class='material'
        [columns]="columns"
        [columnMode]="'force'"
        [headerHeight]="50"
        [footerHeight]="0"
        [rowHeight]="'auto'"
        [limit]="10"
        [selected]="selected"
        [selectionType]="'cell'"
        (select)="onSelect($event, workflowState)"
        [rows]='workflowState.workflowTaskDefinitionList'>

        <ngx-datatable-column name="{{'MESSAGE_NAME' | translate}}" [flexGrow]="7">
          <ng-template ngx-datatable-cell-template let-row="row">
            {{row.taskSettings.taskName}}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="{{'EDIT' | translate}}" [flexGrow]="1">
          <ng-template ngx-datatable-header-template>
            <button mat-mini-fab color="accent" style="top: -10px !important;" type="button"
              (click)="createTask(workflowState)">
              <mat-icon>add</mat-icon>
            </button>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </mat-card>
  </div>

</mat-sidenav-container>
