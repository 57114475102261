import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FloSnackbarComponent } from '../../../snackbar/floSnackbar.component';
// import '@adyen/adyen-web/dist/adyen.css';
import { ContextIdDto } from '@savvy/view-definition';
import { ChargeManually, PlanDto, PlansCompService, PlanService, UpdatePlanCost } from '@savvy/plan';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoadPaymentsByPaymentReferences, Payment, PaymentService } from '@savvy/payment';


export interface PlanSummary {
  customerFullName: string;
  customerEmail: string;
  startDate: string;
  endDate: string;
  status: string;
}

@Component({
  selector: 'app-view-plan',
  templateUrl: './view-plan.component.html',
  styleUrls: ['./view-plan.component.scss']
})
export class ViewPlanComponent implements OnInit {

  contextIdDto: ContextIdDto;
  plan = <PlanDto>{};
  payments: Array<Payment> = [];

  updateCostReq = <UpdatePlanCost>{};

  summaryDetails: PlanSummary[] = [];
  displayedColumns: string[] = ['customerFullName', 'customerEmail', 'startDate', 'endDate', 'status'];

  @Output() added: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private notify: FloSnackbarComponent,
    private planApi: PlanService,
    private planCompApi: PlansCompService,
    private paymentApi: PaymentService,
    public dialogRef: MatDialogRef<ViewPlanComponent>
  ) {
    this.contextIdDto = data.contextIdDto;
    this.plan = data.plan;
  }

  ngOnInit() {
    this.loadSummaryDetails();
    this.loadPayments();

    this.updateCostReq.contextIdDto = this.contextIdDto;
    this.updateCostReq.planId = this.plan.id;
    this.updateCostReq.amount = this.plan.cost;
  }

  loadSummaryDetails() {
    const summary = <PlanSummary>{};
    summary.customerFullName = this.plan.customerFullName;
    summary.customerEmail = this.plan.customerEmail;
    summary.startDate = this.plan.startDate;
    summary.endDate = this.plan.endDate;
    summary.status = this.parsePlanStatus(this.plan.status);

    this.summaryDetails.push(summary);

    this.summaryDetails = Object.assign([], this.summaryDetails);

  }

  loadPayments() {
    if (this.plan && this.plan.id
      && this.plan.paymentReferences && this.plan.paymentReferences.length > 0) {
      const req = <LoadPaymentsByPaymentReferences>{};
      req.contextIdDto = this.contextIdDto;
      req.paymentReferences = this.plan.paymentReferences;

      this.paymentApi.loadPaymentsByPaymentReferences(req).subscribe(res => {
        this.payments = res;
      });
    }
  }

  delete() {
    this.planCompApi.deletePlan(this.contextIdDto.contextId, this.contextIdDto.contextIdType, this.plan.id).subscribe(() => {
      this.notify.message = 'Successfully deleted plan.';
      this.notify.open();

      this.dialogRef.close();
    });
  }

  takePayment() {

    console.log(this.plan.id);
    const req = <ChargeManually>{};
    req.contextIdDto = this.contextIdDto;
    req.planId = this.plan.id;
    this.planCompApi.chargeManually(req).subscribe(res => {
      if (res.plan.status == 'ACTIVE') {
        this.notify.message = 'Successfully charged customer.';
        this.notify.open();
      } else if (res.plan.status == 'PAYMENT_FAILED') {
        this.notify.message = 'Failed charged customer.';
        this.notify.open();
      }
    });
  }

  parsePlanStatus(status) {
    if (status === 'PENDING_PAYMENT') {
      return 'Pending Payment';
    } else if (status === 'ACTIVE') {
      return 'Active';
    } else if (status === 'PAYMENT_FAILED') {
      return 'Payment Failed';
    } else if (status === 'EXPIRED') {
      return 'Expired';
    }
  }

  updateCost() {
    this.planApi.updatePlanCost(this.updateCostReq).subscribe(() => {
      this.notify.message = 'Successfully updated plan cost.';
      this.notify.open();
    });
  }

}
