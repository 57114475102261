import { ElementRef, Injectable } from '@angular/core';
import { GLOBAL } from 'src/app/app.constants';
@Injectable()
export class ThemeService {

  private themes: { label: string; value: string }[] = [
    {
      label: 'Dark',
      value: 'app-dark'
    },
    {
      label: 'Red',
      value: 'app-deep-red'
    },
    {
      label: 'Teal',
      value: 'app-teal'
    },
    {
      label: 'Purple',
      value: 'app-deep-purple'
    },
    {
      label: 'Orange',
      value: 'app-orange'
    },
    {
      label: 'Brown',
      value: 'app-brown'
    },
    {
      label: 'Green',
      value: 'app-green'
    },
    {
      label: 'Pink',
      value: 'app-pink'
    },
    {
      label: 'Mustard',
      value: 'app-mustard'
    }
  ];

  constructor() {
  }

  public getThemes() {
    return [...this.themes];
  }

}
