import { Injectable } from '@angular/core';
import { ContextService } from '../context.service';
import { ContextIdDto } from '@savvy/app';
import { MatDialog } from '@angular/material/dialog';
import { ViewPetDetailsV2Component } from 'src/app/flo/pets/view-pet-details-v2/view-pet-details-v2.component';
import { CreatePetDetailsV2Component } from './create-pet-details-v2/create-pet-details-v2.component';
import { ConfirmationModalComponent } from '../shared/components/confirmation-modal/confirmation-modal.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { Pet, PetService } from '@savvy/pet';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class PetHandlerService {

  constructor(
    private contextService: ContextService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private petService: PetService
  ) {

  }

  public add(contextIdDto: ContextIdDto, customerId?: string, callback?: (data?: any) => void) {
    const createPetModal = this.dialog.open(CreatePetDetailsV2Component, {
      data: {
        contextIdDto,
        customerId
      },
      maxWidth: '1024px',
      panelClass: [
        'modal-100',
        'helpwindow',
        'petProfileModal'
      ],
      autoFocus: false
    });

    createPetModal.afterClosed()
      .subscribe(
        (res) => {
          callback(res);
        });

  }

  public view(contextIdDto: ContextIdDto, customerId: string, petId: string, callback?: (data?: any) => void) {
    const createPetModal = this.dialog.open(ViewPetDetailsV2Component, {
      data: {
        contextIdDto,
        customerId,
        petId
      },
      maxWidth: '1024px',
      panelClass: [
        'modal-100',
        'helpwindow',
        'petProfileModal'
      ],
      autoFocus: false
    });

    createPetModal.afterClosed()
      .subscribe(
        (response) => {
          callback(response);
          //   this.loadPetFlags(petId);
        });
  }

  public edit(contextIdDto: ContextIdDto, customerId: string, petId: string, callback?: (data?: any) => void) {
    const createPetModal = this.dialog.open(CreatePetDetailsV2Component, {
      data: {
        contextIdDto,
        customerId,
        petId
      },
      maxWidth: '1024px',
      panelClass: [
        'modal-100',
        'helpwindow',
        'petProfileModal'
      ],
      autoFocus: false
    });

    createPetModal.afterClosed()
      .subscribe(
        (res) => {
          callback(res);
        });
  }

  softDelete(contextIdDto: ContextIdDto, pet: Pet, callback?: (data?: any) => void) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        name: pet?.name,
        isChange: false,
        message: 'Are you sure you want to delete this pet'
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && pet?.id) {
        this.spinner.show();
        pet.deleted = true;
        this.petService.deletePet(pet.id).subscribe(response => {
          if (callback)
            callback(response);
          this.spinner.hide();
        }, error => {
          pet = null;
          this.spinner.hide();
        });
      }
    });
  }


}
