<app-list-cached-entitys-list
  [contextIdDto]="contextIdDto"
  [listCachedEntityListDefinition]="viewCachedEntitiesListDefinition.listCachedEntityListDefinition"
  [viewContext]="viewContext"
  [eventBus]="eventBus"
  [useModalOnClick]="viewCachedEntitiesListDefinition.useModalOnClick"
  [showPlaceholder]="showPlaceholder"
  [form]="form"
  [changeListener]="changeListener">
</app-list-cached-entitys-list>
