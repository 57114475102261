<mat-card appearance="outlined" class="label-value-panel panel new-card" *ngIf="labelValuePanelDto.labelValueDtos">
    <mat-card-header>
      <mat-card-title>{{labelValuePanelDto.title}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div fxLayout="row" class="entry-list">
            <div fxFlex="50" fxLayout="column">
                <div *ngFor="let labelValue of labelValuePanelDto.labelValueDtos">
                  <p class="mat-text-muted mt-xs mb-xs">
                    {{labelValue.label | translate}}
                  </p>
                </div>
            </div>
            <div fxFlex="50" fxLayout="column">
                <div *ngFor="let labelValue of labelValuePanelDto.labelValueDtos">
                  <p class="mat-text-muted mt-xs mb-xs">
                    {{labelValue.value}}
                  </p>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>
