<mat-toolbar color="primary" *ngIf="workflowState">
  <mat-toolbar-row *ngIf="workflowState.id">
    <app-breadcrumb></app-breadcrumb>
    <div [class.example-spacer]="true"></div>
    <button (click)="deleteState()" class="ml-xs mat-icon-button" mat-icon-button="">
                <span class="mat-button-wrapper">
                    <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">delete</mat-icon>
                </span>
      <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
      <div class="mat-button-focus-overlay"></div>
    </button>
  </mat-toolbar-row>
  <mat-toolbar-row *ngIf="!workflowState.id">
    <app-breadcrumb></app-breadcrumb>
  </mat-toolbar-row>
</mat-toolbar>

<mat-sidenav-container class="app-inner">
  <mat-sidenav #sidePanel position="end" mode="over" opened="false" style="width: 80%">
    <app-add-edit-workflow-task-definition #addEditWorkflowTaskDef *ngIf="selectedTaskDef"
                                           (taskRemoved)="taskRemoved()"
                                           (taskAdded)="loadWorkflowState()"
                                           (taskUpdated)="loadWorkflowState()"
                                           [taskDef]="selectedTaskDef"
                                           [users]="response.users"
                                           [roles]="response.roles"
                                           [emailMessageTemplates]="response.emailMessageTemplates"
                                           [actionTypes]="response.actionTypes"
                                           [taskTypes]="response.taskTypes"
                                           [contextIdDto]="contextIdDto"
                                           [workflowState]="workflowState"
                                           [workflowDefinitionSummaries]="response.workflowDefinitionSummaryDtos"
                                           [workflowDefinitionId]="workflowDefinitionId"></app-add-edit-workflow-task-definition>

  </mat-sidenav>

  <mat-card appearance="outlined" *ngIf="workflowState">
    <mat-card-content>
      <mat-form-field style="width: 100%" appearance="outline">
        <mat-label>{{'STEP_NAME' | translate}}</mat-label>
        <input
          matInput
          [(ngModel)]="workflowState.state"
          #stateName="ngModel"
          class="example-full-width"
          name="stateName"
          placeholder="State Name" required>
        <mat-error *ngIf="stateName.hasError('required')">
          {{'STEP_NAME_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
        </mat-error>
      </mat-form-field>
    </mat-card-content>

    <ngx-datatable
      *ngIf="workflowState.id && workflowState.workflowTaskDefinitionList"
      class='material'
      [columns]="columns"
      [columnMode]="'force'"
      [headerHeight]="50"
      [footerHeight]="0"
      [rowHeight]="'auto'"
      [limit]="10"
      [selected]="selected"
      [selectionType]="'cell'"
      (select)="onSelect($event)"
      [rows]='workflowState.workflowTaskDefinitionList'>

      <ngx-datatable-column name="{{'TASK_NAME' | translate}}" [flexGrow]="7">
        <ng-template ngx-datatable-cell-template let-row="row">
          {{row.taskSettings.taskName}}
        </ng-template>
      </ngx-datatable-column>


      <ngx-datatable-column name="{{'EDIT' | translate}}" [flexGrow]="1">
        <ng-template ngx-datatable-header-template>
          <button mat-mini-fab color="accent" style="top: -10px !important;" type="button" (click)="createTask()">
            <mat-icon>add</mat-icon>
          </button>
        </ng-template>
        <ng-template ngx-datatable-cell-template let-row="row">
        </ng-template>
      </ngx-datatable-column>


    </ngx-datatable>

    <mat-card-content>
      <button mat-raised-button *ngIf="workflowState.id" color="accent" (click)="updateState()">{{'SAVE' | translate}}</button>
      <button mat-raised-button *ngIf="!workflowState.id" color="accent" (click)="createState()">{{'CREATE' | translate}}</button>
    </mat-card-content>
  </mat-card>

</mat-sidenav-container>
