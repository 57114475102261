<div [formGroup]="form" fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">

  <div fxFlex>
    <mat-form-field appearance="outline" style="width: 100%;">
      <mat-label>{{dateTimeDefinition.label | translate}}</mat-label>
      <input matInput placeholder="{{dateTimeDefinition.placeholder | translate}}" [owlDateTime]="dtPicker2"
        [id]="elementInstanceDto.instanceId" [required]="dateTimeDefinition.required"
        [formControlName]="elementInstanceDto.instanceId">

      <mat-icon class="open-icon" matSuffix [owlDateTimeTrigger]="dtPicker2">event</mat-icon>

      <owl-date-time [stepMinute]="'15'" #dtPicker2></owl-date-time>

      <mat-error *ngIf="form.controls[elementInstanceDto.instanceId].hasError('required')">
        {{dateTimeDefinition.label + ' is' | translate}} <strong>{{'required' | translate}}</strong>
      </mat-error>
    </mat-form-field>
  </div>
</div>