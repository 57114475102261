import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataListDto, DatalistService } from '@savvy/datalist';
import { FlagDefinition } from "@savvy/flags";
import { ContextIdDto } from '@savvy/payment';
import { PaginationData, PetService } from '@savvy/pet';
import { PerformPetSearch, PerformPetSearchPagedResponse, SearchService } from '@savvy/search';
import { PetSearchResultDto } from "@savvy/search/model/petSearchResultDto";
import { MenuDefinition } from '@savvy/ui';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { ConfService } from 'src/app/flo/config/conf.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ContextService } from '../context.service';
import { EventBusService } from '../element/EventBusService';
import { NavChangeService } from '../element/NavChangeService';
import { ClearObservable } from '../shared/classes/clear-observable';
import { FlagFilterComponent } from "../shared/components/flag-filter/flag-filter.component";
import { Page } from "../shared/model/page";
import { TrialExpiredService } from '../shared/services/trial-expired.service';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { FilterPetTypeSelectorComponent } from "./filter-pet-type-selector/filter-pet-type-selector.component";
import { PetBreedSelectorComponent } from "./pet-breed-selector/pet-breed-selector.component";
import { PetHandlerService } from "./pet-handler.service";
import MenuItemTypeEnum = MenuDefinition.MenuItemTypeEnum;

const defalutProfilePicDog = '/assets/images/dog-profile.png';
const defalutProfilePicCat = '/assets/images/cat-profile.webp';

export enum StatusEnum {
  All = 'ALL',
  Archived = 'ARCHIVE',
  Merged = 'MERGED'
}
export class PetFilter {
  searchKey = '';
  includeFlagDefs = new Array();
  excludeFlagDefs = new Array();
  selectedPetTypes = new Array();
  selectedPetBreeds = new Array();

  deleteOnly = false;

  sortAscending = true;
  nameSorted = true;
  merged = false;
  status: StatusEnum = StatusEnum.All;
}


@Component({
  selector: 'app-list-pets-grid',
  templateUrl: './listPetsGrid.component.html',
  styleUrls: ['./listPetsGrid.component.scss']
})
export class ListPetsGridComponent extends ClearObservable implements OnInit, OnDestroy {
  @ViewChild('flagFilterComponent', { static: false }) flagFilterComponent: FlagFilterComponent;
  @ViewChild('petTypeFilterComponent', { static: false }) petTypeFilterComponent: FilterPetTypeSelectorComponent;
  @ViewChild('petBreedFilterComponent', { static: false }) petBreedFilterComponent: PetBreedSelectorComponent;

  contextIdDto: ContextIdDto;
  subscriptions: Subscription[] = [];

  pets: Array<PetSearchResultDto> = [];

  selected: PetSearchResultDto[] = [];
  eventBus = new EventBusService();

  flagDefs: FlagDefinition[] = [];


  breeds: DataListDto;

  loaded = false;

  public petFilter = new PetFilter();


  @Input() showFilters = true;

  // For mobile view
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;

  petImage = '/assets/images/dog-profile.png';
  page = new Page();

  statusEnum = StatusEnum;



  constructor(
    private router: Router,
    private petService: PetService,
    private dialog: MatDialog,
    private datalistApi: DatalistService,
    public deviceService: DeviceDetectorService,
    public trialExpiredService: TrialExpiredService,
    private navChange: NavChangeService,
    private contextService: ContextService,
    private searchService: SearchService,
    private petHandlerService: PetHandlerService,
    private notify: FloSnackbarComponent
  ) {
    super();
  }

  ngOnInit() {

    let offset = 0;
    if (sessionStorage.getItem('pageNumber')) {
      offset = Number(sessionStorage.getItem('pageNumber'));
      sessionStorage.removeItem('pageNumber');
    }

    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
        this.navChange.add(MenuItemTypeEnum.ListPets);
        this.loadBreeds();
        this.setPage({ offset: 0 });
      }
    }));
  }

  setPage(pageInfo) {
    console.log('pageInfo is ' + pageInfo);
    this.page.pageNumber = pageInfo.offset;
    this.searchPets();
  }

  prepareRequest(pageNumber: number): PerformPetSearch {
    const req = <PerformPetSearch>{};
    req.contextIdDto = this.contextIdDto;
    req.paginationData = <PaginationData>{};
    req.paginationData.pageNumber = pageNumber;
    req.paginationData.pageSize = this.page.size;
    req.ascending = this.petFilter.sortAscending;
    req.deleteOnly = this.petFilter.deleteOnly;
    req.merged = this.petFilter.merged;
    req.petTypes = this.petFilter.selectedPetTypes;
    req.includeFlags = this.petFilter.includeFlagDefs;
    req.excludeFlags = this.petFilter.excludeFlagDefs;
    req.petBreeds = this.petFilter.selectedPetBreeds;
    if (this.petFilter.searchKey && this.petFilter.searchKey.length > 0) {
      req.searchString = this.petFilter.searchKey;
    } else {
      req.searchString = '';
    }
    return req;
  }

  onScrollDown(ev) {
    console.log("scrolled down!!", ev);
    this.page.pageNumber++;
    this.searchService.performPetSearchPaged(this.prepareRequest(this.page.pageNumber)).subscribe(response => {
      if (response?.pets?.contents?.length) {
        this.loaded = true;
        this.pets = [...this.pets, ...response?.pets?.contents || []];
        this.page.totalElements = response.pets.totalElements;
        this.page.totalPages = response.pets.totalPages;
      } else {
        this.page.pageNumber = this.page.totalPages;
      }
    });
  }

  searchKeyUpdated(searchText) {
    this.petFilter.searchKey = searchText;
    this.setPage({ offset: 0 });

  }

  includeFlagsChange(flagDefs: FlagDefinition[]) {
    this.petFilter.includeFlagDefs = new Array();
    if (flagDefs !== undefined) {
      flagDefs.forEach(flagDef => {
        this.petFilter.includeFlagDefs.push(flagDef.id);
      });
    }
    this.setPage({ offset: 0 });
  }

  excludeFlagsChange(flagDefs: FlagDefinition[]) {
    this.petFilter.excludeFlagDefs = new Array();
    if (flagDefs !== undefined) {
      flagDefs.forEach(flagDef => {
        this.petFilter.excludeFlagDefs.push(flagDef.id);
      });
    }
    this.setPage({ offset: 0 });
  }

  petTypeSelectionChange(event) {
    this.petFilter.selectedPetTypes = new Array();
    if (event !== undefined) {
      this.petFilter.selectedPetTypes.push(event);
    }
    this.setPage({ offset: 0 });
  }

  petBreedSelectionChange(event) {
    this.petFilter.selectedPetBreeds = new Array();

    if (event !== undefined) {
      this.petFilter.selectedPetBreeds.push(event);
    }
    this.setPage({ offset: 0 });
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getUrl(reference: string) {
    if (reference) {
      return ConfService.apiUrl() + '/rest/storage?public=false&contextId=' +
        this.contextIdDto.contextId + '&contextIdType=' +
        this.contextIdDto.contextIdType + '&id=' + reference;
    }
    return null;
  }

  searchPets() {
    console.log('listInstances in ListPets.component.ts');
    this.searchService.performPetSearchPaged(this.prepareRequest(this.page.pageNumber + 1)).subscribe(response => {
      this.handleSearchPets(response);
    });
  }

  handleSearchPets(response: PerformPetSearchPagedResponse) {
    this.loaded = true;
    if (response.pets?.contents?.length) {
      this.pets = response.pets.contents;
      this.page.totalElements = response.pets.totalElements;
      this.page.totalPages = response.pets.totalPages;
    }
  }


  onSort(event) {
    // event was triggered, start sort sequence
    console.log('Sort1', event);
    console.log('Sort2', event.sorts);
    console.log('Sort2[0]', event.sorts[0]);
    this.petFilter.sortAscending = event.newValue === 'asc';
    this.petFilter.nameSorted = event.column.name === 'Name';

    // Reset back to page 1
    this.setPage({ offset: 0 });
  }


  loadBreeds() {
    this.datalistApi.getDataListByName(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      'Breed').subscribe(response => {
        this.breeds = response;
      });
  }

  callback = () => {
    this.setPage({ offset: 0 });
  }

  edit(row: PetSearchResultDto) {
    this.petHandlerService.edit(this.contextIdDto, row.petOwnerId, row.petId, this.callback)
  }


  view(row: PetSearchResultDto) {
    this.petHandlerService.view(this.contextIdDto, row.petOwnerId, row.petId, this.callback)
  }

  onSelect(event) {
    if (event?.selected[0]?.petId) {
      this.router.navigate(['/pets/view',
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        event?.selected[0]?.petId]);
    }
  }

  create() {
    this.router.navigate(['/pets/create',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType]);
  }

  archive(row: PetSearchResultDto) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Archive',
        message: `Are you sure you want to archive this pet ${row.petName}?`
      },
      height: 'auto',
      width: '360px',
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && row?.petId) {
        this.petService.deletePet(
          row.petId
        ).subscribe(response => {
          this.notify.message = 'Pet Archived Successfully';
          this.notify.open();
          this.setPage({ offset: 0 });
        });
      }
    });
  }

  unarchive(row: PetSearchResultDto) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Unarchive',
        message: `Are you sure you want to unarchive this pet ${row.petName}?`
      },
      height: 'auto',
      width: '360px',
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.petService.undoDeletePet(
          row.petId).subscribe(response => {
            this.notify.message = 'Pet Unarchived Successfully';
            this.notify.open();
            this.setPage({ offset: 0 });
          });
      }
    });
  }

  resetFilters() {
    this.petFilter = new PetFilter();
    this.flagFilterComponent.formGroup = new UntypedFormGroup({
      includeFlags: new UntypedFormControl([]),
      excludeFlags: new UntypedFormControl([]),
    });
    this.petTypeFilterComponent.selectedPetType = new FormControl<string>('');
    this.petBreedFilterComponent.selectedPetBreed = new FormControl<string>('');
    this.setPage({ offset: 0 });
  }


  statusChanged(newStatus: StatusEnum) {
    if (newStatus) {
      switch (newStatus) {
        case StatusEnum.All:
          this.petFilter.deleteOnly = false;
          this.petFilter.merged = false;
          break;
        case StatusEnum.Archived:
          this.petFilter.deleteOnly = true;
          this.petFilter.merged = false;
          break;
        case StatusEnum.Merged:
          this.petFilter.deleteOnly = false;
          this.petFilter.merged = true;
          break;
        default:
          break;
      }
    }
    this.setPage({ offset: 0 });

  }
}
