import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { ContextService } from '../context.service';
import { Router } from '@angular/router';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { WindowRef } from '../windowWrapper';
import { TranslateService } from '@ngx-translate/core';
import { ContextIdDto } from '@savvy/menu';
import { Subscription } from 'rxjs';
import { PaymentSettings } from '@savvy/payment/model/models';
import { PaymentSettingsService } from '@savvy/payment';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  AccountHolder as SavvyPayAccountHolder,
  SavvyPayAccountHolder2Service,
} from '@savvy/savvy-pay2';
import { AccountHolder, SavvyPayAccountHolderService } from '@savvy/savvy-pay';

@Component({
  selector: 'app-payment-plans',
  templateUrl: './paymentPlans.component.html',
  styleUrls: ['paymentPlans.component.scss'],
})
export class PaymentPlansComponent implements OnInit, OnDestroy {
  contextIdDto: ContextIdDto;

  subscriptions: Subscription[] = [];
  paymentSettings = <PaymentSettings>{};
  isModal = false;

  accountHolder: AccountHolder;
  savvyPayAccountHolder: SavvyPayAccountHolder; // from upgrade
  hasSavvyPay = false;

  constructor(
    private router: Router,
    private windowRef: WindowRef,
    private translate: TranslateService,
    private notify: FloSnackbarComponent,
    private dialog: MatDialog,
    private contextService: ContextService,
    private paymentSettingsApi: PaymentSettingsService,
    private savvyPayAccountHolderService2: SavvyPayAccountHolder2Service,
    private savvyPayService: SavvyPayAccountHolderService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data?: any
  ) {
    if (this.data) {
      this.isModal = this.data.isModal;
    }
  }

  ngOnInit() {
    this.subscriptions.push(
      this.contextService.contextIdDto$.subscribe((data) => {
        if (data) {
          this.contextIdDto = data;
          this.loadPaymentSettings();
        }
      })
    );
  }

  goToSavvyPay() {
    this.router.navigate([
      'paymentSettings/paymentSettings',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
    ]);
  }

  loadPaymentSettings() {
    this.paymentSettingsApi
      .loadPaymentSettings(
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType
      )
      .subscribe((response) => {
        this.paymentSettings = response;
        this.loadAccountHolder();
      });
  }

  loadAccountHolder() {
    debugger;
    if (this.paymentSettings.savvyPaySettings?.accountHolderCode) {
      this.savvyPayService
        .loadAccountHolder(
          this.paymentSettings?.savvyPaySettings?.accountHolderCode
        )
        .subscribe((response) => {
          if (response) {
            this.accountHolder = response;

            if (
              (this.paymentSettings &&
                this.paymentSettings.savvyPaySettings &&
                this.paymentSettings.savvyPaySettings.accountHolderCode) ||
              (this.savvyPayAccountHolder?.id &&
                this.savvyPayAccountHolder.fromUpgraded)
            ) {
              this.hasSavvyPay = true;
            }
          }
        });
    } else {
      this.savvyPayAccountHolderService2
        .loadAccountHolderByEnvId(this.contextIdDto.contextId)
        .subscribe((res) => {
          if (res && res[0]) {
            this.savvyPayAccountHolder = res[0];
            if (
              (this.paymentSettings &&
                this.paymentSettings.savvyPaySettings &&
                this.paymentSettings.savvyPaySettings.accountHolderCode) ||
              (this.savvyPayAccountHolder?.id &&
                this.savvyPayAccountHolder.fromUpgraded)
            ) {
              this.hasSavvyPay = true;
            }
          }
        });
    }
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}
