<mat-toolbar color="primary">
  <ng-container [ngSwitch]="smsEmailMessage?.messageType">
    <div *ngSwitchCase="'SMS'">{{'SMS_MESSAGE' | translate}}</div>
    <div *ngSwitchCase="'EMAIL'">{{'EMAIL_MESSAGE' | translate}}</div>
    <div *ngSwitchDefault>{{'MESSAGE' | translate}}</div>
  </ng-container>
  <div class="example-spacer"></div>
  <button mat-dialog-close class="ml-xs mat-icon-button" mat-icon-button="">
    <span class="mat-button-wrapper">
      <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">close</mat-icon>
    </span>
    <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
    <div class="mat-button-focus-overlay"></div>
  </button>
</mat-toolbar>
<mat-dialog-content>
  <p *ngIf="smsEmailMessage?.message; else elseMessage" [innerHTML]="smsEmailMessage?.message"></p>
  <ng-template #elseMessage>N/A</ng-template>
</mat-dialog-content>

