import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContextIdDto } from '@savvy/view-definition';
import { EventBusService } from '../EventBusService';
import { CreatePackage, Package, PackagesCompositeService } from '@savvy/packages';

export interface AddEntityDialogData {
  entityInstanceId: string;
  entityDefinitionId: string;
  entityDefinitionLabel: string;
  contextIdDto: ContextIdDto;
  eventBus: EventBusService;
}

@Component({
  selector: 'app-add-package-dialog',
  templateUrl: './addPackageDialog.component.html'
})
export class AddPackageDialogComponent implements OnInit {

  contextIdDto: ContextIdDto;
  entityDefinitionId: string;
  entityDefinitionLabel: string;
  eventBus: EventBusService;
  package: Package = {};

  constructor(
    public dialogRef: MatDialogRef<AddPackageDialogComponent>,
    private packageCompositeService: PackagesCompositeService,
    @Inject(MAT_DIALOG_DATA) public data: AddEntityDialogData) {
    this.contextIdDto = data.contextIdDto;
    this.entityDefinitionId = data.entityDefinitionId;
    this.entityDefinitionLabel = data.entityDefinitionLabel;
    this.eventBus = data.eventBus;
  }

  ngOnInit(): void {
    this.package.packageName = '';
    this.package.packageDurationMins = 60;
    this.package.packagePrice = 25;
    this.package.packageColour = '';
    this.package.ownerId = this.contextIdDto.contextId;
  }

  entityCreated(entityInstanceId: string) {
    this.data.entityInstanceId = entityInstanceId;
  }

  addNew() {
    if (this.package.packageName.length > 0) {
      const req: CreatePackage = {
        contextIdDto: this.contextIdDto,
        apackage: this.package
      };
      this.packageCompositeService.create(req).subscribe((res: Package) => {
        this.package = res;
        this.entityCreated(res.id);
        this.data['package'] = res;
        this.dialogRef.close(this.data);
      });
    }
  }

  updateColor(newColour: string) {
    this.package.packageColour = newColour;
  }
}
