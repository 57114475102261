<div fxLayout="row" fxLayoutGap="0" class="mdc-p-3 mdc-pt-0 sidebar-wrap-mobile"
  *ngIf="response && response.messagesList && response.messagesList.length">

  <div fxFlex="34" class="sidebar-mobile">

    <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="16px">
      <div fxFlex>
      </div>
      <div fxFlex="nogrow" class="sidebarclosebtn"><button mat-button color=""
          onclick="this.closest('.sidebar-wrap-mobile').classList.toggle('active');"> <span>Close</span>
          <mat-icon>cancel</mat-icon></button></div>
    </div>
    <mat-tab-group animationDuration="0ms" (selectedTabChange)="selectedTabChange()">
      <mat-tab label="SMS">
        <mat-list *ngIf="smsList?.length; else elseSms" class="list-hover mdc-p-0">
          <mat-list-item class="new" *ngFor="let sms of smsList;" [ngClass]="{'active': sms === selectedMessage}"
            (click)="selectSms(sms)">
            <mat-icon
              *ngIf="sms.deliveryStatus === deliveryStatuses.Sent || sms.deliveryStatus === deliveryStatuses.Delivered"
              matListItemIcon class="mat-text-theme" matTooltip="Sent today">mark_chat_unread</mat-icon>
            <mat-icon *ngIf="sms.deliveryStatus === deliveryStatuses.Received" matListItemIcon
              matTooltip="Sent">chat</mat-icon>
            <mat-icon
              *ngIf="sms.deliveryStatus !== deliveryStatuses.Sent && sms.deliveryStatus !== deliveryStatuses.Delivered && sms.deliveryStatus !== deliveryStatuses.Received"
              matListItemIcon class="mat-text-red" matTooltip="Error">warning</mat-icon>
            <div matListItemTitle>{{ sms?.smsMessage?.textMessage }}</div>
            <div matListItemLine>{{ sms.sentDateLocal | localizedDate: 'shortDate'}}, {{sms.sentTimeLocal |
              localizedTime:'shortTime' }}</div>
          </mat-list-item>
        </mat-list>
        <ng-template #elseSms>
          <h6 class="text-center"><mat-icon class="mat-text-accent">sms</mat-icon> There are no SMS</h6>
        </ng-template>
      </mat-tab>
      <mat-tab label="Email">
        <mat-list *ngIf="emailList?.length; else elseEmail" class="list-hover mdc-p-0">
          <mat-list-item class="new" *ngFor="let email of emailList;" [ngClass]="{'active': email === selectedMessage}"
            (click)="selectEmail(email)">
            <mat-icon
              *ngIf="email.deliveryStatus === deliveryStatuses.Sent || email.deliveryStatus === deliveryStatuses.Delivered"
              matListItemIcon class="mat-text-theme" matTooltip="Sent today">mark_email_unread</mat-icon>
            <mat-icon *ngIf="email.deliveryStatus === deliveryStatuses.Received" matListItemIcon
              matTooltip="Sent">mail</mat-icon>
            <mat-icon
              *ngIf="email.deliveryStatus !== deliveryStatuses.Sent && email.deliveryStatus !== deliveryStatuses.Delivered && email.deliveryStatus !== deliveryStatuses.Received"
              matListItemIcon class="mat-text-red" matTooltip="Error">warning</mat-icon>
            <div matListItemTitle>{{ email?.emailMessage?.subject }}</div>
            <div matListItemLine>{{ email.sentDateLocal | localizedDate: 'shortDate'}}, {{email.sentTimeLocal |
              localizedTime:'shortTime' }}</div>
          </mat-list-item>
        </mat-list>
        <ng-template #elseEmail>
          <h6 class="text-center"><mat-icon class="mat-text-accent">mail</mat-icon> There are no emails</h6>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>

  <div fxFlex="66" class="sidebar-mobile-content">
    <div fxLayout="row" fxLayoutAlign=" center" class="sidebar-mobile-header mdc-mb-1">
      <div fxFlex>
        <h6>Message Detail</h6>
      </div>
      <div fxFlex="nogrow">
        <button mat-button color="accent"
          onclick="this.closest('.sidebar-wrap-mobile').classList.toggle('active');"><mat-icon>chat</mat-icon> All
          Messages</button>
        <button mat-raised-button class="ml-1" color="accent" [disabled]="trialExpiredService.isTrialExpired()"
          (click)="createMessage()">
          {{'CREATE_NEW' | translate}}
        </button>
      </div>
    </div>

    <ng-container *ngIf="selectedMessage; else elseBlock">
      <div
        *ngIf="selectedMessage.deliveryStatus !== deliveryStatuses.Sent && selectedMessage.deliveryStatus !== deliveryStatuses.Delivered && selectedMessage.deliveryStatus !== deliveryStatuses.Received"
        class="alert alert-danger">
        <div fxLayout="row" fxLayoutAlign=" center" class="w-100 z-1  z-index-1">
          <div fxFlex>
            Sending {{ messageType }} failed
          </div>
          <div fxFlex="nogrow">
            <!-- <button mat-raised-button color="primary">Try again</button>
            <button mat-icon-button class="mat-text-dark"><mat-icon>delete</mat-icon></button> -->
          </div>
        </div>
      </div>

      <mat-card appearance="outlined" class="panel ">
        <mat-card-content>
          <div fxLayout="column" *ngIf="selectedMessage?.type === 'SMS'">
            <div fxLayout="row" fxLayoutGap="16px" class="mdc-mb-2">
              <div fxFlex="nogrow">
                <div mat-list-icon class="avatar avatar-50 circle border-3"
                  *ngIf="selectedMessage?.deliveryStatus === deliveryStatuses.Sent || selectedMessage?.deliveryStatus === deliveryStatuses.Delivered">
                  <mat-icon mat-list-icon>mark_unread_chat_alt</mat-icon>
                </div>
                <div mat-list-icon class="avatar avatar-50 circle border-3"
                  *ngIf="selectedMessage?.deliveryStatus === deliveryStatuses.Received">
                  <mat-icon mat-list-icon>mark_chat_read</mat-icon>
                </div>
                <div mat-list-icon class="avatar avatar-50 circle border-3"
                  *ngIf="selectedMessage?.deliveryStatus !== deliveryStatuses.Sent && selectedMessage?.deliveryStatus !== deliveryStatuses.Delivered && selectedMessage?.deliveryStatus !== deliveryStatuses.Received">
                  <mat-icon mat-list-icon>sms_failed</mat-icon>
                </div>
              </div>
              <div fxFlex>
                <h5 class="mb-0"><strong>{{ selectedMessage?.sentLocalDateTime | localizedDate: 'mediumDate' }}</strong>
                </h5>
                <p class="mat-text-secondary mt-5px mb-0">
                  {{ selectedMessage?.sentDateLocal | date : 'EEEE' }} at {{selectedMessage?.sentTimeLocal |
                  localizedTime:'shortTime'}}
                </p>
                <!-- <p class="mat-text-secondary mb-0">{{'SENT_BY' | translate}}: <a
                    routerLInk="">{{selectedMessage?.smsMessage?.senderId}}</a> </p> -->
              </div>
              <div fxFlex="nogrow">

              </div>
            </div>

            <div>
              <h6>
                <span class="mat-text-secondary">{{'TO' | translate}}:</span>
                {{selectedMessage?.smsMessage?.toNumber?.msidn}}
              </h6>

              <p class="mat-text-secondary">{{selectedMessage?.smsMessage?.textMessage}}</p>
            </div>
          </div>

          <div fxLayout="column" *ngIf="selectedMessage?.type === 'EMAIL'">
            <div fxLayout="row" fxLayoutGap="16px" class="mdc-mb-2">
              <div fxFlex="nogrow">
                <div class="avatar avatar-50 circle border-3"
                  *ngIf="selectedMessage?.deliveryStatus === deliveryStatuses.Sent || selectedMessage?.deliveryStatus === deliveryStatuses.Delivered">
                  <mat-icon class="mat-text-accent">
                    mark_email_unread</mat-icon>
                </div>
                <div class="avatar avatar-50 circle border-3"
                  *ngIf="selectedMessage?.deliveryStatus === deliveryStatuses.Received">
                  <mat-icon class="mat-text-accent">
                    mark_email_read
                  </mat-icon>
                </div>
                <div class="avatar avatar-50 circle border-3"
                  *ngIf="selectedMessage?.deliveryStatus !== deliveryStatuses.Sent && selectedMessage?.deliveryStatus !== deliveryStatuses.Delivered && selectedMessage?.deliveryStatus !== deliveryStatuses.Received">
                  <mat-icon class="mat-text-accent">
                    report_gmailerrorred</mat-icon>
                </div>
              </div>
              <div fxFlex>
                <!-- <h5 class="mb-0"><strong>{{ selectedMessage?.sentLocalDateTime | localizedDate }}</strong></h5> -->
                <h5 class="mb-0">{{selectedMessage?.sentDate | localizedDate: 'mediumDate'}} at
                  {{selectedMessage?.sentTimeLocal | localizedTime:'shortTime'}}</h5>
                <p class="mat-text-secondary mb-0">{{'FROM' | translate}}: {{ selectedMessage?.emailMessage?.fromEmail
                  }} </p>
              </div>
              <div fxFlex="nogrow">
              </div>
            </div>

            <div>
              <h6 class="mdc-mb-1">
                <span class="mat-text-secondary">{{'TO' | translate}}:</span>
                {{selectedMessage?.emailMessage?.recipientsAsString}}
              </h6>
              <h5 [innerHTML]="selectedMessage?.emailMessage?.subject"></h5>
              <p class="mat-text-secondary" [innerHTML]="selectedMessage?.emailMessage?.body"></p>

            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </ng-container>
    <ng-template #elseBlock>
      <div class="no-messages mt-2">
        <div style="text-align:center;">
          <div class="avatar avatar-100 circle border-3 mb-2 d-inline-block">
            <mat-icon class="h1 h-auto w-auto">alarm</mat-icon>
          </div>
          <h6>There are no messages</h6>
          <button mat-raised-button color="accent" [disabled]="trialExpiredService.isTrialExpired()"
            (click)="createMessage()">{{'SEND_MESSAGE' | translate: {Default: 'Send Message'} }}</button>
        </div>
      </div>
    </ng-template>
  </div>
</div>