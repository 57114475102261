import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ChangeListener} from '../changeListener';
import {EventBusService} from '../EventBusService';
import {ContextIdDto, ElementDto} from '@savvy/entity-instance-composite';

@Component({
    selector: 'app-field-instance',
    templateUrl: 'fieldInstance.component.html'
})
export class FieldInstanceComponent implements OnInit {

    @Input() elementDto: ElementDto;
    @Input() contextIdDto: ContextIdDto;
    @Input() viewContext: any;
    @Input() additionalDataMapDto: any;
    @Input() showPlaceholder: boolean;
    @Input() form: UntypedFormGroup;
    @Input() changeListener: ChangeListener;
    @Input() eventBus: EventBusService;

  elementDefinition: ElementDto;
    entityInstanceId: string;

    ngOnInit(): void {

        this.elementDefinition = this.getDefinition();
        this.entityInstanceId = this.viewContext.entityInstanceId.id;
    }

    /**
     *
     *
     * This should be a straight Map
     *
     *
     *
     * @returns
     */
    public getDefinition(): ElementDto {
        let retDef = null;
        if (!this.viewContext.addEditEntityInstanceContextDto.entityDefinitionDto ||
            !this.viewContext.addEditEntityInstanceContextDto.entityDefinitionDto.elementDtoList) {
        } else {
            this.viewContext.addEditEntityInstanceContextDto.entityDefinitionDto.elementDtoList.forEach(elementDef => {

                const retVal = this.getDefinitionFromDef(elementDef);
                if (retVal) {
                    retDef = retVal;
                }
            });
        }
        return retDef;
    }

    private getDefinitionFromDef(elementDef: ElementDto): ElementDto {

        let retDef: ElementDto = null;
        if (elementDef.id === this.elementDto.elementInstanceDto.elementDefinitionId) {
            retDef = elementDef;
        } else {
            if (elementDef.elementSetDefinitionDto) {
                elementDef.elementSetDefinitionDto.elementDtos.forEach(elementDef2 => {
                    const retDef2 = this.getDefinitionFromDef(elementDef2);
                    if (retDef2) {
                        retDef = retDef2;
                    }
                });
            }
        }
        return retDef;
    }
}

