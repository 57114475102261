import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { EicompService, EntityChangeEvent } from '@savvy/entity-instance-composite';
import { ElementControlService } from '../ElementControlService';
import { TimeUtils } from '../../dates/TimeUtils';
import { DateTimeAdapter, NativeDateTimeAdapter, OWL_DATE_TIME_LOCALE, OwlDateTimeIntl } from '@danielmoncada/angular-datetime-picker';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@angular/cdk/platform';
import { LocalIntl } from '../../../services/local-intl.service';
import { ChangeListener } from '../changeListener';
import { ElementInstanceDto, FieldValueDto, TimeFieldDefinitionDto } from '@savvy/view-composite';
import { ContextIdDto } from '@savvy/datalist';

@Component({
  selector: 'app-time-instance',
  templateUrl: 'timeInstance.component.html',
  providers: [
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-US' },
    { provide: DateTimeAdapter, useClass: NativeDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE, Platform] },
    { provide: OwlDateTimeIntl, useClass: LocalIntl },
  ]
})

export class TimeInstanceComponent implements OnInit {

  @Input() timeDefinition: TimeFieldDefinitionDto;
  @Input() elementInstanceDto: ElementInstanceDto;
  @Input() entityInstanceId: string;
  @Input() contextIdDto: ContextIdDto;
  @Input() form: UntypedFormGroup;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;


  constructor(private uiApi: EicompService,
    private ecs: ElementControlService,
    private cd: ChangeDetectorRef,
    private translateService: TranslateService,
    dateTimeAdapter: DateTimeAdapter<any>,
    private timeUtils: TimeUtils) {
    dateTimeAdapter.setLocale(this.translateService.currentLang);
  }

  get isValid() {
    return this.form.controls[this.elementInstanceDto.instanceId].valid;
  }

  ngOnInit(): void {
    this.form.controls[this.elementInstanceDto.instanceId].valueChanges.subscribe((value) => {
      this.onChange(value);
    });
  }

  onChange(newValue: Date) {
    const timeStr = this.timeUtils.getHoursMinsSecsTimeString(newValue);
    const fieldValue: FieldValueDto = {
      timeValue: timeStr,
      valueType: FieldValueDto.ValueTypeEnum.Time
    };

    const req: EntityChangeEvent = {
      contextIdDto: this.contextIdDto,
      fieldValue,
      entityInstanceId: this.entityInstanceId,
      fieldInstanceId: this.elementInstanceDto.instanceId
    };

    this.uiApi.entityInstanceChangeEvent(req).subscribe(
      response => {
        console.log('loadProductsResponse got from update, calling handle changed elements');
        this.ecs.handleChangedElements(response.changedElementList, this.form);
        this.cd.detectChanges();
      }
    );
  }
}

