<div [formGroup]="form"
     *ngIf="elementInstanceDto && elementInstanceDto.instanceId && elementInstanceDto.elementInstanceViewDto.viewable && linkedCustomerDefinition">

  <div fxLayout="row">
    <mat-form-field appearance="outline" style="width: 100%">
      <mat-label>{{linkedCustomerDefinition.label | translate}}</mat-label>
      <input matInput placeholder="Start typing to filter" [matAutocomplete]="auto"
             [required]="linkedCustomerDefinition.required"
             [formControlName]="elementInstanceDto.instanceId"
             (keyup)="onKey($event)">

      <mat-autocomplete #auto="matAutocomplete"
                        [displayWith]="displayFn.bind(this)" (optionSelected)="handler($event)">
        <mat-option *ngFor="let tuple of customers" [value]="tuple.id">
          {{ tuple.name }}
          <!--mat-chip-list>
            <mat-chip color="primary">One fish</mat-chip>
            <mat-chip>Two fish</mat-chip>
            <mat-chip color="accent">Accent fish</mat-chip>
          </mat-chip-list-->

        </mat-option>
        <mat-option *ngIf="customers">
          <button class="add-new-field" mat-raised-button color="accent" (click)="createCust()">Add New Customer</button>
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="form.controls[elementInstanceDto.instanceId].hasError('required')">
        {{linkedCustomerDefinition.label + ' is' | translate}} <strong>{{'required' | translate}}</strong>
      </mat-error>
      <mat-error *ngIf="!form.controls[elementInstanceDto.instanceId].hasError('required') && form.controls[elementInstanceDto.instanceId].hasError('doesNotSelected')">
        {{linkedCustomerDefinition.label + ' is' | translate}} <strong>{{'required' | translate}}</strong>.
        {{'Please Select one of the choices or create it' | translate}}.
      </mat-error>
    </mat-form-field>
    <!--span style="text-align: right; padding: 15px">{{'OR' | translate}}</span>
    <button class="mat-accent" (click)="createCust()" mat-fab>
      <mat-icon>add</mat-icon>
    </button-->
  </div>
  <div fxLayout="row" *ngIf="elementInstanceDto.elementInstanceViewDto.displayWidgetType === 'SHOW_HISTORY'">
    <app-list-flags [contextIdDto]="contextIdDto" [linkId]="customerIdAsLinkId"></app-list-flags>
    <app-view-customer-history-button [contextIdDto]="contextIdDto" [customerId]="this.form.controls[this.elementInstanceDto.instanceId].value"></app-view-customer-history-button>
  </div>
</div>

