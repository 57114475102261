import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { EicompService, EntityChangeEvent } from '@savvy/entity-instance-composite';
import { ChangeListener } from '../changeListener';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
declare let google: any;
import { asapScheduler, pipe, of, from, interval, merge, fromEvent } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { ElementInstanceDto, FieldDefinitionDto, FieldValueDto } from '@savvy/view-composite';
import { ContextIdDto } from '@savvy/datalist';


@Component({
  selector: 'app-location-instance',
  templateUrl: './locationInstance.component.html'
})
export class LocationInstanceComponent implements OnInit, OnDestroy {

  @Input() fieldDefinition: FieldDefinitionDto;
  @Input() elementInstanceDto: ElementInstanceDto;
  @Input() entityInstanceId: string;
  @Input() contextIdDto: ContextIdDto;
  @Input() form: UntypedFormGroup;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;

  searchTerm$ = new Subject<string>();
  predictions: any[] = [];

  constructor(
    private api: EicompService,
    private zone: NgZone,
    // private mapsAPILoader: MapsAPILoader
  ) {
  }

  get isValid() {
    if (this.form.controls[this.elementInstanceDto.instanceId] == null) {
      return false;
    } else {
      return this.form.controls[this.elementInstanceDto.instanceId].valid;
    }
  }

  ngOnInit(): void {
    // this.mapsAPILoader.load().then(() => {
    // });
    this.initSearchSubject(this.searchTerm$, 1000);
  }

  onKeyUp() {
    this.searchTerm$.next(this.form.controls[this.elementInstanceDto.instanceId].value);
  }

  getPlaceholder() {
    if (this.showPlaceholder) {
      return this.fieldDefinition.label;
    }
    return '';
  }

  ngOnDestroy() {
    this.searchTerm$.unsubscribe();
  }

  onChange(newValue: string) {
    const fieldValue: FieldValueDto = {
      strValue: newValue,
      valueType: FieldValueDto.ValueTypeEnum.String
    };
    const req: EntityChangeEvent = {
      contextIdDto: this.contextIdDto,
      fieldValue,
      entityInstanceId: this.entityInstanceId,
      fieldInstanceId: this.elementInstanceDto.instanceId
    };

    // There is no loadProductsResponse from this, do we care?
    this.api.entityInstanceChangeEvent(req).subscribe(
      response => {
        console.log('applied change to input instance, loadProductsResponse ' + response);
        console.log('changeListener is ' + this.changeListener);
        if (this.changeListener) {
          this.changeListener.add('change');
        } else {
          console.log('PANICK CHANGE LISTENER IS NULL IN INPUT INSTANCE');
        }
      }
    );

  }

  handler(event) {
    const value = event.option.value;
    try {
      this.form.controls[this.elementInstanceDto.instanceId].setValue(value);
      this.onChange(value);
    } catch (err) {
      console.error('Location Field Value is not set \n Error:', err);
    }
  }

  private getFormattedAddress(place) {
    const location_obj: any = {};
    location_obj.description = place.description;
    return location_obj;
  }

  private initSearchSubject(searchTerm$, timeout: number) {
    // Create a new session token.
    const sessionToken = new google.maps.places.AutocompleteSessionToken();

    // Pass the token to the autocomplete service.
    const autocompleteService = new google.maps.places.AutocompleteService();

    const getPredictions = this.getPredictions.bind(this, autocompleteService, sessionToken);

    searchTerm$
      .pipe(debounceTime(timeout), switchMap((address: string) => {
        this.onChange(address);
        this.predictions = [];
        if (address) {
          return getPredictions(address);
        }
        return of([]);
      }))
      .subscribe((response: any) => {
        if (response && response.length) {
          this.zone.run(() => {
            this.predictions = response;
          });
        }
      });
  }

  private getPredictions(autocompleteService, token, input) {
    return new Observable(observer => {
      autocompleteService.getPlacePredictions({
        input,
        sessionToken: token
      },
        (res) => {
          if (Array.isArray(res) && res.length) {
            res = res.map(place => this.getFormattedAddress(place));
          }
          observer.next(res);
          return observer.complete();
        });
    });
  }
}

