<div *ngIf="!eventBus">
  VIEW ENTITY PAGE BUS IS EMPTY
</div>

<mat-sidenav-container class="app-inner" *ngIf="contextIdDto">
    <mat-sidenav #sidePanel position="end" align="end" class="create-panel" mode="over" opened="false">
        <app-side-panel *ngIf="viewEntity && viewEntity.loadEiViewResponse && viewEntity.loadEiViewResponse.viewContextDto"
                        [viewContextDto]="viewEntity.loadEiViewResponse.viewContextDto"
                        [contextIdDto]="contextIdDto"
                        [eventBus]="eventBus"
                        [sidePanel]="this.sidePanel"></app-side-panel>
    </mat-sidenav>

    <app-view-entity *ngIf="entityInstanceId" #viewEntity
            [contextIdDto]="contextIdDto"
            [eventBus]="eventBus"
            [entityInstanceId]="entityInstanceId">

    </app-view-entity>
</mat-sidenav-container>

