<mat-form-field appearance="outline" class="w-100">
    <mat-label>{{'SERVICE' | translate}}</mat-label>
  <mat-select id="appointment-service-selector" placeholder="{{'SELECT_SERVICE' | translate}}"
    [(ngModel)]="selectedServiceLocal" required name="serviceField" #serviceField="ngModel"
    (selectionChange)="serviceChanged($event)" [compareWith]="compareObjects">
    <mat-option>
      <ngx-mat-select-search [placeholderLabel]="'SEARCH_SERVICE' | translate" ngModel (ngModelChange)="filterServices($event)">
      </ngx-mat-select-search>
    </mat-option>
    <mat-option [value]="''" disabled>
      <button mat-flat-button color="accent" (click)="createNewService()">{{'CREATE_SERVICE' | translate}}</button>
    </mat-option>

    <mat-option *ngFor="let service of filteredServices | async" [value]="service">
      {{ service?.serviceName }}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="serviceField.hasError('required') && !serviceDeleted">
    {{'SERVICE_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
  </mat-error>
  <mat-error *ngIf="serviceDeleted">
    {{'SERVICE' | translate}} <strong>{{serviceDeleted?.serviceName | translate}}</strong> {{'NO_LONGER_AVAILABLE' | translate}}
  </mat-error>
  <div *ngIf="serviceDeleted" matSuffix
    matTooltip="{{serviceDeleted?.serviceName}} {{'WITH_ID' | translate}} {{serviceDeleted.id}} {{'BEEN_DELETED' | translate}}"
    matTooltipClass="tooltip-red">
    <mat-icon class="vm">help</mat-icon>
  </div>
</mat-form-field>
