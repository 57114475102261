<!-- <form #f="ngForm" [formGroup]="phoneForm">
  <div class="wrapper">
    <ngx-mat-input-tel #phoneNumberRef [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="false"
      [enablePlaceholder]="true" [searchCountryFlag]="true"
      [searchCountryField]="[searchCountryField.Iso2, searchCountryField.Name]" [selectFirstCountry]="false"
      [selectedCountryISO]="selectedCountryCode" [phoneValidation]="true" [inputId]="id"
      [numberFormat]="phoneNumberFormat.International" [customPlaceholder]="placeholder"
      [separateDialCode]="separateDialCode" name="phone" formControlName="phone"
      (countryChange)="onCountryChange($event)"></ngx-mat-input-tel>
    <div class="control-error mt-1" *ngIf="f.form.touched && !f.form.valid || !valid">
      {{'INVALID_PHONE_NUMBER' | translate: {Default: 'Provided number is not valid'} }}
    </div>
  </div>
</form> -->

<form #f="ngForm" [formGroup]="phoneForm">
  <div class="wrapper">
    <ngx-mat-input-tel [preferredCountries]="preferredCountries" [enablePlaceholder]="true"
      [inputPlaceholder]="placeholder" [enableSearch]="true" name="phoneNumber" autocomplete="tel" formControlName="phoneNumber"
      #phone (countryChanged)="onCountryChange($event)"></ngx-mat-input-tel>
    <!-- <mat-hint *ngIf="placeholder">{{placeholder}}</mat-hint> -->
    <mat-error *ngIf="f.form.controls['phoneNumber']?.errors?.required">Required Field</mat-error>
    <mat-error *ngIf="f.form.controls['phoneNumber']?.errors?.validatePhoneNumber">
      {{'INVALID_PHONE_NUMBER' | translate: {Default: 'Provided number is not valid'} }}
    </mat-error>
  </div>
</form>