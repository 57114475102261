import {Component, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ContractTemplate,
  ContractTemplateCompositeService,
  ContractTemplateService,
  CreateContractTemplateComp, UpdateContractTemplateComp
} from '@savvy/contract';
import { ContextIdDto } from '@savvy/view-definition';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';
import {FlagDefinition} from "@savvy/flags";
import {CustomerQuickLinksEditorComponent} from "../../editor/customer-quick-links-editor/customer-quick-links-editor.component";
import {ClearObservable} from "../../shared/classes/clear-observable";
import {Link} from "@savvy/messaging";

@Component({
  selector: 'app-add-edit-contract-template',
  templateUrl: './addEditContractTemplates.component.html'
})
export class AddEditContractTemplateComponent extends ClearObservable implements OnInit {


  @ViewChild('customerQuickLinksEditorV2Component', { static: false }) customerQuickLinksEditorV2Component: CustomerQuickLinksEditorComponent;

  contextIdDto: ContextIdDto;
  contractTemplate = <ContractTemplate>{};
  includedFlags: FlagDefinition[] = [];
  excludedFlags: FlagDefinition[] = [];
  request = <CreateContractTemplateComp>{};
  sendToAll = false;
  sendSigned = false;
  sendUnsigned = false;
  includingFlags = false;
  excludingFlags = false;
  saving = false;

  private sub: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notify: FloSnackbarComponent,
    private api: ContractTemplateService,
    private contractTemplateCompositeService: ContractTemplateCompositeService) {
    super();
  }


  ngOnInit() {
    this.sub = this.route?.parent?.parent?.params.subscribe(params => {
      this.contextIdDto = this.contextIdDto ? this.contextIdDto : {
        contextIdType: params['contextIdType'], contextId: params['contextId']
      };
      this.route.params.subscribe(childParams => {
        if (childParams['contractTemplateId']) {
          this.loadContractTemplate(childParams['contractTemplateId']);
        } else {
          this.contractTemplate = <ContractTemplate>{};
          this.contractTemplate.ownerId = this.contextIdDto.contextId;
        }
      });
    });
  }

  loadContractTemplate(contractTemplateId: string) {
    this.api.getContractTemplate(contractTemplateId).subscribe(response => {
      this.contractTemplate = response;
    });
  }

  create() {
    this.prepareTemplate();
    this.saving = true;
    const req = <CreateContractTemplateComp>{};
    req.contextIdDto = this.contextIdDto;
    req.contractTemplate = this.contractTemplate;
    req.contractTemplate.id = null;

    if (this.sendToAll) {
      req.sendToAllCustomer = this.sendToAll;
    } else if (this.includedFlags){
      req.includedFlags = this.includedFlags.map(flagDef => flagDef.id);
    }
    if (this.excludedFlags) {
      req.excludedFlags = this.excludedFlags.map(flagDef => flagDef.id);
    }
    console.log(req);
    this.contractTemplateCompositeService.createContractTemplateComp(req).subscribe(response => {
      if (this.sendToAll || this.includedFlags.length > 0) {
        this.notify.message = 'Created Contract Template. Sending contracts to customers might take a few minutes.';
      } else {
        this.notify.message = 'Created Contract Template';
      }
      this.saving = false;
      this.notify.open();
      this.backToList();
    });
  }

  prepareTemplate() {
    this.contractTemplate.legalText = this.customerQuickLinksEditorV2Component.getEditorText();
  }

  update() {
    this.prepareTemplate();

    this.saving = true;
    const req = <UpdateContractTemplateComp>{};
    req.contextIdDto = this.contextIdDto;
    req.contractTemplate = this.contractTemplate;

    if (this.sendToAll) {
      req.sendToAllCustomer = this.sendToAll;
    } else if (this.includedFlags){
      req.includedFlags = this.includedFlags.map(flagDef => flagDef.id);
    }
    if (this.excludedFlags) {
      req.excludedFlags = this.excludedFlags.map(flagDef => flagDef.id);
    }
    this.contractTemplateCompositeService.updateContractTemplateComp(req).subscribe(response => {
      this.notify.message = 'Updated Contract Template. Sending contracts to customers might take a few minutes.';
      this.notify.open();
      this.backToList();
    });
  }

  backToList() {
    this.router.navigate(['/admin/settings',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType, 'contracts']);
  }

  changeSendToAll() {
    if (this.sendToAll) {
      this.includedFlags = [];
    } else if (!this.sendToAll && this.includedFlags.length === 0) {
      this.excludedFlags = [];
    }
  }
  addIncludedFlag(flag: FlagDefinition) {
    this.includedFlags.push(flag);
  }

  removeIncludedFlag(idx: number) {
    this.includedFlags.splice(idx, 1);
    if (this.includedFlags.length === 0 && this.excludedFlags.length > 0) {
      this.sendToAll = true;
    } else {
      this.sendToAll = false;
    }
  }

  addExcludedFlag(flag: FlagDefinition) {
    this.excludedFlags.push(flag);

    if (!this.includedFlags || this.includedFlags.length === 0) {
      this.sendToAll = true;
    } else {
      this.sendToAll = false;
    }
  }

  removeExcludedFlag(idx: number) {
    this.excludedFlags.splice(idx, 1);
    if (this.excludedFlags.length === 0) {
      this.sendToAll = false;
    } else if (!this.includedFlags || this.includedFlags.length === 0) {
      this.sendToAll = true;
    } else {
      this.sendToAll = false;
    }
  }

  sendSignedChanged() {
    if (this.sendSigned) {
      this.sendUnsigned = false;
    }
  }

  sendUnsignedChanged() {
    if (this.sendUnsigned) {
      this.sendSigned = false;
    }
  }
}
