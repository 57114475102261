<div [formGroup]="form">
  <mat-form-field appearance="outline" style="width: 100%;">
    <mat-label>{{dateDefinition.label}}</mat-label>
    <input matInput [formControlName]="elementInstanceDto.instanceId" [matDatepicker]="picker"
           [required]="dateDefinition.required"
           (focus)="picker.open()" and (click)="picker.open()"
           [placeholder]="dateDefinition.placeholder">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>

    <mat-error *ngIf="!form.controls[elementInstanceDto.instanceId].hasError('required') && form.controls[elementInstanceDto.instanceId].invalid">
      {{dateDefinition.label + ' is' | translate}} <strong>{{'invalid' | translate}}</strong>
    </mat-error>
    <mat-error *ngIf="form.controls[elementInstanceDto.instanceId].hasError('required')">
      {{dateDefinition.label + ' is' | translate}} <strong>{{'required' | translate}}</strong>
    </mat-error>
  </mat-form-field>
</div>
