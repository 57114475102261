import { OwlDateTimeIntl } from '@danielmoncada/angular-datetime-picker';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LocalIntl extends OwlDateTimeIntl {
  constructor(private translateService: TranslateService) {
    super();
    this.setTranslate('cancelBtnLabel');
    this.setTranslate('setBtnLabel');
  }
  /** A label for the up second button (used by screen readers).  */
  override upSecondLabel = 'Add a second';

  /** A label for the down second button (used by screen readers).  */
  override downSecondLabel = 'Minus a second';

  /** A label for the up minute button (used by screen readers).  */
  override upMinuteLabel = 'Add a minute';

  /** A label for the down minute button (used by screen readers).  */
  override downMinuteLabel = 'Minus a minute';

  /** A label for the up hour button (used by screen readers).  */
  override upHourLabel = 'Add a hour';

  /** A label for the down hour button (used by screen readers).  */
  override downHourLabel = 'Minus a hour';

  /** A label for the previous month button (used by screen readers). */
  override prevMonthLabel = 'Previous month';

  /** A label for the next month button (used by screen readers). */
  override nextMonthLabel = 'Next month';

  /** A label for the previous year button (used by screen readers). */
  override prevYearLabel = 'Previous year';

  /** A label for the next year button (used by screen readers). */
  override nextYearLabel = 'Next year';

  /** A label for the previous multi-year button (used by screen readers). */
  override prevMultiYearLabel = 'Previous 21 years';

  /** A label for the next multi-year button (used by screen readers). */
  override nextMultiYearLabel = 'Next 21 years';

  /** A label for the 'switch to month view' button (used by screen readers). */
  override switchToMonthViewLabel = 'Change to month view';

  /** A label for the 'switch to year view' button (used by screen readers). */
  override switchToMultiYearViewLabel = 'Choose month and year';

  /** A label for the cancel button */
  override cancelBtnLabel = 'Cancel';

  /** A label for the set button */
  override setBtnLabel = 'Set';

  /** A label for the range 'from' in picker info */
  override rangeFromLabel = 'From';

  /** A label for the range 'to' in picker info */
  override rangeToLabel = 'To';

  /** A label for the hour12 button (AM) */
  override hour12AMLabel = 'AM';

  /** A label for the hour12 button (PM) */
  override hour12PMLabel = 'PM';

  private setTranslate(label) {
    if (!this.hasOwnProperty(label)) {
      return;
    }
    this.translateService.get(this[label]).subscribe((val) => {
      this[label] = val;
    });
  }
}
