
<div *ngIf="!eventBus">
  VIEW ENTITY EVENT BUS IS EMPTY
</div>
<app-element *ngIf="loadEiViewResponse != null && loadEiViewResponse.viewRootElementDto != null && form != null"
             style="height: 100%"
             [elementDto]="loadEiViewResponse.viewRootElementDto"
             [form]="form"
             [eventBus]="eventBus"
             [contextIdDto]="contextIdDto"
             [viewContext]="loadEiViewResponse.viewContextDto"
             [additionalDataMapDto]="loadEiViewResponse.additionalDataMapDto"
             [showPlaceholder]="true"
             [changeListener]="changeListener"
>
</app-element>

<!--

<app-view-entity [contextIdDto]="contextIdDto"
                 [eventBus]="eventBus"
                 [entityInstanceId]="viewEntityEmbeddedData.entityInstanceId.id" *ngIf="contextIdDto"></app-view-entity>
                 -->

<!--button mat-fab color="accent" class="mat-fab-bottom-right" (click)="create()" style="z-index: 9;">
    <mat-icon class="mat-24">add</mat-icon>
</button-->
