<mat-form-field appearance="outline" style="width: 100%">
  <mat-label>{{'STAFF' | translate: {Default: 'Staff'} }}</mat-label>
  <mat-select [id]="id" placeholder="{{'SELECT_STAFF' | translate: {Default: 'Select Staff'} }}"
    [(ngModel)]="selectedStaff" [required]="required" (selectionChange)="serviceUserChanged($event)"
    name="serviceUserField" #serviceUserField="ngModel" [compareWith]="compareUserFn">
    <mat-option *ngIf="(filteredStaffList | async)?.length > 10">
      <ngx-mat-select-search ngModel (ngModelChange)="filterStaffList($event)"
        [placeholderLabel]="showPlaceholder ? 'Search Staff' : ''"
        [noEntriesFoundLabel]="'No matching staff member'"></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngIf="allowAllOption" [value]="{id: 'all', name: 'All'}">
      {{'ALL' | translate: {Default: 'All'}
      }}</mat-option>
    <mat-option *ngFor="let serviceUser of filteredStaffList | async" [value]="serviceUser"
      [ngClass]="{'mat-text-red': serviceUser?.notAvailable}">
      <span [ngClass]="{'mat-text-red': serviceUser?.notAvailable}">
        {{ serviceUser.name }} <span *ngIf="serviceUser?.notAvailable">Not Available or Archived</span></span>
    </mat-option>
  </mat-select>
  <mat-error *ngIf="serviceUserField.hasError('required')">
    {{'STAFF_IS' | translate: {Default: 'Staff is'} }} <strong>{{'REQUIRED' | translate: {Default: 'required'} }}
    </strong>
  </mat-error>
  <!-- <mat-icon *ngIf="selectedStaff?.deleted" matSuffix class="mat-text-red"
    matTooltip="{{selectedStaff.fullName}} with id {{selectedStaff.id}} has been deleted"
    matTooltipClass="tooltip-red" class="vm mat-text-red">error_outline</mat-icon> -->
</mat-form-field>