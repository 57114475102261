/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AppMessagingModule } from '../messaging/messaging.module';
import { FloMaterialModule } from '../flo.material.module';
import { ChartlibModule } from '../../chartlib/chartlib.module';
import { AppTaskModule } from '../task/task.module';
import { AppRemindersModule } from '../reminders/reminders.module';
import { ElementComponent } from './element.component';
import { SimpleMessagesPanelComponent } from './simpleMessagesPanel.component';
import { VerticalPanelComponent } from './verticalPanel.component';
import { WeatherPanelComponent } from './weatherPanel.component';
import { ZeroInstancesComponent } from './zeroInstances.component';
import { HorizontalPanelComponent } from './horizontalPanel.component';
import { NewsWidgetComponent } from './newsWidget.component';
import { CardDefinitionComponent } from './cardDefinition.component';
import { BadgeWidgetComponent } from './badgeWidget.component';
import { MapPanelComponent } from './mapPanel.component';
import { ViewCustomerPanelComponent } from './viewCustomerPanel.component';
import { SplitPanelComponent } from './splitPanel.component';
import { ListOrderListComponent } from './listOrderList.component';
import { ListEntitysListComponent } from './listEntitysList.component';
import { ListContractListComponent } from './listContractList.component';
import { ListInvoiceListComponent } from './listInvoiceList.component';
import { EntityTasksPanelComponent } from './entityTasksPanel.component';
import { CreateEntityPageComponent } from './createEntityPage.component';
import { CreateEntityComponent } from './createEntityComponent.component';
import { ListEntityInstancesComponent } from './listEntityInstances.component';
import { ViewDocumentComponent } from './viewDocument.component';
import { ViewEntityPageComponent } from './viewEntityPage.component';
import { ViewEntityInstancePanelComponent } from './viewEntityInstance.component';
import { ViewEntityComponent } from './viewEntity.component';
import { ViewImageComponent } from './viewImage.component';
import { ViewVideoComponent } from './viewVideo.component';
import { SelectionListComponent } from './selectionList.component';
import { ElementInstanceComponent } from './fieldinstance/elementInstance.component';
import { FileUploadComponent } from './fieldinstance/fileUpload.component';
import { ToolBarComponent } from './toolBar.component';
import { ListEntityTableComponent } from './listEntityTable.component';
import { ViewRemindersPanelComponent } from './viewRemindersPanel.component';
import { DateInstanceComponent } from './fieldinstance/dateInstance.component';
import { InputInstanceComponent } from './fieldinstance/inputInstance.component';
import { FieldInstanceComponent } from './fieldinstance/fieldInstance.component';
import { LineInstanceComponent } from './fieldinstance/lineInstance.component';
import { ListEntityTeasersPanelComponent } from './listEntityTeasersPanel.component';
import { WorkflowFieldInstanceComponent } from './fieldinstance/workflowFieldInstance.component';
import { SelectFieldInstanceComponent } from './fieldinstance/selectFieldInstance.component';
import { ElementSetDefinitionComponent } from './fieldinstance/elementSetDefinition.component';
import { LabelValuePanelComponent } from './labelValuePanel.component';
import { ListMessagesPanelComponent } from './listMessagesPanel.component';
import { DateTimeInstanceComponent } from './fieldinstance/dateTimeInstance.component';
import { TabPanelComponent } from './tabPanel.component';
import { TimeInstanceComponent } from './fieldinstance/timeInstance.component';
import { ButtonInstanceComponent } from './fieldinstance/buttonInstance.component';
import { EditEntityComponent } from './editEntity.component';
import { SectionComponent } from './section.component';
import { LabelValueLeftRightPanelComponent } from './labelValueLeftRightPanel.component';
import { LinkedEntityInstanceComponent } from './fieldinstance/linkedEntityInstance.component';
import { SectionHeaderComponent } from './sectionHeader.component';
import { WorkflowPanelComponent } from './workflowPanel.component';
import { SignatureComponent } from './signature.component';
import { ImageFieldInstanceComponent } from './fieldinstance/imageFieldInstance.component';
import { VideoFieldInstanceComponent } from './fieldinstance/videoFieldInstance.component';
import { AvatarRowComponent } from './avatarRow.component';
import { ImageGridElementComponent } from './imageGrid.component';
import { SocialWidgetComponent } from './socialWidget.component';
import { ElementDefLookupService } from './ElementDefLookupService';
import { ReminderService } from '@savvy/reminders';
import { EntitydefsService } from '@savvy/entity-definition';
import { EntityService } from './entity.service';
import { ImageService } from './image.service';
import { ElementControlService } from './ElementControlService';
import { EntitysService } from '@savvy/entity-instance';
import { EicompService } from '@savvy/entity-instance-composite';
import { LinkedCustomerInstanceComponent } from './fieldinstance/linkedCustomerInstance.component';
import { CustomerService } from '@savvy/customer';
import { ViewCustomerHeaderComponent } from './viewCustomerHeader.component';
import { SidePanelComponent } from './sidePanel.component';
import { SingleLabelValuePanelComponent } from './singleLabelValuePanel.component';
import { BreadcrumbModule } from '../breadcrumb/breadcrumb.module';
import { ViewEntitySummaryComponent } from './viewEntitySummary.component';
import { CreateEmbeddedEntityComponent } from './createEmbeddedEntityComponent.component';
import { IntInstanceComponent } from './fieldinstance/intInstance.component';
import { BooleanInstanceComponent } from './fieldinstance/booleanInstance.component';
import { ListQuestionnairesListComponent } from './listQuestionnairesList.component';
import { SendQuestionnaireComponent } from '../questionnaires/sendQuestionnaire.component';
import { QuestionnaireCompositeService } from '@savvy/questionnaire';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatInputModule } from '@angular/material/input';
import { ZeroEisComponent } from './zeroEis.component';
import { QuestionnaireDefsService } from '@savvy/questionnaire';
import { ViewQuestionnaireComponent } from './viewQuestionnaire.component';
import { AddCustomerDialogComponent } from './fieldinstance/addCustomerDialog.component';
import { AddEntityDialogComponent } from './fieldinstance/addEntityDialog.component';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { EditorModule } from '../editor/editor.module';
import { WorkflowDefinitionFieldInstanceComponent } from './fieldinstance/workflowDefinitionFieldInstance.component';
import { WorkflowdefService } from '@savvy/workflow-definition';
import { EntitySummaryWidgetComponent } from './entitySummaryWidget.component';
import { PaymentsSummaryWidgetComponent } from './paymentsSummaryWidget.component';
import { ContactFormsSummaryWidgetComponent } from './contactFormsSummaryWidget.component';
import { QuotesSummaryWidgetComponent } from './quotesSummaryWidget.component';
import { OrdersSummaryWidgetComponent } from './ordersSummaryWidget.component';
import { ElementTableComponent } from './elementTable.component';
import { LabelValuePairComponent } from './labelValuePair.component';
import { LocationInstanceComponent } from './fieldinstance/locationInstance.component';
import { FixImageOrientationDirective } from './fix-image-orientation.directive';
import { TranslateModule } from '@ngx-translate/core';
import { ColourFieldInstanceComponent } from './fieldinstance/colourFieldInstance.component';
import { SharedModule } from '../shared/shared.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { RouterModule } from '@angular/router';
import { RepeatableEventInstanceComponent } from './fieldinstance/repeatableEventInstance.component';
import { ListCachedEntitysListComponent } from './listCachedEntitysList.component';
import { ListCachedEntitysTableComponent } from './listCachedEntitysTable.component';
import { ListCachedEntityInstancesComponent } from './listCachedEntityInstances.component';
import {
  TablerowService,
  CachedtablecompService,
  CachedlistcompService
} from '@savvy/cached-view';
import { EiPopupComponent } from './eiPopup.component';
import { CustomerPopupComponent } from './customerPopup.component';
import { ViewEmbeddedEntityInstancePanelComponent } from './viewEmbeddedEntityInstance.component';
import { ViewEmbeddedCustomerComponent } from './viewEmbeddedCustomer.component';
import { ViewEmbeddedCachedTableComponent } from './viewEmbeddedCachedTable.component';
import { CachedtableviewdefsService } from '@savvy/view-definition';
import { ListCachedEntityInstancesPageComponent } from './listCachedEntityInstancesPage.component';
import { BigDecimalInstanceComponent } from './fieldinstance/bigDecimalInstance.component';
import { WorkflowActionPanelComponent } from './workflowActionPanel.component';
import { InvoicePanelComponent } from './invoicePanel.component';
import { InvoiceServicesPanelComponent } from './invoiceServicesPanel.component';
import { ServicesCompositeService } from '@savvy/services';
import { WorkflowinstcompService } from '@savvy/workflow-instance';
import { ProductCompositeService } from '@savvy/products';
import { FilesFieldInstanceComponent } from './fieldinstance/filesFieldInstance.component';
import { ViewNestedEntityComponent } from './viewNestedEntity.component';
import { ViewEntityPanelComponent } from './viewEntityPanel.component';
import { ViewEntityCardComponent } from './viewEntityCard.component';
import { ViewAppointmentPanelComponent } from './viewAppointmentPanel.component';
import { ViewCustomerPageComponent } from './viewCustomerPage.component';
import { ViewEntityWithImagePanelComponent } from './viewEntityWithImagePanel.component';
import { FlagcompService } from '@savvy/flags';
import { FlagDefService } from '@savvy/flags';
import { ViewFlagsPanelComponent } from './viewFlagsPanel.component';
import { ViewWorkflowStatsPanelComponent } from './viewWorkflowStatsPanel.component';
import { LookAndFeelService } from '@savvy/look-and-feel';
import { DateTimeInstanceDropDownComponent } from './fieldinstance/dateTimeInstanceDropDown.component';
import { DateTimeInstanceTimePickerComponent } from './fieldinstance/dateTimeInstanceTimePicker.component';
import { LinkedEntityInstanceSelectComponent } from './fieldinstance/linkedEntityInstanceSelect.component';
import { LinkedEntityInstanceSingleTypeaheadComponent } from './fieldinstance/linkedEntityInstanceSingleTypeahead.component';
import { LinkedEntityInstanceMultiTypeaheadComponent } from './fieldinstance/linkedEntityInstanceMultiTypeahead.component';
import { ViewListCachedEntitiesComponent } from './viewListCachedEntities.component';
import { ViewExpandableCachedEntitiesComponent } from './viewExpandableCachedEntities.component';
import { ViewEntitySimpleComponent } from './viewEntitySimple.component';
import { InvoiceMultiServicesPanelComponent } from './invoiceMultiServicesPanel.component';
import { InvoiceSingleServicesPanelComponent } from './invoiceSingleServicesPanel.component';
import { EiSelectorComponent } from './eiSelector.component';
import { ShowClashesComponent } from './showClashes.component';
import { ConflictCheckButtonComponent } from './conflictCheckButton.component';
import { EiSelectorWithFlagsComponent } from './eiSelectorWithFlags.component';
import { UserHistoryModalComponent } from './user-history-modal/user-history-modal.component';
import { ListFlagsComponent } from './listFlags.component';
import { ViewCustomerHistoryButtonComponent } from './viewCustomerHistoryButton.component';
import { ListCustomerFlagsComponent } from './listCustomerFlags.component';
import { ViewExpandableInvoiceEntitiesComponent } from './viewExpandableInvoiceEntities.component';
import { InvoiceMultiServiceTableComponent } from './invoiceMultiServiceTable.component';
import { ViewInvoicePanelComponent } from './viewInvoicePanel.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ViewAssociatedEmbeddedCachedTableComponent } from './viewAssociatedEmbeddedCachedTable.component';
import { ViewAssociatedCachedEntitysListComponent } from './viewAssociatedCachedEntitysList.component';
import { ViewInvoiceEntitiesCachedTableComponent } from './viewInvoiceEntitiesCachedTable.component';
import { AppInvoiceModule } from '../invoice/invoice.module';
import { ViewInvoicesByEiListComponent } from './viewInvoicesByEiList.component';
import { ViewAppointmentsByEiListComponent } from './viewAppointmentsByEiList.component';
import { ViewAppointmentsByCustomerListComponent } from './viewAppointmentsByCustomerList.component';
import { ViewAppointmentHistoryComponent } from './viewAppointmentHistory.component';
import { ViewAppointmentHistoryEmbeddedComponent } from './viewAppointmentHistoryEmbedded.component';
import { SmsCreditService } from '../shared/services/sms-credit.service';
import { MatDialogModule } from '@angular/material/dialog';
import { AppointmentCompService } from '@savvy/appointment';
import { ViewAppointmentsByCustomerComponent } from './viewAppointmentsByCustomer.component';
import { ShowCustomerStatusComponent } from './showCustomerStatus.component';
import { ShowEiStatusComponent } from './showEiStatus.component';
import { ListCheckInFormsListComponent } from './listCheckInFormsList.component';
import { CheckinFormDefsService } from '@savvy/check-in-form';
import { CheckinFormService } from '@savvy/check-in-form';
import { CreateAppointmentViewComponent } from './createAppointmentView.component';
import { InvoiceStepperPanelComponent } from './invoiceStepperPanel.component';
import { StepPanelComponent } from './stepPanel.component';
import { ViewInvoiceDialogComponent } from './viewInvoiceDialog.component';
import { HtmlElementComponent } from './htmlElement/htmlElement.component';
import { HtmlSelectElementComponent } from './htmlElement/htmlSelectElement.component';
import { ItinerariesPanelComponent } from './itinerariesPanel.component';
import { IconsInstanceComponent } from './fieldinstance/iconsInstance.component';
import { CheckinFormCompService } from '@savvy/check-in-form';
import { ViewCheckInFormModalComponent } from './viewCheckInFormModal.component';
import { InvoiceService } from '@savvy/invoice';
import { UserAvailabilityService } from '@savvy/user-availability';
import { DirectivesModule } from 'src/app/core/directives/directives.module';
import { NgxPhotoEditorModule } from 'ngx-photo-editor';
import { AddServiceDialogComponent } from './fieldinstance/addServiceDialog.component';
import { ServiceSelectorComponent } from './create-appointment/serviceSelector.component';
import { AppointmentSelectorComponent } from './create-appointment/appointmentSelector.component';
import { NgWizardConfig, NgWizardModule, THEME } from 'ng-wizard-v2';
import { DepositRequestComponent } from './create-appointment/depositRequest.component';
import { CheckInFormSelectorComponent } from './create-appointment/checkInFormSelector.component';

import { PortalOptionsComponent } from './create-appointment/portalOptions.component';
import { ArchwizardModule } from '@viso-trust/angular-archwizard';
import { LocationSelectorComponent } from './create-appointment/location-selector/location-selector.component';
import { AppointmentLocalService } from './create-appointment/appointment.service';
import { ConfirmAppointmentComponent } from './create-appointment/confirm-appointment/confirm-appointment.component';
import { ViewEntityNoViewDefComponent } from './viewEntityNoViewDef.component';
import { ViewCustomerContractsComponent } from './viewCustomerContracts.component';
import { ViewInvoicePaymentStatusPanelComponent } from './viewInvoicePaymentStatusPanel.component';
import { ConfirmPetAppointmentComponent } from './create-pet-appointment/confirm-appointment/confirm-pet-appointment.component';
import { PetDepositRequestComponent } from './create-pet-appointment/petDepositRequest.component';
import { ServiceSelectorPetComponent } from './create-pet-appointment/serviceSelectorPet.component';
import { PetAppointmentSelectorComponent } from './create-pet-appointment/petAppointmentSelector.component';
import { PetPortalOptionsComponent } from './create-pet-appointment/petPortalOptions.component';
import { PetExistingCustomerSelectorComponent } from './create-pet-appointment/petExistingCustomerSelector.component';
import { PetLocationSelectorComponent } from './create-pet-appointment/location-selector/pet-location-selector.component';
import { PetCheckInFormSelectorComponent } from './create-pet-appointment/petCheckInFormSelector.component';
import { NewPetCustomerSelectorComponent } from './create-pet-appointment/newPetCustomerSelector.component';
import { PetAppointmentLocalService } from './create-pet-appointment/pet-appointment.service';
import { PetServiceSelectorComponent } from './create-pet-appointment/petServiceSelector.component';
import { PetSelectorComponent } from './create-pet-appointment/pet-selector/pet-selector.component';
import { PetContractsComponent } from './create-pet-appointment/petContracts.component';
import { CreatePetAppointmentPanelComponent } from './create-pet-appointment/createPetAppointmentPanel.component';
import { CreateAppointmentPanelComponent } from './create-appointment/createAppointmentPanel.component';
import { CommonServiceSelectorComponent } from './create-appointment/commonServiceSelector.component';
import { InvoicePackagePanelComponent } from './invoicePackagePanel.component';
import { ViewCustomerAppointmentHistoryPanelComponent } from './viewCustomerAppointmentHistoryPanel.component';
import { CustomerPlansComponent } from './customerPlans.component';
import { CustomerCheckinFormsComponent } from './customerCheckinForms.component';
import { BaseInvoiceServicesPanelComponent } from './base-invoice-services-panel/base-invoice-services-panel.component';
import { AssignCustomerToPlanComponent } from './assignCustomerToPlan.component';
import { AddPackageDialogComponent } from './fieldinstance/addPackageDialog.component';
import { ViewCustomerGalleriesComponent } from './viewCustomerGalleries.component';
import { ViewGalleriesComponent } from './viewGalleries.component';
import { ViewAppointmentsByCustomerListV2Component } from './viewAppointmentsByCustomerListV2.component';
import { ViewAppointmentsByCustomerListV1V2Component } from './viewAppointmentsByCustomerListV1V2.component';
import { ViewCustomerInvoicesComponent } from './viewCustomerInvoices.component';
import { ViewAppointmentMeetingsComponent } from './viewAppointmentMeetings.component';
import { AddEditAppointmentMeetingComponent } from './create-appointment/addEditAppointmentMeeting.component';
import { DiscountModule } from '../discount/discount.module';
import { AppointmentCalendarFilterComponent } from '../calendar/appointment-calendar/appointment-calendar-filter/appointment-calendar-filter.component';
import { ViewPetAppointmentsComponent } from './viewPetAppointments.component';
import { ViewCustomerPetsComponent } from './viewCustomerPets.component';
import { CommonContractsComponent } from './create-appointment/commonContracts.component';

const ngWizardConfig: NgWizardConfig = {
  theme: THEME.default
};

@NgModule({
  imports: [
    CommonModule,
    ArchwizardModule,
    DirectivesModule,
    AppTaskModule,
    EditorModule,
    AppRemindersModule,
    RouterModule,
    BreadcrumbModule,
    MatBadgeModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatInputModule,
    FloMaterialModule,
    ChartlibModule,
    PdfViewerModule,
    AppInvoiceModule,
    NgWizardModule.forRoot(ngWizardConfig),
    AppMessagingModule,
    SharedModule,
    TranslateModule,
    ColorPickerModule,
    NgxFileDropModule,
    // WorkflowDefinitionModule.forRoot(() => {
    //   return new WorkflowDefinitionConfiguration({
    //     basePath: ConfService.apiUrl()
    //   });
    // }),
    // ImageUploadModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    NgxPhotoEditorModule,
    DiscountModule
  ],
  exports: [
    AddCustomerDialogComponent,
    AddEntityDialogComponent,
    AddServiceDialogComponent,
    AddPackageDialogComponent,
    ConflictCheckButtonComponent,
    ElementTableComponent,
    ItinerariesPanelComponent,
    ListEntityTeasersPanelComponent,
    ElementSetDefinitionComponent,
    ColourFieldInstanceComponent,
    SelectFieldInstanceComponent,
    ViewFlagsPanelComponent,
    SectionComponent,
    DateTimeInstanceComponent,
    DateTimeInstanceDropDownComponent,
    DateTimeInstanceTimePickerComponent,
    RepeatableEventInstanceComponent,
    EiPopupComponent,
    StepPanelComponent,
    CustomerPopupComponent,
    ViewExpandableCachedEntitiesComponent,
    ViewExpandableInvoiceEntitiesComponent,
    EiSelectorComponent,
    EiSelectorWithFlagsComponent,
    ViewAppointmentsByCustomerComponent,
    CreateAppointmentViewComponent,
    ViewInvoicesByEiListComponent,
    ViewAppointmentsByEiListComponent,
    ViewAppointmentsByCustomerListComponent,
    ViewAppointmentsByCustomerListV2Component,
    ViewAppointmentsByCustomerListV1V2Component,
    ViewWorkflowStatsPanelComponent,
    SectionHeaderComponent,
    ElementInstanceComponent,
    ImageGridElementComponent,
    ListEntityTableComponent,
    ListCachedEntitysListComponent,
    ListCachedEntitysTableComponent,
    ListCachedEntityInstancesComponent,
    ListCachedEntityInstancesPageComponent,
    ViewRemindersPanelComponent,
    ViewEntityWithImagePanelComponent,
    ViewEntityPanelComponent,
    EditEntityComponent,
    ViewEmbeddedEntityInstancePanelComponent,
    SingleLabelValuePanelComponent,
    LabelValuePanelComponent,
    LabelValueLeftRightPanelComponent,
    LabelValuePairComponent,
    ViewListCachedEntitiesComponent,
    ViewEntitySimpleComponent,
    ViewAssociatedCachedEntitysListComponent,
    SignatureComponent,
    ViewImageComponent,
    ViewVideoComponent,
    ViewCustomerPageComponent,
    FileUploadComponent,
    LineInstanceComponent,
    LinkedCustomerInstanceComponent,
    LinkedEntityInstanceComponent,
    LinkedEntityInstanceSelectComponent,
    LinkedEntityInstanceSingleTypeaheadComponent,
    LinkedEntityInstanceMultiTypeaheadComponent,
    ListQuestionnairesListComponent,
    ButtonInstanceComponent,
    SelectionListComponent,
    TabPanelComponent,
    ToolBarComponent,
    VideoFieldInstanceComponent,
    ListMessagesPanelComponent,
    SocialWidgetComponent,
    TimeInstanceComponent,
    ViewEmbeddedCustomerComponent,
    ViewEmbeddedCachedTableComponent,
    ViewAssociatedEmbeddedCachedTableComponent,
    ViewInvoiceEntitiesCachedTableComponent,
    DateInstanceComponent,
    InputInstanceComponent,
    IntInstanceComponent,
    IconsInstanceComponent,
    BigDecimalInstanceComponent,
    LocationInstanceComponent,
    BooleanInstanceComponent,
    FieldInstanceComponent,
    WorkflowPanelComponent,
    WorkflowActionPanelComponent,
    InvoicePanelComponent,
    ViewInvoicePaymentStatusPanelComponent,
    InvoiceMultiServiceTableComponent,
    InvoiceServicesPanelComponent,
    InvoicePackagePanelComponent,
    InvoiceSingleServicesPanelComponent,
    InvoiceStepperPanelComponent,
    CreatePetAppointmentPanelComponent,
    ConfirmAppointmentComponent,
    DepositRequestComponent,
    PetContractsComponent,
    ServiceSelectorComponent,
    AppointmentSelectorComponent,
    AddEditAppointmentMeetingComponent,
    PortalOptionsComponent,
    PetServiceSelectorComponent,
    PetSelectorComponent,
    LocationSelectorComponent,
    CheckInFormSelectorComponent,
    InvoiceMultiServicesPanelComponent,
    ImageFieldInstanceComponent,
    ViewEntityCardComponent,
    FixImageOrientationDirective,
    HtmlElementComponent,
    HtmlSelectElementComponent,
    BadgeWidgetComponent,
    CardDefinitionComponent,
    CreateEntityPageComponent,
    CreateEntityComponent,
    CreateEmbeddedEntityComponent,
    ElementComponent,
    ViewInvoicePanelComponent,
    ViewAppointmentHistoryComponent,
    ViewAppointmentHistoryEmbeddedComponent,
    // WizardPrefsComponent,
    FilesFieldInstanceComponent,
    ShowCustomerStatusComponent,
    EntityTasksPanelComponent,
    HorizontalPanelComponent,
    ListContractListComponent,
    ListEntitysListComponent,
    ListEntityInstancesComponent,
    ListInvoiceListComponent,
    ListFlagsComponent,
    ShowEiStatusComponent,
    ListCustomerFlagsComponent,
    ListOrderListComponent,
    MapPanelComponent,
    ListCheckInFormsListComponent,
    NewsWidgetComponent,
    EntitySummaryWidgetComponent,
    OrdersSummaryWidgetComponent,
    PaymentsSummaryWidgetComponent,
    QuotesSummaryWidgetComponent,
    ContactFormsSummaryWidgetComponent,
    CustomerPlansComponent,
    CustomerCheckinFormsComponent,
    ViewNestedEntityComponent,
    ViewPetAppointmentsComponent,
    VerticalPanelComponent,
    SendQuestionnaireComponent,
    ShowClashesComponent,
    SidePanelComponent,
    SimpleMessagesPanelComponent,
    SplitPanelComponent,
    ViewCustomerPanelComponent,
    ViewCustomerHeaderComponent,
    ViewAppointmentPanelComponent,
    ViewCustomerAppointmentHistoryPanelComponent,
    ViewEntityComponent,
    ViewEntityNoViewDefComponent,
    ViewEntitySummaryComponent,
    ViewEntityPageComponent,
    ViewDocumentComponent,
    ViewEntityInstancePanelComponent,
    ViewQuestionnaireComponent,
    WeatherPanelComponent,
    WorkflowFieldInstanceComponent,
    WorkflowDefinitionFieldInstanceComponent,
    ViewCustomerHistoryButtonComponent,
    ViewCheckInFormModalComponent,
    UserHistoryModalComponent,
    ZeroInstancesComponent,
    ZeroEisComponent,
    ConfirmPetAppointmentComponent,
    PetDepositRequestComponent,
    ServiceSelectorPetComponent,
    PetAppointmentSelectorComponent,
    PetPortalOptionsComponent,
    PetExistingCustomerSelectorComponent,
    PetLocationSelectorComponent,
    PetCheckInFormSelectorComponent,
    NewPetCustomerSelectorComponent,
    CreateAppointmentPanelComponent,
    CommonServiceSelectorComponent,
    AppointmentCalendarFilterComponent,
  ],
  providers: [
    AppointmentCompService,
    TablerowService,
    CachedtablecompService,
    CachedtableviewdefsService,
    CachedlistcompService,
    CustomerService,
    EntityService,
    ElementControlService,
    ElementDefLookupService,
    EntitydefsService,
    EntitysService,
    EicompService,
    CheckinFormCompService,
    CheckinFormDefsService,
    CheckinFormService,
    FlagcompService,
    FlagDefService,
    InvoiceService,
    LookAndFeelService,
    ServicesCompositeService,
    ProductCompositeService,
    ReminderService,
    SmsCreditService,
    QuestionnaireCompositeService,
    QuestionnaireDefsService,
    UserAvailabilityService,
    WorkflowdefService,
    WorkflowinstcompService,
    ImageService,
    AppointmentLocalService,
    PetAppointmentLocalService,
    // CreatePetAppointmentV2SyncService
  ],
  declarations: [
    AddCustomerDialogComponent,
    AddEntityDialogComponent,
    AddServiceDialogComponent,
    AddPackageDialogComponent,
    ConflictCheckButtonComponent,
    ElementTableComponent,
    ListEntityTeasersPanelComponent,
    ElementSetDefinitionComponent,
    ColourFieldInstanceComponent,
    SelectFieldInstanceComponent,
    SectionComponent,
    DateTimeInstanceComponent,
    ViewCheckInFormModalComponent,
    DateTimeInstanceDropDownComponent,
    DateTimeInstanceTimePickerComponent,
    RepeatableEventInstanceComponent,
    SectionHeaderComponent,
    ElementInstanceComponent,
    ImageGridElementComponent,
    ItinerariesPanelComponent,
    ShowClashesComponent,
    ViewFlagsPanelComponent,
    ListEntityTableComponent,
    ShowCustomerStatusComponent,
    ListCachedEntitysListComponent,
    ListCachedEntitysTableComponent,
    ViewRemindersPanelComponent,
    ViewEmbeddedCustomerComponent,
    ViewEmbeddedCachedTableComponent,
    ViewNestedEntityComponent,
    ViewPetAppointmentsComponent,
    ViewEntityCardComponent,
    ViewAppointmentMeetingsComponent,
    ViewCustomerContractsComponent,
    ViewCustomerPetsComponent,
    ViewCustomerInvoicesComponent,
    ViewCustomerGalleriesComponent,
    ViewGalleriesComponent,
    ViewEntityPanelComponent,
    ViewCustomerPageComponent,
    ViewCustomerHistoryButtonComponent,
    ViewWorkflowStatsPanelComponent,
    ViewAppointmentHistoryComponent,
    EiSelectorComponent,
    EiSelectorWithFlagsComponent,
    ViewAssociatedEmbeddedCachedTableComponent,
    ViewAssociatedCachedEntitysListComponent,
    ViewAppointmentsByCustomerComponent,
    CreateAppointmentViewComponent,
    StepPanelComponent,
    ViewInvoicesByEiListComponent,
    ViewAppointmentsByEiListComponent,
    ShowEiStatusComponent,
    ViewAppointmentsByCustomerListComponent,
    ViewAppointmentsByCustomerListV2Component,
    ViewAppointmentsByCustomerListV1V2Component,
    ViewEntityWithImagePanelComponent,
    LinkedEntityInstanceMultiTypeaheadComponent,
    ViewInvoiceEntitiesCachedTableComponent,
    ViewAppointmentHistoryEmbeddedComponent,
    ViewListCachedEntitiesComponent,
    EiPopupComponent,
    CustomerPopupComponent,
    FilesFieldInstanceComponent,
    EditEntityComponent,
    ViewEmbeddedEntityInstancePanelComponent,
    SingleLabelValuePanelComponent,
    LabelValuePanelComponent,
    LabelValueLeftRightPanelComponent,
    LabelValuePairComponent,
    HtmlElementComponent,
    HtmlSelectElementComponent,
    ListCheckInFormsListComponent,
    SignatureComponent,
    ViewImageComponent,
    ViewVideoComponent,
    FileUploadComponent,
    LineInstanceComponent,
    LinkedCustomerInstanceComponent,
    LinkedEntityInstanceComponent,
    LinkedEntityInstanceSelectComponent,
    LinkedEntityInstanceSingleTypeaheadComponent,
    ListQuestionnairesListComponent,
    ButtonInstanceComponent,
    SelectionListComponent,
    TabPanelComponent,
    ToolBarComponent,
    VideoFieldInstanceComponent,
    ListMessagesPanelComponent,
    SocialWidgetComponent,
    ViewAppointmentPanelComponent,
    ViewCustomerAppointmentHistoryPanelComponent,
    TimeInstanceComponent,
    ViewExpandableCachedEntitiesComponent,
    ViewExpandableInvoiceEntitiesComponent,
    ViewEntitySimpleComponent,
    DateInstanceComponent,
    InputInstanceComponent,
    IntInstanceComponent,
    IconsInstanceComponent,
    BigDecimalInstanceComponent,
    LocationInstanceComponent,
    BooleanInstanceComponent,
    FieldInstanceComponent,
    WorkflowPanelComponent,
    InvoicePanelComponent,
    ViewInvoicePaymentStatusPanelComponent,
    ViewInvoicePanelComponent,
    InvoiceMultiServiceTableComponent,
    InvoiceServicesPanelComponent,
    InvoicePackagePanelComponent,
    InvoiceSingleServicesPanelComponent,
    InvoiceStepperPanelComponent,
    CreatePetAppointmentPanelComponent,
    ConfirmAppointmentComponent,
    DepositRequestComponent,
    PetContractsComponent,
    ServiceSelectorComponent,

    AppointmentSelectorComponent,
    AddEditAppointmentMeetingComponent,
    PortalOptionsComponent,
    PetServiceSelectorComponent,
    PetSelectorComponent,
    LocationSelectorComponent,
    CheckInFormSelectorComponent,
    InvoiceMultiServicesPanelComponent,
    // WizardPrefsComponent,
    WorkflowActionPanelComponent,
    ImageFieldInstanceComponent,
    BadgeWidgetComponent,
    CardDefinitionComponent,
    CreateEntityPageComponent,
    CreateEntityComponent,
    CreateEmbeddedEntityComponent,
    ElementComponent,
    EntityTasksPanelComponent,
    HorizontalPanelComponent,
    ListContractListComponent,
    ListEntitysListComponent,
    ListEntityInstancesComponent,
    ListFlagsComponent,
    ListCustomerFlagsComponent,
    FilesFieldInstanceComponent,
    ListCachedEntityInstancesComponent,
    ListCachedEntityInstancesPageComponent,
    ListInvoiceListComponent,
    ListOrderListComponent,
    NewsWidgetComponent,
    EntitySummaryWidgetComponent,
    OrdersSummaryWidgetComponent,
    PaymentsSummaryWidgetComponent,
    QuotesSummaryWidgetComponent,
    ContactFormsSummaryWidgetComponent,
    CustomerPlansComponent,
    CustomerCheckinFormsComponent,
    MapPanelComponent,
    SidePanelComponent,
    SendQuestionnaireComponent,
    SimpleMessagesPanelComponent,
    SplitPanelComponent,
    VerticalPanelComponent,
    ViewInvoiceDialogComponent,
    ViewCustomerHeaderComponent,
    ViewCustomerPanelComponent,
    ViewEntityComponent,
    ViewEntityNoViewDefComponent,
    ViewEntitySummaryComponent,
    ViewEntityPageComponent,
    ViewDocumentComponent,
    ViewEntityInstancePanelComponent,
    ViewQuestionnaireComponent,
    WeatherPanelComponent,
    WorkflowFieldInstanceComponent,
    WorkflowDefinitionFieldInstanceComponent,
    UserHistoryModalComponent,
    ZeroInstancesComponent,
    ZeroEisComponent,
    FixImageOrientationDirective,
    ConfirmPetAppointmentComponent,
    PetDepositRequestComponent,
    ServiceSelectorPetComponent,
    PetAppointmentSelectorComponent,
    PetPortalOptionsComponent,
    PetExistingCustomerSelectorComponent,
    PetLocationSelectorComponent,
    PetCheckInFormSelectorComponent,
    NewPetCustomerSelectorComponent,
    CreateAppointmentPanelComponent,
    CommonServiceSelectorComponent,
    BaseInvoiceServicesPanelComponent,
    AssignCustomerToPlanComponent,
    AppointmentCalendarFilterComponent,
    CommonContractsComponent,
    AvatarRowComponent,

    // PetAppointmentSelectorV2Component,
    // NewCustomerSelectorV2Component,
    // NewPetSelectorV2Component,
    // CreatePetExistingCustomerComponent
  ]
})

export class ElementModule {
}
