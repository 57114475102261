import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbsService } from './breadcrumbs.service';
import { IBreadcrumb } from './crumb';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: 'breadcrumb.component.html'
})
export class BreadcrumbComponent {
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public breadcrumbsService: BreadcrumbsService) {
  }
  goHome() {
    this.router.navigate(['/']);
  }

  getLabel(breadcrumb: IBreadcrumb, i: number) {
    if (i === 0) {
      return breadcrumb.label;
    } else {
      return '/ ' + breadcrumb.label;
    }
  }
}
