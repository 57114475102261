<div fxLayout="row" fxLayoutAlign="center start" class="confirm-modal">
  <div fxFlex="100"  class="mt-xs">
      <mat-card-title>
        {{'CONFIRMATION' | translate}}
      </mat-card-title>
      <mat-card-content>
        <div [innerHTML]="data.message">{{data.message}}</div>
      </mat-card-content>
      <mat-card-content>
        <button mat-raised-button color="accent" (click)="dialogRef.close(true)">{{'CONFIRM' | translate}}</button>&nbsp;
        <button mat-raised-button color="accent" (click)="dialogRef.close(false)">{{'CANCEL' | translate}}</button>
      </mat-card-content>
  </div>
</div>
