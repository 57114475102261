import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContextIdDto } from '@savvy/view-definition';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CookiesService } from '../../../services/cookies.service';
import {Router} from "@angular/router";

@Component({
  selector: 'app-past-due',
  templateUrl: './past-due.component.html',
  styleUrls: ['./past-due.component.scss'],
})
export class PastDueComponent implements OnInit {
  @Output() cardChanged = new EventEmitter();

  @Input() contextIdDto: ContextIdDto;
  @Input() subscriptionId: any;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<PastDueComponent>,
    private translateService: TranslateService,
    private cookiesService: CookiesService,
    private router: Router,
  ) {

    this.contextIdDto = data.contextIdDto;
    this.subscriptionId = data.subscriptionId;
  }

  ngOnInit() {
    console.log('init PastDueComponent');
  }

  goToSubscriptions() {
    this.router.navigate(['/subscription/subscriptions', this.contextIdDto.contextId, this.contextIdDto.contextIdType]);
    this.dialogRef.close();
  }

  skip() {
    this.cookiesService.setCookie('skipPastDue', true, 1);
    this.dialogRef.close();
  }

}
