import { Component, Input, OnInit } from '@angular/core';
import { ContextIdDto } from '@savvy/quickbooks';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ConfService } from '../config/conf.service';
import { UsersService } from '@savvy/user';
import { SocialWidgetDto } from '@savvy/view-definition';

@Component({
  selector: 'app-social-widget',
  templateUrl: 'socialWidget.component.html'
})
export class SocialWidgetComponent implements OnInit {
  @Input() socialWidgetDto: SocialWidgetDto;
  @Input() contextIdDto: ContextIdDto;

  avatar;
  imageUrl;

  constructor(
    private router: Router,
    protected http: HttpClient,
    private userApi: UsersService) {
  }

  // getAvatar() {
  //     this.userApi.getAvatar(this.socialWidgetDto.email).subscribe(
  //         response => {
  //             this.avatar = response;
  //         }
  //     );
  // }
  public editEntityInstance() {
    console.log('navigating to ' + this.socialWidgetDto.entityInstanceId.id);
    console.log('navigating to ' + this.contextIdDto.contextId);
    console.log('navigating to ' + this.contextIdDto.contextIdType);
    this.router.navigate(['/entity/editEntity',
      this.socialWidgetDto.entityInstanceId.id,
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType]);
  }

  getImageUrl() {
    const url = ConfService.apiUrl() + '/rest/ui/runtime/avatar/' + this.socialWidgetDto.email;
    console.log('url is ' + url);
    return url;
  }

  // getPicassaUrl() {
  //     return this.userApi.getAvatar(this.socialWidgetDto.email);
  // }

  ngOnInit(): void {
    if (this.socialWidgetDto && this.socialWidgetDto.email) {
      if (this.socialWidgetDto?.email?.endsWith('gmail.com')
        || this.socialWidgetDto?.email?.endsWith('yahoo.com')
        || this.socialWidgetDto?.email?.endsWith('hotmail.com')
        || this.socialWidgetDto?.email?.endsWith('googlemail.com')) {
        this.imageUrl = this.getImageUrl();
      }
    }
  }
}
