<mat-toolbar color="primary">
    <h5>Merge Pet</h5>
</mat-toolbar>
<div class="mdc-p-3 ">
    <h6 class="my-1 font-weight-bold">{{'OG_PET' | translate}}</h6>
    <p class="mat-text-secondary">{{'OG_PET_DESC' | translate}}</p>
    <app-pet-selector-in-customer class="field-center" [contextIdDto]="contextIdDto" (selectedPet)="sourcePetChanged($event)" [customerId]="customerId">
    </app-pet-selector-in-customer>

    <h6 class="my-1 font-weight-bold">{{'DUPE_PET' | translate}}</h6>
    <p class="mat-text-secondary">{{'DUPE_PET_DESC' | translate}}</p>
    <app-pet-selector-in-customer class="field-center" [contextIdDto]="contextIdDto" (selectedPet)="targetPetChanged($event)" [customerId]="customerId">
    </app-pet-selector-in-customer>
    <br>
    <h6 *ngIf="sourcePet && targetPet && sourcePet.id == targetPet.id" style="color: red">{{'SAME_PETS_CANT_MERGE' | translate}}</h6>
    <button mat-raised-button (click)="merge()" color="accent" [disabled]="sourcePet && targetPet && sourcePet.id == targetPet.id">{{'MERGE' | translate}}</button>
</div>