<table *ngIf="lineDefinition && lineDefinition.lineEntryDtos" class="example-full-width"
       cellspacing="0">
    <tr>
        <td *ngFor="let elementDto of lineDefinition.lineEntryDtos">
            <app-element-instance
                    [elementDto]="elementDto"
                    [viewContext]="viewContext"
                    [contextIdDto]="contextIdDto"
                    [additionalDataMapDto]="additionalDataMapDto"
                    [showPlaceholder]="showPlaceholder"
                    [form]="form">
            </app-element-instance>
        </td>
    </tr>
</table>
