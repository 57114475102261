<div>
  <mat-toolbar-row *ngIf="quickAppointment">
    <span fxLayout="row" fxLayoutAlign=" center">
      <span class="icon-24 mat-text-secondary">
        <mat-icon>event</mat-icon>
      </span>
      <span class="date-time padding-left-half" fxLayout="column">
        <span>{{createAppointmentV2SyncService.startDate | localizedDate}}</span>
        <small class="mat-text-secondary fs-12">
          {{createAppointmentV2SyncService.displayStartTime | localizedDate:'h:mm a'}} -
          {{createAppointmentV2SyncService.displayEndTime | localizedDate:'h:mm a'}}
        </small>
      </span>
    </span>
    <span class="example-spacer"></span>
    <button mat-icon-button color="primary" aria-label="Example icon button with a home icon" (click)="fullScreen()">
      <mat-icon class="full-screen-icon">open_in_full</mat-icon>
    </button>
  </mat-toolbar-row>
  <div color="primary" *ngIf="!quickAppointment" class="mdc-px-3 mdc-py-2"
    style="background-color: var(--currentStepColor) ;">

    <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign=" start" fxLayoutAlign.gt-sm=" center" class="w-100"
      *ngIf="!data.edit">
      <span fxFlex="100%" fxFlex.gt-sm="nogrow">
        <h5 class="text-truncated my-2">{{'CREATE' | translate: {Default: 'Create'} }} {{appointmentTitle}}</h5>
      </span>

      <div *ngIf="(!data.edit) || (deviceService.isMobile() || deviceService.isTablet())" fxFlex="100%" fxLayout="row"
        fxLayoutAlign=" center" class="w-100">
        <div fxFlex *ngIf="!data.edit && !data.quoteId" align="start" align.gt-sm="center"
          [ngClass]="{'text-md-center': !deviceService.isMobile()}">
          <mat-button-toggle-group [(ngModel)]="customerMode" (ngModelChange)="customerModeChange($event)"
            name="customerMode" aria-label="Customer Mode" class="button-group-payments">
            <mat-button-toggle value="existingCustomer">{{'EXISTING' | translate: {Default: 'Existing'} }}<span
                class="d-none d-sm-inline-block">{{'CUSTOMER' | translate: {Default: 'Customer'} }}</span>
            </mat-button-toggle>
            <mat-button-toggle value="newCustomer">New Customer</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div fxFlex="nogrow" fxFlex.gt-sm="24%" align="end">
          <button mat-icon-button aria-label="{{'CLOSE' | translate: {Default: 'Close'} }}" (click)="cancel()"
            *ngIf="deviceService.isMobile() || deviceService.isTablet()">
            <mat-icon class="full-screen-icon">close</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign=" center" class="w-100" *ngIf="data.edit">
      <span fxFlex="100%">
        <h5 class="text-truncate my-2">Edit {{appointmentTitle}}</h5>
      </span>
      <div fxFlex="nogrow">
        <button mat-icon-button aria-label="{{'CLOSE' | translate: {Default: 'Close'} }}" (click)="cancel()"
          *ngIf="deviceService.isMobile() || deviceService.isTablet()">
          <mat-icon class="full-screen-icon">close</mat-icon>
        </button>
      </div>
    </div>

  </div>
  <!-- <mat-toolbar color="primary" *ngIf="!quickAppointment">
    <mat-toolbar-row>
      <span fxLayout="row" fxLayoutAlign=" center" *ngIf="!data.edit">
        Create {{appointmentTitle}}
      </span>
      <span fxLayout="row" fxLayoutAlign=" center" *ngIf="data.edit">
        Edit {{appointmentTitle}}
      </span>
      <span class="example-spacer"></span>
      <button type="button" mat-flat-button class="mt-10px ml-1" (click)="existingCustomer = !existingCustomer"
        matTooltip="New Customer" matTooltipPosition="above">
        <mat-icon>person_add</mat-icon> New Customer
      </button>
      <button mat-icon-button aria-label="Close" (click)="cancel()"
        *ngIf="deviceService.isMobile() || deviceService.isTablet()">
        <mat-icon class="full-screen-icon">close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar> -->

  <mat-card appearance="outlined" class="m-0">
    <mat-card-content>
      <!-- <app-create-quick-appointment #createQuickAppointmentComponent *ngIf="quickAppointment"
        [contextIdDto]="contextIdDto" [appointmentDate]="createAppointmentV2SyncService.startDate">
      </app-create-quick-appointment> -->

      <div *ngIf="!quickAppointment">
        <!-- <ng-template matStepLabel>{{'Appointment' | translate}}</ng-template> -->

        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="5px" class="w-100 mdc-mb-1">
          <ng-container *ngIf="customerMode === 'existingCustomer'">
            <div fxFlex="auto">
              <app-customer-selector-v2 #customerSelectorV2Component [contextIdDto]="contextIdDto" [multiple]="false">
              </app-customer-selector-v2>
            </div>
          </ng-container>
          <ng-container *ngIf="customerMode === 'newCustomer'">
            <app-new-customer-selector-v2 class="mt-15px w-100" [contextIdDto]="contextIdDto"
              (customerCreated)="customerCreated($event)" (newExistingSwitch)="existingCustomer = true">
            </app-new-customer-selector-v2>
          </ng-container>

          <!-- <div fxLayout="row">
            <div fxFlex="75" fxFlex.gt-sm="nogrow">
              <app-location-selector-v2 [contextIdDto]="contextIdDto" #locationSelectorV2Component>
              </app-location-selector-v2>
            </div>
          </div> -->
        </div>



        <!-- <h6 class="mt-2 font-weight-medium "
          [hidden]="!existingCustomer || !createAppointmentV2SyncService.customersSelected.length">
          <strong>Services</strong>
        </h6> -->
        <div fxLayout="row wrap" fxLayoutGap="5px" fxLayout.gt-sm="row wrap">
          <div fxFlex="47" fxFlex.gt-sm="20">
            <app-appointment-selector-v2 *ngIf="appointmentConfigHelperService?.appointmentConfig"
              #appointmentSelectorV2Component class="mt-15px display-block" [contextIdDto]="contextIdDto"
              [(startDate)]="createAppointmentV2SyncService.startDate"
              [(endDate)]="createAppointmentV2SyncService.startDate"
              [(startTime)]="createAppointmentV2SyncService.startTime"
              [(endTime)]="createAppointmentV2SyncService.endTime" [totalAppointmentDuration]="totalAppointmentDuration"
              [disableTimePickup]="appointmentConfigHelperService.appointmentConfig.customizedTimeForServices"
              [rebook]="data.rebook">
            </app-appointment-selector-v2>
          </div>
          <div fxFlex="50" fxFlex.gt-sm="20">
            <mat-form-field appearance="outline" class="mt-15px display-block w-100">
              <mat-label>{{'ENQUIRY_DATE' | translate: {Default: 'Enquiry Date'} }} </mat-label>
              <input matInput #datePickerInput [(ngModel)]="enquiryDate" [matDatepicker]="enquiryDatePicker"
                (focus)="enquiryDatePicker.open()"
                [placeholder]="'SELECT_ENQUIRY_DATE' | translate: {Default: 'SELECT_ENQUIRY_DATE'} " disabled>
              <mat-datepicker-toggle matSuffix [for]="enquiryDatePicker"></mat-datepicker-toggle>

              <mat-datepicker #enquiryDatePicker [restoreFocus]="false">
              </mat-datepicker>
            </mat-form-field>
          </div>
          <div fxFlex="50" fxFlex.gt-sm="18" *ngIf="appointmentRulesService.showWorkflowDefs()">
            <app-workflow-selector-v2 class="mt-15px display-block w-100" [contextIdDto]="contextIdDto"
              #workflowSelectorV2Component>
            </app-workflow-selector-v2>
          </div>
          <div fxFlex="47" fxFlex.gt-sm="20">
            <app-appointment-v2-icons #appointmentV2IconsComponent [selectedIcons]="appointment?.icons"
              class="mt-15px display-block">
            </app-appointment-v2-icons>
          </div>
          <div fxFlex="47" fxFlex.gt-sm="18">
            <app-location-selector-v2 class="mt-15px display-block" [contextIdDto]="contextIdDto"
              [locationIdSelected]="appointment?.locationId" (locationChange)="locationChange($event)"
              #locationSelectorV2Component>
            </app-location-selector-v2>
          </div>
        </div>

        <app-packages-selector-v2 *ngIf="createAppointmentV2SyncService.enablePackages" class="display-block"
          #PackagesSelectorV2Component [contextIdDto]="contextIdDto" [disabled]="data.quoteId ? true : false">
        </app-packages-selector-v2>

        <app-services-selector-v2 class="display-block" #ServiceSelectorV2Component [contextIdDto]="contextIdDto"
          [allowedToRemove]="customerMode === 'existingCustomer'"
          [userToUseWhenCreating]="createAppointmentV2SyncService.userToUseWhenCreating"
          (updateCustFlags)="updateCustFlags()" [disabled]="data.quoteId ? true : false">
        </app-services-selector-v2>

        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="5px">
          <div fxFlex="100">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Notes</mat-label>
              <textarea matInput [(ngModel)]="notes" [ngModelOptions]="{standalone: true}" rows="4"></textarea>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign=" center"
          class="petservice mdc-mb-1 rounded-10 border mdc-p-3"
          *ngIf="createAppointmentV2SyncService?.customersSelected?.[0]?.id && createAppointmentV2SyncService.totalAmount">
          <app-payment fxFlex="grow" class="my-2" #paymentComponent [contextIdDto]="contextIdDto"
            [customerId]="createAppointmentV2SyncService?.customersSelected?.[0]?.id"
            [totalAmount]="createAppointmentV2SyncService.totalAmount" [(paymentConfig)]="appointment.paymentConfig"
            (paymentConfig)="paymentConfigChanged($event)" (amountDueChanged)="amountDueChanged($event)"
            (createPlanChanged)="createPlanChanged($event)"></app-payment>
          <app-confirm-appointment-v2 fxFlex="100" fxFlex.gt-sm="45" align="end" [contextIdDto]="contextIdDto"
            #confirmAppointmentV2Component></app-confirm-appointment-v2>
        </div>

        <div *ngIf="createAppointmentV2SyncService?.customersSelected?.length && !data.edit && !data.rebook">
          <mat-slide-toggle [labelPosition]="'before'" class="mdc-mb-1" [(ngModel)]="isRepeatAvailable">
            {{'REPEAT_APPOINTMENT_TOGGLE' | translate: {Default: 'Repeat Appointment?'} }}
          </mat-slide-toggle>
          <br>
          <app-repeat-appointments *ngIf="isRepeatAvailable" (checkConflictApi)="checkConflictApi($event)"
            #repeatAppointmentComponent></app-repeat-appointments>
        </div>
      </div>



      <mat-card appearance="outlined" class="mx-0 mdc-mb-1" *ngIf="showConfirmationDialog">
        <mat-card-header class="close-confirm">
          <mat-icon (click)="closeConfirmDialog()">cancel</mat-icon>
        </mat-card-header>
        <mat-card-content>
          <div fxLayout="row" fxLayoutAlign=" center">
            <div fxFlex>
              <h6>Confirmation!</h6>
              <p class="small mat-text-secondary mb-0">
                <strong>{{'CONFIRMING_SENDS_NOTIFICATIONS' | translate: {Default: 'Confirming will send email/SMS
                  notifications to the customer.'} }}</strong>
              </p>
              <p class="small mat-text-secondary mb-0">
                <mat-checkbox [(ngModel)]="confirmEnabled" (ngModelChange)="updateConfirmEnabled($event)">
                  {{'DONT_SHOW_MESSAGE_AGAIN' | translate: {Default: 'Don\'t Show This Message Again'} }}
                </mat-checkbox>
              </p>
            </div>
            <div fxFlex="nogrow">
              <button mat-raised-button color="accent" (click)="confirmAppointment()">{{'OK' | translate: {Default:
                'OK'} }}</button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card appearance="outlined" class="mx-0 mdc-mb-1" *ngIf="checkConflictsDialog">
        <mat-card-header class="close-confirm">
          <mat-icon (click)="checkConflictsDialog = false">cancel</mat-icon>
        </mat-card-header>
        <mat-card-content>
          <div fxLayout="row" fxLayoutAlign=" center">
            <div fxFlex class="mt-1">
              <h6><strong>Clashes!</strong></h6>
              <p class="small mat-text-secondary mb-0">

                <ng-container *ngFor="let clash of clashes">
                  <!-- WE DELIBERATELY ONLY SHOW THE FIRST CLASH TO STOP SPAMMING USER -->
                  <ng-container *ngIf="clash.clashedCalendarEntries[0].entryType === 'userHours'">
                    <span>
                      {{ clash.clashedCalendarEntries[0].detailedLabel }}
                    </span>
                  </ng-container>
                  <ng-container *ngIf="clash.clashedCalendarEntries[0].entryType === 'userOff'">
                    <span>
                      {{ clash.clashedCalendarEntries[0].detailedLabel }}
                    </span>
                  </ng-container>
                  <ng-container *ngIf="clash.clashedCalendarEntries[0].entryType === 'holiday'">
                    <span>
                      {{ clash.clashedCalendarEntries[0].detailedLabel }}
                    </span>
                  </ng-container>
                  <ng-container *ngIf="clash.clashedCalendarEntries[0].entryType === 'businessClosed'">
                    <span>
                      {{'BUSINESS_CLOSED' | translate: {Default: 'The business is closed.'} }}
                    </span>
                  </ng-container>
                  <ng-container *ngIf="clash.clashedCalendarEntries[0].entryType === 'bankHoliday'">
                    <span>
                      {{'THIS_IS_BANK_HOLIDAY' | translate: {Default: 'This is a bank holiday.'} }}
                    </span>
                  </ng-container>
                  <ng-container *ngIf="clash.clashedCalendarEntries[0].entryType === 'appointment'">
                    {{'APPOINTMENT_CLASHES' | translate: {Default: 'The appointment clashes with the following
                    appointment(s):'} }}&nbsp;<span
                      [innerHTML]="'<br>' + clash.clashedCalendarEntries[0].dayCellLinesHtml"></span>
                  </ng-container>
                </ng-container>
              </p>

              <p class="small mat-text-secondary mb-0">
                <mat-checkbox [(ngModel)]="bypassConflictCheck" (ngModelChange)="updateConflictCheck($event)">
                  {{'NEVER_CHECK_CONFLICTS' | translate: {Default: 'Never check for conflicts.'} }}
                </mat-checkbox>
              </p>

              <p class="small mat-text-secondary mb-0">
                Note: You can still <span *ngIf="!data?.edit">create</span><span *ngIf="data?.edit">update</span> your
                booking by clicking on the same button again.
              </p>
            </div>
            <!-- <div fxFlex="nogrow">
              <button mat-raised-button color="accent" (click)="createAnyway()">
                <span *ngIf="data.edit">{{'UPDATE' | translate: {Default: 'Update'} }}</span><span
                  *ngIf="!data.edit">{{'CREATE' | translate: {Default: 'Create'} }}</span>{{'ANYWAY' | translate:
                {Default: 'Anyway'} }}
              </button>
            </div> -->
          </div>
        </mat-card-content>
      </mat-card>

      <div *ngIf="!quickAppointment" fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="16px" fxLayoutAlign=" center">
        <div fxFlex>
          <div fxLayout>
            <div fxFlex="nogrow" class="icon-24 mat-text-secondary" fxFlexAlign="center">
              <mat-icon>event</mat-icon>
            </div>
            <div fxFlex class="date-time padding-left-half" fxLayout="column">
              <span>{{createAppointmentV2SyncService.startDate | localizedDate}}</span>
              <small class="mat-text-secondary fs-12">
                {{createAppointmentV2SyncService.displayStartTime | localizedDate:'h:mm a'}} -
                {{createAppointmentV2SyncService.displayEndTime | localizedDate:'h:mm a'}}
              </small>
            </div>
          </div>
        </div>


        <div fxFlex="nogrow" fxFlexOrder="3">
          <mat-menu #createAppointmentNotes="matMenu">
            <div class="mdc-p-3">
              <p *ngIf="!data.edit"><strong>{{'CREATE' | translate: {Default:'Create'} }}</strong>:
                {{getMessage('create')}}
              </p>
              <p *ngIf="!data.edit"><strong>{{'CREATE_AND_CONFIRM' | translate: {Default: 'Create and Confirm'}
                  }}</strong>: {{getMessage('createAndConfirm')}}
              </p>

              <p *ngIf="data.edit"><strong>{{'UPDATE' | translate: {Default: 'Update'} }}</strong>:
                {{getMessage('update')}}
              </p>
              <p *ngIf="data.edit"><strong>{{'UPDATE_AND_RECONFIRM' | translate: {Default: 'Update and Re-Confirm'}
                  }}</strong>: {{getMessage('updateAndConfirm')}}
              </p>
            </div>
          </mat-menu>
          <mat-icon *ngIf="createAppointmentV2SyncService.totalAmount" class="mr-1 vm cp"
            [matMenuTriggerFor]="createAppointmentNotes" color="accent">info</mat-icon>
          <button *ngIf="!data.edit" type="button" class="mt-0 mb-0" mat-raised-button color="accent"
            (click)="createAppointment()" [disabled]="!createAppointmentV2SyncService?.customersSelected?.length"
            matTooltip="{{getMessage('create')}}">
            {{'CREATE' | translate: {Default: 'Create'} }}
          </button>
          <button *ngIf="!data.edit" type="button" class="mt-0 mb-0 ml-1" mat-raised-button color="accent"
            (click)="createAndConfirm()"
            [disabled]="!createAppointmentV2SyncService?.customersSelected?.length || createNewPlan"
            matTooltip="{{getMessage('createAndConfirm')}}">
            {{'CREATE_AND_CONFIRM' | translate:{Default: 'Create and Confirm'} }}
          </button>
          <button *ngIf="data.edit" type="button" class="mt-0 mb-0" mat-raised-button color="accent"
            (click)="updateAppointment()" [disabled]="!createAppointmentV2SyncService?.customersSelected?.length"
            matTooltip="{{getMessage('update')}}">
            {{'UPDATE' | translate: {Default: 'Update'} }}
          </button>
          <button *ngIf="data.edit" type="button" class="mt-0 mb-0 ml-1" mat-raised-button color="accent"
            (click)="updateAndConfirmAppointment()"
            [disabled]="!createAppointmentV2SyncService?.customersSelected?.length"
            matTooltip="{{getMessage('updateAndConfirm')}}">
            {{'UPDATE_AND_RECONFIRM' | translate: {Default: 'Update and Re-Confirm'} }}
          </button>
        </div>
      </div>

    </mat-card-content>


  </mat-card>

</div>
