import {EventEmitter, Injectable} from '@angular/core';

@Injectable()
export class StyleService {

  public eventAdded$: EventEmitter<string>;
  private events: string[] = [];

  constructor() {
    this.eventAdded$ = new EventEmitter();
  }

  public list(): string[] {
    return this.events;
  }

  public add(event: string): void {
    if (event) {
      this.events.push(event);
      this.eventAdded$.emit(event);
    }
  }
}
