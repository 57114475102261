import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {
  name: string;
  isChange: false;
  message: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConfirmationModalComponent>
  ) {
  }

  ngOnInit() {
    if (this.data) {
      this.isChange = this.data.isChange ? this.data.isChange : false;

      if (this.data.message) {
        this.message = this.data.message;
      } else if (!this.isChange) {
        this.message = 'Are you sure to delete this';
      } else {
        this.message = 'Are you sure you want to mark as';
      }
    }
  }

}
