<form [formGroup]="petFormGroup">
  <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs=" center" class="mdc-mb-2">
    <div fxFlex>
      <h6 class="mdc-mb-1">{{'PET_DETAILS' | translate}}</h6>
    </div>
    <div fxFlex="100" fxFlex.gt-xs="nogrow">
      <div fxLayout="row" fxLayoutAlign.gt-xs=" center">
        <div class="avatar avatar-40 circle border-3">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" viewBox="0 0 30 22">
            <g id="Group_187" data-name="Group 187" transform="translate(-2255.804 -956)">
              <g id="Rectangle_58" data-name="Rectangle 58" transform="translate(2262.804 956)" fill="#fff" stroke="#ef9a9a" stroke-width="1">
                <path d="M8,0H8a8,8,0,0,1,8,8V20a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V8A8,8,0,0,1,8,0Z" stroke="none" />
                <path d="M8,.5H8A7.5,7.5,0,0,1,15.5,8V19.5a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V8A7.5,7.5,0,0,1,8,.5Z" fill="none" />
              </g>
              <g id="pets_black_24dp" transform="translate(2263.935 958.13)">
                <path id="Path_30" data-name="Path 30" d="M0,0H13.739V13.739H0Z" fill="none" />
                <circle id="Ellipse_6" data-name="Ellipse 6" cx="1.5" cy="1.5" r="1.5" transform="translate(0.87 3.87)" fill="#ef9a9a" />
                <circle id="Ellipse_7" data-name="Ellipse 7" cx="1.431" cy="1.431" r="1.431" transform="translate(3.721 1.717)" fill="#ef9a9a" />
                <circle id="Ellipse_8" data-name="Ellipse 8" cx="1.431" cy="1.431" r="1.431" transform="translate(7.156 1.717)" fill="#ef9a9a" />
                <circle id="Ellipse_9" data-name="Ellipse 9" cx="1.5" cy="1.5" r="1.5" transform="translate(9.87 3.87)" fill="#ef9a9a" />
                <path id="Path_31" data-name="Path 31" d="M11.634,13c-.5-.584-.916-1.082-1.42-1.666a2.429,2.429,0,0,0-1-.756,1.159,1.159,0,0,0-.189-.052,2.881,2.881,0,0,0-.447-.023,2.4,2.4,0,0,0-.452.029,1.159,1.159,0,0,0-.189.052,2.466,2.466,0,0,0-1,.756c-.5.584-.916,1.082-1.42,1.666-.75.75-1.672,1.58-1.5,2.742a1.8,1.8,0,0,0,1.334,1.328c.418.086,1.752-.252,3.171-.252h.1c1.42,0,2.754.332,3.171.252a1.806,1.806,0,0,0,1.334-1.328c.177-1.168-.744-2-1.494-2.748Z" transform="translate(-1.707 -4.489)"
                  fill="#ef9a9a" />
              </g>
              <g id="Rectangle_59" data-name="Rectangle 59" transform="translate(2260.804 975)" fill="#fff" stroke="#ef9a9a" stroke-width="1">
                <rect width="20" height="3" rx="1.5" stroke="none" />
                <rect x="0.5" y="0.5" width="19" height="2" rx="1" fill="none" />
              </g>
              <line id="Line_26" data-name="Line 26" x2="5" transform="translate(2256.304 972.5)" fill="none" stroke="#ef9a9a" stroke-linecap="round" stroke-width="1" />
              <line id="Line_29" data-name="Line 29" x2="3" transform="translate(2258.304 970.5)" fill="none" stroke="#ef9a9a" stroke-linecap="round" stroke-width="1" />
              <line id="Line_27" data-name="Line 27" x2="5" transform="translate(2280.304 972.5)" fill="none" stroke="#ef9a9a" stroke-linecap="round" stroke-width="1" />
              <line id="Line_28" data-name="Line 28" x2="3" transform="translate(2280.304 970.5)" fill="none" stroke="#ef9a9a" stroke-linecap="round" stroke-width="1" />
            </g>
          </svg>
        </div>
        <mat-slide-toggle class="ml-1 mr-2" name="deceased" formControlName="deceased" [labelPosition]="'before'">
          <span>{{'MARK_AS_DECEASED' | translate}}</span>
        </mat-slide-toggle>
      </div>
    </div>
  </div>
  <div fxLayout="row wrap" fxLayoutGap="0px" fxLayoutGap.gt-xs="10px">
    <mat-form-field fxFlex="100" fxFlex.gt-xs="46" fxFlex.gt-sm="24" appearance="outline">
      <mat-label>Gender</mat-label>
      <mat-select placeholder="{{'GENDER' | translate}}" formControlName="bitch" name="bitch">
        <mat-option [value]="false">{{'MALE' | translate}}</mat-option>
        <mat-option [value]="true">{{'FEMALE' | translate}}</mat-option>
      </mat-select>
      <!-- <mat-error *ngIf="petFormGroup.controls?.bitch?.errors && (petFormGroup.touched || petFormGroup.dirty)">Please select gender.</mat-error> -->
    </mat-form-field>
    <mat-form-field fxFlex="100" fxFlex.gt-xs="46" fxFlex.gt-sm="24" appearance="outline">
      <mat-label>Colour</mat-label>
      <mat-select placeholder="{{'SELECT_COLOUR' | translate}}" formControlName="colour" name="colour" (selectionChange)="updateColor($event)">
        <mat-option [value]="petColor.label" *ngFor="let petColor of petColors">{{petColor.label}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field fxFlex="100" fxFlex.gt-xs="46" fxFlex.gt-sm="24" appearance="outline">
      <mat-label>{{'DATE_OF_BIRTH' | translate}}</mat-label>
      <input matInput formControlName="dob" [matDatepicker]="picker" (focus)="picker.open()" (click)="picker.open()" placeholder="Start">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <!-- <mat-error *ngIf="petFormGroup.controls?.dob?.errors && (petFormGroup.touched || petFormGroup.dirty)">Please enter date of birth.</mat-error> -->
    </mat-form-field>
    <mat-form-field fxFlex="100" fxFlex.gt-xs="46" fxFlex.gt-sm="24" appearance="outline">
      <mat-label>{{"PRICE" | translate }}</mat-label>
      <input matInput type="text" appNumberOnly [decimalPoints]="2" placeholder="{{'PRICE' | translate}}" formControlName="groomPrice" />
      <!-- <mat-error *ngIf="petFormGroup.controls?.groomPrice?.errors && (petFormGroup.touched || petFormGroup.dirty)">Please enter price.</mat-error> -->
    </mat-form-field>
  </div>
  <div fxLayout="row wrap" fxLayoutGap="10px">

    <mat-form-field fxFlex="46" fxFlex.gt-sm="24" appearance="outline" *ngIf="!contextService.isPetStay()">
      <mat-label>{{'REGULAR_BLADES' | translate}}</mat-label>
      <input matInput formControlName="blades" name="blades" />
      <!-- <mat-error *ngIf="petFormGroup.controls?.blades?.errors && (petFormGroup.touched || petFormGroup.dirty)">Please enter regular blades.</mat-error> -->
    </mat-form-field>
    <mat-form-field fxFlex="46" fxFlex.gt-sm="24" appearance="outline" *ngIf="!contextService.isPetStay()">
      <mat-label>{{"VISIT_FREQ_WEEKS" | translate}}</mat-label>
      <input matInput type="text" appNumberOnly [decimalPoints]="0" placeholder="{{'VISIT_FREQ_WEEKS' | translate}}" formControlName="recommendedVisitFrequencyInWeeks" />
      <!-- <mat-error *ngIf="petFormGroup.controls?.groomPrice?.errors && (petFormGroup.touched || petFormGroup.dirty)">Please enter price.</mat-error> -->
    </mat-form-field>
    <div fxLayout="row wrap" fxLayoutGap="10px" fxFlex="100" fxFlex.gt-sm="48">
      <div fxFlexAlign="center" fxFlex class="mdc-my-1">
        <!-- <span class="ml-1 mr-2">{{'Neutered' | translate}}</span> -->
        <mat-slide-toggle name="spayedOrNeutered" formControlName="spayedOrNeutered">{{'NEUTERED' | translate}}</mat-slide-toggle>
        <!-- <mat-error *ngIf="petFormGroup.controls?.spayedOrNeutered?.errors && (petFormGroup.touched || petFormGroup.dirty)">Please select neutered.</mat-error> -->
      </div>
      <div fxFlexAlign="center" fxFlex class="mdc-my-1">
        <!-- <span class="ml-1 mr-2">{{'Insured' | translate}}</span> -->
        <mat-slide-toggle name="insured" formControlName="insured">{{'INSURED' | translate}}</mat-slide-toggle>
        <!-- <mat-error *ngIf="petFormGroup.controls?.insured?.errors && (petFormGroup.touched || petFormGroup.dirty)">Please select neutered.</mat-error> -->
      </div>
      <div fxFlexAlign="center" fxFlex class="mdc-my-1">
        <!-- <span class="ml-1 mr-2">{{'Afraid Of Noises' | translate}}</span> -->
        <mat-slide-toggle name="afraidOfNoises" formControlName="afraidOfNoises">{{'AFRAID_OF_NOISES' | translate}}</mat-slide-toggle>
        <!-- <mat-error *ngIf="petFormGroup.controls?.afraidOfNoises?.errors && (petFormGroup.touched || petFormGroup.dirty)">Please select neutered.</mat-error> -->
      </div>

    </div>
  </div>
</form>
