import { Component, Input, OnInit } from '@angular/core';
import { ContextIdDto } from '@savvy/ui';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';
import { TranslateService } from '@ngx-translate/core';
import {
  NotificationMethodMatrix,
  NotificationMethodMatrixService
} from '@savvy/notification';
import {MatDialogRef} from '@angular/material/dialog';
import {NotificationMethod} from '@savvy/notification/model/notificationMethod';

@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss']
})
export class NotificationSettingsComponent implements OnInit {

  @Input() contextIdDto: ContextIdDto;

  notificationMethodMatrix: NotificationMethodMatrix;

  constructor(private api: NotificationMethodMatrixService,
    private notify: FloSnackbarComponent,
              public dialogRef: MatDialogRef<NotificationSettingsComponent>,
              private translateService: TranslateService) {

  }

  ngOnInit(): void {
    this.loadNotificationSettings();
  }

  isBellEnabled(notificationMethodIn: NotificationMethod) {

    let checked = false;

    if (this.notificationMethodMatrix.notificationMethods) {
      this.notificationMethodMatrix.notificationMethods.forEach(notificationMethod => {
        if (notificationMethod.notificationType === notificationMethodIn.notificationType) {
          checked = notificationMethod.bell;
        }
      });
    }

    return checked;
  }

  bellSelectionChanged(event: any, notificationMethodIn: NotificationMethod) {

    if (this.notificationMethodMatrix.notificationMethods) {
      this.notificationMethodMatrix.notificationMethods.forEach(notificationMethod => {
        if (notificationMethod.notificationType === notificationMethodIn.notificationType) {
          notificationMethod.bell = event.checked;
        }
      });
    }
    this.update(false);
  }

  isPopupEnabled(notificationMethodIn: NotificationMethod) {

    let checked = false;

    if (this.notificationMethodMatrix.notificationMethods) {
      this.notificationMethodMatrix.notificationMethods.forEach(notificationMethod => {
        if (notificationMethod.notificationType === notificationMethodIn.notificationType) {
          checked = notificationMethod.snack;
        }
      });
    }

    return checked;
  }

  popupSelectionChanged(event: any, notificationMethodIn: NotificationMethod) {

    if (this.notificationMethodMatrix.notificationMethods) {
      this.notificationMethodMatrix.notificationMethods.forEach(notificationMethod => {
        if (notificationMethod.notificationType === notificationMethodIn.notificationType) {
          notificationMethod.snack = event.checked;
        }
      });
    }
    this.update(false);
  }

  isBannerEnabled(notificationMethodIn: NotificationMethod) {

    let checked = false;

    if (this.notificationMethodMatrix.notificationMethods) {
      this.notificationMethodMatrix.notificationMethods.forEach(notificationMethod => {
        if (notificationMethod.notificationType === notificationMethodIn.notificationType) {
          checked = notificationMethod.banner;
        }
      });
    }

    return checked;
  }

  bannerSelectionChanged(event: any, notificationMethodIn: NotificationMethod) {

    if (this.notificationMethodMatrix.notificationMethods) {
      this.notificationMethodMatrix.notificationMethods.forEach(notificationMethod => {
        if (notificationMethod.notificationType === notificationMethodIn.notificationType) {
          notificationMethod.banner = event.checked;
        }
      });
    }
    this.update(false);
  }

  loadNotificationSettings() {
    this.api.getNotificationMethodMatrix(this.contextIdDto.contextId, this.contextIdDto.contextIdType)
      .subscribe(response => {
        console.log('loaded notification method matrix ', response);
        this.notificationMethodMatrix = response;
      });
  }

  isValid() {
    return true;
  }

  update(close: boolean) {
    console.log('update');
    this.api.saveNotificationMethodMatrix(this.notificationMethodMatrix).subscribe(response => {
      this.notificationMethodMatrix = response;
      this.translateService.get('Notification Matrix updated').subscribe(value => {
        this.notify.message = value;
        this.notify.open();
        if (close) {
          this.dialogRef.close(true);
        }
      });
    });
  }
}
