<div fxLayout="row" fxLayout.gt-sm="row">
  <div fxflex="nogrow">
    <div class="avatar avatar-50 circle border-3">
      <mat-icon>payments</mat-icon>
    </div>
  </div>

  <!-- <div class="subtotal-amount">{{totalAmount | currency:currencyCode:'symbol'}}</div>
  <div class="subtotal-label">Sub Total</div> -->
  <div fxFlex fxLayout="column" class="pl-1">
    <h5>{{'PAYMENT' | translate}}</h5>
    <div fxFlex class="mt-1">
      <h6>
        Generate Invoice
        <mat-radio-group aria-label="Select an option" class="ml-1" [(ngModel)]="paymentConfig.petStayInvoiceType">
          <mat-radio-button value="UP_FRONT">{{'NOW' | translate}}</mat-radio-button>
          <mat-radio-button value="GENERATE_LATER">{{'LATER' | translate}}</mat-radio-button>
        </mat-radio-group>
      </h6>
    </div>
    <div fxFlex *ngIf="paymentConfig.petStayInvoiceType === 'UP_FRONT'">
      <mat-radio-group id="appointment-payment-modes" aria-label="Select an option" [(ngModel)]="paymentConfig.petStayPaymentMode" (ngModelChange)="paymentModeChanged($event)">
        <mat-radio-button [value]="paymentModeEnum.DepositRequest">{{'DEPOSIT_REQUEST' | translate}}</mat-radio-button>
        <mat-radio-button [value]="paymentModeEnum.PaymentPlan">{{'PAYMENT_PLAN' | translate}}</mat-radio-button>
        <mat-radio-button [value]="paymentModeEnum.InPerson">{{'IN_PERSON' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
    <!-- <div fxFlex *ngIf="paymentConfig.petStayInvoiceType === 'GENERATE_LATER'">
      <mat-radio-group id="appointment-payment-modes" aria-label="Select an option"
        [(ngModel)]="paymentConfig.petStayPaymentMode" (ngModelChange)="paymentModeChanged($event)">
        <mat-radio-button [value]="paymentModeEnum.DepositRequest">Weekly</mat-radio-button>
        <mat-radio-button [value]="paymentModeEnum.PaymentPlan">Monthly</mat-radio-button>
      </mat-radio-group>
    </div> -->
    <div fxFlex *ngIf="paymentConfig.petStayInvoiceType === 'UP_FRONT' && paymentConfig.petStayPaymentMode === paymentModeEnum.DepositRequest" class="pt-1">
      <mat-form-field appearance="outline" class="input-fields-custom">
        <mat-label>{{'PAYMENT_VALUE' | translate}}</mat-label>
        <mat-select id="appointment-payment-type-deposit" [(ngModel)]="paymentConfig.petStayPaymentType" (ngModelChange)="paymentTypeChanged($event)">
          <mat-option [value]="paymentTypeEnum.Percentage">{{'PERCENTAGE' | translate}}</mat-option>
          <mat-option [value]="paymentTypeEnum.Amount">{{'AMOUNT' | translate}}</mat-option>
          <mat-option [value]="paymentTypeEnum.FullAmount">{{'FULL_AMOUNT' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="paymentConfig?.petStayPaymentType === paymentTypeEnum.Percentage" appearance="outline" class="input-fields-custom">
        <mat-label>{{'PERCENTAGE' | translate}}</mat-label>
        <input id="appointment-payment-type-deposit-percentage" matInput placeholder="" [(ngModel)]="percentage" (ngModelChange)="calculateAmountDue()" appNumberOnly [decimalPoints]="2" [minValue]="0" [maxValue]="100" required>
        <span matSuffix>%</span>
      </mat-form-field>
      <mat-form-field *ngIf="paymentConfig?.petStayPaymentType === paymentTypeEnum.Amount" appearance="outline" class="input-fields-custom" appNumberOnly [decimalPoints]="2">
        <mat-label>{{'AMOUNT' | translate}}</mat-label>
        <input id="appointment-payment-type-deposit-amount" matInput appNumberOnly [decimalPoints]="2" [maxValue]="totalAmount" placeholder="" [(ngModel)]="amount" (ngModelChange)="amountChanged($event)" required>
      </mat-form-field>
      <!-- <mat-form-field appearance="outline" class="input-fields-custom">
      <mat-label>Amount Payable</mat-label>
      <input matInput type="number" placeholder="" readonly [(ngModel)]="amount" min="0">
    </mat-form-field> -->
    </div>
    <div fxFlex *ngIf="paymentConfig.petStayInvoiceType === 'UP_FRONT' && paymentConfig.petStayPaymentMode === paymentModeEnum.PaymentPlan" fxLayout="column" fxLayoutAlign="center" fxLayoutGap="8px">
      <mat-form-field *ngIf="plans && plans.length" appearance="outline" class="w-100">
        <mat-label>{{'CHOOSE_PAYMENT_PLAN' | translate}}</mat-label>
        <mat-select id="appointment-payment-type-payment-plan" placeholder="{{'SELECT_PAYMENT_PLAN' | translate}}" name="paymentPlan" #paymentPlanField="ngModel" [(ngModel)]="selectedPlan" (ngModelChange)="planChanged($event)" required>
          <mat-option *ngFor="let plan of plans" [value]="plan">
            {{plan.planName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-hint *ngIf="plans && plans.length && selectedPlan?.unlimitedSessions">
        {{'PLAN_USED_UNLIMITED_HINT' | translate}}
      </mat-hint>
      <mat-hint *ngIf="plans && plans.length && selectedPlan?.sessionsRemaining">
        {{'ONLY' | translate}} {{selectedPlan.sessionsRemaining}} {{'NUMBER_OF_SESSIONS_REMAINING' | translate}}
      </mat-hint>
      <div *ngIf="plans && !plans.length" class="d-block mt-1 rounded-10 border mdc-p-3">
        <h6>{{'NO_PLANS_FOR_CUSTOMER' | translate}}</h6>
        <hr class="mx-0 w-100">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
          <div fxFlex="nogrow">
            <mat-form-field appearance="outline" class="nomarginbottom">
              <mat-label>{{'ASSIGN_PLAN' | translate}}</mat-label>
              <mat-select placeholder="{{'ASSIGN_PLAN' | translate}}" name="planDefinition" #planDefinition="ngModel" [(ngModel)]="selectedPlanDefinition" required (ngModelChange)="planDefinitionChange($event)">
                <mat-option *ngFor="let planDefinition of planDefinitions" [value]="planDefinition">
                  {{planDefinition.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

          </div>
          <div fxFlex="nogrow">
            <p class="mb-0">{{'SUITABLE_PLAN_NOT_LISTED' | translate}}?<br>{{'CREATE_NEW_PLAN_AT' | translate}} <a (click)="goToPlans()" class="mat-text-accent">{{'PAYMENT_PLANS' | translate}}</a></p>
          </div>
        </div>
        <mat-hint class="mt-1">{{'SENDING_INVOICE_HINT' | translate}}</mat-hint>
      </div>

      <mat-form-field *ngIf="plans && plans.length && selectedPlan?.sessionsRemaining" appearance="outline" class="input-fields-custom" appNumberOnly [decimalPoints]="2" class="w-100">
        <mat-label>{{'SESSIONS_TO_REDEEM' | translate}}</mat-label>
        <input matInput appNumberOnly placeholder="" [maxValue]="selectedPlan?.sessionsRemaining" [(ngModel)]="paymentConfig.noOfSessionsToRedeem" required>
      </mat-form-field>
    </div>
    <div *ngIf="paymentConfig.petStayInvoiceType === 'UP_FRONT' && paymentConfig.petStayPaymentMode !== paymentModeEnum.PaymentPlan && customerDto && customerDto.mobilePhoneNumberObj && paymentSettings && paymentSettings.savvyPaySettings && paymentSettings.savvyPaySettings.accountHolderCode" fxLayoutAlign="start center" style="padding: 1em .75em 1em 0;">
      <mat-slide-toggle id="appointment-req-payment-in-sms" color="accent" [(ngModel)]="paymentConfig.sendSms" name="sendSms" #sendSms="ngModel">
        <span class="ml-1">{{'SEND_PAYMENT_LINK_BY_SMS' | translate}}?</span>
      </mat-slide-toggle>
      &nbsp;&nbsp;
      <!--      <mat-slide-toggle *ngIf="paymentConfig.petStayPaymentMode === paymentModeEnum.InPerson && paymentConfig.sendSms" color="accent"-->
      <!--                        [(ngModel)]="paymentConfig.preAuth"-->
      <!--                        name="preAuth"-->
      <!--                        #preAuth="ngModel"-->
      <!--      >-->
      <!--        <span class="ml-1">{{'Pre-authorization?' | translate}}</span>-->
      <!--      </mat-slide-toggle>-->

    </div>
    <mat-hint *ngIf="paymentConfig.petStayPaymentMode !== paymentModeEnum.PaymentPlan
      && customerDto && customerDto.mobilePhoneNumberObj && paymentSettings
      && paymentSettings.savvyPaySettings && paymentSettings.savvyPaySettings.accountHolderCode">
      {{'CUSTOMER_PAY_WITH_LINK_HINT' | translate}}
    </mat-hint>
    <mat-hint *ngIf="paymentConfig.petStayPaymentMode === paymentModeEnum.InPerson
     && paymentConfig.sendSms
     && customerDto
     && customerDto.mobilePhoneNumberObj
     && paymentSettings && paymentSettings.savvyPaySettings
     && paymentSettings.savvyPaySettings.accountHolderCode">
      {{'PRE_AUTH_SELECTED_HINT' | translate}}
    </mat-hint>

  </div>
</div>

<!-- <div class="mt-1">
    <h5>Amount Payable</h5>
    <h5>{{paymentConfig.amountDue | currency:currencyCode:'symbol'}}</h5>
  </div> -->