import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ContextIdDto } from '@savvy/app';
import { Location } from '@savvy/location';
import { LocationsHelperService } from 'src/app/flo/shared/services/locations-helper.service';
import { UserDefaultLocationService } from 'src/app/services/user-default-location.service';

@Component({
  selector: 'app-location-selector-v2',
  templateUrl: './location-selector-v2.component.html',
  styleUrls: ['./location-selector-v2.component.scss']
})
export class LocationSelectorV2Component implements OnInit, OnChanges {
  @Input() contextIdDto: ContextIdDto;
  @Input() selectAllLocation = false;
  @Input() required = true;
  @Input() appearance = 'outline';
  @Input() view: 'dropdown' | 'autocomplete' = 'autocomplete';
  @Input() disabled = false;
  @Input() locationIdSelected;

  @Output() locationChange: EventEmitter<Location | ''> = new EventEmitter();

  locations: Array<Location> = [];

  constructor(
    private userDefaultLocationService: UserDefaultLocationService,
    private locationsHelperService: LocationsHelperService
  ) {
  }

  ngOnInit() {
    this.locationsHelperService.activeLocations$.subscribe(res => {
      this.locations = res.filter(l => l.locationName);
      if (this.locations.length) {
        setTimeout(() => {
          if (!this.locationIdSelected) {
            this.locationIdSelected = this.userDefaultLocationService.getLocationId() || this.locations[0].id;
            const locationSelected = this.locations.find(l => l.id === this.locationIdSelected);
            this.locationChange.emit(locationSelected);
          }
        }, 500);
      }
    });
  }

  compareWithFn = (b1: string, b2: string) => b1 === b2;

  locationChanged(locationId: string) {
    if (locationId === 'all') {
      // means all locations selected
      this.locationChange.emit('');
    } else {
      const locationSelected = this.locations.find(l => l.id === locationId);
      this.locationChange.emit(locationSelected);
    }
  }

  findLocation(locationId: string) {
    let locationToRet: Location = {};

    this.locations.forEach(location => {
      if (location.id === locationId) {
        locationToRet = location;
      }
    });

    if (!locationToRet) {
      return this.locations[0];
    }
    return locationToRet;
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (!changes.locationIdSelected.isFirstChange() && changes.locationIdSelected.currentValue !== changes.locationIdSelected.previousValue) {
    //   this.locationIdSelected = changes.locationIdSelected.currentValue;
    // }
  }


}
