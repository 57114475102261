import {Injectable} from '@angular/core';
import {APP_TYPE_ENUM, ContextService} from '../context.service';


@Injectable()
export class AppointmentRulesService {

  constructor(private contextService: ContextService) {

  }

  showWorkflowDefs() {
    if (this.contextService.appType === APP_TYPE_ENUM.PHOTO_STUDIO) {
      return true;
    }
    return false;

  }
}
