import { Injectable } from '@angular/core';
import { ContextIdDto, EntityDefinitionId, EntityInstanceId } from '@savvy/view-definition';
import { MenuDefinition } from '@savvy/menu';
import { Router } from '@angular/router';
import { MenuFactory } from '../menu/menu.factory';

@Injectable()
export class NavigateService {
  constructor(private router: Router,
    private menuFactory: MenuFactory) {
  }

  public navigateHome(contextId: string) {
    this.router.navigate(['calendar/calendarView', contextId, 'ENV_ID']);
  }

  public navigateTo(contextIdDto: ContextIdDto, menuDefinition: MenuDefinition) {
    console.log('contextIdDto is ' + contextIdDto.contextIdType);
    if (menuDefinition) {
      console.log('menuDefinition is ' + menuDefinition);
      const menu = this.menuFactory.getMenuDefinition(menuDefinition, contextIdDto);
      console.log(menu);
      console.log('nav service params ' + menu.params);
      this.router.navigate(menu.params);
    }
  }

  public viewEntity(contextIdDto: ContextIdDto, entityInstanceId: EntityInstanceId) {
    this.viewEi(contextIdDto, entityInstanceId.id);
  }

  public viewEi(contextIdDto: ContextIdDto, entityInstanceId: string) {
    console.log('navigating ' + contextIdDto.contextId + ' ' + entityInstanceId);
    this.router.navigate(['/entity/viewEntity',
      entityInstanceId,
      contextIdDto.contextId,
      contextIdDto.contextIdType
    ]);
  }

  public listEntities(contextIdDto: ContextIdDto, entityDefinitionId: EntityDefinitionId) {
    this.router.navigate(['/entity/listEntityInsts',
      entityDefinitionId.id,
      contextIdDto.contextId,
      contextIdDto.contextIdType
    ]);
  }
}
