<mat-grid-list cols="3" rowHeight="2:1" *ngIf="images && images.length">
  <mat-grid-tile *ngFor="let id of images; let i = index" [attr.data-index]="i">

    <div class="selectbutton" *ngIf="allowedToSelect">
      <mat-radio-button color="primary" name="selectedImage" [value]="id" [checked]="id?.fileReferenceId === selectedImage?.fileReferenceId" (change)="imageSelectionChanged($event)">
        Select
      </mat-radio-button>
    </div>

    <!-- url {{ getUrl(id.fileReferenceId) }} -->
    <img #image src="{{ getUrl(id.fileReferenceId)}}" alt="{{id.fileName}}" (load)="onImageload($event, id)" />
    <mat-grid-tile-footer>
      <h3 matLine>{{ id.mimeType }}</h3>
      <span matLine *ngIf="id.fileName">{{'GALLERY_IMAGE' | translate}}: {{ id.fileName }}</span>
      <span matLine>{{'SIZE' | translate}}: {{ id.width }} * {{ id.height }}</span>
      <span matLine>{{'EXPECTED_SIZE' | translate}}: {{resizeToWidth}} * {{resizeToHeight}}</span>
      <button type="button" (click)="editImage(i, image)" mat-icon-button aria-label="Edit image" title="{{'EDIT_IMAGE' | translate}}">
        <mat-icon>edit</mat-icon>
      </button>
      <button type="button" (click)="deleteImage(i)" mat-icon-button aria-label="Delete image" title="{{'DELETE_IMAGE' | translate}}">
        <mat-icon>delete</mat-icon>
      </button>
    </mat-grid-tile-footer>
  </mat-grid-tile>
</mat-grid-list>

<div class="upload-files mx-0">
  <form #importForm="ngForm" fxLayoutAlign="space-between center">
    <!-- <mat-card-title>Images</mat-card-title> -->
    <input [hidden]="true" id="input-file-id" appImgCompressor multiple required (change)="saveFiles($event)" type="file">
  </form>

  <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="droppedFiles($event)">
    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
      <div class="text-center">
        <h5 class="mb-0">{{'DROP_IMAGES_HERE' | translate}}</h5>
        <button type="button" mat-button color="accent" (click)="openFileSelector()">{{'BROWSE_IMAGES' | translate}}</button>
      </div>
    </ng-template>
  </ngx-file-drop>
</div>