import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { webhookRoutes } from './webhooks.routing';
import { FloMaterialModule } from '../flo.material.module';

import { SharedModule } from '../shared/shared.module';

import { TranslateModule } from '@ngx-translate/core';
import { WorkflowModule } from '../workflow/workflow.module';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ArchwizardModule } from '@viso-trust/angular-archwizard';
import { WebhooksComponent } from "./webhooks.component";
import { CreateWebhookComponent } from "./createWebhook.component";
import { WebhookService } from "@savvy/webhook";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    RouterModule.forChild(webhookRoutes),
    FloMaterialModule,
    WorkflowModule,
    NgApexchartsModule,
    ArchwizardModule
  ],
  declarations: [
    WebhooksComponent,
    CreateWebhookComponent
  ],
  providers: [
    WebhookService
  ],
  exports: [
    CreateWebhookComponent,
    WebhooksComponent
  ]
})

export class AppWebhookModule { }
