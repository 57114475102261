/* eslint-disable max-len */
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ContextIdDto } from '@savvy/app';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PhoneNumberHelperService } from 'src/app/flo/shared/services/phone-number-helper.service';
import { UserCurrencyService } from 'src/app/flo/shared/services/userCurrency.service';
import { CreateAppointmentV2SyncService } from '../create-appointment-v2-sync.service';

@Component({
  selector: 'app-confirm-appointment-v2',
  templateUrl: './confirm-appointment-v2.component.html',
  styleUrls: ['./confirm-appointment-v2.component.scss']
})
export class ConfirmAppointmentV2Component implements OnInit {
  @Input() contextIdDto: ContextIdDto;
  destroy$ = new Subject();

  selectedServiceLength = 0;

  totalAmount = 0;
  currencyCode = 'GBP';
  constructor(
    private sharedService: PhoneNumberHelperService,
    private userCurrencyService: UserCurrencyService,

    public createAppointmentV2SyncService: CreateAppointmentV2SyncService
  ) { }

  ngOnInit(): void {
    // this.selectedServiceLength = this.createAppointmentV2SyncService.selectedServices[this.createAppointmentV2SyncService.customersSelected[0].id].length;
  }

  getCurrencyCode() {
    this.userCurrencyService.getDefaultCurrency(this.contextIdDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.currencyCode = res.org.currencyCode ? res.org.currencyCode : this.userCurrencyService.defaultCurrency;
      });
  }

  get totalPackagesPlural(): boolean {
    return this.createAppointmentV2SyncService?.totalPackages > 1;
  }

  get totalServicesPlural(): boolean {
    return this.createAppointmentV2SyncService?.totalServices > 1;
  }
}
