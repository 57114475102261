<h1 mat-dialog-title>{{'ADD_A_SECTION' | translate}}</h1>
<form [formGroup]="workflowStateForm">

  <div mat-dialog-content>
    <div class="mt-1" *ngIf="allowEditState">
      <mat-form-field style="width: 100%" appearance="outline">
        <mat-label>{{'SECTION_NAME' | translate}}</mat-label>
        <input matInput placeholder="{{'ENTER_NAME' | translate}}" formControlName="stateName" autofocus required>
      </mat-form-field>
    </div>
    <!--div class="mt-1" *ngIf="allowEditState">
            <p class="bold" *ngIf="endState">Is End State</p>
            <p class="bold" *ngIf="!endState">Not End State</p>
        </div-->
    <!--mat-button-toggle-group name="buttonPosition" aria-label="Button Position"
                (change)="statePositionChange($event)" [value]="statePosition" [disabled]="!canEditStateForApp">
                <mat-button-toggle value="start">Start</mat-button-toggle>
                <mat-button-toggle value="intermediate">Intermediate</mat-button-toggle>
                <mat-button-toggle value="end">End</mat-button-toggle>
            </mat-button-toggle-group-->

    <!--div class="mt-1" *ngIf="statePosition === 'intermediate'">
            <mat-form-field style="width: 100%">
                <mat-label>Position To Add</mat-label>
                <input matInput type="text" appNumberOnly placeholder="Position To Add" class="form-control"
                    formControlName="statePosition" min="0">
            </mat-form-field>
        </div-->
    <div class="mt-1">
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>{{'COLOUR' | translate}}</mat-label>
        <input matInput placeholder="{{'COLOUR' | translate}}" formControlName="color" [(colorPicker)]="stateColor"
          [style.background]="stateColor" [value]="stateColor" (colorPickerChange)="onColorChange($event)">
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions [align]="'end'">
    <button mat-button (click)="cancel()">{{'CANCEL' | translate}}</button>
    <button *ngIf="!data.state && allowEditState" mat-raised-button color="primary" (click)="createState()"
      [disabled]="!workflowStateForm.valid" disabled="disabled" mat-button>{{'CREATE' | translate}}</button>
    <button *ngIf="data.state" mat-raised-button color="primary" (click)="updateState()"
      [disabled]="!workflowStateForm.valid" disabled="disabled" mat-button>{{'UPDATE' | translate}}</button>
  </div>
</form>
