import { ChildrenItems, Menu } from './local-menu.service';
import { Injectable } from '@angular/core';
import { ContextIdDto } from '@savvy/view-definition';
import { FlagDefinition } from '@savvy/flags';

import { MenuDefinition } from '@savvy/menu';
import { GLOBAL } from 'src/app/app.constants';
import MenuItemTypeEnum = MenuDefinition.MenuItemTypeEnum;

@Injectable()
export class MenuFactory {

  constructor() {
  }


  getMenuDefinition(menuDefinitionDto: MenuDefinition, contextIdDto: ContextIdDto): Menu {
    // console.log('getting menu def for label ' + menuDefinitionDto.label + ' icon [' + menuDefinitionDto.icon
    //   + '] type [' + menuDefinitionDto.menuItemType + ']');

    switch (menuDefinitionDto.menuItemType) {
      case MenuItemTypeEnum.Submenu: {
        // console.log('is submennu');
        const subMenuMain = {
          edId: '',        // This is for list entities
          itemType: menuDefinitionDto.menuItemType,
          state: 'admin',
          name: menuDefinitionDto.label,
          type: 'sub',
          icon: menuDefinitionDto.icon,
          svgIcon: menuDefinitionDto.svgXml ? menuDefinitionDto.svgIcon : false,
          svgXml: menuDefinitionDto.svgXml,
          children: []
        };
        for (const menuItem of menuDefinitionDto.menuDefinitions) {
          subMenuMain.children.push(this.getChildDefinition(menuItem, contextIdDto));
        }
        return subMenuMain;
      }
      case MenuItemTypeEnum.CheckinFormTemplates:
        const checkinParams: string[] = ['checkinForms', 'checkinFormDefs',
          contextIdDto.contextId, contextIdDto.contextIdType];
        return this.createMainMenu(checkinParams, menuDefinitionDto);
      // case MenuItemTypeEnum.CustomerCaptureFormTemplates:
      //   const customerSignupParams: string[] = ['customerSignup', 'customerSignupFormDefs',
      //     contextIdDto.contextId, contextIdDto.contextIdType];
      //   return this.createMainMenu(customerSignupParams, menuDefinitionDto);
      case MenuItemTypeEnum.Clockin:
        const staffCheckinParams: string[] = ['staffClockin', 'staffClockin',
          contextIdDto.contextId, contextIdDto.contextIdType];
        return this.createMainMenu(staffCheckinParams, menuDefinitionDto);
      // case MenuItemTypeEnum.Inbox:
      //    return this.createMainMenu(this.getParamsWithTwo('messaging', 'inbox', contextIdDto), menuDefinitionDto)
      case MenuItemTypeEnum.ReferAndEarn:
        return this.createMainMenu(this.getParamsWithTwo('referAndEarn', 'show', contextIdDto), menuDefinitionDto)
      case MenuItemTypeEnum.TermsAndConditions:
        return this.createMainMenu(this.getParamsWithTwo('termsAndConditions', 'load', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.OverdueGrooms:
        return this.createMainMenu(this.getParamsWithTwo('pets', 'overdueGrooms', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.RetailSales:
        return this.createMainMenu(this.getParams('retail', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.BookingConfig:
        return this.createMainMenu(this.getParamsWithTwo('simplebooking', 'bookingConfig', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.ListBookingConfig:
        return this.createMainMenu(this.getParamsWithTwo('simplebooking', 'bookingConfigs', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.Galleries:
        return this.createMainMenu(this.getParamsWithTwo('galleries', 'galleries', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.ListTaxes:
        return this.createMainMenu(this.getParamsWithTwo('taxes', 'taxes', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.ListLocations:
        return this.createMainMenu(this.getParamsWithTwo('locations', 'locations', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.ListServices:
        return this.createMainMenu(this.getParamsWithTwo('services', 'services', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.PetRuns:
        return this.createMainMenu(this.getParamsWithTwo('runs', 'runs', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.Packages:
        return this.createMainMenu(this.getParamsWithTwo('packages', 'packages', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.ListPets:
        return this.createMainMenu(this.getParamsWithTwo('pets', 'pets', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.ListPetStays:
        return this.createMainMenu(this.getParamsWithTwo('peyDayCare', 'list', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.ListProducts:
        return this.createMainMenu(this.getParamsWithTwo('products', 'products', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.Appointments:
        return this.createMainMenu(this.getParamsWithTwo('appointments', 'appointments', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.AppointmentsV1:
        return this.createMainMenu(this.getParamsWithTwo('appointments', 'appointmentsV1', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.BookingRequests:
        return this.createMainMenu(this.getParamsWithTwo('simplebooking', 'bookingRequests', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.PetStayDayMonthView:
        return this.createMainMenu(this.getParamsWithTwo('peyDayCare', 'petDayCareCalendar', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.Contracts:
        return this.createMainMenu(this.getParamsWithTwo('contract', 'listContracts', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.ContractTemplates:
        return this.createMainMenu(this.getParamsWithTwo('contract', 'listTemplates', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.ListInvoices:
        return this.createMainMenu(this.getParamsWithTwo('invoice', 'listInvoices', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.WebshopOrders:
        return this.createMainMenu(this.getParamsWithTwo('shop', 'orders', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.PaymentRequests:
        return this.createMainMenu(this.getParamsWithTwo('payment', 'paymentRequests', contextIdDto), menuDefinitionDto);
    //  case MenuItemTypeEnum.PaymentSchedules:
    //    return this.createMainMenu(this.getParamsWithTwo('payment', 'paymentSchedules', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.Webshop:
        return this.createMainMenu(this.getParamsWithTwo('shop', 'setup', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.Messaging:
        return this.createMainMenu(this.getParamsWithTwo('messaging', 'listMessages', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.DailyPets:
        return this.createMainMenu(this.getParamsWithTwo('pets', 'dailyPets', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.Activity:
        return this.createMainMenu(this.getParamsWithTwo('activity', 'activity', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.Reports:
        return this.createMainMenu(this.getParamsWithTwo('reports', 'listReports', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.CustomerViewContracts:
        return this.createMainMenu(this.getParamsWithTwo('cp', 'listCustomerContracts', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.CustomerViewQuotes:
        return this.createMainMenu(this.getParamsWithTwo('qp', 'listCustomerQuotes', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.Branding:
        return this.createMainMenu(this.getParamsWithTwo('branding', 'listBrands', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.CustomerPortal:
        return this.createMainMenu(this.getParamsWithTwo('customerPortal', 'customerPortal', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.Orders:
        return this.createMainMenu(this.getParamsWithTwo('order', 'orders', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.FlagDefinitions: return this.getFlagDefsMenu(contextIdDto, menuDefinitionDto);
      case MenuItemTypeEnum.Quotes:
        return this.createMainMenu(this.getParamsWithTwo('quote', 'quotes', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.Dashboard:
        return this.createMainMenu(['dashboard', 'viewDashboard',
          menuDefinitionDto.dashboardDefinitionId.id,
          contextIdDto.contextId, contextIdDto.contextIdType], menuDefinitionDto);
      case MenuItemTypeEnum.BusinessAnalytics:
        return this.createMainMenu(['businessAnalytics', 'viewDashboard',
          contextIdDto.contextId, contextIdDto.contextIdType], menuDefinitionDto);
      case MenuItemTypeEnum.Marketing:
        return this.createMainMenu(this.getParamsWithTwo('marketing', 'marketing', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.ListEntityInstances:
        return this.createMainMenuWithEdId(['entity', 'listEntityInsts',
          menuDefinitionDto.entityDefinitionId.id,
          contextIdDto.contextId, contextIdDto.contextIdType], menuDefinitionDto, menuDefinitionDto.entityDefinitionId.id);
      case MenuItemTypeEnum.ListEntityInstancesCachedTableView:
        return this.createMainMenuWithEdId(['entity', 'listCachedTableEntityInsts',
          menuDefinitionDto.cachedTableViewDefinitionId.id,
          contextIdDto.contextId, contextIdDto.contextIdType], menuDefinitionDto, menuDefinitionDto.entityDefinitionId.id);
      case MenuItemTypeEnum.DataList:
        return this.createMainMenu(['addEditDatalist', menuDefinitionDto.dataListId.id, contextIdDto.contextId, contextIdDto.contextIdType],
          menuDefinitionDto);
      case MenuItemTypeEnum.StaffRota:
        return this.createMainMenu(this.getParamsWithTwo('user', 'staffRota', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.SentMessages:
        return this.createMainMenu(['messaging', 'sentMessages', contextIdDto.contextId, contextIdDto.contextIdType], menuDefinitionDto);
      case MenuItemTypeEnum.CalendarView:
        return this.createMainMenu(['calendar', 'calendarView', contextIdDto.contextId, contextIdDto.contextIdType], menuDefinitionDto);
      // case MenuItemTypeEnum.Calendar:
      //   return this.createMainMenu(['calendar', 'viewCalendar', contextIdDto.contextId, contextIdDto.contextIdType], menuDefinitionDto);
      case MenuItemTypeEnum.Rules:
        return this.createMainMenu(this.getParamsWithTwo('rules', 'ruleSets', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.TaskBoard:
        return this.createMainMenu(this.getParamsWithTwo('task', 'taskBoard', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.Tasks:
        return this.createMainMenu(this.getParamsWithTwo('task', 'tasks', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.WorkflowOverview:
        return this.createMainMenu(this.getParamsWithTwo('workflow', 'overview', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.Customers:
        return this.createMainMenu(this.getParamsWithTwo('customer', 'customers', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.Payments:
        return this.createMainMenu(this.getParamsWithTwo('payment', 'payments', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.ChatBotSettings:
        return this.createMainMenu(this.getParamsWithTwo('chatflow', 'chatflowSettings', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.EmailSettings:
        return this.createMainMenu(this.getParamsWithTwo('emailSettings', 'emailSettings', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.SmsSettings:
        return this.createMainMenu(this.getParamsWithTwo('smsSettings', 'smsSettings', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.ContactForms:
        return this.createMainMenu(this.getParamsWithTwo('contactForms', 'submittedContactForms', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.CheckinForms:
        return this.createMainMenu(this.getParamsWithTwo('checkinForms', 'submittedCheckinForms', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.Users:
        return this.createMainMenu(this.getParamsWithTwo('user', 'users', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.Roles:
        return this.createMainMenu(this.getParamsWithTwo('roles', 'roles', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.UsersWorkingHours:
        return this.createMainMenu(this.getParamsWithTwo('user', 'usersWorkingHours', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.PaymentDetails:
        return this.createMainMenu(this.getParamsWithTwo('paymentSettings', 'paymentSettings', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.Plan:
        return this.createMainMenu(this.getParamsWithTwo('planDef', 'planDefs', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.CurrencyDetails:
        return this.createMainMenu(this.getParamsWithTwo('currency', 'currencySettings', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.Xero:
        return this.createMainMenu(this.getParamsWithTwo('xero', 'xeroSettings', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.Quickbooks:
        return this.createMenu(this.getParamsWithTwo('qb', 'qbSettings', contextIdDto), menuDefinitionDto);
   //   case MenuItemTypeEnum.Directory:
    //    return this.createMenu(this.getParamsWithTwo('directory', 'directorySettings', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.Website:
        return this.createMainMenu(this.getParamsWithTwo('website', 'websiteIn', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.ListWebsites:
        return this.createMainMenu(this.getParamsWithTwo('website', 'list', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.Reminders:
        return this.createMainMenu(this.getParamsWithTwo('reminders', 'reminderDefs', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.ReminderDef:
        const listResParams: string[] = ['reminders', 'reminderDef',
          menuDefinitionDto.reminderDefinitionId.id,
          contextIdDto.contextId, contextIdDto.contextIdType];
        return this.createMainMenu(listResParams, menuDefinitionDto);
      case MenuItemTypeEnum.RemindersDue:
        return this.createMainMenu(this.getParamsWithTwo('reminders', 'remindersDue', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.RemindersToday:
        return this.createMainMenu(this.getParamsWithTwo('reminders', 'remindersToday', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.VaccinationReminders:
        const listResParams1: string[] = ['reminders', 'reminderDef',
          menuDefinitionDto.reminderDefinitionId.id,
          contextIdDto.contextId, contextIdDto.contextIdType];
        return this.createMainMenu(listResParams1, menuDefinitionDto);
      case MenuItemTypeEnum.WaitingList:
        return this.createMainMenu(this.getParamsWithTwo('waitingList', 'showList', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.BonusCredits:
        return this.createMainMenu(this.getParamsWithTwo('earnBonusCredits', 'show', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.LandingPages:
        return this.createMainMenu(this.getParamsWithTwo('landingPage', 'landingPages', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.Newsletters:
        return this.createMainMenu(this.getParamsWithTwo('newsletter', 'newsletters', contextIdDto), menuDefinitionDto);

      case MenuItemTypeEnum.TaskOnlyWorkflow: {
        const workflowParams: string[] = ['workflow', 'editWorkflowPanel',
          menuDefinitionDto.workflowDefinitionId.id,
          contextIdDto.contextId, contextIdDto.contextIdType];

        return this.createMenu(workflowParams, menuDefinitionDto);
      }
      case MenuItemTypeEnum.MessageOnlyWorkflow: {

        if (!menuDefinitionDto.workflowDefinitionId) {
          console.log('Menu has no workflow id ', menuDefinitionDto);
          return null;
        }
        const messageOnlyworkflowParams: string[] = ['workflow', 'messageOnlyWorkflow',
          menuDefinitionDto.workflowDefinitionId.id,
          contextIdDto.contextId, contextIdDto.contextIdType];
        return this.createMainMenu(messageOnlyworkflowParams, menuDefinitionDto);
      }
      case MenuItemTypeEnum.PetWalkerDayMonthView:
        return this.createMainMenu(this.getParamsWithTwo('walkers', 'walkersCalendar', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.BookingWindow:
        const slotParams: string[] = ['bookingWindow', 'slotManagement',
          contextIdDto.contextId, contextIdDto.contextIdType];
        return this.createMainMenu(slotParams, menuDefinitionDto);
      case MenuItemTypeEnum.SlotTypes:
        const slotTypesParams: string[] = ['bookingWindow', 'slotTypes',
          contextIdDto.contextId, contextIdDto.contextIdType];
        return this.createMenu(slotTypesParams, menuDefinitionDto);
      case MenuItemTypeEnum.Subscriptions:
        const subscriptionParams: string[] = ['subscription', 'subscriptions',
          contextIdDto.contextId, contextIdDto.contextIdType];
        return this.createMainMenu(subscriptionParams, menuDefinitionDto);
      case MenuItemTypeEnum.QuestionnaireTemplates:
        const questionnaireParams: string[] = ['questionnaires', 'questionnaires',
          contextIdDto.contextId, contextIdDto.contextIdType];
        return this.createMainMenu(questionnaireParams, menuDefinitionDto);
      case MenuItemTypeEnum.ContactFormTemplates:
        const contactFormParams: string[] = ['contactForms', 'contactFormDefs',
          contextIdDto.contextId, contextIdDto.contextIdType];
        return this.createMainMenu(contactFormParams, menuDefinitionDto);
      // case MenuItemTypeEnum.EmailHtmlTemplates:
      //   const emailHtmlTemplatesParams: string[] = ['emailTemplates', 'emailHtmlTemplates',
      //     contextIdDto.contextId, contextIdDto.contextIdType];
      //   return this.createMainMenu(emailHtmlTemplatesParams, menuDefinitionDto);
      case MenuItemTypeEnum.EmailMessageTemplates:
        const emailMessageTemplatesParams: string[] = ['emailTemplates', 'emailMessageTemplates',
          contextIdDto.contextId, contextIdDto.contextIdType];
        return this.createMainMenu(emailMessageTemplatesParams, menuDefinitionDto);
      case MenuItemTypeEnum.CalendarSettings:
        return this.createMainMenu(this.getParamsWithTwo('calendarSettings', 'calendarSettings', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.ListWorkflows:
        return this.createMainMenu(this.getParamsWithTwo('workflow', 'workflows', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.ListTaskWorkflows:
        return this.createMainMenu(this.getParamsWithTwo('workflow', 'taskWorkflows', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.ListStudentCourseWorkflows:
        return this.createMenu(this.getParamsWithThree('workflow', 'workflowsByType', 'STUDENT_COURSE', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.ListLeadWorkflows:
        return this.createMenu(this.getParamsWithThree('workflow', 'workflowsByType', 'LEAD', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.ExportCustomers:
        return this.createMainMenu(this.getParamsWithTwo('customer', 'exportCustomers', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.ExportLeads:
        return this.createMainMenu(this.getParamsWithTwo('lead', 'exportLeads', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.ExportPets:
        return this.createMainMenu(this.getParamsWithTwo('pets', 'exportPets', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.ExportCustomersAndPets:
        return this.createMainMenu(this.getParamsWithTwo('pets', 'exportCustomersAndPets', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.ExportInvoices:
        return this.createMainMenu(this.getParamsWithTwo('invoice', 'exportInvoices', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.ExportPayments:
        return this.createMainMenu(this.getParamsWithTwo('payment', 'exportPayments', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.ExportAppointments:
        return this.createMainMenu(this.getParamsWithTwo('appointments', 'exportAppointments', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.CompanyDetails:
        return this.createMainMenu(this.getParamsWithTwo('org', 'editOrg', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.LookAndFeel:
        return this.createMainMenu(this.getParamsWithTwo('org', 'lookAndFeel', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.BusinessHours:
        return this.createMainMenu(this.getParamsWithTwo('org', 'businessHours', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.UserLocationMatrix:
        return this.createMainMenu(this.getParamsWithTwo('locationMatrix', 'locationMatrix', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.UserServiceMatrix:
        return this.createMainMenu(this.getParamsWithTwo('serviceMatrix', 'serviceMatrix', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.SlotManagement:
        return this.createMainMenu(this.getParamsWithTwo('simplebooking', 'slotManagement', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.ListAccommodation:
        return this.createMainMenu(this.getParamsWithTwo('accommodation', 'listAccommodation', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.Accommodationbookingmonthview:
        return this.createMainMenu(this.getParamsWithTwo('accommodation', 'listAccommodation', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.Courses:
        return this.createMainMenu(this.getParamsWithTwo('course', 'courses', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.TrainingEvents:
        return this.createMainMenu(this.getParamsWithTwo('course', 'trainingEvents', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.TrainingDayMonthView:
        return this.createMainMenu(this.getParamsWithTwo('course', 'trainingMonthView', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.TrainingDayPlanner:
        return this.createMainMenu(this.getParamsWithTwo('course', 'trainingPlanner', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.Students:
        return this.createMainMenu(this.getParamsWithTwo('student', 'students', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.StudentCourses:
        return this.createMainMenu(this.getParamsWithTwo('course', 'studentCourses', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.Resources:
        return this.createMainMenu(this.getParamsWithTwo('resource', 'resources', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.Leads:
        return this.createMainMenu(this.getParamsWithTwo('lead', 'leads', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.CourseRemainingDays:
        return this.createMainMenu(this.getParamsWithTwo('course', 'courseRemainingDays', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.StudentResourceReport:
        return this.createMainMenu(this.getParamsWithTwo('student', 'studentResourceReport', contextIdDto), menuDefinitionDto);
      case MenuItemTypeEnum.StudentFlags:
        return this.createMenu(this.getParamsWithThree('flags', 'flagDefsByType',
          FlagDefinition.FlagDefinitionTypeEnum.Student,
          contextIdDto),
          menuDefinitionDto);
      case MenuItemTypeEnum.PetFlags:
        return this.createMenu(this.getParamsWithThree('flags', 'flagDefsByType',
          FlagDefinition.FlagDefinitionTypeEnum.Pet,
          contextIdDto),
          menuDefinitionDto);
      case MenuItemTypeEnum.CustomerFlags:
        return this.createMenu(this.getParamsWithThree('flags', 'flagDefsByType',
          FlagDefinition.FlagDefinitionTypeEnum.Customer,
          contextIdDto),
          menuDefinitionDto);
      case MenuItemTypeEnum.LeadFlags:
        return this.createMenu(this.getParamsWithThree('flags', 'flagDefsByType',
          FlagDefinition.FlagDefinitionTypeEnum.Lead,
          contextIdDto),
          menuDefinitionDto);
      case MenuItemTypeEnum.DiscountSettings:
        return this.createMainMenu(this.getParamsWithTwo('discount', 'settings', contextIdDto), menuDefinitionDto);
      default: {
        console.log('unknown menu type ' + menuDefinitionDto.menuItemType);
        return null;
      }
    }
  }

  getChildDefinition(menuDefinition, contextIdDto: ContextIdDto): ChildrenItems {
    // console.log('getting child def for label ' + menuDefinitionDto.label);

    switch (menuDefinition.menuItemType) {

      case MenuItemTypeEnum.Submenu:

        const subMenuMain: Menu = {
          state: 'admin', name: menuDefinition.label,
          itemType: menuDefinition.menuItemType,
          edId: '',
          type: 'sub',
          icon: 'explore',
          svgIcon: false,
          children: [],
          svgXml: ''
        };

        for (const menuItem of menuDefinition.menuDefinitions) {

          subMenuMain.children.push(this.getChildDefinition(menuItem, contextIdDto));
        }
        return subMenuMain;


      case MenuItemTypeEnum.CheckinFormTemplates:
        const checkinParams: string[] = ['checkinForms', 'checkinFormDefs',
          contextIdDto.contextId, contextIdDto.contextIdType];
        return this.createMainMenu(checkinParams, menuDefinition);
      // case MenuItemTypeEnum.Inbox:
      //    return this.createMainMenu(this.getParamsWithTwo('messaging', 'inbox', contextIdDto), menuDefinition)
      case MenuItemTypeEnum.CustomerCaptureFormTemplates:
        const customerSignupParams: string[] = ['customerSignup', 'customerSignupFormDefs',
          contextIdDto.contextId, contextIdDto.contextIdType];
        return this.createMainMenu(customerSignupParams, menuDefinition);
      case MenuItemTypeEnum.PetWalkerDayMonthView:
        return this.createMainMenu(this.getParamsWithTwo('walkers', 'walkersCalendar', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.ReferAndEarn:
        return this.createMainMenu(this.getParamsWithTwo('referAndEarn', 'show', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.ListPets:
        return this.createMainMenu(this.getParamsWithTwo('pets', 'pets', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.RetailSales:
        return this.createMainMenu(this.getParams('retail', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.ListPetStays:
        return this.createMainMenu(this.getParamsWithTwo('peyDayCare', 'list', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.Galleries:
        return this.createMainMenu(this.getParamsWithTwo('galleries', 'galleries', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.ListServices:
        return this.createMainMenu(this.getParamsWithTwo('services', 'services', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.PetRuns:
        return this.createMainMenu(this.getParamsWithTwo('runs', 'runs', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.Packages:
        return this.createMainMenu(this.getParamsWithTwo('packages', 'packages', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.ListProducts:
        return this.createMainMenu(this.getParamsWithTwo('products', 'products', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.CheckinForms:
        return this.createMainMenu(this.getParamsWithTwo('checkinForms', 'submittedCheckinForms', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.TermsAndConditions:
        return this.createMainMenu(this.getParamsWithTwo('termsAndConditions', 'load', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.OverdueGrooms:
        return this.createMainMenu(this.getParamsWithTwo('pets', 'overdueGrooms', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.BookingConfig:
        return this.createMainMenu(this.getParamsWithTwo('simplebooking', 'bookingConfig', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.ListBookingConfig:
        return this.createMainMenu(this.getParamsWithTwo('simplebooking', 'bookingConfigs', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.SlotManagement:
        return this.createMainMenu(this.getParamsWithTwo('simplebooking', 'slotManagement', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.BookingCancellation:
        return this.createMainMenu(this.getParamsWithTwo('simplebooking', 'BookingCancellation', contextIdDto), menuDefinition);
      // case MenuItemTypeEnum.NoShow:
      //   return this.createMainMenu(this.getParamsWithTwo('simplebooking', 'NoShow', contextIdDto), menuDefinition);
      /*case MenuItemTypeEnum['MARKETING']:
        return this.createMainMenu(['marketing', 'marketing',
          contextIdDto.contextId, contextIdDto.contextIdType], menuDefinition);*/
      case MenuItemTypeEnum.ListTaxes:
        return this.createMainMenu(this.getParamsWithTwo('taxes', 'taxes', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.ListLocations:
        return this.createMainMenu(this.getParamsWithTwo('locations', 'locations', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.PaymentRequests:
        return this.createMainMenu(this.getParamsWithTwo('payment', 'paymentRequests', contextIdDto), menuDefinition);
   //   case MenuItemTypeEnum.PaymentSchedules:
     //   return this.createMainMenu(this.getParamsWithTwo('payment', 'paymentSchedules', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.ExportLeads:
        return this.createMainMenu(this.getParamsWithTwo('lead', 'exportLeads', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.ExportPets:
        return this.createMainMenu(this.getParamsWithTwo('pets', 'exportPets', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.ExportCustomersAndPets:
        return this.createMainMenu(this.getParamsWithTwo('pets', 'exportCustomersAndPets', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.Appointments:
        return this.createMainMenu(this.getParamsWithTwo('appointments', 'appointments', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.AppointmentsV1:
        return this.createMainMenu(this.getParamsWithTwo('appointments', 'appointmentsV1', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.Contracts:
        return this.createMainMenu(this.getParamsWithTwo('contract', 'listContracts', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.ContractTemplates:
        return this.createMainMenu(this.getParamsWithTwo('contract', 'listTemplates', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.ListInvoices:
        return this.createMainMenu(this.getParamsWithTwo('invoice', 'listInvoices', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.WebshopOrders:
        return this.createMainMenu(this.getParamsWithTwo('shop', 'orders', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.Webshop:
        return this.createMainMenu(this.getParamsWithTwo('shop', 'setup', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.ChatBotSettings:
        return this.createMainMenu(this.getParamsWithTwo('chatflow', 'chatflowSettings', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.Payments:
        return this.createMainMenu(this.getParamsWithTwo('payment', 'payments', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.Xero:
        return this.createMainMenu(this.getParamsWithTwo('xero', 'xeroSettings', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.CustomerPortal:
        return this.createMainMenu(this.getParamsWithTwo('customerPortal', 'customerPortal', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.Website:
        return this.createMainMenu(this.getParamsWithTwo('website', 'websiteIn', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.ListWebsites:
        return this.createMainMenu(this.getParamsWithTwo('website', 'list', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.Quickbooks:
        return this.createMainMenu(this.getParamsWithTwo('qb', 'qbSettings', contextIdDto), menuDefinition);
  //    case MenuItemTypeEnum.Directory:
    //    return this.createMenu(this.getParamsWithTwo('directory', 'directorySettings', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.BookingRequests:
        return this.createMainMenu(this.getParamsWithTwo('simplebooking', 'bookingRequests', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.ExportCustomers:
        return this.createMainMenu(this.getParamsWithTwo('customer', 'exportCustomers', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.ExportInvoices:
        return this.createMainMenu(this.getParamsWithTwo('invoice', 'exportInvoices', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.ExportPayments:
        return this.createMainMenu(this.getParamsWithTwo('payment', 'exportPayments', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.ExportAppointments:
        return this.createMainMenu(this.getParamsWithTwo('appointments', 'exportAppointments', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.Activity:
        return this.createMainMenu(this.getParamsWithTwo('activity', 'activity', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.Reports:
        return this.createMainMenu(this.getParamsWithTwo('reports', 'listReports', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.WorkflowOverview:
        return this.createMenu(this.getParamsWithTwo('workflow', 'overview', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.DailyPets:
        return this.createMainMenu(this.getParamsWithTwo('pets', 'dailyPets', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.Users:
        return this.createMenu(this.getParamsWithTwo('user', 'users', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.Roles:
        return this.createMainMenu(this.getParamsWithTwo('roles', 'roles', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.UsersWorkingHours:
        return this.createMainMenu(this.getParamsWithTwo('user', 'usersWorkingHours', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.StaffRota:
        return this.createMainMenu(this.getParamsWithTwo('user', 'staffRota', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.PaymentDetails:
        return this.createMenu(this.getParamsWithTwo('paymentSettings', 'paymentSettings', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.Plan:
        return this.createMenu(this.getParamsWithTwo('planDef', 'planDefs', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.ContactForms: {
        return this.createMenu(this.getParamsWithTwo('contactForms', 'submittedContactForms', contextIdDto), menuDefinition);
      }
      case MenuItemTypeEnum.UserLocationMatrix: {
        return this.createMenu(this.getParamsWithTwo('locationMatrix', 'locationMatrix', contextIdDto), menuDefinition);
      }
      case MenuItemTypeEnum.BonusCredits:
        return this.createMenu(this.getParamsWithTwo('earnBonusCredits', 'show', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.WaitingList:
        return this.createMainMenu(this.getParamsWithTwo('waitingList', 'showList', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.UserServiceMatrix: {
        return this.createMenu(this.getParamsWithTwo('serviceMatrix', 'serviceMatrix', contextIdDto), menuDefinition);
      }
      case MenuItemTypeEnum.Clockin: {
        const staffClockinParams: string[] = ['staffClockin', 'staffClockin',
          contextIdDto.contextId, contextIdDto.contextIdType];
        return this.createMainMenu(staffClockinParams, menuDefinition);
      }
      case MenuItemTypeEnum.FlagDefinitions: return this.getFlagDefsMenu(contextIdDto, menuDefinition);
      case MenuItemTypeEnum.SentMessages:
        return this.createMainMenu(this.getParamsWithTwo('messaging', 'sentMessages', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.MessageOnlyWorkflow:
        // return this.createMainMenu(this.getParamsWithTwo('workflow', 'messageOnlyWorkflow', contextIdDto), menuDefinition);
        const messageOnlyworkflowParams: string[] = ['workflow', 'messageOnlyWorkflow',
          menuDefinition.workflowDefinitionId.id,
          contextIdDto.contextId, contextIdDto.contextIdType];
        return this.createMainMenu(messageOnlyworkflowParams, menuDefinition);
      case MenuItemTypeEnum.CustomerViewContracts:
        return this.createMainMenu(this.getParamsWithTwo('cp', 'listCustomerContracts', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.CustomerViewQuotes:
        return this.createMainMenu(this.getParamsWithTwo('qp', 'listCustomerQuotes', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.ReminderDef:
        const listResParams: string[] = ['reminders', 'reminderDef',
          menuDefinition.reminderDefinitionId.id,
          contextIdDto.contextId, contextIdDto.contextIdType];
        return this.createMainMenu(listResParams, menuDefinition);

      case MenuItemTypeEnum.TaskBoard:
        return this.createMainMenu(this.getParamsWithTwo('task', 'taskBoard', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.Tasks:
        return this.createMenu(this.getParamsWithTwo('task', 'tasks', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.CurrencyDetails:
        return this.createMenu(this.getParamsWithTwo('currency', 'currencySettings', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.Quotes:
        return this.createMenu(this.getParamsWithTwo('quote', 'quotes', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.QuestionnaireTemplates:
        return this.createMenu(this.getParamsWithTwo('questionnaires', 'questionnaires', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.Reminders:
        return this.createMenu(this.getParamsWithTwo('reminders', 'reminderDefs', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.VaccinationReminders:
        const listResParams1: string[] = ['reminders', 'reminderDef',
          menuDefinition.reminderDefinitionId.id,
          contextIdDto.contextId, contextIdDto.contextIdType];
        return this.createMainMenu(listResParams1, menuDefinition);
      case MenuItemTypeEnum.RemindersDue:
        return this.createMenu(this.getParamsWithTwo('reminders', 'reminderDue', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.Messaging: {
        return this.createMenu(this.getParamsWithTwo('messaging', 'listMessages', contextIdDto), menuDefinition);
      }
      case MenuItemTypeEnum.ContactFormTemplates:
        const contactFormParams: string[] = ['contactForms', 'contactFormDefs',
          contextIdDto.contextId, contextIdDto.contextIdType];
        return this.createMainMenu(contactFormParams, menuDefinition);

      case MenuItemTypeEnum.PetStayDayMonthView:
        return this.createMainMenu(this.getParamsWithTwo('peyDayCare', 'petDayCareCalendar', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.ListEntityInstances: {

        const listEisParams: string[] = ['entity', 'listEntityInsts',
          menuDefinition.entityDefinitionId.id,
          contextIdDto.contextId, contextIdDto.contextIdType];

        return this.createMenu(listEisParams, menuDefinition);
      }
      case MenuItemTypeEnum.ListEntityInstancesCachedTableView:
        const listCachedResParams: string[] = ['entity', 'listCachedTableEntityInsts',
          menuDefinition.cachedTableViewDefinitionId.id,
          contextIdDto.contextId, contextIdDto.contextIdType];

        return this.createMenu(listCachedResParams, menuDefinition);
      case MenuItemTypeEnum.TaskOnlyWorkflow: {
        const workflowParams: string[] = ['workflow', 'editWorkflowPanel',
          menuDefinition.workflowDefinitionId.id,
          contextIdDto.contextId, contextIdDto.contextIdType];

        return this.createMenu(workflowParams, menuDefinition);
      }
      case MenuItemTypeEnum.DataList: {
        const dataListParams: string[] = ['addEditDatalist',
          menuDefinition.dataListId.id,
          contextIdDto.contextId, contextIdDto.contextIdType];
        return this.createMenu(dataListParams, menuDefinition);
      }
      case MenuItemTypeEnum.BookingWindow:
        const slotParams: string[] = ['bookingWindow', 'slotManagement',
          contextIdDto.contextId, contextIdDto.contextIdType];
        return this.createMenu(slotParams, menuDefinition);
      case MenuItemTypeEnum.SlotTypes:
        const slotTypesParams: string[] = ['bookingWindow', 'slotTypes',
          contextIdDto.contextId, contextIdDto.contextIdType];
        return this.createMenu(slotTypesParams, menuDefinition);
      case MenuItemTypeEnum.Subscriptions:
        const subscriptionParams: string[] = ['subscription', 'subscriptions',
          contextIdDto.contextId, contextIdDto.contextIdType];
        return this.createMenu(subscriptionParams, menuDefinition);
      // case MenuItemTypeEnum.EmailHtmlTemplates:
      //   const emailHtmlTemplatesParams: string[] = ['emailTemplates', 'emailHtmlTemplates',
      //     contextIdDto.contextId, contextIdDto.contextIdType];
      //   return this.createMenu(emailHtmlTemplatesParams, menuDefinition);
      case MenuItemTypeEnum.EmailMessageTemplates:
        const emailMessageTemplatesParams: string[] = ['emailTemplates', 'emailMessageTemplates',
          contextIdDto.contextId, contextIdDto.contextIdType];
        return this.createMenu(emailMessageTemplatesParams, menuDefinition);
      case MenuItemTypeEnum.Rules:
        return this.createMenu(this.getParamsWithTwo('rules', 'ruleSets', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.ListWorkflows:
        return this.createMenu(this.getParamsWithTwo('workflow', 'workflows', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.ListTaskWorkflows:
        return this.createMainMenu(this.getParamsWithTwo('workflow', 'taskWorkflows', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.ListStudentCourseWorkflows:
        return this.createMenu(this.getParamsWithThree('workflow', 'workflowsByType', 'STUDENT_COURSE', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.ListLeadWorkflows:
        return this.createMenu(this.getParamsWithThree('workflow', 'workflowsByType', 'LEAD', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.EmailSettings:
        return this.createMenu(this.getParamsWithTwo('emailSettings', 'emailSettings', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.SmsSettings:
        return this.createMenu(this.getParamsWithTwo('smsSettings', 'smsSettings', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.CalendarSettings:
        return this.createMenu(this.getParamsWithTwo('calendarSettings', 'calendarSettings', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.CompanyDetails:
        return this.createMenu(this.getParamsWithTwo('org', 'editOrg', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.LookAndFeel:
        return this.createMainMenu(this.getParamsWithTwo('org', 'lookAndFeel', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.BusinessHours:
        return this.createMainMenu(this.getParamsWithTwo('org', 'businessHours', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.ListAccommodation:
        return this.createMainMenu(this.getParamsWithTwo('accommodation', 'listAccommodation', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.Accommodationbookingmonthview:
        return this.createMainMenu(this.getParamsWithTwo('accommodation', 'listAccommodation', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.Courses:
        return this.createMainMenu(this.getParamsWithTwo('course', 'courses', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.TrainingEvents:
        return this.createMainMenu(this.getParamsWithTwo('course', 'trainingEvents', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.TrainingDayMonthView:
        return this.createMainMenu(this.getParamsWithTwo('course', 'trainingMonthView', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.TrainingDayPlanner:
        return this.createMainMenu(this.getParamsWithTwo('course', 'trainingPlanner', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.Students:
        return this.createMainMenu(this.getParamsWithTwo('student', 'students', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.StudentCourses:
        return this.createMainMenu(this.getParamsWithTwo('course', 'studentCourses', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.Resources:
        return this.createMainMenu(this.getParamsWithTwo('resource', 'resources', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.Leads:
        return this.createMainMenu(this.getParamsWithTwo('lead', 'leads', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.CourseRemainingDays:
        return this.createMainMenu(this.getParamsWithTwo('course', 'courseRemainingDays', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.StudentResourceReport:
        return this.createMainMenu(this.getParamsWithTwo('student', 'studentResourceReport', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.LandingPages:
        return this.createMenu(this.getParamsWithTwo('landingPage', 'landingPages', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.Newsletters:
        return this.createMenu(this.getParamsWithTwo('newsletter', 'newsletters', contextIdDto), menuDefinition);
      case MenuItemTypeEnum.StudentFlags:
        return this.createMenu(this.getParamsWithThree('flags', 'flagDefsByType',
          FlagDefinition.FlagDefinitionTypeEnum.Student,
          contextIdDto),
          menuDefinition);
      case MenuItemTypeEnum.LeadFlags:
        return this.createMenu(this.getParamsWithThree('flags', 'flagDefsByType',
          FlagDefinition.FlagDefinitionTypeEnum.Lead,
          contextIdDto),
          menuDefinition);
      case MenuItemTypeEnum.PetFlags:
        return this.createMenu(this.getParamsWithThree('flags', 'flagDefsByType',
          FlagDefinition.FlagDefinitionTypeEnum.Pet,
          contextIdDto),
          menuDefinition);
      case MenuItemTypeEnum.CustomerFlags:
        return this.createMenu(this.getParamsWithThree('flags', 'flagDefsByType',
          FlagDefinition.FlagDefinitionTypeEnum.Customer,
          contextIdDto),
          menuDefinition);
      case MenuItemTypeEnum.DiscountSettings:
        return this.createMainMenu(this.getParamsWithTwo('discount', 'settings', contextIdDto), menuDefinition);
      default: {
        console.log('unknown child menu type ' + menuDefinition.menuItemType);
        return null;
      }
    }
  }


  getFlagDefsMenu(contextIdDto: ContextIdDto, menuDefinitionDto): Menu {
    /**
     *     path: 'flagDefsByType/:flagDefinitionType/:contextId/:contextIdType',
     component: EntityFlagDefsComponent
     },
     {
            path: 'entityFlagDefs/:entityDefinitionId/:contextId/:contextIdType',
            component: EntityFlagDefsComponent
     */

    console.log('menuDefinitionDto.flagDefinitionType ' + menuDefinitionDto.flagDefinitionType);
    // console.log('menuDefinitionDto.entityDefinitionId.id ' + menuDefinitionDto.entityDefinitionId.id);
    if (menuDefinitionDto.flagDefinitionType === FlagDefinition.FlagDefinitionTypeEnum.Entity) {
      return this.createMenu(this.getParamsWithThree('flags', 'entityFlagDefs',
        menuDefinitionDto.entityDefinitionId.id,
        contextIdDto),
        menuDefinitionDto);
    } else if ((menuDefinitionDto.flagDefinitionType === FlagDefinition.FlagDefinitionTypeEnum.Customer)
      || (menuDefinitionDto.flagDefinitionType === FlagDefinition.FlagDefinitionTypeEnum.Student)
      || (menuDefinitionDto.flagDefinitionType === FlagDefinition.FlagDefinitionTypeEnum.Pet)
      || (menuDefinitionDto.flagDefinitionType === FlagDefinition.FlagDefinitionTypeEnum.Order)
      || (menuDefinitionDto.flagDefinitionType === FlagDefinition.FlagDefinitionTypeEnum.Lead)) {
      return this.createMenu(this.getParamsWithThree('flags', 'flagDefsByType',
        menuDefinitionDto.flagDefinitionType,
        contextIdDto),
        menuDefinitionDto);
    } else {

      return this.createMenu(this.getParamsWithTwo('flags', 'flagDefs', contextIdDto),
        menuDefinitionDto);
    }
  }

  createMainMenu(params: string[], menuDefinitionDto) {
    return {
      params,
      name: menuDefinitionDto.label,
      edId: '',
      badge: [
        {
          type: menuDefinitionDto.badgeType, value: menuDefinitionDto.badgeValue
        }
      ],
      itemType: menuDefinitionDto.menuItemType,
      type: this.getLinkType(params),
      icon: menuDefinitionDto?.icon ? menuDefinitionDto?.icon : GLOBAL.defaultIcon,
      svgIcon: menuDefinitionDto.svgXml ? menuDefinitionDto.svgIcon : false,
      svgXml: menuDefinitionDto.svgXml,
    };
  }

  createMainMenuWithEdId(params: string[], menuDefinitionDto, edId: string) {
    return {
      params,
      name: menuDefinitionDto.label,
      edId,
      badge: [
        {
          type: menuDefinitionDto.badgeType, value: menuDefinitionDto.badgeValue
        }
      ],
      itemType: menuDefinitionDto.menuItemType,
      type: this.getLinkType(params),
      icon: menuDefinitionDto?.icon ? menuDefinitionDto?.icon : GLOBAL.defaultIcon,
      svgIcon: menuDefinitionDto.svgXml ? menuDefinitionDto.svgIcon : false,
      svgXml: menuDefinitionDto.svgXml,
    };
  }

  createMenu(params: string[], menuDefinitionDto) {
    return {
      params,
      name: menuDefinitionDto.label,
      itemType: menuDefinitionDto.menuItemType,
      edId: '',
      type: this.getLinkType(params),
      icon: menuDefinitionDto?.icon ? menuDefinitionDto?.icon : GLOBAL.defaultIcon,
      svgIcon: menuDefinitionDto.svgXml ? menuDefinitionDto.svgIcon : false,
      svgXml: menuDefinitionDto.svgXml,
    };
  }

  getParams(str: string, contextIdDto: ContextIdDto): string[] {
    return [str, contextIdDto.contextId, contextIdDto.contextIdType];
  }

  getParamsWithTwo(str1: string, str2: string, contextIdDto: ContextIdDto): string[] {
    return [str1, str2, contextIdDto.contextId, contextIdDto.contextIdType];
  }
  getParamsWithThree(str1: string, str2: string, str3: string, contextIdDto: ContextIdDto): string[] {
    return [str1, str2, str3, contextIdDto.contextId, contextIdDto.contextIdType];
  }
  getLinkType(params: string[]): string {
    if (params.length === 2) {
      return 'linkWithTwoParams';
    } else if (params.length === 3) {
      return 'linkWithThreeParams';
    } else if (params.length === 4) {
      return 'linkWithFourParams';
    } else if (params.length === 5) {
      return 'linkWithFiveParams';
    } else {
      console.log('HELP params is ' + params);
      return 'unknown';
    }
  }
}
