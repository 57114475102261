<div fxLayout="row" fxLayoutWrap="nowrap" fxLayoutAlign="center">
  <div fxFlexAlign="center" style="margin-right: 10px">
    {{data.text}}
  </div>
  <div fxFlexAlign="center" style="margin-left: auto">
    <!--button mat-raised-button color="accent" (click)="onUpdatePlan()">{{'Update Plan' | translate}}</button-->&nbsp;
    <!--button mat-raised-button color="accent" (click)="dismiss()">{{'Dismiss' | translate}}</button-->
  </div>
</div>


