<div fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign="start" fxLayoutAlign.gt-xs=" center" fxLayoutGap="0px" fxLayoutGap.gt-xs="16px" class="" *ngIf="customerFlagIds?.length || workflowStatsDto?.workflowStatDtoList?.length || showCustomerNameAlways">
  <div fxFlex="100" fxFlex.gt-xs="nogrow" *ngIf="customerName">
    <h5 class="mdc-mb-0">
      <span class="mr-5px" (click)="viewCustomer()">{{customerName}}</span>
      <button mat-icon-button (click)="editCustomer()"><mat-icon class="vm">edit</mat-icon></button>
    </h5>
    <mat-card-subtitle>Customer</mat-card-subtitle>
  </div>
  <div fxFlex="100" fxFlex.gt-xs [ngClass]="{'mr-1': customerName}">
    <div fxFlex fxLayout="row" fxLayoutGap="5px">
      <mat-chip-listbox class="example-chip" cdkDropListOrientation="horizontal">
        <app-customer-flags-panel [contextIdDto]="contextIdDto" [disableCreate]="true" [disableDelete]="true" [flagIds]="customerFlagIds">

        </app-customer-flags-panel>
        <!--ng-container *ngIf="customerFlagIds && customerFlagIds.length">
          <mat-chip-option class="example-box" *ngFor="let customerFlag of customerFlagIds" [color]="customerFlag?.flagDefinition?.colour" selected>
            {{customerFlag.flagDefinition.name}}
          </mat-chip-option>
        </ng-container-->

        <ng-container *ngIf="workflowStatsDto && workflowStatsDto.workflowStatDtoList && workflowStatsDto.workflowStatDtoList.length">
          <mat-chip-option class="example-box" *ngFor="let workflowStateDto of workflowStatsDto.workflowStatDtoList" class="mr-1">
            <span [matBadge]="workflowStateDto.numStates" [matBadgeOverlap]="false" [matBadgeColor]="workflowStateDto.colour">
              {{ workflowStateDto.state }}
            </span>
          </mat-chip-option>
        </ng-container>
      </mat-chip-listbox>
    </div>
  </div>
</div>