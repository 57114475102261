<mat-card appearance="outlined" *ngIf="listQuestionnairesListDto && listQuestionnairesListDto.questionnaireList.length > 0" class="full-height">
    <mat-card-title>
        <div fxLayout="row">
            <div fxFlex="80">
                <p class="mt-xs mb-xs">{{'Questionnaires' | translate}}</p>
            </div>
            <div fxFlex="20">
                <button mat-mini-fab color="accent" (click)="create()">
                    <mat-icon class="mat-24">add</mat-icon>
                </button>
            </div>
            <!--
            <div fxFlex="20" *ngIf="!listOrderListDto.allowCreate">
                You can create an Questionnaire from a Shoot
            </div>
            -->
        </div>
    </mat-card-title>

    <mat-card-content>
        <mat-list>
            <mat-list-item *ngFor="let questionnaire of listQuestionnairesListDto.questionnaireList">
                <p matLine (click)="rowSelected(questionnaire.id)">
                    {{questionnaire.dateCreated | localizedDate: 'mediumDate'}}</p>
                <mat-divider *ngIf="listQuestionnairesListDto.questionnaireList.length > 1"></mat-divider>
            </mat-list-item>
        </mat-list>
    </mat-card-content>
</mat-card>

<mat-card appearance="outlined" *ngIf="listQuestionnairesListDto && (!listQuestionnairesListDto.questionnaireList || listQuestionnairesListDto.questionnaireList.length === 0)" class="full-height">

    <mat-card-content>
        <div class="flexbox-parent">
            <div fxLayout="row" fxLayoutAlign="center center">
                <div style="text-align:center;">
                    <app-zero-image name="order"></app-zero-image><br>
                    <h5 class="ma-0">{{'No Questionnaires have been sent' | translate}}</h5><br>
                    <!-- *ngIf="listEntityListDto.canCreate" -->
                    <button mat-raised-button color="primary" (click)="create()">{{'Send Questionnaire' | translate}}</button>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>