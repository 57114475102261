import { Component, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { Feature } from '@savvy/add-ons';
import { Appointment, AppointmentService, UpdateAppointment } from '@savvy/appointment';
import { Invoice } from '@savvy/invoice';
import { ContextIdDto } from '@savvy/student';
import { WorkflowState } from '@savvy/workflow-instance';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/flo/confirmation-dialog/confirmation-dialog.component';
import { DateUtils } from 'src/app/flo/dates/DateUtils';
import { FeaturesHelperService } from 'src/app/flo/shared/services/features-helper.service';
import { AppointmentConfigHelperService } from '../../../shared/services/appointment-config-helper.service';
import { FloSnackbarComponent } from '../../../snackbar/floSnackbar.component';
import { ViewAppointmentV2Service } from '../../view-appointment-v2.service';

@Component({
  selector: 'app-view-customer-appointment',
  templateUrl: './view-customer-appointment.component.html',
  styleUrls: ['./view-customer-appointment.component.scss']
})
export class ViewCustomerAppointmentComponent implements OnInit, OnDestroy {

  @ViewChild(MatTabGroup) matTabGroup: MatTabGroup;

  contextIdDto: ContextIdDto;
  appointmentId: string;
  appointment: Appointment;
  quickAppointment = true;
  startTime;
  endTime;
  currentTab = 0;
  paymentCategory = 'amount';
  messageCategory = 'messages';

  showConfirmationDialog = false;
  updatedState: WorkflowState;
  appointmentTitle = 'Appointment';

  subs: Subscription[] = [];
  featureType = Feature.TypeEnum;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() public dialogRef: MatDialogRef<ViewCustomerAppointmentComponent>,
    private appointmentService: AppointmentService,
    private dialog: MatDialog,
    private notify: FloSnackbarComponent,
    private viewAppointmentV2Service: ViewAppointmentV2Service,
    private appointmentConfigHelperService: AppointmentConfigHelperService,
    private dateUtils: DateUtils,
    public deviceService: DeviceDetectorService,
    public featuresHelperService: FeaturesHelperService

  ) {

    this.contextIdDto = data.contextIdDto;
    this.appointmentId = data.appointmentId;
  }

  ngOnInit(): void {
    console.log('view-cust-appt loading appointment with id', this.appointmentId);
    this.loadAppointment();

    if (!this.appointmentConfigHelperService.appointmentConfig?.appointmentViewQuickMode || !this.quickAppointment) {
      this.fullScreen();
    }

    this.subs.push(this.viewAppointmentV2Service.updateState$.subscribe(res => {
      if (res) {
        this.showConfirmationDialog = true;
        this.updatedState = res;
      }
    }));
  }

  ngOnDestroy(): void {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }



  loadAppointment() {
    this.appointmentService.loadAppointment(this.appointmentId, this.contextIdDto.contextId, this.contextIdDto.contextIdType)
      .subscribe(response => {
        this.appointment = response;
        this.startTime = this.dateUtils.dateFromTime(this.appointment.startTime);
        this.endTime = this.dateUtils.dateFromTime(this.appointment.endTime);
        switch (this.appointment.appointmentType) {
          case Appointment.AppointmentTypeEnum.CommercialShoot:
            this.appointmentTitle = 'Commercial Shoot';
            break;
          case Appointment.AppointmentTypeEnum.Wedding:
            this.appointmentTitle = 'Wedding';
            break;
          case Appointment.AppointmentTypeEnum.Shoot:
            this.appointmentTitle = 'Shoot';
            break;
        }
      });
  }

  cancel() {
    this.dialogRef.close();
  }

  fullScreen() {
    this.quickAppointment = false;
    this.dialogRef.updateSize('800px', 'auto');
    if (this.matTabGroup) {
      this.matTabGroup.realignInkBar();
    }
  }

  exitFullScreen() {
    this.quickAppointment = true;
    this.dialogRef.updateSize(null, 'auto');
  }

  tabChange(event) {
    this.currentTab = event;
  }

  edit() {
    this.dialogRef.close({ appointment: this.appointment, rebook: false });
  }

  rebookEvent() {
    this.dialogRef.close({ appointment: this.appointment, rebook: true });
  }

  cancelAppointment() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Cancel Appointment',
        message: 'Are you sure you want to cancel this appointment?',
        // isChange: true
      },
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.appointment.cancelled = true;
        const updateAppointment: UpdateAppointment = {
          contextIdDto: this.contextIdDto,
          appointment: this.appointment
        };
        this.appointmentService.updateAppointment(updateAppointment)
          .subscribe(response => {
            if (response) {
              this.appointment = response;
              this.notify.message = 'Appointment cancelled';
              this.notify.open();
              this.dialogRef.close();
            }
          });
      }
    });

  }

  deleteAppointment() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete Appointment',
        message: 'Are you sure you want to delete this appointment?',
        // isChange: true
      },
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.appointmentService.deleteAppointment(this.appointmentId, this.contextIdDto.contextId, this.contextIdDto.contextIdType)
          .subscribe(response => {
            if (response) {
              this.appointment = response;
              this.notify.message = 'Appointment deleted';
              this.notify.open();
              this.dialogRef.close();
            }
          });
      }
    });
  }

  confirmStateChange() {
    this.showConfirmationDialog = false;
    this.viewAppointmentV2Service.confirmUpdateState({ state: this.updatedState });
  }

  closeConfirmDialog() {
    this.showConfirmationDialog = false;
    this.viewAppointmentV2Service.confirmUpdateState();
  }

  viewHistory($event) {
    this.matTabGroup.focusTab(1);
    this.matTabGroup.selectedIndexChange.emit(1);
  }

  openEditTemplate() {
    // TODO
  }

  invoiceUpdated(newInvoice: Invoice) {
    this.loadAppointment();
  }

}
