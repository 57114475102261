import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { App, ContextIdDto } from '@savvy/app';
import { AddStateToWorkflow, LoadWorkflowDefinitionForEditResponse, LoadWorkflowStateResponse, UpdateState, WorkflowdefService, WorkflowState } from '@savvy/workflow-definition';
import { Observable } from 'rxjs';
import { APP_TYPE_ENUM, ContextService } from '../../context.service';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';

@Component({
  selector: 'app-add-edit-workflow-state-dialog',
  templateUrl: './add-edit-workflow-state-dialog.component.html',
  styleUrls: ['./add-edit-workflow-state-dialog.component.scss']
})
export class AddEditWorkflowStateDialogComponent implements OnInit {

  contextIdDto: ContextIdDto;
  workflowDefinition: LoadWorkflowDefinitionForEditResponse;

  allowEditState = false;
  menuItemTypeControl = new UntypedFormControl();
  menuItemTypeOptions: Observable<string[]>;
  statePosition = 'end';
  stateColor: string;
  appType: string;
  initialState: boolean;
  endState: boolean;

  workflowStateForm = new UntypedFormGroup({
    stateName: new UntypedFormControl('', [Validators.required]),
    statePosition: new UntypedFormControl('', [Validators.min(1)]),
    color: new UntypedFormControl('')
  });


  constructor(@Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<AddEditWorkflowStateDialogComponent>,
    private api: WorkflowdefService,
    private contextService: ContextService,
    public notify: FloSnackbarComponent) { }

  get canEditStateForApp(): boolean {
    if (this.contextService.builderApp) {
      return true;
    }
    if (this.appType === APP_TYPE_ENUM.PET_GROOMING_SALON) {
      return true;
    }
    return false;
  }

  ngOnInit(): void {
    this.contextIdDto = this.data.contextIdDto;
    this.appType = this.contextService.getAppType();
    this.allowEditState = this.data.allowEditState;
    if (this.data.state) {
      this.loadWorkflow();
    }
    this.setFormDisable(!this.canEditStateForApp);
  }

  setFormDisable(disable: boolean) {
    if (disable) {
      this.workflowStateForm.get('stateName').disable();
      this.workflowStateForm.get('statePosition').disable();
    } else {
      this.workflowStateForm.get('stateName').enable();
      this.workflowStateForm.get('statePosition').enable();
    }
  }

  cancel() {
    this.dialogRef.close({ response: false });
  }

  statePositionChange(event: MatButtonToggleChange) {
    this.statePosition = event.value;
  }

  onColorChange(event) {
    this.workflowStateForm.get('color').setValue(event);
  }

  loadWorkflow() {
    this.api.getWorkflowDefForEdit(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      this.data.workflowDefinitionId).subscribe(response => {
        this.workflowDefinition = response;
        const workflowStateResponse: LoadWorkflowStateResponse = this.workflowDefinition.loadWorkflowStateResponseList.find(v => v.workflowState.id === this.data.state.workflowState.id);
        const workflowStateIndex: number = this.workflowDefinition.loadWorkflowStateResponseList.findIndex(v => v.workflowState.id === this.data.state.workflowState.id);
        if (workflowStateResponse) {
          this.workflowStateForm.get('stateName').setValue(workflowStateResponse.workflowState.state);
          this.workflowStateForm.get('color').setValue(workflowStateResponse.workflowState.colour);
          this.workflowStateForm.get('statePosition').setValue(workflowStateIndex + 1);
          this.stateColor = workflowStateResponse.workflowState.colour;
          this.initialState = workflowStateResponse.workflowState.initialState;
          this.endState = workflowStateResponse.workflowState.endState;
          this.statePosition = 'intermediate';
        }
      });
  }

  createState() {
    const addStateToWorkflowRequest: AddStateToWorkflow = {
      contextIdDto: this.contextIdDto,
      workflowDefinitionId: this.data.workflowDefinitionId,
      newState: this.workflowStateForm.value.stateName,
      color: this.workflowStateForm.value.color,
      startState: this.statePosition === 'start',
      endState: this.statePosition === 'end',
      positionToAdd: this.statePosition === 'intermediate' ? this.workflowStateForm.value.statePosition : null
    };
    this.api.addStateToWorkflow(addStateToWorkflowRequest).subscribe(response => {
      if (response && response.newWorkflowState && response.newWorkflowState.id) {
        this.notify.message = 'State successfully added to workflow.';
        this.notify.open();
      }
    }, () => {
      this.notify.message = 'Failed to add state to workflow.';
      this.notify.open();
    }, () => {
      this.dialogRef.close({ response: true });
    });
  }

  updateState() {
    this.setFormDisable(false);
    const updateStateRequest: UpdateState = {
      contextIdDto: this.contextIdDto,
      workflowDefinitionId: this.data.workflowDefinitionId,
      stateId: this.data.state.workflowState.id,
      stateName: this.workflowStateForm.value.stateName,
      color: this.workflowStateForm.value.color,
      initialState: this.statePosition === 'start',
      endState: this.statePosition === 'end',
      position: this.statePosition === 'intermediate' ? this.workflowStateForm.value.statePosition : null
    };
    this.setFormDisable(!this.canEditStateForApp);
    this.api.updateState(updateStateRequest).subscribe(response => {
      if (response) {
        this.notify.message = 'State successfully updated.';
        this.notify.open();
      }
    }, () => {
      this.notify.message = 'Failed to update state.';
      this.notify.open();
    }, () => {
      this.dialogRef.close({ response: true });
    });
  }



}
