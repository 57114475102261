<mat-toolbar>
    <button mat-button>{{'New album' | translate}}</button>
    <span fxFlex></span>
    <button mat-icon-button><mat-icon>settings</mat-icon></button>
    <button mat-icon-button><mat-icon>chevron_left</mat-icon></button>
    <button mat-icon-button><mat-icon>chevron_right</mat-icon></button>
</mat-toolbar>

<mat-grid-list cols="3">
    <mat-grid-tile *ngFor="let viewImageDto of listEntityImagesDto.imagePanelDto.viewImageDtoList; index as i" rowHeight="2:1">
        <img src="{{ getImageUrl(viewImageDto.url) }}" alt="Card image cap"/>
        <mat-grid-tile-footer>
            <h3 matLine> {{ index }}</h3>
            <span matLine>{{'Gallery image:' | translate}} {{ index }}</span>
            <mat-icon>favorite</mat-icon>
        </mat-grid-tile-footer>
    </mat-grid-tile>
</mat-grid-list>


<!--
<div fxLayout="row" fxLayoutWrap="wrap">
    <div fxFlex.gt-sm="25" fxFlex.gt-xs="100%" fxFlex="100" *ngFor="let viewImageDto of listEntityImagesDto.imagePanelDto.viewImageDtoList">
        <mat-card>
            <div class="media-grid">

                <figure>
                    <div>viewImageDto badge {{ viewImageDto.badge}} url {{ viewImageDto.url}} name {{ viewImageDto.name }}</div>
                    <div class="product-badge"><span>{{viewImageDto.badge}}</span></div>
                    <img src="{{ getImageUrl(viewImageDto.url) }}">
                    <figcaption>
                        <p>
                            <a href="javascript:void(0)" matTooltip="Add to cart" matTooltipPosition="left">
                                <mat-icon>shopping_basket</mat-icon>
                            </a>
                            <a href="javascript:void(0)" matTooltip="Add to favorites" matTooltipPosition="left">
                                <mat-icon>favorite</mat-icon>
                            </a>
                            <a href="javascript:void(0)" matTooltip="Share" matTooltipPosition="left">
                                <mat-icon>share</mat-icon>
                            </a>
                            <a href="javascript:void(0)" matTooltip="Bookmark" matTooltipPosition="left">
                                <mat-icon>bookmark</mat-icon>
                            </a>
                        </p>
                    </figcaption>
                </figure>
            </div>

            <mat-card-content>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <div fxFlex>
                        <p class="ma-0">{{ viewImageDto.name }}</p>
                        <p class="ma-0 pa-0 text-md">{{ viewImageDto.description }}</p>
                    </div>
                    <div class="mat-text-primary text-xl">
                        <span class="strikethrough mat-text-muted text-sm">$34</span>
                        <span>todo</span>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
-->
