import { AfterViewInit, Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ContextIdDto, IdNameTupleDto } from '@savvy/entity-instance-composite';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { EventEmitter } from '@angular/core';
import { UserCountryService } from '../../../services/userCountry.service';
import { Subject } from 'rxjs';
import { MatInput } from '@angular/material/input';
import { PaginationData, Pet, PetService } from '@savvy/pet';
import { SearchPetsInCustomer } from '@savvy/pet/model/searchPetsInCustomer';


@Component({
  selector: 'app-pet-selector-in-customer',
  templateUrl: 'petSelectorInCustomer.component.html'
})
export class PetSelectorInCustomerComponent implements OnInit, AfterViewInit {
  @ViewChild('searchInput') searchInput: MatInput;
  @Input() contextIdDto: ContextIdDto;
  @Input() showPlaceholder: boolean;
  @Input() autoFocus = true;
  @Input() suppportCreate = false;
  @Input() customerId: string;

  @Output() selectedPet = new EventEmitter<string>();
  @Output() addNewPetEvent = new EventEmitter();

  pets: IdNameTupleDto[];
  subject = new Subject();
  private petLocal = null;
  @Input() set petId(value: string) {
    if (value) {
      this.loadPet(value);
    }
  }
  get petId() {
    return this.petLocal;
  }

  constructor(
    private api: PetService,
    public userCountryService: UserCountryService
  ) {

  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    if (this.autoFocus) {
      setTimeout(() => {
        this.searchInput.focus();
      }, 500);
    }
  }

  handler(event: MatAutocompleteSelectedEvent): void {
    console.log('calling set value NOW');
    this.selectedPet.emit(event.option.value);
  }

  loadPet(petId) {
    this.api.get(
      petId
    ).subscribe(response => {
      if (response) {
        this.searchInput.value = response.name;
      }
    });
  }

  loadTypeahead(searchString: string) {

    console.log('env id is ' + this.contextIdDto.contextId);

    const searchPet = <SearchPetsInCustomer>{};
    searchPet.petOwnerId = this.customerId;
    searchPet.searchStr = searchString;

    const paginationData = <PaginationData>{};
    paginationData.pageSize = 1000;
    paginationData.pageNumber = 1;
    searchPet.paginationData = paginationData;

    if (searchString && searchString.length > 0) {
      this.api.searchPetsInCustomer(
        searchPet
      ).subscribe(response => {
        this.handleResponse(response);
      });
    }
  }

  onKey(event: any) { // without type info
    console.log('event is ' + event);
    console.log('event.target is ' + event.target);
    console.log('value is ' + event.target.value);
    //console.log(this.form.controls[this.elementInstanceDto.instanceId].value);
    // this.form.controls[this.elementInstanceDto.instanceId].setErrors({ 'doesNotSelected': true });
    this.loadTypeahead(event.target.value);
  }

  search(event, cell, rowIndex) {
    const searchText = event.target.value;
    // emits the `searchText` into the stream.
    // This will cause the operators in its pipe function (defined in the ngOnInit method)
    // to be run. `debounce` runs and then `map`.
    // If the time interval of 1 sec in debounce hasn't elapsed, map will not be called, thereby saving the server from being called.
    this.subject.next({ searchText, cell, rowIndex });
  }


  displayFn(id) {
    if (!id) {
      return '';
    }
    const index = this.pets.findIndex(state => state.id === id);
    if (index === -1) {
      console.log('id is ' + id + ' index is' + index + ' this should never happen as tuples should always be there first');
      return '';
    } else {
      return this.pets[index].name;
    }
  }

  private handleResponse(response) {
    console.log('setting pets to response ' + response);
    this.pets = response;
    console.log('got pets ' + response.length);
  }
}

