import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ContextService } from '../context.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { ConfirmationModalComponent } from '../shared/components/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { TipsService } from '@savvy/tips';
import { AddTipModalComponent } from '../shared/components/add-tip-model/add-tip-modal.component';
import { Invoice } from '@savvy/view-definition';
import { ContextIdDto } from '@savvy/quickbooks';
import { Tip, PaymentService, StaffTip, Payment, RecordPayment } from '@savvy/payment';
import { UsercompService } from '@savvy/user';
import { UserDto } from '@savvy/user';
import { PhoneNumberHelperService } from '../shared/services/phone-number-helper.service';
import { TipsHelperService } from '../shared/services/tips-helper.service';

@Component({
  selector: 'app-view-tips',
  templateUrl: './viewTips.component.html'
})
export class ViewTipsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() contextIdDto: ContextIdDto;
  @Input() visible: boolean;
  @Output() viewTipsCancelled = new EventEmitter();
  @Output() invoiceUpdated = new EventEmitter();
  tips: Array<Tip>;
  payments: Payment[];
  destroy$ = new Subject();
  subscriptions: Subscription[] = [];
  users: Array<UserDto> = [];

  private localInvoice: Invoice;
  @Input() public set invoice(value: Invoice) {
    this.localInvoice = value;
    this.getUsers();
  }
  public get invoice(): Invoice {
    return this.localInvoice;
  }

  constructor(
    private tipsHelperService: TipsHelperService,
    private paymentService: PaymentService,
    private contextService: ContextService,
    private dialog: MatDialog,
    private userCompApi: UsercompService,

  ) {
  }

  ngOnInit() {
    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
        // this.getTips();
      }
    }));
    this.subscriptions.push(this.tipsHelperService.updateViewTips.subscribe(data => {
      if (data) {
        this.getUsers();
      }
    }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.invoice) {
      this.getUsers();
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }


  cancelIt() {
    this.viewTipsCancelled.emit('cancelled');
  }

  getTips() {
    const tips: any[] = [];
    this.paymentService.loadPaymentsForInvoice(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      this.invoice.invoiceId.id).subscribe(response => {
        this.payments = response;
        for (const payment of response) {
          if (payment.staffTips) {
            for (const staffTip of payment.staffTips) {
              const staff = this.users.find(u => u.id === staffTip.staffMember.id);
              tips.push({
                amount: staffTip.tipAmount,
                dateTime: payment.dateTime,
                paymentMethod: payment.paymentMethod,
                paymentReference: payment.paymentReference,
                paymentId: payment.paymentId,
                staffMember: staffTip.staffMember,
                staffMemberName: staff ? staff.fullName : ''
              });
            }
          }
        }
        this.tips = [...tips];

      });
  }

  deleteTip(row) {
    console.log(row);

    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        name: 'Tip'
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const index = this.payments.findIndex(p => p.id === row.paymentId.id);
        if (index > -1) {
          const staffTipIndex = this.payments[index].staffTips.findIndex(st => st.staffMember.id === row.staffMember.id);
          if (staffTipIndex > -1) {
            this.payments[index].staffTips.splice(staffTipIndex, 1);
          }
          const recordPayment: RecordPayment = {
            contextIdDto: this.contextIdDto,
            payment: this.payments[index]
          };
          this.paymentService.recordPayment(
            recordPayment
          ).subscribe(response => {
            //   this.invoiceUpdated.emit(response.invoice);
            this.getTips();
          });
        }
      } else {
        console.log('ERR: Not deleting');
      }
    });
  }

  addTip() {
    const dialogRef = this.dialog.open(AddTipModalComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        invoice: this.invoice
      },
      autoFocus: false
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.getTips();
        console.log('reloading invoice');
      });
  }

  getUsers() {
    this.userCompApi.getEnvUsersFilterCustomers(this.contextIdDto.contextId)
      .subscribe(response => {
        this.users = response.userDtos;
        this.getTips();
      });
  }
}
