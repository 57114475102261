<mat-toolbar>
  <mat-toolbar-row>{{'Create ' + serviceLabel | translate}}</mat-toolbar-row>
</mat-toolbar>

<div fxLayout="column" fxLayoutWrap="nowrap" fxLayoutAlign="space-between center" class="pt-1">
  <form #form="ngForm">

    <div fxLayout.gt-xs="row" fxLayout="column" fxLayoutWrap="wrap" class="split-panel">
      <mat-form-field appearance="outline">
        <mat-label>Service Name</mat-label>
        <input matInput placeholder="Service Name" name="serviceName" [(ngModel)]="consumerService.serviceName"
          #serviceName="ngModel" required>

        <mat-error *ngIf="serviceName.hasError('required')">
          {{ 'Name is' | translate}} <strong>{{'required' | translate}}</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div fxLayout.gt-xs="row" fxLayout="column" fxLayoutWrap="wrap" class="split-panel">

      <mat-form-field appearance="outline">
        <mat-label>Duration In Mins</mat-label>
        <input matInput placeholder="Duration In Mins" type="text" appNumberOnly name="durationInMins"
          [(ngModel)]="consumerService.durationInMins" #durationInMins="ngModel" required>
      </mat-form-field>
    </div>

    <div fxLayout.gt-xs="row" fxLayout="column" fxLayoutWrap="wrap" class="split-panel">

      <mat-form-field appearance="outline">
        <mat-label>Unit Price</mat-label>
        <input matInput type="text" appNumberOnly [decimalPoints]="2" placeholder="Unit Price" name="unitPrice"
          [(ngModel)]="consumerService.unitPrice" #unitPrice="ngModel" required>
      </mat-form-field>
    </div>

    <div fxLayout.gt-xs="row" fxLayout="column" fxLayoutWrap="wrap" class="split-panel">

      <mat-form-field appearance="outline">
        <mat-label>Color</mat-label>
        <input matInput type="text" placeholder="Color" name="Color" [value]="consumerService.colour"
          [cpUseRootViewContainer]="true" [style.background]="consumerService.colour"
          [(colorPicker)]="consumerService.colour" [cpOKButton]="true" [cpOutputFormat]="'hex'"
          [cpSaveClickOutside]="false" [cpOKButtonClass]="'mat-raised-button mat-accent'"
          (colorPickerSelect)="updateColor($event)">
      </mat-form-field>
    </div>
  </form>
</div>
<div fxLayout="row" fxLayoutAlign="end" class="mr-1">
  <button mat-raised-button color="accent" (click)="addNew()" [disabled]="!form.valid">{{'Add New' |
    translate}}</button>
</div>