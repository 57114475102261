import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { ConfirmCreateInstance, CreateEntityInstanceWithEntityDefinitionId, EicompService, LinkedInstanceDto } from '@savvy/entity-instance-composite';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import LinkedInstanceTypeDtoEnum = LinkedInstanceDto.LinkedInstanceTypeDtoEnum;
import { ChangeListener } from './changeListener';
import { ElementControlService } from './ElementControlService';
import { EventBusService } from './EventBusService';
import { NavChangeService } from './NavChangeService';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContextIdDto } from '@savvy/datalist';
import {ViewcompService} from '@savvy/view-composite';

@Component({
  selector: 'app-create-entity-page',
  templateUrl: 'createEntityPage.component.html'
})
export class CreateEntityPageComponent implements OnInit, OnDestroy {

  active = false;
  isModalOpen = false;
  sub: any;
  public form: UntypedFormGroup; // our form model
  public eventBus = new EventBusService();
  contextIdDto: ContextIdDto;

  creating = false;
  changeListener = new ChangeListener();
  loadEiViewResponse: any;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    @Optional() private dialogRef: MatDialogRef<CreateEntityPageComponent>,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private navChange: NavChangeService,
    private ecs: ElementControlService,
    private notify: FloSnackbarComponent,
    private translateService: TranslateService,
    private viewCompApi: ViewcompService,
    private eiCompApi: EicompService) {

    this.changeListener.eventAdded$.subscribe(event => this.onEvent(event));
  }


  getInvalid() {
    this.ecs.findInvalidControls(this.form);
  }


  ngOnInit() {

    console.log('inside init create entity page');

    this.sub = this.route.params.subscribe(params => {
      const customerId = params['customerId'];
      console.log('customerId ' + customerId);

      const entityDefinitionId = params['entityDefinitionId'] ? params['entityDefinitionId'] : this.data.entityDefinitionId;
      console.log('got entityDefinitionId ' + entityDefinitionId);
      const contextId = params['contextId'];
      const contextIdType = params['contextIdType'];
      console.log('got contextId ' + contextId);
      console.log('got contextIdType ' + contextIdType);

      const linkedEntityInstanceId = params['linkedEntityInstanceId'];
      const linkedEntityDefinitionId = params['linkedEntityDefinitionId'];
      console.log('linkedEntityInstanceId ' + linkedEntityInstanceId);
      console.log('linkedEntityDefinitionId ' + linkedEntityDefinitionId);

      this.contextIdDto = (contextId && contextIdType) ? { contextIdType, contextId } : this.data.contextIdDto;
      this.isModalOpen = !!(this.data && this.data.isModalOpen);

      let linkedInstanceDto = null;
      if (linkedEntityInstanceId) {
        linkedInstanceDto = <LinkedInstanceDto>{};
        linkedInstanceDto.linkedInstanceTypeDto = LinkedInstanceTypeDtoEnum.EntityInstance;
        linkedInstanceDto.entityDefinitionId = linkedEntityDefinitionId;
        linkedInstanceDto.entityInstanceId = linkedEntityInstanceId;
      } else if (customerId) {
        console.log('got customer id');
        linkedInstanceDto = <LinkedInstanceDto>{};
        linkedInstanceDto.linkedInstanceTypeDto = LinkedInstanceTypeDtoEnum.Customer;
        linkedInstanceDto.customerId = customerId;
      } else {
        console.log('No linked instance');
      }

      console.log('calling CreateEntityInstanceWithEntityDefinitionId ');
      const request: CreateEntityInstanceWithEntityDefinitionId = {
        contextIdDto: this.contextIdDto,
        entityDefinitionId,
        linkedInstanceDto
      };
      this.eiCompApi.createEiByEntityDefinitionIdLite(
        request).subscribe(response => {
            this.viewCompApi.loadEiToCreate(response.entityInstanceId,
              this.contextIdDto.contextId,
              this.contextIdDto.contextIdType).subscribe(response2 => {
              this.handleResponse(response2);
            });
        });

      //   console.log('done init');

    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  cancelIt() {
    if (!this.isModalOpen) {
      //  console.log('here in cancel in page');

      this.router.navigate(['/entity/listEntityInsts',
        this.loadEiViewResponse.viewContextDto.addEditEntityInstanceContextDto.entityDefinitionDto.entityDefinitionId.id,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType
      ]);
    }
  }

  /**
   * This will only be called if not created
   */
  createRecord() {
    // console.log('create called');
    const payload = JSON.stringify(this.form.value);
    // console.log('payload ' + payload);

    this.creating = true;
    this.eiCompApi.confirmCreateEi(
      this.loadEiViewResponse.viewContextDto.addEditEntityInstanceContextDto.entityInstanceId.id,
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
    ).subscribe(
      response => {
        this.creating = false;
        // TODO:
      //  alert('load view?');
        // this.loadEiViewResponse = response;
        this.translateService.get('Successfully created').subscribe(translatedTxt => {
          this.notify.message = translatedTxt;
          this.notify.open();
          if (!this.isModalOpen) {
            if (this.dialogRef) {
              this.dialogRef.close(response);
            }
            this.router.navigate(['/entity/viewEntity',
              this.loadEiViewResponse.viewContextDto.entityInstanceId.id,
              this.contextIdDto.contextId,
              this.contextIdDto.contextIdType
            ]);
          }
        });
        // Need to dynamically add the form now....  unless just having the ngIf form of forms things works??
      }, error => {
        console.log('error while createRecord', error);
        this.creating = false;
      });

  }

  private onEvent(event: string): void {
    console.log('createEntityPage onEvent called with event ' + event);

    if (event === 'reload') {

      console.log('reloading');
       this.viewCompApi.loadEiToEdit(this.loadEiViewResponse.viewContextDto.addEditEntityInstanceContextDto.entityInstanceId.id,
         this.contextIdDto.contextId,
         this.contextIdDto.contextIdType).subscribe(
           response => {
             // console.log('Got response it is ' + response);
             this.handleResponse(response);
           });
    }
  }

  private handleResponse(response) {
    this.navChange.add('createEntity:' +
      response.viewContextDto.addEditEntityInstanceContextDto.entityDefinitionDto.entityDefinitionId.id);

    // Build form first!!!
    if (response.viewRootElementDto != null) {
      this.form = this.ecs.toFormGroup(response.viewRootElementDto,
        response.viewContextDto.addEditEntityInstanceContextDto.entityDefinitionDto);
    } else {
      console.log('Empty panel so assuming empty form');
      this.form = this.ecs.emptyFormGroup();
    }
    this.loadEiViewResponse = response;
  }

  public isValid() {
    console.log('returning form valid in create entity page');

    this.ecs.debugControls(this.form);

    if (!this.form.valid) {
      console.log('', this.ecs.findInvalidControls(this.form));
    }
    return this.form.valid;
  }

  backToList() {
    this.location.back();
  }
}

