
import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import { ContextIdDto } from '@savvy/view-definition';

@Component({
    selector: 'app-messages-disabled',
    templateUrl: './messagesDisabled.component.html'
})
export class MessagesDisabledComponent {
    @Input() contextIdDto: ContextIdDto;

    constructor(private router: Router) {
    }

    public gotoEmailSettings() {
        console.log('inside gotoEmailsettings');

        this.router.navigate(['/emailSettings',
            this.contextIdDto.contextId,
            this.contextIdDto.contextIdType]);
    }
}

