<div [formGroup]="form" *ngIf="elementInstanceDto && workflowDefs" style="width: 100%">
  <mat-form-field appearance="outline" style="width: 100%;">
    <mat-label>{{workflowFieldDefinition.label | translate}}</mat-label>
    <mat-select
                [formControlName]="elementInstanceDto.instanceId"
                [required]="workflowFieldDefinition.required"
                placeholder="{{workflowFieldDefinition.placeholder | translate}}">

        <mat-option *ngIf="!workflowFieldDefinition.required" [value]=""></mat-option>

        <mat-option *ngFor="let workflowDef of workflowDefs"
                    [value]="workflowDef.id">{{workflowDef.name}}
        </mat-option>
    </mat-select>
    <mat-error *ngIf="form.controls[elementInstanceDto.instanceId].hasError('required')">
      {{'Workflow is' | translate}} <strong>{{'required' | translate}}</strong>
    </mat-error>
</mat-form-field>
</div>
