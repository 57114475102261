import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Appointment, AppointmentService } from '@savvy/appointment';
import { Invoice, InvoiceService } from '@savvy/invoice';
import { ContextIdDto } from '@savvy/menu';
import { Pet } from '@savvy/search';
import { ViewInvoiceV2Component } from 'src/app/flo/appointment/view-invoice-v2/view-invoice-v2.component';
import { APP_TYPE_ENUM, ContextService } from 'src/app/flo/context.service';
import { GenerateCustomerInvoiceModalComponent, GenerateCustomerInvoiceModalData } from 'src/app/flo/petdaycare/view-pet-boarding/generate-customer-invoice-modal/generate-customer-invoice-modal.component';
import { UserCurrencyService } from 'src/app/flo/shared/services/userCurrency.service';

@Component({
  selector: 'app-appointment-invoice-summary',
  templateUrl: './appointment-invoice-summary.component.html',
  styleUrls: ['./appointment-invoice-summary.component.scss']
})
export class AppointmentInvoiceSummaryComponent implements OnInit, OnChanges {

  @Input() contextIdDto: ContextIdDto;
  @Input() customerId: string;
  @Input() addInvoiceActive = false;
  appointments: Appointment[] = [];
  invoices: Invoice[] = [];
  appType: string = APP_TYPE_ENUM.PET_GROOMING_SALON;
  currencyCode = '';
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private invoiceService: InvoiceService,
    private appointmentService: AppointmentService,
    private userCurrencyService: UserCurrencyService,
    public contextService: ContextService,
  ) { }

  ngOnInit(): void {
    this.loadAppointmentsAndInvoices();
    this.getCurrencyCode();
    this.contextService.appType$.subscribe(res => {
      this.appType = res;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.customerId?.currentValue !== changes?.customerId?.previousValue) {
      this.loadAppointmentsAndInvoices();
    }
  }

  loadAppointmentsAndInvoices() {
    if (this.customerId) {
      this.loadFutureCustomerAppointments(this.customerId);
      this.loadInvoiceLists(this.customerId);
    }
  }

  loadFutureCustomerAppointments(customerId: string) {
    this.appointmentService.loadFutureCustomerAppointments(customerId, this.contextIdDto.contextId,
      this.contextIdDto.contextIdType).subscribe(response => {
        this.appointments = response;
      }, error => {
        this.appointments = [];
      });
  }

  loadInvoiceLists(customerId) {
    this.invoiceService.listInvoiceByCustomerId(
      this.contextIdDto.contextId, this.contextIdDto.contextIdType, customerId)
      .subscribe((response) => {
        this.invoices = response;
        console.log('loaded customer invoices', response);
      }, (err: HttpErrorResponse) => {
        this.invoices = [];
        console.log('error while loadContractLists', err);
      });
  }

  viewInvoice(invoice: Invoice) {
    if (invoice.id) {
      console.log('inside view invoiceId ', invoice);
      const dialogRef = this.dialog.open(ViewInvoiceV2Component, {
        maxWidth: '800px',
        width: '800px',
        data: {
          invoiceId: invoice.id,
          contextIdDto: this.contextIdDto
        },
        panelClass: ['scrollable-modal', 'invoicemodal']
      });

      dialogRef.afterClosed().subscribe((result) => {

      });
    }
  }

  addInvoice() {
    if (!this.customerId) {
      console.warn('no customer id');
    } else {
      this.router.navigate(['/invoice/addCustomerInvoice',
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.customerId
      ]);
    }
  }

  addInvoiceForAppointments() {
    const data: GenerateCustomerInvoiceModalData = {
      contextIdDto: this.contextIdDto,
      currencyCode: this.currencyCode,
      customerId: this.customerId
    }

    const confirmDialog = this.dialog.open(GenerateCustomerInvoiceModalComponent, {
      data,
      height: 'auto',
      width: '800px',
      panelClass: 'helpwindow'
    });


    confirmDialog.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.loadAppointmentsAndInvoices();

      }
    });

  }

  private getCurrencyCode() {
    this.userCurrencyService.getDefaultCurrency(this.contextIdDto)
      .subscribe(res => {
        this.currencyCode = res.org.currencyCode ? res.org.currencyCode : this.userCurrencyService.defaultCurrency;
      });
  }

}
