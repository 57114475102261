import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ContextIdDto } from '@savvy/app';
import { Appointment, AppointmentConfig, AppointmentPaymentConfig } from '@savvy/appointment';
import { PlanDto, PlansCompService } from '@savvy/plan';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PhoneNumberHelperService } from 'src/app/flo/shared/services/phone-number-helper.service';
import * as _ from 'lodash';
import { AppointmentConfigHelperService } from 'src/app/flo/shared/services/appointment-config-helper.service';
import { CustomerCompositeService, Customer, CustomerService } from '@savvy/customer';
import { PaymentSettings, PaymentSettingsService } from '@savvy/payment';
import { PlanDefinition } from '@savvy/plan-definition/model/planDefinition';
import { PlanDefService } from '@savvy/plan-definition';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AddEditPlanDefComponent } from '../../../plan/addEditPlanDef.component';
import { ClearObservable } from 'src/app/flo/shared/classes/clear-observable';
import { CreatePetAppointmentV2SyncService } from '../../create-pet-appointment-v2/create-pet-appointment-v2-sync.service';
import { UserCurrencyService } from 'src/app/flo/shared/services/userCurrency.service';
import { PetStayPaymentConfig } from '@savvy/pet-stay';
import {AccountHolder, SavvyPayAccountHolderService} from "@savvy/savvy-pay";
import {EmailMessageTemplate, EmailmessagetemplatesService} from "@savvy/email-template";
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent extends ClearObservable implements OnInit, OnChanges {

  @Input() contextIdDto: ContextIdDto;
  @Input() customerId: string;
  @Input() totalAmount = 0;
  @Input() paymentConfig: AppointmentPaymentConfig;
  @Output() paymentConfigChange: EventEmitter<AppointmentPaymentConfig> = new EventEmitter();
  @Output() amountDueChanged: EventEmitter<number> = new EventEmitter();
  @Output() createPlanChanged: EventEmitter<boolean> = new EventEmitter();

  percentage = 0;
  amount = 0;
  currencyCode = 'GBP';

  plans: Array<PlanDto> = [];
  planDefinitions: Array<PlanDefinition> = [];
  selectedPlan: PlanDto = {};
  selectedPlanDefinition: PlanDefinition;
  customerDto: Customer;
  paymentSettings: PaymentSettings;

  paymentModeEnum = AppointmentPaymentConfig.PaymentModeEnum;
  paymentTypeEnum = AppointmentPaymentConfig.PaymentTypeEnum;

  savvyPayAccountHolder: AccountHolder;

  hasDepositSmsTemplate = false;

  constructor(
    private dialog: MatDialog,
    private sharedService: PhoneNumberHelperService,
    private plansCompService: PlansCompService,
    private appointmentConfigHelperService: AppointmentConfigHelperService,
    private customerCompService: CustomerCompositeService,
    private customerService: CustomerService,
    private paymentSettingsApi: PaymentSettingsService,
    private planDefinitionService: PlanDefService,
    private createPetAppointmentV2SyncService: CreatePetAppointmentV2SyncService,
    private accountHolderService: SavvyPayAccountHolderService,
    private userCurrencyService: UserCurrencyService,
    private emailMessageTemplateService: EmailmessagetemplatesService

  ) {
    super();
  }

  setDefaults() {
    // Default
    this.paymentConfig = {
      paymentMode: this.appointmentConfigHelperService.appointmentConfig.defaultPaymentMode,
      paymentType: this.appointmentConfigHelperService.appointmentConfig.defaultPaymentType,
      amountDue: 0
    } as AppointmentPaymentConfig;

    if (this.appointmentConfigHelperService.appointmentConfig.defaultPaymentMode
      === AppointmentPaymentConfig.PaymentModeEnum.DepositRequest) {
      if (this.appointmentConfigHelperService.appointmentConfig.defaultPaymentType
        === AppointmentConfig.DefaultPaymentTypeEnum.Percentage) {
        this.percentage = this.appointmentConfigHelperService.appointmentConfig.defaultPercentage;
        this.calculateAmountDue();
      } else if (this.appointmentConfigHelperService.appointmentConfig.defaultPaymentType
        === AppointmentConfig.DefaultPaymentTypeEnum.Amount) {
        if (this.totalAmount > this.appointmentConfigHelperService.appointmentConfig.defaultAmount) {
          this.paymentConfig.amountDue = _.clone(this.appointmentConfigHelperService.appointmentConfig.defaultAmount);
          this.amount = _.clone(this.paymentConfig.amountDue);
        } else {
          this.paymentConfig.amountDue = _.clone(this.totalAmount);
          this.amount = _.clone(this.totalAmount);
        }
        this.amountDueChanged.emit(this.paymentConfig.amountDue);
      }
    } else if (this.appointmentConfigHelperService.appointmentConfig.defaultPaymentMode
      === AppointmentPaymentConfig.PaymentModeEnum.InPerson) {
      this.paymentConfig.amountDue = 0;
      this.amountDueChanged.emit(this.paymentConfig.amountDue);
    } else {
      this.paymentConfig.amountDue = this.totalAmount;
      this.amountDueChanged.emit(this.paymentConfig.amountDue);
    }
    this.paymentConfigChange.emit(this.paymentConfig);
  }

  ngOnInit(): void {
    if (!this.paymentConfig?.paymentMode) {
      this.setDefaults();
    } else {
      this.amount = _.clone(this.paymentConfig.amountDue);
      // this.percentage = _.clone(this.paymentConfig.amountDue);
      this.amountDueChanged.emit(this.paymentConfig.amountDue);
    }
    this.loadPlans();
    this.loadCustomer();
    this.loadPaymentSettings();
    this.loadPlanDefinitions();
    this.loadDepositEmailTemplate();
    this.createPetAppointmentV2SyncService.totalAmountChanged$
      .pipe(takeUntil(this.destroy$))
      .subscribe((newAmount: number) => {
        this.totalAmount = newAmount;
        if (this.paymentConfig.paymentMode === 'DEPOSIT_REQUEST') {
          if (this.paymentConfig.paymentType === 'AMOUNT') {
            if (this.amount > newAmount) {
              this.amount = newAmount;
              this.amountChanged(newAmount);
            } else {
              this.paymentConfig.amountDue = _.clone(this.appointmentConfigHelperService.appointmentConfig.defaultAmount);
              this.amount = _.clone(this.paymentConfig.amountDue);
              this.amountDueChanged.emit(this.paymentConfig.amountDue);
            }
          } else if (this.paymentConfig.paymentType === 'PERCENTAGE') {
            this.calculateAmountDue();
          } else {
            this.paymentConfig.amountDue = _.clone(newAmount);
            this.amount = _.clone(this.paymentConfig.amountDue);
            this.amountDueChanged.emit(this.paymentConfig.amountDue);
          }
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.totalAmount?.currentValue !== changes?.totalAmount?.previousValue) {
      this.totalAmount = changes.totalAmount.currentValue;
    }
    if (changes?.paymentConfig?.currentValue && changes?.paymentConfig?.currentValue !== changes?.paymentConfig?.previousValue) {
      this.paymentConfig = changes.paymentConfig.currentValue;
      if (this.paymentConfig.amountDue && this.totalAmount) {
        this.percentage = Number(((this.paymentConfig.amountDue * 100) / this.totalAmount).toFixed(2));
      }
      this.paymentConfigChange.emit(this.paymentConfig);
    }
  }

  getCurrencyCode() {
    this.userCurrencyService.getDefaultCurrency(this.contextIdDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.currencyCode = res.org.currencyCode ? res.org.currencyCode : this.userCurrencyService.defaultCurrency;
      });
  }

  loadPaymentSettings() {
    this.paymentSettingsApi.loadPaymentSettings(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
    )//.pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.paymentSettings = response;

        if (this.paymentSettings?.savvyPaySettings?.accountHolderCode) {
          this.accountHolderService.loadAccountHolder(this.paymentSettings?.savvyPaySettings?.accountHolderCode).subscribe(res => {
            this.savvyPayAccountHolder = res;
          });
        }
      });

  }

  loadPlans() {
    if (this.customerId) {
      this.plansCompService.loadActiveCustomerPlansComp(this.contextIdDto.contextId,
        this.contextIdDto.contextIdType, this.customerId).subscribe(res => {
          if (res && res.plans && res.plans.length) {
            this.plans = res.plans;
            this.selectedPlan = this.plans[0];
          }
        });
    }
  }

  loadPlanDefinitions() {
    if (this.customerId) {
      this.planDefinitionService.loadByOwnerIdSorted(this.contextIdDto.contextId,
        this.contextIdDto.contextIdType).subscribe(res => {
          if (res && res.planDefinitions && res.planDefinitions.length) {
            this.planDefinitions = res.planDefinitions;
          }
        });
    }
  }

  loadCustomer() {
    if (this.customerId) {
      this.customerService.loadCustomer(this.customerId, this.contextIdDto.contextId,
        this.contextIdDto.contextIdType).subscribe(res => {
          this.customerDto = res;
        });
    }
  }

  loadDepositEmailTemplate() {
    this.emailMessageTemplateService.getEmailMessageTemplateByType(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType, 'depositRequest').subscribe(res => {
      if (res && res.smsMessageBody) {
        this.hasDepositSmsTemplate = true;
      }
    });
  }

  amountChanged(event) {
    this.paymentConfig.amountDue = event;
    this.amountDueChanged.emit(this.paymentConfig.amountDue);
  }

  calculateAmountDue() {
    const percentage = Number((Number(this.percentage) / 100).toFixed(2));
    this.paymentConfig.amountDue = this.totalAmount * percentage;
    this.amount = _.clone(this.paymentConfig.amountDue);
    this.amountDueChanged.emit(this.paymentConfig.amountDue);
  }

  goToPlans() {
    const dialogRef = this.dialog.open(AddEditPlanDefComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        customerId: this.customerId
      },
      width: '1080px',
      panelClass: ['scrollable-modal', 'helpwindow']
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadPlanDefinitions();
    });

  }

  planChanged(newPlan: PlanDto) {
    this.paymentConfig.paymentPlanId = newPlan.id;
    this.paymentConfigChange.emit(this.paymentConfig);
  }

  planDefinitionChange(planDefinition: PlanDefinition) {
    if (this.paymentConfig.paymentMode === AppointmentPaymentConfig.PaymentModeEnum.PaymentPlan
      && this.selectedPlanDefinition && this.selectedPlanDefinition.id) {
      this.createPlanChanged.emit(true);
    } else {
      this.createPlanChanged.emit(false);
    }
  }

  paymentTypeChanged(event) {
    switch (event) {
      case AppointmentPaymentConfig.PaymentTypeEnum.Percentage:
        this.calculateAmountDue();
        break;
      case AppointmentPaymentConfig.PaymentTypeEnum.FullAmount:
        this.paymentConfig.amountDue = _.clone(this.totalAmount);
        this.amount = _.clone(this.paymentConfig.amountDue);
        this.amountDueChanged.emit(this.paymentConfig.amountDue);
        break;
      case AppointmentPaymentConfig.PaymentTypeEnum.Amount:
        this.paymentConfig.amountDue = _.clone(this.amount);
        this.amount = _.clone(this.paymentConfig.amountDue);
        this.amountDueChanged.emit(this.paymentConfig.amountDue);
        break;
    }
    this.paymentConfigChange.emit(this.paymentConfig);

  }

  paymentModeChanged(event) {
    switch (event) {
      case AppointmentPaymentConfig.PaymentModeEnum.DepositRequest:
        this.paymentConfig.paymentMode = AppointmentPaymentConfig.PaymentModeEnum.DepositRequest;
        if (this.paymentConfig.paymentType === this.paymentTypeEnum.Percentage) {
          this.calculateAmountDue();
        } else if (this.paymentConfig.paymentType === this.paymentTypeEnum.Amount) {
          this.paymentConfig.amountDue = this.amount;
        } else {
          this.paymentConfig.amountDue = this.totalAmount;
        }
        this.selectedPlanDefinition = undefined;
        break;
      case AppointmentPaymentConfig.PaymentModeEnum.PaymentPlan:
        this.paymentConfig.paymentMode = AppointmentPaymentConfig.PaymentModeEnum.PaymentPlan;
        this.paymentConfig.amountDue = this.totalAmount;
        this.paymentConfig.sendSms = false;
        break;
      case AppointmentPaymentConfig.PaymentModeEnum.InPerson:
        this.selectedPlanDefinition = undefined;
        this.paymentConfig.paymentMode = AppointmentPaymentConfig.PaymentModeEnum.InPerson;
        this.paymentConfig.amountDue = 0;
        break;
    }
    this.amountDueChanged.emit(this.paymentConfig.amountDue);
    this.paymentConfigChange.emit(this.paymentConfig);

  }

  public getPaymentConfig(sendSms = false): AppointmentPaymentConfig  {
    return {
      paymentMode: this.paymentConfig.paymentMode,
      paymentType: this.paymentConfig.paymentType,
      amountDue: this.paymentConfig.amountDue,
      paymentPlanId: this.selectedPlan ? this.selectedPlan.id : null,
      noOfSessionsToRedeem: this.paymentConfig.noOfSessionsToRedeem,
      sendSms: this.paymentConfig.sendSms,
      preAuth: this.paymentConfig.preAuth
    };
  }

  public getSelectedPlanDefinition(): PlanDefinition {
    return this.selectedPlanDefinition;
  }

}
