import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FloMaterialModule } from '../flo.material.module';
import { EditorModule } from '../editor/editor.module';
import { AddEditScheduleDefComponent } from './addEditScheduleDef.component';
import { TranslateModule } from '@ngx-translate/core';
import { AddEditScheduleDefFullComponent } from './addEditScheduleDefFull.component';

@NgModule({
  imports: [
    CommonModule,
    EditorModule,
    FloMaterialModule,
    TranslateModule
  ],
  declarations: [
    AddEditScheduleDefComponent,
    AddEditScheduleDefFullComponent
  ],
  exports: [
    AddEditScheduleDefComponent,
    AddEditScheduleDefFullComponent
  ],
  providers: [
  ]
})

export class ScheduleModule { }
