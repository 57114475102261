import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Reminder } from '@savvy/reminders';

export interface SmsEmailMessage {
  messageType?: Reminder.ReminderTypeEnum;
  message?: string;
}

@Component({
  selector: 'app-sms-email-message-popup',
  templateUrl: `./sms-email-message-popup.component.html`,
  styleUrl: './sms-email-message-popup.component.css'
})
export class SmsEmailMessagePopupComponent {

  smsEmailMessage: SmsEmailMessage;

  constructor(
    public dialogRef: MatDialogRef<SmsEmailMessagePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SmsEmailMessage
  ) {
    this.smsEmailMessage = data;
  }

}
