<mat-toolbar color="primary">
  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="nogrow" *ngIf="!isViewMode">
      <button mat-button (click)="gotoPetList()" class="mdc-px-2">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
      <span *ngIf="!petId">{{'ADD_PET' | translate}}</span>
      <span *ngIf="petId">{{'EDIT_PET' | translate}}</span>
    </div>
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="end center">
      <button mat-raised-button class="mx-4" *ngIf="isViewMode" (click)="cancel()">{{'CLOSE' | translate}}</button>
      <button mat-raised-button class="mx-4" (click)="persist()" color="accent"><span *ngIf="!petId">{{'ADD_PET' |
          translate}}</span><span *ngIf="petId">{{'UPDATE_PET' | translate}}</span></button>
    </div>
  </div>
</mat-toolbar>

<div class="" [ngClass]="{'flex-container': !isViewMode}">
  <div class="" [ngClass]="{'flex-sub-container': !isViewMode}">
    <mat-card appearance="outlined" class="overflow-hidden mt-1 mx-0 mdc-mb-1" *ngIf="!data?.petId">
      <mat-card-title class="mt-1 mb-2 ml-1 mr-1">

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
          <div fxFlex>
            Select Customer
          </div>
          <div fxFlex *ngIf="!data?.customerId">
            <mat-button-toggle-group #group="matButtonToggleGroup" [(ngModel)]="existingCustomer">
              <mat-button-toggle [value]="'yes'">
                Existing
              </mat-button-toggle>
              <mat-button-toggle [value]="'no'">
                New
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>

        </div>
      </mat-card-title>
      <mat-card-content>
        <app-customer-selector-v2 *ngIf="contextIdDto && existingCustomer === 'yes'" [contextIdDto]="contextIdDto"
          [readOnly]="data?.customerId ? true : false" (customerSelected)="customerSelected($event)">
        </app-customer-selector-v2>
        <app-new-customer-selector-v2 *ngIf="contextIdDto && existingCustomer === 'no'" [contextIdDto]="contextIdDto"
          (customerCreated)="customerSelected($event)">
        </app-new-customer-selector-v2>
        <mat-error
          *ngIf="submitted && !createAppointmentV2SyncService?.customersSelected?.length">{{'PLEASE_SELECT_CUSTOMER' |
          translate}}.</mat-error>
      </mat-card-content>
    </mat-card>
    <mat-card appearance="outlined" class="p-0 mx-0 overflow-hidden mdc-mb-1"
      [ngClass]="{'mt-0': isViewMode, 'mt-1': !isViewMode}">
      <mat-card-content class="p-0">
        <app-pet-profile *ngIf="breeds?.length" [contextIdDto]="contextIdDto" [pet]="pet"
          [breeds]="breeds"></app-pet-profile>
      </mat-card-content>
    </mat-card>
    <mat-card appearance="outlined" class="mx-0 mdc-mb-1">
      <mat-card-content>
        <app-pet-details [contextIdDto]="contextIdDto"></app-pet-details>
      </mat-card-content>
    </mat-card>
    <mat-card appearance="outlined" class="mx-0 mdc-mb-1">
      <mat-card-content>
        <app-pet-medical-details></app-pet-medical-details>
      </mat-card-content>
    </mat-card>
    <mat-card appearance="outlined"
      *ngIf="isVaccinationFeatureAllowed && petId && createAppointmentV2SyncService?.customersSelected[0]?.id"
      class="mx-0 mdc-mb-1">
      <!-- content is inside component-->
      <app-view-vaccinations-panel [contextIdDto]="contextIdDto" [viewVaccinationsData]="viewVaccinationsData"
        [customerId]="createAppointmentV2SyncService?.customersSelected[0]?.id">
      </app-view-vaccinations-panel>
    </mat-card>
    <div *ngIf="petId">
      <mat-card appearance="outlined" class="mx-0 mdc-mb-1">
        <mat-card-content>
          <app-pets-flags-panel [contextIdDto]="contextIdDto" [flagIds]="pet?.flags" (createFlag)="createFlag($event)"
            (deleteFlag)="deleteFlag($event)">
          </app-pets-flags-panel>
        </mat-card-content>
      </mat-card>
    </div>
    <mat-card appearance="outlined" class="mx-0 mdc-mb-1">
      <mat-card-content>
        <app-pet-file-upload [contextIdDto]="contextIdDto" [files]="pet?.files"
          (fileUpdated)="fileUpdated($event)"></app-pet-file-upload>
      </mat-card-content>
    </mat-card>
  </div>
</div>
