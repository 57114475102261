import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { EventBusService } from './EventBusService';
import { ContextIdDto } from '@savvy/view-definition';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ContextService } from '../context.service';
import { EventHandlerService } from '../entry/EventHandlerService';
import { EventType, UiEvent } from '../event/UiEvent';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-side-panel',
  templateUrl: 'sidePanel.component.html'
}) export class SidePanelComponent implements OnInit, OnDestroy {

  @Input() contextIdDto: ContextIdDto;
  @Input() sidePanel: MatSidenav;
  @Input() eventBus: EventBusService;
  @Input() viewContextDto: any;

  activePanel: string;
  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private contextService: ContextService,
    private eventHandler: EventHandlerService) {

  }

  ngOnInit(): void {
    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
      }
    }));
    this.eventBus.eventAdded$.subscribe(event => this.onEvent(event));
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }


  closePanel() {
    console.log('close panel called');
    this.sidePanel.close();
    this.activePanel = '';
  }

  openPanel(panelType: string) {
    console.log('open panel called ' + panelType);
    this.activePanel = panelType;
    this.sidePanel.open();
  }

  private onEvent(event: UiEvent): void {
    console.log('side panel onEvent called with event ' + event);

    if (event.eventType === EventType.EDIT_ENTITY) {
      this.eventHandler.handleEvent(this.contextIdDto, event);
    } else if (event.eventType === EventType.VIEW_ENTITY) {
      this.eventHandler.handleEvent(this.contextIdDto, event);
    } else if (event.eventType === EventType.TASK_MARKED_AS_DONE) {
      this.closePanel();
    } else if (event.eventType === EventType.TASK_EXECUTED) {
      this.closePanel();
    } else if (event.eventType === EventType.CLOSE_TASK) {
      this.closePanel();
    } else if (event.eventType === EventType.TASK_SELECTED) {
      this.openPanel('showTask');
    } else if (event.eventType === EventType.REMINDER_SELECTED) {
      console.log('calling open panel for showReminder');
      this.openPanel('showReminder');
    } else if (event.eventType === EventType.REMINDER_UPDATED) {
      this.closePanel();
    } else if (event.eventType === EventType.TASK_DESELECTED) {
      this.closePanel();
    } else if (event.eventType === EventType.CREATE_TASK) {
      this.openPanel('addEditTask');
    } else if (event.eventType === EventType.TASK_CREATED) {
      this.closePanel();
    } else if (event.eventType === EventType.TASK_UPDATED) {
      this.closePanel();
    } else if (event.eventType === EventType.CANCEL_ADD_EDIT_TASK) {
      this.closePanel();
    } else if (event.eventType === EventType.SEND_QUESTIONNAIRE) {
      this.openPanel('sendQuestionnaire');
    } else if (event.eventType === EventType.SENT_QUESTIONNAIRE) {
      this.closePanel();
    } else if (event.eventType === EventType.SENT_MESSAGE) {
      this.closePanel();
    } else if (event.eventType === EventType.CANCEL_SEND_MESSAGE) {
      this.closePanel();
    } else if (event.eventType === EventType.CLOSE_REMINDER) {
      this.closePanel();
      //  } else if (event.is(EventType.VIEW_ENTITY)) {
      //   this.openPanel('entity');
    } else if (event.eventType === EventType.CANCEL_VIEW_ENTITY) {
      this.closePanel();
    } else if (event.eventType === EventType.MESSAGE_SELECTED) {
      this.openPanel('viewMessage');
    }
  }
}
