import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DatalistService } from '@savvy/datalist';
import { Pet, PetService, PetType, PetTypeService } from '@savvy/pet';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CreatePetV2Component } from "./create-pet-v2.component";
import { FloSnackbarComponent } from "../../snackbar/floSnackbar.component";
import { DataItemDto } from "@savvy/datalist/model/dataItemDto";

@Component({
  selector: 'app-create-pet-v2-existing-customer',
  templateUrl: './create-pet-v2-existing-customer.component.html',
  styleUrls: ['./create-pet-v2.component.scss']
})
export class CreatePetV2ExistingCustomerComponent implements OnInit {

  pet: Pet;
  petFormGroup: UntypedFormGroup;
  existingCustomer = true;
  contextIdDto;


  breeds: Array<DataItemDto> = [];
  public filteredBreeds: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public petBreedFilter: UntypedFormControl = new UntypedFormControl();


  petTypes = [];
  public filteredPetTypes: ReplaySubject<PetType[]> = new ReplaySubject<PetType[]>(1);
  public petTypeFilter: UntypedFormControl = new UntypedFormControl();

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<CreatePetV2Component>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private notify: FloSnackbarComponent,
    private datalistService: DatalistService,
    private petTypeService: PetTypeService,
    private petService: PetService
  ) {
    this.contextIdDto = data.contextIdDto;
  }

  ngOnInit() {
    this.initPet();
    this.initFormGroup();
    this.petBreedFilter.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBreeds();
      });
    this.loadBreeds();

    this.petTypeFilter.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterPetTypes();
      });
    this.loadPetTypes();

    if (this.data.edit) {
      this.petFormGroup.controls.petName.setValue(this.data?.pet?.name);
      this.petFormGroup.controls.petBreed.setValue(this.data?.pet?.breed);
      this.petFormGroup.controls.petType.setValue(this.data?.pet?.petType);
      this.pet = this.data.pet;
    }
  }

  initPet() {
    this.pet = {
      ownerId: this.contextIdDto.contextId,
      name: ''
    } as Pet;
    if (this.data.customerId) {
      this.pet.petOwnerId = this.data.customerId;
    }
  }

  filterPetTypes() {
    if (!this.petTypes) {
      return;
    }
    // get the search keyword
    // get the search keyword
    let search = this.petTypeFilter.value;
    if (!search) {
      this.filteredPetTypes.next(this.petTypes.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the breeds
    this.filteredPetTypes.next(
      this.petTypes.filter(petType => petType.name.toLowerCase().indexOf(search) > -1)
    );
  }

  loadPetTypes() {
    this.petTypeService.listPetTypes()
      .subscribe(petTypes => {
        this.petTypes = petTypes;
        this.filterPetTypes();
        if (!this.petFormGroup?.value?.petType) {
          for (let i = 0; i < petTypes?.length; i += 1) {
            if (petTypes[i]?.name == 'Dog') {
              this.petFormGroup.patchValue({
                petType: petTypes[i]?.name
              });
            }
          }
        }
      });
  }

  loadBreeds() {
    this.datalistService.getDataListByName(
      this.data.contextIdDto.contextId,
      this.data.contextIdDto.contextIdType, 'Breed').subscribe(breeds => {
        if (breeds) {
          this.breeds = breeds.dataItems;
          this.filteredBreeds.next(this.breeds);
        }
      });
  }

  filterBreeds() {
    if (!this.breeds) {
      return;
    }
    // get the search keyword
    // get the search keyword
    let search = this.petBreedFilter.value;
    if (!search) {
      this.filteredBreeds.next(this.breeds.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the breeds
    this.filteredBreeds.next(
      this.breeds.filter(breed => breed.name.toLowerCase().indexOf(search) > -1)
    );
  }

  addPet() {
    if (!this.pet) {
      this.pet = {} as Pet;
    }
    this.pet.name = this.petFormGroup.get('petName').value;
    this.pet.breed = this.petFormGroup.get('petBreed').value;
    this.pet.petType = this.petFormGroup.get('petType').value;
    // Customer gets set when one is selected
    this.pet.breedLabel = this.getBreedLabel();
    this.createPet();
  }

  updatePet() {
     if (!this.pet) {
      this.pet = {} as Pet;
    }
    this.pet.name = this.petFormGroup.get('petName').value;
    this.pet.breed = this.petFormGroup.get('petBreed').value;
    this.pet.petType = this.petFormGroup.get('petType').value;
    this.pet.breedLabel = this.getBreedLabel();
  }

  getBreedLabel(): string {
    let label = '';
    this.breeds.forEach(breed => {
      if (breed?.name === this.pet?.breed) {
        label = breed.label;
      }
    });
    return label;
  }

  createPet() {
    if (!this.pet.ownerId) {
      console.warn('fault');
    } else {
      this.petService.createPet(this.pet).subscribe(response => {
          this.dialogRef.close(response);
        }, error => {
          console.log('error', error);
          this.notify.message = error.message;
          this.notify.open();
        });
    }
  }

  customerSelected(customerId): void {
    console.log('calling set value NOW');
    this.existingCustomer = true;
    if (!this.pet) {
      console.warn('pet should not be null');
    }
    this.pet.petOwnerId = customerId;
    // this.pet.petOwnerFirstName
    // this.pet.petOwnerLastName

  }

  private initFormGroup() {
    this.petFormGroup = this.formBuilder.group({
      petName: ['', Validators.required],
      petBreed: [''],
      petType: ['']
    });
  }


}
