<mat-card appearance="outlined" class="mdc-mb-1 mx-0">
  <mat-card-header class="mdc-mb-1">
    <div fxLayout="row" fxLayoutAlign=" center" class="w-100">
      <div fxFlex>
        <h6 class="mb-0 ">{{'PET_NOTES' | translate}}</h6>
      </div>
      <div fxFlex="nogrow">
        <button mat-button color="accent" (click)="editNotes = !editNotes" *ngIf="!editNotes">
          <mat-icon class="vm">edit</mat-icon>
          {{'EDIT_NOTES' | translate}}
        </button>
        <button mat-button color="accent" (click)="saveNotes()" *ngIf="editNotes">
          <mat-icon class="vm">save</mat-icon>
          {{'SAVE_NOTES' | translate}}
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content>
    <div fxLayout="column" class="mdc-mb-1">
      <div *ngIf="!editNotes && pet?.petNotes && pet?.petNotes !== ''">
        <p [innerText]="pet?.petNotes"></p>
      </div>
      <div *ngIf="!editNotes && (!pet?.petNotes || pet?.petNotes === '')">
        <h6>{{'NO_PET_NOTES' | translate}}</h6>
      </div>

      <mat-form-field *ngIf="editNotes" appearance="outline" class="w-100">
        <mat-label>{{'NOTES' | translate}}</mat-label>
        <textarea matInput [(ngModel)]="pet.petNotes" name="notes" rows="4"></textarea>
      </mat-form-field>

    </div>
  </mat-card-content>
</mat-card>

<div fxLayout="column" fxLayout.gt-md="row" fxLayoutGap="0px" fxLayoutGap.gt-md="18px">

  <div fxFlex="100" fxFlex.gt-md="auto" class="mdc-mb-1">
    <mat-card appearance="outlined" class=" mx-0">
      <mat-card-content>
        <div fxLayout="row" fxLayoutAlign=" center" class="w-100 mdc-mb-1">
          <div fxFlex>
            <h6 class="mb-0 ">{{'VET_DETAILS' | translate}}</h6>
          </div>
        </div>
        <br>
        <div *ngIf="(pet?.vetName || pet?.vetPhone); else elseBlockMedical">
          <div fxLayout="row" fxLayoutAlign=" center" class="mdc-mb-1">
            <div>
              <h6 class="mb-0">
                <mat-icon class="mat-text-accent vm">medication</mat-icon> <span class="customer-name">{{ pet?.vetName }}</span>
              </h6>
              <div class="chiptag theme vm" *ngIf="pet?.vetPhone">
                <mat-icon>phone</mat-icon> {{ pet?.vetPhone }}
              </div>
              <div class="mat-line small mat-text-secondary">{{'ALLERGIES' | translate}}: {{ pet?.allergies }}</div>
              <div class="mat-line small mat-text-secondary">{{'MICRO_CHIPPED' | translate}}: {{ pet?.microChipped }}</div>
              <div class="mat-line small mat-text-secondary">{{ pet?.medical }}</div>
            </div>
          </div>

        </div>
        <ng-template #elseBlockMedical>
          <div class="flexbox-parent">
            <div fxLayout="row" fxLayoutAlign="center center">
              <div style="text-align:center;">
                <div class="avatar avatar-100 circle border-3 mb-2 d-inline-block">
                  <mat-icon class="h1 h-auto w-auto">hourglass_empty</mat-icon>
                </div>
                <h6 class="mb-2">{{'NO_VET_DETAILS' | translate}}.</h6>
              </div>
            </div>
          </div>
        </ng-template>
      </mat-card-content>
    </mat-card>
  </div>

  <app-pet-vaccinations fxFlex="100" fxFlex.gt-md="50" *ngIf="isVaccinationFeatureAllowed" [contextIdDto]="contextIdDto" [pet]="pet">
  </app-pet-vaccinations>
</div>

<app-customer-contracts-v2 [contextIdDto]="contextIdDto" [customerId]="pet?.petOwnerId"></app-customer-contracts-v2>
