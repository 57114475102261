// import { Component, Inject, OnInit, ViewChild } from '@angular/core';
// import { MatLegacyDialogRef, MAT_LEGACY_DIALOG_DATA } from '@angular/material/legacy-dialog';
// import { MatSidenav } from '@angular/material/sidenav';
// import { ActionDefinition, LoadWorkflowDefinitionForEditResponse, LoadWorkflowStateResponse, WorkflowdefService } from '@savvy/workflow-definition';
// import { WorkflowTaskDefinition } from '@savvy/workflow-instance';


import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  ActionDefinition,
  Links,
  ListWorkflowDefinitionsForEditDto,
  LoadWorkflowDefinitionForEditResponse,
  LoadWorkflowStateResponse,
  SendMessageDefinition, SendSmsDefinition,
  TaskSettings,
  WorkflowdefService,
  WorkflowState,
  WorkflowTaskDefinition
} from '@savvy/workflow-definition';
import { RoleId } from '@savvy/ui';
// import { AddEditWorkflowStateDialogComponent } from '../add-edit-workflow-state-dialog/add-edit-workflow-state-dialog.component';
// import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';
// import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { MatSidenav } from '@angular/material/sidenav';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-workflow-details-modal',
  templateUrl: './workflow-details-modal.component.html',
  styleUrls: ['./workflow-details-modal.component.scss']
})
export class WorkflowDetailsModalComponent implements OnInit {
  @ViewChild('sidePanel', { static: true }) sidePanel: MatSidenav;
  response: ListWorkflowDefinitionsForEditDto;
  workflowDefinitionId;
  loadWorkflowDefinitionForEditResponse: LoadWorkflowDefinitionForEditResponse;
  selectedTaskDef: WorkflowTaskDefinition;
  selectedStateId: string;
  workflowTypeLabel = '';
  selectedWorkflowState: WorkflowState;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<WorkflowDetailsModalComponent>,
    private workflowdefService: WorkflowdefService
  ) { }

  ngOnInit() {
    if (this.data.contextIdDto) {
      this.workflowdefService.loadWorkflowMessageDefsByType(this.data.contextIdDto.contextId, this.data.type || 'APPOINTMENT')
        .subscribe(res => {
          this.workflowDefinitionId = res.workflowDefinitionId?.id;
          this.loadWorkflow();
        });
    }
  }

  loadWorkflow() {
    console.log('Loading workflow with ', this.workflowDefinitionId);
    this.workflowdefService.getWorkflowDefsByIdsForEdit(
      this.data.contextIdDto.contextId,
      this.data.contextIdDto.contextIdType,
      [this.workflowDefinitionId]).subscribe(response => {
        if (response) {
          this.response = response;
          console.log('got response');
          if (response.workflowDefinitionForEditResponses && response.workflowDefinitionForEditResponses.length) {
            this.loadWorkflowDefinitionForEditResponse = response.workflowDefinitionForEditResponses[0];
            this.selectedWorkflowState = this.getState(this.selectedStateId);
          } else {
            console.log('unable to init as no workflowDefinitionForEditResponses in', response);
          }
        }
      });
  }

  getState(stateId: string): WorkflowState {
    let workflowState: WorkflowState = null;
    if (stateId) {
      const loadWorkflowStateResponse: LoadWorkflowStateResponse =
        this.loadWorkflowDefinitionForEditResponse.loadWorkflowStateResponseList.find(v => v.workflowState.id === stateId);
      if (loadWorkflowStateResponse) {
        workflowState = loadWorkflowStateResponse.workflowState;
      }
    }
    return workflowState;
  }

  initialise(taskDef: WorkflowTaskDefinition) {
    if (!taskDef.taskSettings) {
      taskDef.taskSettings = <TaskSettings>{};
      taskDef.taskSettings.taskAssignToRoles = <RoleId>{};
    }
    if (!taskDef.postTaskDefinition) {
      console.log('initialising post task def');
      taskDef.postTaskDefinition = <any>{};
    }
    if (!taskDef.autoTaskDefinition) {
      taskDef.autoTaskDefinition = <any>{};
    }
    if (!taskDef.manualTaskDefinition) {
      taskDef.manualTaskDefinition = <any>{};
    }
    if (!taskDef.actionDefinition) {
      taskDef.actionDefinition = <ActionDefinition>{};
      taskDef.actionDefinition.actionType = ActionDefinition.ActionTypeEnum.SendMessage;
      taskDef.actionDefinition.sendMessageDefinition = <SendMessageDefinition>{};
    }
  }

  addTaskToStateClick(state: LoadWorkflowStateResponse, actionType?: ActionDefinition.ActionTypeEnum) {
    this.selectedWorkflowState = state.workflowState;
    this.selectedStateId = state.workflowState.id;
    this.selectedTaskDef = <WorkflowTaskDefinition>{};
    this.initialise(this.selectedTaskDef);
    if (actionType) {
      console.log('Now setting action type', actionType);
      this.selectedTaskDef.actionDefinition.actionType = actionType;
      if (actionType === ActionDefinition.ActionTypeEnum.SendSms) {
        console.log('Initialising sms definition', actionType);
        this.selectedTaskDef.actionDefinition.sendSmsDefinition = <SendSmsDefinition>{};
        this.selectedTaskDef.actionDefinition.sendSmsDefinition.message = '';
        this.selectedTaskDef.actionDefinition.sendSmsDefinition.links = <Links>{};

      }
    }
    this.sidePanel.open();
  }

  onTaskSelect(taskDef: WorkflowTaskDefinition, state: LoadWorkflowStateResponse) {
    this.selectedWorkflowState = state.workflowState;
    this.selectedStateId = state.workflowState.id;
    this.selectedTaskDef = taskDef;
    this.initialise(this.selectedTaskDef);
    this.sidePanel.open();
  }

  public colorInversionByHexString(colorString: string) {
    if (!colorString) {
      return '#000000';
    }

    colorString = colorString.replace('#', '');
    if (colorString.length == 6) {
      const r = parseInt(colorString.substring(0, 2), 16);
      const g = parseInt(colorString.substring(2, 4), 16);
      const b = parseInt(colorString.substring(4, 6), 16);
      const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
      return (yiq >= 128) ? '#000000' : '#FFFFFF';
    } else {
      return '#000000';
    }
  }

  taskRemoved() {
    this.loadWorkflow();
  }

  loadWorkflowState() {
    this.loadWorkflow();
  }

}
