import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
// import { EventBusService } from '../EventBusService';
import { ContextIdDto } from '@savvy/entity-instance-composite';

import { ConsumerService, ServicesService } from '@savvy/services';
import { ReplaySubject, Subject } from 'rxjs';
import { EntityDefinitionId } from '@savvy/view-definition';
// import { AddServiceDialogComponent } from '../fieldinstance/addServiceDialog.component';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { EventBusService } from '../../../element/EventBusService';
import { AddServiceDialogComponent } from '../../../element/fieldinstance/addServiceDialog.component';
import { CreateAppointmentV2SyncService } from '../create-appointment-v2-sync.service';
import { Tax, TaxDto } from '@savvy/tax';
import { CreateServiceComponent } from 'src/app/flo/shared/components/setup/services/create-service/create-service.component';
import { DatalistService } from '@savvy/datalist';
import { DataShareService } from 'src/app/core/data-share.service';
import { ServicesHelperService } from 'src/app/flo/shared/services/services-helper.service';
import { TaxesHelperService } from 'src/app/flo/shared/services/taxes-helper.service';
// import { StaffSelectorComponent } from './petServiceUserSelector.component';

@Component({
  selector: 'app-service-selector-v2',
  templateUrl: 'service-selector-v2.component.html'
})
export class ServiceSelectorV2Component implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() eventBus: EventBusService;
  @Input() showPlaceholder: boolean;
  @Input() selectedService: ConsumerService;
  @Input() disabled = false;

  @Output() serviceSelectionChanged: EventEmitter<ConsumerService> = new EventEmitter();

  serviceGroups = [];
  taxDefs: Tax[] = [];
  public filteredServices: ReplaySubject<ConsumerService[]> = new ReplaySubject<ConsumerService[]>(1);
  private destroy$ = new Subject();


  constructor(
    private dialog: MatDialog,
    private datalistService: DatalistService,
    private taxesHelperService: TaxesHelperService,
    public servicesHelperService: ServicesHelperService,
    public createAppointmentV2SyncService: CreateAppointmentV2SyncService,
  ) {

  }

  ngOnInit(): void {
    if (this.contextIdDto) {
      this.taxesHelperService.getTaxDefs(this.contextIdDto).then(res => {
        this.taxDefs = res;
      });
      this.servicesHelperService.getConsumerServices(this.contextIdDto, true, this.createAppointmentV2SyncService.editMode).then(consumerServices => {
        if (consumerServices && consumerServices.length) {
          if (!this.selectedService) {
            this.selectedService = consumerServices && consumerServices.length ? consumerServices[0] : undefined;
            this.serviceSelectionChanged.emit(this.selectedService);
          } else {
            consumerServices = consumerServices.filter(cs => !cs.deleted || (cs.deleted && cs.id === this.selectedService.id));
          }
          this.filteredServices.next(consumerServices);
        }
      });
      this.getServiceGroups();
    }
  }

  getServiceGroups() {
    this.datalistService.getDataListByName(this.contextIdDto.contextId, this.contextIdDto.contextIdType, 'ServiceGroup').subscribe(res => {
      this.serviceGroups = res.dataItems;
    });
  }

  serviceChanged(event) {
    if (event.value) {
      this.serviceSelectionChanged.emit(event.value);
    }
  }

  filterServices(value: string) {
    this.servicesHelperService.getConsumerServices(this.contextIdDto).then(consumerServices => {
      if (!consumerServices) {
        return;
      }
      // get the search keyword
      if (!value) {
        this.filteredServices.next(consumerServices.slice());
        return;
      }
      const search = value.toLowerCase();
      // filter the consumerServices
      this.filteredServices.next(consumerServices.filter(serviceDto => serviceDto?.serviceName.toLowerCase().indexOf(search) > -1));
    });
  }

  createNewService() {
    const dialogRef = this.dialog.open(CreateServiceComponent, {
      width: '550px',
      panelClass: ['scrollable-modal', 'helpwindow'],
      data: {
        serviceGroups: this.serviceGroups,
        taxDefs: this.taxDefs,
        contextIdDto: this.contextIdDto
      }
    });

    dialogRef.afterClosed().subscribe((serviceNew) => {
      if (serviceNew) {
        console.log('The service is saved');
        this.servicesHelperService.getConsumerServices(this.contextIdDto, true).then(newServices => {
          this.loadAndSetService(serviceNew, newServices);
        });
      }
    });
  }

  createService(serviceEntityDefinitionId: EntityDefinitionId) {
    const dialogRef = this.dialog.open(AddServiceDialogComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        entityDefinitionId: serviceEntityDefinitionId.id,
        entityDefinitionLabel: 'Service',
        eventBus: this.eventBus
      }
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this.destroy$)).subscribe(result => {
        console.log('back from dialog close');
        if (result && result.entityInstanceId) {
          console.log('got a new instance to link to ', result);
          this.servicesHelperService.getConsumerServices(this.contextIdDto, true).then(newServices => {
            this.loadAndSetService(result.service, newServices);
          });
        }
      });
  }

  loadAndSetService(service: ConsumerService, consumerServices: ConsumerService[]) {
    console.log('loading new services');
    if (consumerServices && consumerServices.length) {
      this.filteredServices.next(consumerServices);
      const newService = consumerServices.find(s => s?.serviceName === service?.serviceName);
      setTimeout(() => {
        this.selectedService = newService;
        this.serviceSelectionChanged.emit(service);
      }, 500);
    }
  }

  compareObjects(o1: ConsumerService, o2: ConsumerService): boolean {
    if (o1 && o2) {
      return o1.id === o2.id;
    }
    return false;
  }
}

