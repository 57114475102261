import {Component, Input, ViewChild} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ConfService} from '../../config/conf.service';
import {ContextIdDto, ElementInstanceDto, FieldDefinitionDto} from '@savvy/entity-instance-composite';

@Component({
    selector: 'app-video-field-instance',
    templateUrl: 'videoFieldInstance.component.html'
})
export class VideoFieldInstanceComponent {

    @Input() videoFieldDefinition: FieldDefinitionDto;
    @Input() elementInstanceDto: ElementInstanceDto;
    @Input() entityInstanceId: string;
    @Input() contextIdDto: ContextIdDto;
    @Input() form: UntypedFormGroup;
    @Input() showPlaceholder: boolean;

    @ViewChild('videoPlayer', { static: true }) videoplayer: any;

    constructor() {
    }

    toggleVideo() {
        this.videoplayer.nativeElement.play();
    }

    videoUrl() {
        if (this.elementInstanceDto.fieldInstanceDto.value.videoFieldValueDto) {
            console.log('ref is ' + this.elementInstanceDto.fieldInstanceDto.value.videoFieldValueDto.fileReferenceId);
            return ConfService.apiUrl() + '/rest/storage?contextId=' + this.contextIdDto.contextId +
                '&contextIdType=' + this.contextIdDto.contextIdType +
                '&id=' + this.elementInstanceDto.fieldInstanceDto.value.videoFieldValueDto.fileReferenceId;
        }
        return '';
    }
}

