import { Injectable } from '@angular/core';
import { FormArray, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { DateUtils } from '../dates/DateUtils';
import {
  DaateFieldDefinitionDto,
  DaateTimeFieldDefinitionDto,
  ElementDto,
  ElementInstanceDto,
  FieldDefinitionDto,
  FieldInstanceDto,
  FieldValueDto,
  IconsFieldDefinitionDto,
  LinkedCustomerDefinitionDto,
  LinkedEntityDefinitionDto,
  SelectFieldDefinitionDto,
  TimeFieldDefinitionDto,
  WorkflowFieldDefinitionDto
} from '@savvy/view-composite';
import { DataShareService, ElementType, ValueType } from 'src/app/core/data-share.service';
import { EntityDefinitionDto } from '@savvy/entity-definition';

//  All this service really does is construct a formGroup like this: group[elementInstanceDto.instanceId] =
//  return new UntypedFormControl(fieldInstance.value.dateTimeValue, Validators.required);
//  You could argue is the server returned a flattened Map<elementInstanceId, <value, required>> this would be much faster and simpler.
//  The only reason we actually wrap the whole thing in a form is so we can validate the form.

@Injectable()
export class ElementControlService {
  ValueTypeEnum: any = {};
  constructor(
    private dateUtils: DateUtils,
    private dataShareService: DataShareService
  ) {
  }

  emptyFormGroup() {
    const group: any = {};
    return new UntypedFormGroup(group);
  }

  toFormGroup(elementDto: ElementDto, entityDefinitionDto: EntityDefinitionDto) {

    const group: any = {};

    if (entityDefinitionDto) {
      this.addElement(group, elementDto, entityDefinitionDto);
    } else {
      // console.log('Error null entity def passed');
    }
    return new UntypedFormGroup(group);
  }

  public addElementInstance(group: any, elementInstanceDto: ElementInstanceDto, entityDefinitionDto: EntityDefinitionDto) {

    if (!elementInstanceDto) {
      return;
    }
    console.log('inside getElementInstance method ' + elementInstanceDto.elementDefinitionId
      + ' type ' + elementInstanceDto.elementType);

    // inside getElementInstance method quantity type FIELD_INSTANCE
    if (elementInstanceDto.elementType === ElementType.FieldInstance) {
      this.addFieldInstance(group, elementInstanceDto, entityDefinitionDto);
    } else if (elementInstanceDto.elementType === ElementType.ElementSetValue) {
   //   console.log('value elementSetValueDto is ' + elementInstanceDto.elementSetValueDto);

      if (elementInstanceDto.elementSetValueDto) {
     //   console.log('elementInstanceDto.value.elementSetValueDto.elementSetRowDtos is ' +
        //  elementInstanceDto.elementSetValueDto.elementSetRowDtos.length);

        elementInstanceDto.elementSetValueDto.elementSetRowDtos.forEach(row => {
          row.elementInstanceDtos.forEach(rowElementInstanceDto => {
            this.addElementInstance(group, rowElementInstanceDto, entityDefinitionDto);
          });
        });
      }
    } else {
      console.log('unknown element type ' + elementInstanceDto.elementType);
    }
    // //console.log('returning from addElementInstance method ' +   elementInstanceDto.elementDefinitionId
    //  + ' type ' + elementInstanceDto.elementType);
  }

  public addFieldInstance(group: any, elementInstanceDto: ElementInstanceDto, entityDefinitionDto) {

  //  console.log('inside addFieldInstance instance id ' + elementInstanceDto.instanceId + ' element def id '
   //   + elementInstanceDto.elementDefinitionId);

    const def = this.getEntityDefinition(elementInstanceDto.elementDefinitionId, entityDefinitionDto);

    if (!def || !def.elementType) {
      console.log('failed to get element type' + def);
      return;
    }
    switch (def.elementType) {
      case ElementType.Input:
        group[elementInstanceDto.instanceId] = this.getFieldEntity(def.fieldDefinitionDto, elementInstanceDto.fieldInstanceDto);
        break;
      case ElementType.Location:
        group[elementInstanceDto.instanceId] = this.getFieldEntity(def.fieldDefinitionDto, elementInstanceDto.fieldInstanceDto);
        break;
      case ElementType.IntegerValue:
        group[elementInstanceDto.instanceId] = this.getFieldEntity(def.fieldDefinitionDto, elementInstanceDto.fieldInstanceDto);
        break;
      case ElementType.Icons:
        group[elementInstanceDto.instanceId] = this.getIconsFieldEntity(def.iconsFieldDefinitionDto, elementInstanceDto.fieldInstanceDto);
        break;
      case ElementType.BigDecimalValueDefinition:
        group[elementInstanceDto.instanceId] = this.getFieldEntity(def.fieldDefinitionDto, elementInstanceDto.fieldInstanceDto);
        break;
      case ElementType.IntegerValueDefinition:
        group[elementInstanceDto.instanceId] = this.getFieldEntity(def.fieldDefinitionDto, elementInstanceDto.fieldInstanceDto);
        break;
      case ElementType.ColourValueDefinition:
        group[elementInstanceDto.instanceId] = this.getFieldEntity(def.fieldDefinitionDto, elementInstanceDto.fieldInstanceDto);
        break;
      case ElementType.BooleanValue:
        group[elementInstanceDto.instanceId] = this.getFieldEntity(def.fieldDefinitionDto, elementInstanceDto.fieldInstanceDto);
        break;
      case ElementType.TextArea:
        group[elementInstanceDto.instanceId] = this.getFieldEntity(def.fieldDefinitionDto, elementInstanceDto.fieldInstanceDto);
        break;
      case ElementType.CheckBox:
        group[elementInstanceDto.instanceId] = this.getFieldEntity(def.fieldDefinitionDto, elementInstanceDto.fieldInstanceDto);
        break;
      case ElementType.Date:
        group[elementInstanceDto.instanceId] = this.getDateEntity(def.daateFieldDefinitionDto, elementInstanceDto.fieldInstanceDto);
        break;
      case ElementType.DateTime:
        group[elementInstanceDto.instanceId] =
          this.getDatetimeEntity(def.daateTimeFieldDefinitionDto, elementInstanceDto.fieldInstanceDto);
        // Set time field
        group[elementInstanceDto.instanceId + 'time'] = this.getDatetimeTimeEntity(def.daateTimeFieldDefinitionDto,
          elementInstanceDto.fieldInstanceDto);
        break;
      case ElementType.Time:
        group[elementInstanceDto.instanceId] = this.getTimeEntity(def.timeFieldDefinitionDto, elementInstanceDto.fieldInstanceDto);
        break;
      case ElementType.Select:
        group[elementInstanceDto.instanceId] = this.getSelectEntity(def.selectFieldDefinitionDto, elementInstanceDto.fieldInstanceDto);
        break;
      case ElementType.WorkflowFieldDefinition:
        group[elementInstanceDto.instanceId] =
          this.getWorkflowEntity(def.workflowFieldDefinitionDto, elementInstanceDto.fieldInstanceDto);
        group[elementInstanceDto.instanceId + 'wfd'] =
          this.getWorkflowDefEntity(def.workflowFieldDefinitionDto, elementInstanceDto.fieldInstanceDto);
        break;
      case ElementType.LinkedEntity:
        group[elementInstanceDto.instanceId] = this.getLinkedEntity(def.linkedEntityDefinitionDto, elementInstanceDto);
        break;
      case ElementType.LinkedCustomerDefinition:
        group[elementInstanceDto.instanceId] = this.getLinkedCustomer(def.linkedCustomerDefinitionDto, elementInstanceDto);
        break;
      case ElementType.BooleanValueDefinition:
        group[elementInstanceDto.instanceId] = this.getFieldEntity(def.fieldDefinitionDto, elementInstanceDto.fieldInstanceDto);
        break;
      case ElementType.ListContractsDefinition:
        // console.log('got contract field def');
        group[elementInstanceDto.instanceId] = this.getContractField(def.listContractsDefinition, elementInstanceDto);
        break;
      case ElementType.Signature:
        // console.log('got signature');
        group[elementInstanceDto.instanceId] = this.getFieldEntity(def.fieldDefinitionDto, elementInstanceDto.fieldInstanceDto);
        break;

      default:
        console.log('unknown  element type ' + def.elementType);
    }
  }

  public getElementInstanceValue(elementType: ElementType, elementInstanceDto: ElementInstanceDto) {

    if (elementType === ElementType.IntegerValue) {
      return elementInstanceDto.fieldInstanceDto.value.intValue;
    } else if (elementType === ElementType.BigDecimalValueDefinition) {
      return elementInstanceDto.fieldInstanceDto.value.bigDecimal;
    } else if (elementType === ElementType.TextArea) {
      return elementInstanceDto.fieldInstanceDto.value.strValue;
    } else if (elementType === ElementType.CheckBox) {
      return elementInstanceDto.fieldInstanceDto.value.booleanValue;
    } else if (elementType === ElementType.Date) {
      return elementInstanceDto.fieldInstanceDto.value.dateValue;
    } else if (elementType === ElementType.DateTime) {
      return elementInstanceDto.fieldInstanceDto.value.dateTimeValue;
    } else if (elementType === ElementType.Time) {
      return elementInstanceDto.fieldInstanceDto.value.timeValue;
    } else if (elementType === ElementType.Select) {
      return elementInstanceDto.fieldInstanceDto.value.strValue;
    } else if (elementType === ElementType.WorkflowFieldDefinition) {
      return elementInstanceDto.fieldInstanceDto.value.workflowFieldValue;
    } else if (elementType === ElementType.LinkedEntity) {
      return elementInstanceDto.fieldInstanceDto.value.strArrayValue;
    } else if (elementType === ElementType.StringValue) {
      return elementInstanceDto.fieldInstanceDto.value.strValue;
    } else {
      console.log('ERROR: unknown type ' + elementType);
      return '';
    }
  }



  public handleChangedElements(changedElementList, form: UntypedFormGroup) {

    if (!changedElementList) {
      return;
    }

    //   //console.log('number of change elements ' + changedElementList.length);
    changedElementList.forEach(changedElement => {

      //   //console.log('changed element is of type ' + changedElement.elementInstance.elementType);
      //   //console.log('changed element id is ' + changedElement.elementInstance.instanceId);

      const result = this.getElementInstanceValue(changedElement.elementDefType, changedElement.elementInstance);
      //   //console.log('result ' + result);
      // We need to stop event propogation here

      const eleInst = form.get(changedElement.elementInstance.instanceId);
      if (eleInst) {
        const currValue = eleInst.value;
        //   //console.log('existing value is ' + currValue);
        const changedElementInstanceId = changedElement.elementInstance.instanceId;


        // Why do we only patch woprkflow straight away?
        // if (changedElement.elementDefType ===
        //   ChangedElementDto.ElementDefTypeEnum.WORKFLOWFIELDDEFINITION) {
        //   //console.log('workflow def id has been set to ' +
        //     changedElement.elementInstance.fieldInstanceDto.value.workflowFieldValue.workflowDefinitionId);
        //   form.controls[changedElement.elementInstance.instanceId + 'wfd'].patchValue(
        //     changedElement.elementInstance.fieldInstanceDto.value.workflowFieldValue.workflowDefinitionId, {
        //       onlySelf: true,
        //       // We want to emit an event as we want the workflow state list to be refreshed in worlkflow field instance
        //       emitEvent: true
        //     });


        //   // form.controls[changedElement.elementInstance.instanceId + 'wfd'].c = true;
        //   // form.controls[changedElement.elementInstance.instanceId + 'wfd'].updateValueAndValidity();
        // } else if (changedElement.elementDefType === ChangedElementDto.ElementDefTypeEnum.DATETIME) {

        //   const timeStr = this.dateUtils.getTimeAsString(changedElement.elementInstance.fieldInstanceDto.value.dateTimeValue);

        //   form.controls[changedElement.elementInstance.instanceId + 'time'].patchValue(
        //     timeStr, {
        //       onlySelf: true,
        //       // We want to emit an event as we want the workflow state list to be refreshed in worlkflow field instance
        //       emitEvent: true
        //     });

        // }


        // We only set the value for now if it is NOT a WORKFLOW FIELD.  Why?
        // Well, what happens is, the value is set, but the forms valid state is not updated.  So the create button does not enable.

        // This is not an issue for 'end date' field right now, as this is not a mandatory field.
        // This is a PROBLEM though that needs fixing.  If we take the setTimeout we get EpressionChangeAfter... error
        setTimeout(() => {
          if (form.controls[changedElement.elementInstance.instanceId]) {
            //    //console.log('patching value on element ' + changedElement);
            form.controls[changedElement.elementInstance.instanceId].patchValue(result, {
              onlySelf: true,
              emitEvent: true
            });
          } else {
            //       //console.log('not patching value as it is does not exist');
          }
        });
      }
    });

  }

  private addElement(group: any, elementDto, entityDefinitionDto) {
    if (!elementDto || !elementDto.elementType) {
      console.log('ERROR: GOT elementDto with no type', elementDto);
      return;
    }
    // console.log('element id is ' + elementDto.id);
    console.log('element type in addElement is ' + elementDto.elementType);
    if (elementDto.elementType === ElementType.ElementInstance) {
      // //console.log('got a element instance ' + elementDto.elementInstanceDto);

      if (elementDto.elementInstanceDto) {
        // //console.log('got a element instance id is ' + elementDto.elementInstanceDto.elementId);
        this.addElementInstance(group, elementDto.elementInstanceDto, entityDefinitionDto);
      } else {
        // //console.log('ERROR: got an element instance name ' + elementDto.name);
      }
    } else if (elementDto.elementType === ElementType.LineDefinition) {
      // // //console.log('getting line def');
      elementDto.lineDefinitionDto.lineEntryDtos.forEach(lineEntry => {
        this.addElement(group, lineEntry, entityDefinitionDto);
      });
    } else if (elementDto.elementType === ElementType.SplitPanel) {
      // // //console.log('getting split panel element');

      this.addElement(group, elementDto.splitPanelDefinitionDto.left, entityDefinitionDto);
      this.addElement(group, elementDto.splitPanelDefinitionDto.right, entityDefinitionDto);

    } else if (elementDto.elementType === ElementType.TabPanel) {
      elementDto.tabPanelDefinitionDto.tabDefinitionDtos.forEach(tab => {
        if (tab.enabled && tab.tabBody) {
          this.addElement(group, tab.tabBody, entityDefinitionDto);
        }
      });
    } else if (elementDto.elementType === ElementType.VerticalPanel) {
    //   console.log('getting vertical panel');
      elementDto.verticalPanelDefinitionDto.elementDtos.forEach(vpElement => {
        this.addElement(group, vpElement, entityDefinitionDto);
      });
    } else if (elementDto.elementType === ElementType.StepPanelDefinition) {
      //    //console.log('getting step panel');
      elementDto.stepPanelDto.stepDefinitionDtos.forEach(stepDefinitionDto => {
        stepDefinitionDto.elementDtos.forEach(sdElement => {
          this.addElement(group, sdElement, entityDefinitionDto);
        });
      });
    } else if (elementDto.elementType === ElementType.HorizontalPanel) {
      // // //console.log('getting vertical panel');
      elementDto.horizontalPanelDefinitionDto.elementDtos.forEach(vpElement => {
        this.addElement(group, vpElement, entityDefinitionDto);
      });


    } else if (elementDto.elementType === ElementType.CardDefinition) {
      elementDto.cardDefinitionDto.cardContentDefinitionDto.elementDtos.forEach(vpElement => {
        this.addElement(group, vpElement, entityDefinitionDto);
      });
    } else if (elementDto.elementType === ElementType.Section) {
      // // //console.log('getting section');

      elementDto.sectionDefinitionDto.sectionElements.forEach(sectionEl => {
        this.addElement(group, sectionEl, entityDefinitionDto);
      });
    } else if (elementDto.elementType === ElementType.SectionHeader) {
      this.addElement(group, elementDto.sectionHeaderDefinitionDto.headerLine, entityDefinitionDto);
      this.addElement(group, elementDto.sectionHeaderDefinitionDto.subHeaderLine, entityDefinitionDto);

    } else if (elementDto.elementType === ElementType.InvoiceServicesPanelData) {
      console.log('YES FOUND INVOICE_SERVICES_PANEL_DATA');
      if (elementDto.invoiceServicesPanelDataDto.angularComponentVersion === 2) {
        if (!group['invoiceServiceRows']) {
          if (elementDto.invoiceServicesPanelDataDto.allowMultipleServices) {
            group['invoiceServiceRows'] = new FormArray([]);
          } else {
            group['invoiceServicePanel'] = new UntypedFormControl('', Validators.required);
          }
        }
      } else {
        group['invoiceServicePanel'] = new UntypedFormControl('', Validators.required);
      }
    } else if (elementDto.elementType === ElementType.InvoicePackagePanelDataDto) {
      console.log('YES FOUND INVOICE_PACKAGES_PANEL_DATA');
      group['invoicePackagePanel'] = new UntypedFormControl('', Validators.required);
    } else if (elementDto.elementType === ElementType.ToolBar) {
      // Nothing to add here, it is just a label
    } else {
      console.log('Unknown group element type ' + elementDto.elementType);
    }
  }

  private getEntityDefinition(elementDefId: string, entityDefinitionDto): ElementDto {
    let retElementDef = null;
    // //console.log('getEntityDefinition got entityDefinitionDto ' + entityDefinitionDto);
    if (entityDefinitionDto) {
      entityDefinitionDto.elementDtoList.forEach(elementDef => {
        const retVal = this.getDefinitionFromDef(elementDefId, elementDef);

        // //console.log('got ' + retVal + ' from elementDef ' + elementDef.name);
        if (retVal) {
          retElementDef = retVal;
        }
      });
    } else {
      // console.log('ignoring as it is null');
    }
    return retElementDef;
  }

  private getDefinitionFromDef(elementDefId: string, elementDef: ElementDto): ElementDto {

    let retDef: ElementDto = null;
    if (elementDef.id === elementDefId) {
      retDef = elementDef;
    } else {
      if (elementDef.elementSetDefinitionDto) {
        // //console.log('found an element set def');
        elementDef.elementSetDefinitionDto.elementDtos.forEach(elementDef2 => {
          const retDef2 = this.getDefinitionFromDef(elementDefId, elementDef2);

          // //console.log('got retDef2 ' + retDef2 + ' from element def ' + elementDef2.name);
          if (retDef2 != null) {
            retDef = retDef2;
          }
        });
      }
    }
    return retDef;
  }

  private getSelectEntity(selectDefinition: SelectFieldDefinitionDto, fieldInstance: FieldInstanceDto) {

    //     //console.log('inside getSelectEntity def ' + selectDefinition.name + ' with id ' +
    //      selectDefinition.id + ' fieldInstance ' + fieldInstance);

    if (selectDefinition.required) {
      return new UntypedFormControl(fieldInstance.value.strValue, Validators.required);
    } else {
      return new UntypedFormControl(fieldInstance.value.strValue);
    }
  }

  private getWorkflowEntity(workflowDefinition: WorkflowFieldDefinitionDto, fieldInstance: FieldInstanceDto) {
    //    //console.log('inside get workflow def ' + workflowDefinition.name + ' with id ' +
    //       workflowDefinition.id + ' fieldInstance ' + fieldInstance);
    let id = '';
    if (fieldInstance.value.workflowFieldValue.workflowDefinitionId) {
      id = fieldInstance.value.workflowFieldValue.workflowDefinitionId.id;
    }

    if (workflowDefinition.required) {
      return new UntypedFormControl(id, Validators.required);
    } else {
      return new UntypedFormControl(id);
    }
  }

  private getWorkflowDefEntity(workflowDefinition: WorkflowFieldDefinitionDto, fieldInstance: FieldInstanceDto) {
    //    //console.log('inside get workflow def ' + workflowDefinition.name + ' with id ' +
    //     workflowDefinition.id + ' fieldInstance ' + fieldInstance);
    return new UntypedFormControl(fieldInstance.value.workflowFieldValue.workflowDefinitionId);
  }

  private getDateEntity(dateDefinition: DaateFieldDefinitionDto, fieldInstance: FieldInstanceDto) {

    if (dateDefinition.required) {
      // // //console.log('building date ' + dateDefinition.name + ' with id ' + dateDefinition.id);
      return new UntypedFormControl(fieldInstance.value.dateValue, Validators.required);
    } else {
      return new UntypedFormControl(fieldInstance.value.dateValue);
    }
  }



  private getDatetimeEntity(dateTimeDefinition: DaateTimeFieldDefinitionDto, fieldInstance: FieldInstanceDto) {

    // console.log('tags are :' + dateTimeDefinition.tags);
    if (dateTimeDefinition.required) {
      // // //console.log('building element def ' + dateTimeDefinition.name + ' with id ' + dateTimeDefinition.id);
      return new UntypedFormControl(fieldInstance.value.dateTimeValue, Validators.required);
    } else {
      return new UntypedFormControl(fieldInstance.value.dateTimeValue);
    }
  }

  private getDatetimeTimeEntity(dateTimeDefinition: DaateTimeFieldDefinitionDto, fieldInstance: FieldInstanceDto) {
    return new UntypedFormControl(this.dateUtils.getTimeAsString(fieldInstance.value.dateTimeValue));
  }

  private getTimeEntity(timeDefinition: TimeFieldDefinitionDto, fieldInstance: FieldInstanceDto) {


    // //console.log('building time def ' + timeDefinition.name + ' with id ' + timeDefinition.id
    // + ' value ' + fieldInstance.value.timeValue);
    if (timeDefinition.required) {
      return new UntypedFormControl(this.timeToDate(fieldInstance.value.timeValue), Validators.required);
    } else {

      // // //console.log('type is [' + Object.prototype.toString.call(elementInstance.value.timeValue) + ']');
      return new UntypedFormControl(this.timeToDate(fieldInstance.value.timeValue));
    }
  }

  private getLinkedEntity(linkedEntityDefinition: LinkedEntityDefinitionDto, elementInstance: ElementInstanceDto) {
    //    //console.log('getLinkedEntityy ' + linkedEntityDefinition);

    //   //console.log('building linked entity ' + linkedEntityDefinition.name + ' with id ' + linkedEntityDefinition.id);

    if (linkedEntityDefinition.required) {
      return new UntypedFormControl({
        values: elementInstance.fieldInstanceDto.value.strArrayValue, disabled:
          !elementInstance.elementInstanceViewDto.editable
      }, Validators.required);
    } else {
      return new UntypedFormControl(
        {
          values: elementInstance.fieldInstanceDto.value.strArrayValue, disabled:
            !elementInstance.elementInstanceViewDto.editable
        });
    }
  }

  private getLinkedCustomer(linkedCustomerDefinition: LinkedCustomerDefinitionDto, elementInstance: ElementInstanceDto) {
    // console.log('getLinkedCustomer elementInstance is [' + elementInstance.fieldInstanceDto.value.strValue + ']');

    //   //console.log('building linked entity ' + linkedEntityDefinition.name + ' with id ' + linkedEntityDefinition.id);

    if (linkedCustomerDefinition.required) {
      return new UntypedFormControl({
        value: elementInstance.fieldInstanceDto.value.strValue, disabled:
          !elementInstance.elementInstanceViewDto.editable
      }, Validators.required);
    } else {
      return new UntypedFormControl(
        {
          value: elementInstance.fieldInstanceDto.value.strValue, disabled:
            !elementInstance.elementInstanceViewDto.editable
        });
    }
  }

  private getContractField(contractFieldDefinition, elementInstance: ElementInstanceDto) {
    // console.log('getContractField elementInstance is [' + elementInstance.fieldInstanceDto.value.strValue + ']');

    //   //console.log('building linked entity ' + linkedEntityDefinition.name + ' with id ' + linkedEntityDefinition.id);

    if (contractFieldDefinition.required) {
      return new UntypedFormControl({
        value: elementInstance.fieldInstanceDto.value.strArrayValue, disabled:
          !elementInstance.elementInstanceViewDto.editable
      }, Validators.required);
    } else {
      return new UntypedFormControl(
        {
          value: elementInstance.fieldInstanceDto.value.strArrayValue, disabled:
            !elementInstance.elementInstanceViewDto.editable
        });
    }
  }

  private getFieldEntity(fieldDefinition: FieldDefinitionDto, fieldInstance: FieldInstanceDto) {

    //  //console.log('inside getFieldEntity ' + fieldDefinition.name + ' with definition id ' +
    //   fieldDefinition.id + ' elementInstance ' + fieldInstance);

    if (fieldInstance == null) {
      // //console.log('ERROR: got field definition ' + fieldDefinition.name + ' with a NULL element instance ');
      return new UntypedFormControl('');
    }
    if (fieldInstance.value == null) {
      // //console.log('ERROR: got field definition ' + fieldDefinition.name + ' with a NULL value instance ');
      return new UntypedFormControl('');
    }
    if (fieldDefinition.required) {
      return new UntypedFormControl(this.getValue(fieldInstance), Validators.required);
    } else {
      return new UntypedFormControl(this.getValue(fieldInstance));
    }
  }

  private getIconsFieldEntity(iconsFieldDefinition: IconsFieldDefinitionDto, fieldInstance: FieldInstanceDto) {

    //  //console.log('inside getFieldEntity ' + fieldDefinition.name + ' with definition id ' +
    //   fieldDefinition.id + ' elementInstance ' + fieldInstance);

    if (fieldInstance == null) {
      // //console.log('ERROR: got field definition ' + fieldDefinition.name + ' with a NULL element instance ');
      return new UntypedFormControl('');
    }
    if (fieldInstance.value == null) {
      // //console.log('ERROR: got field definition ' + fieldDefinition.name + ' with a NULL value instance ');
      return new UntypedFormControl('');
    }
    if (iconsFieldDefinition.required) {
      return new UntypedFormControl(this.getValue(fieldInstance), Validators.required);
    } else {
      return new UntypedFormControl(this.getValue(fieldInstance));
    }
  }

  private getValue(fieldInstance: FieldInstanceDto) {
    /*
      BOOLEAN = <any> 'BOOLEAN',
      STRING = <any> 'STRING',
      DATE = <any> 'DATE',
      DATETIME = <any> 'DATE_TIME',
      TIME = <any> 'TIME',
      INTEGER = <any> 'INTEGER',
    */
  //  console.log('value type ', fieldInstance.value.valueType);
    if (fieldInstance.value.valueType === FieldValueDto.ValueTypeEnum.Integer) {
     //  console.log('returning int value ' + fieldInstance.value.intValue);
      return fieldInstance.value.intValue;
    }
    if (fieldInstance.value.valueType === FieldValueDto.ValueTypeEnum.BigDecimal) {
    //   console.log('returning int value ' + fieldInstance.value.bigDecimal);
      return fieldInstance.value.bigDecimal;
    }
    if (fieldInstance.value.valueType === FieldValueDto.ValueTypeEnum.StringArray) {
   //    console.log('returning strArrayValue value ' + fieldInstance.value.strArrayValue);
      return fieldInstance.value.strArrayValue;
    }
    if (fieldInstance.value.valueType === FieldValueDto.ValueTypeEnum.Boolean) {
    //  console.log('returning boolean value ' + fieldInstance.value.booleanValue);
      return fieldInstance.value.booleanValue;
    }

  // console.log('returning str value ' + fieldInstance.value.strValue);
    return fieldInstance.value.strValue;
  }

  getFormValidationErrors(form: UntypedFormGroup) {
    // this.cd.detectChanges();
    // console.log('inside getFormValidationErrors');
    if (form && form.controls) {
      Object.keys(form.controls).forEach(key => {

        // //console.log('got key ' + key);
        const controlErrors: ValidationErrors = form.get(key).errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
            // console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          });
        } else {
          // console.log('no errors for ' + key);
        }
      });
    } else {
      // console.log('empty form passed in');
    }
  }
  timeToDate(time: string): Date {
    if (time) {
      // 09:17:21
      const date = new Date();
      const timeSplit = time.split(':');
      date.setHours(parseInt(timeSplit[0], 10));
      date.setMinutes(parseInt(timeSplit[1], 10));
      return date;
    }
    return null;
  }

  public findInvalidControls(form) {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
   //     console.log('form name is invalid ' + name);
        invalid.push(name);
      }
    }
    return invalid;
  }

  public debugControls(form) {
    const controls = form.controls;
    for (const name in controls) {
      //  console.log('form name is ' + name);
    }
  }

}
