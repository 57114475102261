/* eslint-disable @typescript-eslint/member-ordering */
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Pet, PetService } from '@savvy/pet';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppointmentHandlerService } from 'src/app/flo/appointments-v2/appointment-handler.service';
import { ConfService } from 'src/app/flo/config/conf.service';
import { ConfirmationDialogComponent } from 'src/app/flo/confirmation-dialog/confirmation-dialog.component';
import { PhoneNumberHelperService } from 'src/app/flo/shared/services/phone-number-helper.service';
import { UserCurrencyService } from 'src/app/flo/shared/services/userCurrency.service';
import { FloSnackbarComponent } from 'src/app/flo/snackbar/floSnackbar.component';
import { ContextService } from "../../../context.service";
const defalutCoverPicDog = '/assets/images/dog-cover.png';
const defalutCoverPicCat = '/assets/images/cat-cover.jpeg';
const defalutProfilePicDog = '/assets/images/dog-profile.png';
const defalutProfilePicCat = '/assets/images/cat-profile.webp';
import * as heic2any from 'heic2any';
import { NgxCroppedEvent, NgxPhotoEditorService } from 'ngx-photo-editor';
import { StorageService } from '@savvy/storage';
import { CalendarSetupService } from 'src/app/flo/welcome-to-savvy/calendar-setup.service';
import { ContextIdDto } from '@savvy/menu';

@Component({
  selector: 'app-view-pet-profile',
  templateUrl: './view-pet-profile.component.html',
  styleUrls: ['./view-pet-profile.component.scss']
})
export class ViewPetProfileComponent implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  private _pet: Pet;
  @Input() public get pet(): Pet {
    return this._pet;
  }
  public set pet(value: Pet) {
    this._pet = value;
    this.setCoverPic(value);
    this.setProfilePic(value);
  }

  @Output() loadAppointments: EventEmitter<any> = new EventEmitter();
  @Output() loadPet: EventEmitter<any> = new EventEmitter();
  coverPic = defalutCoverPicDog;
  profilePic = defalutProfilePicDog;
  currencyCode: string;

  destroy$ = new Subject();

  constructor(
    private sharedService: PhoneNumberHelperService,
    private appointmentHandlerService: AppointmentHandlerService,
    private petService: PetService,
    private notify: FloSnackbarComponent,
    private dialog: MatDialog,
    private userCurrencyService: UserCurrencyService,
    private ngxPhotoEditorService: NgxPhotoEditorService,
    public contextService: ContextService,
    private storage: StorageService,
    private calendarSetupService: CalendarSetupService

  ) { }

  loadCalendarSettings() {
    this.calendarSetupService.loadCalendarSettings(this.contextIdDto);
  }

  ngOnInit(): void {
    this.setCoverPic(this.pet);
    this.setProfilePic(this.pet);
    this.getCurrencyCode();
    this.loadCalendarSettings();
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes.pet.currentValue !== changes.pet.previousValue) {
  //     setTimeout(() => {
  //       this.setProfilePic();
  //       this.setCoverPic();
  //     }, 300);
  //   }
  // }

  setCoverPic(pet: Pet) {
    if (pet?.coverPicReference) {
      const coverPic = this.getUrl(pet?.coverPicReference);
      if (coverPic) {
        this.coverPic = coverPic;
      }
    } else {
      if (pet.petType === 'Cat') {
        this.coverPic = defalutCoverPicCat;
      } else {
        this.coverPic = defalutCoverPicDog;
      }
    }
  }

  setProfilePic(pet: Pet) {
    if (pet?.images && pet?.images.length > 0) {
      this.profilePic = this.getUrl(pet.images[0].fileReferenceId);
    } else if (pet?.profilePicReference) {
      const profilePic = this.getUrl(pet?.profilePicReference);
      if (profilePic) {
        this.profilePic = profilePic;
      }
    } else {
      if (pet.petType === 'Cat') {
        this.profilePic = defalutProfilePicCat;
      } else {
        this.profilePic = defalutProfilePicDog;
      }
    }
  }

  getUrl(reference: string) {
    if (reference) {
      return ConfService.apiUrl() + '/rest/storage?public=false&contextId=' +
        this.contextIdDto.contextId + '&contextIdType=' +
        this.contextIdDto.contextIdType + '&id=' + reference;
    }
    return null;
  }

  getCurrencyCode() {
    this.userCurrencyService.getDefaultCurrency(this.contextIdDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.currencyCode = res.org.currencyCode ? res.org.currencyCode : this.userCurrencyService.defaultCurrency;
      });
  }

  callback = (result) => {
    if (result) {
      this.loadAppointments.emit(result);
    }
  };

  createAppointment(pet) {
    this.appointmentHandlerService.createAppointment({ contextIdDto: this.contextIdDto, populatePet: pet, callback: this.callback,
    calendarSettings: this.calendarSetupService.calendarSettings });
  }

  unarchive(row: Pet) {
    const confirmDialog = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Re-activate',
        message: 'Are you sure you want to re-activate this pet?'
      },
      height: 'auto',
      width: '320px',
      panelClass: 'helpwindow'
    });
    confirmDialog.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.petService.undoDeletePet(
          row.id).subscribe(response => {
            this.notify.message = 'Pet re-activated successfully!';
            this.notify.open();
            this.loadPet.emit();
          });
      }
    });
  }

  profileImageCropped(event: NgxCroppedEvent) {
    if (event?.file) {
      this.postFile(event?.file, 'PROFILE');
    }
  }

  postFile(fileToUpload: File | undefined, imageFor: string) {
    this.storage.uploadFileToStorageV2(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      false,
      fileToUpload).subscribe(response => {
        console.log('uploadFileToStorageV2 got response ', response);
        if (imageFor === 'COVER') {
          this.coverPic = this.getUrl(response?.fileReference);
          this.pet.coverPicReference = response?.fileReference;
        } else if (imageFor === 'PROFILE') {
          this.profilePic = this.getUrl(response?.fileReference);
          this.pet.profilePicReference = response?.fileReference;
        }
        this.petService.updatePet(this.pet)
          .subscribe(res => {
            console.log('Pet profile updated');
          });
      });
  }

  async editProfilePic() {
    if (this.profilePic) {
      let response = await fetch(this.profilePic);
      let data = await response.blob();
      let file = new File([data], "test.png");
      this.ngxPhotoEditorService.open(file, {
        // aspectRatio: 4 / 3,
        roundCropper: true,
        autoCropArea: 100
      }).subscribe((croppedEvent: NgxCroppedEvent) => {
        this.profileImageCropped(croppedEvent);
      });
    }
  }

  coverImageCropped(event: NgxCroppedEvent) {
    if (event?.file) {
      this.postFile(event?.file, 'COVER');
    }
  }

  async editCover() {
    if (this.coverPic) {
      let response = await fetch(this.coverPic);
      let data = await response.blob();
      let file = new File([data], "test.png");
      this.ngxPhotoEditorService.open(file, {
        // aspectRatio: 4 / 3,
        roundCropper: true,
        autoCropArea: 100
      }).subscribe((croppedEvent: NgxCroppedEvent) => {
        this.coverImageCropped(croppedEvent);
      });
    }
  }

  handleProfileInput(event) {
    if (event.target.closest('datatable-body-cell')) {
      event.target.closest('datatable-body-cell').blur();
    }

    if (event.srcElement?.files?.length && event.srcElement?.files?.[0]?.name?.split('.')[1] === 'heic') {
      heic2any.default({ blob: event.srcElement?.files?.[0], toType: 'png' }).then((res: any) => {
        const list = new DataTransfer();
        const file: File = new File([res], event.srcElement?.files?.[0]?.name?.split('.')[0] + '.png');
        list.items.add(file);
        event.srcElement.files = list.files;
        this.ngxPhotoEditorService.open(event, {
          // aspectRatio: 4 / 3,
          autoCropArea: 100,
          roundCropper: true,
        }).subscribe((croppedEvent: NgxCroppedEvent) => {
          this.profileImageCropped(croppedEvent);
        });
      });
    } else {
      this.ngxPhotoEditorService.open(event, {
        // aspectRatio: 4 / 3,
        roundCropper: true,
        autoCropArea: 100
      }).subscribe((croppedEvent: NgxCroppedEvent) => {
        this.profileImageCropped(croppedEvent);
      });
    }
  }

  update() {
    this.petService.updatePet(
      this.pet).subscribe(response => {
      this.notify.message = 'Pet has been updated';
      this.notify.open();
      this.pet = response;

    }, error => {
      // this.apiError();
    });
  }


  createFlag(flagId: string) {
    if (!this.pet.flags) {
      this.pet.flags = new Array();
    }
    this.pet.flags.push(flagId);
    this.update();
  }

  deleteFlag(flagId: string) {
    if (this.pet.flags) {
      const index = this.pet.flags.indexOf(flagId, 0);
      if (index > -1) {
        this.pet.flags.splice(index, 1);
        this.update();
      }
    }
  }




}
