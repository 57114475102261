import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ContextIdDto} from '@savvy/user';
import {PetService} from '@savvy/pet';
import {PetHistory} from '@savvy/pet/model/petHistory';


@Component({
  selector: 'app-pet-history',
  templateUrl: './petHistory.component.html'
})
export class PetHistoryComponent {

  contextIdDto: ContextIdDto;
  petId: string;
  petHistories: Array<PetHistory>;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private petService: PetService,
              public dialogRef: MatDialogRef<PetHistoryComponent>) {

    this.contextIdDto = data.contextIdDto;
    this.petId = data.petId;
    this.loadHistory();
  }

  loadHistory() {
    this.petService.listHistoryV2(
      this.petId
    ).subscribe(response=> {
      this.petHistories = response.petHistories;
    });
  }

  close() {
    this.dialogRef.close();
  }
}
