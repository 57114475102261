import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { EntityService } from '../element/entity.service';
import { ElementControlService } from '../element/ElementControlService';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { EntityRoutes } from './entity.routes';
import { EntitysService } from '@savvy/entity-instance';
import { EntitydefsService } from '@savvy/entity-definition';
import { EicompService } from '@savvy/entity-instance-composite';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AppMessagingModule } from '../messaging/messaging.module';
import { FloMaterialModule } from '../flo.material.module';
import { ElementDefLookupService } from '../element/ElementDefLookupService';
import { ChartlibModule } from '../../chartlib/chartlib.module';
import { AppTaskModule } from '../task/task.module';
import { ImageService } from '../element/image.service';
import { ReminderService } from '@savvy/reminders';
import { AppRemindersModule } from '../reminders/reminders.module';
import { ElementModule } from '../element/element.module';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AppContractModule } from '../contract/contract.module';
import { SeriesService } from '@savvy/series';
// import { Configuration as SeriesConfiguration } from '@savvy/series';
// import { ConfService } from '../config/conf.service';

@NgModule({
    imports: [
        CommonModule,
        AppTaskModule,
        ElementModule,
        AppRemindersModule,
        FloMaterialModule,
        ChartlibModule,
        PdfViewerModule,
        AppMessagingModule,
        AppContractModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        // SeriesServiceModule.forRoot(() => {
        //   return new SeriesConfiguration({
        //     basePath: ConfService.apiUrl()
        //   });
        // }),
        RouterModule.forChild(EntityRoutes)
    ],
    exports: [],
    providers: [
        EntityService,
        ElementControlService,
        ElementDefLookupService,
        EntitydefsService,
        EntitysService,
        EicompService,
        ReminderService,
        SeriesService,
        ImageService
    ],
    declarations: []
})

export class EntityModule {
}
