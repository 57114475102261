<!-- <h4 mat-dialog-title>Change Slot</h4> -->
<mat-toolbar>
  <mat-toolbar-row>{{'CHANGE_SLOT' | translate: {Default: 'Change Slot'} }}</mat-toolbar-row>
</mat-toolbar>
<!-- <hr> -->
<mat-card appearance="outlined">
  <mat-card-content>
    <mat-form-field *ngIf="timeArray && timeArray.length > 0" appearance="outline" class="input-box">
      <mat-label>{{'CHANGE_START_TIME' | translate: {Default: 'Change Start Time'} }}</mat-label>
      <mat-select placeholder="{{'SELECT_START_TIME' | translate}}" [(ngModel)]="startTime" required>
        <mat-option *ngFor="let time of timeArray" [value]="time.actualValue">{{time.displayValue}}</mat-option>
      </mat-select>
    </mat-form-field>
  </mat-card-content>
  <div mat-dialog-actions>
    <button mat-button mat-raised-button color="accent" (click)="changeSlot()">{{'CHANGE_SLOT' | translate: {Default: 'Change Slot'} }}</button>
    <button mat-button mat-dialog-close (click)="close()">{{'CLOSE' | translate: {Default:'Close'} }}</button>
  </div>
</mat-card>
