import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ContextIdDto } from '@savvy/menu';
import { OnboardCustomerMessagingModalComponent } from 'src/app/flo/customer/onboard-customer-messaging-modal/onboard-customer-messaging-modal.component';
import { ViewCustomerV2Component } from 'src/app/flo/customer/view-customer-v2/view-customer-v2.component';
import { AddCustomerComponent } from 'src/app/flo/customer/customer/addCustomer.component';
import { UrlLookupService } from 'src/app/flo/shared/services/urlLookup.service';
import { FeaturesHelperService } from 'src/app/flo/shared/services/features-helper.service';
import { Feature } from '@savvy/subscription';
import { PortalSettingsLocalService } from "../../../../services/portalSettingsLocal.service";
import { CustomerCompositeService, Customer, CustomerService } from "@savvy/customer";

@Component({
  selector: 'app-pet-customer-details',
  templateUrl: './pet-customer-details.component.html',
  styleUrls: ['./pet-customer-details.component.scss']
})
export class PetCustomerDetailsComponent implements OnInit, OnChanges {

  @Input() contextIdDto: ContextIdDto;
  @Input() customerId: string;
  @Input() isViewMode = false;
  customer: Customer;
  customerPortalAllowed = false;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private urlService: UrlLookupService,
    private customerCompositeService: CustomerCompositeService,
    private customerService: CustomerService,
    private featuresHelperService: FeaturesHelperService,
    public portalSettingsLocalService: PortalSettingsLocalService
  ) { }

  ngOnInit(): void {
    this.loadCustomer();
    this.featuresHelperService.isFeatureAllowedForce(Feature.TypeEnum.CustomerPortal, this.contextIdDto)
      .then(res => this.customerPortalAllowed = res);
  }

  ngOnChanges(changes: SimpleChanges): void {
    //super.ngOnChanges(changes);
    if (changes.customerId) {
      this.loadCustomer();
    }
  }

  gotoPortal() {
    this.urlService.openNewTab(this.urlService.customerPortalUrl() + '?id=' + this.contextIdDto.contextId + this.urlService.getEnv());
  }

  loadCustomer() {
    this.customerService.loadCustomer(
      this.customerId,
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType)
      .subscribe(res => {
        if (res) {
          this.customer = res;
        }
      });
  }

  portalInvite() {
    console.log(this.customerId);
    this.dialog.open(OnboardCustomerMessagingModalComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        customerId: this.customer.customerId,
        userId: this.customer.userId,
        email: this.customer.username
      },
      panelClass: 'customers-messaging-dialog',
      autoFocus: true
    });
  }

  editCustomer() {
    const createPetModal = this.dialog.open(AddCustomerComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        customerId: this.customer?.customerId?.id,
        isViewMode: true
      },
      maxWidth: '1024px',
      panelClass: [
        'modal-100',
        'helpwindow',
        'scrollable-modal-2'
      ],
      autoFocus: false,
      disableClose: true
    });

    createPetModal.afterClosed()
      .subscribe(
        () => {
          // if (this.customerStatsComponent) {
          //   this.customerStatsComponent.loadStatus();
          // }
        });
  }


  viewCustomer() {
    if (!this.isViewMode) {
      const createPetModal = this.dialog.open(ViewCustomerV2Component, {
        data: {
          contextIdDto: this.contextIdDto,
          customerId: this.customer.customerId?.id
        },
        maxWidth: '1024px',
        // maxHeight: '80vh',
        // height: '80vh',
        panelClass: [
          'modal-100',
          'helpwindow',
          'petProfileModal'
        ],
        autoFocus: false
      });

      createPetModal.afterClosed()
        .subscribe(
          () => {
            // if (this.customerStatsComponent) {
            //   this.customerStatsComponent.loadStatus();
            // }
          });
      // this.router.navigate(['/customer/viewCustomer',
      //   this.contextIdDto.contextId,
      //   this.contextIdDto.contextIdType,
      //   this.customer?.id]);
    }
  }

}
