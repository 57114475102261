<mat-card appearance="outlined">
    <mat-card-content fxLayout="column wrap">
        <form [formGroup]="formGroup">
            <div fxLayout="column wrap">
                <section class="configuration-toggle-section mdc-mb-2">
                    <mat-slide-toggle formControlName="allowEmail">{{'SEND_EMAIL' | translate}}
                    </mat-slide-toggle>
                </section>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'EMAIL_SUBJECT' | translate}}</mat-label>
                    <input type="text" matInput [maxlength]="50" formControlName="subject" placeholder="{{'EMAIL_SUBJECT' | translate}}" required>
                    <mat-hint [align]="'end'">{{fg?.value?.subject?.length || 0}} / 50</mat-hint>
                    <mat-error *ngIf="fg?.controls?.subject?.hasError('required')">
                        {{'SUBJECT_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
                    </mat-error>
                </mat-form-field>
                <app-email-template-select class="w-100" [contextIdDto]="contextIdDto" [emailTemplateId]="emailTemplateId" (emailTemplateSelect)="onEmailTemplateChange($event)">

                </app-email-template-select>
                <app-marketing-quick-links-editor #quickLinksEditorV2Component messageType="EMAIL" messagingMenu="SEND_MESSAGE" (editorContentChange)="bodyUpdated($event)" [(editorContent)]="body" class="w-100"></app-marketing-quick-links-editor>
                <app-upload-files #uploadFiles [contextIdDto]="contextIdDto" class="w-100"></app-upload-files>

            </div>
        </form>
    </mat-card-content>
</mat-card>