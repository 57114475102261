import {Component} from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar',
  templateUrl: './floSnackbar.component.html',
  styleUrls: ['./floSnackbar.component.scss']
})
export class FloSnackbarComponent {
  message = 'Snack Bar opened.';
  actionButtonLabel = 'Retry';
  action = false;
  setAutoHide = true;
  autoHide = 3000; // Hide after 3 seconds
  addExtraClass = true;

  constructor(public snackBar: MatSnackBar) {}

  open() {
    const config = new MatSnackBarConfig();
    config.duration = this.autoHide;
    config.verticalPosition = 'top'; // MatSnackBarVerticalPosition
    config.horizontalPosition = 'right'; // MatSnackBarHorizontalPosition.
    config.panelClass = this.addExtraClass ? ['snack-bar-color'] : null;
    this.snackBar.open(this.message, this.action && this.actionButtonLabel, config);
  }

  openForSecs(overrideDurationInSecs) {
    const config = new MatSnackBarConfig();
    config.duration = overrideDurationInSecs * 100;
    config.verticalPosition = 'top'; // MatSnackBarVerticalPosition
    config.horizontalPosition = 'right'; // MatSnackBarHorizontalPosition.
    config.panelClass = this.addExtraClass ? ['snack-bar-color'] : null;
    this.snackBar.open(this.message, this.action && this.actionButtonLabel, config);
  }
}
