import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { EventBusService } from './EventBusService';
import { EventType } from '../event/UiEvent';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '../shared/components/confirmation-modal/confirmation-modal.component';
import { PhoneNumberHelperService } from '../shared/services/phone-number-helper.service';
import { ConfirmationEiModalComponent } from '../shared/components/confirmation-ei-modal/confirmation-ei-modal.component';
import { ToolBarDto } from '@savvy/view-definition';
import { ContextIdDto } from '@savvy/quickbooks';

@Component({
  selector: 'app-tool-bar',
  templateUrl: './toolBar.component.html',
  styleUrls: ['toolBar.scss']
})
export class ToolBarComponent implements OnInit {

  @Input() toolBarDto: ToolBarDto;
  @Input() contextIdDto: ContextIdDto;
  @Input() eventBus: EventBusService;
  @Input() viewContext: any;
  @Input() form: UntypedFormGroup;
  @Input() additionalDataMapDto: any;
  @Input() showPlaceholder: boolean;

  constructor(private dialog: MatDialog,
    private sharedService: PhoneNumberHelperService) {
  }


  ngOnInit() {
  }

  edit() {
    console.log('edit clicked for instance ' + this.viewContext.entityInstanceId.id);
    this.sharedService.closeEntityModalSub.next(true);
    this.eventBus.addEntityEvent(EventType.EDIT_ENTITY, this.viewContext.entityInstanceId);
  }

  view() {
    console.log('view clicked for instance ' + this.viewContext.entityInstanceId.id);
    this.sharedService.closeEntityModalSub.next(true);
    this.eventBus.addEntityEvent(EventType.VIEW_ENTITY, this.viewContext.entityInstanceId);
  }

  delete() {
    console.log('publishing delete clicked for instance ' + this.viewContext.entityInstanceId.id);

    if (this.viewContext.entityInstanceId) {
      // It is an entity instance
      const dialogRef = this.dialog.open(ConfirmationEiModalComponent, {
        data: {
          name: this.viewContext.addEditEntityInstanceContextDto.entityDefinitionDto.name,
          contextIdDto: this.contextIdDto,
          entityInstanceId: this.viewContext.entityInstanceId,
          entityDefinitionId: this.viewContext.entityDefinitionId
        }
      });

      dialogRef.afterClosed()
        .subscribe((result) => {
          if (result) {
            this.sharedService.closeEntityModalSub.next(true);
            this.eventBus.addEntityEvent(EventType.REFRESH_CALENDAR_AFTER_DELETE_AND_CLOSE_VIEW,
              this.viewContext.entityInstanceId);
          }
        });
    } else {

      const dialogRef = this.dialog.open(ConfirmationModalComponent, {
        data: {
          name: this.viewContext.addEditEntityInstanceContextDto.entityDefinitionDto.name
        }
      });

      dialogRef.afterClosed()
        .subscribe((result) => {
          if (result) {
            this.sharedService.closeEntityModalSub.next(true);
            this.eventBus.addEntityEvent(EventType.DELETE_ENTITY_INSTANCE, this.viewContext.entityInstanceId);
          }
        });
    }
  }


}

