import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ContextIdDto } from '@savvy/app';
import { Customer } from '@savvy/customer';
import { PetService } from '@savvy/pet';
import { Pet } from '@savvy/pet/model/pet';
import { PetHandlerService } from '../../../pets/pet-handler.service';
import { FeaturesHelperService } from '../../../shared/services/features-helper.service';
import { Feature } from '@savvy/add-ons';
import { MatDialog } from '@angular/material/dialog';
import { AppointmentHandlerService } from 'src/app/flo/appointments-v2/appointment-handler.service';
import { MergePetModalComponent } from '../../../pets/merge-pet/merge-pet-modal.component';
import { MergePetSuccessComponent } from '../../../pets/merge-pet-success/merge-pet-success.component';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreatePetDetailsV2Component } from 'src/app/flo/pets/create-pet-details-v2/create-pet-details-v2.component';
import { ViewPetDetailsV2Component } from 'src/app/flo/pets/view-pet-details-v2/view-pet-details-v2.component';
import { ConfirmationModalComponent } from 'src/app/flo/shared/components/confirmation-modal/confirmation-modal.component';
import { CalendarSetupService } from 'src/app/flo/welcome-to-savvy/calendar-setup.service';

@Component({
  selector: 'app-customer-pet-details',
  templateUrl: './customer-pet-details.component.html',
  styleUrls: ['./customer-pet-details.component.scss']
})
export class CustomerPetDetailsComponent implements OnInit, OnChanges {

  @Input() contextIdDto: ContextIdDto;
  @Input() customerDto: Customer;
  @Input() isViewMode = false;
  @Output() apppointmentsChanged: EventEmitter<any> = new EventEmitter();
  pets: Array<Pet> = [];
  featureAllowed = false;

  constructor(
    private router: Router,
    private petService: PetService,
    private dialog: MatDialog,
    private featuresHelperService: FeaturesHelperService,
    // private petHandlerService: PetHandlerService,
    private appointmentHandlerService: AppointmentHandlerService,
    private spinner: NgxSpinnerService,
    private calendarSetupService: CalendarSetupService
  ) { }

  loadCalendarSettings() {
    this.calendarSetupService.loadCalendarSettings(this.contextIdDto);
  }

  ngOnInit() {
    this.featureAllowed = this.featuresHelperService.isFeatureAllowed(Feature.TypeEnum.PetManagement);
    this.loadPets();
    this.loadCalendarSettings();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.customerDto?.currentValue !== changes?.customerDto?.previousValue) {
      this.loadPets();
    }
  }

  createAppointment(populatePet: Pet) {
    this.appointmentHandlerService.createAppointment({
      contextIdDto: this.contextIdDto, populatePet, callback: this.callback,
      calendarSettings: this.calendarSetupService.calendarSettings
    });
  }

  addPet() {
    this.add(this.contextIdDto, this.customerDto.id, this.callback);
  }

  viewPet(pet: Pet) {
    if (!this.isViewMode) {
      this.view(this.contextIdDto, pet.petOwnerId, pet.id, this.callback);
    }
  }

  editPet(pet: Pet) {
    this.edit(this.contextIdDto, pet.petOwnerId, pet.id, this.callback);
  }

  mergePet() {
    const dialogRef = this.dialog.open(MergePetModalComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        customerId: this.customerDto.customerId.id
      },
      panelClass: 'customers-messaging-dialog',
      autoFocus: false,
      width: '100%',
      maxWidth: '600px'
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.mergePetSuccess(result);
      } else {
        this.loadPets();
      }
    });
  }

  mergePetSuccess(result: any) {
    const dialogRef = this.dialog.open(MergePetSuccessComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        pet: result
      },
      panelClass: 'customers-messaging-dialog',
      autoFocus: true,
      width: '100%',
      maxWidth: '600px'
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      this.loadPets();
      if (res) {
        this.router.navigate(['/pets/view',
          res.contextIdDto.contextId,
          res.contextIdDto.contextIdType,
          res?.pet?.id]);
      }
    });
  }

  loadPets() {
    this.petService.loadCustomersPets(
      this.customerDto.customerId.id).subscribe(response => {
        this.pets = response;
      });
  }

  deletePet(pet: Pet) {
    this.softDelete(this.contextIdDto, pet, this.callback);
  }

  callback = (res) => {
    // if (res) {
    this.loadPets();
    // }
    this.apppointmentsChanged.emit();
  };

  public add(contextIdDto: ContextIdDto, customerId?: string, callback?: (data?: any) => void) {
    const createPetModal = this.dialog.open(CreatePetDetailsV2Component, {
      data: {
        contextIdDto,
        customerId,
        approvalNeeded: this.customerDto?.approvalNeeded
      },
      maxWidth: '1024px',
      panelClass: [
        'modal-100',
        'helpwindow',
        'petProfileModal'
      ],
      autoFocus: false
    });

    createPetModal.afterClosed()
      .subscribe(
        (res) => {
          this.callback(res);
        });

  }

  public view(contextIdDto: ContextIdDto, customerId: string, petId: string, callback?: (data?: any) => void) {
    const createPetModal = this.dialog.open(ViewPetDetailsV2Component, {
      data: {
        contextIdDto,
        customerId,
        petId
      },
      maxWidth: '1024px',
      panelClass: [
        'modal-100',
        'helpwindow',
        'petProfileModal'
      ],
      autoFocus: false
    });

    createPetModal.afterClosed()
      .subscribe(
        (response) => {
          this.callback(response);
          //   this.loadPetFlags(petId);
        });
  }

  public edit(contextIdDto: ContextIdDto, customerId: string, petId: string, callback?: (data?: any) => void) {
    const createPetModal = this.dialog.open(CreatePetDetailsV2Component, {
      data: {
        contextIdDto,
        customerId,
        petId
      },
      maxWidth: '1024px',
      panelClass: [
        'modal-100',
        'helpwindow',
        'petProfileModal'
      ],
      autoFocus: false
    });

    createPetModal.afterClosed()
      .subscribe(
        (res) => {
          this.callback(res);
        });
  }

  softDelete(contextIdDto: ContextIdDto, pet: Pet, callback?: (data?: any) => void) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        name: pet?.name,
        isChange: false,
        message: 'Are you sure you want to delete this pet'
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && pet?.id) {
        this.spinner.show();
        pet.deleted = true;
        this.petService.deletePet(pet?.id).subscribe(response => {
            this.callback(response);
            this.spinner.hide();
          }, error => {
            pet = null;
            this.spinner.hide();
          });
      }
    });
  }

}
