<app-flo-editor #messageEditor [hideToolbar]="messageType === 'SMS'" [format]="messageType === 'SMS' ? 'text' : 'html'" (editorCreated)="onEditorCreated($event)" (textUpdated)="ontextUpdated($event)" [text]="editorContent" class="mdc-mb-1 d-block">
</app-flo-editor>

<div class="warning-message mdc-mb-1" *ngIf="messageType === 'SMS' && editorContent">
  <!--
  WE CANNOT SHOW THIS UNLESS WE KNOW THE EXPANDED MESSAGE SIZE
    <p *ngIf="160 - editorContent.length >= 0"> <strong>{{160 - editorContent.length}}</strong> Chars Left</p>
    <p *ngIf="160 - editorContent.length < 0"> <strong>{{chargedSMS(editorContent.length / 160)}}</strong> SMS credits will be deducted </p>
    -->
  <p class="mat-text-secondary">{{ceil(numCredits)}} {{'SMS' | translate}}<span *ngIf="numCredits > 1">s</span> {{'WILL_BE_CHARGED' | translate}}</p>
  <p class="mat-text-secondary">{{'CHAR_LIMIT' | translate}}</p>
</div>

<h6 class="mdc-mb-1">{{'INCLUDE_IN_MESSAGE' | translate}}</h6>
<app-quick-links-panel *ngIf="templateValuesList" [quickLinks]="quickLinks" [onlyShowVariables]="onlyShowVariables" [templateValuesList]="templateValuesList" [currencyCode]="currencyCode" (linkSelected)="onQuickLinksAction($event)" (variableAdded)="variableAdded($event)">
</app-quick-links-panel>