import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ChangeListener } from './changeListener';
import { EicompService, EntityChangeEvent } from '@savvy/entity-instance-composite';
import { MatSelectionListChange } from '@angular/material/list';
import { ElementDto, FieldValueDto } from '@savvy/view-composite';
import { ViewContextDto } from '@savvy/view-composite';
import { ContextIdDto } from '@savvy/view-definition';
@Component({
  selector: 'app-selection-list',
  templateUrl: 'selectionList.component.html'
})
export class SelectionListComponent implements OnInit {

  @Input() elementDto: ElementDto;
  @Input() contextIdDto: ContextIdDto;
  @Input() viewContext: ViewContextDto;
  @Input() form: UntypedFormGroup;
  @Input() showPlaceholder: boolean;
  @Input() additionalDataMapDto: any;
  @Input() changeListener: ChangeListener;

  selectedOptions: string[] = [];

  constructor(private api: EicompService) {
  }

  ngOnInit(): void {

    // console.log('additional data is ' + this.additionalDataDto.keys());

    console.log('dataSourceElementDefinitionId '
      + this.elementDto.selectionListDto.dataSourceElementDefinitionId.id);
  }

  change(event: any) {
  }

  selectionChange(event: MatSelectionListChange) {

    console.log('event is ' + event.options);

    console.log('change event, selectedOptions ' + this.selectedOptions);

    this.selectedOptions = event.options.map(o => o.value);

    console.log('change event, selectedOptions now ' + this.selectedOptions);
    const fieldValue: FieldValueDto = {
      strValue: event.options.map(o => o.value).join(','),
      valueType: FieldValueDto.ValueTypeEnum.String
    };

    console.log('entityInstanceId is ' + this.viewContext.entityInstanceId.id);
    const req: EntityChangeEvent = {
      contextIdDto: this.contextIdDto,
      fieldValue,
      entityInstanceId: this.viewContext.entityInstanceId.id,
      fieldInstanceId: this.elementDto.selectionListDto.elementInstanceDto.instanceId
    };

    // There is no loadProductsResponse from this, do we care?
    this.api.entityInstanceChangeEvent(req).subscribe(
      response => {
        console.log('response ' + response);
        if (this.changeListener) {
          this.changeListener.add('change');
        }
      }
    );
  }
}

