import { Component, OnInit } from '@angular/core';
import { ServicesHelperService } from '../shared/services/services-helper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ContextIdDto, LinkId } from '@savvy/ui';
import { ConsumerService, ServicesService } from '@savvy/services';
import { UserCurrencyService } from '../shared/services/userCurrency.service';
import { ProductsHelperService } from '../shared/services/products-helper.service';
import { IdNameTupleDto, Product, ProductCompositeService, ProductService } from '@savvy/products';
import { CreateInvoice, Invoice, InvoiceProductRow, InvoiceService, InvoiceServiceRow, UpdateInvoice } from '@savvy/invoice';
import { CreateEmptyInvoice, InvoicePaymentStatusDto, InvoicecompService } from '@savvy/invoice';
import { SendInvoiceDialogComponent } from '../invoice/sendInvoiceDialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { AddPaymentModalComponent } from '../shared/components/add-payment-modal/add-payment-modal.component';
import {Payment, PaymentcompService, PaymentSettings, PaymentSettingsService, RecordPaymentComp} from '@savvy/payment';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import PaymentMethodEnum = Payment.PaymentMethodEnum;
import { CardMachine, SavvyPayCardMachineService } from '@savvy/savvy-pay';
import { PlanDto, PlansCompService } from '@savvy/plan';
import { PlansPaymentComponent } from '../invoice/plans-payment.component';
import { CardMachinePaymentComponent } from '../invoice/card-machine-payment.component';
import { ViewDiscountsModalComponent } from '../discount/view-discounts/view-discounts-modal.component';
import { Location } from '@savvy/location';
import { CreateProductComponent } from '../shared/components/setup/products/create-product/create-product.component';
import { Tax, TaxService } from '@savvy/tax';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CreateServiceComponent } from '../shared/components/setup/services/create-service/create-service.component';
import { EventBusService } from "../element/EventBusService";
import { ShopConfig, ShopService } from '@savvy/shop';

@Component({
  selector: 'app-edit-retail-sale',
  templateUrl: './edit-retail-sale.component.html',
  styleUrls: ['./edit-retail-sale.component.scss']
})
export class EditRetailSaleComponent implements OnInit {
  eventBus: EventBusService;
  contextIdDto = {} as ContextIdDto;
  groupedConsumerServices: { [key: string]: ConsumerService[] } = {};
  groupedProducts: { [key: string]: Product[] } = {};
  currencyCode = '';
  activeOrder = 0;
  activeInvoiceRowTabIndex = 0;
  animationDuration = 300;
  cardMachines: Array<CardMachine> = [];
  plans: Array<PlanDto> = [];
  invoicePaymentStatus: InvoicePaymentStatusDto;

  searchServices = '';
  searchProducts = '';
  newCustomer = false;

  categories: IdNameTupleDto[] = [];
  taxDefs: Tax[] = [];
  serviceGroups = [];
  shopConfig: ShopConfig;

  invoice: Invoice;
  selectedCustomerId = '';
  selectedLocation: Location;

  paymentSettings: PaymentSettings;

  constructor(
    private route: ActivatedRoute,
    private servicesHelperService: ServicesHelperService,
    private userCurrencyService: UserCurrencyService,
    private productsHelperService: ProductsHelperService,
    private invoiceService: InvoiceService,
    private invoicecompService: InvoicecompService,
    private dialog: MatDialog,
    private notify: FloSnackbarComponent,
    private paymentcompService: PaymentcompService,
    private savvyPayCardMachineService: SavvyPayCardMachineService,
    private plansCompService: PlansCompService,
    private router: Router,
    private productCompositeService: ProductCompositeService,
    private taxService: TaxService,
    private snackBar: MatSnackBar,
    private productService: ProductService,
    private servicesService: ServicesService,
    private shopService: ShopService,
    private paymentSettingsApi: PaymentSettingsService,

  ) { }

  ngOnInit() {
    this.route
      .params
      .subscribe(params => {
        console.log('inside signin, context id is ' + params['contextId']);
        if (params['contextId']) {
          console.log('setting contextId');
          this.contextIdDto = {} as ContextIdDto;
          this.contextIdDto.contextId = params['contextId'];
          this.contextIdDto.contextIdType = params['contextIdType'];

          this.getCurrencyCode();
          this.loadGroupedServices();
          this.loadGroupedProducts();
          this.loadCardMachines();
          this.loadProductCategories();
          this.loadShopConfig();
          this.loadPaymentSettings();

          if (!this.eventBus) {
            this.eventBus = new EventBusService();
          }
          if (params['invoiceId']) {
            this.loadInvoice(params['invoiceId']);
          } else {
            this.createNewDraftInvoice();
          }
        }
      });
  }

  loadPaymentSettings() {
    this.paymentSettingsApi.loadPaymentSettings(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
    )//.pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        console.log('loaded payment settings ', response);
        this.paymentSettings = response;
      });

  }

  loadShopConfig() {
    this.shopService.loadOrCreateShopConfig(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
    ).subscribe(response => {
      this.shopConfig = response;
    });
  }

  loadProductCategories() {
    this.productCompositeService.loadProductCategories(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
    ).subscribe(response => {
      if (response?.categories) {
        this.categories = response.categories;
      }
    });
  }

  loadInvoicePaymentStatus(invoice: Invoice): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (invoice?.id) {
        this.invoicecompService.loadInvoicePaymentStatus(
          invoice?.id,
          this.contextIdDto.contextId, this.contextIdDto.contextIdType)
          .subscribe(response => {
            console.log('loaded InvoicePaymentStatus', response);
            if (response?.invoicePaymentStatusDto) {
              this.invoicePaymentStatus = response.invoicePaymentStatusDto;
              resolve(true);
            }
          });
      } else {
        reject(false);
      }
    })
  }

  loadCardMachines() {
    this.savvyPayCardMachineService.loadCardMachineByOwnerIdAndStatus(
      this.contextIdDto.contextId, this.contextIdDto.contextIdType, 'ACTIVE')
      .subscribe(res => {
        console.log('loaded card machines', res);
        this.cardMachines = res?.cardMachines || [];
      });
  }

  loadPlans(selectedCustomerId: string) {
    this.plansCompService.loadActiveCustomerPlansComp(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType, selectedCustomerId).subscribe(res => {
        console.log('loaded plans', res);
        this.plans = res?.plans || [];
      });

  }

  loadTaxDefs() {
    this.taxService.listTaxes(
      this.contextIdDto.contextId
    ).subscribe(taxDefs => {
      if (taxDefs) {
        this.taxDefs = taxDefs;
      }
    });
  }

  loadInvoice(invoiceId: string) {
    this.invoiceService.loadInvoice(invoiceId, this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(res => {
      if (res) {
        this.invoice = res;
      }
    });
  }



  getTotalItemsInCart(saleOrder: Invoice): number {
    return Number(saleOrder?.invoiceServiceRows?.length || 0) + Number(saleOrder?.invoiceProductRows?.length || 0);
  }

  customerSelected(customerId: string, invoice: Invoice) {
    console.log('customerSelected', event);
    invoice.linkedId = <LinkId>{};
    invoice.linkedId.linkedIdType = 'CUSTOMER_ID';
    invoice.linkedId.linkedId = customerId;
    invoice.invoiceOwnerId = {
      id: customerId,
      invoiceOwnerIdType: 'CUSTOMER_ID'
    };
    this.selectedCustomerId = customerId
    if (this.invoice?.id) {
      this.updateInvoice(invoice);
    } else {
      // this.createInvoice();
    }
  }

  createNewDraftInvoice() {
    const createEmptyInvoice = <CreateEmptyInvoice>{};
    createEmptyInvoice.contextIdDto = this.contextIdDto;
    createEmptyInvoice.persist = true;
    createEmptyInvoice.created = true;
    createEmptyInvoice.setPaidInFull = false;
    createEmptyInvoice.linkId = <LinkId>{};
    this.invoicecompService.createEmptyInvoiceV4(createEmptyInvoice).subscribe(response => {
      if (response?.invoice) {
        this.invoice = response?.invoice;
      }
    });
  }

  getCurrencyCode() {
    this.userCurrencyService.getDefaultCurrency(this.contextIdDto)
      .subscribe(res => {
        this.currencyCode = res.org.currencyCode ? res.org.currencyCode : this.userCurrencyService.defaultCurrency;
      });
  }

  loadGroupedServices() {
    this.servicesHelperService.getConsumerServicesGroupedByGroupId(this.contextIdDto).then((res: { [key: string]: ConsumerService[] }) => {
      if (res) {
        this.groupedConsumerServices = res;
      }
    });
  }

  loadGroupedProducts() {
    this.productsHelperService.getProductsGroupedByCategory(this.contextIdDto).then((res: { [key: string]: Product[] }) => {
      if (res) {
        this.groupedProducts = res;
      }
    });
  }

  updateInvoice(invoice: Invoice) {
    if (!invoice?.id) {
      return;
    }
    const updateInvoice: UpdateInvoice = {
      contextIdDto: this.contextIdDto,
      invoice
    }
    this.invoiceService.updateInvoice(updateInvoice).subscribe((updatedInvoice: Invoice) => {
      if (updatedInvoice) {
        this.invoice = updatedInvoice;
      }
    });
  }

  addService(invoice: Invoice, consumerService: ConsumerService) {
    const existingIndex = invoice?.invoiceServiceRows?.findIndex(r => r.serviceNumber === consumerService.id);
    if (existingIndex != undefined && existingIndex > -1 && invoice?.invoiceServiceRows?.[existingIndex]) {
      invoice.invoiceServiceRows[existingIndex].quantity = Number(invoice.invoiceServiceRows[existingIndex].quantity) + 1;
    } else {
      invoice.invoiceServiceRows?.push({
        serviceNumber: consumerService.id,
        serviceName: consumerService?.serviceName,
        unitPrice: consumerService.unitPrice,
        durationInMins: consumerService.durationInMins,
        tax: consumerService.tax,
        quantity: 1
      });
    }
    if (this.invoice?.id) {
      this.updateInvoice(invoice);
    } else {
      // this.createInvoice();
    }
  }

  addProduct(invoice: Invoice, product: Product) {
    const existingIndex = invoice?.invoiceProductRows?.findIndex(r => r.productNumber === product.id);
    if (existingIndex != undefined && existingIndex > -1 && invoice?.invoiceProductRows?.[existingIndex]) {
      invoice.invoiceProductRows[existingIndex].quantity = Number(invoice.invoiceProductRows[existingIndex].quantity) + 1;
    } else {
      invoice.invoiceProductRows?.push({
        productNumber: product.id,
        productName: product?.productName,
        unitPrice: product.unitPrice,
        tax: product.tax,
        quantity: 1
      });
    }
    if (this.invoice?.id) {
      this.updateInvoice(invoice);
    } else {
      // this.createInvoice();
    }
  }

  incrementServiceQty(invoice: Invoice, invoiceServiceRow: InvoiceServiceRow) {
    invoiceServiceRow.quantity = Number(invoiceServiceRow.quantity) + 1;
    if (this.invoice?.id) {
      this.updateInvoice(invoice);
    } else {
      // this.createInvoice();
    }
  }

  decrementServiceQty(invoice: Invoice, invoiceServiceRow: InvoiceServiceRow) {
    invoiceServiceRow.quantity = Number(invoiceServiceRow.quantity) - 1;
    if (this.invoice?.id) {
      this.updateInvoice(invoice);
    } else {
      // this.createInvoice();
    }
  }

  incrementProductQty(invoice: Invoice, invoiceProductRow: InvoiceProductRow) {
    invoiceProductRow.quantity = Number(invoiceProductRow.quantity) + 1;
    if (this.invoice?.id) {
      this.updateInvoice(invoice);
    } else {
      // this.createInvoice();
    }
  }

  decrementProductQty(invoice: Invoice, invoiceProductRow: InvoiceProductRow) {
    invoiceProductRow.quantity = Number(invoiceProductRow.quantity) - 1;
    if (this.invoice?.id) {
      this.updateInvoice(invoice);
    } else {
      // this.createInvoice();
    }
  }

  sendInvoice(invoice: Invoice) {
    this.dialog.open(SendInvoiceDialogComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        invoice,
        eventBus: this.eventBus
      },
      autoFocus: false,
      panelClass: 'helpwindow'
    });
  }

  voidInvoice(invoice: Invoice) {
    const confirmDialog = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Void',
        message: 'Are you sure you want to void this invoice?'
      },
      height: 'auto',
      width: '350px',
      panelClass: 'helpwindow'
    });
    confirmDialog.afterClosed().subscribe((result: boolean) => {
      if (result) {
        invoice.invoiceVoid = true;
        this.updateInvoice(invoice);
      }
    });
  }


  addPayment(invoice: Invoice) {
    const dialogRef = this.dialog.open(AddPaymentModalComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        invoice,
      },
      autoFocus: false,
      panelClass: ['helpwindow']
    });

    dialogRef.afterClosed()
      .subscribe((res) => {
        if (res) {
          console.log('reloading invoice');
          this.updateInvoice(invoice);
        }
      });
  }

  paidFull(type: string, invoice: Invoice) {
    const payment = {} as Payment;
    payment.amount = invoice.total;
    payment.ownerId = this.contextIdDto.contextId;
    payment.invoiceId = invoice?.invoiceId;

    if (type === PaymentMethodEnum.Cash) {
      payment.paymentMethod = PaymentMethodEnum.Cash;
    } else if (type === PaymentMethodEnum.DirectDebit) {
      payment.paymentMethod = PaymentMethodEnum.DirectDebit;
    } else if (type === PaymentMethodEnum.BankTransfer) {
      payment.paymentMethod = PaymentMethodEnum.BankTransfer;
    } else if (type === PaymentMethodEnum.Savvypay) {
      payment.paymentMethod = PaymentMethodEnum.Savvypay;
    } else {
      payment.paymentMethod = PaymentMethodEnum.CardMachine;
    }

    const req = {} as RecordPaymentComp;
    req.contextIdDto = this.contextIdDto;
    req.payment = payment;

    this.paymentcompService.recordPaymentComp(req)
      .subscribe(() => {
        this.notify.message = 'Successfully added payment';
        this.notify.open();
        this.updateInvoice(invoice);
      }, () => {
        console.log('Error occurred while adding payment');
      });
  }

  showPaidPlans(invoice: Invoice) {
    const dialogRef = this.dialog.open(PlansPaymentComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        invoice,
        plans: this.plans,
        entityInstanceId: null
      },
      disableClose: true,
      height: 'auto',
      width: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.updateInvoice(invoice);
      }
    });

  }

  showTerminalPayment(invoice: Invoice) {
    this.loadInvoicePaymentStatus(invoice).then(res => {
      if (res) {
        const dialogRef = this.dialog.open(CardMachinePaymentComponent, {
          data: {
            contextIdDto: this.contextIdDto,
            invoice,
            cardMachines: this.cardMachines,
            currencyCode: this.currencyCode,
            locationId: invoice.locationId,
            totalDepositRequests: [],
            amountOutstanding: Number(invoice.total) - Number(this.invoicePaymentStatus.amountPaid)
          },
          disableClose: true,
          height: 'auto',
          width: '500px',
          panelClass: 'helpwindow'
        });

        dialogRef.afterClosed().subscribe(() => {
          if (invoice) {
            this.updateInvoice(invoice);
          }
        });
      }
    });
  }

  goToSavvyPaySettings() {
    this.router.navigate(['/paymentSettings/paymentSettings',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
    ]);
  }

  removeService(invoice: Invoice, i: number) {
    if (invoice?.invoiceServiceRows?.[i]) {
      invoice.invoiceServiceRows.splice(i, 1);
    }
    this.updateInvoice(invoice);
  }

  removeProduct(invoice: Invoice, i: number) {
    if (invoice?.invoiceProductRows?.[i]) {
      invoice.invoiceProductRows.splice(i, 1);
    }
    this.updateInvoice(invoice);
  }

  trackByMethod(index: number, el: Invoice): number {
    return Number(index);
  }

  trackByProductMethod(index: number, prod: Product): number {
    return Number(prod.id);
  }

  trackByServiceMethod(index: number, service: ConsumerService): number {
    return Number(service.id);
  }

  back() {
    this.router.navigate(['retail', this.contextIdDto.contextId, this.contextIdDto.contextIdType]);
  }

  onDiscountClick(invoice: Invoice) {
    const dialogRef = this.dialog.open(ViewDiscountsModalComponent, {
      data: {
        amount: invoice.subTotal,
        discountId: invoice.discountId,
        currencyCode: this.currencyCode
      },
      width: '775px',
      autoFocus: false,
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed()
      .subscribe(result => {
        if (result?.discountItem) {
          invoice.discountDetails = result.discountItem.discount;
          invoice.discountId = result.discountItem.id;
        } else {
          if (invoice.discountId) {
            // overall discount
            invoice.discountDetails = undefined;
            invoice.discountId = undefined;
            invoice.discount = undefined;
            invoice.discountTotal = 0;
          }
          if (invoice.invoiceProductRows?.length) {
            for (const row of invoice.invoiceProductRows) {
              row.discountDetails = undefined;
              row.discountId = undefined;
            }
          }
          if (invoice.invoiceServiceRows?.length) {
            for (const row of invoice.invoiceServiceRows) {
              row.discountDetails = undefined;
              row.discountId = undefined;
            }
          }
        }
        this.updateInvoice(invoice);

      });
  }


  locationChanged(newLocation: Location, invoice: Invoice) {
    if (newLocation?.id) {
      if (invoice?.locationId) {
        if (newLocation?.id && invoice?.locationId !== newLocation?.id) {
          invoice.locationId = newLocation.id;
          this.selectedLocation = newLocation;
          if (this.invoice?.id) {
            this.updateInvoice(invoice);
          } else {
            // this.createInvoice();
          }
        } else {
          // It has not changed
        }
      } else {
        invoice.locationId = newLocation.id;
        this.selectedLocation = newLocation;
        if (this.invoice?.id) {
          this.updateInvoice(invoice);
        } else {
          // this.createInvoice();
        }
      }
    } else {
      invoice.locationId = null;
      this.selectedLocation = null;
      if (this.invoice?.id) {
        this.updateInvoice(invoice);
      } else {
        // this.createInvoice();
      }
    }

  }

  saveProduct(product: Product, notify = false) {
    return new Promise((resolve) => {
      this.productService.updateProduct(product).subscribe(res => {
        console.log('UPDATED!', res);
        if (notify) {
          this.snackBar.open(`${product.productName} updated successfully`, 'ok', {
            duration: 3000,
          });
        }
        resolve(res);
      });
    });
  }

  editProduct(product: Product) {
    let oldProduct = Object.assign({}, product);
    const dialogRef = this.dialog.open(CreateProductComponent, {
      width: '550px',
      panelClass: ['scrollable-modal', 'helpwindow'],
      data: {
        categories: this.categories,
        contextIdDto: this.contextIdDto,
        edit: true,
        product,
        taxDefs: this.taxDefs
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((productNew: Product) => {
      if (productNew) {
        oldProduct.deleted = true;
        this.saveProduct(oldProduct).then(res => {
          this.saveProduct(productNew, true).then(res => {
            console.log('The product is saved', res);
            this.loadGroupedProducts();
          });
        });
      }
    });
  }

  saveService(service: ConsumerService) {
    return new Promise((resolve) => {
      this.servicesService.updateConsumerService(service).subscribe(res => {
        console.log('UPDATED!', res);
        this.snackBar.open(`${service?.serviceName} updated successfully`, 'ok', {
          duration: 2000,
        });
        this.loadGroupedServices();
        resolve(res);
      });
    });
  }

  editService(service: ConsumerService) {
    const dialogRef = this.dialog.open(CreateServiceComponent, {
      width: '550px',
      panelClass: ['scrollable-modal', 'helpwindow'],
      data: {
        serviceGroups: this.serviceGroups,
        contextIdDto: this.contextIdDto,
        edit: true,
        service,
        taxDefs: this.taxDefs
      }
    });

    dialogRef.afterClosed().subscribe((serviceNew) => {
      if (serviceNew) {
        console.log('The service is saved');
        this.saveService(serviceNew).then(res => {
          this.loadGroupedServices();
        });
      }
    });
  }

  onSelect(invoice: Invoice) {
    this.invoice = invoice;
  }

  makeCreated() {
    this.invoice.created = true;
    this.updateInvoice(this.invoice);
  }

  createInvoice() {
    const createEmptyInvoice = <CreateEmptyInvoice>{};
    createEmptyInvoice.contextIdDto = this.contextIdDto;
    createEmptyInvoice.persist = true;
    createEmptyInvoice.created = true;
    createEmptyInvoice.setPaidInFull = false;
    createEmptyInvoice.linkId = <LinkId>{};

    this.invoicecompService.createEmptyInvoiceV4(createEmptyInvoice).subscribe(response => {
      if (response?.invoice) {
        const invoice: Invoice = {
          ...response?.invoice,
          invoiceServiceRows: this.invoice.invoiceServiceRows,
          invoiceProductRows: this.invoice.invoiceProductRows,
          locationId: this.selectedLocation?.id,
          locationName: this.selectedLocation?.name
        };
        if (this.selectedCustomerId) {
          invoice.linkedId = <LinkId>{};
          invoice.linkedId.linkedIdType = 'CUSTOMER_ID';
          invoice.linkedId.linkedId = this.selectedCustomerId;
          invoice.invoiceOwnerId = {
            id: this.selectedCustomerId,
            invoiceOwnerIdType: 'CUSTOMER_ID'
          };
        }
        this.invoice = invoice;
        this.updateInvoice(this.invoice);
      }
    });
  }

}
