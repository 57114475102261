import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataListDto, DatalistService } from '@savvy/datalist';
import { AddDataItem } from "@savvy/datalist/model/addDataItem";
import { DataItemDto } from "@savvy/datalist/model/dataItemDto";
@Component({
  selector: 'app-add-edit-datalist-modal',
  templateUrl: './add-edit-datalist-modal.component.html',
  styleUrls: ['./add-edit-datalist-modal.component.scss']
})
export class AddEditDatalistModalComponent implements OnInit {

  newItemName = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<AddEditDatalistModalComponent>,
    private datalistService: DatalistService

  ) { }

  ngOnInit() {}

  saveDataItem() {
    const addDataItem = <AddDataItem>{};
    addDataItem.contextIdDto = this.data.contextIdDto;
    addDataItem.dataListId = this.data.dataListDto.dataListId;
    addDataItem.dataItemDto = <DataItemDto>{};
    addDataItem.dataItemDto.name = this.newItemName.replaceAll(/\s/g,'');
    addDataItem.dataItemDto.label = this.newItemName;

    if (!addDataItem.dataListId) {
      console.warn('no id set');
    } else {
      this.datalistService.addDataItem(addDataItem).subscribe((res: DataListDto) => {
        this.dialogRef.close(addDataItem.dataItemDto);
      });
    }
  }
}
