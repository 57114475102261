import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContextIdDto } from '@savvy/view-definition';

@Component({
  selector: 'app-view-contract-modal',
  templateUrl: './view-contract-modal.component.html',
  styleUrls: ['./view-contract-modal.component.scss']
})
export class ViewContractModalComponent implements OnInit {
  contextIdDto: ContextIdDto;
  contractId: string;
  isContractModal = false;
  isCustomerSignState = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private dialogRef: MatDialogRef<ViewContractModalComponent>) { }

  ngOnInit() {
    if (this.data) {
      this.contextIdDto = this.data.contextIdDto;
      this.contractId = this.data.contractId;
      this.isContractModal = this.data.isContractModal;
    }
  }

  customerSignEventChange(event: boolean) {
    this.isCustomerSignState = event;
  }

  contractCreatedEventChange(event: boolean) {
    this.dialogRef.close(event);
  }

}
