<div *ngIf="invoice.invoiceServiceRows.length > 0">
  <mat-form-field appearance="outline" style="width: 100%">
    <mat-label>{{'Service' | translate}}</mat-label>
    <mat-select placeholder="{{'Select Service' | translate}}" [(ngModel)]="invoice.invoiceServiceRows[0].serviceNumber"
                (selectionChange)="serviceChanged()" name="service" #service="ngModel">
      <mat-option *ngFor="let service of consumerServices"
        [value]="service.id">
        {{ service?.serviceName }}
      </mat-option>
    </mat-select>
    <!--<mat-error *ngIf="service.hasError('required')">-->
      <!--{{'Service is' | translate}} <strong>{{'required' | translate}}</strong>-->
    <!--</mat-error>-->
  </mat-form-field>
</div>
