<div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="16px" fxLayoutGap.gt-xs="16px" class="mdc-mb-1">
  <div fxFlex="100" fxFlex.gt-xs="35">
    <div fxLayout="row" fxLayoutAlign=" center">
      <div fxFlex>
        <h6 class="mb-0"><strong>{{'DATES_CREATED' | translate: {Default: 'Dates Created'} }}</strong></h6>
      </div>

      <div fxFlex="nogrow">
        <mat-slide-toggle [labelPosition]="'after'" [(ngModel)]="overrideConflictCheck"
          (ngModelChange)="overrideConflictCheckChanged($event)">{{'OVERRIDE_CONFLICT_CHECK' | translate: {Default:
          'Override Conflict Check'} }}
        </mat-slide-toggle>
        <button mat-icon-button color="accent"
          matTooltip="{{'OVERLAPPING_APPOINTMENT_EXPLANATION' | translate: {Default: 'The \'!\' symbol denotes an overlapping appointment only shows when override is enabled.'} }}"
          matTooltipPosition="above">
          <mat-icon>info</mat-icon>
        </button>
      </div>
    </div>
    <mat-selection-list #appointmentDatesList [multiple]="false" *ngIf="appointmentDates?.length; else elseDates"
      [compareWith]="compareObjects">
      <mat-list-option *ngFor="let appointmentDate of appointmentDates; index as i;" [value]="appointmentDate"
        (click)="selectDate(appointmentDate)" [disabled]="appointmentDate?.slotSelected"
        [ngClass]="{'message-list-item-conflict' : appointmentDate?.conflict}" class="message-list-item">
        <div fxLayout="row" fxLayoutAlign=" center">
          <div fxFlex="nogrow">
            <div *ngIf="!appointmentDate?.conflict" class="avatar avatar-50 circle border-3">
              <mat-icon aria-hidden="true" data-mat-icon-type="font" class="done-icon">done</mat-icon>
            </div>
            <div *ngIf="appointmentDate?.conflict" class="avatar avatar-50 circle border-color-3">
              <mat-icon aria-hidden="true" data-mat-icon-type="font" class="conflict-icon"
                [matMenuTriggerFor]="conflictDetails">info</mat-icon>
              <mat-menu #conflictDetails="matMenu">
                <div class="mdc-p-3">
                  <div [innerHTML]="appointmentDate?.clashStr"></div>
                </div>
              </mat-menu>
            </div>
          </div>
          <div fxFlex style="padding-left: 5px;">
            <span [ngClass]="{'deprecated-date': appointmentDate?.conflict && appointmentDate?.startTime}">
              {{appointmentDate?.localStartDate | localizedDate: 'MMM d, y'}} at {{appointmentDate?.localStartDate |
              localizedDate: 'h:mma'}}
            </span>
            <!-- <div style="font-size:11px;">
              at {{appointmentDate?.localStartDate | localizedDate: 'h:mm a'}}
            </div> -->
            <div *ngIf="appointmentDate?.conflict && appointmentDate?.startTime" style="font-size:11px;">
              Clashed
            </div>
          </div>
          <div fxFlex="nogrow">
            <mat-icon color="red" class="rounded-circle vm" (click)="deleteAppointmentDate(i)">close</mat-icon>
          </div>
        </div>
      </mat-list-option>
    </mat-selection-list>
    <ng-template #elseDates>
      <div fxLayout="row" fxLayoutAlign="center center" style="height: 60vh;  ">
        <div style="text-align:center;">
          <app-zero-image name="reminders" class="zero-image"></app-zero-image><br>
          <h6 class="ma-0">{{'NO_DATE_YET_GENERATE' | translate}}</h6>
        </div>
      </div>
    </ng-template>

  </div>
  <div fxFlex>
    <div fxLayout="row wrap">
      <div fxFlex class="mdc-my-2 text-md-center">
        <h6><strong>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</strong></h6>
      </div>
      <div fxFlex="100" fxFlex.gt-xs="nogrow" class="ml-1">
        <button id="month-view" mat-button (click)="viewChange('month')" [class.active]="view === 'month'">
          {{'MONTH' | translate: {Default: 'Month'} }}
        </button>
        <button id="week-view" mat-button (click)="viewChange('week')" [class.active]="view === 'week'">
          {{'WEEK' | translate: {Default: 'Week'} }}
        </button>
        <button id="day-view" mat-button (click)="viewChange('day')" [class.active]="view === 'day'">
          {{'DAY' | translate: {Default: 'Day'} }}
        </button>

        <button id="select-location-button" mat-button class="ml-1"
          *ngIf="calendarSettings && calendarSettings.multiLocationEnabled"
          [matMenuTriggerFor]="menu">{{locationToUseWhenCreating?.locationName}}</button>

        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="onLocationChange(location)"
            *ngFor="let location of locations">{{location.locationName}}</button>

          <button mat-menu-item
            *ngIf="calendarSettings && calendarSettings.multiLocationEnabled && allowSelectAllLocations"
            (click)="allSelected()">{{'ALL' | translate: {Default: 'All'} }}</button>
        </mat-menu>
      </div>

    </div>
    <br>
    <div [ngSwitch]="view">
      <mwl-calendar-month-view *ngSwitchCase="'month'" [viewDate]="viewDate" [events]="newMonthEvents"
        [refresh]="refresh" [weekStartsOn]="weekStartsOn" [locale]="translateService.currentLang"
        [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)" (eventClicked)="eventClicked($event)"
        (eventTimesChanged)="eventTimesChangedRepeat($event)">
      </mwl-calendar-month-view>

      <mwl-calendar-week-view *ngSwitchCase="'week'" [viewDate]="viewDate" [hourSegments]="numHourSegments"
        [dayStartHour]="dayStartHour" [dayEndHour]="dayEndHour" [events]="newWeekEvents"
        [eventTemplate]="customWeekEventTemplate" [weekStartsOn]="weekStartsOn" [refresh]="refresh"
        [locale]="translateService.currentLang" (eventClicked)="eventClicked($event)"
        (eventTimesChanged)="eventTimesChangedRepeat($event)">
      </mwl-calendar-week-view>

      <app-mwl-day-view-scheduler *ngSwitchCase="'day'" [users]="dayViewUsers" [contextIdDto]="contextIdDto"
        [location]="locationToUseWhenCreating" [dayStartHour]="dayStartHour" [dayEndHour]="dayEndHour"
        [hourSegments]="numHourSegments" [viewDate]="viewDate" [events]="events" [eventTemplate]="customDayEventViewTpl"
        [hourSegmentTemplate]="dayHourSegmentTemplate" [refresh]="refresh" [locale]="translateService.currentLang"
        (eventClicked)="eventClicked($event)" (eventTimesChanged)="eventTimesChangedRepeat($event)">
      </app-mwl-day-view-scheduler>
    </div>
  </div>
</div>


<ng-template #customDayEventViewTpl let-weekEvent="weekEvent" let-tooltipPlacement="tooltipPlacement"
  let-eventClicked="eventClicked" let-tooltipTemplate="tooltipTemplate" let-tooltipAppendToBody="tooltipAppendToBody">

  <div *ngIf="weekEvent" class="cal-event" style="overflow: auto; line-height: 16px;"
    [style.backgroundColor]="weekEvent.event.color?.secondary" [style.borderColor]="weekEvent.event.color?.primary"
    [style.color]="weekEvent.event?.color?.textColor"
    [mwlCalendarTooltip]="weekEvent.event.title | calendarEventTitle:'dayTooltip':weekEvent.event"
    [tooltipPlacement]="tooltipPlacement" [tooltipEvent]="weekEvent.event" [tooltipTemplate]="tooltipTemplate"
    [tooltipAppendToBody]="tooltipAppendToBody" (click)="handleEvent(weekEvent.event)">
    <mwl-calendar-event-actions [event]="weekEvent.event">
    </mwl-calendar-event-actions>
    &nbsp;
    <mwl-calendar-event-title style="
                    white-space: normal;
                    word-break: break-all;" [event]="weekEvent.event" view="day"
      [style.color]="weekEvent.event?.color?.textColor">
    </mwl-calendar-event-title>
  </div>
</ng-template>

<ng-template #dayHourSegmentTemplate let-segment="segment" let-locale="locale" let-isTimeLabel="isTimeLabel"
  let-segmentHeight="segmentHeight" let-column="column">
  <div class="cal-hour-segment" [style.height.px]="segmentHeight"
    [class.cal-hour-start]="segment.isStart" [class.cal-after-hour-start]="!segment.isStart"
    [ngClass]="segment.cssClass">
    <div class="cal-time" *ngIf="isTimeLabel">
      {{ segment.date | calendarDate: 'dayViewHour':locale }}
    </div>
  </div>
</ng-template>

<ng-template #customWeekEventTemplate let-weekEvent="weekEvent" let-tooltipPlacement="tooltipPlacement"
  let-eventClicked="eventClicked" let-tooltipTemplate="tooltipTemplate" let-tooltipAppendToBody="tooltipAppendToBody"
  let-tooltipDisabled="tooltipDisabled">
  <div class="cal-event" style="overflow: auto; line-height: 16px"
    [style.backgroundColor]="weekEvent.event.color?.secondary" [style.color]="weekEvent.event?.color?.textColor"
    [style.borderColor]="weekEvent.event.color?.primary"
    [mwlCalendarTooltip]="!tooltipDisabled ? (weekEvent.event.title | calendarEventTitle:'weekTooltip':weekEvent.event) : ''"
    [tooltipPlacement]="tooltipPlacement" [tooltipEvent]="weekEvent.event" [tooltipTemplate]="tooltipTemplate"
    [tooltipAppendToBody]="tooltipAppendToBody" (mwlClick)="eventClicked.emit({ sourceEvent: $event })">

    <mwl-calendar-event-actions [event]="weekEvent.event">
    </mwl-calendar-event-actions>
    <mwl-calendar-event-title style="
                white-space: normal;
                word-break: break-all;" [style.color]="weekEvent.event?.color?.textColor" [event]="weekEvent.event"
      view="week">
    </mwl-calendar-event-title>

  </div>
</ng-template>