<mat-card appearance="outlined" class="mx-0 mdc-mb-1" *ngIf="isVaccinationFeatureAllowed">
  <mat-card-header>
    <div fxLayout="row" fxLayoutAlign=" center" class="w-100">
      <div fxFlex>
        <h6 class="mb-0 ">{{'VACCINATION' | translate}}</h6>
      </div>
      <div fxFlex="nogrow">
        <button mat-button color="accent" (click)="addVaccination()">
          <mat-icon class="vm">add</mat-icon>
          {{'ADD_VACCINATION' | translate}}
        </button>
      </div>
    </div>
  </mat-card-header>

  <div fxLayout="row" fxLayoutAlign=" center">{{pet.vaccinated}}</div>
  <app-pet-vaccination-list #petVaccinationList [contextIdDto]="contextIdDto" [petId]="pet?.id">
  </app-pet-vaccination-list>
</mat-card>