<mat-card appearance="outlined" *ngIf="workflowPanelDto && workflowPanelDto.workflowStateInstanceDtos && workflowPanelDto.workflowStateInstanceDtos.length > 0" class="customer-panel new-card">
  <mat-card-header>
    <mat-card-title>
      <p><b>{{'Workflow' | translate}}</b></p>
      <!--
      <h1 class="mt-0 mb-0">Shoot Workflow</h1>
      -->
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-vertical-stepper [linear]="true">
      <mat-step label="{{state.state}}" *ngFor="let state of workflowPanelDto.workflowStateInstanceDtos">
        <div *ngFor="let task of state.workflowTaskDtos">
          <mat-checkbox (change)="taskSelectionChange($event, task)" [checked]="task.complete" [disabled]="task.complete">
            {{task.taskName | translate}}
          </mat-checkbox>
          <mat-hint>
            <span *ngIf="task.dueDate">
              &nbsp;(Due {{ task.dueDate | localizedDate: 'mediumDate' }})
            </span>
          </mat-hint>
        </div>
        <!--
        <div>
            <button mat-button matStepperPrevious>Previous</button>
            <button mat-button matStepperNext>Next</button>
        </div>
        -->
      </mat-step>
    </mat-vertical-stepper>
  </mat-card-content>
</mat-card>


<mat-card appearance="outlined" *ngIf="workflowPanelDto && workflowPanelDto.workflowStateInstanceDtos && workflowPanelDto.workflowStateInstanceDtos.length == 0" class="customer-panel new-card">
  <mat-card-content>
    <div class="flexbox-parent">
      <div fxLayout="row" fxLayoutAlign="center center" style="height: 60vh;  ">
        <div style="text-align:center;">
          <app-zero-image name="task"></app-zero-image><br>
          <h5 class="ma-0">{{'There is no workflow associated with' | translate}} {{workflowPanelDto.label | translate}}</h5><br>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>