import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContextIdDto } from '@savvy/quickbooks';
import { UntypedFormGroup } from '@angular/forms';
import {
  ConfirmCreateInstance,
  CreateEntityInstanceWithEntityDefinitionId,
  EicompService, LinkedInstanceDto
} from '@savvy/entity-instance-composite';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { ChangeListener } from './changeListener';
import { ElementControlService } from './ElementControlService';
import { EventBusService } from './EventBusService';
import { TranslateService } from '@ngx-translate/core';
import {LoadEiViewResponse, ViewcompService} from '@savvy/view-composite';

@Component({
    selector: 'app-create-embedded-entity-component',
    templateUrl: 'createEmbeddedEntityComponent.component.html'
})
export class CreateEmbeddedEntityComponent implements OnInit {

    active = false;
    sub: any;
    creating = false;
    public embeddedForm: UntypedFormGroup; // our form model

    changeListener = new ChangeListener();

    @Input() contextIdDto: ContextIdDto;
    @Input() entityDefinitionId: string;
    @Input() linkedInstanceDto: LinkedInstanceDto;
    @Input() linkedEntityDefinitionId: string;
    @Input() eventBus: EventBusService;
    @Output() cancelClicked = new EventEmitter();
    @Output() created = new EventEmitter();

    loadEiViewResponse: LoadEiViewResponse;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private ecs: ElementControlService,
        private notify: FloSnackbarComponent,
        private translateService: TranslateService,
        private viewcompService: ViewcompService,
        private eiComposite: EicompService) {

        console.log('inside constructor of create embedded entity component');
    }

    ngOnInit() {
        console.log('inside init create embedded entity component');
        console.log('got entityDefinitionId ' + this.entityDefinitionId);
        const request: CreateEntityInstanceWithEntityDefinitionId = {
            contextIdDto: this.contextIdDto,
            entityDefinitionId: this.entityDefinitionId,
            linkedInstanceDto: this.linkedInstanceDto,
            suppliedValueDto: null
        };
        this.eiComposite.createEmbeddedByEntityDefinitionIdV2(
            request).subscribe(response => {
                console.log('got loadProductsResponse from createEmbeddedByEntityDefinitionId');
                this.viewcompService.loadEiToCreate(response.entityInstanceId.id,
                      this.contextIdDto.contextId,
                      this.contextIdDto.contextIdType).subscribe(response2 => {
                  this.handleResponse(response2);
              });
            });
        console.log('done init');
    }

    cancelIt() {
        console.log('here in cancel in createEmbeddedEntityComponent');
        this.cancelClicked.emit('cancel');
    }

    createRecord() {
        console.log('confirming create instance');
        this.creating = true;
        this.eiComposite.confirmCreateEi(
          this.loadEiViewResponse.viewContextDto.addEditEntityInstanceContextDto.entityInstanceId.id,
          this.contextIdDto.contextId,
          this.contextIdDto.contextIdType
        ).subscribe(
            response => {
              this.creating = false;
                console.log('confirm create instance is back here');
                // NOT SURE WE NEED TO DO THIS : this.loadEiViewResponse = loadProductsResponse;
                this.translateService.get('Successfully created').subscribe(translatedTxt => {
                    this.notify.message = translatedTxt;
                    this.notify.open();
                    this.created.emit(this.loadEiViewResponse.viewContextDto.addEditEntityInstanceContextDto.entityInstanceId.id);
                });
            });
    }

    private handleResponse(response: LoadEiViewResponse) {
        if (response.viewRootElementDto &&
            response.viewContextDto &&
            response.viewContextDto.addEditEntityInstanceContextDto &&
            response.viewContextDto.addEditEntityInstanceContextDto.entityDefinitionDto) {
            // @ts-ignore
          this.embeddedForm = this.ecs.toFormGroup(response.viewRootElementDto,
            // @ts-ignore
                response.viewContextDto.addEditEntityInstanceContextDto.entityDefinitionDto);
        } else {
            console.log('Empty panel so assuming empty form');
            this.embeddedForm = this.ecs.emptyFormGroup();
        }
        this.loadEiViewResponse = response;
    }
}

