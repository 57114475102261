import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class CookiesService {

  constructor(private cookieService: CookieService) { }

  setCookie(cName: string, cValue: any, exdays: number) {
    const exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    document.cookie = encodeURIComponent(cName)
      + '=' + encodeURIComponent(cValue)
      + (!exdays ? '' : `; expires=${exdate.toUTCString()}`);

  }

  deleteAll() {
    this.cookieService.deleteAll();
  }


  getCookie(cname) {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (let c of ca) {
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  deleteCookie(cname) {
    this.setCookie(cname, '', 0);
  }

  // checkCookie() {
  //   const user = this.getCookie('username');
  //   if (user !== '') {
  //     // your code goes here
  //   } else {
  //     //your code goes here
  //     if (user !== '' && user != null) {
  //       this.setCookie('username', user, 5);
  //     }
  //   }
  // }

}
