<div [formGroup]="form" *ngIf="elementDefinition && elementDefinition.elementType">

  <app-files
    *ngIf="elementDefinition.elementType.toString() === 'FILES'"
    [fieldDefinitionDto]="elementDefinition.fieldDefinitionDto"
    [entityInstanceId]="entityInstanceId"
    [elementInstanceDto]="elementDto.elementInstanceDto"
    [contextIdDto]="contextIdDto"
    [additionalDataMapDto]="additionalDataMapDto"
    [showPlaceholder]="showPlaceholder"
    [changeListener]="changeListener">

  </app-files>

  <app-line-instance
            *ngIf="elementDefinition?.elementType?.toString() === 'LINE_DEFINITION'"
            [lineDefinition]="elementDefinition.lineDefinitionDto"
            [entityInstanceId]="entityInstanceId"
            [viewContext]="viewContext"
            [contextIdDto]="contextIdDto"
            [showPlaceholder]="showPlaceholder"
            [form]="form">

    </app-line-instance>

  <app-repeatable-event-instance *ngIf="elementDefinition.elementType.toString() === 'REPEATABLE_EVENT_DEFINITION'"
                             [repeatableEventDefinition]="elementDefinition.repeatableEventDefinition"
                             [entityInstanceId]="entityInstanceId"
                             [viewContext]="viewContext"
                             [additionalDataMapDto]="additionalDataMapDto"
                             [contextIdDto]="contextIdDto"
                             [elementInstanceDto]="elementDto.elementInstanceDto"
                             [showPlaceholder]="showPlaceholder"
                             [form]="form"
                             [changeListener]="changeListener">

  </app-repeatable-event-instance>


    <app-select-field-instance *ngIf="elementDefinition.elementType.toString() === 'SELECT'"
                               [selectFieldDefinition]="elementDefinition.selectFieldDefinitionDto"
                               [entityInstanceId]="entityInstanceId"
                               [additionalDataMapDto]="additionalDataMapDto"
                               [contextIdDto]="contextIdDto"
                               [elementInstanceDto]="elementDto.elementInstanceDto"
                               [showPlaceholder]="showPlaceholder"
                               [form]="form"
                               [changeListener]="changeListener">

    </app-select-field-instance>


  <app-colour-field-instance *ngIf="elementDefinition.elementType.toString() === 'COLOUR_VALUE_DEFINITION'"
                             [fieldDefinition]="elementDefinition.fieldDefinitionDto"
                             [entityInstanceId]="entityInstanceId"
                             [additionalDataMapDto]="additionalDataMapDto"
                             [contextIdDto]="contextIdDto"
                             [elementInstanceDto]="elementDto.elementInstanceDto"
                             [showPlaceholder]="showPlaceholder"
                             [form]="form"
                             [changeListener]="changeListener">

  </app-colour-field-instance>

    <app-image-field-instance *ngIf="elementDefinition.elementType.toString() === 'IMAGE_FIELD_DEFINITION'"
                              [imageFieldDefinition]="elementDefinition.imageFieldDefinitionDto"
                              [entityInstanceId]="entityInstanceId"
                              [contextIdDto]="contextIdDto"
                              [elementInstanceDto]="elementDto.elementInstanceDto"
                              [showPlaceholder]="showPlaceholder"
                              [form]="form">

    </app-image-field-instance>

    <app-video-field-instance *ngIf="getDefinition().elementType.toString() === 'VIDEO'"
                              [videoFieldDefinition]="elementDefinition.fieldDefinitionDto"
                              [entityInstanceId]="entityInstanceId"
                              [contextIdDto]="contextIdDto"
                              [elementInstanceDto]="elementDto.elementInstanceDto"
                              [showPlaceholder]="showPlaceholder"
                              [form]="form">

    </app-video-field-instance>


    <app-workflow-definition-field-instance *ngIf="getDefinition().elementType.toString() === 'WORKFLOW_FIELD_DEFINITION'"
                                 [workflowFieldDefinition]="elementDefinition.workflowFieldDefinitionDto"
                                 [entityInstanceId]="entityInstanceId"
                                            [viewContext]="viewContext"
                                 [additionalDataMapDto]="additionalDataMapDto"
                                 [contextIdDto]="contextIdDto"
                                 [elementInstanceDto]="elementDto.elementInstanceDto"
                                 [showPlaceholder]="showPlaceholder"
                                 [form]="form">

    </app-workflow-definition-field-instance>
    <app-input-instance
            *ngIf="elementDefinition.elementType.toString() === 'INPUT'
                  || elementDefinition.elementType.toString() === 'TEXT_AREA'
                  || elementDefinition.elementType.toString() === 'SIGNATURE'
                  || elementDefinition.elementType.toString() === 'CHECK_BOX'"
            [fieldDefinition]="elementDefinition.fieldDefinitionDto"
            [entityInstanceId]="entityInstanceId"
            [contextIdDto]="contextIdDto"
            [showPlaceholder]="showPlaceholder"
            [elementInstanceDto]="elementDto.elementInstanceDto"
            [form]="form"
            [changeListener]="changeListener">

    </app-input-instance>
  <app-location-instance
    *ngIf="elementDefinition.elementType.toString() === 'LOCATION'"
    [fieldDefinition]="elementDefinition.fieldDefinitionDto"
    [entityInstanceId]="entityInstanceId"
    [contextIdDto]="contextIdDto"
    [showPlaceholder]="showPlaceholder"
    [elementInstanceDto]="elementDto.elementInstanceDto"
    [form]="form"
    [changeListener]="changeListener">

  </app-location-instance>
  <app-int-instance
    *ngIf="elementDefinition.elementType.toString() === 'INTEGER_VALUE_DEFINITION'"
    [fieldDefinition]="elementDefinition.fieldDefinitionDto"
    [entityInstanceId]="entityInstanceId"
    [contextIdDto]="contextIdDto"
    [showPlaceholder]="showPlaceholder"
    [elementInstanceDto]="elementDto.elementInstanceDto"
    [form]="form"
    [changeListener]="changeListener">

  </app-int-instance>

  <app-icons-instance
    *ngIf="elementDefinition.elementType.toString() === 'ICONS'"
    [iconsFieldDefinition]="elementDefinition.iconsFieldDefinitionDto"
    [entityInstanceId]="entityInstanceId"
    [contextIdDto]="contextIdDto"
    [showPlaceholder]="showPlaceholder"
    [eventBus]="eventBus"
    [elementInstanceDto]="elementDto.elementInstanceDto"
    [form]="form"
    [changeListener]="changeListener">

  </app-icons-instance>

  <app-big-decimal-instance
    *ngIf="elementDefinition.elementType.toString() === 'BIG_DECIMAL_VALUE_DEFINITION'"
    [fieldDefinition]="elementDefinition.fieldDefinitionDto"
    [entityInstanceId]="entityInstanceId"
    [contextIdDto]="contextIdDto"
    [showPlaceholder]="showPlaceholder"
    [elementInstanceDto]="elementDto.elementInstanceDto"
    [form]="form"
    [changeListener]="changeListener">

  </app-big-decimal-instance>
  <app-boolean-instance
    *ngIf="elementDefinition.elementType.toString() === 'BOOLEAN_VALUE_DEFINITION'"
    [fieldDefinition]="elementDefinition.fieldDefinitionDto"
    [entityInstanceId]="entityInstanceId"
    [contextIdDto]="contextIdDto"
    [showPlaceholder]="showPlaceholder"
    [elementInstanceDto]="elementDto.elementInstanceDto"
    [form]="form"
    [changeListener]="changeListener">

  </app-boolean-instance>
    <app-file-upload *ngIf="elementDefinition.elementType.toString() === 'FILE_UPLOAD'"
                     [fieldDefinition]="elementDefinition.fieldDefinitionDto"
                     [entityInstanceId]="entityInstanceId"
                     [contextIdDto]="contextIdDto"
                     [showPlaceholder]="showPlaceholder"
                     [elementInstanceDto]="elementDto.elementInstanceDto"
                     [form]="form">


    </app-file-upload>

    <app-date-instance *ngIf="elementDefinition.elementType.toString() === 'DATE'"
                       [dateDefinition]="elementDefinition.daateFieldDefinitionDto"
                       [entityInstanceId]="entityInstanceId"
                       [contextIdDto]="contextIdDto"
                       [showPlaceholder]="showPlaceholder"
                       [changeListener]="changeListener"
                       [elementInstanceDto]="elementDto.elementInstanceDto"
                       [form]="form">

    </app-date-instance>
    <app-time-instance *ngIf="elementDefinition.elementType.toString() === 'TIME'"
                       [timeDefinition]="elementDefinition.timeFieldDefinitionDto"
                       [entityInstanceId]="entityInstanceId"
                       [contextIdDto]="contextIdDto"
                       [showPlaceholder]="showPlaceholder"
                       [changeListener]="changeListener"
                       [elementInstanceDto]="elementDto.elementInstanceDto"
                       [form]="form">

    </app-time-instance>
    <app-date-time-instance *ngIf="elementDefinition.elementType.toString() === 'DATE_TIME'"
                            [dateTimeDefinition]="elementDefinition.daateTimeFieldDefinitionDto"
                            [entityInstanceId]="entityInstanceId"
                            [contextIdDto]="contextIdDto"
                            [changeListener]="changeListener"
                            [showPlaceholder]="showPlaceholder"
                            [elementInstanceDto]="elementDto.elementInstanceDto"
                            [form]="form">

    </app-date-time-instance>


    <app-button-instance *ngIf="elementDefinition.elementType.toString() === 'BUTTON'"
                         [buttonDefinition]="elementDefinition.buttonDefinitionDto">

    </app-button-instance>

    <app-linked-entity-instance
            *ngIf="elementDefinition.elementType.toString() === 'LINKED_ENTITY'"
            [linkedEntityDefinition]="elementDefinition.linkedEntityDefinitionDto"
            [entityInstanceId]="entityInstanceId"
            [contextIdDto]="contextIdDto"
            [showPlaceholder]="showPlaceholder"
            [elementInstanceDto]="elementDto.elementInstanceDto"
            [additionalDataMapDto]="additionalDataMapDto"
            [form]="form"
            [changeListener]="changeListener">

    </app-linked-entity-instance>

    <app-linked-customer-instance
            *ngIf="elementDefinition.elementType.toString() === 'LINKED_CUSTOMER_DEFINITION'"
            [linkedCustomerDefinition]="elementDefinition.linkedCustomerDefinitionDto"
            [entityInstanceId]="entityInstanceId"
            [contextIdDto]="contextIdDto"
            [showPlaceholder]="showPlaceholder"
            [elementInstanceDto]="elementDto.elementInstanceDto"
            [form]="form"
            [changeListener]="changeListener">

    </app-linked-customer-instance>

    <app-entity-element-set-definition
            *ngIf="elementDefinition.elementType.toString() === 'ELEMENT_SET_DEFINITION'"
            [elementSetDefinition]="elementDefinition.elementSetDefinitionDto"
            [viewContext]="viewContext"
            [contextIdDto]="contextIdDto"
            [showPlaceholder]="showPlaceholder"
            [elementInstanceDto]="elementDto.elementInstanceDto"
            [additionalDataMapDto]="additionalDataMapDto"
            [form]="form">

    </app-entity-element-set-definition>


</div>
