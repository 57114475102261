<div class="view-invoice-page" fxLayout="row" fxLayoutAlign="center">

  <div class="invoice-container" fxFlex="100">
    <br /><br />
    <mat-card appearance="outlined" class="mt-0 new-card">
      <div fxLayout="row" fxLayoutWrap="wrap">
        <div class="mdc-mb-1">
          <h1 class="mt-0"><strong>{{'INVOICE' | translate}}</strong></h1>
          <a href="javascript:void(0)" *ngIf="invoice.companyDetails">{{invoice.companyDetails.companyEmail}}</a>
        </div>
      </div>

      <div fxLayout="row" fxLayoutWrap="wrap">
        <div fxFlex.gt-xs="50" fxFlex="100">
          <h4 class="my-0">{{invoiceDto?.fullName | translate}}</h4>
          <div fxLayout="column">
            <span>{{'INVOICE_NO' | translate}}: {{invoice.invoiceNumber}}</span>
            <span>{{'DATE_ISSUED' | translate}}: {{invoice.invoiceDate | localizedDate: 'mediumDate'}}</span>
            <span>{{'DUE_DATE' | translate}}: {{invoice.invoiceDate | localizedDate: 'mediumDate'}}</span>
            <span>{{'VOID' | translate}}: {{ invoice.invoiceVoid }}</span>
          </div>
        </div>
        <div fxFlex.gt-xs="50" fxFlex="100" class="text-sm-right text-xs-left">
          <!--h4 class="my-0">{{invoiceDto?.fullName}}</h4-->
          <div fxLayout="column" *ngIf="invoice.companyDetails && invoice.companyDetails.companyAddress">
            <span>{{invoice.companyDetails.companyTelephone}}</span>
            <span>{{invoice.companyDetails.companyAddress.address1}}</span>
            <span>{{invoice.companyDetails.companyAddress.address2}}</span>
            <span>{{invoice.companyDetails.companyAddress.city}}
              , {{invoice.companyDetails.companyAddress.zipCode}}</span>
          </div>
        </div>
        <div fxLayout="row" fxLayoutWrap="wrap" *ngIf="invoice.paidInFull">
          <img src="assets/images/invoice/invoicePaid.svg" />
          <!--
        <h1>{{invoice.datePaid |date:'medium'}}</h1>
        -->
        </div>
      </div>
    </mat-card>

    <mat-card appearance="outlined" class="new-card pl-0 pr-0 py-1" *ngIf="invoiceDto && invoiceDto.showCourses">
      <mat-card-title class="ml-25 mt-1">{{'COURSES' | translate}}</mat-card-title>
      <ngx-datatable class="material" [rows]="invoiceDto.invoice.invoiceCourseRows" [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'">
        <ngx-datatable-column name="{{'DESCRIPTION' | translate}}" [flexGrow]="3">
          <ng-template ngx-datatable-cell-template let-row="row">
            <strong>{{row.name}}</strong><br /><span class="mat-text-muted">{{row.description}}</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'UNIT_PRICE' | translate}}" [flexGrow]="1">
          <ng-template ngx-datatable-cell-template let-row="row">
            <strong>{{row.unitPrice | currency:currencyCode:'symbol'}}</strong>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'TAX_RATE' | translate}}" [flexGrow]="0.6">
          <ng-template ngx-datatable-cell-template let-row="row">
            <strong>{{row.tax}}</strong>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'QUANTITY' | translate}}" [flexGrow]="1">
          <ng-template ngx-datatable-cell-template let-row="row">
            <strong>{{row.quantity}}</strong>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'DISCOUNT' | translate}}" [flexGrow]="0.6">
          <ng-template ngx-datatable-cell-template let-row="row">
            {{row?.discountDetails | discountTmpl:currencyCode}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'AMOUNT' | translate}}" [flexGrow]="1">
          <ng-template ngx-datatable-cell-template let-row="row">
            <!-- <strong>{{ row.grossPrice | currency:currencyCode:'symbol'}}</strong> -->
            <app-discount-price-view [originalPrice]="row.grossPriceNoOffer" [finalPrice]="row.grossPrice" [currencyCode]="currencyCode"></app-discount-price-view>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </mat-card>

    <mat-card appearance="outlined" class="new-card pl-0 pr-0 py-1" *ngIf="invoiceDto && invoiceDto.showProducts">
      <mat-card-title class="ml-25 mt-1">{{'PRODUCTS' | translate}}</mat-card-title>
      <ngx-datatable class="material" [rows]="invoiceDto.invoiceProductRowDtos" [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'">
        <ngx-datatable-column name="{{'DESCRIPTION' | translate}}" [flexGrow]="3">
          <ng-template ngx-datatable-cell-template let-row="row">
            <strong>{{row.productName}}</strong><br /><span class="mat-text-muted">{{row.productDescription}}</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'UNIT_PRICE' | translate}}" [flexGrow]="1">
          <ng-template ngx-datatable-cell-template let-row="row">
            <strong>{{row.unitPrice | currency:currencyCode:'symbol'}}</strong>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'TAX_RATE' | translate}}" [flexGrow]="0.6">
          <ng-template ngx-datatable-cell-template let-row="row">
            <strong>{{row.tax}}</strong>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'QUANTITY' | translate}}" [flexGrow]="1">
          <ng-template ngx-datatable-cell-template let-row="row">
            <strong>{{row.quantity}}</strong>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'DISCOUNT' | translate}}" [flexGrow]="0.6">
          <ng-template ngx-datatable-cell-template let-row="row">
            {{row?.discountDetails | discountTmpl:currencyCode}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'AMOUNT' | translate}}" [flexGrow]="1">
          <ng-template ngx-datatable-cell-template let-row="row">
            <!-- <strong>{{ row.grossPrice | currency:currencyCode:'symbol'}}</strong> -->
            <app-discount-price-view [originalPrice]="row.grossPriceNoOffer" [finalPrice]="row.grossPrice" [currencyCode]="currencyCode"></app-discount-price-view>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </mat-card>

    <mat-card appearance="outlined" class="new-card pl-0 pr-0 py-1" *ngIf="invoiceDto && invoiceDto.showServices">
      <mat-card-title class="ml-25 mt-1">{{'SERVICES' | translate}}</mat-card-title>
      <ngx-datatable class="material" [rows]="invoiceDto.invoiceServiceRowDtos" [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'">
        <ngx-datatable-column name="{{'DESCRIPTION' | translate}}" [flexGrow]="3">
          <ng-template ngx-datatable-cell-template let-row="row">
            <strong>{{row?.serviceName}}</strong><br /><span class="mat-text-muted">{{row.serviceDescription}}</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'UNIT_PRICE' | translate}}" [flexGrow]="1">
          <ng-template ngx-datatable-cell-template let-row="row">
            <strong>{{row.unitPrice | currency:currencyCode:'symbol'}}</strong>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'TAX_RATE' | translate}}" [flexGrow]="0.6">
          <ng-template ngx-datatable-cell-template let-row="row">
            <strong>{{row.tax}}</strong>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'QUANTITY' | translate}}" [flexGrow]="1">
          <ng-template ngx-datatable-cell-template let-row="row">
            <strong>{{row.quantity}}</strong>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'DISCOUNT' | translate}}" [flexGrow]="0.6">
          <ng-template ngx-datatable-cell-template let-row="row">
            {{row?.discountDetails | discountTmpl:currencyCode}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'AMOUNT' | translate}}" [flexGrow]="1">
          <ng-template ngx-datatable-cell-template let-row="row">
            <!-- <strong>{{ row.grossPrice | currency:currencyCode:'symbol'}}</strong> -->
            <app-discount-price-view [originalPrice]="row.grossPriceNoOffer" [finalPrice]="row.grossPrice" [currencyCode]="currencyCode"></app-discount-price-view>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </mat-card>

    <mat-card appearance="outlined" class="new-card py-1">
      <div *ngIf="invoice.discountId" fxLayout="column" fxLayout.gt-xs="column" fxLayout.gt-sm="row" fxLayoutWrap="wrap">
        <div fxFlex="100" class="mt-1 mdc-mb-1">
          <strong>{{discountItemsById[invoice.discountId]?.code || 'CUSTOM'}}</strong> - {{invoice?.discountDetails |
          discountTmpl:currencyCode}}
        </div>
      </div>
      <div fxLayout="column" fxLayout.gt-xs="column" fxLayout.gt-sm="row" fxLayoutWrap="wrap">
        <div fxFlex.gt-sm="{{ invoice.totalTips > 0 ? '20' : '25'}}" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
          <h6 class="text-uppercase text-md ma-0 text-bold">{{'SUBTOTAL' | translate}}</h6>
          <h1 *ngIf="invoice?.discountId" class="ma-0">{{invoice.subTotalWithoutDiscount |
            currency:currencyCode:'symbol'}}</h1>
          <h1 *ngIf="!invoice?.discountId" class="ma-0">{{invoice.subTotal | currency:currencyCode:'symbol'}}</h1>
        </div>
        <div fxFlex.gt-sm="{{ invoice.totalTips > 0 ? '20' : '25'}}" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
          <h6 class="text-uppercase text-md ma-0 text-bold">{{'TAX' | translate}}</h6>
          <h1 *ngIf="invoice?.discountId" class="ma-0">{{invoice.taxWithoutDiscount | currency:currencyCode:'symbol'}}
          </h1>
          <h1 *ngIf="!invoice?.discountId" class="ma-0">{{invoice.tax | currency:currencyCode:'symbol'}}</h1>
        </div>
        <div fxFlex.gt-sm="{{ invoice.totalTips > 0 ? '20' : '25'}}" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
          <h6 class="text-uppercase text-md ma-0 text-bold">{{'DISCOUNT' | translate}}</h6>
          <h1 *ngIf="invoice?.discountId" class="ma-0">{{invoice.invoiceDiscount | currency:currencyCode:'symbol'}}</h1>
          <h1 *ngIf="!invoice?.discountId" class="ma-0">{{0.00 | currency:currencyCode:'symbol'}}</h1>
        </div>
        <div fxFlex.gt-sm="{{ invoice.totalTips > 0 ? '20' : '25'}}" fxFlex.gt-xs="50" fxFlex="100" class="{{ invoice.totalTips > 0 ? 'mt-1 mdc-mb-1 text-xs-left' : 'mt-1 mdc-mb-1 text-xs-left text-sm-right'}}">
          <h6 class="text-uppercase text-sm ma-0 text-bold">{{'TOTAL' | translate}}</h6>
          <h1 class="ma-0 mat-text-accent">{{ invoice.total | currency:currencyCode:'symbol'}}</h1>
        </div>
        <div *ngIf="invoice.totalTips > 0" fxFlex.gt-sm="{{ invoice.totalTips > 0 ? '20' : '25'}}" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
          <h6 class="text-uppercase text-md ma-0 text-bold">{{'TIPS' | translate}}</h6>
          <h1 class="ma-0">{{invoice.totalTips | currency:currencyCode:'symbol'}}</h1>
        </div>
      </div>
    </mat-card>

    <mat-card appearance="outlined" class="new-card py-1">
      <p class="text-md">
        <strong>
          {{'PAYMENT_TERMS_AND_POLICIES' | translate}}
        </strong>
        {{invoice.invoiceTerms | translate}}
      </p>
    </mat-card>

    <!--mat-card>
    <button color="accent" mat-raised-button (click)="gotoInvoice()">View Full Invoice</button>
  </mat-card-->
  </div>
</div>
