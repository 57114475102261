<div class="customers-email-modal customers-email-modal-mobile">
  <mat-toolbar color="primary">
    <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <span>{{'INVITE_CUSTOMER_PORTAL' | translate}}</span>
      <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="m-0 mdc-p-34" *ngIf="!sending">
    <div fxFlex fxLayout="column">
      <mat-form-field appearance="outline">
        <mat-label>{{'EMAIL_TO' | translate}}</mat-label>
        <input type="text" matInput name="emailTo" #emailToField="ngModel" [(ngModel)]="emailTo" placeholder="{{'ENTER_EMAIL' | translate}}" required>
        <!-- <mat-hint align="end">{{emailToField.value?.length || 0}} / 50</mat-hint> -->
        <mat-error *ngIf="emailToField.hasError('required')">
          {{'EMAIL_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'EMAIL_SUBJECT' | translate}}</mat-label>
        <input type="text" matInput name="subject" [maxlength]="50" #emailSubject="ngModel" [(ngModel)]="emailSubjectText" placeholder="{{'EMAIL_SUBJECT' | translate}}" required>
        <mat-hint align="end">{{emailSubject.value?.length || 0}} / 50</mat-hint>
        <mat-error *ngIf="emailSubject.hasError('required')">
          {{'SUBJECT_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <app-flo-editor [text]="emailMessageText" (textUpdated)="emailMessageBodyUpdated($event)"></app-flo-editor>
      <app-upload-files #uploadFiles [contextIdDto]="contextIdDto" [savedFiles]="savedFiles"></app-upload-files>
    </div>
    <!-- <mat-card>
      <mat-card-content fxLayout="column" fxFlex>
        <mat-slide-toggle name="sendEmail" [(ngModel)]="sendEmail">
          {{'Email' | translate}}
        </mat-slide-toggle>
        <br>

      </mat-card-content>
    </mat-card> -->

  </div>

  <div mat-dialog-content class="m-0 mdc-p-34" *ngIf="sending">
    <mat-spinner></mat-spinner>
  </div>

  <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="center center">
    <button mat-raised-button color="accent" (click)="send()">{{'SEND' | translate}}</button>
  </div>


</div>