import { Component, Input, OnInit } from '@angular/core';
import { ContextIdDto } from '@savvy/quickbooks';
import { Router } from '@angular/router';
import { EntitysService } from '@savvy/entity-instance';
import { TrialExpiredService } from '../shared/services/trial-expired.service';

@Component({
    selector: 'app-zero-eis',
    templateUrl: './zeroEis.component.html'
})
export class ZeroEisComponent implements OnInit {

    @Input() contextIdDto: ContextIdDto;
    @Input() entityDefinitionId: string;
    @Input() entityDefinitionLabel: string;
    @Input() entityDefinitionIcon: string;
    @Input() canCreate: boolean;

    constructor(
        private eiApi: EntitysService,
        public trialExpiredService: TrialExpiredService,
        private router: Router) {
    }

    ngOnInit() {
        console.log('inside zero eis');
    }

    create() {
        this.router.navigate(['/entity/createEntity',
            this.entityDefinitionId,
            this.contextIdDto.contextId,
            this.contextIdDto.contextIdType
        ]);
    }
}
