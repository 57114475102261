import { Component, Input, OnInit } from '@angular/core';
import { EventBusService } from './EventBusService';
import { ChangeListener } from './changeListener';
import { UntypedFormGroup } from '@angular/forms';
import { ElementControlService } from './ElementControlService';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { NavigateService } from '../entry/NavigateService';
import { EicompService } from '@savvy/entity-instance-composite';
import { NavChangeService } from './NavChangeService';
import { ViewEntityEmbeddedData } from '@savvy/view-definition';
import { ContextIdDto } from '@savvy/quickbooks';
import { LoadEiViewResponse, ViewcompService } from '@savvy/view-composite';

@Component({
  selector: 'app-view-embedded-entity-instance',
  templateUrl: './viewEmbeddedEntityInstance.component.html'
})
export class ViewEmbeddedEntityInstancePanelComponent implements OnInit {

  @Input() viewEntityEmbeddedData: ViewEntityEmbeddedData;
  @Input() contextIdDto: ContextIdDto;
  @Input() eventBus: EventBusService;

  loadEiViewResponse: any;

  changeListener = new ChangeListener();

  public form: UntypedFormGroup; // our form model

  constructor(
    private ecs: ElementControlService,
    private notify: FloSnackbarComponent,
    private navigateService: NavigateService,
    private eiCompositeApi: EicompService,
    private viewcompService: ViewcompService,
    private navChange: NavChangeService) {

  }

  ngOnInit(): void {
    this.loadEntity();
    if (!this.eventBus) {
      // this.eventBus.eventAdded$.subscribe(event => this.onEvent(event));
    }
  }

  public loadEntity() {
    if (this.viewEntityEmbeddedData && this.viewEntityEmbeddedData.entityInstanceId &&
      this.viewEntityEmbeddedData.entityInstanceId.id) {
      this.viewcompService.loadEiByViewDefId(this.viewEntityEmbeddedData.entityInstanceId.id,
        this.viewEntityEmbeddedData.viewEntityEmbeddedDefinition.viewDefinitionId.id,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType).subscribe(
          response => {
            this.display(response);
          }
        );
    }
  }



  display(loadEiViewResponse: LoadEiViewResponse) {
    this.loadEiViewResponse = loadEiViewResponse;
    this.createForm();
    this.navChange.add('viewEntity:' +
      loadEiViewResponse.viewContextDto.addEditEntityInstanceContextDto.entityDefinitionDto.entityDefinitionId.id);
  }

  createForm() {
    if (this.loadEiViewResponse.viewRootElementDto) {
      this.form = this.ecs.toFormGroup(this.loadEiViewResponse.viewRootElementDto,
        this.loadEiViewResponse.viewContextDto.addEditEntityInstanceContextDto.entityDefinitionDto);
    } else {
      this.form = this.ecs.emptyFormGroup();
    }
  }
}
