import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContextIdDto } from '@savvy/quickbooks';
import { CreateTask, Task, TaskService, UpdateTask } from '@savvy/task';
import { UsersService } from '@savvy/user';
import { RoleDto, RolesService } from '@savvy/role';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { EventBusService } from '../element/EventBusService';
import { EventType, UiEvent } from '../event/UiEvent';
import { TranslateService } from '@ngx-translate/core';
import { DateTimeAdapter, NativeDateTimeAdapter, OWL_DATE_TIME_LOCALE, OwlDateTimeIntl } from '@danielmoncada/angular-datetime-picker';
import { UserDto } from '@savvy/ui';
import { Platform } from '@angular/cdk/platform';
import { LocalIntl } from 'src/app/services/local-intl.service';

@Component({
  selector: 'app-add-edit-task',
  templateUrl: 'addEditTask.html',
  providers: [
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-US' },
    { provide: DateTimeAdapter, useClass: NativeDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE, Platform] },
    { provide: OwlDateTimeIntl, useClass: LocalIntl },
  ]
})
export class AddEditTaskComponent implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() visible: boolean;
  @Input() eventBus: EventBusService;

  model = <Task>{};

  dueDate: Date; // TODO: MAKE IT LocalDateTime IN TaskDTO!! then we should get a js Date!

  users: Array<UserDto>;
  roles: Array<RoleDto>;

  @Output()
  created = new EventEmitter();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: TaskService,
    private userApi: UsersService,
    private roleApi: RolesService,
    private translateService: TranslateService,
    dateTimeAdapter: DateTimeAdapter<any>,
    private notify: FloSnackbarComponent) {
    dateTimeAdapter.setLocale(this.translateService.currentLang);
  }

  ngOnInit() {
    this.eventBus.eventAdded$.subscribe(event => this.onEvent(event));
    /*
            if (taskId != null && taskId.length > 0) {
                // Load task

              console.log('loading task with id ['
                  + taskId + '] contextId ['
                  + this.contextIdDto.contextId + '] contextIdType ['
                  + this.contextIdDto.contextIdType + ']');

              this.api.getTask(taskId,
                  this.contextIdDto.contextId,
                  this.contextIdDto.contextIdType).subscribe(
                    response => {
                        this.model = response;
                    });
            }*/
    this.loadUsers();
    this.loadRoles();
  }

  loadUsers() {
    this.userApi.getAllUsers(this.contextIdDto.contextId).subscribe(
      response => {
        this.users = response;
      }
    );
  }

  loadRoles() {
    this.roleApi.getAllRolesForOwner(this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(
      roles => {
        this.roles = roles;
      }
    );
  }

  onChange(event) {
    if (event.checked) {
      if (this.model.roleIds == null) {
        this.model.roleIds = [];
      }
      this.model.roleIds.push(event.source.value);
    } else {
      if (this.model.roleIds) {
        const index = this.model.roleIds.indexOf(event.source.value, 0);
        if (index > -1) {
          this.model.roleIds.splice(index, 1);
        }
      }
    }
  }

  addTask() {
    const req = <CreateTask>{};
    req.task = this.model;
    console.log('Adding task with ' + this.contextIdDto.contextId);
    req.contextId = this.contextIdDto.contextId;
    req.contextIdType = this.contextIdDto.contextIdType;

    this.api.createTask(req).subscribe(response => {
      this.model = response;
      this.translateService.get('Successfully created new task').subscribe(value => {
        this.notify.message = value;
        this.notify.open();
      });
      this.created.emit('created');
      this.eventBus.addTaskEvent(EventType.TASK_CREATED, this.model.taskId);
    });
  }


  updateTask() {
    const req = <UpdateTask>{};
    req.task = this.model;
    req.contextId = this.contextIdDto.contextId;
    req.contextIdType = this.contextIdDto.contextIdType;

    this.api.updateTask(req).subscribe(response => {

      // Stay on page? and change it to edit screen?
      this.model = response;
      this.translateService.get('Successfully updated task').subscribe(value => {
        this.notify.message = value;
        this.notify.open();
      });
      this.eventBus.addTaskEvent(EventType.TASK_UPDATED, this.model.taskId);

    });
  }


  cancelAddEditTask() {
    this.eventBus.addEvent(EventType.CANCEL_ADD_EDIT_TASK);
  }

  private onEvent(event: UiEvent) {
    if (event.eventType === EventType.CREATE_TASK) {
      this.model = <Task>{};
      this.loadUsers();
      this.loadRoles();
    }
  }
}
