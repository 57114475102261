import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-social',
  templateUrl: './social.component.html'
//  styleUrls: ['./social.component.css']
})
export class SocialComponent {

  shareObj = {
    href: '',
    hashtag: ''
  };

  @Input() facebookLogin = false;
  @Input() hashtag;
  @Input() shareUrl;

  constructor() {
    this.shareObj.href = this.shareUrl;
    this.shareObj.hashtag = this.hashtag;
  }

  signOut() {
    // if (this.socialAuthService.isSocialLoggedIn()) {
    //   this.socialAuthService.signOut().catch((err) => {

    //   });
    // }
  }

  getSocialUser(socialUser) {
    console.log(socialUser);
  }
}
