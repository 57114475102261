<div fxLayout="row">
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px grid">
    <div fxFlex="40">
      <form [formGroup]="depositForm">

        <div fxLayout="row wrap">
          <mat-radio-group class="example-radio-group" fxFlex formControlName="paymentType"
            (change)="onPaymentTypeChange()">
            <mat-radio-button class="example-radio-button" value="FULL_AMOUNT">{{'FULL_AMOUNT' | translate}}
            </mat-radio-button>
            <mat-radio-button class="example-radio-button" value="AMOUNT">{{'AMOUNT' | translate}}</mat-radio-button>
              <mat-radio-button class="example-radio-button" value="PERCENTAGE">{{'PERCENTAGE' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <br>
        <div *ngIf="depositForm.value.paymentType !== 'FULL_AMOUNT'" fxLayout="row" fxLayout.lt-md="column"
          fxLayoutGap="7px" fxLayoutGap.xs="0px" fxFlexFill fxLayoutAlign.gt-sm="left">
          <mat-form-field *ngIf="depositForm.value.paymentType === 'AMOUNT'" fxFlex appearance="outline">
            <div matSuffix>{{ currencyCode }}</div>
            <mat-label>{{'AMOUNT' | translate}}</mat-label>
            <input type="text" appNumberOnly [decimalPoints]="2" matInput name="amount"
              placeholder="{{'AMOUNT' | translate}}" formControlName="amount" (change)="checkIsValid()" required>
          </mat-form-field>
          <mat-form-field *ngIf="depositForm.value.paymentType === 'PERCENTAGE'" fxFlex appearance="outline">
            <div matSuffix>%</div>
            <mat-label>{{'PERCENTAGE' | translate}}</mat-label>
            <input type="text" appNumberOnly matInput name="percentage" placeholder="{{'PERCENTAGE' | translate}}"
              formControlName="percentage" (change)="checkIsValid()" required>
          </mat-form-field>
        </div>
      </form>
    </div>
  </div>
</div>
