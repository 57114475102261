import { Component, HostListener, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Customer,
  CustomerCompositeService,
  CustomerService,
  ListCustomers, LoadCustomersPagedResponse, UpdateCustomer
} from '@savvy/customer';
import { CustomerPortalSettingsService } from '@savvy/customer-portal-settings';
import { ContextIdDto, MenuDefinition } from '@savvy/view-definition';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { NavChangeService } from '../element/NavChangeService';
import { SendToAllMessageWizardComponent } from '../send-to-all/send-to-all-message-wizard/send-to-all-message-wizard.component';
import { Page } from '../shared/model/page';
import { TrialExpiredService } from '../shared/services/trial-expired.service';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import MenuItemTypeEnum = MenuDefinition.MenuItemTypeEnum;
// import { LoadCustomersResponse } from '@savvy/customer/model/loadCustomersResponse';
import { AlphaFilterComponent } from '@savvy/alpha-filter';
import { PetService } from '@savvy/pet';
import { CustomerSearchResultDto, PaginationData, PerformCustomerSearch, PerformCustomerSearchResponse, SearchService } from "@savvy/search";
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ConfService } from 'src/app/flo/config/conf.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ClearObservable } from '../shared/classes/clear-observable';
import { CustomerHandlerService } from './customer-handler.service';
import { AddCustomerComponent } from './customer/addCustomer.component';
import { CustomerFilter, StatusEnum } from './customersGrid.component';
import { FlagDefinition } from '@savvy/flags';


@Component({
  selector: 'app-customers-alpha',
  templateUrl: './customersAlpha.component.html',
  styleUrls: ['./customersAlpha.component.scss']
})
export class CustomersAlphaComponent extends ClearObservable implements OnInit, OnDestroy {
  @ViewChild('alphabetFilterComponent', { static: false }) alphabetFilterComponent: AlphaFilterComponent;
  @Input() showPlaceholder: boolean;
  @Input() showFilters = true;


  customers: Array<CustomerSearchResultDto> = [];
  page = new Page();

  contextIdDto: ContextIdDto;
  subscriptions: Subscription[] = [];

  selected: Customer[] = [];
  loaded = false;
  firstNameSorted = true;
  sortAscending = true;
  includeDeleted = false;
  approvalNeeded: boolean | null = null;

  portalEnabled = false;
  searchKey = '';
  appendingData = false;


  customerImage = '/assets/images/customer-profile.png';

  includeFlagDefs = new Array();
  excludeFlagDefs = new Array();

  customerFilter: CustomerFilter = new CustomerFilter();
  statusEnum = StatusEnum;

  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;


  constructor(
    private router: Router,
    private notify: FloSnackbarComponent,
    public trialExpiredService: TrialExpiredService,
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private navChangeService: NavChangeService,
    public deviceService: DeviceDetectorService,
    private customerPortalSettingsService: CustomerPortalSettingsService,
    private dialog: MatDialog,
    private renderer: Renderer2,
    private petService: PetService,
    private customerHandlerService: CustomerHandlerService,
    private searchService: SearchService,
    private customerCompositeService: CustomerCompositeService

  ) {
    super();
    this.showFilters = this.deviceService.isMobile() ? false : true;
  }

  @HostListener('click', ['$event'])
  handleClick(event) {
    // handle event
    if (event.srcElement?.offsetParent?.classList?.contains('alpha-letters-list')) {
      // alphabet changed
      this.searchKey = event.srcElement.innerText;
      this.page.pageNumber = 0;
      this.searchCustomer(this.searchKey);
      // this.searchCustomer(this.searchKey);
    }
  }

  getUrl(reference: string) {
    if (reference) {
      return ConfService.apiUrl() + '/rest/storage?public=false&contextId=' +
        this.contextIdDto.contextId + '&contextIdType=' +
        this.contextIdDto.contextIdType + '&id=' + reference;
    }
    return null;
  }

  ngOnInit() {
    console.log('inside on init CustomersV2Component');
    let offset = 0;
    if (sessionStorage.getItem('pageNumber')) {
      offset = Number(sessionStorage.getItem('pageNumber'));
      sessionStorage.removeItem('pageNumber');
    }
    this.route.params.subscribe(params => {
      this.contextIdDto = <ContextIdDto>{};
      if (params['contextId'] && params['contextIdType']) {
        this.contextIdDto.contextId = params['contextId'];
        this.contextIdDto.contextIdType = params['contextIdType'];
        this.loadAllCustomers();

        setTimeout(() => {
          if (this.alphabetFilterComponent) {
            this.renderer.listen(this.alphabetFilterComponent.searchListEl.nativeElement, 'scroll', this.onScrollList.bind(this));
            this.alphabetFilterComponent.form.get('search').valueChanges
              .pipe(
                takeUntil(this.destroy$),
                debounceTime(600),
                distinctUntilChanged()
              )
              .subscribe(search => {
                this.searchKey = search;
                if (search) {
                  this.page.pageNumber = 0;
                  // this.searchCustomer(search);
                } else {
                  this.loadAllCustomers();
                }
              });
          }
        });
        this.loadPortalEnabled();
      }
    });
  }

  onScrollList(e) {
    if (e.currentTarget) {
      // In chrome and some browser scroll is given to body tag
      const pos = e.currentTarget.scrollTop + e.currentTarget.offsetHeight;
      const max = e.currentTarget.scrollHeight;
      // pos/max will give you the distance between scroll bottom and and bottom of screen in percentage.
      if ((pos + 200) >= max && !this.searchKey && !this.appendingData) {
        // Do your action here
        this.page.pageNumber += 1;
        this.appendingData = true;
        this.appendData();
      }
    }
  }

  appendData() {
    console.log('append data');
    const req: ListCustomers = {
      contextIdDto: this.contextIdDto
    };
    req.pageNum = this.page.pageNumber;
    req.pageSize = this.page.size;
    req.ascending = this.customerFilter.sortAscending;
    req.includeDeleted = this.customerFilter.includeDeleted;
    req.searchString = this.customerFilter.searchKey;
    req.includeFlagDefIds = this.customerFilter.includeFlagDefs;
    req.excludeFlagDefIds = this.customerFilter.excludeFlagDefs;
    req.approvalNeeded = this.customerFilter.approvalNeeded || false;
    // req.form = this.customerFilter.formSubmitted;

    this.customerCompositeService.listCustomersPaged(req)
      .subscribe((response: LoadCustomersPagedResponse) => {
        if (response.customers?.contents?.length) {
          this.customers = [...this.customers, ...response.customers?.contents] || [];
          this.page.totalElements = response?.customers?.totalElements;
          this.page.totalPages = response?.customers?.totalPages;
          this.appendingData = false;
        }
      });
  }

  loadCustomers(searchKey?: string) {
    const req: ListCustomers = {
      contextIdDto: this.contextIdDto
    };
    req.pageNum = 1;
    req.pageSize = 100;
    req.ascending = this.customerFilter.sortAscending;
    req.includeDeleted = this.customerFilter.includeDeleted;
    req.searchString = searchKey || this.customerFilter.searchKey;
    req.includeFlagDefIds = this.customerFilter.includeFlagDefs;
    req.excludeFlagDefIds = this.customerFilter.excludeFlagDefs;
    req.approvalNeeded = this.customerFilter.approvalNeeded || false;
    // req.form = this.customerFilter.formSubmitted;

    this.customerCompositeService.listCustomersPaged(req)
      .subscribe((response: LoadCustomersPagedResponse) => {
        this.handleCustomers2(response);
      });
  }

  handleCustomers2(response: LoadCustomersPagedResponse) {
    console.log('customers are ' + response.customers?.contents?.length);
    // response?.customers?.contents.forEach(el =>
    //   el?.fullName = `${el.customerDto.userDto.firstName} ${el.customerDto.userDto.lastName}`);
    this.customers = response.customers?.contents || [];
    this.page.totalElements = response?.customers?.totalElements;
    this.page.totalPages = response?.customers?.totalPages;
  }

  searchCustomer(search: string) {
    const req = <PerformCustomerSearch>{};
    req.contextIdDto = this.contextIdDto;
    req.paginationData = <PaginationData>{};
    this.page.pageNumber = 1;
    req.paginationData.pageNumber = 1;
    req.paginationData.pageSize = this.page.size;
    req.ascending = this.customerFilter.sortAscending;
    req.deleteOnly = this.customerFilter.includeDeleted;
    req.searchString = this.customerFilter.searchKey || search;
    req.includeFlags = this.customerFilter.includeFlagDefs;
    req.excludeFlags = this.customerFilter.excludeFlagDefs;
    req.approvalNeeded = this.customerFilter.approvalNeeded;
    req.formSubmitted = this.customerFilter.formSubmitted;

    this.searchService.performCustomerSearch(req)
      .subscribe(response => {
        this.handleCustomers(response);
      });
  }

  getCustomerImage(item: Customer) {
    if (item && item.profilePicReference) {
      return this.getUrl(item.profilePicReference);
    }
    return this.customerImage;
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  loadPortalEnabled() {
    this.customerPortalSettingsService.getOrCreatePortalSettings(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType).subscribe(response => {
        this.portalEnabled = response.portalEnabled;
      });
  }

  onSort(event) {
    // event was triggered, start sort sequence
    console.log('Sort1', event);
    console.log('Sort2', event.sorts);
    console.log('Sort2[0]', event.sorts[0]);
    this.sortAscending = event.newValue === 'asc';
    this.firstNameSorted = event.column.name !== 'Last Name';
    console.log('firstNameSorted', this.firstNameSorted);
    // Reset back to page 1
    this.setPage({ offset: 0, size: 25 });
  }

  handleCustomers(response: PerformCustomerSearchResponse) {
    console.log('customers are ' + response.customerSearchResultDtos?.length);
    if (response?.customerSearchResultDtos) {
      this.customers = response.customerSearchResultDtos;
      // this.page.totalElements = response.customers.totalElements;
      // this.page.totalPages = response.customers.totalPages;
      if (!this.customers.length) {
        this.customers.push({
          firstName: ` You don't have any customers starting ${this.searchKey ? 'from ' + this.searchKey : ''}`,
          ownerId: '', id: ''
        });
        this.notify.message = ` You don't have any customers starting ${this.searchKey ? 'from ' + this.searchKey : ''}`;
        this.notify.open();
      }
      for (const customer of this.customers) {
        if (customer.firstName === null) {
          customer.firstName = '';
        }
        if (customer.lastName === null) {
          customer.lastName = '';
        }
      }
      this.customers = [...this.customers];
      this.loaded = true;
    }
  }

  listInstances() {
    //   alert('include deleted' + this.includeDeleted);
    console.log('listInstances in customersV2.component.ts');
    this.navChangeService.add(MenuItemTypeEnum.Customers);
    console.log('page number is ' + this.page.pageNumber);
    console.log('page size is ' + this.page.size);
    console.log('firstNameSorted is ' + this.firstNameSorted);
    console.log('sortAscending is ' + this.sortAscending);


    const req = <PerformCustomerSearch>{};
    req.contextIdDto = this.contextIdDto;
    req.paginationData = <PaginationData>{};
    req.paginationData.pageNumber = 1;
    req.paginationData.pageSize = 100;
    req.ascending = this.customerFilter.sortAscending;
    req.deleteOnly = this.customerFilter.includeDeleted;
    req.searchString = this.customerFilter.searchKey;
    req.includeFlags = this.customerFilter.includeFlagDefs;
    req.excludeFlags = this.customerFilter.excludeFlagDefs;
    req.approvalNeeded = this.customerFilter.approvalNeeded;
    req.formSubmitted = this.customerFilter.formSubmitted;

    this.searchService.performCustomerSearch(req)
      .subscribe(response => {
        this.handleCustomers(response);
      });
  }

  /**
   * Populate the table with new data based on the page number
   *
   * @param page The page to select
   */
  setPage(pageInfo) {
    console.log('pageInfo is ' + pageInfo);
    this.page.pageNumber = pageInfo.offset;
    this.page.size = pageInfo.size ? pageInfo.size : this.page.size;
    // this.listInstances();
    this.loadCustomers();
  }

  loadAllCustomers() {
    this.searchKey = '';
    this.setPage({ offset: 0, size: 100 });
  }

  onSelect(event) {
    if (event.selected[0].id) {
      this.edit(event.selected[0]);
    }
  }

  callback = () => {
    this.setPage({ offset: 0, size: 100 });
  }

  edit(row: Customer) {
    this.customerHandlerService.edit(this.contextIdDto, row.id, this.callback)
  }

  view(row: Customer) {
    this.customerHandlerService.view(this.contextIdDto, row.id, this.callback)
  }

  onSelectAlpha(event) {
    // if (event.id) {
    //   sessionStorage.setItem('selectFromGrid', 'false');
    //   sessionStorage.setItem('pageNumber', String(this.page.pageNumber));
    //   this.router.navigate(['/customer/viewCustomer',
    //     this.contextIdDto.contextId,
    //     this.contextIdDto.contextIdType,
    //     event.id]);
    // }
  }

  updateFilter() {
  }

  create() {
    console.log('inside create');
    this.router.navigate(['/customer/addCustomer',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType]);
  }

  openSendModal() {
    this.dialog.open(SendToAllMessageWizardComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        recipientType: 'CUSTOMER'
      },
      panelClass: ['scrollable-modal', 'customers-messaging-dialog'],
      autoFocus: true
    });
  }

  onCustomerMessagesClick() {
    this.router.navigate(['/customer/customerMessageRequests',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      'CUSTOMER']);
  }

  deleteCustomerForever(row: Customer) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete Forever',
        message: `You are about to permanently delete this customer ${row.firstName} ${row.lastName}, are you sure you want to proceed?`
      },
      height: 'auto',
      width: '480px',
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.customerService.deleteCustomerForever(this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          row.id
        ).subscribe(() => {
          this.notify.message = 'Customer deleted permanently';
          this.notify.open();
          this.listInstances();
        });
      }
    });
  }

  archive(row: Customer) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Archive',
        message: `Are you sure you want to archive this customer ${row.firstName} ${row.lastName}?`
      },
      height: 'auto',
      width: '360px',
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.customerService.deleteCustomer(this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          row.id
        ).subscribe(() => {
          this.notify.message = 'Archived Customer';
          this.notify.open();
          this.listInstances();
        });
      }
    });
  }

  unarchive(row: Customer) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Unarchive',
        message: `Are you sure you want to unarchive this customer ${row.firstName} ${row.lastName}?`
      },
      height: 'auto',
      width: '360px',
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.customerService.undoDeleteCustomer(this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          row.id).subscribe(() => {
            this.notify.message = 'Unarchived Customer';
            this.notify.open();
            this.listInstances();
          });
      }
    });
  }

  callMobile(mobileNumner) {
    if (mobileNumner) {
      window.open(`tel:${mobileNumner}`);
    }
  }

  emailTo(email) {
    if (email) {
      window.open(`mailto:${email}`);
    }
  }

  createNewCustomer() {
    const createPetModal = this.dialog.open(AddCustomerComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        pageType: 'addCustomer',
        isViewMode: true
      },
      maxWidth: '1024px',
      panelClass: [
        'modal-100',
        'helpwindow',
        'scrollable-modal-2'
      ],
      autoFocus: false,
      disableClose: true
    });

    createPetModal.afterClosed()
      .subscribe(
        () => {
          this.setPage({ offset: 0, size: 20 });
        });
  }

  verifyPets(customerId: string) {
    if (customerId) {
      this.petService.loadCustomersPets(
        customerId).subscribe(pets => {
          if (pets && pets?.length) {
            for (const pet of pets) {
              pet.approvalNeeded = false;
              this.petService.updatePet(pet).subscribe(res => {
                console.log('Pet approved', res);
              });
            }
          }
        });
    }
  }

  verify(row: Customer) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Verify',
        message: `Are you sure you want to verify this customer ${row.firstName} ${row.lastName}?`
      },
      height: 'auto',
      width: '360px',
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        row.approvalNeeded = false;
        const updateCustomer: UpdateCustomer = {
          contextIdDto: this.contextIdDto,
          customer: row
        };
        this.customerService.updateCustomer(updateCustomer).subscribe(response => {
          this.verifyPets(response?.customerId?.id);
          this.notify.message = 'Customer verified';
          this.notify.open();
          this.setPage({ offset: 0, size: 20 });
        });
      }
    });
  }

  onScrollDown(ev) {
    console.log("scrolled down!!", ev);
    this.page.pageNumber++;

    //   alert('include deleted' + this.includeDeleted);
    console.log('listInstances in customersV2.component.ts');
    this.navChangeService.add(MenuItemTypeEnum.Customers);
    console.log('page number is ' + this.page.pageNumber);
    console.log('page size is ' + this.page.size);
    console.log('firstNameSorted is ' + this.firstNameSorted);
    console.log('sortAscending is ' + this.sortAscending);
  }

  searchKeyUpdated(searchText) {
    this.customerFilter.searchKey = searchText;
    this.searchCustomer(searchText);
  }

  includeFlagsChange(flagDefs: FlagDefinition[]) {
    this.customerFilter.includeFlagDefs = new Array();
    if (flagDefs !== undefined) {
      flagDefs.forEach(flagDef => {
        this.customerFilter.includeFlagDefs.push(flagDef.id);
      });
    }
    this.loadCustomers();
  }

  excludeFlagsChange(flagDefs: FlagDefinition[]) {
    this.customerFilter.excludeFlagDefs = new Array();
    if (flagDefs !== undefined) {
      flagDefs.forEach(flagDef => {
        this.customerFilter.excludeFlagDefs.push(flagDef.id);
      });
    }
    this.loadCustomers();
  }

  statusChanged(newStatus: StatusEnum) {
    if (newStatus) {
      switch (newStatus) {
        case StatusEnum.Active:
          this.customerFilter.approvalNeeded = false;
          this.customerFilter.includeDeleted = false;
          break;
        case StatusEnum.Archived:
          this.customerFilter.includeDeleted = true;
          this.customerFilter.approvalNeeded = false;
          break;
        case StatusEnum.NotVerified:
          this.customerFilter.includeDeleted = false;
          this.customerFilter.approvalNeeded = true;
          this.customerFilter.formSubmitted = true;
          break;
        case StatusEnum.AwaitingRegistration:
          this.customerFilter.includeDeleted = false;
          this.customerFilter.approvalNeeded = true;
          this.customerFilter.formSubmitted = false;
          break;
        default:
          this.customerFilter.approvalNeeded = null;
          break;
      }
    }
    this.setPage({ offset: 0, size: 20 });

  }

  resetFilters() {
    this.customerFilter = new CustomerFilter();
    // this.flagFilterComponent.formGroup = new UntypedFormGroup({
    //   includeFlags: new UntypedFormControl([]),
    //   excludeFlags: new UntypedFormControl([]),
    // });
    this.loadCustomers();
  }


}


