import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";

import {ContextIdDto, LinkId, Notification} from '@savvy/notification';
import {Customer, CustomerService} from "@savvy/customer";
import {FloSnackbarComponent} from "../../snackbar/floSnackbar.component";
import {MatDialog} from "@angular/material/dialog";
import {ViewCustomerV2Component} from "../../customer/view-customer-v2/view-customer-v2.component";
import {UrlLookupService} from "../../shared/services/urlLookup.service";

@Component({
  selector: 'app-check-in-form-notification',
  templateUrl: './checkInFormNotification.component.html'
})
export class CheckInFormNotificationComponent implements OnInit {

  @Input() notification: Notification;
  @Input() contextIdDto: ContextIdDto;

  @Output() notificationProcessed: EventEmitter<any> = new EventEmitter();


  constructor(private notify: FloSnackbarComponent,
              private urlLookupService: UrlLookupService,
              private customerService: CustomerService,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  gotoCheckinForm() {

    const checkinFormId = this.getLinkId('CHECKIN_FORM_ID', this.notification.linkIds);
    const url = this.urlLookupService.portalUrl() + '/viewSubmittedCheckinForm.html?id=' +
      this.contextIdDto.contextId + '&checkinFormId='
      + checkinFormId + this.urlLookupService.getEnv();
    this.urlLookupService.openNewTab(url);
  }

  getLinkId(type: string, linkIds: Array<LinkId>): string {
    let linkIdToRet;

    linkIds.forEach(linkId => {
      if (linkId.linkedIdType === type) {
        linkIdToRet = linkId.linkedId;
      }
    });
    return linkIdToRet;
  }

  viewCustomer(customerId: string) {
    const contextIdDto = this.contextIdDto;
    const viewCustomerModal = this.dialog.open(ViewCustomerV2Component, {
      data: {
        contextIdDto,
        customerId
      },
      maxWidth: '1024px',
      panelClass: [
        'modal-100',
        'helpwindow',
        'petProfileModal'
      ],
      autoFocus: false
    });

    viewCustomerModal.afterClosed()
      .subscribe(
        () => {
        });
  }
}
