<div class="flexbox-parent">
    <div fxLayout="row" fxLayoutAlign="center center" style="height: 60vh;">
        <div style="text-align:center;">
            <app-zero-image name="email"></app-zero-image><br>
            <h5 class="ma-0">{{'ENABLE_MESSAGES_DESC' | translate}}.</h5><br>
            <button mat-raised-button color="accent" (click)="gotoEmailSettings()">{{'SETUP_MESSAGING' | translate}}</button>

        </div>

    </div>
</div>