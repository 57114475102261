<mat-toolbar color="primary" *ngIf="showToolbar">
  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="nogrow">
      <button mat-button (click)="back()" class="mdc-px-2">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
      <span>{{'PET_TYPES' | translate}}</span>
    </div>
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="end center">
      <button mat-raised-button class="mx-4" color="accent" (click)="createPetType()">{{'CREATE' | translate}}</button>
    </div>
  </div>
</mat-toolbar>
<div *ngIf="!showToolbar" fxLayout="row" fxLayoutAlign="end center">
  <button mat-raised-button class="mx-4" color="accent" (click)="createPetType()">{{'CREATE' | translate}}</button>
</div>



<ngx-datatable *ngIf="petTypes && petTypes.length" class="material" [rows]="petTypes" [columnMode]="'force'" [headerHeight]="50" [limit]="10" [rowHeight]="'auto'" [footerHeight]="50">
  <ngx-datatable-column name="{{'NAME' | translate}}" prop="name"></ngx-datatable-column>
  <ngx-datatable-column name="{{'DESCRIPTION' | translate}}" prop="description"></ngx-datatable-column>

  <ngx-datatable-column name="{{'ACTIONS' | translate }}" [flexGrow]="3">
    <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
      <button *ngIf="!row.deleted" mat-raised-button color="accent" (click)="updatePetType(row, rowIndex)">
        {{'EDIT' | translate}}
      </button>
      &nbsp;
      <button *ngIf="!row.deleted" mat-raised-button color="accent" (click)="deletePetType(rowIndex)">
        {{'DELETE' | translate}}
      </button>
      <!-- <button *ngIf="row.deleted" mat-raised-button color="accent" (click)="activatePetRun(row)">Activate</button> -->
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>