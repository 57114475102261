<div class="view-image-container" style="display: flex;
    position: relative;
    text-align: center;
    width: auto;
    height: 450px;
    padding-bottom: 10px;">
  <div class="new-card view-image-card"  style="flex: 0 1 auto;
    display: flex;
    right: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    max-height: 450px;
    justify-content: center;
    overflow: hidden;
    align-items: center;">
    <img
     [appFixImageOrientation]="imageUrl"
     [src]="imageUrl"
     style='flex: 0 1 auto;
        height: 100%;'>

  </div>
</div>

