<mat-toolbar>
  <mat-toolbar-row>{{'NOTIF_PRESENTATION_QUESTION' | translate}}?</mat-toolbar-row>
</mat-toolbar>

<div *ngIf="notificationMethodMatrix && notificationMethodMatrix.notificationMethods &&
    notificationMethodMatrix.notificationMethods.length > 0">
  <ngx-datatable class="material m-0" [rows]="notificationMethodMatrix.notificationMethods" [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'" [columnMode]="'flex'">

    <ngx-datatable-column name="{{'NOTIF_TYPE' | translate}}" [flexGrow]="2">
      <ng-template ngx-datatable-cell-template let-row="row">
        {{row.notificationTypeLabel}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'BELL' | translate}}" [flexGrow]="1">
      <ng-template ngx-datatable-cell-template let-row="row">
        <mat-checkbox [checked]="isBellEnabled(row)" (change)="bellSelectionChanged($event, row)">{{row.name}}
        </mat-checkbox>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'POPUP' | translate}}" [flexGrow]="1">
      <ng-template ngx-datatable-cell-template let-row="row">
        <mat-checkbox [checked]="isPopupEnabled(row)" (change)="popupSelectionChanged($event, row)">{{row.name}}
        </mat-checkbox>

      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'BANNER' | translate}}" [flexGrow]="1">
      <ng-template ngx-datatable-cell-template let-row="row">
        <mat-checkbox [checked]="isBannerEnabled(row)" (change)="bannerSelectionChanged($event, row)">{{row.name}}
        </mat-checkbox>
      </ng-template>
    </ngx-datatable-column>
    <!--ngx-datatable-column name="{{'SMS' | translate}}" [flexGrow]="3">
            <ng-template ngx-datatable-cell-template let-row="row">
              <input type="checkbox" [checked]="row.bell">
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'Email' | translate}}" [flexGrow]="3">
            <ng-template ngx-datatable-cell-template let-row="row">
              <input type="checkbox" [checked]="row.bell">
            </ng-template>
          </ngx-datatable-column-->
  </ngx-datatable>
  <div class="mdc-p-3 ">
    <button mat-flat-button color="accent" (click)="update(true)">{{'SAVE' | translate}}</button>
  </div>
</div>