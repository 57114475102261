import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService, ContextIdDto, ListMessagesForDisplayResponse, Message, MessageId } from '@savvy/messaging';
import { ChangeListener } from './changeListener';
import { EventBusService } from './EventBusService';
import { EventType, UiEvent } from '../event/UiEvent';
import { TrialExpiredService } from '../shared/services/trial-expired.service';
import { MatDialog } from '@angular/material/dialog';
import { NewMessageModalComponent } from '../shared/components/new-message-modal/new-message-modal.component';
import { LinkId } from '@savvy/view-definition';

@Component({
  selector: 'app-simple-messages-panel',
  templateUrl: 'simpleMessagesPanel.component.html',
  styleUrls: ['commonPanel.component.scss', 'simpleMessagesPanel.component.scss']
})
export class SimpleMessagesPanelComponent implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() linkId: LinkId;
  @Input() eventBus: EventBusService;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;
  response: ListMessagesForDisplayResponse;

  @Output() messageSelectedOutput = new EventEmitter();

  constructor(
    private router: Router,
    public trialExpiredService: TrialExpiredService,
    private api: MessageService,
    private dialog: MatDialog) {
  }

  createMessage() {
    this.dialog.open(NewMessageModalComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        linkId: this.linkId,
        eventBus: this.eventBus
      },
      autoFocus: false,
      panelClass: ['helpwindow', 'scrollable-modal']
    });
  }

  ngOnInit(): void {
    this.eventBus.eventAdded$.subscribe(event => this.onEvent(event));
    this.loadMessages();
  }

  getMessageText(message: Message) {
    if (message.smsMessage) {
      return message.smsMessage.textMessage;
    } else if (message.emailMessage) {
      return message.emailMessage.body;
    }
    return '';
  }

  private loadMessages() {

    console.log('loading messages for ' + this.linkId.linkedId);

    this.api.getMessagesForLinkedInstanceV2(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      this.linkId.linkedId,
      1,
      10
      // this.viewContext.customerContextDto.customerId.id
    ).subscribe(response => {
      this.response = response;
    });
  }

  private onEvent(event: UiEvent) {
    if (event.eventType === EventType.SENT_MESSAGE) {
      this.loadMessages();
    }
  }

  sent(message: Message) {
    if (message && message.deliveryStatus) {
      return message.deliveryStatus === Message.DeliveryStatusEnum.Delivered ||
        message.deliveryStatus === Message.DeliveryStatusEnum.Sent;
    }
    return false;
  }

  received(message: Message) {
    if (message && message.deliveryStatus) {
      return message.deliveryStatus === Message.DeliveryStatusEnum.Received;
    }
    return false;
  }

  messageSelected(messageId: MessageId) {
    this.eventBus.addMessageEvent(EventType.MESSAGE_SELECTED, messageId);
    this.messageSelectedOutput.emit(messageId.id);
  }
}

