<mat-card appearance="outlined" *ngIf="contextIdDto && showFilters" class="mdc-m-0">
  <mat-card-content class="mdc-pb-0">

    <div fxLayout="row wrap" fxLayoutGap="5px" fxLayoutGap.gt-md="1%">

      <div fxFlex="100" fxFlex.gt-xs="49" fxFlex.gt-sm="20">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Pet, Customer, Email, Phone...</mat-label>
          <mat-icon matSuffix>search</mat-icon>
          <input matInput placeholder="Pet, Customer, Email, Phone..." [(ngModel)]="petFilter.searchKey"
            (ngModelChange)="searchKeyUpdated($event)">
        </mat-form-field>
      </div>

      <div fxFlex="100" fxFlex.gt-xs="49" fxFlex.gt-sm="10">

        <mat-form-field class="w-100" appearance="outline">
          <!--mat-label>{{'SHOW_ARCHIVED' | translate}}</mat-label-->
          <mat-select placeholder="Status" [(ngModel)]="petFilter.status" (ngModelChange)="statusChanged($event)">
            <mat-option [value]="statusEnum.All">All</mat-option>
            <!--mat-option [value]="statusEnum.InActive">Inactive</mat-option-->
            <mat-option [value]="statusEnum.Archived">Archived Only</mat-option>
            <mat-option [value]="statusEnum.Merged">Merged</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!--div fxFlex="100" fxFlex.gt-md="nogrow">
        <mat-radio-group aria-label="Show Archived?" [ngModel]="deleteOnly"
          (ngModelChange)="deleteOnly = $event; loadAllPets()" class="mdc-mb-1 d-block">
          <mat-radio-button class="example-radio-button px-8" [value]="true">
            <div class="text-bold">{{'SHOW_ARCHIVED' | translate}}</div>
          </mat-radio-button>
          <mat-radio-button class="example-radio-button px-8" [value]="false">
            <div class="text-bold">{{'HIDE_ARCHIVED' | translate}}</div>
          </mat-radio-button>
        </mat-radio-group>
      </div-->
      <div fxFlex="100" fxFlex.gt-xs="49" fxFlex.gt-sm="24">
        <app-flag-filter (includeFlagsChanged)="includeFlagsChange($event);"
          (excludeFlagsChanged)="excludeFlagsChange($event);" [contextIdDto]="contextIdDto" [flagDefinitionType]="'PET'"
          #flagFilterComponent></app-flag-filter>
      </div>
      <div fxFlex="100" fxFlex.gt-xs="49" fxFlex.gt-sm="10">
        <app-filter-pet-type-selector [contextIdDto]="contextIdDto" (petTypeChanged)="petTypeSelectionChange($event);"
          #petTypeFilterComponent></app-filter-pet-type-selector>
      </div>
      <div fxFlex="100" fxFlex.gt-xs="49" fxFlex.gt-sm="15">
        <app-pet-breed-selector [contextIdDto]="contextIdDto" (petBreedChanged)="petBreedSelectionChange($event);"
          #petBreedFilterComponent></app-pet-breed-selector>
      </div>
      <div fxFlex></div>
      <div fxFlex="nogrow">
        <button (click)="resetFilters()" mat-button color="accent"
          class="mt-1 mdc-mb-1 mb-md-0"><mat-icon>cancel</mat-icon>
          Clear Filter</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>


<ng-container *ngIf="!loaded || (pets.length > 0 && pets)">
  <!-- customer responsive card-->
  <div *ngIf="deviceService.isMobile() || deviceService.isTablet()" fxLayout.gt-xs="row wrap" fxLayoutAlign=""
    fxLayout="column" fxLayoutGap="0px" fxLayoutGap.gt-xs="16px" class="mdc-my-2 mdc-px-2 search-results" infiniteScroll
    [infiniteScrollDistance]="scrollDistance" [infiniteScrollUpDistance]="scrollUpDistance" [scrollWindow]="false"
    [infiniteScrollThrottle]="throttle" (scrolled)="onScrollDown($event)">
    <!-- customer card-->
    <div fxFlex="100" fxFlex.gt-xs="48" fxFlex.gt-sm="32" *ngFor="let row of pets">
      <mat-card appearance="outlined" class="mdc-mb-1">
        <mat-card-content>
          <div fxLayout="row" fxLayoutAlign=" " fxLayoutGap="10px">
            <div fxFlex="50px" fxFlex.gt-sm="25">
              <div class="avatar avatar-50 circle border-2 pet-pic coverimg mx-auto"
                [style.background-image]="'url(' + petImage + ')'" (click)="view(row)"></div>
            </div>
            <div fxFlex="65" fxFlex.gt-sm="65">
              <h6 class="dog-name mb-0" (click)="view(row)">
                <span class="mr-5px font-weight-bold vm">{{row?.petName}} {{row?.petBreed}}</span>
                <span class="chiptag green vm px-2" *ngIf="!row?.deleted">Active</span>
                <span class="chiptag red vm px-2" *ngIf="row?.deleted">InActive</span>
              </h6>
              <!--p *ngIf="row.email" class="my-2"><a href="mailto: {{ row.email }}"><span class="chiptag theme-accent vm mt-5px"><mat-icon>email</mat-icon>
                {{ row.email }}</span></a>
              </p>
              <p *ngIf="row.mobileInternational" class="my-2"><a href="tel: {{ row.mobileInternational }}"><span class="chiptag theme vm mt-5px"><mat-icon>call</mat-icon>
                {{ row.mobileInternational }} </span></a></p-->

              <app-pets-flags-panel *ngIf="row?.flags?.length" [disableCreate]="true" [disableDelete]="true"
                [contextIdDto]="contextIdDto" [petId]="row.petId" [flagIds]="row.flags">
              </app-pets-flags-panel>
              <!-- <mat-chip-list class="my-2">
                <mat-chip removable="true" selected="">NC</mat-chip>
              </mat-chip-list> -->
            </div>
            <div fxFlex class="text-end">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu" class="text-start">
                <button mat-menu-item color="primary" (click)="view(row)">
                  <mat-icon>visibility</mat-icon>
                  <span>View</span>
                </button>
                <button mat-menu-item (click)="edit(row)">
                  <mat-icon>edit</mat-icon>
                  <span>Edit</span>
                </button>
                <!--
                <button mat-menu-item color="accent" *ngIf="!row.deleted && !row?.verified" (click)="archive(row); $event.stopImmediatePropagation();">
                  <mat-icon>inventory_2</mat-icon>
                  <span>{{'ARCHIVE' | translate}}</span>
                </button>
                <button *ngIf="row.deleted" mat-menu-item color="accent" (click)="unarchive(row); $event.stopImmediatePropagation();">{{'UN_ARCHIVE' | translate}}</button>
                <button *ngIf="row.deleted" title="Delete Permanently" mat-menu-item color="accent" (click)="deleteCustomerForever(row); $event.stopImmediatePropagation();" class="ml-1">{{'DELETE' |
                  translate}}</button>-->
              </mat-menu>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <!-- customer card ends -->
  </div>
  <!-- customer responsive card ends -->

  <div *ngIf="!deviceService.isMobile() && !deviceService.isTablet()">
    <ngx-datatable class='material mdc-m-1' [rows]='pets' [columnMode]="'force'" [headerHeight]="50"
      [rowHeight]="'auto'" [footerHeight]="50" [externalPaging]="true" [count]="page.totalElements"
      [offset]="page.pageNumber" [limit]="page.size" (page)='setPage($event)'>
      <!-- (select)="onSelect($event)" -->
      <ngx-datatable-column name="{{'NAME' | translate}}" [flexGrow]="1" [resizeable]="true" [sortable]="true"
        [draggable]="true" [canAutoResize]="true" prop="petName">
        <ng-template ngx-datatable-cell-template let-row="row">
          <a href="javascript:void(0)" (click)="edit(row)">
            <span [ngClass]="{ 'deceased-pet': row?.deceased }">{{row.petName}}</span><span
              *ngIf="row?.deceased">(D)</span>
          </a>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'BREED' | translate}}" [flexGrow]="1" [resizeable]="true" [sortable]="true"
        [draggable]="true" [canAutoResize]="true" prop="petBreedLabel">
        <ng-template ngx-datatable-cell-template let-row="row">
          {{row.petBreedLabel}}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="{{'CUSTOMER' | translate}}" [flexGrow]="1" [resizeable]="true" [sortable]="true"
        [draggable]="true" [canAutoResize]="true" prop="customerName">
        <ng-template ngx-datatable-cell-template let-row="row">
          {{row.customerName}}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="{{'FLAGS' | translate}}" [flexGrow]="1" [resizeable]="true" [sortable]="false"
        [draggable]="true" [canAutoResize]="true">
        <ng-template ngx-datatable-cell-template let-row="row">
          <app-pets-flags-panel [disableCreate]="true" [contextIdDto]="contextIdDto"
            [flagIds]="row.flags"></app-pets-flags-panel>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="{{'ACTION' | translate: {Default: 'Action'} }}" [flexGrow]="1" [sortable]="false">
        <ng-template ngx-datatable-cell-template let-row="row">
          <button *ngIf="!row.deleted" class="mr-5px" mat-button color="primary"
            (click)="view(row); $event.stopImmediatePropagation();"><mat-icon>visibility</mat-icon> {{'VIEW' |
            translate:
            {Default: 'View'} }}</button>

          <button *ngIf="!row.deleted" class="mr-5px" mat-button color="accent"
            (click)="edit(row); $event.stopImmediatePropagation();"><mat-icon>edit</mat-icon> {{'EDIT' | translate:
            {Default: 'Edit'} }}</button>

          <button *ngIf="!row.deleted" class="mr-5px" mat-button
            (click)="archive(row); $event.stopImmediatePropagation();"><mat-icon>inventory_2</mat-icon> {{'ARCHIVE' |
            translate}}</button>
          <button *ngIf="row.deleted && !row.merged" class="mr-5px" mat-button
            (click)="unarchive(row); $event.stopImmediatePropagation();"><mat-icon>unarchive</mat-icon> {{'UN_ARCHIVE' |
            translate }}</button>

          <!--button mat-raised-button color="accent" class="ml-1"
                (click)="view(row); $event.stopImmediatePropagation();">{{'VIEW' | translate}}</button-->

        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>

</ng-container>

<div class="flexbox-parent" *ngIf="pets && pets.length === 0 && loaded">
  <div fxLayout="row" fxLayoutAlign="center center" style="height: 60vh;">
    <div style="text-align:center;">
      <app-zero-image name="pet" class="zero-image"></app-zero-image><br>
      <h3 class="mb-2">{{"NO_PETS" | translate}}</h3><br>
      <button mat-raised-button color="accent" [disabled]="trialExpiredService.isTrialExpired()" (click)="create()">
        {{'ADD_PET' | translate}}
      </button>
    </div>
  </div>
</div>
