<div fxLayout="row wrap" fxLayoutAlign="center">
  <div fxFlex="100" class="mt-1 w-100">
    <google-map height="400px" width="100%" [options]="mapOptions" (mapInitialized)="mapInitialized($event)">
      <ng-container *ngFor="let m of markers; let i = index">
        <map-marker #marker="mapMarker" [position]="m.position" [options]="m.markerOptions" [label]="m.label"
          (mapClick)="openInfoWindow1(marker, i)" />
        <map-info-window>{{m.title}}</map-info-window>
      </ng-container>
    </google-map>
    <!-- <google-map [options]="mapOptions">
      <map-marker #marker="mapMarker" [position]="positions"></map-marker>
    </google-map> -->
  </div>

  <div fxFlex="100" class="mt-1 w-100">
    <mat-form-field fxFlex="100" appearance="outline" class="mt-1 w-100">
      <mat-label>{{'ENTER_ADDRESS' | translate}}</mat-label>
      <input matInput (keydown.enter)="$event.preventDefault()" placeholder="Search Nearest Location" autocorrect="off"
        autocapitalize="off" spellcheck="off" type="text" #searchElement>
    </mat-form-field>
  </div>

  <!-- <div class="form-group">
      <label>Enter address</label>
      <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()"
        placeholder="Search Nearest Location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text"
        #search>
    </div> -->
</div>
