import { Component, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { Feature } from '@savvy/add-ons';
import { AppointmentService, ChangeState } from '@savvy/appointment';
import { Appointment, UpdateAppointment } from '@savvy/appointment/model/models';
import { Customer, CustomerService } from '@savvy/customer';
import { Contact, PetService } from '@savvy/pet';
import { Plan, PlanService } from '@savvy/plan';
import { ContextIdDto } from '@savvy/student';
import { PhoneNumber, WorkflowState } from '@savvy/workflow-instance';
import * as _ from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { ClarificationDialogComponent } from 'src/app/flo/clarification-dialog/clarification-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/flo/confirmation-dialog/confirmation-dialog.component';
import { FeaturesHelperService } from 'src/app/flo/shared/services/features-helper.service';
import { SmsCreditService } from 'src/app/flo/shared/services/sms-credit.service';
import { WorkflowDetailsModalComponent } from 'src/app/flo/workflow/workflow-details/workflow-details-modal/workflow-details-modal.component';
import { AppointmentConfigHelperService } from '../../../shared/services/appointment-config-helper.service';
import { UserCurrencyService } from "../../../shared/services/userCurrency.service";
import { FloSnackbarComponent } from '../../../snackbar/floSnackbar.component';
import { ViewAppointmentV2Service } from '../../view-appointment-v2.service';
import { ViewMessagesV2Component } from '../../view-messages-v2/view-messages-v2.component';
import { ContextService } from 'src/app/flo/context.service';

export class ViewPetAppointmentModalData {
  contextIdDto: ContextIdDto;
  appointmentId: string;
  start?: Date; // pet service start time
  end?: Date; // pet service end time
}

@Component({
  selector: 'app-view-pet-appointment',
  templateUrl: './view-pet-appointment.component.html',
  styleUrls: ['./view-pet-appointment.component.scss']
})
export class ViewPetAppointmentComponent implements OnInit, OnDestroy {

  @ViewChild(MatTabGroup) matTabGroup: MatTabGroup;
  @ViewChild(ViewMessagesV2Component) viewMessagesV2Component:ViewMessagesV2Component;
  // @ViewChild(DetailedPetViewComponent, { static: false }) detailedPetViewComponent: DetailedPetViewComponent;
  contextIdDto: ContextIdDto;
  appointment: Appointment;
  quickAppointment = true;
  startTime: Date;
  endTime: Date;

  currentTab = 0;

  paymentCategory = 'amount';
  messageCategory = 'messages';

  showConfirmationDialog = false;
  updatedState: WorkflowState;

  subs: Subscription[] = [];

  uniqueServices: Array<AppointmentService> = [];
  plan: Plan;
  contactList: Contact[] = [];
  showContacts = false;
  contactToShare: PhoneNumber;
  showAskForReview = false;
  showReviewUrlConfig = false;
  askForReview = false;
  showCancellationReason = false;
  featureType = Feature.TypeEnum;
  customer: Customer;
  cancellationReason = '';
  bypassReviewBooster = false;
  currencyCode = 'GBP';

  isVaccinationFeatureAllowed = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ViewPetAppointmentModalData,
    @Optional() public dialogRef: MatDialogRef<ViewPetAppointmentComponent>,
    private appointmentService: AppointmentService,
    private planApi: PlanService,
    private dialog: MatDialog,
    private notify: FloSnackbarComponent,
    private viewAppointmentV2Service: ViewAppointmentV2Service,
    public appointmentConfigHelperService: AppointmentConfigHelperService,
    private router: Router,
    public deviceService: DeviceDetectorService,
    public petService: PetService,
    public featuresHelperService: FeaturesHelperService,
    private customerService: CustomerService,
    private smsCreditService: SmsCreditService,
    private userCurrencyService: UserCurrencyService,
    public contextService: ContextService
  ) {
    this.contextIdDto = data.contextIdDto;
  }

  askForReviewChanged(ask: boolean) {
    if (!ask) {
      this.showReviewUrlConfig = false;
    }
  }

  gotoPet(petId: string) {
    this.dialogRef.close();
    this.router.navigate(['pets/view', this.contextIdDto.contextId, this.contextIdDto.contextIdType, petId]);
  }

  gotoCustomer(customerId) {
    this.dialogRef.close();
    this.router.navigate(['customer/viewCustomer', this.contextIdDto.contextId, this.contextIdDto.contextIdType, customerId]);
  }

  dateFromTime(dStr) {
    const now = new Date();
    now.setHours(dStr.substr(0, dStr.indexOf(':')));
    now.setMinutes(dStr.substr(dStr.indexOf(':') + 1));
    now.setSeconds(0);
    return now;
  }

  ngOnInit(): void {
    console.log('view-pet-appt loading appointment with id', this.data.appointmentId);
    this.loadAppointment();
    if (!this.appointmentConfigHelperService.appointmentConfig?.appointmentViewQuickMode || !this.quickAppointment) {
      this.fullScreen();
    }
    this.subs.push(this.viewAppointmentV2Service.updateState$.subscribe((res: WorkflowState) => {
      if (res) {
        this.showConfirmationDialog = true;
        this.showCancellationReason = false;
        this.showContacts = false;
        this.showAskForReview = false;

        this.updatedState = res;
        if (res.state === 'Ready For Collection') {
          this.showContacts = true;
          this.loadContacts();
        } else if (res.state === 'Cancelled' && this.appointmentConfigHelperService.appointmentConfig.cancellationReasonRequired) {
          // this.askForReview = this.appointmentConfigHelperService.appointmentConfig.askForReviewEnabled;
          this.showCancellationReason = true;
        } else if (res.state === 'Complete' && this.appointmentConfigHelperService.appointmentConfig.askForReviewEnabled) {
          this.askForReview = this.appointmentConfigHelperService.appointmentConfig.askForReviewEnabled;
          this.showAskForReview = true;
        } else {
          this.showContacts = false;
          this.showAskForReview = false;
        }
      }
    }));
    this.loadCurrencyCode();
    this.isVaccinationFeatureAllowed = this.featuresHelperService.isFeatureAllowed(Feature.TypeEnum.Vaccinations);
  }

  loadCurrencyCode() {
    this.userCurrencyService.getDefaultCurrency(this.contextIdDto)
      .subscribe(res => {
        this.currencyCode = res.org.currencyCode ? res.org.currencyCode : this.userCurrencyService.defaultCurrency;
      });
  }

  customerUpdated(event) {
    if (event) {
      this.loadContacts();
      this.loadAppointment();
    }
  }

  petUpdated(event) {
    if (event) {
      this.loadContacts();
      this.loadAppointment();
    }
  }

  loadCustomer() {
    return this.customerService.loadCustomer(
      this.appointment.customerId,
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType).subscribe(res => {
        this.customer = res;
        if (this.customer
          && (this.customer.mobilePhoneNumberObj?.internationalNumber
            || this.customer.phoneNumberObj?.internationalNumber
            || this.customer.workPhoneNumberObj?.internationalNumber
            || this.customer.whatsAppNumberObj?.internationalNumber)) {
          this.contactList.unshift({
            firstName: this.customer.firstName,
            lastName: this.customer.lastName,
            mobilePhoneNumberObj: this.customer.mobilePhoneNumberObj,
            phoneNumberObj: this.customer.phoneNumberObj,
            workPhoneNumberObj: this.customer.workPhoneNumberObj,
            whatsAppNumberObj: this.customer.whatsAppNumberObj,
          });
        }
        if (this.contactList?.length) {
          if (this.contactList[0].mobilePhoneNumberObj) {
            this.contactToShare = this.contactList[0].mobilePhoneNumberObj;
          } else if (this.contactList[0].phoneNumberObj) {
            this.contactToShare = this.contactList[0].phoneNumberObj;
          } else if (this.contactList[0].workPhoneNumberObj) {
            this.contactToShare = this.contactList[0].workPhoneNumberObj;
          } else if (this.contactList[0].whatsAppNumberObj) {
            this.contactToShare = this.contactList[0].whatsAppNumberObj;
          }
        }
      });
  }

  async loadContacts() {
    this.contactList = [];
    const petIds = this.appointment.appointmentServiceRows.map(as => as.eiId);
    const uniquePetIds = petIds.filter((item, pos) => petIds.indexOf(item) === pos);
    for (const petId of uniquePetIds) {
      this.petService.get(petId).subscribe(pet => {
        if (pet?.contacts?.length) {
          this.contactList = [...this.contactList, ...pet.contacts];
        }
      });
    }
    this.loadCustomer();
  }

  ngOnDestroy(): void {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }

  loadAppointment() {
    this.appointmentService.loadAppointment(this.data.appointmentId, this.contextIdDto.contextId, this.contextIdDto.contextIdType)
      .subscribe(response => {
        this.appointment = response;
        this.contactToShare = this.appointment.contactPhone;
        this.startTime = this.dateFromTime(this.appointment.startTime);
        this.endTime = this.dateFromTime(this.appointment.endTime);
        const dataSource = this.appointment.appointmentServiceRows;
        this.uniqueServices = _.uniqBy(dataSource, 'eiId');

        if (this.appointment.customerId) {
          this.loadCustomer();
        }

        if (this.appointment.paymentConfig?.paymentMode === 'PAYMENT_PLAN'
          && this.appointment.paymentConfig.paymentPlanId) {
          this.planApi.loadPlanById(
            this.contextIdDto.contextId,
            this.contextIdDto.contextIdType,
            this.appointment.paymentConfig.paymentPlanId)
            .subscribe(res => {
              this.plan = res.plan;
            });
        }
      });
  }

  cancel() {
    this.dialogRef.close();
  }

  fullScreen() {
    this.quickAppointment = false;
    this.dialogRef.updateSize('800px', 'auto');
    if (this.matTabGroup) {
      this.matTabGroup.realignInkBar();
    }
  }

  exitFullScreen() {
    this.quickAppointment = true;
    this.dialogRef.updateSize(null, 'auto');
  }

  tabChange(event) {
    console.log('event', event);
    this.currentTab = event;
  }

  edit() {
    this.dialogRef.close({ appointment: this.appointment, rebook: false });
  }

  rebookEvent() {
    this.dialogRef.close({ appointment: this.appointment, rebook: true });
  }

  cancelAppointment() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Cancel Appointment',
        message: 'Are you sure you want to cancel this appointment?',
        // isChange: true
      },
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // this.appointment.cancelled = true;
        // const req = <ChangeState>{};
        // req.newState = 'Cancelled';

        // this.appointmentService.changeStateV2(this.appointment.id, this.contextIdDto.contextId,
        //   this.contextIdDto.contextIdType, req)
        //   .subscribe(response => {
        //     if (response) {
        //       this.notify.message = 'Appointment cancelled';
        //       this.notify.open();
        //       this.dialogRef.close();
        //     }
        //   });
        this.clarifyCancellation();
      }
    });

  }

  clarifyCancellation() {
    const dialogRef = this.dialog.open(ClarificationDialogComponent, {
      data: {
        title: 'Cancellation Reasons',
        message: 'What is the reasons for cancellation of appointment?'
      },
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.appointment.cancelled = true;
        this.appointment.cancellationReason = result?.clarify;
        // appointmentId: string, contextId: string, contextIdType: string, newState: string
        const req = <ChangeState>{};
        req.newState = 'Cancelled';
        req.reason = result?.clarify;

        this.appointmentService.changeStateV2(this.appointment.id, this.contextIdDto.contextId,
          this.contextIdDto.contextIdType, req)
          .subscribe(response => {
            if (response) {
              this.notify.message = 'Appointment cancelled';
              this.notify.open();
              this.dialogRef.close();
            }
          });
      }
    });
  }

  deleteAppointment() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete Appointment',
        message: 'Are you sure you want to delete this appointment?',
        // isChange: true
      },
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.appointmentService.deleteAppointment(this.appointment.id, this.contextIdDto.contextId, this.contextIdDto.contextIdType)
          .subscribe(response => {
            if (response) {
              this.appointment = response;
              this.notify.message = 'Appointment deleted';
              this.notify.open();
              this.dialogRef.close();

            }
          });
      }
    });

  }

  hasGoogleReviewUrl() {
    return this.appointmentConfigHelperService.appointmentConfig.googleReviewUrl &&
      this.appointmentConfigHelperService.appointmentConfig.googleReviewUrl.trim().length > 0;
  }

  hasFacebookReviewUrl() {
    return this.appointmentConfigHelperService.appointmentConfig.facebookReviewUrl &&
      this.appointmentConfigHelperService.appointmentConfig.facebookReviewUrl.trim().length > 0;
  }

  confirmStateChange() {
    if (this.askForReview
      && !this.hasGoogleReviewUrl()
      && !this.hasFacebookReviewUrl()) {
      this.notify.message = 'There are no urls configured for review';
      this.notify.open();
      this.showReviewUrlConfig = true;
      return;
    }
    if (this.showCancellationReason && (!this.cancellationReason || !this.cancellationReason?.length)) {
      this.notify.message = 'Cancellation reason is required';
      this.notify.open();
      return;
    }
    this.showReviewUrlConfig = false;
    this.showCancellationReason = false;
    this.showConfirmationDialog = false;
    this.smsCreditService.checkSMSCredits(this.contextIdDto);

    this.viewAppointmentV2Service.confirmUpdateState({
      state: this.updatedState,
      contact: this.contactToShare,
      askForReview: this.askForReview,
      cancellationReason: this.cancellationReason
    });
  }

  closeConfirmDialog() {
    this.showConfirmationDialog = false;
    this.viewAppointmentV2Service.confirmUpdateState();
  }

  viewHistory() {
    console.log('view history called');
    this.currentTab = 1;
    this.matTabGroup.focusTab(1);
    //    this.matTabGroup.selectedIndexChange.emit(1);
  }

  openEditTemplate() {
    const confirmDialog = this.dialog.open(WorkflowDetailsModalComponent, {
      data: {
        contextIdDto: this.contextIdDto
      },
      // height: 'auto',
      width: '100%',
      maxWidth: '800px',
      maxHeight:'calc(100vh - 100px)',
      panelClass: 'helpwindow'

    });

    confirmDialog.afterClosed().subscribe((result: boolean) => {
      if (result) {
      }
    });
  }

  appointmentUpdated(appointment: Appointment) {
    const updateAppointment: UpdateAppointment = {
      appointment,
      contextIdDto: this.contextIdDto
    };
    this.appointmentService.updateAppointment(updateAppointment).subscribe(() => { });
  }

  updateBypassReviewBooster(event) {
    this.askForReview = !event;
    this.appointmentConfigHelperService.appointmentConfig.askForReviewEnabled = !event;
    this.appointmentConfigHelperService.updateAppointmentConfig(this.contextIdDto, this.appointmentConfigHelperService.appointmentConfig);
  }

  invoiceUpdated() {
    this.loadAppointment();
  }

  getEiIds() {
    let eiIds = [];
    if (this.appointment && this.appointment.appointmentServiceRows) {
      this.appointment.appointmentServiceRows.forEach(as => {
        if (as.eiId) {
          eiIds.push(as.eiId);
        }
      });
    }

    return eiIds;
  }

  createMessage() {
    this.viewMessagesV2Component.createMessage();
  }

}
