<div class="customer-page">
  <div fxLayout="column">
    <div class="actions" fxFlex="100" fxLayout="row" fxLayoutAlign="space-between baseline">
      <div>
        <button (click)="backToList()" class="ml-xs mat-icon-button" mat-icon-button="">
    <span class="mat-button-wrapper">
    <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">arrow_back</mat-icon>
    </span>
          <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
          <div class="mat-button-focus-overlay"></div>
        </button>
      </div>
      <div>
        <button (click)="editCustomer()" class="ml-xs mat-icon-button" mat-icon-button="">
    <span class="mat-button-wrapper">
    <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">edit</mat-icon>
    </span>
          <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
          <div class="mat-button-focus-overlay"></div>
        </button>
        <button (click)="removeCustomer()" class="ml-xs mat-icon-button" mat-icon-button="">
    <span class="mat-button-wrapper">
    <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">delete</mat-icon>
    </span>
          <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
          <div class="mat-button-focus-overlay"></div>
        </button>
      </div>
    </div>
    <div class="customer-container" fxFlex="100" fxLayout="column">
      <div fxFlex="100" fxLayoutAlign="center">
        <div fxFlex="70" fxFlex.xs="100">

          <app-element
            [elementDto]="viewCustomerPageDefinitionDto.elementDto"
            [form]="form"
            [contextIdDto]="contextIdDto"
            [viewContext]="viewContext"
            [eventBus]="eventBus"
            [additionalDataMapDto]="additionalDataMapDto"
            [showPlaceholder]="showPlaceholder"
            [changeListener]="changeListener"
          >
          </app-element>
        </div>
      </div>
    </div>
  </div>
</div>

