<mat-toolbar color="accent">
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span>{{'MANUAL_TASK' | translate}}</span>
    <button mat-icon-button (click)="delete()" aria-label="Delete">
      <mat-icon>delete</mat-icon>
    </button>
  </mat-toolbar-row>

</mat-toolbar>

<mat-card appearance="outlined" *ngIf="workflowTaskDefinition && workflowTaskDefinition.manualTaskDefinition && workflowDefinitionTypeData && initialised">
  <aw-wizard [navBarLocation]="'left'" class="custom-global-scss">
    <aw-wizard-step [stepTitle]="'TASK_NAME' | translate" [stepId]="1">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{'GIVE_TASK_NAME' | translate}}</mat-label>
            <input matInput [(ngModel)]="workflowTaskDefinition.taskSettings.taskName"
                     #taskName="ngModel" name="taskName" placeholder="{{'TASK_NAME' | translate}}">
          </mat-form-field>
      <button type="button" mat-flat-button color="accent" awNextStep class="ml-1">{{'NEXT' | translate}}</button>

    </aw-wizard-step>

    <aw-wizard-step [stepTitle]="'SCHEDULE' | translate" [stepId]="2">

      <app-add-edit-schedule-def
                                 [contextIdDto]="contextIdDto"
                                 [title]="'DOES_TASK_DUE_DATE' | translate"
                                 [manualTask]="true"
                                 [scheduleDef]="workflowTaskDefinition.manualTaskDefinition.dueDateSchedule"
                                 [workflowDefinitionId]="workflowDefinitionId"
                                 [workflowDefinitionTypeData]="workflowDefinitionTypeData"
                                 (scheduleUpdated)="scheduleUpdated($event)">


      </app-add-edit-schedule-def>
      <button type="button" mat-flat-button color="accent" awPreviousStep>{{'PREVIOUS' | translate}}</button>&nbsp;&nbsp;
      <button mat-raised-button (click)="save()" color="accent">{{'SAVE' | translate}}</button>
    </aw-wizard-step>
  </aw-wizard>
</mat-card>

