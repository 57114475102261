<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span *ngIf="!data.edit">
      {{'CREATE_PACKAGE' | translate}}
    </span>
    <span *ngIf="data.edit">
      {{'EDIT_PACKAGE' | translate}}
    </span>
    <span class="spacer"></span>
    <button mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>
<mat-card appearance="outlined">
  <mat-card-content>
    <form [formGroup]="packageForm" (ngSubmit)="add()">
      <div fxLayout="column">
        <mat-form-field fxFlex="100" appearance="outline">
          <mat-label>Package Name</mat-label>
          <input matInput placeholder="Package Name" id="packageName" formControlName="packageName" required>
          <mat-error *ngIf="packageName.invalid">
            {{'PACKAGE_NAME_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="100" appearance="outline">
          <mat-label>{{'DURATION_IN_MINS' | translate}}</mat-label>
          <input matInput placeholder="{{'DURATION_IN_MINS' | translate}}" type="text" appNumberOnly name="packageDurationMins"
            formControlName="packageDurationMins">
        </mat-form-field>
        <mat-form-field fxFlex="100" appearance="outline">
          <mat-label>{{'PACKAGE_PRICE' | translate}}</mat-label>
          <input matInput type="text" appNumberOnly [decimalPoints]="2" placeholder="Package Price" name="packagePrice"
            formControlName="packagePrice" required>
          <mat-error *ngIf="packagePrice.invalid">
            {{'PACKAGE_PRICE_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="100" appearance="outline">
          <mat-label>{{'TAX' | translate}}</mat-label>
          <mat-select placeholder="{{'TAX' | translate}}" formControlName="taxEntityInstanceId" name="taxEntityInstanceId"
            (selectionChange)="updateTax($event)">
            <mat-option *ngFor="let taxDef of data.taxDefs" [value]="taxDef.id">{{taxDef.taxName}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex="100" appearance="outline">
          <mat-label>{{'COLOUR' | translate}}</mat-label>
          <input matInput type="text" placeholder="{{'COLOUR' | translate}}" name="color" [value]="packageForm.value.packageColour"
            formControlName="packageColour" [style.background]="packageForm.value.packageColour"
            [(colorPicker)]="packageForm.value.packageColour" [cpSaveClickOutside]="true"
            [cpOKButtonClass]="'mat-raised-button mat-accent'" (colorPickerSelect)="updateColor($event)">
        </mat-form-field>
      </div>
      <button mat-raised-button type="submit" class="mat-accent">
        <span *ngIf="!data.edit">{{'ADD_PACKAGE' | translate}}</span>
        <span *ngIf="data.edit">{{'UPDATE_PACKAGE' | translate}}</span>
      </button>
    </form>

  </mat-card-content>
</mat-card>
