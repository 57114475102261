<div *ngIf="customerDto?.address?.address1" class="mdc-mb-2">
  <p class="mat-text-secondary small">{{'ADDRESS_IS' | translate}} </p>
  <div fxLayout='row wrap' fxLayoutGap="15px">
    <div fxFlex>
      <p>
        {{customerDto?.address?.address1}}
        {{customerDto?.address?.address2}}
        {{customerDto?.address?.address3}}
        {{customerDto?.address?.city}}
        {{customerDto?.address?.zipCode}}<br>
        {{customerDto?.address?.state}}
      </p>
    </div>
    <div fxFlex="nogrow" *ngIf="customerDto?.address">
      <button mat-button color="accent" (click)="openGoogleMaps()">
        <mat-icon>place</mat-icon> {{'VIEW_MAP' | translate}}
      </button>
    </div>
  </div>
</div>