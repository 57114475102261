<div *ngIf="!eventBus">STEP PANEL EVENT BUS IS EMPTY</div>
<div *ngIf="stepPanelDto">
  <mat-vertical-stepper #stepper (selectionChange)="selectionChange($event)">
    <mat-step
      *ngFor="
        let stepDefinition of stepPanelDto.stepDefinitionDtos;
        let i = index
      "
      [stepControl]="form"
      errorMessage="{{ stepDefinition.label }} has errors."
    >
      <form [formGroup]="form">
        <ng-template matStepLabel>{{ stepDefinition.label }}</ng-template>
        <app-element
          *ngFor="let elementDto of stepDefinition.elementDtos"
          [elementDto]="elementDto"
          [contextIdDto]="contextIdDto"
          [form]="form"
          [viewContext]="viewContext"
          [eventBus]="eventBus"
          [additionalDataMapDto]="additionalDataMapDto"
          [showPlaceholder]="showPlaceholder"
          [changeListener]="changeListener"
        >
        </app-element>
      </form>
    </mat-step>
  </mat-vertical-stepper>
</div>
