<mat-toolbar color="primary" *ngIf="_taskDef">
  <mat-toolbar-row *ngIf="!_taskDef.id">
    <span>{{'CREATE_MESSAGE' | translate}}</span>
  </mat-toolbar-row>
  <mat-toolbar-row *ngIf="_taskDef.id">
    <span>{{'EDIT_MESSAGE' | translate}}</span>
    <div [class.example-spacer]="true"></div>
    <button (click)="deleteTask()" class="ml-xs mat-icon-button" mat-icon-button="">
                <span class="mat-button-wrapper">
                    <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">delete</mat-icon>
                </span>
      <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
      <div class="mat-button-focus-overlay"></div>
    </button>
  </mat-toolbar-row>
</mat-toolbar>
<mat-card appearance="outlined" *ngIf="_taskDef">
  <mat-card-content>

    <div fxLayout="column" fxLayoutAlign="space-around">
      <mat-form-field style="width: 100%" appearance="outline">
        <mat-label>{{'MESSAGE_NAME' | translate}}</mat-label>
        <input
          matInput
          [(ngModel)]="_taskDef.taskSettings.taskName"
          #taskName="ngModel"
          class="example-full-width"
          (change)="update()"
          name="taskName"
          placeholder="{{'TASK_NAME' | translate}}" required>
        <mat-error *ngIf="taskName.hasError('required')">
          {{'MESSAGE_NAME_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <div style="padding: 1em .75em;">
        <mat-button-toggle-group name="fontStyle" aria-label="Font Style" value="{{this._taskDef.actionDefinition.actionType}}">
          <mat-button-toggle (click)="actionTypeToggle()"  value="SEND_MESSAGE">{{'EMAIL' | translate}}</mat-button-toggle>
          <mat-button-toggle (click)="actionTypeToggle()" value="SEND_SMS">{{'SMS' | translate}}</mat-button-toggle>
        </mat-button-toggle-group>
      </div>



      <div *ngIf="isSendSms()">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>{{'SMS_TEXT' | translate}}</mat-label>
          <input
            matInput
            [(ngModel)]="_taskDef.actionDefinition.sendSmsDefinition.message"
            (change)="update()"
            #smsText="ngModel"
            name="subject"
            placeholder="{{'SMS_TEXT' | translate}}">
        </mat-form-field>
      </div>

      <div *ngIf="isSendMessage()">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>{{'SUBJECT' | translate}}</mat-label>
          <input
            matInput
            [(ngModel)]="_taskDef.actionDefinition.sendMessageDefinition.subject"
            (change)="update()"
            #fieldName="ngModel"
            name="subject"
            placeholder="{{'SUBJECT' | translate}}">
        </mat-form-field>

        <app-flo-editor (textUpdated)="updateBodyTerm$.next($event)"
                        [variables]="variables"
                        [text]="_taskDef.actionDefinition.sendMessageDefinition.body"></app-flo-editor>

      </div>

      <div *ngIf="templateValuesList && templateValuesList.length">
        <br>
        <h2>{{'VARIABLES' | translate}}</h2>
        <mat-hint>
            <ul>
              <li *ngFor="let templateVar of templateValuesList.keyValueTupleDtos">
                {{ templateVar.key }}  {{ templateVar.value }}
              </li>
            </ul>
        </mat-hint>
      </div>
    </div>
    <br>

  </mat-card-content>
  <mat-card-content>
    <button mat-raised-button color="accent" *ngIf="!_taskDef.id"
            (click)="createTask()">{{'CREATE' | translate}}</button>
  </mat-card-content>


</mat-card>

