<mat-toolbar color="primary" *ngIf="reminderDefs && reminderDefs.length > 0">
  <mat-toolbar-row>
    {{'REMINDERS' | translate}}
  </mat-toolbar-row>
</mat-toolbar>

<mat-sidenav-container class="app-inner" style="width: 100%">

  <mat-sidenav #reminderDefPanel position="end" class="task-panel" mode="over" opened="false" style="width:80%">
    <app-add-edit-reminder-def *ngIf="reminderDef" (reminderAdded)="reminderCreated()" (reminderCancelled)="reminderCancelled()" (reminderDeleted)="reminderDeleted()" [reminderDef]="reminderDef" [contextIdDto]="contextIdDto"></app-add-edit-reminder-def>
  </mat-sidenav>

  <div class="flexbox-parent" *ngIf="reminderDefs && reminderDefs.length == 0">
    <div fxLayout="row" fxLayoutAlign="center center" style="height: 60vh;  ">
      <div style="text-align:center;">
        <app-zero-image name="reminder"></app-zero-image><br>
        <h5 class="ma-0">{{'NO_REMINDERS_SETUP' | translate}}</h5><br>
        <button mat-raised-button color="accent" (click)="createReminder()">
          {{'ADD_REMINDER' | translate}}
        </button>
      </div>
    </div>
  </div>


  <ngx-datatable *ngIf="reminderDefs && reminderDefs.length > 0" class='material' [columns]="columns" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [limit]="10" [selected]="selected" [selectionType]="'cell'" (select)="onSelect($event)" [rows]='reminderDefs'>
  </ngx-datatable>

  <!--
  <mat-card *ngIf="reminderDefs && reminderDefs.length > 0">
    <mat-card-content>
      <div fxLayout="row" fxLayoutWrap="wrap" fxLayoutAlign="start">
        <mat-list role="list" style="width:100%;">
          <mat-list-item role="listitem" *ngFor="let reminderDef of reminderDefs" (click)="editReminder(reminderDef)">
            {{reminderDef.name}}

            <div class="example-spacer"></div>
-->
  <!--
              <button (click)="editReminder(reminderDef)" class="ml-xs mat-icon-button" mat-icon-button="">
                  <span class="mat-button-wrapper">
                      <mat-icon role="img" class="demo-toolbar-icon" aria-label="edit">edit</mat-icon>
                  </span>
                  <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
                  <div class="mat-button-focus-overlay"></div>
              </button>
              -->
  <!--
            <button (click)="deleteReminder(reminderDef)" class="ml-xs mat-icon-button" mat-icon-button="">
                    <span class="mat-button-wrapper">
                        <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">delete</mat-icon>
                    </span>
              <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
              <div class="mat-button-focus-overlay"></div>
            </button>
          </mat-list-item>
        </mat-list>
      </div>
    </mat-card-content>
    <mat-card-content>


    </mat-card-content>

  </mat-card>
-->

  <button mat-fab color="accent" (click)="createReminder()" *ngIf="reminderDefs && reminderDefs.length > 0" class="mat-fab-bottom-right">
    <mat-icon class="mat-24">add</mat-icon>
  </button>
</mat-sidenav-container>