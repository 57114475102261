import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { CreateData } from '../../element/createEntityComponent.component';


@Injectable({
  providedIn: 'root'
})
export class PhoneNumberHelperService {
  public closeSidePanelSubj = new Subject();

  // So this is used for double clicking into the calendar, to preserve the state
  // between calendar view, and the createEntityComponent!!
  public dataCreateInstance = new BehaviorSubject<CreateData>(null);


  public savedCalendarDate: Date = null;
  public closeEntityModalSub = new Subject<boolean>();

  public savedCalendarView: 'month' | 'week' | 'day' | undefined = undefined;

  public viewEntityModalSub = new Subject();

  constructor(
) {
  }

  setLocalPhones(user, localPhones) {
    const emptyPhoneNumber = {
      country: '', internationalNumber: '', nationalNumber: ''
    };

    Object.keys(localPhones).map((key) => {
      localPhones[key] = user[key] ? user[key] : JSON.parse(JSON.stringify(emptyPhoneNumber));
    });
  }


}

