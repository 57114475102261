<div>
  <mat-toolbar color="primary">
    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
      <button mat-icon-button (click)="backToList()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
        <span>{{'MESSAGE_REQUESTS' | translate}}</span>
      </div>
    </div>
  </mat-toolbar>

  <ngx-datatable *ngIf="!loading && sendToAllMessages && sendToAllMessages?.length" class='material has-plus-btn' [rows]='sendToAllMessages' [columnMode]="'force'" [headerHeight]="50" [rowHeight]="'auto'" [footerHeight]="50" [selected]="selected" [selectionType]="'single'" [externalPaging]="true" [count]="page.totalElements" [offset]="page.pageNumber" (sort)="onSort($event)" [loadingIndicator]="loading" [limit]="page.size" (select)="onSelect($event)" (page)='setPage($event)'>


    <ngx-datatable-column name="{{'EMAIL_SUBJECT' | translate}}" [flexGrow]="3">
      <ng-template ngx-datatable-cell-template let-row="row">
        {{ row.emailMessage?.subject }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'SMS_MESSAGE' | translate}}" [flexGrow]="3">
      <ng-template ngx-datatable-cell-template let-row="row">
        <span [innerHTML]="row.smsMessage?.textMessage"></span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'MARKETING_MESSAGE' | translate}}" [flexGrow]="3">
      <ng-template ngx-datatable-cell-template let-row="row">
        {{ row.marketingMessage }}
      </ng-template>
    </ngx-datatable-column>
    <!--ngx-datatable-column name="Status" [flexGrow]="3">
        <ng-template ngx-datatable-cell-template let-row="row">
          {{ row.messageStatus }}
        </ng-template>
      </ngx-datatable-column-->
    <ngx-datatable-column name="{{'NUM_MESSAGES' | translate}}" [flexGrow]="3">
      <ng-template ngx-datatable-cell-template let-row="row">
        {{ row.numMessagesCreated }} (a message will only be created for customers that have an email address)
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'CREATED' | translate}}" [flexGrow]="3">
      <ng-template ngx-datatable-cell-template let-row="row">
        {{ row.lastUpdated | localizedDate: 'medium' }}
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>
<div class="flexbox-parent" *ngIf="!loading && (!sendToAllMessages || !sendToAllMessages?.length)">
  <div fxLayout="row" fxLayoutAlign="center center" style="height: 60vh;  ">
    <div style="text-align:center;">
      <app-zero-image name="messaging" class="zero-image"></app-zero-image><br>
      <h5 class="ma-0">{{'NO_MESSAGE_REQUESTS!' | translate}}</h5><br>
    </div>
  </div>
</div>