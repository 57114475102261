<mat-toolbar-row *ngIf="quickAppointment && appointment">
  <span fxLayout="row" fxLayoutAlign=" center">
    <span class="icon-24 mat-text-secondary">
      <mat-icon>event</mat-icon>
    </span>
    <span class="date-time padding-left-half" fxLayout="column">
      <span>{{appointment.startDate | localizedDate: 'shortDate'}}</span>
      <small class="mat-text-secondary fs-12">
        {{startTime | localizedDate:'shortTime'}} - {{endTime | localizedDate:'shortTime'}}
      </small>
    </span>
  </span>
  <span class="example-spacer"></span>
  <app-view-appointment-workflow-panel *ngIf="appointment" [contextIdDto]="contextIdDto" [(appointment)]="appointment">
  </app-view-appointment-workflow-panel>
  <button mat-icon-button color="primary" aria-label="Example icon button with a home icon" (click)="fullScreen()">
    <mat-icon class="full-screen-icon">open_in_full</mat-icon>
  </button>
</mat-toolbar-row>
<!-- <mat-toolbar color="primary" *ngIf="!quickAppointment">
  <mat-toolbar-row>
    <span fxLayout="row" fxLayoutAlign=" center">
      View Appointment
    </span>
    <span class="example-spacer"></span>
    <button mat-icon-button aria-label="Example icon button with a home icon" (click)="exitFullScreen()">
      <mat-icon class="full-screen-icon">close_fullscreen</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar> -->
<!-- <mat-card class="m-0" *ngIf="appointment">
  <mat-card-content> -->
<div *ngIf="appointment" class="position-relative">
  <div class="mdc-px-3 " *ngIf="quickAppointment">
    <mat-card appearance="outlined" class="mx-0 mdc-mb-1" *ngIf="showConfirmationDialog">
      <mat-card-header class="close-confirm">
        <mat-icon (click)="closeConfirmDialog()">cancel</mat-icon>
      </mat-card-header>
      <mat-card-content>
        <div fxLayout="row" fxLayoutAlign=" center">
          <div fxFlex>
            <h6>{{'NICE_PROGRESS' | translate: {Default: 'Nice Progress!'} }}</h6>
            <p class="small mat-text-secondary mb-0">
              {{'CHANGED_STATUS_CONFIRM' | translate: {Default: 'You have changed status. By confirming Email/SMS will be sent to user.'} }}
            </p>
            <!-- <p class="small mat-text-secondary mb-0">
              To change, setup auto-send or modify template <a (click)="openEditTemplate()">Click here</a>.
            </p> -->
          </div>
          <div fxFlex="nogrow">
            <button mat-raised-button color="accent" (click)="confirmStateChange()">{{'CONFIRM' | translate: {Default: 'Confirm'} }}</button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <app-quick-customer-view [contextIdDto]="contextIdDto" [appointment]="appointment" (rebookEvent)="rebookEvent()">
    </app-quick-customer-view>
  </div>

  <div *ngIf="!quickAppointment" class="quick-actions">
    <button mat-button [matMenuTriggerFor]="menu" class="mat-text-secondary">
      <mat-icon>more_vert</mat-icon>{{'MORE' | translate: {Default: 'More'} }}
    </button>
    <button *ngIf="deviceService.isMobile() || deviceService.isTablet()" mat-icon-button type="button" class="mat-text-secondary" (click)="cancel()">
      <mat-icon>cancel</mat-icon>
    </button>
    <mat-menu #menu="matMenu" class="quick-actions-menu">
      <button mat-menu-item (click)="edit()">
        <mat-icon>edit</mat-icon>{{'EDIT' | translate: {Default: 'Edit'} }}
      </button>
      <button mat-menu-item (click)="cancelAppointment()">
        <mat-icon>cancel</mat-icon>{{'CANCEL' | translate: {Default: 'Cancel'} }}
      </button>
      <button mat-menu-item (click)="deleteAppointment()">
        <mat-icon>delete_forever</mat-icon>{{'DELETE' | translate: {Default: 'Delete'} }}
      </button>
    </mat-menu>
  </div>

  <mat-tab-group class="view-appointment-expanded" #matTabGroup *ngIf="!quickAppointment" (selectedIndexChange)="tabChange($event)">

    <mat-tab [label]="appointmentTitle" class="mdc-p-3 ">
      <mat-toolbar-row *ngIf="appointment" fxLayout="row" fxLayoutAlign=" center">
        <div fxLayout="row" fxLayoutAlign=" center">
          <span class="icon-24 mat-text-secondary">
            <mat-icon>event</mat-icon>
          </span>
          <span class="date-time padding-left-half" fxLayout="column">
            <span>{{appointment.startDate | localizedDate: 'shortDate'}}</span>
            <small class="mat-text-secondary fs-12">
              {{startTime | localizedDate:'shortTime'}} - {{endTime | localizedDate:'shortTime'}}
            </small>
          </span>
        </div>
        <span class="example-spacer"></span>
        <app-view-appointment-workflow-panel fxLayoutAlign=" center" [contextIdDto]="contextIdDto" [(appointment)]="appointment">
        </app-view-appointment-workflow-panel>
        <div fxFlex="nogrow">
          <button mat-button color="accent" (click)="edit()">
            <mat-icon>edit</mat-icon>{{'EDIT' | translate: {Default: 'Edit'} }}
          </button>
        </div>
      </mat-toolbar-row>
      <div class="mdc-px-3 ">
        <mat-card appearance="outlined" class="mx-0 mdc-mb-1" *ngIf="showConfirmationDialog">
          <mat-card-header class="close-confirm">
            <mat-icon (click)="closeConfirmDialog()">cancel</mat-icon>
          </mat-card-header>
          <mat-card-content>
            <div fxLayout="row" fxLayoutAlign=" center">
              <div fxFlex>
                <h6>{{'NICE_PROGRESS' | translate: {Default: 'Nice Progress!'} }}</h6>
                <p class="small mat-text-secondary mb-0">
                  {{'CHANGED_STATUS_CONFIRM' | translate: {Default: 'You have changed status. By confirming Email/SMS will be sent to user.'} }}
                </p>
                <p class="small mat-text-secondary mb-0">
                  {{'CHANGE_SETUP_MODIFY_TEMPLATE' | translate: {Default: 'To change, setup auto-send or modify template'} }} <a href=""> {{'CLICK_HERE' | translate: {Default: 'Click Here'} }}
                  </a>.
                </p>
              </div>
              <div fxFlex="nogrow">
                <button mat-raised-button color="accent" (click)="confirmStateChange()">Confirm</button>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        <div *ngIf="appointment">
          <app-detailed-customer-view *ngIf="!quickAppointment && appointment" [appointment]="appointment" [contextIdDto]="contextIdDto" (rebookEvent)="rebookEvent()">
          </app-detailed-customer-view>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="History">
      <app-view-customer-history *ngIf="appointment" [contextIdDto]="contextIdDto" [customerId]="appointment?.customerId">
      </app-view-customer-history>
    </mat-tab>

    <mat-tab [label]="'MESSAGES' | translate: {Default: 'Messages'} ">
      <div fxLayout="row" fxLayoutAlign="center center" class="mdc-px-3 mt-15px">
        <mat-button-toggle-group [(ngModel)]="messageCategory" name="messageCategory" aria-label="Message Categories" class="button-group-messageCategory">
          <mat-button-toggle value="messages">{{'MESSAGES' | translate: {Default: 'Messages'} }}</mat-button-toggle>
          <mat-button-toggle value="reminders">{{'REMINDERS' | translate: {Default: 'Reminders'} }}</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <app-view-messages-v2 *ngIf="appointment && messageCategory === 'messages'" [contextIdDto]="contextIdDto" [appointment]="appointment">
      </app-view-messages-v2>
      <app-view-reminders-v2 *ngIf="appointment && messageCategory === 'reminders'" [contextIdDto]="contextIdDto" [appointment]="appointment">
      </app-view-reminders-v2>
    </mat-tab>

    <mat-tab [label]="'Contracts' | translate: {Default: 'Contracts'} " *ngIf="featuresHelperService.isFeatureAllowed(featureType.Contracts)">
      <app-view-appointment-contracts *ngIf="appointment" [contextIdDto]="contextIdDto" [customerId]="appointment.customerId">
      </app-view-appointment-contracts>
    </mat-tab>

    <mat-tab label="Payment">
      <div fxLayout="row" fxLayoutAlign="center center" class="mdc-p-3 ">
        <mat-button-toggle-group [(ngModel)]="paymentCategory" name="paymentCategory" aria-label="Payment Categories" class="button-group-payments">
          <mat-button-toggle value="amount">{{'AMOUNT' | translate: {Default: 'Amount'} }}</mat-button-toggle>
          <mat-button-toggle value="deposits">{{'DEPOSITS' | translate: {Default: 'Deposits'} }}</mat-button-toggle>
          <mat-button-toggle value="payments">{{'PAYMENTS' | translate: {Default: 'Payments'} }}</mat-button-toggle>
          <mat-button-toggle value="tips">{{'TIPS' | translate: {Default: 'Tips'} }}</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <app-view-customer-invoice *ngIf="appointment && paymentCategory === 'amount'" [appointment]="appointment" [contextIdDto]="contextIdDto" [invoiceId]="appointment.invoiceId?.id" (invoiceUpdated)="invoiceUpdated($event)">
      </app-view-customer-invoice>
      <app-view-deposit-requests *ngIf="appointment && paymentCategory === 'deposits'" [contextIdDto]="contextIdDto" [invoiceId]="appointment.invoiceId?.id" [requestForPaymentOwnerId]="appointment.customerId" [requestForPaymentOwnerType]="'CUSTOMER'">
      </app-view-deposit-requests>
      <app-view-customer-payments *ngIf="appointment && paymentCategory === 'payments'" [contextIdDto]="contextIdDto" (invoiceUpdated)="invoice = $event" [visible]="true" [invoiceId]="appointment.invoiceId?.id">
      </app-view-customer-payments>
      <app-view-customer-tips *ngIf="appointment && paymentCategory === 'tips'" [contextIdDto]="contextIdDto" (invoiceUpdated)="invoice = $event" [visible]="true" [invoiceId]="appointment.invoiceId?.id">
      </app-view-customer-tips>
    </mat-tab>

    <mat-tab label="Check-In" *ngIf="featuresHelperService.isFeatureAllowed(featureType.CheckinForms)">
      <app-view-checkin-forms *ngIf="appointment" [contextIdDto]="contextIdDto" [customerId]="appointment.customerId" [appointmentId]="appointment.id">
      </app-view-checkin-forms>
    </mat-tab>

  </mat-tab-group>

</div>
<!-- </mat-card-content>
</mat-card> -->