import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { NgxPhotoEditorModule } from 'ngx-photo-editor';
import { ImageEditorComponent } from './image-editor/image-editor.component';
import { NumberOnlyDirective } from './number-only.directive';

@NgModule({
  imports: [
    CommonModule,
    NgxPhotoEditorModule,
    // ImageUploadModule,
    MatIconModule,
    FlexLayoutModule
  ],
  declarations: [
    NumberOnlyDirective,
    ImageEditorComponent
  ],
  exports: [
    NumberOnlyDirective,
    ImageEditorComponent
  ],
})
export class DirectivesModule { }
