<div *ngIf="task && visible">
  <mat-toolbar color="primary">{{task.name}}</mat-toolbar>

  <mat-card appearance="outlined">

    <div *ngIf="task.dueDate">
      <strong>{{'DUE' | translate}}: {{task.dueDate | localizedDate:'medium'}}</strong>
    </div>

    <mat-card-content *ngIf="task.workflowTaskDefinition.actionDefinition && task.workflowTaskDefinition.actionDefinition.actionType">

      <div *ngIf="task.workflowTaskDefinition.actionDefinition.actionType.toUpperCase() === 'SEND_SMS'">
        <mat-form-field class="example-full-width" appearance="outline" style="width: 100%;">
          <mat-label>{{'SMS_MESSAGE' | translate}}</mat-label>
          <input matInput [(ngModel)]="task.workflowTaskDefinition.actionDefinition.sendSmsDefinition.message" name="smsMessage" #smsMessage="ngModel" class="demo-full-width" placeholder="{{'SMS_MESSAGE' | translate}}" style="width: 100%" [disabled]="!customerDto?.mobile || !customerDto?.userDto?.optIn?.allowSms" required>
        </mat-form-field>
      </div>

      <div *ngIf="task.workflowTaskDefinition.actionDefinition.actionType.toUpperCase() === 'SEND_MESSAGE'">
        <mat-form-field class="example-full-width" appearance="outline" style="width: 100%;">
          <mat-label>{{'SUBJECT' | translate}}</mat-label>
          <input matInput [(ngModel)]="task.workflowTaskDefinition.actionDefinition.sendMessageDefinition.subject" name="subject" #name="ngModel" class="demo-full-width" placeholder="{{'SUBJECT' | translate}}" style="width: 100%" [disabled]="!customerDto?.email || !customerDto?.userDto?.optIn?.allowEmail" required>
        </mat-form-field>

        <div fxLayout="row" fxLayoutAlign="center start">
          <div fxFlex="100" class="mt-xs">
            <app-flo-editor [text]="task.workflowTaskDefinition.actionDefinition.sendMessageDefinition.body" [readOnly]="!customerDto?.email || !customerDto?.userDto?.optIn?.allowEmail" (textUpdated)="bodyUpdated($event)"></app-flo-editor>
            <br>
          </div>
        </div>
      </div>
      <div class="mdc-mb-1" *ngIf="showErrorMessage() | async; let errorMessage;">
        <mat-error>{{errorMessage}}</mat-error>
      </div>
    </mat-card-content>
    <mat-card-content>
      <!-- This belongs in the mat-actions block -->
      <button *ngIf="task.workflowTaskDefinition.actionDefinition && task.workflowTaskDefinition.actionDefinition.actionType &&
        task.workflowTaskDefinition.actionDefinition.actionType.toUpperCase() === 'SEND_MESSAGE'" (click)="executeTask(task)" [disabled]="!canExecute()" mat-raised-button color="accent">{{'SEND_MESSAGE' | translate}}
      </button>
      &nbsp;
      <button *ngIf="!task.workflowTaskDefinition.actionDefinition" (click)="markAsDone()" mat-raised-button color="accent">{{'MARK_AS_DONE' | translate}}</button>
      &nbsp;
      <button (click)="closeThePanel()" mat-raised-button color="accent">{{'CLOSE' | translate}}</button>
    </mat-card-content>
  </mat-card>
</div>