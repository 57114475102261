<div class="flexbox-parent">
  <div fxLayout="row" fxLayoutAlign="center center" style="height: 60vh;  ">
    <div style="text-align:center;">
      <app-zero-image [name]="entityDefinitionIcon"></app-zero-image>
      <br>
      <h5 class="ma-0">{{'You don’t have any ' + entityDefinitionLabel + 's' | translate}}</h5><br>
      <button mat-raised-button color="accent" [disabled]="trialExpiredService.isTrialExpired()" *ngIf="canCreate" (click)="create()">
        {{'Add' | translate}} {{entityDefinitionLabel | translate}}
      </button>
    </div>
  </div>
</div>