<div class="entity-card">
  <div fxLayout="column">
    <div class="actions" *ngIf="!isModalOpen" fxFlex="100" fxLayout="row" fxLayoutAlign="space-between baseline">
      <div>
        <button (click)="backToList()" class="ml-xs mat-icon-button" mat-icon-button="">
    <span class="mat-button-wrapper">
    <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">arrow_back</mat-icon>
    </span>
          <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
          <div class="mat-button-focus-overlay"></div>
        </button>
      </div>
    </div>


    <div fxLayout="row" fxLayout.lt-md="column">
      <!-- icon here -->
      <div fxFlex="100" fxLayout="column">
        <div fxFlex="100" fxLayoutAlign="center">
          <div fxFlex="70" [class.max-w-100]="isModalOpen" fxFlex.xs="100">

            <mat-toolbar class="demo-toolbar" color="primary"
                         *ngIf="loadEiViewResponse && loadEiViewResponse.viewContextDto">
              <mat-toolbar-row>{{'Create' | translate}} {{ loadEiViewResponse.viewContextDto.entityDefinitionLabel | translate }}</mat-toolbar-row>
            </mat-toolbar>
            <app-element *ngIf="loadEiViewResponse && loadEiViewResponse.viewRootElementDto"

                         [elementDto]="loadEiViewResponse.viewRootElementDto"
                         [form]="form"
                         [eventBus]="eventBus"
                         [contextIdDto]="contextIdDto"
                         [viewContext]="loadEiViewResponse.viewContextDto"
                         [additionalDataMapDto]="loadEiViewResponse.additionalDataMapDto"
                         [showPlaceholder]="true"
                         [changeListener]="changeListener"
            >

            </app-element>

            <mat-card appearance="outlined" *ngIf="loadEiViewResponse">

              <mat-card-content>

                <mat-spinner *ngIf="creating"></mat-spinner>

                <button mat-raised-button color="accent"
                        *ngIf="!creating && !loadEiViewResponse.viewContextDto.addEditEntityInstanceContextDto.eiCreated"
                        (click)="createRecord()"
                        [disabled]="!form.valid"
                        >{{'CREATE' | translate}}
                </button>
                &nbsp;
                <button mat-raised-button [disabled]="creating" (click)="cancelIt()">{{'CANCEL' | translate}}</button>


              </mat-card-content>

            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
