<div class="app" [dir]="options.dir"
  [ngClass]="{'app-dark': options.dark, 'boxed': options.boxed, 'collapsed-sidebar': options.collapsed, 'compact-sidebar': options.compact, 'side-panel-opened': sidePanelOpened, 'side-panel-closed': !sidePanelOpened }">

  <!-- <ngx-loading-bar color="var(--color)"></ngx-loading-bar> -->
  <app-header [eventBus]="eventBusService" (toggleSidenav)="toggleSidenav($event)"
    (toggleNotificationSidenav)="toggleNotifications()"></app-header>
  <!-- mat-dark -->
  <mat-sidenav-container class="app-inner">
    <mat-sidenav #sidemenu class="sidebar-panel" id="sidebar-panel" [mode]="isOver() ? 'over' : 'side'"
      [opened]="!isOver() && !trialExpired" (open)="sidePanelOpened = true" (close)="sidePanelOpened = false" [fixedInViewport]="true">
      <ng-scrollbar autoHeightDisabled="false" class="ps sidebar-panel-scroll">
        <app-sidebar (click)="updatePS()" (menuChanged)="onMenuChanged()"
          (closeSidebar)="sidemenu.toggle()"></app-sidebar>
      </ng-scrollbar>
    </mat-sidenav>
    <div id="style-1">
      <router-outlet></router-outlet>
    </div>
    <mat-sidenav #notifications position="end" class="chat-panel" mode="over">
      <app-notifications-panel *ngIf="contextIdDto" [contextIdDto]="contextIdDto"
        (notificationUpdated)="notificationUpdated($event)"></app-notifications-panel>
    </mat-sidenav>
  </mat-sidenav-container>
  <app-notification-v2 (notificationUpdated)="notificationUpdated($event)"></app-notification-v2>
</div>

<ngx-spinner type="ball-scale-multiple" bdColor="rgba(0, 0, 0, 0.8)" [fullScreen]="true"></ngx-spinner>
