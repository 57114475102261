import { Component, Input, OnInit } from '@angular/core';
import { ViewContextDto } from '@savvy/view-composite';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ChangeListener } from './changeListener';
import { NavigateService } from '../entry/NavigateService';
import { Page } from '../shared/model/page';
import { CachedlistcompService } from '@savvy/cached-view';
import { TrialExpiredService } from '../shared/services/trial-expired.service';
import { MatDialog } from '@angular/material/dialog';
import { ViewEntityComponent } from './viewEntity.component';
import { EventBusService } from './EventBusService';
import { PhoneNumberHelperService } from '../shared/services/phone-number-helper.service';
import { ContextIdDto, EntityInstanceId, ListEntityListDto, ListEntityListItemDto, ViewAssociatedCachedListData } from '@savvy/view-definition';

@Component({
  selector: 'app-view-associated-cached-entitys-list',
  templateUrl: 'viewAssociatedCachedEntitysList.component.html'
})
export class ViewAssociatedCachedEntitysListComponent implements OnInit {

  @Input() viewAssociatedCachedListData: ViewAssociatedCachedListData;
  @Input() contextIdDto: ContextIdDto;
  @Input() viewContext: any;
  @Input() form: UntypedFormGroup;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;
  @Input() useModalOnClick: boolean;
  @Input() eventBus: EventBusService;

  page = new Page();
  selected: ListEntityListItemDto[] = [];
  listEntityListDto: ListEntityListDto;

  columns = [
    { prop: 'displayText', name: '' }
  ];

  constructor(
    private router: Router,
    private naviateService: NavigateService,
    public trialExpiredService: TrialExpiredService,
    private cachedListCompositeApi: CachedlistcompService,
    private dialog: MatDialog,
    private sharedService: PhoneNumberHelperService) {
  }
  ngOnInit() {
    this.page.size = 10;
    if (this.viewAssociatedCachedListData.listEntityListDto) {
      this.listEntityListDto = this.viewAssociatedCachedListData.listEntityListDto;
      this.page.totalElements = this.viewAssociatedCachedListData.listEntityListDto.totalRows;
      this.page.totalPages = this.viewAssociatedCachedListData.listEntityListDto.totalPages;
      this.page.pageNumber = 0;

    } else {
      this.setPage({ offset: 0 });
    }
  }
  setPage(pageInfo) {
    console.log('pageInfo is ' + pageInfo.offset);
    this.page.pageNumber = pageInfo.offset;
    this.loadPage();
  }

  loadPage() {
    // Call list display names and pass page number

    console.log('loading page:');

    if (!this.viewAssociatedCachedListData.listCachedEntityListDefinition.cachedLineViewDefinitionId) {
      console.log('err');
      return;
    }

    if (this.viewAssociatedCachedListData &&
      this.viewAssociatedCachedListData.entityInstanceIdsCommarSeparated &&
      this.viewAssociatedCachedListData.entityInstanceIdsCommarSeparated.length > 0) {

      this.cachedListCompositeApi.listCachedEntityListByEiIds(
        this.viewAssociatedCachedListData.listCachedEntityListDefinition.cachedLineViewDefinitionId.id,
        this.viewAssociatedCachedListData.entityInstanceIdsCommarSeparated,
        this.viewContext.entityDefinitionId.id,
        (this.page.pageNumber + 1),
        this.page.size,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType).subscribe(response => {
          this.listEntityListDto = response.listEntityListDto;
          this.page.totalElements = response.listEntityListDto.totalRows;
          this.page.totalPages = response.listEntityListDto.totalPages;
        });
    } else {
      this.cachedListCompositeApi.listCachedEntityListNoEiIds(
        this.viewAssociatedCachedListData.listCachedEntityListDefinition.cachedLineViewDefinitionId.id,
        this.viewContext.entityDefinitionId.id,
        (this.page.pageNumber + 1),
        this.page.size,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType).subscribe(response => {
          this.listEntityListDto = response.listEntityListDto;
          this.page.totalElements = response.listEntityListDto.totalRows;
          this.page.totalPages = response.listEntityListDto.totalPages;
        });
    }
  }
  showTable() {
    if (this.rowsAvailable() && this.page.pageNumber === 0 && this.lessThanPageWorth()) {
      return false;
    } else {
      return true;
    }
  }

  onSelect(event) {
    this.rowSelected(this.selected[0].entityInstanceId);
  }

  lessThanPageWorth() {
    return this.numRows() < this.page.size;
  }

  numRows() {
    return this.listEntityListDto.rows.length;
  }
  rowsAvailable() {
    return this.listEntityListDto && this.listEntityListDto.rows;
  }

  rowSelected(entityInstanceId: EntityInstanceId) {
    if (this.useModalOnClick) {
      this.dialog.open(ViewEntityComponent, {
        data: {
          entityInstanceId: entityInstanceId.id,
          contextIdDto: this.contextIdDto,
          eventBus: this.eventBus,
          isViewMode: true
        },
        panelClass: 'modal-100',
        maxHeight: '80vh'
      });
    } else {
      this.sharedService.viewEntityModalSub.next(true);
      this.naviateService.viewEntity(this.contextIdDto, entityInstanceId);
    }
  }

  create() {
    this.sharedService.viewEntityModalSub.next(true);
    console.log('inside create in ListEntitysListComponent');
    if (this.viewContext.viewContextTypeDto === ViewContextDto.ViewContextTypeDtoEnum.Customer) {
      this.router.navigate(['/entity/createEntity',
        this.viewAssociatedCachedListData.listCachedEntityListDefinition.entityDefinitionId.id,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.viewContext.customerContextDto.customerId.id
      ]);
    } else {
      this.sharedService.viewEntityModalSub.next(true);
      this.router.navigate(['/entity/createEntity',
        this.viewAssociatedCachedListData.listCachedEntityListDefinition.entityDefinitionId.id,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.viewContext.entityInstanceId.id,
        this.viewContext.entityDefinitionId.id
      ]);
    }
  }
}

