<div class="calendar-email-modal calendar-email-modal-mobile">
    <mat-toolbar color="primary">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span fxFlex>{{'VIEW_DISCOUNTS' | translate}}</span>
            <button class="mr-1" mat-icon-button (click)="applyDiscount(null)" #tooltip="matTooltip"
                matTooltip="{{'CLEAR_DISCOUNTS' | translate}}">
                <mat-icon>undo</mat-icon>
            </button>
            <button mat-icon-button (click)="dialogRef.close()" #tooltip="matTooltip"
                matTooltip="{{'CLOSE_DIALOG' | translate}}">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
    <div mat-dialog-content class="m-0 p-0 mat-dialog-content">
        <app-view-discounts [hideHeader]="false" [amount]="data?.amount" [currencyCode]="data?.currencyCode" (onApply)="applyDiscount($event)">
        </app-view-discounts>
    </div>
</div>