import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FloSnackbarComponent } from '../../../snackbar/floSnackbar.component';
import {ChangePlanQuantity, Subscription, SubscriptionCompService} from '@savvy/subscription';
import {ContextIdDto} from '@savvy/entity-instance-composite';

export interface SetQuantityModalDialogData {
  contextIdDto: ContextIdDto;
  subscription: Subscription;
}

@Component({
  selector: 'app-set-quantity-modal',
  templateUrl: 'set-quantity-modal.component.html'
})
export class SetQuantityModalComponent implements OnInit {

  contextIdDto: ContextIdDto;
  subscription: Subscription;
  showSpinner = false;

  quantity: number;

  public form: UntypedFormGroup; // our form model

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SetQuantityModalDialogData,
    private notify: FloSnackbarComponent,
    private subCompApi: SubscriptionCompService,
    public dialogRef: MatDialogRef<SetQuantityModalComponent>) {
    this.contextIdDto = this.data.contextIdDto;
    this.subscription = this.data.subscription;
    this.quantity = this.subscription.quantity;
  }

  ngOnInit() {
  }

  changePlan() {
    if(Number(this.quantity) === 0) {
      this.notify.message = 'There should be at least one salon';
      this.notify.open();
      return;
    }

    const req = <ChangePlanQuantity>{};
    req.contextIdDto = this.contextIdDto;
    req.quantity = this.quantity;
    req.subscriptionId = this.subscription.subscriptionId;
    this.subCompApi.changePlanQuantity(req).subscribe(response => {
      this.notify.message = 'Success changing plan';
      this.notify.open();
      this.dialogRef.close(true);
    });
  }
}
