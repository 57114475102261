<mat-toolbar color="primary" *ngIf="flagDefinitions && flagDefinitions.length > 0">
  <mat-toolbar-row>
    {{'FLAGS' | translate}}
  </mat-toolbar-row>
</mat-toolbar>


<div class="flexbox-parent" *ngIf="flagDefinitions && flagDefinitions.length == 0">
  <div fxLayout="row" fxLayoutAlign="center center" style="height: 60vh;  ">
    <div style="text-align:center;">
      <app-zero-image name="flag"></app-zero-image><br>
      <h5 class="ma-0">{{'NO_FLAG_DEFS' | translate}}</h5><br>
      <button mat-raised-button color="primary" (click)="create()">
        {{'ADD_FLAG' | translate}}
      </button>
    </div>
  </div>
</div>


<div *ngIf="flagDefinitions && flagDefinitions.length > 0">
  <ngx-datatable class="material" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'" [rows]="flagDefinitions" [selected]="selected" [selectionType]="'cell'" (select)="onSelect($event)">

    <ngx-datatable-column name="Flag Name" [width]="80" prop="name"></ngx-datatable-column>
    <ngx-datatable-column name="Description" [width]="80" prop="description"></ngx-datatable-column>
    <ngx-datatable-column name="Colour" [width]="80" prop="colour"></ngx-datatable-column>
  </ngx-datatable>
</div>

<button mat-fab class="mat-fab-bottom-right" color="accent" (click)="create()">
  <mat-icon class="mat-24">add</mat-icon>
</button>