import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Feature } from "@savvy/add-ons";
import { AppointmentService, AppointmentServiceRow } from '@savvy/appointment';
import { ContextIdDto } from '@savvy/entity-instance-composite';
import { PetSearchResultDto } from '@savvy/search';
import { ConsumerService } from '@savvy/services';
import { Tax } from '@savvy/tax';
import { IdNameTupleDto } from '@savvy/user';
import { Vaccination, VaccinationService } from "@savvy/vaccination";
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { EventColor } from "calendar-utils";
import * as _ from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { DataShareService } from 'src/app/core/data-share.service';
import { AddCustomerComponent } from 'src/app/flo/customer/customer/addCustomer.component';
import { ViewCustomerV2Component } from 'src/app/flo/customer/view-customer-v2/view-customer-v2.component';
import { DateUtils } from 'src/app/flo/dates/DateUtils';
import { CreatePetDetailsV2Component } from 'src/app/flo/pets/create-pet-details-v2/create-pet-details-v2.component';
import { ViewPetDetailsV2Component } from 'src/app/flo/pets/view-pet-details-v2/view-pet-details-v2.component';
import { CreateServiceComponent } from 'src/app/flo/shared/components/setup/services/create-service/create-service.component';
import { AppointmentConfigHelperService } from 'src/app/flo/shared/services/appointment-config-helper.service';
import { LookAndFeelConfig, LookAndFeelSharedService, TimeValue } from 'src/app/flo/shared/services/look-and-feel-shared.service';
import { OrgUsersHelperService } from 'src/app/flo/shared/services/org-users-helper.service';
import { ServicesHelperService } from 'src/app/flo/shared/services/services-helper.service';
import { TaxesHelperService } from 'src/app/flo/shared/services/taxes-helper.service';
import { UserCountryService } from 'src/app/services/userCountry.service';
import { StaffSelectorComponent } from '../../../shared/components/staff-selector/staff-selector.component';
import { FeaturesHelperService } from "../../../shared/services/features-helper.service";
import { ServiceSelectorData } from '../../shared/extra-types';
import { CreatePetAppointmentV2SyncService, PopulateNewPetEventData } from '../create-pet-appointment-v2-sync.service';
import { PetServiceSelectorV2Component } from './pet-service-selector-v2.component';

export interface PetDueVaccinations {
  petId: string;
  dueVaccinations: Array<Vaccination>;
  color: EventColor;
}
@Component({
  selector: 'app-pet-services-selector-v2',
  templateUrl: './pet-services-selector-v2.component.html',
  styleUrls: ['./pet-services-selector-v2.component.scss']
})
export class PetServicesSelectorV2Component implements OnInit, OnDestroy {
  @ViewChild('petTable') petTable: DatatableComponent;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  @Input() contextIdDto: ContextIdDto;
  @Input() durationRequired = false;
  @Input() allowedToRemove = true;
  @Input() userToUseWhenCreating: IdNameTupleDto;

  @Output() updateCustFlags: EventEmitter<boolean> = new EventEmitter();


  @ViewChild(StaffSelectorComponent, { static: false }) staffSelectorComponent: StaffSelectorComponent;
  @ViewChild(PetServiceSelectorV2Component, { static: false }) petServiceSelectorV2Component: PetServiceSelectorV2Component;

  // petServices: Array<PetServicesRequired> = new Array<PetServicesRequired>();
  valid = true;
  lookAndFeelConfig: LookAndFeelConfig;
  timeArray: TimeValue[] = [];
  step = 0;
  subs: Subscription[] = [];
  lastPrice = 0;
  taxDefs: Tax[] = [];

  petDueVaccinations: Array<PetDueVaccinations> = [];
  isVaccinationFeatureAllowed = false;

  constructor(
    public deviceService: DeviceDetectorService,
    public userCountryService: UserCountryService,
    private appointmentService: AppointmentService,
    private lookAndFeelService: LookAndFeelSharedService,
    private dateUtils: DateUtils,
    public dataShareService: DataShareService,
    public servicesHelperService: ServicesHelperService,
    public taxesHelperService: TaxesHelperService,
    private snackBar: MatSnackBar,
    public createPetAppointmentV2SyncService: CreatePetAppointmentV2SyncService,
    public appointmentConfigHelperService: AppointmentConfigHelperService,
    private dialog: MatDialog,
    public featuresHelperService: FeaturesHelperService,
    private vaccinationApi: VaccinationService,
    public orgUsersHelperService: OrgUsersHelperService,
  ) {

  }

  ngOnInit() {
    if (this.contextIdDto) {
      this.taxesHelperService.getTaxDefs(this.contextIdDto).then(res => {
        this.taxDefs = res;
      });
      // listen for search field value changes
      this.lookAndFeelConfig = this.lookAndFeelService.getLookAndFeelConfig(this.contextIdDto);
      if (this.lookAndFeelConfig) {
        this.timeArray = this.lookAndFeelConfig.timeArray;
      } else {
        console.log('no look and feel config');
      }
      this.isVaccinationFeatureAllowed = this.featuresHelperService.isFeatureAllowed(Feature.TypeEnum.Vaccinations);
    }
    this.subs.push(this.createPetAppointmentV2SyncService.populateNewPet$.subscribe((pet: PopulateNewPetEventData) => {
      if (pet && pet.populatingAppointment) {
        this.servicesHelperService.getConsumerServices(this.contextIdDto).then(consumerServices => {
          if (pet?.petSearchResultDto?.petId) {
            const petId = pet.petSearchResultDto.petId;
            if (this.createPetAppointmentV2SyncService.groupedServicesWithPetId?.[petId]?.length) {
              for (const appointmentService of this.createPetAppointmentV2SyncService.groupedServicesWithPetId[petId]) {
                const serviceSelected = _.cloneDeep(consumerServices.find(s => s.id === appointmentService.serviceNumber));


                if (serviceSelected) {
                  serviceSelected.durationInMins = appointmentService.duration;
                  serviceSelected.unitPrice = appointmentService.unitPrice;
                  serviceSelected.tax = appointmentService.tax;
                  if (!appointmentService.tax) {
                    serviceSelected.taxId = '';
                  } else {
                    const taxDef = this.taxDefs.find(td => td.taxRate === appointmentService.tax);
                    if (taxDef) {
                      serviceSelected.taxId = taxDef?.id;
                    }
                  }
                }
                let serviceToBeSelected: ConsumerService = {} as ConsumerService;
                if (serviceSelected) {
                  serviceToBeSelected = serviceSelected;
                } else {
                  serviceToBeSelected = {
                    ownerId: this.contextIdDto.contextId,
                    serviceName: appointmentService?.serviceName,
                    id: appointmentService.serviceNumber,
                    deleted: true
                  };
                }
                if (!this.createPetAppointmentV2SyncService.selectedServices[petId]) {
                  this.createPetAppointmentV2SyncService.selectedServices[petId] = [];
                }
                if (this.createPetAppointmentV2SyncService.selectedServices[petId]) {
                  this.createPetAppointmentV2SyncService.selectedServices[petId].push(
                    _.cloneDeep({
                      selectedService: serviceToBeSelected,
                      selectedStaff: { id: appointmentService.serviceUser?.id, name: appointmentService.serviceUserFullName },
                      grossPrice: appointmentService.grossPrice,
                      quantity: appointmentService.quantity,
                      subTotal: appointmentService.subTotal,
                      startTime: appointmentService.startTime,
                      endTime: appointmentService.endTime,
                    })
                  );
                  if (pet.rebooking) {
                    const lastIndex = this.createPetAppointmentV2SyncService.selectedServices[petId].length - 1;
                    this.updateGrossPrice(this.createPetAppointmentV2SyncService.selectedServices[petId][lastIndex]);
                  }
                }

                this.createPetAppointmentV2SyncService.calculateTotalAmount();

              }
            }
          }
        });
      }
      if (!pet.populatingAppointment) {
        this.calculateEndTime();
      }
    }));
    this.subs.push(this.createPetAppointmentV2SyncService.petSelectionChanged$.subscribe((res: PetSearchResultDto[]) => {
      if (res.length) {
        for (const pet of this.createPetAppointmentV2SyncService.petsSelected) {
          if (pet?.petId && !this.createPetAppointmentV2SyncService.selectedServices[pet.petId]) {
            this.addServiceNew(pet);
          }
        }
        this.loadDueVaccinations();
      }
    }));

  }

  ngOnDestroy(): void {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }

  calculateLocalEndTime(service: ServiceSelectorData) {
    if (service && service.startTime) {
      console.log('6666', service);
      const endTime = this.dateUtils.addMinsToTimeString(service.startTime, service.selectedService.durationInMins);
      if (this.timeArray?.length) {
        service.endTime = this.timeArray.find(t => t?.actualValue >= endTime)?.actualValue;
      }
    }
  }

  calculateEndTime(service?: ServiceSelectorData) {
    setTimeout(() => {
      this.calculateLocalEndTime(service);
      this.createPetAppointmentV2SyncService.calculateStartEndTime();
    }, 10);
  }


  serviceSelected(service: ConsumerService, petId, rowIndex: number, serviceData: ServiceSelectorData) {
    this.createPetAppointmentV2SyncService.selectedServices[petId][rowIndex].selectedService = _.cloneDeep(service);
    this.createPetAppointmentV2SyncService.selectedServices[petId][rowIndex].quantity = 1;
    this.createPetAppointmentV2SyncService.selectedServices[petId][rowIndex].subTotal = service.unitPrice;
    this.createPetAppointmentV2SyncService.selectedServices[petId][rowIndex].grossPrice = service.unitPrice;
    this.createPetAppointmentV2SyncService.selectedServices[petId][rowIndex].startTime = serviceData.startTime;
    // eslint-disable-next-line max-len
    this.createPetAppointmentV2SyncService.selectedServices[petId][rowIndex].endTime = this.dateUtils.addMinsToTimeString(serviceData.startTime, service.durationInMins);
    if (this.userToUseWhenCreating?.id) {
      this.createPetAppointmentV2SyncService.selectedServices[petId][rowIndex].selectedStaff = this.userToUseWhenCreating;
    }
    this.calculateEndTime(this.createPetAppointmentV2SyncService.selectedServices[petId][rowIndex]);
    this.updateUnitPrice(serviceData);
    this.checkIfValid();
  }

  staffSelectionChanged(serviceUser: IdNameTupleDto, petId, rowIndex) {
    if (!serviceUser) {
      console.log('No service user availabe', serviceUser);
      return;
    }
    this.createPetAppointmentV2SyncService.selectedServices[petId][rowIndex].selectedStaff = serviceUser;
    this.userToUseWhenCreating = { id: '', name: '' };
    this.calculateEndTime(this.createPetAppointmentV2SyncService.selectedServices[petId][rowIndex]);
    this.checkIfValid();
  }

  checkIfValid() {
    this.valid = true;
    for (const key in this.createPetAppointmentV2SyncService.selectedServices) {
      if (Object.prototype.hasOwnProperty.call(this.createPetAppointmentV2SyncService.selectedServices, key)) {
        const groupedRow = this.createPetAppointmentV2SyncService.selectedServices[key];
        for (const pet of groupedRow) {
          if (!pet['selectedService'] || !pet['selectedStaff']) {
            this.valid = false;
          }
        }
      }
    }
  }

  durationInMinsChanged(service: ServiceSelectorData) {
    this.calculateEndTime(service);
  }

  startTimeChanged(startTime: string, service: ServiceSelectorData) {
    this.setEndTimeFromStart(startTime, service);
  }

  setEndTimeFromStart(startTime: string, service: ServiceSelectorData) {
    console.log('got duration in mins ' + service.selectedService);
    service.endTime = this.dateUtils.addMinsToTimeString(startTime, service.selectedService.durationInMins);
    console.log('just set endTime to ' + service.endTime);
    service.selectedService.durationInMins = _.cloneDeep(this.dateUtils.getDurationInMins(startTime, service.endTime));
    this.calculateEndTime(service);
    this.checkIfValid();
  }

  endTimeChanged(endTime: string, service: ServiceSelectorData) {
    if (endTime <= service.startTime) {
      this.snackBar.open('End time should be greater than start time', 'ok', {
        duration: 3000,
      });
    }
    console.log('222', service);
    service.selectedService.durationInMins = _.cloneDeep(this.dateUtils.getDurationInMins(service.startTime, service.endTime));
    this.calculateEndTime(service);
    this.checkIfValid();
  }

  compareTimeFn(user1: string, user2: string) {
    // console.log('comparing');
    return user1 && user2 && user1 === user2;
  }


  toggleExpandGroup(group) {
    console.log('Toggled Expand Group!', group);
    this.petTable.groupHeader.toggleExpandGroup(group);
  }

  getPrevAppointmentServices(petId: string): Promise<AppointmentServiceRow[]> {
    return new Promise(resolve => {
      this.appointmentService.loadPetsMostFutureAppointmentIncludingPast(
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        petId)
        .subscribe(response => {
          if (response) {
            if (this.createPetAppointmentV2SyncService.editMode &&
              this.createPetAppointmentV2SyncService.appointment.id === response.id) {
              // If in edit mode then we don't want to load the previous services
              resolve([]);
              return;
            }
            resolve(response.appointmentServiceRows);

          } else {
            resolve([]);
          }
        }, error => {
          resolve([]);
        });
    });
  }

  pushNewService(petId: string, selectedService: ConsumerService, selectedStaff: IdNameTupleDto, startTime: string, endTime: string) {
    if (this.createPetAppointmentV2SyncService.selectedServices[petId]) {

      this.createPetAppointmentV2SyncService.selectedServices[petId].push(
        _.cloneDeep({
          selectedService: _.cloneDeep(selectedService),
          selectedStaff,
          quantity: 1,
          grossPrice: selectedService?.unitPrice,
          subTotal: selectedService?.unitPrice,
          startTime,
          endTime,
        }));
      const lastAddedIndex = this.createPetAppointmentV2SyncService.selectedServices[petId].length - 1;
      this.calculateEndTime(this.createPetAppointmentV2SyncService.selectedServices[petId][lastAddedIndex]);
      this.updateGrossPrice(this.createPetAppointmentV2SyncService.selectedServices[petId][lastAddedIndex]);
      this.checkIfValid();
    }
  }

  async addServiceNew(pet: PetSearchResultDto, newlyAddedByUser = false) {
    if (!this.createPetAppointmentV2SyncService.selectedServices[pet.petId]) {
      this.createPetAppointmentV2SyncService.selectedServices[pet.petId] = [];
    }
    if (this.createPetAppointmentV2SyncService.selectedServices[pet.petId].length
      // eslint-disable-next-line max-len
      && !this.isRowValid(this.createPetAppointmentV2SyncService.selectedServices[pet.petId][this.createPetAppointmentV2SyncService.selectedServices[pet.petId].length - 1])) {
      this.snackBar.open('Please select a service first', 'ok', {
        duration: 3000,
      });
      return;
    }
    let selectedService = {} as ConsumerService;
    this.servicesHelperService.getConsumerServices(this.contextIdDto).then(async (consumerServices) => {
      if (consumerServices.length) {
        selectedService = _.cloneDeep(consumerServices[0]);
      }

      const lastIndexOfServices = this.createPetAppointmentV2SyncService.selectedServices[pet.petId].length - 1;
      let startTime = this.createPetAppointmentV2SyncService.startTime;
      console.log('111', selectedService);
      let endTime = this.dateUtils.addMinsToTimeString(this.createPetAppointmentV2SyncService.startTime, selectedService.durationInMins);

      if (lastIndexOfServices > -1) {
        startTime = _.cloneDeep(this.createPetAppointmentV2SyncService.selectedServices[pet.petId][lastIndexOfServices].endTime);
        console.log('1212', selectedService);
        endTime = _.cloneDeep(this.dateUtils.addMinsToTimeString(startTime, selectedService.durationInMins));
      }
      console.log('getting prev appointment services ', pet.petId);
      console.log('fresh ', newlyAddedByUser);
      const appointmentServices = await this.getPrevAppointmentServices(pet?.petId);
      console.log('prev appointment services ', appointmentServices);

      if (this.appointmentConfigHelperService.appointmentConfig.autoPopulateFromPreviousAppointmentEnabled
        && appointmentServices.length && !newlyAddedByUser) {
        // add services from last appointment
        for (const appointmentService of appointmentServices) {
          selectedService = consumerServices.find(cs => String(appointmentService.serviceNumber) === String(cs.id)) || {} as ConsumerService;

          if (Object.keys(selectedService).length) {
            console.log('appointmentService', appointmentService);
            console.log('selectedService', selectedService);
            selectedService.unitPrice = appointmentService.unitPrice;
            selectedService.tax = appointmentService.tax;

            if (!appointmentService.tax) {
              selectedService.taxId = '';
            } else {
              selectedService.taxId = this.taxDefs.find(td => td.taxRate === appointmentService.tax)?.id;
            }

            if (pet?.petId && appointmentService.eiId === pet.petId) {
              if (this.createPetAppointmentV2SyncService.selectedServices[pet.petId]) {
                let selectedStaff = { id: appointmentService?.serviceUser?.id, name: appointmentService?.serviceUserFullName };
                if (this.userToUseWhenCreating?.id) {
                  selectedStaff = { id: this.userToUseWhenCreating?.id, name: this.userToUseWhenCreating?.name || '' };
                }
                this.createPetAppointmentV2SyncService.selectedServices[pet.petId].push(
                  _.cloneDeep({
                    selectedService: _.cloneDeep(selectedService),
                    selectedStaff,
                    quantity: 1,
                    grossPrice: selectedService?.unitPrice,
                    subTotal: selectedService?.unitPrice,
                    startTime,
                    endTime,
                  }));
                const lastAddedIndex = this.createPetAppointmentV2SyncService.selectedServices[pet.petId].length - 1;
                this.calculateEndTime(this.createPetAppointmentV2SyncService.selectedServices[pet.petId][lastAddedIndex]);
                this.updateGrossPrice(this.createPetAppointmentV2SyncService.selectedServices[pet.petId][lastAddedIndex]);
                this.checkIfValid();
              }
            }
          }
        }

        if (!this.createPetAppointmentV2SyncService.selectedServices[pet.petId].length) {
          // PRO-2888: Service has been deleted now
          selectedService = _.cloneDeep(consumerServices[0]);
          this.pushNewService(pet.petId, selectedService, this.userToUseWhenCreating, startTime, endTime);
        }
      } else {
        console.log('pusing new service');
        this.pushNewService(pet.petId, selectedService, this.userToUseWhenCreating, startTime, endTime);
      }

    });
  }

  updateUnitPrice(service: ServiceSelectorData) {
    if (!service.selectedService.unitPrice) {
      service.selectedService.unitPrice = 0;
    }
    service.selectedService.unitPrice = parseFloat(String(service.selectedService.unitPrice));
    this.updateQty(service.quantity, service);
  }

  updateGrossPrice(service: ServiceSelectorData) {
    if (service?.selectedService?.taxId) {
      const tax = this.taxDefs.find(td => td.id === service.selectedService.taxId);
      if (tax) {
        service.selectedService.tax = tax.taxRate;
        service.grossPrice = Number((service.subTotal + (service.subTotal * ((tax?.taxRate || 0) / 100))).toFixed(2));
      }
    } else {
      service.selectedService.tax = 0;
      service.grossPrice = service.subTotal;
    }
    this.createPetAppointmentV2SyncService.calculateTotalAmount();
  }

  updateQty(qty, service: ServiceSelectorData) {
    service.subTotal = service.selectedService.unitPrice * qty;
    // console.log('2323', service);
    service.selectedService.durationInMins = service.selectedService.durationInMins * qty;
    this.updateGrossPrice(service);
  }

  isRowValid(service: ServiceSelectorData) {
    return service && service.selectedService && service.selectedService.id && service.selectedStaff.id;
  }

  isGroupValid(group) {
    let valid = false;
    for (const pet of group.value) {
      if (pet['selectedService']) {
        if (this.isRowValid(pet)) {
          valid = true;
        } else {
          valid = false;
        }
      }
    }
    return valid;
  }

  deleteServiceNew(petId, rowIndex) {
    this.createPetAppointmentV2SyncService.selectedServices[petId].splice(rowIndex, 1);
    this.calculateEndTime();
    this.checkIfValid();
  }

  getLastSeenPrice(pet: PetSearchResultDto, service: ConsumerService) {
    if (!this.lastPrice && service?.id) {
      this.appointmentService.lastPetPrice(this.contextIdDto.contextId, this.contextIdDto.contextIdType,
        pet.petId, service.id)
        .subscribe(res => {
          this.lastPrice = res.lastPrice;
        });
    }
  }

  openEditPetModal(pet: PetSearchResultDto) {
    const createPetModal = this.dialog.open(CreatePetDetailsV2Component, {
      data: {
        contextIdDto: this.contextIdDto,
        customerId: pet.petOwnerId,
        petId: pet.petId
      },
      maxWidth: '1024px',
      panelClass: [
        'modal-100',
        'helpwindow',
        'petProfileModal'
      ],
      autoFocus: false
    });

    createPetModal.afterClosed()
      .subscribe(
        () => {
          // this.loadPetFlags(petId);

        });
  }

  openViewPetModal(pet: PetSearchResultDto) {
    const createPetModal = this.dialog.open(ViewPetDetailsV2Component, {
      data: {
        contextIdDto: this.contextIdDto,
        customerId: pet.petOwnerId,
        petId: pet.petId
      },
      maxWidth: '1024px',
      // maxHeight: '80vh',
      // height: '80vh',
      panelClass: [
        'modal-100',
        'helpwindow',
        'petProfileModal'
      ],
      autoFocus: false
    });

    createPetModal.afterClosed()
      .subscribe(
        () => {

          // this.loadPetFlags(petId);
        });
  }

  openViewCustomerModal(pet: PetSearchResultDto) {
    const createPetModal = this.dialog.open(ViewCustomerV2Component, {
      data: {
        contextIdDto: this.contextIdDto,
        customerId: pet.petOwnerId
      },
      maxWidth: '1024px',
      // maxHeight: '80vh',
      // height: '80vh',
      panelClass: [
        'modal-100',
        'helpwindow',
        'petProfileModal'
      ],
      autoFocus: false
    });

    createPetModal.afterClosed()
      .subscribe(
        () => {
          // if (this.customerStatsComponent) {
          //   this.customerStatsComponent.loadStatus();
          // }
          this.updateCustFlags.emit(true);
        });
  }

  openEditCustomerModal(pet: PetSearchResultDto) {
    if (!pet?.petOwnerId) {
      console.log('No customerId');
      return;
    }
    const createPetModal = this.dialog.open(AddCustomerComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        customerId: pet.petOwnerId,
        isViewMode: true
      },
      maxWidth: '1024px',
      panelClass: [
        'modal-100',
        'helpwindow',
        'scrollable-modal-2'
      ],
      autoFocus: false,
      disableClose: true
    });

    createPetModal.afterClosed()
      .subscribe(
        () => {
          // if (this.customerStatsComponent) {
          //   this.customerStatsComponent.loadStatus();
          // }
          this.updateCustFlags.emit(true);

        });
  }

  createNewService() {
    const dialogRef = this.dialog.open(CreateServiceComponent, {
      width: '550px',
      panelClass: ['scrollable-modal', 'helpwindow'],
      data: {
        // serviceGroups: this.serviceGroups,
        taxDefs: this.taxDefs,
        contextIdDto: this.contextIdDto
      }
    });

    dialogRef.afterClosed().subscribe((serviceNew) => {
      if (serviceNew) {
        console.log('The service is saved');
        this.servicesHelperService.getConsumerServices(this.contextIdDto, true).then(consumerServices => {
          if (consumerServices.length && this.petServiceSelectorV2Component) {
            this.petServiceSelectorV2Component.loadAndSetService(serviceNew, consumerServices);
          }
        });
      }
    });
  }


  loadDueVaccinations() {
    this.petDueVaccinations = Object.assign([], []);
    this.createPetAppointmentV2SyncService.petsSelected.forEach(pet => {
      if (this.contextIdDto?.contextId && pet.petId && this.isVaccinationFeatureAllowed) {
        this.vaccinationApi.getDueVaccinationsByOwnerIdAndPetId(this.contextIdDto.contextId, this.contextIdDto.contextIdType, pet.petId).subscribe(res => {
          let petDueVacc = <PetDueVaccinations>{};
          petDueVacc.petId = pet.petId;
          petDueVacc.dueVaccinations = res;

          this.petDueVaccinations.push(petDueVacc);
        });
      }
    })

  }

  getDueVaccines(idx: number) {
    let dueVaccines = '';

    for (let due of this.petDueVaccinations[idx].dueVaccinations) {
      if (dueVaccines.length > 0) {
        dueVaccines = dueVaccines + ", ";
      }
      dueVaccines = dueVaccines + due.nameOfVaccination;
    }

    return dueVaccines;
  }

  createFlag(flagId: string, pet) {
    if (!pet.flags) {
      pet.flags = new Array();
    }
    pet.flags.push(flagId);
  }

}

