import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Pet } from '@savvy/search';
import { OptoutOption } from '../optout-option.enum';

@Component({
  selector: 'app-deceased-confirmation',
  templateUrl: './deceased-confirmation.component.html',
  styleUrls: ['./deceased-confirmation.component.scss']
})
export class DeceasedConfirmationComponent implements OnInit {

  optOutOption: OptoutOption = OptoutOption.none;
  pet: Pet;
  ageDate: Date;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<DeceasedConfirmationComponent>
  ) { }

  ngOnInit(): void {
    this.pet = this.data?.pet;
    if (this.pet) {
      this.ageDate = this.pet.createdDate ? new Date(this.pet.createdDate) : new Date(this.pet.dob);
    }
  }

  confirm() {
    const result = {
      optOutOption: this.optOutOption
    };
    this.dialogRef.close(result);
  }

}
