import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
// import { EventBusService } from '../EventBusService';
import { ContextIdDto } from '@savvy/entity-instance-composite';

import { ConsumerService, ServicesService } from '@savvy/services';
import { ReplaySubject, Subject } from 'rxjs';
import { EntityDefinitionId } from '@savvy/view-definition';
// import { AddServiceDialogComponent } from '../fieldinstance/addServiceDialog.component';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
// import { EventBusService } from '../../../element/EventBusService';
// import { AddServiceDialogComponent } from '../../../element/fieldinstance/addServiceDialog.component';
// import { CreateAppointmentV2SyncService } from '../create-appointment-v2-sync.service';
import { Tax, TaxDto } from '@savvy/tax';
import { CreateServiceComponent } from 'src/app/flo/shared/components/setup/services/create-service/create-service.component';
import { DatalistService } from '@savvy/datalist';
import { DataShareService } from 'src/app/core/data-share.service';
import { ServicesHelperService } from 'src/app/flo/shared/services/services-helper.service';
import { EventBusService } from 'src/app/flo/element/EventBusService';
import { CreateAppointmentV2SyncService } from '../../create-appointment-v2-sync.service';
import { Package } from '@savvy/packages';
import { PackagesHelperService } from 'src/app/flo/shared/services/packages-helper.service';
import { CreatePackageComponent } from 'src/app/flo/shared/components/setup/packages/create-package/create-package.component';
import { TaxesHelperService } from 'src/app/flo/shared/services/taxes-helper.service';

@Component({
  selector: 'app-package-selector-v2',
  templateUrl: './package-selector-v2.component.html',
  styleUrls: ['./package-selector-v2.component.scss']
})
export class PackageSelectorV2Component implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() eventBus: EventBusService;
  @Input() showPlaceholder: boolean;
  @Input() selectedPackage: Package;
  @Input() disabled = false;

  @Output() packageSelectionChanged: EventEmitter<Package> = new EventEmitter();

  serviceGroups = [];
  taxDefs: Tax[] = [];
  public filteredPackages: ReplaySubject<Package[]> = new ReplaySubject<Package[]>(1);
  private destroy$ = new Subject();


  constructor(
    private dialog: MatDialog,
    public packagesHelperService: PackagesHelperService,
    public taxesHelperService: TaxesHelperService,
    public createAppointmentV2SyncService: CreateAppointmentV2SyncService,
  ) {

  }

  ngOnInit(): void {
    if (this.contextIdDto) {
      this.loadPackages();
      this.taxesHelperService.getTaxDefs(this.contextIdDto).then(res => {
        this.taxDefs = res;
      });
    }
  }

  loadPackages() {
    this.packagesHelperService.getPackages(this.contextIdDto).then(packages => {
      if (packages && packages.length) {
        this.filteredPackages.next(packages);
        if (!this.selectedPackage) {
          this.selectedPackage = packages && packages.length ? packages[0] : undefined;
          this.packageSelectionChanged.emit(this.selectedPackage);
        }
      }
    });
  }

  packageChanged(event) {
    if (event.value) {
      this.packageSelectionChanged.emit(event.value);
    }
  }

  filterPackages(value: string) {
    this.packagesHelperService.getPackages(this.contextIdDto).then(packages => {
      if (!packages) {
        return;
      }
      // get the search keyword
      if (!value) {
        this.filteredPackages.next(packages.slice());
        return;
      }
      const search = value.toLowerCase();
      // filter the consumerServices
      this.filteredPackages.next(packages.filter(serviceDto => serviceDto.packageName.toLowerCase().indexOf(search) > -1));
    });
  }

  createNewPackage() {
    const dialogRef = this.dialog.open(CreatePackageComponent, {
      panelClass: ['helpwindow'],
      data: {
        contextIdDto: this.contextIdDto,
        taxDefs: this.taxDefs
      }
    });

    dialogRef.afterClosed().subscribe((packageNew) => {
      if (packageNew) {
        console.log('The package is saved');
        this.selectedPackage = packageNew;
        this.loadPackages();
      }
    });
  }

  loadAndSetPackage(package1: Package, packages: Package[]) {
    console.log('loading new services');
    if (packages && packages.length) {
      this.filteredPackages.next(packages);
      const newService = packages.find(p => p.packageName === package1.packageName);
      setTimeout(() => {
        this.selectedPackage = newService;
        this.packageSelectionChanged.emit(package1);
      }, 500);
    }
  }

  compareObjects(o1: ConsumerService, o2: ConsumerService): boolean {
    if (o1 && o2) {
      return o1.id === o2.id;
    }
    return false;
  }

}
