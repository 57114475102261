import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';
import { ContextIdDto } from '@savvy/ui';
import { ContextService } from '../../context.service';

import { DiscountSettingsService, DiscountItem, DiscountSettings } from '@savvy/invoice';
import { ClearObservable } from '../../shared/classes/clear-observable';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-discount-settings',
  templateUrl: './discount-settings.component.html',
  styleUrls: ['./discount-settings.component.scss']
})
export class DiscountSettingsComponent extends ClearObservable implements OnInit {

  contextIdDto: ContextIdDto;
  discountSettings: DiscountSettings;

  constructor(public notify: FloSnackbarComponent,
    private contextService: ContextService,
    private discountSettingsService: DiscountSettingsService,
  ) {
    super();
  }

  ngOnInit() {
    this.contextService.contextIdDto$
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        if (!data || (this.contextIdDto
          && data.contextId === this.contextIdDto.contextId
          && data.contextIdType === this.contextIdDto.contextIdType)) {
          return;
        }
        this.contextIdDto = data;
        this.loadDiscountSettings();
      });
  }

  loadDiscountSettings() {
    this.discountSettings = null;
    this.discountSettingsService
      .get(this.contextIdDto.contextId, this.contextIdDto.contextIdType)
      .subscribe(res => {
        this.discountSettings = res;
      });
  }

  doSave(discountItems: DiscountItem[]) {
    this.discountSettings.discountItems = discountItems;
    this.saveDiscountSettings();
  }

  saveDiscountSettings() {
    this.discountSettingsService
      .update(this.contextIdDto.contextId, this.contextIdDto.contextIdType, this.discountSettings)
      .subscribe(res => {
        this.notify.message = 'Discount Setting Saved Successfully!';
        this.notify.open();
        this.loadDiscountSettings();
      });
  }

}
