<mat-toolbar>
  <mat-toolbar-row>{{'PET_NOTES' | translate}}</mat-toolbar-row>
</mat-toolbar>
<div class="mdc-p-3">

  <mat-form-field appearance="outline" style="width: 100%" *ngIf="pet">
    <mat-label>{{'NOTES' | translate}}</mat-label>
    <textarea matInput id="bodyField" name="bodyField" #bodyField="ngModel" [(ngModel)]="pet.petNotes" placeholder="Enter Message">
          </textarea>
    <mat-error *ngIf="bodyField.hasError('required')">
      {{'MESSAGE_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
    </mat-error>
  </mat-form-field>
  <br>
  <button mat-raised-button color="accent" (click)="save()">{{'UPDATE' | translate}}</button>

</div>