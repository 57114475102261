
import {Component, EventEmitter, Output} from '@angular/core';

@Component({
    selector: 'app-zero-messages',
    templateUrl: './zeroMessages.component.html'
})
export class ZeroMessagesComponent {
    @Output() newMessageClicked = new EventEmitter();
    public create() {
        this.newMessageClicked.emit('newMessage');
    }
}

