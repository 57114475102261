<mat-toolbar color="primary" *ngIf="!model.id">{{'CREATE_DATA_LIST' | translate}}</mat-toolbar>
<mat-toolbar color="primary" *ngIf="model.id">{{'EDIT_DATA_LIST' | translate}} / {{model.name}}</mat-toolbar>

<div class="container">
  <mat-card appearance="outlined">
    <mat-card-content>
      <div fxLayout="row" fxLayoutAlign="start">
        <div fxFlex="100" class="mt-xs">
          <div *ngIf="model.id" cdkDropList class="data-item-list" (cdkDropListDropped)="drop($event)">
            <h2 mat-header>{{'LIST_ITEMS' | translate}}</h2>
            <div fxLayout="row" fxLayoutGap="10px" class="data-item-box" *ngFor="let item of model.dataItems" cdkDrag>
              <div fxFlex="nogrow" class="example-handle" cdkDragHandle title="Move from here to re-order">
                <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                  <path
                    d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                  </path>
                  <path d="M0 0h24v24H0z" fill="none"></path>
                </svg>
              </div>
              <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
              <div fxFlex="auto" style="padding-top: 3px">{{item.label}}</div>
              <button fxFlex="nogrow" mat-icon-button (click)="deleteItem(item)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
    <br>
    <br>
    <mat-card-content>
      <form #newDatalistForm="ngForm" style="width: 100%">
        <div fxLayout="row" fxLayoutAlign="start">

          <mat-form-field *ngIf="model.id" style="width: 100%;" appearance="outline">
            <mat-label>{{'NEW_ITEM_NAME' | translate}}</mat-label>
            <input matInput class="demo-full-width" [(ngModel)]="newItemName" name="newItem" #newItem="ngModel"
              placeholder="{{'NEW_ITEM_NAME' | translate}}" required>

            <mat-error *ngIf="newItem.hasError('required')">
              {{'NEW_ITEM_NAME_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start">
          <button mat-raised-button color="accent" (click)="addItem()" [disabled]="!newDatalistForm.form.valid"
            disabled="disabled">{{'ADD_ITEM' | translate}}</button>
        </div>

        <!--
      [disabled]="!datalistForm.form.valid"
      -->
        <button *ngIf="!model.id" mat-raised-button color="accent" (click)="addList()" disabled="disabled">{{'ADD' | translate}}
        </button>
      </form>
    </mat-card-content>
  </mat-card>
</div>
