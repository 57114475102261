<mat-card appearance="outlined" style="height: 20vh">
    <mat-card-title>{{newsDto.title}}</mat-card-title>
    <mat-card-subtitle>{{newsDto.subTitle}}</mat-card-subtitle>
    <hr>

    <mat-list>

        <!--
        h3?: string;
    id?: string;
    img?: string;
    paragraph?: string;
    version?: number;
    -->
        <ng-template let-item let-last="last" ngFor [ngForOf]="newsDto.newsItemDtos">
            <mat-list-item (click)="go()">
              <ngx-avatars mat-list-avatar
                          name="{{item.img}}"
                          size="50" [style.border-radius]="'50%'"></ngx-avatars>

              <!--
                <img mat-list-avatar src="assets/images/avatar.jpg" alt="">
                -->
                <h3 matLine> {{item.h3}} </h3>
                <p matLine> {{item.paragraph}}</p>
            </mat-list-item>
        </ng-template>
    </mat-list>
</mat-card>
