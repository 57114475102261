import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  WorkflowdefService,
  WorkflowTaskDefinition,
  WorkflowState,
  ListWorkflowDefinitionsForEditDto
} from '@savvy/workflow-definition';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { AddEditWorkflowTaskDefinitionComponent } from './addEditWorkflowTaskDefinition.component';
import { TranslateService } from '@ngx-translate/core';
import { ActionDefinition, SendMessageDefinition, TaskSettings } from '@savvy/workflow-definition';
import { ContextIdDto, RoleId } from '@savvy/view-definition';

@Component({
  selector: 'app-add-edit-workflow-state',
  templateUrl: 'addEditWorkflowState.html'
})
export class AddEditWorkflowStateComponent implements OnInit {

  @Input() contextIdDto: ContextIdDto;

  workflowState: WorkflowState;
  workflowDefinitionId: string;
  stateId: string;
  selectedTaskDef: WorkflowTaskDefinition;
  response: ListWorkflowDefinitionsForEditDto;

  @ViewChild('sidePanel', { static: true }) sidePanel: MatSidenav;
  @ViewChild('addEditWorkflowTaskDef', { static: false }) addEditWorkflowTaskDef: AddEditWorkflowTaskDefinitionComponent;


  columns = [
    {
      name: 'Task Name',
      prop: 'taskSettings.taskName'
    }
  ];
  selected: any[] = [];

  constructor(
    private api: WorkflowdefService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private notify: FloSnackbarComponent) {
  }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.contextIdDto = <ContextIdDto>{};
      if (params['contextId'] && params['contextIdType']) {
        this.contextIdDto.contextId = params['contextId'];
        this.contextIdDto.contextIdType = params['contextIdType'];
        console.log('Set context id dto to ' + this.contextIdDto.contextId);
        this.workflowDefinitionId = params['workflowDefinitionId'];
        this.stateId = params['stateId'];

        this.api.getAllWorkflowDefsForEdit(
          this.contextIdDto.contextId,
          this.contextIdDto.contextIdType).subscribe(response => {
            this.response = response;
          });
        if (this.workflowDefinitionId && this.stateId) {
          console.log('Got workflowDefinitionId ' + this.workflowDefinitionId);
          this.loadWorkflowState();
        } else {
          this.workflowState = <WorkflowState>{};
          this.workflowState.workflowTaskDefinitionList = new Array();
        }
      }
    });
  }

  createTask() {
    this.selectedTaskDef = <WorkflowTaskDefinition>{};
    this.initialise(this.selectedTaskDef);
    this.sidePanel.open();
  }

  taskRemoved() {
    this.sidePanel.close();
    this.loadWorkflowState();
  }
  loadWorkflowState() {
    this.api.getWorkflowDef(this.contextIdDto.contextId, this.contextIdDto.contextIdType,
      this.workflowDefinitionId).subscribe(workflowDef => {
        workflowDef.workflowStateList.forEach(state => {
          if (state.id === this.stateId) {
            this.workflowState = state;
            if (!this.workflowState.workflowTaskDefinitionList) {
              this.workflowState.workflowTaskDefinitionList = new Array();
            }
          }
        });
      });
  }

  updateState() {
    const req = <any>{};
    req.contextIdDto = this.contextIdDto;
    console.log('updating state with id ' + this.workflowDefinitionId);
    req.workflowDefinitionId = this.workflowDefinitionId;
    req.stateId = this.workflowState.id;
    req.stateName = this.workflowState.state;
    this.api.updateStateName(req).subscribe(
      response => {
        this.translateService.get('Updated State').subscribe(value => {
          this.notify.message = value;
          this.notify.open();
        });
      });
  }

  createState() {

    console.log('calling add state to workflow');
    const req = <any>{};
    req.contextIdDto = this.contextIdDto;
    console.log('creating state with id ' + this.workflowDefinitionId);
    req.workflowDefinitionId = this.workflowDefinitionId;
    req.newState = this.workflowState.state;
    this.api.addStateToWorkflow(req).subscribe(
      response => {
        console.log('called add state to workflow');
        this.workflowState = response.newWorkflowState;
        this.translateService.get('Created State').subscribe(value => {
          this.notify.message = value;
          this.notify.open();
        });
        this.router.navigate(['/workflow/addEditWorkflowState',
          this.workflowDefinitionId,
          this.workflowState.id,
          this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
        ]);
      });
  }

  deleteState() {
    const req = <any>{};
    req.contextIdDto = this.contextIdDto;
    req.workflowDefinitionId = this.workflowDefinitionId;
    req.stateId = this.workflowState.id;
    this.api.removeStateFromWorkflow(req).subscribe(response => {
      this.translateService.get('Removed state "' + this.workflowState.state + '" from workflow').subscribe(value => {
        this.notify.message = value;
        this.notify.open();
      });
      // Go back to workflow
      this.router.navigate(['/workflow/workflows',
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType]);
    });
  }

  onSelect(event) {
    console.log('Event: select', event, this.selected[0]);
    this.initialise(this.selected[0]);
    this.selectedTaskDef = this.selected[0];
    this.sidePanel.open();
  }

  initialise(taskDef: WorkflowTaskDefinition) {
    if (!taskDef.taskSettings) {
      taskDef.taskSettings = <TaskSettings>{};
      taskDef.taskSettings.taskAssignToRoles = <RoleId>{};
    }
    if (!taskDef.postTaskDefinition) {
      console.log('initialising post task def');
      taskDef.postTaskDefinition = <any>{};
    }
    if (!taskDef.autoTaskDefinition) {
      taskDef.autoTaskDefinition = <any>{};
    }
    if (!taskDef.manualTaskDefinition) {
      taskDef.manualTaskDefinition = <any>{};
    }
    if (!taskDef.actionDefinition) {
      taskDef.actionDefinition = <ActionDefinition>{};
      taskDef.actionDefinition.actionType = ActionDefinition.ActionTypeEnum.SendMessage;
      taskDef.actionDefinition.sendMessageDefinition = <SendMessageDefinition>{};
    }
  }
}
