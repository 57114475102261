import { Injectable } from '@angular/core';
import { ContextIdDto } from '@savvy/app';
import { Product, ProductService } from '@savvy/products';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ProductsHelperService {

  public products: Array<Product> = [];
  public archivedProducts: Array<Product> = [];

  constructor(
    private productService: ProductService
  ) { }

  public loadProducts(contextIdDto: ContextIdDto, getDeleted = false): Promise<Array<Product>> {
    this.products = [];
    return new Promise(resolve => {
      if (!contextIdDto?.contextId) {
        resolve([]);
        return;
      }
      if (getDeleted) {
        this.productService.listArchivedProducts(
          contextIdDto.contextId
        ).subscribe(response => {
          console.log('loaded archived products ', response);
          this.archivedProducts = response;
          resolve(this.archivedProducts);
        });
      } else {
        this.productService.listNonDeletedProducts(
          contextIdDto.contextId
        ).subscribe(response => {
          console.log('loaded products ', response);
          this.products = response;
          resolve(this.products);
        });
      }
    });
  }

  public listActiveProducts(contextIdDto: ContextIdDto, fresh = false): Promise<Array<Product>> {
    return new Promise(resolve => {
      if (this.products.length && !fresh) {
        resolve(this.products);
      } else {
        this.products = [];
        this.loadProducts(contextIdDto).then((response: Array<Product>) => {
          if (response && response.length) {
            this.products = response;
          }
          resolve(this.products);
        });
      }
    });
  }

  public listArchivedProducts(contextIdDto: ContextIdDto, fresh = false): Promise<Array<Product>> {
    return new Promise(resolve => {
      if (this.archivedProducts.length && !fresh) {
        resolve(this.archivedProducts);
      } else {
        this.archivedProducts = [];
        this.loadProducts(contextIdDto, true).then((response: Array<Product>) => {
          if (response && response.length) {
            this.archivedProducts = response;
          }
          resolve(this.archivedProducts);
        });
      }
    });
  }

  public getProductsGroupedByCategory(contextIdDto: ContextIdDto): Promise<{ [key: string]: Product[] }> {
    return new Promise(resolve => {
      this.listActiveProducts(contextIdDto).then((latestProducts: Array<Product>) => {
        const groupedData = _.groupBy(latestProducts, 'category');
        console.log("Got grouped products", groupedData)
        resolve(groupedData);
      });
    });
  }

}
