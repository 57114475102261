<div class="mdc-px-3 ">
  <div fxLayout="row wrap" fxLayoutGap="5px" fxLayout.gt-sm="row wrap">

    <div fxFlex="100">
      <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutGap="1%">
        <div fxFlex="49">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{'SELECT_DATE_RANGE' | translate}}</mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
              <input matStartDate formControlName="start" placeholder="{{'START_DATE' | translate}}">
              <input matEndDate formControlName="end" placeholder="{{'END_DATE' | translate}}">
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>

            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">{{'INVALID_START_DATE' | translate}}</mat-error>
            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">{{'INVALID_END_DATE' | translate}}</mat-error>
          </mat-form-field>

          <!-- <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'Start Date' | translate}} </mat-label>
            <input matInput #datePickerInput [(ngModel)]="startDate" id="appointment-start-date"
              [matDatepicker]="startDatePicker" required (dateChange)="startDateChanged($event)"
              (focus)="startDatePicker.open()" placeholder="Drop Off Date" [matDatepickerFilter]="weekendsDatesFilter">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>

            <mat-datepicker #startDatePicker (closed)="closedStream($event)" [restoreFocus]="false"></mat-datepicker>
          </mat-form-field> -->
        </div>
        <!-- <div fxFlex="22" *ngIf="!createBoardingBookingV2SyncService?.editMode">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'How long you like to book?' | translate}}</mat-label>
            <mat-select placeholder="How long you like to book?" (selectionChange)="dayCareFreqSelected($event.value)"
              [(ngModel)]="dayCareFreq" required [disabled]="disableTimePickup">
              <mat-option [value]="'once'">One Time</mat-option>
              <mat-option [value]="'weekly'">One Weekly</mat-option>
               <mat-option [value]="'twoweek'">two Weekly</mat-option>
              <mat-option [value]="'threeweek'">three Weekly</mat-option>
              <mat-option [value]="'onemonth'">One Month</mat-option>
              <mat-option [value]="'onemonth'">Select date range till</mat-option>
            </mat-select>
          </mat-form-field>

        </div> -->

        <!-- conditional for one day  remove class ** d-none **  -->
        <!-- <div fxFlex="nogrow" class="d-none">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'Drop-off' | translate}}</mat-label>
            <mat-select placeholder="Drop-off">
              <mat-option [value]="'09:00'">09:00</mat-option>
              <mat-option [value]="'09:30'">09:30</mat-option>
              <mat-option [value]="'10:00'">10:00</mat-option>
              <mat-option [value]="'10:30'">10:30</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxFlex="nogrow" class="d-none">

          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'Pick-up' | translate}}</mat-label>
            <mat-select placeholder="Pick-up">
              <mat-option [value]="'09:00'">10:30</mat-option>
              <mat-option [value]="'09:30'">11:00</mat-option>
              <mat-option [value]="'10:00'">11:30</mat-option>
              <mat-option [value]="'10:30'">12:00</mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->


        <!-- conditional for one day end -->


        <!-- <div fxFlex="25">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'End Date' | translate}} </mat-label>
            <input matInput #datePickerInput [(ngModel)]="endDate" id="appointment-end-date"
              [matDatepicker]="endDatePicker" required (dateChange)="endDateChanged($event)"
              (focus)="endDatePicker.open()" placeholder="Pickup Date" [matDatepickerFilter]="weekendsDatesFilter">
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker (closed)="closedStream($event)" [restoreFocus]="false"></mat-datepicker>
          </mat-form-field>
        </div> -->

        <div fxFlex="50">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{'EXCLUDE_DAYS' | translate}}
            </mat-label>
            <mat-select [(value)]="excludeDays" (selectionChange)="excludeDaysChange()" [multiple]="true">
              <mat-option *ngFor="let day of days" [value]="day">
                {{day}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>
<nav mat-tab-nav-bar class="dates-selector mdc-mb-1" *ngIf="dates?.length">
  <ng-container *ngFor="let date of dates; let i = index; trackBy:identify">
    <a mat-tab-link (click)="dateSelected(date, i)" [ngClass]="{selected: date.selected}" class="cp" [disabled]="date.disabled">

      <mat-checkbox [(ngModel)]="date.selected" (ngModelChange)="setPetStayTimeRange()" class="mdc-mb-2">
        <h5 class="mdc-mb-0">{{date.dayNumber}} {{date.dayName}}</h5>
      </mat-checkbox>

      <mat-form-field *ngIf="!petStayPreference?.bookHourly" appearance="outline" class="w-100 pb-none">
        <mat-label>{{'SELECT_SLOT' | translate}} </mat-label>
        <mat-select placeholder="{{'PLEASE_SELECT_SLOT_BOOKING' | translate}}" [(ngModel)]="date.slotSelected" (ngModelChange)="slotUpdated(date, $event, i)">
          <mat-option [value]="'FIRST_HALF'">{{'MORNING' | translate}}</mat-option>
          <mat-option [value]="'SECOND_HALF'">{{'AFTERNOON' | translate}}</mat-option>
          <mat-option [value]="'FULL_DAY'">{{'FULL_DAY' | translate}}</mat-option>
          <!-- <mat-option [value]="'CUSTOM'">Custom</mat-option> -->
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="(petStayPreference?.bookHourly || customRange[i]) && timeArray && timeArray.length > 0" appearance="outline" class="w-100 pb-none">
        <mat-label>{{'DROP_OFF' | translate}}</mat-label>
        <mat-select placeholder="{{'SELECT_DROP_OF_TIME' | translate}}" (selectionChange)="startTimeChanged($event.value, i)" [(ngModel)]="date.startTime" [compareWith]="compareTimeFn" required [disabled]="disableTimePickup">
          <mat-option *ngFor="let time of timeArray" [value]="time.actualValue">
            {{time.displayValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="(petStayPreference?.bookHourly || customRange[i]) && timeArray && timeArray.length > 0" appearance="outline" class="w-100 pb-none" matTooltipPosition="above">
        <mat-label>{{'PICKUP' | translate}}</mat-label>
        <mat-select placeholder="{{'PICKUP_TIME' | translate}}" (selectionChange)="endTimeChanged($event.value, i)" [(ngModel)]="date.endTime" [compareWith]="compareTimeFn" required>
          <mat-option *ngFor="let time of timeArray" [value]="time.actualValue">{{time.displayValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>


      <p class="mb-0" *ngIf="!petStayPreference?.bookHourly && !customRange[i]">
        <mat-hint class="mdc-px-2 mdc-py-2" *ngIf="date.slotSelected === 'FIRST_HALF'">
          {{petStayPreference?.firstHalfStartTime + '-' + petStayPreference?.firstHalfEndTime}}
          <mat-icon class="vm" (click)="customRange[i] = true">edit</mat-icon>
        </mat-hint>
        <mat-hint class="mdc-px-2 mdc-py-2" *ngIf="date.slotSelected === 'SECOND_HALF'">
          {{petStayPreference?.secondHalfStartTime + '-' + petStayPreference?.secondHalfEndTime}}
          <mat-icon class="vm" (click)="customRange[i] = true">edit</mat-icon>
        </mat-hint>
        <mat-hint class="mdc-px-2 mdc-py-2" *ngIf="date.slotSelected === 'FULL_DAY'">
          {{getFullDayTimings(date?.date)}}
          <mat-icon class="vm" (click)="customRange[i] = true">edit</mat-icon>
        </mat-hint>
        <!-- <button mat-icon-button (click)="customRange[i] = true"> -->
        <!-- </button> -->
      </p>

      <!-- <mat-form-field appearance="outline" class="w-100 pb-none">
        <mat-label>{{ 'Pick-up' | translate}}</mat-label>
        <mat-select placeholder="Pick-up">
          <mat-option [value]="'09:00'">10:30</mat-option>
          <mat-option [value]="'09:30'">11:00</mat-option>
          <mat-option [value]="'10:00'">11:30</mat-option>
          <mat-option [value]="'10:30'">12:00</mat-option>
        </mat-select>
      </mat-form-field> -->


    </a>
  </ng-container>
</nav>

<!-- <div class="mdc-px-3 " fxLayout="row" fxLayoutGap="8px" *ngIf="selectedDateRangeIndex > -1">
  <div fxFlex="50">
    <mat-form-field *ngIf="timeArray && timeArray.length > 0" appearance="outline" class="w-100">
      <mat-label>{{ 'Drop-off Time' | translate}}</mat-label>
      <mat-select placeholder="Select Start Time" (selectionChange)="startTimeChanged($event.value)"
        [(ngModel)]="selectedDateRange.startTime" [compareWith]="compareTimeFn" required [disabled]="disableTimePickup">
        <mat-option *ngFor="let time of timeArray" [value]="time.actualValue">{{time.displayValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div fxFlex="50">
    <mat-form-field *ngIf="timeArray && timeArray.length > 0" appearance="outline" class="w-100" placeholder="End Time"
      placeholder="Pickup Time" matTooltipPosition="above">
      <mat-label>{{ 'Pick-up Time' | translate}}</mat-label>
      <mat-select placeholder="Pickup Time" (selectionChange)="endTimeChanged($event.value)"
        [(ngModel)]="selectedDateRange.endTime" [compareWith]="compareTimeFn" required>
        <mat-option *ngFor="let time of timeArray" [value]="time.actualValue">{{time.displayValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div> -->