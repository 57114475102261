import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddCustomerComponent } from './customer/addCustomer.component';
import { ViewCustomerV2Component } from './view-customer-v2/view-customer-v2.component';
import { ContextIdDto } from '@savvy/customer';

@Injectable({
  providedIn: 'root'
})
export class CustomerHandlerService {

  constructor(
    private dialog: MatDialog
  ) { }

  public view(contextIdDto: ContextIdDto, customerId: string, callback?: (data?: any) => void) {
    const viewCustomerModal = this.dialog.open(ViewCustomerV2Component, {
      data: {
        contextIdDto,
        customerId
      },
      maxWidth: '1024px',
      // maxHeight: '80vh',
      // height: '80vh',
      panelClass: [
        'modal-100',
        'helpwindow',
        'petProfileModal'
      ],
      autoFocus: false
    });

    viewCustomerModal.afterClosed()
      .subscribe(
        () => {
          if (callback) {
            callback();
          }
        });
  }


  public edit(contextIdDto: ContextIdDto, customerId: string, callback?: (data?: any) => void) {
    const editCustomerModal = this.dialog.open(AddCustomerComponent, {
      data: {
        contextIdDto,
        customerId,
        isViewMode: true
      },
      maxWidth: '1024px',
      panelClass: [
        'modal-100',
        'helpwindow',
        'scrollable-modal-2'
      ],
      autoFocus: false,
      disableClose: true
    });

    editCustomerModal.afterClosed()
      .subscribe(
        () => {
          if (callback) {
            callback();
          }
        });
  }

}
