import { ViewPetAppointmentComponent } from '../appointment/pet/view-pet-appointment/view-pet-appointment.component';
import {
  ViewCustomerAppointmentComponent
} from '../appointment/non-pet/view-customer-appointment/view-customer-appointment.component';
import { Injectable } from '@angular/core';
import { APP_TYPE_ENUM, ContextService } from '../context.service';
import { ContextIdDto } from '@savvy/app';
import { MatDialog } from '@angular/material/dialog';
import { Appointment, AppointmentConfig, AppointmentPackageRow, AppointmentService, AppointmentServiceRow, ChangeState } from '@savvy/appointment';
import { CreatePetAppointmentV2Component } from './create-pet-appointment-v2/create-pet-appointment-v2.component';
import { CreateAppointmentV2Component } from './create-appointment-v2/create-appointment-v2.component';
import { CreatePetAppointmentV2SyncService } from './create-pet-appointment-v2/create-pet-appointment-v2-sync.service';
import { CreateAppointmentV2SyncService } from './create-appointment-v2/create-appointment-v2-sync.service';
import { DateUtils } from '../dates/DateUtils';
import { Pet } from '@savvy/pet';
import { Customer } from '@savvy/customer';
import { IdNameTupleDto, UserDto } from '@savvy/user';
import { QuotePackageRow, QuoteProductRow, QuoteServiceRow } from '@savvy/quote';
import { InvoiceId } from '@savvy/invoice';
import { ConfirmationModalComponent } from '../shared/components/confirmation-modal/confirmation-modal.component';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { CalendarSettings } from '@savvy/calendar';
import * as moment from 'moment';

export class CreateAppointmentParams {
  contextIdDto: ContextIdDto;
  petList?= [];
  populatePet?: Pet;
  startDateToUseOnCreate?: Date;
  populateCustomer?: Customer;
  userToUseWhenCreating?: IdNameTupleDto;
  appointmentType?: Appointment.AppointmentTypeEnum;
  calendarSettings?: CalendarSettings;
  callback?: (data?: any) => void;
}

export class EditAppointmentParams {
  contextIdDto: ContextIdDto;
  appointment: Appointment;
  petList?= [];
  populatePet?: Pet;
  startDateToUseOnCreate?: Date;
  populateCustomer?: Customer;
  userToUseWhenCreating?: IdNameTupleDto;
  appointmentType?: Appointment.AppointmentTypeEnum;
  calendarSettings?: CalendarSettings;
  callback?: (data?: any) => void;
}

export class RebookAppointmentParams {
  contextIdDto: ContextIdDto;
  appointment: Appointment;
  appointmentConfig: AppointmentConfig;
  petList?= [];
  populatePet?: Pet;
  startDateToUseOnCreate?: Date;
  populateCustomer?: Customer;
  userToUseWhenCreating?: IdNameTupleDto;
  appointmentType?: Appointment.AppointmentTypeEnum;
  calendarSettings?: CalendarSettings;
  callback?: (data?: any) => void;
}

export class ViewAppointmentParams {
  contextIdDto: ContextIdDto;
  appointmentId: string;
  appointmentConfig: AppointmentConfig;
  calendarSettings?: CalendarSettings;
  callback?: (data?: any) => void;
}


export class CreateAppointmentFromQuoteParams extends CreateAppointmentParams {
  selectedProductRows?: Array<QuoteProductRow>;
  selectedServiceRows?: Array<QuoteServiceRow>;
  selectedPackageRows?: Array<QuotePackageRow>;
  invoiceId?: InvoiceId;
  quoteId?: string;
}
@Injectable({
  providedIn: 'root'
})
export class AppointmentHandlerService {

  constructor(
    private contextService: ContextService,
    private dialog: MatDialog,
    private createPetAppointmentV2SyncService: CreatePetAppointmentV2SyncService,
    private createAppointmentV2SyncService: CreateAppointmentV2SyncService,
    private dateUtils: DateUtils,
    private appointmentService: AppointmentService,
    private notify: FloSnackbarComponent
  ) {

  }

  public getAppointmentType(appointmentType: Appointment.AppointmentTypeEnum) {
    if (!appointmentType) {
      switch (this.contextService.appType) {
        case 'PET_GROOMING_SALON':
        case 'GROOM_SCHOOL':
          appointmentType = Appointment.AppointmentTypeEnum.PetSpa;
          break;
        case 'PHOTO_STUDIO':
          appointmentType = Appointment.AppointmentTypeEnum.Shoot;
          break;
        default:
          appointmentType = Appointment.AppointmentTypeEnum.PetSpa;
          break;
      }
    }

    return appointmentType;
  }

  getAppointmentPackageRows(selectedPackageRows: Array<QuotePackageRow>): Array<AppointmentPackageRow> {
    const appointmentPackageRows: Array<AppointmentPackageRow> = [];
    for (const selectedPackageRow of selectedPackageRows) {
      appointmentPackageRows.push(
        {
          packageUser: selectedPackageRow.serviceUser,
          packageUserFullName: '',
          startDate: selectedPackageRow.startDate,
          endDate: selectedPackageRow.endDate,
          startTime: selectedPackageRow.startTime,
          endTime: selectedPackageRow.endTime,
          packageId: selectedPackageRow.packageId,
          packageName: selectedPackageRow.packageName,
          quantity: 1,
          packagePrice: selectedPackageRow.unitPrice,
          tax: selectedPackageRow.tax,
          subTotal: selectedPackageRow.subTotal,
          grossPrice: selectedPackageRow.grossPrice,
          // grossPriceNoOffer?: selectedPackageRow.grossPrice,
          discountDetails: selectedPackageRow.discount,
          // discountId?: string,
          // duration?: selectedPackageRow.,
          // numMinutesRequired?: number,
          // startTimeDisplay?: string,
        }
      );
    }
    return appointmentPackageRows;
  }

  getAppointmentServiceRows(selectedServiceRows: Array<QuoteServiceRow>): Array<AppointmentServiceRow> {
    const appointmentServiceRows: Array<AppointmentServiceRow> = [];
    for (const selectedServiceRow of selectedServiceRows) {
      appointmentServiceRows.push(
        {
          serviceUser: selectedServiceRow.serviceUser,
          serviceUserFullName: '',
          startDate: selectedServiceRow.startDate,
          endDate: selectedServiceRow.endDate,
          startTime: selectedServiceRow.startTime,
          endTime: selectedServiceRow.endTime,
          serviceNumber: selectedServiceRow.serviceNumber,
          serviceName: '',
          quantity: 1,
          unitPrice: selectedServiceRow.unitPrice,
          tax: selectedServiceRow.tax,
          subTotal: selectedServiceRow.subTotal,
          grossPrice: selectedServiceRow.grossPrice,
          // grossPriceNoOffer?: selectedPackageRow.grossPrice,
          discountDetails: selectedServiceRow.discount,
          // discountId?: string,
          // duration?: selectedServiceRow.,
          // numMinutesRequired?: number,
          // startTimeDisplay?: string,
        }
      );
    }
    return appointmentServiceRows;
  }

  // getAppointmentProductRows(selectedProductRows: Array<QuoteProductRow>): Array<AppointmentProductRow> {
  //   const appointmentPackageRows: Array<AppointmentPackageRow> = [];
  //   for (const selectedPackageRow of selectedProductRows) {
  //     appointmentPackageRows.push(
  //       {
  //         packageUser: selectedPackageRow.serviceUser,
  //         packageUserFullName: '',
  //         startDate: selectedPackageRow.startDate,
  //         endDate: selectedPackageRow.endDate,
  //         startTime: selectedPackageRow.startTime,
  //         endTime: selectedPackageRow.endTime,
  //         packageId: selectedPackageRow.packageId,
  //         packageName: selectedPackageRow.packageName,
  //         quantity: 1,
  //         packagePrice: selectedPackageRow.unitPrice,
  //         tax: selectedPackageRow.tax,
  //         subTotal: selectedPackageRow.subTotal,
  //         grossPrice: selectedPackageRow.grossPrice,
  //         // grossPriceNoOffer?: selectedPackageRow.grossPrice,
  //         discountDetails: selectedPackageRow.discount,
  //         // discountId?: string,
  //         // duration?: selectedPackageRow.,
  //         // numMinutesRequired?: number,
  //         // startTimeDisplay?: string,
  //       }
  //     );
  //   }
  //   return appointmentPackageRows;
  // }

  public createAppointmentFromQuote(args: CreateAppointmentFromQuoteParams) {
    const appointmentType = this.getAppointmentType(args.appointmentType);
    const dialogRef = this.dialog.open(CreateAppointmentV2Component, {
      data: {
        edit: false,
        contextIdDto: args.contextIdDto,
        populateCustomer: args.populateCustomer,
        appointmentType,
        startDateToUseOnCreate: args.startDateToUseOnCreate,
        userToUseWhenCreating: args.userToUseWhenCreating,
        packageRows: this.getAppointmentPackageRows(args.selectedPackageRows),
        serviceRows: this.getAppointmentServiceRows(args.selectedServiceRows),
        productRows: args.selectedProductRows,
        invoiceId: args.invoiceId,
        quoteId: args.quoteId
      },
      panelClass: 'quickcreate'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.createAppointmentV2SyncService.clear();
      args.callback(result);
    });
  }

  // eslint-disable-next-line max-len
  public createAppointment(args: CreateAppointmentParams) {
    const appointmentType = this.getAppointmentType(args.appointmentType);

    if (this.contextService.appType === APP_TYPE_ENUM.PET_GROOMING_SALON
      || this.contextService.appType === APP_TYPE_ENUM.GROOM_SCHOOL
      || this.contextService.appType === APP_TYPE_ENUM.VETS) {
      const dialogRef = this.dialog.open(CreatePetAppointmentV2Component, {
        data: {
          edit: false,
          contextIdDto: args.contextIdDto,
          petList: args.petList,
          populatePet: args.populatePet,
          startDateToUseOnCreate: args.startDateToUseOnCreate,
          userToUseWhenCreating: args.userToUseWhenCreating,
          calendarSettings: args.calendarSettings,
          appointmentType
        },
        panelClass: 'quickcreate',
        autoFocus: false
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        this.createPetAppointmentV2SyncService.clear();
        args.callback(result);
      });
    } else {
      const dialogRef = this.dialog.open(CreateAppointmentV2Component, {
        data: {
          edit: false,
          contextIdDto: args.contextIdDto,
          populateCustomer: args.populateCustomer,
          appointmentType,
          startDateToUseOnCreate: args.startDateToUseOnCreate,
          userToUseWhenCreating: args.userToUseWhenCreating
        },
        panelClass: 'quickcreate',
        autoFocus: false
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        this.createAppointmentV2SyncService.clear();
        args.callback(result);
      });
    }
  }

  public editAppointment(args: EditAppointmentParams, fromView = false) {
    if (this.contextService.appType === APP_TYPE_ENUM.PET_GROOMING_SALON
      || this.contextService.appType === APP_TYPE_ENUM.GROOM_SCHOOL
      || this.contextService.appType === APP_TYPE_ENUM.VETS) {
      const dialogRef = this.dialog.open(CreatePetAppointmentV2Component, {
        data: {
          edit: true,
          contextIdDto: args.contextIdDto,
          appointment: args.appointment,
          fromView,
          calendarSettings: args.calendarSettings
        },
        panelClass: 'quickcreate',
        autoFocus: false
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        this.createPetAppointmentV2SyncService.clear();
        args.callback(result);
      });
    } else {
      const dialogRef = this.dialog.open(CreateAppointmentV2Component, {
        data: {
          edit: true,
          contextIdDto: args.contextIdDto,
          appointment: args.appointment,
          fromView,
          calendarSettings: args.calendarSettings
        },
        panelClass: 'quickcreate'
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        this.createAppointmentV2SyncService.clear();
        args.callback(result);
      });
    }
  }

  public rebookAppointment(args: RebookAppointmentParams) {
    delete args.appointment.id;
    delete args.appointment.invoiceId;
    args.appointment.enquiryDate = this.dateUtils.getDateAsStringDash(new Date());
    if (args.appointmentConfig?.rebookAfterDays) {
      args.appointment.startDate = this.dateUtils.getDateAsStringDash(moment().add(args.appointmentConfig.rebookAfterDays, 'days'));
      args.appointment.startDateUtc = new Date(args.appointment.startDate).toISOString(); // ??
      args.appointment.endDate = this.dateUtils.getDateAsStringDash(moment().add(args.appointmentConfig.rebookAfterDays, 'days'));
      args.appointment.endDateUtc = new Date(args.appointment.endDate).toISOString();
    } else {
      args.appointment.startDate = this.dateUtils.getDateAsStringDash(new Date());
      args.appointment.startDateUtc = new Date(args.appointment.startDate).toISOString(); // ??
      args.appointment.endDate = this.dateUtils.getDateAsStringDash(new Date());
      args.appointment.endDateUtc = new Date(args.appointment.endDate).toISOString();
    }
    if (this.contextService.appType === APP_TYPE_ENUM.PET_GROOMING_SALON
      || this.contextService.appType === APP_TYPE_ENUM.GROOM_SCHOOL
      || this.contextService.appType === APP_TYPE_ENUM.VETS) {
      const dialogRef = this.dialog.open(CreatePetAppointmentV2Component, {
        data: {
          edit: false,
          rebook: true,
          contextIdDto: args.contextIdDto,
          appointment: args.appointment,
          calendarSettings: args.calendarSettings
        },
        panelClass: 'quickcreate',
        autoFocus: false
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        this.createPetAppointmentV2SyncService.clear();
        args.callback(result);
      });
    } else {
      const dialogRef = this.dialog.open(CreateAppointmentV2Component, {
        data: {
          edit: false,
          rebook: true,
          contextIdDto: args.contextIdDto,
          appointment: args.appointment,
          calendarSettings: args.calendarSettings
        },
        panelClass: 'quickcreate'
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        this.createAppointmentV2SyncService.clear();
        args.callback(result);
      });
    }
  }

  public viewAppointment(args: ViewAppointmentParams) {
    console.log('appointmentId', args.appointmentId);
    if (!args.appointmentId) {
      // alert('no appointmentId');
      return;
    }
    if (this.contextService.appType === APP_TYPE_ENUM.PET_GROOMING_SALON
      || this.contextService.appType === APP_TYPE_ENUM.GROOM_SCHOOL
      || this.contextService.appType === APP_TYPE_ENUM.VETS) {
      const dialogRef = this.dialog.open(ViewPetAppointmentComponent, {
        data: {
          contextIdDto: args.contextIdDto,
          appointmentId: args.appointmentId
        },
        autoFocus: false,
        panelClass: 'quickview',
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.rebook) {
            this.rebookAppointment({
              contextIdDto: args.contextIdDto,
              appointment: result.appointment,
              appointmentConfig: args.appointmentConfig,
              calendarSettings: args.calendarSettings,
              callback: args.callback
            });
          } else {
            this.editAppointment({
              contextIdDto: args.contextIdDto,
              appointment: result.appointment,
              calendarSettings: args.calendarSettings,
              callback: args.callback
            });
          }
        } else {
          args.callback(false);
        }
      });
    } else {
      const dialogRef = this.dialog.open(ViewCustomerAppointmentComponent, {
        data: {
          contextIdDto: args.contextIdDto,
          appointmentId: args.appointmentId
        },
        autoFocus: false,
        panelClass: 'quickview'
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.rebook) {
            this.rebookAppointment({
              contextIdDto: args.contextIdDto,
              appointment: result.appointment,
              appointmentConfig: args.appointmentConfig,
              calendarSettings: args.calendarSettings
            });
          } else {
            this.editAppointment({
              contextIdDto: args.contextIdDto,
              appointment: result.appointment,
              calendarSettings: args.calendarSettings
            });
          }
        } else {
          // Callback?
          args.callback(false);
        }
      });
    }
  }


  cancelAppointment(contextIdDto: ContextIdDto, appointment: Appointment, callback?: (data?: any) => void) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Cancel Appointment',
        message: 'Are you sure you want to cancel this appointment?',
        isChange: true
      },
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        appointment.cancelled = true;
        const req = <ChangeState>{};
        req.newState = 'Cancelled';
        this.appointmentService.changeStateV2(appointment.id, contextIdDto.contextId,
          contextIdDto.contextIdType, req)
          .subscribe(response => {
            if (response) {
              this.notify.message = 'Appointment cancelled';
              this.notify.open();
              if (callback) {
                callback(response);
              }
            }
          });
      }
    });

  }

  deleteAppointment(contextIdDto: ContextIdDto, appointmentId: string, callback?: (data?: any) => void) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete Appointment',
        message: 'Are you sure you want to delete this appointment?',
        // isChange: true
      },
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.appointmentService.deleteAppointment(appointmentId, contextIdDto.contextId, contextIdDto.contextIdType)
          .subscribe(response => {
            if (response) {
              this.notify.message = 'Appointment deleted';
              this.notify.open();
              if (callback) {
                callback(response);
              }
            }
          });
      }
    });

  }

}
