<div *ngIf="consumerServices">
  <mat-form-field appearance="outline" style="width: 100%">
    <mat-label>{{'Service' | translate}}</mat-label>
    <mat-select placeholder="{{'Select Service' | translate}}" [(ngModel)]="selectedService" required name="serviceField"
      #serviceField="ngModel" (selectionChange)="serviceChanged($event)" [compareWith]="compareObjects">
      <mat-option>
        <ngx-mat-select-search [placeholderLabel]="'Search Service'" ngModel (ngModelChange)="filterServices($event)">
        </ngx-mat-select-search>
      </mat-option>
      <mat-option [value]="''">
        <button mat-flat-button color="accent" (click)="createNewService()">&nbsp;&nbsp;Add
          Service</button>
      </mat-option>

      <mat-option *ngFor="let service of filteredServices | async" [value]="service">
        {{ service?.serviceName }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="serviceField.hasError('required')">
      {{'Service is' | translate}} <strong>{{'required' | translate}}</strong>
    </mat-error>
  </mat-form-field>
</div>
