import { Injectable } from '@angular/core';
import tinycolor from 'tinycolor2';

@Injectable({
  providedIn: 'root'
})
export class TextColorHelperService {
  constructor() { }

  getTextColor(background: string) {
    if (!background) {
      background = '#d1e8ff';
    }
    let color = '#fff';
    if (background && tinycolor.readability(color, background) < 5) {
      if (color === '#000') {
        color = '#FFF';
      } else {
        color = '#000';
      }
    }
    return color;
  }

}
