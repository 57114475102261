<div *ngIf="invoice">

  <app-invoice-stepper-panel
    *ngIf="invoice && createEiInline"
    [contextIdDto]="contextIdDto"
    [eventBus]="eventBus"
    [form]="form"
    [additionalDataMapDto]="additionalDataMapDto"
    [changeListener]="changeListener"
    [createEiInline]="createEiInline"
    [eiExtraFields]="eiExtraFields"
    [consumerServices]="consumerServices"
    [products]="products"
    [currencyCode]="currencyCode"
    [invoiceDefinition]="invoiceDefinition"
    [userDtos]="userDtos"
    [invoice]="invoice"
    [viewContext]="viewContext">

  </app-invoice-stepper-panel>

    <app-invoice-single-services-panel
      *ngIf="invoice && !allowMultipleServices && !createEiInline"
      [contextIdDto]="contextIdDto"
      [eventBus]="eventBus"
      [form]="form"
      [additionalDataMapDto]="additionalDataMapDto"
      [changeListener]="changeListener"
      [consumerServices]="consumerServices"
      [products]="products"
      [currencyCode]="currencyCode"
      [invoiceDefinition]="invoiceDefinition"
      [userDtos]="userDtos"
      [invoice]="invoice"
      [viewContext]="viewContext">

    </app-invoice-single-services-panel>



    <app-invoice-multi-services-panel
      *ngIf="invoice && allowMultipleServices && consumerServices"

      [contextIdDto]="contextIdDto"
      [eventBus]="eventBus"
      [form]="form"
      [additionalDataMapDto]="additionalDataMapDto"
      [changeListener]="changeListener"
      [invoicePanelDto]="invoicePanelDto"
      [invoice]="invoicePanelDto.invoice"
      [consumerServices]="consumerServices"
      [products]="products"
      [currencyCode]="currencyCode"
      [invoiceDefinition]="invoiceDefinition"
      [userDtos]="userDtos"
      [viewContext]="viewContext">

    </app-invoice-multi-services-panel>

</div>
