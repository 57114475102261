import { Injectable } from '@angular/core';
import { RoleDto, RolePermission, RolesService } from '@savvy/role';
import { ContextIdDto } from '@savvy/ui';
import { RoleAssignmentDto, UserDto, UsersService } from '@savvy/user';
import { ContextService } from '../../context.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsHelperService {
  userDto: UserDto;
  roles: Array<RoleDto> = [];
  roleAssignments: undefined[] | Set<RoleAssignmentDto> = [];
  adminRoleId = '';

  constructor(
    private rolesService: RolesService,
    private contextService: ContextService,
    private usersService: UsersService
  ) { }

  loadRoles(contextIdDto: ContextIdDto) {
    if (this.contextService.appType) {
      console.log('reloading roles');
      this.rolesService.listRolesForCrmUi(
        contextIdDto.contextId,
        contextIdDto.contextIdType,
        this.contextService.appType)
        .subscribe(response => {
          console.log('got ' + response.length + ' roles');
          this.roles = response || [];
          if (this.roles?.length) {
            this.adminRoleId = this.roles.find(role => role.authority === 'Admin').id;
          }
        });
    } else {
      console.error('error in loading roles');
    }
  }

  updateRoleAssignments() {
    this.usersService.getCurrentUser()
      .subscribe(response => {
        this.userDto = response;
        console.log('got current roles', response?.roleAssignments);
        this.roleAssignments = response?.roleAssignments || [];
      });
  }

  getCurrentUser() {
    return this.userDto;
  }

  public isAdmin(): boolean {
    const roleAssignments = this.roleAssignments as Array<RoleAssignmentDto>;
    return roleAssignments.filter(ra => ra.roleId === this.adminRoleId).length > 0;
  }

  public isPermissionAllowed(permissionType: RolePermission.RolePermissionTypeEnum,
    permission: 'canView' | 'canUpdate' | 'canDelete' | 'canCreate') {
    const roleAssignments = this.roleAssignments as Array<RoleAssignmentDto>;
    const roleIdsAssinged = roleAssignments.map(r => r.roleId);
    const currentRoles = this.roles.filter(r => roleIdsAssinged.indexOf(r.id) > -1);
    if (currentRoles.findIndex(cr => cr.id === this.adminRoleId) > -1) {
      return true;
    }
    let permissionAllowed = true;
    for (const currentRole of currentRoles) {
      const rolePermissionDtoList = currentRole.rolePermissionDtoList.filter(rp => rp.rolePermissionType === permissionType);
      switch (permissionType) {
        case 'USER_MGMT':
          permissionAllowed = rolePermissionDtoList.findIndex(p => p.roleUserMgmtPermission[permission]) > -1;
          if (!permissionAllowed) {
            return false;
          }
          break;
      }
    }
    return permissionAllowed;
  }

}
