<mat-toolbar color="primary" class="mdc-mb-2">
  <mat-toolbar-row>
    <span *ngIf="!data.edit">
      {{'CREATE_PRODUCT' | translate}}
    </span>
    <span *ngIf="data.edit">
      {{'EDIT_PRODUCT' | translate}}
    </span>
    <span class="spacer"></span>
    <button mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<form (ngSubmit)="add()" #createForm="ngForm" class="mdc-px-3 mdc-mb-2">
  <div fxLayout="column" class="mdc-mb-2">
    <mat-form-field fxFlex="100" appearance="outline">
      <mat-label>{{'PRODUCT_NAME' | translate}}</mat-label>
      <input matInput placeholder="{{'PRODUCT_NAME' | translate}}" name="productName" [(ngModel)]="product.productName" [ngModelOptions]="{standalone: true}" #productName required>
    </mat-form-field>
    <mat-form-field fxFlex="100" appearance="outline">
      <mat-label>{{'DESCRIPTION' | translate}}</mat-label>
      <input matInput placeholder="{{'DESCRIPTION' | translate}}" name="{{'DESCRIPTION' | translate}}" [(ngModel)]="product.description" [ngModelOptions]="{standalone: true}" #description>
    </mat-form-field>
    <mat-form-field fxFlex="100" appearance="outline">
      <mat-label>{{'CATEGORY' | translate}}</mat-label>
      <mat-select placeholder="{{'CATEGORY' | translate}}" [(ngModel)]="product.category" [ngModelOptions]="{standalone: true}" name="category" #category required (selectionChange)="updateCategory($event, 'category')">
        <mat-option *ngFor="let category of categories" [value]="category.id">{{category.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field fxFlex="100" appearance="outline">
      <mat-label>{{'UNIT_PRICE' | translate}}</mat-label>
      <input matInput type="text" appNumberOnly allowNegetiveIntegers="true" [decimalPoints]="2" placeholder="{{'UNIT_PRICE' | translate}}" name="unitPrice" #unitPrice [(ngModel)]="product.unitPrice" [ngModelOptions]="{standalone: true}" required>
    </mat-form-field>

    <mat-form-field fxFlex="100" appearance="outline">
      <mat-label>{{'STOCK_AVAILABLE' | translate}}</mat-label>
      <input matInput type="text" appNumberOnly placeholder="{{'STOCK_AVAILABLE' | translate}}" name="stockAvailable" #stockAvailable [(ngModel)]="product.stockAvailable" [ngModelOptions]="{standalone: true}" required>
    </mat-form-field>

    <mat-form-field fxFlex="100" appearance="outline">
      <mat-label>{{'TAX' | translate}}</mat-label>
      <mat-select placeholder="{{'TAX' | translate}}" [(ngModel)]="product.taxId" [ngModelOptions]="{standalone: true}" name="taxId" #taxId (selectionChange)="updateTax($event, 'taxId')">
        <mat-option value="" selected>{{'NO_TAX' | translate}}</mat-option>
        <mat-option *ngFor="let tax of taxDefs" [value]="tax.id">
          {{ tax.taxName }} ( {{ tax.taxRate }} %)
        </mat-option>
      </mat-select>

      <!-- <input matInput type="text" appNumberOnly [decimalPoints]="2" placeholder="Tax" name="tax" #tax="ngModel"
            [(ngModel)]="service.tax" [ngModelOptions]="{standalone: true}" required> -->
    </mat-form-field>

    <app-image-grid fxFlex="100" (imagesUpdated)="setImageIds($event)" (imageDeleted)="imageDeleted($event)" [contextIdDto]="data.contextIdDto" [publicBucket]="true" [images]="product.images"></app-image-grid>

  </div>
  <button mat-raised-button [disabled]="!createForm.form.valid || !product.category || !product.unitPrice || !product.productName" type="submit" class="mat-accent">
    <span *ngIf="!data.edit">{{'ADD_PRODUCT' | translate}}</span>
    <span *ngIf="data.edit">{{'UPDATE_PRODUCT' | translate}}</span>

  </button>
</form>