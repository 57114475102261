import { Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { TasksComponent } from './tasks.component';
import { AddEditTaskPageComponent } from './addEditTaskPage.component';

export const taskRoutes: Routes = [
  {
    path: '',
    children: [
      { path: 'addEditTask/:taskId/:contextId/:contextIdType', component: AddEditTaskPageComponent },
      { path: 'addTask/:contextId/:contextIdType', component: AddEditTaskPageComponent },
      { path: 'tasks/:contextId/:contextIdType', component: TasksComponent, canActivate: [AuthGuard] },
      { path: 'taskBoard/:contextId/:contextIdType', component: TasksComponent, canActivate: [AuthGuard] }
    ]
  }
];
