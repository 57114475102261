<!-- We only show this on create -->
<div style="padding-bottom: 1.34375em; margin: .25em 0;" *ngIf="!viewContext.eiCreated">
  <div style="padding: 1em 0.75em; box-sizing: border-box;">
    <mat-slide-toggle
      color="accent"
      (change)="onChange(null, null, elementInstanceDto.fieldInstanceDto.value.fieldRepeatableValue.enableRecurring);
      openRecurrningPopup(elementInstanceDto.fieldInstanceDto.value.fieldRepeatableValue.enableRecurring);"
      [(ngModel)]="elementInstanceDto.fieldInstanceDto.value.fieldRepeatableValue.enableRecurring"
      name="enableRecurring">
      {{'Enable Recurring' | translate}}
    </mat-slide-toggle>
  </div>
</div>

<!--div style="padding-bottom: 1.34375em; margin: .25em 0;" *ngIf="viewContext.eiCreated">
  Repeating for created
</div-->


<!-- TODO: We could show something else here like - delete series etc.. -->
