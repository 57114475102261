import {Component, OnDestroy, OnInit} from "@angular/core";
import {ContextIdDto} from "@savvy/quickbooks";
import {ActivatedRoute, Router} from "@angular/router";
import {Webhook, WebhookService} from "@savvy/webhook";
import {MatDialog} from "@angular/material/dialog";
import { CreateWebhookComponent } from "./createWebhook.component";
import {Subscription} from "rxjs";
import {ContextService} from "../context.service";


@Component({
  selector: 'app-webhooks',
  templateUrl: './webhooks.component.html'
})
export class WebhooksComponent implements OnInit, OnDestroy {

  sub: any;
  subscriptions: Subscription[] = [];

  contextIdDto: ContextIdDto;
  webhooks: Array<Webhook>;
  selected: Array<Webhook> = [];

  constructor(private router: Router,
              private dialog: MatDialog,
              private contextService: ContextService,
              private route: ActivatedRoute,
              private webhookService: WebhookService) {

  }
  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  ngOnInit(): void {

    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
        this.load();
      }
    }));


  }

  load() {
    // TODO: Or call load bookings?
    this.webhookService.list(1, 10, this.contextIdDto.contextId).subscribe(response => {
      this.webhooks = response;
    })
  }

  onSelect(webhook: Webhook) {

  }

  createWebhook() {
    const dialogRef = this.dialog.open(CreateWebhookComponent, {
      width: '90%',
      height: '90%',
      data: {
        contextIdDto: this.contextIdDto,
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      this.load();
    });
  }

  deleteWebhook(webook: Webhook) {
    this.webhookService._delete(webook.id).subscribe(response => {
      this.load();
    })
  }

}


