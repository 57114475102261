import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ChangeListener } from './changeListener';
import { TrialExpiredService } from '../shared/services/trial-expired.service';
import { PhoneNumberHelperService } from '../shared/services/phone-number-helper.service';
import { MatDialog } from '@angular/material/dialog';
import { ClearObservable } from '../shared/classes/clear-observable';
import { takeUntil } from 'rxjs/operators';
import { ContractCompService, ContractUiDto, LoadContractsResponse } from '@savvy/contract';
import { HttpErrorResponse } from '@angular/common/http';
import { AddContractModalComponent } from '../contract/add-contract-modal/add-contract-modal.component';
import { ViewContractModalComponent } from '../contract/view-contract-modal/view-contract-modal.component';
import { ListContractListDto, ViewContextDto } from '@savvy/view-composite';
import { ContextIdDto } from '@savvy/ui';
import { LinkedIdTypeEnum, LinkId } from 'src/app/core/data-share.service';

@Component({
  selector: 'app-list-contract-list',
  templateUrl: 'listContractList.component.html',
  styleUrls: ['commonPanel.component.scss']
})
export class ListContractListComponent extends ClearObservable implements OnInit {

  @Input() listContractListDto: ListContractListDto;
  @Input() contextIdDto: ContextIdDto;
  @Input() viewContext: ViewContextDto;
  @Input() form: UntypedFormGroup;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;

  constructor(
    private router: Router,
    public trialExpiredService: TrialExpiredService,
    private sharedService: PhoneNumberHelperService,
    private dialog: MatDialog,
    private contractApi: ContractCompService) {
    super();
  }

  ngOnInit() {
    this.loadContractLists();
  }

  create() {
    if (this.listContractListDto.useModal) {
      console.log('TODO: Use modal here');
      this.openCreateContractModal();
    } else {
      this.sharedService.viewEntityModalSub.next(true);
      console.log('navigating to /contract/addContract with entity instance id ');
      if (this.viewContext.entityInstanceId) {
        this.router.navigate([
          '/contract/addContract',
          this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          this.viewContext.entityInstanceId.id,
          LinkedIdTypeEnum.EntityInstanceId,
          this.viewContext.entityDefinitionId.id
        ]);
      } else if (this.viewContext.customerContextDto) {
        this.router.navigate([
          '/contract/addContract',
          this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          this.viewContext.customerContextDto.customerId.id,
          LinkedIdTypeEnum.CustomerId,
          ''
        ]);
      } else {
        console.log('no entity instance id on view context');

      }
    }
  }

  rowSelected(contractId: string) {
    console.log('this.listContractListDto.useModal ' + this.listContractListDto.useModal);
    //  if (this.listContractListDto.useModal) {
    console.log('TODO: Use modal here');
    this.openViewContractModal(contractId);
    /*  } else {
        this.sharedService.viewEntityModalSub.next(true);
        console.log('inside gotoEntity contractId ' + contractId);
        this.router.navigate([
          '/contract/viewContract',
          this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          contractId
        ]);
      }*/
  }

  openCreateContractModal() {
    console.log(this);

    if (this.viewContext.entityInstanceId) {
      const createContractModal = this.dialog.open(AddContractModalComponent, {
        data: {
          contextIdDto: this.contextIdDto,
          linkId: this.viewContext.linkId,
          isContractModal: true
        },
        maxWidth: '1024px',
        panelClass: ['helpwindow', 'scrollable-modal'],
        autoFocus: false
      });

      createContractModal.afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          res => {
            if (res) {
              this.loadContractLists();
            }
          });
    } else {
      console.log('no entity instance id');
    }
  }

  openViewContractModal(contractId) {
    const createContractModal = this.dialog.open(ViewContractModalComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        contractId,
        isContractModal: true
      },
      maxWidth: '1024px',
      maxHeight: '80vh',
      height: '80vh',
      panelClass: [
        'modal-100',
        'scrollable-modal'
      ],
      autoFocus: false
    });

    createContractModal.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.loadContractLists();
        });
  }

  loadContractLists() {
    if (this.viewContext.entityInstanceId) {
      this.contractApi.loadContractsForEiLink(
        this.contextIdDto.contextId, this.contextIdDto.contextIdType,
        this.viewContext.entityInstanceId.id, this.viewContext.entityDefinitionId.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe((response: LoadContractsResponse) => {
          this.listContractListDto.contractUiDtos = response.contractUiDtoList;
        }, (err: HttpErrorResponse) => {
          console.log('error while loadContractLists', err);
        });
    } else if (this.viewContext.customerContextDto && this.viewContext.customerContextDto.customerId) {


      this.listContractListDto.contractUiDtos = new Array<ContractUiDto>();

      // contextId: string, contextIdType: string, linkedInstanceType: string, entityDefinitionId: string, entityInstanceId: string, customerId: string
      /*  this.contractApi.loadContractsForCustomer(
          this.contextIdDto.contextId, this.contextIdDto.contextIdType,
          this.viewContext.customerContextDto.customerId.id)
          .pipe(takeUntil(this.destroy$))
          .subscribe((response: LoadContractsResponse) => {
            this.listContractListDto.contractUiDtos = response.contractUiDtoList;
          }, (err: HttpErrorResponse) => {
            console.log('error while loadContractLists', err);
          });*/
    }
  }
}

