<!-- <div fxLayout="row" fxLayoutAlign=" center" class="mdc-mb-1">
  <div fxFlex>
    <h6 class="mb-0 ">Customer Files</h6>
  </div>
</div> -->
<mat-list *ngIf="files?.length; else elseBlock" class="">
  <mat-list-item *ngFor="let file of files">
    <div fxLayout="row wrap" fxLayoutAlign=" center" fxLayoutGap="16px" class="mdc-py-2 w-100">
      <div fxFlex="calc(100% - 64px)">
        <h6 class="mb-0">{{file?.fileName}}</h6>
      </div>
      <div fxFlex="nogrow">
        <div (click)="downloadFile(file)" mat-icon-button>
          <mat-icon role="img">get_app</mat-icon>
        </div>
      </div>
    </div>
  </mat-list-item>
</mat-list>
<ng-template #elseBlock>
  <div class="flexbox-parent">
    <div fxLayout="row" fxLayoutAlign="center center">
      <div style="text-align:center;">
        <div class="avatar avatar-100 circle border-3 mb-2 d-inline-block mt-15px">
          <mat-icon class="h1 h-auto w-auto">cloud_upload</mat-icon>
        </div>
        <h6 class="mb-2">{{'NO_CUSTOMER_FILES' | translate}}.</h6>
      </div>
    </div>
  </div>
</ng-template>