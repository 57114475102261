<div *ngIf="viewInvoiceEntitiesCachedTableData?.viewAssociatedCachedTableEmbeddedDataList">
  <div *ngFor="let viewAssociatedCachedTableEmbeddedData of viewInvoiceEntitiesCachedTableData.viewAssociatedCachedTableEmbeddedDataList">
    <mat-toolbar>
      <mat-toolbar-row>{{viewAssociatedCachedTableEmbeddedData.label}}</mat-toolbar-row>
    </mat-toolbar>
    <mat-card appearance="outlined" style="padding: 0px">
      <mat-card-content>
        <app-view-associated-embedded-cached-table
            [viewAssociatedCachedTableEmbeddedData]="viewAssociatedCachedTableEmbeddedData"
            [contextIdDto]="contextIdDto"
            [eventBus]="eventBus"
            [viewContext]="viewContext"
        >
        </app-view-associated-embedded-cached-table>
      </mat-card-content>
    </mat-card>
  </div>
</div>
