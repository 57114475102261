import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ContextIdDto} from '@savvy/view-definition';
import {EventBusService} from '../element/EventBusService';
import {EventType, UiEvent} from '../event/UiEvent';

@Component({
  selector: 'app-add-edit-task-page',
  templateUrl: 'addEditTaskPage.html'
})
export class AddEditTaskPageComponent implements OnInit {
  contextIdDto: ContextIdDto;
  taskId: string;
  visible: boolean;
  eventBus = new EventBusService();

  constructor(private route: ActivatedRoute,
    private router: Router) {
  }

  ngOnInit() {

    this.eventBus.eventAdded$.subscribe(event => this.onEvent(event));

    this.route.params.subscribe(params => {
      console.log('got contextId [' + params['contextId'] + ']');
      console.log('got contextIdType [' + params['contextIdType'] + ']');
      this.contextIdDto =
      {
        contextIdType: params['contextId'],
        contextId: params['contextIdType']
      };
      this.taskId = params['taskId'];
      this.visible = true;

      this.eventBus.addEvent(EventType.CREATE_TASK);
    });
  }

  gotoTasks() {
    this.router.navigate(['/task/tasks',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType]);
  }

  private onEvent(event: UiEvent) {
    console.log('tasks event is ' + event);

    if (event.eventType === EventType.CANCEL_ADD_EDIT_TASK) {
      console.log('navigating back to tasks screen');
      this.gotoTasks();
    } else if (event.eventType === EventType.TASK_CREATED) {
      this.gotoTasks();
    }
  }
}
