import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PetRun, PetRunService } from '@savvy/pet-stay';
import { TaxService, Tax } from '@savvy/tax';

@Component({
  selector: 'app-add-run',
  templateUrl: './add-run.component.html',
  styleUrls: ['./add-run.component.scss']
})
export class AddRunComponent implements OnInit {
  run: PetRun = {
    name: '',
    ownerId: '',
    price: 0,
    multiPetPrice: 0,
    petRunType: 'BOARDING',
    maxPets: 1,
    color: '',
    hourlyPrice: 0,
  };

  petRunTypes = Object.values(PetRun.PetRunTypeEnum);

  taxes: Tax[];

  constructor(
    public dialogRef: MatDialogRef<AddRunComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private petRunService: PetRunService,
    private taxService: TaxService
  ) { }

  ngOnInit() {
    this.loadTaxes();
    if (this.data && this.data.petRunId) {
      this.run.ownerId = this.data.ownerId;
      this.loadRun(this.data.petRunId);
    }
  }

  loadTaxes() {
    this.taxService.listTaxes(this.data.contextIdDto.contextId).subscribe(response => {
      this.taxes = response.filter(t => !t.deleted);
    });
  }

  loadRun(petRunId) {
    this.petRunService.get(petRunId).subscribe(res => {
      if (res) {
        this.run = res;
      }
    });
  }

  addPetRun() {
    this.dialogRef.close(this.run);
  }

  updateColor(event, cell) {
    this.run[cell] = event;
    if (this.run.id) {
      // this.updateService();
    }
  }

}
