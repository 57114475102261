<div class="flexbox-parent">
  <div fxLayout="row" fxLayoutAlign="center center" style="height: 60vh;  ">
    <div style="text-align:center;">
      <app-zero-image [name]="zeroEntitiesDto.iconName">
      </app-zero-image>
      <br>
      <h5 class="ma-0">{{'You don’t have any ' + zeroEntitiesDto.entityDefinitionLabel + 's' | translate}}</h5><br>
      <button mat-raised-button color="accent" [disabled]="trialExpiredService.isTrialExpired()" *ngIf="zeroEntitiesDto.canCreate" (click)="create()">
        {{'Add' | translate}} {{zeroEntitiesDto.entityDefinitionLabel | translate}}
      </button>
    </div>

  </div>
</div>