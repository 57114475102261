<mat-card>
  <mat-card-header>
    <mat-card-title>
      {{data?.title || 'CONFIRMATION' | translate}}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div>{{ message | translate}} <b>{{data?.name | translate}}</b>?</div>
    <br>
    <button *ngIf="!isChange" mat-raised-button color="accent" (click)="dialogRef.close(true)">{{'DELETE' | translate}}</button>&nbsp;
    <button *ngIf="isChange" mat-raised-button color="accent" (click)="dialogRef.close(true)">{{'CHANGE' | translate}}</button>&nbsp;
    <button mat-raised-button (click)="dialogRef.close(false)">{{'CANCEL' | translate}}</button>
  </mat-card-content>
</mat-card>