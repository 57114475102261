import { Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';

import { CreatePetDetailsV2Component } from './create-pet-details-v2/create-pet-details-v2.component';
import { OverdueGroomsPetsComponent } from './overdue-grooms-pets.component';
import { DailyPetsComponent } from './dailyPets.component';
import { ListPetsComponent } from './listPets.component';
import { ViewPetDetailsV2Component } from './view-pet-details-v2/view-pet-details-v2.component';
import { ExportPetsComponent } from './exportPets.component';
import { ListPetTypeComponent } from '../petdaycare/list-pet-type/list-pet-type.component';

export const petsRoutes: Routes = [
  { path: 'exportPets/:contextId/:contextIdType', component: ExportPetsComponent, canActivate: [AuthGuard] },
  { path: 'exportCustomersAndPets/:contextId/:contextIdType', component: ExportPetsComponent, canActivate: [AuthGuard] },
  { path: 'overdueGrooms/:contextId/:contextIdType', component: OverdueGroomsPetsComponent, canActivate: [AuthGuard] },
  { path: 'dailyPets/:contextId/:contextIdType', component: DailyPetsComponent, canActivate: [AuthGuard] },
  { path: 'pets/:contextId/:contextIdType', component: ListPetsComponent, canActivate: [AuthGuard] },
  { path: 'create/:contextId/:contextIdType', component: CreatePetDetailsV2Component, canActivate: [AuthGuard] },
  { path: 'create/:contextId/:contextIdType/:petOwnerId', component: CreatePetDetailsV2Component, canActivate: [AuthGuard] },
  { path: 'edit/:contextId/:contextIdType/:petId', component: CreatePetDetailsV2Component, canActivate: [AuthGuard] },
  { path: 'view/:contextId/:contextIdType/:petId', component: ViewPetDetailsV2Component, canActivate: [AuthGuard] },
  { path: 'petTypes/:contextId/:contextIdType', component: ListPetTypeComponent, canActivate: [AuthGuard] }
];
