import { Injectable } from '@angular/core';
import { ViewContextDto } from '@savvy/view-composite';
import { UserDto } from '@savvy/org';
import { LinkId, LinkIds } from '@savvy/messaging';
import { LinkedIdTypeEnum } from 'src/app/core/data-share.service';

@Injectable()
export class LinkIdService {


  public getLinkIdsFromLinkedInstance(linkId: LinkId, userDto: UserDto): LinkIds {

    const linkIds = <LinkIds>{};
    linkIds.linkIds = new Array();

    if (linkId) {
      linkIds.linkIds.push(linkId);
    }
    linkIds.linkIds.push(this.getUserAsLinkId(userDto));
    return linkIds;
  }

  public getLinkIds(viewContextDto: ViewContextDto, userDto: UserDto): LinkIds {

    const linkIds = <LinkIds>{};
    linkIds.linkIds = new Array();

    if (viewContextDto && viewContextDto.entityInstanceId && viewContextDto.entityInstanceId.id) {
      linkIds.linkIds.push(this.getEntityAsLinkId(viewContextDto));
    }
    linkIds.linkIds.push(this.getUserAsLinkId(userDto));
    return linkIds;
  }

  getUserAsLinkId(userDto: UserDto): LinkId {
    const linkId = <LinkId>{};
    linkId.linkedId = userDto.userId.id;
    linkId.linkedIdType = LinkedIdTypeEnum.UserId;
    return linkId;
  }

  private getEntityAsLinkId(viewContextDto: ViewContextDto): LinkId {
    const linkId = <LinkId>{};
    linkId.linkedId = viewContextDto.entityInstanceId.id;
    linkId.linkedIdType = LinkedIdTypeEnum.EntityInstanceId;
    return linkId;
  }





}
