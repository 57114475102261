import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-create-pet-v2',
  templateUrl: './create-pet-v2.component.html',
  styleUrls: ['./create-pet-v2.component.scss']
})
export class CreatePetV2Component implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {

  }


  ngOnInit(): void {
  }



}
