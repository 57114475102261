import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ChangeListener } from './changeListener';
import { NavigateService } from '../entry/NavigateService';
import { Page } from '../shared/model/page';
import { TrialExpiredService } from '../shared/services/trial-expired.service';
import { MatDialog } from '@angular/material/dialog';
import { EventBusService } from './EventBusService';
import { PhoneNumberHelperService } from '../shared/services/phone-number-helper.service';
import { AppointmentDataV1V2, AppointmentCompService, AppointmentService, AppointmentV1V2Service} from '@savvy/appointment';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { ViewCustomerAppointmentsData, ContextIdDto } from '@savvy/view-definition';

@Component({
  selector: 'app-view-appts-by-customer-list-v1-v2',
  templateUrl: 'viewAppointmentsByCustomerListV1V2.component.html',
  styleUrls: ['commonPanel.component.scss']

})
export class ViewAppointmentsByCustomerListV1V2Component implements OnInit {

  @Input() viewCustomerAppointmentsData: ViewCustomerAppointmentsData;
  @Input() contextIdDto: ContextIdDto;
  @Input() viewContext: any;
  @Input() form: UntypedFormGroup;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;
  @Input() useModalOnClick: boolean;
  @Input() eventBus: EventBusService;



  futurePage = new Page();
  futureSelected: AppointmentDataV1V2[] = [];
  futureAppointmentDates: Array<AppointmentDataV1V2>;

  pastPage = new Page();
  pastSelected: AppointmentDataV1V2[] = [];
  pastAppointmentDates: Array<AppointmentDataV1V2>;

  constructor(
    private router: Router,
    private naviateService: NavigateService,
    public trialExpiredService: TrialExpiredService,
    private appointmentService: AppointmentService,
    private appointmentV1V2Service: AppointmentV1V2Service,
    private appointmentCompApi: AppointmentCompService,
    private dialog: MatDialog,
    private sharedService: PhoneNumberHelperService,
    private notify: FloSnackbarComponent) {
  }
  ngOnInit() {
    this.futurePage.size = 10;
    this.pastPage.size = 10;

    this.loadFuturePage();
    this.loadPastPage();
  }

  setFuturePage(pageInfo) {
    console.log('pageInfo is ' + pageInfo.offset);
    this.futurePage.pageNumber = pageInfo.offset;
    this.loadFuturePage();
  }

  setPastPage(pageInfo) {
    console.log('pageInfo is ' + pageInfo.offset);
    this.pastPage.pageNumber = pageInfo.offset;
    this.loadPastPage();
  }

  sendAppointments() {
    this.appointmentCompApi.sendAppointmentsV2(this.contextIdDto.contextId,
      this.viewContext.customerContextDto.customerId.id
    ).subscribe(response => {
      if (response.errorMessage) {
        this.notify.message = response.errorMessage;
        this.notify.open();
      } else {
        this.notify.message = 'Sent Appointments to Customer';
        this.notify.open();
      }
    });
  }

  loadFuturePage() {
    // Call list display names and pass page number

    console.log('loadFuturePage page:');

    if (this.viewCustomerAppointmentsData) {
      this.appointmentV1V2Service.loadCustomerFutureV1V2(
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
      //  this.viewCustomerAppointmentsData.entityDefinitionId.id,
        this.viewContext.customerContextDto.customerId.id
   //     (this.futurePage.pageNumber + 1),
    //    this.futurePage.size,

      ).subscribe(response => {
        this.futureAppointmentDates = response.contents;
        this.futurePage.totalElements = response.totalElements;//.eis.totalElements;
        this.futurePage.totalPages = 1; //response.eis.totalPages;
      });
    }
  }

  loadPastPage() {
    // Call list display names and pass page number

    console.log('loading page:');

    if (this.viewCustomerAppointmentsData) {
      this.appointmentV1V2Service.loadCustomerHistoryV1V2(
   //     this.viewCustomerAppointmentsData.entityDefinitionId.id,
       // (this.pastPage.pageNumber + 1),
      //  this.pastPage.size,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.viewContext.customerContextDto.customerId.id
      ).subscribe(response => {
        this.pastAppointmentDates = response.contents;
        this.pastPage.totalElements = response.totalElements; // response.eis.totalElements;
        this.pastPage.totalPages = 1; // response.eis.totalPages;
      });
    }
  }

  onFutureSelect(event) {
    if (this.futureSelected && this.futureSelected.length > 0) {
      this.rowSelected(this.futureSelected[0]);
    }
  }

  onPastSelect(event) {
    if (this.pastSelected && this.pastSelected.length > 0) {
      this.rowSelected(this.pastSelected[0]);
    }
  }

  numFutureRows() {
    return this.futureAppointmentDates.length;
  }

  numPastRows() {
    return this.pastAppointmentDates.length;
  }

  rowSelected(appointmentDataV1V2: AppointmentDataV1V2) {
    if (this.useModalOnClick) {
      /*this.dialog.open(ViewEntityComponent, {
        data: {
          entityInstanceId: appointmentId,
          contextIdDto: this.contextIdDto,
          eventBus: this.eventBus,
          isViewMode: true
        },
        panelClass: 'modal-100',
        maxHeight: '80vh'
      });*/
    } else {
      /*this.sharedService.viewEntityModalSub.next(true);
      this.naviateService.viewEi(this.contextIdDto, appointmentId);*/
    }
  }

  create() {
    /*this.sharedService.viewEntityModalSub.next(true);
    console.log('inside create in ListEntitysListComponent');
    if (this.viewContext.viewContextTypeDto === ViewContextDto.ViewContextTypeDtoEnum.Customer) {
      this.router.navigate(['/entity/createEntity',
        this.viewCustomerAppointmentsData.entityDefinitionId.id,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.viewContext.customerContextDto.customerId.id
      ]);
    } else {
      this.sharedService.viewEntityModalSub.next(true);
      this.router.navigate(['/entity/createEntity',
        this.viewCustomerAppointmentsData.entityDefinitionId.id,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.viewContext.entityInstanceId.id,
        this.viewContext.entityDefinitionId.id
      ]);
    }*/
  }
}
