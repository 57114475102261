<div [formGroup]="form" *ngIf="fieldDefinition">
    <mat-form-field appearance="outline" style="width: 100%;">
      <mat-label>{{fieldDefinition.label | translate}}</mat-label>

      <input matInput placeholder="{{getPlaceholder() | translate}}"
             [required]="fieldDefinition.required"
             [matAutocomplete]="locationAuto"
             name="addressInput"
             [formControlName]="elementInstanceDto.instanceId"
             (keyup)="onKeyUp($event)">

      <mat-autocomplete #locationAuto="matAutocomplete"
                        (optionSelected)="handler($event)">
        <mat-option *ngFor="let prediction of predictions" [value]="prediction.description">
          {{prediction.description}}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="form.controls[elementInstanceDto.instanceId].hasError('required')">
        {{fieldDefinition.label + ' is' | translate}}  <strong>{{'required' | translate}}</strong>
      </mat-error>
    </mat-form-field>
</div>
