import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'productFilter'
  })
export class ProductFilterPipe implements PipeTransform {
    transform(items: any[], filterBy: string): any {
        const search = filterBy?.toLowerCase();
        if (search?.length) {
            return items.filter(item => item?.productName?.toLowerCase()?.indexOf(search) > -1);
        }
        return items;
    }
}
