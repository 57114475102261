import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContextIdDto } from '@savvy/datalist';

@Component({
  selector: 'app-add-edit-pet-type',
  templateUrl: './add-edit-pet-type.component.html',
  styleUrls: ['./add-edit-pet-type.component.scss']
})
export class AddEditPetTypeComponent implements OnInit {
  @Input() contextIdDto: ContextIdDto;

  constructor(
    public dialogRef: MatDialogRef<AddEditPetTypeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {

  }

  createUpdatePetType() {
    this.dialogRef.close(this.data.petType);
  }

}
