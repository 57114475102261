<!-- <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutAlign="center center" fxLayoutGap="5px">
  <div fxFlex="50">

  </div>
  <div fxFlex="24">

  </div>
  <div fxFlex="25">

  </div>
</div> -->


<div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="5px">
  <div fxFlex="auto" fxFlex.gt-sm="50">
    <mat-slide-toggle id="appointment-pickup-service" [(ngModel)]="pickupRequired" [labelPosition]="'before'" class=" mdc-mb-2">
      {{'DO_YOU_WANT_PICKUP_SERVICE' | translate: {Default: 'Would you like pick-up service?'} }}
    </mat-slide-toggle>
  </div>
  <div fxFlex.gt-sm="50" fxLayout="row" fxLayoutGap="5px" *ngIf="pickupRequired">
    <div fxFlex="48">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'PICKUP_TIME' | translate: {Default: 'Pick-up Time'} }}</mat-label>
        <input id="appointment-pickup-time" matInput [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeholder="{{'PLEASE_SELECT_A_PICKUP_TIME' | translate: {Default: 'Please select a pick-up time.'} }}" [(ngModel)]="collectionTime" [required]="pickupRequired">
        <owl-date-time #dt1 [pickerType]="'timer'"></owl-date-time>
      </mat-form-field>
    </div>
    <div fxFlex="50">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'PICKUP_ADDRESS' | translate: {Default: 'Pick-up Address'} }}</mat-label>
        <input id="appointment-pickup-address" matInput placeholder="{{'PROVIDE_PICKUP_ADDRESS' | translate:{Default: 'Please provide pickup address'} }}" [(ngModel)]="pickupAddress">
      </mat-form-field>
    </div>
  </div>
</div>