<mat-sidenav-container class="mat-drawer-container mat-sidenav-container "
  [ngClass]="{ 'side-panel-opened':  sideNavOpen}">
  <div tabindex="-1" class="cdk-visually-hidden cdk-focus-trap-anchor"></div><!-- remove this if not required -->
  <mat-sidenav #sidenav
    class="mat-drawer mat-sidenav ng-tns-c2-23 ng-trigger ng-trigger-transform mat-drawer-side bg-color-basic"
    tabindex="-1">
    <mat-toolbar class="mat-toolbar mat-toolbar-single-row">
      <button mat-icon-button class="mr-1 mat-icon-button backtochat" mat-icon-button (click)="toggleSidenav()">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <div class="text-md">
        <mat-icon class="mat-icon vm mr-05" role="img">contacts</mat-icon>
        {{'CHAT_HISTORY' | translate}}
      </div>
    </mat-toolbar>
    <div class="search-results mdc-px-2" infiniteScroll [infiniteScrollDistance]="scrollDistance"
      [infiniteScrollUpDistance]="scrollUpDistance" [scrollWindow]="false" [infiniteScrollThrottle]="throttle"
      (scrolled)="onScrollDown($event)">
      <mat-list class="mdc-p-0">
        <mat-list-item *ngFor="let ct of chats;" (click)="selectChat(ct)"
          [ngClass]="{ 'selected-chat': ct?.id === chat?.id }">
          <mat-icon matListItemIcon>person_outline</mat-icon>
          <div matListItemTitle
            [ngClass]="{'mat-text-red': !ct?.open && ct?.senderRead, 'mat-text-green': !ct?.senderRead}"> {{
            ct?.userName }}
          </div>
          <div matListItemLine
            [ngClass]="{'mat-text-red': !ct?.open && ct?.senderRead, 'mat-text-green': !ct?.senderRead}"
            class="text-md mb-0 mat-text-muted"> {{
            ct?.lastModifiedDate | localizedDate: 'short' }} </div>
        </mat-list-item>
      </mat-list>
    </div>
  </mat-sidenav>

  <mat-sidenav-content cdkscrollable="" class="mat-sidenav-content">

    <mat-toolbar class="mdc-py-2 mat-toolbar mat-toolbar-single-row helptitle">
      <div fxLayout="row" fxLayoutGap="15px" class="w-100">
        <div fxFlex="nogrow">
          <button mat-icon-button class="mr-1 mat-icon-button" (click)="sideNavOpen = !sideNavOpen">
            <mat-icon class="mat-icon material-icons" role="img" aria-hidden="true">sort</mat-icon>
          </button>
        </div>
        <div fxFlex="nogrow">
          <mat-icon class="mat-icon vm" scale="2">account_circle</mat-icon>
          <span class="username">{{'HELP_AGENT' | translate}}<br></span>
        </div>
        <span class="example-spacer">

        </span>
        <div fxFlex="nogrow">
          <a mat-raised-button color="accent" target="_blank" class="mr-5px"
            href="https://support.itsallsavvy.com/home">
            {{'WEB_SUPPORT' | translate}}
          </a>
          <!-- <button mat-raised-button class="mr-5px" color="accent" (click)="addNewChat()">
            <span class="mat-button-wrapper"> {{'CHAT' | translate}} </span>
          </button> -->
          <a mat-raised-button class="mr-5px" color="accent" href="https://meetings-eu1.hubspot.com/savvy-customer-support"
            target="_blank">
            {{'SUPPORT_DEMO' | translate}}
          </a>
          <button mat-icon-button color="warn" (click)="dialogRef.close()" class="vm">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
    </mat-toolbar>

    <div class="chatbox" #chatbox>
      <div class="chat-conversation-user"
        [ngClass]="{ 'them': chatMessage?.senderId !== contextIdDto.contextId, 'me': chatMessage?.senderId === contextIdDto.contextId }"
        *ngFor="let chatMessage of chat?.chatMessages;let i = index">
        <div class="chat-conversation-message">
          <div *ngIf="chatMessage?.fileReference?.fileReferenceId"
            class="avatar avatar-150 border-3 coverimg pet-pic mx-auto"
            [style.background-image]="'url(' + getUrl(chatMessage?.fileReference?.fileReferenceId) + ')'"
            (click)="handleEditImageInput($event)">
            <div *ngIf="chatMessage?.fileReference?.fileReferenceId"
              (click)="downloadImage(chatMessage?.fileReference?.fileReferenceId)" style="cursor: pointer;">
              <mat-icon>download</mat-icon>
            </div>
          </div>
          <p [innerHTML]="chatMessage?.message"></p>
        </div>
        <div class="chat-date small text-secondary mb-0">
          {{chatMessage?.supportUser}} {{ chatMessage?.time | localizedDate : 'short' }}</div>
      </div>
    </div>
    <hr>
    <h6 class="mat-text-red deprication-note ml-1">
      Note: Our help chat is moving!
      <br>
      Please use the chat widget that pops up in the bottom right corner to contact support. Thank you.
    </h6>
    <mat-card appearance="outlined" *ngIf="!chat?.open" class="subscription-reminder chat-closed">
      <h6 class="mb-0 ">
        <mat-icon class="vm">speaker_notes_off</mat-icon>
        {{'CHAT_IS_CLOSED' | translate}}
      </h6>
      <!-- <p class="mb-0">
        <span class="text-opac">{{'OPEN_NEW_CHAT' | translate}}</span>
        <button mat-raised-button color="accent" (click)="addNewChat()" class="ml-1">
          <span class="mat-button-wrapper"> New Chat </span>
        </button>
      </p> -->
    </mat-card>
    <div class="sendmessage" *ngIf="chat?.open">
      <form (submit)="send()" class="mat-toolbar-chat">
        <div *ngIf="attachedFile && attachedFileType === 'image'"
          class="avatar avatar-150 border-3 coverimg pet-pic mx-auto"
          [style.background-image]="'url(' + getUrl(attachedFile) + ')'" style="margin-bottom: 36px;">
        </div>
        <video *ngIf="attachedFile && attachedFileType === 'video'" [src]="getUrl(attachedFile)"></video>
        <mat-form-field class="w-100 mb-0">
          <input matInput placeholder="{{'WRITE_RESPONSE' | translate}}..." id="mat-input-15" [(ngModel)]="chatMessage"
            [ngModelOptions]="{standalone: true}" aria-invalid="false" aria-required="false">

          <input [hidden]="true" id="chat-file-id-upload" required (change)="handleImageInput($event)"
            [multiple]="false" type="file">
          <label matSuffix for="chat-file-id-upload">
            <mat-icon (click)="attachFile()">attach_file</mat-icon>
          </label>
          <button matSuffix mat-button color="accent">
            <mat-icon>send</mat-icon>
          </button>
        </mat-form-field>
      </form>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
