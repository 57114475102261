import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ContextService } from '../context.service';
import { Router } from '@angular/router';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { MatSidenav } from '@angular/material/sidenav';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@angular/cdk/platform';
import { DateTimeAdapter, NativeDateTimeAdapter, OWL_DATE_TIME_LOCALE, OwlDateTimeIntl } from '@danielmoncada/angular-datetime-picker';
import { LocalIntl } from '../../services/local-intl.service';
import { DateUtils } from '../dates/DateUtils';
import { LocalTime } from '@savvy/appointment';
import { WorkflowScheduleDefinition, ContextIdDto, WorkflowScheduleSource, WorkflowScheduledTime, WorkflowStateValueEqualsDefinition } from '@savvy/workflow-definition';
import { Subscription } from 'rxjs';
import {
  SetDateTimeRelativeToTimeFieldDefinition, WhenToSendDataDto,
  WorkflowDefinitionTypeData,
  WorkflowdefService, TimePeriodValue
} from '@savvy/workflow-definition';
import TimePeriodTypeEnum = SetDateTimeRelativeToTimeFieldDefinition.TimePeriodTypeEnum;

export interface TimePeriodOption {
  label: string;
  timePeriodType: TimePeriodValue.TimePeriodTypeEnum;
}

export interface ScheduleTypeItem {
  label: string;
  value: WorkflowScheduleDefinition.WorkflowScheduleTypeEnum;
}

@Component({
  selector: 'app-add-edit-schedule-def',
  templateUrl: './addEditScheduleDef.component.html',
  providers: [
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-US' },
    { provide: DateTimeAdapter, useClass: NativeDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE, Platform] },
    { provide: OwlDateTimeIntl, useClass: LocalIntl },
  ]
})
export class AddEditScheduleDefComponent implements OnInit, OnDestroy {

  @Input() scheduleDef: WorkflowScheduleDefinition;
  @Input() workflowDefinitionTypeData: WorkflowDefinitionTypeData;
  @Input() workflowDefinitionId: string;
  @Input() contextIdDto: ContextIdDto;
  @Input() title: string;
  @Input() manualTask: boolean = false;
  @Output() scheduleAdded = new EventEmitter();
  @Output() scheduleCancelled = new EventEmitter();
  @Output() scheduleUpdated = new EventEmitter();

  @ViewChild('scheduleDefPanel', { static: false }) scheduleDefPanel: MatSidenav;
  edName: string;
  timeToExecuteTask: Date;
  whenToSendOptions: Array<WhenToSendDataDto>;
  timePeriodOptions: Array<TimePeriodOption>;
  scheduleTypeItems: Array<ScheduleTypeItem>;
  selectedWhenToSendOption: WhenToSendDataDto;
  subscriptions: Subscription[] = [];
  scheduleType: string;
  immediately = WorkflowScheduleDefinition.WorkflowScheduleTypeEnum.Immediately;
  relativeTo = WorkflowScheduleDefinition.WorkflowScheduleTypeEnum.RelativeTo;

  constructor(
    private router: Router,
    private workflowdefService: WorkflowdefService,
    private notify: FloSnackbarComponent,
    private translateService: TranslateService,
    dateTimeAdapter: DateTimeAdapter<any>,
    private dateUtils: DateUtils,
    private contextService: ContextService) {
    dateTimeAdapter.setLocale(this.translateService.currentLang);
  }

  ngOnInit() {
    console.log('loading');
    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
        this.buildTimePeriodList();
        this.buildScheduleSourceList();

        if (this.scheduleDef) {
          this.setTimeToSend();
          this.initialise(this.scheduleDef);
        } else {
          console.log('no scheduleDefinitionId');
          this.scheduleDef = {} as WorkflowScheduleDefinition;
          this.initialise(this.scheduleDef);
        }
        this.buildWhenToSendList();
        this.setTimeToSend();

      }
    }));
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initialise(scheduleDef: WorkflowScheduleDefinition) {
    if (!scheduleDef.workflowScheduleSource) {
      scheduleDef.workflowScheduleSource = {} as WorkflowScheduleSource;
    }


    if (!scheduleDef.workflowScheduleType || scheduleDef.workflowScheduleType == WorkflowScheduleDefinition.WorkflowScheduleTypeEnum.Immediately) {
      scheduleDef.workflowScheduleType = WorkflowScheduleDefinition.WorkflowScheduleTypeEnum.Immediately;
    } else if (scheduleDef.workflowScheduleType == this.relativeTo) {
      scheduleDef.workflowScheduleType = this.relativeTo;
    }

    if (!scheduleDef.workflowScheduledTime) {
      scheduleDef.workflowScheduledTime = {} as WorkflowScheduledTime;
      scheduleDef.workflowScheduledTime.timePeriodValue = {} as TimePeriodValue;
      scheduleDef.workflowScheduledTime.timePeriodValue.period = 1;
      scheduleDef.workflowScheduledTime.timePeriodValue.timePeriodType = TimePeriodTypeEnum.Days;
    }

    if (!scheduleDef.workflowScheduleSource) {
      scheduleDef.workflowScheduleSource = {} as WorkflowScheduleSource;
    }
    if (!scheduleDef.workflowScheduledTime) {
      scheduleDef.workflowScheduledTime = {} as WorkflowScheduledTime;
      scheduleDef.workflowScheduledTime.timePeriodValue = {} as TimePeriodValue;
    }
    scheduleDef.ownerId = this.contextIdDto.contextId;
  }

  updateScheduleType(scheduleType) {
    if (scheduleType == 'Immediately') {
      this.scheduleDef.workflowScheduleType = WorkflowScheduleDefinition.WorkflowScheduleTypeEnum.Immediately;
    } else {
      this.scheduleDef.workflowScheduleType = WorkflowScheduleDefinition.WorkflowScheduleTypeEnum.RelativeTo;
    }
  }

  updateTimeToSend(event: Date) {
    console.log('event is ' + event);
    console.log('updating time to send:' + this.timeToExecuteTask);
    this.scheduleDef.timeOfDayToSend = this.dateUtils.getTimeAsString(event);
    console.log('updated timeOfDayToSend:' + this.scheduleDef.timeOfDayToSend);
  }

  update() {
    this.scheduleDef.workflowScheduleSource = this.selectedWhenToSendOption.scheduleSource;
    this.scheduleDef.workflowScheduledTime.whenToSend = this.selectedWhenToSendOption.whenToSend;
    this.scheduleUpdated.emit(this.scheduleDef);
  }

  setTimeToSend() {

    console.log('setTimeToSend');
    if (this.scheduleDef && this.scheduleDef.timeOfDayToSend) {
      this.timeToExecuteTask = this.dateUtils.getTimeStrToDate(this.scheduleDef.timeOfDayToSend);
    } else {
      this.timeToExecuteTask = new Date();
    }
    console.log('leaving setTimeToSend');
  }

  timeStrToDate(time: string): Date {
    console.log('inside time to date');
    if (time) {
      // 09:17:21
      const date = new Date();
      const timeSplit = time.split(':');
      date.setHours(parseInt(timeSplit[0], 10));
      date.setMinutes(parseInt(timeSplit[1], 10));
      return date;
    }
    return null;
  }

  timeToDate(time: LocalTime): Date {
    console.log('inside time to date');
    if (time) {
      // 09:17:21
      const date = new Date();
      date.setHours(time.hour);
      date.setMinutes(time.minute);
      return date;
    }
    return null;
  }

  formatTime(date: Date) {
    console.log('date hours [' + date.getHours() + '] mins [' + date.getMinutes() + ']');
    const d = new Date(date);
    let hour = '' + d.getHours();
    let minute = '' + d.getMinutes();
    let second = '' + d.getSeconds();

    if (hour.length < 2) {
      hour = '0' + hour;
    }
    if (minute.length < 2) {
      minute = '0' + minute;
    }
    if (second.length < 2) {
      second = '0' + second;
    }

    return [hour, minute, second].join(':');
  }

  formatToLocalTime(date: Date): LocalTime {
    if (date) {
      console.log('date hours [' + date.getHours() + '] mins [' + date.getMinutes() + ']');
      const d = new Date(date);
      const localTime = {} as LocalTime;
      localTime.hour = d.getHours();
      localTime.minute = d.getMinutes();
      localTime.second = d.getSeconds();
      return localTime;
    }
    return null;
  }

  getDate(timeOfDayToSend: LocalTime): Date {
    if (!timeOfDayToSend) {
      return null;
    }

    const dateToRet = new Date();
    // console.log('dateToRet is ' + dateToRet);
    // Assume it is a String
    const timeToSendParsed = moment(timeOfDayToSend, 'HH:mm:ss');

    const newDateToRet = new Date(dateToRet.getFullYear(), dateToRet.getMonth(), dateToRet.getDay(),
      timeToSendParsed.hour(), timeToSendParsed.minute(), timeToSendParsed.second(), 0);

    // console.log('newDateToRet is now ' + newDateToRet);
    return newDateToRet;
  }

  buildWhenToSendList() {
    if (this.workflowDefinitionId) {
      this.workflowdefService.loadWorkflowBuilderData(this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.workflowDefinitionId).subscribe(response => {
          console.log('got whenToSendOptions', response);
          this.whenToSendOptions = response.whenToSendDataDtos;

          // selectedWhenToSendOption
          // if (this.scheduleDef.workflowScheduleSource.elementValueDefinition) {
          this.selectedWhenToSendOption = this.getWhenToSend();
          // }
        });
    } else {
      console.warn('no workflow def id set');
    }
  }

  getWhenToSend() {

    console.log("scheduleDef");
    console.log(this.scheduleDef);
    let whenToSendToRet = null;

    if (this.whenToSendOptions) {
      this.whenToSendOptions.forEach(whenToSend => {
        console.log('whenToSend', whenToSend);
        if (whenToSend.whenToSend && this.scheduleDef.workflowScheduledTime.whenToSend &&
          whenToSend.whenToSend !== this.scheduleDef.workflowScheduledTime.whenToSend) {
          return false;
        }
        if (this.isEqual(whenToSend.scheduleSource, this.scheduleDef.workflowScheduleSource)) {
          //  console.log('match found!!', whenToSend);
          whenToSendToRet = whenToSend;
        } else {
          // console.log('a', whenToSend.scheduleSource);
          // console.log('b', scheduleDefinition.scheduleSource);
          // console.log('no match');

        }
      });
    } else {
      console.warn('no when to send options');
    }

    return whenToSendToRet;
  }

  isEqual(left: WorkflowScheduleSource, right: WorkflowScheduleSource) {

    if (left.scheduleSourceType !== right.scheduleSourceType) {
      return false;
    }
    /*if (left.eventType && right.eventType && left.eventType !== right.eventType) {
      return false;
    }*/
    if (left.entityDefinitionId && left.entityDefinitionId.id && right.entityDefinitionId && right.entityDefinitionId.id && left.entityDefinitionId.id !== right.entityDefinitionId.id) {
      return false;
    }
    if (left.elementValueDefinition && left.elementValueDefinition.elementDefinitionId && left.elementValueDefinition.elementDefinitionId.id &&
      right.elementValueDefinition && right.elementValueDefinition.elementDefinitionId && right.elementValueDefinition.elementDefinitionId.id &&
      left.elementValueDefinition.elementDefinitionId.id !== right.elementValueDefinition.elementDefinitionId.id) {
      return false;
    }
    return true;
  }


  buildTimePeriodList() {
    this.timePeriodOptions = new Array();
    let timePeriodOption = <TimePeriodOption>{};
    timePeriodOption.timePeriodType = TimePeriodValue.TimePeriodTypeEnum.Days;
    timePeriodOption.label = 'Days';
    this.timePeriodOptions.push(timePeriodOption);
    timePeriodOption = <TimePeriodOption>{};
    timePeriodOption.timePeriodType = TimePeriodValue.TimePeriodTypeEnum.Hours;
    timePeriodOption.label = 'Hours';
    this.timePeriodOptions.push(timePeriodOption);
    timePeriodOption = <TimePeriodOption>{};
    timePeriodOption.timePeriodType = TimePeriodValue.TimePeriodTypeEnum.Months;
    timePeriodOption.label = 'Months';
    this.timePeriodOptions.push(timePeriodOption);
  }

  buildScheduleSourceList() {
    this.scheduleTypeItems = new Array();
    let source = <ScheduleTypeItem>{};
    source.label = 'Immediately';
    source.value = WorkflowScheduleDefinition.WorkflowScheduleTypeEnum.Immediately;
    this.scheduleTypeItems.push(source);

    source = <ScheduleTypeItem>{};
    source.label = 'Relative to';
    source.value = WorkflowScheduleDefinition.WorkflowScheduleTypeEnum.RelativeTo;
    this.scheduleTypeItems.push(source);

    source = <ScheduleTypeItem>{};
    source.label = 'Workflow state';
    // THIS IS NOT QUITE RIGHT
    source.value = WorkflowScheduleDefinition.WorkflowScheduleTypeEnum.WorkflowChange;
    this.scheduleTypeItems.push(source);
  }

  cancel() {
    this.scheduleCancelled.emit('scheduleCancelled');
  }
}
