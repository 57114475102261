import { Injectable } from '@angular/core';
import { SubscriptionCompService, SubscriptionStateDto } from '@savvy/subscription';
import { ContextIdDto } from '@savvy/ui';

@Injectable()
export class TrialExpiredStateService {

  subscriptionState: SubscriptionStateDto;

  public setSubscriptionState(subscriptionState: SubscriptionStateDto) {
    this.subscriptionState = subscriptionState;
  }

  public reloadState(contextIdDto: ContextIdDto,
    subscriptionCompService: SubscriptionCompService) {
    subscriptionCompService.loadTrialStatus(contextIdDto.contextIdType,
      contextIdDto.contextId).subscribe(response => {
        this.subscriptionState = response.subscriptionState;
      });
  }

  isTrialExpired() {
    if (!this.subscriptionState) {
      return false;
    } else {
      if (this.subscriptionState) {
        // this.response.subscriptionState.subscriptionStatus === 'NO_SUBSCRIPTION' ||
        return this.subscriptionState.subscriptionStatus === SubscriptionStateDto.SubscriptionStatusEnum.TrialExpired
          || this.subscriptionState.subscriptionStatus === SubscriptionStateDto.SubscriptionStatusEnum.Cancelled;
      }
      return false;
    }
  }

  isInTrial() {
    if (!this.subscriptionState) {
      return false;
    } else {
      if (this.subscriptionState) {
        // this.response.subscriptionState.subscriptionStatus === 'NO_SUBSCRIPTION' ||
        return this.subscriptionState.subscriptionStatus === SubscriptionStateDto.SubscriptionStatusEnum.InTrialPeriod;
      }
      return false;
    }
  }

  getCurrentSubId() {
    return this.subscriptionState ? this.subscriptionState.subscriptionDefinitionId.id : '';
  }
}
