import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';

import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { StorageService } from '@savvy/storage';
import { MatDialog } from '@angular/material/dialog';
import { ConfService } from '../../../config/conf.service';
import { FloSnackbarComponent } from '../../../snackbar/floSnackbar.component';
import { forkJoin, Observable } from 'rxjs';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { WindowRef } from '../../../windowWrapper';
import { ContextIdDto, FileValue } from '@savvy/entity-instance-composite';

@Component({
  selector: 'app-upload-files',
  templateUrl: 'upload-files.component.html',
  styleUrls: ['upload-files.component.scss']
})
export class UploadFilesComponent implements OnInit {

  @Input() contextIdDto: ContextIdDto;

  private _savedFiles: FileValue[] = [];
  public get savedFiles(): FileValue[] {
    return this._savedFiles;
  }
  @Input()
  public set savedFiles(value: FileValue[]) {
    this._savedFiles = value;
  }

  @Output() savedFilesChange: EventEmitter<FileValue[]> = new EventEmitter();

  droppedFilesArray: File[];
  isMobile: boolean;

  constructor(
    private storage: StorageService,
    private notify: FloSnackbarComponent,
    private dialog: MatDialog,
    private injector: Injector,
    private deviceService: DeviceDetectorService) {
  }

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
  }

  postFile(fileToUpload: File): Observable<any> {
    return this.storage.uploadFileToStorage(this.contextIdDto.contextId, this.contextIdDto.contextIdType, fileToUpload);
  }

  updateFiles() {
    this.savedFiles = [...this.savedFiles];
    this.savedFilesChange.emit(this.savedFiles);
  }

  saveFiles(files) {
    console.log('saveFiles called', files.length);
    const filesObservable = [];
    for (const file of files) {
      filesObservable.push(this.postFile(file));
    }

    forkJoin(filesObservable).subscribe(res => {
      res.forEach((elem, index) => {
        const newFile: FileValue = {
          fileName: files[index].name,
          mimeType: files[index].type,
          fileReferenceId: elem['fileReference']
        };

        console.log('calling savedFiles push ', newFile);
        if (!this.savedFiles) {
          this.savedFiles = [];
        }
        this.savedFiles.push(newFile);
      });
      console.log('calling updateFiles');
      this.updateFiles();
    });

  }

  removeFile(index) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        name: 'file'
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.savedFiles.splice(index, 1);
        this.updateFiles();
      }
    });
  }

  droppedFiles(files: NgxFileDropEntry[]) {
    console.log('droppedFiles called');
    this.droppedFilesArray = [];
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (file.size > 10485760) {
            console.warn('File is too big, size is ' + file.size + '.  Max size is 10485760');
          } else {
            this.droppedFilesArray.push(file);
            if (files.length === this.droppedFilesArray.length) {
              this.saveFiles(this.droppedFilesArray);
            }
          }
        });
      }
    }
  }

  downloadFile(file: FileValue) {
    const url = ConfService.apiUrl() + '/rest/storage?contextId=' + this.contextIdDto.contextId +
      '&contextIdType=' + this.contextIdDto.contextIdType +
      '&id=' + file.fileReferenceId;

    const nativeWindow = this.injector.get(WindowRef).nativeWindow;
    nativeWindow.location.href = url;
  }

  setSavedFiles(res: Array<string>) {
    res.forEach((elem, index) => {
      const newFile: FileValue = {
        fileReferenceId: elem
      };
      console.log('calling savedFiles push ', newFile);
      this.savedFiles.push(newFile);
      this.updateFiles();
    });
  }
}

