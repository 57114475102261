import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Invoice } from '@savvy/view-definition';
import { ContextIdDto } from '@savvy/quickbooks';
import { EventBusService } from '../element/EventBusService';

class ModalData {
  contextIdDto: ContextIdDto;
  invoice: Invoice;
  eventBus: EventBusService;
}
@Component({
  selector: 'app-send-invoice-dialog',
  templateUrl: 'sendInvoiceDialog.component.html'
})
export class SendInvoiceDialogComponent implements OnInit {
  invoice: Invoice;
  contextIdDto: ContextIdDto;
  eventBus: EventBusService;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
    public dialogRef: MatDialogRef<SendInvoiceDialogComponent>) {
    this.contextIdDto = data.contextIdDto;
    this.invoice = data.invoice;
    this.eventBus = data.eventBus;
  }

  ngOnInit(): void {
  }
}
