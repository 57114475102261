
<!--mat-card *ngIf="cachedTableRowPageDto && cachedTableRowPageDto.contents.length > 0">

  <mat-card-title *ngIf="viewCachedTableEmbeddedData.viewCachedTableEmbeddedDefinition.label">
    <div fxLayout="row">
      <div fxFlex="80">
        <p class="mt-xs mb-xs">{{viewCachedTableEmbeddedData.viewCachedTableEmbeddedDefinition.label | translate}}</p>
      </div>
      <div fxFlex="20">

      </div>
    </div>
  </mat-card-title>
  <mat-card-content-->
    <ngx-datatable
      *ngIf="rows && rows.length > 0"
      class='material has-plus-btn'
      [rows]='rows'
      [columnMode]="'force'"
      [headerHeight]="50"
      [rowHeight]="'auto'"
      [footerHeight]="50"
      [columns]="columns"
      [externalPaging]="true"
      [count]="page.totalElements"
      [offset]="page.pageNumber"
      [limit]="page.size"
      (page)='setPage($event)'
    >
    </ngx-datatable>

  <!--/mat-card-content>
</mat-card-->


