import { Routes } from '@angular/router';

import { AuthGuard } from '@auth0/auth0-angular';
import { AddEditDatalistComponent } from './flo/datalist/addEditDatalist.component';
import { EditRetailSaleComponent } from './flo/edit-retail-sale/edit-retail-sale.component';
import { StandardLayoutComponent } from './flo/layout/standard-layout/standard-layout.component';
import { RetailSalesComponent } from './flo/retail-sales/retail-sales.component';
import { NoInternetComponent } from './flo/session/no-internet/no-internet.component';
import { SigninComponent } from './flo/session/signin/signin.component';
import { SignoutComponent } from './flo/session/signout/signout.component';

export const appRoutes: Routes = [
  {
    path: '',
    component: StandardLayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./flo/business-matrix/business-matrix.module').then(m => m.BusinessMatrixModule)
      },
      {
        path: 'admin',
        loadChildren: () => import('./flo/admin-settings/admin-settings.module').then(m => m.AdminSettingsModule)
      },
      {
        path: 'services',
        loadChildren: () => import('./flo/services/services.module').then(m => m.AppServicesModule)
      },
      {
        path: 'chatflow',
        loadChildren: () => import('./flo/chatflow/chatflow.module').then(m => m.AppChatflowModule)
      },
      {
        path: 'packages',
        loadChildren: () => import('./flo/packages/packages.module').then(m => m.PackagesModule)
      },
      {
        path: 'shop',
        loadChildren: () => import('./flo/shop/shop.module').then(m => m.AppShopModule)
      },
      {
        path: 'waitingList',
        loadChildren: () => import('./flo/waitingList/waitingList.module').then(m => m.AppWaitingListModule)
      },
      {
        path: 'earnBonusCredits',
        loadChildren: () => import('./flo/earn-bonus-credits/earn-bonus-credits.module').then(m => m.EarnBonusCreditsModule)
      },
      {
        path: 'referAndEarn',
        loadChildren: () => import('./flo/referAndEarn/referAndEarn.module').then(m => m.ReferAndEarnModule)
      },
      {
        path: 'appointments',
        loadChildren: () => import('./flo/appointments/appointments.module').then(m => m.AppAppointmentsModule)
      },
      {
        path: 'flags',
        loadChildren: () => import('./flo/flags/flags.module').then(m => m.AppFlagsModule)
      },
      {
        path: 'taxes',
        loadChildren: () => import('./flo/taxes/taxes.module').then(m => m.AppTaxesModule)
      },
      {
        path: 'locations',
        loadChildren: () => import('./flo/location/locations.module').then(m => m.AppLocationsModule)
      },
      {
        path: 'serviceMatrix',
        loadChildren: () => import('./flo/serviceMatrix/serviceMatrix.module').then(m => m.ServiceMatrixModule)
      },
      {
        path: 'locationMatrix',
        loadChildren: () => import('./flo/locationMatrix/locationMatrix.module').then(m => m.LocationMatrixModule)
      },
      {
        path: 'calendarSettings',
        loadChildren: () => import('./flo/calendarSettings/calendarSettings.module').then(m => m.AppCalendarSettingsModule)
      },
      {
        path: 'activity',
        loadChildren: () => import('./flo/activity/activity.module').then(m => m.ActivityModule)
      },
      {
        path: 'galleries',
        loadChildren: () => import('./flo/galleries/galleries.module').then(m => m.AppGalleriesModule)
      },
      {
        path: 'calendar',
        loadChildren: () => import('./flo/calendar/calendar.module').then(m => m.FloCalendarModule)
      },
      {
        path: 'welcome',
        loadChildren: () => import('./flo/welcome-to-savvy/welcome-to-savvy.module').then(m => m.WelcomeToSavvyModule)
      },
      {
        path: 'task',
        loadChildren: () => import('./flo/task/task.module').then(m => m.AppTaskModule)
      },
      {
        path: 'staffClockin',
        loadChildren: () => import('./flo/staffClockin/staffClockin.module').then(m => m.StaffClockinModule)
      },
      {
        path: 'contract',
        loadChildren: () => import('./flo/contract/contract.module').then(m => m.AppContractModule)
      },
      {
        path: 'invoice',
        loadChildren: () => import('./flo/invoice/invoice.module').then(m => m.AppInvoiceModule)
      },
      {
        path: 'quote',
        loadChildren: () => import('./flo/quote/quote.module').then(m => m.QuoteModule)
      },
      {
        path: 'marketing',
        loadChildren: () => import('./flo/marketing/marketing.module').then(m => m.MarketingModule)
      },
      {
        path: 'order',
        loadChildren: () => import('./flo/orders/order.module').then(m => m.AppOrderModule)
      },
      {
        path: 'messaging',
        loadChildren: () => import('./flo/messaging/messaging.module').then(m => m.AppMessagingModule)
      },
      {
        path: 'entity',
        loadChildren: () => import('./flo/entity/entity.module').then(m => m.EntityModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./flo/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'customerPortal',
        loadChildren: () => import('./flo/customerPortal/customerPortal.module').then(m => m.AppCustomerPortalModule)
      },
      {
        path: 'contactForms',
        loadChildren: () => import('./flo/contactForms/contactForms.module').then(m => m.ContactFormsModule)
      },
      {
        path: 'checkinForms',
        loadChildren: () => import('./flo/checkinForms/checkinForms.module').then(m => m.CheckinFormsModule)
      },
      // {
      //   path: 'customerSignup',
      //   loadChildren: () => import('./flo/customerSignup/customerSignupForms.module').then(m => m.CustomerSignupFormsModule)
      // },
      {
        path: 'questionnaires',
        loadChildren: () => import('./flo/questionnaires/questionnaires.module').then(m => m.QuestionnairesModule)
      },
      {
        path: 'payment',
        loadChildren: () => import('./flo/payment/payment.module').then(m => m.AppPaymentModule)
      },
      {
        path: 'pets',
        loadChildren: () => import('./flo/pets/pet.module').then(m => m.AppPetModule)
      },
      {
        path: 'customer',
        loadChildren: () => import('./flo/customer/customer.module').then(m => m.AppCustomerModule)
      },
      {
        path: 'student',
        loadChildren: () => import('./flo/students/students.module').then(m => m.AppStudentModule)
      },
      {
        path: 'lead',
        loadChildren: () => import('./flo/leads/leads.module').then(m => m.AppLeadModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('./flo/reports/reports.module').then(m => m.AppReportsModule)
      },
      {
        path: 'user',
        loadChildren: () => import('./flo/user/user.module').then(m => m.AppUserModule)
      },
      {
        path: 'roles',
        loadChildren: () => import('./flo/roles/roles.module').then(m => m.RolesModule)
      },
      {
        path: 'branding',
        loadChildren: () => import('./flo/branding/branding.module').then(m => m.AppBrandingModule)
      },
      {
        path: 'paymentSettings',
        loadChildren: () => import('./flo/paymentSettings/paymentSettings.module').then(m => m.PaymentSettingsModule)
      },
      {
        path: 'releaseNotes',
        loadChildren: () => import('./flo/release-notes/release-notes.module').then(m => m.ReleaseNotesModule)
      },
      {
        path: 'planDef',
        loadChildren: () => import('./flo/plan/planDefinition.module').then(m => m.PlanDefModule)
      },
      {
        path: 'expenses',
        loadChildren: () => import('./flo/expense/expenses.module').then(m => m.ExpensesModule)
      },
      {
        path: 'reminders',
        loadChildren: () => import('./flo/reminders/reminders.module').then(m => m.AppRemindersModule)
      },
      {
        path: 'directory',
        loadChildren: () => import('./flo/directory/directory.module').then(m => m.AppDirectoryModule)
      },
      {
        path: 'workflow',
        loadChildren: () => import('./flo/workflow/workflow.module').then(m => m.WorkflowModule)
      },
      {
        path: 'emailTemplates',
        loadChildren: () => import('./flo/emailTemplates/emailTemplates.module').then(m => m.EmailTemplatesModule)
      },
      {
        path: 'subscription',
        loadChildren: () => import('./flo/subscription/subscription.module').then(m => m.AppSubscriptionModule)
      },
      {
        path: 'xero',
        loadChildren: () => import('./flo/xero/xero.module').then(m => m.AppXeroModule)
      },
      {
        path: 'website',
        loadChildren: () => import('./flo/website/website.module').then(m => m.AppWebsiteModule)
      },
      {
        path: 'course',
        loadChildren: () => import('./flo/courses/courses.module').then(m => m.AppCourseModule)
      },
      {
        path: 'accommodation',
        loadChildren: () => import('./flo/accommodation/accommodation.module').then(m => m.AppAccommodationModule)
      },
      {
        path: 'resource',
        loadChildren: () => import('./flo/resources/resources.module').then(m => m.AppResourcesModule)
      },
      {
        path: 'peyDayCare',
        loadChildren: () => import('./flo/petdaycare/petdaycare.module').then(m => m.AppPetDayCareModule)
      },
      {
        path: 'walkers',
        loadChildren: () => import('./flo/walkers/walkers.module').then(m => m.AppWalkersModule)
      },
      {
        path: 'qb',
        loadChildren: () => import('./flo/quickbooks/qb.module').then(m => m.QuickbooksModule)
      },
      {
        path: 'emailSettings',
        loadChildren: () => import('./flo/emailSettings/emailSettings.module').then(m => m.AppEmailSettingsModule)
      },
      {
        path: 'smsSettings',
        loadChildren: () => import('./flo/smsSettings/smsSettings.module').then(m => m.AppSmsSettingsModule)
      },
      {
        path: 'org',
        loadChildren: () => import('./flo/org/org.module').then(m => m.AppOrgModule)
      },
      {
        path: 'landingPage',
        loadChildren: () => import('./flo/landing-pages/landing-pages.module').then(m => m.LandingPagesModule)
      },
      {
        path: 'newsletter',
        loadChildren: () => import('./flo/marketing/newsletters/newsletters.module').then(m => m.NewslettersModule)
      },
      {
        path: 'callToAction',
        loadChildren: () => import('./flo/marketing/call-to-action-emails/call-to-action-emails.module').then(m => m.CallToActionEmailsModule)
      },
      {
        path: '',
        children: [{
          path: 'simplebooking',
          loadChildren: () => import('./flo/simplebooking/simple-app-booking.module').then(m => m.SimpleAppBookingModule),
          data: {
            breadcrumb: 'Booking'
          }
        }]
      },
      // Datalists
      { path: 'addEditDatalist/:dataListId/:contextId/:contextIdType', component: AddEditDatalistComponent },
      {
        path: '',
        children: [{
          path: 'discount',
          loadChildren: () => import('./flo/discount/discount.module').then(m => m.DiscountModule)
        }]
      },
      {
        path: '',
        children: [{
          path: 'termsAndConditions',
          loadChildren: () => import('./flo/term-and-conditions/term-and-conditions.module').then(m => m.TermAndConditionsModule)
        }]
      },
      {
        path: '',
        children: [{
          path: 'businessAnalytics',
          loadChildren: () => import('./flo/business-matrix/business-matrix.module').then(m => m.BusinessMatrixModule)
        }]
      },
      {
        path: 'retail/:contextId/:contextIdType',
        component: RetailSalesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'editRetailOrder/:contextId/:contextIdType/:invoiceId',
        component: EditRetailSaleComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'createRetailOrder/:contextId/:contextIdType',
        component: EditRetailSaleComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  // {
  //   path: 'session',
  //   loadChildren: () => import('./flo/session/session.module').then(m => m.SessionModule)
  // },
  {
    path: 'login',
    component: SigninComponent,
  },
  {
    path: 'logout',
    component: SignoutComponent,
  },
  {
    path: 'no-internet',
    component: NoInternetComponent
  },
  {
    path: 'registration',
    loadChildren: () => import('./flo/registration/registration.module').then(m => m.AppRegistrationModule)
  },
  {
    path: 'unsupported',
    loadChildren: () => import('./flo/unsupported-browsers/unsupported-browsers.module').then(m => m.UnsupportedBrowsersModule)
  },
  {
    path: 'chrome-update',
    loadChildren: () => import('./flo/chrome-update/chrome-update.module').then(m => m.ChromeUpdateModule)
  },
  // {
  //   path: 'book',
  //   loadChildren: () => import('./flo/simplebooking/simplebookingpub.module').then(m => m.SimpleBookingPublicModule),
  //   data: {
  //     breadcrumb: 'Booking'
  //   }
  // },
  // {
  //   path: 'site',
  //   loadChildren: () => import('./flo/website/websitepub.module').then(m => m.WebsitePublicModule),
  //   data: {
  //     breadcrumb: 'Site'
  //   }
  // },
  // {
  //   path: 'entry',
  //   component: EntryComponent,
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: '',
  //   redirectTo: 'entry',
  //   pathMatch: 'full'
  // },
  {
    path: '**', // this is a catchall
    pathMatch: 'full',
    redirectTo: ''
  },
];
