<!-- <mat-toolbar color="primary">
  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
      <span>{{'INVITE_CUSTOMER_2' | translate}}</span>
    </div>
    <div class="example-spacer"></div>
    <button mat-icon-button aria-label="Close" class="ml-1" (click)="cancel()">
      <mat-icon class="full-screen-icon">close</mat-icon>
    </button>
  </div>
</mat-toolbar> -->
<form #inviteForm="ngForm" class="customer-form w-100">
  <div class="invoice-container w-100">
    <mat-card appearance="outlined" class="mx-0 mb-0 mt-0">
      <mat-card-header class="mdc-mb-1">
        <mat-card-title class="text-center">You can invite customer via Email or Phone Number</mat-card-title>
      </mat-card-header>
      <mat-card-content>

        <!-- <h5 class="mdc-mb-1"><mat-icon class="vm mdc-mr-2 ">person</mat-icon> Customer Details</h5> -->

        <!-- <hr class="my-3"> -->

        <div fxLayout.gt-xs="row wrap" fxLayout="column" fxLayoutGap="2%">
          <div fxFlex.gt-sm="46" fxFlex="100">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>{{'EMAIL' | translate}}</mat-label>
              <input matInput appInputTrim [(ngModel)]="customerEmail" name="userName" #userName="ngModel"
                class="demo-full-width" placeholder="{{'EMAIL' | translate}}" email
                (ngModelChange)="onEmailChange($event)">
              <!-- {{userName?.errors | json}} -->
              <div class="control-error" *ngIf="userName.touched && userName.hasError('email')">
                {{'VALID_EMAIL' | translate}}
              </div>
              <div class="control-error" *ngIf="userName.touched && userName.hasError('required')">
                Email is required
              </div>
              <div class="control-error" *ngIf="userName.touched && userName.hasError('userNameExist')">
                {{'ACCOUNT_WITH_EMAIL_EXISTS' | translate}}
              </div>
            </mat-form-field>
          </div>
          <!-- <div fxFlex style="text-align: center;" class="mt-1">OR</div> -->
          <div fxFlex.gt-sm="46" fxFlex="100">
            <app-phone-input name="mobilePhoneNumber" (phoneChanged)="onMobilePhoneChange($event)"
              (countryChanged)="onMobileCountryChange($event)" [selectedCountryCode]="phoneNumberObj?.country"
              label="Phone Number" placeholder="{{'PHONE_NUMBER' | translate: {Default: 'Phone Number'} }}"
              errorName="Phone Number" class="w-100">
            </app-phone-input>
          </div>
        </div>

        <div fxLayout="row wrap" fxLayoutGap="2%" *ngIf="!customerExist">
          <div fxFlex.gt-sm="46" fxFlex="100">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>{{'FIRST_NAME' | translate}}</mat-label>
              <input matInput [(ngModel)]="firstName" name="firstName" #firstNameField="ngModel" class="demo-full-width"
                placeholder="{{'FIRST_NAME' | translate}}" required>
              <mat-error *ngIf="firstNameField.touched && firstNameField.hasError('required')">
                {{'FIRST_NAME_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex.gt-sm="46" fxFlex="100">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>{{'SURNAME' | translate}}</mat-label>
              <input matInput [(ngModel)]="lastName" name="lastName" #lastNameField="ngModel" class="demo-full-width"
                placeholder="{{'SURNAME' | translate}}" required>
              <mat-error *ngIf="lastNameField.touched && lastNameField.hasError('required')">
                {{'SURNAME_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <ng-container *ngIf="existingCustomer">
          <h5 class="mat-text-secondary mdc-mb-1 text-center">You already have a customer with the same email/phone number</h5>
          <mat-card appearance="outlined">
            <mat-card-content>
              <div fxLayout.gt-xs="row wrap" fxLayoutAlign="center " fxLayout="column" fxLayoutGap="2%">
                <div fxFlex="100" fxFlex.gt-sm="120px">
                  <div class="avatar avatar-120 circle border-3 pet-pic coverimg mx-auto"
                    style="background-image: url('/assets/images/customer-profile.png');"></div>
                </div>
                <div fxFlex="100" fxFlex.gt-sm>
                  <h5 class="dog-name mb-0">
                    <span class="mr-1 font-weight-bold">{{existingCustomer?.firstName}}
                      {{existingCustomer?.lastName}}</span>
                    <span class="chiptag green vm px-2" *ngIf="!existingCustomer.deleted">Active</span>
                    <span class="chiptag red vm px-2" *ngIf="existingCustomer.deleted">In Active</span>
                  </h5>
                  <p class="my-2"><a href="mailto: {{existingCustomer?.username}}"><span
                        class="chiptag theme-accent vm mt-5px"><mat-icon>email</mat-icon>
                        {{existingCustomer?.username}}</span></a></p>
                  <p class="my-2"><a href="tel: {{existingCustomer?.phoneNumberObj?.internationalNumber}}"><span
                        class="chiptag theme vm mt-5px"><mat-icon>call</mat-icon>
                        {{existingCustomer?.phoneNumberObj?.internationalNumber}}</span></a></p>
                  <app-view-customer-flags *ngIf="existingCustomer?.flags?.length" [contextIdDto]="data.contextIdDto"
                    [flags]="existingCustomer.flags">
                  </app-view-customer-flags>
                  <p *ngIf="existingCustomer?.address">
                    <span class="mat-text-secondary">Address:
                    </span>
                    {{existingCustomer?.address?.address1}}
                    {{existingCustomer?.address?.address2}}
                    {{existingCustomer?.address?.address3}}
                    {{existingCustomer?.address?.city}}
                    {{existingCustomer?.address?.zipCode}}<br>
                    {{existingCustomer?.address?.state}}
                  </p>
                </div>
              </div>

              <hr class="mdc-my-3">
              <div fxLayout="row wrap">
                <div fxFlex>
                  <button mat-raised-button (click)="createAppointment()" color="accent"
                    class="mx-1"><mat-icon>event</mat-icon> Book
                    Appointment</button>
                </div>
                <div fxFlex="nogrow" class="py-1">

                  <button mat-button (click)="viewCustomer()" color="primary"
                    class="mx-1"><mat-icon>visibility</mat-icon>
                    View</button>
                  <button mat-button (click)="editCustomer()"><mat-icon>edit</mat-icon> Edit</button>
                </div>
              </div>

            </mat-card-content>
          </mat-card>
        </ng-container>

        <h6 *ngIf="userName.touched && userName.errors == null && !customerExist">
          Please click <a href="{{selfRegUrl}}" target="_blank" rel="noopener noreferrer"
            style="text-decoration: underline;" class="cp">here</a> to review your "Create New Customer" form which is
          going to be sent to the customer. You can configure the fields under <b>New Customers</b> setting menu.
        </h6>

        <div fxLayout="row wrap" class="mt-1">
          <div fxFlex>
            <button mat-raised-button (click)="reset()">Reset</button>
          </div>
          <div fxFlex="nogrow">
            <button *ngIf="existingCustomer" mat-raised-button color="accent" (click)="inviteToPortal()">Invite
              <span>{{existingCustomer?.firstName}}</span> to Customer Portal App</button>
            <button *ngIf="!customerExist" [disabled]="!inviteForm?.valid" mat-raised-button color="accent"
              (click)="inviteForSelfReg()">Send Form</button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</form>