<div style="max-width:410px">
  <mat-toolbar color="primary">
    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

      <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
        <span>{{'SMS_CREDIT_SUMMARY' | translate}}</span>
      </div>
      <div class="example-spacer"></div>
      <button mat-icon-button title="{{'CLOSE' | translate}}" class="ml-1" (click)="closePanel()">
        <mat-icon class="full-screen-icon">close</mat-icon>
      </button>
    </div>
  </mat-toolbar>
  <div *ngIf="smsCreditStateService?.smsCreditSummaryDto && month">
    <mat-card appearance="outlined"
      *ngIf="!subscriptionHelperService?.isFreemium && smsCreditStateService?.smsCreditSummaryDto?.remainingSmsCreditsIncludingPaid < 50"
      class="subscription-reminder m-2">
      <h6 class="mb-0 ">
        <mat-icon class="vm">sms_failed</mat-icon>
        {{'TOPUP_YOUR_ACC' | translate}}
      </h6>
      <p class="text-opac mb-0">{{'YOU_ONLY_HAVE' | translate}}
        {{smsCreditStateService?.smsCreditSummaryDto?.remainingSmsCreditsIncludingPaid}} {{'SMS_CREDITS_LEFT' |
        translate}}</p>
    </mat-card>

    <div fxLayout="row wrap" fxLayoutGap="0px" class="border-top text-center">

      <div fxFlex="100" class="mdc-px-3 mdc-py-2 border-bottom text-left">
        <div fxLayout="row " fxLayoutAlign=" center">
          <div fxFlex="nogrow">
            <div class="avatar avatar-40 circle border-3">
              <mat-icon>sms</mat-icon>
            </div>
          </div>
          <div fxFlex="" class="pl-1">
            <p class="mb-0">{{'YOU_HAVE_USED' | translate}} <b>{{
                smsCreditStateService?.smsCreditSummaryDto.smsUsedThisMonth }} {{'SMS' | translate}}</b> <br>{{'IN' |
              translate}} <b>{{ month }} {{'MONTH(S)' | translate}} </b>
            </p>
          </div>
          <div fxFlex="nogrow">
            <button mat-button color="accent" (click)="loadSmsUsed()"><mat-icon
                class="vm mx-1">visibility</mat-icon><span class="vm">{{'VIEW_ALL' | translate}}</span></button>
          </div>
        </div>
      </div>

      <div fxFlex="50" class="mdc-px-3 mdc-py-2 border-bottom border-right">
        <h4 class="mb-0">{{ smsCreditStateService?.smsCreditSummaryDto.purchasedCredits }}</h4>
        <p class="mb-0 mat-text-secondary small">{{'CREDITS_PURCHASED' | translate}}</p>
      </div>
      <div fxFlex="50" class="mdc-px-3 mdc-py-2 border-bottom ">
        <h4 class="mb-0">{{ smsCreditStateService?.smsCreditSummaryDto.smsCreditsUsedThisMonth }}</h4>
        <p class="mb-0 mat-text-secondary small">{{'CREDITS_USED_IN' | translate}} {{ month }}
          <mat-icon class="vm" matTooltip="{{'SMS_CREDITS_DESC' | translate}}">info</mat-icon>
        </p>
      </div>
      <div fxFlex="50" class="mdc-px-3 mdc-py-2 border-bottom border-right">
        <h4 class="mb-0">{{ smsCreditStateService?.smsCreditSummaryDto.numFreeCreditsOnPlanPerMonth }}</h4>
        <p class="mb-0 mat-text-secondary small">{{'FREE_CREDITS' | translate}}
        </p>
      </div>

      <div fxFlex="50" class="mdc-px-3 mdc-py-2 border-bottom">
        <h4 class="mb-0">{{ smsCreditStateService?.smsCreditSummaryDto.remainingSmsCreditsIncludingPaid }}</h4>
        <p class="mb-0 mat-text-secondary small">{{'CREDITS_LEFT' | translate}}
          <mat-icon class="vm" matTooltip="{{'PAID_CREDITS_DESC' | translate}}">info</mat-icon>
        </p>
      </div>
      <div fxFlex="100" class="mdc-px-3 mdc-py-2 border-bottom">
        <p class="mb-0 small"> <span class="mat-text-secondary mx-1">{{'NOTE' | translate}}:</span> {{'EACH' |
          translate}} <b>160</b> {{'CHAR_COUNTED_AS' | translate}} <b>1 {{'SMS' | translate}}</b>.</p>
      </div>
    </div>

    <!-- <p>You have {{ smsCreditStateService?.smsCreditSummaryDto.numFreeCreditsOnPlanPerMonth }} Free SMS this month </p>
  <p>You have {{ smsCreditStateService?.smsCreditSummaryDto.purchasedCredits }} Purchased Credits</p> -->

    <!-- <p>You have used {{ smsCreditStateService?.smsCreditSummaryDto.smsCreditsUsedThisMonth }} SMS credits in {{ month }}
    <mat-icon aria-hidden="false" matTooltip="If SMS credits used is higher than SMS messages sent, then it is because your SMS messages are > 160 characters.  So if your SMS is characters is between 161 and 320 it will count as 2 credits.
If the SMS message is between 320 and 480 characters, it will count as 3 credits etc." aria-label="Example home icon">
      info</mat-icon>
  </p>
  <p>You have {{ smsCreditStateService?.smsCreditSummaryDto.remainingSmsCreditsIncludingPaid }} SMS remaining this month</p> -->

  </div>
  <div *ngIf="smsCreditService?.smsBundles && smsCreditService?.smsBundles.length > 0">

    <div class="mdc-p-3 ">
      <h6>{{'TOPUP_CREDITS' | translate}}</h6>
      <p class="mat-text-secondary small">{{'CREDITS_CARRIED_OVER_DESC' | translate}}</p>

      <mat-form-field class="w-100" appearance="outline"
        [ngClass]="{'mat-text-green': validCoupon, 'mat-text-red': !validCoupon}">
        <mat-label *ngIf="!validCoupon">{{'DISCOUNT_CODE' | translate}}</mat-label>
        <mat-label *ngIf="validCoupon">{{'DISCOUNT_CODE_APPLIED' | translate}}</mat-label>
        <input #couponCode matInput placeholder="{{'ENTER_DISCOUNT_CODE' | translate}}" [(ngModel)]="coupon.couponCode"
          name="couponCode" (change)="discountCodeChanged()">
        <mat-hint>{{'DISCOUNT_APPLIED_NEXT_BILLING' | translate}}</mat-hint>
      </mat-form-field>
    </div>

    <ngx-datatable *ngIf="!buying" class='material m-0 smstable border-top' [columnMode]="'force'" [headerHeight]="50"
      [footerHeight]="0" [rowHeight]="'auto'" [limit]="10" [rows]='smsCreditService?.smsBundles'>
      <ngx-datatable-column name="{{'NAME' | translate}}">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{row.name}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'BUNDLE_COST' | translate}}">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <button mat-flat-button [disabled]="buying" (click)="buyBundle(row)" color="accent">{{row.amount
            |currency:currencyCode:'symbol'}}</button>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="1 SMS">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{row.amountPerSms | number : '1.2-2'}}
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
    <mat-spinner *ngIf="buying">Buying</mat-spinner>
  </div>
</div>
