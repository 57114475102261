<div class="entity-card">
  <div fxLayout="column">
    <div *ngIf="!isViewMode" class="actions" fxFlex="100" fxLayout="row" fxLayoutAlign="space-between baseline">
      <div>
        <button (click)="backToList()" class="ml-xs mat-icon-button" mat-icon-button="">
          <span class="mat-button-wrapper">
            <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">arrow_back</mat-icon>
          </span>
          <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
          <div class="mat-button-focus-overlay"></div>
        </button>
      </div>
      <div>
        <button (click)="editEntity()" class="ml-xs mat-icon-button" mat-icon-button="">
          <span class="mat-button-wrapper">
            <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">edit</mat-icon>
          </span>
          <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
          <div class="mat-button-focus-overlay"></div>
        </button>
        <button (click)="removeEntity()" class="ml-xs mat-icon-button" mat-icon-button="">
          <span class="mat-button-wrapper">
            <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">delete</mat-icon>
          </span>
          <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
          <div class="mat-button-focus-overlay"></div>
        </button>
      </div>
    </div>
    <div class="entity-container" fxFlex="100" fxLayout="column">
      <div fxFlex="100" fxLayoutAlign="center">
        <div [fxFlex]="widthIsDevice" fxFlex.xs="100">
          <app-element
            *ngIf="loadEiViewResponse != null && loadEiViewResponse.viewRootElementDto != null && form != null"
            style="height: 100%" [elementDto]="loadEiViewResponse.viewRootElementDto" [form]="form"
            [eventBus]="eventBus" [contextIdDto]="contextIdDto" [viewContext]="loadEiViewResponse.viewContextDto"
            [additionalDataMapDto]="loadEiViewResponse.additionalDataMapDto" [showPlaceholder]="true"
            [changeListener]="changeListener">
          </app-element>


        </div>
      </div>
    </div>
  </div>
</div>