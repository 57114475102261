import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {
  ContextIdDto,
  FlagDefinition
} from '@savvy/flags';
import { FlagDefHelperService } from "../../services/flagDefHelper.service";


@Component({
  selector: 'app-pets-flags-panel',
  templateUrl: 'petFlagsPanel.component.html'
})
export class PetFlagsPanelComponent implements OnInit {

  @Input() disableCreate: boolean;
  @Input() disableDelete: boolean;
  @Input() flagIds: Array<string>;
  @Input() contextIdDto: ContextIdDto;
  @Output() deleteFlag: EventEmitter<string> = new EventEmitter();
  @Output() createFlag: EventEmitter<string> = new EventEmitter();


  constructor(public flagDefHelperService: FlagDefHelperService) {
  }

  ngOnInit(): void {
    if (!this.flagIds) {
      this.flagIds = new Array<string>();
    }
  }


  addFlag(flagDef: FlagDefinition) {
    console.log('Adding flag');
    this.createFlag.emit(flagDef.id);
  }

  removeFlag(flagId: string) {
    this.deleteFlag.emit(flagId);
  }
}

