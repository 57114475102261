import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ContextIdDto, FlagDefinition, FlagDefService, ListFlagDefsResponse } from "@savvy/flags";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatSelectChange } from "@angular/material/select";


@Component({
  selector: 'app-flag-filter',
  templateUrl: './flag-filter.component.html',
})
export class FlagFilterComponent implements OnInit {

  flagDefs: FlagDefinition[] = [];
  includeFlagDefs: FlagDefinition[] = [];
  excludeFlagDefs: FlagDefinition[] = [];  

  @Input() contextIdDto: ContextIdDto;
  @Input() flagDefinitionType: FlagDefinition.FlagDefinitionTypeEnum = FlagDefinition.FlagDefinitionTypeEnum.Pet;

  @Output() includeFlagsChanged: EventEmitter<FlagDefinition[]> = new EventEmitter();
  @Output() excludeFlagsChanged: EventEmitter<FlagDefinition[]> = new EventEmitter();


  formGroup: UntypedFormGroup = new UntypedFormGroup({
    includeFlags: new UntypedFormControl([]),
    excludeFlags: new UntypedFormControl([]),
  });

  constructor(private flagDefService: FlagDefService) {
  }

  ngOnInit(): void {
    this.loadFlagDefs();
  }

  loadFlagDefs() {
    this.flagDefService.listFlagDefsByType(this.flagDefinitionType,
      this.contextIdDto.contextId, this.contextIdDto.contextIdType)
      .subscribe((res: ListFlagDefsResponse) => {
        this.flagDefs = [...res.flagDefinitions];
        this.setIncludeFlagDefs();
        this.setExcludeFlagDefs();
      });
  }

  removeFlag(flag: FlagDefinition): void {
    const index = this.selectedIncludeFlagDefs.indexOf(flag);

    if (index >= 0) {
      this.selectedIncludeFlagDefs.splice(index, 1);
    }
  }



  setIncludeFlagDefs() {
    // Filter
    this.includeFlagDefs = this.flagDefs.filter(v => this.formGroup.controls.excludeFlags.value.indexOf(v.id) < 0);
  }

  setExcludeFlagDefs() {
    this.excludeFlagDefs = this.flagDefs.filter(v => this.formGroup.controls.includeFlags.value.indexOf(v.id) < 0);
  }

  includeFlagsSelectionChange(event: MatSelectChange) {
    this.setIncludeFlagDefs();
    this.includeFlagsChanged.emit(this.selectedIncludeFlagDefs);
  }

  excludeFlagsSelectionChange(event: MatSelectChange) {
    this.setExcludeFlagDefs();
    this.excludeFlagsChanged.emit(this.selectedExcludeFlagDefs);
  }

  onIncludeFlagRemovedFromSelection(value: any) {
    const selectedFlagDefs = this.formGroup.controls.includeFlags.value as string[];
    //
    this.formGroup.patchValue({ includeFlags: selectedFlagDefs.filter(v => v !== value.id) });
    this.setExcludeFlagDefs();
    this.includeFlagsChanged.emit(this.selectedIncludeFlagDefs);
  }

  onExcludeFlagRemovedFromSelection(value: any) {
    const selectedFlagDefs = this.formGroup.controls.excludeFlags.value as string[];
    this.formGroup.patchValue({ excludeFlags: selectedFlagDefs.filter(v => v !== value.id) });
    this.setIncludeFlagDefs();
    this.excludeFlagsChanged.emit(this.selectedExcludeFlagDefs);
  }

  get selectedExcludeFlagDefs(): FlagDefinition[] {
    const selectedFlagDefs = this.formGroup.controls.excludeFlags.value as string[];
    if (!selectedFlagDefs || !selectedFlagDefs.length) {
      return [];
    }
    return this.flagDefs.filter(v => selectedFlagDefs.indexOf(v.id) >= 0);
  }

  get selectedIncludeFlagDefs(): FlagDefinition[] {
    const selectedFlagDefs = this.formGroup.controls.includeFlags.value as string[];
    if (!selectedFlagDefs || !selectedFlagDefs.length) {
      return [];
    }
    return this.flagDefs.filter(v => selectedFlagDefs.indexOf(v.id) >= 0);
  }
}

function Import() {
  throw new Error("Function not implemented.");
}

