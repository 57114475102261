import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ContextIdDto } from '@savvy/view-definition';
import { Vaccination, VaccinationService, Vaccine, VaccinesService } from '@savvy/vaccination';
import { FloSnackbarComponent } from '../../../snackbar/floSnackbar.component';
import { MatSelectChange } from '@angular/material/select';
import { DateUtils } from '../../../dates/DateUtils';
import { ConfirmationDialogComponent } from '../../../confirmation-dialog/confirmation-dialog.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
@Component({
  selector: 'app-add-pet-vaccination',
  templateUrl: 'add-edit-pet-vaccination.component.html',
  styleUrls: ['add-edit-pet-vaccination.component.scss'],
})
export class AddEditPetVaccinationComponent implements OnInit, OnDestroy {

  contextIdDto: ContextIdDto;
  petId: string;
  customerId: string;

  vaccinesList: Vaccine[] = [];
  petVaccination: Vaccination;

  // model: Payment;
  private destroy$ = new Subject();

  saving = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notify: FloSnackbarComponent,
    private vaccinationService: VaccinationService,
    private vaccinesService: VaccinesService,
    private dateUtils: DateUtils,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<AddEditPetVaccinationComponent>,
    private dialog: MatDialog) {
    this.contextIdDto = data.contextIdDto;
    this.petId = data.petId;
    this.customerId = data.customerId;
    this.petVaccination = data.vaccination;
  }


  ngOnInit(): void {
    this.loadVaccineList();
    this.init();
  }

  loadVaccineList() {
    this.vaccinesService.loadVaccineByDeleted(false, this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(res => {
      if (res && res.length) {
        this.vaccinesList = res;
      }
    });
  }

  init() {
    if (!this.petVaccination) {
      this.petVaccination = {
        nameOfVaccination: '',
        ownerId: this.contextIdDto.contextId,
        petId: this.petId,
        vaccineId: '',
        dateVaccinated: new Date(Date.now()).toISOString(),
        nextDateDue: new Date(Date.now()).toISOString(),
        customerId: this.customerId
      };
    }
  }

  vaccinationChange(event: MatSelectChange) {
    this.updateDueDate(this.petVaccination.dateVaccinated);
  }

  updateDueDate(dateVaccinated) {
    if (dateVaccinated) {
      this.petVaccination.dateVaccinated = new Date(moment(dateVaccinated).format('YYYY-MM-DD')).toISOString();
      const vaccineGiven = this.vaccinesList.find(v => v.id === this.petVaccination.vaccineId);
      if (vaccineGiven != null) {
        const validityInDays = vaccineGiven.validityInDays;
        const nextDateDue = new Date(moment(this.petVaccination.dateVaccinated)
          .add(validityInDays, 'days').format('YYYY-MM-DD')).toISOString();
        this.petVaccination.nextDateDue = nextDateDue;
      } else {
        this.notify.message = 'Has vaccine been selected yet?';
        this.notify.open();
      }
    }
  }

  updateDateVaccinated(dateVaccinated) {
    this.updateDueDate(dateVaccinated);
    // this.updateVaccine();
  }

  updateVaccine() {
    const vaccine = this.vaccinesList.find(v => v.id === this.petVaccination.vaccineId);
    this.petVaccination.nameOfVaccination = vaccine ? vaccine.vaccineName : '';
    if (this.petVaccination && this.petVaccination.id) {
      this.notify.message = `Saving vaccine`;
      this.notify.open();
      this.vaccinationService.updateVaccination(this.petVaccination).subscribe(res => {
        this.notify.message = `Vaccine has been successfully updated.`;
        this.notify.open();
        this.petVaccination = res;
        this.dialogRef.close();
      });
    } else {
      this.notify.message = `Saving vaccine`;
      this.notify.open();
      this.vaccinationService.createVaccination(this.petVaccination).subscribe(res => {
        this.notify.message = `Vaccine has been successfully created.`;
        this.notify.open();
        this.petVaccination = res;
        this.dialogRef.close();
      });
    }
  }

  compareWithFn(v1: string, v2: string) {
    return v1 && v2 && v1 === v2;
  }

  deleteVaccine() {
    const confirmDialog = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'Are you sure you want to delete this vaccination?'
      },
      height: 'auto',
      width: '400px',
      panelClass: 'helpwindow'
    });

    confirmDialog.afterClosed().subscribe((result: boolean) => {
      if (result) {
        if (this.petVaccination) {
          this.vaccinationService.deleteVaccination(this.petVaccination.id).subscribe(res => {
            this.notify.message = 'Vaccination deleted successfully';
            this.notify.open();
           // this.dialog.closeAll();
            this.dialogRef.close(true);
          });
        }
      }
    });
  }


  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  saveVaccine() {
    this.saving = true;
    if (!this.petVaccination?.vaccineId) {
      this.notify.message = 'Vaccination Name is required';
      this.notify.open();
      return;
    }
    if (!this.petVaccination?.dateVaccinated) {
      this.notify.message = 'Vaccination Date is required';
      this.notify.open();
      return;
    }
    this.updateVaccine();
  }
}
