<div class="mdc-px-2">
  <mat-form-field appearance="outline" class="w-100">
    <mat-label>{{'START_TYPING' | translate}}</mat-label>
    <input type="text" matInput name="variable" [maxlength]="50" placeholder="{{'START_TYPING' | translate}}" [formControl]="searchLinkCtrl">
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
</div>

<div class="row">
  <div class="col px-0">
    <mat-list>
      <mat-list-item *ngFor="let ld of filteredLinks">
        <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="16px">
          <div fxFlex="nogrow">
            <button mat-icon-button color="accent" aria-label="Add to editor" #tooltip="matTooltip" matTooltip="{{'ADD_TO_EDITOR' | translate}}" (click)="onAddItemClick(ld)">
              <mat-icon>new_label</mat-icon>
            </button>
          </div>
          <div fxFlex>
            <h6 class="mb-0"><strong>{{ld.label}}</strong></h6>
            <p class="mb-0" *ngIf="ld.subLabel">{{ld.subLabel}}</p>
          </div>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
</div>