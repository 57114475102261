<mat-toolbar color="primary">
  <h1 mat-dialog-title>{{'ADD' | translate}} {{data.dataListDto.name}}</h1>
</mat-toolbar>
<div class="mdc-px-3 pt-2">
  <mat-form-field class="w-100" appearance="outline">
    <mat-label>{{'ENTER' | translate}} {{data.dataListDto.name | translate}}</mat-label>
    <input matInput class="demo-full-width" [(ngModel)]="newItemName" name="newItem" #newItem="ngModel" placeholder="{{'ENTER' | translate}} {{data.dataListDto.name | translate}} " required autofocus>

    <!-- <mat-error *ngIf="newItem.hasError('required')">
      {{'New Item Name is' | translate}} <strong>{{'required' | translate}}</strong>
    </mat-error> -->
  </mat-form-field>

  <div fxLayout="row" class="mdc-mb-1">
    <div fxFlex>
      <button mat-raised-button [mat-dialog-close]="false">{{'CANCEL' | translate}}</button>
    </div>
    <div fxFlex="nogrow">
      <button mat-raised-button [disabled]="!newItemName" (click)="saveDataItem()" color="primary">{{'SAVE' | translate}}</button>
    </div>
  </div>
</div>