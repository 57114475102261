import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { Message, MessageService } from "@savvy/messaging";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";


@Component({
  selector: 'app-used-sms',
  templateUrl: './usedSms.component.html'
})
export class UsedSmsComponent implements OnInit, OnDestroy {

  usedMessages: Array<Message>;

  constructor(private messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<UsedSmsComponent>) {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.loadSmsSent();
  }


  loadSmsSent() {
    this.messageService.listSmsMessagesThisMonth(this.data.contextIdDto.contextId, this.data.contextIdDto.contextIdType).subscribe(response => {
      this.usedMessages = response.messagesList;
    });
  }
}
