<div *ngIf="customerSignupFormDefinitions && customerSignupFormDefinitions.length > 0">

  <mat-toolbar color="primary">
    {{'CUSTOMER_CAPTURE_TEMPLATE' | translate}}
  </mat-toolbar>
  <ngx-datatable *ngIf="customerSignupFormDefinitions" class='material' [columns]="columns" [columnMode]="'force'"
    [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [limit]="10" [selected]="selected"
    [selectionType]="'cell'" (select)="onSelect($event)" [rows]='customerSignupFormDefinitions'>
  </ngx-datatable>
  <div class="button-pad">
    <button (click)="create()" mat-raised-button color="primary">
      {{'CREATE' | translate}}
    </button>
  </div>

</div>

<app-zero-customer-signup-form-templates [contextIdDto]="contextIdDto"
  *ngIf="customerSignupFormDefinitions && customerSignupFormDefinitions.length === 0" />