import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PetMedicationConfig } from '@savvy/pet-stay';
import { Tax } from '@savvy/tax';
import { CreateBoardingBookingV2SyncService } from '../../create-boarding-booking-v2-sync.service';

@Component({
  selector: 'app-pet-medication-config',
  templateUrl: './pet-medication-config.component.html',
  styleUrls: ['./pet-medication-config.component.scss']
})
export class PetMedicationConfigComponent implements OnInit {
  @Input() taxDefs: Tax[] = [];

  @Input() petMedicationConfig: PetMedicationConfig | undefined = {} as PetMedicationConfig;
  @Output() petMedicationConfigChange: EventEmitter<PetMedicationConfig> = new EventEmitter();

  constructor(
    public createBoardingBookingV2SyncService: CreateBoardingBookingV2SyncService
  ) { }

  ngOnInit() {
  }

  updateUnitPrice(config: PetMedicationConfig) {
    this.updateQty(1, config);
  }

  updateQty(qty, config: PetMedicationConfig) {
    config.subTotal = Number(config.price) * qty;
    this.createBoardingBookingV2SyncService.updateGrossPriceForMedication(config);
  }

  compareTax(o1: string, o2: string): boolean {
    if (o1 && o2) {
      return o1 === o2;
    }
    return false;
  }

  remove() {
    this.petMedicationConfig = undefined;
    this.petMedicationConfigChange.emit(undefined);

  }

}
