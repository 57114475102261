import { Component, Input, OnInit } from '@angular/core';
import { ContextIdDto } from '@savvy/app';
import { Customer } from '@savvy/customer';
import { Address } from '@savvy/location';
import { WindowRef } from '../../../windowWrapper';

@Component({
  selector: 'app-view-customer-address',
  templateUrl: './view-customer-address.component.html',
  styleUrls: ['./view-customer-address.component.scss']
})
export class ViewCustomerAddressComponent implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() customerDto: Customer;

  constructor(private windowRef: WindowRef) { }

  ngOnInit() {
  }

  getEncoded(text: string) {
    return encodeURIComponent(text ? String(text) : '');
  }

  openGoogleMaps() {
    const baseUrl = 'https://www.google.com/maps/dir/?api=1&dir_action=navigate&travelmode=driving&';
    let address: Address = {} as Address;
    if (this.customerDto?.address) {
      address = this.customerDto.address;
    }
    let url = `${baseUrl}destination=${address?.latitude},${address?.longitude}`;
    if (address.zipCode) {
      const finalAddress = `
      ${this.getEncoded(address?.zipCode)}
      ${address?.city?.trim() ? '+' + this.getEncoded(address?.city) : ''}
      `;
      // ${address?.address1?.trim() ? '+' + this.getEncoded(address?.address1) : ''}
      // ${address?.address2?.trim() ? '+' + this.getEncoded(address?.address2) : ''}
      // ${address?.address3?.trim() ? '+' + this.getEncoded(address?.address3) : ''}
      // ${address?.state?.trim() ? '+' + this.getEncoded(address?.state) : ''}
      // ${address?.country?.trim() ? '+' + this.getEncoded(address?.country) : ''}
      url = `${baseUrl}destination=${finalAddress.trim().replace(/ /g, '').replace(/\n/g, '')}`;
    }
    this.windowRef.getWindow().open(url, '_blank');
  }

}
