<form class="mdc-p-3 ">
  <div class="selection-message">
    {{'YOU_HAVE_SELECTED' | translate: {Default: 'You have selected:'} }}
    <span class="highlight-message" *ngIf="createAppointmentV2SyncService.enablePackages">
      {{createAppointmentV2SyncService.totalPackages}} {{totalPackagesPlural? 'PACKAGES' : 'PACKAGE' | translate}}
    </span>

    <span class="highlight-message">
      {{ createAppointmentV2SyncService.totalServices }} {{totalServicesPlural? 'SERVICES' : 'SERVICE' | translate}}
    </span>

    {{'ON' | translate: {Default: 'on'} }}

    <span class="highlight-message">
      {{createAppointmentV2SyncService.startDate | localizedDate}} |
      {{createAppointmentV2SyncService.displayStartTime | localizedDate:'shortTime'}}
    </span>
  </div>

  <div class="amount-label">{{'TOTAL_PAYMENT_AMOUNT_IS' | translate: {Default: 'Total payment amount is:'} }}</div>
  <div class="total-amount">{{createAppointmentV2SyncService.totalAmount | currency:currencyCode:'symbol'}}</div>
  <div class="deposite-label">{{'PAYABLE_DEPOSIT_IS' | translate: {Default: 'The payable deposit is:'} }} <span class="deposite-amount">
      {{createAppointmentV2SyncService.totalAmountDue | currency:currencyCode:'symbol'}}
    </span>
  </div>
</form>