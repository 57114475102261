import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-subscription-modal',
  templateUrl: './confirmation-subscription-modal.component.html',
  styleUrls: ['./confirmation-subscription-modal.component.scss']
})
export class ConfirmationSubscriptionModalComponent implements OnInit {


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<ConfirmationSubscriptionModalComponent>) {
  }

  ngOnInit() {
  }

}
