<ng-container *ngIf="!deviceService.isMobile() && !deviceService.isTablet()">
  <div *ngFor="let pet of createPetAppointmentV2SyncService.petsSelected;let petRowIndex = index;">

    <div *ngIf="pet?.petName && petDueVaccinations[petRowIndex]?.dueVaccinations.length > 0"
      class="alert alert-danger alert-dismissible fade show" role="alert"
      style="background-color: var(--bs-alert-bg); color: #E51136">
      <mat-icon class="mr-5px vm">medical_services</mat-icon> Pet <strong>{{pet.petName}}</strong> vaccination/s
      ({{getDueVaccines(petRowIndex)}}) has expired.
      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>


    <mat-card appearance="outlined" class="mdc-mb-2">
      <mat-card-content class="mdc-pb-0">
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.gt-sm="16px" fxLayoutAlign=" start"
          class="position-relative">
          <div fxFlex="23" fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign.gt-sm=" center">
            <div fxFlex="nogrow">
              <div class="avatar avatar-50 circle border-3">
                <mat-icon>pets</mat-icon>
              </div>
            </div>
            <div fxFlex>
              <h5 class="mb-0">
                <span (click)="openViewPetModal(pet)">{{pet.petName}}</span>
                <button mat-icon-button (click)="openEditPetModal(pet)"><mat-icon class="vm">edit</mat-icon></button>
              </h5>
              <mat-card-subtitle>{{pet.petBreed}}</mat-card-subtitle>
            </div>
          </div>
          <div fxFlex="calc(72% - 16px)" fxLayout="column" class="mdc-m-0">
            <div fxLayout="row" fxLayoutGap="8px"
              *ngFor="let service of createPetAppointmentV2SyncService.selectedServices[pet.petId]; let rowIndex = index; let last = last"
              class="mb-0">
              <div fxFlex="94">
                <div fxLayout="row" fxLayout.gt-sm="row" fxLayoutGap="8px">
                  <div fxFlex>
                    <app-pet-service-selector-v2 #petServiceSelectorV2Component [contextIdDto]="contextIdDto"
                      [selectedService]="service.selectedService"
                      (serviceSelectionChanged)="serviceSelected($event, pet.petId, rowIndex, service)"
                      class="field-center w-100">
                    </app-pet-service-selector-v2>
                  </div>
                  <div fxFlex="12">
                    <mat-form-field appearance="outline" *ngIf="service.selectedService" class="field-center w-100">
                      <mat-label>{{'PRICE' | translate: {Default: 'Price'} }}</mat-label>
                      <input id="appointment-service-unit-price" matInput type="text" appNumberOnly [decimalPoints]="2"
                        placeholder="{{'UNIT_PRICE' | translate: {Default: 'Unit Price'} }}"
                        name="unitPrice{{petRowIndex}}{{rowIndex}}" #unitPrice{{petRowIndex}}{{rowIndex}}="ngModel"
                        [(ngModel)]="service.selectedService.unitPrice" (ngModelChange)="updateUnitPrice(service)"
                        id="unitPrice{{petRowIndex}}{{rowIndex}}" required>
                      <button *ngIf="pet" mat-icon-button color="accent"
                        (mouseover)="getLastSeenPrice(pet, service.selectedService)"
                        matTooltip="{{'LAST_PRICE' | translate: {Default: 'Last Price'} }} : {{lastPrice || 'N/A'}}"
                        matTooltipPosition="above" matSuffix>
                        <mat-icon>info</mat-icon>
                      </button>
                    </mat-form-field>
                  </div>
                  <div fxFlex="20" *ngIf="taxDefs && taxDefs.length">
                    <mat-form-field appearance="outline" class="field-center w-100">
                      <mat-label>{{'TAX' | translate: {Default: 'Tax'} }}</mat-label>
                      <mat-select id="appointment-service-tax"
                        placeholder="{{'SELECT_TAX_RATE' | translate: {Default: 'Select Tax Rate'} }}"
                        [(ngModel)]="service.selectedService.taxId" name="taxEntityInstanceId"
                        #taxEntityInstanceId="ngModel" (ngModelChange)="updateGrossPrice(service)">
                        <mat-option value="" selected>No Tax</mat-option>
                        <mat-option *ngFor="let tax of taxDefs" [value]="tax.id">
                          {{ tax.taxName }} ( {{ tax.taxRate }} %)
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div fxFlex="15">
                    <mat-form-field appearance="outline" *ngIf="service.selectedService" class="field-center w-100">
                      <mat-label>{{'GROSS_PRICE' | translate: {Default: 'Gross Price'} }}</mat-label>
                      <input id="appointment-gross-price" matInput type="text" appNumberOnly [decimalPoints]="2"
                        minValue placeholder="{{'GROSS_PRICE' | translate: {Default: 'Gross Price'} }}"
                        name="grossPrice{{petRowIndex}}{{rowIndex}}" #grossPrice{{petRowIndex}}{{rowIndex}}="ngModel"
                        [(ngModel)]="service.grossPrice"
                        (ngModelChange)="createPetAppointmentV2SyncService.calculateTotalAmount()"
                        id="grossPrice{{petRowIndex}}{{rowIndex}}" required readonly>
                    </mat-form-field>
                  </div>
                  <!-- <div fxFlex="12">
                <mat-form-field appearance="outline" *ngIf="service.selectedService" class="field-center w-100">
                  <mat-label>{{'Duration In Mins' | translate}}</mat-label>
                  <input matInput type="text" appNumberOnly [decimalPoints]="2" placeholder="Unit Price"
                    name="durationInMins{{petRowIndex}}{{rowIndex}}" #durationInMins{{petRowIndex}}{{rowIndex}}="ngModel"
                    [(ngModel)]="service.selectedService.durationInMins" (ngModelChange)="durationInMinsChanged(service)"
                    id="durationInMins{{petRowIndex}}{{rowIndex}}" required>
                </mat-form-field>
              </div> -->

                </div>
                <div fxLayout="row" fxLayoutGap="8px">
                  <div fxFlex="20">
                    <mat-form-field *ngIf="timeArray && timeArray.length > 0" appearance="outline" class="w-100">
                      <mat-label>{{'START_TIME' | translate: {Default: 'Start Time'} }}</mat-label>
                      <mat-select id="appointment-service-start-time"
                        placeholder="{{'SELECT_START_TIME' | translate: {Default: 'Select Start Time'} }}"
                        (selectionChange)="startTimeChanged($event.value, service)" [(ngModel)]="service.startTime"
                        required>
                        <mat-option *ngFor="let time of timeArray" [value]="time.actualValue">{{time.displayValue}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div fxFlex="20">
                    <mat-form-field *ngIf="timeArray && timeArray.length > 0" appearance="outline" class="w-100">
                      <mat-label>{{'END_TIME' | translate: {Default: 'End Time'} }}</mat-label>
                      <mat-select id="appointment-service-end-time"
                        placeholder="{{'SELECT_END_TIME' | translate: {Default: 'Select End Time'} }}"
                        (selectionChange)="endTimeChanged($event.value, service)" [(ngModel)]="service.endTime"
                        required>
                        <mat-option *ngFor="let time of timeArray" [value]="time.actualValue">{{time.displayValue}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div fxFlex="40">
                    <app-staff-selector class="field-center" *ngIf="service.selectedService"
                      [contextIdDto]="contextIdDto" [selectedStaff]="service.selectedStaff"
                      [selectedServiceId]="service.selectedService?.id"
                      [selectedLocationId]="createPetAppointmentV2SyncService?.selectedLocation?.id"
                      (staffSelectionChanged)="staffSelectionChanged($event, pet.petId, rowIndex)">
                    </app-staff-selector>
                  </div>
                </div>

              </div>
              <div fxFlex="nogrow" fxFlexAlign="center" fxLayout="column">
                <button id="appointment-remove-service" mat-icon-button color="accent"
                  (click)="deleteServiceNew(pet.petId, rowIndex)"
                  *ngIf="createPetAppointmentV2SyncService.selectedServices[pet.petId].length > 1">
                  <mat-icon>cancel</mat-icon>
                </button>
                <button id="appointment-add-service" mat-icon-button color="primary" (click)="addServiceNew(pet, true)"
                  *ngIf="last">
                  <mat-icon>add_circle_outline</mat-icon>
                </button>
              </div>
            </div>
            <div *ngIf="pet.petId">
              <app-pets-flags-panel class="mdc-mb-2 d-block" [contextIdDto]="contextIdDto" [flagIds]="pet.flags"
                [disableCreate]="true" [disableDelete]="true" (deleteFlag)="deleteFlag($event)"
                (createFlag)="createFlag($event, pet)">
              </app-pets-flags-panel>
            </div>
          </div>
          <mat-icon id="appointment-remove-pet" fxFlex="24px" *ngIf="allowedToRemove" class="remove-pet"
            (click)="createPetAppointmentV2SyncService.removePet(petRowIndex)">cancel</mat-icon>
        </div>
      </mat-card-content>
    </mat-card>

  </div>

</ng-container>

<ng-container *ngIf="deviceService.isMobile() || deviceService.isTablet()">
  <div fxLayout="column" fxLayout.gt-sm="row wrap" class="petservice mdc-p-3 mdc-mb-1 rounded-10 border mt-2"
    *ngFor="let pet of createPetAppointmentV2SyncService.petsSelected;let petRowIndex = index;">
    <div fxFlex="100" fxLayoutGap="10px" fxLayoutGap.gt-sm="10px" fxFlexAlign.gt-sm="center" fxLayout="row wrap"
      class="">
      <div fxFlex="nogrow" fxFlex.gt-sm="nogrow" fxHide fxShow.gt-sm>
        <div class="avatar avatar-50 circle border-3">
          <mat-icon>pets</mat-icon>
        </div>
      </div>
      <div fxFlex="100" fxFlex.gt-sm fxFlexAlign=" center">
        <h5 class="mb-0">
          <a (click)="openViewPetModal(pet)">
            {{pet.petName}}
          </a>
          <mat-icon class="vm edit-pet" (click)="openEditPetModal(pet)">edit</mat-icon>
        </h5>
        <p class="mb-0">
          <a (click)="openViewCustomerModal(pet)">
            {{pet.customerName}}
          </a>
          <mat-icon class="vm edit-pet" (click)="openEditCustomerModal(pet)">edit</mat-icon>
        </p>
      </div>
      <div fxFlex="100" fxFlex.gt-sm="nogrow" *ngIf="pet.petId">
        <app-pets-flags-panel [contextIdDto]="contextIdDto" [flagIds]="pet.flags" (deleteFlag)="deleteFlag($event)"
          (createFlag)="createFlag($event, pet)">
        </app-pets-flags-panel>
      </div>
      <mat-icon class="remove-pet" (click)="createPetAppointmentV2SyncService.removePet(petRowIndex)"
        style="right: -8px;top: -10px;">cancel</mat-icon>

    </div>

    <div fxFlex="100" fxLayout="column">
      <div fxLayout="column" fxLayoutGap="5px" [ngClass]="{'border-top mdc-pt-2': rowIndex > 0}"
        *ngFor="let service of createPetAppointmentV2SyncService.selectedServices[pet.petId]; let rowIndex = index; let last = last">
        <div fxFlex="100">
          <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutGap.gt-sm="16px">
            <div fxFlex="100" fxFlex.gt-xs="46">
              <app-pet-service-selector-v2 #petServiceSelectorV2Component [contextIdDto]="contextIdDto"
                [selectedService]="service.selectedService"
                (serviceSelectionChanged)="serviceSelected($event, pet.petId, rowIndex, service)"
                class="field-center w-100">
              </app-pet-service-selector-v2>
            </div>
            <div fxFlex="46" fxFlex.gt-xs="25">
              <mat-form-field appearance="outline" *ngIf="service.selectedService" class="field-center w-100">
                <mat-label>{{'PRICE' | translate: {Default: 'Price'} }}</mat-label>
                <input matInput type="text" appNumberOnly [decimalPoints]="2"
                  placeholder="{{'UNIT_PRICE' | translate: {Default: 'Unit Price'} }}"
                  name="unitPrice{{petRowIndex}}{{rowIndex}}" #unitPrice{{petRowIndex}}{{rowIndex}}="ngModel"
                  [(ngModel)]="service.selectedService.unitPrice" (ngModelChange)="updateUnitPrice(service)"
                  id="unitPrice{{petRowIndex}}{{rowIndex}}" required>
                <button *ngIf="pet" mat-icon-button color="accent"
                  (mouseover)="getLastSeenPrice(pet, service.selectedService)"
                  matTooltip="{{'LAST_PRICE' | translate: {Default: 'Last Price'} }} : {{lastPrice || 'N/A'}}"
                  matTooltipPosition="above" matSuffix>
                  <mat-icon>info</mat-icon>
                </button>
              </mat-form-field>

            </div>
            <div fxFlex="46" fxFlex.gt-xs="25" *ngIf="taxDefs && taxDefs.length">
              <mat-form-field appearance="outline" class="field-center w-100">
                <mat-label>{{'TAX' | translate: {Default: 'Tax'} }}</mat-label>
                <mat-select placeholder="{{'SELECT_TAX_RATE' | translate: {Default: 'Select Tax Rate'} }}"
                  [(ngModel)]="service.selectedService.taxId" id="taxId{{petRowIndex}}{{rowIndex}}"
                  name="taxId{{petRowIndex}}{{rowIndex}}" #taxId{{petRowIndex}}{{rowIndex}}="ngModel"
                  (ngModelChange)="updateGrossPrice(service)">
                  <mat-option value="" selected>No Tax</mat-option>
                  <mat-option *ngFor="let tax of taxDefs" [value]="tax.id">
                    {{ tax.taxName }} ( {{ tax.taxRate }} %)
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxFlex="46" fxFlex.gt-xs="47">
              <mat-form-field appearance="outline" *ngIf="service.selectedService" class="field-center w-100">
                <mat-label>{{'GROSS_PRICE' | translate: {Default: 'Gross Price'} }}</mat-label>
                <input matInput type="text" appNumberOnly [decimalPoints]="2"
                  placeholder="{{'GROSS_PRICE' | translate: {Default: 'Gross Price'} }}"
                  name="grossPrice{{petRowIndex}}{{rowIndex}}" #grossPrice{{petRowIndex}}{{rowIndex}}="ngModel"
                  [(ngModel)]="service.grossPrice"
                  (ngModelChange)="createPetAppointmentV2SyncService.calculateTotalAmount()"
                  id="grossPrice{{petRowIndex}}{{rowIndex}}" required>
              </mat-form-field>
            </div>
            <!-- <div fxFlex="50" fxFlex.gt-xs="25">
              <mat-form-field appearance="outline" *ngIf="service.selectedService" class="field-center w-100">
                <mat-label>{{'Duration In Mins' | translate}}</mat-label>
                <input matInput type="text" appNumberOnly [decimalPoints]="2" placeholder="Unit Price"
                  name="durationInMins{{petRowIndex}}{{rowIndex}}" #durationInMins{{petRowIndex}}{{rowIndex}}="ngModel"
                  [(ngModel)]="service.selectedService.durationInMins" (ngModelChange)="durationInMinsChanged(service)"
                  id="durationInMins{{petRowIndex}}{{rowIndex}}" required>
              </mat-form-field>
            </div> -->
            <div fxFlex="46" fxFlex.gt-xs="50">
              <app-staff-selector class="field-center" *ngIf="service.selectedService" [contextIdDto]="contextIdDto"
                [selectedStaff]="service.selectedStaff" [selectedServiceId]="service.selectedService?.id"
                [selectedLocationId]="createPetAppointmentV2SyncService?.selectedLocation?.id"
                (staffSelectionChanged)="staffSelectionChanged($event, pet.petId, rowIndex)">
              </app-staff-selector>
            </div>
            <div fxFlex="46" fxFlex.gt-xs="47">
              <mat-form-field *ngIf="timeArray && timeArray.length > 0" appearance="outline" class="field-center w-100">
                <mat-label>{{'START_TIME' | translate: {Default: 'Start Time'} }} {{ service.startTime }}</mat-label>
                <mat-select placeholder="{{'SELECT_START_TIME' | translate: {Default: 'Select Start Time'} }}"
                  (selectionChange)="startTimeChanged($event.value, service)" [(ngModel)]="service.startTime" required>
                  <mat-option *ngFor="let time of timeArray" [value]="time.actualValue">{{time.displayValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxFlex="46" fxFlex.gt-xs="50">
              <mat-form-field *ngIf="timeArray && timeArray.length > 0" appearance="outline" class="field-center w-100">
                <mat-label>{{'END_TIME' | translate: {Default: 'End Time'} }}</mat-label>
                <mat-select placeholder="{{'SELECT_END_TIME' | translate: {Default: 'Select End Time'} }}"
                  [(ngModel)]="service.endTime" required>
                  <mat-option *ngFor="let time of timeArray" [value]="time.actualValue">{{time.displayValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>


          </div>

        </div>
        <div fxFlex="nogrow" fxFlexAlign="center" fxLayout="row">
          <button mat-icon-button color="accent" (click)="deleteServiceNew(pet.petId, rowIndex)"
            *ngIf="createPetAppointmentV2SyncService.selectedServices[pet.petId].length > 1">
            <mat-icon>cancel</mat-icon>
          </button>
          <button mat-icon-button color="primary" (click)="addServiceNew(pet)" *ngIf="last">
            <mat-icon>add_circle_outline</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- <div class="flexbox-parent" *ngIf="!servicesHelperService?.consumerServices?.length">
  <div fxLayout="row" fxLayoutAlign="center center" style="height: 20vh;">
    <div style="text-align:center;">
      <app-zero-image name="customer" class="zero-image"></app-zero-image><br>
      <h5 class="ma-0">{{'You don’t have any Services' | translate}}</h5><br>
      <button mat-raised-button color="accent" (click)="createNewService()">
        {{'Add Service' | translate}}
      </button>
    </div>
  </div>
</div> -->
