<ngx-datatable *ngIf="webhooks && webhooks.length > 0" class='material mdc-mb-2' [rows]='webhooks' [columnMode]="'force'" [headerHeight]="50" [rowHeight]="'auto'" [footerHeight]="0" [selected]="selected" [selectionType]="'single'" (select)="onSelect($event)">

  <ngx-datatable-column name="{{'URL' | translate: {Default:'URL'} }}">
    <ng-template ngx-datatable-cell-template let-row="row">
      {{row.url}}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{'DELETE' | translate: {Default:'Delete'} }}" [maxWidth]="150">
    <ng-template ngx-datatable-cell-template let-row="row">
      <button (click)="deleteWebhook(row)" mat-button><mat-icon>delete</mat-icon> {{'Delete' | translate: {Default:'Delete'} }}</button>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>


<!--
<div *ngIf="webhooks && webhooks == 0">No webhooks</div>
<div *ngIf="webhooks">Some webhooks {{ webhooks }}</div>
-->

<button mat-raised-button color="primary" (click)="createWebhook()">{{'CREATE' | translate: {Default:'Create'} }}</button>