<ng-container
  *ngIf="featuresHelperService.isFeatureAllowed(featureType.CustomerPortal) && portalSettings?.portalEnabled && customerOnboardingDefinition?.inviteCustomerToPortal">
  <mat-card appearance="outlined" class="m-0">
    <mat-card-content>
      <form #customerForm="ngForm" class="customer-form w-100">
        <!-- <h6 class="font-weight-medium" style="font-size: 1rem;"><strong>{{'CUSTOMER_ONBOARDING' | translate}}</strong></h6> -->

        <div fxLayout="row" class="mdc-mb-2">
          <mat-checkbox (change)="onboardChanged($event);" [checked]="onboardCustomer" [(ngModel)]="onboardCustomer"
            class="example-margin w-50" name="onboardCustomer">
            <strong>{{'ONBOARD_CUSTOMER_QUESTION' | translate}}</strong>
          </mat-checkbox>
        </div>
        <p class="mat-text-secondary mdc-mb-2">
          {{'ONBOARDING_DESC_ONE' | translate}} <a href="javascript:void(0)" style="color: var(--color);"
            (click)="showTermsAndConditions()">{{'TERMS_AND_CONDITIONS' | translate}}</a>,
          {{'ONBOARDING_DESC_TWO' | translate}} <a href="javascript:void(0)" style="color: var(--color);"
            (click)="showContracts()">{{'CONTRACTS' | translate}}</a>
          {{'ONBOARDING_DESC_THREE' | translate}}
        </p>

        <div fxLayout="row wrap" fxLayoutGap="1%" fxLayout.gt-sm="row wrap">
          <div fxFlex="49" *ngIf="onboardCustomer && customerOnboardingDefinition.signContract">
            <mat-form-field *ngIf="contractTemplates && contractTemplates.length > 0" appearance="outline"
              class="w-100">
              <mat-label>{{'CHOOSE_CUSTOMER_CONTRACT' | translate}}</mat-label>
              <mat-select [(ngModel)]="onboard.contractTemplateId" (selectionChange)="contractChanged()"
                name="contractTemplateField" #contractTemplateField="ngModel"
                placeholder="{{'SELECT_CONTRACTS' | translate}}">
                <mat-option *ngFor="let contractTemplate of contractTemplates" [value]="contractTemplate.id">
                  {{contractTemplate.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" *ngIf="!onboardCustomer">
          <mat-checkbox [checked]="doNotInviteCustomerToPortal" [(ngModel)]="doNotInviteCustomerToPortal"
            class="example-margin w-50" name="inviteCustomerToPortal">
            <strong>Don't show me this again</strong> (Note: you can turn this ON anytime from the <b>New Customers</b>
            setting menu)
          </mat-checkbox>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</ng-container>