<!-- generate card modal content -->
<mat-toolbar class="pt-1 mat-toolbar helptitle" style="background:#F7F7F7">
  <div fxLayout="row" class="w-100">
    <div fxFlex>
      <h5 class="mb-0">{{'GENERATE_INVOICE' | translate}}</h5>
    </div>
    <div fxFlex></div>
    <div fxFlex="nogrow">
      <button mat-icon-button (click)="close()"><mat-icon>close</mat-icon></button>
    </div>
  </div>
</mat-toolbar>


<div class="mdc-p-3 " style="background:#F7F7F7">
  <div fxLayout="row wrap " fxLayoutGap="2%">
    <div fxFlex class="mdc-py-2">
      <h6>{{'SELECT_APPTS_RANGE' | translate}}</h6>
    </div>
    <div fxFlex="nogrow" class="mdc-py-2">
      <mat-slide-toggle class="ml-1" [(ngModel)]="includeNoShow" [labelPosition]="'before'" (ngModelChange)="loadBoardingBookingsForCreateInvoices()">
        {{'INCLUDE' | translate}} <b>'{{'NO_SHOW' | translate}}'</b>
      </mat-slide-toggle>
    </div>
  </div>

  <div fxLayout="row wrap " fxLayoutGap="2%">
    <div fxFlex="100" fxFlex.gt-sm="39">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'SELECT_DURATION' | translate}}</mat-label>
        <mat-select id="selectedDuration" [(ngModel)]="selectedDuration" (ngModelChange)="selectedDurationChanged($event)">
          <mat-option *ngFor="let duration of durations" [value]="duration.type">{{duration.label}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="59" *ngIf="selectedDuration === 'custom'">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'SELECT_DATE_RANGE' | translate}}</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
          <input matStartDate formControlName="start" placeholder="{{'START_DATE' | translate}}">
          <input matEndDate formControlName="end" placeholder="{{'END_DATE' | translate}}">
        </mat-date-range-input>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">{{'INVALID_START_DATE' | translate}}</mat-error>
        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">{{'INVALID_END_DATE' | translate}}</mat-error>
      </mat-form-field>
    </div>
  </div>

  <p class="text-center">
    <b>{{appointments?.length}} {{'APPOINTMENTS' | translate}}</b>
  </p>

  <mat-card appearance="outlined" class="my-3 mx-0" *ngFor="let pendingBoardingBooking of appointments">
    <mat-card-content>
      <div fxLayout="flex wrap" fxLayoutAlign=" center">
        <div fxFlex="nowrap" class="mr-1">
          <mat-slide-toggle color="accent" [(ngModel)]="pendingBoardingBooking.selected" [labelPosition]="'before'">
          </mat-slide-toggle>
        </div>
        <div fxFlex>
          <h5 class="mb-0">
            {{pendingBoardingBooking?.startDateUtc | localizedDate}} -
            <span *ngFor="let petRunBooking of pendingBoardingBooking?.petRunBookings;let last = last">
              {{petRunBooking?.petRunName}}<span *ngIf="!last">, </span>
            </span>
          </h5>
          <p class="mat-text-secondary mb-0">
            <span *ngFor="let petRunBooking of pendingBoardingBooking?.petRunBookings;let last = last">
              {{petRunBooking?.petRunDetails?.[0]?.customerName}}
              <span *ngFor="let petRunDetail of petRunBooking?.petRunDetails">
                <span>{{petRunDetail?.petName}}({{petRunDetail?.petBreed}}) </span>
                <span *ngIf="petRunDetail?.petFeedingConfig">{{'FOOD' | translate}}</span>
                <span *ngIf="petRunDetail?.petMedicationConfig"><span *ngIf="petRunDetail?.petFeedingConfig">,
                  </span>{{'MEDICATION' | translate}}</span>
              </span>
            </span>

          </p>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <!-- <mat-card class="my-3 mx-0">
    <mat-card-content>
      <div fxLayout="flex wrap" fxLayoutAlign=" center">
        <div fxFlex="nowrap">
          <mat-radio-button color="accent" [checked]="selectedBookingType === 'invoiceCompleted'"
            [value]="selectedBookingType" name="selectedBookingType"
            (change)="selectedBookingTypeChanged($event)"></mat-radio-button>
        </div>
        <div fxFlex>
          <h5 class="mb-0">
            {{boardingBookingsForCreateInvoiceDto?.totalCompletedAmount | currency:data.currencyCode:'symbol'}}
          </h5>
          <p class="mat-text-secondary mb-0">
            Create {{boardingBookingsForCreateInvoiceDto?.completedBoardingBookings?.length}} Invoices only with
            completed appointments
          </p>
        </div>
      </div>
    </mat-card-content>
  </mat-card> -->

  <hr class="my-3">

  <div fxLayout="flex wrap" fxLayoutAlign=" center">
    <div fxFlex="nowrap">
      <button mat-raised-button color="accent" (click)="generateInvoice()">{{'GENERATE_INVOICE' | translate}}</button>
    </div>
    <div fxFlex></div>
  </div>
</div>
<!-- generate card modal content ends -->