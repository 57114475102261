import { Component, Input } from '@angular/core';
import { ShowClashesComponent } from './showClashes.component';
import { MatDialog } from '@angular/material/dialog';
import { EventBusService } from './EventBusService';
import { UntypedFormGroup } from '@angular/forms';
import { ChangeListener } from './changeListener';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { UserAvailabilityService } from '@savvy/user-availability';
import { ContextIdDto } from '@savvy/quickbooks';

@Component({
  selector: 'app-conflict-check',
  templateUrl: 'conflictCheckButton.component.html'
})
export class ConflictCheckButtonComponent {

  @Input() contextIdDto: ContextIdDto;
  @Input() viewContext: any;
  @Input() additionalDataMapDto: any;
  @Input() eventBus: EventBusService;
  @Input() form: UntypedFormGroup;
  @Input() changeListener: ChangeListener;

  checking = false;

  constructor(
    private dialog: MatDialog,
    private notify: FloSnackbarComponent,
    private availabilityApi: UserAvailabilityService
    ) { }

  checkClashes() {
    this.checking = true;
    this.availabilityApi.clashCheck(this.viewContext.entityInstanceId.id,
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType).subscribe(clashResponse => {

      this.checking = false;
      console.log('got clash response ' + clashResponse.clashes.length);

        if (clashResponse.clashes.length > 0) {
          this.showClashes(clashResponse);
        } else {
          this.notify.message = 'No Clashes';
          this.notify.open();
        }
      });
  }

  showClashes(clashResponse) {
    const dialogRef = this.dialog.open(ShowClashesComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        clashResponse
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
