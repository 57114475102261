<mat-toolbar color="primary" class="mdc-mb-1">
    <mat-toolbar-row>
        <h5>Inbox</h5>
        <span class="example-spacer"></span>
        <div>
            <div class="button-group">
                <button color="accent" mat-raised-button value="chat" class="active">Chat</button>
                <button color="accent" mat-raised-button value="email">Email</button>
            </div>
        </div>
    </mat-toolbar-row>
</mat-toolbar>

<!-- No token set up -->
<!-- <app-messages-disabled [contextIdDto]="contextIdDto" *ngIf="showMessagesDisabled"></app-messages-disabled>

<mat-sidenav-container *ngIf="showMessages" class="app-inner mail-container background-none shadow-none" [ngClass]="{ 'side-panel-opened': sidePanelOpened, 'side-panel-closed': !sidePanelOpened }">

    <mat-sidenav #mailnav [mode]="isOver() ? 'over' : 'side'" [opened]="!isOver()" (open)="sidePanelOpened = true" (close)="sidePanelOpened = false" class="mail-sidebar pl-xs pr-xs" [perfectScrollbar]="config" [disabled]="isOver()">
        <mat-nav-list class="messages-nav">
            <a href="javascript:void(0)" mat-list-item *ngFor="let label of labels; let i = index" (click)="getMessages(label.labelId)">
                <mat-icon class="mat-text-muted">{{getIcon(label.labelName)}}</mat-icon>
                <span>{{label.labelName}}</span>
            </a>
        </mat-nav-list>
    </mat-sidenav>
    <mat-toolbar color="primary" fxHide="false" fxHide.gt-sm>
        <button (click)="mailnav.toggle()" mat-icon-button>
            <mat-icon>short_text</mat-icon>
        </button>
        <span class="mr-1 ml-1">{{'MAILBOX' | translate}}</span>
    </mat-toolbar>


    <app-zero-messages *ngIf="showZeroMessages" (newMessageClicked)="createMessage()"></app-zero-messages>


    <div #mailcontent class="main-content" fxLayout="row" fxLayoutAlign="center start" [perfectScrollbar]="config" [disabled]="isOver()">

        <div fxFlex.gt-sm="90" fxFlex.gt-xs="95" fxFlex="100"> 
            -->

<!--<mat-sidenav #newMessagePanel align="end" class="message-panel" mode="over" opened="false">-->
<!--<app-new-message-panel [contextIdDto]="contextIdDto" [visible]="true">-->
<!--</app-new-message-panel>-->
<!--</mat-sidenav>-->

<!-- 
                <div class="messages-list" *ngIf="!noMessages()">
                <mat-accordion [displayMode]="displayMode" [multi]="multi">
                    <mat-expansion-panel #panel [hideToggle]="hideToggle" *ngFor="let message of response.messagesList; let i = index" [class.selected]="message === selectedMessage">


                        <mat-expansion-panel-header (click)="onSelect(message)">
                            <div fxLayout="row" fxLayoutAlign="none center" class="w-100">
                                <img alt="" class="message-icon mr-1 hide-on-open" src="{{message.avatar}}" *ngIf="!message.icon" />
                                <mat-icon [ngClass]="message.iconClass" class="mr-1 hide-on-open" *ngIf="message.icon">
                                    {{message.icon}}
                                </mat-icon>
                                <div class="pr-1 hide-on-open" fxShow="false" fxShow.gt-xs style="min-width: 120px;">
                                    <span>{{message.emailMessage.fromEmail}}</span>
                                </div>
                                <div class="text-ellipsis">
                                    <span>{{message.emailMessage.subject}}</span>
                                </div>
                                <span fxFlex></span>
                                <div class="pl-1 text-xs-right text-ellipsis hide-on-open" fxShow="false" fxShow.gt-sm>
                                    <small class="text-xs-muted">{{ message.sentLocalDateTime | localizedDate: 'fullDate' }}</small>
                                </div>
                            </div>
                        </mat-expansion-panel-header>

                        <mat-card *ngIf="selectedMessage" class="shadow-none">
                            <mat-card-header>
                                <img matCardAvatar *ngIf="!selectedMessage.icon" src="{{selectedMessage.avatar}}" class="ml-0">
                                <mat-icon *ngIf="selectedMessage.icon" matCardAvatar [ngClass]="selectedMessage.iconClass" class="ml-0">{{selectedMessage.icon}}
                                </mat-icon>
                                <mat-card-title>{{selectedMessage.emailMessage.fromEmail}}</mat-card-title>
                                <mat-card-subtitle>{{selectedMessage.sentLocalDateTime | localizedDate: 'fullDate'}}</mat-card-subtitle>
                            </mat-card-header>
                            <mat-card-content class="pl-0">


                                <div [innerHTML]="selectedMessage.emailMessage.body"></div>

                                <app-reply *ngIf="selectedMessage && selectedMessage.id === message.id" [messageId]="message.id" [contextIdDto]="contextIdDto" (cancelClicked)="panel.expanded = false; selectedMessage = null" [selectedMessage]="selectedMessage"></app-reply>


                            </mat-card-content>
                        </mat-card>
                        <mat-action-row *ngIf="selectedMessage">
                            <button mat-raised-button mat-button-sm color="accent" class="mx-xs" (onclick)="deleteMessage(selectedMessage)">{{'DELETE' | translate}}
                            </button>
                            <button mat-raised-button mat-button-sm class="mx-xs">{{'FORWARD' | translate}}</button>
                        </mat-action-row>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>


    </div>
</mat-sidenav-container>

<button mat-fab color="accent" class="mat-fab-bottom-right" [disabled]="trialExpiredService.isTrialExpired()" (click)="createMessage()" style="z-index: 9;" *ngIf="!noMessages()">
    <mat-icon class="mat-24">create</mat-icon>
</button> -->

<div class="container-fluid">
    <div fxLayout="row wrap" fxLayoutGap="16px">
        <div fxFlex="100" fxFlex.gt-md="30" fxFlex.gt-lg="25">
            <app-chat-list *ngIf="response?.messagesList" [messagesList]="response?.messagesList" (messageSeletionChanged)="messageSelected($event)"></app-chat-list>
        </div>
        <div fxFlex="100" fxFlex.gt-md>
            <app-chat-box *ngIf="contextIdDto && selectedMessage" [contextIdDto]="contextIdDto" [selectedMessage]="selectedMessage"></app-chat-box>
        </div>
        <div fxFlex="100" fxFlex.gt-md="30" fxFlex.gt-lg="25">
            <!-- <mat-card class="mdc-mb-1">
                <mat-card-content>
                    <div class="text-center mdc-mb-1">
                        <div class="avatar avatar-100 border-2 rounded-circle coverimg mdc-mb-1 mx-auto"
                            style="background-image: url('assets/images/customer-profile.png');"></div>
                        <h2><a href="" class="style-none mat-text-accent">Amit Sinha</a></h2>

                        <mat-chip-list>
                            <mat-chip color="accent" removable="true">VIP <mat-icon
                                    matChipRemove>cancel</mat-icon></mat-chip>
                            <mat-chip color="accent" removable="true">MARK <mat-icon
                                    matChipRemove>cancel</mat-icon></mat-chip>
                        </mat-chip-list>
                    </div>

                    <div fxLayout="row wrap" fxLayoutGap="16px" fxLayoutAlign=" center" class="mdc-mb-1">
                        <div fxFlex class="text-end">
                            <h1 class="my-1 fw-bold">$ 1258.00</h1>
                            <p class="mat-text-secondary small mb-0">Total paid amount</p>
                        </div>
                        <div fxFlex>
                            <h1 class="my-1 mat-text-red fw-bold">$ 122.00</h1>
                            <p class="mat-text-secondary small mb-0">Total uppaid amount</p>
                        </div>
                    </div>

                    <div fxLayout="row wrap" fxLayoutGap="16px" fxLayoutAlign=" center" class="text-center mdc-mb-1">
                        <div fxFlex>
                            <h2 class="my-1">2</h2>
                            <p class="mat-text-secondary small mb-0">Upcoming</p>
                        </div>
                        <div fxFlex>
                            <h2 class="my-1">8</h2>
                            <p class="mat-text-secondary small mb-0">Completed</p>
                        </div>
                        <div fxFlex>
                            <h2 class="my-1">1</h2>
                            <p class="mat-text-secondary small mb-0">Cancelled</p>
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutGap="16px" fxLayoutAlign=" center" class="text-center">
                        <div fxFlex>
                            <h2 class="my-1">12</h2>
                            <p class="mat-text-secondary small mb-0">Total Appts</p>
                        </div>
                        <div fxFlex>
                            <h2 class="my-1 mat-text-red">1</h2>
                            <p class="mat-text-secondary small mb-0">No Show</p>
                        </div>
                        <div fxFlex>
                            <h2 class="my-1">4.5</h2>
                            <p class="mat-text-secondary small mb-0">Avg. Review</p>
                        </div>
                    </div>

                </mat-card-content>
            </mat-card>

            <mat-accordion class="multiple" multi>

                <mat-expansion-panel [expanded]="true" class="mdc-mb-1">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon class="mat-text-theme mr-5px vm">account_circle</mat-icon> Customer Information
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutGap="16px">
                        <div fxFlex="20">
                            <p class='mat-text-secondary'>Name</p>
                        </div>
                        <div fxFlex>
                            <p class=""><a href="" class="style-none mat-text-accent">Amit Sinha</a></p>
                        </div>
                    </div>

                    <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutGap="16px">
                        <div fxFlex="20">
                            <p class='mat-text-secondary'>Phone</p>
                        </div>
                        <div fxFlex>
                            <p class="">+91 94558 225840</p>
                        </div>
                    </div>
                    <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutGap="16px">
                        <div fxFlex="20">
                            <p class='mat-text-secondary'>Email</p>
                        </div>
                        <div fxFlex>
                            <p class="">amitsinha1586@gmailtest.com</p>
                        </div>
                    </div>
                    <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutGap="16px">
                        <div fxFlex="20">
                            <p class='mat-text-secondary'>Address</p>
                        </div>
                        <div fxFlex>
                            <p class="">5 West View Lane Lutterworth LE174FU, Leicestershire.</p>
                        </div>
                    </div>
                    <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutGap="16px">
                        <div fxFlex="20">
                            <p class='mat-text-secondary'>First Seen</p>
                        </div>
                        <div fxFlex>
                            <p class="">12 October 2021</p>
                        </div>
                    </div>
                    <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutGap="16px">
                        <div fxFlex="20">
                            <p class='mat-text-secondary'>Referral</p>
                        </div>
                        <div fxFlex>
                            <p class="">Referral</p>
                        </div>
                    </div>


                </mat-expansion-panel>

                <mat-expansion-panel [expanded]="false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon class="mat-text-theme mr-5px vm">pets</mat-icon> Pets
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <a href="" fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutGap="16px" class="style-none">
                        <div fxFlex="nogrow">
                            <div class="avatar avatar-50 border-2 rounded-circle coverimg mdc-mb-1 mx-auto"
                                style="background-image: url('assets/images/customer-profile.png');"></div>
                        </div>
                        <div fxFlex>
                            <h3 class="">Suzy</h3>
                            <p class="mat-text-secondary">Pomeranian</p>
                            <mat-chip-list>
                                <mat-chip color="accent" removable="true">VIP <mat-icon
                                        matChipRemove>cancel</mat-icon></mat-chip>
                                <mat-chip color="accent" removable="true">MARK <mat-icon
                                        matChipRemove>cancel</mat-icon></mat-chip>
                            </mat-chip-list>
                        </div>
                    </a>

                </mat-expansion-panel>
            </mat-accordion>

            <div class="text-center">
                <button mat-button color="accent"><mat-icon class="mr-5px vm">account_circle</mat-icon> View customer's
                    full profile</button>
            </div> -->
        </div>
    </div>
</div>