<form [formGroup]="formGroup">

    <mat-card appearance="outlined" class="mdc-mb-1">
        <mat-card-content>
            <mat-radio-group formControlName="allCustomers">
                <mat-radio-button class="example-radio-button px-8" [value]="true">
                    <div class="text-bold">{{'SEND_TO_ALL' | translate}}</div>
                </mat-radio-button>

                <mat-divider></mat-divider>

                <mat-radio-button class="example-radio-button px-8" [value]="false">
                    <div class="text-bold">{{'BY_FLAGS' | translate}}</div>
                </mat-radio-button>

                <div fxLayout="row wrap" fxLayoutGap="2%">
                    <div fxFlex="100" fxFlex.gt-sm="49" class="mdc-py-2" *ngIf="!fg?.value?.allCustomers">
                        <mat-form-field style="width: 100%;" class="mb-0" appearance="outline">
                            <mat-label>{{'INCLUDE_FLAGS' | translate}}</mat-label>
                            <mat-select formControlName="flags" multiple (selectionChange)="includeFlagsSelectionChange($event)">
                                <mat-select-trigger>
                                    <mat-chip-listbox>
                                        <mat-chip-option *ngFor="let flag of selectedFlagDefs" [color]="flag.colour" [removable]="true" (removed)="onFlagRemovedFromSelection(flag)" selected>
                                            {{ flag.name }}
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip-option>
                                    </mat-chip-listbox>
                                </mat-select-trigger>
                                <mat-option *ngFor="let flag of includeFlagDefs" [value]="flag.id">
                                    {{flag.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxFlex="100" fxFlex.gt-sm="49" class="mdc-py-2" *ngIf="!fg?.value?.allCustomers">
                        <mat-form-field style="width: 100%;" class="mb-0" appearance="outline">
                            <mat-label>{{'EXCLUDE_FLAGS' | translate}}</mat-label>
                            <mat-select formControlName="excludeFlags" multiple (selectionChange)="excludeFlagsSelectionChange($event)">
                                <mat-select-trigger>
                                    <mat-chip-listbox>
                                        <mat-chip-option *ngFor="let flag of selectedExcludeFlagDefs" [color]="flag.colour" [removable]="true" (removed)="onExcludeFlagRemovedFromSelection(flag)" selected>
                                            {{ flag.name }}
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip-option>
                                    </mat-chip-listbox>
                                </mat-select-trigger>
                                <mat-option *ngFor="let flag of excludeFlagDefs" [value]="flag.id">
                                    {{flag.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </mat-radio-group>
        </mat-card-content>
    </mat-card>

    <mat-card appearance="outlined">
        <mat-card-content>
            <section class="configuration-toggle-section">
                <mat-slide-toggle labelPosition="after" formControlName="marketing">{{'MARKETING' | translate}}
                </mat-slide-toggle>
            </section>
        </mat-card-content>
    </mat-card>
</form>