<div fxLayout="row wrap" class="mdc-px-3 ">
  <div fxFlex="100" fxFlex.gt-sm="50">
    <mat-form-field appearance="outline" class="mt-0 w-100">
      <mat-label>{{'SELECT_PET' | translate}}</mat-label>
      <mat-chip-grid #chipList aria-label="Pet selection">
        <mat-chip-row *ngFor="let pet of selectedPetChips; let rowIndex = index" (removed)="remove()">
          <span [innerHTML]="pet | translate"></span>
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
        <input [placeholder]="placeholder" id="appointment-pet-selector" [hidden]="!multiple && selectedPetChips.length"
          #petInput [formControl]="searchControl" [matAutocomplete]="petAuto" [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
      </mat-chip-grid>
      <mat-autocomplete #petAuto="matAutocomplete" (optionSelected)="petSelectionChanged($event)"
        autoActiveFirstOption="false">
        <mat-option [value]="''">
          <button *ngIf="showNewPet" type="button" mat-raised-button color="accent" (click)="createPetFromList()"
            matTooltip="{{'NEW_PET' | translate}}" matTooltipPosition="above">
            <mat-icon class="vm d-none d-sm-inline-block">pets</mat-icon>
            {{'NEW_PET' | translate}}
          </button>
          <!-- <button type="button" mat-raised-button color="accent" (click)="createPetFromList()">
            Create Pet
          </button> -->
        </mat-option>
        <mat-option class="loading-inner" disabled *ngIf="loadingInner">
          <mat-spinner diameter="35"></mat-spinner>
        </mat-option>
        <mat-option disabled *ngIf="searchResults?.length === 0 && !loadingInner && searchControl.value">
          {{'NO_DATA_FOUND' | translate}}
        </mat-option>
        <mat-option *ngFor="let option of filteredSearchResults | async" [value]="option"
          [innerHTML]="option.label | translate">
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <div fxFlex="100" fxFlex.gt-sm="50" class="pl-1 pt-1 pb-1 pt-md-1 pl-md-2 pb-md-0" *ngIf="selectedPet?.petId">
    <app-pets-flags-panel [contextIdDto]="contextIdDto" [flagIds]="selectedPet.flags" [disableCreate]="true">
    </app-pets-flags-panel>
  </div>
</div>

<div fxLayout="row" class="mdc-mb-1 mdc-px-3" *ngIf="selectedPet?.petId && selectedPet?.petFeedingConfig">
  <app-pet-feeding-config [taxDefs]="taxDefs" class="w-100"
    [(petFeedingConfig)]="selectedPet.petFeedingConfig"></app-pet-feeding-config>
</div>
<div fxLayout="row" class="mdc-mb-1 mdc-px-3" *ngIf="selectedPet?.petId && selectedPet?.petMedicationConfig">
  <app-pet-medication-config [taxDefs]="taxDefs" class="w-100"
    [(petMedicationConfig)]="selectedPet.petMedicationConfig"></app-pet-medication-config>
</div>
<div fxLayout="row" class="mdc-mb-1 mdc-px-3" *ngIf="selectedPet?.petId && selectedPet?.petServiceBookings">
  <app-pet-boarding-services-selector class="display-block w-100" #petBoardingServicesSelectorComponent
    [contextIdDto]="contextIdDto" [selectedServices]="selectedPet.petServiceBookings"
    (selectedServicesChange)="selectedServicesChange($event, selectedPet)" [pet]="selectedPet" [allowedToRemove]="true"
    [disabled]="false">
  </app-pet-boarding-services-selector>
</div>


<div fxLayout="row" fxLayoutAlign=" center" class="mdc-mb-1 px-2" *ngIf="selectedPet?.petId">
  <div fxFlex>
    <button *ngIf="!selectedPet?.petFeedingConfig" type="button" mat-button color="accent" class=""
      matTooltip="{{'ADD_FEEDING_CYCLE' | translate}}" (click)="addFeeding()">
      <mat-icon class="vm d-none d-sm-inline-block">add_circle_outline</mat-icon>
      {{'FEEDING_CYCLE' | translate}}
    </button>
    <button *ngIf="!selectedPet?.petMedicationConfig" type="button" mat-button color="accent" class="mx-1"
      matTooltip="{{'ADD_MEDICATION_SERVICE' | translate}}" (click)="addMedication()">
      <mat-icon class="vm d-none d-sm-inline-block">add_circle_outline</mat-icon>
      {{'MEDICATION_SERVICE' | translate}}
    </button>
    <button type="button" mat-button color="accent" class="mr-1" matTooltip="{{'ADD_ANOTHER_ADD_ON' | translate}}"
      (click)="addServices()">
      <mat-icon class="vm d-none d-sm-inline-block">add_circle_outline</mat-icon>
      {{'ADD_ON_SERVICES' | translate}}
    </button>
  </div>
  <!-- <div fxFlex="nogrow" *ngIf="showRemovePet">
    <button type="button" mat-raised-button (click)="removePet()" matTooltip="Add another pet to this run"
      matTooltipPosition="above" class="my-0">
      <mat-icon class="vm d-none d-sm-inline-block">remove</mat-icon> Remove Pet
    </button>
  </div> -->

  <!-- <div fxFlex="nogrow">
    <span class="vm">
      <span class="px-1 mat-text-accent vm" (click)="alert();" matTooltip="Add this Pet">
        <b class="vm">Add Bilimaar</b>
      </span>
      <span class="mat-text-secondary vm">from same customer</span>
    </span>
  </div> -->
  <!-- <button type="button" mat-button color="accent" class="ml-1" matTooltip="Add Walking Service"
    (click)="addService('walking')">
    <mat-icon class="vm d-none d-sm-inline-block">add_circle_outline</mat-icon> Walking Service
  </button> -->
</div>


<div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign=" center" class="pt-1 pb-1 mdc-px-3 border-top">
  <div fxFlex>
    <app-customer-stats *ngIf="selectedPet?.petId" #customerStatsComponent [contextIdDto]="contextIdDto"
      [customerId]="customerId" [customerName]="selectedPet?.customerName" [showCustomerNameAlways]="true">
    </app-customer-stats>
  </div>
  <!-- <div fxFlex="nogrow" *ngIf="showAddPet">
    <button type="button" mat-raised-button (click)="addAnotherPet()" matTooltip="Add another pet to this run"
      matTooltipPosition="above" class="my-0">
      <mat-icon class="vm d-none d-sm-inline-block">pets</mat-icon> Add Pet
    </button>
  </div> -->
</div>

<div *ngIf="createPet" fxLayout="row" fxLayoutGap="16px" fxLayoutAlign=" center"
  class="mdc-mb-1 mdc-px-3 pt-1 border-top">
  <app-create-pet-existing-customer [customerId]="customerId" [customerName]="selectedPet?.customerName"
    class="mdc-mb-1 display-block" [contextIdDto]="contextIdDto" (addNewCustomerEvent)="addNewCustomer($event)"
    (petCreated)="petCreated($event)" (cancelEvent)="createPet = false">
  </app-create-pet-existing-customer>
</div>
