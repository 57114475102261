<h1 mat-dialog-title>{{'DELETE_FLAG' | translate}} {{ data.flag.name }}?</h1>

<div mat-dialog-content>
  <p>
    {{'FLAG_DELETE_CONFIRM' | translate}} '{{ data.flag.name }}'?
    {{'FLAG_DELETE_PERM' | translate}} {{ plural }}.
    {{'CHOOSE_ARCHIVE_FLAG' | translate}} {{ plural }}
    {{'RESTORE_ARCHIVED_FLAGS' | translate}}
  </p>
  <br>
  <button mat-raised-button [mat-dialog-close]="null" class="mr-5px">{{'CANCEL' | translate}}</button>
  <button mat-raised-button color="accent" [mat-dialog-close]="'archive'" class="mr-5px">{{'ARCHIVE' | translate}}</button>
  <button mat-raised-button color="warn" [mat-dialog-close]="'delete'">{{'DELETE' | translate}}</button>
</div>