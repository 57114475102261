import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { UiAuthService } from '../auth/ui-auth.service';
import { UntypedFormGroup } from '@angular/forms';
import { ElementControlService } from './ElementControlService';
import { ChangeListener } from './changeListener';
import { EventBusService } from './EventBusService';
import { NavChangeService } from './NavChangeService';
import { Router } from '@angular/router';
import { ViewcompService, LoadEiViewResponse } from '@savvy/view-composite';
import { EventType, UiEvent } from '../event/UiEvent';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PhoneNumberHelperService } from '../shared/services/phone-number-helper.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ContextIdDto } from '@savvy/datalist';

@Component({
  selector: 'app-view-summary-entity',
  templateUrl: 'viewEntitySummary.component.html'
})
export class ViewEntitySummaryComponent implements OnInit, OnChanges, OnDestroy {

  @Input() entityInstanceId: string;
  @Input() eventBus: EventBusService;
  @Input() contextIdDto: ContextIdDto;

  @Output() changeOccurred = new EventEmitter();

  loadEiViewResponse: LoadEiViewResponse;
  changeListener = new ChangeListener();
  destroy$ = new Subject();

  public form: UntypedFormGroup; // our form model

  sub: any;
  active: boolean;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data,
    public auth: UiAuthService,
    private router: Router,
    private ecs: ElementControlService,
    private viewcompService: ViewcompService,
    private deviceService: DeviceDetectorService,
    private navChange: NavChangeService,
    @Optional() public dialogRef: MatDialogRef<ViewEntitySummaryComponent>,
    private sharedService: PhoneNumberHelperService) {

  }

  ngOnInit(): void {
    if (this.data && this.data.contextIdDto && this.data.eventBus && this.data.entityInstanceId) {
      this.contextIdDto = this.data.contextIdDto;
      this.eventBus = this.data.eventBus;
      this.entityInstanceId = this.data.entityInstanceId;
      this.setEntityInstanceId(this.entityInstanceId);
    }

    this.eventBus.eventAdded$.subscribe(event => this.onEvent(event));

    this.changeListener.eventAdded$.subscribe(event => {
      this.changeOccurred.emit('change');
    });

    this.closeModalSub();
  }

  loadEntity() {
    console.log('load entity called in viewEntity');
    if (this.deviceService.isMobile()) {
      this.viewcompService.loadEiMobileSummaryView(this.entityInstanceId,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType)
        .pipe(takeUntil(this.destroy$)).subscribe(
          response => {
            console.log('layout type is ' + response.viewContextDto.layoutType);
            console.log('Calling display from ngOnInit, loadProductsResponse is ' + response);
            this.display(response);
          }, err => {
            console.log('error while loadEntity', err);
          }
        );
    } else {
      this.viewcompService.loadEiSummaryView(this.entityInstanceId,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType)
        .pipe(takeUntil(this.destroy$)).subscribe(
          response => {
            console.log('layout type is ' + response.viewContextDto.layoutType);
            console.log('Calling display from ngOnInit, loadProductsResponse is ' + response);
            this.display(response);
          }, err => {
            console.log('error while loadEntity', err);
          }
        );
    }
  }


  public setEntityInstanceId(entityInstanceId: string) {
    this.entityInstanceId = entityInstanceId;
    this.loadEntity();
  }

  /**
   * So this will get called when the teasers are changed (for example)
   * but it will also get called when the label
   *
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...
    // console.log('change detected inside view entity');
    for (const propName in changes) {
      // const chng = changes[propName];
      // const cur = JSON.stringify(chng.currentValue);
      // const prev = JSON.stringify(chng.previousValue);
      // console.log('propName is ' + propName);
      // console.log('cur is ' + cur);
      // console.log('prev is ' + prev);

      if (propName === 'entityInstanceId') {
        // if (cur !== prev) {
        // console.log('calling load entity due to change detection');
        this.loadEntity();
        // }
      }
    }
  }

  display(loadEiViewResponse: LoadEiViewResponse) {
    console.log('displaying entity to display');
    this.active = false;
    this.loadEiViewResponse = loadEiViewResponse;
    console.log('creating form');
    this.createForm();
    console.log('created form, additionalDataMapDto:' + loadEiViewResponse.additionalDataMapDto);
    this.active = true;
    this.navChange.add('viewEntity:' +
      loadEiViewResponse.viewContextDto.addEditEntityInstanceContextDto.entityDefinitionDto.entityDefinitionId.id);
  }

  createForm() {
    if (this.loadEiViewResponse.viewRootElementDto) {
      // console.log('Building form in view entity with panel ' + this.loadEiViewResponse.panelDto);
      this.form = this.ecs.toFormGroup(this.loadEiViewResponse.viewRootElementDto,
        // @ts-ignore
        this.loadEiViewResponse.viewContextDto.addEditEntityInstanceContextDto.entityDefinitionDto);
    } else {
      // console.log('Empty panel so assuming empty form');
      this.form = this.ecs.emptyFormGroup();
    }
  }

  cancelIt() {
    console.log('here in cancel in view entity');
    this.eventBus.addEvent(EventType.CANCEL_VIEW_ENTITY);
  }

  private onEvent(event: UiEvent) {
    // console.log('view entity summary got event ' + event);
    //       this.eventBus.addInvoiceEvent(EventType.INVOICE_SERVICE_UPDATED, this.invoice.invoiceId);
  }

  closeModalSub() {
    this.sharedService.closeEntityModalSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: boolean) => {
        if (res && this.dialogRef) {
          this.dialogRef.close();
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}

