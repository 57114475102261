import {NgModel} from '@angular/forms';
import {Directive, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';

@Directive({
  selector: '[ngModel][appInputTrim]',
  providers: [NgModel],
})
export class TrimDirective implements OnInit, OnDestroy {
  subscription: Subscription;
  constructor(private model: NgModel) {
  }

  ngOnInit() {
    this.subscription = this.model.control.valueChanges.subscribe(value => {
      if (!value) {
        return;
      }
      const trimmed = value.toString().trim();
      if (trimmed !== value) {
        this.model.control.setValue(trimmed, {emitEvent: false});
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
