<div fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px grid">
    <div [fxFlex]="maxWidth">
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>{{label | translate}}</mat-label>
        <input matInput #searchInput="matInput" placeholder="{{'START_TYPING' | translate}} {{label}}" [matAutocomplete]="auto"
          autofocus (keyup)="onKey($event)" autocomplete="off" type="text" [readonly]="readonly" [required]="required">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)"
          (optionSelected)="handler($event)">
          <mat-option disabled *ngIf="customers && !customers.length" value="">
            {{ label + 'NOT_FOUND' | translate}}!
          </mat-option>
          <mat-option *ngFor="let tuple of customers" [value]="tuple.id">
            {{ tuple.fullName }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>

</div>
