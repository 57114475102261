import {environment} from '../../../environments/environment';

export abstract class ConfService {

  constructor() { }

  public static apiUrl() {
    let API_URL = environment.API_URL;

    return API_URL;
    /*
    const API_BASE_URL = 'api.itsallsavvy.com';
    const domain = 'savvypetspa';
    switch (window.location.host) {
      case `app.${domain}.com`:
        // PROD
        API_URL = `${window.location.protocol}//${API_BASE_URL}`;
        break;
      case `blue.${domain}.com`:
        // PROD BLUE
        API_URL = `${window.location.protocol}//${API_BASE_URL}`;
        break;
      case `green.${domain}.com`:
        // GREEN
        API_URL = `${window.location.protocol}//green${API_BASE_URL}`;
        break;
      case `dev.${domain}.com`:
        // DEV
        API_URL = `${window.location.protocol}//dev${API_BASE_URL}`;
        break;
      default:
        API_URL = environment.API_URL;
        break;
    }
    return API_URL;*/
  }

  public static version() {
    return environment.VERSION;
  }

  public static production(): boolean {
    return environment.production;
  }
}
