import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PetFeedingConfig } from '@savvy/pet-stay';
import { Tax } from '@savvy/tax';
import { CreateBoardingBookingV2SyncService } from '../../create-boarding-booking-v2-sync.service';

@Component({
  selector: 'app-pet-feeding-config',
  templateUrl: './pet-feeding-config.component.html',
  styleUrls: ['./pet-feeding-config.component.scss']
})
export class PetFeedingConfigComponent implements OnInit {
  @Input() taxDefs: Tax[] = [];
  @Output() petFeedingConfigChange: EventEmitter<PetFeedingConfig> = new EventEmitter();

  @Input() petFeedingConfig: PetFeedingConfig | undefined = {
    howManyTimesADay: 2
  } as PetFeedingConfig;

  constructor(
    public createBoardingBookingV2SyncService: CreateBoardingBookingV2SyncService
  ) { }

  ngOnInit() {
  }

  updateUnitPrice(config: PetFeedingConfig) {
    this.updateQty(1, config);
  }

  updateQty(qty, config: PetFeedingConfig) {
    config.subTotal = Number(config.price) * qty;
    this.createBoardingBookingV2SyncService.updateGrossPriceForFeeding(config);
  }

  compareTax(o1: string, o2: string): boolean {
    if (o1 && o2) {
      return o1 === o2;
    }
    return false;
  }

  remove() {
    this.petFeedingConfig = undefined;
    this.petFeedingConfigChange.emit(undefined);
  }

}
