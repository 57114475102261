import { Routes } from '@angular/router';
import { ListMessagesPageComponent } from './listMessagesPage.component';
import { SentMessagesPageComponent } from './sentMessagesPage.component';
import { InboxComponent } from './inbox.component';

export const MessagingRoutes: Routes = [
  {
    path: '',
    children: [
      { path: 'listMessages/:contextId/:contextIdType', component: ListMessagesPageComponent },
      { path: 'sentMessages/:contextId/:contextIdType', component: SentMessagesPageComponent },
      { path: 'inbox/:contextId/:contextIdType', component: InboxComponent },
      { path: 'inbox/:messageOwnerId/:contextId/:contextIdType', component: InboxComponent }, // messageOwnerId : customerId/studentId/userId
    ]
  }
];
