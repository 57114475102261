<mat-card appearance="outlined" class="panel new-card" *ngIf="checkInForms && checkInForms.length > 0">
  <mat-card-title>
    <div fxLayout="row">
      <div fxFlex="80">
        <p class="mt-xs mb-xs">{{'Check-In Forms' | translate}}</p>
      </div>
      <div fxFlex="20">
        <!--button mat-mini-fab color="accent" [disabled]="trialExpiredService.isTrialExpired()" (click)="create()">
          <mat-icon class="mat-24">add</mat-icon>
        </button-->
      </div>
    </div>
  </mat-card-title>

  <mat-card-content>
    <mat-list>
      <mat-list-item *ngFor="let checkInForm of checkInForms">
        <p matLine (click)="formSelected(checkInForm)">{{checkInForm.name}}</p>
        <mat-divider *ngIf="checkInForms.length > 1"></mat-divider>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card>

<mat-card appearance="outlined" class="panel new-card" *ngIf="(!checkInForms || checkInForms.length === 0)">

  <mat-card-content>
    <div class="flexbox-parent">
      <div fxLayout="row" fxLayoutAlign="center center">
        <div style="text-align:center;">
          <app-zero-image name="Check-In Forms"></app-zero-image><br>
          <h5 class="ma-0">{{'This Customer does not have any Check-In Forms' | translate}}</h5><br>
          <!-- *ngIf="listEntityListDto.canCreate" -->
          <!--button mat-raised-button color="accent" [disabled]="trialExpiredService.isTrialExpired()"
                  (click)="create()">{{'Create Check-In Form' | translate}}</button-->
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>