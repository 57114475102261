<mat-toolbar color="primary">
  <mat-toolbar-row>
    {{'NOTIFICATIONS' | translate}}

    <!-- This fills the remaining space of the current row -->
    <span class="fill-space"></span>
    <button mat-icon-button aria-label="Close" (click)="cancel()"
      *ngIf="(deviceService.isMobile() || deviceService.isTablet())">
      <mat-icon class="full-screen-icon">close</mat-icon>
    </button>
    <button mat-raised-button color="accent" class="ml-xs" (click)="userNotificationSettings()">
      <mat-icon>tune</mat-icon> {{'SETTINGS' | translate}}
    </button>

  </mat-toolbar-row>
</mat-toolbar>

<mat-button-toggle-group class="small-button-group" name="fontStyle" aria-label="Font Style"
  (change)="loadNotificationsFiltered()" [(ngModel)]="periodToLoad">
  <mat-button-toggle value="today">{{'TODAY' | translate}}</mat-button-toggle>
  <mat-button-toggle value="yesterday">{{'YESTERDAY' | translate}}</mat-button-toggle>
  <mat-button-toggle value="lastWeek">{{'LAST_WEEK' | translate}}</mat-button-toggle>
  <div class="checkboxshowread border-left">
    <mat-checkbox (change)="loadNotificationsFiltered()" [(ngModel)]="includeRead" class="label-mb-0">{{'SHOW_READ' |
      translate}}
    </mat-checkbox>
  </div>
</mat-button-toggle-group>

<mat-list class="notificationlist mdc-mb-1">
  <mat-list-item *ngFor="let notification of notifications">
    <div>
      <div fxLayout="row" fxLayoutGap="16px">
        <div fxFlex="nogrow">
          <mat-icon *ngIf="!notification.icon" mat-list-icon class="mat-text-primary">people</mat-icon>
          <mat-icon *ngIf="notification.icon" mat-list-icon class="mat-text-primary">{{ notification.icon }}</mat-icon>
        </div>
        <div fxFlex>
          <div mat-line style="white-space:normal" (click)="showNotification(notification)">{{notification.header |
            translate}}</div>
          <div mat-line class="text-normal-wrap">
            <p class="my-1" (click)="showNotification(notification)">{{notification.message | translate}}</p>

            <div
              *ngIf="notification.notificationType === notificationTypeEnum?.SmsReplied && notification?.showTextbox && !notification?.replyDone"
              style="width: 100%;">
              <textarea style="width: 100%;" [(ngModel)]="notification.replyMesssage"></textarea>
            </div>
            <div fxLayout="row wrap">
              <div fxFlex>
                <p class="mat-text-secondary small mb-0">
                  <span>
                    {{notification.timeOfNotification | localizedDate: 'mediumDate'}}
                  </span>
                </p>
              </div>
              <div fxFlex="nogrow">
                <button mat-button color="accent" class="px-1"
                  (click)="markAsRead(notification); $event.stopImmediatePropagation()">
                  <mat-icon class="mr-5px icon-18 vm">done</mat-icon>
                  <span *ngIf="notification.notificationType !== notificationTypeEnum?.System && notification.notificationType !== notificationTypeEnum?.ReleaseNotes">{{'MARK_AS_READ' |
                    translate}}</span>
                  <span *ngIf="notification.notificationType === notificationTypeEnum?.System || notification.notificationType === notificationTypeEnum?.ReleaseNotes">{{'READ_IT' |
                    translate}}</span>
                </button>
                <app-onboarding-notification
                  *ngIf="notification.notificationType === notificationTypeEnum?.CustomerOnboarded"
                  [contextIdDto]="contextIdDto" [notification]="notification" />
                <app-contract-signed-notification
                  *ngIf="notification.notificationType === notificationTypeEnum?.ContractSigned"
                  [contextIdDto]="contextIdDto" [notification]="notification" />
                <app-release-notes-notification
                  *ngIf="notification.notificationType === notificationTypeEnum?.ReleaseNotes"
                  [contextIdDto]="contextIdDto" [notification]="notification" />
                <app-check-in-form-notification
                  *ngIf="notification.notificationType === notificationTypeEnum?.CheckInFormSubmission"
                  [contextIdDto]="contextIdDto" [notification]="notification" />
                <app-contact-form-notification
                  *ngIf="notification.notificationType === notificationTypeEnum?.ContactFormSubmission"
                  [contextIdDto]="contextIdDto" [notification]="notification" />
                <app-sms-reply-notification *ngIf="notification.notificationType === notificationTypeEnum?.SmsReplied"
                  [contextIdDto]="contextIdDto" [notification]="notification" />

                <app-savvy-message-notification
                  *ngIf="notification.notificationType === notificationTypeEnum?.SavvyMessageReceived"
                  [contextIdDto]="contextIdDto" [notification]="notification" />

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-list-item>
</mat-list>

<div class="mdc-p-3 text-center">
  <div *ngIf="notifications && notifications.length > 0 && showMarkAll">
    <button mat-raised-button color="accent" (click)="deleteAll()">{{'MARK_ALL_AS_READ' | translate}}</button>
  </div>

  <div *ngIf="notifications && notifications.length === 0">
    <div fxLayout="row" fxLayoutAlign="center center" style="height: 60vh;  ">
      <div style="text-align:center;">
        <div class="avatar avatar-100 circle border-3 mb-2 d-inline-block">
          <mat-icon class="h1 h-auto w-auto">notifications</mat-icon>
        </div>
        <br>
        <h6>{{'NO_NOTIFS' | translate}}</h6>
      </div>
    </div>
  </div>
</div>
