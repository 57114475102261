<mat-card appearance="outlined" class="mdc-mb-1">

  <mat-card-header class="mdc-mb-1">
    <div fxLayout="row wrap" class="w-100">
      <div fxFlex>
        <h6 class="mb-0 ">{{'MESSAGES' | translate}}</h6>
      </div>
      <div fxFlex="nogrow">
        <button mat-button color="accent" (click)="sendMessage()">
          <span class="vm">{{'SEND_NEW' | translate}}</span>
          <mat-icon>send</mat-icon>
        </button>
      </div>

    </div>
  </mat-card-header>
  <!-- <hr> -->
  <mat-card-content class="px-0">

    <ng-container *ngIf="messageList?.length; else elseBlockMessages">
      <div class="search-results" infiniteScroll [infiniteScrollDistance]="scrollDistance" [infiniteScrollUpDistance]="scrollUpDistance" [scrollWindow]="false" [infiniteScrollThrottle]="throttle" (scrolled)="onScrollDown($event)">
        <mat-list #messages class="mdc-mb-1">
          <ng-container *ngFor="let message of messageList">
            <mat-list-item *ngIf="message.type === typeEnum.Sms" class="message-list-item">

              <div fxLayout="row wrap" fxLayoutAlign=" center" fxLayoutGap="10px" fxLayoutGap.gt-sm="16px" class="mdc-py-2">
                <div fxFlex="50px">

                  <mat-menu #smsDetails="matMenu">
                    <div class="mdc-p-3" *ngIf="message.smsMessage">
                      <h6>{{"SENT_TO" | translate}}{{message.smsMessage?.toNumber?.msidn}} </h6>
                      <p class="mat-text-secondary" [innerHTML]="message.smsMessage.textMessage"></p>
                    </div>
                  </mat-menu>
                  <div class="avatar avatar-50 circle border-3" *ngIf="message.deliveryStatus === deliveryStatuses.Sent || message.deliveryStatus === deliveryStatuses.Delivered" [matMenuTriggerFor]="smsDetails">
                    <mat-icon>mark_unread_chat_alt</mat-icon>
                  </div>

                  <div class="avatar avatar-50 circle border-3" *ngIf="message.deliveryStatus === deliveryStatuses.Received" [matMenuTriggerFor]="smsDetails">
                    <mat-icon>mark_chat_read</mat-icon>
                  </div>
                  <div class="avatar avatar-50 circle border-3" *ngIf="message.deliveryStatus !== deliveryStatuses.Sent && message.deliveryStatus !== deliveryStatuses.Delivered && message.deliveryStatus !== deliveryStatuses.Received">
                    <mat-icon>sms_failed</mat-icon>
                  </div>
                </div>
                <div fxFlex="74" fxFlex.gt-sm="80">
                  <h6 class="mb-0" [matMenuTriggerFor]="smsDetails"><strong>{{message.sentDateLocal | localizedDate:'shortDate'}}, {{message.sentTimeLocal | localizedTime:'shortTime'}}</strong> (SMS)</h6>
                  <p [matMenuTriggerFor]="smsDetails" class="small mat-text-secondary">{{message?.smsMessage?.textMessage}}</p>
                </div>
              </div>

            </mat-list-item>


            <mat-list-item *ngIf="message.type === typeEnum.Email" class="message-list-item">
              <div fxLayout="row wrap" fxLayoutAlign=" center" fxLayoutGap="10px" fxLayoutGap.gt-sm="16px" class="mdc-py-2">
                <div fxFlex="50px">
                  <mat-menu #emailDetails="matMenu" style="max-width:640px; width:100%">
                    <div class="mdc-p-3" *ngIf="message.emailMessage">
                      <h6>Sent To: <span *ngFor="let recipient of message.emailMessage.recipients; let last = last; let index = index">{{recipient.address}}</span>
                        <span *ngIf="!last && index > 0">,</span>
                      </h6>
                      <h6>{{message.emailMessage.subject}}</h6>
                      <div [innerHTML]="message.emailMessage.body"></div>
                    </div>
                  </mat-menu>
                  <div class="avatar avatar-50 circle border-3" *ngIf="message.deliveryStatus === deliveryStatuses.Sent || message.deliveryStatus === deliveryStatuses.Delivered" [matMenuTriggerFor]="emailDetails">
                    <mat-icon>mark_email_unread</mat-icon>
                  </div>
                  <div class="avatar avatar-50 circle border-3" *ngIf="message.deliveryStatus === deliveryStatuses.Received" [matMenuTriggerFor]="emailDetails">
                    <mat-icon>mark_email_read</mat-icon>
                  </div>
                  <div class="avatar avatar-50 circle border-3" *ngIf="message.deliveryStatus !== deliveryStatuses.Sent && message.deliveryStatus !== deliveryStatuses.Delivered && message.deliveryStatus !== deliveryStatuses.Received">
                    <mat-icon>report_gmailerrorred</mat-icon>
                  </div>
                </div>
                <div fxFlex="74" fxFlex.gt-sm="80">
                  <h6 class="mb-0" [matMenuTriggerFor]="emailDetails"><strong>{{message.sentDateLocal | localizedDate: 'shortDate'}}, {{message.sentTimeLocal | localizedTime:'shortTime'}}</strong> (Email)</h6>
                  <P [matMenuTriggerFor]="emailDetails" class="small mat-text-secondary">{{message?.emailMessage?.subject}}</P>
                </div>
              </div>


            </mat-list-item>

          </ng-container>

          <!-- <h6 class="mdc-mb-1 mt-15px"><strong>{{emailList.length}} {{"EMAIL" | translate}}</strong></h6>
          <mat-list-item *ngFor="let email of emailList" class="message-list-item">

          </mat-list-item> -->
        </mat-list>
      </div>


    </ng-container>
    <ng-template #elseBlockMessages>
      <div class="flexbox-parent">
        <div fxLayout="row" fxLayoutAlign="center center">
          <div style="text-align:center;">
            <div class="avatar avatar-100 circle border-3 mb-2 d-inline-block mt-15px">
              <mat-icon>sms</mat-icon>
            </div>
            <h6 class="ma-0">{{'NO_MESSAGES' | translate}}.</h6>
          </div>
        </div>
      </div>
    </ng-template>


  </mat-card-content>

</mat-card>