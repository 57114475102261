import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ChangeListener} from '../changeListener';
import {ElementDefLookupService} from '../ElementDefLookupService';
import {EventBusService} from '../EventBusService';
import {ContextIdDto, ElementDto} from '@savvy/entity-instance-composite';


@Component({
    selector: 'app-element-instance',
    templateUrl: 'elementInstance.component.html'
})
export class ElementInstanceComponent implements OnInit {

    @Input() elementDto: ElementDto;
    @Input() contextIdDto: ContextIdDto;
    @Input() viewContext: any;
    @Input() additionalDataMapDto: any;
    @Input() form: UntypedFormGroup;
    @Input() showPlaceholder: boolean;
    @Input() eventBus: EventBusService;


  @Input() changeListener: ChangeListener;

    entityInstanceId: string;

    constructor(private elementDefLookupService: ElementDefLookupService) {
    }

    ngOnInit(): void {
        this.entityInstanceId = this.viewContext.entityInstanceId.id;
    }

    public getDefinition(): ElementDto {

        // SWITCH VIEW TYPE??
        return this.elementDefLookupService.getDefinition(
            this.viewContext.addEditEntityInstanceContextDto.entityDefinitionDto,
            this.elementDto.elementInstanceDto.elementDefinitionId);
    }
}


