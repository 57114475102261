import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContextIdDto } from '@savvy/user';
import { UrlLookupService } from 'src/app/flo/shared/services/urlLookup.service';
import { PortalSettingsLocalService } from 'src/app/services/portalSettingsLocal.service';
import { OnboardCustomerMessagingModalComponent } from '../../../customer/onboard-customer-messaging-modal/onboard-customer-messaging-modal.component';
import { ResetCustomerPasswordModalComponent } from '../../../customer/reset-customer-password-modal/reset-customer-password-modal.component';
import { ChangePetOwnerModalComponent, ChangePetOwnerModalData } from './change-pet-owner-modal/change-pet-owner-modal.component';
import { Pet, PetService } from '@savvy/pet';
import { ConfirmationDialogComponent } from 'src/app/flo/confirmation-dialog/confirmation-dialog.component';
import { FloSnackbarComponent } from 'src/app/flo/snackbar/floSnackbar.component';

@Component({
  selector: 'app-quick-actions-buttons',
  templateUrl: './quick-actions-buttons.component.html',
  styleUrls: ['./quick-actions-buttons.component.scss']
})
export class QuickActionsButtonsComponent implements OnInit {

  @Input() customerId: string;
  @Input() pet: Pet;
  @Input() contextIdDto: ContextIdDto;
  @Output() petChanged = new EventEmitter<string>();

  constructor(
    private dialog: MatDialog,
    private urlService: UrlLookupService,
    public portalSettingsLocalService: PortalSettingsLocalService,
    private petService: PetService,
    private notify: FloSnackbarComponent
  ) { }

  ngOnInit() {
  }


  resetPassword() {

    this.dialog.open(ResetCustomerPasswordModalComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        customerId: this.customerId,
        // userId: this.customerDto.userId,
        // email: this.customerDto.username
      },
      panelClass: 'customers-messaging-dialog',
      autoFocus: true
    });
  }

  gotoPortal() {
    this.urlService.openNewTab(this.urlService.customerPortalUrl() + '?id=' + this.contextIdDto.contextId + this.urlService.getEnv());
  }

  changeOwner() {
    const data: ChangePetOwnerModalData = {
      contextIdDto: this.contextIdDto,
      customerId: this.customerId,
      pet: this.pet
    };
    const confirmDialog = this.dialog.open(ChangePetOwnerModalComponent, {
      data,
      panelClass: 'customers-messaging-dialog',
      autoFocus: true,
      width: '100%',
      maxWidth: '1024px',
    });

    confirmDialog.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.petChanged.emit(this.pet.id);
      }
    });

  }

  portalInvite() {
    console.log(this.customerId);
    this.dialog.open(OnboardCustomerMessagingModalComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        customerId: this.customerId,
        // email: this.customer.username
      },
      panelClass: 'customers-messaging-dialog',
      autoFocus: true
    });
  }

  petDeceased() {
    const confirmDialog = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'Are you sure you want to mark this pet as deceased?'
      },
      height: 'auto',
      width: '450px',
      panelClass: 'helpwindow'
    });

    confirmDialog.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.pet.deceased = true;
        this.petService.updatePet(this.pet).subscribe(() => {
          this.notify.message = 'Pet marked as deceased';
          this.notify.open();
        });

      }
    });
  }
}
