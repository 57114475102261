import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Customer, CustomerService } from '@savvy/customer';
import { ContextIdDto, Message, MessageService } from '@savvy/messaging';
import { LinkedIdTypeEnum } from 'src/app/core/data-share.service';

@Component({
  selector: 'app-chat-box',
  templateUrl: './chat-box.component.html',
  styleUrls: ['./chat-box.component.scss']
})
export class ChatBoxComponent implements OnInit, OnChanges {
  @Input() contextIdDto: ContextIdDto;
  @Input() selectedMessage: Message;
  messageTypesEnum = Message.TypeEnum;

  messageList: Message[] = [];
  customer: Customer;

  constructor(
    private messageService: MessageService,
    private customerService: CustomerService
  ) { }

  ngOnInit() {
    if (this.selectedMessage && this.contextIdDto) {
      this.loadCustomer();
      this.getMessagesForCustomer();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedMessage.currentValue != changes.selectedMessage.previousValue) {
      this.loadCustomer();
      this.getMessagesForCustomer();
    }
  }

  loadCustomer() {
    const customerLinkedId = this.selectedMessage.relatedLinkedIds?.find(l => l.linkedIdType === LinkedIdTypeEnum.CustomerId);
    if (customerLinkedId) {
      this.customerService.loadCustomer(customerLinkedId?.linkedId, this.contextIdDto.contextId,
        this.contextIdDto.contextIdType).subscribe((res: Customer) => {
          this.customer = res;
        });
    }
  }


  getMessagesForCustomer() {
    this.messageList = [];
    const customerLinkedId = this.selectedMessage.relatedLinkedIds?.find(l => l.linkedIdType === LinkedIdTypeEnum.CustomerId);
    if (customerLinkedId) {
      switch (this.selectedMessage.type) {
        case Message.TypeEnum.Email:
          this.messageService.getMessagesForCustomer(customerLinkedId?.linkedId).subscribe(
            res => {
              this.messageList = res || [];
            });



          break;
        case Message.TypeEnum.Sms:
          this.messageService.getMessagesForCustomer(customerLinkedId?.linkedId).subscribe(
            res => {
              this.messageList = res || [];
            });
          break;
        case Message.TypeEnum.Savvy:

          break;

        default:
          break;
      }
    }
  }

}
