import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService, ContextIdDto, ListMessagesForDisplayResponse, Message, MessageId } from '@savvy/messaging';
import { MatDialog } from '@angular/material/dialog';
import { LinkId } from '@savvy/view-definition';
import { EventBusService } from '../../element/EventBusService';
import { ChangeListener } from '../../element/changeListener';
import { TrialExpiredService } from '../../shared/services/trial-expired.service';
import { NewMessageModalComponent } from '../../shared/components/new-message-modal/new-message-modal.component';
import { EventType, UiEvent } from '../../event/UiEvent';
import { Appointment } from '@savvy/appointment';
import { LinkedIdTypeEnum } from 'src/app/core/data-share.service';
@Component({
  selector: 'app-view-messages-v2',
  templateUrl: './view-messages-v2.component.html',
  styleUrls: ['./view-messages-v2.component.scss']
})
export class ViewMessagesV2Component implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() appointment: Appointment;
  @Output() messageSelectedOutput = new EventEmitter();

  smsList: Array<Message> = [];
  emailList: Array<Message> = [];

  response: ListMessagesForDisplayResponse;

  deliveryStatuses = Message.DeliveryStatusEnum;
  selectedMessage: Message | null;
  messageType:  "EMAIL" | "SMS" = 'SMS';

  constructor(
    private router: Router,
    public trialExpiredService: TrialExpiredService,
    private api: MessageService,
    private dialog: MatDialog) {
  }

  public createMessage() {
    const dialogRef = this.dialog.open(NewMessageModalComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        additionalLinkId: {
          linkedId: this.appointment.id,
          linkedIdType: LinkedIdTypeEnum.AppointmentId
        },
        linkId: {
          linkedId: this.appointment.customerId,
          linkedIdType: LinkedIdTypeEnum.CustomerId
        }
      },
      autoFocus: false,
      panelClass: ['helpwindow', 'scrollable-modal', 'modal-100']
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.loadMessages();
      }
    });
  }

  ngOnInit(): void {
    this.loadMessages();
  }

  messageSelected(messageId: MessageId) {
    this.messageSelectedOutput.emit(messageId.id);
  }

  private loadMessages() {
    console.log('loading messages for ' + this.appointment.id);
    this.api.getMessagesForLinkedInstanceV2(
      this.contextIdDto?.contextId,
      this.contextIdDto.contextIdType,
      this.appointment.id,
      1,
      100
    ).subscribe(response => {
      this.response = response;
      if (response.messagesList && response.messagesList.length) {
        this.smsList = response.messagesList.filter(m => m.type === Message.TypeEnum.Sms);
        this.emailList = response.messagesList.filter(m => m.type === Message.TypeEnum.Email);
        this.handleTypeSwitch();
      }
    });
  }

  selectSms(sms: Message) {
    this.selectedMessage = sms;
  }

  selectEmail(email: Message) {
    this.selectedMessage = email;
  }

  selectedTabChange() {
    this.messageType = this.messageType === 'SMS' ? 'EMAIL' : 'SMS';
    this.handleTypeSwitch();
  }

  handleTypeSwitch() {
    if (this.messageType === 'SMS' && this.smsList.length) {
      this.selectedMessage = this.smsList[0];
    } else if (this.messageType === 'EMAIL' && this.emailList.length) {
      this.selectedMessage = this.emailList[0];
    } else {
      this.selectedMessage = null;
    }
  }

}
