import {Injectable} from '@angular/core';

@Injectable()
export class SnackBarService {
  private queue = [];
  private visibleInstance: any = null;

  constructor() {
  }

  addToQueue(fn) {
    if (this.queue.length === 0) {
      this.queue.push(fn);
      this.showNext();
    }
  }

  showNext() {
    if (!this.visibleInstance) {
      this.visibleInstance = this.queue.shift();
      if (this.visibleInstance) {
        const subscription = this.visibleInstance().afterDismissed().subscribe(() => {
          this.visibleInstance = null;
          subscription.unsubscribe();
          this.showNext();
        });
      }
    }
  }
}
