<mat-card appearance="outlined" *ngIf="listCheckInFormsListDto && listCheckInFormsListDto.checkInForms.length > 0" class="panel new-card">
  <mat-card-title>
    <div fxLayout="row">
      <div fxFlex="80">
        <p class="mt-xs mb-xs">{{'Check In Forms' | translate}}</p>
      </div>
      <div fxFlex="20" *ngIf="checkinFormDefs && checkinFormDefs.length > 0">
        <mat-menu #appMenu="matMenu">
          <button *ngFor="let formDef of checkinFormDefs" mat-menu-item (click)="create(formDef)">
            Create {{formDef.name}}</button>
        </mat-menu>

        <button mat-mini-fab [matMenuTriggerFor]="appMenu">
          <mat-icon class="mat-24">add</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-title>

  <mat-card-content>
    <mat-list>
      <mat-list-item *ngFor="let checkInForm of listCheckInFormsListDto.checkInForms">
        <p matLine (click)="rowSelected(checkInForm)">{{ checkInForm.dateCompleted |date:'medium'}}
          Form {{ checkInForm.dateSent | localizedDate: 'medium'}} <!--span>{{ checkInForm.dateCompleted }}</span-->
        </p>
        <mat-divider *ngIf="listCheckInFormsListDto.checkInForms.length > 1"></mat-divider>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card>

<mat-card appearance="outlined" *ngIf="listCheckInFormsListDto && (!listCheckInFormsListDto.checkInForms || listCheckInFormsListDto.checkInForms.length === 0)" class="panel new-card">

  <mat-card-content>
    <div class="flexbox-parent">
      <div fxLayout="row" fxLayoutAlign="center center">
        <div style="text-align:center;">
          <app-zero-image name="Check-In Forms"></app-zero-image>
          <br>
          <h5 class="ma-0" *ngIf="checkinFormDefs && checkinFormDefs.length > 0">{{'You don’t have any Check-In Form\'s' | translate}}</h5>
          <h5 class="ma-0" *ngIf="checkinFormDefs && checkinFormDefs.length === 0">{{'No Check-In Form Template\'s' | translate}}</h5>
          <br>
          <!-- *ngIf="listEntityListDto.canCreate" -->

          <!-- checkinFormDefs -->
          <div *ngIf="checkinFormDefs && checkinFormDefs.length > 0">
            <mat-menu #appMenu="matMenu">
              <button color="accent" *ngFor="let formDef of checkinFormDefs" mat-menu-item (click)="create(formDef)">{{formDef.name}}</button>
            </mat-menu>

            <button mat-raised-button color="accent" [matMenuTriggerFor]="appMenu">
              {{'New Check-In' | translate}}
              <!--mat-icon>more_vert</mat-icon-->
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>