import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EicompService } from '@savvy/entity-instance-composite';
import {SeriesService} from '@savvy/series';

@Component({
  selector: 'app-confirmation-ei-modal',
  templateUrl: './confirmation-ei-modal.component.html',
  styleUrls: ['./confirmation-ei-modal.component.scss']
})
export class ConfirmationEiModalComponent implements OnInit {
  name: string;
  series;
  loadedSeries = false;
  isSeries = false;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: SeriesService,
    private eiCompositeApiExtension: EicompService,
    public dialogRef: MatDialogRef<ConfirmationEiModalComponent>) {


  }

  ngOnInit() {
    if (this.data) {
      this.api.loadSeriesByEiLinkId(this.data.contextIdDto.contextIdType,
        this.data.contextIdDto.contextId, this.data.entityInstanceId.id, this.data.entityDefinitionId.id).subscribe(response => {
          console.log('series ', response);
          this.series = response;
          this.loadedSeries = true;
          if (response != null && response.linkIds != null && response.linkIds.length > 0) {
            this.isSeries = true;
          }
      });
    }
  }

  deleteSeries() {
    this.eiCompositeApiExtension.deleteEiSeries(
      this.data.contextIdDto.contextId,
      this.data.contextIdDto.contextIdType,
      this.series.id,
      this.data.entityInstanceId.id,
      false)
      .subscribe(
        response => {
          this.dialogRef.close(true);
        }
      );
  }

  deleteEi() {
    this.eiCompositeApiExtension.deleteEntityInstance(
      this.data.contextIdDto.contextId,
      this.data.contextIdDto.contextIdType,
      this.data.entityInstanceId.id,
      false)
      .subscribe(
        response => {
          this.dialogRef.close(true);
        }
      );
  }

}
