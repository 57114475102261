import { MediaMatcher } from '@angular/cdk/layout';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Title } from '@angular/platform-browser';
import { ContextIdDto } from '@savvy/menu';
import { SubscriptionStateDto } from '@savvy/subscription';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subject, Subscription } from 'rxjs';
import { GLOBAL } from 'src/app/app.constants';
import { LookAndFeelLocalService } from '../../../services/lookAndFeel.service';
import { ContextService } from '../../context.service';
import { EventBusService } from '../../element/EventBusService';
import { LayoutBusService } from '../../element/LayoutBusService';
import { EventType, UiEvent } from '../../event/UiEvent';
import { FaviconService } from '../../logo/favicon.service';
import { OrgSetupHelperService } from '../../shared/services/org-setup-helper.service';
import { StyleService } from '../../style/style.service';
import { Router } from '@angular/router';
import { SubscriptionHelperService } from '../../shared/services/subscription-helper.service';
import * as _ from 'lodash';

const SMALL_WIDTH_BREAKPOINT = 600;

@Component({
  selector: 'app-standard-layout',
  templateUrl: './standard-layout.component.html',
  styleUrls: ['./standard-layout.component.scss']
})
export class StandardLayoutComponent implements OnInit, OnDestroy {
  // @HostBinding('class') componentCssClass;

  @ViewChild('sidemenu', { static: true }) sidemenu;
  @ViewChild('openReferPanel', { static: false }) openReferPanel: MatSidenav;
  @ViewChild('notifications', { static: true }) notifications: MatSidenav;
  destroy$ = new Subject();

  mediaMatcherQuery: MediaQueryList;
  mediaMatcherEvent;
  sidePanelOpened;
  options = {
    collapsed: false,
    compact: false,
    boxed: false,
    dark: false,
    dir: 'ltr'
  };

  eventBusService = new EventBusService();

  contextIdDto: ContextIdDto;
  subscriptions: Subscription[] = [];
  trialExpired = false;
  mobileQueryListener: () => void;

  constructor(
    private router: Router,
    private contextService: ContextService,
    private lookAndFeelService: LookAndFeelLocalService,
    private titleService: Title,
    private faviconService: FaviconService,
    private styleService: StyleService,
    private deviceDetectorService: DeviceDetectorService,
    private orgSetupHelperService: OrgSetupHelperService,
    private layoutBusService: LayoutBusService,
    private subscriptionHelperService: SubscriptionHelperService,
    media: MediaMatcher,
    changeDetectorRef: ChangeDetectorRef,
    public overlayContainer: OverlayContainer,


  ) {

    console.log('inside standard layout constructor');

    this.mediaMatcherQuery = media.matchMedia(`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`);
    this.mediaMatcherEvent = { media: this.mediaMatcherQuery.media, matches: this.mediaMatcherQuery.matches };

    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mediaMatcherQuery.addListener(this.mobileQueryListener);
    console.log('leaving admin layout constructor');
  }

  ngOnInit(): void {

    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
        this.loadLookAndFeel();
      }
    }));

    this.subscriptions.push(this.subscriptionHelperService.subscriptionState$.subscribe(subscriptionState => {
      if (subscriptionState
        && (subscriptionState.subscriptionStatus === SubscriptionStateDto.SubscriptionStatusEnum.TrialExpired
          || subscriptionState.subscriptionStatus === SubscriptionStateDto.SubscriptionStatusEnum.Cancelled)) {
        // Trial Expired
        this.trialExpired = true;
        this.sidemenu.close();
      }
    }));

    this.subscriptions.push(this.orgSetupHelperService.orgSettingsInitialised$.subscribe(orgSettingsInitialised => {
      if (GLOBAL.firstSignupSettingsON && !orgSettingsInitialised) {
        this.sidemenu?.close();
      }
    }));

    this.layoutBusService.toggleMenu$.subscribe((opened) => {
      this.sidemenu.opened = opened;
    });

  }



  toggleNotifications() {
    console.log('inside toggleNotifications');
    this.notifications.toggle();
  }

  loadLookAndFeel() {

    if (this.contextService && this.contextIdDto) {
      if (this.lookAndFeelService.lookAndFeel) {
        this.faviconService.add(this.lookAndFeelService.lookAndFeel.favIcon);
        this.styleService.add(this.lookAndFeelService.lookAndFeel.theme);
        this.titleService.setTitle(this.lookAndFeelService.lookAndFeel.pageTitle);
      } else {
        console.log('loading look and feel in standard layout');
        // TODO: Load from product meta
        /* this.lookAndFeelApi.getLookAndFeel(this.contextIdDto.contextId).subscribe(lookAndFeel => {
           if (lookAndFeel) {
             console.log('theme is ' + lookAndFeel.theme);
             GLOBAL.selectedTheme = lookAndFeel.theme;
             this.faviconService.add(lookAndFeel.favIcon);
             this.styleService.add(lookAndFeel.theme);
             this.titleService.setTitle(lookAndFeel.pageTitle);
           }
         });*/
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    if (this.mediaMatcherQuery) {
      this.mediaMatcherQuery.removeListener(this.mobileQueryListener);
    }
  }



  runOnRouteChange(): void {
    if (this.isOver()) {
      this.sidemenu?.close();
    }

    this.updatePS();
  }

  receiveOptions($event): void {
    this.options = $event;
  }

  isOver(): boolean {
    if (this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet()) {
      return true;
    }
    if (
      // this.url.startsWith('/messaging') ||
      this.router.url.startsWith('/calendar') ||
      this.router.url.startsWith('/bookingWindow') ||
      this.router.url.startsWith('/admin/settings') ||
      this.router.url === '/apps/media' ||
      this.router.url === '/maps/leaflet' ||
      this.router.url === '/taskboard') {
      return true;
    } else if (this.mediaMatcherEvent) {
      return this.mediaMatcherEvent.matches;
    } else {
      return false;
    }
  }

  updatePS(): void {
    if (this.mediaMatcherEvent && !this.mediaMatcherEvent.matches && !this.options.compact) {
      setTimeout(() => {
        // this.directiveScroll.update();
      }, 350);
    }
  }

  onMenuChanged() {
    if (this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet()) {
      this.sidemenu?.close();
    }
  }

  notificationUpdated(notificationCount) {
    const event = <UiEvent>{};
    event.eventType = EventType.NUM_NOTIFICATIONS_CHANGED;
    event.numUnreadNotifications = notificationCount;
    this.eventBusService.add(event);
  }

  toggleSidenav(event) {
    if (!_.isUndefined(event)) {
      if (event) {
        this.sidemenu.open();
      } else {
        this.sidemenu.close();
      }
    } else {
      this.sidemenu.toggle();
    }
  }
}
