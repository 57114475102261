import { Component, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Feature } from '@savvy/add-ons';
import { ViewVaccinationsData } from '@savvy/app-metadata';
import {CustomerService, OptInCustomer} from '@savvy/customer';
import { DataItemDto, DatalistService } from '@savvy/datalist';
import { FileValue } from '@savvy/entity-instance-composite';
import { ContextIdDto } from '@savvy/menu';
import { Pet, PetService } from '@savvy/pet';
import { OptInService, OptInUserV2 } from '@savvy/user';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { CreateAppointmentV2SyncService } from '../../appointments-v2/create-appointment-v2/create-appointment-v2-sync.service';
import { FeaturesHelperService } from '../../shared/services/features-helper.service';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';
import { DeceasedConfirmationComponent } from './deceased-confirmation/deceased-confirmation.component';
import { OptoutOption } from './optout-option.enum';
import { PetDetailsComponent } from './pet-details/pet-details.component';
import { PetFileUploadComponent } from './pet-file-upload/pet-file-upload.component';
import { PetMedicalDetailsComponent } from './pet-medical-details/pet-medical-details.component';
import { PetProfileComponent } from './pet-profile/pet-profile.component';

@Component({
  selector: 'app-create-pet-details-v2',
  templateUrl: './create-pet-details-v2.component.html',
  styleUrls: ['./create-pet-details-v2.component.scss']
})
export class CreatePetDetailsV2Component implements OnInit, OnDestroy {

  @ViewChild(PetProfileComponent, { static: false }) petProfileComponent: PetProfileComponent;
  @ViewChild(PetDetailsComponent, { static: false }) petDetailsComponent: PetDetailsComponent;
  @ViewChild(PetMedicalDetailsComponent, { static: false }) petMedicalDetailsComponent: PetMedicalDetailsComponent;
  @ViewChild(PetFileUploadComponent, { static: false }) petFileUploadComponent: PetFileUploadComponent;

  contextIdDto: ContextIdDto;
  petId: string;
  pet: Pet;
  submitted = false;
  viewVaccinationsData: ViewVaccinationsData = {};
  subs: Subscription[] = [];
  existingCustomer: 'yes' | 'no' = 'yes';
  isViewMode = false;
  closeOnUpdate = false;
  isVaccinationFeatureAllowed = false;
  breeds: DataItemDto[] = [];
  flags: string[] = [];
  ownerChanged = false;
  existingPetOwnerId = '';
  selectedCustomerId: string;

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private petService: PetService,
    private spinner: NgxSpinnerService,
    private notify: FloSnackbarComponent,
    private featuresHelperService: FeaturesHelperService,
    private customerService: CustomerService,
    public createAppointmentV2SyncService: CreateAppointmentV2SyncService,
    private datalistService: DatalistService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    @Optional() public dialogRef?: MatDialogRef<CreatePetDetailsV2Component>,
  ) {
  }

  ngOnInit(): void {
    this.isVaccinationFeatureAllowed = this.featuresHelperService.isFeatureAllowed(Feature.TypeEnum.Vaccinations);
    this.createAppointmentV2SyncService.clear();
    if (this.data && this.data.contextIdDto) {
      this.isViewMode = true;
      this.closeOnUpdate = this.data.closeOnUpdate;
      this.contextIdDto = this.data.contextIdDto;
      if (this.data.petId) {
        this.petId = this.data.petId;
        this.viewVaccinationsData.petId = this.petId;
        this.loadPet(this.petId);
      } else {
        this.loadBreeds();
        if (this.data?.customerId) {
          this.selectedCustomerId = this.data.customerId;
          this.existingCustomer = 'yes';
          this.createAppointmentV2SyncService?.populateCustomerByCustomerId(this.selectedCustomerId, this.contextIdDto);
        }
      }
    } else {
      this.route.params.subscribe(params => {
        this.contextIdDto = <ContextIdDto>{};
        this.contextIdDto.contextId = params['contextId'];
        this.contextIdDto.contextIdType = params['contextIdType'];
        if (params['petId']) {
          this.petId = params['petId'];
          this.viewVaccinationsData.petId = this.petId;
          this.loadPet(this.petId);
        } else {
          this.loadBreeds();
        }
      });
      this.subs.push(this.createAppointmentV2SyncService.createCustomer$.subscribe((result) => {
        if (result) {
          if (this.existingCustomer === 'yes') {
            this.ownerChanged = true;
          }
        }
      }));
    }
  }

  loadBreeds() {
    return new Promise(resolve => {
      this.datalistService.getDataListByName(
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType, 'Breed').subscribe(breeds => {
          if (breeds?.dataItems) {
            this.breeds = breeds.dataItems;
            resolve(this.breeds);
          }
        });
    });
  }

  cancel() {
    this.dialogRef?.close();
  }

  ngOnDestroy(): void {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }

  loadPet(petId) {
    this.spinner.show();
    this.loadBreeds().then(res => {
      this.petService.get(
        petId).subscribe(response => {
          this.pet = response;
          if (this.pet.flags) {
            this.flags = this.pet.flags;
          }
          this.spinner.hide();
          this.renderSelectedValue(response);
        }, error => {
          this.spinner.hide();
        });
    })
  }

  renderSelectedValue(pet: Pet) {
    if (this.petProfileComponent) {
      this.petProfileComponent.setValue(pet);
    }
    this.petDetailsComponent.setValue(pet);
    this.petMedicalDetailsComponent.setValue(pet);
    if (pet?.petOwnerId) {
      this.existingPetOwnerId = pet?.petOwnerId;
      this.selectedCustomerId = pet?.petOwnerId;
      this.existingCustomer = 'yes';
    }
    this.createAppointmentV2SyncService?.populateCustomerByCustomerId(pet?.petOwnerId, this.contextIdDto);
  }

  persist(isFileUpload = false) {
    if (isFileUpload && !this.petId && !this.contextIdDto?.contextId) {
      return;
    }
    if (!isFileUpload) {
      this.submitted = true;
    }
    let isInvalidForm = false;
    if (!this.isViewMode && !this.selectedCustomerId) {
      isInvalidForm = true;
    }
    if (this.petProfileComponent?.petFormGroup?.invalid) {
      this.petProfileComponent?.petFormGroup.markAllAsTouched();
      isInvalidForm = true;
    }
    if (this.petDetailsComponent?.petFormGroup?.invalid) {
      this.petDetailsComponent?.petFormGroup.markAllAsTouched();
      isInvalidForm = true;
    }
    if (this.petMedicalDetailsComponent?.petFormGroup?.invalid) {
      this.petMedicalDetailsComponent?.petFormGroup.markAllAsTouched();
      isInvalidForm = true;
    }
    if (isInvalidForm) {
      return;
    }
    const pet = {} as Pet;
    const petProfile = this.petProfileComponent?.petFormGroup;
    const petDetails = this.petDetailsComponent?.petFormGroup;
    const medicalDetails = this.petMedicalDetailsComponent?.petFormGroup;
    pet.name = petProfile?.get('petName')?.value;
    pet.breed = petProfile?.get('petBreed')?.value;
    pet.petType = petProfile?.get('petType')?.value;
    pet.petNotes = petProfile?.get('petNotes')?.value;
    pet.profilePicReference = petProfile.get('profilePicReference')?.value;
    pet.coverPicReference = petProfile.get('coverPicReference')?.value;
    pet.colour = petDetails.get('colour')?.value;
    pet.dob = petDetails.get('dob')?.value ? moment(petDetails.get('dob')?.value).format('YYYY-MM-DD') : '';
    pet.groomPrice = Number(petDetails.get('groomPrice')?.value);
    pet.blades = petDetails.get('blades')?.value;
    pet.spayedOrNeutered = petDetails.get('spayedOrNeutered')?.value;
    pet.afraidOfNoises = petDetails.get('afraidOfNoises')?.value;
    pet.insured = petDetails.get('insured')?.value;
    pet.deceased = petDetails.get('deceased')?.value;
    pet.bitch = petDetails.get('bitch')?.value;
    pet.recommendedVisitFrequencyInWeeks = petDetails.get('recommendedVisitFrequencyInWeeks')?.value;
    pet.vetName = medicalDetails.get('vetName')?.value;
    pet.vetPhone = medicalDetails.get('vetPhone')?.value;
    pet.microChipped = medicalDetails.get('microChipped')?.value;
    pet.allergies = medicalDetails.get('allergies')?.value;
    pet.medical = medicalDetails.get('medical')?.value;
    pet.weight = medicalDetails.get('weight')?.value;
    pet.vaccinated = medicalDetails.get('vaccinated')?.value;
    pet.files = this.petFileUploadComponent?.files;
    pet.petOwnerId = this.selectedCustomerId;
    pet.ownerId = this.contextIdDto?.contextId;
    pet.approvalNeeded = this.pet?.approvalNeeded ? this.pet.approvalNeeded : false;
    pet.flags = this.flags;

    if (this.pet && this.pet.entityInstanceId) {
      pet.entityInstanceId = this.pet.entityInstanceId;
      pet.entityDefinitionId = this.pet.entityDefinitionId;
    }

    if (this.pet && this.pet.contacts) {
      pet.contacts = this.pet.contacts;
    }

    if (pet.deceased) {
      this.deceasedConfirmation(pet);
    } else {
      if (this.petId) {
        pet.id = this.petId;
        this.update(pet);
      } else {
        this.create(pet);
      }
    }
  }

  create(pet: Pet) {
    this.spinner.show();
    this.petService.createPet(
      pet).subscribe(response => {
        this.pet = response;
        this.spinner.hide();
        this.notify.message = 'Pet has been created';
        this.notify.open();
        if (this.dialogRef) {
          this.dialogRef?.close(response);
        } else {
          this.gotoPetList();
        }
      }, error => {
        this.apiError();
      });
  }

  executeUpdatePet(pet: Pet) {
    this.petService.updatePet(
      pet).subscribe(response => {
        this.pet = response;
        this.spinner.hide();
        this.notify.message = 'Pet has been updated';
        this.notify.open();
        if (this.isViewMode) {
          if (this.closeOnUpdate) {
            this.dialogRef?.close(response);
          }
        }
      }, error => {
        this.apiError();
      });
  }

  update(pet: Pet) {
    this.spinner.show();
    // if (this.ownerChanged && pet?.id && pet?.petOwnerId && this.existingPetOwnerId
    //   && pet?.petOwnerId !== this.existingPetOwnerId
    // ) {
    // this.petService.movePetOwner(pet?.id, this.selectedCustomerId).subscribe(response => {
    //   if (response?.moveSuccess && response?.updatedPet) {
    //     this.pet = response?.updatedPet;
    //     this.executeUpdatePet(pet);
    //   } else {
    //     this.spinner.hide();
    //     if (response?.failureReason) {
    //       this.notify.message = response?.failureReason;
    //       this.notify.open();
    //     }
    //   }
    // });
    // } else {
    this.executeUpdatePet(pet);
    // }

  }

  apiError() {
    this.spinner.hide();
    this.notify.message = 'Error during execution';
    this.notify.open();
  }

  gotoPetList() {
    history.back();
  }

  customerSelected(customerId: string) {
    if (customerId) {
      this.ownerChanged = true;
      this.selectedCustomerId = customerId;
    } else {
      this.selectedCustomerId = '';
    }
  }

  deceasedConfirmation(pet: Pet) {
    const dialogRef = this.dialog.open(DeceasedConfirmationComponent, {
      data: {
        pet
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (this.petId) {
          pet.id = this.petId;
          this.update(pet);
          if (result?.optOutOption !== OptoutOption.none) {
            this.optInV2(pet, result?.optOutOption);
          }
        } else {
          this.create(pet);
        }
      }
    });
  }

  optInV2(pet: Pet, optOutOption: OptoutOption) {
    const optIn = {
      allowEmailMarketing: false,
      allowWhatsAppMarketing: false,
      allowSmsMarketing: false,
      allowEmail: false,
      allowWhatApp: false,
      allowSms: false
    };
    if (optOutOption === 'MARKETING') {
      optIn.allowEmail = true;
      optIn.allowWhatApp = true;
      optIn.allowSms = true;
    }

    this.customerService.loadCustomer(pet?.petOwnerId, this.contextIdDto?.contextId, 'ENV_ID').subscribe(response => {

      if (response?.id) {
        const optInCustomer: OptInCustomer = {
          envId: this.contextIdDto?.contextId,
          customerId: response.id,
          optIn
        };

        this.customerService.optInCustomer(optInCustomer).subscribe(response2 => { });
      }

    });
  }

  fileUpdated(files: Array<FileValue>) {
    this.persist(true);
  }

  createFlag(flagId: string) {
    if (!this.flags) {
      this.flags = new Array();
    }
    this.flags.push(flagId);
    this.persist(false);
  }

  deleteFlag(flagId: string) {
    if (this.flags) {
      this.flags.forEach((item, index) => {
        if (item === flagId) {
          this.flags.splice(index, 1);
          this.persist(false);
        }
      });
    }
  }

}
