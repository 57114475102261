import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OrgId, OrgService, OrgSetupDto } from '@savvy/org';
import { UserId } from '@savvy/user';
import { BehaviorSubject } from 'rxjs';
import { GLOBAL } from 'src/app/app.constants';
import { LanguageService } from 'src/app/services/language.service';
import { APP_TYPE_ENUM, ContextService } from '../../context.service';
import { NavigateService } from '../../entry/NavigateService';
import { LocalMenuService } from '../../menu/local-menu.service';

@Injectable({
  providedIn: 'root'
})
export class OrgSetupHelperService {
  currentSubUserId: UserId;

  // orgId: OrgId;̦
  // orgSetupDto: OrgSetupDto;
  // Default to true
  public orgSettingsInitialised = false;
  public orgId: OrgId;
  public orgSetupDto: OrgSetupDto;


  private orgSettingsInitialisedSource: BehaviorSubject<boolean> = new BehaviorSubject(this.orgSettingsInitialised);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public orgSettingsInitialised$ = this.orgSettingsInitialisedSource.asObservable();

  private orgSetupDtoSource: BehaviorSubject<OrgSetupDto> = new BehaviorSubject(null);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public orgSetupDto$ = this.orgSetupDtoSource.asObservable();

  constructor(
    private orgService: OrgService,
    private languageService: LanguageService,
    private router: Router,
    private contextService: ContextService,
    private localMenuService: LocalMenuService,
    private navigateService: NavigateService
  ) { }

  public setSettingsInitialised(settingsInitialised: boolean, orgId: OrgId) {
    this.orgSettingsInitialised = settingsInitialised;
    this.orgId = orgId;
    console.log('setSettingsInitialised', settingsInitialised);
    this.orgSettingsInitialisedSource.next(settingsInitialised);
  }


  public loadOrgSetup(orgId: string) {
    this.orgService.loadOrgSetup(orgId).subscribe(res => {
      this.orgSetupDto = res;
      this.languageService.setLocale(this.orgSetupDto.locale);
      this.orgSetupDtoSource.next(res);
    });
  }

  public updateOrgSetup() {
    this.orgService.saveOrgSetup(this.orgSetupDto).subscribe(res => {
      this.orgSetupDto = res;
      this.orgSetupDtoSource.next(res);
    });
  }

  public skipSetup(completedSetupStep: string) {
    this.orgService.loadOrgSetup(this.orgId.id).subscribe((res: OrgSetupDto) => {
      res.setupFinished = true;
      res.orgSetupStateDto = {
        chosenSetupOptions: [],
        completedSetupStep
      };
      this.orgService.saveOrgSetup(res).subscribe((orgSaved) => {
        if (orgSaved) {
          this.setSettingsInitialised(
            orgSaved.setupFinished,
            orgSaved.orgId
          );
          if (GLOBAL.firstSignupSettingsON) {
            if (!orgSaved.setupFinished
              && (this.contextService.appType === APP_TYPE_ENUM.PET_GROOMING_SALON
                || this.contextService.appType === APP_TYPE_ENUM.PET_DAY_CARE
                || this.contextService.appType === APP_TYPE_ENUM.HAIR_SALON
                || this.contextService.appType === APP_TYPE_ENUM.BARBERS
                || this.contextService.appType === APP_TYPE_ENUM.NAIL_SALON
                || this.contextService.appType === APP_TYPE_ENUM.BEAUTY_SALON
                || this.contextService.appType === APP_TYPE_ENUM.VETS
                || this.contextService.appType === APP_TYPE_ENUM.GROOM_SCHOOL
              )) {
              if (this.contextService?.getContextDto()) {
                this.router.navigate([`welcome/step/${Number(res.orgSetupStateDto?.completedSetupStep) + 1}`,
                this.contextService?.getContextDto()?.contextId, this.contextService?.getContextDto()?.contextIdType]);
              }
            } else {
              const menuStructure = this.localMenuService.getMenuStructure();
              if (menuStructure) {
                this.navigateService.navigateTo(this.contextService.getContextDto(), menuStructure?.menuDefinitionList[0]);
              } else {
                this.navigateService.navigateHome(this.contextService.getContextDto()?.contextId);
              }
            }
          }
          sessionStorage.removeItem('notesSeen');
        }
      });
    });
  }

  public advancedSetup(completedSetupStep?: string) {
    this.orgService.loadOrgSetup(this.orgId.id).subscribe(res => {
      res.setupFinished = false;
      res.orgSetupStateDto = {
        chosenSetupOptions: [],
        completedSetupStep: completedSetupStep ? completedSetupStep : String(Number(res.orgSetupStateDto.completedSetupStep) - 1)
      };
      this.orgService.saveOrgSetup(res).subscribe((orgSaved) => {
        if (orgSaved) {
          this.setSettingsInitialised(
            orgSaved.setupFinished,
            orgSaved.orgId
          );
          sessionStorage.removeItem('notesSeen');
          sessionStorage.removeItem('selectedMenuItemType');
          sessionStorage.removeItem('isAdminMenu');
          sessionStorage.removeItem('expandedIndex');
          this.router.navigate([`welcome/step/${Number(res.orgSetupStateDto?.completedSetupStep) + 1}`,
          this.contextService?.getContextDto()?.contextId, this.contextService?.getContextDto()?.contextIdType]);
        }
      });
    });
  }


}
