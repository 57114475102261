<mat-card appearance="outlined" *ngIf="listInvoiceListDto && listInvoiceListDto.invoiceList.length > 0" class="panel new-card">
    <mat-card-title>
        <div fxLayout="row">
            <div fxFlex="80">
                <p class="mt-xs mb-xs">{{'Invoices' | translate}}</p>
            </div>
            <div fxFlex="20">
                <button mat-mini-fab color="accent" [disabled]="trialExpiredService.isTrialExpired()" (click)="create()">
                    <mat-icon class="mat-24">add</mat-icon>
                </button>
            </div>
        </div>
    </mat-card-title>

    <mat-card-content>
        <mat-list>
            <mat-list-item *ngFor="let invoice of listInvoiceListDto.invoiceList; let i = index">
                <p matLine (click)="rowSelected(invoice.id)">{{ invoice.invoiceDate |date:'medium':"+0000"}} {{ invoice.total | currency:currencyCode:'symbol'}}
                    {{ getMessage(invoice) | translate}}
                </p>
                <mat-divider *ngIf="listInvoiceListDto.invoiceList.length > 1"></mat-divider>
            </mat-list-item>
        </mat-list>
    </mat-card-content>
</mat-card>

<mat-card appearance="outlined" *ngIf="listInvoiceListDto && (!listInvoiceListDto.invoiceList || listInvoiceListDto.invoiceList.length === 0)" class="panel new-card">

    <mat-card-content>
        <div class="flexbox-parent">
            <div fxLayout="row" fxLayoutAlign="center center">
                <div style="text-align:center;">
                    <app-zero-image name="invoice"></app-zero-image><br>
                    <h5 class="ma-0">{{'You don’t have any Invoice\'s' | translate}}</h5><br>
                    <!-- *ngIf="listEntityListDto.canCreate" -->
                    <button mat-raised-button color="accent" [disabled]="trialExpiredService.isTrialExpired()" (click)="create()">{{'New Invoice' | translate}}</button>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>