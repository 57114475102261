<mat-card appearance="outlined" class="panel new-card" *ngIf="contractUiDtoList && contractUiDtoList.length > 0">
  <mat-card-title>
    <div fxLayout="row">
      <div fxFlex="80">
        <p class="mt-xs mb-xs">{{'Contracts' | translate}}</p>
      </div>
      <div fxFlex="20">
        <button mat-mini-fab color="accent" [disabled]="trialExpiredService.isTrialExpired()" (click)="create()">
          <mat-icon class="mat-24">add</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-title>

  <mat-card-content>
    <mat-list>
      <mat-list-item *ngFor="let contractUi of contractUiDtoList">
        <p matLine (click)="rowSelected(contractUi.contract.id)">{{contractUi.templateName}} -
          {{contractUi.contract.dateClientSigned |date:'medium'}}</p>
        <mat-divider *ngIf="contractUiDtoList.length > 1"></mat-divider>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card>

<mat-card appearance="outlined" class="panel new-card" *ngIf="(!contractUiDtoList || contractUiDtoList.length === 0)">

  <mat-card-content>
    <div class="flexbox-parent">
      <div fxLayout="row" fxLayoutAlign="center center">
        <div style="text-align:center;">
          <app-zero-image name="Contracts"></app-zero-image><br>
          <h5 class="ma-0">{{'You don’t have any Contracts' | translate}}</h5><br>
          <!-- *ngIf="listEntityListDto.canCreate" -->
          <button mat-raised-button color="accent" [disabled]="trialExpiredService.isTrialExpired()" (click)="create()">{{'New Contract' | translate}}</button>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>