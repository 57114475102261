import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Pet } from '@savvy/pet';

@Component({
  selector: 'app-pet-medical-details',
  templateUrl: './pet-medical-details.component.html',
  styleUrls: ['./pet-medical-details.component.scss']
})
export class PetMedicalDetailsComponent implements OnInit {

  petFormGroup: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.initFormGroup();
  }

  public setValue(pet: Pet) {
    this.petFormGroup.patchValue({
      vetName: pet?.vetName,
      vetPhone: pet?.vetPhone,
      microChipped: pet?.microChipped,
      allergies: pet?.allergies,
      medical: pet?.medical,
      vaccinated: pet?.vaccinated,
      weight: pet?.weight
    });
  }

  private initFormGroup() {
    this.petFormGroup = this.formBuilder.group({
      vetName: [''],
      vetPhone: [''],
      microChipped: [null],
      allergies: [''],
      medical: [''],
      vaccinated: [''],
      weight: ['']
    });
  }

}
