import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { startSpan } from '@sentry/angular-ivy';
declare var window: any;

if (environment.production) {
  enableProdMode();
} else {
  window?.clarity('stop');
}

startSpan({
  name: 'platform-browser-dynamic',
  op: 'ui.angular.bootstrap'
},
  async () => {
    await platformBrowserDynamic().bootstrapModule(AppModule).then(() => {
      try {
        if ('serviceWorker' in navigator && environment.production) {
          navigator.serviceWorker.register('/ngsw-worker.js').then((reg) => {
            console.log('Service worker successfully registered.', reg);
            if (reg.installing) {
              reg.installing.onstatechange = (e: any) => {
                if (e?.target?.state === 'installed') {
                  console.log('Service worker successfully installed.');
                } else if (e?.target?.state === 'redundant') {
                  console.log('Service worker failed to install.');
                }
              };
            }
          }).catch((err) => {
            console.error('Service worker failed to register.', err);
          });
        }
      } catch (error) {
        console.error('Error in registering serviceWorker: ', error);
      }
    }).catch(err => console.log(err));
  }
);



