<div fxLayout="row" fxLayoutAlign=" center" class="w-100">
  <div fxFlex>
    <h6 class="mb-0 ">Boardings</h6>
  </div>
  <div fxFlex="auto">
    <div fxLayout="row" fxLayoutAlign="center center" class="mdc-p-3 ">
      <mat-button-toggle-group [(ngModel)]="historyMode" (ngModelChange)="historyModeChanged($event)" name="historyMode"
        aria-label="History Mode" class="button-group-payments">
        <mat-button-toggle [value]="'past'">{{'PAST' | translate}} ({{pastBoardings.length}})</mat-button-toggle>
        <mat-button-toggle [value]="'ongoing'">{{'ONGOING' | translate}}
          ({{ongoingBoardings.length}})</mat-button-toggle>
        <mat-button-toggle [value]="'future'">{{'UPCOMING' | translate}}
          ({{futureBoardings.length}})</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
  <div fxFlex="nogrow">
    <mat-slide-toggle class="mr-1" [labelPosition]="'before'" color="accent" [checked]="showOnlyCancelled"
      [(ngModel)]="showOnlyCancelled" (ngModelChange)="cancelledFilterChanged($event)">
      Only Show Cancelled
    </mat-slide-toggle>
    <button mat-button color="accent" (click)="addAppointment()" *ngIf="!isViewMode">
      <mat-icon class="vm">event</mat-icon> <span class="vm">{{'CREATE_APPOINTMENT' | translate}}</span>
    </button>
    <!--    <button *ngIf="selectedAppointments?.length" mat-button color="accent" (click)="deleteAppointments()">-->
    <!--      <mat-icon class="vm">delete</mat-icon> <span class="vm">Delete Selected</span>-->
    <!--    </button>-->
    <!--    <mat-menu #appMenu="matMenu">-->
    <!--      <button mat-menu-item (click)="sendAppointments()">Send All Appointments</button>-->
    <!--    </mat-menu>-->
    <!--    <button mat-icon-button [matMenuTriggerFor]="appMenu">-->
    <!--      <mat-icon>more_vert</mat-icon>-->
    <!--    </button>-->
  </div>
</div>
<!-- <hr> -->
<!-- {{historyList | json}} -->


<div *ngIf="historyMode === 'past'">
  <ul class="historylist mt-1" *ngIf="pastBoardings.length">
    <li *ngFor="let pastBoarding of pastBoardings">
      <div fxLayout="row">
        <div fxFlex fxLayout="column" fxLayout.gt-sm="row">
          <div fxFlex="grow" fxFlex.gt-sm="30" (click)="openViewBoardingModal(pastBoarding)" class="px-1">
            <p class="my-1 view-invoice font-weight-medium">{{pastBoarding.label}}</p>
          </div>
          <div fxFlex="grow" fxFlex.gt-sm="25" (click)="openViewBoardingModal(pastBoarding)" class="px-1">
            <p class="my-1 mat-text-secondary view-invoice">{{pastBoarding.petLabel}}</p>
          </div>
          <!--          <div fxFlex="grow" fxFlex.gt-sm="15" class="px-1">-->
          <!--            <p class="my-1 mat-text-secondary">{{pastAppointment.blades}}</p>-->
          <!--          </div>-->
          <div fxFlex="grow" fxFlex.gt-sm="30" class="px-1" *ngIf="pastBoarding?.notes">
            <p class="my-1 mat-text-secondary">{{pastBoarding.notes}}</p>
          </div>
        </div>
        <div fxFlex="nogrow">
          <div class="avatar avatar-50 circle border-3 d-inline-block mx-2" matTooltip="Edit Boarding"
            (click)="openEditAppointmentModal(pastBoarding)" *ngIf="!isViewMode">
            <mat-icon class="view-invoice vm">
              edit_calendar
            </mat-icon>
          </div>

          <div class="avatar avatar-50 circle border-3 d-inline-block" *ngIf="pastBoarding?.boardingBooking?.invoiceId"
            matTooltip="View Invoice" (click)="viewInvoice(pastBoarding?.boardingBoooking?.invoiceId?.id)">
            <mat-icon class="view-invoice vm">
              receipt
            </mat-icon>
          </div>
        </div>
      </div>
    </li>
  </ul>
  <div class="no-history mt-2" *ngIf="!pastBoardings.length">
    <div style="text-align:center;">
      <div class="avatar avatar-100 circle border-3 mb-2 d-inline-block">
        <mat-icon class="h1 h-auto w-auto">timeline</mat-icon>
      </div>
      <h6>{{'NO_PAST_BOARDINGS' | translate}}</h6>
    </div>
  </div>
</div>

<div *ngIf="historyMode === 'ongoing'">
  <ul class="historylist mt-1" *ngIf="ongoingBoardings.length">
    <li *ngFor="let ongoingBoarding of ongoingBoardings">
      <div fxLayout="row">
        <div fxFlex fxLayout="column" fxLayout.gt-sm="row">
          <div fxFlex="grow" fxFlex.gt-sm="30" (click)="openViewBoardingModal(ongoingBoarding)" class="px-1">
            <p class="my-1 view-invoice font-weight-medium">{{ongoingBoarding.label}}</p>
          </div>
          <div fxFlex="grow" fxFlex.gt-sm="25" (click)="openViewBoardingModal(ongoingBoarding)" class="px-1">
            <p class="my-1 mat-text-secondary view-invoice">{{ongoingBoarding.petLabel}}</p>
          </div>
          <!--          <div fxFlex="grow" fxFlex.gt-sm="15" class="px-1">-->
          <!--            <p class="my-1 mat-text-secondary">{{pastAppointment.blades}}</p>-->
          <!--          </div>-->
          <div fxFlex="grow" fxFlex.gt-sm="30" class="px-1">
            <p class="my-1 mat-text-secondary">{{ongoingBoarding.notes}}</p>
          </div>
        </div>
        <div fxFlex="nogrow">
          <div class="avatar avatar-50 circle border-3 d-inline-block mx-2" matTooltip="Edit Boarding"
            (click)="openEditAppointmentModal(ongoingBoarding)" *ngIf="!isViewMode">
            <mat-icon class="view-invoice vm">
              edit_calendar
            </mat-icon>
          </div>

          <div class="avatar avatar-50 circle border-3 d-inline-block"
            *ngIf="ongoingBoarding?.boardingBooking?.invoiceId" matTooltip="View Invoice"
            (click)="viewInvoice(ongoingBoarding?.boardingBooking?.invoiceId?.id)">
            <mat-icon class="view-invoice vm">
              receipt
            </mat-icon>
          </div>
        </div>
      </div>
    </li>
  </ul>
  <div class="no-history mt-2" *ngIf="!ongoingBoardings.length">
    <div style="text-align:center;">
      <div class="avatar avatar-100 circle border-3 mb-2 d-inline-block">
        <mat-icon class="h1 h-auto w-auto">timeline</mat-icon>
      </div>
      <h6>{{'NO_ONGOING_BOARDINGS' | translate}}</h6>
    </div>
  </div>
</div>

<div *ngIf="historyMode === 'future'">
  <ul class="historylist mt-1" *ngIf="futureBoardings.length">
    <li *ngFor="let futureBoarding of futureBoardings">
      <div fxLayout="row">
        <div fxFlex fxLayout="column" fxLayout.gt-sm="row">
          <div fxFlex="grow" fxFlex.gt-sm="30" (click)="openViewBoardingModal(futureBoarding)" class="px-1">
            <p class="my-1 view-invoice font-weight-medium">{{futureBoarding.label}}</p>
          </div>
          <div fxFlex="grow" fxFlex.gt-sm="25" (click)="openViewBoardingModal(futureBoarding)" class="px-1">
            <p class="my-1 mat-text-secondary view-invoice">{{futureBoarding.petLabel}}</p>
          </div>
          <!--          <div fxFlex="grow" fxFlex.gt-sm="15" class="px-1">-->
          <!--            <p class="my-1 mat-text-secondary">{{futureAppointment.blades}}</p>-->
          <!--          </div>-->
          <div fxFlex="grow" fxFlex.gt-sm="30" class="px-1">
            <p class="my-1 mat-text-secondary">{{futureBoarding.boardingBooking.notes}}</p>
          </div>
        </div>
        <div fxFlex="nogrow">
          <div class="avatar avatar-50 circle border-3 d-inline-block mx-2" matTooltip="Edit Boarding"
            (click)="openEditAppointmentModal(futureBoarding)" *ngIf="!isViewMode">
            <mat-icon class="view-invoice vm">
              edit_calendar
            </mat-icon>
          </div>
          <div class="avatar avatar-50 circle border-3 d-inline-block" *ngIf="futureBoarding.boardingBooking.invoiceId"
            matTooltip="View Invoice" (click)="viewInvoice(futureBoarding?.boardingBooking?.invoiceId?.id)">
            <mat-icon class="view-invoice vm">
              receipt
            </mat-icon>
          </div>
        </div>
      </div>
    </li>
  </ul>
  <div class="no-history mt-2" *ngIf="!futureBoardings.length">
    <div style="text-align:center;">
      <div class="avatar avatar-100 circle border-3 mb-2 d-inline-block">
        <mat-icon class="h1 h-auto w-auto">timeline</mat-icon>
      </div>
      <h6>{{'NO_UPCOMING_BOARDINGS' | translate}}</h6>
    </div>
  </div>
</div>