import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ContractRoutes } from './contract.routes';
import { FloMaterialModule } from '../flo.material.module';
import { ContractService } from '@savvy/contract';
import { EditContractComponent } from './editContract/editContract.component';
import { MessageService } from '@savvy/messaging';
import { ContractCompService } from '@savvy/contract';
import { EditorModule } from '../editor/editor.module';
import { EicompService } from '@savvy/entity-instance-composite';
import { EntitysService } from '@savvy/entity-instance';
import { EmailmessagetemplatesService } from '@savvy/email-template';
import { TranslateModule } from '@ngx-translate/core';
import { AddContractModalComponent } from './add-contract-modal/add-contract-modal.component';
import { ViewContractModalComponent } from './view-contract-modal/view-contract-modal.component';
import { ListContractsComponent } from './list-contracts/listContracts.component';
import { SharedModule } from '../shared/shared.module';
import { ContractsComponent } from './contracts/contracts.component';
import { SendCustomerContractComponent } from './sendCustomerContract/sendCustomerContract.component';
import { ElementModule } from '../element/element.module';
import { ListContractTemplatesComponent } from './list-contract-templates/listContractTemplates.component';
import { AddEditContractTemplateComponent } from './add-edit-contract-templates/addEditContractTemplates.component';
import {AddEditContractFlagsPanelComponent} from "./send-by-flags/addEditContractFlagsPanel.component";

@NgModule({
  imports: [
    FloMaterialModule,
    EditorModule,
    TranslateModule,
    SharedModule,
    RouterModule.forChild(ContractRoutes),
    ElementModule,
  ],
  providers: [
    ContractService,
    ContractCompService,
    EmailmessagetemplatesService,
    EicompService,
    EntitysService,
    MessageService
  ],
  declarations: [
    ContractsComponent,
    EditContractComponent,
    ListContractsComponent,
    ListContractTemplatesComponent,
    SendCustomerContractComponent,
    AddEditContractTemplateComponent,
    AddContractModalComponent,
    ViewContractModalComponent,
    AddEditContractFlagsPanelComponent
  ],
  exports: [
    ContractsComponent,
    EditContractComponent,
    ListContractsComponent,
    ListContractTemplatesComponent,
    SendCustomerContractComponent,
    AddEditContractTemplateComponent,
    AddContractModalComponent,
    ViewContractModalComponent,
    AddEditContractFlagsPanelComponent
  ]
})

export class AppContractModule { }
