/* eslint-disable @typescript-eslint/member-ordering */
import { Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter, Optional, Inject } from '@angular/core';
import AdyenCheckout from '@adyen/adyen-web';
// import '@adyen/adyen-web/dist/adyen.css';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import {
  CreateCheckoutSessionResponse,
  CreateSubscriptionPayment, CreateSubscriptionPaymentSession,
  SavvyPayPaymentsService,
  SavvyPaySubscriptionPaymentsService
} from '@savvy/savvy-pay';
import { ContextIdDto } from '@savvy/payment';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { MakePayment, SavvyPayPaymentsCompService } from '@savvy/savvy-pay';
import DropinElement from '@adyen/adyen-web/dist/types/components/Dropin';
import { CoreOptions } from '@adyen/adyen-web/dist/types/core/types';
import { PaymentStatus } from '../enum/PaymentStatus';
import { OrgCompositeService, OrgDto } from '@savvy/org';
import { UserId } from '@savvy/invoice';
import {
  Coupon,
  Subscription,
  SubscriptionDefinition,
  SubscriptionPayment,
  SubscriptionPaymentsService, TaxPayment
} from '@savvy/subscription';
import { AddOnDefinition, AddOnsCompService } from '@savvy/add-ons';
import { FloSnackbarComponent } from 'src/app/flo/snackbar/floSnackbar.component';
import {
  MAT_DIALOG_DATA,
  MatDialogRef
} from "@angular/material/dialog";

@Component({
  selector: 'app-savvy-pay',
  templateUrl: './savvy-pay.component.html',
  styleUrls: ['./savvy-pay.component.scss']
})
export class SavvyPayComponent implements OnInit {

  @ViewChild('dropin', { static: true }) dropin: ElementRef;
  // @Input() invoicePaymentDetailsDto: InvoicePaymentDetailsDto | undefined;
  @Input() dueDate: string | undefined = '';
  @Input() channel: MakePayment.ChannelEnum | undefined;
  @Input() customerId: string | undefined = null;
  @Input() customerEmail: string | undefined = null;
  @Input() currency = 'GBP';
  @Input() countryCode = 'gb';
  @Input() returnUrl;
  @Input() failedReturnUrl;
  @Input() staff: Array<UserId> | undefined = null;
  @Input() subscription: Subscription;
  @Input() selectedSubscriptionDef: SubscriptionDefinition;
  @Input() contextIdDto: ContextIdDto;
  @Input() autoRenew: boolean;
  @Input() subscriptionDef: SubscriptionDefinition;
  @Input() discountCoupon: Coupon;
  @Input() referralCode: string;
  @Input() selectedAddOnDefs: Array<AddOnDefinition> = [];
  @Input() planAmount: number;
  @Input() taxPayment: TaxPayment;

  private totalAmount = 0;

  @Input() public set cost(amountToPay: number) {
    this.totalAmount = amountToPay;
    if (this.config && this.checkout && Object.keys(this.checkout).length) {
      this.initAdyenConfig();
    }

  }
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public get cost(): number {
    return this.totalAmount;
  }

  @Output() updateStatus = new EventEmitter<PaymentStatus>();
  org: OrgDto;
  payClicked = false;

  envId = '';
  clientId = environment.SAVVY_PAY_CLIENT_ID;
  error: string | undefined;
  checkout: any;
  config: any;
  dropInElement: DropinElement = {} as DropinElement;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    private notify: FloSnackbarComponent,
    private savvyPaySubscriptionPaymentsService: SavvyPaySubscriptionPaymentsService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private savvyPayPaymentCompService: SavvyPayPaymentsCompService,
    private subscriptionPaymentService: SubscriptionPaymentsService,
    private orgCompApi: OrgCompositeService,
    @Optional() private dialogRef: MatDialogRef<SavvyPayComponent>
  ) {
    if (data) {
      this.contextIdDto = data.contextIdDto;
      this.subscription = data.subscription;
      this.cost = data.cost;
      this.subscriptionDef = data.subscriptionDef;
      this.currency = data.currency;
      this.discountCoupon = data.discountCoupon;
      this.referralCode = data.referralCode;
      this.countryCode = data.countryCode;
      this.autoRenew = data.autoRenew;
      this.returnUrl = data.returnUrl;
      this.failedReturnUrl = data.failedReturnUrl;
      this.selectedAddOnDefs = data.selectedAddOnDefs;
      this.planAmount = data.planAmount;
      this.taxPayment = data.taxPayment;
    }
  }

  ngOnInit() {
    if (this.contextIdDto) {
      this.loadOrg();

    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
  loadOrg() {
    this.orgCompApi.loadOrgFromCtx(this.contextIdDto.contextId).subscribe(res => {
      this.org = res.org;
      this.initAdyenConfig();

    });
  }
  /**
   * Initialize Adyen Configuration.
   */
  initAdyenConfig() {

    const createSubPayment: SubscriptionPayment = {
      subscriptionId: this.subscription.subscriptionId.id,
      amount: this.cost,
      currency: this.currency,
      ownerId: this.contextIdDto.contextId,
      subscriptionDefinitionId: this.subscriptionDef.id,
      subscriptionDefinitionName: this.subscriptionDef.name,
      subscriptionInterval: this.subscription.subscriptionInterval,
      addOnAmount: this.getAddOnAmount(),
      planAmount: this.planAmount,
      status: 'INITIATED',
      taxPayment: this.taxPayment
    };

    this.subscriptionPaymentService.createSubscriptionPayment(createSubPayment).subscribe(res => {
      if (res.id) {
        const createSubscriptionPaymentSession: CreateSubscriptionPaymentSession = {
          contextIdDto: this.contextIdDto,
          cost: this.cost,
          // countryCode: this.selectedSubscriptionDef.coun,
          redirectUrl: this.returnUrl,
          subscriptionId: this.subscription.subscriptionId.id,
          currency: this.org.currencyCode,
          countryCode: this.org.countryCode,
          autoRenew: this.autoRenew,
          subscriptionPaymentId: res.id,
          couponId: this.discountCoupon?.id ? this.discountCoupon.id : null,
          couponCode: this.discountCoupon?.id ? this.discountCoupon.couponCode : null,
          referralCode: this.referralCode
        };

        try {
          if (this.selectedAddOnDefs?.length) {
            sessionStorage.setItem('selectedAddOnDefs', JSON.stringify(this.selectedAddOnDefs));
          }
          this.savvyPaySubscriptionPaymentsService.createSubscriptionPaymentSession(createSubscriptionPaymentSession).subscribe(res => {
            this.configureAdyen(res);
          });
        } catch (error) {
          this.toastr.error('Error occurred during payment', 'Error');
        }
      }
    });

  }

  async configureAdyen(response: CreateCheckoutSessionResponse) {
    const env = environment.production ? 'live' : 'test';
    const billingAddressRequired = this.org && this.org.countryCode === 'US' ? true : false;
    const configuration = {
      environment: env, // Change to one of the environment values specified in step 4.
      clientKey: this.clientId, // Public key used for client-side authentication: https://docs.adyen.com/development-resources/client-side-authentication
      analytics: {
        enabled: true // Set to false to not send analytics data to Adyen.
      },
      session: {
        id: response.id, // Unique identifier for the payment session.
        sessionData: response.sessionData // The payment session data.
      },
      onPaymentCompleted: (result, component) => {
        console.info(result, component);
        this.handleServerResponse(result, component);

      },
      onError: (error, component) => {
        console.error(error.name, error.message, error.stack, component);
      },
      // Any payment method specific configuration. Find the configuration specific to each payment method:  https://docs.adyen.com/payment-methods
      // For example, this is 3D Secure configuration for cards:
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          billingAddressRequired: billingAddressRequired
        }
      }
    };

    this.config = configuration;

    // Create an instance of AdyenCheckout using the configuration object.
    const checkout = await AdyenCheckout(configuration);

    // Create an instance of Drop-in and mount it to the container you created.
    const element = document.querySelector('#dropin-container');
    if (element) {
      checkout.create('dropin').mount(element as HTMLElement);
    }
  }

  getAddOnAmount() {
    let total = 0;
    this.selectedAddOnDefs.forEach(ad => {
      const priceOnCurrency = ad.addOnCurrencies.find(adc => adc.currency === this.currency);
      total += priceOnCurrency.cost;
    });
    return total;
  }

  /**
   * This method is responsible to handle the response coming from make savvy payment.
   */
  private handleServerResponse(res: any, component: any) {

    if (res.action) {
      component.handleAction(res.action);
    } else {
      let status = PaymentStatus.PAID;
      switch (res.resultCode) {
        case 'Authorised':
          status = PaymentStatus.PAID;
          this.toastr.success(`Payment has been successful`, 'Success');
          window.location.href = this.returnUrl;
          break;
        case 'Pending':
          status = PaymentStatus.SUCCESS;
          this.toastr.success(`Payment is currently being processed.`, 'Success');
          break;
        case 'Received':
          status = PaymentStatus.PAID;
          this.toastr.success(`Payment has been successful`, 'Success');
          break;
        default:
          status = PaymentStatus.NONE;
          this.notify.message = 'Payment has been failed. Please try again.';
          this.notify.open();
          window.location.href = this.failedReturnUrl;
          break;
      }
      this.updateStatus.emit(status);

    }
  }
}
