<div class="mdc-px-3 ">
  <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="" class="my-2">
    <div fxFlex>
      <button *ngIf="pet?.id" mat-button color="accent" (click)="addVaccination()"><mat-icon class="vm">add</mat-icon> Add Vaccine</button>
    </div>
    <!--    <div fxFlex="nogrow">-->
    <!--      <button mat-button color="primary"><mat-icon>notifications_active</mat-icon> Send Reminder</button>-->
    <!--    </div>-->
  </div>

  <!-- show if recent due is missed -->
  <div class="alert alert-danger" role="alert" *ngIf="pet?.name && dueVaccinations.length > 0">
    <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="16px">
      <div fxFlex>
        <mat-icon class="mr-5px vm">vaccines</mat-icon> Pet <strong>{{pet.name}}</strong> vaccination/s ({{getDueVaccines()}}) have expired.
      </div>
      <div fxFlex="nogrow">
        <button mat-icon-button (click)="closeExpiredNotif()" class="vm"><mat-icon>close</mat-icon></button>
      </div>
    </div>
  </div>

</div>
<!-- card toggle from add vaccine -->
<!--  <mat-card class="pb-0 mdc-mb-1">-->
<!--    <mat-card-content>-->
<!--      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">-->
<!--        <mat-form-field fxFlex appearance="outline">-->
<!--          <mat-label>{{'VACCINE_NAME' | translate}}</mat-label>-->
<!--          <mat-select>-->
<!--            <mat-option>1</mat-option>-->
<!--          </mat-select>-->
<!--        </mat-form-field>-->
<!--        <mat-form-field fxFlex="25" appearance="outline">-->
<!--          <mat-label>{{'DATE_VACCINATED' | translate}}</mat-label>-->
<!--          <input matInput [matDatepicker]="picker">-->
<!--          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>-->
<!--          <mat-datepicker #picker></mat-datepicker>-->
<!--        </mat-form-field>-->
<!--        <mat-form-field fxFlex="25" appearance="outline">-->
<!--          <mat-label>{{'NEXT_DUE_DATE' | translate}}</mat-label>-->
<!--          <input matInput [matDatepicker]="nextDateDuePicker" disabled>-->
<!--          <mat-datepicker-toggle matSuffix [for]="nextDateDuePicker"></mat-datepicker-toggle>-->
<!--          <mat-datepicker #nextDateDuePicker></mat-datepicker>-->
<!--        </mat-form-field>-->
<!--        <div fxFlex="nogrow">-->
<!--          <button mat-raised-button color="accent" class="mb-2"><mat-icon>save</mat-icon> Save</button>-->
<!--        </div>-->
<!--      </div>-->
<!--    </mat-card-content>-->
<!--  </mat-card>-->

<!-- vaccination list -->
<ul class="historylist mb-0">
  <li *ngFor="let row of vaccinations">
    <div fxLayout="row" fxLayout.gt-sm="row">
      <div fxFlex="60" fxFlex.gt-sm fxLayout="row wrap" fxLayout.gt-sm="row" fxLayoutGap="2%" fxLayoutGap.gt-sm="2%">
        <div fxFlex="48" fxFlex.gt-sm="20" class="px-1">
          <p class="mdc-mb-0">{{row.nameOfVaccination}}</p>
          <p class="mdc-mb-0 small mat-text-secondary">Vaccine</p>
        </div>
        <div fxFlex="48" fxFlex.gt-sm="25" class="px-1">
          <!-- add vaccine time if vaccine service is used -->
          <p class="mdc-mb-0">{{row.dateVaccinated | localizedDate: 'mediumDate'}}</p>
          <p class="mdc-mb-0 small mat-text-secondary">Vaccination date</p>
        </div>
        <div fxFlex="48" fxFlex.gt-sm="25" class="px-1">
          <p class="mdc-mb-0">{{row.nextDateDue | localizedDate: 'mediumDate'}}</p>
          <p class="mdc-mb-0 small mat-text-secondary">Due date</p>
        </div>
      </div>
      <div fxFlex="40" fxFlex.gt-sm="30" class="px-1">
        <p class="mdc-mb-0 mat-text-secondary">{{ row.notes }}</p>
      </div>

      <!--        <div fxFlex="nogrow">-->
      <!--          &lt;!&ndash; if add vaccine manual entry is used &ndash;&gt;-->
      <!--          <div class="avatar avatar-50 circle border-3 d-inline-block " matTooltip="Edit Vaccine Details">-->
      <!--            <mat-icon class="view-invoice vm">edit</mat-icon>-->
      <!--          </div>-->
      <!--        </div>-->
    </div>
  </li>
</ul>

<!-- <div *ngIf="appointment && appointment.appointmentServiceRows && appointment.appointmentServiceRows.length === 0"> -->
<div *ngIf="vaccinations && vaccinations.length === 0">
  <div class="flexbox-parent">
    <div fxLayout="row" fxLayoutAlign="center center">
      <div style="text-align:center;">
        <div class="avatar avatar-100 circle border-3 mdc-mb-1 d-inline-block mt-3">
          <mat-icon style="font-size: 40px; width: 40px; height: 40px;">vaccines</mat-icon>
        </div>
        <h5 class="mdc-mb-1">{{"NO_VACCINATIONS" | translate: {Default: "There is no vaccine details"} }}</h5>
      </div>
    </div>
  </div>
</div>