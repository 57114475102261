import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ContextIdDto } from '@savvy/app';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ClearObservable } from '../../shared/classes/clear-observable';
import {DataListDto, DataItemDto, DatalistService} from "@savvy/datalist";

@Component({
  selector: 'app-pet-breed-selector',
  templateUrl: './pet-breed-selector.component.html'
})
export class PetBreedSelectorComponent extends ClearObservable implements OnInit {
  @Input() contextIdDto: ContextIdDto;
  @Input() selectedPetBreed = new FormControl<string>('');

  @Output() petBreedChanged: EventEmitter<string> = new EventEmitter();

  breeds: Array<DataItemDto>;

  public filteredPetBreeds: ReplaySubject<DataItemDto[]> = new ReplaySubject<DataItemDto[]>(1);
  public petBreedFilter: UntypedFormControl = new UntypedFormControl();

  constructor(
    private dialog: MatDialog,
    private dataListService: DatalistService,
  ) {
    super();
  }

  ngOnInit() {
    this.petBreedFilter.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.filterPetBreeds();
      });
    this.loadBreeds();

    this.selectedPetBreed.valueChanges.subscribe(any => {
      this.petBreedChanged.emit(this.selectedPetBreed.value);
    })
  }

  loadBreeds() {
    this.dataListService.getDataListByName(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      'Breed').subscribe(response => {
      this.breeds = response.dataItems;
      this.filterPetBreeds();

    });
  }

  filterPetBreeds() {
    if (!this.breeds) {
      return;
    }
    // get the search keyword
    let search = this.petBreedFilter.value;
    if (!search) {
      this.filteredPetBreeds.next(this.breeds.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the breeds
    this.filteredPetBreeds.next(
      this.breeds.filter(petType => petType.name.toLowerCase().indexOf(search) > -1)
    );
  }




}
