<ng-scrollbar *ngIf="entityInstanceId != null && visible">
    <mat-card appearance="outlined">
        <mat-card-content>
            <app-view-entity
                    [contextIdDto]="contextIdDto"
                    [entityInstanceId]="entityInstanceId">

            </app-view-entity>
        </mat-card-content>
        <!--
        <mat-card-actions>
            <button mat-button (click)="cancelViewEntity()">Cancel</button>
        </mat-card-actions>
        -->
    </mat-card>
</ng-scrollbar>

