import {
  Component, EventEmitter, Input, OnInit,
  Output, OnChanges, SimpleChanges, HostListener, ElementRef, ViewChild
} from '@angular/core';
import { ContextIdDto } from '@savvy/app';
import { LookAndFeel } from '@savvy/look-and-feel';
import { AppointmentConfigHelperService } from 'src/app/flo/shared/services/appointment-config-helper.service';
import { LookAndFeelConfig, LookAndFeelSharedService, TimeValue } from 'src/app/flo/shared/services/look-and-feel-shared.service';
import { AppointmentConfig } from '@savvy/appointment';
import { CreateBoardingBookingV2SyncService } from '../create-boarding-booking-v2-sync.service';
import * as moment from 'moment';
import { PetStayPreference, PetStayTimeRange } from '@savvy/pet-stay';
import { DateUtils } from 'src/app/flo/dates/DateUtils';
import { WorkingHours } from '@savvy/working-hours';
import { FormControl, FormGroup } from '@angular/forms';
import { FloSnackbarComponent } from 'src/app/flo/snackbar/floSnackbar.component';

export enum SlotEnum {
  FirstHalf = 'FIRST_HALF',
  SecondHalf = 'SECOND_HALF',
  FullDay = 'FULL_DAY'
}

export class PetStayTimeRangeLocal implements PetStayTimeRange {
  date: Date;
  dayNumber: number;
  dayName: string;
  selected: boolean;
  disabled: boolean;
  ownerId: string;
  startDateUtc: string;
  endDateUtc: string;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  slotSelected?: SlotEnum;
  id?: string;
  halfDay?: boolean;
}

// NOTE: hours[0] = Full Day, hours[1] = Morning, hours[2] = Afternoon


@Component({
  selector: 'app-day-care-date-selector',
  templateUrl: './day-care-date-selector.component.html',
  styleUrls: ['./day-care-date-selector.component.scss']
})
export class DayCareDateSelectorComponent implements OnInit, OnChanges {

  @ViewChild('datePickerInput', { static: false }) datePickerInput: ElementRef;

  @Input() contextIdDto: ContextIdDto;
  @Input() totalAppointmentDuration: number;

  @Input() startDate: Date;
  @Output() startDateChange = new EventEmitter();

  @Input() startTime: string;
  @Output() startTimeChange = new EventEmitter();

  @Input() endDate: Date;
  @Output() endDateChange = new EventEmitter();

  @Input() endTime: string;
  @Output() endTimeChange = new EventEmitter();

  @Input() disableTimePickup = false;
  @Input() rebook = false;
  @Input() petStayPreference: PetStayPreference;
  @Input() workingHours: WorkingHours;

  valid = true;

  timeArray: TimeValue[] = [];
  lookAndFeelConfig: LookAndFeelConfig;
  lookAndFeel: LookAndFeel;
  defaultTimeValue: string;
  appointmentConfig: AppointmentConfig;
  endtimeMessage = `Appointment end time will be auto calculated from service duration`;
  dates: PetStayTimeRangeLocal[] = [];
  days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
  ];
  excludeDays = [];

  // dayCareFreq: 'once' | 'weekly' = 'weekly';
  minDate: Date;
  maxDate: Date;
  today = new Date();

  selectedDateRange: PetStayTimeRangeLocal | null;
  selectedDateRangeIndex = -1;
  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  customRange: boolean[] = [];
  constructor(
    private lookAndFeelSharedService: LookAndFeelSharedService,
    public createBoardingBookingV2SyncService: CreateBoardingBookingV2SyncService,
    public appointmentConfigHelperService: AppointmentConfigHelperService,
    private eRef: ElementRef,
    private dateUtils: DateUtils,
    private notify: FloSnackbarComponent
  ) {

  }

  // @HostListener('document:click', ['$event'])
  // clickout(event) {
  //   if (this.eRef.nativeElement.contains(event.target)) {
  //     // this.text = "clicked inside";
  //   } else {
  //     // this.text = "clicked outside";
  //     const focusoutEvent = new Event('focusout');
  //     this.eRef.nativeElement.dispatchEvent(focusoutEvent);
  //     this.datePickerInput.nativeElement.dispatchEvent(focusoutEvent);
  //   }
  // }

  ngOnInit() {
    this.customRange = [];
    this.range.patchValue({
      start: this.startDate,
      end: this.endDate
    })
    this.range.valueChanges.subscribe(value => {
      console.log(value);
      if (value.start && value.end) {
        this.startDate = value.start;
        this.endDate = value.end;
        this.startDateChange.emit(this.startDate);
        this.endDateChange.emit(this.endDate);
        this.enumerateDaysBetweenDates(this.startDate, this.endDate);
      }
    });
    this.appointmentConfig = this.appointmentConfigHelperService.appointmentConfig;

    // if(this.appointmentConfigHelperService.appointmentConfig.customizedTimeForServices) {
    // this.endtimeMessage += ` or specified start & end time`;
    // }
    this.lookAndFeelConfig = this.lookAndFeelSharedService.getLookAndFeelConfig(this.contextIdDto);
    if (this.lookAndFeelConfig) {
      this.lookAndFeel = this.lookAndFeelConfig.lookAndFeel;
      // this.minuteInterval = this.lookAndFeelConfig.minuteInterval;
      this.timeArray = this.lookAndFeelConfig.timeArray;
      this.checkIfValid();
    } else {
      console.log('no look and feel config');
    }
    if (this.createBoardingBookingV2SyncService.editMode) {
      this.excludeDays = [];
      this.dates = [];
      if (this.createBoardingBookingV2SyncService.petStayTimeRanges?.length) {
        let index = 0;
        for (const petStayTimeRange of this.createBoardingBookingV2SyncService.petStayTimeRanges) {
          let day = moment(petStayTimeRange.startDate).clone().format('dddd').toLowerCase();
          const startFirstHalf = this.workingHours[day].hours[1]?.start;
          const endFirstHalf = this.workingHours[day].hours[1]?.end;
          const startSecondHalf = this.workingHours[day].hours[2]?.start;
          const endSecondHalf = this.workingHours[day].hours[2]?.end;
          let slotSelected: SlotEnum;
          if (!this.petStayPreference.bookHourly) {
            if (petStayTimeRange.halfDay) {
              if (petStayTimeRange.startTime === startFirstHalf
                && petStayTimeRange.endTime === endFirstHalf) {
                slotSelected = SlotEnum.FirstHalf;
              } else if (petStayTimeRange.startTime === startSecondHalf
                && petStayTimeRange.endTime === endSecondHalf) {
                slotSelected = SlotEnum.SecondHalf;
              } else if (petStayTimeRange.startTime === startFirstHalf
                || petStayTimeRange.endTime === endFirstHalf) {
                slotSelected = SlotEnum.FirstHalf;
                this.customRange[index] = true;
              } else if (petStayTimeRange.startTime === startSecondHalf
                || petStayTimeRange.endTime === endSecondHalf) {
                slotSelected = SlotEnum.SecondHalf;
                this.customRange[index] = true;
              }
            } else {
              slotSelected = SlotEnum.FullDay;
            }
          }
          this.dates.push({
            ownerId: petStayTimeRange.ownerId,
            date: new Date(petStayTimeRange.startDateUtc),
            dayNumber: moment(petStayTimeRange.startDateUtc).clone().date(),
            dayName: moment(petStayTimeRange.startDateUtc).clone().format('ddd'),
            selected: this.excludeDays.indexOf(moment(petStayTimeRange.startDateUtc).format('dddd')) === -1,
            disabled: this.excludeDays.indexOf(moment(petStayTimeRange.startDateUtc).format('dddd')) > -1,
            startTime: petStayTimeRange.startTime,
            endTime: petStayTimeRange.endTime,
            startDate: petStayTimeRange.startDate,
            endDate: petStayTimeRange.endDate,
            startDateUtc: petStayTimeRange.startDateUtc,
            endDateUtc: petStayTimeRange.endDateUtc,
            slotSelected
          });
          index++;
        }
      } else {
        this.startDate = new Date(this.createBoardingBookingV2SyncService?.boardingBooking?.startDateUtc);
        this.endDate = new Date(this.createBoardingBookingV2SyncService?.boardingBooking?.endDateUtc);
        this.startTime = this.createBoardingBookingV2SyncService?.boardingBooking?.startTime;
        this.endTime = this.createBoardingBookingV2SyncService?.boardingBooking?.endTime;
        this.enumerateDaysBetweenDates(this.startDate, this.endDate);
      }

    } else {
      this.enumerateDaysBetweenDates(this.startDate, this.endDate);
    }

  }

  setMinMaxDates() {
    if (this.startDate) {
      this.minDate = moment(this.startDate).toDate();
    }
    if (this.endDate) {
      this.maxDate = moment(this.endDate).subtract(1, 'day').toDate();
    }
  }

  setStartEndDate() {
    // if (this.dayCareFreq === 'weekly') {
    //   this.endDate = moment(this.startDate).add(4, 'days').toDate();
    // } else {
    //   this.endDate = moment(this.startDate).toDate();
    // }
    this.setMinMaxDates();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.startDate?.currentValue !== changes?.startDate?.previousValue) {
      console.log('loading invoice: ', this.startDate);
      console.log('changes.invoiceId is: ', changes.startDate);
      this.checkIfValid();
    }
  }

  checkIfValid() {
    if (!this.dates.length) {
      this.valid = false;
    }
    // if (this.startDate) {
    //   if (this.allowStartAndEndTime) {
    //     this.valid = this.startTime && this.endTime ? this.startTime <= this.endTime : false;
    //   } else {
    //     this.valid = true;
    //   }
    // } else {
    //   this.valid = false;
    // }
  }

  closedStream($event) {
    const focusoutEvent = new Event('focusout');
    this.eRef.nativeElement.dispatchEvent(focusoutEvent);
    this.datePickerInput.nativeElement.dispatchEvent(focusoutEvent);
  }

  // startDateChanged(event) {
  //   this.startDateChange.emit(this.startDate);
  //   this.endDate = moment(this.startDate).add(1, 'week').endOf('week').toDate();
  //   this.endDateChange.emit(this.endDate);

  //   console.log('startDate ' + this.startDate);
  //   this.checkIfValid();
  //   const focusoutEvent = new Event('focusout');
  //   this.eRef.nativeElement.dispatchEvent(focusoutEvent);
  //   this.datePickerInput.nativeElement.dispatchEvent(focusoutEvent);
  //   this.enumerateDaysBetweenDates(this.startDate, this.endDate);
  // }

  // endDateChanged(event) {
  //   // this.startDateChange.emit(this.startDate);
  //   // this.endDate = this.startDate;
  //   this.endDateChange.emit(this.endDate);

  //   console.log('endDate ' + this.endDate);
  //   this.checkIfValid();
  //   const focusoutEvent = new Event('focusout');
  //   this.eRef.nativeElement.dispatchEvent(focusoutEvent);
  //   this.datePickerInput.nativeElement.dispatchEvent(focusoutEvent);
  //   this.enumerateDaysBetweenDates(this.startDate, this.endDate);

  // }

  startTimeChanged(value: any, index: number) {
    // this.startTimeChange.emit(this.startTime);
    // this.createBoardingBookingV2SyncService.calculateStartEndTime();
    this.checkIfValid();
    this.dates[index].startTime = value;
    const startTime = moment(this.dates[index].startTime, 'HH:mm');
    const endTime = moment(this.dates[index].endTime, 'HH:mm');
    if (startTime.isAfter(endTime)) {
      this.dates[index].startTime = moment(this.dates[index].endTime, 'HH:mm').subtract(1, 'h').format('HH:mm');
      this.notify.message = 'There should be at least one hour selected each day';
      this.notify.open();
    }
    this.setPetStayTimeRange();

  }

  endTimeChanged(value: any, index: number) {
    this.checkIfValid();
    this.dates[index].endTime = value;
    const startTime = moment(this.dates[index].startTime, 'HH:mm');
    const endTime = moment(this.dates[index].endTime, 'HH:mm');
    if (endTime.isBefore(startTime)) {
      this.dates[index].endTime = moment(this.dates[index].startTime, 'HH:mm').add(1, 'h').format('HH:mm');
      this.notify.message = 'There should be at least one hour selected each day';
      this.notify.open();
    }
    this.setPetStayTimeRange();
  }

  compareTimeFn(user1: string, user2: string) {
    // console.log('comparing');
    return user1 && user2 && user1 === user2;
  }

  openCalendar() {
    //
  }

  excludeDaysChange() {
    this.enumerateDaysBetweenDates(this.startDate, this.endDate);
  }

  enumerateDaysBetweenDates(startDate: Date, endDate: Date) {
    const dates: PetStayTimeRangeLocal[] = [];

    const currDate = moment(startDate).startOf('day');
    const lastDate = moment(endDate).startOf('day');
    let startTime = this.startTime;
    let endTime = this.endTime;
    let day = currDate.clone().format('dddd').toLowerCase();
    if (this.createBoardingBookingV2SyncService.workingHours[day]) {
      // Full Day
      startTime = this.createBoardingBookingV2SyncService.workingHours[day].hours[0]?.start;
      endTime = this.createBoardingBookingV2SyncService.workingHours[day].hours[0]?.end;
    }
    if (this.createBoardingBookingV2SyncService.editMode) {
      const timeRange = this.createBoardingBookingV2SyncService.petStayTimeRanges.find(tr => this.dateUtils.getDateAsStringV2(tr.startDate) === this.dateUtils.getDateAsStringV2(currDate));
      if (timeRange) {
        startTime = timeRange?.startTime;
        endTime = timeRange?.endTime;
      }
    }

    dates.push({
      date: currDate.clone().toDate(),
      dayNumber: currDate.clone().date(),
      dayName: currDate.clone().format('ddd'),
      selected: this.excludeDays.indexOf(currDate.format('dddd')) === -1,
      disabled: this.excludeDays.indexOf(currDate.format('dddd')) > -1,
      ownerId: this.contextIdDto.contextId,
      startTime,
      endTime,
      startDate: this.dateUtils.getDateAsStringV2(currDate.clone().toDate()),
      endDate: this.dateUtils.getDateAsStringV2(currDate.clone().toDate()),
      startDateUtc: '',
      endDateUtc: '',
      slotSelected: SlotEnum.FullDay,
    });

    while (currDate.add(1, 'days').diff(lastDate) < 0) {
      console.log(currDate.toDate());
      day = currDate.clone().format('dddd').toLowerCase();
      if (this.createBoardingBookingV2SyncService.workingHours[day]) {
        // Full Day
        startTime = this.createBoardingBookingV2SyncService.workingHours[day].hours[0]?.start;
        endTime = this.createBoardingBookingV2SyncService.workingHours[day].hours[0]?.end;
      }
      if (this.createBoardingBookingV2SyncService.editMode) {
        const timeRange = this.createBoardingBookingV2SyncService.petStayTimeRanges.find(tr => this.dateUtils.getDateAsStringV2(tr.startDate) === this.dateUtils.getDateAsStringV2(currDate));
        if (timeRange) {
          startTime = timeRange?.startTime;
          endTime = timeRange?.endTime;
        }
      }

      dates.push({
        date: currDate.clone().toDate(),
        dayNumber: currDate.clone().date(),
        dayName: currDate.clone().format('ddd'),
        selected: this.excludeDays.indexOf(currDate.format('dddd')) === -1,
        disabled: this.excludeDays.indexOf(currDate.format('dddd')) > -1,
        ownerId: this.contextIdDto.contextId,
        startTime,
        endTime,
        startDate: this.dateUtils.getDateAsStringV2(currDate.clone().toDate()),
        endDate: this.dateUtils.getDateAsStringV2(currDate.clone().toDate()),
        startDateUtc: '',
        endDateUtc: '',
        slotSelected: SlotEnum.FullDay,
      });
    }
    if (this.dateUtils.getDateAsStringV2(startDate) !== this.dateUtils.getDateAsStringV2(endDate)) {
      day = lastDate.clone().format('dddd').toLowerCase();
      if (this.createBoardingBookingV2SyncService.workingHours[day]) {
        // Full Day
        startTime = this.createBoardingBookingV2SyncService.workingHours[day].hours[0]?.start;
        endTime = this.createBoardingBookingV2SyncService.workingHours[day].hours[0]?.end;
      }
      if (this.createBoardingBookingV2SyncService.editMode) {
        const timeRange = this.createBoardingBookingV2SyncService.petStayTimeRanges.find(tr => this.dateUtils.getDateAsStringV2(tr.startDate) === this.dateUtils.getDateAsStringV2(lastDate));
        if (timeRange) {
          startTime = timeRange?.startTime;
          endTime = timeRange?.endTime;
        }
      }
      dates.push({
        date: lastDate.clone().toDate(),
        dayNumber: lastDate.clone().date(),
        dayName: lastDate.clone().format('ddd'),
        selected: this.excludeDays.indexOf(currDate.format('dddd')) === -1,
        disabled: this.excludeDays.indexOf(currDate.format('dddd')) > -1,
        ownerId: this.contextIdDto.contextId,
        startTime,
        endTime,
        startDate: this.dateUtils.getDateAsStringV2(lastDate.clone().toDate()),
        endDate: this.dateUtils.getDateAsStringV2(lastDate.clone().toDate()),
        startDateUtc: '',
        endDateUtc: '',
        slotSelected: SlotEnum.FullDay,
      });
    }
    this.dates = dates;
    // this.selectedDateRange = dates[0];
    // this.selectedDateRangeIndex = 0;
    this.setPetStayTimeRange();
  }

  selectDate(date: PetStayTimeRangeLocal) {
    date.selected = !date.selected;
  }

  getFullDayTimings(date: Date) {
    const day = moment(date).format('dddd').toLowerCase();
    if (this.workingHours) {
      return `${this.workingHours[day]?.hours?.[0]?.start} - ${this.workingHours[day]?.hours?.[0]?.end}`;
    }
    return '';
  }

  dateSelected(date: PetStayTimeRangeLocal, i: number) {
    if (this.selectedDateRangeIndex === i) {
      this.selectedDateRange = null;
      this.selectedDateRangeIndex = -1;
    } else {
      this.selectedDateRange = date;
      this.selectedDateRangeIndex = i;
    }
  }

  getSelectedDates() {
    return this.dates.filter(d => d.selected).map(d => d.date);
  }

  weekendsDatesFilter = (d: Date): boolean => {
    /* Prevent excluded days for select. */
    return this.excludeDays.indexOf(moment(d).format('dddd')) === -1;
  }

  dayCareFreqSelected(event: 'once' | 'weekly') {
    this.setStartEndDate();
  }

  setPetStayTimeRange() {
    const selectedRanges = this.dates.filter(d => d.selected && !d.disabled);
    const petStayTimeRanges: PetStayTimeRangeLocal[] = [];
    for (const date of selectedRanges) {
      petStayTimeRanges.push({
        ...date,
        startDate: this.dateUtils.getDateAsStringV2(date.date),
        endDate: this.dateUtils.getDateAsStringV2(date.date),
        ownerId: this.contextIdDto.contextId,
        startDateUtc: '',
        endDateUtc: ''
      });
    }
    this.createBoardingBookingV2SyncService.petStayTimeRanges = petStayTimeRanges;
    this.createBoardingBookingV2SyncService.totalDays = petStayTimeRanges?.length;
    this.createBoardingBookingV2SyncService.setAppointmentDates(this.startDate, this.endDate, this.startTime, this.endTime);

  }

  slotUpdated(date: PetStayTimeRangeLocal, slotSelected: 'FIRST_HALF' | 'SECOND_HALF' | 'FULL_DAY', index: number) {
    this.customRange[index] = false;
    const day = moment(date.date).format('dddd').toLowerCase();
    switch (slotSelected) {
      case 'FULL_DAY':
        const startFullDay = this.workingHours[day].hours[0]?.start;
        const endFullDay = this.workingHours[day].hours[0]?.end;
        date.startTime = startFullDay;
        date.endTime = endFullDay;
        date.halfDay = false;
        break;
      case 'FIRST_HALF':
        const startFirstHalf = this.workingHours[day].hours[1]?.start;
        const endFirstHalf = this.workingHours[day].hours[1]?.end;
        date.startTime = startFirstHalf;
        date.endTime = endFirstHalf;
        date.halfDay = true;
        break;
      case 'SECOND_HALF':
        const startSecondHalf = this.workingHours[day].hours[2]?.start;
        const endSecondHalf = this.workingHours[day].hours[2]?.end;
        date.startTime = startSecondHalf;
        date.endTime = endSecondHalf;
        date.halfDay = true;
        break;

      default:
        break;
    }
    this.setPetStayTimeRange();

  }

  identify(index, item: PetStayTimeRangeLocal) {
    return item.date;
  }


}
