import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { EventBusService } from './EventBusService';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ContextIdDto, InvoiceDefinition, ViewVaccinationsData } from '@savvy/view-definition';
import { Vaccination, VaccinationService, Vaccine, VaccinesService } from '@savvy/vaccination';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { MatSelectChange } from '@angular/material/select';
import { DateUtils } from '../dates/DateUtils';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
@Component({
  selector: 'app-view-vaccinations-panel',
  templateUrl: 'viewVaccinationsPanel.component.html',
  styles: [`.delete-vaccine-icon {
  cursor: pointer;
  margin-top: 12px;
}`]
})
export class ViewVaccinationsPanelComponent implements OnInit, OnDestroy {

  @Input() viewVaccinationsData: ViewVaccinationsData;
  @Input() viewContext: any;
  @Input() contextIdDto: ContextIdDto;
  @Input() additionalDataMapDto: any;
  @Input() eventBus: EventBusService;
  @Input() customerId: string;

  @Output() taskSelected = new EventEmitter();
  @Output() taskDeselected = new EventEmitter();

  currencyCode: string;
  vaccinesList: Vaccine[] = [];
  petVaccinations: Vaccination[] = [];

  // model: Payment;
  private destroy$ = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notify: FloSnackbarComponent,
    private vaccinationService: VaccinationService,
    private vaccinesService: VaccinesService,
    private dateUtils: DateUtils,
    private dialog: MatDialog,
  ) {
  }


  ngOnInit(): void {
    if (this.viewContext && this.viewContext.customerContextDto) {
      this.customerId = this.viewContext.customerContextDto.customerId.id;
    }
    this.loadVaccineList();
  }

  loadVaccineList() {
    this.vaccinesService.loadVaccineByDeleted(false, this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(res => {
      if (res && res.length) {
        this.vaccinesList = res;
        this.getVaccinationOfPet();
      }
    });
  }

  getVaccinationOfPet() {
    if (this.viewVaccinationsData.petId) {
      this.vaccinationService.getVaccinationsByOwnerIdAndPetId(this.contextIdDto.contextId, this.viewVaccinationsData.petId)
        .subscribe(res => {
          if (res && res.length) {
            this.petVaccinations = res.filter(v => !v.deleted);
            this.mapVaccinationName();
          }
        });
    } else {
      this.petVaccinations = [];
    }
  }

  mapVaccinationName() {
    if (this.petVaccinations?.length && this.vaccinesList?.length) {
      this.petVaccinations.forEach(petVaccination => {
        this.vaccinesList.forEach(vaccination => {
          if (vaccination?.id === petVaccination?.vaccineId) {
            petVaccination.nameOfVaccination = vaccination?.vaccineName;
          }
        });
      });
    }
  }

  vaccinationChange(event: MatSelectChange, index: number) {
    this.updateDueDate(index, this.petVaccinations[index].dateVaccinated);
    this.updateVaccine(event.value, index, 'nameOfVaccination');
  }

  updateDueDate(index: number, dateVaccinated) {
    if (dateVaccinated) {
      this.petVaccinations[index].dateVaccinated = new Date(moment(dateVaccinated).format('YYYY-MM-DD')).toISOString();
      const vaccineGiven = this.vaccinesList.find(v => v.vaccineName === this.petVaccinations[index].nameOfVaccination);
      const validityInDays = vaccineGiven.validityInDays;
      const nextDateDue = new Date(moment(this.petVaccinations[index].dateVaccinated)
        .add(validityInDays, 'days').format('YYYY-MM-DD')).toISOString();
      this.petVaccinations[index].nextDateDue = nextDateDue;
    }
  }

  updateDateVaccinated(dateVaccinated, index: number) {
    this.updateDueDate(index, dateVaccinated);
    this.updateVaccine(this.dateUtils.getDateTimeAsString(dateVaccinated), index, 'dateVaccinated');
  }

  updateVaccine(value, index, property) {
    const params: Vaccination = {
      ...this.petVaccinations[index],
      customerId: this.customerId
    };
    params[property] = value;
    if (params.id) {
      this.vaccinationService.updateVaccination(params).subscribe(res => {
        this.petVaccinations[index] = res;
      });
    } else {
      this.vaccinationService.createVaccination(params).subscribe(res => {
        this.petVaccinations[index] = res;
      });
    }
  }

  compareWithFn(v1: string, v2: string) {
    return v1 && v2 && v1 === v2;
  }

  deleteVaccine(index: number) {
    if (this.petVaccinations[index].id) {
      const confirmDialog = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: 'Are you sure you want to delete this vaccination?'
        },
        height: 'auto',
        width: '400px',
        panelClass: 'helpwindow'
      });

      confirmDialog.afterClosed().subscribe((result: boolean) => {
        if (result) {
          if (this.petVaccinations[index]) {
            this.vaccinationService.deleteVaccination(this.petVaccinations[index].id).subscribe(res => {
              this.notify.message = 'Vaccination deleted successfully';
              this.notify.open();
              this.getVaccinationOfPet();
            });
          }
        }
      });
    } else {
      this.getVaccinationOfPet();
    }
  }


  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
