import { Component, Input, OnInit, Output } from '@angular/core';
import { EventBusService } from '../EventBusService';
import { ContextIdDto } from '@savvy/entity-instance-composite';
import { ViewContextDto } from '@savvy/view-composite';
import { CreateCustomerCompositeLite, CustomerCompositeService, Customer } from '@savvy/customer';
import { EventEmitter } from '@angular/core';
import { PhoneNumber, UserDto, UserId } from '@savvy/view-definition';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserCountryService } from '../../../services/userCountry.service';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';


@Component({
  selector: 'app-new-customer-selector',
  templateUrl: 'newCustomerSelector.component.html'
})
export class NewCustomerSelectorComponent implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() viewContext: ViewContextDto;
  @Input() eventBus: EventBusService;
  @Input() showPlaceholder: boolean;

  @Output() selectedCustomer = new EventEmitter();
  @Output() newExistingSwitch = new EventEmitter();


  newCustomer = false;
  customer;
  // customers: IdNameTupleDto[] = new Array(0);

  firstFormGroup: UntypedFormGroup;
  customerPhone: PhoneNumber;

  createdCustomer: Customer;

  constructor(
    private api: CustomerCompositeService,
    public userCountryService: UserCountryService,
    private notify: FloSnackbarComponent,
    private _formBuilder: UntypedFormBuilder
  ) {

  }

  ngOnInit(): void {
    console.log('inside init for invoice stepper panel');
    this.customerPhone = {} as PhoneNumber;
    this.customerPhone.country = this.userCountryService?.defaultCountryCode;
    this.initFormGroup();
  }

  createCustomer(optIn: boolean) {
    const req = {} as CreateCustomerCompositeLite;
    req.contextIdDto = this.contextIdDto;
    req.firstName = this.firstFormGroup.get('customerFirstName').value;
    req.lastName = this.firstFormGroup.get('customerLastName').value;
    req.email = this.firstFormGroup.get('customerEmail').value;
    req.phone = this.customerPhone;
    req.optIn = optIn;
    // req.onboardCustomer = onboardCustomer;

    this.api.createCustomerCompLite(req).subscribe(response => {
      console.log('got response!');
      this.notify.message = 'Created Customer';
      this.notify.open();
      this.createdCustomer = response.customer;
      this.newCustomer = false;
      // this.customers = new Array();
      // this.customers.push();
      this.selectedCustomer.emit(this.createdCustomer.id);
    }, (error) => {
      console.log('err is', error);
      if (error && error.status === 409) {
        this.notify.message = `Customer already exists with email ${req.email}`;
        this.notify.open();
      } else {
        this.notify.message = error.error;
        this.notify.open();
      }
    }
    );
  }

  onMobilePhoneChange(value) {
    this.customerPhone = value;
  }

  onMobileCountryChange($event: any) {
    console.log('onMobileCountryChange', $event);
    this.customerPhone.country = $event.iso2;
  }

  setUserOptVal(user: UserDto): UserId {
    return { id: user.id, userContextType: UserId.UserContextTypeEnum.UserId };
  }

  compareUserFn(user1: UserDto, user2: UserDto) {
    return user1 && user2 && user1.id === user2.id;
  }

  private initFormGroup() {
    this.firstFormGroup = this._formBuilder.group({
      customerFirstName: ['', Validators.required],
      customerLastName: ['', Validators.required],
      customerEmail: [''],
      customerPhone: ['']
    });
  }
}

