<mat-toolbar>
  <mat-toolbar-row>{{'Create ' + entityDefinitionLabel | translate}}</mat-toolbar-row>
</mat-toolbar>

<div fxLayout="row" fxLayoutWrap="nowrap" fxLayoutAlign="space-between center" class="pt-1" *ngIf="package">

  <form>

    <div fxLayout.gt-xs="row" fxLayout="column" fxLayoutWrap="wrap" class="split-panel">
      <mat-form-field appearance="outline">
        <mat-label>Package Name</mat-label>
        <input matInput placeholder="Package Name" name="packageName" [(ngModel)]="package.packageName"
               #packageName="ngModel" required>

        <mat-error *ngIf="packageName.hasError('required')">
          {{ 'Name is' | translate}} <strong>{{'required' | translate}}</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div fxLayout.gt-xs="row" fxLayout="column" fxLayoutWrap="wrap" class="split-panel">

      <mat-form-field appearance="outline">
        <mat-label>Duration In Mins</mat-label>
        <input matInput placeholder="Duration In Mins" type="text" appNumberOnly name="packageDurationMins"
               [(ngModel)]="package.packageDurationMins" #packageDurationMins="ngModel" required>
      </mat-form-field>
    </div>

    <div fxLayout.gt-xs="row" fxLayout="column" fxLayoutWrap="wrap" class="split-panel">

      <mat-form-field appearance="outline">
        <mat-label>Package Price</mat-label>
        <input matInput type="text" appNumberOnly [decimalPoints]="2" placeholder="Package Price" name="packagePrice"
               [(ngModel)]="package.packagePrice"
               #packagePrice="ngModel" required>
      </mat-form-field>
    </div>

    <div fxLayout.gt-xs="row" fxLayout="column" fxLayoutWrap="wrap" class="split-panel">

      <mat-form-field appearance="outline">
        <mat-label>Color</mat-label>
        <input matInput type="text" placeholder="Color" name="Color" [value]="package.packageColour"
               [style.background]="package.packageColour" [(colorPicker)]="package.packageColour" [cpOKButton]="true"
               [cpOutputFormat]="'hex'"
               [cpSaveClickOutside]="false" [cpOKButtonClass]="'mat-raised-button mat-accent'"
               (colorPickerSelect)="updateColor($event)">
      </mat-form-field>
    </div>
  </form>
</div>
<div fxLayout="row" fxLayoutAlign="end">
  <button mat-raised-button color="accent" (click)="addNew()">{{'Add New' | translate}}</button>
</div>
