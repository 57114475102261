import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfService } from '../config/conf.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalMenuService } from '../menu/local-menu.service';
import { LanguageService } from '../../services/language.service';
import { UserCountryService } from '../../services/userCountry.service';
import { UserCurrencyService } from '../shared/services/userCurrency.service';
import { LoadPostLoginDataResponse, UiService } from '@savvy/ui';
import { TrialExpiredStateService } from '../shared/services/trial-expired-state.service';
import { APP_TYPE_ENUM, ContextService } from '../context.service';
import { LookAndFeelLocalService } from '../../services/lookAndFeel.service';

import { LookAndFeelSharedService } from '../shared/services/look-and-feel-shared.service';
import { IconService } from '../logo/icon.service';
import { SmsCreditStateService } from '../shared/services/sms-credit-state.service';
import { UserDefaultLocationService } from '../../services/user-default-location.service';
import { PortalSettingsLocalService } from '../../services/portalSettingsLocal.service';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { GLOBAL } from 'src/app/app.constants';
import { MatDialog } from '@angular/material/dialog';

import { ContextIdDto } from '@savvy/menu';
import { ProductMetaLocalService } from '../../services/productMeta.service';
import { DataShareService } from 'src/app/core/data-share.service';
import { ServicesHelperService } from '../shared/services/services-helper.service';
import { Router } from '@angular/router';
import { OrgSetupHelperService } from '../shared/services/org-setup-helper.service';
import { WelcomeOrgSetupService } from '../welcome-to-savvy/welcome-org-setup.service';
import { OrgDto } from '@savvy/org';
import { SubscriptionHelperService } from '../shared/services/subscription-helper.service';
import { CookiesService } from '../shared/services/cookies.service';
import { SmsCreditService } from '../shared/services/sms-credit.service';
import { RegistrationService } from '@savvy/registration';
import { PermissionsHelperService } from '../shared/services/permissions-helper.service';
import { SessionStorageService, AuthData } from '../shared/services/local-storage.service';
import { LocaleService } from "../../services/locale.service";
import { FlagDefHelperService } from "../../services/flagDefHelper.service";
// import { MenuService } from '@savvy/menu';
export class EnvRole {
  appId: string;
  appType: APP_TYPE_ENUM;
  envId: string;
  roleId: string;
  roleName: string;
}
declare var window: any;

// @Directive()
@Injectable()
export class UiAuthService {

  token: string | null;
  isLoggedIn = false;
  isTrialExpired = false;
  isLoggedInFromBo = false;
  contextIdDto: ContextIdDto;
  constructor(
    public http: HttpClient,
    private flagDefHelperService: FlagDefHelperService,
    private localMenuService: LocalMenuService, // Force instantiation
    private uiApi: UiService,
    private iconService: IconService,
    private contextService: ContextService,
    private productMetaLocalService: ProductMetaLocalService,
    private languageService: LanguageService,
    private portalSettingsLocalService: PortalSettingsLocalService,
    private userCountryService: UserCountryService,
    private lookAndFeelLocalService: LookAndFeelLocalService,
    private lookAndFeelSharedService: LookAndFeelSharedService,
    private trialExpiredStateService: TrialExpiredStateService,
    private smsCreditStateService: SmsCreditStateService,
    private orgSetupHelperService: OrgSetupHelperService,
    private userCurrencyService: UserCurrencyService,
    private userDefaultLocationService: UserDefaultLocationService,
    private localeService: LocaleService,
    private dialog: MatDialog,
    private dataShareService: DataShareService,
    private router: Router,
    private welcomeOrgSetupService: WelcomeOrgSetupService,
    public servicesHelperService: ServicesHelperService,
    public notify: FloSnackbarComponent,
    private subscriptionHelperService: SubscriptionHelperService,
    private registrationService: RegistrationService,
    private permissionsHelperService: PermissionsHelperService,
    private sessionStorageService: SessionStorageService,
    private cookieService: CookiesService


  ) {
    this.setLoggedInState();
  }

  public setLoggedInState() {
    this.token = this.sessionStorageService.getToken();
    if (this.token) {
      this.isLoggedIn = true;
    }
  }

  public setTokenFromRegistrationAttempt(registrationAttemptId: string) {
    this.registrationService.getToken(registrationAttemptId).subscribe(response => {
      if (response?.success && response?.accessToken) {
        this.sessionStorageService.setTokenAfterRegistration(response?.accessToken);
        this.isLoggedIn = true;
        this.router.navigate(['/']);
      }
    });
  }

  login(username: string, password: string, envId: string): Observable<AuthData> {
    const creds = 'username=' + username + '&password=' + password + '&grant_type=password&scope=write';
    const authorization = 'Basic ' + btoa('foo:bar');
    let headers = new HttpHeaders().set('Authorization', authorization);
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers = headers.append('envId', envId);
    const url = ConfService.apiUrl();

    return this.http.post(
      url + '/oauth/token',
      creds,
      {
        headers
      }
    ) as Observable<AuthData>;
  }

  getAuthorizationHeader() {
    const token = this.sessionStorageService.getToken();
    // console.log('got token from local storage [' + token + ']');
    // console.log("loaded token from local storage " + token);

    if (token == null || typeof (token) === 'undefined' || token.length === 0) {
      return null;
    } else {
      const authValue = 'Bearer ' + token;
      // console.log('found token so adding Authorization header [' + authValue + ']');
      return authValue;
    }
  }

  anon() {
    const creds = 'grant_type=custom-grant&scope=write';
    const authorization = 'Basic ' + btoa('foo:bar');
    let headers = new HttpHeaders().set('Authorization', authorization);
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');

    const url = ConfService.apiUrl();
    return this.http.post(
      url + '/oauth/token',
      creds,
      {
        headers
      }
    );
  }

  handleAnonToken(data) {
    if (data?.access_token) {
      this.token = data.access_token;
      this.sessionStorageService.setTokenAfterRegistration(this.token);
    }
  }

  logout() {
    this.token = undefined;
    this.userDefaultLocationService.setLocationId(null);
    this.isLoggedIn = false;
    this.contextService.setContextDto(undefined);
    this.sessionStorageService.clear();
    this.cookieService.deleteAll();
  }

  loadPostLoginData(data, entry = false) {
    return new Promise<LoadPostLoginDataResponse>(resolve => {
      if (data) {
        this.sessionStorageService.setAuthData(data);
        this.uiApi.getPostLoginData()
          .subscribe((loadPostLoginDataResponse: LoadPostLoginDataResponse) => {
            if (loadPostLoginDataResponse) {
              window.Intercom("boot", {
                api_base: "https://api-iam.intercom.io",
                app_id: "omidcemf",
                name: loadPostLoginDataResponse.fullName, // Full name
                email: loadPostLoginDataResponse.userName, // the email for your user
                created_at: Date.now(), // Signup date as a Unix timestamp
                custom_launcher_selector:'#intercom-launcher',
              });
              window.Intercom('update', { "hide_default_launcher": true });
              this.setPostLoginData(loadPostLoginDataResponse);
              resolve(loadPostLoginDataResponse);
              if (GLOBAL.firstSignupSettingsON) {
                this.orgSetupHelperService.setSettingsInitialised(
                  loadPostLoginDataResponse.orgSettingsInitialised,
                  loadPostLoginDataResponse.orgId
                );
                if (!loadPostLoginDataResponse.orgSettingsInitialised
                  && (loadPostLoginDataResponse.appType === APP_TYPE_ENUM.PET_GROOMING_SALON
                    || loadPostLoginDataResponse.appType === APP_TYPE_ENUM.PET_DAY_CARE
                    || loadPostLoginDataResponse.appType === APP_TYPE_ENUM.HAIR_SALON
                    || loadPostLoginDataResponse.appType === APP_TYPE_ENUM.BARBERS
                    || loadPostLoginDataResponse.appType === APP_TYPE_ENUM.NAIL_SALON
                    || loadPostLoginDataResponse.appType === APP_TYPE_ENUM.BEAUTY_SALON
                    || this.contextService.appType === APP_TYPE_ENUM.VETS
                    || this.contextService.appType === APP_TYPE_ENUM.GROOM_SCHOOL
                  )) {
                  this.welcomeOrgSetupService.loadOrg().then((org: OrgDto) => {
                    this.orgSetupHelperService.loadOrgSetup(org.id);
                    this.router.navigate([`welcome/step/${Number(this.welcomeOrgSetupService.org.orgSetupStateDto.completedSetupStep) + 1}`,
                    this.contextIdDto.contextId, this.contextIdDto.contextIdType]);
                  });
                }
              } else if (entry) {
                this.router.navigate(['/']);
              }
            }
          });
      }

    })
  }

  setPostLoginData(loadPostLoginDataResponse: LoadPostLoginDataResponse) {
    this.isLoggedIn = true;
    const authData = this.sessionStorageService.getAuthData();
    if (authData) {
      authData.userId = loadPostLoginDataResponse?.userId;
      authData.userFirstName = loadPostLoginDataResponse?.firstName;
      authData.userLastName = loadPostLoginDataResponse?.lastName;
      authData.userFullName = loadPostLoginDataResponse?.fullName;
      authData.activePayingSubscription = loadPostLoginDataResponse?.subscriptionState?.subscriptionStatus === 'VALID_SUBSCRIPTION';
      authData.employerOrgId = loadPostLoginDataResponse.orgId?.id;
      authData.countryCode = loadPostLoginDataResponse.countryCode;
      authData.currencyCode = loadPostLoginDataResponse.currencyCode;
      authData.languageTag = loadPostLoginDataResponse.languageCode;
      authData.subscriptionDefinitionId = loadPostLoginDataResponse.subscriptionState?.subscriptionDefinitionId?.id;
      authData.subscriptionId = loadPostLoginDataResponse.subscriptionState?.subscriptionId?.id;
      this.sessionStorageService.setAuthData(authData);
    }

    this.userCurrencyService.setCurrencyCode(loadPostLoginDataResponse?.currencyCode);
    this.userCountryService.setCountryCode(loadPostLoginDataResponse.countryCode);
    this.localeService.languageChanged(loadPostLoginDataResponse.languageCode);

    this.flagDefHelperService.setFlagDefs(loadPostLoginDataResponse.flagDefinitions)

    this.localeService.countryCodeChanged(loadPostLoginDataResponse.countryCode);

    this.contextService.setAppType(loadPostLoginDataResponse.appType);
    if (loadPostLoginDataResponse.envId) {
      console.log('setting context ' + loadPostLoginDataResponse.envId.id);
      this.contextIdDto = { contextId: loadPostLoginDataResponse.envId.id, contextIdType: 'ENV_ID' };
      this.contextService.setContextDto(this.contextIdDto);
      this.subscriptionHelperService.loadCurrent(this.contextIdDto).then(() => {
        this.subscriptionHelperService.checkSubscriptionStatus(this.contextIdDto, loadPostLoginDataResponse.countryCode);
      });
    } else {
      console.log('error: no context returned');
    }
    // if (loadPostLoginDataResponse.location) {
    this.dataShareService.loadDataForQuickAppointment(this.contextIdDto);
    this.permissionsHelperService.loadRoles(this.contextIdDto);
    this.permissionsHelperService.updateRoleAssignments();
    // }

    console.log('loadPostLoginDataResponse.subscriptionState state ', loadPostLoginDataResponse.subscriptionState);

    this.subscriptionHelperService.setSubscriptionState(loadPostLoginDataResponse.subscriptionState);

    this.portalSettingsLocalService.setPortalEnabled(loadPostLoginDataResponse.portalEnabled);

    this.localMenuService.setMenuStructure(loadPostLoginDataResponse.menuStructure);

    // const appsMenu: any = sessionStorage.getItem('adminMenuType');
    // if (!GLOBAL.appsMenu || (GLOBAL.appsMenu && !appsMenu)) {
    //   if (!this.localMenuService.menuInitiated && this.contextIdDto) {
    //     this.localMenuService.initMenu(loadPostLoginDataResponse.menuStructure, this.contextIdDto);
    //   }
    // }

    if (loadPostLoginDataResponse.appType) {
      this.iconService.initAppType(loadPostLoginDataResponse.appType);
    }
    this.languageService.setLocale(loadPostLoginDataResponse.locale);
    this.userCountryService.setCountryCode(loadPostLoginDataResponse.countryCode);
    this.lookAndFeelLocalService.setLookAndFeel(loadPostLoginDataResponse.lookAndFeel);
    this.lookAndFeelSharedService.setLookAndFeel(loadPostLoginDataResponse.lookAndFeel);
    this.userCurrencyService.setCurrencyCode(loadPostLoginDataResponse.currencyCode);

    if (loadPostLoginDataResponse.productMetadata) {
      this.productMetaLocalService.set(loadPostLoginDataResponse.productMetadata);
    }

    //this.userDefaultLocationService.init(loadPostLoginDataResponse.envId.id);
    console.log('loadPostLoginDataResponse.subscriptionState', loadPostLoginDataResponse.subscriptionState);
    this.trialExpiredStateService.setSubscriptionState(loadPostLoginDataResponse.subscriptionState);
    console.log('loadPostLoginDataResponse.smsCreditSummaryDto', loadPostLoginDataResponse.smsCreditSummaryDto);
    this.smsCreditStateService.setSmsCreditSummary(loadPostLoginDataResponse.smsCreditSummaryDto);

  }



  orgSetup(contextIdDto) {
    // const dialogRef = this.dialog.open(SetupDialogComponent, {
    //   width: '98%',
    //   panelClass: ['scrollable-modal', 'helpwindow'],
    //   data: {
    //     contextIdDto,
    //     orgId: this.orgSetupHelperService.orgId
    //   }
    // });

    // dialogRef.afterClosed().subscribe(() => {
    //   console.log('The dialog was closed');
    // });
    // this.matSnackBarRef.dismiss();
  }
}
