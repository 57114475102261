<div fxFlex="500px" fxFlex.xs="300px" *ngIf="meeting">
  <div *ngIf="showSpinner" class="bg-spinner-wrapper">
    <mat-spinner style="margin: 0 auto;"></mat-spinner>
  </div>
  <mat-toolbar color="primary">
    <mat-toolbar-row>{{'CREATE_MEETING' | translate}}</mat-toolbar-row>
  </mat-toolbar>

  <mat-card appearance="outlined">
    <mat-card-content>
      <form fxLayout="column">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>{{'DESCRIPTION' | translate}}</mat-label>

          <input matInput [(ngModel)]="meeting.description" name="description" #description="ngModel"
            placeholder="{{'DESCRIPTION' | translate}}" required>
          <mat-error *ngIf="description.hasError('required')">
            {{'DESCRIPTION_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>{{'NOTES' | translate}}</mat-label>

          <textarea matInput [(ngModel)]="meeting.notes" rows="4" name="notes" #notes="ngModel"
            placeholder="{{'NOTES' | translate}}">
          </textarea>

        </mat-form-field>

        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>{{'START' | translate}}</mat-label>

          <input matInput [(ngModel)]="meeting.start" name="start" (focus)="startPicker.open()"
            (click)="startPicker.open()" #start="ngModel" placeholder="{{'START' | translate}}" required
            [owlDateTimeTrigger]="startPicker" [owlDateTime]="startPicker" (ngModelChange)="startDateChange($event)">
          <mat-icon matSuffix>event</mat-icon>

          <owl-date-time #startPicker [panelClass]="'ios-datePicker'"></owl-date-time>
          <mat-error *ngIf="start.hasError('required')">
            {{'START_DATE_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>{{'END' | translate}}</mat-label>
          <input matInput [(ngModel)]="meeting.end" name="end" (focus)="endPicker.open()" (click)="endPicker.open()"
            [owlDateTimeTrigger]="endPicker" [owlDateTime]="endPicker" #end="ngModel"
            placeholder="{{'END' | translate}}" required>
          <mat-icon matSuffix>event</mat-icon>

          <owl-date-time #endPicker [panelClass]="'ios-datePicker'"></owl-date-time>
          <mat-error *ngIf="end.hasError('required')">
            {{'END_DATE_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field style="width: 100%" appearance="outline">
          <mat-label>{{'USER' | translate}}</mat-label>

          <mat-select placeholder="{{'USER' | translate}}" [compareWith]="compareUserFn" name="userInput"
            #userInput="ngModel" [(ngModel)]="meeting.userId" required>
            <mat-option *ngFor="let user of users" [value]="setUserOptVal(user)">{{user.firstName}} {{user.lastName}}
            </mat-option>
          </mat-select>

          <mat-error *ngIf="userInput.hasError('required')">
            {{'USER_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
          </mat-error>

        </mat-form-field>

      </form>
    </mat-card-content>
  </mat-card>
  <mat-card appearance="outlined">
    <mat-card-content>
      <button mat-raised-button color="accent"
        [disabled]="!meeting.id && (!meeting.end || !meeting.start || !meeting.userId || !meeting.description)"
        (click)="createMeeting()">{{'CREATE' | translate}}</button>
      &nbsp;
      <button mat-raised-button color="accent"
        [disabled]="meeting.id && (!meeting.end || !meeting.start || !meeting.userId || !meeting.description)"
        (click)="updateMeeting()">{{'UPDATE' | translate}}</button>
      &nbsp;
      <button mat-raised-button (click)="close()">{{'CANCEL' | translate}}</button>
    </mat-card-content>
  </mat-card>
</div>