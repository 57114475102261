import {Directive, ElementRef, HostListener, Input, Renderer2} from '@angular/core';
import {Observable} from 'rxjs';

declare const EXIF: any;

@Directive({ selector: '[appFixImageOrientation]' })
export class FixImageOrientationDirective {
  originSrc: string;
  afterLoadSrc: string;
  @Input() set appFixImageOrientation(value: string) {
    this.hideImage();
    this.originSrc = value;
  }

  @HostListener('load') onLoad() {
    if (this.isIPhone() || this.originSrc === this.afterLoadSrc) {
      this.showImage();
      return;
    }
    this.afterLoadSrc = this.originSrc;
    this.getEXIFData(this.el.nativeElement).subscribe((context) => {
      const orientation = EXIF.getTag(context, 'Orientation');
      this.resetOrientation(this.el.nativeElement.src, orientation, (value) => {
        this.renderer.setAttribute(this.el.nativeElement, 'src', value);
        this.showImage();
      });
    });
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  private getEXIFData(image) {
    return new Observable(observer => {
      EXIF.getData(image, () => {
        observer.next(this);
        return observer.complete();
      });
    });
  }

  private isIPhone() {
    return (
      (navigator.platform.indexOf('iPhone') !== -1) ||
      (navigator.platform.indexOf('iPod') !== -1)
    );
  }

  private showImage() {
    this.renderer.setStyle(this.el.nativeElement, 'visibility', 'visible');
    this.renderer.removeClass(this.el.nativeElement.parentElement, 'img-spinner');
  }
  private hideImage() {
    this.renderer.setStyle(this.el.nativeElement, 'visibility', 'hidden');
    this.renderer.addClass(this.el.nativeElement.parentElement, 'img-spinner');
  }

  private resetOrientation(src, srcOrientation, callback) {
    const img = new Image();
    img.onload = function() {
      const width = img.width;
      const height = img.height;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // set proper canvas dimensions before transform & export
      if (4 < srcOrientation && srcOrientation < 9) {
        canvas.width = height;
        canvas.height = width;
      } else {
        canvas.width = width;
        canvas.height = height;
      }

      // transform context before drawing image
      switch (srcOrientation) {
        case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
        case 3: ctx.transform(-1, 0, 0, -1, width, height ); break;
        case 4: ctx.transform(1, 0, 0, -1, 0, height ); break;
        case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
        case 6: ctx.transform(0, 1, -1, 0, height , 0); break;
        case 7: ctx.transform(0, -1, -1, 0, height , width); break;
        case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
        default: break;
      }

      // draw image
      ctx.drawImage(img, 0, 0);

      // export base64
      callback(canvas.toDataURL());
    };
    img.crossOrigin = '';
    img.src = src;
  }
}
