<mat-toolbar color="primary">
  <mat-toolbar-row>
    <h2>{{'PAID_PLANS' | translate}}</h2>
  </mat-toolbar-row>
</mat-toolbar>

<br>

<mat-card appearance="outlined" class="demo-card demo-basic">
  <mat-card-content>
<!--    <h6 *ngIf="hasBalance">Remaining Balance: <strong>{{ this.selectedPlan.balance }}</strong></h6>-->
<!--    <h6 *ngIf="!hasBalance && selectedPlan.id && selectedPlan.balance != null">This plan doesn't have enough balance for payment. Remaining balance <strong>{{ this.selectedPlan.balance }}</strong></h6>-->
    <form #paymentForm="ngForm" class="paid-plans w-100">

      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>{{'PLANS' | translate}}</mat-label>
        <mat-select
          [(ngModel)]="request.planId" name="planId"
          id="planId"
          required="true"
          placeholder="{{'PLANS' | translate}}"  (selectionChange)="selectPlan($event)" required>
          <ng-container *ngFor="let plan of plans">
            <mat-option
              [value]="plan.id">{{ plan.planName }}
            </mat-option>
          </ng-container>

        </mat-select>
      </mat-form-field>
      <mat-hint *ngIf="selectedPlan.unlimitedSessions">{{'PLAN_CAN_BE_USED_UNLIMITED' | translate}}</mat-hint>
      <mat-hint *ngIf="selectedPlan.sessionsRemaining">Only {{selectedPlan.sessionsRemaining}} {{'NUMBER_OF_SESSIONS_REMAINING' | translate}}</mat-hint>
      <mat-hint *ngIf="selectedPlan.sessionsRemaining == 0" class="mat-text-red">{{'PLAN_HAS_NO_SESSIONS' | translate}}</mat-hint>
      <br><mat-hint *ngIf="!hasPayments && selectedPlan?.id" class="mat-text-red">{{'PLAN_HAS_NO_PAYMENTS' | translate}}</mat-hint>
      <br><br>
      <button mat-raised-button color="primary"
              [disabled]="!paymentForm.valid
              && !request.planId
              || (!selectedPlan.unlimitedSessions && selectedPlan.sessionsRemaining == 0)
              || !hasPayments"
              (click)="applyPayment()">{{'PAY' | translate}}</button>&nbsp;&nbsp;&nbsp;
      <button mat-raised-button (click)="closeDialog()">{{'CANCEL' | translate}}</button>
    </form>

  </mat-card-content>
</mat-card>
