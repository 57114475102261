<div fxLayout="row" fxLayoutGap="16px">

  <div fxFlex="nogrow">
    <div class="avatar avatar-50 circle border-3 ">
      <mat-icon>pets</mat-icon>
    </div>
  </div>
  <div fxFlex>
    <mat-form-field appearance="outline" class="w-100 petselector">
      <mat-label>{{'SELECT_PET' | translate: {Default: 'Select Pet'} }}</mat-label>
      <mat-chip-grid #chipList aria-label="Pet selection">
        <mat-chip-row *ngFor="let pet of createPetAppointmentV2SyncService.petsSelectedChips; let rowIndex = index"
          (removed)="remove(rowIndex)">
          <span [innerHTML]="pet | translate"></span>
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
        <input [placeholder]="placeholder | translate" id="appointment-pet-selector"
          [hidden]="!multiple && createPetAppointmentV2SyncService.petsSelectedChips.length" #petInput
          [formControl]="searchControl" [matAutocomplete]="petAuto" [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
      </mat-chip-grid>
      <mat-autocomplete #petAuto="matAutocomplete" (optionSelected)="gotoInstance($event)"
        autoActiveFirstOption="false">
        <mat-option [value]="''">
          <button type="button" mat-raised-button color="accent" (click)="createPetFromList()">
            {{'CREATE_PET' | translate: {Default: 'Create Pet'} }}
          </button>
        </mat-option>
        <mat-option class="loading-inner" disabled *ngIf="loadingInner">
          <mat-spinner diameter="35"></mat-spinner>
        </mat-option>
        <mat-option disabled *ngIf="searchResults?.length === 0 && !loadingInner && searchControl.value">
          {{'NO_DATA_FOUND' | translate: {Default: 'No Data Found'} }}
        </mat-option>
        <mat-option *ngFor="let option of filteredSearchResults | async" [value]="option"
          [innerHTML]="option.label | translate">
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <app-customer-stats #customerStatsComponent
      *ngIf="!quickAppointment && createPetAppointmentV2SyncService.petsSelected.length" [contextIdDto]="contextIdDto"
      [customerId]="customerId" [customerName]="createPetAppointmentV2SyncService.petsSelected[0]?.customerName">
    </app-customer-stats>

  </div>
</div>
