<div fxLayout="row wrap" fxLayout.gt-xs="row wrap" fxLayoutAlign=" center" fxLayoutGap="10px" fxLayoutGap.gt-sm="15px"
  class="rounded-10 border mdc-px-3 pt-1 mb-2" [formGroup]="repeatFilterForm">
  <div fxFlex="46" fxFlex.gt-xs="35" fxFlex.gt-sm="18">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>{{'NUM_APPOINTMENTS' | translate: {Default: 'Number of Appointments'} }}</mat-label>
      <input matInput type="number" max="52" appNumberOnly placeholder="Number Of Times" name="numAppointments"
        formControlName="numAppointments" id="numAppointments">
    </mat-form-field>
  </div>
  <div fxFlex="46" fxFlex.gt-xs="80px" fxFlex.gt-sm="10">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>{{'EVERY' | translate: {Default: 'Every'} }}</mat-label>
      <input matInput type="text" appNumberOnly placeholder="Every" name="frequency" formControlName="frequency"
        id="frequency">
    </mat-form-field>
  </div>
  <div fxFlex="46" fxFlex.gt-xs="100px" fxFlex.gt-sm="15">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Period</mat-label>
      <mat-select formControlName="period">
        <mat-option *ngFor="let period of periods" [value]="period">{{ period }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div fxFlex="46" fxFlex.gt-xs="30" fxFlex.gt-sm="15">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>{{'SELECT_DAY' | translate: {Default: 'Select day'} }}</mat-label>
      <mat-select formControlName="selectedDay">
        <mat-option value="SUNDAY">{{'SUNDAY' | translate: {Default: 'Sunday'} }}</mat-option>
        <mat-option value="MONDAY">{{'MONDAY' | translate: {Default: 'Sunday'} }}</mat-option>
        <mat-option value="TUESDAY">{{'TUESDAY' | translate: {Default: 'Tuesday'} }}</mat-option>
        <mat-option value="WEDNESDAY">{{'WEDNESDAY' | translate: {Default: 'Monday'} }}</mat-option>
        <mat-option value="THURSDAY">{{'THURSDAY' | translate: {Default: 'Thursday'} }}</mat-option>
        <mat-option value="FRIDAY">{{'FRIDAY' | translate: {Default: 'Friday'} }}</mat-option>
        <mat-option value="SATURDAY">{{'SATURDAY' | translate: {Default: 'Saturday'} }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div fxFlex="46" fxFlex.gt-xs="32" fxFlex.gt-sm="15">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>{{'STARTING_FROM' | translate: {Default: 'Starting From:'} }}</mat-label>
      <mat-select formControlName="startFrom" (selectionChange)="startFromChanged()">
        <mat-option value="TODAY">{{'TODAY' | translate: {Default: 'Today'} }}</mat-option>
        <mat-option value="NEXT_WEEK">{{'NEXT_WEEK' | translate: {Default: 'Next week'} }}</mat-option>
        <mat-option value="SPECIFY_DATE">{{'SPECIFIY_DATE' | translate: {Default: 'Specify Date'} }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div fxFlex="46" fxFlex.gt-xs="32" fxFlex.gt-sm *ngIf="repeatFilterForm?.value?.startFrom === 'SPECIFY_DATE'">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Choose a date</mat-label>
      <input matInput #datePickerInput [matDatepicker]="picker" formControlName="startDate" (focus)="picker.open()">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker (closed)="closedStream($event)" [restoreFocus]="false"></mat-datepicker>
    </mat-form-field>
  </div>

  <button fxFlex="nogrow" class="mdc-mb-1" mat-raised-button color="accent" (click)="generateDates()"
    [disabled]="!repeatFilterForm?.valid || inProgress">
    {{'GENERATE_DATES' | translate: {Default: 'Generate Dates'} }}</button>
</div>