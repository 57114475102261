import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ContextIdDto } from '@savvy/entity-instance-composite';
import { UserDto } from '@savvy/view-definition';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAccordion } from '@angular/material/expansion';
import { LookAndFeelConfig, LookAndFeelSharedService, TimeValue } from 'src/app/flo/shared/services/look-and-feel-shared.service';
import { DateUtils } from 'src/app/flo/dates/DateUtils';
import { UserCountryService } from 'src/app/services/userCountry.service';
import { Subscription } from 'rxjs';
import { CustomerSearchResultDto } from '@savvy/search';
import * as _ from 'lodash';
import { Tax } from '@savvy/tax';
import { CreateAppointmentV2SyncService, PopulateNewCustomerEventData } from '../create-appointment-v2-sync.service';
import { DataShareService } from 'src/app/core/data-share.service';
import { Package } from '@savvy/packages';
import { PackageSelectorData } from '../../shared/extra-types';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppointmentConfigHelperService } from 'src/app/flo/shared/services/appointment-config-helper.service';
import { PackagesHelperService } from 'src/app/flo/shared/services/packages-helper.service';
import { TaxesHelperService } from 'src/app/flo/shared/services/taxes-helper.service';
import { OrgUsersHelperService } from 'src/app/flo/shared/services/org-users-helper.service';
import { IdNameTupleDto } from '@savvy/user';
@Component({
  selector: 'app-packages-selector-v2',
  templateUrl: './packages-selector-v2.component.html',
  styleUrls: ['./packages-selector-v2.component.scss']
})
export class PackagesSelectorV2Component implements OnInit {

  // @ViewChild('petTable') petTable: DatatableComponent;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @Input() contextIdDto: ContextIdDto;
  @Input() durationRequired = false;
  @Input() disabled = false;

  // @ViewChild(ServiceSelectorV2Component, { static: false }) serviceSelectorPetV2Component: ServiceSelectorV2Component;

  // petServices: Array<PetServicesRequired> = new Array<PetServicesRequired>();
  valid = true;
  lookAndFeelConfig: LookAndFeelConfig;
  timeArray: TimeValue[] = [];
  totalAppointmentDuration = 0;
  // selectedPackages = {};
  step = 0;
  subs: Subscription[] = [];

  lastPrice = 0;
  taxDefs: Tax[] = [];

  constructor(
    public deviceService: DeviceDetectorService,
    private packagesHelperService: PackagesHelperService,
    public userCountryService: UserCountryService,
    private lookAndFeelService: LookAndFeelSharedService,
    private dateUtils: DateUtils,
    private orgUsersHelperService: OrgUsersHelperService,
    private snackBar: MatSnackBar,
    private taxesHelperService: TaxesHelperService,
    public createAppointmentV2SyncService: CreateAppointmentV2SyncService,
    public appointmentConfigHelperService: AppointmentConfigHelperService,

  ) {

  }

  ngOnInit(): void {
    if (this.contextIdDto) {
      this.taxesHelperService.getTaxDefs(this.contextIdDto).then(res => {
        this.taxDefs = res;
      });
      // listen for search field value changes
      this.lookAndFeelConfig = this.lookAndFeelService.getLookAndFeelConfig(this.contextIdDto);
      if (this.lookAndFeelConfig) {
        this.timeArray = this.lookAndFeelConfig.timeArray;
      } else {
        console.log('no look and feel config');
      }
    }
    this.subs.push(this.createAppointmentV2SyncService.populateNewCustomer$.subscribe((customer: PopulateNewCustomerEventData) => {
      if (customer && customer.populatingAppointment) {
        // eslint-disable-next-line max-len
        for (const appointmentService of this.createAppointmentV2SyncService.groupedPackagesWithCustomerId[customer.customerSearchResultDto.id]) {
          this.packagesHelperService.getPackages(this.contextIdDto).then(package1 => {
            const selectedPackage = package1.find(s => s.id === appointmentService.packageId);
            const selectedPackageUser = this.orgUsersHelperService.staffUsers.find(u => u.id === appointmentService.packageUser.id);
            if (selectedPackage && selectedPackageUser) {
              // serviceSelected.packageDurationMins = appointmentService.duration;
              if (!this.createAppointmentV2SyncService.selectedPackages[customer.customerSearchResultDto.id]) {
                this.createAppointmentV2SyncService.selectedPackages[customer.customerSearchResultDto.id] = [];
              }
              this.createAppointmentV2SyncService.selectedPackages[customer.customerSearchResultDto.id].push(
                {
                  selectedPackage,
                  selectedPackageUser: selectedPackageUser,
                  grossPrice: appointmentService.grossPrice,
                  quantity: appointmentService.quantity,
                  subTotal: appointmentService.subTotal,
                  startTime: appointmentService.startTime || this.createAppointmentV2SyncService.startTime,
                  endTime: appointmentService.endTime || this.createAppointmentV2SyncService.endTime,
                }
              );
            }
          });
        }
        this.createAppointmentV2SyncService.calculateTotalAmount();
      } else {
        this.calculateEndTime();
      }
    }));
    this.subs.push(this.createAppointmentV2SyncService.customerSelectionChanged$.subscribe((res: CustomerSearchResultDto[]) => {
      if (res.length) {
        for (const customer of this.createAppointmentV2SyncService.customersSelected) {
          if (!this.createAppointmentV2SyncService.selectedPackages[customer.id]
            || !this.createAppointmentV2SyncService.selectedPackages[customer.id].length) {
            this.addPackageNew(customer);
          }
        }
      }
    }));

  }

  ngOnDestroy(): void {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }

  calculateLocalEndTime(aPackage: PackageSelectorData) {
    if (aPackage && aPackage.startTime) {
      const endTime = this.dateUtils.addMinsToTimeString(aPackage.startTime, aPackage.selectedPackage.packageDurationMins);
      if (this.timeArray?.length) {
        aPackage.endTime = this.timeArray.find(t => t?.actualValue >= endTime)?.actualValue;
      }
    }
  }

  calculateEndTime(aPackage?: PackageSelectorData) {
    setTimeout(() => {
      this.createAppointmentV2SyncService.calculateStartEndTime();
      this.calculateLocalEndTime(aPackage);
    }, 10);
  }


  packageSelected(aPackage: Package, customerId: string, rowIndex: number, packageData: PackageSelectorData) {
    this.createAppointmentV2SyncService.selectedPackages[customerId][rowIndex].selectedPackage = _.cloneDeep(aPackage);
    this.createAppointmentV2SyncService.selectedPackages[customerId][rowIndex].quantity = 1;
    this.createAppointmentV2SyncService.selectedPackages[customerId][rowIndex].subTotal = aPackage.packagePrice;
    this.createAppointmentV2SyncService.selectedPackages[customerId][rowIndex].grossPrice = aPackage.packagePrice;
    this.createAppointmentV2SyncService.selectedPackages[customerId][rowIndex].startTime = packageData.startTime;
    // eslint-disable-next-line max-len
    this.createAppointmentV2SyncService.selectedPackages[customerId][rowIndex].endTime = this.dateUtils.addMinsToTimeString(packageData.startTime, aPackage.packageDurationMins);
    if (!this.createAppointmentV2SyncService.selectedPackages[customerId][rowIndex].selectedPackageUser?.id) {
      if (this.createAppointmentV2SyncService.userToUseWhenCreating) {
        const selectedPackageUser = this.orgUsersHelperService.staffUsers
          .find(u => u.id === this.createAppointmentV2SyncService.userToUseWhenCreating.id);
        this.createAppointmentV2SyncService.selectedPackages[customerId][rowIndex].selectedPackageUser.id = selectedPackageUser?.id;
      } else {
        this.createAppointmentV2SyncService.selectedPackages[customerId][rowIndex].selectedPackageUser.id
          = _.cloneDeep(this.orgUsersHelperService.staffUsers[0]);
      }
    }
    this.calculateEndTime(this.createAppointmentV2SyncService.selectedPackages[customerId][rowIndex]);

    this.checkIfValid();
  }

  packageUserSelected(packageUser: IdNameTupleDto, customerId: string, rowIndex: number) {
    this.createAppointmentV2SyncService.selectedPackages[customerId][rowIndex].selectedPackageUser.id = packageUser?.id;
    const groomerExist = this.createAppointmentV2SyncService.selectedPackages[customerId]
      .findIndex(g => g.selectedPackageUser?.id === packageUser.id);
    if (groomerExist > -1 && groomerExist !== rowIndex) {
      const existingGroomerService = this.createAppointmentV2SyncService.selectedPackages[customerId][groomerExist];

      this.createAppointmentV2SyncService.selectedPackages[customerId][rowIndex].startTime = existingGroomerService.endTime;
      this.createAppointmentV2SyncService.selectedPackages[customerId][rowIndex].endTime =
        this.dateUtils.addMinsToTimeString(this.createAppointmentV2SyncService.selectedPackages[customerId][rowIndex].startTime
          , this.createAppointmentV2SyncService.selectedPackages[customerId][rowIndex].selectedPackage.packageDurationMins);
    }
    this.calculateEndTime(this.createAppointmentV2SyncService.selectedPackages[customerId][rowIndex]);
    this.checkIfValid();
  }

  checkIfValid() {
    this.valid = true;
    for (const key in this.createAppointmentV2SyncService.selectedPackages) {
      if (Object.prototype.hasOwnProperty.call(this.createAppointmentV2SyncService.selectedPackages, key)) {
        const groupedRow = this.createAppointmentV2SyncService.selectedPackages[key];
        for (const customer of groupedRow) {
          if (!customer.selectedPackage || !customer.selectedPackageUser?.id) {
            this.valid = false;
          }
        }
      }
    }
  }

  durationInMinsChanged(aPackage: PackageSelectorData) {
    this.calculateEndTime(aPackage);
  }

  startTimeChanged(startTime: string, aPackage: PackageSelectorData) {
    this.setEndTimeFromStart(startTime, aPackage);
  }

  setEndTimeFromStart(startTime: string, aPackage: PackageSelectorData) {
    console.log('got duration in mins ' + aPackage.selectedPackage.packageDurationMins);
    // service.endTime = this.dateUtils.addMinsToTimeString(startTime, service.selectedPackage.packageDurationMins);
    // console.log('just set endTime to ' + service.endTime);
    // service.selectedPackage.packageDurationMins = _.cloneDeep(this.dateUtils.getDurationInMins(startTime, service.endTime));
    this.calculateEndTime(aPackage);
    this.checkIfValid();
  }

  endTimeChanged(endTime: string, aPackage: PackageSelectorData) {
    if (endTime <= aPackage.startTime) {
      this.snackBar.open('End time should be greater than start time', 'ok', {
        duration: 3000,
      });
    }
    // service.selectedPackage.packageDurationMins = _.cloneDeep(this.dateUtils.getDurationInMins(service.startTime, service.endTime));
    this.calculateEndTime(aPackage);
    this.checkIfValid();
  }

  compareTimeFn(user1: string, user2: string) {
    // console.log('comparing');
    return user1 && user2 && user1 === user2;
  }

  // toggleExpandGroup(group) {
  //   console.log('Toggled Expand Group!', group);
  //   this.petTable.groupHeader.toggleExpandGroup(group);
  // }

  addPackageNew(customer: CustomerSearchResultDto) {
    if (!this.createAppointmentV2SyncService.selectedPackages[customer.id]) {
      this.createAppointmentV2SyncService.selectedPackages[customer.id] = [];
    }
    if (this.createAppointmentV2SyncService.selectedPackages[customer.id].length
      // eslint-disable-next-line max-len
      && !this.isRowValid(this.createAppointmentV2SyncService.selectedPackages[customer.id][this.createAppointmentV2SyncService.selectedPackages[customer.id].length - 1])) {
      this.snackBar.open('Please select a service first', 'ok', {
        duration: 3000,
      });
      return;
    }
    let selectedPackage = {} as Package;
    this.packagesHelperService.getPackages(this.contextIdDto).then(packages => {
      if (packages.length) {
        selectedPackage = _.cloneDeep(packages[0]);
      }
      let selectedPackageUser = _.cloneDeep(this.orgUsersHelperService.staffUsers[0]);

      const lastIndexOfServices = this.createAppointmentV2SyncService.selectedPackages[customer.id].length - 1;
      let startTime = this.createAppointmentV2SyncService.startTime;
      let endTime = this.dateUtils.addMinsToTimeString(this.createAppointmentV2SyncService.startTime, selectedPackage.packageDurationMins);

      if (this.createAppointmentV2SyncService.userToUseWhenCreating) {
        selectedPackageUser = this.orgUsersHelperService.staffUsers
          .find(u => u.id === this.createAppointmentV2SyncService.userToUseWhenCreating.id);
      } else {
        if (this.orgUsersHelperService.staffUsers.length) {
          if (this.orgUsersHelperService.staffUsers[lastIndexOfServices + 1]) {
            selectedPackageUser = _.cloneDeep(this.orgUsersHelperService.staffUsers[lastIndexOfServices + 1]);
          } else {
            if (lastIndexOfServices > -1) {
              startTime = this.createAppointmentV2SyncService.selectedPackages[customer.id][lastIndexOfServices].endTime;
              endTime = this.dateUtils.addMinsToTimeString(startTime, selectedPackage.packageDurationMins);
            }
          }
        }
      }

      this.createAppointmentV2SyncService.selectedPackages[customer.id].push(
        _.cloneDeep({
          selectedPackage: _.cloneDeep(selectedPackage),
          selectedPackageUser,
          quantity: 1,
          grossPrice: selectedPackage?.packagePrice,
          subTotal: selectedPackage?.packagePrice,
          startTime,
          endTime,
        }));
      const lastAddedIndex = this.createAppointmentV2SyncService.selectedPackages[customer.id].length - 1;
      this.calculateEndTime(this.createAppointmentV2SyncService.selectedPackages[customer.id][lastAddedIndex]);

      this.checkIfValid();
    });
  }

  updateUnitPrice(price, aPackage: PackageSelectorData) {
    this.updateQty(aPackage.quantity, aPackage);
  }

  updateGrossPrice(aPackage: PackageSelectorData) {
    if (aPackage.selectedPackage.tax) {
      aPackage.grossPrice = aPackage.subTotal + (aPackage.subTotal * (aPackage.selectedPackage.tax / 100));
    } else {
      aPackage.grossPrice = aPackage.subTotal;
    }
    this.createAppointmentV2SyncService.calculateTotalAmount();
  }

  updateQty(qty, service: PackageSelectorData) {
    service.subTotal = service.selectedPackage.packagePrice * qty;
    this.updateGrossPrice(service);
  }

  isRowValid(service: PackageSelectorData) {
    return service && service.selectedPackage && service.selectedPackage.id && service.selectedPackageUser?.id;
  }

  isGroupValid(group) {
    let valid = false;
    for (const pet of group.value) {
      if (pet['selectedPackage']) {
        if (this.isRowValid(pet)) {
          valid = true;
        } else {
          valid = false;
        }
      }
    }
    return valid;
  }

  deleteServiceNew(customerId, rowIndex) {
    this.createAppointmentV2SyncService.selectedPackages[customerId].splice(rowIndex, 1);
    this.calculateEndTime();
    this.checkIfValid();
  }

  getLastSeenPrice(customer, service: Package) {
    if (!this.lastPrice) {
      // this.appointmentService.lastPetPrice(this.contextIdDto.contextId, this.contextIdDto.contextIdType,
      //   customer.id, service.id)
      //   .subscribe(res => {
      //     this.lastPrice = res.lastPrice;
      //   });
    }
  }

}
