import { Pipe, PipeTransform } from '@angular/core';
import { Discount } from '@savvy/invoice';

@Pipe({
    name: 'discountCalc'
})
export class DiscountCalculatorPipe implements PipeTransform {
    transform(price: number, discount: Discount): number {
        let priceWithDiscount: number = price;
        if (priceWithDiscount && discount && discount.discountType) {
            if (discount.discountType === Discount.DiscountTypeEnum.Amount && discount.amountDiscount) {
                priceWithDiscount = priceWithDiscount - discount.amountDiscount;
            } else if (discount.discountType === Discount.DiscountTypeEnum.Percentage && discount.percentDiscount) {
                priceWithDiscount = parseFloat((priceWithDiscount - ((discount.percentDiscount * priceWithDiscount) / 100)).toFixed(2));
            }
        }
        return priceWithDiscount;
    }
}
