import { Injectable } from '@angular/core';
import { LocationService } from "@savvy/location";
import { SessionStorageService } from '../flo/shared/services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class UserDefaultLocationService {

  private locationId: string;

  constructor(
    private locationService: LocationService,
    private localStorageService: SessionStorageService
  ) { }

  init(envId: string) {
    this.locationService.listLocations(envId).subscribe(response => {
      if (response.length > 0) {
        this.setLocationId(response[0].id);
      }
    })
  }


  setLocationId(locationId: string) {
    //alert('setting location id to ' + locationId);
    this.locationId = locationId;
    if (this.locationId) {
      this.localStorageService.setDefaultLocation(locationId);
    } else {
      this.localStorageService.resetDefaultLocation();
    }
  }

  getLocationId(): string {
    console.log("got location ");
    console.log(this.locationId);
    if (!this.locationId) {
      this.locationId = this.localStorageService.getDefaultLocation();
    }
    return this.locationId;
  }

}
