import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { PaymentMethodService } from '@savvy/payment';
import { TranslateService } from '@ngx-translate/core';
import { FloSnackbarComponent } from '../../../snackbar/floSnackbar.component';
import { Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { NavChangeService } from '../../../element/NavChangeService';
import { ContextService } from '../../../context.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TipsService, InvoiceId } from '@savvy/tips';
import { UsercompService } from '@savvy/user';
import { RecordTip, Tip } from '@savvy/tips';
import { ContextIdDto } from '@savvy/datalist';
import { UserDto, UserId } from '@savvy/org';
import { DateUtils } from '../../../dates/DateUtils';

@Component({
  selector: 'app-add-tip-modal',
  templateUrl: './add-tip-modal.component.html',
  styleUrls: ['./add-tip-modal.component.scss']
})
export class AddTipModalComponent implements OnInit, OnDestroy {
  model: Tip;
  invoiceId: InvoiceId;
  contextIdDto: ContextIdDto;
  subscriptions: Subscription[] = [];

  methods: Array<string>;
  destroy$ = new Subject();
  users: Array<UserDto> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddTipModalComponent>,
    private router: Router,
    private api: TipsService,
    private notify: FloSnackbarComponent,
    private dateUtils: DateUtils,
    private translateService: TranslateService,
    private navChange: NavChangeService,
    private adapter: DateAdapter<any>,
    private userCompApi: UsercompService,
    private method: PaymentMethodService,
    private contextService: ContextService,
  ) {
    this.invoiceId = this.data.invoice.invoiceId;
    this.contextIdDto = this.data.contextIdDto;
  }

  ngOnInit() {
    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
        this.model = {} as Tip;

        const dateTime = new Date();
        this.model.dateTime = this.dateUtils.getDateTimeAsString(dateTime);
        this.model.amount = this.data.invoice.total;
        this.getUsers();
        this.method.loadPaymentMethods()
          .pipe(takeUntil(this.destroy$))
          .subscribe(methods => {
            this.methods = methods;
          });
      }
    }));
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }


  addTip() {
    // alert('this.invoiceId' + this.invoiceId);
    this.model.ownerId = this.contextIdDto.contextId;
    this.model.invoiceId = this.invoiceId;

    const req = {} as RecordTip;
    req.contextIdDto = this.contextIdDto;
    req.tip = this.model;

    this.api.recordTips(req)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.translateService.get('Successfully added tip')
          .subscribe(val => {
            this.notify.message = val;
            this.notify.open();
            this.dialogRef.close(true);
          }, () => {
            console.log('Error occurred while adding tip');
          });
      });
  }

  setUserOptVal(user: UserDto): UserId {
    return { id: user.id, userContextType: UserId.UserContextTypeEnum.UserId };
  }

  compareUserFn(user1: UserDto, user2: UserDto) {
    return user1 && user2 && user1.id === user2.id;
  }

  private getUsers() {
    this.userCompApi.getEnvUsersFilterCustomers(this.contextIdDto.contextId)
      .subscribe(response => {
        this.users = response.userDtos;
      });
  }

}
