import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ContextIdDto } from '@savvy/view-definition';
import { ChangeListener } from './changeListener';
import { EventBusService } from './EventBusService';
import {HorizontalPanelDefinitionDto} from '@savvy/entity-instance-composite';
@Component({
    selector: 'app-horizontal-panel',
    templateUrl: 'horizontalPanel.component.html'
})
export class HorizontalPanelComponent implements OnInit {

    @Input() horizontalPanelDefinitionDto: HorizontalPanelDefinitionDto;
    @Input() contextIdDto: ContextIdDto;np;
    @Input() viewContext: any;
    @Input() form: UntypedFormGroup;
    @Input() eventBus: EventBusService;
    @Input() additionalDataMapDto: any;
    @Input() showPlaceholder: boolean;
    @Input() changeListener: ChangeListener;
    percent;

    ngOnInit(): void {
        this.percent = 100 / this.horizontalPanelDefinitionDto.elementDtos.length;
        if (this.horizontalPanelDefinitionDto.elementDtos.length === 2) {
            this.percent = this.percent - 1;
        } else if (this.horizontalPanelDefinitionDto.elementDtos.length === 3) {
            this.percent = this.percent - 0.65;
        } else if (this.horizontalPanelDefinitionDto.elementDtos.length === 4) {
            this.percent = this.percent - 0.5;
        }
    }
}
