<mat-toolbar color="primary" class="mdc-mb-2">
  <div fxFlex>Invoices</div>
  <div fxFlex></div>
  <div fxFlex="nogrow">
    <button mat-raised-button color="accent" (click)="createNewDraftInvoice()"> <mat-icon class="vm">add</mat-icon>
      Create New Invoice</button>
  </div>
</mat-toolbar>

<ngx-datatable #bookingRequestTable *ngIf="invoices && invoices.length > 0" class='material has-plus-btn mdc-mx-1' [rows]="invoices" [columnMode]="'force'" [headerHeight]="50" [rowHeight]="'auto'" [footerHeight]="50" [externalPaging]="true" [count]="page.totalElements" [offset]="page.pageNumber" [limit]="page.size" [scrollbarH]="true" (page)='setPage($event)' (select)="onSelect($event)" [selected]="selected" [selectionType]="'single'">
  <ngx-datatable-column name="Number" [width]="80">
    <ng-template ngx-datatable-cell-template let-row="row">
      <b>#{{row.invoiceNumber}}</b>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Created On" [width]="150">
    <ng-template ngx-datatable-cell-template let-row="row">
      {{row?.invoiceDate | localizedDate: 'mediumDate'}}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Customer" [width]="150">
    <ng-template ngx-datatable-cell-template let-row="row">
      {{row?.invoiceOwnerFirstName}} {{row?.invoiceOwnerLastName}}
    </ng-template>
  </ngx-datatable-column>

  <!--ngx-datatable-column name="Last Modified" [width]="150">
    <ng-template ngx-datatable-cell-template let-row="row">
      {{row?.lastModifiedDate | localizedDate: 'short'}}
    </ng-template>
  </ngx-datatable-column-->
  <!--ngx-datatable-column name="Due On" [width]="150">
    <ng-template ngx-datatable-cell-template let-row="row">
      {{row?.dueDate | localizedDate: 'mediumDate'}}
    </ng-template>
  </ngx-datatable-column-->
  <ngx-datatable-column name="{{'AMOUNT' | translate}}" [width]="100">
    <ng-template ngx-datatable-cell-template let-row="row">
      <b [style.text-decoration]="row.invoiceVoid ? 'line-through' : 'inherit'">{{row?.total || 0.00 |
        currency:currencyCode:'symbol'}}</b>
      <br>
      <div class="appointment-status" *ngIf="!row.invoiceVoid">
        <div *ngIf="row?.paidInFull" class="status prepaid-status d-inline-block">{{'PAID' | translate}}
        </div>
        <div *ngIf="!row?.paidInFull" class="status unpaid-status d-inline-block">{{'UNPAID' | translate}}
        </div>
      </div>
      <div class="appointment-status" *ngIf="row.invoiceVoid">
        <div class="status unpaid-status d-inline-block">
          {{'CANCELLED' | translate}}
        </div>
      </div>
    </ng-template>
  </ngx-datatable-column>
  <!--ngx-datatable-column name="Draft" [width]="80">
    <ng-template ngx-datatable-cell-template let-row="row">
      <div class="appointment-status" *ngIf="row?.created">
        <div *ngIf="row?.created" class="status prepaid-status d-inline-block">No
        </div>
        <div *ngIf="!row?.created" class="status unpaid-status d-inline-block">Yes
        </div>
      </div>
    </ng-template>
  </ngx-datatable-column-->
  <ngx-datatable-column name="{{'ACTION' | translate: {Default: 'Action'} }}" [width]="80">
    <ng-template ngx-datatable-cell-template let-row="row">
      <button *ngIf="!row.deleted" mat-button color="primary" class="mr-5px" (click)="viewInvoice(row?.id)"><mat-icon class="vm">visibility</mat-icon> {{'VIEW' | translate}} </button>
      <button *ngIf="!row.deleted" mat-button color="accent" class="mr-5px" (click)="onSelect(row)"><mat-icon class="">edit</mat-icon> {{'EDIT' | translate}} </button>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>