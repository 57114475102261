<mat-card appearance="outlined" class="mdc-mb-1 mdc-mx-0">
  <mat-card-header class="mdc-mb-1">
    <div fxLayout="row" fxLayoutAlign=" center" class="w-100 ">
      <div fxFlex>
        <h6 class="mb-0 ">{{'CONTRACTS' | translate}}</h6>
      </div>
      <div fxFlex="auto"></div>
      <div fxFlex="nogrow">
        <button mat-button color="accent" (click)="addContract()">
          <mat-icon class="vm">add</mat-icon> <span class="vm">{{'ADD_CONTRACTS' | translate}}</span>
        </button>
      </div>
    </div>
  </mat-card-header>
  <!-- <hr> -->
  <mat-card-content>
    <mat-list *ngIf="contractUiDtoList?.length; else elseBlockContract" class="contract-list">
      <mat-list-item *ngFor="let contractUi of contractUiDtoList" (click)="openViewContractModal(contractUi.contract.id)" style="cursor: pointer;">
        <div fxLayout="row wrap" fxLayoutGap="16px" fxLayoutAlign=" center" class="mdc-py-2">
          <div fxFlex="nogrow">
            <div mat-list-icon class="avatar avatar-50 circle border-3">
              <svg xmlns="http://www.w3.org/2000/svg" class="signature-icon" viewBox="0 0 19.084 12.65">
                <path id="Path_119" data-name="Path 119" d="M878.678,330.417s-2.469,4.1.221,4.5,7.628-6.393,4.982-7.055-7.407,10.1-4.542,11.42,8.728-6.146,7.235-7.061-5.324,4.036-3.73,4.782,5.155-2,6.241-3.154,2.208-2.679,3.529-1.967-2.037,3.663-1.155,4.477,4.07-3.629,4.07-3.629" transform="translate(-877.143 -327.317)" fill="none" stroke="#ef9a9a" stroke-linecap="round" stroke-width="1" />
              </svg>
            </div>
          </div>
          <div fxFlex>
            <h6 class="mb-0">{{contractUi.templateName}}</h6>
            <p class="small mat-text-secondary mb-0">{{'ISSUED_ON' | translate}} {{contractUi.contract.dateCreated | date:'short'}} <ng-container *ngIf="contractUi.contract.dateClientSigned">, {{'SIGNED_ON' | translate}} {{contractUi.contract.dateClientSigned | localizedDate:'short'}}</ng-container> </p>
          </div>
          <div fxFlex="nogrow">
            <button *ngIf="contractUi.contract.dateClientSigned" style="border-radius: 20px; background-color: #d5edeb; border-color: transparent; color: #009688;" disabled>{{'SIGNED' | translate}}</button>
            <button *ngIf="!contractUi.contract.dateClientSigned" style="border-radius: 20px; background-color: #f7edce; border-color: transparent; color: orange" disabled>{{'UNSIGNED' | translate}}</button>
          </div>
        </div>
      </mat-list-item>
    </mat-list>
    <ng-template #elseBlockContract>
      <div class="flexbox-parent">
        <div fxLayout="row" fxLayoutAlign="center center">
          <div style="text-align:center;">
            <div class="avatar avatar-100 circle border-3 mb-2 d-inline-block mt-15px">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.084 12.65" style="width: 50px;">
                <path id="Path_119" data-name="Path 119" d="M878.678,330.417s-2.469,4.1.221,4.5,7.628-6.393,4.982-7.055-7.407,10.1-4.542,11.42,8.728-6.146,7.235-7.061-5.324,4.036-3.73,4.782,5.155-2,6.241-3.154,2.208-2.679,3.529-1.967-2.037,3.663-1.155,4.477,4.07-3.629,4.07-3.629" transform="translate(-877.143 -327.317)" fill="none" stroke="#ef9a9a" stroke-linecap="round" stroke-width="1" />
              </svg>

            </div>
            <h6 class="mb-2">{{'NO_CONTRACTS' | translate}}.</h6>
          </div>
        </div>
      </div>
    </ng-template>
  </mat-card-content>
</mat-card>