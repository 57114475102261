import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ContextIdDto } from '@savvy/entity-instance-composite';

import { IdNameTupleDto } from '@savvy/user';
import { ReplaySubject } from 'rxjs';
import { OrgUsersHelperService } from 'src/app/flo/shared/services/org-users-helper.service';
import { EventBusService } from '../../../element/EventBusService';
import { MatSelectChange } from '@angular/material/select';
import * as uuid from 'uuid';

@Component({
  selector: 'app-staff-selector',
  templateUrl: './staff-selector.component.html',
  styleUrl: './staff-selector.component.scss'
})
export class StaffSelectorComponent implements OnInit, OnChanges {

  @Input() contextIdDto: ContextIdDto;
  @Input() eventBus: EventBusService;
  @Input() showPlaceholder: boolean;
  @Input() selectedStaff: IdNameTupleDto;
  @Input() required: boolean = false;
  @Input() selectedLocationId: string;
  @Input() selectedServiceId: string;
  @Input() allowAllOption = false;

  @Output() staffSelectionChanged: EventEmitter<IdNameTupleDto> = new EventEmitter();

  id = '';

  public filteredStaffList: ReplaySubject<IdNameTupleDto[]> = new ReplaySubject<IdNameTupleDto[]>(1);

  constructor(
    public orgUsersHelperService: OrgUsersHelperService,
  ) {
    this.id = uuid.v4();
  }

  ngOnInit(): void {
    this.loadUsers();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedServiceId']
      && !changes['selectedServiceId']?.isFirstChange()
      && changes['selectedServiceId'].currentValue !== changes['selectedServiceId'].previousValue) {
      this.loadUsers();
    }

    if (
      changes['selectedLocationId']
      && !changes['selectedLocationId']?.isFirstChange()
      && changes['selectedLocationId'].currentValue !== changes['selectedLocationId'].previousValue) {
      this.loadUsers();
    }

  }

  loadUsers() {
    this.orgUsersHelperService.loadStaffMembers(this.selectedStaff?.id, this.selectedLocationId, this.selectedServiceId)
      .then((staffUsers) => {
        if (staffUsers.length) {
          this.filteredStaffList.next(staffUsers.slice());
          if (!this.selectedStaff?.id) {
            if (this.allowAllOption) {
              this.selectedStaff = { id: 'all', name: 'All' };
            } else {
              this.selectedStaff = staffUsers[0];
            }
            this.staffSelectionChanged.emit(this.selectedStaff);
          }
        } else {
          this.filteredStaffList.next([]);
          this.staffSelectionChanged.emit(this.selectedStaff);
        }
      });
  }

  filterStaffList($event) {
    if (!this.orgUsersHelperService.staffUsers) {
      return;
    }
    let staffUsers = this.orgUsersHelperService.staffUsers;

    // get the search keyword
    let search = $event;
    if (!search) {
      this.filteredStaffList.next(staffUsers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    // filter the banks
    this.filteredStaffList.next(
      staffUsers.filter(userDto => userDto?.name.toLowerCase().indexOf(search) > -1)
    );
  }

  serviceUserChanged(event: MatSelectChange) {
    console.log('staff selection changed', event?.value);
    this.staffSelectionChanged.emit(event?.value);
  }

  compareUserFn(user1: IdNameTupleDto, user2: IdNameTupleDto) {
    // console.log('comparing');
    return user1 && user2 && user1.id === user2.id;
  }
}

