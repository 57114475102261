<mat-card appearance="outlined" class="panel new-card" *ngIf="cardDefinitionDto">
  <mat-card-header *ngIf="cardDefinitionDto.cardHeaderDefinitionDto">
    <mat-card-title *ngIf="cardDefinitionDto.cardHeaderDefinitionDto.title">
      <app-element [elementDto]="cardDefinitionDto.cardHeaderDefinitionDto.title" [form]="form"
                   [contextIdDto]="contextIdDto" [viewContext]="viewContext" [eventBus]="eventBus"
                   [additionalDataMapDto]="additionalDataMapDto" [showPlaceholder]="showPlaceholder"
                   [changeListener]="changeListener">
      </app-element>
      <div *ngIf="cardDefinitionDto.cardHeaderDefinitionDto.subTitle">
        <app-element *ngIf="cardDefinitionDto.cardHeaderDefinitionDto.subTitle"
                     [elementDto]="cardDefinitionDto.cardHeaderDefinitionDto.subTitle" [form]="form"
                     [contextIdDto]="contextIdDto"
                     [viewContext]="viewContext" [eventBus]="eventBus" [additionalDataMapDto]="additionalDataMapDto"
                     [showPlaceholder]="showPlaceholder" [changeListener]="changeListener">
        </app-element>
      </div>

    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div *ngIf="cardDefinitionDto.cardContentDefinitionDto">
      <app-element *ngFor="let elementDto of cardDefinitionDto.cardContentDefinitionDto.elementDtos"
                   [elementDto]="elementDto" [form]="form" [contextIdDto]="contextIdDto" [viewContext]="viewContext"
                   [eventBus]="eventBus" [additionalDataMapDto]="additionalDataMapDto"
                   [showPlaceholder]="showPlaceholder"
                   [changeListener]="changeListener">
      </app-element>
    </div>
  </mat-card-content>
</mat-card>
