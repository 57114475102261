import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ChangeListener } from './changeListener';
import { EventBusService } from './EventBusService';
import { PhoneNumberHelperService } from '../shared/services/phone-number-helper.service';
import { LocalMenuService } from '../menu/local-menu.service';
import { Router } from '@angular/router';
import { EventType } from '../event/UiEvent';
import { ConfirmationModalComponent } from '../shared/components/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ViewEntityCardDefinitionDto } from '@savvy/view-definition';
import { ContextIdDto } from '@savvy/quickbooks';

@Component({
  selector: 'app-view-entity-card',
  templateUrl: 'viewEntityCard.component.html'
})
export class ViewEntityCardComponent {

  @Input() viewEntityCardDefinition: ViewEntityCardDefinitionDto;
  @Input() contextIdDto: ContextIdDto;
  @Input() viewContext: any;
  @Input() form: UntypedFormGroup;
  @Input() eventBus: EventBusService;
  @Input() additionalDataMapDto: any;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;

  constructor(
    private sharedService: PhoneNumberHelperService,
    public menuService: LocalMenuService,
    private router: Router,
    private dialog: MatDialog) { }


  closePanelBar() {
    this.sharedService.closeSidePanelSubj.next(true);
  }

  backToList() {
    const currentPageName = this.viewContext.addEditEntityInstanceContextDto.entityDefinitionDto.name;
    this.menuService.menuItems.some(el => {
      if (el.name === 'Appointments' && currentPageName === 'Appointment') {
        this.router.navigate(['/entity/listCachedTableEntityInsts',
          el.params[2], this.contextIdDto.contextId, this.contextIdDto.contextIdType]);
        return true;
      } else if (el.name === 'Pets' && currentPageName === 'Pet') {
        this.router.navigate(['/entity/listCachedTableEntityInsts',
          el.params[2], this.contextIdDto.contextId, this.contextIdDto.contextIdType]);
        return true;
      }
    });
  }

  editEntity() {
    this.eventBus.addEntityEvent(EventType.EDIT_ENTITY, this.viewContext.entityInstanceId);
  }

  removeEntity() {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        name: this.viewContext.addEditEntityInstanceContextDto.entityDefinitionDto.name
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.eventBus.addEntityEvent(EventType.DELETE_ENTITY_INSTANCE, this.viewContext.entityInstanceId);
      }
    });
  }
}

