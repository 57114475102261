<mat-card-header class="mdc-mb-1">
  <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutAlign.gt-sm=" center" class="w-100">
    <div fxFlex="100" fxFlex.gt-sm>
      <h6 class="mb-0 ">{{'APPOINTMENTS' | translate}}</h6>
    </div>
    <div fxFlex="auto" fxHide fxShow.gt-sm></div>
    <div fxFlex="100" fxFlex.gt-sm="nogrow">
      <mat-slide-toggle class="mr-1" [labelPosition]="'before'" color="accent" [checked]="showOnlyCancelled" [(ngModel)]="showOnlyCancelled" (ngModelChange)="cancelledFilterChanged()">
        {{"ONLY_SHOW_CANCELLED" | translate}}
      </mat-slide-toggle>
      <button mat-button color="accent" (click)="addAppointment()" *ngIf="!isViewMode && !customerDto?.approvalNeeded">
        <mat-icon class="vm">event</mat-icon> <span class="vm">{{"CREATE_APPT" | translate}}</span>
      </button>
      <button *ngIf="selectedAppointments?.length" mat-button color="accent" (click)="deleteAppointments()">
        <mat-icon class="vm">delete</mat-icon> <span class="vm">{{"DELETE_SELECTED" | translate}} </span>
      </button>
      <mat-menu #appMenu="matMenu">
        <button mat-menu-item (click)="sendAppointments()">{{"SEND_ALL_APPTS" | translate}}</button>
      </mat-menu>
      <button mat-icon-button [matMenuTriggerFor]="appMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
  </div>
</mat-card-header>
<!-- <hr> -->
<!-- {{historyList | json}} -->
<div fxLayout="row" fxLayoutAlign="center center" class="mdc-p-3 ">
  <mat-button-toggle-group [(ngModel)]="historyMode" (ngModelChange)="historyModeChanged($event)" name="historyMode" aria-label="History Mode" class="button-group-payments buttongroupmobile">
    <mat-button-toggle [value]="'past'">{{"PAST_APPTS" | translate}}
      ({{pastAppointments.length}})</mat-button-toggle>
    <mat-button-toggle [value]="'future'">{{"UPCOMING_APPTS" | translate}}
      ({{futureAppointments.length}})</mat-button-toggle>
  </mat-button-toggle-group>
</div>

<div *ngIf="historyMode === 'past'" class="mdc-mb-2">
  <ul class="historylist mt-1" *ngIf="pastAppointments.length">
    <li *ngFor="let pastAppointment of pastAppointments">
      <div fxLayout="row">
        <div fxFlex fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="2%">
          <div fxFlex="grow" fxFlex.gt-sm="20" (click)="openViewAppointmentModal(pastAppointment)">
            <p class="my-1 view-invoice font-weight-medium">
              {{pastAppointment.label}}
            </p>
          </div>
          <div fxFlex="grow" fxFlex.gt-sm="18" (click)="openViewAppointmentModal(pastAppointment)">
            <p class="my-1 mat-text-secondary view-invoice">{{pastAppointment.services}}</p>
          </div>
          <div fxFlex="grow" fxFlex.gt-sm="15">
            <p class="my-1 mat-text-secondary">{{pastAppointment.blades}}</p>
          </div>
          <div fxFlex="grow" fxFlex.gt-sm="18">
            <p class="my-1 mat-text-secondary">{{pastAppointment.notes}}</p>
          </div>
          <div fxFlex="grow" fxFlex.gt-sm="18">
            <p class="my-1 mat-text-secondary">{{pastAppointment.cancellationReason}}</p>
          </div>
        </div>
        <div fxFlex="nogrow">
          <div class="avatar avatar-50 circle border-3 d-inline-block mx-2" [matTooltip]="'EDIT_APPT' | translate" (click)="openEditAppointmentModal(pastAppointment)" *ngIf="!isViewMode">
            <mat-icon class="view-invoice vm">
              edit_calendar
            </mat-icon>
          </div>

          <div class="avatar avatar-50 circle border-3 d-inline-block" *ngIf="pastAppointment.invoiceId" [matTooltip]="'VIEW_INVOICE' | translate" (click)="viewInvoice(pastAppointment?.invoiceId?.id)">
            <mat-icon class="view-invoice vm">
              receipt
            </mat-icon>
          </div>
        </div>
      </div>
    </li>
  </ul>
  <div class="no-history mt-2" *ngIf="!pastAppointments.length">
    <div style="text-align:center;">
      <div class="avatar avatar-100 circle border-3 mb-2 d-inline-block">
        <mat-icon class="h1 h-auto w-auto">timeline</mat-icon>
      </div>
      <h6>{{"NO_PAST_APPTS" | translate}}</h6>
    </div>
  </div>
</div>

<div *ngIf="historyMode === 'future'" class="mdc-mb-2">
  <ul class="historylist mt-1" *ngIf="futureAppointments.length">
    <li *ngFor="let futureAppointment of futureAppointments">
      <div fxLayout="row" fxLayoutAlign=" start">
        <div fxFlex="nogrow">
          <mat-checkbox *ngIf="!showOnlyCancelled" class="mx-2 vm" (change)="selectAppointment(futureAppointment.appointmentId)"></mat-checkbox>
        </div>
        <div fxFlex fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="2%">
          <div fxFlex="grow" fxFlex.gt-sm="20" (click)="openViewAppointmentModal(futureAppointment)">
            <p class="my-1 view-invoice ">
              {{futureAppointment.label}}
            </p>
          </div>
          <div fxFlex="grow" fxFlex.gt-sm="18">
            <p class="my-1 mat-text-secondary view-invoice">{{futureAppointment.services}}</p>
          </div>
          <div fxFlex="grow" fxFlex.gt-sm="15">
            <p class="my-1 mat-text-secondary">{{futureAppointment.blades}}</p>
          </div>
          <div fxFlex="grow" fxFlex.gt-sm="18">
            <p class="my-1 mat-text-secondary">{{futureAppointment.notes}}</p>
          </div>
          <div fxFlex="grow" fxFlex.gt-sm="18">
            <p class="my-1 mat-text-secondary">{{futureAppointment.cancellationReason}}</p>
          </div>
        </div>
        <div fxFlex="nogrow">
          <div class="avatar avatar-50 circle border-3 d-inline-block mx-2" [matTooltip]="'EDIT_APPT' | translate" (click)="openEditAppointmentModal(futureAppointment)" *ngIf="!isViewMode">
            <mat-icon class="view-invoice vm">
              edit_calendar
            </mat-icon>
          </div>
          <div class="avatar avatar-50 circle border-3 d-inline-block" *ngIf="futureAppointment.invoiceId" [matTooltip]="'VIEW_INVOICE' | translate" (click)="viewInvoice(futureAppointment?.invoiceId?.id)">
            <mat-icon class="view-invoice vm">
              receipt
            </mat-icon>
          </div>
        </div>
      </div>
    </li>
  </ul>
  <div class="no-history mt-2" *ngIf="!futureAppointments.length">
    <div style="text-align:center;">
      <div class="avatar avatar-100 circle border-3 mb-2 d-inline-block">
        <mat-icon class="h1 h-auto w-auto">event</mat-icon>
      </div>
      <h6>{{"NO_UPCOMING_APPTS" | translate}}</h6>
    </div>
  </div>
</div>


<!-- <mat-tab-group
  *ngIf="futureAppointmentDates && futureAppointmentDates.length > 0 || pastAppointmentDates && pastAppointmentDates.length > 0; else elseBlockContract">
  <mat-tab label="Future">
    <ng-template matTabContent>

      <mat-list class="contract-list">
        <mat-list-item *ngFor="let futureAppointment of futureAppointmentDates" style="cursor: pointer;">
          <div mat-list-icon class="avatar avatar-50 circle border-3"
            (click)="openViewAppointmentModal(futureAppointment)">
            <svg xmlns="http://www.w3.org/2000/svg" class="signature-icon" viewBox="0 0 19.084 12.65">
              <path id="Path_119" data-name="Path 119"
                d="M878.678,330.417s-2.469,4.1.221,4.5,7.628-6.393,4.982-7.055-7.407,10.1-4.542,11.42,8.728-6.146,7.235-7.061-5.324,4.036-3.73,4.782,5.155-2,6.241-3.154,2.208-2.679,3.529-1.967-2.037,3.663-1.155,4.477,4.07-3.629,4.07-3.629"
                transform="translate(-877.143 -327.317)" fill="none" stroke="#ef9a9a" stroke-linecap="round"
                stroke-width="1" />
            </svg>
          </div>
          <div mat-line (click)="openViewAppointmentModal(futureAppointment)">{{futureAppointment.label}}</div>
          <div *ngIf="futureAppointment?.invoiceId?.id" class="avatar avatar-50 circle border-3 d-inline-block"
            (click)="viewInvoice(futureAppointment?.invoiceId?.id)">
            <mat-icon>receipt</mat-icon>
          </div>
        </mat-list-item>
      </mat-list>
    </ng-template>
  </mat-tab>
  <mat-tab label="Past">
    <ng-template matTabContent>
      <mat-list class="contract-list">
        <mat-list-item *ngFor="let pastAppointmentDate of pastAppointmentDates" style="cursor: pointer;">
          <div mat-list-icon class="avatar avatar-50 circle border-3"
            (click)="openViewAppointmentModal(pastAppointmentDate)">
            <svg xmlns="http://www.w3.org/2000/svg" class="signature-icon" viewBox="0 0 19.084 12.65">
              <path id="Path_119" data-name="Path 119"
                d="M878.678,330.417s-2.469,4.1.221,4.5,7.628-6.393,4.982-7.055-7.407,10.1-4.542,11.42,8.728-6.146,7.235-7.061-5.324,4.036-3.73,4.782,5.155-2,6.241-3.154,2.208-2.679,3.529-1.967-2.037,3.663-1.155,4.477,4.07-3.629,4.07-3.629"
                transform="translate(-877.143 -327.317)" fill="none" stroke="#ef9a9a" stroke-linecap="round"
                stroke-width="1" />
            </svg>
          </div>
          <div mat-line (click)="openViewAppointmentModal(pastAppointmentDate)">{{pastAppointmentDate.label}}</div>
          <div *ngIf="pastAppointmentDate?.invoiceId?.id" class="avatar avatar-50 circle border-3 d-inline-block"
            (click)="viewInvoice(pastAppointmentDate?.invoiceId?.id)">
            <mat-icon>receipt</mat-icon>
          </div>
        </mat-list-item>
      </mat-list>
    </ng-template>
  </mat-tab>
</mat-tab-group> -->


<!-- <ng-template #elseBlockContract>
  <div class="flexbox-parent">
    <div fxLayout="row" fxLayoutAlign="center center" style="height: 40vh;">
      <div style="text-align:center;">
        <div class="avatar avatar-100 circle border-3 mb-2 d-inline-block mt-15px">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.084 12.65" style="width: 50px;">
            <path id="Path_119" data-name="Path 119"
              d="M878.678,330.417s-2.469,4.1.221,4.5,7.628-6.393,4.982-7.055-7.407,10.1-4.542,11.42,8.728-6.146,7.235-7.061-5.324,4.036-3.73,4.782,5.155-2,6.241-3.154,2.208-2.679,3.529-1.967-2.037,3.663-1.155,4.477,4.07-3.629,4.07-3.629"
              transform="translate(-877.143 -327.317)" fill="none" stroke="#ef9a9a" stroke-linecap="round"
              stroke-width="1" />
          </svg>

        </div>
        <h6 class="ma-0">{{'There are no appointments' | translate}}.</h6>
      </div>
    </div>
  </div>
</ng-template> -->