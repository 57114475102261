<div fxFlex="100">
  <mat-card appearance="outlined" class="dashboard-card">
    <mat-card-title>{{entitySummaryDto.title | translate}}</mat-card-title>
    <mat-card-subtitle>{{entitySummaryDto.subTitle | translate}}</mat-card-subtitle>

    <hr>
    <mat-list>
      <ng-template let-item let-last="last" ngFor [ngForOf]="entitySummaryDto.summaryItemDtoList">
        <mat-list-item (click)="go(item)">
          <ngx-avatars mat-list-avatar bgColor="teal" name="{{item.img}}" size="50"
            [style.border-radius]="'50%'"></ngx-avatars>
          <h3 matLine>{{item.h3 | translate}} </h3>
          <p matLine>{{item.paragraph | translate}}</p>
        </mat-list-item>
      </ng-template>
    </mat-list>
  </mat-card>

</div>