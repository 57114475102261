import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ChangeListener } from './changeListener';
import { TrialExpiredService } from '../shared/services/trial-expired.service';
import { PhoneNumberHelperService } from '../shared/services/phone-number-helper.service';
import { MatDialog } from '@angular/material/dialog';
import { ClearObservable } from '../shared/classes/clear-observable';
import { takeUntil } from 'rxjs/operators';
import { LoadContractsResponse } from '@savvy/contract';
import { HttpErrorResponse } from '@angular/common/http';
import { ContextIdDto } from '@savvy/ui';
import { LinkId } from 'src/app/core/data-share.service';
import {Meeting, MeetingsService} from '@savvy/meeting';
import {ViewContextDto} from '@savvy/view-composite';
import {LoadMeetingResponse} from '@savvy/meeting/model/models';
import {AddEditAppointmentMeetingComponent} from './create-appointment/addEditAppointmentMeeting.component';

@Component({
  selector: 'app-view-appointment-meetings',
  templateUrl: 'viewAppointmentMeetings.component.html'
})
export class ViewAppointmentMeetingsComponent extends ClearObservable implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() viewContext: ViewContextDto;
  @Input() form: UntypedFormGroup;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;

  meetings: Array<Meeting>;

  constructor(
    private router: Router,
    public trialExpiredService: TrialExpiredService,
    private sharedService: PhoneNumberHelperService,
    private dialog: MatDialog,
    private meetingService: MeetingsService) {
    super();
  }

  ngOnInit() {
    this.loadAppointmentMeetings();
  }

  rowSelected(selectedMeetingId: string) {
    const createModal = this.dialog.open(AddEditAppointmentMeetingComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        meetingId: selectedMeetingId,
        appointmentId: this.viewContext.linkId.linkedId
      },
      maxWidth: '1024px',
      maxHeight: '80vh',
      autoFocus: false
    });

    createModal.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        res => {
          if (res) {
            this.loadAppointmentMeetings();
          }
        });
  }

  openCreateMeetingModal() {
    console.log(this);

    const createModal = this.dialog.open(AddEditAppointmentMeetingComponent, {
        data: {
          contextIdDto: this.contextIdDto,
          appointmentId: this.viewContext.linkId.linkedId
        },
        maxWidth: '1024px',
        maxHeight: '80vh',
        autoFocus: false
      });

      createModal.afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          res => {
            if (res) {
              this.loadAppointmentMeetings();
            }
          });
  }

  loadAppointmentMeetings() {
      this.meetingService.getAppointmentMeetings(
        this.contextIdDto.contextId, this.contextIdDto.contextIdType,
        this.viewContext.entityInstanceId.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe((response: LoadMeetingResponse) => {
          this.meetings = response.meeting;
        }, (err: HttpErrorResponse) => {
          console.log('error while loading meetings', err);
        });
  }
}

