import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { EventBusService } from './EventBusService';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UntypedFormGroup } from '@angular/forms';
import { ChangeListener } from './changeListener';
import { SendInvoiceDialogComponent } from '../invoice/sendInvoiceDialog.component';
import { RequestPaymentDialogComponent } from '../invoice/requestPaymentDialog.component';
import { DiscountItem, DiscountSettings, DiscountSettingsService, Invoice, InvoiceService } from '@savvy/invoice';
import { InvoicecompService, UpdateInvoiceComp, UserDto, InvoicePanelServiceRowDto } from '@savvy/invoice';
import { ViewContextDto } from '@savvy/view-composite';
import { ContextIdDto, InvoiceDefinition, InvoiceId, InvoiceProductRow, UserId } from '@savvy/view-definition';
import { NgxSpinnerService } from 'ngx-spinner';
import { Package, PackagesCompositeService } from '@savvy/packages';
import { CreateProductComponent } from '../shared/components/setup/products/create-product/create-product.component';
import { Product, ProductService } from '@savvy/products';
import { ViewDiscountsModalComponent } from '../discount/view-discounts/view-discounts-modal.component';
import { ConsumerService } from '@savvy/services';

@Component({
  selector: 'app-view-invoice-panel',
  templateUrl: 'viewInvoicePanel.component.html',
  styleUrls: ['viewInvoicePanel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ViewInvoicePanelComponent implements OnInit, OnDestroy {

  @Input() invoiceId: InvoiceId;
  @Input() viewContext: ViewContextDto;
  @Input() contextIdDto: ContextIdDto;
  @Input() form: UntypedFormGroup;
  @Input() additionalDataMapDto: any;
  @Input() eventBus: EventBusService;
  @Input() changeListener: ChangeListener;
  @Input() showPaymentRequests: boolean;
  @Input() requestForPaymentOwnerId: string;


  @Output() taskSelected = new EventEmitter();
  @Output() taskDeselected = new EventEmitter();

  consumerServices: Array<ConsumerService>;
  packages: Array<Package>;
  products: Array<Product>;
  invoicePanelServiceRowDtos: Array<InvoicePanelServiceRowDto>;
  currencyCode: string;
  invoice: Invoice;
  userDtos: Array<UserDto>;
  invoiceDefinition: InvoiceDefinition;
  // model: Payment;
  private destroy$ = new Subject();
  searchProduct = '';

  discountSettings: DiscountSettings;
  discountItemsById: { [key: string]: DiscountItem } = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: InvoiceService,
    private invoiceCompApi: InvoicecompService,
    private packagesCompositeService: PackagesCompositeService,
    private productService: ProductService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private discountSettingsService: DiscountSettingsService,
  ) {
  }


  ngOnInit(): void {

    if (this.changeListener) {
      console.log('adding listener');
      this.changeListener.eventAdded$.subscribe(event => {
        console.log('reloading');
        this.loadDataForPanel();
      });
    } else {
      console.log('no listener');
    }

    console.log('reloading');
    this.loadDataForPanel();
    this.loadPackages();
    this.loadDiscounts();
    // this.model = <Payment>{};
    // this.model.dateTime = new Date();
  }

  invoiceUpdated(invoice: Invoice) {
    console.log('invoice updated');
    this.invoice = invoice;
  }

  loadPackages() {
    this.packagesCompositeService.loadPackages(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType).subscribe(response => {
        this.packages = response.packages;
      });
  }

  createProductInvoiceRow() {
    console.log('creating row');
    const invoiceRow: InvoiceProductRow = <InvoiceProductRow>{};
    if (!this.invoice.invoiceProductRows) {
      console.log('creating rows array');
      this.invoice.invoiceProductRows = new Array(0);
    }
    this.invoice.invoiceProductRows.push(invoiceRow);
    console.log('rows now ' + this.invoice.invoiceProductRows.length);
    this.updateInvoice();
  }

  deleteProductRow(invoiceRow: InvoiceProductRow) {

    this.invoice.invoiceProductRows.forEach((item, index) => {
      if (item === invoiceRow) {
        this.invoice.invoiceProductRows.splice(index, 1);
      }
    });
    this.updateInvoice();
  }

  getServiceRowLabel(idx: number): string {
    if (this.invoicePanelServiceRowDtos && this.invoicePanelServiceRowDtos.length > 0) {
      return this.invoicePanelServiceRowDtos[0].eiLabel;
    }
    return '';
  }

  getUserName(userId: UserId): string {
    const user = this.getUser(userId);
    if (user) {
      return user.fullName;
    }
    return '';
  }

  getServiceName(serviceNumber: string): string {
    const service = this.getService(serviceNumber);
    if (service) {
      return service?.serviceName;
    }
    return '';
  }

  getPackageName(packageNumber: string): string {
    const aPackage = this.getPackage(packageNumber);
    if (aPackage) {
      return aPackage.packageName;
    }
    return '';
  }

  getUser(userId: UserId): UserDto {

    let retUser: UserDto;
    if (this.userDtos) {
      this.userDtos.forEach(userDto => {

        //  console.log('userId is ' + userDto.userId.id);
        if (userDto.userId.id === userId.id) {
          //  console.log('found match!');
          retUser = userDto;
          return userDto;
        } else {
          //   console.log('no match');
        }
      });
    }
    return retUser;
  }

  productChanged(row) {
    if (row.productNumber) {
      console.log('Product number is ' + row.productNumber);
      // if (!row.unitPrice) {
      const productDto = this.getProduct(row.productNumber);
      if (productDto) {
        row.unitPrice = productDto.unitPrice;
        row.tax = productDto.tax;
      } else {
        console.log('failed to get product');
      }
      // } else {
      //   console.log('Row already has unit price ' + row.unitPrice);
      // }
    }
    this.updateInvoice();
  }
  getProduct(productId: string): Product {

    let retProduct: Product;
    if (this.products) {
      this.products.forEach(productDto => {

        //    console.log('productDto is ' + productDto.entityInstanceId.id);
        //   console.log('entityInstanceId is ' + entityInstanceId);
        if (productDto.id === productId) {
          //   console.log('found match!');
          retProduct = productDto;
          return productDto;
        } else {
          //     console.log('no match');
        }
      });
    }
    return retProduct;
  }

  serviceChanged() {
    if (this.invoice.invoiceServiceRows) {
      this.invoice.invoiceServiceRows.forEach(row => {
        console.log('got row ' + row);
        if (row.serviceNumber) {
          console.log('Service number is ' + row.serviceNumber);
          if (!row.unitPrice) {
            const serviceDto = this.getService(row.serviceNumber);
            if (serviceDto) {
              row.unitPrice = serviceDto.unitPrice;
              row.tax = serviceDto.tax;
            } else {
              console.log('failed to get service');
            }
          } else {
            console.log('Row already has unit price ' + row.unitPrice);
          }
        }
      });
    }
    this.updateInvoice();
  }

  getPackage(entityInstanceId: string): Package {

    let retPackage: Package;
    if (this.packages) {
      this.packages.forEach(aPackage => {

        //   console.log('serviceDto is ' + serviceDto.entityInstanceId.id);
        //  console.log('entityInstanceId is ' + entityInstanceId);
        if (aPackage.id === entityInstanceId) {
          //    console.log('found match!');
          retPackage = aPackage;
          return aPackage;
        } else {
          //   console.log('no match');
        }
      });
    }
    return retPackage;
  }

  getService(serviceId: string): ConsumerService {

    let retConsumerService: ConsumerService;
    if (this.consumerServices) {
      this.consumerServices.forEach(serviceDto => {

        //   console.log('serviceDto is ' + serviceDto.entityInstanceId.id);
        //  console.log('entityInstanceId is ' + entityInstanceId);
        if (serviceDto.id === serviceId) {
          //    console.log('found match!');
          retConsumerService = serviceDto;
          return serviceDto;
        } else {
          //   console.log('no match');
        }
      });
    }
    return retConsumerService;
  }

  updateInvoice() {
    const req: UpdateInvoiceComp = <UpdateInvoiceComp>{};
    req.contextIdDto = this.contextIdDto;
    req.invoice = this.invoice;
    this.spinner.show();
    this.invoiceCompApi.updateInvoiceComp(req)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.spinner.hide();
        // @ts-ignore
        this.invoice = response.invoice;
        if (this.changeListener) {
          this.changeListener.add('invoiceUpdated');
        }
        // this.ecs.handleChangedElements(response.changedElementList, this.form);
      });
  }

  loadInvoice() {

    this.api.loadInvoice(this.invoice.invoiceId.id, this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(response => {
      console.log('loadInvoice:invoice', response);
      this.invoice = response;
    });
  }



  requestDeposit() {
    this.dialog.open(RequestPaymentDialogComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        invoice: this.invoice,
        eventBus: this.eventBus
      },
      autoFocus: false
    });
  }

  sendInvoice() {
    this.dialog.open(SendInvoiceDialogComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        invoice: this.invoice,
        eventBus: this.eventBus
      },
      autoFocus: false
    });
  }

  loadDataForPanel() {
    console.log('elementDto.viewInvoicePanelDataDto ', this.invoice);
    if (this.invoiceId) {
      this.invoiceCompApi.loadDataForServicePanelV2(
        this.invoiceId.id,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType).subscribe(response => {

          this.consumerServices = response.loadServicesResponse.services;
          this.products = response.loadProductsResponse.products as Product[];
          this.currencyCode = response.currencyCode;
          this.userDtos = response.listUsersResponse.userDtos;
          this.invoiceDefinition = response.invoiceDefinition;
          this.invoicePanelServiceRowDtos = response.invoicePanelDto.invoicePanelServiceRowDtos;
          // @ts-ignore
          this.invoice = response.invoicePanelDto.invoice;
          // this.model.amount = this.invoice.total;
        });
    } else {
      console.log('this.invoiceId', this.invoiceId);
      // alert('error no invoice id');
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  filterProducts(search) {
    this.searchProduct = search;
  }

  createProduct(rowIndex) {
    const dialogRef = this.dialog.open(CreateProductComponent, {
      width: '550px',
      panelClass: ['scrollable-modal', 'helpwindow'],
      data: {
        contextIdDto: this.contextIdDto
      },
    });

    dialogRef.afterClosed().subscribe((product: Product) => {
        console.log('back from dialog close');
        if (product) {
          product.ownerId = this.contextIdDto.contextId;
          this.spinner.show();
          this.productService.createProduct(product).subscribe((product: Product) => {
            this.spinner.hide();
            if (product) {
              this.loadProductsAndSetCreated(rowIndex, product);
            }
          });
        }
      });
  }

  loadProductsAndSetCreated(rowIndex, product: Product) {
    this.productService.listProducts(this.contextIdDto.contextId).subscribe(response => {
      this.products = response;
      const productDto = this.getProduct(product?.id);
      if (productDto?.entityInstanceId?.id && this.invoice.invoiceProductRows && this.invoice.invoiceProductRows[rowIndex]) {
        this.invoice.invoiceProductRows[rowIndex].productNumber = productDto?.entityInstanceId?.id;
        this.productChanged(this.invoice.invoiceProductRows[rowIndex]);
      }
    });
  }

  onDiscountClick(row) {
    const dialogRef = this.dialog.open(ViewDiscountsModalComponent, {
      data: {
        amount: row.unitPrice,
        discountId: row.discountId
      },
      width: '775px',
      autoFocus: false,
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result) {
          if (result.discountItem) {
            row.discountDetails = result.discountItem.discount;
            row.discountId = result.discountItem.id;
          } else {
            if (!row.discountId) {
              //overall discount
              this.invoice.discountDetails = null;
              this.invoice.discountId = null;
            }
            row.discountDetails = null;
            row.discountId = null;
          }
          this.updateInvoice();
        }
      });
  }

  applyDiscountOnInvoice() {
    const dialogRef = this.dialog.open(ViewDiscountsModalComponent, {
      data: {
        amount: this.invoice.total,
        discountId: this.invoice.discountId
      },
      width: '775px',
      autoFocus: false,
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result) {
          if (result.discountItem) {
            this.invoice.discountDetails = result.discountItem.discount;
            this.invoice.discountId = result.discountItem.id;
          } else {
            this.invoice.discountDetails = null;
            this.invoice.discountId = null;
          }
          this.updateInvoice();
        }
      });
  }

  loadDiscounts() {
    this.discountSettingsService
      .get(this.contextIdDto.contextId, this.contextIdDto.contextIdType)
      .subscribe(res => {
        this.discountSettings = res;
        if (this.discountSettings && this.discountSettings.discountItems) {
          this.discountSettings.discountItems.forEach(r => {
            this.discountItemsById[r.id] = r;
          });
        }
      });
  }

}
