import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from './notifications.component';
import { NotificationService, UpdateNotification } from '@savvy/notification';
import { Router } from '@angular/router';
import { UsersService } from '@savvy/user';
import { UserDto } from '@savvy/org';
import { LinkedIdTypeEnum, LinkId } from 'src/app/core/data-share.service';


@Component({
  selector: 'app-view-notification-dialog',
  templateUrl: 'viewNotificationDialog.html'
})
export class ViewNotificationDialogComponent {

  customerId: string;
  userId: string;
  userDto: UserDto;

  constructor(
    private api: NotificationService,
    private userApi: UsersService,
    private router: Router,
    public dialogRef: MatDialogRef<ViewNotificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {

    this.setIds();
    this.loadUser();
  }

  close() {
    this.dialogRef.close(true);
  }

  onNoClick(): void {
    this.dialogRef.close(true);
  }

  navigate() {
    // this.router.navigate()
  }

  markAsRead() {

    const req = <UpdateNotification>{};
    req.notification = this.data.notification;
    req.notification.read = true;
    req.contextIdDto = this.data.contextIdDto;

    this.api.update(req).subscribe(response => {
      this.data.notification = response;
      this.dialogRef.close();
    });
  }


  viewCustomer() {
    this.router.navigate(['/customer/viewCustomer',
      this.data.contextIdDto.contextId,
      this.data.contextIdDto.contextIdType,
      this.customerId]);
  }


  loadUser() {
    if (this.userId) {
      this.userApi.getUser(
        this.data.contextIdDto.contextId,
        this.data.contextIdDto.contextIdType,
        this.userId
      ).subscribe(response => {

        this.userDto = response;
      });
    }
  }


  setIds() {
    if (this.data.notification.linkIds) {
      this.data.notification.linkIds.forEach(
        linkId => {
          if (linkId.linkedIdType === LinkedIdTypeEnum.CustomerId) {
            this.customerId = linkId.linkedId;
          } else if (linkId.linkedIdType === LinkedIdTypeEnum.UserId) {
            this.userId = linkId.linkedId;
          }
        }
      );
    }
  }
}

