<mat-card appearance="outlined" *ngIf="cachedTableRowPageDto && cachedTableRowPageDto.contents.length > 0">

    <mat-card-title>
      <div fxLayout="row">
        <div fxFlex="80">
          <p class="mt-xs mb-xs">{{listCachedTableViewDataDto.listCachedEntityTableDefinition.label | translate}}</p>
        </div>
        <div fxFlex="20">
          <button mat-mini-fab color="accent" [disabled]="trialExpiredService.isTrialExpired()" (click)="create()">
            <mat-icon class="mat-24">add</mat-icon>
          </button>
        </div>
      </div>
    </mat-card-title>
    <mat-card-content>
      <ngx-datatable
        *ngIf="rows && rows.length > 0"
        class='material has-plus-btn'
        [rows]='rows'
        [columnMode]="'force'"
        [headerHeight]="50"
        [rowHeight]="'auto'"
        [footerHeight]="50"
        [selected]="selected"
        [selectionType]="'single'"
        [columns]="columns"
        [externalPaging]="true"
        [count]="page.totalElements"
        [offset]="page.pageNumber"
        [limit]="page.size"
        (select)="onSelect($event)"
        (page)='setPage($event)'
      >
      </ngx-datatable>

    </mat-card-content>
  </mat-card>

