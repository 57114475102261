import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { CustomerService } from '@savvy/customer';
import { CustomerCompositeService } from '@savvy/customer';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { EventBusService } from './EventBusService';
import { ChangeListener } from './changeListener';
import { ConfirmationModalComponent } from '../shared/components/confirmation-modal/confirmation-modal.component';
import { ViewCustomerPageDefinitionDto } from '@savvy/view-definition';
import { ContextIdDto } from '@savvy/quickbooks';

@Component({
  selector: 'app-view-customer-page',
  templateUrl: './viewCustomerPage.component.html',
  styleUrls: ['viewCustomerPage.scss']
})
export class ViewCustomerPageComponent implements OnInit {

  @Input() viewCustomerPageDefinitionDto: ViewCustomerPageDefinitionDto;
  @Input() contextIdDto: ContextIdDto;
  @Input() viewContext: any;
  @Input() form: UntypedFormGroup;
  @Input() additionalDataMapDto: any;
  @Input() showPlaceholder: boolean;
  @Input() eventBus: EventBusService;
  @Input() changeListener: ChangeListener;


  constructor(
    private router: Router,
    private api: CustomerCompositeService,
    private customerApi: CustomerService,
    private translateService: TranslateService,
    private notify: FloSnackbarComponent,
    private dialog: MatDialog) {
  }

  ngOnInit(): void {
  }


  backToList() {
    this.router.navigate(['/customer/customers',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType]);
  }

  editCustomer() {
    this.router.navigate(['/customer/editCustomer',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      this.viewContext.customerContextDto.customerId.id
    ]);
  }

  removeCustomer() {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        name: 'Customer'
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.api.deleteCustomerComp(
          this.contextIdDto.contextId, this.contextIdDto.contextIdType, this.viewContext.customerContextDto.customerId.id
        ).subscribe(response => {
          this.translateService.get('Successfully deleted customer').subscribe(value => {
            this.notify.message = value;
            this.notify.open();
          });
          this.backToList();
        });
      }
    });
  }
}

