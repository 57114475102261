<mat-card appearance="outlined" class="panel new-card" *ngIf="petTupleDtos && petTupleDtos.length > 0">
  <mat-card-title>
    <div fxLayout="row">
      <div fxFlex="80">
        <p class="mt-xs mb-xs">{{'Pets' | translate}}</p>
      </div>
      <div fxFlex="20">
        <button mat-mini-fab color="accent" [disabled]="trialExpiredService.isTrialExpired()" (click)="create()">
          <mat-icon class="mat-24">add</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-title>

  <mat-card-content>
    <mat-list>
      <mat-list-item *ngFor="let petTupleDto of petTupleDtos">
        <p matLine class="pet-redirect" (click)="rowSelected(petTupleDto.id)">{{petTupleDto.name}}</p>
        <mat-divider *ngIf="petTupleDtos.length > 1"></mat-divider>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card>

<mat-card appearance="outlined" class="panel new-card" *ngIf="(!petTupleDtos || petTupleDtos.length === 0)">

  <mat-card-content>
    <div class="flexbox-parent">
      <div fxLayout="row" fxLayoutAlign="center center">
        <div style="text-align:center;">
          <app-zero-image name="Pets"></app-zero-image><br>
          <h5 class="ma-0">{{'You don’t have any Pets' | translate}}</h5><br>
          <!-- *ngIf="listEntityListDto.canCreate" -->
          <button mat-raised-button color="accent" [disabled]="trialExpiredService.isTrialExpired()" (click)="create()">{{'New Pet' | translate}}</button>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>