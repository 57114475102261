import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContextIdDto } from '@savvy/entity-instance-composite';

@Component({
  selector: 'app-select-subscription-dialog',
  template: `
    <mat-dialog-content style="height: 100%; max-height: initial;">
      <app-select-subscription-plan [dialogParams]="{contextId: contextIdDto, contextIdType: 'ENV_ID'}"></app-select-subscription-plan>
    </mat-dialog-content>
  `
})
export class SubscriptionDialogComponent {
  contextIdDto: ContextIdDto;

  constructor(
    public dialogRef: MatDialogRef<SubscriptionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.contextIdDto = data.contextIdDto;
  }

}
