<div fxLayout="row" fxLayoutAlign="center start" class="confirm-modal">
  <div fxFlex="100"  class="mt-xs" *ngIf="loadedSeries">
      <mat-card-title>
        {{'CONFIRMATION' | translate}}
      </mat-card-title>
      <mat-card-content>
        <div *ngIf="!isSeries">{{'SURE_DELETE_THIS' | translate}} <b>{{data?.name}}</b>?</div>
        <div *ngIf="isSeries">{{'REPEAT_OF' | translate}} <b>{{data?.name}}</b> {{'DO_YOU_WANT_TO_DELETE'  | translate}}</div>
      </mat-card-content>
      <mat-card-content>
        <button *ngIf="!isSeries" mat-raised-button color="accent" (click)="deleteEi()">{{'DELETE' | translate}}</button>&nbsp;
        <button  *ngIf="isSeries" mat-raised-button color="accent" (click)="deleteEi()">{{'DELETE_APPT_ONLY' | translate}}</button>&nbsp;
        <button *ngIf="isSeries" mat-raised-button color="accent" (click)="deleteSeries()">{{'DELETE_APPT_FUTURE' | translate}}</button>&nbsp;
        <button mat-raised-button color="accent" (click)="dialogRef.close(false)">{{'CANCEL' | translate}}</button>
      </mat-card-content>
  </div>
</div>
