<h2 mat-dialog-title *ngIf="schedule.id">{{'RE_SCHEDULE' | translate}}</h2>
<h2 mat-dialog-title *ngIf="!schedule.id">{{'SCHEDULE' | translate}}</h2>
<mat-dialog-content class="mat-typography">
  <!-- <app-payment-scheduler #paymentSchedulerComponent [contextIdDto]="data.contextIdDto"
    [invoiceId]="data.invoiceId" [requestForPaymentOwnerId]="data.requestForPaymentOwnerId"
    [requestForPaymentOwnerType]="requestForPaymentOwnerType">
  </app-payment-scheduler> -->
  <!-- <mat-card *ngIf="paymentMode === 'RD'">
    <mat-card-content>
      <app-payment-deposit-request #paymentDepositRequestComponent></app-payment-deposit-request>
    </mat-card-content>
  </mat-card>
  <br *ngIf="paymentMode === 'RD'"> -->

  <!-- <div fxFlex="100" fxLayout="column">
    <mat-radio-group fxFlex [(ngModel)]="paymentType">
      <mat-radio-button value="AMOUNT">{{'Amount' | translate}}</mat-radio-button>&nbsp;&nbsp;
      <mat-radio-button value="PERCENTAGE">{{'Percentage' | translate}}</mat-radio-button>&nbsp;&nbsp;
    </mat-radio-group>
  </div> -->

  <div fxFlex="100" fxLayout="column">
    <mat-form-field *ngIf="paymentType === 'AMOUNT'" appearance="outline">
      <div matSuffix>{{ currencyCode }}</div>
      <mat-label>{{'AMOUNT' | translate}}</mat-label>
      <input type="text" appNumberOnly [decimalPoints]="2" matInput name="amount" placeholder="{{'AMOUNT' | translate}}" [(ngModel)]="schedule.paymentAmountInPence">
    </mat-form-field>

    <mat-form-field *ngIf="paymentType === 'PERCENTAGE'" appearance="outline">
      <div matSuffix>%</div>
      <mat-label>{{'PERCENTAGE' | translate}}</mat-label>
      <input type="text" appNumberOnly matInput name="percentage" placeholder="{{'PERCENTAGE' | translate}}" [(ngModel)]="schedule.percentOfTotal">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{'DATE_TO_SEND' | translate}}</mat-label>
      <input matInput [(ngModel)]="schedule.dateToSend" [matDatepicker]="picker" required (focus)="picker.open()" (click)="picker.open()" placeholder="{{'DATE_TO_SEND' | translate}}" [min]="minRange" [max]="schedule.dueDate">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{'DUE_DATE' | translate}}</mat-label>
      <input matInput [(ngModel)]="schedule.dueDate" [matDatepicker]="dueDate" required (focus)="dueDate.open()" (click)="dueDate.open()" placeholder="{{'DUE_DATE' | translate}}" [min]="minRange">
      <mat-datepicker-toggle matSuffix [for]="dueDate"></mat-datepicker-toggle>
      <mat-datepicker #dueDate></mat-datepicker>
    </mat-form-field>

    <!-- <button style="width: 30%;" mat-raised-button color="accent" *ngIf="paymentMode == 'PS'" (click)="addSchedule()"
          [disabled]="disableAddingMore">Add Payment Request</button>&nbsp;&nbsp; -->

  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button color="accent" (click)="close()">{{'CLOSE' | translate}}</button>
  <button mat-flat-button color="primary" (click)="reSchedule()" cdkFocusInitial>{{'SAVE' | translate}}</button>
</mat-dialog-actions>