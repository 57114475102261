import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { OrgCompositeService } from '@savvy/org';

@Component({
  selector: 'app-payment-deposit-request',
  templateUrl: './payment-deposit-request.component.html',
  styleUrls: ['./payment-deposit-request.component.scss']
})
export class PaymentDepositRequestComponent implements OnInit {

  depositForm: UntypedFormGroup = new UntypedFormGroup({
    paymentType: new UntypedFormControl('FULL_AMOUNT', [Validators.required]),
    amount: new UntypedFormControl('', []),
    percentage: new UntypedFormControl('', []),
    askForPayment: new UntypedFormControl('YES')
  });

  intialValues: any = this.depositForm.value;
  valid = false;
  currencyCode = '';

  constructor(private orgCompApi: OrgCompositeService) { }

  ngOnInit(): void {
    this.setDepositForm();
    this.setDepositFormValidators();
    this.checkIsValid();
    this.loadOrg();
  }

  onPaymentTypeChange() {
    this.setDepositFormValidators();
  }

  loadOrg() {
    this.orgCompApi.getCurrentOrgComp().subscribe(res => {
      if (res && res.currencyCode) {
        this.currencyCode = res.currencyCode;
      }
    });
  }

  setDepositFormValidators() {
    if (this.depositForm.value.paymentType === 'AMOUNT') {
      this.depositForm.controls.amount.setValidators([Validators.required, Validators.min(1)]);
      this.depositForm.controls.percentage.clearValidators();
    } else {
      this.depositForm.controls.amount.clearValidators();
      this.depositForm.controls.percentage.setValidators([Validators.required, Validators.min(1), Validators.max(100)]);
    }
    this.depositForm.controls.amount.updateValueAndValidity();
    this.depositForm.controls.percentage.updateValueAndValidity();
    this.checkIsValid();
  }

  setDepositForm() {
    this.depositForm.reset(this.intialValues);
    this.setDepositFormValidators();
    this.depositForm.markAsPristine();
  }

  checkIsValid() {
    switch (this.depositForm.value.paymentType) {
      case 'AMOUNT':
        if (this.depositForm.value.amount) {
          this.valid = true;
        } else {
          this.valid = false;
        }
        break;
      case 'PERCENTAGE':
        if (this.depositForm.value.percentage) {
          this.valid = true;
        } else {
          this.valid = false;
        }
        break;
      case 'FULL_AMOUNT':
        this.valid = true;
        break;
    }
  }


}
