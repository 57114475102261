<mat-toolbar color="primary" *ngIf="!zeroState && currentWorkflowDef">
  <span class="mr-1 ml-1">{{currentWorkflowDef.name | translate}} {{'BOARD' | translate}}</span>
  <div class="example-spacer"></div>
  <div *ngFor="let workflowDef of workflowDefinitions">
    <button mat-flat-button (click)="switchBoard(workflowDef)">{{workflowDef.name | translate}}</button>
    &nbsp;
  </div>


  <!--
  <button mat-button [matMenuTriggerFor]="menu">Switch Board</button>
  <mat-menu #menu="matMenu">
    <button (click)="switchBoard(workflowDef)" *ngFor="let workflowDef of workflowDefinitions" mat-menu-item>{{workflowDef.name}}</button>
  </mat-menu>
  -->
</mat-toolbar>
<div class="taskboard page-height">
  <div class="taskboard-wrapper" *ngFor="let board of workflowBoard">
    <div class="taskboard-list">
      <div class="taskboard-header text-uppercase">
        <strong>{{board.title | translate}}</strong>

      </div>
      <div class="taskboard-cards" [dragula]='"task-group"'>
        <div class="taskboard-task" *ngFor="let workflowInstance of board.workflowInstances" [ngClass]="workflowInstance.class">
          <div class="taskboard-task-title">{{workflowInstance.title | translate}}</div>
          <small class="card-text text-muted">{{workflowInstance.description | translate}}</small>
        </div>
      </div>
    </div>
  </div>
</div>
