<div class="file-upload" fxLayout="row wrap" fxLayoutAlign="space-around center">
  <div class="image-upload-wrap" fxFlex="100">
    <input class="file-upload-input" type='file' (change)="fileChangeEvent($event)" accept="image/*"
      [multiple]="multiple" />
    <div class="drag-text">
      <h3>
        <mat-icon class="upload-icon">backup</mat-icon><br>
        {{buttonCaption}}
      </h3>
    </div>
  </div>
</div>
<div class="file-upload-content" *ngIf="files && files.length" fxLayout="row wrap" fxLayoutAlign="space-around center">
  <div *ngFor="let file of files; let i = index">
    <div fxFlex="25" fxLayout="column" fxLayoutAlign="space-around center">
      <img fxFlex="100" (click)="editImage(file)" class="file-upload-image" [src]="file.url" alt="{{file.fileName}}" />
      <mat-icon fxFlex="100" (click)="removeUpload(i)" title="{{file.fileName}}" class="remove-icon">close</mat-icon>
    </div>
  </div>
</div>
