import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContextIdDto } from '@savvy/view-definition';


export interface ShowClashesModalDialogData {
  contextIdDto: ContextIdDto;
  clashResponse: any;
}

@Component({
  selector: 'app-show-clashes',
  templateUrl: 'showClashes.component.html'
})
export class ShowClashesComponent implements OnInit {

  contextIdDto: ContextIdDto;
  clashResponse: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ShowClashesModalDialogData,
    public dialogRef: MatDialogRef<ShowClashesComponent>) {
    this.contextIdDto = this.data.contextIdDto;
    this.clashResponse = this.data.clashResponse;
  }

  ngOnInit() {
  }
}
