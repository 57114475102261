import { Injectable } from '@angular/core';
import { DataItemDto, DataListDto, DatalistService } from '@savvy/datalist';
import { ConsumerService, ContextIdDto, ServicesService } from '@savvy/services';
import { UserServiceMatrixDto, UserServiceMatrixService } from '@savvy/user-service-matrix';
import * as _ from 'lodash';
@Injectable({
  providedIn: 'root'
})
export class ServicesHelperService {
  public consumerServices: Array<ConsumerService> = [];

  public serviceGroups: Array<DataItemDto> = [];
  public userServiceMatrixDto: UserServiceMatrixDto;
  
  constructor(
    private servicesService: ServicesService,
    private datalistService: DatalistService,
    private userServiceMatrixService: UserServiceMatrixService
  ) { }

  public loadServices(contextIdDto: ContextIdDto): Promise<Array<ConsumerService>> {
    this.consumerServices = [];
    return new Promise(async (resolve) => {
      if (!contextIdDto?.contextId) {
        resolve([]);
        return;
      }
      this.servicesService.listConsumerServices(contextIdDto.contextId).subscribe(async (response) => {
        console.log('loaded Services from loadServices', response);
        if (response && response.length) {
          this.consumerServices = response;
          await this.loadServiceMatrix(contextIdDto);
        }
        resolve(this.consumerServices);
      });
    });
  }

  public loadAllServices(contextIdDto: ContextIdDto): Promise<Array<ConsumerService>> {
    return new Promise((resolve) => {
      if (!contextIdDto?.contextId) {
        resolve([]);
        return;
      }
      this.servicesService.loadAllServices(contextIdDto.contextId).subscribe((response) => {
        console.log('loaded Services from loadAllServices', response);
        if (response && response.length) {
          resolve(response);
        } else {
          resolve([]);
        }
      });
    });
  }

  public loadServiceMatrix(contextIdDto: ContextIdDto) {
    this.userServiceMatrixService.getUserServiceMatrix(contextIdDto.contextId).subscribe(response => {
      this.userServiceMatrixDto = response;
    });
  }

  public isUserAllowedForService(serviceId: string, userId: string) {
    if (this.userServiceMatrixDto?.userServiceMatrixRows?.filter(r => r.userIds?.length > 0)?.length > 0) {
      // Checks only if service matrix active
      const matrixRow = this.userServiceMatrixDto.userServiceMatrixRows.find(row => row.serviceId === serviceId);
      if (!matrixRow.userIds?.length || matrixRow.userIds?.indexOf(userId) === -1) {
        return false;
      }
    }
    return true;
  }

  public getConsumerServices(contextIdDto: ContextIdDto, getNewServices = false, loadDeleted = false): Promise<Array<ConsumerService>> {
    return new Promise(resolve => {
      if (this.consumerServices.length && !getNewServices) {
        resolve(this.consumerServices);
      } else {
        this.consumerServices = [];
        if (loadDeleted) {
          this.loadAllServices(contextIdDto).then(response => {
            if (response && response.length) {
              // console.log('loaded Services');
              resolve(response);
            } else {
              resolve([]);
            }
          });
        } else {
          this.loadServices(contextIdDto).then(response => {
            if (response && response.length) {
              this.consumerServices = response;
              // console.log('loaded Services');
            }
            resolve(this.consumerServices);
          });
        }
      }
    });
  }

  public loadServiceGroups(contextIdDto: ContextIdDto): Promise<DataItemDto[]> {
    this.serviceGroups = [];
    return new Promise(resolve => {
      if (!contextIdDto?.contextId) {
        resolve([]);
        return;
      }
      this.datalistService.getDataListByName(contextIdDto.contextId, contextIdDto.contextIdType, 'ServiceGroup').subscribe(res => {
        this.serviceGroups = res.dataItems;
        console.log('loaded Service Groups');
        resolve(this.serviceGroups);
      });
    });
  }

  public getServiceGroups(contextIdDto: ContextIdDto, getNewServiceGroups = false): Promise<Array<DataItemDto>> {
    return new Promise(resolve => {
      if (this.serviceGroups.length && !getNewServiceGroups) {
        resolve(this.serviceGroups);
      } else {
        this.serviceGroups = [];
        this.loadServiceGroups(contextIdDto).then(serviceGroups => {
          if (serviceGroups && serviceGroups.length) {
            this.serviceGroups = serviceGroups;
          }
          resolve(serviceGroups);
        });
      }
    });
  }

  public getConsumerServiceById(id: string): ConsumerService[] {
    if (this.consumerServices) {
      return this.consumerServices.filter(service => service.id === id);
    }
    return [];
  }

  public getConsumerServicesGroupedByGroupId(contextIdDto: ContextIdDto): Promise<{ [key: string]: ConsumerService[] }> {
    return new Promise<{ [key: string]: ConsumerService[] }>((resolve) => {
      this.getConsumerServices(contextIdDto).then((latestServices: ConsumerService[]) => {
        const groupedData = _.groupBy(latestServices, 'groupName');
        console.log("Got grouped services", groupedData)
        resolve(groupedData);
      });
    });
  }
}
