<mat-toolbar color="primary">
  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
      <span>{{'ADD_PET' | translate}}</span>
    </div>
  </div>
</mat-toolbar>
  <form [formGroup]="petFormGroup">
    <div class="row">
    <div class="col-12">

      <mat-form-field appearance="outline" style="width: 100%;">
        <mat-label>{{'NAME' | translate}}</mat-label>
        <input matInput formControlName="petName" name="petName" required />
      </mat-form-field>
    </div>

    <div class="col-12">
    <mat-form-field appearance="outline" style="width: 100%;">
      <mat-label>{{'PET_TYPE' | translate}}</mat-label>
      <mat-select placeholder="{{'SEARCH_PET_TYPE' | translate}}" formControlName="petType" name="petType" required>
        <mat-option>
          <ngx-mat-select-search [placeholderLabel]="'SEARCH_TYPES' | translate" [formControl]="petTypeFilter">
          </ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let petType of filteredPetTypes | async" [value]="petType.name">
          {{ petType.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    </div>
    <div class="col-12">
    <mat-form-field appearance="outline" style="width: 100%;">
      <mat-label>{{'BREED' | translate}}</mat-label>
      <mat-select placeholder="{{'SELECT_BREED' | translate}}" formControlName="petBreed" name="petBreed">
        <mat-option>
          <ngx-mat-select-search [placeholderLabel]="'SEARCH_BREED' | translate" [formControl]="petBreedFilter">
          </ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let breed of filteredBreeds | async" [value]="breed.name">
          {{ breed.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    </div>
    <div class="col-12" style="width: 100%;">
      <app-existing-customer-search-selector [contextIdDto]="data.contextIdDto" *ngIf="existingCustomer"
        [label]="'Owner'" [maxWidth]="100" [customerId]="pet?.petOwnerId" [autoFocus]="false"
        (selectedCustomer)="customerSelected($event)" (newExistingSwitch)="existingCustomer = false">
      </app-existing-customer-search-selector>
    </div>
    </div>
</form>
<mat-dialog-actions align="end">
  <button mat-flat-button color="primary" (click)="addPet()" cdkFocusInitial *ngIf="!data.edit">
    {{'ADD' | translate}}
  </button>
  <button mat-flat-button color="primary" (click)="updatePet()" cdkFocusInitial *ngIf="data.edit">
    {{'UPDATE' | translate}}
  </button>
</mat-dialog-actions>
