<div class="mb-2 mx-0">

  <!--
   <app-google-maps #appGoogleMaps [longitude]="address?.longitude" [latitude]="address?.latitude"></app-google-maps>
   <br>
   -->
  <div *ngIf="countryCode == 'GB'">
    <!-- We ONLY enable this for UK customers, currently if not UK customer, we don't have an alternative address lookup -->
    <mat-slide-toggle color="accent" [(ngModel)]="googlePlacesLookup" name="googlePlacesLookup">
      {{'GOOGLE_LOOKUP' | translate}}
    </mat-slide-toggle>
  </div>
  <br>
  <div *ngIf="googlePlacesLookup">
    <app-google-places-lookup-v2 [longitude]="address?.longitude" [latitude]="address?.latitude" (modelUpdate)="onGoogleLocationUpdate($event)" label="{{'LOCATION' | translate}}"></app-google-places-lookup-v2>
  </div>

  <div *ngIf="!googlePlacesLookup && countryCode == 'GB'">
    <!--div id="map" style="display: none;"></div-->
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>{{'POSTCODE' | translate: {Default: 'Postcode'} }}
      </mat-label>

      <input #locInput matInput placeholder="{{'ENTER_POSTCODE' | translate}}" [required]="true" [(ngModel)]="locationValue" name="addressInput">

    </mat-form-field>

    <button mat-flat-button class="mdc-mb-1" type="button" color="accent" (click)="lookup()">{{'FIND_ADDRESS' |
      translate}}</button>

    <mat-form-field appearance="outline" class="w-100" *ngIf="addresses && addresses.length">
      <mat-label>{{'SELECT_ADDRESS' | translate}}
      </mat-label>
      <mat-select [(ngModel)]="address" (selectionChange)="addressSelected()">
        <mat-option *ngFor="let prediction of addresses" [value]="prediction">
          {{prediction?.address1}} {{prediction?.address2}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<div *ngIf="address" class="mdc-mx-0">
  <button class="mdc-mb-1" *ngIf="address && address.longitude" mat-flat-button type="button" color="primary" (click)="openGoogleMaps()">Get Directions</button>
  <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutGap.gt-sm="16px">
    <div fxFlex="100" fxFlex.gt-sm="32.2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'ADDRESS' | translate}} 1</mat-label>
        <input matInput [(ngModel)]="address.address1" name="address1" (change)="addressManuallyChanged()" #firstName="ngModel" class="demo-full-width" placeholder="{{'ADDRESS' | translate}} 1" style="width: 100%">
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="32.2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'ADDRESS' | translate}} 2</mat-label>
        <input matInput [(ngModel)]="address.address2" name="address2" (change)="addressManuallyChanged()" #firstName="ngModel" class="demo-full-width" placeholder="{{'ADDRESS' | translate}} 2" style="width: 100%">
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="32.2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'ADDRESS' | translate}} 3</mat-label>
        <input matInput [(ngModel)]="address.address3" name="address3" (change)="addressManuallyChanged()" #firstName="ngModel" class="demo-full-width" placeholder="{{'ADDRESS' | translate}} 3" style="width: 100%">
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutGap.gt-sm="16px">
    <div fxFlex="100" fxFlex.gt-sm="32.2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'CITY' | translate}}</mat-label>
        <input matInput [(ngModel)]="address.city" name="city" (change)="addressManuallyChanged()" #firstName="ngModel" class="demo-full-width" placeholder="{{'CITY' | translate}}" style="width: 100%">
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="32.2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'COUNTRY' | translate}}</mat-label>
        <input matInput [(ngModel)]="address.country" name="country" (change)="addressManuallyChanged()" #firstName="ngModel" class="demo-full-width" placeholder="{{'COUNTRY' | translate}}" style="width: 100%">
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutGap.gt-sm="16px">
    <div fxFlex="100" fxFlex.gt-sm="32.2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'STATE' | translate}}</mat-label>
        <input matInput [(ngModel)]="address.state" name="state" (change)="addressManuallyChanged()" #firstName="ngModel" class="demo-full-width" placeholder="{{'STATE' | translate}}" style="width: 100%">
      </mat-form-field>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="32.2">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'ZIPCODE' | translate}}</mat-label>
        <input matInput [(ngModel)]="address.zipCode" name="zipCode" (change)="addressManuallyChanged()" #firstName="ngModel" class="demo-full-width" placeholder="{{'ZIPCODE' | translate}}" style="width: 100%">
      </mat-form-field>
    </div>
  </div>

</div>