<form [formGroup]="customerPortalLinkForm">
  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.xs="0px" fxFlexFill fxLayoutAlign.gt-sm="center center">
    <mat-form-field fxFlex appearance="outline">
      <mat-label>{{'CUSTOMER_PORTAL' | translate}}</mat-label>
      <input matInput formControlName="label">
      <mat-hint [align]="'end'">{{linkForm?.value?.label?.length || 0}} / 50</mat-hint>
      <mat-error *ngIf="linkForm?.controls?.label.hasError('required')">
        {{'LINK_NAME_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
      </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex appearance="outline">
      <mat-label>{{'LINK_URL' | translate}}</mat-label>
      <input matInput formControlName="link" readonly>
      <!-- <mat-hint [align]="'end'">{{linkForm?.value?.link?.length || 0}} / 250</mat-hint>
      <mat-error *ngIf="linkForm?.controls?.link.hasError('required')">
        {{'Link URL is' | translate}} <strong>{{'required' | translate}}</strong>
      </mat-error> -->
    </mat-form-field>
    <button mat-icon-button color="accent" class="mdc-mb-1" aria-label="Add to editor" #tooltip="matTooltip" matTooltip="{{'ADD_TO_EDITOR' | translate}}" [disabled]="!customerPortalLinkForm.valid" (click)="addCustomerPortalLink()">
      <mat-icon>new_label</mat-icon>
    </button>

  </div>
</form>
<form [formGroup]="inviteToCustomerPortalLinkForm">
  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.xs="0px" fxFlexFill fxLayoutAlign.gt-sm="center center">
    <mat-form-field fxFlex appearance="outline">
      <mat-label>{{'INVITE_CUSTOMER_PORTAL' | translate}}</mat-label>
      <input matInput formControlName="label">
      <mat-hint [align]="'end'">{{inviteToCustomerPortalLinkForm?.value?.label?.length || 0}} / 50</mat-hint>
      <mat-error *ngIf="inviteToCustomerPortalLinkForm?.controls?.label.hasError('required')">
        {{'LINK_NAME_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
      </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex appearance="outline">
      <mat-label>{{'LINK_URL' | translate}}</mat-label>
      <input matInput formControlName="link" readonly>
      <!-- <mat-hint [align]="'end'">{{linkForm?.value?.link?.length || 0}} / 250</mat-hint>
      <mat-error *ngIf="linkForm?.controls?.link.hasError('required')">
        {{'Link URL is' | translate}} <strong>{{'required' | translate}}</strong>
      </mat-error> -->
    </mat-form-field>
    <button mat-icon-button color="accent" class="mdc-mb-1" aria-label="Add to editor" #tooltip="matTooltip" matTooltip="{{'ADD_TO_EDITOR' | translate}}" [disabled]="!inviteToCustomerPortalLinkForm.valid" (click)="addInviteToCustomerPortalLink()">
      <mat-icon>new_label</mat-icon>
    </button>
  </div>
</form>
<form [formGroup]="invoiceLinkForm">
  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.xs="0px" fxFlexFill fxLayoutAlign.gt-sm="center center">
    <mat-form-field fxFlex appearance="outline">
      <mat-label>{{'INCLUDE_INVOICE_LINK' | translate}}</mat-label>
      <input matInput formControlName="label">
      <mat-hint [align]="'end'">{{invoiceLinkForm?.value?.label?.length || 0}} / 50</mat-hint>
      <mat-error *ngIf="invoiceLinkForm?.controls?.label.hasError('required')">
        {{'LINK_NAME_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
      </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex appearance="outline">
      <mat-label>{{'LINK_URL' | translate}}</mat-label>
      <input matInput formControlName="link" readonly>
      <!-- <mat-hint [align]="'end'">{{linkForm?.value?.link?.length || 0}} / 250</mat-hint>
      <mat-error *ngIf="linkForm?.controls?.link.hasError('required')">
        {{'Link URL is' | translate}} <strong>{{'required' | translate}}</strong>
      </mat-error> -->
    </mat-form-field>
    <button mat-icon-button color="accent" class="mdc-mb-1" aria-label="Add to editor" #tooltip="matTooltip" matTooltip="{{'ADD_TO_EDITOR' | translate}}" [disabled]="!invoiceLinkForm.valid" (click)="addInvoiceLink()">
      <mat-icon>new_label</mat-icon>
    </button>
  </div>
</form>
<form [formGroup]="linkForm">
  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.xs="0px" fxFlexFill fxLayoutAlign.gt-sm="center center">
    <mat-form-field fxFlex appearance="outline">
      <mat-label>{{'LINK_NAME' | translate}}</mat-label>
      <input matInput formControlName="label">
      <mat-hint [align]="'end'">{{linkForm?.value?.label?.length || 0}} / 50</mat-hint>
      <mat-error *ngIf="linkForm?.controls?.label.hasError('required')">
        {{'LINK_NAME_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
      </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex appearance="outline">
      <mat-label>{{'LINK_URL' | translate}}</mat-label>
      <input matInput formControlName="link">
      <mat-hint [align]="'end'">{{linkForm?.value?.link?.length || 0}} / 250</mat-hint>
      <mat-error *ngIf="linkForm?.controls?.link.hasError('required')">
        {{'LINK_URL_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
      </mat-error>
    </mat-form-field>
    <button mat-icon-button color="accent" class="mdc-mb-1" aria-label="Add to editor" #tooltip="matTooltip" matTooltip="{{'ADD_TO_EDITOR' | translate}}" [disabled]="!linkForm.valid" (click)="onAddItemClick()">
      <mat-icon>new_label</mat-icon>
    </button>
  </div>
</form>