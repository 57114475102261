import { Component, Input, OnInit } from '@angular/core';
import { SingleLabelValuePanelDto } from '@savvy/view-definition';

@Component({
    selector: 'app-single-label-value-panel',
    templateUrl: 'singleLabelValuePanel.component.html'
})
export class SingleLabelValuePanelComponent implements OnInit {
    ngOnInit(): void {
    }

    @Input() singleLabelValuePanelDto: SingleLabelValuePanelDto;
}

