import { Component, Input, OnInit } from '@angular/core';
import { EventBusService } from './EventBusService';
import { UntypedFormGroup } from '@angular/forms';
import { ElementControlService } from './ElementControlService';
import { Customer, CustomerCompositeService, CustomerService, UpdateCustomer } from '@savvy/customer';
import { TranslateService } from '@ngx-translate/core';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { PhoneNumberHelperService } from '../shared/services/phone-number-helper.service';
import { MatDialog } from '@angular/material/dialog';
import { UserHistoryModalComponent } from './user-history-modal/user-history-modal.component';
import { UserCountryService } from '../../services/userCountry.service';
import { ViewCustomerEmbeddedData } from '@savvy/view-composite';
import { Address } from '@savvy/entity-instance-composite';
import { ContextIdDto, PhoneNumber } from '@savvy/view-definition';

@Component({
  selector: 'app-view-embedded-customer',
  templateUrl: './viewEmbeddedCustomer.component.html',
  styles: [`
      .notes {
        resize: none;
        height: 120px;
        max-height: 120px;
      }

      .full-location {
        position: relative;
        width: 213%;
      }

      .notes-field {
        margin-top: 162px;
      }

      @media (max-width: 599px) {
        .full-location {
          position: static;
          width: 100%;
        }
        .notes-field {
          margin-top: 0;
        }
      }
    `]
})
export class ViewEmbeddedCustomerComponent implements OnInit {

  @Input() viewCustomerEmbeddedData: ViewCustomerEmbeddedData;
  @Input() contextIdDto: ContextIdDto;
  @Input() eventBus: EventBusService;


  model = <Customer>{};

  company = false;
  showNotes = false;
  countryCode: string;

  localPhones = {
    mobilePhoneNumberObj: {},
    phoneNumberObj: {},
    workPhoneNumberObj: {},
    whatsAppNumberObj: {}
  };
  public form: UntypedFormGroup; // our form model

  constructor(
    private ecs: ElementControlService,
    private userCountryService: UserCountryService,
    private translateService: TranslateService,
    private notify: FloSnackbarComponent,
    private api: CustomerCompositeService,
    private customerService: CustomerService,
    private sharedService: PhoneNumberHelperService,
    private dialog: MatDialog) {

    this.countryCode = this.userCountryService?.defaultCountryCode;
    console.log('countryCode is ' + this.countryCode);
  }

  ngOnInit(): void {
    this.loadCustomer();
    if (!this.eventBus) {
      // this.eventBus.eventAdded$.subscribe(event => this.onEvent(event));
    }
  }
  loadCustomer() {
    if (this.viewCustomerEmbeddedData && this.viewCustomerEmbeddedData.customerId &&
      this.viewCustomerEmbeddedData.customerId.id) {
      this.customerService.loadCustomer(
        this.viewCustomerEmbeddedData.customerId.id,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType).subscribe(response => {
          console.log('got response');
          this.model = response;
          this.setLocalPhones(this.model, this.localPhones);
          if (this.model.companyName || this.model.companyNumber) {
            this.company = true;
          }
        });
    }
  }

  addressChanged(address: Address) {
    this.model.address = address;
    if (this.model.id) {
      this.updateCustomer();
    }
  }

  updateCustomer() {

    console.log('updating customer id ' + this.model.id);

    const req = <UpdateCustomer>{};

    req.customer = this.model;
    req.contextIdDto = this.contextIdDto;

    this.customerService.updateCustomer(req).subscribe(response => {

      // Stay on page? and change it to edit screen?
      this.model = response;
      console.log('updated customer');
      this.translateService.get('Successfully updated customer').subscribe(value => {
        this.notify.message = value;
        this.notify.open();
      });
      // this.eventBus.addCustomerEvent(EventType.CUSTOMER_UPDATED, this.model.customer.customerId);
    });
  }

  private setLocalPhones(user, localPhones) {
    this.sharedService.setLocalPhones(user, localPhones);
  }

  onLocationUpdate(value) {
    return this.model.address = value;
  }

  companyChanged() {
    if (!this.company) {
      this.model.companyNumber = null;
      this.model.companyName = null;
    }
  }

  onMobileChange(value) {
    this.model.mobilePhoneNumberObj = value;
    this.model.whatsAppNumberObj = value;
  }

  onPhoneChanged(value) {
    this.model.phoneNumberObj = value;
  }

  onWorkPhoneChanged(value) {
    this.model.workPhoneNumberObj = value;
  }

  onMobileCountryChange($event: any) {
    this.model.mobilePhoneNumberObj = <PhoneNumber>{};
    this.model.mobilePhoneNumberObj.country = '';
    console.log('mobile country change is ', $event);
    console.log('mobile country change is ', $event.iso2);
    if (this.model.mobilePhoneNumberObj['country']) {
      this.model.mobilePhoneNumberObj['country'] = $event.iso2;
      this.model.whatsAppNumberObj['country'] = $event.iso2;
      this.updateCustomer();
    } else {
      console.log('Error, no country available');
    }
  }

  onPhoneCountryChange($event: any) {
    this.model.phoneNumberObj = <PhoneNumber>{};
    this.model.phoneNumberObj.country = '';
    if (this.model.phoneNumberObj['country']) {
      this.model.phoneNumberObj['country'] = $event.iso2;
      this.updateCustomer();
    } else {
      console.log('Error, no country available');
    }
  }

  onWorkPhoneCountryChange($event: any) {
    this.model.workPhoneNumberObj = <PhoneNumber>{};
    this.model.workPhoneNumberObj.country = '';
    if (this.model.workPhoneNumberObj['country']) {
      this.model.workPhoneNumberObj['country'] = $event.iso2;
      this.updateCustomer();
    } else {
      console.log('Error, no country available');
    }
  }

  onManualAddresChange() {
    this.model.address = {};
  }
  showHistoryModal() {
    this.dialog.open(UserHistoryModalComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        customerId: this.model.id
      },
      maxHeight: '80vh',
      panelClass: 'user-modal-history'
    });
  }
}
