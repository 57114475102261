<div *ngIf="selectedReminder && visible">
  <mat-toolbar color="primary">{{'SENDING_REMINDER_AT' | translate}} {{selectedReminder.dueToSend | localizedDate:'h:mm
    a on d MMM'}}</mat-toolbar>

  <mat-card appearance="outlined">
    <form #reminderForm="ngForm" style="width: 100%">


      <mat-card-content *ngIf="isSmsSent(selectedReminder)">
        {{'SMS_REMINDER_WAS_SENT' | translate}} {{selectedReminder.smsReminder.sendReminderResult.timeSent}}
      </mat-card-content>
      <mat-card-content>
        <mat-form-field style="width: 100%" appearance="outline">
          <mat-label>{{'EMAIL_SUBJECT' | translate}}</mat-label>
          <input matInput [(ngModel)]="selectedReminder.emailReminder.subject" name="subject" #subject="ngModel"
            placeholder="{{'EMAIL_SUBJECT' | translate}}" required>
          <!--
        <mat-error *ngIf="subject.hasError('required')">
          Subject is <strong>required</strong>
        </mat-error>
        -->
        </mat-form-field>
        <br>

        <mat-form-field style="width: 100%" appearance="outline">
          <mat-label>{{'EMAIL_BODY' | translate}}</mat-label>
          <textarea matInput [(ngModel)]="selectedReminder.emailReminder.body" name="body" #body="ngModel" rows="4"
            style="width: 100%" placeholder="{{'EMAIL_BODY' | translate}}" required></textarea>
          <!--
        <mat-error *ngIf="body.hasError('required')">
          Email body is <strong>required</strong>
        </mat-error>
        -->
        </mat-form-field>
        <br>
        <!--mat-form-field  appearance="outline" style="width: 100%">
        <mat-label>{{'SMS From' | translate}}</mat-label>
        <input matInput placeholder="{{'SMS From' | translate}}" name="smsFrom" #smsFrom="ngModel"
               [(ngModel)]="selectedReminder.smsReminder.from" required appSenderidValidator/>

        <mat-error *ngIf="smsFrom.hasError('required')">
          {{'Sender Id is required' | translate}}
        </mat-error>
        <mat-error *ngIf="smsFrom.hasError('senderIdValidator') && !smsFrom.hasError('required')">
           {{'Invalid Sender Id.  Sender Id can be up to 11 characters from the following categories: Upper-case letters A-Z, Lower-case letters a-z, Numbers 0-9, Spaces.  It must include at least one letter; It cannot be comprised of only numbers. Special characters and punctuation are not allowed.' | translate}}
        </mat-error>

      </mat-form-field-->
        <br>

        <mat-form-field style="width: 100%" appearance="outline">
          <mat-label>{{'TEXT_MESSAGE' | translate}}</mat-label>
          <textarea matInput [(ngModel)]="selectedReminder.smsReminder.message" name="smsText" #smsText="ngModel"
            style="width: 100%" rows="4" placeholder="{{'TEXT_MESSAGE' | translate}}" required></textarea>
          <!--
        <mat-error *ngIf="smsText.hasError('required')">
          Text message is <strong>required</strong>
        </mat-error>
        -->
        </mat-form-field>


        <br>
        <div *ngIf="selectedReminder.timeSubmittedToQueue">
          <span>
            {{selectedReminder.timeSubmittedToQueue | localizedDate:'h:mm a on d MMM'}}
          </span>
          <br>

        </div>


        <mat-form-field style="width: 100%" appearance="outline">
          <mat-label>{{'DATE_TIME_TO_SEND_REMINDER' | translate}}</mat-label>
          <input matInput placeholder="{{'DATE_TIME_TO_SEND_REMINDER' | translate}}" [owlDateTimeTrigger]="dtPicker2"
            [owlDateTime]="dtPicker2" name="timeToSend" #timeToSend="ngModel" [(ngModel)]="selectedReminder.dueToSend"
            required>
          <owl-date-time #dtPicker2></owl-date-time>
        </mat-form-field>
      </mat-card-content>
      <mat-card-content>
        <button (click)="update()" [disabled]="!reminderForm.form.valid" mat-raised-button color="accent">{{'UPDATE' |
          translate}}</button>
        &nbsp;
        <button (click)="closeThePanel()" mat-raised-button color="accent">{{'CLOSE' | translate}}</button>
      </mat-card-content>
    </form>
  </mat-card>
</div>
