import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FloSnackbarComponent} from "../snackbar/floSnackbar.component";
import { ContextIdDto } from "@savvy/menu";
import {Webhook, WebhookService} from "@savvy/webhook";

@Component({
  selector: 'app-create-webhook',
  templateUrl: './createWebhook.component.html'
})
export class CreateWebhookComponent implements OnInit, OnDestroy {

  contextIdDto: ContextIdDto;

  webhook: Webhook;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private webhookService: WebhookService,
    private notify: FloSnackbarComponent,
    public dialogRef: MatDialogRef<CreateWebhookComponent>) {
    this.contextIdDto = this.data.contextIdDto;
  }


  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.webhook = <Webhook>{};
    this.webhook.ownerId = this.contextIdDto.contextId;
    this.webhook.enabled = true;
  }

  save() {
    this.webhookService.create(this.webhook).subscribe(response => {
      this.notify.message = 'Created Webhook';
      this.notify.open();
      this.dialogRef.close(response);
    })
  }
}
