import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from '@savvy/subscription';
import { ContextIdDto } from '@savvy/view-definition';
import { SubscriptionDefinition } from '@savvy/subscription';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CookiesService } from '../../../services/cookies.service';

@Component({
  selector: 'app-change-card',
  templateUrl: './change-card.component.html',
  styleUrls: ['./change-card.component.scss'],
})
export class ChangeCardComponent implements OnInit {
  @Output() cardChanged = new EventEmitter();

  @Input() contextIdDto: ContextIdDto;
  @Input() subscriptionId: any;
  @Input() selectedSubscriptionDef: SubscriptionDefinition;
  @Input() annual: boolean;
  @Input() currencyCode: string;
  @Input() price: number = 0;
  @Input() subscription: Subscription;
  @Input() countryCode: string;
  @Input() returnUrl: string;


  showSpinner = false;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#999999'
        }
      }
    }
  };

  elementsOptions: StripeElementsOptions = {
    // locale: 'en',
    locale: (<'auto'>this.translateService.currentLang)
  };


  // stripeTest: UntypedFormGroup;
  stripeCardValid = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<ChangeCardComponent>,
    private translateService: TranslateService,
    private cookiesService: CookiesService,
  ) {

    this.contextIdDto = data.contextIdDto;
    this.subscriptionId = data.subscriptionId;
    this.selectedSubscriptionDef = data.selectedSubscriptionDef;
    this.annual = data.annual;
    this.currencyCode = data.currencyCode;
    this.price = data.price;
    this.subscription = data.subscription;
    this.countryCode = data.countryCode;
    this.returnUrl = data.returnUrl;
  }

  ngOnInit() {
    console.log('init ChangeCardComponent');

    console.log(this.price);
  }

  getAnnualLabel() {
    if (this.annual) {
      return 'an Annual ';
    } else {
      return 'a Monthly ';
    }
  }


  onChange({ type, event }) {
    if (type === 'change') {
      this.stripeCardValid = event.complete;
    }
  }

  skipVerification() {
    this.cookiesService.setCookie('skipCardVerification', true, 1);
    this.dialogRef.close();
  }

  // validForm() {
  //   return this.stripeTest.valid;
  // }
}
