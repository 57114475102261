import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ContextIdDto, Pet } from '@savvy/search';
import { ClearObservable } from 'src/app/flo/shared/classes/clear-observable';
import { FeaturesHelperService } from 'src/app/flo/shared/services/features-helper.service';
import { takeUntil } from "rxjs/operators";
import { Feature } from '@savvy/subscription';

import { AddEditPetVaccinationComponent } from "../../../../shared/components/add-edit-pet-vaccination/add-edit-pet-vaccination.component";
import { MatDialog } from "@angular/material/dialog";
import { VaccinationService } from "@savvy/vaccination";
import { PetVaccinationListComponent } from "../pet-vaccination-list/pet-vaccination-list.component";

@Component({
  selector: 'app-pet-vaccinations',
  templateUrl: './pet-vaccinations.component.html'
})
export class PetVaccinationsComponent extends ClearObservable implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() pet: Pet;

  @ViewChild(PetVaccinationListComponent, { static: false }) petVaccinationsList: PetVaccinationListComponent;

  vaccinations: any[] = [];
  isVaccinationFeatureAllowed = false;

  constructor(
    private featuresHelperService: FeaturesHelperService,
    private vaccinationService: VaccinationService,
    private dialog: MatDialog
  ) {
    super();
  }

  ngOnInit(): void {
    this.isVaccinationFeatureAllowed = this.featuresHelperService.isFeatureAllowed(Feature.TypeEnum.Vaccinations);
  }

  addVaccination() {

    const dialogRef = this.dialog.open(AddEditPetVaccinationComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        petId: this.pet.id,
        customerId: this.pet.petOwnerId
      },
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this.destroy$)).subscribe(result => {
        console.log('back from dialog close');
        if (result && result.id) {

        }
        this.petVaccinationsList.getVaccinationOfPet();
      });
  }





}
