import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { TranslateService } from '@ngx-translate/core';
import { ContextIdDto } from '@savvy/app';
import {
  ActionDefinition,
  ElementDefinitionId,
  EmailMessageTemplateId, EntityDefinitionId
} from '@savvy/entity-definition';
import { FileValue } from "@savvy/entity-instance-composite";
import { AddSuppliedTaskToWorkflowState, Link, RemoveTaskFromState, SendMessageDefinition, SendSmsDefinition, UpdateTaskDefinition, WorkflowDefinition, WorkflowDefinitionSummaryDto, WorkflowState, WorkflowTaskDefinition, WorkflowdefService } from '@savvy/workflow-definition';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AppointmentQuickLinksEditorComponent } from '../../editor/appointment-quick-links-editor/appointment-quick-links-editor.component';
import { BoardingQuickLinksEditorComponent } from "../../editor/boarding-quick-links-editor/boarding-quick-links-editor.component";
import { StudentCourseQuickLinksEditorComponent } from "../../editor/student-course-quick-links-editor/student-course-quick-links-editor.component";
import { StudentTrainingDayQuickLinksEditorComponent } from "../../editor/student-training-day-quick-links-editor/student-training-day-quick-links-editor.component";
import { UploadFilesComponent } from "../../shared/components/upload-files/upload-files.component";
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';

@Component({
  selector: 'app-add-edit-workflow-message',
  templateUrl: './add-edit-workflow-message.component.html',
  styleUrls: ['./add-edit-workflow-message.component.scss']
})
export class AddEditWorkflowMessageComponent implements OnInit {

  @ViewChild(UploadFilesComponent, { static: false }) uploadFiles: UploadFilesComponent;
  @ViewChild(AppointmentQuickLinksEditorComponent, { static: false }) appointmentQuickLinksEditorComponent: AppointmentQuickLinksEditorComponent;
  @ViewChild(StudentCourseQuickLinksEditorComponent, { static: false }) studentCourseQuickLinksEditorComponent: StudentCourseQuickLinksEditorComponent;
  @ViewChild(StudentTrainingDayQuickLinksEditorComponent, { static: false }) studentTrainingDayQuickLinksEditorComponent: StudentTrainingDayQuickLinksEditorComponent;
  @ViewChild(BoardingQuickLinksEditorComponent, { static: false }) boardingQuickLinksEditorComponent: BoardingQuickLinksEditorComponent;

  @Input() contextIdDto: ContextIdDto;
  @Input() workflowState: WorkflowState;
  @Input() workflowDefinitionId: string;
  @Input() workflowDefinitionSummaries: Array<WorkflowDefinitionSummaryDto>;

  @Output() taskAdded = new EventEmitter();
  @Output() taskUpdated = new EventEmitter();
  @Output() taskRemoved = new EventEmitter();

  summaryMap = new Map<string, Array<string>>();
  workflowDef: WorkflowDefinition;

  updateBodyTerm$ = new Subject<string>();

  _taskDef: WorkflowTaskDefinition;

  savedFiles: FileValue[] = [];

  ccEmails: string[] = [];
  bccEmails: string[] = [];
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  ccEmailsCtrl = new FormControl([]);
  bccEmailsCtrl = new FormControl([]);

  constructor(
    private api: WorkflowdefService,
    private notify: FloSnackbarComponent,
    private translateService: TranslateService) {
  }

  @Input() set taskDef(val) {
    this._taskDef = val;

    if (this._taskDef?.actionDefinition?.sendMessageDefinition?.attachments) {
      this.savedFiles = this._taskDef.actionDefinition.sendMessageDefinition.attachments;
    }
  }

  private validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  ngOnInit(): void {
    console.log('_taskDef ', this._taskDef);
    this.initUpdateBody();


    this.api.getWorkflowDef(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      this.workflowDefinitionId).subscribe(response => {
        this.workflowDef = response;
        this.populateCCAndBccEmails();
      });

    if (this.workflowDefinitionSummaries) {
      this.workflowDefinitionSummaries.forEach(summary => {
        this.summaryMap.set(summary.workflowDefinitionId.id, summary.states);
      });
    }
  }

  populateCCAndBccEmails() {
    if (this._taskDef.actionDefinition?.sendMessageDefinition?.ccEmails?.length) {
      this.ccEmails = this._taskDef.actionDefinition?.sendMessageDefinition?.ccEmails;
    }
    if (this._taskDef.actionDefinition?.sendMessageDefinition?.bccEmails?.length) {
      this.bccEmails = this._taskDef.actionDefinition?.sendMessageDefinition?.bccEmails;
    }
  }

  addCC(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      if (this.validateEmail(value)) {
        this.ccEmails.push(value);
      }
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  removeCC(email: string): void {
    const index = this.ccEmails.indexOf(email);

    if (index >= 0) {
      this.ccEmails.splice(index, 1);
    }
  }

  editCC(email: string, event: MatChipInputEvent) {
    const value = event.value.trim();

    // Remove fruit if it no longer has a name
    if (!value) {
      this.removeCC(email);
      return;
    }

    // Edit existing fruit
    const index = this.ccEmails.indexOf(email);
    if (index >= 0) {
      this.ccEmails[index] = value;
    }
  }


  addBCC(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      if (this.validateEmail(value)) {
        this.bccEmails.push(value);
      }
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  removeBCC(email: string): void {
    const index = this.bccEmails.indexOf(email);

    if (index >= 0) {
      this.bccEmails.splice(index, 1);
    }
  }

  editBCC(email: string, event: MatChipInputEvent) {
    const value = event.value.trim();

    // Remove fruit if it no longer has a name
    if (!value) {
      this.removeBCC(email);
      return;
    }

    // Edit existing fruit
    const index = this.bccEmails.indexOf(email);
    if (index >= 0) {
      this.bccEmails[index] = value;
    }
  }

  toEmailMessageTemplateId(id: string): EmailMessageTemplateId {
    const msgId = <EmailMessageTemplateId>{};
    msgId.id = id;
    return msgId;
  }

  actionTypeToggle() {
    console.log('this._taskDef.actionDefinition.actionType ' + this._taskDef.actionDefinition.actionType);
    if (this._taskDef.actionDefinition.actionType === ActionDefinition.ActionTypeEnum.SendMessage) {
      if (!this._taskDef.actionDefinition.sendSmsDefinition) {
        this._taskDef.actionDefinition.sendSmsDefinition = <SendSmsDefinition>{};
      }
      this._taskDef.actionDefinition.actionType = ActionDefinition.ActionTypeEnum.SendSms;
    } else {
      if (!this._taskDef.actionDefinition.sendMessageDefinition) {
        this._taskDef.actionDefinition.sendMessageDefinition = <SendMessageDefinition>{};
      }
      this._taskDef.actionDefinition.actionType = ActionDefinition.ActionTypeEnum.SendMessage;
    }
    // this.update();
  }

  getWorkflowStates(): Array<string> {
    return this.summaryMap.get(this.workflowDefinitionId);
  }

  isSendMessage(): boolean {
    return this._taskDef && this._taskDef.actionDefinition &&
      this._taskDef.actionDefinition.actionType === ActionDefinition.ActionTypeEnum.SendMessage;
  }

  isSendSms(): boolean {
    return this._taskDef && this._taskDef.actionDefinition &&
      this._taskDef.actionDefinition.actionType === ActionDefinition.ActionTypeEnum.SendSms;
  }

  compareEntityDefId(c1: EntityDefinitionId, c2: EntityDefinitionId): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  compareElementDefId(c1: ElementDefinitionId, c2: ElementDefinitionId): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }



  createTask() {
    console.log('calling create task');
    if (!this.workflowState) {
      console.log('ERROR, we have no workflowState!!');
      return;
    }
    if (this._taskDef.actionDefinition && !this._taskDef.actionDefinition.actionType) {
      console.log('ERROR, got action def but no action type');
      return;
    }
    if (!this._taskDef.taskType) {
      this._taskDef.taskType = WorkflowTaskDefinition.TaskTypeEnum.ManualTask;
    }
    if (this._taskDef.actionDefinition?.sendMessageDefinition && this._taskDef.actionDefinition.actionType === 'SEND_MESSAGE') {
      if (this.studentCourseQuickLinksEditorComponent) {
        this._taskDef.actionDefinition.sendMessageDefinition.body = this.studentCourseQuickLinksEditorComponent.messageEditor.text;
        this._taskDef.actionDefinition.sendMessageDefinition.links = {
          links: this.studentCourseQuickLinksEditorComponent.getLinksFromQuicklinksData() as Link[]
        };
      } else if (this.studentTrainingDayQuickLinksEditorComponent) {
        this._taskDef.actionDefinition.sendMessageDefinition.body = this.studentTrainingDayQuickLinksEditorComponent.messageEditor.text;
        this._taskDef.actionDefinition.sendMessageDefinition.links = {
          links: this.studentTrainingDayQuickLinksEditorComponent.getLinksFromQuicklinksData() as Link[]
        };
      } else if (this.boardingQuickLinksEditorComponent) {
        this._taskDef.actionDefinition.sendMessageDefinition.body = this.boardingQuickLinksEditorComponent.messageEditor.text;
        this._taskDef.actionDefinition.sendMessageDefinition.links = {
          links: this.boardingQuickLinksEditorComponent.getLinksFromQuicklinksData() as Link[]
        };
      } else {
        this._taskDef.actionDefinition.sendMessageDefinition.body = this.appointmentQuickLinksEditorComponent.messageEditor.text;
        this._taskDef.actionDefinition.sendMessageDefinition.links = {
          links: this.appointmentQuickLinksEditorComponent.getLinksFromQuicklinksData() as Link[]
        };
      }

      if (this.uploadFiles && this.uploadFiles.savedFiles) {
        this._taskDef.actionDefinition.sendMessageDefinition.attachments = this.uploadFiles.savedFiles
          .map(savedFile => savedFile);
      } else {
        this._taskDef.actionDefinition.sendMessageDefinition.attachments = [];
      }

    }
    const req = <AddSuppliedTaskToWorkflowState>{};
    req.contextIdDto = this.contextIdDto;
    req.stateId = this.workflowState.id;
    req.workflowTaskDefinition = this._taskDef;
    req.workflowDefinitionId = this.workflowDefinitionId;
    this.api.addSuppliedTaskToState(req).subscribe(response => {
      this.translateService.get('Task added').subscribe(value => {
        this.notify.message = value;
        this.notify.open();
      });
      this._taskDef = response.newTaskDefinition;
      this.taskAdded.emit('taskAdded');
      /*this.router.navigate(['/workflow/addEditWorkflowState',
        this.workflowDefinitionId,
        this.workflowState.id,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
      ]);*/
    });
  }

  update() {
    if (this._taskDef.id) {
      console.log('updating task');
      if (this._taskDef.postTaskDefinition) {
        console.log('post task def is here:' + this._taskDef.postTaskDefinition.transitionTo);
      } else {
        console.log('post task def is empty');
      }
      if (!this._taskDef.taskType) {
        this._taskDef.taskType = WorkflowTaskDefinition.TaskTypeEnum.ManualTask;
      }
      if (this._taskDef.actionDefinition?.sendMessageDefinition && this._taskDef.actionDefinition.actionType === 'SEND_MESSAGE') {
        if (this.studentCourseQuickLinksEditorComponent) {
          this._taskDef.actionDefinition.sendMessageDefinition.body = this.studentCourseQuickLinksEditorComponent.messageEditor.text;
          this._taskDef.actionDefinition.sendMessageDefinition.links = {
            links: this.studentCourseQuickLinksEditorComponent.getLinksFromQuicklinksData() as Link[]
          };
        } else if (this.studentTrainingDayQuickLinksEditorComponent) {
          this._taskDef.actionDefinition.sendMessageDefinition.body = this.studentTrainingDayQuickLinksEditorComponent.messageEditor.text;
          this._taskDef.actionDefinition.sendMessageDefinition.links = {
            links: this.studentTrainingDayQuickLinksEditorComponent.getLinksFromQuicklinksData() as Link[]
          };
        } else if (this.boardingQuickLinksEditorComponent) {
          this._taskDef.actionDefinition.sendMessageDefinition.body = this.boardingQuickLinksEditorComponent.messageEditor.text;
          this._taskDef.actionDefinition.sendMessageDefinition.links = {
            links: this.boardingQuickLinksEditorComponent.getLinksFromQuicklinksData() as Link[]
          };
        } else {
          this._taskDef.actionDefinition.sendMessageDefinition.body = this.appointmentQuickLinksEditorComponent.messageEditor.text;
          this._taskDef.actionDefinition.sendMessageDefinition.links = {
            links: this.appointmentQuickLinksEditorComponent.getLinksFromQuicklinksData() as Link[]
          };
        }
        if (this.uploadFiles && this.uploadFiles.savedFiles) {
          this._taskDef.actionDefinition.sendMessageDefinition.attachments = this.uploadFiles.savedFiles
            .map(savedFile => savedFile);
        } else {
          this._taskDef.actionDefinition.sendMessageDefinition.attachments = [];
        }

        if (this.ccEmails?.length) {
          this._taskDef.actionDefinition.sendMessageDefinition.ccEmails = this.ccEmails;
        }

        if (this.bccEmails?.length) {
          this._taskDef.actionDefinition.sendMessageDefinition.bccEmails = this.bccEmails;
        }

      } else if (this._taskDef.actionDefinition?.sendSmsDefinition && this._taskDef.actionDefinition.actionType === 'SEND_SMS') {
        if (this.studentCourseQuickLinksEditorComponent) {
          this._taskDef.actionDefinition.sendSmsDefinition.message = this.studentCourseQuickLinksEditorComponent.messageEditor.text;
          this._taskDef.actionDefinition.sendSmsDefinition.links = {
            links: this.studentCourseQuickLinksEditorComponent.getLinksFromQuicklinksData() as Link[]
          };
        } else if (this.studentTrainingDayQuickLinksEditorComponent) {
          this._taskDef.actionDefinition.sendSmsDefinition.message = this.studentTrainingDayQuickLinksEditorComponent.messageEditor.text;
          this._taskDef.actionDefinition.sendSmsDefinition.links = {
            links: this.studentTrainingDayQuickLinksEditorComponent.getLinksFromQuicklinksData() as Link[]
          };
        } else if (this.boardingQuickLinksEditorComponent) {
          this._taskDef.actionDefinition.sendSmsDefinition.message = this.boardingQuickLinksEditorComponent.messageEditor.text;
          this._taskDef.actionDefinition.sendSmsDefinition.links = {
            links: this.boardingQuickLinksEditorComponent.getLinksFromQuicklinksData() as Link[]
          };
        } else {
          this._taskDef.actionDefinition.sendSmsDefinition.message = this.appointmentQuickLinksEditorComponent.messageEditor.text;
          this._taskDef.actionDefinition.sendSmsDefinition.links = {
            links: this.appointmentQuickLinksEditorComponent.getLinksFromQuicklinksData() as Link[]
          };
        }
      }
      const req = <UpdateTaskDefinition>{};
      req.contextIdDto = this.contextIdDto;
      req.workflowTaskDefinition = this._taskDef;
      req.stateId = this.workflowState.id;
      req.workflowDefinitionId = this.workflowDefinitionId;

      this.api.updateTaskDef(req).subscribe(response => {
        this._taskDef = response;

        this.translateService.get('Updated task').subscribe(value => {
          this.notify.message = value;
          this.notify.open();
        });
        this.taskUpdated.emit('updatedTask');
      });
    } else {
      console.log('not updating task as it has not been crated yet');
    }
  }

  deleteTask() {
    const req = <RemoveTaskFromState>{};
    req.contextIdDto = this.contextIdDto;
    req.taskDefinitionId = this._taskDef.taskDefinitionId.id;
    req.state = this.workflowState.state;
    req.workflowDefinitionId = this.workflowDefinitionId;

    this.api.removeTaskFromState(req).subscribe(response => {
      this.translateService.get('Removed task').subscribe(value => {
        this.notify.message = value;
        this.notify.open();
      });
      this.taskRemoved.emit('removedTask');
    });
  }

  private initUpdateBody() {
    this.updateBodyTerm$
      .pipe(debounceTime(1000))
      .pipe(distinctUntilChanged())
      .subscribe(text => {
        console.log('body updated!');
        this._taskDef.actionDefinition.sendMessageDefinition.body = text;
        this.update();
      });
  }
}
