import { Component, Input, OnInit } from '@angular/core';
import { EventBusService } from '../element/EventBusService';
import { MessageService, ContextIdDto } from '@savvy/messaging';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { QuestionnaireCompositeService } from '@savvy/questionnaire';
import { LinkedInstanceFactory } from '../element/linkedInstanceFactory';
import { QuestionnaireDefsService } from '@savvy/questionnaire';
import { EventType } from '../event/UiEvent';
import { TranslateService } from '@ngx-translate/core';
import { QuestionnaireDefinitionId } from '@savvy/view-composite';
import { QuestionnaireDefinition } from '@savvy/questionnaire';
import {CustomerService} from "@savvy/customer";

@Component({
  selector: 'app-send-questionnaire',
  templateUrl: './sendQuestionnaire.component.html'
})
export class SendQuestionnaireComponent implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() visible: boolean;
  @Input() viewContextDto: any;
  @Input() eventBus: EventBusService;

  body: string;
  subject: string;
  questionnaireDefinitionId: QuestionnaireDefinitionId;
  showSpinner = false;
  recipients: string;
  allowedToSend = false;
  questionnaireDefs: Array<QuestionnaireDefinition>;

  public form: UntypedFormGroup; // our form model

  constructor(
    private api: MessageService,
    private customerService: CustomerService,
    private defApi: QuestionnaireDefsService,
    private questionnaireApi: QuestionnaireCompositeService,
    private router: Router,
    private translateService: TranslateService,
    private notify: FloSnackbarComponent) {


  }

  ngOnInit() {
    this.getQuestionnaireTemplates();
    this.loadRecipient();
  }
  bodyUpdated(text: string) {
    this.body = text;
  }

  loadRecipient() {
    if (this.viewContextDto && this.viewContextDto.customerContextDto?.customerId?.id) {
      let customerId = this.viewContextDto.customerContextDto?.customerId?.id;

      this.customerService.loadCustomer(customerId, this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(response => {
        if (response.optIn) {
          this.allowedToSend = response.optIn.allowEmail;
        }
        this.recipients = response.username;
      });
    } else {
    }
  }

  getQuestionnaireTemplates() {
    // contactForm
    this.defApi.loadQuestionnaireDefs(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType).subscribe(
        questionnaireDefs => {
          this.questionnaireDefs = questionnaireDefs;
        });
  }

  displayFn(tuple): string {
    if (tuple) {
      return tuple.name;
    } else {
      return '';
    }
  }

  cancelIt() {
    this.eventBus.addEvent(EventType.CANCEL_SEND_MESSAGE);
  }

  gotoTemplates() {
    this.router.navigate(['/questionnaires/questionnaires',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType]);
  }
  send() {
    this.showSpinner = true;
    const req = <any>{};
    req.subject = this.subject;
    req.body = this.body;
    req.recipientEmailAddresses = this.recipients.split(',');
    req.questionnaireDefinitionId = this.questionnaireDefinitionId;
    req.linkedInstanceDto = LinkedInstanceFactory.createLinkedInstance(this.viewContextDto);
    req.contextIdDto = this.contextIdDto;

    // this.questionnaireApi.sendQuestionnaire(req).subscribe(response => {
    //   this.showSpinner = false;
    //   this.translateService.get('Successfully sent questionnaire').subscribe(value => {
    //     this.notify.message = value;
    //     this.notify.open();
    //   });
    //   this.eventBus.addEvent(EventType.SENT_QUESTIONNAIRE);
    // });
  }
}
