import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ContextIdDto, OrdersSummaryDto, SummaryItemDto } from '@savvy/view-definition';

@Component({
  selector: 'app-orders-summary-widget',
  templateUrl: 'ordersSummaryWidget.component.html'
})
export class OrdersSummaryWidgetComponent {

  @Input() ordersSummaryDto: OrdersSummaryDto;
  @Input() contextIdDto: ContextIdDto;

  constructor(private router: Router) {
  }

  go(summaryItemDto: SummaryItemDto) {
    console.log('going to ' + summaryItemDto.itemId);
    this.router.navigate(['/order/viewOrder',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      summaryItemDto.itemId
    ]);
  }
}
