<mat-toolbar color="primary" *ngIf="visible">{{'VIEW_MESSAGE' | translate}}</mat-toolbar>

<mat-card appearance="outlined" *ngIf="visible">
    <mat-card-content>
        <div class="chat-conversation-user them" *ngIf="message">
            <div class="chat-conversation-message">
              <p *ngIf="message.emailMessage">
                <app-zero-image name="email"></app-zero-image>
                <span [innerHtml]="message.emailMessage.body"></span>
                <span class="chat-date">{{ message.sentLocalDateTime | localizedDate: 'fullDate' }}</span>
              </p>
              <p *ngIf="message.smsMessage">
                <app-zero-image name="sms"></app-zero-image>
                <span [innerHtml]="message.smsMessage.textMessage"></span>
                <span>{{message.smsMessage.deliveryStatus}}</span>
                <span class="chat-date">{{ message.sentLocalDateTime | localizedDate: 'fullDate' }}</span>
              </p>
              <p *ngIf="message.whatsAppMessage">
                <app-zero-image name="email"></app-zero-image>
                <span [innerHtml]="message.whatsAppMessage.whatsAppMessage"></span>
                <span class="chat-date">{{ message.sentLocalDateTime | localizedDate: 'fullDate' }}</span>
              </p>
            </div>
        </div>
    </mat-card-content>
</mat-card>
