import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ContextIdDto, MenucompService } from '@savvy/menu';
import { MenuStructure } from '@savvy/ui';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { GLOBAL } from '../../app.constants';
import { APP_TYPE_ENUM, ContextService } from '../context.service';
import { LayoutBusService } from '../element/LayoutBusService';
import { AddOnsHelperService } from '../shared/services/add-ons-helper.service';
import { SmsCreditStateService } from "../shared/services/sms-credit-state.service";
import { SmsCreditService } from '../shared/services/sms-credit.service';
import { SubscriptionHelperService } from '../shared/services/subscription-helper.service';
import { LocalMenuService, Menu } from './local-menu.service';
import { MenuFactory } from './menu.factory';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {

  @Input() adminMenu: boolean;
  @Input() customerMenu: boolean;
  @Output() menuChanged: EventEmitter<Menu> = new EventEmitter();
  @Output() closeSidebar: EventEmitter<boolean> = new EventEmitter();
  menuStructure: MenuStructure;
  contextIdDto: ContextIdDto;
  subscriptions: Subscription[] = [];

  smsCreditLow: boolean = false;

  constructor(
    private menuApi: MenucompService,
    public localMenuService: LocalMenuService,
    private menuFactory: MenuFactory,
    private contextService: ContextService,
    private layoutBusService: LayoutBusService,
    private router: Router,
    private deviceService: DeviceDetectorService,
    public smsCreditStateService: SmsCreditStateService,
    private addOnsHelperService: AddOnsHelperService,
    public smsCreditService: SmsCreditService,
    // private smsSettingsCompService: SmsSettingsCompService,
    public subscriptionHelperService: SubscriptionHelperService

  ) {

    // console.log('inside constructor of menu items');
  }

  ngOnInit() {
    if (this.deviceService.isMobile()) {
      GLOBAL.appsMenu = false;
    } else {
      GLOBAL.appsMenu = true;
    }
    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
        this.loadNavData();
        this.subscriptions.push(this.layoutBusService.menuChanged$.subscribe(async (menuType) => {
          if (this.contextService && this.contextIdDto
            && GLOBAL.appsMenu && GLOBAL.adminMenu && menuType) {
            const selectFirstForcefully = sessionStorage.getItem('selectFirstForcefully');
            if (menuType.menuDefinitions && menuType.menuDefinitions.length) {
              this.localMenuService.clear();
              this.handleResponse(menuType);
              if (selectFirstForcefully === 'true' && this.localMenuService?.menuItems?.length) {
                this.goto(this.localMenuService.menuItems[0]);
              }
            } else {
              const menuDefinition = this.menuFactory.getMenuDefinition(menuType, this.contextIdDto);
              this.localMenuService.clear();
              console.log('admin menus are');
              console.log(menuDefinition);
              if (menuDefinition) {
                this.localMenuService.add(menuDefinition);
              }
              if (selectFirstForcefully === 'true' && this.localMenuService?.menuItems?.length) {
                this.goto(this.localMenuService.menuItems[0]);
              }
            }
            // if (this.localMenuService.menuItems.length === 1) {
            //   this.layoutBusService.toggleMenu$.emit(false);
            // } else {
            //   // this.layoutBusService.toggleMenu$.emit(true);
            // }
          }
        }));

        this.contextService.appType$.subscribe(res => {
          if (res === APP_TYPE_ENUM.PET_GROOMING_SALON
            && !this.addOnsHelperService.addOnPresent('Day Care')) {
            this.localMenuService.menuItems = this.localMenuService.menuItems.filter(sm => sm.name !== 'Invoicing');
          }
        });
      }
    }));
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  setMenuItemSvg(menuItem: Menu) {
    GLOBAL.selectedMenuIconSvgXml = menuItem.svgXml;
    sessionStorage.setItem('selectedMenuIconSvgXml', menuItem.svgXml);
    sessionStorage.setItem('selectedMenuItemType', menuItem.itemType);
    sessionStorage.setItem('isAdminMenu', 'false');
    this.menuChanged.emit(menuItem);
  }

  goto(menuItem: Menu) {
    this.menuChanged.emit(menuItem);
    console.log('navigating');
    GLOBAL.selectedMenuIconSvgXml = menuItem.svgXml;
    if (menuItem.params) {
      if (menuItem.params[0] && menuItem.params[1] && menuItem.params[2] && menuItem.params[3] && menuItem.params[4]) {
        this.router.navigate(['/', menuItem.params[0], menuItem.params[1], menuItem.params[2], menuItem.params[3], menuItem.params[4]]);
      } else if (menuItem.params[0] && menuItem.params[1] && menuItem.params[2] && menuItem.params[3]) {
        this.router.navigate(['/', menuItem.params[0], menuItem.params[1], menuItem.params[2], menuItem.params[3]]);
      } else if (menuItem.params[0] && menuItem.params[1] && menuItem.params[2]) {
        this.router.navigate(['/', menuItem.params[0], menuItem.params[1], menuItem.params[2]]);
      } else if (menuItem.params[0] && menuItem.params[1]) {
        this.router.navigate(['/', menuItem.params[0], menuItem.params[1]]);
      } else if (menuItem.params[0]) {
        this.router.navigate(['/', menuItem.params[0]]);
      } else {
        this.router.navigate(['/']);
      }
    }
  }

  loadNavData() {
    if (this.contextService && this.contextIdDto) {
      if (this.adminMenu) {
        this.subscriptions.push(this.menuApi.loadAdminMenuComp(
          this.contextIdDto.contextId,
          this.contextIdDto.contextIdType).subscribe(menuStructure => {
            this.handleResponse(menuStructure);
          }));
      } else {
        if (this.customerMenu) {
          console.log('loading customer menu');
          this.subscriptions.push(this.menuApi.loadCustomerMenuComp(
            this.contextIdDto.contextId,
            this.contextIdDto.contextIdType).subscribe(menuStructure => {
              this.handleResponse(menuStructure);
            }));
        } else {
          if (this.localMenuService.menuStructure) {
            this.handleResponse(this.localMenuService.menuStructure);
          } else {
            console.log('loading standard menu as menu local service has', this.localMenuService.menuStructure);
            /*this.sub = this.menuApi.loadStandardMenuComp(
              this.contextIdDto.contextId,
              this.contextIdDto.contextIdType).subscribe(menuStructure => {
                this.handleResponse(menuStructure);
              });*/
          }
        }
      }
    }
  }

  handleResponse(menuStructure) {
    if (menuStructure) {
      console.log('got menu structure ', menuStructure);
      this.menuStructure = menuStructure;
      this.localMenuService.clear();
      if (!this.localMenuService.menuInitiated) {
        this.localMenuService.initMenu(menuStructure, this.contextIdDto);
      }
    } else {
      console.log('no menu structure ' + menuStructure);
    }
  }

  // loadSubscriptionStatus() {
  //   console.log('Subscription status');
  //   this.subscriptionApi.subscriptionTrialStatus(this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(res => {
  //     this.subscriptionStatusDto = res;
  //     this.smsCreditLow = this.smsCreditStateService.isSmsCreditLow(this.subscriptionHelperService.isFreemium);
  //   });
  // }



  goToSubscriptions() {
    this.router.navigate(['/subscription/setSubscription',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType]);
  }

  goToImportRequest() {
    sessionStorage.setItem('selectedMenuItemType', 'IMPORT_EXPORT');
    this.closeSidebar.emit(true);
    this.router.navigate(['/admin/settings',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType, 'import-export']);
  }
}
