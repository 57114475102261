import { Component, Inject, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClearObservable } from '../../shared/classes/clear-observable';
import { takeUntil } from 'rxjs/operators';
import { PhoneNumberHelperService } from '../../shared/services/phone-number-helper.service';
import { CustomerSalesTotalsDto, InvoiceReportsService, LoadCustomerSalesResponse } from '@savvy/invoice-report';
import { ContextIdDto } from '@savvy/view-definition';
import { UserCurrencyService } from '../../shared/services/userCurrency.service';

@Component({
  selector: 'app-user-history-modal',
  templateUrl: './user-history-modal.component.html',
  styleUrls: ['./user-history-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserHistoryModalComponent extends ClearObservable implements OnInit {
  contextIdDto: ContextIdDto;
  customerId: string;
  customerSalesTotalsDto: CustomerSalesTotalsDto;
  currencyCode: string;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data,
    private api: InvoiceReportsService,
    private sharedService: PhoneNumberHelperService,
    private userCurrencyService: UserCurrencyService,
    public dialogRef: MatDialogRef<UserHistoryModalComponent>) {
    super();
  }

  ngOnInit() {
    this.customerId = this.data.customerId;
    this.contextIdDto = this.data.contextIdDto;
    this.getCurrencyCode();
    if (this.customerId && this.contextIdDto) {
      console.log('loading user reports');
      this.loadUserReports();
    } else {
      console.log('no customer id so cannot load data');
    }
  }

  getCurrencyCode() {
    this.userCurrencyService.getDefaultCurrency(this.contextIdDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.currencyCode = res.org.currencyCode ? res.org.currencyCode : this.userCurrencyService.defaultCurrency;
      });
  }

  loadUserReports() {
    this.api.loadCustomerSales(this.contextIdDto.contextId, this.contextIdDto.contextIdType, this.customerId)
      .subscribe((res: LoadCustomerSalesResponse) => {
        console.log('res', res);
        this.customerSalesTotalsDto = res.customerSalesTotalsDto;
      });
  }

  /**
   * @RequestMapping(method = RequestMethod.GET, value = "/loadWorkflowStats")
   public LoadWorkflowStatsCompResponse loadWorkflowStats(
   @RequestParam("contextId") String contextIdStr,
   @RequestParam("contextType") String contextType,
   @RequestParam("customerId") String customerId) {
   */

}
