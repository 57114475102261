<mat-toolbar color="primary" *ngIf="_taskDef">
  <mat-toolbar-row *ngIf="!_taskDef.id">
    <span>{{'CREATE_TASK' | translate}}</span>
  </mat-toolbar-row>
  <mat-toolbar-row *ngIf="_taskDef.id">
    <span>{{'EDIT_TASK' | translate}}</span>
    <div [class.example-spacer]="true"></div>
    <button (click)="deleteTask()" class="ml-xs mat-icon-button" mat-icon-button="">
                <span class="mat-button-wrapper">
                    <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">delete</mat-icon>
                </span>
      <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
      <div class="mat-button-focus-overlay"></div>
    </button>
  </mat-toolbar-row>
</mat-toolbar>
<mat-card appearance="outlined" *ngIf="_taskDef">
  <mat-card-content>
    <div fxLayout="column" fxLayoutAlign="space-around">
      <mat-form-field style="width: 100%" appearance="outline">
        <mat-label>{{'TASK_NAME' | translate}}</mat-label>
        <input
          matInput
          [(ngModel)]="_taskDef.taskSettings.taskName"
          #taskName="ngModel"
          class="example-full-width"
          (change)="update()"
          name="taskName"
          placeholder="{{'TASK_NAME' | translate}}" required>
        <mat-error *ngIf="taskName.hasError('required')">
          {{'TASK_NAME_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{'TYPE_OF_TASK' | translate}}</mat-label>
        <mat-select
          [(ngModel)]="_taskDef.taskType"
          name="actionType" #actionType="ngModel"
          (change)="update()"
          placeholder="{{'TASK_TYPE' | translate}}">

          <mat-option [value]="manualTask">{{'MANUAL_TASK' | translate}}
          </mat-option>
          <mat-option
            [value]="sendMessageTask">{{'SEND_A_MESSAGE' | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!--
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>Task Description</mat-label>
              <input
                matInput
                [(ngModel)]="_taskDef.taskSettings.taskDescription"
                #fieldName="ngModel"
                class="example-full-width"
                (change)="update()"
                name="taskDescription"
                placeholder="Task Description">
            </mat-form-field>
            -->

      <mat-form-field appearance="outline">
        <mat-label>{{'ROLE_ASSIGNED_TO' | translate}}</mat-label>
        <mat-select
          [(ngModel)]="_taskDef.taskSettings.taskAssignToRoles.id"
          name="roleAssignedTask" #name="ngModel"
          (change)="update()"
          placeholder="{{'TASK_ASSIGNEE' | translate}}"
          id="role_assigned_{{_taskDef.taskSettings.taskAssignToRoles.id}}">

          <mat-option *ngFor="let role of roles"
                      [value]="role.id">{{role.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div fxLayout="row" *ngIf="isSendMessage()">
        <mat-checkbox
          [(ngModel)]="_taskDef.autoTask"
          (change)="autoTaskChanged()"
          #autoTask="ngModel"
          name="autoTask">
          {{'AUTO' | translate}}?
        </mat-checkbox>
      </div>
      <br>

      <mat-accordion>
        <!-- SMS details panel -->
        <mat-expansion-panel *ngIf="isSendSms()">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{'SMS_MESSAGE' | translate}}
            </mat-panel-title>
            <mat-panel-description>
              {{'SPECIFY_MESSAGE_HERE' | translate}}
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div>
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>{{'SMS_TEXT' | translate}}</mat-label>
              <input
                matInput
                [(ngModel)]="_taskDef.actionDefinition.sendSmsDefinition.message"
                (change)="update()"
                #smsText="ngModel"
                name="subject"
                placeholder="{{'SMS_TEXT' | translate}}">
            </mat-form-field>
          </div>

        </mat-expansion-panel>

        <!-- Message details panel -->
        <mat-expansion-panel *ngIf="isSendMessage()">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{'EMAIL_MESSAGE' | translate}}
            </mat-panel-title>
            <mat-panel-description>
              {{'SELECT_TEMPLATE' | translate}}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div fxLayout="row">
            <mat-checkbox [(ngModel)]="_taskDef.actionDefinition.sendMessageDefinition.useTemplate">
              {{'EMAIL_TEMPLATE' | translate}}
            </mat-checkbox>
          </div>
          <br>
          <div *ngIf="_taskDef.actionDefinition.sendMessageDefinition.useTemplate">

            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>{{'EMAIL_TEMPLATE' | translate}}</mat-label>
              <mat-select
                [(ngModel)]="_taskDef.actionDefinition.sendMessageDefinition.emailMessageTemplateId"
                name="emailTemplate" #emailTemplate="ngModel"
                (change)="update()"
                placeholder="{{'TEMPLATE' | translate}}">

                <mat-option *ngFor="let item of emailMessageTemplates"
                            [value]="toEmailMessageTemplateId(item.id)">{{item.name}}
                </mat-option>

              </mat-select>
            </mat-form-field>

          </div>

         <!-- label is [{{ _taskDef.actionDefinition.label }}] -->

          <div *ngIf="!_taskDef.actionDefinition.sendMessageDefinition.useTemplate">
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>{{'SUBJECT' | translate}}</mat-label>
              <input
                matInput
                [(ngModel)]="_taskDef.actionDefinition.sendMessageDefinition.subject"
                (change)="update()"
                #fieldName="ngModel"
                name="subject"
                placeholder="{{'SUBJECT' | translate}}">
            </mat-form-field>

            <app-flo-editor (textUpdated)="updateBodyTerm$.next($event)"
                            [variables]="variables"
                            [text]="_taskDef.actionDefinition.sendMessageDefinition.body"></app-flo-editor>
          </div>

        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="_taskDef.autoTask">

          <mat-expansion-panel-header>
            <mat-panel-title>
              {{'AUTO_TASK' | translate}}
            </mat-panel-title>
            <mat-panel-description>
              {{'WHEN_TASK_EXECUTED' | translate}}
            </mat-panel-description>
          </mat-expansion-panel-header>

          <app-add-edit-schedule-def *ngIf="workflowDef && workflowDef.workflowDefinitionTypeData"
                                     [workflowDef]="workflowDef"
                                     [scheduleDef]="workflowTaskDefinition.autoTaskDefinition.workflowScheduleDefinition"
                                     [contextIdDto]="contextIdDto"></app-add-edit-schedule-def>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="!_taskDef.autoTask">

          <mat-expansion-panel-header>
            <mat-panel-title>
              {{'TASK_DUE_DATE' | translate}}
            </mat-panel-title>
            <mat-panel-description>
              {{'WHEN_TASK_DUE' | translate}}
            </mat-panel-description>
          </mat-expansion-panel-header>

          <!--
                    <div *ngIf="workflowDef && workflowDef.workflowDefinitionTypeData">
                      workflowDef.workflowDefinitionTypeData is there
                    </div>
                    <div *ngIf="_taskDef && _taskDef.manualTaskDefinition">
                      _taskDef.manualTaskDefinition is there
                      <div *ngIf="_taskDef.manualTaskDefinition.dueDateSchedule">
                        dueDateSchedule is there
                      </div>
                    </div>
                    -->

          <app-add-edit-schedule-def *ngIf="workflowDef && workflowDef.workflowDefinitionTypeData
                                                        && _taskDef.manualTaskDefinition
                                                        && _taskDef.manualTaskDefinition.dueDateSchedule"
                                     [scheduleDef]="workflowTaskDefinition.autoTaskDefinition.workflowScheduleDefinition"
                                     [workflowDef]="workflowDef"
                                     [contextIdDto]="contextIdDto">

          </app-add-edit-schedule-def>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{'AFTER_TASK' | translate}}
            </mat-panel-title>
            <mat-panel-description>
              {{'WHAT_HAPPENS_WHEN_TASK_COMPLETED' | translate}}
            </mat-panel-description>
          </mat-expansion-panel-header>

          <mat-form-field appearance="outline" style="width: 100%" *ngIf="workflowDef">
            <mat-label>
              {{'MOVE_WORKFLOW' | translate}}
            </mat-label>
            <mat-select
              [(ngModel)]="_taskDef.postTaskDefinition.transitionTo"
              name="transitionTo" #transitionTo="ngModel"
              (selectionChange)="update()"
              placeholder="{{'SELECT_WORKFLOW_STATE'| translate}}">

              <mat-option *ngFor="let workflowState of workflowDef.workflowStateList"
                          [value]="workflowState.state">{{workflowState.state | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <br>

  </mat-card-content>
  <mat-card-content>
    <button mat-raised-button color="accent" *ngIf="!_taskDef.id"
            (click)="createTask()">{{'CREATE' | translate}}</button>
  </mat-card-content>


</mat-card>

