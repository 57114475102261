import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import {LinkDetails, LinkSelectedEvent} from '../../templateLinkEvents';

@Component({
  selector: 'app-quick-list-view',
  templateUrl: './quick-list-view.component.html',
  styleUrls: ['./quick-list-view.component.scss']
})
export class QuickListViewComponent implements OnInit, OnDestroy {

  @Output() linkSelected = new EventEmitter<LinkSelectedEvent>();

  @Input() set linkDetails(linkDetails: LinkDetails[]) {
    this._linkDetails = linkDetails;
    if (this.searchLinkCtrl) {
      this.searchValue(this.searchLinkCtrl.value);
    }
  }

  private _unsubscribeAll: Subject<any>;

  filteredLinks: LinkDetails[];
  _linkDetails: LinkDetails[];


  searchLinkCtrl = new UntypedFormControl();

  constructor() { }

  ngOnInit(): void {
    this._unsubscribeAll = new Subject();

    this.filteredLinks = this._linkDetails;
    this.searchLinkCtrl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this._unsubscribeAll))
      .subscribe((value) => {
        this.searchValue(value);
      });
  }

  searchValue(value: string) {
    if (!value) {
      this.filteredLinks = this._linkDetails;
      return;
    }
    const filterValue = value.toLowerCase();
    this.filteredLinks = this._linkDetails.filter(variable => variable.label.toLowerCase().indexOf(filterValue) >= 0);
  }

  ngOnDestroy() {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  onAddItemClick(ld: LinkSelectedEvent) {
    if (!this.linkSelected) {
      return;
    }
    this.linkSelected.emit(ld);
  }

}
