import { Component, Input } from '@angular/core';
import { FlagPanelDataDto } from '@savvy/view-definition';
import { ContextIdDto } from '@savvy/quickbooks';

@Component({
  selector: 'app-view-flags-panel',
  templateUrl: 'viewFlagsPanel.component.html',
  styleUrls: ['commonPanel.component.scss']
})
export class ViewFlagsPanelComponent {

  @Input() flagPanelDataDto: FlagPanelDataDto;
  @Input() contextIdDto: ContextIdDto;
}

