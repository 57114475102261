<mat-vertical-stepper [linear]="false" #stepper>
  <mat-step [stepControl]="firstFormGroup">
    <form [formGroup]="firstFormGroup">
      <ng-template matStepLabel>Create customer</ng-template>

      <div fxLayout="column">
        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">

          <div fxFlex="49">
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>{{'Customer First Name' | translate}}</mat-label>
              <input matInput placeholder="{{'' | translate}}" formControlName="customerFirstName">
              <!--<mat-error *ngIf="service.hasError('required')">-->
              <!--{{'Service is' | translate}} <strong>{{'required' | translate}}</strong>-->
              <!--</mat-error>-->
            </mat-form-field>

          </div>
          <div fxFlex="49">


            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>{{'Customer Last Name' | translate}}</mat-label>
              <input matInput placeholder="{{'' | translate}}" formControlName="customerLastName">
              <!--<mat-error *ngIf="service.hasError('required')">-->
              <!--{{'Service is' | translate}} <strong>{{'required' | translate}}</strong>-->
              <!--</mat-error>-->
            </mat-form-field>
          </div>
        </div>

      </div>

      <div fxLayout="column">
        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">

          <div fxFlex="49">

            <app-phone-input name="mobilePhoneNumber" (phoneChanged)="onMobilePhoneChange($event)"
              (countryChanged)="onMobileCountryChange($event)" [selectedCountryCode]="customerPhone?.country" label="Mobile"
              placeholder="Mobile" errorName="Mobile" required="false"></app-phone-input>

          </div>
          <div fxFlex="49">

            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>{{'Email' | translate}}</mat-label>
              <input matInput placeholder="{{'' | translate}}" formControlName="customerEmail">
              <!--<mat-error *ngIf="service.hasError('required')">-->
              <!--{{'Service is' | translate}} <strong>{{'required' | translate}}</strong>-->
              <!--</mat-error>-->
            </mat-form-field>

          </div>
        </div>

      </div>

      <div fxLayout="column">
        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">

          <!-- THIS IS FOR THE PET NAME -->
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{ invoiceDefinition?.serviceRowLinkedEntityLabel }}</mat-label>
            <input matInput placeholder="{{'' | translate}}" formControlName="eiName">
            <!--<mat-error *ngIf="service.hasError('required')">-->
            <!--{{'Service is' | translate}} <strong>{{'required' | translate}}</strong>-->
            <!--</mat-error>-->
          </mat-form-field>
        </div>

        <!--div fxFlex="49">


              <mat-checkbox formControlName="portalInvite" *ngIf="portalEnabled"
                            checked="true">
                {{'Invite to Portal' | translate}}
              </mat-checkbox>
          </div-->
      </div>

      <div *ngFor="let eiExtraField of eiExtraFields">
        <app-html-element [htmlElement]="eiExtraField" [formGroup]="firstFormGroup"></app-html-element>
      </div>


      <button [disabled]="!firstFormGroup.valid" mat-raised-button matStepperNext color="accent"
        (click)="createCustomerAndEi(true)">Create {{ invoiceDefinition?.serviceRowLinkedEntityLabel }} and
        Customer
      </button>
    </form>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Create Appointment</ng-template>


    <br>

    <div fxLayout="column" *ngIf="userDtos && invoiceDefinition && invoiceDefinition.serviceRowLinkedEntity && eiLabel">
      <app-ei-selector-with-flags [contextIdDto]="contextIdDto"
        [entityDefinitionId]="invoiceDefinition.serviceRowLinkedEntity"
        [entityDefinitionLabel]="invoiceDefinition?.serviceRowLinkedEntityLabel"
        [entityInstanceId]="invoice.invoiceServiceRows[0].entityInstanceId" [entityInstanceLabel]="eiLabel">
      </app-ei-selector-with-flags>
    </div>

    <!--
    (eiChanged)="eiChanged(invoice.invoiceServiceRows[0], $event)"
    -->


    <div fxLayout="column" *ngIf="userDtos && invoiceDefinition && invoiceDefinition.enableServiceRowUser">
      <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">
        <div fxFlex="49" *ngIf="consumerServices">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{'Service' | translate}}</mat-label>
            <mat-select placeholder="{{'Select Service' | translate}}"
              [(ngModel)]="invoice.invoiceServiceRows[0].serviceNumber" required (selectionChange)="serviceChanged()"
              name="serviceField" #serviceField="ngModel">

              <mat-option [value]="''">
                <button mat-flat-button color="accent" (click)="createNewService(0)">&nbsp;&nbsp;&nbsp;&nbsp;Add
                  Service
                </button>
              </mat-option>


              <mat-option *ngFor="let service of consumerServices" [value]="service.id">
                {{ service?.serviceName }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="serviceField.hasError('required')">
              {{'Service is' | translate}} <strong>{{'required' | translate}}</strong>
            </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="49">

          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{'Price' | translate}}</mat-label>
            <input matInput type="text" appNumberOnly [decimalPoints]="2" placeholder="Unit Price" name="unitPrice"
              #unitPrice="ngModel" [(ngModel)]="invoice.invoiceServiceRows[0].unitPrice" id="unitPrice" required>
            <mat-error *ngIf="unitPrice.hasError('required')">
              {{'Unit Price is' | translate}} <strong>{{'required' | translate}}</strong>
            </mat-error>
          </mat-form-field>

        </div>
      </div>

      <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">

        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>{{'Staff' | translate}}</mat-label>
          <mat-select placeholder="{{'Select Staff' | translate}}" [compareWith]="compareUserFn"
            [(ngModel)]="invoice.invoiceServiceRows[0].serviceUser" required (selectionChange)="serviceUserChanged()"
            name="serviceUserField" #serviceUserField="ngModel">
            <mat-option *ngFor="let serviceUser of userDtos" [value]="serviceUser.userId">
              {{ serviceUser.fullName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="serviceUserField.hasError('required')">
            {{'Staff is' | translate}} <strong>{{'required' | translate}}</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </div>


    <div fxLayout="column">
      <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">
        <div fxFlex="48">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'Appointment Date' | translate}}</mat-label>
            <input matInput [(ngModel)]="invoice.invoiceServiceRows[0].startDate" [matDatepicker]="picker" required
              (dateChange)="startDateChange(invoice.invoiceServiceRows[0])" (focus)="picker.open()" and
              (click)="picker.open()" placeholder="Start">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>

        <div fxFlex="24">
          <mat-form-field appearance="outline" class="w-100"
            *ngIf="timeArray && timeArray.length > 0 && userDtos && invoiceDefinition && invoiceDefinition.enableStartTimeServiceRow">
            <mat-label>{{ 'Start Time' | translate}}</mat-label>
            <mat-select placeholder="Select Start Time"
              (selectionChange)="startTimeChanged(invoice.invoiceServiceRows[0], $event.value)"
              [(ngModel)]="invoice.invoiceServiceRows[0].startTime" [compareWith]="compareTimeFn" required>
              <mat-option *ngFor="let time of timeArray" [value]="time.actualValue">{{time.displayValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxFlex="24">
          <mat-form-field appearance="outline" class="w-100" placeholder="End Time"
            *ngIf="timeArray && timeArray.length > 0 && userDtos && invoiceDefinition">
            <mat-label>{{ 'End Time' | translate}}</mat-label>
            <mat-select placeholder="Select End Time"
              (selectionChange)="endTimeChanged(invoice.invoiceServiceRows[0], $event.value)"
              [(ngModel)]="invoice.invoiceServiceRows[0].endTime" [compareWith]="compareTimeFn" required>
              <mat-option *ngFor="let time of timeArray" [value]="time.actualValue">{{time.displayValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </mat-step>

</mat-vertical-stepper>