<!-- <mat-toolbar color="primary">
    <mat-toolbar-row>
        {{'Discounts' | translate}}
    </mat-toolbar-row>
</mat-toolbar> -->


<app-discount-item *ngIf="discountSettings" entityDefType="GENERAL" [discountSettings]="discountSettings" manualItems="true" (doSave)="doSave($event)">
</app-discount-item>

<div class="flexbox-parent" *ngIf="!discountSettings">
  <div fxLayout="row" fxLayoutAlign="center center">
    <div style="text-align:center;">
      <div class="avatar avatar-100 circle border-3 mb-2 d-inline-block">
        <mat-icon class="h1 h-auto w-auto">sell</mat-icon>
      </div>
      <!-- <app-zero-image name="payment" class="zero-image"></app-zero-image><br> -->
      <h6 class="ma-0">{{'DONT_HAVE_DISCOUNTS' | translate}}</h6><br>
    </div>
  </div>
</div>