/**
 * Created by bengill on 21/12/2016.
 */

import { Injectable } from '@angular/core';

@Injectable()
export class EntityService {

  public getStrippedElementId(elementId): string {

    // if_title-input
    const withoutPrefix = elementId.substr(elementId.lastIndexOf('_') + 1);
    const withoutSuffix = withoutPrefix.substr(0, withoutPrefix.lastIndexOf('-'));

    console.log('stripped input [' + elementId + '] output [' + withoutSuffix + ']');
    return withoutSuffix;
  }

}
