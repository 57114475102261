import { Injectable } from '@angular/core';
import { Address, OrgCompositeService, OrgDto, OrgService, OrgSocialMediaDto } from '@savvy/org';
import { Observable, Subject } from 'rxjs';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
@Injectable({
  providedIn: 'root'
})
export class WelcomeOrgSetupService {

  org: OrgDto = {
    orgContactEmail: '',
    address: {},
    orgSetupStateDto: {
      completedSetupStep: '0',
      chosenSetupOptions: []
    }
  };

  private setupModeSource: Subject<'basic' | 'intermediate' | 'advanced'> = new Subject();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public setupMode$: Observable<'basic' | 'intermediate' | 'advanced'> = this.setupModeSource.asObservable();

  constructor(
    private orgCompositeService: OrgCompositeService,
    private orgService: OrgService,
    private notify: FloSnackbarComponent,
    // private router: Router
  ) { }

  loadOrg() {
    return new Promise<OrgDto>((resolve) => {
      this.orgCompositeService.getCurrentOrgComp().subscribe(
        org => {
          console.log('got org');
          if (!org.address) {
            org.address = {} as Address;
          }
          if (!org.orgSocialMedia) {
            org.orgSocialMedia = {} as OrgSocialMediaDto;
          }
          this.org = org;
          if (!this.org.orgSetupStateDto) {
            this.org.orgSetupStateDto = {
              completedSetupStep: '0',
              chosenSetupOptions: [],
              setupMode: this.org.orgSetupStateDto.setupMode
            };
          }
          resolve(this.org);
        }
      );
    });
  }

  updateOrg() {
    if (this.org?.id) {
      this.orgService.updateOrg(this.org.id, this.org).subscribe(response => {
        if (!response.address) {
          response.address = {} as Address;
        }
        this.org = response;
        this.notify.message = 'Updated Successfully';
        this.notify.open();
      });
    }

  }

  getChosenOptionForStep(stepNo: number) {
    return this.org?.orgSetupStateDto?.chosenSetupOptions[stepNo];
  }

  updateSetupMode(newSetupMode: 'basic' | 'intermediate' | 'advanced') {
    this.org.orgSetupStateDto.setupMode = newSetupMode;
    this.setupModeSource.next(newSetupMode);
    this.updateOrg();
  }

}
