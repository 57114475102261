import {
  Component,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ContextIdDto } from '@savvy/quickbooks';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { NavChangeService } from '../element/NavChangeService';
import { EventBusService } from '../element/EventBusService';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { PhoneNumberHelperService } from '../shared/services/phone-number-helper.service';
import { Subject } from 'rxjs';
import { Payment } from '@savvy/payment';
import { ConfirmationModalComponent } from '../shared/components/confirmation-modal/confirmation-modal.component';
import { WindowRef } from '../windowWrapper';
import { UrlLookupService } from '../shared/services/urlLookup.service';
import { InvoicecompService, InvoiceDto } from '@savvy/invoice';
import { Invoice, DiscountItem, DiscountSettings, DiscountSettingsService, InvoiceService, UpdateInvoice } from '@savvy/invoice';
import { RequestForPayment, RequestForPaymentService } from '@savvy/request-for-payment';
import { UserCurrencyService } from '../shared/services/userCurrency.service';

@Component({
  selector: 'app-view-invoice',
  templateUrl: './viewInvoice.component.html',
  styleUrls: ['./viewInvoice.component.scss']
})
export class ViewInvoiceComponent implements OnInit, OnDestroy, OnChanges {
  @Input() invoiceId: string;
  @Input() viewOnly = false;
  @Input() adminMenu = false;
  @Input() eventBus: EventBusService;
  @Input() contextIdDto: ContextIdDto;
  @Input() requestForPaymentOwnerId: string;
  @Input() requestForPaymentOwnerType = RequestForPayment.RequestForPaymentOwnerTypeEnum.Customer;

  @Output() backClicked = new EventEmitter();
  @ViewChild('sendInvoicePanel', { static: false }) sendInvoicePanel: MatSidenav;

  invoiceDto: InvoiceDto = <InvoiceDto>{};
  invoice: Invoice = <Invoice>{};
  show: string;
  destroy$ = new Subject();
  model: Payment;
  isPaidFull = false;
  currencyCode: string;

  showCourses = false;
  showServices = false;
  showProducts = false;

  discountSettings: DiscountSettings;
  discountItemsById: { [key: string]: DiscountItem } = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private invoiceApi: InvoiceService,
    private api: InvoicecompService,
    private navChange: NavChangeService,
    private translateService: TranslateService,
    private notify: FloSnackbarComponent,
    private dialog: MatDialog,
    private windowRef: WindowRef,
    private injector: Injector,
    private urlLookupService: UrlLookupService,
    private sharedService: PhoneNumberHelperService,
    private requestForPaymentService: RequestForPaymentService,
    private discountSettingsService: DiscountSettingsService,
    private userCurrencyService: UserCurrencyService
  ) {
  }

  ngOnInit() {
    console.log(1, this.contextIdDto);
    this.getCurrencyCode();
    this.loadDiscounts();
    console.log('loading invoice ' + this.invoiceId);
    // this.loadInvoice();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.invoiceId) {
      console.log('loading invoice: ', this.invoiceId);
      console.log('changes.invoiceId is: ', changes.invoiceId);
      this.loadInvoice();
    }
  }

  public loadInvoiceById(invoiceId: string) {
    this.invoiceId = invoiceId;
    this.loadInvoice();
  }

  getCurrencyCode() {
    this.userCurrencyService.getDefaultCurrency(this.contextIdDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.currencyCode = res.org.currencyCode ? res.org.currencyCode : this.userCurrencyService.defaultCurrency;
      });
  }

  sendInvoice() {
    this.show = 'sendInvoice';
    this.sendInvoicePanel.open();
  }

  viewInvoice() {
    const url = this.urlLookupService.invoiceUrl() + '?id='
      + this.contextIdDto.contextId + this.urlLookupService.getEnv() + '&invoiceId=' + this.invoice.id;
    const nativeWindow = this.injector.get(WindowRef).nativeWindow;
    nativeWindow.open(url);
  }

  viewPayments() {
    this.show = 'viewPayments';
    this.sendInvoicePanel.open();
  }

  invoiceSent() {
    this.show = '';
    this.sendInvoicePanel.close();
  }

  invoiceSendCancelled() {
    this.sendInvoicePanel.close();
    this.show = '';
  }

  voidInvoice() {
    this.invoice.invoiceVoid = true;
    this.updateInvoice();
  }

  updateInvoice() {
    const req: UpdateInvoice = <UpdateInvoice>{};
    req.contextIdDto = this.contextIdDto;
    // @ts-ignore
    req.invoice = this.invoice;

    this.invoiceApi.updateInvoice(req).subscribe(response => {
      this.invoice = response;
      this.isPaidFull = this.invoice.paidInFull;
      this.translateService.get('Updated Invoice')
        .pipe(takeUntil(this.destroy$))
        .subscribe(val => {
          this.notify.message = val;
          this.notify.open();
        });
    });
  }

  editInvoice() {
    this.router.navigate(['/invoice/editInvoice',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      this.invoice.id]);
  }


  deleteInvoice() {
    console.log('todo');
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        name: 'Invoice'
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.invoiceApi.deleteInvoice(
          this.invoiceId,
          this.contextIdDto.contextId,
          this.contextIdDto.contextIdType)
          .pipe(takeUntil(this.destroy$))
          .subscribe(response => {

            console.log('deleted invoice');
            this.translateService.get('Successfully deleted Invoice')
              .pipe(takeUntil(this.destroy$))
              .subscribe(val => {
                this.notify.message = val;
                this.notify.open();
              });
            this.router.navigate(['/']);
          });
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }


  back() {

    this.show = null;
    this.backClicked.emit('back');
    // this.location.back();
  }

  loadInvoice() {
    if (this.invoiceId) {
      this.api.loadInvoiceForView(
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.invoiceId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          response => {
            console.log('loaded invoice dto', response.invoiceDto);
            this.invoiceDto = response.invoiceDto;
            // @ts-ignore
            this.invoice = response.invoiceDto.invoice;
            this.isPaidFull = this.invoice.paidInFull;
            this.model = <Payment>{};
            this.model.amount = this.invoice.total;
          });
    } else {
      this.notify.message = 'Invoice Id does not exist';
      this.notify.open();
    }
  }

  loadDiscounts() {
    this.discountSettingsService
      .get(this.contextIdDto.contextId, this.contextIdDto.contextIdType)
      .subscribe(res => {
        this.discountSettings = res;
        if (this.discountSettings && this.discountSettings.discountItems) {
          this.discountSettings.discountItems.forEach(r => {
            this.discountItemsById[r.id] = r;
          });
        }
      });
  }

}
