import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'delete-flag-confirmation-modal',
  templateUrl: 'deleteFlagConfirmationModal.component.html'
})

export class DeleteFlagConfirmationModalComponent implements OnInit {
  plural: string = '';

  constructor(
    public dialogRef: MatDialogRef<DeleteFlagConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    if (this.data.flag.flagDefinitionType) {
      this.plural = this.data.flag.flagDefinitionType.toString().toLowerCase() + "s";
    }
  }
}
