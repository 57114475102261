import {Routes} from '@angular/router';
import {AddEditCustomerSignupFormDefComponent} from './addEditCustomerSignupFormDef.component';
import {CustomerSignupFormTemplatesComponent} from './customerSignupFormTemplates.component';

export const CustomerSignupFormsRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'customerSignupFormDefs/:contextId/:contextIdType', component: CustomerSignupFormTemplatesComponent,
        data: {
          breadcrumb: 'Customer Signup Form Template',
          topLevel: 'true'
        }
      },
      {
        path: 'addCustomerSignupFormDef/:contextId/:contextIdType', component: AddEditCustomerSignupFormDefComponent,
        data: {
          breadcrumb: 'Add Customer Signup Form Template',
          topLevel: 'true'
        }
      },
      {
        path: 'editCustomerSignupFormDef/:contextId/:contextIdType/:customerSignupFormDefinitionId',
        component: AddEditCustomerSignupFormDefComponent,
        data: {
          breadcrumb: 'Edit Customer Signup Form Template',
          topLevel: 'true'
        }
      }
    ]
  }
];
