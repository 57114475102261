import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {AddDataItem, DataItemDto, DataListDto, DatalistService} from '@savvy/datalist';
import { FloSnackbarComponent } from '../../../snackbar/floSnackbar.component';
import {ContextIdDto, DataListId} from '@savvy/entity-instance-composite';

@Component({
  selector: 'app-add-field-definition-modal',
  templateUrl: './add-field-definition-modal.component.html',
  styleUrls: ['./add-field-definition-modal.component.scss']
})
export class AddFieldDefinitionModalComponent implements OnInit {
  newItemName: string;
  model = <DataListDto>{};
  contextIdDto: ContextIdDto;
  dataListName: string;
  dataListId: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<AddFieldDefinitionModalComponent>,
    private dataListApi: DatalistService,
    private notify: FloSnackbarComponent) { }

  ngOnInit() {
    this.contextIdDto = this.data.contextIdDto;
    this.dataListName = this.data.dataListName;
    this.model.dataItems = this.data.dataListItems;
  }

  updateList() {

    const newItem = <DataItemDto>{};
    newItem.name = this.newItemName;
    newItem.label = this.newItemName;


    const req = <AddDataItem>{};
    req.contextIdDto = this.contextIdDto;
    req.dataItemDto = newItem;
    req.dataListId = <DataListId>{};
    req.dataListId.id = this.data.dataListId;

    this.dataListApi.addDataItem(req).subscribe(response => {
      this.notify.message = 'Updated list';
      this.notify.open();
      this.dialogRef.close(this.newItemName);
    }, error => {
      console.log('Can`t add new item to the list');
    });

  }

}
