import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ContextIdDto } from '@savvy/entity-instance-composite';
import { ViewContextDto } from '@savvy/view-composite';
import { Address, CreateCustomerCompositeLite, CustomerCompositeService, Customer, OnboardCustomer } from '@savvy/customer';
import { EventEmitter } from '@angular/core';
import { PhoneNumber, UserDto, UserId } from '@savvy/view-definition';
import { Pet } from '@savvy/pet';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EventBusService } from '../../../element/EventBusService';
import { UserCountryService } from 'src/app/services/userCountry.service';
import { FloSnackbarComponent } from 'src/app/flo/snackbar/floSnackbar.component';
import { AddNewPetV2Component } from '../add-new-pet-v2/add-new-pet-v2.component';
import { CreatePetAppointmentV2SyncService } from '../create-pet-appointment-v2-sync.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ContractService, CreateContract, LinkId } from '@savvy/contract';
import { LinkedIdTypeEnum } from 'src/app/core/data-share.service';
import { OnboardCustomerComponent, OnboardCustomerEvent } from '../../../shared/components/onboard-customer/onboardCustomer.component';
import { PlansCompService, SubscribeToPlan } from '@savvy/plan';
import { RegistrationService, UserNameExistDto } from '@savvy/registration';
import { AppointmentConfigHelperService } from "../../../shared/services/appointment-config-helper.service";

@Component({
  selector: 'app-new-pet-customer-selector-v2',
  templateUrl: './new-pet-customer-selector-v2.component.html',
  styleUrls: ['./new-pet-customer-selector-v2.component.scss']
})
export class NewPetCustomerSelectorV2Component implements OnInit {
  @ViewChild(AddNewPetV2Component, { static: false }) addNewPetV2Component: AddNewPetV2Component;
  @ViewChild(OnboardCustomerComponent, { static: false }) onboardCustomerComponent: OnboardCustomerComponent;

  @Input() contextIdDto: ContextIdDto;
  @Input() viewContext: ViewContextDto;
  @Input() eventBus: EventBusService;
  @Input() showPlaceholder: boolean;
  @Input() passIdOnly = true;

  @Output() customerCreated: EventEmitter<Pet> = new EventEmitter();
  @Output() newExistingSwitch: EventEmitter<boolean> = new EventEmitter();

  newCustomer = false;
  customer;

  customerFormGroup: UntypedFormGroup;
  customerPhone: PhoneNumber = {
    country: '',
    internationalNumber: '',
    nationalNumber: ''
  };

  createdCustomer: Customer;
  addAddress = false;
  onboardCustomer = true;
  onboard: OnboardCustomer;
  customerIdLinkId: LinkId;

  constructor(
    private customerCompositeService: CustomerCompositeService,
    public userCountryService: UserCountryService,
    private notify: FloSnackbarComponent,
    private _formBuilder: UntypedFormBuilder,
    private contractService: ContractService,
    public appointmentConfigHelperService: AppointmentConfigHelperService,
    private createPetAppointmentV2SyncService: CreatePetAppointmentV2SyncService,
    public deviceService: DeviceDetectorService,
    private planCompApi: PlansCompService,
    private registrationApi: RegistrationService
  ) {

  }

  ngOnInit(): void {
    console.log('inside init for invoice stepper panel');
    this.customerPhone = {} as PhoneNumber;
    this.customerPhone.country = this.userCountryService?.defaultCountryCode;
    this.initFormGroup();
  }

  createCustomer(optIn: boolean) {
    if (!this.customerFormGroup.valid || !this.addNewPetV2Component?.valid) {
      this.notify.message = 'Please provide mandatory values';
      this.notify.open();
      return;
    }
    const req = {} as CreateCustomerCompositeLite;
    req.contextIdDto = this.contextIdDto;
    req.firstName = this.customerFormGroup.get('customerFirstName').value;
    req.lastName = this.customerFormGroup.get('customerLastName').value;
    req.email = this.customerFormGroup.get('customerEmail').value;
    req.phone = this.customerPhone;
    req.address = this.customerFormGroup.get('customerAddress').value;
    req.optIn = optIn;
    if (this.onboardCustomer) {
      req.onboardCustomer = this.onboard;
    }
    if (this.onboardCustomerComponent) {
      this.onboardCustomerComponent.updateCustomerToPortalSetting();
    }

    // if (!req?.email && !req.phone?.internationalNumber) {
    //   this.notify.message = 'Email or Phone number is required to communicate with the customer';
    //   this.notify.open();
    //   return;
    // }

    this.customerCompositeService.createCustomerCompLite(req).subscribe(async (response) => {
      setTimeout(() => this.customerFormGroup.disable(), 300);
      this.notify.message = 'Customer has been created successfully';
      this.notify.open();
      this.setLinkId(response.customer.customerId.id);
      if (this.onboardCustomer && this.onboard?.contractTemplateId) {
        await this.addContract(response.customer.customerId.id);
      }
      if (this.onboardCustomer && this.onboard?.paymentPlanDefinitionId) {
        await this.subscribeCustomerToPlan(response.customer.customerId.id);
      }
      this.createdCustomer = response.customer;
      this.createPetAppointmentV2SyncService.petsSelected = [];
      this.createPetAppointmentV2SyncService.petsSelectedChips = [];
      this.addNewPetV2Component.customerId = response.customer.customerId.id;
      await this.addNewPetV2Component.createPetMultiple(response.customer.customerId.id, this.customerCreated);
    }, (error) => {
      console.log('err is', error);
      if (error && error.status === 409) {
        this.notify.message = `Customer already exists with email ${req.email}`;
        this.notify.open();
      } else {
        this.notify.message = error.error;
        this.notify.open();
      }
    });
  }

  subscribeCustomerToPlan(customerId: string) {
    const planReq = <SubscribeToPlan>{};
    planReq.contextIdDto = this.contextIdDto;
    planReq.planDefinitionId = this.onboard?.paymentPlanDefinitionId;
    planReq.customerId = customerId;
    planReq.dayOfPayment = 1;
    return this.planCompApi.subscribe(planReq);
  }

  addContract(customerId) {
    const contract: any = {
      legalText: '',
      contractTemplateId: this.onboard?.contractTemplateId,
      ownerId: this.contextIdDto?.contextId,
      customerId,
      linkId: this.customerIdLinkId
    };
    const req: CreateContract = <CreateContract>{};
    req.contextIdDto = this.contextIdDto;
    req.contract = contract;

    return this.contractService.createContract(req);
  }

  setLinkId(customerId: string) {
    this.customerIdLinkId = <LinkId>{};
    this.customerIdLinkId.linkedIdType = LinkedIdTypeEnum.CustomerId;
    this.customerIdLinkId.linkedId = customerId;
  }

  onMobilePhoneChange(value) {
    console.log('mobile changed');
    this.customerPhone = value;
  }

  onMobileCountryChange($event: any) {
    console.log('onMobileCountryChange', $event);
    this.customerPhone.country = $event.iso2;
  }

  setUserOptVal(user: UserDto): UserId {
    return { id: user.id, userContextType: UserId.UserContextTypeEnum.UserId };
  }

  compareUserFn(user1: UserDto, user2: UserDto) {
    return user1 && user2 && user1.id === user2.id;
  }

  petsFormGroupChange(petFormGroupArray) {
    // this.createPetAppointmentV2SyncService.petsSelected = [];
    // this.createPetAppointmentV2SyncService.petsSelectedChips = [];
    // this.createPetAppointmentV2SyncService.selectedServices = {};
    // for (const petFormGroup of petFormGroupArray) {
    //   const firstName = this.customerFormGroup.get('customerFirstName').value;
    //   const lastName = this.customerFormGroup.get('customerLastName').value;
    //   const pet: PetSearchResultDto = {
    //     petId: uuid.v4(),
    //     petBreed: petFormGroup.get('petBreed').value,
    //     customerName: `${firstName} ${lastName}`,
    //     ownerId: this.contextIdDto.contextId,
    //     petName: petFormGroup.get('petName').value,
    //     petOwnerId: '',
    //     petNotes: '',
    //     deleted: false
    //   };
    //   this.createPetAppointmentV2SyncService.petsSelected.push(pet);
    //   this.createPetAppointmentV2SyncService.petsSelectedChips.push(pet.petName);
    //   this.createPetAppointmentV2SyncService.updatePetSelection(this.createPetAppointmentV2SyncService.petsSelected);
    // }
  }

  cancel() {
    this.newExistingSwitch.emit(false);
  }

  isAllFormGroupValid() {

  }


  addressChanged(customerAddress: Address) {
    this.customerFormGroup.patchValue({ customerAddress });
  }

  initFormGroup() {
    this.customerFormGroup = this._formBuilder.group({
      customerFirstName: ['', Validators.required],
      customerLastName: ['', Validators.required],
      customerEmail: [''],
      customerPhone: [''],
      customerAddress: [{}]
    });
  }

  onboardUpdated(data: OnboardCustomerEvent) {
    if (data?.isOnboardCustomer) {
      this.onboardCustomer = true;
      this.onboard = data?.onboard;
    } else {
      this.onboardCustomer = false;
      this.onboard = null;
    }
  }

  get newPetFormControl() {
    return this.customerFormGroup.controls;
  }

  checkIfCustomerExist() {
    if (this.customerFormGroup.get('customerEmail').value) {
      this.customerCompositeService.checkUserOnboard(this.contextIdDto.contextId, 'ENV_ID', this.customerFormGroup.get('customerEmail').value).subscribe(res => {
        if (res?.userExists) {
          this.customerFormGroup.controls['customerEmail'].setErrors({ 'userNameExist': true });
        } else {
          this.customerFormGroup.controls['customerEmail'].setErrors(null);
        }
        this.customerFormGroup.controls['customerEmail'].markAsDirty();
      });
    }
  }

}
