import { Component, Input, OnInit } from '@angular/core';
import { ContextIdDto, ViewImageDto } from '@savvy/view-definition';
import { ConfService } from '../config/conf.service';

@Component({
  selector: 'app-view-image',
  templateUrl: 'viewImage.component.html'
})
export class ViewImageComponent implements OnInit {

  @Input() viewImageDto: ViewImageDto;
  @Input() viewContext: any;
  @Input() contextIdDto: ContextIdDto;
  imageUrl;

  constructor() {
  }

  ngOnInit(): void {
    this.imageUrl = this.getImageUrl();
  }

  getImageUrl() {
    /*if (this.viewImageDto && this.viewImageDto.url) {
      return this.viewImageDto.url;
    }*/
    if (this.viewImageDto.elementInstanceDto.fieldInstanceDto.value.imageFieldValue &&
      this.viewImageDto.elementInstanceDto.fieldInstanceDto.value.imageFieldValue.fileReferences &&
      this.viewImageDto.elementInstanceDto.fieldInstanceDto.value.imageFieldValue.fileReferences.length > 0 &&
      this.viewImageDto.elementInstanceDto.fieldInstanceDto.value.imageFieldValue.fileReferences[0].fileReferenceId) {
      return ConfService.apiUrl() + '/rest/storage?contextId=' + this.contextIdDto.contextId +
        '&contextIdType=' + this.contextIdDto.contextIdType +
        '&id=' + encodeURIComponent(this.viewImageDto.elementInstanceDto.fieldInstanceDto.value.imageFieldValue.fileReferences[0].fileReferenceId);
    }
    return 'assets/images/no-image.jpg';
  }
}
