import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ContextIdDto, Notification } from '@savvy/notification';
import { Customer, CustomerService } from '@savvy/customer';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';
import { MatDialog } from '@angular/material/dialog';
import { UpdateCustomer } from '@savvy/customer';
import { PetService } from '@savvy/pet';
import { Router } from '@angular/router';
import { error } from 'console';

@Component({
  selector: 'app-invite-customer-notification',
  templateUrl: './invite-customer-notification.component.html',
})
export class InviteCustomerNotificationComponent implements OnInit {
  @Input() notification: Notification;
  @Input() contextIdDto: ContextIdDto;

  @Output() notificationProcessed: EventEmitter<any> = new EventEmitter();

  customer: Customer;

  constructor(
    private router: Router,
    private notify: FloSnackbarComponent,
    private customerService: CustomerService,
    private petService: PetService
  ) {}

  ngOnInit(): void {
    this.loadCustomer();
  }

  loadCustomer() {
    this.customerService
      .loadCustomer(
        this.notification.customerId,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType
      )
      .subscribe(
        (response) => {
          this.customer = response;
        },
        (error) => {
          console.error(
            'Unable to load customer..' + this.notification.customerId
          );
        }
      );
  }

  listCustomers() {
    this.router.navigate([
      'customer/customers',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      true,
    ]);
  }
}
