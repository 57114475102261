import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ContextIdDto } from '@savvy/app';
import { Customer } from '@savvy/customer';
import { AppointmentCompService, AppointmentDataV1V2, AppointmentId, AppointmentService, AppointmentV1V2Service } from '@savvy/appointment';
import { AppointmentHandlerService } from '../../../appointments-v2/appointment-handler.service';
import { Page } from '../../../shared/model/page';
import { IconService } from '../../../logo/icon.service';
import { MatDialog } from '@angular/material/dialog';
import { FloSnackbarComponent } from '../../../snackbar/floSnackbar.component';
import { ViewInvoiceV2Component } from 'src/app/flo/appointment/view-invoice-v2/view-invoice-v2.component';
import { Pet } from '@savvy/pet';
import { ConfirmationDialogComponent } from 'src/app/flo/confirmation-dialog/confirmation-dialog.component';
import { AppointmentConfigHelperService } from 'src/app/flo/shared/services/appointment-config-helper.service';
import { CalendarSetupService } from 'src/app/flo/welcome-to-savvy/calendar-setup.service';

@Component({
  selector: 'app-view-v1-v2-customer-history',
  templateUrl: './view-v1-v2-customer-history.component.html',
  styleUrls: ['./view-v1-v2-customer-history.component.scss']
})
export class ViewV1V2CustomerHistoryComponent implements OnInit, OnChanges {
  @Input() contextIdDto: ContextIdDto;
  @Input() customerDto: Customer;
  @Input() petList: Pet[] = [];
  @Input() isViewMode = false;
  @Input() historyMode: 'past' | 'future' = 'future';

  @Output() openV1Event: EventEmitter<AppointmentDataV1V2> = new EventEmitter();
  svgXml: string;

  futureSelected: AppointmentDataV1V2[] = [];
  futureAppointments: Array<AppointmentDataV1V2> = [];
  cancelledAppointments: Array<AppointmentDataV1V2> = [];

  pastPage = new Page();
  pastSelected: AppointmentDataV1V2[] = [];
  pastAppointments: Array<AppointmentDataV1V2> = [];
  showOnlyCancelled = false;
  selectedAppointments: string[] = [];

  constructor(private appointmentV1V2Service: AppointmentV1V2Service,
    private appointmentHandlerService: AppointmentHandlerService,
    private appointmentService: AppointmentService,
    private iconService: IconService,
    private notify: FloSnackbarComponent,
    private appointmentCompApi: AppointmentCompService,
    private appointmentConfigHelperService: AppointmentConfigHelperService,
    private dialog: MatDialog,
    private calendarSetupService: CalendarSetupService
  ) {
  }

  loadCalendarSettings() {
    this.calendarSetupService.loadCalendarSettings(this.contextIdDto);
  }

  ngOnInit() {
    this.loadAppointments();
    this.loadCalendarSettings();
    this.svgXml = this.iconService.getSvgIcon('Appointments');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.customerDto?.currentValue !== changes?.customerDto?.previousValue) {
      this.loadAppointments();
    }
  }

  historyModeChanged(event) {
    this.selectedAppointments = [];
    if (event === 'past') {
      this.loadHistory();
    } else if (event === 'future') {
      this.loadFuture();
    }
  }


  sendAppointments() {
    this.appointmentCompApi.sendAppointmentsV2(this.contextIdDto.contextId,
      this.customerDto.customerId.id
    ).subscribe(response => {
      if (response.errorMessage) {
        this.notify.message = response.errorMessage;
        this.notify.open();
      } else {
        this.notify.message = 'Sent Appointments to Customer';
        this.notify.open();
      }
    });
  }

  callback = (result) => {
    if (result) {
      this.loadAppointments();
    }
  };

  addAppointment() {
    const petList = this.petList.filter(pet => !pet.deceased);
    this.appointmentHandlerService.createAppointment({
      contextIdDto: this.contextIdDto,
      petList: petList || [],
      callback: this.callback,
      calendarSettings: this.calendarSetupService.calendarSettings
    });
  }

  loadHistory() {
    if (this.customerDto?.customerId) {
      // console.log('loading history for ', this.customerDto.customer);
      if (this.showOnlyCancelled) {
        this.appointmentV1V2Service.loadCustomerHistoryV1V2Cancelled(
          this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          this.customerDto.customerId.id
        ).subscribe(response => {
          console.log('response', response);
          this.pastAppointments = response.contents;
          this.pastPage.totalElements = response.totalElements; // response.eis.totalElements;
          this.pastPage.totalPages = 1; // response.eis.totalPages;
        });
      } else {
        this.appointmentV1V2Service.loadCustomerHistoryV1V2(
          this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          this.customerDto.customerId.id
        ).subscribe(response => {
          console.log('response', response);
          this.pastAppointments = response.contents;
          this.pastPage.totalElements = response.totalElements; // response.eis.totalElements;
          this.pastPage.totalPages = 1; // response.eis.totalPages;
        });
      }

    } else {
      console.log('no customer');
    }
  }

  loadFuture() {
    // console.log('loading future', this.customerDto.customer);
    if (this.customerDto?.customerId) {
      // console.log('loading future for ', this.customerDto.customer);
      if (this.showOnlyCancelled) {
        this.appointmentV1V2Service.loadCustomerFutureV1V2Cancelled(
          this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          this.customerDto.customerId.id
        ).subscribe(response => {
          console.log('response', response);
          this.futureAppointments = response.contents;
        });
      } else {
        this.appointmentV1V2Service.loadCustomerFutureV1V2(
          this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          this.customerDto.customerId.id
        ).subscribe(response => {
          console.log('response', response);
          this.futureAppointments = response.contents;
        });
      }

    } else {
      console.log('no customer');
    }
  }

  onFutureSelect() {
    if (this.futureSelected && this.futureSelected.length > 0 && !this.isViewMode) {
      this.openViewAppointmentModal(this.futureSelected[0]);
    }
  }

  onPastSelect() {
    if (this.pastSelected && this.pastSelected.length > 0 && !this.isViewMode) {
      this.openViewAppointmentModal(this.pastSelected[0]);
    }
  }

  cancelledFilterChanged() {
    this.loadAppointments();
  }

  openViewAppointmentModal(appointmentDataV1V2: AppointmentDataV1V2) {
    if (!this.isViewMode) {
      if (appointmentDataV1V2.appointmentId) {
        console.log('appointmentId is ', appointmentDataV1V2.appointmentId);
        this.appointmentHandlerService.viewAppointment({
          contextIdDto: this.contextIdDto,
          appointmentId: appointmentDataV1V2.appointmentId.id,
          appointmentConfig: this.appointmentConfigHelperService.appointmentConfig,
          callback: this.callback,
          calendarSettings: this.calendarSetupService.calendarSettings,

        });
      } else {
        this.openV1Event.emit(appointmentDataV1V2);
        // TODO: cannot open v1 appointments from here
      }
    }
  }

  openEditAppointmentModal(appointmentDataV1V2: AppointmentDataV1V2) {
    if (!this.isViewMode) {
      if (appointmentDataV1V2.appointmentId) {
        console.log('appointmentId is ', appointmentDataV1V2.appointmentId);
        this.appointmentService.loadAppointment(appointmentDataV1V2.appointmentId.id,
          this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(res => {
            this.appointmentHandlerService.editAppointment({
              contextIdDto: this.contextIdDto, appointment: res, callback: this.callback,
              calendarSettings: this.calendarSetupService.calendarSettings,
            });
          });
      } else {
        this.openV1Event.emit(appointmentDataV1V2);
        // TODO: cannot open v1 appointments from here
      }
    }
  }

  viewInvoice(invoiceId: string) {
    if (invoiceId) {
      // console.log('inside view invoiceId ', invoiceId);
      const dialogRef = this.dialog.open(ViewInvoiceV2Component, {
        maxWidth: '800px',
        width: '800px',
        data: {
          invoiceId,
          contextIdDto: this.contextIdDto
        },
        panelClass: ['scrollable-modal', 'invoicemodal']
      });

      dialogRef.afterClosed().subscribe(() => {

      });
    }
  }

  selectAppointment(appointmentId: AppointmentId) {
    if (appointmentId?.id) {
      const index = this.selectedAppointments.indexOf(appointmentId.id);
      if (index > -1) {
        this.selectedAppointments.splice(index, 1);
      } else {
        this.selectedAppointments.push(appointmentId.id);
      }
    }
  }

  deleteAppointments() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Bulk Delete Appointments',
        message: 'Are you sure you want to delete all selected appointments?',
      },
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.selectedAppointments.forEach((selectedAppointment, index) => {
          this.appointmentService.deleteAppointment(selectedAppointment, this.contextIdDto.contextId, this.contextIdDto.contextIdType)
            .subscribe(response => {
              if (response) {
                console.log('deleted appointment', { id: response.id });
                if (index === this.selectedAppointments.length - 1) {
                  this.loadAppointments();
                }
              }
            });
        });
      }
    });
  }

  public loadAppointments() {
    this.loadHistory();
    this.loadFuture();
    this.selectedAppointments = [];
  }
}
