<div fxLayout="row">

  <mat-form-field appearance="outline" style="width: 100%;">
    <mat-label>{{entityDefinitionLabel | translate}}</mat-label>

    <input matInput
           [required]="true"
           [matAutocomplete]="auto1"
           [(ngModel)]="entityInstanceIdField" name="entityInstanceId"
           #entityInstanceId="ngModel"
           (keyup)="onKey($event)">

    <mat-autocomplete #auto1="matAutocomplete"
                      [displayWith]="displayFn.bind(this)"
                      autoActiveFirstOption>

      <!--button class="add-new-field" mat-raised-button color="accent" (click)="createNewLinkedEntity()">Add new</button-->

      <mat-option *ngFor="let tuple of entityInstanceIdFieldValueTupleDtos" (onSelectionChange)="changedSelection(tuple.id)" [value]="tuple.id">
        {{ tuple.name }}
      </mat-option>
    </mat-autocomplete>

    <mat-error *ngIf="entityInstanceId.hasError('required')">
      {{entityDefinitionLabel + ' is' | translate}} <strong>{{'required' | translate}}</strong>
    </mat-error>
    <mat-error *ngIf="!entityInstanceId.hasError('required') && entityInstanceId.hasError('doesNotSelected')">
      {{entityDefinitionLabel + ' is' | translate}} <strong>{{'required' | translate}}</strong>.
      {{'Please Select one of the choices or create it' | translate}}.
    </mat-error>
  </mat-form-field>
  <span style="text-align: right; padding: 15px">{{'OR' | translate}}</span>
  <button class="mat-accent" (click)="createNewLinkedEntity()" mat-fab>
    <mat-icon>add</mat-icon>
  </button>
</div>
