import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { TranslateService } from '@ngx-translate/core';
import { ContextIdDto } from '@savvy/view-definition';
import { CustomerCompositeService, IdNameTupleDto } from '@savvy/customer';
import {PlanService, PlansCompService, SubscribeToPlan} from '@savvy/plan';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {PlanDefinition, PlanDefService} from '@savvy/plan-definition';

@Component({
  selector: 'app-assign-customer-plan',
  templateUrl: 'assignCustomerToPlan.html'
})
export class AssignCustomerToPlanComponent implements OnInit {

  contextIdDto: ContextIdDto;
  planDefinitions: Array<PlanDefinition>;
  planDef = <PlanDefinition>{};

  searchCustomerResult: Array<IdNameTupleDto> = [];
  request = <SubscribeToPlan>{};

  @Output() added: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private notify: FloSnackbarComponent,
    private planApi: PlanService,
    private planCompApi: PlansCompService,
    private planDefApi: PlanDefService,
    private customerCompApi: CustomerCompositeService,
    private dialogRef: MatDialogRef<AssignCustomerToPlanComponent>) {

    this.contextIdDto = data.contextIdDto;
    this.request.customerId = data.customerId.id;
  }

  ngOnInit() {
    this.loadPlanDefinitions();
  }

  loadPlanDefinitions() {
    this.planDefApi.loadByOwnerId(this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(res => {
      this.planDefinitions = res.planDefinitions;
    });
  }

  planDefinitionChange(event: any) {
    console.log(event);
    this.planDef = this.planDefinitions.find(pd => pd.id === event.value);
  }

  addToPlan() {

    this.request.contextIdDto = this.contextIdDto;

    console.log(this.request);

    this.planCompApi.subscribe(this.request).subscribe(res => {

      this.notify.message = 'Subscription request sent to customer.';
      this.notify.open();

      this.added.emit();
      this.dialogRef.close();
    });

  }


}
