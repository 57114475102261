import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ContextIdDto, DataItemDto, DatalistService } from '@savvy/datalist';
import { Pet } from '@savvy/pet';
import { ContextService } from "../../../context.service";
import { DateAdapter } from '@angular/material/core';
import { LanguageService } from 'src/app/services/language.service';
import { ClearObservable } from 'src/app/flo/shared/classes/clear-observable';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-pet-details',
  templateUrl: './pet-details.component.html',
  styleUrls: ['./pet-details.component.scss']
})
export class PetDetailsComponent extends ClearObservable implements OnInit {
  @Input() contextIdDto: ContextIdDto;
  petFormGroup: UntypedFormGroup;
  petColors: Array<DataItemDto> = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private datalistService: DatalistService,
    public contextService: ContextService,
    private adapter: DateAdapter<any>,
    private languageService: LanguageService
  ) {
    super();
  }

  ngOnInit(): void {
    this.languageService.currentLocale$
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        if (res) {
          this.adapter.setLocale(res);
        }
      });
    if (this.languageService?.currentLocale) {
      this.adapter.setLocale(this.languageService?.currentLocale);
    }
    this.initFormGroup();
    this.loadColors();
  }

  updateColor(event: MatSelectChange) {
    this.petFormGroup.patchValue({
      colour: event.value
    });
  }

  public setValue(pet: Pet) {
    this.petFormGroup.patchValue({
      colour: pet?.colour,
      dob: pet?.dob,
      groomPrice: pet?.groomPrice,
      blades: pet?.blades,
      spayedOrNeutered: pet?.spayedOrNeutered,
      deceased: pet?.deceased,
      bitch: pet?.bitch,
      recommendedVisitFrequencyInWeeks: pet?.recommendedVisitFrequencyInWeeks,
      insured: pet?.insured,
      afraidOfNoises: pet?.afraidOfNoises
    });
  }

  private loadColors() {
    this.datalistService.getDataListByName(this.contextIdDto.contextId, this.contextIdDto.contextIdType, 'Pet Colour')
      .subscribe(res => {
        if (res && res.dataItems) {
          this.petColors = res.dataItems;
        }
      });
  }

  private initFormGroup() {
    this.petFormGroup = this.formBuilder.group({
      colour: [''],
      dob: [''],
      groomPrice: [null],
      blades: [''],
      spayedOrNeutered: [false],
      deceased: [false],
      bitch: [null],
      recommendedVisitFrequencyInWeeks: [null],
      insured: [false],
      afraidOfNoises: [false]
    });
  }

}
