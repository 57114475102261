import { EventEmitter, Injectable } from '@angular/core';
import { MenuDefinition } from '@savvy/menu';
import { BehaviorSubject, Observable } from 'rxjs';
import { LayoutEvent } from '../event/LayoutEvent';

@Injectable()
export class LayoutBusService {
  public eventAdded$: EventEmitter<LayoutEvent>;
  public toggleMenu$: EventEmitter<boolean> = new EventEmitter();

  menuTypeTrigger: BehaviorSubject<MenuDefinition> = new BehaviorSubject(null);
  public menuChanged$: Observable<MenuDefinition> = this.menuTypeTrigger.asObservable();
  private events: LayoutEvent[] = [];

  constructor() {
    this.eventAdded$ = new EventEmitter();
  }

  public list(): LayoutEvent[] {
    return this.events;
  }

  public add(event: LayoutEvent): void {
    this.events.push(event);
    this.eventAdded$.emit(event);
  }
}
