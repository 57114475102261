import {Component, Input} from '@angular/core';
import { ContextIdDto } from '@savvy/view-definition';
import {EventBusService} from './EventBusService';
import {EventType} from '../event/UiEvent';

@Component({
  selector: 'app-view-nested-entity',
  templateUrl: 'viewNestedEntity.component.html'
})
export class ViewNestedEntityComponent {

  @Input() contextIdDto: ContextIdDto;
  @Input() visible: boolean;
  @Input() eventBus: EventBusService;

  entityInstanceId: string;


  cancelViewEntity() {
    this.eventBus.addEvent(EventType.CANCEL_VIEW_ENTITY);
  }
}
