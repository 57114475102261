<mat-toolbar-row *ngIf="quickAppointment && appointment">
  <span fxLayout="row" fxLayoutAlign=" center">
    <div class="icon-24 mat-text-secondary" fxFlex="nogrow">
      <mat-icon>event</mat-icon>
    </div>
    <span class="date-time padding-left-half" fxLayout="column">
      <span>{{appointment.startDate | localizedDate: 'shortDate'}}</span>
      <small class="mat-text-secondary fs-12">
        {{startTime | localizedDate:'shortTime'}} - {{endTime | localizedDate:'shortTime'}}
      </small>
    </span>
  </span>
  <span class="example-spacer"></span>
  <div class="mr-1" *ngIf="appointment?.cancellationReason">
    Cancellation Reason: {{appointment.cancellationReason}}
  </div>
  <app-view-appointment-workflow-panel fxLayoutAlign="center" class="mr-1" [contextIdDto]="contextIdDto"
    [(appointment)]="appointment">
  </app-view-appointment-workflow-panel>
  <button mat-icon-button color="primary" aria-label="Example icon button with a home icon" (click)="fullScreen()">
    <mat-icon class="full-screen-icon">open_in_full</mat-icon>
  </button>
</mat-toolbar-row>

<!-- <mat-toolbar color="primary" *ngIf="!quickAppointment">
  <mat-toolbar-row>
    <span fxLayout="row" fxLayoutAlign=" center">
      View Appointment
    </span>
    <span class="example-spacer"></span>
    <button mat-icon-button aria-label="Example icon button with a home icon" (click)="exitFullScreen()">
      <mat-icon class="full-screen-icon">close_fullscreen</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar> -->
<!-- <mat-card class="m-0" *ngIf="appointment">
  <mat-card-content> -->
<div *ngIf="appointment" class="position-relative">

  <div class="mdc-px-3 " *ngIf="quickAppointment">
    <mat-card appearance="outlined" class="mx-0 mdc-mb-1" *ngIf="showConfirmationDialog">
      <mat-card-header>
        <mat-icon class="close-confirm" (click)="closeConfirmDialog()">cancel</mat-icon>
      </mat-card-header>
      <mat-card-content>
        <div fxLayout="row" fxLayoutAlign=" center">
          <div fxFlex>
            <h6>{{'NICE_PROGRESS' | translate: {Default: 'Nice Progress!'} }}</h6>
            <p class="small mat-text-secondary mb-0">{{'CHANGED_STATUS_CONFIRMATION' | translate: {Default: 'You have
              changed status. By confirming Email/SMS will be sent to user.'} }}</p>
            <p class="small mat-text-secondary mb-0">
              {{'CHANGE_OR_MODIFY_TEMPLATE' | translate: {Default: 'To change or modify template'} }} <a
                class="text-underline" (click)="openEditTemplate()">{{'CLICK_HERE' | translate: {Default: 'Click Here'}
                }}</a>.
            </p>
          </div>
          <div fxFlex="nogrow">
            <button mat-raised-button color="accent" (click)="confirmStateChange()">{{'CONFIRM' | translate: {Default:
              'Confirm'} }}</button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <app-quick-pet-view [contextIdDto]="contextIdDto" [appointment]="appointment" (rebookEvent)="rebookEvent()"
      (gotoPetEvent)="gotoPet($event)">
    </app-quick-pet-view>
  </div>

  <div *ngIf="!quickAppointment" class="quick-actions">
    <!-- <button mat-button [matMenuTriggerFor]="menu" class="mat-text-secondary more-btn">
      <mat-icon>more_vert</mat-icon> <span class="more-text">{{'MORE' | translate: {Default: 'More'} }}</span>
    </button> -->
    <!-- <button *ngIf="deviceService.isMobile() || deviceService.isTablet()" mat-icon-button type="button" class="mat-text-secondary" (click)="cancel()">
      <mat-icon>cancel</mat-icon>
    </button> -->
    <!-- <mat-menu #menu="matMenu" class="quick-actions-menu">
      <button mat-menu-item (click)="edit()">
        <mat-icon>edit</mat-icon>{{'EDIT' | translate: {Default: 'Edit'} }}
      </button>
      <button *ngIf="appointment.status !== 'Cancelled'" mat-menu-item (click)="cancelAppointment()">
        <mat-icon>cancel</mat-icon>{{'CANCEL' | translate: {Default: 'Cancel'} }}
      </button>
      <button mat-menu-item (click)="deleteAppointment()">
        <mat-icon>delete_forever</mat-icon>{{'DELETE' | translate: {Default: 'Delete'} }}
      </button>
    </mat-menu> -->
  </div>

  <mat-tab-group class="view-appointment-expanded" #matTabGroup *ngIf="!quickAppointment"
    (selectedIndexChange)="tabChange($event)">

    <mat-tab #tab label="{{'APPOINTMENT' | translate}}">

      <div *ngIf="appointment" fxLayout="row wrap" fxLayoutAlign=" center" class="mdc-px-3 mdc-mb-1">
        <div fxLayout="row" fxLayoutAlign=" center">
          <span class="icon-24 mat-text-secondary">
            <mat-icon>event</mat-icon>
          </span>
          <div class="date-time padding-left-half" fxLayout="column">
            <p class="mdc-mb-0">{{appointment.startDate | localizedDate: 'shortDate'}}</p>
            <p class="mat-text-secondary small">
              {{startTime | localizedDate:'shortTime'}} - {{endTime | localizedDate:'shortTime'}}
            </p>
          </div>
        </div>
        <span class="example-spacer"></span>
        <div fxFlex="50" class="mr-1 cancellation-reason" *ngIf="appointment?.cancellationReason">
          {{'CANCELLATION_REASON' | translate: {Default: 'Cancellation Reason: '} }} {{appointment.cancellationReason}}
        </div>
        <app-view-appointment-workflow-panel fxLayoutAlign=" center" [contextIdDto]="contextIdDto"
          [(appointment)]="appointment">
        </app-view-appointment-workflow-panel>
        <!-- <div fxFlex="nogrow">
          <button mat-button color="accent" (click)="edit()">
            <mat-icon>edit</mat-icon> {{'EDIT' | translate: {Default: 'EDIT'} }}
          </button>
        </div> -->
      </div>

      <mat-card appearance="outlined"
        *ngIf="appointment.paymentConfig?.paymentMode === 'PAYMENT_PLAN' && plan && plan.id && plan.status === 'PENDING_PAYMENT'"
        class="mdc-px-3 warning">
        <h6>
          <div><b>{{'PAYMENT_IS_PENDING' | translate: {Default: 'Payment is Pending'} }}</b></div>
        </h6>
        <div>{{plan.planName}}: {{plan.cost | currency:currencyCode:'symbol'}}</div>
      </mat-card>

      <div class="mdc-px-3 ">
        <mat-card appearance="outlined" class="mx-0 mdc-mb-1" *ngIf="showConfirmationDialog">
          <mat-card-header>
            <mat-icon class="close-confirm" (click)="closeConfirmDialog()">cancel</mat-icon>
          </mat-card-header>
          <mat-card-content>
            <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="10px" fxLayoutGap.gt-xs="16px"
              fxLayoutAlign.gt-xs=" center">
              <div fxFlex *ngIf="!showReviewUrlConfig" class="mdc-mb-2">
                <h6 *ngIf="!showCancellationReason">{{'NICE_PROGRESS' | translate: {Default: 'Nice Progress!'} }}</h6>
                <h6 *ngIf="showCancellationReason">{{'CONFIRMATION' | translate: {Default: 'Confirmation'} }}</h6>
                <p *ngIf="!showCancellationReason" class="small mat-text-secondary mb-0">
                  {{'CHANGED_STATUS_CONFIRM' | translate: {Default: 'You have changed status. By confirming Email/SMS
                  will be sent to user.'} }}
                </p>
                <p *ngIf="showCancellationReason" class="small mat-text-secondary mb-0">
                  {{'CANCEL_APPOINTMENT_CONFIRM_MESSAGE' | translate: {Default: 'Are you sure you want to cancel this
                  appointment?'} }}
                </p>
                <p class="small mat-text-secondary mb-0">
                  {{'CHANGE_OR_MODIFY_TEMPLATE' | translate: {Default: 'To change or modify template'} }} <a
                    class="text-underline" (click)="openEditTemplate()">{{'CLICK_HERE' | translate: {Default: 'Click
                    Here'} }}</a>.
                </p>

              </div>
              <div *ngIf="showContacts && contactList?.length" fxFlex class="mdc-mb-1">
                <mat-form-field appearance="outline" class="w-100 vm mb-0">
                  <mat-label>{{'CHOOSE_CONTACT' | translate: {Default: 'Choose Contact'} }}</mat-label>
                  <mat-select [(ngModel)]="contactToShare">
                    <ng-container *ngFor="let contact of contactList">
                      <mat-option *ngIf="contact?.mobilePhoneNumberObj?.internationalNumber"
                        [value]="contact.mobilePhoneNumberObj">
                        {{contact.mobilePhoneNumberObj?.internationalNumber}}
                        ({{contact.firstName}} {{contact.lastName}} - Mobile No)
                      </mat-option>
                      <mat-option *ngIf="contact?.phoneNumberObj?.internationalNumber" [value]="contact.phoneNumberObj">
                        {{contact.phoneNumberObj.internationalNumber}}
                        ({{contact.firstName}} {{contact.lastName}} - Phone No)
                      </mat-option>
                      <mat-option *ngIf="contact?.workPhoneNumberObj?.internationalNumber"
                        [value]="contact.workPhoneNumberObj">
                        {{contact.workPhoneNumberObj.internationalNumber}}
                        ({{contact.firstName}} {{contact.lastName}} - Work Phone No)
                      </mat-option>
                      <!-- <mat-option *ngIf="contact?.whatsAppNumberObj?.internationalNumber"
                        [value]="contact.whatsAppNumberObj">
                        {{contact.whatsAppNumberObj.internationalNumber}}
                        ({{contact.firstName}} {{contact.lastName}} - Whats App No)
                      </mat-option> -->
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngIf="showCancellationReason" fxFlex>
                <mat-form-field appearance="outline" class="w-100 vm">
                  <mat-label>{{'CANCELLATION_REASON' | translate: {Default: 'Cancellation Reason: '} }}</mat-label>
                  <textarea matInput [(ngModel)]="cancellationReason" [ngModelOptions]="{standalone: true}" rows="2"
                    required></textarea>
                </mat-form-field>
              </div>
              <div *ngIf="showAskForReview" fxFlex>
                <mat-slide-toggle *ngIf="!bypassReviewBooster" [(ngModel)]="askForReview"
                  (ngModelChange)="askForReviewChanged($event)" class="w-100 pr-1 vm" color="accent"
                  [checked]="askForReview">
                  {{'ASK_FOR_REVIEW' | translate: {Default: 'Ask For Review'} }}
                </mat-slide-toggle>
                <mat-checkbox [(ngModel)]="bypassReviewBooster" name="bypassReviewBooster"
                  (ngModelChange)="updateBypassReviewBooster($event)">
                  {{'NEVER_ASK_FOR_REVIEW' | translate: {Default: 'Never Ask For Review'} }}
                </mat-checkbox>
              </div>
              <div *ngIf="showReviewUrlConfig && askForReview" fxFlex="80" class="ml-1 mr-1">
                Please set at least one
                <div fxLayout="row" fxLayoutGap="5px">
                  <mat-form-field fxFlex appearance="outline" class="w-100">
                    <mat-label>{{'FACEBOOK_REVIEW_URL' | translate: {Default: 'Facebook Review URL'} }}</mat-label>
                    <input type="text" matInput
                      placeholder="{{'FACEBOOK_REVIEW_URL' | translate: {Default: 'Facebook Review URL'} }}"
                      [(ngModel)]="appointmentConfigHelperService.appointmentConfig.facebookReviewUrl"
                      (ngModelChange)="appointmentConfigHelperService.updateAppointmentConfig(contextIdDto, appointmentConfigHelperService.appointmentConfig)"
                      name="facebookReviewUrl">
                  </mat-form-field>
                  <mat-form-field fxFlex appearance="outline" class="w-100">
                    <mat-label>{{'GOOGLE_REVIEW_URL' | translate: {Default: 'Google Review URL'} }}</mat-label>
                    <input type="text" matInput
                      placeholder="{{'GOOGLE_REVIEW_URL' | translate: {Default: 'Google Review URL'} }}"
                      [(ngModel)]="appointmentConfigHelperService.appointmentConfig.googleReviewUrl"
                      (ngModelChange)="appointmentConfigHelperService.updateAppointmentConfig(contextIdDto, appointmentConfigHelperService.appointmentConfig)"
                      name="googleReviewUrl">
                  </mat-form-field>
                </div>
              </div>

              <div fxFlex="nogrow">
                <button mat-raised-button color="accent" (click)="confirmStateChange()">{{'CONFIRM' | translate:
                  {Default: 'Confirm'} }}</button>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <div *ngIf="appointment && tab.isActive">
          <app-detailed-pet-view #detailedPetViewComponent [appointment]="appointment" [contextIdDto]="contextIdDto"
            [start]="data?.start" [end]="data?.end" [currencyCode]="currencyCode"
            (viewHistoryEvent)="viewHistory($event)" (gotoPetEvent)="gotoPet($event)"
            (gotoCustomerEvent)="gotoCustomer($event)" (rebookEvent)="rebookEvent()" (closeEvent)="cancel()"
            (customerUpdated)="customerUpdated($event)" (petUpdated)="petUpdated($event)">
          </app-detailed-pet-view>
        </div>

      </div>
    </mat-tab>

    <mat-tab #tab1 *ngIf="contextService.appType === 'GROOM_SCHOOL' && contextIdDto" label="Students">
      <app-view-appointment-students #viewStudents *ngIf="appointment && tab1.isActive" [contextIdDto]="contextIdDto"
        [appointment]="appointment">
      </app-view-appointment-students>
    </mat-tab>

    <mat-tab #tab2 label="{{'HISTORY' | translate}}">
      <div *ngFor="let appointmentServiceRow of uniqueServices">
        <h6 class="mdc-px-2 mb-0">{{'HISTORY_OF' | translate}} {{appointmentServiceRow.eiLabel}}</h6>
        <app-view-v1-v2-pet-history #viewV1V2PetHistoryComponent
          *ngIf="appointment && appointmentServiceRow.eiId && tab2.isActive" [isViewMode]="true"
          [contextIdDto]="contextIdDto" [petId]="appointmentServiceRow.eiId" [addAppointmentAllowed]="false"
          [historyMode]="'past'" [showVaccinations]="true">
        </app-view-v1-v2-pet-history>
      </div>
    </mat-tab>

    <mat-tab #tab3 label="{{'MESSAGES' | translate}}">
      <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="15px" class="mdc-px-3 mdc-mb-2">
        <div fxFlex>
          <mat-button-toggle-group [(ngModel)]="messageCategory" name="messageCategory" aria-label="Message Categories"
            class="button-group-payments">
            <mat-button-toggle value="messages">{{'MESSAGES' | translate: {Default: 'Messages'} }}</mat-button-toggle>
            <mat-button-toggle value="reminders">{{'REMINDERS' | translate: {Default: 'Reminders'}
              }}</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div fxFlex="nogrow">
          <button mat-raised-button class="w-100" color="accent" (click)="createMessage()"
            *ngIf="appointment && messageCategory === 'messages' && tab3.isActive">
            <mat-icon>add</mat-icon>
            Create Message
          </button>
        </div>
      </div>
      <mat-divider></mat-divider>
      <app-view-messages-v2 *ngIf="appointment && messageCategory === 'messages' && tab3.isActive"
        [contextIdDto]="contextIdDto" [appointment]="appointment">
      </app-view-messages-v2>
      <app-view-reminders-v2 *ngIf="appointment && messageCategory === 'reminders' && tab3.isActive"
        [contextIdDto]="contextIdDto" [appointment]="appointment">
      </app-view-reminders-v2>
    </mat-tab>

    <mat-tab #tab4 label="{{'CONTRACTS' | translate}}"
      *ngIf="featuresHelperService.isFeatureAllowed(featureType.Contracts)">
      <app-view-appointment-contracts *ngIf="appointment && tab4.isActive" [contextIdDto]="contextIdDto"
        [customerId]="appointment.customerId">
      </app-view-appointment-contracts>
    </mat-tab>

    <mat-tab #tab5 label="{{'PAYMENT' | translate}}">
      <div fxLayout="row" fxLayoutAlign="center center" class="mdc-px-3 mdc-mb-2">
        <mat-button-toggle-group [(ngModel)]="paymentCategory" name="paymentCategory" aria-label="Payment Categories"
          class="button-group-payments">
          <mat-button-toggle value="amount">{{'AMOUNT' | translate: {Default: 'Amount'} }}</mat-button-toggle>
          <mat-button-toggle value="deposits">{{'DEPOSITS' | translate: {Default: 'Deposits'} }}</mat-button-toggle>
          <mat-button-toggle value="payments">{{'PAYMENTS' | translate: {Default: 'Payments'} }}</mat-button-toggle>
          <mat-button-toggle value="tips">{{'TIPS' | translate: {Default: 'Tips'} }}</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <app-view-customer-invoice *ngIf="appointment  && paymentCategory === 'amount' && tab5.isActive"
        [appointment]="appointment" [contextIdDto]="contextIdDto" [invoiceId]="appointment.invoiceId?.id"
        (invoiceUpdated)="invoiceUpdated($event)" (editAppointment)="edit()">
      </app-view-customer-invoice>
      <app-view-deposit-requests *ngIf="appointment && paymentCategory === 'deposits' && tab5.isActive"
        [contextIdDto]="contextIdDto" [invoiceId]="appointment.invoiceId?.id"
        [requestForPaymentOwnerId]="appointment.customerId" [requestForPaymentOwnerType]="'CUSTOMER'">
      </app-view-deposit-requests>
      <app-view-customer-payments *ngIf="appointment  && paymentCategory === 'payments' && tab5.isActive"
        [contextIdDto]="contextIdDto" (invoiceUpdated)="invoice = $event" [visible]="true"
        [invoiceId]="appointment.invoiceId?.id">
      </app-view-customer-payments>
      <app-view-customer-tips *ngIf="appointment  && paymentCategory === 'tips' && tab5.isActive"
        [contextIdDto]="contextIdDto" (invoiceUpdated)="invoice = $event" [visible]="true"
        [invoiceId]="appointment.invoiceId?.id">
      </app-view-customer-tips>
    </mat-tab>

    <mat-tab #tab6 label="{{'CHECK_IN' | translate}}"
      *ngIf="featuresHelperService.isFeatureAllowed(featureType.CheckinForms)">
      <app-view-checkin-forms *ngIf="appointment && tab6.isActive" [contextIdDto]="contextIdDto"
        [customerId]="appointment.customerId" [appointmentId]="appointment.id">
      </app-view-checkin-forms>
    </mat-tab>

    <mat-tab #tab7 label="{{'PHOTOS' | translate}}">
      <app-view-appointment-photos *ngIf="appointment && tab7.isActive" [contextIdDto]="contextIdDto"
        [appointment]="appointment" (appointmentUpdated)="appointmentUpdated($event)">
      </app-view-appointment-photos>
    </mat-tab>
    <mat-tab #tab8 label="{{'LOGS' | translate}}">
      <app-appointment-audit-logs *ngIf="appointment && tab8.isActive"
        [appointmentId]="appointment.id"></app-appointment-audit-logs>
    </mat-tab>
  </mat-tab-group>

  <div fxLayout="row" fxLayoutGap="6px" class="mdc-px-3">
    <!-- <div fxLayout="row" fxLayoutGap="6px" *ngIf="deviceService.isMobile() || deviceService.isTablet()" class="mdc-px-3"> -->
    <div fxFlex>
      <button mat-stroked-button color="accent" class="mdc-mb-1 text-center mdc-p-2 w-100 h-auto mat-mdc-card-outlined"
        (click)="cancel()">
        <div>
          <mat-icon>arrow_back</mat-icon><br>
          <p class="fs-12">{{'Back' | translate: {Default: 'Back'} }}</p>
        </div>
      </button>
    </div>
    <div fxFlex>
      <button mat-stroked-button color="accent" class="mdc-mb-1 text-center mdc-p-2 w-100 h-auto mat-mdc-card-outlined"
        (click)="edit()">
        <div>
          <mat-icon>edit</mat-icon><br>
          <p class="fs-12">{{'EDIT' | translate: {Default: 'Edit'} }}</p>
        </div>
      </button>
    </div>

    <div fxFlex>
      <button mat-stroked-button color="accent" class="mdc-mb-1 text-center mdc-p-2 w-100 h-auto mat-mdc-card-outlined"
        (click)="rebookEvent()">
        <div>
          <mat-icon>repeat</mat-icon><br>
          <p class="fs-12">{{'REBOOK' | translate: {Default: 'Re-book'} }}</p>
        </div>
      </button>
    </div>

    <div fxFlex *ngIf="appointment.status !== 'Cancelled'">
      <button mat-stroked-button color="accent" class="mdc-mb-1 text-center mdc-p-2 w-100 h-auto mat-mdc-card-outlined"
        (click)="cancelAppointment()">
        <div>
          <mat-icon>cancel</mat-icon><br>
          <p class="fs-12">{{'CANCEL' | translate: {Default: 'Cancel'} }}</p>
        </div>
      </button>
    </div>
    <div fxFlex>
      <button mat-stroked-button color="accent" class="mdc-mb-1 text-center mdc-p-2 w-100 h-auto mat-mdc-card-outlined"
        (click)="deleteAppointment()">
        <div>
          <mat-icon>delete_forever</mat-icon><br>
          <p class="fs-12">{{'DELETE' | translate: {Default: 'Delete'} }}</p>
        </div>
      </button>
    </div>

  </div>

</div>

<!-- <div fxLayout="row" class="mdc-px-3 ">
  <div fxFlex></div>

  <button mat-raised-button class="mat-text-secondary" *ngIf="deviceService.isMobile() || deviceService.isTablet()"
    type="button" (click)="cancel()">
    Close
  </button>
</div> -->
<!-- </mat-card-content>
</mat-card> -->
