<div *ngIf="!eventBus">
  TOOLBAR:EVENT BUS IS EMPTY
</div>
<mat-toolbar color="{{toolBarDto.colour}}" class="demo-toolbar"
  *ngIf="toolBarDto.toolBarRowDtoList && toolBarDto.toolBarRowDtoList.length">
  <mat-toolbar-row *ngFor="let row of toolBarDto.toolBarRowDtoList">

    <app-breadcrumb *ngIf="toolBarDto.showCrumbs"></app-breadcrumb>

    <ng-container *ngIf="row.elementDtoList && row.elementDtoList.length">
      <div *ngFor="let elementDto of row.elementDtoList" [class.example-spacer]="elementDto.elementType === 'SPACE'">

        <div *ngIf="elementDto.elementType === 'STRING_VALUE'">{{elementDto.sttringValueDto.strValue | translate}}</div>

        <button (click)="view()" class="ml-xs mat-icon-button" mat-icon-button
          *ngIf="elementDto.elementType === 'ICON_DEFINITION' && elementDto.iconDefinitionDto.iconTypeDto === 'VIEW'">
          <mat-icon role="img" aria-label="edit">view_list</mat-icon>
        </button>

        <button (click)="edit()" class="ml-xs mat-icon-button" mat-icon-button
          *ngIf="elementDto.elementType === 'ICON_DEFINITION' && elementDto.iconDefinitionDto.iconTypeDto === 'EDIT'">
          <mat-icon role="img" aria-label="edit">edit</mat-icon>
        </button>

        <button (click)="delete()" class="ml-xs mat-icon-button" mat-icon-button
          *ngIf="elementDto.elementType === 'ICON_DEFINITION' && elementDto.iconDefinitionDto.iconTypeDto === 'DELETE'">
          <mat-icon role="img" aria-label="delete">delete</mat-icon>
        </button>
      </div>
    </ng-container>

  </mat-toolbar-row>


</mat-toolbar>
