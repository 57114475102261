<mat-toolbar color="primary">
  <mat-toolbar-row>
    <h2>{{ plan.planName }}</h2>
  </mat-toolbar-row>
</mat-toolbar>

<br>
<div class="plan-summary" *ngIf="plan.id">

  <table mat-table [dataSource]="summaryDetails" class="mat-elevation-z8">

    <ng-container matColumnDef="customerFullName">
      <th mat-header-cell *matHeaderCellDef class="mat-header-cell">{{'CUSTOMER_NAME' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="mat-column"> {{element.customerFullName}} </td>
    </ng-container>

    <ng-container matColumnDef="customerEmail">
      <th mat-header-cell *matHeaderCellDef class="mat-header-cell">{{'EMAIL' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="mat-column"> {{element.customerEmail}} </td>
    </ng-container>
    <!-- Position Column -->
    <ng-container matColumnDef="startDate">
      <th mat-header-cell *matHeaderCellDef class="mat-header-cell">{{'START_DATE' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="mat-column"> {{element.startDate}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="endDate">
      <th mat-header-cell *matHeaderCellDef class="mat-header-cell">{{'END_DATE' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="mat-column"> {{element.endDate}}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef class="mat-header-cell">{{'STATUS' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="mat-column">
        {{element.status}}<br>
        <mat-hint *ngIf="element.status === 'Pending Payment'">{{'DELETING_PAYMENT_WILL_EXPIRE' | translate}}</mat-hint>
        <mat-hint *ngIf="element.status === 'Payment Failed'">{{'PLAN_CAN_PERM_DELETED' | translate}}</mat-hint>
        <mat-hint *ngIf="element.status === 'Active'">{{'FORCE_EXPIRED_PLAN' | translate}}</mat-hint>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="mat-header-cell"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="mat-header-cell"></tr>
  </table>

  <div class="plan-details">
    <form #planDefForm="ngForm">

      <mat-hint>{{'UPDATED_COST_HINT' | translate}}</mat-hint>
        <mat-form-field style="width: 100%" appearance="outline">
          <mat-label>{{'PLAN_COST'| translate}}</mat-label>
          <input
            matInput
            [(ngModel)]="updateCostReq.amount" name="cost"
            #cost="ngModel"
            id="cost"
            type="number"
            placeholder="{{'COST' | translate}}"
            style="width: 100%" required planName>
          <mat-error *ngIf="cost.hasError('required')">
            {{'COST_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
          </mat-error>
        </mat-form-field>

      <button mat-raised-button color="accent" *ngIf="updateCostReq.planId" (click)="updateCost()"
              [disabled]="!planDefForm.form.valid"
              disabled="disabled">{{'UPDATE' | translate}}
      </button>
    </form>

<!--    <mat-hint *ngIf="plan.balance">You remaining balance is <b>{{ plan.balance}}</b><br></mat-hint>-->
    <mat-hint *ngIf="plan.nextBillingDate">{{'NEXT_BILLING_DATE' | translate}} <b style="color: darkred">{{ plan.nextBillingDate}}</b><br></mat-hint>
    <mat-hint *ngIf="plan.paymentSchedule === 'RECURRING'">
      {{'PLAN_IS_PAID' | translate}} <b>&#163; {{plan.cost}} </b> {{'EVERY' | translate}}
      <span *ngIf="plan.paymentInterval === 'MONTHLY'"> {{'MONTH' | translate}}.</span>
      <span *ngIf="plan.paymentInterval === 'ANNUALLY'"> {{'YEAR' | translate}}.</span>
      <br>
    </mat-hint>
    <mat-hint *ngIf="plan.numberOfSessions"><b>{{ plan.numberOfSessions }}</b> {{'SESSIONS_REMAINING' | translate}}.<br></mat-hint>
    <mat-hint>{{'PLAN_USED' | translate}} <b>{{ plan.numberOfSessionsUsed }}</b> {{'NUMBER_OF_TIMES' | translate}}.<br></mat-hint>
    <mat-hint *ngIf="plan.unlimitedSessions">{{'CUSTOMER_USE_UNLIMITED_TIMES' | translate}}.<br></mat-hint>
  </div>

  <br><br>
  <mat-tab-group>
    <mat-tab label="{{'PAYMENTS_TAKEN' | translate}}">
      <mat-card appearance="outlined">
        <mat-card-title>{{'PAYMENTS_TAKEN_HINT' | translate}}</mat-card-title>
        <ngx-datatable
          class='material has-plus-btn'
          [columnMode]="'force'"
          [headerHeight]="50"
          [footerHeight]="50"
          [rowHeight]="'auto'"
          [rows]="plan.paymentsTaken"
        >

          <ngx-datatable-column name="{{'DATE_PAID' | translate}}">
            <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
              {{ row.paymentDate | localizedDate: 'mediumDate' }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'REFERENCE' | translate}}">
            <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
              {{ row.paymentReference }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'AMOUNT' | translate}}">
            <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
              {{row.amount}}
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </mat-card>
    </mat-tab>
    <mat-tab label="{{'PAYMENTS_SPENT' | translate}}">
      <mat-card appearance="outlined">
        <mat-card-title>{{'APPT_WHERE_PAYMENTS_USED' | translate}}</mat-card-title>
        <ngx-datatable
          class='material has-plus-btn'
          [columnMode]="'force'"
          [headerHeight]="50"
          [footerHeight]="50"
          [rowHeight]="'auto'"
          [rows]="plan.paymentsSpent"
        >

          <ngx-datatable-column name="{{'DATE_PAID' | translate}}">
            <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
              {{ row.paymentDate | localizedDate: 'mediumDate' }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'APPOINTMENT' | translate}}">
            <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
              {{ row.appointmentSummary }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'AMOUNT' | translate}}">
            <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
              {{row.amount}}
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </mat-card>
    </mat-tab>
  </mat-tab-group>

  <br>

    <button *ngIf="plan.status === 'PAYMENT_FAILED' || plan.status === 'PENDING_PAYMENT'" (click)="delete()" mat-raised-button color="accent">{{'DELETE' | translate}}</button>

<!--    <button *ngIf="plan.status === 'ACTIVE'" (click)="forceExpire()" mat-raised-button color="accent">{{'Force Expire' | translate}}</button> &nbsp;-->
    <button *ngIf="plan.status === 'ACTIVE'" (click)="takePayment()" mat-raised-button color="accent">{{'TAKE_PAYMENT' | translate}}</button>
    &nbsp;
    <button mat-raised-button (click)="dialogRef.close(false)">{{'CANCEL' | translate}}</button>

</div>
