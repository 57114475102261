<div fxLayout="column">
  <br>
  <h3><strong>{{'HOW_RECEIVE_PAYMENT' | translate}}</strong></h3>
  <hr>
  <br>

  <div fxLayout="row wrap">
    <mat-radio-group [ngModel]="paymentMode" (ngModelChange)="paymentModeChanged($event)" class="example-radio-group">
      <mat-radio-button class="example-radio-button" value="NP">
        {{'NO_UPFRONT_PAYMENT_REQUIRED' | translate}}</mat-radio-button>
      <mat-radio-button class="example-radio-button" value="RD">
        {{'REQUEST_DEPOSIT' | translate}}</mat-radio-button>
      <mat-radio-button class="example-radio-button" value="FP">
        {{'REQUEST_FULL_PAYMENT_NOW' | translate}}</mat-radio-button>
      <mat-radio-button class="example-radio-button" value="PS">
        {{'SETUP_PAYMENT_SCHEDULE' | translate}}</mat-radio-button>
    </mat-radio-group>
  </div>

  <!--h3><strong>Would you like to ask for the payment up front?</strong></h3>
  <hr>
  <br>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="7px" fxLayoutGap.xs="0px" fxFlexFill
    fxLayoutAlign.gt-sm="left">
    <mat-radio-group fxFlex [(ngModel)]="askForPayment">
      <mat-radio-button value="YES">{{'Yes' | translate}}</mat-radio-button>&nbsp;&nbsp;
      <mat-radio-button value="NO">{{'No' | translate}}</mat-radio-button>&nbsp;&nbsp;
    </mat-radio-group>
  </div>
  <br>
  <div fxLayout="column wrap" *ngIf="askForPayment == 'YES'" style="max-width: 40%;">
    <mat-radio-group fxFlex="62" [ngModel]="paymentMode" (ngModelChange)="paymentModeChanged($event)">
      <mat-radio-button value="PS">{{'Setup a payment schedule' | translate}}</mat-radio-button>
      &nbsp;&nbsp;
      <mat-radio-button value="RD">{{'Request deposit now' | translate}}</mat-radio-button>&nbsp;&nbsp;
      <mat-radio-button value="FP">{{'Request full payment now' | translate}}</mat-radio-button>
      &nbsp;&nbsp;
    </mat-radio-group>
  </div>
  <br-->

  <mat-card appearance="outlined" *ngIf="paymentMode === 'RD'">
    <mat-card-content>
      <!-- TODO: PUT SAME DEPOSIT REQUEST FIELDS IN HERE THAT WE SHOW WHEN WE CREATE AN APPOINTMENT
      Just ask them what the deposit amount is, then server side we can create the payment requests
      (ie. one for deposit) -->
      <app-payment-deposit-request #paymentDepositRequestComponent></app-payment-deposit-request>
    </mat-card-content>
  </mat-card>
  <br>

  <!-- <app-course-booking-invoice [invoiceId]="invoiceId" [viewOnly]="true"
  [contextIdDto]="contextIdDto" [showOnlySummary]="true">
</app-course-booking-invoice> -->

  <mat-card appearance="outlined" *ngIf="paymentMode === 'PS'">
    <mat-card-header>
      <h4><strong>{{'PAYMENT_SCHEDULE' | translate}}</strong></h4>
    </mat-card-header>
    <mat-card-content fxLayout="column">
      <br>
      <div fxFlex="100" fxLayout="column">
        <mat-radio-group fxFlex [(ngModel)]="paymentType">
          <mat-radio-button value="AMOUNT">{{'AMOUNT' | translate}}</mat-radio-button>&nbsp;&nbsp;
          <mat-radio-button value="PERCENTAGE">{{'PERCENTAGE' | translate}}</mat-radio-button>&nbsp;&nbsp;
        </mat-radio-group>
      </div>
      <div fxFlex="100" fxLayout="column">
        <app-pending-amount *ngIf="paymentSchedules && paymentSchedules.length && paymentSchedules[0].id" [contextIdDto]="contextIdDto" [paymentRequests]="paymentSchedules" [totalAmount]="invoice.total"></app-pending-amount>


        <ngx-datatable *ngIf="paymentSchedules && paymentSchedules.length" class='material' [rows]='paymentSchedules' [loadingIndicator]="loading" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'">

          <ngx-datatable-column name="{{'AMOUNT' | translate}}" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-schedule="row" let-rowIndex="rowIndex">
              <mat-form-field *ngIf="paymentType === 'AMOUNT'" appearance="outline" style="width: 80%;">
                <div matSuffix>{{ currencyCode }}</div>
                <mat-label>{{'AMOUNT' | translate}}</mat-label>
                <input type="text" appNumberOnly [decimalPoints]="2" matInput name="amount" placeholder="{{'AMOUNT' | translate}}" [(ngModel)]="schedule.paymentAmountInPence" (change)="scheduleRequestForPayment(schedule)">
              </mat-form-field>
              <mat-form-field *ngIf="paymentType === 'PERCENTAGE'" appearance="outline" style="width: 80%;">
                <div matSuffix>%</div>
                <mat-label>{{'PERCENTAGE' | translate}}</mat-label>
                <input type="text" appNumberOnly matInput name="percentage" placeholder="{{'PERCENTAGE' | translate}}" [(ngModel)]="schedule.percentOfTotal" (change)="scheduleRequestForPayment(schedule)">
              </mat-form-field>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'DATE_TO_SEND' | translate}}" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-schedule="row" let-rowIndex="rowIndex">
              <mat-form-field appearance="outline" style="width: 10vw;">
                <mat-label>{{'DATE_TO_SEND' | translate}}</mat-label>
                <input matInput [(ngModel)]="schedule.dateToSend" [matDatepicker]="picker" required (focus)="picker.open()" (click)="picker.open()" placeholder="{{'DATE_TO_SEND' | translate}}" [min]="minRange" [max]="schedule.dueDate" (dateChange)="scheduleRequestForPayment(schedule)">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'DUE_DATE' | translate}}" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-schedule="row" let-rowIndex="rowIndex">
              <mat-form-field appearance="outline" style="width: 10vw;">
                <mat-label>{{'DUE_DATE' | translate}}</mat-label>
                <input matInput [(ngModel)]="schedule.dueDate" [matDatepicker]="dueDate" required (focus)="dueDate.open()" (click)="dueDate.open()" placeholder="{{'DUE_DATE' | translate}}" (dateChange)="scheduleRequestForPayment(schedule)" [disabled]="paymentMode === 'RD'">
                <mat-datepicker-toggle matSuffix [for]="dueDate"></mat-datepicker-toggle>
                <mat-datepicker #dueDate></mat-datepicker>
                <!-- <input matInput placeholder="" [(ngModel)]="schedule.dueDate"> -->
              </mat-form-field>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'ACTION' | translate}}" [sortable]="false" *ngIf="paymentSchedules.length > 1">
            <ng-template ngx-datatable-cell-template let-schedule="row" let-rowIndex="rowIndex">
              <mat-icon class="delete-icon" (click)="removeSchedule(rowIndex)">delete</mat-icon>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>

        <button style="width: 30%;" mat-raised-button color="accent" *ngIf="paymentMode === 'PS'" (click)="addSchedule()" [disabled]="disableAddingMore">{{'ADD_PAYMENT_REQ' | translate}}</button>&nbsp;&nbsp;

      </div>
    </mat-card-content>
  </mat-card>


</div>