import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ElementControlService } from '../ElementControlService';
import {ContextIdDto, EicompService, ElementInstanceDto, IdNameTupleDto, LinkedEntityDefinitionDto} from '@savvy/entity-instance-composite';
import { ChangeListener } from '../changeListener';
import { AddEntityDialogComponent } from './addEntityDialog.component';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { EventBusService } from '../EventBusService';
import {CachedlistcompService, LoadEiTypeaheadResponse} from '@savvy/cached-view';
import { ClearObservable } from '../../shared/classes/clear-observable';
import { takeUntil } from 'rxjs/operators';
import { EntityChangeEvent, FieldValueDto } from '@savvy/entity-instance-composite';

@Component({
  selector: 'app-linked-entity-instance-single-typeahead',
  templateUrl: 'linkedEntityInstanceSingleTypeahead.component.html'
})

export class LinkedEntityInstanceSingleTypeaheadComponent extends ClearObservable implements OnInit {

  @Input() linkedEntityDefinition: LinkedEntityDefinitionDto;
  @Input() contextIdDto: ContextIdDto;
  @Input() elementInstanceDto: ElementInstanceDto;
  @Input() entityInstanceId: string;
  @Input() additionalDataMapDto: any;


  @Input() form: UntypedFormGroup;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;

  eventBus = new EventBusService();
  entityInstanceIdFieldValueTupleDtos: IdNameTupleDto[] = [];
  private timeout;
  constructor(
    private breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
    private cachedListCompositeApi: CachedlistcompService,
    private eiCompositeApi: EicompService,
    private ecs: ElementControlService) {
    super();
  }

  ngOnInit() {
    this.getPlaceholder();


    if (this.form.controls[this.elementInstanceDto.instanceId] && this.form.controls[this.elementInstanceDto.instanceId].value) {
      if (this.isString(this.form.controls[this.elementInstanceDto.instanceId].value)) {
        this.loadTypeaheadAndSet(this.form.controls[this.elementInstanceDto.instanceId].value);
      }
    }
  }

  isString(value) {
    return typeof value === 'string' || value instanceof String;
  }


  displayFn(id) {
    if (!id) {
      return '';
    }
    const index = this.entityInstanceIdFieldValueTupleDtos.findIndex(state => state.id === id);
    if (index === -1) {
      console.log('id is ' + id + ' index is' + index + ' this should never happen as tuples should always be there first');
      return '';
    } else {
      return this.entityInstanceIdFieldValueTupleDtos[index].name;
    }
  }

  private loadTypeaheadAndSet(entityInstanceId: string) {
    console.log('loadTypeaheadAndSet2 entityInstanceId', entityInstanceId);
    console.log('loadTypeaheadAndSet2 linkedEntityDefinition.name', this.linkedEntityDefinition.name);
    console.log('this.linkedEntityDefinition.targetEntityDefinitionId', this.linkedEntityDefinition.targetEntityDefinitionId);
    this.cachedListCompositeApi.loadSingleEiTypeahead(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      entityInstanceId).pipe(takeUntil(this.destroy$)).subscribe(response => {
        if (response.entityInstanceIdFieldValueTupleDto) {
          this.entityInstanceIdFieldValueTupleDtos = [];
          this.entityInstanceIdFieldValueTupleDtos[0] = response.entityInstanceIdFieldValueTupleDto;
          this.form.controls[this.elementInstanceDto.instanceId].patchValue(response.entityInstanceIdFieldValueTupleDto.id);
        } else {
          console.log('error, expecting an id on ', response.entityInstanceIdFieldValueTupleDto);
        }
      });
  }

  loadTypeahead(searchString: string) {
    if (searchString && searchString.length > 0) {
      this.cachedListCompositeApi.listForTypeahead(
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.linkedEntityDefinition.targetEntityDefinitionId,
        searchString).pipe(takeUntil(this.destroy$)).subscribe(
          result => {
            console.log('got loadProductsResponse ', result);
            this.setResponse(result);
          }, err => {
            console.log('error while loadTypeahead', err);
          });
    }
  }

  setResponse(result: LoadEiTypeaheadResponse) {
    this.entityInstanceIdFieldValueTupleDtos = result.entityInstanceIdFieldValueTupleDtos;
  }


  onKey(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.form.controls[this.elementInstanceDto.instanceId].setErrors({ doesNotSelected: true });
      this.loadTypeahead(event.target.value);
    }, 500);
  }

  createUpdateReq(linkedId: string): EntityChangeEvent {

    const singleStrArr = [];
    singleStrArr.push(linkedId);

    const fieldValue: FieldValueDto = {
      strArrayValue: singleStrArr,
      valueType: FieldValueDto.ValueTypeEnum.StringArray
    };

    const res = <EntityChangeEvent>{
      contextIdDto: this.contextIdDto,
      fieldValue,
      entityInstanceId: this.entityInstanceId,
      fieldInstanceId: this.elementInstanceDto.instanceId
    };
    return res;
  }

  changedSelection(tupleId: string) {
    if (tupleId) {
      const req = this.createUpdateReq(tupleId);

      this.eiCompositeApi.entityInstanceChangeEvent(req)
        .pipe(takeUntil(this.destroy$)).subscribe(response => {
          this.ecs.handleChangedElements(response.changedElementList, this.form);
          if (this.changeListener) {
            this.changeListener.add('change');
          }
        }, error => {
          console.log('error while changedSelection', error);
        });
    }
  }

  createNewLinkedEntity() {
    const dialogRef = this.dialog.open(AddEntityDialogComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        entityDefinitionId: this.linkedEntityDefinition.targetEntityDefinitionId,
        entityDefinitionLabel: this.linkedEntityDefinition.label,
        eventBus: this.eventBus
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.entityInstanceId) {
        console.log('got a new instance to link to ' + result.entityInstanceId);
        this.elementInstanceDto.fieldInstanceDto.value.strValue = result.entityInstanceId;
        this.updateAndReload(result.entityInstanceId);
      }
    });
  }


  updateAndReload(entityInstanceId: string) {
    this.eiCompositeApi.entityInstanceChangeEvent(this.createUpdateReq(entityInstanceId))
      .pipe(takeUntil(this.destroy$)).subscribe(
        response => {
          this.ecs.handleChangedElements(response.changedElementList, this.form);
          if (this.changeListener) {
            this.changeListener.add('change');
          }
          this.loadTypeaheadAndSet(entityInstanceId);
        }, err => {
          console.log('error while updateAndReload', err);
        });
  }

  getPlaceholder() {
    this.linkedEntityDefinition.placeholder = this.showPlaceholder ? this.linkedEntityDefinition.placeholder : '';
  }
}

