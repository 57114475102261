import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ChangeListener } from './changeListener';
import { EventBusService } from './EventBusService';
import { Router } from '@angular/router';
import { NavigateService } from '../entry/NavigateService';
import { TrialExpiredService } from '../shared/services/trial-expired.service';
import { CachedlistcompService } from '@savvy/cached-view';
import { ViewInvoiceEntitiesExpandableData } from '@savvy/view-definition';
import { ContextIdDto } from '@savvy/quickbooks';

@Component({
  selector: 'app-view-expandable-invoice-entities',
  templateUrl: 'viewExpandableInvoiceEntities.component.html'
})
export class ViewExpandableInvoiceEntitiesComponent implements OnInit {

  @Input() viewInvoiceEntitiesExpandableData: ViewInvoiceEntitiesExpandableData;
  @Input() contextIdDto: ContextIdDto;
  @Input() viewContext: any;
  @Input() form: UntypedFormGroup;
  @Input() eventBus: EventBusService;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;


  response: any;

  step = 0;

  columns = [
    { prop: 'displayText', name: '' }
  ];

  constructor(
    private router: Router,
    private naviateService: NavigateService,
    public trialExpiredService: TrialExpiredService,
    private cachedListCompositeApi: CachedlistcompService) {
  }
  ngOnInit() {
    this.loadPage();
  }

  loadPage() {
    // Call list display names and pass page number
    console.log('loading ' + this.viewInvoiceEntitiesExpandableData.entityInstanceIdsCommarSep);
    this.cachedListCompositeApi.loadEisTypeahead(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      this.viewInvoiceEntitiesExpandableData.entityInstanceIdsCommarSep,
      this.viewInvoiceEntitiesExpandableData?.viewInvoiceEntitiesExpandableDefinition?.listCachedEntityListDefinition?.entityDefinitionId?.id).subscribe(response => {
        this.response = response;
        console.log('got response:' + response.entityInstanceIdFieldValueTupleDtos);
      });
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
}

