import { Component, Injector, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ContextIdDto } from '@savvy/quickbooks';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { NavChangeService } from '../element/NavChangeService';
import { EventBusService } from '../element/EventBusService';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { PhoneNumberHelperService } from '../shared/services/phone-number-helper.service';
import { Subject } from 'rxjs';
import { Payment, PaymentService } from '@savvy/payment';
import { Location } from '@angular/common';
import { WindowRef } from '../windowWrapper';
import { UrlLookupService } from '../shared/services/urlLookup.service';
import { InvoicecompService, InvoiceDto } from '@savvy/invoice';
import { DiscountItem, DiscountSettings, DiscountSettingsService, Invoice, InvoiceService } from '@savvy/invoice';
import { GLOBAL } from 'src/app/app.constants';
import { UserCurrencyService } from '../shared/services/userCurrency.service';

@Component({
  selector: 'app-view-invoice-basic',
  templateUrl: './viewInvoiceBasic.component.html',
  styleUrls: ['./viewInvoiceBasic.component.scss']
})
export class ViewInvoiceBasicComponent implements OnInit, OnDestroy, OnChanges {
  invoiceDto: InvoiceDto = <InvoiceDto>{};
  invoice: Invoice = <Invoice>{};
  show: string;
  destroy$ = new Subject();
  model: Payment;
  isPaidFull = false;
  currencyCode: string;

  @Input() invoiceId: string;
  @Input() eventBus: EventBusService;
  @Input() contextIdDto: ContextIdDto;

  discountSettings: DiscountSettings;
  discountItemsById: { [key: string]: DiscountItem } = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private invoiceApi: InvoiceService,
    private api: InvoicecompService,
    private navChange: NavChangeService,
    private translateService: TranslateService,
    private notify: FloSnackbarComponent,
    private dialog: MatDialog,
    private windowRef: WindowRef,
    private injector: Injector,
    private urlLookupService: UrlLookupService,
    private sharedService: PhoneNumberHelperService,
    private paymentApi: PaymentService,
    private location: Location,
    private discountSettingsService: DiscountSettingsService,
    private userCurrencyService: UserCurrencyService) {
  }


  ngOnInit() {
    console.log(1, this.contextIdDto);
    this.getCurrencyCode();
    console.log('loading invoice ' + this.invoiceId);
    this.loadInvoice();
    this.loadDiscounts();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.invoiceId) {
      console.log('loading invoice: ', this.invoiceId);
      console.log('changes.invoiceId is: ', changes.invoiceId);
      this.loadInvoice();
    }
  }

  public loadInvoiceById(invoiceId: string) {
    this.invoiceId = invoiceId;
    this.loadInvoice();
  }



  getCurrencyCode() {
    this.userCurrencyService.getDefaultCurrency(this.contextIdDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.currencyCode = res.org.currencyCode ? res.org.currencyCode : this.userCurrencyService.defaultCurrency;
      });
  }

  viewInvoice() {
    const url = this.urlLookupService.invoiceUrl() + '?id=' + this.contextIdDto.contextId + this.getEnv() + '&invoiceId=' + this.invoice.id;
    const nativeWindow = this.injector.get(WindowRef).nativeWindow;
    nativeWindow.open(url);
  }



  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }


  backToList() {
    this.location.back();
  }


  public gotoInvoice() {
    this.router.navigate(['/invoice/viewInvoice',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      this.invoiceId
    ]);
  }

  private loadInvoice() {
    if (this.invoiceId) {
      this.api.loadInvoiceForView(
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.invoiceId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          response => {
            console.log('loaded invoice dto', response.invoiceDto);
            this.invoiceDto = response.invoiceDto;
            // @ts-ignore
            this.invoice = response.invoiceDto.invoice;
            this.isPaidFull = this.invoice.paidInFull;
            this.model = <Payment>{};
            this.model.amount = this.invoice.total;
          });
    } else {
      this.notify.message = 'Invoice Id does not exist';
      this.notify.open();
    }
  }

  private getEnv() {
    if (GLOBAL.localhost === 'localhost') {
      return '&env=local';
    } else if (GLOBAL.localhost.startsWith('dev')) {
      return '&env=dev';
    }
    return '';
  }

  loadDiscounts() {
    this.discountSettingsService
      .get(this.contextIdDto.contextId, this.contextIdDto.contextIdType)
      .subscribe(res => {
        this.discountSettings = res;
        if (this.discountSettings && this.discountSettings.discountItems) {
          this.discountSettings.discountItems.forEach(r => {
            this.discountItemsById[r.id] = r;
          });
        }
      });
  }


}
