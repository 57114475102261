<mat-form-field appearance="outline" style="width: 100%">
  <mat-label>{{'PET_NAME' | translate}}</mat-label>
  <input matInput #searchInput="matInput" placeholder="{{'START_TYPING_SEARCH_PETS' | translate}}" (keyup)="onKey($event)" autocomplete="off" type="text" [matAutocomplete]="auto">
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)" (optionSelected)="handler($event)">
    <mat-option disabled *ngIf="pets && !pets.length" value="">
      {{'PET_NOT_FOUND' | translate}}
    </mat-option>
    <mat-option *ngFor="let tuple of pets" [value]="tuple.id">
      {{ tuple.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
