<form [formGroup]="depositForm">
  <div class="text-center">
    <mat-radio-group formControlName="paymentType" (change)="onPaymentTypeChange()">
      <mat-radio-button value="AMOUNT">{{'AMOUNT' | translate}}</mat-radio-button>
      <mat-radio-button value="PERCENTAGE" class="ml-1">{{'PERCENTAGE' | translate}}</mat-radio-button>
    </mat-radio-group>
  </div>

  <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="16px" class="mt-1">
    <div fxFlex="20" *ngIf="depositForm.value.paymentType === 'AMOUNT'">
      <mat-form-field appearance="outline" class="w-100">
        <div matSuffix class="mdc-px-2">{{ currencyCode }}</div>
        <mat-label>{{'AMOUNT' | translate}}&nbsp;</mat-label>
        <input type="text" appNumberOnly [decimalPoints]="2" matInput name="amount" placeholder="{{'AMOUNT' | translate}}" formControlName="amount">
      </mat-form-field>
    </div>
    <div fxFlex="20" *ngIf="depositForm.value.paymentType === 'PERCENTAGE'">
      <mat-form-field appearance="outline" class="w-100">
        <div matSuffix class="mdc-px-2">%</div>
        <mat-label>{{'PERCENTAGE' | translate}}</mat-label>
        <input type="text" appNumberOnly matInput name="percentage" placeholder="{{'PERCENTAGE' | translate}}" formControlName="percentage">
      </mat-form-field>
    </div>
    <div fxFlex="nogrow">
      <button class="vm mdc-mb-1" mat-raised-button color="accent" aria-label="Add to editor" #tooltip="matTooltip" matTooltip="Add to editor" [disabled]="!depositForm.valid" (click)="onAddItemClick()">
        <mat-icon>account_balance_wallet</mat-icon> {{'ADD' | translate}}
      </button>
    </div>
  </div>
</form>