<!-- <mat-card *ngIf="viewVaccinationsData" class="panel new-card"> -->
<ng-container *ngIf="viewVaccinationsData">
  <mat-card-header class="mdc-mb-1">
    <h6>{{'Vaccination'| translate}}</h6>
  </mat-card-header>
  <mat-card-content>
    <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="10px grid" *ngFor="let vaccination of petVaccinations; let i = index;">
      <mat-form-field fxFlex="35" appearance="outline">
        <mat-label>Vaccine Name</mat-label>
        <mat-select [(ngModel)]="vaccination.nameOfVaccination" name="nameOfVaccination{{i}}" [ngModelOptions]="{standalone: true}" placeholder="Vaccination Name" (selectionChange)="vaccinationChange($event, i)" [compareWith]="compareWithFn">
          <mat-option *ngFor="let vaccine of vaccinesList" [value]="vaccine.vaccineName">
            {{vaccine.vaccineName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field fxFlex="25" appearance="outline">
        <mat-label>Date Vaccinated</mat-label>
        <input matInput [matDatepicker]="picker" [(ngModel)]="vaccination.dateVaccinated" (ngModelChange)="updateDateVaccinated($event, i)">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field fxFlex="25" appearance="outline">
        <mat-label>Next Due Date</mat-label>
        <input matInput [matDatepicker]="nextDateDuePicker" [(ngModel)]="vaccination.nextDateDue" disabled>
        <mat-datepicker-toggle matSuffix [for]="nextDateDuePicker"></mat-datepicker-toggle>
        <mat-datepicker #nextDateDuePicker></mat-datepicker>
      </mat-form-field>
      <div fxFlex="5">
        <mat-icon aria-hidden="false" aria-label="delete" (click)="deleteVaccine(i)" class="delete-vaccine-icon">
          delete</mat-icon>
      </div>
    </div>

  </mat-card-content>
</ng-container>
<!-- </mat-card> -->