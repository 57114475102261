import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import {
  UpdateCustomer
} from '@savvy/customer';
import { Page } from '../shared/model/page';
import { TrialExpiredService } from '../shared/services/trial-expired.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDialog } from '@angular/material/dialog';
import { TableColumn } from '@swimlane/ngx-datatable';
import { ContextIdDto } from '@savvy/view-definition';
import { OnboardCustomerMessagingModalComponent } from './onboard-customer-messaging-modal/onboard-customer-messaging-modal.component';
import { SendToAllMessageWizardComponent } from '../send-to-all/send-to-all-message-wizard/send-to-all-message-wizard.component';
import { CustomerPortalSettingsService } from '@savvy/customer-portal-settings';
import { Customer, CustomerService } from '@savvy/customer';
import { Subscription } from 'rxjs';
import { MergeCustomerModalComponent } from './merge-customer/merge-customer-modal.component';
import { MergeCustomerSuccessComponent } from './merge-customer-success/merge-customer-success.component';
import { ConfService } from 'src/app/flo/config/conf.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ClearObservable } from '../shared/classes/clear-observable';
import { AddCustomerComponent } from './customer/addCustomer.component';
import { InviteCustomerComponent } from './invite-customer/invite-customer.component';
import { PetService } from '@savvy/pet';
import { SearchService } from "@savvy/search";
import {CustomersGridComponent, StatusEnum} from './customersGrid.component';
import {CustomersAlphaComponent} from "./customersAlpha.component";

@Component({
  selector: 'app-customers-v2',
  templateUrl: './customersV2.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersV2Component extends ClearObservable implements OnInit, OnDestroy {

  @Input() showPlaceholder: boolean;
  @ViewChild(CustomersGridComponent, { static: false }) customersGridComponent: CustomersGridComponent;
  @ViewChild(CustomersAlphaComponent, { static: false }) customersAlphaComponent: CustomersAlphaComponent;

  customers: Array<Customer> = [];
  page = new Page();

  contextIdDto: ContextIdDto;
  subscriptions: Subscription[] = [];

  selected: Customer[] = [];
  columns: TableColumn[] = [];
  loaded = false;
  firstNameSorted = true;
  sortAscending = true;
  approvalNeeded: boolean | null = null;
  showFilters = true;

  portalEnabled = false;
  searchKey = '';


  viewMode = 'grid';
  customersCount = 0;
  selectStatus: StatusEnum;
  constructor(
    private router: Router,
    private notify: FloSnackbarComponent,
    public trialExpiredService: TrialExpiredService,
    private route: ActivatedRoute,
    private customerService: CustomerService,
    public deviceService: DeviceDetectorService,
    private customerPortalSettingsService: CustomerPortalSettingsService,
    private dialog: MatDialog,
    private petService: PetService,
    private searchService: SearchService
  ) {
    super();
    this.showFilters = this.deviceService.isMobile() ? false : true;
  }

  getUrl(reference: string) {
    if (reference) {
      return ConfService.apiUrl() + '/rest/storage?public=false&contextId=' +
        this.contextIdDto.contextId + '&contextIdType=' +
        this.contextIdDto.contextIdType + '&id=' + reference;
    }
    return null;
  }

  ngOnInit() {
    console.log('inside on init CustomersV2Component');
    let offset = 0;
    if (sessionStorage.getItem('pageNumber')) {
      offset = Number(sessionStorage.getItem('pageNumber'));
      sessionStorage.removeItem('pageNumber');
    }
    this.route.params.subscribe(params => {
      if (params?.onlyAwaitingForm) {
        this.selectStatus = StatusEnum.AwaitingRegistration;
      }
      this.contextIdDto = <ContextIdDto>{};
      if (params['contextId'] && params['contextIdType']) {
        this.contextIdDto.contextId = params['contextId'];
        this.contextIdDto.contextIdType = params['contextIdType'];
        const selectFromGrid = sessionStorage.getItem('selectFromGrid');
        if (selectFromGrid === 'false') {
          this.viewMode = 'alpha';
        } else {
        }
        this.loadPortalEnabled();
        this.searchService.countCustomers(this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(res => this.customersCount = res);
      }

    });
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  mergeCustomer() {

    const dialogRef = this.dialog.open(MergeCustomerModalComponent, {
      data: {
        contextIdDto: this.contextIdDto
      },
      panelClass: 'customers-messaging-dialog',
      autoFocus: false,
      width: '100%',
      maxWidth: '600px'
    });

    dialogRef.afterClosed().subscribe((mergedCustomer: Customer) => {
      if (mergedCustomer) {
        this.mergeCustomerSuccess(mergedCustomer);
      } else {
        // this.listInstances();
      }
    });
  }

  mergeCustomerSuccess(mergedCustomer: Customer) {
    const dialogRef = this.dialog.open(MergeCustomerSuccessComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        customer: mergedCustomer
      },
      panelClass: 'customers-messaging-dialog',
      autoFocus: true,
      width: '100%',
      maxWidth: '600px'
    });

    dialogRef.afterClosed().subscribe((confirm: any) => {
      //  this.listInstances();
      if (confirm) {
        this.router.navigate(['/customer/viewCustomer',
          this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          mergedCustomer?.id]);
      }
    });
  }

  loadPortalEnabled() {
    this.customerPortalSettingsService.getOrCreatePortalSettings(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType).subscribe(response => {
        this.portalEnabled = response.portalEnabled;
      });
  }


  updateFilter(event) {
  }

  create() {
    console.log('inside create');
    this.router.navigate(['/customer/addCustomer',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType]);
  }

  openSendModal() {
    this.dialog.open(SendToAllMessageWizardComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        recipientType: 'CUSTOMER'
      },
      panelClass: [ 'scrollable-modal', 'customers-messaging-dialog'] ,
      autoFocus: true,
      width: '100%',
      maxWidth:'1024px'
    });
  }

  onCustomerMessagesClick() {
    this.router.navigate(['/customer/customerMessageRequests',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      'CUSTOMER']);
  }

  onboardCustomerClick() {
    this.dialog.open(OnboardCustomerMessagingModalComponent, {
      data: {
        contextIdDto: this.contextIdDto,
      },
      panelClass: 'customers-messaging-dialog',
      autoFocus: true,
    });

  }



  callMobile(mobileNumner) {
    if (mobileNumner) {
      window.open(`tel:${mobileNumner}`);
    }
  }



  emailTo(email) {
    if (email) {
      window.open(`mailto:${email}`);
    }
  }

  createNewCustomer() {
    const createPetModal = this.dialog.open(AddCustomerComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        pageType: 'addCustomer',
        isViewMode: true
      },
      maxWidth: '1024px',
      panelClass: [
        'modal-100',
        'helpwindow',
        'scrollable-modal-2'
      ],
      autoFocus: false,
      disableClose: true
    });

    createPetModal.afterClosed()
      .subscribe(
        () => {
          this.refreshCustomerList();
        });
  }

  refreshCustomerList() {
    if (this.viewMode === 'grid' && this.customersGridComponent) {
      this.customersGridComponent.searchCustomers();
    } else if (this.customersAlphaComponent) {
      this.customersAlphaComponent.loadCustomers();
    }
  }

  inviteNewCustomer() {
    const createPetModal = this.dialog.open(InviteCustomerComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        pageType: 'addCustomer',
        isViewMode: true
      },
      maxWidth: '1024px',
      panelClass: [
        'modal-100',
        'helpwindow',
        'scrollable-modal-2'
      ],
      autoFocus: false,
      disableClose: true
    });

    createPetModal.afterClosed()
      .subscribe(
        () => {
          //   this.setPage({ offset: 0, size: 20 });
        });
  }

  verifyPets(customerId: string) {
    if (customerId) {
      this.petService.loadCustomersPets(
        customerId).subscribe(pets => {
          if (pets && pets?.length) {
            for (const pet of pets) {
              pet.approvalNeeded = false;
              this.petService.updatePet(pet).subscribe(res => {
                console.log('Pet approved', res);
              });
            }
          }
        });
    }
  }

  verify(row: Customer) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Verify',
        message: `Are you sure you want to verify this customer ${row.firstName} ${row.lastName}?`
      },
      height: 'auto',
      width: '360px',
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        row.approvalNeeded = false;
        const updateCustomer: UpdateCustomer = {
          contextIdDto: this.contextIdDto,
          customer: row
        };
        this.customerService.updateCustomer(updateCustomer).subscribe(response => {
          this.verifyPets(response?.customerId?.id);
          this.notify.message = 'Customer verified';
          this.notify.open();
          //    this.setPage({ offset: 0, size: 20 });
        });
      }
    });
  }

  viewModeChange() {
    if (this.viewMode === 'grid') {
      sessionStorage.setItem('selectFromGrid', 'true');
    } else {
      sessionStorage.setItem('selectFromGrid', 'false');
    }
  }
}


