import { ViewContextDto } from '@savvy/view-composite';
import { LinkedIdTypeEnum } from 'src/app/core/data-share.service';
import {InvoiceOwnerId, LinkedInstanceDto} from '@savvy/invoice';
import InvoiceOwnerIdTypeEnum = InvoiceOwnerId.InvoiceOwnerIdTypeEnum;
import {LinkId} from '@savvy/invoice';

export class LinkedInstanceFactory {

  static createLinkedInstanceFromInvoiceOwnerId(invoiceOwnerId: InvoiceOwnerId): LinkedInstanceDto {

    const linkedInstanceDto = <LinkedInstanceDto>{};

    if (invoiceOwnerId.invoiceOwnerIdType === InvoiceOwnerIdTypeEnum.CustomerId) {
      linkedInstanceDto.linkedInstanceTypeDto = LinkedInstanceDto.LinkedInstanceTypeDtoEnum.Customer;
      linkedInstanceDto.customerId = invoiceOwnerId.id;
    } else if (invoiceOwnerId.invoiceOwnerIdType === InvoiceOwnerIdTypeEnum.StudentId) {
      linkedInstanceDto.linkedInstanceTypeDto = LinkedInstanceDto.LinkedInstanceTypeDtoEnum.Student;
      linkedInstanceDto.studentId = invoiceOwnerId.id;
    }

    return linkedInstanceDto;
  }

  static createLinkedInstanceFromLink(linkId: LinkId): LinkedInstanceDto {

    const linkedInstanceDto = <LinkedInstanceDto>{};

    if (linkId.linkedIdType === LinkedIdTypeEnum.CustomerId) {
      linkedInstanceDto.linkedInstanceTypeDto = LinkedInstanceDto.LinkedInstanceTypeDtoEnum.Customer;
      linkedInstanceDto.customerId = linkId.linkedId;
    } else if (linkId.linkedIdType === LinkedIdTypeEnum.StudentId) {
      linkedInstanceDto.linkedInstanceTypeDto = LinkedInstanceDto.LinkedInstanceTypeDtoEnum.Student;
      linkedInstanceDto.studentId = linkId.linkedId;
    } else if (linkId.linkedIdType === LinkedIdTypeEnum.LeadId) {
      linkedInstanceDto.linkedInstanceTypeDto = LinkedInstanceDto.LinkedInstanceTypeDtoEnum.Lead;
      linkedInstanceDto.leadId = linkId.linkedId;
    } else {
      linkedInstanceDto.linkedInstanceTypeDto = LinkedInstanceDto.LinkedInstanceTypeDtoEnum.EntityInstance;
      linkedInstanceDto.entityInstanceId = linkId.linkedId;
      linkedInstanceDto.entityDefinitionId = linkId.linkedEdId;
    }

    return linkedInstanceDto;
  }

  static createLinkedInstance(viewContext: any): LinkedInstanceDto {

    const linkedInstanceDto = <LinkedInstanceDto>{};
    if (viewContext.viewContextTypeDto === ViewContextDto.ViewContextTypeDtoEnum.Customer) {
      linkedInstanceDto.linkedInstanceTypeDto = LinkedInstanceDto.LinkedInstanceTypeDtoEnum.Customer;
      linkedInstanceDto.customerId = viewContext.customerContextDto.customerId.id;
    } else {
      linkedInstanceDto.linkedInstanceTypeDto = LinkedInstanceDto.LinkedInstanceTypeDtoEnum.EntityInstance;
      if (viewContext.entityInstanceId && viewContext.entityDefinitionId) {
        linkedInstanceDto.entityInstanceId = viewContext.entityInstanceId.id;
        linkedInstanceDto.entityDefinitionId = viewContext.entityDefinitionId.id;
      } else {
        // console.logthrow new Error('Unable to create linked instance');
      }
    }
    return linkedInstanceDto;
  }
}
