import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContextService } from '../context.service';
import { Router } from '@angular/router';
import { ContextIdDto } from '@savvy/payment';
import { NavChangeService } from '../element/NavChangeService';
import { Subscription } from 'rxjs';
import { Pet } from '@savvy/pet';
import { DateUtils } from '../dates/DateUtils';
import { DatalistService } from '@savvy/datalist';
import { MatDialog } from '@angular/material/dialog';

import { PetService } from '@savvy/pet';
import { PetHandlerService } from './pet-handler.service';
import { ViewCustomerV2Component } from '../customer/view-customer-v2/view-customer-v2.component';
import {
  OverdueGroomMessageModalComponent
} from '../shared/components/new-overdue-groom-message-modal/overdue-groom-message-modal.component';
import { Page } from "../shared/model/page";
import { DeviceDetectorService } from 'ngx-device-detector';


@Component({
  selector: 'app-overdue-grooms-pets',
  templateUrl: './overdue-grooms-pets.component.html',
  styleUrls: ['./overdue-grooms-pets.component.scss'],
})
export class OverdueGroomsPetsComponent implements OnInit, OnDestroy {
  contextIdDto: ContextIdDto;
  subscriptions: Subscription[] = [];

  overduePets: Array<Pet>;
  ignoredPets: Array<Pet>;

  selected = [];
  ignoredPage = new Page();
  ignoredLoading: boolean;

  overDuePage = new Page();
  overDueLoading: boolean;

  throttle = 300;
  scrollDistance = 2;
  scrollUpDistance = 2;
  constructor(
    private petService: PetService,
    private dialog: MatDialog,
    private petHandlerService: PetHandlerService,
    private contextService: ContextService,
    public deviceService: DeviceDetectorService,

  ) {
  }

  ngOnInit() {

    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
        this.loadOverdue();
        this.setIgnoredPage({ offset: 0, size: 20 });
      }
    }));
  }


  loadIgnored() {

    this.petService.loadIgnored(
      (this.ignoredPage.pageNumber + 1), this.ignoredPage.size).subscribe(response => {
        this.ignoredPets = response.contents;
        this.ignoredPage.totalElements = response.totalElements;
        this.ignoredPage.totalPages = response.totalPages;
      });

  }


  loadOverdue() {
    this.petService.loadOverdueGrooms(
      (this.overDuePage.pageNumber + 1), this.overDuePage.size).subscribe(response => {
        this.overduePets = response.contents;
        this.overDuePage.totalElements = response.totalElements;
        this.overDuePage.totalPages = response.totalPages;
      });
  }

  sendMessage(pet: Pet) {
    console.log('sending to pet', pet.name);
    const dialogRef = this.dialog.open(OverdueGroomMessageModalComponent, {
      data: {
        pet,
        contextIdDto: this.contextIdDto,
        // eventBus: this.eventBus
      },
      autoFocus: false,
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        if (!pet.numOverdueMessagesSent) {
          pet.numOverdueMessagesSent = 1;
        } else {
          pet.numOverdueMessagesSent = pet.numOverdueMessagesSent + 1;
        }
        this.petService.updatePet(pet).subscribe(response => {
            this.loadOverdue();
          });
      }
    });
  }

  ignoreThisPet(pet: Pet) {
    pet.ignoreOverdue = true;
    this.petService.updatePet(pet).subscribe(response => {
        this.loadOverdue();
      });
  }

  restoreThisPet(pet: Pet) {
    pet.ignoreOverdue = false;
    this.petService.updatePet(pet).subscribe(response => {
        this.loadIgnored();
      });
  }

  viewCustomer(pet: Pet) {
    if (!pet.petOwnerId) {
      return;
    }
    const viewCustomerModal = this.dialog.open(ViewCustomerV2Component, {
      data: {
        contextIdDto: this.contextIdDto,
        customerId: pet.petOwnerId
      },
      maxWidth: '1024px',
      // maxHeight: '80vh',
      // height: '80vh',
      panelClass: [
        'modal-100',
        'helpwindow',
        'petProfileModal'
      ],
      autoFocus: false
    });

    viewCustomerModal.afterClosed()
      .subscribe(
        () => {
          // if (this.customerStatsComponent) {
          //   this.customerStatsComponent.loadStatus();
          // }
        });
    // this.router.navigate(['/customer/viewCustomer',
    //   this.contextIdDto.contextId,
    //   this.contextIdDto.contextIdType,
    //   this.customer?.id]);

  }


  viewPet(pet: Pet) {
    this.petHandlerService.view(this.contextIdDto, pet.petOwnerId, pet.id, this.callback);
  }

  callback = (res) => {

  };


  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  setIgnoredPage(pageInfo) {
    console.log('pageInfo is ' + pageInfo);
    this.ignoredPage.pageNumber = pageInfo.offset;
    this.loadIgnored();
  }

  setOverDuePage(pageInfo) {
    console.log('pageInfo is ' + pageInfo);
    this.overDuePage.pageNumber = pageInfo.offset;
    this.loadOverdue();
  }

  onScrollDown(ev) {
    console.log("scrolled down!!", ev);
    this.overDuePage.pageNumber++;
    this.petService.loadOverdueGrooms(
      (this.overDuePage.pageNumber + 1), this.overDuePage.size).subscribe(response => {
        this.overduePets = [...this.overduePets, ...response.contents || []];
        this.overDuePage.totalElements = response.totalElements;
        this.overDuePage.totalPages = response.totalPages;
      });
  }

  onScrollDownIgnored(ev) {
    console.log("scrolled down!!", ev);
    this.ignoredPage.pageNumber++;
    this.petService.loadIgnored(
      (this.ignoredPage.pageNumber + 1), this.ignoredPage.size).subscribe(response => {
        this.ignoredPets = [...this.overduePets, ...response.contents || []];
        this.ignoredPage.totalElements = response.totalElements;
        this.ignoredPage.totalPages = response.totalPages;
      });
  }



  deleteFlag(flagId: string) {
   /* if (this.pet.flags) {
      const index = this.pet.flags.indexOf(flagId, 0);
      if (index > -1) {
        this.pet.flags.splice(index, 1);
        this.update();
      }
    }*/
  }
}
