export abstract class GLOBAL {
  public static routeOptimizationActive = true;
  public static appsMenu = true;
  public static adminMenu = false;
  public static trialExpiredFeatureON = true;
  public static firstSignupSettingsON = true;
  public static selectedMenuIconSvgXml = '';
  public static defaultIcon = 'alarm';
  public static groupSeparator = ',';
  public static decimalSeparator = '.';
  public static selectedTheme = '';

  public static localhost = 'dev.savvypetspa.com';
}

