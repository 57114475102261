import { Injectable } from '@angular/core';
import { CountryCode } from 'libphonenumber-js';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserCountryService {
  defaultCountryCode: CountryCode = 'GB';
  private countryCodeSource: Subject<CountryCode> = new Subject();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public countryCode$ = this.countryCodeSource.asObservable();

  setCountryCode(countryCode) {
    this.defaultCountryCode = countryCode;
    this.countryCodeSource.next(this.defaultCountryCode);
  }
}
