import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import {
  ContextIdDto,
  ListFlagsCompResponse,
  FlagDefinition
} from '@savvy/flags';
import { FlagDefHelperService } from 'src/app/services/flagDefHelper.service';

export class Link {
  name: string;
  value: string;
  icon: string;
}

@Component({
  selector: 'app-view-customer-flags',
  templateUrl: './view-customer-flags.component.html',
  styleUrls: ['./view-customer-flags.component.scss']
})
export class ViewCustomerFlagsComponent implements OnInit, OnDestroy, OnChanges {

  @Input() customerId: string;
  @Input() contextIdDto: ContextIdDto;
  @Input() flags: string[];

  response: ListFlagsCompResponse;
  flagDefs: Array<FlagDefinition>;

  constructor(
    private flagDefHelperService: FlagDefHelperService
  ) { }

  ngOnInit() {
    // this.loadFlags();
    this.loadFlagsV2();
  }

  loadFlagsV2() {
    this.flagDefs = [];
    if (this.flags?.length) {
      for (const flag of this.flags) {
        if (this.flagDefHelperService.getFlagDef(flag))
          this.flagDefs.push(this.flagDefHelperService.getFlagDef(flag));
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.flags?.currentValue !== changes?.flags?.previousValue) {
      this.loadFlagsV2();
    }
  }

  ngOnDestroy() {

  }

}
