import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { ContextService } from '../context.service';
import { ActivatedRoute, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { EventHandlerService } from '../entry/EventHandlerService';
import { TrialExpiredService } from '../shared/services/trial-expired.service';
import { ExportService, ExportSettings, ExportSettingsService } from '@savvy/export';

import { saveAs } from 'file-saver';
import { HttpClient } from '@angular/common/http';
import { ContextIdDto } from '@savvy/view-definition';
import { filter, map, Subscription } from 'rxjs';

@Component({
  selector: 'app-export-pets',
  templateUrl: './exportPets.component.html',
})
export class ExportPetsComponent implements OnInit, OnDestroy {

  contextIdDto: ContextIdDto;
  subscriptions: Subscription[] = [];
  exporting: boolean;
  exportType = 'allPets';
  exportSettings: ExportSettings;

  constructor(
    private router: Router,
    public http: HttpClient,
    private injector: Injector,
    private eventHandler: EventHandlerService,
    private notify: FloSnackbarComponent,
    private contextService: ContextService,
    public trialExpiredService: TrialExpiredService,
    private route: ActivatedRoute,
    private exportSettingsService: ExportSettingsService,
    private api: ExportService) {
  }

  ngOnInit() {
    console.log('inside on init students.component.ts');
    this.router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      map(event => {
        if (event instanceof NavigationStart) {
          // Show loading indicator
          console.log('got navigation start');
        }

        if (event instanceof NavigationEnd) {
          // Hide loading indicator
          console.log('got navigation end');
        }

        if (event instanceof NavigationError) {
          // Hide loading indicator
          // Present error to user
          console.log('got navigation error');
          // console.log(event.error);
        }
      }));

    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
        this.load();
      }
    }));
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  load() {
    this.exportSettingsService.loadSettings(this.contextIdDto.contextId, 'ENV_ID').subscribe(response => {
      this.exportSettings = response;
    })
  }

  exportData() {

    this.exporting = true;
    if (this.exportType === 'allPets') {
      return this.api.exportPets(this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(
        response => {
          console.log('got export');
          this.exporting = false;
          const blob = new Blob([response.lines], { type: 'text/csv;charset=utf-8' });
          saveAs(blob, 'pets.csv');
          // this.saveFile(data, 'customers.csv');
          // saveAs(data.file, 'customers.csv');
        }, error => {
          this.exporting = false;
        });
    } else {
      return this.api.exportCustomerAndPets(this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(
        response => {
          console.log('got export');
          this.exporting = false;
          const blob = new Blob([response.lines], { type: 'text/csv;charset=utf-8' });
          saveAs(blob, 'customersPets.csv');
          // this.saveFile(data, 'customers.csv');
          // saveAs(data.file, 'customers.csv');
        }, error => {
          this.exporting = false;
        });
    }
  }

  saveFile(data: any, filename?: string) {
    const blob = new Blob([data], { type: 'text/csv; charset=utf-8' });
    saveAs(blob, filename);
  }
}
