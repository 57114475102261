<form [formGroup]="firstFormGroup">
  <h6>Please enter new customer details :</h6>
  <div fxLayout="column">
    <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">

      <div fxFlex="49">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>{{'CUSTOMER_FIRST_NAME' | translate}}</mat-label>
          <input matInput placeholder="{{'' | translate}}" formControlName="customerFirstName">
        </mat-form-field>
      </div>
      <div fxFlex="49">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>{{'CUSTOMER_LAST_NAME' | translate}}</mat-label>
          <input matInput placeholder="{{'' | translate}}" formControlName="customerLastName">
        </mat-form-field>
      </div>
    </div>
  </div>
  <div fxLayout="column">
    <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">
      <div fxFlex="49">
        <app-phone-input name="mobilePhoneNumber" (phoneChanged)="onMobilePhoneChange($event)"
          (countryChanged)="onMobileCountryChange($event)" [selectedCountryCode]="customerPhone?.country" label="Mobile"
          placeholder="{{'MOBILE' | translate}}" errorName="Mobile" required="false"></app-phone-input>
      </div>
      <div fxFlex="49">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>{{'EMAIL' | translate}}</mat-label>
          <input matInput placeholder="{{'' | translate}}" formControlName="customerEmail">
        </mat-form-field>
      </div>
    </div>
  </div>

  <div fxLayout="column">
    <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">
      <button class="btn_pad" [disabled]="!firstFormGroup.valid" mat-raised-button color="accent"
        (click)="createCustomer(true)">
        Create Customer
      </button>&nbsp;
      <!-- <button class="btn_pad" mat-raised-button color="accent" (click)="newExistingSwitch.emit('true')">
        Search Customer
      </button> -->
    </div>
  </div>

</form>