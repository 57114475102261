import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-no-internet',
  templateUrl: './no-internet.component.html',
  styleUrls: ['./no-internet.component.scss']
})
export class NoInternetComponent implements OnInit {

  constructor(
    private router: Router,
    private toastr: ToastrService
  ) { }

  gotoHome() {
    if (window?.navigator?.onLine) {
      this.router.navigate(['/']);
    } else {
      this.toastr.error('No network available, please check your internet connection and try again', 'Error');
    }
  }

  ngOnInit(): void {
  }
}
