import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ContextIdDto} from '@savvy/user';
import {FloSnackbarComponent} from '../snackbar/floSnackbar.component';
import {Pet, PetService} from '@savvy/pet';


@Component({
  selector: 'app-pet-notes',
  templateUrl: './petNotes.component.html'
})
export class PetNotesComponent {

  pet: Pet;
  contextIdDto: ContextIdDto;
  petId: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private petService: PetService,
              private notify: FloSnackbarComponent,
              public dialogRef: MatDialogRef<PetNotesComponent>) {

    this.contextIdDto = data.contextIdDto;
    this.petId = data.petId;
    this.loadNotes();
  }

  loadNotes() {
    this.petService.get(
      this.petId
    ).subscribe(response=> {
      this.pet = response;
    });
  }

  save() {
    if (!this.petId) {
      console.warn('No pet id');
    } else {
      this.petService.updatePet(this.pet).subscribe(response => {
        this.notify.message = 'Updated notes';
        this.notify.open();
        this.dialogRef.close(this.pet.petNotes);
      });
    }
  }
}
