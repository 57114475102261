import { Component, Input, OnInit } from '@angular/core';
import { ViewContextDto } from '@savvy/view-composite';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ChangeListener } from './changeListener';
import { NavigateService } from '../entry/NavigateService';
import { Page } from '../shared/model/page';
import { TrialExpiredService } from '../shared/services/trial-expired.service';
import { MatDialog } from '@angular/material/dialog';
import { ViewEntityComponent } from './viewEntity.component';
import { EventBusService } from './EventBusService';
import { PhoneNumberHelperService } from '../shared/services/phone-number-helper.service';
import { InvoicecompService } from '@savvy/invoice';
import { ContextIdDto, EiData, EntityInstanceId, ViewInvoicesByEiListData } from '@savvy/view-definition';

@Component({
  selector: 'app-view-invoices-by-ei-list',
  templateUrl: 'viewInvoicesByEiList.component.html'
})
export class ViewInvoicesByEiListComponent implements OnInit {

  @Input() viewInvoicesByEiListData: ViewInvoicesByEiListData;
  @Input() contextIdDto: ContextIdDto;
  @Input() viewContext: any;
  @Input() form: UntypedFormGroup;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;
  @Input() useModalOnClick: boolean;
  @Input() eventBus: EventBusService;

  page = new Page();
  selected: EiData[] = [];
  appointmentDates: Array<EiData>;

  columns = [
    { prop: 'label', name: '' }
  ];

  constructor(private router: Router,
    private naviateService: NavigateService,
    public trialExpiredService: TrialExpiredService,
    private invoicecompService: InvoicecompService,
    private dialog: MatDialog,
    private sharedService: PhoneNumberHelperService) {
  }
  ngOnInit() {
    this.page.size = 10;
    if (this.viewInvoicesByEiListData.appointmentDates) {
      this.appointmentDates = this.viewInvoicesByEiListData.appointmentDates;
      this.page.totalElements = this.viewInvoicesByEiListData.totalRows;
      this.page.totalPages = this.viewInvoicesByEiListData.totalPages;
      this.page.pageNumber = 0;

    } else {
      this.setPage({ offset: 0 });
    }
  }
  setPage(pageInfo) {
    console.log('pageInfo is ' + pageInfo.offset);
    this.page.pageNumber = pageInfo.offset;
    this.loadPage();
  }

  loadPage() {
    // Call list display names and pass page number

    console.log('loading page:');

    if (this.viewInvoicesByEiListData &&
      this.viewInvoicesByEiListData.entityInstanceId) {
      this.invoicecompService.loadInvoicesByEi(
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.viewInvoicesByEiListData.entityInstanceId.id,
        (this.page.pageNumber + 1),
        this.page.size,
      ).subscribe(response => {
        this.appointmentDates = response.appointmentDates;
        this.page.totalElements = response.totalRows;
        this.page.totalPages = response.totalPages;
      });
    }
  }
  showTable() {
    if (this.rowsAvailable() && this.page.pageNumber === 0 && this.lessThanPageWorth()) {
      return false;
    } else {
      return true;
    }
  }

  onSelect(event) {
    if (this.selected && this.selected.length > 0) {
      this.rowSelected(this.selected[0].entityInstanceId);
    }
  }

  lessThanPageWorth() {
    return this.numRows() < this.page.size;
  }

  numRows() {
    return this.appointmentDates.length;
  }
  rowsAvailable() {
    return this.appointmentDates;
  }

  rowSelected(entityInstanceId: EntityInstanceId) {
    if (this.useModalOnClick) {
      this.dialog.open(ViewEntityComponent, {
        data: {
          entityInstanceId: entityInstanceId.id,
          contextIdDto: this.contextIdDto,
          eventBus: this.eventBus,
          isViewMode: true
        },
        panelClass: 'modal-100',
        maxHeight: '80vh'
      });
    } else {
      this.sharedService.viewEntityModalSub.next(true);
      this.naviateService.viewEntity(this.contextIdDto, entityInstanceId);
    }
  }

  create() {
    this.sharedService.viewEntityModalSub.next(true);
    console.log('inside create in ListEntitysListComponent');
    if (this.viewContext.viewContextTypeDto === ViewContextDto.ViewContextTypeDtoEnum.Customer) {
      this.router.navigate(['/entity/createEntity',
        this.viewInvoicesByEiListData.entityDefinitionId.id,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.viewContext.customerContextDto.customerId.id
      ]);
    } else {
      this.sharedService.viewEntityModalSub.next(true);
      this.router.navigate(['/entity/createEntity',
        this.viewInvoicesByEiListData.entityDefinitionId.id,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.viewContext.entityInstanceId.id,
        this.viewContext.entityDefinitionId.id
      ]);
    }
  }
}

