import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {FloHtmlElementHolder} from '@savvy/entity-instance-composite';

@Component({
    selector: 'app-html-element',
    templateUrl: 'htmlElement.component.html'
})
export class HtmlElementComponent implements OnInit {

    @Input() htmlElement: FloHtmlElementHolder;
    @Input() formGroup: UntypedFormGroup;

    ngOnInit(): void {
    }
}

