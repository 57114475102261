import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import MenuItemTypeEnum = MenuDefinition.MenuItemTypeEnum;
import {
  CustomerCompositeService,
} from '@savvy/customer';
import { TrialExpiredService } from '../shared/services/trial-expired.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDialog } from '@angular/material/dialog';
import { TableColumn } from '@swimlane/ngx-datatable';
import { ContextIdDto, MenuDefinition } from '@savvy/view-definition';
import { SendToAllMessageWizardComponent } from '../send-to-all/send-to-all-message-wizard/send-to-all-message-wizard.component';
import { CustomerPortalSettingsService } from '@savvy/customer-portal-settings';
import { CustomerService } from '@savvy/customer';
import { Subscription } from 'rxjs';
import { ConfService } from 'src/app/flo/config/conf.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ClearObservable } from '../shared/classes/clear-observable';
import { FlagDefinition } from '@savvy/flags';
import { AddCustomerComponent } from './customer/addCustomer.component';
import { PaginationData } from '@savvy/pet';
import { CustomerHandlerService } from './customer-handler.service';
import {
  CustomerSearchResultDto,
  PerformCustomerSearch,
  PerformCustomerSearchResponse,
  SearchService
} from "@savvy/search";
import { ContextService } from "../context.service";
import { FlagFilterComponent } from "../shared/components/flag-filter/flag-filter.component";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { FlagDefHelperService } from "../../services/flagDefHelper.service";
import { Page } from "../shared/model/page";

export enum StatusEnum {
  Active = 'ACTIVE',
  InActive = 'IN_ACTIVE',
  Archived = 'ARCHIVE',
  NotVerified = 'NOT_VERIFIED',
  AwaitingRegistration = 'AWAITING_REGISTRATION',
  Merged = 'MERGED'
}
export class CustomerFilter {
  searchKey: string = '';
  status: StatusEnum = StatusEnum.Active;
  includeFlagDefs = new Array();
  excludeFlagDefs = new Array();
  includeDeleted = false;
  firstNameSorted = true;
  sortAscending = true;
  approvalNeeded: boolean | null = null;
  merged: boolean = false;
  formSubmitted = false;
}

@Component({
  selector: 'app-customers-grid',
  templateUrl: './customersGrid.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersGridComponent extends ClearObservable implements OnInit, OnDestroy {

  @ViewChild('flagFilterComponent', { static: false }) flagFilterComponent: FlagFilterComponent;

  @Input() showPlaceholder: boolean;
  @Input() selectStatus: StatusEnum;

  customers: Array<CustomerSearchResultDto> = [];

  contextIdDto: ContextIdDto;
  subscriptions: Subscription[] = [];

  selected: CustomerSearchResultDto[] = [];
  columns: TableColumn[] = [];
  loaded = false;
  @Input() showFilters = true;
  showPetRelated = false;

  portalEnabled = false;
  columnsForMobile = [
    { prop: 'firstName', name: 'First Name' },
    { prop: 'lastName', name: 'Last Name' },
    { prop: 'mobilePhoneNumberObj?.internationalNumber', name: 'Mobile' },
    { prop: 'username', name: 'Email' },
  ];

  basicColumns = [
    { prop: 'firstName', name: 'First Name' },
    { prop: 'lastName', name: 'Last Name' },
    { prop: 'companyName', name: 'Company Name' },
    { prop: 'phoneNumberObj.internationalNumber', name: 'Phone' },
    { prop: 'mobilePhoneNumberObj?.internationalNumber', name: 'Mobile' },
    { prop: 'username', name: 'Email' },
  ];

  customerImage = '/assets/images/customer-profile.png';
  loadCustomerDtosResponse;
  pageSizeOptions: number[] = [10, 25, 100, 250];

  customerFilter: CustomerFilter = new CustomerFilter();
  statusEnum = StatusEnum;

  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;

  page = new Page();

  constructor(
    private router: Router,
    private notify: FloSnackbarComponent,
    public trialExpiredService: TrialExpiredService,
    private route: ActivatedRoute,
    private customerService: CustomerService,
    public deviceService: DeviceDetectorService,
    private customerPortalSettingsService: CustomerPortalSettingsService,
    private dialog: MatDialog,
    private contextService: ContextService,
    private customerCompositeService: CustomerCompositeService,
    private searchService: SearchService,
    private customerHandlerService: CustomerHandlerService,
    private flagHelperService: FlagDefHelperService
  ) {
    super();
    this.columns = this.deviceService.isMobile() ? this.columnsForMobile : this.basicColumns;
    this.showPetRelated = this.contextService.hasPets();
  }

  getUrl(reference: string) {
    if (reference) {
      return ConfService.apiUrl() + '/rest/storage?public=false&contextId=' +
        this.contextIdDto.contextId + '&contextIdType=' +
        this.contextIdDto.contextIdType + '&id=' + reference;
    }
    return null;
  }

  ngOnInit() {
    console.log('inside on init CustomersV2Component');
    let offset = 0;
    if (sessionStorage.getItem('pageNumber')) {
      offset = Number(sessionStorage.getItem('pageNumber'));
      sessionStorage.removeItem('pageNumber');
    }
    this.route.params.subscribe(params => {
      this.contextIdDto = <ContextIdDto>{};
      if (params['contextId'] && params['contextIdType']) {
        this.contextIdDto.contextId = params['contextId'];
        this.contextIdDto.contextIdType = params['contextIdType'];
        const selectFromGrid = sessionStorage.getItem('selectFromGrid');
        if (this.selectStatus) {
          this.customerFilter.status = this.selectStatus;
          if (this.selectStatus === StatusEnum.AwaitingRegistration) {
            this.customerFilter.approvalNeeded = true;
          }
        }
        this.setPage({ offset });
        this.loadPortalEnabled();
      }
    });
  }

  prepareRequest(pageNumber: number): PerformCustomerSearch {
    const req = <PerformCustomerSearch>{};
    req.contextIdDto = this.contextIdDto;
    req.paginationData = <PaginationData>{};
    req.paginationData.pageNumber = pageNumber;
    req.paginationData.pageSize = this.page.size;
    req.ascending = this.customerFilter.sortAscending;
    req.deleteOnly = this.customerFilter.includeDeleted;
    req.searchString = this.customerFilter.searchKey;
    req.includeFlags = this.customerFilter.includeFlagDefs;
    req.excludeFlags = this.customerFilter.excludeFlagDefs;
    req.approvalNeeded = this.customerFilter.approvalNeeded || false;
    req.merged = this.customerFilter.merged;
    req.formSubmitted = this.customerFilter.formSubmitted;
    return req;
  }

  onScrollDown(ev) {
    console.log("scrolled down!!", ev);
    this.page.pageNumber++;
    if (this.contextIdDto?.contextId && this.contextIdDto?.contextIdType) {
      this.searchService.performCustomerSearch(this.prepareRequest(this.page.pageNumber))
        .subscribe(response => {
          if (response?.customerSearchResultDtos?.length) {
            this.customers = [...this.customers, ...response?.customerSearchResultDtos || []];
            this.page.totalElements = response.totalNumCustomers;
            this.page.totalPages = response.totalPages;

            for (const customer of this.customers) {
              if (customer.firstName === null) {
                customer.firstName = '';
              }
              if (customer.lastName === null) {
                customer.lastName = '';
              }
            }
            this.customers = [...this.customers];
            this.loaded = true;

          } else {
            this.page.pageNumber = this.page.totalPages;
          }
        });
    }
  }


  searchCustomers() {
    this.searchService.performCustomerSearch(this.prepareRequest(this.page.pageNumber + 1))
      .subscribe(response => {
        this.handleSearchedCustomers(response);
      });
  }

  handleSearchedCustomers(response: PerformCustomerSearchResponse) {
    console.log('customers are ' + response.customerSearchResultDtos.length);
    this.customers = response.customerSearchResultDtos;
    this.page.totalElements = response.totalNumCustomers;
    this.page.totalPages = response.totalPages;

    for (const customer of this.customers) {
      if (customer.firstName === null) {
        customer.firstName = '';
      }
      if (customer.lastName === null) {
        customer.lastName = '';
      }
    }
    this.customers = [...this.customers];
    this.loaded = true;
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  loadPortalEnabled() {
    this.customerPortalSettingsService.getOrCreatePortalSettings(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType).subscribe(response => {
        this.portalEnabled = response.portalEnabled;
      });
  }

  onSort(event) {
    // event was triggered, start sort sequence
    console.log('Sort1', event);
    console.log('Sort2', event.sorts);
    console.log('Sort2[0]', event.sorts[0]);
    this.customerFilter.sortAscending = event.newValue === 'asc';
    this.customerFilter.firstNameSorted = event.column.name !== 'Last Name';
    console.log('firstNameSorted', this.customerFilter.firstNameSorted);
    // Reset back to page 1
    this.setPage({ offset: 0 });
  }



  /**
   * Populate the table with new data based on the page number
   *
   * @param page The page to select
   */
  setPage(pageInfo) {
    console.log('pageInfo is ' + pageInfo);
    this.page.pageNumber = pageInfo.offset;
    this.searchCustomers();
  }

  // onSelect(event) {
  //   if (event.selected[0].id) {
  //     this.edit(event.selected[0]);
  //   }
  // }

  callback = () => {
    this.setPage({ offset: 0 });
  }

  edit(row: CustomerSearchResultDto) {
    this.customerHandlerService.edit(this.contextIdDto, row.id, this.callback)
  }

  view(row: CustomerSearchResultDto) {
    this.customerHandlerService.view(this.contextIdDto, row.id, this.callback)
  }

  updateFilter(event) {
  }

  create() {
    console.log('inside create');
    this.router.navigate(['/customer/addCustomer',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType]);
  }

  openSendModal() {
    this.dialog.open(SendToAllMessageWizardComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        recipientType: 'CUSTOMER'
      },
      panelClass: ['scrollable-modal', 'customers-messaging-dialog'],
      autoFocus: true
    });
  }

  onCustomerMessagesClick() {
    this.router.navigate(['/customer/customerMessageRequests',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      'CUSTOMER']);
  }

  deleteCustomerForever(row: CustomerSearchResultDto) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete Forever',
        message: `You are about to permanently delete this customer ${row.firstName} ${row.lastName}, are you sure you want to proceed?`
      },
      height: 'auto',
      width: '480px',
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.customerService.deleteCustomerForever(this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          row.id
        ).subscribe(() => {
          this.notify.message = 'Customer deleted permanently';
          this.notify.open();
          this.searchCustomers();
        });
      }
    });
  }

  archive(row: CustomerSearchResultDto) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Archive',
        message: `Are you sure you want to archive this customer ${row.firstName} ${row.lastName}?`
      },
      height: 'auto',
      width: '360px',
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.customerService.deleteCustomer(this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          row.id
        ).subscribe(() => {
          this.notify.message = 'Archived Customer';
          this.notify.open();
          this.searchCustomers();
        });
      }
    });
  }

  unarchive(row: CustomerSearchResultDto) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Unarchive',
        message: `Are you sure you want to unarchive this customer ${row.firstName} ${row.lastName}?`
      },
      height: 'auto',
      width: '360px',
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.customerService.undoDeleteCustomer(this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          row.id).subscribe(() => {
            this.notify.message = 'Unarchived Customer';
            this.notify.open();
            this.searchCustomers();
          });
      }
    });
  }

  callMobile(mobileNumner) {
    if (mobileNumner) {
      window.open(`tel:${mobileNumner}`);
    }
  }

  emailTo(email) {
    if (email) {
      window.open(`mailto:${email}`);
    }
  }

  includeFlagsChange(flagDefs: FlagDefinition[]) {
    this.customerFilter.includeFlagDefs = new Array();
    if (flagDefs !== undefined) {
      flagDefs.forEach(flagDef => {
        this.customerFilter.includeFlagDefs.push(flagDef.id);
      });
    }
    this.searchCustomers();
  }

  includeFlagsNames() {
    let flagNames = "";

    if (this.customerFilter?.includeFlagDefs) {
      this.customerFilter.includeFlagDefs.forEach(flag => {
        if (flagNames.length > 0) {
          flagNames = flagNames + ", ";
        }
        flagNames = flagNames + this.flagHelperService.getFlagDef(flag).name;
      });
    }
    return flagNames;
  }

  excludeFlagsNames() {
    let flagNames = "";
    if (this.customerFilter?.excludeFlagDefs) {
      this.customerFilter.excludeFlagDefs.forEach(flag => {
        if (flagNames.length > 0) {
          flagNames = flagNames + ", ";
        }
        flagNames = flagNames + this.flagHelperService.getFlagDef(flag).name;
      });
    }

    return flagNames;
  }

  excludeFlagsChange(flagDefs: FlagDefinition[]) {
    this.customerFilter.excludeFlagDefs = new Array();
    if (flagDefs !== undefined) {
      flagDefs.forEach(flagDef => {
        this.customerFilter.excludeFlagDefs.push(flagDef.id);
      });
    }
    this.searchCustomers();
  }

  createNewCustomer() {
    const createPetModal = this.dialog.open(AddCustomerComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        pageType: 'addCustomer',
        isViewMode: true
      },
      maxWidth: '1024px',
      panelClass: [
        'modal-100',
        'helpwindow',
        'scrollable-modal-2'
      ],
      autoFocus: false,
      disableClose: true
    });

    createPetModal.afterClosed()
      .subscribe(
        () => {
          this.setPage({ offset: 0 });
        });
  }

  searchKeyUpdated(searchText) {

    this.customerFilter.searchKey = searchText;
    this.searchCustomers();

  }

  statusChanged(newStatus: StatusEnum) {
    if (newStatus) {
      switch (newStatus) {
        case StatusEnum.Active:
          this.customerFilter.approvalNeeded = false;
          this.customerFilter.includeDeleted = false;
          this.customerFilter.merged = false;
          break;
        case StatusEnum.Archived:
          this.customerFilter.includeDeleted = true;
          this.customerFilter.approvalNeeded = false;
          this.customerFilter.merged = false;
          break;
        case StatusEnum.NotVerified:
          this.customerFilter.includeDeleted = false;
          this.customerFilter.approvalNeeded = true;
          this.customerFilter.formSubmitted = true;
          this.customerFilter.merged = false;
          break;
        case StatusEnum.AwaitingRegistration:
          this.customerFilter.includeDeleted = false;
          this.customerFilter.approvalNeeded = true;
          this.customerFilter.formSubmitted = false;
          this.customerFilter.merged = false;
          break;
        case StatusEnum.Merged:
          this.customerFilter.includeDeleted = false;
          this.customerFilter.approvalNeeded = false;
          this.customerFilter.formSubmitted = false;
          this.customerFilter.merged = true;
          break;
        default:
          this.customerFilter.approvalNeeded = null;
          break;
      }
    }
    this.setPage({ offset: 0 });

  }

  verify(row: CustomerSearchResultDto) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Verify',
        message: `Are you sure you want to verify this customer ${row.firstName} ${row.lastName}?`
      },
      height: 'auto',
      width: '360px',
      panelClass: 'helpwindow'
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {

        this.customerCompositeService.verifyCustomer(this.contextIdDto.contextId, this.contextIdDto.contextIdType, row.id)
          .subscribe(response => {
            this.notify.message = 'Customer verified';
            this.notify.open();
            this.setPage({ offset: 0 });
          });
      }
    });
  }

  resetFilters() {
    this.customerFilter = new CustomerFilter();
    this.flagFilterComponent.formGroup = new UntypedFormGroup({
      includeFlags: new UntypedFormControl([]),
      excludeFlags: new UntypedFormControl([]),
    });
    this.searchCustomers();
  }

}


