<div class="mdc-p-3 ">
  <h4 class="text-center mb-2">{{'FEEDBACK' | translate: {Default: 'Feedback'} }}</h4>
  <h6 class="text-center mb-2">{{'HOW_LIKELY' | translate}}</h6>
  <div fxLayout="row" flexLayoutAlign="center" class="mb-2">
    <div fxFlex="nogrow" class="pr-2 mat-text-secondary">{{'NOT_LIKELY' | translate}}</div>

    <div fxFlex class="rating-numbers" *ngFor="let star of stars; let i = index">
      <span (click)="rate(i + 1)">
        <ng-container *ngIf="star; else noStar">
          <span class="mat-text-yellow">
            <mat-icon>star</mat-icon>
          </span>
        </ng-container>
        <ng-template #noStar>
          <span class="mat-text-secondary">
            <mat-icon>grade</mat-icon>
          </span>
        </ng-template>
      </span>
    </div>

    <div fxFlex="nogrow" class="pl-2 mat-text-secondary">{{'VERY_LIKELY' | translate}}</div>
  </div>
  <div fxLayout="row" flexLayoutAlign="center" class="mb-2">
    <div fxFlex="100">
      <span style="color:red;" *ngIf="userRating.rating > 0 && userRating.rating < 10">{{'HOW_INCREASE_RATINGS' | translate}}</span>
      <br>
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'COMMENTS' | translate}}</mat-label>
        <textarea matInput [(ngModel)]="userRating.comments" [ngModelOptions]="{standalone: true}" rows="2" [required]="userRating.rating > 0 && userRating.rating < 10"></textarea>
      </mat-form-field>
    </div>
  </div>

  <!-- <div fxLayout="row" flexLayoutAlign="center center" class="mb-2">
    <div fxFlex class="text-center">
      <mat-icon class="mat-text-secondary" style="font-size: 50px; height: 50px; width: 50px; margin-bottom: 5px;">mood_bad</mat-icon>
      <p class="mat-text-secondary">Not Liked</p>
    </div>

    <div fxFlex class="text-center">
      <mat-icon class="mat-text-yellow" style="font-size: 50px; height: 50px; width: 50px; margin-bottom: 5px;">sentiment_satisfied</mat-icon>
      <p class="mat-text-secondary">Good</p>
    </div>

    <div fxFlex class="text-center">
      <mat-icon class="mat-text-secondary" style="font-size: 50px; height: 50px; width: 50px; margin-bottom: 5px;">sentiment_very_satisfied</mat-icon>
      <p class="mat-text-secondary">Very Good</p>
    </div>
  </div> -->

  <div fxLayout="row">
    <div fxFlex>
      <button mat-raised-button color="accent" (click)="submitRating()" [disabled]="!userRating.rating">{{'SUBMIT' | translate}}</button>
    </div>
    <div fxFlex="nogrow">
      <button mat-button (click)="ignoreRating()">{{'REMIND_ME_LATER' | translate}}</button>
    </div>
  </div>
</div>