import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { ContextIdDto } from '@savvy/view-definition';
import {ActivatedRoute} from '@angular/router';
import {ListCachedEntityInstancesComponent} from './listCachedEntityInstances.component';


@Component({
  selector: 'app-list-cached-entity-instances-page',
  templateUrl: 'listCachedEntityInstancesPage.component.html',
  styleUrls: ['./listCachedEntityInstances.component.scss']
})
export class ListCachedEntityInstancesPageComponent implements OnInit {
  @Input() showPlaceholder: boolean;
  contextIdDto: ContextIdDto;
  cachedTableViewDefinitionId: string;

  @ViewChild('listComp', { static: true }) listComp: ListCachedEntityInstancesComponent;


  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    console.log('inside on init listEntityInstances');

    this.route
      .params
      .subscribe(params => {
        console.log('params changed');
        this.cachedTableViewDefinitionId = params['cachedTableViewDefinitionId'];
        if (this.cachedTableViewDefinitionId) {
           this.contextIdDto = <ContextIdDto>{};
      this.contextIdDto.contextId = params['contextId'];
      this.contextIdDto.contextIdType = params['contextIdType'];
          this.listComp.loadCachedTableWith(this.contextIdDto, this.cachedTableViewDefinitionId);
        }
      });
  }
}
