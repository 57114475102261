import { Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import AdyenCheckout from '@adyen/adyen-web';
// import '@adyen/adyen-web/dist/adyen.css';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import {
  CreateChangeCardPaymentSession, CreateCheckoutSessionResponse,
  SavvyPayCardsService,
  SavvyPayPaymentsService,
} from '@savvy/savvy-pay';
import { ContextIdDto, PaymentService } from '@savvy/payment';
import { ToastrService } from 'ngx-toastr';
import { InvoicePaymentDetailsDto } from '@savvy/invoice';
import { NgxSpinnerService } from 'ngx-spinner';
import { MakePayment } from '@savvy/savvy-pay';
import DropinElement from '@adyen/adyen-web/dist/types/components/Dropin';
import { PaymentStatus } from '../enum/PaymentStatus';
import { OrgCompositeService, OrgDto } from '@savvy/org';
import { UserId } from '@savvy/invoice';
import { Subscription, SubscriptionDefinition } from '@savvy/subscription';
import { FloSnackbarComponent } from "../../../snackbar/floSnackbar.component";

@Component({
  selector: 'app-savvy-pay-change-card',
  templateUrl: './savvy-pay-change-card.component.html',
  styleUrls: ['./savvy-pay-change-card.component.scss']
})
export class SavvyPayChangeCardComponent implements OnInit {

  @ViewChild('hook', { static: true }) hook: ElementRef;
  @Input() invoicePaymentDetailsDto: InvoicePaymentDetailsDto | undefined;
  @Input() dueDate: string | undefined = '';
  @Input() channel: MakePayment.ChannelEnum | undefined;
  @Input() customerId: string | undefined = null;
  @Input() customerEmail: string | undefined = null;
  @Input() currency = 'GBP';
  @Input() countryCode = 'gb';
  @Input() returnUrl;
  @Input() staff: Array<UserId> | undefined = null;
  @Input() subscription: Subscription;
  @Input() selectedSubscriptionDef: SubscriptionDefinition;
  @Input() contextIdDto: ContextIdDto;
  @Input() cost: number;


  @Output() updateStatus = new EventEmitter<PaymentStatus>();
  org: OrgDto;
  payClicked = false;

  envId = '';
  clientId = environment.SAVVY_PAY_CLIENT_ID;
  error: string | undefined;
  checkout: any;
  config: any;
  dropInElement: DropinElement = {} as DropinElement;

  constructor(
    private notify: FloSnackbarComponent,
    private route: ActivatedRoute,
    private savvyPayCardsService: SavvyPayCardsService,
    private savvyPayPaymentsService: SavvyPayPaymentsService,
    private paymentService: PaymentService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private orgCompApi: OrgCompositeService,
  ) {
  }

  ngOnInit() {
    if (this.contextIdDto) {
      this.loadOrg();

    }
  }

  loadOrg() {
    this.orgCompApi.loadOrgFromCtx(this.contextIdDto.contextId).subscribe(res => {
      this.org = res.org;
      this.initAdyenConfig();

    });
  }
  /**
   * Initialize Adyen Configuration.
   */
  initAdyenConfig() {
    let subPrice = this.cost;
    const changeCardReq: CreateChangeCardPaymentSession = {};
    changeCardReq.contextIdDto = this.contextIdDto;
    changeCardReq.redirectUrl = this.returnUrl;
    changeCardReq.subscriptionId = this.subscription.id;
    changeCardReq.amount = subPrice;

    changeCardReq.currency = this.org.currencyCode;
    changeCardReq.countryCode = this.org.countryCode;


    try {
      this.savvyPayCardsService.createChangeCardPaymentSession(changeCardReq).subscribe(res => {
        this.configureAdyen(res);
      });
    } catch (error) {
      this.toastr.error('Error occurred during payment', 'Error');
    }

  }

  async configureAdyen(response: CreateCheckoutSessionResponse) {
    const env = environment.production ? 'live' : 'test';
    const billingAddressRequired = this.org && this.org.countryCode === 'US' ? true : false;
    const configuration = {
      environment: env, // Change to one of the environment values specified in step 4.
      clientKey: this.clientId, // Public key used for client-side authentication: https://docs.adyen.com/development-resources/client-side-authentication
      analytics: {
        enabled: true // Set to false to not send analytics data to Adyen.
      },
      session: {
        id: response.id, // Unique identifier for the payment session.
        sessionData: response.sessionData // The payment session data.
      },
      onPaymentCompleted: (result, component) => {
        console.info(result, component);
        this.handleServerResponse(result, component);

      },
      onError: (error, component) => {
        console.error(error.name, error.message, error.stack, component);
      },
      // Any payment method specific configuration. Find the configuration specific to each payment method:  https://docs.adyen.com/payment-methods
      // For example, this is 3D Secure configuration for cards:
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          billingAddressRequired: billingAddressRequired
        }
      }
    };

    this.config = configuration;

    // Create an instance of AdyenCheckout using the configuration object.
    const checkout = await AdyenCheckout(configuration);

    // Create an instance of Drop-in and mount it to the container you created.
    const element = document.querySelector('#dropin-container');
    if (element) {
      checkout.create('dropin').mount(element as HTMLElement);
    }
  }


  /**
   * This method is responsible to handle the response coming from make savvy payment.
   */
  private handleServerResponse(res: any, component: any) {
    if (res.action) {
      component.handleAction(res.action);
    } else {
      let status = PaymentStatus.PAID;
      switch (res.resultCode) {
        case 'Authorised':
          status = PaymentStatus.PAID;
          this.toastr.success(`Payment has been successful`, 'Success');
          break;
        case 'Pending':
          status = PaymentStatus.SUCCESS;
          this.toastr.success(`Payment is currently being processed.`, 'Success');
          break;
        case 'Received':
          status = PaymentStatus.PAID;
          this.toastr.success(`Payment has been successful`, 'Success');
          break;
        default:
          status = PaymentStatus.NONE;
          this.notify.message = 'Payment has been failed. Please try again.';
          this.notify.open();
          break;
      }
      this.updateStatus.emit(status);

    }
  }
}
