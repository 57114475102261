import {
  ContractId,
  CustomerId,
  EntityInstanceId,
  InvoiceId,
  MessageId,
  OrderId,
  QuoteId,
  ReminderId,
  TaskId
} from '@savvy/view-definition';
import {StudentId} from '@savvy/student';
import {LeadId} from '@savvy/leads';

export enum EventType {
  CANCEL_ADD_EDIT_TASK,
  CANCEL_PAY_INVOICE,
  CANCEL_VIEW_MESSAGE,
  CANCEL_SEND_MESSAGE,

  TASK_CREATED,
  TASK_UPDATED,
  TASK_MARKED_AS_DONE,
  TASK_EXECUTED,
  TASK_SELECTED,
  TASK_DESELECTED,

  EI_UPDATED_CALENDAR,
  REFRESH_CALENDAR,
  CALENDAR_ENTRY_STATE_CHANGE,
  REFRESH_CALENDAR_AFTER_DELETE_AND_CLOSE_VIEW,

  NUM_NOTIFICATIONS_CHANGED,

  INVOICE_UPDATED,
  INVOICE_SERVICE_UPDATED,

  CREATE_TASK,
  CLOSE_TASK,

  SENT_CONTRACT,
  SENT_QUESTIONNAIRE,
  SENT_QUOTE,

  CUSTOMER_CREATED,
  CUSTOMER_UPDATED,

  SEND_QUESTIONNAIRE,


  NEW_REMINDER,
  REMINDER_SELECTED,
  REMINDER_UPDATED,
  CLOSE_REMINDER,

  NEW_MESSAGE,
  MESSAGE_SELECTED,

  EDIT_ENTITY,
  VIEW_ENTITY,
  DELETE_ENTITY_INSTANCE,
  CANCEL_VIEW_ENTITY,


  SENT_INVOICE,
  SENT_PAYMENT_REQUEST,
  SENT_MESSAGE,
  SENT_ORDER,

  APPOINTMENT_CREATED,

  CONSUMER_SERVICES_UPDATED
}


export class CalendarEvent {
  start: Date;
  end: Date;
  entityInstanceId: EntityInstanceId;
}

export class CalendarEntryStateChangeEvent {
  state: string;
  entityInstanceId: EntityInstanceId;
}

export class UiEvent {
  eventType: EventType;
  taskId: TaskId;
  contractId: ContractId;
  customerId: CustomerId;
  studentId: StudentId;
  invoiceId: InvoiceId;
  reminderId: ReminderId;
  quoteId: QuoteId;
  orderId: OrderId;
  messageId: MessageId;
  entityInstanceId: EntityInstanceId;
  numUnreadNotifications: number;
  calendarEvent: CalendarEvent;
  calendarEntryStateChangeEvent: CalendarEntryStateChangeEvent;
  leadId: LeadId;


  public is(eventType: EventType): boolean {
    return this.eventType === eventType;
  }
}
