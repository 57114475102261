import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ChangeListener } from './changeListener';
import { TaskService, ListTasksGroupedResponse } from '@savvy/task';
import { TasksPanelComponent } from '../task/tasksPanel.component';
import { EventBusService } from './EventBusService';
import { EventType } from '../event/UiEvent';
import { ContextIdDto } from '@savvy/quickbooks';
import { ViewContextDto } from '@savvy/view-composite';

@Component({
    selector: 'app-entity-tasks-panel',
    templateUrl: 'entityTasksPanel.component.html'
})

export class EntityTasksPanelComponent implements OnInit {

    @Input() contextIdDto: ContextIdDto;
    @Input() viewContext: any;
    @Input() eventBus: EventBusService;
    @Input() showPlaceholder: boolean;
    @Input() changeListener: ChangeListener;

    response: ListTasksGroupedResponse;

    @ViewChild('tasksPanel', { static: false }) tasksPanel: TasksPanelComponent;

    constructor(
        private router: Router,
        public dialog: MatDialog,
        private api: TaskService) {
    }

    ngOnInit(): void {
        console.log('loading tasks for entity');
        this.getTasks();
    }


    getTasks() {
        console.log('get tasks called');
        if (this.viewContext.viewContextTypeDto === ViewContextDto.ViewContextTypeDtoEnum.ViewEntityInstance ||
          this.viewContext.viewContextTypeDto === ViewContextDto.ViewContextTypeDtoEnum.AddEditEntityInstance) {
          console.log('loading getTasksGroupedByExternalId');
            this.api.getTasksGroupedByExternalId(
                this.contextIdDto.contextId,
                this.contextIdDto.contextIdType,
                [this.viewContext.entityInstanceId.id],
                1,
                10).subscribe(response => {
                    console.log('num task sets is ' + response.taskSetDtos.length);
                    this.response = response;
                });
        } else if (this.viewContext.viewContextTypeDto === ViewContextDto.ViewContextTypeDtoEnum.Customer) {
            this.api.getTasksGroupedByExternalId(
                this.contextIdDto.contextId,
                this.contextIdDto.contextIdType,
                [this.viewContext.customerContextDto.customerId.id],
                1,
                10).subscribe(response => {
                    console.log('num task sets is ' + response.taskSetDtos.length);
                    this.response = response;
                });
        } else {
          console.log('none match! ', this.viewContext.viewContextTypeDto);
        }
    }

    openPanel() {

    }

    createTask() {
        this.eventBus.addEvent(EventType.CREATE_TASK);
    }
}

