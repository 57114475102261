<!--ng-scrollbar-->

<div *ngIf="!eventBus">
  VIEW ENTITY SUMMARY EVENT BUS IS EMPTY
</div>

<mat-card
  appearance="outlined"
  *ngIf="data?.entityInstanceId && loadEiViewResponse?.viewRootElementDto && form">
  <mat-card-content>
    <button mat-raised-button
            color="accent"
            (click)="dialogRef.close()">{{'Close' | translate}}
    </button>
  </mat-card-content>
</mat-card>

<app-element *ngIf="loadEiViewResponse && loadEiViewResponse.viewRootElementDto && form" style="width: 100%"
             [elementDto]="loadEiViewResponse.viewRootElementDto"
             [form]="form"
             [eventBus]="eventBus"
             [contextIdDto]="contextIdDto"
             [viewContext]="loadEiViewResponse.viewContextDto"
             [additionalDataMapDto]="loadEiViewResponse.additionalDataMapDto"
             [showPlaceholder]="true"
             [changeListener]="changeListener"
>
</app-element>
<!--/ng-scrollbar-->


<mat-card
  appearance="outlined"
  *ngIf="data?.entityInstanceId && loadEiViewResponse?.viewRootElementDto && form">
  <mat-card-content>
    <button mat-raised-button
            color="accent"
            (click)="dialogRef.close()">{{'Close' | translate}}
    </button>
  </mat-card-content>
</mat-card>
