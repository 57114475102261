import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AddressService } from '@savvy/address';
import { Address, ContextIdDto } from '@savvy/view-definition';
import { WindowRef } from '../../../windowWrapper';
import {OrgCompositeService} from "@savvy/org";

@Component({
  selector: 'app-address-lookup',
  templateUrl: './address-lookup.component.html'
})
export class AddressLookupComponent implements OnInit {

  @Input() address: Address;
  @Input() contextIdDto: ContextIdDto;
  @Output() modelUpdate = new EventEmitter<Address>();

  countryCode: string;
  addresses: Array<Address>;
  locationValue = '';
  googlePlacesLookup = false;


  constructor(
    private service: AddressService,
    private orgService: OrgCompositeService,
    private windowRef: WindowRef
  ) { }

  addressSelected() {
    this.addressUpdated();
  }

  onGoogleLocationUpdate(value: Address) {
    this.address = value;
    // this.address.longitude = value.lng() + '';
    // this.address.latitude = value.lat() + '';
    this.addressUpdated();
  }

  addressManuallyChanged() {
    this.addressUpdated();

  }

  openGoogleMaps() {
    const url = 'https://www.google.com/maps/dir/?api=1&dir_action=navigate&travelmode=driving&destination='
      + this.address.latitude + ',' + this.address.longitude;
    this.windowRef.getWindow().open(url, '_blank');
  }

  addressUpdated() {
    this.modelUpdate.emit(this.address);
    //   this.map.updateMap();
  }

  lookup(): void {
    if (this.locationValue && this.locationValue.length > 0) {
      this.service.lookupAddressByPostcode(this.locationValue,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType).subscribe(
          resp => {

            this.addresses = resp.addressList;
          });
    }
  }

  displayFn(address: Address) {
    if (!address) {
      return '';
    }
    if (!this.addresses) {
      return '';
    }
    const index = this.addresses.findIndex(state => state.address1 === address.address1);
    if (index === -1) {
      console.log('id is ' + address + ' index is' + index + ' this should never happen as tuples should always be there first');
      return '';
    } else {
      return this.address.address1;
    }
  }

  ngOnInit(): void {

    this.orgService.getCurrentOrgComp().subscribe(response => {
      this.countryCode = response.countryCode;
      if (this.countryCode == 'GB') {
        this.googlePlacesLookup = false;
      } else {
        this.googlePlacesLookup = true;
      }
    })

  }
}

