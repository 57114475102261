<div fxLayout="row wrap" fxLayout.gt-sm="row" fxLayoutGap="8px" class="w-100">
  <!-- <div fxFlex="nogrow">
    <div class="avatar avatar-50 circle border-3 mt-1">
      <mat-icon>home</mat-icon>
    </div>
  </div> -->
  <div fxFlex="100" fxFlex.gt-sm="32">
    <mat-form-field appearance="outline" class="field-center w-100">
      <mat-label>{{'FOOD_TO_SERVE' | translate}}</mat-label>
      <input id="foodToServe" matInput type="text" placeholder="Food To Serve" name="foodToServe" #foodToServe="ngModel" [(ngModel)]="petFeedingConfig.foodToServe">
    </mat-form-field>
  </div>

  <div fxFlex="47" fxFlex.gt-sm="16">
    <mat-form-field appearance="outline" class="field-center w-100">
      <mat-label>{{'FOOD_FEEDING' | translate}}</mat-label>
      <mat-select id="boarding-feeding-times" placeholder="{{'HOW_MANY_TIMES_DAY' | translate}}" [(ngModel)]="petFeedingConfig.howManyTimesADay" name="foodFeeding" #foodFeeding="ngModel">
        <mat-option [value]="1">
          1 {{'TIME_DAY' | translate}}
        </mat-option>
        <mat-option [value]="2">
          2 {{'TIME_DAY' | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div fxFlex="47" fxFlex.gt-sm="13">
    <mat-form-field appearance="outline" class="field-center w-100">
      <mat-label>{{'PRICE' | translate}}</mat-label>
      <input id="foodToServePrice" matInput type="text" appNumberOnly [decimalPoints]="2" placeholder="{{'UNIT_PRICE' | translate}}" name="foodToServePrice" #foodToServePrice="ngModel" [(ngModel)]="petFeedingConfig.price" (ngModelChange)="updateUnitPrice(petFeedingConfig)" required>
      <!-- <button *ngIf="pet" mat-icon-button color="accent" (mouseover)="getLastSeenPrice(petFeedingConfig)"
                  matTooltip="Last Price: {{lastPrice || 'N/A'}}" matTooltipPosition="above" matSuffix>
                  <mat-icon>info</mat-icon>
                </button> -->
    </mat-form-field>
  </div>
  <div fxFlex="47" fxFlex.gt-sm="13" *ngIf="taxDefs && taxDefs.length">
    <mat-form-field appearance="outline" class="field-center w-100">
      <mat-label>{{'TAX' | translate}}</mat-label>
      <mat-select id="petFeedingTax" placeholder="{{'SELECT_TAX_RATE' | translate}}" [(ngModel)]="petFeedingConfig.tax" name="petFeedingTax" #petFeedingTax="ngModel" (ngModelChange)="createBoardingBookingV2SyncService.updateGrossPriceForFeeding(petFeedingConfig)" [compareWith]="compareTax" [disabled]="!petFeedingConfig?.price">
        <mat-option value="" selected>{{'NO_TAX' | translate}}</mat-option>
        <mat-option *ngFor="let tax of taxDefs" [value]="tax.taxRate">
          {{ tax.taxName }} ({{ tax.taxRate }}%)
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div fxFlex="47" fxFlex.gt-sm="5">
    <mat-form-field appearance="outline" class="field-center w-100">
      <mat-label>{{'QTY' | translate}}</mat-label>
      <input id="feed-qty" matInput type="text" appNumberOnly [decimalPoints]="0" #feedQty="ngModel" [(ngModel)]="petFeedingConfig.quantity" readonly>
    </mat-form-field>
  </div>
  <div fxFlex="47" fxFlex.gt-sm="13">
    <mat-form-field appearance="outline" class="field-center w-100">
      <mat-label>{{'TOTAL_PRICE' | translate}}</mat-label>
      <input id="petFeedingGrossPrice" matInput type="text" appNumberOnly [decimalPoints]="2" minValue placeholder="Gross Price" name="petFeedingGrossPrice" #petFeedingGrossPrice="ngModel" [(ngModel)]="petFeedingConfig.grossPrice" (ngModelChange)="createBoardingBookingV2SyncService.calculateTotalAmount()" required>
    </mat-form-field>
  </div>
  <div fxFlex.gt-sm="nogrow" fxFlexAlign="center" fxLayout="column">
    <button id="appointment-remove-service" mat-icon-button color="accent" class="mdc-px-2 mdc-mb-1" (click)="remove()">
      <mat-icon>cancel</mat-icon>
    </button>
    <!-- <button id="appointment-add-service" mat-icon-button color="primary" (click)="addServiceNew(pet, true)"
    *ngIf="last">
    <mat-icon>add_circle_outline</mat-icon>
  </button> -->
  </div>

</div>