<mat-card appearance="outlined" class="upcoming-appointment mdc-mb-1" *ngIf="upcomingAppointment">
  <mat-card-content>
    <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="16px">
      <div fxFlex="nogrow">
        <div class="avatar avatar-bell circle border-3">
          <mat-icon aria-hidden="false" style="color: white;">notifications_none</mat-icon>
        </div>
      </div>
      <div fxFlex>
        <h6 class="font-weight-bold main-label mdc-py-1">{{"UPCOMING_APPOINTMENTS" | translate}}</h6>
        <p class="mat-text-secondary mb-0" *ngIf="upcomingAppointment?.startDateUtc">{{ upcomingAppointment?.label}}</p>
        <!-- <div class="time-label">{{ startTime | localizedDate: 'h:mm a' }} </div> -->
        <!-- - {{ endTime | localizedDate: 'hh:mm a' }} -->
      </div>
    </div>
  </mat-card-content>
</mat-card>
<!-- <ng-template #elseBlock>
  <div class="col-10 error-label">There are no upcoming appointments.</div>
</ng-template> -->