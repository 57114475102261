<h1 mat-dialog-title>{{'ADD_NEW_WORKFLOW' | translate}}</h1>
<form [formGroup]="workflowForm">

  <div mat-dialog-content>
    <div class="mt-1">
      <mat-form-field style="width: 100%" appearance="outline">
        <mat-label>{{'NAME' | translate}}</mat-label>
        <input matInput placeholder="{{'WORKFLOW_NAME' | translate}}" formControlName="workflowName" autofocus required>
      </mat-form-field>
    </div>
    <div class="mt-1">
      <mat-form-field style="width: 100%" appearance="outline">
        <mat-label>{{'WORKFLOW_TYPE' | translate}}</mat-label>
        <mat-select formControlName="workflowType">
          <mat-option *ngFor="let wfType of workflowTypes" [value]="wfType">{{wfType.label}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions [align]="'end'">
    <button mat-button (click)="cancel()">{{'CANCEL' | translate}}</button>
    <button *ngIf="!data.workflowDefinition" mat-raised-button color="primary" (click)="createWorkflow()"
      [disabled]="!workflowForm.valid" disabled="disabled" mat-button>{{'CREATE' | translate}}</button>
    <button *ngIf="data.workflowDefinition" mat-raised-button color="primary" (click)="updateWorkflow()"
      [disabled]="!workflowForm.valid" disabled="disabled" mat-button>{{'UPDATE' | translate}}</button>
  </div>
</form>
