<mat-toolbar color="primary">
  <mat-toolbar-row>{{'EDIT_INVOICE' | translate}}</mat-toolbar-row>
</mat-toolbar>
<div class="view-invoice-page" fxLayout="column" fxLayoutAlign="center">
  <mat-sidenav-container class="app-inner h-100" *ngIf="invoice && products">
    <div class="actions" fxFlex="100" fxLayout="row" fxLayoutAlign="space-between baseline">
      <div>
        <button (click)="backToList()" class="ml-xs mat-icon-button" mat-icon-button="">
          <span class="mat-button-wrapper">
            <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">arrow_back</mat-icon>
          </span>
          <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
          <div class="mat-button-focus-overlay"></div>
        </button>
      </div>
      <div>
        <button (click)="deleteInvoice()" class="ml-xs mat-icon-button" mat-icon-button="">
          <span class="mat-button-wrapper">
            <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">delete</mat-icon>
          </span>
          <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
          <div class="mat-button-focus-overlay"></div>
        </button>
      </div>
    </div>

    <mat-sidenav #sidePanel position="end" class="task-panel" mode="over" opened="false" style="width:80%">

      <app-send-invoice *ngIf="invoice && invoice.invoiceId &&  show === 'sendInvoice'" [contextIdDto]="contextIdDto" [visible]="true" [eventBus]="eventBus" (invoiceSent)="invoiceSent()" (invoiceSendCancelled)="invoiceSendCancelled()" [invoice]="invoice"></app-send-invoice>
      <app-view-payments *ngIf="invoice && invoice.invoiceId && show === 'viewPayments'" (invoiceUpdated)="this.invoice = $event" [contextIdDto]="contextIdDto" [visible]="true" [invoice]="invoice">
      </app-view-payments>
    </mat-sidenav>

    <div class="invoice-container" fxFlex="70" fxFlex.xs="100">


      <mat-card appearance="outlined">
        <mat-card-content>

          <form #invoiceForm="ngForm">

            <div fxLayout="column">
              <mat-form-field appearance="outline">
                <mat-label>{{'INVOICE_NO' | translate}}</mat-label>
                <input matInput [(ngModel)]="invoice.invoiceNumber" name="invoiceNumber" #name="ngModel" id="invoiceNumber" placeholder="{{'INVOICE_NO' | translate}}" style="width: 100%" required>
              </mat-form-field>


              <mat-form-field appearance="outline">
                <mat-label>{{'INVOICE_DATE' | translate}}</mat-label>
                <input matInput [(ngModel)]="invoice.invoiceDate" name="invoiceDate" (focus)="picker.open()" and (click)="picker.open()" #name="ngModel" [matDatepicker]="picker" placeholder="{{'INVOICE_DATE' | translate}}" required>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>{{'DUE_DATE' | translate}}</mat-label>
                <input matInput [(ngModel)]="invoice.dueDate" name="dueDate" (focus)="dueDatePicker.open()" and (click)="dueDatePicker.open()" #name="ngModel" [matDatepicker]="dueDatePicker" placeholder="{{'DUE_DATE' | translate}}" required>
                <mat-datepicker-toggle matSuffix [for]="dueDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #dueDatePicker></mat-datepicker>
              </mat-form-field>

              <!--mat-form-field appearance="outline" style="width: 100%"
                *ngIf="paymentScheduleDefs && invoice.paymentScheduleDefinitionId">
                <mat-label>{{'Payment Terms' | translate}}</mat-label>
                <mat-select placeholder="{{'Payment Terms' | translate}}" name="paymentTerms" #paymentTerms="ngModel"
                  [(ngModel)]="invoice.paymentScheduleDefinitionId.id" id="paymentTermsId" required>
                  <mat-option *ngFor="let paymentSchedule of paymentScheduleDefs"
                    [value]="paymentSchedule.paymentScheduleId.id">{{paymentSchedule.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field-->
            </div>

          </form>

        </mat-card-content>
        <hr>

        <div *ngIf="invoiceDto && invoiceDto.showProducts">
          <mat-card-content class="pl-0 pr-0 py-1">
            <mat-card-title style="margin: 0 0 10px 10px">{{'PRODUCTS' | translate}}</mat-card-title>
            <ngx-datatable class="material" [rows]="invoice.invoiceProductRows" [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'" [scrollbarH]="true">

              <ngx-datatable-column name="{{'DESCRIPTION' | translate}}" [flexGrow]="4">
                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <mat-label>{{'PRODUCT' | translate}}</mat-label>
                    <mat-select placeholder="{{'SELECT_PRODUCT' | translate}}" [(ngModel)]="row.productNumber" required (selectionChange)="productChanged(row)" name="product" #product="ngModel">
                      <mat-option *ngFor="let product of products" [value]="product.id">
                        {{ product.productName }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="product.hasError('required')">
                      {{'PRODUCT_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
                    </mat-error>
                  </mat-form-field>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="{{'UNIT_PRICE' | translate}}" [flexGrow]="1">
                <ng-template ngx-datatable-cell-template let-row="row">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'UNIT_PRICE' | translate}}</mat-label>
                    <input matInput type="text" appNumberOnly [decimalPoints]="2" placeholder="Unit Price" name="unitPrice" #unitPrice="ngModel" (change)="updateInvoice()" [(ngModel)]="row.unitPrice" id="unitPrice" required>
                    <mat-error *ngIf="unitPrice.hasError('required')">
                      {{'UNIT_PRICE_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
                    </mat-error>
                  </mat-form-field>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="{{'TAX_RATE' | translate}}" [flexGrow]="1">
                <ng-template ngx-datatable-cell-template let-row="row">
                  <strong>{{row.tax}}</strong>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="{{'QUANTITY' | translate}}" [flexGrow]="1">
                <ng-template ngx-datatable-cell-template let-row="row">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'QUANTITY' | translate}}</mat-label>
                    <input matInput type="text" appNumberOnly placeholder="Quantity" name="quantity" #quantity="ngModel" (change)="updateInvoice()" [(ngModel)]="row.quantity" id="quantity" required>
                    <mat-error *ngIf="quantity.hasError('required')">
                      {{'QUANTITY_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
                    </mat-error>
                  </mat-form-field>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="{{'DISCOUNT' | translate}}" [flexGrow]="1">
                <ng-template ngx-datatable-cell-template let-row="row">
                  <button (click)="onDiscountClick(row)" class="ml-xs mat-icon-button" mat-icon-button="" [matTooltip]="row?.discountDetails | discountTmpl:currencyCode">
                    <mat-icon role="img" [ngClass]="{'discount-applied': row?.discountDetails}" class="demo-toolbar-icon" aria-label="Discount">local_offer</mat-icon>
                  </button>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="{{'AMOUNT' | translate}}" [flexGrow]="1">
                <ng-template ngx-datatable-cell-template let-row="row">
                  <app-discount-price-view [originalPrice]="row.grossPriceNoOffer" [finalPrice]="row.grossPrice" [currencyCode]="currencyCode"></app-discount-price-view>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="{{'EDIT' | translate}}" [flexGrow]="1">
                <ng-template ngx-datatable-header-template>
                  <button mat-mini-fab color="accent" style="top: -10px !important;" type="button" (click)="createProductInvoiceRow()">
                    <mat-icon>add</mat-icon>
                  </button>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-row="row">

                  <!--
                        <button (click)="editRow(row)" class="ml-xs mat-icon-button" mat-icon-button="">
                <span class="mat-button-wrapper">
                    <mat-icon role="img" class="demo-toolbar-icon" aria-label="edit">edit</mat-icon>
                </span>
                            <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
                            <div class="mat-button-focus-overlay"></div>
                        </button>
                        -->
                  <button (click)="deleteProductRow(row)" class="ml-xs mat-icon-button" mat-icon-button="">
                    <span class="mat-button-wrapper">
                      <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">delete</mat-icon>
                    </span>
                    <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
                    <div class="mat-button-focus-overlay"></div>
                  </button>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </mat-card-content>

          <hr>
        </div>
        <div *ngIf="invoiceDto && invoiceDto.showServices">
          <mat-card-content class="pl-0 pr-0 py-1">
            <mat-card-title style="margin: 0 0 10px 10px">{{'SERVICES' | translate}}</mat-card-title>
            <ngx-datatable class="material" [rows]="invoice.invoiceServiceRows" [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'" [scrollbarH]="true">

              <ngx-datatable-column name="{{'SERVICE' | translate}}" [flexGrow]="4" *ngIf="consumerServices">
                <ng-template ngx-datatable-cell-template let-row="row">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <mat-label>{{'SERVICE' | translate}}</mat-label>
                    <mat-select placeholder="{{'SELECT_SERVICE' | translate}}" [(ngModel)]="row.serviceNumber" required (selectionChange)="serviceChanged(row)" name="service" #service="ngModel">
                      <mat-option *ngFor="let service of consumerServices" [value]="service.id">
                        {{ service?.serviceName }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="service.hasError('required')">
                      {{'SERVICE_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
                    </mat-error>
                  </mat-form-field>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="{{'UNIT_PRICE' | translate}}" [flexGrow]="1">
                <ng-template ngx-datatable-cell-template let-row="row">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'UNIT_PRICE' | translate}}</mat-label>
                    <input matInput type="text" appNumberOnly [decimalPoints]="2" placeholder="{{'UNIT_PRICE' | translate}}" name="serviceUnitPrice" #serviceUnitPrice="ngModel" (change)="updateInvoice()" [(ngModel)]="row.unitPrice" id="serviceUnitPrice" required>
                    <mat-error *ngIf="serviceUnitPrice.hasError('required')">
                      {{'UNIT_PRICE_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
                    </mat-error>
                  </mat-form-field>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="{{'TAX_RATE' | translate}}" [flexGrow]="1">
                <ng-template ngx-datatable-cell-template let-row="row">
                  <strong>{{row.tax}}</strong>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="{{'QUANTITY' | translate}}" [flexGrow]="1">
                <ng-template ngx-datatable-cell-template let-row="row">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'QUANTITY' | translate}}</mat-label>
                    <input matInput type="text" appNumberOnly placeholder="{{'QUANTITY' | translate}}" name="serviceQuantity" #serviceQuantity="ngModel" (change)="updateInvoice()" [(ngModel)]="row.quantity" id="serviceQuantity" required>
                    <mat-error *ngIf="serviceQuantity.hasError('required')">
                      {{'QUANTITY_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
                    </mat-error>
                  </mat-form-field>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="{{'DISCOUNT' | translate}}" [flexGrow]="1">
                <ng-template ngx-datatable-cell-template let-row="row">
                  <button (click)="onDiscountClick(row)" class="ml-xs mat-icon-button" mat-icon-button="" [matTooltip]="row?.discountDetails | discountTmpl:currencyCode">
                    <mat-icon role="img" [ngClass]="{'discount-applied': row?.discountDetails}" class="demo-toolbar-icon" aria-label="Discount">local_offer</mat-icon>
                  </button>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="{{'AMOUNT' | translate}}" [flexGrow]="1">
                <ng-template ngx-datatable-cell-template let-row="row">
                  <app-discount-price-view [originalPrice]="row.grossPriceNoOffer" [finalPrice]="row.grossPrice" [currencyCode]="currencyCode"></app-discount-price-view>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="{{'EDIT' | translate}}" [flexGrow]="1">
                <ng-template ngx-datatable-header-template>
                  <button mat-mini-fab color="accent" style="top: -10px !important;" type="button" (click)="createServiceInvoiceRow()">
                    <mat-icon>add</mat-icon>
                  </button>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-row="row">
                  <button (click)="deleteServiceRow(row)" class="ml-xs mat-icon-button" mat-icon-button="">
                    <span class="mat-button-wrapper">
                      <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">delete</mat-icon>
                    </span>
                    <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
                    <div class="mat-button-focus-overlay"></div>
                  </button>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </mat-card-content>
          <hr>
        </div>

        <div *ngIf="invoiceDto && invoiceDto.showCourses">
          <mat-card-content class="pl-0 pr-0 py-1">
            <mat-card-title style="margin: 0 0 10px 10px">{{'COURSES' | translate}}</mat-card-title>
            <ngx-datatable class="material" [rows]="invoice.invoiceCourseRows" [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'">

              <ngx-datatable-column name="{{'DESCRIPTION' | translate}}" [flexGrow]="3">
                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <mat-label>{{'COURSE' | translate}}</mat-label>
                    <mat-select placeholder="{{'SELECT_COURSE' | translate}}" [(ngModel)]="row.courseId" required (selectionChange)="courseChanged(row)" name="course" #course="ngModel">
                      <mat-option *ngFor="let courseDto of courseDtos" [value]="courseDto.course.id">
                        {{ courseDto.course.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="course.hasError('required')">
                      {{'COURSE_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
                    </mat-error>
                  </mat-form-field>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="{{'UNIT_PRICE' | translate}}" [flexGrow]="1">
                <ng-template ngx-datatable-cell-template let-row="row">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'UNIT_PRICE' | translate}}</mat-label>
                    <input matInput type="text" appNumberOnly [decimalPoints]="2" placeholder="{{'UNIT_PRICE' | translate}}" name="unitPrice" #unitPrice="ngModel" (change)="updateInvoice()" [(ngModel)]="row.unitPrice" id="unitPrice" required>
                    <mat-error *ngIf="unitPrice.hasError('required')">
                      {{'UNIT_PRICE_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
                    </mat-error>
                  </mat-form-field>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="{{'TAX_RATE' | translate}}" [flexGrow]="0.6">
                <ng-template ngx-datatable-cell-template let-row="row">
                  <strong>{{row.tax}}</strong>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="{{'QUANTITY' | translate}}" [flexGrow]="1">
                <ng-template ngx-datatable-cell-template let-row="row">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'QUANTITY' | translate}}</mat-label>
                    <input matInput type="text" appNumberOnly placeholder="{{'QUANTITY' | translate}}" name="quantity" #quantity="ngModel" (change)="updateInvoice()" [(ngModel)]="row.quantity" id="quantity" required>
                    <mat-error *ngIf="quantity.hasError('required')">
                      {{'QUANTITY_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
                    </mat-error>
                  </mat-form-field>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="{{'AMOUNT' | translate}}" [flexGrow]="1">
                <ng-template ngx-datatable-cell-template let-row="row">
                  <strong>{{ row.grossPrice | currency:currencyCode:'symbol'}}</strong>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="{{'EDIT' | translate}}" [flexGrow]="1">
                <ng-template ngx-datatable-header-template>
                  <button mat-mini-fab color="accent" style="top: -10px !important;" type="button" (click)="createProductInvoiceRow()">
                    <mat-icon>add</mat-icon>
                  </button>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-row="row">

                  <!--
                          <button (click)="editRow(row)" class="ml-xs mat-icon-button" mat-icon-button="">
                  <span class="mat-button-wrapper">
                      <mat-icon role="img" class="demo-toolbar-icon" aria-label="edit">edit</mat-icon>
                  </span>
                              <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
                              <div class="mat-button-focus-overlay"></div>
                          </button>
                          -->
                  <button (click)="deleteProductRow(row)" class="ml-xs mat-icon-button" mat-icon-button="">
                    <span class="mat-button-wrapper">
                      <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">delete</mat-icon>
                    </span>
                    <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
                    <div class="mat-button-focus-overlay"></div>
                  </button>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </mat-card-content>

          <hr>
        </div>


        <mat-card-content *ngIf="invoice" class="py-1">
          <div fxLayout="row" fxLayoutWrap="wrap" *ngIf="invoice">
            <div fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
              <strong *ngIf="invoice.discountId">{{discountItemsById[invoice.discountId]?.code || 'CUSTOM'}}</strong> -
              {{invoice?.discountDetails | discountTmpl:currencyCode}}
            </div>
            <div fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1" class="text-right">
              <button mat-raised-button color="accent" (click)="applyDiscountOnInvoice()">{{'APPLY_DISCOUNTS' | translate}}
              </button>
            </div>
          </div>
          <div fxLayout="row" fxLayoutWrap="wrap">
            <div fxFlex.gt-sm="25" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
              <h6 class="text-uppercase text-md ma-0 text-bold">{{'SUBTOTAL' | translate}}</h6>
              <h1 *ngIf="invoice?.discountId" class="ma-0">{{invoice.subTotalWithoutDiscount |
                currency:currencyCode:'symbol'}}</h1>
              <h1 *ngIf="!invoice?.discountId" class="ma-0">{{invoice.subTotal | currency:currencyCode:'symbol'}}</h1>
            </div>
            <div fxFlex.gt-sm="25" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
              <h6 class="text-uppercase text-md ma-0 text-bold">{{'TAX' | translate}}</h6>
              <h1 *ngIf="invoice?.discountId" class="ma-0">{{invoice.taxWithoutDiscount |
                currency:currencyCode:'symbol'}}</h1>
              <h1 *ngIf="!invoice?.discountId" class="ma-0">{{invoice.tax | currency:currencyCode:'symbol'}}</h1>
            </div>
            <div fxFlex.gt-sm="25" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
              <h6 class="text-uppercase text-md ma-0 text-bold">{{'DISCOUNT' | translate}}</h6>
              <h1 *ngIf="invoice?.discountId" class="ma-0">{{invoice.invoiceDiscount | currency:currencyCode:'symbol'}}
              </h1>
              <h1 *ngIf="!invoice?.discountId" class="ma-0">{{0.00 | currency:currencyCode:'symbol'}}</h1>
            </div>
            <div fxFlex.gt-sm="25" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1 text-xs-left text-sm-right">
              <h6 class="text-uppercase text-sm ma-0 text-bold">{{'TOTAL' | translate}}</h6>
              <h1 class="ma-0 mat-text-accent">{{ invoice.total |
                currency:currencyCode:'symbol'}}</h1>
            </div>
          </div>
        </mat-card-content>
        <hr>

        <mat-card-content>
          <p class="text-md">
            <strong>
              {{'PAYMENT_TERMS_AND_POLICIES' | translate}}
            </strong>
          </p>
          <mat-form-field class="w-100">
            <mat-label>{{'PAYMENT_TERMS' | translate}}</mat-label>
            <textarea matInput [(ngModel)]="invoice.invoiceTerms" name="invoiceTerms" #invoiceTerms="ngModel" id="invoiceTerms" placeholder="{{'INVOICE_TERMS' | translate}}" style="width: 100%">
              </textarea>
          </mat-form-field>
        </mat-card-content>
        <hr>

        <mat-card-content class="py-1" *ngIf="invoice && invoice.id">

          <button mat-raised-button color="accent" (click)="sendInvoice()" [disabled]="!invoiceForm.form.valid" disabled="disabled">{{'SEND_INVOICE' | translate}}
          </button>
          &nbsp;
          <button mat-raised-button color="accent" (click)="viewInvoice()" [disabled]="!invoiceForm.form.valid" disabled="disabled">{{'VIEW_INVOICE' | translate}}
          </button>
          &nbsp;
          <button mat-raised-button color="accent" (click)="updateInvoice()" [disabled]="!invoiceForm.form.valid" disabled="disabled">{{'SAVE_INVOICE' | translate}}
          </button>

          &nbsp;

          &nbsp;
          <button mat-raised-button color="accent" (click)="viewPayments()" [disabled]="!invoiceForm.form.valid" disabled="disabled">{{'VIEW_PAYMENTS' | translate}}
          </button>

          &nbsp;


        </mat-card-content>
      </mat-card>
    </div>

  </mat-sidenav-container>