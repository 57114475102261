<div fxLayout="row" *ngIf="teasersDto" fxLayoutWrap="wrap">
    <div fxFlex.gt-sm="20" fxFlex.gt-xs="20" flex>
        <mat-list>
            <mat-list-item *ngFor="let teaser of teasersDto.teasersDto.teasers" (click)="showEntity(teaser.entityInstanceId)">
                <a mat-list-avatar class="mat-avatar-gradient" routerLinkActive="true"
                   (click)="showEntity(teaser.entityInstanceId)">

                    <ngx-avatars *ngIf="teaser.imageInitials && teaser.imageInitials.length > 0" [name]="teaser.imageInitials" bgColor="lightgrey"></ngx-avatars>
                </a>
                <h3 mat-line (click)="showEntity(teaser.entityInstanceId)">{{teaser.headerAndLinesDto.header | translate}}</h3>
            </mat-list-item>
        </mat-list>
    </div>

    <div fxFlex.gt-sm="80" fxFlex.gt-xs="80" fxFlex="100" *ngIf="contextIdDto && entityInstanceToDisplayId">
        <app-view-entity [contextIdDto]="contextIdDto" [eventBus]="eventBus" [entityInstanceId]="entityInstanceToDisplayId"></app-view-entity>
    </div>
</div>

<button mat-fab color="accent" class="mat-fab-bottom-right" [disabled]="trialExpiredService.isTrialExpired()" (click)="create()" style="z-index: 9;">
    <mat-icon class="mat-24">add</mat-icon>
</button>
