<div fxLayout="column" fxFlex="100">
  <app-onboard-customer *ngIf="!createdCustomer" #onboardCustomerComponent [contextIdDto]="contextIdDto"
    (onboardUpdated)="onboardUpdated($event)" class="mdc-mb-2"></app-onboard-customer>

  <h6 class="font-weight-medium"><strong>Customer Information</strong></h6>
  <div *ngIf="!deviceService.isMobile()" class="new-pet">
    <form [formGroup]="customerFormGroup" fxLayout="row wrap" fxLayoutGap="10px" fxLayoutGap.gt-sm="16px">
      <div fxFlex="46" fxFlex.gt-sm="23" fxFlexAlign="center">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'CUSTOMER_FIRST_NAME' | translate: {Default: 'Customer First Name'} }}</mat-label>
          <input matInput placeholder="{{'CUSTOMER_FIRST_NAME' | translate: {Default: 'Customer First Name'} }}"
            formControlName="customerFirstName" required>
          <div class="control-error"
            *ngIf="customerFormGroup.controls.customerFirstName.touched && customerFormGroup.controls.customerFirstName.errors?.required">
            Please enter customer's first name.
          </div>
        </mat-form-field>
      </div>
      <div fxFlex="46" fxFlex.gt-sm="24">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'CUSTOMER_LAST_NAME' | translate: {Default: 'Customer Last Name'} }}</mat-label>
          <input matInput placeholder="{{'CUSTOMER_LAST_NAME' | translate: {Default: 'Customer Last Name'} }}"
            formControlName="customerLastName" required>
          <div class="control-error"
            *ngIf="customerFormGroup.controls.customerLastName.touched && customerFormGroup.controls.customerLastName.errors?.required">
            Please enter customer's last name.
          </div>
        </mat-form-field>
      </div>
      <div fxFlex="46" fxFlex.gt-sm="24">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'EMAIL' | translate: {Default: 'Email'} }}</mat-label>
          <input matInput placeholder="{{'EMAIL' | translate: {Default: 'Email'} }}" formControlName="customerEmail"
            email (blur)="checkIfCustomerExist()">
          <div class="control-error"
            *ngIf="customerFormGroup.controls.customerEmail.touched && customerFormGroup.controls.customerEmail.errors?.email">
            {{'ENTER_VALID_EMAIL' | translate: {Default: 'Please enter a valid email address'} }}
          </div>
          <div class="control-error"
            *ngIf="customerFormGroup.controls.customerEmail.touched && customerFormGroup.controls.customerEmail.errors?.userNameExist">
            {{'EMAIL_ALREADY_EXISTS' | translate: {Default: 'A customer with this email already exist'} }}
          </div>
        </mat-form-field>
      </div>
      <div fxFlex="46" fxFlex.gt-sm="23">
        <app-phone-input name="mobilePhoneNumber" (phoneChanged)="onMobilePhoneChange($event)"
          (countryChanged)="onMobileCountryChange($event)" [selectedCountryCode]="customerPhone?.country"
          label="{{'MOBILE' | translate: {Default: 'Mobile'} }}"
          placeholder="{{'MOBILE' | translate: {Default: 'Mobile'} }}" errorName="Mobile" required="false"
          [disabled]="customerFormGroup.disabled"></app-phone-input>
      </div>
    </form>
  </div>
  <div *ngIf="deviceService.isMobile()" class="new-pet">
    <form [formGroup]="customerFormGroup">
      <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.gt-sm="16px">
        <div fxFlex="46" fxFlex.gt-sm="48" fxFlexAlign="center">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{'CUSTOMER_FIRST_NAME' | translate: {Default: 'Customer First Name'} }}</mat-label>
            <input matInput placeholder="{{'' | translate}}" formControlName="customerFirstName" required>
          </mat-form-field>
        </div>
        <div fxFlex="50" fxFlex.gt-sm="49">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{'CUSTOMER_LAST_NAME' | translate: {Default: 'Customer Last Name'} }}</mat-label>
            <input matInput placeholder="{{'' | translate}}" formControlName="customerLastName" required>
          </mat-form-field>
        </div>
      </div>
      <div>
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'Email' | translate: {Default: 'Email'} }}</mat-label>
          <input matInput placeholder="{{'' | translate}}" formControlName="customerEmail" email>
          <div class="control-error"
            *ngIf="newPetFormControl.customerEmail.touched && newPetFormControl.customerEmail.errors?.email">
            {{'ENTER_VALID_EMAIL' | translate: {Default: 'Please enter a valid email address'} }}
          </div>
          <div class="control-error"
            *ngIf="newPetFormControl.customerEmail.touched && newPetFormControl.customerEmail.errors?.userNameExist">
            {{'EMAIL_ALREADY_EXISTS' | translate: {Default: 'Email address already exist'} }}
          </div>
        </mat-form-field>
      </div>
      <div>
        <app-phone-input class="w-100" name="mobilePhoneNumber" (phoneChanged)="onMobilePhoneChange($event)"
          (countryChanged)="onMobileCountryChange($event)" [selectedCountryCode]="customerPhone?.country"
          label="{{'MOBILE' | translate: {Default: 'Mobile'} }}"
          placeholder="{{'MOBILE' | translate: {Default: 'Mobile'} }}" errorName="Mobile" required="false"
          [disabled]="customerFormGroup.disabled"></app-phone-input>
      </div>
    </form>
  </div>
  <app-add-new-pet-v2 fxFlex="100" #newPetSelectorV2Component [contextIdDto]="contextIdDto"
    [customerId]="createdCustomer?.id" (petsFormGroupChange)="petsFormGroupChange($event)" [multiple]="true">
  </app-add-new-pet-v2>
  <div class="mt-1">
    <mat-accordion class="mdc-mb-1 display-block">
      <mat-expansion-panel class="m-0" [disabled]="customerFormGroup.disabled">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{'ADDRESS' | translate: {Default: 'Address'} }}
          </mat-panel-title>
          <mat-panel-description>
            {{'CUSTOMER_ADDRESS_INFO' | translate: {Default: 'Enter Customer Address'} }}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <app-address-lookup [contextIdDto]="contextIdDto" [address]="{}" (modelUpdate)="addressChanged($event)">
        </app-address-lookup>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <!-- </div> -->


  <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="end center" *ngIf="customerFormGroup.enabled">
    <!-- <div fxFlex="nogrow">
    <button class="btn_pad" mat-raised-button (click)="cancel()" color="accent" class="mr-1">
      <mat-icon>chevron_left</mat-icon> Go back
    </button>
  </div>
  <div fxFlex="auto">
  </div> -->
    <div fxFlex="nogrow">
      <button mat-raised-button color="accent" class="btn_pad"
        [disabled]="!customerFormGroup.valid || !newPetSelectorV2Component?.valid" (click)="createCustomer(true)">
        {{'CREATE_CUSTOMER_WITH_PET' | translate: {Default: 'Create Customer With Pet'} }}
      </button>
    </div>
  </div>
</div>