import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ElementControlService } from '../ElementControlService';
import { EicompService, EntityChangeEvent } from '@savvy/entity-instance-composite';
import { ChangeListener } from '../changeListener';
import { EntitysService } from '@savvy/entity-instance';
import { ElementInstanceDto, FieldDefinitionDto, FieldValueDto } from '@savvy/view-composite';
import { ContextIdDto } from '@savvy/datalist';

@Component({
  selector: 'app-colour-field-instance',
  templateUrl: 'colourFieldInstance.component.html'
})
export class ColourFieldInstanceComponent implements OnInit {

  @Input() fieldDefinition: FieldDefinitionDto;
  @Input() elementInstanceDto: ElementInstanceDto;
  @Input() entityInstanceId: string;
  @Input() contextIdDto: ContextIdDto;
  @Input() additionalDataMapDto: any;
  @Input() form: UntypedFormGroup;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;

  color: string;

  constructor(
    private eiApi: EntitysService,
    private api: EicompService,
    private ecs: ElementControlService) {
  }

  get isValid() {
    return this.form.controls[this.elementInstanceDto.instanceId].valid;
  }

  ngOnInit(): void {

    this.color = this.form.controls[this.elementInstanceDto.instanceId].value;
    this.form.controls[this.elementInstanceDto.instanceId].valueChanges.subscribe((value) => {
      // console.log('changed value=' + value);
      this.onChange(value);
    });
  }

  setColor(newColor: string) {
    console.log('newColor ' + newColor);
    this.color = newColor;
    this.form.controls[this.elementInstanceDto.instanceId].setValue(newColor);
  }

  onChange(newValue: string) {
    console.log('onChange detected value new value is ' + newValue);

    const fieldValue: FieldValueDto = {
      strValue: newValue,
      valueType: FieldValueDto.ValueTypeEnum.String
    };

    const req: EntityChangeEvent = {
      contextIdDto: this.contextIdDto,
      fieldValue,
      entityInstanceId: this.entityInstanceId,
      fieldInstanceId: this.elementInstanceDto.instanceId
    };

    // There is no loadProductsResponse from this, do we care?
    this.api.entityInstanceChangeEvent(req).subscribe(
      response => {
        console.log('response ' + response);
        this.ecs.handleChangedElements(response.changedElementList, this.form);
        if (this.changeListener) {
          this.changeListener.add('change');
        }
      }
    );
  }
}
