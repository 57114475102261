<!-- <mat-toolbar color="primary">

  <mat-toolbar-row>
    <span> {{'Workflows' | translate}}</span>
    <span class="example-spacer"></span>
  </mat-toolbar-row>
</mat-toolbar> -->

<ngx-datatable class='material' [columns]="columns" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
  [rowHeight]="'auto'" [limit]="10" [selectionType]="'cell'" (select)="onSelect($event)" [rows]='workflowLists'>
</ngx-datatable>
<button mat-raised-button class="" color="accent" (click)="create()">
  {{'CREATE_NEW' | translate}}
  <!-- <mat-icon class="mat-24 ml-05">add</mat-icon> -->
</button>
<!-- <mat-card>
  <mat-card-actions>
    <button (click)="create()" mat-raised-button color="primary">Create</button>

  </mat-card-actions>
</mat-card> -->
