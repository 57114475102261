import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ListTasksGroupedResponse, TaskService} from '@savvy/task';
import {MatSidenav} from '@angular/material/sidenav';
import {TasksPanelComponent} from './tasksPanel.component';
import {EventBusService} from '../element/EventBusService';
import {NavChangeService} from '../element/NavChangeService';
import {EventType, UiEvent} from '../event/UiEvent';
import {ContextIdDto, MenuDefinition} from '@savvy/view-definition';
import MenuItemTypeEnum = MenuDefinition.MenuItemTypeEnum;

@Component({
    selector: 'app-task-list',
    templateUrl: 'taskList.component.html',
    styleUrls: ['taskList.component.scss']

})
export class TaskListComponent implements OnInit {
    response: ListTasksGroupedResponse;
    addingOrEditingTask: boolean;
    @Input() contextIdDto: ContextIdDto;
    @Input() eventBus: EventBusService;
    @Output() toggleView = new EventEmitter();
    activePanel: string;
    zeroState: boolean;

    @ViewChild('taskPanel', { static: true }) taskPanel: MatSidenav;
    @ViewChild('tasksPanel', { static: false }) tasksPanel: TasksPanelComponent;

    private sub: any;
    url: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private api: TaskService,
        private navChange: NavChangeService) {

    }

    ngOnInit() {
        this.eventBus.eventAdded$.subscribe(event => this.onEvent(event));
        this.navChange.add(MenuItemTypeEnum.Tasks);
        console.log('calling get tasks from init');
        this.getTasks();
    }

    toggleTaskView() {
        this.toggleView.emit('toggle');
    }
    taskCreatedOrUpdated() {

        console.log('calling get tasks from taskCreatedOrUpdated');
        this.getTasks();
        this.addingOrEditingTask = false;
        this.closePanel();
    }

    createTask() {
        console.log('broadcasting create task');
        this.eventBus.addEvent(EventType.CREATE_TASK);
        this.openPanel('addEditTask');
    }

    openPanel(panelType: string) {
        this.activePanel = panelType;
        this.taskPanel.open();
    }

    closePanel() {
        this.activePanel = '';
        if (this.taskPanel) {
            this.taskPanel.close();
        }
    }

    getTasks() {
        console.log('calling get tasks ' + this.contextIdDto.contextId);
        this.api.getTasksGrouped(
            this.contextIdDto.contextId,
            this.contextIdDto.contextIdType,
            1,
            10).subscribe(response => {
                console.log('loadProductsResponse from getTasks is ' + response.numTasks);
                this.response = response;
                this.zeroState = response.numTasks === 0;
            });
    }

    closePanelAndReload() {
        this.closePanel();
        this.getTasks();
    }

    private onEvent(event: UiEvent) {
        console.log('tasks event is ' + event);

        if (event.eventType === EventType.TASK_CREATED) {
            this.taskCreatedOrUpdated();
        } else if (event.eventType === EventType.TASK_UPDATED) {
            this.taskCreatedOrUpdated();
        } else if (event.eventType === EventType.CLOSE_TASK) {
            this.closePanel();
        } else if (event.eventType === EventType.TASK_MARKED_AS_DONE) {
            this.getTasks();
            this.closePanel();
        } else if (event.eventType === EventType.TASK_EXECUTED) {
            this.getTasks();
            this.closePanel();
        } else if (event.eventType === EventType.CANCEL_ADD_EDIT_TASK) {
            this.closePanel();
        } else if (event.eventType === EventType.TASK_SELECTED) {
            this.openPanel('showTask');
        } else if (event.eventType === EventType.CREATE_TASK) {
            this.router.navigate(['/task/addTask',
                this.contextIdDto.contextId,
                this.contextIdDto.contextIdType]);
        }
    }
}

