<mat-card appearance="outlined" *ngIf="showFilters" class="mdc-m-0">
  <mat-card-content class="mdc-pb-0">
    <div fxLayout="row wrap" fxLayoutAlign="" fxLayoutGap="5px" fxLayoutGap.gt-md="1%">
      <!-- <div fxFlex="100" fxFlex.gt-md="nogrow">
        <mat-radio-group aria-label="Show Archived?" [ngModel]="includeDeleted" (ngModelChange)="includeDeleted = $event; listInstances()">
          <mat-radio-button class="example-radio-button px-8" [value]="true">
            <div class="text-bold">{{'SHOW_ARCHIVED' | translate}}</div>
          </mat-radio-button>
          <mat-radio-button class="example-radio-button px-8" [value]="false">
            <div class="text-bold">{{'HIDE_ARCHIVED' | translate}}</div>
          </mat-radio-button>
        </mat-radio-group>
      </div> -->
      <div fxFlex="100" fxFlex.gt-xs="49" fxFlex.gt-sm="20">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Customer, Email, Phone...</mat-label>
          <input matInput placeholder="Customer Name, Email, Phone..." [(ngModel)]="customerFilter.searchKey"
            (ngModelChange)="searchKeyUpdated($event)">
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
      <div fxFlex="100" fxFlex.gt-xs="49" fxFlex.gt-sm="18">
        <!-- make this multi select-->
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Customer Status</mat-label>
          <mat-select [(ngModel)]="customerFilter.status" (ngModelChange)="statusChanged($event)">
            <mat-option [value]="statusEnum.Active">Active</mat-option>
            <!--mat-option [value]="statusEnum.InActive">Inactive</mat-option-->
            <mat-option [value]="statusEnum.Archived">Archived Only</mat-option>
            <mat-option [value]="statusEnum.NotVerified">Needs Verification</mat-option>
            <mat-option [value]="statusEnum.AwaitingRegistration">Awaiting Form</mat-option>
            <mat-option [value]="statusEnum.Merged">Merged</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="100" fxFlex.gt-xs="100" fxFlex.gt-sm="36">
        <app-flag-filter (includeFlagsChanged)="includeFlagsChange($event);"
          (excludeFlagsChanged)="excludeFlagsChange($event);" [contextIdDto]="contextIdDto"
          [flagDefinitionType]="'CUSTOMER'" #flagFilterComponent></app-flag-filter>
      </div>
      <!-- <div fxFlex="nogrow">
        <p class="small mat-text-secondary mb-0 py-1">Archived Customer</p>
        <mat-slide-toggle [(ngModel)]="includeDeleted" (ngModelChange)="listInstances()">Show
          Archived</mat-slide-toggle>
      </div> -->
      <div fxFlex></div>
      <div fxFlex="nogrow">
        <button (click)="resetFilters()" mat-button color="accent"
          class="mt-1 mdc-mb-1 mb-md-0"><mat-icon>cancel</mat-icon>
          Clear Filter</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<div class="mdc-p-3">
  <h6 class="resulttext" *ngIf="(page.totalPages) > 0">
    <span>Showing page&nbsp;</span>
    <span>{{ page.pageNumber + 1 }}</span> of {{ page.totalPages + 1 }}

    <span>for your search:&nbsp; <span *ngIf="customerFilter.searchKey">"{{ customerFilter.searchKey }}",&nbsp;</span>
    </span>
    <span>
      "<span *ngIf="customerFilter.status == statusEnum.Active">Active</span>
      <span *ngIf="customerFilter.status == statusEnum.Archived">Archived</span>
      <span *ngIf="customerFilter.status == statusEnum.NotVerified">Needs Verification</span>
      <span *ngIf="customerFilter.status == statusEnum.AwaitingRegistration">Awaiting Form</span>"

      customers &nbsp;</span>

    <span *ngIf="customerFilter.includeFlagDefs?.length || customerFilter.excludeFlagDefs?.length">
      with flags
      <span *ngIf="customerFilter.includeFlagDefs?.length">including "<span>{{includeFlagsNames()}}</span>"
        &nbsp;</span>
      <span *ngIf="customerFilter.excludeFlagDefs?.length">excluding "<span>{{excludeFlagsNames()}}</span>"</span>
    </span>

  </h6>
</div>

<ng-container *ngIf="!loaded || (customers.length > 0 && customers)">
  <!-- customer responsive card-->
  <div *ngIf="deviceService.isMobile() || deviceService.isTablet()" fxLayout.gt-xs="row wrap" fxLayoutAlign=""
    fxLayout="column" fxLayoutGap="0" fxLayoutGap.gt-xs="2%" class="mdc-my-2 mdc-px-2  search-results" infiniteScroll
    [infiniteScrollDistance]="scrollDistance" [infiniteScrollUpDistance]="scrollUpDistance" [scrollWindow]="false"
    [infiniteScrollThrottle]="throttle" (scrolled)="onScrollDown($event)">
    <!-- customer card-->
    <div fxFlex="100" fxFlex.gt-xs="48" fxFlex.gt-sm="32" *ngFor="let row of customers">
      <mat-card appearance="outlined" class="mdc-mb-1">
        <mat-card-content>
          <div fxLayout="row" fxLayoutAlign=" " fxLayoutGap="10px">
            <div fxFlex="50px" fxFlex.gt-sm="25">
              <div class="avatar avatar-50 circle border-2 pet-pic coverimg mx-auto"
                [style.background-image]="'url(' + customerImage + ')'" (click)="view(row)"></div>
            </div>
            <div fxFlex="65" fxFlex.gt-sm="65">
              <h6 class="dog-name mb-0" (click)="view(row)">
                <span class="mr-5px font-weight-bold vm">{{row?.firstName}} {{row?.lastName}}</span>
                <span class="chiptag green vm mr-5px" *ngIf="!row?.deleted">Active</span>
                <span class="chiptag red vm mr-5px" *ngIf="row?.deleted">InActive</span>
              </h6>
              <p *ngIf="row.email"><a href="mailto: {{ row.email }}"><span
                    class="chiptag theme-accent vm mt-5px"><mat-icon>email</mat-icon> {{ row.email }}</span></a></p>
              <p *ngIf="row.mobileInternational"><a href="tel: {{ row.mobileInternational }}"><span
                    class="chiptag theme vm mt-5px"><mat-icon>call</mat-icon> {{ row.mobileInternational }} </span></a>
              </p>
              <app-view-customer-flags *ngIf="row?.flags?.length" [contextIdDto]="contextIdDto" [flags]="row.flags">
              </app-view-customer-flags>
              <!-- <mat-chip-list class="my-2">
                <mat-chip removable="true" selected="">NC</mat-chip>
              </mat-chip-list> -->
            </div>
            <div fxFlex class="text-end">
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu" class="text-start">
                <button mat-menu-item color="primary" (click)="view(row)">
                  <mat-icon>visibility</mat-icon>
                  <span>View</span>
                </button>
                <button mat-menu-item (click)="edit(row)">
                  <mat-icon>edit</mat-icon>
                  <span>Edit</span>
                </button>
                <button mat-menu-item color="accent" *ngIf="!row.deleted && !row?.verified"
                  (click)="archive(row); $event.stopImmediatePropagation();">
                  <mat-icon>inventory_2</mat-icon>
                  <span>{{'ARCHIVE' | translate}}</span>
                </button>
                <button *ngIf="row.deleted" mat-menu-item color="accent"
                  (click)="unarchive(row); $event.stopImmediatePropagation();">{{'UN_ARCHIVE' | translate}}</button>
                <button *ngIf="row.deleted" title="Delete Permanently" mat-menu-item color="accent"
                  (click)="deleteCustomerForever(row); $event.stopImmediatePropagation();" class="ml-1">{{'DELETE' |
                  translate}}</button>

                <button *ngIf="!row.verified && row.formSubmittedDate" mat-menu-item color="accent"
                  (click)="verify(row); $event.stopImmediatePropagation();"><mat-icon>verified</mat-icon> {{'VERIFY' |
                  translate: {Default: 'Verify'} }}</button>
              </mat-menu>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <!-- customer card ends -->
  </div>
  <!-- customer responsive card ends -->

  <ngx-datatable *ngIf="!deviceService.isMobile() && !deviceService.isTablet()" class='material mdc-m-1'
    [rows]='customers' [columnMode]="'force'" [headerHeight]="50" [scrollbarH]="true" [rowHeight]="'auto'"
    [footerHeight]="50" [externalPaging]="true" [count]="page.totalElements" [offset]="page.pageNumber"
    [limit]="page.size" (page)='setPage($event)'>
    <!-- (select)="onSelect($event)" -->

    <ngx-datatable-column name="{{'NAME' | translate}}" [width]="150" [resizeable]="true" [sortable]="true"
      [draggable]="true" [canAutoResize]="true" prop="firstName">
      <ng-template ngx-datatable-cell-template let-row="row">
        <a href="javascript:void(0)" (click)="view(row); $event.stopImmediatePropagation();" class="table-link"
          color="accent">
          {{row.firstName}} {{row.lastName}}
        </a>
        <span *ngIf="row?.invitedViaEmailDate && row?.formSubmittedDate == null">(Awaiting Form)</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'EMAIL' | translate}}" [width]="120" [resizeable]="true" [sortable]="true"
      [draggable]="true" [canAutoResize]="true" prop="email">
      <ng-template ngx-datatable-cell-template let-row="row">
        <div *ngIf="row?.email" class="chiptag theme vm" (click)="emailTo(row?.email)">
          <mat-icon>email</mat-icon> <span>{{ row?.email }}</span>
        </div>

        <br>
        <span *ngIf="row?.invitedViaEmailDate && row?.formSubmittedDate == null">(Invited on {{row?.invitedViaEmailDate
          | localizedDate: 'short'}})</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'MOBILE' | translate}}" [width]="120" [resizeable]="true" [sortable]="true"
      [draggable]="true" [canAutoResize]="true" prop="mobileInternational">
      <ng-template ngx-datatable-cell-template let-row="row">
        <div class="chiptag theme  vm" *ngIf="row.mobileInternational" (click)="callMobile(row.mobileInternational)">
          <mat-icon>phone</mat-icon> {{row.mobileInternational}}
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'PETS' | translate}}" [width]="120" *ngIf="showPetRelated" [resizeable]="true"
      [sortable]="true" [draggable]="true" [canAutoResize]="true" prop="petLabel">
      <ng-template ngx-datatable-cell-template let-row="row">
        {{row.petLabel}}
      </ng-template>
    </ngx-datatable-column>
    <!--ngx-datatable-column name="{{'LAST_SEEN' | translate}}" [width]="120">
      <ng-template ngx-datatable-cell-template let-row="row">
        {{row.lastSeenDate}}
      </ng-template>
    </ngx-datatable-column-->
    <!--ngx-datatable-column name="{{'NEXT_APPOINTMENT_DATE' | translate}}" [width]="120">
      <ng-template ngx-datatable-cell-template let-row="row">
        {{row.nextAppointmentDate}}
      </ng-template>
    </ngx-datatable-column-->
    <!--ngx-datatable-column name="Phone" [width]="120">
      <ng-template ngx-datatable-cell-template let-row="row">
        {{ row.userDto.phoneNumberObj.internationalNumber }}
      </ng-template>
    </ngx-datatable-column-->
    <ngx-datatable-column name="{{'FLAGS' | translate}}" [width]="200" [sortable]="false">
      <ng-template ngx-datatable-cell-template let-row="row">
        <app-view-customer-flags *ngIf="row.id" [contextIdDto]="contextIdDto" [flags]="row.flags">
        </app-view-customer-flags>
      </ng-template>
    </ngx-datatable-column>
    <!-- <ngx-datatable-column name="{{'INVITED_DATED' | translate}}" [width]="200" [resizeable]="true" [sortable]="true"
      [draggable]="true" [canAutoResize]="true" prop="invitedViaEmailDate">
      <ng-template ngx-datatable-cell-template let-row="row">
        {{row.invitedViaEmailDate | localizedDate:'medium'}}
      </ng-template>
    </ngx-datatable-column> -->
    <ngx-datatable-column name="{{ 'ACTION' | translate: {Default: 'Actions'} }}" [width]="200" [sortable]="false">
      <ng-template ngx-datatable-cell-template let-row="row">

        <button *ngIf="!row.deleted" class="mr-5px" mat-button color="primary"
          (click)="view(row); $event.stopImmediatePropagation();"><mat-icon>visibility</mat-icon> {{'VIEW' | translate:
          {Default: 'View'} }}</button>

        <button *ngIf="!row.deleted" class="mr-5px" mat-button color="accent"
          (click)="edit(row); $event.stopImmediatePropagation();"><mat-icon>edit</mat-icon> {{'EDIT' | translate:
          {Default: 'Edit'} }}</button>

        <button *ngIf="!row.verified && row.formSubmittedDate" class="mr-5px" mat-button color="primary"
          (click)="verify(row); $event.stopImmediatePropagation();"><mat-icon>verified</mat-icon> {{'VERIFY' |
          translate: {Default: 'Verify'} }}</button>

        <button *ngIf="!row.deleted" mat-button color="default" class="mr-5px"
          (click)="archive(row); $event.stopImmediatePropagation();"><mat-icon>inventory_2</mat-icon> {{'ARCHIVE' |
          translate}}</button>

        <button *ngIf="row.deleted && !row.merged" mat-button color="default" class="mr-5px"
          (click)="unarchive(row); $event.stopImmediatePropagation();"><mat-icon>unarchive</mat-icon> {{'UN_ARCHIVE' |
          translate}}</button>

        <button *ngIf="row.deleted" title="Delete Permanently" mat-button color="default"
          (click)="deleteCustomerForever(row); $event.stopImmediatePropagation();"><mat-icon>delete</mat-icon>
          {{'DELETE' | translate}}</button>

      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>

</ng-container>


<div class="flexbox-parent" *ngIf="customers && customers.length === 0 && loaded">
  <div fxLayout="row" fxLayoutAlign="center center" style="height: 60vh;  ">
    <div style="text-align:center;">
      <app-zero-image name="{{'CUSTOMER' | translate}}" class="zero-image"></app-zero-image><br>
      <h5 class="ma-0">{{"NO_CUSTOMERS" | translate}}</h5><br>

    </div>
  </div>
</div>
