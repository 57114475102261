<div
  *ngIf="loadCachedTableViewResponse && loadCachedTableViewResponse.table && loadCachedTableViewResponse.table.contents && loadCachedTableViewResponse.table.contents.length > 0">

  <mat-toolbar color="primary">{{ cachedTableViewDefinition.label | translate}}</mat-toolbar>
  <div class="m-2">
    <ngx-datatable *ngIf="rows" class='material has-plus-btn' [rows]='rows' [columns]="columns" [columnMode]="'force'"
      [headerHeight]="50" [rowHeight]="'auto'" [footerHeight]="50" [selected]="selected" [selectionType]="'single'"
      [externalPaging]="true" [count]="loadCachedTableViewResponse.table.totalElements" [offset]="page.pageNumber"
      [limit]="page.size" (activate)="onActivate($event)" (select)="onSelect($event)" (page)='setPage($event)'>
    </ngx-datatable>
  </div>

  <!--button [disabled]="trialExpiredService.isTrialExpired()" mat-fab color="accent" class="mat-fab-bottom-right"
    (click)="create()" style="z-index: 9;">
    <mat-icon class="mat-24">add</mat-icon>
  </button-->
</div>

<app-zero-eis
  *ngIf="loadCachedTableViewResponse && loadCachedTableViewResponse.table &&
loadCachedTableViewResponse.table.contents && loadCachedTableViewResponse.table.contents.length == 0 && entityDefinitionDto"
  [contextIdDto]="contextIdDto" [entityDefinitionId]="cachedTableViewDefinition.entityDefinitionId.id"
  [entityDefinitionIcon]="entityDefinitionDto.iconName" [entityDefinitionLabel]="entityDefinitionDto.name"
  [canCreate]="true">
</app-zero-eis>
