import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { AppcompService } from '@savvy/app';
import { MenuStructure } from '@savvy/menu';
import { DomainName, LoginDataDto, ProductMetadataService } from '@savvy/product-metadata';
import { ContextIdDto } from '@savvy/subscription';
import { UiAuthService } from './flo/auth/ui-auth.service';
import { ContextService } from './flo/context.service';
import { NavigateService } from './flo/entry/NavigateService';
import { LocalMenuService } from './flo/menu/local-menu.service';
import { AuthData } from './flo/shared/services/local-storage.service';
import { SmsCreditService } from './flo/shared/services/sms-credit.service';
import { TrialExpiredService } from './flo/shared/services/trial-expired.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class BootstrapService {

  loadedRequiredData = false;
  contextIdDto: ContextIdDto;
  loginImageUrl = '';

  constructor(
    private contextService: ContextService,
    private trailExpiredService: TrialExpiredService,
    private smsCreditService: SmsCreditService,
    private appcompService: AppcompService,
    private navigateService: NavigateService,
    private localMenuService: LocalMenuService,
    private uiAuthService: UiAuthService,
    private authService: AuthService,
    private router: Router,
    private productMetadataService: ProductMetadataService) { }

  public doChecks(contextIdDto: ContextIdDto) {
    console.log('doing checks');
    this.trailExpiredService.checkTrialExpired(contextIdDto);
    this.smsCreditService.loadSMSReport(contextIdDto);
    this.smsCreditService.checkSMSCredits(contextIdDto);
  }

  loadProductLoginView(): Promise<LoginDataDto> {
    return new Promise<LoginDataDto>(resolve => {

      const req = <DomainName>{};
      req.domainName = location.hostname;
      try {
        console.log('calling loadLoginView from BootstrapService');
        this.productMetadataService.loadLoginViewByDomain(req).subscribe((response: LoginDataDto) => {
          resolve(response);
        });
      } catch (error) {
        console.error('Error in loadLoginView from BootstrapService');
        console.error(error);
      }
    })
  }

  getPrimaryColor(theme: string | undefined) {
    switch (theme) {
      case 'app-light-green':
        // Pet Stay
        return '#ff8000';
      case 'app-studio2':
        // Photo Studio
        return '#7b27a2';
      case 'app-beauty-salon':
        // Beauty Salon
        return '#fd4182';
      case 'app-beauty-salon':
        // Beauty Salon
        return '#fd4182';
      case 'app-lavender':
        // Groom school
        return '#f44336';
      case 'app-vets':
        // Vets
        return '#c2a6f8';
      default:
        // Pet Spa
        return '#78beb8';
    }
  }

  public process(contextIdDto: ContextIdDto, menuStructure: MenuStructure) {
    console.log('got contextId to in dashboard use ' + contextIdDto.contextId
      + ' and ' + contextIdDto.contextIdType);
    if (menuStructure && menuStructure.menuDefinitionList && menuStructure.menuDefinitionList.length > 0) {
      this.localMenuService.setMenuStructure(menuStructure);
      const allowRedirectionToMenus = !location.pathname.includes('logout') && !location.pathname.includes('subscription');
      const isAdminMenu = sessionStorage.getItem('isAdminMenu');
      if (!isAdminMenu || isAdminMenu === 'false') {
        const selectedMenuItemType = sessionStorage.getItem('selectedMenuItemType');
        if (selectedMenuItemType) {
          if (allowRedirectionToMenus) {
            const selectedMenuDefinition = menuStructure.menuDefinitionList.find(menu => menu.menuItemType === selectedMenuItemType);
            if (selectedMenuDefinition) {
              this.navigateService.navigateTo(this.contextIdDto, selectedMenuDefinition);
            } else {
              this.navigateService.navigateTo(this.contextIdDto, menuStructure.menuDefinitionList[0]);
            }
          }
        } else {
          if (allowRedirectionToMenus) {
            this.navigateService.navigateTo(this.contextIdDto, menuStructure.menuDefinitionList[0]);
          }
        }
      } else {
        if (allowRedirectionToMenus) {
          this.router.navigate(['admin/settings', contextIdDto.contextId, contextIdDto.contextIdType]);
        }
      }
      this.loadedRequiredData = true;
    }
    this.doChecks(contextIdDto);
  }

  public gotoViewCalendar(contextIdDto: ContextIdDto) {
    this.navigateService.navigateTo(this.contextIdDto, this.localMenuService.menuStructure.menuDefinitionList[0]);
    this.trailExpiredService.checkTrialExpired(contextIdDto);
  }

  public bootstrap() {
    console.log('Bootstrapping App');
    this.appcompService.getEnvCurrentContext().subscribe(contextIdDto => {
      // console.log('got contextIdDto ' + contextIdDto);
      if (contextIdDto?.contextId) {
        this.contextService.setContextDto({ contextId: contextIdDto.contextId, contextIdType: contextIdDto.contextIdType });
        this.uiAuthService.contextIdDto = contextIdDto;
        this.contextIdDto = contextIdDto;
        const authData: AuthData = {
          envId: contextIdDto?.contextId
        };
        this.uiAuthService.loadPostLoginData(authData).then((res) => {
          this.process(contextIdDto, res?.menuStructure);
        });
      } else {
        this.uiAuthService.logout();
        this.loadProductLoginView().then((res: LoginDataDto) => {
          this.authService.loginWithRedirect({
            appState: {
              target: window.location.origin,
            },
            authorizationParams: {
              domainName: location.host
            },
          });
        })
      }
    }, () => {
      this.uiAuthService.logout();
      this.loadProductLoginView().then((res: LoginDataDto) => {
        this.authService.loginWithRedirect({
          appState: {
            target: window.location.origin,
          },
          authorizationParams: {
            domainName: location.host
          },
        });
      });
    });
  }


  forceReload() {
    if (environment.production) {
      console.log('prod');
      const form = document.createElement('form');
      form.method = 'GET';
      form.action = location.href;
      document.body.appendChild(form);
      form.submit();
    } else {
      window.location.reload();
    }
  }


}
