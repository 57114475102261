import {Injectable} from '@angular/core';
import {ElementDto, EntityDefinitionDto} from '@savvy/entity-instance-composite';

@Injectable()
export class ElementDefLookupService {

    /**
     *
     *
     * This should be a straight Map
     *
     *
     *
     * @returns
     */
    public getDefinition(entityDefinitionDto: EntityDefinitionDto, elementDefinitionId: string): ElementDto {

        // console.log('looking for def for name ' + this.elementDto.elementInstanceDto.elementDefName);
        let retDef = null;
        if (!entityDefinitionDto || !entityDefinitionDto.elementDtoList) {
            // console.log('entity def is ' + this.entityDefinitionDto);
        } else {
            // console.log('looking through child elements');

            entityDefinitionDto.elementDtoList.forEach(elementDef => {

                const retVal = this.getDefinitionFromDef(elementDef, elementDefinitionId);

                // console.log('got ' + retVal + ' from elementDef ' + elementDef.name);

                if (retVal) {
                    retDef = retVal;
                }
            });
        }
        // console.log('returning ' + retDef);
        return retDef;
    }

    private getDefinitionFromDef(elementDef: ElementDto, elementDefinitionId: string): ElementDto {

        let retDef: ElementDto = null;
        if (elementDef.id === elementDefinitionId) {
            retDef = elementDef;
        } else {
            if (elementDef.elementSetDefinitionDto) {
                // console.log('found an element set def');
                elementDef.elementSetDefinitionDto.elementDtos.forEach(elementDef2 => {
                    const retDef2 = this.getDefinitionFromDef(elementDef2, elementDefinitionId);

                    // console.log('got retDef2 ' + retDef2 + ' from element def ' + elementDef2.name);
                    if (retDef2) {
                        retDef = retDef2;
                    }
                });
            }
        }
        return retDef;
    }

}
