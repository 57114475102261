
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContextIdDto } from '@savvy/view-definition';
import { LinkId } from 'src/app/core/data-share.service';

@Component({
  selector: 'app-add-contract-modal',
  templateUrl: './add-contract-modal.component.html',
  styleUrls: ['./add-contract-modal.component.scss']
})
export class AddContractModalComponent implements OnInit {
  contextIdDto: ContextIdDto;
  linkId: LinkId;
  isContractModal = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddContractModalComponent>) { }

  ngOnInit() {
    if (this.data) {
      this.contextIdDto = this.data.contextIdDto;
      this.linkId = this.data.linkId;
      this.isContractModal = this.data.isContractModal;
    }
  }

  contractEvent(isCreated: boolean) {
    if (isCreated) {
      this.dialogRef.close(isCreated);
    }
  }

}
