<mat-form-field appearance="outline" style="width: 100%">
  <mat-label>{{'CUSTOMER_NAME' | translate}}</mat-label>
  <input matInput #searchInput="matInput" placeholder="{{'START_TYPING_TO_SEARCH_CUSTOMERS' | translate}}"
    (keyup)="onKey($event)" autofocus="false" autocomplete="off" type="text" [matAutocomplete]="auto"
    [disabled]="disabled">
  <mat-icon matSuffix>search</mat-icon>
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)" (optionSelected)="handler($event)"
    autoActiveFirstOption="false">
    <mat-option [value]="''" *ngIf="suppportCreate" disabled>
      <button type="button" mat-raised-button color="accent" (click)="createCustomer()">
        {{'CREATE_CUSTOMER' | translate}}
      </button>
    </mat-option>
    <mat-option disabled *ngIf="customers && !customers.length" value="">
      {{'CUSTOMER_NOT_FOUND' | translate}}
    </mat-option>
    <mat-option *ngFor="let tuple of customers" [value]="tuple.id">
      {{ tuple.name }}
    </mat-option>
  </mat-autocomplete>

</mat-form-field>