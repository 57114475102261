import { Injectable } from '@angular/core';
import { ContextIdDto } from '@savvy/app';
import { Tax, TaxCompositeService } from '@savvy/tax';

@Injectable({
  providedIn: 'root'
})
export class TaxesHelperService {
  taxDefs: Tax[] = [];

  constructor(
    private taxCompositeService: TaxCompositeService
  ) { }

  public loadTaxDefs(contextIdDto: ContextIdDto) {
    this.taxDefs = [];
    return new Promise<Tax[]>(resolve => {
      if (!contextIdDto?.contextId) {
        resolve([]);
        return;
      }
      this.taxCompositeService.loadTaxes(contextIdDto.contextId, contextIdDto.contextIdType).subscribe(response => {
        this.taxDefs = response.taxs;
        resolve(this.taxDefs);
      });
    });
  }

  public getTaxDefs(contextIdDto: ContextIdDto, fresh = false, getDeleted = false): Promise<Array<Tax>> {
    return new Promise<Tax[]>(async (resolve) => {
      if (this.taxDefs.length && !fresh) {
        if (getDeleted) {
          resolve(this.taxDefs);
        } else {
          resolve(this.taxDefs.filter(t => !t.deleted));
        }
      } else {
        this.taxDefs = [];
        await this.loadTaxDefs(contextIdDto).then((response: Array<Tax>) => {
          if (response && response.length) {
            console.log('loaded taxDefs');
            this.taxDefs = response;
            if (getDeleted) {
              resolve(this.taxDefs);
            } else {
              resolve(this.taxDefs.filter(t => !t.deleted));
            }
          } else {
            resolve([]);
          }
        });
      }
    });
  }

}
