import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContextIdDto } from '@savvy/app';
import {
  CreateWorkflowDefinition,
  WorkflowDefinitionTypeData,
  WorkflowDefinitionTypeItem,
  WorkflowdefService,
  UpdateWorkflowDetails, LoadWorkflowDefinitionForEditResponse
} from '@savvy/workflow-definition';
import { Observable } from 'rxjs';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';

@Component({
  selector: 'app-add-edit-workflow-type-dialog',
  templateUrl: './add-edit-workflow-type-dialog.component.html',
  styleUrls: ['./add-edit-workflow-dialog.component.scss']
})
export class AddEditWorkflowTypeDialogComponent implements OnInit {

  contextIdDto: ContextIdDto;
  workflowTypes: WorkflowDefinitionTypeItem[] = [];
  menuItemTypeOptions: Observable<string[]>;

  workflowForm = new UntypedFormGroup({
    workflowName: new UntypedFormControl('', [Validators.required])
  });


  constructor(@Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<AddEditWorkflowTypeDialogComponent>,
    private api: WorkflowdefService,
    public notify: FloSnackbarComponent) { }

  ngOnInit(): void {
    this.contextIdDto = this.data.contextIdDto;

    if (this.data.workflowDefinition) {
      this.setWorkflowForm();
    }

  }

  setWorkflowForm(): void {
    this.workflowForm.patchValue({
      workflowName: this.data.workflowDefinition.name
    });
  }

  cancel() {
    this.dialogRef.close({ response: false });
  }

  createWorkflow() {

    const workflowDefinitionTypeData = <WorkflowDefinitionTypeData>{};
    workflowDefinitionTypeData.workflowDefinitionType = this.data.workflowDefinitionType;


    const createWorkflowDefinition: CreateWorkflowDefinition = {
      contextIdDto: this.contextIdDto,
      workflowName: this.workflowForm.value.workflowName,
      workflowDefinitionTypeData
    };

    this.api.createWorkflowDef(createWorkflowDefinition).subscribe(response => {
      if (response && response.id) {
        this.notify.message = 'Workflow successfully created.';
        this.notify.open();
      }
    }, () => {
      this.notify.message = 'Failed to create Workflow.';
      this.notify.open();
    }, () => {
      this.dialogRef.close({ response: true });
    });
  }

  updateWorkflow() {

    const workflowDefinitionTypeData = <WorkflowDefinitionTypeData>{};
    workflowDefinitionTypeData.workflowDefinitionType = this.data.workflowDefinitionType;

    const updateWorkflowDetailsRequest: UpdateWorkflowDetails = {
      contextIdDto: this.contextIdDto,
      workflowDefinitionId: this.data.workflowDefinition.workflowDefinitionId.id,
      workflowName: this.workflowForm.value.workflowName,
      workflowDefinitionTypeData
    };

    this.api.updateWorkflowDetails(updateWorkflowDetailsRequest).subscribe(response => {
      if (response && response.updatedWorkflowDefinition && response.updatedWorkflowDefinition.id) {
        this.notify.message = 'Workflow successfully updated.';
        this.notify.open();
      }
    }, () => {
      this.notify.message = 'Failed to update Workflow.';
      this.notify.open();
    }, () => {
      this.dialogRef.close({ response: true });
    });
  }

}
