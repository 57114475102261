import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ContextIdDto } from '@savvy/app';
import { PetType, PetTypeService } from '@savvy/pet';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ClearObservable } from '../../shared/classes/clear-observable';

@Component({
  selector: 'app-filter-pet-type-selector',
  templateUrl: './filter-pet-type-selector.component.html',
  styleUrls: ['./filter-pet-type-selector.component.scss']
})
export class FilterPetTypeSelectorComponent extends ClearObservable implements OnInit {
  @Input() contextIdDto: ContextIdDto;
  @Input() selectedPetType = new FormControl<string>('');

  @Output() petTypeChanged: EventEmitter<string> = new EventEmitter();

  petTypes: PetType[] = [];
  public filteredPetTypes: ReplaySubject<PetType[]> = new ReplaySubject<PetType[]>(1);
  public petTypeFilter: UntypedFormControl = new UntypedFormControl();

  constructor(
    private dialog: MatDialog,
    private petTypeService: PetTypeService,
  ) {
    super();
  }

  ngOnInit() {
    this.petTypeFilter.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.filterPetTypes();
      });
    this.loadPetTypes();

    this.selectedPetType.valueChanges.subscribe(any => {
      this.petTypeChanged.emit(this.selectedPetType.value);
    })
  }

  filterPetTypes() {
    if (!this.petTypes) {
      return;
    }
    // get the search keyword
    let search = this.petTypeFilter.value;
    if (!search) {
      this.filteredPetTypes.next(this.petTypes.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the breeds
    this.filteredPetTypes.next(
      this.petTypes.filter(petType => petType.name.toLowerCase().indexOf(search) > -1)
    );
  }


  loadPetTypes() {
    this.petTypeService.listPetTypes()
      .subscribe(petTypes => {
        if (petTypes && petTypes.length) {
          this.petTypes = petTypes;
          this.filterPetTypes();
        }
      });
  }
}
