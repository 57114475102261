<mat-card appearance="outlined" class="panel new-card">
  <mat-card-title>{{'Flags' | translate}}

  </mat-card-title>
  <mat-card-content>

    <div class="flexbox-parent" *ngIf="flagPanelDataDto.flagDtos && flagPanelDataDto.flagDtos.length == 0">
      <div fxLayout="row" fxLayoutAlign="center center">
        <div style="text-align:center;">
          <app-zero-image name="Flags"></app-zero-image><br>
          <h5 class="ma-0">No Flags</h5><br>
        </div>
      </div>
    </div>


    <mat-chip-listbox>
      <mat-chip-option color="{{flagDto.flagDefinition?.colour}}" *ngFor="let flagDto of flagPanelDataDto.flagDtos" removable="false" selected>
        {{flagDto.flagDefinition.name}}
      </mat-chip-option>
    </mat-chip-listbox>
  </mat-card-content>
</mat-card>