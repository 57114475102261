import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContextIdDto, EntityInstanceId } from '@savvy/view-definition';
import { ConsumerService, ServicesService } from '@savvy/services';
import { ServicesHelperService } from '../shared/services/services-helper.service';

@Component({
  selector: 'app-select-service',
  templateUrl: 'bookService.component.html',
  styleUrls: ['bookService.component.scss']
})
export class BookServiceComponent implements OnInit {
  @Input() contextIdDto: ContextIdDto;
  @Output() serviceNumbersSelected = new EventEmitter();

  sub: any;
  bookingDef: any;
  consumerServices: Array<ConsumerService>;

  serviceNumber: string;
  cssClass: string;

  serviceNumbers = new Array();
  services: Array<string>;
  user: string;
  currencyCode: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private el: ElementRef,
    private renderer: Renderer2,
    private servicesHelperService: ServicesHelperService
  ) {

  }

  ngOnInit(): void {
    console.log('inside book');

    this.sub = this.route.params.subscribe(params => {
      this.contextIdDto = <ContextIdDto>{};
      this.contextIdDto.contextId = params['contextId'];
      this.contextIdDto.contextIdType = params['contextIdType'];
      console.log('loading services');
      this.loadServices();
    });
  }

  isSlotAvailable(slotAndCheckDto) {
    return slotAndCheckDto.slotDto.availableUsersNames && slotAndCheckDto.slotDto.availableUsersNames.length > 0;
  }

  loadServices() {
    this.servicesHelperService.getConsumerServices(this.contextIdDto).then(consumerServices => {
      if (consumerServices.length) {
        this.consumerServices = consumerServices;
      }
    });
  }

  serviceSelected(service: ConsumerService) {
    this.serviceNumbers.push(service.id);
  }

  addService() {
    if (this.serviceNumber) {
      console.log('Adding service ' + this.serviceNumber);
      this.serviceNumbers.push(this.serviceNumber);
    }
  }

  deleteService(serviceNumber: string) {

    const index = this.serviceNumbers.indexOf(serviceNumber, 0);
    if (index > -1) {
      this.serviceNumbers.splice(index, 1);
    }
  }

  serviceChanged() {
    this.services = new Array();
    this.services.push(this.getServiceId(this.serviceNumber));
  }


  getServiceName(serviceId: string) {
    let serviceDto: ConsumerService;

    this.consumerServices.forEach(service => {
      if (service.id === serviceId) {
        serviceDto = service;
      }
    });
    if (serviceDto) {
      return serviceDto?.serviceName;
    }
    return '';
  }

  getServiceId(serviceId: string): string {
    let serviceEiId: string;

    this.consumerServices.forEach(service => {
      if (service.id === serviceId) {
        serviceEiId = service.id;
      }
    });
    if (serviceEiId) {
      return serviceEiId;
    }
    return null;
  }
}
