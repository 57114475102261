<mat-card appearance="outlined" class="panel new-card" *ngIf="(futureAppointmentDates && futureAppointmentDates.length > 0 || pastAppointmentDates && pastAppointmentDates.length > 0)">
  <mat-card-title>
    <div fxLayout="row">
      <div fxFlex="100">
        <p class="mt-xs mb-xs">{{viewAppointmentsByEiListData.label | translate}}</p>
      </div>
    </div>
  </mat-card-title>
  <mat-card-content>
    <mat-tab-group (selectedTabChange)="onTabChanged()" (focusChange)="onTabChanged()">
      <mat-tab label="Future">
        <ng-template matTabContent>
          <ngx-datatable *ngIf="futureAppointmentDates" class='no-shadow material has-plus-btn' [columns]="columns" [style.border]="0" [rows]='futureAppointmentDates' [columnMode]="'force'" [selected]="futureSelected" [selectionType]="'single'" [headerHeight]="0" [rowHeight]="40" [footerHeight]="futurePage.totalElements > futurePage.size ? 50 : 0" [externalPaging]="true" [count]="futurePage.totalElements" [offset]="futurePage.pageNumber" [limit]="futurePage.size"
            (select)="onFutureSelect($event)" (page)='setFuturePage($event)'>
          </ngx-datatable>
        </ng-template>
      </mat-tab>
      <mat-tab label="Past">
        <ng-template matTabContent>
          <ngx-datatable *ngIf="pastAppointmentDates" class='no-shadow material has-plus-btn' [columns]="columns" [style.border]="0" [rows]='pastAppointmentDates' [columnMode]="'force'" [selected]="pastSelected" [selectionType]="'single'" [headerHeight]="0" [rowHeight]="40" [footerHeight]="pastPage.totalElements > pastPage.size ? 50 : 0" [externalPaging]="true" [count]="pastPage.totalElements" [offset]="pastPage.pageNumber" [limit]="pastPage.size" (select)="onPastSelect($event)"
            (page)='setPastPage($event)'>
          </ngx-datatable>
        </ng-template>
      </mat-tab>
    </mat-tab-group>


    <div fxLayout="row">
      <div fxFlex="90">
      </div>
      <div fxFlex="10">
        <button mat-mini-fab class="mat-mini-fab-bottom-right" color="accent" [disabled]="trialExpiredService.isTrialExpired()" (click)="create()">
          <mat-icon class="mat-24">add</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-content>

</mat-card>

<mat-card appearance="outlined" class="panel new-card" *ngIf="(futureAppointmentDates && futureAppointmentDates.length == 0 && pastAppointmentDates && pastAppointmentDates.length == 0)">
  <mat-card-content>
    <div class="flexbox-parent">
      <div fxLayout="row" fxLayoutAlign="center center">
        <div style="text-align:center;">
          <app-zero-image [name]="viewAppointmentsByEiListData.label"></app-zero-image><br>
          <h5 class="ma-0">{{'You don’t have any ' + viewAppointmentsByEiListData.label | translate}}</h5><br>
          <!-- *ngIf="listEntityListDto.canCreate" -->
          <button mat-raised-button color="accent" [disabled]="trialExpiredService.isTrialExpired()" (click)="create()">{{'New' | translate}} {{viewAppointmentsByEiListData.label | translate}}</button>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>