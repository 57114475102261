import { Component, EventEmitter, Output } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GLOBAL } from 'src/app/app.constants';
import { LayoutBusService } from '../../element/LayoutBusService';
import { LayoutEvent } from '../../event/LayoutEvent';
import { Menu } from '../../menu/local-menu.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent {
  @Output() menuChanged: EventEmitter<Menu> = new EventEmitter();
  @Output() closeSidebar: EventEmitter<boolean> = new EventEmitter();

  showAdmin = false;

  constructor(
    private layoutBusService: LayoutBusService,
    private deviceService: DeviceDetectorService
  ) {
    this.layoutBusService.eventAdded$.subscribe((event) => {
      if (!GLOBAL.appsMenu || this.deviceService.isMobile()) {
        this.onEvent(event);
      }
    });
  }

  onMenuChanged(newMenu: Menu) {
    this.menuChanged.emit(newMenu);
  }

  private onEvent(event: LayoutEvent) {
    this.showAdmin = event.showAdmin;
  }

  closeSidebarMenu() {
    this.closeSidebar.emit(true);
  }
}
