import { Component, ElementRef, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ListEntityListItemDto } from '@savvy/cached-view/model/listEntityListItemDto';
import { LinkId } from '@savvy/entity-instance';
import { ContextIdDto } from '@savvy/entity-instance-composite';

import {
  Appointment,
  AppointmentPackageRow,
  AppointmentPaymentConfig,
  AppointmentService,
  AppointmentServiceRow,
  CreateAppointment,
  PetServicesRequired,
  UpdateAppointment
} from '@savvy/appointment';
import { DateUtils } from '../../dates/DateUtils';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';

import { MatAccordion } from '@angular/material/expansion';
import {
  SendMessageDefinition,
  SendSmsDefinition,
} from '@savvy/workflow-definition';
import { NgxSpinnerService } from 'ngx-spinner';
import * as Quill from 'quill';

import { MatStepper } from '@angular/material/stepper';
import { Customer } from '@savvy/customer';
import { Location } from '@savvy/location';
import { PlanDefinition } from '@savvy/plan-definition/model/planDefinition';
import { UserId } from '@savvy/user';
import { Clash } from "@savvy/user-availability";
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { AppointmentConfigHelperService } from '../../shared/services/appointment-config-helper.service';
import { LocationsHelperService } from '../../shared/services/locations-helper.service';
import { OrgUsersHelperService } from '../../shared/services/org-users-helper.service';
import { ServicesHelperService } from '../../shared/services/services-helper.service';
import { AppointmentRulesService } from '../appointment-rules.component';
import { RepeatAppointmentsComponent } from '../create-pet-appointment-v2/repeat-appointments/repeat-appointments.component';
import { AppointmentSelectorV2Component } from '../shared/appointment-selector-v2/appointment-selector-v2.component';
import { AppointmentV2IconsComponent } from '../shared/appointment-v2-icons/appointment-v2-icons.component';
import { PaymentComponent } from '../shared/payment/payment.component';
import { ValidateAppointmentV2Service } from '../validate-appointment-v2.service';
import { ConfirmAppointmentV2Component } from './confirm-appointment-v2/confirm-appointment-v2.component';
import { CreateAppointmentV2SyncService } from './create-appointment-v2-sync.service';
import { CustomerSelectorV2Component } from './customer-selector-v2/customer-selector-v2.component';

@Component({
  selector: 'app-create-appointment-v2',
  templateUrl: './create-appointment-v2.component.html',
  styleUrls: ['./create-appointment-v2.component.scss']
})
export class CreateAppointmentV2Component implements OnInit, OnDestroy {

  @ViewChild(AppointmentSelectorV2Component, { static: false }) appointmentSelectorV2Component: AppointmentSelectorV2Component;

  @ViewChild(ConfirmAppointmentV2Component, { static: false }) confirmAppointmentV2Component: ConfirmAppointmentV2Component;
  @ViewChild(AppointmentV2IconsComponent, { static: false }) appointmentV2IconsComponent: AppointmentV2IconsComponent;
  @ViewChild(PaymentComponent, { static: false }) paymentComponent: PaymentComponent;
  @ViewChild(RepeatAppointmentsComponent, { static: false }) repeatAppointmentComponent: RepeatAppointmentsComponent;
  @ViewChild(CustomerSelectorV2Component, { static: false }) customerSelectorV2Component: CustomerSelectorV2Component;


  @ViewChild('messageEditor', { read: ElementRef }) messageEditorEl: ElementRef;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild(MatStepper) stepper: MatStepper;

  quickAppointment = false;
  editor: Quill;
  contextIdDto: ContextIdDto;

  collectionTime = '';
  messageBody = '';
  emailBody = '';
  links: any[] = [];

  isValidTypeBoolean = true;
  petEdId = '';

  creating = false;

  lastStep = '';

  pickupRequired = false;
  locationFreeText = '';
  notes = '';
  enquiryDate: Date = new Date();


  advancedStepVisible = false;
  smsWorkflowDef: SendSmsDefinition;
  smsMessageName = '';
  emailWorkflowDef: SendMessageDefinition;
  emailMessageName = '';
  defaultMessage = 'You need to setup a Confirm message';

  subs: Subscription[] = [];
  appointment: Appointment = {
    paymentConfig: {} as AppointmentPaymentConfig
  } as Appointment;

  selectedTabIndex = 0;

  showConfirmationDialog = false;
  checkConflictsDialog = false;
  clashes: Array<Clash> = [];
  bypassConflictCheck = false;
  operationToPerform = '';
  createAnywayApplied = false;
  isRepeatAvailable = false;

  appointmentTitle = 'Appointment';
  customerMode = '';
  selectedPlanDefinition: PlanDefinition;
  createNewPlan = false;
  paymentModeEnum = AppointmentPaymentConfig.PaymentModeEnum;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    @Optional() public dialogRef: MatDialogRef<CreateAppointmentV2Component>,
    private appointmentService: AppointmentService,
    private notify: FloSnackbarComponent,
    private spinner: NgxSpinnerService,
    private dateUtils: DateUtils,
    public orgUsersHelperService: OrgUsersHelperService,
    public createAppointmentV2SyncService: CreateAppointmentV2SyncService,
    public appointmentConfigHelperService: AppointmentConfigHelperService,
    public deviceService: DeviceDetectorService,
    private validateAppointmentV2Service: ValidateAppointmentV2Service,
    public appointmentRulesService: AppointmentRulesService,
    private servicesHelperService: ServicesHelperService,
    private locationsHelperService: LocationsHelperService
  ) {
    this.contextIdDto = this.data.contextIdDto;
  }

  ngOnDestroy(): void {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.customerMode = 'existingCustomer';
    this.fullScreen();
    this.createAppointmentV2SyncService.setTimeArray(this.contextIdDto);
    this.servicesHelperService.getConsumerServices(this.contextIdDto);
    let appointmentType = this.data.appointmentType;
    if (this.data.edit || this.data.rebook) {
      // Handle edit flow
      this.appointment = this.data.appointment;
      this.notes = this.appointment?.notes;

      if (this.appointment?.enquiryDate) {
        this.enquiryDate = new Date(this.appointment.enquiryDate);
      } else {
        this.enquiryDate = new Date(this.appointment.dateCreated);
      }
      this.createAppointmentV2SyncService.editMode = true;
      this.createAppointmentV2SyncService.populateAppointment(this.appointment, this.contextIdDto);
      appointmentType = this.appointment.appointmentType;
    } else {
      if (this.data.startDateToUseOnCreate) {
        const startDate = this.data.startDateToUseOnCreate ? new Date(this.data.startDateToUseOnCreate) : new Date();
        const startTime = this.data.startDateToUseOnCreate ? this.dateUtils.getTimeAsString(this.data.startDateToUseOnCreate) : '';
        const endTime = this.dateUtils.addMinsToTimeString(startTime, 60);
        this.createAppointmentV2SyncService.setAppointmentDates(startDate, startDate, startTime, endTime);
      } else {
        const startDate = new Date();
        const startTimeTemp = this.dateUtils.getTimeAsString(new Date());
        let startTime = startTimeTemp;
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let tIndex = 0; tIndex < this.createAppointmentV2SyncService.timeArray.length; tIndex++) {
          const t = this.createAppointmentV2SyncService.timeArray[tIndex];
          if (startTime <= t.actualValue) {
            startTime = t.actualValue;
            break;
          }
        }
        const endTime = this.dateUtils.addMinsToTimeString(startTime, 60);
        this.createAppointmentV2SyncService.setAppointmentDates(startDate, startDate, startTime, endTime);
      }

      if (this.data.userToUseWhenCreating) {
        this.createAppointmentV2SyncService.userToUseWhenCreating = this.data.userToUseWhenCreating;
      }

      // Set data from quote
      if (this.data.populateCustomer) {
        this.createAppointmentV2SyncService.customersSelected = [
          this.createAppointmentV2SyncService.getCustomerSearchResultDto(this.data.populateCustomer, this.contextIdDto.contextId)
        ];
        this.createAppointmentV2SyncService.customersSelectedChips =
          this.createAppointmentV2SyncService.customersSelected.map(c => c.fullName);
      }
      if (this.data.packageRows) {
        const packageRows = this.data.packageRows as AppointmentPackageRow[];
        this.createAppointmentV2SyncService.populatePackages(packageRows, this.contextIdDto);
      }
      if (this.data.serviceRows) {
        const serviceRows = this.data.serviceRows as AppointmentServiceRow[];
        this.createAppointmentV2SyncService.populateServices(serviceRows, this.contextIdDto);
      }
    }

    if (appointmentType) {
      this.createAppointmentV2SyncService.appointmentType = appointmentType;
      switch (appointmentType) {
        case Appointment.AppointmentTypeEnum.CommercialShoot:
          this.appointmentTitle = 'Commercial Shoot';
          this.createAppointmentV2SyncService.enablePackages = true;
          break;
        case Appointment.AppointmentTypeEnum.Wedding:
          this.appointmentTitle = 'Wedding';
          this.createAppointmentV2SyncService.enablePackages = true;
          break;
        case Appointment.AppointmentTypeEnum.Shoot:
          this.appointmentTitle = 'Shoot';
          break;
      }
    }

    this.subs.push(this.createAppointmentV2SyncService.createCustomer$.subscribe((result) => {
      if (result) {
        this.fullScreen();
        // this.existingCustomer = false;
      }
    }));
  }

  getMinTime(requiredServices: PetServicesRequired[]) {
    let min = null;
    for (const requiredService of requiredServices) {
      for (const iterator of requiredService.petServicesRequired) {
        if (!min || iterator.start < min) {
          min = iterator.start;
        }
      }
    }
    return min;

  }

  getMaxTime(requiredServices: PetServicesRequired[]) {
    let max = null;
    for (const requiredService of requiredServices) {
      for (const iterator of requiredService.petServicesRequired) {
        if (!max || iterator.end > max) {
          max = iterator.end;
        }
      }
    }
    return max;
  }

  validateRequest() {
    if (!this.createAppointmentV2SyncService.customersSelected || !this.createAppointmentV2SyncService.customersSelected.length) {
      this.notify.message = 'Please select a customer';
      this.notify.open();
      return false;
    }
    if (!Object.keys(this.createAppointmentV2SyncService.selectedServices).length) {
      this.notify.message = 'Please select a service';
      this.notify.open();
      return false;
    }

    if (this.createAppointmentV2SyncService.customersSelected && this.createAppointmentV2SyncService.customersSelected.length) {
      for (const customerSelected of this.createAppointmentV2SyncService.customersSelected) {
        for (const service of this.createAppointmentV2SyncService.selectedServices[customerSelected.id]) {
          if (!service.selectedService) {
            this.notify.message = `Service should be selected`;
            this.notify.open();
            return false;
          }
          if (!service.selectedStaff.id) {
            this.notify.message = `A staff member should be allocated to a service`;
            this.notify.open();
            return false;
          }
          if (!service.grossPrice) {
            service.grossPrice = 0;
          }
          if (!service.startTime) {
            this.notify.message = 'Start time is required';
            this.notify.open();
            return false;
          }
          if (!service.endTime) {
            this.notify.message = 'End time is required';
            this.notify.open();
            return false;
          }
          if (service.endTime < service.startTime) {
            this.notify.message = 'End time should be greater than start time';
            this.notify.open();
            return false;
          }
        }
        if (this.createAppointmentV2SyncService.enablePackages) {
          for (const aPackage of this.createAppointmentV2SyncService.selectedPackages[customerSelected.id]) {
            if (!aPackage.selectedPackage) {
              this.notify.message = `Package should be selected`;
              this.notify.open();
              return false;
            }
            if (!aPackage.selectedPackageUser?.id) {
              this.notify.message = `A staff member should be allocated to a package`;
              this.notify.open();
              return false;
            }
            if (!aPackage.grossPrice) {
              aPackage.grossPrice = 0;
            }
            if (!aPackage.startTime) {
              this.notify.message = 'Start time is required';
              this.notify.open();
              return false;
            }
            if (!aPackage.endTime) {
              this.notify.message = 'End time is required';
              this.notify.open();
              return false;
            }
            if (aPackage.endTime < aPackage.startTime) {
              this.notify.message = 'End time should be greater than start time';
              this.notify.open();
              return false;
            }
          }
        }
      }
    }

    if (!this.createAppointmentV2SyncService.startDateReq
      || !this.createAppointmentV2SyncService.startTime
      || !this.createAppointmentV2SyncService.endTime) {
      this.notify.message = 'Invalid date/time selection';
      this.notify.open();
      return false;
    }
    return true;
  }

  prepareCreateRequestPayload(confirm = false, appointmentDate): CreateAppointment {
    let customerId = '';
    let customerName = '';

    if (this.createAppointmentV2SyncService.customersSelected.length) {
      customerId = this.createAppointmentV2SyncService.customersSelected[0]?.id;
      customerName = this.createAppointmentV2SyncService.customersSelected[0]?.fullName;
    }
    if (!customerId) {
      this.notify.message = 'No pet owner id specified for this pet';
      this.notify.open();
      return;
    }
    const createComp = {} as CreateAppointment;
    createComp.contextIdDto = this.contextIdDto;
    createComp.confirm = confirm;
    if (confirm) {
      this.operationToPerform = 'createAndConfirm';
    } else {
      this.operationToPerform = 'create';
    }
    let selectedLocation;
    if (this.data.rebook && this.appointment?.locationId) {
      selectedLocation = this.locationsHelperService.nonDeletedLocations.find(l=> l?.id === this.appointment?.locationId);
    } else if (this.createAppointmentV2SyncService.selectedLocation) {
      selectedLocation = this.createAppointmentV2SyncService.selectedLocation;
    } else {
      selectedLocation = this.locationsHelperService.nonDeletedLocations[0];
    }

    const appointmentServiceRows: AppointmentServiceRow[] = [];
    const appointmentPackageRows: AppointmentPackageRow[] = [];

    let startDateReq = this.createAppointmentV2SyncService.startDateReq;
    let endDateReq = this.createAppointmentV2SyncService.endDateReq;
    if (this.isRepeatAvailable && appointmentDate) {
      startDateReq = this.dateUtils.getDateAsStringDash(appointmentDate?.localStartDate);
      endDateReq = this.dateUtils.getDateAsStringDash(appointmentDate?.localStartDate);
    }

    for (const customerSelected of this.createAppointmentV2SyncService.customersSelected) {
      for (const service of this.createAppointmentV2SyncService.selectedServices[customerSelected?.id]) {
        const startTime = service.startTime ? service.startTime : this.createAppointmentV2SyncService.startTime;
        const endTime = service.endTime ? service.endTime : this.createAppointmentV2SyncService.endTime;

        appointmentServiceRows.push({
          serviceUser: {
            id: service?.selectedStaff?.id,
            type: 'USER_ID',
            userContextType: UserId.UserContextTypeEnum.UserId
          },
          serviceUserFullName: service?.selectedStaff?.name,
          startDate: startDateReq,
          startTime,
          endDate: endDateReq,
          endTime,
          serviceNumber: service?.selectedService?.id,
          serviceName: service?.selectedService?.serviceName,
          quantity: service.quantity,
          unitPrice: service?.selectedService?.unitPrice,
          tax: service?.selectedService?.tax,
          subTotal: service.subTotal,
          grossPrice: service.grossPrice,
          discountDetails: service.discount,
          // discountId ?: string;
        });
      }
      if (this.createAppointmentV2SyncService.enablePackages && customerSelected?.id) {
        for (const aPackage of this.createAppointmentV2SyncService.selectedPackages[customerSelected?.id]) {
          const startTime = aPackage.startTime ? aPackage.startTime : this.createAppointmentV2SyncService.startTime;
          const endTime = aPackage.endTime ? aPackage.endTime : this.createAppointmentV2SyncService.endTime;

          appointmentPackageRows.push({
            packageUser: {
              id: aPackage?.selectedPackageUser?.id,
              type: 'USER_ID',
              userContextType: UserId.UserContextTypeEnum.UserId
            },
            packageUserFullName: aPackage?.selectedPackageUser?.name,
            startDate: startDateReq,
            startTime,
            endDate: endDateReq,
            endTime,
            packageId: aPackage?.selectedPackage?.id,
            packageName: aPackage?.selectedPackage?.packageName,
            quantity: aPackage.quantity,
            packagePrice: aPackage?.selectedPackage?.packagePrice,
            tax: aPackage?.selectedPackage?.tax,
            subTotal: aPackage.subTotal,
            grossPrice: aPackage.grossPrice,
            discountDetails: aPackage.discount,
            // discountId ?: string;
          });
        }
      }
    }
    const linkedIds = [];
    if (this.data.quoteId) {
      linkedIds.push(this.data.quoteId);
    }
    const startTime = this.createAppointmentV2SyncService.startTime;
    const endTime = this.createAppointmentV2SyncService.endTime;
    createComp.appointment = {
      appointmentDefinitionId: {},
      dateCreated: this.dateUtils.getDateTimeAsIsoString(new Date(), true),
      startDateUtc:
        // eslint-disable-next-line max-len
        this.dateUtils.getDateTimeAsIsoString(new Date(`${startDateReq}T${startTime}`), true),
      endDateUtc:
        // eslint-disable-next-line max-len
        this.dateUtils.getDateTimeAsIsoString(new Date(`${endDateReq}T${endTime}`), true),
      startDate: startDateReq,
      startTime,
      endDate: endDateReq,
      endTime,
      appointmentTimeStrategy: this.getAppointmentTimeStrategy(),
      linkedIds,
      locationId: selectedLocation ? selectedLocation?.id : '',
      locationName: selectedLocation ? selectedLocation?.name : '',
      locationFreeText: selectedLocation ? selectedLocation?.locationName : '',
      entityInstanceId: '',
      // cancelled?: boolean;
      customerId,
      customerName,
      workflowDefinitionId: this.createAppointmentV2SyncService.selectedWorkflowDefinition?.id,
      // workflowInstanceId?: string;
      // status?: string;
      // endState?: boolean;
      // stateColour?: string;
      // extra?: {
      //   [key: string]: object;
      // };
      // scissors?: string;
      notes: this.notes,
      icons: this.appointmentV2IconsComponent ? this.appointmentV2IconsComponent.selectedIcons : [],
      appointmentServiceRows,
      ownerId: this.contextIdDto.contextId,
      // pickupRequired: this.pickupServiceComponent ? this.pickupServiceComponent.pickupRequired : false,
      // collectionTime: this.pickupServiceComponent && this.pickupServiceComponent.pickupRequired
      //   ? moment(this.pickupServiceComponent.collectionTime).format('HH:mm') : '',
      // pickupAddress: this.pickupServiceComponent && this.pickupServiceComponent.pickupRequired
      //   ? this.pickupServiceComponent.pickupAddress : '',
      paymentConfig: this.paymentComponent ? this.paymentComponent.getPaymentConfig() : null,
      appointmentSource: Appointment.AppointmentSourceEnum.WebCrm,
      appointmentPackageRows,
      appointmentType: this.createAppointmentV2SyncService.appointmentType,
      invoiceId: this.data.invoiceId ? this.data.invoiceId : null,
      enquiryDate: this.enquiryDate ? this.dateUtils.getDateAsStringDash(this.enquiryDate) : ''
    };
    return createComp;
  }

  checkConflictApi(appointmentDate) {
    if (Array?.isArray(appointmentDate)) {
      let executed = 0;
      for (const ad of appointmentDate) {
        const createComp: CreateAppointment = this.prepareCreateRequestPayload(true, ad);
        this.validateAppointmentV2Service.checkConflicts(this.contextIdDto, createComp.appointment,
          this.createAppointmentV2SyncService.selectedLocation?.id)
          .then((clashes: Array<Clash>) => {
            if (clashes.length) {
              ad.conflict = true;
            } else {
              ad.conflict = false;
            }
            executed += 1;
            if (executed === appointmentDate?.length) {
              this.repeatAppointmentComponent?.notifyConflict(appointmentDate);
            }
          });
      }
    } else {
      const createComp: CreateAppointment = this.prepareCreateRequestPayload(true, appointmentDate);
      this.validateAppointmentV2Service.checkConflicts(this.contextIdDto, createComp.appointment,
        this.createAppointmentV2SyncService.selectedLocation?.id).then((clashes: Array<Clash>) => {
          if (clashes.length) {
            appointmentDate.conflict = true;
          } else {
            appointmentDate.conflict = false;
          }
          this.repeatAppointmentComponent?.notifyConflict(appointmentDate);
        });
    }
    this.updateStartDateAndEndDate(appointmentDate);
  }

  updateStartDateAndEndDate(appointmentDate) {
    if (appointmentDate && appointmentDate[0] && appointmentDate[0].startTime) {
      this.createAppointmentV2SyncService.updateStartTimeAndEndTimeByStartTime(appointmentDate[0].startTime);
    }
  }

  getAppointmentTimeStrategy() {
    /* if (this.quickAppointment) {
       return Appointment.AppointmentTimeStrategyEnum.AppointmentTimes;
     } else if (this.createAppointmentV2SyncService.appointmentConfig.appointmentConfig.customizedTimeForServices) {
     } else {*/
    // return Appointment.AppointmentTimeStrategyEnum.AppointmentTimes;
    return Appointment.AppointmentTimeStrategyEnum.ServiceTimes;
    // }
  }

  createAppointmentApi(toast = true, confirm?: boolean, appointmentDate?: any) {
    return new Promise<Appointment>((resolve) => {
      if (!this.validateRequest()) {
        return;
      }
      if (this.clashes.length && !this.createAnywayApplied) {
        this.createAnywayApplied = true;
      }
      const createComp: CreateAppointment = this.prepareCreateRequestPayload(confirm, appointmentDate);
      this.validateAppointmentV2Service.checkConflicts(this.contextIdDto, createComp.appointment,
        this.createAppointmentV2SyncService.selectedLocation?.id).then((clashes: Array<Clash>) => {
          if (this.appointmentConfigHelperService.appointmentConfig.conflictCheckEnabled && clashes.length && !this.createAnywayApplied) {
            this.clashes = clashes;
            this.checkConflictsDialog = true;
            // reject(true);
            return;
          }
          this.spinner.show();
          this.appointmentService.createAppointment(createComp).subscribe(response => {
            this.spinner.hide();
            if (response) {
              if (toast) {
                this.notify.message = 'Appointment created successfully';
                this.notify.open();
                this.dialogRef.close(response);
              }
              resolve(response);
            }
          });
        });
    });
  }

  createAppointment(toast = true, confirm?: boolean) {
    if (this.isRepeatAvailable) {
      const appointmentDates = this.repeatAppointmentComponent?.getAppointmentDates();
      appointmentDates?.forEach(appointmentDate => {
        this.createAppointmentApi(toast, confirm, appointmentDate);
      });
    }
    this.createAppointmentApi(toast, confirm);
  }

  prepareUpdateRequestPayload(confirm = false): UpdateAppointment {
    const updateComp = {} as UpdateAppointment;
    updateComp.contextIdDto = this.contextIdDto;
    updateComp.confirm = confirm;
    if (confirm) {
      this.operationToPerform = 'updateAndConfirm';
    } else {
      this.operationToPerform = 'update';
    }

    let customerId = '';
    let customerName = '';

    if (this.createAppointmentV2SyncService.customersSelected.length) {
      customerId = this.createAppointmentV2SyncService.customersSelected[0].id;
      // eslint-disable-next-line max-len
      customerName = this.createAppointmentV2SyncService.customersSelected[0].fullName;
    }

    let selectedLocation: Location;
    if (this.appointment?.locationId) {
      selectedLocation = this.locationsHelperService.nonDeletedLocations.find(l=> l?.id === this.appointment?.locationId);
    } else {
      selectedLocation = this.locationsHelperService.nonDeletedLocations[0];
    }
    const appointmentServiceRows: AppointmentServiceRow[] = [];
    const appointmentPackageRows: AppointmentPackageRow[] = [];

    for (const customerSelected of this.createAppointmentV2SyncService.customersSelected) {
      for (const service of this.createAppointmentV2SyncService.selectedServices[customerSelected.id]) {
        const startTime = service.startTime ? service.startTime : this.createAppointmentV2SyncService.startTime;
        const endTime = service.endTime ? service.endTime : this.createAppointmentV2SyncService.endTime;
        appointmentServiceRows.push({
          serviceUser: {
            id: service.selectedStaff.id,
            type: 'USER_ID',
            userContextType: UserId.UserContextTypeEnum.UserId
          },
          serviceUserFullName: service.selectedStaff?.name,
          // entityInstanceId: service.selectedService.entityInstanceId,
          // eiLabel ?: string;
          // eiType ?: string;
          startDate: this.createAppointmentV2SyncService.startDateReq,
          startTime,
          endDate: this.createAppointmentV2SyncService.endDateReq,
          endTime,
          serviceNumber: service.selectedService.id,
          serviceName: service.selectedService?.serviceName,
          quantity: service.quantity,
          unitPrice: service.selectedService.unitPrice,
          tax: service.selectedService.tax,
          subTotal: service.subTotal,
          grossPrice: service.grossPrice,
          discountDetails: service.discount
          // discountId ?: string;
        });
      }
      if (this.createAppointmentV2SyncService.enablePackages) {
        for (const aPackage of this.createAppointmentV2SyncService.selectedPackages[customerSelected.id]) {
          const startTime = aPackage.startTime ? aPackage.startTime : this.createAppointmentV2SyncService.startTime;
          const endTime = aPackage.endTime ? aPackage.endTime : this.createAppointmentV2SyncService.endTime;

          appointmentPackageRows.push({
            packageUser: {
              id: aPackage.selectedPackageUser?.id,
              type: 'USER_ID',
              userContextType: UserId.UserContextTypeEnum.UserId
            },
            packageUserFullName: aPackage.selectedPackageUser?.name,
            startDate: this.createAppointmentV2SyncService.startDateReq,
            startTime,
            endDate: this.createAppointmentV2SyncService.endDateReq,
            endTime,
            packageId: aPackage.selectedPackage.id,
            packageName: aPackage.selectedPackage.packageName,
            quantity: aPackage.quantity,
            packagePrice: aPackage.selectedPackage.packagePrice,
            tax: aPackage.selectedPackage.tax,
            subTotal: aPackage.subTotal,
            grossPrice: aPackage.grossPrice,
            discountDetails: aPackage.discount,
            // discountId ?: string;
          });
        }
      }
    }
    updateComp.appointment = {
      ...this.appointment,
      id: this.appointment.id,
      appointmentDefinitionId: this.appointment.appointmentDefinitionId,
      startDateUtc:
        // eslint-disable-next-line max-len
        this.dateUtils.getDateTimeAsIsoString(new Date(`${this.createAppointmentV2SyncService.startDateReq}T${this.createAppointmentV2SyncService.startTime}`), true),
      endDateUtc:
        // eslint-disable-next-line max-len
        this.dateUtils.getDateTimeAsIsoString(new Date(`${this.createAppointmentV2SyncService.endDateReq}T${this.createAppointmentV2SyncService.endTime}`), true),
      startDate: this.createAppointmentV2SyncService.startDateReq,
      startTime: this.createAppointmentV2SyncService.startTime,
      endDate: this.createAppointmentV2SyncService.endDateReq,
      endTime: this.createAppointmentV2SyncService.endTime,
      linkedIds: this.appointment.linkedIds,
      locationId: selectedLocation?.id,
      locationName: selectedLocation?.name,
      locationFreeText: selectedLocation?.locationName,
      entityInstanceId: '',
      cancelled: this.appointment.cancelled,
      customerId,
      customerName,
      workflowDefinitionId: this.appointment.workflowDefinitionId,
      workflowInstanceId: this.appointment.workflowInstanceId,
      status: this.appointment.status,
      endState: this.appointment.endState,
      stateColour: this.appointment.stateColour,
      extra: this.appointment.extra,
      // scissors?: string;
      notes: this.notes,
      pickupRequired: this.appointment.pickupRequired,
      icons: this.appointmentV2IconsComponent ? this.appointmentV2IconsComponent.selectedIcons : [],
      appointmentServiceRows,
      ownerId: this.contextIdDto.contextId,
      // collectionTime: this.appointment.pickupRequired ? this.appointment.collectionTime : '',
      paymentConfig: this.paymentComponent ? this.paymentComponent.getPaymentConfig() : null,
      appointmentSource: Appointment.AppointmentSourceEnum.WebCrm,
      appointmentTimeStrategy: this.getAppointmentTimeStrategy(),
      appointmentPackageRows,
      appointmentType: this.createAppointmentV2SyncService.appointmentType,
      enquiryDate: this.enquiryDate ? this.dateUtils.getDateAsStringDash(this.enquiryDate) : ''
    };
    return updateComp;
  }

  updateAppointment(confirm = false) {
    if (!this.validateRequest()) {
      return;
    }
    if (this.clashes.length && !this.createAnywayApplied) {
      this.createAnywayApplied = true;
    }
    const updateComp: UpdateAppointment = this.prepareUpdateRequestPayload(confirm);

    this.validateAppointmentV2Service.checkConflicts(this.contextIdDto, updateComp.appointment,
      this.createAppointmentV2SyncService.selectedLocation?.id).then((clashes: Array<Clash>) => {
        if (this.appointmentConfigHelperService.appointmentConfig.conflictCheckEnabled && clashes.length && !this.createAnywayApplied) {
          this.clashes = clashes;
          this.checkConflictsDialog = true;
          return;
        }
        this.spinner.show();
        this.appointmentService.updateAppointment(updateComp).subscribe(response => {
          this.spinner.hide();
          if (confirm) {
            this.notify.message = 'Appointment confirmed successfully';
          } else {
            this.notify.message = 'Appointment updated successfully';
          }
          this.notify.open();
          this.createAppointmentV2SyncService.editMode = false;
          this.createAppointmentV2SyncService.clear();
          this.dialogRef.close(response);
        });
      });
  }

  confirmAppointment() {
    this.createAppointment(true, true);
  }

  updateAndConfirmAppointment() {
    this.updateAppointment(true);
  }

  customerCreated(event: Customer) {
    // this.existingCustomer = true;
    this.createAppointmentV2SyncService.populateNewCustomer(event, this.contextIdDto.contextId);
  }

  getLinkId(row: ListEntityListItemDto) {
    const linkId = {} as LinkId;
    linkId.linkedId = row.entityInstanceId.id;
    linkId.linkedIdType = 'ENTITY_INSTANCE';
    return linkId;
  }

  cancel() {
    this.dialogRef.close();
  }

  fullScreen() {
    this.quickAppointment = false;
    this.dialogRef.updateSize('1100px', 'auto');
  }

  exitFullScreen() {
    this.quickAppointment = true;
    this.dialogRef.updateSize(null, 'auto');
  }

  amountDueChanged(amountDue: number) {
    this.createAppointmentV2SyncService.totalAmountDue = amountDue;
  }

  createPlanChanged(willCreatePlan: boolean) {
    this.createNewPlan = willCreatePlan;
  }
  closeConfirmDialog() {
    this.showConfirmationDialog = false;
  }

  updateConfirmEnabled(event) {
    this.appointmentConfigHelperService.appointmentConfig.confirmEnabled = !event;
    this.appointmentConfigHelperService.updateAppointmentConfig(this.contextIdDto, this.appointmentConfigHelperService.appointmentConfig);
  }

  updateConflictCheck(event) {
    this.appointmentConfigHelperService.appointmentConfig.conflictCheckEnabled = !event;
    this.appointmentConfigHelperService.updateAppointmentConfig(this.contextIdDto, this.appointmentConfigHelperService.appointmentConfig);
  }

  createAndConfirm() {
    if (this.appointmentConfigHelperService.appointmentConfig.confirmEnabled) {
      this.showConfirmationDialog = true;
    } else {
      this.confirmAppointment();
    }
  }

  customerModeChange(newMode: string) {
    if (newMode === 'newCustomer') {
      this.createAppointmentV2SyncService.customersSelected = [];
      this.createAppointmentV2SyncService.customersSelectedChips = [];
      this.createAppointmentV2SyncService.updateCustomerSelection([]);
      this.createAppointmentV2SyncService.selectedServices = {};
    } else {
      //
    }
  }

  createAnyway() {
    if (this.operationToPerform) {
      this.createAnywayApplied = true;
      switch (this.operationToPerform) {
        case 'create':
          this.createAppointment();
          break;
        case 'createAndConfirm':
          this.createAndConfirm();
          break;
        case 'update':
          this.updateAppointment();
          break;
        case 'updateAndConfirm':
          this.updateAndConfirmAppointment();
          break;
      }
    }
  }

  updateCustFlags() {
    if (this.customerSelectorV2Component && this.customerSelectorV2Component.customerStatsComponent) {
      this.customerSelectorV2Component.customerStatsComponent.loadStatus();
    }
  }

  locationChange(newLocation: Location) {
    this.createAppointmentV2SyncService.setSelectedLocation(newLocation);
    this.appointment.locationId = newLocation.id;
  }

  getMessage(action: string) {
    let message = '';
    switch (action) {
      case 'create':
        message += 'This will create the booking without sending email/SMS confirmation messages';
        if (this.appointment?.paymentConfig?.paymentMode === this.paymentModeEnum.DepositRequest
          || this.appointment?.paymentConfig?.sendSms) {
          message += ' but will send out a deposit request via';
          message += this.appointment?.paymentConfig?.sendSms ? ' SMS' : '';
          if (this.appointment?.paymentConfig?.paymentMode === this.paymentModeEnum.DepositRequest
            && this.appointment?.paymentConfig?.sendSms) {
            message += ' &';
          }
          if (this.appointment?.paymentConfig?.paymentMode === this.paymentModeEnum.DepositRequest) {
            message += ' Email';
          }
        }
        message += '.';
        break;
      case 'createAndConfirm':
        message += 'This will create the booking and will send out email/SMS confirmation messages';
        if (this.appointment?.paymentConfig?.paymentMode === this.paymentModeEnum.DepositRequest
          || this.appointment?.paymentConfig?.sendSms) {
          message += ' with a deposit request via';
          message += this.appointment?.paymentConfig?.sendSms ? ' SMS' : '';
          if (this.appointment?.paymentConfig?.paymentMode === this.paymentModeEnum.DepositRequest
            && this.appointment?.paymentConfig?.sendSms) {
            message += ' &';
          }
          if (this.appointment?.paymentConfig?.paymentMode === this.paymentModeEnum.DepositRequest) {
            message += ' Email';
          }
        }
        if (this.appointment?.paymentConfig?.paymentMode === this.paymentModeEnum.PaymentPlan) {
          message += ' and selected payment plan will be applied';
        }
        message += '.';
        break;
      case 'update':
        message += 'This will update the booking without sending email/SMS confirmation messages';
        if (this.appointment?.paymentConfig?.paymentMode === this.paymentModeEnum.DepositRequest
          || this.appointment?.paymentConfig?.sendSms) {
          message += ' but will send out a deposit request reminder via';
          message += this.appointment?.paymentConfig?.sendSms ? ' SMS' : '';
          if (this.appointment?.paymentConfig?.paymentMode === this.paymentModeEnum.DepositRequest
            && this.appointment?.paymentConfig?.sendSms) {
            message += ' &';
          }
          if (this.appointment?.paymentConfig?.paymentMode === this.paymentModeEnum.DepositRequest) {
            message += ' Email';
          }
        }
        message += '.';
        break;
      case 'updateAndConfirm':
        message += 'This will update the booking and will send out email/SMS confirmation messages';
        if (this.appointment?.paymentConfig?.paymentMode === this.paymentModeEnum.DepositRequest
          || this.appointment?.paymentConfig?.sendSms) {
          message += ' with a deposit request via';
          message += this.appointment?.paymentConfig?.sendSms ? ' SMS' : '';
          if (this.appointment?.paymentConfig?.paymentMode === this.paymentModeEnum.DepositRequest
            && this.appointment?.paymentConfig?.sendSms) {
            message += ' &';
          }
          if (this.appointment?.paymentConfig?.paymentMode === this.paymentModeEnum.DepositRequest) {
            message += ' Email';
          }
        }
        if (this.appointment?.paymentConfig?.paymentMode === this.paymentModeEnum.PaymentPlan) {
          message += ' and selected payment plan will be applied';
        }
        message += '.';
        break;
    }
    return message;
  }

  paymentConfigChanged($event) {
    this.createAppointmentV2SyncService.calculateTotalAmount();
  }
}
