import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ChangeListener } from './changeListener';
import { EventBusService } from './EventBusService';
import {ViewContextDto} from '@savvy/view-composite';
import { ContextIdDto, ViewCustomerAppointmentHistoryDataDto} from '@savvy/view-definition';
import {AppointmentCompService} from '@savvy/appointment';
import {AppointmentSummaryDto} from '@savvy/appointment/model/models';
@Component({
  selector: 'app-view-customer-appointment-history-panel',
  templateUrl: 'viewCustomerAppointmentHistoryPanel.component.html',
  styleUrls: ['viewAppointmentPanel.component.scss', 'commonPanel.component.scss']
})
export class ViewCustomerAppointmentHistoryPanelComponent implements OnInit {
  @Input() viewCustomerAppointmentHistoryDataDto: ViewCustomerAppointmentHistoryDataDto;
  @Input() contextIdDto: ContextIdDto;
  @Input() viewContext: ViewContextDto;
  @Input() form: UntypedFormGroup;
  @Input() eventBus: EventBusService;
  @Input() additionalDataMapDto: any;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;
  appointments: Array<AppointmentSummaryDto>;

  constructor(private appointmentCompService: AppointmentCompService) { }



  ngOnInit() {
    this.load();
  }


  load() {
    console.log('customer id is ', this.viewCustomerAppointmentHistoryDataDto.customerId);
    this.appointmentCompService.loadCustomerAppointmentsComp(this.contextIdDto.contextId,
      this.viewCustomerAppointmentHistoryDataDto.customerId).subscribe(response => {
        console.log('got ', response);
        this.appointments = response;
    });
  }
}

