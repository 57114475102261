import { Component, Input, OnInit } from '@angular/core';
import { EventBusService } from './EventBusService';
import { EicompService, ListFlagsCompResponse, LoadLastAppointmentResponse } from '@savvy/entity-instance-composite';
import { ContextIdDto } from '@savvy/datalist';
import { WorkflowStatsDto } from '@savvy/view-composite';
import { LinkId } from 'src/app/core/data-share.service';
import * as _ from 'lodash';
@Component({
  selector: 'app-show-ei-status',
  templateUrl: 'showEiStatus.component.html'
})

export class ShowEiStatusComponent implements OnInit {

  @Input() linkId: LinkId;
  @Input() contextIdDto: ContextIdDto;

  eventBus = new EventBusService();
  customerFlagsResponse: ListFlagsCompResponse;
  eiFlagsResponse: ListFlagsCompResponse;
  workflowStatsDto: WorkflowStatsDto;
  loadLastAppointmentResponse: LoadLastAppointmentResponse;

  hasEiFlags = false;
  hasCustomerFlags = false;
  hasLastSeen = false;
  hasWorkflowState = false;
  showStatus = false;

  constructor(private eiCompApi: EicompService) { }

  ngOnInit() {
    if (this.linkId && !_.isEmpty(this.linkId)) {
      this.loadStatus();
    }
  }

  private loadStatus() {
    this.eiCompApi.loadEiStatus(this.linkId.linkedId, this.linkId.linkedEdId, this.contextIdDto.contextId,
      this.contextIdDto.contextIdType).subscribe(response => {
        this.eiFlagsResponse = response.eiListFlagsCompResponse;

        if (response.customerStatusResponse) {
          this.customerFlagsResponse = response.customerStatusResponse.listFlagsCompResponse;
          this.workflowStatsDto = response.customerStatusResponse.workflowStatsDto;
          this.loadLastAppointmentResponse = response.customerStatusResponse.loadLastAppointmentResponse;
        }
        this.updateFlagStates();
      });
  }

  private updateFlagStates() {
    this.hasEiFlags = this.eiFlagsResponse && this.eiFlagsResponse.flagDto && this.eiFlagsResponse.flagDto.length > 0;
    this.hasCustomerFlags = this.customerFlagsResponse && this.customerFlagsResponse.flagDto
      && this.customerFlagsResponse.flagDto.length > 0;
    this.hasLastSeen = this.loadLastAppointmentResponse && this.loadLastAppointmentResponse.numWeeks
      && this.loadLastAppointmentResponse.numWeeks > 0;
    this.hasWorkflowState = this.workflowStatsDto && this.workflowStatsDto.workflowStatDtoList
      && this.workflowStatsDto.workflowStatDtoList.length > 0;
    if (this.hasEiFlags || this.hasCustomerFlags || this.hasLastSeen || this.hasWorkflowState) {
      this.showStatus = true;
    }
  }
}

