import { Component, Input, OnInit } from '@angular/core';
import { EventBusService } from './EventBusService';
import { NavigateService } from '../entry/NavigateService';
import { Page } from '../shared/model/page';
import { Router } from '@angular/router';
import { CachedtablecompService } from '@savvy/cached-view';
import { ViewCachedTableEmbeddedData } from '@savvy/view-definition';
import { ContextIdDto } from '@savvy/quickbooks';

@Component({
  selector: 'app-view-embedded-cached-table',
  templateUrl: './viewEmbeddedCachedTable.component.html'
})
export class ViewEmbeddedCachedTableComponent implements OnInit {

  @Input() viewCachedTableEmbeddedData: ViewCachedTableEmbeddedData;
  @Input() contextIdDto: ContextIdDto;
  @Input() eventBus: EventBusService;
  @Input() viewContext: any;
  page = new Page();
  selected: any[] = [];
  cachedTableRowPageDto: any;

  columns: any[] = [];
  rows = [];

  constructor(
    private router: Router,
    private navigateService: NavigateService,
    private cachedtablecompService: CachedtablecompService) {
  }

  ngOnInit() {
    this.page.size = 10;
    this.initCols();
    this.setPage({ offset: 0 });
  }

  initCols() {
    this.columns = new Array();
    this.viewCachedTableEmbeddedData.columnNames.forEach(colName => {
      // console.log('colName is ' + colName);
      this.columns.push(this.getCol(colName));
    });
  }

  getCol(colDef: string) {
    const col = {};
    // console.log('colDef is ' + colDef);
    col['prop'] = colDef;
    col['name'] = colDef;
    return col;
  }

  setPage(pageInfo) {
    // console.log('pageInfo is ' + pageInfo.offset);
    this.page.pageNumber = pageInfo.offset;
    this.loadPage();
  }

  loadPage() {
    console.log('loading page');
    if (this.viewCachedTableEmbeddedData && this.viewCachedTableEmbeddedData.foreignKeyInstanceId) {
      console.log('entityInstanceId is ' + this.viewCachedTableEmbeddedData.entityInstanceId.id);
      console.log('foreignKeyInstanceId is ' + this.viewCachedTableEmbeddedData.foreignKeyInstanceId);
      this.cachedtablecompService.listCachedEntityTableWithForeignKey(
        this.viewCachedTableEmbeddedData.viewCachedTableEmbeddedDefinition.cachedTableViewDefinitionId.id,
        this.viewCachedTableEmbeddedData.foreignKeyInstanceId,
        (this.page.pageNumber + 1),
        this.page.size,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType).subscribe(response => {
          this.handleResponse(response);
        });
    } else {
      console.log('not loading');
    }
  }

  handleResponse(response) {
    this.cachedTableRowPageDto = response.cachedTableRowPageDto;
    this.page.totalElements = response.cachedTableRowPageDto.totalElements;
    this.page.totalPages = response.cachedTableRowPageDto.totalPages;
    this.buildTable();
  }

  buildTable() {
    if (this.cachedTableRowPageDto && this.cachedTableRowPageDto.contents) {
      const newRows = new Array();
      for (const aRow of this.cachedTableRowPageDto.contents) {
        newRows.push(this.getRow(aRow));
      }
      console.log('rows is now ' + newRows.length);
      this.rows = newRows;
    }
  }

  getRow(cachedTableRow) {
    const row = {};
    cachedTableRow.values.forEach((item, index) => {
      console.log('row ' + index + ' is ' + item);
      row[this.viewCachedTableEmbeddedData.columnNames[index]] = item;
    });
    row['id'] = cachedTableRow.cachedTableRow.entityInstanceId;
    return row;
  }

  numRows() {
    return this.cachedTableRowPageDto.contents.length;
  }
}
