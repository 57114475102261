<br>
<h3>Would you like to try and upsell? (send a check in form)</h3>
<br>
<div fxLayout="column">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">
    <div fxFlex="80">
      <mat-list>
        <mat-list-item *ngFor="let form of checkInFormDefs">
          <mat-checkbox class="ml-1" [(ngModel)]="form.checked" (change)="selectedCheckinFormsChanged()">
            {{ form.name }}</mat-checkbox>
        </mat-list-item>
      </mat-list>
    </div>
  </div>
</div>
