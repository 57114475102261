<mat-toolbar color="primary">
  <mat-toolbar-row *ngIf="!data.contact">{{'ADD_CONTACT' | translate}}</mat-toolbar-row>
  <mat-toolbar-row *ngIf="data.contact">{{'EDIT_CONTACT' | translate}}</mat-toolbar-row>
</mat-toolbar>

<mat-card appearance="outlined">
  <mat-card-content>
    <form #contactForm="ngForm">
      <div fxLayout="column" fxLayoutGap="5px">
        <div fxLayout="row" fxLayoutGap="10px" fxFlex="100">
          <mat-form-field fxFlex="50" appearance="outline" class="w-100">
            <mat-label>{{'FIRST_NAME' | translate}}</mat-label>
            <input matInput [(ngModel)]="contact.firstName" name="firstName" required #firstName="ngModel"
              id="firstName" placeholder="{{'ENTER_FIRST_NAME' | translate}}">
          </mat-form-field>
          <mat-form-field fxFlex="50" appearance="outline" class="w-100">
            <mat-label>{{'LAST_NAME' | translate}}</mat-label>
            <input matInput [(ngModel)]="contact.lastName" name="lastName" required #lastName="ngModel" id="lastName"
              placeholder="{{'ENTER_LAST_NAME' | translate}}">
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="10px" fxFlex="100">
          <app-phone-input fxFlex="50" name="mobilePhoneNumber" class="w-100"
            [(ngModel)]="contact.mobilePhoneNumberObj.nationalNumber" (phoneChanged)="onMobilePhoneChange($event)"
            (countryChanged)="onMobileCountryChange($event)" [selectedCountryCode]="contact.mobilePhoneNumberObj?.country"
            label="{{'MOBILE' | translate}}" placeholder="{{'MOBILE' | translate}}" errorName="Mobile" required="true"></app-phone-input>

          <app-phone-input fxFlex="50" name="phoneNumber" class="w-100"
            [(ngModel)]="contact.phoneNumberObj.nationalNumber" (phoneChanged)="onPhoneChanged($event)"
            (countryChanged)="onPhoneCountryChange($event)" [selectedCountryCode]="contact.phoneNumberObj?.country"
            errorName="Phone" placeholder="{{'PHONE' | translate}}" label="{{'PHONE' | translate}}" required="false"></app-phone-input>
        </div>
        <div fxLayout="row" fxLayoutGap="10px" fxFlex="100">
          <app-phone-input fxFlex="50" name="workPhoneNumber" class="w-100"
            [(ngModel)]="contact.workPhoneNumberObj.nationalNumber" (phoneChanged)="onWorkPhoneChanged($event)"
            (countryChanged)="onWorkPhoneCountryChange($event)" [selectedCountryCode]="contact.workPhoneNumberObj?.country"
            errorName="Work Phone" placeholder="{{'WORK_PHONE' | translate}}" label="{{'WORK_PHONE' | translate}}" required="false">
          </app-phone-input>

          <!-- <app-phone-input fxFlex="50" name="whatsAppNumber" class="w-100"
            [(ngModel)]="contact.whatsAppNumberObj.nationalNumber"
            (intPhoneChanged)="contact.whatsAppNumberObj.nationalNumber = $event"
            [selectedCountryCode]="contact.whatsAppNumberObj?.country" errorName="Whats App" placeholder="WhatsApp No"
            label="WhatsApp No" required="false">
          </app-phone-input> -->
        </div>
      </div>

    </form>
  </mat-card-content>
  <mat-card-content>
    <button mat-raised-button color="accent" (click)="addContact()" [disabled]="!contactForm.valid">
      <ng-container *ngIf="!data.contact">{{'ADD' | translate}}</ng-container>
      <ng-container *ngIf="data.contact">{{'UPDATE' | translate}}</ng-container>
    </button>
    &nbsp;
    <button mat-raised-button (click)="dialogRef.close(false)">{{'CANCEL' | translate}}</button>
  </mat-card-content>

</mat-card>
