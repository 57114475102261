<mat-toolbar color="primary">
  <button mat-icon-button color="accent" class="mr-1" (click)="backToList()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <span>{{loadWorkflowDefinitionForEditResponse?.name}}</span>
  <span class="toolbar-spacer"></span>
  <button *ngIf="showButtonsForApp" mat-button aria-label="Edit Workflow" #tooltip="matTooltip" matTooltip="{{'EDIT_WORKFLOW' | translate}}" (click)="editWorkflowBtnClick($event)">
    {{'EDIT_WORKFLOW' | translate}} <mat-icon class="md-20">edit</mat-icon>
  </button>
  <button mat-button aria-label="Delete Workflow" #tooltip="matTooltip" matTooltip="{{'DELETE_WORKFLOW' | translate}}" (click)="deleteWorkflowBtnClick()">
    {{'DELETE_WORKFLOW' | translate}} <mat-icon>delete</mat-icon>
  </button>
  <button *ngIf="allowEditState" mat-raised-button color="accent" aria-label="Add a new workfow Step" #tooltip="matTooltip" matTooltip="{{'ADD_SECTION' | translate}}" (click)="addStateBtnClick()">{{'ADD_SECTION' | translate}} <mat-icon>add
    </mat-icon>
  </button>
</mat-toolbar>

<!-- <mat-card class="taskboard-card">
  <mat-card-content> -->

<mat-card appearance="outlined" *ngFor="let stateDetails of loadWorkflowDefinitionForEditResponse?.loadWorkflowStateResponseList" [ngStyle]="{'background-color': stateDetails.workflowState.colour || '', 'color': colorInversionByHexString(stateDetails.workflowState.colour)}">
  <mat-card-header class="align-items-center mdc-mb-1">
    <div fxFlex>
      <mat-card-title class="mb-0"> {{stateDetails.workflowState.state}}</mat-card-title>
    </div>
    <button class="mr-xs" mat-button aria-label="Edit State" (click)="editTaskGroup(stateDetails)">
      {{'EDIT' | translate}} <mat-icon class="md-20">edit</mat-icon>
    </button>
    <button *ngIf="showButtonsForApp" mat-button class="mr-xs" aria-label="Delete State" (click)="deleteStateBtnClick(stateDetails)">
      {{'DELETE' | translate}} <mat-icon class="md-20">delete</mat-icon>
    </button>

    <button [matMenuTriggerFor]="menu" mat-stroked-button class="accent" *ngIf="actionTypes && actionTypes.length > 0" aria-label="Add Action">
      {{'ADD_STEP' | translate}} <mat-icon class="md-20">add</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item *ngFor="let at of actionTypes" (click)="addTaskToStateClick(stateDetails, at)">
        <mat-icon>{{at.icon}}</mat-icon>
        <span>{{at.label}}</span>
      </button>
    </mat-menu>

  </mat-card-header>
  <mat-card-content>
    <mat-card appearance="outlined" class="p-1 mx-0">
      <mat-card-content>
        <mat-list class="bg-color-white p-0 m-0">
          <mat-list-item *ngFor="let taskDef of stateDetails.workflowTaskDefinitions" (click)="onTaskSelect(taskDef, stateDetails)">
            <div mat-line>
              <h4 class="mb-0">
                <ng-container class="align-self-center" [ngSwitch]="taskDef.taskType">
                  <div class="task-type-icon" *ngSwitchCase="taskTypeEnum.SendMessage">
                    <mat-icon class="md-20">email</mat-icon>
                  </div>
                  <div class="task-type-icon" *ngSwitchCase="taskTypeEnum.SendSms">
                    <mat-icon class="md-20">sms</mat-icon>
                  </div>
                  <div class="task-type-icon" *ngSwitchCase="taskTypeEnum.ManualTask">
                    <mat-icon class="md-20">task_alt</mat-icon>
                  </div>
                </ng-container>
                {{taskDef?.taskSettings?.taskName}}
              </h4>
            </div>


            <!--            <div mat-line class="text-opac">-->
            <!--              Scheduled at 1 Day after enquiry date-->
            <!--            </div>-->
          </mat-list-item>
        </mat-list>
      </mat-card-content>
    </mat-card>
  </mat-card-content>
</mat-card>

<!-- </mat-card-content>
</mat-card> -->