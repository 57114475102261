<div class="mdc-p-3">
    <div fxLayout="row">
        <div fxFlex>
            <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="16px" class="mdc-mb-1">
                <div fxFlex="nogrow" class="avatar avatar-50 circle border-3">

                    <svg xmlns="http://www.w3.org/2000/svg" width="38.073" height="28" viewBox="0 0 38.073 28"
                        style="margin-top:7px">
                        <g id="Group_187" data-name="Group 187" transform="translate(-929.531 -406)">
                            <g id="Rectangle_71" data-name="Rectangle 71" transform="translate(938 406)" fill="#fff"
                                stroke="#ef9a9a" stroke-width="1">
                                <path
                                    d="M10.5,0h0A10.5,10.5,0,0,1,21,10.5V26a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V10.5A10.5,10.5,0,0,1,10.5,0Z"
                                    stroke="none" />
                                <path
                                    d="M10.5.5h0a10,10,0,0,1,10,10v15a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0v-15A10,10,0,0,1,10.5.5Z"
                                    fill="none" />
                            </g>
                            <g id="Rectangle_72" data-name="Rectangle 72" transform="translate(936 430)" fill="#fff"
                                stroke="#ef9a9a" stroke-width="1">
                                <rect width="25" height="4" rx="2" stroke="none" />
                                <rect x="0.5" y="0.5" width="24" height="3" rx="1.5" fill="none" />
                            </g>
                            <line id="Line_32" data-name="Line 32" x2="6.392" transform="translate(930.031 427.32)"
                                fill="none" stroke="#ef9a9a" stroke-linecap="round" stroke-width="1" />
                            <line id="Line_33" data-name="Line 33" x2="3.835" transform="translate(932.588 424.763)"
                                fill="none" stroke="#ef9a9a" stroke-linecap="round" stroke-width="1" />
                            <line id="Line_34" data-name="Line 34" x2="6.392" transform="translate(960.712 427.32)"
                                fill="none" stroke="#ef9a9a" stroke-linecap="round" stroke-width="1" />
                            <line id="Line_35" data-name="Line 35" x2="3.835" transform="translate(960.712 424.763)"
                                fill="none" stroke="#ef9a9a" stroke-linecap="round" stroke-width="1" />
                            <circle id="Ellipse_54" data-name="Ellipse 54" cx="2" cy="2" r="2"
                                transform="translate(941 414)" fill="#ef9a9a" />
                            <circle id="Ellipse_55" data-name="Ellipse 55" cx="1.83" cy="1.83" r="1.83"
                                transform="translate(944.542 411.146)" fill="#ef9a9a" />
                            <circle id="Ellipse_56" data-name="Ellipse 56" cx="1.83" cy="1.83" r="1.83"
                                transform="translate(948.933 411.146)" fill="#ef9a9a" />
                            <ellipse id="Ellipse_57" data-name="Ellipse 57" cx="1.5" cy="2" rx="1.5" ry="2"
                                transform="translate(953 414)" fill="#ef9a9a" />
                            <path id="Path_139" data-name="Path 139"
                                d="M13.761,13.691c-.637-.746-1.171-1.383-1.815-2.13a3.1,3.1,0,0,0-1.281-.966,1.482,1.482,0,0,0-.242-.066,3.683,3.683,0,0,0-.571-.029,3.07,3.07,0,0,0-.578.037,1.482,1.482,0,0,0-.242.066,3.153,3.153,0,0,0-1.281.966c-.637.746-1.171,1.383-1.815,2.13-.959.959-2.137,2.02-1.917,3.505a2.3,2.3,0,0,0,1.705,1.7c.534.11,2.239-.322,4.054-.322h.132c1.815,0,3.52.424,4.054.322a2.309,2.309,0,0,0,1.705-1.7c.227-1.493-.951-2.554-1.91-3.513Z"
                                transform="translate(938.714 406.134)" fill="#ef9a9a" />
                        </g>
                    </svg>

                </div>
                <div fxFlex class="mdc-ps-1">
                    <h6 class="mb-0">
                        <span>{{ pet?.name }}</span>
                    </h6>
                    <p class="mat-text-secondary mb-0">{{ pet?.breedLabel }}</p>
                </div>
            </div>
        </div>
        <div fxFlex="nogrow" align="end">
            <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="16px" class="mdc-mb-1">
                <div fxFlex>
                    <h6 class="mb-0">
                        <span>{{ pet?.createdDate || 'N/A' }}</span><!-- pet created date with us-->
                    </h6>
                    <p class="mat-text-secondary mb-0">{{ageDate | dateAgo}} {{'WITH_US' | translate}}</p>
                </div>
                <span fxFlex="nogrow" class="icon-24 mat-text-secondary">
                    <mat-icon style="vertical-align: top;">event</mat-icon>
                </span>
            </div>
        </div>
    </div>

    <div class="text-center mt-15px">
        <h5 class="mb-2">{{'PET_PASSED_ON' | translate}}</h5>
        <p>{{'OPT_CUSTOMER_OUT' | translate}}?</p>
        <mat-radio-group class="mb-2 d-block" aria-label="Select an option" [(ngModel)]="optOutOption">
            <mat-radio-button value="ALL" class="pr-1">{{'ALL' | translate}}</mat-radio-button>
            <mat-radio-button value="MARKETING" class="pr-1">{{'ONLY_MARKETING' | translate}}</mat-radio-button>
            <mat-radio-button value="NONE" selected>{{'KEEP_THEM_IN' | translate}}</mat-radio-button>
        </mat-radio-group>
    </div>

    <p class="mat-text-secondary text-center mdc-mb-2">*{{'ALL_FUTURE_APPTS_REMOVED' | translate}}.<br>
        {{'CLICK_ON_CONFIRM' | translate}}
    </p>
    <div class="text-center">
        <button mat-raised-button color="accent" (click)="confirm()">{{'CONFIRM' | translate}}</button>
    </div>
</div>