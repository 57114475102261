import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { EventBusService } from './EventBusService';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { PhoneNumberHelperService } from '../shared/services/phone-number-helper.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { UserCurrencyService } from '../shared/services/userCurrency.service';
import { ElementControlService } from './ElementControlService';
import { Invoice, InvoiceService } from '@savvy/invoice';
import { InvoicecompService, UpdateInvoiceComp, UserDto } from '@savvy/invoice';
import { ContextIdDto, EntityInstanceId, InvoiceDefinition, InvoicePanelDataDto, InvoiceProductRow, InvoiceServiceRow } from '@savvy/view-definition';
import { ConsumerService } from '@savvy/services';
import { Product } from '@savvy/invoice';

@Component({
  selector: 'app-invoice-panel',
  templateUrl: 'invoicePanel.component.html'
})
export class InvoicePanelComponent implements OnInit, OnDestroy {

  @Input() invoicePanelDataDto: InvoicePanelDataDto;
  @Input() viewContext: any;
  @Input() contextIdDto: ContextIdDto;
  @Input() additionalDataMapDto: any;
  @Input() eventBus: EventBusService;

  @Output() taskSelected = new EventEmitter();
  @Output() taskDeselected = new EventEmitter();

  currencyCode: string;
  invoice: Invoice = null;
  invoiceDefinition: InvoiceDefinition;
  userDtos: Array<UserDto>;
  consumerServices: Array<ConsumerService>;
  products: Array<Product>;
  // model: Payment;
  private destroy$ = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: InvoiceService,
    private invoiceCompApi: InvoicecompService,
    private dialog: MatDialog,
    private sharedService: PhoneNumberHelperService,
    private ecs: ElementControlService,
    private userCurrencyService: UserCurrencyService,
    private notify: FloSnackbarComponent,
    private translateService: TranslateService) {
  }


  ngOnInit(): void {
    this.getCurrencyCode();
    this.loadData();
  }

  loadData() {
    console.log('this.invoiceServicesPanelDataDto.invoice.invoiceId.id' + this.invoicePanelDataDto.invoiceId.id);
    if (this.invoicePanelDataDto.invoiceId) {
      this.invoiceCompApi.loadDataForServicePanel(this.invoicePanelDataDto.invoiceId.id,
        this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(response => {

          this.invoiceDefinition = response.invoiceDefinition;
          // @ts-ignore
          this.invoice = response.invoice;
          this.userDtos = response.listUsersResponse.userDtos;
          this.consumerServices = response.loadServicesResponse.services;
          this.products = response.loadProductsResponse.products as Product[];
          // this.model = <Payment>{};
          // this.model.dateTime = new Date();
          // this.model.amount = this.invoice.total;
        });
    }
  }


  loadInvoice() {

    this.api.loadInvoice(this.invoice.invoiceId.id, this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(response => {
      console.log('loaded invoice', response);
      this.invoice = response;
    });
  }


  getCurrencyCode() {
    this.userCurrencyService.getDefaultCurrency(this.contextIdDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.currencyCode = res.org.currencyCode ? res.org.currencyCode : this.userCurrencyService.defaultCurrency;
      });
  }

  createServiceInvoiceRow() {
    console.log('creating row');
    const invoiceRow: InvoiceServiceRow = <InvoiceServiceRow>{};
    if (!this.invoice.invoiceServiceRows) {
      console.log('creating rows array');
      this.invoice.invoiceServiceRows = new Array(0);
    }
    console.log('adding service row 126');

    this.invoice.invoiceServiceRows.push(invoiceRow);
    console.log('rows now ' + this.invoice.invoiceServiceRows.length);
    this.updateInvoice();
  }

  deleteServiceRow(invoiceRow: InvoiceServiceRow) {

    this.invoice.invoiceServiceRows.forEach((item, index) => {
      if (item === invoiceRow) {
        this.invoice.invoiceServiceRows.splice(index, 1);
      }
    });
    this.updateInvoice();
  }

  compareUserFn(user1: UserDto, user2: UserDto) {
    // console.log('comparing');
    return user1 && user2 && user1.id === user2.id;
  }

  eiChanged(row: InvoiceServiceRow, entityInstanceId: string) {
    console.log('eiChanged:' + entityInstanceId);

    row.entityInstanceId = <EntityInstanceId>{};
    row.entityInstanceId.id = entityInstanceId;
    this.updateInvoice();
  }

  createProductInvoiceRow() {
    console.log('creating row');
    const invoiceRow: InvoiceProductRow = <InvoiceProductRow>{};
    if (!this.invoice.invoiceProductRows) {
      console.log('creating rows array');
      this.invoice.invoiceProductRows = new Array(0);
    }
    this.invoice.invoiceProductRows.push(invoiceRow);
    console.log('rows now ' + this.invoice.invoiceProductRows.length);
    this.updateInvoice();
  }

  serviceUserChanged() {
    console.log('service user changed');
    this.updateInvoice();
  }

  deleteProductRow(invoiceRow: InvoiceProductRow) {

    this.invoice.invoiceProductRows.forEach((item, index) => {
      if (item === invoiceRow) {
        this.invoice.invoiceProductRows.splice(index, 1);
      }
    });
    this.updateInvoice();
  }

  productChanged() {
    if (this.invoice.invoiceProductRows) {
      this.invoice.invoiceProductRows.forEach(row => {
        console.log('got row ' + row);
        if (row.productNumber) {
          console.log('Product number is ' + row.productNumber);
          if (!row.unitPrice) {
            const productDto = this.getProduct(row.productNumber);
            if (productDto) {
              row.unitPrice = productDto.unitPrice;
              row.tax = productDto.tax;
            } else {
              console.log('failed to get product');
            }
          } else {
            console.log('Row already has unit price ' + row.unitPrice);
          }
        }
      });
    }
    this.updateInvoice();
  }
  getProduct(productId: string): Product {

    let retProduct: Product;
    if (this.products) {
      this.products.forEach(productDto => {
        if (productDto.id === productId) {
          console.log('found match!');
          retProduct = productDto;
          return productDto;
        } else {
          console.log('no match');
        }
      });
    }
    return retProduct;
  }

  serviceChanged() {
    if (this.invoice.invoiceServiceRows) {
      this.invoice.invoiceServiceRows.forEach(row => {
        console.log('got row ' + row);
        if (row.serviceNumber) {
          console.log('Service number is ' + row.serviceNumber);
          if (!row.unitPrice) {
            const serviceDto = this.getService(row.serviceNumber);
            if (serviceDto) {
              row.unitPrice = serviceDto.unitPrice;
              row.tax = serviceDto.tax;
            } else {
              console.log('failed to get service');
            }
          } else {
            console.log('Row already has unit price ' + row.unitPrice);
          }
        }
      });
    }
    this.updateInvoice();
  }
  getService(serviceId: string): ConsumerService {

    let retConsumerService: ConsumerService;
    if (this.consumerServices) {
      this.consumerServices.forEach(serviceDto => {
        if (serviceDto.id === serviceId) {
          console.log('found match!');
          retConsumerService = serviceDto;
          return serviceDto;
        } else {
          console.log('no match');
        }
      });
    }
    return retConsumerService;
  }

  updateInvoice() {
    const req: UpdateInvoiceComp = <UpdateInvoiceComp>{};
    req.contextIdDto = this.contextIdDto;
    req.invoice = this.invoice;

    this.invoiceCompApi.updateInvoiceComp(req)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        // @ts-ignore
        this.invoice = response.invoice;
        // this.ecs.handleChangedElements(response.changedElementList, this.form);
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
