import { Injectable } from '@angular/core';
import { MenuFactory } from './menu.factory';
import { ContextIdDto, MenuDefinition, MenuStructure } from '@savvy/menu';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { APP_TYPE_ENUM, ContextService } from '../context.service';
import { Subject } from 'rxjs';
import { GLOBAL } from '../../app.constants';
import MenuItemTypeEnum = MenuDefinition.MenuItemTypeEnum;

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  edId: string; // This is for breadcrumbs I think.  TODO: Can we remove it?
  itemType: MenuItemTypeEnum;
  state?: string;
  params?: string[];
  name: string;
  icon?: string;
  svgIcon: boolean;
  type?: string;
}

export interface Menu {
  edId: string; // This is for breadcrumbs I think.  TODO: Can we remove it?
  itemType: MenuItemTypeEnum;
  state?: string;
  params?: string[];
  name: string;
  type: string;
  icon?: string;
  svgIcon: boolean;
  svgXml: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}


@Injectable()
export class LocalMenuService {
  iconsLoaded = false;
  menuItems: Menu[] = [];
  adminMenuItems = [];
  created = new Date();
  menuInitiated = false;
  menuStructure: MenuStructure;

  public adminMenuItemsChanged$ = new Subject();
  constructor(
    private menuFactory: MenuFactory,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private contextService: ContextService
  ) { }

  setMenuStructure(menuStructure) {
    this.menuStructure = menuStructure;
  }

  getMenuStructure() {
    return this.menuStructure;
  }

  setAdminMenu(value) {
    this.adminMenuItems = value;
    this.adminMenuItemsChanged$.next(value);
  }

  getAll(): Menu[] {
    return this.menuItems;
  }

  add(menu: Menu) {
    this.menuItems.push(menu);
  }

  clear() {
    this.menuItems = [];
  }


  initMenu(menuStructure, contextIdDto: ContextIdDto) {

    console.log('init menu');
    this.iconsLoaded = false;
    this.menuInitiated = true;
    this.clear();
    if (menuStructure) {
      console.log('init menu with menuStructure', menuStructure);
      if (menuStructure.menuDefinitionList) {
        console.log('menuDefinitionList items are ' + menuStructure.menuDefinitionList.length);
        for (const menuDefinitionDto of menuStructure.menuDefinitionList) {
          // console.log('getting menu def for child menu ' + menuDefinitionDto.label);
          const menuDefinition = this.menuFactory.getMenuDefinition(menuDefinitionDto, contextIdDto);
          if (menuDefinition) {
            this.add(menuDefinition);
          }
        }
      } else {
        console.log('menuDefinitions items are ' + menuStructure.menuDefinitions.length);
        for (const menuDefinitionDto of menuStructure.menuDefinitions) {
          // console.log('getting menu def for child menu ' + menuDefinitionDto.label);
          const menuDefinition = this.menuFactory.getMenuDefinition(menuDefinitionDto, contextIdDto);
          if (menuDefinition) {
            this.add(menuDefinition);
          }
        }
      }
    }
    this.iconsLoaded = true;
    console.log('set icons loaded to true');

    this.menuInitiated = false;
  }


  getMenuItemByType(itemType: MenuItemTypeEnum) {

    let menuToRet = null;
    if (this.menuItems) {
      this.menuItems.forEach(
        menu => {
          if (menu.itemType === itemType) {
            menuToRet = menu;
          }
        }
      );
    }
    return menuToRet;
  }

  getMenuItemByEdId(id: string): Menu {
    let menuToRet = null;

    if (this.menuItems) {
      this.menuItems.forEach(
        menu => {
          if (menu.edId === id) {
            menuToRet = menu;
          }
        }
      );
    }
    return menuToRet;
  }

  getIconPath() {
    switch (this.contextService.appType) {
      case APP_TYPE_ENUM.BEAUTY_SALON:
        return '/BeautySalon';
      // case 'Custom':
      //   return '/Custom';
      // case 'Delivers':
      //   return '/Delivers';
      case APP_TYPE_ENUM.PET_GROOMING_SALON:
        return '/PetGroomingSalon';
      case APP_TYPE_ENUM.PHOTO_STUDIO:
        return '/PhotoStudio';
      case APP_TYPE_ENUM.TRADING:
        return '/Trading';
      default:
        return '/';
    }
  }

  async addMenuIcon(menu: MenuDefinition) {
    const iconPath = await this.getIconPathWithFallback(menu);
    this.iconRegistry.addSvgIcon(menu.icon || '', this.sanitizer.bypassSecurityTrustResourceUrl(iconPath || ''));
  }

  // use a get request to see if the svg exists, if not return a default svg
  async getIconPathWithFallback(menu: MenuDefinition) {
    try {
      const iconPath = `assets/icons${this.getIconPath()}/icons8-${menu.icon}.svg`;
      const response = await this.http.head(iconPath, { responseType: 'blob' }).toPromise();
      console.log('response type', response?.type);
      return response && response.type === 'image/svg+xml' ? iconPath : 'assets/icons/default.svg';
    } catch (error: any) {
      if (error?.status === 404) {
        return `assets/icons${this.getIconPath()}/default.svg`;
      }
    }
    // if the svg icon is found the response will have type svg. If file not found returns html
  }

  handleLoadAdminMenuResponse(menuStructure) {
    if (menuStructure) {
      console.log('got menu structure ', menuStructure);
      for (const menu of menuStructure.menuDefinitionList) {
        // await this.menuService.addMenuIcon(menu);
        if (menu.svgIcon && !menu.svgXml) {
          menu.svgIcon = false;
          menu.icon = GLOBAL.defaultIcon;
        }
        if (menuStructure.menuDefinitions) {
          for (const menuDefinitionDto of menuStructure.menuDefinitions) {
            // console.log('getting menu def for child menu ' + menuDefinitionDto.label);
            // const menuDefinition = this.menuFactory.getMenuDefinition(menuDefinitionDto, this.contextIdDto);
            // if (menuDefinition) {
            //   await this.menuService.addMenuIcon(menuDefinition);
            // }
            if (menuDefinitionDto.svgIcon && !menuDefinitionDto.svgXml) {
              menuDefinitionDto.svgIcon = false;
              menuDefinitionDto.icon = GLOBAL.defaultIcon;
            }
          }
        }
      }
      this.setAdminMenu(menuStructure.menuDefinitionList);
    } else {
      console.log('no menu structure ' + menuStructure);
    }
  }
}
