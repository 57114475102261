import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { WindowRef } from '../windowWrapper';
import { MatDialog } from '@angular/material/dialog';
import { Brand, BrandingService } from '@savvy/branding';
import { ContextIdDto } from '@savvy/quickbooks';
// import {
//   CreateCustomerSignupForm,
//   CustomerSignupFormDefinition,
//   CustomerSignupFormService,
//   UpdateCustomerSignupForm
// } from '@savvy/customer-signup-form';

@Component({
  selector: 'app-add-customer-signup-form-def',
  templateUrl: './addEditCustomerSignupFormDef.component.html'
})
export class AddEditCustomerSignupFormDefComponent implements OnInit {

  // private sub: any;
  // contextIdDto: ContextIdDto;
  // editing = {};
  // embedUrl;
  // linkUrl;
  // brands: Array<Brand>;

  // widthIsDevice = 70;
  // showEmbed = false;
  // showLink = false;
  // customerSignupFormDefinition = <CustomerSignupFormDefinition>{};

  // constructor(
  //   private route: ActivatedRoute,
  //   private notify: FloSnackbarComponent,
  //   private router: Router,
  //   private brandApi: BrandingService,
  //   private windowRef: WindowRef,
  //   public dialog: MatDialog,
  //   private defApi: CustomerSignupFormService) {
  // }


  ngOnInit() {
    //   this.sub = this.route.params.subscribe(params => {
    //      this.contextIdDto = <ContextIdDto>{};
    //     this.contextIdDto.contextId = params['contextId'];
    //     this.contextIdDto.contextIdType = params['contextIdType'];
    //     this.getBrandings();
    //     const customerSignupFormDefinitionId = params['customerSignupFormDefinitionId'];
    //     console.log('in add edit checkinForm customerSignupFormDefinitionId ' + customerSignupFormDefinitionId);
    //     if (customerSignupFormDefinitionId) {
    //       // Load it
    //       this.defApi.loadCustomerSignupForm(customerSignupFormDefinitionId,
    //         this.contextIdDto.contextId,
    //         this.contextIdDto.contextIdType)
    //         .subscribe(checkinFormDef => {
    //           this.customerSignupFormDefinition = checkinFormDef;
    //         });
    //     } else {
    //       this.customerSignupFormDefinition = <CustomerSignupFormDefinition>{};
    //       this.customerSignupFormDefinition.ownerId = this.contextIdDto.contextId;
    //     }
    //   });
  }

  backToList() {
    //   this.router.navigate(['/customerSignup/customerSignupFormDefs',
    //     this.contextIdDto.contextId,
    //     this.contextIdDto.contextIdType]);
  }

  // getBrandings() {
  //   this.brandApi.getAllBrands(this.contextIdDto.contextId).subscribe(
  //     brandings => {
  //       console.log('got ' + brandings.length + ' brandings');
  //       this.brands = brandings;
  //     });
  // }

  // add() {
  //   const req = <CreateCustomerSignupForm>{};
  //   req.contextIdDto = this.contextIdDto;
  //   req.customerSignupFormDefinition = this.customerSignupFormDefinition;

  //   this.defApi.createCustomerSignupForm(req).subscribe(response => {
  //     this.customerSignupFormDefinition = response;
  //     this.notify.message = 'Created Sign Up Form';
  //     this.notify.open();
  //     this.router.navigate(['/customerSignup/editCustomerSignupFormDef',
  //       this.contextIdDto.contextId,
  //       this.contextIdDto.contextIdType,
  //       response.id]);
  //   });
  // }

  // delete() {
  //   this.defApi.deleteCustomerSignupForm(this.customerSignupFormDefinition.id,
  //     this.contextIdDto.contextId,
  //     this.contextIdDto.contextIdType).subscribe(response => {
  //     this.notify.message = 'Deleted Customer Sign In Form';
  //     this.notify.open();
  //     this.backToList();
  //   });
  // }

  // update() {
  //   if (this.customerSignupFormDefinition.id) {
  //     const req = <UpdateCustomerSignupForm>{};
  //     req.contextIdDto = this.contextIdDto;
  //     req.customerSignupFormDefinition = this.customerSignupFormDefinition;
  //     console.log('calling update');
  //     this.defApi.updateCustomerSignupForm(req).subscribe(response => {
  //       this.notify.message = 'Update Customer Signup Form';
  //       this.notify.open();
  //       this.customerSignupFormDefinition = response;
  //     });
  //   }
  // }

  preview() {
    // New window please

    /*  console.log('this.windowRef location is ' + this.windowRef.getWindow().location);
      const url = this.windowRef.getPrefix() + '/cf/enterCheckinForm/' + this.contextIdDto.contextId
        + '/' + this.contextIdDto.contextIdType + '/' + this.customerSignupFormDefinition.id;
  
      console.log('url is ' + url);
      this.windowRef.getWindow().open(url);*/
  }


  embed() {

    /* const url = this.windowRef.getPrefix() + '/cf/enterCheckinForm/' + this.contextIdDto.contextId
       + '/' + this.contextIdDto.contextIdType + '/' + this.customerSignupFormDefinition.id;
     this.embedUrl = '<iframe width="100%" height="670" src="' + url + '"frameborder="0" allowfullscreen></iframe>';
     this.showEmbed = true;*/
  }

  hideEmbed() {
    // this.showEmbed = false;
  }

  link() {

    /* this.linkUrl = this.windowRef.getPrefix() + '/cf/enterCheckinForm/' + this.contextIdDto.contextId
       + '/' + this.contextIdDto.contextIdType + '/' + this.customerSignupFormDefinition.id;
     this.showLink = true;*/
  }

  compareBrandFn(brand1: Brand, brand2: Brand) {
    return brand1 && brand2 && brand1.id === brand2.id;
  }

  hideLink() {
    // this.showLink = false;
  }
}
