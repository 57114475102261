import { Component, Input, OnInit } from '@angular/core';
import {
  SimpleslotsService, ContextIdDto,
  DaySlotDto,
  GetSimpleSlotsForDay,
  SlotAndCheckDto,
  SlotDto,
  SlotFilterDto
} from '@savvy/booking';
import { SimpleBookingDefinition } from '@savvy/booking-definition';
// import {SimpleBookingDefApiExtension} from '../extension/SimpleBookingDefApiExtension';
import { StyleService } from '../style/style.service';

@Component({
  selector: 'app-select-slot',
  templateUrl: 'bookSlot.component.html',
  styleUrls: ['bookSlot.component.scss']
})
export class BookSlotComponent implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() serviceNumbers: Array<string>;
  @Input() bookingDef: SimpleBookingDefinition;

  appointmentTime: Date;
  daySlot: DaySlotDto;
  selectedSlot: SlotDto;

  constructor(
    public styleService: StyleService,
    private slotApi: SimpleslotsService,
    //  private bookingDefApi: SimpleBookingDefApiExtension,
  ) {

  }

  ngOnInit(): void {
  }

  isSlotAvailable(slotAndCheckDto: SlotAndCheckDto) {
    return slotAndCheckDto.slotDto.availableUsersNames && slotAndCheckDto.slotDto.availableUsersNames.length > 0;
  }

  selectSlot(slot: SlotDto) {
    this.selectedSlot = slot;
  }

  search() {
    console.log('search');
    console.log('search' + this.appointmentTime);
    console.log('search' + this.bookingDef);

    if (this.serviceNumbers.length === 0) {

    } else {
      if (this.appointmentTime) {
        console.log('serviceNumbers is ' + this.serviceNumbers);
        const req = <GetSimpleSlotsForDay>{};

        req.contextIdDto = this.contextIdDto;
        req.bookingDefinitionId = this.bookingDef.bookingDefinitionId;
        req.slotFilterDto = <SlotFilterDto>{};
        req.slotFilterDto.serviceNumbers = this.serviceNumbers;
        req.slotFilterDto.locationIds = null;
        req.slotFilterDto.users = null;
        console.log('day is ' + this.appointmentTime.getDate());
        console.log('month is ' + this.appointmentTime.getMonth());
        console.log('full year is ' + this.appointmentTime.getFullYear());


        req.day = this.appointmentTime.getDate();
        req.month = this.appointmentTime.getMonth() + 1;
        req.year = this.appointmentTime.getFullYear();
        this.slotApi.getSimpleSlotsForDay(req).subscribe(response => {
          console.log('got response');
          this.daySlot = response;
        }
        );
      } else {
        console.log('not searching');
      }
    }
  }
}
