import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CalendarSettings } from '@savvy/calendar-settings';
import { BoardingBooking, BoardingBookingService, ChangeState, PetRun, PetStayPreference } from '@savvy/pet-stay';
import { ContextIdDto } from '@savvy/ui';
import { UsersCalendarSettings } from '@savvy/user-calendar-settings';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreateBookingEvent } from 'src/app/core/pet-stay-calendar/pet-stay-calendar.component';
import { ClarificationDialogComponent } from '../clarification-dialog/clarification-dialog.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { DateUtils } from '../dates/DateUtils';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { CreateBoardingBookingV2SyncService } from './create-boarding-booking-v2/create-boarding-booking-v2-sync.service';
import { CreateBoardingBookingData, CreateBoardingBookingV2Component } from './create-boarding-booking-v2/create-boarding-booking-v2.component';
import { ViewPetBoardingComponent } from './view-pet-boarding/view-pet-boarding.component';
import { IdNameTupleDto } from '@savvy/user';

@Injectable({
  providedIn: 'root'
})
export class BoardingBookingHandlerService {

  constructor(
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private createBoardingBookingV2SyncService: CreateBoardingBookingV2SyncService,
    private dateUtils: DateUtils,
    private boardingBookingService: BoardingBookingService,
    private notify: FloSnackbarComponent
  ) { }

  public viewBooking(boardingBookingId: string, contextIdDto: ContextIdDto, petStayPreference: PetStayPreference, callback?: (data?: any) => void, calendarSettings?: CalendarSettings, usersCalendarSettings?: UsersCalendarSettings) {
    if (boardingBookingId.indexOf('-') > -1) {
      boardingBookingId = boardingBookingId.split('-')[0];
    }
    const dialogRef = this.dialog.open(ViewPetBoardingComponent, {
      data: {
        contextIdDto,
        boardingBookingId
      },
      autoFocus: false,
      panelClass: 'quickview'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.rebook) {
          this.loadPetStayBooking(boardingBookingId, contextIdDto, petStayPreference).then((boardingBooking: BoardingBooking) => {
            if (boardingBooking) {
              this.rebookBooking(contextIdDto, result.boardingBooking, petStayPreference, callback, calendarSettings, usersCalendarSettings);
            }
          });
        } else {
          this.loadPetStayBooking(boardingBookingId, contextIdDto, petStayPreference).then((boardingBooking: BoardingBooking) => {
            if (boardingBooking) {
              this.editBooking(contextIdDto, boardingBooking, petStayPreference, callback, calendarSettings, usersCalendarSettings);
            }
          });
        }
      } else {
        callback(result);
      }
    });
  }

  loadPetStayBooking(boardingBookingId: string, contextIdDto: ContextIdDto, petStayPreference: PetStayPreference) {
    return new Promise<BoardingBooking>(resolve => {
      // Perhaps load customer and invoice server side?
      this.boardingBookingService.getBoardingBookingById(boardingBookingId).subscribe(response => {
        this.createBoardingBookingV2SyncService.editMode = true;
        this.createBoardingBookingV2SyncService.populateAppointment(response?.boardingBooking, contextIdDto, response?.petStayTimeRanges, petStayPreference)
          .then(res => {
            resolve(res);
          });
      });

    });
  }

  public editBooking(contextIdDto: ContextIdDto, boardingBooking: BoardingBooking, petStayPreference: PetStayPreference, callback?: (data?: any) => void, calendarSettings?: CalendarSettings, usersCalendarSettings?: UsersCalendarSettings) {
    const data: CreateBoardingBookingData = {
      boardingBooking,
      contextIdDto,
      edit: true,
      calendarSettings: calendarSettings || {} as CalendarSettings,
      usersCalendarSettings: usersCalendarSettings || {} as UsersCalendarSettings,
      petStayPreference
    };
    this.spinner.hide();
    const confirmDialog = this.dialog.open(CreateBoardingBookingV2Component, {
      data,
      panelClass: 'quickcreate',
      autoFocus: false
    });

    confirmDialog.afterClosed().subscribe((result: BoardingBooking) => {
      this.createBoardingBookingV2SyncService.clear();
      if (result) {
        callback(result);
      }
    });

  }

  public rebookBooking(contextIdDto: ContextIdDto, boardingBooking: BoardingBooking, petStayPreference: PetStayPreference, callback?: (data?: any) => void, calendarSettings?: CalendarSettings, usersCalendarSettings?: UsersCalendarSettings) {
    delete boardingBooking.id;
    delete boardingBooking.invoiceId;
    boardingBooking.startDate = this.dateUtils.getDateAsStringDash(new Date());
    boardingBooking.startDateUtc = new Date(boardingBooking.startDate).toISOString(); // ??
    boardingBooking.endDate = this.dateUtils.getDateAsStringDash(this.dateUtils.addDays(new Date(), 1));
    boardingBooking.endDateUtc = new Date(boardingBooking.endDate).toISOString();
    const data: CreateBoardingBookingData = {
      boardingBooking,
      contextIdDto,
      edit: false,
      rebook: true,
      calendarSettings: calendarSettings || {} as CalendarSettings,
      usersCalendarSettings: usersCalendarSettings || {} as UsersCalendarSettings,
      petStayPreference
    };
    const confirmDialog = this.dialog.open(CreateBoardingBookingV2Component, {
      data,
      panelClass: 'quickcreate',
      autoFocus: false
    });
    this.spinner.hide();

    confirmDialog.afterClosed().subscribe((result: BoardingBooking) => {
      this.createBoardingBookingV2SyncService.clear();
      if (result) {
        callback(result);
      }
    });

  }

  createBooking(contextIdDto: ContextIdDto, booking: CreateBookingEvent, usersCalendarSettings: UsersCalendarSettings, calendarSettings: CalendarSettings, petStayPreference: PetStayPreference, locationId?: string, bookingType?: BoardingBooking.BookingTypeEnum, callback?: (data?: any) => void) {
    let startDate = new Date();
    if (booking.start) {
      startDate = new Date(new Date(booking.start.setHours(startDate.getHours())).setMinutes(new Date().getMinutes()));
    }
    
    const data: CreateBoardingBookingData = {
      petRunId: booking?.runId || '',
      contextIdDto,
      edit: false,
      startDateToUseOnCreate: startDate,
      end: booking.end || new Date(),
      bookingType,
      userToUseWhenCreating: {} as IdNameTupleDto,
      startTime: moment(usersCalendarSettings.startDayViewTime, 'HH:mm:ss').format('HH:mm'),
      endTime: moment(usersCalendarSettings.endDayViewTime, 'HH:mm:ss').format('HH:mm'),
      calendarSettings,
      usersCalendarSettings,
      locationId,
      petStayPreference
    };

    const confirmDialog = this.dialog.open(CreateBoardingBookingV2Component, {
      data,
      panelClass: 'quickcreate',
      autoFocus: false
    });

    confirmDialog.afterClosed().subscribe((result: BoardingBooking) => {
      this.createBoardingBookingV2SyncService.clear();
      if (result && callback) {
        callback(result);
      }
    });
  }

  cancelAppointment(boardingBookingId: string, contextIdDto: ContextIdDto, callback?: (data?: any) => void) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Cancel Booking',
        message: 'Are you sure you want to cancel this booking?',
        // isChange: true
      },
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.clarifyCancellation(boardingBookingId, contextIdDto, callback);
      }
    });
  }

  clarifyCancellation(boardingBookingId: string, contextIdDto: ContextIdDto, callback?: (data?: any) => void) {
    const dialogRef = this.dialog.open(ClarificationDialogComponent, {
      data: {
        title: 'Cancellation Reason',
        message: 'What is the reason for cancellation of this booking?'
      },
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const req: ChangeState = {
          newState: 'Cancelled',
          reason: result?.clarify,
          askForReview: true,
        };
        this.boardingBookingService.changeStateV2(boardingBookingId, contextIdDto.contextId,
          contextIdDto.contextIdType, req)
          .subscribe(response => {
            if (response) {
              this.notify.message = 'Booking cancelled successfully';
              this.notify.open();
              callback(response);
            }
          });
      }
    });
  }

  deleteAppointment(boardingBookingId: string, contextIdDto: ContextIdDto, callback?: (data?: any) => void) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete Booking',
        message: 'Are you sure you want to delete this booking?',
      },
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && boardingBookingId) {
        this.boardingBookingService.deleteBoardingBooking(boardingBookingId)
          .subscribe(response => {
            if (response) {
              this.notify.message = 'Booking deleted successfully';
              this.notify.open();
              callback(response);
            }
          });
      }
    });

  }

}
