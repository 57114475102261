import {Injectable} from '@angular/core';
import {ContextIdDto} from '@savvy/view-definition';
import {Router} from '@angular/router';
import {EventType, UiEvent} from '../event/UiEvent';

@Injectable()
export class EventHandlerService {
  constructor(private router: Router) {
  }

  public handleEvent(contextIdDto: ContextIdDto, event: UiEvent) {

    console.log('navigateTo got event ' + event);

    if (event.eventType === EventType.EDIT_ENTITY) {
      this.router.navigate(['/entity/editEntity',
        event.entityInstanceId.id,
        contextIdDto.contextId,
        contextIdDto.contextIdType]);
    } else if (event.eventType === EventType.VIEW_ENTITY) {
      this.router.navigate(['/entity/viewEntity',
        event.entityInstanceId.id,
        contextIdDto.contextId,
        contextIdDto.contextIdType]);
    }
  }
}
