import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ContextService } from '../context.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ReminderDefService, ReminderDefinition } from '@savvy/reminders';
import { ContextIdDto } from '@savvy/view-definition';
import { Subscription } from 'rxjs';
import { ClearObservable } from '../shared/classes/clear-observable';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-add-edit-reminder-def-page',
  templateUrl: './addEditReminderDefPage.component.html',
  providers: [
  ]
})
export class AddEditReminderDefPageComponent extends ClearObservable implements OnInit {
  @Input() reminderDefId: string;
  sub: any;
  reminderDef: ReminderDefinition;
  contextIdDto: ContextIdDto;
  subscriptions: Subscription[] = [];

  reminderDefLoaded = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private reminderDefApi: ReminderDefService,
    public contextService: ContextService) {
    super();
  }


  ngOnInit(): void {
    this.route.parent.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        if (params['contextId'] && params['contextIdType']) {
          this.contextIdDto = {} as ContextIdDto;
          this.contextIdDto.contextId = params['contextId'];
          this.contextIdDto.contextIdType = params['contextIdType'];
          if (this.reminderDefId) {
            this.loadReminderDef(this.reminderDefId);
          }
        }
      });
  }

  loadReminderDef(reminderDefId: string) {
    this.reminderDefLoaded = false;
    console.log('loading reminder def ' + reminderDefId);
    this.reminderDefApi.loadReminderDef(reminderDefId, this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(
      response => {
        this.reminderDef = response;
        this.reminderDefLoaded = true;
      });
  }

}
