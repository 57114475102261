<div *ngIf="showSpinner" class="bg-spinner-wrapper">
  <mat-spinner style="margin: 0 auto;"></mat-spinner>
</div>
<div *ngIf="visible">

  <mat-toolbar color="primary">
    <mat-toolbar-row>{{'SEND_CONTRACT' | translate: {Default: 'Send Contract'} }}</mat-toolbar-row>
  </mat-toolbar>
  <mat-card appearance="outlined">

    <mat-card-content>
      <form #contractForm="ngForm">

        <div fxLayout="column">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{'TO' | translate: {Default: 'To'} }}</mat-label>
            <input type="text" matInput name="recipient" #recipient="ngModel" [(ngModel)]="recipients"
                   placeholder="{{'TO' | translate: {Default: 'To'} }}" required>
            <mat-error *ngIf="recipient.hasError('required')">
              {{'TO_IS' | translate: {Default: 'To is'} }} <strong>{{'REQUIRED' | translate: {Default: 'required'} }}</strong>
            </mat-error>
          </mat-form-field>


          <mat-form-field appearance="outline">
            <mat-label>{{'SUBJECT' | translate: {Default: 'Subject'} }}</mat-label>
            <input matInput
                   placeholder="{{'SUBJECT' | translate: {Default: 'Subject'} }}"
                   name="subjectField"
                   #subjectField="ngModel"
                   [(ngModel)]="subject"
                   id="subjectField"
                   required>
            <mat-error *ngIf="subjectField.hasError('required')">
              {{'SUBJECT_IS' | translate: {Default: 'Subject is'} }} <strong>{{'REQUIRED' | translate: {Default: 'required'} }}</strong>
            </mat-error>
          </mat-form-field>


          <app-flo-editor [text]="body" (textUpdated)="bodyUpdated($event)"></app-flo-editor>
          <app-upload-files #uploadFiles [contextIdDto]="contextIdDto" [savedFiles]="savedFiles"></app-upload-files>
          <br><br/>

          <!--
          <mat-form-field appearance="outline">
            <mat-label>Message</mat-label>
            <textarea matInput
                      id="bodyField"
                      name="bodyField"
                      #bodyField="ngModel"
                      [(ngModel)]="body"
                      placeholder="Enter Message">
          </textarea>
            <mat-error *ngIf="bodyField.hasError('required')">
              Message is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          -->

        </div>
      </form>

    </mat-card-content>
    <hr>
    <mat-card-content>
      <button mat-raised-button color="accent" (click)="send()" [disabled]="!contractForm.valid || !allowedToSend">{{'SEND' | translate: {Default: 'Send'} }}</button>
      &nbsp;
      <button mat-raised-button (click)="cancelIt()">{{'CANCEL' | translate: {Default: 'Cancel'} }}</button>

      <div *ngIf="!allowedToSend">
        <mat-error>{{'CANT_SEND_CONTRACT' | translate: {Default: 'You cant send contract, because its not allowed to send'} }} {{'EMAIL' | translate: {Default: 'Email'} }} {{'MESSAGE_TO_THIS_CUSTOMER' | translate: {Default: 'message to this customer'} }}.</mat-error>
      </div>
    </mat-card-content>

  </mat-card>

</div>
