import { Component, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Customer, CustomerCompositeService, CustomerService, PhoneNumber } from '@savvy/customer';
import { CustomerOnboardingService, Pet, SelfRegisterCustomer } from '@savvy/customer-onboarding';
import { CustomerPortalService, InviteExistingCustomer } from '@savvy/customer-portal';
import { EmailmessagetemplatesService } from '@savvy/email-template';
import { EmailRecipient, Link } from '@savvy/messaging';
import { PetService } from '@savvy/pet';
import { PerformSearch, SearchService } from '@savvy/search';
import { ContextIdDto, UserId } from '@savvy/user';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { UserCountryService } from '../../../services/userCountry.service';
import { AppointmentHandlerService } from '../../appointments-v2/appointment-handler.service';
import { AppointmentConfigHelperService } from "../../shared/services/appointment-config-helper.service";
import { UrlLookupService } from '../../shared/services/urlLookup.service';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';
import { CalendarSetupService } from '../../welcome-to-savvy/calendar-setup.service';
import { CustomerHandlerService } from '../customer-handler.service';
@Component({
  selector: 'app-invite-customer',
  templateUrl: 'invite-customer.html',
  styleUrls: ['invite-customer.scss']
})
export class InviteCustomerComponent implements OnInit {
  @ViewChild('inviteForm') inviteForm: NgForm;

  @Input() contextIdDto: ContextIdDto;

  customerEmail = '';
  firstName = '';
  lastName = '';
  phoneNumberObj: PhoneNumber = {
    country: 'GB',
    internationalNumber: '',
    nationalNumber: ''
  };
  customerExist = false;

  existingCustomer: Customer | undefined = undefined;

  emailSubjectText = '';
  emailMessageText = '';
  userId: UserId;

  petList: Pet[] = [];
  links: Array<Link> = [];

  selfRegUrl = '';

  selfRegister = false;
  emailChangedSub: Subject<string> = new Subject<string>();
  customerId = '';

  constructor(
    public appointmentConfigHelperService: AppointmentConfigHelperService,
    public deviceService: DeviceDetectorService,
    public userCountryService: UserCountryService,
    public customerCompositeService: CustomerCompositeService,
    public customerService: CustomerService,
    private notify: FloSnackbarComponent,
    private emailmessagetemplatesService: EmailmessagetemplatesService,
    private customerPortalService: CustomerPortalService,
    private appointmentHandlerService: AppointmentHandlerService,
    private calendarSetupService: CalendarSetupService,
    private petService: PetService,
    private customerHandlerService: CustomerHandlerService,
    private customerOnboardingService: CustomerOnboardingService,
    private urlLookupService: UrlLookupService,
    private spinner: NgxSpinnerService,
    private searchApi: SearchService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data?,
    @Optional() public dialogRef?: MatDialogRef<InviteCustomerComponent>,
  ) {
  }

  ngOnInit(): void {
    this.loadTemplate();
    this.loadCalendarSettings();
    const baseUrl = this.urlLookupService.customerRegistrationUrl();
    this.selfRegUrl = `${baseUrl}/${this.contextIdDto.contextId}`;

    this.emailChangedSub
      .pipe(debounceTime(800))
      .pipe(distinctUntilChanged())
      .subscribe(model => {
        this.customerEmail = model;
        this.inviteForm.controls['userName'].markAsDirty();
        this.checkUserNameExist();
      });
  }

  onEmailChange(query: string) {
    this.emailChangedSub.next(query);
  }

  loadCalendarSettings() {
    this.calendarSetupService.loadCalendarSettings(this.contextIdDto);
  }

  checkUserNameExist() {
    if (this.customerEmail) {
      if (this.customerEmail) {
        this.customerCompositeService.checkUserOnboard(this.contextIdDto.contextId, 'ENV_ID', this.customerEmail).subscribe(res => {
          if (res?.userExists) {
            this.customerExist = true;
            this.inviteForm.controls['userName'].setErrors({ 'userNameExist': true });
            this.customerId = res?.customerId;
            this.loadCustomer();
          } else {
            this.customerExist = false;
            this.existingCustomer = undefined;
            this.inviteForm.controls['userName'].setErrors(null);
          }
          this.inviteForm.controls['userName'].markAsDirty();
        });
      }
    }

    if (this.phoneNumberObj?.nationalNumber) {
      const req = <PerformSearch>{};
      req.contextIdDto = this.contextIdDto;
      req.searchString = this.phoneNumberObj?.nationalNumber;

      this.searchApi.performSearchV2(req).subscribe(response => {
        const searchResultGroups = response.searchResultGroups?.filter(result => result.searchResultInstanceDtos.length && result.groupType === 'CUSTOMER');
        if (searchResultGroups?.length) {
          this.customerExist = true;
          this.customerId = searchResultGroups[0]?.searchResultInstanceDtos?.[0]?.id;
          this.loadCustomer();
        } else {
          this.customerExist = false;
          this.existingCustomer = undefined;
          this.selfRegister = true;
          if (this.inviteForm.controls['mobilePhoneNumber']) {
            this.inviteForm.controls['mobilePhoneNumber'].setErrors(null);
            this.inviteForm.controls['mobilePhoneNumber'].markAsDirty();
          }
        }
      });
    }
  }

  loadCustomer() {
    if (this.customerEmail) {
      this.customerCompositeService.checkUserOnboard(this.contextIdDto.contextId, this.contextIdDto.contextIdType,
        this.customerEmail).subscribe(res => {
          if (res?.customerId) {
            this.customerService.loadCustomer(res.customerId, this.contextIdDto.contextId, this.contextIdDto.contextIdType)
              .subscribe(res => {
                if (res) {
                  this.existingCustomer = res;
                  this.userId = { id: res.userId } as UserId;
                }
              });
          }
        });
    }
    if (this.customerId) {
      this.customerService.loadCustomer(this.customerId, this.contextIdDto.contextId, this.contextIdDto.contextIdType)
        .subscribe(res => {
          if (res) {
            this.existingCustomer = res;
            this.userId = { id: res.userId } as UserId;
          }
        });
    }
  }

  cancel() {
    this.dialogRef?.close();
  }

  reset() {
    this.customerEmail = '';
    this.firstName = '';
    this.lastName = '';
    this.phoneNumberObj = {
      country: 'GB',
      internationalNumber: '',
      nationalNumber: ''
    };
    this.customerExist = false;
    this.existingCustomer = undefined;
  }

  loadTemplate() {
    this.emailmessagetemplatesService.getEmailMessageTemplateByType(
      this.contextIdDto.contextId, this.contextIdDto.contextIdType, 'inviteToPortal')
      .subscribe(res => {
        if (res?.subject && res?.body) {
          this.emailSubjectText = res.subject;
          this.emailMessageText = res?.body;
          // if (res?.attachments) {
          //   this.savedFiles = res?.attachments;
          // }
          if (res?.links) {
            this.links = res.links;
          }
        }
      });
  }

  inviteToPortal() {
    if (this.existingCustomer?.customerId) {
      this.signupExisting();
    } else {
      // this.signupNew();
    }
  }

  signupExisting() {
    this.spinner.show();

    const req: InviteExistingCustomer = <InviteExistingCustomer>{};
    req.contextIdDto = this.contextIdDto;
    req.customerId = this.existingCustomer?.id;
    req.emailSubject = this.emailSubjectText;
    req.emailBody = this.emailMessageText;

    req.emailRecipient = <EmailRecipient>{};
    req.emailRecipient.address = this.customerEmail;
    req.emailRecipient.recipientId = this.customerId;
    req.emailRecipient.recipientType = EmailRecipient.RecipientTypeEnum.Customer;

    // req.attachments = this.getAttachments();
    // @ts-ignore
    req.links = this.links;

    this.customerPortalService.inviteExisting(req).subscribe(() => {
      this.notify.message = 'Invitation sent!';
      this.notify.open();
      // this.dialogRef?.close();
      this.spinner.hide();

    }, err => {
      this.notify.message = 'Error sending message ' + err.errorMessage;
      this.notify.open();
    });
  }

  isValid() {
    return this.customerEmail && this.firstName && this.lastName;
  }

  callback = () => {

  };

  createAppointment() {
    this.petService.loadCustomersPets(
      this.existingCustomer?.id).subscribe(response => {
        this.petList = response;
        this.appointmentHandlerService.createAppointment({
          contextIdDto: this.contextIdDto,
          petList: this.petList || [],
          callback: this.callback,
          calendarSettings: this.calendarSetupService.calendarSettings
        });
      });
  }

  toEmailRecipients(emailAddresses: string): Array<any> {
    const emailRecipients = new Array<any>();
    const recipientEmails = emailAddresses.split(',');
    recipientEmails.forEach(
      emailAddress => {
        emailRecipients.push(emailAddress.trim());
      }
    );
    return emailRecipients;
  }

  viewCustomer() {
    this.customerHandlerService.view(this.contextIdDto, this.existingCustomer?.id, this.callback);
  }

  editCustomer() {
    this.customerHandlerService.edit(this.contextIdDto, this.existingCustomer?.id, this.callback);
  }

  inviteForSelfReg() {
    this.spinner.show();
    const selfRegisterCustomer: SelfRegisterCustomer = {
      contextIdDto: this.contextIdDto,
      customerEmail: this.customerEmail,
      customerFirstName: this.firstName,
      customerLastName: this.lastName,
      customerPhoneNumber: this.phoneNumberObj?.nationalNumber ? this.phoneNumberObj : null
    };
    this.customerOnboardingService.inviteCustomerForSelfRegistration(selfRegisterCustomer).subscribe(res => {
      this.spinner.hide();
      if (res) {
        this.notify.message = 'Invitation sent!';
        this.notify.open();
        this.dialogRef?.close();

      }
    }, error => {
      this.spinner.hide();
    });
  }

  onMobilePhoneChange(value) {
    console.log('mobile changed');
    this.phoneNumberObj = value;
    this.checkUserNameExist();
  }

  onMobileCountryChange($event: any) {
    console.log('onMobileCountryChange', $event);
    this.phoneNumberObj.country = $event.iso2;
    this.checkUserNameExist();
  }


}
