<mat-card appearance="outlined">
  <mat-card-content fxLayout="column wrap">
    <div fxLayout="row wrap" fxLayoutGap="0px" class="border-top text-center" *ngIf="smsCreditSummaryDto">

      <div fxFlex="100" class="mdc-px-3 mdc-py-2 border-bottom text-left">
        <div fxLayout="row " fxLayoutAlign=" center">
          <div fxFlex="nogrow">
            <div class="avatar avatar-40 circle border-3">
              <mat-icon>sms</mat-icon>
            </div>
          </div>
          <div fxFlex="" class="pl-1">
            <p class="mb-0">{{'YOU_HAVE' | translate}} <b>{{ smsCreditSummaryDto.remainingSmsCreditsIncludingPaid }} {{'SMS' | translate}}</b> {{'CREDITS_REMAINING_DESC' | translate}}
            </p>
          </div>
        </div>
      </div>

      <div fxFlex="50" class="mdc-px-3 mdc-py-2 border-bottom border-right">
        <h4 class="mb-0">{{ smsCreditSummaryDto.purchasedCredits }}</h4>
        <p class="mb-0 mat-text-secondary small">{{'CREDITS_PURCHASED' | translate}}</p>
      </div>
      <div fxFlex="50" class="mdc-px-3 mdc-py-2 border-bottom ">
        <h4 class="mb-0">{{ smsCreditSummaryDto.smsCreditsUsedThisMonth }}</h4>
        <p class="mb-0 mat-text-secondary small">{{'CREDITS_USED_THIS_MONTH' | translate}}
          <mat-icon class="vm" matTooltip="{{'SMS_CREDITS_DESC' | translate}}">info</mat-icon>
        </p>
      </div>
      <div fxFlex="50" class="mdc-px-3 mdc-py-2 border-bottom border-right">
        <h4 class="mb-0">{{ smsCreditSummaryDto.numFreeCreditsOnPlanPerMonth }}</h4>
        <p class="mb-0 mat-text-secondary small">{{'FREE_CREDITS' | translate}}</p>
      </div>

      <div fxFlex="50" class="mdc-px-3 mdc-py-2 border-bottom">
        <h4 class="mb-0">{{ smsCreditSummaryDto.remainingSmsCreditsIncludingPaid }}</h4>
        <p class="mb-0 mat-text-secondary small">{{'CREDITS_LEFT' | translate}}</p>
      </div>
      <div fxFlex="100" class="mdc-px-3 mdc-py-2 border-bottom">
        <p class="mb-0 small" style="color:red"> <span class="mat-text-secondary mx-1" style="color:red">{{'NOTE' | translate}}:</span> {{'EACH' | translate}} <b>160</b> {{'CHAR_COUNTED_AS' | translate}} <b>1 {{'SMS' | translate}}</b>.</p>
      </div>
    </div>
    <br>
    <form [formGroup]="formGroup">
      <div fxLayout="column wrap">
        <section class="configuration-toggle-section">
          <mat-slide-toggle formControlName="allowSms">{{'SEND_SMS' | translate}}
          </mat-slide-toggle>
        </section>
        <div fxLayout="column">
          <label>{{'SMS_MESSAGE' | translate}}</label>
          <app-appointment-quick-links-editor class="w-100" #quickLinksEditorV2Component messageType="SMS" messagingMenu="SEND_MESSAGE">
          </app-appointment-quick-links-editor>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>