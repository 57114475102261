import { Component, Input, Output, OnDestroy, OnInit, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormArray, UntypedFormControl, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';

import * as uuid from 'uuid';

import { Discount, DiscountItem, DiscountSettings } from '@savvy/invoice';
import { ContextIdDto } from '@savvy/ui';
import { ContextService } from 'src/app/flo/context.service';
import { getCurrencySymbol } from '@angular/common';


@Component({
  selector: 'app-discount-item',
  templateUrl: './discount-item.component.html',
  styleUrls: ['./discount-item.component.scss']
})
export class DiscountItemComponent implements OnInit, OnDestroy {

  @Input() entityDefType: string;
  @Input() discountSettings: DiscountSettings;

  @Output() doSave: EventEmitter<DiscountItem[]> = new EventEmitter();

  form: UntypedFormGroup;
  discountItemsFA: FormArray;

  discountItemsByEiId: { [key: string]: DiscountItem } = {};
  discountItemsMapped: DiscountItem[] = [];

  subscriptions: Subscription[] = [];
  discountAmountTypeEnum: any = DiscountItem.DiscountItemTypeEnum;

  currencyCode: string;
  currencySymbol: string;

  private contextIdDto: ContextIdDto;

  constructor(private formBuilder: UntypedFormBuilder,
    private contextService: ContextService) {
  }

  ngOnInit(): void {
    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      this.currencyCode = sessionStorage.getItem('currencyCode') || '';
      if (this.currencyCode) {
        this.currencySymbol = getCurrencySymbol(this.currencyCode, 'wide');
      }
      if (!data || (this.contextIdDto && data.contextId === this.contextIdDto.contextId && data.contextIdType === this.contextIdDto.contextIdType)) {
        return;
      }
      this.contextIdDto = data;
      this.buildForm();
      // this.setDiscountItemsByEiId();
      this.loadGeneralItems();
    }));
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  buildForm(): void {
    this.discountItemsFA = this.formBuilder.array([]);
    this.form = this.formBuilder.group({
      discountItems: this.discountItemsFA
    });
  }

  setDiscountItemsByEiId() {
    if (!this.discountSettings) {
      return;
    }
    (this.discountSettings.discountItems || [])
      .filter(el => el.discountItemType == DiscountItem.DiscountItemTypeEnum.EntityDefinition)
      .forEach(el => this.discountItemsByEiId[el.entityInstanceId.id] = el);
  }

  loadGeneralItems() {
    (this.discountSettings.discountItems || [])
      .filter(el => el.discountItemType === DiscountItem.DiscountItemTypeEnum.General)
      .forEach(el => {
        this.addItemToForm(el);
      });
  }

  addItemToForm(discountItem: DiscountItem) {
    this.discountItemsFA.push(this.formBuilder.group({
      id: discountItem.id,
      code: new UntypedFormControl(discountItem.code),
      notes: new UntypedFormControl(discountItem.notes),
      paymentType: new UntypedFormControl(discountItem.discount.discountType),
      amount: new UntypedFormControl(discountItem.discount.amountDiscount),
      percentage: new UntypedFormControl(discountItem.discount.percentDiscount),
      active: new UntypedFormControl(discountItem.active, [])
    }));
  }

  onAddClick() {
    this.addItemToForm({
      id: uuid.v4(),
      discount: { discountType: Discount.DiscountTypeEnum.Percentage }
    });
  }

  onDeleteClick(ind: number) {
    this.discountItemsFA.removeAt(ind);
  }

  onSaveClick() {
    if (!this.doSave) {
      return;
    }
    const items: DiscountItem[] = this.form.value.discountItems.map(el => {
      const item: DiscountItem = <DiscountItem>{};
      item.id = el.id;
      item.discountItemType = DiscountItem.DiscountItemTypeEnum.General;
      item.autoApply = false;
      item.code = el.code;
      item.notes = el.notes;
      item.discount = {
        amountDiscount: el.amount,
        percentDiscount: el.percentage,
        discountType: el.paymentType
      };
      item.active = el.active;
      return item;
    });
    this.doSave.emit(items);
  }

}
