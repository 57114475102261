<div *ngIf="scheduleDef">

  <form fxLayout="column" fxLayoutAlign="space-around" #scheduleDefForm="ngForm">
    <div class="containerX">

      <div fxLayout="row" fxLayoutWrap="wrap" fxLayout.xs="column" fxLayout.sm="column" fxLayoutGap="10px" fxFlexFill
        class="coloredContainerX box">{{ title }}
      </div>
      <br>

      <div fxLayout="row" fxLayoutWrap="wrap" fxLayout.xs="column" fxLayout.sm="column" fxLayoutGap="10px" fxFlexFill
        class="coloredContainerX box" *ngIf="!manualTask">
        <mat-radio-group fxFlex name="workflowScheduleType" [(ngModel)]="scheduleDef.workflowScheduleType"
          #workflowScheduleType="ngModel">
          <mat-radio-button [value]="immediately">{{'IMMEDIATELY' | translate}}</mat-radio-button>&nbsp;&nbsp;
          <mat-radio-button [value]="relativeTo">{{'SCHEDULED' | translate}}</mat-radio-button>&nbsp;&nbsp;
        </mat-radio-group>
      </div>

      <div fxLayout="row" fxLayoutWrap="wrap" fxLayout.xs="column" fxLayout.sm="column" fxLayoutGap="10px" fxFlexFill
        class="coloredContainerX box" *ngIf="!manualTask">
        <mat-radio-group fxFlex name="workflowScheduleType" [(ngModel)]="scheduleDef.workflowScheduleType"
          #workflowScheduleType="ngModel">
          <mat-radio-button [value]="relativeTo">{{'YES' | translate}}</mat-radio-button>&nbsp;&nbsp;
          <mat-radio-button [value]="immediately">{{'NO' | translate}}</mat-radio-button>&nbsp;&nbsp;
        </mat-radio-group>
      </div>
      <div fxLayout="row" fxLayoutWrap="wrap" fxLayout.xs="column" fxLayout.sm="column" fxLayoutGap="10px" fxFlexFill
        class="coloredContainerX box" *ngIf="scheduleDef.workflowScheduleType == relativeTo">

        <mat-form-field appearance="outline">
          <input matInput name="period" placeholder="" type="number" #period="ngModel" id="period" (change)="update()"
            [(ngModel)]="scheduleDef.workflowScheduledTime.timePeriodValue.period">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-select placeholder="{{'SELECT_TIME_PERIOD' | translate}}" name="timePeriodType"
            (selectionChange)="update()" #timePeriodType="ngModel"
            [(ngModel)]="scheduleDef.workflowScheduledTime.timePeriodValue.timePeriodType">
            <mat-option value="{{timePeriodOption.timePeriodType}}" *ngFor="let timePeriodOption of timePeriodOptions">
              {{timePeriodOption.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-select placeholder="{{'SELECT_FIELD' | translate}}" name="scheduleType" #scheduleType="ngModel"
            (selectionChange)="update()" [(ngModel)]="selectedWhenToSendOption">

            <mat-option *ngFor="let whenToSendOption of whenToSendOptions" [value]="whenToSendOption">
              {{whenToSendOption.label | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>


    <mat-form-field appearance="outline" *ngIf="timeToExecuteTask">
      <mat-label>{{'Time to Send' | translate}}</mat-label>
      <input matInput placeholder="{{'Time to Send' | translate}}" [owlDateTimeTrigger]="dtPicker2"
        [owlDateTime]="dtPicker2" (ngModelChange)="updateTimeToSend($event)" name="timeToExecuteTaskVar"
        #timeToExecuteTaskVar="ngModel" [(ngModel)]="timeToExecuteTask">
      <owl-date-time [pickerType]="'timer'" #dtPicker2></owl-date-time>
    </mat-form-field>
  </form>
</div>