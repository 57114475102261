import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ChangeListener } from './changeListener';
import { TrialExpiredService } from '../shared/services/trial-expired.service';
import { PhoneNumberHelperService } from '../shared/services/phone-number-helper.service';
import { ClearObservable } from '../shared/classes/clear-observable';
import { takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ContextIdDto } from '@savvy/ui';
import {GalleryService} from '@savvy/gallery';
import {LoadGalleriesResponse} from '@savvy/gallery/model/models';
import {Gallery} from '@savvy/gallery/model/gallery';

@Component({
  selector: 'app-view-customer-galleries',
  templateUrl: 'viewCustomerGalleries.component.html'
})
export class ViewCustomerGalleriesComponent extends ClearObservable implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() customerId: string;
  @Input() form: UntypedFormGroup;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;

  galleries: Array<Gallery>;

  constructor(
    private router: Router,
    public trialExpiredService: TrialExpiredService,
    private sharedService: PhoneNumberHelperService,
    private galleryService: GalleryService) {
    super();
  }

  ngOnInit() {
    this.loadGalleriesLists();
  }


  create() {
    this.router.navigate(['/galleries/addGallery',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      this.customerId
    ]);
  }

  rowSelected(galleryId: string) {
    this.router.navigate(['/galleries/editGallery',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      galleryId
    ]);
  }

  loadGalleriesLists() {

      this.galleryService.loadCustomerGalleries(
        this.customerId,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe((response: LoadGalleriesResponse) => {
          this.galleries = response.galleries;
        }, (err: HttpErrorResponse) => {
          console.log('error while galleries', err);
        });
  }
}

