<mat-toolbar color="primary">
  <mat-toolbar-row>{{'ADD_PAYMENT' | translate}}</mat-toolbar-row>
</mat-toolbar>

<mat-card appearance="outlined" class="add-payment-container">
  <mat-card-content>
    <form #form="ngForm" *ngIf="model">
      <div fxLayout="row" fxLayoutGap="5px">
        <div fxFlex="50">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{'PAYMENT_DATE' | translate}}</mat-label>
            <input matInput [(ngModel)]="model.dateTime" name="paymentDate" [owlDateTime]="dtPicker2" [required]="true"
              #paymentDate="ngModel" id="paymentDate" placeholder="{{'ENTER_PAYMENT_DATE' | translate}}">
            <mat-icon class="open-icon" matSuffix [owlDateTimeTrigger]="dtPicker2">event</mat-icon>
            <owl-date-time [stepMinute]="'15'" #dtPicker2></owl-date-time>
          </mat-form-field>
        </div>
        <div fxFlex="50">
          <mat-form-field *ngIf="methods" appearance="outline" class="w-100">
            <mat-label>{{'PAYMENT_METHOD' | translate}}</mat-label>
            <mat-select [(ngModel)]="model.paymentMethod" name="paymentMethod" #paymentMethod="ngModel"
              id="paymentMethod" [required]="true" placeholder="{{'SELECT_PAYMENT_METHOD' | translate}}">
              <mat-option *ngFor="let method of methods" [value]="method">{{method}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="5px">
        <div fxFlex="50">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{'AMOUNT' | translate}}</mat-label>
            <input type="text" appNumberOnly [decimalPoints]="2" matInput [(ngModel)]="model.amount" name="amount"
              #amount="ngModel" id="amount" required placeholder="{{'ENTER_AMOUNT' | translate}}" style="width: 100%">
          </mat-form-field>
        </div>
        <div fxFlex="50">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{'REFERENCE' | translate}}</mat-label>
            <input matInput [(ngModel)]="model.paymentReference" name="paymentReference" #paymentReference="ngModel"
              id="paymentReference" placeholder="{{'PAYMENT_REFERENCE_AMOUNT' | translate}}" style="width: 100%">
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="5px">
        <div fxFlex="100">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{'DESCRIPTION' | translate}}</mat-label>
            <input type="text" matInput [(ngModel)]="model.description" name="description" #description="ngModel"
              id="description" placeholder="{{'DESCRIPTION' | translate}}" style="width: 100%">
          </mat-form-field>
        </div>
      </div>
      <ng-container *ngIf="tipsEnabled">
        <h6 *ngIf="model.staffTips && model.staffTips.length"><strong>Tips</strong></h6>
        <ng-container *ngFor="let staffTip of model.staffTips; let i = index">
          <div fxLayout="row" fxLayoutGap="5px">
            <div fxFlex="45">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{'AMOUNT' | translate}}</mat-label>
                <input type="text" appNumberOnly [decimalPoints]="2" matInput [(ngModel)]="staffTip.tipAmount" required
                  name="tipAmount{{i}}" #tipAmount{{i}}="ngModel" placeholder="{{'ENTER_AMOUNT' | translate}}">
              </mat-form-field>
            </div>
            <div fxFlex="45">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{'TIP_FOR' | translate}}</mat-label>
                <mat-select placeholder="{{'STAFF' | translate}}" [compareWith]="compareUserFn"
                  [required]="staffTip.tipAmount" name="Staff{{i}}" #Staff{{i}}="ngModel"
                  [(ngModel)]="staffTip.staffMember">
                  <mat-option *ngFor="let user of users" [value]="setUserOptVal(user)">{{user.firstName}}
                    {{user.lastName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxFlex="nogrow">
              <button mat-icon-button color="accent" (click)="removeTip(i)">
                <mat-icon class="vm">cancel</mat-icon>
              </button>
            </div>
          </div>
        </ng-container>
        <button mat-raised-button color="accent" (click)="addTip()">{{'ADD_TIP' | translate}}</button>
      </ng-container>
      <br>
      <div>
        <button class="mr-1" mat-raised-button (click)="dialogRef.close(false)">{{'CANCEL' | translate}}</button>
        <button *ngIf="data.invoice && !data.payment" mat-raised-button color="accent" (click)="savePayment()"
          [disabled]="!form.valid">{{'ADD' | translate}}
        </button>
        <button *ngIf="data.invoice && data.payment" mat-raised-button color="accent" (click)="savePayment()"
          [disabled]="!form.valid">{{'UPDATE' | translate}}
        </button>
      </div>

    </form>
  </mat-card-content>
</mat-card>