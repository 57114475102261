import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { EventBusService } from '../element/EventBusService';
import { EmailRecipientHelper } from './EmailRecipientHelper';
import { MessagecompService } from '@savvy/messaging';
import { EventType, UiEvent } from '../event/UiEvent';
import { MessageService, ContextIdDto, Message, MessageId } from '@savvy/messaging';

@Component({
  selector: 'app-view-message-panel',
  templateUrl: 'viewMessagePanel.component.html',
  styleUrls: ['./viewMessage.component.scss']
})
export class ViewMessagePanelComponent implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() visible: boolean;
  @Input() eventBus: EventBusService;


  message: Message;

  public form: UntypedFormGroup; // our form model

  constructor(
    private api: MessagecompService,
    private messageApi: MessageService,
    private emailRecipientHelper: EmailRecipientHelper) {

  }

  ngOnInit() {
    this.eventBus.eventAdded$.subscribe(event => this.onEvent(event));

  }

  private onEvent(event: UiEvent) {
    if (event.eventType === EventType.MESSAGE_SELECTED) {
      this.loadMessage(event.messageId);
    }
  }

  loadMessage(messageId: MessageId) {
    this.messageApi.getMessage(
      messageId.id,
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
    ).subscribe(response => {
      this.message = response;
    });
  }

  cancelIt() {
    this.eventBus.addEvent(EventType.CANCEL_VIEW_MESSAGE);
  }
}
