import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BoardingBooking, BoardingBookingService, ContextIdDto, CreateBoardingBookingInvoice, GenerateDraftBoardingBookingInvoice, Invoice } from '@savvy/pet-stay';

export class GenerateDraftInvoiceModalData {
  contextIdDto: ContextIdDto;
  currencyCode: string;
  bookingId: string;
}


@Component({
  selector: 'app-generate-draft-invoice-modal',
  templateUrl: './generate-draft-invoice-modal.component.html',
  styleUrls: ['./generate-draft-invoice-modal.component.scss']
})
export class GenerateDraftInvoiceModalComponent implements OnInit {
  invoice: Invoice;
  boardingBooking: BoardingBooking;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: GenerateDraftInvoiceModalData,
    public dialogRef: MatDialogRef<GenerateDraftInvoiceModalComponent>,
    private boardingBookingService: BoardingBookingService
  ) { }

  ngOnInit() {
    this.dialogRef.updateSize('800px', 'auto');
    this.generateDraftInvoice();
  }

  generateDraftInvoice() {
    const req: GenerateDraftBoardingBookingInvoice = {
      boardingBookingId: this.data.bookingId,
      contextIdDto: this.data.contextIdDto
    };
    this.boardingBookingService.generateDraftBoardingBookingInvoice(req).subscribe(res => {
      if (res?.invoice && res?.boardingBooking) {
        this.boardingBooking = res.boardingBooking;
        this.invoice = res.invoice;
      }
    });
  }

  generateInvoice() {
    const createBoardingBookingsInvoices: CreateBoardingBookingInvoice = {
      boardingBookingId: this.data.bookingId,
      contextIdDto: this.data.contextIdDto
    };
    this.boardingBookingService.createBoardingBookingInvoice(createBoardingBookingsInvoices).subscribe(res => {
      if (res?.boardingBooking) {
        console.log('Successful creation of id', res?.boardingBooking?.id);
        this.dialogRef.close(true);
      }
    });
  }

   close() {
    this.dialogRef.close();
  }


}
