<div [formGroup]="form" *ngIf="iconsFieldDefinition">
    <mat-form-field appearance="outline">
      <mat-label>{{iconsFieldDefinition.label | translate}}</mat-label>

      <mat-select
        placeholder="{{getPlaceholder() | translate}}"
        [id]="elementInstanceDto.instanceId"
        [required]="iconsFieldDefinition.required"
        [formControlName]="elementInstanceDto.instanceId" multiple>

        <mat-option *ngFor="let icon of iconsFieldDefinition.icons" [value]="icon">
          <mat-icon>{{icon}}</mat-icon>
        </mat-option>
      </mat-select>


      <mat-error *ngIf="form.controls[elementInstanceDto.instanceId].hasError('required')">
        {{iconsFieldDefinition.label + ' is' | translate}} <strong>{{'required' | translate}}</strong>
      </mat-error>
    </mat-form-field>
</div>
