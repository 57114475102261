import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContextIdDto } from '@savvy/view-definition';

@Component({
  selector: 'app-take-payment',
  templateUrl: 'takePayment.component.html'
})
export class TakePaymentComponent implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() bookingDef: any;


  @Output() paymentMade = new EventEmitter();

  constructor() {

  }

  ngOnInit(): void {
  }


  pay() {
    this.paymentMade.emit('ss');
  }
}
