import { Injectable } from '@angular/core';
import { Appointment, ContextIdDto } from '@savvy/appointment';
import {
  UserAvailabilityService,
  AppointmentClashCheck,
  ClashCheckResponse,
  LocalDateRange,
  Clash
} from '@savvy/user-availability';
import { ToastrService } from 'ngx-toastr';
import { DateUtils } from '../dates/DateUtils';
import { RequestedSlotLocalDateTime } from '@savvy/user-availability/model/requestedSlotLocalDateTime';
import { AppointmentConfigHelperService } from '../shared/services/appointment-config-helper.service';
import { AppointmentDateLocal } from './create-pet-appointment-v2/repeat-appointments/repeat-filter/repeat-filter.component';

@Injectable({
  providedIn: 'root'
})
export class ValidateAppointmentV2Service {

  constructor(
    private appointmentConfigHelperService: AppointmentConfigHelperService,
    private userAvailabilityService: UserAvailabilityService,
    private dateUtils: DateUtils,
    private toastrService: ToastrService
  ) { }

  checkConflicts(contextIdDto: ContextIdDto, appointment: Appointment, locationId: string) {
    return new Promise<Array<Clash>>((resolve) => {
      const requestedSlots: RequestedSlotLocalDateTime[] = [];
      for (const appointmentService of appointment.appointmentServiceRows) {

        const slot = {} as RequestedSlotLocalDateTime;
        slot.userId = appointmentService.serviceUser;
        slot.localDateRange = {} as LocalDateRange;
        slot.localDateRange.start = appointment.startDate;
        slot.localDateRange.startTime = appointmentService.startTime;
        slot.localDateRange.end = appointment.endDate;
        slot.localDateRange.endTime = appointmentService.endTime;
        requestedSlots.push(slot);
      }
      const clashCheckPreAppointmentCreation: AppointmentClashCheck = {
        contextIdDto,
        requestedSlots,
        appointmentId: { id: appointment?.id, type: '' },
        locationId
      };
      if (this.appointmentConfigHelperService.appointmentConfig.conflictCheckEnabled) {
        this.userAvailabilityService.clashCheckPreCreateAppointment(contextIdDto.contextId, contextIdDto.contextIdType,
          clashCheckPreAppointmentCreation).subscribe(res => {
            this.checkResponse(resolve, res);
          });
      } else {
        resolve([]);
      }
    });
  }

  checkResponse(resolve, res: ClashCheckResponse) {
    if (res && res.clashes && res.clashes.length > 0) {
      // this.toastrService.info(res.clashStr);
      resolve(res.clashes);
      return;
    }
    resolve([]);
  }

  checkConflictsWithReason(contextIdDto: ContextIdDto, appointment: Appointment, locationId: string) {
    return new Promise<ClashCheckResponse>((resolve) => {
      const requestedSlots: RequestedSlotLocalDateTime[] = [];
      for (const appointmentService of appointment.appointmentServiceRows) {

        const slot = <RequestedSlotLocalDateTime>{};
        slot.userId = appointmentService.serviceUser;
        slot.localDateRange = <LocalDateRange>{};
        slot.localDateRange.start = appointment.startDate;
        slot.localDateRange.startTime = appointmentService.startTime;
        slot.localDateRange.end = appointment.endDate;
        slot.localDateRange.endTime = appointmentService.endTime;
        requestedSlots.push(slot);
      }
      const clashCheckPreAppointmentCreation: AppointmentClashCheck = {
        contextIdDto,
        requestedSlots,
        appointmentId: { id: appointment?.id, type: '' },
        locationId
      };
      if (this.appointmentConfigHelperService.appointmentConfig.conflictCheckEnabled) {
        this.userAvailabilityService.clashCheckPreCreateAppointment(contextIdDto.contextId, contextIdDto.contextIdType,
          clashCheckPreAppointmentCreation).subscribe(res => {
            if (res && res.clashes && res.clashes.length > 0) {
              // this.toastrService.info(res.clashStr);
            }
            resolve(res);
            return;
          });
      } else {
        resolve(null);
      }
    });
  }

}
