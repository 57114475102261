import {NativeDateAdapter} from '@angular/material/core';
import * as moment from 'moment';
import {Injectable} from '@angular/core';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {

    deserialize(value) {
        if (typeof value === 'string') {
            if (!value) {
                return null;
            }
            if (!value.includes(':')) {
                return moment(value, 'YYYY-MM-DD').toDate();
            } else {
                return super.deserialize(value);
            }
        }
        return super.deserialize(value);
    }

}
