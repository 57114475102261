import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ContextIdDto} from '@savvy/view-definition';
import {EventBusService} from '../element/EventBusService';
import {EventType} from '../event/UiEvent';
import {Task} from '@savvy/task';

@Component({
  selector: 'app-tasks-panel',
  templateUrl: 'tasksPanel.component.html',
  styleUrls: ['tasksPanel.component.scss']
})
export class TasksPanelComponent {

  @Input() contextIdDto: ContextIdDto;
  @Input() eventBus: EventBusService;
  @Input() taskSetDtos: Array<Task>;
  @Output() taskSelected = new EventEmitter();
  @Output() taskDeselected = new EventEmitter();

  selectedTask: Task;

  taskSelectionChange(task: Task) {
    this.selectedTask = task;
    console.log('emitting taskSelected event');
    this.taskSelected.emit('taskSelected:' + task.id);
    this.eventBus.addTaskEvent(EventType.TASK_SELECTED, task.taskId);
  }
}
