import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChangeListener } from './changeListener';
import { EventBusService } from './EventBusService';
import { RemindersService } from '@savvy/reminders';
import { EventType, UiEvent } from '../event/UiEvent';
import { RemindersCompService } from '@savvy/reminders';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { ReminderService } from '@savvy/reminders';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '../shared/components/confirmation-modal/confirmation-modal.component';
import { ContextIdDto } from '@savvy/quickbooks';
import { Reminder } from '@savvy/reminders';
import { ViewRemindersDto } from '@savvy/view-definition';

@Component({
  selector: 'app-view-reminders-panel',
  templateUrl: 'viewRemindersPanel.component.html',
  styleUrls: ['viewRemindersPanel.component.scss', 'commonPanel.component.scss']
})
export class ViewRemindersPanelComponent implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() viewContext: any;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;
  @Input() eventBus: EventBusService;
  @Input() viewRemindersDto: ViewRemindersDto;


  sendingReminder = false;
  reminders: Array<Reminder>;

  constructor(
    private router: Router,
    private notify: FloSnackbarComponent,
    private remindersComp: RemindersCompService,
    private api: RemindersService,
    private reminderApi: ReminderService,
    private dialog: MatDialog) {

    if (this.viewRemindersDto) {
      // this.reminders = this.viewRemindersDto.reminders;
    }
  }

  ngOnInit(): void {
    this.eventBus.eventAdded$.subscribe(event => this.onEvent(event));
    this.loadReminders();
    this.changeListener.eventAdded$.subscribe(event => {
      this.loadReminders();
    });
  }



  sent(reminder: Reminder) {
    if (reminder && reminder.sendReminderResult) {
      return reminder.sendReminderResult.timeSent;
    }
    return null;
  }

  getSmsError(reminder: Reminder) {
    if (reminder && reminder.sendReminderResult) {
      return reminder.sendReminderResult.errorMessage;
    }
    return '';
  }

  getErrorText(reminder: Reminder) {
    if (reminder && reminder.sendReminderResult) {
      return reminder.sendReminderResult.errorMessage;
    }
    return 'No error';
  }

  inThePast(reminder: Reminder) {
    if (reminder.reminderState) {
      return reminder.reminderState === Reminder.ReminderStateEnum.ReminderInPast;
    }
    return false;
  }

  send(reminder: Reminder) {
    console.log('sending reminder ' + reminder.id);
     this.sendingReminder = true;
     this.remindersComp.sendReminderNow(
       reminder.id,
       this.contextIdDto.contextId,
       this.contextIdDto.contextIdType).subscribe(response => {
         console.log('Sent reminder');
         const sent = this.isSent(response.reminder);
         if (sent) {
           this.notify.message = 'Sent';
           this.notify.open();
         } else {
           if (response.reminder && response.reminder.sendReminderResult) {
             this.notify.message = 'Error Sending ' + response.reminder.sendReminderResult.errorMessage;
           } else {
             this.notify.message = 'Error Sending';
           }
           this.notify.open();
         }
         this.sendingReminder = false;

       this.loadReminders();
       }, error => {
        this.sendingReminder = false;
     });
  }

  isSent(reminder: Reminder) {
    if (reminder && reminder.sendReminderResult &&
      reminder.sendReminderResult.timeSent) {
      return true;
    }
    return false;
  }

  createReminder() {
    this.eventBus.addEvent(EventType.NEW_REMINDER);
  }

  loadReminders() {
    // if (this.viewContext.viewContextTypeDto === ViewContextDto.ViewContextTypeDtoEnum.VIEWENTITYINSTANCE) {
    this.api.loadReminders(
      this.viewContext.entityInstanceId.id,
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
    ).subscribe(response => {
      this.reminders = response;
    });
    /*} else if (this.viewContext.viewContextTypeDto === ViewContextDto.ViewContextTypeDtoEnum.CUSTOMER) {
      this.api.loadCustomerReminders(
        this.viewContext.customerContextDto.customerId.id,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType
      ).subscribe(response => {
        this.reminders = response;
      });
    }*/
  }

  reminderSelected(reminder: Reminder) {
    console.log('broadcast event REMINDER_SELECTED with id ' + reminder.reminderId + ' event bus is ' + this.eventBus.created);
    this.eventBus.addReminderEvent(EventType.REMINDER_SELECTED, reminder.reminderId);
  }

  onEvent(event: UiEvent): void {
    console.log('viewReminders received event ' + event);
    if (event.eventType === EventType.REMINDER_UPDATED) {
      this.loadReminders();
    }
  }

  getTooltip(reminder) {
    /**
     * return this.getMessageText(reminder) + '{{ reminder.id }} {{ reminder.reminderState }}'
     */
    return this.getMessageText(reminder) + ' id:' + reminder.id + ' state:' + reminder.reminderState;
  }

  getMessageText(reminder) {
    if (reminder.smsReminder) {
      if (reminder.smsReminder.sendReminderResult && reminder.smsReminder.sendReminderResult.sentMessage) {
        return reminder.smsReminder.sendReminderResult.sentMessage;
      }
      return reminder.smsReminder.message;
    }
    if (reminder.emailReminder) {
      if (reminder.emailReminder.sendReminderResult && reminder.emailReminder.sendReminderResult.sentMessage) {
        return reminder.emailReminder.sendReminderResult.sentMessage;
      }
      return reminder.emailReminder.subject;
    }
  }

  deleteReminder(reminder) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        name: 'reminder'
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
         this.remindersComp.deleteReminder(
           reminder.id, this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(res => {
             this.loadReminders();
           });
      }
    });
  }
}

