import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ChangeListener } from '../changeListener';
import { LookAndFeelConfig, LookAndFeelSharedService } from '../../shared/services/look-and-feel-shared.service';
import { DaateTimeFieldDefinitionDto, ElementInstanceDto, ContextIdDto } from '@savvy/view-definition';

@Component({
  selector: 'app-date-time-instance',
  templateUrl: 'dateTimeInstance.component.html'
})
export class DateTimeInstanceComponent implements OnInit {

  @Input() dateTimeDefinition: DaateTimeFieldDefinitionDto;
  @Input() elementInstanceDto: ElementInstanceDto;
  @Input() entityInstanceId: string;
  @Input() contextIdDto: ContextIdDto;
  @Input() form: UntypedFormGroup;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;

  isTimePicker = true;
  lookAndFeelConfig: LookAndFeelConfig;

  constructor(private lookAndFeelService: LookAndFeelSharedService) {
  }

  get isValid() {
    return this.form.controls[this.elementInstanceDto.instanceId].valid;
  }

  ngOnInit(): void {
    this.lookAndFeelConfig = this.lookAndFeelService.getLookAndFeelConfig(this.contextIdDto);
    if (this.lookAndFeelConfig) {
      this.isTimePicker = this.lookAndFeelConfig.isTimePicker;
    }
  }
}

