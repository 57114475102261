import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewCustomerDto } from '@savvy/view-definition';
import { ContextIdDto } from '@savvy/quickbooks';
import { PhoneNumberHelperService } from '../shared/services/phone-number-helper.service';
import { WindowRef } from '../windowWrapper';
import { OnboardCustomerMessagingModalComponent } from '../customer/onboard-customer-messaging-modal/onboard-customer-messaging-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ResetCustomerPasswordModalComponent } from '../customer/reset-customer-password-modal/reset-customer-password-modal.component';
import { PortalSettingsLocalService } from '../../services/portalSettingsLocal.service';
import { UrlLookupService } from '../shared/services/urlLookup.service';

@Component({
  selector: 'app-view-customer-panel',
  templateUrl: 'viewCustomerPanel.component.html',
  styleUrls: ['viewCustomerPanel.component.scss']
})
export class ViewCustomerPanelComponent implements OnInit {
  @Input() viewCustomer: ViewCustomerDto;
  @Input() contextIdDto: ContextIdDto;

  portalEnabled = false;
  constructor(
    private router: Router,
    private windowRef: WindowRef,
    private dialog: MatDialog,
    private portalSettingsLocalService: PortalSettingsLocalService,
    private urlService: UrlLookupService,
    private sharedService: PhoneNumberHelperService) { }

  ngOnInit() {
    this.portalEnabled = this.portalSettingsLocalService.portalEnabled;
  }

  editCustomer() {
    this.sharedService.viewEntityModalSub.next(true);
    this.router.navigate(['/customer/editCustomer',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      this.viewCustomer.customer.id]);
  }

  openGoogleMaps() {
    const url = 'https://www.google.com/maps/dir/?api=1&dir_action=navigate&travelmode=driving&destination=' +
      this.viewCustomer.customer.address.latitude + ',' + this.viewCustomer.customer.address.longitude;
    this.windowRef.getWindow().open(url, '_blank');
  }

  resetPassword() {
    this.dialog.open(ResetCustomerPasswordModalComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        customerId: this.viewCustomer.customer.customerId,
        userId: this.viewCustomer.userDto.userId,
        email: this.viewCustomer.userDto.username
      },
      panelClass: 'customers-messaging-dialog',
      autoFocus: true
    });
  }

  registerToPortal() {

    this.dialog.open(OnboardCustomerMessagingModalComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        customerId: this.viewCustomer.customer.customerId,
        userId: this.viewCustomer.userDto.userId,
        email: this.viewCustomer.userDto.username
      },
      panelClass: 'customers-messaging-dialog',
      autoFocus: true
    });
  }

  gotoPortal() {
    this.urlService.openNewTab(this.urlService.customerPortalUrl() + '?id=' + this.contextIdDto.contextId + this.urlService.getEnv());
  }

  viewCustomerPage() {
    this.sharedService.viewEntityModalSub.next(true);
    this.router.navigate(['/customer/viewCustomer',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      this.viewCustomer.customer.id]);
  }
}

