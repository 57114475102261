<mat-card appearance="outlined" class="mdc-mb-2" *ngIf="response && response.messagesList.length > 0">
  <mat-card-header class="mdc-mb-1">
    <div fxLayout="row" class="w-100">
      <div fxFlex>
        <h6>{{ response.messagesList.length}} {{'Messages' | translate}} </h6>
      </div>
      <div fxFlex="nogrow">
        <button mat-raised-button color="accent" [disabled]="trialExpiredService.isTrialExpired()" (click)="createMessage()">
          <mat-icon class="mat-24">add</mat-icon> Add
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-list>
    <mat-list-item *ngFor="let message of response.messagesList">
      <div fxLayout="row wrap" fxLayoutGap="16px" fxLayoutAlign=" center">
        <div fxFlex="nogrow">
          <mat-chip-listbox>
            <mat-chip-option *ngIf="sent(message)" color="primary" selected matTooltip="Sent {{ message.sentLocalDateTime | localizedDate:'medium'}} {{ getMessageText(message) }}">
              Sent
            </mat-chip-option>
            <mat-chip-option style="line-height: 14px;" *ngIf="!sent(message) && !received(message)" color="primary" selected>
              Not Sent {{ message.errorMessage }}
            </mat-chip-option>
            <mat-chip-option style="line-height: 14px;" *ngIf="!sent(message) && !received(message)" matTooltip="{{ getMessageText(message) }}" color="primary" selected>
              Received {{ message.errorMessage }}
            </mat-chip-option>
          </mat-chip-listbox>
        </div>
        <div fxFlex>
          <p *ngIf="message.emailMessage" (click)="messageSelected(message.messageId)">
            {{message.emailMessage.subject | translate}} </p>
          <p *ngIf="message.smsMessage" (click)="messageSelected(message.messageId)">
            {{message.smsMessage.textMessage | translate}} </p>
          <p *ngIf="message.whatsAppMessage" (click)="messageSelected(message.messageId)">
            {{message.whatsAppMessage.whatsAppMessage | translate}}</p>
        </div>
      </div>
      <mat-divider *ngIf="response.messagesList.length > 1"></mat-divider>
    </mat-list-item>
  </mat-list>
</mat-card>

<mat-card appearance="outlined" class="panel new-card" *ngIf="response && response.messagesList.length === 0">
  <mat-card-content>
    <div class="flexbox-parent">
      <div fxLayout="row" fxLayoutAlign="center center">
        <div style="text-align:center;">
          <app-zero-image [name]="'Messaging'"></app-zero-image><br>
          <h5 class="ma-0">{{"You don't have any Messages" | translate}}</h5><br>
          <button mat-raised-button color="accent" [disabled]="trialExpiredService.isTrialExpired()" (click)="createMessage()">{{'Send Message' | translate}}</button>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>