Select slot
<!--

<mat-card *ngIf="daySlot && daySlot.slotAndCheckDtos">

  <mat-toolbar>
    <mat-toolbar-row>Pick a Slot</mat-toolbar-row>
  </mat-toolbar>
  <br>

  <mat-card-content>
    <h2>{{daySlot.day | localizedDate:'mediumDate'}}</h2>
    <mat-chip-list *ngIf="!debug">
      <mat-chip color="primary" (click)="selectSlot(slotAndCheckDto.slotDto)"
                matTooltip="{{slotAndCheckDto.slotDto.availableUsersNames}}"
                *ngFor="let slotAndCheckDto of daySlot.slotAndCheckDtos | callback: isSlotAvailable">{{slotAndCheckDto.slotDto.startSlot  | localizedDate:'shortTime'}}
      </mat-chip>
    </mat-chip-list>

    <div *ngIf="debug && daySlot.slotAndCheckDtos">

      Number of Slots Available: {{ daySlot.slotAndCheckDtos.length }}
      <mat-card *ngFor="let slotAndCheckDto of daySlot.slotAndCheckDtos">
        <mat-card-title>{{slotAndCheckDto.slotDto.startSlot  | localizedDate:'shortTime'}}</mat-card-title>
        <mat-card-subtitle *ngIf="slotAndCheckDto.errorMessage">{{slotAndCheckDto.errorMessage}}</mat-card-subtitle>
        <mat-card-content *ngFor="let userFreeCheck of slotAndCheckDto.userFreeChecks">
          <div *ngIf="userFreeCheck.userFreeCheck.userFree">
            {{ userFreeCheck.username}} is available
          </div>

          <div *ngIf="!userFreeCheck.userFreeCheck.userFree">
            {{ userFreeCheck.username}} is not available
            <div *ngIf="userFreeCheck.userFreeCheck.clashingAppointment">
              Clashing appoiment {{ userFreeCheck.userFreeCheck.appointmentText }}
              starting at {{ userFreeCheck.userFreeCheck.clashingAppointment.start }}
              until {{ userFreeCheck.userFreeCheck.clashingAppointment.end }}
            </div>
          </div>
        </mat-card-content>
      </mat-card>

    </div>

  </mat-card-content>
</mat-card>

-->
