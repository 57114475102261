import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import {
  ContextIdDto, ViewCustomerCheckinFormsDefinition,
  ViewCustomerPlansDefinition
} from '@savvy/view-definition';
import { NavigateService } from '../entry/NavigateService';
import {TrialExpiredService} from '../shared/services/trial-expired.service';
import {ViewContextDto} from '@savvy/view-composite';

@Component({
  selector: 'app-customer-check-in-forms',
  templateUrl: 'customerCheckinForms.component.html'
})
export class CustomerCheckinFormsComponent {

  @Input() viewCustomerCheckinFormsDefinition: ViewCustomerCheckinFormsDefinition;
  @Input() viewContext: ViewContextDto;
  @Input() contextIdDto: ContextIdDto;

  checkInForms: Array<any>;

  constructor(private router: Router, private nav: NavigateService,
              public trialExpiredService: TrialExpiredService) {
  }

  create() {

  }

  formSelected(plan: any) {

  }
}

