import { Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';

import { ViewCustomerComponent } from './viewCustomer.component';
import { AddCustomerComponent } from './customer/addCustomer.component';
import { ExportCustomersComponent } from './exportCustomers.component';
import { SendToAllMessagesComponent } from '../send-to-all/send-to-all-messages/send-to-all-messages.component';
import { IndividualMessagesComponent } from '../send-to-all/individual-messages/individual-messages.component';
import { CustomersV2Component } from './customersV2.component';
import { ViewCustomerV2Component } from './view-customer-v2/view-customer-v2.component';

export const customerRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'customers/:contextId/:contextIdType', component: CustomersV2Component, canActivate: [AuthGuard],
        data: {
          breadcrumb: 'List Customers',
          topLevel: 'true'
        }
      },
      {
        path: 'customers/:contextId/:contextIdType/:onlyAwaitingForm', component: CustomersV2Component, canActivate: [AuthGuard],
        data: {
          breadcrumb: 'List Customers',
          topLevel: 'true'
        }
      },
      {
        path: 'exportCustomers/:contextId/:contextIdType', component: ExportCustomersComponent, canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Export Customers',
          topLevel: 'true'
        }
      },
      {
        path: 'addCustomer/:contextId/:contextIdType', component: AddCustomerComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Add Customer',
          pageType: 'addEditCustomer'
        }
      },
      {
        path: 'editCustomer/:contextId/:contextIdType/:customerId', component: AddCustomerComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Edit Customer',
          pageType: 'addEditCustomer'
        }
      },
      {
        path: 'viewCustomer/:contextId/:contextIdType/:customerId', component: ViewCustomerV2Component, canActivate: [AuthGuard],
        data: {
          breadcrumb: 'View Customer'
        }
      },
      {
        path: 'viewCustomerV1/:contextId/:contextIdType/:customerId', component: ViewCustomerComponent, canActivate: [AuthGuard],
        data: {
          breadcrumb: 'View Customer'
        }
      },
      {
        path: 'customerMessageRequests/:contextId/:contextIdType/:recipientType', component: SendToAllMessagesComponent, canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Customer Messages'
        }
      },
      {
        path: 'customerMessage/:contextId/:contextIdType/:messageId', component: IndividualMessagesComponent, canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Customer Message Details'
        }
      }
    ]
  }
];
