import {Injectable} from '@angular/core';
import {ICountry} from './country';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';


@Injectable()
export class CountryService {
  countries: ICountry[];
  constructor(private http: HttpClient) {

    this.getJSON().subscribe(data => {
      console.log(data);
      this.countries = data.data;
      console.log('countries size is ' + this.countries.length);

      this.countries.forEach(country => {
        console.log('code ' + country.code);
      });
    });
  }
  public getJSON(): Observable<any> {
    return this.http.get('./assets/countries.json');
  }
  public getCountries(): ICountry[] {
    return this.countries;
  }
}
