<div *ngIf="showSpinner" class="bg-spinner-wrapper">
  <mat-spinner style="margin: 0 auto;"></mat-spinner>
</div>
<div *ngIf="visible">
  <mat-toolbar color="primary">
    <mat-toolbar-row>{{'Request Deposit' | translate}}</mat-toolbar-row>
  </mat-toolbar>

  <mat-card appearance="outlined">
    <mat-card-content>
      <form #msgForm="ngForm">

        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>{{'To' | translate}}</mat-label>
          <input type="text" matInput name="recipient" #recipient="ngModel" [(ngModel)]="recipients" placeholder="{{'To' | translate}}"
                 required disabled>
          <mat-error *ngIf="recipient.hasError('required')">
            {{'To is' | translate}} <strong>{{'required' | translate}}</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>{{'Subject' | translate}}</mat-label>
          <input matInput
                 placeholder="{{'Subject' | translate}}"
                 name="subjectField"
                 #subjectField="ngModel"
                 [(ngModel)]="subject"
                 id="subject" required>
          <mat-error *ngIf="subjectField.hasError('required')">
            {{'Subject is' | translate}} <strong>{{'required' | translate}}</strong>
          </mat-error>
        </mat-form-field>
        <br>


        <mat-button-toggle-group (change)="setAmountRequired($event.value)" [value]="amountRequired" name="percentAmount">
          <mat-button-toggle value="fixedAmount">Fixed Amount</mat-button-toggle>
          <mat-button-toggle value="5">5%</mat-button-toggle>
          <mat-button-toggle value="10">10%</mat-button-toggle>
          <mat-button-toggle value="20">20%</mat-button-toggle>
          <mat-button-toggle value="25">25%</mat-button-toggle>
          <mat-button-toggle value="50">50%</mat-button-toggle>
          <mat-button-toggle value="100">100%</mat-button-toggle>
        </mat-button-toggle-group>
        <br><br>

        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>{{'Payment Amount' | translate}}</mat-label>
          <input matInput
                 number
                 placeholder="{{'Amount' | translate}}"
                 name="amountField"
                 #amountField="ngModel"
                 [(ngModel)]="amountInPounds"
                 id="amount" required>
          <mat-error *ngIf="amountField.hasError('required')">
            {{'Amount is' | translate}} <strong>{{'required' | translate}}</strong>
          </mat-error>
        </mat-form-field>
        <br>


        <app-flo-editor [text]="body" [required]="true" (textUpdated)="bodyUpdated($event)"></app-flo-editor>
        <br><br/>
      </form>

    </mat-card-content>
    <hr>
    <mat-card-content>
      <button mat-raised-button color="accent" (click)="send()" [disabled]="!msgForm.valid || !allowedToSend">{{'Send' | translate}}</button>
      &nbsp;
      <button mat-raised-button (click)="cancelIt()">{{'CANCEL' | translate}}</button>
      <div *ngIf="!allowedToSend">
        <mat-error>{{'You can\'t send deposit request, because it\'s not allowed to send Email message to this customer' | translate}}.</mat-error>
      </div>
    </mat-card-content>
  </mat-card>


</div>
