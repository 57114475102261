<mat-card appearance="outlined" *ngIf="sectionDefinitionDto">
    <mat-card-title>{{sectionDefinitionDto.label | translate}}</mat-card-title>
    <hr>
    <mat-card-content>

        <app-element *ngFor="let elementDto of sectionDefinitionDto.sectionElements"
                     [elementDto]="elementDto"
                     [form]="form"
                     [contextIdDto]="contextIdDto"
                     [eventBus]="eventBus"
                     [viewContext]="viewContext"
                     [additionalDataMapDto]="additionalDataMapDto"
                     [changeListener]="changeListener"

        >

        </app-element>
    </mat-card-content>

</mat-card>
