<mat-card appearance="outlined" class="panel new-card" *ngIf="listEntityListDto && listEntityListDto.rows.length > 0">
  <mat-card-title>
    <div fxLayout="row">
      <p class="mt-xs mb-xs">{{listEntityListDto.name | translate}}</p>
    </div>
  </mat-card-title>
  <mat-card-content>
    <!--page size is [{{ page.size }}]
      <br>
      page totalElements is [{{ page.totalElements }}]
      <br>
      page pageNumber is [{{ page.pageNumber }}]

      <br>
      -->
    <ngx-datatable *ngIf="listEntityListDto && listEntityListDto.rows && listEntityListDto.rows.length > 0 && showTable()" class='no-shadow material has-plus-btn' [style.border]="0" [rows]='listEntityListDto.rows' [columns]="columns" [columnMode]="'force'" [headerHeight]="0" [rowHeight]="'auto'" [footerHeight]="50" [selected]="selected" [selectionType]="'single'" [externalPaging]="true" [count]="page.totalElements" [offset]="page.pageNumber" [limit]="page.size" (select)="onSelect($event)"
      (page)='setPage($event)'>
    </ngx-datatable>

    <mat-list *ngIf="listEntityListDto && listEntityListDto.rows && !showTable()">
      <mat-list-item *ngFor="let row of listEntityListDto.rows">
        <p matLine (click)="rowSelected(row.entityInstanceId)" *ngIf="!row.displayText || row.displayText.length === 0">{{listEntityListDto.name | translate}}</p>
        <p matLine (click)="rowSelected(row.entityInstanceId)" *ngIf="row.displayText && row.displayText.length > 0">{{row.displayText | translate}}</p>
        <mat-divider *ngIf="listEntityListDto.rows.length > 1"></mat-divider>
      </mat-list-item>
    </mat-list>

    <br>

    <div fxLayout="row">
      <div fxFlex="90">
      </div>
      <div fxFlex="10">
        <button mat-mini-fab color="accent" [disabled]="trialExpiredService.isTrialExpired()" (click)="create()">
          <mat-icon class="mat-24">add</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<mat-card appearance="outlined" class="panel new-card" *ngIf="listEntityListDto && listEntityListDto.rows.length === 0">
  <mat-card-content>
    <div class="flexbox-parent">
      <div fxLayout="row" fxLayoutAlign="center center">
        <div style="text-align:center;">
          <app-zero-image [name]="listEntityListDto.name"></app-zero-image><br>
          <h5 class="ma-0">{{'You don’t have any ' + listEntityListDto.name | translate}}</h5><br>
          <!-- *ngIf="listEntityListDto.canCreate" -->
          <button mat-raised-button color="accent" [disabled]="trialExpiredService.isTrialExpired()" (click)="create()">{{'New' | translate}} {{listEntityListDto.name | translate}}</button>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>