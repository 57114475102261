// import { Component, Inject, OnInit, Optional } from '@angular/core';
// import { QuillConfig } from 'ngx-quill';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  Appointment,
  AppointmentPaymentConfig,
  PetServicesRequired,
} from '@savvy/appointment';
import { ListEntityListItemDto } from '@savvy/cached-view/model/listEntityListItemDto';
import { LinkId } from '@savvy/entity-instance';
import { ContextIdDto } from '@savvy/entity-instance-composite';
import { Pet, PetService } from '@savvy/pet';
import { StepPref, WizardPrefs, WizardPrefsService } from '@savvy/wizard-prefs';
import {
  SendMessageDefinition,
  SendSmsDefinition,
  WorkflowDefinitionId,
  WorkflowdefService,
} from '@savvy/workflow-definition';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { DateUtils } from '../../dates/DateUtils';
import { PetServiceSelectorComponent } from '../../element/create-pet-appointment/petServiceSelector.component';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';

import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { DataShareService } from 'src/app/core/data-share.service';
import { AppointmentConfigHelperService } from '../../shared/services/appointment-config-helper.service';
import { CalendarSettings } from '@savvy/calendar-settings';
import { Customer } from '@savvy/customer';
import { Location } from '@savvy/location';
import {
  BoardingBooking,
  BoardingBookingService,
  CreateBoardingBooking,
  PetRunBooking,
  PetRunDetails,
  PetStayPaymentConfig,
  PetStayPreference,
  UpdateBoardingBooking,
} from '@savvy/pet-stay';
import { PlansCompService, SubscribeToPlan } from '@savvy/plan';
import { Clash } from '@savvy/user-availability';
import { UsersCalendarSettings } from '@savvy/user-calendar-settings';
import { STEP_STATE } from 'ng-wizard-v2';
import { QuillConfig } from 'ngx-quill';
import { PetSelectorV2Component } from '../../appointments-v2/create-pet-appointment-v2/pet-selector-v2/pet-selector-v2.component';
import { AppointmentDateLocal } from '../../appointments-v2/create-pet-appointment-v2/repeat-appointments/repeat-filter/repeat-filter.component';
import { AppointmentV2IconsComponent } from '../../appointments-v2/shared/appointment-v2-icons/appointment-v2-icons.component';
import { AddCustomerComponent } from '../../customer/customer/addCustomer.component';
import { LocationsHelperService } from '../../shared/services/locations-helper.service';
import { OrgUsersHelperService } from '../../shared/services/org-users-helper.service';
import { ServicesHelperService } from '../../shared/services/services-helper.service';
import { SmsCreditService } from '../../shared/services/sms-credit.service';
import { BoardingPaymentComponent } from './boarding-payment/boarding-payment.component';
import { CreateBoardingBookingV2SyncService } from './create-boarding-booking-v2-sync.service';
import { DayCareDateSelectorComponent } from './day-care-date-selector/day-care-date-selector.component';
import { PetRunSelectorComponent } from './pet-run-selector/pet-run-selector.component';
import { IdNameTupleDto } from '@savvy/user';

export interface CreateBoardingBookingData {
  contextIdDto: ContextIdDto;
  edit: boolean;
  boardingBooking?: BoardingBooking;
  rebook?: boolean;
  petRunId?: string;
  startDateToUseOnCreate?: Date;
  end?: Date;
  bookingType?: BoardingBooking.BookingTypeEnum;
  userToUseWhenCreating?: IdNameTupleDto;
  startTime?: string | undefined;
  endTime?: string | undefined;
  locationId?: string;
  calendarSettings: CalendarSettings;
  usersCalendarSettings: UsersCalendarSettings;
  petStayPreference: PetStayPreference;
}

@Component({
  selector: 'app-create-boarding-booking-v2',
  templateUrl: './create-boarding-booking-v2.component.html',
  styleUrls: ['./create-boarding-booking-v2.component.scss'],
})
export class CreateBoardingBookingV2Component implements OnInit, OnDestroy {
  @ViewChild(PetServiceSelectorComponent, { static: false })
  petRunSelectorComponent: PetRunSelectorComponent;
  @ViewChild(AppointmentV2IconsComponent, { static: false })
  appointmentV2IconsComponent: AppointmentV2IconsComponent;
  @ViewChild(BoardingPaymentComponent, { static: false })
  paymentComponent: BoardingPaymentComponent;
  @ViewChild(PetSelectorV2Component, { static: false })
  petSelectorV2Component: PetSelectorV2Component;
  @ViewChild(DayCareDateSelectorComponent, { static: false })
  dayCareDateSelectorComponent: DayCareDateSelectorComponent;

  editor: QuillConfig;
  selectedPets = [];
  contextIdDto: ContextIdDto;

  collectionTime = '';
  messageBody = '';
  emailBody = '';
  links: any[] = [];

  stepStates = {
    normal: STEP_STATE.normal,
    disabled: STEP_STATE.disabled,
    error: STEP_STATE.error,
    hidden: STEP_STATE.hidden,
  };

  isValidTypeBoolean = true;
  petEdId = '';

  createPet = false;
  customerId: string;

  serviceNumber;

  creating = false;

  lastStep = '';

  wizardPrefs: WizardPrefs;

  locationFreeText = '';
  notes = '';
  blades = '';
  enquiryDate: Date = new Date();

  wizardSteps: StepPref[] = [];

  advancedStepVisible = false;
  smsWorkflowDef: SendSmsDefinition;
  smsMessageName = '';
  emailWorkflowDef: SendMessageDefinition;
  emailMessageName = '';
  defaultMessage = 'You need to setup a Confirm message';

  totalAppointmentDuration = 60;
  subs: Subscription[] = [];

  boardingBooking: BoardingBooking = {
    // start: '',
    // end: '',
    paymentConfig: {} as PetStayPaymentConfig,
  } as BoardingBooking;

  selectedTabIndex = 0;

  showConfirmationDialog = false;
  checkConflictsDialog = false;
  clashes: Clash[] = [];
  bypassConflictCheck = false;
  operationToPerform = '';
  createAnywayApplied = false;
  isRepeatAvailable = false;
  createNewPlan = false;

  paymentModeEnum = AppointmentPaymentConfig.PaymentModeEnum;

  selectedDay = 'SUNDAY';
  workflowDefId: WorkflowDefinitionId;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: CreateBoardingBookingData,
    @Optional()
    public dialogRef: MatDialogRef<CreateBoardingBookingV2Component>,
    private dialog: MatDialog,
    private wizardPrefsService: WizardPrefsService,
    private boardingBookingService: BoardingBookingService,
    private notify: FloSnackbarComponent,
    private spinner: NgxSpinnerService,
    private dateUtils: DateUtils,
    public dataShareService: DataShareService,
    public createBoardingBookingV2SyncService: CreateBoardingBookingV2SyncService,
    public appointmentConfigHelperService: AppointmentConfigHelperService,
    public deviceService: DeviceDetectorService,
    public planCompApi: PlansCompService,
    public servicesHelperService: ServicesHelperService,
    private smsCreditService: SmsCreditService,
    private petService: PetService,
    private workflowdefService: WorkflowdefService,
    private locationsHelperService: LocationsHelperService,
    public orgUsersHelperService: OrgUsersHelperService
  ) {
    this.contextIdDto = this.data.contextIdDto;
  }

  loadWorkflowDefForBoarding() {
    this.workflowdefService
      .loadWorkflowMessageDefsByType(this.contextIdDto.contextId, 'BOARDING')
      .subscribe((res) => {
        if (res?.workflowDefinitionId) {
          this.workflowDefId = res.workflowDefinitionId;
        }
      });
  }

  loadPreferences() {
    this.createBoardingBookingV2SyncService.petStayPreference =
      this.data.petStayPreference;
  }

  ngOnDestroy(): void {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }

  setTimes(startDate) {
    if (
      this.createBoardingBookingV2SyncService.timeArray.length &&
      this.data.startTime
    ) {
      const startTime = this.data.startTime;
      const endTime = this.data.endTime;
      const endDate = this.dateUtils.addDays(startDate, 1);
      this.createBoardingBookingV2SyncService.setAppointmentDates(
        startDate,
        endDate,
        startTime,
        endTime
      );
    }
  }

  ngOnInit(): void {
    this.loadPreferences();
    this.loadWorkflowDefForBoarding();
    this.createBoardingBookingV2SyncService.bookingType = this.data.bookingType;
    this.dialogRef.updateSize('1100px', 'auto');

    console.log('INSIDE INIT!');
    this.createBoardingBookingV2SyncService.setTimeArray(this.contextIdDto);

    if (this.data.edit || this.data.rebook) {
      console.log('EDIT OR REBOOK');
      // Handle edit flow
      if (this.data.boardingBooking) {
        this.boardingBooking = this.data.boardingBooking;
        this.createBoardingBookingV2SyncService.bookingType =
          this.boardingBooking.bookingType;
        this.notes = this.boardingBooking?.notes || '';
        if (this.boardingBooking?.enquiryDate) {
          this.enquiryDate = new Date(this.boardingBooking.enquiryDate);
        } else {
          this.enquiryDate = new Date(this.boardingBooking.createdDate);
        }
      }
    } else {
      console.log('HERE');
      let startDate = new Date();
      if (this.data.startDateToUseOnCreate) {
        startDate = this.data.startDateToUseOnCreate
          ? new Date(this.data.startDateToUseOnCreate)
          : new Date();
      }
      this.selectedDay = moment(startDate).format('dddd').toUpperCase();
      this.setTimes(startDate);
      if (this.data.petRunId) {
        this.createBoardingBookingV2SyncService.runToUseWhenCreating =
          this.data.petRunId;
      }
      if (this.data.locationId) {
        this.boardingBooking.locationId = this.data.locationId;
      }
    }

    this.subs.push(
      this.createBoardingBookingV2SyncService.createPet$.subscribe((result) => {
        if (result) {
          this.createPet = true;
        }
      })
    );
  }

  addNewCustomerEvent($event) {
    this.createPet = false;
    this.createBoardingBookingV2SyncService.bookingType =
      BoardingBooking.BookingTypeEnum.Boarding;
  }

  loadWizard() {
    this.wizardPrefsService.get('createAppointment').subscribe((response) => {
      this.wizardSteps = response.stepPrefs.filter((step) => !step.hide);
      const ackIndex = this.wizardSteps.findIndex(
        (step) => step.stepName === 'Acknowledgement'
      );
      if (ackIndex > -1) {
        this.wizardSteps.splice(ackIndex, 1);
      }
    });
  }

  locationSelected($event) {}

  createStepPref(stepName: string) {
    const stepPref = {} as StepPref;
    stepPref.stepName = stepName;
    stepPref.hide = true;
    return stepPref;
  }

  getMinTime(requiredServices: PetServicesRequired[]) {
    let min = null;
    for (const requiredService of requiredServices) {
      for (const iterator of requiredService.petServicesRequired) {
        if (!min || iterator.start < min) {
          min = iterator.start;
        }
      }
    }
    return min;
  }

  getMaxTime(requiredServices: PetServicesRequired[]) {
    let max = null;
    for (const requiredService of requiredServices) {
      for (const iterator of requiredService.petServicesRequired) {
        if (!max || iterator.end > max) {
          max = iterator.end;
        }
      }
    }
    return max;
  }

  validateRequest() {
    // if (!this.createBoardingBookingV2SyncService.selectedRuns.length
    // || !this.createBoardingBookingV2SyncService.selectedRuns?.[0]?.selectedPetRun) {
    //   this.notify.message = 'Please select a run';
    //   this.notify.open();
    //   return false;
    // }
    for (const selectedRun of this.createBoardingBookingV2SyncService
      .selectedRuns) {
      if (
        !selectedRun.selectedPetRun ||
        selectedRun.selectedPetRun.deleted ||
        !Object.keys(selectedRun.selectedPetRun).length
      ) {
        this.notify.message = `Pet run should be selected`;
        this.notify.open();
        return false;
      }

      if (!selectedRun.selectedPets || !selectedRun.selectedPets.length) {
        this.notify.message = 'Please select a pet';
        this.notify.open();
        return false;
      }
      const petSelectedInRun = selectedRun.selectedPets.filter(
        (sp) => sp.petId
      );
      if (!petSelectedInRun.length) {
        this.notify.message = 'Please select a pet';
        this.notify.open();
        return false;
      }
      // if (!selectedRun.selectedStaff || !Object.keys(selectedRun.selectedStaff).length) {
      //   this.notify.message = `A staff member should be allocated to a service`;
      //   this.notify.open();
      //   return false;
      // }
      if (!selectedRun.grossPrice) {
        selectedRun.grossPrice = 0;
      }
      // if (!selectedRun.startTime) {
      //   this.notify.message = 'Start time is required';
      //   this.notify.open();
      //   return false;
      // }
      // if (!selectedRun.endTime) {
      //   this.notify.message = 'End time is required';
      //   this.notify.open();
      //   return false;
      // }
      // if (selectedRun.endTime < selectedRun.startTime) {
      //   this.notify.message = 'End time should be greater than start time';
      //   this.notify.open();
      //   return false;
      // }
    }
    if (
      !this.createBoardingBookingV2SyncService.startDateReq ||
      !this.createBoardingBookingV2SyncService.startTime ||
      !this.createBoardingBookingV2SyncService.endTime
    ) {
      this.notify.message = 'Invalid date/time selection';
      this.notify.open();
      return false;
    }
    // if (this.pickupServiceComponent && this.pickupServiceComponent.pickupRequired) {
    //   if (!this.pickupServiceComponent.collectionTime) {
    //     this.notify.message = 'Collection time is required';
    //     this.notify.open();
    //     return false;
    //   }
    //   // if (!this.pickupServiceComponent.pickupAddress) {
    //   //   this.notify.message = 'Pickup address is required';
    //   //   this.notify.open();
    //   //   return false;
    //   // }
    // }
    return true;
  }

  prepareCreateRequestPayload(
    confirm = false,
    appointmentDate: AppointmentDateLocal,
    planId?: any
  ): CreateBoardingBooking {
    let customerId = '';
    let customerName = '';

    if (this.createBoardingBookingV2SyncService.customerId) {
      customerId = this.createBoardingBookingV2SyncService.customerId;
      customerName = this.createBoardingBookingV2SyncService.customerName;
    }
    if (!customerId) {
      this.notify.message = 'No pet owner id specified for this pet';
      this.notify.open();
      return;
    }
    const createComp = {} as CreateBoardingBooking;
    createComp.contextIdDto = this.contextIdDto;
    createComp.confirm = confirm;
    if (confirm) {
      this.operationToPerform = 'createAndConfirm';
    } else {
      this.operationToPerform = 'create';
    }
    let selectedLocation;
    if (
      this.createBoardingBookingV2SyncService.selectedLocation &&
      this.createBoardingBookingV2SyncService.selectedLocation.id &&
      this.createBoardingBookingV2SyncService.selectedLocation.id !== '-1'
    ) {
      selectedLocation =
        this.createBoardingBookingV2SyncService.selectedLocation;
    } else {
      selectedLocation = this.locationsHelperService.nonDeletedLocations[0];
    }

    console.log('selectedLocation');
    console.log(this.createBoardingBookingV2SyncService.selectedLocation);
    console.log(selectedLocation);
    debugger;

    const boardingBookingRows: PetRunBooking[] = [];
    let startDateReq = this.createBoardingBookingV2SyncService.startDateReq;
    let endDateReq = this.createBoardingBookingV2SyncService.endDateReq;
    if (appointmentDate) {
      startDateReq = this.dateUtils.getDateAsStringDash(
        appointmentDate?.localStartDate
      );
      endDateReq = this.dateUtils.getDateAsStringDash(
        appointmentDate?.localStartDate
      );
    }
    for (const selectedRun of this.createBoardingBookingV2SyncService
      .selectedRuns) {
      const petIds: string[] = [];
      const petRunDetails: PetRunDetails[] = [];
      for (const selectedPet of selectedRun.selectedPets) {
        if (selectedPet?.petId) {
          petIds.push(selectedPet.petId);
          if (selectedPet?.petServiceBookings?.length) {
            for (const petServiceBooking of selectedPet?.petServiceBookings) {
              petServiceBooking.startDate = this.dateUtils.getDateAsStringDash(
                petServiceBooking?.startDate
              );
              petServiceBooking.endDate = this.dateUtils.getDateAsStringDash(
                petServiceBooking?.endDate
              );
              petServiceBooking.petId = selectedPet.petId;
              petServiceBooking.petName = selectedPet.petName;
              petServiceBooking.petBreed = selectedPet.petBreed;
            }
          }
          petRunDetails.push({
            petId: selectedPet.petId,
            petBreed: selectedPet.petBreed,
            petName: selectedPet.petName,
            customerId: selectedPet.petOwnerId,
            customerName: selectedPet.customerName,
            petServiceBookings: selectedPet?.petServiceBookings,
            petFeedingConfig: selectedPet?.petFeedingConfig,
            petMedicationConfig: selectedPet?.petMedicationConfig,
          });
        }
      }

      let unitPrice = selectedRun?.selectedPetRun?.price;
      if (
        selectedRun?.selectedPetRun?.maxPets > 1 &&
        selectedRun.selectedPets.filter((sp) => sp.petId).length > 1
      ) {
        unitPrice = selectedRun?.selectedPetRun?.multiPetPrice || 0;
      }
      if (
        this.createBoardingBookingV2SyncService.bookingType === 'DAY_CARE' &&
        this.createBoardingBookingV2SyncService.petStayPreference?.bookHourly
      ) {
        unitPrice = selectedRun?.selectedPetRun?.hourlyPrice || 0;
      }

      boardingBookingRows.push({
        petRunId: selectedRun?.selectedPetRun?.id,
        petRunName: selectedRun?.selectedPetRun?.name,
        unitPrice,
        tax: selectedRun?.selectedPetRun?.tax,
        subTotal: selectedRun.subTotal,
        grossPrice: selectedRun.grossPrice,
        quantity: selectedRun.quantity,
        discountDetails: selectedRun.discount,
        petIds,
        petRunDetails,
      });
    }

    const startTime = this.createBoardingBookingV2SyncService.startTime;
    const endTime = this.createBoardingBookingV2SyncService.endTime;

    const paymentConfig = this.paymentComponent
      ? this.paymentComponent.getPaymentConfig(confirm)
      : {
          petStayInvoiceType:
            PetStayPaymentConfig.PetStayInvoiceTypeEnum.UpFront,
          petStayPaymentMode:
            PetStayPaymentConfig.PetStayPaymentModeEnum.InPerson,
          petStayPaymentType:
            PetStayPaymentConfig.PetStayPaymentTypeEnum.FullAmount,
          amountDue: 0,
          paymentPlanId: null,
          noOfSessionsToRedeem: 0,
          sendSms: false,
          preAuth: false,
        };

    if (planId && paymentConfig) {
      paymentConfig.paymentPlanId = planId;
    }

    console.log('Using paymentConfig', paymentConfig);
    console.log('selectedLocation 2');
    console.log(selectedLocation);
    createComp.boardingBooking = {
      created: true,
      start: '',
      // end: '',
      createdDate: this.dateUtils.getDateTimeAsIsoString(new Date(), true),
      startDateUtc:
        // eslint-disable-next-line max-len
        this.dateUtils.getDateTimeAsIsoString(
          new Date(`${startDateReq}T${startTime}`),
          true
        ),
      endDateUtc:
        // eslint-disable-next-line max-len
        this.dateUtils.getDateTimeAsIsoString(
          new Date(`${endDateReq}T${endTime}`),
          true
        ),
      startDate: startDateReq,
      startTime,
      endDate: endDateReq,
      endTime,
      locationId: selectedLocation ? selectedLocation.id : '',
      locationName: selectedLocation ? selectedLocation.name : '',
      locationFreeText: selectedLocation ? selectedLocation.locationName : '',
      customerId,
      customerName,
      notes: this.notes,
      icons: this.appointmentV2IconsComponent
        ? this.appointmentV2IconsComponent.selectedIcons
        : [],
      petRunBookings: boardingBookingRows,
      ownerId: this.contextIdDto.contextId as string,
      paymentConfig,
      appointmentSource: Appointment.AppointmentSourceEnum.WebCrm,
      enquiryDate: this.enquiryDate
        ? this.dateUtils.getDateAsStringDash(this.enquiryDate)
        : '',
      workflowDefinitionId: this.workflowDefId?.id
        ? this.workflowDefId?.id
        : undefined,
      bookingType: this.createBoardingBookingV2SyncService.bookingType,
    };
    debugger;
    console.log('createComp 3');
    console.log(createComp);
    if (createComp.boardingBooking.bookingType === 'DAY_CARE') {
      createComp.petStayTimeRanges =
        this.createBoardingBookingV2SyncService.petStayTimeRanges;
    }
    return createComp;
  }

  // checkConflictApi(appointmentDates: AppointmentDateLocal | AppointmentDateLocal[]) {
  //   if (Array?.isArray(appointmentDates)) {
  //     let executed = 0;
  //     for (const appointmentDate of appointmentDates) {
  //       const createComp: CreateAppointment = this.prepareCreateRequestPayload(true, appointmentDate);
  //       this.validateAppointmentV2Service.checkConflictsWithReason(this.contextIdDto, createComp.appointment)
  //         .then((clashCheckResponse: ClashCheckResponse) => {
  //           if (clashCheckResponse && clashCheckResponse.clashes.length) {
  //             appointmentDate.conflict = true;
  //             appointmentDate.clashStr = clashCheckResponse.clashStr;
  //           } else {
  //             appointmentDate.conflict = false;
  //           }
  //           executed += 1;
  //         });
  //       if (executed === appointmentDates?.length) {
  //         this.repeatAppointmentComponent?.notifyConflict(appointmentDates);
  //       }
  //     }
  //   } else {
  //     // const createComp: CreateAppointment = this.prepareCreateRequestPayload(true, appointmentDates);
  //     // this.validateAppointmentV2Service.checkConflicts(this.contextIdDto, createComp.appointment).then((clashes: Clash[]) => {
  //     //   if (clashes.length) {
  //     //     appointmentDates.conflict = true;
  //     //   } else {
  //     //     appointmentDates.conflict = false;
  //     //   }
  //     //   this.repeatAppointmentComponent?.notifyConflict(appointmentDates);
  //     // });
  //   }
  //   // this.updateStartDateAndEndDate(appointmentDates);
  // }

  // updateStartDateAndEndDate(appointmentDate) {
  //   if (appointmentDate && appointmentDate[0] && appointmentDate[0].startTime) {
  //     this.createBoardingBookingV2SyncService.updateStartTimeAndEndTimeByStartTime(appointmentDate[0].startTime);
  //   }
  // }

  getAppointmentTimeStrategy() {
    // if (this.quickAppointment) {
    //   return Appointment.AppointmentTimeStrategyEnum.AppointmentTimes;
    // } else
    // if (this.createBoardingBookingV2SyncService.appointmentConfig.appointmentConfig.customizedTimeForServices) {
    return Appointment.AppointmentTimeStrategyEnum.ServiceTimes;
    // } else {
    //   return Appointment.AppointmentTimeStrategyEnum.AppointmentTimes;
    // }
  }

  createAppointmentApi(
    toast = true,
    confirm?: boolean,
    appointmentDate?: AppointmentDateLocal,
    planId?: any
  ) {
    return new Promise<BoardingBooking>((resolve) => {
      if (!this.validateRequest()) {
        return;
      }
      if (this.createBoardingBookingV2SyncService.bookingType === 'BOARDING') {
        const createComp: CreateBoardingBooking =
          this.prepareCreateRequestPayload(confirm, appointmentDate, planId);
        this.spinner.show();
        if (createComp.boardingBooking) {
          createComp.boardingBooking.bookingType = 'BOARDING';
        }
        this.boardingBookingService
          .createBoardingBooking(createComp)
          .subscribe((response) => {
            this.spinner.hide();
            if (response) {
              if (toast) {
                this.notify.message = 'Boarding booking created successfully';
                this.notify.open();
                this.createAnywayApplied = false;
                this.dialogRef.close(response);
              }
              resolve(response?.boardingBooking);
            }
          });
      } else {
        // const selectedDates = this.dayCareDateSelectorComponent.getSelectedDates();
        // console.log("selected dates in daycare", selectedDates);
        // for (const selectedDate of selectedDates) {
        // appointmentDate = {
        //   ...appointmentDate,
        //   localStartDate: selectedDate
        // } as AppointmentDateLocal;
        const createComp: CreateBoardingBooking =
          this.prepareCreateRequestPayload(confirm, appointmentDate, planId);
        this.spinner.show();
        if (createComp.boardingBooking) {
          createComp.boardingBooking.bookingType = 'DAY_CARE';
        }
        this.boardingBookingService
          .createBoardingBooking(createComp)
          .subscribe((response) => {
            this.spinner.hide();
            if (response) {
              if (toast) {
                this.notify.message = 'Boarding booking created successfully';
                this.notify.open();
                this.createAnywayApplied = false;
                this.dialogRef.close(response);
              }
              resolve(response?.boardingBooking);
            }
          });
        // }
      }
      // });
    });
  }

  createAppointment(toast = true, confirm?: boolean) {
    this.smsCreditService.checkSMSCredits(this.contextIdDto);
    if (this.createNewPlan) {
      this.createPaymentPlanThenCreateAppointment(toast, confirm);
    } else {
      this.createAppointmentApi(toast, confirm).then((res) => {
        if (res) {
          // if (this.isRepeatAvailable) {
          //   const appointmentDates = this.repeatAppointmentComponent?.getAppointmentDates();
          //   appointmentDates?.forEach(appointmentDate => {
          //     const smallestTime = this.createBoardingBookingV2SyncService.getEarliestServiceTime();
          //     for (const petSelected of this.createBoardingBookingV2SyncService.petsSelected) {
          //       for (let service of this.createBoardingBookingV2SyncService.selectedRuns[petSelected.petId]) {
          //         if (this.isRepeatAvailable && appointmentDate) {
          //           service = this.createBoardingBookingV2SyncService
          //             .syncServiceStartAndEndTimeForRepeatApp(smallestTime, appointmentDate, service);
          //         }
          //       }
          //     }
          //     this.createAppointmentApi(toast, confirm, appointmentDate);
          //   });
          // }
        }
      });
    }
  }

  createPaymentPlanThenCreateAppointment(toast: boolean, confirm: boolean) {
    if (this.createBoardingBookingV2SyncService.customerId) {
      const customerId = this.createBoardingBookingV2SyncService.customerId;
      const planDefId = this.paymentComponent.getSelectedPlanDefinition().id;
      if (customerId && planDefId) {
        const subscribeToPlanReq: SubscribeToPlan = {};
        subscribeToPlanReq.contextIdDto = this.contextIdDto;
        subscribeToPlanReq.customerId = customerId;
        subscribeToPlanReq.planDefinitionId = planDefId;
        subscribeToPlanReq.dayOfPayment = 1;

        this.planCompApi.subscribe(subscribeToPlanReq).subscribe((res) => {
          this.createAppointmentApi(toast, confirm, null, res.plan.id);
          if (this.isRepeatAvailable) {
            // const appointmentDates = this.repeatAppointmentComponent?.getAppointmentDates();
            // appointmentDates?.forEach(appointmentDate => {
            //   for (const petSelected of this.createBoardingBookingV2SyncService.petsSelected) {
            //     const dates = this.createBoardingBookingV2SyncService.selectedRuns[petSelected.petId]
            //       .map(s => moment(`${moment(appointmentDate.localStartDate).format('YYYY-MM-DD')} ${moment(s.startTime, 'HH:mm')}`));
            //     const smallestTime = moment.min(dates);
            //     for (let service of this.createBoardingBookingV2SyncService.selectedRuns[petSelected.petId]) {
            //       if (this.isRepeatAvailable && appointmentDate) {
            //         service = this.createBoardingBookingV2SyncService
            //           .syncServiceStartAndEndTimeForRepeatApp(smallestTime.format('HH:mm'), appointmentDate, service);
            //       }
            //     }
            //   }
            //   this.createAppointmentApi(toast, confirm, appointmentDate, res.plan.id);
            // });
          }
        });
      }
    }
  }

  prepareUpdateRequestPayload(
    confirm = false,
    planId?: any
  ): UpdateBoardingBooking {
    let customerId = '';
    let customerName = '';

    if (this.createBoardingBookingV2SyncService.customerId) {
      customerId = this.createBoardingBookingV2SyncService.customerId;
      customerName = this.createBoardingBookingV2SyncService.customerName;
    }
    if (!customerId) {
      this.notify.message = 'No pet owner id specified for this pet';
      this.notify.open();
      return;
    }
    const updateComp = {} as UpdateBoardingBooking;
    updateComp.contextIdDto = this.contextIdDto;
    updateComp.confirm = confirm;
    if (confirm) {
      this.operationToPerform = 'updateAndConfirm';
    } else {
      this.operationToPerform = 'update';
    }
    let selectedLocation;
    if (this.createBoardingBookingV2SyncService.selectedLocation) {
      selectedLocation =
        this.createBoardingBookingV2SyncService.selectedLocation;
    } else {
      selectedLocation = this.locationsHelperService.nonDeletedLocations[0];
    }

    const boardingBookingRows: PetRunBooking[] = [];
    const startDateReq = this.createBoardingBookingV2SyncService.startDateReq;
    const endDateReq = this.createBoardingBookingV2SyncService.endDateReq;
    // if (this.isRepeatAvailable && appointmentDate) {
    //   startDateReq = this.dateUtils.getDateAsStringDash(appointmentDate?.localStartDate);
    //   endDateReq = this.dateUtils.getDateAsStringDash(appointmentDate?.localStartDate);
    // }
    for (const selectedRun of this.createBoardingBookingV2SyncService
      .selectedRuns) {
      const petIds = [];
      const petRunDetails: PetRunDetails[] = [];
      for (const selectedPet of selectedRun.selectedPets) {
        if (selectedPet.petId) {
          petIds.push(selectedPet.petId);
          if (selectedPet?.petServiceBookings?.length) {
            for (const petServiceBooking of selectedPet?.petServiceBookings) {
              petServiceBooking.startDate = this.dateUtils.getDateAsStringDash(
                petServiceBooking?.startDate
              );
              petServiceBooking.endDate = this.dateUtils.getDateAsStringDash(
                petServiceBooking?.endDate
              );
              petServiceBooking.petId = selectedPet.petId;
              petServiceBooking.petName = selectedPet.petName;
              petServiceBooking.petBreed = selectedPet.petBreed;
            }
          }
          petRunDetails.push({
            petId: selectedPet.petId,
            petBreed: selectedPet.petBreed,
            petName: selectedPet.petName,
            customerId: selectedPet.petOwnerId,
            customerName: selectedPet.customerName,
            petServiceBookings: selectedPet?.petServiceBookings,
            petFeedingConfig: selectedPet?.petFeedingConfig,
            petMedicationConfig: selectedPet?.petMedicationConfig,
          });
        }
      }

      let unitPrice = selectedRun?.selectedPetRun?.price;
      if (
        selectedRun?.selectedPetRun?.maxPets > 1 &&
        selectedRun.selectedPets.filter((sp) => sp.petId).length > 1
      ) {
        unitPrice = selectedRun?.selectedPetRun?.multiPetPrice;
      }
      if (
        this.createBoardingBookingV2SyncService.bookingType === 'DAY_CARE' &&
        this.createBoardingBookingV2SyncService.petStayPreference?.bookHourly
      ) {
        unitPrice = selectedRun?.selectedPetRun?.hourlyPrice;
      }

      boardingBookingRows.push({
        petRunId: selectedRun?.selectedPetRun?.id,
        petRunName: selectedRun?.selectedPetRun?.name,
        unitPrice,
        tax: selectedRun?.selectedPetRun?.tax,
        subTotal: selectedRun.subTotal,
        quantity: selectedRun.quantity,
        grossPrice: selectedRun.grossPrice,
        discountDetails: selectedRun.discount,
        petIds,
        petRunDetails,
      });
    }

    const startTime = this.createBoardingBookingV2SyncService.startTime;
    const endTime = this.createBoardingBookingV2SyncService.endTime;
    // if (this.isRepeatAvailable && appointmentDate) {
    //   const duration = moment.duration(moment(endTime, 'HH:mm').diff(moment(startTime, 'HH:mm')));
    //   const hours = duration.asHours();
    //   startTime = appointmentDate.startTime;
    //   endTime = moment(startTime, 'HH:mm').add(hours, 'hours').format('HH:mm');
    // }
    const paymentConfig: PetStayPaymentConfig = this.paymentComponent
      ? this.paymentComponent.getPaymentConfig(confirm)
      : {
          petStayInvoiceType: 'GENERATE_LATER',
          petStayPaymentMode: 'IN_PERSON',
          petStayPaymentType:
            PetStayPaymentConfig.PetStayPaymentTypeEnum.FullAmount,
          amountDue: 0,
          paymentPlanId: null,
          noOfSessionsToRedeem: 0,
          sendSms: false,
          preAuth: false,
        };

    if (planId) {
      paymentConfig.paymentPlanId = planId;
    }

    console.log('Using paymentConfig', paymentConfig);
    updateComp.boardingBooking = {
      ...this.createBoardingBookingV2SyncService.boardingBooking,
      created: true,
      // start: '',
      // end: '',
      createdDate: this.dateUtils.getDateTimeAsIsoString(new Date(), true),
      startDateUtc:
        // eslint-disable-next-line max-len
        this.dateUtils.getDateTimeAsIsoString(
          new Date(`${startDateReq}T${startTime}`),
          true
        ),
      endDateUtc:
        // eslint-disable-next-line max-len
        this.dateUtils.getDateTimeAsIsoString(
          new Date(`${endDateReq}T${endTime}`),
          true
        ),
      startDate: startDateReq,
      startTime,
      endDate: endDateReq,
      endTime,
      locationId: selectedLocation ? selectedLocation.id : '',
      locationName: selectedLocation ? selectedLocation.name : '',
      locationFreeText: selectedLocation ? selectedLocation.locationName : '',
      customerId,
      customerName,
      notes: this.notes,
      icons: this.appointmentV2IconsComponent
        ? this.appointmentV2IconsComponent.selectedIcons
        : [],
      petRunBookings: boardingBookingRows,
      ownerId: this.contextIdDto.contextId,
      paymentConfig,
      appointmentSource: Appointment.AppointmentSourceEnum.WebCrm,
      enquiryDate: this.enquiryDate
        ? this.dateUtils.getDateAsStringDash(this.enquiryDate)
        : '',
      bookingType: this.createBoardingBookingV2SyncService.bookingType,
    };

    if (updateComp?.boardingBooking?.bookingType === 'DAY_CARE') {
      updateComp.petStayTimeRanges =
        this.createBoardingBookingV2SyncService.petStayTimeRanges;
    }
    return updateComp;
  }

  updateAppointment(confirm = false) {
    this.smsCreditService.checkSMSCredits(this.contextIdDto);
    if (!this.validateRequest()) {
      return;
    }
    const updateBoarding: UpdateBoardingBooking =
      this.prepareUpdateRequestPayload(confirm);
    // this.validateAppointmentV2Service.checkConflicts(this.contextIdDto, updateAppointment.appointment).then((clashes: Clash[]) => {
    // if (this.appointmentConfigHelperService.appointmentConfig.conflictCheckEnabled && clashes.length && !this.createAnywayApplied) {
    //   this.clashes = clashes;
    //   this.checkConflictsDialog = true;
    //   return;
    // }
    this.spinner.show();
    this.boardingBookingService
      .updateBoardingBooking(updateBoarding)
      .subscribe((response) => {
        this.spinner.hide();
        this.notify.message = 'Boarding booking updated successfully';
        this.notify.open();
        this.createBoardingBookingV2SyncService.editMode = false;
        this.createBoardingBookingV2SyncService.clear();
        this.dialogRef.close(response);
      });
    // });
  }

  confirmAppointment() {
    this.createAppointment(true, true);
  }

  updateAndConfirmAppointment() {
    this.updateAppointment(true);
  }

  petCreated(event: Pet) {
    this.createPet = false;
    // this.createBoardingBookingV2SyncService.populateNewPet(event);
  }

  showPreviousStep(event?: Event) {
    // this.wizard.goToPreviousStep();
  }

  showNextStep(event?: Event) {
    // this.wizard.goToNextStep();
  }

  getLinkId(row: ListEntityListItemDto) {
    const linkId = {} as LinkId;
    linkId.linkedId = row.entityInstanceId.id;
    linkId.linkedIdType = 'ENTITY_INSTANCE';
    return linkId;
  }

  isHidden(stepName: string) {
    if (this.wizardPrefs) {
      this.wizardPrefs.stepPrefs.forEach((step) => {
        if (step.stepName === stepName) {
          return step.hide;
        }
      });
    }
    return false;
  }

  // petSelected($event) {
  //   this.selectedPets = $event || [];
  //   this.petRunSelectorComponent.selectedRuns = [];
  //   for (const pet of this.selectedPets) {
  //     this.petRunSelectorComponent.addServiceNew(pet);
  //   }
  // }

  cancel() {
    // if (this.data.fromView) {
    //   this.dialogRef.close({
    //     fromView: true,
    //     appointmentId: this.boardingBooking.id,
    //   });
    // } else {
    this.dialogRef.close();
    // }
  }

  // fullScreen() {
  //   this.quickAppointment = false;
  //   this.dialogRef.updateSize('1100px', 'auto');
  // }

  // exitFullScreen() {
  //   this.quickAppointment = true;
  //   this.dialogRef.updateSize(null, 'auto');
  // }

  stepChanged(event) {
    this.selectedTabIndex = event.selectedIndex;
    if (event.selectedIndex === 1) {
      // payment step
      this.createBoardingBookingV2SyncService.calculateTotalAmount();
    }
  }

  amountDueChanged(amountDue: number) {
    this.createBoardingBookingV2SyncService.totalAmountDue = amountDue;
  }

  createPlanChanged(willCreatePlan: boolean) {
    this.createNewPlan = willCreatePlan;
  }

  closeConfirmDialog() {
    this.showConfirmationDialog = false;
    // this.appointmentStatus = 'tentative';
  }

  updateConfirmEnabled(event) {
    this.appointmentConfigHelperService.appointmentConfig.confirmEnabled =
      !event;
    this.appointmentConfigHelperService.updateAppointmentConfig(
      this.contextIdDto,
      this.appointmentConfigHelperService.appointmentConfig
    );
  }

  updateConflictCheck(event) {
    this.appointmentConfigHelperService.appointmentConfig.conflictCheckEnabled =
      !event;
    this.appointmentConfigHelperService.updateAppointmentConfig(
      this.contextIdDto,
      this.appointmentConfigHelperService.appointmentConfig
    );
  }

  createAndConfirm() {
    if (this.appointmentConfigHelperService.appointmentConfig.confirmEnabled) {
      this.showConfirmationDialog = true;
    } else {
      this.confirmAppointment();
    }
  }

  customerCreated(event: Pet) {
    this.createBoardingBookingV2SyncService.populateNewCustomer(event);
  }

  bookingTypeChanged(newType: BoardingBooking.BookingTypeEnum) {
    this.createBoardingBookingV2SyncService.bookingTypeChanged(newType);
  }

  createAnyway() {
    if (this.operationToPerform) {
      this.createAnywayApplied = true;
      switch (this.operationToPerform) {
        case 'create':
          this.createAppointment();
          break;
        case 'createAndConfirm':
          this.createAndConfirm();
          break;
        case 'update':
          this.updateAppointment();
          break;
        case 'updateAndConfirm':
          this.updateAndConfirmAppointment();
          break;
      }
    }
  }

  updateCustFlags() {
    if (
      this.petSelectorV2Component &&
      this.petSelectorV2Component.customerStatsComponent
    ) {
      this.petSelectorV2Component.customerStatsComponent.loadStatus();
    }
  }

  locationChange(newLocation: Location) {
    this.createBoardingBookingV2SyncService.setSelectedLocation(newLocation);
  }

  getMessage(action: string) {
    let message = '';
    switch (action) {
      case 'create':
        message +=
          'This will create the booking without sending email/SMS confirmation messages';
        if (
          this.boardingBooking?.paymentConfig?.petStayPaymentMode ===
            this.paymentModeEnum.DepositRequest ||
          this.boardingBooking?.paymentConfig?.sendSms
        ) {
          message += ' but will send out a deposit request via';
          message += this.boardingBooking?.paymentConfig?.sendSms ? ' SMS' : '';
          if (
            this.boardingBooking?.paymentConfig?.petStayPaymentMode ===
              this.paymentModeEnum.DepositRequest &&
            this.boardingBooking?.paymentConfig?.sendSms
          ) {
            message += ' &';
          }
          if (
            this.boardingBooking?.paymentConfig?.petStayPaymentMode ===
            this.paymentModeEnum.DepositRequest
          ) {
            message += ' Email';
          }
        }
        message += '.';
        break;
      case 'createAndConfirm':
        message +=
          'This will create the booking and will send out email/SMS confirmation messages';
        if (
          this.boardingBooking?.paymentConfig?.petStayPaymentMode ===
            this.paymentModeEnum.DepositRequest ||
          this.boardingBooking?.paymentConfig?.sendSms
        ) {
          message += ' with a deposit request via';
          message += this.boardingBooking?.paymentConfig?.sendSms ? ' SMS' : '';
          if (
            this.boardingBooking?.paymentConfig?.petStayPaymentMode ===
              this.paymentModeEnum.DepositRequest &&
            this.boardingBooking?.paymentConfig?.sendSms
          ) {
            message += ' &';
          }
          if (
            this.boardingBooking?.paymentConfig?.petStayPaymentMode ===
            this.paymentModeEnum.DepositRequest
          ) {
            message += ' Email';
          }
        }
        if (
          this.boardingBooking?.paymentConfig?.petStayPaymentMode ===
          this.paymentModeEnum.PaymentPlan
        ) {
          message += ' and selected payment plan will be applied';
        }
        message += '.';
        break;
      case 'update':
        message +=
          'This will update the booking without sending email/SMS confirmation messages';
        if (
          this.boardingBooking?.paymentConfig?.petStayPaymentMode ===
            this.paymentModeEnum.DepositRequest ||
          this.boardingBooking?.paymentConfig?.sendSms
        ) {
          message += ' but will send out a deposit request reminder via';
          message += this.boardingBooking?.paymentConfig?.sendSms ? ' SMS' : '';
          if (
            this.boardingBooking?.paymentConfig?.petStayPaymentMode ===
              this.paymentModeEnum.DepositRequest &&
            this.boardingBooking?.paymentConfig?.sendSms
          ) {
            message += ' &';
          }
          if (
            this.boardingBooking?.paymentConfig?.petStayPaymentMode ===
            this.paymentModeEnum.DepositRequest
          ) {
            message += ' Email';
          }
        }
        message += '.';
        break;
      case 'updateAndConfirm':
        message +=
          'This will update the booking and will send out email/SMS confirmation messages';
        if (
          this.boardingBooking?.paymentConfig?.petStayPaymentMode ===
            this.paymentModeEnum.DepositRequest ||
          this.boardingBooking?.paymentConfig?.sendSms
        ) {
          message += ' with a deposit request via';
          message += this.boardingBooking?.paymentConfig?.sendSms ? ' SMS' : '';
          if (
            this.boardingBooking?.paymentConfig?.petStayPaymentMode ===
              this.paymentModeEnum.DepositRequest &&
            this.boardingBooking?.paymentConfig?.sendSms
          ) {
            message += ' &';
          }
          if (
            this.boardingBooking?.paymentConfig?.petStayPaymentMode ===
            this.paymentModeEnum.DepositRequest
          ) {
            message += ' Email';
          }
        }
        if (
          this.boardingBooking?.paymentConfig?.petStayPaymentMode ===
          this.paymentModeEnum.PaymentPlan
        ) {
          message += ' and selected payment plan will be applied';
        }
        message += '.';
        break;
    }
    return message;
  }

  paymentConfigChanged($event) {
    this.createBoardingBookingV2SyncService.calculateTotalAmount();
  }

  createCustomer() {
    const createPetModal = this.dialog.open(AddCustomerComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        pageType: 'addCustomer',
        isViewMode: true,
      },
      maxWidth: '1024px',
      panelClass: ['modal-100', 'helpwindow', 'scrollable-modal-2'],
      autoFocus: false,
      disableClose: true,
    });

    createPetModal.afterClosed().subscribe((newCustomer: Customer) => {
      if (newCustomer) {
        this.createBoardingBookingV2SyncService.selectedRuns[0].selectedPets =
          [];
        this.petService
          .loadCustomersPets(newCustomer.customerId?.id)
          .subscribe((pets) => {
            for (const pet of pets) {
              this.createBoardingBookingV2SyncService.selectedRuns[0].selectedPets.push(
                this.createBoardingBookingV2SyncService.populateNewPet(pet)
              );
            }
          });
      }
    });
  }
}
