import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {
  CalendarCompositeService, CalendarEntry, CalendarSettings,
  CalendarUserDto, ContextIdDto, GetCalendarComp,
  IdNameTupleDto, LoadCalendarForWebUiResponse, UsersCalendarSettings
} from '@savvy/calendar';
import { Location } from '@savvy/location';
import { CalendarEvent, CalendarEventTimesChangedEvent } from 'angular-calendar';
import { isSameDay, isSameMonth } from 'date-fns';
import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ChangeSlotComponent } from 'src/app/flo/calendar/appointment-calendar/change-slot/change-slot.component';
import { ContextService } from 'src/app/flo/context.service';
import { DateUtils } from 'src/app/flo/dates/DateUtils';
import { TimeUtils } from 'src/app/flo/dates/TimeUtils';
import { ClearObservable } from 'src/app/flo/shared/classes/clear-observable';
import { PhoneNumberHelperService } from 'src/app/flo/shared/services/phone-number-helper.service';
import { UserDefaultLocationService } from 'src/app/services/user-default-location.service';
import { CreatePetAppointmentV2SyncService } from '../../create-pet-appointment-v2-sync.service';
import { AppointmentDateLocal, RepeatDatesFilter } from '../repeat-filter/repeat-filter.component';
import * as _ from 'lodash';
import { TextColorHelperService } from 'src/app/flo/shared/text-color-helper.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-repeat-calendar',
  templateUrl: './repeat-calendar.component.html',
  styleUrls: ['./repeat-calendar.component.scss']
})
export class RepeatCalendarComponent extends ClearObservable implements OnInit, OnDestroy {
  @Output() checkConflictApi: EventEmitter<AppointmentDateLocal[]> = new EventEmitter();
  contextIdDto: ContextIdDto;
  dayViewUsers: CalendarUserDto[] = [];
  viewDate: Date = new Date();
  view: 'day' | 'week' | 'month' | string = 'day';
  calendarViewTypeEnum = GetCalendarComp.CalendarViewTypeEnum;
  calendarSettings: CalendarSettings;
  events: CalendarEvent[] = [];
  newWeekEvents: CalendarEvent[] = [];
  newMonthEvents: CalendarEvent[] = [];

  users: CalendarUserDto[] = [];
  locationToUseWhenCreating: Location;
  public dayStartHour = '0';
  public dayEndHour = '23';
  userCalendarSettings: UsersCalendarSettings;
  numHourSegments: number;
  refresh: Subject<any> = new Subject();
  weekStartsOn = 0;
  allowSelectAllLocations = false;
  locations: Array<Location>;
  creatableEventTypes: Array<IdNameTupleDto>;
  width = 150;
  excludeDays: number[];

  appointmentDates: AppointmentDateLocal[] = [];
  repeatFilter: any;
  selectedAppointmentDate;
  activeDayIsOpen = true;
  overrideConflictCheck = false;

  constructor(
    private dialog: MatDialog,
    private timeUtils: TimeUtils,
    protected dateUtils: DateUtils,
    private datePipe: DatePipe,
    public sharedService: PhoneNumberHelperService,
    private contextService: ContextService,
    public translateService: TranslateService,
    public deviceService: DeviceDetectorService,
    private calendarCompositeService: CalendarCompositeService,
    private userDefaultLocationService: UserDefaultLocationService,
    public createPetAppointmentV2SyncService: CreatePetAppointmentV2SyncService,
    private textColorHelperService: TextColorHelperService
  ) {
    super();
  }

  ngOnInit(): void {
    this.contextService.contextIdDto$
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        if (data) {
          this.contextIdDto = data;
          if (this.userDefaultLocationService.getLocationId()) {
            this.locationToUseWhenCreating = {
              entityInstanceId: {
                id: this.userDefaultLocationService.getLocationId()
              }
            };
          }

          this.loadDataForCalendar();
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getEvent(calendarEntryDto: CalendarEntry, draggable = false): CalendarEvent {
    const color = {
      primary: calendarEntryDto.cellColour,
      secondary: calendarEntryDto.cellColour,
      textColor: this.textColorHelperService.getTextColor(calendarEntryDto.cellColour || 'rgba(255, 115, 115, 0.255)')
    };

    let entryTypeToUse = 'Ei';

    if (calendarEntryDto.entryType) {
      entryTypeToUse = calendarEntryDto.entryType;
    }

    const startDate = new Date(calendarEntryDto.startDate);
    startDate.setMinutes(startDate.getMinutes() + startDate.getTimezoneOffset());
    const start = startDate;
    const endDate = new Date(calendarEntryDto.endDate);
    endDate.setMinutes(endDate.getMinutes() + endDate.getTimezoneOffset());
    const end = endDate;

    let cellLabelToUse = calendarEntryDto.cellLabel;

    if (calendarEntryDto.cellLabelTpl) {
      cellLabelToUse = calendarEntryDto.cellLabelTpl;
    }

    const newEvent: CalendarEvent = {
      cssClass: 'hide-calendar-events',
      start,
      end,
      title: calendarEntryDto.detailedLabel,
      color,
      draggable,
      meta: {
        reference: calendarEntryDto.reference,
        state: calendarEntryDto.state,
        dayCellLines: calendarEntryDto.dayCellLines,
        weekCellLinesHtml: calendarEntryDto.weekCellLinesHtml,
        cellLabel: cellLabelToUse,
        cellColor: calendarEntryDto.cellColour,
        entryType: entryTypeToUse,
        numOverlaps: calendarEntryDto.numOverlaps,
        overlapNum: calendarEntryDto.overlapNum,
        dayCellLinesHtml: calendarEntryDto.dayCellLinesHtml,
        overlapping: calendarEntryDto.overlapping,
        pickup: calendarEntryDto.requiresPickup,
        icons: calendarEntryDto.icons,
        userId: calendarEntryDto.users && calendarEntryDto.users.length > 0 ? calendarEntryDto.users[0] : null,
        user: null,
        id: calendarEntryDto.id,
        durationInMins: calendarEntryDto.durationInMins,

      }
    };
    this.setUser(newEvent, this.users);
    return newEvent;
  }

  setUser(event, users) {
    if (users && users.length > 0) {
      if (event && event.meta && event.meta['userId']) {
        users.forEach(user => {
          if (user.userId && event && event.meta['userId'].id) {
            if (user.userId.id === event.meta['userId'].id) {
              event.meta['user'] = user;
            }
          }
        });
      }
      if (event && !event.meta['user']) {
        event.meta['user'] = users[0];
      }
    }
  }

  populateLoadCalendarForWebUiResponse(response: LoadCalendarForWebUiResponse, isLastAppointment) {
    if (response) {
      const newDayEvents: CalendarEvent[] = [];
      const newMonthEvents: CalendarEvent[] = [];
      const newWeekEvents: CalendarEvent[] = [];
      this.events = [];
      this.newWeekEvents = [];
      this.newMonthEvents = [];
      if (response.calendarDto &&
        response.calendarDto.calendarEntryList &&
        response.calendarDto.calendarEntryList.length > 0) {

        for (const calendarEntryDto of response.calendarDto.calendarEntryList) {
          if (response.calendarUserDtoList) {
            const calendarEvent = this.getEvent(calendarEntryDto);
            if (calendarEntryDto.entryType !== 'businessClosed' &&
              calendarEntryDto.entryType !== 'businessHours') {
              newDayEvents.push(calendarEvent);
            }
            if (
              calendarEntryDto.entryType !== 'userOff'
              && calendarEntryDto.entryType !== 'userHours'
              && calendarEntryDto.entryType !== 'businessHours'
            ) {
              newMonthEvents.push(calendarEvent);
            }
            if (
              calendarEntryDto.entryType !== 'userOff' &&
              calendarEntryDto.entryType !== 'userHours') {
              newWeekEvents.push(calendarEvent);
            }

            if (calendarEntryDto.entryType === 'bankHoliday') {
              calendarEvent.allDay = true;
            }
          }
        }
      }
      this.events = this.events.concat(newDayEvents);
      this.newWeekEvents = this.newWeekEvents.concat(newWeekEvents);
      this.newMonthEvents = this.newMonthEvents.concat(newMonthEvents);
      this.setUsers(response);
      if (isLastAppointment) {
        this.checkConflictOnDates();
      }
    }
  }


  eventTimesChangedRepeat({
    type,
    event,
    newStart,
    newEnd,
    allDay
  }: CalendarEventTimesChangedEvent): void {
    console.log('Inside drag eventTimesChangedRepeat');
    if (new Date(event.start).toISOString() === new Date(newStart).toISOString()
      && new Date(event.end).toISOString() === new Date(newEnd).toISOString()) {
      // this.handleEvent(event);
      return;
    }
    if (event.meta.entryType === 'newRepeatAppointment') {

      // eslint-disable-next-line max-len
      const appointmentDateIndex = this.appointmentDates.findIndex(date => date.id === event.meta.id);
      if (appointmentDateIndex > -1) {
        const localStartDate = _.cloneDeep(newStart);
        // localStartDate.setHours(0);
        this.appointmentDates[appointmentDateIndex].localStartDate = localStartDate;
        this.appointmentDates[appointmentDateIndex].startTime = moment(newStart).format('HH:mm');
        this.appointmentDates[appointmentDateIndex].differenceInHours = this.dateUtils.getDurationInHours(moment(event.start).format('HH:mm'), moment(newStart).format('HH:mm'));
        // eslint-disable-next-line max-len
        event.start = newStart;
        event.end = newEnd;
        event.title = `Selected Slot for ${this.datePipe.transform(this.appointmentDates[appointmentDateIndex].localStartDate, 'MMM d, y, h:mm a')}`;
        event.color.primary = 'green';
        event.color.secondary = 'green';
      }
      this.checkConflictOnApi();
      this.refresh.next(true);
      return;
    }
  }

  generateCustomEvent() {

  }

  setUsers(response: LoadCalendarForWebUiResponse) {
    if (response && response.calendarUserDtoList) {
      this.users = response.calendarUserDtoList;
      this.loadDayViewUsers();
    }
  }

  loadDayViewUsers() {
    if (this.locationToUseWhenCreating && this.locationToUseWhenCreating.id && this.contextIdDto) {
      this.calendarCompositeService.loadStaffByLocation(
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.dateUtils.getDateAsStringV2(this.viewDate),
        this.locationToUseWhenCreating.id
      ).subscribe(response => {
        console.log('calendar users now', response.calendarUserDtos);
        this.dayViewUsers = response.calendarUserDtos;
      });
    } else {
      console.log('defaulting to ALL users as no location is set');
      this.dayViewUsers = this.users;
    }
  }

  setWeekStartsOn() {
    if (this.userCalendarSettings.weekStartDay) {

      if (this.userCalendarSettings.weekStartDay.toUpperCase() === 'SUNDAY') {
        this.weekStartsOn = 0;
      } else if (this.userCalendarSettings.weekStartDay.toUpperCase() === 'MONDAY') {
        this.weekStartsOn = 1;
      } else if (this.userCalendarSettings.weekStartDay.toUpperCase() === 'TUESDAY') {
        this.weekStartsOn = 2;
      } else if (this.userCalendarSettings.weekStartDay.toUpperCase() === 'WEDNESDAY') {
        this.weekStartsOn = 3;
      } else if (this.userCalendarSettings.weekStartDay.toUpperCase() === 'THURSDAY') {
        this.weekStartsOn = 4;
      } else if (this.userCalendarSettings.weekStartDay.toUpperCase() === 'FRIDAY') {
        this.weekStartsOn = 5;
      } else if (this.userCalendarSettings.weekStartDay.toUpperCase() === 'SATURDAY') {
        this.weekStartsOn = 6;
      }
    } else {
      this.weekStartsOn = 0;
    }
  }

  loadCalendarViewSetting(): void {
    if (this.sharedService.savedCalendarView) {
      this.view = this.sharedService.savedCalendarView;
    } else {
      this.view = 'day';
    }
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {

    // console.log('day clicked');
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        console.log('active day open false');
        this.activeDayIsOpen = false;
      } else {
        console.log('active day open true');
        this.activeDayIsOpen = true;
        this.viewDate = date;
      }
    } else {
      console.log('not same month');
    }
  }

  viewChange(viewStr: 'month' | 'week' | 'day') {
    if (viewStr) {
      this.view = viewStr;
      this.sharedService.savedCalendarView = viewStr;
      this.loadCalendar(this.selectedAppointmentDate, true);
    }
    /*console.log('view is now ' + this.view);
    console.log('viewDate.day is ' + this.viewDate.getDay());
    console.log('viewDate.month is ' + this.viewDate.getMonth());
    console.log('viewDate.year is ' + this.viewDate.getFullYear());*/
  }

  loadCalendar(appointmentDate, isLastAppointment) {
    const req = this.getCalendarCompRequest(appointmentDate?.localStartDate);
    this.sharedService.savedCalendarDate = this.viewDate;
    this.calendarCompositeService.loadCalWeb(req).pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.populateLoadCalendarForWebUiResponse(response, isLastAppointment);
    });
  }

  selectDate(appointmentDate: AppointmentDateLocal) {
    if (!appointmentDate) {
      return;
    }
    this.selectedAppointmentDate = appointmentDate;
    if (appointmentDate?.localStartDate) {
      this.viewDate = appointmentDate?.localStartDate;
    }
    this.loadCalendar(appointmentDate, true);
    // this.loadDataForCalendar();
  }

  checkConflictOnDates() {
    if (this.appointmentDates?.length) {
      for (const appointmentDate of this.appointmentDates) {
        this.handleCustomEvents(appointmentDate);
      }
    }
  }

  dateGenerated(appointmentDates: AppointmentDateLocal[], repeatFilter: RepeatDatesFilter) {
    this.events = [];
    this.newWeekEvents = [];
    this.newMonthEvents = [];
    if (repeatFilter) {
      this.repeatFilter = repeatFilter;
    }
    if (appointmentDates) {
      this.appointmentDates = appointmentDates;
      this.selectDate(this.appointmentDates[0]);
    }
  }

  checkConflictOnApi() {
    if (this.appointmentDates?.length) {
      this.checkConflictApi.emit(this.appointmentDates);
    }
  }

  eventClicked({ event }: { event: CalendarEvent }): void {
    console.log('Event clicked', event);
    this.handleEvent(event);
  }

  overrideConflictCheckChanged(overrideConflictCheck: boolean) {
    if (overrideConflictCheck) {
      for (const appointmentDate of this.appointmentDates) {
        appointmentDate.overrideConflictCheck = overrideConflictCheck;
        appointmentDate.conflict = false;
      }
    } else {
      this.checkConflictOnApi();
    }
    this.loadDataForCalendar();
  }

  deleteAppointmentDate(index) {
    if (this.appointmentDates && this.appointmentDates[index]) {
      this.appointmentDates.splice(index, 1);
    }
  }

  handleEvent(event: CalendarEvent): void {
    if (event?.meta?.reference === 'conflict' || event?.meta?.reference === 'confirmed') {
      const dialogRef = this.dialog.open(ChangeSlotComponent, {
        data: {
          contextIdDto: this.contextIdDto,
          selectedSlot: moment(event.start).format('HH:mm')
        },
        panelClass: ['helpwindow']
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (this.appointmentDates?.length) {
            const index = this.appointmentDates?.findIndex(appointmentDate =>
              appointmentDate?.localStartDate === this.selectedAppointmentDate?.localStartDate);
            if (index !== -1) {
              this.appointmentDates[index].startTime = result;
              const hour = result.split(':')[0];
              const minutes = result.split(':')[1];
              this.appointmentDates[index].localStartDate = new Date(new Date(this.appointmentDates[index].localStartDate.setHours(hour)).setMinutes(minutes))
              this.checkConflictOnApi();
              this.loadDataForCalendar();
            }
          }
        }
      });
    }
  }

  public getAppointmentDates() {
    for (const appointmentDate of this.appointmentDates) {
      appointmentDate.overrideConflictCheck = this.overrideConflictCheck;
    }
    return this.appointmentDates;
  }

  notifyConflict(appointmentDates: AppointmentDateLocal[]) {
    if (this.appointmentDates.length) {
      if (Array.isArray(appointmentDates)) {
        this.appointmentDates = appointmentDates;
        this.loadCalendar(this.selectedAppointmentDate, true);
      } else {
        // const index = this.appointmentDates.findIndex(ad => ad?.localStartDate === appointmentDate?.localStartDate);
        // if (index !== -1) {
        //   this.appointmentDates[index] = appointmentDate;
        //   this.checkConflictForAppointmentDates();
        // }
      }
    }
  }

  compareObjects(o1: AppointmentDateLocal, o2: AppointmentDateLocal): boolean {
    return o1.id === o2.id;
  }

  onLocationChange(location: Location) {

    // If filter contains location, then lets reload the users
    this.locationToUseWhenCreating = location;
    // Load different users for the Calendar
    if (this.locationToUseWhenCreating && this.locationToUseWhenCreating.id !== '-1') {
      this.userDefaultLocationService.setLocationId(this.locationToUseWhenCreating.id);
    }
    this.loadCalendar(this.selectedAppointmentDate, true);
  }

  allSelected() {
    this.locationToUseWhenCreating = {} as Location;
    this.locationToUseWhenCreating.locationName = 'All';
    this.locationToUseWhenCreating.id = '-1';
    this.userDefaultLocationService.setLocationId(null);
    this.loadCalendar(this.selectedAppointmentDate, true);
  }

  loadDataForCalendar() {
    const req = this.getCalendarCompRequest(this.viewDate);
    this.calendarCompositeService.loadDataForCalenderWeb(req).subscribe(response => {
      if (response && response.contextIdDto) {
        this.contextIdDto = response.contextIdDto;
        this.allowSelectAllLocations = response.allowSelectAllLocations;
        this.locations = response.locations;
        this.calendarSettings = response.calendarSettings;
        if (this.calendarSettings && this.calendarSettings.multiLocationEnabled) {
          if (response.defaultLocation && response.defaultLocation.selected) {
            this.locationToUseWhenCreating = response.defaultLocation.location;
          } else if (this.userDefaultLocationService.getLocationId()) {
            this.locationToUseWhenCreating =
              this.locations.find(v => v.id === this.userDefaultLocationService.getLocationId());
          } else {
            this.locationToUseWhenCreating = response.locations[0];
          }
          if (this.createPetAppointmentV2SyncService.selectedLocation.id) {
            this.locationToUseWhenCreating = this.createPetAppointmentV2SyncService.selectedLocation;
          }
          this.userDefaultLocationService.setLocationId(this.locationToUseWhenCreating
            ? this.locationToUseWhenCreating.id : null);
        } else {
          this.userDefaultLocationService.setLocationId(null);
        }
        this.handleLoadCalendarSettings(response.usersCalendarSettings);
        this.creatableEventTypes = response.creatable;
        if (response.loadCalendarForWebUiResponse) {
          this.populateLoadCalendarForWebUiResponse(response.loadCalendarForWebUiResponse, true);
        }
      }
    });
  }

  private handleCustomEvents(appointmentDate: AppointmentDateLocal) {
    const time = moment(appointmentDate.startTime, 'HH:mm');
    const startDate = moment(appointmentDate?.localStartDate);
    startDate.set({
      hour: time.get('hour'),
      minute: time.get('minute'),
      second: time.get('second')
    });
    const start = startDate.toDate();
    start.setMinutes(start.getMinutes() - start.getTimezoneOffset());

    const users = {};
    const selectedPetKeys = Object.keys(this.createPetAppointmentV2SyncService.selectedServices);
    if (selectedPetKeys?.length) {
      selectedPetKeys.forEach(petKey => {
        if (this.createPetAppointmentV2SyncService.selectedServices[petKey]
          && this.createPetAppointmentV2SyncService.selectedServices[petKey]?.length) {
          this.createPetAppointmentV2SyncService.selectedServices[petKey].forEach(user => {
            if (user?.selectedStaff?.id) {
              if (isNaN(user?.selectedService?.durationInMins)) {
                user.selectedService.durationInMins = 60;
              }
              if (users[user?.selectedStaff?.id]) {
                users[user?.selectedStaff?.id] = {
                  id: user?.selectedStaff?.id,
                  duration: users[user?.selectedStaff?.id].duration
                    + this.dateUtils.getDurationInMins(user.startTime, user.endTime)
                };
              } else {
                users[user?.selectedStaff?.id] = {
                  id: user?.selectedStaff?.id,
                  duration: this.dateUtils.getDurationInMins(user.startTime, user.endTime)
                };
              }
            }
          });
        }
      });
    }
    for (const property in users) {
      if (Object.prototype.hasOwnProperty.call(users, property)) {
        const element = users[property];
        const endDate = moment(appointmentDate?.localStartDate);
        endDate.set({
          hour: time.get('hour'),
          minute: time.get('minute') + element?.duration,
          second: time.get('second')
        });
        const end = endDate.toDate();
        end.setMinutes(end.getMinutes() - end.getTimezoneOffset());
        const calendarEntrySelected = {
          id: appointmentDate.id,
          cellColour: 'green',
          startDate: start.toISOString(),
          endDate: end.toISOString(),
          cellLabel: 'Cell Label',
          detailedLabel: `Selected Slot for ${this.datePipe.transform(appointmentDate.localStartDate, 'MMM d, y, h:mm a')}`,
          users: [{
            id: element?.id
          }],
          meta: {
            user: {
              userId: {
                id: element?.id
              }
            },
          },
          dayCellLines: ['Day Cell lines'],
          weekCellLinesHtml: '<b>Day Cell lines</b>',
          icons: [],
          numOverlaps: 0,
          overlapNum: null,
          overlapping: false,
          requiresPickup: false,
          reference: 'confirmed',
          state: 'Confirmed',
          dayCellLinesHtml: '<b>dayCellLinesHtml</b>',
          draggable: true,
          entryType: 'newRepeatAppointment'
        };
        const calendarEventSelected = this.getEvent(calendarEntrySelected, calendarEntrySelected.draggable);
        if (appointmentDate?.conflict && !this.overrideConflictCheck) {
          calendarEventSelected.color.primary = 'red';
          calendarEventSelected.color.secondary = 'red';
          calendarEventSelected.meta.reference = 'conflict';
        } else {
          calendarEventSelected.color.primary = 'green';
          calendarEventSelected.color.secondary = 'green';
          calendarEventSelected.meta.reference = 'confirmed';
        }
        this.events.push(calendarEventSelected);
        this.newWeekEvents.push(calendarEventSelected);
        this.newMonthEvents.push(calendarEventSelected);
      }
    }
  }

  private getViewType() {
    if (this.view === 'day') {
      return GetCalendarComp.CalendarViewTypeEnum.Day;
    } else if (this.view === 'week') {
      return GetCalendarComp.CalendarViewTypeEnum.Week;
    } else {
      return GetCalendarComp.CalendarViewTypeEnum.Month;
    }
  }

  private getCalendarCompRequest(viewDate) {
    if (!viewDate) {
      return;
    }
    const req = {} as GetCalendarComp;
    req.contextIdDto = this.contextIdDto;
    req.monthToLoad = viewDate.getMonth() + 1;
    req.yearToLoad = viewDate.getFullYear();
    req.dateToLoad = viewDate.getDate();
    req.loadMonthEitherSide = true;
    req.filterLocations = new Array<string>();
    req.filterUserIds = new Array<string>();
    req.calendarViewType = this.getViewType();

    if (this.locationToUseWhenCreating && this.locationToUseWhenCreating.id && this.locationToUseWhenCreating.id !== '-1') {
      req.filterLocations.push(this.locationToUseWhenCreating.id);
    }
    req.filterUserIds = this.createPetAppointmentV2SyncService.getSelectedUserIds();
    return req;
  }



  private handleLoadCalendarSettings(response) {
    this.userCalendarSettings = response;
    if (!this.userCalendarSettings) {
      return;
    }
    if (this.userCalendarSettings.dayViewColWidth) {
      this.width = this.userCalendarSettings.dayViewColWidth;
    }
    if (this.userCalendarSettings.startDayViewTime) {
      const startDayDate = this.timeUtils.getDateFromTime(this.userCalendarSettings.startDayViewTime);
      this.dayStartHour = startDayDate.getHours() + '';
    }
    if (this.userCalendarSettings.endDayViewTime) {
      const startDayDate = this.timeUtils.getDateFromTime(this.userCalendarSettings.endDayViewTime);
      this.dayEndHour = startDayDate.getHours() + '';
    }
    this.loadCalendarViewSetting();
    this.setWeekStartsOn();


    this.numHourSegments = this.userCalendarSettings.numHourSegments > 0 ? this.userCalendarSettings.numHourSegments : 2;
    this.excludeDays = this.userCalendarSettings.excludeDays && this.userCalendarSettings.excludeDays.length ?
      this.userCalendarSettings.excludeDays : null;
    if (this.userCalendarSettings.excludeDays && this.userCalendarSettings.excludeDays.length < 6) {
      this.excludeDays = this.userCalendarSettings.excludeDays;
    } else {
      this.excludeDays = [];
    }
  }

  private checkConflictForAppointmentDates() {
    this.events = [];
    this.newWeekEvents = [];
    this.newMonthEvents = [];
    if (this.appointmentDates?.length) {
      for (let i = 0; i < this.appointmentDates.length; i++) {
        if ((this.appointmentDates.length - 1) === i) {
          this.loadCalendar(this.appointmentDates[i], true);
        } else {
          this.loadCalendar(this.appointmentDates[i], false);
        }
      }
    }
  }


}
