<h1 mat-dialog-title>{{'ADD_WORKFLOW' | translate}}</h1>
<form [formGroup]="workflowForm">

    <div mat-dialog-content>
        <div class="mt-1">
            <mat-form-field style="width: 100%">
                <mat-label>{{'WORKFLOW_NAME' | translate}}</mat-label>
                <input matInput placeholder="{{'WORKFLOW_NAME' | translate}}" class="form-control" formControlName="workflowName"
                    autofocus required>
            </mat-form-field>
        </div>
    </div>
    <div mat-dialog-actions [align]="'end'">
        <button mat-button (click)="cancel()">{{'CANCEL' | translate}}</button>
        <button *ngIf="!data.workflowDefinition" mat-raised-button color="primary" (click)="createWorkflow()"
            [disabled]="!workflowForm.valid" disabled="disabled" mat-button>{{'CREATE' | translate}}</button>
        <button *ngIf="data.workflowDefinition" mat-raised-button color="primary" (click)="updateWorkflow()"
            [disabled]="!workflowForm.valid" disabled="disabled" mat-button>{{'UPDATE' | translate}}</button>
    </div>
</form>
