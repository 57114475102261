<mat-toolbar color="primary" class="demo-toolbar">
    <mat-toolbar-row>
            <div>MM View {{listEntityTableDto.entityDefinitionLabel}}s</div>
    </mat-toolbar-row>
</mat-toolbar>

<ngx-datatable
  *ngIf="listEntityTableDto.tableDto && rows && rows.length > 0"
  class="material has-plus-btn"
  [rows]="rows"
  [columnMode]="'force'"
  [columns]='columns'
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="'auto'"
  [selected]="selected"
  [selectionType]="'single'"
  [externalPaging]="true"
  [count]="page.totalElements"
  [offset]="page.pageNumber"
  [limit]="page.size"
  (activate)="onActivate($event)"
  (select)="onSelect($event)"
  (page)='setPage($event)'>

</ngx-datatable>
<ng-template #editTmpl let-row="row" let-value="value">
  <mat-progress-bar *ngIf="value.elementType && value.elementType.toString() === 'PROGRESS_BAR_DTO'" mode="determinate" color="warning" value="{{value.progressBarDto.progressPercent}}"></mat-progress-bar>
  <div *ngIf="value.elementType && value.elementType.toString() === 'STRING_VALUE'">{{value.sttringValueDto.strValue | translate}}</div>
</ng-template>


<button mat-fab color="accent" class="mat-fab-bottom-right" [disabled]="trialExpiredService.isTrialExpired()" (click)="create()" style="z-index: 9;">
    <mat-icon class="mat-24">add</mat-icon>
</button>
