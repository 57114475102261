import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ContextIdDto } from '@savvy/app';
import { CheckinFormDefinition, CheckinFormDefsService } from '@savvy/check-in-form';
import { Link } from '@savvy/messaging';
import { Subscription } from 'rxjs';
import { ContextService } from '../../../context.service';
import {LinkDetails, LinkSelectedEvent} from '../../templateLinkEvents';

@Component({
  selector: 'app-quick-checkin-form-view',
  templateUrl: './quick-checkin-form-view.component.html',
  styleUrls: ['./quick-checkin-form-view.component.scss']
})
export class QuickCheckinFormViewComponent implements OnInit, OnDestroy {

  @Input() set selectedItem(selectedItem: any) {
    this._selectedItem = selectedItem;
  }

  @Output() onAction = new EventEmitter<LinkSelectedEvent>();

  checkinFormsList: LinkDetails[] = [];
  dataLoading = false;
  contextIdDto: ContextIdDto;
  subscriptions: Subscription[] = [];

  private _selectedItem: any;

  constructor(private contextService: ContextService,
    private api: CheckinFormDefsService) { }

  ngOnInit(): void {
    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
        this.loadCheckinForms();
      }
    }));
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  loadCheckinForms() {
    this.checkinFormsList = [];
    this.dataLoading = true;
    this.api.loadCheckinFormDefs(this.contextIdDto.contextId, this.contextIdDto.contextIdType)
      .subscribe((response: CheckinFormDefinition[]) => {
        this.checkinFormsList = response.map(v => ({
          type: '',
          label: v.name,
          subLabel: '',
          id: v.id,
          data: v,
        }));
      }, () => { }, () => {
        this.dataLoading = false;
      });
  }

  onCheckInFormSelect(data: LinkSelectedEvent) {
    console.log('onCheckInFormSelect', data);
    if (!this.onAction) {
      return;
    }
    const data1: LinkSelectedEvent = {
      ...data
    };
    data1.type = 'SEND_CHECKIN_FORM';
    console.log('emmiting data', data1);
    this.onAction.emit(data1);
  }

}
