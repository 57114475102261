import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ChangeListener } from './changeListener';
import { EventBusService } from './EventBusService';
import { Router } from '@angular/router';
import { ContextIdDto } from '@savvy/quickbooks';
import {ViewContextDto, ViewEntityPanelDataDto} from '@savvy/view-composite';

@Component({
  selector: 'app-view-entity-panel',
  templateUrl: 'viewEntityPanel.component.html',
  styleUrls: ['viewEntityPanel.component.scss', 'commonPanel.component.scss']
})
export class ViewEntityPanelComponent {


  @Input() viewEntityPanelDataDto: ViewEntityPanelDataDto;
  @Input() contextIdDto: ContextIdDto;
  @Input() viewContext: ViewContextDto;
  @Input() form: UntypedFormGroup;
  @Input() eventBus: EventBusService;
  @Input() additionalDataMapDto: any;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;

  constructor(private router: Router) { }

  editEntity() {
    if (this.viewEntityPanelDataDto.entityInstanceId) {
      this.router.navigate(['/entity/editEntity',
        this.viewEntityPanelDataDto.entityInstanceId.id,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType]);
    }
  }

  viewEntity() {
    console.log('inside view entity');
    if (this.viewEntityPanelDataDto.entityInstanceId) {
      console.log('navigating to ', this.viewEntityPanelDataDto.entityInstanceId.id);
      this.router.navigate(['/entity/viewEntity',
        this.viewEntityPanelDataDto.entityInstanceId.id,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType]);
    } else {
      console.log('viewEntity ei id is null ', this.viewEntityPanelDataDto);
    }
  }
}

