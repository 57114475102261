<!-- <mat-toolbar>{{'View Invoices' | translate}}</mat-toolbar> -->

<div *ngIf="invoices && invoices.length > 0">
  <form [formGroup]="filterForm" class="mdc-mb-2">
    <div fxLayout="row wrap" fxLayoutGap="16px" fxLayoutAlign=" center">
      <div fxFlex>
        <mat-form-field appearance="outline" class="w-100 mb-0">
          <input matInput placeholder="{{'SEARCH_INVOICE_NUMBER' | translate}}" formControlName="invoiceNumber" autofocus required>
        </mat-form-field>
      </div>
      <div fxFlex="nogrow">
        <button color="accent" class="mr-5px" (click)="filter()" mat-raised-button>{{'FILTER' | translate}}</button>&nbsp;&nbsp;
        <button (click)="reset()" mat-raised-button>{{'RESET' | translate}}</button>
      </div>
    </div>
  </form>

  <ngx-datatable class="material payments-tab-view" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="50" [selectionType]="'single'" [selected]="selected" [rows]="invoices" (page)='setPage($event)' [externalPaging]="true" [count]="page.totalElements" [offset]="page.pageNumber" (sort)="onSort($event)" [loadingIndicator]="loading" [limit]="page.size" (select)="onSelect($event)">

    <ngx-datatable-column name="{{'INVOICE_DATE' | translate}}" [width]="100">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
        {{row.invoiceDate | localizedDate:'medium'}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'INVOICE_NO' | translate}}" [width]="80">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
        {{row.invoiceNumberLong}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'AMOUNT' | translate}}" [width]="10">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
        <b *ngIf="row?.total">{{row?.total | currency:currencyCode:'symbol'}}</b>
        <br *ngIf="row?.total">
        <div class="appointment-status" *ngIf="row?.total">
          <div *ngIf="row?.paidInFull" class="status prepaid-status d-inline-block">{{'PAID' | translate}}
          </div>
          <div *ngIf="!row?.paidInFull" class="status unpaid-status d-inline-block">{{'UNPAID' | translate}}
          </div>
        </div>
      </ng-template>

    </ngx-datatable-column>
    <ngx-datatable-column name="{{'CUSTOMER' | translate}}" [width]="10">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
        {{row.invoiceOwnerFirstName}} {{row.invoiceOwnerLastName}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [width]="10">
      <ng-template ngx-datatable-cell-template let-row="row">
        <button mat-button color="accent" *ngIf="!row.invoiceVoid" (click)="voidInvoice(row)"><mat-icon>block</mat-icon> {{'VOID' | translate}}</button>
        <button mat-button color="accent" *ngIf="row.invoiceVoid" (click)="unvoidInvoice(row)"><mat-icon>restore_page</mat-icon> {{'UNVOID' | translate}}</button>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>

</div>

<div class="flexbox-parent" *ngIf="invoices && invoices.length === 0">
  <div fxLayout="row" fxLayoutAlign="center center">
    <div style="text-align:center;">
      <div class="avatar avatar-100 circle border-3 mb-2 d-inline-block">
        <mat-icon class="h1 h-auto w-auto">receipt</mat-icon>
      </div>
      <!-- <app-zero-image name="payment" class="zero-image"></app-zero-image><br> -->
      <h6 class="ma-0">{{'DONOT_HAVE_ANY_INVOICES' | translate}}</h6><br>
    </div>
  </div>
</div>