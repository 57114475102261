import { Component, Input, OnInit } from '@angular/core';
import { ContextIdDto } from '@savvy/app';
import { Appointment } from '@savvy/appointment';
import { DataShareService } from 'src/app/core/data-share.service';
import { CreateAppointmentV2SyncService } from '../../create-appointment-v2/create-appointment-v2-sync.service';
import { CreatePetAppointmentV2SyncService } from '../../create-pet-appointment-v2/create-pet-appointment-v2-sync.service';
import { WorkflowDefinition } from '@savvy/workflow-definition';

@Component({
  selector: 'app-workflow-selector-v2',
  templateUrl: './workflow-selector-v2.component.html',
  styleUrls: ['./workflow-selector-v2.component.scss']
})
export class WorkflowSelectorV2Component implements OnInit {
  @Input() contextIdDto: ContextIdDto;
  @Input() appointment: Appointment;
  workflowDefinitions: Array<WorkflowDefinition> = [];
  workflowDefinition: WorkflowDefinition;

  constructor(
    private dataShareService: DataShareService,
    private createPetAppointmentV2SyncService: CreatePetAppointmentV2SyncService,
    private createAppointmentV2SyncService: CreateAppointmentV2SyncService
  ) { }

  ngOnInit() {
    if (this.dataShareService.workflowDefinitions.length) {
      this.workflowDefinitions = this.dataShareService.workflowDefinitions;
    }
    if (this.workflowDefinitions.length) {
      if (this.appointment) {
        this.workflowDefinition = this.workflowDefinitions.find(l => l.id === this.appointment.workflowDefinitionId);
      } else {
        this.workflowDefinition = this.workflowDefinitions[0];
      }
      this.workflowDefChanged(this.workflowDefinition);
    }
  }

  workflowDefChanged(workflowDefinition: WorkflowDefinition) {
    // this.createPetAppointmentV2SyncService.selectedWorkflowDefinition = workflowDefinition;
    this.createAppointmentV2SyncService.selectedWorkflowDefinition = workflowDefinition;
  }


}
