import {Injectable} from '@angular/core';
import {ProductMetadata} from '@savvy/product-metadata';

@Injectable()
export class ProductMetaLocalService {
  productMetadata: ProductMetadata;
  set(productMetadata) {
    this.productMetadata = productMetadata;
  }
}
