<mat-form-field appearance="outline" class="w-100">
  <mat-label>{{'SERVICE' | translate: {Default: 'Service'} }}</mat-label>
  <mat-select placeholder="{{'SELECT_SERVICE' | translate: {Default: 'Select Service'} }}" [(ngModel)]="selectedService"
    required name="serviceField" #serviceField="ngModel" (selectionChange)="serviceChanged($event)"
    [compareWith]="compareObjects" [disabled]="disabled">
    <mat-option>
      <ngx-mat-select-search [placeholderLabel]="'SEARCH_SERVICE'" ngModel (ngModelChange)="filterServices($event)">
      </ngx-mat-select-search>
    </mat-option>
    <mat-option [value]="''">
      <button mat-flat-button color="accent" (click)="createNewService()">&nbsp;&nbsp;
        {{'ADD_SERVICE' | translate: {Default: 'Add Service'} }}
      </button>
    </mat-option>

    <mat-option *ngFor="let service of filteredServices | async" [value]="service">
      {{ service?.serviceName }}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="serviceField.hasError('required')">
    {{'SERVICE_IS' | translate: {Default: 'Service is'} }} <strong>{{'REQUIRED' | translate: {Default: 'required'}
      }}</strong>
  </mat-error>
  <mat-icon *ngIf="selectedService?.deleted" matSuffix class="mat-text-red"
    matTooltip="{{selectedService?.serviceName}} with id {{selectedService.id}} has been deleted"
    matTooltipClass="tooltip-red" class="vm mat-text-red">error_outline</mat-icon>
</mat-form-field>