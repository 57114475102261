/* eslint-disable @typescript-eslint/member-ordering */
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AppointmentService, GenerateRepeatAppointmentDates } from '@savvy/appointment';
import { ContextIdDto } from '@savvy/menu';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { ContextService } from 'src/app/flo/context.service';
import { LookAndFeelSharedService, TimeValue } from 'src/app/flo/shared/services/look-and-feel-shared.service';
import { AppointmentDate } from '@savvy/appointment/model/appointmentDate';
import { DateUtils } from 'src/app/flo/dates/DateUtils';
import * as uuid from 'uuid';
import { DateAdapter } from '@angular/material/core';
import { LocaleService } from 'src/app/services/locale.service';
import { LanguageService } from 'src/app/services/language.service';

export interface RepeatDates {
  repeatFilter: RepeatDatesFilter;
  appointmentDates: AppointmentDateLocal[];
}

export interface RepeatDatesFilter {
  frequency: string;
  numAppointments: string;
  period: GenerateRepeatAppointmentDates.PeriodEnum;
  selectedDay: string;
  startFrom: string;
  startDate: string;
  startTime: string;
  endTime: string;
}

export interface AppointmentDateLocal {
  id: string;
  localStartDate: Date;
  startTime: string;
  endTime: string;
  slotSelected: boolean;
  conflict: boolean;
  clashStr: string;
  overrideConflictCheck: boolean;
  differenceInHours: number; // Used when the slot is moved upwards or downwards in the calendar to calculate the difference and adjust the service times accordingly
}

@Component({
  selector: 'app-repeat-filter',
  templateUrl: './repeat-filter.component.html',
  styleUrls: ['./repeat-filter.component.scss']
})
export class RepeatFilterComponent implements OnInit {
  @ViewChild('datePickerInput', { static: false }) datePickerInput: ElementRef;

  @Input() selectedDay: string;
  @Input() startTime: string;

  private _startDate: string;
  @Input()
  public get startDate(): string {
    return this._startDate;
  }
  public set startDate(value: string) {
    this._startDate = value;
    if (this.appointmentDates.length) {
      this.generateDates();
    }
  }


  @Output() generated: EventEmitter<RepeatDates> = new EventEmitter();

  subscriptions: Subscription[] = [];
  contextIdDto: ContextIdDto;
  repeatFilterForm = new UntypedFormGroup({
    frequency: new UntypedFormControl(6, [Validators.required]),
    numAppointments: new UntypedFormControl(4, [Validators.required]),
    period: new UntypedFormControl(GenerateRepeatAppointmentDates.PeriodEnum.Weeks, [Validators.required]),
    selectedDay: new UntypedFormControl('SUNDAY', [Validators.required]),
    startFrom: new UntypedFormControl('TODAY', [Validators.required]),
    startDate: new UntypedFormControl(null),
    startTime: new UntypedFormControl(null, [Validators.required])
  });
  timeArray: TimeValue[] = [];
  isFilterValid = true;

  appointmentLocalDates: AppointmentDateLocal[] = [];
  appointmentDates: AppointmentDate[] = [];
  periods = Object.values(GenerateRepeatAppointmentDates.PeriodEnum);
  inProgress = false;

  constructor(
    private dateUtils: DateUtils,
    private contextService: ContextService,
    private appointmentService: AppointmentService,
    private lookAndFeelService: LookAndFeelSharedService,
    private dateAdapter: DateAdapter<any>,
    private languageService: LanguageService,
    private eRef: ElementRef,
  ) {

  }

  ngOnInit(): void {
    this.dateAdapter.setLocale(this.languageService.currentLocale);
    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
        if (this.selectedDay) {
          this.repeatFilterForm.patchValue({
            selectedDay: this.selectedDay
          });
        }

        this.getLookAndFeelConfig();
      }
    }));
  }

  getLookAndFeelConfig() {
    const lookAndFeelConfig = this.lookAndFeelService.getLookAndFeelConfig(this.contextIdDto);

    if (lookAndFeelConfig) {
      this.timeArray = lookAndFeelConfig.timeArray;
      if (this.timeArray?.length) {
        const index = this.timeArray.findIndex(time => time.actualValue === '09:00');
        if (index !== -1) {
          this.repeatFilterForm.patchValue({
            startTime: this.timeArray[index]
          });
        } else {
          this.repeatFilterForm.patchValue({
            startTime: this.timeArray[0]
          });
        }
        if (this.startTime) {
          this.repeatFilterForm.patchValue({
            startTime: this.startTime
          });
        }
      }
    }
  }

  getStartDate() {
    let startDate = new Date();
    if (this.startDate) {
      startDate = new Date(this.startDate);
    }
    if (this.repeatFilterForm?.value?.startFrom) {
      switch (this.repeatFilterForm?.value?.startFrom) {
        case 'TODAY':
          // if (this.repeatFilterForm?.value?.period === GenerateRepeatAppointmentDates.PeriodEnum.Days) {
          //   this.repeatFilterForm.value.startTime = this.dateUtils.addMinsToTimeString(this.repeatFilterForm.value.startTime, 60);
          // } else {
          startDate = moment(this.startDate).startOf('day').toDate();
          // }
          break;
        case 'NEXT_WEEK':
          startDate = moment().add(1, 'weeks').startOf('isoWeek').toDate();
          break;
        case 'SPECIFY_DATE':
          if (this.repeatFilterForm?.value?.startDate) {
            startDate = new Date(this.repeatFilterForm?.value?.startDate);
          }
          break;
        default:
          startDate = new Date();
          break;
      }
    }
    return startDate;
  }

  getDayNumber(day: string) {
    switch (day) {
      case 'SUNDAY':
        return 0;
      case 'MONDAY':
        return 1;
      case 'TUESDAY':
        return 2;
      case 'WEDNESDAY':
        return 3;
      case 'THURSDAY':
        return 4;
      case 'FRIDAY':
        return 5;
      case 'SATURDAY':
        return 6;
    }
  }

  getNextInstanceDay(startDate) {
    if (this.repeatFilterForm?.value?.period !== GenerateRepeatAppointmentDates.PeriodEnum.Days) {
      const dayINeed = this.getDayNumber(this.repeatFilterForm?.value?.selectedDay);
      const today = moment(startDate).isoWeekday();
      if (today <= dayINeed) {
        return moment(startDate).isoWeekday(dayINeed).toDate();
      } else {
        return moment(startDate).add(1, 'weeks').isoWeekday(dayINeed).toDate();
      }
    } else {
      return moment(startDate).toDate();
    }
  }

  generateDates() {
    this.inProgress = true;
    let startDateTemp = this.getStartDate();
    startDateTemp = this.getNextInstanceDay(startDateTemp);
    const startDate = moment(startDateTemp).format('yyyy-MM-DD');
    const generateRepeatAppointmentDates: GenerateRepeatAppointmentDates = {
      numAppointments: this.repeatFilterForm?.value?.numAppointments,
      frequency: this.repeatFilterForm?.value?.frequency,
      period: this.repeatFilterForm?.value?.period,
      startDate,
      startTime: this.repeatFilterForm?.value?.startTime,
    };
    this.appointmentService.loadRepeatDates(generateRepeatAppointmentDates).subscribe((response) => {
      this.setInProgressOff();
      this.appointmentDates = response;
      this.appointmentLocalDates = new Array();

      for (const appointment of this.appointmentDates) {
        // @ts-ignore - ??
        const appointmentLocalDate = <AppointmentDateLocal>{};
        appointmentLocalDate.id = '_' + uuid.v4().replace(/-/g, '_');
        appointmentLocalDate.localStartDate =
          moment(this.dateUtils.getCombinedDateObject(appointment.startDate, appointment.startTime)).toDate();
        appointmentLocalDate.startTime = appointment.startTime;
        appointmentLocalDate.slotSelected = false;
        appointmentLocalDate.conflict = false;
        this.appointmentLocalDates.push(appointmentLocalDate);
      }
      const result = <RepeatDates>{};
      result.repeatFilter = this.repeatFilterForm?.value;
      result.appointmentDates = this.appointmentLocalDates;

      this.generated.emit(result);
    }, error => {
      this.setInProgressOff();
      const result = {
        repeatFilter: this.repeatFilterForm?.value,
        appointmentDates: []
      };
      this.generated.emit(result);
    });
  }

  setInProgressOff() {
    setTimeout(() => {
      this.inProgress = false;
    }, 2000);
  }

  compareTimeFn(user1: string, user2: string) {
    return user1 && user2 && user1 === user2;
  }

  startFromChanged() {
    if (this.repeatFilterForm?.value?.startFrom) {
      switch (this.repeatFilterForm?.value?.startFrom) {
        case 'SPECIFY_DATE':
          this.repeatFilterForm.get('startDate').setValidators([Validators.required]);
          break;
        default:
          this.repeatFilterForm.get('startDate').clearValidators();
      }
    }
  }

  closedStream($event) {
    const focusoutEvent = new Event('focusout');
    this.eRef.nativeElement.dispatchEvent(focusoutEvent);
    this.datePickerInput.nativeElement.dispatchEvent(focusoutEvent);
  }

}
