import { Routes } from '@angular/router';
import { EntityFlagDefsComponent } from './entityFlagDefs.component';
import { AddEditFlagDefComponent } from './addEditFlagDef.component';
import { FlagDefsByTypeComponent } from './flagDefsByType/flagDefsByType.component';

export const FlagsRoutes: Routes = [
  {
    path: '',
    children: [{
      path: 'flagDefsByType/:flagDefinitionType/:contextId/:contextIdType',
      component: FlagDefsByTypeComponent
    },
    {
      path: 'entityFlagDefs/:entityDefinitionId/:contextId/:contextIdType',
      component: EntityFlagDefsComponent
    },
    {
      path: 'editFlagDef/:contextId/:contextIdType/:flagDefinitionId', component: AddEditFlagDefComponent,
      data: {
        breadcrumb: 'Edit Flag Def'
      }
    },
    {
      path: 'addEditFlagDef/:contextId/:contextIdType/:flagDefinitionType', component: AddEditFlagDefComponent,
      data: {
        breadcrumb: 'Add Flag Def'
      }
    },
    {
      path: 'addEditFlagDef/:contextId/:contextIdType/:flagDefinitionType/:entityDefinitionId', component: AddEditFlagDefComponent,
      data: {
        breadcrumb: 'Add Flag Def'
      }
    },
    ]
  }
];
