<ng-container *ngIf="!deviceService.isMobile()">
  <mat-card>
    <mat-card-content>
      <div fxLayout="row" class="petservice"
        *ngFor="let customer of createAppointmentV2SyncService.customersSelected;let customerRowIndex = index;">

        <div fxFlex="100" fxLayout="column">
          <div fxLayout="row" fxLayoutGap="8px"
            *ngFor="let package of createAppointmentV2SyncService.selectedPackages[customer.id]; let rowIndex = index; let last = last">
            <div fxFlex="100">
              <div fxLayout="row" fxLayout.gt-sm="row" fxLayoutGap="8px">
                <div fxFlex>
                  <app-package-selector-v2 #packageSelectorComponent [contextIdDto]="contextIdDto"
                    [selectedPackage]="package.selectedPackage"
                    (packageSelectionChanged)="packageSelected($event, customer.id, rowIndex, package)"
                    class="field-center w-100" [disabled]="disabled">
                  </app-package-selector-v2>
                </div>
                <div fxFlex="12">
                  <mat-form-field appearance="outline" *ngIf="package.selectedPackage" class="field-center w-100">
                    <mat-label>{{'PRICE' | translate: {Defualt: 'Price'} }}</mat-label>
                    <input matInput type="text" appNumberOnly [decimalPoints]="2"
                      placeholder="{{'UNIT_PRICE' | translate: {Default: 'Unit Price'} }}"
                      name="packagePrice{{customerRowIndex}}{{rowIndex}}"
                      #packagePrice{{customerRowIndex}}{{rowIndex}}="ngModel"
                      [(ngModel)]="package.selectedPackage.packagePrice"
                      (ngModelChange)="updateUnitPrice($event, package)"
                      id="packagePrice{{customerRowIndex}}{{rowIndex}}" required [disabled]="disabled">
                    <!-- <button *ngIf="customer" mat-icon-button color="accent"
                  (mouseover)="getLastSeenPrice(customer, package.selectedPackage)"
                  matTooltip="Last Price: {{lastPrice || 'N/A'}}" matTooltipPosition="above" matSuffix>
                  <mat-icon>info</mat-icon>
                </button> -->
                  </mat-form-field>
                </div>
                <div fxFlex="20" *ngIf="taxDefs && taxDefs.length">
                  <mat-form-field appearance="outline" class="field-center w-100">
                    <mat-label>{{'TAX' | translate: {Default: 'Tax'} }}</mat-label>
                    <mat-select placeholder="Select tax rate" [(ngModel)]="package.selectedPackage.tax"
                      name="taxEntityInstanceId" #taxEntityInstanceId="ngModel"
                      (ngModelChange)="updateGrossPrice(package)" [disabled]="disabled">
                      <mat-option *ngFor="let tax of taxDefs" [value]="tax.taxRate">
                        {{ tax.taxName }} ( {{ tax.taxRate }} %)
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div fxFlex="15" *ngIf="taxDefs && taxDefs.length">
                  <mat-form-field appearance="outline" *ngIf="package.selectedPackage" class="field-center w-100">
                    <mat-label>{{'GROSS_PRICE' | translate: {Default: 'Gross Price'} }}</mat-label>
                    <input matInput type="text" appNumberOnly [decimalPoints]="2"
                      placeholder="{{'GROSS_PRICE' | translate: {Default: 'Gross Price'} }}"
                      name="grossPrice{{customerRowIndex}}{{rowIndex}}"
                      #grossPrice{{customerRowIndex}}{{rowIndex}}="ngModel" [(ngModel)]="package.grossPrice"
                      (ngModelChange)="createAppointmentV2SyncService.calculateTotalAmount()"
                      id="grossPrice{{customerRowIndex}}{{rowIndex}}" required [disabled]="disabled">
                  </mat-form-field>
                </div>

                <div fxFlex="15">
                  <app-staff-selector class="field-center" *ngIf="package.selectedPackage" [contextIdDto]="contextIdDto"
                    [selectedStaff]="package.selectedPackageUser"
                    [selectedLocationId]="createAppointmentV2SyncService?.selectedLocation?.id"
                    (staffSelectionChanged)="packageUserSelected($event, customer.id, rowIndex)">
                  </app-staff-selector>
                </div>
                <div fxFlex="15">
                  <mat-form-field *ngIf="timeArray && timeArray.length > 0" appearance="outline"
                    class="field-center w-100">
                    <mat-label>{{'START_TIME' | translate: {Default: 'Start Time'} }}</mat-label>
                    <mat-select placeholder="{{'SELECT_START_TIME' | translate: {Default: 'Select Start Time'} }}"
                      (selectionChange)="startTimeChanged($event.value, package)" [(ngModel)]="package.startTime"
                      required>
                      <mat-option *ngFor="let time of timeArray" [value]="time.actualValue">{{time.displayValue}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div fxFlex="15">
                  <mat-form-field *ngIf="timeArray && timeArray.length > 0" appearance="outline"
                    class="field-center w-100">
                    <mat-label>{{'END_TIME' | translate: {Default: 'End Time'} }}</mat-label>
                    <mat-select placeholder="{{'SELECT_END_TIME' | translate: {Default: 'Select End Time'} }}"
                      [(ngModel)]="package.endTime" required>
                      <mat-option *ngFor="let time of timeArray" [value]="time.actualValue">{{time.displayValue}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </mat-card-content>
  </mat-card>
</ng-container>

<ng-container *ngIf="deviceService.isMobile()">
  <div fxLayout="row wrap" class="petservice mdc-mb-1 rounded-10 border"
    *ngFor="let customer of createAppointmentV2SyncService.customersSelected;let customerRowIndex = index;">

    <!-- <hr> -->
    <div fxFlex="100" fxLayout="column">
      <div fxLayout="column" fxLayoutGap="5px" [ngClass]="{'border-top': rowIndex > 0}"
        *ngFor="let package of createAppointmentV2SyncService.selectedPackages[customer.id]; let rowIndex = index; let last = last">
        <div fxFlex="100">
          <div fxLayout="row wrap" fxLayoutGap="5px">
            <div fxFlex="100" fxFlex.gt-xs="46">
              <app-package-selector-v2 #serviceSelectorComponent [contextIdDto]="contextIdDto"
                [selectedPackage]="package.selectedPackage"
                (serviceSelectionChanged)="serviceSelected($event, customer.id, rowIndex,package)"
                class="field-center w-100">
              </app-package-selector-v2>
            </div>
            <div fxFlex="47" fxFlex.gt-xs="25">
              <mat-form-field appearance="outline" *ngIf="package.selectedPackage" class="field-center w-100">
                <mat-label>{{'PRICE' | translate: {Default: 'Price'} }}</mat-label>
                <input matInput type="text" appNumberOnly [decimalPoints]="2"
                  placeholder="{{'UNIT_PRICE' | translate: {Default: 'Unit Price'} }}"
                  name="packagePrice{{customerRowIndex}}{{rowIndex}}"
                  #packagePrice{{customerRowIndex}}{{rowIndex}}="ngModel"
                  [(ngModel)]="package.selectedPackage.packagePrice" (ngModelChange)="updateUnitPrice($event, package)"
                  id="packagePrice{{customerRowIndex}}{{rowIndex}}" required>
                <button *ngIf="customer" mat-icon-button color="accent"
                  (mouseover)="getLastSeenPrice(customer, package.selectedPackage)"
                  matTooltip="Last Price: {{lastPrice || 'N/A'}}" matTooltipPosition="above" matSuffix>
                  <mat-icon>info</mat-icon>
                </button>
              </mat-form-field>
            </div>
            <div fxFlex="50" fxFlex.gt-xs="25" *ngIf="taxDefs && taxDefs.length">
              <mat-form-field appearance="outline" class="field-center w-100">
                <mat-label>{{'TAX' | translate: {Default: 'Tax'} }}</mat-label>
                <mat-select placeholder="Select tax rate" [(ngModel)]="package.selectedPackage.tax"
                  name="taxEntityInstanceId" #taxEntityInstanceId="ngModel" (ngModelChange)="updateGrossPrice(package)">
                  <mat-option *ngFor="let tax of taxDefs" [value]="tax.taxRate">
                    {{ tax.taxName }} ( {{ tax.taxRate }} %)
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxFlex="47" fxFlex.gt-xs="47">
              <mat-form-field appearance="outline" *ngIf="package.selectedPackage" class="field-center w-100">
                <mat-label>{{'GROSS_PRICE' | translate: {Default: 'Gross Price'} }}</mat-label>
                <input matInput type="text" appNumberOnly [decimalPoints]="2"
                  placeholder="{{'GROSS_PRICE' | translate: {Default: 'Gross Price'} }}"
                  name="grossPrice{{customerRowIndex}}{{rowIndex}}"
                  #grossPrice{{customerRowIndex}}{{rowIndex}}="ngModel" [(ngModel)]="package.grossPrice"
                  (ngModelChange)="createAppointmentV2SyncService.calculateTotalAmount()"
                  id="grossPrice{{customerRowIndex}}{{rowIndex}}" required>
              </mat-form-field>
            </div>

            <div fxFlex="100" fxFlex.gt-xs="50">
              <app-staff-selector class="field-center" *ngIf="package.selectedPackage" [contextIdDto]="contextIdDto"
                [selectedStaff]="package.selectedPackageUser"
                [selectedLocationId]="createAppointmentV2SyncService?.selectedLocation?.id"
                (staffSelectionChanged)="packageUserSelected($event, customer.id, rowIndex)">
              </app-staff-selector>              
            </div>
            <div fxFlex="47" fxFlex.gt-xs="50">
              <mat-form-field *ngIf="timeArray && timeArray.length > 0" appearance="outline" class="field-center w-100">
                <mat-label>{{'START_TIME' | translate: {Default: 'Start Time'} }}</mat-label>
                <mat-select placeholder="{{'SELECT_START_TIME' | translate: {Default: 'Select Start Time'} }}"
                  (selectionChange)="startTimeChanged($event.value, package)" [(ngModel)]="package.startTime" required>
                  <mat-option *ngFor="let time of timeArray" [value]="time.actualValue">{{time.displayValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxFlex="50" fxFlex.gt-xs="50">
              <mat-form-field *ngIf="timeArray && timeArray.length > 0" appearance="outline" class="field-center w-100">
                <mat-label>{{'END_TIME' | translate: {Default: 'End Time'} }}</mat-label>
                <mat-select placeholder="{{'SELECT_END_TIME' | translate: {Default: 'Select End Time'} }}"
                  [(ngModel)]="package.endTime" required>
                  <mat-option *ngFor="let time of timeArray" [value]="time.actualValue">{{time.displayValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</ng-container>