<mat-card appearance="outlined" class="new-card py-1">
  <div fxLayout="row" fxLayoutWrap="wrap">
    <div *ngIf="totalAmount" fxFlex.gt-sm="30" fxFlex.gt-xs="30" fxFlex="100" class="mt-1 mdc-mb-1">
      <h6 class="text-uppercase text-md ma-0 text-bold">{{'TOTAL_AMOUNT' | translate}}</h6>
      <h1 class="ma-0">{{totalAmount | currency:currencyCode:'symbol'}}</h1>
    </div>
    <div fxFlex.gt-sm="30" fxFlex.gt-xs="30" fxFlex="100" class="mt-1 mdc-mb-1">
      <h6 class="text-uppercase text-md ma-0 text-bold">{{'TOTAL_AMOUNT_PAID' | translate}}</h6>
      <h1 class="ma-0">{{totalPaidAmount | currency:currencyCode:'symbol'}}</h1>
    </div>
    <div fxFlex.gt-sm="30" fxFlex.gt-xs="30" fxFlex="100" class="mt-1 mdc-mb-1 text-xs-left text-sm-right">
      <h6 class="text-uppercase text-sm ma-0 text-bold">{{'TOTAL_AMOUNT_REQUESTED' | translate}}</h6>
      <h1 class="ma-0 mat-text-accent">{{ totalPendingAmount | currency:currencyCode:'symbol'}}</h1>
    </div>
  </div>
</mat-card>