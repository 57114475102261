import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ChangeListener } from '../changeListener';
import { RecurringModalComponent } from '../../shared/components/recurring/recurring.modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { Observable, Subscription } from 'rxjs';
import {
  ContextIdDto, EicompService,
  ElementInstanceDto, EntityChangeEvent,
  FieldRepeatableValue,
  FieldValueDto,
  RepeatableEventDefinition
} from '@savvy/entity-instance-composite';
import {CreateSeries, Series, SeriesService, UpdateSeries} from '@savvy/series';
import {AdditionalDataMapDto, ViewContextDto} from '@savvy/view-composite';
import {ElementControlService} from '../ElementControlService';
import {DateUtils} from '../../dates/DateUtils';

@Component({
  selector: 'app-repeatable-event-instance',
  templateUrl: 'repeatableEventInstance.component.html'
})
export class RepeatableEventInstanceComponent implements OnInit, OnDestroy {

  @Input() repeatableEventDefinition: RepeatableEventDefinition;
  @Input() elementInstanceDto: ElementInstanceDto;
  @Input() entityInstanceId: string;
  @Input() contextIdDto: ContextIdDto;
  @Input() viewContext: ViewContextDto;
  @Input() additionalDataMapDto: AdditionalDataMapDto;
  @Input() form: UntypedFormGroup;
  @Input() showPlaceholder: boolean;

  @Input() changeListener: ChangeListener;

  dialogRef: MatDialogRef<RecurringModalComponent>;

  dialogChange: Subject<any> = new Subject<any>();

  dialogChangeSubscription: Subscription;

  series: Series;

  constructor(
    private api: EicompService,
    private ecs: ElementControlService,
    public dialog: MatDialog,
    private dateUtils: DateUtils,
    private seriesApi: SeriesService) {
  }

  get isValid() {
    // return this.form.controls[this.elementInstanceDto.instanceId].valid;
    return true;
  }

  ngOnInit(): void {


    this.dialogChangeSubscription = this.dialogChange.subscribe(() => {
      if (this.dialogRef && this.dialogRef.componentInstance && this.dialogRef.componentInstance.series) {
        this.onChange(
          this.dialogRef.componentInstance.series,
          this.dialogRef.componentInstance.dateTimeRange,
          this.dialogRef.componentInstance.data.fieldRepeatableValue.enableRecurring
        );
      }
    });
    //  console.log('elementInstanceDto instance id is ' + this.elementInstanceDto.instanceId);
    //  console.log('elementInstanceDto;', this.form.controls[this.elementInstanceDto.instanceId]);
    /*
            this.form.controls[this.elementInstanceDto.instanceId].valueChanges.subscribe(function (value) {
                // console.log('changed value=' + value);
                this.onChange(value);
            });*/
  }

  ngOnDestroy() {
    this.dialogChangeSubscription.unsubscribe();
  }

  openRecurrningPopup(open: boolean) {
    if (open) {
      // check if we have seriesId
      if (!this.series && this.elementInstanceDto.fieldInstanceDto.value.fieldRepeatableValue.seriesId &&
        this.elementInstanceDto.fieldInstanceDto.value.fieldRepeatableValue.seriesId.id) {
        // if yes get series by id and open popup
        this.seriesApi.loadSeries(
          this.elementInstanceDto.fieldInstanceDto.value.fieldRepeatableValue.seriesId.id,
          this.contextIdDto.contextId,
          this.contextIdDto.contextIdType
        ).subscribe(res => {
          this.series = res;
          this.openPopup();
        });
      } else {
        this.openPopup();
      }
    }
  }

  openPopup() {
    this.dialogRef = this.dialog.open(RecurringModalComponent, {
      width: '490px',
      height: 'auto',
      // panelClass: 'mobile-view-subscription-dialog',
      data: {
        fieldRepeatableValue: { ...this.elementInstanceDto.fieldInstanceDto.value.fieldRepeatableValue },
        series: this.series,
        dialogChange: this.dialogChange
      }
    });
    const afterClosedSubscription = this.dialogRef.afterClosed().subscribe(res => {
      if (res && res.enableRecurring === false) {
        this.elementInstanceDto.fieldInstanceDto.value.fieldRepeatableValue.enableRecurring = false;
      }
      afterClosedSubscription.unsubscribe();
    });
  }

  onChange(series: Series, dateTimeRange: Date[], enabled = true) {
    const createSeriesReq: CreateSeries | UpdateSeries = {};
    if (series && dateTimeRange) {
      console.log(dateTimeRange);
      createSeriesReq.contextIdDto = this.contextIdDto;
      createSeriesReq.series = {
        numRepeats: series.numRepeats,
        ownerId: this.contextIdDto.contextId,
        repeatEvery: series.repeatEvery,
        repeatEveryPeriod: series.repeatEveryPeriod,
        start: this.dateUtils.getDateTimeAsIsoString(dateTimeRange[0], true),
        end: this.dateUtils.getDateTimeAsIsoString(dateTimeRange[1], true),
        id: series.id
      };
      let seriesApiCall: Observable<Series>;
      // check Id for create or update
      if (createSeriesReq.series.id) {
        seriesApiCall = this.seriesApi.updateSeries(createSeriesReq);
      } else {
        seriesApiCall = this.seriesApi.createSeries(createSeriesReq);
      }
      seriesApiCall.subscribe(res => {
        this.series = res;
        this.updateEventInstance({enableRecurring: enabled, seriesId: {id: res.id}});
      });
    } else if (this.elementInstanceDto.fieldInstanceDto.value.fieldRepeatableValue.seriesId) {
      // if Id is there and nothing changed just update enabled
      this.updateEventInstance({
        enableRecurring: enabled,
        seriesId: this.elementInstanceDto.fieldInstanceDto.value.fieldRepeatableValue.seriesId
      });
    }
  }

  private updateEventInstance(repeatable: FieldRepeatableValue) {
    this.elementInstanceDto.fieldInstanceDto.value.fieldRepeatableValue = repeatable;
    const fieldValue: FieldValueDto = {
      fieldRepeatableValue: repeatable,
      valueType: FieldValueDto.ValueTypeEnum.RepeatableEvent
    };

    const req: EntityChangeEvent = {
      contextIdDto: this.contextIdDto,
      fieldValue,
      entityInstanceId: this.entityInstanceId,
      fieldInstanceId: this.elementInstanceDto.instanceId
    };

    // There is no loadProductsResponse from this, do we care?
    this.api.entityInstanceChangeEvent(req).subscribe(
      response => {
        console.log('response ' + response);
        this.ecs.handleChangedElements(response.changedElementList, this.form);
        if (this.changeListener) {
          this.changeListener.add('change');
        }
      }
    );
  }
}

