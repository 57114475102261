import { Location } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Contract, ContractService, ContractTemplateService, CreateContract } from '@savvy/contract';
import {
  CreateCustomerCompResponse,
  CreateCustomerComposite,
  Customer,
  CustomerCompositeService,
  CustomerService,
  UpdateCustomer
} from '@savvy/customer';
import { OnboardCustomer } from '@savvy/customer-onboarding';
import { DataListDto, DatalistService } from '@savvy/datalist';
import { Address } from '@savvy/entity-instance-composite';
import { PlansCompService, SubscribeToPlan } from '@savvy/plan';
import { ContextIdDto, LinkId, MenuDefinition } from '@savvy/ui';
import { OptIn, PhoneNumber } from '@savvy/user';
import * as _ from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxSpinnerService } from 'ngx-spinner';
import { LinkedIdTypeEnum } from 'src/app/core/data-share.service';
import { AddNewPetV2Component } from 'src/app/flo/appointments-v2/create-pet-appointment-v2/add-new-pet-v2/add-new-pet-v2.component';
import { APP_TYPE_ENUM } from 'src/app/flo/context.service';
import { UserCountryService } from '../../../services/userCountry.service';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { EventBusService } from '../../element/EventBusService';
import { NavChangeService } from '../../element/NavChangeService';
import { EventType } from '../../event/UiEvent';
import { OnboardCustomerComponent, OnboardCustomerEvent } from '../../shared/components/onboard-customer/onboardCustomer.component';
import { AppointmentConfigHelperService } from "../../shared/services/appointment-config-helper.service";
import { PhoneNumberHelperService } from '../../shared/services/phone-number-helper.service';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';
import { WindowRef } from '../../windowWrapper';
import MenuItemTypeEnum = MenuDefinition.MenuItemTypeEnum;
@Component({
  selector: 'app-add-customer',
  templateUrl: 'addCustomer.html',
  styleUrls: ['addCustomer.scss']
})
export class AddCustomerComponent implements OnInit {

  @ViewChild(AddNewPetV2Component, { static: false }) addNewPetV2Component: AddNewPetV2Component;
  @ViewChild(OnboardCustomerComponent, { static: false }) onboardCustomerComponent: OnboardCustomerComponent;

  @ViewChild('customerForm') customerForm: NgForm;

  @Input() contextIdDto: ContextIdDto;
  @Input() visible: boolean;
  @Input() eventBus: EventBusService;
  @Input() showBackToList = true;

  @Output() created = new EventEmitter();
  @Output() cancelClicked = new EventEmitter();

  model: Customer;

  customerSourceList: DataListDto;

  company = false;
  onboardCustomer = false;

  localPhones = {
    mobilePhoneNumberObj: {},
    phoneNumberObj: {},
    workPhoneNumberObj: {},
    whatsAppNumberObj: {}
  };

  customerIdLinkId: LinkId;

  onboard: OnboardCustomer;

  pageType: 'addCustomer' | 'addEditCustomer';
  isViewMode = false;
  showAddPet = false;
  createManually = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public appointmentConfigHelperService: AppointmentConfigHelperService,
    private dataListApi: DatalistService,
    private notify: FloSnackbarComponent,
    private windowRef: WindowRef,
    private translateService: TranslateService,
    private navChange: NavChangeService,
    private sharedService: PhoneNumberHelperService,
    private contractService: ContractService,
    private location: Location,
    private dialog: MatDialog,
    public deviceService: DeviceDetectorService,
    public userCountryService: UserCountryService,
    private planCompApi: PlansCompService,
    private contractTemplateService: ContractTemplateService,
    private ngxSpinnerService: NgxSpinnerService,
    private customerService: CustomerService,
    private customerCompositeService: CustomerCompositeService,
    @Inject(MAT_DIALOG_DATA) public data?,
    public dialogRef?: MatDialogRef<AddCustomerComponent>,
  ) {

    if (!this.eventBus) {
      this.eventBus = new EventBusService();
    }
  }

  checkIfCustomerExist() {
    if (this.customerForm?.value?.username) {
      this.customerCompositeService.checkUserOnboard(this.contextIdDto.contextId, 'ENV_ID', this.customerForm?.value?.username).subscribe(res => {
        if (res?.userExists) {
          this.customerForm.controls['username'].setErrors({ 'userNameExist': true });
        } else {
          this.customerForm.controls['username'].setErrors(null);
        }
        this.customerForm.controls['username'].markAsDirty();
      });
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  companyChanged() {
    if (!this.company) {
      this.model.companyPhoneNumber = null;
      this.model.companyName = null;
    } else {
      this.model.companyPhoneNumber = {
        country: this.model?.companyPhoneNumber?.country,
        internationalNumber: '',
        nationalNumber: ''
      }
      this.model.companyName = '';
    }
  }

  ngOnInit() {
    if (this.data && this.data.contextIdDto) {
      this.isViewMode = !_.isUndefined(this.data.isViewMode) ? this.data.isViewMode : false;
      this.pageType = this.data.pageType ? this.data.pageType : 'addEditCustomer';
      this.contextIdDto = this.data.contextIdDto;
      this.loadCustomer(this.data.customerId);
      this.loadCustomerSource();
    } else {
      this.route.params.subscribe(params => {
        this.pageType = this.route.snapshot.data['pageType'];
        if (!this.contextIdDto) {
          // Try and get it from url
          this.contextIdDto = <ContextIdDto>{};
          this.contextIdDto.contextId = params['contextId'];
          this.contextIdDto.contextIdType = params['contextIdType'];
        }
        this.loadCustomer(params['customerId']);
        this.loadCustomerSource();
      });
    }
    const appType = sessionStorage.getItem('appType');
    switch (appType) {
      case APP_TYPE_ENUM.PET_GROOMING_SALON:
      case APP_TYPE_ENUM.GROOM_SCHOOL:
      case APP_TYPE_ENUM.PET_DAY_CARE:
      case APP_TYPE_ENUM.PET_TRAINERS:
      case APP_TYPE_ENUM.PET_WALKERS:
      case APP_TYPE_ENUM.VETS:
        if (this.data?.showAddPet === false) {
          this.showAddPet = false;
        } else {
          this.showAddPet = true;
        }
        break;
    }
  }

  loadCustomer(customerId: string) {
    if (this.pageType === 'addEditCustomer' && customerId) {
      this.navChange.add(MenuItemTypeEnum.Customers);
      this.customerService.loadCustomer(
        customerId,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType).subscribe(response => {
          this.setLinkId(customerId);
          this.model = response;
          this.setLocalPhones(this.model, this.localPhones);
          if (this.model.companyName || this.model.companyNumber) {
            this.company = true;
            if (!this.model.companyPhoneNumber) {
              this.model.companyPhoneNumber = {
                country: '',
                internationalNumber: '',
                nationalNumber: ''
              };
            }
          }
          if (!this.model?.address) {
            this.model.address = <Address>{};
          }
        });
    } else {
      this.model = {} as Customer;
      this.model.ownerId = this.contextIdDto.contextId;
      this.model.optIn = <OptIn>{};
      this.model.optIn.allowEmail = true;
      this.model.optIn.allowWhatApp = false;
      this.model.optIn.allowSms = true;
      this.model.optIn.allowEmailMarketing = true;
      this.model.optIn.allowWhatsAppMarketing = false;
      this.model.optIn.allowSmsMarketing = true;
      this.model.address = <Address>{};
    }
  }

  setLinkId(customerId: string) {
    this.customerIdLinkId = <LinkId>{};
    this.customerIdLinkId.linkedIdType = LinkedIdTypeEnum.CustomerId;
    this.customerIdLinkId.linkedId = customerId;
  }

  loadCustomerSource() {
    this.dataListApi.getDataListByName(this.contextIdDto.contextId, this.contextIdDto.contextIdType,
      'Source').subscribe(response => {
        // console.log('got source list back');
        this.customerSourceList = response;
      });
  }

  cancelIt() {
    // console.log('here in cancel in addCustomer.scss');
    // this.cancelClicked.emit('cancel');
    this.dialogRef.close();
  }

  gotoCustomerPortal() {
    // console.log('this.windowRef location is ' + this.windowRef.getWindow().location);
    const url = this.windowRef.getPrefix() + '/cu/customerPortal/' + this.contextIdDto.contextId
      + '/' + this.contextIdDto.contextIdType + '/' + this.model.id;

    // console.log('url is ' + url);
    this.windowRef.getWindow().open(url);
  }

  archive() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Archive',
        message: `Are you sure you want to archive this customer ${this.model?.firstName} ${this.model?.lastName}?`
      },
      height: 'auto',
      width: '360px',
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.customerService.deleteCustomer(this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          this.model?.id
        ).subscribe(response => {
          this.notify.message = 'Customer has been archived successfully';
          this.notify.open();
          this.router.navigate(['/customer/customers',
            this.contextIdDto.contextId,
            this.contextIdDto.contextIdType]);
        });
      }
    });
  }

  deleteCustomerForever() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete Forever',
        message: `You are about to permanently delete this customer ${this.model?.firstName} ${this.model?.lastName}, are you sure you want to proceed?`
      },
      height: 'auto',
      width: '480px',
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.customerService.deleteCustomerForever(this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          this.model?.id
        ).subscribe(response => {
          this.notify.message = 'Customer has been deleted permanently';
          this.notify.open();
          this.router.navigate(['/customer/customers',
            this.contextIdDto.contextId,
            this.contextIdDto.contextIdType]);
        });
      }
    });
  }

  unarchive() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Unarchive',
        message: `Are you sure you want to unarchive this customer ${this.model?.firstName} ${this.model?.lastName}?`
      },
      height: 'auto',
      width: '360px',
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.customerService.undoDeleteCustomer(this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          this.model?.id)
          .subscribe(response => {
            this.notify.message = 'Unarchived Customer';
            this.notify.open();
            this.router.navigate(['/customer/customers',
              this.contextIdDto.contextId,
              this.contextIdDto.contextIdType]);
          });
      }
    });
  }

  backToList() {
    this.location.back();
  }

  handleAddCustomer(customer: Customer) {
    // Stay on page? and change it to edit screen?
    if (this.dialogRef && customer) {
      this.dialogRef.close(customer);
    }
    this.model = customer;
    this.setLinkId(this.model.id);

    // if (this.onboardCustomer && this.onboard?.contractTemplateId) {
    //   this.addContract(this.model?.id, this.onboard?.contractTemplateId);
    // }

    // if (this.onboardCustomer && this.onboard?.paymentPlanDefinitionId) {
    //   this.subscribeCustomerToPlan(this.model?.id);
    // }

    console.log('set link id');
    this.translateService.get('Successfully created new customer').subscribe(value => {
      this.notify.message = value;
      this.notify.open();
    });
    if (this.pageType === 'addEditCustomer') {
      this.created.emit('created');
    } else {
      this.created.emit(this.model.id);
    }
    this.eventBus.addCustomerEvent(EventType.CUSTOMER_CREATED, this.model.customerId);
    if (this.pageType === 'addEditCustomer') {
      this.router.navigate(['/customer/viewCustomer',
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.model.id]);
    }
  }

  addCustomer() {
    if (!this.customerForm.valid) {
      this.notify.message = 'Please provide mandatory values';
      this.notify.open();
      return;
    }
    if (this.showAddPet && !this.addNewPetV2Component?.valid) {
      this.notify.message = 'Please provide mandatory values for pets added';
      this.notify.open();
      return;
    }
    // if (!this.model?.username && (!this.model.mobilePhoneNumberObj?.internationalNumber
    //   && !this.model.phoneNumberObj?.internationalNumber
    //   && !this.model.workPhoneNumberObj?.internationalNumber)) {
    //   this.notify.message = 'Email or Any Phone number is required to communicate with the customer';
    //   this.notify.open();
    //   return;
    // }
    const createCustomerComposite: CreateCustomerComposite = {
      contextIdDto: this.contextIdDto,
      customer: this.model,
    }
    // const req = <CreateCustomer>{};
    // req.customer = this.model;
    // req.contextIdDto = this.contextIdDto;
    if (this.onboardCustomer) {
      createCustomerComposite.onboardCustomer = this.onboard;
    }
    if (this.onboardCustomerComponent) {
      this.onboardCustomerComponent.updateCustomerToPortalSetting();
    }

    this.ngxSpinnerService.show();
    this.customerCompositeService.createCustomerComp(createCustomerComposite).subscribe(async (res: CreateCustomerCompResponse) => {
      if (res?.customer?.customerId?.id) {
        if (this.showAddPet) {
          this.addNewPetV2Component.customerId = res?.customer.customerId.id;
          await this.addNewPetV2Component.createPetMultiple(res?.customer.customerId.id);
          this.ngxSpinnerService.hide();
          this.handleAddCustomer(res?.customer);
        } else {
          this.ngxSpinnerService.hide();
          this.handleAddCustomer(res?.customer);
        }
      }
    },
      error => {
        console.log('Got error');
        if (error.status === 409) {
          this.translateService.get('Customer already exists').subscribe(value => {
            this.notify.message = value;
            this.notify.open();
          });
        }
      },
      () => {
        console.log('in complete');
        // 'onCompleted' callback.
        // No errors, route to new page here
      });
  }

  subscribeCustomerToPlan(customerId) {

    const planReq = <SubscribeToPlan>{};
    planReq.contextIdDto = this.contextIdDto;
    planReq.planDefinitionId = this.onboard?.paymentPlanDefinitionId;
    planReq.customerId = customerId;
    planReq.dayOfPayment = 1;

    this.planCompApi.subscribe(planReq).subscribe(res => {

    });

  }

  viewCustomer() {
    this.router.navigate(['/customer/viewCustomer',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      this.model.id]);
  }

  updateCustomer() {

    if (this.model.id) {

      console.log('updating customer id ' + this.model.id);

      const req = <UpdateCustomer>{};

      req.customer = this.model;
      req.contextIdDto = this.contextIdDto;
      this.ngxSpinnerService.show();
      this.customerService.updateCustomer(req).subscribe(response => {

        // Stay on page? and change it to edit screen?
        this.model = response;
        console.log('updated customer');
        this.ngxSpinnerService.hide();

        this.translateService.get('Successfully updated customer').subscribe(value => {
          this.notify.message = value;
          this.notify.open();
        });
        this.eventBus.addCustomerEvent(EventType.CUSTOMER_UPDATED, this.model.customerId);
      }, error => {
        this.ngxSpinnerService.hide();
        if (error.status === 400) {
          this.notify.message = error?.message;
          this.notify.open();
        }
      });
    }
  }

  createContract(customerId: string, contractTemplateId?: string, legalText?: string) {
    const contract: Contract = {
      legalText: legalText ? legalText : '',
      contractTemplateId,
      ownerId: this.contextIdDto?.contextId,
      customerId,
      linkId: this.customerIdLinkId,
      dateCreated: new Date().toISOString(),
    };
    const req: CreateContract = {};
    req.contextIdDto = this.contextIdDto;
    req.contract = contract;
    this.contractService.createContract(req).subscribe(response => {
      if (response) {
        console.log('Contract created');
        console.log(response);
      }
    });
  }

  addContract(customerId: string, contractTemplateId: string) {
    if (contractTemplateId) {
      this.contractTemplateService.getContractTemplate(contractTemplateId).subscribe(template => {
        this.createContract(customerId, contractTemplateId, template.legalText);
      });
    } else {
      this.createContract(customerId);
    }
  }

  addressChanged(address: Address) {
    this.model.address = address;
    if (this.model.id) {
      this.updateCustomer();
    }
  }

  onMobilePhoneChange(value) {
    if (!this.model.mobilePhoneNumberObj) {
      this.model.mobilePhoneNumberObj = {} as PhoneNumber;
    }
    this.model.mobilePhoneNumberObj = value;
    this.updateCustomer();
  }

  onMobileCountryChange($event: any) {
    // console.log('onMobileCountryChange', $event);
    if (!this.model.mobilePhoneNumberObj) {
      this.model.mobilePhoneNumberObj = {} as PhoneNumber;
    }
    this.model.mobilePhoneNumberObj.country = $event.iso2;
    this.updateCustomer();
  }

  onPhoneCountryChange($event: any) {
    if (!this.model.phoneNumberObj) {
      this.model.phoneNumberObj = {} as PhoneNumber;
    }
    this.model.phoneNumberObj.country = $event.iso2;
    this.updateCustomer();
  }

  onWorkPhoneCountryChange($event: any) {
    if (!this.model.workPhoneNumberObj) {
      this.model.workPhoneNumberObj = {} as PhoneNumber;
    }
    this.model.workPhoneNumberObj.country = $event.iso2;
    this.updateCustomer();
  }

  onPhoneChanged(value) {
    if (!this.model.phoneNumberObj) {
      this.model.phoneNumberObj = {} as PhoneNumber;
    }
    this.model.phoneNumberObj = value;
    this.updateCustomer();
  }

  onWorkPhoneChanged(value) {
    if (!this.model.workPhoneNumberObj) {
      this.model.workPhoneNumberObj = {} as PhoneNumber;
    }
    this.model.workPhoneNumberObj = value;
    this.updateCustomer();
  }


  onCompanyPhoneNumberChange(value) {
    if (!this.model?.companyPhoneNumber) {
      this.model.companyPhoneNumber = {} as PhoneNumber;
    }
    this.model.companyPhoneNumber = value;
    this.updateCustomer();
  }

  onCompanyPhoneCountryChange($event: any) {
    // console.log('onMobileCountryChange', $event);
    if (!this.model?.companyPhoneNumber) {
      this.model.companyPhoneNumber = {} as PhoneNumber;
    }
    this.model.companyPhoneNumber.country = $event.iso2;
    this.updateCustomer();
  }


  updateForm(valid) {
    if (valid && this.model.id) {
      this.updateCustomer();
    }
  }

  setLocalPhones(user, localPhones) {
    this.sharedService.setLocalPhones(user, localPhones);
  }

  onboardUpdated(data: OnboardCustomerEvent) {

    console.log('onboardUpdated', data);
    if (data?.isOnboardCustomer) {
      this.onboardCustomer = true;
      this.onboard = data?.onboard;
    } else {
      this.onboardCustomer = false;
      this.onboard = null;
    }
  }

  petsFormGroupChange(petFormGroupArray) {
    //
  }

  createFlag(flagId: string) {
    if (!this.model.flags) {
      this.model.flags = new Array();
    }
    this.model.flags.push(flagId);
    this.updateCustomer();
  }

  deleteFlag(flagId: string) {
    if (this.model.flags) {
      const index = this.model.flags.indexOf(flagId, 0);
      if (index > -1) {
        this.model.flags.splice(index, 1);
        this.updateCustomer();
      }
    }
  }
}
