import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ContextIdDto } from '@savvy/view-definition';
import { ChangeListener } from './changeListener';
import { ElementControlService } from './ElementControlService';
import { EventBusService } from './EventBusService';
import {ElementDto, ViewContextDto} from '@savvy/view-composite';

@Component({
    selector: 'app-element',
    templateUrl: 'element.component.html'
})
export class ElementComponent implements OnInit {

    @Input() elementDto: ElementDto;
    @Input() contextIdDto: ContextIdDto;
    @Input() viewContext: ViewContextDto;
    @Input() additionalDataMapDto: any;
    @Input() form: UntypedFormGroup;
    @Input() eventBus: EventBusService;
    @Input() showPlaceholder: boolean;
    @Input() changeListener: ChangeListener;

    constructor(private ecs: ElementControlService) {
    }

    ngOnInit(): void {
        //  console.log('inside init, form is ' + this.form);
        if (!this.form) {
            // console.log('creating form group to keep element happy');
            this.form = this.ecs.emptyFormGroup();
        }
    }
}
