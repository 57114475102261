import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Contact } from '@savvy/workflow-definition';
import { ConfirmationDialogComponent } from 'src/app/flo/confirmation-dialog/confirmation-dialog.component';
import { AddEditPetContactComponent } from './add-edit-pet-contact/add-edit-pet-contact.component';
@Component({
  selector: 'app-pet-contacts',
  templateUrl: './pet-contacts.component.html',
  styleUrls: ['./pet-contacts.component.scss']
})
export class PetContactsComponent implements OnInit {

  @Input() contacts: Contact[] = [];
  @Output() updateContacts: EventEmitter<Contact[]> = new EventEmitter();

  constructor(
    private dialog: MatDialog,

  ) { }

  ngOnInit() {
  }

  addEditContact(index = -1) {
    const confirmDialog = this.dialog.open(AddEditPetContactComponent, {
      data: {
        contact: index >= 0 ? this.contacts[index] : null
      },
      height: 'auto',
      width: '530px',
      panelClass: ['scrollable-modal', 'helpwindow']
    });
    confirmDialog.afterClosed().subscribe((result: Contact) => {
      if (result) {
        if (index >= 0) {
          this.contacts[index] = result;
        } else {
          this.contacts.push(result);
        }
        this.updateContacts.emit(this.contacts);
      }
    });

  }

  deleteContact(index: number) {
    const confirmDialog = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: `Are you sure you want to delete this contact ${this.contacts[index].firstName} ${this.contacts[index].lastName}?`
      },
      height: 'auto',
      width: '380px',
      panelClass: 'helpwindow'
    });

    confirmDialog.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.contacts.splice(index, 1);
        this.updateContacts.emit(this.contacts);

      }
    });
  }

}
