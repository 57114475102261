<div *ngIf="customerSalesTotalsDto?.customerName" class="user-history-modal">
  <button class="close-modal" mat-icon-button (click)="dialogRef.close()">
    <mat-icon>close</mat-icon></button>
  <h2><span>{{customerSalesTotalsDto.customerName}}</span> Sales</h2>
  <mat-tab-group>
    <mat-tab *ngIf="contextIdDto && customerId" label="Rating">
      <ng-template matTabContent>
        <app-view-customer-workflow-stats-panel [contextIdDto]="contextIdDto" [customerId]="customerId">
        </app-view-customer-workflow-stats-panel>
      </ng-template>
    </mat-tab>
    <mat-tab *ngIf="contextIdDto && customerId" label="Appointments">
      <ng-template matTabContent>
        <app-view-appts-by-customer [contextIdDto]="contextIdDto" [customerId]="customerId">
        </app-view-appts-by-customer>
      </ng-template>
    </mat-tab>
    <mat-tab *ngIf="contextIdDto && customerId" label="Flags">
      <ng-template matTabContent>
        <br>
        <br>
        <app-list-customer-flags [contextIdDto]="contextIdDto" [customerId]="customerId"></app-list-customer-flags>
      </ng-template>
    </mat-tab>
    <mat-tab label="Services">
      <!--SERVICES-->
      <mat-card appearance="outlined" *ngIf="customerSalesTotalsDto?.serviceTotalsDto?.length > 0" class="new-card sales-block" fxLayout="column">
        <div fxFlex="100">
          <div fxFlex="30">
            <h2>Service Sales</h2>
          </div>
          <div fxFlex="40"></div>
        </div>
        <div fxFlex="100" class="mdc-mb-1">
          <div fxFlex="30">Service</div>
          <div fxFlex="40"></div>
          <div fxFlex="15">Quantity</div>
          <div fxFlex="15" class="text-end">Total</div>
        </div>
        <div class="expansion-container">
          <mat-expansion-panel *ngFor="let services of customerSalesTotalsDto.serviceTotalsDto">
            <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
              <mat-panel-title fxFlex="70">
                {{services?.serviceName}}
              </mat-panel-title>
              <mat-panel-description>
                <strong fxLayout="row" fxFlex="50">{{services.quantity}}</strong>
                <strong class="text-end" fxFlex="50">{{services.total | currency:currencyCode:'symbol'}}</strong>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="items-list" fxLayout="row" *ngFor="let service of services.serviceLineDtoList">
              <span fxLayout="row" fxFlex="69.6">{{service.dateTime | localizedDate: 'medium'}}</span>
              <span fxLayout="row" fxFlex="15">{{service.quantity}}</span>
              <strong class="text-end" fxFlex="15">{{service.total | currency:currencyCode:'symbol'}}</strong>
            </div>

          </mat-expansion-panel>
        </div>
      </mat-card>
      <div class="flexbox-parent" *ngIf="customerSalesTotalsDto?.serviceTotalsDto?.length === 0">
        <div fxLayout="row" fxLayoutAlign="center center" style="height: 35vh;">
          <div style="text-align:center;">
            <app-zero-image [name]="'reports'"></app-zero-image>
            <br>
            <h5 class="ma-0">{{'You don’t have any service data' | translate}}</h5><br>
          </div>

        </div>
      </div>
    </mat-tab>
    <mat-tab label="Products">
      <!--PRODUCTS-->
      <mat-card appearance="outlined" *ngIf="customerSalesTotalsDto?.productTotalsDto?.length > 0" class="new-card sales-block" fxLayout="column">
        <div fxFlex="100">
          <div fxFlex="30">
            <h2>Product Sales</h2>
          </div>
          <div fxFlex="40"></div>
        </div>
        <div fxFlex="100" class="mdc-mb-1">
          <div fxFlex="30">Product</div>
          <div fxFlex="40"></div>
          <div fxFlex="15">Quantity</div>
          <div fxFlex="15" class="text-end">Total</div>
        </div>
        <div class="expansion-container">
          <mat-expansion-panel *ngFor="let products of customerSalesTotalsDto.productTotalsDto">
            <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
              <mat-panel-title fxFlex="70">
                {{products.productName}}
              </mat-panel-title>
              <mat-panel-description>
                <strong fxLayout="row" fxFlex="50">{{products.quantity}}</strong>
                <strong class="text-end" fxFlex="50">{{products.total | currency:currencyCode:'symbol'}}</strong>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="items-list" fxLayout="row" *ngFor="let product of products.productLineDtos">
              <span fxLayout="row" fxFlex="35">{{product.dateTime | localizedDate: 'medium'}}</span>
              <strong fxLayout="row" fxFlex="34.6">{{product.customerName}}</strong>
              <span fxLayout="row" fxFlex="15">{{product.quantity}}</span>
              <strong class="text-end" fxFlex="15">{{product.total | currency:currencyCode:'symbol'}}</strong>
            </div>

          </mat-expansion-panel>
        </div>
      </mat-card>

      <div class="flexbox-parent" *ngIf="customerSalesTotalsDto?.productTotalsDto?.length === 0">
        <div fxLayout="row" fxLayoutAlign="center center" style="height: 35vh;">
          <div style="text-align:center;">
            <app-zero-image [name]="'reports'"></app-zero-image>
            <br>
            <h5 class="ma-0">{{'You don’t have any products data' | translate}}</h5><br>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

</div>