export class IndividualMessageDetailsDto {
    messageDetailsId: string;
    fullName: string;
    firstName: string;
    lastName: string;
    sendingStatus: string;
    status: string;
    type: string;
    recipient: string;
}
