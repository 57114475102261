import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppointmentService, LoadPetTransferableAppointmentsResponse } from '@savvy/appointment';
import { ContextIdDto, Customer, CustomerService } from '@savvy/customer';
import { Pet, PetService } from '@savvy/pet';
import { UserCurrencyService } from 'src/app/flo/shared/services/userCurrency.service';
import { FloSnackbarComponent } from 'src/app/flo/snackbar/floSnackbar.component';

export class ChangePetOwnerModalData {
  contextIdDto: ContextIdDto;
  customerId: string;
  pet: Pet;
}

@Component({
  selector: 'app-change-pet-owner-modal',
  templateUrl: './change-pet-owner-modal.component.html',
  styleUrls: ['./change-pet-owner-modal.component.css']
})
export class ChangePetOwnerModalComponent implements OnInit {

  customer: Customer;

  appointmentsSummary: LoadPetTransferableAppointmentsResponse;
  assignAppointmentsToNewOwner = false;

  newOwnerId: string;

  sameOwnerError = false;
  currencyCode = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ChangePetOwnerModalData,
    public dialogRef: MatDialogRef<ChangePetOwnerModalComponent>,
    private customersService: CustomerService,
    private appointmentService: AppointmentService,
    private petService: PetService,
    private notify: FloSnackbarComponent,
    private userCurrencyService: UserCurrencyService
  ) { }

  getCurrencyCode() {
    if (this.data.contextIdDto?.contextId) {
      this.userCurrencyService.getDefaultCurrency(this.data.contextIdDto).subscribe(res => {
        this.currencyCode = res.org?.currencyCode ? res.org.currencyCode : this.userCurrencyService.defaultCurrency;
      });
    }
  }

  ngOnInit() {
    this.getCurrencyCode();
    this.loadCustomer();
  }

  customerSelected(customerId: string) {
    if (customerId && this.data.pet.petOwnerId !== customerId) {
      this.newOwnerId = customerId;
      this.sameOwnerError = false;

    } else {
      this.newOwnerId = '';
      this.sameOwnerError = true;
    }
  }

  loadCustomer() {
    if (this.data.customerId && this.data.contextIdDto?.contextId && this.data.contextIdDto?.contextIdType) {
      this.customersService.loadCustomer(this.data.customerId, this.data.contextIdDto?.contextId, this.data.contextIdDto?.contextIdType)
        .subscribe((customer: Customer) => {
          console.log('customer', customer);
          this.customer = customer;
          this.loadPetTransferableAppointmentsSummary();
        });
    }
  }

  loadPetTransferableAppointmentsSummary() {
    if (this.data.pet?.id && this.data.contextIdDto?.contextId && this.data.contextIdDto?.contextIdType) {
      this.appointmentService.loadPetTransferableAppointmentsSummary(this.data.pet.id)
        .subscribe(petTransferableAppointmentsSummary => {
          console.log('petTransferableAppointmentsSummary', petTransferableAppointmentsSummary);
          this.appointmentsSummary = petTransferableAppointmentsSummary;
        });
    }
  }

  close() {
    this.dialogRef.close();
  }

  changeOwner() {
    if (this.data?.pet?.id && this.newOwnerId) {
      this.petService.movePetOwner(this.data.pet.id, this.newOwnerId).subscribe(response => {
        if (response?.moveSuccess && response?.updatedPet) {
          this.data.pet = response?.updatedPet;
          this.notify.message = 'Owner changed successfully';
          this.notify.open();
          this.dialogRef.close(true);
        } else if (response?.failureReason) {
          this.notify.message = response?.failureReason;
          this.notify.open();
        }
      });
    }
  }

}
