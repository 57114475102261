import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Customer, CustomerService } from "@savvy/customer";
import {EmailmessagetemplatesService, Link} from '@savvy/email-template';
import { FileValue } from "@savvy/entity-instance-composite";
import {
  ContextIdDto,
  EmailRecipient,
  LinkIds,
  MSISDN,
  MessagecompService,
  SendCompositeMessageResponse,
  SendEmailMessage,
  SendMessageResponse,
  SendSmsMessage,
  SendWhatsAppMessage,
  SmsRecipient
} from '@savvy/messaging';
import { Student, StudentService } from "@savvy/student";
import { LinkId } from '@savvy/view-definition';
import { AppointmentQuickLinksEditorComponent } from 'src/app/flo/editor/appointment-quick-links-editor/appointment-quick-links-editor.component';
import { EventBusService } from '../../../element/EventBusService';
import { EventType } from '../../../event/UiEvent';
import { EmailRecipientHelper, MessageRecipient } from '../../../messaging/EmailRecipientHelper';
import { FloSnackbarComponent } from '../../../snackbar/floSnackbar.component';
import { UploadFilesComponent } from '../upload-files/upload-files.component';
import {Lead, LeadsService} from "@savvy/leads";


@Component({
  selector: 'app-new-message-modal-v2',
  templateUrl: 'new-message-modal.component.html'
})
export class NewMessageModalComponent implements OnInit {

  @ViewChild('uploadFiles') uploadFilesComponent: UploadFilesComponent;
  @ViewChild(AppointmentQuickLinksEditorComponent, { static: false }) quickLinksEditorV2Component: AppointmentQuickLinksEditorComponent;

  contextIdDto: ContextIdDto;
  eventBus: EventBusService;
  showSpinner = false;
  linkId: LinkId;
  additionalLinkId: LinkId; // Appointment id?
  body: string;
  subject: string;
  emailAddresses: string;
  smsNumbers: string;
  smsSenderId: string;
  whatsAppNumbers: string;
  messageText: string;
  emailTemplateId: string;
  // readonly messageTypes = ['Email', 'SMS', 'WhatsApp'];
  readonly messageTypes = ['Email', 'SMS'];
  selectedMessageType = this.messageTypes[1];


  public form: UntypedFormGroup; // our form model
  messageRecipient: MessageRecipient;

  savedFiles: FileValue[] = [];
  links?: Array<Link>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: MessagecompService,
    private emailRecipientHelper: EmailRecipientHelper,
    private notify: FloSnackbarComponent,
    private emailmessagetemplatesService: EmailmessagetemplatesService,
    private customerService: CustomerService,
    private studentService: StudentService,
    private leadService: LeadsService,
    public dialogRef: MatDialogRef<NewMessageModalComponent>) {
    this.contextIdDto = this.data.contextIdDto;

    this.linkId = this.data.linkId;
    this.additionalLinkId = this.data.additionalLinkId;
    this.eventBus = this.data.eventBus;
  }

  ngOnInit() {
    console.log("linkd id");
    console.log(this.linkId);
    this.loadRecipient();
    if (this.data.emailTemplateType) {
      this.loadEmailTemplate(this.data.emailTemplateType);
    }
  }

  loadEmailTemplate(templateType: string) {
    this.emailmessagetemplatesService.getEmailMessageTemplateByType(this.contextIdDto.contextId, this.contextIdDto.contextIdType, templateType)
      .subscribe(res => {
        if (res) {
          this.onEmailTemplateChange(res);
        }
      });
  }


  bodyUpdated(text: string) {
    this.body = text;
  }

  // cancelIt() {
  //   this.eventBus.addEvent(EventType.CANCEL_SEND_MESSAGE);
  // }

  onEmailTemplateChange($event) {
    this.emailTemplateId = $event.id;
    this.body = $event.body;
    this.subject = $event.subject;

    if ($event.attachments) {
      this.savedFiles = $event.attachments;
    }
    if ($event.links) {
      this.links = $event.links;
    }
  }

  loadRecipient() {
    if (this.linkId.linkedIdType === 'CUSTOMER_ID') {
      this.customerService.loadCustomer(this.linkId.linkedId, this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(res => {
        if (res) {
          this.handleCustomerMessageRecipient(res);
        }
      });
    } else if (this.linkId.linkedIdType === 'STUDENT_ID') {
      this.studentService.loadStudent(this.linkId.linkedId, this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(res => {
        if (res) {
          this.handleStudentMessageRecipient(res);
        }
      });
    }  else if (this.linkId.linkedIdType === 'LEAD_ID') {
      this.leadService.loadLead(this.linkId.linkedId, this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(res => {
        if (res) {
          this.handleLeadMessageRecipient(res);
        }
      });
    }
  }

  messageDefined() {

    switch (this.selectedMessageType) {
      case this.messageTypes[0]: {
        if (this.body && this.body.length > 0) {
          return true;
        }
        return false;
      }
      case this.messageTypes[1]: {
        if (this.messageText && this.messageText.length > 0) {
          return true;
        }
        return false;
      }
      case this.messageTypes[2]: {
        if (this.messageText && this.messageText.length > 0) {
          return true;
        }
        return false;
        break;
      }
    }
  }

  handleCustomerMessageRecipient(customer: Customer) {
    this.messageRecipient = <MessageRecipient>{};
    this.messageRecipient.optIn = customer.optIn;
    this.messageRecipient.recipientId = customer.id;
    this.messageRecipient.recipientType = EmailRecipient.RecipientTypeEnum.Customer;
    if (customer.username) {
      this.messageRecipient.email = customer.username;
      this.emailAddresses = customer.username;
    }


    if (customer.mobilePhoneNumberObj) {

      this.smsNumbers = customer.mobilePhoneNumberObj.internationalNumber;
    } else if (customer.phoneNumberObj) {
      this.smsNumbers = customer.phoneNumberObj.internationalNumber;
    } else {
    }

    if (customer.whatsAppNumberObj) {

      this.whatsAppNumbers = customer.whatsAppNumberObj.internationalNumber;
    } else {
    }
  }

  handleStudentMessageRecipient(student: Student) {
    this.messageRecipient = <MessageRecipient>{};
    this.messageRecipient.optIn = student.optIn;
    this.messageRecipient.recipientId = student.id;
    this.messageRecipient.recipientType = EmailRecipient.RecipientTypeEnum.Student;
    if (student.username) {
      this.messageRecipient.email = student.username;
      this.emailAddresses = student.username;
    }


    if (student.mobilePhoneNumberObj) {

      this.smsNumbers = student.mobilePhoneNumberObj.internationalNumber;
    } else if (student.phoneNumberObj) {
      this.smsNumbers = student.phoneNumberObj.internationalNumber;
    } else {
    }

    if (student.whatsAppNumberObj) {

      this.whatsAppNumbers = student.whatsAppNumberObj.internationalNumber;
    } else {
    }
  }

  handleLeadMessageRecipient(lead: Lead) {
    this.messageRecipient = <MessageRecipient>{};
    this.messageRecipient.optIn = lead.optIn;
    this.messageRecipient.recipientId = lead.id;
    this.messageRecipient.recipientType = EmailRecipient.RecipientTypeEnum.Lead;
    if (lead.username) {
      this.messageRecipient.email = lead.username;
      this.emailAddresses = lead.username;
    }


    if (lead.mobilePhoneNumberObj) {

      this.smsNumbers = lead.mobilePhoneNumberObj.internationalNumber;
    } else if (lead.phoneNumberObj) {
      this.smsNumbers = lead.phoneNumberObj.internationalNumber;
    } else {
    }

    if (lead.whatsAppNumberObj) {

      this.whatsAppNumbers = lead.whatsAppNumberObj.internationalNumber;
    } else {
    }
  }


  send() {
    switch (this.selectedMessageType) {
      case this.messageTypes[0]: {
        this.sendEmail();
        break;
      }
      case this.messageTypes[1]: {
        this.sendSms();
        break;
      }
      case this.messageTypes[2]: {
        this.sendWhatsApp();
        break;
      }
    }
  }


  checkIfAllowedToSend() {
    if (this.messageRecipient) {
      switch (this.selectedMessageType) {
        case this.messageTypes[0]: {
          return this.messageRecipient.optIn.allowEmail;
        }
        case this.messageTypes[1]: {
          return this.messageRecipient.optIn.allowSms;
        }
        case this.messageTypes[2]: {
          return this.messageRecipient.optIn.allowWhatApp;
        }
        default: {
          return false;
        }
      }
    } else {
      return false;
    }

  }

  getAttachments() {
    const attachments = new Array();

    if (this.uploadFilesComponent.savedFiles) {
      this.uploadFilesComponent.savedFiles.forEach(
        savedFile => {
          attachments.push({
            ...savedFile,
            publicBucket: false
          });
        }
      );
    }
    return attachments;
  }

  handleResponse(response: SendCompositeMessageResponse) {
    if (response.sendMessageResponses && response.sendMessageResponses.length > 0) {
      response.sendMessageResponses.forEach(smr => {
        if (smr.sendMessageError) {
          console.log('smr is ' + smr.sendMessageError);
          if (smr.sendMessageError === SendMessageResponse.SendMessageErrorEnum.AuthorizationFailure) {
            this.notify.message = 'Your username or password is wrong in Email Settings';
            this.notify.open();
          } else if (smr.sendMessageError === SendMessageResponse.SendMessageErrorEnum.ConnectionFailure) {
            this.notify.message = 'We were unable to connect to your SMTP server (please check your host name and port)';
            this.notify.open();
          } else if (smr.sendMessageError === SendMessageResponse.SendMessageErrorEnum.UnknownMailServer) {
            this.notify.message = 'We were unable to connect to your SMTP server (please check your host name)';
            this.notify.open();
          } else if (smr.sendMessageError === SendMessageResponse.SendMessageErrorEnum.NoActiveSubscription) {
            this.notify.message = 'You do not have an active subscription so are not allowed to send messages';
            this.notify.open();
          } else if (smr.sendMessageError === SendMessageResponse.SendMessageErrorEnum.NoCredits) {
            this.notify.message = 'You do not have an credits remaining';
            this.notify.open();
          } else if (smr.sendMessageError === SendMessageResponse.SendMessageErrorEnum.SendingDisabled) {
            this.notify.message = 'You do not have sending enabled, please contact support who can enable it for you';
            this.notify.open();
          } else {
            this.notify.message = 'An error occurred when sending this message';
            this.notify.open();
          }
        } else {
          this.notify.message = 'Message sent!';
          this.notify.open();
          this.dialogRef.close(true);
        }
      });
    }
    if (this.eventBus) {
      this.eventBus.addEvent(EventType.SENT_MESSAGE);
    }
    this.clearFields();
  }

  private sendEmail() {
    if (this.messageRecipient?.recipientId) {
      const req = <SendEmailMessage>{};
      req.subject = this.subject;
      req.body = this.body;
      req.emailRecipients =
        this.emailRecipientHelper.toEmailRecipientsWithUser(this.emailAddresses, this.messageRecipient);
      req.contextIdDto = this.contextIdDto;
      const linkIds = <LinkIds>{};
      linkIds.linkIds = new Array();

      if (this.linkId) {
        linkIds.linkIds.push(this.linkId);
      }

      req.linkIds = linkIds;
      if (this.additionalLinkId) {
        req.linkIds.linkIds.push(this.additionalLinkId);
      }

      req.fileAttachments = this.getAttachments();

      if (this.quickLinksEditorV2Component) {
        req.links = this.quickLinksEditorV2Component.getLinksFromQuicklinksData();
      }

      if (this.links?.length > 0) {
        req.links.push(...this.links);
      }
      this.showSpinner = true;
      this.api.sendEmailMessage(req).subscribe(response => {
        this.showSpinner = false;
        this.handleResponse(response);
      });
    } else {
      console.log('new-message-modal.component : Customer ID is missing while sending EMAIL');
    }
  }

  private sendSms() {
    if (this.messageRecipient?.recipientId) {
      const req = <SendSmsMessage>{};
      req.contextId = this.contextIdDto.contextId;
      req.contextIdType = this.contextIdDto.contextIdType;
      req.message = this.messageText;
      console.log('setting sender id to ' + this.smsSenderId);
      req.smsSenderId = this.smsSenderId;
      const linkIds = <LinkIds>{};
      linkIds.linkIds = new Array();

      if (this.linkId) {
        linkIds.linkIds.push(this.linkId);
      }

      req.linkIds = linkIds;
      if (this.additionalLinkId) {
        req.linkIds.linkIds.push(this.additionalLinkId);
      }
      // To Do:  use real customer number
      req.toNumbers = [];
      if (this.smsNumbers) {
        const msisdns: string[] = this.smsNumbers.split(',');
        msisdns.forEach(msisdn => {
          const smsRecipient = <SmsRecipient>{};
          smsRecipient.recipient = <MSISDN>{};
          smsRecipient.recipient.msidn = msisdn;
          smsRecipient.recipientId = this.messageRecipient.recipientId;
          smsRecipient.recipientType = this.messageRecipient.recipientType;
          req.toNumbers.push(smsRecipient);
        });
      }
      this.showSpinner = true;
      this.api.sendSmsMessage(req).subscribe(response => {
        this.showSpinner = false;
        this.handleResponse(response);
      });
    } else {
      console.log('new-message-modal.component : Customer ID is missing while sending SMS');
    }
  }

  private sendWhatsApp() {
    const req = <SendWhatsAppMessage>{};
    req.contextId = this.contextIdDto.contextId;
    req.contextIdType = this.contextIdDto.contextIdType;
    req.message = this.messageText;

    // To Do:  use real customer number
    req.toNumbers = [];
    if (this.whatsAppNumbers) {
      req.toNumbers = this.whatsAppNumbers.split(',');
    }
    this.showSpinner = true;
    this.api.sendWhatsAppMessage(req).subscribe(response => {
      this.showSpinner = false;
      this.handleResponse(response);
    });
  }


  private clearFields() {
    this.showSpinner = false;
    this.subject = '';
    this.body = '';
    this.messageText = '';
    this.emailTemplateId = null;
  }
}
