import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContextService } from '../context.service';
import { ActivatedRoute, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { ContextIdDto } from '@savvy/quickbooks';
import { TrialExpiredService } from '../shared/services/trial-expired.service';
import { ExportService } from '@savvy/export';

import { saveAs } from 'file-saver';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import {FloSnackbarComponent} from "../snackbar/floSnackbar.component";

@Component({
  selector: 'app-export-invoices',
  templateUrl: './exportInvoices.component.html',
})
export class ExportInvoicesComponent implements OnInit, OnDestroy {

  contextIdDto: ContextIdDto;
  subscriptions: Subscription[] = [];

  exporting: boolean;
  public exportType = '';


  constructor(
    private router: Router,
    private notify: FloSnackbarComponent,
    public http: HttpClient,
    private contextService: ContextService,
    public trialExpiredService: TrialExpiredService,
    private route: ActivatedRoute,
    private api: ExportService) {
  }

  ngOnInit() {
    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
      }
    }));
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  exportData() {
    this.exporting = true;
    console.log('exporting data', this.exportType);
    return this.api.exportInvoices(this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(
      response => {
        console.log('got export');
        this.exporting = false;
        const blob = new Blob([response.lines], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'invoices.csv');
      }, error => {
        if (error.status === 401) {
          this.notify.message = 'You do not have permission to perform export.  Please check your role and permissions under Users menu';
          this.notify.open();
        }
        this.exporting = false;
      });
  }

  exportDataByService() {
    console.log('exporting data', this.exportType);
    this.exporting = false;

    return this.api.exportInvoicesByService(this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(
      response => {
        console.log('got export');
        const blob = new Blob([response.lines], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'invoices.csv');
      }, error => {
        if (error.status === 401) {
          this.notify.message = 'You do not have permission to perform export.  Please check your role and permissions under Users menu';
          this.notify.open();
        }
        this.exporting = false;
      });
  }

  saveFile(data: any, filename?: string) {
    const blob = new Blob([data], { type: 'text/csv; charset=utf-8' });
    saveAs(blob, filename);
  }
}


