<mat-toolbar color="primary">
  <h1 mat-dialog-title *ngIf="!data.edit">{{'ADD_RUN' | translate}}</h1>
  <h1 mat-dialog-title *ngIf="data.edit">{{'EDIT_RUN' | translate}}</h1>
</mat-toolbar>
<div class="mdc-p-3 " fxLayout="column">
  <form #runForm="ngForm" (ngSubmit)="addPetRun()">
    <div fxLayout="row wrap" fxLayoutGap="1%">
      <mat-form-field fxFlex="100" fxFlex.gt-sm="49" appearance="outline">
        <mat-label>{{'RUN_NAME' | translate}}</mat-label>
        <input matInput [(ngModel)]="run.name" name="subscriptionsDef" required>
      </mat-form-field>
      <mat-form-field fxFlex="100" fxFlex.gt-sm="49" appearance="outline">
        <mat-label>{{'PET_RUN_TYPE' | translate}}</mat-label>
        <mat-select [(ngModel)]="run.petRunType" name="petRunType" required>
          <mat-option *ngFor="let petRunType of petRunTypes" [value]="petRunType">{{petRunType}}</mat-option>
        </mat-select>
      </mat-form-field>

    </div>
    <div fxLayout="row wrap" fxLayoutGap="1%">
      <mat-form-field fxFlex="49" fxFlex.gt-sm="33" appearance="outline">
        <mat-label>{{'PRICE' | translate}} / {{'DAY' | translate}}</mat-label>
        <input matInput appNumberOnly [decimalPoints]="2" [(ngModel)]="run.price" name="price" required>
      </mat-form-field>
      <mat-form-field fxFlex="49" fxFlex.gt-sm="33" appearance="outline">
        <mat-label>{{'MULTI_PET_PRICE' | translate}}</mat-label>
        <input matInput appNumberOnly [decimalPoints]="2" [(ngModel)]="run.multiPetPrice" name="multiPetPrice" required>
      </mat-form-field>
      <mat-form-field fxFlex fxFlex.gt-sm="32" appearance="outline" *ngIf="run.petRunType === 'DAY_CARE'">
        <mat-label>{{'PRICE' | translate}} / {{'HOUR' | translate}}</mat-label>
        <input matInput appNumberOnly [decimalPoints]="2" [(ngModel)]="run.hourlyPrice" name="hourlyPrice" required>
      </mat-form-field>
    </div>
    <div fxLayout="row wrap" fxLayoutGap="1%">
      <mat-form-field fxFlex fxFlex.gt-sm="33" appearance="outline">
        <mat-label>{{'COLOUR' | translate}}</mat-label>
        <input matInput type="text" placeholder="{{'COLOUR' | translate}}" name="color" [value]="run.color" [(ngModel)]="run.color" #colour="ngModel" [style.background]="run.color" [(colorPicker)]="run.color" [cpSaveClickOutside]="true" [cpOKButtonClass]="'mat-raised-button mat-accent'" (colorPickerSelect)="updateColor($event, 'color')">
      </mat-form-field>
      <mat-form-field fxFlex="49" fxFlex.gt-sm="33" appearance="outline">
        <mat-label>{{'MAX_PETS_ALLOWED' | translate}}</mat-label>
        <input matInput appNumberOnly [(ngModel)]="run.maxPets" name="maxPets" required>
      </mat-form-field>
      <mat-form-field fxFlex="49" fxFlex.gt-sm="32" *ngIf="taxes && taxes.length" appearance="outline">
        <mat-label>{{'RUN_TAX' | translate}}</mat-label>
        <mat-select placeholder="{{'SELECT_TAX_RATE' | translate}}; trackBy: tax.id" [(ngModel)]="run.taxId" name="taxId">
          <mat-option *ngFor="let tax of taxes" [value]="tax.id">
            {{ tax.taxName }} ( {{ tax.taxRate }} %)
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="row wrap" fxLayoutGap="8px">
      <mat-slide-toggle *ngIf="run.petRunType === 'BOARDING'" fxFlex [(ngModel)]="run.chargePerNight" name="chargePerNight">
        {{'CHARGE_PER_NIGHT' | translate}}
      </mat-slide-toggle>
      <mat-slide-toggle fxFlex [(ngModel)]="run.airConditioning" name="airConditioning">
        {{'AIR_CONDITIONED' | translate}}
      </mat-slide-toggle>
      <mat-slide-toggle fxFlex [(ngModel)]="run.heating" name="heating">
        {{'HEATING' | translate}}
      </mat-slide-toggle>
    </div>
    <div fxLayout="row" fxLayoutAlign="end" class="pt-2">
      <button mat-flat-button color="primary" cdkFocusInitial *ngIf="!data.edit" [disabled]="!runForm.form.valid">Create</button>
      <button mat-flat-button color="primary" cdkFocusInitial *ngIf="data.edit" [disabled]="!runForm.form.valid">Update</button>
    </div>
  </form>
</div>