import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ContextIdDto, RequestForPaymentService, RequestForPayment, ScheduleRequestForPayment } from '@savvy/request-for-payment';
import { Invoice, InvoicecompService, InvoiceDto, LoadInvoiceForViewResponse } from '@savvy/invoice';
import { InvoiceService, UpdateInvoice } from '@savvy/invoice';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FloSnackbarComponent } from 'src/app/flo/snackbar/floSnackbar.component';
import * as _ from 'lodash';
import { CourseBookingService } from '../../courses/course-booking/course-booking-invoice/course-booking.service';
import { PaymentDepositRequestComponent } from './payment-deposit-request/payment-deposit-request.component';
import { OrgCompositeService } from '@savvy/org';
// interface RequestForPayment1 extends RequestForPayment {
//   paymentType: string;
//   invoiceId: string;
//   invoiceDate: string;
//   ownerId: string;
// }
@Component({
  selector: 'app-payment-scheduler',
  templateUrl: './payment-scheduler.component.html',
  styleUrls: ['./payment-scheduler.component.scss']
})
export class PaymentSchedulerComponent implements OnInit, OnChanges {

  @ViewChild(PaymentDepositRequestComponent, { static: false }) paymentDepositRequestComponent: PaymentDepositRequestComponent;

  @Input() contextIdDto: ContextIdDto;
  @Input() invoiceId: string;
  // @Input() paymentType: string;
  @Input() allowMultiple = true;
  @Input() requestDepositNow = false;
  @Input() requestForPaymentOwnerId: string;
  @Input() requestForPaymentOwnerType: RequestForPayment.RequestForPaymentOwnerTypeEnum;


  invoiceDto: InvoiceDto = {} as InvoiceDto;
  invoice: Invoice = {} as Invoice;
  show: string;
  destroy$ = new Subject();
  paymentSchedules: Array<RequestForPayment> = [];
  currencyCode = '';
  loading = false;

  paymentMode = 'NP';
  paymentType = 'AMOUNT';
  disableAddingMore = true;
  minRange = new Date();

  constructor(
    private requestForPaymentService: RequestForPaymentService,
    private invoicecompService: InvoicecompService,
    private notify: FloSnackbarComponent,
    private courseBookingService: CourseBookingService,
    private orgCompositeService: OrgCompositeService

  ) { }

  ngOnInit() {
    this.loadInvoice();
    this.loadOrg();
  }

  loadOrg() {
    this.orgCompositeService.getCurrentOrgComp().subscribe(res => {
      if (res && res.currencyCode) {
        this.currencyCode = res.currencyCode;
      }
    });
  }

  removeAllPrevious() {
    for (let index = 0; index < this.paymentSchedules.length; index++) {
      this.removeSchedule(index);
    }
  }

  paymentModeChanged(newPaymentMode) {
    if (this.paymentMode === 'PS' && newPaymentMode === 'RD') {
      // All schedules will be lost
    }
    if (newPaymentMode === 'RD') {
      this.removeAllPrevious();
      this.paymentSchedules = [];
      this.addSchedule();
    }
    this.paymentMode = newPaymentMode;
  }

  async ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...
    // console.log('change detected inside view entity');
    // for (const propName in changes) {
    //   if (propName === 'requestDepositNow' && changes['requestDepositNow'].currentValue === true) {

    //   }
    // }
  }

  isPaymentReqValid(event: RequestForPayment) {
    let valid = true;

    if (this.paymentType === 'AMOUNT') {
      valid = event.paymentAmountInPence ? true : false;
      // this.notify.message = valid ? '' : 'Please add amount for payment request';
      // this.notify.open();
    } else {
      valid = event.percentOfTotal ? true : false;
      event.percentRequired = true;
    }
    if (this.paymentMode === 'PS') {
      valid = event.dateToSend ? true : false;
    }

    this.disableAddingMore = !valid;
    return valid;
  }

  scheduleRequestForPayment(event: RequestForPayment) {
    if (!this.requestDepositNow && !this.isPaymentReqValid(event)) {
      return;
    }
    const requestForPayment = Object.assign({
      requestForPaymentOwnerType: this.requestForPaymentOwnerType,
      requestForPaymentOwnerId: this.requestForPaymentOwnerId
    }, event);

    if (requestForPayment.paymentAmountInPence) {
      requestForPayment.paymentAmountInPence = _.cloneDeep(requestForPayment.paymentAmountInPence * 100);
    }

    const scheduleRequestForPayment: ScheduleRequestForPayment = {
      contextIdDto: this.contextIdDto,
      requestForPayment,
    };
    this.requestForPaymentService.scheduleRequest(scheduleRequestForPayment).subscribe(res => {
      this.loadRequestsForPayment();
    });
  }

  loadRequestsForPayment() {
    return new Promise(resolve => {
      this.requestForPaymentService.listByInvoice(this.contextIdDto.contextId, this.contextIdDto.contextIdType, this.invoiceId)
        .subscribe((res) => {
          if (res && res.length) {
            this.paymentSchedules = [];
            for (const paymentSchedule of res) {
              paymentSchedule.paymentAmountInPence = paymentSchedule.paymentAmountInPence / 100;
              this.paymentSchedules.push(paymentSchedule);
            }
            this.paymentSchedules = [...this.paymentSchedules];
            this.disableAddingMore = false;
          } else {
            this.paymentSchedules = [];
            this.addSchedule();
          }
          resolve(true);
        });
    });

  }

  addSchedule() {
    const paymentSchedule: RequestForPayment = {
      invoiceId: this.invoiceId,
      invoiceDate: this.invoice.invoiceDate,
      ownerId: this.contextIdDto.contextId,
      dueDate: this.invoice.dueDate,
      // sent: false,
      requestForPaymentOwnerType: this.requestForPaymentOwnerType,
      requestForPaymentOwnerId: this.requestForPaymentOwnerId
    };
    this.paymentSchedules.push(paymentSchedule);
    this.paymentSchedules = [...this.paymentSchedules];
    this.scheduleRequestForPayment(paymentSchedule);
  }

  removeSchedule(index) {
    const paymentSchedule = this.paymentSchedules[index];
    paymentSchedule.deleted = true;
    this.scheduleRequestForPayment(paymentSchedule);
  }

  sendDepositeReqNow(requestForPaymentId: string) {
    this.requestForPaymentService.triggerDepositRequestNow(requestForPaymentId)
      .subscribe(res => {
        if (res) {
          this.notify.message = 'Deposit request sent';
          this.notify.open();
        }
      });
  }

  addDepositRequest(requestForPaymentParam: RequestForPayment): Promise<RequestForPayment> {
    return new Promise(resolve => {
      const requestForPayment = Object.assign({}, requestForPaymentParam);
      // if (requestForPayment.paymentAmountInPence) {
      //   requestForPayment.paymentAmountInPence = _.cloneDeep(requestForPayment.paymentAmountInPence * 100);
      // }
      const scheduleRequestForPayment: ScheduleRequestForPayment = {
        contextIdDto: this.contextIdDto,
        requestForPayment
      };
      this.requestForPaymentService.scheduleRequest(scheduleRequestForPayment).subscribe((res: RequestForPayment) => {
        resolve(res);
      });
    });

  }

  finish() {
    if (this.paymentMode === 'FP') {
      this.courseBookingService.sendInvoice(this.contextIdDto, this.invoiceId);
    }
    if (this.paymentMode === 'RD' && this.paymentDepositRequestComponent) {
      if (!this.paymentDepositRequestComponent.valid) {
        return;
      }
      const requestForPaymentParam: RequestForPayment = {
        invoiceId: this.invoiceId,
        invoiceDate: this.invoice.invoiceDate,
        ownerId: this.contextIdDto.contextId,
        dueDate: this.invoice.dueDate,
        requestForPaymentOwnerType: this.requestForPaymentOwnerType,
        requestForPaymentOwnerId: this.requestForPaymentOwnerId
      };
      switch (this.paymentDepositRequestComponent.depositForm.value.paymentType) {
        case 'FULL_AMOUNT':
          requestForPaymentParam.percentRequired = false;
          requestForPaymentParam.paymentAmountInPence = (this.invoiceDto.invoice.total * 100);
          break;
        case 'AMOUNT':
          requestForPaymentParam.percentRequired = false;
          requestForPaymentParam.paymentAmountInPence = (this.paymentDepositRequestComponent.depositForm.value.amount * 100);
          break;
        case 'PERCENTAGE':
          requestForPaymentParam.percentRequired = true;
          requestForPaymentParam.percentOfTotal = this.paymentDepositRequestComponent.depositForm.value.percentage;
          break;
        default:
          break;
      }
      this.addDepositRequest(requestForPaymentParam).then((requestForPayment: RequestForPayment) => {
        this.sendDepositeReqNow(requestForPayment.id);
      });
    }



    // this.router.navigate(['course', 'courses', this.contextIdDto.contextId, this.contextIdDto.contextIdType]);
  }

  private loadInvoice() {
    if (this.invoiceId) {
      this.invoicecompService.loadInvoiceForView(
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.invoiceId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (response: LoadInvoiceForViewResponse) => {
            console.log('loaded invoice dto', response.invoiceDto);
            this.invoiceDto = response.invoiceDto;
            this.invoice = response.invoiceDto.invoice;
            this.loadRequestsForPayment();
          });
    } else {
      this.notify.message = 'Invoice Id does not exist';
      this.notify.open();
    }
  }

}
