<div *ngIf="bookingDef && styleDef" fxLayout="row wrap" fxLayoutAlign="space-between"  style="height: 100%;">

  <div fxFlex.gt-sm="25%" fxFlex="100" fxFlex.gt-xs="25%">



  </div>

  <div fxFlex.gt-sm="50%" fxFlex="100" fxFlex.gt-xs="50%">

    <div *ngIf="bookingDef.bookingLookAndFeelDefinition && bookingDef.bookingLookAndFeelDefinition.showLogo && logoUri" class="headerLogo" [style.background-image]="'url(' + logoUri + ')'"></div>

    <div *ngIf="bookingDef.bookingLookAndFeelDefinition && bookingDef.bookingLookAndFeelDefinition.headerMessage">
        <h1>{{bookingDef.bookingLookAndFeelDefinition.headerMessage}}</h1>
    </div>


    <!-- Pick Service -->
    <app-select-service [contextIdDto]="contextIdDto" (serviceNumbersSelected)="this.serviceNumbers = $event" *ngIf="wizardStep === 'selectService'"></app-select-service>
    <button *ngIf="wizardStep === 'selectService'" (click)="serviceSelected()">{{'BOOK_NOW' | translate}}</button>

    <!-- Pick User -->
    <app-select-user [contextIdDto]="contextIdDto" *ngIf="wizardStep === 'selectUser'"></app-select-user>
    <button *ngIf="wizardStep === 'selectUser'" (click)="wizardStep = 'selectSlot'">{{'SELECT_SLOT' | translate}}</button>

    <!-- Pick Slot -->
    <app-select-slot [contextIdDto]="contextIdDto" [bookingDef]="bookingDef" [serviceNumbers]="serviceNumbers" *ngIf="wizardStep === 'selectSlot'"></app-select-slot>
    <button *ngIf="wizardStep === 'selectSlot'" (click)="wizardStep = 'login'">{{'BOOK' | translate}}</button>

    <!-- Login Slot -->
    <app-book-login [contextIdDto]="contextIdDto" [bookingDef]="bookingDef" *ngIf="wizardStep === 'login'"></app-book-login>
    <button *ngIf="wizardStep === 'login'" (click)="wizardStep = 'bookSummary'">{{'BOOK' | translate}}</button>

    <!-- Summary -->
    <app-book-summary [contextIdDto]="contextIdDto" [bookingDef]="bookingDef" *ngIf="wizardStep === 'bookSummary'"></app-book-summary>
    <button *ngIf="wizardStep === 'bookSummary'" (click)="bookingSummary()">{{'BOOK' | translate}}</button>

    <!-- Take Payment -->
    <app-take-payment [contextIdDto]="contextIdDto" [bookingDef]="bookingDef" (paymentMade)="paymentMade()" *ngIf="wizardStep === 'takePayment'"></app-take-payment>

    <!-- Confirmation -->
    <app-booking-confirmation [contextIdDto]="contextIdDto"
                              [bookingDef]="bookingDef"
                              [slotDto]="slotDto"
                              [currencyCode]="currencyCode"
                              *ngIf="wizardStep === 'bookingConfirmation'"></app-booking-confirmation>

  </div>

  <div fxFlex.gt-sm="25%" fxFlex="100" fxFlex.gt-xs="25%"></div>
</div>
