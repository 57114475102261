import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-discount-price-view',
  templateUrl: './discount-price-view.component.html',
  styleUrls: ['./discount-price-view.component.scss']
})
export class DiscountPriceViewComponent implements OnInit {

  @Input() originalPrice: number;
  @Input() finalPrice: number;
  @Input() currencyCode: string;

  constructor() {
  }

  ngOnInit(): void {
  }

  // get hasDiscount() {
  //   return this.finalPrice > 0 && this.originalPrice != this.finalPrice;
  // }

}
