import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { ContextIdDto } from '@savvy/entity-instance-composite';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { Invoice } from '@savvy/payment';
import {ApplyPlan, Plan, PlanDto, PlansCompService, PlansPaymentTakenService} from '@savvy/plan';

@Component({
  selector: 'app-plans-payment',
  templateUrl: './plans-payment.component.html'
})
export class PlansPaymentComponent implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() invoice: Invoice;
  @Input() plans: Array<PlanDto> = [];
  @Input() entityInstanceId: string; //appointmentId (entity instance id)

  @Output() added: EventEmitter<any> = new EventEmitter();

  request = <ApplyPlan>{};
  selectedPlan = <PlanDto>{};
  hasBalance = false;
  hasPayments = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private notify: FloSnackbarComponent,
    private dialog: MatDialog,
    private planCompApi: PlansCompService,
    private planPaymentTaken: PlansPaymentTakenService,
    private dialogRef: MatDialogRef<PlansPaymentComponent>) {
    this.contextIdDto = data.contextIdDto;
    this.invoice = data.invoice;
    this.plans = data.plans;
    this.entityInstanceId = data.entityInstanceId;
  }

  ngOnInit(): void {

    this.request.contextIdDto = this.contextIdDto;
    this.request.invoiceId = this.invoice.id;
    this.request.amount = this.invoice.total;
    this.request.appointmentId = this.entityInstanceId;
  }

  applyPayment() {
    console.log(this.request);

    this.planCompApi.applyPlan(this.request).subscribe(res => {
      console.log(res);

      if (res) {
        this.notify.message = 'Payment Successful';
        this.notify.open();
        this.dialogRef.close(res);
      }

    });
  }

  selectPlan($event) {
    const plan = this.plans.find(p => p.id == $event.value);
    this.selectedPlan = plan;

    console.log(this.selectedPlan);

    if (this.selectedPlan.balance >= this.invoice.total) {
      this.hasBalance = true;
    } else {
      this.hasBalance = false;
    }

    this.hasPaymentTaken(this.selectedPlan);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  hasPaymentTaken(plan: PlanDto) {
    if (plan?.id) {
      this.planPaymentTaken.loadByPaymentTakenPlanId(this.contextIdDto.contextId, this.contextIdDto.contextIdType, plan.id).subscribe(res => {
        this.hasPayments = res.length > 0;
      });
    }
  }


}
