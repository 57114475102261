<div fxLayout="row" *ngIf="flagsResponse && flagsResponse.flagDto && flagsResponse.flagDto.length > 0">
  <mat-chip-listbox #chipList *ngIf="flagsResponse && flagsResponse.flagDto">
    <mat-chip-option color="{{flagDto.flagDefinition.colour}}" *ngFor="let flagDto of flagsResponse.flagDto"
              removable="true" selected>
      {{flagDto.flagDefinition.name}}
      <mat-icon matChipRemove >cancel</mat-icon>
    </mat-chip-option>
  </mat-chip-listbox>
</div>
<div fxLayout="row" *ngIf="flagsResponse && flagsResponse.flagDto && flagsResponse.flagDto.length > 0">
</div>
<br *ngIf="flagsResponse && flagsResponse.flagDto && flagsResponse.flagDto.length > 0">
