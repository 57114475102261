<div fxLayout="row" fxLayoutAlign=" center" class="w-100 mdc-mb-1">
  <div fxFlex>
    <h6 class="mb-0 ">{{'CUSTOMER' | translate}}</h6>
  </div>
  <!-- <div fxFlex="nogrow">
    <a *ngIf="customerPortalAllowed" (click)="gotoPortal()" class="mat-text-secondary">Portal <mat-icon
        aria-hidden="false" class="vm">launch
      </mat-icon></a>
  </div> -->
</div>
<div *ngIf="customer; else elseBlock" class="mb-1">
  <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="16px" class="mdc-mb-1">
    <div fxFlex="nogrow">
      <div class="avatar avatar-40 circle border-3" (click)="viewCustomer()">
        <mat-icon>account_circle</mat-icon>
      </div>
    </div>
    <div fxFlex>
      <h6 class="mb-0">
        <span class="customer-name">
          <a href="javascript:void(0);" (click)="viewCustomer()" class="d-inline-block mr-5px vm">
            {{ customer?.firstName }} {{ customer?.lastName }}
          </a>

          <a href="javascript:void(0);" (click)="editCustomer()" class="d-inline-block mr-5px vm">
            <mat-icon>edit</mat-icon>
          </a>

        </span>
        <span *ngIf="!customer?.deleted" class="chiptag green vm px-2">{{'ACTIVE' | translate}}</span><span
          *ngIf="customer?.deleted" class="chiptag red vm px-2">{{'INACTIVE' | translate}}</span>
      </h6>
      <p class="mat-text-secondary mb-0">{{'LAST_SEEN' | translate}}: N/A</p>
    </div>
  </div>
  <a href="mailto: {{customer?.username}}">
    <div class="chiptag theme-accent vm mt-5px" *ngIf="customer?.username">
      <mat-icon>email</mat-icon> {{customer?.username}}
    </div>
  </a>
  <br>
  <a *ngIf="customer?.mobilePhoneNumberObj?.internationalNumber"
    href="tel: {{customer?.mobilePhoneNumberObj?.internationalNumber}}">
    <div class="chiptag theme vm mt-5px">
      <mat-icon>phone</mat-icon> {{customer?.mobilePhoneNumberObj?.internationalNumber}}
    </div>
  </a>
</div>
<!-- <mat-card-footer *ngIf="customer; else elseBlock" class="mx-0 mb-0 mt-15px">
  <button *ngIf="customerPortalAllowed && portalSettingsLocalService.portalEnabled" mat-raised-button color="accent"
    (click)="portalInvite()">
    {{'PORTAL_INVITE' | translate}}
  </button>
</mat-card-footer> -->

<ng-template #elseBlock>
  <div class="flexbox-parent">
    <div fxLayout="row" fxLayoutAlign="center center" style="height: 30vh;">
      <div style="text-align:center;">
        <app-zero-image name="order"></app-zero-image><br>
        <h5 class="ma-0">{{'NO_CUSTOMER_DETAILS' | translate}}.</h5>
      </div>
    </div>
  </div>
</ng-template>
