<mat-toolbar color="primary">
  <h1 mat-dialog-title>{{'SWITCH_TRIAL_PLAN' | translate}}</h1>
</mat-toolbar>
<div class="mdc-p-3 ">
  <div class="row">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>{{'SELECT_SUBSCRIPTION' | translate}}</mat-label>
      <mat-select [(ngModel)]="selectedSubscription" name="subscriptionsDef" [compareWith]="compareObjects">
        <mat-option *ngFor="let subscriptionsDef of data.subscriptionsDefs" [value]="subscriptionsDef">
          {{subscriptionsDef.header}} - {{subscriptionsDef.headerSummary}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div *ngFor="let addOnDef of addOnDefs" fxLayout="row" fxLayoutGap="8px">
      <mat-checkbox fxFlex [checked]="isEnabled(addOnDef)" (change)="selectionChanged($event, addOnDef)">
        {{addOnDef.name}}
      </mat-checkbox>
    </div>
    <!-- <mat-selection-list *ngIf="addOnDefs && addOnDefs.length" [(ngModel)]="selectedAddOns"
      [compareWith]="compareObjects" style="margin: 0 5px 0;">
      <mat-list-option checkboxPosition="before" *ngFor="let addOnDef of addOnDefs" [value]="addOnDef">
        {{addOnDef.name}}
      </mat-list-option>
    </mat-selection-list> -->
  </div>
</div>
<div fxLayout="row" fxLayoutAlign="end" class="mt-0 mr-1 mdc-mb-1">
  <button mat-flat-button color="primary" (click)="updateSubscription()" cdkFocusInitial>{{'UPDATE_PLAN' |
    translate}}</button>
</div>