<mat-accordion *ngIf="response && response.entityInstanceIdFieldValueTupleDtos
&& response.entityInstanceIdFieldValueTupleDtos.length > 1">
  <!-- [expanded]="step === 0" -->
  <mat-expansion-panel *ngFor="let anEntity of response.entityInstanceIdFieldValueTupleDtos" (opened)="setStep(0)" >
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{anEntity.name}}
      </mat-panel-title>
      <!--mat-panel-description>
        Type your name and age
      </mat-panel-description-->
    </mat-expansion-panel-header>

    <app-view-entity-simple *ngIf="viewInvoiceEntitiesExpandableData
            && anEntity
            && viewInvoiceEntitiesExpandableData.viewInvoiceEntitiesExpandableDefinition
            && viewInvoiceEntitiesExpandableData.viewInvoiceEntitiesExpandableDefinition.viewDefinitionId"
            [contextIdDto]="contextIdDto"
            [eventBus]="eventBus"
            [viewDefinitionId]="viewInvoiceEntitiesExpandableData?.viewInvoiceEntitiesExpandableDefinition?.viewDefinitionId?.id"
            [entityInstanceId]="anEntity?.id">

    </app-view-entity-simple>

  </mat-expansion-panel>
</mat-accordion>

<div *ngIf="response && response.entityInstanceIdFieldValueTupleDtos && response.entityInstanceIdFieldValueTupleDtos.length === 1">

  <app-view-entity-simple
    [contextIdDto]="contextIdDto"
    [eventBus]="eventBus"
    [viewDefinitionId]="viewInvoiceEntitiesExpandableData?.viewInvoiceEntitiesExpandableDefinition?.viewDefinitionId?.id"
    [entityInstanceId]="response.entityInstanceIdFieldValueTupleDtos[0]?.id">

  </app-view-entity-simple>
</div>

<div *ngIf="response && response.entityInstanceIdFieldValueTupleDtos && response.entityInstanceIdFieldValueTupleDtos.length === 0">
  There are no entries
</div>
