import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContextService } from '../../context.service';
import { TranslateService } from '@ngx-translate/core';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  SmsSettingsService,
  ContextIdDto,
  SmsSettings
} from '@savvy/sms-settings';
import { Subject, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { UserCurrencyService } from '../../shared/services/userCurrency.service';
import { SmsBundleDto, SmsBundleService } from '@savvy/sms-bundle';
import { SmsCreditSummaryDto, SmsSettingsCompService } from '@savvy/sms-settings';
import { takeUntil } from 'rxjs/operators';
import { TopupSmsCredits } from '@savvy/sms-settings';
import { MessageService } from '@savvy/messaging';
import { UsedSmsComponent } from './usedSms.component';
import { SubscriptionHelperService } from '../../shared/services/subscription-helper.service';
import { CookiesService } from '../../shared/services/cookies.service';
import { Coupon, CouponsService } from "@savvy/subscription";
import { SmsCreditStateService } from '../../shared/services/sms-credit-state.service';
import { SmsCreditService } from '../../shared/services/sms-credit.service';

@Component({
  selector: 'app-sms',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.scss']

})
export class SmsComponent implements OnInit, OnDestroy {

  contextIdDto: ContextIdDto;
  subscriptions: Subscription[] = [];

  currencyCode: string;

  month: string;
  destroy$ = new Subject();
  buying = false;
  countryCode = 'GB';
  validCoupon = false;
  coupon: Coupon = <Coupon>{};
  constructor(
    public contextService: ContextService,
    public smsCreditStateService: SmsCreditStateService,
    public smsCreditService: SmsCreditService,
    private notify: FloSnackbarComponent,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private userCurrencyService: UserCurrencyService,
    private smsBundleApi: SmsBundleService,
    private smsSettingsCompApi: SmsSettingsCompService,
    public dialogRef: MatDialogRef<SmsComponent>,
    public subscriptionHelperService: SubscriptionHelperService,
    private cookiesService: CookiesService,
    private couponsService: CouponsService,

  ) {
  }

  ngOnInit() {
    console.log('inside init of sms component');
    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
        this.loadCurrencyCode();
        this.smsCreditService.loadSMSReport(this.contextIdDto);
        const date = new Date();  // 2009-11-10
        this.month = date.toLocaleString('default', { month: 'long' });
      }
    }));
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  loadCurrencyCode() {
    this.userCurrencyService.getDefaultCurrency(this.contextIdDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.currencyCode = res.org.currencyCode ? res.org.currencyCode : this.userCurrencyService.defaultCurrency;
        this.countryCode = res.org.countryCode ? res.org.countryCode : 'GB';
      });
  }

  closePanel() {
    this.dialogRef.close();
  }

  buyBundle(smsBundleDto: SmsBundleDto) {
    this.subscriptionHelperService.checkSubscriptionStatus(this.contextIdDto, this.countryCode)
      .then(res => {
        const skipCardVerification = this.cookiesService.getCookie('skipCardVerification');
        if (res && !skipCardVerification) {
          this.buySMSBundle(smsBundleDto);
        } else {
          this.notify.message = 'Please verify your card details first to change the plan';
          this.notify.open();
          this.cookiesService.deleteCookie('skipCardVerification');
        }
      });
  }

  buySMSBundle(smsBundleDto: SmsBundleDto) {
    console.log('buy bundle called!!!');
    this.buying = true;
    const coupon = this.coupon.couponCode;
    if (coupon) {
      this.couponsService.searchCouponByCodeAndUsage(coupon, 'SMS_TOP_UP').subscribe(res => {
        const req = <TopupSmsCredits>{};
        req.contextIdDto = this.contextIdDto;
        req.smsBundleId = smsBundleDto.smsBundleId;
        if (res && res.couponCode === coupon) {
          this.validCoupon = true;
          this.coupon = res;
          req.couponCode = this.coupon.couponCode;
        } else {
          this.validCoupon = false;
          this.coupon = <Coupon>{};
          this.coupon.couponCode = coupon;
        }

        this.smsSettingsCompApi.topup(req).subscribe(response => {
          if (response.errorMessage && response.errorMessage.length > 0) {
            this.notify.message = response.errorMessage;
            this.notify.open();
          } else {
            this.notify.message = 'Successful top up!';
            this.notify.open();
            this.smsCreditService.loadSMSReport(this.contextIdDto);
          }
          this.buying = false;
        }, error => {
          this.buying = false;
          if (error.status === 404) {
            this.notify.message = 'Unable to top up as no card setup';
            this.notify.open();
          } else {
            this.notify.message = 'Unable to take payment to top up!';
            this.notify.open();
          }
        });
      });
    } else {
      const req = <TopupSmsCredits>{};
      req.contextIdDto = this.contextIdDto;
      req.smsBundleId = smsBundleDto.smsBundleId;

      this.smsSettingsCompApi.topup(req).subscribe(response => {
        if (response.errorMessage && response.errorMessage.length > 0) {
          this.notify.message = response.errorMessage;
          this.notify.open();
        } else {
          this.notify.message = 'Successful top up!';
          this.notify.open();
          this.smsCreditService.loadSMSReport(this.contextIdDto);
        }
        this.buying = false;
      }, error => {
        this.buying = false;
        if (error.status === 404) {
          this.notify.message = 'Unable to top up as no card setup';
          this.notify.open();
        } else {
          this.notify.message = 'Unable to take payment to top up!';
          this.notify.open();
        }
      });
    }

  }

  loadSmsUsed() {

    console.log('inside load sms sent');

    if (this.smsCreditStateService?.smsCreditSummaryDto.smsUsedThisMonth > 0) {
      const dialogRef = this.dialog.open(UsedSmsComponent, {
        data: {
          contextIdDto: this.contextIdDto
        },
        maxWidth: '1024px',
        // maxHeight: '80vh',
        // height: '80vh',
        panelClass: [
          'modal-100',
          'helpwindow',
          'petProfileModal'
        ],
        autoFocus: false
      });

      dialogRef.afterClosed()
        .subscribe(() => {

        });
    }
  }

  discountCodeChanged() {
    const coupon = this.coupon.couponCode;
    if (coupon) {
      this.couponsService.searchCouponByCodeAndUsage(coupon, 'SMS_TOP_UP').subscribe(res => {
        if (res && res.couponCode === coupon) {
          this.validCoupon = true;
          this.coupon = res;
        } else {
          this.validCoupon = false;
          this.coupon = <Coupon>{};
          this.coupon.couponCode = coupon;
        }
      });
    }

  }


}
