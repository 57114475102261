import { Component, Input, OnInit } from '@angular/core';

import { EventBusService } from './EventBusService';
import {
  FlagcompService, ContextIdDto,
  ListFlagsCompResponse,
  ListFlagsForLinkIdComp
} from '@savvy/flags';
import { LinkId } from 'src/app/core/data-share.service';

@Component({
  selector: 'app-list-flags',
  templateUrl: 'listFlags.component.html'
})

export class ListFlagsComponent implements OnInit {

  @Input() linkId: LinkId;
  @Input() contextIdDto: ContextIdDto;

  eventBus = new EventBusService();
  flagsResponse: ListFlagsCompResponse;


  constructor(private flagCompApi: FlagcompService) { }

  ngOnInit() {
    this.listFlags();
  }

  private listFlags() {
    const req = <ListFlagsForLinkIdComp>{};
    req.contextIdDto = this.contextIdDto;

    if (this.linkId) {
      req.linkId = this.linkId;

      this.flagCompApi.listFlagsForLinkIdComp(req).subscribe(response => {
        console.log('response from list flags:' + response.flagDto.length);
        this.flagsResponse = response;
      });
    }
  }
}

