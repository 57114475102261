<div class="flexbox-parent" *ngIf="planDefinitions && planDefinitions.length == 0">
  <div fxLayout="row" fxLayoutAlign="center center" style="height: 60vh;  ">
    <div style="text-align:center;">
      <app-zero-image name="plan"></app-zero-image><br>
      <h5>{{'NO_PLANS_YET' | translate}}</h5><br>
    </div>
  </div>
</div>
<div *ngIf="planDefinitions && planDefinitions.length > 0">
  <ngx-datatable class='material has-plus-btn' [rows]="planDefinitions" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [externalPaging]="true" [count]="page.totalElements" [offset]="page.pageNumber" [limit]="page.size" (sort)="onSort($event)" (page)='setPage($event)'>

    <ngx-datatable-column name="{{'NAME' | translate}}">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
        <div>{{ row.name }}</div>
        <div class="numCustomers">{{ getCustomerCount(row.id)}} {{'CUSTOMER(S)' | translate}}</div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'COST' | translate}} / {{'SESSIONS' | translate}}" [width]="60">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
        {{ row.cost }}<br>
        <p class="mat-text-secondary small">{{ row.unlimitedSessions ? 'Unlimited' : row.numberOfSessions + ' Sessions' }}</p>
      </ng-template>
    </ngx-datatable-column>
    <!-- <ngx-datatable-column name="{{'SESSIONS' | translate}}" [width]="80">
      <ng-template ngx-datatable-cell-template let-row="row">
        {{ row.unlimitedSessions ? 'Unlimited' : row.numberOfSessions + ' Sessions' }}
      </ng-template>
    </ngx-datatable-column> -->
    <ngx-datatable-column name="{{'PAYMENT_SCHEDULE' | translate}}">
      <ng-template ngx-datatable-cell-template let-row="row">
        {{ row.paymentSchedule === 'IMMEDIATE' ? 'Immediate' : 'Recurring' }}<br>
        <p class="mat-text-secondary small">{{ row.paymentInterval === 'MONTHLY' ? 'Monthly' : 'Annually' }}</p>
      </ng-template>
    </ngx-datatable-column>
    <!-- <ngx-datatable-column name="{{'PAYMENT_INTERVAL' | translate}}">
      <ng-template ngx-datatable-cell-template let-row="row">
        {{ row.paymentInterval === 'MONTHLY' ? 'Monthly' : 'Annually' }}
      </ng-template>
    </ngx-datatable-column> -->
    <ngx-datatable-column name="{{'PAYMENTS_THIS_MONTH' | translate}}" [width]="200">
      <ng-template ngx-datatable-cell-template let-row="row">
        <span class="d-inline-block mdc-py-1">
          <span class="plan-badge paymentReceived vm" [matBadge]="paymentBadgeCount[row.id] ? paymentBadgeCount[row.id].received : 0" matBadgeOverlap="false"></span><span class="badge-label">{{'RECEIVED' | translate}}</span>
        </span>
        <span class="d-inline-block mdc-py-1">
          <span class="plan-badge paymentRefused vm" [matBadge]="paymentBadgeCount[row.id] ? paymentBadgeCount[row.id].failed : 0" matBadgeOverlap="false"></span><span class="badge-label">{{'REFUSED' | translate}}</span>
        </span><br>
        <span class="d-inline-block mdc-py-1">
          <span class="plan-badge paymentPending vm" [matBadge]="paymentBadgeCount[row.id] ? paymentBadgeCount[row.id].pending : 0" matBadgeOverlap="false"></span><span class="badge-label">{{'WAITING_CARD_DETAILS' | translate}}</span>
        </span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="{{'ACTION' | translate}}" *ngIf="!isModal" [width]="180">
      <ng-template ngx-datatable-cell-template let-row="row">

        <button mat-button color="primary" (click)="viewPlanDef(row)">
          <mat-icon>visibility</mat-icon> <span>View</span>
        </button>
        <button mat-button color="accent" (click)="editPlanDef(row)">
          <mat-icon>edit</mat-icon> <span>Edit</span>
        </button>
        <button mat-button (click)="deletePlanDefinition(row)">
          <mat-icon>delete</mat-icon> <span>Delete</span>
        </button>
      </ng-template>
    </ngx-datatable-column>

  </ngx-datatable>
</div>

<div fxLayout="row" fxLayoutAlign="end" *ngIf="!isModal">
  <button mat-raised-button color="accent" (click)="create()">{{'ADD_NEW' | translate}}</button>
</div>