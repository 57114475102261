import { Component, Input } from '@angular/core';
import { EventBusService } from './EventBusService';
import { ChangeListener } from './changeListener';
import { UntypedFormGroup } from '@angular/forms';
import { ConsumerService } from '@savvy/services';
import { Product } from '@savvy/products';

import {
  FloHtmlElementHolder,
  Invoice,
  InvoiceDefinition,
  InvoicePanelDto,
  UserDto
} from '@savvy/view-definition';
import { ContextIdDto } from '@savvy/quickbooks';

@Component({
  selector: 'app-invoice-services-panel',
  templateUrl: 'invoiceServicesPanel.component.html'
})
export class InvoiceServicesPanelComponent {

  @Input() invoice: Invoice;
  @Input() invoicePanelDto: InvoicePanelDto;
  @Input() userDtos: Array<UserDto>;
  @Input() invoiceDefinition: InvoiceDefinition;
  @Input() consumerServices: Array<ConsumerService>;
  @Input() products: Array<Product>;
  @Input() currencyCode: string;
  @Input() allowMultipleServices: boolean;
  @Input() createEiInline: boolean;
  @Input() eiExtraFields: Array<FloHtmlElementHolder>;


  @Input() viewContext: any;
  @Input() contextIdDto: ContextIdDto;
  @Input() additionalDataMapDto: any;
  @Input() eventBus: EventBusService;
  @Input() form: UntypedFormGroup;
  @Input() changeListener: ChangeListener;
}

