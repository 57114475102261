import { ErrorHandler, Injectable } from '@angular/core';

import * as Sentry from '@sentry/browser';
import { environment } from '../../../environments/environment';

export class SentryErrorHandler implements ErrorHandler {
  constructor() { }
  handleError(error) {

    if (error && error.status !== 401 && environment.production) {
      const exception = error?.message || error.error || error.originalError || error;
      Sentry.captureException(exception, { tags: { version: environment.VERSION } });
    }
  }
}
