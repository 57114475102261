import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ContextIdDto } from '@savvy/datalist';
import { EicompService, EntityChangeEvent } from '@savvy/entity-instance-composite';
import { WorkflowDefinitionId } from '@savvy/ui';
import { ElementInstanceDto, WorkflowFieldDefinitionDto } from '@savvy/view-composite';
import { WorkflowdefService, WorkflowDefinition } from '@savvy/workflow-definition';

@Component({
  selector: 'app-workflow-definition-field-instance',
  templateUrl: 'workflowDefinitionFieldInstance.component.html'
})
export class WorkflowDefinitionFieldInstanceComponent implements OnInit {

  @Input() workflowFieldDefinition: WorkflowFieldDefinitionDto;
  @Input() elementInstanceDto: ElementInstanceDto;
  @Input() entityInstanceId: string;

  @Input() contextIdDto: ContextIdDto;
  @Input() additionalDataMapDto: any;
  @Input() form: UntypedFormGroup;
  @Input() showPlaceholder: boolean;
  @Input() viewContext: any;

  workflowDefs: Array<WorkflowDefinition>;

  constructor(
    private uiApi: EicompService,
    private workflowApi: WorkflowdefService) {
  }

  ngOnInit(): void {
    console.log('loading workflow defs for ed ' + this.viewContext.entityDefinitionId.id);

    console.log('workflow field def name ', this.workflowFieldDefinition.name);


      this.workflowApi.getWorkflowDefsByEntityType(
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.viewContext.entityDefinitionId.id
      ).subscribe(response => {
        console.log('got ', response.workflowDefinitions);
        this.workflowDefs = response.workflowDefinitions;
      });


    this.form.controls[this.elementInstanceDto.instanceId].valueChanges.subscribe((value) =>  {
      // console.log('changed value=' + value);
      this.onChange(value);
    });
  }

  get isValid() {
    return this.form.controls[this.elementInstanceDto.instanceId].valid;
  }

  onChange(workflowDefinitionId: string) {
    console.log('workflow change detected, id is now ' + workflowDefinitionId);
    const workflowDefId = <WorkflowDefinitionId>{};
    workflowDefId.id = workflowDefinitionId;
    this.elementInstanceDto.fieldInstanceDto.value.workflowFieldValue.workflowDefinitionId = workflowDefId;
    const req: EntityChangeEvent = {
      contextIdDto: this.contextIdDto,
      fieldValue: this.elementInstanceDto.fieldInstanceDto.value,
      entityInstanceId: this.entityInstanceId,
      fieldInstanceId: this.elementInstanceDto.instanceId
    };

    this.uiApi.entityInstanceChangeEvent(req).subscribe(
      response => {
        console.log('updated the workflow form valid ' + this.form.valid);
        // console.log('calling detect changes, this element is ' + this.elementInstanceDto.instanceId);
        // this.cd.detectChanges();
        // console.log('after detect changes form valid ' + this.form.valid);
        // console.log('calling getFormValidationErrors');
        // this.ecs.getFormValidationErrors(this.form);
      }
    );
  }
}

