import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ElementControlService } from './ElementControlService';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewcompService, LoadEiViewResponse } from '@savvy/view-composite';
import { ChangeListener } from './changeListener';
import { EventBusService } from './EventBusService';
import { NavChangeService } from './NavChangeService';
import { EventType, UiEvent } from '../event/UiEvent';
import { EventHandlerService } from '../entry/EventHandlerService';
import { NavigateService } from '../entry/NavigateService';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '../shared/components/confirmation-modal/confirmation-modal.component';
import { Location } from '@angular/common';
import {ContextIdDto, EicompService} from '@savvy/entity-instance-composite';

@Component({
  selector: 'app-edit-entity',
  templateUrl: './editEntity.component.html',
  styleUrls: ['editEntity.component.scss'],
  providers: [ElementControlService]
})
export class EditEntityComponent implements OnInit, OnDestroy {

  entityInstanceId: string;
  contextIdDto: ContextIdDto;
  loadEiViewResponse: LoadEiViewResponse;
  eventBus = new EventBusService();

  public form: UntypedFormGroup; // our form model

  sub: any;

  changeListener = new ChangeListener();

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    private router: Router,
    private location: Location,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private navChange: NavChangeService,
    private ecs: ElementControlService,
    private navigateService: NavigateService,
    private eventHandler: EventHandlerService,
    private viewCompService: ViewcompService,
    private eicompService: EicompService) {

  //  this.changeListener.eventAdded$.subscribe(event => this.onEvent(event));
    this.eventBus.eventAdded$.subscribe(event => this.onEvent(event));
  }

  ngOnInit(): void {

    this.sub = this.route.params.subscribe(params => {
      if (Object.entries(params).length === 0) {
        this.entityInstanceId = this.data.entityInstanceId;
        this.contextIdDto = this.data.contextIdDto;
        this.load(this.entityInstanceId, this.contextIdDto);
      } else {
        this.entityInstanceId = params['entityInstanceId'];
        const contextId = params['contextId'];
        const contextIdType = params['contextIdType'];
        console.log('got contextId ' + contextId);
        console.log(contextId, contextIdType);
        this.contextIdDto = { contextId, contextIdType };
        this.load(this.entityInstanceId, this.contextIdDto);
      }
    });
  }

  private load(entityInstanceId: string, contextIdDto: ContextIdDto) {
    console.log('loading entity to view??? loadRiToView');
     this.viewCompService.loadEiToEdit(entityInstanceId, contextIdDto.contextId,
       contextIdDto.contextIdType).subscribe(
         response => {

           this.handleResponse(response);
         });
  }

  private handleResponse(response: LoadEiViewResponse) {
    console.log('in on init edit entity ' + this.entityInstanceId + ' and ' + this.contextIdDto.contextId);
    this.createForm(response);
    this.loadEiViewResponse = response;
    this.navChange.add('editEntity:' +
      response.viewContextDto.addEditEntityInstanceContextDto.entityDefinitionDto.entityDefinitionId.id);
  }

  createForm(loadEiViewResponse: LoadEiViewResponse) {
    if (loadEiViewResponse.viewRootElementDto != null) {
      // @ts-ignore
      this.form = this.ecs.toFormGroup(loadEiViewResponse.viewRootElementDto,
        // @ts-ignore
        loadEiViewResponse.viewContextDto.addEditEntityInstanceContextDto.entityDefinitionDto);
    } else {
      this.form = this.ecs.emptyFormGroup();
    }
  }

  ngOnDestroy() {
    // this.sub.unsubscribe();
  }

  cancelIt() {

    this.router.navigate(['/entity/listEntityInsts',
      this.loadEiViewResponse.viewContextDto.addEditEntityInstanceContextDto.entityDefinitionDto.id,
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
    ]);
  }

  onSubmit() {
    const payload = JSON.stringify(this.form.value);
    console.log('payload ' + payload);
  }

  private onEvent(event: UiEvent): void {
    console.log('lkl:onEvent called with event ' + event);
    this.eventHandler.handleEvent(this.contextIdDto, event);

    if (event.eventType === EventType.DELETE_ENTITY_INSTANCE) {
      console.log('got event and DELETING NOW');
      console.log('deleting 124');

      this.eicompService.deleteEntityInstance(
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        event.entityInstanceId.id,
        false)
        .subscribe(
          response => {
            // We want to go back to the menu!!

            // Redirect back to current menu
            this.navigateService.listEntities(this.contextIdDto, this.loadEiViewResponse.viewContextDto.entityDefinitionId);
          }
        );
    }
    /*if (event === 'reload') {
        console.log('reloading in CreateEntityComponent');
        this.load();
    }*/
  }

  backToList() {
    this.location.back();
  }

  getName() {

    if (this.loadEiViewResponse && this.loadEiViewResponse.viewContextDto && this.loadEiViewResponse.viewContextDto.addEditEntityInstanceContextDto) {
        return this.loadEiViewResponse.viewContextDto.addEditEntityInstanceContextDto.entityDefinitionDto.name;
    }
    return '';
  }

  removeEntity() {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        name: this.getName()
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.eventBus.addEntityEvent(EventType.DELETE_ENTITY_INSTANCE, this.loadEiViewResponse.viewContextDto.entityInstanceId);
      }
    });
  }
}

