<mat-card appearance="outlined" *ngIf="reminders != null && reminders.length > 0" class="panel new-card">
  <mat-card-header>
    <mat-card-title>
      <div fxLayout="row">
        <div fxFlex="100">
          <p class="mt-xs mb-xs">
            {{ reminders.length}}
            {{'Reminders'| translate}}
            <small>{{'more' | translate}}</small>
          </p>
        </div>
      </div>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content fxLayout="column">
    <mat-list class="reminders" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start baseline" *ngFor="let reminder of reminders">
      <div fxLayout="row" fxLayoutAlign="start center">
        <mat-chip-listbox>
          <mat-chip-option *ngIf="sent(reminder)" [matTooltip]="getTooltip(reminder)" color="primary" selected>
            Sent
          </mat-chip-option>
          <mat-chip-option style="line-height: 14px;" [matTooltip]="getTooltip(reminder)" *ngIf="!sent(reminder)" color="primary" selected>
            Not Sent {{ getSmsError(reminder) }}
          </mat-chip-option>

        </mat-chip-listbox>
        &nbsp;&nbsp;
        <button *ngIf="!sent(reminder) && !inThePast(reminder)" (click)="deleteReminder(reminder)" class="ml-xs mat-icon-button" mat-icon-button>
          <span class="mat-button-wrapper">
            <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">delete</mat-icon>
          </span>
          <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
          <div class="mat-button-focus-overlay"></div>
        </button>
      </div>


      <p class="reminder-action" fxLayout="row wrap" fxLayoutAlign="space-between baseline" *ngIf="sent(reminder)" matLine><span class="mr-1">{{'Reminder sent at ' | translate}}
          {{reminder.smsReminder?.sendReminderResult?.timeSent | localizedDate:'h:mm a on d MMM'}}</span>
        <button mat-raised-button color="accent" [matTooltip]="getMessageText(reminder)" (click)="send(reminder)">{{'Re-Send' | translate}}</button>

      </p>
      <p class="reminder-action" fxLayout="row wrap" fxLayoutAlign="space-between baseline" *ngIf="!sent(reminder) && inThePast(reminder)" matLine>
        <span class="mr-1">{{reminder.dueToSend | localizedDate:'h:mm a on d MMM'}} {{' is in the past ' | translate}}</span>
        <button mat-raised-button color="accent" [matTooltip]="getErrorText(reminder)" (click)="send(reminder)">{{'Send Anyway' | translate}}</button>
      </p>
      <p class="reminder-action" fxLayout="row wrap" fxLayoutAlign="space-between baseline" *ngIf="!sent(reminder) && !inThePast(reminder)" matLine>
        {{'Due ' | translate}} {{reminder.dueToSend | localizedDate:'h:mm a on d MMM'}}
        &nbsp;&nbsp;
        <button *ngIf="!sendingReminder" mat-raised-button color="accent" [matTooltip]="getMessageText(reminder)" (click)="send(reminder)">{{'Send Now' | translate}}</button>
        <mat-spinner *ngIf="sendingReminder"></mat-spinner>
      </p>
    </mat-list>
  </mat-card-content>
</mat-card>





<mat-card appearance="outlined" *ngIf="reminders != null && reminders.length === 0" class="panel new-card">

  <mat-card-content>
    <div class="flexbox-parent">
      <div fxLayout="row" fxLayoutAlign="center center">
        <div style="text-align:center;">
          <app-zero-image name="reminder"></app-zero-image>
          <br>
          <h5 class="ma-0">{{'You don’t have any Reminders' | translate}}</h5>
          <br>
          <br>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
