import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ContextService } from '../../context.service';
import { SnackBarService } from './snackbar.service';
import { Router } from '@angular/router';
import { TrialExpiredPopupComponent } from '../components/trial-expired/trial-expired-popup.component';
import { TrialExpiredStateService } from './trial-expired-state.service';
import { forkJoin } from 'rxjs';
import { SubscriptionStateDto } from '@savvy/subscription';

@Injectable()
export class TrialExpiredService {

  constructor(
    private snackBar: MatSnackBar,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
    private trialExpiredStateService: TrialExpiredStateService  ) {
  }


  isTrialExpired() {
    return this.trialExpiredStateService.isTrialExpired();
  }

  isInTrial() {
    return this.trialExpiredStateService.isInTrial();
  }



  checkTrialExpired(contextIdDto) {
    console.log('checkTrialExpired', this.trialExpiredStateService.subscriptionState);
    if (this.trialExpiredStateService.subscriptionState) {
      const subscriptionStatus = this.trialExpiredStateService?.subscriptionState?.subscriptionStatus;
      const numTrialDaysLeft = this.trialExpiredStateService.subscriptionState.numTrialDaysLeft || 0;
      console.log('numTrialDaysLeft', numTrialDaysLeft);
      console.log('subscriptionStatus', subscriptionStatus);
      if (subscriptionStatus === 'IN_TRIAL_PERIOD' && numTrialDaysLeft > 0) {
        forkJoin([
          this.translateService.get('Your trial account will expire in'),
          this.translateService.get('day'),
          this.translateService.get('days'),
          this.translateService.get('please update your plan')
        ]).subscribe(([expireTxt, day, days, updatePlan]) => {
          setTimeout(() => {
            this.showNotification(contextIdDto.contextId, `
              ${expireTxt} ${numTrialDaysLeft} ${numTrialDaysLeft === 1 ? day : days}, ${updatePlan}.
             `);
          }, 2000);
        });
      } else if (subscriptionStatus === SubscriptionStateDto.SubscriptionStatusEnum.TrialExpired) {
        forkJoin([
          this.translateService.get('Your trial account has expired')
        ]).subscribe(([expireTxt]) => {
          setTimeout(() => {
            this.showNotification(contextIdDto.contextId, `
              ${expireTxt}
             `);
          }, 2000);
        });

      } else if (subscriptionStatus === SubscriptionStateDto.SubscriptionStatusEnum.Unknown) {
        /*Observable.forkJoin([
          this.translateService.get('You do not have a subscription'),
          this.translateService.get('please update your plan'),
          this.translateService.get('You are now in view only mode')
        ]).subscribe(([expireTxt, updatePlan, viewOnly]) => {
          setTimeout(() => {
            this.showNotification(contextId, `
           ${expireTxt}, ${updatePlan}.  ${viewOnly}
          `);
          }, 2000);
        });*/
      } else if (subscriptionStatus === SubscriptionStateDto.SubscriptionStatusEnum.Cancelled) {
        forkJoin([
          this.translateService.get('Your subscription has been cancelled.')
        ]).subscribe(([expireTxt]) => {
          setTimeout(() => {
            this.showNotification(contextIdDto.contextId, `
              ${expireTxt}
             `);
          }, 2000);
        });
      }
    }
  }

  private showNotification(contextIdDto, text: string, duration = 2000) {
    this.snackBarService.addToQueue(() => this.snackBar.openFromComponent(TrialExpiredPopupComponent, {
        duration,
        data: {
          text,
          contextIdDto,
        },
        panelClass: 'wide-snack-bar'
      }));
  }
}
