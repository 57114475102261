import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Message } from '@savvy/messaging';

@Component({
  selector: 'app-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.scss']
})
export class ChatListComponent implements OnInit {

  @Input() messagesList?: Array<Message>;
  @Output() messageSeletionChanged: EventEmitter<Message> = new EventEmitter();

  messageTypesEnum = Message.TypeEnum;

  filterString: string;
  constructor() { }

  ngOnInit() {
    if (this.messagesList?.length) {
      this.selectMessage(this.messagesList[0]);
    }
  }

  getNoOfDays(dateSent: string) {
    let currentDate = new Date();
    const dateSentDate = new Date(dateSent);
    return Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(),
      currentDate.getDate()) - Date.UTC(dateSentDate.getFullYear(),
        dateSentDate.getMonth(), dateSentDate.getDate())) / (1000 * 60 * 60 * 24));
  }

  selectMessage(message: Message) {
    this.messageSeletionChanged.emit(message);
  }

}
