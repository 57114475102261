import { AfterViewInit, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ElementInstanceDto } from '@savvy/view-definition';

@Component({
    selector: 'app-signature',
    templateUrl: 'signature.component.html'
})
export class SignatureComponent implements AfterViewInit {

    @Input() form: UntypedFormGroup;
    @Input() elementInstanceDto: ElementInstanceDto;

    ngAfterViewInit() {
        // this.signaturePad is now available
    }

    drawComplete() {
        // will be notified of szimek/signature_pad's onEnd event
    }

    drawStart() {
        // will be notified of szimek/signature_pad's onBegin event
        console.log('begin drawing');
    }
}

