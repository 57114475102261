<mat-form-field *ngIf="locations && locations.length && view === 'autocomplete'" [appearance]="appearance"
  class="w-100 mb-0">
  <mat-label>{{'LOCATION' | translate: {Default: 'Location'} }}</mat-label>
  <mat-select id="appointment-select-location" (compareWith)="compareWithFn"
    [placeholder]="'SELECT_LOCATION' | translate: {Default: 'Select Location'} " name="locationIdSelected"
    #locationIdField="ngModel" [(ngModel)]="locationIdSelected" [(value)]="locationIdSelected" (ngModelChange)="locationChanged($event)"
    [required]="required" [disabled]="disabled">
    <mat-option [value]="'all'" *ngIf="selectAllLocation">
      {{'ALL' | translate: {Default: 'All'} }}
    </mat-option>
    <mat-option *ngFor="let location of locations" [value]="location.id">
      {{location.locationName}}
    </mat-option>
  </mat-select>
</mat-form-field>

<ng-container *ngIf="locations && locations.length && view === 'dropdown'">
  <button id="select-location-button" mat-button class="mr-1" [matMenuTriggerFor]="menu">
    {{location?.locationName || 'All'}}
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="locationChanged(location)"
      *ngFor="let location of locations">{{location.locationName}}</button>
    <button mat-menu-item *ngIf="selectAllLocation" (click)="locationChanged('all')">{{'ALL' | translate: {Default: 'All'}
      }}</button>
  </mat-menu>
</ng-container>
