<div class="outer-container">
  <div class="inner-container">
    <img
      [appFixImageOrientation]="imageAvailable(elementInstanceDto) ? getImageUrl(elementInstanceDto.fieldInstanceDto.value.imageFieldValue.fileReferences[0]) : 'assets/images/no-image.jpg'"
      [src]="imageAvailable(elementInstanceDto) ? getImageUrl(elementInstanceDto.fieldInstanceDto.value.imageFieldValue.fileReferences[0]) : 'assets/images/no-image.jpg'"
      style='flex: 0 1 auto;
    height: 100%;'>
  </div>
</div>

<app-image-editor [url]="getUploadUrl()" [preview]="true" [uploadedFiles]="uploadedFiles"
  [maxFileSize]="4048576" [buttonCaption]="'Select or Drop Images!'" [multiple]="imageFieldDefinition.imageFieldDefinition.allowMultiples" (removed)="onRemoved($event)"
  (uploadFinished)="onUploadFinished($event)" (uploadStateChanged)="onUploadStateChanged($event)"
  [headers]="{Authorization: getAuthToken()}"></app-image-editor>

<br>
