import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ContextIdDto } from '@savvy/quickbooks';
import { ActivatedRoute } from '@angular/router';
import { TrialExpiredService } from '../shared/services/trial-expired.service';
import { CachedtableviewdefsService } from '@savvy/view-definition';
import { ListCachedEntityInstancesComponent } from './listCachedEntityInstances.component';

@Component({
  selector: 'app-list-entity-instances',
  templateUrl: 'listEntityInstances.component.html',
  styleUrls: ['./listEntityInstances.component.scss']
})
export class ListEntityInstancesComponent implements OnInit {

  @ViewChild('listComp', { static: true }) listComp: ListCachedEntityInstancesComponent;
  @Input() showPlaceholder: boolean;

  contextIdDto: ContextIdDto;
  cachedTableViewDefinition: any;

  constructor(
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    public trialExpiredService: TrialExpiredService,
    private apiDef: CachedtableviewdefsService) {
  }

  ngOnInit() {
    console.log('inside on init listEntityInstances');
    this.route
      .params
      .subscribe(params => {
        console.log('params changed');
        const entityDefinitionId = params['entityDefinitionId'];
        console.log('got entityDefinitionId' + entityDefinitionId);
        if (entityDefinitionId) {
          this.contextIdDto = <ContextIdDto>{};
          this.contextIdDto.contextId = params['contextId'];
          this.contextIdDto.contextIdType = params['contextIdType'];
          this.apiDef.getDefaultListCachedTableViewDefinition(
            entityDefinitionId,
            this.contextIdDto.contextId,
            this.contextIdDto.contextIdType
          ).subscribe(response => {
            console.log('got loadProductsResponse' + response);
            this.cachedTableViewDefinition = response;
            if (response && response.id) {
              this.listComp.loadCachedTableWith(this.contextIdDto, response.id);
            }
          });
        }
      });
  }
}
