import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ContextService } from '../context.service';
import { Router } from '@angular/router';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { ReminderDefinition, ReminderDefsService, EntityInstanceReminder } from '@savvy/reminders';
import { MatSidenav } from '@angular/material/sidenav';
import { ReminderDefService } from '@savvy/reminders';
import { NavChangeService } from '../element/NavChangeService';
import ReminderDefinitionTypeEnum = ReminderDefinition.ReminderDefinitionTypeEnum;
import MenuItemTypeEnum = MenuDefinition.MenuItemTypeEnum;
import { MenuDefinition } from '@savvy/view-definition';
import { ContextIdDto } from '@savvy/menu';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-reminder-defs',
  templateUrl: './reminderDefs.component.html'
})
export class ReminderDefsComponent implements OnInit, OnDestroy {

  @ViewChild('reminderDefPanel', { static: true }) reminderDefPanel: MatSidenav;
  reminderDefs: Array<ReminderDefinition>;
  reminderDef: ReminderDefinition;

  zeroState: boolean;
  selected: ReminderDefinition[] = [];

  columns = [
    { prop: 'name' }
  ];

  contextIdDto: ContextIdDto;
  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private reminderDefApi: ReminderDefService,
    private navChange: NavChangeService,
    private api: ReminderDefsService,
    private notify: FloSnackbarComponent,
    public contextService: ContextService) {
  }

  ngOnInit() {
    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
        this.navChange.add(MenuItemTypeEnum.Reminders);
        this.loadReminderDefs();
      }
    }));
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  cancel() {
    this.router.navigate(['/']);
  }

  loadReminderDefs() {
    this.api.loadReminderDefs(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType).subscribe(
        reminderDefs => {
          console.log('got reminderDefs ' + reminderDefs);
          this.reminderDefs = reminderDefs;

          // console.log('got hour ' + reminderDefs[0].reminderScheduleList[0].daysBeforeSchedule.timeToSend);

        }
      );
  }

  onSelect(event) {
    this.reminderDef = this.selected[0];
    this.reminderDefPanel.open();
  }

  editReminder(reminderDef: ReminderDefinition) {
    this.reminderDef = reminderDef;
    this.reminderDefPanel.open();
  }

  reminderCreated() {
    this.reminderDefPanel.close();
    this.loadReminderDefs();
  }

  reminderDeleted() {
    this.reminderDefPanel.close();
    this.loadReminderDefs();
  }

  reminderCancelled() {
    this.reminderDefPanel.close();
  }

  reminderSelected(reminderDef: ReminderDefinition) {
    this.reminderDef = reminderDef;
    this.reminderDefPanel.open();
  }

  createReminder() {
    this.reminderDef = <ReminderDefinition>{};
    this.reminderDef.reminderDefinitionType = ReminderDefinitionTypeEnum.EntityInstance;
    this.reminderDef.entityInstanceReminder = <EntityInstanceReminder>{};
    this.reminderDef.emailReminderDetails = <any>{};
    this.reminderDef.smsReminderDetails = <any>{};
    this.reminderDef.reminderScheduleList = new Array(0);
    this.reminderDef.ownerId = this.contextIdDto.contextId;
    this.reminderDefPanel.open();
  }
}
