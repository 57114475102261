<h1 mat-dialog-title>{{'MESSAGE' | translate}}</h1>
<form [formGroup]="workflowStateForm">

  <div mat-dialog-content>
    <div *ngIf="allowEditState">
      <mat-form-field style="width: 100%">
        <mat-label>{{'MESSAGE_NAME' | translate}}</mat-label>
        <input matInput placeholder="{{'MESSAGE_NAME' | translate}}" class="form-control" formControlName="stateName"
          autofocus required>
      </mat-form-field>
    </div>
    <div class="mt-1">
      <mat-form-field style="width: 100%">
        <mat-label>{{'COLOUR' | translate}}</mat-label>
        <input matInput placeholder="{{'COLOUR' | translate}}" class="form-control" formControlName="color"
          [(colorPicker)]="stateColor" [style.background]="stateColor" [value]="stateColor"
          (colorPickerChange)="onColorChange($event)">
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions [align]="'end'">
    <button mat-button (click)="cancel()">{{'CANCEL' | translate}}</button>
    <button *ngIf="!data.state && allowEditState" mat-raised-button color="primary" (click)="createState()"
      [disabled]="!workflowStateForm.valid" disabled="disabled" mat-button>{{'CREATE' | translate}}</button>
    <button *ngIf="data.state" mat-raised-button color="primary" (click)="updateState()"
      [disabled]="!workflowStateForm.valid" disabled="disabled" mat-button>{{'UPDATE' | translate}}</button>
  </div>
</form>
