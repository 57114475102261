<ngx-datatable *ngIf="viewAppointmentHistoryDataEmbedded" class='no-shadow material has-plus-btn mx-0' [columnMode]="'force'" [rows]='viewAppointmentHistoryDataEmbedded.appointmentData' [headerHeight]="'auto'" [rowHeight]="'auto'" [footerHeight]="0">


  <ngx-datatable-column name="{{'Date' | translate}}" [width]="20">
    <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">

      <!--
      blades?: string;
    entityInstanceId?: EntityInstanceId;
    label?: string;
    notes?: string;
    -->
      {{row.label}}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{'Blades' | translate}}" [width]="10">
    <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">

      <!--
      blades?: string;
    entityInstanceId?: EntityInstanceId;
    label?: string;
    notes?: string;
    -->
      {{row.blades}}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{'Notes' | translate}}" [width]="70">
    <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">

      <!--
      blades?: string;
    entityInstanceId?: EntityInstanceId;
    label?: string;
    notes?: string;
    -->
      {{row.notes}}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="{{'Last Paid' | translate}}" [width]="5">
    <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
      <button mat-raised-button color="accent" (click)="viewAppointment(row)">View Invoice</button>
    </ng-template>
  </ngx-datatable-column>



  <!--ngx-datatable-footer>

      </ngx-datatable-footer-->
</ngx-datatable>