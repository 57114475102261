import { Component, Input, OnInit } from '@angular/core';
import { EntitysService } from '@savvy/entity-instance';
import { UntypedFormGroup } from '@angular/forms';
import { ChangeListener } from './changeListener';
import { EventBusService } from './EventBusService';
import { ContextIdDto, SplitPanelDefinitionDto } from '@savvy/view-definition';

@Component({
    selector: 'app-split-panel',
    templateUrl: 'splitPanel.component.html',
    styleUrls: ['splitPanel.component.scss']

})
export class SplitPanelComponent implements OnInit {

    @Input() splitPanelDefinitionDto: SplitPanelDefinitionDto;
    @Input() contextIdDto: ContextIdDto;
    @Input() viewContext: any;
    @Input() form: UntypedFormGroup;
    @Input() eventBus: EventBusService;
    @Input() additionalDataMapDto: any;
    @Input() showPlaceholder: boolean;
    @Input() changeListener: ChangeListener;

    constructor(private api: EntitysService) {
    }

    ngOnInit(): void {


    }

    getPercent() {
        if (this.splitPanelDefinitionDto.percentPad) {
            return 50 - (this.splitPanelDefinitionDto.percentPad / 2);
        }
        return 50;
    }

}
