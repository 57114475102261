import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  ActionDefinition,
  ContextIdDto,
  RoleId,
  SendMessageDefinition,
  SendSmsDefinition,
  TaskSettings
} from '@savvy/entity-instance-composite';
import {
  WorkflowdefService
} from '@savvy/workflow-definition';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { AddEditWorkflowMessageDefinitionComponent } from './addEditWorkflowMessageDefinition.component';


@Component({
  selector: 'app-edit-workflow-panel',
  templateUrl: 'editWorkflowPanel.component.html',
  styleUrls: ['./editWorkflowPanel.component.scss']
})
export class EditWorkflowPanelComponent implements OnInit {

  workflowDef: any;
  contextIdDto: ContextIdDto;
  response: any;
  workflowState: any;
  workflowDefinitionId: string;
  stateId: string;
  selectedTaskDef: any;

  columns = [
    {
      name: 'Task Name',
      prop: 'taskSettings.taskName'
    }
  ];
  selected: any[] = [];

  @ViewChild('sidePanel', { static: false }) sidePanel: MatSidenav;
  @ViewChild('addEditWorkflowMessageDef', { static: false }) addEditWorkflowMessageDef: AddEditWorkflowMessageDefinitionComponent;

  constructor(
    private route: ActivatedRoute,
    private notify: FloSnackbarComponent,
    private router: Router,
    private translateService: TranslateService,
    private api: WorkflowdefService) {

  }

  ngOnInit(): void {
    console.log('inside workflow component');

    this.route.params.subscribe(params => {
      this.contextIdDto = <ContextIdDto>{};
      if (params['contextId'] && params['contextIdType']) {
        this.contextIdDto.contextId = params['contextId'];
        this.contextIdDto.contextIdType = params['contextIdType'];
        console.log('Set context id dto to ' + this.contextIdDto.contextId);
        const workflowDefinitionId = params['workflowDefinitionId'];
        if (workflowDefinitionId) {
          console.log('Got workflowDefinitionId ' + workflowDefinitionId);
          this.loadWorkflowDetail(workflowDefinitionId);
        } else {
          this.workflowDef = <any>{};
        }
      }
    });
  }

  createTask(workflowState: any) {
    this.workflowState = workflowState;
    this.selectedTaskDef = <any>{};
    this.initialise(this.selectedTaskDef);
    this.sidePanel.open();
  }

  taskRemoved() {
    this.sidePanel.close();
    this.loadWorkflowState();
  }
  loadWorkflowState() {
    console.log('reloading workflow state');
    this.api.getWorkflowDef(this.contextIdDto.contextId, this.contextIdDto.contextIdType,
      this.workflowDefinitionId).subscribe(workflowDef => {
        workflowDef.workflowStateList.forEach(state => {
          if (state.id === this.stateId) {
            this.workflowState = state;
            if (!this.workflowState.workflowTaskDefinitionList) {
              this.workflowState.workflowTaskDefinitionList = new Array();
            }
          } else {
            console.log('ignoring state as it is not id ' + this.stateId);
          }
        });
      });
  }
  loadWorkflowDetail(workflowDefinitionId) {

    console.log('loading all  workflows');
    this.api.getAllWorkflowDefsForEdit(this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(response => {
      console.log('got all workflows');
      this.response = response;
      console.log('loading workflow ' + workflowDefinitionId);
      this.api.getWorkflowDef(this.contextIdDto.contextId, this.contextIdDto.contextIdType,
        workflowDefinitionId).subscribe(workflowDef => {
          console.log('got workflow');
          this.workflowDef = workflowDef;
          this.workflowDefinitionId = workflowDef.id;
        });
    });
  }


  reloadWorkflow() {
    this.api.getWorkflowDef(this.contextIdDto.contextId, this.contextIdDto.contextIdType,
      this.workflowDefinitionId).subscribe(workflowDef => {
        console.log('got workflow');
        this.workflowDef = workflowDef;
        this.workflowDefinitionId = workflowDef.id;
      });
  }

  setColor(newColor: string, workflowState) {
    console.log('newColor ' + newColor);
    const req = <any>{};
    req.contextIdDto = this.contextIdDto;
    console.log('updating state with id ' + this.workflowDefinitionId);
    req.workflowDefinitionId = this.workflowDefinitionId;
    req.stateId = workflowState.id;
    req.colour = newColor;

    this.api.updateStateColour(req).subscribe(
      () => {
        this.translateService.get('Updated State Colour').subscribe(value => {
          this.notify.message = value;
          this.notify.open();
        });
      });
  }

  createState() {
    const req = <any>{};
    req.contextIdDto = this.contextIdDto;
    console.log('creating state with id ' + this.workflowDefinitionId);
    req.workflowDefinitionId = this.workflowDefinitionId;
    req.newState = this.workflowState.state;
    this.api.addStateToWorkflow(req).subscribe(
      response => {
        this.workflowState = response.newWorkflowState;
        this.translateService.get('Created State').subscribe(value => {
          this.notify.message = value;
          this.notify.open();
        });
        this.router.navigate(['/workflow/addEditWorkflowState',
          this.workflowDefinitionId,
          this.workflowState.id,
          this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
        ]);
      });
  }

  deleteState() {
    const req = <any>{};
    req.contextIdDto = this.contextIdDto;
    req.workflowDefinitionId = this.workflowDefinitionId;
    req.stateId = this.workflowState.id;
    this.api.removeStateFromWorkflow(req).subscribe(() => {
      this.translateService.get('Removed state "' + this.workflowState.state + '" from workflow').subscribe(value => {
        this.notify.message = value;
        this.notify.open();
      });
      // Go back to workflow
      this.router.navigate(['/workflow/workflows',
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType]);
    });
  }

  onSelect(event, workflowState) {
    console.log('Event: select', event, this.selected[0]);
    this.initialise(this.selected[0]);
    this.workflowState = workflowState;
    this.selectedTaskDef = this.selected[0];
    this.sidePanel.open();
  }

  initialise(taskDef) {
    if (!taskDef.taskSettings) {
      taskDef.taskSettings = <TaskSettings>{};
      taskDef.taskSettings.taskAssignToRoles = <RoleId>{};
    }
    if (!taskDef.postTaskDefinition) {
      console.log('initialising post task def');
      taskDef.postTaskDefinition = <any>{};
    }
    if (!taskDef.autoTaskDefinition) {
      taskDef.autoTaskDefinition = <any>{};
    }
    if (!taskDef.manualTaskDefinition) {
      taskDef.manualTaskDefinition = <any>{};
      // taskDef.manualTaskDefinition.dueDateSchedule = createSchedule();
    }
    if (!taskDef.actionDefinition) {
      taskDef.actionDefinition = <any>{};
      taskDef.actionDefinition.sendMessageDefinition = <SendMessageDefinition>{};
      taskDef.actionDefinition.actionType = ActionDefinition.ActionTypeEnum.SendMessage;
      taskDef.actionDefinition.sendSmsDefinition = <SendSmsDefinition>{};
    }
  }
}

