import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContextIdDto } from '@savvy/view-definition';
import { EventBusService } from '../EventBusService';
import { ConsumerService, ServicesService} from '@savvy/services';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';

export interface AddServiceDialogData {
  contextIdDto: ContextIdDto;
  serviceLabel: string;
  eventBus: EventBusService;
}

@Component({
  selector: 'app-add-service-dialog',
  templateUrl: './addServiceDialog.component.html'
})
export class AddServiceDialogComponent implements OnInit {

  contextIdDto: ContextIdDto;
  eventBus: EventBusService;
  serviceLabel: string;
  consumerService: ConsumerService;

  constructor(
    public dialogRef: MatDialogRef<AddServiceDialogComponent>,
    private servicesService: ServicesService,
    @Inject(MAT_DIALOG_DATA) public data: AddServiceDialogData,
    private notify: FloSnackbarComponent,
  ) {
    this.contextIdDto = data.contextIdDto;
    this.serviceLabel = data.serviceLabel;
    this.eventBus = data.eventBus;
  }

  ngOnInit(): void {
    this.consumerService = <ConsumerService>{};
    this.consumerService.serviceName = '';
    this.consumerService.durationInMins = 60;
    this.consumerService.unitPrice = 25;
    this.consumerService.ownerId = this.contextIdDto.contextId;
    this.consumerService.colour = '';
  }

  addNew() {

    if (this.consumerService?.serviceName?.trim().length > 0) {
      this.servicesService.createConsumerService(this.consumerService).subscribe((res: ConsumerService) => {
        this.consumerService = res;
        this.dialogRef.close(res);
      });
    } else {
      this.notify.message = 'Please enter valid service name';
      this.notify.open();
    }
  }

  updateColor(newColour: string) {
    this.consumerService.colour = newColour;
  }
}
