import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreatePackage, Package, PackagesCompositeService } from '@savvy/packages';
import { Tax } from '@savvy/tax';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-create-package',
  templateUrl: './create-package.component.html',
  styleUrls: ['./create-package.component.scss']
})
export class CreatePackageComponent implements OnInit {
  packageForm = new UntypedFormGroup({
    id: new UntypedFormControl(null),
    packageName: new UntypedFormControl('', Validators.required),
    packageDurationMins: new UntypedFormControl(null),
    packagePrice: new UntypedFormControl(null, Validators.required),
    taxEntityInstanceId: new UntypedFormControl(null),
    packageColour: new UntypedFormControl(null),
    deleted: new UntypedFormControl(false),
    ownerId: new UntypedFormControl(null, Validators.required),
  });

  constructor(
    public dialogRef: MatDialogRef<CreatePackageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private packagesCompositeService: PackagesCompositeService,
    private spinner: NgxSpinnerService,

  ) { }

  get packageName() {
    return this.packageForm.get('packageName');
  }
  get packagePrice() {
    return this.packageForm.get('packagePrice');
  }

  ngOnInit() {
    if (this.data.edit) {
      this.packageForm.patchValue({
        id: this.data.package?.id,
        packageName: this.data.package?.packageName,
        deleted: this.data.package?.deleted,
        ownerId: this.data.contextIdDto.contextId,
        packageDurationMins: this.data.package?.packageDurationMins,
        packagePrice: this.data.package?.packagePrice,
        taxEntityInstanceId: this.data.package?.taxEntityInstanceId,
        packageColour: this.data.package?.packageColour,
      });
    } else {
      this.packageForm.patchValue({
        packageName: `New Package ${Date.now()}`,
        deleted: false,
        ownerId: this.data.contextIdDto.contextId
      });
    }
  }

  updateTax($event) {
    this.packageForm.patchValue({
      taxEntityInstanceId: $event.value
    });
  }

  close() {
    this.dialogRef.close();
  }

  add() {
    if (this.packageForm.invalid) {
      return;
    }
    const req: CreatePackage = {
      contextIdDto: this.data.contextIdDto,
      apackage: this.packageForm?.value
    };
    if (this.data.edit) {
      this.dialogRef.close(this.packageForm?.value);
    } else {
      this.spinner.show();
      this.packagesCompositeService.create(req).subscribe((apackage: Package) => {
        this.spinner.hide();
        if (apackage) {
          this.dialogRef.close({ ...apackage, ...this.packageForm?.value });
        }
      });
    }
  }

  updateColor(event) {
    this.packageForm.patchValue({
      packageColour: event
    });
  }

}
