import { Component, Input } from '@angular/core';
import { ContextIdDto } from '@savvy/quickbooks';
import { Router } from '@angular/router';
import { EntitysService } from '@savvy/entity-instance';
import { EventBusService } from './EventBusService';
import { TrialExpiredService } from '../shared/services/trial-expired.service';
import { ViewEntityInstanceDto } from '@savvy/view-definition';

@Component({
  selector: 'app-view-entity-instance',
  templateUrl: './viewEntityInstance.component.html'
})
export class ViewEntityInstancePanelComponent {

  @Input() viewEntityInstanceDto: ViewEntityInstanceDto;
  @Input() contextIdDto: ContextIdDto;
  @Input() eventBus: EventBusService;

  constructor(
    public trialExpiredService: TrialExpiredService,
    private eiApi: EntitysService,
    private router: Router) {
  }

  create() {
    this.router.navigate(['/entity/createEntity',
      this.viewEntityInstanceDto.entityDefinitionId.id,
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType]);
  }
}
