import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { FloMaterialModule } from '../flo.material.module';
import { AppInvoiceModule } from '../invoice/invoice.module';
import { SharedModule } from '../shared/shared.module';
import { FlagDefService } from '@savvy/flags';
import { FlagsRoutes } from './flags.routing';
import { FlagDefsByTypeComponent } from './flagDefsByType/flagDefsByType.component';
import { EntityFlagDefsComponent } from './entityFlagDefs.component';
import { AddEditFlagDefComponent } from './addEditFlagDef.component';
import { ViewFlagsByLinkComponent } from './viewFlagsByLink.component';
import { TranslateModule } from '@ngx-translate/core';
import { DeleteFlagConfirmationModalComponent } from "./deleteFlagConfirmationModal/deleteFlagConfirmationModal.component";
import { MatDialogModule } from '@angular/material/dialog';
import {ViewFlagsComponent} from "./viewFlags/view-flags.component";


@NgModule({
  imports: [
    CommonModule,
    AppInvoiceModule,
    SharedModule,
    TranslateModule,
    RouterModule.forChild(FlagsRoutes),
    FloMaterialModule,
    MatDialogModule
  ],
  declarations: [
    ViewFlagsComponent,
    ViewFlagsByLinkComponent,
    AddEditFlagDefComponent,
    FlagDefsByTypeComponent,
    EntityFlagDefsComponent,
    DeleteFlagConfirmationModalComponent,
  ],
  providers: [
    FlagDefService
  ],
  exports: [
    ViewFlagsComponent,
    ViewFlagsByLinkComponent,
    AddEditFlagDefComponent,
    FlagDefsByTypeComponent,
    EntityFlagDefsComponent,
  ]
})
export class AppFlagsModule { }
