import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ContextIdDto } from '@savvy/app';
import { Customer } from '@savvy/customer';
import { MatDialog } from '@angular/material/dialog';
import { MessageService, Message, ListMessagesResponse } from '@savvy/messaging';
import { NewMessageModalComponent } from 'src/app/flo/shared/components/new-message-modal/new-message-modal.component';
import { LinkedIdTypeEnum } from 'src/app/core/data-share.service';
import { TranslateService } from "@ngx-translate/core";
import { Page } from 'src/app/flo/shared/model/page';

@Component({
  selector: 'app-customer-message-details',
  templateUrl: './customer-message-details.component.html',
  styleUrls: ['./customer-message-details.component.scss']
})
export class CustomerMessageDetailsComponent implements OnInit, OnChanges {

  @Input() contextIdDto: ContextIdDto;

  // You can pass EITHER one of these two
  @Input() customerId: string;
  customerDto: Customer;

  messageList: Array<Message>;

  deliveryStatuses = Message.DeliveryStatusEnum;

  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  page = new Page();
  typeEnum = Message.TypeEnum;
  constructor(
    public translateService: TranslateService,
    private messageService: MessageService,
    private dialog: MatDialog) { }

  ngOnInit() {
    if (this.customerId) {
      this.loadMessages();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.customerId?.currentValue !== changes?.customerId?.previousValue) {
      this.loadMessages();
    }
  }

  loadMessages() {
    if (!this.contextIdDto?.contextId
      || !this.contextIdDto?.contextIdType
      || !this.customerId) {
      console.error('Error in getMessagesForLinkedInstanceV3');
      return;
    }
    this.messageService.getMessagesForLinkedInstanceV3(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      this.customerId,
      this.page.pageNumber + 1,
      this.page.size
      // this.viewContext.customerContextDto.customerId.id
    ).subscribe((response: ListMessagesResponse) => {
      this.messageList = [];

      this.messageList = response.messagesList;
      this.page.totalElements = response.totalNumMessages;
      this.page.totalPages = response.totalPages;
    });
  }

  sendMessage() {
    const dialogRef = this.dialog.open(NewMessageModalComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        additionalLinkId: {
          linkedId: this.customerId,
          linkedIdType: LinkedIdTypeEnum.CustomerId
        },
        linkId: {
          linkedId: this.customerId,
          linkedIdType: LinkedIdTypeEnum.CustomerId
        },
        // eventBus: this.eventBus
      },
      autoFocus: false,
      panelClass: ['helpwindow', 'scrollable-modal', 'modal-100']
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.loadMessages();
      }
    });
  }

  sent(message: Message) {
    if (message && message.deliveryStatus) {
      return message.deliveryStatus === Message.DeliveryStatusEnum.Delivered ||
        message.deliveryStatus === Message.DeliveryStatusEnum.Sent;
    }
    return false;
  }

  received(message: Message) {
    if (message && message.deliveryStatus) {
      return message.deliveryStatus === Message.DeliveryStatusEnum.Received;
    }
    return false;
  }

  messageSelected() {
    // this.eventBus.addMessageEvent(EventType.MESSAGE_SELECTED, messageId);
    // this.messageSelectedOutput.emit(messageId.id);
  }

  onScrollDown(ev) {
    console.log("scrolled down!!", ev);
    this.page.pageNumber++;
    if (!this.contextIdDto?.contextId
      || !this.contextIdDto?.contextIdType
      || !this.customerId) {
      console.error('Error in getMessagesForLinkedInstanceV3');
      return;
    }
    this.messageService.getMessagesForLinkedInstanceV3(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      this.customerId,
      (this.page.pageNumber + 1),
      this.page.size
    ).subscribe(response => {

      this.messageList = [...this.messageList, ...response.messagesList];
      this.page.totalElements = response.totalNumMessages;
      this.page.totalPages = response.totalPages;
    });
  }

}
