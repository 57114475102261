import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Contract, ContractService } from '@savvy/contract';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { EventBusService } from '../../element/EventBusService';
import { Location } from '@angular/common';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';
import { ConfirmationModalComponent } from '../../shared/components/confirmation-modal/confirmation-modal.component';
import { ClearObservable } from '../../shared/classes/clear-observable';
import { takeUntil } from 'rxjs/operators';
import { WindowRef } from '../../windowWrapper';
import { UrlLookupService } from '../../shared/services/urlLookup.service';
import { ContextIdDto } from '@savvy/view-definition';
import * as moment from 'moment';
@Component({
  selector: 'app-view-contract',
  templateUrl: './viewContract.component.html',
  styleUrls: ['viewContract.component.scss']
})
export class ViewContractComponent extends ClearObservable implements OnInit {
  @Input() contextIdDto: ContextIdDto;
  @Input() contractId: string;
  @Input() isContractModal: boolean;
  @Input() noToolbar: boolean = false;

  @Output() customerSignEvent = new EventEmitter();
  @Output() contractCreated = new EventEmitter();

  contract: Contract = <Contract>{};
  panelOpenState = false;
  viewWidth = 70;
  dueDate = new Date();
  private sub: any;
  eventBus = new EventBusService();

  @ViewChild('sendContractPanel', { static: false }) sendContractPanel: MatSidenav;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private urlLookupService: UrlLookupService,
    private notify: FloSnackbarComponent,
    private windowRef: WindowRef,
    private location: Location,
    private api: ContractService,
    private dialog: MatDialog) {
    super();
  }

  ngOnInit() {
    this.sub = this.route.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        params => {
          this.contextIdDto = this.contextIdDto ? this.contextIdDto : { contextIdType: params['contextIdType'], contextId: params['contextId'] };
          this.contractId = this.contractId ? this.contractId : params['contractId'];
          this.viewWidth = this.isContractModal ? 100 : 70;

          this.loadContract();
        });
  }

  loadContract() {
    if (this.contractId) {
      // Load contract
      this.api.loadContract1(this.contractId, this.contextIdDto.contextId, this.contextIdDto.contextIdType)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          response => {
            this.handleLoadedContract(response);
          });
    } else {
      this.contract = <Contract>{};
    }
  }


  handleLoadedContract(contract: Contract) {
    this.contract = contract;
    this.dueDate = moment(this.contract.dateCreated).add(7, 'days').toDate();
  }

  cancel() {
    this.sendContractPanel.close();
  }

  contractSent() {
    console.log('sent closing panel');
    this.sendContractPanel.close();
  }

  sendContract() {
    console.log('opening send contract panel');
    this.sendContractPanel.open();
  }

  gotoContract() {

    const url = this.urlLookupService.portalUrl() + '/contract.html?id=' + this.contextIdDto.contextId +
      '&contractId=' + this.contract.id + this.urlLookupService.getEnv();

    this.urlLookupService.openNewTab(url);
  }

  editContract() {
    this.closeModal();
    console.log('going to edit contract ' + this.contract.id);
    this.router.navigate([
      '/contract/editContract',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      this.contract.id
    ]);
  }

  deleteContract() {
    const confirmDialog = this.dialog.open(ConfirmationModalComponent, {
      data: {
        name: 'contract'
      },
      autoFocus: false
    });

    confirmDialog.afterClosed()
      .subscribe(
        res => {
          if (res && this.contract.id) {
            this.api.deleteContract(this.contract.id, this.contextIdDto.contextId, this.contextIdDto.contextIdType)
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                response => {

                  console.log('deleted contract');
                  this.notify.message = 'Deleted Contract';
                  this.notify.open();
                  this.closeModal();
                });
          }
        });
  }

  closeModal() {
    if (this.isContractModal) {
      this.contractCreated.emit(true);
    }
  }

  backToList() {
    this.location.back();
  }
}
