import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ContextIdDto } from '@savvy/app';
import { Page } from '../../../shared/model/page';
import { IconService } from '../../../logo/icon.service';
import { MatDialog } from '@angular/material/dialog';
import { FloSnackbarComponent } from '../../../snackbar/floSnackbar.component';
import { ViewInvoiceV2Component } from 'src/app/flo/appointment/view-invoice-v2/view-invoice-v2.component';
import { BoardingBooking, BoardingBookingForListingDto, BoardingBookingService, PetStayPreference, PetStayPreferenceService } from '@savvy/pet-stay';
import { ViewPetBoardingComponent } from "../../../petdaycare/view-pet-boarding/view-pet-boarding.component";
import { BoardingBookingHandlerService } from "../../../petdaycare/boarding-booking-handler.service";
import { CreateBoardingBookingV2SyncService } from 'src/app/flo/petdaycare/create-boarding-booking-v2/create-boarding-booking-v2-sync.service';
import { CreateBookingEvent } from 'src/app/core/timeline-calendar/timeline-calendar.component';
import { CreateBoardingBookingData, CreateBoardingBookingV2Component } from 'src/app/flo/petdaycare/create-boarding-booking-v2/create-boarding-booking-v2.component';
import * as moment from 'moment';
import { UserCalendarSettingsService, UsersCalendarSettings } from '@savvy/user-calendar-settings';
import { CalendarSettings, CalendarSettingsService } from '@savvy/calendar-settings';
import { IdNameTupleDto } from '@savvy/user';

@Component({
  selector: 'app-view-customer-boardings',
  templateUrl: './view-customer-boardings.component.html',
  styleUrls: ['./view-customer-boardings.component.scss']
})
export class ViewCustomerBoardingsComponent implements OnInit, OnChanges {
  @Input() contextIdDto: ContextIdDto;
  @Input() customerId: string;
  @Input() isViewMode = false;
  @Input() historyMode: 'past' | 'future' | 'ongoing' = 'future';

  svgXml: string;

  futureSelected: BoardingBookingForListingDto[] = [];
  futureBoardings: Array<BoardingBookingForListingDto> = [];

  pastPage = new Page();
  pastSelected: BoardingBookingForListingDto[] = [];
  pastBoardings: Array<BoardingBookingForListingDto> = [];
  selectedBoardings: string[] = [];

  ongoingSelected: BoardingBookingForListingDto[] = [];
  ongoingBoardings: Array<BoardingBookingForListingDto> = [];

  showOnlyCancelled = false;
  petStayPreference: PetStayPreference;
  usersCalendarSettings: UsersCalendarSettings;
  calendarSettings: CalendarSettings;

  constructor(private boardingBookingService: BoardingBookingService,
    private iconService: IconService,
    private notify: FloSnackbarComponent,
    private dialog: MatDialog,
    private boardingBookingHandlerService: BoardingBookingHandlerService,
    private createBoardingBookingV2SyncService: CreateBoardingBookingV2SyncService,
    private petStayPreferenceService: PetStayPreferenceService,
    private userCalendarSettingsService: UserCalendarSettingsService,
    private calendarSettingsService: CalendarSettingsService
  ) {
  }

  loadPetStayPreferences() {
    this.petStayPreferenceService.getPetStayPreferenceByOwnerId(this.contextIdDto.contextId).subscribe(res => {
      if (res) {
        this.petStayPreference = res;
      }
    });
  }

  loadUserCalendarSettings() {
    this.userCalendarSettingsService.getOrCreateCalendarSettings(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
    ).subscribe(response => {
      this.usersCalendarSettings = response;
    });
  }

  loadCalendarSettings() {
    this.calendarSettingsService.getCalendarSettings(
      this.contextIdDto.contextIdType,
      this.contextIdDto.contextId,
    ).subscribe(response => {
      this.calendarSettings = response;
      this.loadUserCalendarSettings();

    });
  }

  ngOnInit() {
    this.loadBoardings();
    this.loadPetStayPreferences();
    this.loadCalendarSettings();
    this.loadUserCalendarSettings();
    this.svgXml = this.iconService.getSvgIcon('Appointments');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.customerId?.currentValue !== changes?.customerId?.previousValue) {
      this.loadBoardings();
    }
  }

  historyModeChanged(event) {
    this.selectedBoardings = [];
    if (event === 'past') {
      this.loadHistory();
    } else if (event === 'ongoing') {
      this.loadOngoing();
    } else if (event === 'future') {
      this.loadFuture();
    }
  }

  callback = (result) => {
    if (result) {
      this.loadBoardings();
    }
  };

  addAppointment() {
    const start = moment(new Date()).startOf('day').toDate();
    const end = moment(new Date()).endOf('day').toDate();
    this.createBooking({ start, end, rowId: '', type: BoardingBooking.BookingTypeEnum.Boarding });
  }

  createBooking(booking: CreateBookingEvent) {
    let startDate = new Date();
    if (booking.start) {
      startDate = new Date(new Date(booking.start.setHours(startDate.getHours())).setMinutes(new Date().getMinutes()));
    }
    const data: CreateBoardingBookingData = {
      petRunId: booking.rowId,
      contextIdDto: this.contextIdDto,
      edit: false,
      startDateToUseOnCreate: startDate,
      end: booking.end || new Date(),
      bookingType: BoardingBooking.BookingTypeEnum.Boarding,
      userToUseWhenCreating: {} as IdNameTupleDto,
      startTime: moment(this.usersCalendarSettings.startDayViewTime, 'HH:mm:ss').format('HH:mm'),
      endTime: moment(this.usersCalendarSettings.endDayViewTime, 'HH:mm:ss').format('HH:mm'),
      calendarSettings: this.calendarSettings,
      usersCalendarSettings: this.usersCalendarSettings,
      locationId: '',
      petStayPreference: this.petStayPreference
    }
    const confirmDialog = this.dialog.open(CreateBoardingBookingV2Component, {
      data,
      panelClass: 'quickcreate',
      autoFocus: false
    });

    confirmDialog.afterClosed().subscribe((result: BoardingBooking) => {
      this.createBoardingBookingV2SyncService.clear();
      if (result) {
        this.loadBoardings();
      }
    });
  }

  loadHistory() {
    if (this.customerId) {
      if (this.showOnlyCancelled) {
        this.boardingBookingService.loadPastCancelledCustomerBoardings(
          this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          this.customerId
        ).subscribe(response => {
          console.log('response', response);
          this.pastBoardings = response;
        });
      } else {
        this.boardingBookingService.loadPastCustomerBoardings(
          this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          this.customerId
        ).subscribe(response => {
          console.log('response', response);
          this.pastBoardings = response;
        });
      }


    } else {
      console.log('no customer');
    }
  }

  loadOngoing() {
    if (this.customerId) {
      if (this.showOnlyCancelled) {
        this.boardingBookingService.loadOngoingCancelledCustomerBoardings(
          this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          this.customerId
        ).subscribe(response => {
          console.log('response', response);
          this.ongoingBoardings = response;
        });
      } else {
        this.boardingBookingService.loadOngoingCustomerBoardings(
          this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          this.customerId
        ).subscribe(response => {
          console.log('response', response);
          this.ongoingBoardings = response;
        });
      }


    } else {
      console.log('no customer');
    }
  }

  loadFuture() {
    if (this.customerId) {
      if (this.showOnlyCancelled) {
        this.boardingBookingService.loadFutureCancelledCustomerBoardings(
          this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          this.customerId
        ).subscribe(response => {
          console.log('response', response);
          this.futureBoardings = response;
        });
      } else {
        this.boardingBookingService.loadFutureCustomerBoardings(
          this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          this.customerId
        ).subscribe(response => {
          console.log('response', response);
          this.futureBoardings = response;
        });
      }


    } else {
      console.log('no customer');
    }
  }

  onFutureSelect(event) {
    if (this.futureSelected && this.futureSelected.length > 0 && !this.isViewMode) {
      this.openViewBoardingModal(this.futureSelected[0]);
    }
  }

  onOngoingSelect(event) {
    if (this.ongoingSelected && this.ongoingSelected.length > 0 && !this.isViewMode) {
      this.openViewBoardingModal(this.ongoingSelected[0]);
    }
  }

  onPastSelect(event) {
    if (this.pastSelected && this.pastSelected.length > 0 && !this.isViewMode) {
      this.openViewBoardingModal(this.pastSelected[0]);
    }
  }

  cancelledFilterChanged(event) {
    this.loadBoardings();
  }


  openViewBoardingModal(boardingBookingDto: BoardingBookingForListingDto) {
    if (!this.isViewMode) {
      const dialogRef = this.dialog.open(ViewPetBoardingComponent, {
        data: {
          contextIdDto: this.contextIdDto,
          boardingBookingId: boardingBookingDto.boardingBooking.id
        },
        autoFocus: false,
        panelClass: 'quickview'
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.rebook) {
            this.boardingBookingHandlerService.rebookBooking(this.contextIdDto, result.boardingBooking, this.petStayPreference, this.callback);
          } else {
            this.boardingBookingHandlerService.editBooking(this.contextIdDto, result.boardingBooking, this.petStayPreference, this.callback);
          }
        } else {
          this.loadBoardings();
        }

      });
    }
  }

  loadPetStayBooking(boardingBookingId: string) {
    return new Promise<BoardingBooking>(resolve => {
      // Perhaps load customer and invoice server side?
      this.boardingBookingService.getBoardingBookingById(boardingBookingId).subscribe(response => {
        this.createBoardingBookingV2SyncService.editMode = true;
        this.createBoardingBookingV2SyncService.populateAppointment(response?.boardingBooking, this.contextIdDto, response?.petStayTimeRanges, this.petStayPreference)
          .then(res => {
            resolve(res);
          });
      });

    });
  }

  openEditAppointmentModal(boardingBookingDto: BoardingBookingForListingDto) {
    if (!this.isViewMode) {
      this.loadPetStayBooking(boardingBookingDto.boardingBooking.id).then((boardingBooking: BoardingBooking) => {
        if (boardingBooking) {
          this.boardingBookingHandlerService.editBooking(this.contextIdDto, boardingBooking, this.petStayPreference, this.callback);
        }
      });
    }
  }

  viewInvoice(invoiceId: string) {
    if (invoiceId) {
      // console.log('inside view invoiceId ', invoiceId);
      const dialogRef = this.dialog.open(ViewInvoiceV2Component, {
        maxWidth: '800px',
        width: '800px',
        data: {
          invoiceId,
          contextIdDto: this.contextIdDto
        },
        panelClass: ['scrollable-modal', 'invoicemodal']
      });

      dialogRef.afterClosed().subscribe((result) => {

      });
    }
  }

  selectAppointment(boardingBookingId: string) {
    if (boardingBookingId) {
      const index = this.selectedBoardings.indexOf(boardingBookingId);
      if (index > -1) {
        this.selectedBoardings.splice(index, 1);
      } else {
        this.selectedBoardings.push(boardingBookingId);
      }
    }
  }

  // deleteAppointments() {
  //   const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
  //     data: {
  //       title: 'Bulk Delete Appointments',
  //       message: 'Are you sure you want to delete all selected appointments?',
  //     },
  //     panelClass: 'helpwindow'
  //   });
  //
  //   dialogRef.afterClosed().subscribe((result) => {
  //     if (result) {
  //       this.selectedAppointments.forEach((selectedAppointment, index) => {
  //         this.appointmentService.deleteAppointment(selectedAppointment, this.contextIdDto.contextId, this.contextIdDto.contextIdType)
  //           .subscribe(response => {
  //             if (response) {
  //               console.log('deleted appointment', { id: response.id });
  //               if (index === this.selectedAppointments.length - 1) {
  //                 this.loadAppointments();
  //               }
  //             }
  //           });
  //       });
  //     }
  //   });
  // }

  public loadBoardings() {
    this.loadHistory();
    this.loadOngoing();
    this.loadFuture();
    this.selectedBoardings = [];
  }
}
