<form *ngIf="invoice && invoice.invoiceServiceRows" [formGroup]="form">
  <div formArrayName="invoiceServiceRows">
    <div *ngFor="let row of form.controls.invoiceServiceRows.controls; let idx=index">
      <div [formGroup]="row">

        <div fxLayout="column" *ngIf="userDtos && invoiceDefinition && invoiceDefinition.enableServiceRowUser">
          <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">

            <div fxFlex="49" *ngIf="consumerServices">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>{{'SERVICE' | translate}}</mat-label>
                <mat-select placeholder="{{'SELECT_SERVICE' | translate}}" formControlName="serviceNumber"
                  (selectionChange)="serviceChanged(row, false)" name="serviceField" required>

                  <mat-option>
                    <ngx-mat-select-search [placeholderLabel]="'SEARCH_SERVICE' | translate" ngModel
                      [ngModelOptions]="{standalone: true}" (ngModelChange)="filterServices($event)">
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option [value]="''">
                    <button mat-flat-button color="accent" (click)="createNewService(idx)">&nbsp;&nbsp;&nbsp;&nbsp;Add
                      Service</button>
                  </mat-option>

                  <mat-option *ngFor="let service of filteredServices | async" [value]="service.id">
                    {{ service?.serviceName }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="invoiceServiceRows.controls[idx].get('serviceNumber').invalid && (invoiceServiceRows.controls[idx].get('serviceNumber').dirty || invoiceServiceRows.controls[idx].get('serviceNumber').touched)">
                  {{'SERVICE_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
                </mat-error>
              </mat-form-field>
            </div>


            <div fxFlex="49">

              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{'PRICE' | translate}}</mat-label>
                <input matInput type="text" appNumberOnly [decimalPoints]="2" placeholder="{{'PRICE' | translate}}" name="unitPrice"
                  formControlName="unitPrice" (change)="priceChanged(row)" id="unitPrice" required>
                <mat-error
                  *ngIf="invoiceServiceRows.controls[idx].get('unitPrice').invalid && (invoiceServiceRows.controls[idx].get('unitPrice').dirty || invoiceServiceRows.controls[idx].get('unitPrice').touched)">
                  {{'PRICE_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">

            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>{{'STAFF' | translate}}</mat-label>
              <mat-select placeholder="{{'SELECT_STAFF' | translate}}" [compareWith]="compareUserFn"
                formControlName="serviceUser" (selectionChange)="serviceUserChanged()" name="serviceUserField" required>
                <mat-option *ngFor="let serviceUser of userDtos" [value]="serviceUser.userId">
                  {{ serviceUser.fullName }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="invoiceServiceRows.controls[idx].get('serviceUser').invalid && (invoiceServiceRows.controls[idx].get('serviceUser').dirty || invoiceServiceRows.controls[idx].get('serviceUser').touched)">
                {{'STAFF_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>


        <div fxLayout="column">
          <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">
            <div fxFlex="48">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{'APPOINTMENT_DATE' | translate}}</mat-label>
                <input matInput formControlName="startDate" [matDatepicker]="picker" (dateChange)="startDateChange(row)"
                  (focus)="picker?.open()" and [ngClass]="{ 'start-date-disabled': idx > 0 }" (click)="picker?.open()"
                  placeholder="Start" required [readonly]="idx > 0">
                <mat-datepicker-toggle matSuffix [for]="picker" [disabled]="idx > 0"></mat-datepicker-toggle>
                <mat-datepicker #picker [disabled]="idx > 0"></mat-datepicker>
              </mat-form-field>
            </div>

            <div fxFlex="24">
              <mat-form-field appearance="outline" class="w-100"
                *ngIf="timeArray && timeArray.length > 0 && userDtos && invoiceDefinition && invoiceDefinition.enableStartTimeServiceRow">
                <mat-label>{{'START_TIME' | translate}}</mat-label>
                <mat-select placeholder="{{'SELECT_START_TIME' | translate}}" (selectionChange)="startTimeChanged(row, $event.value)"
                  formControlName="startTime" [compareWith]="compareTimeFn" required>
                  <mat-option *ngFor="let time of timeArray" [value]="time.actualValue">{{time.displayValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div fxFlex="24">
              <mat-form-field appearance="outline" class="w-100" placeholder="{{'END_TIME' | translate}}"
                *ngIf="timeArray && timeArray.length > 0 && userDtos && invoiceDefinition">
                <mat-label>{{'END_TIME' | translate}}</mat-label>
                <mat-select placeholder="{{'SELECT_END_TIME' | translate}}" (selectionChange)="endTimeChanged(row, $event.value)"
                  formControlName="endTime" [compareWith]="compareTimeFn" required>
                  <mat-option *ngFor="let time of timeArray" [value]="time.actualValue">{{time.displayValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div fxLayout="column">
          <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">
            <button mat-flat-button style="width: 100%" color="accent" (click)="createServiceRow()"
              *ngIf="invoiceDefinition?.serviceRowLinkedEntityLabel">{{'ADD_ANOTHER_SERVICE' | translate}} / {{
              invoiceDefinition.serviceRowLinkedEntityLabel }}</button>
            <button mat-flat-button style="width: 100%" color="accent" (click)="createServiceRow()"
              *ngIf="!invoiceDefinition?.serviceRowLinkedEntityLabel">{{'ADD_ANOTHER_SERVICE' | translate}}</button>
            <button (click)="deleteServiceRow(idx)" class="ml-xs mat-icon-button" mat-icon-button="">
              <span class="mat-button-wrapper">
                <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">delete</mat-icon>
              </span>
              <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
              <div class="mat-button-focus-overlay"></div>
            </button>

          </div>
        </div>
        <div fxLayout="column">
          <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">
            &nbsp;
          </div>
        </div>
      </div>

    </div>
  </div>
</form>
