import { Component, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PerformSearch, SearchResultGroup, SearchResultInstanceDto, SearchService } from '@savvy/search';
import { ContextService } from '../context.service';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ContextIdDto, MenuService } from '@savvy/menu';
import { Subscription } from 'rxjs';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MenuStructure } from "@savvy/menu";
import { MenuFactory } from "../menu/menu.factory";

@Component({
  selector: 'app-search',
  templateUrl: 'search.component.html',
  styleUrls: ['search.component.scss']
})
export class SearchComponent implements OnDestroy {

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.eRef.nativeElement.contains(event.target) || event.target.innerText === 'Clear') {
      // this.text = "clicked inside";
    } else {
      // this.text = "clicked outside";
      this.searchBlur();
    }
  }

  stateCtrl: UntypedFormControl;
  searchResultGroups: Array<SearchResultGroup>;
  searchResults: any[] = [
    {
      name: '',
      id: '',
      type: ''
    }
  ];
  menuSearchResults: MenuStructure[] = [];

  contextIdDto: ContextIdDto;
  subscriptions: Subscription[] = [];
  doSearch = false;

  constructor(
    private searchApi: SearchService,
    private menuFactory: MenuFactory,
    private router: Router,
    private notify: FloSnackbarComponent,
    private contextService: ContextService,
    public deviceDetectorService: DeviceDetectorService,
    private eRef: ElementRef,
    private menuService: MenuService,
  ) {
    this.stateCtrl = new UntypedFormControl();
    this.stateCtrl.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged()
    ).subscribe(change => {
      this.search(this.stateCtrl.value);
    });
    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
      }
    }));
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  gotoInstance(searchResultInstanceDto: SearchResultInstanceDto) {

    const id = searchResultInstanceDto.id;
    const type = searchResultInstanceDto.resultType;

    // event.stopPropagation();
    console.log('inside goto instance ' + id + ' type ' + type);
    if (id && id.length > 0) {
      if (type === SearchResultInstanceDto.ResultTypeEnum.Customer) {
        this.router.navigate(['/customer/viewCustomer',
          this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          id
        ]);
      } else if (type === SearchResultInstanceDto.ResultTypeEnum.EntityInstance) {
        this.router.navigate(['/entity/viewEntity',
          id,
          this.contextIdDto.contextId,
          this.contextIdDto.contextIdType]);
      } else if (type === SearchResultInstanceDto.ResultTypeEnum.Pet) {
        this.router.navigate(['/pets/view',
          this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          id]);
      } else if (type === SearchResultInstanceDto.ResultTypeEnum.Help) {
        this.router.navigate(this.menuFactory.getMenuDefinition({
          menuItemType: searchResultInstanceDto.helpResultDto.menuItemType
        }, this.contextIdDto).params);
      }
    }
  }

  search(searchStr: string) {
    console.log('searching with ' + searchStr);

    const req = <PerformSearch>{};
    req.contextIdDto = this.contextIdDto;
    req.searchString = searchStr;

    this.menuService.loadAll(this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(response => {
      console.log("searched settings");
      console.log(response);
      this.menuSearchResults = response;
    })

    this.searchApi.performSearchV2(req).subscribe(response => {
      this.searchResultGroups = response.searchResultGroups;
      this.searchResults = [];

      if (response.searchResultGroups.length == 0) {
        this.notify.message = 'No results';
        this.notify.open();
      }

      this.searchResultGroups.forEach(searchResultGroup => {
        this.searchResults.push({ name: searchResultGroup.groupName, type: 'groupName' });

        searchResultGroup.searchResultInstanceDtos.forEach(
          row => {
            // console.log('row is ' + row.value);
            this.searchResults.push({ name: row.value, type: 'id', id: row.id });
          }
        );
      });
    }
    );
  }

  clearInput() {
    this.stateCtrl.setValue('');
  }

  searchFocus() {
    this.doSearch = true;
  }

  searchBlur() {
    this.doSearch = false;
  }

  onEnter(evt: any) {
    if (evt.source.selected) {
      console.warn(`hello ${evt.source.selected}`);
    }
  }

}
