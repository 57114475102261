import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { InvoiceService } from '@savvy/invoice';
import { OrgCompositeService } from '@savvy/org';
import { ContextIdDto, Invoice, Payment, PaymentService, PaymentcompService, RecordPaymentComp } from '@savvy/payment';
import { PlanDto, PlansCompService } from '@savvy/plan';
import { AccountHolder, CardMachine, SavvyPayAccountHolderService, SavvyPayCardMachineService } from '@savvy/savvy-pay';
import { EntityInstanceId } from '@savvy/view-composite';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ContextService } from '../context.service';
import { AddPaymentModalComponent } from '../shared/components/add-payment-modal/add-payment-modal.component';
import { ConfirmationModalComponent } from '../shared/components/confirmation-modal/confirmation-modal.component';
import { TipsHelperService } from '../shared/services/tips-helper.service';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { CardMachinePaymentComponent } from './card-machine-payment.component';
import { PlansPaymentComponent } from './plans-payment.component';
import PaymentMethodEnum = Payment.PaymentMethodEnum;

@Component({
  selector: 'app-view-payments',
  templateUrl: './viewPayments.component.html'
})
export class ViewPaymentsComponent implements OnInit, OnDestroy {

  @Input() invoice: Invoice;
  @Input() contextIdDto: ContextIdDto;
  @Input() entityInstanceId: EntityInstanceId;
  @Input() visible: boolean;
  @Output() viewPaymentsCancelled = new EventEmitter();
  @Output() invoiceUpdated = new EventEmitter();
  payments: Array<Payment>;
  destroy$ = new Subject();
  subscriptions: Subscription[] = [];
  currencyCode = ''; //default

  accountHolder = <AccountHolder>{};
  cardMachines: Array<CardMachine> = [];
  timer;

  plans: Array<PlanDto> = [];


  totalAmount = 0;

  constructor(
    private paymentService: PaymentService,
    private savvyPayApi: SavvyPayAccountHolderService,
    private savvyPayCardMachine: SavvyPayCardMachineService,
    private orgCompApi: OrgCompositeService,
    private paymentsApiComposite: PaymentcompService,
    private invoiceApi: InvoiceService,
    private notify: FloSnackbarComponent,
    private contextService: ContextService,
    private translateService: TranslateService,
    private planCompApi: PlansCompService,
    private dialog: MatDialog,
    private tipsHelperService: TipsHelperService,

    ) {
  }

  ngOnInit() {
    console.log(this.invoice);
    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
        this.getPayments();
        this.loadOrg();
        this.loadCardMachines();
        this.loadPlans();
      }
    }));

  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  cancelIt() {
    this.viewPaymentsCancelled.emit('cancelled');
  }

  loadOrg() {
    this.orgCompApi.getCurrentOrgComp().subscribe(res => {
      this.currencyCode = res.currencyCode;
    });
  }

  loadAccountHolder() {
    this.savvyPayApi.loadAccountHolderByOwnerId(this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(response => {
      this.accountHolder = response;

    });
  }

  loadCardMachines() {
    this.savvyPayCardMachine.loadCardMachineByOwnerIdAndStatus(this.contextIdDto.contextId, this.contextIdDto.contextIdType, 'ACTIVE').subscribe(res => {
      this.cardMachines = res.cardMachines;
    });
  }

  getPayments() {
    this.contextIdDto = this.contextIdDto;
    this.paymentService.loadPaymentsForInvoice(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      this.invoice.id).subscribe(response => {
        this.payments = response;
        this.payments = [...this.payments];
        this.tipsHelperService.updateTips();
      });
  }

  loadPlans() {
    if (this.invoice.customerId && this.invoice.customerId.id) {
      this.planCompApi.loadActiveCustomerPlansComp(this.contextIdDto.contextId,
        this.contextIdDto.contextIdType, this.invoice.customerId.id).subscribe(res => {

          this.plans = res.plans;
        });
    }
  }

  paidFull(type: string) {

    const payment = <Payment>{};
    payment.amount = this.invoice.total;
    payment.ownerId = this.contextIdDto.contextId;
    payment.invoiceId = this.invoice?.invoiceId;

    if (type === PaymentMethodEnum.Cash) {
      payment.paymentMethod = PaymentMethodEnum.Cash;
    } else if (type === PaymentMethodEnum.DirectDebit) {
      payment.paymentMethod = PaymentMethodEnum.DirectDebit;
    } else if (type === PaymentMethodEnum.BankTransfer) {
      payment.paymentMethod = PaymentMethodEnum.BankTransfer;
    } else if (type === PaymentMethodEnum.Savvypay) {
      payment.paymentMethod = PaymentMethodEnum.Savvypay;
    } else {
      payment.paymentMethod = PaymentMethodEnum.CardMachine;
    }

    const req = <RecordPaymentComp>{};
    req.contextIdDto = this.contextIdDto;
    req.payment = payment;

    this.paymentsApiComposite.recordPaymentComp(req)
      .subscribe(response => {
        this.getPayments();
        this.invoiceUpdated.emit(response.invoice);

        this.translateService.get('Successfully added payment')
          .subscribe(val => {
            this.notify.message = val;
            this.notify.open();
          });
      }, () => {
        console.log('Error occurred while adding payment');
      });
  }

  deletePayment(row) {
    console.log(row);

    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        name: 'Payment'
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.paymentsApiComposite.deletePaymentComp(
          this.contextIdDto.contextId, this.contextIdDto.contextIdType, row.id
        ).subscribe(response => {
          this.invoiceUpdated.emit(response.invoice);
          this.getPayments();
        });
      } else {
        console.log('ERR: Not deleting');
      }
    });
  }

  addPayment() {
    const dialogRef = this.dialog.open(AddPaymentModalComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        invoice: this.invoice
      },
      autoFocus: false,
      panelClass: ['helpwindow']
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res) {
          this.getPayments();
          console.log('reloading invoice');
        }
      });
  }

  showTerminalPayment() {
    const entityInstanceId = this.entityInstanceId ? this.entityInstanceId.id : null;
    const dialogRef = this.dialog.open(CardMachinePaymentComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        invoice: this.invoice,
        cardMachines: this.cardMachines,
        currencyCode: this.currencyCode,
        entityInstanceId
      },
      disableClose: true,
      height: 'auto',
      width: '500px',
      minHeight: '400px'
    });

    dialogRef.afterClosed().subscribe(() => {
      this.invoiceApi.loadInvoice(this.invoice.id, this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(res => {
        this.invoiceUpdated.emit(res);
        this.getPayments();
      });
    });

  }

  showPaidPlans() {
    const entityInstanceId = this.entityInstanceId ? this.entityInstanceId.id : null;
    const dialogRef = this.dialog.open(PlansPaymentComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        invoice: this.invoice,
        plans: this.plans,
        entityInstanceId
      },
      disableClose: true,
      height: 'auto',
      width: '500px'
    });

    dialogRef.afterClosed().subscribe(() => {
      this.invoiceApi.loadInvoice(this.invoice.id, this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(res => {
        this.invoiceUpdated.emit(res);
        this.getPayments();
      });
    });

  }


}
