import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContextIdDto } from '@savvy/app';
import { Customer, CustomerService, UpdateCustomer } from '@savvy/customer';
import { Pet } from '@savvy/pet';
import { AppointmentHandlerService } from 'src/app/flo/appointments-v2/appointment-handler.service';
import { ConfService } from 'src/app/flo/config/conf.service';
import { MatDialog } from '@angular/material/dialog';
import { NewMessageModalComponent } from 'src/app/flo/shared/components/new-message-modal/new-message-modal.component';
import { LinkedIdTypeEnum } from 'src/app/core/data-share.service';
import { CalendarSetupService } from 'src/app/flo/welcome-to-savvy/calendar-setup.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FloSnackbarComponent } from "../../../snackbar/floSnackbar.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-view-customer-profile',
  templateUrl: './view-customer-profile.component.html',
  styleUrls: ['./view-customer-profile.component.scss']
})
export class ViewCustomerProfileComponent implements OnInit {
  @Input() contextIdDto: ContextIdDto;
  @Input() customerDto: Customer;
  @Input() petList: Pet[] = [];
  @Output() apppointmentsChanged: EventEmitter<any> = new EventEmitter();

  coverPic = '/assets/images/customer-cover.png';
  profilePic = '/assets/images/customer-profile.png';

  constructor(
    private dialog: MatDialog,
    private appointmentHandlerService: AppointmentHandlerService,
    private calendarSetupService: CalendarSetupService,
    private notify: FloSnackbarComponent,
    private ngxSpinnerService: NgxSpinnerService,
    private customerService: CustomerService,
    private translateService: TranslateService

  ) { }

  loadCalendarSettings() {
    this.calendarSetupService.loadCalendarSettings(this.contextIdDto);
  }

  ngOnInit(): void {
    const coverPic = this.getUrl(this.customerDto?.coverPicReference)
    if (coverPic) {
      this.coverPic = coverPic;
    } else {
      this.coverPic = '/assets/images/customer-cover.png';
    }
    const profilePic = this.getUrl(this.customerDto?.profilePicReference)
    if (profilePic) {
      this.profilePic = profilePic;
    } else {
      this.profilePic = '/assets/images/customer-profile.png';
    }
    this.loadCalendarSettings();
  }

  getUrl(reference: string) {
    if (reference) {
      return ConfService.apiUrl() + '/rest/storage?public=false&contextId=' +
        this.contextIdDto.contextId + '&contextIdType=' +
        this.contextIdDto.contextIdType + '&id=' + reference;
    }
    return null;
  }

  callback = (result) => {
    if (result) {
      this.apppointmentsChanged.emit(true);
      // this.loadAppointments();
    }
  };

  addAppointment() {
    this.appointmentHandlerService.createAppointment({
      contextIdDto: this.contextIdDto,
      petList: this.petList || [],
      callback: this.callback,
      calendarSettings: this.calendarSetupService.calendarSettings
    });
  }

  sendMessage() {
    const dialogRef = this.dialog.open(NewMessageModalComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        additionalLinkId: {
          linkedId: this.customerDto.id,
          linkedIdType: LinkedIdTypeEnum.CustomerId
        },
        linkId: {
          linkedId: this.customerDto.id,
          linkedIdType: LinkedIdTypeEnum.CustomerId
        },
        // eventBus: this.eventBus
      },
      autoFocus: false,
      panelClass: ['helpwindow', 'scrollable-modal', 'modal-100']

    });

  }

  createFlag(flagId: string) {
    if (!this.customerDto.flags) {
      this.customerDto.flags = new Array();
    }
    this.customerDto.flags.push(flagId);
    this.updateCustomer();
  }

  deleteFlag(flagId: string) {
    if (this.customerDto.flags) {
      const index = this.customerDto.flags.indexOf(flagId, 0);
      if (index > -1) {
        this.customerDto.flags.splice(index, 1);
        this.updateCustomer();
      }
    }
  }

  updateCustomer() {

    if (this.customerDto.id) {

      console.log('updating customer id ' + this.customerDto.id);

      const req = <UpdateCustomer>{};

      req.customer = this.customerDto;
      req.contextIdDto = this.contextIdDto;
      this.ngxSpinnerService.show();
      this.customerService.updateCustomer(req).subscribe(response => {

        // Stay on page? and change it to edit screen?
        this.customerDto = response;
        console.log('updated customer');
        this.ngxSpinnerService.hide();

        this.translateService.get('Successfully updated customer').subscribe(value => {
          this.notify.message = value;
          this.notify.open();
        });
        //    this.eventBus.addCustomerEvent(EventType.CUSTOMER_UPDATED, this.customerDto.customer.customerId);
      }, error => {
        this.ngxSpinnerService.hide();
        if (error.status === 400) {
          this.notify.message = error?.message;
          this.notify.open();
        }
      });
    }
  }


}
