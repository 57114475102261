<div fxLayout="row" [formGroup]="form" *ngIf="linkedEntityDefinition && elementInstanceDto.elementInstanceViewDto.viewable">

<mat-form-field appearance="outline" style="width: 100%;"
                  *ngIf="linkedEntityDefinition && elementInstanceDto.instanceId &&
                            additionalDataMapDto.elementDefDataMap[elementInstanceDto.elementDefinitionId] &&
                            additionalDataMapDto.elementDefDataMap[elementInstanceDto.elementDefinitionId].selectListDto &&
                            additionalDataMapDto.elementDefDataMap[elementInstanceDto.elementDefinitionId].selectListDto.selectItemDtoList">
    <mat-label>{{linkedEntityDefinition.label | translate}}</mat-label>

    <mat-select
      *ngIf="linkedEntityDefinition.allowMulti"
      [formControlName]="elementInstanceDto.instanceId"
      [required]="linkedEntityDefinition.required"
      [multiple]="linkedEntityDefinition.allowMulti"
      (selectionChange)="changedOptions($event)"
      placeholder="{{linkedEntityDefinition.placeholder | translate}}">
      <!--<button mat-button (click)="openCreateModal()">add new</button>-->
      <mat-option
        *ngFor="let item of additionalDataMapDto.elementDefDataMap[elementInstanceDto.elementDefinitionId].selectListDto.selectItemDtoList"
        [value]="item.id">{{item.label}}
      </mat-option>
    </mat-select>
    <mat-select
      *ngIf="!linkedEntityDefinition.allowMulti"
      [(value)]="selectedItem"
      [required]="linkedEntityDefinition.required"
      [multiple]="linkedEntityDefinition.allowMulti"
      (selectionChange)="changedOptions($event)"
      placeholder="{{linkedEntityDefinition.placeholder | translate}}">
      <!--<button mat-button (click)="openCreateModal()">add new</button>-->
      <mat-option
        *ngFor="let item of additionalDataMapDto.elementDefDataMap[elementInstanceDto.elementDefinitionId].selectListDto.selectItemDtoList"
        [value]="item.id">{{item.label}}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="form.controls[elementInstanceDto.instanceId].hasError('required')">
      {{linkedEntityDefinition.label + ' is' | translate}} <strong>{{'required' | translate}}</strong>
    </mat-error>
  </mat-form-field>


  <div *ngIf="linkedEntityDefinition.allowCreate">
    <span style="text-align: center; padding: 15px">{{'OR' | translate}}</span>

    <button (click)="createLink()" mat-fab>
      <mat-icon>add</mat-icon>
    </button>
  </div>

</div>
