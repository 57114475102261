import {DatePipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';
import {LocaleService} from "../../services/locale.service";
import {TranslateService} from "@ngx-translate/core";

@Pipe({
  name: 'localizedTime',
  pure: true
})
export class LocalizedTimePipe implements PipeTransform {

  constructor(private translateService: TranslateService,
              private localeService: LocaleService) {
  }

  transform(value: any, pattern: string = 'shortTime'): any {

    const aDate = this.dateFromTime(value);
    let locale = this.localeService.getLocale();
    console.log('using locale ', locale);
    const datePipe: DatePipe = new DatePipe(locale);
    return datePipe.transform(aDate, pattern);
  }

  dateFromTime(dStr) {
    const now = new Date();
    if (dStr) {
      now.setHours(dStr.substr(0, dStr.indexOf(':')));
      now.setMinutes(dStr.substr(dStr.indexOf(':') + 1));
      now.setSeconds(0);
    }
    return now;
  }

}
