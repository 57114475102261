<div class="container">
  <div class="row" *ngIf="invoice && invoice.invoiceServiceRows && invoice.invoiceServiceRows.length > 0 && invoicePanelServiceRowDtos">
    <div class="col">
      <h3 class="mt-1">Services</h3>
    </div>
    <div class="col-auto"></div>
  </div>

  <ngx-datatable *ngIf="invoice && invoice.invoiceServiceRows && invoice.invoiceServiceRows.length > 0 && invoicePanelServiceRowDtos" class="material" [rows]="invoice.invoiceServiceRows" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'" [scrollbarH]="true">
    <ngx-datatable-column name="{{ 'Service' | translate}}" [width]="300">
      <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
        <span *ngIf="invoicePanelServiceRowDtos[rowIndex].eiLabel">
          {{ invoicePanelServiceRowDtos[rowIndex].eiLabel }} /
        </span>
        {{ getServiceName(invoicePanelServiceRowDtos[rowIndex].invoicePanelServiceRow.serviceNumber) }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'Unit Price' | translate}}" [width]="100">
      <ng-template ngx-datatable-cell-template let-row="row">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'Price' | translate}}</mat-label>
          <input matInput placeholder="Unit" name="unitPrice" #unitPrice="ngModel" (change)="updateInvoice()" [(ngModel)]="row.unitPrice" id="unitPrice" required>
          <mat-error *ngIf="unitPrice.hasError('required')">
            {{'Unit Price is' | translate}} <strong>{{'required' | translate}}</strong>
          </mat-error>
        </mat-form-field>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'Tax' | translate}}" [width]="100">
      <ng-template ngx-datatable-cell-template let-row="row">
        <strong>{{row.tax}}</strong>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'Quantity' | translate}}" [width]="100">
      <ng-template ngx-datatable-cell-template let-row="row">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'Quantity' | translate}}</mat-label>
          <input matInput placeholder="Quantity" name="quantity" #quantity="ngModel" (change)="updateInvoice()" [(ngModel)]="row.quantity" id="quantity" required>
          <mat-error *ngIf="quantity.hasError('required')">
            {{'Quantity is' | translate}} <strong>{{'required' | translate}}</strong>
          </mat-error>
        </mat-form-field>
      </ng-template>
    </ngx-datatable-column>
    <!--ngx-datatable-column name="{{'Discount' | translate}}" [flexGrow]="1">
    <ng-template ngx-datatable-cell-template let-row="row">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'Discount' | translate}}</mat-label>
        <input matInput
               placeholder="Discount"
               name="discount"
               #discount="ngModel"
               (change)="updateInvoice()"
               [(ngModel)]="row.discount"
               id="discount"
               required>
        <mat-error *ngIf="discount.hasError('required')">
          {{'Discount is' | translate}} <strong>{{'required' | translate}}</strong>
        </mat-error>
      </mat-form-field>
    </ng-template>
  </ngx-datatable-column-->
    <ngx-datatable-column name="{{'Discount' | translate}}" [width]="100">
      <ng-template ngx-datatable-cell-template let-row="row">
        <button (click)="onDiscountClick(row)" class="ml-xs mat-icon-button" #tooltip="matTooltip" [matTooltip]="row?.discountDetails | discountTmpl:currencyCode">
          <mat-icon role="img" [ngClass]="{'discount-applied': row?.discountDetails}" class="demo-toolbar-icon" aria-label="Discount">local_offer</mat-icon>
        </button>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'Amount' | translate}}" [flexGrow]="1">
      <ng-template ngx-datatable-cell-template let-row="row">
        <!-- <strong>{{ row.grossPrice | currency:currencyCode:'symbol'}}</strong> -->
        <app-discount-price-view [originalPrice]="row.grossPriceNoOffer" [finalPrice]="row.grossPrice" [currencyCode]="currencyCode"></app-discount-price-view>
      </ng-template>
    </ngx-datatable-column>
    <!-- <ngx-datatable-column name="{{'Edit' | translate}}" [flexGrow]="1">
    <ng-template ngx-datatable-header-template>
      <button mat-mini-fab color="accent" style="top: -10px !important;" type="button" (click)="createServiceInvoiceRow()">
        <mat-icon>add</mat-icon>
      </button>
    </ng-template>
    <ng-template ngx-datatable-cell-template let-row="row">
      <button (click)="deleteServiceRow(row)" class="ml-xs mat-icon-button" mat-icon-button="">
                <span class="mat-button-wrapper">
                    <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">delete</mat-icon>
                </span>
        <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
        <div class="mat-button-focus-overlay"></div>
      </button>
    </ng-template>
  </ngx-datatable-column> -->
  </ngx-datatable>


  <div class="row" *ngIf="invoice && invoice.invoicePackageRows && invoice.invoicePackageRows.length > 0">
    <div class="col">
      <h3 class="mt-1">Package</h3>
    </div>
    <div class="col-auto align-self-center"></div>
  </div>

  <ngx-datatable *ngIf="invoice && invoice.invoicePackageRows && invoice.invoicePackageRows.length > 0" class="material" [rows]="invoice.invoicePackageRows" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'" [scrollbarH]="true">
    <ngx-datatable-column name="{{ 'Package' | translate}}" [width]="300">
      <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
        {{ getPackageName(row.packageNumber) }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'Unit Price' | translate}}" [width]="100">
      <ng-template ngx-datatable-cell-template let-row="row">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'Price' | translate}}</mat-label>
          <input matInput placeholder="Unit" name="unitPrice" #unitPrice="ngModel" (change)="updateInvoice()" [(ngModel)]="row.unitPrice" id="unitPrice" required>
          <mat-error *ngIf="unitPrice.hasError('required')">
            {{'Unit Price is' | translate}} <strong>{{'required' | translate}}</strong>
          </mat-error>
        </mat-form-field>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'Tax' | translate}}" [width]="100">
      <ng-template ngx-datatable-cell-template let-row="row">
        <strong>{{row.tax}}</strong>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'Quantity' | translate}}" [width]="100">
      <ng-template ngx-datatable-cell-template let-row="row">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'Quantity' | translate}}</mat-label>
          <input matInput placeholder="Quantity" name="quantity" #quantity="ngModel" (change)="updateInvoice()" [(ngModel)]="row.quantity" id="quantity" required>
          <mat-error *ngIf="quantity.hasError('required')">
            {{'Quantity is' | translate}} <strong>{{'required' | translate}}</strong>
          </mat-error>
        </mat-form-field>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'Discount' | translate}}" [width]="100">
      <ng-template ngx-datatable-cell-template let-row="row">
        <button (click)="onDiscountClick(row)" class="ml-xs mat-icon-button" mat-icon-button="" [matTooltip]="row?.discountDetails | discountTmpl:currencyCode">
          <mat-icon role="img" [ngClass]="{'discount-applied': row?.discountDetails}" class="demo-toolbar-icon" aria-label="Discount">local_offer</mat-icon>
        </button>
      </ng-template>
    </ngx-datatable-column>
    <!--ngx-datatable-column name="{{'Discount' | translate}}" [flexGrow]="1">
    <ng-template ngx-datatable-cell-template let-row="row">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'Discount' | translate}}</mat-label>
        <input matInput
               placeholder="Discount"
               name="discount"
               #discount="ngModel"
               (change)="updateInvoice()"
               [(ngModel)]="row.discount"
               id="discount"
               required>
        <mat-error *ngIf="discount.hasError('required')">
          {{'Discount is' | translate}} <strong>{{'required' | translate}}</strong>
        </mat-error>
      </mat-form-field>
    </ng-template>
  </ngx-datatable-column-->
    <ngx-datatable-column name="{{'Amount' | translate}}" [flexGrow]="1">
      <ng-template ngx-datatable-cell-template let-row="row">
        <!-- <strong *ngIf="">{{ row.grossPrice | currency:currencyCode:'symbol'}}</strong> -->
        <app-discount-price-view [originalPrice]="row.grossPriceNoOffer" [finalPrice]="row.grossPrice" [currencyCode]="currencyCode"></app-discount-price-view>
      </ng-template>
    </ngx-datatable-column>
    <!-- <ngx-datatable-column name="{{'Edit' | translate}}" [flexGrow]="1">
    <ng-template ngx-datatable-header-template>
      <button mat-mini-fab color="accent" style="top: -10px !important;" type="button" (click)="createServiceInvoiceRow()">
        <mat-icon>add</mat-icon>
      </button>
    </ng-template>
    <ng-template ngx-datatable-cell-template let-row="row">
      <button (click)="deleteServiceRow(row)" class="ml-xs mat-icon-button" mat-icon-button="">
                <span class="mat-button-wrapper">
                    <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">delete</mat-icon>
                </span>
        <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
        <div class="mat-button-focus-overlay"></div>
      </button>
    </ng-template>
  </ngx-datatable-column> -->
  </ngx-datatable>


  <div class="row" *ngIf="invoice">
    <div class="col">
      <h3 class="mt-1">Product</h3>
    </div>
    <div class="col-auto align-self-center">
      <button mat-raised-button color="accent" type="button" (click)="createProductInvoiceRow()" class="m-0">
        <mat-icon>add</mat-icon> Add Product
      </button>
    </div>
  </div>

  <ngx-datatable *ngIf="invoice" class="material" [rows]="invoice.invoiceProductRows" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'" [scrollbarH]="true">
    <ngx-datatable-column name="{{'Product' | translate}}" [width]="300" *ngIf="products">
      <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>{{'Product' | translate}}</mat-label>
          <mat-select placeholder="{{'Select Product' | translate}}" [(ngModel)]="row.productNumber" required (selectionChange)="productChanged(row)" name="product" #product="ngModel">
            <mat-option>
              <ngx-mat-select-search [placeholderLabel]="'Search Package'" ngModel (ngModelChange)="filterProducts($event)"></ngx-mat-select-search>
            </mat-option>
            <mat-option [value]="''">
              <button mat-flat-button color="accent" (click)="createProduct(rowIndex)">&nbsp;&nbsp;&nbsp;&nbsp;Add
                Product</button>
            </mat-option>
            <mat-option *ngFor="let product of products | productFilter: searchProduct" [value]="product.id">
              {{ product.productName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="product.hasError('required')">
            {{'Product is' | translate}} <strong>{{'required' | translate}}</strong>
          </mat-error>
        </mat-form-field>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'Unit Price' | translate}}" [width]="100">
      <ng-template ngx-datatable-cell-template let-row="row">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'Unit Price' | translate}}</mat-label>
          <input matInput type="text" appNumberOnly [decimalPoints]="2" placeholder="Unit Price" name="unitPrice" #unitPrice="ngModel" (change)="updateInvoice()" [(ngModel)]="row.unitPrice" id="productUnitPrice" required>
          <mat-error *ngIf="unitPrice.hasError('required')">
            {{'Unit Price is' | translate}} <strong>{{'required' | translate}}</strong>
          </mat-error>
        </mat-form-field>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'Tax Rate' | translate}}" [width]="80">
      <ng-template ngx-datatable-cell-template let-row="row">
        <strong>{{row.tax}}</strong>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'Quantity' | translate}}" [width]="100">
      <ng-template ngx-datatable-cell-template let-row="row">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'Quantity' | translate}}</mat-label>
          <input matInput type="text" appNumberOnly placeholder="Quantity" name="quantity" #quantity="ngModel" (change)="updateInvoice()" [(ngModel)]="row.quantity" id="productQuantity" required>
          <mat-error *ngIf="quantity.hasError('required')">
            {{'Quantity is' | translate}} <strong>{{'required' | translate}}</strong>
          </mat-error>
        </mat-form-field>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'Discount' | translate}}" [width]="100">
      <ng-template ngx-datatable-cell-template let-row="row">
        <button (click)="onDiscountClick(row)" class="ml-xs mat-icon-button" mat-icon-button="" [matTooltip]="row?.discountDetails | discountTmpl:currencyCode">
          <mat-icon role="img" [ngClass]="{'discount-applied': row?.discountDetails}" class="demo-toolbar-icon" aria-label="Discount">local_offer</mat-icon>
        </button>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'Amount' | translate}}" [width]="100">
      <ng-template ngx-datatable-cell-template let-row="row">
        <!-- <strong>{{ row.grossPrice | currency:currencyCode:'symbol'}}</strong> -->
        <app-discount-price-view [originalPrice]="row.grossPriceNoOffer" [finalPrice]="row.grossPrice" [currencyCode]="currencyCode"></app-discount-price-view>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'Edit' | translate}}" [width]="100">
      <ng-template ngx-datatable-header-template>

      </ng-template>
      <ng-template ngx-datatable-cell-template let-row="row">
        <button (click)="deleteProductRow(row)" class="ml-xs mat-icon-button" mat-icon-button="">
          <span class="mat-button-wrapper">
            <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">delete</mat-icon>
          </span>

        </button>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>
<!--ngx-datatable *ngIf="invoice"
               class="material"
               [rows]="invoice.tips"
               [columnMode]="'flex'"
               [headerHeight]="50"
               [footerHeight]="0"
               [rowHeight]="'auto'">

  <ngx-datatable-column name="{{'Tip' | translate}}" [flexGrow]="3" >
    <ng-template ngx-datatable-cell-template let-row="row">
      TODO
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable-->

<div class="container">
  <div class="row mt-1" *ngIf="invoice">
    <div class="col-auto mdc-py-2 align-flex-center" *ngIf="invoice.discountId">
      <strong>{{discountItemsById[invoice.discountId]?.code || 'CUSTOM'}}</strong> - {{invoice?.discountDetails | discountTmpl:currencyCode}}
    </div>
    <div class="col mdc-py-2 text-right">
      <button mat-raised-button color="accent" (click)="applyDiscountOnInvoice()">{{'Apply Discount' | translate}}
      </button>
    </div>
  </div>
  <div class="row mt-1" *ngIf="invoice">
    <div class="col-auto mdc-py-2">
      <h6 class="text-uppercase text-md text-secondary mb-0">{{'SUBTOTAL' | translate}}</h6>
      <h1 *ngIf="invoice.discountId">{{invoice.subTotalWithoutDiscount | currency:currencyCode:'symbol'}}</h1>
      <h1 *ngIf="!invoice.discountId" class="">{{invoice.subTotal | currency:currencyCode:'symbol'}}</h1>
    </div>
    <div class="col-auto mdc-py-2">
      <h6 class="text-uppercase text-md text-secondary mb-0">{{'TAX' | translate}}</h6>
      <h1 *ngIf="invoice.discountId">{{invoice.taxWithoutDiscount | currency:currencyCode:'symbol'}}</h1>
      <h1 *ngIf="!invoice.discountId" class="">{{invoice.tax | currency:currencyCode:'symbol'}}</h1>
    </div>
    <div class="col-auto mdc-py-2">
      <h6 class="text-uppercase text-md text-secondary mb-0">{{'DISCOUNT' | translate}}</h6>
      <h1 *ngIf="invoice.discountId">{{invoice.invoiceDiscount | currency:currencyCode:'symbol'}}</h1>
      <h1 *ngIf="!invoice.discountId" class="">{{00.0 | currency:currencyCode:'symbol'}}</h1>
    </div>
    <div class="col mdc-py-2 text-right">
      <h6 class="text-uppercase text-md text-secondary  mb-0">{{'TOTAL' | translate}}</h6>
      <h1 class="mat-text-green">{{ invoice.total |
        currency:currencyCode:'symbol'}}</h1>
    </div>
  </div>

  <mat-card appearance="outlined" class="mdc-mb-1" *ngIf="invoice && invoice.id">
    <app-view-payments [contextIdDto]="contextIdDto" (invoiceUpdated)="this.invoice = $event" [visible]="true" [invoice]="invoice" [entityInstanceId]="viewContext.entityInstanceId"></app-view-payments>
  </mat-card>

  <mat-card appearance="outlined" class="mdc-mb-1" *ngIf="invoice && invoice.id && showPaymentRequests && invoice.customerId">
    <app-view-payment-requests [invoice]="invoice" [contextIdDto]="contextIdDto" [requestForPaymentOwnerId]="invoice.customerId.id">
    </app-view-payment-requests>
  </mat-card>

  <mat-card appearance="outlined" class="mdc-mb-1" *ngIf="invoice && invoice.id">
    <app-view-tips [contextIdDto]="contextIdDto" (invoiceUpdated)="this.invoice = $event" [visible]="true" [invoice]="invoice"></app-view-tips>
  </mat-card>
</div>

<div class="container" *ngIf="invoice && invoice.invoiceId">
  <div class="row mb-2" *ngIf="invoice && invoice.paidInFull">
    <div class="col-12"><img src="assets/images/invoice/invoicePaid.svg" /></div>
  </div>
  <button mat-raised-button color="accent" (click)="sendInvoice()">{{'Send Invoice' | translate}}
  </button>

  <!--button mat-raised-button color="accent"  (click)="requestDeposit()">{{'Request Deposit' | translate}}
  </button-->
</div>

<mat-card appearance="outlined" *ngIf="!invoice">
  No invoice available
</mat-card>