import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { DateAdapter } from '@angular/material/core';
import { Subject } from 'rxjs';
@Injectable()
export class LanguageService {
  currentLocale = '';
  private currentLocaleSource: Subject<string> = new Subject();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public currentLocale$ = this.currentLocaleSource.asObservable();
  constructor(
    private translateService: TranslateService,
    private dateAdapter: DateAdapter<any>
  ) { }

  setDefaultLocale(locale: string) {
    console.log('_lang is not supported ', locale);
    moment.locale('en');
    this.translateService.use('en');
    this.dateAdapter.setLocale('en');
    this.currentLocale = 'en';
    this.currentLocaleSource.next(this.currentLocale);
  }

  setLocale(locale: string) {
    if (locale && locale.indexOf('en') > -1) {
      console.log('_lang is supported ', locale);
      moment.locale(locale);
      this.dateAdapter.setLocale(locale);
      this.translateService.use('en');
      this.currentLocale = locale;
      this.currentLocaleSource.next(this.currentLocale);
    } else if (locale && locale.indexOf('en') === -1) {
      if (locale.split('-')?.length > 1 && this.translateService.getLangs().includes(locale.split('-')[0])) {
        console.log('Just ' + locale.split('-')[0] + '_lang is supported');
        moment.locale(locale.split('-')[0]);
        this.dateAdapter.setLocale(locale.split('-')[0]);
        this.translateService.use(locale.split('-')[0]);
        this.currentLocale = locale;
        this.currentLocaleSource.next(this.currentLocale);
      } else {
        this.setDefaultLocale(locale);
      }
    } else {
      this.setDefaultLocale(locale);
    }
  }

}
