<div fxLayout="row" fxLayoutAlign=" center">
  <div fxFlex>
    <h2 mat-dialog-title>Change Owner</h2>
  </div>
  <div fxFlex="nogrow">
    <button mat-icon-button mat-dialog-close style="margin:5px">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<mat-dialog-content>
  <div fxLayout="row wrap" fxLayoutAlign=" center" fxLayoutGap="15px" class="mdc-mb-3">

    <!-- current owner details -->
    <div fxFlex>
      <h6 class="text-center">Current Pet Owner</h6>
      <mat-card appearance="outlined">
        <mat-card-content>
          <div fxLayout="row wrap" fxLayoutAlign=" center" fxLayoutGap="15px" class="mdc-mb-1">
            <div fxFlex="nogrow">
              <div class="avatar avatar-40 circle border-3">
                <mat-icon style="vertical-align:top">person</mat-icon>
              </div>
            </div>
            <div fxFlex>
              <h6 class="mb-0">
                <span class="customer-name vm">{{customer?.firstName}} {{customer?.lastName}}</span>
                <span class="chiptag green vm px-2" *ngIf="!customer?.deleted">Active</span>
                <span class="chiptag red vm px-2" *ngIf="customer?.deleted">In-active</span>
              </h6>
              <!-- <p class="mat-text-secondary small mb-0">Last Seen: 27 April 2024</p> -->
            </div>
          </div>

          <a *ngIf="customer?.username" href="mailto:{{customer?.username}}" class="chiptag theme-accent vm mt-5px">
            <mat-icon>email</mat-icon> {{customer?.username}}
          </a>
          <br>
          <a *ngIf="customer?.username" href="tel:+969985544225" class="chiptag theme vm mt-5px">
            <mat-icon>call</mat-icon> 9558989855
          </a>
        </mat-card-content>
      </mat-card>
    </div>

    <!-- pet details -->
    <div fxFlex="nogrow" class="text-center mdc-mt-3" style="align-self:center">
      <div class="avatar avatar-40 circle border-3">
        <mat-icon style="vertical-align:middle" class="mdc-mb-2">pets</mat-icon>
      </div>
      <h6 class="mb-0">{{data?.pet?.name}}</h6>
      <p class="mat-text-secondary small mdc-mb-1">{{data?.pet?.breedLabel}}</p>
      <mat-icon>arrow_forward</mat-icon>
    </div>

    <!-- new owner details -->
    <div fxFlex>
      <h6 class="text-center">New Pet Owner</h6>
      <mat-card appearance="outlined">
        <mat-card-content>
          <div fxLayout="row wrap" fxLayoutGap="15px" class="mdc-mb-1">
            <div fxFlex>
              <app-customer-selector-v2 *ngIf="data?.contextIdDto" [contextIdDto]="data.contextIdDto" [readOnly]="false"
                (customerSelected)="customerSelected($event)">
              </app-customer-selector-v2>
            </div>
            <!-- <div fxFlex="nogrow">
              <button mat-icon-button color="accent">
                <mat-icon>person_add</mat-icon>
              </button>
            </div> -->
          </div>
          <!-- <div class="text-center mdc-mb-2">
            <p class="mat-text-secondary">OR</p>
          </div>
          <div class="text-center">
            <mat-slide-toggle>Just leave ownership</mat-slide-toggle>
          </div> -->
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <h6 class="text-center">{{data?.pet?.name}}'s future appointment</h6>
  <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="20px">

    <!-- Future Appointment -->
    <div fxFlex="nogrow" class="mdc-mb-2">
      <h4 class="mdc-mb-1">{{appointmentsSummary?.futureAppointments}} <small
          class="mat-text-secondary">/{{appointmentsSummary?.futureAppointmentsTotalAmount | currency:
          currencyCode}}</small></h4>
      <p class="small mat-text-secondary">Future Appointment</p>
    </div>
    <!-- Non Transferable -->
    <div fxFlex="nogrow" class="mdc-mb-2">
      <h4 class="mdc-mb-1">{{appointmentsSummary?.nonTransferableAppointments}} <small
          class="mat-text-secondary">/{{appointmentsSummary?.nonTransferableAppointmentsTotalAmount | currency:
          currencyCode}}</small></h4>
      <p class="small mat-text-secondary">Non Transferable</p>
    </div>
    <!-- Future Appointment -->
    <div fxFlex="nogrow" class="mdc-mb-2">
      <h4 class="mdc-mb-1">{{appointmentsSummary?.transferableAppointments}} <small
          class="mat-text-secondary">/{{appointmentsSummary?.transferableAppointmentsTotalAmount | currency:
          currencyCode}}</small></h4>
      <p class="small mat-text-secondary">Transferable</p>
    </div>

  </div>

  <div class="text-center mat-text-red" *ngIf="sameOwnerError">
    Can't move pet to the same owner. Please select a different owner.
    <!-- <mat-slide-toggle [(ngModel)]="assignAppointmentsToNewOwner">Assign <b>new owner</b> to
      <b>{{appointmentsSummary?.transferableAppointments}} transferable</b> appointments </mat-slide-toggle> -->
  </div>

</mat-dialog-content>

<mat-dialog-actions align="center">
  <button mat-raised-button color="accent" class="mdc-mb-2" (click)="changeOwner()"
    [disabled]="!newOwnerId">Change</button>
</mat-dialog-actions>
