<mat-toolbar color="primary">
  <h1 mat-dialog-title>{{data?.title || 'CONFIRM'}}</h1>
</mat-toolbar>
<div class="mdc-p-3 ">
  <div [innerHTML]="data?.message"></div>
  <div>
    <mat-form-field style="width: 100%" appearance="outline">
      <mat-label>{{'REASON' | translate: {Default: 'Reason'} }}</mat-label>
      <textarea matInput [(ngModel)]="clarification" rows="10"></textarea>
    </mat-form-field>
  </div>
</div>
<div fxLayout="row" fxLayoutAlign="end">
  <button mat-raised-button [mat-dialog-close]="false" class="mr-1">{{'CLOSE' | translate: {Default: 'Close'} }}</button>
  <button mat-raised-button (click)="clarified()" class="mr-1" cdkFocusInitial color="primary" autofocus>{{'SAVE' | translate: {Default: 'Save'} }}</button>
</div>