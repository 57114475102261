<div [formGroup]="form" *ngIf="fieldDefinition">
    <mat-form-field appearance="outline">
      <mat-label>{{fieldDefinition.label | translate}}</mat-label>
        <input matInput
               type="text" appNumberOnly [decimalPoints]="2" [allowNegetiveIntegers]="true"
               placeholder="{{getPlaceholder() | translate}}"
               [id]="elementInstanceDto.instanceId"
               [required]="fieldDefinition.required"
               [formControlName]="elementInstanceDto.instanceId">

      <mat-error *ngIf="form.controls[elementInstanceDto.instanceId].hasError('required')">
        {{fieldDefinition.label + ' is' | translate}} <strong>{{'required' | translate}}</strong>
      </mat-error>
    </mat-form-field>
</div>
