<mat-form-field appearance="outline" class="field-center w-100">
  <mat-label>{{'RUN' | translate}}</mat-label>
  <mat-select placeholder="{{'SELECT_RUN' | translate}}" [(ngModel)]="selectedRun" required name="selectedRun"
    #selectedRunTemp="ngModel" (selectionChange)="runChanged($event)" [compareWith]="compareObjects">
    <mat-select-trigger>
      <mat-icon *ngIf="selectedRun?.petRunType === 'BOARDING'" matSuffix class="vm">house</mat-icon>
      <mat-icon *ngIf="selectedRun?.petRunType === 'DAY_CARE'" matSuffix class="vm">wb_sunny</mat-icon>
      {{selectedRun.name}}
    </mat-select-trigger>
    <mat-option>
      <ngx-mat-select-search [placeholderLabel]="'Search Run'" ngModel (ngModelChange)="filterRuns($event)">
      </ngx-mat-select-search>
    </mat-option>
    <mat-option>
      <button mat-flat-button color="accent" (click)="createRun()">{{'ADD_RUN' | translate}}</button>
    </mat-option>

    <mat-option *ngFor="let run of filteredRuns | async" [value]="run">
      <mat-icon *ngIf="run.petRunType === 'BOARDING'" matSuffix>house</mat-icon>
      <mat-icon *ngIf="run.petRunType === 'DAY_CARE'" matSuffix>wb_sunny</mat-icon> {{ run.name }}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="selectedRunTemp.hasError('required')">
    {{'RUN_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
  </mat-error>
</mat-form-field>
