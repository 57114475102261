/* eslint-disable @typescript-eslint/member-ordering */
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  PerformSearch,
  CustomerSearchResultDto, SearchService, ContextIdDto
} from '@savvy/search';

// import { CreatePetAppointmentV2SyncService, PopulateNewPetEventData } from '../create-pet-appointment-v2-sync.service';
import { Subject, Subscription } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { PerformPetSearchResponse } from '@savvy/search/model/performPetSearchResponse';
import { FloSnackbarComponent } from '../../../snackbar/floSnackbar.component';
import { PetServiceBooking } from '@savvy/pet-stay';
import * as _ from 'lodash';
import { CustomerStatsComponent } from 'src/app/flo/appointments-v2/shared/customer-stats/customer-stats.component';
import { CreateBoardingBookingV2SyncService, SelectedPetData } from '../create-boarding-booking-v2-sync.service';
import { PopulateNewPetEventData } from 'src/app/flo/appointments-v2/create-pet-appointment-v2/create-pet-appointment-v2-sync.service';
import { EventEmitter } from '@angular/core';
import { Tax } from '@savvy/tax';
import { MatDialog } from '@angular/material/dialog';
import { Pet, PetService } from '@savvy/pet';
// import { CustomerStatsComponent } from '../../shared/customer-stats/customer-stats.component';
export class SearchResult {
  label: string;
  value: any;
  type: string;
}
@Component({
  selector: 'app-pet-selector-boarding-v2',
  templateUrl: './pet-selector-boarding-v2.component.html',
  styleUrls: ['./pet-selector-boarding-v2.component.scss']
})
export class PetSelectorBoardingV2Component implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('petInput', { static: false }) petInput: ElementRef;
  @ViewChild('petAuto', { static: false }) petAuto;
  @ViewChild(CustomerStatsComponent) customerStatsComponent: CustomerStatsComponent;


  @Input() contextIdDto: ContextIdDto;
  @Input() multiple = false;
  @Input() maxPets = 1;
  // @Input() quickAppointment = false;
  @Input() petList: Pet[] = [];
  @Input() showNewPet = true;
  @Input() taxDefs: Tax[] = [];

  private _selectedPet: SelectedPetData;
  @Input()
  public get selectedPet(): SelectedPetData {
    return this._selectedPet;
  }
  public set selectedPet(value: SelectedPetData) {
    if (value?.petId) {
      this._selectedPet = value;
      this.selectedPetChips = [this.getPetChips(value)];
    }
  }

  private getPetChips(value: SelectedPetData) {
    if (value.petBreed) {
      return `<b>${value.petName}</b>(${value.petBreed})-${value.customerName}`;
    } else {
      return `<b>${value.petName}</b>${value.customerName}`;
    }
  }

  @Output() selectedPetChange: EventEmitter<SelectedPetData> = new EventEmitter();
  @Output() addAnotherPetEvent: EventEmitter<SelectedPetData> = new EventEmitter();
  @Output() addNewCustomerEvent: EventEmitter<boolean> = new EventEmitter();
  // @Input()
  // @Output() selectedPetChipsChange: EventEmitter<string[]> = new EventEmitter();
  selectedPetChips: string[] = [];
  customerId: string;

  pets: Array<SelectedPetData> = [];
  customers: Array<CustomerSearchResultDto> = [];

  searchControl: UntypedFormControl = new UntypedFormControl();

  searchResults: SearchResult[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];

  searchSource = new Subject<SearchResult[]>();
  filteredSearchResults = this.searchSource.asObservable();
  loadingInner = false;

  subs: Subscription[] = [];

  placeholder = 'Type pet/customer name...';
  createPet = false;
  constructor(
    private searchApi: SearchService,
    private notify: FloSnackbarComponent,
    public createBoardingBookingV2SyncService: CreateBoardingBookingV2SyncService,
    private dialog: MatDialog,
    private petService: PetService
  ) { }

  ngOnInit() {
    if (this.selectedPet) {
      this.customerId = this.selectedPet.petOwnerId;
    }
    if (this.petList.length) {
      this.searchFromCustomersPet();
      return;
    }
    this.subs.push(this.createBoardingBookingV2SyncService.populateNewPet$.subscribe((petData: PopulateNewPetEventData) => {
      if (petData) {
        const pet = petData.petSearchResultDto;
        this.selectedPet = _.cloneDeep(pet);
        if (!petData.populatingAppointment) {
          this.createBoardingBookingV2SyncService.updatePetSelection(this.selectedPet);
        }
        this.customerId = pet.petOwnerId;
        if (this.selectedPet) {
          this.placeholder = 'Type to add another pet...';
        }
      }
    }));

    this.searchControl.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged()
    ).subscribe((searchKey) => {
      this.loadingInner = true;
      if (!searchKey) {
        this.petList = [];
      }

      if (this.petList.length) {
        this.searchFromCustomersPet(searchKey);
        this.loadingInner = false;
        return;
      }

      if (!this.multiple && this.selectedPet) {
        this.searchSource.next([]);
        this.loadingInner = false;
        return;
      }
      if (searchKey && searchKey.length > 0 && searchKey.length < 10) {
        console.log('value change detected, triggering new search', searchKey);
        this.search(searchKey);
      } else {
        this.loadingInner = false;
      }
    });
  }

  ngOnDestroy(): void {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (!this.createBoardingBookingV2SyncService.editMode) {
        this.petInput.nativeElement.focus();
      }
    }, 600);
  }

  validCustomer(petOwnerId) {
    if (this.selectedPet) {
      const customerId = this.selectedPet.petOwnerId;
      return petOwnerId === customerId;
    }
    return true;
  }

  petSelectionChanged(event: MatAutocompleteSelectedEvent) {
    if (event.option.value) {
      const optionSelected = event.option.value as SearchResult;
      if (!this.validCustomer(optionSelected.value.petOwnerId)) {
        this.notify.message = 'Please select pet from same family/owner in an appointment';
        this.notify.open();
        return;
      }
      if (optionSelected && optionSelected.type === 'pet') {
        this.selectedPet = _.cloneDeep(optionSelected.value);
        this.selectedPetChange.emit(this.selectedPet);
        this.createBoardingBookingV2SyncService.customerId = this.selectedPet.petOwnerId;
        this.createBoardingBookingV2SyncService.customerName = this.selectedPet.customerName;
        if (this.selectedPet.petOwnerId) {
          this.createBoardingBookingV2SyncService.setPetList(this.selectedPet.petOwnerId, this.contextIdDto);
        }
      }
      // Clear the input value
      this.petInput.nativeElement.value = null;
      this.searchControl.setValue(null);
      this.searchResults = this.searchResults.filter(sr => sr.value !== optionSelected.value);
      this.searchSource.next(this.searchResults);
      this.customerId = optionSelected.value.petOwnerId;
    }
    if (this.selectedPet) {
      this.placeholder = 'Type to add another pet...';
    }
  }

  selectedServicesChange(petServiceBookings: PetServiceBooking[]) {
    this.selectedPet.petServiceBookings = petServiceBookings;
    this.selectedPetChange.emit(this.selectedPet);
  }

  remove() {
    if (this.selectedPet.petOwnerId) {
      this.createBoardingBookingV2SyncService.setPetList(this.selectedPet.petOwnerId, this.contextIdDto);
    }
    this.selectedPetChange.emit({ petId: '' });

    if (!this.selectedPet) {
      this.customerId = '';
      this.placeholder = 'Type pet/customer name...';
    }
    if (this.petList?.length) {
      this.searchFromCustomersPet(this.searchControl.value);
    }


  }

  // handleDuplicates() {
  //   if (this.selectedPet.length) {
  //     this.pets = this.pets.filter(p => this.selectedPet.findIndex(ps => ps.petId === p.petId) === -1);
  //   }
  // }

  searchFromCustomersPet(name = '') {
    const petList: Pet[] = _.cloneDeep(this.petList);
    this.searchResults = [];
    for (const pet of petList) {
      this.searchResults.push({
        label: `<b>${pet.name}</b>(${pet.breedLabel})-${pet.petOwnerFirstName} ${pet.petOwnerLastName}`,
        type: 'pet',
        value: {
          petId: pet.id,
          ownerId: pet.ownerId,
          petOwnerId: pet.petOwnerId,
          petName: pet.name,
          customerName: `${pet.petOwnerFirstName} ${pet.petOwnerLastName}`,
          petBreed: pet.breedLabel,
          petNotes: pet.petNotes,
          deleted: pet.deleted,
          deceased: pet.deceased,
        }
      });
    }
    setTimeout(() => {
      this.searchSource.next(this.searchResults);
    }, 300);
  }

  search(searchStr: string) {
    console.log('searching with ' + searchStr);

    const req: PerformSearch = {};
    req.contextIdDto = this.contextIdDto;
    req.searchString = searchStr;

    this.searchApi.performPetSearch(req).subscribe((response: PerformPetSearchResponse) => {
      this.loadingInner = false;
      this.pets = response.pets;
      // this.handleDuplicates();
      this.searchResults = [];
      for (const pet of this.pets) {
        this.searchResults.push({ label: this.getLabel(pet), type: 'pet', value: pet });
      }
      this.searchSource.next(this.searchResults);
    }
    );
  }

  private getLabel(pet: SelectedPetData) {
    if (pet.petBreed) {
      return `<b>${pet.petName}</b>(${pet.petBreed})-${pet.customerName}`;
    } else {
      return `<b>${pet.petName}</b>${pet.customerName}`;
    }
  }

  createPetFromList() {
    this.createPet = true;
    // this.createBoardingBookingV2SyncService.handleCreatePetFromList();
  }

  addAnotherPet() {
    this.addAnotherPetEvent.emit();
  }

  petCreated(pet: Pet) {
    this.createPet = false;
    this.selectedPet = _.cloneDeep({
      petId: pet.id,
      ownerId: pet.ownerId,
      petOwnerId: pet.petOwnerId,
      petName: pet.name,
      customerName: `${pet.petOwnerFirstName} ${pet.petOwnerLastName}`,
      petBreed: pet.breedLabel,
      petNotes: pet.petNotes,
      deleted: pet.deleted,
      deceased: pet.deceased,
    });
    this.selectedPetChange.emit(this.selectedPet);
  }

  addFeeding() {
    this.selectedPet.petFeedingConfig = {
      foodToServe: 'Provided By Owner: ',
      howManyTimesADay: 2,
      price: 0,
      subTotal: 0,
      grossPrice: 0,
      quantity: this.createBoardingBookingV2SyncService.totalDays
    };
  }

  addMedication() {
    this.selectedPet.petMedicationConfig = {
      medicinesToGive: 'Add your medication details here...',
      price: 0,
      subTotal: 0,
      grossPrice: 0,
      howManyTimesADay: 2,
      medicinesProvidedByOwner: true,
      quantity: this.createBoardingBookingV2SyncService.totalDays
    };
  }

  addServices() {
    if (!this.selectedPet?.petServiceBookings) {
      this.selectedPet.petServiceBookings = [];
    }
    this.selectedPet.petServiceBookings.push({
      startTime: this.createBoardingBookingV2SyncService.startTime,
      endTime: this.createBoardingBookingV2SyncService.endTime,
      startDate: this.createBoardingBookingV2SyncService.endDateReq,
      endDate: this.createBoardingBookingV2SyncService.endDateReq,
    } as PetServiceBooking);
  }

  addNewCustomer(event) {
    this.createPet = false;
    this.addNewCustomerEvent.emit(event);
  }



}
