<form [formGroup]="formGroup" *ngIf="preview">
    <mat-card appearance="outlined" *ngIf="fg?.value?.allowEmail" class="mdc-mb-2">
        <mat-card-content fxFlex>
            <div fxLayout="column wrap">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'EMAIL_SUBJECT' | translate}}</mat-label>
                    <input type="text" matInput [maxlength]="50" formControlName="subject" placeholder="{{'EMAIL_SUBJECT' | translate}}" required>
                </mat-form-field>
                <div fxLayout="column wrap" class="w-100">
                    {{'EMAIL_MESSAGE' | translate}}
                    <app-flo-editor fxFlex="100" [hideToolbar]="true" [text]="fg.value.emailContent" [readOnly]="true">
                    </app-flo-editor>
                </div>
                <div class="files w-100" fxLayout="row wrap" fxLayoutAlign="start baseline">
                    <div class="file" *ngFor="let file of fg?.value?.savedFiles; let i = index">
                        <img src="../../../../../assets/images/icons8-audio-file.svg" class="zero-image">
                        <p>{{file}}</p>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
    <mat-card appearance="outlined" *ngIf="fg?.value?.allowSms">
        <mat-card-content>
            <label>{{'SMS_MESSAGE' | translate}}</label>
            <app-flo-editor [hideToolbar]="true" [text]="fg.value.smsContent" [readOnly]="true" class="w-100">
            </app-flo-editor>
        </mat-card-content>
    </mat-card>
</form>