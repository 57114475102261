import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContractService } from '@savvy/contract';
import { ContractCompService } from '@savvy/contract';
import { BrandingService } from '@savvy/branding';
import { Page } from '../../shared/model/page';
import { ContextIdDto } from '@savvy/view-definition';
import { IdNameTupleDto } from '@savvy/contract/model/idNameTupleDto';

@Component({
  selector: 'app-list-contract-templates',
  templateUrl: './listContractTemplates.component.html'
})
export class ListContractTemplatesComponent implements OnInit {

  contextIdDto: ContextIdDto;

  contractTemplates: Array<IdNameTupleDto>;
  selected: IdNameTupleDto[] = [];
  page = new Page();
  private sub: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: ContractService,
    private brandApi: BrandingService,
    private contractCompositeApi: ContractCompService) { }


  ngOnInit() {
    this.sub = this.route.parent.params.subscribe(params => {
      this.contextIdDto = this.contextIdDto ? this.contextIdDto : {
        contextIdType: params['contextIdType'], contextId: params['contextId']
      };
      this.setPage({ offset: 0, size: 5 });
    });
  }

  onSelect(event) {
    this.router.navigate(['editContractTemplate', this.selected[0].id], { relativeTo: this.route });
  }

  create() {
    this.router.navigate(['addContractTemplate'], { relativeTo: this.route });
  }

  setPage(pageInfo) {
    console.log('pageInfo is ' + pageInfo);
    this.page.pageNumber = pageInfo.offset;
    this.loadContractTemplates();
  }

  loadContractTemplates() {
    this.contractCompositeApi.loadContractTemplates(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
    ).subscribe(response => {
      this.contractTemplates = response.idNameTupleDtos;
    });
  }
}
