<mat-toolbar color="primary" *ngIf="!adminMenu">
  <mat-toolbar-row>
    <span>{{'CONTRACTS' | translate: {Default:'Contracts'} }}</span>

    <span class="spacer"></span>

    <button class="ml-1" [disabled]="resendingContracts" mat-raised-button color="accent" [matMenuTriggerFor]="templatesMenu" style="min-width: 186px;">
      <mat-icon aria-hidden>alarm</mat-icon> {{'RESEND_ALL_UNSIGNED' | translate: {Default:'Resend All Unsigned'} }}
    </button>
    <mat-menu #templatesMenu>
      <ng-container *ngFor="let template of templates">
        <button mat-menu-item (click)="sendToAllUnsigned(template)">{{ template.name }}</button>
      </ng-container>
    </mat-menu>
    <button class="ml-1" mat-raised-button color="accent" (click)="addContract()" style="min-width: 144px;">
      <mat-icon aria-hidden>draw</mat-icon> {{'NEW_CONTRACT' | translate: {Default: 'New Contract'} }}
    </button>
    <button class="ml-1 mr-1" mat-raised-button color="accent" (click)="sendContract()" style="min-width: 148px;">
      <mat-icon aria-hidden>send</mat-icon> {{'SEND_CONTRACT' | translate: {Default: 'Send Contract'} }}
    </button>

  </mat-toolbar-row>
</mat-toolbar>


<div *ngIf="adminMenu" fxLayout="row wrap" fxLayoutAlign="end center">
  <div fxFlex='nogrow'>
    <button mat-raised-button color="accent" [matMenuTriggerFor]="templatesMenu" class="ml-1">
      <mat-icon aria-hidden>alarm</mat-icon> {{'RESEND_ALL_UNSIGNED' | translate: {Default:'Resend All Unsigned'} }}
    </button>
  </div>
  <div fxFlex='nogrow'>
    <mat-menu #templatesMenu>
      <ng-container *ngFor="let template of templates">
        <button mat-menu-item (click)="sendToAllUnsigned(template)">{{ template.name }}</button>
      </ng-container>
    </mat-menu>
  </div>
  <div fxFlex='nogrow'>
    <button mat-raised-button color="accent" (click)="sendContract()" class="ml-1">
      <mat-icon aria-hidden>send</mat-icon> {{'SEND_A_NEW_CONTRACT' | translate: {Default:'Send A New Contract'} }}
    </button>
  </div>
</div>
<mat-tab-group>
  <mat-tab label="{{'ALL_CONTRACTS' | translate: {Default:'All Contracts'} }}">
    <div class="mdc-px-2">
      <app-list-contracts #listSignedContracts [adminMenu]="adminMenu" [contextIdDto]="contextIdDto" [unsigned]="false">
      </app-list-contracts>
    </div>
  </mat-tab>
  <mat-tab label="{{'UNSIGNED_CONTRACTS' | translate: {Default:'Unsigned Contracts'} }}">
    <div class="mdc-px-2">
      <app-list-contracts #listUnsignedContracts [adminMenu]="adminMenu" [contextIdDto]="contextIdDto" [unsigned]="true">
      </app-list-contracts>
    </div>
  </mat-tab>
</mat-tab-group>