import { Injectable } from '@angular/core';
import { ContextIdDto } from '@savvy/app';
import { CalendarSettings, CalendarSettingsService, UpdateCalendarSettings } from '@savvy/calendar-settings';
import { DayCellLabelPrefs, UpdateUserCalendarSettings, UserCalendarSettingsService, UsersCalendarSettings } from '@savvy/user-calendar-settings';
import { TimeUtils } from '../dates/TimeUtils';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';

@Injectable({
  providedIn: 'root'
})
export class CalendarSetupService {

  userCalendarSettings: UsersCalendarSettings;
  defaultColourStrategyStr: string;
  calendarSettings: CalendarSettings;

  constructor(
    private userCalendarSettingsService: UserCalendarSettingsService,
    private calendarSettingsService: CalendarSettingsService,
    private notify: FloSnackbarComponent,
    private timeUtils: TimeUtils,
  ) { }

  getDefaultColourStrategyStr(defaultValue) {
    const index = Object.values(UsersCalendarSettings.ColourStrategyEnum)
      .findIndex(elStr => elStr === defaultValue);
    if (index !== -1) {
      return Object.values(UsersCalendarSettings.ColourStrategyEnum)[index];
    } else {
      return UsersCalendarSettings.ColourStrategyEnum.UseWorkflowColoursIfEndState;
    }
  }

  loadUserCalendarSettings(contextIdDto: ContextIdDto) {

    this.userCalendarSettingsService.getOrCreateCalendarSettings(
      contextIdDto.contextId,
      contextIdDto.contextIdType
    ).subscribe(response => {
      if (!response.dayCellLabelPrefs) {
        response.dayCellLabelPrefs = {} as DayCellLabelPrefs;
      }
      this.userCalendarSettings = response;


      // this.startDayViewTime = this.timeUtils.getDateFromTime(this.userCalendarSettings.startDayViewTime);
      // this.endDayViewTime = this.timeUtils.getDateFromTime(this.userCalendarSettings.endDayViewTime);
      // this.defaultCalendarViewStr = this.getDefaultCalendarViewStr(this.userCalendarSettings.defaultCalendarView);
      // this.defaultCalendarTabletViewStr = this.getDefaultCalendarViewStr(this.userCalendarSettings.defaultCalendarViewTablet);
      this.defaultColourStrategyStr = this.getDefaultColourStrategyStr(this.userCalendarSettings.colourStrategy);
      // this.defaultCalendarMobileViewStr = this.getDefaultCalendarViewStr(this.userCalendarSettings.defaultCalendarViewMobile, true);
      // this.defaultDayOfWeekViewValue = this.getDefaultDayOfWeekCalendarViewValue(this.userCalendarSettings.weekStartDay);
      // this.numHourSegments = this.userCalendarSettings.numHourSegments;
      // if (this.userCalendarSettings.excludeDays) {
      //   this.userCalendarSettings.excludeDays.forEach(index => {
      //     this.excludeDays[index].checked = false;
      //   });
      // }
    });
  }

  saveCalendarSettings(contextIdDto: ContextIdDto) {
    const req: UpdateCalendarSettings = {};
    req.contextIdDto = contextIdDto;

    req.calendarSettings = this.calendarSettings;
    this.calendarSettingsService.updateCalendarSettings(req).subscribe(response => {
      this.notify.message = 'Updated Calendar settings';
      this.notify.open();
      this.calendarSettings = response;
      this.loadCalendarSettings(contextIdDto);
    });
  }

  setDefaultColourStrategy() {
    if (this.defaultColourStrategyStr) {
      const index = Object.values(UsersCalendarSettings.ColourStrategyEnum)
        .findIndex(elStr => elStr === this.defaultColourStrategyStr);
      if (index !== -1) {
        return Object.values(UsersCalendarSettings.ColourStrategyEnum)[index];
      } else {
        return UsersCalendarSettings.ColourStrategyEnum.UseWorkflowColoursIfEndState;
      }
    }
  }


  saveUserCalendarSettings(contextIdDto: ContextIdDto) {
    const selectedDays = [];
    const req: UpdateUserCalendarSettings = {};
    req.contextIdDto = contextIdDto;

    // this.userCalendarSettings.startDayViewTime = this.timeUtils.getHoursMinsSecsTimeString(this.startDayViewTime);
    // console.log('set start to ' + this.userCalendarSettings.startDayViewTime);
    // this.userCalendarSettings.endDayViewTime = this.timeUtils.getHoursMinsSecsTimeString(this.endDayViewTime);

    // this.userCalendarSettings.defaultCalendarView = this.getDefaultCalendarView(this.defaultCalendarViewStr);
    // console.log('set to ' + this.userCalendarSettings.defaultCalendarView);
    // if (this.userCalendarSettings.defaultCalendarView) {
    //   // @ts-ignore
    //   this.userCalendarSettings.defaultCalendarView = this.userCalendarSettings.defaultCalendarView.toString().toUpperCase();
    // }
    // this.userCalendarSettings.defaultCalendarViewTablet = this.getDefaultCalendarView(this.defaultCalendarTabletViewStr);
    // if (this.userCalendarSettings.defaultCalendarViewTablet) {
    //   // @ts-ignore
    //   this.userCalendarSettings.defaultCalendarViewTablet = this.userCalendarSettings.defaultCalendarViewTablet.toString().toUpperCase();
    // }
    // this.userCalendarSettings.defaultCalendarViewMobile = this.getDefaultCalendarView(this.defaultCalendarMobileViewStr, true);
    // if (this.userCalendarSettings.defaultCalendarViewMobile) {
    //   // @ts-ignore
    //   this.userCalendarSettings.defaultCalendarViewMobile = this.userCalendarSettings.defaultCalendarViewMobile.toString().toUpperCase();
    // }

    // this.userCalendarSettings.weekStartDay = this.getDefaultDayOfWeekCalendarView();
    // if (this.userCalendarSettings.weekStartDay) {
    //   // @ts-ignore
    //   this.userCalendarSettings.weekStartDay = this.userCalendarSettings.weekStartDay.toString().toUpperCase();
    // }
    // this.userCalendarSettings.numHourSegments = this.numHourSegments;
    this.userCalendarSettings.colourStrategy = this.setDefaultColourStrategy();
    // this.excludeDays.forEach(el => {
    //   if (el.checked === false) {
    //     selectedDays.push(el.value);
    //   }
    // });
    this.userCalendarSettings.excludeDays = selectedDays;
    req.userCalendarSettings = this.userCalendarSettings;

    console.log('updating with ', req);

    this.userCalendarSettingsService.updateUserCalendarSettings(req).subscribe(response => {
      // this.notify.message = 'Updated Calendar settings';
      // this.notify.open();
      this.userCalendarSettings = response;
      this.loadUserCalendarSettings(contextIdDto);
    });
  }

  loadCalendarSettings(contextIdDto: ContextIdDto) {
    this.calendarSettingsService.getCalendarSettings(
      contextIdDto.contextIdType,
      contextIdDto.contextId).subscribe(response => {
        this.calendarSettings = response;
      });
  }

}
