import { Injectable } from '@angular/core';
import { AddOn, AddOnService } from '@savvy/add-ons';
import { ContextIdDto } from '@savvy/ui';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddOnsHelperService {
  addOns: Array<AddOn> = [];
  private addOnsSource: BehaviorSubject<AddOn[]> = new BehaviorSubject(this.addOns);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public addOns$: Observable<AddOn[]> = this.addOnsSource.asObservable();

  constructor(
    private addOnService: AddOnService
  ) { }

  loadAddOns(contextIdDto: ContextIdDto) {
    return new Promise((resolve) => {
      this.addOnService.loadByEnv(contextIdDto.contextId).subscribe(response => {
        this.addOns = response;
        this.addOnsSource.next(this.addOns);
        resolve(response);
      });
    })
  }

  addOnPresent(addOnName: string): boolean {
    if (this.addOns.length) {
      return this.addOns.findIndex(ao => ao.addOnDefName === addOnName) > -1;
    }
    return false;
  }




}
