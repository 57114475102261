import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ElementControlService } from '../ElementControlService';
import { EicompService, EntityChangeEvent } from '@savvy/entity-instance-composite';
import { DateTimeAdapter } from '@danielmoncada/angular-datetime-picker';
import { TranslateService } from '@ngx-translate/core';
import { ChangeListener } from '../changeListener';
import { DateUtils } from '../../dates/DateUtils';
import { LookAndFeel, LookAndFeelService } from '@savvy/look-and-feel';
import { LookAndFeelConfig, LookAndFeelSharedService, TimeValue } from '../../shared/services/look-and-feel-shared.service';
import { DaateTimeFieldDefinitionDto, ElementInstanceDto, FieldValueDto } from '@savvy/view-composite';
import { ContextIdDto } from '@savvy/datalist';

@Component({
  selector: 'app-date-time-instance-drop-down',
  templateUrl: 'dateTimeInstanceDropDown.html'
})
export class DateTimeInstanceDropDownComponent implements OnInit {
  private timeout: any = null;

  @Input() dateTimeDefinition: DaateTimeFieldDefinitionDto;
  @Input() elementInstanceDto: ElementInstanceDto;
  @Input() entityInstanceId: string;
  @Input() contextIdDto: ContextIdDto;
  @Input() form: UntypedFormGroup;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;
  timeArray: TimeValue[] = [];
  minuteInterval: number;
  lookAndFeelConfig: LookAndFeelConfig;
  lookAndFeel: LookAndFeel;
  defaultTimeValue: string;

  constructor(
    private api: EicompService,
    private lookAndFeelApi: LookAndFeelService,
    private dateUtils: DateUtils,
    private translateService: TranslateService,
    dateTimeAdapter: DateTimeAdapter<any>,
    private ecs: ElementControlService,
    private lookAndFeelService: LookAndFeelSharedService) {
    dateTimeAdapter.setLocale(this.translateService.currentLang);
  }

  get isValid() {
    return this.form.controls[this.elementInstanceDto.instanceId].valid;
  }

  ngOnInit(): void {
    this.lookAndFeelConfig = this.lookAndFeelService.getLookAndFeelConfig(this.contextIdDto);

    if (this.lookAndFeelConfig) {
      this.lookAndFeel = this.lookAndFeelConfig.lookAndFeel;
      this.minuteInterval = this.lookAndFeelConfig.minuteInterval;
      this.timeArray = this.lookAndFeelConfig.timeArray;
    }

    this.initialise();
  }

  initialise() {

    if (!this.form.controls[this.elementInstanceDto.instanceId].value) {
      console.log('no time so setting default');
      this.setRoundedDefaultTimeValue(this.lookAndFeelConfig.defaultTimeValue);
    } else {
      this.defaultTimeValue = this.form.controls[this.elementInstanceDto.instanceId + 'time'].value;
      console.log('We have date:' + this.form.controls[this.elementInstanceDto.instanceId].value);
      console.log('We have time:' + this.form.controls[this.elementInstanceDto.instanceId + 'time'].value);
    }

    if (this.form.controls[this.elementInstanceDto.instanceId] &&
      this.form.controls[this.elementInstanceDto.instanceId].valueChanges) {
      this.form.controls[this.elementInstanceDto.instanceId].valueChanges.subscribe((value) => {
        console.log('date changed value=' + value);
        // If the date changes, we want to re-set the time to it.
        const recalculateDate = this.setDateTimeFromTime(this.form.controls[this.elementInstanceDto.instanceId].value);
        this.onChange(recalculateDate);
      });
    } else {
      console.log('Error, missing field');
    }

    if (this.form.controls[this.elementInstanceDto.instanceId] &&
      this.form.controls[this.elementInstanceDto.instanceId + 'time'].valueChanges) {
      this.form.controls[this.elementInstanceDto.instanceId + 'time'].valueChanges.subscribe((value) => {
        console.log('changed time value=' + value);
        this.timeChanged();

      });
    } else {
      console.log('Error, missing field');
    }
  }

  timeChanged() {
    console.log('time changed and is now ' + this.form.controls[this.elementInstanceDto.instanceId + 'time'].value);
    console.log('datetime is currently ' + this.form.controls[this.elementInstanceDto.instanceId].value);
    const recalculateDate = this.setDateTimeFromTime(this.form.controls[this.elementInstanceDto.instanceId].value);
    this.form.controls[this.elementInstanceDto.instanceId].patchValue(recalculateDate);
  }

  setDateTimeFromTime(date: Date): Date {

    if (this.form.controls[this.elementInstanceDto.instanceId + 'time'].value) {
      const timeSplit = this.form.controls[this.elementInstanceDto.instanceId + 'time'].value.split(':');

      if (timeSplit.length > 1) {
        const hours = Number(timeSplit[0]);
        const mins = Number(timeSplit[1]);
        return this.dateUtils.setTime(date, hours, mins);
      }
    }
    return null;
  }

  setRoundedDefaultTimeValue(defaultTimeValue) {
    const id = this.elementInstanceDto.instanceId + 'time';
    this.form.controls[id].patchValue(defaultTimeValue);
  }

  changeEvent(newValue) {

    console.log('onChange detected new value is ' + newValue);

    const fieldValue: FieldValueDto = {
      // dateTimeValue: newValue.toLocaleString(),
      dateTimeValue: newValue,
      valueType: FieldValueDto.ValueTypeEnum.DateTime
    };

    const req: EntityChangeEvent = {
      contextIdDto: this.contextIdDto,
      fieldValue,
      entityInstanceId: this.entityInstanceId,
      fieldInstanceId: this.elementInstanceDto.instanceId
    };

    // There is no loadProductsResponse from this, do we care?
    this.api.entityInstanceChangeEvent(req).subscribe(
      response => {
        // EntityInstanceChangeResponse
        console.log('loadProductsResponse got from update, calling handle changed elements');
        this.ecs.handleChangedElements(response.changedElementList, this.form);
        if (this.changeListener) {
          this.changeListener.add('change');
        }
      }
    );
  }

  onChange(newValue: Date) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.changeEvent(newValue);
    }, 500);
  }
}

