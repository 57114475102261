<div>
    <mat-toolbar color="primary">
        <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
            <button mat-icon-button (click)="backToList()">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
                <span>{{'MESSAGES' | translate}}</span>
            </div>
        </div>
    </mat-toolbar>

    <ngx-datatable *ngIf="!loading && individualMessageDetailsDtos && individualMessageDetailsDtos?.length" class='material has-plus-btn' [rows]='individualMessageDetailsDtos' [columnMode]="'force'" [headerHeight]="50" [rowHeight]="'auto'" [footerHeight]="50" [selected]="selected" [selectionType]="'single'" [externalPaging]="true" [count]="page.totalElements" [offset]="page.pageNumber" (sort)="onSort($event)" [loadingIndicator]="loading" [limit]="page.size" (page)='setPage($event)'>

        <ngx-datatable-column name="{{'FIRST_NAME' | translate}}">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.firstName}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'LAST_NAME' | translate}}">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.lastName}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'TYPE' | translate}}">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.type}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'RECIPIENT' | translate}}">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.recipient}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'STATUS' | translate}}">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.status}}
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
</div>
<div class="flexbox-parent" *ngIf="!loading && (!individualMessageDetailsDtos || !individualMessageDetailsDtos?.length)">
    <div fxLayout="row" fxLayoutAlign="center center" style="height: 60vh;  ">
        <div style="text-align:center;">
            <app-zero-image name="messaging" class="zero-image"></app-zero-image><br>
            <h5 class="ma-0">{{'MESSAGE_APPEAR_SHORTLY' | translate}}!</h5><br>
        </div>
    </div>
</div>