<div *ngIf="bookingDef && styleDef" fxLayout="row wrap" fxLayoutAlign="space-between" style="height: 100%;">


  <div fxFlex.gt-sm="25%" fxFlex="100" fxFlex.gt-xs="25%">


  </div>


  <div fxFlex.gt-sm="50%" fxFlex="100" fxFlex.gt-xs="50%">

    <div *ngIf="bookingDef.bookingLookAndFeelDefinition && bookingDef.bookingLookAndFeelDefinition.showLogo && logoUri"
      class="headerLogo" [style.background-image]="'url(' + logoUri + ')'"></div>

    <mat-card appearance="outlined" *ngIf="bookingDef.bookingLookAndFeelDefinition && bookingDef.bookingLookAndFeelDefinition.headerMessage">
      <mat-card-header>
        <h1>{{bookingDef.bookingLookAndFeelDefinition.headerMessage}}</h1>
      </mat-card-header>
    </mat-card>

    <mat-card appearance="outlined">
      <mat-toolbar>
        <mat-toolbar-row>{{'REQ_NEW_APPT' | translate}}</mat-toolbar-row>
      </mat-toolbar>
      <br>

      <mat-card-content>

        <form #bookForm="ngForm" *ngIf="loadServicesResponse">


          <div fxLayout="row" fxLayout.lt-md="column">
            <div fxFlex="20" fxLayout="column">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>{{'DATE' | translate}}</mat-label>
                <input matInput #dueDate name="dueDate" placeholder="{{'DATE' | translate}}" [matDatepicker]="picker"
                  (change)="searchWithSelected()" [(ngModel)]="appointmentTime">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
            <div fxFlex="1" fxLayout="column">
              &nbsp;
            </div>
            <div fxFlex="60" fxLayout="column">
              <mat-form-field appearance="outline" style="width: 100%" class="example-full-width">
                <mat-label>{{'SERVICE' | translate}}</mat-label>
                <mat-select placeholder="{{'SELECT_SERVICE' | translate}}" [(ngModel)]="serviceNumber" required
                  (selectionChange)="serviceChanged()" name="service" #service="ngModel">
                  <mat-option *ngFor="let service of loadServicesResponse.serviceDtos"
                    [value]="service.entityInstanceId.id">
                    {{ service?.serviceName }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="service.hasError('required')">
                  {{'SERVICE_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
                </mat-error>
              </mat-form-field>

            </div>
            <div fxFlex="1" fxLayout="column">
              &nbsp;
            </div>
            <div fxFlex="5" fxLayout="column">

              <!--button mat-fab (click)="addService()" color="accent">
                <mat-icon class="mat-24">add</mat-icon>
              </button-->
            </div>
            <div fxFlex="20" fxLayout="column">
              <!--button mat-raised-button (click)="searchWithSelected()" color="accent">Show Available Times</button-->&nbsp;
            </div>

          </div>

          <!--div fxLayout="row" fxLayout.lt-md="column">
            <button mat-raised-button (click)="searchWithSelected()" color="accent">Show Available Times</button>&nbsp;
          </div-->


        </form>
      </mat-card-content>

    </mat-card>

    <!--mat-card>
      <mat-card-header>
        <mat-card-title>Selected Services</mat-card-title>
        <mat-card-subtitle>Once all services selected, press Show Available Times</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div fxLayout="row" fxLayout.lt-md="column">
          <div fxFlex="100" fxLayout="column">
            <mat-list>
              <mat-list-item *ngFor="let service of serviceNumbers">
                <button (click)="deleteService(service)" class="ml-xs mat-icon-button" mat-icon-button="">
                      <span class="mat-button-wrapper">
                      <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">delete</mat-icon>
                      </span>
                  <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
                  <div class="mat-button-focus-overlay"></div>
                </button>
                &nbsp;&nbsp;
                {{ getServiceName(service) }}
              </mat-list-item>
            </mat-list>
          </div>
        </div>
      </mat-card-content>
    </mat-card-->

    <!--mat-card>
      <mat-card-actions>
        <button mat-raised-button [disabled]="serviceNumbers.length === 0" (click)="search()" color="accent">Show Available Times</button>
      </mat-card-actions>
    </mat-card-->

    <mat-card appearance="outlined" *ngIf="daySlot && daySlot.slotAndCheckDtos">

      <mat-toolbar>
        <mat-toolbar-row>{{'PICK_A_SLOT' | translate}}</mat-toolbar-row>
      </mat-toolbar>
      <br>

      <mat-card-content>
        <h2>{{daySlot.day | localizedDate:'mediumDate'}}</h2>
        <mat-chip-listbox *ngIf="!debug">
          <mat-chip-option color="primary" (click)="selectSlot(slotAndCheckDto.slotDto)"
            matTooltip="{{slotAndCheckDto.slotDto.availableUsersNames}}"
            *ngFor="let slotAndCheckDto of daySlot.slotAndCheckDtos | callback: isSlotAvailable">
            {{slotAndCheckDto.slotDto.startSlot | localizedDate:'shortTime'}}
          </mat-chip-option>
        </mat-chip-listbox>

        <div *ngIf="debug && daySlot.slotAndCheckDtos">

          Number of Slots Available: {{ daySlot.slotAndCheckDtos.length }}
          <mat-card appearance="outlined" *ngFor="let slotAndCheckDto of daySlot.slotAndCheckDtos">
            <mat-card-title>{{slotAndCheckDto.slotDto.startSlot | localizedDate:'shortTime'}}</mat-card-title>
            <mat-card-subtitle *ngIf="slotAndCheckDto.errorMessage">{{slotAndCheckDto.errorMessage}}</mat-card-subtitle>
            <mat-card-content *ngFor="let userFreeCheck of slotAndCheckDto.userFreeChecks">
              <div *ngIf="userFreeCheck.userFreeCheck.userFree">
                {{ userFreeCheck.username}} {{'IS_AVAILABLE' | translate}}
              </div>

              <div *ngIf="!userFreeCheck.userFreeCheck.userFree">
                {{ userFreeCheck.username}} {{'IS_NOT_AVAILABLE' | translate}}
                <div *ngIf="userFreeCheck.userFreeCheck.clashingAppointment">
                  Clashing appoiment {{ userFreeCheck.userFreeCheck.appointmentText }}
                  starting at {{ userFreeCheck.userFreeCheck.clashingAppointment.start }}
                  until {{ userFreeCheck.userFreeCheck.clashingAppointment.end }}
                </div>
              </div>
            </mat-card-content>
          </mat-card>

        </div>

      </mat-card-content>
    </mat-card>

    <mat-card appearance="outlined" *ngIf="debug && daySlot && daySlot.businessHours">
      <mat-card-title>{{'BUSINESS_HOURS' | translate}}</mat-card-title>
      <mat-card-content>
        <mat-list>
          <mat-list-item *ngFor="let open of daySlot.businessHours.hours">{{open.start}} -> {{open.end}}</mat-list-item>
        </mat-list>
      </mat-card-content>
    </mat-card>

    <mat-card appearance="outlined" *ngIf="debug && daySlot && daySlot.nonServiceUsers">
      <mat-card-title>{{'NON_SERVICE_USERS' | translate}}</mat-card-title>
      <mat-card-subtitle>{{'USERS_UNABLE_TO_PERFORM_SERVICE' | translate}}</mat-card-subtitle>
      <mat-card-content>
        <mat-list>
          <mat-list-item *ngFor="let nonServiceUser of daySlot.nonServiceUsers">{{nonServiceUser.name}}</mat-list-item>
        </mat-list>
      </mat-card-content>
    </mat-card>

    <mat-card appearance="outlined" *ngIf="debug && daySlot && daySlot.userWorkingHours">
      <mat-card-title>{{'SERVICE_USERS_WORKING_HRS' | translate}}</mat-card-title>
      <mat-card-subtitle>{{'USERS_ABLE_TO_PERFORM_SERVICE' | translate}}</mat-card-subtitle>
      <mat-card-content>
        <div *ngFor="let userWorkingHour of daySlot.userWorkingHours">
          {{userWorkingHour.userName}}
          <br>
          <mat-list>
            <mat-list-item *ngFor="let hours of userWorkingHour.dayWorkingHours.hours">
              {{ hours.start}} -> {{ hours.end }}
            </mat-list-item>
          </mat-list>
        </div>
      </mat-card-content>
    </mat-card>


    <mat-card appearance="outlined" *ngIf="selectedSlot && bookingDef && countryCode">

      <app-booking-request [contextIdDto]="contextIdDto" [slotDto]="selectedSlot" [countryCode]="countryCode"
        [bookingDef]="bookingDef" [services]="services"></app-booking-request>

    </mat-card>

  </div>

  <div fxFlex.gt-sm="25%" fxFlex="100" fxFlex.gt-xs="25%"></div>
</div>
