import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-appointment-v2-icons',
  templateUrl: './appointment-v2-icons.component.html',
  styleUrls: ['./appointment-v2-icons.component.scss']
})
export class AppointmentV2IconsComponent implements OnInit {
  @Input() selectedIcons = [];
  icons = [
    'adb',
    'alarm',
    'attach_money',
    'camera',
    'done',
    'error',
    'favorite',
    'local_bar',
    'outlined_flag',
    'pets',
    'sentiment_satisfied_alt',
    'sentiment_satisfied',
    'sentiment_very_dissatisfied',
    'sentiment_very_satisfied',
    'unsubscribe',
    'watch',
  ];
  constructor() { }

  ngOnInit() {
  }

}
