<div *ngIf="showSpinner" class="bg-spinner-wrapper">
  <mat-spinner style="margin: 0 auto;"></mat-spinner>
</div>

<mat-toolbar color="primary">
  <mat-toolbar-row>{{'SEND_CONTRACT' | translate: {Default: 'Send Contract'} }}</mat-toolbar-row>
</mat-toolbar>

<div class="mdc-p-3 ">

  <form #contractForm="ngForm">
    <div fxLayout="column">
      <ng-container *ngIf="showCustomer">
        <app-existing-customer-selector [maxWidth]="100" [contextIdDto]="contextIdDto" *ngIf="existingCustomer" (selectedCustomer)="setCustomerId($event)" (newExistingSwitch)="existingCustomer = false">
        </app-existing-customer-selector>
        <app-new-customer-selector [contextIdDto]="contextIdDto" *ngIf="!existingCustomer" (selectedCustomer)="setCustomerId($event)" (newExistingSwitch)="existingCustomer = true">
        </app-new-customer-selector>
      </ng-container>

      <mat-form-field appearance="outline" *ngIf="customerId">
        <mat-label>{{'TO' | translate: {Default: 'To'} }}</mat-label>
        <input type="text" matInput name="recipient" #recipient="ngModel" [(ngModel)]="recipients" placeholder="{{'TO' | translate: {Default: 'To'} }}" required disabled>
        <mat-error *ngIf="recipient.hasError('required')">
          {{'TO_IS' | translate: {Default: 'To is'} }} <strong>{{'REQUIRED' | translate: {Default: 'required'} }}</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" *ngIf="contractTemplates">
        <mat-label>{{'SELECT_CONTRACT_TEMPLATE' | translate: {Default: 'Select Contract Template'} }}</mat-label>
        <mat-select name="contract" #contract="ngModel" [(ngModel)]="contractTemplateId" (ngModelChange)="templateChanged($event)" required placeholder="{{'SELECT_CONTRACT' | translate: {Default: 'Select Contract'} }}">
          <mat-option *ngFor="let contractTemplate of contractTemplates" [value]="contractTemplate.id">
            {{ contractTemplate.name }}
          </mat-option>
        </mat-select>

        <mat-error *ngIf="contract.hasError('required')">
          {{'CONTRACT_IS' | translate: {Default: 'Contract is'} }} <strong>{{'REQUIRED' | translate: {Default: 'required'} }}</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{'SUBJECT' | translate: {Default: 'Subject'} }}</mat-label>
        <input readonly matInput placeholder="{{'SUBJECT' | translate: {Default: 'Subject'} }}" name="subjectField" #subjectField="ngModel" [(ngModel)]="subject" id="subjectField" required>
        <mat-error *ngIf="subjectField.hasError('required')">
          {{'SUBJECT_IS' | translate: {Default: 'Subject is'} }} <strong>{{'REQUIRED' | translate: {Default: 'required'} }}</strong>
        </mat-error>
      </mat-form-field>

      <app-flo-editor [readOnly]="true" [text]="body" (textUpdated)="bodyUpdated($event)"></app-flo-editor>

    </div>
  </form>

  <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="16px" class="mt-1">
    <div fxFlex="nogrow">
      <button mat-raised-button color="accent" (click)="send()" [disabled]="!contractForm.valid || !allowedToSend">{{'SEND' | translate}}</button>
    </div>
    <div fxFlex="">
      <div *ngIf="!allowedToSend">
        <mat-error>{{'CANT_SEND_CONTRACT' | translate: {Default: 'You cant send contract, because its not allowed to send'} }} {{'EMAIL' | translate: {Default: 'Email'} }} {{'MESSAGE_TO_THIS_CUSTOMER' | translate: {Default: 'message to this customer'} }}.</mat-error>
      </div>
    </div>
  </div>

</div>