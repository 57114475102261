import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContextIdDto } from '@savvy/datalist';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';
import { AddEditPetTypeComponent } from '../add-edit-pet-type/add-edit-pet-type.component';
import { PetTypeService } from '@savvy/pet';
import { PetType } from '@savvy/pet/model/models';
import { ConfirmationModalComponent } from '../../shared/components/confirmation-modal/confirmation-modal.component';
import { ClearObservable } from '../../shared/classes/clear-observable';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-list-pet-type',
  templateUrl: './list-pet-type.component.html',
  styleUrls: ['./list-pet-type.component.scss']
})
export class ListPetTypeComponent extends ClearObservable implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  showToolbar = false;
  petTypes: Array<PetType>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private notify: FloSnackbarComponent,
    private petTypeService: PetTypeService,
    private dialog: MatDialog
  ) {
    super();
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (params['contextId'] && !this.contextIdDto) {
        this.contextIdDto = {} as ContextIdDto;
        this.contextIdDto.contextId = params['contextId'];
        this.contextIdDto.contextIdType = params['contextIdType'];
        this.showToolbar = true;
      } else {
        this.showToolbar = false;
      }
      this.listPetTypes();
    });
  }

  update(petType: PetType) {
    this.petTypeService.update(petType).subscribe(newPetTypes => {
      this.listPetTypes();
    });
  }

  deletePetType(index: number) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        name: this.petTypes[index].name
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.petTypes[index].deleted = true;
        this.update(this.petTypes[index]);

      }
    });
  }

  createPetType() {
    const data = {
      edit: false,
      petType: {
        name: '',
        description: '',
      },
    };
    const dialogRef = this.dialog.open(AddEditPetTypeComponent, {
      data,
      width: '400px'
    });

    dialogRef.afterClosed().subscribe(petType => {
      if (petType) {
        this.handleCreatePetType(petType);
      }
    });
  }

  updatePetType(row) {
    const data = {
      edit: true,
      petType: row,
    };
    if (!row) {
      data.edit = false;
    }
    const dialogRef = this.dialog.open(AddEditPetTypeComponent, {
      data,
      width: '400px'
    });

    dialogRef.afterClosed().subscribe(petType => {
      if (petType) {
        this.handleUpdatePetType(petType);
      }
    });
  }

  back() {
    history.back();
  }

  private listPetTypes() {
    this.petTypeService.listPetTypes()
      .subscribe(petTypes => {
        this.petTypes = petTypes;
      });
  }

  private handleCreatePetType(petType: PetType) {
    petType.ownerId = this.contextIdDto.contextId;
    this.petTypeService.create(
      petType)
      .subscribe((newPetType) => {
        if (newPetType) {
          this.notify.message = 'Pet type has been created';
          this.notify.open();
          this.listPetTypes();
        }
      });
  }

  private handleUpdatePetType(petType: PetType) {
    petType.ownerId = this.contextIdDto.contextId;

    this.petTypeService.update(
      petType)
      .subscribe((updatedPetType) => {
        if (updatedPetType) {
          this.notify.message = 'Pet type has been updated';
          this.notify.open();
          this.listPetTypes();
        }
      });
  }

}
