import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ChangeListener } from './changeListener';
import { PhoneNumberHelperService } from '../shared/services/phone-number-helper.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ContextIdDto, ListOrderListDto } from '@savvy/view-definition';
import { ViewContextDto } from '@savvy/view-composite';
import { LinkedIdTypeEnum, LinkId } from 'src/app/core/data-share.service';
import { UserCurrencyService } from '../shared/services/userCurrency.service';

@Component({
  selector: 'app-list-order-list',
  templateUrl: 'listOrderList.component.html',
  styleUrls: ['listOrderList.component.scss']
})
export class ListOrderListComponent implements OnInit, OnDestroy {
  currencyCode: string;
  destroy$ = new Subject();

  @Input() listOrderListDto: ListOrderListDto;
  @Input() contextIdDto: ContextIdDto;
  @Input() viewContext: any;
  @Input() form: UntypedFormGroup;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;

  constructor(private router: Router,
    private sharedService: PhoneNumberHelperService,
    private userCurrencyService: UserCurrencyService) {
  }

  ngOnInit() {
    this.getCurrencyCode();
  }

  getCurrencyCode() {
    if (this.contextIdDto) {
      this.userCurrencyService.getDefaultCurrency(this.contextIdDto)
        .pipe(takeUntil(this.destroy$))
        .subscribe(res => {
          this.currencyCode = res.org.currencyCode ? res.org.currencyCode : this.userCurrencyService.defaultCurrency;
        });
    } else {
      console.warn('no context id');
    }
  }

  create() {
    console.log('inside create in ListOrderListComponent');
    console.log('viewContext is ' + this.viewContext);
    console.log('this.contextIdDto.contextId is ' + this.contextIdDto.contextId);
    console.log('this.contextIdDto.contextIdType is ' + this.contextIdDto.contextIdType);
    if (this.viewContext.viewContextTypeDto === ViewContextDto.ViewContextTypeDtoEnum.Customer) {

      const linkIdType = LinkedIdTypeEnum.CustomerId;
      console.log('linkIdType is ' + linkIdType);

      this.router.navigate(['/order/addOrder',
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.viewContext.customerContextDto.customerId.id,
        linkIdType,
        ''
      ]);
    } else {
      // :linkId/:linkIdType/:linkEdId
      const linkIdType = LinkedIdTypeEnum.EntityInstanceId;
      console.log('linkIdType is ' + linkIdType);

      this.router.navigate(['/order/addOrder',
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.viewContext.entityInstanceId.id,
        linkIdType,
        this.viewContext.entityDefinitionId.id
      ]);
    }
  }

  rowSelected(orderId: string) {
    console.log('inside gotoEntity orderId ' + orderId);
    this.router.navigate(['/order/viewOrder',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      orderId
    ]);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}

