import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ContextIdDto, Contract, ContractCompService, EmailRecipient, SendContractWithUserIds } from '@savvy/contract';
import { CustomerCompositeService, CustomerService } from '@savvy/customer';
import { EmailMessageTemplate, EmailmessagetemplatesService } from '@savvy/email-template';
import { EicompService, FileValue, LoadEmailForEi } from '@savvy/entity-instance-composite';
import { UserId } from '@savvy/user';
import { LinkedIdTypeEnum } from 'src/app/core/data-share.service';
import { EventBusService } from '../../element/EventBusService';
import { EventType } from '../../event/UiEvent';
import { UploadFilesComponent } from "../../shared/components/upload-files/upload-files.component";
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';

@Component({
  selector: 'app-send-contract',
  templateUrl: './sendContract.component.html'
})
export class SendContractComponent implements OnInit {

  @ViewChild('uploadFiles') uploadFilesComponent: UploadFilesComponent;
  @Input() contract: Contract;
  @Input() contextIdDto: ContextIdDto;
  @Input() eventBus: EventBusService;
  @Input() viewContextDto: any;

  @Input() visible: boolean;

  @Output() cancel = new EventEmitter();

  body: string;
  subject: string;
  emailMessageTemplate: EmailMessageTemplate;
  recipients: string;
  showSpinner = false;
  allowedToSend = false;

  customerRecipient: EmailRecipient;

  @Output() contractSent = new EventEmitter();

  public form: UntypedFormGroup; // our form model

  savedFiles: FileValue[] = [];

  constructor(
    private eiApi: EicompService,
    private customerApi: CustomerCompositeService,
    private customerService: CustomerService,
    private notify: FloSnackbarComponent,
    private emailMessageApi: EmailmessagetemplatesService,
    private contractUiApi: ContractCompService) {

  }

  ngOnInit() {
    console.log('inside new message init');
    this.emailMessageApi.getEmailMessageTemplateByType(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType, 'contract').subscribe(response => {
        console.log('loading type');
        this.emailMessageTemplate = response;
        if (response) {
          this.subject = response.subject;
          this.body = response.body;

          if (response?.attachments) {
            this.savedFiles = response?.attachments;
          }
        }
      });
    this.loadRecipient();
  }
  bodyUpdated(text: string) {
    this.body = text;
  }

  loadRecipient() {
    if (this.contract && this.contract.linkId) {
      if (this.contract.linkId.linkedIdType === LinkedIdTypeEnum.EntityInstanceId) {
        const req = <LoadEmailForEi>{};
        req.entityInstanceId = this.contract.linkId.linkedId;
        req.contextIdDto = this.contextIdDto;

        this.eiApi.loadEmailForEi(req).subscribe(response => {
          if (response.customer.optIn) {
            this.allowedToSend = response.customer.optIn.allowEmail;
          }
          this.recipients = response.customer.username;
          this.customerRecipient = <EmailRecipient>{};
          this.customerRecipient.address = response.customer.username;
          this.customerRecipient.recipientId = response?.customer?.id;
          this.customerRecipient.recipientType = EmailRecipient.RecipientTypeEnum.Customer;
        });
      } else if (this.contract.linkId.linkedIdType === LinkedIdTypeEnum.CustomerId && this.contract?.linkId?.linkedId) {
        this.customerService.loadCustomer(this.contract.linkId.linkedId,
          this.contextIdDto.contextId,
          this.contextIdDto.contextIdType).subscribe(customerDto => {
            if (customerDto.optIn) {
              this.allowedToSend = customerDto.optIn.allowEmail;
            }
            this.recipients = customerDto.username;
            this.customerRecipient = <EmailRecipient>{};
            this.customerRecipient.address = customerDto.username;
            this.customerRecipient.recipientId = customerDto?.customerId?.id;
            this.customerRecipient.recipientType = EmailRecipient.RecipientTypeEnum.Customer;
          });
      }
    } else {
      console.log('Failed to load email connected with contract id ' + this.contract);
    }
  }

  cancelIt() {
    this.eventBus.addEvent(EventType.CANCEL_SEND_MESSAGE);
    this.cancel.emit('cancel');
  }

  send() {
    this.showSpinner = true;
    console.log('onSubmit');
    const req = <SendContractWithUserIds>{};
    req.subject = this.subject;
    req.body = this.body;
    req.emailRecipients = new Array();
    req.emailRecipients.push(this.customerRecipient);

    // .recipients.split(',');
    req.contextIdDto = this.contextIdDto;
    req.contractId = this.contract.contractId;
    req.attachments = this.getAttachments();

    this.contractUiApi.sendContractWithUserIds(req).subscribe(response => {
      console.log('Got response!');
      if (response.sendMessageError) {
        console.log('there was an error!');
        this.notify.message = 'Error sending contract ' + response.sendMessageError;
        this.notify.open();
      } else {
        this.notify.message = 'Sent Contract';
        this.notify.open();
      }
      /*
      this.translateService.get('Sent Contract').subscribe(value => {
        this.notify.message = value;
        this.notify.open();
      });*/
      this.showSpinner = false;
      this.eventBus.addEvent(EventType.SENT_CONTRACT);
      this.contractSent.emit('sentContract');
    });
  }

  getAttachments() {
    const attachments = new Array();

    if (this.uploadFilesComponent.savedFiles) {
      this.uploadFilesComponent.savedFiles.forEach(
        savedFile => {
          attachments.push({
            ...savedFile,
            publicBucket: false
          });
        }
      );
    }
    return attachments;
  }
}
