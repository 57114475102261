import {Injectable} from '@angular/core';
import {EmailmessagetemplatesService} from '@savvy/email-template';
import {ContextIdDto, Invoice, InvoicecompService, SendInvoiceWithUserIds, UpdateInvoiceComp} from '@savvy/invoice';
import {EmailRecipient} from '@savvy/messaging';
import {UserDto} from '@savvy/user';
import {FloSnackbarComponent} from 'src/app/flo/snackbar/floSnackbar.component';
import {StudentService} from "@savvy/student";

@Injectable({
  providedIn: 'root'
})
export class CourseBookingService {

  showSpinner = false;
  allowedToSend = false;
  recipientUser: UserDto;
  body: string;
  subject: string;
  emailMessageTemplate: any;
  recipients: string;
  customerRecipient: EmailRecipient;
  contextIdDto: ContextIdDto;

  invoice: Invoice;
  constructor(
    private studentService: StudentService,
    private emailMessageApi: EmailmessagetemplatesService,
    private invoicecompService: InvoicecompService,
    private notify: FloSnackbarComponent,

  ) { }

  updateInvoice(contextIdDto: ContextIdDto, invoiceId: string, created = false) {
    return new Promise(resolve => {
      this.contextIdDto = contextIdDto;
      if (this.invoice) {
        this.invoice.created = created;
        const updateInvoiceComp: UpdateInvoiceComp = {
          contextIdDto: this.contextIdDto,
          invoice: this.invoice
        };
        this.invoicecompService.updateInvoiceComp(updateInvoiceComp).subscribe(res => {
          resolve(res);
        });
      } else {
        this.loadInvoice(invoiceId).subscribe(res => {
          if (res) {
            // @ts-ignore
            this.invoice = res.invoiceDto.invoice;
            this.invoice.created = created;
            const updateInvoiceComp: UpdateInvoiceComp = {
              contextIdDto: this.contextIdDto,
              invoice: this.invoice
            };
            this.invoicecompService.updateInvoiceComp(updateInvoiceComp).subscribe(updatedInvoice => {
              resolve(updatedInvoice);
            });
          }
        });
      }
    });


  }

  loadInvoice(invoiceId) {
    return this.invoicecompService.loadInvoiceForView(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      invoiceId);
  }

  sendInvoice(contextIdDto: ContextIdDto, invoiceId: string) {
    this.contextIdDto = contextIdDto;
    // this.invoice = invoice;
    this.loadInvoice(invoiceId).subscribe(
      response => {
        console.log('loaded invoice dto', response.invoiceDto);
        // this.invoiceDto = response.invoiceDto;
        // @ts-ignore
        this.invoice = response.invoiceDto.invoice;
        this.setupEmailMessage();
        // this.isPaidFull = this.invoice.paidInFull;
        // this.model = {} as Payment;
        // this.model.amount = this.invoice.total;
      });
  }

  setupEmailMessage() {
    console.log('inside new message init');
    this.emailMessageApi.getEmailMessageTemplateByType(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType, 'invoice').subscribe(response => {
        console.log('loading type');
        this.emailMessageTemplate = response;
        this.subject = response.subject;
        this.body = response.body;
        this.loadRecipient();
      });
  }

  loadRecipient() {
    if (this.invoice) {
      let studentId = undefined;
      if (this.invoice.invoiceOwnerId && this.invoice.invoiceOwnerId.invoiceOwnerIdType === 'STUDENT_ID') {
        studentId = this.invoice.invoiceOwnerId.id;
      } else if (this.invoice.linkedId && this.invoice.linkedId.linkedIdType === "STUDENT_ID") {
        studentId = this.invoice.invoiceOwnerId.id;
      } else {
        console.log('no link id set');
      }

      if (studentId) {
        this.studentService.loadStudent(studentId, this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(response => {
          if (response) {
            if (response.optIn) {
              this.allowedToSend = response.optIn.allowEmail;
            }
            this.recipients = response.username;
            this.customerRecipient = {} as EmailRecipient;
            this.customerRecipient.address = response.username;
            this.customerRecipient.recipientId = response.id;
            this.customerRecipient.recipientType = EmailRecipient.RecipientTypeEnum.Student;
            this.send();
          }
        });
      }
    } else {
      console.log('ERROR: No invoice!!');
    }
  }

  send() {
    this.showSpinner = true;
    console.log('onSubmit');
    const req = {} as SendInvoiceWithUserIds;
    req.subject = this.subject;
    req.body = this.body;
    req.recipientEmailAddresses = new Array();
    req.recipientEmailAddresses.push(this.customerRecipient);
    req.contextIdDto = this.contextIdDto;
    req.invoiceId = this.invoice.invoiceId;



    this.invoicecompService.sendInvoiceComp(req).subscribe(response => {
      console.log('Got response! ' + response);
      if (response.sendMessageError) {
        console.log('there was an error!');
        this.notify.message = 'Error sending invoice ' + response.sendMessageError;
        this.notify.open();
        /*
        this.translateService.get('Sent Invoice')
          .subscribe(val => {
            this.notify.message = val;
            this.notify.open();
          });
         */
      } else {
        this.notify.message = 'Sent Invoice';
        this.notify.open();
      }
      this.showSpinner = false;
      // this.invoiceSent.emit('sentInvoice');
      // this.eventBus.addInvoiceEvent(EventType.SENT_INVOICE, this.invoice.invoiceId);
    });
  }

}
