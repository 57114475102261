import { Injectable } from '@angular/core';
import { ContextIdDto } from '@savvy/app';
import { Appointment, AppointmentPackageRow, AppointmentServiceRow } from '@savvy/appointment';
import { Customer, CustomerService } from '@savvy/customer';
import { Location } from '@savvy/location';
import { CustomerSearchResultDto } from '@savvy/search';
import { PetSearchResultDto } from '@savvy/search/model/petSearchResultDto';
import { ConsumerService } from '@savvy/services';
import { IdNameTupleDto, UserDto } from '@savvy/user';
import { WorkflowDefinition } from '@savvy/workflow-definition';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DateUtils } from '../../dates/DateUtils';
import { AppointmentConfigHelperService } from '../../shared/services/appointment-config-helper.service';
import { LookAndFeelSharedService, TimeValue } from '../../shared/services/look-and-feel-shared.service';
import { OrgUsersHelperService } from '../../shared/services/org-users-helper.service';
import { PackagesHelperService } from '../../shared/services/packages-helper.service';
import { ServicesHelperService } from '../../shared/services/services-helper.service';
import {
  AppointmentPackages,
  AppointmentServices,
  SelectedPackages,
  SelectedServices,
  ServiceSelectorData
} from '../shared/extra-types';

export class PopulateNewCustomerEventData {
  customerSearchResultDto: CustomerSearchResultDto;
  populatingAppointment: boolean;
}
@Injectable({
  providedIn: 'root'
})
export class CreateAppointmentV2SyncService {
  calendarDataLoaded = false;
  customersSelected: CustomerSearchResultDto[] = [];
  customersSelectedChips: string[] = [];
  selectedServices: SelectedServices = {};
  selectedPackages: SelectedPackages = {};

  selectedLocation: Location = {};
  selectedWorkflowDefinition: WorkflowDefinition;

  startDate = new Date();
  endDate = new Date();
  startDateReq = '';
  endDateReq = '';
  startTime = '';
  endTime = '';
  displayStartTime: Date;
  displayEndTime: Date;

  editMode = false;
  appointment: Appointment;
  groupedServicesWithCustomerId: AppointmentServices = {};
  groupedPackagesWithCustomerId: AppointmentPackages = {};

  userToUseWhenCreating: IdNameTupleDto;

  totalAmount = 0;
  totalAmountDue = 0;
  totalServices = 0;
  totalPackages = 0;

  timeArray: TimeValue[] = [];

  appointmentType: Appointment.AppointmentTypeEnum;

  // Allow to add packages to an appointment
  enablePackages = false;

  private customerSelectionChangeSource: Subject<CustomerSearchResultDto[]> = new Subject();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public customerSelectionChanged$ = this.customerSelectionChangeSource.asObservable();

  private populateNewCustomerSource: Subject<PopulateNewCustomerEventData> = new Subject();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public populateNewCustomer$ = this.populateNewCustomerSource.asObservable();

  private createCustomerSource: Subject<boolean> = new Subject();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public createCustomer$ = this.createCustomerSource.asObservable();

  private selectedLocationSource: BehaviorSubject<Location> = new BehaviorSubject(this.selectedLocation);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public selectedLocation$: Observable<Location> = this.selectedLocationSource.asObservable();

  constructor(
    private dateUtils: DateUtils,
    private lookAndFeelSharedService: LookAndFeelSharedService,
    private customerService: CustomerService,
    public appointmentConfigHelperService: AppointmentConfigHelperService,
    private packagesHelperService: PackagesHelperService,
    private servicesHelperService: ServicesHelperService,
    private orgUsersHelperService: OrgUsersHelperService
  ) { }

  setSelectedLocation(location: Location) {
    this.selectedLocation = Object.assign({}, location);
    this.selectedLocationSource.next(this.selectedLocation);
  }

  setTimeArray(contextIdDto) {
    if (contextIdDto) {
      const lookAndFeelConfig = this.lookAndFeelSharedService.getLookAndFeelConfig(contextIdDto);
      if (lookAndFeelConfig) {
        // const lookAndFeel = lookAndFeelConfig.lookAndFeel;
        // this.minuteInterval = this.lookAndFeelConfig.minuteInterval;
        this.timeArray = lookAndFeelConfig.timeArray;
      }
    }
  }

  updateCustomerSelection(newPets: PetSearchResultDto[]) {
    this.customersSelected = newPets;
    this.customerSelectionChangeSource.next(this.customersSelected);
  }

  dateFromTime(dStr) {
    const now = new Date();
    if (dStr) {
      now.setHours(dStr.substr(0, dStr.indexOf(':')));
      now.setMinutes(dStr.substr(dStr.indexOf(':') + 1));
      now.setSeconds(0);
    }
    return now;
  }

  setAppointmentDates(startDate: Date, endDate: Date, startTime: string, endTime: string) {
    if (startDate) {
      this.startDate = new Date(startDate);
      console.log('startDate was ', startDate);
      console.log('startDate is now ', this.startDate);
      this.startDateReq = this.dateUtils.getDateAsStringDash(startDate);
    }
    if (endDate) {
      this.endDate = new Date(endDate);
      this.endDateReq = this.dateUtils.getDateAsStringDash(endDate);
    }
    this.startTime = startTime;
    this.endTime = endTime;
    this.displayStartTime = this.dateFromTime(this.startTime);
    this.displayEndTime = this.dateFromTime(this.endTime);
    // this.syncServiceTimes();
  }

  calculateStartEndTime() {
    let minStart = '';
    let maxEnd = '';
    if (this.customersSelected && this.customersSelected?.[0]?.id) {
      minStart = this.selectedServices[this.customersSelected?.[0]?.id]?.[0]?.startTime || '';
      maxEnd = this.selectedServices[this.customersSelected?.[0]?.id]?.[0]?.endTime || '';
    }

    for (const pet of this.customersSelected) {
      if (pet?.id) {
        for (const key in this.selectedServices[pet.id]) {
          if (Object.prototype.hasOwnProperty.call(this.selectedServices[pet.id], key)) {
            const selectedData = this.selectedServices[pet.id][key] as ServiceSelectorData;

            if (minStart && selectedData?.startTime < minStart) {
              minStart = selectedData.startTime;
            }
            if (maxEnd && selectedData?.endTime > maxEnd) {
              maxEnd = selectedData.endTime;
            }
          }
        }
      }
    }
    if (this.timeArray.length) {
      // if (this.appointmentConfig.appointmentConfig.customizedTimeForServices) {
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let tIndex = 0; tIndex < this.timeArray.length; tIndex++) {
        const t = this.timeArray[tIndex];
        if (minStart <= t.actualValue) {
          this.startTime = t.actualValue;
          break;
        }
      }
      this.displayStartTime = this.dateFromTime(this.startTime);

      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let index = 0; index < this.timeArray.length; index++) {
        const t = this.timeArray[index];
        if (maxEnd && t.actualValue >= maxEnd) {
          this.endTime = t.actualValue;
          break;
        }
      }
      this.displayEndTime = this.dateFromTime(this.endTime);
    }

    this.calculateTotalAmount();
    this.setAppointmentDates(this.startDate, this.endDate, this.startTime, this.endTime);
  }

  updateServiceSelectionFromQuickView(customerId: string, selectedService: ConsumerService, selectedStaff: UserDto) {
    if (!this.selectedServices[customerId]) {
      this.selectedServices[customerId] = [];
      this.selectedServices[customerId].push({
        selectedService: {} as ConsumerService,
        selectedStaff: null
      });
    }
    this.selectedServices[customerId][0].selectedService = selectedService;
    this.selectedServices[customerId][0].selectedStaff.id = selectedStaff?.id;
    this.selectedServices[customerId][0].selectedStaff.name = selectedStaff?.fullName;
    this.selectedServices[customerId][0].quantity = 1;
    this.selectedServices[customerId][0].subTotal = selectedService.unitPrice;
    this.selectedServices[customerId][0].grossPrice = selectedService.unitPrice;
    this.selectedServices[customerId][0].startTime = this.startTime;
    this.selectedServices[customerId][0].endTime = this.dateUtils.addMinsToTimeString(this.startTime, selectedService?.durationInMins);

    this.calculateTotalAmount();
    this.calculateStartEndTime();
  }

  clear() {
    this.customersSelected = [];
    this.customersSelectedChips = [];
    this.updateCustomerSelection([]);
    this.selectedServices = {};
    this.selectedPackages = {};
    this.selectedLocation = {};
    this.selectedWorkflowDefinition = <WorkflowDefinition>{};
    this.editMode = false;
    this.appointment = null;
    this.groupedServicesWithCustomerId = {};
    this.groupedPackagesWithCustomerId = {};
    this.userToUseWhenCreating = null;
    this.appointmentType = null;
    this.enablePackages = false;
    this.totalAmount = 0;
    this.totalAmountDue = 0;
    this.totalServices = 0;
  }

  handleCreateCustomerFromList() {
    this.createCustomerSource.next(true);
  }

  getCustomerSearchResultDto(newCustomer: Customer, contextId: string): CustomerSearchResultDto {
    return {
      id: newCustomer.id,
      firstName: newCustomer.firstName,
      lastName: newCustomer.lastName,
      // fullName: newCustomer.fullName,
      ownerId: contextId,
      mobileInternational: newCustomer.mobilePhoneNumberObj?.internationalNumber,
      mobileNational: newCustomer.mobilePhoneNumberObj?.nationalNumber,
      deleted: false
    };
  }

  populateNewCustomer(newCustomer: Customer, contextId: string) {
    const customerSearchResultDto = this.getCustomerSearchResultDto(newCustomer, contextId);
    this.customersSelected.push(customerSearchResultDto);
    this.customersSelectedChips.push(customerSearchResultDto.fullName);
    this.customerSelectionChangeSource.next(this.customersSelected);
  }

  populatePackages(packageRows: AppointmentPackageRow[], contextIdDto: ContextIdDto) {
    if (this.customersSelected && this.customersSelected.length) {
      this.packagesHelperService.getPackages(contextIdDto).then(packages => {
        for (const customerSelected of this.customersSelected) {
          for (const packageRow of packageRows) {
            const packageSelected = packages.find(s => s.id === packageRow.packageId);
            const selectedPackageUser = this.orgUsersHelperService.staffUsers.find(u => u.id === packageRow?.packageUser?.id);
            if (!this.selectedPackages[customerSelected.id]) {
              this.selectedPackages[customerSelected.id] = [];
            }
            this.selectedPackages[customerSelected.id].push(
              {
                selectedPackage: packageSelected,
                selectedPackageUser,
                grossPrice: packageRow.grossPrice,
                quantity: packageRow.quantity,
                subTotal: packageRow.subTotal,
                startTime: packageRow.startTime || this.startTime,
                endTime: packageRow.endTime || this.endTime,
              }
            );
          }
        }
      });
    }

  }

  populateServices(serviceRows: AppointmentServiceRow[], contextIdDto: ContextIdDto) {
    if (this.customersSelected && this.customersSelected.length) {
      this.servicesHelperService.getConsumerServices(contextIdDto).then(consumerServices => {
        for (const customerSelected of this.customersSelected) {
          if (customerSelected?.id) {
            for (const serviceRow of serviceRows) {
              const selectedService = consumerServices.find(s => s.id === serviceRow.serviceNumber);
              const selectedStaff = this.orgUsersHelperService.staffUsers.find(u => u.id === serviceRow?.serviceUser?.id);
              if (!this.selectedServices[customerSelected.id]) {
                this.selectedServices[customerSelected.id] = [];
              }
              this.selectedServices[customerSelected.id].push(
                {
                  selectedService,
                  selectedStaff,
                  grossPrice: serviceRow.grossPrice,
                  quantity: serviceRow.quantity,
                  subTotal: serviceRow.subTotal,
                  startTime: serviceRow.startTime || this.startTime,
                  endTime: serviceRow.endTime || this.endTime,
                }
              );
            }
          }
        }
      });
    }

  }

  populateAppointment(appointment: Appointment, contextIdDto: ContextIdDto) {
    this.appointment = appointment;
    this.calculateTotalAmount();
    this.setAppointmentDates(
      this.dateUtils.convertDateStringToJsDate(appointment.startDate),
      this.dateUtils.convertDateStringToJsDate(appointment.endDate),
      appointment.startTime, appointment.endTime);

    this.groupedServicesWithCustomerId[appointment.customerId] = appointment.appointmentServiceRows;
    this.groupedPackagesWithCustomerId[appointment.customerId] = appointment.appointmentPackageRows;
    this.customerService.loadCustomer(appointment.customerId, contextIdDto.contextId, contextIdDto.contextIdType).subscribe((customer) => {
      const customerResult: CustomerSearchResultDto = {
        id: customer.customerId.id,
        firstName: customer.firstName,
        lastName: customer.lastName,
        fullName: `${customer.firstName} ${customer.lastName}`,
        ownerId: contextIdDto.contextId,
        mobileInternational: customer.mobilePhoneNumberObj?.internationalNumber,
        mobileNational: customer.mobilePhoneNumberObj?.nationalNumber,
        deleted: false
      };
      this.populateNewCustomerSource.next({ customerSearchResultDto: customerResult, populatingAppointment: true });
    });
  }

  populateCustomerByCustomerId(customerId: string, contextIdDto: ContextIdDto) {
    this.customerService.loadCustomer(customerId, contextIdDto.contextId, contextIdDto.contextIdType).subscribe((customer) => {
      const customerResult: CustomerSearchResultDto = {
        id: customer.customerId.id,
        firstName: customer.firstName,
        lastName: customer.lastName,
        fullName: `${customer.firstName} ${customer.lastName}`,
        ownerId: contextIdDto.contextId,
        mobileInternational: customer.mobilePhoneNumberObj?.internationalNumber,
        mobileNational: customer.mobilePhoneNumberObj?.nationalNumber,
        deleted: false
      };
      this.populateNewCustomerSource.next({ customerSearchResultDto: customerResult, populatingAppointment: false });
    });
  }

  calculateTotalAmount() {
    this.totalAmount = 0;
    this.totalServices = 0;
    this.totalPackages = 0;
    for (const key in this.selectedServices) {
      if (Object.prototype.hasOwnProperty.call(this.selectedServices, key)) {
        const selectedServices = this.selectedServices[key];
        for (const selectedService of selectedServices) {
          this.totalAmount += Number(selectedService.grossPrice);
        }
        this.totalServices += selectedServices.length;
      }
    }
    if (this.enablePackages) {
      for (const key in this.selectedPackages) {
        if (Object.prototype.hasOwnProperty.call(this.selectedPackages, key)) {
          const selectedPackages = this.selectedPackages[key];
          for (const selectedPackage of selectedPackages) {
            this.totalAmount += Number(selectedPackage.grossPrice);
          }
          this.totalPackages += selectedPackages.length;
        }
      }
    }
  }

  removeCustomer(customerRowIndex: number) {
    if (this.enablePackages && Object.keys(this.selectedPackages).length) {
      delete this.selectedServices[this.customersSelected[customerRowIndex].id];
      this.calculateTotalAmount();
      return;
    }
    this.customersSelected.splice(customerRowIndex, 1);
    this.customersSelectedChips.splice(customerRowIndex, 1);
    this.updateCustomerSelection(this.customersSelected);
    this.calculateTotalAmount();
  }

  updateStartTimeAndEndTimeByStartTime(startTime: string) {
    for (const customerSelected of this.customersSelected) {
      for (const service of this.selectedServices[customerSelected.id]) {
        const startTimeMoment = moment(service.startTime ? service.startTime : this.startTime, 'HH:mm');
        const endTimeMoment = moment(service.endTime ? service.endTime : this.endTime, 'HH:mm');
        const duration = moment.duration(endTimeMoment.diff(startTimeMoment));
        const hours = duration.asHours();
        service.startTime = startTime;
        service.endTime = moment(startTime, 'HH:mm').add(hours, 'hours').format('HH:mm');
      }
    }
  }

}
