import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, HostBinding, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { GLOBAL } from './app.constants';
import { UiAuthService } from './flo/auth/ui-auth.service';
import { Favicons } from './flo/logo/browserFavicon.component';
import { FaviconService } from './flo/logo/favicon.service';
import { IconService } from './flo/logo/icon.service';
import { FloSnackbarComponent } from './flo/snackbar/floSnackbar.component';
import { StyleService } from './flo/style/style.service';
import { SupportedBrowsers } from './flo/unsupported-browsers/supported-browsers';
import { WindowRef } from './flo/windowWrapper';
// register locale
import { registerLocaleData } from '@angular/common';
import localeArAE from '@angular/common/locales/ar-AE';
import localeArKw from '@angular/common/locales/ar-KW';
import localeDe from '@angular/common/locales/de';
import localeEnUS from '@angular/common/locales/en';
import localeEnAU from '@angular/common/locales/en-AU';
import localeEnCA from '@angular/common/locales/en-CA';
import localeEnGB from '@angular/common/locales/en-GB';
import localeEnIE from '@angular/common/locales/en-IE';
import localeEnNL from '@angular/common/locales/en-NL';
import localeEnNZ from '@angular/common/locales/en-NZ';
import localeEnZA from '@angular/common/locales/en-ZA';
import localeSpain from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeHu from '@angular/common/locales/hu';
import localeNL from '@angular/common/locales/nl';
import { AuthService } from '@auth0/auth0-angular';
import { ContextIdDto } from '@savvy/subscription';
import { BootstrapService } from './bootstrap.service';

declare const window: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

  @HostBinding('class') componentCssClass;

  subscriptions: Subscription[] = [];

  contextIdDto: ContextIdDto;
  loadedRequiredData = false;

  constructor(
    private translateService: TranslateService,
    public styleService: StyleService,
    public authService: AuthService,
    private deviceService: DeviceDetectorService,
    public favicons: Favicons,
    public faviconService: FaviconService,
    private router: Router,
    public notify: FloSnackbarComponent,
    private windowRef: WindowRef,
    public bootstrapService: BootstrapService,
    public vcRef: ViewContainerRef
  ) {

  }

  registerLocales() {
    this.translateService.addLangs([
      'en', // English
      'es', // Spain
    ]);
    registerLocaleData(localeEnUS);
    registerLocaleData(localeEnGB);
    registerLocaleData(localeEnAU);
    registerLocaleData(localeEnNZ);
    registerLocaleData(localeEnCA);
    registerLocaleData(localeEnIE);
    registerLocaleData(localeEnNL);
    registerLocaleData(localeNL);
    registerLocaleData(localeEnZA);
    registerLocaleData(localeSpain);
    registerLocaleData(localeArKw);
    registerLocaleData(localeArAE);
    registerLocaleData(localeDe);
    registerLocaleData(localeFr);
    registerLocaleData(localeHu);

    this.setDefaultLocale();
  }

  initialize() {

    GLOBAL.localhost = this.windowRef.getWindow().location.hostname;

    console.log('in here AppComponent');
    console.log(this.deviceService.browser);
    console.log(this.deviceService.browser_version);
    // this.checkSupportedBrowsers();

    console.log('browser ' + this.deviceService.browser);
    console.log('browser_version ' + this.deviceService.browser_version);

    this.subscriptions.push(this.styleService.eventAdded$.subscribe(event => this.setTheme(event)));
    this.subscriptions.push(this.faviconService.eventAdded$.subscribe(event => this.onSetFavicon(event)));

    this.registerLocales();

    // enable Google Anlytics for route navigations
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (window) {
          window.Intercom('update');
          if (window.ga) {
            window.ga('set', 'page', event.urlAfterRedirects);
            window.ga('send', 'pageview');
          }
        }
      }
    });

    this.bootstrapService.bootstrap();

  }

  setDefaultLocale() {
    console.log('Setting default language to en');
    this.translateService.setDefaultLang('en');
    this.translateService.use('en');
  }

  onSetFavicon(faviconDir) {
    try {
      console.log('faviconDir', faviconDir);
      this.favicons.activate(faviconDir);
    } catch (e) {
      console.log('Error activating favicon ', e);
      this.favicons.reset();
    }
  }

  checkSupportedBrowsers() {
    if (this.deviceService.browser === 'Chrome') {
      const supportedChromeVersion = 72;
      const chromeVersion = +this.deviceService.browser_version.substring(0, 2);
      if (chromeVersion < supportedChromeVersion) {
        this.router.navigate(['chrome-update']);
      }
    } else if (!SupportedBrowsers.includes(this.deviceService.browser)) {
      this.router.navigate(['unsupported']);
    }
  }

  ngOnInit() {
    const urlParams = new URLSearchParams(window.location.search);

    const username = urlParams.get('username');
    console.log('got username:', username);

    this.authService.isAuthenticated$.subscribe(async (authenticated) => {
      console.log('is authenticated ', authenticated);
      if (authenticated) {
        this.initialize();
      } else {
        this.authService.loginWithRedirect({
          appState: {
            target: window.location.origin,
          },
          authorizationParams: {
            login_hint: username || '',
            domainName: location.host
          },
        });
      }
    });

  }

  setTheme(theme: string) {
    document.body.classList.add(theme);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

}
