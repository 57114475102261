import { Injectable } from '@angular/core';
import { ContextIdDto, IdNameTupleDto, LoadUsersForServiceSelector, UserDto, UserLiteDto, UsercompService, UsersService } from '@savvy/user';
import { LocationsHelperService } from './locations-helper.service';
import { AppointmentConfigHelperService } from './appointment-config-helper.service';
import { ServicesHelperService } from './services-helper.service';
import { FeaturesHelperService } from './features-helper.service';
import { Feature } from '@savvy/subscription';

@Injectable({
  providedIn: 'root'
})
export class OrgUsersHelperService {

  staffUsers: UserLiteDto[] = [];

  constructor(
    private usercompService: UsercompService,
    private usersService: UsersService,
    private locationsHelperService: LocationsHelperService,
    private appointmentConfigHelperService: AppointmentConfigHelperService,
    private servicesHelperService: ServicesHelperService,
    private featuresHelperService: FeaturesHelperService
  ) { }



  filterUsersForLocationMatrix(staffUsers: UserLiteDto[], locationId?: string, currentStaffUserId?: string): UserLiteDto[] {
    if (this.locationsHelperService.userLocationMatrixDto && locationId
      && this.appointmentConfigHelperService.appointmentConfig.filterStaffOnLocationMatrix) {
      console.log('filtering staff list with location matrix', { locationId, staffUsers });
      return staffUsers.filter(u => {
        if (u.id && !this.locationsHelperService.isUserAllowedInLocation(locationId, u.id) && currentStaffUserId === u.id) {
          u.name = `${u.name} (Not working at selected location)`;
        }
        return (u.id && this.locationsHelperService.isUserAllowedInLocation(locationId, u.id)) || currentStaffUserId === u.id;
      });
    }
    return staffUsers;
  }

  filterUsersForServiceMatrix(staffUsers: UserLiteDto[], serviceId?: string, currentStaffUserId?: string): UserLiteDto[] {
    if (this.servicesHelperService.userServiceMatrixDto && serviceId
      && this.appointmentConfigHelperService.appointmentConfig.filterStaffOnServiceMatrix) {
      console.log('filtering staff list with service matrix', { serviceId, staffUsers });
      return staffUsers.filter(u => {
        if (u.id && !this.servicesHelperService.isUserAllowedForService(serviceId, u.id) && currentStaffUserId === u.id) {
          u.name = `${u.name} (Can't serve selected service)`;
        }
        return (u.id && this.servicesHelperService.isUserAllowedForService(serviceId, u.id)) || currentStaffUserId === u.id;
      });
    }
    return staffUsers;
  }

  getUser(id: string): UserLiteDto {
    if (this.staffUsers.length && this.staffUsers.find(u => u.id === id)) {
      return this.staffUsers.find(u => u.id === id) as UserLiteDto;
    }
    return {} as UserLiteDto;
  }

  loadUsers(contextIdDto: ContextIdDto, serviceId?: string, locationId?: string) {
    return new Promise<UserLiteDto[]>(resolve => {
      this.staffUsers = [];
      if (contextIdDto?.contextId) {
        if (locationId || serviceId) {
          this.usercompService.listEnvUsersFiltered(contextIdDto.contextId, locationId).subscribe(response => {
            if (response.userDtos && response.userDtos.length) {
              this.staffUsers = response.userDtos;
            }
            resolve(this.staffUsers);
          });
        } else {
          this.usercompService.listAllOrgUsers(contextIdDto.contextId, false).subscribe(response => {
            if (response.userDtos && response.userDtos.length) {
              this.staffUsers = response.userDtos;
              console.log('loaded Users', this.staffUsers);
            }
            resolve(this.staffUsers);
          });
        }
      }
    });
  }

  filterArchived(staffUsers: UserLiteDto[], currentStaffUserId?: string): UserLiteDto[] {
    return staffUsers.filter(u => {
      if (u.deleted && currentStaffUserId && currentStaffUserId === u.id) {
        u.name = `${u.name} (Archived)`;
      }
      return (currentStaffUserId && currentStaffUserId === u.id) || !u?.deleted;
    });
  }

  filterDeleted(staffUsers: UserLiteDto[], currentStaffUserId?: string): UserLiteDto[] {
    if (currentStaffUserId && staffUsers.findIndex(u => u.id === currentStaffUserId) === -1) {
      staffUsers.push({ id: currentStaffUserId, name: `User has been deleted permanently` });
    }
    return staffUsers;
  }

  loadStaffMembers(currentStaffUserId?: string, locationId?: string, serviceId?: string): Promise<UserLiteDto[]> {
    return new Promise((resolve, reject) => {
      this.usersService.getOrgUsers().subscribe((users: UserLiteDto[]) => {

        this.staffUsers = this.filterArchived(users, currentStaffUserId);
        console.log('Filtered with archived', this.staffUsers);

        if (this.featuresHelperService.isFeatureAllowed(Feature.TypeEnum.MultiLocation)) {
          this.staffUsers = this.filterUsersForLocationMatrix(this.staffUsers, locationId, currentStaffUserId);
          console.log('Filtered with location matrix', this.staffUsers);

          this.staffUsers = this.filterUsersForServiceMatrix(this.staffUsers, serviceId, currentStaffUserId);
          console.log('Filtered with service matrix', this.staffUsers);
        }

        this.staffUsers = this.filterDeleted(this.staffUsers, currentStaffUserId);
        console.log('User is deleted forever', this.staffUsers);

        resolve(this.staffUsers);
      });
    });
  }

}
