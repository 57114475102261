import { Component } from '@angular/core';


@Component({
  selector: 'app-map-panel',
  styleUrls: ['mapPanel.component.scss'],
  templateUrl: 'mapPanel.component.html'
})
export class MapPanelComponent {

  lat = -34.397;
  lng = 150.644;
  zoom = 11;
  mapOptions: google.maps.MapOptions = {
    center: { lat: 55.624928, lng: -3.453697 },
    zoom: 8
  };
}

