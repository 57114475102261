import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import {
  ActionDefinition,
  ContextIdDto,
  MarkAsDoneTask,
  Task,
  TaskcompService,
  TaskId,
  TaskService,
  UpdateTask
} from '@savvy/task';
import { EventBusService } from '../element/EventBusService';
import { EventType, UiEvent } from '../event/UiEvent';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { ExecuteSuppliedTask } from '@savvy/task/model/executeSuppliedTask';

import { Customer } from '@savvy/customer';

@Component({
  selector: 'app-show-task',
  templateUrl: 'showTask.html'
})
export class ShowTaskComponent implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() visible: boolean;
  @Input() eventBus: EventBusService;
  @Output() taskComplete = new EventEmitter();

  task: Task;
  customerDto: Customer;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notify: FloSnackbarComponent,
    private translateService: TranslateService,
    private compApi: TaskcompService,
    private api: TaskService) {

  }

  ngOnInit() {
    this.eventBus.eventAdded$.subscribe(event => this.onEvent(event));
  }

  loadTask(taskId: TaskId) {
    if (this.task && taskId.id === this.task.taskId.id) {
      // Already got that task
      console.log('Already got task loaded');
    } else {
      this.compApi.getCustomerAndTask(taskId.id,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType).subscribe(response => {
          console.log('loaded task ', response);
          this.task = response.task;
          this.customerDto = response['customerDto'];
        });
    }
  }

  executeTask(task: Task) {

    console.log('executing task ' + task.id);

    const req = <ExecuteSuppliedTask>{};
    req.task = task;
    req.contextIdDto = this.contextIdDto;

    this.compApi.executeSuppliedTask(req).subscribe(response => {
      console.log('Called execute task, now what???, need to refresh the tasks');

      if (response.executeActionResponse && response.executeActionResponse.error) {

        this.notify.message = 'Error executing task ' + response.executeActionResponse.error;
        this.notify.open();
      } else {
        // Push message to say task has been executed?
        // this.getTasks();
        // this.notify.msg('Sent Message');
        this.notify.message = 'Sent message';
        this.notify.open();

        this.taskComplete.emit('any');
        this.eventBus.addTaskEvent(EventType.TASK_EXECUTED, task.taskId);
      }
    });
  }

  markAsDone() {
    this.task.complete = true;

    const req = <MarkAsDoneTask>{};

    req.taskId = this.task.taskId;
    req.contextIdDto = this.contextIdDto;

    this.api.markAsDone(req).subscribe(response => {
      console.log('marked task as done');
      this.task = response;
      this.eventBus.addTaskEvent(EventType.TASK_MARKED_AS_DONE, this.task.taskId);
    });
  }

  updateTask() {

    console.log('updating task id ' + this.task.taskId.id);

    const req = <UpdateTask>{};

    req.task = this.task;
    req.contextId = this.contextIdDto.contextId;
    req.contextIdType = this.contextIdDto.contextIdType;

    this.api.updateTask(req).subscribe(response => {
      console.log('marked task as done');
      this.eventBus.addTaskEvent(EventType.TASK_MARKED_AS_DONE, this.task.taskId);
    });
  }

  selectedTaskActionable() {
    return this.task.workflowTaskDefinition.actionDefinition;
  }

  bodyUpdated(event: any) {

  }

  hasExecutableAction() {
    return this.task.workflowTaskDefinition.actionDefinition;
  }

  showErrorMessage(): Observable<string | boolean> {
    if (this.task.workflowTaskDefinition.actionDefinition.actionType === ActionDefinition.ActionTypeEnum.SendMessage) {
      if (this.customerDto && !this.customerDto.username) {
        return this.translateService.get('Cannot send Message as customer has no Email');
      } else if (this.customerDto && !this.customerDto?.optIn?.allowEmail) {
        return this.translateService.get('Cannot send Message as it is not allowed to send Email message to this customer');
      }
    } else if (this.task.workflowTaskDefinition.actionDefinition.actionType === ActionDefinition.ActionTypeEnum.SendSms) {
      if (this.customerDto && !this.customerDto.mobilePhoneNumberObj) {
        return this.translateService.get('Cannot send Message as customer has no Mobile');
      } else if (this.customerDto && !this.customerDto?.optIn?.allowSms) {
        return this.translateService.get('Cannot send Message as it is not allowed to send SMS message to this customer');
      }
    }

    return of(false);
  }

  canExecute() {
    if (this.task.workflowTaskDefinition.actionDefinition.actionType === ActionDefinition.ActionTypeEnum.SendMessage) {
      if (this.customerDto && (!this.customerDto.username || !this.customerDto.optIn.allowEmail)) {
        return false;
      }
    }
    return true;
  }


  public gotoEmailSettings() {
    console.log('inside gotoEmailsettings');

    this.router.navigate(['/emailSettings',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType]);
  }

  closeThePanel() {
    this.eventBus.addEvent(EventType.CLOSE_TASK);
  }

  private onEvent(event: UiEvent) {
    console.log('showTask:onEvent received', event);
    if (event.eventType === EventType.TASK_SELECTED) {
      this.loadTask(event.taskId);
    }
  }

}


