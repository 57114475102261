import { Component, Input } from '@angular/core';
import { AvatarRowDto } from '@savvy/view-definition';

@Component({
    selector: 'app-avatar-row',
    templateUrl: 'avatarRow.component.html'
})
export class AvatarRowComponent {

    @Input() avatarRowDto: AvatarRowDto;
}

