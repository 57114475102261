import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ContextIdDto } from '@savvy/app';
import { PetType, PetTypeService } from '@savvy/pet';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AddEditPetTypeComponent } from '../../petdaycare/add-edit-pet-type/add-edit-pet-type.component';
import { ClearObservable } from '../../shared/classes/clear-observable';

@Component({
  selector: 'app-pet-type-selector',
  templateUrl: './pet-type-selector.component.html',
  styleUrls: ['./pet-type-selector.component.scss']
})
export class PetTypeSelectorComponent extends ClearObservable implements OnInit {
  @Input() contextIdDto: ContextIdDto;
  @Input() selectedPetType = new FormControl<string>('');

  @Output() petTypeChanged: EventEmitter<string> = new EventEmitter();

  petTypes: PetType[] = [];
  public filteredPetTypes: ReplaySubject<PetType[]> = new ReplaySubject<PetType[]>(1);
  public petTypeFilter: UntypedFormControl = new UntypedFormControl();

  constructor(
    private dialog: MatDialog,
    private petTypeService: PetTypeService,
  ) {
    super();
  }

  ngOnInit() {
    this.petTypeFilter.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.filterPetTypes();
      });
    this.loadPetTypes();

    this.selectedPetType.valueChanges.subscribe(any => {
      this.petTypeChanged.emit(this.selectedPetType.value);
    })
  }

  filterPetTypes() {
    if (!this.petTypes) {
      return;
    }
    // get the search keyword
    let search = this.petTypeFilter.value;
    if (!search) {
      this.filteredPetTypes.next(this.petTypes.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the breeds
    this.filteredPetTypes.next(
      this.petTypes.filter(petType => petType.name.toLowerCase().indexOf(search) > -1)
    );
  }


  loadPetTypes(newPetType?: PetType) {
    this.petTypeService.listPetTypes()
      .subscribe(petTypes => {
        if (petTypes && petTypes.length) {
          this.petTypes = petTypes;
          this.filterPetTypes();
          if (!this.selectedPetType?.value) {
            for (let i = 0; i < petTypes?.length; i += 1) {
              if (petTypes[i]?.name === 'Dog') {
                this.selectedPetType.patchValue(petTypes[i]?.name);
              }
            }
          }
          if (newPetType) {
            this.selectedPetType.patchValue(newPetType.name);
          }
        }
      });
  }

  createPetType() {
    const data = {
      edit: false,
      petType: {
        name: '',
        description: '',
      },
    };
    const dialogRef = this.dialog.open(AddEditPetTypeComponent, {
      data,
      width: '400px'
    });

    dialogRef.afterClosed().subscribe(petType => {
      if (petType) {
        this.handleCreatePetType(petType);
      }
    });
  }

  private handleCreatePetType(petType: PetType) {
    petType.ownerId = this.contextIdDto.contextId;
    this.petTypeService.create(petType)
      .subscribe((newPetType) => {
        if (newPetType) {
          this.loadPetTypes(newPetType);
        }
      });
  }

}
