import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContextService } from '../context.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import {
  ContextIdDto,
  CreateFlagDef,
  EntityDefinitionId,
  FlagDefinition,
  FlagDefService,
  UpdateFlagDef
} from '@savvy/flags';
import { Subscription } from 'rxjs';
import {FlagDefHelperService} from "../../services/flagDefHelper.service";

@Component({
  selector: 'app-add-edit-flag-def',
  templateUrl: './addEditFlagDef.component.html'
})
export class AddEditFlagDefComponent implements OnInit, OnDestroy {

  contextIdDto: ContextIdDto;
  flagDefinition: FlagDefinition;
  subscriptions: Subscription[] = [];
  constructor(
    private router: Router,
    private api: FlagDefService,
    private flagDefHelperService: FlagDefHelperService,
    private route: ActivatedRoute,
    private notify: FloSnackbarComponent,
    private contextService: ContextService) {
  }

  ngOnInit() {
    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
        this.route.params.subscribe(params => {
          if (params['flagDefinitionId']) {
            const flagDefinitionId = params['flagDefinitionId'];
            console.log('got flagDefinitionId ' + flagDefinitionId);
            this.load(flagDefinitionId);
          } else {
            const flagDefinitionType = params['flagDefinitionType'];
            this.flagDefinition = <FlagDefinition>{};
            this.flagDefinition.flagDefinitionType = this.getFlagDefType(flagDefinitionType);
            if (this.flagDefinition.flagDefinitionType === FlagDefinition.FlagDefinitionTypeEnum.Entity) {
              this.flagDefinition.entityDefinitionId = <EntityDefinitionId>{};
              this.flagDefinition.entityDefinitionId.id = params['entityDefinitionId'];
            }
            this.flagDefinition.ownerId = this.contextIdDto.contextId;
          }
        });
      }
    }));

  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getFlagDefType(flagDefType: string) {
    if (flagDefType === 'CUSTOMER') {
      return FlagDefinition.FlagDefinitionTypeEnum.Customer;
    } else if (flagDefType === 'ENTITY') {
      return FlagDefinition.FlagDefinitionTypeEnum.Entity;
    } else if (flagDefType === 'PET') {
      return FlagDefinition.FlagDefinitionTypeEnum.Pet;
    } else if (flagDefType === 'STUDENT') {
      return FlagDefinition.FlagDefinitionTypeEnum.Student;
    } else if (flagDefType === 'LEAD') {
      return FlagDefinition.FlagDefinitionTypeEnum.Lead;
    }
    return null;
  }

  load(flagDefId: string) {
    console.log('loading with id ' + flagDefId);
    this.api.loadFlagDefinition(flagDefId,
      this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(
        response => {
          console.log('got');
          this.flagDefinition = response;
        }
      );
  }

  create() {
    console.log('inside create');
    const req = <CreateFlagDef>{};
    req.contextIdDto = this.contextIdDto;
    req.flagDefinition = this.flagDefinition;

    this.api.createFlagDef(req).subscribe(
      response => {
        this.flagDefinition = response;
        this.updateCache();
        this.notify.message = 'Successfully added flag';
        this.notify.open();
        this.backToList();
      });
  }


  update() {
    if (this.flagDefinition.id) {
      const req = <UpdateFlagDef>{};
      req.contextIdDto = this.contextIdDto;
      req.flagDefinition = this.flagDefinition;

      this.api.updateFlagDef(req).subscribe(
        response => {
          this.flagDefinition = response;
          this.notify.message = 'Successfully updated flag';
          this.notify.open();
          this.backToList();
        });
    } else {
      this.create();
    }
  }

  updateCache() {
    this.api.listFlagDefs(this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(res => {
      if (res.flagDefinitions) {
        this.flagDefHelperService.setFlagDefs(res.flagDefinitions);
      }
    });
  }

  updateAndEmit() {
    console.log('inside update flagDefinition');

    const req = <UpdateFlagDef>{};
    req.contextIdDto = this.contextIdDto;
    req.flagDefinition = this.flagDefinition;

    this.api.updateFlagDef(req).subscribe(
      response => {
        this.updateCache();
        this.notify.message = 'Successfully updated flag';
        this.notify.open();
        this.backToList();
      });
  }

  backToList() {
    this.router.navigate(['/admin/settings',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType, 'flags']);
  }
}
