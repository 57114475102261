import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ContextIdDto } from '@savvy/datalist';
import {
  ActionDefinition, RemoveTaskFromState, SendMessageDefinition, TaskSettings,
  WorkflowDefinitionTypeData, WorkflowdefService, WorkflowState,
  WorkflowTaskDefinition, AutoTaskDefinition, WorkflowScheduledTime, WorkflowScheduleDefinition, TimePeriodValue
} from '@savvy/workflow-definition';
import { TimePeriodOption } from '../../schedule/addEditScheduleDef.component';

import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MovingDirection, WizardComponent } from '@viso-trust/angular-archwizard';
import { ConfirmationModalComponent } from '../../shared/components/confirmation-modal/confirmation-modal.component';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';

interface ScheduleType {
  label: string;
  scheduleType: WorkflowScheduleDefinition.WorkflowScheduleTypeEnum;
}

@Component({
  selector: 'app-send-message-task',
  templateUrl: './send-message-task.component.html',
  styleUrls: ['./send-message-task.component.scss']
})
export class SendMessageTaskComponent implements OnInit {
  @ViewChild(WizardComponent) public wizard: WizardComponent;

  contextIdDto: ContextIdDto;
  workflowTaskDefinition: WorkflowTaskDefinition;
  workflowState: WorkflowState;
  workflowDefinitionId: string;
  workflowDefinitionTypeData: WorkflowDefinitionTypeData;

  scheduleDefinition: WorkflowScheduleDefinition;
  timePeriodOptions: Array<TimePeriodOption>;
  scheduleTypes: Array<ScheduleType> = new Array<ScheduleType>();

  initialised = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog,
    private notify: FloSnackbarComponent,
    private workflowdefService: WorkflowdefService,
    public dialogRef: MatDialogRef<SendMessageTaskComponent>) {


    this.contextIdDto = data.contextIdDto;
    this.workflowState = data.workflowState;
    this.workflowDefinitionTypeData = data.workflowDefinitionTypeData;
    this.workflowDefinitionId = data.workflowDefinitionId;
    console.log('set to ', this.workflowDefinitionTypeData);

  }

  ngOnInit(): void {
    if (this.data.workflowTaskDefinition) {
      this.workflowTaskDefinition = this.data.workflowTaskDefinition;
    }
    this.initialise();
    this.buildTimePeriodList();
  }

  enterStep(movingDirection: MovingDirection, stepId: string) {
    console.log(stepId);
    if (movingDirection === MovingDirection.Forwards) {
      switch (stepId) {
        case 'messageName':
          break;
        case 'enterMessage':
          break;
        case 'schedule':
          break;

        default:
          break;
      }
    }
  }

  isRelativeTo() {
    return this.scheduleDefinition.workflowScheduleType === WorkflowScheduleDefinition.WorkflowScheduleTypeEnum.RelativeTo;
  }

  isSms() {

  }

  buildTimePeriodList() {
    this.timePeriodOptions = new Array();
    let timePeriodOption = <TimePeriodOption>{};
    timePeriodOption.timePeriodType = TimePeriodValue.TimePeriodTypeEnum.Days;
    timePeriodOption.label = 'Days';
    this.timePeriodOptions.push(timePeriodOption);
    timePeriodOption = <TimePeriodOption>{};
    timePeriodOption.timePeriodType = TimePeriodValue.TimePeriodTypeEnum.Hours;
    timePeriodOption.label = 'Hours';
    this.timePeriodOptions.push(timePeriodOption);
    timePeriodOption = <TimePeriodOption>{};
    timePeriodOption.timePeriodType = TimePeriodValue.TimePeriodTypeEnum.Months;
    timePeriodOption.label = 'Months';
    this.timePeriodOptions.push(timePeriodOption);
  }

  bodyUpdated(event: any) {
    this.workflowTaskDefinition.actionDefinition.sendMessageDefinition.body = event;
  }

  save() {
    console.log('closing dialog ref as save has been called');
    this.dialogRef.close(this.workflowTaskDefinition);
  }

  delete() {

    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        message: 'Are you sure want to delete'
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {

        const req = <RemoveTaskFromState>{};
        req.contextIdDto = this.contextIdDto;
        req.workflowDefinitionId = this.workflowDefinitionId;
        req.state = this.workflowState.state;
        req.taskDefinitionId = this.workflowTaskDefinition.id;

        this.workflowdefService.removeTaskFromState(req).subscribe(response => {

          this.notify.message = 'Removed';
          this.notify.open();
          this.dialogRef.close();
        });
      }
    });

  }

  scheduleUpdated(scheduleDefinition: WorkflowScheduleDefinition) {
    if (!this.workflowTaskDefinition.autoTaskDefinition) {
      this.workflowTaskDefinition.autoTaskDefinition = <AutoTaskDefinition>{};

    }
    this.workflowTaskDefinition.autoTaskDefinition.workflowScheduleDefinition = scheduleDefinition;
    console.log('schedule has been added (and it will be saved when we close this dialog');
  }

  private initialise() {
    if (!this.workflowTaskDefinition) {
      this.workflowTaskDefinition = <WorkflowTaskDefinition>{};
    }
    if (!this.workflowTaskDefinition.taskSettings) {
      this.workflowTaskDefinition.taskSettings = <TaskSettings>{};
    }


    this.workflowTaskDefinition.taskType = WorkflowTaskDefinition.TaskTypeEnum.SendMessage;

    if (!this.workflowTaskDefinition.actionDefinition) {
      this.workflowTaskDefinition.actionDefinition = <ActionDefinition>{};
      this.workflowTaskDefinition.actionDefinition.actionType = ActionDefinition.ActionTypeEnum.SendMessage;
      this.workflowTaskDefinition.actionDefinition.sendMessageDefinition = <SendMessageDefinition>{};
    }

    if (!this.scheduleDefinition) {
      this.scheduleDefinition = <WorkflowScheduleDefinition>{};
      this.scheduleDefinition.workflowScheduledTime = <WorkflowScheduledTime>{};
      this.scheduleDefinition.workflowScheduledTime.timePeriodValue = <TimePeriodValue>{};
      this.scheduleDefinition.workflowScheduledTime.timePeriodValue.period = 1;
      this.scheduleDefinition.workflowScheduledTime.timePeriodValue.timePeriodType = TimePeriodValue.TimePeriodTypeEnum.Days;
      this.scheduleDefinition.workflowScheduledTime.whenToSend = WorkflowScheduledTime.WhenToSendEnum.Before;
    }

    if (!this.workflowTaskDefinition.autoTaskDefinition) {
      this.workflowTaskDefinition.autoTaskDefinition = <AutoTaskDefinition>{};
      this.workflowTaskDefinition.autoTaskDefinition.workflowScheduleDefinition = this.scheduleDefinition;
    }
    this.initialised = true;
  }
}
