import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { throwError } from 'rxjs';
import { UiAuthService } from '../auth/ui-auth.service';
import { SentryErrorHandler } from './sentryErrorHandler';

@Injectable()
export class GlobalErrorHandler extends SentryErrorHandler implements ErrorHandler {

  constructor(
    private injector: Injector,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private router: Router,
  ) {
    super();

    window.addEventListener('online', (e) => {
      console.log('And we\'re back :).');
      this.online();
    }, false);

    window.addEventListener('offline', (e) => {
      console.log('Connection is down.');
      this.offline();
    }, false);
  }

  online() {
    console.log('Online');
    if (window['wentOffline']) {
      window['wentOffline'] = false;
      location.reload();
    }
  }

  offline() {
    window['wentOffline'] = true;
    this.router.navigate(['/no-internet']);
  }

  handleError(error) {
    super.handleError(error);

    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      // new version released or poor connection
      window.location.reload();
    }

    console.log('inside GlobalErrorHandler error type is ' + (typeof error));
    if (error.status) {
      console.log('status is ', error.status.toString());
    }
    if (error.message) {
      console.log('message is ', error.message);
    }
    if (error.error) {
      console.log('error is ', error.error);
    }
    if (error.url) {
      console.log('error url is ', error.url);
    }
    console.log(error);

    const router = this.injector.get(Router);
    if (error instanceof HttpErrorResponse) {
      console.log('error code is ', error.status);
      if (error.status === 401) {
        console.log('401 detected, logging out');
        this.redirectToLogin();
      } else if (error.status === 409) {
        console.log('got conflict, let this through and let caller handle');
        const err = new Error(error?.message);
        return throwError(() => err);
      } else if (error.status === 400) {
        console.log('400 detected');
      } else if (error.status === 504) {
        console.log('gateway timeout');
        router.navigate(['/session/error']);
      } else if (error.status === 404) {
        this.snackBar.open(error.message, '404', {
          panelClass: 'alert-danger',
          duration: 3000,
          horizontalPosition: 'right',
          verticalPosition: 'bottom',
        });
        const err = new Error(error?.message);
        return throwError(() => err);
      } else if (error.status === 500) {
        if (error.error && error.error.error) {
          this.snackBar.open(error.error.error, '', {
            panelClass: 'alert-danger',
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
          });
          const err = new Error(error?.message);
          return throwError(() => err);
        } else {
          this.snackBar.open(error.message, '', {
            panelClass: 'alert-danger',
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
          });
          const err = new Error(error?.message);
          return throwError(() => err);

        }
      } else if (error.status === 0) {
        // Chrome (and possibly other browsers) return a status of 0 when incorrect CORS headers are used. T
        console.log('CORS issue redirecting to /session/server as error is 0');
        router.navigate(['/session/error']);
      } else {
        console.log('no status matching');
        console.log(error);
        // router.navigate(['/session/no-internet']);
      }
    } else {
      if (error.status) {
        console.log('status code on error is ' + error.status);
      }
      if (error.message) {
        console.log('err mess is ' + error.message);
      }
      console.log('navigating to /session/error as error is not a HttpErrorResponse' + typeof (error));
      router.navigate(['/']);
    }
    const err = new Error(error?.message);
    return throwError(() => err);
  }


  redirectToLogin() {
    const auth = this.injector.get(UiAuthService);
    auth.logout();

    this.authService.logout({ logoutParams: { returnTo: document.location.origin } });
  }
}
