import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { SwUpdate } from '@angular/service-worker';
import { BootstrapService } from 'src/app/bootstrap.service';

@Component({
  selector: 'app-angular-version-update-banner',
  templateUrl: './angular-version-update-banner.component.html',
  styleUrls: ['./angular-version-update-banner.component.scss']
})
export class AngularVersionUpdateBannerComponent {

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<AngularVersionUpdateBannerComponent>,
    private swUpdate: SwUpdate,
    private bootstrapService: BootstrapService
  ) { }

  update(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    if (this.swUpdate && this.swUpdate.isEnabled) {
      this.swUpdate.activateUpdate().then(() => {
        // passing true ignores cache
        this.bootstrapService.forceReload();
      });
      event.preventDefault();
    } else {
      this.bootstrapService.forceReload();
    }

  }

  remindMeLater() {

  }

}
