import { Component, Input, ViewChild } from '@angular/core';
import { ViewVideoDto } from '@savvy/view-definition';
import { ContextIdDto } from '@savvy/quickbooks';
import { ConfService } from '../config/conf.service';

@Component({
    selector: 'app-view-video',
    templateUrl: 'viewVideo.component.html'
})
export class ViewVideoComponent {

    @Input() viewVideoDto: ViewVideoDto;
    @Input() viewContext: any;
    @Input() contextIdDto: ContextIdDto;

    @ViewChild('videoPlayer', { static: true }) videoplayer: any;

    constructor() {
    }

    toggleVideo() {
        console.log('playing video');
        this.videoplayer.nativeElement.play();
    }

    videoUrl() {
        if (this.viewVideoDto.elementInstanceDto.fieldInstanceDto.value.videoFieldValueDto != null) {
            console.log('ref is ' + this.viewVideoDto.elementInstanceDto.fieldInstanceDto.value.videoFieldValueDto.fileReferenceId);
            return ConfService.apiUrl() + '/rest/storage?contextId=' + this.contextIdDto.contextId +
                '&contextIdType=' + this.contextIdDto.contextIdType +
                '&id=' + this.viewVideoDto.elementInstanceDto.fieldInstanceDto.value.videoFieldValueDto.fileReferenceId;
        }
        return '';
    }
}

