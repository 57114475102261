import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ContextService } from 'src/app/flo/context.service';
import { SendToAllMessageStep } from '../send-to-all-message-step.interface';
import { ContextIdDto } from '@savvy/ui';
import { MarketingQuickLinksEditorComponent } from 'src/app/flo/editor/marketing-quick-links-editor/marketing-quick-links-editor.component';
import { UploadFilesComponent } from 'src/app/flo/shared/components/upload-files/upload-files.component';
import { Subscription } from 'rxjs';
import {Link} from "@savvy/messaging";

@Component({
  selector: 'app-send-to-all-message-email',
  templateUrl: './send-to-all-message-email.component.html',
  styleUrls: ['./send-to-all-message-email.component.scss']
})
export class SendToAllMessageEmailComponent implements OnInit, OnDestroy, SendToAllMessageStep {
  @ViewChild(UploadFilesComponent, { static: false }) uploadFiles: UploadFilesComponent;

  @ViewChild(MarketingQuickLinksEditorComponent, { static: false }) quickLinksEditorV2Component: MarketingQuickLinksEditorComponent;

  formGroup: UntypedFormGroup = new UntypedFormGroup({
    allowEmail: new UntypedFormControl(true),
    subject: new UntypedFormControl(''),
    body: new UntypedFormControl('')
  });

  contextIdDto: ContextIdDto;
  subscriptions: Subscription[] = [];
  emailTemplateId: string;

  body: string;
  links: Link[] = [];

  constructor(private contextService: ContextService) { }

  ngOnInit(): void {
    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
      }
    }));
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  get fg(): UntypedFormGroup {
    return this.formGroup;
  }

  getFormRef(): UntypedFormGroup {
    return this.formGroup;
  }

  onStepChange(): boolean {
    return true;
  }

  bodyUpdated(text: string) {
    this.body = text;
    this.formGroup.patchValue({
      body: text
    });
  }


  onEmailTemplateChange($event) {
    this.emailTemplateId = $event.id;

    this.body = $event.body;
    this.links = Object.assign([],$event.links);
    this.formGroup.patchValue({
      body: $event.body,
      subject: $event.subject
    });
  }

}
