import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ContextIdDto } from '@savvy/app';
import { SmsSettings, SmsSettingsCompService } from '@savvy/sms-settings';
import { forkJoin } from 'rxjs';
import { SmsComponent } from '../../layout/header/sms.component';
import { SmsCreditStateService } from './sms-credit-state.service';
import { SnackBarService } from './snackbar.service';
import { SmsBundleDto } from '@savvy/sms-bundle';

@Injectable()
export class SmsCreditService {
  public smsSettings: SmsSettings = {};
  public smsBundles: SmsBundleDto[] = [];

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
    private smsCreditStateService: SmsCreditStateService,
    private smsSettingsCompService: SmsSettingsCompService
  ) {
  }

  checkSMSCredits(contextIdDto) {
    console.log('checking sms credit');
    if (contextIdDto) {
      const contextId = contextIdDto.contextId;
      const contextIdType = contextIdDto.contextIdType;
      if (!contextId || !contextIdType) {
        console.error('checkCreditLow: contextIdDto or contextIdType invalid');
        return;
      }

      if (!this.smsCreditStateService.smsCreditSummaryDto) {
        console.log('checkCreditLow: no state to check');
        return;
      }

      if (this.smsCreditStateService.smsCreditSummaryDto) {

        if (this.smsCreditStateService.smsCreditSummaryDto.remainingSmsCreditsIncludingPaid === 0 &&
          this.smsCreditStateService.smsCreditSummaryDto.numFreeCreditsOnPlanPerMonth === 0 &&
          this.smsCreditStateService.smsCreditSummaryDto.purchasedCredits === 0 &&
          this.smsCreditStateService.smsCreditSummaryDto.smsCreditsUsedThisMonth === 0 &&
          this.smsCreditStateService.smsCreditSummaryDto.smsUsedThisMonth === 0) {
          console.error('checkCreditLow: state is all set to zero');
          return;
        }
      }

      console.log(this.smsCreditStateService.smsCreditSummaryDto);


      if (this.smsCreditStateService.isSmsCreditZero()) {
        console.log('credit is zero');
        forkJoin([
          this.translateService.get(`You are running out of your SMS credits. Your customers won't recieve any
          SMS reminders now.`)
        ]).subscribe(([expireTxt]) => {
          setTimeout(() => {
            this.showNotification(contextId, `${expireTxt}`);
          }, 1000);
        });
      } else {
        console.log('credit is NOT zero');

        if (this.smsCreditStateService.isSmsCreditLow() && !this.smsSettings.autoRenew) {

          console.log('credit is low');

          forkJoin([
            this.translateService.get('Your SMS credits are low')
          ]).subscribe(([expireTxt]) => {
            setTimeout(() => {
              this.showNotification(contextId, `${expireTxt}`);
            }, 1000);
          });
        } else {
          console.log('credit is NOT low');

        }
      }
    }
  }

  loadSMSReport(contextIdDto: ContextIdDto) {
    if (contextIdDto && contextIdDto.contextId) {
      this.smsSettingsCompService.getSmsReport(contextIdDto.contextId,
        contextIdDto.contextIdType).subscribe(response => {
          this.smsCreditStateService.smsCreditSummaryDto = response.smsCreditSummaryDto;
          this.smsSettings = response.smsSettings;
          this.smsBundles = response.smsBundleDtos;
        });
    }
  }

  buyNewBundle(contextIdDto: ContextIdDto): void {
    if (!contextIdDto) {
      console.warn('No context set');
      return;
    }
    const dialogRef = this.dialog.open(SmsComponent, {
      data: {
        contextIdDto
      },
      autoFocus: false,
      panelClass: ['scrollable-modal', 'helpwindow'],
    });

    dialogRef.afterClosed()
      .subscribe(() => {
        this.loadSMSReport(contextIdDto);
      });
  }

  private showNotification(contextIdDto, text: string, duration = 5000) {
    console.log('state is ', this.smsCreditStateService.smsCreditSummaryDto);
    console.log('in show notification' + text);
    console.log('snackBarService', this.snackBarService);
    console.log('snackBar', this.snackBar);

    const config = new MatSnackBarConfig();
    config.verticalPosition = 'top';
    config.horizontalPosition = 'center';
    config.politeness = 'polite';
    config.duration = duration; // 5 seconds
    const snack = this.snackBar.open(text, 'Buy New Bundle', config);
    snack.onAction().subscribe(res => {
      this.buyNewBundle(contextIdDto);
    });
  }
}
