<mat-card appearance="outlined">
    <mat-card-content>
        <app-flo-editor [text]="replyText" (textUpdated)="bodyUpdated($event)"></app-flo-editor>
    </mat-card-content>

    <mat-card-actions>
        <button mat-raised-button color="accent" (click)="sendReply()">{{'SEND' | translate}}</button>
        <button mat-button (click)="cancel($event)">{{'CANCEL' | translate}}</button>
    </mat-card-actions>
</mat-card>



