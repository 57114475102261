<h5 mat-dialog-title>Booking Details</h5>

<mat-dialog-content>
  <!-- <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayout.gt-md="row wrap" fxLayoutGap="2%">
    <div fxFlex="100" fxFlex.gt-sm="48" fxFlex.gt-sm="23" class="mdc-mb-3">
      <p class="mat-text-secondary mdc-mb-0">Appointment Date</p>
      <h6 class="mdc-mb-0">25/04/2024 at 12:00 pm</h6>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="48" fxFlex.gt-sm="23" class="mdc-mb-3">
      <p class="mat-text-secondary mdc-mb-0">Service</p>
      <h6 class="mdc-mb-0">Spa Bath</h6>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="48" fxFlex.gt-sm="23" class="mdc-mb-3">
      <p class="mat-text-secondary mdc-mb-0">Staff</p>
      <h6 class="mdc-mb-0">Farhan Sharief</h6>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="48" fxFlex.gt-sm="23" class="mdc-mb-3">
      <p class="mat-text-secondary mdc-mb-0">Time of Request</p>
      <h6 class="mdc-mb-0">20/04/2024 at 11:00 pm</h6>
    </div>
    <div fxFlex="100" class="mdc-mb-3">
      <p class="mat-text-secondary mdc-mb-0">Customer</p>
      <h6 class="mdc-mb-0">
        <span class="mdc-me-2 h6 d-inline-block">Amit Mahida</span>
        <span class="mdc-px-2"> - </span>
        <span class="d-inline-block">Suzzy
          <span class="mat-text-secondary small d-inline-block fw-normal">(austrailianLabradoodle)</span>
        </span>
      </h6>
      <a href="mailto: amit@itsallsavvy.com" class="d-inline-block">
        <span class="chiptag theme-accent vm mt-5px"><mat-icon>email</mat-icon> amit-at-itsallsavvy.com</span>
      </a>
      <a href="tel: 07778904392" class="d-inline-block">
        <span class="chiptag theme vm mt-5px"><mat-icon>call</mat-icon> 07778904392</span>
      </a>
    </div>
  </div>
  <br> -->


  <div *ngIf="!eventBus">
    VERTICAL PANEL EVENT BUS IS EMPTY
  </div>

  <div *ngIf="verticalPanelDefinitionDto">
    <!-- mt-0 new-card -->
    <app-element *ngFor="let elementDto of verticalPanelDefinitionDto.elementDtos" [elementDto]="elementDto"
      [form]="form" [contextIdDto]="contextIdDto" [viewContext]="viewContext" [eventBus]="eventBus"
      [additionalDataMapDto]="additionalDataMapDto" [showPlaceholder]="showPlaceholder"
      [changeListener]="changeListener">
    </app-element>
  </div>

</mat-dialog-content>

<mat-dialog-actions>
  <div fxLayout="row wrap" fxLayoutGap="2%" class="w-100">
    <!-- <div fxFlex="nogrow">
      <button matTooltip="{{'CONFIRM_BOOKING' | translate}}" class="mat-text-green" mat-button>
        <mat-icon>event_available</mat-icon>
        {{'CONFIRM' | translate}}
      </button>
    </div>
    <div fxFlex="nogrow">
      <button matTooltip="{{'REJECT_BOOKING' | translate}}" class="ml-1 mat-text-red" mat-button>
        <mat-icon>event_busy</mat-icon>
        {{'REJECT' | translate}}
      </button>
    </div> -->
    <div fxFlex></div>
    <div fxFlex="nogrow">
      <button mat-button mat-dialog-close>Close</button>
    </div>
  </div>
</mat-dialog-actions>