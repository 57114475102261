<div fxLayout="row wrap" class="mdc-mb-1">
  <div fxFlex="100">
    <app-flo-editor #messageEditor [hideToolbar]="messageType === 'SMS'" [format]="messageType === 'SMS' ? 'text' : 'html'" (editorCreated)="onEditorCreated($event)" (textUpdated)="ontextUpdated($event)" [text]="editorContent">
    </app-flo-editor>
  </div>
  <div fxFlex="100" class="warning-message" *ngIf="messageType === 'SMS' && editorContent">
    <!--
  WE CANNOT SHOW THIS UNLESS WE KNOW THE EXPANDED MESSAGE SIZE
    <p *ngIf="160 - editorContent.length >= 0"> <strong>{{160 - editorContent.length}}</strong> Chars Left</p>
    <p *ngIf="160 - editorContent.length < 0"> <strong>{{chargedSMS(editorContent.length / 160)}}</strong> SMS credits will be deducted </p>
    -->

    <p class="mat-text-secondary">{{ceil(numCredits)}} SMS<span *ngIf="numCredits > 1">s</span> will be charged</p>
    <p class="mat-text-secondary">{{'CHAR_LIMITS' | translate}}</p>
  </div>
  <div fxFlex="100" class="mt-1">
    <h6>{{'INCLUDE_IN_MESSAGE' | translate}}</h6>
    <app-quick-links-panel *ngIf="templateValuesList" [quickLinks]="quickLinks" [onlyShowVariables]="onlyShowVariables" [templateValuesList]="templateValuesList" [currencyCode]="currencyCode" (linkSelected)="onQuickLinksAction($event)" (variableAdded)="variableAdded($event)">
    </app-quick-links-panel>
  </div>

</div>