import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContextIdDto, NotificationService, Notification, UpdateNotification, NotificationMethodMatrixService, LinkId } from '@savvy/notification';
import { Subscription } from 'rxjs';
import { ContextService } from '../../context.service';
import { UrlLookupService } from '../../shared/services/urlLookup.service';
import {ViewCustomerV2Component} from "../../customer/view-customer-v2/view-customer-v2.component";

@Component({
  selector: 'app-notification-v2',
  templateUrl: './notification-v2.component.html',
  styleUrls: ['./notification-v2.component.scss']
})
export class NotificationV2Component implements OnInit {

  @Output() notificationUpdated: EventEmitter<any> = new EventEmitter();
  contextIdDto: ContextIdDto;
  subscriptions: Subscription[] = [];
  notifications: Notification[] = [];
  showNotifications = false;
  isHover = false;
  inside = false;
  notificationTypeEnum = Notification.NotificationTypeEnum;
  timer;
  isProcessed = false;

  constructor(
    public dialog: MatDialog,
    private api: NotificationService,
    private contextService: ContextService,
    private urlLookupService: UrlLookupService
  ) { }

  @HostListener('click')
  clicked() {
    this.inside = true;
  }
  @HostListener('document:click')
  clickedOut() {
    if (!this.inside) {
      this.hideNotifications();
    }
    this.inside = false;
  }

  ngOnInit(): void {
    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
        if (!this.isProcessed) {
          console.log('kicking off loader');
          this.getUnreadBannerNotifications();
        } else {
          console.log('loader already running');
        }
        this.isProcessed = true;
      }
    }));
  }


  viewCustomer(customerId: string) {
    //if (this.no)
    const contextIdDto = this.contextIdDto;
      const viewCustomerModal = this.dialog.open(ViewCustomerV2Component, {
          data: {
              contextIdDto,
              customerId
          },
          maxWidth: '1024px',
          // maxHeight: '80vh',
          // height: '80vh',
          panelClass: [
              'modal-100',
              'helpwindow',
              'petProfileModal'
          ],
          autoFocus: false
      });

      viewCustomerModal.afterClosed()
          .subscribe(
              () => {
                  // if (this.customerStatsComponent) {
                  //   this.customerStatsComponent.loadStatus();
                  // }
              });
  }



  markAllAsRead() {
    this.api.markAllAsRead(this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(response => {
      this.notifications = [];
      this.showNotifications = false;
    });
  }

  markAsRead(notification: Notification) {
    const req = {} as UpdateNotification;
    req.notification = notification;
    req.notification.read = true;
    req.contextIdDto = this.contextIdDto;

    this.api.update(req).subscribe(response => {
      this.getUnreadBannerNotifications();
    });
  }

  getUnreadBannerNotifications() {
    this.api.loadUnreadAndBannerNotifications(1, 50, this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(response => {
      this.notifications = response.bannerNotifications.contents;
      clearInterval(this.timer);
      this.startToLoadNotifications();
      if (this.notifications.length > 0) {
        this.showPanel();
      } else {
        this.hidePanel();
      }
      this.notificationUpdated.emit(response.numUnreadOverall);
    }, error => {
      this.notifications = [];
      this.notificationUpdated.emit(0);
    });
  }

  getLinkId(type: string, linkIds: Array<LinkId>): string {
    let linkIdToRet;

    linkIds.forEach(linkId => {
      if (linkId.linkedIdType === type) {
        linkIdToRet = linkId.linkedId;
      }
    });
    return linkIdToRet;
  }

  showPanel() {
    this.isHover = true;
    this.showNotifications = true;
  }

  /** What does this do? */
  hidePanel() {
    this.isHover = false;
    this.showNotifications = false;
  }

  hideNotifications() {
    this.isHover = false;
    this.showNotifications = false;
  }


  // Every 2 mins load
  startToLoadNotifications(count = 120000) {
    this.timer = setInterval(() => {
      this.getUnreadBannerNotifications();
    }, count);
  }

}
