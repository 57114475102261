<!-- <div class="mdc-mb-1" *ngIf="multiple && petsTobeAdded.length">
  <div class="petchips ml-1" *ngFor="let pet of petsTobeAdded; let i = index">
    <div fxLayout="row" fxLayoutGap="10px" fxflexalign="center">
      <div fxflex="nogrow">
        <div class="avatar avatar-40 circle border-3">
          <mat-icon>pets</mat-icon>
        </div>
      </div>
      <div fxflex fxflexalign="center">
        <h5 class="mb-0">{{pet.name}}</h5>
        <p class="small mb-0"><a href="javascript:void(0)">{{pet.petType}}</a></p>
      </div>
      <div fxflex="nogrow">
        <button mat-icon-button color="accent" (click)="removePet(i)">
          <mat-icon>cancel</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div> -->
<div *ngIf="multiple">
  <div fxLayout="row wrap" fxLayoutGap="16px">
    <div *ngIf="showTitle">
      <h6 class="font-weight-medium"><strong>{{'PET_INFORMATION' | translate: {Default: 'Pet Information'} }}</strong>
      </h6>
    </div>
    <span class="example-spacer"></span>
    <ng-container *ngIf="maxPets">
      <button *ngIf="maxPets > 1 && petFormGroupArray.length < maxPets" mat-button color="accent" type="button" (click)="addPet()" [disabled]="!valid">
        <mat-icon>add</mat-icon> {{'ADD_ANOTHER_PET' | translate: {Default: 'Add Another Pet'} }}
      </button>
    </ng-container>
    <ng-container *ngIf="!maxPets">
      <button mat-button color="accent" type="button" (click)="addPet()" [disabled]="!valid">
        <mat-icon>add</mat-icon> {{'ADD_ANOTHER_PET' | translate: {Default: 'Add Another Pet'} }}
      </button>
    </ng-container>
  </div>
  <div fxLayout="column" fxLayoutGap="16px" class="new-pet rounded-10 border">
    <ng-container *ngFor="let petFormGroup of petFormGroupArray; let last = last, let index = index">
      <form [formGroup]="petFormGroup" *ngIf="multiple" class="m-0">
        <!-- <div fxFlex="100" fxFlex.gt-sm="24" fxFlex.gt-md="15" fxFlexAlign="center">
            <div class="my-2">
              <h6 *ngIf="index === 0">{{'ADD_NEW_PET' | translate: {Default: 'Add Another Pet'} }}</h6>
            </div>
          </div> -->
        <!-- <div fxFlex="15" fxFlexAlign="center" *ngIf="petFormGroupArray.length > 1">
            <p class="mat-text-secondary small mb-0"></p>
            <h6>Add Pet-{{index + 1}}</h6>
          </div> -->
        <div fxLayout="row wrap" fxLayoutGap="16px" class="pr-1">
          <div fxFlex fxLayout="row wrap" fxLayoutGap="16px" fxLayoutGap.gt-xs="16px">
            <div fxFlex="100" fxFlex.gt-xs="32" class="field-center">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{'PET_NAME' | translate: {Default: 'Pet Name'} }}</mat-label>
                <input matInput placeholder="{{'ENTER_PET_NAME' | translate: {Default: 'Enter Pet Name'} }}" formControlName="petName" required>
              </mat-form-field>
            </div>

            <div fxFlex="100" fxFlex.gt-xs="32" class="field-center">
              <app-pet-type-selector [contextIdDto]="contextIdDto" [selectedPetType]="petFormGroup.controls?.petType">
              </app-pet-type-selector>
            </div>

            <div fxFlex="100" fxFlex.gt-xs="32" class="field-center">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{'BREED' | translate: {Default: 'Breed'} }}</mat-label>
                <mat-select placeholder="{{'SELECT_BREED' | translate: {Default: 'Select Breed'} }}" formControlName="petBreed" name="petBreed">
                  <mat-option>
                    <ngx-mat-select-search [placeholderLabel]="'SEARCH_BREED' | translate: {Default: 'Search Breed'} " [formControl]="petBreedFilter">
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option [value]="'-1'">
                    <button mat-flat-button color="accent" type="button" (click)="addNewBreed()">
                      {{'ADD_NEW' | translate: {Default: 'Add New'} }}
                    </button>
                  </mat-option>
                  <mat-option *ngFor="let breed of filteredBreeds | async" [value]="breed.name">
                    {{ breed.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div fxFlex="nogrow" fxFlexAlign="center" fxLayout="column">
            <button *ngIf="petFormGroupArray.length > 1" mat-icon-button (click)="removePet(index)" class="mdc-mb-2">
              <mat-icon>cancel</mat-icon>
            </button>
            <!-- <button *ngIf="petFormGroup.length > 1" mat-icon-button (click)="savePet(index)">
                <mat-icon>save</mat-icon>
              </button> -->
          </div>
        </div>
      </form>
      <div *ngIf="last" fxFlex="100" fxLayout="row" fxLayoutAlign="center center">
        <!-- no any margin class-->

      </div>
    </ng-container>
  </div>
</div>

<form [formGroup]="petFormGroup" *ngIf="!multiple">
  <div fxLayout="row wrap" fxLayoutGap="5px">
    <div fxFlex="100" fxFlex.gt-xs="100" fxFlex.gt-sm="30">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'PET_NAME' | translate: {Default: 'Pet Name'} }}</mat-label>
        <input matInput placeholder="{{'ENTER_PET_NAME' | translate: {Default: 'Enter Pet Name'} }}" formControlName="petName" required>
      </mat-form-field>
    </div>

    <div fxFlex="100" fxFlex.gt-xs="49" fxFlex.gt-sm="30">
      <app-pet-type-selector [contextIdDto]="contextIdDto" [selectedPetType]="petFormGroup.controls?.petType">
      </app-pet-type-selector>
    </div>

    <div fxFlex="100" fxFlex.gt-xs="49" fxFlex.gt-sm="30">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'BREED' | translate: {Default:'Breed'} }}</mat-label>
        <mat-select placeholder="{{'SELECT_BREED' | translate: {Default: 'Select Breed'} }}" formControlName="petBreed" name="petBreed">
          <mat-option>
            <ngx-mat-select-search [placeholderLabel]="'Search Breed'" [formControl]="petBreedFilter">
            </ngx-mat-select-search>
          </mat-option>
          <mat-option [value]="'-1'">
            <button mat-flat-button color="accent" type="button" (click)="addNewBreed()">
              {{'ADD_NEW' | translate: {Default: 'Add New'} }}
            </button>
          </mat-option>
          <mat-option *ngFor="let breed of filteredBreeds | async" [value]="breed.name">
            {{ breed.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- <div fxFlex="15">
      <button mat-raised-button color="accent" type="button" (click)="createPet()" [disabled]="!petFormGroup.valid">
        <mat-icon>add</mat-icon>
      </button>
    </div> -->
  </div>
  <!-- <button *ngIf="pets && pets.length" mat-raised-button color="accent" class="ml-1"
    (click)="addNewPet = false">Cancel</button> -->
</form>