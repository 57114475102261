<mat-tab-group  #matTabGroup [selectedIndex]="tabIndex" class="rounded-10 border" *ngIf="!onlyShowVariables">
  <ng-container *ngFor="let quickLink of quickLinks">
    <mat-tab *ngIf="quickLink.type === linkTypeRef.Href">
      <ng-template mat-tab-label>
        <mat-icon class="vm mr-1">add_link</mat-icon>
        {{quickLink.label}}
      </ng-template>
      <div class="link-list mdc-p-2">
        <app-quick-link-view [selectedItem]="getSelectedItem" (linkAdded)="onLinkAction($event)"></app-quick-link-view>
      </div>
    </mat-tab>
    <mat-tab *ngIf="quickLink.type === linkTypeRef.EntityInstance">
      <ng-template mat-tab-label>
        <mat-icon class="vm mr-1">tag</mat-icon>
        {{quickLink.label}}
      </ng-template>
      <div class="link-list">
        <app-quick-default-variables [selectedItem]="getSelectedItem" [templateValuesList]="templateValuesList"
          (variableSelected)="onVariableAction($event)">
        </app-quick-default-variables>
      </div>
    </mat-tab>
    <mat-tab *ngIf="quickLink.type === linkTypeRef.RequestDeposit">
      <ng-template mat-tab-label>
        <mat-icon class="vm mr-1">account_balance_wallet</mat-icon>
        {{quickLink.label}}
      </ng-template>
      <div class="link-list">
        <app-quick-deposit-view [selectedItem]="getSelectedItem" (onAction)="onDepositRequestAction($event)">
        </app-quick-deposit-view>
      </div>
    </mat-tab>
    <mat-tab *ngIf="quickLink.type === linkTypeRef.SendCheckinForm">
      <ng-template mat-tab-label>
        <mat-icon class="vm mr-1">format_list_bulleted</mat-icon>
        {{quickLink.label}}
      </ng-template>
      <div class="link-list">
        <app-quick-checkin-form-view [selectedItem]="getSelectedItem" (onAction)="onCheckInFormAction($event)">
        </app-quick-checkin-form-view>
      </div>
    </mat-tab>
    <!-- <mat-tab *ngIf="quickLink.type === linkTypeRef.Questionnaire">
      <ng-template mat-tab-label>
        <mat-icon class="vm mr-1">help</mat-icon>
        {{quickLink.label}}
      </ng-template>
      <div class="link-list">
        <app-quick-questionnaires-view [selectedItem]="getSelectedItem" (onAction)="onQuestionnaireAction($event)">
        </app-quick-questionnaires-view>
      </div>
    </mat-tab> -->
  </ng-container>
</mat-tab-group>

<ng-container *ngIf="onlyShowVariables">
  <ng-container *ngFor="let quickLink of quickLinks">
    <ng-container *ngIf="quickLink.type === linkTypeRef.EntityInstance">
      <div class="link-list">
        <mat-chip-listbox aria-label="Variable selection">
          <mat-chip-option class="chip" [title]="'Click to add ' + variable.label" color="accent" *ngFor="let variable of variablesList"
            (click)="variableSelectionChange(variable)">
            <div class="mat-chip-text">{{variable.label | translate}}</div>
          </mat-chip-option>
        </mat-chip-listbox>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
