<!-- No token set up -->
<app-messages-disabled [contextIdDto]="contextIdDto" *ngIf="showMessagesDisabled"></app-messages-disabled>

<mat-sidenav-container *ngIf="showMessages" class="app-inner mail-container background-none shadow-none"
  [ngClass]="{ 'side-panel-opened': sidePanelOpened, 'side-panel-closed': !sidePanelOpened }">

  <mat-sidenav #mailnav [mode]="isOver() ? 'over' : 'side'" [opened]="!isOver()" (open)="sidePanelOpened = true"
    (close)="sidePanelOpened = false" class="mail-sidebar pl-xs pr-xs" [disabled]="isOver()">
    <ng-scrollbar autoHeightDisabled="false">
      <mat-toolbar color="primary" fxHide="false" fxHide.gt-sm>
        <button (click)="mailnav.toggle()" mat-icon-button>
          <mat-icon>short_text</mat-icon>
        </button>
        <span class="mr-1 ml-1">{{'MAILBOX' | translate}}</span>
      </mat-toolbar>
      <mat-nav-list class="messages-nav">
        <a href="javascript:void(0)" mat-list-item *ngFor="let label of labels; let i = index"
          (click)="getMessages(label.labelId)">
          <mat-icon class="mat-text-muted">{{getIcon(label.labelName)}}</mat-icon>
          <span>{{label.labelName}}</span>
        </a>
      </mat-nav-list>
    </ng-scrollbar>
  </mat-sidenav>
  <mat-toolbar color="primary" fxHide="false" fxHide.gt-sm>
    <button (click)="mailnav.toggle()" mat-icon-button>
      <mat-icon>short_text</mat-icon>
    </button>
    <span class="mr-1 ml-1">{{'Mailbox' | translate}}</span>
  </mat-toolbar>

  <!-- No messages -->
  <app-zero-messages *ngIf="showZeroMessages" (newMessageClicked)="createMessage()"></app-zero-messages>


  <div #mailcontent class="main-content" fxLayout="row" fxLayoutAlign="center start" [disabled]="isOver()">
    <ng-scrollbar autoHeightDisabled="false">
      <div fxFlex.gt-sm="90" fxFlex.gt-xs="95" fxFlex="100">

        <!--<mat-sidenav #newMessagePanel align="end" class="message-panel" mode="over" opened="false">-->
        <!--<app-new-message-panel [contextIdDto]="contextIdDto" [visible]="true">-->
        <!--</app-new-message-panel>-->
        <!--</mat-sidenav>-->

        <div class="messages-list" *ngIf="!noMessages()">
          <mat-accordion [displayMode]="displayMode" [multi]="multi">
            <mat-expansion-panel #panel [hideToggle]="hideToggle"
              *ngFor="let message of response.messagesList; let i = index"
              [class.selected]="message === selectedMessage">


              <mat-expansion-panel-header (click)="onSelect(message)">
                <div fxLayout="row" fxLayoutAlign="none center" class="w-100">
                  <img alt="" class="message-icon mr-1 hide-on-open" src="{{message.avatar}}" *ngIf="!message.icon" />
                  <mat-icon [ngClass]="message.iconClass" class="mr-1 hide-on-open" *ngIf="message.icon">
                    {{message.icon}}
                  </mat-icon>
                  <div class="pr-1 hide-on-open" fxShow="false" fxShow.gt-xs style="min-width: 120px;">
                    <span>{{message.emailMessage.fromEmail}}</span>
                  </div>
                  <div class="text-ellipsis">
                    <span>{{message.emailMessage.subject}}</span>
                  </div>
                  <span fxFlex></span>
                  <div class="pl-1 text-xs-right text-ellipsis hide-on-open" fxShow="false" fxShow.gt-sm>
                    <small class="text-xs-muted">{{ message.sentLocalDateTime | localizedDate: 'fullDate' }}</small>
                  </div>
                </div>
              </mat-expansion-panel-header>

              <mat-card appearance="outlined" *ngIf="selectedMessage" class="shadow-none">
                <mat-card-header>
                  <img matCardAvatar *ngIf="!selectedMessage.icon" src="{{selectedMessage.avatar}}" class="ml-0">
                  <mat-icon *ngIf="selectedMessage.icon" matCardAvatar [ngClass]="selectedMessage.iconClass"
                    class="ml-0">{{selectedMessage.icon}}
                  </mat-icon>
                  <mat-card-title>{{selectedMessage.emailMessage.fromEmail}}</mat-card-title>
                  <mat-card-subtitle>{{selectedMessage.sentLocalDateTime | localizedDate:
                    'fullDate'}}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content class="pl-0">


                  <div [innerHTML]="selectedMessage.emailMessage.body"></div>

                  <app-reply *ngIf="selectedMessage && selectedMessage.id === message.id" [messageId]="message.id"
                    [contextIdDto]="contextIdDto" (cancelClicked)="panel.expanded = false; selectedMessage = null"
                    [selectedMessage]="selectedMessage"></app-reply>


                </mat-card-content>
              </mat-card>
              <mat-action-row *ngIf="selectedMessage">
                <button mat-raised-button mat-button-sm color="accent" class="mx-xs"
                  (onclick)="deleteMessage(selectedMessage)">{{'DELETE' | translate}}
                </button>
                <button mat-raised-button mat-button-sm class="mx-xs">{{'FORWARD' | translate}}</button>
              </mat-action-row>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </ng-scrollbar>
  </div>
</mat-sidenav-container>

<button mat-fab color="accent" class="mat-fab-bottom-right" [disabled]="trialExpiredService.isTrialExpired()"
  (click)="create()" style="z-index: 9;" *ngIf="!noMessages()">
  <mat-icon class="mat-24">create</mat-icon>
</button>