import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { PaymentSettingsService } from '@savvy/payment';
import { Product } from '@savvy/products';
import { NavChangeService } from '../element/NavChangeService';
import { ProductService } from '@savvy/products';
import { EventBusService } from '../element/EventBusService';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';
import { takeUntil } from 'rxjs/operators';
import { PhoneNumberHelperService } from '../shared/services/phone-number-helper.service';
import { Subject } from 'rxjs';
import { PaymentService } from '@savvy/payment';
import { ConsumerService, ServicesService } from '@savvy/services';
import { ConfirmationModalComponent } from '../shared/components/confirmation-modal/confirmation-modal.component';
import { Location } from '@angular/common';
import { InvoicecompService, InvoiceDto } from '@savvy/invoice';
import { CreateInvoice, DiscountItem, DiscountSettings, DiscountSettingsService, Invoice, InvoiceProductRow, InvoiceService, UpdateInvoice } from '@savvy/invoice';
import { InvoiceServiceRow } from '@savvy/view-definition';
import { ContextIdDto } from '@savvy/quickbooks';
import { CourseDto, CourseService } from '@savvy/course';
import { Course } from '@savvy/course/model/models';
import { InvoiceCourseRow } from '@savvy/invoice/model/invoiceCourseRow';
import { ViewDiscountsModalComponent } from '../discount/view-discounts/view-discounts-modal.component';
import { ServicesHelperService } from '../shared/services/services-helper.service';
import { UserCurrencyService } from '../shared/services/userCurrency.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-edit-invoice',
  templateUrl: './editInvoice.component.html',
  styleUrls: ['./editInvoice.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditInvoiceComponent implements OnInit, OnDestroy {
  @ViewChild('sidePanel', { static: false }) sidePanel: MatSidenav;

  invoice: Invoice = null;
  invoiceDto: InvoiceDto = null;
  products: Array<Product>;
  consumerServices: Array<ConsumerService>;
  courseDtos: Array<CourseDto>;
  paymentSettings: any;
  show: string;
  eventBus = new EventBusService();
  destroy$ = new Subject();
  // model: Payment;
  isPaidFull = false;
  currencyCode: string;

  discountSettings: DiscountSettings;
  discountItemsById: { [key: string]: DiscountItem } = {};

  private contextIdDto: ContextIdDto;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private navChange: NavChangeService,
    private api: InvoiceService,
    private invoiceCompService: InvoicecompService,
    private productService: ProductService,
    private invoicecompService: InvoicecompService,
    private paymentSettingsApi: PaymentSettingsService,
    private translateService: TranslateService,
    private adapter: DateAdapter<any>,
    private notify: FloSnackbarComponent,
    private dialog: MatDialog,
    private location: Location,
    private sharedService: PhoneNumberHelperService,
    private paymentApi: PaymentService,
    private courseService: CourseService,
    private servicesHelperService: ServicesHelperService,
    private discountSettingsService: DiscountSettingsService,
    private userCurrencyService: UserCurrencyService,
    private languageService: LanguageService) {
    this.adapter.setLocale(this.translateService.currentLang);
  }


  ngOnInit() {
    this.languageService.currentLocale$
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        if (res) {
          this.adapter.setLocale(res);
        }
      });
    this.route.params.subscribe(params => {
      this.contextIdDto = <ContextIdDto>{};
      this.contextIdDto.contextId = params['contextId'];
      this.contextIdDto.contextIdType = params['contextIdType'];

      this.getCurrencyCode();

      const invoiceId = params['invoiceId'];
      console.log('got invoiceId ' + invoiceId);

      if (invoiceId) {
        console.log('editing invoice ' + invoiceId);
        console.log('this.contextIdDto.contextId ' + this.contextIdDto.contextId);
        console.log('this.contextIdDto.contextIdType ' + this.contextIdDto.contextIdType);
        // Load invoice
        this.invoiceCompService.loadInvoiceForView(
          this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          invoiceId)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            response => {
              console.log('loaded invoice ' + response);
              this.invoiceDto = response.invoiceDto;
              // @ts-ignore
              this.invoice = response.invoiceDto.invoice;
              this.isPaidFull = this.invoice.paidInFull;
              // this.model = <Payment>{};
              //   this.model.dateTime = new Date();
              // this.model.amount = this.invoice.total;
            });

        this.loadServices();
        this.loadProducts();
        this.loadCourses();
        this.loadDiscounts();

        this.paymentSettingsApi.loadPaymentSettings(this.contextIdDto.contextId,
          this.contextIdDto.contextIdType
        ).pipe(takeUntil(this.destroy$))
          .subscribe(response => {
            console.log('loaded payment settings ' + response);

            this.paymentSettings = response;
          });
      } else {
        this.notify.message = 'Invoice Id does not exist';
        this.notify.open();
      }
    });
  }

  loadServices() {
    this.servicesHelperService.getConsumerServices(this.contextIdDto).then(consumerServices => {
      if (consumerServices.length) {
        this.consumerServices = consumerServices;
      }
    });
  }

  getCurrencyCode() {
    this.userCurrencyService.getDefaultCurrency(this.contextIdDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.currencyCode = res.org.currencyCode ? res.org.currencyCode : this.userCurrencyService.defaultCurrency;
      });
  }

  courseChanged(row: InvoiceCourseRow) {
    if (row.courseId) {
      console.log('Course id is ' + row.courseId);
      const courseDto = this.getCourse(row.courseId);
      if (courseDto) {
        row.unitPrice = courseDto.unitPrice;
        //  row.tax = courseDto..tax;
      }
    }
    this.updateInvoice();
  }

  productChanged(row) {
    if (row.productNumber) {
      console.log('Product number is ' + row.productNumber);
      const productDto = this.getProduct(row.productNumber);
      if (productDto) {
        row.unitPrice = productDto.unitPrice;
        row.tax = productDto.tax;
      }
    }
    this.updateInvoice();
  }

  getCourse(courseId: string): Course {
    let retCourseDto: Course;
    if (this.courseDtos) {
      this.courseDtos.forEach(courseDto => {
        console.log('courseId is ' + courseId);
        if (courseDto.course.id === courseId) {
          console.log('found match!');
          retCourseDto = courseDto.course;
          return courseDto;
        } else {
          console.log('no match');
        }
      });
    }
    return retCourseDto;
  }

  getProduct(productId: string): Product {
    let retProduct: Product;
    if (this.products) {
      this.products.forEach(productDto => {
        if (productDto.entityInstanceId && productDto.id === productId) {
          console.log('found match!');
          retProduct = productDto;
          return productDto;
        } else {
          console.log('no match');
        }
      });
    }
    return retProduct;
  }

  createProductInvoiceRow() {
    console.log('creating row');
    const invoiceRow: InvoiceProductRow = <InvoiceProductRow>{};
    if (!this.invoice.invoiceProductRows) {
      console.log('creating rows array');
      this.invoice.invoiceProductRows = new Array(0);
    }
    invoiceRow.quantity = 1;
    this.invoice.invoiceProductRows.push(invoiceRow);
    console.log('rows now ' + this.invoice.invoiceProductRows.length);
    this.updateInvoice();
  }

  deleteProductRow(invoiceRow: InvoiceProductRow) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        name: 'product'
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.invoice.invoiceProductRows.forEach((item, index) => {
          if (item === invoiceRow) {
            this.invoice.invoiceProductRows.splice(index, 1);
          }
        });
        this.updateInvoice();
      }
    });
  }

  sendInvoice() {
    this.show = 'sendInvoice';
    this.sidePanel.open();
  }

  viewPayments() {
    this.show = 'viewPayments';
    this.sidePanel.open();
  }

  paymentAdded() {
    this.show = '';
    this.sidePanel.close();
  }

  invoiceSent() {
    this.sidePanel.close();
    this.show = '';
  }

  invoiceSendCancelled() {
    this.sidePanel.close();
    this.show = '';
  }

  addPaymentCancelled() {
    this.sidePanel.close();
    this.show = '';
  }

  viewInvoice() {
    if (this.invoice) {
      this.router.navigate(['/invoice/viewInvoice',
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.invoice.id]);
    }
  }

  addInvoice() {
    const req: CreateInvoice = <CreateInvoice>{};
    req.contextIdDto = this.contextIdDto;
    req.invoice = this.invoice;

    this.api.createInvoice(req).subscribe(response => {
      this.invoice = response;
      this.translateService.get('Created Invoice')
        .pipe(takeUntil(this.destroy$))
        .subscribe(val => {
          this.notify.message = val;
          this.notify.open();
        });
    });
  }


  updateInvoice(value?: string) {
    const req: UpdateInvoice = <UpdateInvoice>{};
    req.contextIdDto = this.contextIdDto;
    req.invoice = this.invoice;

    this.api.updateInvoice(req)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.invoice = response;
        this.isPaidFull = this.invoice.paidInFull;
        this.translateService.get('Updated Invoice')
          .subscribe(val => {
            this.notify.message = val;
            this.notify.open();
          });
      });
  }

  serviceChanged(row: InvoiceServiceRow) {
    if (row.serviceNumber) {
      const serviceDto = this.getService(row.serviceNumber);
      if (serviceDto) {
        row.unitPrice = serviceDto.unitPrice;
        row.tax = serviceDto.tax;
        row.taxString = String(serviceDto.tax) + '%';
      }
    }
    this.updateInvoice();
  }

  getService(serviceId: string): ConsumerService {
    let retConsumerService: ConsumerService;
    if (this.consumerServices) {
      this.consumerServices.forEach((service: ConsumerService) => {
        console.log('serviceDto is ' + service.id);
        if (service.id && service.id === serviceId) {
          console.log('found match!');
          retConsumerService = service;
          return service;
        } else {
          console.log('no match');
        }
      });
    }
    return retConsumerService;
  }

  createServiceInvoiceRow() {
    const invoiceRow: InvoiceServiceRow = <InvoiceServiceRow>{};
    if (!this.invoice.invoiceServiceRows) {
      this.invoice.invoiceServiceRows = new Array(0);
    }
    invoiceRow.quantity = 1;
    console.log('adding service row 128');

    this.invoice.invoiceServiceRows.push(invoiceRow);
    this.updateInvoice();
  }

  deleteServiceRow(invoiceRow: InvoiceServiceRow) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        name: 'service'
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.invoice.invoiceServiceRows.forEach((item, index) => {
          if (item === invoiceRow) {
            this.invoice.invoiceServiceRows.splice(index, 1);
          }
        });
        this.updateInvoice();
      }
    });
  }

  deleteInvoice() {
    console.log('todo');
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        name: 'Invoice'
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.api.deleteInvoice(
          this.invoice.invoiceId.id,
          this.contextIdDto.contextId,
          this.contextIdDto.contextIdType)
          .pipe(takeUntil(this.destroy$))
          .subscribe(response => {

            console.log('deleted invoice');
            this.translateService.get('Successfully deleted Invoice')
              .pipe(takeUntil(this.destroy$))
              .subscribe(val => {
                this.notify.message = val;
                this.notify.open();
              });
            this.router.navigate(['/']);
          });
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  backToList() {
    this.location.back();
  }

  loadDiscounts() {
    this.discountSettingsService
      .get(this.contextIdDto.contextId, this.contextIdDto.contextIdType)
      .subscribe(res => {
        this.discountSettings = res;
        if (this.discountSettings && this.discountSettings.discountItems) {
          this.discountSettings.discountItems.forEach(r => {
            this.discountItemsById[r.id] = r;
          });
        }
      });
  }

  onDiscountClick(row) {
    const dialogRef = this.dialog.open(ViewDiscountsModalComponent, {
      data: {
        amount: row.unitPrice,
        discountId: row.discountId
      },
      width: '775px',
      autoFocus: false,
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result) {
          if (result.discountItem) {
            row.discountDetails = result.discountItem.discount;
            row.discountId = result.discountItem.id;
          } else {
            if (!row.discountId) {
              //overall discount
              this.invoice.discountDetails = null;
              this.invoice.discountId = null;
            }
            row.discountDetails = null;
            row.discountId = null;
          }
          this.updateInvoice();
        }
      });
  }

  applyDiscountOnInvoice() {
    const dialogRef = this.dialog.open(ViewDiscountsModalComponent, {
      data: {
        amount: this.invoice.totalWithoutDiscount,
        discountId: this.invoice.discountId
      },
      width: '775px',
      autoFocus: false,
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result) {
          if (result.discountItem) {
            this.invoice.discountDetails = result.discountItem.discount;
            this.invoice.discountId = result.discountItem.id;
          } else {
            this.invoice.discountDetails = null;
            this.invoice.discountId = null;
          }
          this.updateInvoice();
        }
      });
  }

  private loadCourses() {
    this.courseService.loadCourseDtos(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
    ).pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        console.log('loaded courses ' + response);
        this.courseDtos = response;
      });
  }

  private loadProducts() {
    this.productService.listProducts(
      this.contextIdDto.contextId
    )
      .subscribe(response => {
        console.log('loaded products ' + response);
        this.products = response;
      });
  }

}
