<div fxLayout="row" fxLayoutAlign=" center" class="w-100">
  <div fxFlex>
    <h6 class="mb-0 ">{{'BOARDINGS' | translate}}</h6>
  </div>
  <div fxFlex="auto"></div>
  <div fxFlex="nogrow">
    <mat-slide-toggle class="mr-1" [labelPosition]="'before'" color="accent" [checked]="showOnlyCancelled"
      [(ngModel)]="showOnlyCancelled" (ngModelChange)="cancelledFilterChanged($event)">
      {{'ONLY_SHOW_CANCELLED' | translate}}
    </mat-slide-toggle>

    <!--    <button [hidden]="isViewMode" mat-button color="accent" >-->
    <!--      <mat-icon class="vm">event</mat-icon> <span class="vm">Create Boardings</span>-->
    <!--    </button>-->
  </div>
</div>

<!-- {{historyList | json}} -->
<div fxLayout="row" fxLayoutAlign="center center" class="mdc-p-3 ">
  <mat-button-toggle-group [(ngModel)]="historyMode" (ngModelChange)="historyModeChanged($event)" name="historyMode"
    aria-label="History Mode" class="button-group-payments">
    <mat-button-toggle value="past">{{'PAST_BOARDINGS' | translate}} ({{pastBoardings.length}})</mat-button-toggle>
    <mat-button-toggle value="ongoing">{{'ONGOING_BOARDINGS' | translate}}
      ({{ongoingBoardings.length}})</mat-button-toggle>
    <mat-button-toggle value="future">{{'UPCOMING_BOARDINGS' | translate}}
      ({{futureBoardings.length}})</mat-button-toggle>
  </mat-button-toggle-group>
</div>

<div *ngIf="historyMode === 'past'">
  <ul class="historylist mb-0" *ngIf="pastBoardings.length">
    <li *ngFor="let pastBoarding of pastBoardings">
      <div fxLayout="row">
        <div fxFlex fxLayout="column" fxLayout.gt-sm="row">
          <div fxFlex="grow" fxFlex.gt-sm="30" (click)="openViewBoardingModal(pastBoarding)" class="px-1">
            <p class="my-1 view-invoice">{{pastBoarding.label}}</p>
          </div>
          <div fxFlex="grow" fxFlex.gt-sm="25" (click)="openViewBoardingModal(pastBoarding)" class="px-1">
            <p class="my-1 view-invoice mat-text-secondary">{{pastBoarding.petLabel}}</p>
          </div>
          <div fxFlex="grow" fxFlex.gt-sm="30" class="px-1">
            <p class="my-1 mat-text-secondary">{{pastBoarding?.boardingBooking?.notes}}</p>
          </div>
        </div>

        <div fxFlex="nogrow">
          <div class="avatar avatar-50 circle border-3 d-inline-block mx-2" matTooltip="{{'EDIT_BOARDING' | translate}}"
            (click)="openEditAppointmentModal(pastBoarding)" *ngIf="!isViewMode">
            <mat-icon class="view-invoice vm">
              edit_calendar
            </mat-icon>
          </div>
          <div class="avatar avatar-50 circle border-3 d-inline-block" *ngIf="pastBoarding?.boardingBooking.invoiceId"
            matTooltip="{{'VIEW_INVOICE' | translate}}" (click)="viewInvoice(pastBoarding)">
            <mat-icon class="view-invoice vm">
              receipt
            </mat-icon>
          </div>
          <!-- <p class="mb-0" (click)="viewInvoice(pastAppointment)">Invoice</p> -->
        </div>
      </div>
    </li>
  </ul>
  <div class="no-history mt-2" *ngIf="!pastBoardings.length">
    <div style="text-align:center;">
      <div class="avatar avatar-100 circle border-3 mb-2 d-inline-block">
        <mat-icon class="h1 h-auto w-auto">timeline</mat-icon>
      </div>
      <h6>{{'NO_PAST_BOOKINGS' | translate}}</h6>
    </div>
  </div>
</div>

<div *ngIf="historyMode === 'ongoing'">
  <ul class="historylist mb-0" *ngIf="ongoingBoardings.length">
    <li *ngFor="let ongoingBoarding of ongoingBoardings">
      <div fxLayout="row">
        <div fxFlex fxLayout="column" fxLayout.gt-sm="row">
          <div fxFlex="grow" fxFlex.gt-sm="30" (click)="openViewBoardingModal(ongoingBoarding)" class="px-1">
            <p class="my-1 view-invoice">{{ongoingBoarding.label}}</p>
          </div>
          <div fxFlex="grow" fxFlex.gt-sm="25" (click)="openViewBoardingModal(ongoingBoarding)" class="px-1">
            <p class="my-1 view-invoice mat-text-secondary">{{ongoingBoarding.petLabel}}</p>
          </div>
          <div fxFlex="grow" fxFlex.gt-sm="30" class="px-1">
            <p class="my-1 mat-text-secondary">{{ongoingBoarding?.boardingBooking?.notes}}</p>
          </div>
        </div>

        <div fxFlex="nogrow">
          <div class="avatar avatar-50 circle border-3 d-inline-block mx-2" matTooltip="{{'EDIT_BOARDING' | translate}}"
            (click)="openEditAppointmentModal(ongoingBoarding)" *ngIf="!isViewMode">
            <mat-icon class="view-invoice vm">
              edit_calendar
            </mat-icon>
          </div>
          <div class="avatar avatar-50 circle border-3 d-inline-block"
            *ngIf="ongoingBoarding?.boardingBooking.invoiceId" matTooltip="{{'VIEW_INVOICE' | translate}}"
            (click)="viewInvoice(ongoingBoarding)">
            <mat-icon class="view-invoice vm">
              receipt
            </mat-icon>
          </div>
          <!-- <p class="mb-0" (click)="viewInvoice(pastAppointment)">Invoice</p> -->
        </div>
      </div>
    </li>
  </ul>
  <div class="no-history mt-2" *ngIf="!ongoingBoardings.length">
    <div style="text-align:center;">
      <div class="avatar avatar-100 circle border-3 mb-2 d-inline-block">
        <mat-icon class="h1 h-auto w-auto">timeline</mat-icon>
      </div>
      <h6>{{'NO_ONGOING_BOOKINGS' | translate}}</h6>
    </div>
  </div>
</div>

<div *ngIf="historyMode === 'future'">
  <ul class="historylist mt-1" *ngIf="futureBoardings.length">
    <li *ngFor="let futureBoarding of futureBoardings">
      <div fxLayout="row">
        <div fxFlex fxLayout="column" fxLayout.gt-sm="row">
          <div fxFlex="grow" fxFlex.gt-sm="30" (click)="openViewBoardingModal(futureBoarding)" class="px-1">
            <p class="my-1 view-invoice">{{futureBoarding.label}}</p>
          </div>
          <div fxFlex="grow" fxFlex.gt-sm="25" (click)="openViewBoardingModal(futureBoarding)" class="px-1">
            <p class="my-1 mat-text-secondary view-invoice">{{futureBoarding.petLabel}}</p>
          </div>
          <div fxFlex="grow" fxFlex.gt-sm="30" class="px-1">
            <p class="my-1 mat-text-secondary">{{futureBoarding?.boardingBooking?.notes}}</p>
          </div>
        </div>
        <div fxFlex="nogrow">
          <div class="avatar avatar-50 circle border-3 d-inline-block mx-2" matTooltip="{{'EDIT_BOARDING' | translate}}"
            (click)="openEditAppointmentModal(futureBoarding)" *ngIf="!isViewMode">
            <mat-icon class="view-invoice vm">
              edit_calendar
            </mat-icon>
          </div>
          <div class="avatar avatar-50 circle border-3 d-inline-block"
            *ngIf="futureBoarding?.boardingBooking?.invoiceId" matTooltip="{{'VIEW_INVOICE' | translate}}"
            (click)="viewInvoice(futureBoarding)">
            <mat-icon class="view-invoice vm">
              receipt
            </mat-icon>
          </div>
          <!--          <mat-checkbox [hidden]="isViewMode" *ngIf="!showOnlyCancelled" class="mx-2 vm"-->
          <!--            (change)="selectBoarding($event, futureBoarding?.boardingBooking?.id)"></mat-checkbox>-->
          <!-- <p class="mb-0" (click)="viewInvoice(futureAppointment)">Invoice</p> -->
        </div>
      </div>
    </li>
  </ul>
  <div class="no-history mt-2" *ngIf="!futureBoardings.length">
    <div style="text-align:center;">
      <div class="avatar avatar-100 circle border-3 mb-2 d-inline-block">
        <mat-icon class="h1 h-auto w-auto">event</mat-icon>
      </div>
      <h6>{{'NO_UPCOMING_BOOKINGS' | translate}}</h6>
    </div>
  </div>
</div>