<mat-toolbar color="primary">
  <mat-toolbar-row>
    <h2>{{'SEND_PAYMENT_LINK_SMS' | translate}}</h2>
  </mat-toolbar-row>
</mat-toolbar>


<div *ngIf="!linkUrl">
  <form #paymentForm="ngForm" class="sms-link w-100 mdc-p-3">

    <mat-form-field appearance="outline" style="width: 100%">
      <mat-label>{{'AMOUNT' | translate}}</mat-label>
      <input matInput [(ngModel)]="createAndSendLinkRequest.amount" name="amount" [required]="true" #amount="ngModel" id="amount" placeholder="{{'AMOUNT' | translate}}" style="width: 100%">
      <mat-hint>{{'AMOUNT_TO_BE_CHARGED' | translate}}</mat-hint>
    </mat-form-field>
    <mat-checkbox class="example-margin" [(ngModel)]="createAndSendLinkRequest.preAuth" name="preAuth" #preAuth="ngModel">{{'PRE_AUTH' | translate}}</mat-checkbox><br>
    <mat-hint>{{'NO_PAYMENT_TAKEN_IF_CHECKED' | translate}}</mat-hint>
    <br><br>

    <div fxLayout="row">
      <div fxFlex="grow">
        <button mat-raised-button color="primary" [disabled]="!paymentForm.valid" (click)="createAndSendLink()">{{'SEND_LINK' | translate}}</button>
      </div>
      <div fxFlex="nogrow">
        <button mat-raised-button (click)="closeDialog()">{{'CANCEL' | translate}}</button>
      </div>
    </div>

  </form>
</div>

<div *ngIf="linkUrl" class="mdc-p-3 ">
  <p>{{'PAYMENT_LINK_SUCCESSFULLY_SENT' | translate}} {{ createAndSendLinkRequest.recipient }}.</p>
  <p><span class="mat-text-secondary">{{'LINK_GENERATED_IS' | translate}}:</span><br><a href="{{ linkUrl }}">{{ linkUrl }}</a></p>
  <div fxLayout="row">
    <div fxFlex="grow">
    </div>
    <div fxFlex="nogrow">
      <button mat-raised-button (click)="closeDialog()">{{'CLOSE' | translate}}</button>
    </div>
  </div>
</div>