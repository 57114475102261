import { debounceTime } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { EicompService, EntityChangeEventV2, FieldValueDto } from '@savvy/entity-instance-composite';
import { ChangeListener } from '../changeListener';
import { FieldDefinitionDto, ElementInstanceDto, ContextIdDto, ElementDefinitionId } from '@savvy/view-definition';
import { ElementInstanceId } from '@savvy/entity-instance-composite';

@Component({
    selector: 'app-boolean-instance',
    templateUrl: './booleanInstance.component.html'
})
export class BooleanInstanceComponent implements OnInit {

    @Input() fieldDefinition: FieldDefinitionDto;
    @Input() elementInstanceDto: ElementInstanceDto;
    @Input() entityInstanceId: string;
    @Input() contextIdDto: ContextIdDto;
    @Input() form: UntypedFormGroup;
    @Input() showPlaceholder: boolean;
    @Input() changeListener: ChangeListener;

    constructor(private api: EicompService) {
    }

    get isValid() {

        if (this.form.controls[this.elementInstanceDto.instanceId] == null) {
            return false;
        } else {
            return this.form.controls[this.elementInstanceDto.instanceId].valid;
        }
    }

    ngOnInit(): void {

        if (this.form.controls[this.elementInstanceDto.instanceId] == null) {
            console.log('PANIC missing form control for field name ' + this.fieldDefinition.name + ' id ' + this.fieldDefinition.id);
        } else {
            this.form.controls[this.elementInstanceDto.instanceId].valueChanges.pipe(debounceTime(400)).subscribe((value) => {
                console.log('changed value=' + value);
                this.onChange(value);
            });
        }

        if (this.elementInstanceDto.elementInstanceViewDto.displayWidgetType === 'RADIO' &&
            this.form.controls[this.elementInstanceDto.instanceId].value == null) {
            console.log('setting to be false as it is a radio');
            this.form.controls[this.elementInstanceDto.instanceId].setValue(false);
        }
    }

    getPlaceholder() {
        if (this.showPlaceholder) {
            return this.fieldDefinition.label;
        }
        return '';
    }

    onChange(newValue: boolean) {
        const fieldValue: FieldValueDto = {
            booleanValue: newValue,
            valueType: FieldValueDto.ValueTypeEnum.Boolean
        };

        const fieldInstanceId = <ElementInstanceId>{};
        fieldInstanceId.id = this.elementInstanceDto.instanceId;

        const elementDefinitionId = <ElementDefinitionId>{};
        elementDefinitionId.id = this.elementInstanceDto.elementDefinitionId;

        const req: EntityChangeEventV2 = {
            contextIdDto: this.contextIdDto,
            fieldValue,
            entityInstanceId: this.entityInstanceId,
            elementDefinitionId,
            fieldInstanceId
        };

        /* contextIdDto?: ContextIdDto;
         entityInstanceId?: string;
         fieldInstanceId?: ElementInstanceId;
         elementDefinitionId?: ElementDefinitionId;
         fieldValue?: FieldValueDto;*/

        // There is no loadProductsResponse from this, do we care?
        this.api.entityInstanceChangeEventV2(req).subscribe(
            response => {
                console.log('applied change to input instance, loadProductsResponse ' + response);
                console.log('changeListener is ' + this.changeListener);
                if (this.changeListener) {
                    this.changeListener.add('change');
                } else {
                    console.log('PANICK CHANGE LISTENER IS NULL IN INPUT INSTANCE');
                }
            }
        );

    }
}

