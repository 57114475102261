<mat-toolbar color="primary" class="mdc-mb-2" fxLayout="row" fxLayoutGap="10px">
  <div fxFlex *ngIf="invoice?.id && invoice?.created"><mat-icon (click)="back()" class="vm cp">arrow_back</mat-icon>
    Invoice #{{invoice?.invoiceNumber}}
  </div>
  <!-- <div fxFlex *ngIf="!invoice?.created"><mat-icon (click)="back()" class="vm cp">arrow_back</mat-icon> Create
    Invoice </div> -->
  <div fxFlex></div>
  <div fxFlex="nogrow" *ngIf="invoice?.id">
    <button *ngIf="!invoice?.created" mat-raised-button color="accent" (click)="makeCreated()" class="mr-05 d-inline-block">
      <mat-icon>receipt_long</mat-icon>
      {{'CREATE_INVOICE' | translate: {Default: 'Create Invoice'} }}
    </button>
    <button [disabled]="!paymentSettings?.paymentSetup" *ngIf="!invoice?.invoiceVoid" mat-raised-button color="accent" (click)="sendInvoice(invoice)" class="mr-05 d-inline-block">
      <mat-icon>receipt_long</mat-icon>
      {{'SEND_INVOICE' | translate: {Default: 'Send Invoice'} }}
    </button>
    <button *ngIf="invoice?.id && !invoice.invoiceVoid && !invoice?.paidInFull" mat-raised-button color="accent" (click)="voidInvoice(invoice)" class="mr-05 d-inline-block">
      <mat-icon>block</mat-icon>
      {{'VOID_INVOICE' | translate: {Default: 'Void Invoice'} }}
    </button>
  </div>
</mat-toolbar>

<div class="mdc-px-2" *ngIf="invoice">
  <div fxLayout='row wrap' fxLayoutGap="0" fxLayoutGap.gt-sm="16px">
    <div fxFlex="100" fxFlex.gt-sm="55" fxFlex.gt-md="65" class="mdc-mb-1">
      <mat-card appearance="outlined" class="p-0 m-0">
        <mat-tab-group [(selectedIndex)]="activeInvoiceRowTabIndex">
          <mat-tab label="Retail">
            <ng-container *ngIf="activeInvoiceRowTabIndex === 0">
              <div class="mdc-px-2 border-bottom">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Search product</mat-label>
                  <input matInput placeholder="Search product..." [(ngModel)]="searchProducts">
                  <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
              </div>

              <mat-card-content class="bg-light-gray">
                <div fxLayout='row wrap' fxLayoutGap="0" fxFlexAlign=" center" class="mdc-py-1">
                  <div fxFlex></div>
                  <div fxFlex="nogrow">
                    <p class="mat-text-secondary small mb-0">Double click/tap & hold to edit product</p>
                  </div>
                </div>

                <ng-container *ngFor="let groupedProduct of groupedProducts | keyvalue">
                  <div fxLayout='row wrap' fxLayoutGap="0" fxFlexAlign=" center">
                    <div fxFlex>
                      <h6 class="mdc-my-2">
                        <span *ngIf="groupedProduct.key == 'null'">General</span>
                        <span *ngIf="groupedProduct.key != 'null'">{{groupedProduct.key}}</span>
                        -
                        <span class="fw-normal">{{groupedProduct?.value?.length}} <span class="mat-text-secondary">Products in total</span></span>
                      </h6>
                    </div>
                    <div fxFlex="nogrow"></div>
                  </div>
                  <div fxLayout='row wrap' fxLayoutGap="1.5%">
                    <div *ngFor="let product of groupedProduct?.value | search:'productName':searchProducts; trackBy: trackByProductMethod" fxFlex="100" fxFlex.gt-sm="46" fxFlex.gt-md="31.5" class="cp">
                      <mat-card appearance="outlined" (dblclick)="editProduct(product)" class="mdc-my-1">
                        <mat-card-content>
                          <div fxLayout='row wrap' fxLayoutGap="0" fxLayoutAlign=" center">
                            <div fxFlex>
                              <h6 class="mdc-mb-0">{{product?.productName}}</h6>
                              <p class="mat-text-secondary mb-0"><span *ngIf="product?.description">{{product?.description}} -
                                </span>{{product?.unitPrice
                                | currency: currencyCode}}<span *ngIf="product?.tax"> - Tax:
                                  {{product?.tax}}%</span>
                              </p>
                            </div>
                            <div fxFlex="nogrow">
                              <ng-container *ngIf="shopConfig?.enableStockControl">
                                <h6 *ngIf="product?.stockAvailable <= 0" class="mat-text-red mb-0 outofstock">{{'OUT_OF_STOCK' | translate}}</h6>
                                <button mat-icon-button color="primary" (click)="addProduct(invoice, product)" *ngIf="!invoice?.paidInFull && !invoice?.invoiceVoid && product?.stockAvailable > 0"><mat-icon matSuffix>add_circle</mat-icon></button>
                              </ng-container>
                              <ng-container *ngIf="!shopConfig?.enableStockControl">
                                <button mat-icon-button color="primary" (click)="addProduct(invoice, product)" *ngIf="!invoice?.paidInFull && !invoice?.invoiceVoid"><mat-icon matSuffix>add_circle</mat-icon>
                                </button>
                              </ng-container>

                            </div>
                          </div>
                        </mat-card-content>
                      </mat-card>
                    </div>
                  </div>
                </ng-container>
              </mat-card-content>
            </ng-container>
          </mat-tab>

          <mat-tab label="Service">
            <ng-container *ngIf="activeInvoiceRowTabIndex === 1">
              <div class="mdc-px-2 border-bottom">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Search service</mat-label>
                  <input matInput placeholder="Search service..." [(ngModel)]="searchServices">
                  <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
              </div>

              <mat-card-content class="mdc-p-3 bg-light-gray">
                <div fxLayout='row wrap' fxLayoutGap="0" fxFlexAlign=" center" class="mdc-py-1">
                  <div fxFlex></div>
                  <div fxFlex="nogrow">
                    <p class="mat-text-secondary small mb-0">Double click/tap & hold to edit service</p>
                  </div>
                </div>

                <ng-container *ngFor="let groupedConsumerService of groupedConsumerServices | keyvalue">
                  <div fxLayout='row wrap' fxLayoutGap="0" fxFlexAlign=" center">
                    <div fxFlex>
                      <h6 class="mdc-my-2">
                        <span *ngIf="groupedConsumerService.key == 'null'">General</span>
                        <span *ngIf="groupedConsumerService.key != 'null'">{{groupedConsumerService.key}}</span>
                        -
                        <span class="fw-normal">{{groupedConsumerService?.value?.length}} <span class="mat-text-secondary">Services in total</span></span>
                      </h6>
                    </div>
                    <div fxFlex="nogrow"></div>
                  </div>
                  <div fxLayout='row wrap' fxLayoutGap="1.5%">
                    <div *ngFor="let service of groupedConsumerService?.value | search:'serviceName':searchServices; trackBy: trackByServiceMethod" fxFlex="100" fxFlex.gt-sm="46" fxFlex.gt-md="31.5" class="cp">
                      <mat-card appearance="outlined" (dblclick)="editService(service)" class="mdc-my-1">
                        <mat-card-content>
                          <div fxLayout='row wrap' fxLayoutGap="0" fxLayoutAlign=" center">
                            <div fxFlex>
                              <h6 class="mdc-mb-0">{{service?.serviceName}}</h6>
                              <p class="mat-text-secondary mb-0">{{service?.durationInMins}} Mins -
                                {{service?.unitPrice
                                | currency: currencyCode}}<span *ngIf="service?.tax"> - Tax:
                                  {{service?.tax}}%</span>
                              </p>
                            </div>
                            <div fxFlex="nogrow">
                              <button mat-icon-button color="primary" (click)="addService(invoice, service)" *ngIf="!invoice?.paidInFull && !invoice?.invoiceVoid"><mat-icon matSuffix>add_circle</mat-icon></button>
                            </div>
                          </div>
                        </mat-card-content>
                      </mat-card>
                    </div>
                  </div>
                </ng-container>
              </mat-card-content>
            </ng-container>
          </mat-tab>
        </mat-tab-group>
      </mat-card>
    </div>

    <div fxFlex="100" fxFlex.gt-sm="">
      <mat-card appearance="outlined" class="mdc-mb-1">
        <mat-card-header class="mdc-mb-3">
          <div fxLayout='row wrap' fxLayoutGap="0" fxFlexAlign=" center">
            <div fxFlex>
              <h5>{{getTotalItemsInCart(invoice)}} Items</h5>
            </div>
            <div fxFlex="nogrow">
            </div>
          </div>
        </mat-card-header>
        <mat-card-content class="mdc-py-2 border-bottom mdc-mb-2">
          <app-add-invoice-customer-selector *ngIf="contextIdDto && !newCustomer" [contextIdDto]="contextIdDto" [suppportCreate]="true" [customerId]="invoice?.invoiceOwnerId?.id" [autoFocus]="false" (customerSelected)="customerSelected($event, invoice)">
          </app-add-invoice-customer-selector>
          <app-location-selector-v2 class="display-block" [contextIdDto]="contextIdDto" [locationIdSelected]="invoice?.locationId" (locationChange)="locationChanged($event, invoice)" #locationSelectorV2Component>
          </app-location-selector-v2>
        </mat-card-content>

        <mat-card-content class="bg-light-gray cartProduct border-bottom mdc-mb-2">
          <div fxLayout="row wrap" fxLayoutAlign=" center" class="mdc-py-2 mdc-py-1" *ngFor="let invoiceServiceRow of invoice?.invoiceServiceRows; let serviceIndex = index">
            <div fxFlex="nogrow">
              <button mat-icon-button class="mdc-mr-2 mat-text-secondary" (click)="removeService(invoice, serviceIndex)" *ngIf="!invoice?.paidInFull && !invoice?.invoiceVoid">
                <mat-icon>cancel</mat-icon>
              </button>
            </div>
            <div fxFlex>
              <h6 class="mdc-mb-0">{{invoiceServiceRow?.serviceName}}</h6>
              <p class="mat-text-secondary">{{invoiceServiceRow?.durationInMins}} Mins</p>
            </div>
            <div fxFlex="20" fxFlex.gt-xs="30">
              <div fxLayout="column" fxLayout.gt-xs="row wrap" fxLayoutAlign="center center">
                <div fxFlex="100" fxFlex.gt-xs="nogrow">
                  <button mat-icon-button color="primary" (click)="decrementServiceQty(invoice, invoiceServiceRow)" *ngIf="!invoice?.paidInFull && !invoice?.invoiceVoid" [disabled]="invoiceServiceRow.quantity === 1">
                    <mat-icon>do_not_disturb_on</mat-icon>
                  </button>
                </div>
                <div fxFlex="100" fxFlex.gt-xs>
                  <input type="text" [(ngModel)]="invoiceServiceRow.quantity" placeholder="Qty." class="text-center normalinput w-100" [disabled]="invoice?.paidInFull || invoice?.invoiceVoid" />
                </div>
                <div fxFlex="100" fxFlex.gt-xs="nogrow">
                  <button mat-icon-button color="primary" (click)="incrementServiceQty(invoice, invoiceServiceRow)" *ngIf="!invoice?.paidInFull && !invoice?.invoiceVoid">
                    <mat-icon>add_circle</mat-icon>
                  </button>
                </div>
              </div>
            </div>
            <div fxFlex="22" class="text-end">
              <h6 class="mdc-mb-0">{{invoiceServiceRow?.unitPrice | currency: currencyCode }}</h6>
              <p class="small">
                <span *ngIf="invoiceServiceRow?.tax">Tax: {{invoiceServiceRow?.tax}}%</span>
                <span *ngIf="invoiceServiceRow?.tax && invoiceServiceRow?.grossPrice">, Gross: {{invoiceServiceRow?.grossPrice | currency: currencyCode}}</span>
              </p>
            </div>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign=" center" class="mdc-py-2 mdc-py-1" *ngFor="let invoiceProductRow of invoice?.invoiceProductRows; let productIndex = index">
            <div fxFlex="nogrow">
              <button mat-icon-button class="mdc-mr-2 mat-text-secondary" (click)="removeProduct(invoice, productIndex)" *ngIf="!invoice?.paidInFull && !invoice?.invoiceVoid">
                <mat-icon>cancel</mat-icon>
              </button>
            </div>
            <div fxFlex>
              <h6 class="mdc-mb-0">{{invoiceProductRow?.productName}}</h6>
            </div>
            <div fxFlex="20" fxFlex.gt-xs="30">
              <div fxLayout="column" fxLayout.gt-xs="row wrap" fxLayoutAlign="center center">
                <div fxFlex="100" fxFlex.gt-xs="nogrow">
                  <button mat-icon-button color="primary" (click)="decrementProductQty(invoice, invoiceProductRow)" *ngIf="!invoice?.paidInFull && !invoice?.invoiceVoid" [disabled]="invoiceProductRow.quantity === 1">
                    <mat-icon>do_not_disturb_on</mat-icon>
                  </button>
                </div>
                <div fxFlex="100" fxFlex.gt-xs>
                  <input type="text" [(ngModel)]="invoiceProductRow.quantity" placeholder="Qty." class="text-center normalinput w-100" [disabled]="invoice?.paidInFull || invoice?.invoiceVoid" />
                </div>
                <div fxFlex="100" fxFlex.gt-xs="nogrow">
                  <button mat-icon-button color="primary" (click)="incrementProductQty(invoice, invoiceProductRow)" *ngIf="!invoice?.paidInFull && !invoice?.invoiceVoid">
                    <mat-icon>add_circle</mat-icon>
                  </button>
                </div>
              </div>
            </div>
            <div fxFlex="22" class="text-end">
              <h5 class="mdc-mb-0">{{invoiceProductRow?.unitPrice | currency: currencyCode }}</h5>
              <p class="small">
                <span class="mdc-mb-0" *ngIf="invoiceProductRow?.tax">Tax: {{invoiceProductRow?.tax}}%</span>
                <span class="mdc-mb-0" *ngIf="invoiceProductRow?.tax && invoiceProductRow?.grossPrice">, Gross: {{invoiceProductRow?.grossPrice | currency: currencyCode}}</span>
              </p>
            </div>
          </div>
        </mat-card-content>

        <mat-card-content class="">
          <div fxLayout="row wrap" class="">
            <div fxFlex="25">
              <h5 class="mdc-mb-0">{{invoice?.subTotalWithoutDiscount | currency: currencyCode}}</h5>
              <p class="mat-text-secondary mb-0">Sub total</p>
            </div>
            <div fxFlex="25">
              <h5 class="mdc-mb-0">{{invoice?.tax | currency: currencyCode}}</h5>
              <p class="mat-text-secondary mb-0">Tax</p>
            </div>
            <div fxFlex="25">
              <h5 class="mdc-mb-0">{{invoice?.discountTotal | currency: currencyCode}}</h5>
              <p class="mat-text-secondary mb-0" *ngIf="invoice.id && !invoice.invoiceVoid && !invoice?.paidInFull">
                <button (click)="onDiscountClick(invoice)" mat-icon-button [matTooltip]="invoice?.discountDetails | discountTmpl:currencyCode">
                  {{invoice?.discountDetails | discountTmpl:currencyCode}}
                  <mat-icon role="img" [ngClass]="{'discount-applied': invoice?.discountDetails}" class="demo-toolbar-icon" aria-label="Discount">local_offer</mat-icon>
                </button>
              </p>
            </div>
            <div fxFlex="25" class="text-end">
              <h5 class="mdc-mb-0 mat-text-green">{{invoice?.total | currency: currencyCode}}</h5>
              <p class="mat-text-secondary mb-0">Total</p>
            </div>
          </div>

          <div *ngIf="!invoice?.id">
            <button mat-raised-button color="accent" (click)="createInvoice()" class="mr-05" [disabled]="!invoice?.invoiceServiceRows?.length && !invoice?.invoiceProductRows?.length">
              <mat-icon>receipt_long</mat-icon>
              {{'CREATE_INVOICE' | translate: {Default: 'Create Invoice'} }}
            </button>
          </div>

          <br>
          <div *ngIf="invoice?.id">
            <h5 *ngIf="invoice?.paidInFull" class="mdc-mb-1 mat-text-primary text-bold">
              {{'INVOICE_STATUS' | translate}} <b>{{'PAID' | translate}}</b>
            </h5>

            <h5 *ngIf="invoice?.invoiceVoid" class="mdc-mb-1 mat-text-primary text-bold">
              {{'INVOICE_STATUS' | translate}} <b>{{'VOIDED' | translate}}</b>
            </h5>
            <button *ngIf="!invoice?.paidInFull && !invoice.invoiceVoid" mat-raised-button color="accent" (click)="addPayment(invoice)" class="mr-05 mdc-my-1">
              <mat-icon>payments</mat-icon>
              {{'ADD_PAYMENT' | translate: {Default: 'Add Payment'} }}
            </button>

            <button *ngIf="!invoice?.paidInFull && !invoice.invoiceVoid" mat-raised-button color="accent" (click)="paidFull('CASH', invoice)" class="mr-05 mdc-my-1">
              <mat-icon>store</mat-icon>
              {{'PAID_CASH' | translate: {Default: 'Paid Cash'} }}
            </button>

            <button *ngIf="!invoice?.paidInFull && !invoice.invoiceVoid" mat-raised-button color="accent" (click)="paidFull('CARD_MACHINE', invoice)" class="mr-05 mdc-my-1">
              <mat-icon>payment</mat-icon>
              {{'PAID_CARD' | translate: {Default: 'Paid Card'} }}
            </button>

            <button *ngIf="!invoice?.paidInFull && !invoice.invoiceVoid" mat-raised-button color="accent" (click)="paidFull('DIRECT_DEBIT', invoice)" class="mr-05 mdc-my-1">
              <mat-icon>payments</mat-icon>
              {{'PAID_DD' | translate: {Default: 'Paid Direct Debit'} }}
            </button>

            <button *ngIf="!invoice?.paidInFull && !invoice.invoiceVoid" mat-raised-button color="accent" (click)="paidFull('BANK_TRANSFER', invoice)" class="mr-05 mdc-my-1">
              <mat-icon>account_balance</mat-icon>
              {{'PAID_BANK' | translate: {Default: 'Paid Bank'} }}
            </button>

            <button *ngIf="plans && plans.length > 0 && !invoice?.paidInFull && !invoice.invoiceVoid" mat-raised-button color="accent" (click)="showPaidPlans(invoice)" class=" mdc-py-1">
              <mat-icon>card_membership</mat-icon>
              {{'PAID_PLANS' | translate: {Default: 'Paid Plans'} }}
            </button>

            <button *ngIf="!invoice?.paidInFull && !invoice.invoiceVoid" [disabled]="!cardMachines || cardMachines.length <= 0" mat-raised-button color="accent" (click)="showTerminalPayment(invoice)" class="mr-05 mdc-my-1">
              <mat-icon>account_balance</mat-icon>
              {{'SAVVY_CARD_MACHINE' | translate: {Default: 'Savvy Card Machine'} }}
            </button>

            <mat-hint *ngIf="!paymentSettings?.paymentSetup"><a (click)="goToPaymentSettings()">{{"SETUP_SAVVY_PAYMENTS" | translate: {Default: 'Setup your Payment Settings here to start sending invoices.'} }}</a></mat-hint>
            <mat-hint *ngIf="!invoice?.paidInFull && !invoice.invoiceVoid && (!cardMachines || cardMachines.length <= 0)">
              <a (click)="goToSavvyPaySettings()">{{'SETUP_SAVVY_PAY_DESCRIPTION' | translate: {Default: 'Setup your Savvy Pay Card Machine here and start accepting in-person payments.'} }} </a>
            </mat-hint>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>