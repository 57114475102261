import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavChangeService } from '../element/NavChangeService';
import MenuItemTypeEnum = MenuDefinition.MenuItemTypeEnum;
import { CheckinFormDefinition, CheckinFormDefsService } from '@savvy/check-in-form';
import { ContextIdDto } from '@savvy/quickbooks';
import { MenuDefinition } from '@savvy/view-definition';
// import { CustomerSignupFormDefinition, CustomerSignupFormService } from '@savvy/customer-signup-form';

@Component({
  selector: 'app-customer-signup-form-templates',
  templateUrl: 'customerSignupFormTemplates.component.html',
  styleUrls: ['./customerSignupFormTemplates.component.scss']
})
export class CustomerSignupFormTemplatesComponent implements OnInit {
  customerSignupFormDefinitions: Array<any> = [];

  selected: any[] = [];

  private sub: any;
  public contextIdDto: ContextIdDto;

  columns = [
    { prop: 'name' }
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navChange: NavChangeService,
    // private api: CustomerSignupFormService
  ) {
  }

  ngOnInit() {

    this.navChange.add(MenuItemTypeEnum.CustomerCaptureFormTemplates);

    this.sub = this.route.params.subscribe(params => {
      this.contextIdDto = <ContextIdDto>{};
      this.contextIdDto.contextId = params['contextId'];
      this.contextIdDto.contextIdType = params['contextIdType'];
      this.getForms();
    });
  }

  getForms() {
    // contactForm
    // this.api.listCustomerSignupForms(this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(
    //   forms => {
    //     console.log('got ' + forms.length + ' forms');
    //     this.customerSignupFormDefinitions = forms;
    //   });
  }

  create() {
    this.router.navigate(['/customerSignup/addCustomerSignupFormDef',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType]);
  }

  onSelect(event) {
    this.router.navigate(['/customerSignup/editCustomerSignupFormDef',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      this.selected[0].id
    ]);
  }
}
