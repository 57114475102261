import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContextIdDto } from '@savvy/branding';
import { DateTimeAdapter, NativeDateTimeAdapter, OWL_DATE_TIME_LOCALE, OwlDateTimeIntl } from '@danielmoncada/angular-datetime-picker';
import { CreateMeeting, Meeting, MeetingsService, UpdateMeeting, UserId } from '@savvy/meeting';
import { CalendarService, UserDto } from '@savvy/calendar';
import { TranslateService } from '@ngx-translate/core';
import { UsercompService } from '@savvy/user';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';
import { Platform } from '@angular/cdk/platform';
import { LocalIntl } from 'src/app/services/local-intl.service';


@Component({
  selector: 'app-add-edit-appointment-meeting',
  templateUrl: 'addEditAppointmentMeeting.component.html',
  providers: [
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-US' },
    { provide: DateTimeAdapter, useClass: NativeDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE, Platform] },
    { provide: OwlDateTimeIntl, useClass: LocalIntl },
  ]
})
export class AddEditAppointmentMeetingComponent implements OnInit {


  meeting: Meeting;
  users: Array<any> = [];

  showSpinner: boolean;


  contextIdDto: ContextIdDto;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddEditAppointmentMeetingComponent>,
    dateTimeAdapter: DateTimeAdapter<any>,
    private calendarApi: CalendarService,
    private meetingsService: MeetingsService,
    private translateService: TranslateService,
    private userCompApi: UsercompService,
    private notify: FloSnackbarComponent) {

    dateTimeAdapter.setLocale(this.translateService.currentLang);
  }

  ngOnInit() {
    if (this.data) {
      this.contextIdDto = this.data.contextIdDto;
      if (this.data.meetingId) {
        this.loadMeeting(this.data.meetingId);
      } else {
        this.meeting = {} as Meeting;
        this.meeting.appointmentId = this.data.appointmentId;
        console.log('set appointment id to ', this.data.appointmentId);
        this.showSpinner = false;
      }

      this.getUsers();
    }
  }


  createMeeting() {
    this.showSpinner = true;

    this.meeting.ownerId = this.contextIdDto.contextId;
    const req: CreateMeeting = {
      contextIdDto: this.contextIdDto,
      meeting: this.meeting
    };
    this.meetingsService.createMeeting(req)
      .subscribe(res => {
        this.translateService.get('Meeting successfully created')
          .subscribe((val) => {
            this.notify.message = val;
            this.notify.open();
          });
        this.showSpinner = false;
        this.dialogRef.close(res.meeting);
      });
  }

  updateMeeting() {
    const req: UpdateMeeting = {
      contextIdDto: this.contextIdDto,
      meeting: this.meeting
    };
    this.meetingsService.updateMeeting(req)
      .subscribe(res => {
        this.translateService.get('Meeting successfully updated')
          .subscribe((val) => {
            this.notify.message = val;
            this.notify.open();
          });
        this.showSpinner = false;
        this.dialogRef.close(res.meeting);
      });
  }

  setUserOptVal(user: UserDto): UserId {
    return { id: user.id, userContextType: UserId.UserContextTypeEnum.UserId };
  }

  compareUserFn(user1: UserDto, user2: UserDto) {
    return user1 && user2 && user1.id === user2.id;
  }

  startDateChange(event) {
    this.meeting.end = event;
  }

  close() {
    this.dialogRef.close();
  }

  private getUsers() {
    this.userCompApi.getEnvUsersFilterCustomers(this.contextIdDto.contextId)
      .subscribe(response => {
        this.users = response.userDtos;
      });
  }

  private loadMeeting(meetingId) {
    this.meetingsService.getMeeting(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      meetingId).subscribe(response => {
        this.meeting = response.meeting[0];
      });
  }
}
