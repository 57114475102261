<mat-toolbar color="primary" class="mdc-mb-2">
  <mat-toolbar-row>
    <h5>{{'SELECT_SUBSCRIPTION' | translate}}</h5>
  </mat-toolbar-row>
</mat-toolbar>

<div *ngIf="showSpinner" class="bg-spinner-wrapper">
  <mat-spinner style="margin: 0 auto;"></mat-spinner>
</div>
<div class="container" *ngIf="subscription">
  <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutGap="16px" fxLayoutAlign="" fxLayoutAlign.gt-sm=" center"
    class="mdc-mb-2">
    <div fxFlex="100" fxFlex.gt-sm>
      <h2>{{'MY_SUBSCRIPTION' | translate}}</h2>
      <div
        *ngIf="subscriptionHelperService.subscriptionStatusDto && !subscriptionHelperService.subscriptionStatusDto.onTrial && !subscriptionHelperService.subscriptionStatusDto.active">
        <p>
          {{'ACCOUNT_IS_INACTIVE' | translate}}
        </p>
        <div *ngIf="(subscription.cardToken || subscriptionHelperService.subscriptionStatusDto?.recurringReference)"
          fxLayout="row wrap" fxLayoutAlign=" center" fxLayoutGap="16px">
          <div fxFlex="nogrow">
            <p class="mdc-mb-0">
              <span class="mat-text-secondary ">
                <mat-icon class="vm">credit_card</mat-icon>
                {{'MY_CARD' | translate}}:
              </span>
              XXXX XXXX XXXX {{ subscriptionHelperService.subscriptionStatusDto.lastDigits }}
            </p>
          </div>
          <div fxFlex="nogrow"
            *ngIf="subscriptionHelperService.subscriptionStatusDto.subscriptionStatus !== 'TRIAL_EXPIRED'"><a
              (click)="changeCardModal()" class="mat-text-accent">{{'CHANGE_CARD' | translate}}</a></div>
        </div>
      </div>
      <div
        *ngIf="subscriptionHelperService.subscriptionStatusDto && subscriptionHelperService.subscriptionStatusDto.onTrial">
        <p>
          {{'YOU_HAVE' | translate}} <b>{{subscriptionHelperService.subscriptionStatusDto.numberOfDaysRemaining}}
            {{'DAYS' | translate}}</b> {{'LEFT_FROM_YOUR_TRIAL_PERIOD' | translate}}<br>
          {{'UPGRADE_SUBSCRIPTION_TO_CONTINUE' | translate}}
        </p>
        <div *ngIf="(subscription.cardToken || subscriptionHelperService.subscriptionStatusDto?.recurringReference)"
          fxLayout="row wrap" fxLayoutAlign=" center" fxLayoutGap="16px">
          <div fxFlex="nogrow">
            <p class="mb-0">
              <span class="mat-text-secondary ">
                <mat-icon class="vm">credit_card</mat-icon>
                {{'MY_CARD' | translate}}:
              </span>
              XXXX XXXX XXXX {{ subscriptionHelperService.subscriptionStatusDto.lastDigits }}
            </p>
          </div>
          <div fxFlex="nogrow"
            *ngIf="subscriptionHelperService.subscriptionStatusDto.subscriptionStatus != 'TRIAL_EXPIRED'"><a
              (click)="changeCardModal()" class="mat-text-accent">{{'CHANGE_CARD' | translate}}</a></div>
        </div>
      </div>
      <div
        *ngIf="subscriptionHelperService.subscriptionStatusDto && subscriptionHelperService.subscriptionStatusDto.active">
        <p>
          {{'YOU_HAVE' | translate}} <b>{{subscriptionHelperService.subscriptionStatusDto.numberOfDaysRemaining}}
            {{'DAYS' | translate}}</b> {{'UNTIL_YOUR_NEXT_INVOICE' | translate}}.<br>
        </p>
        <div *ngIf="(subscription?.cardToken || subscriptionHelperService.subscriptionStatusDto?.recurringReference)"
          fxLayout="row wrap" fxLayoutAlign=" center" fxLayoutGap="16px">
          <div fxFlex="nogrow">
            <p class="mb-0">
              <span class="mat-text-secondary ">
                <mat-icon class="vm">credit_card</mat-icon> {{'MY_CARD' | translate}}:
              </span>
              XXXX XXXX XXXX {{ subscriptionHelperService.subscriptionStatusDto.lastDigits }}
            </p>
          </div>
          <div fxFlex="nogrow"
            *ngIf="subscriptionHelperService.subscriptionStatusDto.subscriptionStatus != 'TRIAL_EXPIRED'"><a
              (click)="changeCardModal()" class="mat-text-accent">{{'CHANGE_CARD' | translate}}</a></div>
        </div>
      </div>
    </div>

    <div fxFlex="100" fxFlex.gt-sm="50" fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="16px"
      *ngIf="subscriptionHelperService?.subscriptionStatusDto">
      <div fxFlex="100" fxFlex.gt-sm="50">
        <mat-card appearance="outlined" class="my-0">
          <mat-card-content>
            <h5><span [innerHTML]="subscriptionHelperService.getTitle(currentSubscriptionDef) | translate"></span>
              {{'PLAN' | translate}} </h5>

            <div *ngIf="subscriptionHelperService.subscriptionStatusDto.onTrial">
              <h2><sup class="small" style="font-size: 14px;">{{getCurrencyCode(currentSubscriptionInterval)}}</sup>
                {{getCost(currentSubscriptionInterval)}}</h2>
              <!-- {{subscription.cost | currency:currencyCode:'symbol'}} -->
              <p class="mb-0 mat-text-secondary">
                {{subscriptionHelperService.subscriptionStatusDto.numberOfDaysRemaining}}
                {{'DAYS_LEFT_EXPIRES_ON' | translate}}
                {{subscriptionHelperService.subscriptionStatusDto.stripeTrialEndDate}}</p>
            </div>
            <div *ngIf="subscriptionHelperService.subscriptionStatusDto.active">
              <h2><sup class="small" style="font-size: 14px;">{{getCurrencyCode(currentSubscriptionInterval)}}</sup>
                {{getCost(currentSubscriptionInterval)}}</h2>
              <!-- {{subscription.cost | currency:currencyCode:'symbol'}} -->
              <p class="mb-0 mat-text-secondary">
                {{subscriptionHelperService.subscriptionStatusDto.numberOfDaysRemaining}}
                {{'NEXT_INVOICE' | translate}} {{subscriptionHelperService.subscriptionStatusDto.subscriptionEndDate}}
              </p>
            </div>
            <div
              *ngIf="!subscriptionHelperService.subscriptionStatusDto.active && !subscriptionHelperService.subscriptionStatusDto.onTrial">
              <h2><sup class="small" style="font-size: 14px;">{{getCurrencyCode(currentSubscriptionInterval)}}</sup>
                {{getCost(currentSubscriptionInterval)}}</h2>
              <!-- {{subscription.cost | currency:currencyCode:'symbol'}} -->
              <p class="mb-0 mat-text-secondary">{{'ACCOUNT_IS_INACTIVE' | translate}}</p>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div fxFlex="100" fxFlex.gt-sm="50">
        <mat-card appearance="outlined" class="my-0 h-100">
          <mat-card-content>
            <h5>{{'ADD_ONS' | translate}}</h5>
            <div>
              <h2><sup class="small" style="font-size: 14px;">{{getCurrencyCode(currentSubscriptionInterval)}}</sup> {{
                getAddOnCost() }}</h2>
              <!-- <span>{{subscription?.totalAddOns | currency:currencyCode:'symbol'}}</span> -->
              <div fxLayout="row">
                <div fxFlex>
                  <p class="mb-0 mat-text-secondary">{{getNumberOfAddOns()}} {{'ADD_ONS_INCLUDED' | translate}} </p>
                </div>
                <!--              <div fxFlex="nogrow"><a href="" class="mat-text-accent">View Details</a></div>&lt;!&ndash; show active addon list in modal box&ndash;&gt;-->
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>

  <ng-container *ngIf="subscriptionDefinitionList?.length">
    <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="5px" class="mdc-mb-2">
      <div fxFlex>
        <h4>{{'SUBSCRIPTION_PLANS' | translate}}</h4>
        <p class="mat-text-secondary">{{'FLEXIBLE_AND_DIFF_PLANS_TO_CHOOSE_FROM' | translate}}<br>
          {{'DIFF_PLANS_DIFF_FEATURES' | translate}}</p>
      </div>
      <div fxFlex="nogrow" *ngIf="!selectedSubscriptionDef">
        <h5 *ngIf="trialExpired">{{'TRIAL_ACC_EXPIRED' | translate}}.</h5>
        <mat-slide-toggle [(ngModel)]="annual" (ngModelChange)="planTypeChanged()" class="switch-center">
          <div class="h6">{{'SAVE_MORE_WITH_ANNUAL_PAYMENT' | translate}}</div>
          <p class="mat-text-secondary mb-0">{{'12_PRICE_11' | translate}}</p>
        </mat-slide-toggle>
      </div>
    </div>

    <div fxLayout="column" fxLayout.gt-md="row wrap" fxLayoutGap="0px" fxLayoutGap.gt-md="1%"
      *ngIf="!selectedSubscriptionDef">
      <div fxFlex="100" fxFlex.gt-md="{{getCardWidth()}}" class="mdc-mb-1"
        *ngFor="let subDef of subscriptionDefinitionList">
        <div *ngFor="let intervalPackage of subDef.subscriptionIntervalPackages">
          <mat-card appearance="outlined" *ngIf="display(intervalPackage.subscriptionInterval)"
            [class]="subscriptionHelperService.currentPlanAndActive(subDef) ? 'p-0 my-0 activeplan plan-card' : 'p-0 my-0 plan-card'">
            <!-- class toggle on active plan "activeplan"-->
            <mat-card-header class="mdc-mb-2">
              <div class="planheader text-center">
                <h2 [innerHTML]="subscriptionHelperService.getTitle(subDef)" class="my-1">{{'ADVANCE' | translate}}</h2>
                <p class="mb-0 text-opac">{{subDef.headerSummary | translate}}</p>
                <!-- <p *ngIf="!subscriptionHelperService.currentPlanAndActive(subDef) && subscription?.subscriptionDefinitionId?.id === subDef.id"
                class="mb-0 text-opac">{{subDef.headerSummary | translate}}</p> -->
              </div>
            </mat-card-header>
            <mat-card-content>
              <div class="text-center mdc-mb-1">
                <h1 class="mat-text-theme mb-0"><sup class="small"
                    style="font-size: 14px;">{{getCurrencyCode(intervalPackage)}}</sup> {{getCost(intervalPackage)}}
                </h1>
                <p class="mat-text-secondary" *ngIf="!subDef.subscriptionPackage.maxNumLocations">{{'PER' | translate}}
                  {{
                  getPeriod() |
                  translate}}</p>
                <p class="mat-text-secondary" *ngIf="subDef.subscriptionPackage.maxNumLocations > 2">{{'PER' |
                  translate}}
                  {{ getPeriod() |
                  translate}} {{'PER_SALON' | translate }}</p>
              </div>

              <ul class="feature-list mat-text-secondary">
                <li *ngFor="let feature of subDef.subscriptionPackage.displayFeatures">
                  <mat-icon>check_circle</mat-icon> {{feature | translate}}
                </li>
                <li>
                  <mat-icon>check_circle</mat-icon> {{ getSmsCredits(intervalPackage, subDef) + ' SMS'}}
                </li>
              </ul>

            </mat-card-content>
            <mat-card-actions class="mdc-p-3 text-center">
              <button *ngIf="subscriptionHelperService.isMultiLocationPlan(subDef)" (click)="locationManagement(subDef)"
                mat-button color="accent">{{'ADD_REMOVE_SALON' | translate}} <mat-icon>plus</mat-icon></button>
              <button *ngIf="subscriptionHelperService.noCurrentPlan()" (click)="choosePlan(subDef, intervalPackage)"
                mat-button color="accent">{{'CHOOSE_PLAN' | translate}}<mat-icon>chevron_right</mat-icon></button>
              <button *ngIf="subscriptionHelperService.differentCurrentPlanAndHasCard(subDef)"
                (click)="changePlanSameCardSelected(subDef, intervalPackage)" mat-button color="accent">
                {{'CHANGE_PLAN' | translate}}<mat-icon>chevron_right</mat-icon>
              </button>
              <!--            <button *ngIf="currentPlanAndActiveAndHasCard(subDef)" (click)="changeCard(subDef)" mat-button color="accent">{{'Change Card' | translate}}<mat-icon>credit_card</mat-icon></button>-->
            </mat-card-actions>
          </mat-card>
        </div>
      </div>
    </div>

    <div fxLayout="column" fxLayout.gt-md="row wrap" fxLayoutGap="0px" fxLayoutGap.gt-md="1%"
      *ngIf="selectedSubscriptionDef">
      <div fxFlex="100" fxFlex.gt-md="18.9" class="mdc-mb-1">
        <mat-card appearance="outlined" *ngIf="display(selectedSubscriptionInterval.subscriptionInterval)"
          [class]="subscriptionHelperService.currentPlanAndActive(selectedSubscriptionDef) ? 'p-0 my-0 activeplan plan-card' : 'p-0 my-0 plan-card'">
          <!-- class toggle on active plan "activeplan"-->
          <div class="planheader text-center mdc-py-2">
            <h2 [innerHTML]="subscriptionHelperService.getTitle(selectedSubscriptionDef)" class="my-1">{{'ADVANCE' |
              translate}}</h2>
            <p class="mb-0 text-opac">{{selectedSubscriptionDef.headerSummary | translate}}</p>
            <!-- <p *ngIf="!subscriptionHelperService.currentPlanAndActive(selectedSubscriptionDef) && subscription?.subscriptionDefinitionId?.id === selectedSubscriptionDef.id"
              class="mb-0 text-opac">{{selectedSubscriptionDef.headerSummary | translate}}</p> -->
          </div>
          <mat-card-content class="mdc-p-3 ">
            <div class="text-center mdc-mb-1">
              <h1 class="mat-text-theme mb-0"><sup class="small"
                  style="font-size: 14px;">{{getCurrencyCode(selectedSubscriptionInterval)}}</sup>
                {{getCost(selectedSubscriptionInterval)}}</h1>
              <p class="mat-text-secondary" *ngIf="!selectedSubscriptionDef.subscriptionPackage.maxNumLocations">{{'PER'
                |
                translate}} {{
                getPeriod() | translate}}</p>
              <p class="mat-text-secondary" *ngIf="selectedSubscriptionDef.subscriptionPackage.maxNumLocations > 2">
                {{'PER' | translate}} {{
                getPeriod() | translate}} {{'PER' | translate}} {{'PER_SALON' | translate }}</p>
            </div>

            <ul class="feature-list mat-text-secondary">
              <li *ngFor="let feature of selectedSubscriptionDef.subscriptionPackage.displayFeatures">
                <mat-icon>check_circle</mat-icon> {{feature | translate}}
              </li>
              <li>
                <mat-icon>check_circle</mat-icon> {{ getSmsCredits(selectedSubscriptionInterval,
                selectedSubscriptionDef)
                + ' SMS'}}
              </li>
            </ul>

          </mat-card-content>
          <mat-card-actions class="mdc-p-3 text-center">
            <button (click)="backToPlanList()" mat-button color="accent">
              <mat-icon>chevron_left</mat-icon> {{'CHANGE_PLAN' | translate}}
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
      <div fxFlex="100" fxFlex.gt-md="18.9" class="mdc-mb-1"
        *ngIf="display(selectedSubscriptionInterval.subscriptionInterval)">
        <mat-card appearance="outlined" class="p-0 my-0 h-100 plan-card">
          <!-- class toggle on active plan "activeplan"-->
          <div class="planheader text-center mdc-py-2">
            <h2 class="my-1">{{'ADD_ONS' | translate}}</h2>
            <p class="mb-0 text-opac">{{'COMBINE_WITH_PLAN' | translate}}</p>
          </div>
          <mat-card-content class="mdc-p-3 ">
            <div class="text-center mdc-mb-1">
              <h1 class="mat-text-theme mb-0"><sup class="small"
                  style="font-size: 14px;">{{getCurrencyCode(selectedSubscriptionInterval)}}</sup> {{getAddOnCost()}}
              </h1>
              <p class="mat-text-secondary" *ngIf="!selectedSubscriptionDef.subscriptionPackage.maxNumLocations">{{'PER'
                |
                translate}} {{
                getPeriod() | translate}}</p>
            </div>

            <p class="mat-text-secondary">Add some add-ons to limitless experience</p>
            <div *ngFor="let addOnDef of addOnDefToggle">
              <div fxLayout="row">
                <div fxFlex>
                  <mat-slide-toggle [(ngModel)]="addOnDef.checked" (ngModelChange)="calculateTotalPayable()"
                    class="feature-list mat-text-secondary mdc-mb-1">
                    {{addOnDef.addOnDef.name}}
                  </mat-slide-toggle>
                </div>
                <div fxFlex="nogrow">
                  <p class="mb-0 mat-text-secondary"><sup class="small" style="font-size: 10px;">{{currencyCode}}</sup>
                    {{getAddOnPrice(addOnDef.addOnDef)}}</p>
                </div>
              </div>
            </div>

          </mat-card-content>
          <mat-card-actions class="mdc-p-3 text-center">
            <button (click)="backToPlanList()" mat-button color="accent">
              <mat-icon>chevron_left</mat-icon> {{'CHANGE_PLAN' | translate}}
            </button>
          </mat-card-actions>
        </mat-card>
      </div>

      <div fxFlex="100" fxFlex.gt-md="18.9" class="mdc-mb-1"
        *ngIf="display(selectedSubscriptionInterval.subscriptionInterval)">
        <mat-card appearance="outlined" class="p-0 my-0 h-100 plan-card">
          <!-- class toggle on active plan "activeplan"-->
          <div class="planheader text-center mdc-py-2">
            <h2 class="my-1">{{'CONFIRM' | translate}}</h2>
            <p class="mb-0 text-opac">{{'PURCHASE_AND_PRICING' | translate}}</p>
          </div>
          <mat-card-content class="mdc-p-3 ">

            <h3 class="font-weight-bold mb-0">
              {{'SELECTED_ITEMS' | translate}}
            </h3>

            <div fxLayout="row mdc-py-1">
              <div fxFlex>
                <p class="my-2">{{ selectedSubscriptionDef.name }} {{ annual ? 'Annual' : 'Monthly'}} </p>
              </div>
              <div fxFlex="nogrow">
                <p class="my-2 font-weight-bold"><sup class="small"
                    style="font-size: 10px;">{{getCurrencyCode(selectedSubscriptionInterval)}}</sup>
                  {{getCost(selectedSubscriptionInterval)}}</p>
              </div>
            </div>

            <div fxLayout="row">
              <div fxFlex>
                <p class="my-2">{{'ADD_ONS' | translate}} {{ annual ? 'Annual' : 'Monthly'}}</p>
              </div>
              <div fxFlex="nogrow">
                <p class="my-2 font-weight-bold"><sup class="small"
                    style="font-size: 10px;">{{getCurrencyCode(selectedSubscriptionInterval)}}</sup> {{getAddOnCost()}}
                </p>
              </div>
            </div>
            <div fxLayout="row" *ngIf="totalDiscount > 0">
              <div fxFlex>
                <p class="my-2">{{'DISCOUNT' | translate}} {{ annual ? 'Annual' : 'Monthly'}}</p>
              </div>
              <div fxFlex="nogrow">
                <p class="my-2 font-weight-bold"><sup class="small"
                    style="font-size: 10px;">{{getCurrencyCode(selectedSubscriptionInterval)}}</sup> -{{totalDiscount}}
                </p>
              </div>
            </div>
            <div fxLayout="row" *ngIf="totalReferralDiscount > 0">
              <div fxFlex>
                <p class="my-2">{{'DISCOUNT' | translate}} {{ annual ? 'Annual' : 'Monthly'}}</p>
              </div>
              <div fxFlex="nogrow">
                <p class="my-2 font-weight-bold"><sup class="small"
                    style="font-size: 10px;">{{getCurrencyCode(selectedSubscriptionInterval)}}</sup>
                  -{{totalReferralDiscount}}
                </p>
              </div>
            </div>
            <div fxLayout="row" *ngIf="taxPayment">
              <div fxFlex>
                <p class="my-2"> {{ annual ? taxPayment.name + ' Annual' : taxPayment.name + ' Monthly'}}</p>
              </div>
              <div fxFlex="nogrow">
                <p class="my-2 font-weight-bold"><sup class="small"
                    style="font-size: 10px;">{{getCurrencyCode(selectedSubscriptionInterval)}}</sup>
                  {{taxPayment.amount}}
                </p>
              </div>
            </div>
            <br>
            <div class="divider my-3"></div>
            <br>
            <div class="text-center mb-2">
              <h4 class="font-weight-bold mdc-py-1"> {{'TOTAL_AMOUNT_TO_PAY' | translate}} </h4>
              <div class="text-center mdc-mb-1">
                <h1 class="mat-text-theme mb-0"><sup class="small" style="font-size: 14px;">
                    {{getCurrencyCode(selectedSubscriptionInterval)}}</sup> {{totalPrice}}</h1>
              </div>
              <!--            <p class="mat-text-secondary">You have saved <b class="text-dark"><sup class="small" style="font-size: 10px;">GBP</sup> 99.00</b><br> by <b class="text-dark">Annual Payment</b> selection</p>-->
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <div fxFlex="100" fxFlex.gt-md="40" class="mdc-mb-1"
        *ngIf="display(selectedSubscriptionInterval.subscriptionInterval)">
        <mat-card appearance="outlined" class="p-0 my-0 h-100 plan-card">
          <!-- class toggle on active plan "activeplan"-->
          <div class="planheader text-center mdc-py-2">
            <h2 class="my-1">{{'PAYMENT' | translate}}</h2>
            <p class="mb-0 text-opac">{{'DISCOUNT_AND_REFERRAL' | translate}}</p>
          </div>
          <mat-card-content class="mdc-p-3 ">

            <form novalidate [formGroup]="stripeTest">
              <!-- <mat-form-field class="w-100" appearance="outline">
                <mat-label>{{'Name on Card' | translate}}</mat-label>
                <input matInput placeholder="{{'Name on Card' | translate}}" formControlName="name">
              </mat-form-field> -->
              <mat-form-field class="w-100" appearance="outline"
                [ngClass]="{'mat-text-green': validCoupon, 'mat-text-red': !validCoupon}">
                <mat-label *ngIf="!validCoupon">{{'DISCOUNT_CODE' | translate}}</mat-label>
                <mat-label *ngIf="validCoupon">{{'DISCOUNT_CODE_APPLIED' | translate}}</mat-label>
                <input #discountCodeInput matInput placeholder="{{'DISCOUNT_CODE' | translate}}"
                  formControlName="coupon" (change)="discountCodeChanged($event)">
              </mat-form-field>
              <mat-form-field class="w-100" appearance="outline"
                [ngClass]="{'mat-text-green': validReferralCode, 'mat-text-red': !validReferralCode}">
                <mat-label *ngIf="!validReferralCode">{{'REFERRAL_CODE' | translate}}</mat-label>
                <mat-label *ngIf="validReferralCode">{{'REFERRAL_CODE_APPLIED' | translate}}</mat-label>
                <input #referralCodeInput matInput placeholder="{{'REFERRAL_CODE' | translate}}"
                  formControlName="referralCode" (change)="referralCodeChanged($event)">
              </mat-form-field>

              <br>
              <section class="configuration-toggle-section">
                <mat-slide-toggle class="mat-text-secondary" formControlName="autoRenew">{{'ALLOW_AUTO_RENEW' |
                  translate}}
                </mat-slide-toggle>
              </section>
              <br>

              <div *ngIf="!subscriptionContract?.dateSigned">
                <ng-container *ngIf="subscriptionContract">
                  <div [innerHTML]="subscriptionContract?.legalText"></div>
                </ng-container>
                <signature-pad id="sign_contract_canvas" class="sign-pad w-100" [options]="signaturePadOptions"
                  (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete()" (window:resize)="resizeSignaturePad()">
                </signature-pad>
                <div class="header-button">
                  <button mat-raised-button color="primary" (click)="clearSignPad()">{{'CLEAR' | translate}}</button>
                </div>
              </div>
              <div *ngIf="subscriptionContract?.dateSigned">
                <ng-container *ngIf="subscriptionContract">
                  <div [innerHTML]="subscriptionContract?.legalText"></div>
                  <p>{{'DATE_SIGNED' | translate}} : {{subscriptionContract?.dateSigned | localizedDate: 'shortDate'}}
                  </p>
                </ng-container>
                <img [src]="subscriptionContract?.signature" alt="">
              </div>

              <button *ngIf="subscriptionContract?.signature" mat-raised-button color="accent"
                (click)="proceedToPayment()">
                {{'PROCEED_TO_PAYMENT' | translate}}
              </button>
            </form>

          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </ng-container>

</div>
