import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { EventBusService } from './EventBusService';
import { EventType, UiEvent } from '../event/UiEvent';
import { WorkflowPanelDto, WorkflowTaskDto, WorkflowDefinitionId } from '@savvy/view-definition';
import { ContextIdDto } from '@savvy/quickbooks';
import {SelectListDto} from '@savvy/view-composite/model/selectListDto';

@Component({
  selector: 'app-workflow-panel',
  templateUrl: 'workflowPanel.component.html',
  styleUrls: ['workflowPanel.component.scss'],

})
export class WorkflowPanelComponent implements OnInit {

  @Input() workflowPanelDto: WorkflowPanelDto;
  @Input() viewContext: any;
  @Input() contextIdDto: ContextIdDto;
  @Input() additionalDataMapDto: any;
  @Input() eventBus: EventBusService;

  @Output() taskSelected = new EventEmitter();
  @Output() taskDeselected = new EventEmitter();

  workflowStateList: SelectListDto;
  selectedTask: WorkflowTaskDto;

  ngOnInit(): void {
    this.eventBus.eventAdded$.subscribe(event => this.onEvent(event));
    this.setWorkflowStateList(this.workflowPanelDto.workflowDefinitionId);
  }

  taskSelectionChange(event: MatCheckboxChange, task: WorkflowTaskDto) {

    if (event.checked) {
      this.selectedTask = task;
      this.taskSelected.emit('taskSelected:' + task.workflowTask.taskId.id);
      this.eventBus.addTaskEvent(EventType.TASK_SELECTED, task.workflowTask.taskId);
    } else {
      this.taskDeselected.emit('taskDeselected:' + task.workflowTask.taskId.id);
      this.eventBus.addTaskEvent(EventType.TASK_DESELECTED, task.workflowTask.taskId);
    }
  }

  setWorkflowStateList(workflowDefinitionId: WorkflowDefinitionId) {
    if (workflowDefinitionId) {
      if (this.additionalDataMapDto.workflowStateDataDtoMap[workflowDefinitionId.id] &&
        this.additionalDataMapDto.workflowStateDataDtoMap[workflowDefinitionId.id].selectListDto) {
        this.workflowStateList = this.additionalDataMapDto.workflowStateDataDtoMap[workflowDefinitionId.id].selectListDto;
      }
    }
  }

  private reloadPanel() {

  }

  private onEvent(event: UiEvent) {
    if (event.eventType === EventType.TASK_CREATED) {
      this.reloadPanel();
    } else if (event.eventType === EventType.TASK_UPDATED) {
      this.reloadPanel();
    } else if (event.eventType === EventType.TASK_MARKED_AS_DONE) {
      this.reloadPanel();
    } else if (event.eventType === EventType.TASK_EXECUTED) {
      this.reloadPanel();
    }
  }
}

