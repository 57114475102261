import { Component, Input, OnInit } from '@angular/core';
import { EventBusService } from './EventBusService';
import {
  FlagcompService, ContextIdDto,
  ListFlagsCompResponse,
  ListFlagsForLinkIdComp
} from '@savvy/flags';
import { LinkedIdTypeEnum, LinkId } from 'src/app/core/data-share.service';

@Component({
  selector: 'app-list-customer-flags',
  templateUrl: 'listCustomerFlags.component.html'
})

export class ListCustomerFlagsComponent implements OnInit {

  @Input() customerId: string;
  @Input() contextIdDto: ContextIdDto;

  eventBus = new EventBusService();
  flagsResponse: ListFlagsCompResponse;


  constructor(private flagCompApi: FlagcompService) { }

  ngOnInit() {
    this.listFlags();
  }

  private listFlags() {
    const req = <ListFlagsForLinkIdComp>{};
    req.contextIdDto = this.contextIdDto;

    if (this.customerId) {
      req.linkId = <LinkId>{};
      req.linkId.linkedIdType = LinkedIdTypeEnum.CustomerId;
      req.linkId.linkedId = this.customerId;

      this.flagCompApi.listFlagsForLinkIdComp(req).subscribe(response => {
        console.log('response from list flags:' + response.flagDto.length);
        this.flagsResponse = response;
      });
    }
  }
}

