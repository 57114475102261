<!-- <mat-sidenav-container class="app-inner">
    <mat-sidenav #sidePanel position="end" mode="over" opened="false">
      <ng-container *ngIf="calendarEventType === 'Ei'">
        <app-view-summary-entity fxFlex="930px" fxFlex.lt-md="500px" fxFlex.xs="100vw"
          *ngIf="deviceService.isDesktop() && viewing && eventBus" [contextIdDto]="contextIdDto" [eventBus]="eventBus"
          (changeOccurred)="updateHistory()" [entityInstanceId]="entityInstanceId">
        </app-view-summary-entity>
      </ng-container>
    </mat-sidenav>
  </mat-sidenav-container> -->
<ng-container *ngIf="(contextIdDto && pet) || !loaded">
  <mat-toolbar color="primary" class="mdc-mb-2">
    <div fxFlex fxLayout="row" fxLayoutAlign=" center" *ngIf="pet">
      <div fxFlex="nogrow">
        <button *ngIf="!isViewMode" mat-button (click)="gotoPetList()" class="mdc-px-2">
          <mat-icon>arrow_back</mat-icon>
        </button>
      </div>
      <div fxFlex><span>{{'PET_DETAILS' | translate}}</span></div>
      <span class="example-spacer"></span>
      <div fxFlex="nogrow">
        <button mat-raised-button class="mx-4" (click)="edit()" color="accent">{{'EDIT_PET' | translate}} <mat-icon
            aria-hidden="false" aria-label="Example home icon">edit</mat-icon></button>
        <button *ngIf="!isViewMode && !pet.deleted" mat-raised-button class="mx-4" (click)="softDelete()"
          color="accent">{{'ARCHIVE' | translate}}
          <mat-icon aria-hidden="false" aria-label="Example home icon">delete</mat-icon>
        </button>
      </div>
      <button mat-icon-button aria-label="Close" (click)="cancel()"
        *ngIf="isViewMode && (deviceService.isMobile() || deviceService.isTablet())">
        <mat-icon class="full-screen-icon">close</mat-icon>
      </button>
    </div>
  </mat-toolbar>
  <div [ngClass]="{container: !isViewMode}">
    <div fxLayout="column" *ngIf="pet">
      <app-view-pet-profile *ngIf="pet && contextIdDto" [contextIdDto]="contextIdDto" [pet]="pet" class="mdc-mb-1"
        (loadAppointments)="loadAppointments($event)" (loadPet)="loadPet(petId)"></app-view-pet-profile>
      <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="0px" fxLayoutGap.gt-sm="14px">
        <div fxFlex="100" fxFlex.gt-sm="65" fxFlex.gt-md="70" fxFlex.gt-xl="73">
          <div *ngIf="!pet?.deceased">
            <app-upcoming-appointment [upcomingAppointment]="appointments[0]">
            </app-upcoming-appointment>
          </div>
          <mat-card appearance="outlined" class="mdc-mb-1" *ngIf="!contextService.isPetStay()">
            <app-view-v1-v2-pet-history #viewV1V2PetHistoryComponent [contextIdDto]="contextIdDto" *ngIf="pet"
              [pet]="pet" [addAppointmentAllowed]="true" (openV1Event)="viewV1Appointment($event)"
              [isViewMode]="isViewMode" (loadAppointmentsEvent)="reloadInvoices($event)">
            </app-view-v1-v2-pet-history>
          </mat-card>
          <mat-card appearance="outlined" class="mdc-mb-1"
            *ngIf="contextService.isPetStay() || petStayAddOnActive">
            <mat-card-content class="mdc-pt-2">
              <app-view-pet-boardings #viewPetBoardingsComponent [contextIdDto]="contextIdDto" *ngIf="pet" [pet]="pet"
                [addBoardingAllowed]="true" [isViewMode]="isViewMode" (loadBoardingsEvent)="reloadInvoices($event)">
              </app-view-pet-boardings>
            </mat-card-content>
          </mat-card>

          <app-pet-description [contextIdDto]="contextIdDto" [pet]="pet"></app-pet-description>

          <app-customer-message-details [contextIdDto]="contextIdDto" [customerId]="pet?.petOwnerId">
          </app-customer-message-details>

        </div>
        <div fxFlex="100" fxFlex.gt-sm="33" fxFlex.gt-md="28" fxFlex.gt-xl="25">
          <mat-card appearance="outlined" class=" mdc-mb-1" *ngIf="pet?.petOwnerId">
            <mat-card-content class="mdc-pt-2">
              <app-pet-customer-details [contextIdDto]="contextIdDto" [customerId]="pet?.petOwnerId"
                [isViewMode]="isViewMode">
              </app-pet-customer-details>
            </mat-card-content>
          </mat-card>
          <mat-card appearance="outlined" class="mdc-mb-1">
            <mat-card-content>
              <app-quick-actions-buttons [contextIdDto]="contextIdDto" [customerId]="pet?.petOwnerId" [pet]="pet"
                (petChanged)="petChanged($event)"></app-quick-actions-buttons>
            </mat-card-content>
          </mat-card>
          <mat-card appearance="outlined" class=" mdc-mb-1">
            <mat-card-content class="mdc-pt-2">
              <app-pet-contacts [contacts]="pet.contacts || []" (updateContacts)="updateContacts($event)">
              </app-pet-contacts>
            </mat-card-content>
          </mat-card>
          <mat-card appearance="outlined" class=" mdc-mb-1">

            <mat-tab-group mat-align-tabs="center" disablePagination="true" fitInkBarToContent="false" class="mdc-mb-2">
              <mat-tab label="{{'FILES' | translate}}">
                <app-pet-file-upload [contextIdDto]="contextIdDto" [files]="pet?.files" [isView]="true"
                  [hideHeader]="true">
                </app-pet-file-upload>
              </mat-tab>
              <mat-tab label="{{'CUSTOMERS_FILES' | translate}}">
                <app-pet-customer-file-upload [contextIdDto]="contextIdDto" [files]="pet?.customerFiles">
                </app-pet-customer-file-upload>
              </mat-tab>
            </mat-tab-group>

          </mat-card>

          <app-appointment-invoice-summary #appointmentInvoiceSummaryComponent [contextIdDto]="contextIdDto"
            [customerId]="pet?.petOwnerId">
          </app-appointment-invoice-summary>


          <mat-card appearance="outlined" class="mdc-mb-1">
            <mat-card-header class="mdc-mb-1">
              <div fxLayout="row" fxLayoutAlign=" center">
                <div fxFlex>
                  <h6 class="">Pet {{'FILES' | translate}}</h6>
                </div>
              </div>
            </mat-card-header>
            <mat-card-content>
              <app-upload-files fxFlex="100" #uploadFiles [contextIdDto]="contextIdDto" [savedFiles]="pet?.files"
                (savedFilesChange)="filesUpdated($event)">
              </app-upload-files>
            </mat-card-content>
          </mat-card>

          <mat-card appearance="outlined" class="mdc-mb-1">
            <mat-card-header class="mdc-mb-1">
              <div fxLayout="row" fxLayoutAlign=" center">
                <div fxFlex>
                  <h6 class="">Customer {{'FILES' | translate}}</h6>
                </div>
              </div>
            </mat-card-header>
            <mat-card-content>
              <app-upload-files fxFlex="100" #uploadFiles [contextIdDto]="contextIdDto"
                [savedFiles]="pet?.customerFiles" (savedFilesChange)="customerFilesUpdated($event)">
              </app-upload-files>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</ng-container>


<div class="flexbox-parent" *ngIf="loaded && !pet">
  <div fxLayout="row" fxLayoutAlign="center center" style="height: 60vh;">
    <div style="text-align:center;">
      <app-zero-image name="order"></app-zero-image><br>
      <h5 class="ma-0">{{'PET_DOESNT_EXIST' | translate}}.</h5>
    </div>
  </div>
</div>
