<div class="mdc-px-3 ">
  <div fxLayout="row wrap" fxLayoutGap="5px" fxLayout.gt-sm="row wrap">
    <div fxFlex="100">
      <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="8px">
        <div fxFlex="35">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{'DROP_OFF_DATE' | translate}}</mat-label>
            <!-- {{  this.startDate }} -->
            <input matInput #datePickerInput [(ngModel)]="startDate" id="appointment-start-date" [matDatepicker]="startDatePicker" required (dateChange)="startDateChanged()" (focus)="startDatePicker.open()" placeholder="{{'DROP_OFF_DATE' | translate}}">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>

            <mat-datepicker #startDatePicker (closed)="closedStream($event)" [restoreFocus]="false"></mat-datepicker>
          </mat-form-field>
        </div>
        <div fxFlex="15">
          <mat-form-field *ngIf="timeArray && timeArray.length > 0" appearance="outline" class="w-100">
            <mat-label>{{'DROP_OFF_TIME' | translate}}</mat-label>
            <mat-select placeholder="{{'SELECT_START_TIME' | translate}}" (selectionChange)="startTimeChanged($event.value)" [(ngModel)]="startTime" [compareWith]="compareTimeFn" required [disabled]="disableTimePickup">
              <mat-option *ngFor="let time of timeArray" [value]="time.actualValue">{{time.displayValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>
        <div fxFlex="35">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{'PICKUP_DATE' | translate}} </mat-label>
            <!-- {{  this.endDate }} -->
            <input matInput #datePickerInput [(ngModel)]="endDate" id="appointment-end-date" [matDatepicker]="endDatePicker" required (dateChange)="endDateChanged()" (focus)="endDatePicker.open()" placeholder="{{'PICKUP_DATE' | translate}}" [min]="minDate">
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker (closed)="closedStream($event)" [restoreFocus]="false"></mat-datepicker>
          </mat-form-field>
        </div>
        <div fxFlex="15">
          <mat-form-field *ngIf="timeArray && timeArray.length > 0" appearance="outline" class="w-100" matTooltipPosition="above">
            <mat-label>{{'PICKUP_TIME' | translate}}</mat-label>
            <mat-select placeholder="{{'PICKUP_TIME' | translate}}" (selectionChange)="endTimeChanged( $event.value)" [(ngModel)]="endTime" [compareWith]="compareTimeFn" required [disabled]="disableTimePickup">
              <mat-option *ngFor="let time of timeArray" [value]="time.actualValue">{{time.displayValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>