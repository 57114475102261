<mat-sidenav-container class="app-inner" style="width: 100%" *ngIf="loadViewResponse">
  <mat-sidenav #sidePanel position="end" align="end" class="create-panel" mode="over" opened="false">
    <app-side-panel *ngIf="loadViewResponse.viewContextDto"
                    [viewContextDto]="loadViewResponse.viewContextDto"
                    [eventBus]="eventBus"
                    [contextIdDto]="contextIdDto" [sidePanel]="this.sidePanel"></app-side-panel>
  </mat-sidenav>

  <div fxLayout="row" fxLayoutWrap="wrap" style="width: 100%">
    <app-element *ngIf="loadViewResponse"
                 [elementDto]="loadViewResponse.viewRootElementDto"
                 [contextIdDto]="contextIdDto"
                 [eventBus]="eventBus"
                 [viewContext]="loadViewResponse.viewContextDto"
                 [additionalDataMapDto]="loadViewResponse.additionalDataMapDto"
                 [changeListener]="changeListener"
                 [form]="form" style="width: 100%">

    </app-element>

  </div>

  <button mat-fab color="accent" class="mat-fab-bottom-right" [disabled]="trialExpiredService.isTrialExpired()" (click)="create()" style="z-index: 9;">
    <mat-icon class="mat-24">add</mat-icon>
  </button>


</mat-sidenav-container>


