import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ContextIdDto, PaymentsSummaryDto, SummaryItemDto } from '@savvy/view-definition';
import { NavigateService } from '../entry/NavigateService';

@Component({
  selector: 'app-payments-summary-widget',
  templateUrl: 'paymentsSummaryWidget.component.html'
})
export class PaymentsSummaryWidgetComponent {

  @Input() paymentsSummaryDto: PaymentsSummaryDto;
  @Input() contextIdDto: ContextIdDto;

  constructor(private router: Router, private nav: NavigateService) {
  }

  go(summaryItemDto: SummaryItemDto) {
    console.log('reuse logic from menu to construct');
    this.router.navigate(['/payment/viewPayment',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      summaryItemDto.itemId
    ]);
  }
}
