import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ContextIdDto } from '@savvy/entity-instance-composite';
import { UserDto } from '@savvy/view-definition';
import { PetService } from '@savvy/pet';
import { PetSummaryDto } from '@savvy/pet/model/models';
import { RunSelectorPetStayComponent } from './runSelectorPetStay.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { MatAccordion } from '@angular/material/expansion';
import { DateUtils } from 'src/app/flo/dates/DateUtils';
import { BoardingBooking, PetRun, PetRunBooking, PetRunService, PetStayPreference } from '@savvy/pet-stay';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  CreateBoardingBookingV2SyncService,
  PetRunSelectorData,
  SelectedPetData
} from '../create-boarding-booking-v2-sync.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDialog } from '@angular/material/dialog';
import { PetSearchResultDto } from '@savvy/search';
import { CreatePetDetailsV2Component } from 'src/app/flo/pets/create-pet-details-v2/create-pet-details-v2.component';
import { ViewPetDetailsV2Component } from 'src/app/flo/pets/view-pet-details-v2/view-pet-details-v2.component';
import { ViewCustomerV2Component } from 'src/app/flo/customer/view-customer-v2/view-customer-v2.component';
import { AddCustomerComponent } from 'src/app/flo/customer/customer/addCustomer.component';
import * as _ from 'lodash';
import { TaxesHelperService } from 'src/app/flo/shared/services/taxes-helper.service';
import { Tax } from '@savvy/tax';
import { Subscription } from 'rxjs';
import { UserCurrencyService } from 'src/app/flo/shared/services/userCurrency.service';
import { Customer } from '@savvy/customer';
import { TimeValue } from 'src/app/flo/shared/services/look-and-feel-shared.service';

@Component({
  selector: 'app-pet-run-selector',
  templateUrl: './pet-run-selector.component.html',
  styleUrls: ['./pet-run-selector.component.scss']
})
export class PetRunSelectorComponent implements OnInit, OnDestroy {


  @ViewChild('petTable') petTable: DatatableComponent;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @Input() contextIdDto: ContextIdDto;
  @Input() customerId: string;
  @ViewChild(RunSelectorPetStayComponent, { static: false }) runSelectorPetStayComponent: RunSelectorPetStayComponent;
  @Input() pets: Array<PetSummaryDto> = [];
  @Input() allowedToRemove = true;
  @Input() selectedPetRunId;
  @Input() petStayPreference: PetStayPreference;

  @Input() bookingType: BoardingBooking.BookingTypeEnum;

  @Output() runBookingsChanged: EventEmitter<PetRunBooking[]> = new EventEmitter();
  @Output() updateCustFlags: EventEmitter<boolean> = new EventEmitter();

  runList: Array<PetRun> = [];
  masterRunList: Array<PetRun> = [];
  timeArray: TimeValue[] = [];
  userDtos: Array<UserDto>;
  totalAppointmentDuration = 0;
  step = 0;

  isAllPetsAllocated = false;
  taxDefs: Tax[] = [];
  subs: Subscription[] = [];
  currencyCode: string;

  // key is run id
  applyMultiPetPrice: { [key: string]: boolean } = {};

  constructor(
    private dateUtils: DateUtils,
    private snackBar: MatSnackBar,
    private petService: PetService,
    private spinner: NgxSpinnerService,
    public createBoardingBookingV2SyncService: CreateBoardingBookingV2SyncService,
    public deviceService: DeviceDetectorService,
    private dialog: MatDialog,
    private petRunService: PetRunService,
    private taxesHelperService: TaxesHelperService,
    private userCurrencyService: UserCurrencyService

  ) {

  }

  loadTaxDefs() {
    this.taxesHelperService.getTaxDefs(this.contextIdDto).then(res => {
      this.taxDefs = res;
    });
  }

  filterPetRunList() {
    this.runList = _.cloneDeep(this.masterRunList.filter(run => run.petRunType === this.bookingType));
    if (this.runList?.length) {
      if (this.createBoardingBookingV2SyncService.selectedRuns.length) {
        this.runSelected(this.runList[0], 0);
      } else {
        this.createBoardingBookingV2SyncService.addRunNew(this.contextIdDto);
      }
    }
  }

  ngOnInit(): void {
    this.loadTaxDefs();
    this.getCurrencyCode();
    this.petRunService.loadAllByOwnerIdAndDeleted(false, this.contextIdDto.contextId, this.contextIdDto.contextIdType)
      .subscribe(res => {
        if (res && res.length) {
          this.runList = _.cloneDeep(res);
          this.masterRunList = _.cloneDeep(res);
          if (!this.createBoardingBookingV2SyncService.editMode) {
            this.filterPetRunList();
          }
        }
      });

    if (this.createBoardingBookingV2SyncService.editMode) {
      for (const selectedRun of this.createBoardingBookingV2SyncService.selectedRuns) {
        if (selectedRun?.selectedPetRun) {
          if (selectedRun?.selectedPets?.filter(sp => sp.petId).length > 1) {
            this.applyMultiPetPrice[selectedRun.selectedPetRun.id] = true;
          }
        }
      }
    }

    this.subs.push(this.createBoardingBookingV2SyncService.bookingTypeChanged$.subscribe((res) => {
      if (res) {
        this.bookingType = _.cloneDeep(res);
        this.filterPetRunList();
      }
    }));
  }

  ngOnDestroy(): void {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }

  chosenInAnotherRun(petId: string, rowIndex: number) {
    let chosen = false;
    for (const [index, run] of this.createBoardingBookingV2SyncService.selectedRuns.entries()) {
      if (run.selectedPetRun.id && run.selectedPets.findIndex(p => p.petId === petId) > -1 && rowIndex !== index) {
        chosen = true;
        break;
      }
    }
    return chosen;
  }

  // maxLimitInRun(runId, rowIndex, petId) {
  //   if (this.runList && this.runList.length) {
  //     const run = this.runList.find(r => r.id === runId);
  //     return this.createBoardingBookingV2SyncService.selectedRuns[petId][rowIndex].petIds.length >= run.maxPets
  //       && this.selectedRuns[rowIndex].petIds.indexOf(petId) === -1;
  //   } else {
  //     return false;
  //   }
  // }

  runSelected(selectedPetRun: PetRun, index: number) {
    this.createBoardingBookingV2SyncService.selectedRuns[index].selectedPetRun = selectedPetRun;
    // this.createBoardingBookingV2SyncService.bookingType = selectedPetRun?.petRunType === 'BOARDING' ? BoardingBooking.BookingTypeEnum.Boarding : BoardingBooking.BookingTypeEnum.DayCare;
    this.createBoardingBookingV2SyncService.selectedRuns[index].subTotal = selectedPetRun.price;
    this.createBoardingBookingV2SyncService.selectedRuns[index].grossPrice = selectedPetRun.price;
    const selectedPets: SelectedPetData[] = [];
    if (selectedPetRun?.maxPets) {
      for (let index = 0; index < selectedPetRun?.maxPets; index++) {
        selectedPets.push({
          petId: ''
        });
      }
    }
    this.createBoardingBookingV2SyncService.selectedRuns[index].selectedPets = selectedPets;

    if (this.checkIfValid()) {
      const tempSelectedRuns = Object.assign([], this.createBoardingBookingV2SyncService.selectedRuns);
      this.runBookingsChanged.emit(tempSelectedRuns);
      this.createBoardingBookingV2SyncService.updateQty();
    }
  }

  getAllSelectedPetIds() {
    const allPetIds = [];
    for (const selectedRun of this.createBoardingBookingV2SyncService.selectedRuns) {
      for (const pet of selectedRun.selectedPets) {
        allPetIds.push(pet.petId);
      }
    }
    return allPetIds;
  }

  petSelectionChange(event) {
    this.isAllPetsAllocated = this.pets.length === this.getAllSelectedPetIds().length;
    if (this.checkIfValid()) {
      const tempSelectedRuns = Object.assign([], this.createBoardingBookingV2SyncService.selectedRuns);
      this.runBookingsChanged.emit(tempSelectedRuns);
    }
  }

  runUserSelected(runUser: UserDto, petId, rowIndex) {
    if (this.checkIfValid()) {
      const tempSelectedRuns = Object.assign([], this.createBoardingBookingV2SyncService.selectedRuns);
      this.runBookingsChanged.emit(tempSelectedRuns);
    }
  }

  checkIfValid() {
    return this.createBoardingBookingV2SyncService.checkIfValid();
  }

  startTimeChanged(value: any, row, rowIndex) {
    this.setEndTimeFromStart(value, row);
  }

  setEndTimeFromStart(value: any, row) {
    const durationInMins = 60;
    console.log('got duration in mins ' + durationInMins);
    row.endTime = this.dateUtils.addMinsToTimeString(value, durationInMins);
    console.log('just set endTime to ' + row.endTime);
    if (this.checkIfValid()) {
      const tempSelectedRuns = Object.assign([], this.createBoardingBookingV2SyncService.selectedRuns);
      this.runBookingsChanged.emit(tempSelectedRuns);
    }
  }

  endTimeChanged(value, row) {
    if (value <= row.startTime) {
      row.endTime = row.startTime;
      this.snackBar.open('End time should be greater than start time', 'ok', {
        duration: 3000,
      });
    }
    if (this.checkIfValid()) {
      const tempSelectedRuns = Object.assign([], this.createBoardingBookingV2SyncService.selectedRuns);
      this.runBookingsChanged.emit(tempSelectedRuns);
    }
  }

  compareTimeFn(user1: string, user2: string) {
    // console.log('comparing');
    return user1 && user2 && user1 === user2;
  }

  toggleExpandGroup(group) {
    console.log('Toggled Expand Group!', group);
    this.petTable.groupHeader.toggleExpandGroup(group);
  }

  deleteRun(rowIndex) {
    this.createBoardingBookingV2SyncService.selectedRuns.splice(rowIndex, 1);
    if (this.checkIfValid()) {
      const tempSelectedRuns = Object.assign([], this.createBoardingBookingV2SyncService.selectedRuns);
      this.runBookingsChanged.emit(tempSelectedRuns);
    }
    // this.filterRunList();
  }

  openEditPetModal(pet: PetSearchResultDto) {
    const createPetModal = this.dialog.open(CreatePetDetailsV2Component, {
      data: {
        contextIdDto: this.contextIdDto,
        customerId: pet.petOwnerId,
        petId: pet.petId
      },
      maxWidth: '1024px',
      panelClass: [
        'modal-100',
        'helpwindow',
        'petProfileModal'
      ],
      autoFocus: false
    });

    createPetModal.afterClosed()
      .subscribe(
        () => {
          // this.loadPetFlags(petId);
          // if (this.viewPetFlagsV2Component) {
          //   this.viewPetFlagsV2Component.loadPetFlags(pet.petId);
          // }
        });
  }

  openViewPetModal(pet: PetSearchResultDto) {
    const createPetModal = this.dialog.open(ViewPetDetailsV2Component, {
      data: {
        contextIdDto: this.contextIdDto,
        customerId: pet.petOwnerId,
        petId: pet.petId
      },
      maxWidth: '1024px',
      // maxHeight: '80vh',
      // height: '80vh',
      panelClass: [
        'modal-100',
        'helpwindow',
        'petProfileModal'
      ],
      autoFocus: false
    });

    createPetModal.afterClosed()
      .subscribe(
        () => {

          // this.loadPetFlags(petId);
        });
  }

  openViewCustomerModal(pet: PetSearchResultDto) {
    const createPetModal = this.dialog.open(ViewCustomerV2Component, {
      data: {
        contextIdDto: this.contextIdDto,
        customerId: pet.petOwnerId
      },
      maxWidth: '1024px',
      // maxHeight: '80vh',
      // height: '80vh',
      panelClass: [
        'modal-100',
        'helpwindow',
        'petProfileModal'
      ],
      autoFocus: false
    });

    createPetModal.afterClosed()
      .subscribe(
        () => {
          this.updateCustFlags.emit(true);
        });
  }

  openEditCustomerModal(pet: PetSearchResultDto) {
    if (!pet?.petOwnerId) {
      console.log('No customerId');
      return;
    }
    const createPetModal = this.dialog.open(AddCustomerComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        customerId: pet.petOwnerId,
        isViewMode: true
      },
      maxWidth: '1024px',
      panelClass: [
        'modal-100',
        'helpwindow',
        'scrollable-modal-2'
      ],
      autoFocus: false,
      disableClose: true
    });

    createPetModal.afterClosed()
      .subscribe(
        () => {
          this.updateCustFlags.emit(true);
        });
  }

  updateGrossPriceForRun(petRunSelectorData: PetRunSelectorData) {
    if (this.bookingType === 'BOARDING') {
      if (petRunSelectorData.selectedPetRun?.maxPets > 1
        && petRunSelectorData.selectedPets.filter(sp => sp.petId).length > 1) {
        petRunSelectorData.subTotal = petRunSelectorData.selectedPetRun.multiPetPrice;
        this.applyMultiPetPrice[petRunSelectorData.selectedPetRun.id] = true;
      } else {
        petRunSelectorData.subTotal = petRunSelectorData.selectedPetRun.price;
        this.applyMultiPetPrice[petRunSelectorData.selectedPetRun.id] = false;
      }
    } else {
      if (this.petStayPreference?.bookHourly) {
        petRunSelectorData.subTotal = petRunSelectorData.selectedPetRun.hourlyPrice;
      } else {
        if (petRunSelectorData.selectedPetRun?.maxPets > 1
          && petRunSelectorData.selectedPets.filter(sp => sp.petId).length > 1) {
          petRunSelectorData.subTotal = petRunSelectorData.selectedPetRun.multiPetPrice;
          this.applyMultiPetPrice[petRunSelectorData.selectedPetRun.id] = true;
        } else {
          petRunSelectorData.subTotal = petRunSelectorData.selectedPetRun.price;
          this.applyMultiPetPrice[petRunSelectorData.selectedPetRun.id] = false;
        }
      }
    }
    this.createBoardingBookingV2SyncService.updateGrossPriceForRun(petRunSelectorData);
  }

  selectedPetChanged(petRunSelectorData: PetRunSelectorData) {
    if (this.createBoardingBookingV2SyncService.selectedRuns.filter(sr => sr.selectedPets.filter(sp => sp.petId).length).length === 0) {
      this.createBoardingBookingV2SyncService.customerId = '';
      this.createBoardingBookingV2SyncService.customerName = '';
      this.createBoardingBookingV2SyncService.petList = [];
      return;
    }
    this.createBoardingBookingV2SyncService.customerId = petRunSelectorData?.selectedPets?.[0]?.petOwnerId || '';
    this.createBoardingBookingV2SyncService.customerName = petRunSelectorData?.selectedPets?.[0]?.customerName || '';
    this.updateGrossPriceForRun(petRunSelectorData);
    this.createBoardingBookingV2SyncService.calculateTotalAmount();
  }

  getCurrencyCode() {
    this.userCurrencyService.getDefaultCurrency(this.contextIdDto)
      // .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.currencyCode = res.org.currencyCode ? res.org.currencyCode : this.userCurrencyService.defaultCurrency;
      });
  }



  compareObjects(o1: PetSearchResultDto, o2: PetSearchResultDto): boolean {
    if (o1 && o2) {
      return o1.petId === o2.petId;
    }
    return false;
  }

  compareTax(o1: string, o2: string): boolean {
    if (o1 && o2) {
      return o1 === o2;
    }
    return false;
  }

  addAnotherPetEvent(run: PetRunSelectorData) {
    run.selectedPets.push({
      petId: ''
    });
  }

  updateQty(run: PetRunSelectorData) {
    if (run?.selectedPetRun) {
      this.updateGrossPriceForRun(run);
    }
  }

  updateUnitPrice(run: PetRunSelectorData) {
    this.updateQty(run);
  }

  createCustomer(petRun: PetRunSelectorData) {
    const createPetModal = this.dialog.open(AddCustomerComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        pageType: 'addCustomer',
        isViewMode: true,
        maxPets: petRun.selectedPetRun.maxPets
      },
      maxWidth: '1024px',
      panelClass: [
        'modal-100',
        'helpwindow',
        'scrollable-modal-2'
      ],
      autoFocus: false,
      disableClose: true
    });

    createPetModal.afterClosed()
      .subscribe(
        (newCustomer: Customer) => {
          if (newCustomer) {
            this.createBoardingBookingV2SyncService.customerId = newCustomer.customerId?.id;
            this.createBoardingBookingV2SyncService.customerName = `${newCustomer.firstName} ${newCustomer.lastName}`;
            petRun.selectedPets = [];
            this.petService.loadCustomersPets(
              newCustomer.customerId?.id)
              .subscribe(pets => {
                for (const pet of pets) {
                  petRun.selectedPets.push(this.createBoardingBookingV2SyncService.populateNewPet(pet));
                }
              });
          }
        });
  }

}
