<div class="flexbox-parent" *ngIf="plans && plans.length == 0">
  <div fxLayout="row" fxLayoutAlign="center center" style="height: 60vh;  ">
    <div style="text-align:center;">
      <app-zero-image name="plan"></app-zero-image><br>
      <h5 class="ma-0">{{'NO_PLANS_YET' | translate}}</h5><br>
    </div>
  </div>
</div>
<div *ngIf="plans && plans.length > 0">
  <ngx-datatable class='material has-plus-btn' [rows]="plans" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'">

    <ngx-datatable-column name="{{'NAME' | translate}}" [width]="12">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
        <div>{{ row.customerName }}</div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'PLAN_NAME' | translate}}" [width]="12">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
        {{ row.planName }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'COST' | translate}} / {{'SESSIONS' | translate}}" [width]="14">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
        {{ row.cost }}<br>
        <p class="mat-text-secondary small">{{ row.unlimitedSessions ? 'Unlimited' : row.sessionsRemaining + ' Sessions' }}</p>
      </ng-template>
    </ngx-datatable-column>
    <!-- <ngx-datatable-column name="{{'SESSIONS' | translate}}">
      <ng-template ngx-datatable-cell-template let-row="row">
        {{ row.unlimitedSessions ? 'Unlimited' : row.sessionsRemaining + ' Sessions' }}
      </ng-template>
    </ngx-datatable-column> -->
    <ngx-datatable-column name="{{'PAYMENT_SCHEDULE' | translate}}" [width]="14">
      <ng-template ngx-datatable-cell-template let-row="row">
        {{ row.paymentSchedule === 'IMMEDIATE' ? 'Immediate' : 'Recurring' }}<br>
        <p class="mat-text-secondary small"> {{ row.paymentInterval === 'MONTHLY' ? 'Monthly' : 'Annually' }}</p>
      </ng-template>
    </ngx-datatable-column>
    <!-- <ngx-datatable-column name="{{'PAYMENT_INTERVAL' | translate}}">
      <ng-template ngx-datatable-cell-template let-row="row">
        {{ row.paymentInterval === 'MONTHLY' ? 'Monthly' : 'Annually' }}
      </ng-template>
    </ngx-datatable-column> -->
    <ngx-datatable-column name="{{'NEXT_PAYMENT_DUE' | translate}}" [width]="15">
      <ng-template ngx-datatable-cell-template let-row="row">
        {{ row.nextBillingDate }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'STATUS' | translate}}" [width]="12">
      <ng-template ngx-datatable-cell-template let-row="row">
        <span *ngIf="row.status != 'PAST_DUE'" class="rounded" [class]="row.status == 'ACTIVE' ? 'planStatusActive' : 'planStatusInactive'">{{ row.status == 'ACTIVE' ? 'Active' : 'Inactive' }}</span>
        <span *ngIf="row.status == 'PAST_DUE'" class="rounded" [class]="'planStatusInactive'">{{'PAST_DUE' | translate}}</span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="{{'ACTION' | translate}}" *ngIf="!isModal" [width]="15">
      <ng-template ngx-datatable-cell-template let-row="row">

        <button mat-button color="primary" (click)="viewPlan(row)">
          <mat-icon>visibility</mat-icon> <span>View</span>
        </button>
        <button mat-button (click)="deletePlan(row)">
          <mat-icon>delete</mat-icon> <span>Delete</span>
        </button>

      </ng-template>
    </ngx-datatable-column>

  </ngx-datatable>
</div>