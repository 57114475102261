import { Component, Input, OnInit } from '@angular/core';
import { ContextIdDto } from '@savvy/view-definition';

@Component({
  selector: 'app-booking-confirmation',
  templateUrl: 'bookingConfirmation.component.html'
})
export class BookingConfirmationComponent implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() bookingDef: any;
  @Input() slotDto: any;
  @Input() currencyCode: string;
  @Input() bookingSuccess: boolean;

  constructor() {

  }

  bookAgain() { }

  ngOnInit(): void {
    this.bookingSuccess = true;
  }
}
