import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService, ContextIdDto, ListMessageSummariesResponse, MessageSummaryDto } from '@savvy/messaging';
import { MenuDefinition } from '@savvy/ui';
import MenuItemTypeEnum = MenuDefinition.MenuItemTypeEnum;
import { NavChangeService } from '../element/NavChangeService';
import { Page } from '../shared/model/page';
import { EmailSettingsService } from '@savvy/email-settings';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-sent-messages-page',
  templateUrl: './sentMessagesPage.component.html',
  styleUrls: ['./sentMessagesPage.component.scss']
})
export class SentMessagesPageComponent implements OnInit {

  @Input() showPlaceholder: boolean;
  @Input() showTitle = true;

  sentMessages: MessageSummaryDto[];
  contextIdDto: ContextIdDto;
  selected: MessageSummaryDto[] = [];
  page = new Page();

  spamEmail = false;

  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;

  constructor(
    private api: MessageService,
    private route: ActivatedRoute,
    private emailSettingsService: EmailSettingsService,
    private navChangeService: NavChangeService,
    public deviceService: DeviceDetectorService
  ) {

  }

  ngOnInit() {
    console.log('inside on init students.component.ts');
    this.navChangeService.add(MenuItemTypeEnum.SentMessages);

    this.route.parent.params
      .subscribe(params => {
        if (params['contextId'] && params['contextIdType']) {
          console.log('params changed');
          this.contextIdDto = <ContextIdDto>{};
          this.contextIdDto.contextId = params['contextId'];
          this.contextIdDto.contextIdType = params['contextIdType'];
          this.setPage({ offset: 0, size: 20 });
          this.loadSpam();
        }
      });
    this.route.params
      .subscribe(params => {
        if (params['contextId'] && params['contextIdType']) {
          console.log('params changed');
          this.contextIdDto = <ContextIdDto>{};
          this.contextIdDto.contextId = params['contextId'];
          this.contextIdDto.contextIdType = params['contextIdType'];
          this.setPage({ offset: 0, size: 20 });
          this.loadSpam();
        }
      });
  }


  loadSpam() {
    this.emailSettingsService.getOrCreateEmailSettings(this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(response => {
      this.spamEmail = response.spam;
    })
  }



  /**
   * Populate the table with new data based on the page number
   *
   * @param page The page to select
   */
  setPage(pageInfo) {
    console.log('pageInfo is ' + pageInfo);
    this.page.pageNumber = pageInfo.offset;
    this.listInstances();
  }

  listInstances() {
    console.log('listInstances in SentMessagesPageComponent');
    this.navChangeService.add(MenuItemTypeEnum.SentMessages);
    console.log('page number is ' + this.page.pageNumber);
    console.log('page size is ' + this.page.size);
    this.api.listMessages(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      (this.page.pageNumber + 1) + '',
      this.page.size + '').subscribe(response => {
        this.handleMessages(response);
      });
  }

  onSelect() {
    /* this.router.navigate(['/customer/viewCustomer',
       this.contextIdDto.contextId,
       this.contextIdDto.contextIdType,
       this.selected[0].message.id]);*/
  }

  updateFilter(event) {
  }

  handleMessages(response: ListMessageSummariesResponse) {
    console.log('messages are ' + response.messagesList.length);
    this.sentMessages = response.messagesList;
    this.page.totalElements = response.totalNumMessages;
    this.page.totalPages = response.totalPages;
  }

  onScrollDown(ev) {
    console.log("scrolled down!!", ev);
    this.page.pageNumber++;
    this.api.listMessages(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      (this.page.pageNumber + 1) + '',
      this.page.size + '').subscribe(response => {
        this.sentMessages = [...this.sentMessages, ...response?.messagesList || []];
        this.page.totalElements = response.totalNumMessages;
        this.page.totalPages = response.totalPages;
      });
  }


}
