<div *ngIf="usedMessages && usedMessages.length > 0">
  <mat-toolbar color="primary">{{'SENT_MESSAGES' | translate}}</mat-toolbar>
  <div>
    <ngx-datatable *ngIf="usedMessages" class='material has-plus-btn' [rows]='usedMessages' [headerHeight]="50" [rowHeight]="'auto'" [footerHeight]="50" [columnMode]="'flex'">
      <ngx-datatable-column name="{{'SENT_DATE' | translate}}" [flexGrow]="1">
        <ng-template ngx-datatable-cell-template let-row="row">
          {{row.sentDate | localizedDate: 'mediumDate'}} at {{row.sentTimeLocal | localizedTime:'shortTime'}}
        </ng-template>
      </ngx-datatable-column>
      <!--ngx-datatable-column name="{{'Recipient' | translate}}" [flexGrow]="1">
        <ng-template ngx-datatable-cell-template let-row="row">
          {{ row.recipient }}
        </ng-template>
      </ngx-datatable-column-->
      <ngx-datatable-column name="{{'MESSAGE' | translate}}" [flexGrow]="5">
        <ng-template ngx-datatable-cell-template let-row="row">
          {{ row?.smsMessage?.textMessage }}
        </ng-template>
      </ngx-datatable-column>
      <!--ngx-datatable-column name="{{'Status' | translate}}" [flexGrow]="1">
        <ng-template ngx-datatable-cell-template let-row="row">
          {{ row.status }}
        </ng-template>
      </ngx-datatable-column-->
      <ngx-datatable-column name="{{'CREDITS' | translate}}" [flexGrow]="1">
        <ng-template ngx-datatable-cell-template let-row="row">
          {{ row?.numCredits }}
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>


<div class="flexbox-parent" *ngIf="usedMessages && usedMessages.length === 0">
  <div fxLayout="row" fxLayoutAlign="center center" style="height: 60vh;  ">
    <div style="text-align:center;">
      <app-zero-image name="email"></app-zero-image><br>
      <h5 class="ma-0">{{'NO_SENT_MESSAGES' | translate}}</h5><br>
    </div>
  </div>
</div>