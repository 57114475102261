<mat-list *ngIf="vaccinations?.length; else elseBlockVaccination" class="mdc-p-0">
  <mat-list-item lines="3" *ngFor="let vaccination of vaccinations" (click)="editVaccination(vaccination)">
    <div matListItemIcon class="avatar avatar-50 circle border-3">
      <mat-icon>vaccines</mat-icon>
    </div>
    <div matListItemTitle>{{vaccination.nameOfVaccination}}</div>
    <div matListItemLine>{{'TAKEN_ON' | translate}}: {{vaccination.dateVaccinated | date:'shortDate'}}
      <ng-container *ngIf="vaccination.nextDateDue">, {{'NEXT_DOSE' | translate}}:
        {{vaccination.nextDateDue | localizedDate:'shortDate'}}</ng-container>
    </div>
    <div matListItemLine>
      <span class="">Notes:</span> {{vaccination.notes}}
    </div>
  </mat-list-item>
</mat-list>
<ng-template #elseBlockVaccination>
  <div class="flexbox-parent">
    <div fxLayout="row" fxLayoutAlign="center center">
      <div style="text-align:center;">
        <div class="avatar avatar-100 circle border-3 mb-2 d-inline-block">
          <mat-icon class="h1 h-auto w-auto">vaccines</mat-icon>
        </div>
        <h6 class="mb-2">{{'NO_VACCINATIONS' | translate}}.</h6>
      </div>
    </div>
  </div>
</ng-template>