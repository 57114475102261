

<div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">
  <div fxFlex="{{percent}}"
       *ngFor="let elementDto of horizontalPanelDefinitionDto.elementDtos">

      <app-element style="height: 100%"
                   [elementDto]="elementDto"
                   [form]="form"
                   [contextIdDto]="contextIdDto"
                   [viewContext]="viewContext"
                   [eventBus]="eventBus"
                   [showPlaceholder]="showPlaceholder"
                   [additionalDataMapDto]="additionalDataMapDto"
                   [changeListener]="changeListener">

      </app-element>
  </div>
</div>

<!--
<div class="containerX">
  <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutGap="10px" fxFlexFill class="coloredContainerX box">
    <div fxFlex *ngFor="let elementDto of horizontalPanelDefinitionDto.elementDtos">
      <app-element style="height: 100%"
                   [elementDto]="elementDto"
                   [form]="form"
                   [contextIdDto]="contextIdDto"
                   [viewContext]="viewContext"
                   [eventBus]="eventBus"
                   [showPlaceholder]="showPlaceholder"
                   [additionalDataMapDto]="additionalDataMapDto"
                   [changeListener]="changeListener">

      </app-element>
    </div>
  </div>
</div>
-->
