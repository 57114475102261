import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { Message, MessageService } from '@savvy/messaging';
import { ContextIdDto } from '@savvy/quickbooks';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';

@Component({
  selector: 'app-reply',
  templateUrl: './reply.component.html',
  styleUrls: ['./reply.component.scss']
})
export class ReplyComponent implements AfterViewInit {

  @Input() messageId: string;
  @Input() selectedMessage: Message;
  @Input() contextIdDto: ContextIdDto;
  @Output() cancelClicked = new EventEmitter();

  public replyText: string;

  constructor(
    private api: MessageService,
    private notify: FloSnackbarComponent) { }

  ngAfterViewInit() {

    console.log('ReplyComponent on init, messageId is ' + this.messageId);
  }

  public sendReply() {

    console.log('send reply, quil reply text is ' + this.replyText);

    const req = <any>{};

    req.messageId = this.messageId;
    req.body = this.replyText;
    req.contextId = this.contextIdDto.contextId;
    req.contextIdType = this.contextIdDto.contextIdType;
    this.api.replyMessage(req).subscribe(response => {
      console.log('Got response!');

      // Close this reply window
      this.notify.message = 'Reply Sent';
      this.notify.open();
      this.cancelClicked.emit('');
    });

    // this.api.replyTo(this.messageId, this.quill.getText());
  }

  bodyUpdated(text: string) {
    console.log('body now ' + text);
    this.replyText = text;
  }

  public cancel(event) {
    console.log('close');
    event.stopPropagation();
    this.cancelClicked.emit('');
  }

}
