import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ContextIdDto } from '@savvy/menu';
import { BadgeDto } from '@savvy/view-definition';
import { NavigateService } from '../entry/NavigateService';

@Component({
  selector: 'app-badge-widget',
  templateUrl: 'badgeWidget.component.html'
})
export class BadgeWidgetComponent {

  @Input() badgeDto: BadgeDto;
  @Input() contextIdDto: ContextIdDto;

  constructor(
    router: Router,
    private nav: NavigateService) {
  }

  go() {
    console.log('reuse logic from menu to construct');
    // use NavigateService, and pull logic from menu factory to navigate properly
   // this.nav.navigateTo(this.contextIdDto, this.badgeDto.badgeDefinition.menuDefinition);
  }
}

