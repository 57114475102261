import { Injectable } from '@angular/core';
import { ContextIdDto } from '@savvy/menu';
import { UserId } from '@savvy/ui';

import {
  Clash,
  ClashCheck, DateAndTimeRange,
  RequestedDateAndTimeSlot,
  UserAvailabilityService
} from '@savvy/user-availability';

@Injectable({
  providedIn: 'root'
})
export class PetAppointmentLocalService {

  constructor(
    private userAvailabilityService: UserAvailabilityService,

  ) { }

  clashCheck(userId: string, contextIdDto: ContextIdDto) {

    const slot = {} as RequestedDateAndTimeSlot;
    slot.userId = {} as UserId;
    slot.userId.id = userId;

    // TODO: Set these properly
    slot.dateAndTimeRange = {} as DateAndTimeRange;
    slot.dateAndTimeRange.start = 'yyyy-MM-dd';
    slot.dateAndTimeRange.startTime = 'HH:mm:ss';
    slot.dateAndTimeRange.end = 'yyyy-MM-dd';
    slot.dateAndTimeRange.endTime = 'HH:mm:ss';

    const clashCheck = {} as ClashCheck;
    clashCheck.requestedSlots = new Array<RequestedDateAndTimeSlot>();
    clashCheck.requestedSlots.push(slot);

    let clashes: Clash[] = [];

    this.userAvailabilityService.clashCheckSlots(contextIdDto.contextId,
      contextIdDto.contextIdType, clashCheck).subscribe(response => {
        clashes = response.clashes;
      });
  }

}
