import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContextIdDto } from '@savvy/app';
import { UpdateStateColour, WorkflowdefService } from '@savvy/workflow-definition';
import { Observable } from 'rxjs';
import { ContextService } from '../../context.service';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';
import { WorkflowState } from "@savvy/workflow-definition/model/workflowState";

@Component({
  selector: 'app-add-edit-workflow-state-color-dialog',
  templateUrl: './add-edit-workflow-state-color-dialog.component.html'
})
export class AddEditWorkflowStateColorDialogComponent implements OnInit {

  contextIdDto: ContextIdDto;
  menuItemTypeControl = new UntypedFormControl();
  menuItemTypeOptions: Observable<string[]>;

  workflowState: WorkflowState;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<AddEditWorkflowStateColorDialogComponent>,
    private api: WorkflowdefService,
    private contextService: ContextService,
    public notify: FloSnackbarComponent) { }

  ngOnInit(): void {
    this.contextIdDto = this.data.contextIdDto;
    this.workflowState = this.data.workflowState;
  }

  cancel() {
    this.dialogRef.close({ response: false });
  }

  onColorChange(event) {
    this.workflowState.colour = event;
    // this.updateStateColor();
  }

  updateStateColor() {

    const updateStateRequest: UpdateStateColour = {
      contextIdDto: this.contextIdDto,
      workflowDefinitionId: this.data.workflowDefinitionId,
      stateId: this.workflowState.id,
      colour: this.workflowState.colour
    };
    this.api.updateStateColour(updateStateRequest).subscribe(response => {
      if (response) {
        this.notify.message = 'Color successfully updated.';
        this.notify.open();
      }
    }, () => {
      this.notify.message = 'Failed to update color.';
      this.notify.open();
    }, () => {
      this.dialogRef.close({ response: true });
    });
  }
}
