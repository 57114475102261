<div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="5px">
  <div fxFlex="auto" fxFlex.gt-sm="100">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>{{'APPOINTMENT_DATE' | translate: {Default: 'Appointment Date'} }} </mat-label>
      <!-- {{  this.startDate }} -->
      <input matInput #datePickerInput [(ngModel)]="startDate" id="appointment-start-date" [matDatepicker]="picker" required
        (dateChange)="startDateChanged()" (focus)="picker.open()" placeholder="Start">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>

      <mat-datepicker #picker (closed)="closedStream($event)" [restoreFocus]="false"></mat-datepicker>
    </mat-form-field>
  </div>
</div>
