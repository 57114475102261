<mat-selection-list *ngIf="additionalDataMapDto && additionalDataMapDto.elementDefDataMap
      && additionalDataMapDto.elementDefDataMap[elementDto.selectionListDto.dataSourceElementDefinitionId.id]
      && additionalDataMapDto.elementDefDataMap[elementDto.selectionListDto.dataSourceElementDefinitionId.id].selectListDto"
                    (selectionChange)="selectionChange($event)"
                    [(ngModel)]="selectedOptions" (ngModelChange)="change($event)">

    <mat-list-option
            *ngFor="let item of additionalDataMapDto.elementDefDataMap[elementDto.selectionListDto.dataSourceElementDefinitionId.id].selectListDto.selectItemDtoList"
            [value]="item.id">

        <h3 matLine><b>{{item.label | translate}}</b></h3>
        <!--
        <p matLine>3 hrs / £300</p>
        -->


    </mat-list-option>


</mat-selection-list>
<!--
<mat-list dense>
  <mat-list-item *ngFor="let contact of contacts">
    <h3 matLine>{{contact.name}}</h3>
    <p matLine>{{contact.headline}}</p>
  </mat-list-item>
</mat-list>
-->
