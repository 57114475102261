import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Contract, ContractService } from '@savvy/contract';
import { ContractCompService } from '@savvy/contract';
import { BrandingService } from '@savvy/branding';
import { Page } from '../../shared/model/page';
import { ContractUiDto } from '@savvy/view-composite';
import { ContextIdDto } from '@savvy/view-definition';
import { FloSnackbarComponent } from "../../snackbar/floSnackbar.component";
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-list-contracts',
  templateUrl: './listContracts.component.html',
  styleUrls: ['listContracts.component.scss']
})
export class ListContractsComponent implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() adminMenu = false;
  @Input() unsigned: boolean | null;

  contracts: Array<ContractUiDto> = [];
  page = new Page();

  throttle = 300;
  scrollDistance = 2;
  scrollUpDistance = 2;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: ContractService,
    private brandApi: BrandingService,
    private contractCompositeApi: ContractCompService,
    private notify: FloSnackbarComponent,
    public deviceService: DeviceDetectorService,

  ) { }


  ngOnInit() {
    this.setPage({ offset: 0, size: 5 });
  }

  viewContract(contract: Contract) {
    this.router.navigate([
      '/contract/viewContract',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      contract.id
    ])
  }

  editContract(contract: Contract) {
    if (this.adminMenu) {
      this.router.navigate(['editContract', contract.id], { relativeTo: this.route });
    }

    this.router.navigate([
      '/contract/editContract',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      contract.id
    ]);
  }

  resendContract(contract: Contract) {
    this.contractCompositeApi.sendContractsToCustomer({
      contextIdDto: this.contextIdDto,
      customerId: contract.customerId,
      contractTemplateIds: [contract.contractTemplateId]
    }).subscribe(
      response => {
        this.notify.message = "Contract Resent!";
        this.notify.open();
      },
      error => {
        this.notify.message = "Error Resending Contract";
        this.notify.open();
      }
    )
  }

  setPage(pageInfo) {
    console.log('pageInfo is ' + pageInfo);
    this.page.pageNumber = pageInfo.offset;
    this.loadContracts();
  }

  loadContracts() {
    this.contractCompositeApi.loadContracts(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      (this.page.pageNumber + 1),
      this.page.size, this.unsigned
    ).subscribe(response => {
      this.contracts = response.contractUiDtoList.contents;

      this.page.totalElements = response.contractUiDtoList.totalElements;
      this.page.totalPages = response.contractUiDtoList.totalPages;
    });
  }

  onScrollDown(ev) {
    console.log("scrolled down!!", ev);
    this.page.pageNumber++;
    this.contractCompositeApi.loadContracts(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      (this.page.pageNumber + 1),
      this.page.size, this.unsigned
    ).subscribe(response => {
      this.contracts = [...this.contracts, ...response?.contractUiDtoList?.contents] || [];
      this.page.totalElements = response.contractUiDtoList.totalElements;
      this.page.totalPages = response.contractUiDtoList.totalPages;
    });
  }
}
