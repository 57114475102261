<div *ngIf="flagDefinitions" class="mx-0 mdc-mb-1">
  <div fxLayout="row" fxLayoutAlign="start center">

    <div fxFlex="nogrow">
      <p class="mb-0" *ngIf="include">{{'SEND_WITH_INCLUDED_FLAGS' | translate: {Default: 'Send to all included flags'} }}</p>
      <p class="mb-0" *ngIf="!include">{{'SEND_WITH_EXCLUDED_FLAGS' | translate: {Default: 'Send to all except those with excluded flags'} }}</p>
    </div>
    <div fxFlex="nogrow">
      <button mat-button color="accent" [matMenuTriggerFor]="menu">
        <mat-icon class="vm">add</mat-icon> Add Flag
      </button>
      <mat-menu #menu="matMenu">
        <ng-container *ngFor="let flagDef of flagDefinitions">
          <button color="accent" *ngIf="!flagDef.archived" (click)="addFlag(flagDef)" mat-menu-item>{{ flagDef.name }}</button>
        </ng-container>
      </mat-menu>
    </div>

  </div>
  <div fxLayout="row" fxLayoutAlign="start">
    <mat-chip-listbox *ngIf="selectedFlags">
      <mat-chip-option color="{{selectedFlag.colour}}" *ngFor="let selectedFlag of selectedFlags;let i = index" [attr.data-index]="i" removable="true" (removed)="removeFlag(i)" selected>
        {{selectedFlag.name}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-option>
    </mat-chip-listbox>
  </div>
</div>