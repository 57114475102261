import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Feature } from '@savvy/add-ons';
import { ContractCompService } from '@savvy/contract';
import { IdNameTupleDto } from '@savvy/contract/model/idNameTupleDto';
import {
  CustomerOnboardingDefinition,
  CustomerOnboardingDefinitionService,
  OnboardCustomer
} from '@savvy/customer-onboarding';
import { CustomerPortalSettingsService, PortalSettings } from '@savvy/customer-portal-settings';
import { PlanDefService } from "@savvy/plan-definition";
import { PlanDefinition } from "@savvy/plan-definition/model/planDefinition";
import { ContextIdDto } from '@savvy/ui';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ContractsComponent } from 'src/app/flo/contract/contracts/contracts.component';
import { PaymentPlansComponent } from 'src/app/flo/plan/paymentPlans.component';
import { TermAndConditionCrudComponent } from 'src/app/flo/term-and-conditions/term-and-condition-crud/term-and-condition-crud.component';
import { AddEditPlanDefComponent } from "../../../plan/addEditPlanDef.component";
import { FeaturesHelperService } from '../../services/features-helper.service';

export interface OnboardCustomerEvent {
  isOnboardCustomer: boolean;
  onboard: OnboardCustomer;
}

@Component({
  selector: 'app-onboard-customer',
  templateUrl: 'onboardCustomer.html'
})
export class OnboardCustomerComponent implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Output() onboardUpdated = new EventEmitter<OnboardCustomerEvent>();

  onboard: OnboardCustomer;
  onboardCustomer = true;

  contractTemplates: Array<IdNameTupleDto>;
  planDefinitions: Array<PlanDefinition>;

  customerOnboardingDefinition: CustomerOnboardingDefinition;
  doNotInviteCustomerToPortal = false;
  featureType = Feature.TypeEnum;
  portalSettings: PortalSettings;

  constructor(
    private customerOnboardingDefinitionService: CustomerOnboardingDefinitionService,
    private customerPortalSettingsService: CustomerPortalSettingsService,
    private planDefApi: PlanDefService,
    private contractCompService: ContractCompService,
    private dialog: MatDialog,
    public deviceService: DeviceDetectorService,
    public featuresHelperService: FeaturesHelperService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data?,
    @Optional() public dialogRef?: MatDialogRef<OnboardCustomerComponent>,
  ) {


  }

  ngOnInit() {
    if (this.data && this.data.contextIdDto) {
      this.contextIdDto = this.data.contextIdDto;
    }

    this.customerPortalSettingsService.getOrCreatePortalSettings(this.contextIdDto?.contextId, this.contextIdDto?.contextIdType)
      .subscribe(res => {
        if (res) {
          this.portalSettings = res;
          if (res?.portalEnabled) {
            this.onboard = <OnboardCustomer>{};
            this.onboard.contextIdDto = this.contextIdDto;

            this.customerOnboardingDefinitionService.byOwnerId().subscribe(response => {
              this.customerOnboardingDefinition = response;
              this.loadContractTemplates();
            });
          }
        }
      })

    // this.loadPlanDefinitions(null);
  }

  onboardChanged(event) {
    console.log('onboard changed with event ', event);
    this.onboardCustomer = event.checked;
    this.sendUpdatedData();
  }

  loadContractTemplates() {

    this.contractCompService.loadContractTemplates(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
    ).subscribe(response => {
      this.contractTemplates = response.idNameTupleDtos;
      if (response.idNameTupleDtos &&
        response.idNameTupleDtos.length > 0) {
        this.onboard.contractTemplateId = response.idNameTupleDtos[0].id;
      }
      this.sendUpdatedData();
    });
  }

  loadPlanDefinitions(currentPlanDefId) {

    this.planDefApi.loadByOwnerIdSorted(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType).subscribe(res => {
        this.planDefinitions = res.planDefinitions;

        if (currentPlanDefId) {
          this.onboard.paymentPlanDefinitionId = currentPlanDefId;
        }
      });
  }

  goToPlans() {
    const dialogRef = this.dialog.open(AddEditPlanDefComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        customerOnboarding: true
      },
      width: '90%',
      height: '90%'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      this.loadPlanDefinitions(result);
    });

  }

  contractChanged() {
    this.sendUpdatedData();
  }

  sendUpdatedData() {
    if (this.customerOnboardingDefinition?.inviteCustomerToPortal) {
      const result = <OnboardCustomerEvent>{
        isOnboardCustomer: this.onboardCustomer,
        onboard: this.onboard
      };
      this.onboardUpdated.emit(result);
    }
  }

  showTermsAndConditions() {
    const dialogRef = this.dialog.open(TermAndConditionCrudComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        isModal: true
      },
      panelClass: ['helpwindow', 'scrollable-modal-2']

    });

    dialogRef.afterClosed().subscribe(() => {

    });
  }

  showContracts() {
    const dialogRef = this.dialog.open(ContractsComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        isModal: true
      },
      width: '90%',
      panelClass: ['helpwindow', 'scrollable-modal-2']
      // height: '80%'
    });

    dialogRef.afterClosed().subscribe(() => {

    });
  }

  showPaymentPlans() {
    const dialogRef = this.dialog.open(PaymentPlansComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        isModal: true
      },
      panelClass: ['helpwindow', 'scrollable-modal-2'],
      width: '90%',
      // height: '80%'
    });

    dialogRef.afterClosed().subscribe(() => {

    });
  }

  updateCustomerToPortalSetting() {
    if (!this.portalSettings?.portalEnabled) {
      return;
    }
    const customerOnboardingDefinition: CustomerOnboardingDefinition = this.customerOnboardingDefinition;
    if (this.doNotInviteCustomerToPortal) {
      customerOnboardingDefinition.inviteCustomerToPortal = false;
    }
    this.customerOnboardingDefinitionService.update(customerOnboardingDefinition).subscribe(response => {
      this.customerOnboardingDefinition = response;
    });
  }
}
