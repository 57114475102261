<div *ngIf="adminMenu" class="view-invoice-page" fxLayout="column" fxLayoutAlign="center">
  <form #contractForm="ngForm" *ngIf="contract">
    <app-existing-customer-selector [contextIdDto]="contextIdDto" [customerId]="contract.customerId" (selectedCustomer)="customerSelected($event)" [maxWidth]="100">
    </app-existing-customer-selector>

    <mat-form-field appearance="outline" class="w-100">
      <mat-select placeholder="Select Contract Template" (selectionChange)="templateChange()" [(ngModel)]="contract.contractTemplateId" required name="contractTemplateId" #contractTemplateId="ngModel">
        <mat-option *ngFor="let contractTemplate of response.idNameTupleDtos" [value]="contractTemplate.id">
          {{ contractTemplate.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div fxLayout="row" fxLayoutAlign="center start">
      <div fxFlex="100" class="mt-xs">
        <app-flo-editor [text]="contract.legalText" (textUpdated)="bodyUpdated($event)"></app-flo-editor>
      </div>
    </div>
    <div>
      <button mat-raised-button color="accent" *ngIf="!contract?.id" (click)="addContract()" [disabled]="!contractForm.form.valid" disabled="disabled" class="ml-1">{{'CREATE_CONTRACT' | translate: {Default: 'Create Contract'} }}
      </button>
      <button mat-raised-button color="accent" *ngIf="contract.id" (click)="sendContract()" [disabled]="!contractForm.form.valid" disabled="disabled">{{'SEND_CONTRACT' | translate: {Default: 'Send Contract'} }}
      </button>
      <button mat-raised-button color="accent" *ngIf="contract.id" (click)="viewContract()" [disabled]="!contractForm.form.valid" disabled="disabled" class="ml-1">{{'VIEW_CONTRACT' | translate: {Default: 'View Contract'} }}
      </button>
      <button mat-raised-button color="accent" *ngIf="contract.id" (click)="updateContract()" [disabled]="!contractForm.form.valid" disabled="disabled" class="ml-1">{{'UPDATE_CONTRACT' | translate: {Default: 'Update Contract'} }}
      </button>
      <button mat-raised-button color="accent" *ngIf="!contract.dateClientSigned" (click)="customerSign()" class="ml-1">{{'CUSTOMER_SIGN' | translate: {Default: 'Customer Sign'} }}</button>
      <button mat-raised-button (click)="backToList()" class="ml-1">{{'CANCEL' | translate: {Default: 'Cancel'} }}</button>

    </div>
  </form>

</div>


<div *ngIf="!adminMenu" class="view-invoice-page" fxLayout="column" fxLayoutAlign="center">
  <mat-sidenav-container class="app-inner" *ngIf="response && contract">
    <mat-toolbar color="primary" class="mdc-mb-1">
      <div class="actions" fxFlex="100" fxLayout="row" fxLayoutAlign=" center">
        <div fxFlex="nogrow">
          <button mat-icon-button (click)="backToList()" class="ml-xs ">
            <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">arrow_back</mat-icon>
          </button>
        </div>
        <div fxFlex="grow">
          <h5>{{'EDIT_CONTRACT' | translate: {Default: 'Edit Contract'} }}</h5>
        </div>
        <div fxFlex="nogrow">
          <!--button (click)="editCustomer()" class="ml-xs mat-icon-button" mat-icon-button="">
          <span class="mat-button-wrapper">
          <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">edit</mat-icon>
          </span>
            <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
            <div class="mat-button-focus-overlay"></div>
          </button-->
          <button mat-icon-button (click)="delete()" class="ml-xs ">
            <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">delete</mat-icon>
          </button>
        </div>
      </div>
    </mat-toolbar>




    <mat-sidenav #sendContractPanel [style.display]="showPanel ? 'block' : 'none' " position="end" class="task-panel" mode="over" opened="false" style="width:80%">
      <app-send-contract *ngIf="contract && contract.contractId" [contextIdDto]="contextIdDto" [eventBus]="eventBus" [visible]="true" (contractSent)="contractSend()" (cancel)="cancel()" [contract]="contract"></app-send-contract>
    </mat-sidenav>

    <div class="container" fxFlex="70" fxFlex.xs="100">



      <mat-card appearance="outlined" *ngIf="contract">
        <mat-card-content>
          <form #contractForm="ngForm" class="mdc-mb-1">
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-select placeholder="Select Contract Template" (selectionChange)="templateChange()" [(ngModel)]="contract.contractTemplateId" required name="contractTemplateId" #contractTemplateId="ngModel">
                <mat-option *ngFor="let contractTemplate of response.idNameTupleDtos" [value]="contractTemplate.id">
                  {{ contractTemplate.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <app-flo-editor [text]="contract.legalText" (textUpdated)="bodyUpdated($event)"></app-flo-editor>
          </form>


          <button mat-raised-button color="accent" class="mr-5px mdc-py-1" *ngIf="contract.id == null" (click)="addContract()" [disabled]="!contractForm.form.valid" disabled="disabled">{{'CREATE_CONTRACT' | translate: {Default: 'Create Contract'} }}
          </button>

          <button mat-raised-button color="accent" class="mr-5px mdc-py-1" *ngIf="contract.id" (click)="sendContract()" [disabled]="!contractForm.form.valid" disabled="disabled">{{'SEND_CONTRACT' | translate: {Default: 'Send Contract'} }}
          </button>

          <button mat-raised-button color="accent" class="mr-5px mdc-py-1" *ngIf="contract.id" (click)="viewContract()" [disabled]="!contractForm.form.valid" disabled="disabled">{{'VIEW_CONTRACT' | translate: {Default: 'View Contract'} }}
          </button>

          <button mat-raised-button color="accent" class="mr-5px mdc-py-1" *ngIf="contract.id" (click)="updateContract()" [disabled]="!contractForm.form.valid" disabled="disabled">{{'UPDATE_CONTRACT' | translate: {Default: 'Update Contract'} }}
          </button>
          <button mat-raised-button color="accent" class="mr-5px mdc-py-1" *ngIf="!contract.dateClientSigned" (click)="customerSign()">{{'CUSTOMER_SIGN' | translate: {Default: 'Customer Sign'} }}</button>

        </mat-card-content>
      </mat-card>
    </div>


  </mat-sidenav-container>

</div>