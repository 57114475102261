<div *ngIf="showSpinner" class="bg-spinner-wrapper">
  <mat-spinner style="margin: 0 auto;"></mat-spinner>
</div>
<div style="width: 60vw; max-height: 80vh" *ngIf="messageRecipient && messageTypes">
  <mat-toolbar color="primary" class="mdc-mb-1">
    <mat-toolbar-row>{{'SEND_MESSAGE' | translate}}</mat-toolbar-row>
  </mat-toolbar>
  <div class="mdc-px-3">
    <form #msgForm="ngForm" class="mdc-mb-0">
      <div class="mdc-mb-1">
        <mat-radio-group name="radio" [(ngModel)]="selectedMessageType">
          <mat-radio-button style="margin: 10px 10px 15px 0;" *ngFor="let messageType of messageTypes"
            [value]="messageType">
            {{messageType}}
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <!-- Email Message -->
      <mat-form-field *ngIf="selectedMessageType === 'Email'" appearance="outline" style="width: 100%">
        <mat-label>{{'TO' | translate}}</mat-label>
        <input type="text" matInput name="recipient" #recipient="ngModel" [(ngModel)]="emailAddresses"
          placeholder="{{'TO' | translate}}" required>
        <mat-error *ngIf="recipient.hasError('required')">
          {{'TO_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
        </mat-error>
      </mat-form-field>
      <br>

      <!-- SMS Message -->
      <div *ngIf="selectedMessageType === 'SMS'">

        <!--mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{'From' | translate}}</mat-label>
            <input type="text" matInput name="from" #from="ngModel" [(ngModel)]="smsSenderId" placeholder="{{'From' | translate}}" required appSenderidValidator>
            <mat-error *ngIf="from.hasError('required')">
              {{'From is' | translate}} <strong>{{'required' | translate}}</strong>
            </mat-error>
            <mat-error *ngIf="from.hasError('senderIdValidator') && !from.hasError('required')">
              {{'Invalid from value.  SMS from value can be up to 11 characters from the following categories: Upper-case letters A-Z, Lower-case letters a-z, Numbers 0-9, Spaces.  It must include at least one letter; It cannot be comprised of only numbers. Special characters and punctuation are not allowed.' | translate}}
            </mat-error>
          </mat-form-field-->

        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>{{'TO' | translate}}</mat-label>
          <input type="text" matInput name="smsNumber" #smsNumber="ngModel" [(ngModel)]="smsNumbers"
            placeholder="{{'TO' | translate}}" required>
          <mat-error *ngIf="smsNumber.hasError('required')">
            {{'TO_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-label>{{'MESSAGE' | translate}}</mat-label>
          <textarea matInput #msgTxt="ngModel" name="msgTxt" required [(ngModel)]="messageText"
            placeholder="{{'ENTER_MESSAGE' | translate}}"></textarea>
          <mat-error *ngIf="msgTxt.hasError('required')">
            {{'MESSAGE_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <ng-container *ngIf="selectedMessageType === messageTypes[0]">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>{{'SUBJECT' | translate}}</mat-label>
          <input matInput placeholder="{{'SUBJECT' | translate}}" name="subjectFieldName" #subjectFieldName="ngModel"
            [(ngModel)]="subject" [required]="true" id="subjectFieldName" required>
          <mat-error *ngIf="subjectFieldName.hasError('required')">
            {{'SUBJECT_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
          </mat-error>
        </mat-form-field>

        <app-pet-quick-links-editor [(editorContent)]="body" messageType="EMAIL"> </app-pet-quick-links-editor>
        <app-upload-files #uploadFiles [contextIdDto]="contextIdDto" [savedFiles]="savedFiles"></app-upload-files>
      </ng-container>
    </form>

    <button mat-raised-button class="mr-5px mb-1" color="accent" (click)="send()"
      [disabled]="!msgForm.valid || !checkIfAllowedToSend() || !messageDefined()">{{'SEND' | translate}}</button>
    <button mat-raised-button class="mb-1" (click)="dialogRef.close(false)">{{'CANCEL' | translate}}</button>

    <div *ngIf="!checkIfAllowedToSend()" class="mdc-py-2">
      <mat-error>{{'CANT_SEND_MESSAGE_ONE' | translate}}.</mat-error>
    </div>
  </div>