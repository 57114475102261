import { Component, Input, OnInit } from '@angular/core';
import { ContextIdDto } from '@savvy/invoice';
import { InvoicecompService, InvoicePaymentDetailsDto } from '@savvy/invoice';
import { RequestForPayment } from '@savvy/request-for-payment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PhoneNumberHelperService } from 'src/app/flo/shared/services/phone-number-helper.service';
import { UserCurrencyService } from '../../services/userCurrency.service';

@Component({
  selector: 'app-pending-amount',
  templateUrl: './pending-amount.component.html',
  styleUrls: ['./pending-amount.component.scss']
})
export class PendingAmountComponent implements OnInit {
  @Input() contextIdDto: ContextIdDto;
  @Input() totalAmount;
  totalPendingAmount = 0;
  totalPaidAmount = 0;
  currencyCode = this.userCurrencyService.defaultCurrency;
  destroy$ = new Subject();
  private localPaymentRequests = [];

  @Input() public set paymentRequests(paymentRequests: RequestForPayment[]) {
    if (paymentRequests && paymentRequests.length) {
      this.localPaymentRequests = paymentRequests;
      this.preparePaymentSummary();
    }
  }
  public get paymentRequests(): RequestForPayment[] {
    return this.localPaymentRequests;
  }

  constructor(
    private invoicecompService: InvoicecompService,
    private sharedService: PhoneNumberHelperService,
    private userCurrencyService: UserCurrencyService

  ) { }

  ngOnInit() {
    this.getCurrencyCode();
    this.preparePaymentSummary();
  }

  loadInvoicePayments(paymentRequestsId: string) {
    return new Promise<InvoicePaymentDetailsDto>(resolve => {
      this.invoicecompService.loadPaymentRequest(this.contextIdDto.contextId, this.contextIdDto.contextIdType, paymentRequestsId)
        .subscribe(res => {
          resolve(res.invoicePaymentDetailsDto);
        });
    });
  }

  async preparePaymentSummary() {
    let totalPaidAmount = 0;
    let totalPendingAmount = 0;
    for await (const paymentRequest of this.paymentRequests) {
      if (paymentRequest.id) {
        await this.loadInvoicePayments(paymentRequest.id).then(res => {
          if (paymentRequest.paid) {
            totalPaidAmount += res.amountToPayInPounds;
          } else {
            totalPendingAmount += res.amountToPayInPounds;
          }
        });
      }
    }
    this.totalPaidAmount = totalPaidAmount;
    this.totalPendingAmount = totalPendingAmount;
  }

  getCurrencyCode() {
    this.userCurrencyService.getDefaultCurrency(this.contextIdDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.currencyCode = res.org.currencyCode ? res.org.currencyCode : this.userCurrencyService.defaultCurrency;
      });
  }

}
