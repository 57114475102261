<div *ngIf="contractTemplates && contractTemplates.length > 0">
  <ngx-datatable *ngIf="contractTemplates" class='material mdc-mb-2' [rows]='contractTemplates' [columnMode]="'force'" [headerHeight]="50" [rowHeight]="'auto'" [footerHeight]="50" [selected]="selected" [selectionType]="'single'" (select)="onSelect($event)">
    <ngx-datatable-column name="{{'NAME' | translate: {Default: 'Name'} }}" [width]="80">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
        {{ row.name }}
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
  <button mat-raised-button color="accent" (click)="create()">{{'ADD_CONTRACT_TEMPLATE' | translate: {Default: 'Add Contract Template'} }}</button>
</div>

<div class="flexbox-parent" *ngIf="contractTemplates && contractTemplates.length === 0">
  <div fxLayout="row" fxLayoutAlign="center center">
    <div style="text-align:center;">
      <div class="avatar avatar-100 circle border-3 mdc-mb-1 d-inline-block mt-3">
        <mat-icon style="font-size: 40px; width: 40px; height: 40px;">verified_user</mat-icon>
      </div>
      <h3 class="mdc-mb-1">{{"YOU_DONT_CONTRACT_TEMPLATES" | translate: {Default: 'You don\'t have any contract templates'} }}</h3>
      <button mat-flat-button color="accent" (click)="create()">{{'CREATE' | translate: {Default: 'Create'} }}</button>
    </div>
  </div>
</div>