import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FloMaterialModule } from '../flo.material.module';
import { AppInvoiceModule } from '../invoice/invoice.module';
import { petsRoutes } from './pet.routing';

import { SharedModule } from '../shared/shared.module';

import { TranslateModule } from '@ngx-translate/core';
import { AlphabetFilterModule } from '@savvy/alpha-filter';
import { PetService } from '@savvy/pet';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxPhotoEditorModule } from 'ngx-photo-editor';
import { AppCustomerModule } from '../customer/customer.module';
import { ElementModule } from '../element/element.module';
import { AppFlagsModule } from '../flags/flags.module';
import { PipesModule } from '../pipes/pipes.module';
import { AppointmentNotesComponent } from './appointmentNotes.component';
import { AppointmentNotesV2Component } from './appointmentNotesV2.component';
import { CreatePetDetailsV2Component } from './create-pet-details-v2/create-pet-details-v2.component';
import { DeceasedConfirmationComponent } from './create-pet-details-v2/deceased-confirmation/deceased-confirmation.component';
import { PetDetailsComponent } from './create-pet-details-v2/pet-details/pet-details.component';
import { PetFileUploadComponent } from './create-pet-details-v2/pet-file-upload/pet-file-upload.component';
import { PetMedicalDetailsComponent } from './create-pet-details-v2/pet-medical-details/pet-medical-details.component';
import { PetProfileComponent } from './create-pet-details-v2/pet-profile/pet-profile.component';
import { CreatePetV2ExistingCustomerComponent } from './create-pet-v2/create-pet-v2-existing-customer.component';
import { CreatePetV2NewCustomerComponent } from './create-pet-v2/create-pet-v2-new-customer.component';
import { CreatePetV2Component } from './create-pet-v2/create-pet-v2.component';
import { DailyPetsComponent } from './dailyPets.component';
import { ExportPetsComponent } from './exportPets.component';
import { FilterPetTypeSelectorComponent } from "./filter-pet-type-selector/filter-pet-type-selector.component";
import { ListPetsComponent } from './listPets.component';
import { ListPetsAlphaComponent } from "./listPetsAlpha.component";
import { ListPetsGridComponent } from "./listPetsGrid.component";
import { MergePetSuccessComponent } from './merge-pet-success/merge-pet-success.component';
import { MergePetModalComponent } from './merge-pet/merge-pet-modal.component';
import { OverdueGroomsPetsComponent } from './overdue-grooms-pets.component';
import { PetBreedSelectorComponent } from "./pet-breed-selector/pet-breed-selector.component";
import { PetSelectorInCustomerComponent } from './pet-selector/petSelectorInCustomer.component';
import { PetFlagsPanelComponent } from './petFlagsPanel.component';
import { PetHistoryComponent } from './petHistory.component';
import { PetNotesComponent } from './petNotes.component';
import { AddEditPetContactComponent } from './view-pet-details-v2/pet-contacts/add-edit-pet-contact/add-edit-pet-contact.component';
import { PetContactsComponent } from './view-pet-details-v2/pet-contacts/pet-contacts.component';
import { PetCustomerDetailsComponent } from './view-pet-details-v2/pet-customer-details/pet-customer-details.component';
import { PetCustomerFileUploadComponent } from './view-pet-details-v2/pet-customer-file-upload/pet-customer-file-upload.component';
import { PetDescriptionComponent } from './view-pet-details-v2/pet-description/pet-description.component';
import { PetVaccinationListComponent } from './view-pet-details-v2/pet-description/pet-vaccination-list/pet-vaccination-list.component';
import {
  PetVaccinationsComponent
} from './view-pet-details-v2/pet-description/pet-vaccinations/pet-vaccinations.component';
import { ViewBoardingPetHistoryComponent } from "./view-pet-details-v2/view-boarding-pet-history/view-boarding-pet-history.component";
import { ViewPetDetailsV2Component } from './view-pet-details-v2/view-pet-details-v2.component';
import { ViewPetProfileComponent } from './view-pet-details-v2/view-pet-profile/view-pet-profile.component';
import { ViewPetV2Component } from './view-pet-v2/view-pet-v2.component';
import { QuickActionsButtonsComponent } from './view-pet-details-v2/quick-action-buttons/quick-actions-buttons.component';


@NgModule({
  imports: [
    CommonModule,
    AppInvoiceModule,
    ColorPickerModule,
    TranslateModule,
    AppCustomerModule,
    AppFlagsModule,
    SharedModule,
    NgxFileDropModule,
    RouterModule.forChild(petsRoutes),
    FloMaterialModule,
    NgxPhotoEditorModule,
    ElementModule,
    AlphabetFilterModule,
    PipesModule
  ],
  declarations: [
    AppointmentNotesComponent,
    AppointmentNotesV2Component,
    PetNotesComponent,
    DailyPetsComponent,
    FilterPetTypeSelectorComponent,
    PetBreedSelectorComponent,
    ListPetsComponent,
    ListPetsAlphaComponent,
    ListPetsGridComponent,
    PetHistoryComponent,
    ExportPetsComponent,
    PetFlagsPanelComponent,
    CreatePetV2Component,
    CreatePetV2ExistingCustomerComponent,
    CreatePetV2NewCustomerComponent,
    ViewPetV2Component,
    CreatePetDetailsV2Component,
    ViewPetDetailsV2Component,
    PetProfileComponent,
    PetDetailsComponent,
    ViewPetProfileComponent,
    OverdueGroomsPetsComponent,
    PetCustomerDetailsComponent,
    PetDescriptionComponent,
    PetVaccinationsComponent,
    PetMedicalDetailsComponent,
    PetVaccinationListComponent,
    DeceasedConfirmationComponent,
    PetFileUploadComponent,
    PetContactsComponent,
    AddEditPetContactComponent,
    PetCustomerFileUploadComponent,
    PetSelectorInCustomerComponent,
    MergePetModalComponent,
    MergePetSuccessComponent,
    ViewBoardingPetHistoryComponent,
    QuickActionsButtonsComponent
  ],
  providers: [
    PetService
  ],
  exports: [
    PetSelectorInCustomerComponent,
    ExportPetsComponent,
    PetFlagsPanelComponent
  ]
})

export class AppPetModule { }
