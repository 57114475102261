import { Component, Input, OnInit } from '@angular/core';
import { ContextIdDto } from '@savvy/view-definition';

@Component({
  selector: 'app-book-summary',
  templateUrl: 'bookSummary.component.html'
})
export class BookSummaryComponent implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() bookingDef: any;

  constructor() {

  }

  ngOnInit(): void {
  }
}
