import { Injectable } from '@angular/core';

export class AuthData {
  access_token?: string;
  token_type?: string;
  refresh_token?: string;
  expires_in?: number;
  scope?: string;
  employerOrgId?: string;
  timeZoneId?: string;
  subscriptionDefinitionId?: string;
  envRoles?: string;
  userFullName?: string;
  userFirstName?: string;
  languageTag?: string; // This is actually a full en-GB
  activePayingSubscription?: boolean;
  envId: string;
  userName?: string;
  userId?: string;
  userLastName?: string;
  countryCode?: string;
  orgRoles?: string;
  subscriptionId?: string;
  currencyCode?: string;
  jti?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  constructor() { }

  setAuthData(authData: AuthData) {
    sessionStorage.setItem('authData', JSON.stringify(authData));
    sessionStorage.setItem('envId', authData.envId);
  }

  getAuthData(): AuthData {
    return JSON.parse(sessionStorage.getItem('authData') || '{}');
  }

  setTokenAfterRegistration(token: string) {
    if (token) {
      this.clear();
      const authData: AuthData = this.getAuthData();
      authData.access_token = token;
      this.setAuthData(authData);
    }
  }

  getToken(): string | null {
    const authData: AuthData = JSON.parse(sessionStorage.getItem('authData') || '{}');
    if (Object.keys(authData)?.length > 0) {
      return authData.access_token;
    }
    return null;
  }

  clear() {
    sessionStorage.clear();
  }

  getItem(variable: string) {
    return sessionStorage.getItem(variable);
  }

  setItem(variable: string, value: string) {
    return sessionStorage.setItem(variable, value);
  }

  removeItem(variable: string) {
    return sessionStorage.removeItem(variable);
  }

  setDefaultLocation(locationId: string) {
    sessionStorage.setItem('defaultLocationId', locationId);
  }

  getDefaultLocation(): string {
    return sessionStorage.getItem('defaultLocationId') || '';
  }

  resetDefaultLocation() {
    sessionStorage.removeItem('defaultLocationId');
  }
}
