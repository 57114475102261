<mat-toolbar color="primary">
  <mat-toolbar-row>
    {{'OVERDUE_GROOMS' | translate}}
  </mat-toolbar-row>
</mat-toolbar>
<mat-tab-group>
  <mat-tab label="{{'OVERDUE_GROOMS' | translate}}">
    <div fxLayout="row wrap" *ngIf="deviceService.isMobile() || deviceService.isTablet()"
      class="search-results mdc-px-2" infiniteScroll [infiniteScrollDistance]="scrollDistance"
      [infiniteScrollUpDistance]="scrollUpDistance" [scrollWindow]="false" [infiniteScrollThrottle]="throttle"
      (scrolled)="onScrollDown($event)">
      <!-- mat-card repeat -->
      <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="33" *ngFor="let row of overduePets">
        <mat-card appearance="outlined" class="mdc-mb-1">
          <mat-card-header>
            <div fxLayout="row wrap" class="w-100">
              <div fxFlex>
                <mat-card-title>{{ row.nextDueDate | localizedDate:'mediumDate' }}</mat-card-title>
                <mat-card-subtitle>Last Appointment: <b>{{ row.prevApptDate | localizedDate:'mediumDate'
                    }}</b></mat-card-subtitle>
              </div>
              <div fxFlex="nogrow">
                <mat-chip-option color="primary">
                  <span *ngIf="row.numDaysOverdue <= 21">{{row.numDaysOverdue}}</span>
                  <span *ngIf="row.numDaysOverdue > 21 && row.numDaysOverdue <= 42">{{row.numDaysOverdue}}</span>
                  <span *ngIf="row.numDaysOverdue > 42">{{row.numDaysOverdue}}</span> &nbsp;<small>days
                    ago</small></mat-chip-option>
              </div>
            </div>
          </mat-card-header>
          <mat-card-content>
            <div fxLayout="row wrap" class="w-100" fxLayoutGap="8px">
              <div fxFlex="100%">
                <h6 class="mb-0 cp" (click)="viewPet(row)">{{ row.name }} ({{ row.breedLabel }}) </h6>
                <p class="mat-text-secondary mdc-mb-0" (click)="viewCustomer(row)">{{ row.petOwnerFirstName }}
                  {{row.petOwnerLastName}} </p>
              </div>
              <div fxFlex class="my-2">
                <!-- if available -->
                <app-pets-flags-panel [flagIds]="row.flags" [disableCreate]="true" [disableDelete]="true"
                  [contextIdDto]="contextIdDto" class="d-inline-block mdc-py-1" style="margin-right: 8px">
                </app-pets-flags-panel>
                <app-view-customer-flags [contextIdDto]="contextIdDto" [customerId]="row.petOwnerId"
                  class="d-inline-block mdc-py-1" style="margin-right: 8px">
                </app-view-customer-flags>
                <app-view-customer-stats *ngIf="row.petOwnerId" [contextIdDto]="contextIdDto" [customerId]="row.petOwnerId"
                  class="d-inline-block mdc-py-1">
                </app-view-customer-stats>
              </div>
            </div>
            <p class="mdc-mb-2">{{ row.numOverdueMessagesSent }} Reminders sent</p>

            <button mat-raised-button color="primary" class="mr-5px"
              (click)="sendMessage(row); $event.stopPropagation();"><mat-icon>mail</mat-icon> Send Message</button>
            <button mat-raised-button class="mat-text-red"
              (click)="ignoreThisPet(row)"><mat-icon>close</mat-icon>Ignore</button>
          </mat-card-content>
        </mat-card>
      </div>
      <!-- mat-card repeat ends -->
    </div>

    <ngx-datatable
      *ngIf="!deviceService.isMobile() && !deviceService.isTablet() && overduePets && overduePets.length > 0"
      class='material mdc-mx-1 mdc-mb-2' [rows]="overduePets" [columnMode]="'force'" [scrollbarH]="true"
      [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [externalPaging]="true"
      [count]="overDuePage.totalElements" [offset]="overDuePage.pageNumber" [loadingIndicator]="overDueLoading"
      [limit]="overDuePage.size" (page)='setOverDuePage($event)'>
      <ngx-datatable-column name="{{'PET' | translate}}" [width]="200">
        <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
          <div (click)="viewPet(row)" style="cursor: pointer;">{{ row.name }} ({{ row.breedLabel }})</div>

          <app-pets-flags-panel [contextIdDto]="contextIdDto" [flagIds]="row.flags" [disableCreate]="true"
            [disableDelete]="true">
          </app-pets-flags-panel>

        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'CUSTOMER' | translate}}" [width]="200">
        <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
          <div (click)="viewCustomer(row)" style="cursor: pointer;">
            {{ row.petOwnerFirstName }} {{ row.petOwnerLastName}}
            <app-view-customer-flags [contextIdDto]="contextIdDto" [customerId]="row.petOwnerId">
            </app-view-customer-flags>
            <app-view-customer-stats [contextIdDto]="contextIdDto" [customerId]="row.petOwnerId" *ngIf="row.petOwnerId">
            </app-view-customer-stats>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'NEXT_DUE_DATE' | translate}}" [width]="200">
        <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
          {{ row.nextDueDate | localizedDate:'mediumDate' }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'DAYS_OVERDUE' | translate}}" [width]="100">
        <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
          <span *ngIf="row.numDaysOverdue <= 21" matBadge="{{row.numDaysOverdue}}" matBadgeOverlap="false"></span>
          <span *ngIf="row.numDaysOverdue > 21 && row.numDaysOverdue <= 42" matBadge="{{row.numDaysOverdue}}"
            matBadgeColor="accent" matBadgeOverlap="false"></span>
          <span *ngIf="row.numDaysOverdue > 42" matBadge="{{row.numDaysOverdue}}" matBadgeOverlap="false"
            matBadgeColor="warn"></span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'LAST_APPT' | translate}}" [width]="200">
        <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
          {{ row.prevApptDate | localizedDate:'mediumDate' }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'SENT' | translate}}" [width]="100">
        <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
          {{ row.numOverdueMessagesSent }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'ACTIONS' | translate}}" [width]="230">
        <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
          <button class="mr-5px" mat-button color="primary"
            (click)="sendMessage(row); $event.stopPropagation();"><mat-icon>send</mat-icon> {{'SEND_MESSAGE' |
            translate}}</button>
          <button class="mr-5px" mat-button (click)="ignoreThisPet(row)"><mat-icon>change_circle</mat-icon> {{'IGNORE' |
            translate}}</button>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>

    <mat-card appearance="outlined" *ngIf="overduePets && overduePets.length === 0">
      {{'NO_OVERDUE_PETS' | translate}}
    </mat-card>

  </mat-tab>
  <mat-tab label="{{'IGNORED_OVERDUE' | translate}}">
    <div fxLayout="row wrap" *ngIf="deviceService.isMobile() || deviceService.isTablet()"
      class="search-results mdc-px-2" infiniteScroll [infiniteScrollDistance]="scrollDistance"
      [infiniteScrollUpDistance]="scrollUpDistance" [scrollWindow]="false" [infiniteScrollThrottle]="throttle"
      (scrolled)="onScrollDownIgnored($event)">
      <!-- mat-card repeat -->
      <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="33" *ngFor="let row of ignoredPets">
        <mat-card appearance="outlined" class="mdc-mb-1">
          <mat-card-header>
            <div fxLayout="row wrap" class="w-100">
              <div fxFlex>
                <mat-card-title>{{ row.nextDueDate | localizedDate:'mediumDate' }}</mat-card-title>
                <mat-card-subtitle>Last Appointment: <b>{{ row.prevApptDate | localizedDate:'mediumDate'
                    }}</b></mat-card-subtitle>
              </div>
              <div fxFlex="nogrow">
                <mat-chip-option color="primary">
                  <span *ngIf="row.numDaysOverdue <= 21">{{row.numDaysOverdue}}</span>
                  <span *ngIf="row.numDaysOverdue > 21 && row.numDaysOverdue <= 42">{{row.numDaysOverdue}}</span>
                  <span *ngIf="row.numDaysOverdue > 42">{{row.numDaysOverdue}}</span> &nbsp;<small>days
                    ago</small></mat-chip-option>
              </div>
            </div>
          </mat-card-header>
          <mat-card-content>
            <div fxLayout="row wrap" class="w-100" fxLayoutGap="8px">
              <div fxFlex="100%">
                <h6 class="mb-0 cp" (click)="viewPet(row)">{{ row.name }} ({{ row.breedLabel }})</h6>
                <p class="mat-text-secondary cp mb-0" (click)="viewCustomer(row)">{{ row.petOwnerFirstName }}
                  {{row.petOwnerLastName}}</p>
              </div>
              <div fxFlex class="mdc-mb-0">
                <app-pets-flags-panel class="d-inline-block mdc-py-1" style="margin-right: 8px"
                  [contextIdDto]="contextIdDto" [flagIds]="row.flags" [disableCreate]="true" [disableDelete]="true">
                </app-pets-flags-panel>
                <app-view-customer-flags [contextIdDto]="contextIdDto" [customerId]="row.petOwnerId"
                  class="d-inline-block mdc-py-1" style="margin-right: 8px">
                </app-view-customer-flags>
                <app-view-customer-stats [contextIdDto]="contextIdDto" [customerId]="row.petOwnerId" *ngIf="row.petOwnerId"
                  class="d-inline-block mdc-py-1">
                </app-view-customer-stats>
              </div>
            </div>
            <p class="mdc-mb-2">{{ row.numOverdueMessagesSent }} Reminders sent</p>

            <button mat-raised-button color="accent" (click)="restoreThisPet(row)" style="cursor: pointer;">
              {{'RESTORE_PET' | translate}}
            </button>
          </mat-card-content>
        </mat-card>
      </div>
      <!-- mat-card repeat ends -->
    </div>

    <ngx-datatable
      *ngIf="!deviceService.isMobile() && !deviceService.isTablet() && ignoredPets && ignoredPets.length > 0"
      class='material mdc-mx-1 mdc-mb-2' [rows]="ignoredPets" [columnMode]="'force'" [headerHeight]="50"
      [footerHeight]="50" [rowHeight]="'auto'" [externalPaging]="true" [count]="ignoredPage.totalElements"
      [offset]="ignoredPage.pageNumber" [loadingIndicator]="ignoredLoading" [limit]="ignoredPage.size"
      (page)='setIgnoredPage($event)'>

      <ngx-datatable-column name="{{'PET' | translate}}" [width]="80">
        <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
          <div (click)="viewPet(row)" style="cursor: pointer;">{{ row.name }}</div>

        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'RESTORE' | translate}}" [width]="80">
        <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
          <button mat-button (click)="restoreThisPet(row)" style="cursor: pointer;"><mat-icon>restore_page</mat-icon>
            {{'RESTORE_PET' | translate}}</button>
        </ng-template>
      </ngx-datatable-column>

    </ngx-datatable>

    <mat-card appearance="outlined" *ngIf="ignoredPets && ignoredPets.length == 0">
      {{'NO_OVERDUE_PETS' | translate}}
    </mat-card>
  </mat-tab>
</mat-tab-group>
