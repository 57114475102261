<mat-card appearance="outlined" class="demo-card demo-basic" *ngIf="embeddedForm">
  <mat-card-content #topLevelElement>

    <app-element *ngIf="loadEiViewResponse && loadEiViewResponse.viewRootElementDto"

                 [elementDto]="loadEiViewResponse.viewRootElementDto"
                 [form]="embeddedForm"
                 [eventBus]="eventBus"
                 [contextIdDto]="contextIdDto"
                 [viewContext]="loadEiViewResponse.viewContextDto"
                 [additionalDataMapDto]="loadEiViewResponse.additionalDataMapDto"
                 [showPlaceholder]="true"
                 [changeListener]="changeListener"
    >

    </app-element>
  </mat-card-content>

  <mat-card-content>

    <mat-spinner *ngIf="creating"></mat-spinner>

    <button mat-raised-button color="accent"
            [disabled]="creating && !embeddedForm.valid"
            (click)="createRecord()">{{'CREATE' | translate}}
    </button>
    &nbsp;

    <button [disabled]="creating" mat-raised-button (click)="cancelIt()">{{'CANCEL' | translate}}</button>

  </mat-card-content>


</mat-card>
