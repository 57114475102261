import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContextIdDto } from '@savvy/app';
import {
  Subscription,
  SubscriptionDefService,
  SubscriptionDefinition,
  SubscriptionIntervalPackage,
  SubscriptionService,
  SubscriptionStateDto,
  SubscriptionStatusDto,
} from '@savvy/subscription';
import { GLOBAL } from 'src/app/app.constants';
// import { PastDueComponent } from '../components/subscription/changeCard.component';
import { SmsSettings, SmsSettingsCompService } from '@savvy/sms-settings';
import { BehaviorSubject, Observable } from 'rxjs';
import { ChangeCardComponent } from '../components/subscription/change-card/change-card.component';
import { CookiesService } from './cookies.service';
import { PastDueComponent } from '../components/subscription/past-due/past-due.component';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionHelperService {
  subscription: Subscription;
  annual = false;
  currentSubscriptionDef: SubscriptionDefinition;
  currentSubscriptionInterval: SubscriptionIntervalPackage;
  subscriptionStatusDto: SubscriptionStatusDto;
  oldInterval: Subscription.SubscriptionIntervalEnum;
  verifyCardDetailsOpened = false;
  pastDueOpened = false;
  isFreemium = false;
  smsSettings: SmsSettings;

  subscriptionState: SubscriptionStateDto = {};

  private subscriptionStateSource: BehaviorSubject<SubscriptionStateDto> =
    new BehaviorSubject(this.subscriptionState);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public subscriptionState$: Observable<SubscriptionStateDto> =
    this.subscriptionStateSource.asObservable();

  constructor(
    private dialog: MatDialog,
    private subscriptionService: SubscriptionService,
    private cookiesService: CookiesService,
    private subscriptionDefService: SubscriptionDefService,
    private smsSettingsCompService: SmsSettingsCompService
  ) {}

  setSubscriptionState(subscriptionState: SubscriptionStateDto) {
    this.subscriptionState = subscriptionState;
    this.subscriptionStateSource.next(this.subscriptionState);
  }

  loadCurrent(contextIdDto: ContextIdDto): Promise<Subscription> {
    return new Promise((resolve) => {
      // console.log('loading current subscription');
      this.subscriptionService
        .getCurrentSubscription(
          contextIdDto.contextIdType,
          contextIdDto.contextId
        )
        .subscribe((subscription) => {
          console.log('got subscription', subscription);
          this.subscription = subscription;
          if (this.subscription?.subscriptionInterval === 'ANNUAL') {
            this.annual = true;
          } else {
            this.annual = false;
          }
          this.oldInterval = this.subscription.subscriptionInterval;
          this.loadSmsCredit(contextIdDto);
          resolve(this.subscription);
        });
    });
  }

  currentPlan(subscriptionDefinition: SubscriptionDefinition): boolean {
    if (this.subscription && subscriptionDefinition) {
      if (
        this.subscription.subscriptionDefinitionId.id ===
        subscriptionDefinition.id
      ) {
        return true;
      }
    }
    return false;
  }

  reVerifyCardDetails(contextIdDto: ContextIdDto, countryCode: string) {
    if (
      this.subscriptionStatusDto.subscriptionStatus !== 'VALID_SUBSCRIPTION' &&
      this.subscriptionStatusDto.subscriptionStatus !== 'PAST_DUE'
    ) {
      this.verifyCardDetailsOpened = true;
      return;
    }
    const data = {
      contextIdDto,
      subscriptionId: this.subscription.subscriptionId,
      selectedSubscriptionDef: this.currentSubscriptionDef,
      annual: this.annual,
      currencyCode: this.subscription.currencyCode,
      price: this.subscription.cost,
      subscription: this.subscription,
      countryCode,
      returnUrl: this.getReturnUrl(contextIdDto),
      title: 'Verify Card Details',
      allowToSkip: true,
      desc: true,
    };
    console.log('data');
    console.log(data);
    const dialogRef = this.dialog.open(ChangeCardComponent, {
      data,
      width: '450px',
      height: 'auto',
      panelClass: ['scrollable-modal', 'helpwindow'],
    });

    dialogRef.afterClosed().subscribe((result) => {
      // this.loadCurrent();
      this.verifyCardDetailsOpened = false;
      this.checkSubscriptionStatus(contextIdDto, countryCode);
    });
  }

  showPastDue(contextIdDto: ContextIdDto, countryCode: string) {
    const data = {
      contextIdDto,
      subscriptionId: this.subscription.subscriptionId,
    };
    console.log('data');
    console.log(data);
    const dialogRef = this.dialog.open(PastDueComponent, {
      data,
      width: '450px',
      height: 'auto',
      panelClass: ['scrollable-modal', 'helpwindow'],
    });

    dialogRef.afterClosed().subscribe((result) => {
      // this.loadCurrent();
      this.pastDueOpened = false;
    });
  }

  checkSubscriptionStatus(contextIdDto: ContextIdDto, countryCode: string) {
    return new Promise((resolve) => {
      this.subscriptionService
        .subscriptionTrialStatus(
          contextIdDto.contextId,
          contextIdDto.contextIdType
        )
        .subscribe((res) => {
          this.subscriptionStatusDto = res;
          const skipCardVerification = this.cookiesService.getCookie(
            'skipCardVerification'
          );
          const skipPastDue = this.cookiesService.getCookie('skipPastDue');
          if (
            !res.onTrial &&
            !res?.recurringReference &&
            !skipCardVerification &&
            !this.verifyCardDetailsOpened &&
            res.subscriptionStatus !== 'TRIAL_EXPIRED'
          ) {
            this.verifyCardDetailsOpened = true;
            this.reVerifyCardDetails(contextIdDto, countryCode);
          } else if (res.subscriptionStatus === 'PAST_DUE' && !skipPastDue) {
            this.pastDueOpened = true;
            this.showPastDue(contextIdDto, countryCode);
          } else {
            resolve(true);
          }
        });
    });
  }

  getReturnUrl(contextIdDto: ContextIdDto) {
    if (GLOBAL.localhost === 'localhost') {
      return (
        'http://' +
        GLOBAL.localhost +
        ':4200/subscription/subscriptions/cardVerified/' +
        contextIdDto.contextId +
        '/' +
        contextIdDto.contextIdType
      );
    } else {
      return (
        'https://' +
        GLOBAL.localhost +
        '/subscription/subscriptions/cardVerified/' +
        contextIdDto.contextId +
        '/' +
        contextIdDto.contextIdType
      );
    }
  }

  hasCurrentPlan(): boolean {
    return !this.noCurrentPlan();
  }

  noCurrentPlan(): boolean {
    if (!this.subscription) {
      return true;
    } else if (this.subscription.current && this.subscription.cancelled) {
      return true;
    } else if (!this.subscriptionStatusDto?.recurringReference) {
      return true;
    } else if (
      !this.subscriptionStatusDto?.recurringReference &&
      !this.subscription.cardToken
    ) {
      return true;
    }
    return false;
  }

  isMultiLocationPlan(subDef: SubscriptionDefinition): boolean {
    return (
      subDef.subscriptionPackage.maxNumLocations > 1 &&
      this.hasCurrentPlan() &&
      this.subscription.subscriptionDefinitionId.id === subDef.id
    );
  }

  differentCurrentPlanAndHasCard(
    subscriptionDefinition: SubscriptionDefinition
  ): boolean {
    if (this.subscription && this.subscription.id) {
      if (
        this.subscription.subscriptionDefinitionId.id !==
          subscriptionDefinition.id ||
        this.subscription.subscriptionInterval !== this.oldInterval
      ) {
        if (
          this.subscription.current &&
          !this.subscription.cancelled &&
          this.subscriptionStatusDto?.recurringReference
        ) {
          if (
            this.subscription.cardToken ||
            this.subscriptionStatusDto?.recurringReference
          ) {
            return true;
          }
        }
      }
    }
    return false;
  }

  /*differentCurrentPlanNoCard(subscriptionDefinition: SubscriptionDefinition): boolean {
    if (this.subscription && this.subscription.id) {
      if (this.subscription.subscriptionDefinitionId.id !== subscriptionDefinition.id) {
        if (this.subscription.current && !this.subscription.cancelled) {
          if (!this.subscription.cardToken) {
            return true;
          }
        }
      }
    }
    return false;
  }*/

  /*currentPlanAndActiveAndHasCard(subscriptionDefinition: SubscriptionDefinition): boolean {
    return false;
  }*/

  currentPlanAndActive(
    subscriptionDefinition: SubscriptionDefinition
  ): boolean {
    return false;
  }

  getTitle(subscriptionDefinition: SubscriptionDefinition): string {
    if (this.currentPlan(subscriptionDefinition) && this.subscription) {
      if (!this.subscription.cancelled && this.subscription.quantity) {
        return (
          subscriptionDefinition.header +
          ' (<strong>' +
          this.subscription.quantity +
          '  Active</strong>) '
        );
      } else if (!this.subscription.cancelled && !this.subscription.quantity) {
        return subscriptionDefinition.header + ' <strong>(Active)</strong> ';
      }
    } else if (subscriptionDefinition) {
      return subscriptionDefinition.header;
    }
  }

  loadSmsCredit(contextIdDto: ContextIdDto) {
    if (contextIdDto && contextIdDto.contextId) {
      this.smsSettingsCompService
        .getSmsReport(contextIdDto.contextId, contextIdDto.contextIdType)
        .subscribe((response) => {
          if (response?.smsSettings) {
            this.smsSettings = response.smsSettings;
            if (this.smsSettings.smsCredits?.customNumCreditsOnPlan === 0) {
              this.isFreemium = true;
            }
          }
        });
    }
  }
}
