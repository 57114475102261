<div class="customers-email-modal customers-email-modal-mobile">
  <mat-toolbar color="primary">
    <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <span>{{'RESET_CUSTOMER_PASSWORD' | translate}}</span>
      <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="m-0 mdc-p-34" *ngIf="!sending">

    <mat-card appearance="outlined">
      <mat-card-content fxLayout="column" fxFlex>
        <div fxFlex fxLayout="column">
          <mat-form-field appearance="outline">
            <mat-label>{{'PASSWORD' | translate}}</mat-label>
            <input type="password" matInput name="subject" [maxlength]="50" #passwordField="ngModel" [(ngModel)]="password" placeholder="{{'ENTER_PASSWORD' | translate}}" required>
            <mat-hint align="end">{{passwordField.value?.length || 0}} / 50</mat-hint>
            <mat-error *ngIf="passwordField.hasError('required')">
              {{'PASSWORD_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
            </mat-error>
          </mat-form-field>
          <password-strength-meter [password]="password"></password-strength-meter>


        </div>
      </mat-card-content>
    </mat-card>

  </div>

  <div mat-dialog-content class="m-0 mdc-p-34" *ngIf="sending">
    <mat-spinner></mat-spinner>
  </div>

  <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="center center">
    <button mat-raised-button color="accent" (click)="reset()">{{'RESET_PASSWORD' | translate}}</button>
  </div>


</div>