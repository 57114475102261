<mat-card appearance="outlined" class="mx-0 shadow-none mdc-mb-1" *ngIf="featureAllowed">
  <mat-card-header class="mdc-mb-1">
    <div fxLayout="row" fxLayoutAlign=" center">
      <div fxFlex>
        <h6 class="mb-0 ">{{'PETS' | translate}}</h6>
      </div>
      <div fxFlex="nogrow">
        <button mat-button color="accent" (click)="addPet()" *ngIf="!isViewMode">
          <mat-icon class="vm">add</mat-icon> <span class="vm">{{'ADD_PET' | translate}}</span>
        </button>
        <button mat-button color="accent" (click)="mergePet()" *ngIf="!isViewMode">
          <mat-icon class="vm">merge_type</mat-icon> <span class="vm">{{'MERGE_PETS' | translate}}</span>
        </button>
      </div>
    </div>
  </mat-card-header>
  <!-- <hr> -->
  <mat-card-content>
    <ng-container *ngIf="pets?.length; else elseBlockPets">
      <mat-list>
        <mat-list-item *ngFor="let pet of pets" style="cursor: pointer;">
          <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="16px" class="w-100 mdc-py-2">
            <div class="avatar avatar-50 circle border-3 d-inline-block" fxFlex="50px" (click)="viewPet(pet)">
              <mat-icon>pets</mat-icon>
            </div>
            <div fxFlex class="" (click)="viewPet(pet)">
              <p class="mb-0 " *ngIf="pet.deceased"><span class="strikethrough">{{ pet.name }}</span>{{'DECEASED_PET' | translate}}</p>
              <p class="mb-0" *ngIf="!pet?.deceased">{{ pet.name }}</p>
              <p class="small mat-text-secondary mb-0 text-truncated" *ngIf="isViewMode">{{ pet.breedLabel }}</p>
              <p class="small mat-text-secondary mb-0 text-truncated" style="max-width:130px" *ngIf="!isViewMode">{{ pet.breedLabel }}</p>
            </div>
            <div fxFlex="nogrow" *ngIf="!isViewMode">
              <button mat-icon-button color="accent" matTooltip="{{'EDIT_APPT' | translate}}" (click)="createAppointment(pet)">
                <mat-icon class="vm">event</mat-icon>
              </button>
              <button mat-icon-button color="accent" matTooltip="{{'EDIT_PET' | translate}}" (click)="editPet(pet)">
                <mat-icon class="vm">edit</mat-icon>
              </button>
              <button mat-icon-button color="accent" matTooltip="{{'DELETE_PET' | translate}}" (click)="deletePet(pet)">
                <mat-icon class="vm">delete</mat-icon>
              </button>
            </div>
          </div>

          <!-- <div mat-list-icon class="avatar avatar-50 circle border-3">
            <svg xmlns="http://www.w3.org/2000/svg" class="signature-icon" viewBox="0 0 19.084 12.65">
              <path id="Path_119" data-name="Path 119"
                d="M878.678,330.417s-2.469,4.1.221,4.5,7.628-6.393,4.982-7.055-7.407,10.1-4.542,11.42,8.728-6.146,7.235-7.061-5.324,4.036-3.73,4.782,5.155-2,6.241-3.154,2.208-2.679,3.529-1.967-2.037,3.663-1.155,4.477,4.07-3.629,4.07-3.629"
                transform="translate(-877.143 -327.317)" fill="none" stroke="#ef9a9a" stroke-linecap="round"
                stroke-width="1" />
            </svg>
          </div> -->
        </mat-list-item>
      </mat-list>
    </ng-container>
    <ng-template #elseBlockPets>
      <div class="flexbox-parent">
        <div fxLayout="row" fxLayoutAlign="center center">
          <div style="text-align:center;">
            <div class="avatar avatar-100 circle border-3 mb-2 d-inline-block mt-15px">
              <mat-icon class="h1 h-auto w-auto">pets</mat-icon>
            </div>
            <h6>{{'CUSTOMER_NO_PETS' | translate}}.</h6>
          </div>
        </div>
      </div>
    </ng-template>
  </mat-card-content>
</mat-card>