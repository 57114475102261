import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ChangeListener } from './changeListener';
import { NavigateService } from '../entry/NavigateService';
import { Page } from '../shared/model/page';
import { TrialExpiredService } from '../shared/services/trial-expired.service';
import { MatDialog } from '@angular/material/dialog';
import { ViewEntityComponent } from './viewEntity.component';
import { EventBusService } from './EventBusService';
import { PhoneNumberHelperService } from '../shared/services/phone-number-helper.service';
import { EicompService } from '@savvy/entity-instance-composite';
import { ViewAppointmentData, EiData, EntityInstanceId } from '@savvy/view-definition';
import { ContextIdDto } from '@savvy/quickbooks';

@Component({
  selector: 'app-view-appt-history',
  templateUrl: 'viewAppointmentHistory.component.html'
})
export class ViewAppointmentHistoryComponent implements OnInit {

  @Input() viewAppointmentData: ViewAppointmentData;
  @Input() contextIdDto: ContextIdDto;
  @Input() viewContext: any;
  @Input() form: UntypedFormGroup;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;
  @Input() useModalOnClick: boolean;
  @Input() eventBus: EventBusService;

  page = new Page();
  selected: EiData[] = [];
  appointmentDates: Array<EiData>;

  columns = [
    { prop: 'label', name: '' }
  ];

  constructor(
    private router: Router,
    private naviateService: NavigateService,
    public trialExpiredService: TrialExpiredService,
    private eiCompApi: EicompService,
    private dialog: MatDialog,
    private sharedService: PhoneNumberHelperService
  ) {
  }
  ngOnInit() {
    this.page.size = 10;
    /* if (this.viewAppointmentHistoryData.appointmentDates) {
       this.appointmentDates = this.viewAppointmentHistoryData.appointmentDates;
       this.page.totalElements = this.viewAppointmentHistoryData.totalRows;
       this.page.totalPages = this.viewAppointmentHistoryData.totalPages;
       this.page.pageNumber = 0;

     } else {
       this.setPage({offset: 0});
     }*/
  }
  setPage(pageInfo) {
    console.log('pageInfo is ' + pageInfo.offset);
    this.page.pageNumber = pageInfo.offset;
    this.loadPage();
  }

  loadPage() {
    // Call list display names and pass page number

    console.log('loading page:');

    /*if (this.viewAppointmentHistoryData &&
      this.viewAppointmentHistoryData.entityInstanceId) {
      this.eiCompApi.listAppointmentsByInvoiceEi(
        this.viewAppointmentHistoryData.entityDefinitionId.id,
        this.viewAppointmentHistoryData.entityInstanceId.id,
        (this.page.pageNumber + 1),
        this.page.size,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType
        ).subscribe(response => {
        this.appointmentDates = response.eis.contents;
        this.page.totalElements = response.eis.totalElements;
        this.page.totalPages = response.eis.totalPages;
      });
    }*/
  }
  showTable() {
    if (this.rowsAvailable() && this.page.pageNumber === 0 && this.lessThanPageWorth()) {
      return false;
    } else {
      return true;
    }
  }

  onSelect(event) {
    if (this.selected && this.selected.length > 0) {
      this.rowSelected(this.selected[0].entityInstanceId);
    }
  }

  lessThanPageWorth() {
    return this.numRows() < this.page.size;
  }

  numRows() {
    return this.appointmentDates.length;
  }
  rowsAvailable() {
    return this.appointmentDates;
  }

  rowSelected(entityInstanceId: EntityInstanceId) {
    if (this.useModalOnClick) {
      this.dialog.open(ViewEntityComponent, {
        data: {
          entityInstanceId: entityInstanceId.id,
          contextIdDto: this.contextIdDto,
          eventBus: this.eventBus,
          isViewMode: true
        },
        panelClass: 'modal-100',
        maxHeight: '80vh'
      });
    } else {
      this.sharedService.viewEntityModalSub.next(true);
      this.naviateService.viewEntity(this.contextIdDto, entityInstanceId);
    }
  }
}

