import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PhoneNumberHelperService } from '../shared/services/phone-number-helper.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { BarChartDto } from '@savvy/view-definition';
import { ContextService } from '../context.service';
import { ContextIdDto } from '@savvy/menu';
import { UserCurrencyService } from '../shared/services/userCurrency.service';

@Component({
  selector: 'app-bar-chart-widget',
  templateUrl: 'barChartWidget.component.html'
})
export class BarChartWidgetComponent implements OnInit, OnDestroy {
  @Input() barChartDto: BarChartDto;
  currencyCode: string;
  destroy$ = new Subject();


  globalChartOptions: any = {
    responsive: true,
    legend: {
      display: false,
      position: 'bottom'
    }
  };

  lineChartColors: Array<any> = [{ // grey
    backgroundColor: '#7986cb',
    borderColor: '#3f51b5',
    pointBackgroundColor: '#3f51b5',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(148,159,177,0.8)'
  }, { // dark grey
    backgroundColor: '#eeeeee',
    borderColor: '#e0e0e0',
    pointBackgroundColor: '#e0e0e0',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(77,83,96,1)'
  }, { // grey
    backgroundColor: 'rgba(148,159,177,0.2)',
    borderColor: 'rgba(148,159,177,1)',
    pointBackgroundColor: 'rgba(148,159,177,1)',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(148,159,177,0.8)'
  }];

  // Bar
  barChartLabels: string[] = null;
  barChartType = 'bar';
  barChartLegend = true;
  barChartData: any[] = null;
  barChartOptions: any = Object.assign({
    scaleShowVerticalLines: false,
    scales: {
      xAxes: [{
        gridLines: {
          color: 'rgba(0,0,0,0.02)',
          zeroLineColor: 'rgba(0,0,0,0.02)'
        }
      }],
      yAxes: [{
        gridLines: {
          color: 'rgba(0,0,0,0.02)',
          zeroLineColor: 'rgba(0,0,0,0.02)'
        },
        position: 'left',
        ticks: {
          beginAtZero: true,
          suggestedMax: 9
        }
      }]
    }
  }, this.globalChartOptions);

  contextIdDto: ContextIdDto;
  subscriptions: Subscription[] = [];

  constructor(
    private sharedService: PhoneNumberHelperService,
    private contextService: ContextService,
    private userCurrencyService: UserCurrencyService
  ) {
  }

  ngOnInit() {
    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
        this.getCurrencyCode();
        if (this.barChartDto) {
          this.barChartLabels = this.barChartDto.xaxisLabels;
          /*
                [{
                  data: [6, 5, 8, 8, 5, 5, 4],
                  label: 'Series A',
                  borderWidth: 0
                }, {
                  data: [5, 4, 4, 2, 6, 2, 5],
                  label: 'Series B',
                  borderWidth: 0
                }];*/

          this.barChartData = [{
            data: this.barChartDto.barChartDataDtoList[0].values,
            label: this.barChartDto.barChartDataDtoList[0].label,
            borderWidth: 0
          }, {
            data: this.barChartDto.barChartDataDtoList[1].values,
            label: this.barChartDto.barChartDataDtoList[1].label,
            borderWidth: 0
          }];
        }
      }
    }));
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getCurrencyCode() {
    this.userCurrencyService.getDefaultCurrency(this.contextIdDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.currencyCode = res.org.currencyCode ? res.org.currencyCode : this.userCurrencyService.defaultCurrency;
      });
  }
}
