import {Component, Input} from '@angular/core';
import { ContextIdDto } from '@savvy/view-definition';
import {UserHistoryModalComponent} from './user-history-modal/user-history-modal.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
    selector: 'app-view-customer-history-button',
    templateUrl: 'viewCustomerHistoryButton.component.html'
})
export class ViewCustomerHistoryButtonComponent {

  @Input() contextIdDto: ContextIdDto;
  @Input() customerId: string;


  constructor(private dialog: MatDialog) {

  }

  viewCustomerHistory() {
    this.dialog.open(UserHistoryModalComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        customerId: this.customerId
      },
      maxHeight: '80vh',
      panelClass: 'user-modal-history'
    });
  }
}

