import { Component, Input, OnInit } from '@angular/core';
import { ViewContextDto } from '@savvy/view-composite';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ChangeListener } from './changeListener';
import { NavigateService } from '../entry/NavigateService';
import { Page } from '../shared/model/page';
import { TrialExpiredService } from '../shared/services/trial-expired.service';
import { MatDialog } from '@angular/material/dialog';
import { ViewEntityComponent } from './viewEntity.component';
import { EventBusService } from './EventBusService';
import { PhoneNumberHelperService } from '../shared/services/phone-number-helper.service';
import { EicompService } from '@savvy/entity-instance-composite';
import { ContextIdDto, EiData, EntityInstanceId, ViewAppointmentsByEiListData } from '@savvy/view-definition';

@Component({
  selector: 'app-view-appts-by-ei-list',
  templateUrl: 'viewAppointmentsByEiList.component.html',
  styleUrls: ['commonPanel.component.scss']

})
export class ViewAppointmentsByEiListComponent implements OnInit {

  @Input() viewAppointmentsByEiListData: ViewAppointmentsByEiListData;
  @Input() contextIdDto: ContextIdDto;
  @Input() viewContext: ViewContextDto;
  @Input() form: UntypedFormGroup;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;
  @Input() useModalOnClick: boolean;
  @Input() eventBus: EventBusService;

  futurePage = new Page();
  futureSelected: EiData[] = [];
  futureAppointmentDates: Array<EiData>;

  pastPage = new Page();
  pastSelected: EiData[] = [];
  pastAppointmentDates: Array<EiData>;

  columns = [
    { prop: 'label', name: '' }
  ];

  constructor(
    private router: Router,
    private naviateService: NavigateService,
    public trialExpiredService: TrialExpiredService,
    private eiCompApi: EicompService,
    private dialog: MatDialog,
    private sharedService: PhoneNumberHelperService) {
  }
  ngOnInit() {
    this.futurePage.size = 10;
    this.pastPage.size = 10;
    if (this.viewAppointmentsByEiListData.futureAppointmentDates) {
      this.futureAppointmentDates = this.viewAppointmentsByEiListData.futureAppointmentDates;
      this.futurePage.totalElements = this.viewAppointmentsByEiListData.futureTotalRows;
      this.futurePage.totalPages = this.viewAppointmentsByEiListData.futureTotalPages;
      this.futurePage.pageNumber = 0;
    }
    if (this.viewAppointmentsByEiListData.pastAppointmentDates) {
      this.pastAppointmentDates = this.viewAppointmentsByEiListData.pastAppointmentDates;
      this.pastPage.totalElements = this.viewAppointmentsByEiListData.pastTotalRows;
      this.pastPage.totalPages = this.viewAppointmentsByEiListData.pastTotalPages;
      this.pastPage.pageNumber = 0;
    }
  }

  onTabChanged() {
    console.log('reloading');
    this.loadFuturePage();
    this.loadPastPage();
  }

  setFuturePage(pageInfo) {
    console.log('pageInfo is ' + pageInfo.offset);
    this.futurePage.pageNumber = pageInfo.offset;
    this.loadFuturePage();
  }

  setPastPage(pageInfo) {
    console.log('pageInfo is ' + pageInfo.offset);
    this.pastPage.pageNumber = pageInfo.offset;
    this.loadPastPage();
  }

  loadFuturePage() {
    // Call list display names and pass page number

    console.log('loading page:');

    /**
     * https://blue.floapi.com/rest/eicomp/listAppointmentsByInvoiceEi/5c60922e67adb1000589f86f/5cd063716166b000077f6dbc/2/10/5c60922d67adb1000589f83b/ENV_ID
     */

    if (this.viewAppointmentsByEiListData &&
      this.viewAppointmentsByEiListData.entityInstanceId) {
      this.eiCompApi.listFutureAppointmentsByInvoiceEi(
        this.viewContext.entityDefinitionId.id,
        this.viewAppointmentsByEiListData.entityInstanceId.id,
        (this.futurePage.pageNumber + 1),
        this.futurePage.size,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType
      ).subscribe(response => {
        this.futureAppointmentDates = response.eis.contents;
        this.futurePage.totalElements = response.eis.totalElements;
        this.futurePage.totalPages = response.eis.totalPages;
      });
    }
  }

  loadPastPage() {
    // Call list display names and pass page number

    console.log('loading page:');

    /**
     * https://blue.floapi.com/rest/eicomp/listAppointmentsByInvoiceEi/5c60922e67adb1000589f86f/5cd063716166b000077f6dbc/2/10/5c60922d67adb1000589f83b/ENV_ID
     */

    if (this.viewAppointmentsByEiListData &&
      this.viewAppointmentsByEiListData.entityInstanceId) {
      this.eiCompApi.listPastAppointmentsByInvoiceEi(
        this.viewContext.entityDefinitionId.id,
        this.viewAppointmentsByEiListData.entityInstanceId.id,
        (this.pastPage.pageNumber + 1),
        this.pastPage.size,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType
      ).subscribe(response => {
        this.pastAppointmentDates = response.eis.contents;
        this.pastPage.totalElements = response.eis.totalElements;
        this.pastPage.totalPages = response.eis.totalPages;
      });
    }
  }


  onFutureSelect(event) {
    if (this.futureSelected && this.futureSelected.length > 0) {
      this.rowSelected(this.futureSelected[0].entityInstanceId);
    }
  }

  onPastSelect(event) {
    if (this.pastSelected && this.pastSelected.length > 0) {
      this.rowSelected(this.pastSelected[0].entityInstanceId);
    }
  }

  numFutureRows() {
    return this.futureAppointmentDates.length;
  }

  numPastRows() {
    return this.pastAppointmentDates.length;
  }

  rowSelected(entityInstanceId: EntityInstanceId) {
    if (this.useModalOnClick) {
      this.dialog.open(ViewEntityComponent, {
        data: {
          entityInstanceId: entityInstanceId.id,
          contextIdDto: this.contextIdDto,
          eventBus: this.eventBus,
          isViewMode: true
        },
        panelClass: 'modal-100',
        maxHeight: '80vh'
      });
    } else {
      this.sharedService.viewEntityModalSub.next(true);
      this.naviateService.viewEntity(this.contextIdDto, entityInstanceId);
    }
  }

  create() {
    this.sharedService.viewEntityModalSub.next(true);
    console.log('inside create in ListEntitysListComponent');
    if (this.viewContext.viewContextTypeDto === ViewContextDto.ViewContextTypeDtoEnum.Customer) {
      this.router.navigate(['/entity/createEntity',
        this.viewAppointmentsByEiListData.entityDefinitionId.id,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.viewContext.customerContextDto.customerId.id
      ]);
    } else {
      this.sharedService.viewEntityModalSub.next(true);
      this.router.navigate(['/entity/createEntity',
        this.viewAppointmentsByEiListData.entityDefinitionId.id,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.viewContext.entityInstanceId.id,
        this.viewContext.entityDefinitionId.id
      ]);
    }
  }
}

