<mat-card appearance="outlined" *ngIf="contextIdDto && showFilters" class="mdc-m-0">
  <mat-card-content class="mdc-pb-0">

    <div fxLayout="row wrap" fxLayoutGap="5px" fxLayoutGap.gt-md="1%">

      <div fxFlex="100" fxFlex.gt-xs="49" fxFlex.gt-sm="20">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Pet, Customer, Email, Phone...</mat-label>
          <mat-icon matSuffix>search</mat-icon>
          <input matInput placeholder="Pet, Customer, Email, Phone..." [(ngModel)]="petFilter.searchKey"
            (ngModelChange)="searchKeyUpdated($event)">
        </mat-form-field>
      </div>

      <div fxFlex="100" fxFlex.gt-xs="49" fxFlex.gt-sm="10">

        <mat-form-field class="w-100" appearance="outline">
          <!--mat-label>{{'SHOW_ARCHIVED' | translate}}</mat-label-->
          <mat-select placeholder="Stuff" (selectionChange)="archivedChanged($event)"
            [(ngModel)]="petFilter.deleteOnly">
            <mat-option [value]="false">All</mat-option>
            <mat-option [value]="true">Archived Only</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!--div fxFlex="100" fxFlex.gt-md="nogrow">
        <mat-radio-group aria-label="Show Archived?" [ngModel]="deleteOnly"
          (ngModelChange)="deleteOnly = $event; loadAllPets()" class="mdc-mb-1 d-block">
          <mat-radio-button class="example-radio-button px-8" [value]="true">
            <div class="text-bold">{{'SHOW_ARCHIVED' | translate}}</div>
          </mat-radio-button>
          <mat-radio-button class="example-radio-button px-8" [value]="false">
            <div class="text-bold">{{'HIDE_ARCHIVED' | translate}}</div>
          </mat-radio-button>
        </mat-radio-group>
      </div-->
      <div fxFlex="100" fxFlex.gt-xs="49" fxFlex.gt-sm="24">
        <app-flag-filter (includeFlagsChanged)="includeFlagsChange($event);"
          (excludeFlagsChanged)="excludeFlagsChange($event);" [contextIdDto]="contextIdDto" [flagDefinitionType]="'PET'"
          #flagFilterComponent></app-flag-filter>
      </div>
      <div fxFlex="100" fxFlex.gt-xs="49" fxFlex.gt-sm="10">
        <app-filter-pet-type-selector [contextIdDto]="contextIdDto" (petTypeChanged)="petTypeSelectionChange($event);"
          #petTypeFilterComponent></app-filter-pet-type-selector>
      </div>
      <div fxFlex="100" fxFlex.gt-xs="49" fxFlex.gt-sm="15">
        <app-pet-breed-selector [contextIdDto]="contextIdDto" (petBreedChanged)="petBreedSelectionChange($event);"
          #petBreedFilterComponent></app-pet-breed-selector>
      </div>
      <div fxFlex></div>
      <div fxFlex="nogrow">
        <button (click)="resetFilters()" mat-button color="accent"
          class="mt-1 mdc-mb-1 mb-md-0"><mat-icon>cancel</mat-icon>
          Clear Filter</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<ng-container *ngIf="!loaded || (pets.length > 0 && pets)">
  <alphabet-filter #alphabetFilterComponent propAlphaOrder="name" propsSearch="name"
    placeholder="{{'SEARCH_FOR_PET_NAME' | translate}}" listClass="search-list" [data]="pets"
    (onClick)="onSelectAlpha($event)" [withTemplate]="true" height="calc(100vh - 140px)">
    <ng-template let-item>
      <div class="search-list-item">
        <img [src]="getPetImage(item)">
        <span>
          <p>{{item.name}} <span *ngIf="item.breedLabel">({{item.breedLabel}})</span></p>
          <p>{{item.petOwnerFirstName}} {{item.petOwnerLastName}}</p>
        </span>
        <span *ngIf="item.id">
          <p *ngIf="item.email"><a href="mailto: {{ item.email }}"><span
                class="chiptag theme-accent vm mt-5px"><mat-icon>email</mat-icon> {{ item.email }}</span></a></p>
          <p *ngIf="item.mobileInternational"><a href="tel: {{ item.mobileInternational }}"><span
                class="chiptag theme vm mt-5px"><mat-icon>call</mat-icon> {{ item.mobileInternational }} </span></a>
          </p>
          <app-pets-flags-panel [disableCreate]="true" [contextIdDto]="contextIdDto"
            [flagIds]="item.flags"></app-pets-flags-panel>
        </span>
        <span *ngIf="!item.id">
          <button mat-raised-button color="accent" (click)="resetData()">
            Reset
          </button>
        </span>
        <span *ngIf="item.id" class="action-button">
          <button *ngIf="!item.deleted" mat-raised-button color="accent"
            (click)="archive(item); $event.stopImmediatePropagation();">{{'ARCHIVE' | translate}}</button>
          <button *ngIf="item.deleted" mat-raised-button color="accent"
            (click)="unarchive(item); $event.stopImmediatePropagation();">{{'UN_ARCHIVE' | translate}}</button>
        </span>
      </div>
    </ng-template>
  </alphabet-filter>
</ng-container>

<div class="flexbox-parent" *ngIf="pets && pets.length === 0 && loaded">
  <div fxLayout="row" fxLayoutAlign="center center" style="height: 60vh;">
    <div style="text-align:center;">
      <app-zero-image name="pet" class="zero-image"></app-zero-image><br>
      <h3 class="mb-2">{{"NO_PETS" | translate}}</h3><br>
      <button mat-raised-button color="accent" [disabled]="trialExpiredService.isTrialExpired()" (click)="create()">
        {{'ADD_PET' | translate}}
      </button>
    </div>
  </div>
</div>
