import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';

import { Title } from '@angular/platform-browser';
import { Address } from '@savvy/workflow-definition';

interface Marker {
  clickable: boolean;
  markerOptions?: google.maps.marker.AdvancedMarkerElementOptions;
  position: google.maps.LatLngLiteral;
  title?: string;
  label?: string;
  infoWindow?: string;
}
@Component({
  selector: 'app-google-places-lookup-v2',
  templateUrl: './google-places-lookup-v2.component.html',
  styleUrls: ['./google-places-lookup-v2.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class GooglePlacesLookupV2Component implements OnInit {
  @ViewChildren(MapInfoWindow) infoWindowsView: QueryList<MapInfoWindow>;

  @ViewChild('searchElement', { static: true }) searchElement: ElementRef;

  @Input() longitude;
  @Input() latitude;
  @Input() label: string;
  @Input() showPlaceholder: boolean;
  @Output() modelUpdate = new EventEmitter<Address>();


  // public appearance = Appearance;
  public zoom: number;
  address: string;
  mapOptions: google.maps.MapOptions = {
    center: { lat: 55.624928, lng: -3.453697 },
    zoom: 8
  };
  positions: google.maps.LatLngLiteral[] = [];
  map: any;
  markers: Marker[] = [];


  constructor(
    private titleService: Title,
    private ngZone: NgZone
  ) { }

  ngOnInit() {

    // this.mapsAPILoader.load().then(() => {

    this.setCurrentLocation();
    // this.geoCoder = new google.maps.Geocoder();

    //this.titleService.setTitle('A Title');
  }

  getPlaceholder() {
    if (this.showPlaceholder) {
      return this.label;
    }
    return '';
  }

  mapInitialized(map) {
    this.map = map;
    const autocomplete = new google.maps.places.Autocomplete(this.searchElement.nativeElement, {
      types: ['address']
    });
    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const bounds = new google.maps.LatLngBounds();
        const newMarkers = new Array();

        // get the place result
        const place: google.maps.places.PlaceResult = autocomplete.getPlace();

        // verify result
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }

        // set latitude, longitude and zoom
        if (place.geometry?.location) {
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
        }

        const newAddress = this.parsePlaceToLocation(place);
        newAddress.latitude = this.latitude;
        newAddress.longitude = this.longitude;

        this.modelUpdate.emit(newAddress);

        this.zoom = 12;

        this.mapOptions = {
          center: { lat: this.latitude, lng: this.longitude },
          zoom: this.zoom
        };
        this.markers = [];
        const position: google.maps.LatLngLiteral = {
          lat: Number(this.latitude),
          lng: Number(this.longitude),
        };
        const marker: Marker = {
          clickable: true,
          markerOptions: { gmpDraggable: false },
          position,
          // infoWindow: this.getInfoW  indow(appointment),
          title: newAddress.googlePlacesAddress,
          label: '',
        };
        console.log('adding marker', marker);
        newMarkers.push(marker);

        // const loc = new LatLng(marker.lat, marker.lng);
        bounds.extend(marker.position);

        this.markers = [...newMarkers];
      });
    });
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;

        this.mapOptions = {
          center: { lat: this.latitude, lng: this.longitude },
          zoom: 8
        };
        this.zoom = 15;
      });
    }
  }


  private parsePlaceToLocation(gplace: google.maps.places.PlaceResult): Address {
    let locality;
    let country;
    let state;
    let zipCode;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    let street_number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    let street_name;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    let postal_town;

    if (!gplace) {
      return;
    }
    const name = gplace.formatted_address;

    if (Array.isArray(gplace.address_components)) {

      gplace.address_components.forEach(component => {
        if (component.types.indexOf('country') !== -1) {
          country = component.long_name;
        }

        if (component.types.indexOf('locality') !== -1) {
          locality = component.long_name;
        }

        if (component.types.indexOf('postal_town') !== -1) {
          postal_town = component.long_name;
        }

        if (component.types.indexOf('street_number') !== -1) {
          street_number = component.long_name;
        }

        if (component.types.indexOf('route') !== -1) {
          street_name = component.long_name;
        }

        if (component.types.indexOf('administrative_area_level_1') !== -1) {
          state = component.long_name;
        }

        if (component.types.indexOf('postal_code') !== -1) {
          zipCode = component.long_name;
        }
      });
    }

    const address = <Address>{};

    address.address1 = street_number;
    address.address2 = street_name;
    address.address3 = locality;
    address.googlePlacesAddress = name;
    address.city = postal_town;
    address.country = country;
    address.state = state;
    address.zipCode = zipCode;

    return address;
  }

  openInfoWindow1(marker: MapMarker, windowIndex: number) {
    /// stores the current index in forEach
    let curIdx = 0;
    this.infoWindowsView.forEach((window: MapInfoWindow) => {
      if (windowIndex === curIdx) {
        window.open(marker);
        curIdx++;
      } else {
        curIdx++;
      }
    });
  }
}

