import { Component, Input, OnInit } from '@angular/core';
import { ContextIdDto } from '@savvy/view-definition';
import { WorkflowinstcompService, WorkflowStatsDto } from '@savvy/workflow-instance';

@Component({
  selector: 'app-view-customer-workflow-stats-panel',
  templateUrl: 'view-customer-workflow-stats-panel.component.html'
})
export class ViewCustomerWorkflowStatsPanelComponent implements OnInit {

  @Input() customerId: string;
  @Input() contextIdDto: ContextIdDto;


  workflowStatsDto: WorkflowStatsDto;

  constructor(private workflowInstanceCompApiExtension: WorkflowinstcompService) {

  }

  ngOnInit(): void {
    this.loadStats();
  }

  loadStats() {
    this.workflowInstanceCompApiExtension.loadWorkflowStats(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      this.customerId
    ).subscribe(response => {
      this.workflowStatsDto = response.workflowStatsDto;
    });
  }
}

