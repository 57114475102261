import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {ContextIdDto} from '@savvy/entity-instance-composite';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FloSnackbarComponent} from '../snackbar/floSnackbar.component';
import {
  AccountHolder,
  CardMachine,
  MakeTerminalPaymentComp,
  SavvyPayAccountHolderService,
  SavvyPayTerminalPaymentsCompService,
  SavvyPayTerminalPaymentsService
} from '@savvy/savvy-pay';

import {
  AccountHolder as SavvyPayAccountHolder,
  SavvyPayTerminalPaymentService
} from '@savvy/savvy-pay2';
import {UserCountryService} from 'src/app/services/userCountry.service';
import {Invoice, PaymentSettings, PaymentSettingsService} from '@savvy/payment';
import {TipsService} from '@savvy/tips';
import {SavvyPayAccountHolder2Service} from "@savvy/savvy-pay2";

@Component({
  selector: 'app-card-machine-payment',
  styleUrls: ['./card-machine-payment.component.scss'],
  templateUrl: './card-machine-payment.component.html'
})
export class CardMachinePaymentComponent implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() invoice: Invoice;
  @Input() entityInstanceId: string;
  @Input() currencyCode = '';
  @Input() totalDepositRequests = 0;
  @Input() amountOutstanding = 0;
  @Input() cardMachines: Array<CardMachine> = [];

  @Output() added: EventEmitter<any> = new EventEmitter();

  timer;
  makeTerminalPayment = {} as MakeTerminalPaymentComp;
  donePayment = true;
  successMessage = '';
  errorMessage = '';
  paymentSettings: PaymentSettings;
  accountHolder: AccountHolder;
  savvyPayAccountHolder: SavvyPayAccountHolder; // from upgrade

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private notify: FloSnackbarComponent,
    public userCountryService: UserCountryService,
    private dialog: MatDialog,
    private savvyPayTerminalPaymentApi: SavvyPayTerminalPaymentsService,
    private savvyPayTerminalPaymentsCompApi: SavvyPayTerminalPaymentsCompService,
    private tipService: TipsService,
    private paymentSettingsService: PaymentSettingsService,
    private savvyPayService: SavvyPayAccountHolderService,
    private savvyPayAccountHolderService2 : SavvyPayAccountHolder2Service,
    private savvyPayTerminalPaymentService : SavvyPayTerminalPaymentService,
    private dialogRef: MatDialogRef<CardMachinePaymentComponent>) {

    this.contextIdDto = data.contextIdDto;
    this.invoice = data.invoice;
    this.currencyCode = data.currencyCode;
    this.entityInstanceId = data.entityInstanceId;
    this.totalDepositRequests = data.totalDepositRequests;
    this.amountOutstanding = data.amountOutstanding;
  }

  ngOnInit(): void {
    if (this.invoice) {
      if (this.amountOutstanding > 0) {
        this.makeTerminalPayment.amount = this.amountOutstanding;
      } else if (this.totalDepositRequests) {
        this.makeTerminalPayment.amount = this.invoice.total - this.totalDepositRequests;
      } else {
        this.makeTerminalPayment.amount = this.invoice.total;
      }


      this.loadCardMachines(this.invoice.locationId);
    }

    this.paymentSettingsService.loadPaymentSettings(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType).subscribe(response => {
        this.paymentSettings = response;

        this.loadAccountHolder();
      });
  }

  loadCardMachines(locationId: string) {
    //only show card machine for that location or machines that are not assigned to any location if any
    const cardMachines = this.data.cardMachines.filter(cardMachine => !cardMachine.locationId || cardMachine.locationId === locationId);
    this.cardMachines = Object.assign([], cardMachines);
  }

  loadAccountHolder() {
    debugger
    if (this.paymentSettings.savvyPaySettings?.accountHolderCode) {
      this.savvyPayService.loadAccountHolder(this.paymentSettings?.savvyPaySettings?.accountHolderCode).subscribe(response => {
        if (response) {
          this.accountHolder = response;
        }
      });
    } else {
      this.savvyPayAccountHolderService2.loadAccountHolderByEnvId(this.contextIdDto.contextId).subscribe(res => {
        if (res && res[0]) {
          this.savvyPayAccountHolder = res[0];
        }
      });
    }

  }

  payTerminal() {
    if (!this.invoice) {
      console.log('Invoice does not exist');
      return;
    }
    this.successMessage = '';
    this.errorMessage = '';

    this.donePayment = false;
    this.makeTerminalPayment.contextIdDto = this.contextIdDto;
    this.makeTerminalPayment.invoiceId = this.invoice.invoiceId.id;
    this.makeTerminalPayment.currency = this.currencyCode;
    this.makeTerminalPayment.entityInstanceId = this.entityInstanceId;
    this.makeTerminalPayment.locationId = this.data.locationId;

    console.log(this.makeTerminalPayment);
    this.savvyPayTerminalPaymentsCompApi.makeTerminalPaymentComp(this.makeTerminalPayment).subscribe(res => {
      if (res.terminalPayment) {
        this.startLoadTerminalPaymentStatus(res.terminalPayment.merchantReference);
      } else {
        this.donePayment = true;
        this.notify.message = 'Unable to initiate payment';
        this.notify.open();
        this.errorMessage = 'Payment has failed. Please try again.';
      }
    });
  }

  payTerminal2() {
    if (!this.invoice) {
      console.log('Invoice does not exist');
      return;
    }
    this.successMessage = '';
    this.errorMessage = '';

    this.donePayment = false;
    this.makeTerminalPayment.contextIdDto = this.contextIdDto;
    this.makeTerminalPayment.invoiceId = this.invoice.invoiceId.id;
    this.makeTerminalPayment.currency = this.currencyCode;
    this.makeTerminalPayment.entityInstanceId = this.entityInstanceId;
    this.makeTerminalPayment.locationId = this.data.locationId;

    console.log(this.makeTerminalPayment);
    this.savvyPayTerminalPaymentService.makeTerminalPaymentComp(this.makeTerminalPayment).subscribe(res => {
      if (res.terminalPayment) {
        this.startLoadTerminalPaymentStatus(res.terminalPayment.merchantReference);
      } else {
        this.donePayment = true;
        this.notify.message = 'Unable to initiate payment';
        this.notify.open();
        this.errorMessage = 'Payment has failed. Please try again.';
      }
    });
  }

  startLoadTerminalPaymentStatus(merchantReference) {
    this.timer = setInterval(() => {
      this.savvyPayTerminalPaymentApi.loadByMerchantReference(
        this.contextIdDto.contextId, this.contextIdDto.contextIdType, merchantReference)
        .subscribe(res => {
          if (res.terminalPayment && res.terminalPayment.status == 'SUCCESS') {
            this.donePayment = true;
            this.notify.message = 'Payment Successful';
            this.notify.open();
            clearInterval(this.timer);
            this.successMessage = 'Payment has been successful';
            // this.closeDialog();

          } else if (res.terminalPayment && res.terminalPayment.status == 'FAILED') {
            this.donePayment = true;
            this.notify.message = 'Payment Failed';
            this.notify.open();
            clearInterval(this.timer);
            this.errorMessage = 'Payment has failed. Please try again.';
            // this.closeDialog();
          }
        });
    }, 5000);
  }

  closeDialog() {
    clearInterval(this.timer);
    this.dialogRef.close();
  }


}
