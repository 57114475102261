<mat-toolbar color="primary">
  <mat-toolbar-row>{{data.notification.header | translate}}</mat-toolbar-row>
</mat-toolbar>
<mat-card appearance="outlined">
  <mat-card-header>
    <mat-card-title>{{ data.notification.timeOfNotification | localizedDate:'medium' }}</mat-card-title>
    <mat-card-subtitle>
      <p matLine *ngIf="userDto">{{ userDto.fullName }}</p>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <p matLine [innerHTML]="data.notification.message"></p>
    <br />
    <iframe width="600" height="400" *ngIf="data.notification.videoUrl" [src]="data.notification.videoUrl | safe: 'resourceUrl'" frameborder="0" allowfullscreen></iframe>
  </mat-card-content>
  <mat-card-content>
    <!--button mat-raised-button color="primary" type="submit" (click)="viewCustomer()" *ngIf="customerId">View
        Customer</button-->
    <button mat-raised-button color="accent" *ngIf="!this.data.notification.read" (click)="markAsRead()" class="mr-1">{{'MARK_AS_READ' | translate}}</button>
    <button mat-raised-button (click)="close()">{{'CANCEL' | translate}}</button>
  </mat-card-content>
</mat-card>