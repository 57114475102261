import { Injectable } from '@angular/core';
import { AppointmentConfig, AppointmentConfigService, ContextIdDto, UpdateAppointmentConfig } from '@savvy/appointment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppointmentConfigHelperService {
  public appointmentConfig: AppointmentConfig;

  private appointmentConfigUpdatedSource: Subject<AppointmentConfig> = new Subject();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public appointmentConfigUpdated$ = this.appointmentConfigUpdatedSource.asObservable();

  constructor(
    private appointmentConfigService: AppointmentConfigService
  ) { }

  loadAppointmentConfig(contextIdDto: ContextIdDto) {
    if (contextIdDto) {
      return new Promise((resolve) => {
        this.appointmentConfigService.loadAppointmentConfig(contextIdDto.contextId, contextIdDto.contextIdType)
          .subscribe(res => {
            this.appointmentConfig = res;
            this.triggerUpdateAppointmentConfig(this.appointmentConfig);
            resolve(res);
          });
      });
    }
  }

  triggerUpdateAppointmentConfig(appointmentConfig: AppointmentConfig) {
    this.appointmentConfig = appointmentConfig;
    this.appointmentConfigUpdatedSource.next(appointmentConfig);
  }

  updateAppointmentConfig(contextIdDto: ContextIdDto, appointmentConfig: AppointmentConfig) {
    return new Promise((resolve) => {
      const updateCOnfig: UpdateAppointmentConfig = {
        contextIdDto,
        appointmentConfig
      };
      this.appointmentConfigService.updateAppointmentConfig(updateCOnfig)
        .subscribe(res => {
          this.appointmentConfig = res;
          this.triggerUpdateAppointmentConfig(this.appointmentConfig);
          resolve(res);
        });
    });
  }

}
