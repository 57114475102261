import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContextIdDto } from '@savvy/quickbooks';
import { EventBusService } from '../element/EventBusService';
import { ReminderService, Reminder, ReminderId, UpdateReminder } from '@savvy/reminders';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { EventType, UiEvent } from '../event/UiEvent';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@angular/cdk/platform';
import { DateTimeAdapter, NativeDateTimeAdapter, OWL_DATE_TIME_LOCALE, OwlDateTimeIntl } from '@danielmoncada/angular-datetime-picker';
import { LocalIntl } from '../../services/local-intl.service';
import { NG_VALIDATORS } from '@angular/forms';
import { SenderidValidator } from '../validators/senderid.validator';

@Component({
  selector: 'app-show-reminder',
  templateUrl: 'showReminder.html',
  providers: [
    { provide: NG_VALIDATORS, useExisting: SenderidValidator, multi: true },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-US' },
    { provide: DateTimeAdapter, useClass: NativeDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE, Platform] },
    { provide: OwlDateTimeIntl, useClass: LocalIntl },
  ]
})
export class ShowReminderComponent implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() visible: boolean;
  @Input() eventBus: EventBusService;

  selectedReminder: Reminder;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notify: FloSnackbarComponent,
    dateTimeAdapter: DateTimeAdapter<any>,
    private translateService: TranslateService,
    private api: ReminderService) {
    dateTimeAdapter.setLocale(this.translateService.currentLang);
  }

  ngOnInit() {
    this.eventBus.eventAdded$.subscribe(event => this.onEvent(event));
  }
  update() {

    console.log('updating reminder id ' + this.selectedReminder.id);
    console.log('updating reminder to number is ' + this.selectedReminder.smsReminder.toNumber);

    const req = <UpdateReminder>{};

    req.reminder = this.selectedReminder;
    req.contextIdDto = this.contextIdDto;

    this.api.updateReminder(req).subscribe(response => {
      this.translateService.get('Updated reminder').subscribe(value => {
        this.notify.message = value;
        this.notify.open();
      });
      this.eventBus.addReminderEvent(EventType.REMINDER_UPDATED, this.selectedReminder.reminderId);
    });
  }

  isSent() {
    if (this.selectedReminder && this.selectedReminder.sendReminderResult &&
      this.selectedReminder.sendReminderResult.timeSent) {
      return true;
    }
    return false;
  }

  loadReminder(reminderId: ReminderId) {
    console.log('loadingReminderDetail ' + reminderId.id);
    this.api.loadReminder(reminderId.id,
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType).subscribe(response => {
        console.log('loaded selected reminder ' + response);
        this.selectedReminder = response;
      });
  }

  closeThePanel() {
    this.eventBus.addEvent(EventType.CLOSE_REMINDER);
  }

  private onEvent(event: UiEvent) {
    if (event.eventType === EventType.REMINDER_SELECTED) {
      this.loadReminder(event.reminderId);
    } else {
      console.log('unrecognised event ' + event.eventType);
    }
  }
}

