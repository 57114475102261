import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ViewCachedEntitiesListDefinition } from '@savvy/view-definition';
import { ContextIdDto } from '@savvy/quickbooks';
import { ChangeListener } from './changeListener';
import { EventBusService } from './EventBusService';

@Component({
  selector: 'app-view-list-cached-entities',
  templateUrl: 'viewListCachedEntities.component.html'
})
export class ViewListCachedEntitiesComponent {

  @Input() viewCachedEntitiesListDefinition: ViewCachedEntitiesListDefinition;
  @Input() contextIdDto: ContextIdDto;
  @Input() viewContext: any;
  @Input() form: UntypedFormGroup;
  @Input() eventBus: EventBusService;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;
}

