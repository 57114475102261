<div fxLayout="column" fxFlex="100">
  <h6 class="font-weight-medium"><strong>{{"CUSTOMER_INFORMATION" | translate: {Default: 'Customer Information'}
      }}</strong></h6>

  <div *ngIf="!deviceService.isMobile()" class="new-pet">
    <form [formGroup]="customerFormGroup" fxLayout="row wrap" fxLayoutGap="1%">
      <div fxFlex="24" fxFlexAlign="center">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'CUSTOMER_FIRST_NAME' | translate: {Default: 'Customer First Name'} }}</mat-label>
          <input matInput placeholder="{{'CUSTOMER_FIRST_NAME' | translate: {Default: 'Customer First Name'} }}" formControlName="customerFirstName" required>
        </mat-form-field>
      </div>
      <div fxFlex="24">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'CUSTOMER_LAST_NAME' | translate: {Default: 'Customer Last Name'} }}</mat-label>
          <input matInput placeholder="{{'CUSTOMER_LAST_NAME' | translate: {Default: 'Customer Last Name'} }}" formControlName="customerLastName" required>
        </mat-form-field>
      </div>
      <div fxFlex="24">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'EMAIL' | translate: {Default: 'Email'} }}</mat-label>
          <input matInput placeholder="{{'EMAIL' | translate: {Default: 'Email'} }}" formControlName="customerEmail">
        </mat-form-field>
      </div>
      <div fxFlex="25">
        <app-phone-input name="mobilePhoneNumber" (phoneChanged)="onMobilePhoneChange($event)" (countryChanged)="onMobileCountryChange($event)" [selectedCountryCode]="customerPhone?.country" label="{{'MOBILE' | translate: {Default: 'Mobile'} }}" placeholder="{{'MOBILE' | translate: {Default: 'Mobile'} }}" errorName="Mobile" required="false" [disabled]="customerFormGroup.disabled"></app-phone-input>
      </div>
    </form>
  </div>
  <div *ngIf="deviceService.isMobile()" class="new-pet">
    <form [formGroup]="customerFormGroup" fxLayout="row wrap" fxLayoutGap="1%">
      <div fxFlex="48" fxFlexAlign="center">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'CUSTOMER_FIRST_NAME' | translate: {Default: 'Customer First Name'} }}</mat-label>
          <input matInput placeholder="{{'CUSTOMER_FIRST_NAME' | translate: {Default: 'Customer First Name'} }}" formControlName="customerFirstName" required>
        </mat-form-field>
      </div>
      <div fxFlex="50">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'CUSTOMER_LAST_NAME' | translate: {Default: 'Customer First Name'} }}</mat-label>
          <input matInput placeholder="{{'CUSTOMER_LAST_NAME' | translate: {Default: 'Customer First Name'} }}" formControlName="customerLastName" required>
        </mat-form-field>
      </div>
      <div fxFlex="48">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'EMAIL' | translate: {Default: 'Email'} }}</mat-label>
          <input matInput placeholder="{{'EMAIL' | translate: {Default: 'Email'} }}" formControlName="customerEmail">
        </mat-form-field>
      </div>
      <div fxFlex="50">
        <app-phone-input name="mobilePhoneNumber" (phoneChanged)="onMobilePhoneChange($event)" (countryChanged)="onMobileCountryChange($event)" [selectedCountryCode]="customerPhone?.country" label="{{'MOBILE' | translate: {Default: 'Mobile'} }}" placeholder="{{'MOBILE' | translate: {Default: 'Mobile'} }}" errorName="Mobile" required="false" [disabled]="customerFormGroup.disabled"></app-phone-input>
      </div>
    </form>
  </div>
  <div>
    <mat-accordion class="mdc-mb-1 display-block">
      <mat-expansion-panel class="m-0" [disabled]="customerFormGroup.disabled">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{"ADDRESS" | translate: {Default: 'Address'} }}
          </mat-panel-title>
          <mat-panel-description>
            {{"CUSTOMER_ADDRESS_INFO" | translate: {Default: 'Enter customer address information'} }}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <app-address-lookup [contextIdDto]="contextIdDto" [address]="{}" (modelUpdate)="addressChanged($event)">
        </app-address-lookup>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="end center" *ngIf="customerFormGroup.enabled">
    <div fxFlex="nogrow">
      <button mat-raised-button color="accent" class="btn_pad" [disabled]="!customerFormGroup.valid" (click)="createCustomer(true)">
        {{'CREATE_CUSTOMER' | translate: {Default: 'Create Customer'} }}
      </button>
    </div>
  </div>
</div>