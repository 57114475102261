import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ChangeListener} from '../changeListener';
import {EventBusService} from '../EventBusService';
import {ContextIdDto, ElementInstanceDto, ElementInstanceViewDto, LinkedEntityDefinitionDto} from '@savvy/entity-instance-composite';

@Component({
  selector: 'app-linked-entity-instance',
  styleUrls: ['linkedEntityInstance.component.scss'],
  templateUrl: 'linkedEntityInstance.component.html'
})
export class LinkedEntityInstanceComponent implements OnInit {

  @Input() linkedEntityDefinition: LinkedEntityDefinitionDto;
  @Input() contextIdDto: ContextIdDto;
  @Input() elementInstanceDto: ElementInstanceDto;
  @Input() entityInstanceId: string;
  @Input() additionalDataMapDto: any;


  @Input() form: UntypedFormGroup;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;

  isTypeahead: boolean;
  isSelect: boolean;
  eventBus = new EventBusService();

  constructor() {}

  ngOnInit() {
    this.setDisplayWidget();
  }

  setDisplayWidget() {
    if (!this.elementInstanceDto.elementInstanceViewDto.displayWidgetType) {
      // Default is typeahead
      this.isTypeahead = true;
    }
    if (this.elementInstanceDto.elementInstanceViewDto.displayWidgetType === ElementInstanceViewDto.DisplayWidgetTypeEnum.Typeahead) {
      this.isTypeahead = true;
    }
    if (this.elementInstanceDto.elementInstanceViewDto.displayWidgetType === ElementInstanceViewDto.DisplayWidgetTypeEnum.Select) {
      this.isSelect = true;
    }
  }
}

