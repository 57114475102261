import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContextService } from '../context.service';
import { NavChangeService } from '../element/NavChangeService';
import MenuItemTypeEnum = MenuDefinition.MenuItemTypeEnum;
import {
  RemindersCompService,
  Reminder,
  ReminderDefinition,
  ReminderDefsService,
  LoadRemindersFiltered
} from '@savvy/reminders';
import { DateUtils } from '../dates/DateUtils';
import { MenuDefinition } from '@savvy/view-definition';
import { ConfirmationModalComponent } from '../shared/components/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ContextIdDto } from '@savvy/menu';
import { Subscription } from 'rxjs';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SmsEmailMessage, SmsEmailMessagePopupComponent } from './sms-email-message-popup/sms-email-message-popup.component';

@Component({
  selector: 'app-reminder-today',
  templateUrl: './remindersToday.component.html',
  styleUrls: ['./remindersToday.component.scss']
})
export class RemindersTodayComponent implements OnInit, OnDestroy {

  reminders: Array<Reminder>;
  reminder: any;
  selected: Reminder[] = [];
  contextIdDto: ContextIdDto;
  subscriptions: Subscription[] = [];
  daysToAdd: number = 0;
  reminderDefId: string = "";
  reminderDefs: ReminderDefinition[];
  loaded = false;
  showFilters = false;
  startDate = new Date();
  endDate = new Date();
  constructor(
    private remindersCompApi: RemindersCompService,
    private reminderDefsApi: ReminderDefsService,
    private navChange: NavChangeService,
    private dialog: MatDialog,
    private notify: FloSnackbarComponent,
    private dateUtils: DateUtils,
    public contextService: ContextService,
    public deviceService: DeviceDetectorService) { }

  ngOnInit() {
    this.init();
  }

  private init() {
    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
        this.navChange.add(MenuItemTypeEnum.RemindersToday);
        this.loadReminders(this.startDate, this.endDate);
        this.loadReminderDefs();
      }
    }));
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  private loadReminderDefs() {
    this.reminderDefsApi.loadReminderDefs(this.contextIdDto?.contextId, this.contextIdDto?.contextIdType).subscribe(reminderDefs => {
      this.reminderDefs = reminderDefs;
    });
  }

  loadReminders(startDate: Date, endDate: Date) {
    console.log('loading reminders');
    console.log('startDate is ' + startDate);
    console.log('endDate is ' + endDate);
    if (!startDate || !endDate) {
      return;
    }
    this.reminders = [];

    const request: LoadRemindersFiltered = {
      contextIdDto: this.contextIdDto,
      startDayToLoad: this.dateUtils.getDateAsStringV2(startDate),
      endDayToLoad: this.dateUtils.getDateAsStringV2(endDate),
      reminderDefinitionId: this.reminderDefId === "" ? undefined : this.reminderDefId
    }

    this.remindersCompApi.loadRemindersCompFiltered(request).subscribe(
      response => {
        this.handleResponse(response?.reminders);
      }, error => {
        this.handleResponse();
      }
    );
  }

  private handleResponse(reminders: Array<Reminder> = []) {
    this.reminders = reminders;
    this.loaded = true;
  }

  deleteReminder(reminder) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        name: 'reminder'
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && reminder?.reminder?.id && this.contextIdDto?.contextId && this.contextIdDto?.contextIdType) {
        this.remindersCompApi.deleteReminder(
          reminder?.reminder?.id, this.contextIdDto?.contextId,
          this.contextIdDto?.contextIdType).subscribe(() => {
            this.loadReminders(this.startDate, this.endDate);
          });
      }
    });
  }

  sendNow(reminder: Reminder) {
    if (reminder?.reminderId?.id && this.contextIdDto?.contextId && this.contextIdDto?.contextIdType) {
      this.remindersCompApi.sendReminderNow(reminder?.reminderId?.id,
        this.contextIdDto?.contextId,
        this.contextIdDto?.contextIdType
      ).subscribe(res => {
        if (res?.reminder?.reminderState === 'REMINDER_SENT') {
          this.notify.message = 'Reminder Sent';
          this.notify.open();
        } else {
          this.notify.message = 'Unable to send reminder.';
          this.notify.open();
        }
        this.loadReminders(this.startDate, this.endDate);
      });
    }
  }

  today() {
    if (this.daysToAdd !== 0) {
      this.startDate = new Date();
      this.endDate = new Date();
      this.daysToAdd = 0;
      this.loadReminders(this.startDate, this.endDate);
    }
  }

  tomorrow() {
    if (this.daysToAdd !== 1) {
      this.daysToAdd = 1;
      this.startDate = new Date();
      this.endDate = this.dateUtils.addDays(new Date(), 1);
      this.loadReminders(this.startDate, this.endDate);
    }
  }

  yesterday() {
    if (this.daysToAdd !== -1) {
      this.daysToAdd = -1;
      this.startDate = this.dateUtils.minusDays(new Date(), 1);
      this.endDate = new Date();
      this.loadReminders(this.startDate, this.endDate);
    }
  }

  showEntireReminderMessage(reminder: Reminder) {
    const data: SmsEmailMessage = {
      messageType: reminder?.reminderType,
      message: reminder?.expandedMessageText
    }
    const dialogRef = this.dialog.open(SmsEmailMessagePopupComponent, {
      data
    });

    dialogRef.afterClosed().subscribe(() => { });
  }
}
