<div *ngIf="!eventBus">
  ELEMENT EVENT BUS IS EMPTY
</div>


<div [formGroup]="form" *ngIf="elementDto">

<!--
  {{ elementDto?.elementType?.toString() }}
  -->


  <ng-container [ngSwitch]="elementDto?.elementType?.toString()">

    <ng-container *ngSwitchCase="'STEP_PANEL_DEFINITION'">
      <app-step-panel [contextIdDto]="contextIdDto" [stepPanelDto]="elementDto.stepPanelDto" [viewContext]="viewContext" [eventBus]="eventBus"
      [additionalDataMapDto]="additionalDataMapDto" [showPlaceholder]="showPlaceholder" [form]="form" [changeListener]="changeListener"></app-step-panel>
    </ng-container>

    <!--ng-container *ngSwitchCase="'ITINERARIES'">
       <app-itineraries-panel
         [contextIdDto]="contextIdDto"
         [viewContext]="viewContext"
         [eventBus]="eventBus"
         [additionalDataMapDto]="additionalDataMapDto"
         [form]="form"></app-itineraries-panel>
     </ng-container-->

    <ng-container *ngSwitchCase="'VIEW_CUSTOMER_PAGE_DEFINITION'">
      <app-view-customer-page [contextIdDto]="contextIdDto" [viewCustomerPageDefinitionDto]="elementDto.viewCustomerPageDefinitionDto" [viewContext]="viewContext" [eventBus]="eventBus" [additionalDataMapDto]="additionalDataMapDto" [showPlaceholder]="showPlaceholder" [form]="form" [changeListener]="changeListener"></app-view-customer-page>
    </ng-container>

    <ng-container *ngSwitchCase="'VIEW_APPOINTMENT_MEETINGS_DEFINITION'">
      <app-view-appointment-meetings [contextIdDto]="contextIdDto" [viewContext]="viewContext" [showPlaceholder]="showPlaceholder" [form]="form" [changeListener]="changeListener"></app-view-appointment-meetings>
    </ng-container>

    <ng-container *ngSwitchCase="'VIEW_CUSTOMER_CONTRACTS_DEFINITION'">
      <app-view-customer-contracts [contextIdDto]="contextIdDto" [customerId]="viewContext.customerContextDto.customerId.id" [showPlaceholder]="showPlaceholder" [form]="form" [changeListener]="changeListener"></app-view-customer-contracts>
    </ng-container>

    <ng-container *ngSwitchCase="'VIEW_CUSTOMER_PETS_DEFINITION'">
      <app-view-customer-pets [contextIdDto]="contextIdDto" [customerId]="viewContext.customerContextDto.customerId.id" [showPlaceholder]="showPlaceholder" [form]="form" [changeListener]="changeListener"></app-view-customer-pets>
    </ng-container>

    <ng-container *ngSwitchCase="'VIEW_CUSTOMER_INVOICES_DEFINITION'">
      <app-view-customer-invoices [contextIdDto]="contextIdDto" [customerId]="viewContext.customerContextDto.customerId.id" [showPlaceholder]="showPlaceholder" [form]="form" [changeListener]="changeListener"></app-view-customer-invoices>
    </ng-container>

    <ng-container *ngSwitchCase="'VIEW_CUSTOMER_GALLERIES_DEFINITION'">
      <app-view-customer-galleries [contextIdDto]="contextIdDto" [customerId]="viewContext.customerContextDto.customerId.id" [showPlaceholder]="showPlaceholder" [form]="form" [changeListener]="changeListener"></app-view-customer-galleries>
    </ng-container>

    <ng-container *ngSwitchCase="'VIEW_GALLERIES_DEFINITION'">
      <app-view-galleries [contextIdDto]="contextIdDto" [viewContext]="viewContext" [showPlaceholder]="showPlaceholder" [form]="form" [changeListener]="changeListener"></app-view-galleries>
    </ng-container>

    <ng-container *ngSwitchCase="'CONFLICT_CHECK_BUTTON'">
      <app-conflict-check [contextIdDto]="contextIdDto" [viewContext]="viewContext" [eventBus]="eventBus" [additionalDataMapDto]="additionalDataMapDto" [form]="form" [changeListener]="changeListener"></app-conflict-check>
    </ng-container>

    <!--ng-container *ngSwitchCase="'VIEW_CUSTOMER_HISTORY_BUTTON'">
       <app-view-customer-history-button
         [contextIdDto]="contextIdDto"
         [customerId]="viewContext.customerContextDto.customerId"
       ></app-view-customer-history-button>
     </ng-container-->

    <ng-container *ngSwitchCase="'LIST_ENTITY_TEASER'">
      <app-teaser-panel [eventBus]="eventBus" [teasersDto]="elementDto.listEntityTeasersDto" [contextIdDto]="contextIdDto"></app-teaser-panel>
    </ng-container>


    <ng-container *ngSwitchCase="'WORKFLOW_STATS_DATA'">
      <app-view-workflow-stats-panel [contextIdDto]="contextIdDto" [workflowStatsDto]="elementDto.workflowStatsDto"></app-view-workflow-stats-panel>
    </ng-container>

    <ng-container *ngSwitchCase="'FLAG_PANEL_DATA'">
      <app-view-flags-panel [contextIdDto]="contextIdDto" [flagPanelDataDto]="elementDto.flagPanelDataDto"></app-view-flags-panel>
    </ng-container>

    <ng-container *ngSwitchCase="'FLAG_PANEL_DEFINITION'">
      <app-add-edit-flags-panel [linkId]="viewContext.linkId" [contextIdDto]="contextIdDto"></app-add-edit-flags-panel>
    </ng-container>

    <ng-container *ngSwitchCase="'LIST_ENTITY_TABLE'">
      <app-list-entity-table [listEntityTableDto]="elementDto.listEntityTableDto" [contextIdDto]="contextIdDto"></app-list-entity-table>
    </ng-container>

    <ng-container *ngSwitchCase="'EI_POPUP_BUTTON_DEFINITION'">
      <app-ei-popup [eiPopupButtonDefinition]="elementDto.eiPopupButtonDefinition" [contextIdDto]="contextIdDto">
      </app-ei-popup>
    </ng-container>

    <ng-container *ngSwitchCase="'CUSTOMER_POPUP_BUTTON_DEFINITION'">
      <app-customer-popup [customerPopupButtonDefinition]="elementDto.customerPopupButtonDefinition" [contextIdDto]="contextIdDto">
      </app-customer-popup>
    </ng-container>

    <ng-container *ngSwitchCase="'LIST_ENTITY_IMAGES'">
      <app-image-grid-element [listEntityImagesDto]="elementDto.listEntityImagesDto" [contextIdDto]="contextIdDto">
      </app-image-grid-element>
    </ng-container>

    <ng-container *ngSwitchCase="'SOCIAL_WIDGET'">
      <app-social-widget [socialWidgetDto]="elementDto.socialWidgetDto" [contextIdDto]="contextIdDto">
      </app-social-widget>
    </ng-container>

    <ng-container *ngSwitchCase="'LINE_BREAK'">
      <hr class="mt-xs mb-xs">
    </ng-container>

    <ng-container *ngSwitchCase="'SINGLE_LABEL_VALUE_PANEL'">
      <app-single-label-value-panel [singleLabelValuePanelDto]="elementDto.singleLabelValuePanelDto">
      </app-single-label-value-panel>
    </ng-container>

    <!--<ng-container *ngSwitchCase="'LABEL_VALUE_PAIR'">-->

    <!--<app-label-value-pair [labelValuePairDto]="elementDto.labelValuePairDto"></app-label-value-pair>-->

    <!--</ng-container>-->

    <ng-container *ngSwitchCase="'LABEL_VALUE_PANEL'">
      <app-label-value-panel [labelValuePanelDto]="elementDto.labelValuePanelDto"></app-label-value-panel>
    </ng-container>

    <ng-container *ngSwitchCase="'LABEL_VALUE_LEFT_RIGHT_PANEL'">
      <app-label-value-left-right-panel [labelValueLeftRightPanelDto]="elementDto.labelValueLeftRightPanelDto"></app-label-value-left-right-panel>
    </ng-container>

    <ng-container *ngSwitchCase="'VIEW_INVOICE_PAYMENT_STATUS_PANEL_DATA'">
      <app-view-invoice-payment-status-panel [contextIdDto]="contextIdDto" [eventBus]="eventBus" [additionalDataMapDto]="additionalDataMapDto" [viewInvoicePaymentStatusDataDto]="elementDto.viewInvoicePaymentStatusDataDto" [viewContext]="viewContext">
      </app-view-invoice-payment-status-panel>
    </ng-container>

    <ng-container *ngSwitchCase="'VIEW_VACCINATIONS_DATA'">
      <app-view-vaccinations-panel [contextIdDto]="contextIdDto" [eventBus]="eventBus" [additionalDataMapDto]="additionalDataMapDto" [viewVaccinationsData]="elementDto.viewVaccinationsData" [viewContext]="viewContext">
      </app-view-vaccinations-panel>
    </ng-container>

    <ng-container *ngSwitchCase="'VIEW_INVOICE_PANEL_DATA'">
      <app-view-invoice-panel [contextIdDto]="contextIdDto" [form]="form" [eventBus]="eventBus" [changeListener]="changeListener" [additionalDataMapDto]="additionalDataMapDto" [invoiceId]="elementDto.viewInvoicePanelDataDto.invoice.invoiceId" [viewContext]="viewContext" [showPaymentRequests]="true">
      </app-view-invoice-panel>
    </ng-container>

    <ng-container *ngSwitchCase="'INVOICE_PANEL_DATA'">
      <app-invoice-panel [contextIdDto]="contextIdDto" [eventBus]="eventBus" [additionalDataMapDto]="additionalDataMapDto" [invoicePanelDataDto]="elementDto.invoicePanelDto" [viewContext]="viewContext">
      </app-invoice-panel>
    </ng-container>

    <ng-container *ngSwitchCase="'INVOICE_PACKAGE_PANEL_DATA'">
      <app-invoice-package-panel [contextIdDto]="contextIdDto" [eventBus]="eventBus" [form]="form" [additionalDataMapDto]="additionalDataMapDto" [currencyCode]="elementDto.invoicePackagePanelDataDto.currencyCode" [invoice]="elementDto.invoicePackagePanelDataDto.invoice" [userDtos]="elementDto.invoicePackagePanelDataDto.userDtos" [viewContext]="viewContext">
      </app-invoice-package-panel>
    </ng-container>

    <ng-container *ngSwitchCase="'INVOICE_SERVICES_PANEL_DATA'">
      <app-invoice-services-panel *ngIf="elementDto.invoiceServicesPanelDataDto.angularComponentVersion === 0"
        [contextIdDto]="contextIdDto" [eventBus]="eventBus" [form]="form" [additionalDataMapDto]="additionalDataMapDto"
        [changeListener]="changeListener"
        [allowMultipleServices]="elementDto.invoiceServicesPanelDataDto.allowMultipleServices"
        [createEiInline]="elementDto.invoiceServicesPanelDataDto.createEiInline"
        [eiExtraFields]="elementDto.invoiceServicesPanelDataDto.eiExtraFields"
        [consumerServices]="elementDto.invoiceServicesPanelDataDto.consumerServices"
        [products]="elementDto.invoiceServicesPanelDataDto.products"
        [currencyCode]="elementDto.invoiceServicesPanelDataDto.currencyCode"
        [invoiceDefinition]="elementDto.invoiceServicesPanelDataDto.invoiceDefinition"
        [invoice]="elementDto.invoiceServicesPanelDataDto.invoice"
        [invoicePanelDto]="elementDto.invoiceServicesPanelDataDto.invoicePanelDto"
        [userDtos]="elementDto.invoiceServicesPanelDataDto.userDtos" [viewContext]="viewContext">
      </app-invoice-services-panel>
      <app-base-invoice-services-panel *ngIf="elementDto.invoiceServicesPanelDataDto.angularComponentVersion === 2"
        [contextIdDto]="contextIdDto" [eventBus]="eventBus" [form]="form" [additionalDataMapDto]="additionalDataMapDto"
        [changeListener]="changeListener" [consumerServices]="elementDto.invoiceServicesPanelDataDto.consumerServices"
        [products]="elementDto.invoiceServicesPanelDataDto.products"
        [currencyCode]="elementDto.invoiceServicesPanelDataDto.currencyCode"
        [invoiceDefinition]="elementDto.invoiceServicesPanelDataDto.invoiceDefinition"
        [invoice]="elementDto.invoiceServicesPanelDataDto.invoice"
        [invoicePanelDto]="elementDto.invoiceServicesPanelDataDto.invoicePanelDto"
        [userDtos]="elementDto.invoiceServicesPanelDataDto.userDtos" [viewContext]="viewContext">

      </app-base-invoice-services-panel>
    </ng-container>

    <ng-container *ngSwitchCase="'WORKFLOW_PANEL'">
      <app-workflow-panel [contextIdDto]="contextIdDto" [eventBus]="eventBus" [additionalDataMapDto]="additionalDataMapDto" [workflowPanelDto]="elementDto.workflowPanelDto" [viewContext]="viewContext">
      </app-workflow-panel>
    </ng-container>

    <ng-container *ngSwitchCase="'WORKFLOW_EXECUTE_ACTION_PANEL_DATA'">
      <app-workflow-action-panel [contextIdDto]="contextIdDto" [eventBus]="eventBus" [additionalDataMapDto]="additionalDataMapDto" [workflowExecuteActionPanelDataDto]="elementDto.workflowExecuteActionPanelDataDto" [changeListener]="changeListener" [viewContext]="viewContext">
      </app-workflow-action-panel>
    </ng-container>

    <ng-container *ngSwitchCase="'VIEW_IMAGE'">
      <app-view-image [contextIdDto]="contextIdDto" [viewImageDto]="elementDto.viewImageDto" [viewContext]="viewContext">
      </app-view-image>
    </ng-container>

    <ng-container *ngSwitchCase="'VIEW_DOCUMENT'">
      <app-view-document [contextIdDto]="contextIdDto" [viewDocumentDto]="elementDto.viewDocumentDto" [viewContext]="viewContext">
      </app-view-document>
    </ng-container>

    <ng-container *ngSwitchCase="'VIEW_VIDEO'">
      <app-view-video [contextIdDto]="contextIdDto" [viewVideoDto]="elementDto.viewVideoDto" [viewContext]="viewContext">
      </app-view-video>
    </ng-container>

    <ng-container *ngSwitchCase="'VIEW_ENTITY_PANEL_DATA'">
      <app-view-entity-panel [contextIdDto]="contextIdDto" [viewEntityPanelDataDto]="elementDto.viewEntityPanelDataDto" [viewContext]="viewContext" [eventBus]="eventBus" [additionalDataMapDto]="additionalDataMapDto" [showPlaceholder]="showPlaceholder" [form]="form" [changeListener]="changeListener">
      </app-view-entity-panel>
    </ng-container>

    <ng-container *ngSwitchCase="'VIEW_ENTITY_WITH_IMAGE_PANEL_DATA'">
      <app-view-entity-with-image-panel [contextIdDto]="contextIdDto" [viewEntityWithImagePanelDataDto]="elementDto.viewEntityWithImagePanelDataDto" [viewContext]="viewContext" [eventBus]="eventBus" [additionalDataMapDto]="additionalDataMapDto" [showPlaceholder]="showPlaceholder" [form]="form" [changeListener]="changeListener">
      </app-view-entity-with-image-panel>
    </ng-container>

    <ng-container *ngSwitchCase="'CREATE_APPOINTMENT_VIEW_DATA'">
      <app-create-appointment-view [contextIdDto]="contextIdDto" [createAppointmentViewData]="elementDto.createAppointmentViewData" [viewContext]="viewContext" [eventBus]="eventBus" [additionalDataMapDto]="additionalDataMapDto" [showPlaceholder]="showPlaceholder" [form]="form" [changeListener]="changeListener">
      </app-create-appointment-view>
    </ng-container>

    <ng-container *ngSwitchCase="'VIEW_CUSTOMER_APPOINTMENT_HISTORY_DATA'">
      <app-view-customer-appointment-history-panel [contextIdDto]="contextIdDto" [viewCustomerAppointmentHistoryDataDto]="elementDto.viewCustomerAppointmentHistoryDataDto" [viewContext]="viewContext" [eventBus]="eventBus" [additionalDataMapDto]="additionalDataMapDto" [showPlaceholder]="showPlaceholder" [form]="form" [changeListener]="changeListener">
      </app-view-customer-appointment-history-panel>
    </ng-container>

    <ng-container *ngSwitchCase="'VIEW_PET_APPOINTMENT_DATA'">
      <app-view-pet-appointments [contextIdDto]="contextIdDto" [viewPetAppointmentData]="elementDto.viewPetAppointmentData" [viewContext]="viewContext" [eventBus]="eventBus" [additionalDataMapDto]="additionalDataMapDto" [showPlaceholder]="showPlaceholder" [form]="form" [changeListener]="changeListener">
      </app-view-pet-appointments>
    </ng-container>




    <ng-container *ngSwitchCase="'VIEW_APPOINTMENT_PANEL_DATA'">
      <app-view-appointment-panel [contextIdDto]="contextIdDto" [appointmentPanelData]="elementDto.appointmentPanelDataDto" [viewContext]="viewContext" [eventBus]="eventBus" [additionalDataMapDto]="additionalDataMapDto" [showPlaceholder]="showPlaceholder" [form]="form" [changeListener]="changeListener">
      </app-view-appointment-panel>
    </ng-container>

    <ng-container *ngSwitchCase="'VIEW_ENTITY_CARD_DEFINITION'">
      <app-view-entity-card [contextIdDto]="contextIdDto" [viewEntityCardDefinition]="elementDto.viewEntityCardDefinitionDto" [viewContext]="viewContext" [eventBus]="eventBus" [additionalDataMapDto]="additionalDataMapDto" [showPlaceholder]="showPlaceholder" [form]="form" [changeListener]="changeListener">
      </app-view-entity-card>
    </ng-container>

    <ng-container *ngSwitchCase="'TAB_PANEL'">
      <app-tab-panel [contextIdDto]="contextIdDto" [tabPanelDefinitionDto]="elementDto.tabPanelDefinitionDto" [viewContext]="viewContext" [eventBus]="eventBus" [additionalDataMapDto]="additionalDataMapDto" [showPlaceholder]="showPlaceholder" [form]="form" [changeListener]="changeListener">
      </app-tab-panel>
    </ng-container>

    <ng-container *ngSwitchCase="'SECTION_HEADER'">
      <app-section-header [contextIdDto]="contextIdDto" [sectionHeaderDefinitionDto]="elementDto.sectionHeaderDefinitionDto" [viewContext]="viewContext" [eventBus]="eventBus" [additionalDataMapDto]="additionalDataMapDto" [showPlaceholder]="showPlaceholder" [form]="form" [changeListener]="changeListener">
      </app-section-header>
    </ng-container>

    <ng-container *ngSwitchCase="'TOOL_BAR'">
      <app-tool-bar [contextIdDto]="contextIdDto" [eventBus]="eventBus" [toolBarDto]="elementDto.toolBarDto" [viewContext]="viewContext" [additionalDataMapDto]="additionalDataMapDto" [showPlaceholder]="showPlaceholder" [form]="form">
      </app-tool-bar>
    </ng-container>

    <ng-container *ngSwitchCase="'LIST_MESSAGES_DEFINITION'">
      <app-list-messages-panel [contextIdDto]="contextIdDto" [showPlaceholder]="showPlaceholder" [viewContext]="viewContext" [changeListener]="changeListener">
      </app-list-messages-panel>
    </ng-container>

    <ng-container *ngSwitchCase="'SECTION'">
      <app-section [contextIdDto]="contextIdDto" [eventBus]="eventBus" [sectionDefinitionDto]="elementDto.sectionDefinitionDto" [viewContext]="viewContext" [additionalDataMapDto]="additionalDataMapDto" [showPlaceholder]="showPlaceholder" [form]="form" [changeListener]="changeListener">
      </app-section>
    </ng-container>

    <ng-container *ngSwitchCase="'SELECTION_LIST'">
      <app-selection-list [elementDto]="elementDto" [contextIdDto]="contextIdDto" [viewContext]="viewContext" [additionalDataMapDto]="additionalDataMapDto" [showPlaceholder]="showPlaceholder" [form]="form" [changeListener]="changeListener">
      </app-selection-list>
    </ng-container>

    <ng-container *ngSwitchCase="'VIEW_REMINDERS'">
      <app-view-reminders-panel [contextIdDto]="contextIdDto" [showPlaceholder]="showPlaceholder" [eventBus]="eventBus" [viewRemindersDto]="elementDto.viewRemindersDto" [viewContext]="viewContext" [changeListener]="changeListener">
      </app-view-reminders-panel>
    </ng-container>

    <ng-container *ngSwitchCase="'AVATAR_ROW'">
      <app-avatar-row [avatarRowDto]="elementDto.avatarRowDto">
      </app-avatar-row>
    </ng-container>

    <ng-container *ngSwitchCase="'VIEW_ENTITY_INSTANCE'">
      <app-view-entity-instance [viewEntityInstanceDto]="elementDto.viewEntityInstanceDto" [eventBus]="eventBus" [contextIdDto]="contextIdDto">
      </app-view-entity-instance>
    </ng-container>

    <ng-container *ngSwitchCase="'VIEW_ENTITY_EMBEDDED_DATA'">
      <app-view-embedded-entity-instance [viewEntityEmbeddedData]="elementDto.viewEntityEmbeddedData" [eventBus]="eventBus" [contextIdDto]="contextIdDto">
      </app-view-embedded-entity-instance>
    </ng-container>

    <ng-container *ngSwitchCase="'VIEW_CUSTOMER_EMBEDDED_DATA'">
      <app-view-embedded-customer [viewCustomerEmbeddedData]="elementDto.viewCustomerEmbeddedData" [eventBus]="eventBus" [contextIdDto]="contextIdDto">
      </app-view-embedded-customer>
    </ng-container>

    <ng-container *ngSwitchCase="'VIEW_CACHED_TABLE_VIEW_EMBEDDED_DATA'">
      <app-view-embedded-cached-table [viewCachedTableEmbeddedData]="elementDto.viewCachedTableEmbeddedData" [eventBus]="eventBus" [viewContext]="viewContext" [contextIdDto]="contextIdDto">
      </app-view-embedded-cached-table>
    </ng-container>

    <ng-container *ngSwitchCase="'VIEW_INVOICES_BY_EI_LIST_VIEW_DATA'">
      <app-view-invoices-by-ei-list [viewInvoicesByEiListData]="elementDto.viewInvoicesByEiListData" [eventBus]="eventBus" [viewContext]="viewContext" [contextIdDto]="contextIdDto">
      </app-view-invoices-by-ei-list>
    </ng-container>

    <ng-container *ngSwitchCase="'VIEW_APPTS_BY_EI_LIST_VIEW_DATA'">
      <app-view-appts-by-ei-list [viewAppointmentsByEiListData]="elementDto.viewAppointmentsByEiListData" [eventBus]="eventBus" [viewContext]="viewContext" [contextIdDto]="contextIdDto">
      </app-view-appts-by-ei-list>
    </ng-container>

    <ng-container *ngSwitchCase="'VIEW_CUSTOMER_APPTS_VIEW_DATA'">

<!--
      <app-view-appts-by-customer-list *ngIf="!elementDto.viewCustomerAppointmentsData.useAppointmentRepository" [viewCustomerAppointmentsData]="elementDto.viewCustomerAppointmentsData" [eventBus]="eventBus" [viewContext]="viewContext" [contextIdDto]="contextIdDto">
      </app-view-appts-by-customer-list>

      <app-view-appts-by-customer-list-v2 *ngIf="elementDto.viewCustomerAppointmentsData.useAppointmentRepository" [viewCustomerAppointmentsData]="elementDto.viewCustomerAppointmentsData" [eventBus]="eventBus" [viewContext]="viewContext" [contextIdDto]="contextIdDto">
      </app-view-appts-by-customer-list-v2>
      -->

      <app-view-appts-by-customer-list-v1-v2 [viewCustomerAppointmentsData]="elementDto.viewCustomerAppointmentsData" [eventBus]="eventBus"
      [viewContext]="viewContext" [contextIdDto]="contextIdDto">
      </app-view-appts-by-customer-list-v1-v2>
    </ng-container>

    <ng-container *ngSwitchCase="'VIEW_APPOINTMENT_DATA'">
      <app-view-appt-history [viewAppointmentData]="elementDto.viewAppointmentData" [eventBus]="eventBus" [viewContext]="viewContext" [contextIdDto]="contextIdDto">
      </app-view-appt-history>
    </ng-container>

    <ng-container *ngSwitchCase="'VIEW_ASSOCIATED_CACHED_TABLE_VIEW_EMBEDDED_DATA'">
      <app-view-associated-embedded-cached-table [viewAssociatedCachedTableEmbeddedData]="elementDto.viewAssociatedCachedTableEmbeddedData" [eventBus]="eventBus" [viewContext]="viewContext" [contextIdDto]="contextIdDto">
      </app-view-associated-embedded-cached-table>
    </ng-container>

    <ng-container *ngSwitchCase="'SIMPLE_MESSAGES_PANEL_DEFINITION'">
      <app-simple-messages-panel [contextIdDto]="contextIdDto" [showPlaceholder]="showPlaceholder" [eventBus]="eventBus" [linkId]="viewContext.linkId" [changeListener]="changeListener">
      </app-simple-messages-panel>
    </ng-container>

    <ng-container *ngSwitchCase="'STRING_VALUE'">
      <div *ngIf="elementDto.sttringValueDto">
        {{elementDto.sttringValueDto.strValue}}
      </div>
      <div *ngIf="!elementDto.sttringValueDto">
        ERROR MISSING STRING VALUE
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'SPLIT_PANEL'">
      <app-split-panel [contextIdDto]="contextIdDto" [splitPanelDefinitionDto]="elementDto.splitPanelDefinitionDto" [viewContext]="viewContext" [eventBus]="eventBus" [additionalDataMapDto]="additionalDataMapDto" [showPlaceholder]="showPlaceholder" [form]="form" [changeListener]="changeListener">
      </app-split-panel>
    </ng-container>

    <ng-container *ngSwitchCase="'ZERO_INSTANCES'">
      <app-zero-instances [zeroEntitiesDto]="elementDto.zeroEntitiesDto" [viewContext]="viewContext" [contextIdDto]="contextIdDto">
      </app-zero-instances>
    </ng-container>

    <ng-container *ngSwitchCase="'ENTITY_TASKS_PANEL_DEFINITION'">
      <app-entity-tasks-panel [contextIdDto]="contextIdDto" [eventBus]="eventBus" [showPlaceholder]="showPlaceholder" [viewContext]="viewContext" [changeListener]="changeListener">
      </app-entity-tasks-panel>
    </ng-container>

    <ng-container *ngSwitchCase="'VERTICAL_PANEL'">
      <app-vertical-panel *ngIf="elementDto.verticalPanelDefinitionDto" [contextIdDto]="contextIdDto" [verticalPanelDefinitionDto]="elementDto.verticalPanelDefinitionDto" [viewContext]="viewContext" [eventBus]="eventBus" [additionalDataMapDto]="additionalDataMapDto" [showPlaceholder]="showPlaceholder" [form]="form" [changeListener]="changeListener">
      </app-vertical-panel>
    </ng-container>

    <ng-container *ngSwitchCase="'HORIZONTAL_PANEL'">
      <app-horizontal-panel *ngIf="elementDto.horizontalPanelDefinitionDto" [contextIdDto]="contextIdDto" [horizontalPanelDefinitionDto]="elementDto.horizontalPanelDefinitionDto" [viewContext]="viewContext" [eventBus]="eventBus" [additionalDataMapDto]="additionalDataMapDto" [showPlaceholder]="showPlaceholder" [form]="form" [changeListener]="changeListener">
      </app-horizontal-panel>
    </ng-container>

    <ng-container *ngSwitchCase="'LIST_CHECK_IN_FORMS_DATA'">
      <app-list-check-in-forms [contextIdDto]="contextIdDto" [listCheckInFormsListDto]="elementDto.listCheckInFormsListDto" [viewContext]="viewContext" [showPlaceholder]="showPlaceholder" [form]="form" [changeListener]="changeListener">
      </app-list-check-in-forms>
    </ng-container>

    <ng-container *ngSwitchCase="'LIST_INVOICE_LIST'">
      <app-list-invoice-list [contextIdDto]="contextIdDto" [listInvoiceListDto]="elementDto.listInvoiceListDto" [viewContext]="viewContext" [showPlaceholder]="showPlaceholder" [form]="form" [changeListener]="changeListener">
      </app-list-invoice-list>
    </ng-container>

    <ng-container *ngSwitchCase="'LIST_QUESTIONNAIRES_LIST'">
      <app-list-questionnaires-list [contextIdDto]="contextIdDto" [listQuestionnairesListDto]="elementDto.listQuestionnairesListDto" [viewContext]="viewContext" [eventBus]="eventBus" [showPlaceholder]="showPlaceholder" [form]="form" [changeListener]="changeListener">
      </app-list-questionnaires-list>
    </ng-container>

    <ng-container *ngSwitchCase="'LIST_CONTRACT_LIST'">
      <app-list-contract-list [contextIdDto]="contextIdDto" [listContractListDto]="elementDto.listContractListDto" [viewContext]="viewContext" [showPlaceholder]="showPlaceholder" [form]="form" [changeListener]="changeListener">
      </app-list-contract-list>
    </ng-container>

    <ng-container *ngSwitchCase="'LIST_CACHED_ENTITYS_LIST_DEFINITION'">
      <app-list-cached-entitys-list [contextIdDto]="contextIdDto" [listCachedEntityListDefinition]="elementDto.listCachedEntityListDefinition" [viewContext]="viewContext" [showPlaceholder]="showPlaceholder" [form]="form" [changeListener]="changeListener">
      </app-list-cached-entitys-list>
    </ng-container>

    <ng-container *ngSwitchCase="'VIEW_ASSOCIATED_CACHED_LIST_VIEW_DATA'">
      <app-view-associated-cached-entitys-list [contextIdDto]="contextIdDto" [viewAssociatedCachedListData]="elementDto.viewAssociatedCachedListData" [viewContext]="viewContext" [showPlaceholder]="showPlaceholder" [form]="form" [changeListener]="changeListener">
      </app-view-associated-cached-entitys-list>
    </ng-container>

    <ng-container *ngSwitchCase="'VIEW_CACHED_ENTITYS_LIST_DEFINITION'">
      <app-view-list-cached-entities [contextIdDto]="contextIdDto" [viewCachedEntitiesListDefinition]="elementDto.viewCachedEntitiesListDefinition" [viewContext]="viewContext" [eventBus]="eventBus" [showPlaceholder]="showPlaceholder" [form]="form" [changeListener]="changeListener">
      </app-view-list-cached-entities>
    </ng-container>

    <ng-container *ngSwitchCase="'VIEW_CACHED_ENTITYS_EXPANDABLE_DEFINITION'">
      <app-view-expandable-cached-entities [contextIdDto]="contextIdDto" [viewCachedEntitiesExpandableDefinition]="elementDto.viewCachedEntitiesExpandableDefinition" [viewContext]="viewContext" [eventBus]="eventBus" [showPlaceholder]="showPlaceholder" [form]="form" [changeListener]="changeListener">
      </app-view-expandable-cached-entities>
    </ng-container>



    <ng-container *ngSwitchCase="'VIEW_INVOICE_ENTITYS_CACHED_TABLE_DATA'">
      <app-view-invoice-entities-cached-table [contextIdDto]="contextIdDto" [viewInvoiceEntitiesCachedTableData]="elementDto.viewInvoiceEntitiesCachedTableData" [viewContext]="viewContext" [eventBus]="eventBus" [showPlaceholder]="showPlaceholder" [form]="form" [changeListener]="changeListener">
      </app-view-invoice-entities-cached-table>
    </ng-container>

    <ng-container *ngSwitchCase="'VIEW_INVOICE_ENTITYS_EXPANDABLE_DATA'">
      <app-view-expandable-invoice-entities [contextIdDto]="contextIdDto" [viewInvoiceEntitiesExpandableData]="elementDto.viewInvoiceEntitiesExpandableData" [viewContext]="viewContext" [eventBus]="eventBus" [showPlaceholder]="showPlaceholder" [form]="form" [changeListener]="changeListener">
      </app-view-expandable-invoice-entities>
    </ng-container>

    <ng-container *ngSwitchCase="'LIST_CACHED_ENTITYS_TABLE_DATA'">
      <app-list-cached-entitys-table [contextIdDto]="contextIdDto" [listCachedTableViewDataDto]="elementDto.listCachedTableViewDataDto" [viewContext]="viewContext" [showPlaceholder]="showPlaceholder" [form]="form" [changeListener]="changeListener">
      </app-list-cached-entitys-table>
    </ng-container>

    <ng-container *ngSwitchCase="'LIST_ENTITYS_LIST'">
      <app-list-entitys-list [contextIdDto]="contextIdDto" [listEntityListDto]="elementDto.listEntityListDto" [viewContext]="viewContext" [showPlaceholder]="showPlaceholder" [form]="form" [changeListener]="changeListener">
      </app-list-entitys-list>
    </ng-container>

    <ng-container *ngSwitchCase="'LIST_ORDERS_LIST'">
      <app-list-order-list [contextIdDto]="contextIdDto" [listOrderListDto]="elementDto.listOrderListDto" [viewContext]="viewContext" [showPlaceholder]="showPlaceholder" [form]="form" [changeListener]="changeListener">
      </app-list-order-list>
    </ng-container>

    <ng-container *ngSwitchCase="'CARD_DEFINITION'">
      <app-card-definition [contextIdDto]="contextIdDto" [eventBus]="eventBus" [cardDefinitionDto]="elementDto.cardDefinitionDto" [viewContext]="viewContext" [additionalDataMapDto]="additionalDataMapDto" [showPlaceholder]="showPlaceholder" [form]="form" [changeListener]="changeListener">
      </app-card-definition>
    </ng-container>

    <ng-container *ngSwitchCase="'NEWS'">
      <app-news-widget style="height: 100%; width: 100%" [newsDto]="elementDto.newsDto" [contextIdDto]="contextIdDto">
      </app-news-widget>
    </ng-container>

    <ng-container *ngSwitchCase="'ENTITY_SUMMARY_DTO'">
      <app-entity-summary-widget style="height: 100%; width: 100%" [entitySummaryDto]="elementDto.entitySummaryDto" [contextIdDto]="contextIdDto">
      </app-entity-summary-widget>
    </ng-container>

    <ng-container *ngSwitchCase="'PAYMENTS_SUMMARY'">
      <app-payments-summary-widget style="height: 100%; width: 100%" [paymentsSummaryDto]="elementDto.paymentsSummaryDto" [contextIdDto]="contextIdDto">
      </app-payments-summary-widget>
    </ng-container>

    <ng-container *ngSwitchCase="'ORDERS_SUMMARY_DTO'">
      <app-orders-summary-widget style="height: 100%; width: 100%" [ordersSummaryDto]="elementDto.ordersSummaryDto" [contextIdDto]="contextIdDto">
      </app-orders-summary-widget>
    </ng-container>

    <ng-container *ngSwitchCase="'QUOTES_SUMMARY'">
      <app-quotes-summary-widget style="height: 100%; width: 100%" [quotesSummaryDto]="elementDto.quotesSummaryDto" [contextIdDto]="contextIdDto">
      </app-quotes-summary-widget>
    </ng-container>





    <ng-container *ngSwitchCase="'VIEW_CUSTOMER_CHECKIN_FORMS_DEFINITION'">
      <app-customer-check-in-forms style="height: 100%; width: 100%" [viewCustomerCheckinFormsDefinition]="elementDto.viewCustomerCheckinFormsDefinition" [viewContext]="viewContext" [contextIdDto]="contextIdDto">
      </app-customer-check-in-forms>
    </ng-container>

    <ng-container *ngSwitchCase="'VIEW_CUSTOMER_PLANS_DEFINITION'">
      <app-customer-plans style="height: 100%; width: 100%" [viewCustomerPlansDefinition]="elementDto.viewCustomerPlansDefinition" [viewContext]="viewContext" [contextIdDto]="contextIdDto">
      </app-customer-plans>
    </ng-container>

    <ng-container *ngSwitchCase="'CONTACT_FORM_SUMMARY'">
      <app-contact-forms-summary-widget style="height: 100%; width: 100%" [contactFormsSummaryDto]="elementDto.contactFormsSummaryDto" [contextIdDto]="contextIdDto">
      </app-contact-forms-summary-widget>
    </ng-container>

    <ng-container *ngSwitchCase="'BADGE'">
      <app-badge-widget [badgeDto]="elementDto.badgeDto" [contextIdDto]="contextIdDto">
      </app-badge-widget>
    </ng-container>

    <ng-container *ngSwitchCase="'MAP_PANEL_DEFINITION'">
      <app-map-panel>
      </app-map-panel>
    </ng-container>

    <ng-container *ngSwitchCase="'BAR_CHART'">
      <app-bar-chart-widget [barChartDto]="elementDto.barChartDto">
      </app-bar-chart-widget>
    </ng-container>

    <ng-container *ngSwitchCase="'DONUT_CHART'">
      <app-donut-chart [donutChartDto]="elementDto.donutChartDto">
      </app-donut-chart>
    </ng-container>

    <ng-container *ngSwitchCase="'VIEW_CUSTOMER'">
      <app-view-customer-panel [viewCustomer]="elementDto.viewCustomerDto" [contextIdDto]="contextIdDto">
      </app-view-customer-panel>
    </ng-container>

    <ng-container *ngSwitchCase="'WEATHER_PANEL_DEFINITION'">
      <app-weather-panel>
      </app-weather-panel>
    </ng-container>

    <ng-container *ngSwitchCase="'ELEMENT_INSTANCE'">
      <app-element-instance [contextIdDto]="contextIdDto" [elementDto]="elementDto" [eventBus]="eventBus" [viewContext]="viewContext" [additionalDataMapDto]="additionalDataMapDto" [showPlaceholder]="showPlaceholder" [form]="form" [changeListener]="changeListener">
      </app-element-instance>
    </ng-container>

  </ng-container>

</div>
