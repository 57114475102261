import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { StorageService } from '@savvy/storage';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { ConfService } from '../config/conf.service';
import { forkJoin, Observable } from 'rxjs';
import { ContextIdDto, FileValue } from '@savvy/view-definition';
import { NgxCroppedEvent, NgxPhotoEditorService } from 'ngx-photo-editor';
import { ResizeImage } from './resize-image';

@Component({
  selector: 'app-image-grid',
  templateUrl: 'imageGrid.component.html',
  styleUrls: ['imageGrid.component.scss']
})
export class ImageGridComponent implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() resizeToWidth;
  @Input() resizeToHeight;
  @Input() images: Array<FileValue>;
  @Input() publicBucket: boolean;
  @Input() allowedToSelect = false;
  @Input() selectedImage: FileValue;
  @Input() numberOfImagesAllowed: number;
  @Output() imagesUpdated = new EventEmitter<Array<FileValue>>();
  @Output() imageDeleted = new EventEmitter<number>();
  @Output() imageSelected = new EventEmitter<FileValue>();
  aspectRatio;
  droppedFilesArray: File[];
  isMobile: boolean;
  selectedImageUrl = '';
  selectedImageIndex = -1;
  imageFile: any;

  constructor(
    private storage: StorageService,
    private notify: FloSnackbarComponent,
    private deviceService: DeviceDetectorService,
    private ngxPhotoEditorService: NgxPhotoEditorService
    ) {
  }

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
  }

  getUploadUrl() {
    return ConfService.apiUrl() + '/rest/storage/uploadFileToStorageV2?contextId=' +
      this.contextIdDto.contextId + '&contextIdType=' +
      this.contextIdDto.contextIdType + '&public=' + this.publicBucket;
  }

  getUrl(id: string) {
    return ConfService.apiUrl() + '/rest/storage?public=' + this.publicBucket + '&contextId=' +
      this.contextIdDto.contextId + '&contextIdType=' +
      this.contextIdDto.contextIdType + '&id=' + id;
  }

  deleteImage(idx: number) {
    console.log('deleting image in position ' + idx);
    this.imageDeleted.emit(idx);
  }

  async editImage(idx: number, image) {
    console.log('editing image in position ' + this.images[idx]);
    this.selectedImageUrl = this.getUrl(this.images[idx].fileReferenceId);
    this.selectedImageIndex = idx;
    let response = await fetch(this.selectedImageUrl);
    let data = await response.blob();
    let file = new File([data], this.images[idx]?.fileName || 'test.png');
    this.ngxPhotoEditorService.open(file, {
      // aspectRatio: 4 / 3,
      autoCropArea: 100
    }).subscribe((croppedEvent: NgxCroppedEvent) => {
      this.imageCropped(croppedEvent);
    });
  }

  postFile(fileToUpload: File): Observable<any> {
    console.log('uploading file to storage api');
    return this.storage.uploadFileToStorageV2(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      this.publicBucket,
      fileToUpload);
  }

  saveFiles(files) {
    if (this.numberOfImagesAllowed && files && ((files.length + (this.images?.length || 0)) > this.numberOfImagesAllowed)) {
      this.notify.message = `Only ${this.numberOfImagesAllowed} files are allowed to upload`;
      this.notify.open();
      return;
    }
    console.log('saveFiles called' + files);
    const filesObservable = [];
    for (const file of files) {
      console.log('saveFiles called');
      filesObservable.push(this.postFile(file));
    }

    forkJoin(filesObservable).subscribe(res => {
      res.forEach((elem, index) => {
        const newFile: FileValue = {
          fileName: files[index].name, mimeType: files[index].type, fileReferenceId: elem['fileReference']
        };
        console.log('calling savedFiles push');
        if (!this.images) {
          this.images = [];
        }
        this.images.push(newFile);
      });
      console.log('calling updateFiles');
      this.droppedFilesArray = [];
      this.onChange();
    });
  }

  onChange() {
    console.log('onChange images is ', this.images);
    this.imagesUpdated.emit(this.images);
  }

  droppedFiles(files: NgxFileDropEntry[]) {
    console.log('droppedFiles called, files is ', files.length);
    this.droppedFilesArray = [];
    if (files.length === 1) {
      for (const droppedFile of files) {
        if (droppedFile.fileEntry.isFile) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) => {
            this.imageFile = file;
            this.ngxPhotoEditorService.open(file, {
              // aspectRatio: 4 / 3,
              autoCropArea: 100
            }).subscribe((croppedEvent: NgxCroppedEvent) => {
              this.imageCropped(croppedEvent);
            });
          });
        }
      }
    } else {
      for (const droppedFile of files) {
        if (droppedFile.fileEntry.isFile) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) => {
            this.droppedFilesArray.push(file);
            if (files.length === this.droppedFilesArray.length) {
              this.saveFiles(this.droppedFilesArray);
            }
          });
        }
      }
    }
  }

  imageCropped(event: NgxCroppedEvent) {
    if (!this.images) {
      this.images = [];
    }
    // if (this.selectedImageIndex > -1) {
    //   this.images.splice(this.selectedImageIndex, 1);
    // }
    if (event?.file) {
      ResizeImage.resizeImage(
        {
          file: event.file,
          maxSize: this.resizeToWidth
        }
      ).then((res: any) => {
        this.postFile(res).subscribe((newFile) => {
          if (this.selectedImageIndex > -1) {
            this.images[this.selectedImageIndex] = {
              fileName: event.file.name,
              mimeType: event.file.type,
              fileReferenceId: newFile['fileReference']
            };
          } else {
            this.images.push({
              fileName: event.file.name,
              mimeType: event.file.type,
              fileReferenceId: newFile['fileReference']
            });
          }
          this.imageFile = undefined;
          this.selectedImageUrl = '';
          this.onChange();
        });
      });
    }
  }

  onImageload(event, td) {
    td.width = event.target.width;
    td.height = event.target.height;
    // alert(event.width + 'x' + .height);
  }

  imageSelectionChanged(event) {
    this.imageSelected.emit(event.value);
  }
}

