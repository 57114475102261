import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { AppUpdaterService } from '@savvy/app-metadata';
import { ContextIdDto } from '@savvy/menu';
import { Subscription } from 'rxjs';
import { ContextService } from 'src/app/flo/context.service';
import { FloSnackbarComponent } from 'src/app/flo/snackbar/floSnackbar.component';

@Component({
  selector: 'app-app-version-update-banner',
  templateUrl: './app-version-update-banner.component.html',
  styleUrls: ['./app-version-update-banner.component.scss']
})
export class AppVersionUpdateBannerComponent implements OnInit, OnDestroy {
  contextIdDto: ContextIdDto;
  subscriptions: Subscription[] = [];

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<AppVersionUpdateBannerComponent>,
    private contextService: ContextService,
    private appUpdaterService: AppUpdaterService,
    private notify: FloSnackbarComponent
  ) { }

  ngOnInit() {
    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
      }
    }));
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  update(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    console.log('calling scheduleMigration');
    this.appUpdaterService.performMigrations(this.contextIdDto.contextId).subscribe(response => {
      this.notify.message = 'Scheduled product update';
      this.notify.open();
    });
    event.preventDefault();
  }
}
