

<div *ngIf="this.customer && this.customer.deleted">
  Customer has been archived
</div>
<div *ngIf="this.customer && !this.customer.deleted && !this.customer.approvalNeeded">
  Customer has been verified
</div>

<div *ngIf="this.customer && this.customer.approvalNeeded  && !this.customer.deleted">
  <button (click)="verify()" mat-button color="accent"
          class="px-1">
    Verify
  </button>
  <button (click)="archive()" mat-button color="accent"
          class="px-1">
    Archive
  </button>
</div>
