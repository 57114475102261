import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContextIdDto } from '@savvy/app';
import {
  CreateWorkflowDefinition,
  WorkflowDefinitionTypeData,
  WorkflowDefinitionTypeItem,
  WorkflowdefService,
  UpdateWorkflowDetails
} from '@savvy/workflow-definition';
import { Observable } from 'rxjs';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';

@Component({
  selector: 'app-add-edit-workflow-dialog',
  templateUrl: './add-edit-workflow-dialog.component.html',
  styleUrls: ['./add-edit-workflow-dialog.component.scss']
})
export class AddEditWorkflowDialogComponent implements OnInit {

  contextIdDto: ContextIdDto;
  workflowTypes: WorkflowDefinitionTypeItem[] = [];
  menuItemTypeOptions: Observable<string[]>;

  workflowForm = new UntypedFormGroup({
    workflowName: new UntypedFormControl('', [Validators.required]),
    workflowType: new UntypedFormControl('', [Validators.required])
  });


  constructor(@Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<AddEditWorkflowDialogComponent>,
    private api: WorkflowdefService,
    public notify: FloSnackbarComponent) {

  }

  ngOnInit(): void {
    this.contextIdDto = this.data.contextIdDto;
    this.loadWorkflowTypes();

    if (this.data.workflowDefinition) {
      this.setWorkflowForm();
    }

  }

  setWorkflowForm(): void {
    this.workflowForm.patchValue({
      workflowName: this.data.workflowDefinition.name
    });
  }

  cancel() {
    this.dialogRef.close({ response: false });
  }

  workflowTypeComparator(option: WorkflowDefinitionTypeItem, value: WorkflowDefinitionTypeData) {
    if (!value) {
      return;
    }
    return option.workflowDefinitionTypeData.workflowDefinitionType === value.workflowDefinitionType;
  }

  loadWorkflowTypes() {
    this.api.getValidWorkflowTypes(
      this.contextIdDto.contextId, this.contextIdDto.contextIdType
    ).subscribe(response => {
      this.workflowTypes = response;
      if (this.data.workflowDefinition) {
        const workflowTypeComparator = this.workflowTypes.filter(v =>
          this.workflowTypeComparator(v, this.data.workflowDefinition.workflowDefinitionTypeData));
        if (workflowTypeComparator && workflowTypeComparator.length) {
          this.workflowForm.patchValue({
            workflowType: workflowTypeComparator[0]
          });
        }
      }
    });
  }

  createWorkflow() {

    const creaetWorkflowDefRequest: CreateWorkflowDefinition = {
      contextIdDto: this.contextIdDto,
      workflowName: this.workflowForm.value.workflowName,
      workflowDefinitionTypeData: this.workflowForm.value.workflowType.workflowDefinitionTypeData
    };

    this.api.createWorkflowDef(creaetWorkflowDefRequest).subscribe(response => {
      if (response && response.id) {
        this.notify.message = 'Workflow successfully created.';
        this.notify.open();
      }
    }, () => {
      this.notify.message = 'Failed to create Workflow.';
      this.notify.open();
    }, () => {
      this.dialogRef.close({ response: true });
    });
  }

  updateWorkflow() {

    const updateWorkflowDetailsRequest: UpdateWorkflowDetails = {
      contextIdDto: this.contextIdDto,
      workflowDefinitionId: this.data.workflowDefinition.workflowDefinitionId.id,
      workflowName: this.workflowForm.value.workflowName,
      workflowDefinitionTypeData: this.workflowForm.value.workflowType.workflowDefinitionTypeData
    };

    this.api.updateWorkflowDetails(updateWorkflowDetailsRequest).subscribe(response => {
      if (response && response.updatedWorkflowDefinition && response.updatedWorkflowDefinition.id) {
        this.notify.message = 'Workflow successfully updated.';
        this.notify.open();
      }
    }, () => {
      this.notify.message = 'Failed to update Workflow.';
      this.notify.open();
    }, () => {
      this.dialogRef.close({ response: true });
    });
  }

}
