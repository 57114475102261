import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CheckinFormCompService, SendCheckinForm } from '@savvy/check-in-form';
import { ContextIdDto } from '@savvy/datalist';
import { EmailMessageTemplate, EmailmessagetemplatesService } from '@savvy/email-template';
import { LoadUserForLink, UsercompService } from '@savvy/user';
import { CheckinForm, EmailRecipient } from '@savvy/view-composite';
import { UrlLookupService } from '../shared/services/urlLookup.service';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { LinkedInstanceFactory } from './linkedInstanceFactory';

@Component({
  selector: 'app-view-check-in-form-modal',
  templateUrl: './viewCheckInFormModal.component.html'
})
export class ViewCheckInFormModalComponent implements OnInit {

  contextIdDto: ContextIdDto;

  sub;
  subject: string;
  body: string;

  showSpinner: boolean;
  allowedToSend: boolean;

  recipients: string;

  emailAddress: string;
  viewContext: any;
  checkInForm: CheckinForm;
  emailMessageTemplate: EmailMessageTemplate;
  public form: UntypedFormGroup; // our form model
  customerRecipient: EmailRecipient;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ViewCheckInFormModalComponent>,
    private notify: FloSnackbarComponent,
    private userCompApi: UsercompService,
    private urlService: UrlLookupService,
    private checkinFormCompositeApi: CheckinFormCompService,
    private emailMessageApi: EmailmessagetemplatesService,
    public translateService: TranslateService) { }

  ngOnInit(): void {
    this.contextIdDto = this.data.contextIdDto;
    this.emailAddress = this.data.emailAddress;
    this.checkInForm = this.data.checkInForm;
    this.viewContext = this.data.viewContext;

    this.emailMessageApi.getEmailMessageTemplateByType(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType, 'checkInForm').subscribe(response => {
        console.log('loading type');
        this.emailMessageTemplate = response;
        if (response) {
          this.subject = response.subject;
          this.body = response.body;
        } else {
          this.subject = 'Check-In Form';
          this.body = 'Here is your Checkin Form.  Click below to enter the form in our Customer Portal.';
        }
      });
    this.loadRecipient();
  }

  cancel() {
    this.dialogRef.close();
  }

  delete() {

  }

  loadRecipient() {
    if (this.checkInForm) {
      if (this.checkInForm.entityInstanceId) {
        const li = LinkedInstanceFactory.createLinkedInstance(this.viewContext);
        const req = <LoadUserForLink>{};
        req.linkedInstanceDto = li;
        req.contextIdDto = this.contextIdDto;

        this.userCompApi.loadUserForLink(req).subscribe(response => {
          if (response.userDto) {
            if (response.userDto.optIn) {
              this.allowedToSend = response.userDto.optIn.allowEmail;
            }
            this.recipients = response.userDto.email;
            this.customerRecipient = <EmailRecipient>{};
            this.customerRecipient.address = response.userDto.username;
            this.customerRecipient.recipientId = response.userDto.userId?.id;
            this.customerRecipient.recipientType = EmailRecipient.RecipientTypeEnum.Customer;

          } else {
          }
        });
      } else {
        console.log('no link id set');
      }
    } else {
      console.log('ERROR: No check in form!!');
    }
  }

  bodyUpdated(text: string) {
    this.body = text;
  }

  send() {
    this.showSpinner = true;
    console.log('onSubmit');
    const req = <SendCheckinForm>{};
    req.contextIdDto = this.contextIdDto;
    req.checkinFormId = this.checkInForm.checkinFormId;
    req.checkinFormDefinitionId = this.checkInForm.checkinFormDefinitionId;

    this.checkinFormCompositeApi.sendCheckInForm(req).subscribe(response => {
      console.log('Got response! ' + response);
      if (response.sendMessageError) {
        console.log('there was an error!');
        this.notify.message = 'Error sending check in form ' + response.sendMessageError;
        this.notify.open();
        /*
        this.translateService.get('Sent Invoice')
          .subscribe(val => {
            this.notify.message = val;
            this.notify.open();
          });
         */
      } else {
        this.notify.message = 'Sent Checkin Form';
        this.notify.open();
        this.cancel();
      }
      this.showSpinner = false;
    });
  }

  view() {
    const url = this.urlService.portalUrl() + '/viewSubmittedCheckinForm.html?id=' + this.contextIdDto.contextId +
      '&checkinFormId=' + this.checkInForm.id + this.urlService.getEnv();
    console.log('url', url);
    this.urlService.openNewTab(url);
  }

  enter() {
    const url = this.urlService.portalUrl() + '/enterCheckinForm.html?id=' + this.contextIdDto.contextId +
      '&checkinFormId=' + this.checkInForm.id + this.urlService.getEnv();
    console.log('url', url);
    this.urlService.openNewTab(url);
  }
}
