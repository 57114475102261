import { Component, Input, OnInit } from '@angular/core';

import { UntypedFormGroup } from '@angular/forms';
import { ElementControlService } from '../ElementControlService';
import { EicompService, EntityChangeEvent } from '@savvy/entity-instance-composite';
import { DateTimeAdapter, NativeDateTimeAdapter, OWL_DATE_TIME_LOCALE, OwlDateTimeIntl } from '@danielmoncada/angular-datetime-picker';
import { TranslateService } from '@ngx-translate/core';
import { LocalIntl } from '../../../services/local-intl.service';
import { ChangeListener } from '../changeListener';
import { DaateTimeFieldDefinitionDto, ElementInstanceDto, FieldValueDto } from '@savvy/view-composite';
import { ContextIdDto } from '@savvy/datalist';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'app-date-time-instance-time-picker',
  templateUrl: 'dateTimeInstanceTimePicker.html',
  providers: [
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-US' },
    { provide: DateTimeAdapter, useClass: NativeDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE, Platform] },
    { provide: OwlDateTimeIntl, useClass: LocalIntl },
  ],
  styles: [`
    .open-icon {
      cursor: pointer;
    }
  `]
})
export class DateTimeInstanceTimePickerComponent implements OnInit {
  private timeout: any = null;

  @Input() dateTimeDefinition: DaateTimeFieldDefinitionDto;
  @Input() elementInstanceDto: ElementInstanceDto;
  @Input() entityInstanceId: string;
  @Input() contextIdDto: ContextIdDto;
  @Input() form: UntypedFormGroup;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;

  constructor(
    private api: EicompService,
    private translateService: TranslateService,
    dateTimeAdapter: DateTimeAdapter<any>,
    private ecs: ElementControlService
  ) {
    dateTimeAdapter.setLocale(this.translateService.currentLang);
  }

  get isValid() {
    return this.form.controls[this.elementInstanceDto.instanceId].valid;
  }

  ngOnInit(): void {

    if (this.form.controls[this.elementInstanceDto.instanceId] &&
      this.form.controls[this.elementInstanceDto.instanceId].valueChanges) {
      this.form.controls[this.elementInstanceDto.instanceId].valueChanges.subscribe((value) => {
        console.log('changed value=' + value);
        this.onChange(value);
      });
    } else {
      console.log('Error, missing field');
    }
  }

  getOffSet() {
    return new Date().getTimezoneOffset();
  }

  changeEvent(newValue) {

    console.log('onChange detected new value is ' + newValue);
    // console.log('onChange detected new value is toISOString() ' + newValue.toISOString());
    // console.log('onChange detected new value is toLocaleString() ' + newValue.toLocaleString());
    // console.log('onChange detected new value is toDateString() ' + newValue.toDateString());
    // console.log('onChange detected new value is toString() ' + newValue.toString());

    const offset = new Date().getTimezoneOffset();
    console.log('client side offset is ' + offset);

    const fieldValue: FieldValueDto = {
      // dateTimeValue: newValue.toLocaleString(),
      dateTimeValue: newValue,
      valueType: FieldValueDto.ValueTypeEnum.DateTime
    };

    const req: EntityChangeEvent = {
      contextIdDto: this.contextIdDto,
      fieldValue,
      entityInstanceId: this.entityInstanceId,
      fieldInstanceId: this.elementInstanceDto.instanceId
    };

    // There is no loadProductsResponse from this, do we care?
    this.api.entityInstanceChangeEvent(req).subscribe(
      response => {
        // EntityInstanceChangeResponse
        console.log('loadProductsResponse got from update, calling handle changed elements');
        this.ecs.handleChangedElements(response.changedElementList, this.form);
        if (this.changeListener) {
          this.changeListener.add('change');
        }
      }
    );
  }

  onChange(newValue: Date) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.changeEvent(newValue);
    }, 500);
  }
}

