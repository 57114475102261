<div>
  <mat-card appearance="outlined" class="customer-panel new-card" *ngIf="viewCustomer && viewCustomer.userDto">
    <mat-card-header fxLayoutAlign="space-between baseline">
      <mat-card-title>Customer</mat-card-title>
      <div class="customer-actions" fxLayout="row" fxLayoutAlign="start center">
        <div>
          <button (click)="editCustomer()" class="ml-xs mat-icon-button" mat-icon-button="">
            <span class="mat-button-wrapper">
              <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">edit</mat-icon>
            </span>
            <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
            <div class="mat-button-focus-overlay"></div>
          </button>
        </div>
      </div>
    </mat-card-header>
    <div class="entry-title" fxLayout="row" fxLayoutAlign="start center">
      <ngx-avatars (click)="viewCustomerPage()" name="{{viewCustomer.userDto.firstName}}" bgColor="pink" class="pointer" size="30" [style.border-radius]="'50%'"></ngx-avatars>
      <h4><a class="text-underline" (click)="viewCustomerPage()">{{viewCustomer.userDto.firstName}}
          {{viewCustomer.userDto.lastName}}</a></h4>
    </div>
    <mat-card-content class="entry-list" *ngIf="viewCustomer && viewCustomer.userDto">
      <div fxLayout="column" *ngIf="(viewCustomer.customer.companyName && viewCustomer.customer.companyName.length > 0) ||
        (viewCustomer.customer.companyNumber && viewCustomer.customer.companyNumber.length > 0)">
        <div fxFlex="100">
          <div fxFlex="30">
            <p class="mat-text-muted mt-xs mb-xs">{{'Company Name'| translate}}</p>
          </div>
          <div fxFlex="70">
            <p class="mt-xs mb-xs">
              {{viewCustomer.customer.companyName}}
            </p>
          </div>
        </div>
        <div fxFlex="100">
          <div fxFlex="30">
            <p class="mat-text-muted mt-xs mb-xs">
              {{'Company Number'| translate}}</p>
          </div>
          <div fxFlex="70">
            <p class="mt-xs mb-xs">
              &nbsp;{{viewCustomer.customer.companyNumber}}
            </p>
          </div>
        </div>
      </div>

      <div fxLayout="row" class="entry-list">
        <div fxFlex="100" fxLayout="column">
          <div fxFlex="100" *ngIf="viewCustomer?.userDto?.mobilePhoneNumberObj?.internationalNumber">
            <div fxFlex="30">
              <p class="mat-text-muted mt-xs mb-xs">
                {{'Mobile'| translate}}</p>
            </div>
            <div fxFlex="70">
              <p class="mt-xs mb-xs">
                {{viewCustomer.userDto.mobilePhoneNumberObj?.internationalNumber}}
              </p>
            </div>
          </div>
          <div fxFlex="100" *ngIf="viewCustomer?.userDto?.phoneNumberObj?.internationalNumber">
            <div fxFlex="30">
              <p class="mat-text-muted mt-xs mb-xs">
                {{'Phone'| translate}}</p>
            </div>
            <div fxFlex="70">
              <p class="mt-xs mb-xs">
                {{viewCustomer.userDto.phoneNumberObj.internationalNumber}}
              </p>
            </div>
          </div>
          <div fxFlex="100" *ngIf="viewCustomer?.userDto?.workPhoneNumberObj?.internationalNumber">
            <div fxFlex="30">
              <p class="mat-text-muted mt-xs mb-xs">
                {{'Work Phone'| translate}}</p>
            </div>
            <div fxFlex="70">
              <p class="mt-xs mb-xs">
                {{viewCustomer.userDto.workPhoneNumberObj.internationalNumber}}
              </p>
            </div>
          </div>
          <div fxFlex="100" *ngIf="viewCustomer?.userDto?.email">
            <div fxFlex="30">
              <p class="mat-text-muted mt-xs mb-xs">
                {{'Email'| translate}}</p>
            </div>
            <div fxFlex="70">
              <p class="mt-xs mb-xs" style="word-break: break-all;">
                {{viewCustomer.userDto.email}}
              </p>
            </div>
          </div>
          <!-- <div fxFlex="100" *ngIf="viewCustomer?.userDto?.whatsAppNumberObj?.internationalNumber">
            <div fxFlex="30">
              <p class="mat-text-muted mt-xs mb-xs">Whats App</p>
            </div>
            <div fxFlex="70">
              <p class="mt-xs mb-xs">
                {{viewCustomer.userDto.whatsAppNumberObj.internationalNumber}}
              </p>
            </div>
          </div> -->
          <div fxFlex="100" *ngIf="viewCustomer?.customer?.notes">
            <div fxFlex="30">
              <p class="mat-text-muted mt-xs mb-xs">Notes</p>
            </div>
            <div fxFlex="70">
              <p class="mt-xs mb-xs">
                {{viewCustomer?.customer?.notes}}
              </p>
            </div>
          </div>
          <div class="mt-xs mb-xs" fxFlex="100" *ngIf="viewCustomer?.customer?.address?.address1">
            <div fxFlex="30">
              <p class="mat-text-muted mt-xs mb-xs">Address</p>
            </div>
            <div fxFlex="70">
              <p class="mt-xs mb-xs" (click)="openGoogleMaps()">
                {{viewCustomer?.customer?.address?.address1}}
                {{viewCustomer?.customer?.address?.address2}}
                {{viewCustomer?.customer?.address?.address3}}
                {{viewCustomer?.customer?.address?.city}}
                {{viewCustomer?.customer?.address?.state}}
                {{viewCustomer?.customer?.address?.zipCode}}
              </p>
            </div>
          </div>
          <div class="mt-xs mb-xs" fxFlex="100" *ngIf="viewCustomer?.customer?.address?.longitude">
            <div fxFlex="30">&nbsp;</div>
            <div fxFlex="70">
              <button mat-flat-button color="accent" *ngIf="viewCustomer?.customer?.address?.longitude" (click)="openGoogleMaps()">View On Map</button>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card appearance="outlined" class="customer-panel new-card" *ngIf="portalEnabled && viewCustomer && viewCustomer.userDto">
    <mat-card-content>
      <div fxLayout="row" class="entry-list">
        <div fxFlex="100" fxLayout="column">
          <div fxFlex="100">
            <div fxFlex="30">
              <p class="mat-text-muted mt-xs mb-xs">
                {{'Portal'| translate}}</p>
            </div>
            <div fxFlex="70">
              <p class="mt-xs mb-xs">
                <button color="accent" mat-raised-button (click)="resetPassword()">Set Password</button>&nbsp;
                <button color="accent" mat-raised-button (click)="registerToPortal()">Invite</button>&nbsp;
                <button color="accent" mat-raised-button (click)="gotoPortal()">Portal</button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

</div>