import { Component, Input, OnInit } from '@angular/core';
import {ContextIdDto, Feature } from '@savvy/add-ons';
import { Pet } from '@savvy/pet';
import { PetService } from '@savvy/pet';
import { ClearObservable } from 'src/app/flo/shared/classes/clear-observable';
import { FeaturesHelperService } from 'src/app/flo/shared/services/features-helper.service';
import { FloSnackbarComponent } from 'src/app/flo/snackbar/floSnackbar.component';

@Component({
  selector: 'app-pet-description',
  templateUrl: './pet-description.component.html',
  styleUrls: ['./pet-description.component.scss']
})
export class PetDescriptionComponent extends ClearObservable implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() pet: Pet;
  isVaccinationFeatureAllowed = false;
  editNotes = false;

  constructor(
    private featuresHelperService: FeaturesHelperService,
    private petService: PetService,
    private notify: FloSnackbarComponent,
  ) {
    super();
  }

  ngOnInit(): void {
    this.isVaccinationFeatureAllowed = this.featuresHelperService.isFeatureAllowed(Feature.TypeEnum.Vaccinations);

  }

  saveNotes() {
    this.petService.updatePet(this.pet)
      .subscribe(res => {
        if (res) {
          this.notify.message = 'Notes Updated';
          this.notify.open();
          this.editNotes = false;
          this.pet = res;
        }
      });
  }
}
