import { AfterViewChecked, Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Chat, ChatMessage, ChatService, ListChats } from '@savvy/chat';
import { ContextIdDto } from '@savvy/menu';
import { StorageService } from '@savvy/storage';
import * as saveAs from 'file-saver';
import * as moment from 'moment';
import { NgxCroppedEvent, NgxPhotoEditorService } from 'ngx-photo-editor';
import { Subscription } from 'rxjs';
import { ConfService } from '../../config/conf.service';
import { ContextService } from '../../context.service';
import { SingleImageUpdated } from '../../images/viewSingleImage.component';
import { Page } from '../../shared/model/page';

@Component({
  selector: 'app-help-modal',
  templateUrl: './help-modal.component.html',
  styleUrls: ['./help-modal.component.scss']
})
export class HelpModalComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('chatbox') private chatbox: ElementRef;
  chatMessage = '';
  chat: Chat = {
    chatMessages: [],
    lastModifiedDate: ''
  };
  timer;
  errorCounter = 120000; // 2 min

  contextIdDto: ContextIdDto;
  subscriptions: Subscription[] = [];
  chats: Array<Chat> = [];
  sideNavOpen = true;
  attachedFile;
  attachedFileType = 'image';

  page = new Page();

  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<HelpModalComponent>,
    public chatService: ChatService,
    private storage: StorageService,
    private contextService: ContextService,
    private ngxPhotoEditorService: NgxPhotoEditorService,
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
        this.listChats();
        this.scrollToBottom();
      }
    }));
  }

  ngAfterViewChecked() {
  }

  toggleSidenav() {
    this.sideNavOpen = !this.sideNavOpen;
  }

  scrollToBottom(): void {
    try {
      setTimeout(() => {
        this.chatbox.nativeElement.scrollTop = this.chatbox.nativeElement.scrollHeight;
      }, 500);
    } catch (err) { }
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    clearInterval(this.timer);
  }

  // Every 10 secs load
  startToLoadChatMessages(count = 5000) {
    this.timer = setInterval(() => {
      this.loadChatMessages(this.chat?.id);
      // this.scrollToBottom();
    }, count);
  }

  loadChatMessages(chatId) {
    if (chatId) {
      this.chatService.getChat(this.contextIdDto.contextId, this.contextIdDto.contextIdType, chatId).subscribe(response => {
        this.handleResponse(response);
        this.markChatAsRead(chatId);
      }, () => {
        clearInterval(this.timer);
        this.startToLoadChatMessages(this.errorCounter);
        this.errorCounter = this.errorCounter + 120000;
      });
    }
  }

  markChatAsRead(chatId) {
    if (chatId) {
      this.chatService.markAsRead(this.contextIdDto.contextId, this.contextIdDto.contextIdType, chatId, true).subscribe(response => { });
    }
  }

  listChats() {
    this.chatService.listChats1(this.contextIdDto.contextId, this.contextIdDto.contextIdType, this.page.pageNumber + 1, this.page.size).subscribe(response => {
      if (response?.contents?.length > 0) {
        this.chats = response?.contents;
        this.loadChatMessages(this.chats[0].id);
      } else {
        this.resetChat();
      }
    }, () => {
      this.resetChat();
    });
  }

  send() {
    if (!this.chatMessage || this.chatMessage.trim() === '') {
      return;
    }
    const chatMessage: ChatMessage = {
      message: this.chatMessage,
      senderId: this.contextIdDto.contextId,
      time: moment().toISOString()
    };
    if (this.attachedFile) {
      chatMessage.fileReference = {
        fileReferenceId: this.attachedFile,
        fileName: this.attachedFile,
        publicBucket: true
      };
    }
    if (!this.chat.chatMessages) {
      this.chat.chatMessages = new Array<ChatMessage>();
    }
    if (this.chat?.id) {
      this.chat.chatMessages.push(chatMessage);
    } else {
      this.chat.chatMessages.push(chatMessage);
    }
    if (!this.chat.id) {
      this.chatService.create(this.chat).subscribe(response => {
        this.chatMessage = '';
        this.attachedFile = null;
        this.chat = response;
      }, error => {
        this.attachedFile = null;
      });
    } else {
      this.chatService.sendChatMessage(this.chat.id, chatMessage).subscribe(response => {
        this.chatMessage = '';
        this.attachedFile = null;
        this.chat = response.updatedChat;
        this.scrollToBottom();
      }, error => {
        this.attachedFile = null;
      });
    }
  }

  // addNewChat() {
  //   this.chatMessage = '';
  //   this.attachedFile = null;
  //   this.chat = {
  //     chatMessages: [],
  //     lastModifiedDate: ''
  //   };
  //   const chat: Chat = {
  //     chatMessages: [],
  //     lastModifiedDate: new Date().toISOString()
  //   };
  //   this.chatService.create(chat).subscribe(res => {
  //     if (res) {
  //       this.listChats();
  //     }

  //   });
  // }

  selectChat(chat: Chat) {
    this.chatMessage = '';
    this.attachedFile = null;
    this.chat = chat;
    this.scrollToBottom();
    this.markChatAsRead(chat?.id);
    clearInterval(this.timer);
    this.startToLoadChatMessages();
  }

  updateChatStatus() {
    this.chat.open = !this.chat.open;
    this.chatService.update(this.chat).subscribe(response => {
      this.chatMessage = '';
      this.attachedFile = null;
      this.chat = response;
    }, error => {

    });
  }

  resetChat() {
    this.attachedFile = null;
    this.chatMessage = '';
    this.chats = [];
    this.chat = {
      chatMessages: [],
      lastModifiedDate: ''
    };
    // this.addNewChat();
  }

  handleResponse(response) {
    this.chat = response;
    clearInterval(this.timer);
    this.startToLoadChatMessages();
  }

  attachFile() {

  }

  handleImageInput(event) {
    // if (event.target.closest('datatable-body-cell')) {
    //   event.target.closest('datatable-body-cell').blur();
    // }
    if (event?.currentTarget?.files[0]?.type?.indexOf('video') > -1) {
      // video file
      this.attachedFileType = 'video';
      this.postFile(event.currentTarget?.files[0]);
    } else {
      this.attachedFileType = 'image';
      this.ngxPhotoEditorService.open(event, {
        // aspectRatio: 4 / 3,
        autoCropArea: 100
      }).subscribe((croppedEvent: NgxCroppedEvent) => {
        this.imageCropped(croppedEvent);
      });
    }
  }

  handleEditImageInput(event) {
    this.ngxPhotoEditorService.open(event, {
      // aspectRatio: 4 / 3,
      autoCropArea: 100
    }).subscribe((croppedEvent: NgxCroppedEvent) => {
      this.imageCropped(croppedEvent);
    });
  }

  imageCropped(event: NgxCroppedEvent) {
    this.postFile(event.file);
  }

  postFile(fileToUpload: File) {
    if (fileToUpload) {
      this.storage.uploadFileToStorageV2(this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        false,
        fileToUpload).subscribe(response => {
          if (response?.fileReference) {
            this.attachedFile = response?.fileReference;
          }
        });
    }
  }

  getUrl(reference: string) {
    if (reference) {
      return ConfService.apiUrl() + '/rest/storage?public=false&contextId=' +
        this.contextIdDto.contextId + '&contextIdType=' +
        this.contextIdDto.contextIdType + '&id=' + reference;
    }
    return null;
  }

  downloadImage(url: string) {
    saveAs(this.getUrl(url), url);
  }

  imageUpdated(singleImageUpdated: SingleImageUpdated) {
    if (singleImageUpdated?.fileReference) {
      if (singleImageUpdated.rowIdx >= 0) {
        console.log('row is found');
        this.attachedFile = singleImageUpdated.fileReference;

        this.chat.chatMessages[singleImageUpdated.rowIdx].fileReference.fileReferenceId = singleImageUpdated.fileReference;
        this.chatService.update(this.chat).subscribe(response => {
          this.chatMessage = '';
          this.attachedFile = null;
          this.chat = response;
          this.scrollToBottom();
        }, error => {
          this.attachedFile = null;
        });
      } else {
        console.log('row not found!');
        // this.addImages(singleImageUpdated?.fileReference);
      }

    }
  }

  onScrollDown(ev) {
    console.log("scrolled down!!", ev);
    this.page.pageNumber++;
    this.chatService.listChats1(this.contextIdDto.contextId, this.contextIdDto.contextIdType, this.page.pageNumber + 1, this.page.size).subscribe(response => {
      if (response?.contents?.length > 0) {
        this.chats = [...this.chats, ...response?.contents] || [];
        this.page.totalElements = response.totalElements;
        this.page.totalPages = response.totalPages;
        // this.loadChatMessages(this.chats[0].id);
      }
    }, () => {
      this.resetChat();
    });
  }

}
