import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class TimeUtils {

  // Returns 7:24 am for example
  getTimeForDisplay(date: Date): string {
    if (!date) {
      return null;
    }
    let hour = date.getHours();
    const min = date.getMinutes();
    let amOrPm = ' am';
    if (hour > 11) {
      amOrPm = ' pm';
    }
    if (hour > 12) {
      hour = hour - 12;
    }

    if (min > 0) {
      return hour + ':' + this.pad(min, 2) + amOrPm;
    } else {
      return hour + amOrPm;
    }
  }

  pad(num: number, size: number): string {
    let s = num + '';
    while (s.length < size) {
      s = '0' + s;
    }
    return s;
  }

  // Returns 12:13 from Date string: 2018-10-31T14:00:00.000Z
  getHourMinTimeFromFullDateString(localDateTime: string): string {
    // console.log('getting date 2 ' + localDateTime.constructor.name);
    // console.log('getting time ' + localDateTime);
    // 2018-10-31T14:00:00.000Z
    const hour = localDateTime.slice(11, 13);
    const min = localDateTime.slice(14, 16);
    // console.log('getting time with ' + hour + ' min ' + min);
    return hour + ':' + min;
  }

  // HH:mm:ss
  getDateFromTime(timeStr: string): Date {
    if (!timeStr) {
      return null;
    }
    const dateToRet = new Date();
    const timeToSendParsed = moment(timeStr, 'HH:mm:ss');
    return new Date(dateToRet.getFullYear(), dateToRet.getMonth(), dateToRet.getDay(),
      timeToSendParsed.hour(), timeToSendParsed.minute(), timeToSendParsed.second(), 0);
  }

  getDateFromLocalTime(localTime): Date {
    if (!localTime) {
      return null;
    }
    const dateToRet = new Date();
    return new Date(dateToRet.getFullYear(), dateToRet.getMonth(), dateToRet.getDay(),
      localTime.hour, localTime.minute, localTime.second, 0);
  }

  // Formats date into "HH:mm:ss"
  getHoursMinsSecsTimeString(date: Date) {
    if (date) {
      console.log('date hours [' + date.getHours() + '] mins [' + date.getMinutes() + ']');
      const d = new Date(date);
      let hour = '' + d.getHours();
      let minute = '' + d.getMinutes();
      let second = '' + d.getSeconds();

      if (hour.length < 2) {
        hour = '0' + hour;
      }
      if (minute.length < 2) {
        minute = '0' + minute;
      }
      if (second.length < 2) {
        second = '0' + second;
      }

      return [hour, minute, second].join(':');
    }
    return null;
  }

  dateToLocalTime(date: Date) {
    if (date) {
      console.log('date hours [' + date.getHours() + '] mins [' + date.getMinutes() + ']');
      const d = new Date(date);
      const localTime = {};
      localTime['hour'] = d.getHours();
      localTime['minute'] = d.getMinutes();
      localTime['second'] = d.getSeconds();
      return localTime;
    }
    return null;
  }

  // Round a date's minutes, in place, to the nearest multiple of an interval.
  roundMinutesToInterval(date: Date, interval: number) {
    date.setMinutes(Math.round(date.getMinutes() / interval) * interval)
  }
}
