import { AfterContentChecked, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EventBusService } from './EventBusService';
import { ChangeListener } from './changeListener';
import { UntypedFormGroup } from '@angular/forms';
import {
  EntityDefinitionId,
  UserDto, UserId
} from '@savvy/view-definition';
import { ContextIdDto } from '@savvy/quickbooks';
import { AdditionalDataMapDto, ViewContextDto } from '@savvy/view-composite';
import { Product } from '@savvy/products';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DateUtils } from '../dates/DateUtils';
import { InvoiceItemRow, InvoicePackageRow, Invoice, InvoiceService } from '@savvy/invoice';
import { ElementControlService } from './ElementControlService';
import { InvoicecompService, UpdateInvoiceComp } from '@savvy/invoice';
import { UserCurrencyService } from '../shared/services/userCurrency.service';
import { PhoneNumberHelperService } from '../shared/services/phone-number-helper.service';
import { DateTimeAdapter } from '@danielmoncada/angular-datetime-picker';
import { TranslateService } from '@ngx-translate/core';
import { LookAndFeelService } from '@savvy/look-and-feel';
import { LookAndFeelSharedService, TimeValue } from '../shared/services/look-and-feel-shared.service';
import { SendInvoiceDialogComponent } from '../invoice/sendInvoiceDialog.component';
import { RequestPaymentDialogComponent } from '../invoice/requestPaymentDialog.component';
import { takeUntil } from 'rxjs/operators';
import { EventType } from '../event/UiEvent';
import * as _ from 'lodash';
import { Package, PackagesCompositeService } from '@savvy/packages';
import { TaxService } from '@savvy/tax';
import { AddPackageDialogComponent } from './fieldinstance/addPackageDialog.component';
import { ServicesCompositeService } from '@savvy/services';

@Component({
  selector: 'app-invoice-package-panel',
  templateUrl: 'invoicePackagePanel.component.html'
})
export class InvoicePackagePanelComponent implements OnInit, AfterContentChecked {

  @Input() invoice: Invoice;
  @Input() userDtos: Array<UserDto>;
  @Input() viewContext: ViewContextDto;
  @Input() contextIdDto: ContextIdDto;
  @Input() additionalDataMapDto: AdditionalDataMapDto;
  @Input() eventBus: EventBusService;
  @Input() allowMultiplePackages: boolean;
  @Input() form: UntypedFormGroup;
  @Input() products: Array<Product>;
  @Input() changeListener: ChangeListener;
  @Input() currencyCode: string;

  @Output() taskSelected = new EventEmitter();
  @Output() taskDeselected = new EventEmitter();
  @Output() invoiceUpdated = new EventEmitter();

  valid = false;
  init = false;
  customerId: string;
  packages: Array<Package>;
  timeArray: TimeValue[] = [];

  searchService = false;

  private destroy$ = new Subject();
  searchPackage = '';


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private dateUtils: DateUtils,
    private invoiceService: InvoiceService,
    private ecs: ElementControlService,
    private invoicecompService: InvoicecompService,
    private userCurrencyService: UserCurrencyService,
    private sharedService: PhoneNumberHelperService,
    private dateTimeAdapter: DateTimeAdapter<any>,
    private translateService: TranslateService,
    private lookAndFeelApi: LookAndFeelService,
    private lookAndFeelService: LookAndFeelSharedService,
    private packagesCompositeService: PackagesCompositeService,
    private serviceApi: ServicesCompositeService,
    private taxService: TaxService) {

    console.log('setting locale to ', this.translateService.currentLang);
    this.dateTimeAdapter.setLocale(this.translateService.currentLang);

  }


  ngOnInit(): void {
    console.log('invoice coming in as ', this.invoice);
    if (!this.invoice.invoicePackageRows || this.invoice.invoicePackageRows.length === 0) {
      this.createRow();
    } else {
      this.ensureFirstRowSet();
    }
    this.lookAndFeelApi.getLookAndFeel(this.contextIdDto.contextId).subscribe(response => {
      //   console.log('setting time array');
      this.timeArray = this.lookAndFeelService.generateLocalisedTimeArray(response.timeWidgetPreferences.timeDropDownPeriod,
        response.timeDisplayPreferences);
    });
    this.loadPackages();
  }

  loadPackages() {
    this.packagesCompositeService.loadPackages(this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(response => {
      this.packages = response.packages;
    });
  }

  ngAfterContentChecked(): void {
    this.init = true;
  }

  sendInvoice() {
    this.dialog.open(SendInvoiceDialogComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        invoice: this.invoice,
        eventBus: this.eventBus
      },
      autoFocus: false
    });
  }

  requestForPayment() {
    this.dialog.open(RequestPaymentDialogComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        invoice: this.invoice,
        eventBus: this.eventBus
      },
      autoFocus: false
    });
  }

  ensureFirstRowSet() {
    if (!this.invoice.invoicePackageRows[0].serviceUser) {
      this.invoice.invoicePackageRows[0].serviceUser = this.userDtos[0].userId;
    }
    if (!this.invoice.invoicePackageRows[0].packageNumber) {
      if (this.packages && this.packages.length > 0) {
        this.invoice.invoicePackageRows[0].packageNumber = this.packages[0].id;
        this.invoice.invoicePackageRows[0].quantity = 1;
        this.invoice.invoicePackageRows[0].unitPrice = this.packages[0].packagePrice;
        this.invoice.invoicePackageRows[0].tax = this.getTaxById(this.packages[0].taxEntityInstanceId);

      } else {
        console.log('err no services');
      }
    }
  }


  setValid() {
    if (this.init) {
      const valid = this.isValid();
      console.log('setting invoicePackagePanel validity state');
      this.form.controls['invoicePackagePanel'].setErrors({ incorrect: !valid });

      if (this.valid) {
        console.log('setting invoicePackagePanel valid true');
        this.form.controls['invoicePackagePanel'].setValue('abc');
      } else {
        console.log('setting invoicePackagePanel valid false');
        this.form.controls['invoicePackagePanel'].setValue('');
      }
    }
  }

  isValid() {
    console.log('checking is valid');
    this.valid = true;
    if (this.invoice.invoicePackageRows.length == 0) {
      console.log('No invoice item rows');
      this.valid = false;
    }

    console.log('imsp:returning valid ' + this.valid);
    return this.valid;
  }

  priceChanged(row: InvoiceItemRow, event: any) {
    console.log('price changed:', row.unitPrice);
    console.log('price event:', event);
    row.unitPrice = event;
    this.updateInvoiceLite();
  }

  createRow() {
    console.log('creating row');
    const newInvoiceRow: InvoicePackageRow = <InvoicePackageRow>{};
    newInvoiceRow.quantity = 1;

    if (!this.invoice.invoicePackageRows) {
      console.log('creating rows array');
      this.invoice.invoicePackageRows = new Array(0);
    } else {

      // Lets make it the same as the previous row
      const lastRow = _.cloneDeep(this.invoice.invoicePackageRows[this.invoice.invoicePackageRows.length - 1]);
      if (lastRow) {
        newInvoiceRow.serviceUser = lastRow.serviceUser;
        newInvoiceRow.quantity = lastRow.quantity;
        newInvoiceRow.packageNumber = lastRow.itemId;
        newInvoiceRow.subTotal = lastRow.subTotal;
        newInvoiceRow.discount = lastRow.discount;
        newInvoiceRow.grossPrice = lastRow.grossPrice;
        newInvoiceRow.tax = lastRow.tax;
        console.log('set price to ', lastRow.grossPrice);
      } else {
        console.log('not last row');
        if (this.userDtos && this.userDtos.length > 0) {
          newInvoiceRow.serviceUser = this.userDtos[0].userId;
          newInvoiceRow.quantity = 1;
        } else {

        }
      }
    }
    console.log('adding invoicePackageRows to ', newInvoiceRow);
    this.invoice.invoicePackageRows.push(newInvoiceRow);
    console.log('rows now ' + this.invoice.invoicePackageRows.length);
    this.itemChanged(newInvoiceRow, true); // Causes recalculate
  }

  deleteItemRow(invoiceRow: InvoicePackageRow) {

    this.invoice.invoicePackageRows.forEach((item, index) => {
      if (item === invoiceRow) {
        this.invoice.invoicePackageRows.splice(index, 1);
      }
    });
    this.updateInvoice();
  }

  itemChanged(invPackageRow: InvoicePackageRow, fullUpdate: boolean) {
    if (this.invoice.invoicePackageRows) {
      console.log('got row ' + invPackageRow);
      if (invPackageRow.packageNumber) {
        console.log('Package number is ' + invPackageRow.packageNumber);
        const aPackage = this.getPackage(invPackageRow.packageNumber);
        if (aPackage) {
          invPackageRow.unitPrice = aPackage.packagePrice;
          console.log('set unit price to ' + aPackage.packagePrice);
          invPackageRow.tax = this.getTaxById(aPackage.taxEntityInstanceId);

        } else {
          console.log('failed to get package');
        }
      }
      // });
    }
    if (fullUpdate) {
      this.updateInvoice();
    } else {
      this.updateInvoiceLite();
    }
  }

  serviceUserChanged() {
    console.log('service user changed');
    this.updateInvoiceLite();
  }

  getPackage(itemId: string): Package {

    let retItemDto: Package;

    console.log('looking for package with id ', itemId);
    if (this.packages) {
      console.log('there are ', this.packages.length);
      this.packages.forEach(itemDto => {

        if (itemDto.id === itemId) {
          console.log('found match!');
          retItemDto = itemDto;
          return itemDto;
        } else {
          console.log('no match');
        }
      });
    }
    return retItemDto;
  }

  setUserOptVal(user: UserDto): UserId {
    return { id: user.id, userContextType: UserId.UserContextTypeEnum.UserId };
  }

  compareUserFn(user1: UserDto, user2: UserDto) {
    // console.log('comparing');
    return user1 && user2 && user1.id === user2.id;
  }

  compareTimeFn(user1: string, user2: string) {
    // console.log('comparing');
    return user1 && user2 && user1 === user2;
  }
  updateInvoice() {
    const req: UpdateInvoiceComp = <UpdateInvoiceComp>{};
    req.contextIdDto = this.contextIdDto;
    req.invoice = this.invoice;
    req.entityInstanceId = this.viewContext.entityInstanceId;


    this.invoicecompService.updateInvoicePanel(req)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        // @ts-ignore
        this.invoice = response.invoicePanelDto.invoice;
        console.log('response.changedElementList ' + response.changedElementList);
        // @ts-ignore
        this.ecs.handleChangedElements(response.changedElementList, this.form);
        this.invoiceUpdated.emit(response.invoicePanelDto.invoice);
        console.log('invoiceMulti adding invoice changed ' + this.changeListener);
        if (this.changeListener) {
          this.changeListener.add('invoiceChanged');
        }
        this.setValid();
      });
  }

  updateInvoiceLite() {
    //  this.updateInvoice();
    //  console.log('startTimeChanged', this.invoice);
    const req: UpdateInvoiceComp = <UpdateInvoiceComp>{};
    req.contextIdDto = this.contextIdDto;
    req.invoice = _.cloneDeep(this.invoice);
    req.entityInstanceId = this.viewContext.entityInstanceId;

    this.invoicecompService.updateInvoicePanel(req)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        //    this.invoicePanelDto = response.invoicePanelDto;
        //  this.invoice = response.invoicePanelDto.invoice;
        console.log('response.changedElementList ' + response.changedElementList);
        //    this.ecs.handleChangedElements(response.changedElementList, this.form);
        //   this.invoiceUpdated.emit(response.invoicePanelDto.invoice);
        console.log('invoiceMulti adding invoice changed ' + this.changeListener);
        //  this.changeListener.add('invoiceChanged');
        this.eventBus.addCalendarEvent(EventType.REFRESH_CALENDAR);
        this.setValid();
      });
  }

  displayFn(id) {
    if (!id) {
      return '';
    }
    const index = this.packages.findIndex(state => state.id === id);
    if (index === -1) {
      return '';
    } else {
      return this.packages[index].packageName;
    }
  }

  getTaxById(taxEntityId: string): number {
    if (!taxEntityId) {
      return 0;
    }
    let taxNum = 0;
    this.taxService.getTax(taxEntityId).subscribe(res => {
      taxNum = res.taxRate;
      return taxNum;
    });

  }

  startTimeChanged(row: InvoicePackageRow, value: any) {
    console.log('start time changed:' + row.startTime);
    console.log('start time value:' + value);
    this.setEndTimeFromStart(row, value);
    this.updateInvoiceLite();
  }

  setEndTimeFromStart(row: InvoicePackageRow, value: any) {

    if (row.packageNumber) {
      const service = this.getPackage(row.packageNumber);
      if (service) {
        const durationInMins = service.packageDurationMins;
        console.log('got duration in mins ' + durationInMins);
        console.log('value is ' + value);
        if (value) {
          row.endTime = this.dateUtils.addMinsToTimeString(value, durationInMins);
          console.log('just set endTime to ' + row.endTime);
        }
      }
    }
  }

  setEndTimeFromStartRow(row: InvoicePackageRow) {

    if (row.packageNumber && row.startTime) {
      const service = this.getPackage(row.packageNumber);
      if (service) {
        const durationInMins = service.packageDurationMins;
        console.log('got duration in mins ' + durationInMins);
        row.endTime = this.dateUtils.addMinsToTimeString(row.startTime, durationInMins);
        console.log('just set endTime to ' + row.endTime);
      }
    }
  }

  startDateChange(row: InvoicePackageRow) {
    let dateStr;
    if (row.startDate) {
      dateStr = this.dateUtils.getDateAsStringDash(row.startDate);
    }
    console.log('dateStr ' + dateStr);

    row.startDate = dateStr;

    // 2021-04-26T04:00:00.000
    row.endDate = row.startDate;


    console.log('start date is ', row.startDate);
    // Reset all services to be same start / end date
    this.invoice.invoicePackageRows.forEach(aRow => {
      aRow.startDate = row.startDate;
      aRow.endDate = row.endDate;
    });
    // Update all

    this.updateInvoiceLite();
  }

  endTimeChanged(row: InvoicePackageRow, value: any) {
    console.log('end time changed:' + row.startTime);
    console.log('end time value:' + value);
    this.updateInvoiceLite();
  }

  createNewPackage(rowIndex) {

    this.serviceApi.loadServiceEd(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType).subscribe(response => {
        this.createPackage(response.entityDefinitionId, rowIndex);
      });
  }

  createPackage(packageEntityDefinitionId: EntityDefinitionId, rowIndex) {
    const dialogRef = this.dialog.open(AddPackageDialogComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        entityDefinitionId: packageEntityDefinitionId.id,
        entityDefinitionLabel: 'Package',
        eventBus: this.eventBus
      }
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this.destroy$)).subscribe(result => {
        console.log('back from dialog close');
        if (result && result.entityInstanceId) {
          console.log('got a new instance to link to ', result);
          this.loadAndSetPackage(result?.entityInstanceId, rowIndex);
        }
      });
  }

  loadAndSetPackage(packageNumber: string, rowIndex) {
    console.log('loading new packages');
    this.packagesCompositeService.loadPackages(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
    ).subscribe(response => {
      this.packages = response.packages;
      // this.filteredServices.next(this.serviceDtos);
      this.setSelectedPackage(packageNumber, rowIndex);
    });
  }

  setSelectedPackage(packageNumber, rowIndex) {
    if (this.invoice.invoicePackageRows && this.invoice.invoicePackageRows.length > 0) {
      const invPackageRow = this.invoice.invoicePackageRows[rowIndex];
      console.log('got row ' + invPackageRow);
      invPackageRow.packageNumber = packageNumber;
      console.log('Package number is ' + invPackageRow.packageNumber);
      const packageDto: Package = this.getPackage(invPackageRow.packageNumber);
      if (packageDto) {
        invPackageRow.unitPrice = packageDto.packagePrice;
        console.log('set package price to ' + packageDto.packagePrice);
        this.invoice.invoicePackageRows[rowIndex] = invPackageRow;
      } else {
        console.log('failed to get package');
      }
    }
    this.setValid();
  }

  filterPackages(event) {
    this.searchPackage = event;
  }

}

