<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <!-- <mat-toolbar-row cdkDragHandle *ngIf="quickAppointment">
    <span fxLayout="row" fxLayoutAlign=" center">
      <span class="icon-24 mat-text-secondary">
        <mat-icon>event</mat-icon>
      </span>
      <span class="date-time padding-left-half" fxLayout="column">
        <span>{{createPetAppointmentV2SyncService.startDate | localizedDate}}</span>
        <small class="mat-text-secondary fs-12">
          {{createPetAppointmentV2SyncService.displayStartTime | localizedDate:'h:mm a'}} -
          {{createPetAppointmentV2SyncService.displayEndTime | localizedDate:'h:mm a'}}
        </small>
      </span>
    </span>
    <span class="example-spacer"></span>
    <button mat-icon-button color="primary" aria-label="Example icon button with a home icon" (click)="fullScreen()">
      <mat-icon class="full-screen-icon">open_in_full</mat-icon>
    </button>
  </mat-toolbar-row> -->
  <mat-toolbar cdkDragHandle color="primary" *ngIf="!quickAppointment">
    <mat-toolbar-row>
      <div fxLayout="row wrap" fxLayout.gt-sm="row" fxLayoutAlign=" start" fxLayoutAlign.gt-sm=" center" class="w-100"
        *ngIf="!data.edit">
        <span fxFlex fxFlex.gt-sm="25%" fxFlexOrder='1' fxFlexOrder.gt-sm='1'>
          <h5 class="text-truncate my-2" *ngIf="!data.rebook">{{'CREATE_APPOINTMENT' | translate: {Default: 'Create
            Appointment'} }}</h5>
          <h5 class="text-truncate my-2" *ngIf="data.rebook">{{'REBOOK_APPOINTMENT' | translate: {Default: 'Re-book
            Appointment'} }}</h5>
        </span>

        <div *ngIf="(!appointment?.id) || (deviceService.isMobile() || deviceService.isTablet())" fxFlex="100"
          fxFlex.gt-sm fxLayout="row" fxLayoutAlign=" center" fxFlexOrder='3' fxFlexOrder.gt-sm='2' class="w-100">
          <div fxFlex *ngIf="!data.edit" align="center" align.gt-sm="center"
            [ngClass]="{'text-md-center': !deviceService.isMobile()}" class="mdc-py-1">
            <mat-button-toggle-group [(ngModel)]="customerMode" (ngModelChange)="customerModeChange($event)"
              name="customerMode" aria-label="Customer Mode" class="button-group-payments">
              <mat-button-toggle value="existingCustomer">{{'EXISTING_CUSTOMER' | translate: {Default: 'Existing
                Customer'} }}
              </mat-button-toggle>
              <mat-button-toggle value="newCustomer">{{'NEW_CUSTOMER' | translate: {Default: 'New Customer'}
                }}</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <div fxFlex="nogrow" fxFlex.gt-sm="25%" fxLayout="row" fxLayoutAlign="end center"
          *ngIf="(!appointment?.id) || (deviceService.isMobile() || deviceService.isTablet())" fxFlexOrder='2'
          fxFlexOrder.gt-sm='3'>
          <div fxFlex="nogrow" align="end">
            <button *ngIf="!appointment?.id && customerMode === 'existingCustomer'" type="button" mat-raised-button
              color="accent" class="ml-1" (click)="createPet = !createPet"
              [matTooltip]="'NEW_PET' | translate: {Default: 'New Pet'} " matTooltipPosition="above">
              <mat-icon class="vm d-none d-sm-inline-block">pets</mat-icon>{{'NEW_PET' | translate: {Default: 'New Pet'}
              }}
            </button>
            <button mat-icon-button aria-label="Close" (click)="cancel()"
              *ngIf="deviceService.isMobile() || deviceService.isTablet()">
              <mat-icon class="full-screen-icon">close</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign=" center" class="w-100" *ngIf="data.edit">
        <span fxFlex="100%" fxLayout="row" fxLayoutAlign=" center">
          <mat-icon class="back-icon vm" (click)="cancel()">keyboard_backspace</mat-icon>
          <h5 class="text-truncate">{{'EDIT_APPT' | translate: {Default: 'Edit Appointment'} }}</h5>
        </span>
        <div fxFlex="nogrow">
          <button mat-icon-button aria-label="Close" (click)="cancel()"
            *ngIf="deviceService.isMobile() || deviceService.isTablet()">
            <mat-icon class="full-screen-icon">close</mat-icon>
          </button>
        </div>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <mat-card appearance="outlined" class="m-0">
    <mat-card-content>
      <!-- <app-create-quick-pet-appointment #createQuickPetAppointmentComponent *ngIf="quickAppointment"
        [contextIdDto]="contextIdDto" [appointmentDate]="createPetAppointmentV2SyncService.startDate"
        [petList]="data?.petList || []">
      </app-create-quick-pet-appointment> -->

      <div *ngIf="!quickAppointment">
        <!-- <ng-template matStepLabel>{{'Appointment' | translate}}</ng-template> -->
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="5px" class="w-100 mdc-mb-1">
          <ng-container *ngIf="customerMode === 'existingCustomer'">
            <div fxFlex="auto">
              <app-pet-selector-v2 [contextIdDto]="contextIdDto" [multiple]="true" [petList]="data?.petList || []">
              </app-pet-selector-v2>
            </div>
          </ng-container>
          <ng-container *ngIf="customerMode === 'newCustomer'">
            <app-new-pet-customer-selector-v2 class="w-100" [contextIdDto]="contextIdDto"
              (customerCreated)="customerCreated($event)" (newExistingSwitch)="customerMode = 'existingCustomer'">
            </app-new-pet-customer-selector-v2>
          </ng-container>
        </div>

        <app-create-pet-existing-customer [customerId]="createPetAppointmentV2SyncService.petsSelected?.[0]?.petOwnerId"
          [customerName]="createPetAppointmentV2SyncService.petsSelected?.[0]?.customerName"
          class="mdc-mb-1 display-block" *ngIf="createPet && customerMode === 'existingCustomer'"
          [contextIdDto]="contextIdDto" (addNewCustomerEvent)="addNewCustomerEvent($event)"
          (petCreated)="petCreated($event)" (cancelEvent)="createPet = false">
        </app-create-pet-existing-customer>

        <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutGap.gt-sm="16px" fxLayout.gt-sm="row wrap">
          <div fxFlex="46" fxFlex.gt-sm="19">
            <app-appointment-selector-v2 *ngIf="appointmentConfigHelperService?.appointmentConfig"
              #appointmentSelectorV2Component class="mt-15px display-block" [contextIdDto]="contextIdDto"
              [(startDate)]="createPetAppointmentV2SyncService.startDate"
              [(endDate)]="createPetAppointmentV2SyncService.endDate"
              [(startTime)]="createPetAppointmentV2SyncService.startTime"
              [(endTime)]="createPetAppointmentV2SyncService.endTime"
              [totalAppointmentDuration]="totalAppointmentDuration"
              [disableTimePickup]="appointmentConfigHelperService.appointmentConfig.customizedTimeForServices"
              [rebook]="data.rebook">
            </app-appointment-selector-v2>
          </div>
          <div fxFlex="46" fxFlex.gt-sm="19">
            <mat-form-field appearance="outline" class="mt-15px display-block w-100">
              <mat-label>{{'ENQUIRY_DATE' | translate: {Default: 'Enquiry Date'} }} </mat-label>
              <input id="appointment-enquiry-date" matInput #datePickerInput [(ngModel)]="enquiryDate"
                [matDatepicker]="enquiryDatePicker" (focus)="enquiryDatePicker.open()" placeholder="Select Enquiry Date"
                disabled>
              <mat-datepicker-toggle matSuffix [for]="enquiryDatePicker"></mat-datepicker-toggle>

              <mat-datepicker #enquiryDatePicker [restoreFocus]="false">
              </mat-datepicker>
            </mat-form-field>
          </div>
          <div fxFlex="46" fxFlex.gt-sm="18">
            <app-appointment-v2-icons #appointmentV2IconsComponent [selectedIcons]="appointment?.icons"
              class="mt-15px display-block">
            </app-appointment-v2-icons>
          </div>
          <div fxFlex="46" fxFlex.gt-sm="19" *ngIf="contextService.appType != 'VETS'">
            <mat-form-field appearance="outline" class="mt-15px display-block w-100">
              <mat-label>{{'REGULAR_BLADES' | translate: {Default: 'Regular Blades'} }}</mat-label>
              <input id="appointment-blades" matInput
                placeholder="{{'ENTER_REGULAR_BLADES' | translate: {Default: 'Please Enter Regular Blades'} }}"
                [(ngModel)]="blades">
            </mat-form-field>
          </div>
          <div fxFlex="46" fxFlex.gt-sm="18" *ngIf="data?.calendarSettings?.multiLocationEnabled">
            <app-location-selector-v2 class="mt-15px display-block" [contextIdDto]="contextIdDto"
              [locationIdSelected]="appointment?.locationId" (locationChange)="locationChangedEvent($event)"
              #locationSelectorV2Component>
            </app-location-selector-v2>
          </div>
        </div>

        <app-pet-services-selector-v2 class="display-block" #petServiceSelectorV2Component [contextIdDto]="contextIdDto"
          [allowedToRemove]="customerMode === 'existingCustomer'"
          [userToUseWhenCreating]="createPetAppointmentV2SyncService.userToUseWhenCreating"
          (updateCustFlags)="updateCustFlags()">
        </app-pet-services-selector-v2>

        <app-pickup-service #pickupServiceComponent
          *ngIf="appointmentConfigHelperService.appointmentConfig.pickupEnabled"
          [pickupRequired]="appointment?.pickupRequired" [collectionTime]="appointment?.collectionTime"
          [pickupAddress]="appointment?.pickupAddress">
        </app-pickup-service>

        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="10px" fxLayoutGap.gt-sm="16px" class="w-100">
          <div fxFlex="100">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label *ngIf="contextService.appType != 'VETS'">{{'GROOMING_NOTES' | translate: {Default: 'Grooming
                Notes'} }}</mat-label>
              <mat-label *ngIf="contextService.appType == 'VETS'">{{'CONSULTATION_NOTES' | translate: {Default:
                'Consultation Notes'} }}</mat-label>
              <textarea id="appointment-notes" matInput [(ngModel)]="notes" [ngModelOptions]="{standalone: true}"
                rows="2"></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class="" *ngIf="createPetAppointmentV2SyncService.totalAmount">
          <mat-card appearance="outlined" class="mdc-mb-2">
            <mat-card-content>
              <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="10px" fxLayoutGap.gt-sm="16px"
                fxLayoutAlign=" center">
                <app-payment fxFlex class="my-2" #paymentComponent [contextIdDto]="contextIdDto"
                  [customerId]="createPetAppointmentV2SyncService?.petsSelected?.[0]?.petOwnerId"
                  [totalAmount]="createPetAppointmentV2SyncService.totalAmount"
                  [(paymentConfig)]="appointment.paymentConfig" (paymentConfigChange)="paymentConfigChanged($event)"
                  (amountDueChanged)="amountDueChanged($event)"
                  (createPlanChanged)="createPlanChanged($event)"></app-payment>

                <app-pet-confirm-appointment-v2 class="mdc-py-2" fxFlex="100" fxFlex.gt-sm="48"
                  #confirmAppointmentV2Component [contextIdDto]="contextIdDto"
                  [pickupRequired]="pickupServiceComponent?.pickupRequired">
                </app-pet-confirm-appointment-v2>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div *ngIf="createPetAppointmentV2SyncService?.petsSelected?.length && !data.edit && !data.rebook"
          class="mdc-mb-1">
          <mat-slide-toggle id="appointment-repeat" [labelPosition]="'before'" [(ngModel)]="isRepeatAvailable"
            class="mdc-mb-1">
            {{'REPEAT_APPOINTMENT_TOGGLE' | translate: {Default: 'Repeat Appointment?'} }}
          </mat-slide-toggle>
          <br>
          <app-repeat-appointments *ngIf="isRepeatAvailable" (checkConflictApi)="checkConflictApi($event)"
            [selectedDay]="selectedDay" [startTime]="createPetAppointmentV2SyncService?.startTime"
            [startDate]="createPetAppointmentV2SyncService?.startDateReq" #repeatAppointmentComponent>
          </app-repeat-appointments>
        </div>
      </div>

      <mat-card appearance="outlined" class="mx-0 mdc-mb-1" *ngIf="showConfirmationDialog && !checkConflictsDialog">
        <mat-card-header class="close-confirm">
          <mat-icon (click)="closeConfirmDialog()">cancel</mat-icon>
        </mat-card-header>
        <mat-card-content>
          <div fxLayout="row" fxLayoutAlign=" center">
            <div fxFlex>
              <h6><strong>Confirmation!</strong></h6>
              <p class="small mat-text-secondary mb-0">
                <strong>{{'CONFIRMING_SENDS_NOTIFICATIONS' | translate: {Default: 'Confirming will send email/SMS
                  notifications to the customer.'} }}</strong>
              </p>
              <p class="small mat-text-secondary mb-0">
                <mat-checkbox [(ngModel)]="confirmEnabled" (ngModelChange)="updateConfirmEnabled($event)">
                  {{'DONT_SHOW_MESSAGE_AGAIN' | translate: {Default: "Don't Show This Message Again"} }}
                </mat-checkbox>
              </p>
            </div>
            <div fxFlex="nogrow">
              <button mat-raised-button color="accent" (click)="confirmAppointment()">{{'OK' | translate: {Default:
                'OK'} }}</button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card appearance="outlined" class="mx-0 mdc-mb-1" *ngIf="checkConflictsDialog">
        <mat-card-header class="close-confirm">
          <mat-icon (click)="checkConflictsDialog = false">cancel</mat-icon>
        </mat-card-header>
        <mat-card-content>
          <div fxLayout="row" fxLayoutAlign=" center">
            <div fxFlex class="mt-1">
              <h6><strong>{{'CLASHES' | translate: {Default: 'Clashes!'} }}</strong></h6>
              <p class="small mat-text-secondary mb-0">
                <ng-container *ngFor="let clash of clashes">
                  <!-- WE DELIBERATELY ONLY SHOW THE FIRST CLASH TO STOP SPAMMING USER -->
                  <ng-container *ngIf="clash.clashedCalendarEntries[0].entryType === 'userHours'">
                    <span>
                      {{ clash.clashedCalendarEntries[0].detailedLabel }}
                    </span>
                  </ng-container>
                  <ng-container *ngIf="clash.clashedCalendarEntries[0].entryType === 'userOff'">
                    <span>
                      {{ clash.clashedCalendarEntries[0].detailedLabel }}
                    </span>
                  </ng-container>
                  <ng-container *ngIf="clash.clashedCalendarEntries[0].entryType === 'holiday'">
                    <span>
                      {{ clash.clashedCalendarEntries[0].detailedLabel }}
                    </span>
                  </ng-container>
                  <ng-container *ngIf="clash.clashedCalendarEntries[0].entryType === 'blockCalendarEvent'">
                    <span>
                      {{ clash.clashedCalendarEntries[0].detailedLabel }}
                    </span>
                  </ng-container>
                  <ng-container *ngIf="clash.clashedCalendarEntries[0].entryType === 'businessClosed'">
                    <span>
                      {{'BUSINESS_CLOSED' | translate: {Default: 'The business is closed.'} }}
                    </span>
                  </ng-container>
                  <ng-container *ngIf="clash.clashedCalendarEntries[0].entryType === 'bankHoliday'">
                    <span>
                      {{'THIS_IS_BANK_HOLIDAY' | translate: {Default: 'This is a bank holiday.'} }}
                    </span>
                  </ng-container>
                  <ng-container *ngIf="clash.clashedCalendarEntries[0].entryType === 'appointment'">
                    {{'APPOINTMENT_CLASHES' | translate: {Default: 'The appointment clashes with the following
                    appointment(s):'} }}&nbsp;<span
                      [innerHTML]="'<br>' + clash.clashedCalendarEntries[0].dayCellLinesHtml"></span>
                  </ng-container>
                </ng-container>
              </p>
              <p class="small mat-text-secondary mb-0">
                <mat-checkbox [(ngModel)]="bypassConflictCheck" (ngModelChange)="updateConflictCheck($event)">
                  {{'NEVER_CHECK_CONFLICTS' | translate: {Default: 'Never check for conflicts.'} }}
                </mat-checkbox>
              </p>
              <p class="small mat-text-secondary mb-0">
                Note: You can still <span *ngIf="!data?.edit">create</span><span *ngIf="data?.edit">update</span> your booking by clicking on the same button again.
              </p>
            </div>
            <!-- <div fxFlex="nogrow">
              <button mat-raised-button color="accent" (click)="createAnyway()">
                <span *ngIf="data.edit">{{'UPDATE' | translate: {Default: 'Update'} }}</span><span
                  *ngIf="!data.edit">{{'CREATE' | translate: {Default: 'Create'} }}</span> {{'ANYWAY' | translate:
                {Default: 'Anyway'} }}
              </button>
            </div> -->
          </div>
        </mat-card-content>
      </mat-card>
      <div *ngIf="!quickAppointment" fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="16px"
        fxLayoutAlign.gt-xs=" center">
        <div fxFlex>
          <div fxLayout>
            <div fxFlex="nogrow" class="icon-24 mat-text-secondary" fxFlexAlign="center">
              <mat-icon>event</mat-icon>
            </div>
            <div fxFlex class="date-time padding-left-half" fxLayout="column">
              <span>{{createPetAppointmentV2SyncService.startDate | localizedDate: 'shortDate'}}</span>
              <small class="mat-text-secondary fs-12">
                {{createPetAppointmentV2SyncService.displayStartTime | localizedDate:'shortTime'}} -
                {{createPetAppointmentV2SyncService.displayEndTime | localizedDate:'shortTime'}}
              </small>
            </div>
          </div>
        </div>
        <!-- <div fxFlex="nogrow" [hidden]="appointment && appointment?.status !== 'Created'">
          <mat-radio-group aria-label="Select an option" [(ngModel)]="appointmentStatus" name="appointmentStatus"
            class="mr-1">
            <mat-radio-button value="tentative"
              matTooltip="This will save the booking as a draft and won't sent any Email/SMS notifications to the customer.">
              Tentative</mat-radio-button>
            <mat-radio-button value="confirm" class="ml-1"
              matTooltip="This will confirm the booking immediately and will send Email/SMS notifications to the customer.">
              Confirmed</mat-radio-button>
          </mat-radio-group>
        </div> -->

        <div fxFlex="nogrow" fxFlexOrder="3">
          <mat-menu #createAppointmentNotes="matMenu">
            <div class="mdc-p-3">
              <p *ngIf="!data.edit"><strong>{{'CREATE' | translate: {Default: 'Create'} }}</strong>:
                {{getMessage('create')}}
              </p>
              <p *ngIf="!data.edit"><strong>{{'CREATE_AND_CONFIRM' | translate: {Default: 'Create and Confirm'}
                  }}</strong>: {{getMessage('createAndConfirm')}}
              </p>

              <p *ngIf="data.edit"><strong>{{'UPDATE' | translate: {Default: 'Update'} }}</strong>:
                {{getMessage('update')}}
              </p>
              <p *ngIf="data.edit"><strong>{{'UPDATE_AND_RECONFIRM' | translate: {Default: 'Update and Re-Confirm'}
                  }}</strong>: {{getMessage('updateAndConfirm')}}
              </p>
            </div>
          </mat-menu>
          <mat-icon *ngIf="createPetAppointmentV2SyncService.totalAmount" class="mr-5px vm cp"
            [matMenuTriggerFor]="createAppointmentNotes" color="accent">info</mat-icon>
          <button id="create-appointment-button" *ngIf="!data.edit" type="button" class="mt-0 mb-0 mr-5px"
            mat-raised-button color="accent" (click)="createAppointment()"
            [disabled]="!createPetAppointmentV2SyncService?.petsSelected?.length" matTooltip="{{getMessage('create')}}">
            {{'CREATE' | translate: {Default: 'Create'} }}
          </button>
          <button id="create-confirm-appointment-button" *ngIf="!data.edit" type="button" class="mt-0 mb-0 mr-5px"
            mat-raised-button color="accent" (click)="createAndConfirm()"
            [disabled]="!createPetAppointmentV2SyncService?.petsSelected?.length || showConfirmationDialog || createNewPlan"
            matTooltip="{{getMessage('createAndConfirm')}}">
            {{'CREATE_AND_CONFIRM' | translate: {Default: 'Create and confirm'} }}
          </button>
          <button id="update-appointment-button" *ngIf="data.edit" type="button" class="mt-0 mb-0 mr-5px"
            mat-raised-button color="accent" (click)="updateAppointment()"
            [disabled]="!createPetAppointmentV2SyncService?.petsSelected?.length" matTooltip="{{getMessage('update')}}">
            {{'UPDATE' | translate: {Default: 'Update'} }}
          </button>
          <button id="update-confirm-appointment-button" *ngIf="data.edit" type="button" class="mt-0 mb-0"
            mat-raised-button color="accent" (click)="updateAndConfirmAppointment()"
            [disabled]="!createPetAppointmentV2SyncService?.petsSelected?.length || showConfirmationDialog || createNewPlan"
            matTooltip="{{getMessage('updateAndConfirm')}}">
            {{'UPDATE_AND_RECONFIRM' | translate: {Default: 'Update and Re-Confirm'} }}
          </button>
        </div>
      </div>

    </mat-card-content>
  </mat-card>
</div>
