<div class="containerX">
  <div fxLayout="row wrap" fxLayout.xl="row wrap" fxLayout.lg="column" fxLayout.md="column" fxLayout.xs="column" fxLayout.sm="column" fxLayoutGap="16px" fxFlex="100" class="coloredContainerX box">
    <div fxFlex>
      <app-element [form]="form" [contextIdDto]="contextIdDto" [elementDto]="splitPanelDefinitionDto.left" [viewContext]="viewContext" [eventBus]="eventBus" [additionalDataMapDto]="additionalDataMapDto" [showPlaceholder]="showPlaceholder" [changeListener]="changeListener">
      </app-element>
    </div>
    <div fxFlex>
      <app-element [form]="form" [contextIdDto]="contextIdDto" [elementDto]="splitPanelDefinitionDto.right" [viewContext]="viewContext" [eventBus]="eventBus" [additionalDataMapDto]="additionalDataMapDto" [showPlaceholder]="showPlaceholder" [changeListener]="changeListener">
      </app-element>

    </div>
  </div>
</div>