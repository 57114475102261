<mat-card appearance="outlined" class="entity-with-image-panel panel new-card" *ngIf="viewEntityWithImagePanelDataDto">

  <mat-card-content class="entry-list" fxLayout="row" fxLayout.lt-md="column">
      <!--<img src="./assets/images/'{{viewEntityWithImagePanelDataDto.viewImageDefinition.noImageUploadedImage}}" alt="">-->
    <div class="image-container">
      <app-view-image
        *ngIf="viewEntityWithImagePanelDataDto.viewImageDto?.
        elementInstanceDto?.fieldInstanceDto?.value?.imageFieldValue?.fileReferences"
        [contextIdDto]="contextIdDto"
        [viewImageDto]="viewEntityWithImagePanelDataDto.viewImageDto"
        [viewContext]="viewContext"></app-view-image>
      <img *ngIf="!viewEntityWithImagePanelDataDto.viewImageDto?.
      elementInstanceDto?.fieldInstanceDto?.value?.imageFieldValue?.fileReferences"
           class="no-image" src="./assets/images/pawPrint.svg" alt="">
    </div>
    <div fxFlex="75" fxLayout="column">
      <div>
        <h2>{{viewEntityWithImagePanelDataDto.header}}</h2>
      </div>
      <div fxLayout="row" fxLayout.lt-md="column" *ngFor="let labelValueRowDto of viewEntityWithImagePanelDataDto.labelValueRowDtos">
        <div fxFlex="55" fxLayout="column">
          <div fxFlex="100">
            <div fxFlex="40">
              <p class="mat-text-muted mt-xs mb-xs">
                {{labelValueRowDto.left?.label}}
              </p>
            </div>
            <div fxFlex="60">
              <p class="mt-xs mb-xs">
                {{labelValueRowDto.left?.value}}
              </p>
            </div>
          </div>
        </div>
        <div fxFlex="45" fxLayout="column">
          <div fxFlex="100">
            <div fxFlex="40">
              <p class="mat-text-muted mt-xs mb-xs">
                {{labelValueRowDto.right?.label}}
              </p>
            </div>
            <div fxFlex="60">
              <p class="mt-xs mb-xs">
                {{labelValueRowDto.right?.value}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </mat-card-content>
</mat-card>
<!--.appt-panel {-->
<!--.entry-list {-->
<!--p {-->
<!--margin-top: 0 !important;-->
<!--}-->
<!--}-->
<!--}-->
