import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ContextService } from 'src/app/flo/context.service';
import { SendToAllMessageStep } from '../send-to-all-message-step.interface';
import { ContextIdDto } from '@savvy/ui';
import { AppointmentQuickLinksEditorComponent } from 'src/app/flo/editor/appointment-quick-links-editor/appointment-quick-links-editor.component';
import { Subscription } from 'rxjs';
import { SmsCreditSummaryDto, SmsSettingsCompService } from "@savvy/sms-settings";

@Component({
  selector: 'app-send-to-all-message-sms',
  templateUrl: './send-to-all-message-sms.component.html',
  styleUrls: ['./send-to-all-message-sms.component.scss']
})
export class SendToAllMessageSmsComponent implements OnInit, OnDestroy, SendToAllMessageStep {

  @ViewChild(AppointmentQuickLinksEditorComponent, { static: false }) quickLinksEditorV2Component: AppointmentQuickLinksEditorComponent;
  formGroup: UntypedFormGroup = new UntypedFormGroup({
    allowSms: new UntypedFormControl(true),
    sms: new UntypedFormControl('')
  });

  contextIdDto: ContextIdDto;
  subscriptions: Subscription[] = [];
  smsCreditSummaryDto: SmsCreditSummaryDto;

  constructor(
    private contextService: ContextService,
    private smsSettingsCompApi: SmsSettingsCompService
  ) { }

  ngOnInit() {
    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
        this.loadSmsCredit();
      }
    }));
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  get fg(): UntypedFormGroup {
    return this.formGroup;
  }

  getFormRef(): UntypedFormGroup {
    return this.formGroup;
  }

  onStepChange(): boolean {
    return true;
  }

  loadSmsCredit() {
    if (this.contextIdDto && this.contextIdDto.contextId) {
      this.smsSettingsCompApi.getSmsReport(this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(response => {
        this.smsCreditSummaryDto = response.smsCreditSummaryDto;
      });
    }
  }

}
