ENTITY TASKS PANEL!!!
<mat-card appearance="outlined" *ngIf="response != null && response.taskSetDtos.length > 0" class="full-height">
    <mat-card-header>
        <mat-card-title>
            <div fxLayout="row">
                <div fxFlex="80">
                    <p class="mt-xs mb-xs">{{'Tasks' | translate}} <small>{{'more' | translate}}</small></p>
                </div>
                <div fxFlex="20">
                    <button mat-mini-fab color="accent" (click)="createTask()">
                        <mat-icon class="mat-24">add</mat-icon>
                    </button>
                </div>
            </div>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-tasks-panel #tasksPanel *ngIf="response != null" [taskSetDtos]="response.taskSetDtos" [eventBus]="eventBus" [contextIdDto]="contextIdDto" (taskSelected)="openPanel()">

        </app-tasks-panel>
    </mat-card-content>
</mat-card>

<mat-card appearance="outlined" *ngIf="response != null && response.taskSetDtos.length === 0" class="full-height">

    <mat-card-content>
        <div class="flexbox-parent">
            <div fxLayout="row" fxLayoutAlign="center center">
                <div style="text-align:center;">
                    <app-zero-image name="task"></app-zero-image><br>
                    <h5 class="ma-0">{{'You don’t have any Task\'s' | translate}}</h5><br>
                    <button mat-raised-button color="accent" (click)="createTask()">{{'Create Task' | translate}}</button>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>