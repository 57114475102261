import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContextIdDto } from '@savvy/app';
import { DataItemDto } from '@savvy/datalist';
import {
  ConsumerService, PetServiceRestriction, ServiceRestriction,
  ServicesService

} from '@savvy/services';
import { Tax } from '@savvy/tax';
import { NgxSpinnerService } from 'ngx-spinner';
import { AddEditDatalistModalComponent } from 'src/app/flo/datalist/add-edit-datalist-modal/add-edit-datalist-modal.component';
import { FloSnackbarComponent } from '../../../../../snackbar/floSnackbar.component';
import { DatalistService } from '@savvy/datalist';
import { MatDialog } from '@angular/material/dialog';
import { DataListDto, DataListItem } from '@savvy/app-metadata';
import {AppointmentService, ServicePriceChangeImpactDto, UpdateServicePricesRequest} from '@savvy/appointment';
import {formatDate} from "@angular/common";
import {TranslateService} from "@ngx-translate/core";
import {UntypedFormControl} from "@angular/forms";
import {ReplaySubject} from "rxjs";

class CreateServiceData {
  contextIdDto: ContextIdDto;
  serviceGroups: DataItemDto[];
  taxDefs: Tax[];
  edit: boolean;
  service: ConsumerService;
  hideGroups: boolean;
}
@Component({
  selector: 'app-create-service',
  templateUrl: './create-service.component.html',
  styleUrls: ['./create-service.component.scss']
})
export class CreateServiceComponent implements OnInit {
  service: ConsumerService = {} as ConsumerService;
  serviceGroups: DataItemDto[] = [];
  serviceGroupsDtos: DataListDto;
  taxDefs: Tax[] = [];

  originalUnitPrice = 0;

  // If filtering by pets
  breeds: DataItemDto[] = [];
  breedsListDto: DataListDto;
  public filteredBreeds: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public petBreedFilter: UntypedFormControl = new UntypedFormControl();


  fromDate: Date = new Date();
  priceChangeImpactDto: ServicePriceChangeImpactDto;

  // Whether to update all appointments or not
  updateFutureAppointments: boolean;
  unitPriceUpdated = false;
  updateServicePricesRequest: UpdateServicePricesRequest = <UpdateServicePricesRequest>{};

  constructor(
    public dialogRef: MatDialogRef<CreateServiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CreateServiceData,
    private servicesService: ServicesService,
    private spinner: NgxSpinnerService,
    private notify: FloSnackbarComponent,
    private datalistService: DatalistService,
    private dialog: MatDialog,
    private appointmentService: AppointmentService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    // this.service?.serviceName = `New Service ${Date.now()}`;
    this.service.serviceRestricted = false;
    this.service.serviceRestriction = <ServiceRestriction>{};
    this.service.serviceRestriction.petServiceRestriction = <PetServiceRestriction>{};
    this.service.durationInMins = 60;
    this.service.colour = '';
    this.service.ownerId = this.data.contextIdDto.contextId;
    this.serviceGroups = this.data.serviceGroups;
    this.taxDefs = this.data.taxDefs;
    this.getServiceGroups();
    this.loadBreeds();

    if (this.data.edit) {
      this.service = this.data.service;
      this.originalUnitPrice = this.service.unitPrice;
    }
  }

  updateTax($event, cell) {
    this.service[cell] = $event.value;
    this.refreshPriceChangeImpact(false);
  }

  close() {
    this.dialogRef.close();
  }

  setGroupLabel() {
    if (this.service?.groupName) {
      const group = this.serviceGroups.find(sg => sg.name === this.service?.groupName);
      console.log('found group', group);
      this.service.groupLabel = group?.label;
    } else {
    }
  }

  add() {
    if (this.data.edit) {
      this.updateService();
      this.dialogRef.close(this.service);
    } else {
      if (this.service?.serviceName.trim().length) {
        this.spinner.show();
        this.setGroupLabel();
        this.servicesService.createConsumerService(this.service).subscribe((service: ConsumerService) => {
          this.spinner.hide();
          if (service) {
            this.notify.message = `${this.service?.serviceName} updated successfully`;
            this.notify.open();
            this.dialogRef.close({ ...service, ...this.service });
          }
        });
      } else {
        this.notify.message = 'Please enter valid service name';
        this.notify.open();
      }
    }
  }

  updateColor(event, cell) {
    this.service[cell] = event;
    if (this.service.id) {
      this.updateService();
    }
  }

  updateService() {
    console.log('updating service only');
    this.setGroupLabel(); // Not sure this should be done client side

    this.servicesService.updateConsumerService(this.service).subscribe(res => {
      console.log('UPDATED!', res);
      console.log('UPDATED!', this.updateFutureAppointments);
      if (!this.updateFutureAppointments) { //only show pop up for updating appointments
        this.notify.message = `${this.service?.serviceName} updated successfully`;
        this.notify.open();
      } else {
        this.updateServicePrices();
      }
    });
  }

  updateServicePrices() {
    this.appointmentService.updateServicePrices(this.updateServicePricesRequest).subscribe(_ => {
      this.translateService.get('Updating appointments. This might take few minutes.')
        .subscribe(val => {
          this.notify.message = val;
          this.notify.open();
        });

    });
  }

  getServiceGroups(newGroup?: DataListItem) {
    this.datalistService.getDataListByName(this.data.contextIdDto.contextId,
      this.data.contextIdDto.contextIdType, 'ServiceGroup').subscribe(res => {
        this.serviceGroupsDtos = res;
        this.serviceGroups = this.serviceGroupsDtos.dataItems;
        if (newGroup) {
          this.service.groupName = newGroup.name;
        }
      });
  }

  addNew() {
    const confirmDialog = this.dialog.open(AddEditDatalistModalComponent, {
      data: {
        contextIdDto: this.data.contextIdDto,
        dataListDto: this.serviceGroupsDtos
      },
      height: 'auto',
      width: '600px',
      panelClass: 'helpwindow'
    });

    confirmDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.getServiceGroups(result);
      }
    });
  }


  //---------- BULK PRICE CHANGE

  refreshPriceChangeImpact(unitPriceUpdated: boolean) {
    if (unitPriceUpdated) {
      this.unitPriceUpdated = true;
    }
    if (this.data.edit) {
      this.buildServicePriceRequest();
      console.log('reload service price change data');
      this.appointmentService.getServicePriceChangeImpact(this.updateServicePricesRequest).subscribe(response => {
        this.priceChangeImpactDto = response;
      });
    }
  }

  updateFutureAppointmentsChange() {
    if (this.updateFutureAppointments) {
      console.log(this.fromDate);
      this.buildServicePriceRequest();
    }
  }

  private buildServicePriceRequest() {
    let formattedStartDate = formatDate(this.fromDate, 'yyyy-MM-dd', 'en');
    console.log("re-building service price request", formattedStartDate)
    this.updateServicePricesRequest.startDate = formattedStartDate;
    this.updateServicePricesRequest.serviceId = this.service.id;
    this.updateServicePricesRequest.newPrice = this.service.unitPrice;
  }

  //----------------- PET BREED OVERRIDE

  loadBreeds() {
    this.datalistService.getDataListByName(
      this.data.contextIdDto.contextId,
      this.data.contextIdDto.contextIdType, 'Breed').subscribe(breeds => {
      if (breeds) {
        this.breedsListDto = breeds;
        this.breeds = breeds.dataItems;
        this.filteredBreeds.next(this.breeds);
      }
    });
  }

  addNewBreed() {
    const confirmDialog = this.dialog.open(AddEditDatalistModalComponent, {
      data: {
        contextIdDto: this.data.contextIdDto,
        dataListDto: this.breedsListDto
      },
      height: 'auto',
      width: '600px',
      panelClass: 'helpwindow'
    });

    confirmDialog.afterClosed().subscribe((result: DataItemDto) => {
      if (result) {
        this.loadBreeds();
        this.service.serviceRestriction.petServiceRestriction.breedIds = new Array<string>();
        this.service.serviceRestriction.petServiceRestriction.breedIds.push(result.name);
      }
    });
  }

  serviceRestrictedChange(event: any) {
    console.log('event is ', event);
    if (event.checked) {
      // Check
      if (!this.service.serviceRestriction) {
        this.service.serviceRestriction = <ServiceRestriction>{};
        this.service.serviceRestriction.petServiceRestriction = <PetServiceRestriction>{};
      }
    }
    this.service.serviceRestricted = event.checked;
  }

  filterBreeds() {
    if (!this.breeds) {
      return;
    }
    // get the search keyword
    // get the search keyword
    let search = this.petBreedFilter.value;
    if (!search) {
      this.filteredBreeds.next(this.breeds.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the breeds
    this.filteredBreeds.next(
      this.breeds.filter(breed => breed.name.toLowerCase().indexOf(search) > -1)
    );
  }
}
