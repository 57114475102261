// export const environment = {
//   production: false,
//   WEBSOCKET_URL: 'wss://devapi.itsallsavvy.com',
//   API_URL: 'https://devapi.itsallsavvy.com',
//   API_HOST: 'devapi.itsallsavvy.com',
//   STRIPE_KEY: 'pk_test_51IlB9PBIyYWjTWBBeY7kvr19BWGy4qC9KS63UV76lFJguR1MehNUTWxrCPizroXHOXTvBZlPn20uNXU3hf2S5dWG00g0LefjRF',
//   SAVVY_PAY_CLIENT_ID: 'test_43EOC6HK7ZAIDIUAZVR6RD4FUIGMUP5F',
//   VERSION: require('../../package.json').version,
//   VERSION_URL: '',
//   VERSION_CHECK_FREQUENCY: 1000 * 60 * 5 // 5 minutes
// };

export const environment = {
  production: false,
  WEBSOCKET_URL: 'wss://devapi.itsallsavvy.com',
  API_URL: 'https://devapi.itsallsavvy.com',
  // API_URL: 'https://api.itsallsavvy.com',
  //API_URL: 'https://blueapi.itsallsavvy.com',
  API_HOST: 'devapi.itsallsavvy.com',
  AUTH0_DOMAIN: 'authdev.itsallsavvy.com',
  AUTH0_CLIENT_ID: '2IwTfqr1o7s8iLK2Ljt13kna5lWEuTy2',

  GAPI_CLIENT_ID: '175252431223-pt89hn5pos217pjgctibk58qpnes7p5g.apps.googleusercontent.com',
  GAPI_API_KEY: 'AIzaSyApvEoctkvRXr2IAvome0wx-jbeKYGt3_I',

  /** Needed for subscriptions */
  STRIPE_KEY: 'pk_test_51IlB9PBIyYWjTWBBeY7kvr19BWGy4qC9KS63UV76lFJguR1MehNUTWxrCPizroXHOXTvBZlPn20uNXU3hf2S5dWG00g0LefjRF',
  SAVVY_PAY_CLIENT_ID: 'test_43EOC6HK7ZAIDIUAZVR6RD4FUIGMUP5F',

  VERSION: require('../../package.json').version,
  VERSION_URL: '',
  VERSION_CHECK_FREQUENCY: 1000 * 60 * 5, // 5 minutes
  CALLBACK_URL_ORIGIN: "https://devcallback.itsallsavvy.com",
  PET_STAY_CALENDAR_KEY: "GSTC:U2FsdGVkX1/89vSxuAG/Kc1nvCIkjew9y0g1Df0yS7LPkShrDe6vO4KFF4nireF1wSlhiXrywBm79PuthzX6DDtHnf8dZj+tY6z7Ifs/hFFUV0aavE8K1nIZEfSgHygrXRFWdQ1kcyuheDjfl7tn0b0GJraRyD9Qpu1QeDTVcy7PaEfzEYq1yF8yi8mTpxEEebG+p9FYaqyRYQIiDEsH2rGnEivnLAzPLuLHWa0PS03avCakm2pW0fybfHisnF2lOxbhyequOwWZIJMZgm0Fh2bcKI3wRSMmug8/xOVH+0Bhv61C8iIToAebjpCRgVwURXgL8Lm3PaTp7JdLog8koT08J2n7w2WD0aT/33K5v368+F4J0LmGG6F5zfwOUEJ5diqLGf963dyDiem3enUwPtZyp1hGUA2oeJlaJdXVXtkXte3c1IUN5jnMrOeDlZmcP2Hvzd5ZeNxIcZJoi/PJl3ZcyEpu4Qwb0NC2VWkpsBhKMrme4PyTBNo6fRPr7WjsyEZehDipMA+KbXOljXfPCNLWmMRv7mjXf/KA0pDDxkng3AG2979rvN0higuqjGwfPBGEdhR4EsuBB6NXmgNd8RfewDeIfqVjriegvf0ieeSGLRQuoftUvBboBVewLn0DI/4fG3HdOPl7UqddrR10NYumv/ePYAX/27ETGtOHNXYk1sU6W71uA3vQeqOB/108"

};
