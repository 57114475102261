<mat-card appearance="outlined" class="panel new-card" *ngIf="(futureAppointmentDates && futureAppointmentDates.length > 0 || pastAppointmentDates && pastAppointmentDates.length > 0)">
  <mat-card-title>
    <div fxLayout="row">
      <div fxFlex="90">
        <p class="mt-xs mb-xs">{{viewCustomerAppointmentsData.label | translate}}</p>
      </div>
      <div fxFlex="10">
        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="sendAppointments()">Send Appointments</button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="appMenu">
          <mat-icon>more_vert</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-title>
  <mat-card-content>
    <mat-tab-group>
      <mat-tab label="Future">
        <ng-template matTabContent>
          <ngx-datatable *ngIf="futureAppointmentDates" class='no-shadow material has-plus-btn' [style.border]="0" [rows]='futureAppointmentDates' [columnMode]="'force'" [selected]="futureSelected" [selectionType]="'single'" [headerHeight]="0" [rowHeight]="40" [footerHeight]="0" (select)="onFutureSelect($event)">
            <ngx-datatable-column name="{{'Date' | translate}}" [width]="80">
              <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
                {{ row.label }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'Blades' | translate}}" [width]="80">
              <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
                {{ row.blades }}
              </ng-template>
            </ngx-datatable-column>

          </ngx-datatable>
        </ng-template>
      </mat-tab>
      <mat-tab label="Past">
        <ng-template matTabContent>
          <ngx-datatable *ngIf="pastAppointmentDates" class='no-shadow material has-plus-btn' [style.border]="0" [rows]='pastAppointmentDates' [columnMode]="'force'" [selected]="pastSelected" [selectionType]="'single'" [headerHeight]="0" [rowHeight]="40" [footerHeight]="pastPage.totalElements > pastPage.size ? 50 : 0" [externalPaging]="true" [count]="pastPage.totalElements" [offset]="pastPage.pageNumber" [limit]="pastPage.size" (select)="onPastSelect($event)" (page)='setPastPage($event)'>

            <ngx-datatable-column name="{{'Date' | translate}}" [width]="80">
              <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
                {{ row.label }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'Blades' | translate}}" [width]="80">
              <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
                {{ row.blades }}
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </ng-template>
      </mat-tab>
    </mat-tab-group>


    <!--div fxLayout="row">
      <div fxFlex="90">
      </div>
      <div fxFlex="10">
        <button mat-mini-fab class="mat-mini-fab-bottom-right" color="accent" [disabled]="trialExpiredService.isTrialExpired()" (click)="create()">
          <mat-icon class="mat-24">add</mat-icon>
        </button>
      </div>
    </div-->
  </mat-card-content>

</mat-card>

<mat-card appearance="outlined" class="panel new-card" *ngIf="(futureAppointmentDates && futureAppointmentDates.length == 0 && pastAppointmentDates && pastAppointmentDates.length == 0)">
  <mat-card-content>
    <div class="flexbox-parent">
      <div fxLayout="row" fxLayoutAlign="center center">
        <div style="text-align:center;">
          <app-zero-image [name]="appointments"></app-zero-image><br>
          <h5 class="ma-0">{{'You don’t have any ' + viewCustomerAppointmentsData.label | translate}}</h5><br>
          <!-- *ngIf="listEntityListDto.canCreate" -->
          <!--button mat-raised-button color="accent" [disabled]="trialExpiredService.isTrialExpired()" (click)="create()">{{'New' | translate}} {{viewCustomerAppointmentsData.label | translate}}</button-->
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>