import { Injectable } from '@angular/core';

function _window(): any {
  // return the global native browser window object
  return window;
}

@Injectable(
  {
    providedIn: 'root'
  }
)
export class WindowRef {
  get nativeWindow(): any {
    return _window();
  }

  public getWindow(): any {
    return _window();
  }
  getProtocolHostPort() {
    let url = this.nativeWindow.location.protocol + '://' + this.nativeWindow.location.host;
    if (this.nativeWindow.location.port.length > 0) {
      url = url + ':' + this.nativeWindow.location.port;
    }
    console.log('returning ' + url);
    return url;
  }

  getWebsite() {

    if (this.getWindow().location.hostname === 'localhost') {
      return 'http://localhost';
    }
    return 'https://www' + this.getWindow().location.hostname.substring(3);
  }

  getPrefix() {
    let prefix = 'https';
    if (this.getWindow().location.hostname === 'localhost') {
      prefix = 'http://localhost:4100';
    } else {
      prefix = 'https://' + this.getWindow().location.hostname;
    }
    return prefix;
  }
}
