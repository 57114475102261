<mat-card appearance="outlined">
  <mat-card-content>
    <form #newDatalistForm="ngForm" class="w-100">
      <div fxLayout="row" fxLayoutAlign="start">

        <mat-form-field class="w-100" appearance="outline">
          <mat-label>{{'NEW_ITEM_NAME' | translate}}</mat-label>
          <input matInput class="demo-full-width" [(ngModel)]="newItemName" name="newItem" #newItem="ngModel" placeholder="{{'NEW_ITEM_NAME' | translate}}"
                 required>

          <mat-error *ngIf="newItem.hasError('required')">
            {{'NEW_ITEM_NAME_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between start" style="margin-top: 20px">
        <button mat-raised-button color="accent" (click)="updateList()"
                [disabled]="!newDatalistForm.form.valid" disabled="disabled">{{'ADD_ITEM' | translate}}</button>
        <button mat-raised-button color="accent" (click)="dialogRef.close(false)">{{'CANCEL' | translate}}</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
