import {EventEmitter} from '@angular/core';

export class ChangeListener {
    public eventAdded$: EventEmitter<string>;
    private events: string[] = [];

    constructor() {
        this.eventAdded$ = new EventEmitter();
    }

    public list(): string[] {
        return this.events;
    }

    public add(event: string): void {
        console.log('adding event ' + event);
        console.log('events ' + this.events);
        this.events.push(event);
        console.log('eventAdded$ ' + this.eventAdded$);
        this.eventAdded$.emit(event);
    }
}
