import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Contact, PhoneNumber } from '@savvy/workflow-definition';
import { UserCountryService } from 'src/app/services/userCountry.service';

class ModalData {
  contact: Contact;
}

@Component({
  selector: 'app-add-edit-pet-contact',
  templateUrl: './add-edit-pet-contact.component.html',
  styleUrls: ['./add-edit-pet-contact.component.scss']
})
export class AddEditPetContactComponent implements OnInit {
  contact: Contact = {
    firstName: '',
    lastName: '',
    mobilePhoneNumberObj: {
      country: '',
      internationalNumber: '',
      nationalNumber: ''
    },
    phoneNumberObj: {
      country: '',
      internationalNumber: '',
      nationalNumber: ''
    },
    whatsAppNumberObj: {
      country: '',
      internationalNumber: '',
      nationalNumber: ''
    },
    workPhoneNumberObj: {
      country: '',
      internationalNumber: '',
      nationalNumber: ''
    }
  };

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ModalData,
    @Optional() public dialogRef: MatDialogRef<AddEditPetContactComponent>,
    public userCountryService: UserCountryService
  ) { }

  ngOnInit() {
    if (this.data.contact) {
      this.contact = this.data.contact;
    }
  }

  addContact() {
    this.dialogRef.close(this.contact);
  }

  onMobilePhoneChange(value) {
    if (!this.contact.mobilePhoneNumberObj) {
      this.contact.mobilePhoneNumberObj = {} as PhoneNumber;
    }
    this.contact.mobilePhoneNumberObj = value;
  }

  onMobileCountryChange($event: any) {
    if (!this.contact.mobilePhoneNumberObj) {
      this.contact.mobilePhoneNumberObj = {
        country: '',
        internationalNumber: ''
      } as PhoneNumber;
    }
    this.contact.mobilePhoneNumberObj.country = $event.iso2;
    this.contact.mobilePhoneNumberObj.internationalNumber = `+${$event.dialCode}${this.contact.mobilePhoneNumberObj.nationalNumber}`;
  }

  onPhoneChanged(value) {
    if (!this.contact.phoneNumberObj) {
      this.contact.phoneNumberObj = {} as PhoneNumber;
    }
    this.contact.phoneNumberObj = value;
  }

  onPhoneCountryChange($event: any) {
    if (!this.contact.phoneNumberObj) {
      this.contact.phoneNumberObj = {} as PhoneNumber;
    }
    this.contact.phoneNumberObj.country = $event.iso2;
    this.contact.phoneNumberObj.internationalNumber = `+${$event.dialCode}${this.contact.phoneNumberObj.nationalNumber}`;
  }

  onWorkPhoneChanged(value) {
    if (!this.contact.workPhoneNumberObj) {
      this.contact.workPhoneNumberObj = {} as PhoneNumber;
    }
    this.contact.workPhoneNumberObj = value;
  }

  onWorkPhoneCountryChange($event: any) {
    if (!this.contact.workPhoneNumberObj) {
      this.contact.workPhoneNumberObj = {} as PhoneNumber;
    }
    this.contact.workPhoneNumberObj.country = $event.iso2;
    this.contact.workPhoneNumberObj.internationalNumber = `+${$event.dialCode}${this.contact.workPhoneNumberObj.nationalNumber}`;
  }

}
