import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ContextIdDto } from '@savvy/view-definition';

@Component({
  selector: 'app-zero-customer-signup-form-templates',
  templateUrl: 'zeroCustomerSignupFormTemplates.component.html'
})
export class ZeroCustomerSignupFormTemplatesComponent {

  @Input() contextIdDto: ContextIdDto;

  constructor(private router: Router) {
  }
  create() {
    this.router.navigate(['/customerSignup/addCustomerSignupFormDef',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType]);
  }
}

