import { Component, Input } from '@angular/core';
import { ViewDocumentDto } from '@savvy/view-definition';
import { ContextIdDto } from '@savvy/quickbooks';
import { ConfService } from '../config/conf.service';

@Component({
  selector: 'app-view-document',
  templateUrl: 'viewDocument.component.html'
})
export class ViewDocumentComponent {

  @Input() viewDocumentDto: ViewDocumentDto;
  @Input() viewContext: any;
  @Input() contextIdDto: ContextIdDto;

  constructor() {
  }

  documentUrl() {
    if (this.viewDocumentDto.elementInstanceDto.fieldInstanceDto.value.documentFieldValueDto) {
      console.log('ref is ' + this.viewDocumentDto.elementInstanceDto.fieldInstanceDto.value.documentFieldValueDto.fileReferenceId);
      return ConfService.apiUrl() + '/rest/storage?contextId=' + this.contextIdDto.contextId +
        '&contextIdType=' + this.contextIdDto.contextIdType +
        '&id=' + this.viewDocumentDto.elementInstanceDto.fieldInstanceDto.value.documentFieldValueDto.fileReferenceId;
    }
    return '';
  }
}

