/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { ContextIdDto } from '@savvy/app';
import { SubscriptionService } from '@savvy/subscription';
import { WorkflowDefinition, WorkflowdefService } from "@savvy/workflow-definition";
import { AddOnsHelperService } from '../flo/shared/services/add-ons-helper.service';
import { AppointmentConfigHelperService } from '../flo/shared/services/appointment-config-helper.service';
import { LocationsHelperService } from '../flo/shared/services/locations-helper.service';
import { OrgSetupHelperService } from '../flo/shared/services/org-setup-helper.service';
import { ProductsHelperService } from '../flo/shared/services/products-helper.service';
import { ServicesHelperService } from '../flo/shared/services/services-helper.service';
import { TaxesHelperService } from '../flo/shared/services/taxes-helper.service';

export enum ElementType {
  'RoleId' = 'ROLE_ID',
  'AppointmentId' = 'APPOINTMENT_ID',
  'AppointmentDefinitionId' = 'APPOINTMENT_DEFINITION_ID',
  'EntityDefinitionId' = 'ENTITY_DEFINITION_ID',
  'WebsiteId' = 'WEBSITE_ID',
  'WebsiteTemplateId' = 'WEBSITE_TEMPLATE_ID',
  'PaymentSettingsId' = 'PAYMENT_SETTINGS_ID',
  'SmsBundleId' = 'SMS_BUNDLE_ID',
  'SimpleBookingWindowId' = 'SIMPLE_BOOKING_WINDOW_ID',
  'ReferralId' = 'REFERRAL_ID',
  'ItineraryId' = 'ITINERARY_ID',
  'ReferralDefinitionId' = 'REFERRAL_DEFINITION_ID',
  'ReportDefinitionId' = 'REPORT_DEFINITION_ID',
  'SeriesId' = 'SERIES_ID',
  'EntityDefinitionViewId' = 'ENTITY_DEFINITION_VIEW_ID',
  'CalendarEntryId' = 'CALENDAR_ENTRY_ID',
  'CalendarSettingsId' = 'CALENDAR_SETTINGS_ID',
  'OrderId' = 'ORDER_ID',
  'QuoteId' = 'QUOTE_ID',
  'LabelValueRowData' = 'LABEL_VALUE_ROW_DATA',
  'TitleDescriptionDto' = 'TITLE_DESCRIPTION_DTO',
  'DonutChart' = 'DONUT_CHART',
  'ClockInId' = 'CLOCK_IN_ID',
  'StockId' = 'STOCK_ID',
  'CustomerSignupFormId' = 'CUSTOMER_SIGNUP_FORM_ID',
  'CustomerSignupFormDefinitionId' = 'CUSTOMER_SIGNUP_FORM_DEFINITION_ID',
  'ContactFormId' = 'CONTACT_FORM_ID',
  'ContactFormDefinitionId' = 'CONTACT_FORM_DEFINITION_ID',
  'CheckinFormId' = 'CHECKIN_FORM_ID',
  'CheckinFormDefinitionId' = 'CHECKIN_FORM_DEFINITION_ID',
  'LeadId' = 'LEAD_ID',
  'LeadDefinitionId' = 'LEAD_DEFINITION_ID',
  'QuestionnaireId' = 'QUESTIONNAIRE_ID',
  'QuestionnaireDefinitionId' = 'QUESTIONNAIRE_DEFINITION_ID',
  'BookingId' = 'BOOKING_ID',
  'BookingDefinitionId' = 'BOOKING_DEFINITION_ID',
  'AllowedSlotId' = 'ALLOWED_SLOT_ID',
  'BrandId' = 'BRAND_ID',
  'ElementDefinitionId' = 'ELEMENT_DEFINITION_ID',
  'InvoiceId' = 'INVOICE_ID',
  'RequestForPaymentId' = 'REQUEST_FOR_PAYMENT_ID',
  'FlagId' = 'FLAG_ID',
  'FlagDefinitionId' = 'FLAG_DEFINITION_ID',
  'PaymentScheduleDefinitionId' = 'PAYMENT_SCHEDULE_DEFINITION_ID',
  'ContractId' = 'CONTRACT_ID',
  'PaymentId' = 'PAYMENT_ID',
  'TipId' = 'TIP_ID',
  'ReminderDefinitionId' = 'REMINDER_DEFINITION_ID',
  'ReminderConfDefinitionId' = 'REMINDER_CONF_DEFINITION_ID',
  'ReminderId' = 'REMINDER_ID',
  'ElementId' = 'ELEMENT_ID',
  'AppId' = 'APP_ID',
  'HolidayId' = 'HOLIDAY_ID',
  'WorkingHoursId' = 'WORKING_HOURS_ID',
  'ClassificationId' = 'CLASSIFICATION_ID',
  'DashboardDefinitionId' = 'DASHBOARD_DEFINITION_ID',
  'DatalistId' = 'DATALIST_ID',
  'ElementInstanceId' = 'ELEMENT_INSTANCE_ID',
  'EmailMessageTemplateId' = 'EMAIL_MESSAGE_TEMPLATE_ID',
  'EmailHtmlTemplateId' = 'EMAIL_HTML_TEMPLATE_ID',
  'EntityInstanceId' = 'ENTITY_INSTANCE_ID',
  'ServiceGroupId' = 'SERVICE_GROUP_ID',
  'ServiceId' = 'SERVICE_ID',
  'EnrichedEntityInstanceId' = 'ENRICHED_ENTITY_INSTANCE_ID',
  'CustomerId' = 'CUSTOMER_ID',
  'StudentId' = 'STUDENT_ID',
  'SlotDefinitionId' = 'SLOT_DEFINITION_ID',
  'ViewEntityInstance' = 'VIEW_ENTITY_INSTANCE',
  'ViewEntityInstanceDefinition' = 'VIEW_ENTITY_INSTANCE_DEFINITION',
  'RoleIds' = 'ROLE_IDS',
  'ViewCustomer' = 'VIEW_CUSTOMER',
  'SubscriptionDefinitionId' = 'SUBSCRIPTION_DEFINITION_ID',
  'SubscriptionId' = 'SUBSCRIPTION_ID',
  'BookableWindowInstanceId' = 'BOOKABLE_WINDOW_INSTANCE_ID',
  'EnvId' = 'ENV_ID',
  'ImportDefinitionId' = 'IMPORT_DEFINITION_ID',
  'InvoiceDefinitionId' = 'INVOICE_DEFINITION_ID',
  'ImportRequestId' = 'IMPORT_REQUEST_ID',
  'JobId' = 'JOB_ID',
  'LookAndFeelId' = 'LOOK_AND_FEEL_ID',
  'WaitingListId' = 'WAITING_LIST_ID',
  'MessageId' = 'MESSAGE_ID',
  'OrgId' = 'ORG_ID',
  'PermissionId' = 'PERMISSION_ID',
  'PublishRequestId' = 'PUBLISH_REQUEST_ID',
  'QueryDefinitionId' = 'QUERY_DEFINITION_ID',
  'ViewDefinitionId' = 'VIEW_DEFINITION_ID',
  'MenuDefinitionId' = 'MENU_DEFINITION_ID',
  'CustomerAggregateViewDefinitionId' = 'CUSTOMER_AGGREGATE_VIEW_DEFINITION_ID',
  'CachedTableViewDefinitionId' = 'CACHED_TABLE_VIEW_DEFINITION_ID',
  'CachedLineViewDefinitionId' = 'CACHED_LINE_VIEW_DEFINITION_ID',
  'UserId' = 'USER_ID',
  'RegistrationAttemptId' = 'REGISTRATION_ATTEMPT_ID',
  'BookableWindowId' = 'BOOKABLE_WINDOW_ID',
  'ScheduleDefinitionId' = 'SCHEDULE_DEFINITION_ID',
  'WorkflowInstanceId' = 'WORKFLOW_INSTANCE_ID',
  'ShopOrderId' = 'SHOP_ORDER_ID',
  'WorkflowDefinitionId' = 'WORKFLOW_DEFINITION_ID',
  'WorkflowDefinitionConfigId' = 'WORKFLOW_DEFINITION_CONFIG_ID',
  'WorkflowStateId' = 'WORKFLOW_STATE_ID',
  'TaskId' = 'TASK_ID',
  'TokenId' = 'TOKEN_ID',
  'SessionId' = 'SESSION_ID',
  'RuleSetDefinitionId' = 'RULE_SET_DEFINITION_ID',
  'SecuritySettingsId' = 'SECURITY_SETTINGS_ID',
  'TaskDefinitionId' = 'TASK_DEFINITION_ID',
  'TestDefinitionId' = 'TEST_DEFINITION_ID',
  'TestExecutionId' = 'TEST_EXECUTION_ID',
  'RuleDefinitionId' = 'RULE_DEFINITION_ID',
  'ActionDefinitionId' = 'ACTION_DEFINITION_ID',
  'ActionTypeDefinitionId' = 'ACTION_TYPE_DEFINITION_ID',
  'ActionConfigurationDefinitionId' = 'ACTION_CONFIGURATION_DEFINITION_ID',
  'ActionConfigurationInstanceId' = 'ACTION_CONFIGURATION_INSTANCE_ID',
  'SlotStartTime' = 'SLOT_START_TIME',
  'SlotEndTime' = 'SLOT_END_TIME',
  'BankHolidayId' = 'BANK_HOLIDAY_ID',
  'Links' = 'LINKS',
  'CalendarViewDefinition' = 'CALENDAR_VIEW_DEFINITION',
  'CalendarViewEntryDefinition' = 'CALENDAR_VIEW_ENTRY_DEFINITION',
  'SlideOutDefinition' = 'SLIDE_OUT_DEFINITION',
  'EntityTasksPanelDefinition' = 'ENTITY_TASKS_PANEL_DEFINITION',
  'ActivityPanelDefinition' = 'ACTIVITY_PANEL_DEFINITION',
  'WizardDefinition' = 'WIZARD_DEFINITION',
  'MapPanelDefinition' = 'MAP_PANEL_DEFINITION',
  'WeatherPanelDefinition' = 'WEATHER_PANEL_DEFINITION',
  'LoginPanel' = 'LOGIN_PANEL',
  'SingleLabelValuePanel' = 'SINGLE_LABEL_VALUE_PANEL',
  'LabelValuePanel' = 'LABEL_VALUE_PANEL',
  'LabelValueLeftRightPanel' = 'LABEL_VALUE_LEFT_RIGHT_PANEL',
  'SingleLabelValuePanelDefinition' = 'SINGLE_LABEL_VALUE_PANEL_DEFINITION',
  'LabelValuePanelDefinition' = 'LABEL_VALUE_PANEL_DEFINITION',
  'LabelValuePair' = 'LABEL_VALUE_PAIR',
  'LabelValuePairDefinition' = 'LABEL_VALUE_PAIR_DEFINITION',
  'LabelValueRowDefinition' = 'LABEL_VALUE_ROW_DEFINITION',
  'ViewEntityPanelDefinition' = 'VIEW_ENTITY_PANEL_DEFINITION',
  'LabelValueLeftRightPanelDefinition' = 'LABEL_VALUE_LEFT_RIGHT_PANEL_DEFINITION',
  'ViewEntityWithImagePanelDefinition' = 'VIEW_ENTITY_WITH_IMAGE_PANEL_DEFINITION',
  'ViewEntityWithImagePanelData' = 'VIEW_ENTITY_WITH_IMAGE_PANEL_DATA',
  'ViewAppointmentPanelDefinition' = 'VIEW_APPOINTMENT_PANEL_DEFINITION',
  'ViewAppointmentPanelData' = 'VIEW_APPOINTMENT_PANEL_DATA',
  'ViewEntityPanelData' = 'VIEW_ENTITY_PANEL_DATA',
  'ViewEntityCardDefinition' = 'VIEW_ENTITY_CARD_DEFINITION',
  'TitleDescriptionDefinition' = 'TITLE_DESCRIPTION_DEFINITION',
  'WorkflowPanel' = 'WORKFLOW_PANEL',
  'WorkflowPanelDefinition' = 'WORKFLOW_PANEL_DEFINITION',
  'SelectionList' = 'SELECTION_LIST',
  'SelectionListDefinition' = 'SELECTION_LIST_DEFINITION',
  'ActionDefinition' = 'ACTION_DEFINITION',
  'IconDefinition' = 'ICON_DEFINITION',
  'TaskDefinition' = 'TASK_DEFINITION',
  'CardContentDefinition' = 'CARD_CONTENT_DEFINITION',
  'CardHeaderDefinition' = 'CARD_HEADER_DEFINITION',
  'TaskSettings' = 'TASK_SETTINGS',
  'TimeFormat' = 'TIME_FORMAT',
  'SplitPanel' = 'SPLIT_PANEL',
  'SplitLogin' = 'SPLIT_LOGIN',
  'SocialWidget' = 'SOCIAL_WIDGET',
  'SocialWidgetDefinition' = 'SOCIAL_WIDGET_DEFINITION',
  'Panel' = 'PANEL',
  'VerticalPanel' = 'VERTICAL_PANEL',
  'CreateAppointmentViewDefinition' = 'CREATE_APPOINTMENT_VIEW_DEFINITION',
  'CreateAppointmentViewData' = 'CREATE_APPOINTMENT_VIEW_DATA',
  'HorizontalPanel' = 'HORIZONTAL_PANEL',
  'ZeroInstances' = 'ZERO_INSTANCES',
  'CardDefinition' = 'CARD_DEFINITION',
  'CopyButtonDefinition' = 'COPY_BUTTON_DEFINITION',
  'ConfirmAndCreateButtonDefinition' = 'CONFIRM_AND_CREATE_BUTTON_DEFINITION',
  'MoveButtonDefinition' = 'MOVE_BUTTON_DEFINITION',
  'CustomerPopupButtonDefinition' = 'CUSTOMER_POPUP_BUTTON_DEFINITION',
  'EiPopupButtonDefinition' = 'EI_POPUP_BUTTON_DEFINITION',
  'ImportFieldDefinition' = 'IMPORT_FIELD_DEFINITION',
  'ImagePanelDefinition' = 'IMAGE_PANEL_DEFINITION',
  'ImagePanel' = 'IMAGE_PANEL',
  'ViewElement' = 'VIEW_ELEMENT',
  'ListEntityTableDefinition' = 'LIST_ENTITY_TABLE_DEFINITION',
  'ListEntityTable' = 'LIST_ENTITY_TABLE',
  'ListEntityTeaserDefinition' = 'LIST_ENTITY_TEASER_DEFINITION',
  'ListEntityTeaser' = 'LIST_ENTITY_TEASER',
  'ListEntityImagesDefinition' = 'LIST_ENTITY_IMAGES_DEFINITION',
  'ListEntityImages' = 'LIST_ENTITY_IMAGES',
  'BadgetDefinition' = 'BADGET_DEFINITION',
  'Badge' = 'BADGE',
  'BarChartDefinition' = 'BAR_CHART_DEFINITION',
  'InvoiceChartDefinition' = 'INVOICE_CHART_DEFINITION',
  'DonutChartDefinition' = 'DONUT_CHART_DEFINITION',
  'CustomerSourceDefinition' = 'CUSTOMER_SOURCE_DEFINITION',
  'BarChart' = 'BAR_CHART',
  'EntitySummaryDefinition' = 'ENTITY_SUMMARY_DEFINITION',
  'OrderSummaryDefinition' = 'ORDER_SUMMARY_DEFINITION',
  'ContactFormSummaryDefinition' = 'CONTACT_FORM_SUMMARY_DEFINITION',
  'PaymentsSummaryDefinition' = 'PAYMENTS_SUMMARY_DEFINITION',
  'QuotesSummaryDefinition' = 'QUOTES_SUMMARY_DEFINITION',
  'ContactFormSummary' = 'CONTACT_FORM_SUMMARY',
  'PaymentsSummary' = 'PAYMENTS_SUMMARY',
  'QuotesSummary' = 'QUOTES_SUMMARY',
  'NewsDefinition' = 'NEWS_DEFINITION',
  'News' = 'NEWS',
  'ServiceStartTimeViewFragmentDef' = 'SERVICE_START_TIME_VIEW_FRAGMENT_DEF',
  'StartTimeViewFragmentDef' = 'START_TIME_VIEW_FRAGMENT_DEF',
  'StartTimeViewFragmentData' = 'START_TIME_VIEW_FRAGMENT_DATA',
  'ListContractsDefinition' = 'LIST_CONTRACTS_DEFINITION',
  'FlagPanelDefinition' = 'FLAG_PANEL_DEFINITION',
  'ViewFlagPanelDefinition' = 'VIEW_FLAG_PANEL_DEFINITION',
  'FlagPanelData' = 'FLAG_PANEL_DATA',
  'ListOrdersDefinition' = 'LIST_ORDERS_DEFINITION',
  'ViewCustomerPanelDefinition' = 'VIEW_CUSTOMER_PANEL_DEFINITION',
  'ViewCustomerWorkflowStats' = 'VIEW_CUSTOMER_WORKFLOW_STATS',
  'ViewCustomerHistoryButton' = 'VIEW_CUSTOMER_HISTORY_BUTTON',
  'LinkedCustomerSelector' = 'LINKED_CUSTOMER_SELECTOR',
  'WorkflowStatsData' = 'WORKFLOW_STATS_DATA',
  'ViewCustomerPageDefinition' = 'VIEW_CUSTOMER_PAGE_DEFINITION',
  'ListInvoiceDefinition' = 'LIST_INVOICE_DEFINITION',
  'ListItineraryDefinition' = 'LIST_ITINERARY_DEFINITION',
  'ListCheckInFormsDefinition' = 'LIST_CHECK_IN_FORMS_DEFINITION',
  'ListItinerariesList' = 'LIST_ITINERARIES_LIST',
  'ListInvoiceList' = 'LIST_INVOICE_LIST',
  'ListContractList' = 'LIST_CONTRACT_LIST',
  'ListCheckInFormsData' = 'LIST_CHECK_IN_FORMS_DATA',
  'ListOrdersList' = 'LIST_ORDERS_LIST',
  'ListQuestionnairesList' = 'LIST_QUESTIONNAIRES_LIST',
  'ListEntitysListDefinition' = 'LIST_ENTITYS_LIST_DEFINITION',
  'ListCachedEntitysListDefinition' = 'LIST_CACHED_ENTITYS_LIST_DEFINITION',
  'StepPanelDefinition' = 'STEP_PANEL_DEFINITION',
  'StepDefinition' = 'STEP_DEFINITION',
  'ViewCachedEntitysListDefinition' = 'VIEW_CACHED_ENTITYS_LIST_DEFINITION',
  'ViewCachedEntitysExpandableDefinition' = 'VIEW_CACHED_ENTITYS_EXPANDABLE_DEFINITION',
  'ViewInvoiceEntitysExpandableDefinition' = 'VIEW_INVOICE_ENTITYS_EXPANDABLE_DEFINITION',
  'ViewInvoiceEntitysCachedTableDefinition' = 'VIEW_INVOICE_ENTITYS_CACHED_TABLE_DEFINITION',
  'ViewInvoiceEntityNamesDefinition' = 'VIEW_INVOICE_ENTITY_NAMES_DEFINITION',
  'ViewInvoiceEntityNamesData' = 'VIEW_INVOICE_ENTITY_NAMES_DATA',
  'ViewInvoiceEntitysCachedTableData' = 'VIEW_INVOICE_ENTITYS_CACHED_TABLE_DATA',
  'ViewInvoiceEntitysExpandableData' = 'VIEW_INVOICE_ENTITYS_EXPANDABLE_DATA',
  'ViewAppointmentDefinition' = 'VIEW_APPOINTMENT_DEFINITION',
  'ListCachedEntitysTableDefinition' = 'LIST_CACHED_ENTITYS_TABLE_DEFINITION',
  'ListCachedEntitysTableData' = 'LIST_CACHED_ENTITYS_TABLE_DATA',
  'ListQuestionnairesDefinition' = 'LIST_QUESTIONNAIRES_DEFINITION',
  'ListEntitysList' = 'LIST_ENTITYS_LIST',
  'TeaserDefinition' = 'TEASER_DEFINITION',
  'Teasers' = 'TEASERS',
  'ExpandablesDefinition' = 'EXPANDABLES_DEFINITION',
  'Expandables' = 'EXPANDABLES',
  'TableDefinition' = 'TABLE_DEFINITION',
  'Table' = 'TABLE',
  'ListMessagesDefinition' = 'LIST_MESSAGES_DEFINITION',
  'ViewInvoicePanel' = 'VIEW_INVOICE_PANEL',
  'ViewInvoicePanelData' = 'VIEW_INVOICE_PANEL_DATA',
  'InvoiceServicePanel' = 'INVOICE_SERVICE_PANEL',
  'ConflictCheckButton' = 'CONFLICT_CHECK_BUTTON',
  'SimpleMessagesPanelDefinition' = 'SIMPLE_MESSAGES_PANEL_DEFINITION',
  'ViewRemindersDefinition' = 'VIEW_REMINDERS_DEFINITION',
  'ViewCustomerRemindersDefinition' = 'VIEW_CUSTOMER_REMINDERS_DEFINITION',
  'SendSmsDefinition' = 'SEND_SMS_DEFINITION',
  'ViewReminders' = 'VIEW_REMINDERS',
  'AvatarRowDefinition' = 'AVATAR_ROW_DEFINITION',
  'AvatarRow' = 'AVATAR_ROW',
  'SectionHeader' = 'SECTION_HEADER',
  'TabPanel' = 'TAB_PANEL',
  'Tab' = 'TAB',
  'ElementSetDefinition' = 'ELEMENT_SET_DEFINITION',
  'MultiValue' = 'MULTI_VALUE',
  'ElementInstance' = 'ELEMENT_INSTANCE',
  'ViewCustomerEmbedded' = 'VIEW_CUSTOMER_EMBEDDED',
  'ViewCustomerEmbeddedData' = 'VIEW_CUSTOMER_EMBEDDED_DATA',
  'ViewAssociatedCachedTableViewEmbedded' = 'VIEW_ASSOCIATED_CACHED_TABLE_VIEW_EMBEDDED',
  'ViewAssociatedCachedTableViewEmbeddedData' = 'VIEW_ASSOCIATED_CACHED_TABLE_VIEW_EMBEDDED_DATA',
  'ViewAppointmentEmbeddedData' = 'VIEW_APPOINTMENT_EMBEDDED_DATA',
  'ViewAppointmentData' = 'VIEW_APPOINTMENT_DATA',
  'ViewInvoicesByEiListView' = 'VIEW_INVOICES_BY_EI_LIST_VIEW',
  'ViewInvoicesByEiListViewData' = 'VIEW_INVOICES_BY_EI_LIST_VIEW_DATA',
  'ViewApptsByEiListView' = 'VIEW_APPTS_BY_EI_LIST_VIEW',
  'ViewApptsByEiListViewData' = 'VIEW_APPTS_BY_EI_LIST_VIEW_DATA',
  'ViewCustomerApptsView' = 'VIEW_CUSTOMER_APPTS_VIEW',
  'ViewCustomerApptsViewData' = 'VIEW_CUSTOMER_APPTS_VIEW_DATA',
  'ViewAssociatedCachedListView' = 'VIEW_ASSOCIATED_CACHED_LIST_VIEW',
  'ViewAssociatedCachedListViewData' = 'VIEW_ASSOCIATED_CACHED_LIST_VIEW_DATA',
  'ViewCachedTableViewEmbedded' = 'VIEW_CACHED_TABLE_VIEW_EMBEDDED',
  'ViewCachedTableViewEmbeddedData' = 'VIEW_CACHED_TABLE_VIEW_EMBEDDED_DATA',
  'ViewEntityEmbedded' = 'VIEW_ENTITY_EMBEDDED',
  'ViewEntityEmbeddedData' = 'VIEW_ENTITY_EMBEDDED_DATA',
  'WorkflowActionInstance' = 'WORKFLOW_ACTION_INSTANCE',
  'FieldInstance' = 'FIELD_INSTANCE',
  'ViewImageDefinition' = 'VIEW_IMAGE_DEFINITION',
  'ViewImage' = 'VIEW_IMAGE',
  'ViewVideoDefinition' = 'VIEW_VIDEO_DEFINITION',
  'ViewVideo' = 'VIEW_VIDEO',
  'ViewDocumentDefinition' = 'VIEW_DOCUMENT_DEFINITION',
  'ViewDocument' = 'VIEW_DOCUMENT',
  'MappingDefinition' = 'MAPPING_DEFINITION',
  'FieldReference' = 'FIELD_REFERENCE',
  'ServiceUser' = 'SERVICE_USER',
  'BrandName' = 'BRAND_NAME',
  'ServiceUserName' = 'SERVICE_USER_NAME',
  'ServiceType' = 'SERVICE_TYPE',
  'TimePeriod' = 'TIME_PERIOD',
  'FieldReferenceDefinition' = 'FIELD_REFERENCE_DEFINITION',
  'TimePeriodDefinition' = 'TIME_PERIOD_DEFINITION',
  'UserResolutionStrategy' = 'USER_RESOLUTION_STRATEGY',
  'IntegerValueDefinition' = 'INTEGER_VALUE_DEFINITION',
  'BigDecimalValueDefinition' = 'BIG_DECIMAL_VALUE_DEFINITION',
  'ColourValueDefinition' = 'COLOUR_VALUE_DEFINITION',
  'BooleanValueDefinition' = 'BOOLEAN_VALUE_DEFINITION',
  'ImageFieldDefinition' = 'IMAGE_FIELD_DEFINITION',
  'Section' = 'SECTION',
  'Select' = 'SELECT',
  'Date' = 'DATE',
  'DateTime' = 'DATE_TIME',
  'Time' = 'TIME',
  'Input' = 'INPUT',
  'Files' = 'FILES',
  'FileUpload' = 'FILE_UPLOAD',
  'Image' = 'IMAGE',
  'Video' = 'VIDEO',
  'Document' = 'DOCUMENT',
  'Password' = 'PASSWORD',
  'TextArea' = 'TEXT_AREA',
  'Icons' = 'ICONS',
  'Location' = 'LOCATION',
  'Address' = 'ADDRESS',
  'Contact' = 'CONTACT',
  'Signature' = 'SIGNATURE',
  'CheckBox' = 'CHECK_BOX',
  'Radio' = 'RADIO',
  'NavigateTo' = 'NAVIGATE_TO',
  'Button' = 'BUTTON',
  'Anchor' = 'ANCHOR',
  'Label' = 'LABEL',
  'HtmlFragment' = 'HTML_FRAGMENT',
  'LinkedEntity' = 'LINKED_ENTITY',
  'LinkedCustomerDefinition' = 'LINKED_CUSTOMER_DEFINITION',
  'HeaderAndLines' = 'HEADER_AND_LINES',
  'InvoicePanelData' = 'INVOICE_PANEL_DATA',
  'InvoiceServicesPanelData' = 'INVOICE_SERVICES_PANEL_DATA',
  'InvoicePackagePanelDataDto' = 'INVOICE_PACKAGE_PANEL_DATA',
  'ToolBar' = 'TOOL_BAR',
  'ToolBarDefinition' = 'TOOL_BAR_DEFINITION',
  'ToolBarRowDefinition' = 'TOOL_BAR_ROW_DEFINITION',
  'LineDefinition' = 'LINE_DEFINITION',
  'LinesDefinition' = 'LINES_DEFINITION',
  'ViewCustomerHeaderDefinition' = 'VIEW_CUSTOMER_HEADER_DEFINITION',
  'ViewCustomerHeader' = 'VIEW_CUSTOMER_HEADER',
  'Space' = 'SPACE',
  'LineBreak' = 'LINE_BREAK',
  'GridListDefinition' = 'GRID_LIST_DEFINITION',
  'GridTileDefinition' = 'GRID_TILE_DEFINITION',
  'HtmlElement' = 'HTML_ELEMENT',
  'HtmlSelectElement' = 'HTML_SELECT_ELEMENT',
  'ElementValueDefinition' = 'ELEMENT_VALUE_DEFINITION',
  'CountWorkflowStatesDefinition' = 'COUNT_WORKFLOW_STATES_DEFINITION',
  'TotalSpentDefinition' = 'TOTAL_SPENT_DEFINITION',
  'AverageSpentDefinition' = 'AVERAGE_SPENT_DEFINITION',
  'WorkflowStateColourValueDefinition' = 'WORKFLOW_STATE_COLOUR_VALUE_DEFINITION',
  'ServiceColourValueDefinition' = 'SERVICE_COLOUR_VALUE_DEFINITION',
  'ServiceNameValueDefinition' = 'SERVICE_NAME_VALUE_DEFINITION',
  'ServiceCostValueDefinition' = 'SERVICE_COST_VALUE_DEFINITION',
  'ServiceEiValueDefinition' = 'SERVICE_EI_VALUE_DEFINITION',
  'ServiceStartTimeValueDefinition' = 'SERVICE_START_TIME_VALUE_DEFINITION',
  'ServiceStartDateTimeValueDefinition' = 'SERVICE_START_DATE_TIME_VALUE_DEFINITION',
  'ServiceEndTimeValueDefinition' = 'SERVICE_END_TIME_VALUE_DEFINITION',
  'ServiceEndDateTimeValueDefinition' = 'SERVICE_END_DATE_TIME_VALUE_DEFINITION',
  'InvoiceDateDefinition' = 'INVOICE_DATE_DEFINITION',
  'InvoiceDueDateDefinition' = 'INVOICE_DUE_DATE_DEFINITION',
  'ServiceDurationValueDefinition' = 'SERVICE_DURATION_VALUE_DEFINITION',
  'WorkflowStateValueDefinition' = 'WORKFLOW_STATE_VALUE_DEFINITION',
  'ElementSetValue' = 'ELEMENT_SET_VALUE',
  'TagElementValue' = 'TAG_ELEMENT_VALUE',
  'StringValue' = 'STRING_VALUE',
  'WorkflowExecuteActionPanelDefinition' = 'WORKFLOW_EXECUTE_ACTION_PANEL_DEFINITION',
  'WorkflowExecuteActionPanelData' = 'WORKFLOW_EXECUTE_ACTION_PANEL_DATA',
  'WorkflowProgressDefinition' = 'WORKFLOW_PROGRESS_DEFINITION',
  'ProgressBarDto' = 'PROGRESS_BAR_DTO',
  'CustomerFullNameValue' = 'CUSTOMER_FULL_NAME_VALUE',
  'CustomerFirstNameValue' = 'CUSTOMER_FIRST_NAME_VALUE',
  'CustomerLastNameValue' = 'CUSTOMER_LAST_NAME_VALUE',
  'CustomerEmailValue' = 'CUSTOMER_EMAIL_VALUE',
  'CustomerMobileValue' = 'CUSTOMER_MOBILE_VALUE',
  'CustomerPhoneValue' = 'CUSTOMER_PHONE_VALUE',
  'RepeatableEventDefinition' = 'REPEATABLE_EVENT_DEFINITION',
  'CustomerFirstNameField' = 'CUSTOMER_FIRST_NAME_FIELD',
  'CustomerLastNameField' = 'CUSTOMER_LAST_NAME_FIELD',
  'CustomerEmailField' = 'CUSTOMER_EMAIL_FIELD',
  'CustomerMobileField' = 'CUSTOMER_MOBILE_FIELD',
  'CustomerPhoneField' = 'CUSTOMER_PHONE_FIELD',
  'EntitySummaryDto' = 'ENTITY_SUMMARY_DTO',
  'OrdersSummaryDto' = 'ORDERS_SUMMARY_DTO',
  'BooleanValue' = 'BOOLEAN_VALUE',
  'IntegerValue' = 'INTEGER_VALUE',
  'FieldValue' = 'FIELD_VALUE',
  'FieldValueLine' = 'FIELD_VALUE_LINE',
  'WorkflowFieldDefinition' = 'WORKFLOW_FIELD_DEFINITION',
  'InvoiceFieldDefinition' = 'INVOICE_FIELD_DEFINITION',
}

export enum ValueType {
  Boolean = 'BOOLEAN',
  String = 'STRING',
  StringArray = 'STRING_ARRAY',
  Image = 'IMAGE',
  Video = 'VIDEO',
  Document = 'DOCUMENT', // ie. PDF, Excel etc.
  Files = 'FILES',
  // .
  /**
   * An instance of a workflow (ie. state but it also holds the workflow def it relates to).
   */
  Workflow = 'WORKFLOW',
  Invoice = 'INVOICE',
  Date = 'DATE',
  DateTime = 'DATE_TIME',
  Time = 'TIME',
  Integer = 'INTEGER',
  BigDecimal = 'BIG_DECIMAL',
  Contact = 'CONTACT',
  Address = 'ADDRESS',
  FieldRedirect = 'FIELD_REDIRECT',
  RepeatableEvent = 'REPEATABLE_EVENT',
}

export class LinkId {
  linkedId?: string;
  linkedIdType?: LinkedIdTypeEnum;
  linkedEdId?: string;
}

export enum LinkedIdTypeEnum {
  'RoleId' = 'ROLE_ID',
  'PetId' = 'PET_ID',
  'AppointmentId' = 'APPOINTMENT_ID',
  'AppointmentDefinitionId' = 'APPOINTMENT_DEFINITION_ID',
  'EntityDefinitionId' = 'ENTITY_DEFINITION_ID',
  'WebsiteId' = 'WEBSITE_ID',
  'WebsiteTemplateId' = 'WEBSITE_TEMPLATE_ID',
  'PaymentSettingsId' = 'PAYMENT_SETTINGS_ID',
  'SmsBundleId' = 'SMS_BUNDLE_ID',
  'SimpleBookingWindowId' = 'SIMPLE_BOOKING_WINDOW_ID',
  'ReferralId' = 'REFERRAL_ID',
  'ItineraryId' = 'ITINERARY_ID',
  'ReferralDefinitionId' = 'REFERRAL_DEFINITION_ID',
  'ReportDefinitionId' = 'REPORT_DEFINITION_ID',
  'SeriesId' = 'SERIES_ID',
  'EntityDefinitionViewId' = 'ENTITY_DEFINITION_VIEW_ID',
  'CalendarEntryId' = 'CALENDAR_ENTRY_ID',
  'CalendarSettingsId' = 'CALENDAR_SETTINGS_ID',
  'OrderId' = 'ORDER_ID',
  'QuoteId' = 'QUOTE_ID',
  'LabelValueRowData' = 'LABEL_VALUE_ROW_DATA',
  'TitleDescriptionDto' = 'TITLE_DESCRIPTION_DTO',
  'DonutChart' = 'DONUT_CHART',
  'ClockInId' = 'CLOCK_IN_ID',
  'StockId' = 'STOCK_ID',
  'ContactFormId' = 'CONTACT_FORM_ID',
  'ContactFormDefinitionId' = 'CONTACT_FORM_DEFINITION_ID',
  'CheckinFormId' = 'CHECKIN_FORM_ID',
  'CheckinFormDefinitionId' = 'CHECKIN_FORM_DEFINITION_ID',
  'LeadId' = 'LEAD_ID',
  'LeadDefinitionId' = 'LEAD_DEFINITION_ID',
  'QuestionnaireId' = 'QUESTIONNAIRE_ID',
  'QuestionnaireDefinitionId' = 'QUESTIONNAIRE_DEFINITION_ID',
  'BookingId' = 'BOOKING_ID',
  'BookingDefinitionId' = 'BOOKING_DEFINITION_ID',
  'AllowedSlotId' = 'ALLOWED_SLOT_ID',
  'BrandId' = 'BRAND_ID',
  'ElementDefinitionId' = 'ELEMENT_DEFINITION_ID',
  'InvoiceId' = 'INVOICE_ID',
  'FlagId' = 'FLAG_ID',
  'FlagDefinitionId' = 'FLAG_DEFINITION_ID',
  'PaymentScheduleDefinitionId' = 'PAYMENT_SCHEDULE_DEFINITION_ID',
  'ContractId' = 'CONTRACT_ID',
  'PaymentId' = 'PAYMENT_ID',
  'TipId' = 'TIP_ID',
  'ReminderDefinitionId' = 'REMINDER_DEFINITION_ID',
  'ReminderConfDefinitionId' = 'REMINDER_CONF_DEFINITION_ID',
  'ReminderId' = 'REMINDER_ID',
  'ElementId' = 'ELEMENT_ID',
  'AppId' = 'APP_ID',
  'HolidayId' = 'HOLIDAY_ID',
  'WorkingHoursId' = 'WORKING_HOURS_ID',
  'ClassificationId' = 'CLASSIFICATION_ID',
  'DashboardDefinitionId' = 'DASHBOARD_DEFINITION_ID',
  'DatalistId' = 'DATALIST_ID',
  'ElementInstanceId' = 'ELEMENT_INSTANCE_ID',
  'EmailMessageTemplateId' = 'EMAIL_MESSAGE_TEMPLATE_ID',
  'EmailHtmlTemplateId' = 'EMAIL_HTML_TEMPLATE_ID',
  'EntityInstanceId' = 'ENTITY_INSTANCE_ID',
  'ServiceGroupId' = 'SERVICE_GROUP_ID',
  'ServiceId' = 'SERVICE_ID',
  'EnrichedEntityInstanceId' = 'ENRICHED_ENTITY_INSTANCE_ID',
  'CustomerId' = 'CUSTOMER_ID',
  'StudentId' = 'STUDENT_ID',
  'SlotDefinitionId' = 'SLOT_DEFINITION_ID',
  'ViewEntityInstance' = 'VIEW_ENTITY_INSTANCE',
  'ViewEntityInstanceDefinition' = 'VIEW_ENTITY_INSTANCE_DEFINITION',
  'RoleIds' = 'ROLE_IDS',
  'ViewCustomer' = 'VIEW_CUSTOMER',
  'SubscriptionDefinitionId' = 'SUBSCRIPTION_DEFINITION_ID',
  'SubscriptionId' = 'SUBSCRIPTION_ID',
  'BookableWindowInstanceId' = 'BOOKABLE_WINDOW_INSTANCE_ID',
  'EnvId' = 'ENV_ID',
  'ImportDefinitionId' = 'IMPORT_DEFINITION_ID',
  'InvoiceDefinitionId' = 'INVOICE_DEFINITION_ID',
  'ImportRequestId' = 'IMPORT_REQUEST_ID',
  'JobId' = 'JOB_ID',
  'LookAndFeelId' = 'LOOK_AND_FEEL_ID',
  'WaitingListId' = 'WAITING_LIST_ID',
  'MessageId' = 'MESSAGE_ID',
  'OrgId' = 'ORG_ID',
  'PermissionId' = 'PERMISSION_ID',
  'PublishRequestId' = 'PUBLISH_REQUEST_ID',
  'QueryDefinitionId' = 'QUERY_DEFINITION_ID',
  'ViewDefinitionId' = 'VIEW_DEFINITION_ID',
  'MenuDefinitionId' = 'MENU_DEFINITION_ID',
  'CustomerAggregateViewDefinitionId' = 'CUSTOMER_AGGREGATE_VIEW_DEFINITION_ID',
  'CachedTableViewDefinitionId' = 'CACHED_TABLE_VIEW_DEFINITION_ID',
  'CachedLineViewDefinitionId' = 'CACHED_LINE_VIEW_DEFINITION_ID',
  'UserId' = 'USER_ID',
  'RegistrationAttemptId' = 'REGISTRATION_ATTEMPT_ID',
  'BookableWindowId' = 'BOOKABLE_WINDOW_ID',
  'ScheduleDefinitionId' = 'SCHEDULE_DEFINITION_ID',
  'WorkflowInstanceId' = 'WORKFLOW_INSTANCE_ID',
  'ShopOrderId' = 'SHOP_ORDER_ID',
  'WorkflowDefinitionId' = 'WORKFLOW_DEFINITION_ID',
  'WorkflowDefinitionConfigId' = 'WORKFLOW_DEFINITION_CONFIG_ID',
  'WorkflowStateId' = 'WORKFLOW_STATE_ID',
  'TaskId' = 'TASK_ID',
  'TokenId' = 'TOKEN_ID',
  'SessionId' = 'SESSION_ID',
  'RuleSetDefinitionId' = 'RULE_SET_DEFINITION_ID',
  'SecuritySettingsId' = 'SECURITY_SETTINGS_ID',
  'TaskDefinitionId' = 'TASK_DEFINITION_ID',
  'TestDefinitionId' = 'TEST_DEFINITION_ID',
  'TestExecutionId' = 'TEST_EXECUTION_ID',
  'RuleDefinitionId' = 'RULE_DEFINITION_ID',
  'ActionDefinitionId' = 'ACTION_DEFINITION_ID',
  'ActionTypeDefinitionId' = 'ACTION_TYPE_DEFINITION_ID',
  'ActionConfigurationDefinitionId' = 'ACTION_CONFIGURATION_DEFINITION_ID',
  'ActionConfigurationInstanceId' = 'ACTION_CONFIGURATION_INSTANCE_ID',
  'SlotStartTime' = 'SLOT_START_TIME',
  'SlotEndTime' = 'SLOT_END_TIME',
  'BankHolidayId' = 'BANK_HOLIDAY_ID',
  'Links' = 'LINKS',
  'CalendarViewDefinition' = 'CALENDAR_VIEW_DEFINITION',
  'CalendarViewEntryDefinition' = 'CALENDAR_VIEW_ENTRY_DEFINITION',
  'SlideOutDefinition' = 'SLIDE_OUT_DEFINITION',
  'EntityTasksPanelDefinition' = 'ENTITY_TASKS_PANEL_DEFINITION',
  'ActivityPanelDefinition' = 'ACTIVITY_PANEL_DEFINITION',
  'WizardDefinition' = 'WIZARD_DEFINITION',
  'MapPanelDefinition' = 'MAP_PANEL_DEFINITION',
  'WeatherPanelDefinition' = 'WEATHER_PANEL_DEFINITION',
  'LoginPanel' = 'LOGIN_PANEL',
  'SingleLabelValuePanel' = 'SINGLE_LABEL_VALUE_PANEL',
  'LabelValuePanel' = 'LABEL_VALUE_PANEL',
  'LabelValueLeftRightPanel' = 'LABEL_VALUE_LEFT_RIGHT_PANEL',
  'SingleLabelValuePanelDefinition' = 'SINGLE_LABEL_VALUE_PANEL_DEFINITION',
  'LabelValuePanelDefinition' = 'LABEL_VALUE_PANEL_DEFINITION',
  'LabelValuePair' = 'LABEL_VALUE_PAIR',
  'LabelValuePairDefinition' = 'LABEL_VALUE_PAIR_DEFINITION',
  'LabelValueRowDefinition' = 'LABEL_VALUE_ROW_DEFINITION',
  'ViewEntityPanelDefinition' = 'VIEW_ENTITY_PANEL_DEFINITION',
  'LabelValueLeftRightPanelDefinition' = 'LABEL_VALUE_LEFT_RIGHT_PANEL_DEFINITION',
  'ViewEntityWithImagePanelDefinition' = 'VIEW_ENTITY_WITH_IMAGE_PANEL_DEFINITION',
  'ViewEntityWithImagePanelData' = 'VIEW_ENTITY_WITH_IMAGE_PANEL_DATA',
  'ViewAppointmentPanelDefinition' = 'VIEW_APPOINTMENT_PANEL_DEFINITION',
  'ViewAppointmentPanelData' = 'VIEW_APPOINTMENT_PANEL_DATA',
  'ViewEntityPanelData' = 'VIEW_ENTITY_PANEL_DATA',
  'ViewEntityCardDefinition' = 'VIEW_ENTITY_CARD_DEFINITION',
  'TitleDescriptionDefinition' = 'TITLE_DESCRIPTION_DEFINITION',
  'WorkflowPanel' = 'WORKFLOW_PANEL',
  'WorkflowPanelDefinition' = 'WORKFLOW_PANEL_DEFINITION',
  'SelectionList' = 'SELECTION_LIST',
  'SelectionListDefinition' = 'SELECTION_LIST_DEFINITION',
  'ActionDefinition' = 'ACTION_DEFINITION',
  'IconDefinition' = 'ICON_DEFINITION',
  'TaskDefinition' = 'TASK_DEFINITION',
  'CardContentDefinition' = 'CARD_CONTENT_DEFINITION',
  'CardHeaderDefinition' = 'CARD_HEADER_DEFINITION',
  'TaskSettings' = 'TASK_SETTINGS',
  'TimeFormat' = 'TIME_FORMAT',
  'SplitPanel' = 'SPLIT_PANEL',
  'SplitLogin' = 'SPLIT_LOGIN',
  'SocialWidget' = 'SOCIAL_WIDGET',
  'SocialWidgetDefinition' = 'SOCIAL_WIDGET_DEFINITION',
  'Panel' = 'PANEL',
  'VerticalPanel' = 'VERTICAL_PANEL',
  'CreateAppointmentViewDefinition' = 'CREATE_APPOINTMENT_VIEW_DEFINITION',
  'CreateAppointmentViewData' = 'CREATE_APPOINTMENT_VIEW_DATA',
  'HorizontalPanel' = 'HORIZONTAL_PANEL',
  'ZeroInstances' = 'ZERO_INSTANCES',
  'CardDefinition' = 'CARD_DEFINITION',
  'CopyButtonDefinition' = 'COPY_BUTTON_DEFINITION',
  'ConfirmAndCreateButtonDefinition' = 'CONFIRM_AND_CREATE_BUTTON_DEFINITION',
  'MoveButtonDefinition' = 'MOVE_BUTTON_DEFINITION',
  'CustomerPopupButtonDefinition' = 'CUSTOMER_POPUP_BUTTON_DEFINITION',
  'EiPopupButtonDefinition' = 'EI_POPUP_BUTTON_DEFINITION',
  'ImportFieldDefinition' = 'IMPORT_FIELD_DEFINITION',
  'ImagePanelDefinition' = 'IMAGE_PANEL_DEFINITION',
  'ImagePanel' = 'IMAGE_PANEL',
  'ViewElement' = 'VIEW_ELEMENT',
  'ListEntityTableDefinition' = 'LIST_ENTITY_TABLE_DEFINITION',
  'ListEntityTable' = 'LIST_ENTITY_TABLE',
  'ListEntityTeaserDefinition' = 'LIST_ENTITY_TEASER_DEFINITION',
  'ListEntityTeaser' = 'LIST_ENTITY_TEASER',
  'ListEntityImagesDefinition' = 'LIST_ENTITY_IMAGES_DEFINITION',
  'ListEntityImages' = 'LIST_ENTITY_IMAGES',
  'BadgetDefinition' = 'BADGET_DEFINITION',
  'Badge' = 'BADGE',
  'BarChartDefinition' = 'BAR_CHART_DEFINITION',
  'InvoiceChartDefinition' = 'INVOICE_CHART_DEFINITION',
  'DonutChartDefinition' = 'DONUT_CHART_DEFINITION',
  'CustomerSourceDefinition' = 'CUSTOMER_SOURCE_DEFINITION',
  'BarChart' = 'BAR_CHART',
  'EntitySummaryDefinition' = 'ENTITY_SUMMARY_DEFINITION',
  'OrderSummaryDefinition' = 'ORDER_SUMMARY_DEFINITION',
  'ContactFormSummaryDefinition' = 'CONTACT_FORM_SUMMARY_DEFINITION',
  'PaymentsSummaryDefinition' = 'PAYMENTS_SUMMARY_DEFINITION',
  'QuotesSummaryDefinition' = 'QUOTES_SUMMARY_DEFINITION',
  'ContactFormSummary' = 'CONTACT_FORM_SUMMARY',
  'PaymentsSummary' = 'PAYMENTS_SUMMARY',
  'QuotesSummary' = 'QUOTES_SUMMARY',
  'NewsDefinition' = 'NEWS_DEFINITION',
  'News' = 'NEWS',
  'ServiceStartTimeViewFragmentDef' = 'SERVICE_START_TIME_VIEW_FRAGMENT_DEF',
  'StartTimeViewFragmentDef' = 'START_TIME_VIEW_FRAGMENT_DEF',
  'StartTimeViewFragmentData' = 'START_TIME_VIEW_FRAGMENT_DATA',
  'ListContractsDefinition' = 'LIST_CONTRACTS_DEFINITION',
  'FlagPanelDefinition' = 'FLAG_PANEL_DEFINITION',
  'ViewFlagPanelDefinition' = 'VIEW_FLAG_PANEL_DEFINITION',
  'FlagPanelData' = 'FLAG_PANEL_DATA',
  'ListOrdersDefinition' = 'LIST_ORDERS_DEFINITION',
  'ViewCustomerPanelDefinition' = 'VIEW_CUSTOMER_PANEL_DEFINITION',
  'ViewCustomerWorkflowStats' = 'VIEW_CUSTOMER_WORKFLOW_STATS',
  'ViewCustomerHistoryButton' = 'VIEW_CUSTOMER_HISTORY_BUTTON',
  'LinkedCustomerSelector' = 'LINKED_CUSTOMER_SELECTOR',
  'WorkflowStatsData' = 'WORKFLOW_STATS_DATA',
  'ViewCustomerPageDefinition' = 'VIEW_CUSTOMER_PAGE_DEFINITION',
  'ListInvoiceDefinition' = 'LIST_INVOICE_DEFINITION',
  'ListItineraryDefinition' = 'LIST_ITINERARY_DEFINITION',
  'ListCheckInFormsDefinition' = 'LIST_CHECK_IN_FORMS_DEFINITION',
  'ListItinerariesList' = 'LIST_ITINERARIES_LIST',
  'ListInvoiceList' = 'LIST_INVOICE_LIST',
  'ListContractList' = 'LIST_CONTRACT_LIST',
  'ListCheckInFormsData' = 'LIST_CHECK_IN_FORMS_DATA',
  'ListOrdersList' = 'LIST_ORDERS_LIST',
  'ListQuestionnairesList' = 'LIST_QUESTIONNAIRES_LIST',
  'ListEntitysListDefinition' = 'LIST_ENTITYS_LIST_DEFINITION',
  'ListCachedEntitysListDefinition' = 'LIST_CACHED_ENTITYS_LIST_DEFINITION',
  'StepPanelDefinition' = 'STEP_PANEL_DEFINITION',
  'StepDefinition' = 'STEP_DEFINITION',
  'ViewCachedEntitysListDefinition' = 'VIEW_CACHED_ENTITYS_LIST_DEFINITION',
  'ViewCachedEntitysExpandableDefinition' = 'VIEW_CACHED_ENTITYS_EXPANDABLE_DEFINITION',
  'ViewInvoiceEntitysExpandableDefinition' = 'VIEW_INVOICE_ENTITYS_EXPANDABLE_DEFINITION',
  'ViewInvoiceEntitysCachedTableDefinition' = 'VIEW_INVOICE_ENTITYS_CACHED_TABLE_DEFINITION',
  'ViewInvoiceEntityNamesDefinition' = 'VIEW_INVOICE_ENTITY_NAMES_DEFINITION',
  'ViewInvoiceEntityNamesData' = 'VIEW_INVOICE_ENTITY_NAMES_DATA',
  'ViewInvoiceEntitysCachedTableData' = 'VIEW_INVOICE_ENTITYS_CACHED_TABLE_DATA',
  'ViewInvoiceEntitysExpandableData' = 'VIEW_INVOICE_ENTITYS_EXPANDABLE_DATA',
  'ViewAppointmentDefinition' = 'VIEW_APPOINTMENT_DEFINITION',
  'ListCachedEntitysTableDefinition' = 'LIST_CACHED_ENTITYS_TABLE_DEFINITION',
  'ListCachedEntitysTableData' = 'LIST_CACHED_ENTITYS_TABLE_DATA',
  'ListQuestionnairesDefinition' = 'LIST_QUESTIONNAIRES_DEFINITION',
  'ListEntitysList' = 'LIST_ENTITYS_LIST',
  'TeaserDefinition' = 'TEASER_DEFINITION',
  'Teasers' = 'TEASERS',
  'ExpandablesDefinition' = 'EXPANDABLES_DEFINITION',
  'Expandables' = 'EXPANDABLES',
  'TableDefinition' = 'TABLE_DEFINITION',
  'Table' = 'TABLE',
  'ListMessagesDefinition' = 'LIST_MESSAGES_DEFINITION',
  'ViewInvoicePanel' = 'VIEW_INVOICE_PANEL',
  'ViewInvoicePanelData' = 'VIEW_INVOICE_PANEL_DATA',
  'InvoiceServicePanel' = 'INVOICE_SERVICE_PANEL',
  'ConflictCheckButton' = 'CONFLICT_CHECK_BUTTON',
  'SimpleMessagesPanelDefinition' = 'SIMPLE_MESSAGES_PANEL_DEFINITION',
  'ViewRemindersDefinition' = 'VIEW_REMINDERS_DEFINITION',
  'ViewCustomerRemindersDefinition' = 'VIEW_CUSTOMER_REMINDERS_DEFINITION',
  'SendSmsDefinition' = 'SEND_SMS_DEFINITION',
  'ViewReminders' = 'VIEW_REMINDERS',
  'AvatarRowDefinition' = 'AVATAR_ROW_DEFINITION',
  'AvatarRow' = 'AVATAR_ROW',
  'SectionHeader' = 'SECTION_HEADER',
  'TabPanel' = 'TAB_PANEL',
  'Tab' = 'TAB',
  'ElementSetDefinition' = 'ELEMENT_SET_DEFINITION',
  'MultiValue' = 'MULTI_VALUE',
  'ElementInstance' = 'ELEMENT_INSTANCE',
  'ViewCustomerEmbedded' = 'VIEW_CUSTOMER_EMBEDDED',
  'ViewCustomerEmbeddedData' = 'VIEW_CUSTOMER_EMBEDDED_DATA',
  'ViewAssociatedCachedTableViewEmbedded' = 'VIEW_ASSOCIATED_CACHED_TABLE_VIEW_EMBEDDED',
  'ViewAssociatedCachedTableViewEmbeddedData' = 'VIEW_ASSOCIATED_CACHED_TABLE_VIEW_EMBEDDED_DATA',
  'ViewAppointmentEmbeddedData' = 'VIEW_APPOINTMENT_EMBEDDED_DATA',
  'ViewAppointmentData' = 'VIEW_APPOINTMENT_DATA',
  'ViewInvoicesByEiListView' = 'VIEW_INVOICES_BY_EI_LIST_VIEW',
  'ViewInvoicesByEiListViewData' = 'VIEW_INVOICES_BY_EI_LIST_VIEW_DATA',
  'ViewApptsByEiListView' = 'VIEW_APPTS_BY_EI_LIST_VIEW',
  'ViewApptsByEiListViewData' = 'VIEW_APPTS_BY_EI_LIST_VIEW_DATA',
  'ViewCustomerApptsView' = 'VIEW_CUSTOMER_APPTS_VIEW',
  'ViewCustomerApptsViewData' = 'VIEW_CUSTOMER_APPTS_VIEW_DATA',
  'ViewAssociatedCachedListView' = 'VIEW_ASSOCIATED_CACHED_LIST_VIEW',
  'ViewAssociatedCachedListViewData' = 'VIEW_ASSOCIATED_CACHED_LIST_VIEW_DATA',
  'ViewCachedTableViewEmbedded' = 'VIEW_CACHED_TABLE_VIEW_EMBEDDED',
  'ViewCachedTableViewEmbeddedData' = 'VIEW_CACHED_TABLE_VIEW_EMBEDDED_DATA',
  'ViewEntityEmbedded' = 'VIEW_ENTITY_EMBEDDED',
  'ViewEntityEmbeddedData' = 'VIEW_ENTITY_EMBEDDED_DATA',
  'WorkflowActionInstance' = 'WORKFLOW_ACTION_INSTANCE',
  'FieldInstance' = 'FIELD_INSTANCE',
  'ViewImageDefinition' = 'VIEW_IMAGE_DEFINITION',
  'ViewImage' = 'VIEW_IMAGE',
  'ViewVideoDefinition' = 'VIEW_VIDEO_DEFINITION',
  'ViewVideo' = 'VIEW_VIDEO',
  'ViewDocumentDefinition' = 'VIEW_DOCUMENT_DEFINITION',
  'ViewDocument' = 'VIEW_DOCUMENT',
  'MappingDefinition' = 'MAPPING_DEFINITION',
  'FieldReference' = 'FIELD_REFERENCE',
  'ServiceUser' = 'SERVICE_USER',
  'BrandName' = 'BRAND_NAME',
  'ServiceUserName' = 'SERVICE_USER_NAME',
  'ServiceType' = 'SERVICE_TYPE',
  'TimePeriod' = 'TIME_PERIOD',
  'FieldReferenceDefinition' = 'FIELD_REFERENCE_DEFINITION',
  'TimePeriodDefinition' = 'TIME_PERIOD_DEFINITION',
  'UserResolutionStrategy' = 'USER_RESOLUTION_STRATEGY',
  'IntegerValueDefinition' = 'INTEGER_VALUE_DEFINITION',
  'BigDecimalValueDefinition' = 'BIG_DECIMAL_VALUE_DEFINITION',
  'ColourValueDefinition' = 'COLOUR_VALUE_DEFINITION',
  'BooleanValueDefinition' = 'BOOLEAN_VALUE_DEFINITION',
  'ImageFieldDefinition' = 'IMAGE_FIELD_DEFINITION',
  'Section' = 'SECTION',
  'Select' = 'SELECT',
  'Date' = 'DATE',
  'DateTime' = 'DATE_TIME',
  'Time' = 'TIME',
  'Input' = 'INPUT',
  'Files' = 'FILES',
  'FileUpload' = 'FILE_UPLOAD',
  'Image' = 'IMAGE',
  'Video' = 'VIDEO',
  'Document' = 'DOCUMENT',
  'Password' = 'PASSWORD',
  'TextArea' = 'TEXT_AREA',
  'Icons' = 'ICONS',
  'Location' = 'LOCATION',
  'Address' = 'ADDRESS',
  'Contact' = 'CONTACT',
  'Signature' = 'SIGNATURE',
  'CheckBox' = 'CHECK_BOX',
  'Radio' = 'RADIO',
  'NavigateTo' = 'NAVIGATE_TO',
  'Button' = 'BUTTON',
  'Anchor' = 'ANCHOR',
  'Label' = 'LABEL',
  'HtmlFragment' = 'HTML_FRAGMENT',
  'LinkedEntity' = 'LINKED_ENTITY',
  'LinkedCustomerDefinition' = 'LINKED_CUSTOMER_DEFINITION',
  'HeaderAndLines' = 'HEADER_AND_LINES',
  'InvoicePanelData' = 'INVOICE_PANEL_DATA',
  'InvoiceServicesPanelData' = 'INVOICE_SERVICES_PANEL_DATA',
  'ToolBar' = 'TOOL_BAR',
  'ToolBarDefinition' = 'TOOL_BAR_DEFINITION',
  'ToolBarRowDefinition' = 'TOOL_BAR_ROW_DEFINITION',
  'LineDefinition' = 'LINE_DEFINITION',
  'LinesDefinition' = 'LINES_DEFINITION',
  'ViewCustomerHeaderDefinition' = 'VIEW_CUSTOMER_HEADER_DEFINITION',
  'ViewCustomerHeader' = 'VIEW_CUSTOMER_HEADER',
  'Space' = 'SPACE',
  'LineBreak' = 'LINE_BREAK',
  'GridListDefinition' = 'GRID_LIST_DEFINITION',
  'GridTileDefinition' = 'GRID_TILE_DEFINITION',
  'HtmlElement' = 'HTML_ELEMENT',
  'HtmlSelectElement' = 'HTML_SELECT_ELEMENT',
  'ElementValueDefinition' = 'ELEMENT_VALUE_DEFINITION',
  'CountWorkflowStatesDefinition' = 'COUNT_WORKFLOW_STATES_DEFINITION',
  'TotalSpentDefinition' = 'TOTAL_SPENT_DEFINITION',
  'AverageSpentDefinition' = 'AVERAGE_SPENT_DEFINITION',
  'WorkflowStateColourValueDefinition' = 'WORKFLOW_STATE_COLOUR_VALUE_DEFINITION',
  'ServiceColourValueDefinition' = 'SERVICE_COLOUR_VALUE_DEFINITION',
  'ServiceNameValueDefinition' = 'SERVICE_NAME_VALUE_DEFINITION',
  'ServiceCostValueDefinition' = 'SERVICE_COST_VALUE_DEFINITION',
  'ServiceEiValueDefinition' = 'SERVICE_EI_VALUE_DEFINITION',
  'ServiceStartTimeValueDefinition' = 'SERVICE_START_TIME_VALUE_DEFINITION',
  'ServiceStartDateTimeValueDefinition' = 'SERVICE_START_DATE_TIME_VALUE_DEFINITION',
  'ServiceEndTimeValueDefinition' = 'SERVICE_END_TIME_VALUE_DEFINITION',
  'ServiceEndDateTimeValueDefinition' = 'SERVICE_END_DATE_TIME_VALUE_DEFINITION',
  'InvoiceDateDefinition' = 'INVOICE_DATE_DEFINITION',
  'InvoiceDueDateDefinition' = 'INVOICE_DUE_DATE_DEFINITION',
  'ServiceDurationValueDefinition' = 'SERVICE_DURATION_VALUE_DEFINITION',
  'WorkflowStateValueDefinition' = 'WORKFLOW_STATE_VALUE_DEFINITION',
  'ElementSetValue' = 'ELEMENT_SET_VALUE',
  'TagElementValue' = 'TAG_ELEMENT_VALUE',
  'StringValue' = 'STRING_VALUE',
  'WorkflowExecuteActionPanelDefinition' = 'WORKFLOW_EXECUTE_ACTION_PANEL_DEFINITION',
  'WorkflowExecuteActionPanelData' = 'WORKFLOW_EXECUTE_ACTION_PANEL_DATA',
  'WorkflowProgressDefinition' = 'WORKFLOW_PROGRESS_DEFINITION',
  'ProgressBarDto' = 'PROGRESS_BAR_DTO',
  'CustomerFullNameValue' = 'CUSTOMER_FULL_NAME_VALUE',
  'CustomerFirstNameValue' = 'CUSTOMER_FIRST_NAME_VALUE',
  'CustomerLastNameValue' = 'CUSTOMER_LAST_NAME_VALUE',
  'CustomerEmailValue' = 'CUSTOMER_EMAIL_VALUE',
  'CustomerMobileValue' = 'CUSTOMER_MOBILE_VALUE',
  'CustomerPhoneValue' = 'CUSTOMER_PHONE_VALUE',
  'RepeatableEventDefinition' = 'REPEATABLE_EVENT_DEFINITION',
  'CustomerFirstNameField' = 'CUSTOMER_FIRST_NAME_FIELD',
  'CustomerLastNameField' = 'CUSTOMER_LAST_NAME_FIELD',
  'CustomerEmailField' = 'CUSTOMER_EMAIL_FIELD',
  'CustomerMobileField' = 'CUSTOMER_MOBILE_FIELD',
  'CustomerPhoneField' = 'CUSTOMER_PHONE_FIELD',
  'EntitySummaryDto' = 'ENTITY_SUMMARY_DTO',
  'OrdersSummaryDto' = 'ORDERS_SUMMARY_DTO',
  'BooleanValue' = 'BOOLEAN_VALUE',
  'IntegerValue' = 'INTEGER_VALUE',
  'FieldValue' = 'FIELD_VALUE',
  'FieldValueLine' = 'FIELD_VALUE_LINE',
  'WorkflowFieldDefinition' = 'WORKFLOW_FIELD_DEFINITION',
  'InvoiceFieldDefinition' = 'INVOICE_FIELD_DEFINITION'
}

@Injectable({
  providedIn: 'root',
})
export class DataShareService {

  
  workflowDefinitions: Array<WorkflowDefinition> = [];
  calendarDataLoaded = false;

  constructor(
    private workflowdefService: WorkflowdefService,
    private subscriptionService: SubscriptionService,
    public servicesHelperService: ServicesHelperService,
    public productsHelperService: ProductsHelperService,
    public taxesHelperService: TaxesHelperService,
    public orgSetupHelperService: OrgSetupHelperService,
    public locationsHelperService: LocationsHelperService,
    public addOnsHelperService: AddOnsHelperService,
    public appointmentConfigHelperService: AppointmentConfigHelperService
  ) { }

  equals(value, compareWith) {
    return value === compareWith;
  }

  public isTextField(compareValue): boolean {
    return (
      this.equals(ElementType.Input, compareValue) ||
      this.equals(ElementType.TextArea, compareValue) ||
      this.equals(ElementType.Password, compareValue)
    );
  }

  public isDateField(compareValue): boolean {
    return this.equals(ElementType.Date, compareValue);
  }

  public isButton(compareValue): boolean {
    return this.equals(ElementType.Button, compareValue);
  }

  public getValueType(compareValue) {
    switch (compareValue) {
      case ElementType.Select:
      case ElementType.Input:
      case ElementType.Password:
      case ElementType.TextArea:
      case ElementType.Location:
        return ValueType.String;
      case ElementType.Files:
        return ValueType.Files;
      case ElementType.CheckBox:
      case ElementType.Radio:
        return ValueType.Boolean;
      case ElementType.Date:
        return ValueType.Date;
      case ElementType.DateTime:
        return ValueType.DateTime;
      case ElementType.Time:
        return ValueType.Time;
      case ElementType.LinkedEntity:
        return ValueType.StringArray;
      case ElementType.LinkedCustomerDefinition:
        return ValueType.String;
      case ElementType.WorkflowFieldDefinition:
        return ValueType.Workflow;
      case ElementType.InvoiceFieldDefinition:
        return ValueType.Invoice;
      case ElementType.BooleanValueDefinition:
        return ValueType.Boolean;
      case ElementType.ImageFieldDefinition:
        return ValueType.String;
      case ElementType.RepeatableEventDefinition:
        return ValueType.RepeatableEvent;
      case ElementType.ColourValueDefinition:
        return ValueType.String;
      case ElementType.IntegerValueDefinition:
        return ValueType.Integer;
      case ElementType.BigDecimalValueDefinition:
        return ValueType.BigDecimal;
      default:
        break;
      // throw new IllegalArgumentException("Unsupported type " + this);
    }
  }

  public loadWorkflowDefinitions(contextIdDto: ContextIdDto) {
    return new Promise(resolve => {
      this.workflowdefService.loadByTypeV2(contextIdDto.contextId, 'ENV_ID', 'APPOINTMENT').subscribe(res => {
        if (res && res.workflowDefinitions.length) {
          this.workflowDefinitions = res.workflowDefinitions;
          console.log('loaded workflow defs');
        }
        resolve(this.workflowDefinitions);
      });
    });
  }

  public loadCurrentSubscription(contextIdDto: ContextIdDto) {
    return new Promise(resolve => {
      console.log('loading current subscription');
      this.subscriptionService.getCurrentSubscription(
        contextIdDto.contextIdType,
        contextIdDto.contextId
      ).subscribe(subscription => {
        console.log('got subscription', subscription);
        if (subscription) {
          this.orgSetupHelperService.currentSubUserId = subscription.userId;
        }
        resolve(true);
      });
    });
  }

  public loadDataForQuickAppointment(contextIdDto: ContextIdDto) {
    return new Promise(async (resolve) => {
      this.loadWorkflowDefinitions(contextIdDto);
      this.locationsHelperService.loadActiveLocations(contextIdDto);
      console.log('complete loadDataForQuickAppointment');
      this.calendarDataLoaded = true;
      resolve(true);
    });
  }
}
