<div *ngIf="showSpinner" class="bg-spinner-wrapper">
  <mat-spinner style="margin: 0 auto;"></mat-spinner>
</div>
<mat-toolbar color="primary" *ngIf="visible && questionnaireDefs && questionnaireDefs.length > 0">
  <mat-toolbar-row>{{'SEND_QUESTIONNAIRE' | translate}}</mat-toolbar-row>
</mat-toolbar>

<mat-card appearance="outlined" *ngIf="visible && questionnaireDefs && questionnaireDefs.length > 0">

  <mat-card-content>
    <form #msgForm="ngForm">


      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>{{'TO' | translate}}</mat-label>
        <input type="text" matInput name="recipient" #recipient="ngModel" [(ngModel)]="recipients" placeholder="{{'TO' | translate}}">
        <mat-error *ngIf="recipient.hasError('required')">
          {{'TO_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
        </mat-error>
      </mat-form-field>
      <br>
      <mat-form-field appearance="outline">
        <mat-label>{{'QUESTIONNAIRE_TO_SEND' | translate}}</mat-label>
        <mat-select placeholder="{{'SELECT_QUESTIONNAIRE' | translate}}" name="questionnaireTemplate" #questionnaireTemplate="ngModel" [(ngModel)]="questionnaireDefinitionId" required>
          <mat-option *ngFor="let questionnaireDef of questionnaireDefs" [value]="questionnaireDef.questionnaireDefinitionId">
            {{questionnaireDef.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="questionnaireTemplate.hasError('required')">
          {{'QUESTIONNAIRE_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
        </mat-error>
      </mat-form-field>
      <br>

      <mat-form-field appearance="outline">
        <mat-label>{{'SUBJECT' | translate}}</mat-label>
        <input matInput placeholder="{{'SUBJECT' | translate}}" name="subjectField" #subjectField="ngModel" [(ngModel)]="subject" id="subjectField" required>

        <mat-error *ngIf="subjectField.hasError('required')">
          {{'SUBJECT_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
        </mat-error>

      </mat-form-field>

      <br>

      <app-flo-editor [text]="body" [required]="true" (textUpdated)="bodyUpdated($event)"></app-flo-editor>
      <br><br />
      <!--

      <mat-form-field appearance="outline">
          <mat-label>Message</mat-label>
          <textarea matInput
                    id="body"
                    name="body"
                    [(ngModel)]="body"
                    placeholder="Enter Message">
          </textarea>
      </mat-form-field>-->
    </form>

  </mat-card-content>
  <mat-card-content>
    <button mat-raised-button color="accent" (click)="send()" [disabled]="!msgForm.valid || !allowedToSend">{{'SEND' | translate}}</button>
    &nbsp;
    <button mat-raised-button (click)="cancelIt()">{{'CANCEL' | translate}}</button>

    <div *ngIf="!allowedToSend">
      <mat-error>{{'CANT_SEND_QUESTIONNAIRE' | translate}}.</mat-error>
    </div>
  </mat-card-content>


</mat-card>


<mat-card appearance="outlined" *ngIf="visible && questionnaireDefs && questionnaireDefs.length === 0" class="full-height">

  <mat-card-content>
    <div class="flexbox-parent">
      <div fxLayout="row" fxLayoutAlign="center center">
        <div style="text-align:center;">
          <app-zero-image name="email"></app-zero-image><br>
          <h5 class="ma-0">{{'NO_QUESTIONNAIRE_TEMPLATES' | translate}}</h5><br>
          <button mat-raised-button color="accent" (click)="gotoTemplates()">{{'GOTO_QUESTIONNAIRE_TEMPLATES' | translate}}</button>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>