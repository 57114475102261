import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ChangeListener } from './changeListener';
import { EventBusService } from './EventBusService';
import { EventType } from '../event/UiEvent';
import { ContextIdDto, ListQuestionnairesListDto } from '@savvy/view-definition';

@Component({
    selector: 'app-list-questionnaires-list',
    templateUrl: 'listQuestionnairesList.component.html'
})
export class ListQuestionnairesListComponent {

    @Input() listQuestionnairesListDto: ListQuestionnairesListDto;
    @Input() contextIdDto: ContextIdDto;
    @Input() viewContext: any;
    @Input() form: UntypedFormGroup;
    @Input() eventBus: EventBusService;
    @Input() showPlaceholder: boolean;
    @Input() changeListener: ChangeListener;

    constructor(private router: Router) {
    }
    create() {
        console.log('inside create in ListQuestionnairesListComponent');
        this.eventBus.addEvent(EventType.SEND_QUESTIONNAIRE);
    }

    rowSelected(questionnaireId: string) {
        console.log('inside gotoEntity questionnaireId ' + questionnaireId);
        this.router.navigate(['/questionnaires/viewQuestionnaire',
            this.contextIdDto.contextId,
            this.contextIdDto.contextIdType,
            questionnaireId
        ]);
    }
}
