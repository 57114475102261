import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {GLOBAL} from 'src/app/app.constants';
import {LocalMenuService} from '../menu/local-menu.service';

@Component({
  selector: 'app-zero-image',
  templateUrl: 'zeroImage.component.html'
})
export class ZeroImageComponent implements OnInit, OnChanges {
  @Input() name: string;
  @Input() localIcon;
  svgIcon: string;
  defaultIcon = GLOBAL.defaultIcon;
  constructor(
    // private iconService: IconService,
    private menuService: LocalMenuService,
  ) {
  }

  ngOnInit(): void {
    this.menuService.adminMenuItemsChanged$.subscribe((newMenu) => {
      if (!this.localIcon) {
        this.setPath();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('change detected', changes);
    if (changes && changes.localIcon) {
      this.localIcon = changes.localIcon.currentValue;
    } else if (changes.name && !this.localIcon) {
      console.log('setting path');
      // this.name = changes.name.currentValue;
      this.setPath();
    } else {
      console.log('not doing anything');
    }
  }

  setPath() {
    console.log('looking for icon matching:', this.name);
    // this.path = this.iconService.getIcon(this.name);
    const menuHasMatchingIcon = this.menuService.menuItems.find(menuItem => menuItem.name && this.name
      && (menuItem.name === this.name
        || String(menuItem.name).toLowerCase().includes(this.name.toLowerCase())));
    const adminMenuHasMatchingIcon = this.menuService.adminMenuItems.find(menuItem => menuItem.label && this.name
      && (menuItem.label === this.name
        || String(menuItem.label).toLowerCase().includes(this.name.toLowerCase())));


    if (this.name && menuHasMatchingIcon) {
      this.svgIcon = menuHasMatchingIcon.svgXml;
    } else if (this.name && adminMenuHasMatchingIcon) {
      this.svgIcon = adminMenuHasMatchingIcon.svgXml;
    } else {
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let index = 0; index < this.menuService.adminMenuItems.length; index++) {
        const adminMenuItem = this.menuService.adminMenuItems[index];
        if (adminMenuItem.menuDefinitions && adminMenuItem.menuDefinitions.length) {
          const adminMenuHasMatchingIcon2 = adminMenuItem.menuDefinitions.find(menuItem => menuItem.label
            && this.name && (menuItem.label === this.name
              || String(menuItem.label).toLowerCase().includes(this.name.toLowerCase())));
          if (adminMenuHasMatchingIcon2) {
            this.svgIcon = adminMenuHasMatchingIcon2.svgXml;
            return;
          }
        }
      }
      this.svgIcon = GLOBAL.selectedMenuIconSvgXml;
    }
    // console.log('got path ' + this.path + ' for name ' + this.name);
  }
}
