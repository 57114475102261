import { Component, Inject, Input, OnChanges, OnDestroy, OnInit, Optional, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ElementControlService } from './ElementControlService';
import { EicompService } from '@savvy/entity-instance-composite';
import { ChangeListener } from './changeListener';
import { EventBusService } from './EventBusService';
import { NavChangeService } from './NavChangeService';
import { EventType, UiEvent } from '../event/UiEvent';
import { NavigateService } from '../entry/NavigateService';
import { ConfirmationModalComponent } from '../shared/components/confirmation-modal/confirmation-modal.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { PhoneNumberHelperService } from '../shared/services/phone-number-helper.service';
import { ClearObservable } from '../shared/classes/clear-observable';
import { ContextIdDto } from '@savvy/quickbooks';
import { LoadEiViewResponse, ViewcompService } from '@savvy/view-composite';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-view-entity',
  templateUrl: 'viewEntity.component.html',
  providers: [ElementControlService]
})
export class ViewEntityComponent extends ClearObservable implements OnInit, OnChanges, OnDestroy {

  @Input() entityInstanceId: string;
  @Input() contextIdDto: ContextIdDto;
  @Input() viewDefinitionId: string;
  @Input() eventBus: EventBusService;

  loadEiViewResponse: LoadEiViewResponse;

  changeListener = new ChangeListener();

  public form: UntypedFormGroup; // our form model

  subs: Subscription[] = [];
  active: boolean;
  isViewMode = false;
  widthIsDevice = 70;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    @Optional() private dialogRef: MatDialogRef<ViewEntityComponent>,
    private ecs: ElementControlService,
    private navigateService: NavigateService,
    private location: Location,
    private dialog: MatDialog,
    private eiCompositeApi: EicompService,
    private viewCompositeApi: ViewcompService,
    private navChange: NavChangeService,
    private sharedService: PhoneNumberHelperService) {
    super();
  }

  ngOnInit(): void {
    if (this.data && this.data.entityInstanceId && this.data.contextIdDto && this.data.eventBus && this.data.isViewMode) {
      this.entityInstanceId = this.data.entityInstanceId;
      this.contextIdDto = this.data.contextIdDto;
      this.eventBus = this.data.eventBus;
      this.isViewMode = this.data.isViewMode;
      this.widthIsDevice = 100;
      this.loadEntity();
      this.startSubscriptionForModal();
    } else {
      if (this.eventBus) {
        this.subs.push(this.eventBus.eventAdded$.subscribe(event => this.onEvent(event)));
      }
    }
  }

  ngOnDestroy(): void {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }

  startSubscriptionForModal() {
    this.subs.push(this.sharedService.viewEntityModalSub
      .subscribe(res => {
        if (res) {
          this.dialogRef.close();
        }
      }));
  }

  loadEntity() {
    if (this.viewDefinitionId) {
      this.viewCompositeApi.loadEiByViewDefId(this.entityInstanceId,
        this.viewDefinitionId,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType)
        .subscribe(response => {
          this.display(response);
        }, err => {
          console.log('error while loadEntity', err);
        });
    } else {
      this.viewCompositeApi.loadEiToView(this.entityInstanceId,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType)
        .subscribe(
          response => {
            this.display(response);
          }, err => {
            console.log('error while loadEntity', err);
          });
    }
  }

  setEntityInstanceId(entityInstanceId: string) {
    this.entityInstanceId = entityInstanceId;
    this.loadEntity();
  }

  /**
   * So this will get calOled when the teasers are changed (for example)
   * but it will also get called when the label
   *
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...
    // console.log('change detected inside view entity');
    for (const propName in changes) {
      if (propName === 'entityInstanceId') {
        // if (cur !== prev) {
        // console.log('calling load entity due to change detection');
        this.loadEntity();
        // }
      }
    }
  }

  display(loadEiViewResponse: LoadEiViewResponse) {
    this.active = false;
    this.loadEiViewResponse = loadEiViewResponse;
    this.createForm();
    this.active = true;
    this.navChange.add('viewEntity:' +
      loadEiViewResponse.viewContextDto.addEditEntityInstanceContextDto.entityDefinitionDto.entityDefinitionId.id);
  }

  createForm() {
    if (this.loadEiViewResponse.viewRootElementDto) {
      // @ts-ignore
      this.form = this.ecs.toFormGroup(this.loadEiViewResponse.viewRootElementDto,
        // @ts-ignore
        this.loadEiViewResponse.viewContextDto.addEditEntityInstanceContextDto.entityDefinitionDto);
    } else {
      this.form = this.ecs.emptyFormGroup();
    }
  }

  cancelIt() {
    this.eventBus.addEvent(EventType.CANCEL_VIEW_ENTITY);
  }

  onEvent(event: UiEvent): void {
    console.log('got event ' + event);
    if (event.eventType === EventType.TASK_CREATED ||
      event.eventType === EventType.TASK_UPDATED ||
      event.eventType === EventType.TASK_MARKED_AS_DONE ||
      event.eventType === EventType.TASK_EXECUTED) {
      this.loadEntity();
    } else if (event.eventType === EventType.DELETE_ENTITY_INSTANCE) {
      console.log('got event and DELETING NOW');
      console.log('deleting 123');
      this.eiCompositeApi.deleteEntityInstance(
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        event.entityInstanceId.id,
        false)
        .subscribe(
          response => {
            this.navigateService.listEntities(this.contextIdDto, this.loadEiViewResponse.viewContextDto.entityDefinitionId);
          }
        );
    }
  }

  backToList() {
    this.location.back();
  }

  editEntity() {
    this.eventBus.addEntityEvent(EventType.EDIT_ENTITY, this.loadEiViewResponse.viewContextDto.entityInstanceId);
  }

  removeEntity() {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        name: this.loadEiViewResponse.viewContextDto.addEditEntityInstanceContextDto.entityDefinitionDto.name
      }
    });

    dialogRef.afterClosed()
      .subscribe((result) => {
        if (result) {
          this.eventBus.addEntityEvent(EventType.DELETE_ENTITY_INSTANCE, this.loadEiViewResponse.viewContextDto.entityInstanceId);
        }
      });
  }

}

