<!-- <mat-toolbar color="primary" *ngIf="flagDefinition">
  <mat-toolbar-row *ngIf="!flagDefinition.id">Add Flag</mat-toolbar-row>
  <mat-toolbar-row *ngIf="flagDefinition.id">Edit Flag</mat-toolbar-row>
</mat-toolbar> -->
<form #form="ngForm" *ngIf="flagDefinition">
  <mat-form-field appearance="outline" style="width: 100%">
    <mat-label>{{'FLAG_NAME' | translate}}</mat-label>
    <input matInput [(ngModel)]="flagDefinition.name" name="name" [required]="true" #name="ngModel" id="name"
      placeholder="{{'ENTER_NAME' | translate}}" style="width: 100%">
  </mat-form-field>

  <mat-form-field appearance="outline" style="width: 100%">
    <mat-label>{{'DESCRIPTION' | translate}}</mat-label>
    <input matInput [(ngModel)]="flagDefinition.description" name="description" [required]="true" #description="ngModel"
      id="description" placeholder="{{'ENTER_DESCRIPTION' | translate}}" style="width: 100%">
  </mat-form-field>


  <mat-form-field appearance="outline" style="width: 100%">
    <mat-label>{{'COLOUR' | translate}}</mat-label>
    <mat-select [(ngModel)]="flagDefinition.colour" name="colour" [required]="true" #colour="ngModel" id="colour"
      placeholder="{{'ENTER_NAME' | translate}}" style="width: 100%">

      <mat-option value="primary">{{'PRIMARY' | translate}}</mat-option>
      <mat-option value="accent">{{'ACCENT' | translate}}</mat-option>
      <mat-option value="warn">{{'WARNING' | translate}}</mat-option>

    </mat-select>
  </mat-form-field>

  <button mat-raised-button color="primary" *ngIf="!flagDefinition.id" (click)="create()"
    [disabled]="!form.valid">{{'ADD' | translate}}</button>
  <button mat-raised-button color="primary" *ngIf="flagDefinition.id" (click)="updateAndEmit()"
    [disabled]="!form.valid">
    {{'UPDATE' | translate}}
  </button>
  <button class="ml-1" mat-raised-button (click)="backToList()">{{'CANCEL' | translate}}</button>
</form>
