<div fxLayout="row wrap" fxLayoutGap="16px" fxLayoutAlign="center center" class="mdc-py-2">
  <div fxFlex="nogrow">
    <div class="avatar avatar-50 border-3 border-theme mat-text-accent circle">
      <mat-icon>celebration</mat-icon>
    </div>
  </div>
  <div fxFlex="nogrow">
    <h6>{{'NEW_UI_UPDATE' | translate}}</h6>
    <p>{{'PLEASE_CLICK' | translate}} <strong class="update-button blinking" (click)="update($event)">{{'HERE' | translate}}</strong> {{'TO_UPDATE' | translate}}</p>
    <!-- <button (click)="remindMeLater()" mat-flat-button color="accent">
    Remind me later
  </button> -->
  </div>
</div>