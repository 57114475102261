import { AfterContentChecked, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EventBusService } from './EventBusService';
import { ChangeListener } from './changeListener';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DateUtils } from '../dates/DateUtils';
import { ElementControlService } from './ElementControlService';
import { UserCurrencyService } from '../shared/services/userCurrency.service';
import { PhoneNumberHelperService } from '../shared/services/phone-number-helper.service';
import { LookAndFeelSharedService, TimeValue } from '../shared/services/look-and-feel-shared.service';
import { takeUntil } from 'rxjs/operators';
import { UserCountryService } from '../../services/userCountry.service';
import { EntityInstanceId, Invoice, InvoiceService, InvoiceServiceRow } from '@savvy/invoice';
import { ConsumerService, ServicesService } from '@savvy/services';
import { Product, ProductService } from '@savvy/products';
import { InvoicecompService, UpdateInvoiceComp } from '@savvy/invoice';
import { LookAndFeelService } from '@savvy/look-and-feel';
import {
  ContextIdDto,
  FloHtmlElementHolder,
  InvoiceDefinition,
  PhoneNumber,
  UserDto,
  UserId
} from '@savvy/view-definition';
import { AdditionalDataMapDto, ViewContextDto } from '@savvy/view-composite';
import { CreateEiAndCustomer, EicompService, NameValueTuple } from '@savvy/entity-instance-composite';
import { PortalSettingsLocalService } from '../../services/portalSettingsLocal.service';
import { AddServiceDialogComponent } from './fieldinstance/addServiceDialog.component';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { ServicesHelperService } from '../shared/services/services-helper.service';

@Component({
  selector: 'app-invoice-stepper-panel',
  templateUrl: 'invoiceStepperPanel.component.html'
})
export class InvoiceStepperPanelComponent implements OnInit, AfterContentChecked {

  @Input() invoice: Invoice;
  @Input() userDtos: Array<UserDto>;
  @Input() invoiceDefinition: InvoiceDefinition;
  @Input() consumerServices: Array<ConsumerService>;
  @Input() products: Array<Product>;
  @Input() currencyCode: string;
  @Input() allowMultipleServices: boolean;
  @Input() createEiInline: boolean;
  @Input() eiExtraFields: Array<FloHtmlElementHolder>;


  @Input() viewContext: ViewContextDto;
  @Input() contextIdDto: ContextIdDto;
  @Input() additionalDataMapDto: AdditionalDataMapDto;
  @Input() eventBus: EventBusService;
  @Input() form: UntypedFormGroup;
  @Input() changeListener: ChangeListener;


  @Output() taskSelected = new EventEmitter();
  @Output() taskDeselected = new EventEmitter();

  init = false;
  valid = false;
  timeArray: TimeValue[] = [];
  firstFormGroup: UntypedFormGroup;

  // Phone
  customerPhone: PhoneNumber;

  /** We only set this once created the Ei */
  eiLabel = null;

  portalEnabled = false;
  private destroy$ = new Subject();


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private dateUtils: DateUtils,
    private invoiceService: InvoiceService,
    private ecs: ElementControlService,
    public userCountryService: UserCountryService,
    private invoicecompService: InvoicecompService,
    private eiCompApi: EicompService,
    private userCurrencyService: UserCurrencyService,
    private sharedService: PhoneNumberHelperService,
    private servicesHelperService: ServicesHelperService,
    private lookAndFeelApi: LookAndFeelService,
    private notify: FloSnackbarComponent,
    private lookAndFeelService: LookAndFeelSharedService,
    private portalSettingsLocalService: PortalSettingsLocalService,
    private _formBuilder: UntypedFormBuilder) {
  }

  ngOnInit(): void {


    this.portalEnabled = this.portalSettingsLocalService.portalEnabled;

    console.log('inside init for invoice stepper panel');
    this.customerPhone = <PhoneNumber>{};
    this.customerPhone.country = this.userCountryService?.defaultCountryCode;

    this.initFormGroup();
    if (this.invoice) {
      if (!this.invoice.invoiceServiceRows || this.invoice.invoiceServiceRows.length === 0) {
        this.createServiceInvoiceRow();
      } else {
        this.ensureFirstRowSet();
        this.updateInvoice();
      }
    } else {
      console.log('No invoice');
    }

    console.log('eiExtraFields', this.eiExtraFields);


    this.lookAndFeelApi.getLookAndFeel(this.contextIdDto.contextId)
      .subscribe(response => {
        console.log('setting time array');
        this.timeArray = this.lookAndFeelService.generateLocalisedTimeArray(
          response.timeWidgetPreferences.timeDropDownPeriod,
          response.timeDisplayPreferences);
      });
  }



  ngAfterContentChecked(): void {
    this.init = true;
  }

  createNewService(rowIndex) {
    this.createService(rowIndex);
  }

  createCustomerAndEi(optIn: boolean) {
    const req = <CreateEiAndCustomer>{};
    req.contextIdDto = this.contextIdDto;
    req.entityDefinitionId = this.invoiceDefinition.serviceRowLinkedEntity.id;
    req.eiName = this.firstFormGroup.get('eiName').value;
    req.customerFirstName = this.firstFormGroup.get('customerFirstName').value;
    req.customerLastName = this.firstFormGroup.get('customerLastName').value;
    req.customerEmail = this.firstFormGroup.get('customerEmail').value;
    req.customerPhone = this.customerPhone;
    req.optIn = optIn;
    req.eiExtraFieldValues = new Array();


    if (this.eiExtraFields && this.eiExtraFields.length > 0) {
      this.eiExtraFields.forEach(eiExtraField => {


        console.log('eiExtraField', eiExtraField);
        if (eiExtraField.htmlSelectElementHolder) {
          const name = eiExtraField.htmlSelectElementHolder.name;
          const value = this.firstFormGroup.get(name).value;
          console.log('got name ', name);
          console.log('got value ', value);
          const nameValueTuple = <NameValueTuple>{};
          nameValueTuple.name = name;
          nameValueTuple.values = new Array();
          nameValueTuple.values.push(value);
          req.eiExtraFieldValues.push(nameValueTuple);
        }
      });
    }

    this.eiCompApi.crateEiAndCustomer(req).subscribe(response => {

      console.log('got response!');
      this.invoice.invoiceServiceRows[0].entityInstanceId = <EntityInstanceId>{};
      this.invoice.invoiceServiceRows[0].entityInstanceId.id = response.entityInstance.id;
      this.eiLabel = this.getEiLabel();
      this.updateInvoice();
    }, error => {
      if (error.status === 409) {
        // Previous step
        this.notify.message = 'Customer already exists';
        this.notify.open();
      }
    });
  }

  onMobilePhoneChange(value) {
    console.log('mobile changed');
    this.customerPhone = value;
  }

  onMobileCountryChange($event: any) {
    console.log('onMobileCountryChange', $event);
    this.customerPhone.country = $event.iso2;
    //    this.updateCustomer();
  }

  getEiLabel() {
    return this.firstFormGroup.get('eiName').value;
  }


  createService(rowIndex) {
    const dialogRef = this.dialog.open(AddServiceDialogComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        entityDefinitionLabel: 'Service',
        eventBus: this.eventBus
      }
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this.destroy$)).subscribe(result => {
        console.log('back from dialog close');
        if (result && result.id) {
          console.log('got a new instance to link to ', result);
          this.loadAndSetService(result.id, rowIndex);
        }
      });
  }

  loadAndSetService(serviceId: string, rowIndex) {
    console.log('loading new services');
    this.servicesHelperService.loadServices(this.contextIdDto).then(response => {
      this.consumerServices = response;
      this.setSelectedService(serviceId, rowIndex);
    });
  }

  setSelectedService(serviceNumber, rowIndex) {
    if (this.invoice.invoiceServiceRows && this.invoice.invoiceServiceRows.length > 0) {
      const invServiceRow = this.invoice.invoiceServiceRows[rowIndex];
      console.log('got row ' + invServiceRow);
      invServiceRow.serviceNumber = serviceNumber;
      console.log('Service number is ' + invServiceRow.serviceNumber);
      const serviceDto = this.getService(invServiceRow.serviceNumber);
      if (serviceDto) {
        invServiceRow.unitPrice = serviceDto.unitPrice;
        console.log('set unit price to ' + serviceDto.unitPrice);
        invServiceRow.tax = serviceDto.tax;
        this.invoice.invoiceServiceRows[rowIndex] = invServiceRow;
      } else {
        console.log('failed to get service');
      }
    }
    this.setValid();
  }

  setValid() {
    console.log('inside set valid');
    if (this.init) {
      if (this.form.controls['invoiceServicePanel']) {
        this.form.controls['invoiceServicePanel'].setErrors({ incorrect: !this.isValid() });

        if (this.valid) {
          console.log('setting invoiceServicePanel valid to true');
          this.form.controls['invoiceServicePanel'].setValue('abc');
        } else {
          console.log('setting invoiceServicePanel valid to false');
          this.form.controls['invoiceServicePanel'].setValue('');
        }
      }
    }
  }

  isValid() {
    console.log('inside isValid invoiceServicePanel');
    this.valid = true;
    this.invoice.invoiceServiceRows.forEach(
      row => {
        if (this.invoiceDefinition.enableServiceRowUser && !row.serviceUser) {
          console.log('serviceUser is invalid');
          this.valid = false;
        }
        if (!row.serviceNumber) {
          console.log('serviceNumber is invalid');
          this.valid = false;
        }
        if (this.invoiceDefinition.enableStartTimeServiceRow && !row.startDate) {
          console.log('startDate is invalid');
          this.valid = false;
        }
        if (this.invoiceDefinition.enableStartTimeServiceRow && !row.startTime) {
          console.log('startTime is invalid');
          this.valid = false;
        }
        if (this.invoiceDefinition.enableStartTimeServiceRow && !row.endDate) {
          console.log('endDate is invalid');
          this.valid = false;
        }
        if (this.invoiceDefinition.enableStartTimeServiceRow && !row.endTime) {
          console.log('endTime is invalid');
          this.valid = false;
        }
        if (this.invoiceDefinition.serviceRowLinkedEntity && !row.entityInstanceId) {
          console.log('entityInstanceId is invalid');
          this.valid = false;
        }
      }
    );
    console.log('isp:returning valid ' + this.valid);
    return this.valid;
  }

  createServiceInvoiceRow() {
    console.log('*** creating service invoice row *** ');
    const invoiceRow: InvoiceServiceRow = <InvoiceServiceRow>{};
    if (!this.invoice.invoiceServiceRows) {
      console.log('creating rows array');
      this.invoice.invoiceServiceRows = new Array(0);
    }
    console.log('adding service row 127');

    this.invoice.invoiceServiceRows.push(invoiceRow);

    console.log('rows now ' + this.invoice.invoiceServiceRows.length);
    console.log('invoiceRow now ', invoiceRow);


    this.updateInvoice();
  }

  startTimeChanged(row: InvoiceServiceRow, value: any) {
    console.log('start time changed:' + row.startTime);
    console.log('start time value:' + value);

    this.setEndTimeFromStart(row, value);
    this.updateInvoice();
  }

  setEndTimeFromStart(row: InvoiceServiceRow, value: any) {

    if (row.serviceNumber) {
      const service = this.getService(row.serviceNumber);
      if (service) {
        const durationInMins = service.durationInMins;
        console.log('got duration in mins ' + durationInMins);
        row.endTime = this.dateUtils.addMinsToTimeString(value, durationInMins);
        console.log('just set endTime to ' + row.endTime);
      }
    }
  }

  setEndTimeFromStartRow(row: InvoiceServiceRow) {

    if (row.serviceNumber && row.startTime) {
      const service = this.getService(row.serviceNumber);
      if (service) {
        const durationInMins = service.durationInMins;
        console.log('got duration in mins ' + durationInMins);
        row.endTime = this.dateUtils.addMinsToTimeString(row.startTime, durationInMins);
        console.log('just set endTime to ' + row.endTime);
      }
    }
  }

  deleteServiceRow(invoiceRow: InvoiceServiceRow) {

    this.invoice.invoiceServiceRows.forEach((item, index) => {
      if (item === invoiceRow) {
        this.invoice.invoiceServiceRows.splice(index, 1);
      }
    });
    this.updateInvoice();
  }

  serviceChanged() {
    console.log('service changes');
    if (this.invoice.invoiceServiceRows) {
      this.invoice.invoiceServiceRows.forEach(row => {
        console.log('got row ' + row);
        if (row.serviceNumber) {
          console.log('Service number is ' + row.serviceNumber);
          const serviceDto = this.getService(row.serviceNumber);
          if (serviceDto) {
            console.log('setting unit price to ', serviceDto.unitPrice);
            row.unitPrice = serviceDto.unitPrice;
            row.tax = serviceDto.tax;
          } else {
            console.log('failed to get service');
          }
          if (!row.quantity) {
            row.quantity = 1;
          }
        }
      });
    }
    this.updateInvoice();
  }
  getService(serviceId: string): ConsumerService {

    let retConsumerService: ConsumerService;
    this.consumerServices.forEach(serviceDto => {

      console.log('serviceDto is ' + serviceDto.id);
      console.log('serviceId is ' + serviceId);
      if (serviceDto.id === serviceId) {
        console.log('found match!');
        retConsumerService = serviceDto;
        return serviceDto;
      } else {
        console.log('no match');
      }
    });
    return retConsumerService;
  }

  updateInvoice() {

    const req: UpdateInvoiceComp = <UpdateInvoiceComp>{};
    req.contextIdDto = this.contextIdDto;
    req.invoice = this.invoice;
    req.entityInstanceId = this.viewContext.entityInstanceId;

    this.invoicecompService.updateInvoiceComp(req).subscribe(response => {
      // @ts-ignore
      this.invoice = response.invoice;
      // @ts-ignore
      this.ecs.handleChangedElements(response.changedElementList, this.form);
      this.setValid();
      // this.changeListener.add('change');
      // this.eventBus.addInvoiceEvent(EventType.INVOICE_UPDATED, this.invoice.invoiceId);
    });
  }

  ensureFirstRowSet() {
    console.log('ensuring first row is set');
    if (!this.invoice.invoiceServiceRows[0].serviceUser) {
      this.invoice.invoiceServiceRows[0].serviceUser = this.userDtos[0].userId;
    }
    if (!this.invoice.invoiceServiceRows[0].serviceNumber) {
      if (this.consumerServices.length > 0) {
        this.invoice.invoiceServiceRows[0].serviceNumber = this.consumerServices[0].id;
        this.invoice.invoiceServiceRows[0].quantity = 1;
        this.invoice.invoiceServiceRows[0].unitPrice = this.consumerServices[0].unitPrice;
        this.invoice.invoiceServiceRows[0].tax = this.consumerServices[0].tax;


        // this.setEndTimeFromStart(this.invoice.invoiceServiceRows[0],  )


        // TODO: TO BE CALCULATED
        // this.invoice.invoiceServiceRows[0].tax = this.serviceDtos[0].tax;
        console.log('set service to:', this.consumerServices[0].id);
      }
    }
  }

  serviceUserChanged() {
    console.log('service user changed');

    // invoiceServicePanel
    this.updateInvoice();
  }

  startDateChange(row: InvoiceServiceRow) {
    let dateStr;
    if (row.startDate) {
      dateStr = this.dateUtils.getDateAsStringDash(row.startDate);
    }
    console.log('dateStr ' + dateStr);

    row.startDate = dateStr;
    row.endDate = dateStr;

    this.invoice.invoiceServiceRows.forEach(aRow => {
      aRow.startDate = dateStr;
      aRow.endDate = dateStr;
    });
    // Update all

    this.updateInvoice();
  }

  endTimeChanged(row: InvoiceServiceRow, value: any) {
    console.log('end time changed:' + row.startTime);
    console.log('end time value:' + value);
    this.updateInvoice();
  }

  setUserOptVal(user: UserDto): UserId {
    return { id: user.id, userContextType: UserId.UserContextTypeEnum.UserId };
  }

  compareUserFn(user1: UserDto, user2: UserDto) {
    return user1 && user2 && user1.id === user2.id;
  }

  compareTimeFn(user1: string, user2: string) {
    // console.log('comparing');
    return user1 && user2 && user1 === user2;
  }

  loadServices() {
    this.servicesHelperService.getConsumerServices(this.contextIdDto).then(consumerServices => {
      if (consumerServices.length) {
        this.consumerServices = consumerServices;
      }
    });
  }

  private initFormGroup() {

    console.log('inside init form group');
    this.firstFormGroup = this._formBuilder.group({
      eiName: ['', Validators.required],
      customerFirstName: ['', Validators.required],
      customerLastName: ['', Validators.required],
      customerEmail: [''],
      customerPhone: ['']
    });

    if (this.eiExtraFields && this.eiExtraFields.length > 0) {
      this.eiExtraFields.forEach(eiExtraField => {


        console.log('eiExtraField', eiExtraField);
        if (eiExtraField.htmlSelectElementHolder) {
          console.log('got name ', eiExtraField.htmlSelectElementHolder.name);
          this.firstFormGroup.addControl(eiExtraField.htmlSelectElementHolder.name, new UntypedFormControl());
          console.log('got value ', this.firstFormGroup.get(eiExtraField.htmlSelectElementHolder.name).value);
        }
      });
    }
  }
}
