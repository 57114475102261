import {
  Component, EventEmitter, Input, OnInit,
  Output, OnChanges, SimpleChanges, HostListener, ElementRef, ViewChild
} from '@angular/core';
import { ContextIdDto } from '@savvy/app';
import { LookAndFeel } from '@savvy/look-and-feel';
import { DateUtils } from 'src/app/flo/dates/DateUtils';
import { AppointmentConfigHelperService } from 'src/app/flo/shared/services/appointment-config-helper.service';
import { LookAndFeelConfig, LookAndFeelSharedService, TimeValue } from 'src/app/flo/shared/services/look-and-feel-shared.service';
import { AppointmentConfig } from '@savvy/appointment';
import { CreateBoardingBookingV2SyncService } from '../create-boarding-booking-v2-sync.service';
import * as moment from 'moment';
import { FloSnackbarComponent } from 'src/app/flo/snackbar/floSnackbar.component';

@Component({
  selector: 'app-boarding-date-selector',
  templateUrl: './boarding-date-selector.component.html',
  styleUrls: ['./boarding-date-selector.component.scss']
})
export class BoardingDateSelectorComponent implements OnInit, OnChanges {

  @ViewChild('datePickerInput', { static: false }) datePickerInput: ElementRef;


  @Input() contextIdDto: ContextIdDto;
  @Input() totalAppointmentDuration: number;

  @Input() startDate: Date;
  @Output() startDateChange = new EventEmitter();

  @Input() startTime: string;
  @Output() startTimeChange = new EventEmitter();

  @Input() endDate: Date;
  @Output() endDateChange = new EventEmitter();

  @Input() endTime: string;
  @Output() endTimeChange = new EventEmitter();

  @Input() disableTimePickup = false;
  @Input() rebook = false;



  allowStartAndEndTime = true;
  valid = false;

  timeArray: TimeValue[] = [];
  lookAndFeelConfig: LookAndFeelConfig;
  lookAndFeel: LookAndFeel;
  defaultTimeValue: string;
  appointmentConfig: AppointmentConfig;
  endtimeMessage = `Appointment end time will be auto calculated from service duration`;

  minDate: Date;
  maxDate: Date;
  today = new Date();

  constructor(
    private lookAndFeelSharedService: LookAndFeelSharedService,
    private dateUtils: DateUtils,
    private createBoardingBookingV2SyncService: CreateBoardingBookingV2SyncService,
    public appointmentConfigHelperService: AppointmentConfigHelperService,
    private eRef: ElementRef,
    private notify: FloSnackbarComponent
  ) {

  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.eRef.nativeElement.contains(event.target)) {
      // this.text = "clicked inside";
    } else {
      // this.text = "clicked outside";
      const focusoutEvent = new Event('focusout');
      this.eRef.nativeElement.dispatchEvent(focusoutEvent);
      this.datePickerInput.nativeElement.dispatchEvent(focusoutEvent);
    }
  }

  ngOnInit() {
    this.appointmentConfig = this.appointmentConfigHelperService.appointmentConfig;

    this.lookAndFeelConfig = this.lookAndFeelSharedService.getLookAndFeelConfig(this.contextIdDto);
    if (this.lookAndFeelConfig) {
      this.lookAndFeel = this.lookAndFeelConfig.lookAndFeel;
      this.timeArray = this.lookAndFeelConfig.timeArray;
      this.checkIfValid();
    } else {
      console.log('no look and feel config');
    }
    this.setMinMaxDates();
    let day = moment(this.startDate).clone().format('dddd').toLowerCase();
    if (!this.createBoardingBookingV2SyncService.editMode && this.createBoardingBookingV2SyncService.workingHours[day]) {
      this.startTime = this.createBoardingBookingV2SyncService.workingHours[day].hours[0]?.start;
      this.endTime = this.createBoardingBookingV2SyncService.workingHours[day].hours[0]?.end;
    }
    this.createBoardingBookingV2SyncService.setAppointmentDates(this.startDate, this.endDate, this.startTime, this.endTime);

  }

  setMinMaxDates() {
    if (this.startDate) {
      this.minDate = moment(this.startDate).add(1, 'day').toDate();
    }
    if (this.endDate) {
      this.maxDate = moment(this.endDate).subtract(1, 'day').toDate();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.startDate?.currentValue !== changes?.startDate?.previousValue) {
      console.log('loading startDate: ', this.startDate);
      this.checkIfValid();
    }
  }

  checkIfValid() {
    if (this.startDate > this.endDate) {
      this.startDate = this.endDate;
      this.notify.message = 'End date should be greater than or equal to start date';
      this.notify.open();
      this.valid = false;
    } else {
      this.valid = true;
    }
    if (this.dateUtils.getDateAsStringV2(this.startDate) === this.dateUtils.getDateAsStringV2(this.endDate)) {
      this.notify.message = 'End time should be greater than or equal to start time';
      this.notify.open();
      this.valid = this.startTime && this.endTime ? this.startTime <= this.endTime : false;
    }
  }

  closedStream($event) {
    const focusoutEvent = new Event('focusout');
    this.eRef.nativeElement.dispatchEvent(focusoutEvent);
    this.datePickerInput.nativeElement.dispatchEvent(focusoutEvent);
  }

  startDateChanged() {
    // this.checkIfValid();
    // if (!this.valid) {
    //   return;
    // }
    this.startDateChange.emit(this.startDate);
    this.endDate = moment(this.startDate).add(1, 'day').toDate();
    this.endDateChange.emit(this.endDate);

    console.log('startDate ' + this.startDate);
    this.createBoardingBookingV2SyncService.setAppointmentDates(this.startDate, this.endDate, this.startTime, this.endTime);
    const focusoutEvent = new Event('focusout');
    this.eRef.nativeElement.dispatchEvent(focusoutEvent);
    this.datePickerInput.nativeElement.dispatchEvent(focusoutEvent);
    this.setMinMaxDates();
  }

  endDateChanged() {
    this.checkIfValid();
    if (!this.valid) {
      return;
    }
    this.endDateChange.emit(this.endDate);
    console.log('endDate ' + this.endDate);
    this.createBoardingBookingV2SyncService.setAppointmentDates(this.startDate, this.endDate, this.startTime, this.endTime);
    const focusoutEvent = new Event('focusout');
    this.eRef.nativeElement.dispatchEvent(focusoutEvent);
    this.datePickerInput.nativeElement.dispatchEvent(focusoutEvent);
    this.setMinMaxDates();
  }

  startTimeChanged(value: any) {
    this.startTimeChange.emit(this.startTime);
    this.createBoardingBookingV2SyncService.setAppointmentDates(this.startDate, this.endDate, this.startTime, this.endTime);
    this.checkIfValid();
  }

  // setEndTimeFromStart(value: any) {
  //   console.log('got duration in mins ' + this.totalAppointmentDuration);
  //   this.endTime = this.dateUtils.addMinsToTimeString(value, this.totalAppointmentDuration);
  //   this.endTimeChange.emit(this.endTime);
  //   this.createPetAppointmentV2SyncService.setAppointmentDates(this.startDate, this.endDate, this.startTime, this.endTime);
  //   this.createAppointmentV2SyncService.setAppointmentDates(this.startDate, this.endDate, this.startTime, this.endTime);
  //   console.log('just set endTime to ' + this.endTime);
  // }

  endTimeChanged(value: any) {
    this.endTimeChange.emit(this.endTime);
    this.createBoardingBookingV2SyncService.setAppointmentDates(this.startDate, this.endDate, this.startTime, this.endTime);
    this.checkIfValid();
  }

  compareTimeFn(user1: string, user2: string) {
    // console.log('comparing');
    return user1 && user2 && user1 === user2;
  }

  openCalendar() {
    //
  }

}
