import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import {
  WorkflowdefService,
  ContextIdDto,
  UpdateWorkflowDefinition,
  WorkflowDefinitionTypeItem,
  WorkflowState,
  DeleteWorkflowDefinition,
  RemoveStateFromWorkflow,
  CreateWorkflowDefinition
} from '@savvy/workflow-definition';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { MatSelectChange } from '@angular/material/select';
import { WorkflowDefinition } from '@savvy/workflow-instance';


@Component({
  selector: 'app-add-edit-workflow',
  templateUrl: 'addEditWorkflow.component.html',
  styleUrls: ['./addEditWorkflow.component.scss']
})
export class AddEditWorkflowComponent implements OnInit {

  workflowDef: any;
  contextIdDto: ContextIdDto;
  columns = [
    { prop: 'state' }
  ];
  selected: any[] = [];
  workflowDefinitionTypeItems: WorkflowDefinitionTypeItem[];
  workflowDefinitionTypeDataIdx: number;

  constructor(
    private route: ActivatedRoute,
    private notify: FloSnackbarComponent,
    private router: Router,
    private cd: ChangeDetectorRef,
    // private compApi: WorkflowDefinitionCompApiExtension,
    private api: WorkflowdefService) {

  }

  ngOnInit(): void {
    console.log('inside workflow component');

    this.route.params.subscribe(params => {
       this.contextIdDto = <ContextIdDto>{};
      this.contextIdDto.contextId = params['contextId'];
      this.contextIdDto.contextIdType = params['contextIdType'];
      console.log('Set context id dto to ' + this.contextIdDto.contextId);
      const workflowDefinitionId = params['workflowDefinitionId'];

      this.api.getValidWorkflowTypes(
        this.contextIdDto.contextId, this.contextIdDto.contextIdType
      ).subscribe(response => {
        console.log('getValidWorkflowTypes', response);
        this.workflowDefinitionTypeItems = response;
        if (workflowDefinitionId) {
          console.log('Got workflowDefinitionId ' + workflowDefinitionId);
          this.loadWorkflowDetail(workflowDefinitionId);
        } else {
          this.workflowDef = <WorkflowDefinition>{};
          this.setWorkflowIdx();
        }
      });
    });
  }
  setWorkflowIdx() {
    if (this.workflowDefinitionTypeItems && this.workflowDef && this.workflowDef.workflowDefinitionTypeData) {
      this.workflowDefinitionTypeItems.forEach(
        typeItem => {
          if (this.workflowDef.workflowDefinitionTypeData.workflowDefinitionType.toString()
            === typeItem.workflowDefinitionTypeData.workflowDefinitionType.toString()) {
            if (this.workflowDef.workflowDefinitionTypeData.entityDefinitionId.id ===
              typeItem.workflowDefinitionTypeData.entityDefinitionId.id) {
              this.workflowDefinitionTypeDataIdx = typeItem.idx;
              this.cd.detectChanges();
            }
          }
        });
    }
  }

  update() {
    console.log('update called');
    if (this.workflowDef.id) {
      const req = <UpdateWorkflowDefinition>{};
      req.contextIdDto = this.contextIdDto;
      req.workflowDefinition = this.workflowDef;
      console.log('name is ' + req.workflowDefinition.name);
      this.api.updateWorkflowDef(req).subscribe(
        workflowDefinitionDef => {
          this.workflowDef = workflowDefinitionDef;
        });
    }
  }

  public deleteWorkflow() {

    const req = <DeleteWorkflowDefinition>{};
    req.contextIdDto = this.contextIdDto;
    req.workflowDefinitionId = this.workflowDef.workflowDefinitionId.id;
    this.api.deleteWorkflowDefinition(req).subscribe(response => {
      console.log('removed workflow definition');

      this.router.navigate(['/workflow/workflows',
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType]);
    });

  }

  public deleteState(state: WorkflowState) {
    console.log('deleting state');
    const req = <RemoveStateFromWorkflow>{};
    req.contextIdDto = this.contextIdDto;
    req.workflowDefinitionId = this.workflowDef.workflowDefinitionId.id;
    req.stateId = state.id;
    this.api.removeStateFromWorkflow(req).subscribe(response => {
      console.log('removeStateFromWorkflow response is ' + response);
      this.workflowDef = response.workflowDefinition;
    });
  }

  loadWorkflowDetail(workflowDefinitionId) {

    this.api.getWorkflowDef(this.contextIdDto.contextId, this.contextIdDto.contextIdType,
      workflowDefinitionId).subscribe(workflowDef => {
        this.workflowDef = workflowDef;
        this.setWorkflowIdx();
      });
  }
  updateTypeData(item: MatSelectChange) {
    if (this.workflowDef) {
      this.workflowDef.workflowDefinitionTypeData = this.workflowDefinitionTypeItems[item.value].workflowDefinitionTypeData;
    }
  }

  createWorkflow() {
    console.log('create workflow called');
    const req = <CreateWorkflowDefinition>{};
    req.contextIdDto = this.contextIdDto;
    req.workflowName = this.workflowDef.name;

      if (this.workflowDef && this.workflowDef.workflowDefinitionTypeData && this.workflowDef.workflowDefinitionTypeData.workflowDefinitionType) {
        req.workflowDefinitionTypeData = this.workflowDef.workflowDefinitionTypeData;

        this.api.createWorkflowDef(req).subscribe(response => {
          this.notify.message = 'Created Workflow';
          this.notify.open();
          this.router.navigate(['/workflow/addEditWorkflow',
            response.id,
            this.contextIdDto.contextId,
            this.contextIdDto.contextIdType,
          ]);
          // this.workflowAdded.emit('workflowAdded');
        });
      } else {
        console.log('error, type not set');
      }
  }
  createState() {
    this.router.navigate(['/workflow/addEditWorkflowState',
      this.workflowDef.id,
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
    ]);
  }

  onSelect(event) {

    console.log('Event: select', event, this.selected[0]);

    this.router.navigate(['/workflow/addEditWorkflowState',
      this.workflowDef.id,
      this.selected[0].id,
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
    ]);
  }
}

