import { Component, Input, OnInit } from '@angular/core';

import { UntypedFormGroup } from '@angular/forms';
import { EntitysService } from '@savvy/entity-instance';
import {
  ContextIdDto,
  EicompService, ElementDto,
  ElementInstanceDto,
  ElementSetDefinitionDto,
  EntityDefinitionDto, EntityNewFieldEvent
} from '@savvy/entity-instance-composite';
import { ChangeListener } from '../changeListener';
import {ElementSetRowDto} from '@savvy/entity-definition';

@Component({
    selector: 'app-entity-element-set-definition',
    templateUrl: './elementSetDefinition.component.html',
    styleUrls: ['elementSetDefinition.css']
})
export class ElementSetDefinitionComponent implements OnInit {

    @Input() elementSetDefinition: ElementSetDefinitionDto;
    @Input() entityDefinitionDto: EntityDefinitionDto;
    @Input() contextIdDto: ContextIdDto;
    @Input() elementInstanceDto: ElementInstanceDto;
    @Input() viewContext: any;
    @Input() form: UntypedFormGroup;
    @Input() showPlaceholder: boolean;
    @Input() additionalDataMapDto: any;
    @Input() changeListener: ChangeListener;

    displayedColumns = [];
    rows: RowData[] = [];

    editing = {};

    constructor(
        private uiApi: EicompService,
        public api: EntitysService) {
    }

    ngOnInit() {
        for (const el of this.elementSetDefinition.elementDtos) {
            // console.log('pushing col ' + el.elementType + ' name ' + el.name);
            this.displayedColumns.push(el.name);
        }
        // console.log('columns is ' + JSON.stringify(this.displayedColumns));

        this.setRows();
        // console.log('rows are ' + this.rows.length);
        // this.dataSource = new RowDataSource(this.rows);
    }

    updateValue(event, cell, cellValue, row) {
        console.log('value updated to ' + cellValue);
        this.editing[row.$$index] = false;
        // this.contactForm.elementDtoList[row.$$index].name = event.target.value;
    }

    /**
     *
     *
     * @param rowIdx
     * @param colName
     * @returns
     */
    getElement(rowIdx: number, colName: string): ElementDto {
        // console.log('getViewElement ' + rowIdx + ' colName ' + colName);

        const colElementDef: ElementDto = this.getColumnElementDefinition(colName);

        if (colElementDef == null) {
            console.log('FAILED to get col element def for colName ' + colName);
        } else {
            const elementInstance = this.getElementInstance(rowIdx, colElementDef.id);

            if (elementInstance) {
                //  console.log('Found element instance');


                //  console.log('FOUND col def for colName ' + colName);
                //  console.log('elementInstanceDto.value ' + elementInstance.fieldInstanceDto.value);
             /*   const newElementDto: ElementDto = {
                    elementInstanceDto: elementInstance,
                    elementType: ElementDto.ElementTypeEnum.ElementInstance
                };*/
                // console.log('returning ' + newElementDto);
             //   return newElementDto;

            } else {
                console.log('failed to get field instance for colName ' + colName);
            }
        }
        return null;
    }

    getColumnElementDefinition(colName: string): ElementDto {
        let retViewElement = null;
        this.elementSetDefinition.elementDtos.forEach((elementDef, index) => {

            if (elementDef.name === colName) {
                // console.log('FOUND col def ' + elementDef.name);
                retViewElement = elementDef;
            }
        });
        return retViewElement;
    }

    getElementInstance(rowIdx: number, colElementDefId: string): ElementInstanceDto {

        const retFieldInstance = null;
    /*   this.elementInstanceDto.elementSetValueDto.elementSetRowDtos[rowIdx].elementInstanceDtos.forEach((elementInstance, index) => {
            if (elementInstance.elementDefinitionId === colElementDefId) {
                retFieldInstance = elementInstance;
            }
        });*/
        return retFieldInstance;
    }

    setRows() {

        // Reset it!
        this.rows = [];

        if (this.elementInstanceDto.elementSetValueDto && this.elementInstanceDto.elementSetValueDto.elementSetRowDtos) {
            // console.log(' there are ' + this.elementInstanceDto.elementSetValueDto.elementSetRowDtos.length + ' rows');
            this.elementInstanceDto.elementSetValueDto.elementSetRowDtos.forEach((aRow, index) => {
                this.rows.push(this.getRow(aRow, index));
            });
        }
    }


    getRow(rowElements: ElementSetRowDto, rowIdx: number): RowData {
        const row: ElementDto[] = [];

        for (const colName of this.displayedColumns) {
            row.push(this.getElement(rowIdx, colName));
        }

        const rowData: RowData = new RowData();
        rowData.rowData = row;
        return rowData;
    }

    create() {
        const request: EntityNewFieldEvent = {
            contextIdDto: this.contextIdDto,
            entityInstanceId: this.viewContext.entityInstanceId.id,
            fieldElementId: this.elementSetDefinition.elementId,
            fieldName: this.elementSetDefinition.name
        };

        this.uiApi.entityInstanceNewFieldEvent(request).subscribe(
            response => {

                // console.log('got loadProductsResponse num rows now '
                // + loadProductsResponse.newField.elementSetValueDto.elementSetRowDtos.length);
                // console.log('firing reload event');
                if (this.changeListener) {
                    this.changeListener.add('reload');
                }
            }
        );
    }
}
export class RowData {
    rowData: ElementDto[];
}
