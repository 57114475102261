
<mat-card appearance="outlined" class="upload-files panel new-card">

  <form #importForm="ngForm" fxLayoutAlign="space-between center">
    <mat-card-title>Files</mat-card-title>
    <input [hidden]="true" id="input-file-id-field" multiple required (change)="saveFiles($event)"
           type="file">
    <!--button class="upload-button" mat-mini-fab color="accent">
      <label class="upload-label" for="input-file-id">
      </label>
      <mat-icon class="mat-24">add</mat-icon>
    </button-->
  </form>

  <mat-card-content *ngIf="!isMobile">

    <ngx-file-drop dropZoneLabel="Drop files here" dropZoneClassName="duff" (onFileDrop)="droppedFiles($event)">
      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        Drop files here.
        <button mat-raised-button color="accent" (click)="openFileSelector()">Browse Files</button>
      </ng-template>
    </ngx-file-drop>


    <!--ngx-file-drop class="drop" (onFileDrop)="droppedFiles($event)">
      <div class="drop-info">
        <i class="material-icons">
          insert_drive_file
        </i>
        <mat-card-title>Drop files here</mat-card-title>
      </div>
    </ngx-file-drop-->
  </mat-card-content>

  <mat-card-content>
    <div class="files" fxLayout="row wrap" fxLayoutAlign="start baseline">
      <div class="file" *ngFor="let file of savedFiles; let i = index">
        <img src="./assets/images/icons8-audio-file.svg" class="zero-image">
        <p>{{file.fileName}}</p>
        <div class="file-menu">
          <button (click)="removeFile(i)" class="ml-xs mat-icon-button remove-file" mat-icon-button="">
                <span class="mat-button-wrapper">
                    <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">close</mat-icon>
                </span>
            <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
            <div class="mat-button-focus-overlay"></div>
          </button>
          <button (click)="downloadFile(file)" class="ml-xs mat-icon-button download-file" mat-icon-button="">
                <span class="mat-button-wrapper">
                    <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">get_app</mat-icon>
                </span>
            <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
            <div class="mat-button-focus-overlay"></div>
          </button>
        </div>
      </div>
    </div>
  </mat-card-content>

</mat-card>
