import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Invoice,
  InvoiceService,
  ListInvoiceSorted,
  UpdateInvoice
} from '@savvy/invoice';
import { Payment, PaymentcompService, RecordPaymentComp } from '@savvy/payment';
import { PlanDto } from '@savvy/plan';
import { IdNameTupleDto, Product } from '@savvy/products';
import { CardMachine } from '@savvy/savvy-pay';
import { ConsumerService } from '@savvy/services';
import { ShopConfig } from '@savvy/shop';
import { Tax } from '@savvy/tax';
import { ContextIdDto } from '@savvy/ui';
import { ViewInvoiceV2Component } from '../appointment/view-invoice-v2/view-invoice-v2.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ViewDiscountsModalComponent } from '../discount/view-discounts/view-discounts-modal.component';
import { EventBusService } from "../element/EventBusService";
import { SendInvoiceDialogComponent } from '../invoice/sendInvoiceDialog.component';
import { AddPaymentModalComponent } from '../shared/components/add-payment-modal/add-payment-modal.component';
import { Page } from '../shared/model/page';
import { UserCurrencyService } from '../shared/services/userCurrency.service';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import PaymentMethodEnum = Payment.PaymentMethodEnum;

@Component({
  selector: 'app-retail-sales',
  templateUrl: './retail-sales.component.html',
  styleUrls: ['./retail-sales.component.scss']
})
export class RetailSalesComponent implements OnInit {
  eventBus: EventBusService;
  contextIdDto = {} as ContextIdDto;
  currencyCode = '';
  draftInvoices: Invoice[] = [];
  cardMachines: Array<CardMachine> = [];
  plans: Array<PlanDto> = [];

  newCustomer = false;

  categories: IdNameTupleDto[] = [];
  taxDefs: Tax[] = [];
  serviceGroups = [];
  shopConfig: ShopConfig;

  invoices: Invoice[] = [];
  page = new Page();
  selected = [];

  constructor(
    private route: ActivatedRoute,
    private userCurrencyService: UserCurrencyService,
    private invoiceService: InvoiceService,
    private dialog: MatDialog,
    private notify: FloSnackbarComponent,
    private paymentcompService: PaymentcompService,
    private router: Router,

  ) { }

  setPage(pageInfo) {
    console.log('pageInfo is ' + pageInfo);
    this.page.pageNumber = pageInfo.offset;
    this.loadOrders();
  }

  ngOnInit() {
    this.route
      .params
      .subscribe(params => {
        console.log('inside signin, context id is ' + params['contextId']);
        if (params['contextId']) {
          console.log('setting contextId');
          this.contextIdDto = {} as ContextIdDto;
          this.contextIdDto.contextId = params['contextId'];
          this.contextIdDto.contextIdType = params['contextIdType'];
          this.getCurrencyCode();
          this.setPage({ offset: 0, size: 20 });
        }
      });
  }

  getTotalItemsInCart(saleOrder: Invoice): number {
    return Number(saleOrder?.invoiceServiceRows?.length || 0) + Number(saleOrder?.invoiceProductRows?.length || 0);
  }

  loadOrders() {

    const req = <ListInvoiceSorted>{};
    req.contextIdDto = this.contextIdDto;
    req.pageNum = this.page.pageNumber + 1;
    req.pageSize = this.page.size;
    req.ascending = false;
    req.column = 'invoiceDate';

    this.invoiceService.listInvoiceSorted(req).subscribe(res => {
      this.invoices = res.contents;
      this.page.totalElements = res.totalElements;
      this.page.totalPages = res.totalPages;
    });
  }

  getCurrencyCode() {
    this.userCurrencyService.getDefaultCurrency(this.contextIdDto)
      .subscribe(res => {
        this.currencyCode = res.org.currencyCode ? res.org.currencyCode : this.userCurrencyService.defaultCurrency;
      });
  }

  updateInvoice(invoice: Invoice, reloadAll = false) {
    const updateInvoice: UpdateInvoice = {
      contextIdDto: this.contextIdDto,
      invoice
    }
    this.invoiceService.updateInvoice(updateInvoice).subscribe((updatedInvoice: Invoice) => {
      if (updatedInvoice) {
        if (reloadAll) {
          // this.loadOrders();
        } else {
          const draftInvoiceIndex = this.draftInvoices.findIndex(inv => inv.id === invoice.id);
          if (draftInvoiceIndex > -1) {
            this.draftInvoices[draftInvoiceIndex] = updatedInvoice;
          }
        }
      }
    });
  }


  sendInvoice(invoice: Invoice) {
    this.dialog.open(SendInvoiceDialogComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        invoice,
        eventBus: this.eventBus
      },
      autoFocus: false,
      panelClass: 'helpwindow'
    });
  }

  voidInvoice(invoice: Invoice) {
    const confirmDialog = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Void',
        message: 'Are you sure you want to void this invoice?'
      },
      height: 'auto',
      width: '350px',
      panelClass: 'helpwindow'
    });
    confirmDialog.afterClosed().subscribe((result: boolean) => {
      if (result) {
        invoice.invoiceVoid = true;
        this.updateInvoice(invoice);
      }
    });
  }

  addPayment(invoice: Invoice) {
    const dialogRef = this.dialog.open(AddPaymentModalComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        invoice,
      },
      autoFocus: false,
      panelClass: ['helpwindow']
    });

    dialogRef.afterClosed()
      .subscribe((res) => {
        if (res) {
          console.log('reloading invoice');
          this.updateInvoice(invoice);
        }
      });
  }

  paidFull(type: string, invoice: Invoice) {
    const payment = {} as Payment;
    payment.amount = invoice.total;
    payment.ownerId = this.contextIdDto.contextId;
    payment.invoiceId = invoice?.invoiceId;

    if (type === PaymentMethodEnum.Cash) {
      payment.paymentMethod = PaymentMethodEnum.Cash;
    } else if (type === PaymentMethodEnum.DirectDebit) {
      payment.paymentMethod = PaymentMethodEnum.DirectDebit;
    } else if (type === PaymentMethodEnum.BankTransfer) {
      payment.paymentMethod = PaymentMethodEnum.BankTransfer;
    } else if (type === PaymentMethodEnum.Savvypay) {
      payment.paymentMethod = PaymentMethodEnum.Savvypay;
    } else {
      payment.paymentMethod = PaymentMethodEnum.CardMachine;
    }

    const req = {} as RecordPaymentComp;
    req.contextIdDto = this.contextIdDto;
    req.payment = payment;

    this.paymentcompService.recordPaymentComp(req)
      .subscribe(() => {
        this.notify.message = 'Successfully added payment';
        this.notify.open();
        this.updateInvoice(invoice);
      }, () => {
        console.log('Error occurred while adding payment');
      });
  }


  trackByMethod(index: number): number {
    return Number(index);
  }

  trackByProductMethod(prod: Product): number {
    return Number(prod.id);
  }

  trackByServiceMethod(service: ConsumerService): number {
    return Number(service.id);
  }

  onDiscountClick(invoice: Invoice) {
    const dialogRef = this.dialog.open(ViewDiscountsModalComponent, {
      data: {
        amount: invoice.subTotal,
        discountId: invoice.discountId,
        currencyCode: this.currencyCode
      },
      width: '775px',
      autoFocus: false,
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed()
      .subscribe(result => {
        if (result?.discountItem) {
          invoice.discountDetails = result.discountItem.discount;
          invoice.discountId = result.discountItem.id;
        } else {
          if (invoice.discountId) {
            // overall discount
            invoice.discountDetails = undefined;
            invoice.discountId = undefined;
            invoice.discount = undefined;
            invoice.discountTotal = 0;
          }
          if (invoice.invoiceProductRows?.length) {
            for (const row of invoice.invoiceProductRows) {
              row.discountDetails = undefined;
              row.discountId = undefined;
            }
          }
          if (invoice.invoiceServiceRows?.length) {
            for (const row of invoice.invoiceServiceRows) {
              row.discountDetails = undefined;
              row.discountId = undefined;
            }
          }
        }
        this.updateInvoice(invoice);

      });
  }



  createNewDraftInvoice() {
    this.router.navigate(['createRetailOrder', this.contextIdDto.contextId, this.contextIdDto.contextIdType]);
  }

  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);
    if (this.selected?.[0]) {
      this.router.navigate(['editRetailOrder', this.contextIdDto.contextId, this.contextIdDto.contextIdType, this.selected[0]?.id]);
    }
  }

  viewInvoice(invoiceId: string) {

    console.log('inside view invoiceId ', invoiceId);
    const dialogRef = this.dialog.open(ViewInvoiceV2Component, {
      maxWidth: '800px',
      width: '800px',
      data: {
        invoiceId,
        contextIdDto: this.contextIdDto
      },
      panelClass: ['scrollable-modal', 'invoicemodal']
    });

    dialogRef.afterClosed().subscribe(() => {

    });
  }

  // onActivate(event) {
  //   console.log('Activate Event', event);
  // }

}
