import { Component, Input, OnInit } from '@angular/core';
import { DonutChartDto } from '@savvy/view-definition';
import {Chart} from 'chart.js/auto';

@Component({
  selector: 'app-donut-chart',
  templateUrl: 'donutChart.component.html'
})
export class DonutChartComponent implements OnInit {

  @Input() donutChartDto: DonutChartDto;
  public chart: any;
  donutChart = null;

  globalChartOptions: any = {
    responsive: true,
    legend: {
      display: false,
      position: 'bottom'
    }
  };

  // Doughnut
  doughnutChartColors: any[] = [{
    backgroundColor: ['#ffcc80', '#ef9a9a']
  }];
  doughnutChartLabels: string[] = ['Download Sales', 'In-Store Sales', 'Mail-Order Sales'];
  doughnutChartData: number[] = [350, 450, 100];
  doughnutChartType = 'doughnut';
  doughnutOptions: any = Object.assign({
    elements: {
      arc: {
        borderWidth: 0
      }
    }
  }, this.globalChartOptions);


  ngOnInit(): void {
    this.donutChart = document.getElementById('donutChart');
    this.doughnutChartLabels = new Array();
    this.doughnutChartData = new Array();
    this.donutChartDto.donutChartDataDtos.forEach(item => {
      this.doughnutChartLabels.push(item.label);
      this.doughnutChartData.push(+item.value);
    });

    this.createChart();
    // ['Download Sales', 'In-Store Sales', 'Mail-Order Sales'];
    // doughnutChartData: number[] = [350, 450, 100];
  }

  createChart(){

    if (this.donutChart) {
      const context = this.donutChart.getContext('2d');

      this.chart = new Chart(context, {
        type: 'doughnut', //this denotes tha type of chart

        data: {// values on X-Axis
          labels: this.doughnutChartLabels,
          datasets: [{
            label: this.donutChartDto.title,
            data: this.doughnutChartData,
            hoverOffset: 4
          }],
        },
        options: {
          aspectRatio:2.5
        }

      });
    }

  }
}
