import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  ContextIdDto,
  CustomerSearchResultDto,
  PerformCustomerSearchResponse,
  PerformSearch,
  SearchService
} from '@savvy/search';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { Customer } from '@savvy/customer';
import { Subject, Subscription } from 'rxjs';
import { AddCustomerComponent } from 'src/app/flo/customer/customer/addCustomer.component';
import { CustomerStatsComponent } from '../../shared/customer-stats/customer-stats.component';
import { CreateAppointmentV2SyncService, PopulateNewCustomerEventData } from '../create-appointment-v2-sync.service';
export class SearchResult {
  label: string;
  value: any;
  type: string;
}
@Component({
  selector: 'app-customer-selector-v2',
  templateUrl: './customer-selector-v2.component.html',
  styleUrls: ['./customer-selector-v2.component.scss']
})
export class CustomerSelectorV2Component implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('customerInput', { static: false }) customerInput: ElementRef;
  @ViewChild('petAuto', { static: false }) petAuto;
  @ViewChild(CustomerStatsComponent) customerStatsComponent: CustomerStatsComponent;


  @Input() contextIdDto: ContextIdDto;
  @Input() multiple = false;
  @Input() quickAppointment = false;
  @Input() readOnly = false;
  @Output() customerSelected: EventEmitter<string> = new EventEmitter();

  customers: Array<CustomerSearchResultDto> = [];
  searchControl: UntypedFormControl = new UntypedFormControl();

  searchResults: SearchResult[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];

  searchSource = new Subject<SearchResult[]>();
  filteredSearchResults = this.searchSource.asObservable();
  loadingInner = false;

  subs: Subscription[] = [];

  customerId: string;

  constructor(
    private dialog: MatDialog,
    private searchService: SearchService,
    public createAppointmentV2SyncService: CreateAppointmentV2SyncService
  ) { }

  ngOnInit() {
    if (this.createAppointmentV2SyncService.customersSelected.length) {
      this.customerId = this.createAppointmentV2SyncService.customersSelected[0].id;
    }

    this.subs.push(this.createAppointmentV2SyncService.populateNewCustomer$.subscribe((customer: PopulateNewCustomerEventData) => {
      if (customer) {
        this.createAppointmentV2SyncService.customersSelectedChips.push(`<b>${customer.customerSearchResultDto.fullName}</b>`);
        this.createAppointmentV2SyncService.customersSelected.push(customer.customerSearchResultDto);
        if (!customer.populatingAppointment) {
          this.createAppointmentV2SyncService.updateCustomerSelection(this.createAppointmentV2SyncService.customersSelected);
        }
        this.customerId = customer.customerSearchResultDto.id;
      }
    }));

    this.searchControl.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged()
    ).subscribe((searchKey) => {
      this.loadingInner = true;

      if (!this.multiple && this.createAppointmentV2SyncService.customersSelectedChips.length === 1) {
        this.searchSource.next([]);
        this.loadingInner = false;
        return;
      }
      if (searchKey && searchKey.length > 0 && searchKey.length < 10) {
        console.log('value change detected, triggering new search', searchKey);
        this.search(searchKey);
      } else {
        this.loadingInner = false;
      }
    });

    if (!this.createAppointmentV2SyncService.editMode) {
      setTimeout(() => {
        this.customerInput.nativeElement.focus();
      }, 500);
    }
  }

  ngOnDestroy(): void {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    if (!this.createAppointmentV2SyncService.editMode) {
      setTimeout(() => {
        this.customerInput.nativeElement.focus();
      }, 500);
    }
  }

  gotoInstance(event: MatAutocompleteSelectedEvent) {
    if (event.option.value) {
      const optionSelected = event.option.value as SearchResult;
      if (optionSelected && optionSelected.type !== 'pet') {
        this.createAppointmentV2SyncService.customersSelectedChips.push(optionSelected.label);
        this.createAppointmentV2SyncService.customersSelected.push(optionSelected.value);
        this.createAppointmentV2SyncService.updateCustomerSelection(this.createAppointmentV2SyncService.customersSelected);
      }
      // Clear the input value
      this.customerInput.nativeElement.value = null;
      this.searchControl.setValue(null);
      this.searchResults = this.searchResults.filter(sr => sr.value !== optionSelected.value);
      this.searchSource.next(this.searchResults);
      this.customerId = this.createAppointmentV2SyncService.customersSelected[0].id;
      if (optionSelected?.value?.id) {
        this.customerSelected.emit(optionSelected.value.id);
      }
    }
  }

  remove(index: number) {
    this.createAppointmentV2SyncService.customersSelected.splice(index, 1);
    this.createAppointmentV2SyncService.customersSelectedChips.splice(index, 1);
    this.createAppointmentV2SyncService.updateCustomerSelection(this.createAppointmentV2SyncService.customersSelected);
    this.customerSelected.emit(undefined);
  }

  handleDuplicates() {
    if (this.createAppointmentV2SyncService.customersSelected.length) {
      this.customers = this.customers.filter(c => this.createAppointmentV2SyncService.customersSelected
        .findIndex(cs => cs.id === c.id) === -1);
    }
  }

  search(searchStr: string) {
    console.log('searching with ' + searchStr);

    const req: PerformSearch = {};
    req.contextIdDto = this.contextIdDto;
    req.searchString = searchStr;

    this.searchService.performCustomerSearch(req).subscribe((response: PerformCustomerSearchResponse) => {
      this.loadingInner = false;
      this.customers = response.customerSearchResultDtos;
      this.handleDuplicates();
      this.searchResults = [];
      for (const customer of this.customers) {
        this.searchResults.push({ label: `<b>${customer.fullName}</b>`, type: 'customer', value: customer });
      }
      this.searchSource.next(this.searchResults);
    });
  }

  createNewCustomer() {
    const createPetModal = this.dialog.open(AddCustomerComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        pageType: 'addCustomer',
        isViewMode: true,
        showAddPet: false
      },
      maxWidth: '1024px',
      // maxHeight: '80vh',
      // height: '80vh',
      panelClass: [
        'modal-100',
        'helpwindow',
        'scrollable-modal-2'
      ],
      autoFocus: false,
      disableClose: true
    });

    createPetModal.afterClosed()
      .subscribe(
        (newCustomer: Customer) => {
          if (newCustomer?.customerId?.id) {
            // this.createAppointmentV2SyncService.populateNewCustomer(newCustomer, this.contextIdDto.contextId);
            this.createAppointmentV2SyncService.customersSelectedChips = [];
            this.createAppointmentV2SyncService.customersSelected = [];

            const fullName = `${newCustomer?.firstName} ${newCustomer?.lastName}`;
            this.createAppointmentV2SyncService.customersSelectedChips.push(`<b>${newCustomer?.firstName} ${newCustomer?.lastName}</b>`);
            this.createAppointmentV2SyncService.customersSelected.push({
              id: newCustomer.customerId?.id,
              ownerId: this.contextIdDto.contextId,
              fullName,
              firstName: newCustomer?.firstName,
              lastName: newCustomer?.lastName,
              mobileInternational: newCustomer?.mobilePhoneNumberObj?.internationalNumber,
              mobileNational: newCustomer?.mobilePhoneNumberObj?.nationalNumber
            });
            this.createAppointmentV2SyncService.updateCustomerSelection(this.createAppointmentV2SyncService.customersSelected);

            this.customerId = newCustomer.customerId?.id;
            this.customerSelected.emit(this.customerId);

          }
        });
  }

}
