import { Component, Input } from '@angular/core';
import { ButtonDefinitionDto } from '@savvy/view-definition';

@Component({
    selector: 'app-button-instance',
    templateUrl: 'buttonInstance.component.html'
})
export class ButtonInstanceComponent {

    @Input() buttonDefinition: ButtonDefinitionDto;
}

