import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ChangeListener } from './changeListener';
import { NavigateService } from '../entry/NavigateService';
import { Page } from '../shared/model/page';
import { CachedtablecompService } from '@savvy/cached-view';
import { TrialExpiredService } from '../shared/services/trial-expired.service';
import { EntityInstanceId, ListCachedTableViewDataDto } from '@savvy/view-definition';
import { ContextIdDto } from '@savvy/quickbooks';
import { ViewContextDto } from '@savvy/view-composite';

@Component({
  selector: 'app-list-cached-entitys-table',
  templateUrl: 'listCachedEntitysTable.component.html'
})
export class ListCachedEntitysTableComponent implements OnInit, OnChanges {

  @Input() listCachedTableViewDataDto: ListCachedTableViewDataDto;
  @Input() contextIdDto: ContextIdDto;
  @Input() viewContext: any;
  @Input() form: UntypedFormGroup;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;

  page = new Page();
  selected: any[] = [];
  cachedTableRowPageDto: any;

  columns: any[] = [];
  rows = [];


  constructor(
    private router: Router,
    private naviateService: NavigateService,
    public trialExpiredService: TrialExpiredService,
    private cachedTableCompositeApi: CachedtablecompService) {
  }
  ngOnInit() {
    this.page.size = 10;
    this.initCols();
    this.setPage({ offset: 0 });
  }
  initCols() {
    this.columns = new Array();
    this.listCachedTableViewDataDto.columnNames.forEach(colName => {
      console.log('colName is ' + colName);
      this.columns.push(this.getCol(colName));
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.buildTable();
  }

  getCol(colDef: string) {
    const col = {};
    // console.log('colDef is ' + colDef);
    col['prop'] = colDef;
    col['name'] = colDef;
    return col;
  }

  setPage(pageInfo) {
    console.log('pageInfo is ' + pageInfo.offset);
    this.page.pageNumber = pageInfo.offset;
    this.loadPage();
  }

  loadPage() {
    if (this.listCachedTableViewDataDto.foreignKey) {
      this.cachedTableCompositeApi.listCachedEntityTableWithForeignKey(
        this.listCachedTableViewDataDto.listCachedEntityTableDefinition.cachedTableViewDefinitionId.id,
        this.listCachedTableViewDataDto.foreignKey, // foreign key
        (this.page.pageNumber + 1),
        this.page.size,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType).subscribe(response => {
          this.handleResponse(response);
        });
    } else {
      this.cachedTableCompositeApi.listCachedEntityTable(
        this.listCachedTableViewDataDto.listCachedEntityTableDefinition.cachedTableViewDefinitionId.id,
        (this.page.pageNumber + 1),
        this.page.size,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType).subscribe(response => {
          this.handleResponse(response);

        });
    }
  }
  handleResponse(response) {
    this.cachedTableRowPageDto = response.cachedTableRowPageDto;
    this.page.totalElements = response.cachedTableRowPageDto.totalElements;
    this.page.totalPages = response.cachedTableRowPageDto.totalPages;
    this.buildTable();
  }

  buildTable() {
    if (this.cachedTableRowPageDto && this.cachedTableRowPageDto.contents) {
      const newRows = new Array();
      for (const aRow of this.cachedTableRowPageDto.contents) {
        newRows.push(this.getRow(aRow));
      }
      console.log('rows is now ' + newRows.length);
      this.rows = newRows;
    }
  }

  getRow(cachedTableRow) {
    const row = {};
    cachedTableRow.values.forEach((item, index) => {
      console.log('row ' + index + ' is ' + item);
      row[this.listCachedTableViewDataDto.columnNames[index]] = item;
    });
    row['id'] = cachedTableRow.cachedTableRow.entityInstanceId;
    return row;
  }

  numRows() {
    return this.cachedTableRowPageDto.contents.length;
  }
  onSelect() {
    console.log('on select called ' + this.selected[0].id);
    this.naviateService.viewEi(this.contextIdDto, this.selected[0].id);
  }

  rowSelected(entityInstanceId: EntityInstanceId) {
    this.naviateService.viewEntity(this.contextIdDto, entityInstanceId);
  }

  create() {
    console.log('inside create in ListEntitysListComponent');
    if (this.viewContext.viewContextTypeDto === ViewContextDto.ViewContextTypeDtoEnum.Customer) {
      this.router.navigate(['/entity/createEntity',
        this.listCachedTableViewDataDto.listCachedEntityTableDefinition.entityDefinitionId.id,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.viewContext.customerContextDto.customerId.id
      ]);
    } else {
      this.router.navigate(['/entity/createEntity',
        this.listCachedTableViewDataDto.listCachedEntityTableDefinition.entityDefinitionId.id,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.viewContext.entityInstanceId.id,
        this.viewContext.entityDefinitionId.id
      ]);
    }
  }
}

