<mat-form-field appearance="outline" class="w-100">
  <mat-label>
    <span [ngClass]="{'mat-text-red': selectedService.deleted}">
      {{ 'SERVICE' | translate: {Default: 'Service'} }}
    </span>
  </mat-label>
  <mat-select id="appointment-service-selector"
    placeholder="{{'SELECT_SERVICE' | translate: {Default: 'Select Service'} }}" [(ngModel)]="selectedService" required
    name="serviceField" #serviceField="ngModel" (selectionChange)="serviceChanged($event)"
    [compareWith]="compareObjects">
    <mat-option>
      <ngx-mat-select-search [placeholderLabel]="'Search Service'" ngModel (ngModelChange)="filterServices($event)">
      </ngx-mat-select-search>
    </mat-option>
    <mat-option [value]="''">
      <button mat-flat-button color="accent" (click)="createNewService()">
        {{'ADD_SERVICE' | translate: {Default: 'Add Service'} }}
      </button>
    </mat-option>

    <mat-option *ngFor="let service of filteredServices | async" [value]="service" [disabled]="service.deleted">
      <span [ngClass]="{'mat-text-red': service.deleted}">{{ service?.serviceName }}</span>
    </mat-option>
  </mat-select>
  <!-- <mat-error *ngIf="serviceField.hasError('required') && !selectedService?.deleted">
    {{'FORM_REQUIRED_PLACEHOLDER' | translate}}
  </mat-error> -->
  <mat-error *ngIf="selectedService?.deleted">
    {{'SERVICE' | translate: {Default: 'Service'} }} <strong>{{selectedService?.serviceName | translate}}</strong> is no
    more available
  </mat-error>
  <mat-icon *ngIf="selectedService?.deleted" matSuffix class="mat-text-red"
    matTooltip="{{selectedService?.serviceName}} with id {{selectedService.id}} has been deleted"
    matTooltipClass="tooltip-red" class="vm mat-text-red">error_outline</mat-icon>
</mat-form-field>