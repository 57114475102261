<div fxLayout="row" *ngIf="initialised">

  <mat-form-field appearance="outline" style="width: 100%;">
    <mat-label>{{entityDefinitionLabel | translate}}</mat-label>

    <input matInput [required]="true" [matAutocomplete]="eiAuto" [(ngModel)]="entityInstanceIdField"
      name="entityInstanceId" placeholder="Start typing to select {{entityDefinitionLabel}}" #entityInstanceId="ngModel"
      (keyup)="onKey($event)">

    <mat-autocomplete #eiAuto="matAutocomplete" [displayWith]="displayFn.bind(this)" autoActiveFirstOption>

      <mat-option *ngFor="let tuple of entityInstanceIdFieldValueTupleDtos"
        (onSelectionChange)="changedSelection(tuple.id)" [value]="tuple.id">
        {{ tuple.name }}
      </mat-option>

      <mat-option *ngIf="entityInstanceIdFieldValueTupleDtos">
        <button class="add-new-field" mat-raised-button color="accent" (click)="createNewLinkedEntity()">Add new
          {{ entityDefinitionLabel }}</button>
      </mat-option>
    </mat-autocomplete>

    <mat-error *ngIf="entityInstanceId.hasError('required')">
      {{entityDefinitionLabel + ' is' | translate}} <strong>{{'required' | translate}}</strong>
    </mat-error>
    <mat-error *ngIf="!entityInstanceId.hasError('required') && entityInstanceId.hasError('doesNotSelected')">
      {{entityDefinitionLabel + ' is' | translate}} <strong>{{'required' | translate}}</strong>.
      {{'Please Select one of the choices or create it' | translate}}.
    </mat-error>

  </mat-form-field>
</div>
<app-show-ei-status [contextIdDto]="contextIdDto" [linkId]="eiAsLinkId"></app-show-ei-status>
