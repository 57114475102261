<mat-toolbar *ngIf="createEmbeddedComponent && createEmbeddedComponent.loadEiViewResponse">
  <mat-toolbar-row>{{'Create ' + entityDefinitionLabel | translate}}</mat-toolbar-row>
</mat-toolbar>
<mat-dialog-content>
  <app-create-embedded-entity-component #createEmbeddedComponent [contextIdDto]="contextIdDto"
                                        [entityDefinitionId]="entityDefinitionId"
                                        [eventBus]="eventBus"
                                        (created)="entityCreated($event); dialogRef.close(data)"
                                        (cancelClicked)="dialogRef.close(data)">

  </app-create-embedded-entity-component>
</mat-dialog-content>

