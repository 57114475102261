import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ChangeListener } from './changeListener';
import { EventBusService } from './EventBusService';
import { ViewContextDto } from '@savvy/view-composite';
import { AppointmentPanelDataDto, ContextIdDto } from '@savvy/view-definition';
@Component({
  selector: 'app-view-appointment-panel',
  templateUrl: 'viewAppointmentPanel.component.html',
  styleUrls: ['viewAppointmentPanel.component.scss', 'commonPanel.component.scss']
})
export class ViewAppointmentPanelComponent implements OnInit {

  constructor() { }

  @Input() appointmentPanelData: AppointmentPanelDataDto;
  @Input() contextIdDto: ContextIdDto;
  @Input() viewContext: ViewContextDto;
  @Input() form: UntypedFormGroup;
  @Input() eventBus: EventBusService;
  @Input() additionalDataMapDto: any;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;

  ngOnInit() {
    console.log(12, this.appointmentPanelData);
  }
}

