import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { FloMaterialModule } from '../flo.material.module';

import { DiscountSettingsService } from '@savvy/invoice';

import { DiscountSettingsRoutes } from './discount.routing';
import { DiscountSettingsComponent } from './discount-settings/discount-settings.component';
import { DiscountItemComponent } from './discount-settings/discount-item/discount-item.component';
import { ProductCompositeService } from '@savvy/products';
import { ServicesCompositeService } from '@savvy/services';
import { TranslateModule } from '@ngx-translate/core';
import { ViewDiscountsComponent } from './view-discounts/view-discounts.component';
import { ViewDiscountsModalComponent } from './view-discounts/view-discounts-modal.component';
import { DiscountPriceViewComponent } from './discount-price-view/discount-price-view.component';


@NgModule({
  declarations: [
    DiscountSettingsComponent,
    DiscountItemComponent,
    ViewDiscountsComponent,
    ViewDiscountsModalComponent,
    // DiscountPriceViewComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(DiscountSettingsRoutes),
    FloMaterialModule,
    SharedModule,
    TranslateModule,
  ],
  exports: [
    DiscountSettingsComponent,
    DiscountItemComponent,
    ViewDiscountsComponent,
    ViewDiscountsModalComponent,
    DiscountPriceViewComponent,
  ],
  providers: [
    DiscountSettingsService,
    ProductCompositeService,
    ServicesCompositeService,
  ]
})
export class DiscountModule { }
