<div fxLayout>
  <div fxFlex="nogrow">
    <div class="avatar avatar-50 circle border-3">
      <mat-icon>person_search</mat-icon>
    </div>
  </div>
  <div fxFlex>
    <mat-form-field appearance="outline" class="padding-left-half mt-0 w-100">
      <mat-label>{{'SELECT_CUSTOMER' | translate: {Default: 'Select Customer'} }}</mat-label>
      <mat-chip-grid #chipList aria-label="Customer selection" [disabled]="readOnly">
        <mat-chip-row
          *ngFor="let customer of createAppointmentV2SyncService.customersSelectedChips; let rowIndex = index"
          (removed)="remove(rowIndex)">
          <span [innerHTML]="customer | translate"></span>
          <button matChipRemove *ngIf="!readOnly">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
        <input placeholder="{{'TYPE_CUSTOMER_NAME' | translate: {Default: 'Type customer name...'} }}"
          [hidden]="!multiple && createAppointmentV2SyncService.customersSelectedChips.length" #customerInput
          [formControl]="searchControl" [matAutocomplete]="customerAuto" [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [readonly]="readOnly">
      </mat-chip-grid>
      <mat-autocomplete #customerAuto="matAutocomplete" (optionSelected)="gotoInstance($event)">
        <mat-option [value]="''">
          <button type="button" mat-raised-button color="accent" (click)="createNewCustomer()">
            {{'CREATE_CUSTOMER' | translate: {Default: 'Create Customer'} }}
          </button>
        </mat-option>
        <mat-option class="loading-inner" disabled *ngIf="loadingInner">
          <mat-spinner diameter="35"></mat-spinner>
        </mat-option>
        <mat-option disabled *ngIf="searchResults?.length === 0 && !loadingInner && searchControl.value">
          {{'NO_DATA_FOUND' | translate: {Default: 'No Data Found'} }}
        </mat-option>
        <mat-option *ngFor="let option of filteredSearchResults | async" [value]="option"
          [innerHTML]="option.label | translate">
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <app-customer-stats #customerStatsComponent
      *ngIf="createAppointmentV2SyncService.customersSelected.length && !quickAppointment" [contextIdDto]="contextIdDto"
      [customerId]="customerId">
    </app-customer-stats>

  </div>
</div>
