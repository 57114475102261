import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ContextIdDto, NewsDto } from '@savvy/view-definition';
import { NavigateService } from '../entry/NavigateService';

@Component({
  selector: 'app-news-widget',
  templateUrl: 'newsWidget.component.html'
})
export class NewsWidgetComponent {

  @Input() newsDto: NewsDto;
  @Input() contextIdDto: ContextIdDto;

  constructor(router: Router, private nav: NavigateService) {
  }

  go() {
    console.log('reuse logic from menu to construct');
    // use NavigateService, and pull logic from menu factory to navigate properly
    // this.nav.navigateTo(this.contextIdDto, this.newsDto.badgeDefinition.menuDefinition);
  }
}

