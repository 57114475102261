import { Injectable } from '@angular/core';
import { OrgCompositeService } from '@savvy/org';
import { ContextIdDto } from '@savvy/user';

@Injectable({
  providedIn: 'root'
})
export class UserCurrencyService {

  currencyCode: string;

  public defaultCurrency = '';

  constructor(private orgCompositeApiExtension: OrgCompositeService) {
  }

  getCurrency() {
    return this.currencyCode;
  }

  setCurrencyCode(currencyCode: string) {
    this.currencyCode = currencyCode;
  }

  getDefaultCurrency(contextIdDto: ContextIdDto) {
    if (contextIdDto?.contextId) {
      return this.orgCompositeApiExtension.loadOrgFromCtx(contextIdDto.contextId);
    }
  }
}

