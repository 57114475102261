import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { RepeatCalendarComponent } from './repeat-calendar/repeat-calendar.component';
import { AppointmentDateLocal, RepeatDates, RepeatFilterComponent } from './repeat-filter/repeat-filter.component';

@Component({
  selector: 'app-repeat-appointments',
  templateUrl: './repeat-appointments.component.html',
  styleUrls: ['./repeat-appointments.component.scss']
})
export class RepeatAppointmentsComponent implements OnInit {
  @Input() selectedDay: string;
  @Input() startTime: string;
  @Input() startDate: string;

  @ViewChild(RepeatCalendarComponent, { static: false }) repeatCalendarComponent: RepeatCalendarComponent;
  @ViewChild(RepeatFilterComponent, { static: false }) repeatFilterComponent: RepeatFilterComponent;
  @Output() checkConflictApi: EventEmitter<AppointmentDateLocal[]> = new EventEmitter();
  appointmentDates: AppointmentDateLocal[] = [];
  isDateGenerated = false;

  constructor() { }

  ngOnInit(): void {
  }

  generated(result: RepeatDates) {
    if (result?.appointmentDates) {
      this.isDateGenerated = true;
      this.appointmentDates = result?.appointmentDates;
      setTimeout(() => {
        this.repeatCalendarComponent.dateGenerated(result?.appointmentDates, result?.repeatFilter);
        this.checkConflictApi.emit(result?.appointmentDates);
      }, 1000);
    }
  }

  public getAppointmentDates() {
    return this.repeatCalendarComponent?.getAppointmentDates();
  }

  checkConflict(appointmentDate: AppointmentDateLocal[]) {
    this.checkConflictApi.emit(appointmentDate);
  }

  notifyConflict(appointmentDate: AppointmentDateLocal[]) {
    this.repeatCalendarComponent.notifyConflict(appointmentDate);
  }

}
