import { Component, Input, OnInit } from '@angular/core';
import { ContextIdDto } from '@savvy/app';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PhoneNumberHelperService } from 'src/app/flo/shared/services/phone-number-helper.service';
import { UserCurrencyService } from 'src/app/flo/shared/services/userCurrency.service';
import { CreateBoardingBookingV2SyncService } from '../create-boarding-booking-v2-sync.service';
@Component({
  selector: 'app-pet-confirm-boarding-v2',
  templateUrl: './confirm-pet-boarding-v2.component.html',
  styleUrls: ['./confirm-pet-boarding-v2.component.scss']
})
export class ConfirmPetBoardingV2Component implements OnInit {

  @Input() pickupRequired = false;
  @Input() contextIdDto: ContextIdDto;
  destroy$ = new Subject();

  currencyCode = 'GBP';

  constructor(
    private sharedService: PhoneNumberHelperService,
    public createBoardingBookingV2SyncService: CreateBoardingBookingV2SyncService,
    private userCurrencyService: UserCurrencyService
  ) { }

  ngOnInit(): void {
    this.getCurrencyCode();
  }

  getCurrencyCode() {
    this.userCurrencyService.getDefaultCurrency(this.contextIdDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.currencyCode = res.org.currencyCode ? res.org.currencyCode : this.userCurrencyService.defaultCurrency;
      });
  }

}
