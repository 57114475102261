import { Component, Inject, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';
import { Subject } from 'rxjs';
import { ContextIdDto } from '@savvy/view-definition';
import { UserId } from '@savvy/user';
import {CustomerId, CustomerService} from '@savvy/customer';
import { CustomerCompositeService, SetCustomerPassword } from '@savvy/customer';

@Component({
  selector: 'app-reset-customer-password-modal',
  templateUrl: './reset-customer-password-modal.component.html',
  styleUrls: ['./reset-customer-password-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ResetCustomerPasswordModalComponent implements OnDestroy {
  password: string;

  private destroy$ = new Subject();

  sending = false;

  // Passed in data
  contextIdDto: ContextIdDto;
  customerId: CustomerId;
  userId: UserId;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ResetCustomerPasswordModalComponent>,
    private notify: FloSnackbarComponent,
    private api: CustomerService
  ) {


    this.contextIdDto = data.contextIdDto;

    this.customerId = null;
    this.userId = null;


    if (data.customerId) {
      this.customerId = data.customerId;
    }
    if (data.userId) {
      this.userId = data.userId;
    }

  }

  reset() {


    if (!this.password) {
      this.notify.message = 'No password entered';
      this.notify.open();
      return;
    }

    if (this.customerId) {

      const req = <SetCustomerPassword>{};
      req.contextIdDto = this.contextIdDto;
      req.customerId = this.customerId.id;
      req.newPassword = this.password;

      this.api.setCustomerPassword(req).subscribe(response => {
        this.notify.message = 'Successfully reset Customer password';
        this.notify.open();
        this.close();
      });
    }
  }



  close() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
