import {Injectable} from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class DateUtils {

  MONTH_NAMES = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];


  getMonthLabel(d) {
    return this.MONTH_NAMES[d.getMonth()];
  }

  getNextMonday(): Date {
    return this.setWeekDay(new Date(), 1);
  }

  setWeekDay(startDate, dayINeed): Date {
    const today = moment().isoWeekday();

    // if we haven't yet passed the day of the week that I need:
    if (today <= dayINeed) {
      // then just give me this week's instance of that day
      return moment(startDate).isoWeekday(dayINeed).toDate();
    } else {
      // otherwise, give me *next week's* instance of that same day
      return moment(startDate).add(1, 'weeks').isoWeekday(dayINeed).toDate();
    }
  }

  setTime(startDate, hours: number, mins: number) {
    let endDateMoment = moment(startDate); // moment(...) can also be used to parse dates in string format
    endDateMoment = endDateMoment.hour(hours);
    endDateMoment = endDateMoment.minute(mins);
    return endDateMoment.toDate();
  }

  addMonths(startDate, numMonths: number) {
    const endDateMoment = moment(startDate); // moment(...) can also be used to parse dates in string format
    endDateMoment.add(numMonths, 'months');
    return endDateMoment.toDate();
  }

  addHours(startDate, numHours: number) {
    const endDateMoment = moment(startDate); // moment(...) can also be used to parse dates in string format
    endDateMoment.add(numHours, 'hours');
    return endDateMoment.toDate();
  }

  addMins(startDate, numMinutes: number) {
    const endDateMoment = moment(startDate); // moment(...) can also be used to parse dates in string format
    endDateMoment.add(numMinutes, 'minutes');
    return endDateMoment.toDate();
  }

  /**
   * @description returns time string adding hours to the time
   * @author Amit Mahida
   * @param {string} time
   * @param {number} numHours
   * @return {*}  {string}
   * @memberof DateUtils
   */
  addHoursToTime(time: string, numHours: number): string {
    return moment(time, 'HH:mm').add(numHours, 'hours').format('HH:mm');
  }

  addMinsToLocalTime(localTime, numMinutes: number) {
    const date = new Date();
    date.setHours(localTime.hour);
    date.setMinutes(localTime.minute);
    date.setSeconds(localTime.second);
    const newDate = moment(date).add(numMinutes, 'm').toDate();
    localTime.hour = newDate.getHours();
    localTime.minute = newDate.getMinutes();
    return localTime;
  }

  addMinsToTimeString(timeStr: string, numMinutes: number) {
    // console.log('timeStr' + timeStr);
    const date = new Date();
    if (timeStr) {
      const hour = Number(timeStr.slice(0, 2));
      const min = Number(timeStr.slice(3, 5));
      // console.log('hour' + hour);
      // console.log('min' + min);

      date.setHours(hour);
      date.setMinutes(min);
    }
    const newDate = moment(date).add(numMinutes, 'm').toDate();
    return this.getTimeAsString(newDate);
  }

  subtractMinsToTimeString(timeStr: string, numMinutes: number) {
    // console.log('timeStr' + timeStr);
    const hour = Number(timeStr.slice(0, 2));
    const min = Number(timeStr.slice(3, 5));
    // console.log('hour' + hour);
    // console.log('min' + min);

    const date = new Date();
    date.setHours(hour);
    date.setMinutes(min);
    const newDate = moment(date).subtract(numMinutes, 'm').toDate();
    return this.getTimeAsString(newDate);
  }

  addDays(startDate, numDays: number) {
    const endDateMoment = moment(startDate); // moment(...) can also be used to parse dates in string format
    endDateMoment.add(numDays, 'days');
    return endDateMoment.toDate();
  }

  minusDays(startDate, numDays: number) {
    const endDateMoment = moment(startDate); // moment(...) can also be used to parse dates in string format
    endDateMoment.subtract(numDays, 'days');
    return endDateMoment.toDate();
  }

  subtractMonths(startDate, numMonths: number) {
    const endDateMoment = moment(startDate); // moment(...) can also be used to parse dates in string format
    endDateMoment.subtract(numMonths, 'months');
    return endDateMoment.toDate();
  }

  getLocalDate(date: Date) {
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() + userTimezoneOffset);
  }

  getLocalDateV2(date: Date) {
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() - userTimezoneOffset);
  }

  getDate(localDateTime) {
    // // // console.log('getting date 2 ' + localDateTime.constructor.name);
    // // console.log('getting date with ' + localDateTime);
    if (localDateTime) {
      // 2015,12,21,13,45
      const year = localDateTime[0];
      const month = localDateTime[1] - 1;
      const day = localDateTime[2];
      const hour = localDateTime[3];
      const min = localDateTime[4];

      // // // console.log("getting date with year " + year + ' month ' + month + ' day ' +
      //  day + ' hour ' + hour + " min " + min);
      const aDate = new Date(year, month, day, hour, min, 0, 0);
      // // // console.log("created date with " + aDate);
      return aDate;
    }
    // console.log('get date called with null date, so just creating new instance');
    return new Date();
  }

  getDateAsStringV2(aDate: Date | string | moment.Moment): string {
    // console.log('getting date 2 ' + aDate.constructor.name);
    const endDateMoment = moment(aDate); // moment(...) can also be used to parse dates in string format
    return endDateMoment.format('YYYY-MM-DD');
  }


  // 2022-03-11
  convertDateStringToJsDate(str: string): Date {
    return moment(str, 'YYYY-MM-DD').toDate();
  }

  // this.dateUtils.getDateTimeAsString(createData.startDateToUseOnCreate)
  getDateTimeAsString(aDate: Date): string {

    // console.log('getDateTimeAsString ' + aDate);

    // Mon Apr 26 2021 08:00:00 GMT-0400

    if (!aDate) {
      return null;
    }

    // SO HERE, WE DO WANT TO PASS THE DATE IN UTC

    const dateAsMoment = moment(aDate).utc(false); // moment(...) can also be used to parse dates in string format
    //  const dateAsMomentTest = moment(aDate).utc(false).format("YYYY-MM-DD HH:mm"); // moment(...) can also be used to parse dates in string format
    //  alert('date' + dateAsMomentTest);


    //  2020-10-22T13:30:00.000Z
    return dateAsMoment.format('YYYY-MM-DD') + 'T' + dateAsMoment.format('HH:mm:SS') + '.000Z';
  }

  getDateTimeAsIsoString(aDate: Date, utc?: boolean): string {

    // console.log('getDateTimeAsString ' + aDate);

    // Mon Apr 26 2021 08:00:00 GMT-0400

    if (!aDate) {
      return null;
    }

    const dateAsMoment = moment(aDate).utc(utc); // moment(...) can also be used to parse dates in string format

    //  2020-10-22T13:30:00.000Z
    return dateAsMoment.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  }

  getDateAsIsoString(aDate): string {
    // console.log('getting date 2 ' + aDate.constructor.name);
    const endDateMoment = moment(aDate); // moment(...) can also be used to parse dates in string format
    return endDateMoment.format('YYYYMMDD');
  }

  getTimeStrToDate(timeStr: string) {

    const hour = Number(timeStr.slice(0, 2));
    const min = Number(timeStr.slice(3, 5));
    // console.log('hour' + hour);
    // console.log('min' + min);

    const date = new Date();
    date.setHours(hour);
    date.setMinutes(min);
    return date;
  }

  getTimeAsString(aDate): string {
    const endDateMoment = moment(aDate); // moment(...) can also be used to parse dates in string format
    return endDateMoment.format('HH:mm');
  }

  getTimeAsStringAMPMUtc(aDate: string): string {
    const endDateMoment = moment(new Date(aDate).toISOString()); // moment(...) can also be used to parse dates in string format
    return endDateMoment.utc().format('hh:mm a');
  }

  pad(num: number, size: number): string {
    let s = num + '';
    while (s.length < size) {
      s = '0' + s;
    }
    return s;
  }

  getDateAsStringDash(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    // // console.log('date was ', date);
    // // console.log('full year is ', d.getFullYear());


    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  /** 2018-10-31T14:00:00.000Z */
  getDateFromString(localDateTime: string) {
    // // console.log('getting date 2 ' + localDateTime.constructor.name);
    // // console.log('getting date ' + localDateTime);
    // 2015,12,21,13,45
    const year = Number(localDateTime.slice(0, 4));
    const month = Number(localDateTime.slice(5, 7));
    const day = Number(localDateTime.slice(8, 10));
    const hour = Number(localDateTime.slice(11, 13));
    const min = Number(localDateTime.slice(14, 16));
    // // console.log('getting date with year ' + year + ' month ' + month + ' day ' +
    //  day + ' hour ' + hour + ' min ' + min);
    const aDate = new Date(year, (month - 1), day, hour, min, 0, 0);
    // // console.log('created date with ' + aDate);
    return aDate;
  }

  getCombinedDateObject(date: string, time: string) {
    let calculatedDateString;
    if (date && time) {
      // const utcDate = moment.utc(date);
      // const utcTime = moment.utc(time);
      calculatedDateString = moment(`${date} ${time}`);
    }
    const finalDateTime = new Date(calculatedDateString);
    if (isNaN(finalDateTime.getTime())) {
      return new Date();
    } else {
      return finalDateTime;
    }
  }

  getDurationInMins(startTime: string, endTime: string) {
    const duration = moment.duration(moment(this.getTimeStrToDate(endTime)).diff(moment(this.getTimeStrToDate(startTime))));
    return Math.ceil(duration.asMinutes());
  }

  getDurationInMinsFromDates(startTime: Date, endTime: Date) {
    const duration = moment.duration(moment(endTime).diff(moment(startTime)));
    return Math.ceil(duration.asMinutes());
  }

  getDurationInHours(startTime: string, endTime: string) {
    const duration = moment.duration(moment(this.getTimeStrToDate(endTime)).diff(moment(this.getTimeStrToDate(startTime))));
    return duration.asHours();
  }

  getDurationInHoursFromDates(startDate: Date, endDate: Date) {
    const duration = moment.duration(moment(endDate).diff(moment(startDate)));
    return duration.asHours();
  }

  getDurationInDays(startDate: Date, endDate: Date) {
    const duration = moment.duration(moment(endDate).endOf('day').diff(moment(startDate).startOf('day')));
    return Math.floor(duration.asDays());
  }

  dateFromTime(dStr) {
    const now = new Date();
    const split = dStr.split(':');
    now.setHours(split[0] ? split[0] : 0);
    now.setMinutes(split[1] ? split[1] : 0);
    now.setSeconds(split[2] ? split[2] : 0);
    return now;
  }
}
