import { Injectable } from '@angular/core';
import { DataListDto, DatalistService } from '@savvy/datalist';
import { MenucompService, MenuDefinition } from '@savvy/menu';
import { Address, OrgCompositeService, OrgDto, OrgService, PhoneNumber } from '@savvy/org';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { APP_TYPE_ENUM, ContextService } from '../context.service';
import { AddOnsHelperService } from '../shared/services/add-ons-helper.service';

@Injectable({
  providedIn: 'root'
})
export class AdminSettingsService {
  public adminMenuItems: Array<MenuDefinition> = [];
  public subMenuItems: Array<MenuDefinition> = [];
  public selectedMenuItemType: MenuDefinition.MenuItemTypeEnum;
  public menuItemTypeEnum = MenuDefinition.MenuItemTypeEnum;

  selectedMenuItemTypeSource: BehaviorSubject<MenuDefinition[]> = new BehaviorSubject([]);
  public selectedMenuItemType$: Observable<MenuDefinition[]> = this.selectedMenuItemTypeSource.asObservable();

  org: OrgDto;

  constructor(
    private menucompService: MenucompService,
    private datalistService: DatalistService,
    private orgCompositeService: OrgCompositeService,
    private orgService: OrgService,
    private contextService: ContextService,
    private addOnsHelperService: AddOnsHelperService

  ) { }

  loadAdminMenu(contextIdDto): Promise<Array<MenuDefinition>> {
    return new Promise((resolve) => {
      this.menucompService.loadAdminMenuComp(contextIdDto.contextId, contextIdDto.contextIdType)
        .subscribe(menuStructure => {
          if (menuStructure?.menuDefinitionList?.length) {
            this.addOnsHelperService.addOns$.subscribe(addOns => {
              this.contextService.appType$.subscribe(appType => {
                if (appType === APP_TYPE_ENUM.PET_GROOMING_SALON
                  && !this.addOnsHelperService.addOnPresent('Day Care')) {
                  this.adminMenuItems = menuStructure.menuDefinitionList.filter(am => am.menuItemType !== this.menuItemTypeEnum.PetRuns);
                } else {
                  this.adminMenuItems = menuStructure.menuDefinitionList;
                }
                this.adminMenuItems = this.adminMenuItems?.filter(am => am.menuItemType !== this.menuItemTypeEnum.Tax);
              });
            });
            resolve(this.adminMenuItems);
          }
        });
    });
  }

  setCurrentMenu(selectedMenuItemType: MenuDefinition.MenuItemTypeEnum) {
    this.selectedMenuItemType = selectedMenuItemType;
    sessionStorage.setItem('selectedMenuItemType', this.selectedMenuItemType);
    const adminMenuItem = this.adminMenuItems.find(md => md.menuItemType === selectedMenuItemType);
    if (adminMenuItem) {
      this.subMenuItems = adminMenuItem.menuDefinitions;
      this.selectedMenuItemTypeSource.next(this.subMenuItems);
    }
  }

  getDataListById(contextId: string, contextIdType: string, id: string): Promise<DataListDto> {
    return new Promise((resolve) => {
      this.datalistService.getDataList(contextId, contextIdType, id).subscribe(res => {
        resolve(res);
      });
    });
  }

  loadOrg() {
    return new Promise((resolve) => {
      this.orgCompositeService.getCurrentOrgComp().subscribe(
        org => {
          console.log('got org');
          if (!org.address) {
            org.address = {} as Address;
          }
          if (!org.orgSocialMedia) {
            org.orgSocialMedia = {};
          }
          this.org = org;
          if (!this.org?.phoneNumber) {
            this.org.phoneNumber = {} as PhoneNumber;
          }
          if (!this.org?.phoneNumber2) {
            this.org.phoneNumber2 = {} as PhoneNumber;
          }
          resolve(org);
        }
      );
    });
  }

  updateOrg() {
    this.orgService.updateOrg(this.org.id, this.org).subscribe(
      org => {
        console.log('got org');
        if (!org.address) {
          org.address = {} as Address;
        }
        if (!org.orgSocialMedia) {
          org.orgSocialMedia = {};
        }
        this.org = org;
      }
    );
  }

}
