import { Injectable } from '@angular/core';
import { GLOBAL } from 'src/app/app.constants';
import { WindowRef } from '../../windowWrapper';

@Injectable({
  providedIn: 'root'
})
export class UrlLookupService {

  constructor(private windowRef: WindowRef) {
  }

  portalUrl() {
    if (this.getEnv() == '') {
      // Prod
      return 'https://portal.itsallsavvy.com';
    } else {
      return 'https://devportal.itsallsavvy.com';
    }
  }

  invoiceUrl() {
    if (this.getEnv() == '') {
      // Prod
      return 'https://savvyinvoice.itsallsavvy.com';
    } else {
      return 'https://devsavvyinvoice.itsallsavvy.com';
    }
  }

  customerPortalUrl() {
    if (this.getEnv() == '') {
      // Prod
      return 'https://cportal.itsallsavvy.com';
    } else {
      return 'https://devcportal.itsallsavvy.com';
    }
  }

  customerRegistrationUrl() {
    if (this.getEnv() == '') {
      // Prod
      return 'https://registration.itsallsavvy.com';
    } else {
      return 'https://devregistration.itsallsavvy.com';
    }
  }

  directoryUrl() {
    if (this.getEnv() == '') {
      // Prod
      return 'https://booking.itsallsavvy.com/';
    } else {
      return 'https://booking.itsallsavvy.com/';
    }
  }


  cloudfrontUrl() {
    if (this.getEnv() == '') {
      // Prod
      return 'https://storage.itsallsavvy.com';
    } else {
      return 'https://devstorage.itsallsavvy.com';
    }
  }

  galleryUrl() {
    if (this.getEnv() == '') {
      // Prod
      return 'https://images.itsallsavvy.com';
    } else {
      return 'https://devimages.itsallsavvy.com';
    }
  }

  shopUrl() {
    if (this.getEnv() == '') {
      // Prod
      return 'https://shop.itsallsavvy.com';
    } else {
      return 'https://devshop.itsallsavvy.com';
    }
  }

  getEnv() {
    if (GLOBAL.localhost === 'localhost') {
      return '&env=dev';
    } else if (GLOBAL.localhost.startsWith('dev')) {
      return '&env=dev';
    }
    return '';
  }

  open(url) {
    const nativeWindow = this.windowRef.nativeWindow;
    nativeWindow.location.href = url;
  }

  openNewTab(url) {
    const nativeWindow = this.windowRef.nativeWindow;
    nativeWindow.open(url, '_blank');
  }
}
