<mat-card appearance="outlined">
  <mat-card-title>{{'SNACK_BAR' | translate}}</mat-card-title>
  <mat-card-subtitle>{{'SNACK_BAR_DESC' | translate}}</mat-card-subtitle>
  <mat-card-content>
    <p>Message: <mat-form-field><input matInput type="text" [(ngModel)]="message"></mat-form-field></p>
    <mat-checkbox [(ngModel)]="action">
      <p *ngIf="!action">{{'SHOW_SNACKBAR_BUTTON' | translate}}</p>
      <mat-form-field *ngIf="action">
        <input matInput
               type="text"
               class="demo-button-label-input"
               placeholder="{{'SNACKBAR_ACTION_LABEL' | translate}}"
               [(ngModel)]="actionButtonLabel">
      </mat-form-field>
    </mat-checkbox>

    <mat-checkbox [(ngModel)]="setAutoHide">
      <p *ngIf="!setAutoHide">{{'AUTO_HIDE_AFTER_DURATION' | translate}}</p>
      <mat-form-field *ngIf="setAutoHide">
        <input matInput
               type="text" appNumberOnly
               class="demo-button-label-input"
               placeholder="{{'AUTO_HIDE_DURATION_LABEL' | translate}}"
               [(ngModel)]="autoHide">
      </mat-form-field>
    </mat-checkbox>
    <p><mat-checkbox [(ngModel)]="addExtraClass">{{'ADD_EXTRA_CLASS' | translate}}</mat-checkbox></p>
    <button mat-raised-button (click)="open()">{{'SHOW_SNACKBAR' | translate}}</button>
  </mat-card-content>
</mat-card>
