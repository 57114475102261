import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  CreateInvoice,
  Invoice,
  InvoiceOwnerId,
  InvoiceProductRow,
  InvoiceService,
  LinkId,
  UpdateInvoice
} from '@savvy/invoice';
import { CreateEmptyInvoice, InvoicecompService } from '@savvy/invoice';
import { Product, ProductService } from '@savvy/products';
import { ContextIdDto } from '@savvy/quickbooks';
import { CustomerId } from '@savvy/quote';
import { LinkedInstanceDto } from '@savvy/invoice';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';
import { PhoneNumberHelperService } from '../../shared/services/phone-number-helper.service';
import { ConfirmationModalComponent } from '../../shared/components/confirmation-modal/confirmation-modal.component';
import { ViewDiscountsModalComponent } from '../../discount/view-discounts/view-discounts-modal.component';
import { UserCurrencyService } from '../../shared/services/userCurrency.service';
import { LanguageService } from 'src/app/services/language.service';

class EditInvoiceModalData {
  invoice: Invoice;
  contextIdDto: ContextIdDto;
}

@Component({
  selector: 'app-edit-invoice-v2',
  templateUrl: './edit-invoice-v2.component.html',
  styleUrls: ['./edit-invoice-v2.component.scss']
})
export class EditInvoiceV2Component implements OnInit, OnDestroy {
  invoice: Invoice = null;
  products: Array<Product>;

  linkedId: string;
  linkedEdId: string = null;
  linkedIdType: string;

  orderId: string; // Optional

  currencyCode: string;

  destroy$ = new Subject();
  searchProduct = '';
  private sub: any;


  constructor(
    public dialogRef: MatDialogRef<EditInvoiceV2Component>,
    @Inject(MAT_DIALOG_DATA) public data: EditInvoiceModalData,
    private dialog: MatDialog,
    private router: Router,
    private invoiceService: InvoiceService,
    private invoicecompService: InvoicecompService,
    private translateService: TranslateService,
    private productService: ProductService,
    private adapter: DateAdapter<any>,
    private notify: FloSnackbarComponent,
    private sharedService: PhoneNumberHelperService,
    private userCurrencyService: UserCurrencyService,
    private languageService: LanguageService
  ) {
    if (this.languageService.currentLocale) {
      this.adapter.setLocale(this.languageService.currentLocale);
    }
  }

  ngOnInit() {
    this.languageService.currentLocale$
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        if (res) {
          this.adapter.setLocale(res);
        }
      });
    console.log('inside EditInvoiceV2Component onInit');
    if (this.data.invoice && this.data.contextIdDto) {
      this.invoice = this.data.invoice;
      this.getCurrencyCode();
      this.loadProducts();
      // This is a bit specific
      // this.createEmpty();
    }
  }

  getCurrencyCode() {
    this.userCurrencyService.getDefaultCurrency(this.data.contextIdDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.currencyCode = res.org.currencyCode ? res.org.currencyCode : this.userCurrencyService.defaultCurrency;
      });
  }

  productChanged(row) {
    if (row.productNumber) {
      console.log('Product number is ' + row.productNumber);
      const productDto = this.getProduct(row.productNumber);
      if (productDto) {
        row.unitPrice = productDto.unitPrice;
        row.tax = productDto.tax;
        row.taxString = `${String(productDto.tax)}%`;
      }
    }
    this.updateInvoice();
  }

  getProduct(entityInstanceId: string): Product {
    let retProduct: Product;
    if (this.products) {
      this.products.forEach(productDto => {

        if (productDto.id === entityInstanceId) {
          console.log('found match!');
          retProduct = productDto;
          return productDto;
        } else {
          console.log('no match');
        }
      });
    }
    return retProduct;
  }

  sendInvoice() {
    console.log('TODO: slide out');
  }

  createProductInvoiceRow() {
    console.log('creating row');
    const invoiceRow: InvoiceProductRow = {} as InvoiceProductRow;
    if (!this.invoice.invoiceProductRows) {
      console.log('creating rows array');
      this.invoice.invoiceProductRows = new Array(0);
    }
    invoiceRow.quantity = 1;
    this.invoice.invoiceProductRows.push(invoiceRow);
    console.log('rows now ' + this.invoice.invoiceProductRows.length);
    this.updateInvoice();
  }

  deleteProductRow(invoiceRow: InvoiceProductRow) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        name: 'product'
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.invoice.invoiceProductRows.forEach((item, index) => {
          if (item === invoiceRow) {
            this.invoice.invoiceProductRows.splice(index, 1);
          }
        });
        this.updateInvoice();
      }
    });
  }

  addOrUpdateInvoice() {
    if (this.invoice.id) {
      this.updateInvoice();
    } else {
      // this.addInvoice();
    }
  }

  onDiscountClick(row) {
    const dialogRef = this.dialog.open(ViewDiscountsModalComponent, {
      data: {
        amount: row.unitPrice,
        discountId: row.discountId
      },
      width: '775px',
      autoFocus: false,
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result) {
          if (result.discountItem) {
            row.discountDetails = result.discountItem.discount;
            row.discountId = result.discountItem.id;
          } else {
            if (!row.discountId) {
              //overall discount
              this.invoice.discountDetails = null;
              this.invoice.discountId = null;
            }
            row.discountDetails = null;
            row.discountId = null;
          }
          this.updateInvoice();
        }
      });
  }

  updateInvoice(close = false) {
    const req: UpdateInvoice = {};
    req.contextIdDto = this.data.contextIdDto;
    req.invoice = this.invoice;
    this.invoiceService.updateInvoice(req).pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.invoice = response;
      this.notify.message = 'Invoice updated successfully';
      this.notify.open();
      if (close) {
        this.dialogRef.close(true);
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  filterProducts(search) {
    this.searchProduct = search;
  }

  private loadProducts() {
    this.productService.listProducts(this.data.contextIdDto.contextId).subscribe(response => {
      this.products = response;
    });
  }
}
