
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from '@savvy/messaging';
import { LabelsService, ContextIdDto, MessagingLabel, ListMessagesForDisplayResponse, Message, DeleteMessage } from '@savvy/messaging';
import { MatDialog } from '@angular/material/dialog';
import { EventBusService } from '../element/EventBusService';
import MenuItemTypeEnum = MenuDefinition.MenuItemTypeEnum;
import { NavChangeService } from '../element/NavChangeService';
import { EventType, UiEvent } from '../event/UiEvent';
import { TrialExpiredService } from '../shared/services/trial-expired.service';
import { NewMessageModalComponent } from '../shared/components/new-message-modal/new-message-modal.component';
import { MenuDefinition } from '@savvy/ui';

const SMALL_WIDTH_BREAKPOINT = 960;

@Component({
    selector: 'app-inbox',
    templateUrl: './inbox.component.html',
    styleUrls: ['./inbox.component.scss']
})
export class InboxComponent implements OnInit {

    private mediaMatcher: MediaQueryList = matchMedia(`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`);

    displayMode = 'default';
    multi = false;
    hideToggle = true;

    sidePanelOpened = true;

    private sub: any;
    contextIdDto: ContextIdDto;
    response: ListMessagesForDisplayResponse;
    labels: Array<MessagingLabel>;

    currentLabelId: string;
    selectedMessage: Message;

    showMessagesDisabled: boolean;
    showZeroMessages: boolean;
    showMessages: boolean;
    eventBus = new EventBusService();

    // @ViewChild('newMessagePanel') newMessagePanel: MatSidenav;


    constructor(
        private route: ActivatedRoute,
        private messageService: MessageService,
        public trialExpiredService: TrialExpiredService,
        private navChange: NavChangeService,
        private dialog: MatDialog
    ) {

    }

    ngOnInit(): void {
        this.eventBus.eventAdded$.subscribe(event => this.onEvent(event));
        this.navChange.add(MenuItemTypeEnum.Messaging);

        this.sub = this.route.params.subscribe(params => {
            this.contextIdDto = <ContextIdDto>{};
            this.contextIdDto.contextId = params['contextId'];
            this.contextIdDto.contextIdType = params['contextIdType'];

            console.log('contextId in ListMessagesComponent is ' + params['contextId']);


            this.showMessages = true;

            this.labels = new Array();

            this.addLabel('Inbox');
            this.addLabel('Done');
            this.addLabel('Snoozed');
            this.addLabel('Draft');
            this.addLabel('Important');
            this.addLabel('Junk');
            this.addLabel('Trash');
            this.addLabel('Social');
            this.addLabel('Promotions');
            this.addLabel('Family');
            this.addLabel('Work');
            this.addLabel('Clients');

            this.getMessages('Inbox');
        });
    }

    private addLabel(labelName: string) {
        const label = <MessagingLabel>{};
        label.labelName = labelName;
        this.labels.push(label);
    }

    isOver(): boolean {
        if (this.mediaMatcher) {
            return this.mediaMatcher.matches;
        }
        return false;
    }

    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }

    onSelect(message: Message): void {
        this.selectedMessage = message;
        console.log('onSelect called');
    }

    noMessages(): boolean {
        if (!this.response) {
            return true;
        } else if (!this.response.messagesList) {
            return true;
        } else {
            return this.response.messagesList.length === 0;
        }
    }

    messageSelected(selectedMessage: Message) {
        console.log('messageSelected called', selectedMessage);
        this.selectedMessage = selectedMessage;
    }

    public createMessage() {
        console.log('create new message called in list messages page');
        // this.newMessagePanel.open();

        // TODO add viewContextDto
        this.dialog.open(NewMessageModalComponent, {
            data: {
                contextIdDto: this.contextIdDto,
                // viewContextDto: this.viewContext,
                eventBus: this.eventBus
            },
            autoFocus: false,
            panelClass: ['helpwindow', 'scrollable-modal', 'modal-100']

        });
    }

    public deselectMessage() {
        // @ts-ignore
        this.selectedMessage = null;
        // We need to close the expansion panel
        console.log('deselectMessage. selectedMessage is ' + this.selectedMessage);
    }

    public getIcon(labelName: string) {
        if (labelName === 'Inbox') {
            return 'inbox';
        } else if (labelName === 'Done') {
            return 'check';
        } else if (labelName === 'Snoozed') {
            return 'schedule';
        } else if (labelName === 'Draft') {
            return 'drafts';
        } else if (labelName === 'Important') {
            return 'stars';
        } else if (labelName === 'Junk') {
            return 'error';
        } else if (labelName === 'Trash') {
            return 'delete';
        } else if (labelName === 'Social') {
            return 'people';
        } else if (labelName === 'Promotions') {
            return 'ocal_offer';
        } else if (labelName === 'Family') {
            return 'wc';
        } else if (labelName === 'Work') {
            return 'business';
        } else if (labelName === 'Clients') {
            return 'contacts';
        }
        return 'schedule';
    }

    public deleteMessage(message: Message) {
        console.log('deleting message ' + message.id);
        const req = <DeleteMessage>{};
        req.messageId = message.id;
        req.contextId = this.contextIdDto.contextId;
        req.contextIdType = this.contextIdDto.contextIdType;

        this.messageService.deleteMessage(req).subscribe(response => {
            this.getMessages(this.currentLabelId);
        });
    }

    public getMessages(labelId: string) {
        this.currentLabelId = labelId;

        console.log('loading messages with label id ' + labelId);

        /* this.messageService.listMessagesByMonth(
             this.contextIdDto.contextId,
             this.contextIdDto.contextIdType,
             new Date().getMonth(),
             new Date().getFullYear()
         ).subscribe(response => {
             console.log('Got messages ', response.messagesList);
             this.response = response;
             this.setViewState();
         });*/
    }
    private setViewState() {

        // Reset state
        this.showMessagesDisabled = false;
        this.showZeroMessages = false;
        this.showMessages = false;

        if (!this.response.hasToken) {
            this.showMessagesDisabled = true;
        } else {
            this.showMessages = true;
        }
        /* if (this.response.messagesList.length === 0) {
             this.showZeroMessages = true;
         }*/
    }


    private onEvent(event: UiEvent) {
        if (event.eventType === EventType.CANCEL_SEND_MESSAGE) {
            // this.newMessagePanel.close();
            this.getMessages(this.currentLabelId);
        }
    }
}


