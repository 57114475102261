<!-- <mat-toolbar color="primary">Workflows</mat-toolbar> -->
<ngx-datatable class='material' [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'"
  [limit]="10" [selectionType]="'cell'" (select)="onSelect($event)" [rows]='workflowLists'>

  <ngx-datatable-column name="{{'NAME' | translate}}" [flexGrow]="1">
    <ng-template ngx-datatable-cell-template let-row="row">
      {{ row.name }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="{{'DELETE' | translate}}" [flexGrow]="1">
    <ng-template ngx-datatable-cell-template let-row="row">
      <button mat-flat-button (click)="delete(row); $event.stopPropagation()" color="accent">{{'DELETE' | translate}}</button>
    </ng-template>
  </ngx-datatable-column>

</ngx-datatable>

<button (click)="create()" mat-raised-button color="accent">{{'CREATE_NEW' | translate}}</button>
