<mat-toolbar color="primary">
  <mat-toolbar-row>{{'EDIT_APPT_MESSAGES' | translate}}</mat-toolbar-row>
</mat-toolbar>

<div>
  <mat-sidenav-container class="app-inner">
    <mat-sidenav #sidePanel position="end" mode="over" opened="false" style="width: 90%">
      <app-add-edit-workflow-message *ngIf="selectedTaskDef" (taskRemoved)="taskRemoved()"
        (taskAdded)="loadWorkflowState()" (taskUpdated)="loadWorkflowState()" [contextIdDto]="data.contextIdDto"
        [taskDef]="selectedTaskDef" [workflowState]="selectedWorkflowState | translate"
        [workflowDefinitionSummaries]="response?.workflowDefinitionSummaryDtos"
        [workflowDefinitionId]="workflowDefinitionId"></app-add-edit-workflow-message>
    </mat-sidenav>

    <mat-sidenav-content>
      <div class="mdc-p-2">
          <!-- cdkDrag [cdkDragData]="stateDetails" -->
          <mat-card appearance="outlined" *ngFor="let stateDetails of loadWorkflowDefinitionForEditResponse?.loadWorkflowStateResponseList" class="mdc-mb-1">
            <mat-card-content style="cursor: move; border-radius:4px" [ngStyle]="{'background-color': stateDetails.workflowState.colour, 'color': colorInversionByHexString(stateDetails.workflowState.colour)}">
                <div fxLayout="row wrap" class="mdc-mb-1">
                  <span *ngIf="!stateDetails.workflowState.colour" fxFlex>
                   <h5>{{stateDetails.workflowState.state}}</h5>
                  </span>
                  <span *ngIf="stateDetails.workflowState.colour" fxFlex
                    [ngStyle]="{'color': colorInversionByHexString(stateDetails.workflowState.colour)}">
                    <h5>{{stateDetails.workflowState.state}}</h5>
                  </span>

                  <button mat-mini-fab color="accent" *ngIf="allowEditState" class="mr-xs" aria-label="Edit Message"
                    #tooltip="matTooltip" matTooltip="{{'EDIT_MESSAGE' | translate}}" (click)="editState(stateDetails)">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button mat-mini-fab [matMenuTriggerFor]="addStateMenu" color="primary" class="mr-xs"
                    aria-label="Add Message" #tooltip="matTooltip" matTooltip="{{'ADD_MESSAGE' | translate}}">
                    <mat-icon>add</mat-icon>
                  </button>
                  <mat-menu #addStateMenu="matMenu">
                    <button mat-menu-item (click)="addTaskToStateClick(stateDetails, 'SEND_MESSAGE')">
                      <mat-icon>email</mat-icon>
                      <span>{{'SEND_EMAIL' | translate}}</span>
                    </button>
                    <button mat-menu-item (click)="addTaskToStateClick(stateDetails, 'SEND_SMS')">
                      <mat-icon>sms</mat-icon>
                      <span>{{'SEND_SMS' | translate}}</span>
                    </button>
                  </mat-menu>
                </div>            
             
                  <mat-list>
                    <mat-list-item *ngFor="let taskDef of stateDetails.workflowTaskDefinitions"  style="background-color: #ffffff">
                      <div mat-line *ngIf="taskDef?.taskSettings?.taskName" (click)="onTaskSelect(taskDef, stateDetails)">
                        {{taskDef?.taskSettings?.taskName}}
                      </div>
                      <div mat-line *ngIf="!taskDef?.taskSettings?.taskName" (click)="onTaskSelect(taskDef, stateDetails)">
                        {{'UNKNOWN' | translate}}
                      </div>
                    </mat-list-item>
                  </mat-list>

            </mat-card-content>
          </mat-card>

      </div>
    </mat-sidenav-content>

  </mat-sidenav-container>
</div>
