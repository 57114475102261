import { Component, Input } from '@angular/core';
import { LabelValuePanelDto } from '@savvy/view-definition';

@Component({
    selector: 'app-label-value-panel',
    templateUrl: 'labelValuePanel.component.html',
    styleUrls: ['labelValuePanel.component.scss', 'commonPanel.component.scss']
})
export class LabelValuePanelComponent {
    @Input() labelValuePanelDto: LabelValuePanelDto;
}

