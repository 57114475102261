<div *ngIf="!noMessages()" #mailcontent class="main-content" fxLayout="row" fxLayoutAlign="center start" [disabled]="isOver()">
  <ng-scrollbar autoHeightDisabled="false">

    <div class="flexbox-parent" *ngIf="noMessages()">
      <div fxLayout="row" fxLayoutAlign="center center" style="height: 60vh;  ">
        <div style="text-align:center;">
          <app-zero-image name="messaging"></app-zero-image><br>
          <h5 class="ma-0">You don’t have any Message's</h5><br>
          <button mat-raised-button color="accent" [disabled]="trialExpiredService.isTrialExpired()" (click)="create()">{{'Create Message' | translate}}</button>
        </div>
      </div>
    </div>

    <div class="messages-list" fxFlex.gt-sm="90" fxFlex.gt-xs="95" fxFlex="100" *ngIf="!noMessages()">
      <mat-accordion [displayMode]="displayMode" [multi]="multi">
        <mat-expansion-panel #panel [hideToggle]="hideToggle" *ngFor="let message of response.messagesList; let i = index" [class.selected]="message === selectedMessage">
          <mat-expansion-panel-header (click)="onSelect(message)">
            <div fxLayout="row" fxLayoutAlign="none center" class="w-100">
              <img alt="" class="message-icon mr-1 hide-on-open" src="{{message.avatar}}" *ngIf="!message.icon" />
              <mat-icon [ngClass]="message.iconClass" class="mr-1 hide-on-open" *ngIf="message.icon">
                {{message.icon}}
              </mat-icon>
              <div class="pr-1 hide-on-open" fxShow="false" fxShow.gt-xs style="min-width: 120px;">
                <span>{{message.emailMessage.fromEmail}}</span>
              </div>
              <div class="text-ellipsis">
                <span>{{message.emailMessage.subject}}</span>
              </div>
              <span fxFlex></span>
              <div class="pl-1 text-xs-right text-ellipsis hide-on-open" fxShow="false" fxShow.gt-sm>
                <small class="text-xs-muted">{{ message.sentLocalDateTime | localizedDate: 'fullDate' }}</small>
              </div>
            </div>
          </mat-expansion-panel-header>
          <mat-card appearance="outlined" *ngIf="selectedMessage && selectedMessage.id === message.id" class="shadow-none">
            <mat-card-header>
              <img matCardAvatar *ngIf="!selectedMessage.icon" src="{{selectedMessage.avatar}}" class="ml-0">
              <mat-icon *ngIf="selectedMessage.icon" matCardAvatar [ngClass]="selectedMessage.iconClass" class="ml-0">{{selectedMessage.icon}}
              </mat-icon>
              <mat-card-title>{{selectedMessage.emailMessage.fromEmail}}</mat-card-title>
              <mat-card-subtitle>{{selectedMessage.sentLocalDateTime | localizedDate: 'fullDate'}}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content class="pl-0">


              <div [innerHTML]="selectedMessage.emailMessage.body"></div>

              <app-reply *ngIf="selectedMessage && selectedMessage.id === message.id" [messageId]="message.id" [contextIdDto]="contextIdDto" (cancelClicked)="panel.expanded = false; selectedMessage = null" [selectedMessage]="selectedMessage"></app-reply>


            </mat-card-content>
          </mat-card>
          <mat-action-row *ngIf="selectedMessage && selectedMessage.id === message.id">
            <button mat-raised-button mat-button-sm color="accent" class="mx-xs" (onclick)="deleteMessage(selectedMessage)">{{'Delete' | translate}}
            </button>
            <button mat-raised-button mat-button-sm class="mx-xs">{{'Forward' | translate}}</button>
          </mat-action-row>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </ng-scrollbar>
</div>

<button *ngIf="!noMessages()" mat-fab color="warn" class="mat-fab-bottom-right" [disabled]="trialExpiredService.isTrialExpired()" (click)="create()">
  <mat-icon class="mat-24">create</mat-icon>
</button>