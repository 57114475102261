<ng-container *ngIf="!deviceService.isMobile() && !deviceService.isTablet()">
  <div fxLayout="row" class="petservice mdc-mb-1 rounded-10 border pt-2"
    *ngFor="let customer of createAppointmentV2SyncService.customersSelected;let customerRowIndex = index;">

    <div fxFlex="20" fxLayoutGap="10px" fxFlexAlign="center">
      <div fxFlex="nogrow">
        <div class="avatar avatar-50 circle border-3">
          <mat-icon>perm_identity</mat-icon>
        </div>
      </div>
      <div fxFlex fxFlexAlign="center">
        <h5 class="mb-0">
          <a (click)="openViewCustomerModal(customer)">
            {{customer.fullName}}
          </a>
          <mat-icon class="vm edit-pet" (click)="openEditCustomerModal(customer)">edit</mat-icon>
        </h5>
      </div>
    </div>
    <div fxFlex="80" fxLayout="column">
      <div fxLayout="row" fxLayoutGap="8px"
        *ngFor="let service of createAppointmentV2SyncService.selectedServices[customer.id]; let rowIndex = index; let last = last">
        <div fxFlex="94">
          <div fxLayout="row" fxLayout.gt-sm="row" fxLayoutGap="8px">
            <div fxFlex>
              <app-service-selector-v2 #serviceSelectorComponent [contextIdDto]="contextIdDto"
                [selectedService]="service.selectedService"
                (serviceSelectionChanged)="serviceSelected($event, customer.id, rowIndex, service)"
                class="field-center w-100" [disabled]="disabled">
              </app-service-selector-v2>
            </div>
            <div fxFlex="12">
              <mat-form-field appearance="outline" *ngIf="service.selectedService" class="field-center w-100">
                <mat-label>{{'PRICE' | translate: {Default: 'Price'} }}</mat-label>
                <input matInput type="text" appNumberOnly [decimalPoints]="2"
                  placeholder="{{'UNIT_PRICE' | translate: {Default: 'Unit Price'} }}"
                  name="unitPrice{{customerRowIndex}}{{rowIndex}}" #unitPrice{{customerRowIndex}}{{rowIndex}}="ngModel"
                  [(ngModel)]="service.selectedService.unitPrice" (ngModelChange)="updateUnitPrice(service)"
                  id="unitPrice{{customerRowIndex}}{{rowIndex}}" required [disabled]="disabled">
                <button *ngIf="customer" mat-icon-button color="accent"
                  (mouseover)="getLastSeenPrice(customer, service.selectedService)"
                  matTooltip="{{'LAST_PRICE' | translate: {Default: 'Last Price'} }} : {{lastPrice || 'N/A'}}"
                  matTooltipPosition="above" matSuffix>
                  <mat-icon>info</mat-icon>
                </button>
              </mat-form-field>
            </div>
            <div fxFlex="20" *ngIf="taxDefs && taxDefs.length">
              <mat-form-field appearance="outline" class="field-center w-100">
                <mat-label>{{'TAX' | translate: {Default: 'Tax'} }}</mat-label>
                <mat-select placeholder="{{'SELECT_TAX_RATE' | translate: {Default: 'Select Tax Rate'} }}"
                  [(ngModel)]="service.selectedService.taxId" id="taxId{{customerRowIndex}}{{rowIndex}}"
                  name="taxId{{customerRowIndex}}{{rowIndex}}" #taxId{{customerRowIndex}}{{rowIndex}}="ngModel"
                  (ngModelChange)="updateGrossPrice(service)">
                  <mat-option value="" selected>{{'NO_TAX' | translate: {Default: 'No Tax'} }}</mat-option>
                  <mat-option *ngFor="let tax of taxDefs" [value]="tax.id">
                    {{ tax.taxName }} ( {{ tax.taxRate }} %)
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxFlex="15" *ngIf="taxDefs && taxDefs.length">
              <mat-form-field appearance="outline" *ngIf="service.selectedService" class="field-center w-100">
                <mat-label>{{'Gross_PRICE' | translate: {Default: 'Gross Price'} }}</mat-label>
                <input matInput type="text" appNumberOnly [decimalPoints]="2"
                  placeholder="{{'Gross_PRICE' | translate: {Default: 'Gross Price'} }}"
                  name="grossPrice{{customerRowIndex}}{{rowIndex}}"
                  #grossPrice{{customerRowIndex}}{{rowIndex}}="ngModel" [(ngModel)]="service.grossPrice"
                  (ngModelChange)="createAppointmentV2SyncService.calculateTotalAmount()"
                  id="grossPrice{{customerRowIndex}}{{rowIndex}}" required [disabled]="disabled">
              </mat-form-field>
            </div>
          </div>
          <div fxLayout="row" fxLayoutGap="8px">
            <div fxFlex="20">
              <mat-form-field *ngIf="timeArray && timeArray.length > 0" appearance="outline" class="field-center w-100">
                <mat-label>{{'START_TIME' | translate: {Default: 'Start Time'} }}</mat-label>
                <mat-select placeholder="{{'SELECT_START_TIME' | translate: {Default: 'Select Start Time'} }}"
                  (selectionChange)="startTimeChanged($event.value, service)" [(ngModel)]="service.startTime" required>
                  <mat-option *ngFor="let time of timeArray" [value]="time.actualValue">{{time.displayValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxFlex="20">
              <mat-form-field *ngIf="timeArray && timeArray.length > 0" appearance="outline" class="field-center w-100">
                <mat-label>{{'END_TIME' | translate: {Default: 'End Time'} }}</mat-label>
                <mat-select placeholder="{{'SELECT_END_TIME' | translate: {Default: 'Select End Time'} }}"
                  [(ngModel)]="service.endTime" required>
                  <mat-option *ngFor="let time of timeArray" [value]="time.actualValue">{{time.displayValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxFlex="40">
              <app-staff-selector class="field-center" *ngIf="service.selectedService" [contextIdDto]="contextIdDto"
                [selectedStaff]="service.selectedStaff" [selectedServiceId]="service.selectedService?.id"
                [selectedLocationId]="createAppointmentV2SyncService?.selectedLocation?.id"
                (staffSelectionChanged)="staffSelectionChanged($event, customer.id, rowIndex)">
              </app-staff-selector>
            </div>
          </div>


          <!-- <div fxLayout="row" fxLayoutGap="8px">

            </div> -->
        </div>

        <div fxFlex="nogrow" fxFlexAlign="center" fxLayout="column">
          <button mat-icon-button color="accent" (click)="deleteServiceNew(customer.id, rowIndex)"
            *ngIf="createAppointmentV2SyncService.selectedServices[customer.id].length > 1">
            <mat-icon>cancel</mat-icon>
          </button>
          <button mat-icon-button color="primary" (click)="addServiceNew(customer)" *ngIf="last">
            <mat-icon>add_circle_outline</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <mat-icon fxFlex="24px" *ngIf="allowedToRemove" class="remove-pet"
      (click)="createAppointmentV2SyncService.removeCustomer(customerRowIndex)">cancel</mat-icon>
  </div>
</ng-container>

<ng-container *ngIf="deviceService.isMobile() || deviceService.isTablet()">
  <ng-container *ngFor="let customer of createAppointmentV2SyncService.customersSelected;let customerRowIndex = index;">
    <div fxLayout="row wrap" class="petservice mdc-mb-1 rounded-10 border"
      *ngIf="createAppointmentV2SyncService.selectedServices[customer.id]">
      <!-- <div fxFlex="100" fxLayoutGap="10px" fxFlexAlign="center" class="mt-1">
        <div fxFlex="nogrow">
          <div class="avatar avatar-50 circle border-3">
            <mat-icon>perm_identity</mat-icon>
          </div>
        </div>
        <div fxFlex fxFlexAlign="center">
          <h5 class="mb-0">{{customer.fullName}}</h5>
          <p class="small mb-0">
             <a href="javascript:void(0)">{{customer.customerName}}
          <mat-icon *ngIf="customerRowIndex === 0">stars</mat-icon>
        </a>
          </p>
        </div>

        <mat-icon class="remove-pet-mobile" (click)="createAppointmentV2SyncService.removeCustomer(customerRowIndex)">
          cancel
        </mat-icon>
      </div> -->
      <!-- <hr> -->
      <div fxFlex="100" fxLayout="column">
        <div fxLayout="column" fxLayoutGap="5px" [ngClass]="{'border-top': rowIndex > 0}"
          *ngFor="let service of createAppointmentV2SyncService.selectedServices[customer.id]; let rowIndex = index; let last = last">
          <div fxFlex="100">
            <div fxLayout="row wrap" fxLayoutGap="5px">
              <div fxFlex="100" fxFlex.gt-xs="46">
                <app-service-selector-v2 #serviceSelectorComponent [contextIdDto]="contextIdDto"
                  [selectedService]="service.selectedService"
                  (serviceSelectionChanged)="serviceSelected($event, customer.id, rowIndex, service)"
                  class="field-center w-100" [disabled]="disabled">
                </app-service-selector-v2>
              </div>
              <div fxFlex="47" fxFlex.gt-xs="25">
                <mat-form-field appearance="outline" *ngIf="service.selectedService" class="field-center w-100">
                  <mat-label>{{'PRICE' | translate: {Default: 'Price'} }}</mat-label>
                  <input matInput type="text" appNumberOnly [decimalPoints]="2"
                    placeholder="{{'UNIT_PRICE' | translate: {Default: 'Unit Price'} }}"
                    name="unitPrice{{customerRowIndex}}{{rowIndex}}"
                    #unitPrice{{customerRowIndex}}{{rowIndex}}="ngModel" [(ngModel)]="service.selectedService.unitPrice"
                    (ngModelChange)="updateUnitPrice(service)" id="unitPrice{{customerRowIndex}}{{rowIndex}}" required
                    [disabled]="disabled">
                  <button *ngIf="customer" mat-icon-button color="accent"
                    (mouseover)="getLastSeenPrice(customer, service.selectedService)"
                    matTooltip="{{'LAST_PRICE' | translate: {Default: 'Last Price'} }} : {{lastPrice || 'N/A'}}"
                    matTooltipPosition="above" matSuffix>
                    <mat-icon>info</mat-icon>
                  </button>
                </mat-form-field>
              </div>
              <div fxFlex="50" fxFlex.gt-xs="25" *ngIf="taxDefs && taxDefs.length">
                <mat-form-field appearance="outline" class="field-center w-100">
                  <mat-label>{{'TAX' | translate: {Default: 'Tax'} }}</mat-label>
                  <mat-select placeholder="{{'SELECT_TAX_RATE' | translate: {Default: 'Select Tax Rate'} }}"
                    [(ngModel)]="service.selectedService.taxId" id="taxId{{customerRowIndex}}{{rowIndex}}"
                    name="taxId{{customerRowIndex}}{{rowIndex}}" #taxId{{customerRowIndex}}{{rowIndex}}="ngModel"
                    (ngModelChange)="updateGrossPrice(service)">
                    <mat-option value="" selected>{{'NO_TAX' | translate: {Default: 'No Tax'} }}</mat-option>
                    <mat-option *ngFor="let tax of taxDefs" [value]="tax.id">
                      {{ tax.taxName }} ( {{ tax.taxRate }} %)
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div fxFlex="47" fxFlex.gt-xs="47">
                <mat-form-field appearance="outline" *ngIf="service.selectedService" class="field-center w-100">
                  <mat-label>{{'GROSS_PRICE' | translate: {Default: 'Gross Price'} }}</mat-label>
                  <input matInput type="text" appNumberOnly [decimalPoints]="2"
                    placeholder="{{'GROSS_PRICE' | translate: {Default: 'Gross Price'} }}"
                    name="grossPrice{{customerRowIndex}}{{rowIndex}}"
                    #grossPrice{{customerRowIndex}}{{rowIndex}}="ngModel" [(ngModel)]="service.grossPrice"
                    (ngModelChange)="createAppointmentV2SyncService.calculateTotalAmount()"
                    id="grossPrice{{customerRowIndex}}{{rowIndex}}" required [disabled]="disabled">
                </mat-form-field>
              </div>
              <div fxFlex="50" fxFlex.gt-xs="50">
                <app-staff-selector class="field-center" *ngIf="service.selectedService" [contextIdDto]="contextIdDto"
                  [selectedStaff]="service.selectedStaff" [selectedServiceId]="service.selectedService?.id"
                  [selectedLocationId]="createAppointmentV2SyncService?.selectedLocation?.id"
                  (staffSelectionChanged)="staffSelectionChanged($event, customer.id, rowIndex)">
                </app-staff-selector>
              </div>
              <div fxFlex="47" fxFlex.gt-xs="47">
                <mat-form-field *ngIf="timeArray && timeArray.length > 0" appearance="outline"
                  class="field-center w-100">
                  <mat-label>{{'START_TIME' | translate: {Default: 'Start Time'} }}</mat-label>
                  <mat-select placeholder="{{'SELECT_START_TIME' | translate: {Default: 'Select Start Time'} }}"
                    (selectionChange)="startTimeChanged($event.value, service)" [(ngModel)]="service.startTime"
                    required>
                    <mat-option *ngFor="let time of timeArray" [value]="time.actualValue">{{time.displayValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div fxFlex="50" fxFlex.gt-xs="50">
                <mat-form-field *ngIf="timeArray && timeArray.length > 0" appearance="outline"
                  class="field-center w-100">
                  <mat-label>{{'END_TIME' | translate: {Default: 'End Time'} }}</mat-label>
                  <mat-select placeholder="{{'SELECT_END_TIME' | translate: {Default: 'Select End Time'} }}"
                    [(ngModel)]="service.endTime" required>
                    <mat-option *ngFor="let time of timeArray" [value]="time.actualValue">{{time.displayValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div fxFlex="nogrow" fxFlexAlign="center" fxLayout="row">
            <button mat-icon-button color="accent" (click)="deleteServiceNew(customer.id, rowIndex)"
              *ngIf="createAppointmentV2SyncService.selectedServices[customer.id].length > 1">
              <mat-icon>cancel</mat-icon>
            </button>
            <button mat-icon-button color="primary" (click)="addServiceNew(customer)" *ngIf="last">
              <mat-icon>add_circle_outline</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
