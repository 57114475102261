<div *ngIf="consumerServices.length">

  <h2>{{'PICK_SERVICE' | translate}}</h2>

  <br>

  <form #bookForm="ngForm">

    <!-- https://www.groomnshed.com/ -->
    <div *ngFor="let service of consumerServices">
      <span (click)="serviceSelected(service)">{{ service?.serviceName }}</span>
      <span>{{service.unitPrice | currency:currencyCode:'symbol'}}</span>
      <span>{{service.durationInMins}} {{'MINUTES' | translate}}</span>
    </div>

  </form>
</div>
