import { Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ContextIdDto, ElementRowDto, ListEntityTableDto } from '@savvy/view-definition';
import { Page } from '../shared/model/page';
import { TrialExpiredService } from '../shared/services/trial-expired.service';

@Component({
  selector: 'app-list-entity-table',
  templateUrl: 'listEntityTable.component.html'
})
export class ListEntityTableComponent implements OnInit, OnChanges {

  @Input() listEntityTableDto: ListEntityTableDto;
  @Input() contextIdDto: ContextIdDto;
  @Input() showPlaceholder: boolean;


  @ViewChild('editTmpl', { static: true }) editTmpl: TemplateRef<any>;

  rows = [];
  columns: any[] = [];
  selected: any[] = [];
  page = new Page();

  constructor(private router: Router, public trialExpiredService: TrialExpiredService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.buildTable();
  }

  ngOnInit() {
    this.page.size = 10;
    this.setPage({ offset: 0 });
  }

  setPage(pageInfo) {
    console.log('pageInfo is ' + pageInfo);
    this.page.pageNumber = pageInfo.offset;
    this.loadPage();
  }

  loadPage() {
    console.log('this.page.size ' + this.page.size);
    /*this.eicomp.listEntityTable(
      this.listEntityTableDto.entityDefinitionId.id,
      (this.page.pageNumber + 1),
      this.page.size,
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType).subscribe(loadProductsResponse => {
        this.listEntityTableDto = loadProductsResponse.listEntityTableDto;
        this.page.totalElements = loadProductsResponse.listEntityTableDto.totalNumEis;
        this.page.totalPages = loadProductsResponse.listEntityTableDto.totalNumPages;
        this.buildTable();
    });*/
  }

  buildTable() {
    if (this.listEntityTableDto && this.listEntityTableDto.tableDto && this.listEntityTableDto.tableDto.rows) {
      const newRows = new Array();
      for (const aRow of this.listEntityTableDto.tableDto.rows) {
        newRows.push(this.getRow(aRow));
      }
      const newCols = new Array();
      for (const aCol of this.listEntityTableDto.tableDto.columnNames) {
        // console.log('pushing col ' + aCol);
        newCols.push(this.getCol(aCol));
      }
      this.rows = newRows;
      this.columns = newCols;
      // this.cd.detectChanges();
    }
  }

  getCol(colDef: string) {

    const col = {};
    col['prop'] = colDef;
    col['cellTemplate'] = this.editTmpl;
    return col;
  }

  getRow(rowDto: ElementRowDto) {

    console.log('getting row ' + rowDto);
    /**
     * "rows" : [ {
            "rowId" : "5bfe528ab391a40c68b4e94d",
            "columns" : [ {
              "value" : {
                "elementType" : "STRING_VALUE",
                "sttringValueDto" : {
                  "strValue" : "lkj"
                }
              }
            }, {
              "value" : {
                "elementType" : "STRING_VALUE",
                "sttringValueDto" : {
                  "strValue" : ""
                }
              }
            }, {
              "value" : {
                "elementType" : "STRING_VALUE",
                "sttringValueDto" : {
                  "strValue" : "j k"
                }
              }
            } ],
            "numColumns" : 3
          } ]
     */
    const row = {};
    rowDto.columns.forEach((item, index) => {
      if (item.value) {
        console.log('there is a value');
        row[this.listEntityTableDto.tableDto.columnNames[index]] = item.value;
      } else {
        console.log('but no str value');
        row[this.listEntityTableDto.tableDto.columnNames[index]] = '';
      }
    }
    );
    row['id'] = rowDto.rowId;
    return row;
  }

  onSelect(event) {

  }

  onActivate(event) {
    if (event.type === 'click') {
      this.router.navigate(['/entity/viewEntity',
        event.row.id,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType]);
    }
  }

  create() {
    this.router.navigate(['/entity/createEntity',
      this.listEntityTableDto.entityDefinitionId.id,
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType]);
  }

  zeroRows() {
    if (this.listEntityTableDto === null) {
      return true;
    }
    if (this.listEntityTableDto.tableDto === null) {
      return true;
    }
    if (this.listEntityTableDto.tableDto.rows === null) {
      return true;
    }
    return this.listEntityTableDto.tableDto.rows.length === 0;
  }
}

