import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TaskService} from '@savvy/task';
import {MatSidenav} from '@angular/material/sidenav';
import {TasksPanelComponent} from './tasksPanel.component';
import {EventBusService} from '../element/EventBusService';
import {NavChangeService} from '../element/NavChangeService';
import {EventType, UiEvent} from '../event/UiEvent';
import {ContextIdDto, MenuDefinition} from '@savvy/view-definition';
import {Subscription} from 'rxjs';
import {ContextService} from '../context.service';
import MenuItemTypeEnum = MenuDefinition.MenuItemTypeEnum;

interface Tasks {
  title: string;
  description: string;
  class?: string;
}

interface Taskboard {
  title: string;
  tasks: Tasks[];
}

@Component({
  selector: 'app-task-board',
  templateUrl: 'taskBoard.component.html',
  styleUrls: ['taskBoard.component.scss']

})
export class TaskBoardComponent implements OnInit {
  response: any;
  addingOrEditingTask: boolean;

  @Input() contextIdDto: ContextIdDto;
  @Input() eventBus: EventBusService;
  @Output() toggleView = new EventEmitter();

  activePanel: string;
  zeroState: boolean;
  taskBoardDto: any;
  taskboard: Taskboard[] = [];

  @ViewChild('taskPanel', { static: true }) taskPanel: MatSidenav;
  @ViewChild('tasksPanel', { static: false }) tasksPanel: TasksPanelComponent;

  url: string;
  subscriptions: Subscription[] = [];


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: TaskService,
    private contextService: ContextService,
    private navChange: NavChangeService) {

  }

  ngOnInit() {
      this.eventBus.eventAdded$.subscribe(event => this.onEvent(event));
      this.navChange.add(MenuItemTypeEnum.Tasks);
      console.log('calling get tasks from init');
      this.getTasks();
  }

  taskCreatedOrUpdated() {

    console.log('calling get tasks from taskCreatedOrUpdated');
    this.getTasks();
    this.addingOrEditingTask = false;
    this.closePanel();
  }

  createTask() {
    console.log('broadcasting create task');
    this.eventBus.addEvent(EventType.CREATE_TASK);
    this.openPanel('addEditTask');
  }

  openPanel(panelType: string) {
    this.activePanel = panelType;
    this.taskPanel.open();
  }

  closePanel() {
    this.activePanel = '';
    if (this.taskPanel) {
      this.taskPanel.close();
    }
  }

  toggleTaskView() {
    this.toggleView.emit('toggle');
  }

  getTasks() {
    console.log('get tasks called');
    this.api.getWeekTaskBoard(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType).subscribe(response => {
        this.taskBoardDto = response;
        this.populateBoard();
      });
  }

  populateBoard() {
    this.taskboard = new Array();
    if (this.taskBoardDto && this.taskBoardDto.taskBoardColumnDtoList) {
      this.taskBoardDto.taskBoardColumnDtoList.forEach(col => {
        const board = <Taskboard>{};
        board.title = col.title;
        board.tasks = new Array();
        if (col.taskBoardTaskDtoList) {
          col.taskBoardTaskDtoList.forEach(task => {
            const taskToAdd = <Tasks>{};
            taskToAdd.title = task.title;
            taskToAdd.class = task.cssClass;
            taskToAdd.description = task.description;
            board.tasks.push(taskToAdd);
          });
        }
        this.taskboard.push(board);
      });
    }
    this.zeroState = this.taskboard.length === 0;

  }

  closePanelAndReload() {
    this.closePanel();
    this.getTasks();
  }

  private onEvent(event: UiEvent) {
    console.log('tasks event is ' + event);

    if (event.eventType === EventType.TASK_CREATED) {
      this.taskCreatedOrUpdated();
    } else if (event.eventType === EventType.TASK_UPDATED) {
      this.taskCreatedOrUpdated();
    } else if (event.eventType === EventType.CLOSE_TASK) {
      this.closePanel();
    } else if (event.eventType === EventType.TASK_MARKED_AS_DONE) {
      this.getTasks();
      this.closePanel();
    } else if (event.eventType === EventType.TASK_EXECUTED) {
      this.getTasks();
      this.closePanel();
    } else if (event.eventType === EventType.CANCEL_ADD_EDIT_TASK) {
      this.closePanel();
    } else if (event.eventType === EventType.TASK_SELECTED) {
      this.openPanel('showTask');
    } else if (event.eventType === EventType.CREATE_TASK) {
      this.router.navigate(['/task/addTask',
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType]);
    }
  }
}

