import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ChangeListener } from './changeListener';
import { TrialExpiredService } from '../shared/services/trial-expired.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PhoneNumberHelperService } from '../shared/services/phone-number-helper.service';
import { ContextIdDto, Invoice, ListInvoiceListDto } from '@savvy/view-definition';
import { ViewContextDto } from '@savvy/view-composite';
import { LinkedIdTypeEnum, LinkId } from 'src/app/core/data-share.service';
import { UserCurrencyService } from '../shared/services/userCurrency.service';

@Component({
  selector: 'app-list-invoice-list',
  templateUrl: 'listInvoiceList.component.html',
  styleUrls: ['listInvoiceList.component.scss', 'commonPanel.component.scss']
})
export class ListInvoiceListComponent implements OnInit, OnDestroy {

  currencyCode: string;
  destroy$ = new Subject();

  @Input() listInvoiceListDto: ListInvoiceListDto;
  @Input() contextIdDto: ContextIdDto;
  @Input() viewContext: any;
  @Input() form: UntypedFormGroup;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;

  constructor(private router: Router,
    public trialExpiredService: TrialExpiredService,
    private sharedService: PhoneNumberHelperService,
    private userCurrencyService: UserCurrencyService) { }

  ngOnInit() {
    this.getCurrencyCode();
  }
  create() {
    console.log('inside create in ListInvoiceListComponent');
    console.log('this.contextIdDto.contextId is ' + this.contextIdDto.contextId);
    console.log('this.contextIdDto.contextIdType is ' + this.contextIdDto.contextIdType);
    if (this.viewContext.viewContextTypeDto === ViewContextDto.ViewContextTypeDtoEnum.Customer) {

      const linkIdType = LinkedIdTypeEnum.CustomerId;
      console.log('linkIdType is ' + linkIdType);

      this.router.navigate(['/invoice/addInvoiceWithLink',
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.viewContext.customerContextDto.customerId.id,
        linkIdType
      ]);
    } else {
      // :linkId/:linkIdType/:linkEdId
      const linkIdType = LinkedIdTypeEnum.EntityInstanceId;
      console.log('linkIdType is ' + linkIdType);
      this.router.navigate(['/invoice/addInvoiceWithLinkEd',
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.viewContext.entityInstanceId.id,
        linkIdType,
        this.viewContext.entityDefinitionId.id
      ]);
    }
  }

  getCurrencyCode() {
    this.userCurrencyService.getDefaultCurrency(this.contextIdDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.currencyCode = res.org.currencyCode ? res.org.currencyCode : this.userCurrencyService.defaultCurrency;
      });
  }

  getMessage(invoice: Invoice): string {
    if (!invoice) {
      return '';
    }
    if (invoice.invoiceVoid) {
      return 'VOID';
    }
    if (invoice.paidInFull) {
      return 'PAID';
    } else {
      return 'UNPAID';
    }
  }

  rowSelected(invoiceId: string) {
    console.log('inside gotoEntity invoiceId ' + invoiceId);
    this.router.navigate(['/invoice/viewInvoice',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      invoiceId
    ]);
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}

