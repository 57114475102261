import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { EicompService, EntityChangeEvent } from '@savvy/entity-instance-composite';
import { ChangeListener } from '../changeListener';
import { ElementControlService } from '../ElementControlService';
import { ElementInstanceDto, FieldDefinitionDto, FieldValueDto } from '@savvy/view-composite';
import { ContextIdDto } from '@savvy/datalist';


@Component({
  selector: 'app-input-instance',
  templateUrl: './inputInstance.component.html'
})
export class InputInstanceComponent implements OnInit {

  @Input() fieldDefinition: FieldDefinitionDto;
  @Input() elementInstanceDto: ElementInstanceDto;
  @Input() entityInstanceId: string;
  @Input() contextIdDto: ContextIdDto;
  @Input() form: UntypedFormGroup;
  @Input() showPlaceholder: boolean;
  // TODO: IS CHANGE LISTENER BEING USED??
  @Input() changeListener: ChangeListener;


  constructor(private api: EicompService, private ecs: ElementControlService) {
  }

  get isValid() {

    if (this.form.controls[this.elementInstanceDto.instanceId] == null) {
      return false;
    } else {
      return this.form.controls[this.elementInstanceDto.instanceId].valid;
    }
  }

  ngOnInit(): void {

    if (this.form.controls[this.elementInstanceDto.instanceId] == null) {
      // console.log('PANIC missing form control for field name ' + this.fieldDefinition.name + ' id ' + this.fieldDefinition.id);
    } else {
      this.form.controls[this.elementInstanceDto.instanceId].valueChanges.pipe(debounceTime(1500),
        distinctUntilChanged()).subscribe((value) =>  {
          // console.log('changed value=' + value);
          this.onChange(value);
        });
    }
  }

  getPlaceholder() {
    if (this.showPlaceholder) {
      return this.fieldDefinition.placeholder;
    }
    return '';
  }

  ltrim(str): string {
    if (!str) {
      return str;
    }
    return str.replace(/^\s+/g, '');
  }

  onChange(newValue: string) {
    if (newValue) {
      const trimmed = this.ltrim(newValue);
      console.log('updating field value with ' + trimmed);
      const fieldValue: FieldValueDto = {
        strValue: trimmed,
        valueType: FieldValueDto.ValueTypeEnum.String
      };
      const req: EntityChangeEvent = {
        contextIdDto: this.contextIdDto,
        fieldValue,
        entityInstanceId: this.entityInstanceId,
        fieldInstanceId: this.elementInstanceDto.instanceId
      };

      // There is no loadProductsResponse from this, do we care?
      this.api.entityInstanceChangeEvent(req).subscribe(
        response => {
          console.log('applied change to input instance, loadProductsResponse ' + response);
          console.log('changeListener is ' + this.changeListener);
          this.ecs.handleChangedElements(response.changedElementList, this.form);
          if (this.changeListener) {
            if (this.changeListener) {
              this.changeListener.add('change');
            }
          } else {
            console.log('PANICK CHANGE LISTENER IS NULL IN INPUT INSTANCE');
          }
        }
      );
    }

  }
}

