<mat-card appearance="outlined" *ngIf="appointmentDates && appointmentDates.length > 0">
  <mat-card-title>
    <div fxLayout="row">
      <div fxFlex="80">
        <p class="mt-xs mb-xs">{{viewInvoicesByEiListData.label | translate}}</p>
      </div>
      <div fxFlex="20">
        <button mat-mini-fab color="accent" [disabled]="trialExpiredService.isTrialExpired()" (click)="create()">
          <mat-icon class="mat-24">add</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-title>
  <mat-card-content>

    <ngx-datatable *ngIf="appointmentDates && appointmentDates.length > 0" class='no-shadow material has-plus-btn' [columns]="columns" [style.border]="0" [rows]='appointmentDates' [columnMode]="'force'" [selected]="selected" [selectionType]="'single'" [headerHeight]="0" [rowHeight]="40" [footerHeight]="50" [externalPaging]="true" [count]="page.totalElements" [offset]="page.pageNumber" [limit]="page.size" (select)="onSelect($event)" (page)='setPage($event)'>
    </ngx-datatable>
  </mat-card-content>
</mat-card>

<mat-card appearance="outlined" *ngIf="appointmentDates && appointmentDates.length === 0">
  <mat-card-content>
    <div class="flexbox-parent">
      <div fxLayout="row" fxLayoutAlign="center center">
        <div style="text-align:center;">
          <app-zero-image [name]="viewInvoicesByEiListData.iconName"></app-zero-image><br>
          <h5 class="ma-0">{{'You don’t have any ' + viewInvoicesByEiListData.label | translate}}</h5><br>
          <!-- *ngIf="listEntityListDto.canCreate" -->
          <button mat-raised-button color="accent" [disabled]="trialExpiredService.isTrialExpired()" (click)="create()">{{'New' | translate}} {{viewInvoicesByEiListData.label | translate}}</button>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>