import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  PerformSearch, PetSearchResultDto,
  CustomerSearchResultDto, SearchService, ContextIdDto
} from '@savvy/search';

import { CreatePetAppointmentV2SyncService, PopulateNewPetEventData } from '../create-pet-appointment-v2-sync.service';
import { Subject, Subscription } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { PerformPetSearchResponse } from '@savvy/search/model/performPetSearchResponse';
import { FloSnackbarComponent } from '../../../snackbar/floSnackbar.component';
import { Pet } from '@savvy/pet';
import * as _ from 'lodash';
import { CustomerStatsComponent } from '../../shared/customer-stats/customer-stats.component';
export class SearchResult {
  label: string;
  value: any;
  type: string;
}
@Component({
  selector: 'app-pet-selector-v2',
  templateUrl: './pet-selector-v2.component.html',
  styleUrls: ['./pet-selector-v2.component.scss']
})
export class PetSelectorV2Component implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('petInput', { static: false }) petInput: ElementRef;
  @ViewChild('petAuto', { static: false }) petAuto;
  @ViewChild(CustomerStatsComponent) customerStatsComponent: CustomerStatsComponent;


  @Input() contextIdDto: ContextIdDto;
  @Input() multiple = false;
  @Input() quickAppointment = false;
  @Input() petList: Pet[] = [];

  customerId: string;

  pets: Array<PetSearchResultDto> = [];
  customers: Array<CustomerSearchResultDto> = [];

  searchControl: UntypedFormControl = new UntypedFormControl();

  searchResults: SearchResult[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];

  searchSource = new Subject<SearchResult[]>();
  filteredSearchResults = this.searchSource.asObservable();
  loadingInner = false;

  subs: Subscription[] = [];

  placeholder = 'Type pet/customer name...';

  constructor(
    private searchApi: SearchService,
    private notify: FloSnackbarComponent,
    public createPetAppointmentV2SyncService: CreatePetAppointmentV2SyncService
  ) { }

  ngOnInit() {
    if (this.createPetAppointmentV2SyncService.petsSelected.length) {
      this.customerId = this.createPetAppointmentV2SyncService.petsSelected[0].petOwnerId;
    }
    if (this.petList.length) {
      this.searchFromCustomersPet();
      return;
    }
    this.subs.push(this.createPetAppointmentV2SyncService.populateNewPet$.subscribe((petData: PopulateNewPetEventData) => {
      if (petData) {
        const pet = petData.petSearchResultDto;
        this.createPetAppointmentV2SyncService.petsSelectedChips.push(this.getLabel(pet));
        this.createPetAppointmentV2SyncService.petsSelected.push(pet);
        if (!petData.populatingAppointment) {
          this.createPetAppointmentV2SyncService.updatePetSelection(this.createPetAppointmentV2SyncService.petsSelected);
        }
        this.customerId = pet.petOwnerId;
        if (this.createPetAppointmentV2SyncService.petsSelected.length) {
          this.placeholder = 'Type to add another pet...';
        }
      }
    }));

    this.searchControl.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged()
    ).subscribe((searchKey) => {
      this.loadingInner = true;

      if (this.petList.length) {
        this.searchFromCustomersPet(searchKey);
        this.loadingInner = false;
        return;
      }

      if (!this.multiple && this.createPetAppointmentV2SyncService.petsSelectedChips.length === 1) {
        this.searchSource.next([]);
        this.loadingInner = false;
        return;
      }
      if (searchKey && searchKey.length > 0 && searchKey.length < 10) {
        console.log('value change detected, triggering new search', searchKey);
        this.search(searchKey);
      } else {
        this.loadingInner = false;
      }
    });
  }

  private getLabel(pet: PetSearchResultDto) {
    if (pet.petBreed) {
      return `<b>${pet.petName}</b>(${pet.petBreed})-${pet.customerName}`;
    } else {
      return `<b>${pet.petName}</b>${pet.customerName}`;
    }
  }

  ngOnDestroy(): void {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (!this.createPetAppointmentV2SyncService.editMode) {
        this.petInput.nativeElement.focus();
      }
    }, 600);
  }

  validCustomer(petOwnerId) {
    if (this.createPetAppointmentV2SyncService.petsSelected.length) {
      const customerId = this.createPetAppointmentV2SyncService.petsSelected[0].petOwnerId;
      return petOwnerId === customerId;
    }
    return true;
  }

  gotoInstance(event: MatAutocompleteSelectedEvent) {
    if (event.option.value) {
      const optionSelected = event.option.value as SearchResult;
      if (!this.validCustomer(optionSelected.value.petOwnerId)) {
        this.notify.message = 'Please select pet from same family/owner in an appointment';
        this.notify.open();
        return;
      }
      if (optionSelected && optionSelected.type === 'pet') {
        this.createPetAppointmentV2SyncService.petsSelectedChips.push(optionSelected.label);
        this.createPetAppointmentV2SyncService.petsSelected.push(optionSelected.value);
        this.createPetAppointmentV2SyncService.updatePetSelection(this.createPetAppointmentV2SyncService.petsSelected);
      }
      // Clear the input value
      this.petInput.nativeElement.value = null;
      this.searchControl.setValue(null);
      this.searchResults = this.searchResults.filter(sr => sr.value !== optionSelected.value);
      this.searchSource.next(this.searchResults);
      this.customerId = optionSelected.value.petOwnerId;
    }
    if (this.createPetAppointmentV2SyncService.petsSelected.length) {
      this.placeholder = 'Type to add another pet...';
    }
  }

  remove(index: number) {
    this.createPetAppointmentV2SyncService.petsSelected.splice(index, 1);
    this.createPetAppointmentV2SyncService.petsSelectedChips.splice(index, 1);
    this.createPetAppointmentV2SyncService.updatePetSelection(this.createPetAppointmentV2SyncService.petsSelected);
    if (!this.createPetAppointmentV2SyncService.petsSelected.length) {
      this.customerId = '';
      this.placeholder = 'Type pet/customer name...';
      this.createPetAppointmentV2SyncService.selectedServices = {};
      this.createPetAppointmentV2SyncService.totalServices = 0;
      this.createPetAppointmentV2SyncService.totalAmount = 0;
      this.createPetAppointmentV2SyncService.totalAmountDue = 0;
    }
    if (this.petList?.length) {
      this.searchFromCustomersPet(this.searchControl.value);
    }

  }

  handleDuplicates() {
    if (this.createPetAppointmentV2SyncService.petsSelected.length) {
      this.pets = this.pets.filter(p => this.createPetAppointmentV2SyncService.petsSelected.findIndex(ps => ps.petId === p.petId) === -1);
    }
  }

  searchFromCustomersPet(name = '') {
    let petList: Pet[] = _.cloneDeep(this.petList);
    if (this.createPetAppointmentV2SyncService.petsSelected.length) {
      petList = petList.filter(p => this.createPetAppointmentV2SyncService.petsSelected.findIndex(ps => ps.petId === p.id) === -1);
    }
    if (name) {
      petList = petList.filter(p => p.name.toLowerCase().indexOf(name.toLowerCase()) > -1);
    }
    this.searchResults = [];
    for (const pet of petList) {
      this.searchResults.push({
        label: `<b>${pet.name}</b>(${pet.breedLabel})-${pet.petOwnerFirstName} ${pet.petOwnerLastName}`,
        type: 'pet',
        value: {
          petId: pet.id,
          ownerId: pet.ownerId,
          petOwnerId: pet.petOwnerId,
          petName: pet.name,
          customerName: `${pet.petOwnerFirstName} ${pet.petOwnerLastName}`,
          petBreed: pet.breedLabel,
          petNotes: pet.petNotes,
          deleted: pet.deleted,
          deceased: pet.deceased,
        }
      });
    }
    setTimeout(() => {
      this.searchSource.next(this.searchResults);
    }, 300);
  }

  search(searchStr: string) {
    console.log('searching with ' + searchStr);

    const req: PerformSearch = {};
    req.contextIdDto = this.contextIdDto;
    req.searchString = searchStr;

    this.searchApi.performPetSearch(req).subscribe((response: PerformPetSearchResponse) => {
      this.loadingInner = false;
      this.pets = response.pets;
      this.handleDuplicates();
      this.searchResults = [];
      for (const pet of this.pets) {
        this.searchResults.push(this.buildSearchResult(pet));
      }
      this.searchSource.next(this.searchResults);
    }
    );
  }

  private buildSearchResult(pet: PetSearchResultDto) {
    if (pet.petBreedLabel) {
      return {label: `<b>${pet.petName}</b>(${pet.petBreedLabel}) - ${pet.customerName}`, type: 'pet', value: pet};
    } else {
      return {label: `<b>${pet.petName}</b> - ${pet.customerName}`, type: 'pet', value: pet};
    }
  }

  createPetFromList() {
    this.createPetAppointmentV2SyncService.handleCreatePetFromList();
  }

}
