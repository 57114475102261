<ng-container *ngIf="petVaccination">
  <mat-toolbar color="primary">
    <mat-toolbar-row>
      {{'VACCINATION'| translate}}
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="mdc-p-3 mb-0">
    <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="1%">
      <mat-form-field fxFlex="100" fxFlex.gt-sm="48" appearance="outline">
        <mat-label>{{'VACCINE_NAME' | translate}}</mat-label>
        <mat-select [(ngModel)]="petVaccination.vaccineId" name="vaccineId" [ngModelOptions]="{standalone: true}" placeholder="{{'VACCINE_NAME' | translate}}" (selectionChange)="vaccinationChange($event)" [compareWith]="compareWithFn">
          <mat-option *ngFor="let vaccine of vaccinesList" [value]="vaccine.id">
            {{vaccine.vaccineName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field fxFlex="100" fxFlex.gt-sm="25" appearance="outline">
        <mat-label>{{'DATE_VACCINATED' | translate}}</mat-label>
        <input matInput [matDatepicker]="picker" [(ngModel)]="petVaccination.dateVaccinated" (ngModelChange)="updateDateVaccinated($event)">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field fxFlex="100" fxFlex.gt-sm="25" appearance="outline">
        <mat-label>{{'NEXT_DUE_DATE' | translate}}</mat-label>
        <input matInput [matDatepicker]="nextDateDuePicker" [(ngModel)]="petVaccination.nextDateDue" disabled>
        <mat-datepicker-toggle matSuffix [for]="nextDateDuePicker"></mat-datepicker-toggle>
        <mat-datepicker #nextDateDuePicker></mat-datepicker>
      </mat-form-field>
    </div>


    <mat-form-field appearance="outline" class="w-100">
      <mat-label>{{'NOTES' | translate: {Default: 'Notes'} }}</mat-label>
      <textarea matInput [(ngModel)]="petVaccination.notes" [ngModelOptions]="{standalone: true}" rows="2"></textarea>
    </mat-form-field>

    <mat-icon *ngIf="petVaccination?.id" aria-hidden="false" aria-label="delete" (click)="deleteVaccine()" class="delete-vaccine-icon">
      delete</mat-icon>
    <button mat-raised-button color="accent" *ngIf="!saving" aria-hidden="false" aria-label="delete" (click)="saveVaccine()" class="delete-vaccine-icon">
      <mat-icon>save</mat-icon> Save</button>


  </div>
</ng-container>
<!-- </mat-card> -->