import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EicompService, LoadLastAppointmentResponse } from '@savvy/entity-instance-composite';
import { ContextIdDto } from '@savvy/datalist';
import { WorkflowStatsDto } from '@savvy/view-composite';
import * as _ from 'lodash';
import { EventBusService } from 'src/app/flo/element/EventBusService';
import { CustomerCompositeService } from '@savvy/customer';
import { FlagDto } from '@savvy/flags';
import { ViewCustomerV2Component } from 'src/app/flo/customer/view-customer-v2/view-customer-v2.component';
import { MatDialog } from '@angular/material/dialog';
import { AddCustomerComponent } from 'src/app/flo/customer/customer/addCustomer.component';

@Component({
  selector: 'app-customer-stats',
  templateUrl: './customer-stats.component.html',
  styleUrls: ['./customer-stats.component.scss']
})
export class CustomerStatsComponent implements OnInit, OnChanges {

  @Input() customerId: string;
  @Input() customerName: string;
  @Input() contextIdDto: ContextIdDto;
  @Input() showCustomerNameAlways = false;


  eventBus = new EventBusService();
  workflowStatsDto: WorkflowStatsDto;
  loadLastAppointmentResponse: LoadLastAppointmentResponse;
  showStatus = false;
  customerFlagIds: Array<string> = [];

  constructor(
    private customerCompApi: CustomerCompositeService,
    private eicompService: EicompService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    if (this.customerId) {
      this.loadStatus();
      // this.eicompService.loadEiStatus
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes?.customerId?.currentValue) {
      this.loadStatus();
    }
  }

  public loadStatus() {
    this.customerCompApi.loadCustomerStatus(this.customerId, this.contextIdDto.contextId,
      this.contextIdDto.contextIdType).subscribe(response => {
        this.customerFlagIds = response.flagIds;
        this.workflowStatsDto = response.workflowStatsDto;
        this.loadLastAppointmentResponse = response.loadLastAppointmentResponse;
        this.showStatus = true;
      });
  }

  viewCustomer() {
    const createPetModal = this.dialog.open(ViewCustomerV2Component, {
      data: {
        contextIdDto: this.contextIdDto,
        customerId: this.customerId
      },
      maxWidth: '1024px',
      // maxHeight: '80vh',
      // height: '80vh',
      panelClass: [
        'modal-100',
        'helpwindow',
        'petProfileModal'
      ],
      autoFocus: false
    });

    createPetModal.afterClosed()
      .subscribe(
        () => {
          this.loadStatus();
        });
  }

  editCustomer() {
    const createPetModal = this.dialog.open(AddCustomerComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        customerId: this.customerId,
        isViewMode: true
      },
      maxWidth: '1024px',
      panelClass: [
        'modal-100',
        'helpwindow',
        'petProfileModal'
      ],
      autoFocus: false,
      disableClose: true
    });

    createPetModal.afterClosed()
      .subscribe(
        () => {
          this.loadStatus();
        });
  }
}
