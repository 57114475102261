import { Routes } from '@angular/router';
import { ViewContractComponent } from './viewContract/viewContract.component';
import { AddContractComponent } from './addContract/addContract.component';
import { EditContractComponent } from './editContract/editContract.component';
import { ContractsComponent } from './contracts/contracts.component';
import { ListContractTemplatesComponent } from "./list-contract-templates/listContractTemplates.component";
import { AddEditContractTemplateComponent } from "./add-edit-contract-templates/addEditContractTemplates.component";


export const ContractRoutes: Routes = [
  {
    path: '',
    children: [
      { path: 'listTemplates/:contextId/:contextIdType', component: ListContractTemplatesComponent },
      { path: 'editContractTemplate/:contextId/:contextIdType/:contractTemplateId', component: AddEditContractTemplateComponent },
      { path: 'addContractTemplate/:contextId/:contextIdType', component: AddEditContractTemplateComponent },
      {
        path: 'listContracts/:contextId/:contextIdType', component: ContractsComponent,
        data: {
          breadcrumb: 'List Contracts'
        }
      },
      {
        path: 'addContract/:contextId/:contextIdType/:linkId/:linkIdType/:linkedEdId', component: AddContractComponent,
        data: {
          breadcrumb: 'Add Contract'
        }
      },
      {
        path: 'addContract/:contextId/:contextIdType/:linkId/:linkIdType', component: AddContractComponent,
        data: {
          breadcrumb: 'Add Contract'
        }
      },
      {
        path: 'editContract/:contextId/:contextIdType/:contractId', component: EditContractComponent,
        data: {
          breadcrumb: 'Edit Contract'
        }
      },
      {
        path: 'viewContract/:contextId/:contextIdType/:contractId', component: ViewContractComponent,
        data: {
          breadcrumb: 'View Contract'
        }
      },


      // 'contract/addContract/60759f7f9048292b4ff061b7/ENV_ID/60ea7623ea95ed5680e290ae/CUSTOMER_ID'
      // Error: Cannot match any routes. URL Segment: 'contract/addContract/60759f7f9048292b4ff061b7/ENV_ID/60ea7623ea95ed5680e290ae/CUSTOMER_ID'

      /* { path: 'listCustomerContracts/:contextId/:contextIdType/:contractId', component: ListCustomerContractsComponent,
         data: {
           breadcrumb: 'List Contract'
         }}*/

    ]
  }
];
