<mat-toolbar color="primary">
  <h1 mat-dialog-title>{{'CONFIRM_CHANGE_PLAN' | translate}}</h1>
</mat-toolbar>
<div class="mdc-p-3 ">
  <strong>{{'UPDATING_SUBSCRIPTION_TO' | translate}} <b>{{data.annual ? 'an Annual' : 'a Monthly'}} {{data.subscriptionDef.name}}</b> {{'PLAN_FOR' | translate}} {{ data.currencyCode + ' ' + data.cost}} {{ data.annual ? '' : ' per month'}}, {{'ARE_YOU_SURE' | translate}}</strong>
</div>
<div class="mdc-p-3 ">
  <mat-form-field class="w-100" appearance="outline" [ngClass]="{'mat-text-green': validCoupon, 'mat-text-red': !validCoupon}">
    <mat-label *ngIf="!validCoupon">{{'DISCOUNT_CODE' | translate}}</mat-label>
    <mat-label *ngIf="validCoupon">{{'DISCOUNT_CODE_APPLIED' | translate}}</mat-label>
    <input #couponCode matInput placeholder="{{'ENTER_DISCOUNT_CODE' | translate}}" [(ngModel)]="response.couponCode" name="couponCode" (change)="discountCodeChanged($event)">
    <mat-hint>{{'DISCOUNT_APPLIED_NEXT_BILLING'  | translate}}</mat-hint>
  </mat-form-field>
</div>
<div fxLayout="row" fxLayoutAlign="end" class="mb-2">
  <button mat-raised-button (click)="closeDialog()" class="mr-1">{{'NO' | translate}}</button>
  <button mat-raised-button (click)="confirmDialog()" class="mr-1" cdkFocusInitial color="primary" autofocus>{{'YES' | translate}}</button>
</div>
