<mat-toolbar color="primary" *ngIf="_taskDef">
  <mat-toolbar-row *ngIf="!_taskDef.id">
    <span>{{'CREATE_MESSAGE' | translate}}</span>
    <span class="toolbar-spacer"></span>

  </mat-toolbar-row>
  <mat-toolbar-row *ngIf="_taskDef.id">
    <span>{{'EDIT_MESSAGE' | translate}}</span>
    <span class="toolbar-spacer"></span>

  </mat-toolbar-row>
</mat-toolbar>
<mat-card appearance="outlined">
  <mat-card-content>
    <div class="mb-1">
      <button *ngIf="!_taskDef.id" mat-raised-button color="primary" aria-label="Save Message" #tooltip="matTooltip"
        matTooltip="{{'SAVE_MESSAGE' | translate}}" (click)="createTask()">
        <mat-icon>save</mat-icon> Save
      </button>
      <button *ngIf="_taskDef.id" mat-raised-button color="primary" aria-label="Save Message" #tooltip="matTooltip"
        matTooltip="{{'SAVE_MESSAGE' | translate}}" (click)="update()">
        <mat-icon>save</mat-icon> Save
      </button>
      <button *ngIf="_taskDef.id" mat-raised-button color="accent" aria-label="Delete Message" #tooltip="matTooltip"
        matTooltip="{{'DELETE_MESSAGE' | translate}}" (click)="deleteTask()" class="ml-1">
        <mat-icon>delete</mat-icon> Delete
      </button>
    </div>

    <div *ngIf="_taskDef" fxLayout="column" fxLayoutAlign="space-around">
      <mat-form-field style="width: 100%" appearance="outline">
        <mat-label>{{'MESSAGE_NAME' | translate}}</mat-label>
        <input matInput [(ngModel)]="_taskDef.taskSettings.taskName" #taskName="ngModel" class="example-full-width"
          name="taskName" placeholder="{{'TASK_NAME' | translate}}" required>
        <mat-error *ngIf="taskName.hasError('required')">
          {{'MESSAGE_NAME_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <div *ngIf="isSendSms()" class="mb-2">
        <label>{{'SMS_TEXT' | translate}}</label>
        <app-appointment-quick-links-editor #appointmentQuickLinksEditorComponent
          [selectedLinks]="_taskDef?.actionDefinition?.sendSmsDefinition?.links?.links"
          [(editorContent)]="_taskDef.actionDefinition.sendSmsDefinition.message" messageType="SMS">
        </app-appointment-quick-links-editor>

        <app-boarding-quick-links-editor
          *ngIf="workflowDef?.workflowDefinitionTypeData?.workflowDefinitionType === 'BOARDING'"
          #boardingQuickLinksEditorComponent
          [selectedLinks]="_taskDef?.actionDefinition?.sendSmsDefinition?.links?.links"
          [(editorContent)]="_taskDef.actionDefinition.sendSmsDefinition.message" messageType="SMS">
        </app-boarding-quick-links-editor>
      </div>

      <div *ngIf="isSendMessage()" class="mb-2">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>{{'SUBJECT' | translate}}</mat-label>
          <input matInput [(ngModel)]="_taskDef.actionDefinition.sendMessageDefinition.subject" #fieldName="ngModel"
            name="subject" placeholder="{{'SUBJECT' | translate}}">
        </mat-form-field>

        <mat-form-field class="w-100" appearance="outline">
          <mat-label>cc</mat-label>
          <mat-chip-grid #ccEmailChipGrid aria-label="Enter emails">
            <mat-chip-row *ngFor="let ccEmail of ccEmails" (removed)="removeCC(ccEmail)"
              (edited)="editCC(ccEmail, $event)">
              {{ccEmail}}
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
            <input placeholder="New ccEmail..." #ccEmailsInput [formControl]="ccEmailsCtrl"
              [matChipInputFor]="ccEmailChipGrid" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="addCC($event)" />
          </mat-chip-grid>
        </mat-form-field>

        <mat-form-field class="w-100" appearance="outline">
          <mat-label>bcc</mat-label>
          <mat-chip-grid #bccEmailChipGrid aria-label="Enter emails">
            <mat-chip-row *ngFor="let bccEmail of bccEmails" (removed)="removeBCC(bccEmail)"
              (edited)="editBCC(bccEmail, $event)">
              {{bccEmail}}
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
            <input placeholder="New bccEmail..." #bccEmailsInput [formControl]="bccEmailsCtrl"
              [matChipInputFor]="bccEmailChipGrid" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="addBCC($event)" />
          </mat-chip-grid>
        </mat-form-field>

        <app-student-course-quick-links-editor
          *ngIf="workflowDef?.workflowDefinitionTypeData?.workflowDefinitionType == 'STUDENT_COURSE'"
          #studentCourseQuickLinksEditorComponent
          [selectedLinks]="_taskDef?.actionDefinition?.sendMessageDefinition?.links?.links"
          [(editorContent)]="_taskDef.actionDefinition.sendMessageDefinition.body">
        </app-student-course-quick-links-editor>

        <app-student-training-day-quick-links-editor
          *ngIf="workflowDef?.workflowDefinitionTypeData?.workflowDefinitionType == 'STUDENT_TRAINING_DAY'"
          #studentTrainingDayQuickLinksEditorComponent
          [selectedLinks]="_taskDef?.actionDefinition?.sendMessageDefinition?.links?.links"
          [(editorContent)]="_taskDef.actionDefinition.sendMessageDefinition.body">
        </app-student-training-day-quick-links-editor>

        <app-appointment-quick-links-editor *ngIf="workflowDef?.workflowDefinitionTypeData?.workflowDefinitionType != 'STUDENT_COURSE'
          && workflowDef?.workflowDefinitionTypeData?.workflowDefinitionType !== 'BOARDING'
          && workflowDef?.workflowDefinitionTypeData?.workflowDefinitionType !== 'STUDENT_TRAINING_DAY'"
          #appointmentQuickLinksEditorComponent
          [selectedLinks]="_taskDef?.actionDefinition?.sendMessageDefinition?.links?.links"
          [(editorContent)]="_taskDef.actionDefinition.sendMessageDefinition.body">
        </app-appointment-quick-links-editor>

        <app-boarding-quick-links-editor
          *ngIf="workflowDef?.workflowDefinitionTypeData?.workflowDefinitionType === 'BOARDING'"
          #boardingQuickLinksEditorComponent
          [selectedLinks]="_taskDef?.actionDefinition?.sendMessageDefinition?.links?.links"
          [(editorContent)]="_taskDef.actionDefinition.sendMessageDefinition.body">
        </app-boarding-quick-links-editor>

      </div>

      <app-upload-files fxFlex="100" #uploadFiles [contextIdDto]="contextIdDto" [savedFiles]="savedFiles">
      </app-upload-files>

    </div>

  </mat-card-content>
</mat-card>
