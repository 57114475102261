<div *ngIf="showSpinner" class="bg-spinner-wrapper">
  <mat-spinner style="margin: 0 auto;"></mat-spinner>
</div>
<mat-toolbar color="primary">
  <mat-toolbar-row>{{'SALON_PLAN_SETTINGS' | translate}}</mat-toolbar-row>
</mat-toolbar>
<div class="mdc-p-3 ">
  <form #msgForm="ngForm">

    <!-- Email Message -->
    <mat-form-field appearance="outline" style="width: 100%">
      <mat-label>{{'NO_SALONS' | translate}}</mat-label>
      <input type="text" matInput appNumberOnly name="recipient" #quantityField="ngModel" [(ngModel)]="quantity" placeholder="{{'QUANTITY' | translate}}" required>
      <mat-error *ngIf="quantityField.hasError('required')">
        {{'QUANTITY_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
      </mat-error>
    </mat-form-field>
  </form>

  <div fxLayout="row">
    <div fxFlex><button mat-raised-button color="accent" (click)="changePlan()" [disabled]="!quantity || quantity === 0">{{'CONFIRM' | translate}}</button></div>
    <div fxFlex="nogrow"><button mat-raised-button (click)="dialogRef.close(false)">{{'CANCEL' | translate}}</button></div>
  </div>

</div>