<div *ngIf="showSpinner" class="bg-spinner-wrapper">
  <mat-spinner style="margin: 0 auto;"></mat-spinner>
</div>
<div *ngIf="visible">
  <mat-toolbar color="primary">
    <mat-toolbar-row>{{'SEND_INVOICE' | translate}}</mat-toolbar-row>
  </mat-toolbar>

  <mat-card appearance="outlined" class="invoice-container">
    <mat-card-content>
      <form #msgForm="ngForm">

        <div *ngIf="selectedMessageType === messageTypes[0]">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{'TO' | translate}} </mat-label>
            <input type="text" matInput name="recipient" #recipient="ngModel" [(ngModel)]="recipients" (ngModelChange)="recipientChanged($event)"  
              placeholder="{{'TO' | translate}}" required>
            <mat-error *ngIf="recipient.hasError('required')">
              {{'TO_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{'SUBJECT' | translate}}</mat-label>
            <input matInput placeholder="{{'SUBJECT' | translate}}" name="subjectField" #subjectField="ngModel"
              [(ngModel)]="subject" id="subject" required>
            <mat-error *ngIf="subjectField.hasError('required')">
              {{'SUBJECT_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
            </mat-error>
          </mat-form-field>


          <app-flo-editor [text]="body" [required]="true" (textUpdated)="bodyUpdated($event)"></app-flo-editor>
          <app-upload-files #uploadFiles [contextIdDto]="contextIdDto" [savedFiles]="savedFiles"></app-upload-files>
        </div>

        <div *ngIf="selectedMessageType === messageTypes[1]">

          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{'TO' | translate}}</mat-label>
            <input type="text" matInput name="smsNumber" #smsNumber="ngModel" [(ngModel)]="smsNumbers"
              placeholder="{{'TO' | translate}}" required>
            <mat-error *ngIf="smsNumber.hasError('required')">
              {{'TO_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" style="width: 100%;">
            <mat-label>{{'MESSAGE' | translate}}</mat-label>
            <textarea matInput #msgTxt="ngModel" name="msgTxt" required [(ngModel)]="messageText"
              placeholder="{{'ENTER_MESSAGE' | translate}}"></textarea>
            <mat-error *ngIf="msgTxt.hasError('required')">
              {{'MESSAGE_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
            </mat-error>
          </mat-form-field>
        </div>

        <mat-radio-group name="radio" [(ngModel)]="selectedMessageType">
          <mat-radio-button style="margin: 10px 10px 15px 0;" *ngFor="let messageType of messageTypes"
            [value]="messageType">
            {{messageType | translate}}
          </mat-radio-button>
        </mat-radio-group>
        <br><br />
      </form>

    </mat-card-content>
    <hr>
    <mat-card-content>
      <button mat-raised-button color="accent" (click)="send()" [disabled]="!msgForm.valid || !allowedToSend">{{'SEND' |
        translate}}</button>
      &nbsp;
      <button mat-raised-button (click)="cancelIt()">{{'CANCEL' | translate}}</button>
      <div *ngIf="!allowedToSend && recipientId">
        <mat-error>{{'CANNOT_SEND_INVOICE' | translate}} {{ recipientFullName }} {{'HASNT_GOT_ALLOW_EMAIL_TICKED' |
          translate}}.</mat-error>
      </div>
    </mat-card-content>
  </mat-card>


</div>