import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Link } from '@savvy/messaging';
import { UrlLookupService } from '../../../shared/services/urlLookup.service';
import { LinkSelectedEvent } from '../../templateLinkEvents';
import * as uuid from 'uuid';
import { ContextService } from 'src/app/flo/context.service';
@Component({
  selector: 'app-quick-link-view',
  templateUrl: './quick-link-view.component.html',
  styleUrls: ['./quick-link-view.component.scss']
})
export class QuickLinkViewComponent implements OnInit {

  @Output() linkAdded: EventEmitter<LinkSelectedEvent> = new EventEmitter();

  customerPortalLinkForm: UntypedFormGroup = new UntypedFormGroup({
    label: new UntypedFormControl('Customer Portal', [Validators.required, Validators.maxLength(50)]),
    link: new UntypedFormControl('', [Validators.required, Validators.maxLength(250)])
  });

  inviteToCustomerPortalLinkForm: UntypedFormGroup = new UntypedFormGroup({
    label: new UntypedFormControl('Invite To Customer Portal', [Validators.required, Validators.maxLength(50)]),
    link: new UntypedFormControl('', [Validators.required, Validators.maxLength(250)])
  });

  invoiceLinkForm: UntypedFormGroup = new UntypedFormGroup({
    label: new UntypedFormControl('Invoice', [Validators.required, Validators.maxLength(50)]),
    link: new UntypedFormControl('', [Validators.required, Validators.maxLength(250)])
  });

  linkForm: UntypedFormGroup = new UntypedFormGroup({
    label: new UntypedFormControl('', [Validators.required, Validators.maxLength(50)]),
    link: new UntypedFormControl('', [Validators.required, Validators.maxLength(250)])
  });

  private _selectedItem: any;


  @Input() set selectedItem(selectedItem: any) {
    this._selectedItem = selectedItem;
    this.setLinkForm();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(
    private urlLookupService: UrlLookupService,
    private contextService: ContextService,

  ) {
    this.customerPortalLinkForm.patchValue({
      label: 'Customer Portal',
      link: this.urlLookupService.customerPortalUrl() + '?id=' + this.contextService.getContextDto()?.contextId + this.urlLookupService.getEnv(),
    });

    this.inviteToCustomerPortalLinkForm.patchValue({
      label: 'Invite To Customer Portal',
      link: `${this.urlLookupService.customerPortalUrl()}/existing?id=${this.contextService.getContextDto()?.contextId}${this.urlLookupService.getEnv()}`,
    });

    this.invoiceLinkForm.patchValue({
      label: 'Link to Invoice',
      link: this.urlLookupService.invoiceUrl(),
    });
  }

  ngOnInit(): void {

  }

  onAddItemClick() {
    if (!this.linkAdded) {
      return;
    }
    const data1: LinkSelectedEvent = {
      id: '_' + uuid.v4().replace(/-/g, '_'),
      type: Link.LinkTypeEnum.Href,
      link: this.linkForm.value.link,
      label: this.linkForm.value.label,
      subLabel: ''
    };
    this.linkAdded.emit(data1);
  }

  addCustomerPortalLink() {
    if (!this.linkAdded) {
      return;
    }
    const data1: LinkSelectedEvent = {
      type: Link.LinkTypeEnum.Href,
      ...this.customerPortalLinkForm.value
    };
    this.linkAdded.emit(data1);
  }

  addInvoiceLink() {
    if (!this.linkAdded) {
      return;
    }
    const data1: LinkSelectedEvent = {
      type: Link.LinkTypeEnum.Invoice,
      ...this.invoiceLinkForm.value
    };
    this.linkAdded.emit(data1);
  }

  addInviteToCustomerPortalLink() {
    if (!this.linkAdded) {
      return;
    }
    const data1: LinkSelectedEvent = {
      type: Link.LinkTypeEnum.CustomerPortalSignupNew,
      ...this.inviteToCustomerPortalLinkForm.value
    };

    console.log(data1);
    console.log(this.inviteToCustomerPortalLinkForm.value);
    this.linkAdded.emit(data1);
  }

  setLinkForm() {
    if (!this._selectedItem) {
      this.linkForm.reset();
      this.linkForm.markAsPristine();
      return;
    }
    this.linkForm.patchValue({
      label: this._selectedItem.label,
      link: this._selectedItem.link
    });
  }

}
