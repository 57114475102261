import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AppointmentCompService, UpdateAppointmentNotes} from '@savvy/appointment';
import {ContextIdDto} from '@savvy/user';
import {FloSnackbarComponent} from '../snackbar/floSnackbar.component';


@Component({
  selector: 'app-appointment-notes',
  templateUrl: './appointmentNotes.component.html'
})
export class AppointmentNotesComponent {

  notes: string;
  contextIdDto: ContextIdDto;
  appointmentId: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private appointmentCompService: AppointmentCompService,
              private notify: FloSnackbarComponent,
              public dialogRef: MatDialogRef<AppointmentNotesComponent>) {

    this.contextIdDto = data.contextIdDto;
    this.appointmentId = data.appointmentId;
    this.loadNotes();
  }

  loadNotes() {
    this.appointmentCompService.loadAppointmentNotes(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      this.appointmentId
    ).subscribe(response=> {
      if (response) {
        this.notes = response.notes;
      }
    });
  }

  save() {
    if (!this.appointmentId) {
      console.warn('No apt id');
    } else {
      const req = <UpdateAppointmentNotes>{};
      req.contextIdDto = this.contextIdDto;
      req.appointmentId = this.appointmentId;
      req.notes = this.notes;
      this.appointmentCompService.updateAppointmentNotes(req).subscribe(response => {
        this.notify.message = 'Updated notes';
        this.notify.open();
        this.dialogRef.close(this.notes);
      });
    }
  }
}
