<mat-toolbar color="primary" class="mdc-mb-2">
  <mat-toolbar-row>

    {{'DAY_VIEW' | translate}}

    <span fxFlex fxShow="false" fxShow.gt-xs>&nbsp;
    </span>

    {{ viewDate | localizedDate: 'fullDate' }}

    <button mat-icon-button (click)="prev()">
      <mat-icon>chevron_left</mat-icon>
    </button>
    <button mat-icon-button (click)="picker.open()">
      <mat-icon>today</mat-icon>
    </button>
    <mat-form-field style="visibility: hidden; width: 0" class="mb-0">
      <input matInput [matDatepicker]="picker" [value]="viewDate"
        (dateChange)="viewDate = $event.target.value; viewDateChange()">
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <button mat-icon-button (click)="next()">
      <mat-icon>chevron_right</mat-icon>
    </button>
    <button mat-raised-button color="accent" (click)="setToday()">{{'TODAY' | translate}}</button>&nbsp;&nbsp;
    <button mat-raised-button color="accent" (click)="exportAppointments()">{{'EXPORT' |
      translate}}</button>&nbsp;&nbsp;
    <button mat-raised-button color="accent" (click)="printAppointments()">{{'PRINT' | translate}}</button>&nbsp;&nbsp;

  </mat-toolbar-row>
</mat-toolbar>

<div class="flexbox-parent"
  *ngIf="!loading && appointments && appointments.length === 0 && dailyPetSummaryDtos && dailyPetSummaryDtos.length == 0">
  <div fxLayout="row" fxLayoutAlign="center center" style="height: 60vh;  ">
    <div style="text-align:center;">
      <app-zero-image name="appointment"></app-zero-image>
      <br>
      <h5 class="ma-0">{{'NO_APPTS' | translate}}</h5><br>
    </div>
  </div>
</div>

<div id="appointments-block" class="activity-stream mdc-px-2">

  <div *ngFor="let appointment of appointments">
    <mat-card appearance="outlined" class="mdc-mb-2">
      <mat-card-content>
        <div fxLayout="column" fxLayoutAlign.gt-sm=" center" fxLayout.gt-md="row" fxLayoutGap="5px"
          fxLayoutGap.gt-sm="16px" class="mdc-mb-1">
          <div fxFlex="100" fxFlex.gt-md="nogrow">
            <p class="mdc-mb-0 mat-text-secondary small">Customer Name</p>
            <h6>{{appointment.customerName}}</h6>
          </div>
          <div fxFlex="100" fxFlex.gt-md>
            <app-customer-stats #customerStatsComponent *ngIf="appointment?.customerId" [contextIdDto]="contextIdDto"
              [customerId]="appointment.customerId">
            </app-customer-stats>
          </div>
        </div>
        <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutGap="0px" fxLayoutGap.gt-sm="16px">
          <div fxFlex="100" fxFlex.gt-sm="48" *ngFor="let appointmentServiceRow of appointment.appointmentServiceRows">
            <mat-card appearance="outlined" class="mdc-mb-1">
              <mat-card-header>
                <div fxLayout="row wrap" fxLayoutGap="16px" fxLayoutAlign=" center" class="w-100">
                  <div fxFlex>
                    <ngx-avatars matCardAvatar name="{{appointmentServiceRow.eiLabel}}" bgColor="pink" class="pointer"
                      size="30" [style.border-radius]="'50%'"></ngx-avatars>
                    <mat-card-title>
                      {{ appointmentServiceRow.eiLabel }}
                    </mat-card-title>
                    <mat-card-subtitle>
                      ({{ appointmentServiceRow.eiType }})
                    </mat-card-subtitle>
                  </div>
                  <div fxFlex="nogrow">
                    <h5><mat-icon class="mr-5px vm">schedule</mat-icon> {{ appointmentServiceRow.startTimeDisplay }}
                    </h5>
                  </div>
                </div>
              </mat-card-header>
              <mat-card-content>
                <app-pets-flags-panel *ngIf="appointmentServiceRow.eiId" [contextIdDto]="contextIdDto"
                  [flagIds]="pets[appointmentServiceRow.eiId]?.flags"
                  (createFlag)="createFlag($event, pets[appointmentServiceRow.eiId])"
                  (deleteFlag)="deleteFlag($event, pets[appointmentServiceRow.eiId])">
                </app-pets-flags-panel>
                <p><span class="mdc-mb-0 mat-text-secondary small mr-5px">Service: </span> {{
                  appointmentServiceRow?.serviceName }} by {{ appointmentServiceRow.serviceUserFullName }}</p>
                <p><span class="mdc-mb-0 mat-text-secondary small mr-5px">Scissors: </span> {{ appointment.scissors }}
                </p>
                <p><span class="mdc-mb-0 mat-text-secondary small mr-5px">Notes: </span> {{ appointment.notes }}</p>
                <!-- <p>{{ appointment.petDetailedSummaryDto.petDto.petNotes }}</p>-->

                <button mat-raised-button color="primary" class="mr-5px mdc-my-1"
                  (click)="loadPetHistory(appointmentServiceRow.eiId)">{{'HISTORY' | translate}}</button>
                <button mat-raised-button color="accent" class="mr-5px mdc-my-1"
                  (click)="recordV2Notes(appointment.id)">{{'APPT_NOTES' | translate}}</button>
                <button mat-raised-button class="mr-5px"
                  (click)="recordPetNotes(appointmentServiceRow.eiId)">{{'PET_NOTES' | translate}}</button>

              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>


</div>


<!-- V1 -->
<br>
<br>

<div class="activity-stream" *ngIf="dailyPetSummaryDtos && dailyPetSummaryDtos.length > 0">
  <mat-card appearance="outlined">
    <h2>{{'V1_APPTS' | translate}}</h2>
  </mat-card>
</div>
<br>
<br>
<div class="activity-stream" *ngIf="dailyPetSummaryDtos && dailyPetSummaryDtos.length > 0">

  <mat-card appearance="outlined" class="shadow-none" *ngFor="let petAppointment of dailyPetSummaryDtos">
    <mat-card-header>

      <!-- Click will load pet History -->
      <ngx-avatars matCardAvatar name="{{petAppointment.petDetailedSummaryDto.petDto.name}}" bgColor="pink"
        class="pointer" size="30" [style.border-radius]="'50%'"></ngx-avatars>

      <!--img *ngIf="pet.imageUrl" matCardAvatar src="{{ pet.imageUrl }}"-->
      <mat-card-title>{{ petAppointment.petDetailedSummaryDto.petDto.name }} {{
        petAppointment.appointmentDataDto.startDateLocal | localizedDate: 'shortTime' :'+0:00' }}</mat-card-title>
      <mat-card-subtitle>{{ getBreed(petAppointment.petDetailedSummaryDto.petDto.breed) }}</mat-card-subtitle>
      <mat-card-subtitle *ngFor="let service of petAppointment.appointmentDataDto.serviceSummaryDtos">
        {{ service?.serviceName }} by {{ service.serviceUser }}</mat-card-subtitle>
      <mat-card-subtitle>
        <mat-chip-listbox>
          <mat-chip-option color="{{flagDto.flagDefinition.colour}}"
            *ngFor="let flagDto of petAppointment.petDetailedSummaryDto.flagDtos" removable="false" selected>
            {{flagDto.flagDefinition.name}}
          </mat-chip-option>
        </mat-chip-listbox>

      </mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <p>{{ petAppointment.petDetailedSummaryDto.petDto.blades }}</p>
      <p>{{ petAppointment.petDetailedSummaryDto.petDto.petNotes }}</p>
      <p>{{ petAppointment.appointmentDataDto.notes }}</p>


      <button mat-button *ngIf="petAppointment.petDetailedSummaryDto.petDto.imageUrls.length > 0"
        (click)="showImages(petAppointment.petDetailedSummaryDto.petDto)">{{'IMAGEAS' | translate}}</button>&nbsp;

      <button mat-button (click)="loadPetHistory(petAppointment.petDetailedSummaryDto.petDto.id)">{{'HISTORY' |
        translate}}</button>
      &nbsp;&nbsp;
      <button mat-button (click)="recordV1Notes(petAppointment.appointmentDataDto.id)">{{'APPT_NOTES' |
        translate}}</button>
      &nbsp;
      <button mat-button (click)="recordPetNotes(petAppointment.petDetailedSummaryDto.petDto.id)">{{'PET_NOTES' |
        translate}}</button>
      &nbsp;
      <hr>
    </mat-card-content>
  </mat-card>
</div>
