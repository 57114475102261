import { Component, OnInit, ViewChild } from '@angular/core';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Contract, ContractService } from '@savvy/contract';
import { MatSidenav } from '@angular/material/sidenav';
import { ContractCompService } from '@savvy/contract';
import { EventBusService } from '../../element/EventBusService';
import { Location } from '@angular/common';
import { WindowRef } from '../../windowWrapper';
import { Brand, BrandingService } from '@savvy/branding';
import { UrlLookupService } from '../../shared/services/urlLookup.service';
import { CreateContract, UpdateContract } from '@savvy/contract';
import { ContextIdDto } from '@savvy/view-definition';
import { SendCustomerContractComponent } from '../sendCustomerContract/sendCustomerContract.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-edit-contract',
  templateUrl: './editContract.component.html',
  styleUrls: ['./editContract.component.scss']
})
export class EditContractComponent implements OnInit {

  @ViewChild('sendContractPanel', { static: false }) sendContractPanel: MatSidenav;
  contextIdDto: ContextIdDto;
  contract: Contract = null;
  eventBus = new EventBusService();
  showPanel = false;
  brands: Array<Brand>;

  response: any;
  adminMenu = false;
  private sub: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private contractCompService: ContractCompService,
    private dialog: MatDialog,
    private urlLookupService: UrlLookupService,
    private urlService: UrlLookupService,
    private brandApi: BrandingService,
    private windowRef: WindowRef,
    private location: Location,
    private api: ContractService,
    private notify: FloSnackbarComponent
  ) { }


  ngOnInit() {

    console.log('inside init of edit contract');

    this.sub = this.route?.parent?.parent?.params.subscribe(params => {
      if (params['contextId'] && params['contextIdType']) {
        this.adminMenu = true;
        this.contextIdDto = <ContextIdDto>{};
        this.contextIdDto.contextId = params['contextId'];
        this.contextIdDto.contextIdType = params['contextIdType'];
      }
      this.route.params.subscribe(childParams => {
        if (childParams['contextId'] && childParams['contextIdType']) {
          this.contextIdDto = <ContextIdDto>{};
          this.contextIdDto.contextId = childParams['contextId'];
          this.contextIdDto.contextIdType = childParams['contextIdType'];
          this.adminMenu = false;
        }
        const contractId = childParams['contractId'];
        console.log('got contractId ' + contractId);
        if (contractId != null) {
          console.log('editing contract ' + contractId);
          console.log('this.contextIdDto.contextId ' + this.contextIdDto.contextId);
          console.log('this.contextIdDto.contextIdType ' + this.contextIdDto.contextIdType);
          // Load contract

          this.contractCompService.loadContractTemplates(
            this.contextIdDto.contextId,
            this.contextIdDto.contextIdType
          ).subscribe(templates => {
            this.response = templates;
            this.api.loadContract1(contractId,
              this.contextIdDto.contextId,
              this.contextIdDto.contextIdType).subscribe(
                response => {
                  console.log('loaded contract ');
                  this.contract = response;
                });
          });
        }
      });

    });
  }

  customerSelected(event) {
    this.contract.customerId = event;
  }

  getBrandings() {
    this.brandApi.getAllBrands(this.contextIdDto.contextId).subscribe(
      brandings => {
        console.log('got ' + brandings.length + ' brandings');
        this.brands = brandings;
      });
  }

  compareBrandFn(user1: Brand, user2: Brand) {
    return user1 && user2 && user1.id === user2.id;
  }

  templateChange() {
    this.contractCompService.loadLegalText(
      this.contract.contractTemplateId,
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
    ).subscribe(response => {
      // console.log('legal text is ' + loadProductsResponse.legalText);
      this.contract.legalText = response.legalText;
    });
  }

  bodyUpdated(text: string) {
    // console.log('body now ' + text);
    this.contract.legalText = text;
  }

  // contractSend() {
  //   this.sendContractPanel.close();
  //   this.showPanel = false;
  // }

  sendContract() {
    const dialogRef = this.dialog.open(SendCustomerContractComponent, {
      panelClass: ['helpwindow', 'scrollable-modal-2'],
      data: {
        contextIdDto: this.contextIdDto,
        contractTemplateId: this.contract.contractTemplateId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.loadContracts();
    });
  }

  // sendContract() {
  //   console.log('opening send contract panel');
  //   this.sendContractPanel.open();
  //   this.showPanel = true;
  // }

  viewContract() {
    const url = this.urlLookupService.portalUrl() + '/contract.html?contractId=' + this.contract.id +
      '&id=' + this.contextIdDto.contextId + this.urlLookupService.getEnv();
    this.urlLookupService.openNewTab(url);
  }

  addContract() {
    const req: CreateContract = <CreateContract>{};
    req.contextIdDto = this.contextIdDto;
    req.contract = this.contract;

    this.api.createContract(req).subscribe(response => {
      this.contract = response;
      this.notify.message = 'Created Contract';
      this.notify.open();
      this.backToList();
    });
  }

  cancel() {
    this.backToList();

  }

  customerSign() {
    const url = this.urlService.portalUrl() + '/contract.html?id=' + this.contextIdDto.contextId +
      '&contractId=' + this.contract.id + this.urlService.getEnv();
    this.urlLookupService.openNewTab(url);
  }

  updateContract() {
    const req: UpdateContract = <UpdateContract>{};
    req.contextIdDto = this.contextIdDto;
    req.contract = this.contract;
    this.api.updateContract(req).subscribe(response => {
      this.contract = response;
      this.notify.message = 'Updated Contract';
      this.notify.open();
      this.backToList();
    });
  }

  delete() {
    const confirmDialog = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete',
        message: 'Are you sure you want to delete this contract?'
      },
      height: 'auto',
      width: '350px',
      panelClass: 'helpwindow'
    });
    confirmDialog.afterClosed().subscribe((result: boolean) => {
      if (result) {
        const req: UpdateContract = {};
        req.contextIdDto = this.contextIdDto;
        req.contract = this.contract;
        req.contract.deleted = true;
        this.api.updateContract(req).subscribe(response => {
          this.contract = response;
          this.notify.message = 'Contract deleted successfully';
          this.notify.open();
          this.backToList();
        });
      }
    });
  }

  backToList() {
    if (this.adminMenu) {
      this.router.navigate(['/admin/settings',
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType, 'contracts']);
    } else {
      this.location.back();
    }
  }
}
