import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Appointment } from '@savvy/appointment';
import { CourseDto, CourseService } from '@savvy/course';
import { Course } from '@savvy/course/model/models';
import { Customer, CustomerService } from '@savvy/customer';
import {
  CreateInvoice, DiscountItem, DiscountSettings,
  DiscountSettingsService, Invoice, InvoiceDefinition, InvoiceDto, InvoicePackageRow, InvoicePaymentStatusDto, InvoiceProductRow, InvoiceService, InvoiceServiceRow, InvoicecompService, UpdateInvoice, ViewInvoicePaymentStatusDataDto
} from '@savvy/invoice';
import { InvoiceCourseRow } from '@savvy/invoice/model/invoiceCourseRow';
import { Package } from '@savvy/packages';
import { Payment, PaymentSettings, PaymentSettingsService, PaymentcompService, RecordPaymentComp } from '@savvy/payment';
import { BoardingBooking } from '@savvy/pet-stay';
import { PlanDto, PlansCompService } from '@savvy/plan';
import { Product } from '@savvy/products';
import { ContextIdDto } from '@savvy/quickbooks';
import { RequestForPaymentService } from '@savvy/request-for-payment';
import { RequestForPayment } from '@savvy/request-for-payment/model/requestForPayment';
import {
  AccountHolder,
  CaptureTokenizedPaymentComp,
  CardMachine,
  PaymentsResponse,
  SavvyPayCardMachineService,
  SavvyPayPaymentsCompService
} from '@savvy/savvy-pay';
import {
  AccountHolder as SavvyAccountHolder, ChargeStoredPayment, SavvyPayAccountHolder2Service, SavvyPayCheckoutService,
} from '@savvy/savvy-pay2';
import { ConsumerService } from '@savvy/services';
import { ShopConfig, ShopService } from '@savvy/shop';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { BootstrapService } from 'src/app/bootstrap.service';
import { CardMachinePaymentComponent } from 'src/app/flo/invoice/card-machine-payment.component';
import { PlansPaymentComponent } from 'src/app/flo/invoice/plans-payment.component';
import { SendInvoiceDialogComponent } from 'src/app/flo/invoice/sendInvoiceDialog.component';
import { AddPaymentModalComponent } from 'src/app/flo/shared/components/add-payment-modal/add-payment-modal.component';
import { PackagesHelperService } from 'src/app/flo/shared/services/packages-helper.service';
import { ProductsHelperService } from 'src/app/flo/shared/services/products-helper.service';
import { ServicesHelperService } from 'src/app/flo/shared/services/services-helper.service';
import { UserCurrencyService } from 'src/app/flo/shared/services/userCurrency.service';
import { ViewDiscountsModalComponent } from '../../../discount/view-discounts/view-discounts-modal.component';
import { EventBusService } from '../../../element/EventBusService';
import { SmsLinkPaymentComponent } from '../../../invoice/sms-link-payment.component';
import { ConfirmationModalComponent } from '../../../shared/components/confirmation-modal/confirmation-modal.component';
import { FloSnackbarComponent } from '../../../snackbar/floSnackbar.component';
import { EditInvoiceV2Component } from '../../edit-invoice-v2/edit-invoice-v2.component';
import { ViewInvoiceV2Component } from '../../view-invoice-v2/view-invoice-v2.component';
import PaymentMethodEnum = Payment.PaymentMethodEnum;

@Component({
  selector: 'app-view-customer-invoice',
  templateUrl: './view-customer-invoice.component.html',
  styleUrls: ['./view-customer-invoice.component.scss']
})
export class ViewCustomerInvoiceComponent implements OnInit, OnDestroy {
  @Input() invoiceId: string;
  @Input() appointment: Appointment;
  @Input() boardingBooking: BoardingBooking;
  @Input() contextIdDto: ContextIdDto;
  @Input() viewOnly: boolean = false;
  @Output() invoiceUpdated = new EventEmitter();
  @Output() editAppointment = new EventEmitter();

  focusedElement: any;
  invoice: Invoice;
  invoiceDto: InvoiceDto | undefined = null;
  customerDto: Customer;
  products: Product[];
  courseDtos: Array<CourseDto>;
  show: string;
  eventBus = new EventBusService();
  destroy$ = new Subject();
  // model: Payment;
  isPaidFull = false;
  currencyCode: string;

  discountSettings: DiscountSettings;
  discountItemsById: { [key: string]: DiscountItem } = {};
  cardMachines: Array<CardMachine> = [];
  plans: Array<PlanDto> = [];

  viewInvoicePaymentStatusDataDto: ViewInvoicePaymentStatusDataDto;
  amountOutstanding: number;
  invoicePaymentStatus: InvoicePaymentStatusDto;
  invoiceDefinition: InvoiceDefinition;
  hasSavvyPayPayments = false;
  submitting = false;
  captureTokenized = {} as CaptureTokenizedPaymentComp;
  chargeStoredPayment = {} as ChargeStoredPayment;
  paymentSettings: PaymentSettings;
  paymentRequests: Array<RequestForPayment>;
  totalDepositRequests = 0;
  consumerServices: ConsumerService[] = [];
  packages: Package[] = [];

  subs: Subscription[] = [];

  searchProduct = '';
  unitPriceChangedSub = new Subject<{ newUnitPrice: number, row: InvoiceServiceRow | InvoiceProductRow | InvoicePackageRow }>();
  quantityChangedSub = new Subject<{ newQty: number, row: InvoiceProductRow }>();
  shopConfig: ShopConfig;
  accountHolder: SavvyAccountHolder;

  constructor(
    private router: Router,
    private invoiceService: InvoiceService,
    private invoiceCompService: InvoicecompService,
    private invoicecompService: InvoicecompService,
    private paymentSettingsApi: PaymentSettingsService,
    private translateService: TranslateService,
    private adapter: DateAdapter<any>,
    private notify: FloSnackbarComponent,
    private dialog: MatDialog,
    private location: Location,
    private courseService: CourseService,
    private discountSettingsService: DiscountSettingsService,
    public servicesHelperService: ServicesHelperService,
    public packagesHelperService: PackagesHelperService,
    private paymentsApiComposite: PaymentcompService,
    private savvyPayCardMachineService: SavvyPayCardMachineService,
    private plansCompService: PlansCompService,
    private savvyPayPaymentCompApi: SavvyPayPaymentsCompService,
    private requestForPaymentService: RequestForPaymentService,
    private customerService: CustomerService,
    private productsHelperService: ProductsHelperService,
    private userCurrencyService: UserCurrencyService,
    private shopService: ShopService,
    private bootstrapService: BootstrapService,
    private savvyPayAccountHolder2Service: SavvyPayAccountHolder2Service,
    private savvyPayCheckoutService: SavvyPayCheckoutService

  ) {
    this.adapter.setLocale(this.translateService.currentLang);
    this.subs.push(this.unitPriceChangedSub
      .pipe(debounceTime(600))
      .subscribe((result: { newUnitPrice: number, row: InvoiceServiceRow | InvoiceProductRow | InvoicePackageRow }) => {
        if (result.newUnitPrice) {
          result.row.unitPrice = Number(parseFloat(String(result.newUnitPrice)).toFixed(2));
          this.updateInvoice();
        }
      }));

    this.subs.push(this.quantityChangedSub
      .pipe(debounceTime(600))
      .subscribe((result: { newQty: number, row: InvoiceProductRow }) => {

        console.log('quantity changed', result.newQty);
        if (result.newQty) {
          result.row.quantity = result.newQty;
        } else {
          result.row.quantity = 0;
          result.row.grossPrice = 0;
        }
        console.log('calling update invoice');
        this.updateInvoice();
      }));
  }


  getServiceDesc(service: InvoiceServiceRow) {
    if (this.appointment?.appointmentServiceRows?.length) {
      const row = this.appointment?.appointmentServiceRows.find(appointmentServiceRow => appointmentServiceRow.startTime === service.startTime
        && appointmentServiceRow.endTime === service.endTime
        && appointmentServiceRow.serviceUser.id === service.serviceUser?.id
        && service.serviceConsumerId === appointmentServiceRow.eiId);
      if (row) {
        return row.eiLabel;
      }
    }
    return '';
  }

  loadShopConfig() {
    this.shopService.loadOrCreateShopConfig(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
    ).subscribe(response => {
      this.shopConfig = response;
    });
  }

  ngOnInit() {

    this.getCurrencyCode();
    this.loadCardMachines();

    console.log('got this.invoiceId ', this.invoiceId);

    if (this.invoiceId) {
      console.log('editing invoice ', this.invoiceId);
      console.log('this.contextIdDto.contextId ', this.contextIdDto.contextId);
      console.log('this.contextIdDto.contextIdType ', this.contextIdDto.contextIdType);
      // Load invoice
      this.loadInvoice();
      this.loadPackages();
      this.loadServices();
      this.loadProducts();
      this.loadCourses();
      this.loadDiscounts();
      this.loadPaymentSettings();
      this.loadAppointmentPaymentStatus();
      this.loadDepositRequests();
      this.loadShopConfig();
    }
  }

  loadAccountHolder() {
    this.savvyPayAccountHolder2Service.loadAccountHolderByEnvId(this.contextIdDto.contextId).subscribe(res => {
      if (res) {
        this.accountHolder = res[0];
      }
    });
  }

  focusOn(element: FocusEvent) {
    this.focusedElement = element?.target;
  }

  unitPriceChanged(newUnitPrice: number, row: InvoiceServiceRow | InvoiceProductRow | InvoicePackageRow) {
    this.unitPriceChangedSub.next({ newUnitPrice, row });
  }

  unitPriceBlured(event, row: InvoiceServiceRow | InvoiceProductRow | InvoicePackageRow) {
    if (!row?.unitPrice) {
      row.unitPrice = 0;
      row.grossPrice = 0;
      this.updateInvoice();
    } else {
      row.unitPrice = Number(parseFloat(String(row.unitPrice)).toFixed(2));
      event.target.value = parseFloat(String(event.target.value));
    }
    this.focusedElement?.blur();
  }

  invoiceUpdatedFromProduct(invoice: Invoice) {
    if (!invoice?.id) {
      this.addInvoice();
    } else {
      this.updateInvoice();
    }
  }

  quantityChanged(newQty: number, row: InvoiceProductRow) {
    console.log('pushing quantity change', newQty);
    this.quantityChangedSub.next({ newQty, row });
  }

  loadDepositRequests() {
    console.log('Loading deposit requests with invoice id ', this.invoiceId);
    this.requestForPaymentService.listByInvoice(this.contextIdDto.contextId, this.contextIdDto.contextIdType, this.invoiceId)
      .subscribe(res => {
        console.log('got deposit requests ', res);
        this.paymentRequests = res;

        this.paymentRequests.forEach(dep => {
          if (dep.paid) {
            this.totalDepositRequests = this.totalDepositRequests + (dep.amountPaidInPence / 100);
          }
        });
      });
  }

  filterProducts(search) {
    this.searchProduct = search;
  }

  loadPaymentSettings() {
    this.paymentSettingsApi.loadPaymentSettings(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
    )//.pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        console.log('loaded payment settings ', response);
        this.paymentSettings = response;

        if (!this.paymentSettings.savvyPaySettings?.accountHolderCode) {
          this.loadAccountHolder();
        }
      });

  }

  loadAppointmentPaymentStatus() {
    if (this.appointment) {
      this.invoiceCompService.loadAppointmentPaymentStatus(
        this.appointment.id,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType
      ).subscribe(res => {
        if (res) {
          this.viewInvoicePaymentStatusDataDto = res.viewInvoicePaymentStatusDataDto;
          this.setHasSavvyPayments();
        }
      });
    }
    if (this.boardingBooking) {
      // this.invoiceCompService.loadAppointmentPaymentStatus(
      //   this.boardingBooking.id,
      //   this.contextIdDto.contextId,
      //   this.contextIdDto.contextIdType
      // ).subscribe(res => {
      //   if (res) {
      //     this.viewInvoicePaymentStatusDataDto = res.viewInvoicePaymentStatusDataDto;
      //     this.setHasSavvyPayments();
      //   }
      // });
    }
  }


  loadInvoice() {
    if (!this.invoiceId) {
      this.notify.message = 'Invoice Id does not exist';
      this.notify.open();
      return;
    }
    this.invoiceCompService.loadInvoiceForView(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      this.invoiceId)
      //.pipe(takeUntil(this.destroy$))
      .subscribe(
        response => {
          console.log('loaded invoice ' + response);
          this.invoiceDto = response.invoiceDto;
          this.invoice = response.invoiceDto.invoice;
          this.isPaidFull = this.invoice.paidInFull;
          this.loadPlans();
          this.loadInvoicePaymentStatus();

          if (this.invoice && this.invoice.customerId) {
            this.loadCustomerDto(this.invoice.customerId.id);
          }
        });

  }

  loadInvoiceState() {
    this.loadInvoice();
    this.loadInvoicePaymentStatus();
  }

  takePayment() {
    this.submitting = true;
    this.captureTokenized.envId = this.contextIdDto.contextId;
    this.captureTokenized.amount = this.amountOutstanding;

    this.savvyPayPaymentCompApi.captureTokenizedPayment(this.captureTokenized).subscribe(res => {
      this.submitting = false;
      this.loadInvoiceState();

      if (res.serviceError && res.serviceError.message) {
        this.notify.message = res.serviceError.message;
        this.notify.open();
      } else if (res.resultCode === PaymentsResponse.ResultCodeEnum.Refused) {
        if (res.refusalReason) {
          if (res.refusalReason == 'Not enough balance') {
            this.notify.message = 'Customer doesn\'t have enough balance on their account.'
          } else {
            this.notify.message = res.refusalReason;
          }
        } else {
          this.notify.message = 'Payment has been refused by issuer bank.';
        }
        this.notify.open();
      } else {
        this.notify.message = 'Payment has been successfully captured.';
        this.notify.open();
      }
    });
  }

  takePaymentV2() {
    this.submitting = true;
    this.chargeStoredPayment.amount = this.amountOutstanding;

    this.savvyPayCheckoutService.chargeStoredPayment(this.chargeStoredPayment).subscribe(res => {
      this.submitting = false;
      this.loadInvoiceState();

      if (res.resultCode === PaymentsResponse.ResultCodeEnum.Refused) {
        if (res.refusalReason) {
          if (res.refusalReason == 'Not enough balance') {
            this.notify.message = 'Customer doesn\'t have enough balance on their account.'
          } else {
            this.notify.message = res.refusalReason;
          }
        } else {
          this.notify.message = 'Payment has been refused by issuer bank.';
        }
        this.notify.open();
      } else {
        this.notify.message = 'Payment has been successfully captured.';
        this.notify.open();
      }
    });
  }

  chargeNoShow() {
    this.submitting = true;
    const merchantReference = this.viewInvoicePaymentStatusDataDto.paymentReferences[0];

    this.savvyPayPaymentCompApi.chargeNoShow(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      merchantReference).subscribe(res => {
        this.submitting = false;
        this.loadInvoiceState();
        if (res.serviceError && res.serviceError.message) {
          this.notify.message = res.serviceError.message;
          this.notify.open();
        } else if (res.resultCode === PaymentsResponse.ResultCodeEnum.Refused) {
          if (res.refusalReason) {
            if (res.refusalReason == 'Not enough balance') {
              this.notify.message = 'Customer doesn\'t have enough balance on their account.'
            } else {
              this.notify.message = res.refusalReason;
            }
          } else {
            this.notify.message = 'Payment has been refused by issuer bank.';
          }
          this.notify.open();
        } else {
          this.notify.message = 'No show charge has been successfully applied.';
          this.notify.open();
        }
      });
  }

  chargeNoShowV2() {
    this.submitting = true;
    const merchantReference = this.viewInvoicePaymentStatusDataDto.paymentReferences[0];

    this.savvyPayCheckoutService.chargeNoShow(
      merchantReference).subscribe(res => {
      this.submitting = false;
      this.loadInvoiceState();
      if (res.resultCode === PaymentsResponse.ResultCodeEnum.Refused) {
        if (res.refusalReason) {
          if (res.refusalReason == 'Not enough balance') {
            this.notify.message = 'Customer doesn\'t have enough balance on their account.'
          } else {
            this.notify.message = res.refusalReason;
          }
        } else {
          this.notify.message = 'Payment has been refused by issuer bank.';
        }
        this.notify.open();
      } else {
        this.notify.message = 'No show charge has been successfully applied.';
        this.notify.open();
      }
    });
  }

  chargeLateCancellation() {
    this.submitting = true;
    const merchantReference = this.viewInvoicePaymentStatusDataDto.paymentReferences[0];

    this.savvyPayPaymentCompApi.chargeLateCancellation(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      merchantReference).subscribe(res => {
        this.submitting = false;
        this.loadInvoiceState();
        if (res.serviceError && res.serviceError.message) {
          this.notify.message = res.serviceError.message;
          this.notify.open();
        } else if (res.resultCode === PaymentsResponse.ResultCodeEnum.Refused) {
          if (res.refusalReason) {
            if (res.refusalReason == 'Not enough balance') {
              this.notify.message = 'Customer doesn\'t have enough balance on their account.'
            } else {
              this.notify.message = res.refusalReason;
            }
          } else {
            this.notify.message = 'Payment has been refused by issuer bank.';
          }
          this.notify.open();
        } else {
          this.notify.message = 'Late cancellation charge has been successfully applied.';
          this.notify.open();
        }
      });
  }

  chargeLateCancellationV2() {
    this.submitting = true;
    const merchantReference = this.viewInvoicePaymentStatusDataDto.paymentReferences[0];

    this.savvyPayCheckoutService.chargeLateCancellation(
      merchantReference).subscribe(res => {
      this.submitting = false;
      this.loadInvoiceState();
      if (res.resultCode === PaymentsResponse.ResultCodeEnum.Refused) {
        if (res.refusalReason) {
          if (res.refusalReason == 'Not enough balance') {
            this.notify.message = 'Customer doesn\'t have enough balance on their account.'
          } else {
            this.notify.message = res.refusalReason;
          }
        } else {
          this.notify.message = 'Payment has been refused by issuer bank.';
        }
        this.notify.open();
      } else {
        this.notify.message = 'Late cancellation charge has been successfully applied.';
        this.notify.open();
      }
    });
  }

  setHasSavvyPayments() {
    // Payment settings
    //   "savvyPaySettings":{
    //       "accountHolderCode":"611d5ef0b688b82719f833b8"
    //    },
    if (this.viewInvoicePaymentStatusDataDto?.paymentReferences
      && this.viewInvoicePaymentStatusDataDto?.paymentReferences.length > 0) {
      this.hasSavvyPayPayments = true;
      this.captureTokenized.merchantReference = this.viewInvoicePaymentStatusDataDto.paymentReferences[0];
      this.chargeStoredPayment.merchantReference = this.viewInvoicePaymentStatusDataDto.paymentReferences[0];
    }
  }

  loadInvoicePaymentStatus() {
    if (this.invoiceId) {
      this.invoicecompService.loadInvoicePaymentStatus(
        this.invoiceId,
        this.contextIdDto.contextId, this.contextIdDto.contextIdType)
        .subscribe(response => {
          console.log('loaded invoice', response);
          this.invoicePaymentStatus = response.invoicePaymentStatusDto;
          this.amountOutstanding = response.invoicePaymentStatusDto.amountOutstanding;
          this.captureTokenized.amount = response.invoicePaymentStatusDto.amountOutstanding;
          this.chargeStoredPayment.amount = response.invoicePaymentStatusDto.amountOutstanding;
        });
    }
  }

  getCurrencyCode() {
    this.userCurrencyService.getDefaultCurrency(this.contextIdDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.currencyCode = res.org.currencyCode ? res.org.currencyCode : this.userCurrencyService.defaultCurrency;
      });
  }

  courseChanged(row: InvoiceCourseRow) {
    if (row.courseId) {
      console.log('Course id is ' + row.courseId);
      const courseDto = this.getCourse(row.courseId);
      if (courseDto) {
        row.unitPrice = courseDto.unitPrice;
        //  row.tax = courseDto..tax;
      }
    }
    this.updateInvoice();
  }

  productChanged(row: InvoiceProductRow) {
    if (row.productNumber) {
      console.log('Product number is ' + row.productNumber);
      const productDto = this.getProduct(row.productNumber);
      if (productDto) {
        row.unitPrice = productDto.unitPrice;
        row.tax = productDto.tax;
      }
    }
    this.updateInvoice();
  }

  getCourse(courseId: string): Course {
    let retCourseDto: Course;
    if (this.courseDtos) {
      this.courseDtos.forEach(courseDto => {
        console.log('courseId is ' + courseId);
        if (courseDto.course.id === courseId) {
          console.log('found match!');
          retCourseDto = courseDto.course;
          return courseDto;
        } else {
          console.log('no match');
        }
      });
    }
    return retCourseDto;
  }

  getProduct(prouctId: string): Product {
    let retProductDto: Product;
    if (this.products) {
      this.products.forEach(productDto => {
        // console.log('entityInstanceId is ' + entityInstanceId);
        if (productDto.id && productDto.id === prouctId) {
          console.log('found match!');
          retProductDto = productDto;
          return productDto;
        } else {
          console.log('no match');
        }
      });
    }
    return retProductDto;
  }

  createProductInvoiceRow() {
    console.log('creating row');
    const invoiceRow: InvoiceProductRow = {} as InvoiceProductRow;
    if (!this.invoice.invoiceProductRows) {
      console.log('creating rows array');
      this.invoice.invoiceProductRows = new Array(0);
    }
    invoiceRow.quantity = 1;
    this.invoice.invoiceProductRows.push(invoiceRow);
    console.log('rows now ' + this.invoice.invoiceProductRows.length);

    this.updateInvoice();
  }

  deleteProductRow(invoiceRow: InvoiceProductRow) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        name: 'product'
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.invoice.invoiceProductRows.forEach((item, index) => {
          if (item === invoiceRow) {
            this.invoice.invoiceProductRows.splice(index, 1);
          }
        });
        this.updateInvoice();
      }
    });
  }

  sendInvoice() {
    this.dialog.open(SendInvoiceDialogComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        invoice: this.invoice,
        eventBus: this.eventBus
      },
      autoFocus: false,
      panelClass: 'helpwindow'
    });
  }

  viewInvoice() {
    console.log('inside view invoiceId ', this.invoice.id);
    if (this.invoice?.id) {
      const dialogRef = this.dialog.open(ViewInvoiceV2Component, {
        maxWidth: '800px',
        width: '800px',
        data: {
          invoiceId: this.invoice.id,
          contextIdDto: this.contextIdDto
        },
        panelClass: ['scrollable-modal', 'invoicemodal']
      });

      dialogRef.afterClosed().subscribe(() => {

      });
    }
  }

  editInvoice() {
    const dialogRef = this.dialog.open(EditInvoiceV2Component, {
      data: {
        contextIdDto: this.contextIdDto,
        invoice: this.invoice
      },
      // disableClose: true,
      maxWidth: '800px',
      width: '800px',
      panelClass: ['scrollable-modal', 'invoicemodal'],
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadInvoiceState();
      }
    });
  }

  addInvoice() {
    const req: CreateInvoice = {} as CreateInvoice;
    req.contextIdDto = this.contextIdDto;
    // @ts-ignore
    req.invoice = this.invoice;

    this.invoiceService.createInvoice(req).subscribe(response => {
      this.invoice = response;
      this.translateService.get('Created Invoice')
        .pipe(takeUntil(this.destroy$))
        .subscribe(val => {
          this.notify.message = val;
          this.notify.open();
        });
    });
  }

  isInvoiceValid() {
    if (this.invoice && this.invoice?.invoiceServiceRows?.length
      && this.invoice.invoiceServiceRows.length !== this.invoice.invoiceServiceRows.filter(pr => pr.serviceNumber).length) {
      console.log('Invalid service');
      return false;
    }
    if (this.invoice && this.invoice?.invoiceProductRows?.length
      && this.invoice.invoiceProductRows.length !== this.invoice.invoiceProductRows.filter(pr => pr.productNumber).length) {
      console.log('Invalid product');
      return false;
    }
    if (this.invoice && this.invoice?.invoiceCourseRows?.length
      && this.invoice.invoiceCourseRows.length !== this.invoice.invoiceCourseRows.filter(pr => pr.courseId).length) {
      console.log('Invalid course');
      return false;
    }
    if (this.invoice && this.invoice?.invoicePackageRows?.length
      && this.invoice.invoicePackageRows.length !== this.invoice.invoicePackageRows.filter(pr => pr.packageNumber).length) {
      console.log('Invalid package');
      return false;
    }
    if (this.invoice && this.invoice?.invoiceItemRows?.length
      && this.invoice.invoiceItemRows.length !== this.invoice.invoiceItemRows.filter(pr => pr.itemId).length) {
      console.log('Invalid invoiceItem');
      return false;
    }
    return true;
  }


  updateInvoice() {
    if (!this.isInvoiceValid()) {
      return;
    }
    const req: UpdateInvoice = {} as UpdateInvoice;
    req.contextIdDto = this.contextIdDto;
    // @ts-ignore
    req.invoice = this.invoice;

    this.invoiceService.updateInvoice(req)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.invoice = response;
        this.invoiceUpdated.emit(response);
        console.log('just set invoice back to ', this.invoice);
        this.isPaidFull = this.invoice.paidInFull;
        this.translateService.get('Updated Invoice')
          .subscribe(val => {
            this.notify.message = val;
            this.notify.open();
          });
        this.loadInvoicePaymentStatus();
        // if (this.focusedElement) {
        //   this.focusedElement?.focus();
        // }
      });
  }

  loadServices() {
    this.servicesHelperService.getConsumerServices(this.contextIdDto).then(consumerServices => {
      console.log('loaded services');
      this.consumerServices = consumerServices;
    });
  }

  loadPackages() {
    this.packagesHelperService.getPackages(this.contextIdDto).then(packages => {
      console.log('loaded packages');
      this.packages = packages;
    });
  }

  serviceChanged(row: InvoiceServiceRow) {
    if (row.serviceNumber) {
      this.servicesHelperService.getConsumerServices(this.contextIdDto).then(consumerServices => {
        this.consumerServices = consumerServices;
        const serviceDto = this.getService(row.serviceNumber, consumerServices);
        if (serviceDto) {
          row.unitPrice = serviceDto.unitPrice;
          row.tax = serviceDto.tax;
          row.taxString = String(serviceDto.tax) + '%';
        }
      });
    }
    this.updateInvoice();
  }

  getService(serviceId: string, consumerServices: ConsumerService[]): ConsumerService {
    let retServiceDto: ConsumerService;
    if (consumerServices.length) {
      consumerServices.forEach(serviceDto => {
        console.log('serviceDto is ' + serviceDto.id);
        if (serviceDto.id && serviceDto.id === serviceId) {
          console.log('found match!');
          retServiceDto = serviceDto;
          return serviceDto;
        } else {
          console.log('no match');
        }
      });
    }
    return retServiceDto;
  }

  createServiceInvoiceRow() {
    const invoiceRow: InvoiceServiceRow = {} as InvoiceServiceRow;
    if (!this.invoice.invoiceServiceRows) {
      this.invoice.invoiceServiceRows = new Array(0);
    }
    invoiceRow.quantity = 1;
    console.log('adding service row 128');

    this.invoice.invoiceServiceRows.push(invoiceRow);
    this.updateInvoice();
  }

  deleteServiceRow(invoiceRow: InvoiceServiceRow) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        name: 'service'
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.invoice.invoiceServiceRows.forEach((item, index) => {
          if (item === invoiceRow) {
            this.invoice.invoiceServiceRows.splice(index, 1);
          }
        });
        this.updateInvoice();
      }
    });
  }

  deleteInvoice() {
    console.log('todo');
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        name: 'Invoice'
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.invoiceService.deleteInvoice(
          this.invoiceId,
          this.contextIdDto.contextId,
          this.contextIdDto.contextIdType)
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {

            console.log('deleted invoice');
            this.translateService.get('Successfully deleted Invoice')
              .pipe(takeUntil(this.destroy$))
              .subscribe(val => {
                this.notify.message = val;
                this.notify.open();
              });
            this.bootstrapService.gotoViewCalendar(this.contextIdDto);

          });
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    for (const subs of this.subs) {
      subs.unsubscribe();
    }
  }

  backToList() {
    this.location.back();
  }

  loadDiscounts() {
    this.discountSettingsService
      .get(this.contextIdDto.contextId, this.contextIdDto.contextIdType)
      .subscribe(res => {
        console.log('loaded discounts', res);
        this.discountSettings = res;
        if (this.discountSettings && this.discountSettings.discountItems) {
          this.discountSettings.discountItems.forEach(r => {
            this.discountItemsById[r.id] = r;
          });
        }
      });
  }

  onDiscountClick(row) {
    const dialogRef = this.dialog.open(ViewDiscountsModalComponent, {
      data: {
        amount: row.unitPrice,
        discountId: row.discountId,
        currencyCode: this.currencyCode
      },
      width: '775px',
      autoFocus: false,
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result) {
          if (result.discountItem) {
            row.discountDetails = result.discountItem.discount;
            row.discountId = result.discountItem.id;
          } else {
            if (!row.discountId) {
              //overall discount
              this.invoice.discountDetails = null;
              this.invoice.discountId = null;
            }
            row.discountDetails = null;
            row.discountId = null;
          }
          this.updateInvoice();
        }
      });
  }

  applyDiscountOnInvoice() {
    const dialogRef = this.dialog.open(ViewDiscountsModalComponent, {
      data: {
        amount: this.invoice.totalWithoutDiscount,
        discountId: this.invoice.discountId,
        currencyCode: this.currencyCode
      },
      width: '775px',
      autoFocus: false,
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result) {
          if (result.discountItem) {
            this.invoice.discountDetails = result.discountItem.discount;
            this.invoice.discountId = result.discountItem.id;
          } else {
            this.invoice.discountDetails = null;
            this.invoice.discountId = null;
          }
          this.updateInvoice();
        }
      });
  }

  addPayment() {
    const dialogRef = this.dialog.open(AddPaymentModalComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        invoice: this.invoice,
        totalDepositRequests: this.totalDepositRequests
      },
      autoFocus: false,
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res) {
          this.loadInvoiceState();
          console.log('reloading invoice');
        }
      });
  }

  paidFull(type: string) {

    const payment = {} as Payment;

    console.log(this.totalDepositRequests);

    payment.amount = this.amountOutstanding;
    payment.ownerId = this.contextIdDto.contextId;
    payment.invoiceId = this.invoice?.invoiceId;

    if (type === PaymentMethodEnum.Cash) {
      payment.paymentMethod = PaymentMethodEnum.Cash;
    } else if (type === PaymentMethodEnum.DirectDebit) {
      payment.paymentMethod = PaymentMethodEnum.DirectDebit;
    } else if (type === PaymentMethodEnum.BankTransfer) {
      payment.paymentMethod = PaymentMethodEnum.BankTransfer;
    } else if (type === PaymentMethodEnum.Savvypay) {
      payment.paymentMethod = PaymentMethodEnum.Savvypay;
    } else {
      payment.paymentMethod = PaymentMethodEnum.CardMachine;
    }

    const req = {} as RecordPaymentComp;
    req.contextIdDto = this.contextIdDto;
    req.payment = payment;

    this.paymentsApiComposite.recordPaymentComp(req)
      .subscribe(response => {
        this.loadInvoiceState();
        this.invoiceUpdated.emit(response.invoice);

        this.translateService.get('Successfully added payment')
          .subscribe(val => {
            this.notify.message = val;
            this.notify.open();
          });
      }, () => {
        console.log('Error occurred while adding payment');
      });
  }

  loadCardMachines() {
    this.savvyPayCardMachineService.loadCardMachineByOwnerIdAndStatus(
      this.contextIdDto.contextId, this.contextIdDto.contextIdType, 'ACTIVE')
      .subscribe(res => {
        console.log('loaded card machines', res);
        this.cardMachines = res.cardMachines;
      });
  }

  showTerminalPayment() {
    console.log('32');
    console.log(this.totalDepositRequests);
    const dialogRef = this.dialog.open(CardMachinePaymentComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        invoice: this.invoice,
        cardMachines: this.cardMachines,
        currencyCode: this.currencyCode,
        entityInstanceId: this.appointment ? this.appointment.id : this.boardingBooking.id,
        locationId: this.appointment ? this.appointment.locationId : this.boardingBooking.locationId,
        totalDepositRequests: this.totalDepositRequests,
        amountOutstanding: this.amountOutstanding
      },
      disableClose: true,
      height: 'auto',
      width: '500px',
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe(() => {
      if (this.invoice) {
        this.invoiceService.loadInvoice(this.invoice.id, this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(res => {
          this.invoiceUpdated.emit(res);
          // this.getPayments();

          //refresh invoice to know if invoice has been paid after card machine payment
          this.loadInvoice();
        });
      }
    });

  }

  showSmsLink() {
    const dialogRef = this.dialog.open(SmsLinkPaymentComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        invoice: this.invoice,
        totalDepositRequests: this.totalDepositRequests,
        currencyCode: this.currencyCode,
        customerDto: this.customerDto
      },
      disableClose: true,
      height: 'auto',
      width: '500px',
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe(() => {
      // this.invoiceService.loadInvoice(this.invoice.id, this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(res => {
      //   this.invoiceUpdated.emit(res);
      //   // this.getPayments();
      // });
    });

  }

  loadCustomerDto(customerId: string) {
    this.customerService.loadCustomer(customerId, this.contextIdDto.contextId, this.contextIdDto.contextIdType)
      .subscribe(async (res) => {
        this.customerDto = res;
      });
  }

  showPaidPlans() {
    const dialogRef = this.dialog.open(PlansPaymentComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        invoice: this.invoice,
        plans: this.plans,
        entityInstanceId: this.appointment ? this.appointment.id : this.boardingBooking.id
      },
      disableClose: true,
      height: 'auto',
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadInvoiceState();
      }
    });

  }

  loadPlans() {
    if (this.invoice.invoiceOwnerId && this.invoice.invoiceOwnerId.id && this.invoice.invoiceOwnerId.invoiceOwnerIdType === 'CUSTOMER_ID') {
      this.plansCompService.loadActiveCustomerPlansComp(this.contextIdDto.contextId,
        this.contextIdDto.contextIdType, this.invoice.invoiceOwnerId.id).subscribe(res => {
          console.log('loaded plans', res);
          this.plans = res.plans;
        });
    }
  }

  goToPaymentSettings() {
    this.router.navigate(['/paymentSettings/paymentSettings',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
    ]);
  }

  editServiceRow() {
    this.editAppointment.emit(true);
  }

  trackByFnService(row: InvoiceServiceRow) {
    return row.serviceNumber;
  }

  trackByFnProduct(row: InvoiceProductRow) {
    return row.productNumber;
  }

  trackByFnPackage(row: InvoicePackageRow) {
    return row.packageNumber;
  }

  private loadCourses() {
    this.courseService.loadCourseDtos(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
    ).pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        console.log('loaded courses ' + response);
        this.courseDtos = response;
      });
  }

  private loadProducts() {
    this.productsHelperService.listActiveProducts(this.contextIdDto).then(products => {
      console.log('loaded products');
      this.products = products;
    });
  }

  getSendInvoiceToolTip() {
    if (!this.paymentSettings?.paymentSetup) {
      return 'Sending invoice is disabled as no payment settings have been setup'
    }
    return '';
  }
}
