import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, HostListener, ElementRef, ViewChild } from '@angular/core';
import { ContextIdDto } from '@savvy/app';
import { LookAndFeel } from '@savvy/look-and-feel';
import { DateUtils } from 'src/app/flo/dates/DateUtils';
import { AppointmentConfigHelperService } from 'src/app/flo/shared/services/appointment-config-helper.service';
import { LookAndFeelConfig, LookAndFeelSharedService, TimeValue } from 'src/app/flo/shared/services/look-and-feel-shared.service';
import { CreateAppointmentV2SyncService } from '../../create-appointment-v2/create-appointment-v2-sync.service';
import { CreatePetAppointmentV2SyncService } from '../../create-pet-appointment-v2/create-pet-appointment-v2-sync.service';
import { AppointmentConfig } from '@savvy/appointment';

@Component({
  selector: 'app-appointment-selector-v2',
  templateUrl: './appointment-selector-v2.component.html',
  styleUrls: ['./appointment-selector-v2.component.scss']
})
export class AppointmentSelectorV2Component implements OnInit, OnChanges {

  @ViewChild('datePickerInput', { static: false }) datePickerInput: ElementRef;


  @Input() contextIdDto: ContextIdDto;
  @Input() totalAppointmentDuration: number;

  @Input() startDate: Date;
  @Output() startDateChange = new EventEmitter();

  @Input() startTime: string;
  @Output() startTimeChange = new EventEmitter();

  @Input() endDate: Date;
  @Output() endDateChange = new EventEmitter();

  @Input() endTime: string;
  @Output() endTimeChange = new EventEmitter();

  @Input() disableTimePickup = false;
  @Input() rebook = false;



  allowStartAndEndTime = true;
  valid = false;

  timeArray: TimeValue[] = [];
  lookAndFeelConfig: LookAndFeelConfig;
  lookAndFeel: LookAndFeel;
  defaultTimeValue: string;
  appointmentConfig: AppointmentConfig;
  endtimeMessage = `Appointment end time will be auto calculated from service duration`;


  constructor(
    private lookAndFeelSharedService: LookAndFeelSharedService,
    private dateUtils: DateUtils,
    private createPetAppointmentV2SyncService: CreatePetAppointmentV2SyncService,
    private createAppointmentV2SyncService: CreateAppointmentV2SyncService,
    public appointmentConfigHelperService: AppointmentConfigHelperService,
    private eRef: ElementRef
  ) {

  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.eRef.nativeElement.contains(event.target)) {
      // this.text = "clicked inside";
    } else {
      // this.text = "clicked outside";
      const focusoutEvent = new Event('focusout');
      this.eRef.nativeElement.dispatchEvent(focusoutEvent);
      this.datePickerInput.nativeElement.dispatchEvent(focusoutEvent);
    }
  }

  ngOnInit() {
    this.appointmentConfig = this.appointmentConfigHelperService.appointmentConfig;
    // if(this.appointmentConfigHelperService.appointmentConfig.customizedTimeForServices) {
    // this.endtimeMessage += ` or specified start & end time`;
    // }
    this.lookAndFeelConfig = this.lookAndFeelSharedService.getLookAndFeelConfig(this.contextIdDto);
    if (this.lookAndFeelConfig) {
      this.lookAndFeel = this.lookAndFeelConfig.lookAndFeel;
      // this.minuteInterval = this.lookAndFeelConfig.minuteInterval;
      this.timeArray = this.lookAndFeelConfig.timeArray;
      this.checkIfValid();
    } else {
      console.log('no look and feel config');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.startDate?.currentValue !== changes?.startDate?.previousValue) {
      console.log('loading invoice: ', this.startDate);
      console.log('changes.invoiceId is: ', changes.startDate);
      this.checkIfValid();
    }
  }

  checkIfValid() {
    if (this.startDate) {
      if (this.allowStartAndEndTime) {
        this.valid = this.startTime && this.endTime ? this.startTime <= this.endTime : false;
      } else {
        this.valid = true;
      }
    } else {
      this.valid = false;
    }
  }

  closedStream($event) {
    const focusoutEvent = new Event('focusout');
    this.eRef.nativeElement.dispatchEvent(focusoutEvent);
    this.datePickerInput.nativeElement.dispatchEvent(focusoutEvent);
  }

  startDateChanged() {
    this.startDateChange.emit(this.startDate);
    this.endDate = this.startDate;
    this.endDateChange.emit(this.endDate);

    console.log('startDate ' + this.startDate);
    this.createPetAppointmentV2SyncService.setAppointmentDates(this.startDate, this.endDate, this.startTime, this.endTime);
    this.createAppointmentV2SyncService.setAppointmentDates(this.startDate, this.endDate, this.startTime, this.endTime);
    this.checkIfValid();
    const focusoutEvent = new Event('focusout');
    this.eRef.nativeElement.dispatchEvent(focusoutEvent);
    this.datePickerInput.nativeElement.dispatchEvent(focusoutEvent);
  }

  startTimeChanged(value: any) {
    this.startTimeChange.emit(this.startTime);
    this.createPetAppointmentV2SyncService.calculateStartEndTime();
    this.createAppointmentV2SyncService.calculateStartEndTime();
    this.checkIfValid();
  }

  // setEndTimeFromStart(value: any) {
  //   console.log('got duration in mins ' + this.totalAppointmentDuration);
  //   this.endTime = this.dateUtils.addMinsToTimeString(value, this.totalAppointmentDuration);
  //   this.endTimeChange.emit(this.endTime);
  //   this.createPetAppointmentV2SyncService.setAppointmentDates(this.startDate, this.endDate, this.startTime, this.endTime);
  //   this.createAppointmentV2SyncService.setAppointmentDates(this.startDate, this.endDate, this.startTime, this.endTime);
  //   console.log('just set endTime to ' + this.endTime);
  // }

  endTimeChanged(value: any) {
    this.endTimeChange.emit(this.endTime);
    this.createPetAppointmentV2SyncService.setAppointmentDates(this.startDate, this.endDate, this.startTime, this.endTime);
    this.createAppointmentV2SyncService.setAppointmentDates(this.startDate, this.endDate, this.startTime, this.endTime);
    this.checkIfValid();
  }

  compareTimeFn(user1: string, user2: string) {
    // console.log('comparing');
    return user1 && user2 && user1 === user2;
  }

  openCalendar() {
    //
  }

}
