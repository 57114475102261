import { Injectable } from '@angular/core';
import { ContextIdDto } from '@savvy/app';
import { SubscriptionCompService } from '@savvy/subscription';
import { Feature } from '@savvy/add-ons';

@Injectable({
  providedIn: 'root'
})
export class FeaturesHelperService {
  featuresAllowed: Feature[] = [];

  constructor(
    private subscriptionCompService: SubscriptionCompService
  ) { }

  public loadFeaturesAllowed(contextIdDto: ContextIdDto) {
    this.subscriptionCompService.allowedFeatures(contextIdDto.contextId, contextIdDto.contextIdType)
      .subscribe(res => {
        if (res && res.length) {
          this.featuresAllowed = res;
        }
      });
  }

  public isFeatureAllowed(feature: Feature.TypeEnum) {
    return this.featuresAllowed.findIndex(f => f.type === feature) > -1;
  }

  public isFeatureAllowedForce(feature: Feature.TypeEnum, contextIdDto: ContextIdDto): Promise<boolean> {
    return new Promise(resolve => {
      this.subscriptionCompService.allowedFeatures(contextIdDto.contextId, contextIdDto.contextIdType)
        .subscribe(res => {
          if (res && res.length) {
            this.featuresAllowed = res;
            resolve(this.featuresAllowed.findIndex(f => f.type === feature) > -1);
          }
        });
    });
  }

  // used for testing
  public setMultiLocationAllowed() {
    this.featuresAllowed = [
      {
        type: Feature.TypeEnum.MultiLocation
      }
    ];
  }

  public setMultiLocationNotAllowed() {
    this.featuresAllowed = [];
  }

}
