<div fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="20">
        <p class="mat-text-muted mt-xs mb-xs">{{avatarRowDto.label | translate}}</p>
    </div>
    <div>
        <!-- <ngx-avatars name="{{avatarRowDto?.avatarName}}" size="30" [style.border-radius]="'50%'"></ngx-avatars> -->
    </div>
    <div>
        <p class="mt-xs mb-xs ml-xs">{{avatarRowDto.value}}</p>
    </div>
</div>

<hr *ngIf="avatarRowDto.lineBreakAfter" class="mt-xs mb-xs">