import { AfterContentChecked, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EventBusService } from './EventBusService';
import { ChangeListener } from './changeListener';
import { ConsumerService } from '@savvy/services';
import { Product } from '@savvy/products';
import { UntypedFormGroup } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { ElementControlService } from './ElementControlService';
import { LookAndFeelSharedService, TimeValue } from '../shared/services/look-and-feel-shared.service';
import { DateUtils } from '../dates/DateUtils';
import { EventType } from '../event/UiEvent';
import { DateTimeAdapter } from '@danielmoncada/angular-datetime-picker';
import { TranslateService } from '@ngx-translate/core';
import { Invoice } from '@savvy/invoice';
import { InvoicecompService, InvoicePanelDto, UpdateInvoiceComp, InvoiceServiceRow } from '@savvy/invoice';
import { SendInvoiceDialogComponent } from '../invoice/sendInvoiceDialog.component';
import { RequestPaymentDialogComponent } from '../invoice/requestPaymentDialog.component';
import {
  EntityInstanceId, InvoiceDefinition, UserDto, UserId
} from '@savvy/view-definition';
import { ContextIdDto } from '@savvy/quickbooks';
import { LookAndFeelService } from '@savvy/look-and-feel';
import { AdditionalDataMapDto, ViewContextDto } from '@savvy/view-composite';
import { AddServiceDialogComponent } from './fieldinstance/addServiceDialog.component';
import * as _ from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServicesHelperService } from '../shared/services/services-helper.service';
@Component({
  selector: 'app-invoice-multi-services-panel',
  templateUrl: 'invoiceMultiServicesPanel.component.html'
})
export class InvoiceMultiServicesPanelComponent implements OnInit, AfterContentChecked {

  @Input() invoice: Invoice;
  @Input() invoicePanelDto: InvoicePanelDto;
  @Input() userDtos: Array<UserDto>;
  @Input() invoiceDefinition: InvoiceDefinition;
  @Input() viewContext: ViewContextDto;
  @Input() contextIdDto: ContextIdDto;
  @Input() additionalDataMapDto: AdditionalDataMapDto;
  @Input() eventBus: EventBusService;
  @Input() form: UntypedFormGroup;
  @Input() consumerServices: Array<ConsumerService>;
  @Input() products: Array<Product>;
  @Input() changeListener: ChangeListener;
  @Input() currencyCode: string;

  @Output() taskSelected = new EventEmitter();
  @Output() taskDeselected = new EventEmitter();
  @Output() invoiceUpdated = new EventEmitter();

  valid = false;
  init = false;
  timeArray: TimeValue[] = [];
  customerId: string;

  public filteredServices: ReplaySubject<ConsumerService[]> = new ReplaySubject<ConsumerService[]>(1);
  // filteredServiceGroups: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  searchService = false;

  private destroy$ = new Subject();


  constructor(
    private dialog: MatDialog,
    private dateUtils: DateUtils,
    private ecs: ElementControlService,
    private invoicecompService: InvoicecompService,
    private dateTimeAdapter: DateTimeAdapter<any>,
    private translateService: TranslateService,
    private lookAndFeelApi: LookAndFeelService,
    private lookAndFeelService: LookAndFeelSharedService,
    private snackBar: MatSnackBar,
    private servicesHelperService: ServicesHelperService
  ) {

    console.log('setting locale to ', this.translateService.currentLang);
    this.dateTimeAdapter.setLocale(this.translateService.currentLang);

  }

  getGroupedServices(services: ConsumerService[]) {
    const tempArray = [];
    const grouped = _.groupBy(services, (service) => service.groupName);
    for (const key in grouped) {
      if (Object.prototype.hasOwnProperty.call(grouped, key)) {
        const element = grouped[key];
        tempArray.push({
          name: key,
          services: element
        });
      }
    }
    return tempArray;
  }


  ngOnInit(): void {
    if (!this.invoice.invoiceServiceRows || this.invoice.invoiceServiceRows.length === 0) {
      this.createServiceRow();
    } else {
      this.ensureFirstRowSet();
    }

    this.lookAndFeelApi.getLookAndFeel(this.contextIdDto.contextId).subscribe(response => {
      //   console.log('setting time array');
      this.timeArray = this.lookAndFeelService.generateLocalisedTimeArray(response.timeWidgetPreferences.timeDropDownPeriod,
        response.timeDisplayPreferences);
    });
    // load the initial bank list
    this.filteredServices.next(this.consumerServices);
  }

  ngAfterContentChecked(): void {
    this.init = true;
  }

  sendInvoice() {
    this.dialog.open(SendInvoiceDialogComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        invoice: this.invoice,
        eventBus: this.eventBus
      },
      autoFocus: false
    });
  }

  requestForPayment() {
    this.dialog.open(RequestPaymentDialogComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        invoice: this.invoice,
        eventBus: this.eventBus
      },
      autoFocus: false
    });
  }

  ensureFirstRowSet() {
    this.servicesHelperService.getConsumerServices(this.contextIdDto).then(consumerServices => {
      if (consumerServices.length) {
        this.consumerServices = consumerServices;
        this.filteredServices.next(this.consumerServices);
      }
      console.log('invoice ', this.invoice);
      if (!this.invoice.invoiceServiceRows[0].serviceUser) {
        this.invoice.invoiceServiceRows[0].serviceUser = this.userDtos[0].userId;
      }
      if (!this.invoice.invoiceServiceRows[0].serviceNumber) {
        if (this.consumerServices && this.consumerServices.length > 0) {
          this.invoice.invoiceServiceRows[0].serviceNumber = this.consumerServices[0].id;
          this.invoice.invoiceServiceRows[0].quantity = 1;
          this.invoice.invoiceServiceRows[0].unitPrice = this.consumerServices[0].unitPrice;
          this.invoice.invoiceServiceRows[0].tax = this.consumerServices[0].tax;
          this.setEndTimeFromStart(this.invoice.invoiceServiceRows[0], this.invoice.invoiceServiceRows[0].startTime);
        } else {
          console.log('err no services');
        }
      }
    });
  }


  setValid() {
    if (this.init) {
      const valid = this.isValid();
      console.log('setting invoiceServicePanel validity state');
      this.form.controls['invoiceServicePanel'].setErrors({ incorrect: !valid });

      if (this.valid) {
        console.log('setting invoiceServicePanel valid true');
        this.form.controls['invoiceServicePanel'].setValue('abc');
      } else {
        console.log('setting invoiceServicePanel valid false');
        this.form.controls['invoiceServicePanel'].setValue('');
      }
    }
  }

  isValid() {
    console.log('checking is valid');
    this.valid = true;
    if (this.invoice.invoiceServiceRows.length == 0) {
      console.log('No invoice service rows');
      this.valid = false;
    }
    this.invoice.invoiceServiceRows.forEach(
      row => {
        if (this.invoiceDefinition.enableServiceRowUser && !row.serviceUser) {
          console.log('serviceUser is invalid');
          this.valid = false;
        }
        if (!row.serviceNumber) {
          console.log('serviceNumber is invalid');
          this.valid = false;
        }
        if (this.invoiceDefinition.enableStartTimeServiceRow && !row.startDate) {
          console.log('startDate is invalid');
          this.valid = false;
        }
        if (this.invoiceDefinition.enableStartTimeServiceRow && !row.startTime) {
          console.log('startTime is invalid');
          this.valid = false;
        }
        if (this.invoiceDefinition.enableStartTimeServiceRow && !row.endDate) {
          console.log('endDate is invalid');
          this.valid = false;
        }
        if (this.invoiceDefinition.enableStartTimeServiceRow && !row.endTime) {
          console.log('endTime is invalid');
          this.valid = false;
        }
        if (this.invoiceDefinition.serviceRowLinkedEntity && !row.entityInstanceId) {
          console.log('entityInstanceId is invalid');
          this.valid = false;
        }
      }
    );
    console.log('imsp:returning valid ' + this.valid);
    return this.valid;
  }

  getEiLabel(idx: number) {
    if (this.invoicePanelDto.invoicePanelServiceRowDtos.length > idx) {
      return this.invoicePanelDto.invoicePanelServiceRowDtos[idx].eiLabel;
    }
    return null;
  }

  startTimeChanged(row: InvoiceServiceRow, value: any) {
    console.log('start time changed:' + row.startTime);
    console.log('start time value:' + value);
    this.setEndTimeFromStart(row, value);
    this.updateInvoiceLite();
  }

  setEndTimeFromStart(row: InvoiceServiceRow, value: any) {

    if (row.serviceNumber) {
      const service = this.getService(row.serviceNumber);
      if (service) {
        const durationInMins = service.durationInMins;
        console.log('got duration in mins ' + durationInMins);
        console.log('value is ' + value);
        if (value) {
          row.endTime = this.dateUtils.addMinsToTimeString(value, durationInMins);
          console.log('just set endTime to ' + row.endTime);
        }
      }
    }
  }

  setEndTimeFromStartRow(row: InvoiceServiceRow) {

    if (row.serviceNumber && row.startTime) {
      const service = this.getService(row.serviceNumber);
      if (service) {
        const durationInMins = service.durationInMins;
        console.log('got duration in mins ' + durationInMins);
        row.endTime = this.dateUtils.addMinsToTimeString(row.startTime, durationInMins);
        console.log('just set endTime to ' + row.endTime);
      }
    }
  }

  startDateChange(row: InvoiceServiceRow) {
    let dateStr;
    if (row.startDate) {
      dateStr = this.dateUtils.getDateAsStringDash(row.startDate);
    }
    console.log('dateStr ' + dateStr);

    row.startDate = dateStr;

    // 2021-04-26T04:00:00.000
    row.endDate = row.startDate;


    console.log('start date is ', row.startDate);
    // Reset all services to be same start / end date
    this.invoice.invoiceServiceRows.forEach(aRow => {
      aRow.startDate = row.startDate;
      aRow.endDate = row.endDate;
    });
    // Update all

    this.updateInvoiceLite();
  }

  endTimeChanged(row: InvoiceServiceRow, value: any) {
    console.log('end time changed:' + row.startTime);
    console.log('end time value:' + value);
    this.updateInvoiceLite();
  }

  priceChanged(row: InvoiceServiceRow, event: any) {
    console.log('price changed:', row.unitPrice);
    console.log('price event:', event);
    row.unitPrice = event;
    this.updateInvoiceLite();
  }

  endDateChange(row: InvoiceServiceRow) {
    let dateStr;
    if (row.endDate) {
      dateStr = this.dateUtils.getDateAsStringDash(row.endDate);
    }
    console.log('dateStr ' + dateStr);

    row.endDate = dateStr;

    /*this.invoice.invoiceServiceRows.forEach(aRow => {
      aRow.endDate = dateStr;
    });*/
    this.updateInvoiceLite();
  }

  createServiceRow() {
    console.log('creating row');
    const newInvoiceRow: InvoiceServiceRow = <InvoiceServiceRow>{};
    newInvoiceRow.quantity = 1;

    if (!this.invoice.invoiceServiceRows) {
      console.log('creating rows array');
      this.invoice.invoiceServiceRows = new Array(0);
    } else {

      // Lets make it the same as the previous row
      const lastRow = _.cloneDeep(this.invoice.invoiceServiceRows[this.invoice.invoiceServiceRows.length - 1]);
      if (lastRow) {
        newInvoiceRow.serviceUser = lastRow.serviceUser;
        newInvoiceRow.quantity = lastRow.quantity;
        newInvoiceRow.entityInstanceId = lastRow.entityInstanceId;
        console.log('is last row set ei to ', lastRow.entityInstanceId);
        newInvoiceRow.startDate = lastRow.startDate;
        console.log('is last row set startDate to ', lastRow.startDate);
        newInvoiceRow.startTime = lastRow.startTime;
        newInvoiceRow.endDate = lastRow.endDate;
        newInvoiceRow.serviceNumber = lastRow.serviceNumber;
        this.setEndTimeFromStart(lastRow, lastRow.startTime);
        newInvoiceRow.subTotal = lastRow.subTotal;
        newInvoiceRow.discount = lastRow.discount;
        newInvoiceRow.grossPrice = lastRow.grossPrice;
        newInvoiceRow.tax = lastRow.tax;
        console.log('set price to ', lastRow.grossPrice);
      } else {
        console.log('not last row');
        if (this.userDtos && this.userDtos.length > 0) {
          newInvoiceRow.serviceUser = this.userDtos[0].userId;
          newInvoiceRow.quantity = 1;
        } else {

        }
      }
    }
    console.log('invoiceServiceRows to ', newInvoiceRow);
    console.log('adding service row 125');

    this.invoice.invoiceServiceRows.push(newInvoiceRow);
    console.log('rows now ' + this.invoice.invoiceServiceRows.length);
    this.serviceChanged(newInvoiceRow, true); // Causes recalculate
  }

  deleteServiceRow(invoiceRow: InvoiceServiceRow) {
    if (this.invoice.invoiceServiceRows.length === 1) {
      this.snackBar.open('At least one service should be selected', 'ok', {
        duration: 3000,
      });
      return;
    }
    this.invoice.invoiceServiceRows.forEach((item, index) => {
      if (item === invoiceRow) {
        this.invoice.invoiceServiceRows.splice(index, 1);
      }
    });
    this.updateInvoice();
  }

  serviceChanged(invServiceRow: InvoiceServiceRow, fullUpdate: boolean) {
    if (this.invoice.invoiceServiceRows) {
      // this.invoice.invoiceServiceRows.forEach(invServiceRow => {
      console.log('got row ' + invServiceRow);
      if (invServiceRow.serviceNumber) {
        console.log('Service number is ' + invServiceRow.serviceNumber);
        const serviceDto = this.getService(invServiceRow.serviceNumber);
        if (serviceDto) {
          invServiceRow.unitPrice = serviceDto.unitPrice;
          console.log('set unit price to ' + serviceDto.unitPrice);
          invServiceRow.tax = serviceDto.tax;

        } else {
          console.log('failed to get service');
        }
      }
      // });
    }
    this.setEndTimeFromStartRow(invServiceRow);
    if (fullUpdate) {
      this.updateInvoice();
    } else {
      this.updateInvoiceLite();
    }
  }

  eiChanged(row: InvoiceServiceRow, entityInstanceId: string) {
    console.log('eiChanged:' + entityInstanceId);

    if (row.entityInstanceId) {
      console.log('row is ' + row.entityInstanceId.id);
    }
    row.entityInstanceId = <EntityInstanceId>{};
    row.entityInstanceId.id = entityInstanceId;
    if (row.entityInstanceId) {
      console.log('row is ' + row.entityInstanceId.id);
    }
    this.updateInvoice();
  }

  serviceUserChanged() {
    console.log('service user changed');
    this.updateInvoiceLite();
  }

  getService(serviceId: string): ConsumerService {

    let retConsumerService: ConsumerService;

    if (this.consumerServices) {
      this.consumerServices.forEach(serviceDto => {
        if (serviceDto.id === serviceId) {
          console.log('found match!');
          retConsumerService = serviceDto;
          return serviceDto;
        } else {
          console.log('no match');
        }
      });
    }
    return retConsumerService;
  }

  setUserOptVal(user: UserDto): UserId {
    return { id: user.id, userContextType: UserId.UserContextTypeEnum.UserId };
  }

  compareUserFn(user1: UserDto, user2: UserDto) {
    // console.log('comparing');
    return user1 && user2 && user1.id === user2.id;
  }

  compareTimeFn(user1: string, user2: string) {
    // console.log('comparing');
    return user1 && user2 && user1 === user2;
  }
  updateInvoice() {
    const req: UpdateInvoiceComp = <UpdateInvoiceComp>{};
    req.contextIdDto = this.contextIdDto;
    req.invoice = this.invoice;
    req.entityInstanceId = this.viewContext.entityInstanceId;

    // Ok, so what he was trying to do here.  Was because Australian dates are set to
    // 12 hours ahead, if they book a date/time it can get set to the wrong day as it uses local javascript date

    /*req.invoice.invoiceServiceRows.forEach((row) => {
      if (row.startDate) {
        console.log('resetting start date from', row.startDate);
        row.startDate = this.dateUtils.getDateAsStringDash(row.startDate);
        console.log('resetting start date to', row.startDate);
      }
      if (row.endDate) {
        row.endDate = this.dateUtils.getDateAsStringDash(row.endDate);
      }
    })*/

    this.invoicecompService.updateInvoicePanel(req)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.invoicePanelDto = response.invoicePanelDto;
        // @ts-ignore
        this.invoice = response.invoicePanelDto.invoice;
        console.log('response.changedElementList ' + response.changedElementList);
        // @ts-ignore
        this.ecs.handleChangedElements(response.changedElementList, this.form);
        this.invoiceUpdated.emit(response.invoicePanelDto.invoice);
        console.log('invoiceMulti adding invoice changed ' + this.changeListener);
        if (this.changeListener) {
          this.changeListener.add('invoiceChanged');
        }
        this.setValid();
      });
  }

  updateInvoiceLite() {
    //  this.updateInvoice();
    //  console.log('startTimeChanged', this.invoice);
    const req: UpdateInvoiceComp = <UpdateInvoiceComp>{};
    req.contextIdDto = this.contextIdDto;
    req.invoice = _.cloneDeep(this.invoice);
    req.entityInstanceId = this.viewContext.entityInstanceId;


    /*req.invoice.invoiceServiceRows.forEach((row) => {
      if (row.startDate) {
        console.log('resetting start date from', row.startDate);
        row.startDate = this.dateUtils.getDateAsStringDash(row.startDate);
        console.log('resetting start date to', row.startDate);
      }
      if (row.endDate) {
        row.endDate = this.dateUtils.getDateAsStringDash(row.endDate);
      }
    })*/

    this.invoicecompService.updateInvoicePanel(req)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        //    this.invoicePanelDto = response.invoicePanelDto;
        //  this.invoice = response.invoicePanelDto.invoice;
        console.log('response.changedElementList ' + response.changedElementList);
        //    this.ecs.handleChangedElements(response.changedElementList, this.form);
        //   this.invoiceUpdated.emit(response.invoicePanelDto.invoice);
        console.log('invoiceMulti adding invoice changed ' + this.changeListener);
        //  this.changeListener.add('invoiceChanged');
        this.eventBus.addCalendarEvent(EventType.REFRESH_CALENDAR);
        this.setValid();
      });
  }


  createNewService(rowIndex) {
    this.createService(rowIndex);
  }

  createService(rowIndex) {
    const dialogRef = this.dialog.open(AddServiceDialogComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        entityDefinitionLabel: 'Service',
        eventBus: this.eventBus
      }
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this.destroy$)).subscribe(result => {
        console.log('back from dialog close');
        if (result && result.id) {
          console.log('got a new instance to link to ', result);
          this.loadAndSetService(result.id, rowIndex);
        }
      });
  }

  loadAndSetService(serviceId: string, rowIndex) {
    console.log('loading new services');
    this.servicesHelperService.getConsumerServices(this.contextIdDto).then(consumerServices => {
      if (consumerServices.length) {
        this.consumerServices = consumerServices;
        this.filteredServices.next(this.consumerServices);
        this.setSelectedService(serviceId, rowIndex);
      }
    });
  }

  setSelectedService(serviceNumber, rowIndex) {
    if (this.invoice.invoiceServiceRows && this.invoice.invoiceServiceRows.length > 0) {
      const invServiceRow = this.invoice.invoiceServiceRows[rowIndex];
      console.log('got row ' + invServiceRow);
      invServiceRow.serviceNumber = serviceNumber;
      console.log('Service number is ' + invServiceRow.serviceNumber);
      const serviceDto = this.getService(invServiceRow.serviceNumber);
      if (serviceDto) {
        invServiceRow.unitPrice = serviceDto.unitPrice;
        console.log('set unit price to ' + serviceDto.unitPrice);
        invServiceRow.tax = serviceDto.tax;
        this.invoice.invoiceServiceRows[rowIndex] = invServiceRow;
      } else {
        console.log('failed to get service');
      }
    }
    this.setValid();
  }

  filterServices(value: string) {
    this.servicesHelperService.getConsumerServices(this.contextIdDto).then(consumerServices => {
      if (!consumerServices) {
        return;
      }
      // get the search keyword
      if (!value) {
        this.filteredServices.next(consumerServices.slice());
        return;
      }
      const search = value.toLowerCase();
      // filter the consumerServices
      this.filteredServices.next(consumerServices.filter(serviceDto => serviceDto?.serviceName.toLowerCase().indexOf(search) > -1));
    });
  }

  displayFn(id) {
    if (!id) {
      return '';
    }
    const index = this.consumerServices.findIndex(state => state.entityInstanceId.id === id);
    if (index === -1) {
      return '';
    } else {
      return this.consumerServices[index]?.serviceName;
    }
  }

}

