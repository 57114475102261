<mat-accordion *ngIf="listEntityListDto && listEntityListDto.rows.length > 0">
  <!-- [expanded]="step === 0" -->
  <mat-expansion-panel *ngFor="let anEntity of listEntityListDto.rows" (opened)="setStep(0)" >
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{anEntity.displayText}}
      </mat-panel-title>
      <!--mat-panel-description>
        Type your name and age
      </mat-panel-description-->
    </mat-expansion-panel-header>

    <app-view-entity-simple
            [contextIdDto]="contextIdDto"
            [eventBus]="eventBus"
            [viewDefinitionId]="viewCachedEntitiesExpandableDefinition.viewDefinitionId.id"
            [entityInstanceId]="anEntity.entityInstanceId.id">

    </app-view-entity-simple>

  </mat-expansion-panel>
</mat-accordion>
