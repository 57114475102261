
import { Component, Input, OnInit } from '@angular/core';
import { ViewContextDto } from '@savvy/view-composite';
import { UntypedFormGroup } from '@angular/forms';
import { ChangeListener } from './changeListener';
import { EventBusService } from './EventBusService';
import { Page } from '../shared/model/page';
import { Router } from '@angular/router';
import { NavigateService } from '../entry/NavigateService';
import { TrialExpiredService } from '../shared/services/trial-expired.service';
import { CachedlistcompService } from '@savvy/cached-view';
import { ContextIdDto, ListEntityListDto, ListEntityListItemDto, ViewCachedEntitiesExpandableDefinition } from '@savvy/view-definition';

@Component({
  selector: 'app-view-expandable-cached-entities',
  templateUrl: 'viewExpandableCachedEntities.component.html'
})
export class ViewExpandableCachedEntitiesComponent implements OnInit {

  @Input() viewCachedEntitiesExpandableDefinition: ViewCachedEntitiesExpandableDefinition;
  @Input() contextIdDto: ContextIdDto;
  @Input() viewContext: any;
  @Input() form: UntypedFormGroup;
  @Input() eventBus: EventBusService;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;


  page = new Page();
  selected: ListEntityListItemDto[] = [];
  listEntityListDto: ListEntityListDto;
  step = 0;



  columns = [
    { prop: 'displayText', name: '' }
  ];

  constructor(
    private router: Router,
    private naviateService: NavigateService,
    public trialExpiredService: TrialExpiredService,
    private cachedListCompositeApi: CachedlistcompService) {
  }
  ngOnInit() {
    this.step = 0;
    this.page.size = 10;
    this.setPage({ offset: 0 });
  }
  setPage(pageInfo) {
    console.log('pageInfo is ' + pageInfo.offset);
    this.page.pageNumber = pageInfo.offset;
    this.loadPage();
  }

  loadPage() {
    // Call list display names and pass page number

    console.log('loading page:');
    if (this.viewContext.viewContextTypeDto ===
      ViewContextDto.ViewContextTypeDtoEnum.ViewEntityInstance ||
      this.viewContext.viewContextTypeDto ===
      ViewContextDto.ViewContextTypeDtoEnum.AddEditEntityInstance) {
      console.log('loading page from ei' + this.viewContext.viewContextTypeDto);
      this.cachedListCompositeApi.listCachedEntityListByEiId(
        this.viewCachedEntitiesExpandableDefinition.listCachedEntityListDefinition.cachedLineViewDefinitionId.id,
        this.viewContext.entityInstanceId.id,
        this.viewContext.entityDefinitionId.id,
        (this.page.pageNumber + 1),
        this.page.size,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType)
        .subscribe(response => {
          this.listEntityListDto = response.listEntityListDto;
          this.page.totalElements = response.listEntityListDto.totalRows;
          this.page.totalPages = response.listEntityListDto.totalPages;
        });
    } else if (this.viewContext.viewContextTypeDto === ViewContextDto.ViewContextTypeDtoEnum.Customer) {
      console.log('loading page from customer');
      this.cachedListCompositeApi.listCachedEntityListByCustomerId(
        this.viewCachedEntitiesExpandableDefinition.listCachedEntityListDefinition.cachedLineViewDefinitionId.id,
        this.viewContext.customerContextDto.customerId.id,
        (this.page.pageNumber + 1),
        this.page.size,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType).subscribe(response => {
          this.listEntityListDto = response.listEntityListDto;
          this.page.totalElements = response.listEntityListDto.totalRows;
          this.page.totalPages = response.listEntityListDto.totalPages;
        });
    } else {
      console.log('ERROR');
      /*
      this.cachedListCompositeApi.listCachedEntityList(
        this.listCachedEntityListDefinition.cachedLineViewDefinitionId.id,
        (this.page.pageNumber + 1),
        this.page.size,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType).subscribe(loadProductsResponse => {
        this.listEntityListDto = loadProductsResponse.listEntityListDto;
        this.page.totalElements = loadProductsResponse.listEntityListDto.totalRows;
        this.page.totalPages = loadProductsResponse.listEntityListDto.totalPages;
      });*/
    }
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
}
