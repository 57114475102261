<div>
  <section class="app-discount-price-view"
    *ngIf="(finalPrice > 0 && originalPrice > 0 && finalPrice !== originalPrice); else noDiscount">
    <p class="mat-text-red originalprice strikethrough">{{originalPrice | currency:currencyCode:'symbol'}}</p>
    <strong>{{finalPrice | currency:currencyCode:'symbol'}}</strong>
  </section>
  <ng-template #noDiscount>
    <strong>{{finalPrice | currency:currencyCode:'symbol'}}</strong>
  </ng-template>
</div>
