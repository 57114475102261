import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { BoardingBooking, BoardingBookingService, BoardingBookingsForCreateInvoiceDto, ContextIdDto, CreateBoardingBookingsInvoices, LoadBoardingBookingsForCreateInvoices } from '@savvy/pet-stay';
import * as moment from 'moment';
import { DateUtils } from 'src/app/flo/dates/DateUtils';
import { FloSnackbarComponent } from 'src/app/flo/snackbar/floSnackbar.component';

class Duration {
  type: string;
  label: string;
}

interface CustomerInvoiceBooking extends BoardingBooking {
  selected: boolean;
}

export class GenerateCustomerInvoiceModalData {
  contextIdDto: ContextIdDto;
  currencyCode: string;
  customerId: string;
}
@Component({
  selector: 'app-generate-customer-invoice-modal',
  templateUrl: './generate-customer-invoice-modal.component.html',
  styleUrls: ['./generate-customer-invoice-modal.component.scss']
})
export class GenerateCustomerInvoiceModalComponent implements OnInit {
  selectedDuration = 'custom';
  durations: Duration[] = [
    // {
    //   type: 'all',
    //   label: 'All Pending'
    // },
    {
      type: 'thisWeek',
      label: 'This Week'
    },
    {
      type: 'lastWeek',
      label: 'Last Week'
    },
    {
      type: 'thisMonth',
      label: 'This Month'
    },
    {
      type: 'lastMonth',
      label: 'Last Month'
    },
    {
      type: 'custom',
      label: 'Custom date range'
    }
  ];
  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  startDate: Date = moment(new Date()).startOf('day').toDate();
  endDate: Date = moment(new Date()).endOf('day').toDate();
  includeNoShow = false;
  boardingBookingsForCreateInvoiceDto: BoardingBookingsForCreateInvoiceDto;
  selectedBookingType: 'invoiceCompleted' | 'invoicePending' = 'invoiceCompleted';
  appointments: CustomerInvoiceBooking[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: GenerateCustomerInvoiceModalData,
    public dialogRef: MatDialogRef<GenerateCustomerInvoiceModalComponent>,
    private boardingBookingService: BoardingBookingService,
    private notify: FloSnackbarComponent,
    private dateUtils: DateUtils
  ) { }

  ngOnInit() {
    this.range.valueChanges.subscribe(value => {
      console.log(value);
      if (value.start && value.end) {
        this.startDate = value.start;
        this.endDate = value.end;
        this.loadBoardingBookingsForCreateInvoices();
      }
    });
    this.range.patchValue({
      start: moment(new Date()).startOf('day').toDate(),
      end: moment(new Date()).endOf('day').toDate()
    })
  }

  selectedDurationChanged(newDuration: string) {
    switch (newDuration) {
      // case 'all':
      //   break;
      case 'thisWeek':
        this.startDate = moment(new Date()).startOf('isoWeek').toDate();
        this.endDate = moment(new Date()).endOf('isoWeek').toDate();
        break;
      case 'lastWeek':
        this.startDate = moment(new Date()).subtract(1, 'week').startOf('isoWeek').toDate();
        this.endDate = moment(new Date()).subtract(1, 'week').endOf('isoWeek').toDate();
        break;
      case 'thisMonth':
        this.startDate = moment(new Date()).startOf('month').toDate();
        this.endDate = moment(new Date()).endOf('month').toDate();
        break;
      case 'lastMonth':
        this.startDate = moment(new Date()).subtract(1, 'month').startOf('month').toDate();
        this.endDate = moment(new Date()).subtract(1, 'month').endOf('month').toDate();
        break;
    }
    this.loadBoardingBookingsForCreateInvoices();
  }

  loadBoardingBookingsForCreateInvoices() {
    const loadBoardingBookingsForCreateInvoices: LoadBoardingBookingsForCreateInvoices = {
      contextIdDto: this.data.contextIdDto,
      start: this.dateUtils.getDateTimeAsIsoString(this.startDate, true),
      end: this.dateUtils.getDateTimeAsIsoString(this.endDate, true),
      includeNoShows: this.includeNoShow,
      customerId: this.data.customerId,
    };
    this.appointments = [];
    this.boardingBookingService.loadBoardingBookingsForCreateInvoices(loadBoardingBookingsForCreateInvoices).subscribe(res => {
      if (res?.boardingBookingsDto) {
        this.boardingBookingsForCreateInvoiceDto = res?.boardingBookingsDto;
        if (res.boardingBookingsDto?.pendingBoardingBookings?.length) {
          for (const pendingBoardingBooking of res.boardingBookingsDto.pendingBoardingBookings) {
            this.appointments.push({
              ...pendingBoardingBooking,
              selected: true
            });
          }
        }
        if (res.boardingBookingsDto?.completedBoardingBookings?.length) {
          for (const completedBoardingBooking of res.boardingBookingsDto.completedBoardingBookings) {
            this.appointments.push({
              ...completedBoardingBooking,
              selected: true
            });
          }
        }
      }
    });
  }

  close() {
    this.dialogRef.close();
  }

  generateInvoice() {
    const selectedAppointments = this.appointments.filter(ap => ap.selected);
    if (!selectedAppointments?.length) {
      this.notify.message = 'Select at least one appointment to continue';
      this.notify.open();
      return;
    }
    const createBoardingBookingsInvoices: CreateBoardingBookingsInvoices = {
      boardingBookingIds: selectedAppointments.map(a => a.id) as string[],
      contextIdDto: this.data.contextIdDto
    };
    this.boardingBookingService.createBoardingBookingInvoicesByIds(createBoardingBookingsInvoices).subscribe(res => {
      if (res?.successList?.length) {
        console.log('Successful creation of ids');
        console.log(res?.successList);
      }
      if (res?.failedList?.length) {
        console.log('Successful failed of ids');
        console.log(res?.failedList);
      }
      this.close();
    });
  }

  selectedBookingTypeChanged(event: MatRadioChange) {
    if (event?.value) {
      this.selectedBookingType = event?.value;
    }
  }

}
