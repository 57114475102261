<mat-card appearance="outlined" class="mdc-mb-1" *ngIf="portalSettingsLocalService.portalEnabled && customerDto">
  <mat-card-header class="mdc-mb-1 d-block">
    <div fxLayout="row" fxLayoutAlign=" center" class="w-100">
      <div fxFlex>
        <h6>{{"CUSTOMER_PORTAL" | translate}}<br>
          <small class="mat-text-secondary fw-normal">
            <span [matTooltip]="'CUSTOMER_PORTAL_TOOLTIP' | translate" matTooltipClass="example-tooltip-red" aria-label="Button that shows a red tooltip" class="small vm">
              More Info
              <mat-icon class="vm mat-text-theme">info</mat-icon>
            </span>
          </small>
        </h6>
      </div>
      <div fxFlex="nogrow">
        <button color="accent" mat-button (click)="gotoPortal()" class="">
          {{"PORTAL" | translate}} <mat-icon>open_in_new</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-header>

  <mat-card-content>
    <div class="showhidethis">
      <button color="accent" mat-raised-button (click)="registerToPortal()" class="mdc-mb-2">{{'INVITE_TO_PORTAL' | translate}}</button>
      <p class="mat-text-secondary mdc-mb-1">{{'INVITATION_LINK_CUSTOMER_PORTAL' | translate}}</p>
    </div>
    <!-- once invitation send  "d-none" remove and add on above "d-none"-->
    <div class="showhidethis d-none">
      <button color="accent" mat-raised-button disabled>{{'INVITE_TO_PORTAL' | translate}}</button>
      <p class="mat-text-secondary mt-1 mdc-mb-2">{{"INVITATION_SENT" | translate}}</p>
    </div>

    <!-- for on-boarded user set password-->
    <!-- <button class="mb-0" color="accent" mat-raised-button (click)="resetPassword()">{{'SET_NEW_PASSWORD' |
      translate}}</button> -->

  </mat-card-content>
</mat-card>