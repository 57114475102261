<mat-toolbar color="primary">
  <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="10px" class="w-100">
    <div *ngIf="pageType === 'addEditCustomer'" fxFlex>
      <span>{{'EDIT_CUSTOMER' | translate}}</span>
    </div>
    <div *ngIf="pageType === 'addCustomer'" fxFlex>
      <span>{{'CREATE_CUSTOMER' | translate}}</span>
    </div>
    <div *ngIf="pageType === 'addCustomer'" fxFlex class="text-center">
      <mat-button-toggle-group [(ngModel)]="createManually" name="createManually" aria-label="Create Customer Mode"
        class="button-group-payments">
        <mat-button-toggle [value]="true">{{'MANUALLY' | translate: {Default: 'Manual'} }}</mat-button-toggle>
        <mat-button-toggle [value]="false">{{'INVITE' | translate: {Default: 'Invite'} }}</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div fxFlex="nogrow" fxFlex.gt-sm class="text-end" align="end">
      <button mat-icon-button aria-label="Close" (click)="cancel()">
        <mat-icon class="full-screen-icon">close</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
<div>
  <div class="invoice-container" fxFlex="100" fxFlex.xs="100">
    <div *ngIf="createManually && model && model.optIn">
      <!-- <ng-container *ngIf="pageType === 'addEditCustomer'; else modalTpl">
        <ng-template [ngTemplateOutlet]="contentTpl"></ng-template>
      </ng-container>

      <ng-template #modalTpl>
        <mat-dialog-content class="m-0 mdc-p-3">
          <ng-template [ngTemplateOutlet]="contentTpl"></ng-template>
        </mat-dialog-content>
      </ng-template> -->

      <!-- <ng-template #contentTpl> -->

      <mat-dialog-content class="m-0 mdc-p-3">


        <mat-accordion class="" [multi]="true">

          <!-- customer details -->
          <mat-expansion-panel class="mx-0" [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="mdc-mr-2">person</mat-icon> Customer Details
              </mat-panel-title>
            </mat-expansion-panel-header>

            <form #customerForm="ngForm" class="customer-form w-100">
              <div fxLayout.gt-xs="row wrap" fxLayout="column" fxLayoutGap="1%" class="split-panel">
                <div fxFlex.gt-sm="32" fxFlex="100">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'FIRST_NAME' | translate}}</mat-label>
                    <input matInput [(ngModel)]="model.firstName" name="firstName"
                      (change)="updateForm(customerForm.form.valid)" #firstName="ngModel" class="demo-full-width"
                      placeholder="{{'FIRST_NAME' | translate}}" required>
                    <mat-error *ngIf="firstName && firstName.hasError('required')">
                      {{'FIRST_NAME_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
                    </mat-error>
                  </mat-form-field>
                </div>

                <div fxFlex.gt-sm="32" fxFlex="100">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'SURNAME' | translate}}</mat-label>
                    <input matInput [(ngModel)]="model.lastName" name="surname"
                      (change)="updateForm(customerForm.form.valid)" #surname="ngModel" class="demo-full-width"
                      placeholder="{{'SURNAME' | translate}}" required>
                    <mat-error *ngIf="surname.hasError('required')">
                      {{'SURNAME_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
                    </mat-error>
                  </mat-form-field>
                </div>

                <div fxFlex.gt-sm="32" fxFlex="100">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'EMAIL' | translate}}</mat-label>
                    <input matInput appInputTrim (change)="updateForm(customerForm.form.valid)"
                      [(ngModel)]="model.username" name="username" #username="ngModel" class="demo-full-width"
                      placeholder="{{'EMAIL' | translate}}" email (blur)="checkIfCustomerExist()">
                    <div class="control-error" *ngIf="username && username.hasError('email')">
                      {{'VALID_EMAIL' | translate}}
                    </div>
                    <div class="control-error" *ngIf="username && username.hasError('userNameExist')">
                      {{'ACCOUNT_WITH_EMAIL_EXISTS' | translate}}
                    </div>
                  </mat-form-field>
                </div>

                <div fxFlex.gt-sm="32" fxFlex="100">
                  <app-phone-input name="mobilePhoneNumber"
                    [(ngModel)]="localPhones.mobilePhoneNumberObj.nationalNumber"
                    [ngModelOptions]="{ updateOn: 'blur' }" (phoneChanged)="onMobilePhoneChange($event)"
                    (countryChanged)="onMobileCountryChange($event)"
                    [selectedCountryCode]="localPhones.mobilePhoneNumberObj?.country" label="Mobile"
                    placeholder="{{'MOBILE' | translate}}" errorName="Mobile" required="false"
                    (change)="updateForm(customerForm.form.valid)"></app-phone-input>
                </div>

                <div fxFlex.gt-sm="32" fxFlex="100">
                  <app-phone-input name="phoneNumber" [(ngModel)]="localPhones.phoneNumberObj.nationalNumber"
                    [ngModelOptions]="{ updateOn: 'blur' }" (phoneChanged)="onPhoneChanged($event)"
                    (countryChanged)="onPhoneCountryChange($event)"
                    [selectedCountryCode]="localPhones.phoneNumberObj?.country" errorName="Phone"
                    placeholder="{{'PHONE' | translate}}" label="{{'PHONE' | translate}}" required="false"
                    (change)="updateForm(customerForm.form.valid)"></app-phone-input>
                </div>

                <div fxFlex.gt-sm="32" fxFlex="100">
                  <app-phone-input name="workPhoneNumber" [(ngModel)]="localPhones.workPhoneNumberObj.nationalNumber"
                    [ngModelOptions]="{ updateOn: 'blur' }" (phoneChanged)="onWorkPhoneChanged($event)"
                    (countryChanged)="onWorkPhoneCountryChange($event)"
                    [selectedCountryCode]="localPhones.workPhoneNumberObj?.country" errorName="Work Phone"
                    placeholder="{{'WORK_PHONE' | translate}}" label="{{'WORK_PHONE' | translate}}" required="false"
                    (change)="updateForm(customerForm.form.valid)"></app-phone-input>
                </div>

                <div fxFlex.gt-sm="32" fxFlex="100">
                  <mat-form-field appearance="outline" *ngIf="customerSourceList" #sourceField class="w-100">
                    <mat-label>{{'SOURCE' | translate}}</mat-label>
                    <mat-select [(ngModel)]="model.customerSource" name="customerSource"
                      (selectionChange)="updateForm(customerForm.form.valid)" #customerSource="ngModel" required="false"
                      placeholder="{{ 'SOURCE' | translate}}">
                      <mat-option [value]=""></mat-option>
                      <mat-option *ngFor="let item of customerSourceList.dataItems" [value]="item.name">{{item.label}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <!-- <div fxFlex.gt-sm="32" fxFlex="100">
                    <mat-checkbox (change)="companyChanged();updateForm(customerForm.form.valid)" [checked]="company"
                      [(ngModel)]="company" class="example-margin w-100 mt-1 mdc-mb-1" name="company">
                      {{'CLIENT_COMPANY' | translate}}
                    </mat-checkbox>
                  </div> -->

                <!-- <app-phone-input name="whatsAppNumber" [(ngModel)]="localPhones.whatsAppNumberObj.nationalNumber" [ngModelOptions]="{ updateOn: 'blur' }"
                        (intPhoneChanged)="model.userDto.whatsAppNumberObj.nationalNumber = $event"
                        [selectedCountryCode]="localPhones.whatsAppNumberObj?.country"
                        errorName="Whats App" placeholder="Whats App Number" label="Customer Whats App" required="false"
                        (change)="updateForm(customerForm.form.valid)">
                      </app-phone-input> -->

                <div *ngIf="company" fxFlex.gt-sm="32" fxFlex="100">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'COMPANY_NAME' | translate}}</mat-label>
                    <input matInput autofocus (change)="updateForm(customerForm.form.valid)"
                      [(ngModel)]="model.companyName" name="companyName" #companyName="ngModel" class="demo-full-width"
                      placeholder="{{'ENTER_COMPANY_NAME' | translate}}">

                    <mat-error *ngIf="companyName.hasError('required')">
                      {{'COMPANY_NAME_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
                    </mat-error>
                  </mat-form-field>
                </div>

                <div *ngIf="company" fxFlex.gt-sm="32" fxFlex="100">
                  <app-phone-input name="workPhoneNumber" [(ngModel)]="model.companyPhoneNumber.nationalNumber"
                    [ngModelOptions]="{ updateOn: 'blur' }" (phoneChanged)="onCompanyPhoneNumberChange($event)"
                    (countryChanged)="onCompanyPhoneCountryChange($event)"
                    [selectedCountryCode]="model?.companyPhoneNumber?.country" label="Company Phone Number"
                    placeholder="{{'COMPANY_NUMBER' | translate}}" errorName="companyPhoneNumber"
                    required="false"></app-phone-input>
                </div>

              </div>

            </form>

          </mat-expansion-panel>

          <!-- pets details -->
          <mat-expansion-panel class="mx-0" *ngIf="showAddPet" [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="mdc-mr-2">pets</mat-icon> {{'PETS' | translate}} Details
              </mat-panel-title>
            </mat-expansion-panel-header>

            <app-customer-pet-details *ngIf="showAddPet && model?.id" #customerPetDetailsComponent
              [contextIdDto]="contextIdDto" [customerDto]="model" [isViewMode]="false">
            </app-customer-pet-details>

            <app-add-new-pet-v2 *ngIf="showAddPet && !model?.id" [maxPets]="data.maxPets" [showTitle]="false"
              fxFlex="100" #newPetSelectorV2Component [contextIdDto]="contextIdDto" [customerId]="model?.id"
              (petsFormGroupChange)="petsFormGroupChange($event)" [multiple]="true">
            </app-add-new-pet-v2>

          </mat-expansion-panel>

          <!-- address  details -->
          <mat-expansion-panel class="mx-0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="mdc-mr-2">location_on</mat-icon> {{'Address Details' | translate}}
              </mat-panel-title>
              <!-- <mat-panel-description class="d-none d-md-flex">
                {{'CUSTOMER_ADDRESS_INFO' | translate}}
              </mat-panel-description> -->
            </mat-expansion-panel-header>

            <h6 class="d-block d-md-none"> {{'CUSTOMER_ADDRESS_INFO' | translate}}</h6>
            <app-address-lookup [contextIdDto]="contextIdDto" [address]="model?.address"
              (modelUpdate)="addressChanged($event)"></app-address-lookup>

          </mat-expansion-panel>

          <!--  communication details -->
          <mat-expansion-panel class="mx-0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="mdc-mr-2">notifications</mat-icon> Communication and Marketing
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div fxLayout.gt-xs="row" fxLayout="column" fxLayoutGap="0px" fxLayoutGap.gt-xs="15px" fxLayoutWrap="wrap"
              class="split-panel">
              <div fxFlex.gt-sm="50" fxFlex.gt-xs="50" fxFlex="100">
                <mat-card appearance="outlined">
                  <mat-card-header class="mdc-mb-1">
                    <h6>{{'COMMUNICATION' | translate}}</h6>
                  </mat-card-header>
                  <mat-card-content>

                    <mat-list *ngIf="model?.optIn">
                      <mat-list-item>
                        <span fxFlex>{{'EMAIL' | translate}}</span>
                        <mat-slide-toggle name="allowEmail" #allowEmail="ngModel"
                          (change)="updateForm(customerForm.form.valid)" [(ngModel)]="model.optIn.allowEmail">
                        </mat-slide-toggle>
                      </mat-list-item>

                      <mat-list-item>
                        <span fxFlex>{{'SMS' | translate}}</span>
                        <mat-slide-toggle name="allowSms" #allowSms="ngModel"
                          (change)="updateForm(customerForm.form.valid)" [(ngModel)]="model.optIn.allowSms">
                        </mat-slide-toggle>
                      </mat-list-item>

                      <!-- <mat-list-item>
                        <span fxFlex>{{'WhatsApp' | translate}}</span>
                        <mat-slide-toggle name="allowWhatApp" #allowWhatApp="ngModel"
                          (change)="updateForm(customerForm.form.valid)" [(ngModel)]="model.userDto.optIn.allowWhatApp">
                        </mat-slide-toggle>
                      </mat-list-item> -->
                    </mat-list>
                  </mat-card-content>
                </mat-card>

              </div>

              <!-- <div fxShow.gt-xs="true" fxShow="false" fxFlex.gt-sm="2" fxFlex.gt-xs="2" fxFlex="100">
              </div> -->

              <div fxFlex.gt-sm="50" fxFlex.gt-xs="50" fxFlex="100">

                <mat-card appearance="outlined">
                  <mat-card-header class="mdc-mb-1">
                    <h6>{{'MARKETING' | translate}}</h6>
                  </mat-card-header>
                  <mat-card-content>
                    <mat-list>
                      <mat-list-item>
                        <span fxFlex>{{'EMAIL' | translate}}</span>
                        <mat-slide-toggle name="allowEmailMarketing" #allowEmailMarketing="ngModel"
                          (change)="updateForm(customerForm.form.valid)"
                          [(ngModel)]="model.optIn.allowEmailMarketing"></mat-slide-toggle>
                      </mat-list-item>

                      <mat-list-item>
                        <span fxFlex>{{'SMS' | translate}}</span>
                        <mat-slide-toggle name="allowSmsMarketing" #allowSmsMarketing="ngModel"
                          (change)="updateForm(customerForm.form.valid)"
                          [(ngModel)]="model.optIn.allowSmsMarketing"></mat-slide-toggle>
                      </mat-list-item>

                      <!-- <mat-list-item>
                        <span fxFlex>{{'WhatsApp' | translate}}</span>
                        <mat-slide-toggle name="allowWhatsAppMarketing" #allowWhatsAppMarketing="ngModel" color="accent"
                          (change)="updateForm(customerForm.form.valid)"
                          [(ngModel)]="model.userDto.optIn.allowWhatsAppMarketing"></mat-slide-toggle>
                      </mat-list-item> -->
                    </mat-list>
                  </mat-card-content>
                </mat-card>

              </div>
            </div>

          </mat-expansion-panel>

          <!-- customer notes -->
          <mat-expansion-panel class="mx-0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="mdc-mr-2">edit_note</mat-icon> Customer Notes
              </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-form-field appearance="outline" class="w-100">
              <mat-label>{{'NOTES' | translate}}</mat-label>
              <textarea matInput class="notes" #notes="ngModel" (change)="updateForm(customerForm.form.valid)"
                name="notes" [(ngModel)]="model.notes" [maxlength]="900"
                placeholder="{{'NOTES' | translate}}"></textarea>
            </mat-form-field>

          </mat-expansion-panel>

          <!-- portal invite details -->
          <!-- <mat-expansion-panel class="mx-0" *ngIf="!model?.customer?.id">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon class="mdc-mr-2">public</mat-icon> Invite to Customer Portal
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div fxLayout="row">
              </div>
            </mat-expansion-panel> -->


          <mat-expansion-panel class="mx-0" *ngIf="customerIdLinkId">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="mdc-mr-2">flag</mat-icon> Flags
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div fxLayout="row">
              <app-customer-flags-panel class="w-100" [contextIdDto]="contextIdDto" [flagIds]="model.flags"
                (createFlag)="createFlag($event)" (deleteFlag)="deleteFlag($event)">
              </app-customer-flags-panel>
            </div>
          </mat-expansion-panel>

          <app-onboard-customer *ngIf="!model?.id" #onboardCustomerComponent [contextIdDto]="contextIdDto"
            (onboardUpdated)="onboardUpdated($event)">
          </app-onboard-customer>


        </mat-accordion>

        <br>
        <div fxLayout="row wrap" *ngIf="!model.id">
          <div fxFlex>
            <button mat-raised-button (click)="cancelIt()">{{'CLOSE' |
              translate}}</button>
          </div>
          <div fxFlex="nogrow">
            <button *ngIf="pageType === 'addCustomer'" mat-raised-button color="accent" type="submit"
              (click)="addCustomer()"
              [disabled]="!customerForm?.form?.valid || (showAddPet && !addNewPetV2Component?.valid)">{{'CREATE' |
              translate}}
            </button>
            <!-- <button *ngIf="model.customer.id" mat-raised-button color="accent" type="submit"
                (click)="updateCustomer()"
                [disabled]="!customerForm.form.valid || (showAddPet && !addNewPetV2Component?.valid)">{{'UPDATE' |
                translate}}</button> -->
          </div>
        </div>



      </mat-dialog-content>
      <!-- </ng-template> -->
    </div>
    <ng-container *ngIf="!createManually">
      <app-invite-customer [contextIdDto]="contextIdDto"></app-invite-customer>
    </ng-container>
  </div>
</div>
