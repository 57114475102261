import { Routes } from '@angular/router';
import { DiscountSettingsComponent } from './discount-settings/discount-settings.component';

export const DiscountSettingsRoutes: Routes = [
    {
        path: '',
        children: [
            { path: 'settings/:contextId/:contextIdType', component: DiscountSettingsComponent },
            // { path: 'settings/:contextId/:contextIdType', component: ViewDiscountsComponent },
        ]
    }
];
