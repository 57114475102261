import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ContextIdDto } from '@savvy/app';
import { AppointmentConfig } from '@savvy/appointment';
import { PlanDto, PlansCompService } from '@savvy/plan';
import { takeUntil } from 'rxjs/operators';
import { PhoneNumberHelperService } from 'src/app/flo/shared/services/phone-number-helper.service';
import * as _ from 'lodash';
import { AppointmentConfigHelperService } from 'src/app/flo/shared/services/appointment-config-helper.service';
import { CustomerCompositeService, Customer, CustomerService } from '@savvy/customer';
import { PaymentSettings, PaymentSettingsService } from '@savvy/payment';
import { PlanDefinition } from '@savvy/plan-definition/model/planDefinition';
import { PlanDefService } from '@savvy/plan-definition';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AddEditPlanDefComponent } from '../../../plan/addEditPlanDef.component';
import { ClearObservable } from 'src/app/flo/shared/classes/clear-observable';
import { UserCurrencyService } from 'src/app/flo/shared/services/userCurrency.service';
import { PetStayPaymentConfig } from '@savvy/pet-stay';
import { CreateBoardingBookingV2SyncService } from '../create-boarding-booking-v2-sync.service';

@Component({
  selector: 'app-boarding-payment',
  templateUrl: './boarding-payment.component.html',
  styleUrls: ['./boarding-payment.component.scss']
})
export class BoardingPaymentComponent extends ClearObservable implements OnInit, OnChanges {

  @Input() contextIdDto: ContextIdDto;
  @Input() customerId: string;
  @Input() totalAmount = 0;
  @Input() paymentConfig: PetStayPaymentConfig;
  @Output() paymentConfigChange: EventEmitter<PetStayPaymentConfig> = new EventEmitter();
  @Output() amountDueChanged: EventEmitter<number> = new EventEmitter();
  @Output() createPlanChanged: EventEmitter<boolean> = new EventEmitter();

  percentage = 0;
  amount = 0;
  currencyCode = 'GBP';

  plans: Array<PlanDto> = [];
  planDefinitions: Array<PlanDefinition> = [];
  selectedPlan: PlanDto = {};
  selectedPlanDefinition: PlanDefinition;
  customerDto: Customer;
  paymentSettings: PaymentSettings;

  invoiceTypeEnum = PetStayPaymentConfig.PetStayInvoiceTypeEnum;
  paymentModeEnum = PetStayPaymentConfig.PetStayPaymentModeEnum;
  paymentTypeEnum = PetStayPaymentConfig.PetStayPaymentTypeEnum;

  constructor(
    private dialog: MatDialog,
    private sharedService: PhoneNumberHelperService,
    private plansCompService: PlansCompService,
    private appointmentConfigHelperService: AppointmentConfigHelperService,
    private customerCompService: CustomerCompositeService,
    private customerService: CustomerService,
    private paymentSettingsApi: PaymentSettingsService,
    private planDefinitionService: PlanDefService,
    private userCurrencyService: UserCurrencyService,
    private createBoardingBookingV2SyncService: CreateBoardingBookingV2SyncService

  ) {
    super();
  }

  setDefaults() {
    // Default
    this.paymentConfig = {
      petStayInvoiceType: 'GENERATE_LATER',
      petStayPaymentMode: 'IN_PERSON',
      petStayPaymentType: this.appointmentConfigHelperService.appointmentConfig.defaultPaymentType,
      amountDue: 0
    } as PetStayPaymentConfig;

    if (this.appointmentConfigHelperService.appointmentConfig.defaultPaymentMode
      === PetStayPaymentConfig.PetStayPaymentModeEnum.DepositRequest) {
      if (this.appointmentConfigHelperService.appointmentConfig.defaultPaymentType
        === AppointmentConfig.DefaultPaymentTypeEnum.Percentage) {
        this.percentage = this.appointmentConfigHelperService.appointmentConfig.defaultPercentage;
        this.calculateAmountDue();
      } else if (this.appointmentConfigHelperService.appointmentConfig.defaultPaymentType
        === AppointmentConfig.DefaultPaymentTypeEnum.Amount) {
        if (this.totalAmount > this.appointmentConfigHelperService.appointmentConfig.defaultAmount) {
          this.paymentConfig.amountDue = _.clone(this.appointmentConfigHelperService.appointmentConfig.defaultAmount);
          this.amount = _.clone(this.paymentConfig.amountDue);
        } else {
          this.paymentConfig.amountDue = _.clone(this.totalAmount);
          this.amount = _.clone(this.totalAmount);
        }
        this.amountDueChanged.emit(this.paymentConfig.amountDue);
      }
    } else if (this.appointmentConfigHelperService.appointmentConfig.defaultPaymentMode
      === PetStayPaymentConfig.PetStayPaymentModeEnum.InPerson) {
      this.paymentConfig.amountDue = 0;
      this.amountDueChanged.emit(this.paymentConfig.amountDue);
    } else {
      this.paymentConfig.amountDue = this.totalAmount;
      this.amountDueChanged.emit(this.paymentConfig.amountDue);
    }
    this.paymentConfigChange.emit(this.paymentConfig);
  }

  ngOnInit(): void {
    if (!this.paymentConfig?.petStayPaymentMode) {
      this.setDefaults();
    } else {
      this.amount = _.clone(this.paymentConfig.amountDue);
      // this.percentage = _.clone(this.paymentConfig.amountDue);
      this.amountDueChanged.emit(this.paymentConfig.amountDue);
    }
    this.loadPlans();
    this.loadCustomer();
    this.loadPaymentSettings();
    this.loadPlanDefinitions();
    this.createBoardingBookingV2SyncService.totalAmountChanged$
      .pipe(takeUntil(this.destroy$))
      .subscribe((newAmount: number) => {
        this.totalAmount = newAmount;
        if (this.paymentConfig.petStayInvoiceType === PetStayPaymentConfig.PetStayInvoiceTypeEnum.UpFront) {
          if (this.paymentConfig.petStayPaymentMode === 'DEPOSIT_REQUEST') {
            if (this.paymentConfig.petStayPaymentType === 'AMOUNT') {
              if (this.amount > newAmount) {
                this.amount = newAmount;
                this.amountChanged(newAmount);
              } else {
                this.paymentConfig.amountDue = _.clone(this.appointmentConfigHelperService.appointmentConfig.defaultAmount);
                this.amount = _.clone(this.paymentConfig.amountDue);
                this.amountDueChanged.emit(this.paymentConfig.amountDue);
              }
            } else if (this.paymentConfig.petStayPaymentType === 'PERCENTAGE') {
              this.calculateAmountDue();
            } else {
              this.paymentConfig.amountDue = _.clone(newAmount);
              this.amount = _.clone(this.paymentConfig.amountDue);
              this.amountDueChanged.emit(this.paymentConfig.amountDue);
            }
          }
        } else {
          this.paymentConfig.amountDue = 0;
          this.amountDueChanged.emit(this.paymentConfig.amountDue);
        }
      });

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.totalAmount?.currentValue !== changes?.totalAmount?.previousValue) {
      this.totalAmount = changes.totalAmount.currentValue;
    }
    if (changes?.paymentConfig?.currentValue && changes?.paymentConfig?.currentValue !== changes?.paymentConfig?.previousValue) {
      this.paymentConfig = changes.paymentConfig.currentValue;
      if (this.paymentConfig.amountDue && this.totalAmount) {
        this.percentage = Number(((this.paymentConfig.amountDue * 100) / this.totalAmount).toFixed(2));
      }
      this.paymentConfigChange.emit(this.paymentConfig);
    }
  }

  getCurrencyCode() {
    this.userCurrencyService.getDefaultCurrency(this.contextIdDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.currencyCode = res.org.currencyCode ? res.org.currencyCode : this.userCurrencyService.defaultCurrency;
      });
  }

  loadPaymentSettings() {
    this.paymentSettingsApi.loadPaymentSettings(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
    )//.pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.paymentSettings = response;
      });

  }

  loadPlans() {
    if (this.customerId) {
      this.plansCompService.loadActiveCustomerPlansComp(this.contextIdDto.contextId,
        this.contextIdDto.contextIdType, this.customerId).subscribe(res => {
          if (res && res.plans && res.plans.length) {
            this.plans = res.plans;
            this.selectedPlan = this.plans[0];
          }
        });
    }
  }

  loadPlanDefinitions() {
    if (this.customerId) {
      this.planDefinitionService.loadByOwnerIdSorted(this.contextIdDto.contextId,
        this.contextIdDto.contextIdType).subscribe(res => {
          if (res && res.planDefinitions && res.planDefinitions.length) {
            this.planDefinitions = res.planDefinitions;
          }
        });
    }
  }

  loadCustomer() {
    if (this.customerId) {
      this.customerService.loadCustomer(this.customerId, this.contextIdDto.contextId,
        this.contextIdDto.contextIdType).subscribe(res => {
          this.customerDto = res;
        });
    }
  }

  amountChanged(event) {
    this.paymentConfig.amountDue = event;
    this.amountDueChanged.emit(this.paymentConfig.amountDue);
  }

  calculateAmountDue() {
    const percentage = Number((Number(this.percentage) / 100).toFixed(2));
    this.paymentConfig.amountDue = this.totalAmount * percentage;
    this.amount = _.clone(this.paymentConfig.amountDue);
    this.amountDueChanged.emit(this.paymentConfig.amountDue);
  }

  goToPlans() {
    const dialogRef = this.dialog.open(AddEditPlanDefComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        customerId: this.customerId
      },
      width: '1080px',
      panelClass: ['scrollable-modal', 'helpwindow']
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadPlanDefinitions();
    });

  }

  planChanged(newPlan: PlanDto) {
    this.paymentConfig.paymentPlanId = newPlan.id;
    this.paymentConfigChange.emit(this.paymentConfig);
  }

  planDefinitionChange(planDefinition: PlanDefinition) {
    if (this.paymentConfig.petStayPaymentMode === PetStayPaymentConfig.PetStayPaymentModeEnum.PaymentPlan
      && this.selectedPlanDefinition && this.selectedPlanDefinition.id) {
      this.createPlanChanged.emit(true);
    } else {
      this.createPlanChanged.emit(false);
    }
  }

  paymentTypeChanged(event) {
    switch (event) {
      case PetStayPaymentConfig.PetStayPaymentTypeEnum.Percentage:
        this.calculateAmountDue();
        break;
      case PetStayPaymentConfig.PetStayPaymentTypeEnum.FullAmount:
        this.paymentConfig.amountDue = _.clone(this.totalAmount);
        this.amount = _.clone(this.paymentConfig.amountDue);
        this.amountDueChanged.emit(this.paymentConfig.amountDue);
        break;
      case PetStayPaymentConfig.PetStayPaymentTypeEnum.Amount:
        this.paymentConfig.amountDue = _.clone(this.amount);
        this.amount = _.clone(this.paymentConfig.amountDue);
        this.amountDueChanged.emit(this.paymentConfig.amountDue);
        break;
    }
    this.paymentConfigChange.emit(this.paymentConfig);

  }

  paymentModeChanged(event) {
    switch (event) {
      case PetStayPaymentConfig.PetStayPaymentModeEnum.DepositRequest:
        this.paymentConfig.petStayPaymentMode = PetStayPaymentConfig.PetStayPaymentModeEnum.DepositRequest;
        if (this.paymentConfig.petStayPaymentType === this.paymentTypeEnum.Percentage) {
          this.calculateAmountDue();
        } else if (this.paymentConfig.petStayPaymentType === this.paymentTypeEnum.Amount) {
          this.paymentConfig.amountDue = this.amount;
        } else {
          this.paymentConfig.amountDue = this.totalAmount;
        }
        this.selectedPlanDefinition = undefined;
        break;
      case PetStayPaymentConfig.PetStayPaymentModeEnum.PaymentPlan:
        this.paymentConfig.petStayPaymentMode = PetStayPaymentConfig.PetStayPaymentModeEnum.PaymentPlan;
        this.paymentConfig.amountDue = this.totalAmount;
        this.paymentConfig.sendSms = false;
        break;
      case PetStayPaymentConfig.PetStayPaymentModeEnum.InPerson:
        this.selectedPlanDefinition = undefined;
        this.paymentConfig.petStayPaymentMode = PetStayPaymentConfig.PetStayPaymentModeEnum.InPerson;
        this.paymentConfig.amountDue = 0;
        break;
    }
    this.amountDueChanged.emit(this.paymentConfig.amountDue);
    this.paymentConfigChange.emit(this.paymentConfig);

  }

  public getPaymentConfig(sendSms = false): PetStayPaymentConfig {
    return {
      petStayInvoiceType: this.paymentConfig.petStayInvoiceType,
      petStayPaymentMode: this.paymentConfig.petStayPaymentMode,
      petStayPaymentType: this.paymentConfig.petStayPaymentType,
      amountDue: this.paymentConfig.amountDue,
      paymentPlanId: this.selectedPlan ? this.selectedPlan.id : null,
      noOfSessionsToRedeem: this.paymentConfig.noOfSessionsToRedeem,
      sendSms: sendSms ? this.paymentConfig.sendSms : false,
      preAuth: this.paymentConfig.preAuth
    };
  }

  public getSelectedPlanDefinition(): PlanDefinition {
    return this.selectedPlanDefinition;
  }

}
