<mat-toolbar color="primary">
  <app-breadcrumb></app-breadcrumb>
</mat-toolbar>

<div class="view-invoice-page" fxLayout="column" fxLayoutAlign="center">

  <mat-card appearance="outlined" *ngIf="invoice">
    <mat-card-content>

      <form #invoiceForm="ngForm">

        <div fxLayout="column">
          <mat-form-field appearance="outline">
            <mat-label>{{'INVOICE_NUMBER' | translate}}</mat-label>
            <input matInput [(ngModel)]="invoice.invoiceNumber" name="invoiceNumber" #name="ngModel" id="invoiceNumber" class="form-control" placeholder="{{'INVOICE_NUMBER' | translate}}" style="width: 100%" required>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{'INVOICE_DATE' | translate}}</mat-label>
            <input matInput [(ngModel)]="invoice.invoiceDate" name="invoiceDate" (focus)="picker.open()" and (click)="picker.open()" #name="ngModel" [matDatepicker]="picker" placeholder="{{'INVOICE_DATE' | translate}}" required>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{'DUE_DATE' | translate}}</mat-label>
            <input matInput [(ngModel)]="invoice.dueDate" name="dueDate" (focus)="dueDatePicker.open()" and (click)="dueDatePicker.open()" #name="ngModel" [matDatepicker]="dueDatePicker" placeholder="{{'DUE_DATE' | translate}}" required>
            <mat-datepicker-toggle matSuffix [for]="dueDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #dueDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
      </form>

    </mat-card-content>

    <mat-card-content class="pl-0 pr-0">
      <ngx-datatable class="material" [rows]="invoice.invoiceProductRows" [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'">

        <ngx-datatable-column name="{{'DESCRIPTION' | translate}}" [flexGrow]="3">
          <ng-template ngx-datatable-cell-template let-row="row">
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>{{'PRODUCT' | translate}}</mat-label>
              <mat-select placeholder="{{'SELECT_PRODUCT' | translate}}" [(ngModel)]="row.productNumber" required (selectionChange)="productChanged(row)" name="product" #product="ngModel">
                <mat-option *ngFor="let product of products" [value]="product.id">
                  {{ product.productName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="product.hasError('required')">
                {{'PRODUCT_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
              </mat-error>
            </mat-form-field>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'UNIT_PRICE' | translate}}" [flexGrow]="1">
          <ng-template ngx-datatable-cell-template let-row="row">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>{{'UNIT-PRICE' | translate}}</mat-label>
              <input matInput type="text" appNumberOnly [decimalPoints]="2" placeholder="Unit Price" name="unitPrice" #unitPrice="ngModel" (change)="updateInvoice()" [(ngModel)]="row.unitPrice" id="unitPrice" required>
              <mat-error *ngIf="unitPrice.hasError('required')">
                {{'UNIT_PRICE_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
              </mat-error>
            </mat-form-field>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'TAX_RATE' | translate}}" [flexGrow]="0.6">
          <ng-template ngx-datatable-cell-template let-row="row">
            <strong>{{row.tax}}</strong>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'QUANTITY' | translate}}" [flexGrow]="1">
          <ng-template ngx-datatable-cell-template let-row="row">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>{{'QUANTITY' | translate}}</mat-label>
              <input matInput placeholder="Quantity" type="text" appNumberOnly name="quantity" #quantity="ngModel" (change)="updateInvoice()" [(ngModel)]="row.quantity" id="quantity" required>
              <mat-error *ngIf="quantity.hasError('required')">
                {{'QUANTITY_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
              </mat-error>
            </mat-form-field>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'AMOUNT' | translate}}" [flexGrow]="1">
          <ng-template ngx-datatable-cell-template let-row="row">
            <strong>{{ row.grossPrice | currency:currencyCode:'symbol'}}</strong>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'EDIT' | translate}}" [flexGrow]="1">
          <ng-template ngx-datatable-header-template>
            <button mat-mini-fab color="accent" style="top: -10px !important;" type="button" (click)="createInvoiceRow()">
              <mat-icon>add</mat-icon>
            </button>
          </ng-template>
          <ng-template ngx-datatable-cell-template let-row="row">

            <!--
          <button (click)="editRow(row)" class="ml-xs mat-icon-button" mat-icon-button="">
                <span class="mat-button-wrapper">
                    <mat-icon role="img" class="demo-toolbar-icon" aria-label="edit">edit</mat-icon>
                </span>
            <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
            <div class="mat-button-focus-overlay"></div>
          </button>
          -->
            <button (click)="deleteRow(row)" class="ml-xs mat-icon-button" mat-icon-button="">
              <span class="mat-button-wrapper">
                <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">delete</mat-icon>
              </span>
              <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
              <div class="mat-button-focus-overlay"></div>
            </button>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </mat-card-content>


    <hr>
    <mat-card-content *ngIf="invoice">
      <div fxLayout="row" fxLayoutWrap="wrap">
        <div fxFlex.gt-sm="25" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
          <h6 class="text-uppercase text-md ma-0 text-bold">{{'SUBTOTAL' | translate}}</h6>
          <h1 class="ma-0">{{invoice.subTotal | currency:currencyCode:'symbol'}}</h1>
        </div>
        <div fxFlex.gt-sm="25" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
          <h6 class="text-uppercase text-md ma-0 text-bold">{{'TAX' | translate}}</h6>
          <h1 class="ma-0">{{invoice.tax | currency:currencyCode:'symbol'}}</h1>
        </div>
        <div fxFlex.gt-sm="25" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
          <h6 class="text-uppercase text-md ma-0 text-bold">{{'DISCOUNT' | translate}}</h6>
          <h1 class="ma-0">{{0.00|currency:currencyCode:'symbol'}}</h1>
        </div>
        <div fxFlex.gt-sm="25" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1 text-xs-left text-sm-right">
          <h6 class="text-uppercase text-sm ma-0 text-bold">{{'TOTAL' | translate}}</h6>
          <h1 class="ma-0 mat-text-accent">{{ invoice.total | currency:currencyCode:'symbol'}}</h1>
        </div>
      </div>
    </mat-card-content>
    <hr>

    <mat-card-content class="py-1">
      <p class="text-md">
        <strong>
          {{'PAYMENT_TERMS_AND_POLICIES' | translate}}
        </strong>
        {{invoice.invoiceTerms}}
      </p>
    </mat-card-content>

    <hr>
    <mat-card-content class="py-1">

      <button mat-raised-button color="accent" *ngIf="!invoice.id" (click)="addInvoice()" [disabled]="!invoiceForm.form.valid" disabled="disabled">{{'CREATE_INVOICE' | translate}}
      </button>

      &nbsp;

      <button mat-raised-button color="accent" *ngIf="invoice.id" (click)="viewInvoice()" [disabled]="!invoiceForm.form.valid" disabled="disabled">{{'VIEW_INVOICE' | translate}}
      </button>

    </mat-card-content>
  </mat-card>

</div>