<!-- This is most complex step -->
<!-- <h3 *ngIf="(pets && pets.length > 0) && !addNewPet">What pets are coming?</h3> -->
<br>
<h3 *ngIf="pets && pets.length > 0">Select the services required?</h3>
<br>

<!-- <div class="example-action-buttons" *ngIf="pets && pets.length > 0">
  <button mat-button (click)="accordion.openAll()">Expand All</button>
  <button mat-button (click)="accordion.closeAll()">Collapse All</button>
</div>
<br> -->

<mat-card appearance="outlined" class="example-card" *ngFor="let pet of pets;let petRowIndex = index;">
  <mat-card-header>
    <div mat-card-avatar class="example-header-image"></div>
    <mat-card-title>
      <div fxLayout="row">
        <h4 fxFlex="88" [ngClass]="{ 'deceased-pet': pet?.deceased }">{{pet.name}}</h4><span *ngIf="pet?.deceased"> (D)
        </span>
      </div>
    </mat-card-title>
  </mat-card-header>
  <!-- <img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="Photo of a Shiba Inu"> -->
  <mat-card-content>
    <div fxLayout="row wrap" fxLayoutGap="5px grid">
      <div fxFlex="100" fxLayoutGap="5px grid" *ngFor="let service of selectedServices[pet.id]; let rowIndex = index;"
        style="border-bottom: 1px #888;">
        <app-service-selector-pet #serviceSelectorComponent *ngIf="contextIdDto" [contextIdDto]="contextIdDto"
          [selectedService]="service.selectedService" (serviceSelected)="serviceSelected($event,pet.id, rowIndex)"
          fxFlex="30">
        </app-service-selector-pet>

        <mat-form-field appearance="outline" *ngIf="service.selectedService" fxFlex="15">
          <mat-label>{{'Price' | translate}}</mat-label>
          <input matInput type="text" appNumberOnly [decimalPoints]="2" placeholder="Unit Price"
            name="unitPrice{{rowIndex}}" #unitPrice="ngModel" [(ngModel)]="service.unitPrice" id="unitPrice{{rowIndex}}"
            required readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="service.selectedService" fxFlex="15">
          <mat-label>{{'Duration In Mins' | translate}}</mat-label>
          <input matInput type="text" appNumberOnly [decimalPoints]="2" placeholder="Unit Price"
            name="durationInMins{{rowIndex}}" #durationInMins="ngModel"
            [(ngModel)]="service.selectedService.durationInMins" id="durationInMins{{rowIndex}}" required readonly>
        </mat-form-field>

        <app-staff-selector *ngIf="service.selectedService && userDtos.length > 1" [contextIdDto]="contextIdDto"
          [selectedStaff]="service.selectedStaff"
          (staffSelectionChanged)="staffSelectionChanged($event, pet.id, rowIndex)" fxFlex="25">
        </app-staff-selector>

        <mat-icon *ngIf="service.selectedService" (click)="deleteServiceNew(pet.id, rowIndex)" title="Delete Service"
          class="delete-icon" fxFlex="5">delete</mat-icon>
        <!-- <mat-divider fxFlex="100" *ngIf="rowIndex > 0"></mat-divider> -->
      </div>
      <div fxFlex="100">
        <div fxFlex="84"></div>
        <button fxFlex="16" class="add-service-button" mat-flat-button color="accent" (click)="addServiceNew(pet)"
          title="Add Another Service">
          <mat-icon>add_box</mat-icon> Add Service
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<br>

<!-- <mat-accordion class="example-headers-align" multi *ngIf="pets && pets.length > 0">
  <mat-expansion-panel *ngFor="let pet of pets;let petRowIndex = index;" [expanded]="step === petRowIndex"
    (opened)="setStep(petRowIndex)">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h5>{{pet.name}}</h5>
      </mat-panel-title>
      <mat-panel-description>
        <button class="add-service-button" mat-flat-button color="accent" (click)="addServiceNew(pet)"
          title="Add Another Service">
          <mat-icon>add_box</mat-icon> Add Service
        </button>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div fxLayout="row wrap" fxLayoutGap="5px grid">
      <div fxFlex="100" fxLayoutGap="5px grid" *ngFor="let service of selectedServices[pet.id]; let rowIndex = index;">
        <app-service-selector-pet #serviceSelectorComponent *ngIf="contextIdDto" [contextIdDto]="contextIdDto"
          [selectedService]="service.selectedService" (serviceSelected)="serviceSelected($event,pet.id, rowIndex)"
          fxFlex="30">
        </app-service-selector-pet>

        <mat-form-field appearance="outline" *ngIf="service.selectedService" fxFlex="15">
          <mat-label>{{'Price' | translate}}</mat-label>
          <input matInput type="text" appNumberOnly [decimalPoints]="2" placeholder="Unit Price"
            name="unitPrice{{rowIndex}}" #unitPrice="ngModel" [(ngModel)]="service.unitPrice" id="unitPrice{{rowIndex}}"
            required readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="service.selectedService" fxFlex="15">
          <mat-label>{{'Duration In Mins' | translate}}</mat-label>
          <input matInput type="text" appNumberOnly [decimalPoints]="2" placeholder="Unit Price"
            name="durationInMins{{rowIndex}}" #durationInMins="ngModel"
            [(ngModel)]="service.selectedService.durationInMins" id="durationInMins{{rowIndex}}" required readonly>
        </mat-form-field>

        <app-staff-selector *ngIf="service.selectedService" [contextIdDto]="contextIdDto"
          [selectedStaff]="service.selectedStaff" [userDtos]="userDtos"
          (staffSelectionChanged)="staffSelectionChanged($event, pet.id, rowIndex)" fxFlex="25">
        </app-staff-selector>

        <mat-icon *ngIf="service.selectedService" (click)="deleteServiceNew(pet.id, rowIndex)" title="Delete Service"
          style="cursor: pointer;padding: 16px 5px 5px 20px;">delete</mat-icon>
      </div>
    </div>

  </mat-expansion-panel>

</mat-accordion> -->

<!-- <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="5px grid" *ngIf="pets && pets.length > 0">
  <div fxLayout="row wrap" fxFlex="100">
    <div style="line-height: 40px;" [ngClass]="{'valid-group': isGroupValid(group)}">
      <a href="javascript:void(0)" title="Expand/Collapse Group" (click)="toggleExpandGroup(group)">
        <button mat-icon-button>
          <mat-icon *ngIf="expanded">keyboard_arrow_up</mat-icon>
          <mat-icon *ngIf="!expanded">expand_more</mat-icon>
        </button>
        <b class="pl-1">{{ group.value[0].name }}</b>
      </a>
      <button class="add-service-button" mat-flat-button color="accent" (click)="addService(group, expanded)"
        title="Add Another Service">
        <mat-icon>add_box</mat-icon> Add Service
      </button>
    </div>
  </div>
</div> -->
<!-- <mat-card *ngIf="pets && pets.length > 0" [hidden]="addNewPet" style="box-shadow: none;">
  <ngx-datatable [rows]="pets" [columnMode]="'force'" class='material' [headerHeight]="0" [footerHeight]="0"
    [rowHeight]="'auto'" [limit]="5" [loadingIndicator]="loading" [groupRowsBy]="'id'" [groupExpansionDefault]="false"
    #petTable>
    <ngx-datatable-group-header [rowHeight]="75" #myGroupHeader>
      <ng-template let-group="group" let-expanded="expanded" ngx-datatable-group-header-template>
        <div style="line-height: 40px;" [ngClass]="{'valid-group': isGroupValid(group)}">
          <a href="javascript:void(0)" title="Expand/Collapse Group" (click)="toggleExpandGroup(group)">
            <button mat-icon-button>
              <mat-icon *ngIf="expanded">keyboard_arrow_up</mat-icon>
              <mat-icon *ngIf="!expanded">expand_more</mat-icon>
            </button>
            <b class="pl-1">{{ group.value[0].name }}</b>
          </a>
          <button class="add-service-button" mat-flat-button color="accent" (click)="addService(group, expanded)"
            title="Add Another Service">
            <mat-icon>add_box</mat-icon> Add Service
          </button>
        </div>
      </ng-template>
    </ngx-datatable-group-header>


    <ngx-datatable-column name="Services" [width]="120" [sortable]="false">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
        <app-service-selector-pet #serviceSelectorComponent *ngIf="contextIdDto" [contextIdDto]="contextIdDto"
          [selectedService]="row.selectedService" (serviceSelected)="serviceSelected($event,row)">
        </app-service-selector-pet>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Price" [width]="80" [sortable]="false">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
        <mat-form-field appearance="outline" class="w-100" *ngIf="row.selectedService">
          <mat-label>{{'Price' | translate}}</mat-label>
          <input matInput type="text" appNumberOnly [decimalPoints]="2" placeholder="Unit Price"
            name="unitPrice{{rowIndex}}" #unitPrice="ngModel" [(ngModel)]="row.unitPrice" id="unitPrice{{rowIndex}}"
            required readonly>

        </mat-form-field>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Duration In Mins" [width]="80" [sortable]="false">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
        <mat-form-field appearance="outline" class="w-100" *ngIf="row.selectedService">
          <mat-label>{{'Duration In Mins' | translate}}</mat-label>
          <input matInput type="text" appNumberOnly [decimalPoints]="2" placeholder="Unit Price"
            name="durationInMins{{rowIndex}}" #durationInMins="ngModel" [(ngModel)]="row.selectedService.durationInMins"
            id="durationInMins{{rowIndex}}" required readonly>

        </mat-form-field>
      </ng-template>
    </ngx-datatable-column>


    <ngx-datatable-column name="Groomer" [width]="80" [sortable]="false" *ngIf="userDtos && userDtos.length > 1">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
        <app-staff-selector *ngIf="row.selectedService" [contextIdDto]="contextIdDto"
          [selectedStaff]="row.selectedStaff" (staffSelectionChanged)="staffSelectionChanged($event, row)">
        </app-staff-selector>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Delete" [width]="80" [sortable]="false">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex"
        let-group="group">
        <mat-icon *ngIf="row.selectedService" (click)="deleteService(rowIndex, group)" title="Delete Service"
          style="cursor: pointer;">delete</mat-icon>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</mat-card> -->




<!-- <mat-chip-list aria-label="Advanced">
  <mat-chip color="primary">Multi Groomer Appt</mat-chip>
</mat-chip-list> -->