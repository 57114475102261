import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ContextService} from '../context.service';
import {Router} from '@angular/router';
import {FloSnackbarComponent} from '../snackbar/floSnackbar.component';
import {WindowRef} from '../windowWrapper';
import {TranslateService} from '@ngx-translate/core';
import {ContextIdDto} from '@savvy/menu';
import {Subscription} from 'rxjs';
import {PlanDefinition, PlanDefService} from '@savvy/plan-definition';
import {PaymentSettings} from '@savvy/payment/model/models';
import {PaymentSettingsService} from '@savvy/payment';
import {EventBusService} from '../element/EventBusService';
import {Page} from '../shared/model/page';
import {PlansCompService, PlanService} from '@savvy/plan';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmationDialogComponent} from "../confirmation-dialog/confirmation-dialog.component";

export interface CustomerCount {
  planDefId: string;
  count: number;
}

@Component({
  selector: 'app-plan-definition',
  templateUrl: './planDefinition.component.html',
  styleUrls: ['planDefinition.component.scss']
})
export class PlanDefinitionComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  @Input() contextIdDto: ContextIdDto;
  @Input() isModal: boolean = false;
  eventBus = new EventBusService();
  page = new Page();
  sortAscending = true;
  planDefinition = <PlanDefinition>{};
  planDefinitions: Array<any> = [];
  paymentSettings = <PaymentSettings>{};

  selected: any[] = [];
  paymentBadgeCount: any = {};
  planDefsCount: Array<CustomerCount> = [];

  constructor(
    private router: Router,
    private windowRef: WindowRef,
    private translate: TranslateService,
    private notify: FloSnackbarComponent,
    private dialog: MatDialog,
    private contextService: ContextService,
    private planDefApi: PlanDefService,
    private planApi: PlanService,
    private planCompApi: PlansCompService,
    private paymentSettingsApi: PaymentSettingsService) {
  }

  ngOnInit() {
    this.page.size = 10;
    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
        this.setPage({ offset: 0, size: this.page.size });

      }
    }));
    this.loadPaymentSettings();

  }

  listInstances() {
    this.planDefApi.loadPlanDefinitionPaged(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      (this.page.pageNumber + 1),
      this.page.size,
      this.sortAscending).subscribe(res => {
        this.planDefinitions = res.contents;

        this.planDefinitions.forEach(planDef => {
          this.getBadgeCount(planDef.id);
          this.populateCustomerCount(planDef.id);
        });
        this.page.totalElements = res.totalElements;
        this.page.totalPages = res.totalPages;

    });
  }

  populateCustomerCount(planDefId: string) {
    this.planApi.countAllByPlanDefinitionId(planDefId).subscribe(res => {
      let customerCount = <CustomerCount>{};
      customerCount.planDefId = planDefId;
      customerCount.count = res;

      this.planDefsCount.push(customerCount);
    });
  }

  getCustomerCount(planDefId: string) {
    let count = 0;

    this.planDefsCount.forEach(customerCount => {
      if (planDefId === customerCount.planDefId) {
        count = customerCount.count;
      }
    });

    return count;
  }


  onSort(event) {
    this.sortAscending = event.newValue === 'asc';

    if (event.column.name !== 'Name') {
      this.sortAscending = true;
    }
    this.setPage({ offset: 0, size: 20 });
  }

  setPage(pageInfo) {
    console.log('pageInfo is ' + pageInfo);
    this.page.pageNumber = pageInfo.offset;
    this.listInstances();
  }

  loadPaymentSettings() {
    this.paymentSettingsApi.loadPaymentSettings(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType).subscribe(response => {
      this.paymentSettings = response;
    });
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  create() {
    this.router.navigate(['/planDef/addEditPlanDef',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType]);
  }

  onSelect(event) {
    console.log(event);
    this.router.navigate(['/planDef/addEditPlanDef',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      this.selected[0].id
    ]);
  }

  editPlanDef(planDefinition: PlanDefinition) {
    console.log(event);
    this.router.navigate(['/planDef/addEditPlanDef',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      planDefinition.id
    ]);
  }

  deletePlanDefinition(planDefinition: PlanDefinition) {
    const confirmDialog = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete',
        message: 'Are you sure you would like to permanently delete this plan? This will remove all current customers linked and cancel any future payments associated with this plan.'
      },
      height: 'auto',
      width: '500px',
      panelClass: 'helpwindow'
    });
    confirmDialog.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.planDefApi._delete(this.contextIdDto.contextId, this.contextIdDto.contextIdType, planDefinition.id).subscribe(res => {
          this.notify.message = 'Successfully deleted plan';
          this.notify.open();
          this.listInstances();
          this.deletePlans(planDefinition);
        })
      }
    });

  }

  deletePlans(planDefinition: PlanDefinition) {
    this.planApi.loadByPlanDefIdAndDeleted(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      planDefinition.id,
      false
    ).subscribe(res => {
      if (res && res.plans) {
        res.plans.forEach(plan => {
          this.planCompApi.expireAndDeleteComp(this.contextIdDto.contextId,
            this.contextIdDto.contextIdType,
            plan.id).subscribe(res => {
          });
        });
      }
    });
  }

  viewPlanDef(planDefinition: PlanDefinition) {
    console.log(event);
    this.router.navigate(['/planDef/viewPlanDef',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      planDefinition.id
    ]);
  }

  goToSavvyPay() {
    this.router.navigate(['paymentSettings/paymentSettings',
      this.contextIdDto.contextId, this.contextIdDto.contextIdType]);
  }

  getBadgeCount(planDefId: string) {
    if (!this.paymentBadgeCount?.planDefId) {
      this.planApi.loadPlanPaymentCountByPlanDefId(this.contextIdDto.contextId, this.contextIdDto.contextIdType, planDefId).subscribe(res => {
        this.paymentBadgeCount[planDefId] = res; //This will populate the numbers in mat-badge
      });
    }
  }
}
