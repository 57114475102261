import { NgModule } from '@angular/core';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CustomDateAdapter } from './custom-date-adapter';

@NgModule({
    declarations: [],
    imports: [],
    exports: [MatDatepickerModule, MatNativeDateModule],
    providers: [
        {
            provide: DateAdapter, useClass: CustomDateAdapter
        }
    ]
})
export class CustomDateModule {

}
