<mat-form-field appearance="outline" class="w-100">
  <mat-label>{{'PET_TYPE' | translate}}</mat-label>
  <mat-select placeholder="{{'SEARCH_PET_TYPE' | translate}}" [formControl]="selectedPetType" name="selectedPetType">
    <mat-option>
      <ngx-mat-select-search [placeholderLabel]="'SEARCH_TYPES' | translate" [formControl]="petTypeFilter">
      </ngx-mat-select-search>
    </mat-option>
    <mat-option>

    </mat-option>
    <mat-option *ngFor="let petType of filteredPetTypes | async" [value]="petType.name">
      {{ petType.name }}
    </mat-option>
  </mat-select>
</mat-form-field>