import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
// import { EventBusService } from '../EventBusService';
import { ContextIdDto } from '@savvy/entity-instance-composite';

import { ConsumerService, ServicesService } from '@savvy/services';
import { ReplaySubject, Subject } from 'rxjs';
// import { AddServiceDialogComponent } from '../fieldinstance/addServiceDialog.component';
import { CreateServiceComponent } from 'src/app/flo/shared/components/setup/services/create-service/create-service.component';
import { Tax } from '@savvy/tax';
import { DataShareService } from 'src/app/core/data-share.service';
import { ServicesHelperService } from 'src/app/flo/shared/services/services-helper.service';
// import { StaffSelectorComponent } from './petServiceUserSelector.component';
import * as _ from 'lodash';
import { NgModel } from '@angular/forms';
import { TaxesHelperService } from 'src/app/flo/shared/services/taxes-helper.service';
import { CreateBoardingBookingV2SyncService } from '../../../create-boarding-booking-v2-sync.service';
import { PetServiceBooking } from '@savvy/pet-stay';
import { UserId } from '@savvy/user';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-pet-boarding-service-selector',
  templateUrl: './pet-boarding-service-selector.component.html',
  styleUrls: ['./pet-boarding-service-selector.component.scss']
})
export class PetBoardingServiceSelectorComponent implements OnInit {

  @ViewChild('serviceField') serviceField: NgModel;

  @Input() contextIdDto: ContextIdDto;
  // @Input() eventBus: EventBusService;
  @Input() showPlaceholder: boolean;
  @Input() selectedService: PetServiceBooking;
  selectedServiceLocal: ConsumerService | undefined;
  @Input() petId: string;

  @Output() serviceSelectionChanged: EventEmitter<PetServiceBooking> = new EventEmitter();
  serviceGroups = [];
  serviceDeleted;
  taxDefs: Tax[] = [];

  public filteredServices: ReplaySubject<ConsumerService[]> = new ReplaySubject<ConsumerService[]>(1);
  private destroy$ = new Subject();
  constructor(
    private servicesService: ServicesService,
    private dialog: MatDialog,
    private taxesHelperService: TaxesHelperService,
    public createBoardingBookingV2SyncService: CreateBoardingBookingV2SyncService,
    public dataShareService: DataShareService,
    public servicesHelperService: ServicesHelperService,
  ) {

  }

  ngOnInit(): void {
    if (this.contextIdDto) {
      this.taxesHelperService.getTaxDefs(this.contextIdDto).then(res => {
        this.taxDefs = res;
      });
      this.servicesHelperService.getConsumerServices(this.contextIdDto).then(consumerServices => {
        if (consumerServices && consumerServices.length) {
          this.filteredServices.next(consumerServices);
          if (!this.selectedService?.serviceId) {
            this.selectedServiceChanged(consumerServices[0]);
          } else {
            if (consumerServices.findIndex(cs => cs.id === this.selectedService.serviceId) === -1) {
              // Service is delete
              this.serviceDeleted = _.cloneDeep(this.selectedService);
              this.selectedService = null;
              if (this.serviceField) {
                this.serviceField.control.markAsTouched();
              }
            } else {
              this.selectedServiceLocal = consumerServices.find(cs => cs.id === this.selectedService.serviceId);
            }
          }
        }
      });
      this.servicesHelperService.getServiceGroups(this.contextIdDto).then(groups => {
        this.serviceGroups = groups;
      });
    }
  }

  selectedServiceChanged(consumerService: ConsumerService) {
    if (consumerService) {
      this.selectedServiceLocal = consumerService;
      this.selectedService = {
        serviceId: consumerService.id as string,
        serviceName: consumerService?.serviceName,
        serviceUser: {} as UserId,
        serviceUserFullName: '',
        petId: this.petId,
        startDate: this.createBoardingBookingV2SyncService.startDateReq,
        endDate: this.createBoardingBookingV2SyncService.endDateReq,
        startTime: this.createBoardingBookingV2SyncService.startTime,
        endTime: this.createBoardingBookingV2SyncService.endTime,
        quantity: 1,
        unitPrice: consumerService.unitPrice,
        subTotal: consumerService.unitPrice,
        grossPrice: consumerService.unitPrice,
        duration: consumerService.durationInMins,
        taxId: consumerService.taxId,
        tax: consumerService.tax,
      };
      this.serviceSelectionChanged.emit(this.selectedService);
    }
  }

  serviceChanged(event) {
    if (event?.value) {
      this.serviceDeleted = undefined;
      this.selectedServiceChanged(event.value);
    }
  }

  filterServices(value: string) {
    this.servicesHelperService.getConsumerServices(this.contextIdDto).then(consumerServices => {
      if (!consumerServices) {
        return;
      }
      // get the search keyword
      if (!value) {
        this.filteredServices.next(consumerServices.slice());
        return;
      }
      const search = value.toLowerCase();
      // filter the consumerServices
      this.filteredServices.next(consumerServices.filter(serviceDto => serviceDto?.serviceName.toLowerCase().indexOf(search) > -1));
    });
  }

  createNewService() {
    const dialogRef = this.dialog.open(CreateServiceComponent, {
      width: '550px',
      panelClass: ['scrollable-modal', 'helpwindow'],
      data: {
        serviceGroups: this.serviceGroups,
        taxDefs: this.taxDefs,
        contextIdDto: this.contextIdDto
      }
    });

    dialogRef.afterClosed().subscribe((serviceNew) => {
      if (serviceNew) {
        console.log('The service is saved');
        this.servicesHelperService.getConsumerServices(this.contextIdDto, true).then(consumerServices => {
          this.loadAndSetService(serviceNew, consumerServices);
        });
      }
    });
  }

  loadAndSetService(service: ConsumerService, consumerServices: ConsumerService[]) {
    console.log('loading new services');
    if (consumerServices && consumerServices.length) {
      this.filteredServices.next(consumerServices);
      const newService = consumerServices.find(s => s?.serviceName === service?.serviceName);
      setTimeout(() => {
        this.selectedServiceChanged(newService);
      }, 500);
    }
  }

  compareObjects(o1: ConsumerService, o2: ConsumerService): boolean {
    if (o1 && o2) {
      return o1.id === o2.id;
    }
    return false;
  }

}
