<div class="entity-card">
  <div fxLayout="column">
    <div class="actions" fxFlex="100" fxLayout="row" fxLayoutAlign="space-between baseline">
      <div>
        <button (click)="backToList()" class="ml-xs mat-icon-button" mat-icon-button="">
    <span class="mat-button-wrapper">
    <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">arrow_back</mat-icon>
    </span>
          <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
          <div class="mat-button-focus-overlay"></div>
        </button>
      </div>
      <div>
        <button (click)="removeEntity()" class="ml-xs mat-icon-button" mat-icon-button="">
    <span class="mat-button-wrapper">
    <mat-icon role="img" class="demo-toolbar-icon" aria-label="delete">delete</mat-icon>
    </span>
          <div class="mat-button-ripple mat-ripple mat-button-ripple-round" mat-ripple=""></div>
          <div class="mat-button-focus-overlay"></div>
        </button>
      </div>
    </div>


    <div fxLayout="row" fxLayout.lt-md="column">
      <!-- icon here -->
      <div fxFlex="100" fxLayout="column">
        <div fxFlex="100" fxLayoutAlign="center">
          <div fxFlex="70" fxFlex.xs="100">

            <mat-toolbar class="demo-toolbar" color="primary"
                         *ngIf="loadEiViewResponse && loadEiViewResponse.viewContextDto">
              <mat-toolbar-row>{{'Edit' | translate}} {{ loadEiViewResponse.viewContextDto.entityDefinitionLabel | translate }}</mat-toolbar-row>
            </mat-toolbar>

            <app-element *ngIf="loadEiViewResponse && loadEiViewResponse.viewRootElementDto"
                         [elementDto]="loadEiViewResponse.viewRootElementDto"
                         [form]="form"
                         [contextIdDto]="contextIdDto"
                         [eventBus]="eventBus"
                         [viewContext]="loadEiViewResponse.viewContextDto"
                         [additionalDataMapDto]="loadEiViewResponse.additionalDataMapDto"
                         [showPlaceholder]="true"
                         [changeListener]="changeListener"
            >
            </app-element>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
