<!-- <div class="cover-photo coverimg" [style.background-image]="'url(' + coverPic + ')'"></div> -->
<mat-card appearance="outlined" class="mdc-mb-1">
  <mat-card-content class="pt-0">
    <div fxLayout="column" fxLayout.gt-sm="row">
      <div fxFlex="nogrow" class="mdc-px-3 text-center">
        <div class="avatar avatar-120 circle border-3 pet-pic coverimg mx-auto" [style.background-image]="'url(' + profilePic + ')'"></div>

      </div>
      <div fxFlex>
        <div fxLayout="column" fxLayout.gt-md="row">
          <div fxFlex fxLayout="column" class="mdc-p-3 ">
            <h5 class="dog-name mb-0">
              <span class="mr-1 font-weight-bold">{{customerDto?.firstName}} {{customerDto?.lastName}}</span>
              <span *ngIf="!customerDto?.deleted" class="chiptag green vm px-2">{{'ACTIVE' | translate}}</span>
              <span *ngIf="customerDto?.deleted" class="chiptag red vm px-2">{{'INACTIVE' | translate}}</span>
            </h5>
            <div fxFlex="100" fxFlex.gt-sm>
              <p class="my-2" *ngIf="customerDto?.username">
                <!-- <span class="mat-text-secondary mr-1">Email:</span> -->
                <a href="mailto: {{customerDto?.username}}">
                  <span class="chiptag theme-accent vm mt-5px">
                    <mat-icon>email</mat-icon>
                    {{customerDto?.username || 'N/A' }}
                  </span>
                </a>
              </p>
              <p class="my-2" *ngIf="customerDto?.mobilePhoneNumberObj?.internationalNumber">
                <!-- <span class="mat-text-secondary mr-1">Mobile:</span> -->
                <a href="tel: {{customerDto?.mobilePhoneNumberObj?.internationalNumber}}">
                  <span class="chiptag theme vm mt-5px">
                    <mat-icon>phone</mat-icon>
                    {{customerDto?.mobilePhoneNumberObj?.internationalNumber || 'N/A' }}
                  </span>
                </a>

              </p>
              <p class="my-2" *ngIf="customerDto?.phoneNumberObj?.internationalNumber">
                <!-- <span class="mat-text-secondary mr-1">Phone:</span> -->
                <a href="tel: {{customerDto?.phoneNumberObj?.internationalNumber}}">
                  <span class="chiptag theme vm mt-5px">
                    <mat-icon>phone</mat-icon>
                    {{customerDto?.phoneNumberObj?.internationalNumber || 'N/A' }}
                  </span>
                </a>
              </p>
              <p *ngIf="customerDto?.workPhoneNumberObj?.internationalNumber">
                <!-- <span class="mat-text-secondary mr-1">Work:</span> -->
                <a href="tel: {{customerDto?.workPhoneNumberObj?.internationalNumber}}">
                  <span class="chiptag theme vm mt-5px">
                    <mat-icon>phone</mat-icon>
                    {{customerDto?.workPhoneNumberObj?.internationalNumber || 'N/A' }}
                  </span>
                </a>
              </p>
            </div>
            <app-view-customer-address class="mt-5px" *ngIf="customerDto" [contextIdDto]="contextIdDto" [customerDto]="customerDto">
            </app-view-customer-address>
          </div>
          <div fxFlex="nogrow" class="mdc-px-3 ">
            <!-- <button class="pl-0" mat-button color="accent" (click)="addAppointment()">
              <mat-icon class="vm">event</mat-icon> Create Appointment
            </button> -->
            <app-customer-flags-panel class="d-block" [contextIdDto]="contextIdDto" [flagIds]="customerDto.flags" (createFlag)="createFlag($event)" (deleteFlag)="deleteFlag($event)">
            </app-customer-flags-panel>
          </div>
        </div>
        <div class="mdc-px-3 ">
          <button mat-raised-button color="accent" class="text-left" (click)="addAppointment()">
            <mat-icon class="vm mr-05 mdc-py-1">event</mat-icon> <span class="vm">{{"CREATE" | translate}}</span>
          </button>
          <button mat-raised-button color="accent" class="text-left ml-1 ml-md-0" (click)="sendMessage()">
            <mat-icon class="vm mr-05 mdc-py-1">mail</mat-icon> <span class="vm">{{"MESSAGE" | translate}}</span>
          </button>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
