<!-- <mat-toolbar>
  <mat-toolbar-row>{{'Create Customer' | translate}}</mat-toolbar-row>
</mat-toolbar> -->

<div *ngIf="!eventBus">
  ADD CUST DIALOG BUS IS EMPTY
</div>
<div *ngIf="!contextIdDto">
  ADD CUST DIALOG contextIdDto IS EMPTY
</div>

<app-add-customer *ngIf="contextIdDto" (created)="customerCreated($event); dialogRef.close(data);"
  (cancelClicked)="dialogRef.close(data)" [contextIdDto]="contextIdDto" [eventBus]="eventBus" [showBackToList]="false">
</app-add-customer>
