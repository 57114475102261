

<!--mat-toolbar>

  <mat-toolbar-row>

    Dog: Jess / Katie Rourke Dowding / Poodle Toy
  </mat-toolbar-row>
</mat-toolbar-->


<mat-tab-group>

  <mat-tab label="{{'PAST' | translate}}">

    <ngx-datatable *ngIf="pastAppointments" class='material has-plus-btn'
                   [rows]='pastAppointments' [columnMode]="'force'" [headerHeight]="50"
                   [rowHeight]="'auto'" [footerHeight]="50">
      <ngx-datatable-column name="{{'APPT_TIME' | translate}}" [width]="80">
        <ng-template ngx-datatable-cell-template let-row="row">
          {{ row.appointmentTimeDisplay }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'BLADES' | translate}}" [width]="80">
        <ng-template ngx-datatable-cell-template let-row="row">
           {{ row.appointment.scissors }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'NOTES' | translate}}" [width]="80">
        <ng-template ngx-datatable-cell-template let-row="row">
           {{ row.appointment.notes }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="{{'LAST_PAID' | translate}}" [width]="5">
        <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
          <button mat-raised-button color="accent" (click)="viewAppointment(row)">{{'VIEW_INVOICE' | translate}}</button>
        </ng-template>
      </ngx-datatable-column>
      <!--ngx-datatable-column name="Last Paid" [width]="80">
        <ng-template ngx-datatable-cell-template let-row="row">
          <button color="warn" mat-raised-button>Last Paid</button>
        </ng-template>
      </ngx-datatable-column-->




    </ngx-datatable>




  </mat-tab>

  <mat-tab label="{{'FUTURE' | translate}}">


    <ngx-datatable *ngIf="futureAppointments" class='material has-plus-btn'
                   [rows]='futureAppointments' [columnMode]="'force'" [headerHeight]="50"
                   [rowHeight]="'auto'" [footerHeight]="50">
      <ngx-datatable-column name="{{'APPT_TIME' | translate}}" [width]="80">
        <ng-template ngx-datatable-cell-template let-row="row">
          {{ row.appointmentTimeDisplay }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'BLADES' | translate}}" [width]="80">
        <ng-template ngx-datatable-cell-template let-row="row">
          {{ row.appointment.scissors }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'NOTES' | translate}}" [width]="80">
        <ng-template ngx-datatable-cell-template let-row="row">
          {{ row.appointment.notes }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="{{'LAST_PAID' | translate}}" [width]="5">
        <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
          <button mat-raised-button color="accent" (click)="viewAppointment(row)">{{'VIEW_INVOICE' | translate}}</button>
        </ng-template>
      </ngx-datatable-column>

      <!--ngx-datatable-column name="Last Paid" [width]="80">
        <ng-template ngx-datatable-cell-template let-row="row">
          <button color="warn" mat-raised-button>Last Paid</button>
        </ng-template>
      </ngx-datatable-column-->

    </ngx-datatable>


  </mat-tab>

</mat-tab-group>
