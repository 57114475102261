import { Injectable } from '@angular/core';
import { SmsCreditSummaryDto } from '@savvy/sms-settings';

@Injectable()
export class SmsCreditStateService {

  smsCreditSummaryDto: SmsCreditSummaryDto;

  public setSmsCreditSummary(smsCreditSummaryDto: SmsCreditSummaryDto) {
    this.smsCreditSummaryDto = smsCreditSummaryDto;
  }

  getRemaining() {
    if (this.smsCreditSummaryDto) {
      return this.smsCreditSummaryDto.remainingSmsCreditsIncludingPaid;
    }
    return 111111;
  }

  isSmsCreditLow(isFreemium = false) {
    if (!this.smsCreditSummaryDto) {
      return false;
    } else {
      if (this.smsCreditSummaryDto) {
        // this.response.subscriptionState.subscriptionStatus === 'NO_SUBSCRIPTION' ||
        return !isFreemium && this.smsCreditSummaryDto?.remainingSmsCreditsIncludingPaid < 50;
      }
      return false;
    }
  }

  isSmsCreditZero() {
    if (!this.smsCreditSummaryDto) {
      return false;
    } else {
      if (this.smsCreditSummaryDto) {
        // this.response.subscriptionState.subscriptionStatus === 'NO_SUBSCRIPTION' ||
        return this.smsCreditSummaryDto.remainingSmsCreditsIncludingPaid < 1;
      }
      return false;
    }
  }
}
