import { Component, Input } from '@angular/core';
import { ContextIdDto, QuotesSummaryDto, SummaryItemDto } from '@savvy/view-definition';
import { Router } from '@angular/router';
import { NavigateService } from '../entry/NavigateService';

@Component({
  selector: 'app-quotes-summary-widget',
  templateUrl: 'quotesSummaryWidget.component.html'
})
export class QuotesSummaryWidgetComponent {

  @Input() quotesSummaryDto: QuotesSummaryDto;
  @Input() contextIdDto: ContextIdDto;

  constructor(private router: Router, private nav: NavigateService) {
  }

  go(summaryItemDto: SummaryItemDto) {
    this.router.navigate(['/quote/viewQuote',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      summaryItemDto.itemId
    ]);
  }
}
