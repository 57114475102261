import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ContextIdDto } from '@savvy/quickbooks';
import { Router } from '@angular/router';
import { EicompService } from '@savvy/entity-instance-composite';
import { EventHandlerService } from '../entry/EventHandlerService';
import { EventBusService } from './EventBusService';
import { TrialExpiredService } from '../shared/services/trial-expired.service';
import { ListEntityTeasersDto } from '@savvy/view-definition';

@Component({
    selector: 'app-teaser-panel',
    templateUrl: './listEntityTeasersPanel.component.html'
})
export class ListEntityTeasersPanelComponent implements OnInit, OnChanges {

    @Input() teasersDto: ListEntityTeasersDto;
    @Input() contextIdDto: ContextIdDto;
    @Input() eventBus: EventBusService;
    @Input() showPlaceholder: boolean;
    entityInstanceToDisplayId: string;

    constructor(
        public trialExpiredService: TrialExpiredService,
        private eventHandler: EventHandlerService,
        private eiUiApi: EicompService,
        private router: Router) {
    }

    ngOnInit() {
        console.log('calling setInstanceToDisplay from ngOnInit');
        this.setFirstInstanceToDisplay();
    }

    /**
     * So this will get called when the teasers are changed (for example)
     * but it will also get called when the label
     *
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges) {
        // changes.prop contains the old and the new value...
        console.log('change detected inside teaser panel');
        for (const propName in changes) {
            if (propName === 'teasersDto') {
                // if (cur !== prev) {
                // console.log('calling setInstanceToDisplay from ngOnChanges ' + changes.currentValue);
                this.setFirstInstanceToDisplay();
                // }
            } else {
                console.log('not setting first instance as propName is ' + propName);
            }
        }
    }

    setFirstInstanceToDisplay() {

        console.log('inside set instance to display');

        if (this.teasersDto && this.teasersDto.teasersDto.teasers.length > 0) {

            // Lets load the first record
            this.entityInstanceToDisplayId =
                this.teasersDto.teasersDto.teasers[0].entityInstanceId;
        } else {
            // console.log('no results so setting loadForEditInstanceResponse to null');
            this.entityInstanceToDisplayId = null;
        }

        console.log('leaving inside set instance to display');

    }

    showEntity(entityInstanceId: string) {
        console.log('inside showEntity with instance id ' + entityInstanceId);
        this.entityInstanceToDisplayId = entityInstanceId;
    }

    create() {

        console.log('inside create 1234');
        console.log('Navigating to create entity with entityDefinitionId ' + this.teasersDto.entityDefinitionId +
            ' context id ' + this.contextIdDto.contextId);

        this.router.navigate(['/entity/createEntity',
            this.teasersDto.entityDefinitionId.id,
            this.contextIdDto.contextId,
            this.contextIdDto.contextIdType]);
    }

    handleRowSelected(entityInstanceId): void {
        // console.log('inside handle row selected with entity instance id ' + entityInstanceId);

        //  { path: 'editEntity/:entityInstanceId/:contextId/:contextIdType', component: EditEntityComponent },

        this.router.navigate(['/entity/editEntity',
            entityInstanceId,
            this.contextIdDto.contextId,
            this.contextIdDto.contextIdType]);
    }
}
