import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { LoginDataDto } from '@savvy/product-metadata';
import { ContextIdDto } from '@savvy/view-definition';
import { BootstrapService } from 'src/app/bootstrap.service';


@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  loadViewResponse: any;
  logoUrl;
  appType;
  loginImageUrl;
  loadedView = false;
  defaultLogin = true;
  hostname = '';


  username: string;
  password: string;

  contextIdDto: ContextIdDto;

  constructor(
    private authService: AuthService,
    private bootstrapService: BootstrapService
  ) {
  }

  ngOnInit() {
    console.log('inside signin on init');
    this.bootstrapService.loadProductLoginView().then((res: LoginDataDto) => {
      this.authService.loginWithRedirect({
        appState: {
          target: window.location.origin,
        },
        authorizationParams: {
          domainName: location.host
        },
      });
      console.log('redirected to signin');
    });
  }



}
