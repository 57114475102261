import {Injectable} from '@angular/core';
import {Location} from '@savvy/location';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  public currentLocation: Location;

}

