<div class="flexbox-parent" *ngIf="flagDefinitions && flagDefinitions.length === 0">
  <div fxLayout="row" fxLayoutAlign="center center" style="height: 60vh;  ">
    <div style="text-align:center;">
      <app-zero-image name="paymentSchedule"></app-zero-image><br>
      <h5 class="ma-0">{{'NO_FLAG_DEFS' | translate}}...</h5><br>
      <button mat-raised-button color="primary" (click)="create()">
        {{'ADD_FLAG' | translate}}
      </button>
    </div>
  </div>
</div>
<div class="table-actions">
  <mat-slide-toggle [(ngModel)]="showArchived" labelPosition="before">{{'ARCHIVED' | translate}}</mat-slide-toggle>
  <button class="ml-1" mat-raised-button color="primary" matTooltip="{{'ADD_FLAG' | translate}}" (click)="create()">
    {{'CREATE' | translate}} <mat-icon>add</mat-icon>
  </button>
</div>

<div class="flags-table-container" *ngIf="flagDefinitions && flagDefinitions.length > 0">
  <ngx-datatable class="material" [columnMode]="'force'" [scrollbarH]="true" [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'" [rows]="showArchived? archivedFlagDefinitions : flagDefinitions">
    <ngx-datatable-column [width]="120" name="{{'FLAG_NAME' | translate}}" prop="name"></ngx-datatable-column>
    <ngx-datatable-column [width]="150" name="{{'DESCRIPTION' | translate}}" prop="description"></ngx-datatable-column>

    <ngx-datatable-column [width]="100" name="{{'COLOUR' | translate}}" prop="colour">
      <ng-template ngx-datatable-cell-template let-row="row">
        <mat-icon [color]="row.colour" matTooltip="{{ row.colour }}">circle</mat-icon>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [width]="180" name="{{'ACTIONS' | translate}}" [sortable]="false">
      <ng-template ngx-datatable-cell-template let-row="row">

        <button mat-button color="primary" class="mr-5px" matTooltip="{{'EDIT_FLAG' | translate}}" (click)="editFlag(row)">
          <mat-icon>edit</mat-icon> <span>Edit</span>
        </button>

        <ng-container *ngIf="showArchived; else archiveButton">
          <button mat-button color="accent" class="mr-5px" matTooltip="{{'UN_ARCHIVE_FLAG' | translate}}" (click)="unarchiveFlag(row)">
            <mat-icon>unarchive</mat-icon> <span>Unarchive</span>
          </button>
        </ng-container>

        <ng-template #archiveButton>
          <button mat-button color="accent" class="mr-5px" matTooltip="{{'ARCHIVE_FLAG' | translate}}" (click)="archiveFlag(row)">
            <mat-icon>inventory_2</mat-icon> <span>Archive</span>
          </button>
        </ng-template>

        <button mat-button matTooltip="{{'DELETE_FLAG' | translate}}" (click)="deleteFlagDialog(row)">
          <mat-icon>delete</mat-icon> <span>Delete</span>
        </button>

      </ng-template>
    </ngx-datatable-column>

  </ngx-datatable>



</div>