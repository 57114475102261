<form #contractTemplateForm="ngForm" *ngIf="contractTemplate">
  <div fxLayout="row" fxLayoutAlign="center start">
    <div fxFlex="100" class="mt-xs">
      <mat-form-field style="width: 100%">
        <mat-label>{{'TEMPLATE_NAME' | translate: {Default:'Template Name'} }}</mat-label>
        <input matInput [(ngModel)]="contractTemplate.name" required name="contractTemplateName" #contractTemplateName="ngModel">
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="center start">
    <div fxFlex="100" class="mt-xs">
      <app-customer-quick-links-editor #customerQuickLinksEditorV2Component [(editorContent)]="contractTemplate.legalText" messageType="EMAIL"> </app-customer-quick-links-editor>

      <!--app-flo-editor style="width: 100%" [text]="contractTemplate.legalText" (textUpdated)="bodyUpdated($event);">
      </app-flo-editor-->
    </div>
  </div>
  <br>

  <mat-card appearance="outlined" class="no-shadow border mx-0 mdc-mb-1 rounded-10" style="box-shadow: none !important">
    <mat-card-content>
      <h4><strong>{{'CREATE_AND_SEND_CONTRACT_NOW' | translate: {Default:'Would you like to create and send contract now?'} }}</strong></h4>

      <div fxLayout="row wrap" fxLayoutAlign="" fxLayoutGap="1%">
        <div fxFlex class="mb-lg-0">
          <mat-checkbox fxFlex="30" name="sendToAll" [(ngModel)]="sendToAll" [color]="'accent'" (ngModelChange)="changeSendToAll()">
            {{'SEND_TO_ALL_CUSTOMERS' | translate: {Default:'Send To All Customers'} }}
          </mat-checkbox>
        </div>
        <div fxFlex class="mb-lg-0" *ngIf="!sendToAll">
          <app-add-edit-contract-flags-panel [contextIdDto]="contextIdDto" [include]="true" [selectedFlags]="includedFlags" (addFlagEvt)="addIncludedFlag($event)" (removeFlagEvt)="removeIncludedFlag($event)"></app-add-edit-contract-flags-panel>
        </div>
        <div fxFlex class="mb-lg-0">
          <app-add-edit-contract-flags-panel [include]="false" [contextIdDto]="contextIdDto" [selectedFlags]="excludedFlags" (addFlagEvt)="addExcludedFlag($event)" (removeFlagEvt)="removeExcludedFlag($event)"></app-add-edit-contract-flags-panel>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <div fxLayout="row" class="mt-1">
    <button mat-raised-button color="accent" (click)="update()" *ngIf="contractTemplate.id" [disabled]="!contractTemplateForm.form.valid" disabled="disabled">
      {{'UPDATE_CONTRACT_TEMPLATE' | translate: {Default:'Update Contract Template'} }}
    </button>
    <button mat-raised-button color="accent" (click)="create()" *ngIf="!contractTemplate.id" [disabled]="!contractTemplateForm.form.valid || saving" disabled="disabled">
      {{'CREATE_CONTRACT_TEMPLATE' | translate: {Default:'Create Contract template'} }}
    </button>
    <button mat-raised-button (click)="backToList()" class="ml-1">{{'CANCEL' | translate: {Default: 'Cancel'} }}</button>
  </div>

</form>