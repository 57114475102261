<div class="view-invoice-page invoice-container w-100" *ngIf="invoice && invoiceDto">

  <!-- <div class="mdc-px-3 mdc-mb-1" fxLayout="row" fxLayoutWrap="wrap">
    <div fxFlex>
      <h3>{{'Invoice No' | translate}}: <strong>{{invoice.invoiceNumber}}</strong></h3>
    </div>
    <div fxFlex="nogrow" fxLayout="column">
      <div fxFlex>
        {{'Invoice Date' | translate}}: <strong>{{invoice.invoiceDate | localizedDate}}</strong>
      </div>
      <div fxFlex>
        {{'Due Date' | translate}}: <strong>{{invoice.dueDate | localizedDate}}</strong>
      </div>
    </div>
  </div> -->
  <div class="mdc-px-3 mdc-mb-1" *ngIf="invoiceDto && invoiceDto.showPackages && invoice?.invoicePackageRows?.length">
    <div fxLayout="row" class="mdc-mb-1">
      <div fxFlexAlign="center">
        <h6 class="mb-0">{{'PACKAGE' | translate: {Default: 'Package'} }}</h6>
      </div>
      <span class="example-spacer"></span>
    </div>
    <div class="table-responsive">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th class="mat-text-secondary">{{"DESCRIPTION" | translate: {Default: 'Description'} }}</th>
            <th class="mat-text-secondary">{{'PRICE' | translate:{Default: 'Price'} }}</th>
            <th class="mat-text-secondary" style="width:120px" *ngIf="!invoice?.paidInFull">{{'DISCOUNT' | translate:
              {Default: 'Discount'} }}</th>
            <th class="mat-text-secondary">{{'TAX' | translate:{Default: 'Tax'} }}</th>
            <th class="mat-text-secondary">{{'AMOUNT' | translate: {Default: 'Amount'} }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of invoice.invoicePackageRows; let editPackageIndex = index; trackBy: trackByFnPackage"
            tabindex="-1">
            <td>
              <select class="service-select border-none table-input" [(ngModel)]="row.packageNumber" required
                (ngModelChanged)="packageChanged(row)" name="packageNumber" #packageNumber{{editPackageIndex}}="ngModel"
                disabled>
                <option *ngFor="let package of packages" [value]="package.id">
                  {{ package.packageName }}
                </option>
              </select>
            </td>
            <td>
              <input class="table-input" type="number"
                placeholder="{{'UNIT_PRICE' | translate: {Default: 'Unit Price'} }}"
                name="packageUnitPrice{{editPackageIndex}}" #packageUnitPrice="ngModel" [(ngModel)]="row.unitPrice"
                (ngModelChange)="unitPriceChanged($event, row)" (focus)="focusOn($event)"
                (blur)="unitPriceBlured($event, row)" id="packageUnitPrice{{editPackageIndex}}" required>
              <div *ngIf="packageUnitPrice.hasError('required')">
                {{'UNIT_PRICE_IS' | translate: {Default: 'Unit Price Is'} }} <strong>{{'REQUIRED' | translate: {Default:
                  'required'} }}</strong>
              </div>
            </td>
            <td *ngIf="!invoice?.paidInFull">
              <button (click)="onDiscountClick(row)" class="ml-xs mat-icon-button" mat-icon-button=""
                [matTooltip]="row?.discountDetails | discountTmpl:currencyCode">
                {{row?.discountDetails | discountTmpl:currencyCode}}
                <mat-icon role="img" [ngClass]="{'discount-applied': row?.discountDetails}" class="demo-toolbar-icon"
                  aria-label="Discount">local_offer</mat-icon>
              </button>
            </td>
            <td>
              {{row.tax || '0.00'}}%
            </td>
            <td>
              <app-discount-price-view [originalPrice]="row.grossPriceNoOffer" [finalPrice]="row.grossPrice"
                [currencyCode]="currencyCode"></app-discount-price-view>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="mdc-px-3 mdc-mb-1" *ngIf="invoiceDto && invoiceDto.showServices">
    <div fxLayout="row" class="mdc-mb-1">
      <div fxFlexAlign="center">
        <h6 class="mb-0">{{'SERVICES' | translate: {Default: 'Services'} }}</h6>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-bordered w-100">
        <thead>
          <tr>
            <th class="mat-text-secondary">{{"DESCRIPTION" | translate: {Default: 'Description'} }}</th>
            <th class="mat-text-secondary">{{'PRICE' | translate:{Default: 'Price'} }}</th>
            <th class="mat-text-secondary" style="width:120px" *ngIf="!invoice?.paidInFull">{{'DISCOUNT' | translate:
              {Default: 'Discount'} }}</th>
            <th class="mat-text-secondary">{{'TAX' | translate:{Default: 'Tax'} }}</th>
            <th class="mat-text-secondary">{{'AMOUNT' | translate: {Default: 'Amount'} }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of invoice.invoiceServiceRows; let editServiceIndex = index; trackBy: trackByFnService"
            tabindex="-1">
            <td>
              <!-- <p>{{row?.serviceName}} <span
                  class="service-select border-none table-input text-secondary">({{row?.serviceConsumerName ||
                  getServiceDesc(row)}})</span></p> -->
              <select id="serviceNumber{{editServiceIndex}}" class="service-select border-none w-100"
                [(ngModel)]="row.serviceNumber" (ngModelChanged)="serviceChanged(row)" required
                name="serviceNumber{{editServiceIndex}}" #serviceNumber{{editServiceIndex}}="ngModel" disabled>
                <option *ngFor="let service of consumerServices" [value]="service.id">
                  {{ service?.serviceName }}
                </option>
              </select>
              <span class="service-select border-none table-input text-secondary">({{row?.serviceConsumerName ||
                getServiceDesc(row)}})</span>

            </td>
            <td>
              <input class="table-input" type="number"
                placeholder="{{'UNIT_PRICE' | translate: {Default: 'Unit Price'} }}"
                name="serviceUnitPrice{{editServiceIndex}}" #serviceUnitPrice="ngModel" [(ngModel)]="row.unitPrice"
                (ngModelChange)="unitPriceChanged($event, row)" (focus)="focusOn($event)"
                (blur)="unitPriceBlured($event, row)" id="serviceUnitPrice{{editServiceIndex}}" required>
              <div *ngIf="serviceUnitPrice.hasError('required')">
                {{'UNIT_PRICE_IS' | translate: {Default: 'Unit Price Is'} }} <strong>{{'REQUIRED' | translate: {Default:
                  'required'} }}</strong>
              </div>
            </td>
            <td *ngIf="!invoice?.paidInFull">
              <button (click)="onDiscountClick(row)" class="ml-xs" mat-button
                [matTooltip]="row?.discountDetails | discountTmpl:currencyCode">
                {{row?.discountDetails | discountTmpl:currencyCode}}
                <mat-icon role="img" [ngClass]="{'discount-applied': row?.discountDetails}" class="demo-toolbar-icon"
                  aria-label="Discount">local_offer</mat-icon>
              </button>
            </td>
            <td>
              {{row.tax || '0.00'}}%
            </td>
            <td>
              <app-discount-price-view [originalPrice]="row.grossPriceNoOffer" [finalPrice]="row.grossPrice"
                [currencyCode]="currencyCode"></app-discount-price-view>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="mdc-px-3 mdc-mb-1" *ngIf="invoiceDto && invoiceDto.showProducts">
    <div fxLayout="row" class="mdc-mb-1">
      <div fxFlexAlign="center">
        <h6 class="mb-0">{{'PRODUCTS' | translate: {Default: 'Products'} }}</h6>
      </div>
      <span class="example-spacer"></span>
      <button mat-button color="accent" type="button" (click)="createProductInvoiceRow()">
        <mat-icon>add</mat-icon>
        {{'ADD_PRODUCT' | translate: {Default: 'Add Product'} }}
      </button>
    </div>
    <div class="no-data-available" *ngIf="!invoice.invoiceProductRows || !invoice.invoiceProductRows.length">
      {{'NO_PRODUCTS_IN_THIS_INVOICE' | translate: {Default: 'There are no products in this invoice.'} }}
    </div>
    <div class="table-responsive">
      <table class="table table-bordered w-100" *ngIf="invoice.invoiceProductRows && invoice.invoiceProductRows.length">
        <thead>
          <tr>
            <th class="mat-text-secondary">{{'PRODUCT' | translate: {Default: 'Product'} }}</th>
            <th class="mat-text-secondary" style="max-width:10%">{{'UNIT_PRICE' | translate: {Default: 'Unit Price'} }}
            </th>
            <th class="mat-text-secondary" style="width:50px">{{'QUANTITY' | translate: {Default: 'Quantity'} }}</th>
            <th class="mat-text-secondary" style="width:150px" *ngIf="!invoice?.paidInFull">{{'DISCOUNT' | translate:
              {Default: 'Discount'} }}</th>
            <th class="mat-text-secondary" style="width:80px">{{'TAX' | translate: {Default: 'Tax'} }}</th>
            <th class="mat-text-secondary">{{'AMOUNT' | translate: {Default: 'Amount'} }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of invoice.invoiceProductRows; let productIndex = index; trackBy: trackByFnProduct"
            tabindex="-1">
            <td>
              <mat-form-field appearance="outline" class="w-100 mb-0">
                <mat-select id="productNumber{{productIndex}}"
                  placeholder="{{'SELECT_PRODUCT' | translate: {Default: 'Select Product'} }}"
                  [(ngModel)]="row.productNumber" required (selectionChange)="productChanged(row)"
                  name="productNumber{{productIndex}}" #productNumber{{productIndex}}="ngModel">
                  <mat-option>
                    <ngx-mat-select-search [placeholderLabel]="'Search Product'" ngModel
                      (ngModelChange)="filterProducts($event)"
                      [noEntriesFoundLabel]="'No matching product'"></ngx-mat-select-search>
                  </mat-option>
                  <ng-container *ngIf="!shopConfig?.enableStockControl">
                    <mat-option *ngFor="let product of products | productFilter: searchProduct" [value]="product.id">
                      {{ product.productName }}
                    </mat-option>
                  </ng-container>
                  <ng-container *ngIf="shopConfig?.enableStockControl">
                    <mat-option *ngFor="let product of products | productFilter: searchProduct" [value]="product.id"
                      [disabled]="product?.stockAvailable <= 0">
                      {{ product.productName }}
                      <span *ngIf="product?.stockAvailable <= 0" class="mat-text-red">({{'OUT_OF_STOCK' |
                        translate}})</span>
                    </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </td>
            <td>
              <input class="table-input" type="number" placeholder="Unit Price" name="productUnitPrice{{productIndex}}"
                #productUnitPrice="ngModel" (ngModelChanged)="updateInvoice()" [(ngModel)]="row.unitPrice"
                (ngModelChange)="unitPriceChanged($event, row)" (focus)="focusOn($event)"
                (blur)="unitPriceBlured($event, row)" id="productUnitPrice{{productIndex}}" required>
              <div *ngIf="productUnitPrice.hasError('required')">
                {{'UNIT_PRICE_IS' | translate: {Default: 'Unit Price Is'} }} <strong>{{'REQUIRED' | translate: {Default:
                  'required'} }}</strong>
              </div>
            </td>
            <td>
              <input class="table-input" type="text" appNumberOnly
                [placeholder]="'QUANTITY' | translate: {Default: 'Quantity' } " name="productQuantity{{productIndex}}"
                #productQuantity="ngModel" [(ngModel)]="row.quantity" (ngModelChange)="quantityChanged($event, row)"
                id="productQuantity{{productIndex}}" required>
              <div *ngIf="productQuantity.hasError('required')">
                {{'QUANTITY_IS' | translate}} <strong>{{'REQUIRED' | translate: {Default: 'required'} }}</strong>
              </div>
            </td>
            <td *ngIf="!invoice?.paidInFull">
              <button (click)="onDiscountClick(row)" class="ml-xs" mat-button=""
                [matTooltip]="row?.discountDetails | discountTmpl:currencyCode">
                {{row?.discountDetails | discountTmpl:currencyCode}}

                <mat-icon role="img" [ngClass]="{'discount-applied': row?.discountDetails}"
                  class="demo-toolbar-icon">local_offer</mat-icon>
              </button>
            </td>
            <td>
              {{row.tax || '0.00'}}%
            </td>
            <td>
              <app-discount-price-view [originalPrice]="row.grossPriceNoOffer" [finalPrice]="row.grossPrice"
                [currencyCode]="currencyCode"></app-discount-price-view>
            </td>
            <td>
              <button mat-icon-button (click)="deleteProductRow(row)"
                *ngIf="row.productNumber && row.unitPrice ? !invoice?.paidInFull : true">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="mdc-px-3 mdc-mb-1" *ngIf="invoiceDto && invoiceDto.showItemRows">
    <h6 class="mdc-mb-1" *ngIf="invoiceTypes?.indexOf('BOARDING') > -1 || invoiceTypes?.indexOf('DAY_CARE') > -1">
      {{'RUNS' | translate: {Default: 'Runs'} }}
    </h6>
    <div class="table-responsive">
      <ngx-datatable class="material m-0" [rows]="invoiceDto.invoice.invoiceItemRows" [columnMode]="'flex'"
        [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'">
        <ngx-datatable-column name="{{'DESCRIPTION' | translate: {Default: 'Description'} }}" [flexGrow]="2">
          <ng-template ngx-datatable-cell-template let-row="row">
            <ng-container *ngIf="row?.itemRowType === 'petRun'">
              <strong>{{row.name}}</strong><br /><span class="mat-text-muted">{{row.description}}</span>
            </ng-container>
            <ng-container *ngIf="row?.itemRowType === 'petRunFeed'">
              <strong>{{'FEEDING_CHARGE' | translate: {Default: 'Feeding Charge'} }}</strong>
            </ng-container>
            <ng-container *ngIf="row?.itemRowType === 'petRunMedicine'">
              <strong>{{'MEDICATION_CHARGE' | translate: {Default: 'Medication Charge'} }}</strong>
            </ng-container>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'UNIT_PRICE' | translate: {Default: 'Unit Price'} }}" [flexGrow]="1">
          <ng-template ngx-datatable-cell-template let-row="row">
            <strong>{{row.unitPrice | currency:currencyCode:'symbol'}}</strong>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'TAX' | translate: {Default: 'Tax'} }}" [flexGrow]="1">
          <ng-template ngx-datatable-cell-template let-row="row">
            <strong>{{row.tax || 0}}%</strong>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'QUANTITY' | translate: {Default: 'Quantity'} }}" [flexGrow]="1">
          <ng-template ngx-datatable-cell-template let-row="row">
            <strong>{{row.quantity}}</strong>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'DISCOUNT' | translate: {Default: 'Discount'} }}" [flexGrow]="1">
          <ng-template ngx-datatable-cell-template let-row="row">
            {{row?.discountDetails | discountTmpl:currencyCode}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'AMOUNT' | translate: {Default: 'Amount'} }}" [flexGrow]="1">
          <ng-template ngx-datatable-cell-template let-row="row">
            <!-- <strong>{{ row.grossPrice | currency:currencyCode:'symbol'}}</strong> -->
            <app-discount-price-view [originalPrice]="row.grossPriceNoOffer" [finalPrice]="row.grossPrice"
              [currencyCode]="currencyCode"></app-discount-price-view>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>

  <div *ngIf="invoice" class="mdc-px-3 mt-15px">
    <div fxLayout="row">
      <div fxFlexAlign="center">
        <h6 class="mb-0" *ngIf="invoice.discountId && invoice?.discountDetails">
          <strong>
            {{discountItemsById[invoice.discountId]?.code || 'CUSTOM'}}
          </strong> - {{invoice?.discountDetails | discountTmpl:currencyCode}}
        </h6>
      </div>
      <span class="example-spacer"></span>
      <button *ngIf="!invoice?.paidInFull" mat-button color="primary" type="button" (click)="applyDiscountOnInvoice()">
        <mat-icon>verified</mat-icon>
        {{'APPLY_DISCOUNT' | translate: {Default: 'Apply Discount'} }}
      </button>
    </div>

    <div fxLayout="row" fxLayoutWrap="wrap">
      <div fxFlex.gt-sm="25" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
        <p class="text-uppercase ma-0 mat-text-secondary">{{'Subtotal' | translate}}</p>
        <h5 class="mt-5px mb-0">{{invoice.subTotalWithoutDiscount | currency:currencyCode:'symbol'}}</h5>
      </div>
      <div fxFlex.gt-sm="25" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
        <p class="text-uppercase ma-0 mat-text-secondary">{{'Tax' | translate}}</p>
        <h5 class="mt-5px mb-0">{{invoice.tax | currency:currencyCode:'symbol'}}</h5>
      </div>
      <div fxFlex.gt-sm="25" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
        <p class="text-uppercase ma-0 mat-text-secondary">{{'Discount' | translate}}</p>
        <h5 class="mt-5px mb-0">{{invoice.discountTotal | currency:currencyCode:'symbol'}}
        </h5>
      </div>
      <div fxFlex.gt-sm="25" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1 text-xs-left text-sm-right">
        <p class="text-uppercase ma-0 mat-text-secondary">{{'Total' | translate}}</p>
        <h5 class="mt-5px mb-0 mat-text-green">{{ invoice.total | currency:currencyCode:'symbol'}}</h5>
      </div>
    </div>
  </div>

  <div fxLayout="row wrap" *ngIf="!viewOnly && invoice && invoicePaymentStatus" class="mdc-px-3 ">
    <div fxFlex.gt-sm="33" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
      <h5 class="ma-0 text-bold">{{'AMOUNT_PAID' | translate}}: <b>{{ invoicePaymentStatus.amountPaid |
          currency:currencyCode:'symbol' }}</b></h5>
    </div>

    <div *ngIf="invoice?.paidInFull" fxFlex.gt-sm="33" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
      <h5 class="ma-0 mat-text-primary text-bold">{{'INVOICE_STATUS' | translate: {Default: 'Invoice Status: '}
        }}<b>{{'PAID' | translate: {Default: 'Paid'} }}</b></h5>
    </div>

    <div *ngIf="!invoice?.paidInFull && viewInvoicePaymentStatusDataDto?.depositStatus" fxFlex.gt-sm="33"
      fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
      <h5 class="ma-0 mat-text-primary text-bold">{{'STATUS_:' | translate: {Default: 'Status: '} }}<b>{{
          viewInvoicePaymentStatusDataDto?.depositStatus }}</b>
      </h5>
    </div>
    <div *ngIf="hasSavvyPayPayments && viewInvoicePaymentStatusDataDto?.noShowPolicyEnabled && !invoice?.paidInFull"
      fxFlex.gt-sm="33" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
      <h5 class="ma-0 text-bold">{{'NO_SHOW_CHARGE' | translate: {Default: 'No Show Charge: '} }}<b>{{
          ((viewInvoicePaymentStatusDataDto.noShowPolicyCharge / 100) *
          viewInvoicePaymentStatusDataDto.totalAmount) |
          currency:currencyCode:'symbol' }}</b></h5>
      <mat-hint>
        {{'NO_SHOW_CHARGE_IS' | translate: {Default: 'No Show Charge is'} }} {{
        viewInvoicePaymentStatusDataDto.noShowPolicyCharge }} {{'PERCENT_OF_TOTAL' | translate: {Default: '% of the
        total amount.'} }}
      </mat-hint>
    </div>
    <div *ngIf="hasSavvyPayPayments && viewInvoicePaymentStatusDataDto.noShowPolicyEnabled && !invoice?.paidInFull"
      fxFlex.gt-sm="33" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
      <h5 class="ma-0 text-bold">{{'LATE_CANCELLATION_CHARGE' | translate: {Default: 'Late Cancellation Charge: '}
        }}<b>{{
          ((viewInvoicePaymentStatusDataDto.lateCancellationCharge / 100) * viewInvoicePaymentStatusDataDto.totalAmount)
          |
          currency:currencyCode:'symbol' }}</b></h5>
      <mat-hint *ngIf="viewInvoicePaymentStatusDataDto.lateCancellationRemainingDays <= 0">
        {{'LATE_CANCELLATION_CHARGE_IS' | translate: {Default: 'Late Cancellation Charge is'} }} {{
        viewInvoicePaymentStatusDataDto.lateCancellationCharge }} {{'PERCENT_OF_TOTAL' | translate: {Default: '% of the
        total amount.'} }}
      </mat-hint>
      <mat-hint *ngIf="viewInvoicePaymentStatusDataDto.lateCancellationRemainingDays > 0">
        {{ viewInvoicePaymentStatusDataDto.lateCancellationRemainingDays }} {{'DAYS_REMAINING_BEFORE_CHARGE_APPLIED' |
        translate: {Default: 'days remaining before late cancellation charge can be applied.'} }}
      </mat-hint>
    </div>
    <div fxFlex.gt-sm="33" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1" align="right"
      *ngIf="!invoice?.paidInFull">
      <h5 class="ma-0 mat-text-primary text-bold">{{'AMOUNT_REMAINING' | translate: {Default: 'Amount Remaining: '}
        }}<b>{{amountOutstanding | currency:currencyCode:'symbol'}}</b>
      </h5>
    </div>
    <div fxFlex align="right"
      *ngIf="(!hasSavvyPayPayments || viewInvoicePaymentStatusDataDto.depositStatus !== null)  && !invoice?.paidInFull">
      <button
        *ngIf="(paymentSettings?.savvyPaySettings === null || paymentSettings?.savvyPaySettings?.accountHolderCode === null) && !this.accountHolder?.id"
        mat-raised-button color="primary" (click)="goToPaymentSettings()">{{'SETUP_SAVVY_PAY' | translate: {Default:
        'Setup Savvy Pay'} }}
      </button>
    </div>
  </div>

  <div *ngIf="!viewOnly && hasSavvyPayPayments" class="mdc-px-3 mt-15px">
    <form *ngIf="!invoice?.paidInFull" #paymentForm="ngForm" fxLayout="column">
      <mat-form-field appearance="outline">
        <mat-hint>
          {{'ENTER_AMOUNT_TAKEN' | translate: {Default: 'Enter the amount to be taken.'} }}
          {{'AMOUNT_CAN_BE_GREATER_THAN_TOTAL' | translate: {Default: 'Amount can be greater than the total amount.'} }}
        </mat-hint>
        <mat-label>{{'AMOUNT' | translate: {Default: 'Amount'} }}</mat-label>
        <input matInput [(ngModel)]="amountOutstanding" name="amount" [required]="true" #amount="ngModel" id="amount"
          [placeholder]="'ENTER_AMOUNT' | translate: {Default: 'Enter Amount'} " style="width: 100%">
      </mat-form-field>

      <br>
      <mat-card-actions *ngIf="!accountHolder?.fromUpgraded">
        <button mat-raised-button color="accent" (click)="takePayment()" [disabled]="!paymentForm.valid || submitting">
          {{'TAKE_PAYMENT' | translate: {Default: 'Take Payment'} }}
        </button>&nbsp;

        <button *ngIf="viewInvoicePaymentStatusDataDto.noShowPolicyEnabled" mat-raised-button color="accent"
          (click)="chargeNoShow()" [disabled]="submitting">{{'CHARGE_NO_SHOW' | translate: {Default: 'Charge No-Show'}
          }}</button>&nbsp;

        <button
          *ngIf="viewInvoicePaymentStatusDataDto.noShowPolicyEnabled && viewInvoicePaymentStatusDataDto.lateCancellationRemainingDays <= 0"
          mat-raised-button color="accent" (click)="chargeLateCancellation()" [disabled]="submitting">
          {{'CHARGE_LATE_CANCELLATION' | translate: {Default: 'Charge Late Cancellation'} }}
        </button>
      </mat-card-actions>

      <mat-card-actions *ngIf="accountHolder?.fromUpgraded">
        <button mat-raised-button color="accent" (click)="takePaymentV2()" [disabled]="!paymentForm.valid || submitting">
          {{'TAKE_PAYMENT' | translate: {Default: 'Take Payment'} }}
        </button>&nbsp;

        <button *ngIf="viewInvoicePaymentStatusDataDto.noShowPolicyEnabled" mat-raised-button color="accent"
                (click)="chargeNoShowV2()" [disabled]="submitting">{{'CHARGE_NO_SHOW' | translate: {Default: 'Charge No-Show'}
          }}</button>&nbsp;

        <button
          *ngIf="viewInvoicePaymentStatusDataDto.noShowPolicyEnabled && viewInvoicePaymentStatusDataDto.lateCancellationRemainingDays <= 0"
          mat-raised-button color="accent" (click)="chargeLateCancellationV2()" [disabled]="submitting">
          {{'CHARGE_LATE_CANCELLATION' | translate: {Default: 'Charge Late Cancellation'} }}
        </button>
      </mat-card-actions>

    </form>
  </div>
</div>


<div class="mdc-px-3 my-2" *ngIf="!viewOnly">

  <button *ngIf="!invoice?.paidInFull" mat-raised-button color="accent" (click)="editInvoice()"
    class="mr-05 d-inline-block mdc-py-1">
    <mat-icon>edit</mat-icon>
    {{'EDIT_INVOICE' | translate: {Default: 'Edit Invoice'} }}
  </button>

  <button [disabled]="!paymentSettings?.paymentSetup" mat-raised-button color="accent" (click)="sendInvoice()"
    class="mr-05 mdc-my-1 d-inline-block" [matTooltip]="getSendInvoiceToolTip()">
    <mat-icon>receipt_long</mat-icon>
    {{'SEND_INVOICE' | translate: {Default: 'Send Invoice'} }}
  </button>

  <button *ngIf="!invoice?.paidInFull" mat-raised-button color="accent" (click)="addPayment()" class="mr-05 mdc-my-1">
    <mat-icon>payments</mat-icon>
    {{'ADD_PAYMENT' | translate: {Default: 'Add Payment'} }}
  </button>

  <button *ngIf="!invoice?.paidInFull" mat-raised-button color="accent" (click)="paidFull('CASH')"
    class="mr-05 mdc-my-1">
    <mat-icon>store</mat-icon>
    {{'PAID_CASH' | translate: {Default: 'Paid Cash'} }}
  </button>

  <button *ngIf="!invoice?.paidInFull" mat-raised-button color="accent" (click)="paidFull('CARD_MACHINE')"
    class="mr-05 mdc-my-1">
    <mat-icon>payment</mat-icon>
    {{'PAID_CARD' | translate: {Default: 'Paid Cash'} }}
  </button>

  <button *ngIf="!invoice?.paidInFull" mat-raised-button color="accent" (click)="paidFull('DIRECT_DEBIT')"
    class="mr-05 mdc-my-1">
    <mat-icon>payments</mat-icon>
    {{'PAID_DIRECT_DEBIT' | translate: {Default: 'Paid Direct Debit'} }}
  </button>

  <button *ngIf="!invoice?.paidInFull" mat-raised-button color="accent" (click)="paidFull('BANK_TRANSFER')"
    class="mr-05 mdc-my-1">
    <mat-icon>account_balance</mat-icon>
    {{'PAID_BANK' | translate: {Default: 'Paid Bank'} }}
  </button>

  <!--  <button [disabled]="!paymentSettings-->
  <!--    || !paymentSettings.savvyPaySettings-->
  <!--    || !paymentSettings.savvyPaySettings.accountHolderCode-->
  <!--    || !customerDto-->
  <!--    || !customerDto.mobile-->
  <!--" mat-raised-button color="accent"-->
  <!--          (click)="showSmsLink()" class="mr-05">-->
  <!--    <mat-icon>money</mat-icon>-->
  <!--    {{'Send Payment Link' | translate}}-->
  <!--  </button>-->

  <button *ngIf="plans && plans.length > 0 && !invoice?.paidInFull" mat-raised-button color="accent"
    (click)="showPaidPlans()" class="mr-05">
    <mat-icon>card_membership</mat-icon>
    {{'PAID_PLANS' | translate: {Default: 'Paid Plans'} }}
  </button>

  <button [disabled]="!cardMachines || cardMachines.length <= 0" mat-raised-button color="accent"
    (click)="showTerminalPayment()" class="mr-05">
    <mat-icon>account_balance</mat-icon>
    {{'SAVVY_CARD_MACHINE' | translate: {Default: 'Savvy Card Machine'} }}
  </button><br>
  <mat-hint *ngIf="!paymentSettings?.paymentSetup"><a (click)="goToPaymentSettings()">{{"SETUP_SAVVY_PAYMENTS" |
      translate: {Default: 'Setup your Payment Settings here to start sending invoices.'} }}</a></mat-hint>
  <br>
  <mat-hint *ngIf="!cardMachines || cardMachines.length <= 0"><a
      (click)="goToPaymentSettings()">{{"SETUP_SAVVY_PAY_DESCRIPTION" | translate: {Default: 'Setup your Savvy Pay Card
      Machine here to start taking in-person payments.'} }}</a></mat-hint>

</div>
