import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { FlagDefService, FlagDefinition, ListFlagDefsResponse } from '@savvy/flags';
import { ContextIdDto } from '@savvy/menu';
import { Subject, Subscription } from 'rxjs';
import { ContextService } from 'src/app/flo/context.service';
import { SendToAllMessageStep } from '../send-to-all-message-step.interface';
import { EmailRecipient } from '@savvy/messaging';

@Component({
  selector: 'app-send-to-all-message-configuration',
  templateUrl: './send-to-all-message-configuration.component.html',
  styleUrls: ['./send-to-all-message-configuration.component.scss']
})
export class SendToAllMessageConfigurationComponent implements OnInit, OnDestroy, SendToAllMessageStep {

  contextIdDto: ContextIdDto;
  subscriptions: Subscription[] = [];
  sendWithflags = false;

  @Input() recipientType: string;

  flagDefs: FlagDefinition[] = [];
  includeFlagDefs: FlagDefinition[] = [];
  excludeFlagDefs: FlagDefinition[] = [];

  formGroup: UntypedFormGroup = new UntypedFormGroup({
    allCustomers: new UntypedFormControl(true),
    marketing: new UntypedFormControl(false),
    flags: new UntypedFormControl([]),
    excludeFlags: new UntypedFormControl([]),
  });

  private _unsubscribeAll: Subject<any>;

  constructor(
    private contextService: ContextService,
    private flagDefService: FlagDefService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
        this.loadFlagDefs();
      }
    }));
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  removeFlag(flag: FlagDefinition): void {
    const index = this.selectedFlagDefs.indexOf(flag);

    if (index >= 0) {
      this.selectedFlagDefs.splice(index, 1);
    }
  }

  getFlagType() {

    if (this.recipientType === EmailRecipient.RecipientTypeEnum.Customer) {
      return FlagDefinition.FlagDefinitionTypeEnum.Customer;
    } else if (this.recipientType === EmailRecipient.RecipientTypeEnum.Student) {
      return FlagDefinition.FlagDefinitionTypeEnum.Student;
    } else if (this.recipientType === EmailRecipient.RecipientTypeEnum.Lead) {
      return FlagDefinition.FlagDefinitionTypeEnum.Lead;
    } else {
      return 'UNKNOWN';
    }
  }

  loadFlagDefs() {
    this.flagDefService.listFlagDefsByType(this.getFlagType(),
      this.contextIdDto.contextId, this.contextIdDto.contextIdType)
      .subscribe((res: ListFlagDefsResponse) => {
        this.flagDefs = [...res.flagDefinitions];
        // this.includeFlagDefs = [...res.flagDefinitions];
        // this.excludeFlagDefs = [...res.flagDefinitions];
        this.setIncludeFlagDefs();
        this.setExcludeFlagDefs();
      });
  }

  setIncludeFlagDefs() {
    this.includeFlagDefs = this.flagDefs.filter(v => this.formGroup.controls.excludeFlags.value.indexOf(v.id) < 0);
  }

  setExcludeFlagDefs() {
    this.excludeFlagDefs = this.flagDefs.filter(v => this.formGroup.controls.flags.value.indexOf(v.id) < 0);
  }

  includeFlagsSelectionChange(event: MatSelectChange) {
    this.setExcludeFlagDefs();
  }

  excludeFlagsSelectionChange(event: MatSelectChange) {
    this.setIncludeFlagDefs();
  }

  onFlagRemovedFromSelection(value: any) {
    const selectedFlagDefs = this.formGroup.controls.flags.value as string[];
    this.formGroup.patchValue({ flags: selectedFlagDefs.filter(v => v !== value.id) });
    this.setExcludeFlagDefs();
  }

  get selectedFlagDefs(): FlagDefinition[] {
    const selectedFlagDefs = this.formGroup.controls.flags.value as string[];
    if (!selectedFlagDefs || !selectedFlagDefs.length) {
      return [];
    }
    return this.flagDefs.filter(v => selectedFlagDefs.indexOf(v.id) >= 0);
  }

  onExcludeFlagRemovedFromSelection(value: any) {
    const selectedFlagDefs = this.formGroup.controls.excludeFlags.value as string[];
    this.formGroup.patchValue({ excludeFlags: selectedFlagDefs.filter(v => v !== value.id) });
    this.setIncludeFlagDefs();
  }

  get selectedExcludeFlagDefs(): FlagDefinition[] {
    const selectedFlagDefs = this.formGroup.controls.excludeFlags.value as string[];
    if (!selectedFlagDefs || !selectedFlagDefs.length) {
      return [];
    }
    return this.flagDefs.filter(v => selectedFlagDefs.indexOf(v.id) >= 0);
  }

  get fg(): UntypedFormGroup {
    return this.formGroup;
  }

  getFormRef(): UntypedFormGroup {
    return this.formGroup;
  }

  onStepChange(): boolean {
    return true;
  }

}
