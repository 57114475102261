import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import Quill from 'quill';
import { QuillEditorComponent } from 'ngx-quill';
import QuicklinksBlot from './QuicklinksBlot';
import { FormControl } from '@angular/forms';

// override p with div tag
const Parchment = Quill.import('parchment');
const Block = Parchment.query('block');

Block.tagName = 'DIV';
// or class NewBlock extends Block {}; NewBlock.tagName = 'DIV';
Quill.register(Block /* or NewBlock */, true);

// Add fonts to whitelist
const Font = Quill.import('formats/font');
Font.whitelist = ['sans-serif', 'monospace', 'serif'];
Quill.register(Font, true);

Quill.register({
  'formats/Quicklinks': QuicklinksBlot
});

export interface Variable {
  label: string;
  value: string;
  type: string;
  id: string;
  cssClassName: string;
}
@Component({
  selector: 'app-flo-editor',
  templateUrl: 'editor.component.html',
  styleUrls: ['editor.component.scss']
})
export class FloEditorComponent implements OnInit {

  @Output() textUpdated = new EventEmitter();
  @Output() editorCreated = new EventEmitter<Quill>();

  @ViewChild('editor', { static: true }) editor: QuillEditorComponent;
  @Input() variables: Array<Variable>;
  @Input() text: string;
  @Input() required: boolean;
  @Input() editorId: string;
  @Input() readOnly: boolean;
  @Input() hideToolbar: boolean;
  @Input() placeholder = 'Insert text here...';
  @Input() format: 'html' | 'text' = 'html';

  title = 'Quill works!';

  hide;
  quill;
  modules: any;
  quillEditorControl = new FormControl('');

  constructor(
  ) { }

  logChange(event: any) {
    this.textUpdated.emit(this.text);
  }

  insertVariable(variable: Variable) {
    const range = this.quill.getSelection(true);
    if (range) {
      this.quill.insertText(range.index, `{{${variable.value}}}`, Quill.sources.USER);
    }
  }

  setFocus($event) {
    this.quill = $event;
    $event.focus();
  }

  created(event: Quill) {
    this.quill = event;
    if (this.editorCreated) {
      this.editorCreated.emit(event);
    }
    console.log('editor-created', event);
  }

  includeQuicklink(variable: Variable, update?: boolean) {
    const range = this.quill.getSelection(true);

    console.log("variable");
    console.log(variable);
    if (update) {
      this.quill.deleteText(range.index, 1, Quill.sources.SILENT);
    }

    this.quill.insertEmbed(
      range.index,
      //Insert the TemplateMarker in the same range as the cursor is

      'Quicklinks',
      //This is the name of the Embed

      variable,
      //These are the variable values to feed to the editor
      Quill.sources.USER
    );

    this.quill.insertText(range.index + 1, ' ', Quill.sources.SILENT);
    //Add a space after the marker

    this.quill.setSelection(range.index + 2, Quill.sources.SILENT);
  }

  ngOnInit() {
    this.modules = {
      toolbar: !this.hideToolbar,
    };
  }
}
