/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { ContextIdDto } from '@savvy/ui';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export enum APP_TYPE_ENUM {
  AESTHETICS = 'AESTHETICS',
  BARBERS = 'BARBERS',
  BEAUTY_SALON = 'BEAUTY_SALON',
  CONTRACTS = 'CONTRACTS',
  GROOM_SCHOOL = 'GROOM_SCHOOL',
  HAIR_SALON = 'HAIR_SALON',
  INVOICES = 'INVOICES',
  NAIL_SALON = 'NAIL_SALON',
  PET_DAY_CARE = 'PET_DAY_CARE',
  PET_GROOMING_SALON = 'PET_GROOMING_SALON',
  PET_TRAINERS = 'PET_TRAINERS',
  PET_WALKERS = 'PET_WALKERS',
  PHOTO_STUDIO = 'PHOTO_STUDIO',
  RETAILER = 'RETAILER',
  SHOP = 'SHOP',
  TATTOO_SALON = 'TATTOO_SALON',
  TRADING = 'TRADING',
  VETS = 'VETS',
  WEBSITES = 'WEBSITES'
}

export enum APP_URLS {
  BARBERS = 'https://app.savvybarbers.com/',
  BEAUTY_SALON = ' https://app.savvybeautysalon.com/',
  GROOM_SCHOOL = 'https://app.savvygroomschool.com/',
  HAIR_SALON = 'https://app.savvyhairsalon.com/',
  NAIL_SALON = 'https://app.savvynailspa.com/',
  PET_DAY_CARE = 'https://app.savvypetstay.com/',
  PET_GROOMING_SALON = 'https://app.savvypetspa.com/',
  PET_TRAINERS = 'https://app.savvypettrainers.com/',
  PET_WALKERS = 'https://app.savvywalkers.com/',
  PHOTO_STUDIO = 'https://app.savvystudio.com/',
  TATTOO_SALON = 'https://app.savvytattoos.com/',
  TRADING = 'https://app.savvytrades.com/',
  VETS = 'https://app.savvyvetcare.com/'
}

@Injectable()
export class ContextService {

  appType: APP_TYPE_ENUM | string = APP_TYPE_ENUM.PET_GROOMING_SALON;
  builderApp = false;
  public appOwnerLabel = 'Groomer';
  private contextIdDto: ContextIdDto | null = null;
  private contextIdDtoSource: BehaviorSubject<ContextIdDto | null> = new BehaviorSubject(this.contextIdDto);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public contextIdDto$: Observable<ContextIdDto | null> = this.contextIdDtoSource.asObservable();

  private appTypeSource: BehaviorSubject<APP_TYPE_ENUM | string> = new BehaviorSubject(this.appType);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public appType$: Observable<string> = this.appTypeSource.asObservable();

  constructor(
  ) {
    // console.log('ContextService:context id ' + this.paramsService.getParam('contextId'));

  }

  public setContextDto(contextIdDto: ContextIdDto | undefined): void {
    this.contextIdDto = contextIdDto;
    this.contextIdDtoSource.next(this.contextIdDto);
  }

  public getContextDto(): ContextIdDto | undefined {
    return this.contextIdDto || undefined;
  }

  public isPetStay(): boolean {
    return this.appType === APP_TYPE_ENUM.PET_DAY_CARE;
  }

  public setAppType(appType: APP_TYPE_ENUM | string) {
    // alert('app type is ' + appType);
    this.appType = appType;
    sessionStorage.setItem('appType', appType);
    switch (appType) {
      case APP_TYPE_ENUM.PET_GROOMING_SALON:
      case APP_TYPE_ENUM.GROOM_SCHOOL:
        this.appOwnerLabel = 'Groomer';
        break;
      case APP_TYPE_ENUM.PHOTO_STUDIO:
        this.appOwnerLabel = 'Photographer';
        break;
      case APP_TYPE_ENUM.TATTOO_SALON:
        this.appOwnerLabel = 'Tattoo Artist';
        break;
      case APP_TYPE_ENUM.BARBERS:
        this.appOwnerLabel = 'Barber';
        break;
      case APP_TYPE_ENUM.BEAUTY_SALON:
        this.appOwnerLabel = 'Stylist';
        break;
      case APP_TYPE_ENUM.VETS:
        this.appOwnerLabel = 'Vet';
        break;

      default:
        break;
    }
    this.appTypeSource.next(appType);
  }

  public getAppType(): APP_TYPE_ENUM | string {
    return this.appType;
  }

  public hasPets(): boolean {
    if (this.appType === APP_TYPE_ENUM.PET_GROOMING_SALON
      || this.appType === APP_TYPE_ENUM.VETS
      || this.appType === APP_TYPE_ENUM.PET_DAY_CARE
      || this.appType === APP_TYPE_ENUM.GROOM_SCHOOL
      || this.appType === APP_TYPE_ENUM.PET_TRAINERS
      || this.appType === APP_TYPE_ENUM.PET_WALKERS) {
      return true;
    }
    return false;
  }
}
