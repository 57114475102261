import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ContextIdDto } from '@savvy/entity-instance-composite';

// import { PetRun, RunsCompositeRun } from '@savvy/services';
import { ReplaySubject, Subject } from 'rxjs';
import { EntityDefinitionId } from '@savvy/view-definition';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
// import { AddRunDialogComponent } from 'src/app/flo/element/fieldinstance/addRunDialog.component';
// import { EventBusRun } from 'src/app/flo/element/EventBusRun';
import { PetRun, PetRunService } from '@savvy/pet-stay';
import { AddRunComponent } from '../../add-run/add-run.component';

@Component({
  selector: 'app-run-selector-pet-stay',
  templateUrl: './runSelectorPetStay.component.html'
})
export class RunSelectorPetStayComponent implements OnInit, OnChanges {

  @Input() contextIdDto: ContextIdDto;
  @Input() showPlaceholder: boolean;
  @Input() selectedRun: PetRun;
  @Input() runList: PetRun[] = [];

  @Output() runSelected: EventEmitter<PetRun> = new EventEmitter();

  public filteredRuns: ReplaySubject<PetRun[]> = new ReplaySubject<PetRun[]>(1);
  private destroy$ = new Subject();


  constructor(
    private petRunService: PetRunService,
    private dialog: MatDialog
  ) {

  }

  ngOnInit(): void {
    this.loadRuns();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.runList?.currentValue !== changes?.runList?.previousValue) {
      this.loadRuns();
    }
  }

  runChanged(event) {
    if (event.value) {
      this.runSelected.emit(this.selectedRun);
    }
  }

  loadRuns() {
    this.filteredRuns.next(this.runList);
  }

  filterRuns(value: string) {
    if (!this.runList) {
      return;
    }
    // get the search keyword
    if (!value) {
      this.filteredRuns.next(this.runList.slice());
      return;
    }
    const search = value.toLowerCase();
    // filter the runs
    this.filteredRuns.next(
      this.runList.filter(petRun => petRun.name.toLowerCase().indexOf(search) > -1)
    );
  }

  createRun() {
    const dialogRef = this.dialog.open(AddRunComponent, {
      width: '650px',
      data: {
        contextIdDto: this.contextIdDto
      },
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe((petRun: PetRun) => {
      if (petRun) {
        petRun.ownerId = this.contextIdDto.contextId;
        this.petRunService.createPetRun(petRun).subscribe((res: PetRun) => {
          if (res) {
            this.runList.push(res);
            this.loadRuns();
            this.selectedRun = res;
            this.runSelected.emit(this.selectedRun);
          }
        });
      }
    });

  }

  loadAndSetRun(service: PetRun) {
    console.log('loading new services');
    // this.serviceApi.loadRunsComp(
    //   this.contextIdDto.contextId,
    //   this.contextIdDto.contextIdType
    // ).subscribe(response => {
    //   this.petRuns = response.serviceDtos;
    //   this.filteredRuns.next(this.petRuns);
    //   const newRun = response.serviceDtos.find(s => s?.serviceName === service?.serviceName);
    //   setTimeout(() => {
    //     this.selectedRun = newRun;
    //     this.serviceSelected.emit(service);
    //   }, 500);
    // });
  }

  compareObjects(o1: PetRun, o2: PetRun): boolean {
    if (o1 && o2) {
      return o1.id === o2.id;
    }
    return false;
  }
}

