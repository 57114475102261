import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ContextIdDto } from '@savvy/app';
import { WorkflowdefService } from '@savvy/workflow-definition';
import { Subscription } from 'rxjs';
import { ContextService } from '../context.service';
import { AddEditTaskWorkflowDialogComponent } from './add-edit-task-workflow-dialog/add-edit-task-workflow-dialog.component';

@Component({
  selector: 'app-task-workflow-lists',
  templateUrl: 'task-workflow-list.html'
})
export class TaskWorkflowlistsComponent implements OnInit, OnDestroy {
  contextIdDto: ContextIdDto;
  subscriptions: Subscription[] = [];

  workflowLists: any[] = [];
  columns = [
    { prop: 'name' }
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private contextService: ContextService,
    private api: WorkflowdefService) { }

  create() {
    const createWorkflowDialogRef = this.dialog.open(AddEditTaskWorkflowDialogComponent, {
      data: {
        contextIdDto: this.contextIdDto
      },
      height: 'auto',
      width: '600px',
    });

    createWorkflowDialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.response) {
        this.getLists();
      }
    });

  }

  onSelect($event) {
    if ($event && $event.selected && $event.selected.length) {
      this.router.navigate(['task-workflow-details',
        $event.selected[0].id], { relativeTo: this.route });
    }
  }


  getLists() {
    this.api.getAllWorkflowDefs(this.contextIdDto.contextId, 'ENV_ID').subscribe(
      workflowLists => {
        this.workflowLists = workflowLists.workflowDefinitions;
      });
  }

  ngOnInit() {
    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
        this.getLists();
      }
    }));
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

}
