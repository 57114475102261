<mat-toolbar>
  <mat-toolbar-row>
    <h5>{{'APPT_NOTES' | translate}}</h5>
  </mat-toolbar-row>
</mat-toolbar>

<div class="mdc-p-3">
  <mat-form-field appearance="outline" style="width: 100%">
    <mat-label>{{'NOTES' | translate}}</mat-label>
    <textarea matInput id="bodyField" name="bodyField" #bodyField="ngModel" [(ngModel)]="notes" placeholder="{{'ENTER_MESSAGE' | translate}}">
          </textarea>
    <mat-error *ngIf="bodyField.hasError('required')">
      {{'MESSAGE_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
    </mat-error>
  </mat-form-field>
  <br>
  <button mat-raised-button color="accent" (click)="save()">{{'UPDATE' | translate}}</button>
</div>