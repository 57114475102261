<div *ngIf="invoice && invoicePaymentStatus" class="summary-container">
  <div fxLayout="row wrap">
    <div fxFlex.gt-sm="25" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
      <h6 class="text-uppercase text-md ma-0 text-bold">{{'Subtotal' | translate}}</h6>
      <h4 class="ma-0">{{invoice.subTotal | currency:currencyCode:'symbol'}}</h4>
    </div>
    <div fxFlex.gt-sm="25" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
      <h6 class="text-uppercase text-md ma-0 text-bold">{{'Tax' | translate}}</h6>
      <h4 class="ma-0">{{invoice.tax | currency:currencyCode:'symbol'}}</h4>
    </div>
    <div fxFlex.gt-sm="25" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
      <h6 class="text-uppercase text-md ma-0 text-bold">{{'Discount' | translate}}</h6>
      <h4 class="ma-0">{{0.00|currency:currencyCode:'symbol'}}</h4>
    </div>
    <div fxFlex.gt-sm="25" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
      <h6 class="text-uppercase text-sm ma-0 text-bold">{{'Total' | translate}}</h6>
      <h4 class="ma-0 text-bold">{{ invoice.total |
        currency:currencyCode:'symbol'}}</h4>
    </div>
  </div>
  <div fxLayout="row wrap">
    <div fxFlex.gt-sm="33" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
      <h4 class="ma-0 text-bold">Amount Paid: <b>{{ invoicePaymentStatus.amountPaid |
          currency:currencyCode:'symbol' }}</b></h4>
    </div>
    <div *ngIf="!invoice.paidInFull && viewInvoicePaymentStatusDataDto.depositStatus" fxFlex.gt-sm="33" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
      <h4 class="ma-0 mat-text-primary text-bold">Status: <b>{{ viewInvoicePaymentStatusDataDto.depositStatus }}</b></h4>
    </div>
    <div *ngIf="invoice.paidInFull" fxFlex.gt-sm="33" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
      <h4 class="ma-0 mat-text-primary text-bold">Invoice Status: <b>Paid</b></h4>
    </div>
    <div *ngIf="hasSavvyPayPayments && viewInvoicePaymentStatusDataDto.noShowPolicyEnabled && !invoice.paidInFull" fxFlex.gt-sm="33" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
      <h4 class="ma-0 text-bold">No Show Charge: <b>{{ ((viewInvoicePaymentStatusDataDto.noShowPolicyCharge / 100) * viewInvoicePaymentStatusDataDto.totalAmount) |
          currency:currencyCode:'symbol' }}</b></h4>
      <mat-hint>
        No show charge is {{ viewInvoicePaymentStatusDataDto.noShowPolicyCharge }} % of the total amount.
      </mat-hint>
    </div>
    <div *ngIf="hasSavvyPayPayments && viewInvoicePaymentStatusDataDto.noShowPolicyEnabled && !invoice.paidInFull" fxFlex.gt-sm="33" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
      <h4 class="ma-0 text-bold">Late cancellation charge: <b>{{ ((viewInvoicePaymentStatusDataDto.lateCancellationCharge / 100) * viewInvoicePaymentStatusDataDto.totalAmount) |
          currency:currencyCode:'symbol' }}</b></h4>
      <mat-hint *ngIf="viewInvoicePaymentStatusDataDto.lateCancellationRemainingDays <= 0">
        Late cancellation charge is {{ viewInvoicePaymentStatusDataDto.lateCancellationCharge }} % of the total amount.
      </mat-hint>
      <mat-hint *ngIf="viewInvoicePaymentStatusDataDto.lateCancellationRemainingDays > 0">
        {{ viewInvoicePaymentStatusDataDto.lateCancellationRemainingDays }} days remaining before late cancellation charge can be applied.
      </mat-hint>
    </div>
  </div>
  <div *ngIf="(!hasSavvyPayPayments || viewInvoicePaymentStatusDataDto.depositStatus !== null)  && !invoice.paidInFull">
    <mat-card-actions>
      <button *ngIf="paymentSettings?.savvyPaySettings == null || paymentSettings?.savvyPaySettings?.accountHolderCode == null" mat-raised-button color="primary" (click)="goToSavvyPaySettings()">{{'Setup Savvy Pay' | translate}}
      </button>&nbsp;

      <button mat-raised-button color="accent" (click)="addPayment()">{{'Add Payment' | translate}}
      </button>
      &nbsp;
      <button *ngIf="invoice && invoice.invoiceId" mat-raised-button color="accent" (click)="sendInvoice()">{{'Send Invoice' | translate}}
      </button>
    </mat-card-actions>
  </div>

  <div *ngIf="hasSavvyPayPayments && viewInvoicePaymentStatusDataDto.depositStatus === null">
    <form *ngIf="!invoice.paidInFull" #form="ngForm" fxLayout="column">
      <mat-form-field appearance="outline">
        <mat-hint>
          Enter the amount to be taken. Amount can be greater than the total amount.
        </mat-hint>
        <mat-label>Amount</mat-label>
        <input matInput [(ngModel)]="amountOutstanding" name="amount" [required]="true" #amount="ngModel" id="amount" placeholder="Enter Amount" style="width: 100%">
      </mat-form-field>

      <br>
      <mat-card-actions>
        <button mat-raised-button color="accent" (click)="takePayment()" [disabled]="!form.valid || submitting">Take Payment</button>&nbsp;

        <button *ngIf="viewInvoicePaymentStatusDataDto.noShowPolicyEnabled" mat-raised-button color="accent" (click)="chargeNoShow()" [disabled]="submitting">Charge No Show</button>&nbsp;

        <button *ngIf="viewInvoicePaymentStatusDataDto.noShowPolicyEnabled && viewInvoicePaymentStatusDataDto.lateCancellationRemainingDays <= 0" mat-raised-button color="accent" (click)="chargeLateCancellation()" [disabled]="submitting">Charge Late Cancellation</button>&nbsp;
        <button *ngIf="invoice && invoice.invoiceId" mat-raised-button color="accent" (click)="sendInvoice()">{{'Send Invoice' | translate}}
        </button>
      </mat-card-actions>

    </form>
  </div>
</div>