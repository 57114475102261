import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ContextIdDto } from '@savvy/app';
import { EntitydefsService } from '@savvy/entity-definition';
import { ContextService } from '../../../context.service';
import { LinkDetails } from '../../templateLinkEvents';
import { KeyValueTupleDto, TemplateCompService, TemplateValuesList } from '@savvy/template-engine';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-quick-default-variables',
  templateUrl: './quick-default-variables.component.html',
  styleUrls: ['./quick-default-variables.component.scss']
})
export class QuickDefaultVariablesComponent implements OnInit, OnDestroy {

  @Input() templateValuesList: TemplateValuesList;
  @Output() variableSelected = new EventEmitter();

  contextIdDto: ContextIdDto;
  subscriptions: Subscription[] = [];
  variablesList: LinkDetails[] = [];
  dataLoading = false;

  private _selectedItem: any;
  constructor(
    private contextService: ContextService
  ) {
  }


  @Input() set selectedItem(selectedItem: any) {
    this._selectedItem = selectedItem;
  }

  ngOnInit(): void {
    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
        if (this.templateValuesList) {
          this.handleResponse(this.templateValuesList);
        }
      }
    }));
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  loadTemplate() {

  }

  variableSelectionChange(data: any) {
    if (!this.variableSelected) {
      return;
    }
    this.variableSelected.emit(data);
  }

  private handleResponse(response: TemplateValuesList) {
    this.variablesList = response.keyValueTupleDtos.map(v => ({
      type: '',
      label: this.getLabel(v),
      subLabel: '',
      id: v.key,
      data: v,
    }));
  }

  private getLabel(v: KeyValueTupleDto) {
    if (v.value && v.value !== 'TODO' && v.value.length > 0) {
      return v.key + '  (' + v.value + ')';
    }
    return v.key;
  }
}
