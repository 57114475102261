import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContextIdDto } from '@savvy/menu';
import { FileValue } from '@savvy/pet';
import { StorageService } from '@savvy/storage';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { forkJoin, Observable } from 'rxjs';
import { ConfService } from 'src/app/flo/config/conf.service';
import { ConfirmationModalComponent } from 'src/app/flo/shared/components/confirmation-modal/confirmation-modal.component';
import { WindowRef } from 'src/app/flo/windowWrapper';

@Component({
  selector: 'app-pet-file-upload',
  templateUrl: './pet-file-upload.component.html',
  styleUrls: ['./pet-file-upload.component.scss']
})
export class PetFileUploadComponent implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() files: Array<FileValue> = [];
  @Input() isView = false;
  @Input() hideHeader = false;
  @Output() fileUpdated = new EventEmitter<Array<FileValue>>();
  droppedFilesArray: File[] = [];

  constructor(
    private dialog: MatDialog,
    private injector: Injector,
    private storage: StorageService,
  ) { }

  ngOnInit(): void {
    if (!this.files) {
      this.files = [];
    }
  }

  droppedFiles(files: NgxFileDropEntry[]) {
    console.log('droppedFiles called');
    this.droppedFilesArray = [];
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          /**
           *  // 5937643
           // 14273062
           alert('file size is ' + fileToUpload.size);
           /* if (fileToUpload.size > 10485760) {
      alert('File is too big.  Max size is 10485760');
    } else {
           */
          if (file.size > 10485760) {
            console.warn('File is too big, size is ' + file.size + '.  Max size is 10485760');
          } else {
            this.droppedFilesArray.push(file);
            if (files.length === this.droppedFilesArray.length) {
              this.saveFiles(this.droppedFilesArray);
            }
          }
        });
      }
    }
  }

  saveFiles(files) {
    console.log('saveFiles called', files.length);
    const filesObservable = [];
    for (const file of files) {
      filesObservable.push(this.postFile(file));
    }

    forkJoin(filesObservable).subscribe(res => {
      res.forEach((elem, index) => {
        const newFile: FileValue = {
          fileName: files[index].name,
          mimeType: files[index].type,
          fileReferenceId: elem['fileReference']
        };

        console.log('calling savedFiles push ', newFile);
        this.files.push(newFile);
        this.fileUpdated.emit(this.files);
      });
      console.log('calling updateFiles');
    });

  }

  postFile(fileToUpload: File): Observable<any> {
    return this.storage.uploadFileToStorage(this.contextIdDto.contextId, this.contextIdDto.contextIdType, fileToUpload);
  }

  removeFile(index) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        name: 'file'
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.files.splice(index, 1);
        this.fileUpdated.emit(this.files);
      }
    });
  }

  downloadFile(file: FileValue) {
    const url = ConfService.apiUrl() + '/rest/storage?contextId=' + this.contextIdDto.contextId +
      '&contextIdType=' + this.contextIdDto.contextIdType +
      '&id=' + file.fileReferenceId;

    const nativeWindow = this.injector.get(WindowRef).nativeWindow;
    nativeWindow.location.href = url;
  }
}
