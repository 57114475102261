<div *ngIf="bookingSuccess">
    <h1 *ngIf="this.bookingDef.bookingConfirmationDefinition.requireManualApproval">{{'BOOKING_REQUEST_RECEIVED' | translate}}</h1>
    <h2 *ngIf="!this.bookingDef.bookingConfirmationDefinition.requireManualApproval">{{'BOOKING_CONFIRMATION' | translate}}</h2>
    <p *ngIf="this.bookingDef.bookingConfirmationDefinition.requireManualApproval"> {{'SUCCESSFULLY_REQUESTED' | translate}} {{slotDto.title}} {{'AT_A_COST_OF' | translate}} {{slotDto.cost|currency:currencyCode:'symbol'}}. </p>
    <p *ngIf="!this.bookingDef.bookingConfirmationDefinition.requireManualApproval"> {{'SUCCESSFULLY_BOOKED' | translate}} {{slotDto.title}} {{'AT_A_COST_OF' | translate}} {{slotDto.cost|currency:currencyCode:'symbol'}}. </p>
    <p *ngIf="this.bookingDef.bookingConfirmationDefinition.requireManualApproval"> {{'REQUESTED_BOOKING' | translate}} {{slotDto.startSlot | localizedDate: 'medium'}}</p>
    <p *ngIf="!this.bookingDef.bookingConfirmationDefinition.requireManualApproval"> {{'BOOKING_IS_ON' | translate}} {{slotDto.startSlot | localizedDate: 'medium'}}</p>

    <button mat-raised-button (click)="bookAgain()">Book Again</button>
</div>
