<ng-container *ngIf="contracts && contracts.length > 0; else noContracts">

  <div fxLayout="row wrap" *ngIf="deviceService.isMobile() || deviceService.isTablet()" class="search-results" infiniteScroll [infiniteScrollDistance]="scrollDistance" [infiniteScrollUpDistance]="scrollUpDistance" [scrollWindow]="false" [infiniteScrollThrottle]="throttle" (scrolled)="onScrollDown($event)">
    <!-- mat-card repeat -->
    <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="33" *ngFor="let row of contracts">
      <mat-card appearance="outlined" class="mdc-mb-1">
        <mat-card-header>
          <div fxLayout="row wrap" class="w-100">
            <div fxFlex>
              <mat-card-title>{{ row.customerName }}</mat-card-title>
              <mat-card-subtitle>Customer</mat-card-subtitle>
            </div>
            <div fxFlex="nogrow">
              <div *ngIf="!row.contract?.dateClientSigned" class="label unsigned">{{'UNSIGNED' | translate}}</div>
              <div *ngIf="row.contract.dateClientSigned" class="label signed">{{'SIGNED' | translate}}</div>
            </div>
          </div>
        </mat-card-header>
        <mat-card-content>
          <h6 class="mdc-mb-2">
            <span class="mat-text-secondary fw-normal mb-0">Contract:</span> {{ row.templateName }}
            <span *ngIf="row.contract?.dateClientSigned" class="mat-text-secondary fw-normal mb-0">sent on</span> {{
            row.contract.dateClientSigned |
            localizedDate:
            'mediumDate'}}
          </h6>
          <button *ngIf="!row?.contract?.dateClientSigned" mat-raised-button color="primary" (click)="resendContract(row.contract)"><mat-icon>alarm</mat-icon> {{'RESEND' | translate}}</button>
          <button mat-raised-button color="accent" class="ml-1" (click)="viewContract(row.contract)"><mat-icon>visibility</mat-icon> {{'VIEW' | translate:
            {Default: 'View'} }}</button>
          <button mat-button color="accent" class="ml-1" (click)="editContract(row.contract)"><mat-icon>edit</mat-icon>
            {{'EDIT' | translate: {Default: 'Edit'} }}</button>
        </mat-card-content>
      </mat-card>
    </div>
    <!-- mat-card repeat ends -->
  </div>

  <ngx-datatable *ngIf="!deviceService.isMobile() && !deviceService.isTablet()" class='material mx-0 mb-0' [rows]='contracts' [columnMode]="'force'" [headerHeight]="50" [rowHeight]="'auto'" [footerHeight]="50" [externalPaging]="true" [count]="page.totalElements" [offset]="page.pageNumber" [limit]="page.size" (page)='setPage($event)'>


    <ngx-datatable-column name="{{'CUSTOMER' | translate: {Default: 'Customer'} }}" [sortable]="false">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
        {{ row.customerName }}
      </ng-template>
    </ngx-datatable-column>


    <ngx-datatable-column name="{{'CONTRACT_DETAILS' | translate: {Default: 'Contract Details'} }}" [sortable]="false">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
        <p class="mb-0">
          {{ row.templateName }}
          <br>
          <span class="mat-text-secondary small">{{ row.contract.dateCreated | localizedDate: 'mediumDate' }}</span>
        </p>
      </ng-template>
    </ngx-datatable-column>


    <ngx-datatable-column name="{{'CONTRACT_STATUS' | translate:  {Default: 'Contract Status'} }}" [sortable]="false">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">

        <ng-container *ngIf="row.contract.dateClientSigned; else contractUnsigned">
          <div fxLayout="row" fxLayoutAlign=" center">
            <div fxFlex="nogrow">
              <div class="label signed">{{'SIGNED' | translate}}</div>
            </div>
            <div fxFlex class="pl-1">
              <p class="mb-0">
                {{'SIGNED_FROM_URL' | translate}}
                <br>
                <span class="mat-text-secondary small">on {{ row.contract.dateClientSigned | localizedDate:
                  'mediumDate'}}</span>
              </p>
            </div>
          </div>
        </ng-container>

        <ng-template #contractUnsigned>
          <div class="label unsigned">{{'UNSIGNED' | translate}}</div>
          <button mat-button color="primary" (click)="resendContract(row.contract)">
            <mat-icon>alarm</mat-icon>
            {{'RESEND' | translate}}
          </button>
        </ng-template>

      </ng-template>
    </ngx-datatable-column>


    <ngx-datatable-column name="{{'ACTION' | translate: {Default: 'Action'} }}" [sortable]="false">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">

        <button mat-button color="primary" (click)="viewContract(row.contract)"><mat-icon>visibility</mat-icon> {{'VIEW' | translate: {Default: 'View'} }} </button>
        <button mat-button color="accent" (click)="editContract(row.contract)"><mat-icon>edit</mat-icon> {{'EDIT' |translate: {Default: 'Edit'} }} </button>

      </ng-template>
    </ngx-datatable-column>

  </ngx-datatable>
</ng-container>

<ng-template #noContracts class="flexbox-parent">
  <div fxLayout="row" fxLayoutAlign="center center" style="height: 30vh;  ">
    <div style="text-align:center;">
      <div class="avatar avatar-100 circle border-3 mb-2 d-inline-block mt-15px">
        <mat-icon class="h1 h-auto w-auto">checklist</mat-icon>
      </div>
      <h5 class="ma-0" *ngIf="unsigned">{{"NO_UNSIGNED_CONTRACTS" | translate: {Default:'You don\'t have any Unsigned Contracts'} }}</h5><br>
      <h5 class="ma-0" *ngIf="!unsigned">{{"DONT_CONTRACTS" | translate: {Default:'You don\'t have any Contracts'} }}
      </h5><br>
    </div>
  </div>
</ng-template>