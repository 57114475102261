<!-- <mat-toolbar color="primary">
  <span>{{loadWorkflowDefinitionForEditResponse?.name}}</span>
  <span class="toolbar-spacer"></span>
  <button *ngIf="allowAddState" mat-raised-button #tooltip="matTooltip"
    matTooltip="{{'ADD' | translate}} {{ workflowTypeLabel }}" (click)="addStateBtnClick($event)">{{'ADD' | translate}}
    {{ workflowTypeLabel }}
  </button>
  <button *ngIf="allowAddState" mat-raised-button (click)="backToList()" class="ml-1">
    {{'BACK' | translate}}
  </button>
</mat-toolbar> -->
<mat-sidenav-container>
  <mat-sidenav #sidePanel position="end" mode="over" opened="false" style="width: 90%">
    <app-add-edit-workflow-message *ngIf="selectedTaskDef" (taskRemoved)="taskRemoved()"
      (taskAdded)="loadWorkflowState()" (taskUpdated)="loadWorkflowState()" [contextIdDto]="contextIdDto"
      [taskDef]="selectedTaskDef" [workflowState]="selectedWorkflowState"
      [workflowDefinitionSummaries]="response?.workflowDefinitionSummaryDtos"
      [workflowDefinitionId]="workflowDefinitionId"></app-add-edit-workflow-message>
  </mat-sidenav>

  <mat-sidenav-content class="mdc-p-2">

    <div cdkDropList (cdkDropListDropped)="drop($event)">
      <!-- cdkDrag [cdkDragData]="stateDetails" -->
      <mat-card class="mdc-mb-2"
        *ngFor="let stateDetails of loadWorkflowDefinitionForEditResponse?.loadWorkflowStateResponseList">
        <mat-card-content
          [ngStyle]="{'background-color': stateDetails.workflowState.colour, 'color': colorInversionByHexString(stateDetails.workflowState.colour)}"
          style="border-radius:4px">
          <div fxLayout="row wrap" fxLayoutAlign=" center" class="mdc-mb-1">
            <span *ngIf="!stateDetails.workflowState.colour" fxFlex>
              <h6>{{stateDetails.workflowState.state}}</h6>
            </span>
            <span *ngIf="stateDetails.workflowState.colour" fxFlex
              [ngStyle]="{'color': colorInversionByHexString(stateDetails.workflowState.colour)}">
              <h6> {{stateDetails.workflowState.state}}</h6>
            </span>

            <button mat-mini-fab color="accent" *ngIf="allowEditState" class="mr-xs" aria-label="Edit Message"
              #tooltip="matTooltip" matTooltip="{{'EDIT_MESSAGE' | translate}}" (click)="editState(stateDetails)">
              <mat-icon>edit</mat-icon>
            </button>

            <button mat-mini-fab [matMenuTriggerFor]="addStateMenu" color="primary" class="mr-xs"
              aria-label="Add Message" #tooltip="matTooltip" matTooltip="{{'ADD_MESSAGE' | translate}}">
              <mat-icon>add</mat-icon>
            </button>
            <mat-menu #addStateMenu="matMenu">
              <button mat-menu-item (click)="addTaskToStateClick(stateDetails, 'SEND_MESSAGE')">
                <mat-icon>email</mat-icon>
                <span>{{'SEND_EMAIL' | translate}}</span>
              </button>
              <button mat-menu-item (click)="addTaskToStateClick(stateDetails, 'SEND_SMS')">
                <mat-icon>sms</mat-icon>
                <span>{{'SEND_SMS' | translate}}</span>
              </button>
            </mat-menu>
          </div>

          <mat-list class="mdc-p-0">
            <mat-list-item *ngFor="let taskDef of stateDetails.workflowTaskDefinitions"
              style="background-color:#ffffff">
              <div matListItemTitle *ngIf="taskDef?.taskSettings?.taskName"
                (click)="onTaskSelect(taskDef, stateDetails)">
                {{taskDef?.taskSettings?.taskName}}
              </div>
              <div matListItemLine *ngIf="!taskDef?.taskSettings?.taskName"
                (click)="onTaskSelect(taskDef, stateDetails)">
                {{'UNKNOWN' | translate}}
              </div>
            </mat-list-item>
          </mat-list>
        </mat-card-content>
      </mat-card>

    </div>

  </mat-sidenav-content>
</mat-sidenav-container>
