import {Observable, of as observableOf} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {UiAuthService} from '../../auth/ui-auth.service';
import {ConfService} from '../../config/conf.service';
import {ContextIdDto, ElementInstanceDto, FieldDefinitionDto} from '@savvy/entity-instance-composite';

@Component({
    selector: 'app-file-upload',
    templateUrl: 'fileUpload.component.html'
})
export class FileUploadComponent {

    @Input() fieldDefinition: FieldDefinitionDto;
    @Input() elementInstanceDto: ElementInstanceDto;
    @Input() entityInstanceId: string;
    @Input() contextIdDto: ContextIdDto;
    @Input() form: UntypedFormGroup;
    @Input() showPlaceholder: boolean;

    fileToUpload: File = null;

    constructor(private httpClient: HttpClient,
                private authService: UiAuthService,
                ) {
    }

    handleFileInput(files: FileList) {
        this.fileToUpload = files.item(0);
        this.uploadFileToActivity();
    }

    uploadFileToActivity() {
        this.postFile(this.fileToUpload).subscribe(data => {
            // do something, if upload success
        }, error => {
            console.log(error);
        });
    }

    postFile(fileToUpload: File): Observable<boolean> {
        const endpoint = this.getUploadUrl();
        const formData: FormData = new FormData();
        formData.append('file', fileToUpload, fileToUpload.name);
        // formData.append('fileKey', fileToUpload, fileToUpload.name);

        const authValue = this.authService.getAuthorizationHeader();
        const headers = new HttpHeaders().set('Authorization', authValue);
        // headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.httpClient
            .post(endpoint, formData, {headers}).pipe(
            map(() => true),
            catchError((e) => this.handleError(e)) );
        // return Observable.of(false);
    }

    getUploadUrl() {
        console.log('this.contextIdDto is ' + this.contextIdDto);
        return ConfService.apiUrl() + '/rest/ui/uploadFile?contextId=' +
            this.contextIdDto.contextId + '&contextIdType=' +
            this.contextIdDto.contextIdType + '&entityInstanceId=' +
            this.entityInstanceId + '&fieldInstanceId=' +
            this.elementInstanceDto.instanceId;
    }

    private handleError(e: any): Observable<boolean> {
        console.log('error ' + e);
        return observableOf(false);
    }
}
