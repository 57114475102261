/* eslint-disable max-len */
import { HTTP_INTERCEPTORS, HttpClient, HttpClientJsonpModule, HttpClientModule, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { BidiModule } from '@angular/cdk/bidi';
import { CdkMenuModule } from '@angular/cdk/menu';
import { FlexLayoutModule } from '@angular/flex-layout';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { NgWizardConfig, NgWizardModule, THEME } from 'ng-wizard-v2';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';

import { AuthHttpInterceptor } from '@auth0/auth0-angular';
@Injectable()
export class AggregateInterceptor implements HttpInterceptor {

  constructor(
    private auth0Ceptor: AuthHttpInterceptor,
    private router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.auth0Ceptor.intercept(request, next).pipe(
      catchError((error: HttpErrorResponse) => {
        // Handle the error here
        console.error('error occurred:', error);
        //throw error as per requirement
        if (error instanceof HttpErrorResponse && error.status === 0) {
          this.offline();
        }
        const err = new Error(error?.message);
        return throwError(() => err);
      })
    );
  }

  offline() {
    window['wentOffline'] = true;
    this.router.navigate(['/no-internet']);
  }
}

const ngWizardConfig: NgWizardConfig = {
  theme: THEME.default
};
declare let hammer: any;
// to override hammerjs touchAction none to pan-y
@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement) {
    const mc = new hammer(element, {
      // touchAction: 'auto'
      touchAction: 'pan-y'
    });
    return mc;
  }
}

export class DynamicLocaleId extends String {
  constructor(protected service: TranslateService) {
    super('');
  }

  toString() {
    return this.service.currentLang || 'en-us';
  }
}

import {
  AccordionAnchorDirective,
  AccordionDirective,
  AccordionLinkDirective,
  AdminLayoutComponent,
  AuthLayoutComponent,
  OptionsComponent
} from './core';

import { AuthGuard } from '@auth0/auth0-angular';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routing';
import { AddEditDatalistComponent } from './flo/datalist/addEditDatalist.component';
import { FloMaterialModule } from './flo/flo.material.module';
import { CustomerLayoutComponent } from './flo/layout/customer-layout/customer-layout.component';
import { StandardLayoutComponent } from './flo/layout/standard-layout/standard-layout.component';
import { FloLogoComponent } from './flo/logo/flologo.component';
import { SearchComponent } from './flo/search/search.component';
import { FloSnackbarComponent } from './flo/snackbar/floSnackbar.component';

import { CalendarSettingsService } from '@savvy/calendar-settings';
import { DatalistService } from '@savvy/datalist';
import { DashboarddefsService } from '@savvy/entity-definition';
import { MenuService as MenuServicenpm, MenucompService } from '@savvy/menu';
import { NotificationService } from '@savvy/notification';
import { SearchService } from '@savvy/search';
import { StorageService } from '@savvy/storage';
import { UiService } from '@savvy/ui';
import { UsersService } from '@savvy/user';
import { UiAuthService } from './flo/auth/ui-auth.service';
import { BreadcrumbsService } from './flo/breadcrumb/breadcrumbs.service';
import { ConfService } from './flo/config/conf.service';
import { ContextService } from './flo/context.service';
import { CountryService } from './flo/country/country.service';
import { DateUtils } from './flo/dates/DateUtils';
import { TimeUtils } from './flo/dates/TimeUtils';
import { LayoutBusService } from './flo/element/LayoutBusService';
import { NavChangeService } from './flo/element/NavChangeService';
import { EventHandlerService } from './flo/entry/EventHandlerService';
import { NavigateService } from './flo/entry/NavigateService';
import { CustomerLayoutHeaderComponent } from './flo/layout/customer-layout/customer-layout-header.component';
import { CustomerLayoutNoMenuComponent } from './flo/layout/customer-layout/customer-layout-no-menu.component';
import { HeaderComponent } from './flo/layout/header/header.component';
import { SidebarComponent } from './flo/layout/sidebar/sidebar.component';
import { BROWSER_FAVICONS_CONFIG, BrowserFavicons, Favicons } from './flo/logo/browserFavicon.component';
import { FaviconService } from './flo/logo/favicon.service';
import { IconService } from './flo/logo/icon.service';
import { MenuComponent } from './flo/menu/menu.component';
import { MenuFactory } from './flo/menu/menu.factory';
import { NotificationsComponent } from './flo/notifications/notifications.component';
import { ParamsService } from './flo/params.service';
import { SnackBarService } from './flo/shared/services/snackbar.service';
import { ThemeService } from './flo/shared/services/theme.service';
import { TrialExpiredService } from './flo/shared/services/trial-expired.service';
import { SharedModule } from './flo/shared/shared.module';
import { StyleService } from './flo/style/style.service';

import { LanguageService } from './services/language.service';
// import { ReferralscompService } from '@savvy/subscription';
// import { ReferralsService } from '@savvy/subscription';
import { ArchwizardModule } from '@viso-trust/angular-archwizard';

import { MatBadgeModule } from '@angular/material/badge';
import { MatDialogModule } from '@angular/material/dialog';
import { Configuration as AppEnvConfiguration, ApiModule as AppEnvModule } from '@savvy/app';
import { Configuration as AppointmentConfiguration, ApiModule as AppointmentModule } from '@savvy/appointment';
import { Configuration as CalendarConfiguration, ApiModule as CalendarModule } from '@savvy/calendar';
import { Configuration as CalendarSettingsConfiguration, ApiModule as CalendarSettingsModule } from '@savvy/calendar-settings';
import { Configuration as ChatConfiguration, ApiModule as ChatModule } from '@savvy/chat';
import { Configuration as ContractConfiguration, ApiModule as ContractModule } from '@savvy/contract';
import { Configuration as CustomerConfiguration, ApiModule as CustomerModule } from '@savvy/customer';
import { Configuration as DatalistConfiguration, ApiModule as DatalistModule } from '@savvy/datalist';
import { Configuration as DayAvailabilityConfiguration, ApiModule as DayAvailabilityModule } from '@savvy/day-availability';
import { Configuration as EmailSettingsConfiguration, ApiModule as EmailSettingsModule } from '@savvy/email-settings';
import { Configuration as EntityDefConfiguration, ApiModule as EntityDefModule } from '@savvy/entity-definition';
import { Configuration as ImportConfiguration, ApiModule as ImportModule } from '@savvy/import';
import { Configuration as InvoiceReportConfiguration, ApiModule as InvoiceReportModule } from '@savvy/invoice-report';
import { Configuration as MenuConfiguration, ApiModule as MenuModule } from '@savvy/menu';
import { MessagecompService } from '@savvy/messaging';
import { OrgCompositeService, Configuration as OrgConfiguration, ApiModule as OrgModule } from '@savvy/org';
import { PaymentService } from '@savvy/payment';
import { Configuration as ProductMetadataConfiguration, ApiModule as ProductMetadataModule } from '@savvy/product-metadata';
import { Configuration as ProductConfiguration, ApiModule as ProductModule } from '@savvy/products';
import { SmsBundleService } from '@savvy/sms-bundle';
import { SmsSettingsService } from '@savvy/sms-settings';
import { Configuration as TaxConfiguration, ApiModule as TaxModule } from '@savvy/tax';
import { Configuration as UserCalendarLocationsConfiguration, ApiModule as UserCalendarLocationsModule } from '@savvy/user-calendar-locations';
import { UserCalendarSettingsService } from '@savvy/user-calendar-settings';
import { ClipboardModule } from 'ngx-clipboard';
import { environment } from '../environments/environment';
import { ImageService } from './flo/element/image.service';
import { HelpService } from './flo/help/help.service';
import { SmsComponent } from './flo/layout/header/sms.component';
import { ViewNotificationDialogComponent } from './flo/notifications/viewNotificationDialog.component';
import { LinkIdService } from './flo/shared/services/linkId.service';
import { SmsCreditStateService } from './flo/shared/services/sms-credit-state.service';
import { SmsCreditService } from './flo/shared/services/sms-credit.service';
import { TrialExpiredStateService } from './flo/shared/services/trial-expired-state.service';
import { SocialComponent } from './flo/social/social.component';
import { LookAndFeelLocalService } from './services/lookAndFeel.service';
import { PortalSettingsLocalService } from './services/portalSettingsLocal.service';

import { AuthModule } from '@auth0/auth0-angular';
import { Configuration as AddOnsConfiguration, ApiModule as AddOnsModule } from '@savvy/add-ons';
import { Configuration as AddressConfiguration, ApiModule as AddressModule } from '@savvy/address';
import { Configuration as AppMetadataConfiguration, ApiModule as AppMetadataModule } from '@savvy/app-metadata';
import { Configuration as CustomerOnboardingConfiguration, ApiModule as CustomerOnboardingModule } from '@savvy/customer-onboarding';
import { Configuration as LookAndFeelConfiguration, ApiModule as LookAndFeelModule } from '@savvy/look-and-feel';
import { Configuration as NotificationConfiguration, ApiModule as NotificationModule } from '@savvy/notification';
import { Configuration as ReferralConfiguration, ApiModule as ReferralModule } from '@savvy/referral';
import { Configuration as RegistrationConfiguration, ApiModule as RegistrationModule } from '@savvy/registration';
import { Configuration as ResourcesConfiguration, ApiModule as ResourcesModule } from '@savvy/resources';
import { Configuration as RoleConfiguration, ApiModule as RoleModule } from '@savvy/role';
import { Configuration as SavvyPayConfiguration, ApiModule as SavvyPayModule } from '@savvy/savvy-pay';
import { Configuration as SavvyPay2Configuration, ApiModule as SavvyPay2Module } from '@savvy/savvy-pay2';
import { Configuration as SearchConfiguration, ApiModule as SearchModule } from '@savvy/search';
import { Configuration as SeriesConfiguration, ApiModule as SeriesModule } from '@savvy/series';
import { Configuration as SmsSettingsConfiguration, ApiModule as SmsSettingsModule } from '@savvy/sms-settings';
import { Configuration as SubscriptionConfiguration, ApiModule as SubscriptionModule } from '@savvy/subscription';
import { Configuration as UiConfiguration, ApiModule as UiModule } from '@savvy/ui';
import { Configuration as UserConfiguration, ApiModule as UserModule } from '@savvy/user';
import { Configuration as UserAvailabilityConfiguration, ApiModule as UserAvailabilityModule } from '@savvy/user-availability';
import { Configuration as UserLocationConfiguration, ApiModule as UserLocationModule } from '@savvy/user-location';
import { Configuration as ViewCompositeConfiguration, ApiModule as ViewCompositeModule, ViewcompService } from '@savvy/view-composite';
import { Configuration as ViewDefConfiguration, ApiModule as ViewDefModule } from '@savvy/view-definition';

import { Configuration as BrandingConfiguration, ApiModule as BrandingModule } from '@savvy/branding';
import { Configuration as CachedViewConfiguration, ApiModule as CachedViewModule } from '@savvy/cached-view';
import { Configuration as ChatflowConfiguration, ApiModule as ChatflowModule } from '@savvy/chatflow';
import { Configuration as CheckInFormConfiguration, ApiModule as CheckInFormModule } from '@savvy/check-in-form';
import { Configuration as CustomerPortalConfiguration, ApiModule as CustomerPortalModule } from '@savvy/customer-portal';
import { Configuration as CustomerPortalSettingsConfiguration, ApiModule as CustomerPortalSettingsModule } from '@savvy/customer-portal-settings';



import { Configuration as ClockInConfiguration, ApiModule as ClockInModule } from '@savvy/clock-in';
import { Configuration as ContactFormConfiguration, ApiModule as ContactFormModule } from '@savvy/contact-form';
import { Configuration as CustomerLastSeenConfiguration, ApiModule as CustomerLastSeenModule } from '@savvy/customer-lastseen';
import { Configuration as DirectoryEntryConfiguration, ApiModule as DirectoryEntryModule } from '@savvy/directory-entry';

import { Configuration as BookingConfiguration, ApiModule as BookingModule } from '@savvy/booking';
import { Configuration as BookingDefinitionConfiguration, ApiModule as BookingDefinitionModule } from '@savvy/booking-definition';
import { Configuration as EmailTemplateConfiguration, ApiModule as EmailTemplateModule } from '@savvy/email-template';
import { Configuration as EntityInstanceConfiguration, ApiModule as EntityInstanceModule } from '@savvy/entity-instance';
import { Configuration as EntityInstanceCompositeConfiguration, ApiModule as EntityInstanceCompositeModule } from '@savvy/entity-instance-composite';
import { Configuration as ExpenseConfiguration, ApiModule as ExpenseModule } from '@savvy/expense';
import { Configuration as FlagsConfiguration, ApiModule as FlagsModule } from '@savvy/flags';
import { Configuration as GoogleCalendarConfiguration, ApiModule as GoogleCalendarModule } from '@savvy/google-calendar';
import { Configuration as ICalConfiguration, ApiModule as ICalModule } from '@savvy/ical';
import { Configuration as InvoiceConfiguration, ApiModule as InvoiceModule } from '@savvy/invoice';
import { Configuration as LocationConfiguration, ApiModule as LocationModule } from '@savvy/location';
import { Configuration as MessagingConfiguration, ApiModule as MessagingModule } from '@savvy/messaging';
import { Configuration as PaymentConfiguration, ApiModule as PaymentModule } from '@savvy/payment';
import { Configuration as PetConfiguration, ApiModule as PetModule } from '@savvy/pet';
import { Configuration as PetStayConfiguration, ApiModule as PetStayModule } from '@savvy/pet-stay';
import { Configuration as PlanConfiguration, ApiModule as PlanModule } from '@savvy/plan';
import { Configuration as PlanDefinitionConfiguration, ApiModule as PlanDefinitionModule } from '@savvy/plan-definition';
import { Configuration as QuestionnaireConfiguration, ApiModule as QuestionnaireModule } from '@savvy/questionnaire';
import { Configuration as RemindersConfiguration, ApiModule as RemindersModule } from '@savvy/reminders';
import { Configuration as ReportsConfiguration, ApiModule as ReportsModule } from '@savvy/reports';
import { Configuration as RequestForPaymentConfiguration, ApiModule as RequestForPaymentModule } from '@savvy/request-for-payment';
import { Configuration as ServicesConfiguration, ApiModule as ServicesModule } from '@savvy/services';
import { Configuration as ShopConfiguration, ApiModule as ShopModule } from '@savvy/shop';

import { ApiModule as AccommodationApiModule, Configuration as AccommodationConfiguration } from '@savvy/accommodation';
import { Configuration as SimpleBookingConfiguration, ApiModule as SimpleBookingModule } from '@savvy/booking';
import { Configuration as CourseConfiguration, ApiModule as CourseModule } from '@savvy/course';
import { Configuration as GalleryConfiguration, ApiModule as GalleryModule } from '@savvy/gallery';
import { Configuration as HolidayConfiguration, ApiModule as HolidayModule } from '@savvy/holiday';
import { Configuration as LandingPageConfiguration, ApiModule as LandingPageModule } from '@savvy/landing-pages';
import { Configuration as LeadConfiguration, ApiModule as LeadModule } from '@savvy/leads';
import { Configuration as MeetingConfiguration, ApiModule as MeetingModule } from '@savvy/meeting';
import { Configuration as NewsletterConfiguration, ApiModule as NewsletterModule } from '@savvy/newsletters';
import { Configuration as OrderConfiguration, ApiModule as OrderModule } from '@savvy/order';
import { Configuration as QBConfiguration, ApiModule as QBModule } from '@savvy/quickbooks';
import { Configuration as ResourceConfiguration, ApiModule as ResourceModule } from '@savvy/resources';
import { Configuration as SendToAllConfiguration, ApiModule as SendToAllModule } from '@savvy/send-to-all';
import { Configuration as SlotManagementConfiguration, ApiModule as SlotManagementModule } from '@savvy/slot-management';
import { Configuration as SmsBundleConfiguration, ApiModule as SmsBundleModule } from '@savvy/sms-bundle';
import { Configuration as StaffRotaConfiguration, ApiModule as StaffRotaModule } from '@savvy/staff-rota';
import { Configuration as StorageConfiguration, ApiModule as StorageModule } from '@savvy/storage';
import { Configuration as StudentConfiguration, ApiModule as StudentModule } from '@savvy/student';
import { Configuration as StudentCourseConfiguration, ApiModule as StudentCourseModule } from '@savvy/student-course';
import { Configuration as TaskConfiguration, ApiModule as TaskModule } from '@savvy/task';
import { Configuration as TemplateEngineConfiguration, ApiModule as TemplateEngineModule } from '@savvy/template-engine';
import { Configuration as TermsAndConditionsConfiguration, ApiModule as TermsAndConditionsModule } from '@savvy/terms-and-conditions';
import { Configuration as TipsConfiguration, ApiModule as TipsModule } from '@savvy/tips';
import { Configuration as TrainingEventConfiguration, ApiModule as TrainingEventModule } from '@savvy/training-event';
import { Configuration as UserCalendarSettingsConfiguration, ApiModule as UserCalendarSettingsModule } from '@savvy/user-calendar-settings';
import { Configuration as UserServiceMatrixConfiguration, ApiModule as UserServiceMatrixModule } from '@savvy/user-service-matrix';
import { Configuration as VaccinationConfiguration, ApiModule as VaccinationModule } from '@savvy/vaccination';
import { Configuration as WaitingListConfiguration, ApiModule as WaitingListModule } from '@savvy/waiting-list';
import { Configuration as WalkersConfiguration, ApiModule as WalkersModule } from '@savvy/walkers';
import { Configuration as WebhookConfiguration, ApiModule as WebhookModule } from '@savvy/webhook';
import { Configuration as WebsiteConfiguration, ApiModule as WebsiteModule } from '@savvy/website';
import { Configuration as WizardConfiguration, ApiModule as WizardModule } from '@savvy/wizard-prefs';
import { Configuration as WorkflowInstanceConfiguration, ApiModule as WorkflowInstanceModule } from '@savvy/workflow-instance';
import { Configuration as WorkingHoursConfiguration, ApiModule as WorkingHoursModule } from '@savvy/working-hours';
import { Configuration as XeroConfiguration, ApiModule as XeroModule } from '@savvy/xero';
import { LocalMenuService } from './flo/menu/local-menu.service';
// import { SessionModule } from './flo/session/session.module';
import { SimpleBookingPublicModule } from './flo/simplebooking/simplebookingpub.module';
import { WebsitePublicModule } from './flo/website/websitepub.module';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Configuration as ExportConfiguration, ApiModule as ExportServiceModule } from '@savvy/export';
import { Configuration as PackagesConfiguration, ApiModule as SavvyPackagesModule } from '@savvy/packages';
import { PlanDefService } from '@savvy/plan-definition';
import { ApiModule as QuoteApiModule, Configuration as QuoteConfiguration } from '@savvy/quote';
import { Configuration as WorkflowDefinitionConfiguration, ApiModule as WorkflowDefinitionModule } from '@savvy/workflow-definition';

import { AngularSplitModule } from 'angular-split';
import { AppsMenuComponent } from './core/apps-menu/apps-menu.component';
import { DirectivesModule } from './core/directives/directives.module';
import { SafePipeModule } from './core/safeHtml.pipe';
import { AppointmentsV2Module } from './flo/appointments-v2/appointments-v2.module';
import { CustomerSignupFormsModule } from './flo/customerSignup/customerSignupForms.module';
import { EventBusService } from './flo/element/EventBusService';
import { AppImagesModule } from './flo/images/images.module';
import { AngularVersionUpdateBannerComponent } from './flo/layout/header/angular-version-update-banner/angular-version-update-banner.component';
import { AppVersionUpdateBannerComponent } from './flo/layout/header/app-version-update-banner/app-version-update-banner.component';
import { NotificationSettingsDialogComponent } from './flo/notifications/notification-settings/notification-settings-dialog/notification-settings-dialog.component';
import { NotificationSettingsComponent } from './flo/notifications/notification-settings/notification-settings.component';
import { NotificationsPanelComponent } from './flo/notifications/notificationsPanel.component';
import { UserNotificationSettingsDialogComponent } from './flo/notifications/user-notification-settings/user-notification-settings-dialog/user-notification-settings-dialog.component';
import { UserNotificationSettingsComponent } from './flo/notifications/user-notification-settings/user-notification-settings.component';
import { UpdateSubscriptionInTrialModalComponent } from './flo/shared/components/subscription/update-subscription-in-trial-modal/update-subscription-in-trial-modal.component';
import { ProductMetaLocalService } from './services/productMeta.service';


init({
  environment: environment.production ? 'production' : 'develop',
  dsn: 'https://516c931265ca40e18be56d3d1acd28fd@o195790.ingest.sentry.io/1301608',
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    browserTracingIntegration(),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    replayIntegration(),
  ],
  tracePropagationTargets: ['https://api.itsallsavvy.com'],
  release: environment.VERSION,
  ignoreErrors: [
    'Non-Error exception captured',
    'ResizeObserver loop completed with undelivered notifications.'
  ],
  attachStacktrace: environment.production ? true : false,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

// ng6 social buttons Configs
// export const getAuthServiceConfigs = () => {
//   const socialServiceConfig = new SocialServiceConfig()
//     .addFacebook('645427932581008');
//   // .addGoogle("Your-Google-Client-Id")
//   // .addLinkedIn("Your-LinkedIn-Client-Id");
//   return socialServiceConfig;
// };

import { MAT_DATE_LOCALE } from '@angular/material/core';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { NgxPhotoEditorModule } from 'ngx-photo-editor';
import { TimeToDateModule } from './core/time-to-date.pipe';
// import { Auth0CallbackComponent } from "./flo/callback/callback.component";
import { TraceService, browserTracingIntegration, init, replayIntegration } from '@sentry/angular-ivy';
import { CookieService } from "ngx-cookie-service";
import { Observable, catchError, throwError } from 'rxjs';
import { EditRetailSaleComponent } from './flo/edit-retail-sale/edit-retail-sale.component';
import { GlobalErrorHandler } from './flo/error/GlobalErrorHandler';
import { HelpModalComponent } from './flo/help/help-modal/help-modal.component';
import { UsedSmsComponent } from './flo/layout/header/usedSms.component';
import { RetailSalesComponent } from './flo/retail-sales/retail-sales.component';
import { NoInternetComponent } from './flo/session/no-internet/no-internet.component';
import { SigninComponent } from './flo/session/signin/signin.component';
import { UserProfileAuth0Component } from "./flo/session/signin/user-profile";
import { SignoutComponent } from './flo/session/signout/signout.component';
import { TipOfTheDayModalComponent } from "./flo/tip-of-the-day-modal/tipOfTheDayModal.component";
import { UserRatingModalComponent } from './flo/user-rating-modal/user-rating-modal.component';
import { AppWebhookModule } from "./flo/webhooks/webhooks.module";
import { FlagDefHelperService } from "./services/flagDefHelper.service";
import { LocaleService } from "./services/locale.service";

declare var window: any;

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    if (params.interpolateParams) {
      return params.interpolateParams["Default"] || params.key;
    }
    return params.key;
  }
}

export const httpLoaderFactory = (httpClient: HttpClient) => new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');



@NgModule({
  declarations: [
    AppComponent,
    AppsMenuComponent,
    AngularVersionUpdateBannerComponent,
    AppVersionUpdateBannerComponent,
    HeaderComponent,
    SidebarComponent,
    OptionsComponent,
    MenuComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    // START CORE PRIMER
    FloLogoComponent,
    StandardLayoutComponent,
    AuthLayoutComponent,
    CustomerLayoutComponent,
    CustomerLayoutNoMenuComponent,
    CustomerLayoutHeaderComponent,
    SidebarComponent,
    OptionsComponent,
    HeaderComponent,
    SmsComponent,
    // Auth0CallbackComponent,
    UserProfileAuth0Component,
    UsedSmsComponent,
    MenuComponent,
    FloSnackbarComponent,
    SocialComponent,
    // ManifestComponent,
    // END CORE PRIMER
    AddEditDatalistComponent,
    NotificationsComponent,
    NotificationsPanelComponent,
    ViewNotificationDialogComponent,
    NotificationSettingsDialogComponent,
    UserNotificationSettingsDialogComponent,
    UserNotificationSettingsComponent,
    NotificationSettingsComponent,
    SearchComponent,
    UpdateSubscriptionInTrialModalComponent,
    UserRatingModalComponent,
    HelpModalComponent,
    TipOfTheDayModalComponent,
    RetailSalesComponent,
    EditRetailSaleComponent,
    SigninComponent,
    SignoutComponent,
    NoInternetComponent
  ],
  imports: [
    DirectivesModule,
    SafePipeModule,
    AngularSplitModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, {
      enableTracing: false,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 150] // [x, y] - adjust scroll offset

    }),
    GoogleTagManagerModule.forRoot({ id: 'UA-130791039-1' }),
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgxPhotoEditorModule,
    TranslateModule.forRoot({
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler },
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot(),
    NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
    FloMaterialModule,
    AppointmentsV2Module,
    MatBadgeModule,
    CdkMenuModule,
    MatDialogModule,
    MatSidenavModule,
    MatCardModule,
    MatMenuModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatTabsModule,
    MatListModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatBottomSheetModule,
    FlexLayoutModule,
    BidiModule,
    DragDropModule,
    GoogleMapsModule,
    NgScrollbarModule,
    SharedModule,
    ClipboardModule,
    InvoiceReportModule.forRoot(() => new InvoiceReportConfiguration({
      basePath: ConfService.apiUrl()
    })),
    ExportServiceModule.forRoot(() => new ExportConfiguration({
      basePath: ConfService.apiUrl()
    })),
    SavvyPackagesModule.forRoot(() => new PackagesConfiguration({
      basePath: ConfService.apiUrl()
    })),
    AppEnvModule.forRoot(() => new AppEnvConfiguration({
      basePath: ConfService.apiUrl()
    })),
    AppointmentModule.forRoot(() => new AppointmentConfiguration({
      basePath: ConfService.apiUrl()
    })),
    SavvyPayModule.forRoot(() => new SavvyPayConfiguration({
      basePath: ConfService.apiUrl()
    })),
    SavvyPay2Module.forRoot(() => new SavvyPay2Configuration({
      basePath: ConfService.apiUrl()
    })),
    ReferralModule.forRoot(() => new ReferralConfiguration({
      basePath: ConfService.apiUrl()
    })),
    CalendarModule.forRoot(() => new CalendarConfiguration({
      basePath: ConfService.apiUrl()
    })),
    CalendarSettingsModule.forRoot(() => new CalendarSettingsConfiguration({
      basePath: ConfService.apiUrl()
    })),
    ContractModule.forRoot(() => new ContractConfiguration({
      basePath: ConfService.apiUrl()
    })),
    // ApiModule
    CustomerModule.forRoot(() => new CustomerConfiguration({
      basePath: ConfService.apiUrl()
    })),
    ChatModule.forRoot(() => new ChatConfiguration({
      basePath: ConfService.apiUrl()
    })),
    UserCalendarLocationsModule.forRoot(() => new UserCalendarLocationsConfiguration({
      basePath: ConfService.apiUrl()
    })),
    TaxModule.forRoot(() => new TaxConfiguration({
      basePath: ConfService.apiUrl()
    })),
    DatalistModule.forRoot(() => new DatalistConfiguration({
      basePath: ConfService.apiUrl()
    })),
    EntityDefModule.forRoot(() => new EntityDefConfiguration({
      basePath: ConfService.apiUrl()
    })),
    EmailSettingsModule.forRoot(() => new EmailSettingsConfiguration({
      basePath: ConfService.apiUrl()
    })),
    GoogleCalendarModule.forRoot(() => new GoogleCalendarConfiguration({
      basePath: ConfService.apiUrl()
    })),
    ICalModule.forRoot(() => new ICalConfiguration({
      basePath: ConfService.apiUrl()
    })),
    ImportModule.forRoot(() => new ImportConfiguration({
      basePath: ConfService.apiUrl()
    })),
    MenuModule.forRoot(() => new MenuConfiguration({
      basePath: ConfService.apiUrl()
    })),
    OrgModule.forRoot(() => new OrgConfiguration({
      basePath: ConfService.apiUrl()
    })),
    ProductModule.forRoot(() => new ProductConfiguration({
      basePath: ConfService.apiUrl()
    })),
    ProductMetadataModule.forRoot(() => new ProductMetadataConfiguration({
      basePath: ConfService.apiUrl()
    })),
    AppMetadataModule.forRoot(() => new AppMetadataConfiguration({
      basePath: ConfService.apiUrl()
    })),
    RegistrationModule.forRoot(() => new RegistrationConfiguration({
      basePath: ConfService.apiUrl()
    })),
    RoleModule.forRoot(() => new RoleConfiguration({
      basePath: ConfService.apiUrl()
    })),
    ResourcesModule.forRoot(() => new ResourcesConfiguration({
      basePath: ConfService.apiUrl()
    })),
    SearchModule.forRoot(() => new SearchConfiguration({
      basePath: ConfService.apiUrl()
    })),
    SmsSettingsModule.forRoot(() => new SmsSettingsConfiguration({
      basePath: ConfService.apiUrl()
    })),
    SeriesModule.forRoot(() => new SeriesConfiguration({
      basePath: ConfService.apiUrl()
    })),
    CustomerPortalSettingsModule.forRoot(() => new CustomerPortalSettingsConfiguration({
      basePath: ConfService.apiUrl()
    })),
    ChatflowModule.forRoot(() => new ChatflowConfiguration({
      basePath: ConfService.apiUrl()
    })),
    CustomerPortalModule.forRoot(() => new CustomerPortalConfiguration({
      basePath: ConfService.apiUrl()
    })),
    AddOnsModule.forRoot(() => new AddOnsConfiguration({
      basePath: ConfService.apiUrl()
    })),
    CustomerOnboardingModule.forRoot(() => new CustomerOnboardingConfiguration({
      basePath: ConfService.apiUrl()
    })),
    SubscriptionModule.forRoot(() => new SubscriptionConfiguration({
      basePath: ConfService.apiUrl()
    })),
    UiModule.forRoot(() => new UiConfiguration({
      basePath: ConfService.apiUrl()
    })),
    UserModule.forRoot(() => new UserConfiguration({
      basePath: ConfService.apiUrl()
    })),
    UserAvailabilityModule.forRoot(() => new UserAvailabilityConfiguration({
      basePath: ConfService.apiUrl()
    })),
    UserLocationModule.forRoot(() => new UserLocationConfiguration({
      basePath: ConfService.apiUrl()
    })),
    ViewCompositeModule.forRoot(() => new ViewCompositeConfiguration({
      basePath: ConfService.apiUrl()
    })),
    LookAndFeelModule.forRoot(() => new LookAndFeelConfiguration({
      basePath: ConfService.apiUrl()
    })),
    NotificationModule.forRoot(() => new NotificationConfiguration({
      basePath: ConfService.apiUrl()
    })),
    ViewDefModule.forRoot(() => new ViewDefConfiguration({
      basePath: ConfService.apiUrl()
    })),
    AddressModule.forRoot(() => new AddressConfiguration({
      basePath: ConfService.apiUrl()
    })),
    // Import the module into the application, with configuration
    AuthModule.forRoot({
      domain: environment.AUTH0_DOMAIN,
      clientId: environment.AUTH0_CLIENT_ID,
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: `${ConfService.apiUrl()}/`
      },
      // The AuthHttpInterceptor configuration
      httpInterceptor: {
        allowedList: [
          "https://devapi.itsallsavvy.com/rest/*",
          "https://testapi.itsallsavvy.com/rest/*",
          "https://api.itsallsavvy.com/rest/*",
        ]
      },
    }),

    BrandingModule.forRoot(() => new BrandingConfiguration({
      basePath: ConfService.apiUrl()
    })),
    CachedViewModule.forRoot(() => new CachedViewConfiguration({
      basePath: ConfService.apiUrl()
    })),
    CheckInFormModule.forRoot(() => new CheckInFormConfiguration({
      basePath: ConfService.apiUrl()
    })),
    // CustomerSignupFormModule.forRoot(() => new CustomerSignupFormConfiguration({
    //   basePath: ConfService.apiUrl()
    // })),
    ClockInModule.forRoot(() => new ClockInConfiguration({
      basePath: ConfService.apiUrl()
    })),
    DayAvailabilityModule.forRoot(() => new DayAvailabilityConfiguration({
      basePath: ConfService.apiUrl()
    })),
    ContactFormModule.forRoot(() => new ContactFormConfiguration({
      basePath: ConfService.apiUrl()
    })),
    CustomerLastSeenModule.forRoot(() => new CustomerLastSeenConfiguration({
      basePath: ConfService.apiUrl()
    })),
    DirectoryEntryModule.forRoot(() => new DirectoryEntryConfiguration({
      basePath: ConfService.apiUrl()
    })),
    EntityInstanceCompositeModule.forRoot(() => new EntityInstanceCompositeConfiguration({
      basePath: ConfService.apiUrl()
    })),
    EmailTemplateModule.forRoot(() => new EmailTemplateConfiguration({
      basePath: ConfService.apiUrl()
    })),
    EntityInstanceModule.forRoot(() => new EntityInstanceConfiguration({
      basePath: ConfService.apiUrl()
    })),
    FlagsModule.forRoot(() => new FlagsConfiguration({
      basePath: ConfService.apiUrl()
    })),
    InvoiceModule.forRoot(() => new InvoiceConfiguration({
      basePath: ConfService.apiUrl()
    })),
    MessagingModule.forRoot(() => new MessagingConfiguration({
      basePath: ConfService.apiUrl()
    })),
    LocationModule.forRoot(() => new LocationConfiguration({
      basePath: ConfService.apiUrl()
    })),
    PaymentModule.forRoot(() => new PaymentConfiguration({
      basePath: ConfService.apiUrl()
    })),
    PlanModule.forRoot(() => new PlanConfiguration({
      basePath: ConfService.apiUrl()
    })),
    PlanDefinitionModule.forRoot(() => new PlanDefinitionConfiguration({
      basePath: ConfService.apiUrl()
    })),
    RequestForPaymentModule.forRoot(() => new RequestForPaymentConfiguration({
      basePath: ConfService.apiUrl()
    })),
    QuestionnaireModule.forRoot(() => new QuestionnaireConfiguration({
      basePath: ConfService.apiUrl()
    })),
    RemindersModule.forRoot(() => new RemindersConfiguration({
      basePath: ConfService.apiUrl()
    })),
    ReportsModule.forRoot(() => new ReportsConfiguration({
      basePath: ConfService.apiUrl()
    })),
    // RuleDefinitionModule.forRoot(() => new RuleDefinitionConfiguration({
    //   basePath: ConfService.apiUrl()
    // })),
    ServicesModule.forRoot(() => new ServicesConfiguration({
      basePath: ConfService.apiUrl()
    })),
    ShopModule.forRoot(() => new ShopConfiguration({
      basePath: ConfService.apiUrl()
    })),
    BookingModule.forRoot(() => new BookingConfiguration({
      basePath: ConfService.apiUrl()
    })),
    BookingDefinitionModule.forRoot(() => new BookingDefinitionConfiguration({
      basePath: ConfService.apiUrl()
    })),
    PetModule.forRoot(() => new PetConfiguration({
      basePath: ConfService.apiUrl()
    })),
    PetStayModule.forRoot(() => new PetStayConfiguration({
      basePath: ConfService.apiUrl()
    })),
    SlotManagementModule.forRoot(() => new SlotManagementConfiguration({
      basePath: ConfService.apiUrl()
    })),
    SmsBundleModule.forRoot(() => new SmsBundleConfiguration({
      basePath: ConfService.apiUrl()
    })),
    StaffRotaModule.forRoot(() => new StaffRotaConfiguration({
      basePath: ConfService.apiUrl()
    })),
    StorageModule.forRoot(() => new StorageConfiguration({
      basePath: ConfService.apiUrl()
    })),
    SimpleBookingModule.forRoot(() => new SimpleBookingConfiguration({
      basePath: ConfService.apiUrl()
    })),
    CachedViewModule.forRoot(() => new CachedViewConfiguration({
      basePath: ConfService.apiUrl()
    })),
    TaskModule.forRoot(() => new TaskConfiguration({
      basePath: ConfService.apiUrl()
    })),
    TipsModule.forRoot(() => new TipsConfiguration({
      basePath: ConfService.apiUrl()
    })),
    UserCalendarSettingsModule.forRoot(() => new UserCalendarSettingsConfiguration({
      basePath: ConfService.apiUrl()
    })),
    WaitingListModule.forRoot(() => new WaitingListConfiguration({
      basePath: ConfService.apiUrl()
    })),
    UserServiceMatrixModule.forRoot(() => new UserServiceMatrixConfiguration({
      basePath: ConfService.apiUrl()
    })),
    HolidayModule.forRoot(() => new HolidayConfiguration({
      basePath: ConfService.apiUrl()
    })),
    MeetingModule.forRoot(() => new MeetingConfiguration({
      basePath: ConfService.apiUrl()
    })),
    WebsiteModule.forRoot(() => new WebsiteConfiguration({
      basePath: ConfService.apiUrl()
    })),
    WebhookModule.forRoot(() => new WebhookConfiguration({
      basePath: ConfService.apiUrl()
    })),
    WorkingHoursModule.forRoot(() => new WorkingHoursConfiguration({
      basePath: ConfService.apiUrl()
    })),
    WorkflowDefinitionModule.forRoot(() => new WorkflowDefinitionConfiguration({
      basePath: ConfService.apiUrl()
    })),
    WorkflowInstanceModule.forRoot(() => new WorkflowInstanceConfiguration({
      basePath: ConfService.apiUrl()
    })),
    XeroModule.forRoot(() => new XeroConfiguration({
      basePath: ConfService.apiUrl()
    })),
    TemplateEngineModule.forRoot(() => new TemplateEngineConfiguration({
      basePath: ConfService.apiUrl()
    })),
    SendToAllModule.forRoot(() => new SendToAllConfiguration({
      basePath: ConfService.apiUrl()
    })),
    // ChatgptModule.forRoot(() => new ChatgptConfiguration({
    //   basePath: ConfService.apiUrl()
    // })),
    QBModule.forRoot(() => new QBConfiguration({
      basePath: ConfService.apiUrl()
    })),
    CourseModule.forRoot(() => new CourseConfiguration({
      basePath: ConfService.apiUrl()
    })),
    TrainingEventModule.forRoot(() => new TrainingEventConfiguration({
      basePath: ConfService.apiUrl()
    })),
    StudentCourseModule.forRoot(() => new StudentCourseConfiguration({
      basePath: ConfService.apiUrl()
    })),
    StudentModule.forRoot(() => new StudentConfiguration({
      basePath: ConfService.apiUrl()
    })),
    WalkersModule.forRoot(() => new WalkersConfiguration({
      basePath: ConfService.apiUrl()
    })),
    WizardModule.forRoot(() => new WizardConfiguration({
      basePath: ConfService.apiUrl()
    })),
    ResourceModule.forRoot(() => new ResourceConfiguration({
      basePath: ConfService.apiUrl()
    })),
    LeadModule.forRoot(() => new LeadConfiguration({
      basePath: ConfService.apiUrl()
    })),
    OrderModule.forRoot(() => new OrderConfiguration({
      basePath: ConfService.apiUrl()
    })),
    AccommodationApiModule.forRoot(() => new AccommodationConfiguration({
      basePath: ConfService.apiUrl()
    })),
    QuoteApiModule.forRoot(() => new QuoteConfiguration({
      basePath: ConfService.apiUrl()
    })),
    VaccinationModule.forRoot(() => new VaccinationConfiguration({
      basePath: ConfService.apiUrl()
    })),
    GalleryModule.forRoot(() => new GalleryConfiguration({
      basePath: ConfService.apiUrl()
    })),
    NewsletterModule.forRoot(() => new NewsletterConfiguration({
      basePath: ConfService.apiUrl()
    })),
    LandingPageModule.forRoot(() => new LandingPageConfiguration({
      basePath: ConfService.apiUrl()
    })),
    TermsAndConditionsModule.forRoot(() => new TermsAndConditionsConfiguration({
      basePath: ConfService.apiUrl()
    })),
    ExpenseModule.forRoot(() => new ExpenseConfiguration({
      basePath: ConfService.apiUrl()
    })),
    CustomerSignupFormsModule,
    SimpleBookingPublicModule,
    AppImagesModule,
    AppWebhookModule,
    WebsitePublicModule,
    NgWizardModule.forRoot(ngWizardConfig),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgxDaterangepickerMd.forRoot(),
    ArchwizardModule,
    TimeToDateModule,
  ],
  providers: [
    CookieService,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    },
    // The Favicons is an abstract class that represents the dependency-injection
    // token and the API contract. THe BrowserFavicon is the browser-oriented
    // implementation of the service.
    {
      provide: Favicons,
      useClass: BrowserFavicons
    },
    {
      provide: BROWSER_FAVICONS_CONFIG,
      useValue: {
        icons: {
          cacheBusting: true
        }
      }
    },
    // {
    //   provide: 'SocialAuthServiceConfig',
    //   useValue: {
    //     autoLogin: false,
    //     providers: [
    //       {
    //         id: FacebookLoginProvider.PROVIDER_ID,
    //         provider: new FacebookLoginProvider('645427932581008'),
    //       }
    //     ]
    //   }
    // },
    AuthHttpInterceptor,
    {

      provide: HTTP_INTERCEPTORS,
      useClass: AggregateInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [TraceService],
      multi: true,
    },
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: navigator.language },
    {
      provide: LOCALE_ID,
      useClass: DynamicLocaleId,
      deps: [TranslateService]
    },

    BreadcrumbsService,
    LocalMenuService,
    MenuServicenpm,
    MenuFactory,
    NavChangeService,
    LayoutBusService,
    IconService,
    // Shared services
    ContextService,
    CountryService,
    DateUtils,
    TimeUtils,
    NavigateService,
    EventHandlerService,
    UiService,
    UiAuthService,
    UsersService,
    StorageService,
    FaviconService,
    StyleService,
    FloSnackbarComponent,
    SocialComponent,
    EventBusService,
    ParamsService,
    LanguageService,
    LocaleService,
    LookAndFeelLocalService,
    PortalSettingsLocalService,
    ProductMetaLocalService,
    LinkIdService,
    HelpService,
    SmsSettingsService,
    MenucompService,
    SearchService,
    DashboarddefsService,
    DatalistService,
    CalendarSettingsService,
    NotificationService,
    ViewcompService,
    MessagecompService,
    SmsBundleService,
    AuthGuard,
    ThemeService,
    SmsCreditService,
    SmsCreditStateService,
    TrialExpiredService,
    TrialExpiredStateService,
    SnackBarService,
    FlagDefHelperService,
    ImageService,
    PaymentService,
    PlanDefService,
    UserCalendarSettingsService,
    OrgCompositeService
  ],
  bootstrap: [
    AppComponent
  ]
})

export class AppModule {
}
