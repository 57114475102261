import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ListMessagesPageComponent } from './listMessagesPage.component';

import { MessagingRoutes } from './messaging.routing';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageService } from '@savvy/messaging';
import { LabelsService } from '@savvy/messaging';
import { NewMessagePanelComponent } from './newMessagePanel.component';
import { ReplyComponent } from './reply.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { EditorModule } from '../editor/editor.module';
import { ViewMessagePanelComponent } from './viewMessagePanel.component';
import { MessagesDisabledComponent } from './messagesDisabled.component';
import { ZeroMessagesComponent } from './zeroMessages.component';
import { MessagecompService } from '@savvy/messaging';
import { EmailRecipientHelper } from './EmailRecipientHelper';
import { PipesModule } from '../pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { UsercompService } from '@savvy/user';
import { SentMessagesPageComponent } from './sentMessagesPage.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {InboxComponent} from "./inbox.component";
import { ChatBoxComponent } from './chat-box/chat-box.component';
import { ChatListComponent } from './chat-list/chat-list.component';
import { FilterChatPipe } from './chat-list/filter-chat.pipe';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(MessagingRoutes),
        EditorModule,
        MatAutocompleteModule,
        NgxDatatableModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatDialogModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatProgressBarModule,
        MatRadioModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        NgScrollbarModule,
        FlexLayoutModule,
        PipesModule,
        TranslateModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [
        NewMessagePanelComponent,
        ListMessagesPageComponent,
        MessagesDisabledComponent,
        SentMessagesPageComponent,
        // Make this available to other modules
        ReplyComponent,
        ViewMessagePanelComponent,
        ZeroMessagesComponent
    ],
    providers: [
        EmailRecipientHelper,
        MessageService,
        MessagecompService,
        LabelsService,
        UsercompService
    ],
    declarations: [
        ReplyComponent,
        InboxComponent,
        ListMessagesPageComponent,
        SentMessagesPageComponent,
        MessagesDisabledComponent,
        NewMessagePanelComponent,
        ViewMessagePanelComponent,
        ZeroMessagesComponent,
        ChatBoxComponent,
        ChatListComponent,
        FilterChatPipe
    ]
})

export class AppMessagingModule { }
