import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ChangeListener } from './changeListener';
import { EventBusService } from './EventBusService';
import { Router } from '@angular/router';
import { NavigateService } from '../entry/NavigateService';
import { TrialExpiredService } from '../shared/services/trial-expired.service';
import { ViewInvoiceEntitiesCachedTableData } from '@savvy/view-definition';
import { ContextIdDto } from '@savvy/quickbooks';

@Component({
  selector: 'app-view-invoice-entities-cached-table',
  templateUrl: 'viewInvoiceEntitiesCachedTable.component.html'
})
export class ViewInvoiceEntitiesCachedTableComponent implements OnInit {

  @Input() viewInvoiceEntitiesCachedTableData: ViewInvoiceEntitiesCachedTableData;
  @Input() contextIdDto: ContextIdDto;
  @Input() viewContext: any;
  @Input() form: UntypedFormGroup;
  @Input() eventBus: EventBusService;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;

  constructor(private router: Router,
    private naviateService: NavigateService,
    public trialExpiredService: TrialExpiredService) {
  }
  ngOnInit() {

    // TODO: Reload when tab changes
    // ViewInvoiceEntitiesCachedTableDefinition
  }
}

