import { Routes } from '@angular/router';
import { AddInvoiceComponent } from './addInvoice.component';
import { EditInvoiceComponent } from './editInvoice.component';
import { ViewInvoicePageComponent } from './viewInvoicePage.component';
import { ExportInvoicesComponent } from './exportInvoices.component';
import { ViewInvoicesComponent } from './viewInvoices.component';
import { AddCustomerInvoiceComponent } from './addCustomerInvoice.component';

export const invoiceRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'addInvoiceWithOrderId/:contextId/:contextIdType/:orderId/:linkId/:linkIdType/:linkEdId',
        component: AddInvoiceComponent,
        data: {
          breadcrumb: 'Add Invoice'
        }
      },
      {
        path: 'addInvoiceWithLinkEd/:contextId/:contextIdType/:linkId/:linkIdType/:linkEdId',
        component: AddInvoiceComponent,
        data: {
          breadcrumb: 'Add Invoice'
        }
      },
      {
        path: 'addInvoiceWithLink/:contextId/:contextIdType/:linkId/:linkIdType', component: AddInvoiceComponent,
        data: {
          breadcrumb: 'Add Invoice'
        }
      },
      {
        path: 'addCustomerInvoice/:contextId/:contextIdType/:customerId', component: AddCustomerInvoiceComponent,
        data: {
          breadcrumb: 'Add Invoice'
        }
      },
      {
        path: 'editInvoice/:contextId/:contextIdType/:invoiceId', component: EditInvoiceComponent,
        data: {
          breadcrumb: 'Edit Invoice'
        }
      },
      {
        path: 'viewInvoice/:contextId/:contextIdType/:invoiceId', component: ViewInvoicePageComponent,
        data: {
          breadcrumb: 'View Invoice'
        }
      },
      {
        path: 'viewInvoice/:contextId/:contextIdType/:invoiceId/:invoicePaymentOwnerId/:invoicePaymentOwnerType',
        component: ViewInvoicePageComponent,
        data: {
          breadcrumb: 'View Invoice'
        }
      },
      {
        path: 'listInvoices/:contextId/:contextIdType', component: ViewInvoicesComponent,
        data: {
          breadcrumb: 'View Invoices'
        }
      },
      {
        path: 'exportInvoices/:contextId/:contextIdType', component: ExportInvoicesComponent,
        data: {
          breadcrumb: 'Export Invoices'
        }
      }
    ]
  }
];
