<mat-card appearance="outlined" class="appt-panel new-card" *ngIf="createAppointmentViewData">
  <mat-card-content>

    <mat-tab-group>
      <mat-tab label="New Customer">
        <form #embeddedForm id="embeddedForm">

          <br>
          <div>


            <mat-form-field appearance="outline" class="w-100">
              <mat-label>{{'Date/Time' | translate}}</mat-label>

              <input matInput [matDatepicker]="picker"
                     required
                     (click)="picker.open()"
                     placeholder="Date/Time">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>

              <!--mat-error *ngIf="!form.controls[elementInstanceDto.instanceId].hasError('required') && form.controls[elementInstanceDto.instanceId].invalid">
                {{dateTimeDefinition.label + ' is' | translate}} <strong>{{'invalid' | translate}}</strong>
              </mat-error>
              <mat-error *ngIf="form.controls[elementInstanceDto.instanceId].hasError('required')">
                {{dateTimeDefinition.label + ' is' | translate}} <strong>{{'required' | translate}}</strong>
              </mat-error-->
            </mat-form-field>



            <mat-form-field appearance="outline" class="w-100" *ngIf="timeArray && timeArray.length > 0">
              <mat-select required>

                <mat-option *ngFor="let time of timeArray"
                            [value]="time.actualValue">{{time.displayValue}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <br>

            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>{{'Customer Name' | translate}}</mat-label>
              <input matInput
                     [(ngModel)]="customerName" name="customerNameField"
                     #customerNameField="ngModel"
                     class="demo-full-width" placeholder="{{'Customer Name' | translate}}"
                     style="width: 100%"
                     required>
              <mat-error *ngIf="customerNameField.hasError('required')">
                {{'Customer Name is' | translate}} <strong>{{'required' | translate}}</strong>
              </mat-error>
            </mat-form-field>

            <br>

            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>{{'Customer Phone' | translate}}</mat-label>
              <input matInput
                     [(ngModel)]="customerPhone" name="customerPhoneField"
                     #customerPhoneField="ngModel"
                     class="demo-full-width" placeholder="{{'Customer Phone' | translate}}"
                     style="width: 100%"
                     required>
              <mat-error *ngIf="customerPhoneField.hasError('required')">
                {{'Customer Phone is' | translate}} <strong>{{'required' | translate}}</strong>
              </mat-error>
            </mat-form-field>

            <br>

            <!--
             [disabled]="!embeddedForm.valid"
             -->
            <button mat-raised-button color="accent"

                    (click)="create()">{{'CREATE' | translate}}
            </button>
          </div>

        </form>

      </mat-tab>
      <mat-tab label="Existing Customer">

      </mat-tab>
    </mat-tab-group>


  </mat-card-content>
</mat-card>
