import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContextIdDto } from '@savvy/menu';
import { FileValue } from '@savvy/pet';
import { StorageService } from '@savvy/storage';
import { ConfService } from 'src/app/flo/config/conf.service';
import { WindowRef } from 'src/app/flo/windowWrapper';

@Component({
  selector: 'app-pet-customer-file-upload',
  templateUrl: './pet-customer-file-upload.component.html',
  styleUrls: ['./pet-customer-file-upload.component.scss']
})
export class PetCustomerFileUploadComponent implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() files: Array<FileValue> = [];
  droppedFilesArray: File[] = [];

  constructor(
    private dialog: MatDialog,
    private injector: Injector,
    private storage: StorageService,
  ) { }

  ngOnInit(): void {
    if (!this.files) {
      this.files = [];
    }
  }

  downloadFile(file: FileValue) {
    const url = ConfService.apiUrl() + '/rest/storage?contextId=' + this.contextIdDto.contextId +
      '&contextIdType=' + this.contextIdDto.contextIdType +
      '&id=' + file.fileReferenceId;

    const nativeWindow = this.injector.get(WindowRef).nativeWindow;
    nativeWindow.location.href = url;
  }
}
