
<div *ngIf="!lookAndFeelConfig">
No look and feel config
</div>

<div *ngIf="lookAndFeelConfig">

  <app-date-time-instance-time-picker *ngIf="isTimePicker"
                                      [dateTimeDefinition]="dateTimeDefinition"
                                      [entityInstanceId]="entityInstanceId"
                                      [contextIdDto]="contextIdDto"
                                      [changeListener]="changeListener"
                                      [showPlaceholder]="showPlaceholder"
                                      [elementInstanceDto]="elementInstanceDto"
                                      [form]="form">

  </app-date-time-instance-time-picker>

  <app-date-time-instance-drop-down *ngIf="!isTimePicker"
                                    [dateTimeDefinition]="dateTimeDefinition"
                                    [entityInstanceId]="entityInstanceId"
                                    [contextIdDto]="contextIdDto"
                                    [changeListener]="changeListener"
                                    [showPlaceholder]="showPlaceholder"
                                    [elementInstanceDto]="elementInstanceDto"
                                    [form]="form">

  </app-date-time-instance-drop-down>

</div>


