<mat-form-field appearance="outline" class="w-100">
  <mat-label>{{'PACKAGE' | translate: {Default: 'Package'} }}</mat-label>
  <mat-select placeholder="{{'SELECT_PACKAGE' | translate: {Default: 'Select Package'} }}" [(ngModel)]="selectedPackage" required name="packageField"
    #packageField="ngModel" (selectionChange)="packageChanged($event)" [compareWith]="compareObjects" [disabled]="disabled">
    <mat-option>
      <ngx-mat-select-search [placeholderLabel]="'Search Service'" [noEntriesFoundLabel]="'No matching package'" ngModel (ngModelChange)="filterPackages($event)">
      </ngx-mat-select-search>
    </mat-option>
    <mat-option [value]="''">
      <button mat-flat-button color="accent" (click)="createNewPackage()">&nbsp;&nbsp;
        {{'ADD_PACKAGE' | translate: {Default: 'Add Package'} }}
      </button>
    </mat-option>

    <mat-option *ngFor="let package of filteredPackages | async" [value]="package">
      {{ package.packageName }}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="packageField.hasError('required')">
    {{'PACKAGE_IS' | translate: {Default: 'Package is'} }} <strong>{{'REQUIRED' | translate: {Default: 'required'} }}</strong>
  </mat-error>
</mat-form-field>
