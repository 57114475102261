import {HttpClient} from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {NgxCroppedEvent, NgxPhotoEditorService} from 'ngx-photo-editor';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss']
})
export class ImageEditorComponent implements OnInit, OnChanges {

  @Input() url;
  @Input() preview;
  @Input() style;
  @Input() maxFileSize;
  @Input() buttonCaption;
  @Input() headers;
  @Input() multiple;
  @Input() uploadedFiles;

  @Output() removed: EventEmitter<any> = new EventEmitter();
  @Output() uploadFinished: EventEmitter<any> = new EventEmitter();
  @Output() uploadStateChanged: EventEmitter<any> = new EventEmitter();

  imageURL: any;
  files: any[] = [];


  constructor(
    private httpClient: HttpClient,
    private changeDetectorRef: ChangeDetectorRef,
    private ngxPhotoEditorService: NgxPhotoEditorService,
  ) { }

  ngOnInit() {
    if (this.uploadedFiles && this.uploadedFiles.length) {
      for (const file of this.uploadedFiles) {
        this.files.push({
          fileName: file.fileName,
          url: file.url
        });
      }
    }
  }

  editImage(image) {
    this.ngxPhotoEditorService.open(image, {
      // aspectRatio: 4 / 3,
      autoCropArea: 100
    }).subscribe((croppedEvent: NgxCroppedEvent) => {
      this.imageCropped(croppedEvent);
    });
  }

  fileChangeEvent(event: any) {
    if (event.target.files && event.target.files.length
      && event.target.files.length > 1) {
      Array.from(event.target.files).forEach((file: any) => {
        this.readURL(file).then(url => {
          this.postFile(file).subscribe(res => {
            this.uploadFinished.emit(res);
            this.imageURL = undefined;
            const input = {
              fileName: file.name,
              url,
            };
            this.files.push(input);
            setTimeout(() => {
              this.changeDetectorRef.detectChanges();
            }, 300);
          });

        });
      });
    } else if (event.target.files && event.target.files.length
      && event.target.files.length === 1) {
      this.ngxPhotoEditorService.open(event, {
        // aspectRatio: 4 / 3,
        autoCropArea: 100
      }).subscribe((croppedEvent: NgxCroppedEvent) => {
        if (croppedEvent?.file) {
          this.uploadStateChanged.emit(true);
          this.readURL(croppedEvent.file).then(url => {
            this.files = [];
            this.imageURL = undefined;
            const input = {
              fileName: croppedEvent?.file?.name,
              url,
            };
            this.files.push(input);
            setTimeout(() => {
              this.changeDetectorRef.detectChanges();
            }, 300);
          });
        }
      });
    }
  }

  imageCropped(event: NgxCroppedEvent) {
    if (event?.file) {
      this.postFile(event?.file).subscribe(res => {
        this.uploadFinished.emit(res);
        this.imageURL = undefined;
      });
    }
  }

  onRemoved(event) {
    this.removed.emit(event);
  }
  onUploadStateChanged($event) {
    this.uploadStateChanged.emit($event);
  }

  postFile(fileToUpload: File): Observable<any> {
    const endpoint = this.url;
    const formData: FormData = new FormData();
    formData.append('image', fileToUpload, fileToUpload.name);
    return this.httpClient
      .post(endpoint, formData, { headers: this.headers });
  }

  readURL(input: File) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const url = e.target.result;
        resolve(url);
      };
      reader.readAsDataURL(input); // convert to base64 string
    });
  }

  removeUpload(i) {
    const file = this.files[i];
    this.removed.emit(file.url);
    this.files.splice(i, 1);
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      // console.log(changes);
    }

  }

}
