import {Component, Input, OnInit} from "@angular/core";
import {WorkflowState} from "@savvy/workflow-definition";
import {WorkflowDefinition} from "@savvy/workflow-definition/model/workflowDefinition";
import {
  WorkflowInstance,
  WorkflowinstanceService,
  WorkflowinstcompService,
  WorkflowStateInstanceDto
} from "@savvy/workflow-instance";
import {ContextIdDto} from "@savvy/ui";
import {FloSnackbarComponent} from "../../snackbar/floSnackbar.component";
import { AppointmentService} from "@savvy/appointment";
import {EventBusService} from "../../element/EventBusService";
import {CustomerCompositeService, CustomerStatusResponse} from "@savvy/customer";
import {LoadWorkflowStatsCompResponse} from "@savvy/workflow-instance/model/models";
import {WorkflowStatsDto} from "@savvy/workflow-instance/model/workflowStatsDto";


@Component({
  selector: 'app-view-customer-stats',
  templateUrl: 'customerHistory.component.html'
})
export class CustomerHistoryComponent implements OnInit {

  @Input() contextIdDto: ContextIdDto;
  @Input() customerId: string;

  workflowStatsDto: WorkflowStatsDto;

  constructor(private workflowInstance: WorkflowinstcompService) {

  }


  ngOnInit(): void {
    this.loadHistory();
  }

  loadHistory() {
    if (this.customerId) {
      this.workflowInstance.loadWorkflowStats(this.contextIdDto.contextId, this.contextIdDto.contextIdType, this.customerId).subscribe(response => {
        this.workflowStatsDto = response.workflowStatsDto;
      });
    }
  }

}


