import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Injectable,
  Input,
  LOCALE_ID,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { CalendarUtils, CalendarWeekViewComponent, DateAdapter, getWeekViewPeriod } from 'angular-calendar';
import {
  WeekView, GetWeekViewArgs, WeekViewTimeEvent, CalendarEvent,
  WeekViewAllDayEventRow,
  WeekViewAllDayEvent,
} from 'calendar-utils';
import { DragEndEvent, DragMoveEvent } from 'angular-draggable-droppable';
import { DateUtils } from '../dates/DateUtils';
import { ContextIdDto } from '@savvy/quickbooks';
import { CalendarUserDto, Location } from '@savvy/calendar';

interface DayViewScheduler extends WeekView {
  users: CalendarUserDto[];
}

interface GetWeekViewArgsWithUsers extends GetWeekViewArgs {
  users: CalendarUserDto[];
}

@Injectable()
export class DayViewSchedulerCalendarUtils extends CalendarUtils {
  getWeekView(args: GetWeekViewArgsWithUsers): DayViewScheduler {
    const { period } = super.getWeekView(args);
    const view: DayViewScheduler = {
      period,
      allDayEventRows: [],
      hourColumns: [],
      users: [...args.users]
    };

    view.users.forEach((user, columnIndex) => {
      const events = args.events.filter(
        event => (event.meta.userId && user.userId && event.meta.userId.id === user.userId.id) || event.allDay
      );
      const columnView = super.getWeekView({
        ...args,
        events
      });
      const column = columnView.hourColumns[0];
      column['user'] = user;
      column.hours.forEach(hour => {
        hour.segments.forEach(segment => {
          segment['user'] = user;
        });
      });
      view.hourColumns.push(column);
      columnView.allDayEventRows.forEach(({ row }, rowIndex) => {
        view.allDayEventRows[rowIndex] = view.allDayEventRows[rowIndex] || {
          row: [],
        };
        view.allDayEventRows[rowIndex].row.push({
          ...row[0],
          offset: columnIndex,
          span: 1,
        });
      });
    });

    return view;
  }
}

@Component({
  // eslint-disable-line  max-classes-per-file
  selector: 'app-mwl-day-view-scheduler',
  templateUrl: 'calendarDayViewScheduler.component.html',
  providers: [DayViewSchedulerCalendarUtils],
  styleUrls: ['calendarDayViewScheduler.component.scss']
})
export class DayViewSchedulerComponent extends CalendarWeekViewComponent
  implements OnChanges, OnInit {

  @ViewChild('dayColumns') dayColumns: ElementRef;
  @ViewChild('scrollTwo') scrollTwo: ElementRef;

  @Input() users: CalendarUserDto[];
  @Input() contextIdDto: ContextIdDto;
  @Input() location: Location;
  @Output() userChanged = new EventEmitter();

  view: DayViewScheduler;

  daysInWeek = 1;
  scroll1 = false;
  scroll2 = false;
  constructor(
    protected cdr: ChangeDetectorRef,
    protected dateUtils: DateUtils,
    protected utils: DayViewSchedulerCalendarUtils,
    @Inject(LOCALE_ID) locale: string,
    protected dateAdapter: DateAdapter,
    protected elementRef: ElementRef,
  ) {
    super(cdr, utils, locale, dateAdapter, elementRef);
  }

  ngOnInit(): void {

  }

  updateScroll(scrollOne: HTMLElement, scrollTwo: HTMLElement) {
    // do logic and set
    scrollTwo.scrollLeft = scrollOne.scrollLeft;
    // this.scroll1 = true;
    // if (!this.scroll2) {
    // }
    // setTimeout(() => {
    //   this.scroll2 = false;
    // }, 2000);
  }

  updateScroll2(event, scrollTwo: HTMLElement, scrollOne: HTMLElement) {
    event.preventDefault();
    // do logic and set
    // scrollOne.scrollLeft = scrollTwo.scrollLeft;
    // this.scroll2 = true;
    // if (!this.scroll1) {
    // }
    // setTimeout(() => {
    //   this.scroll2 = false;
    // }, 2000);
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.users) {
      this.view = this.getWeekView(this.events);
    }
    if (changes.viewDate) {
      if (this.location) {
        console.log('view date changed, and location is set:' + this.location.locationName);
      } else {
        console.log('view date changed, but no location set');
      }
    }
    if (changes.location) {
      if (this.location) {
        console.log('location changed, and location is now:' + this.location.locationName);
      } else {
        console.log('location change ' + changes.location);
      }
    }
  }


  getDayColumnWidth(eventRowContainer: HTMLElement): number {
    return Math.floor(eventRowContainer.offsetWidth / this.users.length);
  }

  dragMove(dayEvent: WeekViewTimeEvent, dragEvent: DragMoveEvent) {
    if (this.snapDraggedEvents) {
      const newUser = this.getDraggedUserColumn(dayEvent, dragEvent.x);
      const newEventTimes = this.getDragMovedEventTimes(
        dayEvent,
        { ...dragEvent, x: 0 },
        this.dayColumnWidth,
        true
      );
      const originalEvent = dayEvent.event;
      const adjustedEvent = {
        ...originalEvent,
        ...newEventTimes,
        meta: { ...originalEvent.meta, user: newUser }
      };
      const tempEvents = this.events.map(event => {
        if (event === originalEvent) {
          return adjustedEvent;
        }
        return event;
      });
      this.restoreOriginalEvents(
        tempEvents,
        new Map([[adjustedEvent, originalEvent]])
      );
    }
    this.dragAlreadyMoved = true;
  }

  dragEnded(
    weekEvent: WeekViewAllDayEvent | WeekViewTimeEvent,
    dragEndEvent: DragEndEvent,
    dayWidth: number,
    useY = false
  ) {
    super.dragEnded(
      weekEvent,
      {
        ...dragEndEvent,
        x: 0
      },
      dayWidth,
      useY
    );
    const newUser = this.getDraggedUserColumn(weekEvent, dragEndEvent.x);
    if (newUser && newUser !== weekEvent.event.meta.user) {
      this.userChanged.emit({ event: weekEvent.event, newUser });
    }
  }

  protected getWeekView(events: CalendarEvent[]) {
    if (!events) {
      return;
    }
    console.log('we have got week events', events.length);
    return this.utils.getWeekView({
      events,
      users: this.users,
      viewDate: this.viewDate,
      weekStartsOn: this.weekStartsOn,
      excluded: this.excludeDays,
      precision: this.precision,
      absolutePositionedEvents: true,
      hourSegments: this.hourSegments,
      dayStart: {
        hour: this.dayStartHour,
        minute: this.dayStartMinute
      },
      dayEnd: {
        hour: this.dayEndHour,
        minute: this.dayEndMinute
      },
      segmentHeight: this.hourSegmentHeight,
      weekendDays: this.weekendDays,
      ...getWeekViewPeriod(
        this.dateAdapter,
        this.viewDate,
        this.weekStartsOn,
        this.excludeDays,
        this.daysInWeek
      )
    });
  }

  private getDraggedUserColumn(dayEvent: WeekViewTimeEvent | WeekViewAllDayEvent, xPixels: number) {
    const columnsMoved = Math.round(xPixels / this.dayColumnWidth);
    const currentColumnIndex = this.view.users.findIndex(
      user => user === dayEvent.event.meta.user
    );
    const newIndex = currentColumnIndex + columnsMoved;
    return this.view.users[newIndex];
  }
}

