import { Component, OnInit, ViewChild } from '@angular/core';
import { ContextIdDto } from '@savvy/quickbooks';
import { UntypedFormGroup } from '@angular/forms';
import { ElementControlService } from './ElementControlService';
import { ChangeListener } from './changeListener';
import { ActivatedRoute } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { ViewEntityComponent } from './viewEntity.component';
import { EventBusService } from './EventBusService';
import { EventType, UiEvent } from '../event/UiEvent';
import { EicompService } from '@savvy/entity-instance-composite';
import { NavigateService } from '../entry/NavigateService';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';

@Component({
  selector: 'app-view-entity-page',
  templateUrl: 'viewEntityPage.component.html',
  styleUrls: ['viewEntityPage.component.scss'],
  providers: [ElementControlService]
})
export class ViewEntityPageComponent implements OnInit {
  entityInstanceId: string;
  contextIdDto: ContextIdDto;
  changeListener = new ChangeListener();
  eventBus = new EventBusService();

  public form: UntypedFormGroup; // our form model

  sub: any;
  active: boolean;

  @ViewChild('sidePanel', { static: false }) sidePanel: MatSidenav;
  @ViewChild('viewEntity', { static: false }) viewEntity: ViewEntityComponent;

  constructor(
    private notify: FloSnackbarComponent,
    private navigateService: NavigateService,
    private eiCompositeApi: EicompService,
    private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    console.log('ViewEntityPageComponent inside init');
    this.eventBus.eventAdded$.subscribe(event => this.onEvent(event));


    this.sub = this.route.params.subscribe(params => {
      this.entityInstanceId = params['entityInstanceId'];
      const contextId = params['contextId'];
      const contextIdType = params['contextIdType'];
      console.log('got contextId ' + contextId);

      this.contextIdDto = { contextIdType, contextId };

      console.log('leaving init of ViewEntityPageComponent');
    });
  }

  private onEvent(event: UiEvent): void {
    console.log('got event ' + event);
    if (event.eventType === EventType.DELETE_ENTITY_INSTANCE) {
      console.log('got event and DELETING NOW');
      console.log('deleting 125');

      this.eiCompositeApi.deleteEntityInstance(
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        event.entityInstanceId.id,
        false)
        .subscribe(
          response => {
            this.navigateService.listEntities(this.contextIdDto, this.viewEntity.loadEiViewResponse.viewContextDto.entityDefinitionId);
          },
          err => {
            console.log('err ' + err.status);
            if (err.status === 409) {
              this.notify.message = err.message;
              this.notify.open();
            }
          },
        );
    }
  }

}
