import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";

import {ContextIdDto, LinkId, Notification} from '@savvy/notification';
import {FloSnackbarComponent} from "../../snackbar/floSnackbar.component";
import {MatDialog} from "@angular/material/dialog";
import {UrlLookupService} from "../../shared/services/urlLookup.service";

@Component({
  selector: 'app-contact-form-notification',
  templateUrl: './contactFormNotification.component.html'
})
export class ContactFormNotificationComponent implements OnInit {

  @Input() notification: Notification;
  @Input() contextIdDto: ContextIdDto;

  @Output() notificationProcessed: EventEmitter<any> = new EventEmitter();

  constructor(private notify: FloSnackbarComponent,
              private urlLookupService: UrlLookupService,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
  }





  gotoContractForm() {
    const contactFormId = this.getLinkId('CONTACT_FORM_ID', this.notification.linkIds);

    const url = this.urlLookupService.portalUrl() + '/viewSubmittedContactForm.html?id=' +
      this.contextIdDto.contextId + '&contactFormId='
      + contactFormId + this.urlLookupService.getEnv();
    this.urlLookupService.openNewTab(url);
  }






  getLinkId(type: string, linkIds: Array<LinkId>): string {
    let linkIdToRet;

    linkIds.forEach(linkId => {
      if (linkId.linkedIdType === type) {
        linkIdToRet = linkId.linkedId;
      }
    });
    return linkIdToRet;
  }
}
