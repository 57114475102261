<mat-toolbar>
  <mat-toolbar-row>{{'APPT_NOTES' | translate}}</mat-toolbar-row>
</mat-toolbar>

<mat-card appearance="outlined">

<mat-form-field appearance="outline"  style="width: 100%">
  <mat-label>{{'NOTES' | translate}}</mat-label>
  <textarea matInput
            id="bodyField"
            name="bodyField"
            #bodyField="ngModel"
            [(ngModel)]="notes"
            placeholder="Enter Message">
          </textarea>
  <mat-error *ngIf="bodyField.hasError('required')">
    {{'MESSAGE_IS' | translate}} <strong>{{'REQUIRED' | translate}}</strong>
  </mat-error>
</mat-form-field>
  <br>

  <button mat-flat-button color="accent" (click)="save()">{{'UPDATE' | translate}}</button>
</mat-card>





