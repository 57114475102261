
<div [formGroup]="formGroup">
  <mat-form-field appearance="outline" style="width: 100%;">
    <mat-label>{{htmlSelectElement.label | translate}}</mat-label>
    <mat-select
      [required]="htmlSelectElement.required"
      [formControlName]="htmlSelectElement.name"
      placeholder="{{htmlSelectElement.placeholder | translate}}">

      <mat-option
        *ngFor="let item of htmlSelectElement.dataList.dataItems"
        [value]="item.name">{{item.label}}
      </mat-option>
    </mat-select>
    <!--mat-error *ngIf="form.controls[elementInstanceDto.instanceId].hasError('required')">
      {{selectFieldDefinition.label + ' is' | translate}} <strong>{{'required' | translate}}</strong>
    </mat-error-->
  </mat-form-field>
</div>
