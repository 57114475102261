<mat-toolbar color="primary">
  <mat-toolbar-row>
    <h2>{{'PAY_VIA_MACHINE' | translate}}</h2>
  </mat-toolbar-row>
</mat-toolbar>

<div *ngIf="successMessage">
  <h6>{{ successMessage }}</h6>
  <button mat-raised-button (click)="closeDialog()">{{'CLOSE' | translate}}</button>
</div>

<h6 *ngIf="errorMessage" class="errorMessage">{{ errorMessage }}</h6>
<br>
<div>
  <form *ngIf="donePayment && !successMessage" #paymentForm="ngForm" class="request-terminal w-100 mdc-p-3">
    <!-- Only allow choosing card machine if there are more than card machine available-->
    <mat-form-field appearance="outline" style="width: 100%" *ngIf="cardMachines && cardMachines.length > 1">
      <mat-label>{{'CARD_MACHINES' | translate}}</mat-label>
      <mat-select [(ngModel)]="makeTerminalPayment.cardMachineId" name="cardMachineId" id="cardMachineId" required="true" placeholder="{{'CARD_MACHINE' | translate}}" required>
        <ng-container *ngFor="let cardMachine of cardMachines">
          <mat-option [value]="cardMachine.id">{{ cardMachine.alias }}
          </mat-option>
        </ng-container>

      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" style="width: 100%">
      <mat-label>Amount</mat-label>
      <input matInput [(ngModel)]="makeTerminalPayment.amount" name="amount" [required]="true" #amount="ngModel" id="amount" placeholder="{{'AMOUNT' | translate}}" style="width: 100%">
      <mat-hint>{{'AMOUNT_TO_BE_CHARGED' | translate}}</mat-hint>
    </mat-form-field>
<!--    <div *ngIf="accountHolder && accountHolder.allowTelephoneOrder">-->
<!--      <mat-checkbox class="example-margin" [(ngModel)]="makeTerminalPayment.telephoneOrder" name="telephoneOrder" #telephoneOrder="ngModel">{{'MAIL_PHONE_ORDER' | translate}}</mat-checkbox><br>-->
<!--      <mat-hint>{{'TAKE_PAYMENT_VIA_PHONE' | translate}}</mat-hint>-->
<!--    </div>-->

    <br><br>

    <button *ngIf="savvyPayAccountHolder?.id && savvyPayAccountHolder?.fromUpgraded" mat-raised-button color="primary" [disabled]="!paymentForm.valid && !donePayment" (click)="payTerminal2()">{{'PAY_2' | translate}}</button>&nbsp;&nbsp;&nbsp;
    <button *ngIf="!savvyPayAccountHolder" mat-raised-button color="primary" [disabled]="!paymentForm.valid && !donePayment" (click)="payTerminal()">{{'PAY' | translate}}</button>&nbsp;&nbsp;&nbsp;
    <button mat-raised-button (click)="closeDialog()">{{'CANCEL' | translate}}</button>
  </form>
</div>

<div *ngIf="!donePayment">
  <h5>{{'PAYMENT_IN_PROGRESS' | translate}}...</h5>
</div>
