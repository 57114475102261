import { Component, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppointmentDataV1V2, AppointmentV1V2Service } from '@savvy/appointment';
import { CalendarEntry } from '@savvy/calendar';
import { Customer, CustomerCompositeService, CustomerService, FileReference, UpdateCustomer } from '@savvy/customer';
import { ContextIdDto } from '@savvy/quickbooks';
import { Feature } from '@savvy/subscription';
import { CalendarEvent } from 'angular-calendar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ContextService } from '../../context.service';
import { EventBusService } from '../../element/EventBusService';
import { ViewEntitySummaryComponent } from '../../element/viewEntitySummary.component';
import { UiEvent } from '../../event/UiEvent';
import { ConfirmationModalComponent } from '../../shared/components/confirmation-modal/confirmation-modal.component';
import { FeaturesHelperService } from '../../shared/services/features-helper.service';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';
import { AddCustomerComponent } from '../customer/addCustomer.component';
import { CustomerPetDetailsComponent } from './customer-pet-details/customer-pet-details.component';
import { ViewV1V2CustomerHistoryComponent } from './view-v1-v2-customer-history/view-v1-v2-customer-history.component';
import { AddOnsHelperService } from '../../shared/services/add-ons-helper.service';
@Component({
  selector: 'app-view-customer-v2',
  templateUrl: './view-customer-v2.component.html',
  styleUrls: ['./view-customer-v2.component.scss']
})
export class ViewCustomerV2Component implements OnInit {
  @ViewChild('sidePanel') sidePanel;
  @ViewChild('viewV1V2CustomerHistoryComponent') viewV1V2CustomerHistoryComponent: ViewV1V2CustomerHistoryComponent;
  @ViewChild('customerPetDetailsComponent') customerPetDetailsComponent: CustomerPetDetailsComponent;


  @Input() contextIdDto: ContextIdDto;

  customerDto: Customer;
  customerId: string;
  appointments: AppointmentDataV1V2[] = [];

  options = {
    collapsed: false,
    compact: false,
    boxed: false,
    dark: false,
    dir: 'ltr'
  };
  sidePanelOpened;
  viewing = false;
  calendarEventType: string;
  calendarEventToView: CalendarEntry;
  entityInstanceId;
  eventBus: EventBusService;
  isViewMode = false;
  editNotes = false;
  loaded = false;
  customerPortalAllowed = false;
  petStayAddOnActive = false;
  constructor(
    private route: ActivatedRoute,
    private api: CustomerCompositeService,
    private customerService: CustomerService,
    private translateService: TranslateService,
    private notify: FloSnackbarComponent,
    private dialog: MatDialog,
    public deviceService: DeviceDetectorService,
    public contextService: ContextService,
    private featuresHelperService: FeaturesHelperService,
    private appointmentV1V2Service: AppointmentV1V2Service,
    private addOnsHelperService: AddOnsHelperService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data?,
    @Optional() public dialogRef?: MatDialogRef<ViewCustomerV2Component>,
  ) {
  }

  ngOnInit(): void {
    if (this.data && this.data.contextIdDto) {
      this.isViewMode = true;
      this.contextIdDto = this.data.contextIdDto;
      this.featuresHelperService.isFeatureAllowedForce(Feature.TypeEnum.CustomerPortal, this.contextIdDto)
        .then(res => this.customerPortalAllowed = res);

      if (this.data.customerId) {
        this.loadCustomer(this.data.customerId);
      }
    } else {
      this.eventBus = new EventBusService();
      this.eventBus.eventAdded$.subscribe(event => this.onEvent(event));
      this.route.params.subscribe(params => {
        this.contextIdDto = {} as ContextIdDto;
        this.contextIdDto.contextId = params['contextId'];
        this.contextIdDto.contextIdType = params['contextIdType'];
        this.featuresHelperService.isFeatureAllowedForce(Feature.TypeEnum.CustomerPortal, this.contextIdDto)
          .then(res => this.customerPortalAllowed = res);

        console.log('customerId is ' + params['customerId']);
        if (params['customerId']) {
          this.customerId = params['customerId'];
          this.loadCustomer(this.customerId);
        }
      });
    }
    this.addOnsHelperService.loadAddOns(this.contextIdDto).then(res => {
      if (this.addOnsHelperService.addOnPresent('Day Care')) {
        this.petStayAddOnActive = true;
      }
    });
  }

  onEvent(event: UiEvent) {
    console.log('got event ', event);
    // if (event.eventType === EventType.DELETE_ENTITY_INSTANCE) {
    //   console.log('deleting entity instance NOW');
    //   this.eiCompositeApiExtension.deleteEntityInstance(
    //     this.contextIdDto.contextId,
    //     this.contextIdDto.contextIdType,
    //     event.entityInstanceId.id,
    //     false)
    //     .pipe(takeUntil(this.destroy$))
    //     .subscribe(
    //       response => {
    //         this.loadCalendarCloseView();
    //       }
    //     );
    // } else if (event.eventType === EventType.EDIT_ENTITY) {
    //   this.eventHandler.handleEvent(this.contextIdDto, event);
    // } else if (event.eventType === EventType.VIEW_ENTITY) {
    //   this.eventHandler.handleEvent(this.contextIdDto, event);
    // } else if (event.eventType === EventType.REFRESH_CALENDAR_AFTER_DELETE_AND_CLOSE_VIEW) {
    //   console.log('reloading calendar');
    //   this.loadCalendarCloseView();
    // } else if (event.eventType === EventType.CALENDAR_ENTRY_STATE_CHANGE) {
    //   this.calendarCompositeService.loadCellColour(this.contextIdDto.contextId,
    //     this.contextIdDto.contextIdType,
    //     event.calendarEntryStateChangeEvent.entityInstanceId.id,
    //     event.calendarEntryStateChangeEvent.state).subscribe(response => {


    //       this.loadCalendar();
    //     });
    // } else if (event.eventType === EventType.REFRESH_CALENDAR) {
    //   this.loadCalendar();
    // }
  }

  loadFutureCustomerAppointments(customerId: string) {
    this.appointmentV1V2Service.loadCustomerFutureV1V2(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      customerId
    ).subscribe(response => {
      console.log('response', response);
      this.appointments = response.contents;
    }, () => {
      this.appointments = [];
    });
  }

  loadCustomer(customerId: string) {
    this.customerService.loadCustomer(
      customerId,
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType)
      .subscribe(res => {
        if (res) {
          this.customerDto = res;
          this.loadFutureCustomerAppointments(this.customerDto.customerId.id);
          this.loaded = true;
        }
      });
  }

  cancel() {
    this.dialogRef.close();
  }

  backToList() {
    // this.router.navigate(['/customer/customers',
    //   this.contextIdDto.contextId,
    //   this.contextIdDto.contextIdType]);
    history.back();
  }

  editCustomer() {
    this.openEditCustomerModal();
    // if (this.isViewMode) {
    // } else {
    //   if (this.customerDto && this.customerDto.customer) {
    //     this.router.navigate(['/customer/editCustomer',
    //       this.contextIdDto.contextId,
    //       this.contextIdDto.contextIdType,
    //       this.customerDto.customer.id
    //     ]);
    //   } else {
    //     console.log('no customer for some reason!');
    //   }
    // }
  }

  removeCustomer() {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        name: 'Customer'
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.api.deleteCustomerComp(
          this.contextIdDto.contextId, this.contextIdDto.contextIdType, this.customerDto.customerId.id
        ).subscribe(() => {
          this.translateService.get('Successfully deleted customer').subscribe(value => {
            this.notify.message = value;
            this.notify.open();
          });
          this.backToList();
        });
      }
    });
  }

  viewV1Appointment(event: AppointmentDataV1V2) {
    const entryTypeToUse = 'Ei';
    this.entityInstanceId = event.entityInstanceId;
    // if (calendarEntryDto.entryType) {
    //   entryTypeToUse = calendarEntryDto.entryType;
    // }
    const newEvent: CalendarEvent = {
      start: new Date(event.startDateUtc),
      title: '',
      meta: {
        reference: event.entityInstanceId.id,
        entryType: entryTypeToUse,
      }
    };
    this.openViewDialog(newEvent);
  }


  openViewDialog(calendarEntry) {
    if (calendarEntry && calendarEntry.meta) {
      const reference = calendarEntry.meta.reference;
      this.viewing = true;

      if (calendarEntry && calendarEntry.meta) {

        console.log('calendarEntry.meta.entryType', calendarEntry.meta.entryType);
        if (calendarEntry.meta.entryType === 'holiday') {
          console.log('it is a holiday!');
          this.sidePanel.open();
          this.calendarEventType = calendarEntry.meta.entryType.charAt(0).toUpperCase() + calendarEntry.meta.entryType.slice(1);
          this.calendarEventToView = calendarEntry;
        } else if (calendarEntry.meta.entryType === 'meeting') {
          console.log('it is a meeting!');
          this.sidePanel.open();
          this.calendarEventType = calendarEntry.meta.entryType.charAt(0).toUpperCase() + calendarEntry.meta.entryType.slice(1);
          this.calendarEventToView = calendarEntry;
        } else if (calendarEntry.meta.entryType === 'gmail') {

          console.log('it is a gmail!');
          this.notify.message = 'You cannot open Gmail Calendar Entries, just read them';
          this.notify.open();
        } else if (calendarEntry.meta.entryType === 'Ei' && reference) {
          this.openViewEi(reference);
        } else if (calendarEntry.meta.entryType === 'appointment') {
          // this.openAppointment(reference);
        } else {
          console.log('not sure what this is, id is:' + calendarEntry.id
            + ' title is:' + calendarEntry.title
            + ' start is:' + calendarEntry.start
            + ' end is:' + calendarEntry.end);
        }
      }
    }
  }

  openViewEi(entityInstanceId) {
    console.log('opening up view ei ', entityInstanceId);
    this.viewing = true;
    console.log('openViewDialog entityInstanceId', entityInstanceId);
    setTimeout(() => {
      this.entityInstanceId = entityInstanceId;
      this.calendarEventType = 'Ei';
      if (this.deviceService.isDesktop()) {
        console.log('opening side panel');
        this.sidePanel.open();
      } else if (this.deviceService.isMobile()) {
        this.openEntitySummaryDialog();
      } else {
        this.openEntitySummaryDialog();
      }
    }, 0);
  }

  openEntitySummaryDialog() {
    const dialogRef = this.dialog.open(ViewEntitySummaryComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        eventBus: this.eventBus,
        entityInstanceId: this.entityInstanceId
      },
      maxHeight: '80vh',
      panelClass: 'entitySummaryComponent'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log('dialog closed!', true);
      }
    });
  }

  updateHistory() {
    if (this.viewV1V2CustomerHistoryComponent) {
      this.viewV1V2CustomerHistoryComponent.loadAppointments();
    }
  }

  updateCustomer() {
    const updateCustomerComposite: UpdateCustomer = {
      contextIdDto: this.contextIdDto,
      customer: this.customerDto
    };
    this.customerService.updateCustomer(updateCustomerComposite).subscribe((res: Customer) => {
      if (res) {
        this.notify.message = 'Updated';
        this.notify.open();
        this.editNotes = false;
        this.customerDto = res;
      }
    }, error => {
      if (error.status === 400) {
        this.notify.message = error?.message;
        this.notify.open();
      }
    });
  }

  openEditCustomerModal() {
    if (!this.customerDto?.customerId?.id) {
      console.log('No customerId');
      return;
    }
    const createPetModal = this.dialog.open(AddCustomerComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        customerId: this.customerDto.customerId.id,
        isViewMode: true
      },
      maxWidth: '1024px',
      panelClass: [
        'modal-100',
        'helpwindow',
        'scrollable-modal-2'
      ],
      autoFocus: false,
      disableClose: true
    });

    createPetModal.afterClosed()
      .subscribe(
        () => {
          // if(this.customerStatsComponent) {
          //   this.customerStatsComponent.loadStatus();
          // }
          // this.loadPetFlags(petId);
          this.loadCustomer(this.customerId || this.customerDto.customerId.id);
        });
  }


  apppointmentsChanged() {
    this.updateHistory();
  }

  filesUpdated(newFiles: FileReference[]) {
    this.customerDto = {
      ...this.customerDto,
      files: newFiles
    };
    this.updateCustomer();
  }

}
