import { Injectable } from '@angular/core';
import { Package, ContextIdDto, PackagesCompositeService } from '@savvy/packages';

@Injectable({
  providedIn: 'root'
})
export class PackagesHelperService {
  public packages: Array<Package> = [];

  constructor(
    private packagesService: PackagesCompositeService,
  ) { }

  public loadPackages(contextIdDto: ContextIdDto): Promise<Array<Package>> {
    this.packages = [];
    return new Promise(resolve => {
      if (!contextIdDto?.contextId) {
        resolve([]);
        return;
      }
      this.packagesService.loadPackages(contextIdDto.contextId, contextIdDto.contextIdType).subscribe(response => {
        if (response && response.packages) {
          this.packages = response.packages;
          console.log('loaded packages');
        }
        resolve(this.packages);
      });
    });
  }

  public getPackages(contextIdDto, fresh = false): Promise<Array<Package>> {
    return new Promise(resolve => {
      if (this.packages.length && !fresh) {
        resolve(this.packages);
      } else {
        this.packages = [];
        this.loadPackages(contextIdDto).then(response => {
          if (response && response.length) {
            this.packages = response;
            console.log('loaded Packages');
            resolve(response);
          }
        });
      }
    });
  }

}
