import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'packageFilter'
  })
export class PackageFilterPipe implements PipeTransform {
    transform(items: any[], filterBy: string): any {
        const search = filterBy?.toLowerCase();
        if (search?.length) {
            return items.filter(item => item?.packageName?.toLowerCase()?.indexOf(search) > -1);
        }
        return items;
    }
}
