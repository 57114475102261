<mat-card appearance="outlined" class="p-0">

  <!-- header title -->
  <mat-card-header class="mdc-p-3 ">
    <div fxLayout="row wrap" fxLayoutGap="16px" fxLayoutAlign=" center" class="w-100">
      <div fxFlex>
        <h3 class="mb-0">Contacts</h3>
      </div>
      <div fxFlex="nogrow">
        <button mat-button color="accent"><mat-icon class="vm">campaign</mat-icon>
          Broadcast</button>
      </div>
      <div fxFlex="nogrow">
        <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>filter_list</mat-icon></button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item>Item 1</button>
          <button mat-menu-item>Item 2</button>
        </mat-menu>
      </div>
    </div>
  </mat-card-header>

  <!-- search field -->
  <div class="mdc-px-2">
    <mat-form-field class="pb-0 w-100" appearance="outline">
      <mat-label>Search Contact</mat-label>
      <input matInput type="text" [(ngModel)]="filterString">
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>

  <mat-card-content>
    <!-- tabs and list -->
    <mat-tab-group class="scroll-box-contact">
      <mat-tab label="All">
        <mat-list>
          <mat-list-item *ngFor="let message of messagesList | filterChat:filterString;" class="border-bottom list-item-h cp">
            <div [ngSwitch]="message?.type" (click)="selectMessage(message)" class="w-100">
              <div *ngSwitchCase="messageTypesEnum.Email" class="w-100">
                <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign=" center">
                  <div class="avatar avatar-40 border-2 rounded-circle coverimg " fxFlex="nogrow" style="background-image: url('assets/images/customer-profile.png');"></div>
                  <div fxFlex fxLayout="row wrap">
                    <div fxFlex="">
                      <p class="mb-0">
                        <span class="fw-bold">{{message?.emailMessage?.recipientsAsString}} </span>
                        <span>
                          <small class="mat-text-secondary">
                            <span class="status-online"></span> Online
                          </small>
                        </span>
                      </p>
                    </div>
                    <div fxFlex="nogrow"><small class="mat-text-secondary">{{getNoOfDays(message?.sentLocalDateTime)}}
                        days ago</small>
                    </div>
                    <div fxFlex="100" class="text-truncate">
                      <!-- <p class="small mat-text-secondary mb-0" [innerHTML]="message?.emailMessage?.body">
                        {{message?.emailMessage?.body}}</p> -->
                      <p class="small mat-text-secondary mb-0 lineWrap" [innerHTML]="(message?.emailMessage?.body.length > 60)? (message?.emailMessage?.body | slice:0:60)+'...':(message?.emailMessage?.body )">
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngSwitchCase="messageTypesEnum.Sms" class="w-100">
                <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign=" center">
                  <div class="avatar avatar-40 border-2 rounded-circle coverimg " fxFlex="nogrow" style="background-image: url('assets/images/customer-profile.png');"></div>
                  <div fxFlex fxLayout="row wrap">
                    <div fxFlex="">
                      <p class="mb-0">
                        <span class="fw-bold">{{message?.smsMessage?.toNumber?.msidn}} </span>
                        <span>
                          <small class="mat-text-secondary">
                            <span class="status-online"></span> Online
                          </small>
                        </span>
                      </p>
                    </div>
                    <div fxFlex="nogrow"><small class="mat-text-secondary">{{getNoOfDays(message?.sentLocalDateTime)}}
                        days ago</small>
                    </div>
                    <div fxFlex="100" class="text-truncate">
                      <p class="small mat-text-secondary mb-0 lineWrap" [innerHTML]="(message?.smsMessage?.textMessage.length > 60)? (message?.smsMessage?.textMessage | slice:0:60)+'...':(message?.smsMessage?.textMessage )">
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngSwitchCase="messageTypesEnum.Savvy" class="w-100">
              </div>
              <div *ngSwitchDefault class="w-100">Message type not implemented yet</div>
            </div>
          </mat-list-item>

          <!-- <mat-list-item class="border-bottom list-item-h">
            <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign=" center">
              <div class="avatar avatar-40 border-2 rounded-circle coverimg " fxFlex="nogrow"
                style="background-image: url('assets/images/customer-profile.png');"></div>
              <div fxFlex fxLayout="row wrap">
                <div fxFlex="">
                  <p class="mb-0">
                    <span class="fw-bold">Amit Sinha</span>
                    <span><small class="mat-text-secondary"><span class="status-offline"></span>Offline</small></span>
                  </p>
                </div>
                <div fxFlex="nogrow"><small class="mat-text-secondary">5 days ago</small>
                </div>
                <div fxFlex="100" class="text-truncate">
                  <p class="small mat-text-secondary mb-0">Hello, There are new offer we
                    got here for y...</p>
                </div>
              </div>
            </div>
          </mat-list-item> -->

        </mat-list>
      </mat-tab>
      <!-- <mat-tab label="Favorite"> </mat-tab> -->
    </mat-tab-group>

  </mat-card-content>

</mat-card>