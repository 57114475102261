import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ElementControlService } from '../ElementControlService';
import { ContextIdDto, EicompService, ElementInstanceDto, IdNameTupleDto, LinkedEntityDefinitionDto } from '@savvy/entity-instance-composite';
import { ChangeListener } from '../changeListener';
import { EntitysService } from '@savvy/entity-instance';
import { Router } from '@angular/router';
import { AddEntityDialogComponent } from './addEntityDialog.component';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { EventBusService } from '../EventBusService';
import { CachedlistcompService, LoadEiTypeaheadResponse } from '@savvy/cached-view';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import * as _ from 'lodash';
import { ClearObservable } from '../../shared/classes/clear-observable';
import { takeUntil } from 'rxjs/operators';
import { EntityChangeEvent, FieldValueDto } from '@savvy/entity-instance-composite';

@Component({
  selector: 'app-linked-entity-instance-multi-typeahead',
  styleUrls: ['linkedEntityInstanceMultiTypeahead.component.scss'],
  templateUrl: 'linkedEntityInstanceMultiTypeahead.component.html'
})
export class LinkedEntityInstanceMultiTypeaheadComponent extends ClearObservable implements OnInit {

  @Input() linkedEntityDefinition: LinkedEntityDefinitionDto;
  @Input() contextIdDto: ContextIdDto;
  @Input() elementInstanceDto: ElementInstanceDto;
  @Input() entityInstanceId: string;
  @Input() additionalDataMapDto: any;


  @Input() form: UntypedFormGroup;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;

  eventBus = new EventBusService();
  entityInstanceIdFieldValueTupleDtos: IdNameTupleDto[] = new Array(0);

  visible = true;
  selectable = false;
  removable = true;
  addOnBlur = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  petsCtrl = new UntypedFormControl('', Validators.required);
  pets: IdNameTupleDto[] = [];
  selectedPetsIds: string[] = [];
  private timeout;

  @ViewChild('fruitInput', { static: false }) fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;


  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
    private cachedListCompositeApi: CachedlistcompService,
    private eiApi: EntitysService,
    private eiCompositeApi: EicompService,
    private ecs: ElementControlService,
    private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.cdr.detectChanges();
    if (this.elementInstanceDto.fieldInstanceDto.value.strArrayValue
      && this.elementInstanceDto.fieldInstanceDto.value.strArrayValue.length > 0) {
      const convertedList = this.elementInstanceDto.fieldInstanceDto.value.strArrayValue.join(',');

      console.log('convertedList is ' + convertedList);

      this.cachedListCompositeApi.loadEisTypeahead(
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        convertedList,
        this.linkedEntityDefinition.targetEntityDefinitionId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(response => {
          console.log('got response');
          this.pets = response.entityInstanceIdFieldValueTupleDtos;

          this.selectedPetsIds = _.map(this.pets, (item) => item.id);

          console.log('selectedPetsIds is ' + this.selectedPetsIds);

        });
    }
  }


  displayFn(id) {
    if (!id) {
      return '';
    }
    const index = this.entityInstanceIdFieldValueTupleDtos.findIndex(state => state.id === id);
    if (index === -1) {
      console.log('id is ' + id + ' index is' + index + ' this should never happen as tuples should always be there first');
      return '';
    } else {
      return this.entityInstanceIdFieldValueTupleDtos[index].name;
    }
  }

  entityCreated(entityInstanceId: string) {
    console.log('entity created with id ', entityInstanceId);
    if (!this.elementInstanceDto.fieldInstanceDto.value.strArrayValue) {
      this.elementInstanceDto.fieldInstanceDto.value.strArrayValue = [];
    }
    this.elementInstanceDto.fieldInstanceDto.value.strArrayValue.push(entityInstanceId);
    this.updateAndLoadNewEntity(entityInstanceId);
  }

  updateAndLoadNewEntity(entityInstanceId: string) {
    this.eiCompositeApi.entityInstanceChangeEvent(
      this.createUpdateReq(this.elementInstanceDto.fieldInstanceDto.value.strArrayValue))
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        response => {
          this.ecs.handleChangedElements(response.changedElementList, this.form);
          if (this.changeListener) {
            this.changeListener.add('change');
          }
          this.loadTypeaheadAndSet(entityInstanceId);
        }, err => {
          console.log('error while updateAndLoadNewEntity', err);
        });
  }

  handler(event: MatAutocompleteSelectedEvent): void {
    this.form.controls[this.elementInstanceDto.instanceId].setValue(event.option.value);
    this.form.controls[this.elementInstanceDto.instanceId].setErrors(null);
    if (this.form.controls[this.elementInstanceDto.instanceId].value) {
      const newLinkedEiId = this.form.controls[this.elementInstanceDto.instanceId].value;
      this.update(newLinkedEiId);
    }
  }

  private loadTypeaheadAndSet(entityInstanceId: string) {
    this.cachedListCompositeApi.loadSingleEiTypeahead(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      entityInstanceId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        if (response.entityInstanceIdFieldValueTupleDto) {
          this.entityInstanceIdFieldValueTupleDtos = new Array(1);
          this.entityInstanceIdFieldValueTupleDtos[0] = response.entityInstanceIdFieldValueTupleDto;
          this.addNewFruit(response.entityInstanceIdFieldValueTupleDto);
        }
      });
  }

  loadTypeahead(searchString: string) {
    if (searchString && searchString.length > 0) {
      this.cachedListCompositeApi.listForTypeahead(
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.linkedEntityDefinition.targetEntityDefinitionId,
        searchString)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          this.setResponse(result);
        }, error => {
          console.log('error while loadTypeahead', error);
        });
    }
  }

  setResponse(result: LoadEiTypeaheadResponse) {
    this.entityInstanceIdFieldValueTupleDtos = result.entityInstanceIdFieldValueTupleDtos;
  }


  onKey(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.form.controls[this.elementInstanceDto.instanceId].setErrors({ doesNotSelected: true });
      this.loadTypeahead(event.target.value);
    }, 500);
  }

  update(linkedIds: string[]) {
    this.eiCompositeApi.entityInstanceChangeEvent(this.createUpdateReq(linkedIds))
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.ecs.handleChangedElements(response.changedElementList, this.form);
        if (this.changeListener) {
          this.changeListener.add('change');
        }
        if (!this.pets.length) {
          this.form.controls[this.elementInstanceDto.instanceId].setErrors(Validators.required);
        } else {
          this.form.controls[this.elementInstanceDto.instanceId].setValue(this.pets);
          this.form.controls[this.elementInstanceDto.instanceId].setErrors(null);
        }
      }, err => {
        console.log('error while update', err);
      });
  }

  createUpdateReq(linkedIds: string[]): EntityChangeEvent {
    const fieldValue: FieldValueDto = {
      strArrayValue: linkedIds,
      valueType: FieldValueDto.ValueTypeEnum.StringArray
    };

    return {
      contextIdDto: this.contextIdDto,
      fieldValue,
      entityInstanceId: this.entityInstanceId,
      fieldInstanceId: this.elementInstanceDto.instanceId
    } as EntityChangeEvent;
  }

  getPlaceholder() {
    if (this.showPlaceholder) {
      return this.linkedEntityDefinition.placeholder;
    }
    return '';
  }

  addNewFruit(tuple: IdNameTupleDto) {
    if (tuple) {
      this.pets.push(tuple);
      this.selectedPetsIds.push(tuple.id);

      this.update(this.selectedPetsIds);
    }
    this.petsCtrl.setValue(null);
  }

  selected(tuple: IdNameTupleDto): void {
    if (!_.find(this.pets, tuple)) {
      this.pets.push(tuple);
      this.selectedPetsIds.push(tuple.id);

      this.update(this.selectedPetsIds);
    }

    this.fruitInput.nativeElement.value = '';
    this.petsCtrl.setValue(null);
  }

  remove(pet: IdNameTupleDto): void {
    this.pets = _.filter(this.pets, (item) => item.id !== pet.id);
    this.selectedPetsIds = _.map(this.pets, (item) => item.id);

    this.update(this.selectedPetsIds);
  }

  createNewLinkedEntity() {
    const dialogRef = this.dialog.open(AddEntityDialogComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        entityDefinitionId: this.linkedEntityDefinition.targetEntityDefinitionId,
        entityDefinitionLabel: this.linkedEntityDefinition.label,
        eventBus: this.eventBus
      }
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this.destroy$)).subscribe(result => {
        console.log('back from dialog close');
        if (result && result.entityInstanceId) {
          console.log('got a new instance to link to ', result);
          this.entityCreated(result.entityInstanceId);
        }
      });
  }
}

