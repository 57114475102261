<mat-toolbar color="primary" class="mdc-mb-2" *ngIf="showTitle">
  <mat-toolbar-row>
    {{'Sent Messages' | translate}}
  </mat-toolbar-row>
</mat-toolbar>
<!--  -->
<div class="mdc-px-2" *ngIf="spamEmail">
  <div class="alert alert-danger">
    <p>{{'EMAIL_IN_SPAM_DESC' | translate}}</p>
  </div>
</div>
<div *ngIf="deviceService.isMobile() || deviceService.isTablet()" class="search-results mdc-px-2" infiniteScroll
  [infiniteScrollDistance]="scrollDistance" [infiniteScrollUpDistance]="scrollUpDistance" [scrollWindow]="false"
  [infiniteScrollThrottle]="throttle" (scrolled)="onScrollDown($event)">
  <!-- mobile view -->
  <div fxLayout="row wrap">
    <!-- mat-card repeat -->
    <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="33" *ngFor="let row of sentMessages">
      <mat-card appearance="outlined" class="mdc-mb-1">
        <mat-card-header>
          <mat-card-title>{{row.sentDate | localizedDate: 'mediumDate'}} at {{row.sentTimeLocal |
            localizedTime:'shortTime'}}</mat-card-title>
          <mat-card-subtitle>{{'SENT_DATE' | translate}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <!-- recipient only-->
          <div class="mdc-mb-1">
            <p class='mdc-mb-0' *ngIf="row.messageType === 'EMAIL'">
              <span class="mat-text-secondary fw-normal mr-5px">{{'RECIPIENT' | translate}} </span>
              <span class="chiptag theme vm"><mat-icon>email</mat-icon> {{row.recipient }} </span>
            </p>
            <p class='mdc-mb-0' *ngIf="row.messageType === 'SMS'">
              <span class="mat-text-secondary fw-normal mr-5px">{{'RECIPIENT' | translate}}: </span>
              <span class="chiptag theme-accent vm"><mat-icon>phone</mat-icon> {{row.recipient }}</span>
            </p>

          </div>

          <p style="word-break:break-word"><span class="mat-text-secondary mr-5px">{{'MESSAGE' | translate}}: </span> {{
            row.message }}</p>

          <p class="appointment-status">
            <span class="mat-text-secondary mr-5px">{{'STATUS' | translate}}: </span>
            <!-- any one show -->
            <span class="text-center status paid-status d-inline-block w-auto">{{ row.status }}</span>
            <!-- one show -->
            <!-- <span class="text-center status unpaid-status d-inline-block w-auto">Sent</span> -->
          </p>
        </mat-card-content>
      </mat-card>
    </div>
    <!-- mat-card repeat ends -->
  </div>
  <!-- mobile view ends -->
</div>


<ngx-datatable
  *ngIf="!deviceService.isMobile() && !deviceService.isTablet() && sentMessages && sentMessages?.length > 0"
  class='material mdc-m-1' [columnMode]="'flex'" [rows]='sentMessages' [headerHeight]="50" [rowHeight]="'auto'"
  [footerHeight]="50" [externalPaging]="true" [count]="page.totalElements" [offset]="page.pageNumber"
  [limit]="page.size" (select)="onSelect($event)" (page)='setPage($event)'>

  <ngx-datatable-column name="{{'SENT_DATE' | translate}}" [sortable]="true" [draggable]="true" [flexGrow]="1"
    prop="sentDate">
    <ng-template ngx-datatable-cell-template let-row="row">
      <h6 class="mdc-mb-0">{{row.sentTimeLocal | localizedTime:'shortTime'}} </h6>
      <p class="mat-text-secondary">{{row.sentDate | localizedDate: 'mediumDate'}}</p>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Type" [sortable]="true" [draggable]="true" [flexGrow]="1">
    <ng-template ngx-datatable-cell-template let-row="row">
      <span *ngIf="row.messageType === 'EMAIL'">
        <div class="message-status email"><mat-icon>mail</mat-icon> Email</div>
      </span>
      <span *ngIf="row.messageType === 'SMS'">
        <div class="message-status sms"><mat-icon>sms</mat-icon> SMS</div>
      </span>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="{{'MESSAGE' | translate}}" [flexGrow]="2" [resizeable]="true" [sortable]="true"
    [draggable]="true" [canAutoResize]="true" [flexGrow]="1" prop="message">
    <ng-template ngx-datatable-cell-template let-row="row">
      {{ row.message }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="{{'RECIPIENT' | translate}}" [flexGrow]="1" [resizeable]="true" [sortable]="true"
    [draggable]="true" [canAutoResize]="true" [flexGrow]="1" prop="recipient">
    <ng-template ngx-datatable-cell-template let-row="row">

      <span *ngIf="row.messageType === 'EMAIL'">
        <a class="chiptag theme vm" href="mailto: {{ row.recipient }} ">
          <mat-icon class="vm">mail</mat-icon>{{ row.recipient }}
        </a>
      </span>
      <span *ngIf="row.messageType === 'SMS'">
        <a class="chiptag theme-accent vm" href="tel: {{ row.recipient }} ">
          <mat-icon class="vm">phone</mat-icon>{{ row.recipient }}
        </a>
      </span>

    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{'STATUS' | translate}}" [flexGrow]="1" [resizeable]="true" [sortable]="true"
    [draggable]="true" [canAutoResize]="true" [flexGrow]="1" prop="status">
    <ng-template ngx-datatable-cell-template let-row="row">
      <!-- {{ row.status }} -->
      <div class="message-status sent" *ngIf="row.status === 'SENT'"><mat-icon>check</mat-icon> {{ row.status }}</div>
      <div class="message-status delivered" *ngIf="row.status === 'DELIVERED'">
        <mat-icon>done_all</mat-icon> {{ row.status }}
      </div>
      <div class="message-status failed" *ngIf="row.status === 'FAILED'"><mat-icon>warning</mat-icon> {{ row.status }}
      </div>
    </ng-template>
  </ngx-datatable-column>
  <!--ngx-datatable-column name="{{'USER' | translate}}" [flexGrow]="1" [resizeable]="true" [sortable]="true"
    [draggable]="true" [canAutoResize]="true" [flexGrow]="1" prop="userFullName">
    <ng-template ngx-datatable-cell-template let-row="row">
      <app-recipient-name [recipientId]="row.recipientId" [recipientType]="row.recipientType">

      </app-recipient-name>
    </ng-template>
  </ngx-datatable-column-->
</ngx-datatable>

<div class="flexbox-parent" *ngIf="sentMessages && sentMessages?.length === 0">
  <div fxLayout="row" fxLayoutAlign="center center" style="height: 30vh;  ">
    <div style="text-align:center;">
      <div class="avatar avatar-100 circle border-3 mb-2 d-inline-block">
        <mat-icon class="h1 h-auto w-auto">mark_chat_read</mat-icon>
      </div>
      <!-- <app-zero-image name="email"></app-zero-image><br> -->
      <h6 class="ma-0">{{'NO_SENT_MESSAGES' | translate}}</h6><br>
    </div>
  </div>
</div>
