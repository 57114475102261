import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ContextService } from '../context.service';
import { FloSnackbarComponent } from '../snackbar/floSnackbar.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { ReminderDefService } from '@savvy/reminders';
import {
  ContextIdDto,
  ElementDefinitionSummaryDto
} from '@savvy/entity-definition';
import { TimeUtils } from '../dates/TimeUtils';
import { TranslateService } from '@ngx-translate/core';
import { NG_VALIDATORS } from '@angular/forms';
import { SenderidValidator } from '../validators/senderid.validator';
import { ReminderdefscompService } from '@savvy/reminders';
import { ConfirmationModalComponent } from '../shared/components/confirmation-modal/confirmation-modal.component';
import {
  CreateReminderDefinition,
  DaysAfterSchedule, DaysBeforeSchedule,
  OnSameDayOfYearSchedule, ReminderDefinition, ReminderSchedule, UpdateReminderDefinition,
  Link
} from '@savvy/reminders';
import { AppointmentQuickLinksEditorComponent } from '../editor/appointment-quick-links-editor/appointment-quick-links-editor.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-edit-reminder-def',
  templateUrl: './addEditReminderDef.component.html',
  providers: [
    { provide: NG_VALIDATORS, useExisting: SenderidValidator, multi: true },
  ]
})
export class AddEditReminderDefComponent implements OnInit, OnDestroy {

  @ViewChild('reminderDefPanel', { static: false }) reminderDefPanel: MatSidenav;

  @ViewChild('emailQuicklinksMessageEditor', { static: false }) emailQuicklinksMessageEditor: AppointmentQuickLinksEditorComponent;
  @ViewChild('quickLinksEditorV2Component', { static: false }) quickLinksEditorV2Component: AppointmentQuickLinksEditorComponent;

  @Input() reminderDef: ReminderDefinition;
  @Input() contextIdDto: ContextIdDto;
  @Input() templateType: 'email' | 'sms' = 'email';

  @Output() reminderAdded = new EventEmitter();
  @Output() reminderDeleted = new EventEmitter();
  @Output() reminderCancelled = new EventEmitter();
  defaultDay: number = 1;

  timeToSendArr: Array<Date> = [];
  subscriptions: Subscription[] = [];
  activeIndex = 0;
  constructor(
    private timeUtils: TimeUtils,
    private reminderApi: ReminderDefService,
    private translateService: TranslateService,
    private notify: FloSnackbarComponent,
    private contextService: ContextService,
    private dialog: MatDialog) {
  }

  ngOnInit() {
    console.log('loading');
    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
        this.setTimeToSend();
      }
    }));
  }

  selectedTabChange(event) {
    console.log(event.index);
    this.activeIndex = event.index;
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  setTimeToSend() {

    console.log('setTimeToSend');

    if (this.reminderDef && this.reminderDef.reminderScheduleList) {

      console.log('got a schedule list');
      this.timeToSendArr = [];

      this.reminderDef.reminderScheduleList.forEach((item, index) => {
        this.timeToSendArr[index] = this.getDate(item);
      });
    } else {
      console.log('no schedule list so setting empty array');
      this.timeToSendArr = [new Date()];
    }

    console.log('leaving setTimeToSend');

  }

  getDate(reminderSchedule: ReminderSchedule): Date {
    if (!reminderSchedule) {
      return new Date();
    }
    return this.timeUtils.getDateFromTime(reminderSchedule.timeToSend);
  }

  cancel() {
    this.reminderCancelled.emit('reminderCancelled');
  }

  updateTime(timeAsDate, reminderSchedule: ReminderSchedule) {
    const timeAsStr = this.timeUtils.getHoursMinsSecsTimeString(timeAsDate);
    console.log('got timeAsStr:' + timeAsStr);
    reminderSchedule.timeToSend = timeAsStr;
  }

  update() {
    this.prepareReminder();
    const req = <UpdateReminderDefinition>{};
    req.contextIdDto = this.contextIdDto;
    req.reminderDefinition = this.reminderDef;

    this.reminderApi.updateReminderDef(req).subscribe(
      reminderDef => {
        console.log('got reminderDef ' + reminderDef);
        this.reminderDef = reminderDef;
        this.translateService.get('Updated Reminder')
          .subscribe(val => {
            this.notify.message = val;
            this.notify.open();
          });
      }
    );
  }

  create() {
    this.prepareReminder();
    const req = <CreateReminderDefinition>{};
    req.contextIdDto = this.contextIdDto;
    req.reminderDefinition = this.reminderDef;

    console.log('reminderDef entity def id is ' + this.reminderDef.entityInstanceReminder.entityDefinitionId);

    this.reminderApi.createReminderDef(req).subscribe(
      reminderDef => {
        console.log('got reminderDef ' + reminderDef);
        this.reminderDef = reminderDef;
        this.translateService.get('Created Reminder')
          .subscribe(val => {
            this.notify.message = val;
            this.notify.open();
          });
        this.reminderAdded.emit('reminderCreated:');
      }
    );
  }

  delete(reminderDef: ReminderDefinition) {
    this.prepareReminder();
    this.reminderApi.deleteReminderDef(
      reminderDef.id,
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType)
      .subscribe(response => {
        this.translateService.get('Deleted reminder definition')
          .subscribe(val => {
            this.notify.message = val;
            this.notify.open();
          });
        this.reminderDeleted.emit('reminderDeleted:');
      }
      );
  }

  prepareReminder() {
    if (this.reminderDef.emailReminderDetails && this.activeIndex === 1) {
      this.reminderDef.emailReminderDetails.body = this.emailQuicklinksMessageEditor.getEditorText();
      this.reminderDef.emailReminderDetails.links = this.emailQuicklinksMessageEditor.getLinksFromQuicklinksData() as Link[];
    }
    if (this.reminderDef.smsReminderDetails && this.activeIndex === 2) {
      this.reminderDef.smsReminderDetails.body = this.quickLinksEditorV2Component.getEditorText();
      this.reminderDef.smsReminderDetails.links = this.quickLinksEditorV2Component.getLinksFromQuicklinksData() as Link[];
    }
  }

  addScheduleToList() {

    if (this.reminderDef.allowAnyScheduleType) {
      this.addDaysBeforeScheduleToList();
    } else if (this.reminderDef.allowedScheduleType === ReminderSchedule.ScheduleTypeEnum.DaysAfter) {
      this.addDaysAfterScheduleToList();
    } else if (this.reminderDef.allowedScheduleType === ReminderSchedule.ScheduleTypeEnum.DaysBefore) {
      this.addDaysBeforeScheduleToList();
    } else if (this.reminderDef.allowedScheduleType === ReminderSchedule.ScheduleTypeEnum.OnSameDayOfYear) {
      this.addSameDayScheduleToList();
    } else {
      this.addDaysBeforeScheduleToList();
    }
  }

  addSameDayScheduleToList() {
    console.log('addSameDayScheduleToList');
    const reminderSchedule = <ReminderSchedule>{};
    reminderSchedule.onSameDayOfYearSchedule = <OnSameDayOfYearSchedule>{};
    reminderSchedule.scheduleType = ReminderSchedule.ScheduleTypeEnum.OnSameDayOfYear;
    reminderSchedule.timeToSend = '12:00:00';
    this.reminderDef.reminderScheduleList.push(reminderSchedule);
    this.setTimeToSend();
  }

  addDaysBeforeScheduleToList() {
    console.log('addDaysBeforeScheduleToList');
    const reminderSchedule = <ReminderSchedule>{};
    reminderSchedule.daysBeforeSchedule = <DaysBeforeSchedule>{};
    reminderSchedule.scheduleType = ReminderSchedule.ScheduleTypeEnum.DaysBefore;
    reminderSchedule.timeToSend = '12:00:00';
    reminderSchedule.daysBeforeSchedule.numDaysBefore = this.defaultDay;
    this.reminderDef.reminderScheduleList.push(reminderSchedule);
    this.setTimeToSend();
  }

  addDaysAfterScheduleToList() {
    console.log('addDaysAfterScheduleToList');
    const reminderSchedule = <ReminderSchedule>{};
    reminderSchedule.daysAfterSchedule = <DaysAfterSchedule>{};
    reminderSchedule.scheduleType = ReminderSchedule.ScheduleTypeEnum.DaysAfter;
    reminderSchedule.timeToSend = '12:00:00';
    reminderSchedule.daysAfterSchedule.numDaysAfter = this.defaultDay;
    this.reminderDef.reminderScheduleList.push(reminderSchedule);
    this.setTimeToSend();
  }

  deleteSchedule(index: number) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        name: 'schedule'
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.reminderDef.reminderScheduleList.splice(index, 1);
        this.update();
      }
    });
  }
}
