<div class="view-invoice-page mdc-p-3" *ngIf="invoiceDto">
  <!-- <mat-dialog-content> -->

  <div fxLayout="row" fxLayoutWrap="wrap" class="mdc-mb-1">
    <div fxFlex="auto">
      <h2 class="mb-0"><strong>{{'INVOICE' | translate: {Default: 'Invoice'} }}</strong></h2>
      <p>{{'INVOICE_NO' | translate: {Default: 'Invoice No'} }}: <strong>{{invoice.invoiceNumber}}</strong></p>
      <a href="javascript:void(0)" *ngIf="invoice.companyDetails">{{invoice.companyDetails.companyEmail}}</a>
    </div>
    <div fxFlex="nogrow" *ngIf="!invoice.paidInFull && !viewOnly">
      <button mat-raised-button color="accent" (click)="editInvoice()" class="mr-05 d-inline-block">
        <mat-icon>edit</mat-icon>
        {{'EDIT_INVOICE' | translate: {Default: 'Edit Invoice'} }}
      </button>
    </div>
  </div>

  <div fxLayout="row" fxLayoutWrap="wrap" class="mb-2">
    <div fxFlex.gt-xs="50" fxFlex="100">
      <h4 class="my-0">{{getFullName()}}</h4>
      <div fxLayout="column">
        <span>{{'DATE_ISSUED' | translate: {Default: 'Date issued'} }}: {{invoice.invoiceDate | localizedDate: 'short'}}</span>
        <span>{{'DUE_DATE' | translate: {Default: 'Due date'} }}: {{invoice.dueDate | localizedDate: 'short'}}</span>
        <span>{{'VOID' | translate: {Default: 'Void'} }}: {{ invoice.invoiceVoid }}</span>
      </div>
    </div>
    <div fxFlex.gt-xs="50" fxFlex="100" class="text-sm-right text-xs-left">
      <h4 class="my-0" data-testid="orgName">{{orgDto?.name}}</h4>
      <h5 data-testid="orgVatRefNum" *ngIf="orgDto?.vatReferenceNumber" class="my-0">{{'VAT' | translate: {Default: 'VAT'} }}:{{orgDto?.vatReferenceNumber}}</h5>
      <div fxLayout="column">
        <span data-testid="orgMobileNum" *ngIf="orgDto?.phoneNumber">{{orgDto?.phoneNumber?.internationalNumber}}</span>
        <span data-testid="orgAddress1" *ngIf="orgDto?.address?.address1">{{orgDto?.address?.address1}}</span>
        <span data-testid="orgAddress2" *ngIf="orgDto?.address?.address2">{{orgDto?.address?.address2}}</span>
        <span data-testid="orgCityAndZip" *ngIf="orgDto?.address?.city">{{orgDto?.address?.city}}</span>
      </div>
    </div>
    <div fxFlex.gt-xs="50" fxFlex="100" *ngIf="invoice.paidInFull">
      <img src="assets/images/invoice/invoicePaid.svg" />
      <!--
        <h1>{{invoice.datePaid |date:'medium'}}</h1>
        -->
    </div>
  </div>

  <div class="mdc-mb-1" *ngIf="invoiceDto && invoiceDto.showCourses">
    <h5 class="mdc-mb-1">{{'COURSES' | translate: {Default: 'Courses'} }}</h5>
    <ngx-datatable class="material m-0" [rows]="invoiceDto.invoice.invoiceCourseRows" [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'">
      <ngx-datatable-column name="{{'DESCRIPTION' | translate: {Default: 'Description'} }}" [flexGrow]="3">
        <ng-template ngx-datatable-cell-template let-row="row">
          <strong>{{row.name}}</strong><br /><span class="mat-text-muted">{{row.description}}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'UNIT_PRICE' | translate: {Default: 'Unit Price'} }}" [flexGrow]="1">
        <ng-template ngx-datatable-cell-template let-row="row">
          <strong>{{row.unitPrice | currency:currencyCode:'symbol'}}</strong>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'TAX_RATE' | translate: {Default: 'Tax Rate'} }}" [flexGrow]="0.6">
        <ng-template ngx-datatable-cell-template let-row="row">
          <strong>{{row.tax}}%</strong>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'QUANTITY' | translate: {Default: 'Quantity'} }}" [flexGrow]="1">
        <ng-template ngx-datatable-cell-template let-row="row">
          <strong>{{row.quantity}}</strong>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'DISCOUNT' | translate: {Default: 'Discount'} }}" [flexGrow]="0.6">
        <ng-template ngx-datatable-cell-template let-row="row">
          {{row?.discountDetails | discountTmpl:currencyCode}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'AMOUNT' | translate: {Default: 'Amount'} }}" [flexGrow]="1">
        <ng-template ngx-datatable-cell-template let-row="row">
          <!-- <strong>{{ row.grossPrice | currency:currencyCode:'symbol'}}</strong> -->
          <app-discount-price-view [originalPrice]="row.grossPriceNoOffer" [finalPrice]="row.grossPrice" [currencyCode]="currencyCode"></app-discount-price-view>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>

  <div class="mdc-mb-1" *ngIf="invoiceDto && invoiceDto.showProducts">
    <h5 class="mdc-mb-1">{{'PRODUCTS' | translate: {Default: 'Products'} }}</h5>
    <ngx-datatable class="material m-0" [rows]="invoiceDto.invoiceProductRowDtos" [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'">
      <ngx-datatable-column name="{{'DESCRIPTION' | translate: {Default: 'Description'} }}" [flexGrow]="2">
        <ng-template ngx-datatable-cell-template let-row="row">
          <strong>{{row.productName}}</strong><br /><span class="mat-text-muted">{{row.productDescription}}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'UNIT_PRICE' | translate: {Default: 'Unit Price'} }}" [flexGrow]="1">
        <ng-template ngx-datatable-cell-template let-row="row">
          <strong>{{row.unitPrice | currency:currencyCode:'symbol'}}</strong>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'TAX_RATE' | translate: {Default: 'Tax Rate'} }}" [flexGrow]="1">
        <ng-template ngx-datatable-cell-template let-row="row">
          <strong>{{row.tax}}%</strong>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'QUANTITY' | translate: {Default: 'Quantity'} }}" [flexGrow]="1">
        <ng-template ngx-datatable-cell-template let-row="row">
          <strong>{{row.quantity}}</strong>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'DISCOUNT' | translate: {Default: 'Discount'} }}" [flexGrow]="1">
        <ng-template ngx-datatable-cell-template let-row="row">
          {{row?.discountDetails | discountTmpl:currencyCode}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'AMOUNT' | translate: {Default: 'Amount'} }}" [flexGrow]="1">
        <ng-template ngx-datatable-cell-template let-row="row">
          <!-- <strong>{{ row.grossPrice | currency:currencyCode:'symbol'}}</strong> -->
          <app-discount-price-view [originalPrice]="row.grossPriceNoOffer" [finalPrice]="row.grossPrice" [currencyCode]="currencyCode"></app-discount-price-view>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>

  <div class="mdc-mb-1" *ngIf="invoiceDto && invoiceDto.showServices">
    <h5 class="mdc-mb-1">{{'SERVICES' | translate: {Default: 'Services'} }}</h5>
    <ngx-datatable class="material m-0" [rows]="invoiceDto.invoiceServiceRowDtos" [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'">
      <ngx-datatable-column name="{{'DESCRIPTION' | translate: {Default: 'Description'} }}" [flexGrow]="2">
        <ng-template ngx-datatable-cell-template let-row="row">
          <strong>{{row?.serviceName}}</strong><br /><span class="mat-text-muted">{{row.serviceDescription}}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'UNIT_PRICE' | translate: {Default: 'Unit Price'} }}" [flexGrow]="1">
        <ng-template ngx-datatable-cell-template let-row="row">
          <strong>{{row.unitPrice | currency:currencyCode:'symbol'}}</strong>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'TAX_RATE' | translate: {Default: 'Tax Rate'} }}" [flexGrow]="1">
        <ng-template ngx-datatable-cell-template let-row="row">
          <strong>{{row.tax}}%</strong>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'QUANTITY' | translate: {Default: 'Quantity'} }}" [flexGrow]="1">
        <ng-template ngx-datatable-cell-template let-row="row">
          <strong>{{row.quantity}}</strong>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'DISCOUNT' | translate: {Default: 'Discount'} }}" [flexGrow]="1">
        <ng-template ngx-datatable-cell-template let-row="row">
          {{row?.discountDetails | discountTmpl:currencyCode}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'AMOUNT' | translate: {Default: 'Amount'} }}" [flexGrow]="1">
        <ng-template ngx-datatable-cell-template let-row="row">
          <!-- <strong>{{ row.grossPrice | currency:currencyCode:'symbol'}}</strong> -->
          <app-discount-price-view [originalPrice]="row.grossPriceNoOffer" [finalPrice]="row.grossPrice" [currencyCode]="currencyCode"></app-discount-price-view>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>

  <div class="mdc-mb-1" *ngIf="invoiceDto && invoiceDto.showItemRows">
    <h5 class="mdc-mb-1" *ngIf="invoiceTypes?.indexOf('BOARDING') > -1 || invoiceTypes?.indexOf('DAY_CARE')">{{'RUNS' | translate: {Default: 'Runs'} }}</h5>
    <ngx-datatable class="material m-0" [rows]="invoiceDto.invoice.invoiceItemRows" [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="0" [rowHeight]="'auto'">
      <ngx-datatable-column name="{{'DESCRIPTION' | translate: {Default: 'Description'} }}" [flexGrow]="2">
        <ng-template ngx-datatable-cell-template let-row="row">
          <ng-container *ngIf="row?.itemRowType === 'petRun'">
            <strong>{{row.name}}</strong><br /><span class="mat-text-muted">{{row.description}}</span>
          </ng-container>
          <ng-container *ngIf="row?.itemRowType === 'petRunFeed'">
            <strong>Feeding Charge</strong>
          </ng-container>
          <ng-container *ngIf="row?.itemRowType === 'petRunMedicine'">
            <strong>Medication Charge</strong>
          </ng-container>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'UNIT_PRICE' | translate: {Default: 'Unit Price'} }}" [flexGrow]="1">
        <ng-template ngx-datatable-cell-template let-row="row">
          <strong>{{row.unitPrice | currency:currencyCode:'symbol'}}</strong>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'TAX_RATE' | translate: {Default: 'Tax Rate'} }}" [flexGrow]="1">
        <ng-template ngx-datatable-cell-template let-row="row">
          <strong>{{row.tax}}%</strong>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'QUANTITY' | translate: {Default: 'Quantity'} }}" [flexGrow]="1">
        <ng-template ngx-datatable-cell-template let-row="row">
          <strong>{{row.quantity}}</strong>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'DISCOUNT' | translate: {Default: 'Discount'} }}" [flexGrow]="1">
        <ng-template ngx-datatable-cell-template let-row="row">
          {{row?.discountDetails | discountTmpl:currencyCode}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{'AMOUNT' | translate: {Default: 'Amount'} }}" [flexGrow]="1">
        <ng-template ngx-datatable-cell-template let-row="row">
          <!-- <strong>{{ row.grossPrice | currency:currencyCode:'symbol'}}</strong> -->
          <app-discount-price-view [originalPrice]="row.grossPriceNoOffer" [finalPrice]="row.grossPrice" [currencyCode]="currencyCode"></app-discount-price-view>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>

  <div class="mdc-mb-1" fxLayout="column">
    <div *ngIf="postalCharges" fxFlex="100" class="mt-1 mdc-mb-1">
      <h5 class="ma-0 text-bold">{{'POSTAL_CHARGES' | translate}}: <b>{{ postalCharges |
          currency:currencyCode:'symbol' }}</b></h5>
    </div>
    <div *ngIf="invoice.discountId" fxLayout="column" fxLayout.gt-xs="column" fxLayout.gt-sm="row" fxLayoutWrap="wrap">
      <div fxFlex="100" class="mt-1 mdc-mb-1">
        <strong>{{discountItemsById[invoice.discountId]?.code || 'CUSTOM'}}</strong> - {{invoice?.discountDetails |
        discountTmpl:currencyCode}}
      </div>
    </div>
    <div fxLayout="column" fxLayout.gt-xs="column" fxLayout.gt-sm="row" fxLayoutWrap="wrap">
      <div fxFlex.gt-sm="{{ invoice.totalTips > 0 ? '20' : '25'}}" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
        <h6 class="text-uppercase text-md ma-0 text-bold">{{'SUBTOTAL' | translate: {Default: 'Subtotal'} }}</h6>
        <h2 class="ma-0">{{invoice.subTotalWithoutDiscount |
          currency:currencyCode:'symbol'}}</h2>
      </div>
      <div fxFlex.gt-sm="{{ invoice.totalTips > 0 ? '20' : '25'}}" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
        <h6 class="text-uppercase text-md ma-0 text-bold">{{'TAX' | translate: {Default: 'Tax'} }}</h6>
        <h2 class="ma-0">{{invoice.tax | currency:currencyCode:'symbol'}}</h2>
      </div>
      <div fxFlex.gt-sm="{{ invoice.totalTips > 0 ? '20' : '25'}}" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
        <h6 class="text-uppercase text-md ma-0 text-bold">{{'DISCOUNT' | translate: {Default: 'Discount'} }}</h6>
        <h2 class="ma-0">{{invoice.discountTotal | currency:currencyCode:'symbol'}}</h2>
      </div>
      <div *ngIf="!postalCharges" fxFlex.gt-sm="{{ invoice.totalTips > 0 ? '20' : '25'}}" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1 text-xs-left text-sm-right">
        <h6 class="text-uppercase text-sm ma-0 text-bold">{{'TOTAL' | translate: {Default: 'Total'} }}</h6>
        <h2 class="ma-0 mat-text-green">{{ invoice.total | currency:currencyCode:'symbol'}}</h2>
      </div>
      <div *ngIf="postalCharges" fxFlex.gt-sm="{{ invoice.totalTips > 0 ? '20' : '25'}}" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1 text-xs-left text-sm-right">
        <h6 class="text-uppercase text-sm ma-0 text-bold">{{'TOTAL' | translate: {Default: 'Total'} }}</h6>
        <h2 class="ma-0 mat-text-green">{{ invoice.total + postalCharges | currency:currencyCode:'symbol'}}</h2>
      </div>
      <div *ngIf="invoice.totalTips > 0" fxFlex.gt-sm="{{ invoice.totalTips > 0 ? '20' : '25'}}" fxFlex.gt-xs="50" fxFlex="100" class="{{ invoice.totalTips > 0 ? 'mt-1 mdc-mb-1 text-xs-left text-sm-right' : 'mt-1 mdc-mb-1 text-xs-left'}}">
        <h6 class="text-uppercase text-sm ma-0 text-bold">{{'TIPS' | translate: {Default: 'Total'} }}</h6>
        <h2 class="ma-0 mat">{{ invoice.totalTips | currency:currencyCode:'symbol'}}</h2>
      </div>
    </div>
  </div>

  <mat-card appearance="outlined" class="new-card py-1" *ngIf="invoice.invoiceTerms">
    <p class="text-md">
      <strong>
        {{'PAYMENT TERMS AND POLICIES' | translate: {Default: 'Payment Terms and Policies'} }}
      </strong>
      {{invoice.invoiceTerms | translate}}
    </p>
  </mat-card>

  <div fxLayout="row wrap" *ngIf="invoice && invoicePaymentStatus" class="">
    <div *ngIf="!postalCharges" fxFlex.gt-sm="33" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
      <h5 class="ma-0 text-bold">{{'AMOUNT_PAID' | translate}}: <b>{{ invoicePaymentStatus.amountPaid |
          currency:currencyCode:'symbol' }}</b></h5>
    </div>


    <div *ngIf="invoice?.paidInFull" fxFlex.gt-sm="33" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
      <h5 class="ma-0 mat-text-primary text-bold">{{'INVOICE_STATUS' | translate}} <b>{{'PAID' | translate}}</b></h5>
    </div>

    <div *ngIf="!invoice?.paidInFull && viewInvoicePaymentStatusDataDto?.depositStatus" fxFlex.gt-sm="33" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
      <h5 class="ma-0 mat-text-primary text-bold">{{'STATUS_:' | translate: {Default: 'Status: '} }}<b>{{ viewInvoicePaymentStatusDataDto?.depositStatus }}</b>
      </h5>
    </div>
    <div *ngIf="hasSavvyPayPayments && viewInvoicePaymentStatusDataDto?.noShowPolicyEnabled && !invoice?.paidInFull" fxFlex.gt-sm="33" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
      <h5 class="ma-0 text-bold">{{'NO_SHOW_CHARGE' | translate: {Default: 'No Show Charge: '} }}<b>{{ ((viewInvoicePaymentStatusDataDto.noShowPolicyCharge / 100) *
          viewInvoicePaymentStatusDataDto.totalAmount) |
          currency:currencyCode:'symbol' }}</b></h5>
      <mat-hint>
        {{'NO_SHOW_CHARGE_IS' | translate: {Default: 'No show charge is '} }} {{ viewInvoicePaymentStatusDataDto.noShowPolicyCharge }} {{'PERCENT_OF_TOTAL:' | translate: {Default: '% of the total amount.'} }}
      </mat-hint>
    </div>
    <div *ngIf="hasSavvyPayPayments && viewInvoicePaymentStatusDataDto.noShowPolicyEnabled && !invoice?.paidInFull" fxFlex.gt-sm="33" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
      <h5 class="ma-0 text-bold">{{'LATE_CANCELLATION_CHARGE' | translate: {Default: 'Late Cancellation Charge: '} }}<b>{{
          ((viewInvoicePaymentStatusDataDto.lateCancellationCharge / 100) *
          viewInvoicePaymentStatusDataDto.totalAmount)
          |
          currency:currencyCode:'symbol' }}</b></h5>
      <mat-hint *ngIf="viewInvoicePaymentStatusDataDto.lateCancellationRemainingDays <= 0">
        {{'LATE_CANCELLATION_CHARGE_IS' | translate: {Default: 'Late cancellation charge is '} }} {{ viewInvoicePaymentStatusDataDto.lateCancellationCharge }} {{'PERCENT_OF_TOTAL:' | translate: {Default: '% of the total amount.'} }}
        amount.
      </mat-hint>
      <mat-hint *ngIf="viewInvoicePaymentStatusDataDto.lateCancellationRemainingDays > 0">
        {{ viewInvoicePaymentStatusDataDto.lateCancellationRemainingDays }} {{'DAYS_REMAINING_BEFORE_CHARGE_APPLIED' | translate: {Default: 'days remaining before late cancellation charge can be applied.'} }}
      </mat-hint>
    </div>
    <div fxFlex align="right" *ngIf="(!hasSavvyPayPayments || viewInvoicePaymentStatusDataDto.depositStatus !== null)  && !invoice?.paidInFull && !viewOnly">
      <button *ngIf="paymentSettings?.savvyPaySettings === null || paymentSettings?.savvyPaySettings?.accountHolderCode === null" mat-raised-button color="primary" (click)="goToSavvyPaySettings()">{{'SETUP_SAVVY_PAY' | translate: {Default: 'Setup Savvy Pay'} }}
      </button>
    </div>
  </div>

  <div *ngIf="hasSavvyPayPayments && !viewOnly" class="mt-15px">
    <form *ngIf="!invoice?.paidInFull" #paymentForm="ngForm" fxLayout="column">
      <mat-form-field appearance="outline">
        <mat-hint>
          {{'ENTER_AMOUNT_DESC' | translate: {Default: 'Enter the amount to be taken. Amount can be greater than the total amount.'} }}
        </mat-hint>
        <mat-label>Amount</mat-label>
        <input matInput [(ngModel)]="amountOutstanding" name="amount" [required]="true" #amount="ngModel" id="amount" placeholder="{{'ENTER_AMOUNT' | translate: {Default: 'Enter Amount'} }}" style="width: 100%">
      </mat-form-field>

      <br>
      <mat-card-actions>
        <button mat-raised-button color="accent" (click)="takePayment()" [disabled]="!paymentForm.valid || submitting">
          {{'TAKE_PAYMENT' | translate: {Default: 'Take Payment'} }}
        </button>

        <button *ngIf="viewInvoicePaymentStatusDataDto.noShowPolicyEnabled" mat-raised-button color="accent" (click)="chargeNoShow()" [disabled]="submitting">{{'CHARGE_NO_SHOWS' | translate: {Default: 'Charge No Shows'} }}</button>

        <button *ngIf="viewInvoicePaymentStatusDataDto.noShowPolicyEnabled && viewInvoicePaymentStatusDataDto.lateCancellationRemainingDays <= 0" mat-raised-button color="accent" (click)="chargeLateCancellation()" [disabled]="submitting">
          {{'CHARGE_LATE_CANCELLATION' | translate: {Default: 'Charge Late Cancellation'} }}
        </button>
      </mat-card-actions>

    </form>
  </div>

  <!-- </mat-dialog-content> -->

  <!-- <button color="accent" mat-raised-button (click)="gotoInvoice()">View Full Invoice</button> -->


  <!-- hide on page -->
  <div class=" my-2" *ngIf="!viewOnly">

    <button [disabled]="!paymentSettings?.paymentSetup" mat-raised-button color="accent" (click)="sendInvoice()" class="mr-05 d-inline-block mdc-py-1">
      <mat-icon>receipt_long</mat-icon>
      {{'SEND_INVOICE' | translate: {Default: 'Send Invoice'} }}
    </button>
    <button *ngIf="invoice.id && !invoice.invoiceVoid" mat-raised-button color="accent" (click)="voidInvoice()" class="mr-05 d-inline-block mdc-py-1">
      <mat-icon>block</mat-icon>
      {{'VOID_INVOICE' | translate: {Default: 'Void Invoice'} }}
    </button>

    <button *ngIf="!invoice?.paidInFull" mat-raised-button color="accent" (click)="addPayment()" class="mr-05 mdc-my-1">
      <mat-icon>payments</mat-icon>
      {{'ADD_PAYMENT' | translate: {Default: 'Add Payment'} }}
    </button>

    <button *ngIf="!invoice?.paidInFull" mat-raised-button color="accent" (click)="paidFull('CASH')" class="mr-05 mdc-my-1">
      <mat-icon>store</mat-icon>
      {{'PAID_CASH' | translate: {Default: 'Paid Cash'} }}
    </button>

    <button *ngIf="!invoice?.paidInFull" mat-raised-button color="accent" (click)="paidFull('CARD_MACHINE')" class="mr-05 mdc-my-1">
      <mat-icon>payment</mat-icon>
      {{'PAID_CARD' | translate: {Default: 'Paid Card'} }}
    </button>

    <button *ngIf="!invoice?.paidInFull" mat-raised-button color="accent" (click)="paidFull('DIRECT_DEBIT')" class="mr-05 mdc-my-1">
      <mat-icon>payments</mat-icon>
      {{'PAID_DD' | translate: {Default: 'Paid Direct Debit'} }}
    </button>

    <button *ngIf="!invoice?.paidInFull" mat-raised-button color="accent" (click)="paidFull('BANK_TRANSFER')" class="mr-05 mdc-my-1">
      <mat-icon>account_balance</mat-icon>
      {{'PAID_BANK' | translate: {Default: 'Paid Bank'} }}
    </button>

    <!--  <button [disabled]="!paymentSettings-->
    <!--    || !paymentSettings.savvyPaySettings-->
    <!--    || !paymentSettings.savvyPaySettings.accountHolderCode-->
    <!--    || !customerDto-->
    <!--    || !customerDto.mobile-->
    <!--" mat-raised-button color="accent"-->
    <!--          (click)="showSmsLink()" class="mr-05">-->
    <!--    <mat-icon>money</mat-icon>-->
    <!--    {{'Send Payment Link' | translate}}-->
    <!--  </button>-->

    <button *ngIf="plans && plans.length > 0 && !invoice?.paidInFull" mat-raised-button color="accent" (click)="showPaidPlans()" class="mr-05 mdc-my-1">
      <mat-icon>card_membership</mat-icon>
      {{'PAID_PLANS' | translate: {Default: 'Paid Plans'} }}
    </button>
    <button [disabled]="!cardMachines || cardMachines.length <= 0" mat-raised-button color="accent" (click)="showTerminalPayment()" class="mr-05 mdc-my-1">
      <mat-icon>account_balance</mat-icon>
      {{'SAVVY_CARD_MACHINE' | translate: {Default: 'Savvy Card Machine'} }}
    </button><br>
    <mat-hint *ngIf="!paymentSettings?.paymentSetup"><a (click)="goToPaymentSettings()">{{"SETUP_SAVVY_PAYMENTS" | translate: {Default: 'Setup your Payment Settings here to start sending invoices.'} }}</a></mat-hint>
    <br>
    <mat-hint *ngIf="!cardMachines || cardMachines.length <= 0">
      <a (click)="goToSavvyPaySettings()">
        {{'SETUP_SAVVY_PAY_DESCRIPTION' | translate: {Default: 'Setup your Savvy Pay Card Machine here and start accepting in-person payments.'} }}
      </a>
    </mat-hint>

    <button mat-raised-button mat-dialog-close style="float: right;">{{'CLOSE' | translate}}</button>
  </div>


  <!-- <button mat-raised-button color="accent" *ngIf="invoice.id" (click)="sendInvoice()" class="mr-1">
      Send {{'Invoice' | translate}}
    </button>
    <button mat-raised-button color="accent" *ngIf="invoice.id && !invoice.invoiceVoid" (click)="voidInvoice()"
      class="mr-1">
      Void {{'Invoice' | translate}}
    </button> -->
  <!-- <mat-dialog-actions>


  </mat-dialog-actions> -->

</div>
