import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UiAuthService} from '../auth/ui-auth.service';
import {UntypedFormGroup} from '@angular/forms';
import {ElementControlService} from './ElementControlService';
import {ChangeListener} from './changeListener';
import {EventBusService} from './EventBusService';
import {NavChangeService} from './NavChangeService';
import {NavigateService} from '../entry/NavigateService';
import {FloSnackbarComponent} from '../snackbar/floSnackbar.component';
import { MatDialog } from '@angular/material/dialog';
import {Location} from '@angular/common';
import {ClearObservable} from '../shared/classes/clear-observable';
import {takeUntil} from 'rxjs/operators';
import { ContextIdDto } from '@savvy/quickbooks';
import {LoadEiViewResponse, ViewcompService} from '@savvy/view-composite';

@Component({
  selector: 'app-view-entity-simple',
  templateUrl: 'viewEntitySimple.component.html',
  providers: [ElementControlService]
})
export class ViewEntitySimpleComponent extends ClearObservable implements OnInit, OnChanges {

  @Input() entityInstanceId: string;
  @Input() contextIdDto: ContextIdDto;
  @Input() viewDefinitionId: string;
  @Input() eventBus: EventBusService;

  loadEiViewResponse: any;

  changeListener = new ChangeListener();

  public form: UntypedFormGroup; // our form model

  sub: any;
  active: boolean;
  widthIsDevice = 70;

  constructor(
              public auth: UiAuthService,
              private ecs: ElementControlService,
              private notify: FloSnackbarComponent,
              private navigateService: NavigateService,
              private location: Location,
              private dialog: MatDialog,
              private viewCompService: ViewcompService,
              private navChange: NavChangeService) {
    super();
  }

  ngOnInit(): void {
      this.loadEntity();
  }

  public loadEntity() {
    if (this.viewDefinitionId) {
      this.viewCompService.loadEiByViewDefId(this.entityInstanceId,
        this.viewDefinitionId,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType).pipe(takeUntil(this.destroy$)).subscribe(
        response => {
          this.display(response);
        }, err => {
          console.log('error while loadEntity', err);
        });
    } else {
      this.viewCompService.loadEiToView(this.entityInstanceId,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType).pipe(takeUntil(this.destroy$)).subscribe(
        response => {
          this.display(response);
        }, err => {
          console.log('error while loadEntity', err);
        });
    }
  }



  /**
   * So this will get called when the teasers are changed (for example)
   * but it will also get called when the label
   *
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...
    // console.log('change detected inside view entity');
    for (const propName in changes) {
      if (propName === 'entityInstanceId') {
        // if (cur !== prev) {
        // console.log('calling load entity due to change detection');
        this.loadEntity();
        // }
      }
    }
  }

  display(loadEiViewResponse: LoadEiViewResponse) {
    this.active = false;
    this.loadEiViewResponse = loadEiViewResponse;
    this.createForm();
    this.active = true;
    this.navChange.add('viewEntity:' +
      loadEiViewResponse.viewContextDto.addEditEntityInstanceContextDto.entityDefinitionDto.entityDefinitionId.id);
  }

  createForm() {
    if (this.loadEiViewResponse.viewRootElementDto) {
      this.form = this.ecs.toFormGroup(this.loadEiViewResponse.viewRootElementDto,
        this.loadEiViewResponse.viewContextDto.addEditEntityInstanceContextDto.entityDefinitionDto);
    } else {
      this.form = this.ecs.emptyFormGroup();
    }
  }
}

