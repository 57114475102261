<div *ngIf="showSpinner" class="bg-spinner-wrapper">
  <mat-spinner style="margin: 0 auto;"></mat-spinner>
</div>
<div>
  <mat-toolbar color="primary">
    <mat-toolbar-row>{{'Send Checkin Form' | translate}}</mat-toolbar-row>
  </mat-toolbar>

  <mat-card appearance="outlined">
    <mat-card-content>
      <form #msgForm="ngForm">

        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>{{'To' | translate}}</mat-label>
          <input type="text" matInput name="recipient" #recipient="ngModel" [(ngModel)]="recipients" placeholder="{{'To' | translate}}"
                 required disabled>
          <mat-error *ngIf="recipient.hasError('required')">
            {{'To is' | translate}} <strong>{{'required' | translate}}</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>{{'Subject' | translate}}</mat-label>
          <input matInput
                 placeholder="{{'Subject' | translate}}"
                 name="subjectField"
                 #subjectField="ngModel"
                 [(ngModel)]="subject"
                 id="subject" required>
          <mat-error *ngIf="subjectField.hasError('required')">
            {{'Subject is' | translate}} <strong>{{'required' | translate}}</strong>
          </mat-error>
        </mat-form-field>


        <app-flo-editor [text]="body" [required]="true" (textUpdated)="bodyUpdated($event)"></app-flo-editor>
        <br><br/>
      </form>

    </mat-card-content>
    <hr>
    <mat-card-content>
      <button mat-raised-button color="accent" (click)="send()" [disabled]="!msgForm.valid || !allowedToSend">{{'Send' | translate}}</button>&nbsp;

      <button mat-raised-button color="accent" (click)="enter()" [disabled]="!msgForm.valid || !allowedToSend">{{'Enter' | translate}}</button>

      &nbsp;
      <button mat-raised-button color="accent" (click)="view()" [disabled]="!msgForm.valid || !allowedToSend">{{'View' | translate}}</button>
      &nbsp;
      <button mat-raised-button (click)="cancel()">{{'CANCEL' | translate}}</button>
      <div *ngIf="!allowedToSend">
        <mat-error>{{'You can\'t send Check-In Form, because it\'s not allowed to send Email message to this customer' | translate}}.</mat-error>
      </div>
    </mat-card-content>
  </mat-card>


</div>
