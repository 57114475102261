import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
// import { EventBusService } from '../EventBusService';
import { ContextIdDto } from '@savvy/entity-instance-composite';

import { ConsumerService, ServicesService } from '@savvy/services';
import { ReplaySubject, Subject } from 'rxjs';
// import { AddServiceDialogComponent } from '../fieldinstance/addServiceDialog.component';
import { MatDialog } from '@angular/material/dialog';
import { EventBusService } from '../../../element/EventBusService';
import { CreatePetAppointmentV2SyncService } from '../create-pet-appointment-v2-sync.service';
import { CreateServiceComponent } from 'src/app/flo/shared/components/setup/services/create-service/create-service.component';
import { Tax } from '@savvy/tax';
import { DataShareService } from 'src/app/core/data-share.service';
import { ServicesHelperService } from 'src/app/flo/shared/services/services-helper.service';
// import { StaffSelectorComponent } from './petServiceUserSelector.component';
import * as _ from 'lodash';
import { NgModel } from '@angular/forms';
import { TaxesHelperService } from 'src/app/flo/shared/services/taxes-helper.service';
@Component({
  selector: 'app-pet-service-selector-v2',
  templateUrl: './pet-service-selector-v2.component.html'
})
export class PetServiceSelectorV2Component implements OnInit {
  @ViewChild('serviceField') serviceField: NgModel;

  @Input() contextIdDto: ContextIdDto;
  @Input() eventBus: EventBusService;
  @Input() showPlaceholder: boolean;
  @Input() selectedService: ConsumerService;

  @Output() serviceSelectionChanged: EventEmitter<ConsumerService> = new EventEmitter();
  serviceGroups = [];
  taxDefs: Tax[] = [];

  public filteredServices: ReplaySubject<ConsumerService[]> = new ReplaySubject<ConsumerService[]>(1);
  private destroy$ = new Subject();
  constructor(
    private dialog: MatDialog,
    private taxesHelperService: TaxesHelperService,
    public createPetAppointmentV2SyncService: CreatePetAppointmentV2SyncService,
    public dataShareService: DataShareService,
    public servicesHelperService: ServicesHelperService,
  ) {

  }

  ngOnInit(): void {
    if (this.contextIdDto) {
      this.taxesHelperService.getTaxDefs(this.contextIdDto).then(res => {
        this.taxDefs = res;
      });
      this.servicesHelperService.getConsumerServices(this.contextIdDto, true, this.createPetAppointmentV2SyncService.editMode).then(consumerServices => {
        if (consumerServices && consumerServices.length) {
          if (!this.selectedService) {
            this.selectedService = consumerServices[0];
            this.serviceSelectionChanged.emit(this.selectedService);
          } else {
            consumerServices = consumerServices.filter(cs => !cs.deleted || (cs.deleted && cs.id === this.selectedService.id));
          }
          this.filteredServices.next(consumerServices);
          if (this.serviceField && this.selectedService?.deleted) {
            this.serviceField.control.markAsTouched();
          }
        }
      });
      this.servicesHelperService.getServiceGroups(this.contextIdDto).then(groups => {
        this.serviceGroups = groups;
      });
    }
  }

  serviceChanged(event) {
    if (event.value) {
      this.serviceSelectionChanged.emit(event.value);
    }
  }

  filterServices(value: string) {
    this.servicesHelperService.getConsumerServices(this.contextIdDto).then(consumerServices => {
      if (!consumerServices) {
        return;
      }
      // get the search keyword
      if (!value) {
        this.filteredServices.next(consumerServices.slice());
        return;
      }
      const search = value.toLowerCase();
      // filter the consumerServices
      this.filteredServices.next(consumerServices.filter(serviceDto => serviceDto?.serviceName.toLowerCase().indexOf(search) > -1));
    });
  }

  createNewService() {
    const dialogRef = this.dialog.open(CreateServiceComponent, {
      width: '550px',
      panelClass: ['scrollable-modal', 'helpwindow'],
      data: {
        serviceGroups: this.serviceGroups,
        taxDefs: this.taxDefs,
        contextIdDto: this.contextIdDto
      }
    });

    dialogRef.afterClosed().subscribe((serviceNew) => {
      if (serviceNew) {
        console.log('The service is saved');
        this.servicesHelperService.getConsumerServices(this.contextIdDto, true).then(consumerServices => {
          this.loadAndSetService(serviceNew, consumerServices);
        });
      }
    });
  }

  loadAndSetService(service: ConsumerService, consumerServices: ConsumerService[]) {
    console.log('loading new services');
    if (consumerServices && consumerServices.length) {
      this.filteredServices.next(consumerServices);
      const newService = consumerServices.find(s => s?.serviceName === service?.serviceName);
      setTimeout(() => {
        this.selectedService = newService;
        this.serviceSelectionChanged.emit(service);
      }, 500);
    }
  }

  compareObjects(o1: ConsumerService, o2: ConsumerService): boolean {
    if (o1 && o2) {
      return o1?.serviceName === o2?.serviceName;
    }
    return false;
  }
}

