import { Component, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { FormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ContextIdDto } from '@savvy/menu';
import { TermsAndConditionsDefinition, TermsAndConditionsDefinitionService, TermsAndConditionsMessage } from '@savvy/terms-and-conditions';
import { UploadFilesComponent } from '../../shared/components/upload-files/upload-files.component';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';
import { NavChangeService } from "../../element/NavChangeService";
import MenuItemTypeEnum = MenuDefinition.MenuItemTypeEnum;
import { MenuDefinition } from '@savvy/menu';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-term-and-condition-crud',
  templateUrl: './term-and-condition-crud.component.html',
  styleUrls: ['./term-and-condition-crud.component.scss']
})
export class TermAndConditionCrudComponent implements OnInit {

  @ViewChild(UploadFilesComponent, { static: false }) uploadFiles: UploadFilesComponent;
  contextIdDto: ContextIdDto;
  termsAndConditionsDefinition: TermsAndConditionsDefinition;
  body: string;
  // tcDefinitionsForm = new UntypedFormGroup({
  //   id: new UntypedFormControl(''),
  //   deleted: new UntypedFormControl(''),
  //   ownerId: new UntypedFormControl(''),
  //   message: new UntypedFormGroup({
  //     body: new UntypedFormControl(''),
  //     // emailHtmlTemplateId: new UntypedFormControl(''),
  //     // links: new UntypedFormControl(''),
  //     attachments: new FormArray([])
  //   })
  // });
  isModal = false;
  rowsSize = 40;
  @Input() showToolbar = true;
  private sub: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navChange: NavChangeService,
    private termsAndConditionsDefinitionService: TermsAndConditionsDefinitionService,
    private notify: FloSnackbarComponent,
    private translateService: TranslateService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data?: any,
    @Optional() public dialogRef?: MatDialogRef<TermAndConditionCrudComponent>,) {
    if (this.data) {
      this.isModal = this.data.isModal;
      this.contextIdDto = this.data.contextIdDto;
    }
  }

  ngOnInit() {
    this.navChange.add(MenuItemTypeEnum.TermsAndConditions);

    if (this.isModal) {
      this.rowsSize = 10;
      this.load();
    } else {
      this.sub = this.route.parent.params.subscribe(params => {
        this.contextIdDto = <ContextIdDto>{};
        this.contextIdDto.contextId = params['contextId'];
        this.contextIdDto.contextIdType = params['contextIdType'];
        this.load();
      });
    }
  }

  load() {
    this.termsAndConditionsDefinitionService.load(this.contextIdDto?.contextId, this.contextIdDto?.contextIdType).subscribe(response => {
      this.termsAndConditionsDefinition = response;
      if (response && response.message) {
        this.body = response.message.body;
      } else {
        this.body = "";
      }
      // this.setDataIntoForm();
      // this.setAttachments();
    }, error => {
      this.termsAndConditionsDefinition = null;
    });
  }

  create() {
    const req = this.generateRequest();
    this.termsAndConditionsDefinitionService.create(this.contextIdDto?.contextId, this.contextIdDto?.contextIdType, req)
      .subscribe(response => {
        this.termsAndConditionsDefinition = response;
        this.setDataIntoForm();
        if (this.isModal) {
          this.dialogRef.close();
        }
        this.translateService.get('Successfully created emailTemplate').subscribe(val => {
          this.notify.message = val;
          this.notify.open();
        });
      });
  }

  update() {
    const req = this.generateRequest();
    this.termsAndConditionsDefinitionService.create(this.contextIdDto?.contextId, this.contextIdDto?.contextIdType, req)
      .subscribe(response => {
        this.termsAndConditionsDefinition = response;
        this.setDataIntoForm();
        if (this.isModal) {
          this.dialogRef.close();
        }
        this.translateService.get('Successfully created emailTemplate').subscribe(val => {
          this.notify.message = val;
          this.notify.open();
        });
      });
  }

  bodyUpdated(event: string) {
    this.body = event;
  }

  generateRequest() {
    // const attachments = [];
    // if (this.uploadFiles && this.uploadFiles.savedFiles) {
    //   this.uploadFiles.savedFiles.forEach(
    //     savedFile => {
    //       attachments.push(savedFile.fileReferenceId);
    //     }
    //   );
    // }
    // const termsAndConditionsMessage = <TermsAndConditionsMessage>{};
    // termsAndConditionsMessage.body = this.tcDefinitionsForm?.value?.message?.body;
    // // termsAndConditionsMessage.emailHtmlTemplateId = {
    // //   id:
    // // }
    // termsAndConditionsMessage.links = [];
    // termsAndConditionsMessage.attachments = attachments;
    const req: TermsAndConditionsDefinition = {} as TermsAndConditionsDefinition;
    req.ownerId = this.contextIdDto?.contextId;
    req.message = {
      body: this.body
    };
    req.id = this.termsAndConditionsDefinition?.id;
    return req;
  }

  setDataIntoForm() {
    // this.tcDefinitionsForm.patchValue({
    //   id: this.termsAndConditionsDefinition?.id,
    //   deleted: this.termsAndConditionsDefinition?.deleted,
    //   ownerId: this.termsAndConditionsDefinition?.ownerId,
    //   message: {
    //     body: this.termsAndConditionsDefinition?.message?.body,
    //     // emailHtmlTemplateId: ,
    //     // links: [],
    //     attachments: this.termsAndConditionsDefinition?.message?.attachments
    //   }
    // });
  }

  setAttachments() {
    // if (this.uploadFiles && this.termsAndConditionsDefinition && this.termsAndConditionsDefinition.message && this.termsAndConditionsDefinition.message.attachments) {
    //   this.uploadFiles.setSavedFiles(this.termsAndConditionsDefinition?.message?.attachments);
    // }
  }

  backToList() {
    this.router.navigate(['/admin/settings',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType, 'on-boarding']);
  }

}
