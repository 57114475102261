<div *ngIf="disableCreate">
  <mat-chip-listbox>
    <mat-chip-option color="{{flagDefHelperService.getFlagDef(flagId)?.colour}}" *ngFor="let flagId of flagIds"
      removable="false" [matTooltip]="flagDefHelperService.getFlagDef(flagId)?.description" matTooltipPosition="above"
      selected>
      {{flagDefHelperService.getFlagDef(flagId)?.name}}
    </mat-chip-option>
  </mat-chip-listbox>
</div>

<div appearance="outlined" class="mdc-mb-1 border-0" *ngIf="!disableCreate">
  <div class="mdc-mb-1">
    <div fxLayout="row" fxLayoutAlign=" center" class="w-100">
      <div fxFlex>
        <h6 class="mb-0">{{'FLAGS' | translate}}</h6>
      </div>
      <div fxFlex="nogrow" *ngIf="!disableCreate && flagDefHelperService.getPetFlagDefs().length > 0">
        <button mat-button color="accent" [matMenuTriggerFor]="menu">
          <mat-icon class="vm">add</mat-icon> {{'ADD_FLAG' | translate}}
        </button>
        <mat-menu #menu="matMenu">
          <button color="accent" *ngFor="let flagDef of flagDefHelperService.getPetFlagDefs()"
            (click)="addFlag(flagDef)" mat-menu-item>{{ flagDef.name }}</button>
        </mat-menu>
      </div>
    </div>
  </div>
  <div>
    <mat-chip-listbox>
      <mat-chip-option color="{{flagDefHelperService.getFlagDef(flagId)?.colour}}" *ngFor="let flagId of flagIds"
        removable="true" [matTooltip]="flagDefHelperService.getFlagDef(flagId)?.description" matTooltipPosition="above"
        (removed)="removeFlag(flagId)" selected>
        {{flagDefHelperService.getFlagDef(flagId)?.name}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-option>
    </mat-chip-listbox>
  </div>
</div>