import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContextIdDto, Invoice } from '@savvy/invoice';
import { RequestForPayment, RequestForPaymentService, ScheduleRequestForPayment } from '@savvy/request-for-payment';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';
import { PaymentSchedulerModalComponent } from './payment-scheduler-modal/payment-scheduler-modal.component';
import * as _ from 'lodash';
import { PhoneNumberHelperService } from '../../shared/services/phone-number-helper.service';
import { UserCurrencyService } from '../../shared/services/userCurrency.service';
@Component({
  selector: 'app-view-payment-requests',
  templateUrl: './view-payment-requests.component.html',
  styleUrls: ['./view-payment-requests.component.scss']
})
export class ViewPaymentRequestsComponent implements OnInit {

  @Input() invoice: Invoice;
  @Input() requestForPaymentOwnerId: string;
  @Input() requestForPaymentOwnerType: RequestForPayment.RequestForPaymentOwnerTypeEnum;
  @Input() contextIdDto: ContextIdDto;
  @Input() currencyCode: string;

  paymentRequests: RequestForPayment[] = [];

  constructor(
    private requestForPaymentService: RequestForPaymentService,
    private notify: FloSnackbarComponent,
    private dialog: MatDialog,
    private sharedService: PhoneNumberHelperService,
    private userCurrencyService: UserCurrencyService
  ) { }

  ngOnInit() {
    if (this.invoice && this.invoice.invoiceId) {
      this.loadRequestsForPayment();
      this.getCurrencyCode();
    }
  }

  getCurrencyCode() {
    this.userCurrencyService.getDefaultCurrency(this.contextIdDto).subscribe(res => {
      this.currencyCode = res.org.currencyCode ? res.org.currencyCode : this.userCurrencyService.defaultCurrency;
    });
  }

  send(paymentRequestId: string) {
    this.requestForPaymentService.triggerDepositRequestNow(paymentRequestId).subscribe(res => {
      if (res) {
        this.notify.message = 'Request for payment has been sent';
        this.notify.open();
      }
    });
  }

  reSchedule(paymentRequest: RequestForPayment) {
    const confirmDialog = this.dialog.open(PaymentSchedulerModalComponent, {
      data: {
        paymentRequest: Object.assign({}, paymentRequest),
        contextIdDto: this.contextIdDto,
        invoice: this.invoice,
        requestForPaymentOwnerId: this.requestForPaymentOwnerId,
        requestForPaymentOwnerType: this.requestForPaymentOwnerType
      },
      height: 'auto',
      width: '400px',
    });

    confirmDialog.afterClosed().subscribe((requestForPayment: RequestForPayment) => {
      if (requestForPayment) {
        const scheduleRequestParams = {
          contextIdDto: this.contextIdDto,
          requestForPayment
        };
        this.requestForPaymentService.scheduleRequest(scheduleRequestParams)
          .subscribe(res => {
            paymentRequest = res;
            this.notify.message = 'Request for payment saved';
            this.notify.open();
            this.loadRequestsForPayment();
          });
      }
    });
  }

  loadRequestsForPayment() {
    this.requestForPaymentService.listByInvoice(this.contextIdDto.contextId, this.contextIdDto.contextIdType, this.invoice.id)
      .subscribe(res => {
        this.paymentRequests = res;
        this.paymentRequests = [...this.paymentRequests];
      });
  }

  addNew() {
    const confirmDialog = this.dialog.open(PaymentSchedulerModalComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        invoice: this.invoice,
        requestForPaymentOwnerId: this.requestForPaymentOwnerId,
        requestForPaymentOwnerType: this.requestForPaymentOwnerType,
        percentRequired: this.paymentRequests.length ? this.paymentRequests[0].percentRequired : false
      },
      height: 'auto',
      width: '400px',
    });

    confirmDialog.afterClosed().subscribe((requestForPayment: RequestForPayment) => {
      if (requestForPayment) {
        requestForPayment.invoiceId = this.invoice.id;
        requestForPayment.invoiceDate = this.invoice.invoiceDate;
        requestForPayment.ownerId = this.contextIdDto.contextId;
        // @ts-ignore
        requestForPayment.sent = false;

        const requestForPaymentParams = Object.assign({
          requestForPaymentOwnerType: this.requestForPaymentOwnerType,
          requestForPaymentOwnerId: this.requestForPaymentOwnerId
        }, requestForPayment);

        const scheduleRequestForPayment: ScheduleRequestForPayment = {
          contextIdDto: this.contextIdDto,
          requestForPayment: requestForPaymentParams,
        };
        this.requestForPaymentService.scheduleRequest(scheduleRequestForPayment).subscribe(res => {
          this.notify.message = 'Request for payment saved';
          this.notify.open();
          this.loadRequestsForPayment();
        });
      }
    });
  }

}
