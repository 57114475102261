import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { CustomerService } from '@savvy/customer';
import { ExportService } from '@savvy/export';
import { ViewcompService } from '@savvy/view-composite';
import { EntityModule } from '../entity/entity.module';
import { FloMaterialModule } from '../flo.material.module';
import { AppInvoiceModule } from '../invoice/invoice.module';
import { AppMessagingModule } from '../messaging/messaging.module';
import { AppRemindersModule } from '../reminders/reminders.module';
import { IndividualMessagesComponent } from '../send-to-all/individual-messages/individual-messages.component';
import { SendToAllMessagesComponent } from '../send-to-all/send-to-all-messages/send-to-all-messages.component';
import { SharedModule } from '../shared/shared.module';
import { AppTaskModule } from '../task/task.module';
import { customerRoutes } from './customer.routing';
import { CustomersEmailModalComponent } from './customers-email-modal/customers-email-modal.component';
import { ExportCustomersComponent } from './exportCustomers.component';
import { ViewCustomerComponent } from './viewCustomer.component';

import { ClipboardModule } from '@angular/cdk/clipboard';
import { CustomerPortalService } from '@savvy/customer-portal';
import { CustomerPortalSettingsService } from '@savvy/customer-portal-settings';
import { EditorModule } from '../editor/editor.module';
import { OnboardCustomerMessagingModalComponent } from './onboard-customer-messaging-modal/onboard-customer-messaging-modal.component';

import { NgWizardConfig, NgWizardModule, THEME } from 'ng-wizard-v2';
import { SendToAllMessageConfigurationComponent } from '../send-to-all/send-to-all-message-wizard/send-to-all-message-configuration/send-to-all-message-configuration.component';
import { SendToAllMessageEmailComponent } from '../send-to-all/send-to-all-message-wizard/send-to-all-message-email/send-to-all-message-email.component';
import { SendToAllMessagePreviewComponent } from '../send-to-all/send-to-all-message-wizard/send-to-all-message-preview/send-to-all-message-preview.component';
import { SendToAllMessageSmsComponent } from '../send-to-all/send-to-all-message-wizard/send-to-all-message-sms/send-to-all-message-sms.component';
import { SendToAllMessageWizardComponent } from '../send-to-all/send-to-all-message-wizard/send-to-all-message-wizard.component';
import { CustomersV2Component } from './customersV2.component';
import { ResetCustomerPasswordModalComponent } from './reset-customer-password-modal/reset-customer-password-modal.component';
import { ViewCustomerFlagsComponent } from './view-customer-flags/view-customer-flags.component';
import { CustomerAppointmentInvoiceSummaryComponent } from './view-customer-v2/customer-appointment-invoice-summary/customer-appointment-invoice-summary.component';
import { ViewCustomerProfileComponent } from './view-customer-v2/view-customer-profile/view-customer-profile.component';
import { ViewCustomerV2Component } from './view-customer-v2/view-customer-v2.component';
import { ViewV1V2CustomerHistoryComponent } from './view-customer-v2/view-v1-v2-customer-history/view-v1-v2-customer-history.component';
// import { CustomerPetDetailsComponent } from './view-customer-v2/customer-pet-details/customer-pet-details.component';
import { AlphabetFilterModule } from '@savvy/alpha-filter';
import { PasswordStrengthMeterComponent } from 'angular-password-strength-meter';
import { provideZxvbnServiceForPSM } from 'angular-password-strength-meter/zxcvbn';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SafePipeModule } from '../../core/safeHtml.pipe';
import { AppFlagsModule } from '../flags/flags.module';
import { ChangePetOwnerModalComponent } from '../pets/view-pet-details-v2/quick-action-buttons/change-pet-owner-modal/change-pet-owner-modal.component';
import { CustomerHistoryComponent } from './customer-workflow-stats/customerHistory.component';
import { CustomersAlphaComponent } from "./customersAlpha.component";
import { CustomersGridComponent } from "./customersGrid.component";
import { MergeCustomerSuccessComponent } from './merge-customer-success/merge-customer-success.component';
import { MergeCustomerModalComponent } from './merge-customer/merge-customer-modal.component';
import {
  CustomerMessageDetailsComponent
} from './view-customer-v2/customer-message-details/customer-message-details.component';
import { CustomerPortalButtonsComponent } from './view-customer-v2/customer-portal-buttons/customer-portal-buttons.component';
import { CustomerStatsPanelComponent } from './view-customer-v2/customer-stats-panel/customer-stats-panel.component';
import { ViewCustomerBoardingsComponent } from "./view-customer-v2/view-customer-boardings/view-customer-boardings.component";

const ngWizardConfig: NgWizardConfig = {
  theme: THEME.default
};

@NgModule({
  imports: [
    CommonModule,
    AppRemindersModule,
    MatProgressSpinnerModule,
    EntityModule,
    AppFlagsModule,
    AppTaskModule,
    // ElementModule,
    AppMessagingModule,
    AppInvoiceModule,
    RouterModule.forChild(customerRoutes),
    // ExportServiceModule.forRoot(() => {
    //   return new ExportConfiguration({
    //     basePath: ConfService.apiUrl()
    //   });
    // }),
    FloMaterialModule,
    SharedModule,
    TranslateModule,
    FormsModule,
    SafePipeModule,
    ReactiveFormsModule,
    // CustomerPortalModule.forRoot(() => {
    //   return new CustomerPortalConfiguration({
    //     basePath: ConfService.apiUrl()
    //   });
    // }),
    // CustomerPortalSettingsModule.forRoot(() => {
    //   return new CustomerPortalSettingsConfiguration({
    //     basePath: ConfService.apiUrl()
    //   });
    // }),
    EditorModule,
    ClipboardModule,
    NgWizardModule.forRoot(ngWizardConfig),
    AlphabetFilterModule,
    InfiniteScrollModule,
    PasswordStrengthMeterComponent
  ],
  declarations: [
    CustomersV2Component,
    CustomersAlphaComponent,
    CustomersGridComponent,
    ViewCustomerComponent,
    ExportCustomersComponent,
    // CustomerSelectorComponent,
    CustomersEmailModalComponent,
    SendToAllMessagesComponent,
    IndividualMessagesComponent,
    ResetCustomerPasswordModalComponent,
    MergeCustomerModalComponent,
    OnboardCustomerMessagingModalComponent,
    CustomerMessageDetailsComponent,
    ViewCustomerFlagsComponent,
    CustomerHistoryComponent,
    SendToAllMessageWizardComponent,
    SendToAllMessageConfigurationComponent,
    SendToAllMessageEmailComponent,
    SendToAllMessageSmsComponent,
    SendToAllMessagePreviewComponent,
    ViewCustomerV2Component,
    ViewCustomerProfileComponent,
    CustomerAppointmentInvoiceSummaryComponent,
    ViewV1V2CustomerHistoryComponent,
    ViewCustomerBoardingsComponent,
    CustomerStatsPanelComponent,
    CustomerPortalButtonsComponent,
    MergeCustomerSuccessComponent,
    ChangePetOwnerModalComponent,

  ],
  providers: [
    CustomerService,
    ExportService,
    CustomerPortalSettingsService,
    CustomerPortalService,
    ViewcompService,
    provideZxvbnServiceForPSM()
  ],
  exports: [
    // CustomerSelectorComponent,
    ViewCustomerFlagsComponent,
    CustomerHistoryComponent,
    ExportCustomersComponent,
    ViewCustomerBoardingsComponent,
    CustomerMessageDetailsComponent,
  ]
})

export class AppCustomerModule { }
