import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ContextIdDto } from '@savvy/datalist';
import { EmailMessageTemplate, EmailmessagetemplatesService } from '@savvy/email-template';
import { Invoice } from '@savvy/entity-instance-composite';
import { EmailRecipient } from '@savvy/view-composite';
import { EventBusService } from '../element/EventBusService';
import { EventType } from '../event/UiEvent';

@Component({
  selector: 'app-request-payment',
  templateUrl: './requestPayment.component.html'
})
export class RequestPaymentComponent implements OnInit {

  @Input() invoice: Invoice;
  @Input() contextIdDto: ContextIdDto;
  @Input() viewContextDto: any;
  @Input() visible: boolean;
  @Input() eventBus: EventBusService;

  showSpinner = false;
  allowedToSend = false;
  body: string;
  subject: string;
  amount: number;
  emailMessageTemplate: EmailMessageTemplate;
  recipients: string;
  customerRecipient: EmailRecipient;


  @Output() depositRequestSent = new EventEmitter();
  @Output() depositRequestSendCancelled = new EventEmitter();

  public form: UntypedFormGroup; // our form model

  constructor(
    private emailMessageApi: EmailmessagetemplatesService) {

  }

  ngOnInit() {

    console.log('inside new message init');
    this.emailMessageApi.getEmailMessageTemplateByType(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType, 'invoice').subscribe(response => {
        console.log('loading type');
        this.emailMessageTemplate = response;
        this.subject = response.subject;
        this.body = response.body;
      });
    this.loadRecipient();
  }

  bodyUpdated(text: string) {
    this.body = text;
  }

  loadRecipient() {
    /* if (this.invoice) {
       if (this.invoice.linkedId) {
         const li = LinkedInstanceFactory.createLinkedInstanceFromLink(this.invoice.linkedId);
         const req = <LoadUserForLink>{};
         req.linkedInstanceDto = li;
         req.contextIdDto = this.contextIdDto;

         this.userCompApi.loadUserForLink(req).subscribe(response => {
           if (response.userDto) {
             if (response.userDto.optIn) {
               this.allowedToSend = response.userDto.optIn.allowEmail;
             }
             this.recipients = response.userDto.email;
             this.customerRecipient = <EmailRecipient>{};
             this.customerRecipient.address = response.userDto.username;
             this.customerRecipient.userId = response.userDto.userId;
             this.customerRecipient.recipientType = EmailRecipient.RecipientTypeEnum.Customer;

           } else {
           }
         });
       } else {
         console.log('no link id set');
       }
     } else {
       console.log('ERROR: No invoice!!');
     }*/
  }

  cancelIt() {
    this.eventBus.addEvent(EventType.CANCEL_SEND_MESSAGE);
    this.depositRequestSendCancelled.emit('cancelled');
  }

  send() {
    this.showSpinner = true;
    console.log('onSubmit');
    const req = <any>{};
    req.subject = this.subject;
    req.body = this.body;
    req.amountInPence = this.amount;

    req.recipientEmailAddresses = new Array();
    req.recipientEmailAddresses.push(this.customerRecipient);
    req.contextIdDto = this.contextIdDto;
    req.invoiceId = this.invoice.invoiceId;

    // this.invoiceCompositeService.sendDepositRequest(req).subscribe(response => {
    //   console.log('Got response! ' + response);
    //   if (response.sendMessageError) {
    //     console.log('there was an error!');
    //     this.notify.message = 'Error sending deposit request ' + response.sendMessageError;
    //     this.notify.open();
    //     /*
    //     this.translateService.get('Sent Invoice')
    //       .subscribe(val => {
    //         this.notify.message = val;
    //         this.notify.open();
    //       });
    //      */
    //   } else {
    //     this.notify.message = 'Sent Deposit Request';
    //     this.notify.open();
    //   }
    //   this.showSpinner = false;
    //   this.depositRequestSent.emit('sentInvoice');
    //   this.eventBus.addInvoiceEvent(EventType.SENT_INVOICE, this.invoice.invoiceId);
    // });
  }
}
