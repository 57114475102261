<mat-card appearance="outlined" class="full-height weather-widget">
    <mat-card-content class="pb-0 top-weather">
        <div fxLayout="row" fxLayoutAlign="start center" class="mb-2">
            <div fxFlex>
                <h1 class="mt-0 mb-0">London, UK</h1>
                <h2 class="mt-0 mb-0">Saturday 2:00 AM</h2>
                <p class="mat-text-muted mt-0 mb-0">Mostly Sunny</p>
            </div>
            <span fxFlex></span>
            <div fxFlex class="text-xs-right">
                <h1 class="text-xxl mt-0 mb-0">
                    <span class="pe-is-w-moon-sea mat-text-accent"></span>
                    <span>6<sup></sup></span>
                </h1>
            </div>
        </div>
        <div fxLayout="row" layout-align="center" class="mb-2 text-md">
            <div class="pr-1" fxFlex>
                <div fxLayout="row">
                    <span>Pressure</span>
                    <span fxFlex></span>
                    <span>30.09</span>
                    <span>hPa</span>
                </div>

                <div fxLayout="row">
                    <span>Visibility</span>
                    <span fxFlex></span>
                    <span>11.27</span>
                    <span>km</span>
                </div>

                <div fxLayout="row">
                    <span>Light</span>
                    <span fxFlex></span>
                    <span>0.00</span>
                </div>

                <div fxLayout="row">
                    <span>Light</span>
                    <span fxFlex></span>
                    <span>16</span>
                    <span>C</span>
                </div>
            </div>
            <div class="pl-1" fxFlex>
                <div fxLayout="row">
                    <span>Humidity</span>
                    <span fxFlex></span>
                    <span>94</span>
                    <span>%</span>
                </div>

                <div fxLayout="row">
                    <span>Precp.</span>
                    <span fxFlex></span>
                    <span></span>
                    <span>mm</span>
                </div>

                <div fxLayout="row">
                    <span>Snow Depth</span>
                    <span fxFlex></span>
                    <span></span>
                </div>

                <div fxLayout="row">
                    <span>Wind Gust</span>
                    <span fxFlex></span>
                    <span></span>
                </div>
            </div>
        </div>
    </mat-card-content>
    <mat-card-actions class="mat-amber bottom-weather">
        <div fxLayout="row" class="text-xs-center">
            <div fxFlex>
                <div class="text-md">6 km/h</div>
                <div class="text-md pb-xs"><mat-icon>call_received</mat-icon></div>
                <div class="text-md">MON</div>
                <h1 class="block mt-0 mb-0 pb-xs"><i class="pe-is-w-blizzard"></i></h1>
                <strong class="degree-value">10</strong>
            </div>
            <div fxFlex>
                <div class="text-md">6 km/h</div>
                <div class="text-md pb-xs"><mat-icon>call_received</mat-icon></div>
                <div class="text-md">TUE</div>
                <h1 class="block mt-0 mb-0 pb-xs"><i class="pe-is-w-eclipse-1"></i></h1>
                <strong class="degree-value">8</strong>
            </div>
            <div fxFlex>
                <div class="text-md">6 km/h</div>
                <div class="text-md pb-xs"><mat-icon>call_made</mat-icon></div>
                <div class="text-md">WED</div>
                <h1 class="block mt-0 mb-0 pb-xs"><i class="pe-is-w-fog-3"></i></h1>
                <strong class="degree-value">6</strong>
            </div>
            <div fxFlex>
                <div class="text-md">6 km/h</div>
                <div class="text-md pb-xs"><mat-icon>arrow_upward</mat-icon></div>
                <div class="text-md">THU</div>
                <h1 class="block mt-0 mb-0 pb-xs"><i class="pe-is-w-hail-day-2"></i></h1>
                <strong class="degree-value">6</strong>
            </div>
            <div fxFlex>
                <div class="text-md">6 km/h</div>
                <div class="text-md pb-xs"><mat-icon>call_received</mat-icon></div>
                <div class="text-md">FRI</div>
                <h1 class="block mt-0 mb-0 pb-xs"><i class="pe-is-w-mix-rainfall-1"></i></h1>
                <strong class="degree-value">9</strong>
            </div>
            <div fxFlex>
                <div class="text-md">6 km/h</div>
                <div class="text-md pb-xs"><mat-icon>arrow_forward</mat-icon></div>
                <div class="text-md">SAT</div>
                <h1 class="block mt-0 mb-0 pb-xs"><i class="pe-is-w-moon-sea"></i></h1>
                <strong class="degree-value">8</strong>
            </div>
        </div>
    </mat-card-actions>
</mat-card>