<h2 mat-dialog-title *ngIf="!data.edit">{{'ADD_PET_TYPE' | translate}}</h2>
<h2 mat-dialog-title *ngIf="data.edit">{{'EDIT_PET_TYPE' | translate}}</h2>

<mat-dialog-content class="pt-1">
  <mat-form-field appearance="outline" class="w-100">
    <mat-label>{{'NAME' | translate}}</mat-label>
    <input matInput [(ngModel)]="data.petType.name" name="subscriptionsDef" required />
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-100">
    <mat-label>{{'DESCRIPTION' | translate}}</mat-label>
    <input matInput [(ngModel)]="data.petType.description" name="subscriptionsDef" />
  </mat-form-field>
  <div class="text-end">
    <button mat-flat-button color="primary" [disabled]="!data.petType.name" (click)="createUpdatePetType()" cdkFocusInitial *ngIf="!data.edit">
      {{'ADD' | translate}}
    </button>
    <button mat-flat-button color="primary" (click)="createUpdatePetType()" cdkFocusInitial *ngIf="data.edit">
      {{'UPDATE' | translate}}
    </button>
  </div>
</mat-dialog-content>