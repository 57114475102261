<mat-card *ngIf="showNotifications" class="notification-v2 shadow">
  <mat-card-header>
    <div fxFlex="nogrow">
      <mat-card-title>{{'NOTIFICATIONS' | translate}}</mat-card-title>
    </div>
    <div fxFlex></div>
    <div fxFlex="nogrow" align="right">
      <button mat-button color="accent" (click)="markAllAsRead()">
        <mat-icon class="mr-5px icon-18 vm ">done_all</mat-icon>{{'MARK_ALL_AS_READ' | translate}}
      </button>
      <button mat-icon-button aria-label="Close" (click)="hideNotifications()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </mat-card-header>
  <mat-card-content class="notification-wrap-content">
    <mat-card *ngFor="let notification of notifications" class="mx-0 notification-card">
      <mat-card-content>
        <div fxLayout="row" fxLayoutGap="10px">
          <div fxFlex="nogrow">
            <mat-icon *ngIf="!notification.icon" class="mat-text-red">notifications_active</mat-icon>
            <mat-icon *ngIf="notification.icon" mat-list-icon class="mat-text-primary">{{ notification.icon
              }}</mat-icon>
          </div>
          <div fxFlex>
            <p style="margin-bottom:5px; white-space:normal">{{ notification?.header }}</p>
            <p class="small mat-text-secondary">{{ notification?.message }}</p>
            <div fxLayout="row wrap">
              <button mat-button color="accent" class="px-1"
                (click)="markAsRead(notification); $event.stopImmediatePropagation()">
                <!-- <mat-icon class="mr-5px icon-18 vm">done</mat-icon>
              <span *ngIf="notification.notificationType !== notificationTypeEnum?.System">{{'MARK_AS_READ' | translate}}</span> -->
                <!-- <mat-icon class="mr-5px icon-18 vm">done</mat-icon> -->
                <mat-icon *ngIf="notification.notificationType !== notificationTypeEnum?.System && notification.notificationType !== notificationTypeEnum?.ReleaseNotes"
                  class="vm">mark_email_read</mat-icon>
                <span *ngIf="notification.notificationType !== notificationTypeEnum?.System && notification.notificationType !== notificationTypeEnum?.ReleaseNotes">{{'MARK_AS_READ' |
                  translate}}</span>

                <span *ngIf="notification.notificationType === notificationTypeEnum?.System || notification.notificationType === notificationTypeEnum?.ReleaseNotes">{{'READ_IT' |
                  translate}}</span>
              </button>

              <app-onboarding-notification
                *ngIf="notification.notificationType === notificationTypeEnum?.CustomerOnboarded"
                [contextIdDto]="contextIdDto" [notification]="notification" />
              <app-invite-customer-notification
                *ngIf="notification.notificationType === notificationTypeEnum?.CustomerInvitationSent"
                [contextIdDto]="contextIdDto" [notification]="notification" />
              <app-contract-signed-notification
                *ngIf="notification.notificationType === notificationTypeEnum?.ContractSigned"
                [contextIdDto]="contextIdDto" [notification]="notification" />
              <app-release-notes-notification
                *ngIf="notification.notificationType === notificationTypeEnum?.ReleaseNotes"
                [contextIdDto]="contextIdDto" [notification]="notification" />
              <app-check-in-form-notification
                *ngIf="notification.notificationType === notificationTypeEnum?.CheckInFormSubmission"
                [contextIdDto]="contextIdDto" [notification]="notification" />
              <app-contact-form-notification
                *ngIf="notification.notificationType === notificationTypeEnum?.ContactFormSubmission"
                [contextIdDto]="contextIdDto" [notification]="notification" />
              <app-sms-reply-notification *ngIf="notification.notificationType === notificationTypeEnum?.SmsReplied"
                [contextIdDto]="contextIdDto" [notification]="notification" />
              <app-savvy-message-notification
                *ngIf="notification.notificationType === notificationTypeEnum?.SavvyMessageReceived"
                [contextIdDto]="contextIdDto" [notification]="notification" />

            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </mat-card-content>
</mat-card>
