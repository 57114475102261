import {Component} from '@angular/core';

@Component({
    selector: 'app-weather-panel',
    templateUrl: 'weatherPanel.component.html'
})

export class WeatherPanelComponent {
}

