import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReminderDefsComponent } from './reminderDefs.component';
import { reminderRoutes } from './reminders.routing';
import { ReminderService } from '@savvy/reminders';
import { ReminderDefService } from '@savvy/reminders';
import { ReminderDefsService } from '@savvy/reminders';
import { FloMaterialModule } from '../flo.material.module';
import { EntitydefsService } from '@savvy/entity-definition';
import { ShowReminderComponent } from './showReminder.component';
import { RemindersService } from '@savvy/reminders';
import { EditorModule } from '../editor/editor.module';
import { AddEditReminderDefComponent } from './addEditReminderDef.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { SenderidValidator } from '../validators/senderid.validator';
import { ReminderdefscompService } from '@savvy/reminders';
import { RemindersCompService } from '@savvy/reminders';
import { AddEditReminderDefPageComponent } from './addEditReminderDefPage.component';
import { RemindersTodayComponent } from './remindersToday.component';
import {TimeSelectModule} from "../time-select/time-select.module";
import { SmsEmailMessagePopupComponent } from './sms-email-message-popup/sms-email-message-popup.component';

@NgModule({
  imports: [
    CommonModule,
    EditorModule,
    RouterModule.forChild(reminderRoutes),
    FloMaterialModule,
    SharedModule,
    TranslateModule,
    TimeSelectModule
  ],
  declarations: [
    AddEditReminderDefComponent,
    AddEditReminderDefPageComponent,
    ReminderDefsComponent,
    RemindersTodayComponent,
    ShowReminderComponent,
    SmsEmailMessagePopupComponent,
    SenderidValidator

  ],
  exports: [
    ShowReminderComponent,
    AddEditReminderDefPageComponent,
    RemindersTodayComponent
  ],
  providers: [
    ReminderService,
    RemindersService,
    ReminderDefService,
    ReminderdefscompService,
    RemindersCompService,
    ReminderDefsService,
    EntitydefsService
  ]
})

export class AppRemindersModule { }
