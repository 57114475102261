import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EiPopupButtonDefinition } from '@savvy/view-definition';
import { ContextIdDto } from '@savvy/quickbooks';

@Component({
  selector: 'app-ei-popup',
  templateUrl: 'eiPopup.component.html'
})
export class EiPopupComponent {

  @Input() eiPopupButtonDefinition: EiPopupButtonDefinition;
  @Input() contextIdDto: ContextIdDto;
  constructor(private router: Router) {
  }
  open() {
    // Get ei instance id!!
    this.router.navigate(['/entity/viewEntity',
      this.eiPopupButtonDefinition.entityDefinitionId.id,
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType]);
  }
}
