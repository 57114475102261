<!-- <div *ngIf="customer?.invitedViaEmailDate">
  Customer {{customer?.firstName}} has been invited on {{customer?.invitedViaEmailDate | localizedDate: 'short'}}
</div>
<div>
  <a href="javascript:void(0)" >Click here</a> to list all customers who have not submitted their details yet.
</div> -->


<div *ngIf="customer?.approvalNeeded  && !customer.deleted">
  <button (click)="listCustomers()" mat-button color="accent" class="px-1">
    List Customers (Awaiting Form)
  </button>
</div>