import { Injectable } from '@angular/core';
import { FlagDefinition } from "@savvy/flags";

@Injectable()
export class FlagDefHelperService {
  flagDefs: Array<FlagDefinition>;
  setFlagDefs(flagDefs) {
    this.flagDefs = flagDefs;
  }

  getFlagDef(flagId: string): FlagDefinition | undefined {
    return this.flagDefs ? this.flagDefs.filter(e => e.flagDefinitionId.id === flagId).pop() : undefined;
  }

  getPetFlagDefs(): Array<FlagDefinition> {
    return this.flagDefs ? this.flagDefs.filter(e => e.flagDefinitionType === 'PET') : [];
  }

  getCustomerFlagDefs(): Array<FlagDefinition> {
    return this.flagDefs ? this.flagDefs.filter(e => e.flagDefinitionType === 'CUSTOMER') : [];
  }

  getStudentFlagDefs(): Array<FlagDefinition> {
    return this.flagDefs ? this.flagDefs.filter(e => e.flagDefinitionType === 'STUDENT') : [];
  }

  getLeadFlagDefs(): Array<FlagDefinition> {
    return this.flagDefs ? this.flagDefs.filter(e => e.flagDefinitionType === 'LEAD') : [];
  }
}
