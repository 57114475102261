<!-- [(selectedIndex)]="activeTabIndex" -->

<mat-tab-group  mat-dynamic-height>
  <!-- We could use disabled if we are creating for example [disabled]="tab.disabled" -->



  <mat-tab *ngFor="let tab of tabPanelDefinitionDto.tabDefinitionDtos" [disabled]="!tab.enabled">
    <ng-template mat-tab-label>{{tab.title}}</ng-template>

    <ng-template matTabContent>
      <app-element [elementDto]="tab.tabBody" [form]="form" [contextIdDto]="contextIdDto" [viewContext]="viewContext" [eventBus]="eventBus" [additionalDataMapDto]="additionalDataMapDto" [showPlaceholder]="showPlaceholder" [changeListener]="changeListener">

      </app-element>
    </ng-template>
  </mat-tab>
</mat-tab-group>


<!--<mat-card *ngIf="tabPanelDefinitionDto.tabDefinitionDtos.length && tabPanelDefinitionDto.tabDefinitionDtos.length !== 2">-->
<!--<mat-card-content>-->
<!--<button mat-raised-button-->
<!--color="accent"-->
<!--(click)="closePanelBar()">{{'Close' | translate}}</button>-->
<!--</mat-card-content>-->
<!--</mat-card>-->
