import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SettingsModule } from './settings/settings.module';
import { EntityDefinitionEditorComponent } from './entityDefinitionEditor.component';
import { FloMaterialModule } from '../flo.material.module';
import { EntitydefsService } from '@savvy/entity-definition';
import { EntitysService } from '@savvy/entity-instance';
import { EicompService } from '@savvy/entity-instance-composite';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        SettingsModule,
        FloMaterialModule,
        DragDropModule,
        TranslateModule
    ],
    exports: [
        EntityDefinitionEditorComponent
    ],
    providers: [
        EntitydefsService,
        EntitysService,
        EicompService
    ],
    declarations: [
        EntityDefinitionEditorComponent
    ]
})

export class EntityDefsModule {
}
