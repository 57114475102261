<div class="app" [dir]="options.dir"
  [ngClass]="{'app-dark': options.dark, 'boxed': options.boxed, 'collapsed-sidebar': options.collapsed, 'compact-sidebar': options.compact, 'side-panel-opened': sidePanelOpened, 'side-panel-closed': !sidePanelOpened }">
  <mat-sidenav-container class="app-inner">

    <mat-sidenav #sidePanel position="end" mode="over" opened="false">
      <ng-container *ngIf="calendarEventType === 'Ei'">
        <!-- <ng-container *ngIf="creating && response && creatableEventTypes && eventBus && calendarEventType">
          <ng-container *ngFor="let eventType of creatableEventTypes">
            <app-create-entity-component fxFlex="930px" fxFlex.lt-md="500px" fxFlex.xs="100vw"
              *ngIf="calendarEventType === eventType.id" #createEntityComponent [contextIdDto]="contextIdDto"
              [entityDefinitionId]="calendarEventType" [eventBus]="eventBus"
              (created)="updateCalendarAndOpenViewEi($event)" (cancelClicked)="cancelCreate()">
            </app-create-entity-component>
          </ng-container>
        </ng-container> -->
        <app-view-summary-entity fxFlex="930px" fxFlex.lt-md="500px" fxFlex.xs="100vw"
          *ngIf="deviceService.isDesktop() && viewing && eventBus" [contextIdDto]="contextIdDto" [eventBus]="eventBus"
          (changeOccurred)="updateHistory()" [entityInstanceId]="entityInstanceId">
        </app-view-summary-entity>
      </ng-container>

      <!-- <ng-container *ngIf="calendarEventType === 'Holiday'">
        <app-create-holiday *ngIf="creating" [contextIdDto]="contextIdDto" (closePanel)="closeSidePanel()"
          (created)="updateCalendar()"></app-create-holiday>
        <app-edit-holiday *ngIf="editing" [contextIdDto]="contextIdDto" [calendarEventToView]="calendarEventToView"
          (closePanel)="closeSidePanel()" (updated)="updateCalendar()"></app-edit-holiday>
        <app-view-calendar-entry *ngIf="viewing" [contextIdDto]="contextIdDto" (deleted)="updateCalendar()"
          (gotoEditMode)="gotoEdit()" [viewCalendarEventType]="calendarEventType"
          [calendarEventToView]="calendarEventToView" (closePanel)="closeSidePanel()">
        </app-view-calendar-entry>
      </ng-container>

      <ng-container *ngIf="calendarEventType === 'Meeting'">
        <app-create-meeting *ngIf="creating" [contextIdDto]="contextIdDto" (closePanel)="closeSidePanel()"
          (created)="updateCalendar()"></app-create-meeting>
        <app-edit-meeting *ngIf="editing" [contextIdDto]="contextIdDto" [calendarEventToView]="calendarEventToView"
          (closePanel)="closeSidePanel()" (updated)="updateCalendar()"></app-edit-meeting>
        <app-view-calendar-entry *ngIf="viewing" [contextIdDto]="contextIdDto" (deleted)="updateCalendar()"
          (gotoEditMode)="gotoEdit()" [viewCalendarEventType]="calendarEventType"
          [calendarEventToView]="calendarEventToView" (closePanel)="closeSidePanel()">
        </app-view-calendar-entry>
      </ng-container> -->
    </mat-sidenav>

    <ng-container *ngIf="(contextIdDto && customerDto) || !loaded; else elseBlock">
      <mat-toolbar color="primary" class="mdc-mb-1">
        <div fxFlex fxLayout="row" fxLayoutAlign=" center">
          <div fxFlex="nogrow">
            <button *ngIf="!isViewMode" mat-button (click)="backToList()" class="px-0">
              <mat-icon>arrow_back</mat-icon>
            </button>
          </div>
          <div fxFlex><span>{{'CUSTOMER_DETAILS' | translate}}</span></div>
          <span class="example-spacer"></span>
          <div fxFlex="nogrow">
            <button mat-raised-button class="mx-4" (click)="editCustomer()" color="accent">{{'EDIT_CUSTOMER' |
              translate}}
              <mat-icon aria-hidden="false" aria-label="Example home icon">edit</mat-icon>
            </button>
            <button *ngIf="!isViewMode && !customerDto?.deleted" mat-raised-button class="mx-4"
              (click)="removeCustomer()" color="accent">{{'ARCHIVE' | translate}}
              <mat-icon aria-hidden="false" aria-label="Example home icon">delete</mat-icon>
            </button>
          </div>
          <button mat-icon-button aria-label="Close" (click)="cancel()"
            *ngIf="isViewMode && (deviceService.isMobile() || deviceService.isTablet())">
            <mat-icon class="full-screen-icon">close</mat-icon>
          </button>
        </div>
      </mat-toolbar>
      <div [ngClass]="{'container': !isViewMode, 'p-3': isViewMode}" *ngIf="customerDto">
        <div fxLayout="column">
          <app-view-customer-profile [contextIdDto]="contextIdDto" [customerDto]="customerDto"
            [petList]="customerPetDetailsComponent?.pets" (apppointmentsChanged)="apppointmentsChanged($event)">
          </app-view-customer-profile>
          <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="0" fxLayoutGap.gt-sm="16px">
            <div fxFlex="100" fxFlex.gt-sm="65" fxFlex.gt-md="68" fxFlex.gt-xl="68">
              <app-upcoming-appointment [upcomingAppointment]="appointments[0]">
              </app-upcoming-appointment>

              <mat-card appearance="outlined" class="mdc-mb-2" *ngIf="!contextService.isPetStay()">
                <app-view-v1-v2-customer-history #viewV1V2CustomerHistoryComponent [contextIdDto]="contextIdDto"
                  [customerDto]="customerDto" [petList]="customerPetDetailsComponent?.pets"
                  (openV1Event)="viewV1Appointment($event)" [isViewMode]="isViewMode">
                </app-view-v1-v2-customer-history>
              </mat-card>

              <mat-card appearance="outlined" class="mdc-mb-2" *ngIf="contextService.isPetStay() || petStayAddOnActive">
                <mat-card-content>
                  <app-view-customer-boardings #viewCustomerBoardingsComponent [contextIdDto]="contextIdDto"
                    [customerId]="customerDto?.id" [isViewMode]="isViewMode">
                  </app-view-customer-boardings>
                </mat-card-content>
              </mat-card>

              <mat-card appearance="outlined" class="mdc-mb-2">
                <mat-card-header>
                  <div fxLayout="row" fxLayoutAlign=" center" class="w-100 mdc-mb-1">
                    <div fxFlex>
                      <h6 class="mb-0">{{"CUSTOMER_NOTES" | translate}}</h6>
                      <!-- <div class="avatar avatar-50 circle border-3 d-inline-block" matTooltip="View Invoice">
                      <mat-icon class="view-invoice vm" (click)="editNotes = !editNotes">
                        edit
                      </mat-icon> Edit
                      <span *ngIf="editNotes">
                        <mat-icon matTooltip="Update Notes">save</mat-icon> Save
                      </span>
                    </div> -->
                    </div>
                    <div fxFlex="nogrow">
                      <button mat-button color="accent" (click)="editNotes = !editNotes" *ngIf="!editNotes">
                        <mat-icon class="vm">edit</mat-icon> <span class="vm">{{"EDIT_NOTES" | translate}}</span>
                      </button>
                      <button mat-button color="accent" (click)="updateCustomer()" *ngIf="editNotes">
                        <mat-icon class="vm">save</mat-icon> <span class="vm">{{"SAVE_NOTES" | translate}}</span>
                      </button>
                    </div>
                  </div>
                </mat-card-header>
                <!-- <hr> -->
                <mat-card-content>
                  <div fxLayout="column">
                    <div *ngIf="customerDto?.notes && !editNotes">
                      <p [innerText]="customerDto.notes"></p>
                    </div>
                    <div *ngIf="!customerDto?.notes && !editNotes">
                      <h6>{{"NO_CUSTOMER_NOTES" | translate}}</h6>
                    </div>
                    <mat-form-field *ngIf="editNotes" appearance="outline" class="w-100">
                      <mat-label>{{"NOTES" | translate}}</mat-label>
                      <textarea matInput [(ngModel)]="customerDto.notes" name="notes" rows="4"></textarea>
                    </mat-form-field>
                  </div>
                </mat-card-content>
              </mat-card>

              <app-customer-message-details [contextIdDto]="contextIdDto" [customerId]="customerDto.id">
              </app-customer-message-details>

              <app-customer-contracts-v2 [contextIdDto]="contextIdDto" [customerId]="customerDto.id">
              </app-customer-contracts-v2>
              <!-- <app-view-appointment-contracts [contextIdDto]="contextIdDto" [customerId]="customerDto.id">
            </app-view-appointment-contracts> -->
              <!-- <app-view-customer-description [contextIdDto]="contextIdDto" [customerDto]="customerDto">
            </app-view-customer-description> -->



              <div>
                <app-customer-stats-panel [contextIdDto]="contextIdDto" [customerDto]="customerDto">
                </app-customer-stats-panel>
              </div>
            </div>
            <div fxFlex="100" fxFlex.gt-sm="35" fxFlex.gt-md="30" fxFlex.gt-xl="32">
              <!-- customer buttons -->
              <app-customer-portal-buttons *ngIf="customerPortalAllowed && customerDto" [contextIdDto]="contextIdDto"
                [customerDto]="customerDto">
              </app-customer-portal-buttons>

              <!-- <app-quick-actions-buttons *ngIf="customerPortalAllowed && customerDto" [contextIdDto]="contextIdDto"
                [customerDto]="customerDto"></app-quick-actions-buttons> -->

              <app-customer-pet-details #customerPetDetailsComponent [contextIdDto]="contextIdDto"
                [customerDto]="customerDto" [isViewMode]="false" (apppointmentsChanged)="apppointmentsChanged($event)">
              </app-customer-pet-details>

              <app-appointment-invoice-summary [addInvoiceActive]="true" [contextIdDto]="contextIdDto"
                [customerId]="customerDto?.id">
              </app-appointment-invoice-summary>

              <mat-card appearance="outlined" class="mx-0 mdc-mb-2">
                <mat-card-header class="mdc-mb-1">
                  <div fxLayout="row" fxLayoutAlign=" center">
                    <div fxFlex>
                      <h6 class="">{{'FILES' | translate}}</h6>
                    </div>
                  </div>
                </mat-card-header>
                <mat-card-content>
                  <app-upload-files fxFlex="100" #uploadFiles [contextIdDto]="contextIdDto"
                    [savedFiles]="customerDto?.files" (savedFilesChange)="filesUpdated($event)">
                  </app-upload-files>
                </mat-card-content>
              </mat-card>

            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #elseBlock>
      <div class="flexbox-parent">
        <div fxLayout="row" fxLayoutAlign="center center" style="height: 60vh;">
          <div style="text-align:center;">
            <app-zero-image name="customer"></app-zero-image><br>
            <h5 class="ma-0">{{'CUSTOMER_NOT_EXIST' | translate}}.</h5>
          </div>
        </div>
      </div>
    </ng-template>


  </mat-sidenav-container>
</div>
