import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewDepositRequestsComponent } from '../../appointment/customer-payment/view-deposit-requests/view-deposit-requests.component';
import { ViewCustomerInvoiceComponent } from '../../appointment/customer-payment/view-customer-invoice/view-customer-invoice.component';
import { AppointmentInvoiceSummaryComponent } from '../../pets/view-pet-details-v2/appointment-invoice-summary/appointment-invoice-summary.component';
import { AddCustomerInvoiceModalComponent } from '../../invoice/add-customer-invoice-modal/add-customer-invoice-modal.component';
import { AddInvoiceCustomerSelectorComponent } from '../../invoice/add-customer-invoice/addInvoiceCustomerSelector.component';
import { AddCustomerInvoiceComponent } from '../../invoice/addCustomerInvoice.component';
import { PipesModule } from '../../pipes/pipes.module';
import { FloMaterialModule } from '../../flo.material.module';
import { TranslateModule } from '@ngx-translate/core';
import { DiscountPriceViewComponent } from '../../discount/discount-price-view/discount-price-view.component';
import { ViewCustomerPaymentsComponent } from '../../appointment/customer-payment/view-customer-payments/view-customer-payments.component';
import { ViewCustomerTipsComponent } from '../../appointment/customer-payment/view-customer-tips/view-customer-tips.component';
import { GenerateCustomerInvoiceModalComponent } from '../../petdaycare/view-pet-boarding/generate-customer-invoice-modal/generate-customer-invoice-modal.component';
import { PendingPaymentStatusComponent } from '../../appointment/customer-payment/view-deposit-requests/pending-payment-status/pending-payment-status.component';
import {
  ResendDepositRequestsComponent
} from "../../appointment/customer-payment/view-deposit-requests/resend-deposit-requests/resend-deposit-requests.component";

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    FloMaterialModule,
    TranslateModule
  ],
  declarations: [
    ViewCustomerInvoiceComponent,
    ViewDepositRequestsComponent,
    ResendDepositRequestsComponent,
    AppointmentInvoiceSummaryComponent,
    AddCustomerInvoiceModalComponent,
    AddInvoiceCustomerSelectorComponent,
    AddCustomerInvoiceComponent,
    DiscountPriceViewComponent,
    ViewCustomerPaymentsComponent,
    ViewCustomerTipsComponent,
    GenerateCustomerInvoiceModalComponent,
    PendingPaymentStatusComponent
  ],
  exports: [
    ViewCustomerInvoiceComponent,
    ViewDepositRequestsComponent,
    ResendDepositRequestsComponent,
    AppointmentInvoiceSummaryComponent,
    AddCustomerInvoiceModalComponent,
    AddInvoiceCustomerSelectorComponent,
    AddCustomerInvoiceComponent,
    DiscountPriceViewComponent,
    ViewCustomerPaymentsComponent,
    ViewCustomerTipsComponent,
    PendingPaymentStatusComponent
  ]
})
export class SharedInvoiceModule { }
