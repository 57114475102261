import { Injectable } from '@angular/core';
import { UiAuthService } from '../auth/ui-auth.service';
import { Router } from '@angular/router';
import { ContextService } from '../context.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ChatService } from '@savvy/chat';
import { MatDialog } from '@angular/material/dialog';
import { HelpModalComponent } from './help-modal/help-modal.component';

@Injectable()
export class HelpService {
  isChatOpen = false;
  constructor(
    public auth: UiAuthService,
    public router: Router,
    public contextService: ContextService,
    public deviceService: DeviceDetectorService,
    public chatService: ChatService,
    private dialog: MatDialog
  ) {

  }

  openHelp() {
    this.isChatOpen = true;
    const confirmDialog = this.dialog.open(HelpModalComponent, {
      data: {},
      height: 'auto',
      width: '98%',
      panelClass: ['helpwindow']
    });

    confirmDialog.afterClosed().subscribe((result: boolean) => {
      this.isChatOpen = false;
    });
  }

  isCustomerFacingPage(): boolean {

    const href = this.router.url;
    const currentURL = window.location.href;

    console.log('href is ' + href);
    console.log('currentURL is ' + currentURL);
    if (currentURL.indexOf('book/book') !== -1) {
      return true;
    }
    if (currentURL.indexOf('site/view') !== -1) {
      return true;
    }

    if (currentURL.indexOf('cp/customerViewContract') !== -1) {
      return true;
    }
    return false;
  }

}
