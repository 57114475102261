<div [formGroup]="form">
  <mat-form-field appearance="outline" style="width: 100%;">
    <mat-label>{{timeDefinition.label | translate}}</mat-label>
    <input matInput placeholder="{{timeDefinition.label | translate}}" [owlDateTimeTrigger]="dtPicker3"
      [owlDateTime]="dtPicker3" (focus)="dtPicker3.open()" (click)="dtPicker3.open()"
      [id]="elementInstanceDto.instanceId" [required]="timeDefinition.required"
      [formControlName]="elementInstanceDto.instanceId">

    <mat-icon matSuffix>schedule</mat-icon>
    <owl-date-time [pickerType]="'timer'" [stepMinute]="'15'" #dtPicker3></owl-date-time>

    <mat-error *ngIf="form.controls[elementInstanceDto.instanceId].hasError('required')">
      {{timeDefinition.label + ' is' | translate}} <strong>{{'required' | translate}}</strong>
    </mat-error>
  </mat-form-field>
</div>