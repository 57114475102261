import { Component, Input, OnInit } from '@angular/core';
import { EventBusService } from './EventBusService';
import { NavigateService } from '../entry/NavigateService';
import { Page } from '../shared/model/page';
import { Router } from '@angular/router';
import {
  CachedtablecompService, CachedTableRowDto,
  EntityInstanceId,
  ListCachedEntityTableResponse,
  PageDtoCachedTableRowDto
} from '@savvy/cached-view';
import { ViewAssociatedCachedTableEmbeddedData } from '@savvy/view-definition';
import { ContextIdDto } from '@savvy/quickbooks';
import { ListTableByEiIds } from '@savvy/cached-view/model/listTableByEiIds';
import { ViewContextDto } from '@savvy/view-composite';

@Component({
  selector: 'app-view-associated-embedded-cached-table',
  templateUrl: './viewAssociatedEmbeddedCachedTable.component.html'
})
export class ViewAssociatedEmbeddedCachedTableComponent implements OnInit {

  @Input() viewAssociatedCachedTableEmbeddedData: ViewAssociatedCachedTableEmbeddedData;
  @Input() contextIdDto: ContextIdDto;
  @Input() eventBus: EventBusService;
  @Input() viewContext: ViewContextDto;

  page = new Page();
  selected: any[] = [];
  cachedTableRowPageDto: PageDtoCachedTableRowDto;

  columns: any[] = [];
  rows = [];

  constructor(
    private router: Router,
    private navigateService: NavigateService,
    private cachedtablecompService: CachedtablecompService) {
  }

  ngOnInit() {
    this.page.size = 50;
    this.initCols();
    this.setPage({ offset: 0 });
  }

  initCols() {
    this.columns = new Array();
    if (this.viewAssociatedCachedTableEmbeddedData) {
      this.viewAssociatedCachedTableEmbeddedData.displayColumns.forEach(colName => {
        // console.log('colName is ' + colName);
        this.columns.push(this.getCol(colName));
      });
    }
  }

  getCol(colDef: string) {
    const col = {};
    // console.log('colDef is ' + colDef);
    col['prop'] = colDef;
    col['name'] = colDef;
    return col;
  }

  setPage(pageInfo) {
    // console.log('pageInfo is ' + pageInfo.offset);
    this.page.pageNumber = pageInfo.offset;
    this.loadPage();
  }

  loadPage() {
    console.log('loading page aect');
    if (this.viewAssociatedCachedTableEmbeddedData.entityInstanceIdsCommarSeparated &&
      this.viewAssociatedCachedTableEmbeddedData.entityInstanceIdsCommarSeparated.length > 0) {
      if (this.viewAssociatedCachedTableEmbeddedData && this.viewAssociatedCachedTableEmbeddedData.entityInstanceIds) {

        console.log('this.contextIdDto', this.contextIdDto);
        if (this.contextIdDto) {
          console.log('this.contextIdDto.contextIdType', this.contextIdDto.contextIdType);
          console.log('this.contextIdDto.contextId', this.contextIdDto.contextId);
        }
        const req = <ListTableByEiIds>{};
        req.contextIdDto = this.contextIdDto;
        req.cachedTableViewDefinitionId = this.viewAssociatedCachedTableEmbeddedData.cachedTableViewDefinitionId.id;
        req.entityInstanceIds = this.convert(this.viewAssociatedCachedTableEmbeddedData.entityInstanceIds);
        req.pageNum = (this.page.pageNumber + 1);
        req.pageSize = this.page.size;

        console.log('req listByEiIds', req);
        if (req.entityInstanceIds.length > 0) {
          this.cachedtablecompService.listByEiIds(req).subscribe(response => {
            this.handleResponse(response);
          });
        }
      }
    } else if (this.viewAssociatedCachedTableEmbeddedData.foreignKeyInstanceId) {
      this.cachedtablecompService.listCachedEntityTableWithForeignKey(
        this.viewAssociatedCachedTableEmbeddedData.cachedTableViewDefinitionId.id,
        this.viewAssociatedCachedTableEmbeddedData.foreignKeyInstanceId,
        (this.page.pageNumber + 1),
        this.page.size,
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType).subscribe(response => {
          this.handleResponse(response);
        });
    }
  }

  handleResponse(response: ListCachedEntityTableResponse) {
    this.cachedTableRowPageDto = response.cachedTableRowPageDto;
    this.page.totalElements = response.cachedTableRowPageDto.totalElements;
    this.page.totalPages = response.cachedTableRowPageDto.totalPages;
    this.buildTable();
  }

  buildTable() {
    if (this.cachedTableRowPageDto && this.cachedTableRowPageDto.contents) {
      const newRows = new Array();
      for (const aRow of this.cachedTableRowPageDto.contents) {
        newRows.push(this.getRow(aRow));
      }
      console.log('rows is now ' + newRows.length);
      this.rows = newRows;
    }
  }

  getRow(cachedTableRow: CachedTableRowDto) {
    const row = {};
    cachedTableRow.values.forEach((item, index) => {
      console.log('row ' + index + ' is ' + item);
      row[this.viewAssociatedCachedTableEmbeddedData.columnNames[index]] = item;
    });
    row['id'] = cachedTableRow.cachedTableRow.entityInstanceId;
    return row;
  }

  numRows() {
    return this.cachedTableRowPageDto.contents.length;
  }

  private convert(entityInstanceIds: Array<EntityInstanceId>): Array<string> {

    const arr = new Array();

    if (entityInstanceIds) {
      entityInstanceIds.forEach(entityInstanceId => {
        arr.push(entityInstanceId.id);
      });
    }
    return arr;
  }
}
