<mat-toolbar color="primary">
  <mat-toolbar-row>
    <h2>{{'ADD_CUSTOMER_TO_PLAN' | translate}}</h2>
  </mat-toolbar-row>
</mat-toolbar>

<br>

<mat-card appearance="outlined" class="demo-card demo-basic">
  <mat-card-content>
    <mat-hint>
      <span *ngIf="planDef.unlimitedSessions">{{'UNLIMITED_SESSIONS' | translate}}</span>
      <span *ngIf="!planDef.unlimitedSessions"><b>{{ planDef.numberOfSessions }} sessions.</b></span><br></mat-hint>
    <mat-hint *ngIf="planDef.paymentSchedule === 'IMMEDIATE'">{{'FULL_PAYMENT_OF' | translate}} <b>&#163; {{
        planDef.cost }}</b> {{'FULL_PAYMENT_DESC' | translate}}.<br></mat-hint>
    <mat-hint *ngIf="planDef.paymentSchedule === 'RECURRING' && planDef.paymentInterval === 'MONTHLY'"><b>&#163; {{
        planDef.cost }}</b> {{'PLAN_PAID_MONTHLY' | translate}}.<br></mat-hint>
    <mat-hint *ngIf="planDef.paymentSchedule === 'RECURRING' && planDef.paymentInterval === 'ANNUALLY'"><b>&#163; {{
        planDef.cost }}</b> {{'PLAN_PAID_ANNUALLY' | translate}}.<br></mat-hint>

    <br><br>
    <form #searchForm="ngForm">
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-hint style="color: red;"
          *ngIf="customerDto && !customerDto.username">{{'CUSTOMER_HAS_NO_EMAIL' |
          translate}}</mat-hint>
        <mat-label>{{'START_TYPING_TO_SEARCH_CUSTOMERS' | translate}}</mat-label>
        <input matInput placeholder="{{'START_TYPING_TO_FILTER' | translate}}" [matAutocomplete]="auto" required
          (keyup)="onKey($event)">


        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)"
          (optionSelected)="handler($event)">
          <mat-option *ngFor="let tuple of searchCustomerResult" [value]="tuple.id">
            {{ tuple.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field *ngIf="planDef.paymentSchedule === 'RECURRING' && planDef.paymentInterval === 'MONTHLY'"
        style="width: 100%" appearance="outline">
        <mat-label>{{'TAKE_PAYMENT_EVERY' | translate}}</mat-label>
        <input matInput type="number" [(ngModel)]="request.dayOfPayment" name="dayOfPayment" #dayOfPayment="ngModel"
          id="dayOfPayment" placeholder="{{'TAKE_PAYMENT_EVERY' | translate}}" required dayOfPayment>
        <mat-hint>{{'PAYMENT_PLAN_HINT' | translate}}</mat-hint>
      </mat-form-field>

      <button mat-raised-button color="accent" (click)="addToPlan()"
        [disabled]="!searchForm.form.valid || (customerDto && !customerDto.username)"
        disabled="disabled">{{'ADD_TO_PLAN' | translate}}
      </button>
    </form>
  </mat-card-content>
</mat-card>