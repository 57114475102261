<!--div>
<div *ngIf="!localMenuService">
  No menu service
</div>
<div *ngIf="localMenuService">
IN HERE MENU localMenuService.iconsLoaded {{ localMenuService.iconsLoaded }}
localMenuService.menuItems.length {{  localMenuService.menuItems.length }}
</div>
</div-->
<div class="mdc-p-2" *ngIf="subscriptionHelperService?.subscriptionStatusDto?.onTrial
|| (!subscriptionHelperService?.isFreemium && smsCreditStateService?.smsCreditSummaryDto?.remainingSmsCreditsIncludingPaid < 50)
|| (subscriptionHelperService?.subscriptionStatusDto?.active && subscriptionHelperService?.subscriptionStatusDto.subscriptionInterval === 'ANNUAL' && !subscriptionHelperService?.subscriptionStatusDto.autoRenew && subscriptionHelperService?.subscriptionStatusDto.numberOfDaysRemaining <= 10)
|| smsCreditLow
|| subscriptionHelperService?.subscriptionStatusDto?.onTrial">
  <mat-card appearance="outlined" *ngIf="subscriptionHelperService?.subscriptionStatusDto?.onTrial" class="subscription-reminder mdc-mb-2"
    (click)="goToSubscriptions()">
    <mat-card-content>
      <h6 class="mb-0 font-weight-bold">
        {{'UPGRADE_SUBSCRIPTION' | translate}} <mat-icon class="vm">keyboard_arrow_right</mat-icon>
      </h6>
      <p class="text-opac mb-0">{{'YOU_HAVE' | translate}} {{ subscriptionHelperService?.subscriptionStatusDto.numberOfDaysRemaining}}
        {{'TRIAL_DAYS_LEFT' | translate}}</p>
    </mat-card-content>
  </mat-card>
  <mat-card appearance="outlined"
    *ngIf="!subscriptionHelperService?.isFreemium && smsCreditStateService?.smsCreditSummaryDto?.remainingSmsCreditsIncludingPaid < 50"
    class="subscription-reminder  mdc-mb-2">
    <mat-card-content>
      <h6 class="mb-0 font-weight-bold">
        <mat-icon class="vm">sms_failed</mat-icon>{{'TOPUP_YOUR_ACC' | translate}}
      </h6>
      <p class="text-opac mb-0">{{'YOU_HAVE_ONLY' | translate}}
        {{smsCreditStateService?.smsCreditSummaryDto?.remainingSmsCreditsIncludingPaid}}
        {{'SMS_CREDITS_LEFT' | translate}}</p>
    </mat-card-content>
  </mat-card>
  <mat-card appearance="outlined"
    *ngIf="subscriptionHelperService?.subscriptionStatusDto?.active && subscriptionHelperService?.subscriptionStatusDto?.subscriptionInterval === 'ANNUAL' && !subscriptionHelperService?.subscriptionStatusDto?.autoRenew && subscriptionHelperService?.subscriptionStatusDto?.numberOfDaysRemaining <= 10"
    class="subscription-reminder mdc-mb-2">
    <mat-card-content>
      <h6 class="mb-0 font-weight-bold">{{'RENEW_SUBSCRIPTION' | translate}} <a (click)="goToSubscriptions()">
          <mat-icon class="vm">keyboard_arrow_right</mat-icon>
        </a></h6>
      <h6>{{'YOU_HAVE' | translate}} {{ subscriptionHelperService?.subscriptionStatusDto.numberOfDaysRemaining}} {{'SUBSCRIPTION_DAYS_LEFT' |
        translate}}</h6>
    </mat-card-content>
  </mat-card>
  <mat-card appearance="outlined" *ngIf="smsCreditLow" class="subscription-reminder  mdc-mb-2"
    (click)="smsCreditService.buyNewBundle(contextIdDto)">
    <mat-card-content>
      <h6 class="mb-0 font-weight-bold">
        {{'SMS_CREDITS_LOW' | translate}} <mat-icon class="vm">keyboard_arrow_right</mat-icon>
      </h6>
      <p class="text-opac mb-0">{{'RUNNING_OUT_OF_SMS' | translate}}</p>
    </mat-card-content>
  </mat-card>
  <mat-card appearance="outlined" *ngIf="subscriptionHelperService?.subscriptionStatusDto?.onTrial" class="subscription-reminder  mdc-mb-2"
    (click)="goToImportRequest()">
    <mat-card-content>
      <h6 class="mb-0 font-weight-bold">
        {{'IMPORT_REQUEST' | translate}} <mat-icon class="vm">keyboard_arrow_right</mat-icon>
      </h6>
      <p class="text-opac mb-0">{{'CLICK_FOR_IMP_REQ' | translate}}</p>
    </mat-card-content>
  </mat-card>
</div>
<mat-nav-list appAccordion class="navigation"
  *ngIf="localMenuService && localMenuService.iconsLoaded && localMenuService?.menuItems && localMenuService?.menuItems?.length > 0">
  <mat-list-item appAccordionLink *ngFor="let menuItem of localMenuService.menuItems" group="{{menuItem.state}}"
    routerLinkActive="true">
    <a [id]="menuItem.name | translate" appAccordionToggle class="relative" (click)="setMenuItemSvg(menuItem)"
      [routerLink]="['/', menuItem.state]" *ngIf="menuItem && menuItem.type === 'link'">
      <mat-icon *ngIf="menuItem.svgIcon && !menuItem.svgXml" [svgIcon]="menuItem.icon"></mat-icon>
      <div class="mr-1" *ngIf="menuItem.svgIcon && menuItem.svgXml" [innerHtml]="menuItem.svgXml | safe: 'html'"> </div>
      <mat-icon *ngIf="!menuItem.svgIcon">{{ menuItem.icon }}</mat-icon>
      <span>{{ menuItem.name | translate }}</span>
      <span fxFlex></span>
      <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuItem.badge">{{ badge.value }}</span>
    </a>
    <a [id]="menuItem.name | translate" appAccordionToggle class="relative" (click)="setMenuItemSvg(menuItem)"
      [routerLink]="['/', menuItem.params[0], menuItem.params[1], menuItem.params[2]]"
      *ngIf="menuItem && menuItem.type === 'linkWithThreeParams'">
      <mat-icon *ngIf="menuItem.svgIcon && !menuItem.svgXml" [svgIcon]="menuItem.icon"></mat-icon>
      <div class="mr-1" *ngIf="menuItem.svgIcon && menuItem.svgXml" [innerHtml]="menuItem.svgXml | safe: 'html'"> </div>
      <mat-icon *ngIf="!menuItem.svgIcon">{{ menuItem.icon }}</mat-icon>
      <span>{{ menuItem.name | translate }}</span>
      <span fxFlex></span>
      <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuItem.badge">{{ badge.value }}</span>
    </a>
    <a [id]="menuItem.name | translate" appAccordionToggle class="relative" (click)="setMenuItemSvg(menuItem)"
      [routerLink]="['/', menuItem.params[0], menuItem.params[1]]"
      *ngIf="menuItem && menuItem.type === 'linkWithTwoParams'">
      <mat-icon *ngIf="menuItem.svgIcon && !menuItem.svgXml" [svgIcon]="menuItem.icon">{{menuItem.icon}}</mat-icon>
      <div class="mr-1" *ngIf="menuItem.svgIcon && menuItem.svgXml" [innerHtml]="menuItem.svgXml | safe: 'html'"> </div>
      <mat-icon *ngIf="!menuItem.svgIcon">{{ menuItem.icon }}</mat-icon> <span>{{ menuItem.name | translate }}</span>
      <span fxFlex></span>
      <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuItem.badge">{{ badge.value }}</span>
    </a>
    <a [id]="menuItem.name | translate" appAccordionToggle class="relative" (click)="setMenuItemSvg(menuItem)"
      [routerLink]="['/', menuItem.params[0], menuItem.params[1], menuItem.params[2],
        menuItem.params[3]]" *ngIf="menuItem && menuItem.type === 'linkWithFourParams'">
      <mat-icon *ngIf="menuItem.svgIcon && !menuItem.svgXml" [svgIcon]="menuItem.icon">{{menuItem.icon}}</mat-icon>
      <div class="mr-1" *ngIf="menuItem.svgIcon && menuItem.svgXml" [innerHtml]="menuItem.svgXml | safe: 'html'"> </div>
      <mat-icon *ngIf="!menuItem.svgIcon">{{ menuItem.icon }}</mat-icon> <span>{{ menuItem.name | translate }}</span>
      <span fxFlex></span>
      <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuItem.badge">{{ badge.value }}</span>
    </a>
    <a [id]="menuItem.name | translate" appAccordionToggle class="relative" (click)="setMenuItemSvg(menuItem)"
      [routerLink]="['/', menuItem.params[0], menuItem.params[1], menuItem.params[2],
        menuItem.params[3], menuItem.params[4]]" *ngIf="menuItem && menuItem.type === 'linkWithFiveParams'">
      <mat-icon *ngIf="menuItem.svgIcon && !menuItem.svgXml" [svgIcon]="menuItem.icon">{{ menuItem.icon }}</mat-icon>
      <div class="mr-1" *ngIf="menuItem.svgIcon && menuItem.svgXml" [innerHtml]="menuItem.svgXml | safe: 'html'"> </div>
      <mat-icon *ngIf="!menuItem.svgIcon">{{ menuItem.icon }}</mat-icon> <span>{{ menuItem.name | translate }}</span>
      <span fxFlex></span>
      <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuItem.badge">{{ badge.value }}</span>
    </a>
    <a [id]="menuItem.name | translate" appAccordionToggle class="relative" href="{{menuItem.state}}"
      *ngIf="menuItem && menuItem.type === 'extLink'" (click)="setMenuItemSvg(menuItem)">
      <mat-icon *ngIf="menuItem.svgIcon && !menuItem.svgXml" [svgIcon]="menuItem.icon">{{ menuItem.icon }}</mat-icon>
      <div class="mr-1" *ngIf="menuItem.svgIcon && menuItem.svgXml" [innerHtml]="menuItem.svgXml | safe: 'html'"> </div>
      <mat-icon *ngIf="!menuItem.svgIcon">{{ menuItem.icon }}</mat-icon> <span>{{ menuItem.name | translate }}</span>
      <span fxFlex></span>
      <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuItem.badge">{{ badge.value }}</span>
    </a>
    <a [id]="menuItem.name | translate" appAccordionToggle class="relative" href="{{menuItem.state}}" target="_blank"
      *ngIf="menuItem && menuItem.type === 'extTabLink'" (click)="setMenuItemSvg(menuItem)">
      <mat-icon *ngIf="menuItem.svgIcon && !menuItem.svgXml" [svgIcon]="menuItem.icon">{{ menuItem.icon }}</mat-icon>
      <div class="mr-1" *ngIf="menuItem.svgIcon && menuItem.svgXml" [innerHtml]="menuItem.svgXml | safe: 'html'"> </div>
      <mat-icon *ngIf="!menuItem.svgIcon">{{ menuItem.icon }}</mat-icon> <span>{{ menuItem.name | translate }}</span>
      <span fxFlex></span>
      <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuItem.badge">{{ badge.value }}</span>
    </a>
    <!-- <a [id]="menuItem.name | translate" appAccordionToggle class="relative" href="javascript:void(0)"
      *ngIf="menuItem && menuItem.type === 'sub'" (click)="setMenuItemSvg(menuItem)">
      <mat-icon *ngIf="menuItem.svgIcon && !menuItem.svgXml" [svgIcon]="menuItem.icon"></mat-icon>
      <div class="mr-1" *ngIf="menuItem.svgIcon && menuItem.svgXml" [innerHtml]="menuItem.svgXml | safe: 'html'"> </div>
      <mat-icon *ngIf="!menuItem.svgIcon">{{ menuItem.icon }}</mat-icon> <span>{{ menuItem.name | translate }}</span>
      <span fxFlex></span>
      <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuItem.badge">{{ badge.value }}</span>
      <mat-icon class="menu-caret">arrow_drop_down</mat-icon>
    </a> -->
    <!-- <mat-nav-list class="sub-menu" *ngIf="menuItem && menuItem.type === 'sub'">
      <mat-list-item *ngFor="let childitem of menuItem.children" routerLinkActive="open">
        <a [id]="childitem.name" *ngIf="childitem && childitem.type === 'link'" (click)="setMenuItemSvg(menuItem)"
          [routerLink]="['/', menuItem.state, childitem.state ]" class="relative">
          {{ childitem.name | translate }}</a>
        <a [id]="childitem.name" *ngIf="childitem && !childitem.type" (click)="setMenuItemSvg(menuItem)"
          [routerLink]="['/', menuItem.state, childitem.state ]" class="relative">
          {{ childitem.name | translate }}</a>
        <a [id]="childitem.name" *ngIf="childitem && childitem.type === 'linkWithThreeParams'"
          (click)="setMenuItemSvg(menuItem)" [routerLink]="['/', childitem.params[0], childitem.params[1],
                childitem.params[2] ]" class="relative">
          {{ childitem.name | translate }}</a>
        <a [id]="childitem.name" *ngIf="childitem && childitem.type === 'linkWithTwoParams'"
          (click)="setMenuItemSvg(menuItem)" [routerLink]="['/', childitem.params[0], childitem.params[1] ]"
          class="relative">
          {{ childitem.name | translate}}</a>
        <a [id]="childitem.name" *ngIf="childitem && childitem.type === 'linkWithFourParams'"
          (click)="setMenuItemSvg(menuItem)" [routerLink]="['/', childitem.params[0], childitem.params[1],
                childitem.params[2], childitem.params[3] ]" class="relative">
          {{ childitem.name | translate }}</a>
        <a [id]="childitem.name" *ngIf="childitem && childitem.type === 'linkWithFiveParams'"
          (click)="setMenuItemSvg(menuItem)" [routerLink]="['/', childitem.params[0], childitem.params[1],
                childitem.params[2], childitem.params[3], childitem.params[4] ]" class="relative">
          {{ childitem.name | translate }}</a>
      </mat-list-item>
    </mat-nav-list> -->
  </mat-list-item>
</mat-nav-list>