import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {QuickLinkDto, Link, MessageService, CleanMessage} from '@savvy/messaging';
// import { Link } from '@savvy/send-to-all';
import { ContextIdDto } from '@savvy/app';
import { FloEditorComponent, Variable } from '../editor.component';

import * as Quill from 'quill';
import QuicklinksBlot from '../QuicklinksBlot';
import * as uuid from 'uuid';
import { ContextService } from '../../context.service';
import {Subject, Subscription} from 'rxjs';
import { PhoneNumberHelperService } from '../../shared/services/phone-number-helper.service';
import { TemplateCompService, TemplateValuesList } from '@savvy/template-engine';
import { LinkSelectedEvent } from '../templateLinkEvents';
import { UserCurrencyService } from '../../shared/services/userCurrency.service';
import {debounceTime} from "rxjs/operators";
import {SegmentedMessage} from "sms-segments-calculator";

@Component({
  selector: 'app-marketing-quick-links-editor',
  templateUrl: './marketing-quick-links-editor.component.html',
  styleUrls: ['./marketing-quick-links-editor.component.scss']
})
export class MarketingQuickLinksEditorComponent implements OnInit, OnDestroy {

  @Output() editorContentChange: EventEmitter<string> = new EventEmitter();

  @Input() selectedLinks: Link[];
  @Input() onlyShowVariables = false;
  @Input() showIncludeSection = true;
  @Input() templateValuesList: TemplateValuesList;
  @Input() messageType: 'EMAIL' | 'SMS' | 'WHATS_APP';

  @ViewChild(FloEditorComponent, { static: false }) messageEditor: FloEditorComponent;
  @ViewChild('messageEditor', { read: ElementRef }) messageEditorEl: ElementRef;

  subject = new Subject<string>();

  quickLinks: QuickLinkDto[];
  contextIdDto: ContextIdDto;
  subscriptions: Subscription[] = [];
  currencyCode = '';

  editorQuickLinksData: Variable[] = [];
  quickLinksData: any = {};
  editor: Quill;
  selectedQuickLink: Variable;
  selectedQuickLinkData: any;

  totalMessages = 0;
  numCredits = 1;
  private _selectedQuickLinkId: string;
  private editorContentLocal;
  constructor(
    private contextService: ContextService,
    private sharedService: PhoneNumberHelperService,
    private templateCompService: TemplateCompService,
    private userCurrencyService: UserCurrencyService,
    private messagingService: MessageService,
  ) { }

  get selectedEmailLink(): string {
    return this._selectedQuickLinkId;
  }
  public get editorContent(): string {
    return this.editorContentLocal;
  }
  @Input() public set editorContent(value: string) {
    this.editorContentLocal = value;
    this.editorContentChange.emit(value);
  }

  ngOnInit(): void {
    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data) {
        this.contextIdDto = data;
        this.loadCurrencyCode();
        this.setQuickLinks();
        this.loadVariables();
        this.loadNumberOfCredits();
      }
    }));
    this.getTotalMessages(this.editorContent);
  }

  ceil(value: number) {
    return Math.ceil(value);
  }

  chargedSMS(value) {
    return Math.ceil(Number(value));
  }

  setQuickLinks() {
    if (this.onlyShowVariables) {
      this.quickLinks = [{
        label: 'Variable',
        type: QuickLinkDto.TypeEnum.EntityInstance,
        icon: 'tag',
      }];
    } else {
      this.quickLinks = [
        {
          label: 'Variable',
          type: QuickLinkDto.TypeEnum.EntityInstance,
          icon: 'tag',
        },
        {
          label: 'Link',
          type: QuickLinkDto.TypeEnum.Href,
          icon: 'add_link',
        },

        {
          label: 'Deposit Request',
          type: QuickLinkDto.TypeEnum.RequestDeposit,
          icon: 'account_balance_wallet',
        },
        {
          label: 'Check-In Form',
          type: QuickLinkDto.TypeEnum.SendCheckinForm,
          icon: 'assignment',
        },
        {
          label: 'Questionnaire',
          type: QuickLinkDto.TypeEnum.Questionnaire,
          icon: 'help_outline',
        }
      ];
    }
  }

  loadVariables() {
    this.templateCompService.lookupValuesForMarketing(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
    ).subscribe(response => {
      this.templateValuesList = response;
    });
  }


  loadCurrencyCode() {
    this.userCurrencyService.getDefaultCurrency(this.contextIdDto)
      .subscribe(res => {
        this.currencyCode = res.org.currencyCode ? res.org.currencyCode : this.userCurrencyService.defaultCurrency;
      });
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  onEditorCreated(editor: Quill) {
    if (!editor) {
      return;
    }
    if (!this.selectedLinks) {
      this.selectedLinks = [];
    }
    this.editor = editor;
    this.setQuicklinksFromEditor();
    this.editor.root.addEventListener('click', (ev) => {
      this.selectedQuickLink = null;
      this.selectedQuickLinkData = null;
      let blotObj = Quill.find(ev.target);
      if (!blotObj) {
        blotObj = Quill.find(ev.target.parentElement);
      }
      if (blotObj instanceof QuicklinksBlot) {
        this.onQuickLinkSelect(blotObj);
      }
    });
  }

  onQuickLinkSelect(quickLinkBlot: QuicklinksBlot) {
    this.selectedQuickLink = quickLinkBlot.value().Quicklinks;
    if (this.quickLinksData[this.selectedQuickLink.id]) {
      this._selectedQuickLinkId = this.quickLinksData[this.selectedQuickLink.id].type;
      this.selectedQuickLinkData = this.quickLinksData[this.selectedQuickLink.id];
    }
  }

  onQuickLinksAction(data: LinkSelectedEvent) {
    console.log('onQuickLinksAction', data);
    if (this.selectedQuickLink && this.selectedQuickLink.id) {
      this.updateQuicklink(data);
    } else {
      this.addQuicklink(data);
    }
  }

  variableAdded(data: any) {
    const variable: Variable = {} as Variable;
    variable.id = '_' + uuid.v4().replace(/-/g, '_');
    variable.type = this.selectedEmailLink;
    variable.value = data.id;
    this.messageEditor.insertVariable(variable);
    this.editorContentChange.emit(this.editorContent);
  }

  setQuicklinksFromEditor() {
    this.editorQuickLinksData = this.editor.getContents()
      .filter((v: any) => v.insert && v.insert.Quicklinks)
      .map((v: any) => {
        const quicklink = v.insert.Quicklinks;
        this.setQuicklinkAttributes(quicklink, quicklink);
        return quicklink;
      });
    this.setQuicklinksDataFromLinks();
  }

  setQuicklinksDataFromLinks() {
    this.editorQuickLinksData.forEach(q => {
      const linkData = this.selectedLinks.find(l => l.quickLinkId === q.id);
      if (linkData) {
        let quickLinksData = null;
        if (q && q.type) {
          if (q.type === Link.LinkTypeEnum.RequestDeposit) {
            quickLinksData = linkData.requestDepositLink;
          } else if (q.type === Link.LinkTypeEnum.Href) {
            quickLinksData = {
              label: linkData.title,
              link: linkData.href
            };
          } else if (q.type === Link.LinkTypeEnum.CustomerPortalSignupExisting) {
            quickLinksData = {
              label: linkData.title
            };
          } else if (q.type === Link.LinkTypeEnum.CustomerPortalSignupNew) {
            quickLinksData = {
              label: linkData.title
            };
          }  else if (q.type === Link.LinkTypeEnum.Invoice) {
            quickLinksData = {
              label: linkData.title
            };
          } else if (q.type === Link.LinkTypeEnum.SendCheckinForm) {
            if (linkData.sendCheckInFormLink && linkData.sendCheckInFormLink.checkinFormDefinitionId) {
              quickLinksData = {
                label: linkData.title,
                id: linkData.sendCheckInFormLink.checkinFormDefinitionId.id
              };
            } else {
              quickLinksData = {
                label: linkData.title,
                id: ''
              };
            }
          } else if (q.type === Link.LinkTypeEnum.Questionnaire) {
            if (linkData.sendQuestionnaireLink && linkData.sendQuestionnaireLink.questionnaireDefinitionId) {
              quickLinksData = {
                label: linkData.title,
                id: linkData.sendQuestionnaireLink.questionnaireDefinitionId.id
              };
            } else {
              quickLinksData = {
                label: linkData.title,
                id: ''
              };
            }
          }
          if (quickLinksData && q) {
            quickLinksData.type = q.type;
          }
          this.quickLinksData[q.id] = quickLinksData;
        } else if (q.type === Link.LinkTypeEnum.EntityInstance) {
          this.quickLinksData[q.id] = {
            label: q.label,
            type: q.type
          };
        } else if (q.type === Link.LinkTypeEnum.Appointment) {
          this.quickLinksData[q.id] = {
            label: q.label,
            type: q.type
          };

        }
      }

    });
  }

  getQuicklinksFromEditor(): Variable[] {
    return this.editor.getContents()
      .filter((v: any) => v.insert && v.insert.Quicklinks)
      .map((v: any) => {
        const quicklink = v.insert.Quicklinks;
        this.setQuicklinkAttributes(quicklink, quicklink);
        return quicklink;
      });

  }

  getLinksFromQuicklinksData(): Link[] {
    const quicklinks: Variable[] = this.getQuicklinksFromEditor();
    if (!quicklinks || !quicklinks.length) {
      return [];
    }

    return quicklinks
      .filter(q => q.type !== Link.LinkTypeEnum.EntityInstance && this.quickLinksData[q.id])
      .map(q => {
        const link: Link = <Link>{};
        link.quickLinkId = q.id;
        link.title = q.label;

        const linkData: any = this.quickLinksData[q.id];
        if (q.type === Link.LinkTypeEnum.RequestDeposit) {
          link.linkType = Link.LinkTypeEnum.RequestDeposit;
          link.requestDepositLink = linkData;
        } else if (q.type === Link.LinkTypeEnum.Href) {
          link.linkType = Link.LinkTypeEnum.Href;
          link.href = linkData.link;
        } else if (q.type === Link.LinkTypeEnum.CustomerPortalSignupExisting) {
          link.linkType = Link.LinkTypeEnum.CustomerPortalSignupExisting;
        } else if (q.type === Link.LinkTypeEnum.CustomerPortalSignupNew) {
          link.linkType = Link.LinkTypeEnum.CustomerPortalSignupNew;
        } else if (q.type === Link.LinkTypeEnum.Invoice) {
          link.linkType = Link.LinkTypeEnum.Invoice;
        } else if (q.type === Link.LinkTypeEnum.SendCheckinForm) {
          link.linkType = Link.LinkTypeEnum.SendCheckinForm;
          link.sendCheckInFormLink = {
            checkinFormDefinitionId: {
              id: linkData.id
            }
          };
        } else if (q.type === Link.LinkTypeEnum.Questionnaire) {
          link.linkType = Link.LinkTypeEnum.Questionnaire;
          link.sendQuestionnaireLink = {
            questionnaireDefinitionId: {
              id: linkData.id
            }
          };
        }
        return link;
      });
  }

  setQuicklinkAttributes(quicklink: Variable, data: LinkSelectedEvent) {
    if (data.type === Link.LinkTypeEnum.Href || data.type === Link.LinkTypeEnum.CustomerPortalSignupExisting || data.type === Link.LinkTypeEnum.CustomerPortalSignupNew) {
      quicklink.label = data.label;
      quicklink.cssClassName = 'special-link';
    } else if (data.type === Link.LinkTypeEnum.EntityInstance) {
      quicklink.label = data.data ? data.data.key : data.label;
    } else if (data.type === Link.LinkTypeEnum.RequestDeposit) {
      quicklink.label = 'Deposit Request';
      quicklink.cssClassName = 'special-link';
    } else if (data.type === Link.LinkTypeEnum.SendCheckinForm) {
      quicklink.label = data.label;
      quicklink.cssClassName = 'special-link';
    } else if (data.type === Link.LinkTypeEnum.Invoice) {
      quicklink.label = data.label;
      quicklink.cssClassName = 'special-link';
    } else if (data.type === Link.LinkTypeEnum.Questionnaire) {
      quicklink.label = data.label;
      quicklink.cssClassName = 'special-link';
    } else if (data.type === Link.LinkTypeEnum.CustomerPortal) {
      quicklink.label = data.label;
      quicklink.cssClassName = 'special-link';
    } else if (data.type === Link.LinkTypeEnum.Invoice) {
      quicklink.label = data.label;
    }
  }

  updateQuicklink(data: LinkSelectedEvent) {
    const quicklinkVar: Variable = <Variable>{};
    data.type = this.selectedEmailLink;
    this.setQuicklinkAttributes(quicklinkVar, data);

    quicklinkVar.id = this.selectedQuickLink.id;
    quicklinkVar.type = this.selectedEmailLink;

    this.messageEditor.includeQuicklink(quicklinkVar, true);

    this.editorQuickLinksData[this.editorQuickLinksData.findIndex(v => v.id === quicklinkVar.id)] = quicklinkVar;
    this.quickLinksData[quicklinkVar.id] = data;
  }

  addQuicklink(data: LinkSelectedEvent) {
    console.log('addQuicklink', data);
    const quicklinkVar: Variable = <Variable>{};
    data.type = data.type ? data.type : this.selectedEmailLink;
    console.log('selectedEmailLink', this.selectedEmailLink);
    console.log('addQuicklink', data);

    this.setQuicklinkAttributes(quicklinkVar, data);

    quicklinkVar.id = '_' + uuid.v4().replace(/-/g, '_');
    quicklinkVar.type = data.type ? data.type : this.selectedEmailLink;

    this.messageEditor.includeQuicklink(quicklinkVar, false);

    console.log('quicklinkVar', quicklinkVar);
    this.editorQuickLinksData.push(quicklinkVar);
    this.quickLinksData[quicklinkVar.id] = data;
  }

  emailLinkUpdated(event, source: string) {
    if (source === 'api') {
      this.selectedQuickLink = null;
      this.selectedQuickLinkData = null;
    }
    this.messageEditorEl.nativeElement.scrollIntoView();
    this._selectedQuickLinkId = event;
  }


  ontextUpdated(event) {
    if (!this.editorContentChange) {
      return;
    }
    this.editorContentChange.emit(event);
    console.log(event);
    this.getTotalMessages(this.getEditorText());
    if (this.getEditorText()) {
      this.subject.next(this.getEditorText());
    }
  }

  getEditorText(): string {
    return this.messageEditor.text;
  }

  getTotalMessages(text) {
    if (text?.length) {
      this.totalMessages = Math.floor(text?.length / 160) + 1;
    } else {
      this.totalMessages = 0;
    }
  }

  loadNumberOfCredits() {
    this.subject.pipe(
      debounceTime(600)
    ).subscribe((data: any) => {
      if (this.messageType === 'SMS') {
        let req = <CleanMessage>{};
        req.message = data;
        this.messagingService.cleanMessage(req).subscribe(res => {
          const message = new SegmentedMessage(res.message);
          this.numCredits = message.segmentsCount;
        });
      }

    });
  }

}
