/* eslint-disable max-len */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CreatePetAppointmentV2Component } from './create-pet-appointment-v2/create-pet-appointment-v2.component';

import { AppointmentSelectorV2Component } from './shared/appointment-selector-v2/appointment-selector-v2.component';

import { CreatePetAppointmentV2SyncService } from './create-pet-appointment-v2/create-pet-appointment-v2-sync.service';

import { PetSelectorV2Component } from './create-pet-appointment-v2/pet-selector-v2/pet-selector-v2.component';
import { PetServiceSelectorV2Component } from './create-pet-appointment-v2/pet-service-selector-v2/pet-service-selector-v2.component';

import { PetServicesSelectorV2Component } from './create-pet-appointment-v2/pet-service-selector-v2/pet-services-selector-v2.component';

import { CreateAppointmentV2SyncService } from './create-appointment-v2/create-appointment-v2-sync.service';

import { ServiceSelectorV2Component } from './create-appointment-v2/service-selector-v2/service-selector-v2.component';

import { ServicesSelectorV2Component } from './create-appointment-v2/service-selector-v2/services-selector-v2.component';

import { TranslateModule } from '@ngx-translate/core';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AppCustomerModule } from '../customer/customer.module';
import { FloMaterialModule } from '../flo.material.module';
import { AppPetModule } from '../pets/pet.module';
import { SharedModule } from '../shared/shared.module';
import { AppointmentRulesService } from './appointment-rules.component';
import { ConfirmAppointmentV2Component } from './create-appointment-v2/confirm-appointment-v2/confirm-appointment-v2.component';
import { CreateAppointmentV2Component } from './create-appointment-v2/create-appointment-v2.component';
import { PackageSelectorV2Component } from './create-appointment-v2/package-selector-v2/package-selector-v2/package-selector-v2.component';
import { PackagesSelectorV2Component } from './create-appointment-v2/package-selector-v2/packages-selector-v2.component';
import { PickupServiceComponent } from './create-pet-appointment-v2/pickup-service/pickup-service.component';
import { RepeatAppointmentsComponent } from './create-pet-appointment-v2/repeat-appointments/repeat-appointments.component';
import { RepeatCalendarComponent } from './create-pet-appointment-v2/repeat-appointments/repeat-calendar/repeat-calendar.component';
import { RepeatFilterComponent } from './create-pet-appointment-v2/repeat-appointments/repeat-filter/repeat-filter.component';
import { CreatePetAppointmentV3Component } from './create-pet-appointment-v3/create-pet-appointment-v3.component';
import { WorkflowSelectorV2Component } from './shared/workflow-selector-v2/workflow-selector-v2.component';
import { ValidateAppointmentV2Service } from './validate-appointment-v2.service';
@NgModule({
  imports: [
    CommonModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    FloMaterialModule,
    TranslateModule,
    AppPetModule,
    SharedModule,
    AppCustomerModule
  ],
  declarations: [
    // Shared
    AppointmentSelectorV2Component,

    WorkflowSelectorV2Component,

    // Pet version Dependencies
    CreatePetAppointmentV2Component,

    PetSelectorV2Component,
    // NewPetCustomerSelectorV2Component,
    // CreatePetExistingCustomerComponent,
    PetServicesSelectorV2Component,
    PetServiceSelectorV2Component,

    // Non Pet version Dependencies
    CreateAppointmentV2Component,
    ServicesSelectorV2Component,
    ServiceSelectorV2Component,

    // PaymentComponent,
    // ConfirmPetAppointmentV2Component,
    ConfirmAppointmentV2Component,
    PickupServiceComponent,
    RepeatAppointmentsComponent,
    RepeatFilterComponent,
    RepeatCalendarComponent,
    PackagesSelectorV2Component,
    PackageSelectorV2Component,
    CreatePetAppointmentV3Component
  ],
  providers: [
    CreatePetAppointmentV2SyncService,
    CreateAppointmentV2SyncService,
    AppointmentRulesService,
    ValidateAppointmentV2Service
  ]
})
export class AppointmentsV2Module { }
