import { Directive } from '@angular/core';
import {
  NG_VALIDATORS,
  UntypedFormControl,
  ValidatorFn,
  Validator
} from '@angular/forms';
@Directive({
  selector: '[appSenderidValidator][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: SenderidValidator,
      multi: true
    }
  ]
})
export class SenderidValidator implements Validator {
  validator: ValidatorFn;
  constructor() {
    this.validator = this.senderIdValidator();
  }
  validate(c: UntypedFormControl) {
    return this.validator(c);
  }

  senderIdValidator(): any {
    return (c: UntypedFormControl) => {
      const isValid = /^(?=.*[a-zA-Z])(?=.*[a-zA-Z0-9])([a-zA-Z0-9 ]{1,11})$/m.test(c.value);
      if (isValid) {
        return null;
      } else {
        return {
          senderIdValidator: {
            valid: false
          }
        };
      }
    };
  }
}
