import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ElementControlService } from '../ElementControlService';
import { ChangeListener } from '../changeListener';
import { EntitysService } from '@savvy/entity-instance';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { BreakpointObserver } from '@angular/cdk/layout';
import { CachedlistcompService } from '@savvy/cached-view';
import { ClearObservable } from '../../shared/classes/clear-observable';
import { takeUntil } from 'rxjs/operators';
import { CreateEntityPageComponent } from '../createEntityPage.component';
import {
  EntityChangeEvent,
  FieldValueDto,
  EicompService,
  LinkedEntityDefinitionDto,
  ContextIdDto,
  ElementInstanceDto
} from '@savvy/entity-instance-composite';

@Component({
  selector: 'app-linked-entity-instance-select',
  templateUrl: 'linkedEntityInstanceSelect.component.html'
})
export class LinkedEntityInstanceSelectComponent extends ClearObservable implements OnInit {

  @Input() linkedEntityDefinition: LinkedEntityDefinitionDto;
  @Input() contextIdDto: ContextIdDto;
  @Input() elementInstanceDto: ElementInstanceDto;
  @Input() entityInstanceId: string;
  @Input() additionalDataMapDto: any;


  @Input() form: UntypedFormGroup;
  @Input() showPlaceholder: boolean;
  @Input() changeListener: ChangeListener;
  selectedItem: string;

  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
    private cachedListCompositeApi: CachedlistcompService,
    private eiApi: EntitysService,
    private eiCompositeApi: EicompService,
    private ecs: ElementControlService) {
    super();
  }

  ngOnInit() {
    this.linkedEntityDefinition.allowMulti = false;
    const selectedValues = this.elementInstanceDto.fieldInstanceDto.value.strArrayValue;
    if (selectedValues && selectedValues.length === 1) {
      this.selectedItem = this.elementInstanceDto.fieldInstanceDto.value.strArrayValue[0];
      console.log('selectedItem is:' + this.selectedItem);
    }
  }

  changedOptions(event: MatSelectChange) {
    const fieldValue: FieldValueDto = {
      strArrayValue: [],
      valueType: FieldValueDto.ValueTypeEnum.StringArray
    };

    const singleStrArr = [];

    if (!this.linkedEntityDefinition.allowMulti) {
      singleStrArr.push(event.value);
    }

    fieldValue.strArrayValue = this.linkedEntityDefinition.allowMulti ? event.value : singleStrArr;

    const req: EntityChangeEvent = {
      contextIdDto: this.contextIdDto,
      fieldValue,
      entityInstanceId: this.entityInstanceId,
      fieldInstanceId: this.elementInstanceDto.instanceId
    };

    this.eiCompositeApi.entityInstanceChangeEvent(req)
      .pipe(takeUntil(this.destroy$)).subscribe(
        response => {
          this.ecs.handleChangedElements(response.changedElementList, this.form);
          if (this.changeListener) {
            this.changeListener.add('change');
          }
        }
      );
  }

  getPlaceholder() {
    if (this.showPlaceholder) {
      return this.linkedEntityDefinition.placeholder;
    }
    return '';
  }

  openCreateModal() {
    const dialogRef = this.dialog.open(CreateEntityPageComponent, {
      data: {
        entityDefinitionId: this.linkedEntityDefinition.targetEntityDefinitionId,
        contextIdDto: this.contextIdDto,
        isModalOpen: true
      },
      width: '100vw',
      maxWidth: '55vw'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log('dialog closed!', result);
      }
    });
  }
}

