import { NgModule } from '@angular/core';
import { LocalizedDatePipe } from './localized-date.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { PackageFilterPipe } from './package-filter.pipe';
import { ProductFilterPipe } from './product-filter.pipe';
import { DiscountCalculatorPipe } from './discount-calculator.pipe';
import { DiscountTmplatePipe } from './discount-template.pipe';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { DateAgoPipe } from './date-ago.pipe';
import { SearchPipe } from './search.pipe';
import { SortFilterPipe } from './sort-filter.pipe';
import {LocalizedTimePipe} from "./localized-time.pipe";

@NgModule({
  imports: [
    TranslateModule
  ],
  declarations: [
    LocalizedDatePipe,
    LocalizedTimePipe,
    PackageFilterPipe,
    ProductFilterPipe,
    DiscountCalculatorPipe,
    DiscountTmplatePipe,
    DateAgoPipe,
    SearchPipe,
    SortFilterPipe
  ],
  exports: [
    LocalizedDatePipe,
    LocalizedTimePipe,
    PackageFilterPipe,
    ProductFilterPipe,
    DiscountCalculatorPipe,
    DiscountTmplatePipe,
    DateAgoPipe,
    SearchPipe,
    SortFilterPipe
  ],
  providers: [
    DatePipe,
    CurrencyPipe,
    DateAgoPipe,
    LocalizedDatePipe,
    LocalizedTimePipe
  ]
})

export class PipesModule {
}
