<app-zero-tasks *ngIf="zeroState" [contextIdDto]="contextIdDto" [eventBus]="eventBus">
</app-zero-tasks>

<mat-toolbar color="primary" *ngIf="!zeroState">
    <span class="mr-1 ml-1">{{'TASK_LIST' | translate}}</span>
    <div class="example-spacer"></div>
    <button mat-flat-button (click)="toggleTaskView()">{{'TOGGLE_VIEW' | translate}}</button>
</mat-toolbar>

<mat-sidenav-container class="app-inner" style="width: 100%">

    <mat-sidenav #taskPanel position="end" class="task-panel" mode="over" opened="false" style="width:80%">
        <app-show-task *ngIf="contextIdDto" [contextIdDto]="contextIdDto" [eventBus]="eventBus" [visible]="activePanel === 'showTask'"></app-show-task>
        <app-add-edit-task *ngIf="contextIdDto" [contextIdDto]="contextIdDto" [eventBus]="eventBus"
                           [visible]="activePanel === 'addEditTask'"></app-add-edit-task>
    </mat-sidenav>


    <app-tasks-panel #tasksPanel *ngIf="contextIdDto && !zeroState && response && response.taskSetDtos"
                     [taskSetDtos]="response.taskSetDtos"
                     [eventBus]="eventBus"
                     [contextIdDto]="contextIdDto">

    </app-tasks-panel>

    <!--button mat-fab color="accent" (click)="createTask()" *ngIf="!zeroState" class="mat-fab-bottom-right">
        <mat-icon class="mat-24">add</mat-icon>
    </button-->
</mat-sidenav-container>
