import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MessageService, PhoneNumber } from '@savvy/messaging';
import parsePhoneNumberFromString, { CountryCode, getCountryCallingCode, PhoneNumber as PhoneNumberLib } from 'libphonenumber-js';
import * as _ from 'lodash';
import { NgxMatInputTelComponent } from 'ngx-mat-input-tel';
import { Country } from 'ngx-mat-input-tel/lib/model/country.model';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';
import { FloSnackbarComponent } from 'src/app/flo/snackbar/floSnackbar.component';
import { UserCountryService } from 'src/app/services/userCountry.service';
import * as uuid from 'uuid';
import { ClearObservable } from '../../classes/clear-observable';

const noop = () => {
};
@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneInputComponent),
      multi: true
    }
  ],
})
export class PhoneInputComponent extends ClearObservable implements ControlValueAccessor, OnInit {
  @ViewChild('phone', { static: true }) phoneNumber: NgxMatInputTelComponent;
  @Input() label = '';
  @Input() errorName = '';
  @Input() selectedCountryCode: CountryCode = 'GB';
  @Input() required: string | boolean = false;
  @Input() disabled = false;
  @Input() placeholder = '';
  @Input() id = uuid.v4();

  @Output() phoneChanged = new EventEmitter<PhoneNumber>();
  @Output() countryChanged = new EventEmitter<Country>();

  countryObj: Country;
  hasFocus = false;
  separateDialCode = true;
  // searchCountryField = SearchCountryField;
  countryISO: { 'countries': CountryCode } = { 'countries': 'GB' };
  // phoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryCode[] = [
    "GB",
    "US",
    "NZ"
  ];
  phoneForm = new FormGroup({
    phoneNumber: new FormControl('', [])
  });
  defaultCountryCode: CountryCode = 'GB';
  valid = true;
  // Placeholders for the callbacks which are later provided
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  constructor(
    private cd: ChangeDetectorRef,
    private notify: FloSnackbarComponent,
    private userCountryService: UserCountryService,
    private messageService: MessageService
  ) {
    super();
  }

  checkIfValidMsisDn(number: string) {
    return new Promise((resolve) => {
      this.messageService.validateMsisdn(number).subscribe(res => {
        this.valid = true;
        resolve(true);
      }, error => {
        if (error.status === 400) {
          this.valid = false;
          this.phoneForm.controls.phoneNumber.setErrors({ 'invalidMsisdn': true });
          this.phoneForm.controls.phoneNumber.markAsDirty();
          resolve(false);
        }
      });
    });
  }

  ngOnInit(): void {
    this.userCountryService.countryCode$
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.defaultCountryCode = res;
        if (!this.phoneForm?.controls?.phoneNumber?.value) {
          this.selectedCountryCode = res;
        }
      });

    if (this.required === true || this.required === 'true') {
      this.phoneForm.controls.phoneNumber.setValidators([Validators.required]);
      this.placeholder = this.placeholder + ' *';
    }

    if (this.disabled) {
      this.phoneForm.controls.phoneNumber.disable();
    }
    if (!this.selectedCountryCode && this.userCountryService?.defaultCountryCode) {
      // If PhoneNumber input is not have country set use default country code
      this.selectedCountryCode = this.userCountryService?.defaultCountryCode;
    }
    if (this.selectedCountryCode && this.phoneNumber) {
      const selectedCountry = Object.values(this.phoneNumber.allCountries).find(v => v.iso2.toLowerCase() === this.selectedCountryCode.toLowerCase());
      if (selectedCountry) {
        // this.phoneForm.controls.phone.patchValue(`+${selectedCountry?.dialCode}`);
        if (!this.phoneNumber.numberInstance) {
          this.phoneNumber.numberInstance = {} as PhoneNumberLib;
        }
        this.phoneNumber.numberInstance.countryCallingCode = getCountryCallingCode(this.selectedCountryCode.toUpperCase() as CountryCode);
        this.phoneNumber.numberInstance.country = this.selectedCountryCode;
      }
    }

    if (this.phoneForm && this.phoneForm?.get('phoneNumber')) {

      this.phoneForm.get('phoneNumber')
        ?.valueChanges
        .pipe(startWith(''))
        .pipe(debounceTime(800))
        .subscribe((value) => {
          if (value) {
            const phoneNumberTemp = parsePhoneNumberFromString(value);
            if (phoneNumberTemp?.country) {
              const phoneNumber: PhoneNumber = {
                country: phoneNumberTemp?.country as string,
                internationalNumber: phoneNumberTemp.formatInternational().replace(/[- )(]/g, ''),
                nationalNumber: phoneNumberTemp?.formatNational().replace(/[- )(]/g, '')
              };
              this.checkIfValidMsisDn(phoneNumber?.internationalNumber).then(valid => {
                if (valid) {
                  this.phoneChanged.emit(phoneNumber);
                }
              });
            }
          } else if (value === undefined) {
            this.phoneChanged.emit({
              country: '',
              internationalNumber: '',
              nationalNumber: ''
            });
          }
        });
    }
  }

  onCountryChange(event: Country) {
    this.selectedCountryCode = _.clone(event.iso2);
    this.countryObj = _.clone(event);
    if (this.phoneForm?.controls?.phoneNumber?.value) {
      this.cd.detectChanges();
      this.countryChanged.emit(event);
    }
  }

  onFocus() {
    this.hasFocus = true;
  }

  // From ControlValueAccessor interface
  writeValue(value: any) {
    if (value && value !== null && value !== this.phoneForm.controls.phoneNumber.value) {
      console.log('setting value in phone input to ', value);
      if (this.countryObj) {
        this.phoneForm.patchValue({ phoneNumber: value });
      }
      this.phoneForm.controls.phoneNumber.updateValueAndValidity();
      this.cd.detectChanges();
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

}
