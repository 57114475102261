import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";

import {ContextIdDto, LinkId, Notification} from '@savvy/notification';
import {FloSnackbarComponent} from "../../snackbar/floSnackbar.component";
import {MatDialog} from "@angular/material/dialog";
import {UrlLookupService} from "../../shared/services/urlLookup.service";
import {Customer} from "@savvy/customer";
import {ViewCustomerV2Component} from "../../customer/view-customer-v2/view-customer-v2.component";
import {Router} from "@angular/router";

@Component({
  selector: 'app-release-notes-notification',
  templateUrl: './releaseNotesNotification.component.html'
})
export class ReleaseNotesNotificationComponent implements OnInit {

  @Input() notification: Notification;
  @Input() contextIdDto: ContextIdDto;

  @Output() notificationProcessed: EventEmitter<any> = new EventEmitter();

  customer: Customer;

  constructor(private notify: FloSnackbarComponent,
              private router: Router,
              private urlLookupService: UrlLookupService,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {

  }

  gotoReleaseNotes() {
    this.router.navigate(['releaseNotes']);
  }
}
