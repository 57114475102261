import { Component, Input, OnInit } from '@angular/core';
import { AppointmentDataV1V2 } from '@savvy/appointment';

@Component({
  selector: 'app-upcoming-appointment',
  templateUrl: './upcoming-appointment.component.html',
  styleUrls: ['./upcoming-appointment.component.scss']
})
export class UpcomingAppointmentComponent implements OnInit {
  @Input() upcomingAppointment: AppointmentDataV1V2;
  startTime: Date;
  endTime: Date;

  constructor(
  ) { }

  ngOnInit(): void {
  }

}
