import {Routes} from '@angular/router';

import {EditEntityComponent} from '../element/editEntity.component';
import {ListEntityInstancesComponent} from '../element/listEntityInstances.component';
import {ViewEntityPageComponent} from '../element/viewEntityPage.component';
import {CreateEntityPageComponent} from '../element/createEntityPage.component';
import {ListCachedEntityInstancesPageComponent} from '../element/listCachedEntityInstancesPage.component';

export const EntityRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'createEntity/:entityDefinitionId/:contextId/:contextIdType/:customerId', component: CreateEntityPageComponent,
        data: {
          breadcrumb: 'Create'
        }
      },
      {
        path: 'createEntity/:entityDefinitionId/:contextId/:contextIdType', component: CreateEntityPageComponent,
        data: {
          breadcrumb: 'Create'
        }
      },
      {
        path: 'createEntity/:entityDefinitionId/:contextId/:contextIdType/:linkedEntityInstanceId/:linkedEntityDefinitionId',
        component: CreateEntityPageComponent,
        data: {
          breadcrumb: 'Create'
        }
      },
      {
        path: 'viewEntity/:entityInstanceId/:contextId/:contextIdType',
        component: ViewEntityPageComponent,
        data: {
          breadcrumb: 'View'
        }
      },
      {
        path: 'editEntity/:entityInstanceId/:contextId/:contextIdType', component: EditEntityComponent,
        data: {
          breadcrumb: 'Edit',
          topLevel: 'true'
        }
      },
      {
        path: 'listEntityInsts/:entityDefinitionId/:contextId/:contextIdType', component: ListEntityInstancesComponent,
        data: {
          breadcrumb: 'List',
          topLevel: 'true'
        }
      },
      {
        path: 'listCachedTableEntityInsts/:cachedTableViewDefinitionId/:contextId/:contextIdType', component: ListCachedEntityInstancesPageComponent,
        data: {
          breadcrumb: 'List',
          topLevel: 'true'
        }
      }
    ]
  }
];
