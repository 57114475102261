import { Pipe, PipeTransform } from '@angular/core';
import { Message } from '@savvy/messaging';

@Pipe({
  name: 'filterChat'
})
export class FilterChatPipe implements PipeTransform {

  transform(items: Message[], filter: string): any {
    if (!items || !filter) {
      return items;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return items.filter(item => {
      if (item.type === 'EMAIL')
        return item?.emailMessage?.recipientsAsString?.toLocaleLowerCase().indexOf(filter) !== -1;

      if (item.type === 'SMS')
        return item?.smsMessage?.toNumberMsisdn?.toLocaleLowerCase().indexOf(filter) !== -1;

      return false;
    });
  }

}
