<mat-card appearance="outlined" class="mx-0 mdc-mb-1">
  <mat-card-header class="mdc-mb-1">
    <div fxLayout="row" fxLayoutAlign=" center" class="w-100">
      <div fxFlex>
        <h6 class="">{{'INVOICES' | translate}}</h6>
      </div>
      <div fxFlex="nogrow">
        <button *ngIf="addInvoiceActive && appType !== 'PET_DAY_CARE'" mat-button color="accent" (click)="addInvoice()">
          <mat-icon class="vm">add</mat-icon> <span class="vm">{{"ADD_INVOICE" | translate}}</span>
        </button>
        <button *ngIf="addInvoiceActive && appType === 'PET_DAY_CARE'" mat-button color="accent" [matMenuTriggerFor]="createInvoiceMenu">
          <mat-icon class="vm">add</mat-icon> <span class="vm">{{"ADD_INVOICE" | translate}}</span>
        </button>
        <mat-menu #createInvoiceMenu="matMenu" x-position="before">
          <button id="create-invoice-for-appointments" mat-menu-item (click)="addInvoiceForAppointments()">
            <span>{{'FOR_APPTS' | translate}}</span>
          </button>
          <button id="create-invoice-for-products" mat-menu-item (click)="addInvoice()">
            <span>{{'FOR_PRODUCTS' | translate}}</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </mat-card-header>
  <!-- <hr> -->
  <mat-card-content>
    <ng-container *ngIf="invoices?.length; else elseBlockInvoices">
      <mat-list>
        <mat-list-item *ngFor="let invoice of invoices" style="cursor: pointer;">
          <div fxLayout="row wrap" fxLayoutAlign=" center" fxLayoutGap="16px" class="mdc-py-2 w-100">
            <div fxFlex="calc(100% - 70px)">
              <h6 class="mb-0 text-truncated">{{ invoice.invoiceDate | localizedDate: 'short' }}</h6>
              <p class="small mat-text-secondary mb-0 text-truncated">{{"DUE_ON" | translate}} {{ invoice?.dueDate | localizedDate:'shortDate' }}</p>
            </div>
            <div fxFlex="50px">
              <div class="avatar avatar-50 circle border-3 d-inline-block" (click)="viewInvoice(invoice)" matTooltip="{{'VIEW_INVOICE' | translate}}">
                <mat-icon>receipt</mat-icon>
              </div>
            </div>
          </div>

          <!-- <div mat-list-icon class="avatar avatar-50 circle border-3">
            <svg xmlns="http://www.w3.org/2000/svg" class="signature-icon" viewBox="0 0 19.084 12.65">
              <path id="Path_119" data-name="Path 119"
                d="M878.678,330.417s-2.469,4.1.221,4.5,7.628-6.393,4.982-7.055-7.407,10.1-4.542,11.42,8.728-6.146,7.235-7.061-5.324,4.036-3.73,4.782,5.155-2,6.241-3.154,2.208-2.679,3.529-1.967-2.037,3.663-1.155,4.477,4.07-3.629,4.07-3.629"
                transform="translate(-877.143 -327.317)" fill="none" stroke="#ef9a9a" stroke-linecap="round"
                stroke-width="1" />
            </svg>
          </div> -->
        </mat-list-item>
      </mat-list>
    </ng-container>
    <ng-template #elseBlockInvoices>
      <div class="flexbox-parent">
        <div fxLayout="row" fxLayoutAlign="center center">
          <div style="text-align:center;">
            <div class="avatar avatar-100 circle border-3 mb-2 d-inline-block mt-15px">
              <mat-icon class="h1 h-auto w-auto">receipt</mat-icon>
            </div>
            <h6 class="mb-2">{{'NO_INVOICES' | translate}}.</h6>
          </div>
        </div>
      </div>
    </ng-template>
  </mat-card-content>
</mat-card>