import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { FloEditorComponent } from './editor.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TranslateModule } from '@ngx-translate/core';
import { FileUploadModule } from 'ng2-file-upload';
import { QuickListViewComponent } from './quick-links/quick-list-view/quick-list-view.component';
import { QuickLinkViewComponent } from './quick-links/quick-link-view/quick-link-view.component';
import { QuickDepositViewComponent } from './quick-links/quick-deposit-view/quick-deposit-view.component';
import { QuickDefaultVariablesComponent } from './quick-links/quick-default-variables/quick-default-variables.component';
import { QuickCheckinFormViewComponent } from './quick-links/quick-checkin-form-view/quick-checkin-form-view.component';
import {
  QuickQuestionnairesViewComponent
} from './quick-links/quick-questionnaires-view/quick-questionnaires-view.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { QuickCustomerPortalComponent } from './quick-links/quick-customer-portal/quick-customer-portal.component';
import { DirectivesModule } from 'src/app/core/directives/directives.module';
import { AppointmentQuickLinksEditorComponent } from './appointment-quick-links-editor/appointment-quick-links-editor.component';
import { MatTabsModule } from '@angular/material/tabs';
import { QuickLinksPanelComponent } from './quick-links/quick-links-panel/quick-links-panel.component';
import { VaccinationQuickLinksEditorComponent } from './vaccination-quick-links-editor/vaccination-quick-links-editor.component';
import { CustomerQuickLinksEditorComponent } from './customer-quick-links-editor/customer-quick-links-editor.component';
import { DepositQuickLinksEditorComponent } from './deposit-quick-links-editor/deposit-quick-links-editor.component';
import { PetQuickLinksEditorComponent } from './pet-quick-links-editor/pet-quick-links-editor.component';
import {StudentCourseQuickLinksEditorComponent} from "./student-course-quick-links-editor/student-course-quick-links-editor.component";
import {MarketingQuickLinksEditorComponent} from "./marketing-quick-links-editor/marketing-quick-links-editor.component";
import { BoardingQuickLinksEditorComponent } from './boarding-quick-links-editor/boarding-quick-links-editor.component';
import {StudentTrainingDayQuickLinksEditorComponent} from "./student-training-day-quick-links-editor/student-training-day-quick-links-editor.component";

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatRadioModule,
    MatButtonModule,
    MatProgressBarModule,
    MatToolbarModule,
    MatTooltipModule,
    MatSelectModule,
    MatChipsModule,
    MatListModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    QuillModule.forRoot(),
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
    TranslateModule,
    DirectivesModule
  ],
  declarations: [
    FloEditorComponent,
    QuickListViewComponent,
    QuickLinkViewComponent,
    QuickDepositViewComponent,
    QuickDefaultVariablesComponent,
    QuickCheckinFormViewComponent,
    QuickQuestionnairesViewComponent,
    QuickCustomerPortalComponent,
    QuickLinksPanelComponent,
    AppointmentQuickLinksEditorComponent,
    BoardingQuickLinksEditorComponent,
    MarketingQuickLinksEditorComponent,
    DepositQuickLinksEditorComponent,
    PetQuickLinksEditorComponent,
    VaccinationQuickLinksEditorComponent,
    CustomerQuickLinksEditorComponent,
    StudentCourseQuickLinksEditorComponent,
    StudentTrainingDayQuickLinksEditorComponent,
  ],
  exports: [
    FloEditorComponent,
    QuickListViewComponent,
    QuickLinkViewComponent,
    QuickDepositViewComponent,
    QuickDefaultVariablesComponent,
    QuickCheckinFormViewComponent,
    QuickQuestionnairesViewComponent,
    QuickCustomerPortalComponent,
    QuickLinksPanelComponent,
    BoardingQuickLinksEditorComponent,
    AppointmentQuickLinksEditorComponent,
    MarketingQuickLinksEditorComponent,
    DepositQuickLinksEditorComponent,
    PetQuickLinksEditorComponent,
    VaccinationQuickLinksEditorComponent,
    CustomerQuickLinksEditorComponent,
    StudentCourseQuickLinksEditorComponent,
    StudentTrainingDayQuickLinksEditorComponent,
  ]
})

export class EditorModule { }
