import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { AuthService } from '@auth0/auth0-angular';
import { TranslateService } from '@ngx-translate/core';
import { AppUpdaterService, LoadMigrationsAvailableResponse } from '@savvy/app-metadata';
import { ChatService } from '@savvy/chat';
import { LookAndFeel, LookAndFeelService } from '@savvy/look-and-feel';
import { ContextIdDto } from '@savvy/menu';
import { OrgDto } from '@savvy/org';
import {
  SubscriptionCompService,
  SubscriptionDefService,
  SubscriptionDefinition,
  SubscriptionStateDto
} from '@savvy/subscription';
import { UserRatingsService } from '@savvy/user';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { GLOBAL } from 'src/app/app.constants';
import { BootstrapService } from 'src/app/bootstrap.service';

import { LanguageService } from 'src/app/services/language.service';
import { environment } from 'src/environments/environment';
import { LookAndFeelLocalService } from '../../../services/lookAndFeel.service';
import { ProductMetaLocalService } from '../../../services/productMeta.service';
import { UiAuthService } from '../../auth/ui-auth.service';
import { ConfService } from '../../config/conf.service';
import { APP_TYPE_ENUM, ContextService } from '../../context.service';
import { DateUtils } from '../../dates/DateUtils';
import { EventBusService } from '../../element/EventBusService';
import { EventType, UiEvent } from '../../event/UiEvent';
import { HelpService } from '../../help/help.service';
import { FaviconService } from '../../logo/favicon.service';
import { ClearObservable } from '../../shared/classes/clear-observable';
import {
  UpdateSubscriptionInTrialModalComponent
} from '../../shared/components/subscription/update-subscription-in-trial-modal/update-subscription-in-trial-modal.component';
import { AppointmentConfigHelperService } from '../../shared/services/appointment-config-helper.service';
import { FeaturesHelperService } from '../../shared/services/features-helper.service';
import { AuthData, SessionStorageService } from '../../shared/services/local-storage.service';
import { OrgSetupHelperService } from '../../shared/services/org-setup-helper.service';
import { ServicesHelperService } from '../../shared/services/services-helper.service';
import { SmsCreditStateService } from '../../shared/services/sms-credit-state.service';
import { SubscriptionHelperService } from '../../shared/services/subscription-helper.service';
import { TrialExpiredStateService } from '../../shared/services/trial-expired-state.service';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';
import { StyleService } from '../../style/style.service';
import { UserRatingModalComponent } from '../../user-rating-modal/user-rating-modal.component';
import { WelcomeOrgSetupService } from '../../welcome-to-savvy/welcome-org-setup.service';
import {
  AngularVersionUpdateBannerComponent
} from './angular-version-update-banner/angular-version-update-banner.component';
import { AppVersionUpdateBannerComponent } from './app-version-update-banner/app-version-update-banner.component';
import { SmsComponent } from './sms.component';

declare const window: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']

})
export class HeaderComponent extends ClearObservable implements OnInit, OnDestroy {

  @Output() toggleSidenav = new EventEmitter<void | boolean>();
  @Output() toggleNotificationSidenav = new EventEmitter<boolean>();
  @Input() eventBus: EventBusService;
  production = environment.production;
  elem: HTMLElement | any;
  backgroundImageUrl = null;
  logoUri: any;
  version: string;
  isFullscreen = false;
  numUnreadNotifications = 0;
  runtimeNavBarData: any;
  uiUpdateAvailable = false; // When new version of Angular app has been released
  appsMenuEnabled = GLOBAL.appsMenu;

  appMigration: LoadMigrationsAvailableResponse; // Contains details on the migration
  migrationScheduled = false;
  contextIdDto: ContextIdDto;
  subscriptions: Subscription[] = [];
  subscriptionsDefs: SubscriptionDefinition[] = [];
  orgSettingsInitialised = false;
  newMessageCount = 0;
  senderInterval: NodeJS.Timeout;

  languages = [
    { locale: 'ar-KW', label: 'KW' },
    { locale: 'en-AU', label: 'AU' },
    { locale: 'en-CA', label: 'CA' },
    { locale: 'en-GB', label: 'GB' },
    { locale: 'en-NZ', label: 'NZ' },
    { locale: 'en-US', label: 'US' },
    { locale: 'fr', label: 'FR' },
  ];
  constructor(
    public router: Router,
    private dateUtils: DateUtils,
    private lookAndFeelService: LookAndFeelService,
    public smsCreditStateService: SmsCreditStateService,
    public contextService: ContextService,
    private lookAndFeelLocalService: LookAndFeelLocalService,
    private productMetaService: ProductMetaLocalService,
    public deviceService: DeviceDetectorService,
    public servicesHelperService: ServicesHelperService,
    public helpService: HelpService,
    private notify: FloSnackbarComponent,
    private dialog: MatDialog,
    private swUpdate: SwUpdate,
    private appUpdaterService: AppUpdaterService,
    private welcomeOrgSetupService: WelcomeOrgSetupService,
    private orgSetupHelperService: OrgSetupHelperService,
    public uiAuthService: UiAuthService,
    private bottomSheet: MatBottomSheet,
    private faviconService: FaviconService,
    private styleService: StyleService,
    private titleService: Title,
    private toastr: ToastrService,
    private subscriptionCompService: SubscriptionCompService,
    private subscriptionDefService: SubscriptionDefService,
    public trialExpiredStateService: TrialExpiredStateService,
    public eventBusService: EventBusService,
    private appointmentConfigHelperService: AppointmentConfigHelperService,
    private featuresHelperService: FeaturesHelperService,
    private chatService: ChatService,
    private userRatingsService: UserRatingsService,
    public languageService: LanguageService,
    public translate: TranslateService,
    private localStorageService: SessionStorageService,
    public subscriptionHelperService: SubscriptionHelperService,
    public bootstrapService: BootstrapService,
    private authService: AuthService,
    @Inject(DOCUMENT) private document: any
  ) {
    super();



    this.subscriptions.push(this.orgSetupHelperService.orgSettingsInitialised$.subscribe((orgSettingsInitialised) => {
      if (GLOBAL.firstSignupSettingsON) {
        if (this.contextService.appType === APP_TYPE_ENUM.PET_GROOMING_SALON
          || this.contextService.appType === APP_TYPE_ENUM.PET_DAY_CARE) {
          this.orgSettingsInitialised = orgSettingsInitialised;
        } else {
          this.orgSettingsInitialised = true;
        }
      }
    }));

    this.subscriptions.push(this.eventBusService.eventAdded$.subscribe((event: UiEvent) => {
      if (event.eventType === EventType.CONSUMER_SERVICES_UPDATED) {
        this.servicesHelperService.loadServices(this.contextIdDto);
      }
    }));
    this.version = ConfService.version();
    const updatesAvailable = this.swUpdate.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      map(evt => ({
        type: 'UPDATE_AVAILABLE',
        current: evt.currentVersion,
        available: evt.latestVersion,
      })));
    updatesAvailable.subscribe((event) => {
      console.log('New update available');
      console.log('current version is', event.current);
      console.log('available version is', event.available);
      this.uiUpdateAvailable = true;
      this.bottomSheet.open(AngularVersionUpdateBannerComponent, {
        // disableClose: true,
        autoFocus: true
      });
    });


  }

  navigateToDashBoard() {
    this.router.navigate(['/retail',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType]);
  }

  triggerRatings() {
    const authData = this.localStorageService.getAuthData();
    const dateToCheck = this.dateUtils.getDateAsStringDash(new Date());
    this.userRatingsService.checkToTriggerRating(authData?.userId, this.contextIdDto.contextId, dateToCheck).subscribe(res => {
      if (res) {
        // trigger rating modal
        this.rating();
      }
    });
  }



  ngOnInit() {
    console.log('inside header init');
    window.onstorage = () => {
      console.log('Storage Updated');
      // When local storage changes, dump the list to
      // the console.
      const authData: AuthData = JSON.parse(window.sessionStorage.getItem("authData"));
      const newEnvId = authData?.envId;
      const existingEnvId = sessionStorage.getItem('envId');
      console.log('New Env Id', newEnvId);
      console.log('Old Env Id', existingEnvId);
      if (newEnvId && existingEnvId && existingEnvId != newEnvId) {
        this.router.navigate(['/']);
      }
    };
    this.elem = document.documentElement;

    this.subscriptions.push(this.contextService.contextIdDto$.subscribe(data => {
      if (data && data.contextId && !this.contextIdDto) {
        this.contextIdDto = data;
        this.appointmentConfigHelperService.loadAppointmentConfig(this.contextIdDto);
        this.featuresHelperService.loadFeaturesAllowed(this.contextIdDto);
        console.log('loading runtime data');

        if (!this.orgSetupHelperService.orgSetupDto) {
          this.welcomeOrgSetupService.loadOrg().then((org: OrgDto) => {
            this.orgSetupHelperService.loadOrgSetup(org.id);
          });
        }
        this.process();

        this.loadSubscriptionList();
        // this.listByOwnerIdAndSenderReadAndOpen();
        this.senderInterval = setInterval(() => {
          this.listByOwnerIdAndSenderReadAndOpen();
        }, 15000);
      }
    }));
    this.eventBus.eventAdded$.subscribe(event => this.onEvent(event));
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    if (this.senderInterval) {
      clearInterval(this.senderInterval);
    }
  }


  loadMetadataUpdates() {
    this.appUpdaterService.loadMigrationsAvailable(this.contextIdDto.contextId).subscribe(response => {
      console.log('appMigration', response);
      if (response && response.migrationsAvailable) {
        this.appMigration = response;
        this.bottomSheet.open(AppVersionUpdateBannerComponent, {
          // disableClose: true,
          autoFocus: true
        });
      }
    });
  }

  performMigration() {
    console.log('calling scheduleMigration');
    this.appUpdaterService.performMigrations(this.contextIdDto.contextId).subscribe(() => {
      this.migrationScheduled = true;
      this.notify.message = 'Scheduled product update';
      this.notify.open();
    });
  }

  getLookAndFeel() {

    if (this.productMetaService.productMetadata) {
      return this.productMetaService.productMetadata.lookAndFeel;
    } else if (this.runtimeNavBarData) {
      this.contextService.setAppType(this.runtimeNavBarData.app.appType);
      return this.runtimeNavBarData.lookAndFeelSettings;
    } else if (this.lookAndFeelLocalService.lookAndFeel) {
      return this.lookAndFeelLocalService.lookAndFeel;
    } else {
      return null;
    }
  }

  process() {
    this.lookAndFeelService.getLookAndFeel(this.contextIdDto.contextId, true).subscribe((lookAndFeel: LookAndFeel) => {
      if (lookAndFeel?.appLogoDark) {
        this.logoUri = lookAndFeel.appLogoDark;
      }
      // appLogoLight
      if (lookAndFeel.appLogoLight) {
        this.backgroundImageUrl = 'url(\'' + lookAndFeel.appLogoLight + '\')';
      }
      if (lookAndFeel.theme) {
        GLOBAL.selectedTheme = lookAndFeel.theme;
        this.styleService.add(lookAndFeel.theme);
      }
      this.faviconService.add(lookAndFeel.favIcon);
      this.titleService.setTitle(lookAndFeel.pageTitle);
    });

    this.subscriptions.push(this.subscriptionHelperService.subscriptionState$.subscribe(subscriptionState => {
      if (subscriptionState && this.contextIdDto?.contextId
        && (subscriptionState.subscriptionStatus === SubscriptionStateDto.SubscriptionStatusEnum.TrialExpired
          || subscriptionState.subscriptionStatus === SubscriptionStateDto.SubscriptionStatusEnum.Cancelled)) {
        // Trial Expired
        this.uiAuthService.isTrialExpired = true;
        this.router.navigate(['subscription/trialExpired',
          this.contextIdDto.contextId, this.contextIdDto.contextIdType]);
      }
    }));
  }

  showVersion() {
    this.notify.message = 'Version is ' + this.version;
    this.notify.open();
  }


  onEvent(event: UiEvent) {
    console.log('header received event!!!', event);
    if (event.eventType === EventType.NUM_NOTIFICATIONS_CHANGED) {
      this.numUnreadNotifications = event.numUnreadNotifications;
      console.log('NUM_NOTIFICATIONS_CHANGED!!!', event);
    }
  }

  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }

  /* Close fullscreen */
  closeFullscreen() {
    if (!this.document?.active) {
      return;
    }
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
  }

  fullScreenToggle() {
    if (!this.isFullscreen) {
      this.openFullscreen();
    } else {
      this.closeFullscreen();
    }
    this.isFullscreen = !this.isFullscreen;
  }

  sms(): void {
    const dialogRef = this.dialog.open(SmsComponent, {
      data: {
        contextIdDto: this.contextIdDto
      },
      autoFocus: false,
      panelClass: ['scrollable-modal', 'helpwindow'],
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        // this.loadSmsCredits();
      });
  }

  checkForUpdate() {
    this.loadMetadataUpdates();
    if (this.swUpdate.isEnabled) {
      this.swUpdate.checkForUpdate().then(() => {
        console.log('Checking for updates...');
        this.updateToLatest();
      }).catch((err) => {
        console.error('Error when checking for update', err);
      });
    }
  }

  updateToLatest(): void {
    console.log('Updating to latest version.');
    this.swUpdate.activateUpdate().then(() => {
      this.bootstrapService.forceReload();
    });
  }

  orgSetup() {
    this.uiAuthService.orgSetup(this.contextIdDto);
  }

  notifications() {
    this.toggleNotificationSidenav.emit(true);
  }

  home() {
    console.log('going to entry');
    this.toastr.clear();
    if (this.orgSettingsInitialised && !this.trialExpiredStateService.isTrialExpired()) {
      this.bootstrapService.gotoViewCalendar(this.contextIdDto);
    } else {
      console.log('setup is not done yet');
    }
  }

  loadSubscriptionList() {
    return new Promise((resolve) => {
      if (this.subscriptionsDefs && this.subscriptionsDefs.length) {
        resolve(this.subscriptionsDefs);
      } else {
        this.subscriptionDefService.loadSubscriptionDefsForUsersAppType(
          this.contextIdDto.contextIdType,
          this.contextIdDto.contextId).subscribe(subDefs => {
            this.subscriptionsDefs = subDefs.subscriptionDefinitionList;
            resolve(this.subscriptionsDefs);
          });
      }
    });
  }

  updateSubscription() {
    this.loadSubscriptionList().then(subscriptionsDefs => {
      const dialogRef = this.dialog.open(UpdateSubscriptionInTrialModalComponent, {
        minWidth: '400px',
        panelClass: ['helpwindow', 'scrollable-modal'],
        data: {
          contextIdDto: this.contextIdDto,
          subscriptionsDefs,
          selectedSubscriptionId: this.trialExpiredStateService.getCurrentSubId()
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed', result);

        if (result) {
          const params = {
            contextId: this.contextIdDto.contextId,
            contextIdType: this.contextIdDto.contextIdType,
            subscriptionDefinitionId: result.id
          };
          this.subscriptionCompService.switchTrialPackage(params).subscribe((res) => {
            if (res) {
              this.notify.message = 'Subscription switched successfully';
              this.notify.open();
              if (result.relogin) {
                this.router.navigate(['/session/signout']);
              } else {
                this.bootstrapService.bootstrap();
              }
            }
          });
        } else {
          this.bootstrapService.bootstrap();
        }
      });

    });
  }

  openHelp() {
    this.newMessageCount = 0;
    this.helpService.openHelp();
  }

  listByOwnerIdAndSenderReadAndOpen() {
    this.chatService.listChats1(this.contextIdDto.contextId, this.contextIdDto.contextIdType, 1, 20)
      .subscribe(response => {
        if (response?.contents?.length) {
          this.newMessageCount = response?.contents?.filter(msg => !msg?.senderRead).length;
        } else {
          this.newMessageCount = 0;
        }
      }, () => {
        this.newMessageCount = 0;
      });
  }

  rating() {
    const dialogRef = this.dialog.open(UserRatingModalComponent, {
      width: '550px',
      panelClass: 'helpwindow',
      data: {
        contextIdDto: this.contextIdDto
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(() => {

    });
  }

  runTour() {
    // window.Intercom('startTour', tourId);
  }

  gotoSettings() {
    sessionStorage.removeItem('selectedMenuItemType');
    sessionStorage.removeItem('expandedIndex');
    this.router.navigate(['/admin/settings',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType, 'company-details']);
  }

  logout() {
    this.uiAuthService.logout();
    this.authService.logout({ logoutParams: { returnTo: document.location.origin } });
  }
}
