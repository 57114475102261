<mat-card appearance="outlined" class="mdc-mb-1">
  <mat-card-header class="mdc-mb-1">
    <h6 class="mb-0 ">{{'CUSTOMER_HISTORY' | translate}}</h6>
  </mat-card-header>
  <!-- <hr> -->
  <mat-card-content>
    <div class="flexbox-parent" *ngIf="workflowStatsDto.workflowStatDtoList && workflowStatsDto.workflowStatDtoList.length === 0">
      <div style="text-align:center;">
        <div class="avatar avatar-100 circle border-3 mb-2 d-inline-block">
          <mat-icon class="h1 h-auto w-auto">timeline</mat-icon>
        </div>
        <h6>{{'NO_HISTORY' | translate}}</h6>
      </div>
      <!-- <div fxLayout="row" fxLayoutAlign="center center">
        <div style="text-align:center;">
          <app-zero-image [localIcon]="'history'"></app-zero-image><br>
          <mat-icon class="h1 h-auto w-auto">timeline</mat-icon>
          <h5 class="ma-0">No History</h5><br>
        </div>
      </div> -->
    </div>

    <div class="flexbox-parent" *ngIf="workflowStatsDto.workflowStatDtoList && workflowStatsDto.workflowStatDtoList.length > 0">
      <div fxLayout="row" fxLayoutAlign="center center">
        <div style="text-align:center;">
          <p *ngFor="let workflowStatDto of workflowStatsDto.workflowStatDtoList">
            <span matBadge="{{workflowStatDto.numStates}}" matBadgeOverlap="false" matBadge="11" matBadgeColor="accent" matBadgeSize="medium" matBadgePosition="above after">
              {{workflowStatDto.state}}
            </span>
            <mat-divider></mat-divider>
          </p>
          <h5 class="ma-0">&nbsp;</h5>
        </div>
      </div>
    </div>


  </mat-card-content>
</mat-card>