<div fxLayout="row wrap">
  <div fxFlex="100">
    <app-flo-editor #messageEditor [hideToolbar]="messageType === 'SMS'"
      [format]="messageType === 'SMS' ? 'text' : 'html'" (editorCreated)="onEditorCreated($event)"
      (textUpdated)="ontextUpdated($event)" [text]="editorContent">
    </app-flo-editor>
  </div>
  <div fxFlex="100" class="mt-1">
    <h6 class="mdc-mb-2"><strong>{{'INCLUDE_IN_MESSAGE' | translate}}</strong></h6>
    <app-quick-links-panel *ngIf="templateValuesList" [quickLinks]="quickLinks" [currencyCode]="currencyCode"
      [templateValuesList]="templateValuesList" (linkSelected)="onQuickLinksAction($event)"
      (variableAdded)="variableAdded($event)"></app-quick-links-panel>
  </div>
  <div fxFlex="100" class="warning-message" *ngIf="messageType === 'SMS'">
    {{'CHAR_LIMIT' | translate}}

  </div>
</div>
