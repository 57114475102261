<mat-toolbar *ngIf="hideHeader" color="primary">
    <mat-toolbar-row>
        {{'APPLY_DISCOUNTS' | translate}}
    </mat-toolbar-row>
</mat-toolbar>
<mat-tab-group *ngIf="discountSettings" (selectedTabChange)="selectedTabChange($event)">
    <mat-tab label="{{'CONFIGURED' | translate}}">
        <div class="mdc-px-3">
            <table class="table mdc-mb-1 w-100">
                <thead>
                    <tr>
                        <th>{{'CODE' | translate}}</th>
                        <th>{{'NOTES' | translate}}</th>
                        <th>{{'DISCOUNT' | translate}}</th>
                        <th>{{'EST_PRICE' | translate}}</th>
                        <th>{{'APPLY' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let discountItem of discountSettings.discountItems">
                        <td>{{discountItem.code}}</td>
                        <td>{{discountItem.notes}}</td>
                        <td>{{discountItem?.discount | discountTmpl:currencyCode}}</td>
                        <td> {{amount | discountCalc:discountItem?.discount | currency:currencyCode:'symbol'}}</td>
                        <td>
                            <button mat-icon-button [disabled]="!discountItem.active" color="accent" aria-label="Apply" #tooltip="matTooltip" matTooltip="Apply" (click)="onApplyClick(discountItem)">
                                <mat-icon>forward</mat-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </mat-tab>
    <mat-tab label="{{'CUSTOM' | translate}}">
        <form [formGroup]="customDiscountForm" fxLayout="column" fxLayoutAlign="center" class="mdc-px-3 mdc-mb-2">
            <mat-radio-group formControlName="discountType" (change)="onDiscountTypeChange()" class="mdc-mb-1">
                <mat-radio-button class="mr-1" value="AMOUNT">{{'AMOUNT' | translate}}
                </mat-radio-button>
                <mat-radio-button value="PERCENTAGE">{{'PERCENTAGE' | translate}}
                </mat-radio-button>
            </mat-radio-group>
            <mat-form-field *ngIf="customDiscountForm.controls['discountType'].value === 'AMOUNT'" appearance="outline">
                <mat-label>{{'AMOUNT' | translate}}&nbsp;</mat-label>
                <input type="text" appNumberOnly [decimalPoints]="2" matInput name="amountDiscount" placeholder="{{'AMOUNT' | translate}}" formControlName="amountDiscount">
                <div matSuffix class="mdc-px-2 fw-bold">{{ currencySymbol }}</div>
            </mat-form-field>
            <mat-form-field *ngIf="customDiscountForm.controls['discountType'].value === 'PERCENTAGE'" appearance="outline">
                <mat-label>{{'PERCENTAGE' | translate}}</mat-label>
                <input type="text" appNumberOnly matInput name="percentDiscount" placeholder="{{'PERCENTAGE' | translate}}" formControlName="percentDiscount">
                <div matSuffix class="mdc-px-2 fw-bold">%</div>
            </mat-form-field>
            <section *ngIf="customDiscountForm.valid" class="mdc-mb-1 ">
                {{'EST_PRICE_BEFORE_DISCOUNT' | translate}} : <span class="mat-text-red" style="text-decoration: line-through;"><strong>{{amount |
                        currency:currencyCode:'symbol'}}</strong></span>
            </section>
            <section *ngIf="customDiscountForm.valid" class="mdc-mb-1 ">
                {{'EST_PRICE_AFTER_DISCOUNT' | translate}} : <span class="mat-text-green"><strong>{{amount | discountCalc:customDiscountForm?.value | currency:currencyCode:'symbol'}}</strong></span>
            </section>
            <button mat-flat-button color="primary" class="" [disabled]="!customDiscountForm.valid" (click)="onCustomApplyClick()">{{'APPLY' | translate}}</button>
        </form>
    </mat-tab>
</mat-tab-group>