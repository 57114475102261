import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { invoiceRoutes } from './invoice.routes';
import { FloMaterialModule } from '../flo.material.module';
import { ViewInvoiceComponent } from './viewInvoice.component';
import { AddInvoiceComponent } from './addInvoice.component';
import { EditInvoiceComponent } from './editInvoice.component';
import { SendInvoiceComponent } from './sendInvoice.component';
import { ViewInvoicePageComponent } from './viewInvoicePage.component';
import { EditorModule } from '../editor/editor.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { SendInvoiceDialogComponent } from './sendInvoiceDialog.component';
import { ExportInvoicesComponent } from './exportInvoices.component';
import { ViewInvoiceBasicComponent } from './viewInvoiceBasic.component';
import { RequestPaymentDialogComponent } from './requestPaymentDialog.component';
import { InvoiceService } from '@savvy/invoice';
import { RequestPaymentComponent } from './requestPayment.component';
import { ExportService } from '@savvy/export';
import { DirectivesModule } from 'src/app/core/directives/directives.module';
import { CardMachinePaymentComponent } from './card-machine-payment.component';
import { PlansPaymentComponent } from './plans-payment.component';
import { ViewInvoicesComponent } from './viewInvoices.component';
import { PaymentSchedulerModalComponent } from './view-payment-requests/payment-scheduler-modal/payment-scheduler-modal.component';
import { DiscountModule } from '../discount/discount.module';
import { SmsLinkPaymentComponent } from './sms-link-payment.component';

@NgModule({
  imports: [
    DirectivesModule,
    FloMaterialModule,
    EditorModule,
    TranslateModule,
    SharedModule,
    DiscountModule,
    RouterModule.forChild(invoiceRoutes),
  ],
  exports: [
    AddInvoiceComponent,
    EditInvoiceComponent,
    RequestPaymentDialogComponent,
    SendInvoiceDialogComponent,
    ExportInvoicesComponent,
    RequestPaymentComponent,
    ViewInvoiceComponent,
    ViewInvoiceBasicComponent,
    ViewInvoicesComponent,
    ViewInvoicePageComponent,
    SendInvoiceComponent,
    CardMachinePaymentComponent,
    PlansPaymentComponent,
    PaymentSchedulerModalComponent,
    SmsLinkPaymentComponent
  ],
  providers: [
    // EiCompositeApiExtension,
    // CustomerCompositeApiExtension,
    // ProductCompositeApiExtension,
    // ServiceCompositeApiExtension,
    // PaymentScheduleDefinitionApiExtension,
    InvoiceService,
    ExportService,
    // ExportApiExtension,
    // MessageApiExtension,
    // EmailMessageTemplatesApiExtension,
    // PaymentCompositeApiExtension,
    // TipsCompositeApiExtension,
    // TipsApiExtension,
    // PaymentMethodApiExtension,
    // PaymentSettingsApiExtension,
    // UserCompositeApiExtension
  ],
  declarations: [
    AddInvoiceComponent,
    EditInvoiceComponent,
    RequestPaymentDialogComponent,
    SendInvoiceDialogComponent,
    ExportInvoicesComponent,
    RequestPaymentComponent,
    ViewInvoiceComponent,
    ViewInvoiceBasicComponent,
    ViewInvoicesComponent,
    ViewInvoicePageComponent,
    SendInvoiceComponent,
    CardMachinePaymentComponent,
    PlansPaymentComponent,
    PaymentSchedulerModalComponent,
    SmsLinkPaymentComponent
  ]
})

export class AppInvoiceModule { }
