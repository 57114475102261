import { Injectable } from '@angular/core';
import { OptIn } from "@savvy/customer";
import { EmailRecipient, SmsRecipient } from "@savvy/messaging";
import RecipientTypeEnum = SmsRecipient.RecipientTypeEnum;

export interface MessageRecipient {
  recipientId: string | undefined;
  recipientType: RecipientTypeEnum;
  email: string;
  mobileNumber: string;
  phoneNumber: string;
  whatsAppNumber: string;
  optIn: OptIn | undefined
}

@Injectable()
export class EmailRecipientHelper {

  public toEmailRecipients(emailAddresses: string): Array<any> {
    const emailRecipients = new Array<any>();
    const recipientEmails = emailAddresses.split(',');
    recipientEmails.forEach(
      emailAddress => {
        const recipient = <any>{};
        recipient.address = emailAddress;
        emailRecipients.push(recipient);
      }
    );
    return emailRecipients;
  }

  public toEmailRecipientsWithCustomerId(emailAddresses: string, customerId: string): Array<EmailRecipient> {
    const emailRecipients = new Array<EmailRecipient>();
    const recipientEmails = emailAddresses.split(',');
    recipientEmails.forEach(
      emailAddress => {
        const recipient = <EmailRecipient>{};
        recipient.address = emailAddress;
        recipient.recipientId = customerId;
        recipient.recipientType = EmailRecipient.RecipientTypeEnum.Customer;
        emailRecipients.push(recipient);
      }
    );
    return emailRecipients;
  }

  public toEmailRecipientsWith(emailAddresses: string, messageToRecipientDto): Array<EmailRecipient> {
    const emailRecipients = new Array<EmailRecipient>();
    const recipientEmails = emailAddresses.split(',');
    recipientEmails.forEach(
      emailAddress => {
        const recipient = <EmailRecipient>{};
        recipient.address = emailAddress;
        if (messageToRecipientDto && messageToRecipientDto.emailAddress === emailAddress) {
          recipient.userId = messageToRecipientDto.id;
        }
        emailRecipients.push(recipient);
      }
    );
    return emailRecipients;
  }

  public toEmailRecipientsWithUser(emailAddresses: string, messageRecipient: MessageRecipient): Array<EmailRecipient> {
    const emailRecipients = new Array<EmailRecipient>();
    const recipientEmails = emailAddresses.split(',');
    recipientEmails.forEach(
      emailAddress => {
        const recipient = <EmailRecipient>{};
        recipient.address = emailAddress;
        recipient.recipientType = EmailRecipient.RecipientTypeEnum.Customer;

        if (messageRecipient && messageRecipient.email === emailAddress) {
          recipient.recipientId = messageRecipient.recipientId;
          recipient.recipientType = messageRecipient.recipientType;
        }
        emailRecipients.push(recipient);
      }
    );
    return emailRecipients;
  }


}
