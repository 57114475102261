import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DiscountItem } from '@savvy/invoice';

@Component({
  selector: 'app-view-discounts-modal',
  templateUrl: './view-discounts-modal.component.html',
  styleUrls: ['./view-discounts-modal.component.scss']
})
export class ViewDiscountsModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ViewDiscountsModalComponent>,) {

  }

  ngOnInit(): void {
  }

  applyDiscount(discountItem: DiscountItem) {
    this.dialogRef.close({
      discountItem
    });
  }

}
