import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

/**
 * Based off https://stackoverflow.com/a/42301718/4185989
 */
@Injectable()
export class ParamsService {
  constructor(
    public router: Router
  ) { }

  getAllParams() {
    const params = {};
    let route = this.router.routerState.snapshot.root;

    do {
      for (const key in route.params) {
        if (Object.prototype.hasOwnProperty.call(route.params, key)) {
          params[key] = route.params[key];
        }
      }
      route = route.firstChild;
    } while (route);

    return params;
  }

  getParam(key) {
    return this.getAllParams()[key];
  }
}
